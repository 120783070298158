import {getDifference} from "./get-difference";
import {getTooltipFontSizes} from "./get-tooltip-font-sizes";
import {getIndicatorShapes} from "./indicator-shapes";

export const getPiePointTableCompare = ({tile, extractedPoint, formatters, rawData, theme}) => {
    const fontSizes = getTooltipFontSizes(theme);

    const dateRangeFormatter = (range) => formatters.dateFormatter(range?.dateStart) + " - " + "</br>" + formatters.dateFormatter(range?.dateEnd);

    const rHeader = () => {
        const seriesTitle = (() => {
            const {diff, diffIcon, stateColor} = getDifference({
                value: extractedPoint.percentage,
                compareValue: extractedPoint.comparePercentage,
                theme,
            });

            return `
                <div class="title">
                    ${extractedPoint.title}
                </div>
                <div class="difference" style="color: ${stateColor}">
                    ${diffIcon}
                    ${formatters.percentFormatter(diff)}
                </div>
            `;
        })();

        const mainColumnTitle = dateRangeFormatter(rawData.series.find((s) => !s.isCompare)?.range);

        const compareColumnTitle = dateRangeFormatter(rawData.series.find((s) => s.isCompare)?.range);

        return `<div class="header table-row" style="font-size: ${fontSizes.small}px">
                <div class="series-title">${seriesTitle}</div>
                    <div class="main-title">
                        ${mainColumnTitle}
                    </div>
                    <div class="compare-title">
                        ${compareColumnTitle}
                    </div>
            </div>`;
    };

    const rPoint = () => {
        const indicatorShapes = getIndicatorShapes(theme);
        return `<div class="point table-row first-point" style="font-size: ${fontSizes.medium}px">
                <div class="name">
                    <span class="indicator" style="color: ${extractedPoint.color}">${indicatorShapes["bar"]}</span>
                    ${formatters.groupFieldFormatter(extractedPoint.dimCategory)}
                </div>
                <div class="main-value">
                    ${formatters.valueFormatter(extractedPoint.value || 0)}
                    </br>
                    <span style="font-weight: normal; font-size: ${fontSizes.small}px">
                        (${formatters.percentFormatter(extractedPoint.percentage)})
                    </span>
                </div>
                <div class="compare-value">
                    ${extractedPoint.compareValue != null ? formatters.valueFormatter(extractedPoint.compareValue) : "N/A"}
                    </br>
                    <span style="font-weight: normal; font-size: ${fontSizes.small}px">
                        ${extractedPoint.comparePercentage != null ? "(" + formatters.percentFormatter(extractedPoint.comparePercentage) + ")" : ""}
                    </span>
                </div>
            </div>`;
    };

    return `${rHeader()}
         ${rPoint()}`;
};
