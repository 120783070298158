import "./review-changes-dialog.scss";

import {keyed} from "@common/react/keyed";
import {cs} from "@common/react/chain-services";

import {chain} from "@common/utils/fs";
import {noun} from "@common/utils/plural";
import {isBlank} from "@common/utils/strings";
import {equalDeep} from "@common/utils/objects";
import {Button} from "@common/form/buttons/button/button";

import {DialogService} from "../../../common/dialog/dialog-service";
import {tooltipService4} from "../../../common/tooltip3/tooltip-service-3";
import {dsSyncHelpers} from "../../edit/common/helpers";
import TableIcon from "assets/icons/common/TableIcon";

export const ReviewChangesDialog = ({next: rootNext}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    // strong: true,
                    disabledClickOverlay: true,
                    render: ({resolve, args: {oriDs, updatedDs}}) => ({
                        width: 650,
                        title: "Review Changes",
                        content: next({resolve, oriDs, updatedDs}),
                    }),
                    next: rootNext,
                }),
        ],
        ({modal}) => {
            const {oriDs, updatedDs, resolve} = modal;
            const isUnstructured = !oriDs.structured;
            const ignoreCheckPrimaryKey = isUnstructured || oriDs.type == "File";

            const getDetailColumnsChanges = (ori, updated) => {
                let result = [];
                if (ori === null)
                    result.push({
                        label: `${isUnstructured ? "Property" : "Column"} Sync On`,
                        value: (
                            <>
                                {updated.name}({updated.dataType}({updated.dataTypeProperties}))
                            </>
                        ),
                    });

                if (!updated?.hasOwnProperty?.("id") && !!ori) {
                    result.push({
                        labelTooltip: `${isUnstructured ? "Properties" : "Columns"} that have sync turned off will be instantly deleted. Re-adding a ${
                            isUnstructured ? "property" : "column"
                        } at a later time will require a full sync of that ${isUnstructured ? "property" : "column"}’s data.`,
                        label: `${isUnstructured ? "Property" : "Column"} Sync Off`,
                        value: (
                            <>
                                {ori.name} ({ori.dataType}
                                {ori.dataTypeProperties !== "None" && ` (${ori.dataTypeProperties})`})
                            </>
                        ),
                    });
                }

                if (ori?.id && updated?.id) {
                    if (!equalDeep(ori?.dataTypeProperties, updated?.dataTypeProperties)) {
                        result.push({
                            label: `${isUnstructured ? "Property" : "Column"} Type Changed`,
                            value: (
                                <>
                                    {updated.name} ({updated.dataType}
                                    {updated.dataTypeProperties !== "None" && ` (${updated.dataTypeProperties})`})
                                </>
                            ),
                            previousValue: (
                                <>
                                    {ori.name} ({ori.dataType}
                                    {ori.dataTypeProperties !== "None" && ` (${ori.dataTypeProperties})`})
                                </>
                            ),
                        });
                    }
                }

                if (!ignoreCheckPrimaryKey && ori?.hasOwnProperty("primaryKey") && updated?.hasOwnProperty("primaryKey") && !equalDeep(ori?.primaryKey, updated?.primaryKey)) {
                    result.push({
                        label: `Primary Key Changed`,
                        value: (
                            <>
                                {updated?.name} ({updated?.primaryKey?.toString()})
                            </>
                        ),
                        previousValue: (
                            <>
                                {ori?.name} ({ori?.primaryKey?.toString()})
                            </>
                        ),
                    });
                }
                return result;
            };

            const getDetailChanges = (ori, updated) => {
                let result = [];
                if (ori === null) result.push({label: "Sync Setting Changed", value: "Sync"});

                if (ori?.id && updated?.id) {
                    if (!equalDeep(ori?.paused, updated?.paused)) {
                        result.push({
                            label: "Sync Setting Changed",
                            labelTooltip: "Sync Setting Changed",
                            value: updated?.paused ? "Pause" : "Sync",
                            previousValue: ori?.paused ? "Pause" : "Sync",
                        });
                    }

                    if (!equalDeep(ori?.syncMethod, updated?.syncMethod)) {
                        result.push({
                            label: "Sync Method Changed",
                            labelTooltip: "Sync Method Changed",
                            value: updated?.syncMethod,
                            previousValue: ori.syncMethod,
                        });
                    }

                    if (!equalDeep(ori?.syncSchedule, updated?.syncSchedule)) {
                        result.push({
                            label: "Sync Interval Changed",
                            labelTooltip: "Sync Interval Changed",
                            value: (
                                <>
                                    {updated.syncSchedule.every && (
                                        <span className="text">
                                            Every {updated.syncSchedule.every} {updated.syncSchedule.interval}
                                            {!isBlank(updated.syncSchedule.duringHours) && (
                                                <>
                                                    {": "}
                                                    {updated.syncSchedule.duringHours === "All"
                                                        ? "All Day"
                                                        : updated.syncSchedule.duringHours
                                                              .split(",")
                                                              .map((item) => {
                                                                  const hours = item.replace("Hour", "");
                                                                  return parseInt(hours) < 12 ? `${parseInt(hours) === 0 ? 12 : hours} AM` : `${parseInt(hours) - 12} PM`;
                                                              })
                                                              .join(", ")}
                                                </>
                                            )}
                                            {!isBlank(updated.syncSchedule.onDays) && (
                                                <>
                                                    {"; "}
                                                    {updated.syncSchedule.onDays === "All" ? "Every Day" : updated.syncSchedule.onDays.split(",").join(", ")}
                                                </>
                                            )}
                                        </span>
                                    )}
                                </>
                            ),
                            previousValue: (
                                <>
                                    {ori.syncSchedule.every ? (
                                        <span className="text">
                                            Every {ori.syncSchedule.every} {ori.syncSchedule.interval}
                                            {!isBlank(ori.syncSchedule.duringHours) && (
                                                <>
                                                    {": "}
                                                    {ori.syncSchedule.duringHours === "All"
                                                        ? "All Day"
                                                        : ori.syncSchedule.duringHours
                                                              .split(",")
                                                              .map((item) => {
                                                                  const hours = item.replace("Hour", "");
                                                                  return parseInt(hours) < 12 ? `${parseInt(hours) === 0 ? 12 : hours} AM` : `${parseInt(hours) - 12} PM`;
                                                              })
                                                              .join(", ")}
                                                </>
                                            )}
                                            {!isBlank(ori.syncSchedule.onDays) && (
                                                <>
                                                    {"; "}
                                                    {ori.syncSchedule.onDays === "All" ? "Every Day" : ori.syncSchedule.onDays.split(",").join(", ")}
                                                </>
                                            )}
                                        </span>
                                    ) : (
                                        `Not Set`
                                    )}
                                </>
                            ),
                        });
                    }

                    if (!equalDeep(ori?.whereFilter, updated?.whereFilter)) {
                        result.push({
                            labelTooltip: `Turning on or changing the row filter query requires a full sync of that table’s data. Turning off the row filter will use the sync method set for the table to bring it up to date.`,
                            label: "Row Filter Changed",
                            value: updated.whereFilter,
                            previousValue: ori.whereFilter,
                        });
                    }

                    if (dsSyncHelpers.columnChanged(ori.columns, updated.columns)) {
                        const columnsChanged = dsSyncHelpers.getColumnsChanged(ori.columns, updated.columns);
                        for (const columnChanged of columnsChanged) {
                            result = [...result, ...getDetailColumnsChanges(columnChanged.ori, columnChanged.updated)];
                        }
                    }
                }

                if (!updated || !updated.hasOwnProperty("id")) {
                    result.push({
                        label: "Sync Setting Changed",
                        value: "Do not sync",
                    });
                }

                return result;
            };

            const tablesChanged = chain(dsSyncHelpers.getTablesChanged(oriDs, updatedDs), (_) =>
                _.map((change, j) => ({
                    ...change,
                    detailChanges: getDetailChanges(change.ori, change.updated),
                }))
            );

            let numbersOfChanges = tablesChanged.reduce((prev, t) => (prev += t.detailChanges.length), 0);

            return (
                <div className="review-changes-dialog-99o">
                    <div className="dialog-body">
                        <div className="label">
                            <span className="highlight">
                                {numbersOfChanges == 1 ? (
                                    `There was 1 change`
                                ) : (
                                    <>
                                        There were {numbersOfChanges} {noun(numbersOfChanges, "change")} made on {tablesChanged.length}{" "}
                                        {noun(tablesChanged.length, isUnstructured ? "object" : "table")}
                                    </>
                                )}
                            </span>
                            . Please review the update carefully before saving.
                        </div>

                        <div className="list">
                            {tablesChanged.map((tc, i) =>
                                cs(keyed(i), () => (
                                    <div className="item">
                                        {TableIcon({className: "table-icon"})}

                                        <div className="content">
                                            <div className="table-name">{(tc.updated ?? tc.ori).name}</div>
                                            <div className="changes">
                                                {tc.detailChanges.map((change, j) =>
                                                    cs(keyed(j), () => (
                                                        <div className="change-item">
                                                            <div className="change-label">
                                                                {change.label}

                                                                {change.labelTooltip &&
                                                                    cs(
                                                                        [
                                                                            "tooltip",
                                                                            (_, next) =>
                                                                                tooltipService4({
                                                                                    direction: "above",
                                                                                    next,
                                                                                }),
                                                                        ],
                                                                        ({tooltip}) => (
                                                                            <i {...tooltip(() => change.labelTooltip)} className="material-icons info-icon">
                                                                                info
                                                                            </i>
                                                                        )
                                                                    )}
                                                            </div>

                                                            <div className="change-value">
                                                                <div className="value">
                                                                    <i className="fal fa-horizontal-rule" />
                                                                    {change.value}
                                                                    {change.previousValue &&
                                                                        cs(
                                                                            [
                                                                                "tooltip",
                                                                                (_, next) =>
                                                                                    tooltipService4({
                                                                                        direction: "below",
                                                                                        next,
                                                                                    }),
                                                                            ],
                                                                            ({tooltip}) => (
                                                                                <span
                                                                                    className="previous-value"
                                                                                    {...tooltip(() => (
                                                                                        <>
                                                                                            Previous Value <i className="fal fa-horizontal-rule" />
                                                                                            &nbsp;
                                                                                            {change.previousValue}
                                                                                        </>
                                                                                    ))}
                                                                                >
                                                                                    PreviousValue
                                                                                </span>
                                                                            )
                                                                        )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>

                    <div className="buttons">
                        <div className="left">
                            <Button onClick={() => resolve("reset")} btnType="secondary">
                                Discard Changes
                            </Button>
                        </div>

                        <div className="right">
                            <Button btnType="secondary" onClick={() => resolve(null)}>
                                Cancel
                            </Button>
                            <Button onClick={() => resolve("save")}>Save & Done</Button>
                        </div>
                    </div>
                </div>
            );
        }
    );
