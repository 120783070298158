import React from "react";
import {cx} from "emotion";

import {InfoIcon} from "@common/ui-components/icons/global-icons";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";

import {CheckIcon} from "../theme-select/theme-select-icons";

export const resolveCurrentIntervalConfig = (clientRefreshIntervalMins) => {
    if (!clientRefreshIntervalMins) {
        return "Disabled";
    }

    return `${clientRefreshIntervalMins} Minute${clientRefreshIntervalMins > 1 ? "s" : ""}`;
};

export const SelectInterval = ({collection, onClose}) => {
    const clientRefreshIntervalMins = collection.value?.clientRefreshIntervalMins ?? 0;

    return (
        <div className="collection-theme-select-a33">
            <VerbScrollbar maxHeight={300}>
                {[0, 1, 3, 5, 10].map((option) => (
                    <div
                        className={cx("item", clientRefreshIntervalMins == option && "selected")}
                        key={option}
                        onClick={() => {
                            collection.change((c) => ({
                                ...c,
                                clientRefreshIntervalMins: option,
                            }));
                            onClose();
                        }}
                    >
                        {resolveCurrentIntervalConfig(option)}
                        <CheckIcon />
                    </div>
                ))}
            </VerbScrollbar>

            <div className="item-footer no-action">
                <InfoIcon />
                <div>Refresh Interval will automatically refresh the data for an end-user of the embedded collection.</div>
            </div>
        </div>
    );
};
