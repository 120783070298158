const {handleUnauthWithRefreshAuthToken} = require("./handle-unauth-with-refresh-auth-token");
const {createFetcher} = require("../../../../tools/fetcher/fetcher");

const createBasicApis = ({authToken, tenantId, refreshAuthToken, onUnauthenticated}) => {
    // console.log("createBasicApis", authToken);

    let _onUnauthenticated = handleUnauthWithRefreshAuthToken({
        refreshAuthToken,
        onUnauthenticated,
    });

    const fetcher = createFetcher({
        onUnauthenticated: _onUnauthenticated,
        headers: {
            ...(tenantId && {
                "x-tenant-id": tenantId,
            }),
        },
    });

    const fetcherWithEnv = (envId) =>
        createFetcher({
            onUnauthenticated: _onUnauthenticated,
            headers: {
                "x-env-id": envId,
                ...(tenantId && {
                    "x-tenant-id": tenantId,
                }),
            },
        });

    return {
        tenant: {
            getTenant: () => fetcher.get("/api/user/tenant"),
            upsertTenant: (tenant) => fetcher.put("/api/user/tenant", tenant),
            getAuthParameters: (authEndpoint) => fetcher.put("/api/user/tenant/auth/parameters", authEndpoint),
            authTest: (authTestReq) => fetcher.put("/api/user/tenant/auth/test", authTestReq),
            getEnvironments: () => fetcher.get("/api/user/tenant/environments"),
            upsertEnvironment: (env) => fetcher.put("/api/user/tenant/environment", env),
            checkEnvironmentName: (name, {envId} = {}) => fetcher.put(`/api/user/tenant/environment/available${envId ? "?currentID=" + encodeURIComponent(envId) : ""}`, {name}),
            getUsers: () => fetcher.get(`/api/user/tenant/users`),
            inviteUser: (user) => fetcher.post(`/api/user/tenant/user/invite`, user),
            resendInvite: (inviteID) => fetcher.put(`/api/user/tenant/user/invite/${inviteID}`),
            deletePendingInvite: (inviteID) => fetcher.delete(`/api/user/tenant/user/invite/${inviteID}`),
            renameTenant: (params) => fetcher.put(`/api/user/tenant/rename`, params),
            generateSDKKey: () => fetcher.post(`/api/user/tenant/sdk-key`),
            getSDKKeys: () => fetcher.get(`/api/user/tenant/sdk-keys`),
            rollANewKey: (sdkKeyID, immediate) => fetcher.delete(`/api/user/tenant/sdk-key/${sdkKeyID}/${immediate}`),
            getGettingStartedStatus: () => fetcher.get(`/api/user/tenant/getting-started/status`),
            getGettingStarted: () => fetcher.get(`/api/user/tenant/getting-started`),
            updateGettingStarted: (data) => fetcher.put(`/api/user/tenant/getting-started`, data),
            getIntegrations: () => fetcher.get(`/api/user/tenant/integrations`),
            addSlackIntegration: (data) => fetcher.post(`/api/user/tenant/integrations/slack`, data),
            getSlackChannels: () => fetcher.get(`/api/user/tenant/integrations/slack/channels`),
            deleteIntegration: (id) => fetcher.delete(`/api/user/tenant/integrations/${id}`),
            getDataSources: ({namesOnly} = {}, envID) => fetcherWithEnv(envID).get(`/api/data` + (namesOnly ? "?namesOnly=true" : "")),
        },
        user: (() => {
            const guestFetcher = createFetcher({
                headers: {
                    ...(tenantId && {
                        "x-tenant-id": tenantId,
                    }),
                },
            });

            return {
                signOut: () => guestFetcher.post("/api/user/sign-out"),
                globalSignOut: () => guestFetcher.post("/api/user/global-sign-out"),
                updateUser: (detail) => fetcher.put("/api/user/update/details", detail),
                changeEmail: ({password, newEmail}) => fetcher.put("/api/user/email", {password, newEmail}),
                changePassword: (detail) => fetcher.post("/api/user/change-password", detail),
                updateRole: (detail) => fetcher.put("/api/user/update/role", detail),
                deleteUser: (userID) => fetcher.delete(`/api/user/${userID}`),
                updateUserPreferences: (settings) => fetcher.patch(`/api/user/update/preferences`, settings),
                enableMFA: (userID, enabled) =>
                    fetcher.put(`/api/user/mfa-enable`, {
                        userID,
                        enabled,
                    }),
                enableMFAUser: (password, enabled) =>
                    fetcher.put(`/api/user/mfa-enable-user`, {
                        password,
                        enabled,
                    }),
                getAssociationToken: (password) => fetcher.post("/api/user/association-token", {password}),
                verifyAssociationToken: (userID, code) =>
                    fetcher.post("/api/user/verify-association-token", {
                        userID,
                        code,
                    }),
                verifyPassword: (password) => fetcher.post("/api/user/verify-password", {password}),
            };
        })(),
        billing: {
            getBillingInfo: () => fetcher.get("/api/user/billing"),
            getSubscriptionInfo: () => fetcher.get(`/api/user/billing/subscription`),
            getSubscriptionBrief: () => fetcher.get(`/api/user/billing/subscription/brief`),
            getInvoices: () => fetcher.get(`/api/user/billing/invoices`),
            autoUpgrade: (value) => fetcher.put(`/api/user/billing/auto-upgrade/${value}`),
            undoDowngrade: () => fetcher.post(`/api/user/billing/subscription/undo-downgrade`),
        },
    };
};
exports.createBasicApis = createBasicApis;
