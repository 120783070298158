import React from "react";
import {cs} from "@common/react/chain-services";
import {TextInput} from "../../../../../../../../../common/form/text-input/text-input";
import {ffToTextInput} from "../../../../../../../../../common/form/ff-to-text-input";
import {Form2} from "@common/react/cs-form/form2";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {ffToDropdown} from "../../../../../../../../../common/form/ff-to-dropdown";
import {ConnectionSettingsLayout} from "../connection-settings-layout";
import {tooltipService3} from "../../../../../../common/tooltip3/tooltip-service-3";
import {Button} from "../../../../../../../../../common/form/buttons/button/button";
import {DsFolderField} from "../../../../../common/folders-dialog/ds-folder-field";
import {cx} from "emotion";
const {required} = require("@common/react/cs-form/validators/required");

export const BigQueryConnectionForm = ({data, adding, dsApi, connectionStatus, formConfig, next}) =>
    cs(
        [
            "form",
            ({state, databaseConfig}, next) =>
                Form2({
                    data,
                    fields: {
                        ...formConfig,
                        "connectionDetails.projectID": [required],
                        "connectionDetails.dataSetID": [required],
                        "connectionDetails.location": [required],
                        "connectionDetails.privateKeyJson": [required],
                    },
                    next,
                }),
        ],
        [
            "testConnection",
            ({form}, next) =>
                next(() => {
                    const _connectionDetails = form.data.value?.connectionDetails;
                    const databaseConfigData = {
                        $type: data.value.$type,
                        ..._connectionDetails,
                    };

                    return dsApi.testConnection(
                        {
                            submitConnectionDetails: databaseConfigData,
                            connectionDetails: databaseConfigData,
                        },
                        {
                            type: data.value?.type,
                            name: form.data.value?.name,
                        }
                    );
                }),
        ],
        ({form, testConnection}) => {
            return next({
                valid: form.valid,
                beforeGoNext: testConnection,
                render: () =>
                    ConnectionSettingsLayout({
                        type: data.value.type,
                        adding,
                        connectionStatus,
                        controls: {
                            valid: form.valid,
                            onTestConnection: testConnection,
                            onDiscard: () =>
                                form.data.onChange({
                                    ...data.value,
                                    connectionDetails: {},
                                }),
                            onGoNext: dsApi.onNextStep,
                        },
                        content: (
                            <>
                                {TextInput({
                                    label: "Display Name",
                                    ...ffToTextInput(form.field("name")),
                                })}

                                {DsFolderField({
                                    state: form.field("folderID").state,
                                })}

                                <div className="form-group-flex">
                                    {TextInput({
                                        label: "Project ID",
                                        placeholder: "project-id",
                                        ...ffToTextInput(form.field("connectionDetails.projectID")),
                                    })}

                                    {TextInput({
                                        label: "Data Set ID",
                                        placeholder: "data-set-id",
                                        ...ffToTextInput(form.field("connectionDetails.dataSetID")),
                                    })}

                                    {DropdownSelect({
                                        label: "GCP Location",
                                        placeholder: "GCP Location",
                                        valueToInputLabel: (v) => v.name,
                                        valueToLabel: (v) => v.name,
                                        list: [
                                            {
                                                name: "Americas - Iowa (us-central1)",
                                                value: "us-central1",
                                            },
                                            {
                                                name: "Americas - Las Vegas (us-west4)",
                                                value: "us-west4",
                                            },
                                            {
                                                name: "Americas - Los Angeles (us-west2)",
                                                value: "us-west2",
                                            },
                                            {
                                                name: "Americas - Montreal (northamerica-northeast1)",
                                                value: "northamerica-northeast1",
                                            },
                                            {
                                                name: "Americas - Northern Virginia (us-east4)",
                                                value: "us-east4",
                                            },
                                            {
                                                name: "Americas - Oregon (us-west1)",
                                                value: "us-west1",
                                            },
                                            {
                                                name: "Americas - Salt Lake City (us-west3)",
                                                value: "us-west3",
                                            },
                                            {
                                                name: "Americas - Sao Paulo (southamerica-east1)",
                                                value: "southamerica-east1",
                                            },
                                            {
                                                name: "Americas - Santiago (southamerica-west1)",
                                                value: "southamerica-west1",
                                            },
                                            {
                                                name: "Americas - South Carolina (us-east1)",
                                                value: "us-east1",
                                            },
                                            {
                                                name: "Americas - Toronto (northamerica-northeast2)",
                                                value: "northamerica-northeast2",
                                            },
                                            {
                                                name: "Europe - Belgium (europe-west1)",
                                                value: "europe-west1",
                                            },
                                            {
                                                name: "Europe - Finland (europe-north1)",
                                                value: "europe-north1",
                                            },
                                            {
                                                name: "Europe - Frankfurt (europe-west3)",
                                                value: "europe-west3",
                                            },
                                            {
                                                name: "Europe - London (europe-west2)",
                                                value: "europe-west2",
                                            },
                                            {
                                                name: "Europe - Netherlands (europe-west4)",
                                                value: "europe-west4",
                                            },
                                            {
                                                name: "Europe - Warsaw (europe-central2)",
                                                value: "europe-central2",
                                            },
                                            {
                                                name: "Europe - Zurich (europe-west6)",
                                                value: "europe-west6",
                                            },
                                            {
                                                name: "Asia Pacific - Delhi (asia-south2)",
                                                value: "asia-south2",
                                            },
                                            {
                                                name: "Asia Pacific - Hong Kong (asia-east2)",
                                                value: "asia-east2",
                                            },
                                            {
                                                name: "Asia Pacific - Jakarta (asia-southeast2)",
                                                value: "asia-southeast2",
                                            },
                                            {
                                                name: "Asia Pacific - Melbourne (australia-southeast2)",
                                                value: "australia-southeast2",
                                            },
                                            {
                                                name: "Asia Pacific - Mumbai (asia-south1)",
                                                value: "asia-south1",
                                            },
                                            {
                                                name: "Asia Pacific - Osaka (asia-northeast2)",
                                                value: "asia-northeast2",
                                            },
                                            {
                                                name: "Asia Pacific - Seoul (asia-northeast3)",
                                                value: "asia-northeast3",
                                            },
                                            {
                                                name: "Asia Pacific - Singapore (asia-southeast1)",
                                                value: "asia-southeast1",
                                            },
                                            {
                                                name: "Asia Pacific - Sydney (australia-southeast1)",
                                                value: "australia-southeast1",
                                            },
                                            {
                                                name: "Asia Pacific - Taiwan (asia-east1)",
                                                value: "asia-east1",
                                            },
                                            {
                                                name: "Asia Pacific - Tokyo (asia-northeast1)",
                                                value: "asia-northeast1",
                                            },
                                        ],
                                        ...ffToDropdown(form.field("connectionDetails.location"), ["value"]),
                                    })}
                                </div>

                                <div className="form-group-flex">
                                    {TextInput({
                                        label: "Private Key Json",
                                        placeholder:
                                            '{\n  "type": "service_account",\n  "project_id": "...",\n  "private_key_id": "...",\n  "private_key": "...",\n  "client_email": "...",\n  "client_id": "...",\n  "auth_uri": "https://accounts.google.com/o/oauth2/auth",\n  "token_uri": "https://oauth2.googleapis.com/token",\n  "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",\n  "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/..."\n}',
                                        multiline: true,
                                        rows: 12,
                                        ...ffToTextInput(form.field("connectionDetails.privateKeyJson")),
                                    })}
                                </div>

                                {/*<div className="buttons">*/}
                                {/*    {cs(*/}
                                {/*        tooltipService3({*/}
                                {/*            direction: "above",*/}
                                {/*            tooltipContentStyle: {*/}
                                {/*                width: 150*/}
                                {/*            }*/}
                                {/*        }),*/}
                                {/*        ({tooltip}) => (*/}
                                {/*            <Button*/}
                                {/*                btnType="secondary"*/}
                                {/*                size="small"*/}
                                {/*                {...!form.valid ? tooltip(() => `All required fields must be filled before testing the connection`) : {}}*/}
                                {/*                className={cx({disabled: !form.valid    })}*/}
                                {/*                onClick={() => form.valid && testConnection()}*/}
                                {/*            >Test Connection</Button>*/}
                                {/*        )*/}
                                {/*    )}*/}
                                {/*    */}
                                {/*    <Button btnType="danger"*/}
                                {/*            size="small"*/}
                                {/*        onClick={() => form.data.onChange({ ...data.value, connectionDetails: {} })}*/}
                                {/*    >*/}
                                {/*        Discard Database</Button>*/}
                                {/*</div>*/}
                            </>
                        ),
                    }),

                // <div className="bigQuery-connection-form data-source-configuration-form-5rq">
                //     <div className="col-left">
                //         {/* <div className="header">Data Source Details</div>

                //         {TextInput({
                //             label: "Display Name",
                //             ...ffToTextInput(form.field("name"))
                //         })} */}

                //         <div className="header">Connection Settings</div>

                //         <div className="form-group-flex">
                //             {TextInput({
                //                 label: "Project ID",
                //                 placeholder: "project-id",
                //                 ...ffToTextInput(form.field("connectionDetails.projectID"))
                //             })}

                //             {TextInput({
                //                 label: "Data Set ID",
                //                 placeholder: "data-set-id",
                //                 ...ffToTextInput(form.field("connectionDetails.dataSetID"))
                //             })}

                //             {DropdownSelect({
                //                 label: "GCP Location",
                //                 placeholder: "GCP Location",
                //                 valueToInputLabel: v => v.name,
                //                 valueToLabel: v => v.name,
                //                 list: [
                //                     { name: "Americas - Iowa (us-central1)", value: "us-central1" },
                //                     { name: "Americas - Las Vegas (us-west4)", value: "us-west4" },
                //                     { name: "Americas - Los Angeles (us-west2)", value: "us-west2" },
                //                     { name: "Americas - Montreal (northamerica-northeast1)", value: "northamerica-northeast1" },
                //                     { name: "Americas - Northern Virginia (us-east4)", value: "us-east4" },
                //                     { name: "Americas - Oregon (us-west1)", value: "us-west1" },
                //                     { name: "Americas - Salt Lake City (us-west3)", value: "us-west3" },
                //                     { name: "Americas - Sao Paulo (southamerica-east1)", value: "southamerica-east1" },
                //                     { name: "Americas - Santiago (southamerica-west1)", value: "southamerica-west1" },
                //                     { name: "Americas - South Carolina (us-east1)", value: "us-east1" },
                //                     { name: "Americas - Toronto (northamerica-northeast2)", value: "northamerica-northeast2" },
                //                     { name: "Europe - Belgium (europe-west1)", value: "europe-west1" },
                //                     { name: "Europe - Finland (europe-north1)", value: "europe-north1" },
                //                     { name: "Europe - Frankfurt (europe-west3)", value: "europe-west3" },
                //                     { name: "Europe - London (europe-west2)", value: "europe-west2" },
                //                     { name: "Europe - Netherlands (europe-west4)", value: "europe-west4" },
                //                     { name: "Europe - Warsaw (europe-central2)", value: "europe-central2" },
                //                     { name: "Europe - Zurich (europe-west6)", value: "europe-west6" },
                //                     { name: "Asia Pacific - Delhi (asia-south2)", value: "asia-south2" },
                //                     { name: "Asia Pacific - Hong Kong (asia-east2)", value: "asia-east2" },
                //                     { name: "Asia Pacific - Jakarta (asia-southeast2)", value: "asia-southeast2" },
                //                     { name: "Asia Pacific - Melbourne (australia-southeast2)", value: "australia-southeast2" },
                //                     { name: "Asia Pacific - Mumbai (asia-south1)", value: "asia-south1" },
                //                     { name: "Asia Pacific - Osaka (asia-northeast2)", value: "asia-northeast2" },
                //                     { name: "Asia Pacific - Seoul (asia-northeast3)", value: "asia-northeast3" },
                //                     { name: "Asia Pacific - Singapore (asia-southeast1)", value: "asia-southeast1" },
                //                     { name: "Asia Pacific - Sydney (australia-southeast1)", value: "australia-southeast1" },
                //                     { name: "Asia Pacific - Taiwan (asia-east1)", value: "asia-east1" },
                //                     { name: "Asia Pacific - Tokyo (asia-northeast1)", value: "asia-northeast1" }
                //                 ],
                //                 ...ffToDropdown(form.field("connectionDetails.location"), ['value'])
                //             })}
                //         </div>

                //         <div className="form-group-flex">
                //             {TextInput({
                //                 label: "Private Key Json",
                //                 placeholder: "{\n  \"type\": \"service_account\",\n  \"project_id\": \"...\",\n  \"private_key_id\": \"...\",\n  \"private_key\": \"...\",\n  \"client_email\": \"...\",\n  \"client_id\": \"...\",\n  \"auth_uri\": \"https://accounts.google.com/o/oauth2/auth\",\n  \"token_uri\": \"https://oauth2.googleapis.com/token\",\n  \"auth_provider_x509_cert_url\": \"https://www.googleapis.com/oauth2/v1/certs\",\n  \"client_x509_cert_url\": \"https://www.googleapis.com/robot/v1/metadata/x509/...\"\n}",
                //                 multiline: true,
                //                 rows: 12,
                //                 ...ffToTextInput(form.field("connectionDetails.privateKeyJson"))
                //             })}
                //         </div>
                //     </div>

                //     <div className="col-right">
                //         <div className="section information">
                //             <div className="text-header">Information</div>
                //         </div>

                //         <div className="section getting-connected">
                //             <div className="text-header active">
                //                 Getting Connected
                //             </div>

                //             <div className="content">
                //                 <div>
                //                     Provide the details and credentials for the data source you're trying to connect to.
                //                     <p>Verb will query the schema and give you the option to include/exclude data like tables, columns and rows.</p>
                //                     <p>All of the fields are required unless explicitly noted.</p>
                //                 </div>
                //             </div>
                //         </div>
                //     </div>
                // </div>
            });
        }
    );
