import {cs} from "@common/react/chain-services";
import {CheckboxLine} from "../../../../../../../common/checkbox-line/checkbox-line";
import {tooltipService3} from "../../../../../../../common/tooltip3/tooltip-service-3";
import "./handling-missing-dates.scss";

export const HandlingMissingDates = ({state}) =>
    cs(tooltipService3({direction: "below"}), ({tooltip}) => (
        <div className="comparison handling-missing-dates-5qq">
            <div className="header">HANDLING MISSING DATES</div>

            <div className="content">
                {CheckboxLine({
                    state,
                    label: (
                        <>
                            Show toggle for including data with missing dates
                            <i {...tooltip(() => `Missing dates will not display when shown as the X or Y axis on bar charts or line charts.`)} className="material-icons">
                                info
                            </i>
                        </>
                    ),
                })}
            </div>
        </div>
    ));
