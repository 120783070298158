import {ffToTextInput} from "../../../../../../../../common/form/ff-to-text-input";
import {DebounceTextInput} from "../../../../../../../../common/form/debounce-input/debounce-text-input";

export const rNewColumnNameInput = ({form, newColumnPath, newColumnNamePath, label}) => {
    const newColumnProps = newColumnPath && ffToTextInput(form.field(newColumnPath));
    const newColumnNameProps = ffToTextInput(form.field(newColumnNamePath));
    return DebounceTextInput({
        label: label || "Name",
        hasError: newColumnProps?.hasError || newColumnNameProps.hasError,
        errorMessage: newColumnProps?.errorMessage || newColumnNameProps.errorMessage,
        // state: {
        //     value: newColumnProps.state.value?.name,
        //     onChange: (v) => newColumnProps.state.onChange(({
        //         $type: "ViewStepColumn",
        //         name: v,
        //     }))
        // }
        state: newColumnNameProps.state,
    });
};
