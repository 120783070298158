import React from "react";
import "./left-panel-closable.scss";
import {cs} from "@common/react/chain-services";
import CloseIcon from "../../../../../common/form/common/close-black-icon.svg";
import {TruncatingTooltip} from "../truncating-tooltip/truncating-tooltip";
import {cx} from "emotion";

export const LeftPanelClosable = ({label, content, onClose, enableTruncatingTooltip, hideClose = false, className, overrideLeftPanelRef, hideCloseXButton}) =>
    cs(["truncatingTooltip", (_, next) => TruncatingTooltip({next})], ({truncatingTooltip}) => (
        <div className={cx("left-panel-closable-1os left-panel-closable", className)}>
            {!hideClose && (
                <div className="header" id="left-panel-header">
                    <div
                        {...{
                            className: "label",
                            ...(enableTruncatingTooltip && {
                                ref: truncatingTooltip.ref,
                                ...truncatingTooltip.tooltip(label),
                            }),
                        }}
                    >
                        {label}
                    </div>

                    {!hideCloseXButton && (
                        <div
                            className="close"
                            onClick={() => {
                                onClose?.();
                            }}
                        >
                            <img src={CloseIcon} alt="" />
                        </div>
                    )}
                </div>
            )}

            <div className="content">{content}</div>

            {overrideLeftPanelRef && <div ref={overrideLeftPanelRef.set} />}
        </div>
    ));
