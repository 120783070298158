import {tooltipPositionerNormal} from "./tooltip-positioner-normal";
import {tooltipPositionerHorizontal} from "./tooltip-positioner-horizontal";
import {tooltipPositionerPieDonut} from "./tooltip-positioner-pie-donut";

const tooltipPositioners = [
    {
        types: ["VerticalBarChartTile", "LineChartTile", "ComboChartTile", "BoxPlotTile"],
        positioner: tooltipPositionerNormal,
    },
    {
        types: ["HorizontalBarChartTile"],
        positioner: tooltipPositionerHorizontal,
    },
    {
        types: ["PieChartTile", "DonutChartTile", "GaugeTile", "MapTile", "FunnelChartTile", "VennDiagramTile", "ScatterPlotTile", "BubbleChartTile"],
        positioner: tooltipPositionerPieDonut,
    },
];

export const getTooltipPositioner = (type) => {
    return tooltipPositioners.find((tp) => tp.types.includes(type))?.positioner;
};
