const {GlobalMouseUp} = require("@common/react/global-mouse-up");
const {spc} = require("@common/react/state-path-change");
const {GlobalMouseMove} = require("@common/react/global-mouse-move");
const {fragments} = require("@common/react/fragments");
const {UseState} = require("@common/react/use-state");
const {cs} = require("@common/react/chain-services");
const {getELoc} = require("@common/react/get-e-loc");

const DragWatcher = ({onDragged, next}) =>
    cs(["state", (_, next) => UseState({next})], ({state}) =>
        fragments(
            next({
                onMouseDown: (e) => {
                    state.onChange({pos: getELoc(e)});
                },
            }),

            state.value &&
                fragments(
                    GlobalMouseMove({
                        fn: (e) => {
                            // spc(state, ["informed"]);
                            //console.log("mouse move");
                            state.onChange(null);
                            onDragged(e);
                        },
                    }),

                    GlobalMouseUp({
                        fn: (e) => {
                            state.onChange(null);
                        },
                    })
                )
        )
    );
exports.DragWatcher = DragWatcher;
