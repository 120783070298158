import * as React from "react";
import {Expandable} from "../../../../../../../../../../../common/expandable/expandable";
import {cs} from "@common/react/chain-services";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {stateToSelect} from "@common/ui-components/form/state-to-select";
import {scope} from "@common/react/scope";
import {spc} from "@common/react/state-path-change";

export const Negatives = ({field}) =>
    cs(({}) => (
        <div className="negatives-33b">
            {DropdownSelect({
                label: "Negative Format",
                list: [
                    {
                        label: "Minus Sign: -1,234.56",
                        value: "MinusSign",
                    },
                    {
                        label: "Parentheses: (1,234.56)",
                        value: "Parentheses",
                    },
                ],
                valueToLabel: (v) => v.label,
                // ...stateToSelect(scope(field, ["numericProperties", "negativeFormat"]), ["value"]),
                isSelected: (v) => v.value === (field.value.numericProperties?.negativeFormat || "Parentheses"),
                onChange: (v) => spc(field, ["numericProperties", "negativeFormat"], () => v.value),
            })}
        </div>
    ));
