import "./tile-fields.scss";

import * as React from "react";

import {cs} from "@common/react/chain-services";
import {consumeContext, provideContext} from "@common/react/context";
import {scope} from "@common/react/scope";
import {getPath} from "@common/utils/arr-path";
import {isEmpty} from "@common/utils/objects";
import {Expandable} from "../../../../../../../common/expandable/expandable";
import {InfoDialog} from "../../../../../../../common/info-dialog/info-dialog";
import {getTileActionsUpdated} from "../actions/get-tile-actions-updated";
import {getTileFields} from "./common/get-tile-fields";
import {hasDateGroupsConfiguration} from "./common/has-date-groups-configuration";
import {getLimitUpdated} from "./common/limit-sorting/limit/get-limit-updated";
import {Limit} from "./common/limit-sorting/limit/limit";
import {AxisSorting} from "./common/limit-sorting/sorting/axis/axis-sorting";
import {getAxisSortingUpdated, getAxisSortingUpdatedByLimit} from "./common/limit-sorting/sorting/axis/get-axis-sorting-updated";
import {getGroupSortingUpdated} from "./common/limit-sorting/sorting/group/get-group-sorting-updated";
import {GroupSorting} from "./common/limit-sorting/sorting/group/group-sorting";
import {MeasureFieldGroups} from "./common/measure-field-groups/measure-field-groups";
import {getColumnType} from "./common/select-field/select-field";
import {SingleFieldWell} from "./common/single-field-well/single-field-well";
import {updateLimitSortingInTile, updateMeasureFieldsGroupsInTile, updateSingleFieldInTile} from "./common/update-tile";

export const HorizontalBarFields = ({tile, isSubTile, form, tileActions, debugFunction, next}) =>
    cs(
        consumeContext("getFieldInfo"),
        [
            "tileFields",
            ({getFieldInfo}, next) =>
                next(
                    getTileFields({
                        tile: tile.value,
                        dimensionAttr: "yAxisField",
                        measureGroupAttrs: ["xAxisFields"],
                        groupFieldAttr: "groupField",
                        getFieldInfo,
                    })
                ),
        ],
        ({tileFields}, next) => provideContext("tileFields", tileFields, next),

        ["infoDialog", (_, next) => InfoDialog({next})],
        [
            "yAxisField",
            ({tileFields}, next) =>
                SingleFieldWell({
                    field: scope(tile, ["yAxisField"]),
                    onUpdateTile: (newVal, oriVal) =>
                        updateSingleFieldInTile({
                            fieldAttr: "yAxisField",
                            fieldVal: {newVal, oriVal},
                            tileFields,
                            tile,
                            updateSorting: getAxisSortingUpdated,
                            updateLimit: getLimitUpdated,
                            updateTileActions: getTileActionsUpdated,
                        }),
                    $type: "DimensionTileField",
                    label: "Y-Axis",
                    next,
                }),
        ],
        [
            "xAxisFields",
            ({infoDialog, tileFields}, next) =>
                MeasureFieldGroups({
                    fieldGroups: scope(tile, ["xAxisFields"]),
                    label: "X-Axis",
                    onUpdateTile: (newVal, indexes) =>
                        updateMeasureFieldsGroupsInTile({
                            fieldAttr: "xAxisFields",
                            fieldVal: {newVal, oriVal: null},
                            tileFields,
                            indexes,
                            tile,
                            updateSorting: getAxisSortingUpdated,
                            updateLimit: getLimitUpdated,
                        }),
                    unavailable: () => !isEmpty(tile.value?.groupField),
                    showUnavailableMessage: () =>
                        infoDialog.show({
                            title: "Grouped Measure Unavailable",
                            content: `The grouped measure option is not available because there is a group field selected. To create a grouped measure joined with an "AND" first remove the group field.`,
                        }),
                    info: (
                        <span>
                            The <b>OR</b> option will create a separate axis on the chart. You may control how multiple axes display in the Display tab, Multiple Axis Display
                            option.
                        </span>
                    ),
                    direction: "top",
                    next,
                }),
        ],
        [
            "groupField",
            ({infoDialog, tileFields}, next) =>
                SingleFieldWell({
                    field: scope(tile, ["groupField"]),
                    hasDateGroupsConfiguration: hasDateGroupsConfiguration(tile.value.$type),
                    $type: "CategoryTileField",
                    label: "Group",
                    filterColumn: (column) => column.$type !== "AggregatedMeasureModelColumn",
                    onUpdateTile: (newVal, oriVal) =>
                        updateSingleFieldInTile({
                            fieldAttr: "groupField",
                            fieldVal: {newVal, oriVal},
                            tileFields,
                            tile,
                            updateSorting: getGroupSortingUpdated,
                            updateTileActions: getTileActionsUpdated,
                        }),
                    unavailable: () => tile.value?.xAxisFields?.filter((f) => f.measureFields?.length > 1).length > 0,
                    showUnavailableMessage: () =>
                        infoDialog.show({
                            title: "Grouped Field Unavailable",
                            content: `This chart has more than one measure (x-axis field) joined with an "AND" so a group field cannot be applied. To add a group field first update the x-axis fields so there no measures joined by an "AND"`,
                        }),
                    next,
                }),
        ],
        [
            "fieldsSection",
            ({xAxisFields, yAxisField, groupField}, next) =>
                next({
                    render: () =>
                        Expandable({
                            label: "Fields",
                            render: () => (
                                <>
                                    {yAxisField.render()}
                                    {xAxisFields.render()}
                                    {groupField.render()}
                                </>
                            ),
                        }),
                    override: xAxisFields.override || yAxisField.override || groupField.override,
                }),
        ],
        consumeContext("getFieldInfo"),
        [
            "limitSorting",
            ({tileFields}, next) =>
                cs(
                    [
                        "limit",
                        (_, next) => {
                            const formField = form.field(["limit"]);
                            return next(
                                Limit({
                                    field: formField.state,
                                    allFields: tileFields.allFieldsWithoutUniq,
                                    onChange: (newLimit, field) => {
                                        const limit = {
                                            ...newLimit,
                                            direction: field.$type === "MeasureTileField" ? "Asc" : newLimit.direction,
                                        };

                                        updateLimitSortingInTile({
                                            tile,
                                            limit,
                                            updateSorting: () =>
                                                getAxisSortingUpdatedByLimit({
                                                    tile,
                                                    field: {
                                                        ...limit,
                                                        $type: field.$type,
                                                    },
                                                    dimensionFieldAttr: "yAxisField",
                                                }),
                                        });
                                    },
                                    isHiddenRankOption: () => getPath(formField.state.value, ["modelColumnID"]) == getPath(tile.value, ["yAxisField", "modelColumnID"]),
                                })
                            );
                        },
                    ],
                    [
                        "sorting",
                        (_, next) =>
                            next(
                                <>
                                    {AxisSorting({
                                        fieldGroups: form.field(["sort"]).state,
                                        title: "Y-Axis",
                                    })}
                                    {tile.value.groupField && GroupSorting({form, tile})}
                                </>
                            ),
                    ],
                    ({limit, sorting}) =>
                        next({
                            render: () =>
                                Expandable({
                                    initExpand: false,
                                    label: "Limit & Sorting",
                                    render: () => (
                                        <>
                                            {limit}
                                            {sorting}
                                        </>
                                    ),
                                }),
                        })
                ),
        ],
        ({limitSorting, tileFields, getFieldInfo, fieldsSection}) => {
            console.log(isSubTile);
            return next({
                render: () => (
                    <div className="tile-fields-3vr">
                        {fieldsSection.render()}
                        {limitSorting.render({tileFields, getFieldInfo})}
                        {tileActions?.render?.()}
                        {isSubTile && debugFunction?.render?.({})}
                    </div>
                ),
                override: fieldsSection.override || debugFunction?.override() || tileActions?.override({fieldType: "MeasureTileField"}),
                overrideAll: debugFunction?.overrideAll || tileActions?.overrideAll,
            });
        }
    );
