export const MaximizeIcon = ({className, onClick, fill = "white"} = {}) => (
    <svg width="20" onClick={onClick} className={className} height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.3332 2.5H18.3332V7.5H16.6665V4.16667H13.3332V2.5ZM1.6665 2.5H6.6665V4.16667H3.33317V7.5H1.6665V2.5ZM16.6665 15.8333V12.5H18.3332V17.5H13.3332V15.8333H16.6665ZM3.33317 15.8333H6.6665V17.5H1.6665V12.5H3.33317V15.8333Z"
            fill={fill}
        />
    </svg>
);

export const MinimizeIcon = ({className, onClick, fill = "white"} = {}) => (
    <svg width="20" onClick={onClick} className={className} height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14.9998 5.83333H18.3332V7.5H13.3332V2.5H14.9998V5.83333ZM6.6665 7.5H1.6665V5.83333H4.99984V2.5H6.6665V7.5ZM14.9998 14.1667V17.5H13.3332V12.5H18.3332V14.1667H14.9998ZM6.6665 12.5V17.5H4.99984V14.1667H1.6665V12.5H6.6665Z"
            fill={fill}
        />
    </svg>
);
