import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {createReportApis} from "./report-apis";
import "./notion-report.scss";
import {DropdownSelect2} from "@common/ui-components/dropdown-select/dropdown-select2";
import {DatePicker} from "@common/form/date-picker/date-picker";
import {scope} from "@common/react/scope";
import {CheckboxLineGroup} from "../../common/checkbox-line-group/checkbox-line-group";
import {Load} from "@common/react/load";
import {consumeContext, provideContext} from "@common/react/context";
import {waitTimeout} from "@common/utils/wait-timeout";
import {LoadingIndicator} from "@common/ui-components/loading-indicator/loading-indicator";
import {TextInput} from "@common/form/text-input/text-input";
import {Load2} from "@common/react/load2";

export default function NotionReport() {
    return cs(
        consumeContext("auth"),
        ["reportApis", (_, next) => next(createReportApis())],
        ({reportApis}, next) => provideContext("reportApis", reportApis, next),
        [
            "reportSections",
            ({reportApis}, next) =>
                Load({
                    fetch: async () => {
                        await waitTimeout(500);
                        return await reportApis.getReportSections();
                    },
                    next,
                }),
        ],
        ["state", (_, next) => UseState({next})],
        ["result", (_, next) => UseState({next})],
        ({reportApis, state, result, reportSections, auth}) => {
            return (
                <div className="notion-report-9c2">
                    <div className="generate-report">
                        <div className="select customer-select">
                            {TextInput({
                                state: scope(state, ["advertiserId"]),
                            })}
                            {TextInput({
                                state: scope(state, ["notionReportingPageUrl"]),
                            })}
                            {TextInput({
                                state: scope(state, ["name"]),
                            })}
                        </div>

                        <div className="select date-range-select">
                            {DatePicker({
                                className: "date-picker",
                                label: "Start date",
                                state: scope(state, ["dateConfig", "from"]),
                            })}
                            {(() => {
                                const {value, onChange} = scope(state, ["dateConfig", "to"]);
                                return DatePicker({
                                    className: "date-picker",
                                    label: "End date",
                                    state: {
                                        value,
                                        onChange: (v) => onChange(v.replace("T00:00:00Z", "T23:59:59Z")),
                                    },
                                });
                            })()}
                        </div>

                        <div className="select report-section-select">
                            {ReportSectionSelect({
                                list: reportSections,
                                ...scope(state, ["sections"]),
                            })}
                        </div>

                        {/*<div className="" style={{width: 500}}>*/}
                        {/*    <pre>{JSON.stringify(state.value, null, 2)}</pre>*/}
                        {/*</div>*/}

                        <div className="buttons">
                            <button
                                onClick={async () => {
                                    try {
                                        // const report = await reportApis.generateReport(state.value);
                                        // result.onChange({url: report.url});
                                        const report = await reportApis.saveReport({
                                            ...state.value,
                                            createdBy: [auth.user.firstName, auth.user.lastName].filter(v => v).join(" "),
                                        });
                                        result.onChange(report);
                                    } catch (error) {
                                        result.onChange({error});
                                    }
                                }}
                            >
                                generate report
                            </button>
                            {/*<button*/}
                            {/*    onClick={async () => {*/}
                            {/*        try {*/}
                            {/*            const page = await reportApis.getPageProps("aa2d9612e07646aba7974693b838f44f");*/}
                            {/*            result.onChange({page});*/}
                            {/*        } catch (error) {*/}
                            {/*            result.onChange({error});*/}
                            {/*        }*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    get page*/}
                            {/*</button>*/}
                        </div>

                        <div className="result">
                            {/*{result.value?.url && (*/}
                            {/*    <a href={result.value.url} target={"_blank"}>*/}
                            {/*        {result.value.url}*/}
                            {/*    </a>*/}
                            {/*)}*/}
                            {/*{result.value?.error && (*/}
                            {/*    <div className="" style={{width: 500}}>*/}
                            {/*        <pre>{JSON.stringify(result.value.error, null, 2)}</pre>*/}
                            {/*    </div>*/}
                            {/*)}*/}
                            {/*{result.value?.page && (*/}
                            {/*    <div className="" style={{width: 500}}>*/}
                            {/*        <pre>{JSON.stringify(result.value.page, null, 2)}</pre>*/}
                            {/*    </div>*/}
                            {/*)}*/}

                            {result.value && (
                                <div className="" style={{width: 500}}>
                                    <pre>{JSON.stringify(result.value, null, 2)}</pre>
                                </div>
                            )}
                        </div>
                    </div>

                    {/*<div className="report-sections">*/}
                    {/*    {ReportSections({reportSections})}*/}
                    {/*</div>*/}

                    <div className="report-sections">
                        {Reports()}
                    </div>
                </div>
            );
        },
    );
}

const Reports = () => {
    return cs(
        consumeContext("reportApis"),
        [
            "reports",
            ({reportApis}, next) =>
                Load2({
                    fetch: async () => {
                        await waitTimeout(500);
                        return await reportApis.getReports();
                    },
                    next,
                }),
        ],
        ({reports}) => {
            return (
                <div className="">
                    REPORTS
                    <div className="">
                        <button onClick={() => reports.reload()}>reload</button>
                    </div>
                    <pre>{JSON.stringify(reports.value, null, 2)}</pre>
                </div>
            );
        },
    );
};

const ReportSections = ({reportSections}) => {
    if (!reportSections) {
        return null;
    }

    return cs(
        ["section", (_, next) => UseState({next})],
        ({section}) => {
            return (
                <div className="">
                    <div className="sections">
                        {reportSections.map((rs) => (
                            <div className="" key={rs.id} onClick={() => section.onChange(rs)}>
                                {rs.name}
                            </div>
                        ))}
                    </div>
                    {section.value && (() => {
                        const {name, type, children} = section.value;
                        return (
                            <div className="section">
                                <div className="name">
                                    name: {name}
                                </div>
                                <div className="content">
                                    {children.map((c) => {
                                        if (c.type === "api-collection") {
                                            return (
                                                <div className="child">
                                                    <div className="type">
                                                        type: {c.type}
                                                    </div>
                                                    <div className="col-id">
                                                        collectionId: {c.config.collectionId}
                                                    </div>
                                                    <div className="api-url">
                                                        api url: {c.config.url}
                                                    </div>
                                                    <div className="api-url">
                                                        api key: {c.config.apiKey}
                                                    </div>
                                                </div>
                                            );
                                        }
                                        return (
                                            <div className="child">
                                                <div className="type">
                                                    type: {c.type}
                                                </div>
                                                <div className="col-id">
                                                    collectionId: {c.config.collectionId}
                                                </div>
                                                <div className="api-url">
                                                    iframe url: {c.config.url}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                                {/*<pre>{JSON.stringify(section.value, null, 2)}</pre>*/}
                            </div>
                        );
                    })()}
                </div>
            );
        },
    );
};

const CustomerSelect = ({value, onChange}) => {
    return cs(
        consumeContext("reportApis"),
        [
            "customers",
            ({reportApis}, next) =>
                Load({
                    fetch: async () => {
                        await waitTimeout(500);
                        return await reportApis.getCustomers();
                    },
                    next,
                }),
        ],
        ({customers}) => {
            return DropdownSelect2({
                label: "Customer",
                list: customers,
                onChange,
                isSelected: (l) => l.name === value,
                valueToLabel: (l) => l.name,
            });
        }
    );
};

const ReportSectionSelect = ({list, value, onChange}) => {
    return cs(
        // consumeContext("reportApis"),
        // [
        //     "reportSections",
        //     ({reportApis}, next) =>
        //         Load({
        //             fetch: async () => {
        //                 await waitTimeout(500);
        //                 return await reportApis.getReportSections();
        //             },
        //             next,
        //         }),
        // ],
        ({reportSections}) => {
            if (!list) {
                return LoadingIndicator({});
            }

            const isSelected = (l) => value?.findIndex((v) => v.sectionId === l.id) > -1;
            return CheckboxLineGroup({
                list: list.map((l) => ({...l, label: l.name})),
                isSelected,
                onChange: (l) => {
                    if (isSelected(l)) {
                        onChange(value.filter((v) => v.sectionId !== l.id));
                    } else {
                        onChange([...(value || []), {sectionId: l.id}]);
                    }
                },
            });
        }
    )
};
