import * as React from "react";
import {cs} from "@common/react/chain-services";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {stateToSelect} from "@common/ui-components/form/state-to-select";
import {scope} from "@common/react/scope";
import {getTextAggregationFuncs} from "../../../../../../../../../../../common/aggregation-funcs/text-aggregation-funcs";
import "./text-field.scss";
import {SelectNumberFormat} from "../number/select-number-format";
import {getPath} from "@common/utils/arr-path";
import {FieldSection} from "../../../field-section/field-section";
import {FieldInfoProvider} from "../../../../../../tile-tab/chart-types/common/field-info-provider/field-info-provider";
import {NumberFieldPreview} from "../number/number-field-preview";
import {getBoolAggregationFuncs} from "../../../../../../../../../../../common/aggregation-funcs/bool-aggregation-funcs";

export const TextField = ({type, field, onAddFormat, notAllowNoneAggregation, customListAgg}) =>
    cs(({}) => {
        const notAllowNone = notAllowNoneAggregation || ["MeasureTileField", "DimensionlessMeasureTileField"].includes(field.value.$type);
        const aggregationFuncs = type === "bool" ? getBoolAggregationFuncs({notAllowNone}) : getTextAggregationFuncs({notAllowNone});
        return (
            <>
                {getPath(field.value, ["aggregationFunc"])?.toLowerCase().startsWith("count") && NumberFieldPreview({field})}

                {FieldSection({
                    header: FieldInfoProvider({
                        field: <div className="text">FORMATTING</div>,
                    }),
                    content: (
                        <div className="text-field-4zs">
                            {field.value.$type.includes("MeasureTileField") &&
                                DropdownSelect({
                                    label: "Aggregate",
                                    list: (customListAgg?.() ?? aggregationFuncs).filter((v) => v),
                                    valueToLabel: (v) => v.label,
                                    ...stateToSelect(scope(field, ["aggregationFunc"]), ["value"]),
                                })}
                            {getPath(field.value, ["aggregationFunc"])?.toLowerCase().startsWith("count") && SelectNumberFormat({field, onAddFormat})}
                        </div>
                    ),
                })}
            </>
        );
    });
