import {equalDeep, omit} from "../../utils/objects";
import {provideContext} from "../context";

const {cs} = require("../chain-services");
const {Static} = require("../static");

const createApiCache = ({next: rootNext}) => {
    return cs(
        [
            "cache",
            (_, next) => {
                return Static({
                    getInitValue: () => [],
                    next,
                });
            },
        ],
        ({cache}) => {
            return rootNext({
                getCachedData: ({key}) => {
                    return cache.find((r) => equalDeep(r.key, key));
                },
                setCache: ({key, api}) => {
                    const data = {
                        key,
                        apiPromise: api(),
                        api,
                        createdAt: Date.now(),
                    };
                    cache.push(data);
                    return data;
                },
                deleteFromCache: ({key}) => {
                    const index = cache.findIndex((c) => equalDeep(c.key, key));
                    if (index === -1) {
                        return;
                    }
                    const [removed] = cache.splice(index, 1);
                    return removed;
                },
            });
        }
    );
};

const createData = async (cacheData) => {
    const data = await cacheData.apiPromise;
    return {
        data,
        createdAt: cacheData.createdAt,
    };
};

const CacheAdapter = ({apiCache, next}) => {
    const fetch = async ({key, api, onCached}) => {
        const cachedData = apiCache.getCachedData({key});
        if (cachedData) {
            return createData(cachedData);
        }

        const data = await createData(apiCache.setCache({key, api}));
        onCached?.({key, data});
        return data;
    };
    const refresh = async ({key, onCached}) => {
        const prev = apiCache.deleteFromCache({key});
        if (!prev) {
            return;
        }

        const data = await createData(apiCache.setCache({key, api: prev.api}));
        onCached?.({key, data});
        return data;
    };
    return next({
        fetch,
        refresh,
    });
};

export const CachedApiProvider = ({next: rootNext}) => {
    return cs(
        [
            "apiCache",
            (_, next) => {
                return createApiCache({next});
            },
        ],
        ["cacheAdapter", ({apiCache}, next) => CacheAdapter({next, apiCache})],

        ({cacheAdapter}) => {
            return provideContext(
                "apiCache",
                {
                    createFetcher: ({fetch, key, getCacheKey, onCached}) => {
                        const cacheKey = getCacheKey(key);
                        return {
                            fetch: async () => {
                                try {
                                    const data = await cacheAdapter.fetch({
                                        key: cacheKey,

                                        api: fetch,
                                        onCached,
                                    });

                                    return data;
                                } catch (err) {
                                    throw err;
                                }
                            },
                            refresh: async () => {
                                try {
                                    const data = await cacheAdapter.refresh({
                                        key: cacheKey,
                                        onCached,
                                    });

                                    return data;
                                } catch (err) {
                                    throw err;
                                }
                            },
                        };
                    },
                },
                rootNext
            );
        }
    );
};
