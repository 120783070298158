import {cx} from "emotion";
import React from "react";
import {cs} from "@common/react/chain-services";
import {consumeContext, provideContext} from "@common/react/context";
import {DelayRender} from "@common/react/delay-render";
import {keyed} from "@common/react/keyed";
import {UseState} from "@common/react/use-state";
import {ObserveWindowWidth} from "@common/ui-components/live/live-dashboard/live-filters/layout/observe-window-width";
import "./adding-guidance.scss";
import {Step1} from "./steps/step1";
import {Step2} from "./steps/step2";
import {Step3} from "./steps/step3";

export const AddingGuidance = ({dataSource, showTutorial}) =>
    cs(
        consumeContext("routing"),
        consumeContext("apis"),
        consumeContext("auth"),
        [
            "windowSize",
            (_, next) =>
                ObserveWindowWidth({
                    getSize: (vw) => (vw < 1600 ? "medium" : "large"),
                    next,
                }),
        ],
        [
            "open",
            ({auth, windowSize}, next) => {
                return UseState({next, initValue: windowSize === "large"});
            },
        ],
        ["step", (_, next) => UseState({next, initValue: 0})],
        ["toggleModal", ({open, apis, auth, windowSize}, next) => next(() => windowSize === "medium" && open.onChange(!open.value))],
        ({open, step, folders, toggleModal}) => {
            const steps = [
                {
                    title: `Configure ${dataSource.structured ? `Tables` : `Objects`} required for your model and reports`,
                    component: <Step1 />,
                    width: 396,
                    height: 570,
                },
                {
                    title: "Next, set the Sync Methods and Sync Intervals",
                    component: <Step2 />,
                    width: 396,
                    height: 456,
                },
                ...[
                    {
                        title: "Click Save & Done — so Verb can begin an initial full sync",
                        component: <Step3 />,
                        width: 396,
                        height: 550,
                    },
                ],
            ];

            const selectedStep = steps[step.value];

            return (
                <div
                    className="model-tutorial-a23"
                    // style={{top: lowPosition ? 70 : 20}}
                >
                    <div
                        className={`tutorial-content ${open.value ? "expanded" : "minimize"}`}
                        style={{
                            width: open.value ? selectedStep.width : 60,
                            height: open.value ? selectedStep.height : 60,
                        }}
                    >
                        <div className={cx("expanded-title")}>{selectedStep.title}</div>

                        <div className="expanded-body">
                            {open.value &&
                                cs(
                                    keyed(step.value),
                                    ({}, next) => DelayRender({delay: 200, next}),
                                    () =>
                                        React.cloneElement(selectedStep.component, {
                                            showTutorial,
                                            structured: dataSource.structured,
                                            isLastStep: steps.length - 1 === step.value,
                                            onNext: () => step.onChange(step.value + 1),
                                            onBack: () => step.onChange(step.value - 1),
                                            onClose: () => open.onChange(false),
                                            onGoToStep: (stepIndex) => step.onChange(stepIndex),
                                        })
                                )}
                        </div>

                        <div className="close-btn" onClick={() => toggleModal()}>
                            <i className="fas fa-chevron-up" />
                        </div>
                    </div>
                </div>
            );
        }
    );
