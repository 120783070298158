import "./create-collection-dialog.scss";

import * as React from "react";

import {Load} from "@common/react/load";
import {Load2} from "@common/react/load2";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {Form2} from "@common/react/cs-form/form2";
import {DialogService} from "../../../../../common/dialog/dialog-service";
import {consumeContext} from "@common/react/context";
import moment from "moment";

import {Button} from "@common/form/buttons/button/button";

import {LoadingIndicator} from "@common/ui-components/loading-indicator/loading-indicator";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";

import {isDataOrSparkCollection} from "../../../../../getting-started/build-data/create-dashboards/create-collection-with-env-dialog";

import {ChooseCollectionType} from "./choose-type/choose-type";
import {OtherCollectionFields} from "./other-fields/other-collection-fields";
import {collectionFormConfig} from "./collection-form-config";
import {recentStorage} from "../../../recent-section/recent-storage";

export const CreateCollectionDialog = ({folders, model, next: rootNext, environment}) =>
    cs(
        consumeContext("apis"),
        consumeContext("dataSummary"),
        [
            "models",
            ({apis, routing}, next) => {
                return Load2({
                    fetch: () => apis.model.getModels({namesOnly: true}),
                    next,
                });
            },
        ],
        [
            "modal",
            (_, next) =>
                DialogService({
                    render: ({resolve}) => ({
                        title: "Create Collection",
                        width: 540,
                        content: next({
                            resolve,
                        }),
                    }),
                    next: rootNext,
                }),
        ],

        consumeContext("routing"),
        consumeContext("apis"),
        [
            "themes",
            ({apis}, next) =>
                Load({
                    fetch: () => apis.collection.getThemes(),
                    next,
                }),
        ],
        ({models, themes}, next) => {
            if (models.loading || !themes) {
                return <div style={{minHeight: 50}}>{LoadingIndicator()}</div>;
            } else return next();
        },
        [
            "collection",
            ({themes, models}, next) => {
                return UseState({
                    initValue: {
                        modelID: model?.id ?? (models.value?.length == 1 ? models.value[0].id : null),
                        folderID: null,
                        ...(!!environment?.readOnly
                            ? {
                                  modelID: models.value[0]?.id,
                                  folderID: folders[0].id,
                              }
                            : {}),
                    },
                    next,
                });
            },
        ],

        [
            "form",
            ({collection, dataSummary, apis, routing, modal}, next) =>
                Form2({
                    data: collection,
                    ...collectionFormConfig({
                        apis,
                        collection: collection.value,
                    }),
                    onSubmit: async (newFolderId) => {
                        const defaultDataByCollectionTypes = {
                            ApiCollection: {
                                autoDataRefresh: dataSummary.isLessThan1GB ? null : false,
                                tile: {
                                    title: "API",
                                },
                                version: "1.0",
                            },
                            DashboardCollection: {
                                autoDataRefresh: dataSummary.isLessThan1GB ? null : false,
                                filterStyle: {
                                    showLabels: true,
                                },
                            },
                            SparkCollection: {
                                autoDataRefresh: dataSummary.isLessThan1GB ? null : false,
                                hideAllFilters: true,
                                viewWidthPixels: 200,
                                viewHeightPixels: 200,
                            },
                        };

                        const newCol = await apis.collection.upsertCollection({
                            ...collection.value,
                            ...(newFolderId && {
                                folderID: newFolderId,
                            }),
                            ...defaultDataByCollectionTypes[collection.value.$type],
                        });

                        if (!newCol?.id) {
                            return;
                        }

                        recentStorage.add({
                            envId: routing.params?.envId,
                            type: "collection",
                            item: {
                                ...newCol,
                                type: collection.value.$type,
                                versionInfo: {
                                    editedOnUtc: moment.utc().toISOString(),
                                },
                            },
                            path: folders.filter((f) => f.id === newCol.folderID),
                        });

                        modal.resolve();

                        routing.goto("edit-collection", {
                            colId: newCol.id,
                            collectionTab: collection.value.$type == "ApiCollection" ? "fields" : null,
                        });
                    },
                    next,
                }),
        ],

        [
            "chooseType",
            ({collection, themes}, next) =>
                ChooseCollectionType({
                    onChoose: (type) => {
                        let newCollection = {
                            ...(collection.value || {}),
                            $type: type,
                        };
                        if (themes?.length > 0 && isDataOrSparkCollection(newCollection)) {
                            newCollection.themeID = (themes.find((t) => t.default) || themes[0]).id;
                        }

                        collection.onChange(newCollection);
                    },
                    next,
                }),
        ],
        [
            "otherFields",
            ({collection, form, themes, models}, next) =>
                OtherCollectionFields({
                    models,
                    folders,
                    collection,
                    form,
                    themes,
                    next,
                }),
        ],

        ({chooseType, otherFields, collection, modal, models}) => {
            if (!models.value || models.value?.length === 0) {
                return (
                    <div className="remove-block-dialog-a32">
                        <VerbDialogBodyScrollbar>
                            {!models.value && LoadingIndicator()}
                            {models.value?.length === 0 && (
                                <div className="messages">
                                    <div className="message">Before you can create a collection you must create a model with at least 1 table.</div>
                                </div>
                            )}
                        </VerbDialogBodyScrollbar>
                    </div>
                );
            }

            return (
                <div className="create-collection-dialog-2df">
                    <VerbDialogBodyScrollbar>{!collection.value.$type ? chooseType.main : otherFields.main}</VerbDialogBodyScrollbar>

                    <div className="buttons">
                        <Button btnType="secondary" onClick={() => modal.resolve()}>
                            Cancel
                        </Button>

                        {!collection.value.$type ? chooseType.button : otherFields.button}
                    </div>
                </div>
            );
        }
    );
