import "./design-theme-buttons-auto.scss";

import {css} from "emotion";

import {DateFilterExpand} from "@common/ui-components/live/live-dashboard/live-filters/filter-types/date/expand/date-filter-expand";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {provideContext} from "@common/react/context";
import {DropdownMultiSelectSearchLive} from "@common/ui-components/live/filters/common/dropdown-select/dropdown-multi-select-search-live";
import {Checkboxes} from "@common/ui-components/live/live-dashboard/live-filters/filter-types/text/selectable-list/checkboxes/checkboxes";
import {ButtonGroup} from "@common/ui-components/live/live-dashboard/live-filters/filter-types/text/selectable-list/button-group/button-group";
import {RunReportButton} from "@common/ui-components/live/live-dashboard/live-filters/live-filters";
import {MoreFilterButton} from "@common/ui-components/live/live-dashboard/live-filters/layout/live-filters-desktop/live-filters-desktop";
import {DownloadButton} from "@common/ui-components/live/live-dashboard/live-filters/download-button/download-button";
import {DownloadReportButton} from "@common/ui-components/charts/downloadable-report/download-button/download-report-button";

export const DesignThemeButtonsAuto = ({theme}) =>
    cs(
        (_, next) => provideContext("theme", theme, next),
        [
            "dateFilterState",
            (_, next) =>
                UseState({
                    next,
                    initValue: {
                        main: {
                            from: {
                                year: 2022,
                                month: 4,
                                day: 8,
                                hours: 0,
                                minutes: 0,
                                seconds: 0,
                            },
                            to: {
                                year: 2022,
                                month: 4,
                                day: 15,
                                hours: 0,
                                minutes: 0,
                                seconds: 0,
                            },
                        },
                        columns: [
                            {
                                modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                                modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                                modelColumnID: "5035cb8a-55ef-45e5-9772-4a2a019d89b4",
                                displayName: null,
                                default: true,
                            },
                        ],
                        comparing: {
                            to: {
                                year: 2022,
                                month: 4,
                                day: 8,
                                hours: 0,
                                minutes: 0,
                                seconds: 0,
                            },
                            from: {
                                year: 2022,
                                month: 4,
                                day: 1,
                                hours: 0,
                                minutes: 0,
                                seconds: 0,
                            },
                        },
                        enableComparing: true,
                    },
                }),
        ],
        ["dropdownState", (_, next) => UseState({next})],
        ["checkboxesState", (_, next) => UseState({next, initValue: []})],
        ({dateFilterState, dropdownState, checkboxesState}) => {
            const componentTheme = theme.general.components;

            const cssClass = css`
                background: ${componentTheme.menuBackgroundColorRGB};
                color: ${componentTheme.menuTextColorRGB};
            `;

            return (
                <div
                    className="design-theme-buttons-auto"
                    style={{
                        background: theme.general.canvas.backgroundColorRGB,
                    }}
                >
                    <div className="text-description">Filter buttons / Check Boxes</div>

                    <div className="filter-buttons-wrapper">
                        <div className="col">
                            {DateFilterExpand({
                                size: "desktop",
                                state: dateFilterState,
                                onClose: () => {},
                                showApplyBtn: true,
                                filter: {
                                    id: "37c3b0fa-7e7a-4182-b2d8-ce212963eca0",
                                    options: "Last7Days, Last30Days, Custom, Last90Days",
                                    defaultOption: "Last7Days",
                                    customRange: {
                                        dateStart: "2018-08-01T00:00:00Z",
                                        dateEnd: "2018-08-31T00:00:00Z",
                                        $type: "InRangeCustomDateFilterRange",
                                    },
                                    compareEnabled: true,
                                    compareOnByDefault: true,
                                    compareDefaultRange: null,
                                    label: "Date Placed Utc (Order)",
                                    showLabelInfoIcon: false,
                                    labelInfoIconText: null,
                                    columns: [
                                        {
                                            modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                                            modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                                            modelColumnID: "5035cb8a-55ef-45e5-9772-4a2a019d89b4",
                                            displayName: null,
                                            default: true,
                                        },
                                    ],
                                    multiSelectionOption: "SingleSelect",
                                    required: false,
                                    displayArea: "Visible",
                                    disconnectedTiles: ["1a4e2874-981d-4fcc-bd01-b120bfada0b4", "665898ab-4112-4c4b-b67c-ea720fb38434"],
                                    $type: "DateFilter",
                                },
                            })}

                            <div className="text-label">Date Picker</div>
                        </div>

                        <div className="col">
                            {DropdownMultiSelectSearchLive({
                                list: ["Item #1", "Item #2", "Item #3"],
                                onChange: (v) => dropdownState.onChange(v),
                                isSelected: (v) => dropdownState.value === v,
                                onSelect: (v) => dropdownState.onChange(v),
                                previewMode: true,
                                checkboxBackground: theme.general.buttons.primaryButton.backgroundColorRGB,
                                iconColor: componentTheme.inputIconColorRGB,
                                dropdownCss: cssClass,
                                borderRadius: componentTheme.menuCornerRadius,
                            })}

                            <div className="text-label">Multi Select Dropdown</div>
                        </div>

                        <div className="col">
                            <div className="checkboxes">
                                <div className="checkboxes-label">Label</div>

                                <div className="checkboxes-border">
                                    {Checkboxes({
                                        data: ["Item #1", "Item #2", "Item #3"],
                                        theme,
                                        state: checkboxesState,
                                    })}
                                </div>

                                <div className="text-label">Checkbox</div>
                            </div>

                            <div className="checkboxes">
                                <div className="checkboxes-label">Label</div>

                                {ButtonGroup({
                                    data: ["Item #1", "Item #2", "Item #3"],
                                    theme,
                                    state: checkboxesState,
                                    isToggle: true,
                                })}

                                <div className="text-label">Button Toggle</div>
                            </div>

                            <div className="checkboxes">
                                <div className="checkboxes-label">Label</div>

                                {ButtonGroup({
                                    data: ["Item #1", "Item #2", "Item #3"],
                                    theme,
                                    state: checkboxesState,
                                })}

                                <div className="text-label">Button Group</div>
                            </div>
                        </div>
                    </div>

                    <div className="buttons-wrapper">
                        <div className="text-description">Buttons</div>

                        <div className="button-list-wrapper">
                            <div className="col">
                                <RunReportButton theme={theme} onRunReport={() => {}}>
                                    Primary Button
                                </RunReportButton>

                                <div className="text-label">Filter Submit</div>
                            </div>

                            <div className="col">
                                <MoreFilterButton onClick={() => {}}>Secondary Button</MoreFilterButton>

                                <div className="text-label">More Filters</div>
                            </div>

                            <div className="col">
                                {DownloadButton({
                                    editMode: false,
                                })}

                                <div className="text-label">Download</div>
                            </div>

                            <div className="col">
                                <div className="download-button-border">
                                    {DownloadReportButton({
                                        tile: {
                                            id: "d460ced4-fdab-4cf8-8eb3-2e99853a9925",
                                            actualValueFields: [
                                                {
                                                    id: "35284d87-b914-497a-9d1e-fe0043d37573",
                                                    modelID: "2863ada0-8e18-4384-a291-c19a7f417b62",
                                                    modelTableID: "1865488f-dbe6-4d15-97a8-a6525a4901c3",
                                                    modelColumnID: "eb7424db-000f-48c5-bce2-1151b58487e2",
                                                    displayName: "ContactInfoID",
                                                    dataType: "IDUUID",
                                                    numericProperties: null,
                                                    dateProperties: null,
                                                    $type: "CategoryTileField",
                                                },
                                            ],
                                            aggregatedValues: [],
                                            style: {
                                                buttonText: "Download Report",
                                                downloadFormats: "XLSX, CSV, JSON",
                                                title: {
                                                    show: false,
                                                    titleLocation: "Above",
                                                },
                                                dataColorPalette: [],
                                                showCompare: true,
                                                showDownloadData: false,
                                                downloadFormat: null,
                                                showDownloadImage: false,
                                                showTitleInfoIcon: false,
                                                titleInfoIconText: null,
                                                $type: "DownloadReportTileStyle",
                                            },
                                            title: "Downloadable Report 520",
                                            filters: [],
                                            sort: [],
                                            categorySort: null,
                                            categorySortBasis: null,
                                            limit: null,
                                            tileActions: [],
                                            fieldValidity: "Valid",
                                            $type: "DownloadReportTile",
                                        },
                                        theme,
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    );
