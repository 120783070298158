let loadedSrcs = {};

const loadScript = ({src, ...attrs}) => {
    if (loadedSrcs.hasOwnProperty(src)) {
        return loadedSrcs[src];
    }
    const promise = loadScript1({src, ...attrs});
    loadedSrcs[src] = promise;
    return promise;
};
exports.loadScript = loadScript;

const loadScript1 = (attrs) =>
    new Promise((resolve, reject) => {
        let script = document.createElement("script");
        script.type = "text/javascript";
        Object.assign(script, attrs);
        // script.src = src;
        //
        // for (const key in attrs) {
        //     script[key] = attrs[key];
        // }

        // @ts-ignore
        if (script.readyState) {
            script.onreadystatechange = () => {
                if (script.readyState === "loaded" || script.readyState === "complete") {
                    script.onreadystatechange = null;
                    resolve();
                }
            };
        } else {
            script.onload = resolve;
            script.onerror = function () {
                // console.error(arguments);
                reject(arguments);
            };
        }

        document.getElementsByTagName("head")[0].appendChild(script);
    });
