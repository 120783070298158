const {isDimRealDateTime} = require("../factory/is-dim-real-date-time");
const {getDateFilterValue} = require("../factory/get-date-filter-value");
const {getTooltipPerSeriesOption} = require("../factory/tooltip/tooltip-series-option");
const {getFieldType} = require("../common/get-field-type");

const getComboTooltipOptions = ({tile, theme, formatters, isCompare, rawData, barStacks, lineStacks}) => {
    const isDimensionDate = getFieldType(tile["xAxisField"]) === "date";
    const isDimensionRealDate = isDimRealDateTime({
        tile,
        dimensionAttr: "xAxisField",
        series: rawData.series,
    });
    const dateFilterValue = getDateFilterValue(rawData.series);
    return {
        lineTooltipOptions: getTooltipPerSeriesOption({
            tile,
            theme,
            styleMeasurementAttr: "yAxisLine",
            formatters,
            isDimensionDate,
            isDimensionRealDate,
            isCompare,
            dateFilterValue,
            isBarClassic: !tile.style.yAxisBarDisplayType || tile.style.yAxisBarDisplayType === "Classic",
            rawData: {
                series: rawData.series.filter((s) => s.isCompare && lineStacks.includes(s.stack)),
            },
        }),
        barTooltipOptions: getTooltipPerSeriesOption({
            tile,
            theme,
            styleMeasurementAttr: "yAxisBar",
            formatters,
            isDimensionDate,
            isDimensionRealDate,
            isCompare,
            dateFilterValue,
            isBarClassic: !tile.style.yAxisBarDisplayType || tile.style.yAxisBarDisplayType === "Classic",
            rawData: {
                series: rawData.series.filter((s) => s.isCompare && barStacks.includes(s.stack)),
            },
        }),
    };
};
exports.getComboTooltipOptions = getComboTooltipOptions;
