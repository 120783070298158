import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {UseState} from "@common/react/use-state";
import {noun} from "@common/utils/plural";
import {TabsHeader} from "../../../../common/tabs/tabs-header";
import {TableIcon} from "../../../common/icons";
import {formatFoldersPath, getFoldersPath} from "../../common/helpers";
import {FlatFileNameLocation} from "../../flat-file-status/import-configuration/flat-file-name-location";
import {BannerClosable, ErrorInfo, SuccessInfo} from "../banner-closable/banner-closable";
import {FlatFileColumnsMapping} from "./flat-file-columns-mapping";
import "./flat-file-mapping.scss";
import {FlatFileTablesMapping} from "./flat-file-tables-mapping";
import {ObserveDomSize} from "@common/react/observe-dom-size";
import {validImportFlatFile} from "../flat-file-mapping-helpers";

export const FlatFileMapping = ({editingDs, originalDs, attemptSubmit}) =>
    cs(
        consumeContext("dataSourceFolders"),
        ["active", (_, next) => UseState({initValue: "Tables", next})],
        ["bannerDomSize", (_, next) => ObserveDomSize({next})],
        ({active, dataSourceFolders, bannerDomSize}) => {
            return (
                <>
                    <div className="flat-file-import-configuration-88y flat-file-mapping-88p">
                        <div className="col-left">
                            <div ref={bannerDomSize.ref}>
                                {BannerClosable({
                                    error: true,
                                    forceExpand: attemptSubmit.value && !validImportFlatFile(editingDs.value),
                                    content: ErrorInfo({
                                        dataSource: editingDs.value,
                                    }),
                                })}
                            </div>

                            <div
                                className="col-left-content"
                                style={{
                                    height: `calc(100% - ${bannerDomSize.value?.height ?? 0}px)`,
                                }}
                            >
                                {TabsHeader({
                                    className: "flat-file-tabs",
                                    tabs: [
                                        {
                                            id: "Tables",
                                            label: "Tables",
                                            getNumbers: () => editingDs.value.tables.length + (editingDs.value.disabledTables || []).length,
                                        },
                                        {
                                            id: "Columns",
                                            label: "Columns",
                                            getNumbers: () => {
                                                const c1 = editingDs.value.tables.reduce((prev, item) => (prev += item.columns.length), 0);
                                                // const c2 = (dataSource.value.disabledTables || []).reduce((prev, item) => prev += item.columns.length, 0)
                                                return c1;
                                            },
                                        },
                                        {
                                            id: "Name",
                                            label: "Name & Location",
                                        },
                                    ].map((item) => ({
                                        id: item.id,
                                        label: (
                                            <>
                                                {item.label} {item.getNumbers && `(${item.getNumbers?.()})`}
                                            </>
                                        ),
                                        onClick: () => active.onChange(item.id),
                                        active: active.value === item.id,
                                    })),
                                })}

                                {active.value === "Tables" &&
                                    FlatFileTablesMapping({
                                        editingDs,
                                        originalDs,
                                    })}
                                {active.value === "Columns" &&
                                    FlatFileColumnsMapping({
                                        editingDs,
                                        originalDs,
                                    })}
                                {active.value === "Name" &&
                                    FlatFileNameLocation({
                                        dataSource: editingDs,
                                    })}
                            </div>
                        </div>

                        <div className="col-right">
                            <div className="summary-box">
                                <div className="label">
                                    <i className="fa-light fa-cloud-arrow-up"></i>
                                    {"  "}
                                    Import Summary
                                </div>

                                <div className="content">
                                    <div className="section">
                                        <div className="label">Tables</div>
                                        <div className="value">
                                            <div className="text">
                                                {editingDs.value.tables.length} to import, {(editingDs.value?.disabledTables || []).length} to exclude
                                            </div>
                                        </div>
                                    </div>
                                    <div className="section">
                                        <div className="label">Columns</div>
                                        <div className="value">
                                            <div className="text">
                                                {editingDs.value.tables.reduce((prev, item) => (prev += item.columns.length), 0)} to import,{" "}
                                                {editingDs.value.tables.reduce((prev, item) => (prev += (item.disabledColumns || []).length), 0)} to exclude
                                            </div>
                                        </div>
                                    </div>
                                    <div className="section">
                                        <div className="label">Name & Location</div>
                                        <div className="value">
                                            <div className="text">{editingDs.value.name}</div>
                                            {dataSourceFolders.value && (
                                                <div className="location">{formatFoldersPath(getFoldersPath(editingDs.value?.folderID, dataSourceFolders.value))}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {OriginalTableMappingSummary({originalDs})}
                        </div>
                    </div>
                </>
            );
        }
    );

const OriginalTableMappingSummary = ({originalDs}) =>
    cs(() => {
        const oriTables = [...originalDs.value.tables, ...(originalDs.value?.disabledTables || [])];

        return (
            <div className="orginal-mapping-summary">
                <div className="label">
                    {TableIcon({fill: "#919eb0"})}

                    <div className="text">Original File Table Mapping</div>
                </div>

                <div className="list">
                    {oriTables.map((t) => (
                        <div className="item">
                            <span className="material-icons-outlined">done</span>
                            <span className="text">{t.name}</span> ({t.columns.length} {noun(t.columns.length, "column")}; {Number(t.rows)} rows)
                        </div>
                    ))}
                </div>
            </div>
        );
    });
