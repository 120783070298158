import * as React from "react";
import {cs} from "@common/react/chain-services";
import {DialogService} from "../../../../../common/dialog/dialog-service";
import {Load} from "@common/react/load";
import {UseState} from "@common/react/use-state";
import {MapFieldsForCloningCollectionDialog} from "./map-fields-for-cloning-collection-dialog";
import {SelectModel} from "./select-model";
import "./select-model-for-cloning-collection-dialog.scss";
import {LoadingIndicator} from "@common/ui-components/loading-indicator/loading-indicator";
import {consumeContext} from "@common/react/context";
import {chain} from "@common/utils/fs";
import {removeIdsInCollection} from "../../../../../common/remove-ids";
import {flatten1, unique} from "@common/utils/collections";
import {purifyCollection} from "./purify-collection";
import {Button} from "../../../../../../../../common/form/buttons/button/button";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";
import {cx} from "emotion";
import {Load2} from "@common/react/load2";

export const SelectModelForCloningCollectionDialog = ({onDone, next: rootNext}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    render: ({resolve, args: {collection, folder}}) => ({
                        title: "Select Model",
                        width: 347,
                        content: next({
                            resolve,
                            collection,
                            folder,
                        }),
                    }),
                    next: rootNext,
                }),
        ],
        consumeContext("apis"),
        consumeContext("routing"),
        [
            "fullCollection",
            ({apis, modal}, next) => {
                if (modal.collection.gridLocations || modal.collection.tile) {
                    return next(modal.collection);
                }
                return Load({
                    fetch: async () => apis.collection.getCollection(modal.collection.id),
                    next,
                });
            },
        ],
        // todo: cache this
        [
            "models",
            ({apis, routing}, next) =>
                Load({
                    fetch: () => apis.model.getModels({namesOnly: true}),
                    next,
                }),
        ],
        // todo: cache this
        [
            "folders",
            ({apis, routing}, next) =>
                Load2({
                    _key: routing.params?.envId,
                    fetch: () => apis.collection.getFolders(),
                    next,
                }),
        ],
        ["destinationModelId", ({fullCollection}, next) => UseState({next})],
        [
            "mapFieldsDialog",
            ({fullCollection, destinationModelId, modal}, next) =>
                MapFieldsForCloningCollectionDialog({
                    collection: fullCollection,
                    destinationModelId: destinationModelId.value,
                    onDone: (newCollection) => {
                        onDone(newCollection);
                        modal.resolve();
                    },
                    next,
                }),
        ],
        ["cloning", (_, next) => UseState({next})],
        ["error", (_, next) => UseState({next})],
        ({modal, fullCollection, models, folders, destinationModelId, mapFieldsDialog, apis, cloning, error}) => {
            const cloneName =
                folders.value &&
                getCloneName(
                    modal.collection.name,
                    flatten1(folders.value.map((f) => f.collections)).map((c) => c.name),
                    modal.collection.version
                );
            const loading = !fullCollection || !models || !folders.value;
            return (
                <div className="select-model-for-cloning-dialog-24f">
                    <VerbDialogBodyScrollbar noPadding className={cx({loading})}>
                        {error.value && (
                            <div className="messages">
                                <div className="message error">Sorry, there is an issue cloning this collection.</div>
                            </div>
                        )}
                        {loading
                            ? LoadingIndicator({})
                            : SelectModel({
                                  list: models,
                                  isSelected: (id) => id === (destinationModelId.value || fullCollection.modelID),
                                  onSelect: (id) => destinationModelId.onChange(id),
                              })}
                    </VerbDialogBodyScrollbar>
                    <div className="buttons">
                        <Button
                            disabled={!fullCollection || !models || cloning.value}
                            onClick={async () => {
                                if (!destinationModelId.value || destinationModelId.value === fullCollection.modelID) {
                                    const cloneCollection = chain(
                                        {
                                            ...purifyCollection(fullCollection),
                                            name: cloneName,
                                        },
                                        (col) => removeIdsInCollection(col)
                                    );
                                    try {
                                        cloning.onChange(true);
                                        const newCollection = await apis.collection.upsertCollection(cloneCollection);
                                        onDone(newCollection);
                                        modal.resolve();
                                    } catch (e) {
                                        error.onChange(e.message);
                                        cloning.onChange(false);
                                    }
                                } else {
                                    mapFieldsDialog.show({cloneName});
                                }
                            }}
                        >
                            Clone
                        </Button>
                    </div>
                </div>
            );
        }
    );

export const getCloneName = (oldName, existingNames, version) => {
    let uniqueNames = unique(existingNames);
    const copyNumber = uniqueNames.filter((n) => n.includes(oldName)).length;

    let getNewName = (copyNumber) => oldName + (version ? ` Version ${version}` : "") + " copy" + (copyNumber > 1 ? ` ${copyNumber}` : "");
    const getUniqueNewName = (copyNumber) => {
        const newName = getNewName(copyNumber);
        if (!uniqueNames.includes(newName)) return newName;
        return getUniqueNewName(copyNumber + 1);
    };

    return getUniqueNewName(copyNumber);
};
