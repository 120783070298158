import React from "react";
import {cs} from "@common/react/chain-services";
import {cx} from "emotion";
import "./mobile-block-view.scss";

export const MobileBlockView = ({}) =>
    cs(() => (
        <div className={cx("guest-layout-7d3 mobile-block-view-9io")}>
            <div className="header">
                <div className="container">
                    <div className="logo">Verb</div>
                </div>
            </div>

            <div className="mobile-block-view">
                <div className="text">
                    Hey there, <br />
                    We're working on optimizing our admin for small screens.
                    <br />
                    <div className="login">For now, please expand your window or use a larger device.</div>
                </div>

                <button className="back-btn" onClick={() => window.open(`https://revivemedia.us`)}>
                    Back to Homepage
                </button>
            </div>
        </div>
    ));
