import "./delete-theme-dialog.scss";
import * as React from "react";
import {cs} from "@common/react/chain-services";
import {DialogService} from "../../../../common/dialog/dialog-service";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {UseState} from "@common/react/use-state";
import {Button} from "@common/form/buttons/button/button";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";
import {TextInput} from "@common/form/text-input/text-input";
import {consumeContext} from "@common/react/context";

export const DeleteThemeDialog = ({themes: allThemes, next: rootNext, onDone, reloadFolderOnDone = false}) =>
    cs(
        consumeContext("apis"),
        consumeContext("toast"),
        [
            "modal",
            ({}, next) =>
                DialogService({
                    render: ({resolve, args: {themes}}) => ({
                        title: "Delete Theme?",
                        content: next({
                            resolve,
                            themes,
                        }),
                    }),
                    next: rootNext,
                }),
        ],
        ["confirmName", (_, next) => UseState({next})],
        ["submitting", (_, next) => UseState({next})],
        [
            "selectedTheme",
            ({modal}, next) =>
                UseState({
                    next,
                    initValue: modal.themes.length == 1 ? modal.themes[0] : null,
                }),
        ],
        ({modal, selectedTheme, confirmName, submitting, apis, toast}) => {
            const {themes} = modal;

            const numberOfThemes = themes.length;
            const hasManyThemes = numberOfThemes > 1;
            const isDisabled =
                (hasManyThemes ? confirmName.value !== "delete" : false) || submitting.value || !selectedTheme.value || themes.find((t) => selectedTheme.value.id == t.id);
            const message = !hasManyThemes
                ? `Before we delete this theme, please select a different theme to apply to this collection and any other collection that uses ${themes[0].name}.`
                : `Please select a different theme to apply to the collections that use the old themes.`;

            return (
                <form
                    className="leave-edit-theme-dialog-8sx"
                    onSubmit={async (e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        if (isDisabled) {
                            return;
                        }

                        submitting.onChange(true);
                        await Promise.all(themes.map((t) => apis.collection.deleteTheme(t.id, t.name, selectedTheme.value.id)));

                        const newFolders = reloadFolderOnDone ? await apis.theme.getFolders() : null;
                        onDone(newFolders);

                        toast.show(`${numberOfThemes === 1 ? themes[0].name : `${numberOfThemes} themes`} deleted`);

                        submitting.onChange(false);
                        modal.resolve(true);
                    }}
                >
                    <VerbDialogBodyScrollbar>
                        <div className="messages">
                            <div className="message">{message}</div>
                        </div>

                        <div className="select-alternative-theme">
                            {DropdownSelect({
                                label: "Theme Name",
                                list: allThemes,
                                onChange: (v) => selectedTheme.onChange(v),
                                isSelected: (v) => selectedTheme.value?.id === v.id,
                                valueToLabel: (v) => v.name,
                            })}
                        </div>
                        {hasManyThemes && (
                            <>
                                <div className="messages" style={{marginTop: 40}}>
                                    <div className="message">Are you sure you want to delete {themes.length} themes? </div>
                                </div>

                                {TextInput({
                                    label: <div>Please type DELETE to confirm</div>,
                                    state: confirmName,
                                })}
                            </>
                        )}
                    </VerbDialogBodyScrollbar>

                    <div className="buttons">
                        <Button btnType="secondary" onClick={() => modal.resolve()}>
                            Cancel
                        </Button>
                        <Button type="submit" disabled={isDisabled} btnType="primary-danger">
                            Delete
                        </Button>
                    </div>
                </form>
            );
        }
    );
