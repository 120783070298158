import * as React from "react";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {comparisonMethods} from "@common/ui-components/live/filters/text-filter/comparison-methods";
import {stateToSelect} from "@common/ui-components/form/state-to-select";
import {cs} from "@common/react/chain-services";
import {scope} from "@common/react/scope";
import {TextInput} from "../../../../../../../../../../common/form/text-input/text-input";
import {ffToTextInput} from "../../../../../../../../../../common/form/ff-to-text-input";
import {ShowHideToggle} from "../../../../../../../../../../common/form/toggles/show-hide-toggle";
import {omit} from "@common/utils/objects";

export const FreeFormText = ({filter, form}) =>
    cs(({}) => {
        const hasDefaultValue = Object.keys(filter.value).includes("defaultFreeFormValue");
        const currentType = filter.value?.defaultOperator && comparisonMethods.find((m) => m.name == filter.value?.defaultOperator);

        return (
            <div className="free-form-text-3j9">
                <div className="header">Filter Type</div>

                <div className="content">
                    {DropdownSelect({
                        label: "Comparison Method",
                        list: comparisonMethods,
                        valueToLabel: (item) => item.label,
                        ...stateToSelect(scope(filter, ["defaultOperator"]), ["name"]),
                    })}
                </div>

                {currentType?.hasDefaultValue && (
                    <>
                        <div className="header">
                            Default Filter Value
                            <div className="control">
                                {ShowHideToggle({
                                    state: {
                                        value: hasDefaultValue,
                                        onChange: () =>
                                            filter.onChange(
                                                hasDefaultValue
                                                    ? omit(filter.value, "defaultFreeFormValue")
                                                    : {
                                                          ...filter.value,
                                                          defaultFreeFormValue: null,
                                                      }
                                            ),
                                    },
                                })}
                            </div>
                        </div>

                        {hasDefaultValue && (
                            <div className="content">
                                {TextInput({
                                    label: "Default Value",
                                    ...ffToTextInput(form.field("defaultFreeFormValue")),
                                })}
                            </div>
                        )}
                    </>
                )}
            </div>
        );
    });
