import {createFetcher} from "../../../../tools/fetcher/fetcher";

export const createApiCollectionApis = () => {
    const fetcher = createFetcher({});

    const fetcher2 = (apiKey) =>
        createFetcher({
            headers: {
                "x-api-key": apiKey,
            },
        });

    return {
        validateToken: (token) => fetcher.put(`/api/user/tenant/data-access-api-key/validate-token`, token),
        populateDocs: (apiKey) => fetcher2(apiKey).post(`/api/render/api`),
    };
};
