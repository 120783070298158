export const BoldIcon = () => (
    <svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1.625 5.6875H5.28125C5.81997 5.6875 6.33663 5.47349 6.71756 5.09256C7.09849 4.71163 7.3125 4.19497 7.3125 3.65625C7.3125 3.11753 7.09849 2.60087 6.71756 2.21994C6.33663 1.83901 5.81997 1.625 5.28125 1.625H1.625V5.6875ZM9.75 9.34375C9.75 9.8239 9.65543 10.2993 9.47168 10.7429C9.28794 11.1865 9.01862 11.5896 8.67911 11.9291C8.33959 12.2686 7.93653 12.5379 7.49294 12.7217C7.04934 12.9054 6.5739 13 6.09375 13H0V0H5.28125C5.99703 2.99936e-05 6.69705 0.210155 7.29452 0.60432C7.89199 0.998484 8.36063 1.55935 8.64231 2.21737C8.924 2.87539 9.00635 3.60162 8.87915 4.306C8.75195 5.01038 8.42079 5.66194 7.92675 6.17987C8.4814 6.50105 8.94184 6.96245 9.26185 7.51778C9.58187 8.0731 9.75021 8.70282 9.75 9.34375ZM1.625 7.3125V11.375H6.09375C6.63247 11.375 7.14913 11.161 7.53006 10.7801C7.91099 10.3991 8.125 9.88247 8.125 9.34375C8.125 8.80503 7.91099 8.28837 7.53006 7.90744C7.14913 7.52651 6.63247 7.3125 6.09375 7.3125H1.625Z"
            fill="currentColor"
        />
    </svg>
);

export const StrikeIcon = () => (
    <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.5001 8.125C11.687 8.54425 11.7812 9.01062 11.7812 9.5225C11.7812 10.6129 11.3555 11.466 10.5048 12.0794C9.6525 12.6929 8.47681 13 6.97613 13C5.64363 13 4.32494 12.6904 3.01925 12.0705V10.2375C4.25425 10.9501 5.51769 11.3068 6.81038 11.3068C8.88306 11.3068 9.92225 10.712 9.92956 9.52169C9.93385 9.28035 9.88943 9.04063 9.79897 8.81684C9.70851 8.59306 9.57386 8.38981 9.40306 8.21925L9.30556 8.12419H0V6.49919H14.625V8.12419H11.5001V8.125ZM8.18675 5.6875H3.76106C3.61874 5.55773 3.48797 5.41582 3.37025 5.26337C3.01925 4.81 2.84375 4.26237 2.84375 3.61725C2.84375 2.613 3.22237 1.75906 3.97881 1.05544C4.73688 0.351812 5.90769 0 7.49288 0C8.68806 0 9.83206 0.2665 10.9233 0.7995V2.548C9.94825 1.98981 8.87981 1.71112 7.71712 1.71112C5.70213 1.71112 4.69544 2.3465 4.69544 3.61725C4.69544 3.9585 4.87256 4.25587 5.22681 4.51019C5.58106 4.7645 6.01819 4.96681 6.53737 5.11956C7.04112 5.26581 7.59119 5.45594 8.18675 5.6875Z"
            fill="currentColor"
        />
    </svg>
);

export const ItalicIcon = () => (
    <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.5 13H0V11.375H2.37819L4.09744 1.625H1.625V0H8.125V1.625H5.74681L4.02756 11.375H6.5V13Z" fill="currentColor" />
    </svg>
);

export const HeadingIcon = () => (
    <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.11765 1.52941V13H4.58823V1.52941H0V0H10.7059V1.52941H6.11765ZM12.2353 7.64706V13H10.7059V7.64706H8.41176V6.11765H14.5294V7.64706H12.2353Z"
            fill="currentColor"
        />
    </svg>
);

export const ListIndentIcon = () => (
    <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3.82353 0.382353H13.7647V1.91176H3.82353V0.382353ZM0 0H2.29412V2.29412H0V0ZM0 5.35294H2.29412V7.64706H0V5.35294ZM0 10.7059H2.29412V13H0V10.7059ZM3.82353 5.73529H13.7647V7.26471H3.82353V5.73529ZM3.82353 11.0882H13.7647V12.6176H3.82353V11.0882Z"
            fill="currentColor"
        />
    </svg>
);

export const ListNumberIcon = () => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3.88889 0.777778H14V2.33333H3.88889V0.777778ZM1.55556 0V2.33333H2.33333V3.11111H0V2.33333H0.777778V0.777778H0V0H1.55556ZM0 8.55556V6.61111H1.55556V6.22222H0V5.44444H2.33333V7.38889H0.777778V7.77778H2.33333V8.55556H0ZM1.55556 12.8333H0V12.0556H1.55556V11.6667H0V10.8889H2.33333V14H0V13.2222H1.55556V12.8333ZM3.88889 6.22222H14V7.77778H3.88889V6.22222ZM3.88889 11.6667H14V13.2222H3.88889V11.6667Z"
            fill="currentColor"
        />
    </svg>
);

export const BlockQuoteIcon = () => (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 0H11.76V1.52941H3V0ZM3 11.4706H9.27082V13H3V11.4706ZM3 7.64706H11.76V9.17647H3V7.64706ZM3 3.82353H9.27082V5.35294H3V3.82353Z" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M0 13L0 0L1.5 0L1.5 13H0Z" fill="currentColor" />
    </svg>
);

export const LinkIcon = () => (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.6904 8.55668L9.64935 7.51562L10.6904 6.47456C10.9639 6.20109 11.1808 5.87642 11.3288 5.51911C11.4768 5.16179 11.553 4.77883 11.553 4.39207C11.553 4.00532 11.4768 3.62235 11.3288 3.26504C11.1808 2.90772 10.9639 2.58306 10.6904 2.30959C10.4169 2.03611 10.0923 1.81918 9.73496 1.67117C9.37765 1.52317 8.99468 1.44699 8.60793 1.44699C8.22117 1.44699 7.83821 1.52317 7.48089 1.67117C7.12358 1.81918 6.79891 2.03611 6.52544 2.30959L5.48438 3.35065L4.44332 2.30959L5.48438 1.26852C6.3151 0.451225 7.43512 -0.00470854 8.60048 3.66721e-05C9.76583 0.00478189 10.8821 0.469822 11.7061 1.29386C12.5302 2.1179 12.9952 3.23417 13 4.39952C13.0047 5.56488 12.5488 6.6849 11.7315 7.51562L10.6904 8.55668ZM8.60756 10.6395L7.5665 11.6806C7.15753 12.0963 6.6703 12.4269 6.13292 12.6533C5.59554 12.8798 5.01864 12.9976 4.4355 13C3.85236 13.0023 3.27452 12.8892 2.73532 12.6672C2.19611 12.4451 1.7062 12.1185 1.29386 11.7061C0.881515 11.2938 0.554891 10.8039 0.33283 10.2647C0.110768 9.72548 -0.00233786 9.14764 3.66249e-05 8.5645C0.00241111 7.98136 0.120219 7.40447 0.346664 6.86708C0.57311 6.3297 0.903712 5.84247 1.3194 5.4335L2.36046 4.39244L3.40152 5.4335L2.36046 6.47456C2.08698 6.74804 1.87005 7.0727 1.72205 7.43002C1.57404 7.78733 1.49787 8.1703 1.49787 8.55705C1.49787 8.9438 1.57404 9.32677 1.72205 9.68409C1.87005 10.0414 2.08698 10.3661 2.36046 10.6395C2.63394 10.913 2.9586 11.1299 3.31591 11.278C3.67323 11.426 4.0562 11.5021 4.44295 11.5021C4.8297 11.5021 5.21267 11.426 5.56998 11.278C5.9273 11.1299 6.25196 10.913 6.52544 10.6395L7.5665 9.59848L8.60756 10.6395ZM8.60756 3.35065L9.64935 4.39244L4.44332 9.59774L3.40152 8.55668L8.60756 3.35138V3.35065Z"
            fill="currentColor"
        />
    </svg>
);
