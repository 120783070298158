import {cx} from "emotion";
import React from "react";
import {cs} from "@common/react/chain-services";
import "./alert-message.scss";

//type: info, error

export const AlertMessage = ({type = "info", message, alertStyle = {}}) =>
    cs(() => (
        <div className={cx("alert-message-3io alert-message", `${type}-message`)} style={alertStyle}>
            <span className="material-icons">warning</span>
            <div>{message}</div>
        </div>
    ));
