import {sum} from "../../../../utils/collections";
import {getTextWidth} from "../../render-chart/render-chart-helper";

export const getChildGroup = (header, parentID) => {
    if (!header) return false;
    return header.find((group) => group.parentID == parentID);
};

export const findChildRelated = (headers, currentIndex, parentID) => {
    let total = 0;
    let index = currentIndex;

    while (true) {
        let childGroup = headers[index];
        if (!childGroup) break;
        for (let group of childGroup) {
            if (group.parentID == parentID) {
                total += sum(group.items, (item) => item.colSpan);
            }
        }
        index++;
    }

    return total;
};

export const getCollapsibleHeaderColumns = (headers) => {
    let ret = [];
    for (let i = 0; i < headers.length; i++) {
        let header = headers[i];
        for (let group of header) {
            for (let item of group.items) {
                const haveChild = getChildGroup(headers[i + 1], item.id);
                if (haveChild) {
                    ret.push(item.id);
                }
            }
        }
    }

    return ret;
};

export const getMaxWidthLeftColumn = (rows, fontSize) => {
    let maxVal = 0;

    for (let row of rows) {
        let currentWidth = 16 * row.deep + (getTextWidth(fontSize, row.name) + 60) + 20;
        if (currentWidth > maxVal) {
            maxVal = currentWidth;
        }
    }

    return maxVal;
};
