import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {SyncStatusTable} from "./table/sync-status-table";
import {SyncStatusSummary} from "./summary/sync-status-summary";
import {SyncErrors} from "./errors/sync-errors";
import {UseState} from "@common/react/use-state";
import "./edit-sync-status.scss";
import {ForceSyncDialog} from "../../common/force-sync-dialog/force-sync-dialog";
import {ObserveDomSize} from "@common/react/observe-dom-size";
import {SyncStatusTableSkeletons} from "./table/sync-status-table-skeletons";
import {SyncStatusSummarySkeletons} from "./summary/sync-status-summary-skeletons";

export const EditSyncStatus = ({dsSettings, tablesStatus, dataSource, onReload}) =>
    cs(
        consumeContext("routing"),
        consumeContext("apis"),
        consumeContext("syncingProcess"),
        ["selectedTables", (_, next) => UseState({initValue: [], next})],
        ({selectedTables, syncingProcess, apis}) => {
            if (tablesStatus.loading || dataSource.loading) {
                return (
                    <div className="edit-sync-status-8uo">
                        <div className="left-side">{SyncStatusTableSkeletons({})}</div>

                        <div className="right-side">{SyncStatusSummarySkeletons({})}</div>
                    </div>
                );
            }

            return cs(
                ["tablesErrors", (_, next) => next((dataSource.value?.errors || [])?.filter((x) => x.dataSourceTableID))],
                ["dataSourceErrors", (_, next) => next((dataSource.value?.errors || [])?.filter((x) => !x.dataSourceTableID))],
                ({tablesErrors, dataSourceErrors}) =>
                    cs(["syncScreenSize", (_, next) => ObserveDomSize({next})], ["forceSyncDialog", (_, next) => ForceSyncDialog({next})], ({syncScreenSize, forceSyncDialog}) => {
                        const {syncingProcessObserver} = syncingProcess;

                        return (
                            <div className="edit-sync-status-8uo" ref={syncScreenSize.ref}>
                                <div className="left-side">
                                    {cs(["infoRef", (_, next) => ObserveDomSize({next})], ({infoRef}) => (
                                        <>
                                            <div className="info" ref={infoRef.ref}>
                                                {dataSourceErrors.length > 0 && (
                                                    <>
                                                        {SyncErrors({
                                                            errors: dataSourceErrors,
                                                        })}
                                                        <div
                                                            style={{
                                                                height: 16,
                                                            }}
                                                        />
                                                    </>
                                                )}
                                            </div>

                                            {cs(
                                                [
                                                    "height",
                                                    (_, next) => {
                                                        const minHeight = 40 + tablesStatus.value.tables.length * 56;
                                                        const maxHeight = syncScreenSize.value?.height - (infoRef.value?.height ?? 0) - 40;
                                                        return next(maxHeight >= minHeight ? minHeight : maxHeight);
                                                    },
                                                ],
                                                ({height}) =>
                                                    SyncStatusTable({
                                                        dataSource,
                                                        style: {
                                                            height: `${height}px`,
                                                        },
                                                        dsId: dataSource.value.id,
                                                        dsSettings,
                                                        selectedTables,
                                                        data: tablesStatus.value.tables,
                                                        tablesSyncing: syncingProcessObserver.tablesSyncing || [],
                                                        tablesErrorIDs: tablesErrors.map((t) => t.dataSourceTableID),
                                                        forceSyncDialog,
                                                        onReload,
                                                    })
                                            )}
                                        </>
                                    ))}
                                </div>
                                <div className="right-side">
                                    {SyncStatusSummary({
                                        dataSource,
                                        dataSourceErrors,
                                        tablesErrorIDs: tablesErrors.map((t) => t.dataSourceTableID),
                                        selectedTables,
                                        forceSyncDialog,
                                        tablesStatus,
                                        tablesSyncing: syncingProcessObserver.tablesSyncing || [],
                                        onReload,
                                    })}
                                </div>
                            </div>
                        );
                    })
            );
        }
    );
