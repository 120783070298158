import { cs } from "@common/react/chain-services";
import { csArr } from "@common/react/cs-arr";
import { keyed } from "@common/react/keyed";
import { filterTypes } from "@common/ui-components/live/live-dashboard/live-filters/filter-types/filter-types";
import { pick } from "@common/utils/objects";
import { isSameFilter } from "../data/filter-forms/filter-forms";
import { initFilterState } from "../data/filter-forms/init-filter-state/init-filter-state";

export const getFiltersConfig = ({apiKey, collection, hiddenFilterIds, sdkDashboard, isLiveDashboard, next}) =>
    cs(() => {
        const visibleFilters = collection.value.filters.filter((f) => (hiddenFilterIds || []).indexOf(f.id) === -1);
        const dateTimeFilters = collection.value.filters.filter((f) => f.$type === "DateFilter");
        const numDateFiltersBehindMore = dateTimeFilters.filter((f) => f.displayArea === "BehindMore").length;
        const numDateFiltersVisible = dateTimeFilters.filter((f) => f.displayArea === "Visible").length;

        const showTimezoneDropdownInDateRangePopup =
            collection.value?.showTimezoneConversion && (numDateFiltersBehindMore === 0 && numDateFiltersVisible === 1);
        return next({
            visibleFilters,
            showTimezoneDropdownInDateRangePopup,
            sdkDashboard,
            isLiveDashboard,
            isNestedFilters: isLiveDashboard || isLiveDashboard,
            apiKey,
            collection,
        });
    });

export const getCollectionFilters = ({filterForms, filterStyle, filtersConfig, next}) =>
    cs(
        [
            "filters",
            (_, next) => {
                const {visibleFilters, showTimezoneDropdownInDateRangePopup, isNestedFilters, apiKey, collection} = filtersConfig;

                return csArr(
                    visibleFilters.map(
                        (filter) => (next) =>
                            cs(
                                keyed(
                                    JSON.stringify({
                                        id: filter.id,
                                        filterStyle: collection.value.filterStyle,
                                    })
                                ),
                                [
                                    "display",
                                    (_, next) =>
                                        filterTypes[filter.$type]({
                                            filterStyle: {
                                                ...collection.value.filterStyle,
                                                ...filterStyle,
                                            },
                                            apiKey,
                                            filter,
                                            form: filterForms.getForm(filter.id),
                                            collection: collection.value,
                                            showSelectTimezone: showTimezoneDropdownInDateRangePopup,
                                            next,
                                            filterValues: isNestedFilters ? filterForms.getAllFilterValues() : null,
                                            // filterValues: filterForms.getAllFilterValues(),
                                        }),
                                ],
                                ({display}) => {
                                    const filterForm = filterForms.getForm(filter.id);
                                    return next({
                                        ...display,
                                        filterValues: isNestedFilters ? filterForms.getAllFilterValues() : null,
                                        form: filterForm,
                                        resetable: () => !isSameFilter(filter.$type, filterForm?.data?.value, initFilterState(filter)),
                                        label: filter.label,
                                        reset: () => filterForms.getForm(filter.id)?.data?.onChange?.(initFilterState(filter)),
                                        hidden: filter.hidden,
                                        columns: filter.columns,
                                        id: filter.id,
                                        $type: filter.$type,
                                        displayArea: filter.displayArea,
                                        ...pick(filter, ["labelInfoIconText", "showLabelInfoIcon"]),
                                    });
                                }
                            )
                    ),
                    next
                );
            },
        ],
        ({filters}) => next(filters)
    );
