const {IconDownSvgStr} = require("./icons-svg-string");
const {IconUpSvgStr} = require("./icons-svg-string");
const {cGetFontSize} = require("../../common/style-map/font-size");
const {getCenterLabelStyles} = require("./write-center-label");
const {rectVertices} = require("../../../../utils/rectangles");
const {isRectFullyInsideCircle} = require("./adjust-fully-inside-data-labels");
const {getAdjustedLabelDims} = require("./write-center-label");
const {getTooltipBoxStyle} = require("../../factory/tooltip/tooltip-box-style");
const {chain} = require("../../../../utils/fs");

const writeComparisonCenterLabel = ({chart, theme, style, formatters}) => {
    const elemName = "donutComparisonCenterLabel";
    chart[elemName]?.destroy();

    if (!style.centerDisplay || style.centerDisplay === "Nothing") {
        return;
    }

    const formatter = style.autoRound ? formatters.valueRoundFormatter : formatters.valueFormatter;
    const mainSeries = chart.series.find((s) => !s.options.isCompare);
    const comparisonSeries = chart.series.find((s) => s.options.isCompare);

    const commonCssStr = getCenterLabelStyles(theme);

    const gFontSize = (type) =>
        cGetFontSize(
            theme.general.canvas.fontSize,
            theme
        )({
            group: style.centerLabelFontSize,
            elemType: style.centerDisplay.startsWith(type) ? "label" : "other",
        });

    const rLabel = (fontSize) => `<div style="${commonCssStr}; font-size: ${fontSize || gFontSize("Label")}px">` + `${style.centerLabel || ""}` + `</div>`;

    const rReducedLabel = (fontSize) => `<div style="font-size: ${fontSize || gFontSize("Total")}px; height: ${fontSize / 1.5}px; margin-bottom: ${fontSize / 3}px">` + `<i class="fa fa-ellipsis-h"></i>` + `</div>`;

    const rTotal = (fontSize) => {
        const size = fontSize || gFontSize("Total");
        const separatorHeight = Math.round(size / 5);

        return (
            `<div style="${commonCssStr}; font-weight: 500; font-size: ${size}px">` +
            `<div>${formatter(mainSeries.total)}</div>` +
            `<div style="height: ${separatorHeight}px; border-bottom: 1px solid #EAEAEA; margin-bottom: ${separatorHeight}px"></div>` +
            `<div>${formatter(comparisonSeries.total)}</div>` +
            `</div>`
        );
    };

    const rReducedTotal = (ratio) =>
        mainSeries.total > comparisonSeries.total
            ? `<div style="transform: scale(${ratio})">${IconUpSvgStr({
                  fill: theme.dataVisualization.dataColorPalettes.otherColors.conditionalGoodColorRGB || "#18C96E",
              })}</div>`
            : `<div style="transform: scale(${ratio})">${IconDownSvgStr({
                  fill: theme.dataVisualization.dataColorPalettes.otherColors.conditionalBadColorRGB || "#E95A5A",
              })}</div>`;

    const donutHole = {
        x: comparisonSeries.center[0] + chart.plotLeft,
        y: comparisonSeries.center[1] + chart.plotTop,
        diameter: comparisonSeries.center[3],
        radius: comparisonSeries.center[3] * 0.5,
    };

    const rContent = ({rLabel, rTotal}) =>
        `<div style="width: max-content; text-align: center">` +
        chain(
            [style.centerDisplay.includes("Label") && rLabel(), style.centerDisplay.includes("Total") && rTotal()],
            (_) => (style.centerDisplay === "LabelThenTotal" ? _ : _.reverse()),
            (_) => _.filter((v) => v).join(" ")
        ) +
        `</div>`;

    const pendingLabel = chart.renderer.text(rContent({rLabel, rTotal}), donutHole.x, donutHole.y, true).hide().add();

    const pendingDims = getAdjustedLabelDims({
        labelElem: pendingLabel,
        center: donutHole,
    });

    if (
        isRectFullyInsideCircle({
            rectVertices: rectVertices(pendingDims),
            circleCenter: donutHole,
            circleRadius: donutHole.radius,
        })
    ) {
        chart[elemName] = pendingLabel;
        chart[elemName].attr({x: pendingDims.x, y: pendingDims.y}).show();
    } else {
        const scaleRatio = Math.min(donutHole.radius / (0.5 * Math.hypot(pendingDims.width, pendingDims.height)), 1);
        const newLabelFontSize = gFontSize("Label") * scaleRatio,
            newTotalFontSize = gFontSize("Total") * scaleRatio;

        // threshold to hide total and label at once
        const fsThreshold = {
            LabelOnly: newTotalFontSize,
            LabelThenTotal: newTotalFontSize,
            TotalOnly: newLabelFontSize,
            TotalThenLabel: newLabelFontSize,
        }[style.centerDisplay];

        pendingLabel.destroy();

        chart[elemName] = chart.renderer
            .text(
                rContent({
                    rLabel: () => (fsThreshold > 10 ? rLabel(newLabelFontSize) : rReducedLabel((gFontSize("Label") * donutHole.diameter) / 60)),
                    rTotal: () => (fsThreshold > 10 ? rTotal(newTotalFontSize) : rReducedTotal(donutHole.diameter / 60)), // base for this ratio: d = 60, icon width = 18
                }),
                donutHole.x,
                donutHole.y,
                true
            )
            .hide()
            .add();

        const {x, y} = getAdjustedLabelDims({
            labelElem: chart[elemName],
            center: donutHole,
        });

        if (fsThreshold > 10) {
            chart[elemName]
                .attr({
                    x,
                    y: y - (style.centerDisplay === "TotalThenLabel" ? chart[elemName].getBBox().height / 8 : 0), // this y is weird too
                })
                .show();
        } else {
            // the y of this elem is weird so i use this offset scheme, investigate more when refactor
            const yOffset = () => {
                const {height} = chart[elemName].getBBox();
                return {
                    LabelOnly: height,
                    LabelThenTotal: height / 4,
                    TotalOnly: height / 2,
                    TotalThenLabel: height / 4,
                }[style.centerDisplay];
            };
            chart[elemName]
                .attr({
                    x,
                    y: y + yOffset(),
                })
                .show();

            const tooltipContent =
                `<div style="${getTooltipBoxStyle({
                    theme,
                })}; cursor: default">` +
                rContent({rLabel: () => rLabel(12), rTotal: () => rTotal(12)}) +
                `</div>`;

            let centerTooltip = null;

            const getTooltipPos = () => {
                const {x, y, width} = chart[elemName].element.children[0].getBoundingClientRect();
                return {
                    left: x + width / 2,
                    bottom: window.innerHeight - y - 5,
                };
            };

            chart[elemName].element.children[0].addEventListener("mouseenter", (e) => {
                const tooltipPos = getTooltipPos();
                centerTooltip = document.createElement("div");
                centerTooltip.style.position = "fixed";
                centerTooltip.style.bottom = `${tooltipPos.bottom}px`;
                centerTooltip.style.left = `${tooltipPos.left}px`;
                centerTooltip.style.transform = `translateX(-50%)`;
                centerTooltip.style.zIndex = 5;
                centerTooltip.innerHTML = tooltipContent;
                document.body.appendChild(centerTooltip);
            });

            chart[elemName].element.children[0].addEventListener("mouseleave", (e) => {
                document.body.removeChild(centerTooltip);
            });
        }
    }
};
exports.writeComparisonCenterLabel = writeComparisonCenterLabel;
