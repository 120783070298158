const {changeIndex} = require("../../../../../../../../../../../common/utils/collections");
const {removeIndex} = require("../../../../../../../../../../../common/utils/collections");
const {setPath} = require("@common/utils/arr-path");
const {getColumnType} = require("./select-field/select-field");

const updateSingleFieldInTile = ({$type, tileFields, tile, fieldAttr, fieldVal, updateSorting, updateLimit, updateTileActions}) => {
    let nValue = fieldVal.newVal;

    let newTile = {
        ...tile.value,
        ...(updateSorting ? updateSorting({tileFields, tile, fieldVal}) : {}),
        ...(updateLimit ? updateLimit({tile, fieldVal}) : {}),
        ...(updateTileActions ? updateTileActions({tile, fieldVal}) : {}),
    };

    newTile = setPath(
        newTile,
        Array.isArray(fieldAttr) ? fieldAttr : [fieldAttr],
        nValue
            ? {
                ...nValue,
                ...(nValue.$type === "CategoryTileField" ? {isDateGroupCategory: getColumnType(nValue) === "date"} : {}),
            }
            : null
    );

    return tile.onChange(newTile);
};

exports.updateSingleFieldInTile = updateSingleFieldInTile;

const updateMeasureFieldsGroupsInTile = ({tileFields, tile, fieldAttr, fieldVal, indexes: {groupIndex, fieldIndex}, updateSorting, updateLimit}) => {
    if (fieldVal.newVal == null) {
        const subFields = tile.value[fieldAttr][groupIndex]["measureFields"];
        const removedItem = tile.value[fieldAttr][groupIndex]["measureFields"][fieldIndex];

        return tile.onChange({
            ...tile.value,
            [fieldAttr]:
                subFields.length > 1
                    ? changeIndex(groupIndex, tile.value[fieldAttr], (item) => ({
                          ...item,
                          measureFields: removeIndex(fieldIndex, item["measureFields"]),
                      }))
                    : removeIndex(groupIndex, tile.value[fieldAttr]),
            ...(updateSorting
                ? updateSorting({
                      tileFields,
                      tile,
                      fieldVal: {newVal: null, oriVal: removedItem},
                  })
                : {}),
            ...(updateLimit
                ? updateLimit({
                      tile,
                      fieldVal: {newVal: null, oriVal: removedItem},
                  })
                : {}),
        });
    }
    //handle remove field
};

exports.updateMeasureFieldsGroupsInTile = updateMeasureFieldsGroupsInTile;

const updateLimitSortingInTile = ({ tile, limit, updateSorting }) =>
  tile.onChange({ ...tile.value, limit, ...updateSorting() });

exports.updateLimitSortingInTile = updateLimitSortingInTile;
