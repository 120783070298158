import * as React from "react";
import {css, cx} from "emotion";
import {cs} from "../../../react/chain-services";
import {IconUnchanged} from "../common/icons/icons";
import {ArrowDownSolid, ArrowUpSolid} from "./render-summary";
import "./render-table.scss";
import {consumeContext} from "../../../react/context";
import {CURRENCY_CONVERSION} from "../common/currency-conversion-warning/currency-conversion-warning";
import {StyledClass} from "@common/react/styled-class";

export const rTable = ({
    style,
    theme,
    rows,
    currencyConversion,
    formatter: {valueFormatter, percentFormatter},
    hideComparison,
    next,
    onClickPoint,
    tileID,
}) =>
    cs(consumeContext("tileActionControlFilter"), ({tileActionControlFilter}) => {
        const showCompare = !hideComparison && style.showCompare;
        const hasActions = !!onClickPoint;
        const hasFilteredByTileAction = tileActionControlFilter.value?.tile?.id == tileID && tileActionControlFilter.value?.action?.value;

        const colors = theme.dataVisualization.dataColorPalettes.kpiColorsRGB;
        const color = colors[style.valueColorApplication?.index || 0];

        return next(
            <div
                className={cx("kpi-table-fg2 verb-table-kpi-list")}
                style={{
                    ...(!style.summaryShown && {
                        border: "none",
                        padding: 0,
                    }),
                }}
                key={"table"}
            >
                <div className="details">
                    {style.tableTitleShown && (
                        <div className="title" style={{color}}>
                            {style.tableTitle}
                        </div>
                    )}

                    {rows.map((row, i) => (
                        <div
                            className={cx("member verb-table-kpi-row", showCompare && "has-comparison", hasActions && "has-actions")}
                            key={i}
                            style={{borderColor: color}}
                            onClick={(e) =>
                                onClickPoint?.({
                                    x: e.clientX,
                                    y: e.clientY,
                                    row,
                                })
                            }
                        >
                            {(() =>
                                cs(
                                    [
                                        "cssClass",
                                        (_, next) => {
                                            const getColumnColor = (name) => {
                                                if (
                                                    (hasActions && !hasFilteredByTileAction) ||
                                                    tileActionControlFilter.value?.action?.value == name
                                                ) {
                                                    return {
                                                        color: `${
                                                            theme.general.buttons.hyperlinkButton.fontColorRGB || "#0276F2"
                                                        } !important`,
                                                        "&:hover": {
                                                            color: `${
                                                                theme.general.buttons.hyperlinkButton.hoverFontColorRGB || "#0294F2"
                                                            } !important`,
                                                            "text-decoration": "underline",
                                                        },
                                                    };
                                                }

                                                return {
                                                    color: `${color} !important`,
                                                };
                                            };

                                            return StyledClass({
                                                next,
                                                content: getColumnColor(row.name),
                                            });
                                        },
                                    ],
                                    ({cssClass}) => <div className={cx("name verb-table-kpi-column-name", cssClass)}>{row.name}</div>
                                ))()}

                            {(() => {
                                return (
                                    <div className="value-change verb-table-kpi-column-change">
                                        {style.actualValueShown && (
                                            <>
                                                <div
                                                    className={cx("value", {
                                                        "is-compare": showCompare,
                                                    })}
                                                    style={{color: color}}
                                                >
                                                    {style.actualValueFormat === "PercentOfTotal"
                                                        ? percentFormatter(row.percentOfTotal)
                                                        : valueFormatter(row.value)}

                                                    {cs(
                                                        (_, next) => (currencyConversion?.hasFailures ? next() : null),
                                                        ["condition", (_, next) => next(row.currencyConversionFailures)],
                                                        ({condition}) => {
                                                            return condition ? (
                                                                <span
                                                                    className={cx("material-icons-round currency-failures")}
                                                                    style={{
                                                                        color: "#ff5959",
                                                                        "font-size": "10px",
                                                                    }}
                                                                >
                                                                    priority_high
                                                                </span>
                                                            ) : null;
                                                        }
                                                    )}
                                                </div>

                                                {showCompare &&
                                                    (() => {
                                                        if (row.change == null) {
                                                            return (
                                                                <span
                                                                    className="change no-padding"
                                                                    style={{
                                                                        color: color,
                                                                    }}
                                                                >
                                                                    N/A
                                                                </span>
                                                            );
                                                        }

                                                        const isGood =
                                                            (style.positiveChangeGood && (row.change > 0 || row.difference > 0)) ||
                                                            (!style.positiveChangeGood && (row.change < 0 || row.difference < 0));
                                                        const isUp = row.change > 0 || row.difference > 0;

                                                        const isUnchanged = () => {
                                                            return (
                                                                style.showCompare &&
                                                                (style.compareValueMethod === "Percent"
                                                                    ? percentFormatter(Math.abs(row.change)) === "0%"
                                                                    : valueFormatter(Math.abs(row.difference)) === 0)
                                                            );
                                                        };

                                                        const goodColor =
                                                            theme.dataVisualization.dataColorPalettes.otherColors.conditionalGoodColorRGB ||
                                                            "#18C96E";
                                                        const badColor =
                                                            theme.dataVisualization.dataColorPalettes.otherColors.conditionalBadColorRGB ||
                                                            "#E95A5A";

                                                        return (
                                                            <div className="value-compare">
                                                                <div
                                                                    className="change"
                                                                    style={{
                                                                        color: `${
                                                                            isUnchanged() ? "#7e7e7e" : isGood ? goodColor : badColor
                                                                        }`,
                                                                    }}
                                                                >
                                                                    {style.indicatorShown &&
                                                                        style.compareValueMethod &&
                                                                        (isUnchanged()
                                                                            ? IconUnchanged({})
                                                                            : isUp
                                                                            ? ArrowUpSolid({
                                                                                  fill: isGood ? goodColor : badColor,
                                                                              })
                                                                            : ArrowDownSolid({
                                                                                  fill: isGood ? goodColor : badColor,
                                                                              }))}

                                                                    {style.compareValueMethod &&
                                                                        (style.compareValueMethod === "Percent"
                                                                            ? percentFormatter(Math.abs(row.change))
                                                                            : valueFormatter(Math.abs(row.difference)))}
                                                                </div>

                                                                {style.previousPeriodShown && (
                                                                    <div className="prev verb-table-kpi-column-change-prev">
                                                                        {cs(() => {
                                                                            const formattedValue = valueFormatter(row.previousValue);
                                                                            return formattedValue ? `Prev ${formattedValue}` : null;
                                                                        })}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        );
                                                    })()}
                                            </>
                                        )}
                                    </div>
                                );
                            })()}
                        </div>
                    ))}
                </div>
            </div>
        );
    });
