import {getTablesMatchWithRelationship} from "./relationships/relationships-common";
import {envAuthSegments} from "../tabs/left-panel-overrides/column-security/column-security";
import {createDateFormatter} from "../../../../../../common/ui-components/charts/common/formatters/date-formatter";
import {useRef} from "react";
import * as React from "react";

export function getTableGroups({tables, relationships}) {
    let groups = [];

    let ids = tables.map((t) => t.id);
    const mapTables = tables.reduce((map, table) => {
        return {
            ...map,
            [table.id]: table,
        };
    }, {});

    function findTableHasCol(colId) {
        return tables.find((t) => t.columns.includes(colId));
    }

    function getRelationshipOfTable(tableId) {
        const cols = mapTables[tableId].columns;

        return relationships
            .reduce((tables, r) => {
                if (!cols.includes(r.leftColumnID) && !cols.includes(r.rightColumnID)) {
                    return tables;
                }

                const foundTable = findTableHasCol(cols.includes(r.leftColumnID) ? r.rightColumnID : r.leftColumnID);

                if (foundTable) {
                    return tables.concat(foundTable);
                }

                return tables;
            }, [])
            .filter((v) => v);
    }

    function DFS(tableId) {
        let s = [];
        let explored = new Set();
        s.push(tableId);

        explored.add(tableId);

        while (s.length > 0) {
            let tId = s.pop();

            try {
                getRelationshipOfTable(tId)
                    .filter((t) => !explored.has(t.id))
                    .forEach((t) => {
                        explored.add(t.id);
                        s.push(t.id);
                    });
            } catch (e) {
                throw e;
            }
        }

        return [...explored];
    }

    while (ids.length > 0) {
        const newGroup = DFS(ids[0]);
        groups.push({
            tableIds: newGroup,
            hasRowSecurity: newGroup.findIndex((i) => !!mapTables[i].rowLevelSecurity?.Endpoint) >= 0,
        });

        ids = ids.filter((id) => !newGroup.includes(id));
    }

    return groups;
}

export function getHasSecurityWarningTables({tables, relationships}) {
    const _groups = getTableGroups({
        tables,
        relationships,
    });

    const {warningTables, hasRowSecurityTables} = _groups.reduce(
        (res, g) => {
            return {
                warningTables: res.warningTables.concat(g.hasRowSecurity ? [] : g.tableIds),
                hasRowSecurityTables: res.hasRowSecurityTables.concat(!g.hasRowSecurity ? [] : g.tableIds),
            };
        },
        {
            warningTables: [],
            hasRowSecurityTables: [],
        }
    );

    return hasRowSecurityTables.length > 0 ? warningTables : [];
}

export function getTablesDisplayInCanvas(model) {
    const {tables, disabledTables, relationships} = model;

    const isRelationshipInsideValidTable = (relationship) => {
        return getTablesMatchWithRelationship(tables, relationship).length > 0;
    };

    const displayedInCanvas = disabledTables.filter((table) => {
        if (isTableHasRowLevelSecurity(table)) return true;

        return table.columns.find((c) => {
            const filteredRelationships = relationships.filter((r) => r.leftColumnID == c.id || r.rightColumnID == c.id);

            for (let relationship of filteredRelationships) {
                if (isRelationshipInsideValidTable(relationship)) {
                    return true;
                }
            }

            return false;
        });
    });

    return tables.filter((t) => !t.excludeFromModel).concat(displayedInCanvas);
}

export const isTableHasRowLevelSecurity = (table) => {
    for (let env of envAuthSegments) {
        if (table.rowLevelSecurity?.[env.value]) return true;
    }
};

export const getConflictRowLevelSecurity = (rowLevelSecurity, deletedTables) => {
    let errors = [];
    for (let env of envAuthSegments) {
        if (rowLevelSecurity?.[env.value]) {
            for (let outerBlock of rowLevelSecurity?.[env.value].outerBlocks) {
                for (let innerBlock of outerBlock.innerBlocks) {
                    let table = deletedTables.find((t) => t.dataSourceTableID == innerBlock.leftValue.dataSourceTableID);

                    if (table) {
                        const column = table.columns.find((c) => c.dataSourceColumnID == innerBlock.leftValue.dataSourceColumnID);

                        errors.push({
                            tableID: table.id,
                            columnID: column.id,
                            tableName: table.visualName,
                            columnName: column.name,
                        });
                    }

                    table = deletedTables.find((t) => t.dataSourceTableID == innerBlock.rightValue.dataSourceTableID);

                    if (table) {
                        const column = table.columns.find((c) => c.dataSourceColumnID == innerBlock.leftValue.dataSourceColumnID);

                        errors.push({
                            tableID: table.id,
                            columnID: column.id,
                            tableName: table.visualName,
                            columnName: column.name,
                        });
                    }
                }
            }
        }
    }

    return errors;
};

export const getBrokenRelationshipWithTables = (table, relationships, deletedTables) => {
    let tables = [];

    for (let relationship of relationships) {
        const leftColumn = table.columns.find((c) => c.id == relationship.leftColumnID);
        if (leftColumn) {
            const deletedTable = deletedTables.find((t) => t.columns.find((c) => c.id == relationship.rightColumnID));
            if (deletedTable) {
                tables.push(deletedTable);
            }
        }

        const rightColumn = table.columns.find((c) => c.id == relationship.rightColumnID);
        if (rightColumn) {
            const deletedTable = deletedTables.find((t) => t.columns.find((c) => c.id == relationship.leftColumnID));
            if (deletedTable) {
                tables.push(deletedTable);
            }
        }
    }

    return tables;
};

export const getBrokenRowLevelSecurityWithTable = (table, tables, disabledTables) => {
    let errors = [];

    for (let env of envAuthSegments) {
        if (table.rowLevelSecurity?.[env.value]) {
            const findErrorInOuterBlocks = (outerBlocks = []) => {
                if (!outerBlocks) return;

                for (let i = 0; i < outerBlocks.length; i++) {
                    const outerBlock = outerBlocks[i];
                    for (let j = 0; j < outerBlock.innerBlocks.length; j++) {
                        const innerBlock = outerBlock.innerBlocks[j];

                        const foundDeletedTable = disabledTables.find((t) => {
                            return (innerBlock.leftValue.dataSourceTableID
                                ? t.dataSourceTableID == innerBlock.leftValue.dataSourceTableID
                                : t.id == innerBlock.leftValue.modelTableID) || innerBlock.rightValue.dataSourceTableID
                                ? t.dataSourceTableID == innerBlock.rightValue.dataSourceTableID
                                : t.id == innerBlock.rightValue.modelTableID;
                        });

                        if (foundDeletedTable) {
                            errors.push({
                                label: `Broken Row level security - ${foundDeletedTable.visualName} Table`,
                                tableID: foundDeletedTable.id,
                                errorInTable: table,
                                outerBlockIndex: i,
                                innerBlockIndex: j,
                                envValue: env.value,
                                rlsAlertTitle: `${foundDeletedTable.visualName} Table`,
                                rlsAlertText: `${foundDeletedTable.visualName} — table removed from data source by ${
                                    foundDeletedTable.deletedInfo.deletedBy
                                } on ${createDateFormatter("MMM d, yyyy @ h:mm tt").format(
                                    new Date(foundDeletedTable.deletedInfo.deletedDateUtc)
                                )}.`,
                            });
                        } else {
                            if (innerBlock.compareOp == "Contains") {
                                findErrorInOuterBlocks(innerBlock?.rightValue?.orAndBlock?.outerBlocks);
                            }
                        }

                        const findTableError = (dsTableID) => {
                            const targetTable = tables.find((t) => t.dataSourceTableID == dsTableID);

                            if (targetTable) {
                                const findColumnError = (dsColumnID, valueKey) => {
                                    const columnValue = targetTable.disabledColumns.find((c) => c.dataSourceColumnID == dsColumnID);

                                    if (columnValue) {
                                        errors.push({
                                            label: `Broken Row level security - ${targetTable.name}.${columnValue.name} Column`,
                                            tableID: targetTable.id,
                                            columnID: columnValue.id,
                                            errorInTable: table,
                                            outerBlockIndex: i,
                                            innerBlockIndex: j,
                                            envValue: env.value,
                                            valueKey,
                                            rlsAlertTitle: `${columnValue.name} Column`,
                                            rlsAlertText: `${targetTable.visualName} > ${
                                                columnValue.name
                                            } — column removed from data source by ${
                                                columnValue.deletedInfo.deletedBy
                                            } on ${createDateFormatter("MMM d, yyyy @ h:mm tt").format(
                                                new Date(columnValue.deletedInfo.deletedDateUtc)
                                            )}.`,
                                        });

                                        return true;
                                    }

                                    return false;
                                };

                                if (findColumnError(innerBlock.leftValue.dataSourceColumnID, "leftValue")) {
                                } else {
                                    findColumnError(innerBlock.rightValue.dataSourceColumnID, "rightValue");
                                }

                                return true;
                            }

                            return false;
                        };

                        if (findTableError(innerBlock.leftValue.dataSourceTableID)) {
                        } else {
                            findTableError(innerBlock.rightValue.dataSourceTableID);
                        }
                    }
                }
            };

            findErrorInOuterBlocks(table.rowLevelSecurity?.[env.value]?.outerBlocks);
        }
    }

    return errors;
};

export const getErrorsInDataView = ({table, disabledTables, tables, dataViewTransforms = []}) => {
    let ret = [];

    const transformation = dataViewTransforms.find((t) => t.id == table.transformationID);

    if (transformation) {
        const transformationTableSource = transformation.steps.find((s) => s.$type == "ModelTableDatasetStep");
        if (transformationTableSource) {
            const deletedTable = disabledTables.find((t) => t.id == transformationTableSource.modelTableID);
            if (deletedTable) {
                ret.push({
                    label: `${table.visualName}: Broken Transformation - ${deletedTable.visualName} Table`,
                    tableID: deletedTable.id,
                    errorInTable: table,
                });
            } else {
                const usedTable = tables.find((t) => t.id == transformationTableSource.modelTableID);
                if (usedTable) {
                    for (let column of usedTable.disabledColumns) {
                        const found = table.requiredModelColumns.find((c) => c.modelColumnID == column.id);
                        if (found) {
                            ret.push({
                                label: `${table.visualName}: Broken Transformation - ${usedTable.visualName}.${column.name} Column`,
                                tableID: usedTable.id,
                                columnID: column.id,
                                errorInTable: table,
                            });
                        }
                    }
                }
            }
        }
    }

    return ret;
};

export const getErrorInCalculationOrMeasureColumn = ({table, columnID}) => {
    let ret = [];

    const trySearchColumnError = (column, columnType) => {
        if (table.deleted) {
            ret.push({
                label: `${column.name}: Broken Transformation - ${table.visualName}`,
                tableID: table.id,
                dependencyError: (
                    <span>
                        This calculated column on this table <b>{column.name}</b> will be deleted
                    </span>
                ),
                errorColumn: column,
            });
        } else {
            if (columnID) {
                const found = column.requiredModelColumns.find((c) => c.modelColumnID == columnID);
                if (found) {
                    ret.push({
                        label: (
                            <span>
                                {table.visualName} > {column.name} - Transformation - <b className="error-text">{columnType}</b>
                            </span>
                        ),
                        dependencyError: (
                            <span>
                                This calculated column on this table <b>{column.name}</b> will be deleted
                            </span>
                        ),
                        errorColumn: column,
                    });
                }
            } else {
                for (let disabledColumn of table.disabledColumns) {
                    const found = column.requiredModelColumns.find((c) => c.modelColumnID == disabledColumn.id);
                    if (found) {
                        ret.push({
                            label: `${column.name}: Broken Transformation - ${table.visualName}.${disabledColumn.name}`,
                            tableID: table.id,
                            columnID: disabledColumn.id,
                            dependencyError: (
                                <span>
                                    This calculated column on this table <b>{column.name}</b> will be deleted
                                </span>
                            ),
                            errorColumn: column,
                        });
                    }
                }
            }
        }
    };

    for (let column of table.columns) {
        if (column.$type == "CalculatedModelColumn" && column.calculations[0]?.versionDetails) {
            trySearchColumnError(column, "Calculator");
        }

        if (column.$type == "AggregatedMeasureModelColumn" && column.versionDetails) {
            trySearchColumnError(column, "Aggregated Measure");
        }
    }

    return ret;
};

export const getTableCalculatedColumn = (table) => {
    return table.columns.filter((column) => {
        return (
            (column.$type == "CalculatedModelColumn" && column.calculations[0].versionDetails) ||
            (column.$type == "AggregatedMeasureModelColumn" && column.versionDetails)
        );
    });
};
