import React from "react";
import {cs} from "@common/react/chain-services";
import {scope} from "@common/react/scope";
import {UseState} from "@common/react/use-state";
import {TransformationDiagram} from "./diagrams/transformation-diagram";
import {GlobalMouseMove} from "@common/react/global-mouse-move";
import {spc} from "@common/react/state-path-change";
import {GlobalMouseUp} from "@common/react/global-mouse-up";
import {cx} from "emotion";
import {Static2} from "@common/react/static-2";
import {diagramSizes, diagramUtils} from "./common/diagram-utils";
import "./transformation-diagram-panel.scss";
import {keepOnly, omit} from "@common/utils/objects";
import {last} from "@common/utils/collections";
import {consumeContext} from "@common/react/context";
import {AlertMessage} from "../../../../common/alert-message/alert-message";
import {isDataView} from "../../common/transformation-utils";
import {Button} from "@common/form/buttons/button/button";
import {Watch} from "@common/react/watch";

const defaultValue = {
    lastPosition: {x: 0, y: 0},
    position: {x: 0, y: 0},
    id: null,
};
export const TransformationDiagramPanel = ({transformation, interactions, next, getConfiguredColor, environment, isAggregatedMeasure}) =>
    cs(
        ["diagramRef", (_, next) => Static2({next})],
        ["newTransformationStep", (_, next) => UseState({initValue: null, next})],
        ["draggingItem", (_, next) => UseState({initValue: defaultValue, next})],
        [
            "zoomTransform",
            (_, next) =>
                UseState({
                    initValue: {
                        scale: 1,
                        transform: {x: 0, y: 0},
                    },
                    next,
                }),
        ],
        [
            "getNewPositionStep",
            ({diagramRef, zoomTransform}, next) =>
                next((item, noDelta = true) => {
                    const boundingClient = diagramRef.get()?.getBoundingClientRect();
                    const {top, left} = boundingClient;
                    const {scale, transform} = zoomTransform.value;

                    const position = item?.position;
                    const delta1 = !noDelta && diagramUtils.isTransformationStep(item.$type) ? diagramSizes(item.$type) / 2 : 0;

                    if (position && position.x > left && position.y > top) {
                        return {
                            x: (position.x - boundingClient.left - delta1 - transform.x) / scale,
                            y: (position.y - boundingClient.top - delta1 - transform.y) / scale,
                        };
                    }
                    return null;
                }),
        ],
        [
            "rInstructions",
            (_, next) =>
                cs(consumeContext("routing"), ["removed", ({routing}, next) => UseState({initValue: !routing.params.isNew, next})], ({removed}) =>
                    next({
                        remove: () => removed.onChange(true),
                        render: () => {
                            const type = isDataView(transformation.value) ? "view" : "column";

                            return (
                                !removed.value &&
                                transformation.value.steps.length <= 2 && (
                                    <div className="diagram-instructions">
                                        Drag inputs and transformations from the left to build your new {type}.
                                        <br /> <br />
                                        When finished, connect your workflow to your new {type} at the bottom.
                                        <br />
                                        <br />
                                        <Button btnType="border" size="medium" onClick={() => removed.onChange(true)}>
                                            Got it
                                        </Button>
                                    </div>
                                )
                            );
                        },
                    })
                ),
        ],
        [
            "collisionNode",
            ({draggingItem, newTransformationStep, getNewPositionStep}, next) => {
                const getCollisionNode = (item) => {
                    const steps = transformation.value.steps;
                    for (let step of steps) {
                        if (step.id !== item.id) {
                            if (diagramUtils.isCollide2Nodes(item, step)) {
                                return step;
                            }
                        }
                    }
                    return null;
                };

                if (newTransformationStep.value) {
                    return next(
                        getCollisionNode({
                            ...newTransformationStep.value,
                            position: getNewPositionStep(newTransformationStep.value, false) || {
                                x: 0,
                                y: 0,
                            },
                        })
                    );
                }
                if (draggingItem.value.id) return next(getCollisionNode(draggingItem.value));
                return next(null);
            },
        ],

        [
            "diagramsLocal",
            (_, next) =>
                UseState({
                    initValue: transformation.value?.steps,
                    next,
                }),
        ],

        ({diagramsLocal}, next) => {
            return (
                <>
                    {Watch({
                        value: JSON.stringify({
                            length: transformation.value?.steps.length,
                            steps: transformation.value?.steps?.map((s) => keepOnly(s, ["errors", "modelID", "modelTableID", "name"])),
                        }),
                        onChanged: () =>
                            diagramsLocal.change((olds) => {
                                const latestPosition = olds.reduce((prev, item) => {
                                    if (item.id) prev[item.id] = item.position;
                                    return prev;
                                }, {});
                                return transformation.value?.steps.map((s) => ({
                                    ...s,
                                    position: latestPosition[s.id] ?? s.position,
                                }));
                            }),
                    })}

                    {next()}
                </>
            );
        },

        ({newTransformationStep, diagramRef, getNewPositionStep, rInstructions, draggingItem, collisionNode, zoomTransform, diagramsLocal}) =>
            next({
                startDraggingNewStep: (stepInfo) =>
                    newTransformationStep.onChange({
                        ...stepInfo,
                        timestamp: new Date().getTime(),
                    }),
                render: () => {
                    return (
                        <div className="main transformation-diagram-panel-9rw" ref={diagramRef.set}>
                            {!!environment?.readOnly &&
                                AlertMessage({
                                    message: "Transformations in the sample environment cannot be edited.",
                                    alertStyle: {
                                        zIndex: interactions.setting?.showPreview ? 0 : 3,
                                        right: interactions.setting != null && 500,
                                    },
                                })}

                            {TransformationDiagram({
                                diagrams: diagramsLocal,
                                interactions,
                                rInstructions,
                                draggingItem,
                                collisionNode,
                                getNewPositionStep,
                                getConfiguredColor,
                                zoomTransform,
                                transformation,
                                isAggregatedMeasure,
                            })}

                            {newTransformationStep.value &&
                                DraggingNew({
                                    zoomTransform: zoomTransform.value,
                                    newTransformationStep,
                                    onDrop: (newStep) => {
                                        const newPos = getNewPositionStep(newStep);
                                        if (newPos) {
                                            const inputStep = transformation.value.steps.find((d) =>
                                                diagramUtils.isCollide2Nodes(
                                                    {
                                                        ...newTransformationStep.value,
                                                        position: newPos,
                                                    },
                                                    d
                                                )
                                            );

                                            let nTransformation = {
                                                ...omit(newStep, ["iconSrc"]),
                                                ...(newStep.hasOwnProperty("inputStep1ID")
                                                    ? {
                                                          inputStep1ID: inputStep?.id ?? null,
                                                      }
                                                    : {
                                                          inputStepID: inputStep?.id ?? null,
                                                      }),
                                                position: inputStep
                                                    ? diagramUtils.generatePositionWithoutOverlapping(transformation.value.steps, {
                                                          ...newStep,
                                                          position: {
                                                              x: inputStep.position.x,
                                                              y: inputStep.position.y + 90,
                                                          },
                                                      })
                                                    : newPos,
                                            };

                                            diagramsLocal.change((old) => [...old, nTransformation]);

                                            const latestPosition = diagramsLocal.value.reduce((prev, item) => {
                                                if (item.id) prev[item.id] = item.position;
                                                return prev;
                                            }, {});

                                            transformation.change(
                                                (oldTransformation) => ({
                                                    ...oldTransformation,
                                                    steps: [
                                                        ...oldTransformation.steps.map((s) => ({
                                                            ...s,
                                                            position: latestPosition[s.id],
                                                        })),
                                                        nTransformation,
                                                    ],
                                                }),
                                                false,
                                                (transformation) => {
                                                    diagramsLocal.onChange(transformation.steps);
                                                    interactions.openRightPanel({
                                                        stepId: last(transformation.steps).id,
                                                    });
                                                }
                                            );
                                        }
                                        newTransformationStep.onChange(null);
                                    },
                                })}
                        </div>
                    );
                },
            })
    );

const DraggingNew = ({newTransformationStep, onDrop, zoomTransform}) =>
    cs(() => {
        const {position, name, $type, iconSrc, icon} = newTransformationStep.value;
        const originalSize = diagramSizes($type);
        const isTransformationStep = diagramUtils.isTransformationStep($type);
        const size = (isTransformationStep ? 1 : 2) * originalSize;

        return (
            <>
                <div
                    className="dragging-new-step"
                    style={{
                        top: position.y - (isTransformationStep ? originalSize / 2 : originalSize),
                        left: position.x - (isTransformationStep ? originalSize / 2 : originalSize),
                    }}
                >
                    <div
                        className={cx("new-step", isTransformationStep ? "transformation" : $type.toLowerCase())}
                        style={{
                            width: size,
                            height: size,
                            transform: `scale(${zoomTransform.scale})`,
                        }}
                    >
                        <div className="icon">{icon({})}</div>
                        <div className="label">{name}</div>
                    </div>
                </div>

                {GlobalMouseMove({
                    fn: (e) => {
                        spc(newTransformationStep, ["position"], () => ({
                            x: e.clientX,
                            y: e.clientY,
                        }));
                    },
                })}
                {GlobalMouseUp({
                    fn: () =>
                        onDrop({
                            ...newTransformationStep.value,
                            position: isTransformationStep
                                ? {
                                      x: newTransformationStep.value.position.x - (originalSize / 2) * zoomTransform.scale,
                                      y: newTransformationStep.value.position.y - (originalSize / 2) * zoomTransform.scale,
                                  }
                                : newTransformationStep.value.position,
                        }),
                })}
            </>
        );
    });
