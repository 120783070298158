import * as React from "react";
import {cs} from "../../../react/chain-services";
import {consumeContext} from "../../../react/context";
import {isDataValid, loadTileData} from "../common/load-tile-data";
import {keepOnly} from "../../../utils/objects";
import {NoDataScreen} from "../common/no-data-screen/no-data-screen";
import {isDimRealDateTime} from "../factory/is-dim-real-date-time";
import {getFormatters} from "../factory/formatters/formatters";
import {loadTileFields} from "../get-field-color";
import {BlankDateWarning} from "../common/blank-date-warning";
import {MenuOptions} from "../common/menu-options/menu-options";
import {ChartInnerLayout} from "../chart-layout/chart-inner-layout";
import {ActionMenu} from "../common/action-menu/action-menu";
import {IgnoreUpdate} from "../../../react/ignore-update";
import {RenderChart} from "../render-chart/render-chart";
import {getBoxPlotOptions} from "./chart-options/get-box-plot-options";
import {getBoxPlotXAxis, getBoxPlotYAxis} from "@common/ui-components/charts/box-plot/chart-options/box-plot-axes";
import {decorateBoxPlotData} from "@common/ui-components/charts/box-plot/decorate-box-plot-data";
import {getBoxPlotFormatters} from "@common/ui-components/charts/box-plot/formatter";

export const BoxPlot = ({
    tile,
    tileFilters,
    theme,
    loadData,
    downloadData,
    size,
    chartRef,
    defaultData,
    overrideTile,
    disabledTileActions = false,
}) =>
    cs(
        consumeContext("tileActionControlFilter"),
        [
            "chartData",
            ({tileActionControlFilter}, next) =>
                defaultData
                    ? next(defaultData)
                    : loadTileData({
                          next,
                          tileFilters,
                          loadData,
                          size,
                          tile,
                          theme,
                          tileKey: JSON.stringify([
                              keepOnly(tile, ["xAxisField", "yAxisField", "filters", "limit", "sort", "includeOutliers"]),
                          ]),
                          removeJSCursor: true,
                          // disabled: tileActionControlFilter.value?.tile?.id === tile.id
                      }),
        ],

        ["rawData", ({chartData}, next) => next(chartData)],

        ({rawData}, next) => (!isDataValid(rawData) ? NoDataScreen({size, theme}) : next()),

        [
            "formatters",
            ({rawData, selectedTimezone}, next) => {
                return next(
                    getBoxPlotFormatters({
                        tile,
                        timezone: selectedTimezone?.value,
                        rawData: rawData,
                    })
                );
            },
        ],

        [
            "tileFields",
            ({}, next) =>
                loadTileFields({
                    next,
                    configs: {
                        tile,
                        dimensionAttr: "xAxisField",
                        measureGroupAttrs: ["yAxisFields"],
                        isBarFields: ["yAxisFields"],
                        checkUnique: false,
                    },
                }),
        ],

        [
            "controls",
            ({data, yAxisKey, tileActionControlFilter, rawData}, next) => {
                // const seriesKeys = Object.keys(data);
                const hasMenuOptions = tile.style.showDownloadData || tile.style.showDownloadImage;
                // const hideRemoveTileControlFilter = tileActionControlFilter.value?.action?.value && tileActionControlFilter.value?.tile.id == tile.id;

                return next(
                    <div className="controls">
                        {BlankDateWarning({
                            theme,
                            isDimensionRealDate: isDimRealDateTime({
                                tile,
                                dimensionAttr: "xAxisField",
                                series: rawData?.series,
                                noLimit: true,
                            }),
                            filters: tileFilters.getValue(),
                        })}

                        {/*{RemoveTileControlFilter({tile, hideBorder: (!hasAlternativeAxes && !hasMenuOptions)})}*/}

                        {hasMenuOptions &&
                            MenuOptions({
                                chartRef,
                                theme,
                                tile,
                                downloadData,
                                tileFilters,
                            })}
                    </div>
                );
            },
        ],

        ({rawData, controls}, next) =>
            ChartInnerLayout({
                size,
                tile,
                theme,
                next,
                noData: !isDataValid(rawData),
                hasControls: !!controls,
            }),

        [
            "actionMenu",
            ({formatters}, next) =>
                ActionMenu({
                    tile,
                    overrideTile,
                    dimensionFormatter: formatters.dimensionFormatter,
                    disabledTileActions,
                    next,
                }),
        ],

        [
            "onClickPoint",
            ({actionMenu, tileFields, data}, next) => {
                if (!actionMenu.hasActions()) {
                    return next();
                }
                return next(({point, ...other}) => {
                    const dimensionField = tileFields.find((f) => f.$type == "DimensionTileField");
                    const dimensionValue = point.name ?? point.category;

                    let fieldToValue = {
                        [dimensionField.id]: dimensionValue,
                    };

                    const _fieldToValue = tileFields
                        .filter((f) => f.$type == "MeasureTileField")
                        .reduce((obj, field) => {
                            let items = null;
                            const key = Object.keys(data).find((k) => k.indexOf(field.id) >= 0);
                            items = data[key].series.find((s) => s.tileFieldID == field.id)?.data;

                            obj[field.id] = items?.find((d) => d[0] == dimensionValue)?.[1] ?? null;
                            return obj;
                        }, {});

                    const tileActionData = point.series?.userOptions?.tileActionData;

                    tileActionData?.columns.forEach((c, index) => {
                        fieldToValue[c.tileFieldID] = tileActionData.data[index];
                    });

                    actionMenu.show({
                        point,
                        ...other,
                        fieldToValue: {
                            ...fieldToValue,
                            ..._fieldToValue,
                        },
                    });
                });
            },
        ],

        ({selectedTimezone}, next) =>
            IgnoreUpdate({
                next,
                when: () => selectedTimezone?.value,
            }),

        ({rawData, isCompare, formatters, onClickPoint, controls}) => {
            const xAxis = getBoxPlotXAxis({tile, rawData, theme, formatters});
            const yAxis = getBoxPlotYAxis({tile, rawData, theme, formatters});
            const decorateData = decorateBoxPlotData({rawData, tile, theme});

            let options = {
                ...getBoxPlotOptions({
                    tile,
                    theme,
                    isCompare,
                    rawData,
                    formatters,
                    onClickPoint,
                }),
                xAxis,
                yAxis,
                series: decorateData.series,
            };

            return (
                <div
                    className="vertical-bar-2wd"
                    key={getRefreshKey({
                        tile,
                        theme,
                        // tileFiltersValue: tileFilters.getValue(),
                    })}
                >
                    <div className="chart">
                        <RenderChart
                            {...{
                                chartRef,
                                options,
                                size,
                                theme,
                                tile,
                                formatters,
                            }}
                        />
                    </div>

                    {controls}
                </div>
            );
        }
    );

const getRefreshKey = ({tile, theme, tileFiltersValue, key}) =>
    JSON.stringify([
        keepOnly(tile, [
            "id",
            "style",
            "filters",
            "groupField",
            "xAxisField",
            "yAxisField",
            "limit",
            "sort",
            "categorySort",
            "referenceLines",
        ]),
        theme.dataVisualization.toolTipsAndLegends,
        key,
        tileFiltersValue,
    ]);
