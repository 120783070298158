import {cs} from "../../../../../../react/chain-services";
import * as React from "react";
import "./text-filter.scss";
import {FreeFormText} from "./free-form-text/free-form-text";
import {SelectableList} from "./selectable-list/selectable-list";

export const TextFilter = ({filter, form, providedFilterData, filterValues, apiKey, filterStyle, next}) =>
    cs(({}) =>
        (filter.option === "FreeFormText" ? FreeFormText : SelectableList)({
            form,
            filter,
            filterStyle,
            providedFilterData,
            next,
            filterValues,
            apiKey,
        })
    );
