import "./tile-fields.scss";

import * as React from "react";

import {cs} from "@common/react/chain-services";
import {consumeContext, provideContext} from "@common/react/context";
import {scope} from "@common/react/scope";
import {setPath} from "@common/utils/arr-path";

import {Expandable} from "../../../../../../../common/expandable/expandable";

import {getTileActionsUpdated} from "../actions/get-tile-actions-updated";
import {getTileFields} from "./common/get-tile-fields";
import {AxisSorting} from "./common/limit-sorting/sorting/axis/axis-sorting";
import {getAxisSortingUpdated} from "./common/limit-sorting/sorting/axis/get-axis-sorting-updated";
import {getColumnType} from "./common/select-field/select-field";
import {SingleFieldWell} from "./common/single-field-well/single-field-well";
import {updateSingleFieldInTile} from "./common/update-tile";

export const SparkLineKpiFields = ({tile, isSubTile, form, tileActions, debugFunction, next}) =>
    cs(
        consumeContext("getFieldInfo"),
        [
            "tileFields",
            ({getFieldInfo}, next) =>
                next(
                    getTileFields({
                        tile: tile.value,
                        dimensionAttr: "xAxisField",
                        measureSingleAttrs: ["yAxisField"],
                        getFieldInfo,
                    })
                ),
        ],
        ({tileFields}, next) => provideContext("tileFields", tileFields, next),
        [
            "xAxisField",
            ({tileFields}, next) =>
                SingleFieldWell({
                    field: scope(tile, ["xAxisField"]),
                    onUpdateTile: (newVal, oriVal) => {
                        if (newVal) {
                            // always use the auto-aggregation type
                            newVal.dateProperties = {
                                aggregation: "Auto",
                                format: "MM/dd/yyyy",
                            };
                        }

                        return updateSingleFieldInTile({
                            fieldAttr: "xAxisField",
                            fieldVal: {newVal, oriVal},
                            tileFields,
                            tile,
                            updateSorting: getAxisSortingUpdated,
                            updateTileActions: getTileActionsUpdated,
                        });
                    },
                    $type: "DimensionTileField",
                    label: "Date Axis",
                    filterColumn: (column) => {
                        const type = getColumnType(column);
                        return type == "date";
                    },
                    hideAggregationSelect: true,
                    next,
                }),
        ],
        [
            "yAxisField",
            (_, next) =>
                SingleFieldWell({
                    field: scope(tile, ["yAxisField", "measureFields", 0]),
                    $type: "MeasureTileField",
                    label: "KPI and Measure Value",
                    hideDisplayName: true,
                    hideSort: true,
                    onUpdateTile: (newVal, oriVal) => {
                        let newTile = {
                            ...tile.value,
                            ...getTileActionsUpdated({tile, fieldVal: {newVal, oriVal}}),
                        };

                        if (newVal) {
                            newTile = setPath(newTile, ["yAxisField", "measureFields", 0], newVal);
                        } else {
                            newTile = setPath(newTile, ["yAxisField", "measureFields"], []);
                        }

                        return tile.onChange(newTile);
                    },
                    next,
                }),
        ],
        [
            "fieldsSection",
            ({xAxisField, yAxisField}, next) =>
                next({
                    render: () =>
                        Expandable({
                            label: "Fields",
                            render: () => (
                                <>
                                    {yAxisField.render()}
                                    {xAxisField.render()}
                                </>
                            ),
                        }),
                    override: yAxisField?.override || xAxisField?.override,
                }),
        ],
        [
            "sortingSection",
            ({tileFields}, next) =>
                next({
                    render: () =>
                        Expandable({
                            initExpand: false,
                            label: "Sorting On Date Axis",
                            render: () =>
                                AxisSorting({
                                    fieldGroups: form.field(["sort"]).state,
                                    title: "Date Axis",
                                }),
                        }),
                }),
        ],

        ({fieldsSection, sortingSection}) =>
            next({
                render: () => (
                    <div className="tile-fields-3vr">
                        {fieldsSection.render()}
                        {sortingSection.render()}
                        {tileActions?.render?.()}
                        {isSubTile && debugFunction?.render?.({})}
                    </div>
                ),
                override: fieldsSection.override || debugFunction?.override() || tileActions?.override({fieldType: "MeasureTileField"}),
                overrideAll: debugFunction?.overrideAll || tileActions?.overrideAll,
            })
    );
