import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {css, cx} from "emotion";
import {StyledClass} from "@common/react/styled-class";

export const FilterSubmitButtons = ({onSubmit, onReset, disabled}) =>
    cs(consumeContext("theme"), ({theme}) => {
        const secondaryButtonTheme = theme.general.buttons.secondaryButton;
        const primaryButtonTheme = theme.general.buttons.primaryButton;

        const getCss = (selectedTheme) => ({
            background: `${selectedTheme.backgroundColorRGB}`,
            color: `${selectedTheme.fontColorRGB}`,
            border: `${selectedTheme.borderWidth}px solid ${selectedTheme.borderColorRGB}`,
            "border-radius": `${selectedTheme.cornerRadius}px`,
            "font-family": `"${theme.general.canvas.fontFamily}", sans-serif`,

            "&:hover": {
                background: `${selectedTheme.hoverBackgroundColorRGB}`,
                color: `${selectedTheme.hoverFontColorRGB}`,
                "border-color": `${selectedTheme.hoverBorderColorRGB}`,
            },
        });

        return (
            <div className="btn-wrapper">
                {(() =>
                    cs(
                        [
                            "css",
                            (_, next) =>
                                StyledClass({
                                    next,
                                    content: getCss(secondaryButtonTheme),
                                }),
                        ],
                        ({css}) => (
                            <button className={cx("btn btn-reset", css)} onClick={onReset}>
                                Reset
                            </button>
                        )
                    ))()}

                {(() =>
                    cs(
                        [
                            "css",
                            (_, next) =>
                                StyledClass({
                                    next,
                                    content: getCss(primaryButtonTheme),
                                }),
                        ],
                        ({css}) => (
                            <button className={cx("btn btn-search", css)} onClick={onSubmit} disabled={disabled}>
                                Apply
                            </button>
                        )
                    ))()}
            </div>
        );
    });
