import React from "react";
import {cs} from "../../../../react/chain-services";
import {css, cx} from "emotion";
import {getScrollbarStyleLegend} from "../../render-chart/chart-scrolling-style";
import {UseState} from "../../../../react/use-state";
import {Static2} from "../../../../react/static-2";
import {LegendControl} from "../../render-chart/legend/control/legend-control";
import {RevalidateChartService} from "../../render-chart/legend/display/revalidate-chart-service";
import {StyledClass} from "@common/react/styled-class";

export const MapValueGroupLegend = (props) => {
    const {chartRef, legendRoom, tile, options, theme, isCompare, formatters} = props;

    return cs(
        ["scrollLeft", (_, next) => UseState({next, initValue: null})],
        ["legendContainerRef", (_, next) => Static2({next})],

        [
            "chart",
            (_, next) =>
                RevalidateChartService({
                    props: {tile, theme, mapName: options.series[0].mapGeoJson},
                    chartRef,
                    next,
                }),
        ],

        ["hiddenGroup", (_, next) => UseState({next})],
        [
            "scrollBarClass",
            ({}, next) =>
                StyledClass({
                    content: getScrollbarStyleLegend(theme),
                    next,
                }),
        ],
        ({legendContainerRef, scrollLeft, chart, hiddenGroup, scrollBarClass}) => {
            const groups = chart.series[0].userOptions.groups;
            return (
                <div
                    className={cx(
                        "map-value-group-legend-h24",
                        "legend-wrapper verb-tile-legend",
                        scrollBarClass,
                        `${
                            legendRoom.paddingTop || legendRoom.paddingBottom
                                ? `horizontal-scroll verb-tile-legend-${legendRoom.paddingTop ? "top" : "bottom"}`
                                : `vertical-scroll verb-tile-legend-${legendRoom.paddingLeft ? "left" : "right"}`
                        }`
                    )}
                    style={{
                        width: legendRoom.paddingTop || legendRoom.paddingBottom ? "100%" : `${legendRoom.legendWidth}px`,
                        left: legendRoom.paddingLeft ? 0 : "",
                        right: legendRoom.paddingRight ? 0 : "",
                        height: legendRoom.paddingTop || legendRoom.paddingBottom ? "" : `${chart.plotHeight}px`,
                        top: legendRoom.paddingBottom ? "" : 0,
                        bottom: legendRoom.paddingBottom ? "0" : "",
                    }}
                >
                    <div
                        className="legend-container"
                        ref={(elem) => {
                            legendContainerRef.set(elem);
                            setTimeout(() => {
                                if (scrollLeft.value === null) {
                                    scrollLeft.onChange(0);
                                }
                            });
                        }}
                        style={{
                            justifyContent:
                                legendContainerRef.get()?.scrollWidth - legendContainerRef.get()?.clientWidth > 0 ? "" : "center",
                        }}
                        onScroll={() => {
                            scrollLeft.onChange(legendContainerRef.get().scrollLeft);
                        }}
                    >
                        {groups.map((group, i) => {
                            return (
                                <div
                                    className="legend-item"
                                    key={i}
                                    style={{cursor: "default"}}
                                    onClick={() => {
                                        const locs = chart.series[0].data.filter((d) => d.largestGroup?.group === group.group);
                                        if (!hiddenGroup.value) {
                                            hiddenGroup.onChange(group.group);
                                            locs.forEach((loc) => loc.setVisible(false));
                                        } else {
                                            hiddenGroup.onChange(null);
                                            locs.forEach((loc) => loc.setVisible(true));
                                        }
                                    }}
                                >
                                    <div
                                        className="icon bar-icon"
                                        style={{
                                            background: group.color,
                                            borderRadius: theme.dataVisualization.toolTipsAndLegends.legendSymbol === "Circle" ? "50%" : "",
                                        }}
                                    />
                                    <div
                                        className="legend-text"
                                        style={{
                                            fontSize: `${options.legend.itemStyle.fontSize}px`,
                                            color: `${theme.general.canvas.fontColorRGB}`,
                                        }}
                                    >
                                        {group.group}
                                    </div>
                                </div>
                            );
                        })}
                    </div>

                    {scrollLeft.value !== null &&
                        LegendControl({
                            legendContainerRef,
                            scrollLeft,
                        })}
                </div>
            );
        }
    );
};
