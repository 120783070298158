import {YesNoToggle} from "../../../../../../../common/form/toggles/yes-no-toggle";
import {cs} from "@common/react/chain-services";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {sort} from "@common/utils/collections";
import {chain} from "@common/utils/fs";
import {noun} from "@common/utils/plural";
import {TableIcon} from "../../../common/icons";
import {cx} from "emotion";

export const FlatFileTablesImport = ({dataSource, mappingImport = false}) =>
    cs(() => {
        const tables = [...dataSource.value.tables, ...(dataSource.value?.disabledTables || [])];

        const sortedTables = chain(
            tables,
            (_) => [...sort(_, (c) => c.name)]
            // (_) => _.filter((c) => c.name.toLowerCase().indexOf((keyword.value || "").toLowerCase()) > -1)
        );

        return (
            <>
                <div className="label">Import Configuration</div>

                <div className="list">
                    <div className="labels">
                        <div>Table</div>
                        <div>Import</div>
                    </div>

                    {sortedTables.map((table) => {
                        const isDisabledTable = (dataSource.value?.disabledTables || []).findIndex((c) => c.name === table.name) > -1;

                        return (
                            <div
                                className={cx("table-item", {
                                    disabled: isDisabledTable,
                                })}
                            >
                                <div className="info">
                                    {TableIcon({})}

                                    <div className="center">
                                        <div className="text-wrapper">
                                            <div className="name">{table.name}</div>

                                            <div className="overview">
                                                ({table.columns.length} {noun(table.columns.length, "column")})
                                            </div>
                                        </div>

                                        {mappingImport &&
                                            DropdownSelect({
                                                label: "Maps To",
                                                list: [],
                                                isSelected: () => {},
                                                onChange: () => {},
                                            })}
                                    </div>
                                </div>

                                {tables.length > 1 &&
                                    YesNoToggle({
                                        colors: {
                                            right: "#294661",
                                        },
                                        state: {
                                            value: !isDisabledTable,
                                            onChange: (value) => {
                                                dataSource.change((ds) => ({
                                                    ...ds,
                                                    tables: value ? [...ds.tables, table] : ds.tables.filter((t) => t.name !== table.name),
                                                    disabledTables: value ? ds.disabledTables.filter((t) => t.name !== table.name) : [...ds.disabledTables, table],
                                                }));
                                            },
                                        },
                                    })}
                            </div>
                        );
                    })}
                </div>
            </>
        );
    });
