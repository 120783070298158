import {IgnoreUpdate} from "@common/react/ignore-update";
import {cyrb53} from "@common/utils/strings";
import {omit} from "@common/utils/objects";
import {animate} from "@common/utils/loop";

const getTablesWithoutPos = (tables) => (tables ?? []).map((t) => omit(t, ["position"]));
// const getHash = (obj) => cyrb53(JSON.stringify(obj));
const getHash = (obj) => JSON.stringify(obj);

export const IgnoreUpdateModelPositions = ({dataSourceModelContext, extraProps = {}, next}) => {
    const dataSources = dataSourceModelContext.entities.dataSources ?? {};
    const modelTables = dataSourceModelContext.entities.modelTables ?? {};
    const searchText = dataSourceModelContext.getSearchText();
    const relationships = dataSourceModelContext.relationships;

    return IgnoreUpdate({
        next,
        props: {
            key: `${Object.keys(dataSources)?.length}_${getHash(
                {...extraProps, searchText, relationships, tables: getTablesWithoutPos(Object.values(modelTables))} ?? {}
            )}`,
        },
        when: ({key}) => {
            const newHash = `${Object.keys(dataSources)?.length}_${getHash(
                {...extraProps, searchText, relationships, tables: getTablesWithoutPos(Object.values(modelTables))} ?? {}
            )}`;
            return key == newHash;
        },
    });
};

function easeInOutQuad(t) {
    return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
}

export const scrollTopTo = ({
    disabledAnimation = false,
    containerElem,
    targetElem,
    offset = () => 0,
    duration = 1000,
    onDone = () => null,
}) => {
    if (!containerElem.contains(targetElem)) {
        return;
    }

    const containerScrollTop = containerElem.scrollTop;

    const targetElemTop = targetElem.getBoundingClientRect().top + containerScrollTop - containerElem.getBoundingClientRect().top;

    const gapToTarget = targetElemTop - containerScrollTop + offset(targetElemTop - containerScrollTop > 0 ? 1 : -1);

    if (disabledAnimation) {
        containerElem.scrollTop = containerScrollTop + gapToTarget;
        return;
    }

    animate(
        {
            onProcess: (percentage) => {
                containerElem.scrollTop = containerScrollTop + gapToTarget * easeInOutQuad(percentage);
            },
            onDone,
        },
        duration
    );
};
