import React from "react";
import {cs} from "@common/react/chain-services";
import "./create-collection-with-env-dialog.scss";
import {DialogService} from "../../../common/dialog/dialog-service";
import {consumeContext} from "@common/react/context";
import {UseState} from "@common/react/use-state";
import {Form2} from "@common/react/cs-form/form2";
import {required} from "@common/react/cs-form/validators/required";
import {Watch} from "@common/react/watch";
import {DropdownSelect2} from "@common/ui-components/dropdown-select/dropdown-select2";
import {TextInput} from "../../../../../../common/form/text-input/text-input";
import {Load2} from "@common/react/load2";
import {SelectFolderService} from "../../../dashboard/env/collections/folder-cm/select-folder-service/select-folder-service";
import {scope} from "@common/react/scope";
import {ffToTextInput} from "../../../../../../common/form/ff-to-text-input";
import {ffToDropdown} from "../../../../../../common/form/ff-to-dropdown";
import {CreateCollectionService} from "../../../dashboard/env/collections/create-collection-service";
import {sum} from "@common/utils/collections";
import {SearchableSelect} from "@common/ui-components/searchable-select/searchable-select";
import {downloadReportTemplate} from "../../../collection/common/tile-templates/download-report/download-report-template";
import {Button} from "../../../../../../common/form/buttons/button/button";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";

export function isDataOrSparkCollection({$type}) {
    return ["DashboardCollection", "SparkCollection"].includes($type);
}

export const CreateCollectionWithEnvDialog = ({next: rootNext}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    render: ({resolve, args: {$type, $tileType} = {}}) => ({
                        title: "Create Collection",
                        width: 540,
                        content: next({
                            resolve,
                            $type,
                            $tileType,
                        }),
                    }),
                    next: rootNext,
                }),
        ],
        ({modal}) =>
            cs(
                consumeContext("apis"),
                consumeContext("tenant"),
                consumeContext("routing"),
                [
                    "themes",
                    ({apis, tenant: {environments}}, next) =>
                        Load2({
                            _key: environments[0].id,
                            fetch: () => apis.gettingStarted.getThemes(environments[0].id),
                            next,
                        }),
                ],
                [
                    "collection",
                    ({tenant: {environments}, themes}, next) => {
                        let initCollection = {
                            envID: environments[0].id,
                            $type: modal.$type,
                            $tileType: modal.$tileType,
                        };

                        if (themes.value && isDataOrSparkCollection(initCollection)) {
                            initCollection.themeID = themes.value.find((t) => t.default) || themes.value[0];
                        }

                        return UseState({
                            next,
                            initValue: initCollection,
                        });
                    },
                ],
                ["saving", (_, next) => UseState({next})],
                ["error", (_, next) => UseState({next})],
                [
                    "models",
                    ({apis, collection}, next) =>
                        Load2({
                            _key: collection.value.envID,
                            fetch: () => apis.gettingStarted.getModels(collection.value.envID),
                            next,
                        }),
                ],
                [
                    "folders",
                    ({apis, collection}, next) =>
                        Load2({
                            _key: collection.value.envID,
                            fetch: () => apis.gettingStarted.getFolders(collection.value.envID),
                            next,
                        }),
                ],
                [
                    "createCollectionService",
                    ({folders}, next) =>
                        CreateCollectionService({
                            currentLength: sum(folders.value || [], (fo) => fo.collections?.length ?? 0),
                            next,
                        }),
                ],
                [
                    "form",
                    ({collection, saving, apis, auth, error, routing, createCollectionService}, next) =>
                        Form2({
                            fields: {
                                envID: [required],
                                name: {
                                    transforms: ["trim"],
                                    validators: [required],
                                    debounce: true,
                                    asyncValidators: [
                                        {
                                            validate: (name, {data}) =>
                                                apis.gettingStarted.checkCollectionName(
                                                    name,
                                                    {collectionId: data?.id},
                                                    collection.value.envID
                                                ),
                                            getMessage: () => `Existed`,
                                        },
                                    ],
                                },
                                modelID: [required],
                                themeID: collection.$type == "DashboardCollection" ? [required] : [],
                            },
                            data: collection,
                            onSubmit: async (newFolderId) => {
                                await createCollectionService.createNew({
                                    onDone: async () => {
                                        saving.onChange(true);
                                        const dashboardApiDefaultData =
                                            collection.value.$type == "ApiCollection"
                                                ? {
                                                      tile: {
                                                          title: "API",
                                                      },
                                                      version: "1.0",
                                                  }
                                                : {};

                                        const submitCollection = {
                                            ...collection.value,
                                            ...(newFolderId && {
                                                folderID: newFolderId,
                                            }),
                                            ...dashboardApiDefaultData,
                                        };

                                        if (modal.$tileType == "DownloadReportTile") {
                                            submitCollection.gridLocations = [
                                                {
                                                    colStart: 1,
                                                    rowStart: 1,
                                                    colSpan: 6,
                                                    rowSpan: 2,
                                                    tile: {
                                                        title: "New Downloadable Report",
                                                        ...downloadReportTemplate,
                                                    },
                                                },
                                            ];
                                        }

                                        const newCol = await apis.collection.upsertCollection(submitCollection);

                                        if (!newCol?.id) {
                                            return;
                                        }

                                        modal.resolve();

                                        routing.goto("edit-collection", {
                                            envId: collection.value.envID,
                                            colId: newCol.id,
                                            collectionTab: collection.value.$type == "ApiCollection" ? "fields" : null,
                                        });
                                    },
                                });
                            },
                            next,
                        }),
                ],
                [
                    "selectFolderService",
                    ({collection, folders, apis}, next) =>
                        folders.value
                            ? SelectFolderService({
                                  state: scope(collection, ["folderID"]),
                                  label: "Select a folder",
                                  folders: folders.value,
                                  next,
                                  customUpsertApi: (folder) => apis.gettingStarted.upsertFolder(folder, collection.value.envID),
                                  customCheckFolderApi: (name, data) =>
                                      apis.gettingStarted.checkFolderName(name, data, collection.value.envID),
                              })
                            : next(),
                ],
                ({
                    collection,
                    models,
                    folders,
                    themes,
                    selectFolderService,
                    error,
                    form,
                    saving,
                    createCollectionService,
                    tenant: {environments},
                }) => (
                    <div className="remove-block-dialog-a32">
                        <VerbDialogBodyScrollbar>
                            {Watch({
                                value: collection.value,
                                onChanged: async () => {
                                    error.onChange(null);
                                },
                            })}

                            <div className="messages">
                                <div className="error-message">{error.value}</div>

                                <div className="message">
                                    {environments.length > 1 &&
                                        DropdownSelect2({
                                            label: "Environment",
                                            list: environments,
                                            valueToLabel: (v) => v.name,
                                            isSelected: (v) => v.id === collection.value?.envID,
                                            onChange: (v) => {
                                                collection.change((p) => ({
                                                    ...p,
                                                    envID: v.id,
                                                    name: null,
                                                }));
                                            },
                                        })}
                                </div>

                                <div className="message">
                                    {DropdownSelect2({
                                        label: "Select the data model to build from",
                                        list: models.value || [],
                                        valueToLabel: (v) => v.name,
                                        isSelected: (v) => v.id === collection.value.modelID,
                                        onChange: (v) =>
                                            collection.change((p) => ({
                                                ...p,
                                                modelID: v.id,
                                            })),
                                        loading: !models.value,
                                    })}
                                </div>

                                <div className="message">
                                    {TextInput({
                                        label: "Collection Name",
                                        ...ffToTextInput(form.field(["name"])),
                                    })}
                                </div>

                                {folders.value && themes.value && isDataOrSparkCollection(collection.value) && (
                                    <>
                                        <div className="message">{selectFolderService.render()}</div>

                                        <div className="message">
                                            {isDataOrSparkCollection(collection.value) &&
                                                SearchableSelect({
                                                    label: "Select a theme",
                                                    list: themes.value,
                                                    valueToLabel: (theme) => theme.name,
                                                    ...ffToDropdown(form.field(["themeID"]), ["id"]),
                                                })}
                                        </div>
                                    </>
                                )}
                            </div>
                        </VerbDialogBodyScrollbar>

                        <div className="buttons">
                            <Button btnType="secondary" onClick={() => modal.resolve()}>
                                Cancel
                            </Button>
                            <Button
                                disabled={!selectFolderService?.valid || !form.looksValid || saving.value}
                                onClick={async () => {
                                    if (selectFolderService.selectNewFolder) {
                                        try {
                                            const folder = await selectFolderService.selectNewFolder();
                                            return folder?.id && form.submit(folder.id);
                                        } catch (error) {
                                            //return console.log(error);
                                        }
                                    }

                                    form.submit();
                                }}
                            >
                                Create Collection
                            </Button>
                        </div>
                    </div>
                )
            )
    );
