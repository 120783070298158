import React from "react";
import {cs} from "../../../../../react/chain-services";
import {UseState} from "../../../../../react/use-state";
import {Static2} from "../../../../../react/static-2";
import {unique} from "../../../../../utils/collections";
import {css, cx} from "emotion";
import {LegendItemView} from "../legend-view/legend-item-view";
import {LegendControl} from "../control/legend-control";
import {cRenderComparison} from "./render-comparison";
import {getScrollbarStyleLegend} from "../../chart-scrolling-style";
import {Watch} from "../../../../../react/watch";
import {RevalidateChartService} from "./revalidate-chart-service";
import {StyledClass} from "@common/react/styled-class";

export const LegendNormalDisplay = (props) => {
    const {chartRef, legendRoom, tile, options, theme, isCompare, formatters} = props;

    return cs(
        [
            "hiddenSeries",
            (_, next) =>
                UseState({
                    next,
                    initValue: [
                        // {name, color}
                    ],
                }),
        ],
        ["scrollLeft", (_, next) => UseState({next, initValue: null})],
        ["legendContainerRef", (_, next) => Static2({next})],
        ["legendParameter", (_, next) => UseState({next, initValue: null})],
        [
            "getMaxLegendSubText",
            ({legendContainerRef}, next) =>
                next(() => {
                    let maxLegendSubText = 0;
                    const legendWrapper = legendContainerRef.get();
                    if (legendWrapper && !(legendRoom.paddingTop || legendRoom.paddingBottom)) {
                        const items = legendWrapper.getElementsByClassName("legend-sub-text");
                        for (let i = 0; i < items.length; i++) {
                            maxLegendSubText = Math.max(maxLegendSubText, items[i].offsetWidth);
                        }
                    }

                    return maxLegendSubText;
                }),
        ],
        [
            "chart",
            (_, next) =>
                RevalidateChartService({
                    props: {tile, theme},
                    chartRef,
                    next,
                }),
        ],
        [
            "scrollbarLegendClass",
            (_, next) =>
                StyledClass({
                    content: getScrollbarStyleLegend(theme),
                    next,
                }),
        ],
        ({hiddenSeries, legendContainerRef, getMaxLegendSubText, scrollLeft, chart, scrollbarLegendClass}) => {
            let data = ["PieChartTile", "DonutChartTile", "GaugeTile", "VennDiagramTile"].includes(tile.$type)
                ? chart.series[0].data
                : unique(chart.series, (s) => s.name + s.color).sort(
                      (item1, item2) => item1.userOptions.legendIndex - item2.userOptions.legendIndex
                  );
            const renderComparison =
                ["PieChartTile", "DonutChartTile"].includes(tile.$type) &&
                isCompare &&
                tile.style.legendStyle.legendContent?.startsWith("LabelAnd")
                    ? cRenderComparison({chart, formatters})
                    : undefined;

            return (
                <div
                    className={cx(
                        "legend-wrapper verb-tile-legend",
                        scrollbarLegendClass,
                        `${
                            legendRoom.paddingTop || legendRoom.paddingBottom
                                ? `horizontal-scroll verb-tile-legend-${legendRoom.paddingTop ? "top" : "bottom"}`
                                : `vertical-scroll verb-tile-legend-${legendRoom.paddingLeft ? "left" : "right"}`
                        }`
                    )}
                    style={{
                        width: legendRoom.paddingTop || legendRoom.paddingBottom ? "100%" : `${legendRoom.legendWidth}px`,
                        left: legendRoom.paddingLeft ? 0 : "",
                        right: legendRoom.paddingRight ? 0 : "",
                        height: legendRoom.paddingTop || legendRoom.paddingBottom ? "" : `${chart.plotHeight}px`,
                        top: legendRoom.paddingBottom ? "" : 0,
                        bottom: legendRoom.paddingBottom ? "0" : "",
                    }}
                >
                    <div
                        className="legend-container"
                        ref={(elem) => {
                            legendContainerRef.set(elem);
                            setTimeout(() => {
                                if (scrollLeft.value === null) {
                                    scrollLeft.onChange(0);
                                }
                            });
                        }}
                        style={{
                            justifyContent:
                                legendContainerRef.get()?.scrollWidth - legendContainerRef.get()?.clientWidth > 0 ? "" : "center",
                        }}
                        onScroll={() => {
                            scrollLeft.onChange(legendContainerRef.get().scrollLeft);
                        }}
                    >
                        {data.map((series, index) =>
                            LegendItemView({
                                series,
                                hiddenSeries,
                                theme,
                                options,
                                tile,
                                chart,
                                getMaxLegendSubText,
                                key: index,
                                formatters,
                                renderComparison,
                            })
                        )}
                    </div>

                    {scrollLeft.value !== null &&
                        LegendControl({
                            legendContainerRef,
                            scrollLeft,
                        })}
                </div>
            );
        }
    );
};
