import * as React from "react";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {LeftPanelClosable} from "../../../../../../../common/left-panel-closable/left-panel-closable";
import {FixedPopupMenu} from "../../../../../../../common/fixed-popup-menu/fixed-popup-menu";
import {Button} from "../../../../../../../../../../common/form/buttons/button/button";
import "./refenrence-lines-panel.scss";
import {keyed} from "@common/react/keyed";
import {EditReferenceLine} from "./edit-reference-line";
import {spc} from "../../../../../../../../../../common/react/state-path-change";
import {scope} from "../../../../../../../../../../common/react/scope";
import {camelCaseToSpaces} from "../../../../../../../../../../common/utils/strings";
import {LoadingIndicator} from "../../../../../../../../../../common/ui-components/loading-indicator/loading-indicator";
import {Watch} from "../../../../../../../../../../common/react/watch";
import {last} from "../../../../../../../../../../common/utils/collections";
import {waitTimeout} from "../../../../../../../../../../common/utils/wait-timeout";
import {ReferenceLineColorConfig} from "./reference-line-color-config";
import {MAX_NUM_COLOR} from "../../../../../../../../../../common/ui-components/charts/get-field-color";
import {getMeasureGroups} from "./reference-line-helpers";
import {TruncatingTooltip} from "../../../../../../../common/truncating-tooltip/truncating-tooltip";

export const ReferenceLinesPanel = ({tile, form, next}) =>
    cs(["editing", (_, next) => UseState({next})], ({editing}) =>
        next({
            render: () => {
                return (
                    <>
                        {Watch({
                            value: tile.value.referenceLines?.length > 0 && last(tile.value.referenceLines)?.id,
                            onChanged: () =>
                                editing.value?.isNew &&
                                editing.onChange({
                                    id: last(tile.value.referenceLines)?.id,
                                    isNew: true,
                                }),
                        })}

                        <div className="reference-lines-02d">
                            {tile.value.referenceLines?.length > 0 && (
                                <div className="list">
                                    {tile.value.referenceLines.map((rl, i) =>
                                        cs(
                                            keyed(i),
                                            [
                                                "refLineCM",
                                                (_, next) =>
                                                    FixedPopupMenu({
                                                        getCommands: () => [
                                                            {
                                                                label: "Edit",
                                                                onClick: () => {
                                                                    editing.onChange({
                                                                        id: rl.id,
                                                                    });
                                                                },
                                                            },
                                                            {
                                                                label: "Delete",
                                                                className: "delete-action",
                                                                onClick: () => {
                                                                    spc(tile, ["referenceLines"], (rls) => rls.filter((rl1) => rl1.id !== rl.id));
                                                                },
                                                            },
                                                        ],
                                                        next,
                                                    }),
                                            ],
                                            ["truncatingTooltip", (_, next) => TruncatingTooltip({next})],
                                            ({refLineCM, truncatingTooltip}) => (
                                                <div className="item">
                                                    <div className="info">
                                                        <div className="line-type">{camelCaseToSpaces(rl.$type.replace("ReferenceLine", ""))}</div>
                                                        <div
                                                            className="line-label"
                                                            ref={truncatingTooltip.ref}
                                                            {...truncatingTooltip.tooltip(rl.label)}
                                                        >
                                                            {rl.label}
                                                        </div>
                                                    </div>
                                                    {refLineCM.render()}
                                                </div>
                                            )
                                        )
                                    )}
                                </div>
                            )}
                            <div className="action">
                                {(() => {
                                    const measureGroups = getMeasureGroups({tile: tile.value});
                                    return (
                                        <Button
                                            btnType="secondary"
                                            disabled={!measureGroups?.length}
                                            onClick={async () => {
                                                editing.onChange({isNew: true});
                                                await waitTimeout(100);
                                                spc(tile, ["referenceLines"], (referenceLines) => {
                                                    return [
                                                        ...(referenceLines || []),
                                                        {
                                                            $type: "ConstantValueReferenceLine",
                                                            label: "Constant",
                                                            measureGroupID: measureGroups[0].id,
                                                            lineStyle: "Solid",
                                                            lineWidth: "Medium",
                                                            lineColor: "#000000",
                                                            colorApplication: {
                                                                $type: "DiscreteColorApplication",
                                                                index: !referenceLines?.length
                                                                    ? // first reference line should take the 8th color is discrete color palette
                                                                      MAX_NUM_COLOR.DiscreteColorApplication - 1
                                                                    : // then work down for each of the following added ref lines, round and round
                                                                      Math.abs(MAX_NUM_COLOR.DiscreteColorApplication - 1 - referenceLines.length),
                                                            },
                                                        },
                                                    ];
                                                });
                                            }}
                                        >
                                            New Reference Line
                                        </Button>
                                    );
                                })()}
                            </div>
                        </div>
                    </>
                );
            },
            override: (() => {
                if (!editing.value) {
                    return;
                }
                if (!editing.value.id) {
                    return LoadingIndicator({
                        className: "reference-line-loading-24f",
                    });
                }
                const rli = tile.value.referenceLines.findIndex((rl) => rl.id === editing.value.id);

                if (editing.value.type === "color") {
                    return (
                        <LeftPanelClosable
                            {...{
                                onClose: () => editing.onChange({id: editing.value.id}),
                                label: `${tile.value.referenceLines[rli].label} color`,
                                content: ReferenceLineColorConfig({
                                    referenceLine: scope(tile, ["referenceLines", rli]),
                                }),
                            }}
                        />
                    );
                }

                return (
                    <LeftPanelClosable
                        {...{
                            onClose: () => editing.onChange(null),
                            label: "Reference Line",
                            content: EditReferenceLine({
                                tile,
                                referenceLine: scope(tile, ["referenceLines", rli]),
                                onDelete: () => {
                                    editing.onChange(null);
                                    spc(tile, ["referenceLines"], (rls) => rls.filter((rl1) => rl1.id !== editing.value.id));
                                },
                                onConfigColor: () => {
                                    editing.onChange({
                                        id: editing.value.id,
                                        type: "color",
                                    });
                                },
                            }),
                        }}
                    />
                );
            })(),
        })
    );
