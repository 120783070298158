import React from "react";
import {cs} from "@common/react/chain-services";
import {DropdownSelectSearch} from "@common/ui-components/dropdown-select/dropdown-select-search";
import "./column-select-from-model-columns.scss";
import {rFieldTypeIcon} from "../../../../common/field-type-icon/get-field-type-icon";

/**
 * simply choose a column from a set of model columns, no relationships counted.
 * to take relationships into account, use column-value-drilling-select.jsx
 */

export const ColumnSelectFromModelColumns = ({state, columns, label, rightInfo, overrideIsSelected, ...props}) =>
    cs(({}) => {
        return (
            <div className="column-select-from-model-columns column-select-from-model-columns-yfj">
                {DropdownSelectSearch({
                    ...props,
                    label,
                    searchPlaceholder: "Find column",
                    list: columns,
                    valueToLabel: (c) => (
                        <div className="column-label">
                            {rFieldTypeIcon(c.dataType)}
                            <div className="text">{c.name}</div>

                            {rightInfo && <div className="right-info">{rightInfo(c)}</div>}
                        </div>
                    ),
                    valueToSearch: (c) => c.name,
                    isSelected: overrideIsSelected ? overrideIsSelected : (c) => state.value === c.id,
                    onChange: state.onChange,
                })}
            </div>
        );
    });
