import {cx} from "emotion";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import "./banner-closable.scss";
import {getDuplicatesMapping} from "../flat-file-mapping-helpers";
import TableIcon from "../../../../../assets/icons/common/TableIcon";
import ColumnIcon from "../../../../../assets/icons/data-sources/ColumnIcon";
import {Watch} from "@common/react/watch";

export const BannerClosable = ({initExpand = false, forceExpand, content, height, success = false, error = false}) =>
    cs(
        ["expanded", (_, next) => UseState({initValue: initExpand, next})],
        // ({expanded}, next) => expanded.value ? next() : null,
        ({expanded}) => (
            <>
                {Watch({
                    value: forceExpand,
                    onChanged: (value) => expanded.onChange(value),
                })}

                <div
                    style={{maxHeight: expanded.value ? height : 0}}
                    className={cx("banner-closable-99p", {
                        success: success,
                        errors: error,
                        active: expanded.value,
                    })}
                >
                    <div className="close-icon" onClick={() => !forceExpand && expanded.onChange(false)}>
                        <span className="material-icons-outlined">close</span>
                    </div>

                    <div className="content">{content}</div>
                </div>
            </>
        )
    );

export const ErrorInfo = ({dataSource, expanded}) =>
    cs(() => {
        const tablesMappingDuplicates = getDuplicatesMapping(dataSource.tables);
        const columnsMappingDuplicates = dataSource.tables
            .map((t) => ({
                table: t.name,
                columns: t.columns,
                duplicates: getDuplicatesMapping(t.columns),
            }))
            .filter((c) => c.duplicates.length > 0);

        return (
            <>
                <div className="left">
                    <i className="fa-solid fa-triangle-exclamation"></i>
                </div>

                <div className="right">
                    <div className="label">3 mapping errors.</div>
                    <div className="messages">
                        {tablesMappingDuplicates.map((duplicate) => (
                            <div className="item">
                                {TableIcon({fill: "#FF5959"})}
                                {dataSource.tables
                                    .filter((t) => t.mapsToName === duplicate)
                                    .map((t) => t.name)
                                    .join(" and ")}{" "}
                                are mapped to the same table
                            </div>
                        ))}

                        {columnsMappingDuplicates.map((c) => (
                            <>
                                {c.duplicates.map((duplicate) => (
                                    <div className="item">
                                        {ColumnIcon({})}
                                        {c.table}:{" "}
                                        {c.columns
                                            .filter((t) => t.mapsToName === duplicate)
                                            .map((t) => t.name)
                                            .join(" and ")}{" "}
                                        are mapped to the same column
                                    </div>
                                ))}
                            </>
                        ))}
                    </div>
                </div>
            </>
        );
    });

export const SuccessInfo = ({}) =>
    cs(() => (
        <>
            <div className="left">
                <i className="fa-solid fa-circle-check"></i>
            </div>

            <div className="right">
                <div className="label">File upload successful.</div>

                <div>All sheets/tabs have been converted to separate tables in Verb. All tables will be imported.</div>
            </div>
        </>
    ));
