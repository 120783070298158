import React from "react";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {DialogService} from "../../../../common/dialog/dialog-service";
import {UseState} from "@common/react/use-state";
import {Form2} from "@common/react/cs-form/form2";
import {chain} from "@common/utils/fs";
import {validateEmail} from "@common/react/cs-form/validators/validate-email";
import {TextInput} from "../../../../../../../common/form/text-input/text-input";
import {ffToTextInput} from "../../../../../../../common/form/ff-to-text-input";
import "./email-instructions-dialog.scss";
import {Button} from "../../../../../../../common/form/buttons/button/button";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";

export const EmailInstructionsDialog = ({next: rootNext, onClose}) =>
    cs(
        [
            "modal",
            ({collection}, next) =>
                DialogService({
                    render: ({resolve, args: {template}}) => ({
                        title: "Email Instructions",
                        width: 560,
                        content: next({
                            resolve,
                            template,
                        }),
                    }),
                    next: rootNext,
                }),
        ],
        consumeContext("authEndpoint"),
        consumeContext("collection"),
        ["data", (_, next) => UseState({next})],
        ["sending", (_, next) => UseState({next})],
        [
            "form",
            ({data, sending, authEndpoint, collection, modal}, next) =>
                cs(consumeContext("apis"), ({apis}) =>
                    Form2({
                        data,
                        fields: {
                            emailAddresses: [
                                {
                                    validate: (addresses) => addresses && chain(addresses.split(/\s*,\s*/), (_) => _.length && _.findIndex((e) => !e || !validateEmail(e)) === -1),
                                },
                            ],
                        },
                        onSubmit: async () => {
                            sending.onChange({sending: true});

                            var apiKey = await apis.tenantEnv.getSDKApiKey();

                            await apis.collection.shareCollection({
                                collectionId: collection.value.id,
                                emails: data.value.emailAddresses.split(/\s*,\s*/),
                                code1: modal.template.dashboard({
                                    includeFilter: collection.value.hideAllFilters,
                                    collection: collection.value,
                                    hiddenFilterIds: collection.value.filters
                                        .filter((f) => f.hidden)
                                        .map((f) => f.id)
                                        .join(","),
                                }),
                                code2: modal.template.script({
                                    apiKey,
                                    authEndpoint: authEndpoint.value,
                                }),
                            });
                            sending.onChange({sent: true});
                            modal.resolve();
                            onClose();
                        },
                        next,
                    })
                ),
        ],
        ({form, sending, modal}) => (
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    form.submit();
                }}
            >
                <div className="email-instructions-9ok">
                    <VerbDialogBodyScrollbar>
                        <div className="instruction">Send instructions and embed code to your team. You may enter multiple addresses with a comma separated list.</div>

                        {TextInput({
                            placeholder: "Enter e-mail addresses, seperated by commas",
                            disabled: sending.value?.sent,
                            label: "Email Addresses",
                            ...ffToTextInput(form.field(["emailAddresses"])),
                        })}
                    </VerbDialogBodyScrollbar>

                    <div className="buttons">
                        <Button btnType="secondary" onClick={() => modal.resolve()}>
                            Cancel
                        </Button>
                        <Button type="submit" disabled={!form.looksValid || sending.value}>
                            {sending.value == null ? "Send" : sending.value.sending ? "Sending..." : "Sent"}
                        </Button>
                    </div>
                </div>
            </form>
        )
    );
