import * as React from "react";
import {cs} from "@common/react/chain-services";
import "./display.scss";
import {scope} from "@common/react/scope";
import {ThumbnailSelect} from "../../../../../../../../../common/thumbnail-select/thumbnail-select";
import {stateToSelect} from "@common/ui-components/form/state-to-select";
import {LineWidthSelect} from "../../../common/line-width-select";
import {LineMarkerSelect, LineMarkerToggle} from "../../../common/line-marker-select";
import {getPath} from "@common/utils/arr-path";
import {scc} from "@common/react/state-cascade-change";
import {MultipleAxisOptions} from "../../../common/multiple-axis-options";

export const Display = ({tile}) => ({
    initExpand: true,
    label: "Display",
    render: () =>
        cs(({}) => {
            const firstLineKey = Object.keys(tile.value.style.yAxisLine)[0];
            const getLineState = (attr) => ({
                value: firstLineKey && getPath(tile.value.style.yAxisLine, [firstLineKey, attr]),
                onChange: (v) => scc(scope(tile, ["style", "yAxisLine"]), `[*].${attr}`, () => v),
            });

            return (
                <div className="combo-chart-displays-3qg">
                    <div className="bar-display">
                        {ThumbnailSelect({
                            ...stateToSelect(scope(tile, ["style", "yAxisBarDisplayType"]), ["name"]),
                            list: types.map((type) => ({
                                ...type,
                                label: type.name,
                            })),
                        })}

                        {MultipleAxisOptions({
                            state: {
                                value: getPath(tile.value.style, ["multipleAxisBarOption"]),
                                onChange: (v) => scc(scope(tile, ["style"]), `multipleAxisBarOption`, () => v),
                            },
                            label: "Multiple Axis Options on Bars",
                        })}
                    </div>

                    <div className="subsection line-display">
                        <div className="header">Line Display</div>
                        <div className="content">
                            {ThumbnailSelect({
                                list: lineTypes,
                                ...stateToSelect(getLineState("displayType"), ["name"]),
                            })}

                            {LineWidthSelect(getLineState("thickness"))}

                            {MultipleAxisOptions({
                                state: {
                                    value: getPath(tile.value.style, ["multipleAxisLineOption"]),
                                    onChange: (v) => scc(scope(tile, ["style"]), `multipleAxisLineOption`, () => v),
                                },
                                label: "Multiple Axis Options on Lines",
                            })}
                        </div>
                    </div>

                    {(() => {
                        const state = getLineState("marker");
                        return (
                            <div className="subsection line-marker">
                                <div className="header">
                                    Markers
                                    <div className="control">{LineMarkerToggle(state)}</div>
                                </div>
                                {state.value !== "None" && <div className="content">{LineMarkerSelect(state)}</div>}
                            </div>
                        );
                    })()}
                </div>
            );
        }),
});

const types = [
    {
        name: "Classic",
        thumbnail: require("./icons/combo-classic-icon.svg"),
    },
    {
        name: "Stacked",
        thumbnail: require("./icons/combo-stacked-icon.svg"),
    },
];

const lineTypes = [
    {
        label: "Straight",
        name: "Line",
        thumbnail: require("../../../line-chart/panels/display/icons/line-straight-icon.svg"),
    },
    {
        label: "Smooth",
        name: "Spline",
        thumbnail: require("../../../line-chart/panels/display/icons/line-smooth-icon.svg"),
    },
];
