const {getLegendOptions} = require("../../factory/legend");
const {useColorAxis} = require("../map-helper");
const {cGetFontSize} = require("../../common/style-map/font-size");

const mapLegendOptions = ({tile, theme}) => {
    const legend = getLegendOptions({style: tile.style.legendStyle, theme});
    const getFontSize = cGetFontSize(theme.general.canvas.fontSize, theme);

    return useColorAxis(tile)
        ? {
              legend: {
                  enabled: false,
                  itemStyle: {
                      fontFamily: `"${theme.dataVisualization.fonts.fontFamily || theme.general.canvas.fontFamily}", sans-serif`,
                      fontSize: getFontSize({
                          group: theme.dataVisualization.toolTipsAndLegends.legendFontSize,
                      }),
                  },
              },
          }
        : {
              legend: {
                  ...legend,
                  enabled: false,
              },
          };
};
exports.mapLegendOptions = mapLegendOptions;
