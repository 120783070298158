import "./select-field.scss";

import {cx} from "emotion";
import * as React from "react";

import {keyed} from "@common/react/keyed";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {consumeContext} from "@common/react/context";

import {chain} from "@common/utils/fs";
import {isMatchText} from "@common/utils/strings";

import {SearchInput} from "@common/form/search-input/search-input";

import {LeftPanelClosable} from "../../../../../../../../../common/left-panel-closable/left-panel-closable";
import {Expandable} from "../../../../../../../../../common/expandable/expandable";
import {rFieldTypeIcon} from "../../../../../../../../../common/field-type-icon/get-field-type-icon";
import {TruncatingTooltip} from "../../../../../../../../../common/truncating-tooltip/truncating-tooltip";
import {getHasSecurityWarningTables} from "../../../../../../../../../model/edit/main/model-utils";
import {tooltipService3} from "../../../../../../../../../common/tooltip3/tooltip-service-3";
import {SelectFieldOrConstantValueComp} from "./select-field-or-constant-value";
import {isAuthorized} from "../../../../../../../../../../verb-web/authorization/authorization";

export const SelectField = ({
    $type,
    label,
    onCancel,
    actionType = "add", // "add", "change"
    onSelect,
    allowedFieldType,
    notAllowedFields,
    allowSameFieldsInWell,
    allowAggregations,
    hideAggregatedMeasureColumn,
    filterColumn = (c) => true,
    field,
    enableConstantValue,
    constantField,
    onChooseValueType,
    constantFieldSelector,
}) => {
    const Comp = enableConstantValue ? SelectFieldOrConstantValueComp : SelectFieldComp;
    return (
        <LeftPanelClosable
            {...{
                onClose: onCancel,
                label,
                content: Comp({
                    $type,
                    label,
                    onCancel,
                    actionType,
                    onSelect,
                    allowedFieldType,
                    notAllowedFields,
                    allowSameFieldsInWell,
                    allowAggregations,
                    hideAggregatedMeasureColumn,
                    filterColumn,
                    field,
                    constantField,
                    onChooseValueType,
                    constantFieldSelector,
                }),
            }}
        />
    );
};

export const SelectFieldComp = ({
    $type,
    label,
    onCancel,
    actionType = "add", // "add", "change"
    onSelect,
    allowedFieldType,
    notAllowedFields,
    allowSameFieldsInWell,
    allowAggregations,
    hideAggregatedMeasureColumn,
    filterColumn = (c) => true,
}) =>
    cs(
        consumeContext("modelForCollection"),
        consumeContext("routing"),
        consumeContext("auth"),
        tooltipService3({direction: "below"}),
        ["search", (_, next) => UseState({next})],
        ({search, tooltip, modelForCollection: model, routing, auth}) => {
            if (!model) {
                return null;
            }

            const warningTables = getHasSecurityWarningTables({
                tables: model.tables
                    .filter((t) => !t.excludeFromModel)
                    .map((t) => ({
                        id: t.id,
                        columns: t.columns.filter((c) => c).map((c) => c.id), // somehow there can be undefined columns here so need to filter them out
                        rowLevelSecurity: t.rowLevelSecurity,
                    })),
                relationships: model.relationships,
            });

            const searchedTables = search.value
                ? model?.tables
                      .map((table) => {
                          const filteredColumns = table.columns.filter((column) => isMatchText(column.visualNameShort, search.value));
                          if (filteredColumns.length > 0) {
                              return {
                                  ...table,
                                  columns: filteredColumns,
                              };
                          }
                      })
                      .filter((v) => v)
                : model?.tables;

            const isDisabled = (table, column) => {
                if (allowAggregations) {
                    const columnType = getColumnType(column);

                    const allowMultiple = allowAggregations(columnType, $type, table, column);
                    if (allowMultiple && allowMultiple.list.length == 0) {
                        return true;
                    }
                }

                if (!notAllowedFields?.length) {
                    return false;
                }

                if (
                    chain(
                        notAllowedFields,
                        (naf) => (allowSameFieldsInWell ? naf.filter((f) => f.$type !== $type) : naf),
                        (naf) => naf.findIndex((f) => f.modelTableID === table.id && f.modelColumnID === column.id) > -1
                    )
                )
                    return true;
            };

            const modelAccessAllowed = isAuthorized({
                roles: auth.user.roles,
                routeName: "edit-model",
            });

            return (
                <div className="select-field-0s8">
                    <div className="search">
                        <SearchInput
                            {...{
                                state: search,
                                label: "Search",
                                placeholder: "Find Fields",
                            }}
                        />
                    </div>

                    <Expandable
                        {...{
                            label: model?.name,
                            render: () =>
                                searchedTables
                                    ?.filter((table) => !(table.$type === "ViewModelTable" && table.versionDetails === "NotPublished"))
                                    .filter((table) => !allowedFieldType || table.columns.filter((c) => getColumnType(c) === allowedFieldType).length > 0)
                                    .filter((table) => table.columns.filter((c) => filterColumn(c)).length > 0)
                                    .map((table, i) =>
                                        cs(
                                            keyed(i),
                                            [
                                                "truncatingTooltip",
                                                (_, next) =>
                                                    TruncatingTooltip({
                                                        direction: "above",
                                                        next,
                                                    }),
                                            ],
                                            ({truncatingTooltip}) => {
                                                return (
                                                    <Expandable
                                                        {...{
                                                            label: (
                                                                <div {...truncatingTooltip.tooltip(table.visualName)}>
                                                                    <div ref={truncatingTooltip.ref}>{table.visualName}</div>
                                                                </div>
                                                            ),
                                                            icon: (
                                                                <img
                                                                    src={
                                                                        table.$type === "ViewModelTable"
                                                                            ? require("../../../../../../../../../common/icons/data-view-icon.svg")
                                                                            : require("../../../../../../../../../common/icons/table-icon.svg")
                                                                    }
                                                                    alt=""
                                                                />
                                                            ),
                                                            control: (
                                                                <>
                                                                    {warningTables.includes(table.id) && (
                                                                        <img
                                                                            className="security-warning"
                                                                            {...{
                                                                                ...tooltip("Not associated to row-level security"),
                                                                            }}
                                                                            src={require("./row-level-security-alert-2.svg")}
                                                                            alt="Not associated to row-level security"
                                                                        />
                                                                    )}
                                                                    {table.$type === "ViewModelTable" && modelAccessAllowed ? (
                                                                        <div
                                                                            className="edit-transformation-link"
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                e.stopPropagation();
                                                                                routing.goto("edit-data-view", {
                                                                                    modelId: model.id,
                                                                                    transformationId: table.transformationID,
                                                                                });
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src={require("../../../../../../../../../model/edit/tabs/common/column-line/column-line-actions/edit-column-icon.svg")}
                                                                            />
                                                                        </div>
                                                                    ) : (
                                                                        <div className="space" />
                                                                    )}
                                                                </>
                                                            ),
                                                            level: 2,
                                                            noTopBorder: i === 0,
                                                            initExpand: false,
                                                            passiveExpand: !!search.value,
                                                            render: () => {
                                                                const handleSelect = (column) => {
                                                                    const fieldProps = {
                                                                        modelID: model.id,
                                                                        modelTableID: table.id,
                                                                        modelColumnID: column.id,
                                                                        dataType: column.dataType,
                                                                        displayName: column.visualNameShort,
                                                                    };

                                                                    const columnType = getColumnType(column);

                                                                    const allowMultipleAggregations = (aggType, $type, table, column) => {

                                                                        if (!allowAggregations) return null;
                                                                        if (!allowAggregations(aggType, $type, table, column)) return null;

                                                                        const list = allowAggregations(aggType, $type, table, column).list;

                                                                        const isHaveDefaultValue = list.find((item) => item.value == defaultAggregationFunctions[aggType]);

                                                                        if (isHaveDefaultValue) return defaultAggregationFunctions[aggType];

                                                                        return list[0].value;
                                                                    };

                                                                    const defaultDate = {
                                                                        ...defaultDateProperties,
                                                                        aggregation: allowMultipleAggregations("date", $type, table, column) ?? defaultDateProperties.aggregation,
                                                                    };

                                                                    const defaultAggFunc = {
                                                                        number: allowMultipleAggregations("number", $type, table, column) ?? defaultAggregationFunctions.number,
                                                                        text: allowMultipleAggregations("text", $type, table, column) ?? defaultAggregationFunctions.text,
                                                                        // AB#5373: treat  column's type is uuid as a text column
                                                                        id: allowMultipleAggregations("text", $type, table, column) ?? defaultAggregationFunctions.text,
                                                                        date: allowMultipleAggregations("date", $type, table, column) ?? defaultAggregationFunctions.date,
                                                                        bool: allowMultipleAggregations("bool", $type, table, column) ?? defaultAggregationFunctions.bool,
                                                                    };

                                                                    const otherProps = {
                                                                        MeasureTileField: {
                                                                            aggregationFunc: defaultAggFunc[columnType] ?? defaultAggregationFunctions.text,
                                                                            numericProperties: getDefaultNumericProperties(columnType),
                                                                        },
                                                                        DimensionTileField: {
                                                                            // sort: fieldProps,
                                                                            // limit: fieldProps,
                                                                            ...(columnType === "date" && {
                                                                                dateProperties: defaultDate,
                                                                            }),
                                                                        },
                                                                        DimensionlessMeasureTileField: {
                                                                            aggregationFunc: defaultAggFunc[columnType] ?? defaultAggregationFunctions.text,
                                                                            // sort: fieldProps,
                                                                            // limit: fieldProps,
                                                                            numericProperties: getDefaultNumericProperties(columnType),
                                                                            ...(columnType === "date" && {
                                                                                dateProperties: defaultDate,
                                                                            }),
                                                                        },
                                                                        CategoryTileField: {
                                                                            ...(columnType === "date" && {
                                                                                dateProperties: defaultDate,
                                                                            }),
                                                                        },
                                                                        PivotCategoryTileField: {
                                                                            ...(columnType === "date" && {
                                                                                dateProperties: defaultDate,
                                                                            }),
                                                                        },
                                                                    };

                                                                    onSelect({
                                                                        ...fieldProps,
                                                                        ...otherProps[$type],
                                                                    });
                                                                };

                                                                const handleChange = (column) => {
                                                                    onSelect({
                                                                        modelID: model.id,
                                                                        modelTableID: table.id,
                                                                        modelColumnID: column.id,
                                                                        dataType: column.dataType,
                                                                        displayName: column.visualNameShort,
                                                                    });
                                                                };

                                                                return chain(
                                                                    table.columns,
                                                                    (_columns) =>
                                                                        allowedFieldType ? _columns.filter((column) => getColumnType(column) === allowedFieldType) : _columns,
                                                                    (_columns) =>
                                                                        _columns.filter(
                                                                            // somehow there can be undefined columns here so need to filter them out, too
                                                                            (column) =>
                                                                                column &&
                                                                                !column.deleted &&
                                                                                !(
                                                                                    column.$type === "CalculatedModelColumn" &&
                                                                                    column.calculations?.filter((cal) => cal.versionDetails !== "NotPublished").length === 0
                                                                                ) &&
                                                                                !(column.$type === "AggregatedMeasureModelColumn" && column.versionDetails === "NotPublished")
                                                                        ),
                                                                    // AB#1215 Aggregated Measures can only be used as data in collections.
                                                                    (_columns) =>
                                                                        !["DimensionlessMeasureTileField", "MeasureTileField"].includes($type) || hideAggregatedMeasureColumn
                                                                            ? _columns.filter((column) => column.$type !== "AggregatedMeasureModelColumn")
                                                                            : _columns,
                                                                    (_columns) => _columns.filter((c) => filterColumn(c))
                                                                ).map((column, j) =>
                                                                    cs(keyed(j), () =>
                                                                        RenderColumn({
                                                                            column,
                                                                            key: j,
                                                                            actionType,
                                                                            handleChange,
                                                                            handleSelect,
                                                                            table,
                                                                            model,
                                                                            disabled: isDisabled(table, column),
                                                                            modelAccessAllowed,
                                                                        })
                                                                    )
                                                                );
                                                            },
                                                        }}
                                                    />
                                                );
                                            }
                                        )
                                    ),
                        }}
                    />
                </div>
            );
        }
    );

const RenderColumn = ({column, key, actionType, handleChange, handleSelect, model, table, disabled, modelAccessAllowed}) =>
    cs(consumeContext("routing"), ["truncatingTooltip", (_, next) => TruncatingTooltip({direction: "above", next})], ({routing, truncatingTooltip}) => (
        <div
            key={key}
            className={cx("field-line-3sw", {disabled})}
            onClick={() => {
                if (disabled) {
                    return;
                }
                actionType === "change" ? handleChange(column) : handleSelect(column);
            }}
            {...truncatingTooltip.tooltip(column.visualNameShort)}
        >
            {column.$type === "CalculatedModelColumn" && (
                <img className="calculated-plus-icon" src={require("../../../../../../../../../model/edit/tabs/common/icons/calculated-plus-icon.svg")} alt="" />
            )}
            {column.$type === "AggregatedMeasureModelColumn" && (
                <img className="aggregated-measure-icon" src={require("../../../../../../../../../model/edit/tabs/common/icons/aggregated-measure-icon.svg")} alt="" />
            )}
            {rFieldTypeIcon(column.dataType)}
            <div className="name" ref={truncatingTooltip.ref}>
                {column.visualNameShort}
            </div>
            {["CalculatedModelColumn", "AggregatedMeasureModelColumn"].indexOf(column.$type) > -1 && modelAccessAllowed && (
                <div
                    className="edit-transformation-link"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        // routing.goto("edit-model", {modelId: model.id, tableID: table.id, columnID: column.id});
                        routing.goto("edit-transformed-column", {
                            tab: "build",
                            modelId: model.id,
                            modelTableId: table.id,
                            transformationId: column.transformationID || column.calculations[0].transformationID,
                        });
                    }}
                >
                    <img src={require("../../../../../../../../../model/edit/tabs/common/column-line/column-line-actions/edit-column-icon.svg")} />
                </div>
            )}
        </div>
    ));

export const getColumnType = (column) => {
    if (!column) {
        return;
    }

    const typeMap = {
        number: ["Double", "Int"],
        date: ["DateTime", "DateTimeOffset"],
        bool: ["Bool"],
        id: ["IDUUID"],
    };

    for (const type in typeMap) {
        if (typeMap[type].includes(column.dataType)) {
            return type;
        }
    }

    return "text";
};

export const getDefaultNumericProperties = (columnType) => ({
    displayType: "Number",
    decimalSeperator: "Dot",
    thousandsSeperator: "Comma",
    decimalPlaces: columnType === "text" ? 0 : 2,
    negativeFormat: "Parentheses",
});

export const defaultDateProperties = {
    aggregation: "None",
    format: "",
};

export const defaultAggregationFunctions = {
    number: "Sum",
    text: "Count",
    date: "Count",
    bool: "Count",
};
