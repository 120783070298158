import * as React from "react";
import {css, cx} from "emotion";

import {cs} from "@common/react/chain-services";

import {Dropdown} from "../../../dropdown/dropdown";

import {downloadBlob} from "@common/utils/browser/download-service";
import {canDownloadDirectly} from "@common/utils/browser/can-download-directly";

import {EllipsisVAlt} from "../icons/icons";
import {IframeDownloadModal} from "../iframe-modal";
import {consumeContext} from "@common/react/context";
import {downloadWithToast} from "@common/ui-components/toast/downloadWithToast";
import {StyledClass} from "@common/react/styled-class";

export const MenuOptions = ({theme, tile, downloadData, tileFilters, chartRef}) =>
    cs(
        consumeContext("selectedTimezone"),
        consumeContext("downloadToast"),
        [
            "iconClass",
            ({}, next) =>
                StyledClass({
                    content: {
                        svg: {
                            path: {
                                fill: `${theme.general.components.inputIconColorRGB} !important`,
                                "fill-opacity": "1 !important",
                            },
                        },
                    },
                    next,
                }),
        ],
        [
            "renderToggle",
            ({iconClass}, next) =>
                next(({showExpand, showingExpand}) => (
                    <div className={cx("toggle", {expanding: showingExpand}, iconClass)} onClick={() => showExpand(!showingExpand)}>
                        {EllipsisVAlt({width: "17px"})}
                    </div>
                )),
        ],
        [
            "onDownload",
            ({selectedTimezone, downloadToast}, next) =>
                cs(["iframeModal", (_, next) => IframeDownloadModal({next})], ({iframeModal}) =>
                    next(async () => {
                        const format = tile.style.downloadFormat;

                        const params = {
                            format,
                            timeZoneId: selectedTimezone?.value,
                            filters: tileFilters.getValue(),
                        };

                        if (!canDownloadDirectly()) {
                            iframeModal.show({
                                href:
                                    window.location.href +
                                    `&downloadTileData=${tile.id}&filterVals=` +
                                    encodeURIComponent(JSON.stringify(params)),
                            });
                        } else {
                            const {blob, filename} = await downloadWithToast({
                                fetch: () => downloadData(params),
                                toast: downloadToast,
                                _key: `${tile.id}${Date.now()}`,
                            });

                            downloadBlob(blob, {
                                name: filename || `${tile.title}.${new Date().toISOString()}.${format.toLowerCase()}`,
                            });
                        }
                    })
                ),
        ],
        [
            "list",
            ({onDownload}, next) =>
                next([
                    {
                        label: "Export Data",
                        condition: () => tile.style.showDownloadData,
                        function: onDownload,
                        // function: async () => {
                        //     const format = tile.style.downloadFormat;
                        //     const {blob, filename} = await downloadData({
                        //         format,
                        //         filters: tileFilters.getValue(),
                        //     });
                        //     console.log(canDownloadDirectly())
                        //
                        //     //console.log("downloaded", filename)
                        //     if (blob) {
                        //         downloadBlob(blob, {name: filename || `${tile.title}.${(new Date()).toISOString()}.${format.toLowerCase()}`});
                        //     }
                        // }
                    },
                    ...(chartRef
                        ? [
                              {
                                  label: "Download Image",
                                  condition: () => tile.style.showDownloadImage,
                                  function: async () => {
                                      // Must fire 2 times to trigger download. Still looking for solution
                                      chartRef.get()?.chart.exportChartLocal();
                                      chartRef.get()?.chart.exportChartLocal();
                                  },
                              },
                          ]
                        : []),
                ]),
        ],
        ["availableList", ({list}, next) => next(list.filter((item) => item.condition()))],
        [
            "renderExpand",
            ({availableList}, next) =>
                next(({close, width}) =>
                    cs(
                        [
                            "cssClass",
                            ({}, next) =>
                                StyledClass({
                                    content: {
                                        background: `${theme.general.components.menuBackgroundColorRGB}`,
                                        color: `${theme.general.components.menuTextColorRGB}`,
                                        "&:hover": {
                                            background: `${theme.general.components.menuHoverBackgroundColorRGB} !important`,
                                        },

                                        "&.selected": {
                                            background: `${theme.general.components.menuHoverBackgroundColorRGB} !important`,
                                        },
                                    },
                                    next,
                                }),
                        ],
                        ({cssClass}) => {
                            const textStyle = {
                                fontFamily: `"${theme.general.canvas.fontFamily}", sans-serif`,
                            };

                            return (
                                <div
                                    className="list"
                                    style={{
                                        minWidth: width,
                                    }}
                                >
                                    {availableList.map((sublist, i) => (
                                        <div className="sublist" key={i}>
                                            {/*{sublist.label && (*/}
                                            {/*    <div className="label" style={{...textStyle}}>*/}
                                            {/*        {sublist.label}*/}
                                            {/*    </div>*/}
                                            {/*)}*/}
                                            <div className="items">
                                                <div
                                                    className={cx("item no-icon", cssClass)}
                                                    style={{...textStyle}}
                                                    onClick={() => {
                                                        sublist.function?.();
                                                    }}
                                                >
                                                    {sublist.label}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            );
                        }
                    )
                ),
        ],
        ({renderToggle, renderExpand, availableList}) =>
            availableList.length > 0 ? (
                <div className={cx("swap-axis swap-axis-90s verb-tile-menu")}>
                    {Dropdown({
                        detectOnWheelEvent: true,
                        registryRender: true,
                        expandClassNames: "swap-axis-expand-f45",
                        renderToggle,
                        renderExpand,
                        borderRadius: theme.general.components.menuCornerRadius,
                    })}
                </div>
            ) : null
    );
