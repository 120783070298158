import {chain} from "@common/utils/fs";
import * as React from "react";
import {LeftPanelClosable} from "../../../../../../../../../../common/left-panel-closable/left-panel-closable";
import {cs} from "@common/react/chain-services";
import {DateTimeFormat} from "./date-time/date-time-format";
import {NumberFormat} from "./number/number-format";
import "./field-format.scss";
import {keyed} from "@common/react/keyed";

export const FieldFormat = ({field, oriField, getFieldInfo, onCancel}) =>
    cs(
        ["fieldInfo", (_, next) => next(getFieldInfo(field.value))],
        ({fieldInfo}, next) => (
            <LeftPanelClosable
                {...{
                    onClose: onCancel,
                    label: " Format `" + fieldInfo.visualNameShort + "` Field",
                    content: next(),
                }}
            />
        ),

        ({fieldInfo}) => (
            <div className="field-formats-23y">
                {(() => {
                    const type = fieldInfo.type;
                    return cs(keyed(type), () =>
                        chain(
                            {
                                date: field.value.aggregationFunc?.startsWith("Count") ? NumberFormat : DateTimeFormat,
                                number: NumberFormat,
                                text: NumberFormat, // the case when text (id included) field has the Count aggregations
                            }[type],
                            (comp) => comp({field})
                        )
                    );
                })()}

                <div className="reset-default-btn" onClick={() => field.onChange(oriField)}>
                    <img src={require("../../../../../../../../../../common/icons/refresh-icon.svg")} alt={""} />
                    Reset to default settings
                </div>
            </div>
        )
    );
