import * as React from "react";
import {cs} from "@common/react/chain-services";
import {FiltersMenu} from "./left-panel/filters-menu";
import {AddingFilter} from "./adding-filter/adding-filter";
import {consumeContext} from "@common/react/context";
import {createAutoSaveCollection} from "../../common/auto-save-collection";

export const FiltersTab = ({next, savingQueue}) =>
    cs(
        consumeContext("collection"),
        consumeContext("routing"),
        [
            "addingFilter",
            ({collection}, next) =>
                AddingFilter({
                    next,
                    collection,
                }),
        ],
        ({addingFilter, collection, routing}) =>
            next({
                renderLeftPanel: () =>
                    FiltersMenu({
                        collection: createAutoSaveCollection({
                            collection,
                            savingQueue,
                        }),
                        onAddFilter: () => routing.goto("add-filter"),
                    }),
                leftPanelOverride: [addingFilter],
            })
    );
