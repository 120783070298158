const {tileFieldAttrs} = require("../../../common/tile-field-attrs");

const disableSelectTileType = (tile) => {
    if(!tile) {
        return false;
    }

    const fieldAttrs = tileFieldAttrs[tile.$type];
    if(!fieldAttrs) return false;
    return fieldAttrs.map((fa) => tile[fa]).filter((v) => v).length !== 0;
};

exports.disableSelectTileType = disableSelectTileType;
