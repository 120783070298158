import * as React from "react";
import "./radio-buttons.scss";
import {css, cx} from "emotion";
import {RadioButton} from "../../../../../../../../web-client/src/routes/common/radio-button/radio-button";
import {cs} from "../../../../../../../react/chain-services";
import {consumeContext} from "../../../../../../../react/context";

export const RadioButtons = ({state, data, isSelected, valueToLabel = (v) => v, theme, size}) =>
    cs(consumeContext("theme"), ({theme}) => {
        const inputTheme = theme.general.components;

        return (
            <div
                className={cx("radio-buttons-53v")}
                style={{
                    border: `${inputTheme.inputBorderWidth}px solid ${inputTheme.inputBorderColorRGB}`,
                    "border-radius": `${inputTheme.inputCornerRadius}px`,
                }}
            >
                {data?.map((d, i) => {
                    const selected = isSelected ? isSelected(d) : state.value?.includes(d);
                    return (
                        <div
                            key={i}
                            className={cx("radio-item", {selected})}
                            style={{
                                color: inputTheme.inputTextColorRGB,
                                "fontFamily": `${theme.general.canvas.fontFamily || "Roboto"}, sans-serif`,
                            }}
                            onClick={() => state.change((list) => (selected ? [] : [d].filter((v) => v)))}
                        >
                            {RadioButton({
                                selected,
                                color: theme.general.buttons.primaryButton.backgroundColorRGB,
                            })}
                            {valueToLabel(d)}
                        </div>
                    );
                })}
            </div>
        );
    });
