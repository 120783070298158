import "./copy-setting-key.scss";

import React from "react";

import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {spc} from "@common/react/state-path-change";
import {copyStringToClipboard} from "@common/utils/browser/copy-string-to-clipboard";
import {GhostButton} from "../../../../../../../../common/form/buttons/ghost-button/ghost-button";
import {consumeContext} from "@common/react/context";
import {Button} from "../../../../../../../../common/form/buttons/button/button";

export const CopySettingKey = ({value, toastMessage, text, delay = 3 * 1000}) =>
    cs(consumeContext("toast"), ["state", (_, next) => UseState({initValue: false, next})], ({state, toast}) => (
        <div className="copy-setting-key-9io copy-setting-key">
            <Button
                btnType="secondary"
                size="large"
                onClick={() => {
                    spc(state, ["copied"], () => true);
                    copyStringToClipboard(value);
                    setTimeout(() => state.onChange(null), delay);
                    toast?.show(toastMessage || "Copied");
                }}
            >
                Copy {text || "Key"}
            </Button>
        </div>
    ));
