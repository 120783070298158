import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import "./conditional-color-and-style.scss";
import {TableSelectField} from "../format-rules/single-color-fortmat-rules/table-select-field/table-select-field";
import {scope} from "@common/react/scope";
import {ColorSchemaDropdown} from "./color-schema-dropdown";
import {ButtonIcon} from "@common/form/buttons/button-icon/button-icon";
import {ResetColorIcon, ResetIcon} from "@common/ui-components/icons/global-icons";

export const ConditionalColorAndStyle = ({state, tile}) =>
    cs(consumeContext("theme"), ({theme}) => {
        const tableColumns = tile.value.actualValueFields.concat(tile.value.aggregatedValues);
        const colorType = state.value.colorType;

        return (
            <div className="conditional-color-and-style-a33">
                <TableSelectField
                    enable
                    label="Highlight Placement"
                    columns={tableColumns}
                    {...scope(state, ["highlightPlacement"])}
                    className="row-col"
                    highlightPlacementSelect
                />

                {ColorSchemaDropdown({
                    ...scope(state, ["fontColor", "index"]),
                    label: "Font Color",
                    onSelectDefaultOption: () =>
                        state.onChange({
                            ...state.value,
                            fontColor: null,
                        }),
                    singleColor: true,
                    list: theme.dataVisualization.tables.fontColorsRGB,
                    className: "row-col",
                    renderColor: (index) => {
                        const color = theme.dataVisualization.tables.fontColorsRGB.find((color, i) => i == index);
                        return (
                            <div className="single-color-wrapper">
                                <div className="color-box" style={{background: color}} />
                                Color {index + 1}
                            </div>
                        );
                    },
                })}

                {colorType == "SingleColor" &&
                    ColorSchemaDropdown({
                        ...scope(state, ["backgroundColor", "index"]),
                        label: "Background Color",
                        list: theme.dataVisualization.tables.singleColorsRGB,
                        onSelectDefaultOption: () =>
                            state.onChange({
                                ...state.value,
                                backgroundColor: null,
                            }),
                        className: "row-col",
                        singleColor: true,
                        renderColor: (index) => {
                            const color = theme.dataVisualization.tables.singleColorsRGB.find((color, i) => i == index);
                            return (
                                <div className="single-color-wrapper">
                                    <div className="color-box" style={{background: color}} />
                                    Color {index + 1}
                                </div>
                            );
                        },
                    })}

                {colorType == "ColorScale" &&
                    ColorSchemaDropdown({
                        ...scope(state, ["backgroundColorScale", "index"]),
                        label: "Background Color Scale",
                        list: theme.dataVisualization.tables.colorScaleSchemes,
                        className: "row-col",
                        onSelectDefaultOption: () =>
                            state.onChange({
                                ...state.value,
                                backgroundColorScale: null,
                            }),
                        hideDefaultOption: true,
                        renderColor: (index) => {
                            const scheme = theme.dataVisualization.tables.colorScaleSchemes.find((color, i) => i == index);

                            return (
                                <div
                                    className="color-item"
                                    style={{
                                        background: `linear-gradient(to right, ${scheme.colorsRGB.join(",")})`,
                                    }}
                                />
                            );
                        },
                    })}

                <div className="row-col" />

                <div className="action-buttons">
                    <ButtonIcon
                        className="delete-button"
                        btnType="ghost-no-border"
                        size="small"
                        icon={<ResetColorIcon />}
                        onClick={() => {
                            state.onChange({
                                ...state.value,
                                highlightPlacement: null,
                                fontColor: null,
                                backgroundColorScale: {index: 0},
                                backgroundColor: null,
                            });
                        }}
                    />
                </div>
            </div>
        );
    });
