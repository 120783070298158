const {MockAuthentication} = require("./load-auth-mock");
const clientUseMockApis = require("./client-use-mock-apis");
const {Authentication} = require("./authentication");
const {provideContext} = require("@common/react/context");
const {cs} = require("@common/react/chain-services");

const loadAuth = ({isPreviewIframe, next}) =>
    cs(
        [
            "auth",
            (_, next) =>
                clientUseMockApis
                    ? MockAuthentication({next})
                    : Authentication({isPreviewIframe, next}),
        ],
        ({auth}) => provideContext("auth", auth, next)
    );
exports.loadAuth = loadAuth;
