const {required} = require("@common/react/cs-form/validators/required");

exports.horizontalBarFormConfig = {
    fields: {
        yAxisField: {
            inGroups: ["fields"],
            validators: [
                {
                    validate: (v) => v != null,
                },
            ],
        },
        xAxisFields: {
            inGroups: ["fields"],
            validators: [
                {
                    validate: (v) => !!v?.length,
                },
            ],
        },
        groupField: {
            inGroups: ["fields"],
            validators: [
                {
                    when: (tile) => tile.xAxisFields && Math.max(...tile.xAxisFields.map((yaf) => yaf.measureFields.length)) > 1,
                    validate: (groupField) => groupField == null,
                    getMessage: () => "Group field must be empty when more than 1 x-axis fields are selected",
                },
            ],
        },
        title: {
            transforms: ["trim"],
            validators: [required],
            debounce: true,
        },
    },
};
