import {lastXDays} from "./last-x-days";
import {xLastWeekMonSun, xLastWeekSunSat, xThisWeekMonSun, xThisWeekSunSat, xToDate, xToday, xTomorrow, xYesterday} from "./x-to-date";
import {addMonth, addYear, truncateMonth, truncateYear, yesterday} from "../../../../../utils/dates/date-object";
import {lastMonth, lastWeek, lastYear} from "./last-x";
import {previousXDays} from "@common/ui-components/live/live-dashboard/common/logic/previous-x-days";

export const defaultDateRangeModes = [
    {
        label: "Yesterday",
        name: "Yesterday",
        ...xYesterday,
    },
    {
        label: "Today",
        name: "Today",
        ...xToday,
    },
    {
        label: "Tomorrow",
        name: "Tomorrow",
        ...xTomorrow,
    },
    {
        label: "This Week (Mon-Sun)",
        name: "ThisWeekMondayFirst",
        ...xThisWeekMonSun,
    },
    {
        label: "This Week (Sun-Sat)",
        name: "ThisWeekSundayFirst",
        ...xThisWeekSunSat,
    },
    {
        label: "Last Week (Mon-Sun)",
        name: "LastWeekMondayFirst",
        ...xLastWeekMonSun,
    },
    {
        label: "Last Week (Sun-Sat)",
        name: "LastWeek",
        ...xLastWeekSunSat,
    },
    {
        label: "Last 7 days",
        name: "Last7Days",
        ...lastXDays(7),
    },
    {
        label: "Last 30 days",
        name: "Last30Days",
        ...lastXDays(30),
    },
    {
        label: "Last 90 days",
        name: "Last90Days",
        ...lastXDays(90),
    },
    {
        label: "Last 365 days",
        name: "Last365Days",
        ...lastXDays(365),
    },
    // {
    //     label: "Last week",
    //     name: "LastWeek",
    //     ...lastWeek(),
    // },
    {
        label: "Previous 7 Days",
        name: "Previous7Days",
        ...previousXDays(7),
    },
    {
        label: "Previous 30 Days",
        name: "Previous30Days",
        ...previousXDays(30),
    },
    {
        label: "Previous 60 Days",
        name: "Previous60Days",
        ...previousXDays(60),
    },
    {
        label: "Previous 90 Days",
        name: "Previous90Days",
        ...previousXDays(90),
    },
    {
        label: "Previous 365 Days",
        name: "Previous365Days",
        ...previousXDays(365),
    },
    // {
    //     label: "Previous 7 Days",
    //     name: "Previous7Days",
    //     ...previousXDays(7),
    // },
    // {
    //     label: "Previous 30 Days",
    //     name: "Previous30Days",
    //     ...previousXDays(30),
    // },
    // {
    //     label: "Previous 60 Days",
    //     name: "Previous60Days",
    //     ...previousXDays(60),
    // },
    // {
    //     label: "Previous 365 Days",
    //     name: "Previous365Days",
    //     ...previousXDays(365),
    // },
    {
        label: "Last month",
        name: "LastMonth",
        ...lastMonth(),
    },
    {
        label: "Last full year",
        name: "LastFullYear",
        ...lastYear(),
    },
    {
        label: "Month to Date",
        name: "MonthToDate",
        ...xToDate({truncate: truncateMonth, add: addMonth}),
    },
    {
        label: "Month to Yesterday",
        name: "MonthToYesterday",
        ...xToDate({truncate: truncateMonth, add: addMonth, subtractFromTo: 1}),
    },
    {
        label: "Year to Date",
        name: "YearToDate",
        ...xToDate({truncate: truncateYear, add: addYear}),
    },
    // {
    //     label: "None (Show all)",
    //     name: "AllTime",
    // },
    // {
    //     label: "Custom",
    //     name: "Custom",
    //     ...lastXDays(2),
    // },
];
