import React from "react";
import QRCode from "qrcode";

import {consumeContext} from "@common/react/context";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {Invoke} from "@common/react/invoke";
import {spc} from "@common/react/state-path-change";
import {Button} from "../../../../../../../common/form/buttons/button/button";
import {LoadingIndicator} from "@common/ui-components/loading-indicator/loading-indicator";
import {chunk} from "@common/utils/arr-util";
import {EnterPasswordStep} from "./enter-password-step";

export const ScanQrcodeStep = ({password, onNext, verifyPasswordText, onBack}) =>
    cs(
        consumeContext("apis"),
        consumeContext("auth"),
        ["state", (_, next) => UseState({next, initValue: {appToken: ""}})],
        ({state, apis, auth}, next) => {
            if (!password.value || password.value.length == 0) {
                return EnterPasswordStep({
                    description: verifyPasswordText,
                    password,
                });
            }

            return next();
        },
        ({apis, auth, state}, next) =>
            !password.value || password.value.length == 0
                ? next()
                : Invoke({
                      next,
                      fn: async () => {
                          let token = null;

                          try {
                              token = await apis.user.getAssociationToken(password.value);
                          } catch (e) {
                              throw new Error("Password not match or Cannot create a secret token.");
                          }

                          spc(state, ["appToken"], () => token);

                          try {
                              const optCode = `otpauth://totp/VerbData:${auth.user.email}?secret=${token}&issuer=VerbData`;
                              const url = await QRCode.toDataURL(optCode);
                              spc(state, ["qrCode"], () => url);
                          } catch (e) {}
                      },
                  }),
        ({state}) => {
            const {appToken, qrCode} = state.value;
            return (
                <>
                    <div className="content">
                        <div className="scan-qr-code">
                            {!appToken || !qrCode ? (
                                LoadingIndicator({})
                            ) : (
                                <>
                                    <div className="label">Register Verb</div>
                                    <div className="description">Scan this QR code with a security app to receive a code</div>

                                    {qrCode && <img src={qrCode} alt="" />}

                                    <div className="description">Or enter the following code manually</div>

                                    <pre className="app-token">
                                        {chunk(appToken, 4).map((str, i) => (
                                            <span key={i}>{str}</span>
                                        ))}
                                    </pre>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="buttons">
                        <div className="space" />
                        <Button disabled={!appToken || !qrCode} onClick={() => onNext()}>
                            Enter Code
                        </Button>
                    </div>
                </>
            );
        }
    );
