import * as React from "react";
import {Expandable} from "../../../../../../../../../../../common/expandable/expandable";
import {cs} from "@common/react/chain-services";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {stateToSelect} from "@common/ui-components/form/state-to-select";
import {scope} from "@common/react/scope";
import {spc} from "@common/react/state-path-change";

export const Separators = ({field}) =>
    cs(({}) => (
        <div className="separators-09z">
            {DropdownSelect({
                label: "Decimal Format",
                list: ["Dot", "Comma"],
                // ...stateToSelect(scope(field, ["numericProperties", "decimalSeperator"])),
                isSelected: (v) => v === (field.value.numericProperties?.decimalSeperator || "Dot"),
                onChange: (v) => spc(field, ["numericProperties", "decimalSeperator"], () => v),
            })}
            {DropdownSelect({
                label: "Thousands Format",
                list: ["None", "Comma", "Dot", "Space"],
                // ...stateToSelect(scope(field, ["numericProperties", "thousandsSeperator"])),
                isSelected: (v) => v === (field.value.numericProperties?.thousandsSeperator || "Comma"),
                onChange: (v) => spc(field, ["numericProperties", "thousandsSeperator"], () => v),
            })}
        </div>
    ));
