import React from "react";

const ColumnIcon = ({className}) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="30.859" height="23.999" viewBox="0 0 30.859 23.999">
        <path
            id="Columns_Icon"
            data-name="Columns Icon"
            d="M-1376.24-2807h-10.286v-24h10.287v24Zm-8.572-1.714h6.858v-5.143h-6.858Zm0-6.858h6.858v-5.144h-6.858Zm0-6.857h6.858v-5.143h-6.858Zm18.858,13.47h-8.815v-20.571h8.816v20.57h0Zm-7.347-1.47h5.878v-4.408h-5.878Zm0-5.878h5.878v-4.408h-5.878Zm0-5.878h5.878v-4.407h-5.878Zm-14.7,13.226h-8.816v-20.571H-1388v20.57h0Zm-7.347-1.47h5.878v-4.408h-5.878Zm0-5.878h5.878v-4.408h-5.878Zm0-5.878h5.878v-4.407h-5.878Z"
            transform="translate(1396.813 2831)"
            fill="currentColor"
        />
    </svg>
);

export default ColumnIcon;
