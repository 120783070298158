import {getFieldType} from "./get-field-type";

export const isHundredPercent = (tile) => {
    return (tile.style.displayType || tile.style.yAxisBarDisplayType) === "HundredPercent" || (tile.$type === "LineChartTile" && tile.style.displayType.includes("AreaHundredPercent"));
};

export const isStacked = (tile) => {
    return (tile.style.displayType || tile.style.yAxisBarDisplayType) === "Stacked" || (tile.$type === "LineChartTile" && tile.style.displayType.includes("AreaStacked"));
};

export const isDimDate = (tile) => getFieldType(tile.xAxisField || tile.yAxisField) === "date";

export const hasMeasureGroups = (tile) => {
    if (tile.$type === "ComboChartTile") {
        return tile.yAxisBarFields?.length > 0 || tile.yAxisLineFields?.length > 0;
    }
    if (tile.$type === "HorizontalBarChartTile") {
        return tile.xAxisFields?.length > 0;
    }
    return tile.yAxisFields?.length > 0;
};

export const getMeasureGroups = (tile) => {
    if (tile.$type === "ComboChartTile") {
        return [...(tile.yAxisBarFields || []), ...(tile.yAxisLineFields || [])];
    }
    if (tile.$type === "HorizontalBarChartTile") {
        return tile.xAxisFields;
    }
    return tile.yAxisFields;
};
