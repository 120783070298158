import "./transformation-diagram.scss";

import React from "react";
import {cx} from "emotion";

import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {keyed} from "@common/react/keyed";
import {TransformationDiagramNode} from "./transformation-diagram-node";
import {Static2} from "@common/react/static-2";
import {Invoke} from "@common/react/invoke";
import {MaxZoom, MinZoom, ZoomControls} from "../../../../common/zoom-controls/zoom-controls";
import {TransformationDiagramRelationships} from "./transformation-diagram-relationships";
import {equalDeep} from "@common/utils/objects";
// import {last} from "@common/utils/collections";
import {GlobalMouseMove} from "@common/react/global-mouse-move";
import {spc} from "@common/react/state-path-change";
import {GlobalMouseUp} from "@common/react/global-mouse-up";
import {GlobalKeyDown} from "@common/react/keys/global-key-down";
import {diagramUtils} from "../common/diagram-utils";

const defaultValue = {
    lastPosition: {x: 0, y: 0},
    position: {x: 0, y: 0},
    id: null,
};

export const TransformationDiagram = ({
    diagrams,
    transformation,
    interactions,
    rInstructions,
    draggingItem,
    collisionNode,
    getNewPositionStep,
    getConfiguredColor,
    zoomTransform,
    isAggregatedMeasure,
}) =>
    cs(
        ["svgRef", (_, next) => Static2({next})],
        ["diagramRef", (_, next) => Static2({next})],
        ["selectedConnectionPath", (_, next) => UseState({initValue: null, next})],
        ["hoverValue", (_, next) => UseState({initValue: null, next})],
        ["drawingLine", (_, next) => UseState({initValue: {position: null, inputStep: null}, next})],

        ({svgRef, diagramRef, hoverValue, selectedConnectionPath, drawingLine}) => {
            const zoom = d3
                .zoom()
                .scaleExtent([MinZoom, MaxZoom])
                .on("zoom", (event) => {
                    const {k, x, y} = event.transform;
                    zoomTransform.onChange({
                        scale: k,
                        transform: {x, y},
                    });
                });

            const {scale, transform} = zoomTransform.value;
            const relationships = diagramUtils.getRelationshipsTransformation(diagrams.value);

            return (
                <div className="transformation-diagrams-8er" ref={diagramRef.set}>
                    {ZoomControls({
                        zoom,
                        svgRef,
                        scale,
                        className: "vertical",
                        hideInputScale: true,
                        containerRef: diagramRef,
                        nodes: diagrams.value,
                    })}

                    {selectedConnectionPath.value && (
                        <div
                            className="delete-connection"
                            onClick={() => {
                                const {target, source} = selectedConnectionPath.value;

                                let mappingFn = (d) =>
                                    d.id === target
                                        ? {
                                              ...d,
                                              ...(d.inputStep1ID === source ? {inputStep1ID: null} : {}),
                                              ...(d.inputStep2ID === source ? {inputStep2ID: null} : {}),
                                              ...(d.inputStepID === source ? {inputStepID: null} : {}),
                                          }
                                        : d;

                                diagrams.change(
                                    (_diagrams) => _diagrams.map(mappingFn),
                                    () =>
                                        transformation.change((t) => ({
                                            ...t,
                                            steps: t.steps.map(mappingFn),
                                        }))
                                );
                                selectedConnectionPath.onChange(null);
                            }}
                        >
                            <i className="fa fa-trash" /> <br />
                            Delete <br />
                            Connection
                        </div>
                    )}

                    {rInstructions.render()}

                    {Invoke({
                        onMounted: ({}) => {
                            d3.select(svgRef.get())
                                .call(zoom)
                                .on("click", (e) => {
                                    if (e.target === svgRef.get()) {
                                        interactions.onCancel();
                                        selectedConnectionPath.onChange(null);
                                        drawingLine.onChange({
                                            position: [],
                                            inputStep: null,
                                        });
                                    }
                                });
                        },
                    })}

                    <svg
                        width="100%"
                        height="100%"
                        ref={svgRef.set}
                        className={cx({
                            "has-drawing-line": drawingLine.value?.inputStep?.id,
                        })}
                    >
                        <g transform={`translate(${transform.x}, ${transform.y}) scale(${scale})`}>
                            {TransformationDiagramRelationships({
                                relationships,
                                diagrams: diagrams.value,
                                hoverValue,
                                interactions,
                                selectedConnectionPath,
                            })}

                            {diagrams.value.map((diagram, index) =>
                                cs(keyed(diagram.id ?? diagram.timestamp), () => {
                                    return TransformationDiagramNode({
                                        diagram,
                                        getNewPositionStep,
                                        onStartDragging: (e) => {
                                            draggingItem.change(() => ({
                                                id: diagram.id,
                                                lastPosition: diagram.position,
                                                position: diagram.position,
                                            }));
                                        },
                                        onDragging: (e) => {
                                            const position = {
                                                x: e.x,
                                                y: e.y,
                                            };
                                            diagrams.change((oldD) => oldD.map((d) => (d.id === diagram.id ? {...d, position} : d)));
                                            draggingItem.change((g) => ({
                                                ...g,
                                                position,
                                            }));
                                        },
                                        collisionNode,
                                        onDropped: (getLatestProps) => {
                                            const {draggingItem, diagrams: _diagrams, collisionNode} = getLatestProps();

                                            if (equalDeep(draggingItem.value.lastPosition, draggingItem.value.position)) return;

                                            if (collisionNode) {
                                                let mappingFn = (d) =>
                                                    d.id === diagram.id
                                                        ? {
                                                              ...d,
                                                              ...(d.hasOwnProperty("inputStep1ID")
                                                                  ? {
                                                                        inputStep1ID: d.inputStep1ID ?? collisionNode.id,
                                                                        inputStep2ID: d.inputStep1ID
                                                                            ? collisionNode.id
                                                                            : d.inputStep2ID ?? collisionNode.id,
                                                                    }
                                                                  : {
                                                                        inputStepID: collisionNode.id,
                                                                    }),
                                                              position: {
                                                                  x: collisionNode.position.x,
                                                                  y: collisionNode.position.y + 100,
                                                              },
                                                          }
                                                        : d;

                                                _diagrams.change(
                                                    (oldD) => oldD.map(mappingFn),
                                                    () =>
                                                        transformation.change((old) => ({
                                                            ...old,
                                                            steps: old.steps.map(mappingFn),
                                                        }))
                                                );
                                            }

                                            if (
                                                collisionNode === null &&
                                                !equalDeep(draggingItem.value.lastPosition, draggingItem.value.position)
                                            ) {
                                                interactions.upsertTransformationPosition({
                                                    [draggingItem.value.id]: draggingItem.value.position,
                                                });
                                                diagrams.change((oldD) =>
                                                    oldD.map((d) =>
                                                        d.id === draggingItem.value.id
                                                            ? {
                                                                  ...d,
                                                                  position: draggingItem.value.position,
                                                              }
                                                            : d
                                                    )
                                                );
                                            }

                                            draggingItem.onChange(defaultValue);
                                        },
                                        onConnected: (inputStep, targetStep) => {
                                            if (!diagramUtils.isValidConnected(inputStep, targetStep)) return;

                                            const mappingFunc = (d) =>
                                                d.id === targetStep.id
                                                    ? {
                                                          ...d,
                                                          ...(d.hasOwnProperty("inputStep1ID")
                                                              ? {
                                                                    inputStep1ID: d.inputStep1ID ?? inputStep.id,
                                                                    inputStep2ID: d.inputStep1ID
                                                                        ? inputStep.id
                                                                        : d.inputStep2ID ?? inputStep.id,
                                                                }
                                                              : {
                                                                    inputStepID: inputStep.id,
                                                                }),
                                                      }
                                                    : d;

                                            diagrams.change(
                                                (oldD) => oldD.map(mappingFunc),
                                                () =>
                                                    transformation.change((t) => ({
                                                        ...t,
                                                        steps: t.steps.map(mappingFunc),
                                                    }))
                                            );
                                        },
                                        interactions,
                                        diagrams,
                                        draggingItem,
                                        hoverValue,
                                        drawingLine,
                                        relationships,
                                        selectedConnectionPath,
                                        getConfiguredColor,
                                        isAggregatedMeasure,
                                    });
                                })
                            )}

                            {drawingLine.value.inputStep &&
                                DrawingConnectionLine({
                                    drawingLine,
                                    getNewPositionStep,
                                    svgRef,
                                })}
                        </g>
                    </svg>
                </div>
            );
        }
    );

const DrawingConnectionLine = ({drawingLine, getNewPositionStep, svgRef}) =>
    cs(() => {
        if (!drawingLine.value.inputStep) return null;

        const start = drawingLine.value.position.start;
        const end = drawingLine.value.position.end;
        return (
            <>
                <defs>
                    <marker id="arrow" viewBox="0 0 10 10" refX="5" refY="5" markerWidth="6" markerHeight="6" orient="auto-start-reverse">
                        <path d="M 0 0 L 10 5 L 0 10 z" fill="#8F9EAC" />
                    </marker>
                </defs>
                <path
                    className="link"
                    d={`M ${start.x} ${start.y} L ${end.x} ${end.y}`}
                    strokeWidth="1"
                    stroke="#8F9EAC"
                    markerEnd="url(#arrow)"
                />

                {GlobalMouseMove({
                    fn: (e) => {
                        const newPositionStep = getNewPositionStep({
                            position: {x: e.clientX, y: e.clientY - 2},
                        });
                        spc(drawingLine, ["position"], (position) => ({
                            ...position,
                            end: newPositionStep ?? position.end,
                        }));
                    },
                })}

                {GlobalKeyDown({
                    keyCombo: "Escape",
                    onKeyDown: () => drawingLine.onChange({position: [], inputStep: null}),
                })}

                {GlobalMouseUp({
                    fn: (e) => {
                        // if(e.target === svgRef.get()) {
                        drawingLine.onChange({position: [], inputStep: null});
                        // }
                        e.preventDefault();
                        e.stopPropagation();
                    },
                })}
            </>
        );
    });
