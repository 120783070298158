import {sort, unique} from "@common/utils/collections";
import {chain} from "@common/utils/fs";
import {ModelPanelHelper} from "../../../common/model-panel-helper";
import {RELATIONSHIP_INNER_JOIN, RELATIONSHIP_LEFT_JOIN} from "../model-constrants";

export const SPLIT_CHAR = "|";

export const mapPossibleSide = (table, anchors) => {
    let groupedBySides = [];
    let ret = [];

    for (let anchor of anchors) {
        let found = groupedBySides.find((g) => g.id == anchor.id);
        if (found) {
            found.sides.push(anchor.side);
            found.targetSides.push(anchor.targetTable);
        } else {
            groupedBySides.push({
                id: anchor.id,
                sides: [anchor.side],
                targetSides: [anchor.targetTable],
            });
        }
    }

    for (let group of groupedBySides) {
        const c = count(group.sides);
        const opposite = isHasOppositeSides(c);

        if (opposite) {
            ret.push({
                ...anchors.find((a) => a.id == group.id),
                side: getMaxKey(
                    count(group.targetSides.map((targetTable) => getTargetTableSide(table, targetTable, opposite == "horizontal")))
                ),
            });
        } else {
            ret.push({
                ...anchors.find((a) => a.id == group.id),
                side: getMaxKey(c),
            });
        }
    }

    return ret;
};

const getTargetTableSide = (table, target, isHorizontal) => {
    if (isHorizontal) {
        if (table.position.y > target.position.y) return "top";
        return "bottom";
    } else {
        if (table.position.x > target.position.x) return "left";
        return "right";
    }
};

const isHasOppositeSides = (sides) => {
    if (sides.left && sides.right && !sides.top && !sides.bottom) return "horizontal";
    if (!sides.left && !sides.right && sides.top && sides.bottom) return "vertical";
    return null;
};

const count = (arr) => {
    let ret = {};
    for (let item of arr) {
        if (!ret[item]) ret[item] = 1;
        else ret[item] = ret[item] + 1;
    }

    return ret;
};

const getMaxKey = (obj) => {
    let ret = null;
    let max = 0;
    for (let key in obj) {
        if (obj[key] > max) {
            ret = key;
            max = obj[key];
        }
    }

    return ret;
};

export const getTablesMatchWithRelationship = (tables, relationship) => {
    return tables.filter((table) =>
        table.columns
            .concat(table.disabledColumns || [])
            .find((c) => c.id == relationship.leftColumnID || c.id == relationship.rightColumnID)
    );
};

export const getErrorInRelationships = ({tables, relationships}) => {
    let ret = [];

    for (let relationship of relationships) {
        const getBrokenRelationshipsErrors = (table1, table2) => {
            let errors = [];

            const hasTableDeleted = table1.deleted || table2.deleted;

            if (hasTableDeleted) {
                errors.push({
                    label: `${table1.deleted ? table2.visualName : table1.visualName}: Broken Relationship to ${
                        table1.deleted ? table1.visualName : table2.visualName
                    }`,
                    tableID: table1.deleted ? table1.id : table2.id,
                    relationship: relationship,
                });
            }

            const anchor1DeletedColumn = table1.disabledColumns.find(
                (c) => c.id == relationship.leftColumnID || c.id == relationship.rightColumnID
            );
            const anchor2DeletedColumn = table2.disabledColumns.find(
                (c) => c.id == relationship.leftColumnID || c.id == relationship.rightColumnID
            );
            const hasColumnDeleted = anchor1DeletedColumn || anchor2DeletedColumn;

            if (hasColumnDeleted) {
                errors.push({
                    label: `${anchor1DeletedColumn ? table2.visualName : table1.visualName}: Broken Relationship to ${
                        anchor1DeletedColumn ? table1.visualName : table2.visualName
                    }`,
                    tableID: anchor1DeletedColumn ? table1.id : table2.id,
                    columnID: hasColumnDeleted.id,
                    relationship: relationship,
                });
            }

            return errors;
        };

        const [table1, table2] = getTablesMatchWithRelationship(tables, relationship);
        if (table1 && table2) {
            const errors = getBrokenRelationshipsErrors(table1, table2);
            if (errors.length) {
                ret = ret.concat(errors);
            }
        }
    }

    return ret;
};

export const generateAnchorsInTable = ({table, relationships, tables}) => {
    let targetTables = {};
    let anchors = [];

    const findTargetTable = (relationship, fromKey, toKey) => {
        const columnFound = table.columns.find((c) => c.id == relationship[fromKey]);

        if (columnFound) {
            const target = tables.find((table) => {
                return table.columns.find((c) => c.id == relationship[toKey]);
            });

            if (target) {
                if (targetTables[target.id]) {
                    targetTables[target.id].push({
                        column: columnFound,
                        relationship,
                    });
                } else {
                    targetTables[target.id] = [
                        {
                            column: columnFound,
                            relationship,
                        },
                    ];
                }
            }
        }
    };

    for (let relationship of relationships) {
        findTargetTable(relationship, "leftColumnID", "rightColumnID");
        findTargetTable(relationship, "rightColumnID", "leftColumnID");
    }

    for (let tableID in targetTables) {
        const relatedRelationships = targetTables[tableID].map((v) => v.relationship);
        const targetTable = tables.find((t) => t.id == tableID);

        let anchor = chain(
            targetTables[tableID],
            (arr) => unique(arr, (v) => v.column.id),
            (uniqArr) => sort(uniqArr, (v) => v.name),
            (sortedArr) => {
                return {
                    anchorID: sortedArr.map((v) => v.column.name.toLowerCase()).join("-"),
                    relationships: relatedRelationships,
                    joinType: relatedRelationships[0].joinType,
                    targetTable,
                    side: ModelPanelHelper.getSide(table.position, targetTable.position),
                    tableID: table.id,
                };
            }
        );

        anchors.push(anchor);
    }

    //If multiple tables connect to anchor. Must uniq anchor again.

    let ret = [];

    for (let anchor of anchors) {
        const foundAnchor = ret.find((a) => a.anchorID == anchor.anchorID);
        if (!foundAnchor) {
            ret.push({
                ...anchor,
                relatedTables: [
                    {
                        targetTable: anchor.targetTable,
                        relationships: anchor.relationships,
                    },
                ],
            });
        } else {
            foundAnchor.relatedTables.push({
                targetTable: anchor.targetTable,
                relationships: anchor.relationships,
            });
        }
    }

    return ret;
};

export const drawRelationships = ({anchors}) => {
    let ret = [];
    for (let anchor of anchors) {
        for (let related of anchor.relatedTables) {
            const {targetTable, relationships} = related;

            const ID = sort([anchor.table.id, targetTable.id], (t) => t).join("-");
            const targetAnchor = anchors.find((a) => {
                if (a.table.id != anchor.table.id) {
                    for (let related of a.relatedTables) {
                        const found = related.relationships.find(
                            (r) => r.leftColumnID == relationships[0].leftColumnID && r.rightColumnID == relationships[0].rightColumnID
                        );
                        if (found) {
                            return true;
                        }
                    }
                }

                return false;
            });

            const isGenerated = ret.find((r) => r.id == ID);
            if (!isGenerated && targetAnchor) {
                ret.push({
                    id: ID,
                    tables: [anchor.table, targetTable],
                    relationships: relationships,
                    position: anchor.position,
                    targetPosition: targetAnchor.position,
                    anchors: [`${anchor.id}${SPLIT_CHAR}${anchor.table.id}`, `${targetAnchor.id}${SPLIT_CHAR}${targetAnchor.table.id}`],
                    joinType: relationships[0].joinType,
                });
            }
        }
    }

    return ret;
};

export const generateAnchorsAndRelationships = ({tables, relationships}) => {
    let generatedAnchors = [];

    for (let table of tables) {
        const anchors = generateAnchorsInTable({table, tables, relationships});
        if (anchors.length > 0) {
            generatedAnchors.push({
                tableInfo: table,
                anchors,
            });
        }
    }

    let anchors = [];
    for (let table of generatedAnchors) {
        anchors = anchors.concat(ModelPanelHelper.buildAnchorPosition(table));
    }

    const relationshipsTable = drawRelationships({anchors});

    return {
        anchors,
        relationshipsTable,
    };
};

const isRelationshipsConnectedTogether = ({current, target, relationships}) => {
    let travelled = [];

    const findRelationship = (travelled, relationship) => {
        travelled.push(relationship);
        for (let anchor of relationship.anchors) {
            const [_, tableID] = anchor.split(SPLIT_CHAR);
            const rFound = relationships.filter(
                (r) =>
                    r.anchors.find((a) => {
                        const [_, tID] = a.split(SPLIT_CHAR);
                        return tableID == tID;
                    }) && !travelled.find((_r) => _r.id == r.id)
            );

            for (let found of rFound) {
                if (found.id == target.id) return true;
                else {
                    travelled.push(found);
                    const isMatch = findRelationship(travelled, found);
                    if (isMatch) return true;
                }
            }
        }
    };

    return findRelationship(travelled, current);
};

export const getPotentiallyInvalidRelationships = ({relationships}) => {
    let outerJoinCausingErrors = [];
    let testedOuterJoinRelationships = [];
    let invalidInnerJoinRelationships = [];

    const isMeetInnerJoin = (tableID, travelledOuterJoin) => {
        const rFound = relationships.filter(
            (r) =>
                r.anchors.find((a) => {
                    const [_, tID] = a.split(SPLIT_CHAR);
                    return tableID == tID;
                }) && !testedOuterJoinRelationships.find((_r) => _r.id == r.id)
        );

        for (let r of rFound) {
            if (r.joinType == RELATIONSHIP_LEFT_JOIN) {
                testedOuterJoinRelationships.push(r);
                travelledOuterJoin.push(r);
                for (let a of r.anchors) {
                    const [_, tableID] = a.split(SPLIT_CHAR);
                    const meetInnerJoin = isMeetInnerJoin(tableID, travelledOuterJoin);
                    if (meetInnerJoin) {
                        return meetInnerJoin;
                    }
                }
            } else {
                return r;
            }
        }

        return false;
    };

    for (let relationship of relationships) {
        if (relationship.joinType == RELATIONSHIP_LEFT_JOIN && !testedOuterJoinRelationships.includes(relationship.id)) {
            testedOuterJoinRelationships.push(relationship.id);
            //Find next relationship line until meet inner join relationship and stop.

            const [anchor1, anchor2] = relationship.anchors;

            const [_, tableID] = anchor1.split(SPLIT_CHAR);
            let r1 = [];

            const a1InnerJoin = isMeetInnerJoin(tableID, r1);
            if (!a1InnerJoin) {
                break;
            } else {
                const [_, tableID] = anchor2.split(SPLIT_CHAR);
                let r2 = [];
                const a2InnerJoin = isMeetInnerJoin(tableID, r2);
                if (a2InnerJoin) {
                    outerJoinCausingErrors = outerJoinCausingErrors.concat([...r1, ...r2, relationship]);
                    invalidInnerJoinRelationships = invalidInnerJoinRelationships.concat([a1InnerJoin, a2InnerJoin]);
                }
            }
        }
    }

    for (let relationship of relationships) {
        if (relationship.joinType == RELATIONSHIP_INNER_JOIN && !invalidInnerJoinRelationships.find((r) => r.id == relationship.id)) {
            for (let outerJoinR of outerJoinCausingErrors) {
                if (isRelationshipsConnectedTogether({relationships, current: relationship, target: outerJoinR})) {
                    invalidInnerJoinRelationships.push(relationship);
                }
            }
        }
    }

    return unique(invalidInnerJoinRelationships, (r) => r.id);
};
