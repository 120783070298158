import React from "react";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {cx} from "emotion";
import {Dropdown} from "@common/ui-components/dropdown/dropdown";
import "./column-select.scss";
import {ColumnSelectHelper} from "./column-select-helper";
import {SearchInput} from "../../../../../../../../../../../../../common/form/search-input/search-input";
import {rFieldTypeIcon} from "../../../../../../../../../../common/field-type-icon/get-field-type-icon";
import {Static2} from "@common/react/static-2";
import {Watch} from "@common/react/watch";
import {consumeContext} from "@common/react/context";
import {isNotPublishedCalculatedColumn} from "../../../../../../../common/column-utils";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";
import {isMatchText} from "@common/utils/strings";

export const formatValueDefault = (tables) => {
    return (val) => {
        const {dataSourceTableID, dataSourceColumnID, modelTableID, modelColumnID} = val;

        if (modelTableID || modelColumnID)
            return {
                tableId: modelTableID,
                columnId: modelColumnID,
            };

        if (!dataSourceColumnID || !dataSourceTableID) return null;

        let table = tables.find((t) => t.dataSourceTableID == dataSourceTableID);
        let column = table.columns.find((c) => c.dataSourceColumnID == dataSourceColumnID);

        return {
            tableId: table.id,
            columnId: column.id,
        };
    };
};

export const parseValueDefault = (tables, dataSources) => {
    return (val) => {
        if (!val) return null;

        let table = tables.find((t) => t.id == val.tableId);
        let column = table.columns.find((c) => c.id == val.columnId);
        let dataSource = dataSources.find((d) => d.tables.find((t) => t.id == table.dataSourceTableID));

        if (dataSource && !val.isAggregated) {
            return {
                dataSourceID: dataSource.id,
                dataSourceTableID: table.dataSourceTableID,
                dataSourceColumnID: column.dataSourceColumnID,
            };
        }

        return {
            modelId: val.modelId,
            modelTableID: table.id,
            modelColumnID: column.id,
        };
    };
};

export const ColumnSelect = ({label, onChange, relationships, domRef, hasError, ...otherProps}) => {
    const reMappingColumn = (table) => ({...table, columns: table.columns.concat(table.disabledColumns)});

    const table = reMappingColumn(otherProps.table);
    const tables = otherProps.tables.map((table) => reMappingColumn(table));

    const {formatValue = formatValueDefault(tables), parseValue = parseValueDefault(tables, otherProps.dataSources, otherProps.dataType)} =
        otherProps;

    return cs(
        consumeContext("routing"),
        ["viewExpand", (_, next) => UseState({initValue: false, next})],
        ["value", (_, next) => next(formatValue(otherProps.value) || {})],
        ["active", (_, next) => UseState({next})],
        ["search", (_, next) => UseState({next, initValue: ""})],
        ["scrollContainerRef", (_, next) => Static2({next})],
        ["tablesTree", (_, next) => UseState({initValue: ColumnSelectHelper.buildTree(table, tables, relationships), next})],
        [
            "stack",
            ({tablesTree, value, viewExpand}, next) => next(ColumnSelectHelper.getPath(value || {}, [tablesTree.value], viewExpand.value)),
        ],
        ({}, next) => (
            <div className="column-select-o13 column-select" ref={domRef}>
                {next()}
            </div>
        ),
        [
            "handleSelectColumn",
            ({stack, scrollContainerRef, routing}, next) =>
                next((column, table) => {
                    onChange(
                        parseValue({
                            isAggregated: !column.hasOwnProperty("dataSourceColumnID"),
                            tableId: table.id,
                            columnId: column.id,
                            modelId: routing.params.modelId,
                        })
                    );
                }),
        ],
        [
            "renderColumns",
            ({stack, value, viewExpand}, next) =>
                next((columns, onSelect) =>
                    columns
                        .filter((c) => !isNotPublishedCalculatedColumn(c))
                        .map((column, index) => {
                            return (
                                <div key={index} className={cx("item")}>
                                    <div
                                        className={cx("item-wrapper", {
                                            selected: column.id == value?.columnId,
                                            disabled: column.deleted || table.deleted,
                                        })}
                                        onClick={() => {
                                            if (column.deleted || table.deleted) return;
                                            onSelect(column);
                                            viewExpand.onChange(false);
                                        }}
                                    >
                                        {rFieldTypeIcon(column.dataType)}

                                        <div className="radio-button radio-button-35a" />

                                        <div className="name">{column.name}</div>
                                    </div>

                                    {column.childs.length > 0 && (
                                        <div
                                            className="have-child"
                                            onClick={() => {
                                                onSelect(column);
                                                viewExpand.onChange(true);
                                            }}
                                        >
                                            Expand <i className="fa fa-chevron-right" />
                                        </div>
                                    )}
                                </div>
                            );
                        })
                ),
        ],
        [
            "renderValue",
            ({stack, value}, next) =>
                next(() => {
                    const selectedTable = value.tableId ? tables.find((t) => t.id == value.tableId) : null;
                    const selectedColumn = selectedTable ? selectedTable.columns.find((c) => c.id == value.columnId) : null;
                    const getInfo = ({tableId, columnId}) => {
                        let table = tables.find((t) => t.id == tableId);
                        let column = table.columns.find((c) => c.id == columnId);
                        return {
                            table,
                            column,
                        };
                    };

                    if (stack.length == 0) return selectedColumn.name;

                    let columnText = stack
                        .map((s, index) => {
                            let {table, column} = getInfo(s);
                            if (index == 0) {
                                return column.name;
                            }

                            return `${table.name}.${column.name}`;
                        })
                        .join(".");

                    return `${columnText}${
                        stack[stack.length - 1]?.columnId != selectedColumn.id ? `.${selectedTable.name}.${selectedColumn.name}` : ""
                    }`;
                }),
        ],
        ({tablesTree, search, stack, renderColumns, handleSelectColumn, scrollContainerRef, renderValue, value, routing}) => {
            const {header, list} = ColumnSelectHelper.getListByPath(stack, [tablesTree.value], []);
            const selectedColumn = value.tableId
                ? tables.find((t) => t.id == value.tableId)?.columns.find((c) => c.id == value.columnId)
                : null;

            return (
                <>
                    {Watch({
                        value: stack,
                        onChanged: async (newStack, prevStack) => {
                            if (scrollContainerRef.get() && newStack.length != prevStack.length) {
                                scrollContainerRef.get().scrollTop = 0;
                                search.onChange(null);
                            }
                        },
                    })}

                    {Dropdown({
                        minExpandHeight: 350,
                        minWidth: 300,
                        renderToggle: ({showExpand, showingExpand}) => (
                            <div
                                className={cx(
                                    "toggle",
                                    {expanding: showingExpand, "with-value": value.tableId || otherProps.value === "useStatic"},
                                    selectedColumn?.deleted && "has-error"
                                )}
                            >
                                <div
                                    className={cx("display-value", {"use-static": otherProps.value === "useStatic"})}
                                    onClick={() => showExpand(true)}
                                >
                                    {otherProps.value === "useStatic" && otherProps.useStatic && <span>Use Static Value</span>}
                                    {selectedColumn && (
                                        <>
                                            {rFieldTypeIcon(selectedColumn.dataType)}
                                            <div className="name">{renderValue()}</div>
                                        </>
                                    )}
                                </div>

                                {label && <div className="label">{label}</div>}
                                <i className="fa fa-chevron-down" />
                            </div>
                        ),
                        renderExpand: ({close, width}) => (
                            <div className={cx("list")} style={{width: "100%"}}>
                                <div className="list-header">
                                    <SearchInput
                                        {...{
                                            state: search,
                                            label: "Search",
                                            placeholder: "Find Column",
                                        }}
                                    />
                                </div>

                                {header.length > 0 && (
                                    <div className="header-text">
                                        <i
                                            className="fa fa-chevron-left"
                                            onClick={() =>
                                                onChange({
                                                    ...parseValue(stack[stack.length - 2]),
                                                    modelId: routing.params.modelId,
                                                })
                                            }
                                        />{" "}
                                        {header.slice(1).join(".")}
                                    </div>
                                )}

                                <VerbScrollbar maxHeight="250px" className="list-wrapper" ref={scrollContainerRef.set}>
                                    {stack.length == 0
                                        ? renderColumns(
                                              list[0].columns.filter((c) => isMatchText(c.name, search.value)),
                                              (column) => {
                                                  handleSelectColumn(column, table);
                                                  if (column.childs.length == 0) close();
                                              }
                                          )
                                        : list
                                              .filter((table) => table.columns.filter((c) => isMatchText(c.name, search.value)).length > 0)
                                              .map((table, index) => (
                                                  <div className="table" key={index}>
                                                      <div className="table-name">
                                                          <img
                                                              className="table-icon"
                                                              src={
                                                                  table.$type === "ViewModelTable"
                                                                      ? require("../../../../../../../../../../common/icons/data-view-icon.svg")
                                                                      : require("../../../../../../../../../../common/icons/table-icon.svg")
                                                              }
                                                              alt=""
                                                          />

                                                          {table.name}
                                                      </div>

                                                      <div className="column-list">
                                                          {renderColumns(
                                                              table.columns.filter((c) => isMatchText(c.name, search.value)),
                                                              (column) => {
                                                                  handleSelectColumn(column, table);
                                                                  if (column.childs.length == 0) close();
                                                              }
                                                          )}
                                                      </div>
                                                  </div>
                                              ))}
                                </VerbScrollbar>

                                {otherProps.useStatic && (
                                    <div
                                        className="use-static-value"
                                        onClick={() => {
                                            onChange("useStatic");
                                            close();
                                        }}
                                    >
                                        Use Static Value
                                    </div>
                                )}
                            </div>
                        ),
                    })}
                </>
            );
        }
    );
};
