const {ChildDatasetIcon, ModelTableIcon} = require("./icons/icons");

const inputStepTypes = [
    {
        $type: "ModelTableDatasetStep",
        name: "Table",
        iconSrc: require("./icons/model-table-icon.svg"),
        icon: ModelTableIcon,
    },
    {
        $type: "AggregatedModelTableDatasetStep",
        name: "Table",
        iconSrc: require("./icons/model-table-icon.svg"),
        icon: ModelTableIcon,
    },
    {
        $type: "ChildDatasetStep",
        name: "Child Dataset",
        icon: ChildDatasetIcon,
        iconSrc: require("./icons/child-dataset-icon.svg"),
    },
];
exports.inputStepTypes = inputStepTypes;
