import React from "react";
import {cs} from "../../../../../../../../../common/react/chain-services";
import "./rename-columns-configuration.scss";
import {scope} from "../../../../../../../../../common/react/scope";
import {spc} from "../../../../../../../../../common/react/state-path-change";
import {SelectColumns} from "../../../../common/select-columns/select-columns";
import {TextInput} from "../../../../../../../../../common/form/text-input/text-input";

export const RenameColumnsConfiguration = ({transformation, step, form, model}) => {
    const prevStep = transformation.value.steps.find((s) => s.id === step.value.inputStepID);

    return (
        <div className="rename-columns-configurations-42t">
            <div className="config-group">
                <div className="label">Select Columns to rename</div>

                {SelectColumns({
                    step,
                    form,
                    path: ["columns"],
                    columns: prevStep.outputColumns,
                    objectForm: {
                        columnProp: "column",
                        propertyInput: ({state, ...props}) =>
                            TextInput({
                                label: "Rename To",
                                state,
                                ...props,
                            }),
                        propertyPropInput: "renameTo",
                    },
                })}
            </div>
        </div>
    );
};
