import * as React from "react";
import {css, cx} from "emotion";

import {cs} from "../../react/chain-services";
import {UseState1} from "../../react/use-state";
import {Watch} from "../../react/watch";
import {cGetFontSize} from "../charts/common/style-map/font-size";
import {Invoke} from "../../react/invoke";
import {keyed} from "../../react/keyed";
import {Dropdown} from "../dropdown/dropdown";
import {ObserveDomSize} from "../../react/observe-dom-size";
import {equalDeep} from "../../utils/objects";
import {VerbScrollbar} from "../verb-scrollbar/verb-scrollbar";
import {InfoIconTooltip} from "../charts/common/info-icon-tooltip/info-icon-tooltip";
import {StyledClass} from "@common/react/styled-class";

export const ContainerHeader = ({containerTile, theme, currentTile, onSelectTile, next}) =>
    cs(
        [
            "headerSizeRef",
            ({}, next) =>
                ObserveDomSize({
                    next,
                    ignoreChange: (newSize, oldSize) => newSize.width === oldSize.width,
                }),
        ],
        ["headerHeight", (_, next) => UseState1({next})],
        ["headerType", (_, next) => UseState1({next, initValue: containerTile.style.displayType})],
        [
            "onClickTile",
            (_, next) =>
                next((tile) => {
                    onSelectTile?.(tile);
                    currentTile.onChange(tile);
                }),
        ],
        ({headerType}, next) => (
            <>
                {Watch({
                    value: containerTile,
                    onChanged: ({style, tiles}) => {
                        headerType.onChange(style.displayType);

                        if (tiles.length == 0) {
                            currentTile.onChange(null);
                            return;
                        }

                        if (tiles.length > 0 && (!currentTile.value || !tiles.find((t) => t.id == currentTile.value.id))) {
                            currentTile.onChange(tiles[0]);
                            return;
                        }

                        const _currentTile = tiles.find((tile) => tile.id == currentTile.value.id);
                        if (!equalDeep(_currentTile, currentTile.value.value)) {
                            currentTile.onChange(_currentTile);
                        }
                    },
                })}

                {next()}
            </>
        ),
        [
            "autoChangeToDropdown",
            ({headerType, headerSizeRef, headerHeight}, next) =>
                next(({sidePadding = 0}) =>
                    cs(keyed(headerSizeRef.value?.width), () =>
                        Invoke({
                            onMounted: () => {
                                const itemsContainerElem = headerSizeRef.getElement();

                                if (!itemsContainerElem) return;

                                let totalWidth = sidePadding * 2;

                                itemsContainerElem.childNodes.forEach((itemElem) => {
                                    const {width, marginLeft, marginRight} = getComputedStyle(itemElem);

                                    totalWidth = parseInt(totalWidth) + parseInt(width) + parseInt(marginLeft) + parseInt(marginRight);
                                });

                                const parentWidth = parseInt(getComputedStyle(itemsContainerElem).width);

                                if (totalWidth > parentWidth) {
                                    headerType.onChange("Dropdown");
                                    return;
                                }

                                headerHeight.onChange(parseInt(getComputedStyle(itemsContainerElem.parentNode).height));
                            },
                        })
                    )
                ),
        ],
        [
            "renderTooltip",
            ({headerType}, next) =>
                next((tile) => {
                    const {style} = tile;
                    const getFontSize = cGetFontSize(theme.general.canvas.fontSize, theme);

                    return (
                        <>
                            &nbsp;
                            {InfoIconTooltip({
                                theme,
                                width: getFontSize({
                                    group: theme.general.tile.styles.titleFontSize || theme.general.canvas.fontSize,
                                    elemType: "title_icon",
                                }),
                                showInfoIcon: () => style.showTitleInfoIcon && style.titleInfoIconText,
                                infoText: style.titleInfoIconText,
                            })}
                        </>
                    );
                }),
        ],
        [
            "renderFunc",
            ({headerType, headerSizeRef, onClickTile, headerHeight, autoChangeToDropdown, renderTooltip}, next) => {
                if (containerTile?.tiles?.length == 0) {
                    return next(() => null);
                }

                const {buttonStyle, tabStyle, dropdownStyle} = theme?.general.tile.containerTiles;

                const getFontSize = cGetFontSize(theme.general.canvas.fontSize, theme);

                const mapTypeToRender = {
                    Buttons: () => {
                        const sidePadding = 16;

                        const commonStyle = {
                            fontSize: getFontSize({
                                group: containerTile.style?.fontSize || "Medium",
                                elemType: "label",
                            }),
                            fontFamily: `"${theme.general.canvas.fontFamily || "Roboto"}", sans-serif`,
                            borderRadius: `${buttonStyle.cornerRadius}px`,
                            borderWidth: `${buttonStyle.borderWidth}px`,
                            borderStyle: `solid`,
                            height: "40px",
                            margin: "10px",
                            textAlign: "center",
                            lineHeight: `${40 - buttonStyle.borderWidth * 2}px`,
                            padding: `0 ${sidePadding}px`,
                            cursor: "pointer",
                        };

                        const normalStyle = {
                            ...commonStyle,
                            background: buttonStyle.backgroundColorRGB,
                            borderColor: buttonStyle.borderColorRGB,
                            color: buttonStyle.fontColorRGB,
                        };

                        const hoverStyle = {
                            ...commonStyle,
                            background: buttonStyle.hoverBackgroundColorRGB,
                            borderColor: buttonStyle.hoverFontColorRGB,
                            color: buttonStyle.hoverFontColorRGB,
                        };

                        const activeStyle = {
                            ...commonStyle,
                            background: buttonStyle.activeBackgroundColorRGB,
                            borderColor: buttonStyle.activeBorderColorRGB,
                            color: buttonStyle.activeFontColorRGB,
                        };

                        return (
                            <>
                                {autoChangeToDropdown({sidePadding})}

                                <div
                                    className="buttons-container"
                                    ref={headerSizeRef.ref}
                                    style={{
                                        opacity: headerHeight.value != undefined ? 1 : 0,
                                    }}
                                >
                                    {containerTile.tiles.map((tile) =>
                                        cs(
                                            keyed(`${currentTile.value?.id}_${tile.id}`),
                                            ["isActive", (_, next) => next(tile.id == currentTile.value?.id)],
                                            [
                                                "buttonStyle",
                                                ({isActive}, next) =>
                                                    UseState1({
                                                        next,
                                                        initValue: isActive ? activeStyle : normalStyle,
                                                    }),
                                            ],
                                            [
                                                "buttonInteractions",
                                                ({buttonStyle, isActive}, next) =>
                                                    next({
                                                        onMouseOver: () => !isActive && buttonStyle.onChange(hoverStyle),
                                                        onMouseOut: () => buttonStyle.onChange(isActive ? activeStyle : normalStyle),
                                                        onClick: () => onClickTile(tile),
                                                    }),
                                            ],
                                            ({buttonStyle, buttonInteractions}) => (
                                                <div className="btn" style={buttonStyle.value} {...buttonInteractions}>
                                                    {tile.title}
                                                    {renderTooltip(tile)}
                                                </div>
                                            )
                                        )
                                    )}
                                </div>
                            </>
                        );
                    },
                    Tabs: () => {
                        const commonStyle = {
                            fontSize: getFontSize({
                                group: containerTile.style?.fontSize || "Medium",
                                elemType: "label",
                            }),
                            fontFamily: `"${theme.general.canvas.fontFamily || "Roboto"}", sans-serif`,
                            height: "52px",
                            textAlign: "center",
                            lineHeight: `${52 - tabStyle.bottomBorderHeight}px`,
                            cursor: "pointer",
                            borderBottomWidth: `${tabStyle.bottomBorderHeight}px`,
                            borderBottomStyle: `solid`,
                            padding: "0 20px",
                        };

                        const normalStyle = {
                            ...commonStyle,
                            background: tabStyle.backgroundColorRGB,
                            color: tabStyle.fontColorRGB,
                            borderBottomColor: tabStyle.borderColorRGB,
                        };

                        const hoverStyle = {
                            ...commonStyle,
                            background: tabStyle.hoverBackgroundColorRGB,
                            borderBottomColor: tabStyle.hoverBorderColorRGB,
                            color: tabStyle.hoverFontColorRGB,
                        };

                        const activeStyle = {
                            ...commonStyle,
                            background: tabStyle.activeBackgroundColorRGB,
                            borderBottomColor: tabStyle.activeBorderColorRGB,
                            color: tabStyle.activeFontColorRGB,
                        };

                        return (
                            <>
                                {autoChangeToDropdown({sidePadding: 0})}

                                <div
                                    className="tabs-container"
                                    ref={headerSizeRef.ref}
                                    style={{
                                        opacity: headerHeight.value != undefined ? 1 : 0,
                                        borderBottom: `${theme.general.tile.styles.tileBorderWidth}px solid ${theme.general.tile.styles.tileBorderColorRGB}`,
                                    }}
                                >
                                    {containerTile.tiles.map((tile) =>
                                        cs(
                                            keyed(`${currentTile.value?.id}_${tile.id}`),
                                            ["isActive", (_, next) => next(tile.id == currentTile.value?.id)],
                                            [
                                                "tabStyle",
                                                ({isActive}, next) =>
                                                    UseState1({
                                                        next,
                                                        initValue: isActive ? activeStyle : normalStyle,
                                                    }),
                                            ],
                                            [
                                                "tabInteractions",
                                                ({tabStyle, isActive}, next) =>
                                                    next({
                                                        onMouseOver: () => !isActive && tabStyle.onChange(hoverStyle),
                                                        onMouseOut: () => tabStyle.onChange(isActive ? activeStyle : normalStyle),
                                                        onClick: () => onClickTile(tile),
                                                    }),
                                            ],
                                            ({tabStyle, tabInteractions}) => (
                                                <div className="btn" style={tabStyle.value} {...tabInteractions}>
                                                    {tile.title}
                                                    {renderTooltip(tile)}
                                                </div>
                                            )
                                        )
                                    )}
                                </div>
                            </>
                        );
                    },
                    Dropdown: () => {
                        const sidePadding = 16;
                        const fontSize = getFontSize({
                            group: containerTile.style?.fontSize || "Medium",
                            elemType: "label",
                        });

                        const commonStyle = {
                            fontSize,
                            fontFamily: `"${theme.general.canvas.fontFamily}", sans-serif`,
                            borderRadius: `${dropdownStyle.cornerRadius}px`,
                            borderWidth: `${dropdownStyle.borderWidth}px`,
                            borderStyle: `solid`,
                            height: "40px",
                            textAlign: "center",
                            lineHeight: `${40 - dropdownStyle.borderWidth * 2}px`,
                            padding: `0 ${sidePadding}px`,
                            cursor: "pointer",
                            display: "inline-block",
                        };

                        const normalStyle = {
                            ...commonStyle,
                            background: dropdownStyle.backgroundColorRGB,
                            borderColor: dropdownStyle.borderColorRGB,
                            color: dropdownStyle.fontColorRGB,
                        };

                        const activeStyle = {
                            ...commonStyle,
                            background: dropdownStyle.backgroundColorRGB,
                            borderColor: dropdownStyle.borderColorRGB,
                            color: dropdownStyle.fontColorRGB,
                        };

                        const dropdownListStyle = {
                            background: theme.general.components.menuBackgroundColorRGB,
                            borderRadius: theme.general.components.menuCornerRadius,
                        };

                        return (
                            <>
                                {Invoke({
                                    onMounted: () => {
                                        if (headerSizeRef.getElement()) {
                                            headerHeight.onChange(parseInt(getComputedStyle(headerSizeRef.getElement().parentNode).height));
                                        }
                                    },
                                })}
                                <div className="dropdown-container" ref={headerSizeRef.ref}>
                                    {Dropdown({
                                        // dropdownRef,
                                        detectOnWheelEvent: true,
                                        minExpandHeight: 300,
                                        renderExpand: ({close}) => (
                                            <VerbScrollbar className="list" style={dropdownListStyle} maxHeight="300px">
                                                {containerTile.tiles?.map((tile) => {
                                                    return cs(
                                                        keyed(tile.id),
                                                        [
                                                            "cssClass",
                                                            (_, next) =>
                                                                StyledClass({
                                                                    next,
                                                                    content: {
                                                                        background: `${theme.general.components.menuBackgroundColorRGB}`,
                                                                        color: `${theme.general.components.menuTextColorRGB}`,
                                                                        "&:hover": {
                                                                            background: `${theme.general.components.menuHoverBackgroundColorRGB}`,
                                                                        },
                                                                        "&.selected": {
                                                                            background: `${theme.general.components.menuHoverBackgroundColorRGB}`,
                                                                        },
                                                                    },
                                                                }),
                                                        ],
                                                        ({cssClass}) => (
                                                            <div
                                                                className={cx(
                                                                    "item",
                                                                    {
                                                                        selected: tile.id == currentTile.value?.id,
                                                                    },
                                                                    cssClass
                                                                )}
                                                                onClick={() => {
                                                                    onClickTile(tile);
                                                                    close();
                                                                }}
                                                                style={{
                                                                    fontSize,
                                                                }}
                                                            >
                                                                {tile.title}
                                                                {renderTooltip(tile)}
                                                            </div>
                                                        )
                                                    );
                                                })}
                                            </VerbScrollbar>
                                        ),
                                        renderToggle: ({showExpand, showingExpand}) => (
                                            <div
                                                className="btn"
                                                style={showingExpand ? activeStyle : normalStyle}
                                                onClick={() => showExpand(!showingExpand)}
                                            >
                                                {currentTile.value?.title || "Select a Tile"}
                                                {currentTile.value && renderTooltip(currentTile.value)}
                                                <i className="fa fa-caret-down" style={{marginLeft: 20}} />
                                            </div>
                                        ),
                                    })}
                                </div>
                            </>
                        );
                    },
                };

                return next(mapTypeToRender[headerType.value]);
            },
        ],

        ({renderFunc, headerType, headerHeight}) =>
            next({
                height: headerHeight.value ? headerHeight.value + (headerType.value == "Dropdown" ? 20 : 0) : undefined,
                render: () => {
                    return cs(
                        keyed(
                            `${headerType.value}_${containerTile.style?.fontSize}_${containerTile.tiles.map((tile) => tile.id).join("_")}`
                        ),
                        () => <div className="container-header">{renderFunc()}</div>
                    );
                },
            })
    );
