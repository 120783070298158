import React from "react";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {CanvasTheme} from "./canvas/canvas-theme";
import {LeftPanelClosable} from "../../../../common/left-panel-closable/left-panel-closable";
import {TilesThemeTheme} from "./tiles/tiles-theme-theme";
import {Static2} from "@common/react/static-2";
import {ComponentAndInputTheme} from "./component-and-input/component-and-input-theme";
import {ButtonsTheme} from "./buttons/buttons-theme";

export const GeneralTab = ({form, next, viewTree}) =>
    cs(
        ["selected", (_, next) => UseState({next, initValue: false})],
        ["panelOverride", (_, next) => UseState({next, initValue: null})],
        ["overrideLeftPanelRef", (_, next) => Static2({next})],
        [
            "sections",
            ({overrideLeftPanelRef}, next) =>
                next([
                    {
                        label: "Canvas (Dashboard)",
                        render: (form) => CanvasTheme({form, viewTree}),
                        headerTitle: "Canvas",
                        id: "canvas",
                    },
                    {
                        label: "Tiles",
                        render: (form) =>
                            TilesThemeTheme({
                                form,
                                overrideLeftPanelRef,
                                viewTree,
                            }),
                        id: "tiles",
                    },
                    {
                        label: "Buttons",
                        render: (form) =>
                            ButtonsTheme({
                                form,
                                overrideLeftPanelRef,
                                viewTree,
                            }),
                        id: "buttons",
                    },
                    {
                        label: "COMPONENTS & INPUTS",
                        render: (form) => ComponentAndInputTheme({form}),
                        id: "components",
                    },
                ]),
        ],
        ({sections, selected, panelOverride, overrideLeftPanelRef}) =>
            next({
                renderLeftPanel: () =>
                    sections.map((section, index) => (
                        <div
                            className="section-item"
                            key={index}
                            onClick={() => {
                                panelOverride.onChange(section);
                                viewTree.change((v) => v.concat(section.id));
                                setTimeout(() => {
                                    selected.onChange(section);
                                });
                            }}
                        >
                            {section.label}

                            <img src={require("../../common/icons/arrow-right.svg")} className="arrow-right" />
                        </div>
                    )),
                panelOverride: {
                    active: selected.value,
                    component: LeftPanelClosable({
                        onClose: () => {
                            selected.onChange(null);
                            viewTree.change((v) => v.splice(0, v.length - 1));
                        },
                        label: (panelOverride.value?.headerTitle || panelOverride.value?.label || "").toUpperCase(),
                        content: panelOverride.value?.render(form),
                        overrideLeftPanelRef,
                    }),
                },
            })
    );
