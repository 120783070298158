import {cs} from "@common/react/chain-services";
import {consumeContext, provideContext} from "@common/react/context";
import {ObserveSyncingService} from "./observe-syncing-service";

export const syncingProcessProvider = ({next}) =>
    cs(
        consumeContext("apis"),
        consumeContext("routing"),
        [
            "syncingProcessObserver",
            ({apis, routing}, next) =>
                ObserveSyncingService({
                    fetch: () => apis.data.getTablesSyncing(routing.params.dsId),
                    next,
                }),
        ],
        ({syncingProcessObserver}, next) =>
            provideContext(
                "syncingProcess",
                {
                    syncingProcessObserver,
                },
                next
            ),
        ({syncingProcessObserver}) =>
            next({
                syncingProcessObserver,
            })
    );
