import collection from "./dashboard-collection.json";
import data from "./dashboard-data.json";
import {LiveDashboard} from "@common/ui-components/live/live-dashboard/live-dashboard";

export const DesignThemeDashboardAuto = (props) => {
    return LiveDashboard({
        collection,
        defaultData: data,
        disabledTileActions: true,
        renderFilter: () => {},
        ...props,
    });
};
