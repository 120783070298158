import {findMaxValue, sum} from "../../../../../utils/collections";
import {dashArrays, getTrianglePath} from "./tooltip-connector-helpers";

export const drawTooltipConnectorHorizontal = ({chart, tooltipStyle}) => {
    drawConnectorCircle({chart, tooltipStyle});
    drawConnectorLine({chart, tooltipStyle});
    placeTooltipAnchor({chart, tooltipStyle});
};

const drawConnectorCircle = ({chart, tooltipStyle}) => {
    const crosshair = chart.xAxis[0].cross;

    if (!crosshair) {
        return;
    }

    const {1: x, 2: y} = crosshair.pathArray[0];

    if (!chart.connectorCircle?.element) {
        chart.connectorCircle = chart.renderer
            .circle(x, y, 3)
            .attr({
                fill: tooltipStyle.indicatorLineColorRGB || "#000000",
                stroke: tooltipStyle.indicatorLineColorRGB || "#000000",
                "stroke-width": 1,
                zIndex: 3,
            })
            .add()
            .show();
    } else {
        chart.connectorCircle.attr({x, y});
    }
};

const getBarHeight = (chart) => {
    // boost mode
    if (chart.boostClipRect || chart.isBoosting) {
        return (chart.boostClipRect || chart.clipRect).element.getBoundingClientRect().width / 2;
    }

    if (chart.userOptions.plotOptions.bar.stacking === "percent") {
        // AB#6187: for 100% stacking horizontal bar chart, place the tooltip in the middle
        return chart.plotWidth / 2;
    }

    if (chart.userOptions.plotOptions.bar.stacking === "normal") {
        return sum(chart.hoverPoints, (p) => p.shapeArgs?.height || 0);
    }

    // no stacking
    return findMaxValue(chart.hoverPoints, (p) => p.shapeArgs?.height || 0);
};

const drawConnectorLine = ({chart, tooltipStyle}) => {
    const crosshair = chart.xAxis[0].cross;

    if (!crosshair) {
        return;
    }

    const barHeight = getBarHeight(chart);

    const {1: x, 2: y} = crosshair.pathArray[0];

    const turningPoint = {
        x: x + barHeight,
        y,
    };

    const topEnd = {
        x: x + barHeight,
        y: chart.customTooltipPosition === "Below" ? chart.container.offsetHeight : 0,
    };

    const pathArr = ["M", x, y, "L", turningPoint.x, turningPoint.y, "L", topEnd.x, topEnd.y];

    if (!chart.connectorLine?.element) {
        chart.connectorLine = chart.renderer
            .path(pathArr)
            .attr({
                stroke: tooltipStyle.indicatorLineColorRGB || "#000000",
                "stroke-width": tooltipStyle.indicatorLineWidth || 0.5,
                ...(["Dashed", "Dotted"].includes(tooltipStyle.indicatorLineStyle) && {
                    "stroke-dasharray": dashArrays[tooltipStyle.indicatorLineStyle],
                }),
                zIndex: 3,
            })
            .add()
            .show();
    } else {
        chart.connectorLine.attr({
            d: pathArr.join(" "),
        });
    }
};

const placeTooltipAnchor = ({chart, tooltipStyle}) => {
    const crosshair = chart.xAxis[0].cross;

    if (!crosshair || !chart.tooltip.container) {
        return;
    }

    const {1: x, 2: y} = crosshair.pathArray[0];

    const barHeight = getBarHeight(chart);

    const topEnd = {
        x: x + barHeight,
        y: chart.customTooltipPosition === "Below" ? chart.container.offsetHeight : 0,
    };

    chart.connectorFakeAnchor = chart.renderer
        .path(getTrianglePath(topEnd))
        .attr({
            fill: "none",
            stroke: "none",
            "stroke-width": 1,
            zIndex: 3,
        })
        .add()
        .hide();

    const tooltipAnchor = chart.tooltip.container.getElementsByClassName("tooltip-anchor")[0];
    const tooltipBox = chart.tooltip.container.getElementsByClassName("chart-tooltip-wrapper")[0];

    if (!tooltipBox) {
        return;
    }

    const tooltipBoxRect = tooltipBox.getBoundingClientRect();
    const anchorRect = chart.connectorFakeAnchor.element.getBoundingClientRect();

    if (chart.customTooltipPosition === "Above") {
        tooltipAnchor.style.top = "100%";
        tooltipAnchor.style["border-bottom-color"] = "transparent";
        tooltipAnchor.style["border-top-color"] = tooltipStyle.tooltipTheme === "Dark" ? "#000000" : "#FFFFFF";
    } else {
        tooltipAnchor.style.top = "-12px";
        tooltipAnchor.style["border-top-color"] = "transparent";
        tooltipAnchor.style["border-bottom-color"] = tooltipStyle.tooltipTheme === "Dark" ? "#000000" : "#FFFFFF";
    }

    tooltipAnchor.style.left = `${Math.floor(anchorRect.x - tooltipBoxRect.x)}px`;
    tooltipAnchor.style.opacity = "1";
};
