import "./dropdown-multi-select-search.scss";

import React from "react";
import {cx} from "emotion";

import {Static2} from "@common/react/static-2";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {bindInput} from "@common/react/bind-input";

import {Checkbox} from "@common/ui-components/form/checkbox/checkbox";
import {Dropdown} from "@common/ui-components/dropdown/dropdown";

import SearchIcon from "assets/icons/common/SearchIcon";
import {keyed} from "@common/react/keyed";
import {isMatchText} from "@common/utils/strings";

export const DropdownMultiSelectSearch = ({list, isSelected, onSelect, label, valueToLabel, valueToSearch, hasError, errorMessage}) =>
    cs(
        ["inputRef", (_, next) => Static2({next})],
        [
            "searching",
            (_, next) =>
                UseState({
                    // initValue: {front, behind},
                    next,
                }),
        ],
        ({searching, inputRef}) => {
            const renderToggle = ({showExpand, showingExpand}) => (
                <div
                    className={cx("toggle", {expanding: showingExpand})}
                    onClick={() => {
                        inputRef.get().focus();
                    }}
                >
                    {label && <div className="label">{label}</div>}

                    <input
                        {...{
                            ref: inputRef.set,
                            placeholder: "Find Column",
                            onFocus: () => {
                                showExpand(true);
                                searching.change((s) => s ?? {front: ""});
                            },
                            ...bindInput({
                                value: searching.value?.front,
                                onChange: (v) => searching.onChange({front: v, behind: v}),
                            }),
                        }}
                    />

                    {SearchIcon({})}
                </div>
            );

            const renderExpand = ({close, width}) =>
                cs(keyed(list?.length ?? Date.now()), () => {
                    const searched = list?.filter((l) => isMatchText(valueToSearch(l), searching.value?.behind));
                    return (
                        <div className="list" style={{minWidth: width}}>
                            {!searched && <div className="no-results">Loading...</div>}
                            {searched &&
                                (!searched?.length ? (
                                    <div className="no-results">No results found.</div>
                                ) : (
                                    searched.map((l, i) => (
                                        <div
                                            key={i}
                                            className={cx("item", {
                                                selected: isSelected(l),
                                            })}
                                            onClick={(e) => {
                                                onSelect(l);
                                                searching.onChange({
                                                    ...searching.value,
                                                    front: "",
                                                });
                                            }}
                                        >
                                            {Checkbox({
                                                readOnly: true,
                                                state: {
                                                    value: isSelected(l),
                                                    onChange: () => {},
                                                },
                                            })}
                                            {valueToLabel(l)}
                                        </div>
                                    ))
                                ))}
                        </div>
                    );
                });

            return (
                <div
                    className={cx("dropdown-multi-select-search dropdown-multi-select-search-g43", {
                        hasError,
                    })}
                >
                    {Dropdown({
                        renderToggle,
                        renderExpand,
                        minExpandHeight: 200,
                        onPassiveClose: () => searching.onChange(null),
                    })}

                    {hasError && errorMessage && <div className="error-message">{errorMessage}</div>}
                </div>
            );
        }
    );
