import {last} from "../../../../utils/collections";

export const getDatetimeExtremes = ({series, tile}) => {
    const mainSeries = series.find((s) => !s.isCompare);
    const compareSeries = series.find((s) => s.isCompare);
    const tickInterval = mainSeries.data[1][0] - mainSeries.data[0][0];
    // if no padding is added, the line will end at the very ends of chart
    const padding = tile.$type === "LineChartTile" && !tile.style.displayType.includes("Area") ? tickInterval / 10 : 0;
    return {
        primaryAxis: {
            min: mainSeries.data[0][0] - padding,
            max: last(mainSeries.data)[0] + padding,
        },
        compareAxis: compareSeries && {
            min: compareSeries.data[0][0] - padding,
            max: last(compareSeries.data)[0] + padding,
        },
    };
};
