
export const cOnClickScatterPoint = ({actionMenu, tile}) => {
    if (!actionMenu.hasActions()) {
        return;
    }
    return ({point, ...other}) => {
        let fieldToValue = {};

        if (tile.dimensionField) {
            fieldToValue[tile.dimensionField.id] = point.series.userOptions.name;
        }

        if (tile.colorGroupField) {
            fieldToValue[tile.colorGroupField.id] = point.series.userOptions.colorGroupName;
        }

        if (tile.shapeGroupField) {
            fieldToValue[tile.shapeGroupField.id] = point.series.userOptions.shapeGroupName;
        }

        const tileActionData = point.series?.userOptions?.tileActionData;

        tileActionData?.columns.forEach((c, index) => {
            fieldToValue[c.tileFieldID] = tileActionData.data[index];
        });

        actionMenu.show({
            point,
            ...other,
            fieldToValue,
        });
    }
};
