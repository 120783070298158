import { AnyAction } from "@common/react/any-action";
import { cs } from "@common/react/chain-services";
import { consumeContext } from "@common/react/context";
import { Static2 } from "@common/react/static-2";
import { UseState } from "@common/react/use-state";
import { cx } from "emotion";
import React, { createElement as h } from "react";
import { ConfirmDialog } from "../../../../../common/confirm-dialog/confirm-dialog";
import { getClonedTile, updatedCollectionAfterAdd } from "../../dnd-common";
import { MapFieldsForCloningTileDialog } from "./map-fields/map-fields-for-cloning-tile-dialog";
import { SelectCollectionDialog } from "./select-collection-dialog/select-collection-dialog";
import "./tile-control-box.scss";

export const TileControlBox = ({next, onDelete, onClone, collection, tile}) =>
    cs(
        consumeContext("collectionToast"),
        consumeContext("routing"),
        consumeContext("apis"),
        [
            "confirmDialog",
            (_, next) =>
                ConfirmDialog({
                    next,
                    submitText: "Yes, Remove",
                    cancelText: "Cancel",
                    title: "Remove Shared Tile",
                    body: "Are you sure you want to remove this shared tile from this collection?",
                }),
        ],
        ["ref", (_, next) => Static2({next})],
        [
            "expanded",
            (_, next) => (
                <UseState
                    {...{
                        initValue: false,
                        next,
                    }}
                />
            ),
        ],
        ({expanded}, next) =>
            h(AnyAction, {
                disabled: !expanded.value,
                fn: () => {
                    expanded.onChange(false);
                },
                next,
            }),
        [
            "cloneTile",
            ({apis, routing, collectionToast}, next) =>
                next(async ({selected, sourceTile}) => {
                    const mapTiles = (collection) =>
                        collection.gridLocations.map((gridLoc) => {
                            const size = {
                                width: gridLoc.colSpan,
                                height: gridLoc.rowSpan,
                            };

                            return {
                                key: gridLoc.tile.id,
                                position: {
                                    x: gridLoc.colStart - 1,
                                    y: gridLoc.rowStart - 1,
                                },
                                size,
                            };
                        });

                    const targetCol = selected.id
                        ? await apis.collection.getCollection(selected.id)
                        : await apis.collection.upsertCollection(selected);
                    const newTile = getClonedTile({
                        tile: {...tile, position: {x: 0, y: 0}},
                        tiles: mapTiles(targetCol),
                        collection: {value: targetCol},
                        sourceTile,
                    });
                    await apis.collection.upsertCollection(
                        updatedCollectionAfterAdd({
                            tile: newTile.tile,
                            rect: newTile.rect,
                            collection: {value: targetCol},
                        })
                    );

                    collectionToast.show({
                        text: `Tile successfully copied to ${targetCol.name}`,
                        action: {
                            text: "Go to Collection",
                            onClick: () =>
                                routing.goto("edit-collection", {
                                    colId: targetCol.id,
                                }),
                        },
                    });
                }),
        ],
        [
            "mapFieldsDialog",
            ({apis, routing, cloneTile}, next) =>
                MapFieldsForCloningTileDialog({
                    onDone: async ({collection, selected}) => {
                        await cloneTile({
                            selected,
                            sourceTile: collection.gridLocations[0].tile,
                        });
                    },
                    next,
                }),
        ],
        [
            "selectCollectionDialog",
            ({cloneTile, mapFieldsDialog, currentType}, next) =>
                SelectCollectionDialog({
                    next,
                    collection,
                    onDone: async (selected) => {
                        if (selected.modelID == collection.value.modelID) {
                            await cloneTile({
                                selected,
                                sourceTile: collection.value.gridLocations.find((g) => g.tile.id == tile.key).tile,
                            });
                        } else {
                            const updatedCollection = {
                                ...collection.value,
                                gridLocations: collection.value.gridLocations.filter((grid) => grid.tile.id == tile.key),
                            };

                            if (tile.$type == "SectionTile" || tile.$type == "TextTile") {
                                const cloneCollection = {
                                    ...updatedCollection,
                                    modelID: selected.modelID,
                                };
                                await cloneTile({
                                    selected,
                                    sourceTile: cloneCollection.gridLocations[0].tile,
                                });
                            } else {
                                mapFieldsDialog.show({
                                    collection: {
                                        ...collection.value,
                                        gridLocations: collection.value.gridLocations.filter((grid) => grid.tile.id == tile.key),
                                    },
                                    modelID: selected.modelID,
                                    selected,
                                });
                            }
                        }
                    },
                }),
        ],
        ({expanded, collectionToast, routing, selectCollectionDialog, ref, confirmDialog}) =>
            next({
                toggleRef: ref,
                showControl: ({side}) => {
                    if (expanded.value) {
                        return expanded.onChange(null);
                    }

                    const toggleRect = ref.get().getBoundingClientRect();
                    const boxWidth = 210;
                    const distance = 5;

                    if (side === "left") {
                        return expanded.onChange({
                            top: toggleRect.height,
                            right: distance,
                        });
                    }

                    if (boxWidth + distance + toggleRect.x > window.innerWidth) {
                        expanded.onChange({top: toggleRect.height, right: 0});
                    } else {
                        expanded.onChange({
                            top: toggleRect.height,
                            left: distance,
                        });
                    }
                },
                render: () =>
                    expanded.value &&
                    cs(() => {
                        const SHARED_TILE_ACTIONS = [
                            {
                                label: "Go to Shared Tile View",
                                click: () => {
                                    routing.goto("edit-shared-tile", {sharedTileId: tile.key});
                                },
                            },
                            {
                                label: "Clone and Replace",
                                click: () => tile.onReplaceSharedTile(),
                            },
                            {
                                label: "Clone as New",
                                click: () => onClone(),
                            },
                            {
                                label: "Remove",
                                click: () => {
                                    confirmDialog.show({
                                        description: "",
                                        confirmClick: async () => {
                                            await tile.onDelete();
                                        },
                                    });
                                },
                                className: "delete-action",
                            },
                        ];

                        const UN_SHARED_TILE_ACTIONS = [
                            {
                                label: "Clone",
                                click: async () => {
                                    const newTile = await onClone();
                                    collectionToast.show({
                                        text: "Tile successfully copied",
                                        action: {
                                            text: "Edit new tile",
                                            onClick: () => {
                                                let updatedTile = newTile.gridLocations[newTile.gridLocations.length - 1].tile;
                                                if (updatedTile.$type !== "ContainerTile") {
                                                    routing.goto("edit-tile", {
                                                        tileId: updatedTile.id,
                                                        ...(["CannotDisplay", "Invalid"].includes(updatedTile.fieldValidity) && {
                                                            tileTab: "fields",
                                                        }),
                                                    });
                                                } else {
                                                    routing.goto("edit-container-tile", {
                                                        cTileId: updatedTile.id,
                                                    });
                                                }
                                            },
                                        },
                                    });
                                },
                            },
                            {
                                label: "Clone to...",
                                click: () => selectCollectionDialog.show(),
                            },
                            {
                                label: "Convert to Shared",
                                click: () => tile.convertToSharedTile(),
                            },
                            {
                                label: "Delete",
                                click: () => onDelete(),
                                className: "delete-action",
                            },
                        ];

                        const actions = tile.isShared ? SHARED_TILE_ACTIONS : UN_SHARED_TILE_ACTIONS;

                        return (
                            <div className="tile-control-box-a33" style={{...expanded.value}}>
                                <div className="tile-control-wrapper">
                                    {actions.map((action, index) => (
                                        <div
                                            className={cx("action", action.className)}
                                            key={index}
                                            onClick={async (e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                expanded.onChange(false);
                                                await action.click();
                                            }}
                                        >
                                            {action.label}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        );
                    }),
            })
    );
