import React from "react";
import {cs} from "@common/react/chain-services";
import "./join-data-configuration.scss";
import {DropdownSelect2} from "@common/ui-components/dropdown-select/dropdown-select2";
import {scope} from "@common/react/scope";
import {TrashIcon} from "../../../../../../common/icons/trash-icon";
import {spc} from "@common/react/state-path-change";
import {removeIndex} from "@common/utils/collections";
import {rColumnValueSelectFromStepOutput} from "../../../../common/column-value-select-from-step-output/column-value-select-from-step-output";
import {DebounceTextInput} from "../../../../../../../../../common/form/debounce-input/debounce-text-input";
import {LeftRightInputSelect} from "./left-right-input-select";
import {getPath} from "@common/utils/arr-path";
import {ffToDropdown} from "../../../../../../../../../common/form/ff-to-dropdown";
import {keepOnly} from "@common/utils/objects";
import {GhostButton} from "../../../../../../../../../common/form/buttons/ghost-button/ghost-button";
import {PlusIcon} from "@common/ui-components/icons/global-icons";
import {joinTypes} from "./join-types";

export const JoinDataConfiguration = ({transformation, step, form, model}) => {
    const inputSteps = [step.value.inputStep1ID, step.value.inputStep2ID].map((sid) => transformation.value.steps.find((s) => s.id === sid));

    const selectColumnValue1 = ({state, columns, disableStaticValue, ...props}) => {
        if (state.value?.$type === "StaticValue") {
            return DebounceTextInput({
                label: "Column/Value",
                state: scope(state, ["value"]),
                ...(!state.value.value && {
                    autoFocus: true,
                }),
                ...props,
            });
        }
        return rColumnValueSelectFromStepOutput({
            label: "Column/Value",
            columns,
            disableStaticValue,
        })({state, ...props});
    };

    const selectColumnValue = ({side, index}) => {
        const dropdownProps = ffToDropdown(form.field(["expressions", index, side]));
        return selectColumnValue1({
            state: {
                value: dropdownProps.value,
                onChange: dropdownProps.onChange,
            },
            columns: inputSteps.find((s) => s.id === step.value[side === "left" ? "leftStepID" : "rightStepID"])?.outputColumns,
            disableStaticValue: getPath(step.value, ["expressions", index, side === "left" ? "right" : "left"])?.$type === "StaticValue",
            ...keepOnly(dropdownProps, ["domRef", "hasError", "errorMessage"]),
        });
    };

    const leftRightSelect = ({attr}) =>
        LeftRightInputSelect({
            steps: inputSteps,
            getStepName: (step) => model.tables.find((t) => t.id === step.modelTableID)?.visualName,
            isSelected: (s) => s.id === step.value[attr],
            onChange: (s) => {
                if (s.id !== step.value[attr]) {
                    step.onChange({
                        ...step.value,
                        [attr]: s.id,
                        [attr === "leftStepID" ? "rightStepID" : "leftStepID"]: inputSteps.find((s1) => s1 !== s).id,
                        expressions: step.value.expressions?.map((exp) => ({
                            left: exp.right,
                            right: exp.left,
                        })),
                    });
                }
            },
            ...keepOnly(ffToDropdown(form.field([attr])), ["domRef", "hasError", "errorMessage"]),
        });

    return (
        <div className="join-data-configuration-idf">
            <div className="config-group join-type">
                <div className="label">Join type</div>
                <div className="type-select">
                    <div className="selector">
                        {DropdownSelect2({
                            label: "Type",
                            list: joinTypes,
                            valueToLabel: (v) => (
                                <div className="join-label">
                                    <img src={v.iconSrc} alt="" />
                                    {v.label}
                                </div>
                            ),
                            isSelected: (v) => v.value === step.value.joinType,
                            onChange: (v) =>
                                step.onChange({
                                    ...step.value,
                                    joinType: v.value,
                                    ...(v.value !== "Cross"
                                        ? {
                                              expressions: step.value.expressions || [{}],
                                          }
                                        : {
                                              expressions: null,
                                          }),
                                }),
                            ...keepOnly(ffToDropdown(form.field(["joinType"])), ["domRef", "hasError", "errorMessage"]),
                        })}
                    </div>

                    {step.value.joinType && <div className="note">{joinTypes.find((t) => t.value === step.value.joinType).note}</div>}
                </div>
            </div>

            {step.value.joinType && (
                <div className="config-group match-add">
                    <div className="header">
                        <div className="left-label">Left</div>
                        <div className="right-label">Right</div>
                    </div>
                    <div className="body">
                        <div className="line">
                            <div className="left part">{leftRightSelect({attr: "leftStepID"})}</div>
                            <div className="right part">{leftRightSelect({attr: "rightStepID"})}</div>
                        </div>
                        {step.value.joinType !== "Cross" && (
                            <>
                                {(step.value.expressions?.length > 0 ? step.value.expressions : [{}]).map((exp, ei) => (
                                    <div className="line" key={ei}>
                                        <div className="left part">
                                            {selectColumnValue({
                                                side: "left",
                                                index: ei,
                                            })}
                                        </div>
                                        <div className="right part">
                                            {selectColumnValue({
                                                side: "right",
                                                index: ei,
                                            })}
                                        </div>
                                        {ei > 0 && (
                                            <div className="delete-btn" onClick={() => spc(step, ["expressions"], (expressions) => removeIndex(ei, expressions))}>
                                                {TrashIcon()}
                                            </div>
                                        )}
                                    </div>
                                ))}

                                <GhostButton
                                    iconLeft={<PlusIcon />}
                                    className="btn-add"
                                    onClick={() => spc(step, ["expressions"], (expressions) => [...(expressions?.length > 0 ? expressions : [{}]), {}])}
                                >
                                    Add Another Match
                                </GhostButton>
                            </>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};
