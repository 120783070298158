import * as React from "react";
import "./tile-title.scss";
import {scope} from "@common/react/scope";
import {ffToTextInput} from "../../../../../../../../../../../../common/form/ff-to-text-input";
import {ShowHideToggle} from "../../../../../../../../../../../../common/form/toggles/show-hide-toggle";
import {TextInput} from "../../../../../../../../../../../../common/form/text-input/text-input";
import {TextareaInput} from "../textarea-input/textarea-input";
import {getPath} from "@common/utils/arr-path";
import {HiddenFieldAlert} from "../hidden-field-alert/hidden-field-alert";
import {ValueChipInput} from "../../../../fields-tab/actions/add/common/value-chip-input";
import {isChartTitleHidden} from "@common/ui-components/charts/chart-layout/chart-title-display-rule";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {ffToDropdown} from "../../../../../../../../../../../../common/form/ff-to-dropdown";

const getSectionTitle = (type) => {
    if (type == "SectionTile") return "Section Title";
    return `${type == "ListKPITile" ? "Chart" : "Tile"} Title`;
};

export const TITLE_ALIGNMENT = {
    LEFT: "Left",
    CENTER: "Center",
    EMPTY: null,
};

export const TileTitle = ({tile, size, parentTile, tileAction, form, isContainerTile}) => ({
    label: getSectionTitle(tile.value.$type),
    control:
        !isContainerTile && tile.value.$type != "SectionTile"
            ? ShowHideToggle({
                  state: scope(tile, ["style", "title", "show"]),
              })
            : null,
    render: () => {
        const isTileTitleShown = getPath(tile.value, ["style", "title", "show"]) && !isChartTitleHidden({tile: tile.value, size});
        return (
            <div className="tile-title-8as">
                <div className="subsection">
                    <div className="content">
                        {parentTile
                            ? ValueChipInput({
                                  tile: parentTile,
                                  tileAction,
                                  label: "Title",
                                  beforeField: "tileTitleBefore",
                                  afterField: "tileTitleAfter",
                              })
                            : TextInput({
                                  label: "Title",
                                  ...ffToTextInput(form.field(["title"])),
                              })}
                        {scope(tile, ["style", "title", "show"]).value &&
                            DropdownSelect({
                                list: [
                                    {
                                        label: "Left Aligned",
                                        value: TITLE_ALIGNMENT.LEFT,
                                    },
                                    {
                                        label: "Center Aligned",
                                        value: TITLE_ALIGNMENT.CENTER,
                                    },
                                ],
                                label: "Title Alignment",
                                valueToLabel: (v) => v?.label ?? "",
                                ...ffToDropdown(form.field("style.title.titleAlign"), ["value"]),
                            })}
                    </div>
                </div>

                {tile.value.title && (
                    <div className="subsection">
                        <div className="header">
                            Title Info Icon
                            <div className="control">
                                {isTileTitleShown
                                    ? ShowHideToggle({
                                          state: scope(tile, ["style", "showTitleInfoIcon"]),
                                      })
                                    : HiddenFieldAlert({
                                          info: "Unavailable when the Tile Title is hidden.",
                                      })}
                            </div>
                        </div>

                        {isTileTitleShown && tile.value?.style.showTitleInfoIcon && (
                            <div className="content">
                                {TextareaInput({
                                    state: scope(tile, ["style", "titleInfoIconText"]),
                                })}
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    },
});
