import "./live-filters-mobile.scss";

import { css, cx } from "emotion";
import React from "react";
import { cs } from "../../../../../../react/chain-services";
// import {hexToRGB} from "../../../../../../utils/color-util";
import { StyledClass } from "@common/react/styled-class";
import FilterMobileIcon from "../../../../../../../web-client/src/assets/icons/common/FilterMobileIcon";
import { SelectTimezoneDropdown } from "../live-filters-desktop/select-timezone";

export const LiveFiltersMobile = ({
    filters,
    showTimezoneDropdown = false,
    noLabel,
    runReportButton,
    onRunReport,
    downLoadButton,
    theme,
    filterDialog,
    filterForms,
    customWindowWidth,
    highlightFilterMobile,
}) =>
    cs(() => {
        const displayFiltersButton = filters.map((f) => ["Visible", "BehindMore"].includes(f.displayArea)).length > 0;
        const isFullWidthTimezoneDropdown = (runReportButton && downLoadButton) || (!runReportButton && !downLoadButton);
        const componentsTheme = theme.general.components;
        const primaryButtonTheme = theme.general.buttons.primaryButton;
        const secondaryButtonTheme = theme.general.buttons.secondaryButton;

        const changeCounts = filterForms.getChangedFilters().length;
        const renderFilterButton = () => {
            const shortPreview = highlightFilterMobile?.mobileFilter?.shortPreview?.();

            const parts = [
                highlightFilterMobile
                    ? (size) => (
                          <div className={cx("filter-text", size)}>
                              <span className="label">{highlightFilterMobile.mobileFilter.label}: </span>
                              {shortPreview && <span className="value">{shortPreview}</span>}
                          </div>
                      )
                    : null,
                (size) => <div className={cx("filter-count", size)}>Filters ({changeCounts})</div>,
            ].filter((p) => !!p);
            if (changeCounts > 0) {
                parts.reverse();
            }
            return cs(
                [
                    "buttonCss",
                    (_, next) =>
                        StyledClass({
                            content: {
                                background: `${componentsTheme.inputBackgroundColorRGB}`,
                                color: `${componentsTheme.inputTextColorRGB}`,
                                border: `${componentsTheme.inputBorderWidth}px solid ${componentsTheme.inputBorderColorRGB}`,
                                "border-radius": `${componentsTheme.inputCornerRadius}px`,

                                "& > .filter-text": {
                                    "& > .label:": {
                                        color: `${componentsTheme.inputLabelTextColorRGB}`,
                                    },
                                },
                                "& > .filter-count": {
                                    color: `${componentsTheme.menuTextColorRGB}`,
                                    opacity: 0.7,
                                },
                                "&.has-value-count": {
                                    background: `${primaryButtonTheme.backgroundColorRGB}`,
                                    color: `${primaryButtonTheme.fontColorRGB}`,
                                    border: `${primaryButtonTheme.borderWidth}px solid ${primaryButtonTheme.borderColorRGB}`,
                                    "border-radius": `${primaryButtonTheme.cornerRadius}px`,
                                    "& > .filter-text": {
                                        "& > .label": {
                                            color: `${primaryButtonTheme.fontColorRGB}`,
                                            opacity: 0.7,
                                        },
                                    },
                                    "& > .filter-count": {
                                        color: `${primaryButtonTheme.fontColorRGB}`,
                                        opacity: 1,
                                    },
                                    "& > .reset": {
                                        "background-color": `${secondaryButtonTheme.backgroundColorRGB}`,
                                        color: `${secondaryButtonTheme.fontColorRGB}`,
                                        border: `${secondaryButtonTheme.borderWidth}px solid ${secondaryButtonTheme.borderColorRGB}`,
                                        "border-radius": `${secondaryButtonTheme.cornerRadius}px`,
                                    },
                                },
                            },
                            next,
                        }),
                ],
                ({buttonCss}) => (
                    <button
                        className={cx("filters-button", changeCounts > 0 && "has-value-count", buttonCss)}
                        onClick={() => filterDialog.show({customWindowWidth})}
                    >
                        {parts.map((p, i) => p(i === 0 ? "big" : ""))}
                        <FilterMobileIcon
                            size={{width: 22, height: 18}}
                            fill={changeCounts > 0 ? primaryButtonTheme.fontColorRGB : componentsTheme.inputIconColorRGB}
                            className="icon"
                        />
                        {changeCounts > 0 && (
                            <div
                                className={cx("reset")}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    filterForms.resetToDefault();
                                    onRunReport?.();
                                }}
                            >
                                Reset
                            </div>
                        )}
                    </button>
                )
            );
        };
        const renderButtons = () => {
            return (
                <>
                    {displayFiltersButton && <div className="button-row one-btn">{renderFilterButton()}</div>}
                    {showTimezoneDropdown && isFullWidthTimezoneDropdown && (
                        <div className="button-row one-btn">{SelectTimezoneDropdown({theme})}</div>
                    )}

                    <div className="button-row">
                        {showTimezoneDropdown && SelectTimezoneDropdown({theme})}

                        {downLoadButton ?? null}
                    </div>
                </>
            );
        };

        return (
            <div
                className={cx("live-filter-mobile-a33", {noLabel})}
                style={{
                    fontFamily: `"${theme.general.canvas.fontFamily || "Roboto"}", sans-serif`,
                }}
            >
                <div className="buttons">{renderButtons()}</div>
            </div>
        );
    });
