const {spc} = require("../../../../react/state-path-change");
const createLoadTileData2 = ({tileId, collectionId, isShared = false, apis, loadedTileCounter, refreshKey}) => {
    let apiFunc = apis.collection.getTileData2 ? apis.collection.getTileData2 : apis.collection.getTileData;

    return async ({filters, page, extraCacheKey, tableOverrides, apiKey, timeZoneId}) => {
        spc(loadedTileCounter, ["loadingTiles"], (v) => v + 1);

        let res = await apiFunc({
            tileId,
            collectionId,
            isShared,
            filterValues: filters,
            tableOverrides,
            extraCacheKey,
            page,
            apiKey,
            timeZoneId,
            refreshKey,
        });

        spc(loadedTileCounter, ["loadedTiles"], (v) => v + 1);

        return res;
    };
};
exports.createLoadTileData2 = createLoadTileData2;
