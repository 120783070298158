import "./column-line.scss";

import React from "react";
import {cx} from "emotion";

import {cs} from "@common/react/chain-services";
import {EllipsisText} from "@common/ui-components/text/text";

import {rFieldTypeIcon} from "../../../../../common/field-type-icon/get-field-type-icon";
import {TruncatingTooltip} from "../../../../../common/truncating-tooltip/truncating-tooltip";
import {ColumnLineActions} from "./column-line-actions/column-line-actions";
import {TransformationPublishInfo2} from "../transformation-publish-info2";

export const ColumnLine = ({
    type,
    name,
    className,
    dataType,
    transformationId,
    versionDetails,
    publishedOn,
    highlighted,
    hasRelationship,
    modelColumnID,
    table,
    modelTable,
    column,
    color,
}) => {
    const isTransformed = ["CalculatedModelColumn", "AggregatedMeasureModelColumn"].includes(type);
    return cs(
        [
            "publishInfo",
            (_, next) =>
                TransformationPublishInfo2({
                    table: {
                        transformationID: transformationId,
                        versionDetails,
                        publishedOn,
                        $type: type,
                    },
                    next,
                }),
        ],
        [
            "actions",
            (_, next) =>
                ColumnLineActions({
                    next,
                    type,
                    transformationId,
                    versionDetails,
                    publishedOn,
                    column,
                    table,
                    hasRelationship,
                    modelColumnID,
                    modelTable,
                }),
        ],
        [
            "truncatingTooltip",
            (_, next) =>
                TruncatingTooltip({
                    direction: isTransformed ? "above" : "below",
                    next,
                }),
        ],
        ({publishInfo, truncatingTooltip, actions}) => (
            <div
                {...{
                    className: cx("column-line-h46 column-line", className, {
                        highlighted,
                        ...(isTransformed && {
                            transformed: isTransformed,
                            "never-been-published": !publishInfo?.isActive,
                        }),
                    }),
                }}
            >
                {/*need this truncating-tooltip-wrapper to carry the truncating tooltip, b/c the column picker in relationship picker needs pointer-events:none from this point downward*/}
                <div
                    {...{
                        className: "truncating-tooltip-wrapper",
                        ...truncatingTooltip.tooltip(name),
                        style: {
                            paddingRight: actions.width,
                        },
                    }}
                >
                    {(() => {
                        if (type === "CalculatedModelColumn") {
                            return (
                                <img className="calculated-plus-icon" src={require("../../common/icons/calculated-plus-icon.svg")} alt="" />
                            );
                        } else if (type === "AggregatedMeasureModelColumn") {
                            return (
                                <img
                                    className="aggregated-measure-icon"
                                    src={require("../../common/icons/aggregated-measure-icon.svg")}
                                    alt=""
                                />
                            );
                        }
                        return null;
                    })()}

                    {rFieldTypeIcon(dataType)}

                    <div
                        {...{
                            className: "name",
                            ref: truncatingTooltip.ref,
                        }}
                    >
                        <EllipsisText text={name} highlights={column.ranges} highlightColor={color} />

                        {isTransformed && publishInfo.renderIndicator()}
                    </div>

                    {actions.render()}
                </div>
            </div>
        )
    );
};
