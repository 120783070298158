
import * as React from "react";

import {chain} from "../../../../utils/fs";
import {scope} from "../../../../react/scope";
import {cs} from "../../../../react/chain-services";
import {UseState} from "../../../../react/use-state";
import {spc} from "../../../../react/state-path-change";
import {addMonth, today} from "../../../../utils/dates/date-object";
import {parseDateLong2} from "../../../formats/parse-date-long";
import {formatDateLong2} from "../../../formats/format-date-long";
import {MAX_YEAR, MIN_YEAR} from "@common/ui-components/calendars/single-calendar/allowed-years";
import {DatePickerCalendar} from "@common/ui-components/calendars/single-calendar/date-picker-calendar";

const YEAR_LIMIT = {max: MAX_YEAR, min: MIN_YEAR};

export const AccessibleDatePicker = ({next, value, isMobile, theme}) => {

    return cs(
        [
            "state",
            (_, next) =>
                UseState({
                    getInitValue: () => ({
                        month: value ?? today(),
                    }),
                    next,
                }),
        ],
        ["hover", (_, next) => UseState({next})],


        ({state, hover}) => {
            return next({
                getInput: ({next, onChange}) => {
                    let ref;
                    return cs(
                        ['inputState', (_, next) => UseState({
                            next,
                            initValue: null
                        })],

                        [
                            "proxy",
                            ({inputState}, next) => {
                                const getInputValue = () => value ? formatDateLong2(value) : formatDateLong2(today());

                                return next({
                                    state: {
                                        value: inputState.value ?? getInputValue(),
                                        onChange: str => {
                                            inputState.onChange(str);

                                            const value = str ? chain(str, v => parseDateLong2(v)) : null;
                                            if(value && value.year >= YEAR_LIMIT.min && value.year <= YEAR_LIMIT.max) {
                                                spc(state, ["month"], () => value);
                                                hover.onChange(value)
                                                onChange(value);
                                            }
                                        }
                                    },
                                    flush: () => inputState.onChange(null)
                                })
                            }

                        ],
                        ({proxy}) => {
                            const focus = () => {
                                hover.onChange(value ?? today());
                                spc(state, ['month'], () => value ?? today());
                            }
                            return next({
                                state: proxy.state,
                                onBlur: () => {
                                    proxy.flush();
                                },
                                onClick: focus,
                                onFocus: focus,
                                domRef: (r) => (ref = r),
                                getRef: () => ref,
                                reset: () => proxy.flush(),
                            });
                        }
                    )
                },
                render: ({width, space, onChange}) => {

                    return DatePickerCalendar({
                        state,
                        hover,
                        size: width,
                        space,
                        theme,
                        isMobile,
                        month: scope(state, ["month"]),
                        value,
                        onSelect: (day) => {
                            onChange(day);
                        },

                    });
                }

            });
        }
    );
}
