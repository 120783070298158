import "./popup-menu.scss";

import {cx} from "emotion";
import React, {createElement as h} from "react";
import {cs} from "../../../../../common/react/chain-services";
import {Invoke} from "../../../../../common/react/invoke";
import {GlobalKeyDown} from "../../../../../common/react/keys/global-key-down";
import {UseState} from "../../../../../common/react/use-state";
import {ArrowChevRight} from "../../../../../common/ui-components/icons/global-icons";
import {ArrowRightIcon} from "../../dashboard/env/settings/data-access-key/roll-key/setting-roll-key-icons";
import {PopupConsumer} from "./popup-registry";

export const PopupMenu = ({next, getCommands, minWidth, itemHeight = 48, customizeBadge, popupClassName, registryRender, customRenderMenu, maxHeight = Infinity, overflow}) =>
    cs(() => (
        <>
            {next({
                render: ({params, img, onMenuOpen} = {}) =>
                    cs(["show", (_, next) => h(UseState, {next})], ({show}) => {
                        return (
                            <>
                                {(() => {
                                    let dom;
                                    let commands = getCommands(params);
                                    return (
                                        (commands || []).length > 0 && (
                                            <div
                                                ref={(r) => (dom = r)}
                                                className="popup-menu-toggle-1ws"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();

                                                    const {bottom, right, top} = dom.getBoundingClientRect();
                                                    const wh = window.innerHeight;
                                                    const popupHeight = Math.min(itemHeight * commands.length, maxHeight);

                                                    const isEndOfPage = wh - bottom - popupHeight < 20;

                                                    show.onChange({
                                                        bottom: isEndOfPage ? top - popupHeight : bottom,
                                                        right: window.innerWidth - right,
                                                        params,
                                                    });
                                                }}
                                            >
                                                {customizeBadge ?? img ?? <i className="far fa-ellipsis-h" />}
                                            </div>
                                        )
                                    );
                                })()}

                                {show.value &&
                                    cs(
                                        [
                                            "render",
                                            (_, next) =>
                                                next(() =>
                                                    cs(
                                                        ({}, next) =>
                                                            h(GlobalKeyDown, {
                                                                keyCombo: "Escape",
                                                                onKeyDown: () => show.onChange(null),
                                                                next,
                                                            }),
                                                        ({}) => {
                                                            let overlayDom;
                                                            return (
                                                                <div
                                                                    className={cx("popup-menu-overlay-49c", popupClassName)}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        e.stopPropagation();

                                                                        return e.target === overlayDom && show.onChange(null);
                                                                    }}
                                                                    ref={(ref) => (overlayDom = ref)}
                                                                >
                                                                    {Invoke({
                                                                        fn: () => onMenuOpen?.(),
                                                                    })}
                                                                    {customRenderMenu ? (
                                                                        customRenderMenu({
                                                                            top: show.value.bottom,
                                                                            right: show.value.right,
                                                                            className: "popup-menu-commands",
                                                                            onClose: () => show.onChange(null),
                                                                        })
                                                                    ) : (
                                                                        <div
                                                                            className="popup-menu-commands"
                                                                            style={{
                                                                                top: show.value.bottom,
                                                                                right: show.value.right,
                                                                                minWidth,
                                                                                maxHeight,
                                                                                overflow,
                                                                            }}
                                                                        >
                                                                            {getCommands(show.value.params).map((cm, i) => (
                                                                                <div
                                                                                    key={i}
                                                                                    className={cx("command", cm.className, {
                                                                                        disabled: cm.disabled,
                                                                                    })}
                                                                                    onClick={(e) => {
                                                                                        if (cm.disabled) return;
                                                                                        e.preventDefault();
                                                                                        e.stopPropagation();

                                                                                        if (cm.onClick) {
                                                                                            cm.onClick?.();
                                                                                            show.onChange(null);
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    {cm.label}

                                                                                    {cm.subComponent && ArrowChevRight({className: "arrow-right"})}

                                                                                    <div className="sub-component">{cm.subComponent}</div>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            );
                                                        }
                                                    )
                                                ),
                                        ],
                                        ["registry", ({render}, next) => (registryRender ? React.createElement(PopupConsumer, {}, next) : next(null))],
                                        ({registry, render}) => <>{registry ? registry.render(render()) : render()}</>
                                    )}
                            </>
                        );
                    }),
            })}
        </>
    ));
