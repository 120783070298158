import "./chart-outer-layout.scss";

import {cx} from "emotion";
import * as React from "react";

import {SuspendUpdate} from "../../../react/suspend-update";
import {cs} from "../../../react/chain-services";
import {UseState} from "../../../react/use-state";
import {renderChildTile} from "../render-child-tile";
import {DialogService} from "../../../../web-client/src/routes/common/dialog/dialog-service";
import {ModalChildTileLayout} from "../../../../web-client/src/routes/collection/tile/edit/child-tile/child-modal-tile";
import {Static2} from "../../../react/static-2";
import {isChartTitleHidden} from "./chart-title-display-rule";
import {ChartTitle} from "./chart-title";
import {TITLE_ALIGNMENT} from "../../../../web-client/src/routes/collection/tile/edit/left-panel/tabs/tile-tab/chart-types/common/tile-title/tile-title";
import {ChartOuterSingleKpiLayout} from "./single-kpi/chart-outer-single-kpi-layout";
import {
    ChartOuterSparkLineKpiLayout
} from "@common/ui-components/charts/chart-layout/spark-line-kpi/chart-outer-spark-line-kpi-layout";

export const TITLE_ALIGNMENT_TO_JUSTIFY_CONTENT = {
    Left: "left",
    Center: "center",
};

export const ChartOuterLayout = ({tile, size, theme, tileFilters, isLive, sdkDashboard, content, renderOverlay}) =>
    cs(
        ["tileAction", (_, next) => UseState({initValue: null, next})],
        ["hasSwapAxis", (_, next) => UseState({initValue: false, next})],
        ["modalTileAction", (_, next) => childModalTileDialog({next, tileFilters, isLive, sdkDashboard})],
        ["headerCenterSlotRef", (_, next) => Static2({next})],
        ["headerRightSlotRef", (_, next) => Static2({next})],
        ({tileAction, modalTileAction, headerCenterSlotRef, headerRightSlotRef, hasSwapAxis}) => {
            const style = tile.style || {};

            const hasTitle =
                ((typeof style?.title?.show != "undefined" ? style?.title.show : false) && !isChartTitleHidden({tile, size}) && !tile?.isContainerTile) || tile?.isChild;

            const titleHeaderHeight = 52; // AB#312
            const nonTitleHeaderHeight = tile.$type === "MapTile" ? 36 : 0;
            const contentHeight = tile.$type !== "TableTile" ? `calc(100% - ${hasTitle ? titleHeaderHeight : nonTitleHeaderHeight}px)` : "100%";
            const titleCenter = tile.style.title?.titleAlign === TITLE_ALIGNMENT.CENTER;

            return renderOverlay ? (
                renderOverlay()
            ) : (
                <>
                    <div
                        className={cx("chart-outer-layout-4yh chart-outer-layout")}
                        style={{
                            opacity: tileAction.value?.tile ? 0 : 1,
                        }}
                    >
                        <div
                            className="header"
                            style={{
                                ...(tile.style.textAlignmentTypeValues
                                    ? {
                                          justifyContent:
                                              tile.style.textAlignmentTypeValues.toLowerCase() == "center"
                                                  ? "center"
                                                  : tile.style.textAlignmentTypeValues.toLowerCase() == "right"
                                                  ? "flex-end"
                                                  : "",
                                      }
                                    : {}),
                                ...(tile.style.title?.titleAlign
                                    ? {
                                          justifyContent: TITLE_ALIGNMENT_TO_JUSTIFY_CONTENT[tile.style.title.titleAlign],
                                      }
                                    : {}),
                            }}
                        >
                            {tile.$type === "MapTile" ? (
                                <>
                                    {hasTitle && !titleCenter && ChartTitle({tile, theme, titleHeaderHeight, hasSwapAxis})}
                                    <div
                                        className={cx("center-slot-container", {
                                            hasTitle,
                                            titleCenter,
                                        })}
                                        style={{
                                            height: hasTitle ? titleHeaderHeight : nonTitleHeaderHeight,
                                        }}
                                        ref={headerCenterSlotRef.set}
                                    />
                                    {hasTitle && titleCenter && ChartTitle({tile, theme, titleHeaderHeight, hasSwapAxis})}
                                    <div
                                        className={cx("right-slot-container", {
                                            hasTitle,
                                            titleCenter,
                                        })}
                                        style={{
                                            height: hasTitle ? titleHeaderHeight : nonTitleHeaderHeight,
                                        }}
                                        ref={headerRightSlotRef.set}
                                    />
                                </>
                            ) : (
                                hasTitle &&
                                ChartTitle({
                                    tile,
                                    theme,
                                    titleHeaderHeight,
                                    hasSwapAxis,
                                    hasOptions: style.showDownloadData || style.showDownloadImage,
                                })
                            )}
                        </div>

                        <div className={cx("content")} style={{height: contentHeight}}>
                            {content?.({
                                tileAction,
                                modalTileAction,
                                headerCenterSlotRef,
                                headerRightSlotRef,
                                hasSwapAxis,
                            })}
                        </div>
                    </div>

                    <div
                        className={cx("chart-tile-override-88i", {
                            active: tileAction.value?.tile,
                        })}
                    >
                        {cs(
                            ({}, next) => SuspendUpdate({active: !tileAction.value?.tile, next}),
                            () =>
                                tileAction.value?.tile
                                    ? cs(
                                          [
                                              "chartChildTile",
                                              (_, next) =>
                                                  renderChildTile({
                                                      next,
                                                      size,
                                                      tile: {
                                                          ...tileAction.value?.tile,
                                                          isChild: true,
                                                          goBack: () => tileAction.onChange(null),
                                                      },
                                                      tileAction: tileAction.value,
                                                      filterVals: tileAction.value.filterVals,
                                                  }),
                                          ],
                                          ({chartChildTile}) => chartChildTile.render()
                                      )
                                    : null
                        )}
                    </div>
                </>
            );
        }
    );

export const childModalTileDialog = ({isLive, sdkDashboard, tileFilters, next: rootNext}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    registryRender: true,
                    render: ({resolve, args: {tileAction}}) => ({
                        customizeDialog: true,
                        content: next({resolve, tileAction, sdkDashboard}),
                        className: {
                            "child-modal-tile-dialog-89i": true,
                            "is-live": isLive,
                        },
                    }),
                    next: rootNext,
                }),
        ],
        [
            "childTileComp",
            ({modal}, next) => {
                return renderChildTile({
                    next,
                    tile: modal.tileAction.tile,
                    tileAction: modal.tileAction,
                    sdkDashboard: modal.sdkDashboard,
                    filterVals: {
                        ...tileFilters?.getValue?.(),
                        ...modal.tileAction.filterVals,
                    },
                });
            },
        ],
        ({modal, childTileComp}) =>
            ModalChildTileLayout({
                onClose: () => modal.resolve(),
                content: childTileComp && childTileComp.render(),
                otherContent: childTileComp && childTileComp.filter(),
            })
    );

export const cChartOuterLayout = (props) => ({ content }) => {
    const outerLayout =
        {
            SingleKPITile: ChartOuterSingleKpiLayout,
            SparkLineKPITile: ChartOuterSparkLineKpiLayout,
            TextLabelTile: ChartOuterSingleKpiLayout,
        }[props.tile.$type] || ChartOuterLayout;

        return outerLayout({...props, content});
    };
