import {getExtractedPointsCompare} from "./get-extracted-points-compare";
import {getColumnPointsTableCompare} from "./column-points-table-compare";
import {getLinePointsTableForComboCompare} from "./line-points-table-for-combo-compare";
import {tooltipLayout} from "./tooltip-layout";
import {getExtractedPoints} from "./get-extracted-points";
import {getColumnPointsTable} from "./column-points-table";
import {getLinePointsTableForCombo} from "./line-points-table-for-combo";
import {getBarLineNonCompareHeader} from "./bar-line-non-compare-header";
import {getReferencePointsTable} from "./reference-points-table";

export const renderComboChartTooltip = ({points, tile, theme, rawData, formatters, isCompare}) => {
    const dataPoints = points.filter((p) => !p.series.userOptions.customType);

    const referencePointsTable = getReferencePointsTable({
        tile,
        theme,
        formatters,
        points,
        series: points[0].series.chart.series,
    });

    if (isCompare) {
        const barPoints = dataPoints.filter((p) => !p.series.userOptions.type || p.series.userOptions.type === "column");
        const {extractedPoints: columnExtractedPoints, generalInfo: columnGeneralInfo} = getExtractedPointsCompare({
            points: barPoints,
            formatters,
            rawData,
            keepZeroValuePoints: barPoints[0]?.series.userOptions.isCumulative,
        });

        const {extractedPoints: lineExtractedPoints, generalInfo: lineGeneralInfo} = getExtractedPointsCompare({
            points: dataPoints.filter((p) => p.series.userOptions.type && p.series.userOptions.type !== "column"),
            formatters,
            rawData,
            keepZeroValuePoints: true,
        });

        return tooltipLayout({
            content: `<div class="table">
                    ${
                        columnExtractedPoints?.length > 0
                            ? getColumnPointsTableCompare({
                                  tile,
                                  extractedPoints: columnExtractedPoints,
                                  generalInfo: columnGeneralInfo,
                                  rawData,
                                  formatters,
                                  theme,
                              })
                            : ""
                    }
                    ${
                        lineExtractedPoints?.length > 0
                            ? getLinePointsTableForComboCompare({
                                  tile,
                                  extractedPoints: lineExtractedPoints,
                                  generalInfo: lineGeneralInfo,
                                  rawData,
                                  formatters,
                                  theme,
                                  hideMainCompareTitle: columnExtractedPoints?.length > 0,
                              })
                            : ""
                    }
                </div>
                ${referencePointsTable}`,
            theme,
            isCompare,
        });
    }

    const barPoints = dataPoints.filter((p) => !p.series.userOptions.type || p.series.userOptions.type === "column");
    const {extractedPoints: columnExtractedPoints, generalInfo: columnGeneralInfo} = getExtractedPoints({
        points: barPoints,
        formatters,
        keepZeroValuePoints: barPoints[0]?.series.userOptions.isCumulative,
    });

    const {extractedPoints: lineExtractedPoints, generalInfo: lineGeneralInfo} = getExtractedPoints({
        points: dataPoints.filter((p) => p.series.userOptions.type && p.series.userOptions.type !== "column"),
        formatters,
        keepZeroValuePoints: true,
    });

    return tooltipLayout({
        content: [
            columnExtractedPoints?.length > 0 &&
                `${getBarLineNonCompareHeader({
                    tile,
                    extractedPoints: columnExtractedPoints,
                    generalInfo: columnGeneralInfo,
                    formatters,
                    theme,
                })}
                <div class="table ${lineExtractedPoints?.length > 0 ? "has-margin-bottom" : ""}">
                    ${getColumnPointsTable({
                        tile,
                        extractedPoints: columnExtractedPoints,
                        generalInfo: columnGeneralInfo,
                        rawData,
                        formatters,
                        theme,
                    })}
                </div>`,
            lineExtractedPoints?.length > 0 &&
                `${getBarLineNonCompareHeader({
                    tile,
                    extractedPoints: lineExtractedPoints,
                    generalInfo: lineGeneralInfo,
                    formatters,
                    theme,
                })}
                <div class="table">
                    ${getLinePointsTableForCombo({
                        tile,
                        extractedPoints: lineExtractedPoints,
                        generalInfo: lineGeneralInfo,
                        rawData,
                        formatters,
                        theme,
                    })}
                </div>`,
            referencePointsTable,
        ]
            .filter((v) => v)
            .join(""),
        theme,
    });
};
