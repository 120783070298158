import * as React from "react";
import {cs} from "@common/react/chain-services";
import "./display.scss";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {scope} from "@common/react/scope";
import {stateToSelect} from "@common/ui-components/form/state-to-select";
import {DebounceNumber} from "../../../../../../../../../../../../../common/form/debounce-number";
import {NumberInput} from "../../../../../../../../../../../../../common/form/number-input/number-input";
import {ShowHideToggle} from "../../../../../../../../../../../../../common/form/toggles/show-hide-toggle";
import {LpColorLine} from "../../../../../../../../../../common/left-panel/lp-color-line";
import {getPath} from "@common/utils/arr-path";

export const Display = ({tile, form, theme}) => ({
    initExpand: true,
    label: "Display",
    render: () => (
        <div className="gauge-tile-display-lk1">
            <div className="subsection">
                <div className="content">
                    {DropdownSelect({
                        label: "Thickness",
                        list: ["Thin", "Thick"],
                        ...stateToSelect(scope(tile, ["style", "tickness"])),
                    })}

                    {DropdownSelect({
                        label: "Arc Size",
                        list: ["180", "270", "300", "330", "360"].map((l) => ({
                            label: l,
                            value: "Deg" + l,
                        })),
                        valueToLabel: (v) => v.label,
                        ...stateToSelect(scope(tile, ["style", "arcSize"]), ["value"]),
                    })}

                    {cs(
                        [
                            "debounce",
                            (_, next) =>
                                DebounceNumber({
                                    state: scope(tile, ["style", "reservedPadding"]),
                                    next,
                                }),
                        ],
                        ({debounce}) =>
                            NumberInput({
                                label: "Reserved Padding",
                                state: debounce.state,
                                onBlur: debounce.flush,
                                unit: "percent",
                            })
                    )}
                </div>
            </div>

            {/*<div className="subsection">*/}
            {/*    <div className="header">*/}
            {/*        Borders*/}
            {/*        <div className="control">*/}
            {/*            {ShowHideToggle({state: scope(tile, ["style", "borderShown"])})}*/}
            {/*        </div>*/}
            {/*    </div>*/}

            {/*    {getPath(tile.value, ["style", "borderShown"]) && (*/}
            {/*        <div className="content">*/}
            {/*            {(() => {*/}
            {/*                const {value, onChange} = scope(tile, ["style", "borderWidth"]);*/}
            {/*                return cs(*/}
            {/*                    ["debounce", (_, next) => DebounceNumber({*/}
            {/*                        state: {*/}
            {/*                            value: value ?? 1,*/}
            {/*                            onChange,*/}
            {/*                        },*/}
            {/*                        next,*/}
            {/*                    })],*/}
            {/*                    ({debounce}) => NumberInput({*/}
            {/*                        state: debounce.state,*/}
            {/*                        label: "Border Width",*/}
            {/*                        unit: "pixels",*/}
            {/*                    })*/}
            {/*                )*/}
            {/*            })()}*/}
            {/*            {(() => {*/}
            {/*                const {value, onChange} = scope(tile, ["style", "borderColor"]);*/}
            {/*                return LpColorLine({*/}
            {/*                    label: "Border Color",*/}
            {/*                    state: {*/}
            {/*                        value: value || theme.general.tile.styles.tileBackgroundColorRGB,*/}
            {/*                        onChange,*/}
            {/*                    },*/}
            {/*                })*/}
            {/*            })()}*/}
            {/*        </div>*/}
            {/*    )}*/}
            {/*</div>*/}
        </div>
    ),
});
