import React from "react";

import {consumeContext} from "@common/react/context";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {spc} from "@common/react/state-path-change";
import {Button} from "@common/form/buttons/button/button";
import {scope} from "@common/react/scope";
import {TextInput} from "@common/form/text-input/text-input";

export const VerifyAppStep = ({onNext, onBack}) =>
    cs(consumeContext("apis"), consumeContext("auth"), ["state", (_, next) => UseState({next, initValue: {appToken: ""}})], ({apis, auth, state}) => {
        const {authenticationCode, loading, errorMessage = null} = state.value;
        const verifySecurityApp = async () => {
            if (!authenticationCode || authenticationCode.length < 6 || loading) {
                return;
            }

            try {
                spc(state, ["loading"], () => true);
                const res = await apis.user.verifyAssociationToken(auth.user.id, authenticationCode);
                if (res == "SUCCESS") {
                    await auth.refetchUser();
                    onNext();
                } else {
                    throw new Error("Invalid Code");
                }
            } catch (e) {
                state.onChange({
                    ...state.value,
                    errorMessage: e.message,
                    loading: false,
                });
            }
        };

        return (
            <>
                <div className="content">
                    <div className="verify-security-app">
                        <div className="label">Verify your security app</div>
                        <div className="description">Enter a code from authentication app to make sure everything works</div>

                        {TextInput({
                            placeholder: "Authentication Code",
                            state: scope(state, ["authenticationCode"]),
                            hasError: !!errorMessage,
                            errorMessage: "Invalid Code",
                            maxLength: 6,
                            autoFocus: true,
                            onKeyDown: (e) => e.key == "Enter" && verifySecurityApp(),
                        })}
                    </div>
                </div>

                <div className="buttons">
                    <Button btnType="secondary" disabled={loading} onClick={() => onBack()}>
                        Back
                    </Button>
                    <div className="space" />
                    <Button disabled={!authenticationCode || authenticationCode.length < 6 || loading} onClick={verifySecurityApp}>
                        Submit
                    </Button>
                </div>
            </>
        );
    });
