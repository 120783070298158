import {cs} from "@common/react/chain-services";
import {provideContext} from "@common/react/context";
import {scope} from "@common/react/scope";
import * as React from "react";
import {Expandable} from "../../../../../../../common/expandable/expandable";
import {getTileFields} from "./common/get-tile-fields";
import {getColumnType} from "./common/select-field/select-field";
import {SingleFieldWell} from "./common/single-field-well/single-field-well";
import "./tile-fields.scss";

export const TextLabelFields = ({tile, tileActions, debugFunction, next}) =>
    cs(
        [
            "tileFields",
            ({}, next) =>
                next(
                    getTileFields({
                        tile: tile.value,
                        groupAttr: ["valueField"],
                    })
                ),
        ],
        ({tileFields}, next) => provideContext("tileFields", tileFields, next),
        [
            "valueField",
            (_, next) =>
                SingleFieldWell({
                    field: scope(tile, ["valueField"]),
                    $type: "MeasureTileField",
                    label: "Value",
                    hideConfiguration: true,
                    hideAggregationType: true,
                    filterColumn: (column) => {
                        const type = getColumnType(column);
                        return type === "text";
                    },
                    onUpdateTile: (newField, oldField) => {
                        tile.onChange({
                            ...tile.value,
                            valueField: newField && {
                                ...newField,
                                // this will always be set to Minimum on backend, too.
                                aggregationFunc: "Minimum",
                            },
                        });
                    },
                    next,
                }),
        ],
        [
            "fieldsSection",
            ({valueField}, next) =>
                next({
                    render: () =>
                        Expandable({
                            label: "Fields",
                            render: () => <>{valueField.render()}</>,
                        }),
                    override: valueField.override,
                }),
        ],
        ({fieldsSection}) => {
            return next({
                render: () => <div className="tile-fields-3vr">{fieldsSection.render()}</div>,
                override: fieldsSection.override || debugFunction?.override(), // || tileActions?.override({fieldType: "CategoryTileField"}),
                overrideAll: debugFunction?.overrideAll, // || tileActions?.overrideAll,
            });
        }
    );
