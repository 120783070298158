import {css, cx} from "emotion";
import * as React from "react";
import {cs} from "../../../../../../react/chain-services";
import {consumeContext} from "../../../../../../react/context";
import {getPath} from "../../../../../../utils/arr-path";
import {addDate} from "../../../../../../utils/dates/date-object";
import {Dropdown} from "../../../../../dropdown/dropdown";
import {formatDateLong, formatTimeLong} from "../../../../../formats/format-date-long";
import {expandDistance} from "../../../../filters/common/control-styles-config";
import "./date-filter.scss";
import {DateFilterExpand} from "./expand/date-filter-expand";
import {DateFilterMobile} from "./expand/date-filter-mobile";
import {InlineDropdown} from "./inline-dropdown/inline-dropdown";

export const DateFilter = ({form, filter, collection, noComparing, disabled, showSelectTimezone = false, next}) =>
    cs(consumeContext("theme"), ({theme}) => {
        const renderRange = (range) => {
            if (!range || (!range?.from && !range?.to)) return `None (Show all)`;

            return !range.from ? (
                `Before ${formatDateLong(range.to)} ${form.data.value.allowTimeFilter ? formatTimeLong(range.to) : ""}`
            ) : !range.to ? (
                `After ${formatDateLong(range.from)} ${form.data.value.allowTimeFilter ? formatTimeLong(range.from) : ""}`
            ) : (
                <>
                    {formatDateLong(range.from)}&nbsp;{form.data.value.allowTimeFilter ? formatTimeLong(range.from) : ""}
                    <span className="dash">&ndash;</span>
                    {formatDateLong(range.to)}&nbsp;{form.data.value.allowTimeFilter ? formatTimeLong(range.to) : ""}
                </>
            );
        };

        const displayMain = renderRange(form.data.value.main);
        const displayComparing = renderRange(form.data.value.comparing);
        return next({
            mobileFilter: {
                label: "Date",
                shortPreview: () => displayMain,
                preview: () => {
                    return (
                        <div className={cx("preview-date")}>
                            <div className="main">{displayMain}</div>
                            {form.data.value.comparing && form.data.value.main && form.data.value.enableComparing && !noComparing && (
                                <div className="comparing">
                                    <span
                                        className="sub-label"
                                        style={{
                                            opacity: 0.7,
                                        }}
                                    >
                                        Compare to:
                                    </span>
                                    <span className="range">{displayComparing}</span>
                                </div>
                            )}
                        </div>
                    );
                },
            },
            control: ({size}) => {
                const inputTheme = theme.general.components;

                const renderToggle = ({showExpand, showingExpand}) => {
                    return (
                        <div
                            className={cx("toggle", {
                                comparing: form.data.value.comparing && form.data.value.enableComparing && !noComparing,
                            })}
                            onClick={() => showExpand(!showingExpand)}
                            style={{
                                border: `${inputTheme.inputBorderWidth}px solid ${inputTheme.inputBorderColorRGB}`,
                                background: `${inputTheme.inputBackgroundColorRGB}`,
                                color: `${inputTheme.inputTextColorRGB}`,
                                borderRadius: `${inputTheme.inputCornerRadius}px`,
                            }}
                        >
                            <div className="main">{displayMain}</div>

                            {form.data.value.comparing && form.data.value.main && form.data.value.enableComparing && !noComparing && (
                                <div
                                    className="comparing"
                                    style={{
                                        color: inputTheme.inputTextColorRGB,
                                    }}
                                >
                                    <div className="label">Compare to:</div>
                                    <div className="range">{displayComparing}</div>
                                </div>
                            )}

                            <i
                                className="fa fa-chevron-down"
                                style={{
                                    color: inputTheme.inputIconColorRGB,
                                }}
                            />
                        </div>
                    );
                };
                const renderExpand = ({close, width}) =>
                    DateFilterExpand({
                        size,
                        width,
                        state: form.data,
                        filter,
                        onClose: close,
                        showApplyBtn: getPath(collection, ["filterStyle", "runType"]) === "OnChange",
                        noComparing,
                        showSelectTimezone,
                    });
                return [
                    size === "mobile"
                        ? DateFilterMobile({
                              size,
                              state: form.data,
                              filter,
                              showApplyBtn: getPath(collection, ["filterStyle", "runType"]) === "OnChange",
                              noComparing,
                              showSelectTimezone,
                          })
                        : Dropdown({
                              disabled,
                              minExpandHeight: 200,
                              expandDistance,
                              detectOnWheelEvent: true,
                              disableAutoWidth: true,
                              className: cx("date-filter-24c", {fullWidth: size !== "desktop"}),
                              renderToggle,
                              renderExpand,
                          }),
                ];
            },
        });
    });
