import {cs} from "@common/react/chain-services";
import * as React from "react";
import "./error-dialog.scss";
import {DialogService} from "../../../../routes/common/dialog/dialog-service";
import {Button} from "../../../../../../common/form/buttons/button/button";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";

export const ErrorDialog = ({next: rootNext}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    render: ({resolve, args: {title, content, renderButtons}}) => ({
                        title,
                        width: 540,
                        content: next({
                            resolve,
                            content,
                            renderButtons,
                        }),
                    }),
                    next: rootNext,
                    registryRender: true,
                }),
        ],
        ({modal}) => (
            <div className="error-dialog-13x">
                <VerbDialogBodyScrollbar>
                    <div className="content">{modal.content}</div>
                </VerbDialogBodyScrollbar>

                {modal.renderButtons ? (
                    modal.renderButtons(modal)
                ) : (
                    <div className="buttons">
                        <Button onClick={() => modal.resolve()}>Got it</Button>
                    </div>
                )}
            </div>
        )
    );
