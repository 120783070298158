import {
    SortBooleanAscIcon,
    SortBooleanDescIcon,
    SortDateAscIcon,
    SortDateDescIcon,
    SortNumberAscIcon,
    SortNumberDescIcon,
    SortTextAscIcon,
    SortTextDescIcon,
} from "./icons/sorting-icons";

export const sortIconsTypes = {
    number: {
        asc: <SortNumberAscIcon />,
        desc: <SortNumberDescIcon />,
    },
    date: {
        asc: <SortDateAscIcon />,
        desc: <SortDateDescIcon />,
    },
    text: {
        asc: <SortTextAscIcon />,
        desc: <SortTextDescIcon />,
    },
    id: {
        asc: <SortTextAscIcon />,
        desc: <SortTextDescIcon />,
    },
    bool: {
        asc: <SortBooleanAscIcon />,
        desc: <SortBooleanDescIcon />,
    },
};
