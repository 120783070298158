import {cs} from "@common/react/chain-services";
import {StaticTooltipService} from "../../../../../../../../../../common/tooltip3/static-tooltip-service";
import React from "react";
import {LpLine} from "../../../../../../../../../../common/left-panel/line/lp-line";
import {ShowHideToggle} from "@common/form/toggles/show-hide-toggle";
import {scope} from "@common/react/scope";
import {css} from "emotion";

export const TableDisplay = ({tile}) => ({
    label: "Table Display",
    render: () => (
        <div className="column-settings-a23">
            <div className="subsection">
                <div className="content">
                    {LpLine({
                        label: "Table Header",
                        control: ShowHideToggle({state: scope(tile, ["style", "headerShown"])}),
                    })}

                    {LpLine({
                        label: "Table Totals",
                        control: ShowHideToggle({state: scope(tile, ["style", "totalsShown"])}),
                    })}

                    {LpLine({
                        label: "Column Filters",
                        info: "Turning on column filters will update the column search functionality to show selectable list filters on all columns",
                        control: ShowHideToggle({state: scope(tile, ["style", "columnFilters"])}),
                        className: css`
                            right: 6px;
                            top: -2px;
                            img {
                                width: 17px;
                                height: 17px;
                            }
                        `,
                    })}

                    {LpLine({
                        label: "Duplicate Rows",
                        info: "Hiding duplicate rows will remove duplicates.",
                        control: ShowHideToggle({
                            isReversed: true,
                            state: scope(tile, ["style", "removeDuplicates"]),
                        }),
                        className: css`
                            right: 6px;
                            top: -2px;
                            img {
                                width: 17px;
                                height: 17px;
                            }
                        `,
                    })}
                </div>
            </div>
        </div>
    ),
});
