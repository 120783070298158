import React from "react";
import {cs} from "@common/react/chain-services";
import "./unpivot-data-configuration.scss";
import {DropdownSelect2} from "@common/ui-components/dropdown-select/dropdown-select2";
import {rColumnSelectFromStepOutput} from "../../../../common/column-select-from-step-output/column-select-from-step-output";
import {SelectColumns} from "../../../../common/select-columns/select-columns";
import {aggregationFuncs} from "../../../../../../common/aggregation-funcs/aggregation-funcs";
import {ffToDropdown} from "../../../../../../../../../common/form/ff-to-dropdown";
import {rNewColumnNameInput} from "../../common/new-column-name-input";

export const UnpivotDataConfiguration = ({transformation, step, form}) =>
    cs(() => {
        const inputStep = transformation.value.steps.find((s) => s.id === step.value.inputStepID);
        const columnSelect = rColumnSelectFromStepOutput({
            label: "Column",
            columns: inputStep?.outputColumns || [],
        });

        return (
            <div className="unpivot-data-configuration-9we">
                <div className="config-group">
                    <div className="label">Select columns to normalize</div>

                    {SelectColumns({
                        step,
                        form,
                        path: ["columnsToNormalize"],
                        columns: inputStep?.outputColumns,
                    })}
                </div>

                <div className="config-group">
                    <div className="label">Labels Column Name</div>

                    {rNewColumnNameInput({
                        form,
                        newColumnPath: ["newLabelColumn"],
                        newColumnNamePath: ["newLabelColumn", "name"],
                    })}
                </div>

                <div className="config-group">
                    <div className="label">Values Column Name</div>

                    {rNewColumnNameInput({
                        form,
                        newColumnPath: ["newValueColumn"],
                        newColumnNamePath: ["newValueColumn", "name"],
                    })}
                </div>

                <div className="config-group">
                    <div className="label">Values Column Aggregation</div>
                    {DropdownSelect2({
                        label: "Aggregation",
                        list: aggregationFuncs,
                        valueToLabel: (v) => v.label,
                        ...ffToDropdown(form.field("aggregationFunc"), ["value"]),
                    })}
                </div>
            </div>
        );
    });
