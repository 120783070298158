import * as React from "react";
import {cs} from "@common/react/chain-services";
import "./leave-edit-transformation-dialog.scss";
import {DialogService} from "../../../../common/dialog/dialog-service";
import {Button} from "../../../../../../../common/form/buttons/button/button";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";

export const LeaveEditTransformationDialog = ({next: rootNext}) =>
    cs(
        [
            "modal",
            ({}, next) =>
                DialogService({
                    render: ({resolve}) => ({
                        title: "Transformation is Incomplete",
                        content: next({
                            resolve,
                        }),
                    }),
                    next: rootNext,
                }),
        ],
        ({modal}) => (
            <div className="leave-edit-transformation-dialog-4tg">
                <VerbDialogBodyScrollbar>
                    <div className="messages">
                        <div className="message">This transformation is not complete and cannot be used in your model.</div>
                        <div className="message">You may save the transformation and return to the model or cancel to complete the transformation.</div>
                    </div>
                </VerbDialogBodyScrollbar>

                <div className="buttons">
                    <Button btnType="secondary" onClick={() => modal.resolve("return-to-editing")}>
                        Cancel
                    </Button>
                    <Button onClick={() => modal.resolve("save-as-draft")}>Save Incomplete Transformation</Button>
                </div>
            </div>
        )
    );
