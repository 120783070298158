import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import * as React from "react";
import {PopupMenu} from "../../../../common/popup-menu/popup-menu";
import {isParentApiCollection} from "../collection-dashboard-helpers";
import {CollectionPreviewProfileDropdown} from "../preview-profile-dropdown/collection-preview-profile-dropdown";
import {SelectModelForCloningCollectionDialog} from "./clone-collection/select-model-for-cloning-collection-dialog";
import {RenameCollectionDialog} from "./rename-collection/rename-collection-dialog";

export const CollectionCM = ({folders, onUpdateFolders, moveCollectionDialog, deleteCollectionDialog, next, collectionToast, environment, profileFolders, iframeKeys}) =>
    cs(
        consumeContext("routing"),

        [
            "renameCollectionDialog",
            ({}, next) =>
                RenameCollectionDialog({
                    onDone: onUpdateFolders,
                    next,
                }),
        ],
        [
            "selectModelForCloningDialog",
            ({routing}, next) =>
                SelectModelForCloningCollectionDialog({
                    onDone: (newCollection) => {
                        collectionToast.show({
                            text: "Collection successfully cloned",
                            action: {
                                text: "Go to New Collection",
                                onClick: () =>
                                    routing.goto("edit-collection", {
                                        colId: newCollection.id,
                                    }),
                            },
                        });
                        folders.reload();
                    },
                    next,
                }),
        ],
        [
            "collectionCM",
            ({renameCollectionDialog, selectModelForCloningDialog, publishProcess, routing}) =>
                PopupMenu({
                    getCommands: ({collection, publishCollection}) => {
                        const apiParentCollection = isParentApiCollection(collection);
                        let commands = [
                            {
                                label: "Edit",
                                onClick: () =>
                                    routing.goto("edit-collection", {
                                        colId: collection.id,
                                    }),
                                isHidden: () => apiParentCollection,
                            },
                            {
                                label: "Preview",
                                subComponent: (
                                    <CollectionPreviewProfileDropdown
                                        previewMode
                                        collection={collection}
                                        iframeKeys={iframeKeys}
                                        profileFolders={profileFolders}
                                        style={{
                                            width: 220,
                                            right: "100%",
                                            top: 0,
                                        }}
                                    />
                                ),
                                isHidden: () => apiParentCollection,
                            },
                            {
                                label: "Rename",
                                onClick: () => renameCollectionDialog.show({collection}),
                                isHidden: () => collection.isChild || !!environment?.readOnly,
                            },
                            {
                                label: "Clone",
                                onClick: () =>
                                    selectModelForCloningDialog.show({
                                        collection,
                                        folder: folders.value.find((f) => f.collections.findIndex((c) => c.id === collection.id) > -1),
                                    }),
                                isHidden: () => !!environment?.readOnly || apiParentCollection,
                            },
                            {
                                label: "Move to",
                                onClick: () =>
                                    moveCollectionDialog.show({
                                        params: apiParentCollection ? collection.childs : collection,
                                        isUpdateMany: apiParentCollection,
                                    }),
                                isHidden: () => collection.isChild || !!environment?.readOnly,
                            },
                            {
                                label: "Publish",
                                onClick: () => publishCollection(),
                                isHidden: () => !publishCollection,
                            },
                            {
                                label: "Delete",
                                className: "text-danger",
                                onClick: () => deleteCollectionDialog.show({collections: apiParentCollection ? collection.childs : [collection]}),
                                isHidden: () => !!environment?.readOnly,
                            },
                        ];

                        return commands.filter((c) => !c.isHidden || !c.isHidden());
                    },
                    next,
                }),
        ]
    );
