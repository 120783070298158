const {getSeriesColorByLegendIndex} = require("../bar-series-processors/get-series-color-by-legend-index");
const {markerRadiusPlus} = require("./group-series-by-stack-line");
const {getMarkerRadiusFromWidth} = require("./group-series-by-stack-line");
const {markerSizes} = require("./group-series-by-stack-line");
const {lineWidths} = require("./group-series-by-stack-line");
const {arrMapToO} = require("../../../utils/objects");
const {unique} = require("../../../utils/collections");
const {chain} = require("../../../utils/fs");
const {prepareColor} = require("../prepare-color");
const {generateSeriesId} = require("../bar-series-processors/generate-series-id");

// used for combo chart with classic compare bar
const groupClassicBarCompareLineSeries = ({series, tile, tileFields, theme, zIndex, measurementFormatters, getYAxis, usingColorGroupField = true}) => {
    const isSparkLineKPITile = tile.$type == "SparkLineKPITile";
    const stackKeys = unique(series.map((s) => s.stack));
    const stackKeyTitleMap = arrMapToO(stackKeys, (sk) => unique(series.filter((s) => s.stack === sk).map((s) => s.measureAxisTitle)));
    const hasGroupField = tile.groupField != null;

    const {getDisplayColor, colorScale} = prepareColor({
        tile,
        tileFields,
        usingColorGroupField,
        dataColorPalettes: theme.dataVisualization.dataColorPalettes,
    });

    const getSeries = (series) => {
        const enhanceSeries = (series1, isCompare) => {
            const dataLabelsStyle = tile.style.dataLabels || tile.style.yAxisLineDataLabels;
            const getLineThickness = (stack) => tile.style.thickness || tile.style.yAxisLine[stack].thickness;
            const getLineMarker = (stack) => tile.style.marker || tile.style.yAxisLine[stack].marker;
            const getDisplayType = (stack) => tile.style.displayType || tile.style.yAxisLine[stack].displayType;

            colorScale?.setRange(0, series1.length - 1);

            return series1.map((s, i) => {
                const lineThickness = getLineThickness(s.stack)?.toLowerCase() || "thin";
                const lineWidth = lineWidths[lineThickness];
                const lineMarker = !isSparkLineKPITile ? getLineMarker(s.stack) : null;
                const lineMarkerSize = lineMarker ? markerSizes[lineThickness] : null;
                const lineMarkerRadius = lineMarker ? getMarkerRadiusFromWidth(lineMarkerSize.width) : null;
                const displayType = getDisplayType(s.stack);
                const type = (() => {
                    if (!displayType || displayType === "Line") {
                        return "line";
                    }
                    if (displayType === "Spline") {
                        return "spline";
                    }
                    if (displayType.startsWith("Area")) {
                        return "area";
                    }
                    if (displayType.startsWith("SplineArea")) {
                        return "areaspline";
                    }
                })();
                const {color: displayColor} = getDisplayColor({
                    series: s,
                    index: i,
                });

                const id = generateSeriesId(s);
                const indexInCustomStack = hasGroupField ? 0 : i;

                return {
                    ...s,
                    type,
                    zIndex,
                    customStack: s.stack,
                    indexInCustomStack,
                    ...(getYAxis && {yAxis: getYAxis(s)}),
                    // ...(isXAxisDate && {data: sort(s.data, (d) => d[0])}),
                    xAxis: 0,
                    marker: {
                        enabled: lineMarker ? lineMarker !== "None" : false,
                        ...(lineMarker &&
                            lineMarker !== "None" && {
                                symbol: lineMarker.replace("Hollow", "").toLowerCase(),
                            }),
                        radius: lineMarkerRadius,
                        ...(lineMarker?.startsWith("Hollow") && {
                            fillColor: theme.general.tile.styles.tileBackgroundColorRGB,
                            lineWidth: lineMarkerSize.borderWidth,
                            lineColor: null, // inherit from series
                        }),
                        states: {
                            hover: {
                                enabled: lineMarker ? lineMarker !== "None" : false,
                                radiusPlus: markerRadiusPlus,
                                lineWidthPlus: 0.5,
                            },
                        },
                    },
                    states: {
                        hover: {
                            lineWidthPlus: 0,
                            halo: {
                                size: (lineMarkerRadius + markerRadiusPlus) * 2, // hovered marker's halo radius
                            },
                        },
                    },
                    // data labels formatter in series level does not work anymore, since HC v.10.2.0
                    // ...(dataLabelsStyle.show && {
                    //     dataLabels: {
                    //         enabled: true,
                    //         formatter: function () {
                    //             return measurementFormatters[s.stack][indexInCustomStack](this.y);
                    //         }
                    //     },
                    // }),
                    label: {enabled: false},
                    // color: colors[i + (shiftColor ?? 0)],
                    color: displayColor,
                    animation: false,
                    ...(!isCompare
                        ? {
                              id,
                              lineWidth: lineWidth.primary,
                              // ...tooltipOptions[s.stack](indexInCustomStack),
                          }
                        : {
                              id: `${id}_compare`,
                              linkedTo: id,
                              // name: `${s.name} compare`,
                              data: modifyCompareData({
                                  compareSeries: s,
                                  seriesArr: series,
                              }),
                              opacity: 0.7,
                              dashStyle: "dash",
                              lineWidth: lineWidth.compare,
                              enableMouseTracking: false,
                              // dataLabels: {
                              //     enabled: false,
                              // },
                          }),
                };
            });
        };

        return [
            ...enhanceSeries(
                series.filter((s) => s.isCompare),
                true
            ),
            ...enhanceSeries(series.filter((s) => !s.isCompare)),
        ];
    };

    return arrMapToO(stackKeys, (sk) => ({
        label: stackKeyTitleMap[sk].join(" & "),
        series: chain(
            series.filter((s) => s.stack === sk),
            (_) => getSeries(_)
        ),
    }));
};
exports.groupClassicBarCompareLineSeries = groupClassicBarCompareLineSeries;

const modifyCompareData = ({compareSeries, seriesArr}) => {
    const respMainSeries = seriesArr.find((s) => s.name === compareSeries.name && !s.isCompare);
    if (!respMainSeries) return [];
    return compareSeries.data.map((d, i) => [respMainSeries.data[i][0], d[1]]);
};
