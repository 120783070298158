export const textFilterFormConfig = (filter) => ({
    fields:
        filter.option === "FreeFormText"
            ? {
                  freeFormValue: {
                      validators: [
                          // {
                          //     when: () => filter.required,
                          //     validate: (value) => value != null && value !== "",
                          // }
                      ],
                      debounce: true,
                  },
              }
            : {
                  selectableListValues: {
                      validators: [
                          // {
                          //     when: () => filter.required,
                          //     validate: (list) => list != null && list.length,
                          // }
                      ],
                  },
              },
});
