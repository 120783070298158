import "./dropdown.scss";

import {cx} from "emotion";
import React from "react";

import {AnyAction2} from "../../react/any-action-2";
import {cs} from "../../react/chain-services";
import {GlobalEvent} from "../../react/global-event";
import {Invoke} from "../../react/invoke";
import {Static2} from "../../react/static-2";
import {UseState} from "../../react/use-state";

import {SubcribeKeyEvents} from "../../react/keys/global-key-down";
import {remove1Mutate} from "../../utils/collections";
import {isEmpty, pick} from "../../utils/objects";
import {DropdownConsumer} from "./dropdown-registry";

// managing all nested dropdowns, ignore the bug that closes all dropdowns when clicking outside.
let dropDownList = (() => {
    let list = [];
    let map = {};
    return {
        getAll: () => list,
        add: (id, removeFunc) => {
            if (list.findIndex((i) => id == i) >= 0) {
                return;
            }
            list.push(id);
            if (removeFunc) {
                map[id] = removeFunc;
            }
        },
        last: () => list[list.length - 1],
        remove: (id) => {
            delete map[id];
            return remove1Mutate(list, id);
        },
        removeAllOther: (ignoreID) => {
            const listIDs = list.filter((id) => id != ignoreID);
            for (let i = 0; i < listIDs.length; i++) {
                const id = listIDs[i];
                map[id]();
            }
        },
    };
})();

const BindTabIndex = ({onTab, disabled}) => <input className="bind-tab-index" onFocus={onTab} disabled={disabled} tabIndex={0} />;

export const Dropdown = ({
    renderExpand,
    renderToggle,
    className,
    expandClassNames,
    borderRadius,
    registryRender = false,
    customZIndex = 1,
    expandDistance = 0,
    forcedExpandLeft,
    // minExpandHeight,
    // forcedExpandRight,
    forcedExpandBottom,
    onPassiveClose,
    onDropdownClose,
    disabled,
    dropdownRef,
    disabledTab = false,
    minWidth,
    useTogglePosition = false,
    detectOnWheelEvent = false,
    customExpandPosition,
    closeAllOtherDropdownWhenOpen = true,
    customToggleRef,
    disableAutoWidth = false,
    next: rootNext,
}) =>
    cs(
        ["dropdownID", (_, next) => Static2({next, getInitValue: () => Date.now()})],
        ["domRef", (_, next) => Static2({next})],
        ["expanderRef", (_, next) => Static2({next})],
        [
            "expanded",
            (_, next) =>
                UseState({
                    initValue: false,
                    next,
                }),
        ],
        [
            "state",
            (_, next) =>
                UseState({
                    initValue: {
                        expanded: false,
                        isShowed: false,
                        focus: false,
                        fixPosition: null,
                    },
                    next,
                }),
        ],
        ({state, expanderRef, domRef, dropdownID}) => {
            const {expanded, fixPosition, isShowed, focus} = state.value;

            const showExpand = (show, pos) => {
                if (disabled) return;
                show = show === undefined ? !expanded : show;

                if (show) {
                    dropDownList.add(dropdownID.get(), () => closeDropdown());
                }

                if (closeAllOtherDropdownWhenOpen) {
                    dropDownList.removeAllOther(dropdownID.get());
                }

                if (show !== expanded) {
                    setTimeout(() => {
                        let elem = useTogglePosition ? domRef.get().firstChild : domRef.get();

                        if (customToggleRef) {
                            elem = customToggleRef.get();
                        }

                        let defaultPos = elem.getBoundingClientRect();
                        pos = !isEmpty(pos) ? pos : pick(defaultPos, ["top", "bottom", "left", "right", "width", "height"]);
                        state.change((s) => ({
                            ...s,
                            expanded: show,
                            focus: !!show,
                            fixPosition: show ? pos : null,
                        }));

                        if (!show) onPassiveClose?.();
                    }, 0);
                }
            };

            const closeDropdown = (e) => {
                e?.preventDefault();
                e?.stopPropagation();
                dropDownList.remove(dropdownID.get());
                onDropdownClose?.();
                state.change(() => ({
                    expanded: false,
                    isShowed: false,
                    focus: false,
                    fixPosition: null,
                }));
            };

            dropdownRef?.set({
                showExpand,
                closeDropdown,
            });

            const render = () =>
                cs(["mouseHovering", (_, next) => UseState({next, initValue: false})], ({mouseHovering}) => {
                    const _position = (() => {
                        if (!isShowed) {
                            return {
                                top: -99999,
                                left: -99999,
                                zIndex: -1,
                            };
                        }

                        let returnPos = {};
                        if (typeof fixPosition.top == "number") {
                            returnPos.top = customExpandPosition?.top ?? fixPosition.top + (expandDistance ?? 0);
                        } else {
                            returnPos.bottom = fixPosition.bottom + (expandDistance ?? 0);
                        }

                        if (fixPosition.left) {
                            returnPos.left = customExpandPosition?.left ?? fixPosition.left;
                        } else {
                            returnPos.right = fixPosition.right;
                        }

                        return returnPos;
                    })();

                    let toggleDom = customToggleRef ? customToggleRef.get() : useTogglePosition ? domRef.get().firstChild : domRef.get();
                    const defaultExpandWidth = disableAutoWidth ? null : fixPosition.width ?? toggleDom?.getBoundingClientRect().width;

                    return (
                        <div
                            className={cx("expand", expandClassNames)}
                            onMouseEnter={() => mouseHovering.onChange(true)}
                            onMouseLeave={() => mouseHovering.onChange(false)}
                            ref={expanderRef.set}
                            style={{
                                ...(registryRender ? {position: "fixed", zIndex: customZIndex} : {}),
                                ..._position,
                                maxHeight: fixPosition.maxHeight,
                                overflow: fixPosition.maxHeight ? "auto" : borderRadius ? "hidden" : "",
                                minWidth,
                                borderRadius,
                                ...(customExpandPosition?.position ? {position: customExpandPosition.position} : {}),
                            }}
                        >
                            {isShowed &&
                                AnyAction2({
                                    disabled: !state.value.expanded,
                                    getDom: domRef.get,
                                    fn: (e) => {
                                        if (dropDownList.last() != dropdownID.get()) {
                                            return;
                                        }
                                        if (registryRender && expanderRef.get()?.contains(e.composedPath()[0])) {
                                            return;
                                        }

                                        if (customToggleRef?.get()?.contains(e.composedPath()[0])) {
                                            return;
                                        }

                                        // e.preventDefault();
                                        // e.stopPropagation();

                                        if (!closeAllOtherDropdownWhenOpen) dropDownList.remove(dropdownID.get());
                                        else dropDownList.removeAllOther();

                                        state.change(() => ({
                                            expanded: false,
                                            isShowed: false,
                                            fixPosition: null,
                                            focus: false,
                                        }));

                                        onPassiveClose?.();
                                    },
                                })}
                            {Invoke({
                                onMounted: () => {
                                    let {top, left, bottom, ...other} = fixPosition;
                                    const dom = expanderRef.get();

                                    if (!dom) {
                                        return;
                                    }

                                    const rect = dom.getBoundingClientRect();
                                    const rectToggle = toggleDom?.getBoundingClientRect() ?? {width: 0, height: 0};
                                    const ww = window.innerWidth;
                                    const wh = window.innerHeight;

                                    if (forcedExpandBottom || rect.height + rectToggle.height + top <= wh) {
                                        top = top + rectToggle.height;
                                        bottom = null;
                                    } else {
                                        if (rect.height + rectToggle.height + top > wh) {
                                            bottom = wh - top;
                                            top = null;
                                        } else {
                                            top = Math.max(top - rect.height, 0);
                                            bottom = null;
                                        }
                                    }

                                    if (forcedExpandLeft || rect.width + left > ww) {
                                        left = Math.max(left + rectToggle.width - rect.width, 0);
                                    }

                                    const maxHeight = forcedExpandBottom ? wh - (rect.y + rect.height) - 40 : null;
                                    state.change((s) => ({
                                        ...s,
                                        fixPosition: {
                                            top,
                                            bottom,
                                            left,
                                            maxHeight,
                                            ...other,
                                        },
                                        isShowed: true,
                                    }));
                                },
                            })}

                            {renderExpand({
                                close: closeDropdown,
                                ...(isShowed
                                    ? {
                                          ...fixPosition,
                                          width: defaultExpandWidth,
                                      }
                                    : {width: defaultExpandWidth}),
                            })}

                            {GlobalEvent({
                                eventName: "scroll",
                                fn: closeDropdown,
                            })}

                            {detectOnWheelEvent &&
                                !mouseHovering.value &&
                                GlobalEvent({
                                    eventName: "wheel",
                                    fn: (e) => {
                                        if (dropDownList.last() != dropdownID.get()) {
                                            return;
                                        }
                                        const parentElem = expanderRef.get();
                                        if (!parentElem?.contains?.(e.target)) {
                                            closeDropdown(e);
                                        }
                                    },
                                })}
                        </div>
                    );
                });

            return (
                <>
                    <div
                        className={cx("dropdown dropdown-jd2389", className, {
                            disabled,
                        })}
                        ref={(r) => domRef.set(r)}
                    >
                        {renderToggle?.({
                            showExpand,
                            showingExpand: expanded,
                            focus,
                        })}
                        {BindTabIndex({
                            disabled,
                            onTab: () => {
                                state.change((s) => ({
                                    ...s,
                                    focus: true,
                                }));
                            },
                        })}

                        {focus &&
                            SubcribeKeyEvents({
                                events: [
                                    {
                                        keyCombo: "Escape",
                                        onKeyDown: closeDropdown,
                                    },
                                    {
                                        keyCombo: "Enter",
                                        onKeyDown: (e) => {
                                            e?.stopPropagation();
                                            showExpand(true);
                                        },
                                    },
                                    {
                                        keyCombo: "Tab",
                                        onKeyDown: () =>
                                            !expanded &&
                                            state.change((s) => ({
                                                ...s,
                                                focus: false,
                                            })),
                                        disabled: disabledTab,
                                    },
                                ],
                            })}

                        {expanded &&
                            cs(
                                ["registry", ({}, next) => (registryRender ? React.createElement(DropdownConsumer, {}, next) : next(null))],
                                ({registry}) => <>{registry ? registry.render(render()) : render()}</>
                            )}
                    </div>
                    {rootNext?.({
                        showingExpand: expanded,
                        showExpand,
                        closeDropdown,
                        isShowed,
                    })}
                </>
            );
        }
    );
