import React from "react";
import {cs} from "@common/react/chain-services";
import "./inner-value-dropdown.scss";
import {TextInput} from "../../../../../../../../../../../../common/form/text-input/text-input";
import {ColumnSelect} from "./column-select/column-select";
import {DropdownSelect2} from "@common/ui-components/dropdown-select/dropdown-select2";

export const InnerValueDropdown = ({filterType, value, onChange, userMapping, ...otherProps}) =>
    cs(
        [
            "ColumnDropdown",
            (_, next) =>
                next(({value, onChange}) => {
                    return ColumnSelect({
                        label: "Column",
                        value,
                        onChange,
                        ...otherProps,
                    });
                }),
        ],
        [
            "UserDropdown",
            (_, next) =>
                next(({value, onChange, userMapping}) => {
                    return DropdownSelect2({
                        label: "User Mapping",
                        list: userMapping,
                        valueToLabel: (type) => type,
                        onChange: (type) => onChange({valueSelector: type}),
                        isSelected: (type) => type == value?.valueSelector,
                    });
                }),
        ],
        [
            "StaticInput",
            (_, next) =>
                next(({value, onChange}) => {
                    return TextInput({
                        label: "Value",
                        state: {
                            value: value?.value,
                            onChange: (value) => onChange({value}),
                        },
                        type: "text",
                    });
                }),
        ],
        ({ColumnDropdown, UserDropdown, StaticInput}) => {
            const dropdowns = [
                {
                    type: "ColumnBlockValue",
                    comp: ColumnDropdown,
                },
                {
                    type: "ViewColumnBlockValue",
                    comp: ColumnDropdown,
                },
                {
                    type: "UserBlockValue",
                    comp: UserDropdown,
                },
                {
                    type: "StaticBlockValue",
                    comp: StaticInput,
                },
            ];

            const View = dropdowns.find((d) => d.type == filterType).comp;

            return (
                <div className="inner-value-dropdown-o92">
                    {View({
                        value,
                        onChange,
                        userMapping,
                    })}
                </div>
            );
        }
    );
