import React from "react";
import {RemoveGuidanceButton} from "./step1";
import {Button} from "../../../../../../../../../common/form/buttons/button/button";

export const Step2 = ({onNext, onBack, structured, isLastStep, showTutorial}) => {
    return (
        <div className="step-1">
            <div>
                <strong>The sync method</strong> controls how and which data is pulled from the source.
            </div>
            <br />
            <div>
                <strong>The sync interval</strong> sets the frequency with which Verb retrieves data from the source. We suggest any type of
                incremental ingestion and avoiding full sync where possible.
            </div>

            <br />
            <div className="box info">
                Both of these values must be set on every {structured ? `table` : `object`} that has sync enabled before your data source
                can be saved.
            </div>

            <div className="step-footer">
                {RemoveGuidanceButton({showTutorial})}

                <Button btnType="border" iconLeft={<i className="fas fa-chevron-left" />} onClick={onBack}>
                    Previous
                </Button>

                {!isLastStep && (
                    <Button iconRight={<i className="fas fa-chevron-right" />} onClick={onNext}>
                        Next
                    </Button>
                )}
            </div>
        </div>
    );
};

export const Step2a = ({onNext, onBack}) => {
    return (
        <div className="step-1">
            <div className="box info">
                Both of these values must be set on every table that has sync enabled before your data source can be saved.
            </div>

            <div>
                <strong>Sync Method</strong>
            </div>
            <br />
            <div>The sync method sets 2 things: 1) whether any data needs ingested and 2) how much data will be ingested.</div>
            <br />
            <div>1. Change Tracking. Best for most use cases with a relational database.</div>
            <br />
            <div>
                2. Incremental. Best for {`{`}some text should go here defining when it is best to use{`}`}. Select the column that {`{`}I
                don’t really know how to pick a column, but we should help users to figure that out{`}`}.
            </div>
            <br />
            <div>3. Full. Best for tables where the data changes regularly as opposed to new data being added.</div>
            <br />
            <div>
                <strong>Sync Interval</strong>
            </div>
            <br />
            <div>The sync interval sets the frequency with which Verb will periodically check to see what should be synced.</div>

            <br />

            <div>
                If using Full sync method, it is best to set the sync interval to as infrequently as possible. For other sync methods,
                choose the option most appropriate to how fresh the data in your charts needs to be.
            </div>

            <div className="questions">
                <div className="label">Related Documentation</div>

                <div className="links">
                    {["How to select the best sync method", "How the sync interval impacts each invoice"].map((item, i) => (
                        <div className="link">
                            <div className="bullet" />
                            <div className="text">{item}</div>
                        </div>
                    ))}
                </div>
            </div>

            <div className="step-footer">
                {RemoveGuidanceButton({})}

                <Button btnType="border" iconLeft={<i className="fas fa-chevron-left" />} onClick={onBack}>
                    Previous
                </Button>

                <Button iconRight={<i className="fas fa-chevron-right" />} onClick={onNext}>
                    Next
                </Button>
            </div>
        </div>
    );
};
