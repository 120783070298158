import {DropdownSelect2} from "@common/ui-components/dropdown-select/dropdown-select2";
import {stateToSelect} from "@common/ui-components/form/state-to-select";
import {aggregationFuncs} from "../../../../common/aggregation-funcs/aggregation-funcs";

export const AggregationSelect = ({state, label, hideNone, dataType, exceptAggFuncs, extraAggFuncs, ...props}) => {
    let aggAggFuncs = aggregationFuncs.concat(extraAggFuncs || []);

    if (dataType) aggAggFuncs = dataType === "Unsupported" ? [] : aggAggFuncs.filter((f) => !f.dataTypes || f.dataTypes.indexOf(dataType) > -1);

    if (exceptAggFuncs) aggAggFuncs = aggAggFuncs.filter((f) => !f.dataTypes || f.dataTypes.indexOf(dataType) > -1).filter((f) => !exceptAggFuncs.includes(f.value));

    return DropdownSelect2({
        label: label || "Aggregation",
        list: hideNone ? aggAggFuncs.filter((x) => x.value != "None") : aggAggFuncs,
        valueToLabel: (v) => v.label,
        ...stateToSelect(state, ["value"]),
        ...props,
    });
};
