import {Load} from "@common/react/load";
import {cs} from "@common/react/chain-services";
import {consumeContext, provideContext} from "@common/react/context";

import {cGetFieldInfo} from "./get-field-info";

export const createGetFieldInfo = ({next}) =>
    cs(
        consumeContext("apis"),
        consumeContext("collection"),
        consumeContext("modelForCollection"),
        [
            "related",
            ({collection, apis}, next) => {
                return collection.value?.id
                    ? Load({
                          fetch: () => apis.collection.getRelatedTo(collection.value?.id),
                          next,
                      })
                    : next({});
            },
        ],
        ["getFieldInfo", ({modelForCollection: model, related}, next) => next(cGetFieldInfo(model, related?.DataSource))],
        ({getFieldInfo}) => provideContext("getFieldInfo", getFieldInfo, next),
        ({getFieldInfo}) => next(getFieldInfo)
    );
