const {cs} = require("../../../react/chain-services");
const {DownwardProxy} = require("../../../react/downward-proxy");

const RejectScrollbarWidth = ({size, next}) =>
    cs(
        [
            "width",
            ({}, next) =>
                DownwardProxy({
                    value: size.width,
                    allow: (newValue, oldValue) => oldValue == null || newValue > oldValue + 20 || newValue < oldValue,
                    // allow: (newValue, oldValue) => true,
                    next,
                }),
        ],
        ({width}) => next({...size, width})
    );
exports.RejectScrollbarWidth = RejectScrollbarWidth;
