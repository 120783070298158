const staticResourceHost = process.env.appConfig.verb["static-resource.host"].replace("{ticket}", process.env.appConfig["featureTicket"]);

export const sdkStaticUrl = (scriptSrc) => {
    const origin = (() => {
        if (scriptSrc.startsWith("http://localhost:")) {
            return null;
        } else {
            const m = /^(https?:\/\/[^\/]+)/.exec(scriptSrc);
            if (!m) {
                return staticResourceHost;
            }
            return m[1];
        }
    })();

    if (!origin) {
        return (url) => url;
    } else {
        return (url) => origin + "/sdk/" + url.replace(/^\//, "");
    }
};
