import * as React from "react";
import { cs } from "../../../../../../../react/chain-services";
import { consumeContext } from "../../../../../../../react/context";
import { Load2 } from "../../../../../../../react/load2";
import { FilterDataErrorHandler } from "../../../common/filter-data-error-handler/filter-data-error-handler";
import { ButtonGroup } from "./button-group/button-group";
import { Checkboxes } from "./checkboxes/checkboxes";
import { MultiSelectDropdown } from "./multi-select-dropdown/multi-select-dropdown";
import "./selectable-list.scss";
import { SingleSelectDropdown } from "./single-select-dropdown/single-select-dropdown";

export const SelectableList = ({filter, form, providedFilterData, filterStyle, filterValues, apiKey, next}) => {
    let filtersKey = {...filterValues};
    delete filtersKey[filter.id];
    return cs(
        consumeContext("apis"),
        consumeContext("collection"),
        consumeContext("theme"),
        [
            "filterData",
            ({apis, collection}, next) =>
                providedFilterData
                    ? next({value: providedFilterData})
                    : Load2({
                        next,
                        _key: JSON.stringify({
                            apiKey,
                            filtersKey,
                        }),
                        fetch: async () =>
                            apis.collection.getFilterData({
                                apiKey,
                                collectionId: collection?.value?.id,
                                filterId: filter.id,
                                columns: filter.columns,
                                filterValues,
                            }),
                    }),
        ],
        ({filterData, theme}) => {
            return next({
                mobileFilter: {
                    label: filter.label,
                    ...(mobileDisplayConfigs[filter.design]?.({form, data: filterData.value}) ?? mobileDisplayConfigs['SingleSelectDropdown']({data: filterData.value, form}))
                },
                control: ({size}) => [
                    (() => {
                        const state = form.field("selectableListValues").state;
                        const data = filterData.value;

                        if(filterData.error) {
                            return FilterDataErrorHandler({
                                error: filterData.error,
                            });
                        }

                        if(!filter.design || ["ButtonToggle", "Checkbox", "ButtonGroup"].includes(filter.design)) {
                            if(data?.length <= (filter.itemsVisible || 5)) {
                                return controlTypes[filter.design || "ButtonGroup"]({
                                    state,
                                    data,
                                    theme,
                                    size,
                                    placeholder: filter.hint,
                                });
                            } else {
                                const dropdown = !filter.design || ["Checkbox", "ButtonGroup"].includes(filter.design) ? MultiSelectDropdown : SingleSelectDropdown;
                                return dropdown({
                                    filterStyle,
                                    form,
                                    state,
                                    data,
                                    theme,
                                    size,
                                    placeholder: filter.hint,
                                });
                            }
                        }

                        return controlTypes[filter.design]({
                            filterStyle,
                            form,
                            state,
                            data,
                            theme,
                            size,
                            placeholder: filter.hint,
                        });
                    })(),
                ],
            });
        }
    );
};

const controlTypes = {
    SingleSelectDropdown: SingleSelectDropdown,
    MultiSelectDropdown: MultiSelectDropdown,
    ButtonToggle: (props) => ButtonGroup({...props, isToggle: true}),
    Checkbox: Checkboxes,
    ButtonGroup: ButtonGroup,
};
const singleValue = ({form}) => {
    const selected = form.data?.value?.selectableListValues ?? [];

    return {
        preview: () => selected[0],
        shortPreview: () => selected[0]
    }
}

const multipleValue = ({form, data}) => {
    const {selectableListValuesInverse = false, selectableListValues = []} = form.data?.value ?? {};
    let preview;
    if(!selectableListValuesInverse) {
        preview = selectableListValues.join(', ');
    } else {
        preview = `${Math.max(0, (data?.length || 0) - (selectableListValues?.length || 0))} selected`;
    }
    return {
        preview: () => preview,
        shortPreview: () => preview
    }

}

const mobileDisplayConfigs = {
    "SingleSelectDropdown": singleValue,
    "MultiSelectDropdown": multipleValue,
    "ButtonToggle": singleValue,
    "Checkbox": multipleValue,
    "ButtonGroup": multipleValue,
}
