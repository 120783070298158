import React from "react";
import {getTooltipFontSizes} from "../../factory/tooltip/shared-tooltip-formatter/get-tooltip-font-sizes";
import {tooltipService4} from "../../../../../web-client/src/routes/common/tooltip3/tooltip-service-3";
import {css, cx} from "emotion";
import {cs} from "../../../../react/chain-services";
import {consumeContext} from "../../../../react/context";
import {flatten1, unique} from "../../../../utils/collections";
import {keyDelim} from "../../pivot-table/common/pivot-table-column-value";

export const CURRENCY_CONVERSION = {
    Unconverted: "UseUnconverted",
    Exclude: "ExcludeMissing",
};

// ChartTileData
export const CurrencyConversionWarning = ({theme, currencyConversion}) =>
    cs(
        consumeContext("currencyOverrides"),
        ({currencyOverrides}, next) => (theme.sdkDashboard && currencyOverrides && currencyConversion?.hasFailures ? next() : null),
        [
            "tooltip",
            (_, next) => {
                const themeMode = theme.dataVisualization.toolTipsAndLegends.tooltipTheme.toLowerCase();
                return tooltipService4({
                    direction: theme?.tooltipHover?.position?.toLowerCase() || "below",
                    className: `${themeMode}-theme`,
                    tooltipContentStyle: {
                        fontSize: getTooltipFontSizes(theme).small,
                        fontFamily: theme.general.canvas.fontFamily,
                        color: themeMode === "light" ? theme.general.canvas.fontColorRGB || "#546B81" : "#E2E2E2",
                    },
                    next,
                });
            },
        ],
        ({tooltip}) => {
            return (
                <span
                    className={cx("material-icons-round")}
                    style={{
                        color: "#ff5959",
                        "font-size": "17px",
                        "margin-left": "3px",
                    }}
                    {...tooltip(() => <>{currencyConversion.message}</>)}
                >
                    warning
                </span>
            );
        }
    );

const validField = (t) => ![t.modelColumnID, t.modelID, t.modelTableID].includes("00000000-0000-0000-0000-000000000000");

export const getCurrencyConversion = ({$type, data, tileFields}) => {
    if ($type === "ChartTileData") {
        const conversions = tileFields
            .map((t) => {
                if (t.currencyConversion && validField(t.currencyConversion)) return t.currencyConversion;
                return null;
            })
            .filter((v) => v);

        if (conversions.length === 0) return null;

        return {
            [CURRENCY_CONVERSION.Unconverted]: {
                conversions,
                message: "Some values did not have a currency type. Conversion may be incorrect.",
                hasFailures: data.series.filter((s) => s.currencyConversionFailures).length > 0,
            },
            ExcludeMissing: {
                conversions,
                message: "Some values are missing because the currency could not be converted.",
                hasFailures: data.series.filter((s) => s.currencyConversionFailures).length > 0,
                // hasFailures: flatten1(data.series.map(s => s.data.filter(item => {
                //     let val = flatten1(item)[1];
                //     return val === 0 || !val;
                // }))).length > 0
            },
        }[conversions[0]?.isoCurrencyStrategy];
    }

    if (["TableTileData", "PivotTableTileData"].includes($type)) {
        const indexes = tileFields
            .map((t, index) => {
                if (t.currencyConversion && validField(t.currencyConversion)) {
                    return index;
                }
                return null;
            })
            .filter((v) => v);

        if (indexes.length === 0) return null;

        return {
            [CURRENCY_CONVERSION.Unconverted]: {
                message: "Some values did not have a currency type. Conversion may be incorrect.",
                hasFailures: flatten1(data.rows.map((r) => r.currencyConversionFailures)).length > 0,
            },
            [CURRENCY_CONVERSION.Exclude]: {
                message: "Some values are missing because the currency could not be converted.",
                hasFailures: data.rows.filter((r) => !indexes.every((index) => r.columns[`${index}${keyDelim}`] > 0)).length > 0,
            },
        }[tileFields[indexes[0]]?.currencyConversion?.isoCurrencyStrategy];
    }

    if ($type === "GaugeTileData") {
        const conversions = tileFields
            .map((t) => {
                if (t.currencyConversion && validField(t.currencyConversion)) return t.currencyConversion;
                return null;
            })
            .filter((v) => v);

        if (conversions.length === 0) return null;

        return {
            [CURRENCY_CONVERSION.Unconverted]: {
                message: "Some values did not have a currency type. Conversion may be incorrect.",
                hasFailures: data.currencyConversionFailures,
            },
            [CURRENCY_CONVERSION.Exclude]: {
                message: "Some values are missing because the currency could not be converted.",
                hasFailures: data.currencyConversionFailures,
            },
        }[conversions[0].isoCurrencyStrategy];
    }

    if ($type === "KPITileData") {
        if (data.summaryRow && (!data.rows || (data.rows && data.rows.length === 0))) {
            const conversion = tileFields[0]?.currencyConversion;
            if (!conversion || !validField(conversion)) return null;

            let result = {
                [CURRENCY_CONVERSION.Unconverted]: {
                    message: "Some values did not have a currency type. Conversion may be incorrect.",
                    hasFailures: data.summaryRow.currencyConversionFailures,
                },
                [CURRENCY_CONVERSION.Exclude]: {
                    message: "Some values are missing because the currency could not be converted.",
                    hasFailures: data.summaryRow.currencyConversionFailures,
                },
            }[conversion.isoCurrencyStrategy];

            return {
                ...(result || {}),
                isoCurrencyStrategy: conversion.isoCurrencyStrategy,
            };
        }

        const conversions = tileFields
            .map((t) => {
                if (t.currencyConversion && validField(t.currencyConversion)) return t.currencyConversion;
                return null;
            })
            .filter((v) => v);

        if (conversions.length === 0) return null;

        let result = {
            [CURRENCY_CONVERSION.Unconverted]: {
                message: "Some values did not have a currency type. Conversion may be incorrect.",
                hasFailures: data.rows.filter((r) => r.currencyConversionFailures).length > 0,
            },
            [CURRENCY_CONVERSION.Exclude]: {
                message: "Some values are missing because the currency could not be converted.",
                hasFailures: data.rows.filter((r) => r.currencyConversionFailures).length > 0,
            },
        }[conversions[0].isoCurrencyStrategy];

        return {
            ...(result || {}),
            isoCurrencyStrategy: conversions[0].isoCurrencyStrategy,
        };
    }
};

export const getTableTileColumnCurrencyConversion = ({index, data, tileFields}) => {
    const conversions = tileFields
        .map((t) => {
            if (t.currencyConversion && validField(t.currencyConversion)) return t.currencyConversion;
            return null;
        })
        .filter((v) => v);

    if (conversions.length === 0) return null;

    return {
        [CURRENCY_CONVERSION.Unconverted]: {
            message: "Some values did not have a currency type. Conversion may be incorrect.",
            hasFailures: flatten1(data.rows.map((r) => r.currencyConversionFailures)).includes(index),
        },
        [CURRENCY_CONVERSION.Exclude]: {
            message: "Some values are missing because the currency could not be converted.",
            hasFailures: flatten1(data.rows.map((r) => r.currencyConversionFailures)).includes(index),
        },
    }[conversions[0]?.isoCurrencyStrategy];
};

export const getPivotTableTileColumnCurrencyConversion = ({data, column, tileFields}) => {
    const field = tileFields.find((t) => t.modelColumnID === column.columnName);
    if (!field?.currencyConversion || !validField(field?.currencyConversion)) return null;

    return {
        [CURRENCY_CONVERSION.Unconverted]: {
            message: "Some values did not have a currency type. Conversion may be incorrect.",
            hasFailures: data.currencyConversionFailures,
        },
        [CURRENCY_CONVERSION.Exclude]: {
            message: "Some values are missing because the currency could not be converted.",
            // hasFailures: data.values[`${column.parentID}|${column.aggIndex}`].filter((d) => d.columnName === `${column.columnName}${column.aggregateName}` && d.value === 0).length > 0,
            hasFailures: data.currencyConversionFailures,
        },
    }[field.currencyConversion.isoCurrencyStrategy];
};
