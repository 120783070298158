import {cs} from "../../../../../react/chain-services";
import {css, cx} from "emotion";
import {Dropdown} from "../../../../dropdown/dropdown";
import React from "react";
import {expandDistance} from "../control-styles-config";
import {UseState} from "../../../../../react/use-state";
import {SearchInput} from "../../../../../form/search-input/search-input";
import {sort} from "../../../../../utils/collections";
import {consumeContext} from "../../../../../react/context";
import {keyed} from "../../../../../react/keyed";
import {LoadingIndicator} from "../../../../loading-indicator/loading-indicator";
import {isMatchText} from "../../../../../utils/strings";
import {FixedSizeList} from "react-window";
import {getHeightOfDropdown, getInputSearchCss, ITEM_SIZE_HEIGHT} from "./dropdown.config";
import {TruncatingTooltip} from "../../../../../../web-client/src/routes/common/truncating-tooltip/truncating-tooltip";
import {StyledClass} from "@common/react/styled-class";

export const DropdownSelectLive = ({
    label,
    placeholder,
    list,
    valueToLabel = (v) => v,
    valueToSearch,
    onChange,
    isSelected,
    hasError,
    domRef,
    disabled,
    hideLabelOnValue,
    className,
    enableSearch,
    allowUnselect,
    customExpandPosition,
    toggleStyle,
    prioritizeSelect = true,
    iconColor,
    dropdownCss,
    borderRadius,
    closeAllOtherDropdownWhenOpen,
    fixMaxWidth = true,
    overrideWidth,
}) =>
    cs(
        consumeContext("theme"),
        ["searching", (_, next) => UseState({next})],
        ["selected", (_, next) => next(list?.find(isSelected))],
        [
            "renderToggle",
            ({selected}, next) =>
                next(({showExpand, showingExpand}) => {
                    return (
                        <div
                            className={cx("toggle", {expanding: showingExpand})}
                            style={toggleStyle}
                            onClick={() => !disabled && showExpand(!showingExpand)}
                        >
                            {selected !== undefined ? valueToLabel(selected, true) : placeholder}
                            <i
                                className="fa fa-chevron-down"
                                style={{
                                    color: iconColor,
                                }}
                            />
                        </div>
                    );
                }),
        ],
        [
            "renderExpand",
            ({searching, selected, theme}, next) =>
                next(({close, width}) =>
                    cs(keyed(list?.length ?? Date.now()), () => {
                        const sortedList = prioritizeSelect ? sort(list, (l) => (isSelected(l) ? 0 : 1)) : list;
                        const searched = sortedList?.filter((l) => isMatchText((valueToSearch || valueToLabel)(l), searching.value));
                        const componentTheme = theme.general.components;

                        return cs(
                            [
                                "inputSearchCss",
                                (_, next) =>
                                    StyledClass({
                                        content: getInputSearchCss(componentTheme, theme),
                                        next,
                                    }),
                            ],
                            ({inputSearchCss}) => (
                                <div
                                    className="expand-group"
                                    style={{
                                        maxWidth: fixMaxWidth ? "100%" : "auto",
                                        minWidth: overrideWidth ?? width,
                                    }}
                                >
                                    {cs(
                                        (_, next) => (!list ? <div style={{padding: 24}}>{LoadingIndicator({})}</div> : next()),
                                        () => (
                                            <>
                                                {enableSearch &&
                                                    SearchInput({
                                                        state: searching,
                                                        placeholder: "Search...",
                                                        delay: 300,
                                                        autoFocus: true,
                                                        className: inputSearchCss,
                                                    })}

                                                <div className="list">
                                                    {!searched ? (
                                                        <div
                                                            className="no-results"
                                                            style={{
                                                                background: componentTheme.menuBackgroundColorRGB,
                                                                color: componentTheme.menuTextColorRGB,
                                                            }}
                                                        >
                                                            {LoadingIndicator({})}
                                                        </div>
                                                    ) : !searched?.length ? (
                                                        <div
                                                            className="no-results"
                                                            style={{
                                                                background: componentTheme.menuBackgroundColorRGB,
                                                                color: componentTheme.menuTextColorRGB,
                                                            }}
                                                        >
                                                            No results found.
                                                        </div>
                                                    ) : (
                                                        <FixedSizeList
                                                            // width={width}
                                                            className="fixed-select-list"
                                                            height={getHeightOfDropdown(list)}
                                                            itemData={{list: searched, valueToLabel, dropdownCss}}
                                                            itemCount={searched.length}
                                                            itemSize={ITEM_SIZE_HEIGHT}
                                                        >
                                                            {({data, index, style}) => {
                                                                // console.log(style);
                                                                const {list, dropdownCss, valueToLabel} = data;
                                                                let value = list[index];
                                                                // style.position
                                                                return cs(
                                                                    [
                                                                        "truncatingTooltip",
                                                                        (_, next) =>
                                                                            TruncatingTooltip({
                                                                                forceUpdate: true,
                                                                                delay: 0,
                                                                                alternateStyle: false,
                                                                                // className: `${themeMode}-theme`,
                                                                                // tooltipContentStyle: {
                                                                                //     fontSize: getTooltipFontSizes(theme).small,
                                                                                //     fontFamily: theme.general.canvas.fontFamily,
                                                                                //     color: themeMode === "light" ? theme.general.canvas.fontColorRGB || "#546B81" : "#E2E2E2",
                                                                                // },
                                                                                next,
                                                                            }),
                                                                    ],
                                                                    ({truncatingTooltip}) => (
                                                                        <div
                                                                            key={index}
                                                                            className={cx("item", dropdownCss, {
                                                                                selected: selected === value,
                                                                            })}
                                                                            ref={truncatingTooltip.ref}
                                                                            style={style}
                                                                            onClick={() => {
                                                                                if (selected !== value) {
                                                                                    onChange(value);
                                                                                } else if (allowUnselect) {
                                                                                    onChange(null);
                                                                                }
                                                                                searching.onChange(null);
                                                                                close();
                                                                            }}
                                                                            {...truncatingTooltip.tooltip(valueToLabel(value))}
                                                                        >
                                                                            {valueToLabel(value)}
                                                                        </div>
                                                                    )
                                                                );
                                                            }}
                                                        </FixedSizeList>
                                                    )}
                                                </div>
                                            </>
                                        )
                                    )}
                                </div>
                            )
                        );
                    })
                ),
        ],
        ({renderToggle, renderExpand, searching, selected}) => {
            return (
                <div
                    className={cx("dropdown-select dropdown-select-live-5gy", className, {
                        hasError,
                    })}
                    ref={domRef}
                >
                    {Dropdown({
                        renderToggle,
                        renderExpand,
                        expandDistance,
                        customExpandPosition,
                        onPassiveClose: () => searching.onChange(null),
                        borderRadius,
                        detectOnWheelEvent: true,
                        closeAllOtherDropdownWhenOpen,
                    })}

                    {label && (selected === undefined || !hideLabelOnValue) && (
                        <div
                            className={cx("label", {
                                up: selected !== undefined,
                            })}
                        >
                            {label}
                        </div>
                    )}
                </div>
            );
        }
    );
