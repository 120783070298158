import * as React from "react";
import {ShowHideToggle} from "../../../../../../../../../../../../../common/form/toggles/show-hide-toggle";
import "./tile-title.scss";
import {TextInput} from "../../../../../../../../../../../../../common/form/text-input/text-input";
import {scope} from "@common/react/scope";
import {ffToTextInput} from "../../../../../../../../../../../../../common/form/ff-to-text-input";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {TITLE_ALIGNMENT} from "../../../common/tile-title/tile-title";
import {ffToDropdown} from "../../../../../../../../../../../../../common/form/ff-to-dropdown";

export const TileTitle = ({tile, form}) => ({
    label: "Tile Title",
    control: ShowHideToggle({state: scope(tile, ["style", "title", "show"])}),
    render: () => (
        <div className="combo-chart-tile-title-43l">
            {TextInput({
                label: "Tile Title",
                ...ffToTextInput(form.field(["title"])),
            })}
            {scope(tile, ["style", "title", "show"]).value &&
                DropdownSelect({
                    list: [
                        {label: "Left Aligned", value: TITLE_ALIGNMENT.LEFT},
                        {
                            label: "Center Aligned",
                            value: TITLE_ALIGNMENT.CENTER,
                        },
                    ],
                    label: "Title Alignment",
                    valueToLabel: (v) => v?.label ?? "",
                    ...ffToDropdown(form.field("style.title.titleAlign"), ["value"]),
                })}
        </div>
    ),
});
