// make use of the series label drawn by series-label module.
// adjust position to left aligned if measure axis is not opposite.
export function adjustIndicatorSeriesLabel(H) {
    function adjustIndicatorSeriesLabel(chart) {
        const indicatorSeries = chart.series.filter((s) => s.userOptions.customType === "IndicatorReferenceLine");

        if (!indicatorSeries?.length) {
            return;
        }

        for (const s of indicatorSeries) {
            if (!s.labelBySeries) {
                return;
            }

            let newTranslateX, newTranslateY;

            if (chart.userOptions.chart.customType === "HorizontalBarChartTile") {
                // indicator series label is drawn manually
                // if (!s.yAxis.opposite) { // measure axis at bottom position
                //     if (chart.scrollingContainer) {
                //         const topmostPoint = s.points[s.points.length - 1];
                //         newTranslateX = topmostPoint.plotY - s.labelBySeries.bBox.height;
                //         newTranslateY = topmostPoint.plotX;
                //     } else {
                //         const bottommostPoint = s.interpolatedPoints[s.interpolatedPoints.length - 1];
                //         newTranslateX = chart.chartWidth - bottommostPoint.plotY + s.labelBySeries.bBox.height;
                //         newTranslateY = chart.chartHeight - bottommostPoint.plotX - s.labelBySeries.bBox.width - chart.axisOffset[2];
                //     }
                // } else { // measure axis at top position
                //     if (chart.scrollingContainer) { // if having scrolling
                //         const topmostPoint = s.points[s.points.length - 1];
                //         newTranslateX = topmostPoint.plotY - s.labelBySeries.bBox.height;
                //         newTranslateY = topmostPoint.plotX + chart.axisOffset[0];
                //     } else {
                //         const topmostPoint = s.points[0];
                //         newTranslateX = chart.chartWidth - topmostPoint.plotY + s.labelBySeries.bBox.height;
                //         newTranslateY = chart.chartHeight - topmostPoint.plotX - s.labelBySeries.bBox.height;
                //     }
                // }
                //
                // s.labelBySeries.element.setAttribute(
                //     "transform",
                //     `translate(${newTranslateX}, ${newTranslateY}), rotate(90)`
                // );
            } else {
                if (s.yAxis.opposite) {
                    // measure axis at right position
                    const rightmostPoint = s.interpolatedPoints[s.interpolatedPoints.length - 1];
                    newTranslateX = rightmostPoint.chartX - s.labelBySeries.bBox.width;
                    newTranslateY = rightmostPoint.chartY - (s.userOptions.dataLabels.enabled ? 0 : s.labelBySeries.bBox.height + 4);
                } else {
                    // measure axis at left position
                    const leftmostPoint = s.interpolatedPoints[0];
                    newTranslateX = leftmostPoint.chartX;
                    newTranslateY = leftmostPoint.chartY - (s.userOptions.dataLabels.enabled ? 0 : s.labelBySeries.bBox.height + 4);
                }

                s.labelBySeries.element.setAttribute("transform", `translate(${newTranslateX}, ${newTranslateY})`);
            }
        }
    }

    // line series labels is drawn by method drawSeriesLabels of H.Chart.prototype
    // ref. https://github.com/highcharts/highcharts/blob/a0fe07fbd97a279e877fd77d66746cd6ea49816d/ts/Extensions/SeriesLabel.ts#L793
    H.wrap(H.Chart.prototype, "drawSeriesLabels", function (proceed) {
        proceed.apply(this);
        adjustIndicatorSeriesLabel(this);
    });
}
