import "./confirm-dialog.scss";

import React from "react";

import {cs} from "@common/react/chain-services";
import {DialogService} from "../dialog/dialog-service";
import {UseState} from "@common/react/use-state";
import {Button} from "../../../../../common/form/buttons/button/button";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";

export const ConfirmDialog = ({next: rootNext, noHeader = false, title, body, submitText = "Confirm", cancelText = "Cancel", hideCancel}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    registryRender: true,
                    strong: true,
                    render: ({resolve, args: {description, confirmClick, confirmTitle, submitBtn, ...other} = {}}) => ({
                        title: confirmTitle || title,
                        width: 480,
                        noHeader,
                        content: next({
                            resolve,
                            description,
                            confirmClick,
                            submitBtn,
                        }),
                        initShow: true,
                        className: "confirm-dialog-wrapper-z33",
                    }),
                    next: rootNext,
                }),
        ],
        ["disabled", (_, next) => UseState({next})],
        ({modal, disabled}) =>
            cs(() => (
                <div className="confirm-dialog-z33">
                    <VerbDialogBodyScrollbar>
                        <div className="message">{body ? body : modal.description}</div>
                    </VerbDialogBodyScrollbar>

                    <div className="buttons">
                        {!hideCancel && (
                            <Button
                                btnType="secondary"
                                onClick={() => {
                                    modal.resolve(false);
                                }}
                            >
                                {cancelText}
                            </Button>
                        )}

                        <Button
                            disabled={disabled.value}
                            onClick={async () => {
                                if (modal.confirmClick) {
                                    disabled.onChange(true);
                                    await modal.confirmClick();
                                    modal.resolve(true);
                                } else {
                                    modal.resolve(true);
                                }
                            }}
                        >
                            {modal.submitBtn || submitText}
                        </Button>
                    </div>
                </div>
            ))
    );
