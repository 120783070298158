import {destroyTooltipConnector, drawTooltipConnector} from "./draw-tooltip-connector";
import {drawTooltipConnectorHorizontal} from "./draw-tooltip-connector-horizontal";

export const extendTooltipConnector = (H, isMobile) => {
    H.wrap(H.Tooltip.prototype, "refresh", function (proceed, points) {
        // Run the original proceed method
        proceed.apply(this, Array.prototype.slice.call(arguments, 1));

        const chart = this.chart;
        const tooltipStyle = this.options.tooltipStyle;

        if (tooltipStyle.indicatorLineShown && !["pie", "solidgauge", "pyramid", "scatter", "bubble", "venn"].includes(chart.userOptions.chart.type)) {
            const timeout = isMobile ? 300 : 0; // even 0 to ensure this to run after the tooltip is rendered

            chart.tooltipConnectorDrawTimeout = setTimeout(() => {
                if (chart.userOptions.chart.customType === "HorizontalBarChartTile") {
                    drawTooltipConnectorHorizontal({chart, tooltipStyle});
                } else {
                    drawTooltipConnector({chart, tooltipStyle});
                }
            }, timeout);
        }
    });

    H.wrap(H.Tooltip.prototype, "manuallyHide", function (proceed) {
        proceed.apply(this);
        destroyTooltipConnector({chart: this.chart});
    });
};
