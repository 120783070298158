// simple version of @paularmstrong/normalizr
const defaultOptions = {
    idAttribute: "id",
    mergeStrategy: (entityA, entityB) => {
        return {...entityA, ...entityB};
    },
    processStrategy: (input) => ({...input}),
};

const createSchema = (name, definition = {}, options = defaultOptions) => {
    let schema = Object.keys(definition).reduce((entitySchema, key) => {
        const schema = definition[key];
        return {...entitySchema, [key]: schema};
    }, {});

    const {idAttribute, mergeStrategy, processStrategy} = {
        ...defaultOptions,
        ...options,
    };

    let _getId = typeof idAttribute === "function" ? idAttribute : (item) => item[idAttribute];

    const entitySchema = {
        name,
        getId: (input, parent, key) => _getId(input, parent, key),
        merge: mergeStrategy,
        process: processStrategy,
        normalize: (input, parent, key, addEntity, visitedEntities) => {
            const id = _getId(input, parent, key); // should store resolved ID?
            const entityType = name;

            if (!(entityType in visitedEntities)) {
                visitedEntities[entityType] = {};
            }
            if (!(id in visitedEntities[entityType])) {
                visitedEntities[entityType][id] = [];
            }
            if (visitedEntities[entityType][id].some((entity) => entity === input)) {
                return id;
            }
            visitedEntities[entityType][id].push(input);

            const processedEntity = {
                ...processStrategy(input, parent, key),
                _id: id,
            };
            Object.keys(schema).forEach((key) => {
                if (processedEntity.hasOwnProperty(key) && typeof processedEntity[key] === "object") {
                    const _schema = schema[key];
                    const resolvedSchema = typeof _schema === "function" ? _schema(input) : _schema;

                    processedEntity[key] = visit(
                        processedEntity[key],
                        processedEntity,
                        key,
                        resolvedSchema,
                        addEntity,
                        visitedEntities
                    );
                }
            });

            addEntity(entitySchema, processedEntity, input, parent, key);
            return id;
        },
    };

    return entitySchema;
};

const normalizeObject = (schema, input, parent, key, addEntity, visitedEntities) => {
    const object = {...input};
    Object.keys(schema).forEach((key) => {
        const localSchema = schema[key];
        const resolvedLocalSchema = typeof localSchema === "function" ? localSchema(input) : localSchema;
        const value = visit(input[key], input, key, resolvedLocalSchema, addEntity, visitedEntities);
        if (value === undefined || value === null) {
            delete object[key];
        } else {
            object[key] = value;
        }
    });
    return object;
};

const validateSchema = (definition) => {
    const isArray = Array.isArray(definition);
    if (isArray && definition.length > 1) {
        throw new Error(`Expected schema definition to be a single schema, but found ${definition.length}.`);
    }

    return definition[0];
};

const getValues = (input) => (Array.isArray(input) ? input : Object.keys(input).map((key) => input[key]));

const normalizeArray = (schema, input, parent, key, addEntity, visitedEntities) => {
    schema = validateSchema(schema);

    const values = getValues(input);

    // Special case: Arrays pass *their* parent on to their children, since there
    // is not any special information that can be gathered from themselves directly
    return values.map((value, index) => visit(value, parent, key, schema, addEntity, visitedEntities));
};

const visit = (value, parent, key, schema, addEntity, visitedEntities) => {
    if (typeof value !== "object" || !value) {
        return value;
    }

    if (typeof schema === "object" && (!schema.normalize || typeof schema.normalize !== "function")) {
        const method = Array.isArray(schema) ? normalizeArray : normalizeObject;
        return method(schema, value, parent, key, addEntity, visitedEntities);
    }

    return schema.normalize(value, parent, key, addEntity, visitedEntities);
};

const addEntities = (entities, schemas, ids) => (schema, processedEntity, value, parent, key) => {
    const schemaKey = schema.name;
    const id = schema.getId(value, parent, key);
    if (!(schemaKey in entities)) {
        entities[schemaKey] = {};
        schemas[schemaKey] = schema;
    }

    const existingEntity = entities[schemaKey][id];
    if (existingEntity) {
        entities[schemaKey][id] = schema.merge(existingEntity, processedEntity);
    } else {
        entities[schemaKey][id] = processedEntity;
        ids.push({schemaKey, id});
    }
};

const normalize = (schema, input) => {
    if (!input || typeof input !== "object") {
        throw new Error(
            `Unexpected input given to normalize. Expected type to be "object", found "${
                input === null ? "null" : typeof input
            }".`
        );
    }

    let entities = {};
    let schemas = {};
    let ids = []; // store ids from leaf to root
    const addEntity = addEntities(entities, schemas, ids);
    const visitedEntities = {};

    const result = visit(input, input, null, schema, addEntity, visitedEntities);
    return {entities, schemas, ids, result};
};

exports.createSchema = createSchema;
exports.normalize = normalize;
