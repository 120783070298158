import {cs} from "../../../../../../../../react/chain-services";
import * as React from "react";
import {DropdownMultiSelectSearchLive} from "../../../../../../filters/common/dropdown-select/dropdown-multi-select-search-live";
import {css, cx} from "emotion";
import "./multi-select-dropdown.scss";
import {consumeContext} from "../../../../../../../../react/context";
import {UseState} from "../../../../../../../../react/use-state";
import {keyed} from "@common/react/keyed";
import {StyledClass} from "@common/react/styled-class";

export const MultiSelectDropdown = ({state, form, data: _rawData, filterStyle, closeAllOtherDropdownWhenOpen, placeholder}) =>
    cs(
        consumeContext("theme"),
        [
            "cssClass",
            ({theme}, next) => {
                const componentTheme = theme.general.components;

                return StyledClass({
                    content: {
                        background: `${componentTheme.menuBackgroundColorRGB}`,
                        color: `${componentTheme.menuTextColorRGB}`,
                    },
                    next,
                });
            },
        ],
        ({theme, cssClass}) => {
            let data = _rawData ?? [];
            const componentTheme = theme.general.components;

            const inputStyle = {
                border: `${componentTheme.inputBorderWidth}px solid ${componentTheme.inputBorderColorRGB}`,
                background: `${componentTheme.inputBackgroundColorRGB}`,
                color: `${componentTheme.inputTextColorRGB}`,
                borderRadius: `${componentTheme.inputCornerRadius}px`,
            };

            const primaryButton = {
                ...theme.general.buttons.primaryButton,
                fontFamily: theme.general.canvas.fontFamily,
            };

            const hyperLinkButton = {
                ...theme.general.buttons.hyperlinkButton,
                fontFamily: theme.general.canvas.fontFamily,
            };

            //AB5060
            const formatRule = (fList) => {
                if (fList.length <= data.length / 2)
                    return {
                        selectableListValues: fList,
                        selectableListValuesInverse: false,
                    };
                return {
                    selectableListValues: data.filter((d) => !fList.includes(d)),
                    selectableListValuesInverse: true,
                };
            };

            const reformatList = (filterVal) => {
                return filterVal.selectableListValuesInverse
                    ? data.filter((d) => !filterVal.selectableListValues.includes(d))
                    : filterVal.selectableListValues;
            };
            //TODO: refactor local state
            return cs(
                keyed(JSON.stringify({runType: filterStyle?.runType})),
                [
                    "localState",
                    (_, next) =>
                        UseState({
                            initValue: {
                                ...form.data.value,
                                selectableListValues: reformatList(form.data.value),
                            },
                            next,
                        }),
                ],
                [
                    "listState",
                    (_, next) =>
                        UseState({
                            initValue: reformatList(form.data.value),
                            next,
                        }),
                ],
                ["isSelected", ({localState, listState}, next) => next((d) => listState.value?.includes(d))],
                [
                    "resetBtnClass",
                    (_, next) =>
                        StyledClass({
                            content: getCss(hyperLinkButton),
                            next,
                        }),
                ],
                [
                    "applyBtnClass",
                    (_, next) =>
                        StyledClass({
                            content: getCss(primaryButton),
                            next,
                        }),
                ],
                ({localState, listState, isSelected, resetBtnClass, applyBtnClass}) => {
                    return DropdownMultiSelectSearchLive({
                        closeAllOtherDropdownWhenOpen,
                        className: "multi-select-dropdown-yg4 multi-select-dropdown",
                        loading: !_rawData,
                        list: data,
                        minExpandHeight: 350,
                        toggleLabel: (
                            <span
                                className={cx("hint")}
                                style={{
                                    "fontFamily": `${theme.general.canvas.fontFamily || "Roboto"}, sans-serif`,
                                    color: `${
                                        !listState.value?.length ? componentTheme.inputHintTextColorRGB : componentTheme.inputTextColorRGB
                                    }`,
                                }}
                            >
                                {!listState.value?.length
                                    ? placeholder || "Select..."
                                    : listState.value.length > 1
                                    ? `${listState.value?.length ?? 0} Selections`
                                    : listState.value[0] || "Blank / Empty"}
                            </span>
                        ),

                        isSelectedAll: (cList) =>
                            listState.value?.length === cList?.length ? true : listState.value?.length > 0 ? "partial" : false,
                        onSelectAll: (cList) => {
                            if (listState.value.length === cList.length) {
                                listState.onChange([]);
                            } else {
                                listState.onChange(cList);
                                localState.change((old) => ({
                                    ...old,
                                    ...formatRule(cList),
                                }));
                            }
                        },
                        onPassiveClose: () => {
                            if (filterStyle?.runType === "OnChange") {
                                // Bug #5699: Nested Filters Don't Update On Change
                                form.data.onChange(localState.value);
                                // listState.onChange(reformatList(form.data.value));
                                // localState.onChange({
                                //     ...form.data.value,
                                //     selectableListValues: reformatList(form.data.value),
                                // });
                            }
                        },
                        valueToLabel: (v) => (v === "" ? "Blank / Empty" : v),
                        isSelected,
                        onSelect: (d) => {
                            listState.change(
                                (list) => (isSelected(d) ? list.filter((e) => e !== d) : [...list, d]),
                                (newList) =>
                                    localState.change((old) => ({
                                        ...old,
                                        ...formatRule(newList.value),
                                    }))
                            );
                        },
                        checkboxBackground: theme.general.buttons.primaryButton.backgroundColorRGB,
                        toggleStyle: inputStyle,
                        iconColor: componentTheme.inputIconColorRGB,
                        resetBtnClassName: resetBtnClass,
                        applyBtnClassName: applyBtnClass,
                        dropdownCss: cssClass,
                        borderRadius: componentTheme.menuCornerRadius,
                        ...(filterStyle?.runType === "OnChange"
                            ? {
                                  onApply: () => {
                                      return form.data.onChange(localState.value);
                                  },
                              }
                            : // Bug #5699: Nested Filters Don't Update On Change
                              {
                                  onClose: () => {
                                      return form.data.onChange(localState.value);
                                  },
                              }),
                    });
                }
            );
        }
    );

const getCss = (selectedTheme) => ({
    background: `${selectedTheme.backgroundColorRGB} !important`,
    color: `${selectedTheme.fontColorRGB} !important`,
    border: `${selectedTheme.borderWidth}px solid ${selectedTheme.borderColorRGB} !important`,
    "border-radius": `${selectedTheme.cornerRadius}px !important`,
    "font-family": `"${selectedTheme.fontFamily}", sans-serif !important`,

    "&:hover": {
        background: `${selectedTheme.hoverBackgroundColorRGB} !important`,
        color: `${selectedTheme.hoverFontColorRGB} !important`,
        "border-color": `${selectedTheme.hoverBorderColorRGB} !important`,
    },
});
