import "./error-badge.scss";

import {createElement as h} from "react";

import {AlertIcon} from "@common/ui-components/icons/global-icons";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {AnyAction} from "@common/react/any-action";
import {consumeContext} from "@common/react/context";
import {groupErrors} from "./error-badge-helper";
import {noun} from "@common/utils/plural";
import {sum} from "@common/utils/collections";
import {renderCompInRegistry} from "@common/ui-components/registry/common-registry";
import {Static2} from "@common/react/static-2";

import {ArrowDownIcon} from "../../../../collection/preview/collection-preview-icons";

export const ModelErrorsBadgeHeader = ({model, dataSources}) =>
    cs(
        consumeContext("dataViewTransforms"),
        consumeContext("fixIssuesDialog"),
        ["open", (_, next) => UseState({next})],
        ["badgeRef", (_, next) => Static2({next})],
        ({open}, next) =>
            h(AnyAction, {
                disabled: !open.value,
                fn: () => {
                    setTimeout(() => {
                        open.onChange(false);
                    }, 10);
                },
                next,
            }),
        ({open, dataViewTransforms, fixIssuesDialog, badgeRef}) => {
            if (!dataViewTransforms.value) return null;

            const errors = groupErrors({model, dataViewTransforms: dataViewTransforms.value, dataSources});

            const badgePos = badgeRef.get()?.getBoundingClientRect() || null;

            return (
                errors.length > 0 && (
                    <div className="error-badge" ref={badgeRef.set} onClick={() => open.onChange(true)}>
                        <AlertIcon className="alert-badge" />{" "}
                        <span className="number-of-errors">{sum(errors, (e) => e.numberOfErrors)}</span>
                        <ArrowDownIcon />
                        {open.value &&
                            badgePos &&
                            renderCompInRegistry({
                                comp: (
                                    <div
                                        className="error-description-box-bbb"
                                        style={{
                                            top: badgePos.top + badgePos.height + 8,
                                            right: window.innerWidth - badgePos.right,
                                        }}
                                    >
                                        {errors.map((error, index) => (
                                            <div className="error-row" key={index}>
                                                <AlertIcon className="icon" />

                                                <div className="text-description">
                                                    <span
                                                        className="error-link"
                                                        onClick={() => {
                                                            fixIssuesDialog.show({
                                                                tableID: error.table.id,
                                                                columnID: error.column?.id,
                                                            });
                                                        }}
                                                    >
                                                        {error.numberOfErrors} {noun(error.numberOfErrors, "error")}
                                                    </span>{" "}
                                                    from {error.table.visualName}
                                                    {error.column && <span>.{error.column.name}</span>} — {error.reason}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ),
                            })}
                    </div>
                )
            );
        }
    );
