import React from "react";
import {cs} from "../../../../react/chain-services";
import "./map-legend.scss";
import {ColorAxis} from "./color-axis";
import {useColorAxis} from "../map-helper";
import {MapValueGroupLegend} from "./map-value-group-legend";

export const MapLegend = (props) =>
    cs(({}) => {
        if (!props.tile.style.legendStyle.legendShown || !props.legendRoom) {
            return null;
        }

        if (useColorAxis(props.tile)) {
            return ColorAxis(props);
        }

        return MapValueGroupLegend(props);
    });
