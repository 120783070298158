import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {SearchInputBar} from "@common/ui-components/search-input-bar/search-input-bar";
import {css, cx} from "emotion";
import {isMatchText} from "@common/utils/strings";
import {LoadingIndicator} from "@common/ui-components/loading-indicator/loading-indicator";
import {Checkbox} from "@common/ui-components/form/checkbox/checkbox";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";
import {FilterSubmitButtons} from "@common/ui-components/charts/table/table/header/filter-types/filter-submit-buttons";
import {sort} from "@common/utils/collections";
import {StyledClass} from "@common/react/styled-class";

export const HeaderFilterByText = ({search, tileColumn, componentTheme, theme, close, columnIndex, defaultSelected}) => {
    return cs(
        ["keyword", (_, next) => UseState({next, initValue: ""})],
        [
            "selected",
            (_, next) =>
                UseState({
                    next,
                    initValue: defaultSelected?.search || [],
                }),
        ],
        [
            "list",
            ({selected}, next) => {
                const isSelected = (item) => selected.value.find((s) => s === item);
                const sortList = (list) => {
                    let selectedList = sort(
                        list.filter((item) => isSelected(item)),
                        (v) => v
                    );
                    const unSelectedList = sort(
                        list.filter((item) => !isSelected(item)),
                        (v) => v
                    );
                    return selectedList.concat(unSelectedList);
                };

                return UseState({next, initValue: sortList(tileColumn.textFilterValues)});
            },
        ],
        [
            "inputCss",
            (_, next) =>
                StyledClass({
                    next,
                    content: {
                        background: `${componentTheme.inputBackgroundColorRGB}`,
                        color: `${componentTheme.inputTextColorRGB}`,
                        border: `${componentTheme.inputBorderWidth}px solid ${componentTheme.inputBorderColorRGB}`,
                        "border-radius": `${componentTheme.inputCornerRadius}px`,
                        "font-family": `${theme.general.canvas.fontFamily}, sans-serif !important`,
                        "::-webkit-input-placeholder": {
                            color: `${componentTheme.inputHintTextColorRGB}`,
                        },
                        ":-ms-input-placeholder": {
                            color: `${componentTheme.inputHintTextColorRGB}`,
                        },
                        "::placeholder": {
                            color: `${componentTheme.inputHintTextColorRGB}`,
                        },
                    },
                }),
        ],
        [
            "dropdownCss",
            (_, next) =>
                StyledClass({
                    next,
                    content: {
                        background: `${componentTheme.menuBackgroundColorRGB}`,
                        color: `${componentTheme.menuTextColorRGB}`,
                    },
                }),
        ],
        ({keyword, selected, list, inputCss, dropdownCss}) => {
            const checkboxBackground = theme.general.buttons.primaryButton.backgroundColorRGB;

            const searched = list.value.filter((l) => isMatchText(l, keyword.value));
            const isSelected = (item) => selected.value.find((s) => s === item);
            const isSelectAll = !searched.find((item) => !isSelected(item));

            return (
                <>
                    <SearchInputBar
                        iconColor={componentTheme.inputIconColorRGB}
                        inputClassName={inputCss}
                        className="search-input"
                        state={keyword}
                        placeholder="Search"
                        autoFocus
                        delay={0}
                    />

                    <VerbScrollbar maxHeight={300} className="header-checkboxes">
                        {!searched?.length ? (
                            <div
                                className="no-results"
                                style={{
                                    background: componentTheme.menuBackgroundColorRGB,
                                    color: componentTheme.menuTextColorRGB,
                                }}
                            >
                                No results found.
                            </div>
                        ) : (
                            <>
                                <div
                                    className={cx("item", dropdownCss)}
                                    onClick={() => {
                                        selected.onChange(isSelectAll ? [] : searched);
                                    }}
                                >
                                    {Checkbox({
                                        readOnly: true,
                                        state: {
                                            value: isSelectAll,
                                            onChange: () => {},
                                        },
                                        background: checkboxBackground,
                                    })}
                                    Select all {keyword.value?.length > 0 ? "in view" : ""}
                                </div>

                                {searched.map((l, i) => (
                                    <div
                                        key={i}
                                        className={cx(
                                            "item",
                                            {
                                                selected: isSelected(l),
                                            },
                                            dropdownCss
                                        )}
                                        onClick={(e) => {
                                            if (isSelected(l)) {
                                                selected.onChange(selected.value.filter((v) => v != l));
                                            } else {
                                                selected.onChange(selected.value.concat(l));
                                            }
                                        }}
                                    >
                                        {Checkbox({
                                            readOnly: true,
                                            state: {
                                                value: isSelected(l),
                                                onChange: () => {},
                                            },
                                            background: checkboxBackground,
                                        })}
                                        {l}
                                    </div>
                                ))}
                            </>
                        )}
                    </VerbScrollbar>

                    <FilterSubmitButtons
                        onReset={() => {
                            close?.();
                            search.searchColumn({
                                cIndex: columnIndex,
                                search: null,
                                $type: "TextListTableSearch",
                            });
                        }}
                        disabled={selected.value == 0}
                        onSubmit={() => {
                            close?.();
                            search.searchColumn({
                                cIndex: columnIndex,
                                search: selected.value,
                                $type: "TextListTableSearch",
                            });
                        }}
                    />
                </>
            );
        }
    );
};
