import { SubcribeKeyEvents } from "@common/react/keys/global-key-down";
import { CollectionErrorBadgeWithTooltip } from "@common/ui-components/live/live-dashboard/common/collection-error-badge/collection-error-badge-with-tooltip";
import { cx } from "emotion";
import React from "react";
import { StaticTooltipService } from "../../../web-client/src/routes/common/tooltip3/static-tooltip-service";
import { cs } from "../../react/chain-services";
import { Static2 } from "../../react/static-2";
import { UseState } from "../../react/use-state";
import { Dropdown } from "../dropdown/dropdown";
import { VerbScrollbar } from "../verb-scrollbar/verb-scrollbar";
import "./dropdown-select.scss";

// vs DropdownSelect2:
// same API, can basically be used interchangeably
// differences: toggle icon and border bottom is unchanged when opened, support info icon tooltip, disabled options
// TODO make this and DropdownSelect2 a unified component in the next component style update

const FocusControl = ({next: rootNext, initIndex, list}) => {
    return cs(["focusIndex", (_, next) => UseState({next, initValue: initIndex ?? -1})], ({focusIndex}) =>
        rootNext({
            notFocus: () => focusIndex === -1,
            isFocusOnEndOfList: () => focusIndex === list.length - 1,
            isFocusOnStartOfList: () => focusIndex === 0,
            focusOnStart: () => focusIndex.onChange(0),
            focusOnEnd: () => focusIndex.onChange(list.length - 1),
            focusOn: (fn) => focusIndex.change((i) => fn(i)),
            getFocusItem: () => list?.[focusIndex.value] ?? null,
            isIndexFocus: (i) => i === focusIndex.value,
        })
    );
};

export const DropdownSelect = ({
    list,
    onChange,
    isSelected,
    valueToLabel = (v) => v,
    valueToLabelList,
    label,
    labelInfo,
    placeholder,
    disabled,
    minWidth = false,
    className,
    icon = null,
    tabIndex,
    info,
    detectOnWheelEvent = true,
    hasError,
    domRef,
    errorMessage,
    directionTooltip = "right",
    tooltipTopOffset,
    registryRender = true,
    expandClassName,
    expandWidth,
    hasDeletedFieldError,
    closeAllOtherDropdownWhenOpen = true,
}) =>
    cs(
        ["chosenIndex", (_, next) => next(list?.findIndex(isSelected))],
        [
            "staticTooltip",
            (_, next) =>
                labelInfo || info
                    ? StaticTooltipService({
                          direction: directionTooltip,
                          info: labelInfo ?? info ?? "",
                          tooltipBoxWidth: 200,
                          next,
                      })
                    : next(),
        ],
        [
            "renderToggle",
            ({chosenIndex, staticTooltip}, next) =>
                next(({showExpand, showingExpand, focus}) => {
                    return (
                        <div
                            className={cx("toggle", {
                                expanding: showingExpand,
                                focus,
                            })}
                            onClick={() => {
                                showExpand(!showingExpand);
                            }}
                        >
                            {info &&
                                staticTooltip.renderIcon({
                                    icon: <i className="material-icons">info</i>,
                                    className: "info-tooltip",
                                })}

                            {chosenIndex > -1 ? (
                                <span className="item-render">
                                    {icon && <span className="icon">{icon}</span>}
                                    <div className="text">{valueToLabel(list[chosenIndex])}</div>
                                </span>
                            ) : placeholder ? (
                                <span className="placeholder-text">{placeholder}</span>
                            ) : null}
                            <i className="fa fa-chevron-down" />
                        </div>
                    );
                }),
        ],
        [
            "renderExpand",
            ({chosenIndex}, next) =>
                next(({close, width}) =>
                    cs(
                        [
                            "focusControl",
                            (_, next) =>
                                FocusControl({
                                    next,
                                    initIndex: chosenIndex,
                                    list,
                                }),
                        ],
                        ({focusControl}) => {
                            const selectItem = (item, i) => {
                                if (!item?.disabled) {
                                    onChange(item, i);
                                    close();
                                }
                            };

                            return (
                                <>
                                    {SubcribeKeyEvents({
                                        events: [
                                            {
                                                keyCombo: "ArrowUp",
                                                onKeyDown: () => {
                                                    if (focusControl.notFocus() || focusControl.isFocusOnStartOfList()) {
                                                        return focusControl.focusOnEnd();
                                                    }
                                                    return focusControl.focusOn((i) => i - 1);
                                                },
                                            },
                                            {
                                                keyCombo: "ArrowDown",
                                                onKeyDown: () => {
                                                    if (focusControl.notFocus() || focusControl.isFocusOnEndOfList()) {
                                                        return focusControl.focusOnStart();
                                                    }
                                                    return focusControl.focusOn((i) => i + 1);
                                                },
                                            },
                                            {
                                                keyCombo: "Enter",
                                                onKeyDown: (e) => {
                                                    e?.stopPropagation();
                                                    const item = focusControl.getFocusItem();
                                                    item && selectItem(item);
                                                },
                                            },
                                        ],
                                    })}
                                    <VerbScrollbar
                                        maxHeight="300px"
                                        className={cx("dropdown-select-list-5gy", expandClassName)}
                                        style={{
                                            width: !minWidth ? expandWidth ?? width : null,
                                            minWidth: minWidth && (expandWidth ?? width),
                                        }}
                                        onInit={(dom) =>
                                            dom.querySelector(".item.selected")?.scrollIntoView({
                                                behavior: "auto",
                                                block: "center",
                                                inline: "center",
                                            })
                                        }
                                    >
                                        <div className="wrapper">
                                            {list?.map((l, i) => (
                                                <div
                                                    key={i}
                                                    className={cx("item", {
                                                        selected: chosenIndex === i,
                                                        disabled: l?.disabled,
                                                        focus: focusControl.isIndexFocus(i),
                                                    })}
                                                    onClick={() => {
                                                        selectItem(l, i);
                                                    }}
                                                >
                                                    {(valueToLabelList ?? valueToLabel)(l)} {l?.disabled && l?.disabledText}
                                                </div>
                                            ))}
                                        </div>
                                    </VerbScrollbar>
                                </>
                            );
                        }
                    )
                ),
        ],
        ["dropdownRef", (_, next) => Static2({next})],
        ({renderToggle, renderExpand, dropdownRef, staticTooltip, chosenIndex}) => (
            <div
                className={cx(
                    "dropdown-select dropdown-select-5gy",
                    className,
                    {hasError},
                    {"has-deleted-field-error": hasDeletedFieldError},
                    {"has-info": info},
                    {"has-value": placeholder || chosenIndex > -1}
                )}
                ref={domRef}
            >
                {label && (
                    <div className={cx("label", {"has-info": labelInfo})}>
                        {label}
                        {labelInfo &&
                            staticTooltip.renderIcon({
                                icon: <i className="material-icons">info</i>,
                                className: "info-tooltip",
                            })}
                    </div>
                )}

                {Dropdown({
                    dropdownRef,
                    renderToggle,
                    minExpandHeight: 300,
                    renderExpand,
                    disabled,
                    detectOnWheelEvent,
                    closeAllOtherDropdownWhenOpen,
                    registryRender,
                    customZIndex: 100,
                })}

                {hasDeletedFieldError && (
                    <CollectionErrorBadgeWithTooltip error={hasDeletedFieldError} className="error-badge-with-tooltip" />
                )}

                {hasError && errorMessage && <div className="error-message">{errorMessage}</div>}
            </div>
        )
    );
