const {isEmpty} = require("../../../../../../../../../../../../../../common/utils/collections");
const objectUtils = require("../../../../../../../../../../../../../../common/utils/objects");
const {isSameField} = require("@common/ui-components/charts/factory/is-same-field");
const {keepOnly} = require("../../../../../../../../../../../../../../common/utils/objects");

const getDimensionlessSortingUpdated = ({tileFields, tile, fieldVal: {newVal, oriVal}}) => {
    return newVal == null
        ? (() => {
              const _sorts = tileFields.fieldsForSort.filter((s) => !isSameField(s, oriVal));
              return {
                  sort: !isEmpty(_sorts)
                      ? [
                            {
                                ...keepOnly(_sorts[0], ["modelID", "modelTableID", "modelColumnID"]),
                                direction: "Asc",
                            },
                        ]
                      : [],
              };
          })()
        : (() => {
              const direction = ["PieChartTile", "DonutChartTile"].includes(tile.value?.$type) ? "Desc" : "Asc";

              const newSortField = {
                  ...keepOnly(newVal, ["modelID", "modelTableID", "modelColumnID", "aggregationFunc"]),
                  direction,
              };

              if (newVal.$type === "DimensionlessMeasureTileField") {
                  if (isEmpty(tile.value.sort)) {
                      return {sort: [newSortField, ...tile.value.sort]};
                  } else {
                      return {sort: tile.value.sort};
                  }
              } else {
                  return {sort: [...tile.value.sort, newSortField]};
              }
          })();
};

exports.getDimensionlessSortingUpdated = getDimensionlessSortingUpdated;

const getDimensionlessSortingUpdatedByLimit = ({tile, field, groupFieldAttr}) => ({
    sort: [
        keepOnly(field, ["modelID", "modelTableID", "modelColumnID", "direction"]),
        ...(field.$type === "DimensionlessMeasureTileField" && !objectUtils.isEmpty(tile.value[groupFieldAttr])
            ? [
                  {
                      ...keepOnly(tile.value[groupFieldAttr], ["modelID", "modelTableID", "modelColumnID"]),
                      direction: field.direction,
                  },
              ]
            : []),
    ],
});

exports.getDimensionlessSortingUpdatedByLimit = getDimensionlessSortingUpdatedByLimit;
