import React from "react";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {DropdownSelect2} from "@common/ui-components/dropdown-select/dropdown-select2";
import {scope} from "@common/react/scope";
import "./calculator-configuration.scss";
import {mathIcons} from "./math-icons";
import {cx} from "emotion";
import {keyed} from "@common/react/keyed";
import {isNotEmpty, last} from "@common/utils/collections";
import {spc} from "@common/react/state-path-change";
import {TextInput} from "../../../../../../../../../common/form/text-input/text-input";
import {getNumberAggregationFuncs} from "../../../../../../common/aggregation-funcs/number-aggregation-funcs";
import {roundToNearest} from "./round-to-nearest";
import {ffToDropdown} from "../../../../../../../../../common/form/ff-to-dropdown";
import {keepOnly} from "@common/utils/objects";
import {ffToTextInput} from "../../../../../../../../../common/form/ff-to-text-input";
import {DebounceTextInput} from "../../../../../../../../../common/form/debounce-input/debounce-text-input";
import {rColumnValueDrillingSelectFromStepOutput} from "../../../../common/column-value-drilling-select-from-step-output/column-value-drilling-select-from-step-output";
import {rNewColumnNameInput} from "../../common/new-column-name-input";
import {cLsJson} from "@common/react/ls-json";

const formula2Vars = ["Addition", "Subtraction", "Multiplication", "Division", "Power", "Min", "Max"];
const formula1Var = ["Ceiling", "Floor", "Round", "AbsoluteValue"];

export const CalculatorConfiguration = ({transformation, step, form, model, setting, togglePreview}) =>
    cs(
        [
            "action",
            (_, next) =>
                UseState({
                    initValue: step.value?.calculations.map((c, index) => {
                        if (step.value?.calculations.length - 1 == index) {
                            return step.value?.newColumn?.name ? "Save To Column" : null;
                        }
                        return "Use As Input For Another Column";
                    }),
                    next,
                }),
        ],
        ({action}) => {
            const prevStep = transformation.value.steps.find((s) => s.id === step.value.inputStepID);
            const calculations = isNotEmpty(step.value?.calculations) ? step.value?.calculations : [{$type: null}];

            return (
                <div className="calculator-configuration-9re">
                    {calculations.map((path, index) =>
                        cs(keyed(index), () => {
                            const columnValueSelect = rColumnValueDrillingSelectFromStepOutput({
                                columns: prevStep.outputColumns,
                                model,
                                useStatic: true,
                                disableSelectingTable: true,
                                filterColumnFn: (c) => ["Double", "Int"].includes(c.dataType) || c.childs?.length > 0,
                                isValueNotAllowed: (c) => !["Double", "Int"].includes(c.dataType),
                                usePreviousResult: index > 0,
                            });

                            return (
                                <>
                                    <div className="config-group">
                                        <div className="label">Operation</div>

                                        {DropdownSelect2({
                                            label: "Type",
                                            list: [...formula2Vars, ...formula1Var],
                                            isSelected: (v) => v === path.$type,
                                            valueToLabel: (v) => (v === "AbsoluteValue" ? "Absolute Value" : v),
                                            onChange: (value) =>
                                                spc(step, ["calculations!arr", index], () => ({
                                                    $type: value,
                                                })),
                                        })}
                                    </div>

                                    {path.$type && (
                                        <>
                                            <div className="config-group">
                                                <div className="label">Build Formula</div>

                                                <div
                                                    className={cx("formula", {
                                                        "has-math": formula2Vars.indexOf(path.$type) > -1,
                                                    })}
                                                >
                                                    <div className="item">
                                                        {(() => {
                                                            const dropdownProps = ffToDropdown(form.field(["calculations", index, "left"]));
                                                            return columnValueSelect({
                                                                state: {
                                                                    value: dropdownProps.value,
                                                                    onChange: dropdownProps.onChange,
                                                                },
                                                                ...keepOnly(dropdownProps, ["hasError", "errorMessage", "domRef"]),
                                                                renderStaticInput: ({state}) =>
                                                                    TextInput({
                                                                        state,
                                                                        label: "Value",
                                                                    }),
                                                            });
                                                        })()}

                                                        {path.left &&
                                                            (formula2Vars.indexOf(path.$type) > -1 && path.left.$type == "AggregateValue" && path.left.modelColumnID
                                                                ? (() => {
                                                                      const dropdownProps = ffToDropdown(form.field(["calculations", index, "left", "aggregationFunc"]));
                                                                      return DropdownSelect2({
                                                                          label: "Aggregation",
                                                                          list: getNumberAggregationFuncs({
                                                                              notAllowNone: true,
                                                                          }),
                                                                          valueToLabel: (v) => v.label,
                                                                          isSelected: (v) => v.value == path.left?.aggregationFunc,
                                                                          onChange: (value) =>
                                                                              spc(step, ["calculations", index], (v) => ({
                                                                                  ...v,
                                                                                  left: {
                                                                                      ...(v?.left ?? {}),
                                                                                      $type: "AggregateValue",
                                                                                      aggregationFunc: value.value,
                                                                                  },
                                                                              })),
                                                                          ...keepOnly(dropdownProps, ["hasError", "errorMessage"]),
                                                                      });
                                                                  })()
                                                                : path.$type === "Round" &&
                                                                  cs(
                                                                      [
                                                                          "state",
                                                                          (_, next) =>
                                                                              UseState({
                                                                                  initValue: step.value?.calculations[index].roundToNearest || "Whole Number",
                                                                                  next,
                                                                              }),
                                                                      ],
                                                                      ({state}) =>
                                                                          DropdownSelect2({
                                                                              label: "Round To Nearest",
                                                                              list: roundToNearest,
                                                                              valueToLabel: (v) => v.label,
                                                                              isSelected: (v) => v.label === state.value,
                                                                              onChange: (value) => {
                                                                                  state.onChange(value.label);
                                                                                  return spc(step, ["calculations", index], (v) => ({
                                                                                      ...v,
                                                                                      left: {
                                                                                          ...(v?.left ?? {}),
                                                                                      },
                                                                                      roundToNearest: value.value,
                                                                                  }));
                                                                              },
                                                                          })
                                                                  ))}
                                                    </div>

                                                    {formula2Vars.indexOf(path.$type) > -1 && (
                                                        <>
                                                            <div className="item">
                                                                {(() => {
                                                                    const dropdownProps = ffToDropdown(form.field(["calculations", index, "right"]));
                                                                    return columnValueSelect({
                                                                        state: {
                                                                            value: dropdownProps.value,
                                                                            onChange: dropdownProps.onChange,
                                                                        },
                                                                        ...keepOnly(dropdownProps, ["hasError", "errorMessage", "domRef"]),
                                                                        renderStaticInput: ({state}) =>
                                                                            TextInput({
                                                                                state,
                                                                                label: "Value",
                                                                            }),
                                                                    });
                                                                })()}

                                                                {path.right &&
                                                                    (formula2Vars.indexOf(path.$type) > -1 && path.right.$type == "AggregateValue" && path.right.modelColumnID
                                                                        ? (() => {
                                                                              const dropdownProps = ffToDropdown(form.field(["calculations", index, "right", "aggregationFunc"]));
                                                                              return DropdownSelect2({
                                                                                  label: "Aggregation",
                                                                                  list: getNumberAggregationFuncs({
                                                                                      notAllowNone: true,
                                                                                  }),
                                                                                  valueToLabel: (v) => v.label,
                                                                                  isSelected: (v) => v.value == path.right?.aggregationFunc,
                                                                                  onChange: (value) =>
                                                                                      spc(step, ["calculations", index], (v) => ({
                                                                                          ...v,
                                                                                          right: {
                                                                                              ...(v?.right ?? {}),
                                                                                              $type: "AggregateValue",
                                                                                              aggregationFunc: value.value,
                                                                                          },
                                                                                      })),
                                                                                  ...keepOnly(dropdownProps, ["hasError", "errorMessage"]),
                                                                              });
                                                                          })()
                                                                        : path.$type === "Round" &&
                                                                          DropdownSelect2({
                                                                              label: "Round To Nearest",
                                                                              list: roundToNearest,
                                                                              isSelected: (v) => v == path.right?.roundToNearest,
                                                                              onChange: (value) =>
                                                                                  spc(step, ["calculations", index], (v) => ({
                                                                                      ...v,
                                                                                      right: {
                                                                                          ...(v?.right ?? {}),
                                                                                          roundToNearest: value,
                                                                                      },
                                                                                  })),
                                                                          }))}
                                                            </div>

                                                            <div className="math-icon">
                                                                <i className={cx("fa-solid", mathIcons[path.$type])} />
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="config-group">
                                                <div className="label">Result</div>
                                                {(() => {
                                                    const errIndicatingField = form.field(["newColumn"]);
                                                    return DropdownSelect2({
                                                        label: "Action",
                                                        list: ["Save To Column", "Use As Input For Another Column"],
                                                        isSelected: (v) => v == action.value[index],
                                                        // onChange: (value) => {
                                                        //     spc(action, [index], () => value);
                                                        //
                                                        //     if(last(action.value) !== value) {
                                                        //         if(value !== "Save To Column") {
                                                        //             spc(step, ["calculations"], (cal) => [...cal, {$type: null}])
                                                        //         } else {
                                                        //             spc(step, ["newColumn"], () => ({$type: "ViewStepColumn", name: ""}));
                                                        //             if(calculations.length > index + 1) {
                                                        //                 spc(step, ["calculations"], (cal) => cal.filter((c, i) => index >= i))
                                                        //             }
                                                        //         }
                                                        //     }
                                                        // },
                                                        onChange: (value) => {
                                                            if (value === action.value[index]) {
                                                                return;
                                                            }

                                                            if (value !== "Save To Column") {
                                                                spc(action, [index], () => value);
                                                                spc(
                                                                    step,
                                                                    ["calculations"],
                                                                    (cal) => [
                                                                        ...cal,
                                                                        {
                                                                            $type: null,
                                                                        },
                                                                    ],
                                                                    true
                                                                );
                                                            } else {
                                                                if (calculations.length > index) {
                                                                    action.onChange([...action.value.slice(0, index), value]);
                                                                }
                                                                step.onChange({
                                                                    ...step.value,
                                                                    ...(!step.value.newColumn?.name && {
                                                                        newColumn: {
                                                                            $type: "ViewStepColumn",
                                                                            name: "",
                                                                        },
                                                                    }),
                                                                    ...(calculations.length > index + 1 && {
                                                                        calculations: step.value.calculations.filter((c, i) => index >= i),
                                                                    }),
                                                                });
                                                            }
                                                        },
                                                        hasError: errIndicatingField.error,
                                                        errorMessage: errIndicatingField.getErrorMessage?.(),
                                                    });
                                                })()}
                                            </div>
                                        </>
                                    )}
                                </>
                            );
                        })
                    )}

                    {last(action.value) == "Save To Column" && (
                        <div className="config-group">
                            <div className="label">Column Name</div>
                            {rNewColumnNameInput({
                                form,
                                newColumnPath: ["newColumn"],
                                newColumnNamePath: ["newColumn", "name"],
                            })}
                        </div>
                    )}
                </div>
            );
        }
    );
