const {getDomPos} = require("./get-dom-pos");

const {autoMove} = require("../../../../../../../common/utils/auto-move");
const {consumeContext} = require("@common/react/context");
const {GlobalKeyDown} = require("@common/react/keys/global-key-down");
const {equalDeep} = require("../../../../../../../common/utils/objects");
const {spc} = require("@common/react/state-path-change");
const {GlobalMouseMove} = require("@common/react/global-mouse-move");
const {getELoc} = require("@common/react/get-e-loc");
const {GlobalMouseUp} = require("@common/react/global-mouse-up");
const {fragments} = require("@common/react/fragments");
const {UseState} = require("@common/react/use-state");
const {cs} = require("@common/react/chain-services");
const autoScroll = autoMove.init();
const {gridHeight, numColumnsOfGrid} = require("../grid-constants");
const {omit} = require("@common/utils/objects");

const Resizing = ({blockSize, restrictRect, onDone, getRootPos, rejectRect, next, spacing, filterDropArea}) =>
    cs(consumeContext("containerBoxRef"), ["state", (_, next) => UseState({next})], ({state, containerBoxRef}) => {
        const rect = (() => {
            if (!state.value) {
                return null;
            }
            const rect = getTileSize({
                ...state.value,
                spacing,
                getRootPos,
                containerRef: containerBoxRef.get(),
                tilePos: {
                    ...state.value.tile.position,
                    width: state.value.tile.size.width,
                },
                blockSize,
                filterDropArea,
            });
            const restricted = restrictRect(rect);

            return {
                error: !equalDeep(restricted, rect),
                restricted,
            };
        })();

        return fragments(
            state.value &&
                fragments(
                    GlobalMouseMove({
                        fn: (e) => {
                            const {tile, pos0, pos1} = state.value;
                            const container = containerBoxRef.get();
                            const {bottom, top, left, right} = container.getBoundingClientRect();
                            const delta = {
                                x: pos1.x - pos0.x,
                                y: pos1.y - pos0.y,
                            };
                            const {scrollTop, scrollLeft, scrollHeight, scrollWidth, offsetHeight, offsetWidth} = container.getDom();

                            spc(state, ["pos2"], (prev) => {
                                const {x, y} = getELoc(e);
                                if (rect.error) {
                                    if (prev.x >= x || prev.x <= left + 32) {
                                        return {x, y};
                                    } else {
                                        return prev;
                                    }
                                }
                                return {x, y};
                            });

                            const movePosition = ({x, y}) => {
                                let ret = {};
                                const _x = x - delta.x;
                                const _y = y - delta.y;

                                if (_x > right - tile.size.width * blockSize) {
                                    ret.right = true;
                                }

                                if (_x < left + 20) {
                                    ret.left = true;
                                }

                                if (_y < top + 20) {
                                    ret.top = true;
                                }

                                if (_y > bottom - tile.size.height * (gridHeight + spacing * 2)) {
                                    ret.bottom = true;
                                }

                                return ret;
                            };

                            const isStopAutoScroll = ({x, y}) => {
                                const movePos = movePosition({x, y});
                                return (
                                    !movePos ||
                                    (movePos.bottom && scrollTop === scrollHeight - offsetHeight) ||
                                    (movePos.top && scrollTop <= 0) ||
                                    (movePos.right && scrollLeft === scrollWidth - offsetWidth) ||
                                    (movePos.left && scrollLeft <= 0)
                                );
                            };

                            autoScroll.move(getELoc(e), isStopAutoScroll, () => {
                                const {x, y} = getELoc(e);

                                if (movePosition({x, y}).left) {
                                    container.autoScrollingLeft(-3);
                                }

                                if (movePosition({x, y}).right) {
                                    container.autoScrollingLeft(3);
                                }

                                if (movePosition({x, y}).top) {
                                    container.autoScrollingTop(-3);
                                }

                                if (movePosition({x, y}).bottom) {
                                    container.autoScrollingTop(3);
                                }
                            });
                        },
                    }),
                    GlobalMouseUp({
                        fn: () => {
                            autoScroll.forceStop();
                            spc(state, ["lastScrollTop"], () => 0);
                            const {tile} = state.value;

                            if (rejectRect?.({tile, rect: rect.restricted})) {
                                const newRect = getSpaceFitAvailable({
                                    isReject: (position) =>
                                        rejectRect?.({
                                            tile,
                                            rect: position,
                                        }),
                                    position: {
                                        ...tile.size,
                                        ...tile.position,
                                    },
                                    direction: state.value.position,
                                });
                                if (newRect) {
                                    onDone({tile, rect: newRect});
                                    state.onChange(null);
                                    return;
                                }
                            }

                            if (
                                !rejectRect?.({
                                    tile,
                                    rect: rect.restricted,
                                }) &&
                                (rect.restricted.width !== tile.size.width || rect.restricted.height !== tile.size.height)
                            ) {
                                onDone({tile, rect: rect.restricted});
                            }
                            state.onChange(null);
                        },
                    }),
                    GlobalKeyDown({
                        keyCombo: "Escape",
                        onKeyDown: () => {
                            autoScroll.forceStop();
                            state.onChange(null);
                        },
                    })
                ),
            next({
                active: state.value,
                rect,

                activate: ({e, tileIndex, tile, dom, position}) => {
                    e.preventDefault();

                    return state.onChange({
                        tileIndex,
                        tile,
                        rect0: dom.getBoundingClientRect(),
                        rootPos0: getRootPos(),
                        pos0: getDomPos(dom),
                        pos1: getELoc(e),
                        pos2: getELoc(e),
                        position,
                    });
                },

                forTile: (tileIndex) => {
                    if (!state.value || state.value.tileIndex !== tileIndex) {
                        return null;
                    }
                    const {tile} = state.value;
                    return {
                        rejecting: rejectRect?.({
                            tile,
                            rect: rect.restricted,
                        }),
                        size: (() => {
                            return getForTileSize({
                                ...state.value,
                                getRootPos,
                                spacing,
                                containerRef: containerBoxRef.get(),
                                tilePos: tile.position,
                                blockSize,
                                filterDropArea,
                            });
                        })(),
                    };
                },
            })
        );
    });
exports.Resizing = Resizing;

const getForTileSize = ({rect0, pos1, pos2, rootPos0, position, spacing, tilePos, blockSize, containerRef, filterDropArea}) => {
    let width = rect0.width;
    let height = rect0.height;
    let x = tilePos.x * blockSize;
    let y = tilePos.y * (gridHeight + spacing * 2);
    const {scrollTop, scrollLeft} = containerRef.getDom();
    const filterDropAreaRect = filterDropArea.get() ? filterDropArea.get().getBoundingClientRect() : null;

    const mousePosY =
        pos2.y + scrollTop - containerRef.getBoundingClientRect().top - (filterDropAreaRect ? filterDropAreaRect.height + 45 : 25);
    const mousePosX = pos2.x + scrollLeft - containerRef.getBoundingClientRect().left - 35;

    if (position.top) {
        height = rect0.height + (y - mousePosY);
        y = Math.min(mousePosY, tilePos.y * (gridHeight + spacing * 2) + rect0.height - 10);
    }

    if (position.left) {
        width = rect0.width + (x - mousePosX);
        x = Math.min(mousePosX, tilePos.x * blockSize + rect0.width - 10);
    }

    if (position.right) {
        width = mousePosX - (x + rect0.width - 13) + rect0.width;
    }

    if (position.bottom) {
        height = mousePosY - (y + rect0.height) + rect0.height;
    }

    return {width, height, x, y};
};
const getTileSize = ({rect0, pos1, pos2, rootPos0, position, spacing, getRootPos, tilePos, blockSize, containerRef, filterDropArea}) => {
    let width = rect0.width / blockSize;
    let height = rect0.height / (gridHeight + spacing * 2);
    let x = tilePos.x;
    let y = tilePos.y;

    const {scrollTop, scrollLeft} = containerRef.getDom();

    const filterDropAreaRect = filterDropArea.get() ? filterDropArea.get().getBoundingClientRect() : null;

    const mousePosY =
        pos2.y + scrollTop - containerRef.getBoundingClientRect().top - (filterDropAreaRect ? filterDropAreaRect.height + 45 : 25);
    const mousePosX = pos2.x + scrollLeft - containerRef.getBoundingClientRect().left - 35;

    if (position.top) {
        height = Math.ceil(height + (y * (gridHeight + spacing * 2) - mousePosY) / (gridHeight + spacing * 2));
        y = Math.ceil(mousePosY / (gridHeight + spacing * 2)) - 1;
    }

    if (position.left) {
        width = Math.min(Math.round(width + (x * blockSize - mousePosX) / blockSize), x + tilePos.width);
        x = Math.round(mousePosX / blockSize);
    }

    if (position.right) {
        width = (mousePosX - (x * blockSize + rect0.width - 13) + rect0.width) / blockSize;
    }

    if (position.bottom) {
        height = (mousePosY - (y * (gridHeight + spacing * 2) + rect0.height - 13) + rect0.height) / (gridHeight + spacing * 2);
    }

    return {width: Math.round(width), height: Math.round(height), x, y};
};

const getSpaceFitAvailable = ({isReject, position, direction}) => {
    let ret = {...position};

    if (direction.right) {
        for (let i = position.width + 1; i <= numColumnsOfGrid - position.x; i++) {
            if (isReject({...position, width: i})) {
                ret.width = i - 1;
                break;
            }
        }
    }

    if (direction.bottom) {
        for (let i = position.height + 1; i <= gridHeight - position.y; i++) {
            if (isReject({...position, height: i})) {
                ret.height = i - 1;
                break;
            }
        }
    }

    if (direction.left) {
        for (let i = position.x - 1; i >= 0; i--) {
            if (
                isReject({
                    ...position,
                    x: i,
                    width: position.width + (position.x - i),
                })
            ) {
                ret.x = i + 1;
                ret.width = position.width + (position.x - ret.x);
                break;
            }
        }
    }

    if (direction.top) {
        for (let i = position.y - 1; i >= 0; i--) {
            if (
                isReject({
                    ...position,
                    y: i,
                    height: position.height + (position.y - i),
                })
            ) {
                ret.y = i + 1;
                ret.height = position.height + (position.y - ret.y);
                break;
            }
        }
    }

    return ret;
};
