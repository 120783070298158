const {mapPlotOptions} = require("./plot-options");
const {mapLegendOptions} = require("./map-legend-options");
const {mapColorAxis} = require("./map-color-axis");
const {getTooltipOptions} = require("../../factory/tooltip/tooltip-options");

const getMapOptions = ({tile, theme, formatters, chartData, series, matchIndex, map, onClickPoint}) => {
    return {
        chart: {
            map,
            animation: false,
            backgroundColor: theme.general.tile.styles.tileBackgroundColorRGB,
        },

        title: {
            text: null,
        },

        mapNavigation: {
            enabled: false, // turn off the default map nav of HC, use the custom one
            enableMouseWheelZoom: tile.style.showMapControls,
        },

        plotOptions: mapPlotOptions({tile, theme, onClickPoint}),

        ...mapColorAxis({tile, theme, series, matchIndex}),

        ...mapLegendOptions({tile, theme}),

        tooltip: getTooltipOptions({
            tile,
            theme,
            formatters,
            isCompare: tile.style.showCompare && chartData.range && chartData.previousRange,
            rawData: chartData,
        }),

        credits: {
            enabled: false,
        },
        exporting: {
            enabled: false,
        },
    };
};
exports.getMapOptions = getMapOptions;
