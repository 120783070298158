import { generateDefaultTimeRange } from "@common/logic/date-time/date-util";
import { addDate, parseDate, today, tomorrow } from "../../../../../utils/dates/date-object";
import { getPreviousDays } from "../../live-filters/filter-types/date/expand/common/time/get-previous-days";

export const lastXDays = (count, negative) => {
    const getMainRange = () =>
        !negative
            ? {
                  from: addDate(today(), -count + 1),
                  to: {
                      ...today(),
                      hours: 23,
                      minutes: 59,
                      seconds: 59,
                  },
              }
            : {
                  from: parseDate("1900-01-01"),
                  to: {
                      ...addDate(today(), -count),
                      hours: 23,
                      minutes: 59,
                      seconds: 59,
                  },
              };
    return {
        getMainRange,
        getComparingRange: () => {
            if (negative) return null;

            const main = getMainRange();

            return getPreviousDays(generateDefaultTimeRange(main));
        },
        diff: count,
    };
};
