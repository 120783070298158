import {cs} from "@common/react/chain-services";
import {ReportBuilder} from "./builder/report-builder";
import {ReportList} from "./report-list/report-list";
import {UseState} from "@common/react/use-state";
import {ReportBuilderLayout} from "./layout/report-builder-layout";
import {RoutingParam} from "../common/routing/routing-param";

export const ReportBuilderMain = ({reportSections}) =>
    cs(
        [
            "tabs",
            (_, next) =>
                next([
                    {
                        name: "builder",
                        label: "Report Builder",
                        render: () => ReportBuilder({reportSections}),
                    },
                    {
                        name: "recurring",
                        label: "Recurring Reports",
                        render: () => ReportList({reportType: "recurring"}),
                    },
                    // {
                    //     name: "one-time",
                    //     label: "One-time Reports",
                    //     render: () => ReportList({reportType: "one-time"}),
                    // },
                ]),
        ],
        [
            "tab",
            (_, next) =>
                RoutingParam({
                    defaultValue: "builder",
                    param: "reportTab",
                    next,
                }),
        ],
        // [
        //     "tab",
        //     (_, next) =>
        //         UseState({
        //             initValue: "report-builder",
        //             next,
        //         }),
        // ],
        ({tabs, tab}) => ReportBuilderLayout({tabs, activeTab: tab})
    );
