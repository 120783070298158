import * as React from "react";
import {cx} from "emotion";

export const rFieldTypeIcon2 = ({dataType, fillColor}) => {
    const color = fillColor || "#294661";
    const icons = [
        {
            dataTypes: ["DateTime"],
            render: () => (
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                    <path
                        id="Icon_ionic-md-calendar"
                        data-name="Icon ionic-md-calendar"
                        d="M14,11H11v3h3ZM13,4.5v1H8v-1H6.5v1H5.75A1.254,1.254,0,0,0,4.5,6.75v8.5A1.254,1.254,0,0,0,5.75,16.5h9.5a1.254,1.254,0,0,0,1.25-1.25V6.75A1.254,1.254,0,0,0,15.25,5.5H14.5v-1Zm2.25,10.75H5.75V8.625h9.5Z"
                        transform="translate(-4.5 -4.5)"
                        fill={color}
                    />
                </svg>
            ),
        },
        {
            dataTypes: ["DateTimeOffset"],
            render: () => (
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                    <g fill={color}>
                        <path
                            d="M13 4.5v1H8v-1H6.5v1h-.75A1.254 1.254 0 0 0 4.5 6.75v8.5a1.254 1.254 0 0 0 1.25 1.25h9.5a1.254 1.254 0 0 0 1.25-1.25v-8.5a1.254 1.254 0 0 0-1.25-1.25h-.75v-1zm2.25 10.75h-9.5V8.625h9.5z"
                            transform="translate(-9.439 -191) translate(4.939 186.5)"
                        />
                        <text fontFamily="Roboto-Regular, Roboto" fontSize="5px" transform="translate(-9.439 -191) translate(14 201)">
                            <tspan x="0" y="0">
                                +4
                            </tspan>
                        </text>
                    </g>
                </svg>
            ),
        },
        {
            dataTypes: ["Int"],
            render: () => (
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                    <path
                        id="Icon_awesome-hashtag"
                        data-name="Icon awesome-hashtag"
                        d="M11.8,6.271,11.995,5.2a.321.321,0,0,0-.316-.378h-2l.392-2.194A.321.321,0,0,0,9.75,2.25H8.662a.321.321,0,0,0-.316.265L7.933,4.821H5.291l.392-2.194a.321.321,0,0,0-.316-.378H4.278a.321.321,0,0,0-.316.265L3.55,4.821H1.431a.321.321,0,0,0-.316.265L.923,6.158a.321.321,0,0,0,.316.378h2L2.631,9.964H.513a.321.321,0,0,0-.316.265L.005,11.3a.321.321,0,0,0,.316.378h2l-.392,2.194a.321.321,0,0,0,.316.378H3.338a.321.321,0,0,0,.316-.265l.412-2.306H6.709l-.392,2.194a.321.321,0,0,0,.316.378H7.722a.321.321,0,0,0,.316-.265l.412-2.306h2.119a.321.321,0,0,0,.316-.265l.191-1.071a.321.321,0,0,0-.316-.378h-2l.612-3.429h2.119a.321.321,0,0,0,.316-.265ZM7.015,9.964H4.373l.612-3.429H7.627Z"
                        transform="translate(0 -2.25)"
                        fill={color}
                    />
                </svg>
            ),
        },
        {
            dataTypes: ["Double"],
            render: () => (
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="7.5" viewBox="0 0 14 7.5">
                    <g id="Decimal_Icon" transform="translate(-10 -124)">
                        <g id="Group_833" transform="translate(.129 1.5)">
                            <path
                                id="Icon_awesome-hashtag"
                                d="M6.885 4.6L7 3.97a.188.188 0 0 0-.185-.22H5.644l.228-1.28a.188.188 0 0 0-.185-.22h-.634a.187.187 0 0 0-.185.155l-.24 1.345H3.087l.228-1.28a.187.187 0 0 0-.185-.22H2.5a.187.187 0 0 0-.185.155L2.071 3.75H.835a.187.187 0 0 0-.185.15l-.111.63a.188.188 0 0 0 .185.22h1.168l-.357 2H.3a.188.188 0 0 0-.185.15L0 7.53a.188.188 0 0 0 .185.22h1.171l-.228 1.28a.188.188 0 0 0 .185.22h.635a.187.187 0 0 0 .184-.15l.24-1.345h1.541L3.685 9.03a.187.187 0 0 0 .185.22h.63a.187.187 0 0 0 .189-.15l.24-1.345h1.236A.187.187 0 0 0 6.35 7.6l.112-.625a.188.188 0 0 0-.185-.22H5.108l.357-2H6.7a.187.187 0 0 0 .185-.155zM4.092 6.75H2.551l.357-2h1.541z"
                                transform="translate(9.871 120.25)"
                                fill={color}
                            />
                            <path
                                id="Icon_awesome-hashtag-2"
                                d="M6.885 4.6L7 3.97a.188.188 0 0 0-.185-.22H5.644l.228-1.28a.188.188 0 0 0-.185-.22h-.634a.187.187 0 0 0-.185.155l-.24 1.345H3.087l.228-1.28a.187.187 0 0 0-.185-.22H2.5a.187.187 0 0 0-.185.155L2.071 3.75H.835a.187.187 0 0 0-.185.15l-.111.63a.188.188 0 0 0 .185.22h1.168l-.357 2H.3a.188.188 0 0 0-.185.15L0 7.53a.188.188 0 0 0 .185.22h1.171l-.228 1.28a.188.188 0 0 0 .185.22h.635a.187.187 0 0 0 .184-.15l.24-1.345h1.541L3.685 9.03a.187.187 0 0 0 .185.22h.63a.187.187 0 0 0 .189-.15l.24-1.345h1.236A.187.187 0 0 0 6.35 7.6l.112-.625a.188.188 0 0 0-.185-.22H5.108l.357-2H6.7a.187.187 0 0 0 .185-.155zM4.092 6.75H2.551l.357-2h1.541z"
                                transform="translate(16.871 120.25)"
                                fill={color}
                            />
                        </g>
                        <rect id="Rectangle_1652" width="1.5" height="1.5" rx=".2" transform="translate(16 130)" fill={color} />
                    </g>
                </svg>
            ),
        },
        {
            dataTypes: ["IDInt"],
            render: () => (
                <svg xmlns="http://www.w3.org/2000/svg" width="13.009" height="12.045" viewBox="0 0 13.009 12.045">
                    <g id="Group_686" data-name="Group 686" transform="translate(-48.493 -514)">
                        <path
                            id="Icon_awesome-hashtag"
                            data-name="Icon awesome-hashtag"
                            d="M11.8,6.271,11.995,5.2a.321.321,0,0,0-.316-.378h-2l.392-2.194A.321.321,0,0,0,9.75,2.25H8.662a.321.321,0,0,0-.316.265L7.933,4.821H5.291l.392-2.194a.321.321,0,0,0-.316-.378H4.278a.321.321,0,0,0-.316.265L3.55,4.821H1.431a.321.321,0,0,0-.316.265L.923,6.158a.321.321,0,0,0,.316.378h2L2.631,9.964H.513a.321.321,0,0,0-.316.265L.005,11.3a.321.321,0,0,0,.316.378h2l-.392,2.194a.321.321,0,0,0,.316.378H3.338a.321.321,0,0,0,.316-.265l.412-2.306H6.709l-.392,2.194a.321.321,0,0,0,.316.378H7.722a.321.321,0,0,0,.316-.265l.412-2.306h2.119a.321.321,0,0,0,.316-.265l.191-1.071a.321.321,0,0,0-.316-.378h-2l.612-3.429h2.119a.321.321,0,0,0,.316-.265ZM7.015,9.964H4.373l.612-3.429H7.627Z"
                            transform="translate(49 511.75)"
                            fill={color}
                        />
                        <g id="vpn_key-24px_1_" data-name="vpn_key-24px (1)" transform="translate(11 426)">
                            <path
                                id="Path_382"
                                data-name="Path 382"
                                d="M7.356,8.181a3.273,3.273,0,1,0,0,2.182H9.729v1.091a1.091,1.091,0,1,0,2.182,0V10.363a1.091,1.091,0,1,0,0-2.182ZM4.274,10.363A1.091,1.091,0,1,1,5.365,9.272,1.094,1.094,0,0,1,4.274,10.363Z"
                                transform="translate(37 87)"
                                fill="#fbfbfc"
                                strokeWidth="1"
                                stroke={color}
                            />
                        </g>
                    </g>
                </svg>
            ),
        },
        {
            dataTypes: ["IDUUID"],
            render: () => (
                <svg xmlns="http://www.w3.org/2000/svg" width="15.5" height="24" viewBox="0 0 15.5 24">
                    <g id="UUID_Icon" data-name="UUID Icon" transform="translate(-27 -383)">
                        <text id="U" transform="translate(27 402)" fontSize="16" fontFamily="Roboto-Bold, Roboto" fontWeight="700" fill={color}>
                            <tspan x="0" y="0">
                                U
                            </tspan>
                        </text>
                        <g id="vpn_key-24px_1_" data-name="vpn_key-24px (1)" transform="translate(-9.002 303)">
                            <path
                                id="Path_382"
                                data-name="Path 382"
                                d="M7.356,8.181a3.273,3.273,0,1,0,0,2.182H9.729v1.091a1.091,1.091,0,1,0,2.182,0V10.363a1.091,1.091,0,1,0,0-2.182ZM4.274,10.363A1.091,1.091,0,1,1,5.365,9.272,1.094,1.094,0,0,1,4.274,10.363Z"
                                transform="translate(38 85)"
                                fill="#fbfbfc"
                                strokeWidth="1"
                                stroke={color}
                            />
                        </g>
                    </g>
                </svg>
            ),
        },
        {
            dataTypes: ["Bool"],
            render: () => (
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="7.5" viewBox="0 0 15 7.5">
                    <g>
                        <path
                            fill={color}
                            d="M13.25 7h-7.5a3.75 3.75 0 0 0 0 7.5h7.5a3.75 3.75 0 0 0 0-7.5zm0 6a2.25 2.25 0 1 1 2.25-2.25A2.247 2.247 0 0 1 13.25 13z"
                            transform="translate(-4 -9) translate(2 2)"
                        />
                    </g>
                </svg>
            ),
        },
        {
            dataTypes: [],
            isDefault: true,
            render: () => (
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                    <path
                        id="type"
                        d="M7.383,9.656V2.344A1.408,1.408,0,0,1,8.789.938h.469V0H8.789A2.342,2.342,0,0,0,6.914.939,2.342,2.342,0,0,0,5.039,0H4.57V.938h.469A1.408,1.408,0,0,1,6.445,2.344v.491H1.406A1.408,1.408,0,0,0,0,4.241v3.75A1.408,1.408,0,0,0,1.406,9.4H6.445v.259a1.408,1.408,0,0,1-1.406,1.406H4.57V12h.469a2.342,2.342,0,0,0,1.875-.939A2.342,2.342,0,0,0,8.789,12h.469v-.937H8.789A1.408,1.408,0,0,1,7.383,9.656ZM1.406,8.46a.469.469,0,0,1-.469-.469V4.241a.469.469,0,0,1,.469-.469H6.445V8.46ZM12,4.241v3.75A1.408,1.408,0,0,1,10.594,9.4H8.32V8.46h2.273a.469.469,0,0,0,.469-.469V4.241a.469.469,0,0,0-.469-.469H8.32V2.835h2.273A1.408,1.408,0,0,1,12,4.241Z"
                        fill={color}
                    />
                </svg>
            ),
        },
    ];

    const icon = icons.find((i) => i.dataTypes.includes(dataType) || i.isDefault);

    return (
        <div
            className={cx("field-type-icon", {
                "id-uuid": dataType === "IDUUID",
                bool: dataType === "Bool",
            })}
        >
            {icon.render()}
        </div>
    );
};
