import {cx} from "emotion";
import {Button} from "../../../../../../../../common/form/buttons/button/button";
import {YesNoToggle} from "../../../../../../../../common/form/toggles/yes-no-toggle";
import {cs} from "@common/react/chain-services";
import {scope} from "@common/react/scope";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {stateToSelect} from "@common/ui-components/form/state-to-select";
import {flatten1, last, sort} from "@common/utils/collections";
import {chain} from "@common/utils/fs";
import {isBlank} from "@common/utils/strings";
import {rFieldTypeIcon} from "../../../../../common/field-type-icon/get-field-type-icon";
import {InteractionTable} from "../../../sync/common/tables/interaction-table";
import {getRealNameChildTable} from "../unstructured-list-helpers";
import "./sync-settings-properties-searches.scss";

export const SyncSettingsPropertiesSearches = ({dataSource, columns, noSearch}) =>
    cs(() => {
        const getCurrentTable = (row) => {
            const tableIndex = dataSource.value.tables.findIndex((t) => t.name === row.tableName);
            const disabledTableIndex = dataSource.value.disabledTables.findIndex((t) => t.name === row.tableName);

            return scope(dataSource, [tableIndex > -1 ? "tables" : "disabledTables", tableIndex > -1 ? tableIndex : disabledTableIndex]);
        };

        return cs(
            (_, next) => next(),
            () => {
                return (
                    <div className="sync-settings-columns-searches-88q sync-settings-properties-searches-99y">
                        {columns.length === 0
                            ? noSearch
                            : InteractionTable({
                                  list: chain(columns, (_) => [
                                      ..._.filter((c) => c.primaryKey),
                                      ...sort(
                                          _.filter((c) => !c.primaryKey),
                                          (c) => c.name
                                      ),
                                  ]),
                                  rowClassName: (row) => (row.isChildTable ? "child-table" : row.disabled ? "disabled" : ""),
                                  columns: [
                                      {
                                          label: "Matched Item",
                                          classNameFn: (row) => (row.isChildTable ? "child-table" : ""),
                                          className: "column-name",
                                          format: (row) => {
                                              if (row.isChildTable) {
                                                  return <>{getRealNameChildTable(row)}</>;
                                              }
                                              return (
                                                  <>
                                                      {row.name} {row.primaryKey && <span className="primary">(Primary Key)</span>}
                                                  </>
                                              );
                                          },
                                          sortValue: (row) => row.name,
                                      },
                                      {
                                          label: "PARENT OBJECT",
                                          format: (row) => {
                                              if (row.isChildTable) {
                                                  const split = row.name.split(">");
                                                  return split
                                                      .splice(0, split.length - 1)
                                                      .join(">")
                                                      .toUpperCase();
                                              }
                                              return row.tableName.toUpperCase();
                                          },
                                          sortValue: (row) => (row.isChildTable ? row.name : row.tableName),
                                      },
                                      {
                                          label: "PROPERTY TYPE",
                                          className: "interaction-row-types",
                                          format: (row) => {
                                              if (row.isChildTable) return null;

                                              const table = getCurrentTable(row);
                                              const isDisabledColumn = table.value.columns.findIndex((c) => c.name === row.name) === -1;

                                              const config =
                                                  {
                                                      Text: {
                                                          list: ["NonPII", "PII", "None"],
                                                          valueToLabel: (v) => (
                                                              <>
                                                                  {rFieldTypeIcon(`Text`)} Text ({v === "None" ? "Unqualified" : v === "NonPII" ? "Non-PII" : v})
                                                              </>
                                                          ),
                                                      },
                                                      DateTime: {
                                                          list: ["LocalTime", "UTC", "None"],
                                                          valueToLabel: (v) => (
                                                              <>
                                                                  {rFieldTypeIcon(`DateTime`)} DateTime ({v === "None" ? "Unqualified" : v === "LocalTime" ? "Local Time" : v})
                                                              </>
                                                          ),
                                                      },
                                                      DateTimeOffset: {
                                                          list: ["LocalTime", "UTC", "None"],
                                                          valueToLabel: (v) => (
                                                              <>
                                                                  {rFieldTypeIcon(`DateTimeOffset`)} DateTimeOffset (
                                                                  {v === "None" ? "Unqualified" : v === "LocalTime" ? "Local Time" : v})
                                                              </>
                                                          ),
                                                      },
                                                  }[row.dataType] || undefined;

                                              if (config && !isDisabledColumn) {
                                                  const columnIndex = table.value.columns.findIndex((c) => c.name === row.name);
                                                  const disabledColumnIndex = table.value.disabledColumns?.findIndex((c) => c.name === row.name);

                                                  return DropdownSelect({
                                                      ...config,
                                                      disabled: isDisabledColumn,
                                                      label: null,
                                                      errorMessage: `This field is required`,
                                                      hasError: isBlank(row.dataTypeProperties),
                                                      ...stateToSelect(
                                                          scope(table, [
                                                              isDisabledColumn ? "disabledColumns" : "columns",
                                                              isDisabledColumn ? disabledColumnIndex : columnIndex,
                                                              "dataTypeProperties",
                                                          ])
                                                      ),
                                                  });
                                              } else {
                                                  return (
                                                      <span
                                                          className={cx("value", {
                                                              "id-uuid": row.dataType === "IDUUID",
                                                          })}
                                                      >
                                                          {config ? (
                                                              config.valueToLabel(row.dataTypeProperties)
                                                          ) : (
                                                              <>
                                                                  {rFieldTypeIcon(row.dataType)} {row.dataType}
                                                              </>
                                                          )}
                                                      </span>
                                                  );
                                              }
                                          },
                                      },
                                      {
                                          labelF: () => (
                                              <>
                                                  Sync &nbsp;&nbsp;
                                                  <Button btnType="border" size="tiny">
                                                      ALL
                                                  </Button>{" "}
                                                  &nbsp;&nbsp;
                                                  <Button btnType="border" size="tiny">
                                                      NONE
                                                  </Button>
                                              </>
                                          ),
                                          format: (row) => {
                                              if (row.isChildTable) return null;

                                              const table = getCurrentTable(row);
                                              return YesNoToggle({
                                                  colors: {right: "#294661"},
                                                  state: {
                                                      value: table.value.columns.findIndex((c) => c.name === row.name) > -1,
                                                      onChange: (value) =>
                                                          table.change((oldTable) => ({
                                                              ...oldTable,
                                                              columns: value ? [...oldTable.columns, row] : oldTable.columns.filter((c) => c.id !== row.id),
                                                              disabledColumns: !value
                                                                  ? [...oldTable.disabledColumns, row]
                                                                  : oldTable.disabledColumns.filter((c) => c.id !== row.id),
                                                          })),
                                                  },
                                              });
                                          },
                                      },
                                  ],
                              })}
                    </div>
                );
            }
        );
    });
