// type = "collection" | "render"
const {AsyncQueue2} = require("../signal-r/async-queue-2");
const {randomId} = require("../../utils/random");
const signalR = require("@microsoft/signalr");
const {chain} = require("@common/utils/fs");

const DataQueryFetcher2 = ({
    channels,
    connBuilder = () => new signalR.HubConnectionBuilder().withAutomaticReconnect(),
    httpConnectionOptions,
    configBuilder = undefined,
}) => {
    const channels1 = channels.map((channel) => {
        return {
            channel,
            idTranslator: IdTranslator({
                matchResponsePayload: channel.matchResponsePayload,
            }),
            queue: AsyncQueue2({debounceDelay: 50}),
        };
    });

    const conn = chain(
        connBuilder(),
        (_) => configBuilder?.(_, httpConnectionOptions),
        (_) => _.configureLogging(signalR.LogLevel.Information).build()
    );

    (async () => {
        channels1.forEach((c) => {
            conn.on(c.channel.responseChannel, (batchId, ...payload) => {
                const id = c.idTranslator.extractRequest(batchId, payload);

                const {success, message} = c.channel.getResponseMessage(payload);
                c.queue[success ? "resolve" : "reject"](id, message);
            });
        });

        await conn.start();
        channels1.forEach((c) =>
            c.queue.activate({
                pushRequests: (requests) => {
                    const {batchId, ids} = c.idTranslator.addRequests(requests);
                    conn.invoke(c.channel.method, batchId, ...c.channel.formatRequestMessage(requests));
                    return ids;
                },
            })
        );

        // conn.onclose(() => {
        //     console.log("data-query close");
        // });
    })();

    return {
        channelInvoke: async (channelName, message) => {
            return channels1.find((c) => c.channel.name === channelName).queue.request(message);
        },
        destroy: async () => {
            channels1.forEach((c) => c.queue.destroy());
            await conn.stop();
        },
    };
};
exports.DataQueryFetcher2 = DataQueryFetcher2;

const IdTranslator = ({matchResponsePayload}) => {
    let batches = {};
    return {
        addRequests: (requests) => {
            const batchId = randomId();
            const ids = requests.map(() => randomId());
            batches[batchId] = requests.map((request, i) => ({
                request,
                id: ids[i],
            }));
            return {
                batchId,
                ids,
            };
        },
        extractRequest: (batchId, payload) => {
            const list = batches[batchId];
            const index = list.findIndex(({request}) => matchResponsePayload(payload, request));
            if (index === -1) {
                throw "[IdTranslator] Can't find request for this response payload:" + JSON.stringify(payload);
            } else {
                const id = list[index].id;
                if (list.length > 1) {
                    list.splice(index, 1);
                } else {
                    delete batches[batchId];
                }
                return id;
            }
        },
    };
};
