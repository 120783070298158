const {colorAxisAnchor} = require("../legend/color-axis-anchor");
const {useColorAxis, isColorAxisVertical, getDrillingStepProps2, isDrillingAllowed2} = require("../map-helper");

const mapPlotOptions = ({tile, theme, onClickPoint}) => {
    const caAnchor =
        tile.style.legendStyle.legendShown &&
        useColorAxis(tile) &&
        colorAxisAnchor({
            isVertical: isColorAxisVertical(tile),
        });

    return {
        map: {
            // turn this false if multiple series, if not hc will be modified the series object
            // allAreas: false,
            point: {
                events: {
                    click: function ({x, y, point}) {
                        if (this.isNull) {
                            return;
                        }

                        // if (isDrillingAllowed(tile, this.properties)) {
                        //     onDrill(getDrillingStepProps(this.properties));
                        // }

                        onClickPoint?.({x, y, point, chart: this.series.chart});
                    },

                    mouseOver: function () {
                        // show hand cursor on point whose drilling is available
                        const {properties: mapLocProperties, options: dataOptions} = this;
                        if (
                            isDrillingAllowed2({
                                mapLocProperties,
                                dataOptions,
                                tile,
                            })
                        ) {
                            this.graphic.addClass("drilling-allowed");
                        }

                        // make area border thicker on hover
                        const strokeWidth = this.graphic.parentGroup.element.getAttribute("stroke-width") || this.graphic.parentGroup.parentGroup.element.getAttribute("stroke-width") || this.graphic.parentGroup.parentGroup.element.firstChild.getAttribute("stroke-width");

                        this.graphic.element.setAttribute("stroke-width", strokeWidth * 2);

                        // show anchor on color axis
                        if (caAnchor) {
                            caAnchor.show({point: this});
                        }
                    },

                    mouseOut: function () {
                        this.graphic.removeClass("drilling-allowed");

                        // make area border back to normal
                        this.graphic.element.setAttribute("stroke-width", "inherit");

                        // hide anchor on color axis
                        if (caAnchor) {
                            caAnchor.hide();
                        }
                    },
                },
            },
            borderWidth: 0.8,
            borderColor: theme.dataVisualization.maps.borderColorRGB,
            nullColor: theme.dataVisualization.maps.emptyLocationColorRGB,
            // nullInteraction: true,
            states: {
                hover: {
                    color: {}, // do not change area color on hover
                },
            },
        },
    };
};
exports.mapPlotOptions = mapPlotOptions;
