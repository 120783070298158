import {DateFilter} from "./date/date-filter";
import {TextFilter} from "./text/text-filter";
import {NumericFilter} from "./numeric/numeric-filter";
import {BooleanFilter} from "./boolean/boolean-filter";

export const filterTypes = {
    DateFilter: (props) =>
        DateFilter({
            ...props,
            noComparing: props.collection?.$type === "ApiCollection",
        }),
    TextFilter: TextFilter,
    NumericFilter: NumericFilter,
    BooleanFilter: BooleanFilter,
};
