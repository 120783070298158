import {cx} from "emotion";
import {Button} from "../../../../../../../common/form/buttons/button/button";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {scope} from "@common/react/scope";
import {UseState} from "@common/react/use-state";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {stateToSelect} from "@common/ui-components/form/state-to-select";
import {SearchInputBar} from "@common/ui-components/search-input-bar/search-input-bar";
import {isBlank, isMatchText} from "@common/utils/strings";
import {rFieldTypeIcon} from "../../../../common/field-type-icon/get-field-type-icon";
import {InteractionTable} from "../../sync/common/tables/interaction-table";
import "./flat-file-interaction.scss";

export const FlatFileInteraction = ({interactions, dataSource}) =>
    cs(
        (_, next) => (interactions.value === null ? null : next()),
        consumeContext("apis"),
        ["keyword", (_, next) => UseState({next})],
        ["selected", (_, next) => UseState({initValue: interactions.value.tables[0], next})],
        ({keyword, selected, apis}) => {
            return (
                <div className="flat-file-interaction-99u">
                    <div className="header">
                        <div className="label">
                            <div className="text">Configure Data Types</div>
                        </div>
                        <span className="material-icons-outlined close-icon" onClick={() => interactions.onChange(null)}>
                            close
                        </span>
                    </div>

                    <div className="content">
                        <div className="header">
                            <div className="left">
                                <div className="name">{interactions.value.name}</div>
                                <div className="info">
                                    <div>Data: 425 KB</div>
                                    <div className="separator" />
                                    <div>Records: {Number(selected.value.rows).toLocaleString()}</div>
                                </div>
                            </div>

                            {SearchInputBar({state: keyword})}
                        </div>

                        <div className="main">
                            <div className="left">
                                {interactions.value.tables.map((table) => (
                                    <div
                                        onClick={() => selected.onChange(table)}
                                        className={cx("table-item", {
                                            selected: selected.value.name === table.name,
                                        })}
                                    >
                                        <img src={require("../../../../common/icons/data-source-table-icon.svg")} />
                                        <div className="text">{table.visualName}</div>
                                    </div>
                                ))}
                            </div>

                            <div className="separator" />

                            <div className="right">
                                {InteractionTable({
                                    list: selected.value.columns.filter((c) => isMatchText(c.name, keyword.value)),
                                    columns: [
                                        {
                                            label: "Name",
                                            className: "column-name",
                                            format: (row) => (
                                                <>
                                                    {row.name} {row.primaryKey && <span className="primary">(Primary Key)</span>}
                                                </>
                                            ),
                                            sortValue: (row) => row.name,
                                        },
                                        {
                                            label: "Type",
                                            className: "interaction-row-types",
                                            format: (row) => {
                                                const isDisabledColumn = false;

                                                const config =
                                                    {
                                                        Text: {
                                                            list: ["NonPII", "PII", "None"],
                                                            valueToLabel: (v) => (
                                                                <>
                                                                    {rFieldTypeIcon(`Text`)} Text ({v === "None" ? "Unqualified" : v === "NonPII" ? "Non-PII" : v})
                                                                </>
                                                            ),
                                                        },
                                                        DateTime: {
                                                            list: ["LocalTime", "UTC", "None"],
                                                            valueToLabel: (v) => (
                                                                <>
                                                                    {rFieldTypeIcon(`DateTime`)} DateTime ({v === "None" ? "Unqualified" : v === "LocalTime" ? "Local Time" : v})
                                                                </>
                                                            ),
                                                        },
                                                        DateTimeOffset: {
                                                            list: ["LocalTime", "UTC", "None"],
                                                            valueToLabel: (v) => (
                                                                <>
                                                                    {rFieldTypeIcon(`DateTimeOffset`)} DateTimeOffset (
                                                                    {v === "None" ? "Unqualified" : v === "LocalTime" ? "Local Time" : v})
                                                                </>
                                                            ),
                                                        },
                                                    }[row.dataType] || undefined;
                                                if (config && !isDisabledColumn) {
                                                    const table = scope(dataSource, ["tables", dataSource.value.tables.findIndex((t) => t.name === selected.value.name)]);
                                                    let columnIndex = table.value.columns.findIndex((c) => c.name === row.name);
                                                    let disabledColumnIndex = table.value.disabledColumns?.findIndex((c) => c.name === row.name);

                                                    return DropdownSelect({
                                                        ...config,
                                                        disabled: isDisabledColumn,
                                                        label: null,
                                                        errorMessage: `This field is required`,
                                                        hasError: isBlank(row.dataTypeProperties),
                                                        ...stateToSelect(
                                                            scope(table, [
                                                                isDisabledColumn ? "disabledColumns" : "columns",
                                                                isDisabledColumn ? disabledColumnIndex : columnIndex,
                                                                "dataTypeProperties",
                                                            ])
                                                        ),
                                                    });
                                                } else {
                                                    return (
                                                        <span
                                                            className={cx("value", {
                                                                "id-uuid": row.dataType === "IDUUID",
                                                            })}
                                                        >
                                                            {config ? (
                                                                config.valueToLabel(row.dataTypeProperties)
                                                            ) : (
                                                                <>
                                                                    {rFieldTypeIcon(row.dataType)} {row.dataType}
                                                                </>
                                                            )}
                                                        </span>
                                                    );
                                                }
                                            },
                                            // sortValue: (item) => -item.dataType
                                        },
                                    ],
                                })}
                            </div>
                        </div>

                        <div className="footer">
                            <Button
                                onClick={async () => {
                                    interactions.onChange(null);
                                    await apis.data.upsertDataSource(dataSource.value);
                                }}
                            >
                                Close
                            </Button>
                        </div>
                    </div>
                </div>
            );
        }
    );
