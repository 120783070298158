import React from "react";

const FilterIcon = ({className, fill = "currentColor"}) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 24 24"
        style={{transform: "rotate(360deg)"}}
    >
        <g fill="none" stroke={fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M22 3H2l8 9.46V19l4 2v-8.54L22 3z" />
        </g>
        <rect x="0" y="0" width="24" height="24" fill={"rgba(0, 0, 0, 0)"} />
    </svg>
);

export default FilterIcon;
