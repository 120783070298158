import {cs} from "@common/react/chain-services";
import * as React from "react";
import "./checkbox-line.scss";
import {cx} from "emotion";
import {Checkbox} from "@common/ui-components/form/checkbox/checkbox";

export const CheckboxLine = ({state, label, readOnly, background, disabled, textColor, className, style}) =>
    cs(({}) => (
        <div
            className={cx("checkbox-line checkbox-line-5gh", className)}
            onClick={(e) => {
                if (!readOnly && !disabled) {
                    state.onChange(!state.value);
                    e.preventDefault();
                    e.stopPropagation();
                }
            }}
            style={style ?? {}}
        >
            {Checkbox({state, readOnly, background, disabled})}

            <div className="label" style={{color: textColor}}>
                {label}
            </div>
        </div>
    ));
