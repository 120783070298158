import React from "react";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import "./model-action-tooltip.scss";

export const ModelActionTooltip = ({next}) =>
    cs(["pos", (_, next) => UseState({next})], ["info", (_, next) => UseState({next})], ({pos, info}) =>
        next({
            render: ({scale = 1}) =>
                pos.value && (
                    <div
                        className="model-action-tooltip-a33"
                        style={{
                            top: pos.value.y,
                            left: pos.value.x,
                            transform: pos.value.transform ?? `scale(${scale})`,
                            transformOrigin: pos.value.transformOrigin ?? "top left",
                        }}
                    >
                        {info.value}
                    </div>
                ),
            showTooltip: (props) => {
                pos.onChange(props.pos);
                info.onChange(props.info);
            },
            hideToolTip: () => pos.onChange(null),
        })
    );
