import React from "react";
import {cs} from "@common/react/chain-services";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";
import {cx} from "emotion";
import "./recent-table.scss";
import {UnhappyIcon} from "@common/ui-components/icons/global-icons";
export const RecentTable = ({headers, columns, list, onClickRow, getTrClass}) =>
    cs(
        (_, next) => (
            <>
                <VerbScrollbar>{next()}</VerbScrollbar>
                {list.length === 0 && (
                    <div className="recursive-table-no-result-vb1">
                        <UnhappyIcon />

                        <div className="text-message">No recent found</div>
                    </div>
                )}
            </>
        ),
        () => {
            return (
                <table className="recent-table-9k8">
                    <thead>
                        <tr>
                            {headers.map((c, i) => (
                                <th
                                    key={i}
                                    className={cx({
                                        "align-right": c.alignRight,
                                        "align-center": c.alignCenter,
                                    })}
                                    style={{...(i === 0 && {minWidth: 250})}}
                                >
                                    {c.label}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((item, k) => (
                            <>
                                <tr key={k} className={cx(getTrClass(item))}>
                                    {columns?.(item.type).map((column, j) => (
                                        <td
                                            key={j}
                                            style={column.style || {}}
                                            className={cx(column.classNameFn?.(item), column.className, {
                                                "align-right": column.alignRight,
                                                "align-center": column.alignCenter,
                                                shy: column.shy || column?.shyF?.(item),
                                            })}
                                            onClick={() => onClickRow(item)}
                                        >
                                            {(column.format || column.sortValue)(item.item, item?.path || [])}
                                        </td>
                                    ))}
                                </tr>
                            </>
                        ))}
                    </tbody>
                </table>
            );
        }
    );
