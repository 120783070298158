import "./y-axis-lines.scss";

import * as React from "react";

import {ShowHideToggle} from "../../../../../../../../../../../../../common/form/toggles/show-hide-toggle";
import {LpLine} from "../../../../../../../../../../common/left-panel/line/lp-line";
import {scope} from "@common/react/scope";
import {OrientationSelect} from "../../../common/orientation-select";
import {AxisRangeSelect} from "../../../common/axis-range-select";
import {GridStepTypeSelect} from "../../../common/grid-step-type-select";
import {AxisLocationSelect} from "../../../common/axis-location-select";
import {AxisTitle} from "../x-axis/x-axis-rules-comp";
import {YAxisLabel, YGridStepLabel} from "../../../horizontal-bar/panels/y-axis/y-axis-rules-comp";
import {getPath} from "@common/utils/arr-path";
import {CumulativeSelect} from "../../../common/cumulative-select/cumulative-select";
import {AxisScaleSelect} from "../../../common/axis-scale-select";
// import {LineMarkerSelect} from "../../../common/line-marker-select";
// import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
// import {stateToSelect} from "@common/ui-components/form/state-to-select";
// import {LineWidthSelect} from "../../../common/line-width-select";

export const rYAxisLines =
    (fieldsKey) =>
    ({tile, size}) => ({
        getLabel: (getField) =>
            `Y-Axis - Lines - (${fieldsKey
                .split("|")
                .map((f) => getField(f)?.visualNameShort)
                .join(" & ")})`,
        render: () => (
            <div className="combo-chart-y-axis-lines-sdv">
                {/*<div className="subsection">*/}
                {/*    <div className="header">*/}
                {/*        Visual Display*/}
                {/*    </div>*/}
                {/*    <div className="content">*/}
                {/*        {LineWidthSelect(scope(tile, ["style", "yAxisLine", fieldsKey, "thickness"]))}*/}
                {/*        {DropdownSelect({*/}
                {/*            label: "Style",*/}
                {/*            list: [*/}
                {/*                {label: "Straight", value: "Line"},*/}
                {/*                {label: "Smooth", value: "Spline"},*/}
                {/*            ],*/}
                {/*            valueToLabel: (v) => v.label,*/}
                {/*            ...stateToSelect(scope(tile, ["style", "yAxisLine", fieldsKey, "displayType"]), ["value"]),*/}
                {/*        })}*/}
                {/*        {LineMarkerSelect(scope(tile, ["style", "yAxisLine", fieldsKey, "marker"]))}*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div className="subsection">
                    <div className="header">Axis Title</div>
                    <div className="content">
                        {AxisTitle({
                            tile,
                            size,
                            state: scope(tile, ["style", "yAxisLine", fieldsKey, "label"]),
                        })}
                    </div>
                </div>

                <div className="subsection">
                    {YAxisLabel({
                        tile,
                        size,
                        state: scope(tile, ["style", "yAxisLine", fieldsKey, "labelShown"]),
                    })}
                </div>

                <div className="subsection">
                    <div className="header">Range</div>
                    <div className="content">{AxisRangeSelect(scope(tile, ["style", "yAxisLine", fieldsKey]))}</div>
                </div>

                <div className="subsection">
                    <div className="header">Grid Step</div>
                    <div className="content">
                        {GridStepTypeSelect({
                            state: scope(tile, ["style", "yAxisLine", fieldsKey]),
                            fieldType: "number",
                        })}
                    </div>
                </div>

                <div className="subsection">
                    {YGridStepLabel({
                        tile,
                        size,
                        state: scope(tile, ["style", "yAxisLine", fieldsKey, "gridStepLabelsShown"]),
                    })}

                    {getPath(tile.value, ["style", "yAxisLine", fieldsKey, "gridStepLabelsShown"]) && (
                        <div className="content">{OrientationSelect(scope(tile, ["style", "yAxisLine", fieldsKey, "gridStepLabelOrientation"]))}</div>
                    )}
                </div>

                <div className="subsection">
                    <div className="header">Other Options</div>
                    <div className="content">
                        {/*{MaxValInView(scope(tile, ["style", "yAxisLine", fieldsKey, "maxValuesInViewFixed"]))}*/}

                        {AxisLocationSelect(scope(tile, ["style", "yAxisLine", fieldsKey, "location"]))}

                        {LpLine({
                            label: "Axis Line",
                            control: ShowHideToggle({
                                state: scope(tile, ["style", "yAxisLine", fieldsKey, "lineShown"]),
                            }),
                        })}

                        {LpLine({
                            label: "Grid Lines",
                            control: ShowHideToggle({
                                state: scope(tile, ["style", "yAxisLine", fieldsKey, "gridLinesShown"]),
                            }),
                        })}

                        {AxisScaleSelect(scope(tile, ["style", "yAxisLine", fieldsKey, "logarithmic"]))}

                        {CumulativeSelect({
                            tile,
                            state: scope(tile, ["style", "yAxisLine", fieldsKey, "cumulative"]),
                        })}
                    </div>
                </div>
            </div>
        ),
    });
