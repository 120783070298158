import * as React from "react";
import {cs} from "@common/react/chain-services";
import "./display.scss";
import {scope} from "@common/react/scope";
import {ThumbnailSelect} from "../../../../../../../../../common/thumbnail-select/thumbnail-select";
import {stateToSelect} from "@common/ui-components/form/state-to-select";
import {MultipleAxisOptions} from "../../../common/multiple-axis-options";

export const Display = ({tile}) => ({
    initExpand: true,
    label: "Display",
    render: () =>
        cs(({}) => (
            <div className="vertical-tile-displays-4zs">
                {ThumbnailSelect({
                    ...stateToSelect(scope(tile, ["style", "displayType"]), ["name"]),
                    list: types.map((type) => ({
                        ...type,
                        label: type.label ?? type.name,
                    })),
                })}

                <div style={{marginTop: 20}}>
                    {MultipleAxisOptions({
                        state: scope(tile, ["style", "multipleAxisOption"]),
                    })}
                </div>
            </div>
        )),
});

const types = [
    {
        name: "Classic",
        thumbnail: require("./icons/bar-vertical-icon.svg"),
    },
    {
        name: "Stacked",
        thumbnail: require("./icons/bar-vertical-stacked-icon.svg"),
    },
    {
        label: "100%",
        name: "HundredPercent",
        thumbnail: require("./icons/bar-vertical-100-icon.svg"),
    },
];
