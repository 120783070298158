import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {stateToSelect} from "@common/ui-components/form/state-to-select";

export const ComparisonTypeSelect = (state) =>
    DropdownSelect({
        label: "Comparison Type",
        list: [
            {label: "Percent Change", value: "Percent"},
            {label: "Absolute Change", value: "Absolute"},
        ],
        valueToLabel: (v) => v.label,
        ...stateToSelect(state, ["value"]),
    });
