import {cs} from "@common/react/chain-services";
import {createGetFieldInfo} from "./common";
import {EditTileLogic} from "../tile/edit/edit-tile-logic";
import {DeleteSharedTileDialog} from "../../dashboard/env/shared-tiles/cm/delete/delete-shared-tile-dialog";
import {consumeContext, provideContext} from "@common/react/context";
import {Tabs} from "../tile/edit/left-panel/tabs/tabs";
import {LiveDashboard} from "@common/ui-components/live/live-dashboard/live-dashboard";
import {unique} from "@common/utils/collections";
import {equalDeep, keepOnly} from "@common/utils/objects";
import {UseState} from "@common/react/use-state";
import {IndependentContextLayout} from "./independent-context-layout";
import {LoadingIndicator} from "@common/ui-components/loading-indicator/loading-indicator";
import {LeftPanel} from "../tile/edit/left-panel/left-panel";
import ViewCollections from "./view-collections/view-collections";
import {DebugFunction} from "../tile/edit/left-panel/tabs/fields-tab/chart-types/common/debug-function/debug-function";

export const NormalTileLayout = ({savingQueue, selectModelForCloningDialog, moveSharedTileDialog, deleteSharedTileDialog, renameSharedTileDialog}) => {
    return cs(
        consumeContext("sharedTile"),
        consumeContext("collection"),
        consumeContext("theme"),
        consumeContext("routing"),
        ({sharedTile}, next) => createGetFieldInfo({next, sharedTile}),
        [
            "editTileLogic",
            ({sharedTile}, next) =>
                EditTileLogic({
                    next,
                    tile: sharedTile,
                    allowInvalidUpdates: true,
                    updateFunc: async (newTile) => {
                        if (newTile) {
                            const _newTile = await savingQueue.push((t) => newTile);
                            sharedTile.onChange(_newTile);
                            return _newTile;
                        }
                    },
                }),
        ],
        [
            "debugFunction",
            ({editTileLogic}, next) =>
                DebugFunction({
                    tile: editTileLogic?.tile,
                    apiType: "collectionTiles",
                    isChildTile: false,
                    next,
                }),
        ],
        ({debugFunction}, next) => provideContext("debugFunction", debugFunction, next),
        ["leftPanelWidth", (_, next) => UseState({next, initValue: null})],
        [
            "tabs",
            ({sharedTile, editTileLogic, leftPanelWidth, collection, theme, removeTile}, next) =>
                !editTileLogic.tile.value
                    ? next()
                    : Tabs({
                          ...editTileLogic,
                          remoteTile: sharedTile,
                          leftPanelWidth,
                          isSaving: savingQueue.executing,
                          size: {
                              width: collection.value?.gridLocations[0]?.colSpan,
                              height: collection.value?.gridLocations[0]?.rowSpan,
                          },
                          tileFuncs: {
                              removeTile,
                          },
                          renderMain: ({renderOverlay}) =>
                              LiveDashboard({
                                  theme,
                                  isEditTile: true,
                                  collection: collection.value,
                                  renderOverlay,
                                  noFilters: true,
                                  apiKey: null,
                                  disabledTileActions: true,
                                  noBackgroundColor: false,
                                  disabledFilters: unique([...sharedTile?.value?.filters.map((f) => keepOnly(f, ["$type", "columns"]))]),
                              }),
                          next,
                      }),
        ],
        ["interactions", (_, next) => UseState({initValue: null, next})],
        ({sharedTile, editTileLogic, leftPanelWidth, tabs, debugFunction, interactions, routing}) => {
            return IndependentContextLayout({
                tileCommands: [
                    {
                        label: "Rename",
                        onClick: () => renameSharedTileDialog.show({tile: sharedTile.value}),
                    },
                    {
                        label: "Clone Shared Tile",
                        onClick: () =>
                            selectModelForCloningDialog.show({
                                tile: sharedTile.value,
                            }),
                    },
                    {
                        label: "Move To",
                        onClick: () => moveSharedTileDialog.show({params: sharedTile.value}),
                    },
                    {
                        label: "DeBug",
                        onClick: () => {
                            routing.updateParams({tileTab: "fields"});
                            debugFunction.showDebugUI();
                        },
                    },
                    {
                        label: "Delete Shared Tile",
                        className: "delete-action",
                        onClick: () => deleteSharedTileDialog.show({tiles: [sharedTile.value]}),
                    },
                ],
                interactions,
                sharedTile: editTileLogic.tile,
                leftPanel: {
                    content: !editTileLogic.tile.value ? LoadingIndicator() : LeftPanel(tabs, leftPanelWidth),
                    width: leftPanelWidth.value,
                },
                main: tabs.main(),
                syncState: {
                    type: savingQueue.executing ? "saving" : equalDeep(editTileLogic?.tile?.value, sharedTile.value) ? "saved" : "unsaved",
                    onClick: editTileLogic?.form?.showErrors,
                },
                rightPanel: {
                    content: interactions.value?.name === "view-collections" && (
                        <ViewCollections onClose={() => interactions.onChange(null)} sharedTile={editTileLogic.tile.value} />
                    ),
                },
            });
        }
    );
};
