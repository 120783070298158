const {cs} = require("@common/react/chain-services");
const {Load} = require("@common/react/load");
const {cascade} = require("../../../../../../../common/utils/cascade");

const loadCollections = ({apis, next}) =>
    cs(
        (_, next) => apis?.data && next(),
        [
            "collectionIds",
            ({}, next) =>
                Load({
                    fetch: async () => {
                        const folders = await apis.collection.getFolders();
                        return cascade(folders, "[*].collections[*].id").filter((v) => v);
                    },
                    next,
                }),
        ],
        [
            "collections",
            ({collectionIds}, next) =>
                Load({
                    _key: JSON.stringify(collectionIds),
                    fetch: () =>
                        collectionIds &&
                        Promise.all(
                            collectionIds.map(async (cId) => {
                                return await apis.collection.getCollection(cId);
                            })
                        ),
                    next,
                }),
        ],
        ({collections}) => next(collections)
    );
exports.loadCollections = loadCollections;
