import React from "react";
import {RemoveGuidanceButton} from "./step1";
import {cs} from "@common/react/chain-services";
import {Button} from "../../../../../../../../../common/form/buttons/button/button";

export const Step3 = ({onBack, structured, showTutorial}) =>
    cs((_) => (
        <div className="step-1">
            <div>
                <strong>Other Configuration Options:</strong>
            </div>

            {structured && (
                <div className="do-not-sync">
                    <div>
                        <strong>Row Filtering.</strong>
                        Click the top right corner of any table to enter a filter query. Use this optional feature to sync just the exact
                        data you want to show in your charts.
                    </div>
                    <img src={require("../images/row-filtering.png")} />
                </div>
            )}

            <br />
            <div className="do-not-sync">
                <div>
                    <strong>Sync Once (then pause)</strong>. If you don’t need your data to be refreshed continually, use this feature to
                    sync only when you tell it to.
                </div>
                <img src={require("../images/do-not-sync.png")} />
            </div>

            <div className="step-footer has-margin">
                {RemoveGuidanceButton({showTutorial})}
                <Button iconLeft={<i className="fas fa-chevron-left" />} btnType="border" onClick={onBack}>
                    Previous
                </Button>
            </div>
        </div>
    ));
