import React from "react";
import {cs} from "@common/react/chain-services";
import {cx} from "emotion";
import {Dropdown} from "@common/ui-components/dropdown/dropdown";
import "./button-dropdown.scss";
import {Button} from "../../../../../../common/form/buttons/button/button";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";

export const ButtonDualDropdown = ({
    label,
    list,
    onChange,
    size = "medium",
    btnType = "primary",
    disabled,
    valueToLabel = (v) => v,
    iconRight = <i className="fas fa-angle-down"></i>,
    onClick,
}) =>
    cs(
        [
            "renderToggle",
            ({chosenIndex}, next) =>
                next(({showExpand, showingExpand}) => (
                    <div className="dual">
                        <Button
                            // className={cx("toggle", {expanding: showingExpand})}
                            onClick={onClick}
                            size={size}
                            btnType={btnType}
                            disabled={disabled}
                        >
                            {label}
                        </Button>
                        <div
                            className={cx("action-toggle", {
                                expanding: showingExpand,
                            })}
                            onClick={() => showExpand(!showingExpand)}
                        >
                            {iconRight}
                        </div>
                    </div>
                )),
        ],
        [
            "renderExpand",
            ({chosenIndex}, next) =>
                next(({close, width}) => (
                    <VerbScrollbar maxHeight="300px" className="list" style={{minWidth: width}}>
                        {list?.map((l, i) => (
                            <div
                                key={i}
                                className={cx("item")}
                                onClick={() => {
                                    onChange(l);
                                    close();
                                }}
                            >
                                {valueToLabel(l)}
                            </div>
                        ))}
                    </VerbScrollbar>
                )),
        ],
        ({renderToggle, renderExpand}) => (
            <div className={cx("button-dropdown button-dropdown-9wq", disabled && "disabled")}>
                {Dropdown({
                    renderToggle,
                    minExpandHeight: 300,
                    renderExpand,
                    disabled,
                })}
            </div>
        )
    );
