import {getLegendPosition} from "../../../../web-client/src/routes/collection/tile/edit/left-panel/tabs/tile-tab/chart-types/combo-chart/panels/legend/legend-display-rules";
import {getLegendContentValue} from "../../../../web-client/src/routes/collection/tile/edit/left-panel/tabs/tile-tab/chart-types/common/legend-display-select";
import {isTitleShown} from "../../../../web-client/src/routes/collection/tile/edit/left-panel/tabs/tile-tab/chart-types/pie-chart/display-size-rules";
import {isSectionLabelHidden} from "../../../../web-client/src/routes/collection/tile/edit/left-panel/tabs/tile-tab/chart-types/venn-diagram/display-rules";

// copy title, legend display rules from pie chart
export const vennDiagramRestyling = ({tile, size, isCompare}) => {
    const newTitleStyle = {
        ...tile.style.title,
        show: isTitleShown({size}) ? tile.style.title.show : false,
    };

    const legendPosition = getLegendPosition(tile.style.legendStyle.legendPosition, size, tile.$type);
    const newLegendStyle = {
        ...tile.style.legendStyle,
        legendPosition,
        legendShown: !legendPosition ? false : tile.style.legendStyle.legendShown,
        legendContent: getLegendContentValue(tile.style.legendStyle.legendContent, tile.style.legendStyle),
    };

    const newSectionLabels = {
        ...tile.style.dataLabels,
        show: isSectionLabelHidden({size}) ? false : tile.style.dataLabels?.show,
    };

    return {
        ...tile,
        style: {
            ...tile.style,
            title: newTitleStyle,
            legendStyle: newLegendStyle,
            dataLabels: newSectionLabels,
        },
    };
};
