import React from "react";
import {cs} from "@common/react/chain-services";
import "./env-authentication.scss";
import {UseState} from "@common/react/use-state";
import {Form2} from "@common/react/cs-form/form2";
// import {required} from "@common/react/cs-form/validators/required";
import {AuthMethod} from "./auth-method";
import {Headers} from "./headers";
import {RequestParameters} from "./request-parameters";
// import {InstructionService} from "./instruction-service";
import {BodyType} from "./body-type";
import {ResponseMapping} from "./response-mapping";
import {keyed} from "@common/react/keyed";
import {consumeContext} from "@common/react/context";
import {AuthTestDialog} from "./auth-test-dialog/auth-test-dialog";
import {Load} from "@common/react/load";
import {isEmpty, keepOnly} from "@common/utils/objects";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {AuthStaticTooltip} from "./auth-static-tooltip";
import {Button} from "../../../../../../../common/form/buttons/button/button";
import {PlayIcon} from "@common/ui-components/icons/global-icons";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";
import {isWebsiteUrl} from "@common/react/cs-form/validators/is-url";

export const EvnAuthentication = ({evnAuthEndpoint}) =>
    cs(
        consumeContext("apis"),
        consumeContext("auth"),
        consumeContext("authEndpoint"),
        consumeContext("envDashboardToast"),
        [
            "localEnvAuthEndpoint",
            ({authEndpoint}, next) =>
                UseState({
                    initValue: evnAuthEndpoint || {},
                    next,
                }),
        ],
        [
            "form",
            ({localEnvAuthEndpoint, authEndpoint, apis, envDashboardToast}, next) =>
                Form2({
                    // initShowErrors: true,
                    data: localEnvAuthEndpoint,
                    fields: {
                        endpoint: {
                            transforms: ["trim"],
                            validators: [
                                {
                                    when: (state) => !state?.apiOnly,
                                    validate: isWebsiteUrl.validate,
                                    getMessage: () => "Endpoint is not valid.",
                                },
                            ],
                            debounce: true,
                        },
                        bodyRaw: {
                            debounce: true,
                        },
                        headers: {
                            validators: [
                                {
                                    when: (state) => !state?.apiOnly,
                                    validate: (field) => field && Object.keys(field).filter((k) => k === null || k.trim() === "").length === 0,
                                    getMessage: () => "Headers: key cannot be empty.",
                                },
                                {
                                    when: (state) => !state?.apiOnly,
                                    validate: (field) => field !== null && Object.values(field).filter((v) => v === null || v.trim() === "").length === 0,
                                    getMessage: () => "Headers: value cannot be empty.",
                                },
                            ],
                        },
                        resultMapping: {
                            validators: [
                                {
                                    when: (state) => state?.resultMapping != null,
                                    validate: (field) =>
                                        Object.keys(field).filter((v) => !/^[a-zA-Z0-9_]+$/.test((typeof v == "object" ? v.property : v).replace("User.", ""))).length === 0,
                                    getMessage: () => "User Mapping: Only alpha-numeric and underscores are allowed.",
                                },
                                {
                                    when: (state) => state?.resultMapping != null,
                                    validate: (field) =>
                                        Object.values(field).filter((v) => v == null || (typeof v == "object" ? v.property.trim() === "" : v.trim() === "")).length === 0,
                                    getMessage: () => "Property: Property cannot be empty.",
                                },
                            ],
                        },
                    },
                    onSubmit: async () => {
                        const successMessage = "Active Authentication saved";
                        const failMessage = "Active Authentication cannot saved";
                        try {
                            const newAuthEndpoint = await apis.authEndpoint.upsertAuthEndpoint({
                                $type: "EndpointAuthConfig",
                                ...localEnvAuthEndpoint.value,
                            });

                            if (newAuthEndpoint?.id) {
                                envDashboardToast.show({
                                    text: successMessage,
                                });
                                localEnvAuthEndpoint.onChange(newAuthEndpoint);
                                authEndpoint.change((old) => [...old.filter((e) => e.$type !== newAuthEndpoint.$type), newAuthEndpoint]);
                            } else {
                                envDashboardToast.show({
                                    text: failMessage,
                                    isError: true,
                                });
                            }
                        } catch (err) {
                            envDashboardToast.show({
                                text: failMessage,
                                isError: true,
                            });
                        }
                    },
                    next,
                }),
        ],
        [
            "authParams",
            ({localEnvAuthEndpoint, apis}, next) =>
                Load({
                    _key: JSON.stringify(keepOnly(localEnvAuthEndpoint.value, ["method", "endpoint", "headers", "bodyContentType", "bodyRaw", "bodyFormData"])),
                    fetch: () => !isEmpty(localEnvAuthEndpoint.value) && apis.tenant.getAuthParameters(localEnvAuthEndpoint.value),
                    next,
                }),
        ],
        ["authTestDialog", (_, next) => AuthTestDialog({next})],
        ({form, localEnvAuthEndpoint, authParams, authTestDialog, auth}) => {
            const allowEditing = ["Full"].includes(auth.user.roles[0]);
            return (
                <div className="env-authentication-4lk">
                    <VerbScrollbar className="main">
                        <div className="title">Active Authentication</div>
                        <div className="description">
                            The Auth Endpoint is configured as a callback to your API. After a user is authenticated by your application and the Verb javascript is rendered and
                            token information is supplied, this is the endpoint Verb will call to verify the user's legitimate and retrieve any user-specific data used for data
                            filtering.
                        </div>

                        {!localEnvAuthEndpoint.value ? (
                            <div className="no-auth-found">No authentication found.</div>
                        ) : (
                            <>
                                <div className="form">
                                    <div className="auth-method-9sf">
                                        {DropdownSelect({
                                            disabled: !allowEditing,
                                            label: (
                                                <div className="auth-method-select-label">
                                                    Embedding Type
                                                    {AuthStaticTooltip({
                                                        name: "collection_types",
                                                    })}
                                                </div>
                                            ),
                                            list: [false, true],
                                            isSelected: (v) => localEnvAuthEndpoint.value.apiOnly === v,
                                            onChange: (v) =>
                                                localEnvAuthEndpoint.change((c) => ({
                                                    ...c,
                                                    apiOnly: v,
                                                })),
                                            placeholder: "Select Option",
                                            valueToLabel: (val) => (!val ? "All Embedding Types" : "Key Based Embedding Only"),
                                        })}
                                    </div>

                                    {cs(
                                        // keyed(Math.random()),
                                        () =>
                                            [AuthMethod, Headers, BodyType, RequestParameters, ResponseMapping]
                                                .filter((_, index) => {
                                                    if (localEnvAuthEndpoint.value.apiOnly) {
                                                        return index == 4;
                                                    }
                                                    return true;
                                                })
                                                .map((comp, i) =>
                                                    cs(keyed(i), () =>
                                                        comp({
                                                            authEndpoint: localEnvAuthEndpoint,
                                                            authParams,
                                                            form,
                                                            disableEditing: !allowEditing,
                                                        })
                                                    )
                                                )
                                    )}
                                </div>
                            </>
                        )}
                    </VerbScrollbar>

                    <div className="buttons fixed-buttons">
                        <div className="left">
                            {allowEditing && (
                                <Button btnType="border" size="large" onClick={() => localEnvAuthEndpoint.onChange(evnAuthEndpoint || {})}>
                                    Cancel
                                </Button>
                            )}
                        </div>
                        <div className="right">
                            {!localEnvAuthEndpoint.value.apiOnly && (
                                <Button
                                    iconLeft={<PlayIcon />}
                                    btnType="secondary"
                                    size="large"
                                    disabled={!form.looksValid}
                                    onClick={() => {
                                        if (form.valid) {
                                            authTestDialog.show({
                                                authEndpoint: localEnvAuthEndpoint.value,
                                                authParams,
                                            });
                                        } else {
                                            form.showErrors();
                                        }
                                    }}
                                >
                                    Test
                                </Button>
                            )}
                            {allowEditing && (
                                <Button btnType="primary" size="large" className="button-primary" disabled={!form.looksValid} onClick={form.submit}>
                                    Save
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            );
        }
    );
