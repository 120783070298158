const {setPath} = require("../../../../common/utils/arr-path");

const handleUnauthWithRefreshAuthToken =
    ({refreshAuthToken, onUnauthenticated}) =>
    async ({reInvoke}) => {
        if (refreshAuthToken) {
            const success = await refreshAuthToken();

            if (success) {
                return reInvoke({});
            }
        }

        onUnauthenticated?.();
        return new Promise(() => {});
    };
exports.handleUnauthWithRefreshAuthToken = handleUnauthWithRefreshAuthToken;
