import * as React from "react";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {Load} from "@common/react/load";
import {consumeContext} from "@common/react/context";
import {stateToSelect} from "@common/ui-components/form/state-to-select";
import {scope} from "@common/react/scope";
import {DebounceCache} from "@common/react/debounce-cache";
import {TextInput} from "../../../../../../../../common/form/text-input/text-input";
import "./create-folder-service.scss";
import {getFolderDepthOrderList} from "../get-folder-depth-order-list";
import {SearchableSelect} from "@common/ui-components/searchable-select/searchable-select";

export const CreateFolderService = ({folders, next, customUpsertApi, customCheckFolderApi, apiType}) =>
    cs(
        consumeContext("apis"),
        [
            "folder",
            (_, next) =>
                UseState({
                    initValue: {
                        parentFolderID: null,
                    },
                    next,
                }),
        ],
        // ["form", ({folder, apis}, next) => Form2({
        //     data: folder,
        //     ...folderFormConfig({apis}),
        //     next,
        // })],
        [
            "validation",
            ({folder, apis}, next) =>
                cs(
                    [
                        "check",
                        (_, next) =>
                            Load({
                                _key: JSON.stringify(folder.value),
                                fetch: () => {
                                    if (!folder.value?.name) {
                                        return false;
                                    }
                                    const newFolderName = folder.value.name.trim();
                                    const isExistName = folders.find((f) => f.name == newFolderName);
                                    if (isExistName) {
                                        return false;
                                    }

                                    const api = customCheckFolderApi ? customCheckFolderApi : apis[apiType].checkFolderName;
                                    return api(newFolderName, {
                                        parentFolderId: folder.value.parentFolderID,
                                    });
                                },
                                next,
                            }),
                    ],
                    ({check}) => next(check)
                ),
        ],
        [
            "debounce",
            ({folder}, next) =>
                DebounceCache({
                    state: scope(folder, ["name"]),
                    next,
                }),
        ],
        ({folder, debounce, validation, form, apis}) =>
            next({
                render: () => (
                    <div className="create-folder-service-9sf create-folder-service">
                        {SearchableSelect({
                            label: "Parent Folder",
                            list: getFolderDepthOrderList(folders),
                            valueToInputLabel: (f) => f.name,
                            valueToLabel: (f) => <span style={{paddingLeft: f.level * 15}}>{f.name}</span>,
                            ...stateToSelect(scope(folder, ["parentFolderID"]), ["id"]),
                        })}

                        {TextInput({
                            label: "Folder Name",
                            state: debounce.state,
                            onBlur: debounce.flush,
                            status:
                                debounce.state.value &&
                                (debounce.active || validation == null ? (
                                    <i className="status-46c loading fa fa-spinner fa-pulse" />
                                ) : !validation ? (
                                    <i className="status-46c invalid fa fa-close" />
                                ) : (
                                    <i className="status-46c valid fa fa-check" />
                                )),
                        })}
                    </div>
                ),
                create: async () => {
                    try {
                        const newFolder = customUpsertApi ? await customUpsertApi(folder.value) : await apis[apiType].upsertFolder(folder.value);
                        return {folder: newFolder};
                    } catch (error) {
                        return {error};
                    }
                },
                validName: !debounce.active && validation,
            })
    );
