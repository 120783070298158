import {getTooltipFontSizes} from "./get-tooltip-font-sizes";
import {getIndicatorShapes} from "./indicator-shapes";
import {getColors} from "../../../gauge/chart-options/gauge-helpers";
import {cs} from "../../../../../react/chain-services";
import {CURRENCY_CONVERSION, getCurrencyConversion} from "../../../common/currency-conversion-warning/currency-conversion-warning";

export const rGaugeTable = ({tile, rawData, formatters, theme}) => {
    const fontSizes = getTooltipFontSizes(theme);
    const indicatorShapes = getIndicatorShapes(theme);

    const failures = cs(
        [
            "currencyConversion",
            (_, next) =>
                next(
                    getCurrencyConversion({
                        $type: rawData.$type,
                        data: rawData,
                        tileFields: [tile.valueField],
                    })
                ),
        ],
        ({currencyConversion}, next) => (currencyConversion?.hasFailures ? next() : ""),
        () => `<span
                class="material-icons-round currency-failures"
                style="color: #FF5959; font-size: 11px"
            >priority_high</span>`
    );

    const rHeader = () => {
        return `<div class="non-compare-header" style="font-size: ${fontSizes.small}px">
                <div class="title">
                    ${tile.valueField.displayName}
                </div>
            </div>`;
    };

    const rPoints = () => {
        const {dataColor, compareDataColor} = getColors({tile, theme});
        return [
            `<div class="point table-row" style="font-size: ${fontSizes.medium}px">
                <div class="name">
                    <span class="indicator" style="color: ${dataColor}">
                        ${indicatorShapes["bar"]}
                    </span>
                    ${tile.valueField.displayName}
                </div>
                <div class="main-value">${rawData.value == null ? "N/A" : formatters.valueFormatter(rawData.value)}${failures}</div>
                
            </div>`,
            `<div class="point table-row" style="font-size: ${fontSizes.medium}px">
                <div class="name">
                    <span class="indicator" style="color: ${compareDataColor}">
                        ${indicatorShapes["dashed-line"]}
                    </span>
                    ${tile.targetValueField?.displayName || tile.targetValueConstant?.displayName || "Target"}
                </div>
                <div class="main-value">${rawData.target == null ? "N/A" : formatters.targetValueFormatter(rawData.target)}</div>
            </div>`,
        ].join("");
    };

    const rDifference = () => {
        return `
            <div class="total-row table-row" style="font-size: ${fontSizes.medium}px">
                <div style="font-weight: normal; text-align: left">Difference</div>
                <div class="main-total">
                    ${formatters.valueFormatter(rawData.value - rawData.target)}${failures}
                    </br>
                    <span style="font-weight: normal; font-size: ${fontSizes.small}px">
                        (${formatters.percentFormatter((rawData.value / rawData.target) * 100)})
                    </span>
                </div>
            </div>
        `;
    };

    return `${rHeader()}
        <div class="table non-compare">
            ${rPoints()}
            ${rDifference()}
        </div>`;
};
