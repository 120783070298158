import {Expandable} from "../../../../common/expandable/expandable";
import {LpColorLine} from "../../../../common/left-panel/lp-color-line";
import {ffToColorPickerLine} from "../../../../../../../common/form/ff-to-color-picker-line";
import * as React from "react";
import {ShowHideToggle} from "../../../../../../../common/form/toggles/show-hide-toggle";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {ffToDropdown} from "../../../../../../../common/form/ff-to-dropdown";
import {FontSizeSelect} from "../../../tile/edit/left-panel/tabs/tile-tab/chart-types/common/font-size-select";
import {ffToNumberInput} from "../../../../../../../common/form/ff-to-number-input";
import {DebounceNumberInput} from "../../../../../../../common/form/debounce-input/debounce-number-input";

export const HoverTooltips = ({form, sectionInitClosed}) => (
    <Expandable
        {...{
            initExpand: !sectionInitClosed,
            label: "Hover Tooltips",
            render: () => (
                <>
                    <div className="panel-22">
                        {(() => {
                            const ffProps = ffToDropdown(form.field(["tooltipHover", "theme"]));
                            return DropdownSelect({
                                label: "Tooltip Theme",
                                list: ["Light", "Dark"],
                                ...ffProps,
                                isSelected: (v) => (ffProps.value || "Light") === v,
                            });
                        })()}
                        {(() => {
                            const ffProps = ffToDropdown(form.field(["tooltipHover", "position"]), ["value"]);
                            return DropdownSelect({
                                label: "Tooltip Position",
                                list: ["Above", "Below"].map((value) => ({
                                    value,
                                    label: value + " charts",
                                })),
                                valueToLabel: (v) => v.label,
                                ...ffProps,
                                isSelected: (v) => v.value === (ffProps.value || "Above"),
                            });
                        })()}
                        {FontSizeSelect({
                            label: "Tooltip Font Size Group",
                            ...ffToDropdown(form.field(["tooltipHover", "fontSize"])),
                            themeFontSizeGroup: form.field(["fontSizeGroup"]).state.value,
                        })}
                    </div>
                    <div className="panel-33">
                        <div className="header">
                            Hover Indicator Line
                            <div className="control">
                                {ShowHideToggle({
                                    state: form.field(["tooltipHover", "indicatorLineShown"]).state,
                                })}
                            </div>
                        </div>
                        {form.field(["tooltipHover", "indicatorLineShown"]).state.value && (
                            <div className="content">
                                {(() => {
                                    const ffProps = ffToDropdown(form.field(["tooltipHover", "indicatorLineStyle"]));
                                    return DropdownSelect({
                                        label: "Line Style",
                                        list: ["Solid", "Dashed", "Dotted"],
                                        ...ffProps,
                                        isSelected: (v) => (ffProps.value || "Solid") === v,
                                    });
                                })()}
                                {(() => {
                                    const ffProps = ffToColorPickerLine(form.field(["tooltipHover", "indicatorLineColor"]));
                                    return LpColorLine({
                                        label: "Line Color",
                                        ...ffProps,
                                        state: {
                                            value: ffProps.state.value || "#000000",
                                            onChange: ffProps.state.onChange,
                                        },
                                    });
                                })()}
                                {(() => {
                                    const ffProps = ffToNumberInput(form.field(["tooltipHover", "indicatorLineWidth"]));
                                    return DebounceNumberInput({
                                        label: "Line Width",
                                        unit: "pixels",
                                        showZero: true,
                                        ...ffProps,
                                        state: {
                                            value: ffProps.state.value == null ? 0.5 : ffProps.state.value,
                                            onChange: ffProps.state.onChange,
                                        },
                                    });
                                })()}
                            </div>
                        )}
                    </div>
                </>
            ),
        }}
    />
);
