import "./header-bar.scss";

import {cx} from "emotion";
import React, {createElement as h} from "react";

import SearchIcon from "assets/icons/common/SearchIcon";

import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {Load2} from "@common/react/load2";
import {UseState} from "@common/react/use-state";
import {scope} from "@common/react/scope";
import {AnyAction} from "@common/react/any-action";
import {TextInput} from "@common/form/text-input/text-input";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";
import {ApiCollectionIcon, CollectionDashboardIcon} from "@common/ui-components/icons/global-icons";
import {isMatchText} from "@common/utils/strings";

const getAPIType = (type) => {
    const map = {
        datasource: "data",
        collection: "collection",
        model: "model",
        sharedTile: "collectionTiles",
    };

    return map[type];
};

export const DataTypeSelect = ({ID, type, name, noDataType}) => {
    return cs(
        consumeContext("auth"),
        ({auth}, next) => {
            if (["Full"].includes(auth.user.roles[0])) {
                return next();
            }
            return (
                <div className="data-type-select">
                    <div className="data-type">
                        <div className="data-type-name">{name}</div>
                    </div>
                </div>
            );
        },
        consumeContext("apis"),
        consumeContext("routing"),
        [
            "opened",
            (_, next) => (
                <UseState
                    {...{
                        initValue: false,
                        next,
                    }}
                />
            ),
        ],
        ["keyword", (_, next) => UseState({initValue: "", next})],
        ({opened}, next) =>
            h(AnyAction, {
                disabled: !opened.value,
                fn: () => opened.onChange(false),
                next,
            }),
        [
            "list",
            ({apis, routing}, next) =>
                ID
                    ? Load2({
                          fetch: () => apis[getAPIType(type)].getRelatedTo(ID),
                          next,
                      })
                    : next(),
        ],
        [
            "formattedList",
            ({list, routing}, next) => {
                if (!list || !list.value) return next(null);
                let ret = [];
                const types = [
                    {
                        name: "Model",
                        icon: require("./icons/model.svg"),
                    },
                    {
                        name: "DataSource",
                        icon: require("./icons/datasource.svg"),
                    },
                    {
                        name: "Collection",
                        getIcon: (collection) => (collection.version ? ApiCollectionIcon({}) : CollectionDashboardIcon({})),
                    },
                ];

                for (let type of types) {
                    const key = type.name;
                    if (list.value[key]?.length > 0) {
                        ret.push({
                            name: key == "DataSource" ? "Data Source" : key,
                            type: key.toLowerCase(),
                            icon: type.icon,
                            getIcon: type.getIcon,
                            items: list.value[key].map((item) => ({
                                ...item,
                                onClick: () => {
                                    if (key == "Model")
                                        routing.goto("edit-model", {
                                            modelId: item.id,
                                        });
                                    if (key == "DataSource")
                                        routing.goto("edit-data-source", {
                                            dsId: item.id,
                                            type: item.type,
                                        });
                                    if (key == "Collection")
                                        routing.goto("edit-collection", {
                                            colId: item.id,
                                        });
                                },
                            })),
                        });
                    }
                }

                return next(ret);
            },
        ],
        ({formattedList, opened, keyword}) => {
            const colors = {
                collection: "#1497DF1F",
                model: "#9544D71F",
                datasource: "#20B78F1F",
            };

            return (
                <div className="data-type-select">
                    <div
                        className={cx("data-type", {
                            opened: opened.value,
                            clickable: !noDataType,
                        })}
                        onClick={() => !noDataType && opened.onChange(!opened.value)}
                    >
                        <div className="data-type-name">{name}</div>

                        {formattedList?.length > 0 && <span className="material-icons-outlined arrow-down">keyboard_arrow_down</span>}
                    </div>

                    {formattedList?.length > 0 && (
                        <>
                            <VerbScrollbar maxHeight="calc(100vh - 120px)" className={cx("data-types-list", opened.value && "show")}>
                                <div className="search-input">
                                    {SearchIcon({})}
                                    {TextInput({
                                        placeholder: "Search for a model or data source...",
                                        state: scope(keyword),
                                    })}
                                </div>

                                {formattedList.map((section, index) => (
                                    <div className="section" key={index}>
                                        <div className="section-title">
                                            {section.name}
                                            {section.items.length > 1 ? "s" : ""}
                                        </div>

                                        {section.items
                                            .filter((item) => isMatchText(item.name, keyword.value))
                                            .map((item, index) => (
                                                <div className="section-item" key={index} onClick={() => item.onClick()}>
                                                    <div
                                                        className="icon"
                                                        style={{
                                                            background: colors[section.type],
                                                        }}
                                                    >
                                                        {section.getIcon ? section.getIcon(item) : <img src={section.icon} alt="" />}
                                                    </div>
                                                    {item.name} {item.version && <span>| {item.version}</span>}
                                                </div>
                                            ))}
                                    </div>
                                ))}
                            </VerbScrollbar>
                        </>
                    )}
                </div>
            );
        }
    );
};
