import * as React from "react";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {keyed} from "@common/react/keyed";
import "./choose-type.scss";
import {cx} from "emotion";
import {Button} from "../../../../../../../../../common/form/buttons/button/button";
import {consumeContext} from "@common/react/context";

export const ChooseCollectionType = ({next, onChoose}) =>
    cs(
        consumeContext("subscriptionBrief"),
        [
            "chosen",
            (_, next) =>
                UseState({
                    initValue: "DashboardCollection",
                    next,
                }),
        ],
        [
            "types",
            ({subscriptionBrief}, next) =>
                next(
                    [
                        {
                            iconPath: require("../../../../../../getting-started/build-data/create-dashboards/icons/dashboard-icon.svg"),
                            name: "Dashboard",
                            description: "Create a full-featured dashboard with multiple charts.",
                            type: "DashboardCollection",
                        },
                        {
                            iconPath: require("../../../../../../getting-started/build-data/create-dashboards/icons/api-icon.svg"),
                            name: "API Report",
                            description: "Create a report accessible via API.",
                            type: "ApiCollection",
                            isHidden: !subscriptionBrief.isBoughtFeature("ApiCollection"),
                        },
                        {
                            iconPath: require("../../../../../../getting-started/build-data/create-dashboards/icons/contextual-tile-icon.svg"),
                            name: "Contextual Tile",
                            description: "Create a single chart or data point.",
                            type: "SparkCollection",
                            isHidden: true,
                        },
                    ].filter((c) => !c.isHidden)
                ),
        ],
        ({chosen, types}) =>
            next({
                main: (
                    <div className="choose-collection-type-09a">
                        <div className="question">What type of collection are you creating?</div>
                        <div className="collection-types">
                            {types.map(({iconPath, name, description, type, disabled}, i) =>
                                cs(keyed(i), ({}) =>
                                    rColType({
                                        iconPath,
                                        name,
                                        description,
                                        selected: chosen.value === type,
                                        onChoose: () => !disabled && chosen.onChange(type),
                                        disabled,
                                    })
                                )
                            )}
                        </div>
                    </div>
                ),
                button: <Button onClick={() => onChoose(chosen.value)}>Next</Button>,
            })
    );

const rColType = ({iconPath, name, description, selected, disabled, onChoose}) => (
    <div className={cx("col-type col-type-53g", {disabled}, {active: selected})} onClick={onChoose}>
        <div className="left-radio">
            <div className={cx("radio", {selected})} />
        </div>
        <div className="left">
            <img src={iconPath} alt="" />
        </div>
        <div className="right">
            <div className="name">{name}</div>
            <div className="description">{description}</div>
        </div>
    </div>
);
