import "./line.scss";

import {cx} from "emotion";
import * as React from "react";

import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {consumeContext} from "@common/react/context";
import {isDataValid, loadTileData} from "../common/load-tile-data";
import {groupSeriesByStackLine} from "../line-series-processors/group-series-by-stack-line";
import {getLineChartOptions} from "./chart-options/get-line-chart-options";
import {getFormatters} from "../factory/formatters/formatters";
import {getTooltipPerSeriesOption} from "../factory/tooltip/tooltip-series-option";
import {isComparing} from "../factory/check-compare";
import {getMeasurementAxesOptions} from "../factory/measurement-axes/measurement-axes";
import {getDimensionAxisOptions} from "../factory/dimension-axis/dimension-axis";
import {keepOnly} from "@common/utils/objects";
import {getDateFilterValue} from "../factory/get-date-filter-value";
import {getFieldType} from "../common/get-field-type";
import {RenderChart} from "../render-chart/render-chart";
import {SwapAxis} from "../common/swap-axis/swap-axis";
import {MenuOptions} from "../common/menu-options/menu-options";
import {isDimRealDateTime} from "../factory/is-dim-real-date-time";
import {rawDataProcessor} from "../data-processor/raw-data-processor";
import {rawDataManipulator} from "../raw-data-manipulator/raw-data-manipulator";
import {NoDataScreen} from "../common/no-data-screen/no-data-screen";
import {ChartInnerLayout} from "../chart-layout/chart-inner-layout";
import {flatten1} from "@common/utils/collections";
import {ActionMenu} from "../common/action-menu/action-menu";
import {loadTileFields} from "../get-field-color";
import {generateReferenceSeries} from "../reference-lines/generate-reference-series";
import {IgnoreUpdate} from "@common/react/ignore-update";
import {BlankDateWarning} from "../common/blank-date-warning";
import {CurrencyConversionWarning, getCurrencyConversion} from "../common/currency-conversion-warning/currency-conversion-warning";
import {fragments} from "@common/react/fragments";
import {Watch} from "@common/react/watch";

export const Line = ({tile, tileFilters, theme, loadData, downloadData, size, chartRef, defaultData, overrideTile, hasSwapAxis, disabledTileActions = false}) =>
    cs(
        consumeContext("sdkStaticUrl"),
        consumeContext("tileActionControlFilter"),
        [
            "chartData",
            ({tileActionControlFilter}, next) =>
                defaultData
                    ? next(defaultData)
                    : loadTileData({
                          next,
                          tileFilters,
                          loadData,
                          size,
                          tile,
                          theme,
                          tileKey: JSON.stringify([
                              keepOnly(tile, ["xAxisField", "yAxisFields", "groupField", "filters", "limit", "sort", "categorySort"]),
                              tile.style.xAxis.dateGaps,
                          ]),
                          disabled: tileActionControlFilter?.value?.tile?.id == tile.id,
                          removeJSCursor: true,
                      }),
        ],
        consumeContext("selectedTimezone"),
        ["rawData", ({chartData}, next) => next(rawDataManipulator({tile, rawData: chartData}))],
        ({rawData}, next) => (!isDataValid(rawData) ? NoDataScreen({size, theme}) : next()),
        ["isCompare", ({rawData}, next) => next(isComparing(rawData, tile))],
        [
            "isDimensionRealDate",
            ({rawData}, next) =>
                next(
                    isDimRealDateTime({
                        tile,
                        dimensionAttr: "xAxisField",
                        series: rawData.series,
                    })
                ),
        ],

        [
            "rawData2",
            ({isCompare, rawData, isDimensionRealDate}, next) =>
                next(
                    rawDataProcessor({
                        tile,
                        rawData,
                        isDimensionRealDate,
                        isCompare,
                    })
                ),
        ],

        [
            "formatters",
            ({rawData2, selectedTimezone}, next) =>
                next(
                    getFormatters({
                        tile,
                        dimensionAttr: "xAxisField",
                        measurementAttrs: ["yAxisFields"],
                        rawData: rawData2,
                        timezone: selectedTimezone?.value,
                    })
                ),
        ],

        [
            "tooltipOptions",
            ({formatters, isCompare, rawData2, isDimensionRealDate}, next) =>
                next(
                    getTooltipPerSeriesOption({
                        tile,
                        theme,
                        styleMeasurementAttr: "yAxis",
                        formatters,
                        isDimensionDate: getFieldType(tile["xAxisField"]) === "date",
                        isDimensionRealDate,
                        isCompare,
                        dateFilterValue: getDateFilterValue(rawData2.series),
                        rawData: rawData2,
                    })
                ),
        ],

        [
            "tileFields",
            ({}, next) =>
                loadTileFields({
                    next,
                    configs: {
                        tile,
                        dimensionAttr: "xAxisField",
                        measureGroupAttrs: ["yAxisFields"],
                        groupFieldAttr: "groupField",
                        checkUnique: false,
                    },
                }),
        ],

        [
            "data",
            ({rawData2, tooltipOptions, formatters, tileFields}, next) =>
                next(
                    groupSeriesByStackLine({
                        series: rawData2.series,
                        tile,
                        theme,
                        tooltipOptions,
                        tileFields,
                        measurementFormatters: formatters.measurementFormatters,
                    })
                ),
        ],

        [
            "yAxisKey",
            ({data}, next) =>
                UseState({
                    next: ({value, onChange}) =>
                        next({
                            value: value == null || data[value] == null ? Object.keys(data)[0] : value,
                            onChange,
                        }),
                }),
        ],

        [
            "referenceSeries",
            ({yAxisKey, data, formatters}, next) =>
                next(
                    generateReferenceSeries({
                        tile,
                        theme,
                        formatters,
                        series: tile.style.multipleAxisOption === "IndependentAxes" ? flatten1(Object.values(data).map(({series}) => series)) : data[yAxisKey.value].series,
                    })
                ),
        ],

        [
            "yAxisOptions",
            ({formatters, data, referenceSeries}, next) =>
                next(
                    getMeasurementAxesOptions({
                        tile,
                        theme,
                        data,
                        referenceSeries,
                        styleMeasurementAttr: "yAxis",
                        measurementFormatters: formatters.measurementFormatters,
                    })
                ),
        ],

        [
            "xAxisOptions",
            ({formatters, rawData2, data, yAxisKey, isCompare}, next) =>
                next(
                    getDimensionAxisOptions({
                        tile,
                        theme,
                        rawSeries: rawData2.series.filter((s) => s.stack === yAxisKey.value),
                        series: data[yAxisKey.value].series,
                        isCompare,
                        dimensionAttr: "xAxisField",
                        styleDimensionAttr: "xAxis",
                        dimensionFormatter: formatters.dimensionFormatter,
                    })
                ),
        ],

        [
            "controls",
            ({data, yAxisKey, tileActionControlFilter, rawData, tileFields}, next) => {
                const seriesKeys = Object.keys(data);
                const isIndependentAxes = tile.style.multipleAxisOption == "IndependentAxes";
                const hasMenuOptions = tile.style.showDownloadData || tile.style.showDownloadImage;
                const hasAlternativeAxes = !isIndependentAxes && seriesKeys.length > 1;
                // const hasCurrencyConversionFailures = rawData.series.filter(s => s.currencyConversionFailures).length > 0

                return next(
                    fragments(
                        Watch({
                            value: !!hasAlternativeAxes,
                            onChanged: (changed) => hasSwapAxis?.onChange?.(!!changed),
                            initRun: true,
                        }),
                        <div className="controls">
                            {CurrencyConversionWarning({
                                currencyConversion: getCurrencyConversion({
                                    $type: rawData.$type,
                                    data: rawData,
                                    tileFields,
                                }),
                                theme,
                            })}

                            {BlankDateWarning({
                                isDimensionRealDate: isDimRealDateTime({
                                    tile,
                                    dimensionAttr: "xAxisField",
                                    series: rawData.series,
                                    noLimit: true,
                                }),
                                filters: tileFilters.getValue(),
                            })}

                            {hasMenuOptions &&
                                MenuOptions({
                                    chartRef,
                                    theme,
                                    tile,
                                    downloadData,
                                    tileFilters,
                                })}

                            {hasAlternativeAxes &&
                                SwapAxis({
                                    theme,
                                    tile,
                                    list: [
                                        {
                                            choices: seriesKeys,
                                            onSelect: (k) => yAxisKey.onChange(k),
                                            isSelected: (k) => yAxisKey.value === k,
                                            valueToLabel: (key) => tile.style.yAxis[key].label || data[key].label,
                                        },
                                    ],
                                })}
                        </div>
                    )
                );
            },
        ],

        ({rawData, data, controls}, next) =>
            ChartInnerLayout({
                size,
                tile,
                theme,
                next,
                noData: !isDataValid(rawData),
                hasControls: !!controls,
            }),

        [
            "actionMenu",
            ({formatters}, next) =>
                ActionMenu({
                    tile,
                    overrideTile,
                    dimensionFormatter: formatters.dimensionFormatter,
                    disabledTileActions,
                    next,
                }),
        ],

        [
            "onClickPoint",
            ({actionMenu, tileFields, data}, next) => {
                if (!actionMenu.hasActions()) {
                    return next();
                }
                return next(({point, ...other}) => {
                    const dimensionField = tileFields.find((f) => f.$type == "DimensionTileField");
                    const dimensionValue = point.name ?? point.category;

                    const groupField = tileFields.find((f) => f.$type == "CategoryTileField");
                    let groupValue = null;
                    let fieldToValue = {
                        [dimensionField.id]: dimensionValue,
                    };

                    if (groupField) {
                        groupValue = point.series.name;
                        fieldToValue[groupField.id] = groupValue;
                    }

                    const _fieldToValue = tileFields
                        .filter((f) => f.$type == "MeasureTileField")
                        .reduce((obj, field) => {
                            let items = null;
                            if (groupField) {
                                const series = data[field.id].series;
                                items = series.find((s) => s.name == groupValue)?.data;
                            } else {
                                const key = Object.keys(data).find((k) => k.indexOf(field.id) >= 0);
                                items = data[key].series.find((s) => s.tileFieldID == field.id)?.data;
                            }

                            obj[field.id] = items?.find((d) => d[0] == dimensionValue)?.[1] ?? null;
                            return obj;
                        }, {});

                    const tileActionData = point.series?.userOptions?.tileActionData;

                    tileActionData?.columns.forEach((c, index) => {
                        fieldToValue[c.tileFieldID] = tileActionData.data[index];
                    });

                    actionMenu?.show?.({
                        point,
                        ...other,
                        fieldToValue: {
                            ...fieldToValue,
                            ..._fieldToValue,
                        },
                    });
                });
            },
        ],

        ({selectedTimezone}, next) =>
            IgnoreUpdate({
                next,
                props: {timezoneId: selectedTimezone?.value},
                when: ({timezoneId}) => timezoneId != selectedTimezone?.value,
            }),

        ({data, yAxisKey, yAxisOptions, xAxisOptions, referenceSeries, isCompare, isDimensionRealDate, formatters, rawData2, onClickPoint, controls}) => {
            const isIndependentAxes = tile.style.multipleAxisOption == "IndependentAxes";
            const isAreaLine = ["Area", "SplineArea"].includes(tile.style.displayType);
            const createColoringSeries = (s, prefix = "fake") => ({
                ...s,
                color: s._baseColor,
                type: s.type.includes("spline") ? "spline" : "line",
                ...(s.id && {id: prefix + s.id}),
                linkedTo: s.linkedTo || s.id,
                enableMouseTracking: false,
                label: {enabled: false},
            });

            let options = {
                ...getLineChartOptions({
                    tile,
                    theme,
                    isDimensionRealDate,
                    isCompare,
                    formatters,
                    rawData: rawData2,
                    onClickPoint,
                }),
                xAxis: tile.style.displayType.includes("Area")
                    ? !isCompare
                        ? {...xAxisOptions, maxPadding: 0, minPadding: 0}
                        : xAxisOptions?.map((axis) => ({
                              ...axis,
                              maxPadding: 0,
                              minPadding: 0,
                          }))
                    : xAxisOptions,
                yAxis: !isIndependentAxes ? yAxisOptions[yAxisKey.value].options : Object.values(yAxisOptions).map(({options}) => options),

                series: [
                    ...(!isIndependentAxes
                        ? [...data[yAxisKey.value].series, ...(isAreaLine ? data[yAxisKey.value].series.map(createColoringSeries) : [])]
                        : flatten1(Object.values(data).map(({series}, i) => series.concat(isAreaLine ? series.map((s) => createColoringSeries(s, `fake_${i}_`)) : [])))),
                    ...(referenceSeries || []),
                ],
            };

            const currentXAxis = Array.isArray(options.xAxis) ? options.xAxis.find((item) => item.visible !== false) : options.xAxis;

            if (onClickPoint && currentXAxis.labels.enabled) {
                currentXAxis.labels.events = {
                    click: function ({x, y}) {
                        onClickPoint?.({
                            x,
                            y,
                            point: {category: this.pos, y: null},
                            onlyDimensionField: true,
                            chart: this.chart,
                        });
                    },
                };
            }

            return (
                <div
                    className={cx("line-chart-533", !tile?.style?.title.show && "hide-title")}
                    key={getRefreshKey({
                        tile,
                        theme,
                        tileFiltersValue: tileFilters.getValue(),
                        key: yAxisKey.value,
                    })}
                >
                    <div className="chart">
                        <RenderChart
                            {...{
                                chartRef,
                                options,
                                size,
                                theme,
                                tile,
                                formatters,
                            }}
                        />
                    </div>

                    {controls}
                </div>
            );
        }
    );

const getRefreshKey = ({tile, theme, tileFiltersValue, key}) =>
    JSON.stringify([
        keepOnly(tile, ["id", "style", "filters", "groupField", "limit", "sort", "categorySort", "referenceLines"]),
        theme.dataVisualization.toolTipsAndLegends,
        key,
        tileFiltersValue,
    ]);
