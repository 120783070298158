import "./table-field-config-override.scss";

import React from "react";

import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {scope} from "@common/react/scope";
import {LeftPanelClosable} from "../../../../../../../common/left-panel-closable/left-panel-closable";
import {keyed} from "@common/react/keyed";
import {ShowHideToggle} from "../../../../../../../../../../common/form/toggles/show-hide-toggle";
import {TextareaInput} from "../chart-types/common/textarea-input/textarea-input";
import {PositiveChangeValueSelect} from "../chart-types/common/positive-change-value-select";
import {StaticTooltipService} from "../../../../../../../common/tooltip3/static-tooltip-service";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {getPath} from "@common/utils/arr-path";
import {FieldItem} from "../../../../../../edit/common/field-item/field-item";
import {rFieldTypeIcon} from "../../../../../../../common/field-type-icon/get-field-type-icon";
import {ComparisonTypeSelect} from "../chart-types/common/comparison-type-select";

export const TableFieldConfigOverride = ({next, tile}) =>
    cs(["selecting", (_, next) => UseState({next})], ({selecting}) =>
        next({
            render: () =>
                [
                    ...tile.value.actualValueFields.map((item) => ({
                        ...item,
                        actualValueField: true,
                    })),
                    ...tile.value.aggregatedValues,
                ].map((item, i) =>
                    cs(keyed(i), () => (
                        <FieldItem
                            onClick={() => selecting.onChange(item)}
                            label={item.displayName}
                            rightIcon={<i className="far fa-arrow-right" aria-hidden="true" />}
                            leftIcon={rFieldTypeIcon(item.dataType)}
                        />
                    ))
                ),
            toggleOpen: (val) => selecting.onChange(val),
            override:
                selecting.value &&
                cs(
                    ({}, next) => (
                        <LeftPanelClosable
                            {...{
                                onClose: () => selecting.onChange(false),
                                label: selecting.value.displayName + " Column Settings",
                                content: next(),
                            }}
                        />
                    ),
                    [
                        "staticTooltip",
                        (_, next) =>
                            StaticTooltipService({
                                direction: "top",
                                info: "Show users a comparison of the current and previous period values.",
                                tooltipBoxWidth: 200,
                                // topOffset: 35,
                                leftOffset: -20,
                                next,
                            }),
                    ],
                    ({staticTooltip}) => {
                        const {columnSettings} = tile.value.style || {};
                        const {actualValueField, aggregationFunc, dataType, id} = selecting.value || {};

                        const allowSummaryAggregation = !actualValueField || ["Int", "Double"].includes(dataType);
                        // const state = scope(tile, ["style", "columnSettings", id, "summaryAggregation"]);
                        const columnState = scope(tile, ["style", "columnSettings", id]);

                        return (
                            <div className="table-field-config-override-2a3">
                                <div className="subsection">
                                    <div className="header">
                                        Information Icon
                                        <div className="control">
                                            {ShowHideToggle({
                                                state: scope(columnState, ["showInfoIcon"]),
                                            })}
                                        </div>
                                    </div>

                                    {columnSettings?.[id]?.showInfoIcon && (
                                        <div className="content">
                                            {TextareaInput({
                                                state: scope(columnState, ["infoIconText"]),
                                            })}
                                        </div>
                                    )}
                                </div>

                                {(["Int", "Double", "IDInt"].includes(selecting.value.dataType) ||
                                    (!aggregationFunc?.includes("First") &&
                                        !aggregationFunc?.includes("Last") &&
                                        tile.value.aggregatedValues?.findIndex((av) => av.id === selecting.value.id) > -1)) && (
                                    <div className="subsection">
                                        <div className="header">
                                            Comparison Values
                                            {staticTooltip.renderIcon({
                                                icon: <i className="material-icons">info</i>,
                                                className: "info",
                                            })}
                                            <div className="control">
                                                {ShowHideToggle({
                                                    state: scope(columnState, ["showComparisonValues"]),
                                                })}
                                            </div>
                                        </div>
                                        {columnSettings?.[id]?.showComparisonValues && (
                                            <>
                                                <div className="content">{ComparisonTypeSelect(scope(columnState, ["comparisonType"]))}</div>
                                                <div className="content">{PositiveChangeValueSelect(scope(columnState, ["positiveValueGood"]))}</div>
                                            </>
                                        )}
                                    </div>
                                )}

                                {allowSummaryAggregation && (
                                    <div className="subsection">
                                        <div className="header">
                                            Summary Number
                                            <div className="control">
                                                {ShowHideToggle({
                                                    // state: scope(tile, ["style", "columnSettings", id, "showSummaryAggregation"])
                                                    state: {
                                                        value: columnState.value?.showSummaryAggregation,
                                                        onChange: (val) => {
                                                            columnState.onChange({
                                                                ...columnState.value,
                                                                showSummaryAggregation: val,
                                                                summaryAggregation: columnState.value?.summaryAggregation ?? (actualValueField && val ? "Sum" : null),
                                                            });
                                                        },
                                                    },
                                                })}
                                            </div>
                                        </div>

                                        {getPath(tile.value, ["style", "columnSettings", id, "showSummaryAggregation"]) && (
                                            <div className="content">
                                                {DropdownSelect({
                                                    label: "Summary Aggregation",
                                                    list: getListAggregations(actualValueField),
                                                    isSelected: (val) => columnState.value?.summaryAggregation == val.value,
                                                    onChange: (val) =>
                                                        columnState.onChange({
                                                            ...columnState.value,
                                                            summaryAggregation: val.value,
                                                        }),
                                                    valueToLabel: (val) => val.label,
                                                })}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        );
                    }
                ),
        })
    );

const getListAggregations = (isActualValueField) => {
    const defaultList = [
        {value: null, label: "Auto"},
        {value: "Sum", label: "Sum"},
        {value: "Average", label: "Average"},
        {value: "Median", label: "Median"},
        {value: "Minimum", label: "Minimum"},
        {value: "Maximum", label: "Maximum"},
        {
            value: "StandardDeviationPartial",
            label: "Standard Deviation Partial",
        },
        {
            value: "StandardDeviationPopulation",
            label: "Standard Deviation Population",
        },
        {value: "VariationPartial", label: "Variation Partial"},
        {value: "VariationPopulation", label: "Variation Population"},
    ];

    if (isActualValueField) {
        return defaultList.filter((i) => !!i.value);
    }

    return defaultList;
};
