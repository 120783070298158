import * as React from "react";
import {cs} from "@common/react/chain-services";
import {loadTileData} from "@common/ui-components/charts/common/load-tile-data";
import {keepOnly} from "@common/utils/objects";
import "./text-label.scss";
import {getTooltipFontSizes} from "@common/ui-components/charts/factory/tooltip/shared-tooltip-formatter/get-tooltip-font-sizes";
import {TruncatingTooltip} from "../../../../web-client/src/routes/common/truncating-tooltip/truncating-tooltip";
import {lightOrDark} from "@common/utils/color-util";

export const TextLabel = ({tile, tileFilters, loadData, theme, size, defaultData, overrideTile, disabledTileActions}) =>
    cs(
        [
            "data",
            (_, next) =>
                defaultData
                    ? next(defaultData)
                    : loadTileData({
                          next,
                          tileFilters,
                          loadData,
                          size,
                          tile,
                          theme,
                          tileKey: JSON.stringify(keepOnly(tile, ["valueField", "filters"])),
                      }),
        ],
        [
            "truncatingTooltip",
            (_, next) => {
                const themeMode = theme.dataVisualization.toolTipsAndLegends.tooltipTheme.toLowerCase() || "light";
                return TruncatingTooltip({
                    forceUpdate: true,
                    delay: 0,
                    alternateStyle: false,
                    className: `${themeMode}-theme`,
                    tooltipContentStyle: {
                        fontSize: getTooltipFontSizes(theme).small,
                        fontFamily: theme.general.canvas.fontFamily,
                        color: themeMode === "light" ? theme.general.canvas.fontColorRGB || "#546B81" : "#E2E2E2",
                    },
                    next,
                });
            },
        ],
        ({data, truncatingTooltip}) => {
            const text = [tile.style.supportTextBefore, data.content, tile.style.supportTextAfter].filter((v) => v != null).join(" ");

            const colors = theme.dataVisualization.dataColorPalettes.kpiColorsRGB;
            const color = colors[tile.style.valueColorApplication?.index || 0];

            return (
                <div className="text-label-tile-0f3">
                    <div className="text-container">
                        <div
                            {...{
                                className: "text",
                                ref: truncatingTooltip.ref,
                                ...truncatingTooltip.tooltip(text),
                                style: {
                                    color:
                                        tile.style.backgroundStyle === "NoBackground" &&
                                        lightOrDark(theme.general.canvas.backgroundColorRGB) === "dark"
                                            ? "white"
                                            : color,
                                    fontSize: size.height > 2 ? 40 : 30,
                                    textAlign: tile.style.valuePosition,
                                    padding: "0px 20px",
                                },
                            }}
                        >
                            {text}
                        </div>
                    </div>
                </div>
            );
        }
    );
