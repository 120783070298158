const {equalDeep} = require("../utils/objects");
const {UseState} = require("./use-state");
const {Interval} = require("./interval");
const {fragments} = require("./fragments");
const {Static2} = require("./static-2");
const {cs} = require("./chain-services");

const ObserveDomSize = ({onChange, ignoreChange, next, invokeOnStart = false, delay = 2000, debounce = 2000 - 50, useDebounce = false}) =>
    cs(["domRef", (_, next) => Static2({next})], ["size", (_, next) => UseState({next, debounce: useDebounce ? debounce : 0})], ({domRef, size}) =>
        fragments(
            size.value &&
                Interval({
                    delay,
                    fn: () => {
                        const dom = domRef.get();
                        if (!dom) {
                            return;
                        }

                        const r = dom.getBoundingClientRect();
                        const size1 = {
                            width: r.width,
                            height: r.height,
                        };
                        if (!equalDeep(size.value, size1) && (ignoreChange == null || !ignoreChange(size.value, size1))) {
                            if (size.isDebounce) {
                                size.onChange.cancel();
                            }

                            size.onChange(size1);
                            onChange?.(size1);
                        }
                    },
                }),

            next({
                ref: (dom) => {
                    domRef.set(dom);

                    if (!size.value && dom) {
                        const r = dom.getBoundingClientRect();
                        const size1 = {
                            width: r.width,
                            height: r.height,
                        };
                        size.onChange(size1);
                        invokeOnStart && onChange?.(size1);
                    }
                },
                getElement: () => domRef.get(),
                value: size.value,
            })
        )
    );
exports.ObserveDomSize = ObserveDomSize;
