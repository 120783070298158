const {cs} = require("./chain-services");
const {createElement: h, Fragment} = require("react");
const {UseState} = require("./use-state");
const {TimedAction} = require("./timed-action");

const DebounceCache = ({state, debug = false, delay = 500, disableAutoFlush, next}) =>
    cs(["cache", (_, next) => UseState({next})], ({cache}) =>
        h(
            Fragment,
            {},
            cache.value &&
                TimedAction({
                    time: cache.value.time + delay,
                    action: () => {
                        if (disableAutoFlush?.(cache.value?.value)) {
                            return;
                        }

                        if (debug) {
                            console.log("flush cache");
                        }

                        if (state.onChange) {
                            state.onChange(cache.value?.value, ...(cache.value?.others ?? []));
                        }
                        cache.onChange(null);
                    },
                }),

            next({
                state: {
                    value: !cache?.value ? state?.value : cache.value.value,
                    change: (reducer, ...others) =>
                        cache.change((cacheState) => {
                            const {value} = cacheState ?? {value: state?.value};
                            return {time: Date.now(), value: reducer(value ?? state?.value), others};
                        }),
                    onChange: (value) =>
                        cache.onChange({
                            time: Date.now(),
                            value,
                        }),
                },
                active: !!cache.value,
                flush: () => {
                    if (cache.value) {
                        if (disableAutoFlush?.(cache.value.value)) {
                            return;
                        }

                        state.onChange(cache.value.value);
                        cache.onChange(null);
                    }
                },
            })
        )
    );
exports.DebounceCache = DebounceCache;
