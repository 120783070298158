import React from "react";
import {cx} from "emotion";
import {cs} from "@common/react/chain-services";
import {SuspendUpdate} from "@common/react/suspend-update";
import {DsClearErrorDialog} from "../../common/clear-error-dialog/ds-clear-error-dialog";
import {ButtonDropdown} from "../../../common/button-dropdown/button-dropdown";
import NotSyncIcon from "../../../../../assets/icons/data-sources/NotSyncIcon";
import PauseIcon from "../../../../../assets/icons/data-sources/PauseIcon";
import ResumeSyncIcon from "../../../../../assets/icons/data-sources/ResumeSyncIcon";
import InSyncIcon from "../../../../../assets/icons/data-sources/InSyncIcon";
import {cascadeChange} from "@common/utils/cascade";
import {chain} from "@common/utils/fs";
import {first, sort, unique} from "@common/utils/collections";
import {SyncMethodDialog} from "../common/sync-method-dialog/sync-method-dialog";
import {syncScheduleDefault} from "../../../add/default-data-source";
import {ChooseIntervalDialog} from "../../../add/wizard/steps/tables-columns/wizard/steps/tables/choose-interval/choose-interval-dialog";
import {InfoDialog} from "../../../../common/info-dialog/info-dialog";
import {tooltipService3} from "../../../../common/tooltip3/tooltip-service-3";
import {noun} from "@common/utils/plural";
import {Checkbox} from "@common/ui-components/form/checkbox/checkbox";
import {isBlank} from "@common/utils/strings";
import {Button} from "@common/form/buttons/button/button";
import TableIcon from "assets/icons/common/TableIcon";
import {decorateColumnsData} from "./sync-columns-decorate-data";

export const SyncSettingsHeader = ({adding, searchVal, selectedTables, dataSource, onReload, $type, interactions}) =>
    cs(() => (
        <div className={cx("settings-headers", {adding: adding})}>
            {isBlank(searchVal) && (
                <div className="header">
                    {cs(() => {
                        const tables = (dataSource.value.tables || [])?.filter((t) => !t?.isChildTable);
                        const disabledTables = (dataSource.value.disabledTables || [])?.filter((t) => !t?.isChildTable);
                        let checked = () => (selectedTables.value.length === tables.length + disabledTables.length ? true : selectedTables.value.length > 0 ? "partial" : false);

                        return Checkbox({
                            state: {
                                value: checked(),
                                onChange: () => {
                                    if (checked() === "partial" || checked() === false) {
                                        selectedTables.onChange([...dataSource.value?.tables, ...(dataSource.value?.disabledTables || [])]);
                                    } else {
                                        selectedTables.onChange([]);
                                    }
                                },
                            },
                        });
                    })}

                    <div className="label">{adding ? `3. ` : ``} Sync Settings</div>
                </div>
            )}

            <div
                className={cx("actions", {
                    active: selectedTables.value.length > 0,
                })}
            >
                {cs(
                    (_, next) =>
                        SuspendUpdate({
                            active: selectedTables.value.length > 0,
                            next,
                        }),

                    (_, next) => (selectedTables.value.length > 0 ? next() : null),
                    ["selectedTablesNames", (_, next) => next(selectedTables.value.map((t) => t.name))],
                    [
                        "updatedSelectedTables",
                        ({selectedTablesNames}, next) =>
                            next([...(dataSource.value?.tables || []), ...(dataSource.value?.disabledTables || [])].filter((t) => selectedTablesNames.includes(t.name))),
                    ],
                    ["tableErrorsIDs", (_, next) => next((dataSource.value?.errors || []).map((x) => x.dataSourceTableID))],
                    [
                        "onDisabledSync",
                        ({selectedTablesNames}, next) =>
                            next(() => {
                                dataSource.change((ds) => ({
                                    ...ds,
                                    tables: ds.tables.filter((t) => selectedTablesNames.indexOf(t.name) === -1),
                                    disabledTables: [...ds.disabledTables, ...ds.tables.filter((t) => selectedTablesNames.indexOf(t.name) > -1)],
                                }));
                            }),
                    ],

                    [
                        "allDisabledSelected",
                        ({updatedSelectedTables}, next) =>
                            next(
                                updatedSelectedTables.filter((s) => (dataSource.value?.disabledTables || []).map((t) => t.name).includes(s.name)).length ===
                                    updatedSelectedTables.length
                            ),
                    ],

                    ({tableErrorsIDs, selectedTablesNames, onDisabledSync, updatedSelectedTables, allDisabledSelected}) => (
                        <>
                            {cs(
                                (_, next) => (
                                    <div className="buttons">{next()}</div>
                                ),
                                ["clearErrorsDialog", (_, next) => DsClearErrorDialog({next})],
                                ({clearErrorsDialog}, next) => {
                                    const errorTablesIDsSelected = selectedTables.value.filter((s) => tableErrorsIDs.indexOf(s.id) > -1).map((s) => s.id);

                                    return errorTablesIDsSelected.length > 0 ? (
                                        <>
                                            {ButtonDropdown({
                                                size: "medium",
                                                label: "Sync State",
                                                list: [
                                                    {
                                                        value: "Disabled",
                                                        label: (
                                                            <>
                                                                {NotSyncIcon({})}
                                                                Disable Sync / Delete Data
                                                            </>
                                                        ),
                                                    },
                                                ],
                                                valueToLabel: (row) => row.label,
                                                onChange: (t) => {
                                                    switch (t.value) {
                                                        case "Disabled": {
                                                            onDisabledSync();
                                                        }
                                                    }
                                                },
                                            })}

                                            <Button
                                                btnType="danger"
                                                onClick={async () => {
                                                    const resp = await clearErrorsDialog.show({
                                                        dsID: dataSource.value.id,
                                                        goSyncSettings: null,
                                                        errorTablesIDsSelected,
                                                    });

                                                    if (resp) {
                                                        onReload();
                                                    }
                                                }}
                                            >
                                                Clear Errors
                                            </Button>
                                        </>
                                    ) : (
                                        next()
                                    );
                                },
                                (_, next) =>
                                    allDisabledSelected ? (
                                        <Button
                                            onClick={() => {
                                                dataSource.change((ds) => ({
                                                    ...ds,
                                                    disabledTables: ds.disabledTables.filter((t) => selectedTablesNames.indexOf(t.name) === -1),
                                                    tables: [
                                                        ...ds.tables,
                                                        ...ds.disabledTables
                                                            .filter((t) => selectedTablesNames.indexOf(t.name) > -1)
                                                            .map((t) => ({
                                                                ...t,
                                                                columns: decorateColumnsData(t.columns),
                                                                syncSchedule: {
                                                                    ...t.syncSchedule,
                                                                    $type: "SyncSchedule",
                                                                },
                                                            })),
                                                    ],
                                                }));
                                            }}
                                        >
                                            Enable Sync
                                        </Button>
                                    ) : (
                                        next()
                                    ),
                                () =>
                                    ButtonDropdown({
                                        size: "medium",
                                        label: "Sync State",
                                        list: [
                                            {
                                                value: "Pause",
                                                label: (
                                                    <>
                                                        {PauseIcon({})}
                                                        Pause
                                                    </>
                                                ),
                                                condition: updatedSelectedTables.findIndex((s) => s.syncSchedule?.$type === "SyncSchedule") > -1,
                                            },
                                            {
                                                value: "Unpause",
                                                label: (
                                                    <>
                                                        {ResumeSyncIcon({})}
                                                        Unpause
                                                    </>
                                                ),
                                                condition: updatedSelectedTables.findIndex((s) => s.paused) > -1,
                                            },
                                            {
                                                value: "EnableSync",
                                                label: (
                                                    <>
                                                        {InSyncIcon({})}
                                                        Enable Sync
                                                    </>
                                                ),
                                                condition:
                                                    updatedSelectedTables.findIndex((s) => (dataSource.value?.disabledTables || []).map((t) => t.name).includes(s.name)) > -1,
                                            },
                                            {
                                                value: "Disabled",
                                                label: (
                                                    <>
                                                        {NotSyncIcon({})}
                                                        Disable Sync / Delete Data
                                                    </>
                                                ),
                                                condition: true,
                                            },
                                        ].filter((l) => l.condition),
                                        valueToLabel: (row) => row.label,
                                        onChange: (t) => {
                                            const togglePause = (paused) => {
                                                dataSource.change((ds) => ({
                                                    ...ds,
                                                    tables: cascadeChange(ds.tables, "[*]", (t) => {
                                                        return {
                                                            ...t,
                                                            paused: selectedTablesNames.indexOf(t.name) > -1 ? paused : t.paused,
                                                            syncSchedule: {
                                                                ...t.syncSchedule,
                                                                // $type: selectedTablesNames.indexOf(t.name) > -1 ? (paused ? "OneTimeSync" : "SyncSchedule") : t.syncSchedule?.$type
                                                            },
                                                        };
                                                    }),
                                                }));
                                            };

                                            const enableSync = () => {
                                                const _disabledTables = updatedSelectedTables.filter((s) =>
                                                    (dataSource.value?.disabledTables || []).map((t) => t.name).includes(s.name)
                                                );

                                                dataSource.change((ds) => ({
                                                    ...ds,
                                                    disabledTables: ds.disabledTables.filter((t) => _disabledTables.findIndex((t1) => t1.name === t.name) === -1),
                                                    tables: chain(
                                                        [
                                                            ..._disabledTables.map((t) => ({
                                                                ...t,
                                                                columns: decorateColumnsData(t.columns),
                                                                syncSchedule: {
                                                                    ...t.syncSchedule,
                                                                    $type: "SyncSchedule",
                                                                },
                                                            })),
                                                            ...ds.tables,
                                                        ],
                                                        (_) => sort(_, (t) => t.name)
                                                    ),
                                                }));
                                            };

                                            switch (t.value) {
                                                case "EnableSync": {
                                                    enableSync();
                                                    break;
                                                }
                                                case "Pause": {
                                                    togglePause(true);
                                                    break;
                                                }
                                                case "Unpause": {
                                                    togglePause(false);
                                                    break;
                                                }
                                                case "Disabled": {
                                                    onDisabledSync();
                                                }
                                            }
                                        },
                                    })
                            )}

                            {$type === "Table" && selectedTables.value.length === 1 && !selectedTables.value[0].disabled && (
                                <Button
                                    className="view-columns"
                                    btnType="secondary"
                                    onClick={() =>
                                        interactions.change((oldInteractions) => ({
                                            ...oldInteractions,
                                            selectedTable: selectedTables.value[0],
                                        }))
                                    }
                                >
                                    View Columns
                                </Button>
                            )}

                            {!allDisabledSelected && !(updatedSelectedTables.findIndex((s) => (dataSource.value?.disabledTables || []).map((t) => t.name).includes(s.name)) > -1) && (
                                <div className="buttons border">
                                    {cs(["syncMethodDialog", (_, next) => SyncMethodDialog({next})], ({syncMethodDialog}) => (
                                        <Button
                                            btnType="secondary"
                                            onClick={async () => {
                                                const tablesChanges = selectedTables.value.map((st) => st.name);
                                                const _selectedTables = dataSource.value.tables.filter((t) => tablesChanges.includes(t.name));
                                                const methods = unique(_selectedTables.map((v) => v.syncMethod));

                                                const resp = await syncMethodDialog.show({
                                                    value: _selectedTables.length > 0 && methods.length === 1 ? methods[0] : null,
                                                    tables: _selectedTables,
                                                });

                                                if (resp) {
                                                    // const tablesChanges = selectedTables.value.map((st) => st.name);
                                                    dataSource.change((ds) => ({
                                                        ...ds,
                                                        tables: cascadeChange(ds.tables, "[*]", (t1) => {
                                                            if (tablesChanges.indexOf(t1.name) > -1) {
                                                                const defaultSyncSchedule = syncScheduleDefault[resp.syncMethod];
                                                                return {
                                                                    ...t1,
                                                                    ...(t1.syncSchedule.$type !== "OneTimeSync"
                                                                        ? {
                                                                              syncMethod: resp.syncMethod,
                                                                              syncSchedule: {
                                                                                  ...defaultSyncSchedule,
                                                                                  ...(t1.syncSchedule || {}),
                                                                              },
                                                                              ...(resp.incrementalColumn && t1.columns.find((c) => c.name === resp.incrementalColumn)
                                                                                  ? {
                                                                                        columns: cascadeChange(t1.columns, "[*]", (column) => ({
                                                                                            ...column,
                                                                                            incrementalColumn: column.name === resp.incrementalColumn,
                                                                                        })),
                                                                                    }
                                                                                  : {}),
                                                                          }
                                                                        : {}),
                                                                };
                                                            }
                                                            return t1;
                                                        }),
                                                    }));
                                                }
                                            }}
                                        >
                                            Sync Method
                                        </Button>
                                    ))}

                                    {cs(
                                        [
                                            "chooseIntervalDialog",
                                            (_, next) =>
                                                ChooseIntervalDialog({
                                                    next,
                                                }),
                                        ],
                                        ["infoDialog", (_, next) => InfoDialog({next})],
                                        ({chooseIntervalDialog, infoDialog}) => {
                                            const tablesChanges = selectedTables.value.map((st) => st.name);
                                            const _selectedTables = dataSource.value.tables.filter((t) => tablesChanges.includes(t.name));

                                            const syncTables = _selectedTables.filter((t) => !t.disabled && t.syncMethod && t.syncMethod != "None");
                                            const onSync = async () => {
                                                // const tablesChanges = selectedTables.value.map((st) => st.name);
                                                // const _selectedTables = dataSource.value.tables.filter((t) =>
                                                //     tablesChanges.includes(t.name)
                                                // );
                                                //
                                                // const syncTables = _selectedTables.filter(
                                                //     (t) => !t.disabled && t.syncMethod && t.syncMethod != "None"
                                                // );
                                                const tablesHasSyncMethod = syncTables.filter((t) => t.syncMethod === "Full");
                                                const syncSchedules = unique(
                                                    _selectedTables.map((v) => v.syncSchedule),
                                                    (v) => JSON.stringify(v)
                                                );

                                                const resp = await chooseIntervalDialog.show({
                                                    ...(syncSchedules.length === 1
                                                        ? {
                                                              value: syncSchedules[0],
                                                              syncMethod: _selectedTables[0]?.syncMethod,
                                                          }
                                                        : {
                                                              value: {
                                                                  $type: "SyncSchedule",
                                                                  onDays: "All",
                                                                  duringHours: "All",
                                                              },
                                                          }),
                                                    // showWarning: hasSyncMethodFull,
                                                    title: cs(
                                                        tooltipService3({
                                                            direction: "below",
                                                        }),
                                                        ({tooltip}) => (
                                                            <>
                                                                Sync Interval for{" "}
                                                                {syncTables.length === 1 ? (
                                                                    first(syncTables).name
                                                                ) : (
                                                                    <span
                                                                        {...tooltip(() => (
                                                                            <>
                                                                                {syncTables
                                                                                    .map((t) => t.name)
                                                                                    .slice(0, 10)
                                                                                    .join(", ")}

                                                                                {syncTables.length > 10 && (
                                                                                    <>
                                                                                        <br />
                                                                                        <div
                                                                                            style={{
                                                                                                fontStyle: "italic",
                                                                                            }}
                                                                                        >
                                                                                            (only the first 10 are shown)
                                                                                        </div>
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        ))}
                                                                    >
                                                                        {`${syncTables.length} Tables`}
                                                                    </span>
                                                                )}
                                                            </>
                                                        )
                                                    ),
                                                });

                                                if (resp) {
                                                    if (resp.interval === "Minutes" && tablesHasSyncMethod.length > 0) {
                                                        infoDialog.show({
                                                            title: "Update Sync Interval",
                                                            content: (
                                                                <>
                                                                    <div className="text">
                                                                        This sync interval cannot be applied to the following tables because it is not available when the sync
                                                                        method is set to Full.
                                                                    </div>
                                                                    {tablesHasSyncMethod.map((t) => (
                                                                        <div className="table-line">
                                                                            {TableIcon({})}
                                                                            {t.name}
                                                                        </div>
                                                                    ))}
                                                                </>
                                                            ),
                                                        });
                                                    }

                                                    const syncTablesUpdate = chain(
                                                        syncTables,
                                                        (_) => (resp.interval === "Minutes" ? _.filter((b) => b.syncMethod !== "Full") : _),
                                                        (_) => _.map((t) => t.name)
                                                    );

                                                    dataSource.change((ds) => ({
                                                        ...ds,
                                                        tables: cascadeChange(ds.tables, "[*]", (t1) => {
                                                            if (syncTablesUpdate.indexOf(t1.name) > -1) {
                                                                return {
                                                                    ...t1,
                                                                    syncSchedule: resp,
                                                                };
                                                            }
                                                            return t1;
                                                        }),
                                                    }));
                                                }
                                            };

                                            return (
                                                <Button btnType="secondary" onClick={onSync} disabled={syncTables.length === 0}>
                                                    Sync Interval
                                                </Button>
                                            );
                                        }
                                    )}
                                </div>
                            )}

                            <div className="separator" />

                            <div className="selecteds">
                                {selectedTables.value.length} {noun(selectedTables.value.length, $type)} Selected
                                <span className="material-icons-outlined icon-close" onClick={() => selectedTables.onChange([])}>
                                    close
                                </span>
                            </div>
                        </>
                    )
                )}
            </div>
        </div>
    ));
