import "./slack-integration-dialog.scss";

import React from "react";
import {cx} from "emotion";

import {Load} from "@common/react/load";
import {Load2} from "@common/react/load2";
import {Invoke} from "@common/react/invoke";
import {scope} from "@common/react/scope";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {spc} from "@common/react/state-path-change";
import {consumeContext} from "@common/react/context";

import {Button} from "../../../../../../common/form/buttons/button/button";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";
import {SearchableSelect} from "@common/ui-components/searchable-select/searchable-select";
import {LoadingIndicator} from "@common/ui-components/loading-indicator/loading-indicator";
import {DialogService} from "../../../common/dialog/dialog-service";
import {RadioLineGroup} from "../../../common/radio-line-group/radio-line-group";
import {CheckboxLineGroup} from "../../../common/checkbox-line-group/checkbox-line-group";

export const SlackIntegrationDialog = ({next: rootNext, notifications, config, integrations}) =>
    cs(
        consumeContext("apis"),
        consumeContext("tenant"),
        consumeContext("toast"),
        [
            "channels",
            ({apis}, next) =>
                Load({
                    next,
                    fetch: apis.tenant.getSlackChannels,
                }),
        ],
        [
            "modal",
            (_, next) =>
                DialogService({
                    render: ({resolve}) => ({
                        width: 545,
                        title: "Configure Your Slack Connection",
                        content: next({
                            resolve,
                        }),
                    }),
                    next: rootNext,
                }),
        ],
        [
            "state",
            (_, next) =>
                UseState({
                    next,
                    initValue: {
                        notification: null,
                        loading: true,
                        isSelectedAll: false,
                    },
                }),
        ],
        ({channels, state, tenant: {environments}}, next) => {
            if (!state.value.notification && notifications.value) {
                return Invoke({
                    fn: async () => {
                        const currentNoti =
                            notifications.value?.length > 0
                                ? notifications.value.find((n) => n.$type == "SlackNotification" && n.environmentIDs.length > 0) ?? notifications.value[0]
                                : {
                                      isUnsaved: true,
                                      $type: "SlackNotification",
                                      channelId: null,
                                      channelName: null,
                                      notificationTypes: [1, 2],
                                      environmentIDs: environments.map((e) => e.id),
                                  };
                        state.onChange({
                            loading: false,
                            isSelectedAll: currentNoti.environmentIDs.length == environments.length,
                            notification: currentNoti,
                        });
                    },
                });
            }

            return next();
        },
        ({modal, apis, channels, state, tenant: {environments}, toast}) => {
            const loading = scope(state, ["loading"]);
            const notification = scope(state, ["notification"]);
            const isSelectedAll = scope(state, ["isSelectedAll"]);

            const disconnectSlack = async () => {
                loading.onChange(true);
                await apis.tenant.deleteIntegration(config.id);
                await integrations.reload();
                loading.onChange(false);
                modal.resolve();
            };

            const saveConfig = async () => {
                const {isUnsaved, ...other} = notification.value;
                if (!other.channelId) {
                    return;
                }

                loading.onChange(true);

                if (isUnsaved) {
                    await apis.tenantEnv.addNotification(other);
                } else {
                    await apis.tenantEnv.updateNotification(other);
                }

                // const otherNotifications = notifications.value
                //     .filter(n => n.channelId != notification.value.channelId && n.environmentIDs.length > 0)
                //     .map(n => ({...n, environmentIDs: []}))
                // ;
                //
                // await Promise.all(otherNotifications.map(apis.tenantEnv.updateNotification));
                await Promise.all([integrations.reload(), notifications.reload()]);

                toast.show("Saved Configurations");

                loading.onChange(false);
                modal.resolve();
            };

            return (
                <div className="slack-integration-dialog-88s">
                    {!notifications.value || !channels ? (
                        <div className="content">{LoadingIndicator({})}</div>
                    ) : (
                        <div className="content">
                            {SearchableSelect({
                                placeholder: "Select a channel",
                                label: "Channel",
                                list: channels,
                                isSelected: (v) => v.id == notification.value?.channelId,
                                onChange: (v) =>
                                    notification.change((n) => ({
                                        ...n,
                                        channelId: v.id,
                                        channelName: v.name,
                                    })),
                                valueToLabel: (v) => v.name,
                            })}

                            <div className="container">
                                <div className="left-side">
                                    <div className="label">ENVIRONMENTS</div>

                                    {RadioLineGroup({
                                        list: [
                                            {
                                                label: "All Environments",
                                                value: true,
                                            },
                                            {
                                                label: "Selected Environments",
                                                value: false,
                                            },
                                        ],
                                        state: {
                                            value: isSelectedAll.value,
                                            onChange: (value) => {
                                                isSelectedAll.onChange(value);
                                                if (value) {
                                                    spc(notification, ["environmentIDs"], () => environments.map((e) => e.id));
                                                }
                                            },
                                        },
                                    })}
                                </div>

                                <div className="divider" />

                                <div className={cx("right-side", isSelectedAll.value && "disabled")}>
                                    <div className="label">Select environments to connect</div>

                                    <VerbScrollbar maxHeight={300}>
                                        {CheckboxLineGroup({
                                            disabled: isSelectedAll.value,
                                            list: environments.map((e) => ({
                                                ...e,
                                                label: e.name,
                                            })),
                                            isSelected: (item) => notification.value.environmentIDs.includes(item.id),
                                            onChange: (item) => {
                                                const isSelected = notification.value.environmentIDs.includes(item.id);
                                                spc(notification, ["environmentIDs"], (ids) => (isSelected ? ids.filter((t) => t != item.id) : ids.concat(item.id)));
                                            },
                                        })}
                                    </VerbScrollbar>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="buttons">
                        <Button btnType="danger" disabled={loading.value} onClick={disconnectSlack}>
                            Disconnect
                        </Button>
                        <div className="space" />
                        <Button
                            btnType="secondary"
                            onClick={() => {
                                modal.resolve();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button disabled={loading.value || !notification.value?.channelId} onClick={saveConfig}>
                            Save
                        </Button>
                    </div>
                </div>
            );
        }
    );
