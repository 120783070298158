// todo: apply barCornerRadius from tile's style

const getThemeBorderRadiusOptions = ({barChartCornerRadius, barChartCornerRadiusLocation}) => {
    if (barChartCornerRadius > 0) {
        const location = barChartCornerRadiusLocation || "Top";

        if (location == "All") {
            return {
                borderRadiusTopLeft: barChartCornerRadius,
                borderRadiusTopRight: barChartCornerRadius,
                borderRadiusBottomRight: barChartCornerRadius,
                borderRadiusBottomLeft: barChartCornerRadius,
            };
        } else {
            return {
                borderRadiusTopLeft: barChartCornerRadius,
                borderRadiusTopRight: barChartCornerRadius,
                borderRadiusBottomRight: 0,
                borderRadiusBottomLeft: 0,
            };
        }
    }

    return {
        borderRadiusTopLeft: 0,
        borderRadiusTopRight: 0,
        borderRadiusBottomRight: 0,
        borderRadiusBottomLeft: 0,
    };
};

const getSeriesBorderRadiusOptions = (index, seriesLength, theme) => {
    const axisChartsStyle = theme.dataVisualization.axisCharts;

    if (axisChartsStyle.barChartCornerRadius > 0) {
        const location = axisChartsStyle.barChartCornerRadiusLocation || "Top";

        if (index === 0) {
            return {
                borderRadiusTopLeft: axisChartsStyle.barChartCornerRadius,
                borderRadiusTopRight: axisChartsStyle.barChartCornerRadius,
            };
        }

        if (location == "All" && index === seriesLength - 1) {
            return {
                borderRadiusBottomRight: axisChartsStyle.barChartCornerRadius,
                borderRadiusBottomLeft: axisChartsStyle.barChartCornerRadius,
            };
        }
    }

    return {
        borderRadiusTopLeft: 0,
        borderRadiusTopRight: 0,
        borderRadiusBottomRight: 0,
        borderRadiusBottomLeft: 0,
    };
};

exports.getThemeBorderRadiusOptions = getThemeBorderRadiusOptions;
exports.getSeriesBorderRadiusOptions = getSeriesBorderRadiusOptions;
