import React from "react";
import "./ghost-button.scss";
import {cx} from "emotion";

// Preview: https://www.figma.com/file/lLyR8h0WfMXZsnVjeXKtgJ/Components?node-id=2505%3A14305
//size: "medium" | "small",

export const GhostButton = ({iconLeft, iconRight, children, disabled, className, size = "medium", ...otherProps}) => (
    <button className={cx("verb-ghost-button", className, `size-${size}`)} disabled={disabled} {...otherProps}>
        {iconLeft && <span className="icon-left">{iconLeft}</span>}

        {children}

        {iconRight && <span className="icon-right">{iconRight}</span>}
    </button>
);
