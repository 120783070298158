import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {Form2} from "@common/react/cs-form/form2";
import {getTileFormConfig} from "../../collection/tile/edit/tiles-form-config/get-tile-form-config";
import {Watch} from "@common/react/watch";
import {equalDeep} from "@common/utils/objects";
import {keyed} from "@common/react/keyed";
import {Invoke} from "@common/react/invoke";
import * as React from "react";
import {themeFormConfig} from "./theme-form-config";

export const EditThemeLogic = ({updateFunc, allowInvalidUpdates = false, theme, next}) =>
    cs(
        [
            "localTheme",
            (_, next) => {
                return UseState({
                    initValue: {
                        value: theme,
                        lastUpdatedAt: null,
                    },
                    next: ({onChange, value}) =>
                        next({
                            value: {
                                ...value,
                                value: value.value ?? theme,
                            },
                            flush: () => onChange({}),
                            onChange: ({value, lastUpdatedAt}) => {
                                onChange({
                                    value,
                                    lastUpdatedAt,
                                });
                            },
                        }),
                });
            },
        ],

        [
            "form",
            ({localTheme}, next) =>
                Form2({
                    data: {
                        value: localTheme.value.value,
                        onChange: (v) => {
                            localTheme.onChange({
                                value: v,
                                lastUpdatedAt: Date.now(),
                            });
                        },
                    },
                    ...themeFormConfig(),
                    next,
                    initShowErrors: true,
                }),
        ],

        ({localTheme, tilePath, savingQueue, form}, next) =>
            cs(["lastSave", (_, next) => UseState({next})], ({lastSave}) => (
                <>
                    {next()}
                    {localTheme.value.lastUpdatedAt &&
                        localTheme.value.value &&
                        (!lastSave.value || lastSave.value < localTheme.value.lastUpdatedAt) &&
                        (allowInvalidUpdates ? true : form.valid) &&
                        cs(keyed(localTheme.value.lastUpdatedAt), () =>
                            Invoke({
                                fn: async ({isMounted}) => {
                                    updateFunc?.(localTheme.value.value);
                                    if (isMounted()) {
                                        localTheme.flush();
                                        lastSave.onChange(localTheme.value.lastUpdatedAt);
                                    }
                                },
                            })
                        )}
                </>
            )),
        ({form}) => next(form)
    );
