import {UseState} from "@common/react/use-state";
import {cs} from "@common/react/chain-services";
import {arrMapToO} from "@common/utils/objects";
import {ObserveProgressService} from "../../edit/layout/publish-button/observe-progress-service";
import {Invoke} from "@common/react/invoke";
import {consumeContext} from "@common/react/context";
import {NewPublishProcess} from "../../edit/layout/publish-button/publish-button";
import {keyed} from "@common/react/keyed";

const BulkPublishService = ({collectionIds, next}) => {
    return cs(
        [
            "bulkPublishStatus",
            (_, next) =>
                UseState({
                    initValue: arrMapToO(collectionIds, () => ({status: null, progress: null})),
                    next,
                }),
        ],
        ["newBulkPublish", (_, next) => UseState({next})],
        ({bulkPublishStatus, newBulkPublish}) => {
            return (
                <>
                    {next({
                        status: bulkPublishStatus.value,
                        publish: (colIds) => newBulkPublish.change((nbp) => [...(nbp || []), ...colIds]),
                    })}

                    {collectionIds.map((cid) =>
                        EachCollectionPublish({
                            collectionId: cid,
                            publishStatus: bulkPublishStatus.value?.[cid],
                            onChangePublishStatus: (v) =>
                                bulkPublishStatus.change((bps) => ({
                                    ...bps,
                                    [cid]: {...bps[cid], status: v},
                                })),
                            onProgress: ({progress, loading}) =>
                                bulkPublishStatus.change((bps) => ({
                                    ...bps,
                                    [cid]: {...bps[cid], progress, loading},
                                })),
                            newPublish: newBulkPublish.value?.findIndex((cid1) => cid === cid1) > -1,
                            onDoneNewPublish: () => newBulkPublish.change((nbp) => nbp.filter((cid1) => cid1 !== cid)),
                        })
                    )}
                </>
            );
        }
    );
};
export default BulkPublishService;

const EachCollectionPublish = ({collectionId, onChangePublishStatus, publishStatus, newPublish, onDoneNewPublish, onProgress}) => {
    return cs(
        keyed(collectionId),
        consumeContext("apis"),
        ["confirming", (_, next) => UseState({next})],
        [
            "publishProcessObserver",
            ({apis, confirming}, next) => {
                return ObserveProgressService({
                    fetch: () =>
                        apis.collection.getPublishInfo({
                            collectionId,
                        }),
                    calculateProgress: (info) => info.complete / info.total,
                    onProgress,
                    checkDone: (info) => info?.status === "Success",
                    onDone: (info, {processType}) => {
                        if (processType === "new-process") {
                            confirming.onChange(true);
                            setTimeout(() => confirming.onChange(false), 500);
                            onChangePublishStatus(info);
                        }

                        if (processType === "remote-process") {
                            onChangePublishStatus(info);
                        }
                    },
                    checkError: (info) => info?.status === "Error",
                    onError: (info) => {
                        onChangePublishStatus(info);
                    },
                    onApiError: () => {},
                    next,
                });
            },
        ],
        ({publishProcessObserver}) => {
            return (
                <>
                    {/* check publish status or if a remote publish process is running */}
                    {publishStatus.status == null &&
                        Invoke({
                            fn: () => {
                                publishProcessObserver.start({
                                    args: {
                                        processType: "remote-process",
                                    },
                                });
                            },
                        })}

                    {/* mount new publish process when new publish is triggered */}
                    {newPublish &&
                        NewPublishProcess({
                            collectionId,
                            publishProcessObserver,
                            unmount: () => onDoneNewPublish(),
                        })}
                </>
            );
        }
    );
};
