exports.currencies = [
    {
        name: "Brazilian Real",
        abbreviation: "BRL",
        symbol: "R$",
        symbolPlacement: "Left",
        numericDecimalSeperator: "Dot",
        numericThousandsSeperator: "Comma",
        spaceBetweenSymbolAndNumber: false,
        region: "Americas",
    },
    {
        name: "Canadian Dollars",
        abbreviation: "CAD",
        symbol: "$",
        symbolPlacement: "Left",
        numericDecimalSeperator: "Dot",
        numericThousandsSeperator: "Comma",
        spaceBetweenSymbolAndNumber: false,
        region: "Americas",
    },
    {
        name: "Chilean Peso",
        abbreviation: "CLP",
        symbol: "$",
        symbolPlacement: "Left",
        numericDecimalSeperator: "Dot",
        numericThousandsSeperator: "Comma",
        spaceBetweenSymbolAndNumber: false,
        region: "Americas",
    },
    {
        name: "Colombian Peso",
        abbreviation: "COP",
        symbol: "$",
        symbolPlacement: "Left",
        numericDecimalSeperator: "Dot",
        numericThousandsSeperator: "Comma",
        spaceBetweenSymbolAndNumber: false,
        region: "Americas",
    },
    {
        name: "Mexican Peso",
        abbreviation: "MXN",
        symbol: "$",
        symbolPlacement: "Left",
        numericDecimalSeperator: "Dot",
        numericThousandsSeperator: "Comma",
        spaceBetweenSymbolAndNumber: false,
        region: "Americas",
    },
    {
        name: "Peruvian Sol",
        abbreviation: "PEN",
        symbol: "S",
        symbolPlacement: "Left",
        numericDecimalSeperator: "Dot",
        numericThousandsSeperator: "Comma",
        spaceBetweenSymbolAndNumber: false,
        region: "Americas",
    },
    {
        name: "US Dollar",
        abbreviation: "USD",
        symbol: "$",
        symbolPlacement: "Left",
        numericDecimalSeperator: "Dot",
        numericThousandsSeperator: "Comma",
        spaceBetweenSymbolAndNumber: false,
        region: "Americas",
    },
    {
        name: "Emirati Dirham",
        abbreviation: "AED",
        symbol: "د.إ",
        symbolPlacement: "Right",
        numericDecimalSeperator: "Dot",
        numericThousandsSeperator: "Comma",
        spaceBetweenSymbolAndNumber: false,
        region: "MiddleEastAndAfrica",
    },
    {
        name: "Israeli Shekel",
        abbreviation: "ILS",
        symbol: "₪",
        symbolPlacement: "Left",
        numericDecimalSeperator: "Dot",
        numericThousandsSeperator: "Comma",
        spaceBetweenSymbolAndNumber: false,
        region: "MiddleEastAndAfrica",
    },
    {
        name: "Kenyan Shilling",
        abbreviation: "KES",
        symbol: "Ksh",
        symbolPlacement: "Left",
        numericDecimalSeperator: "Dot",
        numericThousandsSeperator: "Comma",
        spaceBetweenSymbolAndNumber: false,
        region: "MiddleEastAndAfrica",
    },
    {
        name: "Moroccan Dirham",
        abbreviation: "MAD",
        symbol: ".د.م",
        symbolPlacement: "Right",
        numericDecimalSeperator: "Dot",
        numericThousandsSeperator: "Comma",
        spaceBetweenSymbolAndNumber: false,
        region: "MiddleEastAndAfrica",
    },
    {
        name: "Nigerian Naira",
        abbreviation: "NGN",
        symbol: "₦",
        symbolPlacement: "Left",
        numericDecimalSeperator: "Dot",
        numericThousandsSeperator: "Comma",
        spaceBetweenSymbolAndNumber: false,
        region: "MiddleEastAndAfrica",
    },
    {
        name: "South African Rand**",
        abbreviation: "ZAR",
        symbol: "R",
        symbolPlacement: "Left",
        numericDecimalSeperator: "Dot",
        numericThousandsSeperator: "Comma",
        spaceBetweenSymbolAndNumber: false,
        region: "MiddleEastAndAfrica",
    },
    {
        name: "Bulgarian Lev",
        abbreviation: "BGN",
        symbol: "лв",
        symbolPlacement: "Left",
        numericDecimalSeperator: "Dot",
        numericThousandsSeperator: "Comma",
        spaceBetweenSymbolAndNumber: false,
        region: "Europe",
    },
    {
        name: "Swiss Franc",
        abbreviation: "CHF",
        symbol: "CHF",
        symbolPlacement: "Left",
        numericDecimalSeperator: "Dot",
        numericThousandsSeperator: "Comma",
        spaceBetweenSymbolAndNumber: false,
        region: "Europe",
    },
    {
        name: "Czech Koruna",
        abbreviation: "CZK",
        symbol: "Kč",
        symbolPlacement: "Right",
        numericDecimalSeperator: "Dot",
        numericThousandsSeperator: "Comma",
        spaceBetweenSymbolAndNumber: false,
        region: "Europe",
    },
    {
        name: "Danish Krone",
        abbreviation: "DKK",
        symbol: "kr",
        symbolPlacement: "Left",
        numericDecimalSeperator: "Dot",
        numericThousandsSeperator: "Comma",
        spaceBetweenSymbolAndNumber: false,
        region: "Europe",
    },
    {
        name: "Euro",
        abbreviation: "EUR",
        symbol: "€",
        symbolPlacement: "Left",
        numericDecimalSeperator: "Dot",
        numericThousandsSeperator: "Comma",
        spaceBetweenSymbolAndNumber: false,
        region: "Europe",
    },
    {
        name: "Pounds Sterling",
        abbreviation: "GBP",
        symbol: "£",
        symbolPlacement: "Left",
        numericDecimalSeperator: "Dot",
        numericThousandsSeperator: "Comma",
        spaceBetweenSymbolAndNumber: false,
        region: "Europe",
    },
    {
        name: "Croatian Kuna",
        abbreviation: "HRK",
        symbol: "kn",
        symbolPlacement: "Left",
        numericDecimalSeperator: "Dot",
        numericThousandsSeperator: "Comma",
        spaceBetweenSymbolAndNumber: false,
        region: "Europe",
    },
    {
        name: "Georgian Lari",
        abbreviation: "GEL",
        symbol: "₾",
        symbolPlacement: "Left",
        numericDecimalSeperator: "Dot",
        numericThousandsSeperator: "Comma",
        spaceBetweenSymbolAndNumber: false,
        region: "Europe",
    },
    {
        name: "Hungarian Forint",
        abbreviation: "HUF",
        symbol: "ft",
        symbolPlacement: "Right",
        numericDecimalSeperator: "Dot",
        numericThousandsSeperator: "Comma",
        spaceBetweenSymbolAndNumber: false,
        region: "Europe",
    },
    {
        name: "Norwegian Krone",
        abbreviation: "NOK",
        symbol: "kr",
        symbolPlacement: "Left",
        numericDecimalSeperator: "Dot",
        numericThousandsSeperator: "Comma",
        spaceBetweenSymbolAndNumber: false,
        region: "Europe",
    },
    {
        name: "Polish Zloty",
        abbreviation: "PLN",
        symbol: "zł",
        symbolPlacement: "Right",
        numericDecimalSeperator: "Dot",
        numericThousandsSeperator: "Comma",
        spaceBetweenSymbolAndNumber: false,
        region: "Europe",
    },
    {
        name: "Russian Ruble",
        abbreviation: "RUB",
        symbol: "₽",
        symbolPlacement: "Right",
        numericDecimalSeperator: "Dot",
        numericThousandsSeperator: "Comma",
        spaceBetweenSymbolAndNumber: false,
        region: "Europe",
    },
    {
        name: "Romanian Leu",
        abbreviation: "RON",
        symbol: "lei",
        symbolPlacement: "Left",
        numericDecimalSeperator: "Dot",
        numericThousandsSeperator: "Comma",
        spaceBetweenSymbolAndNumber: false,
        region: "Europe",
    },
    {
        name: "Swedish Krona",
        abbreviation: "SEK",
        symbol: "kr",
        symbolPlacement: "Right",
        numericDecimalSeperator: "Dot",
        numericThousandsSeperator: "Comma",
        spaceBetweenSymbolAndNumber: false,
        region: "Europe",
    },
    {
        name: "Turkish Lira",
        abbreviation: "TRY",
        symbol: "₺",
        symbolPlacement: "Right",
        numericDecimalSeperator: "Dot",
        numericThousandsSeperator: "Comma",
        spaceBetweenSymbolAndNumber: false,
        region: "Europe",
    },
    {
        name: "Ukrainian Hryvna",
        abbreviation: "UAH",
        symbol: "₴",
        symbolPlacement: "Left",
        numericDecimalSeperator: "Dot",
        numericThousandsSeperator: "Comma",
        spaceBetweenSymbolAndNumber: false,
        region: "Europe",
    },
    {
        name: "Australian Dollars",
        abbreviation: "AUD",
        symbol: "$",
        symbolPlacement: "Left",
        numericDecimalSeperator: "Dot",
        numericThousandsSeperator: "Comma",
        spaceBetweenSymbolAndNumber: false,
        region: "AsiaPacific",
    },
    {
        name: "Bangladeshi Taka",
        abbreviation: "BDT",
        symbol: "৳",
        symbolPlacement: "Left",
        numericDecimalSeperator: "Dot",
        numericThousandsSeperator: "Comma",
        spaceBetweenSymbolAndNumber: false,
        region: "AsiaPacific",
    },
    {
        name: "Chinese Yuan",
        abbreviation: "CNY",
        symbol: "¥",
        symbolPlacement: "Left",
        numericDecimalSeperator: "Dot",
        numericThousandsSeperator: "Comma",
        spaceBetweenSymbolAndNumber: false,
        region: "AsiaPacific",
    },
    {
        name: "Hong Kong Dollar",
        abbreviation: "HKD",
        symbol: "HK$",
        symbolPlacement: "Left",
        numericDecimalSeperator: "Dot",
        numericThousandsSeperator: "Comma",
        spaceBetweenSymbolAndNumber: false,
        region: "AsiaPacific",
    },
    {
        name: "Indonesian Rupiah",
        abbreviation: "IDR",
        symbol: "Rp",
        symbolPlacement: "Left",
        numericDecimalSeperator: "Dot",
        numericThousandsSeperator: "Comma",
        spaceBetweenSymbolAndNumber: false,
        region: "AsiaPacific",
    },
    {
        name: "Indian Rupee",
        abbreviation: "INR",
        symbol: "₹",
        symbolPlacement: "Left",
        numericDecimalSeperator: "Dot",
        numericThousandsSeperator: "Comma",
        spaceBetweenSymbolAndNumber: false,
        region: "AsiaPacific",
    },
    {
        name: "Japanese Yen",
        abbreviation: "JPY",
        symbol: "¥",
        symbolPlacement: "Left",
        numericDecimalSeperator: "Dot",
        numericThousandsSeperator: "Comma",
        spaceBetweenSymbolAndNumber: false,
        region: "AsiaPacific",
    },
    {
        name: "Malaysian Ringgit",
        abbreviation: "MYR",
        symbol: "RM",
        symbolPlacement: "Left",
        numericDecimalSeperator: "Dot",
        numericThousandsSeperator: "Comma",
        spaceBetweenSymbolAndNumber: false,
        region: "AsiaPacific",
    },
    {
        name: "New Zealand Dollar",
        abbreviation: "NZD",
        symbol: "$",
        symbolPlacement: "Left",
        numericDecimalSeperator: "Dot",
        numericThousandsSeperator: "Comma",
        spaceBetweenSymbolAndNumber: false,
        region: "AsiaPacific",
    },
    {
        name: "Philippine Peso",
        abbreviation: "PHP",
        symbol: "₱",
        symbolPlacement: "Left",
        numericDecimalSeperator: "Dot",
        numericThousandsSeperator: "Comma",
        spaceBetweenSymbolAndNumber: false,
        region: "AsiaPacific",
    },
    {
        name: "Pakistani Rupee",
        abbreviation: "PKR",
        symbol: "Rs",
        symbolPlacement: "Left",
        numericDecimalSeperator: "Dot",
        numericThousandsSeperator: "Comma",
        spaceBetweenSymbolAndNumber: false,
        region: "AsiaPacific",
    },
    {
        name: "Singapore Dollar",
        abbreviation: "SGD",
        symbol: "$",
        symbolPlacement: "Left",
        numericDecimalSeperator: "Dot",
        numericThousandsSeperator: "Comma",
        spaceBetweenSymbolAndNumber: false,
        region: "AsiaPacific",
    },
    {
        name: "South Korean Won",
        abbreviation: "KRW",
        symbol: "₩",
        symbolPlacement: "Left",
        numericDecimalSeperator: "Dot",
        numericThousandsSeperator: "Comma",
        spaceBetweenSymbolAndNumber: false,
        region: "AsiaPacific",
    },
    {
        name: "Sri Lankan Rupee",
        abbreviation: "LKR",
        symbol: "Rs",
        symbolPlacement: "Left",
        numericDecimalSeperator: "Dot",
        numericThousandsSeperator: "Comma",
        spaceBetweenSymbolAndNumber: false,
        region: "AsiaPacific",
    },
    {
        name: "Thai Baht",
        abbreviation: "THB",
        symbol: "฿",
        symbolPlacement: "Right",
        numericDecimalSeperator: "Dot",
        numericThousandsSeperator: "Comma",
        spaceBetweenSymbolAndNumber: false,
        region: "AsiaPacific",
    },
    {
        name: "Vietnamese Dong",
        abbreviation: "VND",
        symbol: "₫",
        symbolPlacement: "Right",
        numericDecimalSeperator: "Dot",
        numericThousandsSeperator: "Comma",
        spaceBetweenSymbolAndNumber: false,
        region: "AsiaPacific",
    },
    {
        name: "Bitcoin",
        abbreviation: "BTC",
        symbol: "₿",
        symbolPlacement: "Left",
        numericDecimalSeperator: "Dot",
        numericThousandsSeperator: "Comma",
        spaceBetweenSymbolAndNumber: false,
        region: "Cryptocurrencies",
    },
    {
        name: "Ripples",
        abbreviation: "XRP",
        symbol: "XRP",
        symbolPlacement: "Left",
        numericDecimalSeperator: "Dot",
        numericThousandsSeperator: "Comma",
        spaceBetweenSymbolAndNumber: false,
        region: "Cryptocurrencies",
    },
    {
        name: "Monero",
        abbreviation: "XMR",
        symbol: "ɱ",
        symbolPlacement: "Left",
        numericDecimalSeperator: "Dot",
        numericThousandsSeperator: "Comma",
        spaceBetweenSymbolAndNumber: false,
        region: "Cryptocurrencies",
    },
    {
        name: "Litecoin",
        abbreviation: "LTC",
        symbol: "Ł",
        symbolPlacement: "Left",
        numericDecimalSeperator: "Dot",
        numericThousandsSeperator: "Comma",
        spaceBetweenSymbolAndNumber: false,
        region: "Cryptocurrencies",
    },
    {
        name: "Ethereum",
        abbreviation: "ETH",
        symbol: "Ξ",
        symbolPlacement: "Left",
        numericDecimalSeperator: "Dot",
        numericThousandsSeperator: "Comma",
        spaceBetweenSymbolAndNumber: false,
        region: "Cryptocurrencies",
    },
];
