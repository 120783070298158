import "./edit-api-collection-layout.scss";
import "../../../collection/edit/layout/edit-collection-layout.scss";

import React from "react";
import {cx} from "emotion";

import {Load2} from "@common/react/load2";
import {Invoke} from "@common/react/invoke";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {consumeContext} from "@common/react/context";
import {SuspendUpdate} from "@common/react/suspend-update";

import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";

import {Button} from "../../../../../../common/form/buttons/button/button";
import {ButtonIconWithTooltip} from "../../../../../../common/form/buttons/button-icon/button-icon";

import {Badge} from "../../../common/badge/badge";
import {HeaderBar} from "../../../common/header-bar/header-bar";
import {LeftPanelTabs} from "../../../common/left-panel-tabs/left-panel-tabs";
import {settingKey} from "../../../dashboard/env/settings/settings-constants";
import {
    ManageAccessKeyDialog
} from "../../../dashboard/env/settings/data-access-key/manage-key/manage-access-key-dialog";

import {PublishApiDialog} from "./publish-dialog/publish-api-dialog";
import {ApiCollectionShareDialog} from "./share-dialog/api-collection-share-dialog";
import {ShareIcon} from "../../../dashboard/env/overview/icons/share-icon";
import {SelectViewProfile} from "../../../collection/preview/select-view-profile/select-view-profile";
import {AutoRefreshBadge, AutoRefreshSettingBtns} from "../../../collection/edit/layout/auto-refresh-settings";
import {
    CollectionPublishBtn,
    publishProcessProvider
} from "../../../collection/edit/layout/publish-button/publish-button";

export const EditApiCollectionLayout = ({
                                            saving,
                                            leftPanelTabs,
                                            panelOverride,
                                            selectedTab,
                                            main,
                                            collection,
                                            collectionCommands
                                        }) =>
  cs(
    consumeContext("tenant"),
    consumeContext("apis"),
    consumeContext("routing"),
    consumeContext("routeTransitioning"),
    consumeContext("viewAsContext"),
    consumeContext("collectionToast"),
    [
        "collections",
        ({apis, routing}, next) =>
          Load2({
              _key: routing.params?.envId,
              fetch: () => apis.collection.getApiCollectionsGroup(),
              next,
          }),
    ],
    ["collectionVersion", (_, next) => UseState({next, initValue: collection.value.version})],
    ["publishDialog", (_, next) => PublishApiDialog({next})],
    ["shareDialog", (_, next) => ApiCollectionShareDialog({next})],
    ["manageKeyDialog", (_, next) => ManageAccessKeyDialog({next})],
    ({walkThrough}, next) => publishProcessProvider({walkThrough: null, next}),
    ({
         routeTransitioning,
         collections,
         routing,
         publishDialog,
         collectionVersion,
         shareDialog,
         manageKeyDialog,
         viewAsContext,
         collectionToast
     }) => {
        const {folders, iframeKeys, viewAs} = viewAsContext || {};

        const {autoDataRefresh} = collection?.value ?? {};
        const isEnabledAutoRefresh = autoDataRefresh == null ? true : autoDataRefresh;

        return (
          <div
            className={cx(
              "edit-collection-layout-4rw",
              "edit-api-collection-layout-86y",
              {transitioning: routeTransitioning?.transitioning},
              routeTransitioning?.transitioning
            )}
          >
              {HeaderBar({
                  ID: collection.value?.id,
                  type: "collection",
                  commands: collectionCommands,
                  name: collection.value?.name ? `${collection.value.name} | Version ${collection.value.version}` : null,
                  previousRoutes: ["dashboard", "edit-data-source", "edit-model"],
                  shareActions: () => (
                    <>
                        {(iframeKeys?.value || folders?.value) &&
                          cs(() => {
                              const keys = iframeKeys.value?.filter((k) => k.collections.indexOf(collection?.value?.id) > -1);
                              if (keys?.length === 0 && folders.value?.length == 0) return null;
                              return SelectViewProfile({
                                  keys,
                                  viewAs,
                                  folders,
                              });
                          })}

                        <div className="space"/>

                        {AutoRefreshBadge({isEnabledAutoRefresh})}

                        {saving ? (
                          <div className={cx("save-status")}>
                              <i className="fa fa-spinner fa-pulse"/>
                              Saving
                          </div>
                        ) : (
                          Badge({
                              size: "lg",
                              icon: <i className="far fa-check"/>,
                              type: "success",
                              label: "Saved",
                          })
                        )}

                        <div className="group-buttons">
                            {AutoRefreshSettingBtns({
                                collectionToast,
                                isEnabledAutoRefresh,
                                collection,
                            })}

                            <ButtonIconWithTooltip
                              tooltipClassName="tooltip-white-f3z small"
                              content="Share"
                              icon={<ShareIcon fill="#919EB0"/>}
                              className="btn"
                              size="medium"
                              btnType="ghost-no-border"
                              onClick={async () => {
                                  const resp = await shareDialog.show({
                                      $type: settingKey.DATA_ACCESS,
                                  });
                                  if (resp) {
                                      manageKeyDialog.show({
                                          $type: settingKey.DATA_ACCESS,
                                          accessKey: resp,
                                          editStep: 2,
                                          collections: collections,
                                      });
                                  }
                              }}
                            />
                        </div>

                        <div className="group-buttons">

                            {CollectionPublishBtn({
                                beforeRun: async () => {
                                    const data = await publishDialog.show({});
                                    if (data?.version) {
                                        collectionVersion.onChange(data?.version);
                                        return true;
                                    }
                                    return false;
                                }
                            })}


                        </div>

                        {Invoke({
                            fn: async () => {
                                if (routing.params.action == "publish") {
                                    const version = await publishDialog.show({});
                                    if (version) {
                                        collectionVersion.onChange(version);
                                    }
                                }

                                if (routing.params.action == "share") {
                                    const resp = await shareDialog.show({
                                        $type: settingKey.DATA_ACCESS,
                                    });
                                    if (resp) {
                                        manageKeyDialog.show({
                                            $type: settingKey.DATA_ACCESS,
                                            accessKey: resp,
                                            editStep: 2,
                                            collections: collections.value,
                                        });
                                    }
                                }
                            },
                        })}
                    </>
                  ),
              })}

              <div className="center">
                  <div className="left-panel">
                      <div
                        className={cx("main", {
                            active: !panelOverride?.leftPanel,
                        })}
                      >
                          <LeftPanelTabs
                            {...{
                                tabs: leftPanelTabs,
                                selected: selectedTab,
                            }}
                          />
                      </div>

                      <div
                        className={cx("override", {
                            active: panelOverride?.leftPanel,
                        })}
                      >
                          {cs(
                            ({}, next) =>
                              SuspendUpdate({
                                  active: !panelOverride?.leftPanel,
                                  next,
                              }),
                            () => panelOverride?.leftPanel
                          )}
                      </div>
                  </div>

                  <div className="content">
                      <VerbScrollbar className="absolute-box">{panelOverride?.main ?? main}</VerbScrollbar>
                  </div>

                  {collectionToast.render()}
              </div>
          </div>
        );
    }
  );
