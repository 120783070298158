import React from "react";

const ArrowChervonDown = ({className}) => (
    <svg className={className} width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.99974 5.37188L11.9497 0.421875L13.3637 1.83587L6.99974 8.19988L0.635742 1.83587L2.04974 0.421875L6.99974 5.37188Z" fill="#919EB0" />
    </svg>
);

export default ArrowChervonDown;

export const ArrowChervonRight = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.9766 9.9994L7.85156 5.8744L9.0299 4.69607L14.3332 9.9994L9.0299 15.3027L7.85156 14.1244L11.9766 9.9994Z" fill="#919EB0" />
    </svg>
);
