import React from "react";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import "./collapsible.scss";
import {Static2} from "@common/react/static-2";
import {cx} from "emotion";
import {Watch} from "@common/react/watch";
import {ArrowChervonDown, ArrowChervonUp} from "@common/ui-components/charts/common/icons/icons";

export const Collapsible = ({children, renderHeader, open, separatedStyle = false, className}) =>
    cs(
        ["isOpen", (_, next) => UseState({next, initValue: open.value})],
        ["animation", (_, next) => UseState({next, initValue: false})],
        ["containerHeight", (_, next) => UseState({next, initValue: 0})],
        ["collapseBodyRef", (_, next) => Static2({next})],
        [
            "toggleOpen",
            ({containerHeight, collapseBodyRef, animation, isOpen}, next) =>
                next((action) => {
                    if (!animation.value) {
                        animation.onChange(true);
                        if (!action) {
                            isOpen.onChange(false);
                            setTimeout(() => {
                                containerHeight.onChange(0);
                            });
                        } else {
                            isOpen.onChange(true);
                            containerHeight.onChange(collapseBodyRef.get().scrollHeight);
                        }

                        setTimeout(() => {
                            animation.onChange(false);
                        }, 255);
                    }
                }),
        ],
        ({containerHeight, collapseBodyRef, toggleOpen, animation, isOpen}) => (
            <div
                className={cx("collapsible-as3 collapsible", className, {
                    separated: separatedStyle,
                    collapsed: !open.value,
                })}
            >
                <div className="c-header" onClick={() => open.onChange(!open.value)}>
                    {renderHeader()}

                    <div className="arrow-toggle">{!open.value ? ArrowChervonDown({}) : ArrowChervonUp()}</div>
                </div>

                {Watch({
                    value: open.value,
                    onChanged: async (currentValue) => {
                        toggleOpen(currentValue);
                    },
                })}

                <div
                    className={cx("c-body", isOpen.value && "open", animation.value && "animation")}
                    style={{
                        maxHeight: animation.value ? `${containerHeight.value}px` : isOpen.value ? "" : 0,
                        opacity: isOpen.value ? 1 : 0,
                    }}
                    ref={collapseBodyRef.set}
                >
                    {children}
                </div>
            </div>
        )
    );
