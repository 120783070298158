const cc1 = (value, chain) => {
    if (chain === undefined) {
        chain = value;
        value = undefined;
    }
    return cc(value, chain);
};

const cc = (value, chain) => {
    const [first, ...last] = chain;
    return first(value, (newValue) => {
        return cc(newValue, last);
    });
};
exports.cc = cc1;
exports.cbc = cc1;
exports.cc1 = cc1;
