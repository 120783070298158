exports.sampleEnvironments = [
    {
        id: "c57c0db1-7310-42cb-8474-9b360cad3845",
        name: "Production",
        isProd: true,
    },
    {
        id: "c57c0db1-7310-42cb-8474-9b360cad3849",
        name: "Staging",
        isProd: false,
    },
];
