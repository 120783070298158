import {getFieldType} from "@common/ui-components/charts/common/get-field-type";
import {arrMapToO} from "../../../../../../../../../../../common/utils/objects";
import {isSameField} from "@common/ui-components/charts/factory/is-same-field";
import {getLimitUpdated} from "../common/limit-sorting/limit/get-limit-updated";

export const getDataTypeBasedMeasureAttrs = (tile) => {
    let ret = {numericMeasureAttrs: [], nonNumericMeasureAttrs: []};

    ["xAxisField", "yAxisField"].forEach((attr) => {
        if (!tile[attr]) {
            return;
        }
        if (getFieldType(tile[attr]) !== "number") {
            return ret.nonNumericMeasureAttrs.push(attr);
        }
        ret.numericMeasureAttrs.push(attr);
    });

    return ret;
};

export const updateScatterPlotDimension = async ({newDimension, oldDimension, tile, dimensionDialog}) => {
    const {numericMeasureAttrs, nonNumericMeasureAttrs} = getDataTypeBasedMeasureAttrs(tile.value);

    //--- remove dimension
    if (newDimension == null && oldDimension) {
        // if there is no non-numeric measure then just remove dimension field
        // and change the aggregationFunc of numeric measures to None
        if (nonNumericMeasureAttrs.length === 0) {
            return tile.onChange({
                ...tile.value,
                dimensionField: null,
                // change the aggregationFunc of numeric measures to None
                ...arrMapToO(numericMeasureAttrs, (attr) => ({
                    ...tile.value[attr],
                    aggregationFunc: "None",
                })),
                // remove limit if it is dimension field
                ...getLimitUpdated({
                    tile,
                    fieldVal: {newVal: null, oriVal: oldDimension},
                }),
            });
        }

        // else, if there are non-numeric measures then ask before removing
        const res = await dimensionDialog.show({
            type: "remove",
            tile: tile.value,
            nonNumericMeasureAttrs,
        });

        if (!res) {
            return;
        }

        return tile.onChange({
            ...tile.value,
            dimensionField: null,
            // remove non-numeric measures
            ...arrMapToO(nonNumericMeasureAttrs, () => null),
            // remove limit that is non-numeric measures or dimension
            ...(() => {
                if (!tile.value.limit) {
                    return {};
                }
                [...nonNumericMeasureAttrs, "dimensionField"].forEach((attr) => {
                    if (isSameField(tile.value[attr], tile.value.limit)) {
                        return {limit: null};
                    }
                });
            })(),
            // change the aggregationFunc of numeric measures to None
            ...arrMapToO(numericMeasureAttrs, (attr) => ({
                ...tile.value[attr],
                aggregationFunc: "None",
            })),
        });
    }
    //---

    //--- add dimension
    if (newDimension && oldDimension == null) {
        if (numericMeasureAttrs.length > 0) {
            const res = await dimensionDialog.show({
                type: "add",
                tile: tile.value,
            });

            if (!res) {
                return;
            }

            return tile.onChange({
                ...tile.value,
                dimensionField: newDimension,
                // change the aggregationFunc of numeric measures to Sum
                ...arrMapToO(numericMeasureAttrs, (attr) => ({
                    ...tile.value[attr],
                    aggregationFunc: "Sum",
                })),
            });
        }

        return tile.onChange({
            ...tile.value,
            dimensionField: newDimension,
        });
    }
    //---

    //--- change dimension field
    tile.onChange({
        ...tile.value,
        dimensionField: newDimension,
        // update limit if it is dimension field
        limit: isSameField(tile.value.limit, oldDimension) ? null : tile.value.limit,
    });
};
