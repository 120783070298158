import React from "react";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {DropdownSelect2} from "@common/ui-components/dropdown-select/dropdown-select2";
import "./value-replacements.scss";
import {spc} from "@common/react/state-path-change";
import {ListOfCustomValues} from "./list-of-custom-values/list-of-custom-values";
import {SearchInTable} from "./search-in-table/search-in-table";
import {keepOnly} from "@common/utils/objects";
import {ffToDropdown} from "../../../../../../../../../../common/form/ff-to-dropdown";

export const ValueReplacements = ({step, form, model}) =>
    cs(consumeContext("getModelColumn"), ({getModelColumn}) => {
        const processors = {
            MapValuesStaticValueLookup: ListOfCustomValues,
            MapValuesModelTableValueLookup: SearchInTable,
        };

        const colToMap = step.value.columnToMap?.$type === "ModelStepColumn" ? getModelColumn(step.value.columnToMap) : step.value.columnToMap;

        return (
            <>
                <div className="config-group">
                    <div className="label">Value replacements</div>
                    {DropdownSelect2({
                        label: "Type",
                        list: [
                            {
                                label: "List of Custom Values",
                                $type: "MapValuesStaticValueLookup",
                            },
                            {
                                label: "Search in a Table",
                                $type: "MapValuesModelTableValueLookup",
                            },
                        ],
                        valueToLabel: (v) => v.label,
                        isSelected: (v) => step.value.valueLookup?.$type === v.$type,
                        onChange: (v) => {
                            spc(step, ["valueLookup"], () => ({
                                $type: v.$type,
                                // ...(v.$type === "MapValuesStaticValueLookup" && {
                                //     valueMappings: [
                                //         ["Double", "Int"].includes(colToMap.dataType) ? {} : {compare: "Equals"}
                                //     ],
                                // })
                            }));
                        },
                        ...keepOnly(ffToDropdown(form.field(["valueLookup"])), ["domRef", "hasError", "errorMessage"]),
                    })}
                </div>

                {step.value.valueLookup &&
                    processors[step.value.valueLookup.$type]({
                        step,
                        form,
                        model,
                        colMapDataType: colToMap?.dataType,
                    })}
            </>
        );
    });
