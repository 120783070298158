const {cGetFontSize} = require("../../common/style-map/font-size");
const {chain} = require("../../../../utils/fs");

const writeCenterLabel = ({chart, theme, style, formatters}) => {
    const elemName = "donutCenterLabel";

    chart[elemName]?.destroy();

    if (!style.centerDisplay || style.centerDisplay === "Nothing") {
        return;
    }

    const formatter = style.autoRound ? formatters.valueRoundFormatter : formatters.valueFormatter;

    const commonCssStr = getCenterLabelStyles(theme);

    const gFontSize = (type) =>
        cGetFontSize(
            theme.general.canvas.fontSize,
            theme
        )({
            group: style.centerLabelFontSize,
            elemType: style.centerDisplay.startsWith(type) ? "label" : "other",
        });

    const rLabel = () => style.centerDisplay.includes("Label") && `<div style="${commonCssStr}; font-size: ${gFontSize("Label")}px">` + `${style.centerLabel || ""}` + `</div>`;
    const rTotal = () => style.centerDisplay.includes("Total") && `<div style="${commonCssStr}; font-weight: 500; font-size: ${gFontSize("Total")}px">` + `${formatter(chart.series[0].total)}` + `</div>`;
    const donutHole = {
        x: chart.series[0].center[0] + chart.plotLeft,
        y: chart.series[0].center[1] + chart.plotTop,
    };

    const content =
        `<div style="width: max-content; text-align: center">` +
        chain(
            [rLabel(), rTotal()],
            (_) => (style.centerDisplay === "LabelThenTotal" ? _ : _.reverse()),
            (_) => _.filter((v) => v).join(" ")
        ) +
        `</div>`;
    chart[elemName] = chart.renderer.text(content, donutHole.x, donutHole.y, true).hide().add();

    const {x, y} = getAdjustedLabelDims({
        labelElem: chart[elemName],
        center: donutHole,
    });

    chart[elemName].attr({x, y}).show();
};
exports.writeCenterLabel = writeCenterLabel;

const getCenterLabelStyles = (theme) =>
    [
        `font-family: ${theme.dataVisualization.fonts.fontFamily || theme.general.canvas.fontFamily}, sans-serif`,
        // "font-weight: 500",
        `color: ${theme.dataVisualization.fonts.fontColorRGB || theme.general.canvas.fontColorRGB}`,
        `text-shadow: -1px 0 ${theme.general.tile.styles.tileBackgroundColorRGB}, 0 1px ${theme.general.tile.styles.tileBackgroundColorRGB}, 1px 0 ${theme.general.tile.styles.tileBackgroundColorRGB}, 0 -1px ${theme.general.tile.styles.tileBackgroundColorRGB}`,
    ].join("; ");
exports.getCenterLabelStyles = getCenterLabelStyles;

const getAdjustedLabelDims = ({labelElem, center}) => {
    const bbox = labelElem.getBBox();
    return {
        ...bbox,
        x: center.x - bbox.width / 2,
        y: center.y - bbox.height / 4,
    };
};
exports.getAdjustedLabelDims = getAdjustedLabelDims;
