import React from "react";
import {cs} from "@common/react/chain-services";
import "./textarea-input.scss";
import {DebounceCache} from "@common/react/debounce-cache";
import {bindInput} from "@common/react/bind-input";

export const TextareaInput = ({state = {value: "", onChange: () => {}}}) =>
    cs(
        [
            "debounce",
            (_, next) =>
                DebounceCache({
                    state,
                    next,
                }),
        ],
        ({debounce}) => <textarea className="textarea-7sd" cols="30" rows="4" {...{...bindInput(debounce.state)}} />
    );
