import React from "react";
import {cs} from "@common/react/chain-services";
import {DialogService} from "../../../../../../../../common/dialog/dialog-service";
import "./cloud-connections-dialog.scss";
import {GDrivePicker} from "../../../../../../../../../common/gdrive-picker/gdrive-picker";
import {UseState} from "@common/react/use-state";
import {consumeContext} from "@common/react/context";
import {Button} from "../../../../../../../../../../../common/form/buttons/button/button";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";

export const CloudConnectionsDialog = ({dsApi, next: rootNext}) =>
    cs(
        consumeContext("apis"),
        consumeContext("auth"),
        ["selectedFile", (_, next) => UseState({initValue: null, next})],
        [
            "modal",
            ({}, next) =>
                DialogService({
                    render: ({resolve, args: {type, groupType}}) => ({
                        title: `Connect a Google ${type === `GoogleSheets` ? `Spreadsheet` : `Drive`}`,
                        content: next({resolve, type, groupType}),
                    }),
                    next: rootNext,
                }),
        ],
        [
            "ggDrivePicker",
            ({selectedFile, apis, auth, modal}, next) => {
                const submit = async (code) => await apis.authEndpoint.authorizeTenantGGDrive(code);

                const upload = (file) => {
                    let submitD = {
                        fileID: file.id,
                        authorizingUserID: auth.user.id,
                        $type: "GoogleSheetsConnectionDetails",
                    };
                    const data = {
                        submit: submitD,
                        dataSource: {
                            connectionDetails: submitD,
                            name: file.name,
                            type: "GoogleSheets",
                            groupType: modal.groupType,
                        },
                    };

                    dsApi.testConnection(
                        {
                            submitConnectionDetails: submitD,
                            connectionDetails: submitD,
                        },
                        {
                            connectionDetails: submitD,
                            name: file.name,
                            type: "GoogleSheets",
                            groupType: modal.groupType,
                        },
                        true
                    );
                    modal.resolve();
                };

                return GDrivePicker({
                    next,
                    onChange: upload,
                    submit,
                    afterLoadPicker: () => {},
                });
            },
        ],
        ({ggDrivePicker, modal}) => {
            return (
                <div className="cloud-connections-dialog-6kj">
                    <VerbDialogBodyScrollbar>
                        <div className="text">
                            You will be prompted to connect a Google account then select the sheet you would like to sync with Verb. <br />
                            <br />
                            Once connected you will be able to select the tabs and columns that you would like to sync and the frequency the sheet data is synced with Verb. <br />
                            <br />
                            Please ensure that your sheet tabs are clearly named and column headers are applied.
                        </div>

                        <div className="connect">
                            <Button
                                className="google"
                                onClick={() => {
                                    ggDrivePicker.show({type: modal.type});
                                }}
                            >
                                &nbsp;
                            </Button>
                        </div>
                    </VerbDialogBodyScrollbar>

                    <div className="buttons">
                        <Button btnType="secondary" onClick={modal.resolve}>
                            Cancel
                        </Button>
                    </div>
                </div>
            );
        }
    );
