import {cs} from "@common/react/chain-services";
import {Routing} from "../../routes/common/routing/routing";
// import {ValueTransition2} from "@common/react/transitioning/value-transition-2";
import {provideContext} from "@common/react/context";

export const loadRouting = ({routes, getDelays, defaultParams, getDefaultRoute, next}) =>
    cs(
        [
            "routing",
            ({tenant}, next) =>
                Routing({
                    defaultParams,
                    routes,
                    getDefaultRoute,
                    next,
                }),
        ],

        // ["transition", ({routing}, next) => ValueTransition2({
        //     value: routing,
        //     isSame: (r1, r2) => r1.route === r2.route,
        //     getDelays,
        //     next,
        // })],

        ({routing}, next) => provideContext("routing", routing, next),
        ({routing}) => next(routing)

        // ({transition}, next) => provideContext("routeTransitioning", transition, next),
        //
        // ({transition}, next) => provideContext("routing", transition.value, next),
        // ({transition}) => next(transition.value),
    );
