import { cs } from "@common/react/chain-services";
import { consumeContext } from "@common/react/context";
import { scope } from "@common/react/scope";
import { Static2 } from "@common/react/static-2";
import { transformState } from "@common/react/transform-state";
import { UseState } from "@common/react/use-state";
import { Watch } from "@common/react/watch";
import { DropdownSelectLive } from "@common/ui-components/live/filters/common/dropdown-select/dropdown-select-live";
import "@common/ui-components/live/live-dashboard/live-filters/filter-types/date/expand/controller/time-picker/time-picker-inputs.scss";
import {
    getMaxTime,
    getMinTime,
    timepickerGettingOut,
    timePickerGoingIn
} from "@common/ui-components/live/live-dashboard/live-filters/filter-types/date/expand/controller/time-picker/time-picker-utils";
import { az } from "@common/utils/common";
import { css } from "emotion";

export const TimePickerInputs = ({state}) => {
    return (
        <div className="time-picker-inputs-a37">
            {TimePickerInput({
                state: transformState({
                    state: scope(state, ["from"]),
                    goingIn: timePickerGoingIn,
                    gettingOut: timepickerGettingOut,
                }),
                maxTime: timePickerGoingIn(state.value.to),
            })}

            {TimePickerInput({
                state: transformState({
                    state: scope(state, ["to"]),
                    goingIn: timePickerGoingIn,
                    gettingOut: timepickerGettingOut,
                }),
                minTime: timePickerGoingIn(state.value.from),
            })}
        </div>
    );
};

const TimePickerInput = ({state, maxTime, minTime}) =>
    cs(
        consumeContext("theme"),
        ["hourInputRef", (_, next) => Static2({next})],
        ["minuteInputRef", (_, next) => Static2({next})],
        ["localState", (_, next) => UseState({next, initValue: state.value})],
        ({localState}, next) =>
            Watch({
                next,
                value: state.value,
                onChanged: (value) => {
                    localState.onChange(value);
                },
            }),
        ({theme, hourInputRef, minuteInputRef, localState}) => {
            const componentTheme = theme.general.components;
            const inputStyle = {
                border: `${componentTheme.inputBorderWidth}px solid ${componentTheme.inputBorderColorRGB}`,
                background: `${componentTheme.inputBackgroundColorRGB}`,
                color: `${componentTheme.inputTextColorRGB}`,
                borderRadius: `${componentTheme.inputCornerRadius}px`,
            };

            const cssClass = css`
                background: ${componentTheme.menuBackgroundColorRGB};
                color: ${componentTheme.menuTextColorRGB};
                &:hover {
                    background: ${componentTheme.menuHoverBackgroundColorRGB};
                }

                &.selected {
                    background: ${componentTheme.menuHoverBackgroundColorRGB};
                }
            `;

            const handleInputChange = (value, key, maxVal) => {
                const v = value.replace(/[^0-9\.]+/g, "");
                if (v == "") {
                    localState.onChange({
                        ...localState.value,
                        [key]: "",
                    });
                } else {
                    const number = parseInt(v);

                    if (number >= 2) {
                        localState.onChange({
                            ...localState.value,
                            [key]: key == "hours" ? az(Math.min(number, maxVal)) : Math.min(number, maxVal),
                        });

                        if (key == "hours") {
                            setTimeout(() => {
                                minuteInputRef.get().select();
                            });
                        }
                    } else {
                        localState.onChange({
                            ...localState.value,
                            [key]: number,
                        });
                    }
                }
            };

            const handleKeyDown = (keyCode, stateProperty) => {
                if (stateProperty == "hours" && keyCode == "Tab") {
                    minuteInputRef.get().focus();
                    return;
                }

                const currentValue = parseInt(localState.value[stateProperty]);
                const maxVal = stateProperty == "hours" ? 12 : 59;

                if (keyCode == "ArrowUp") {
                    localState.onChange({
                        ...localState.value,
                        [stateProperty]: az(Math.min(currentValue + 1, maxVal)),
                    });
                }

                if (keyCode == "ArrowDown") {
                    localState.onChange({
                        ...localState.value,
                        [stateProperty]: az(Math.max(currentValue - 1, 0)),
                    });
                }
            };

            const onBlur = (key) => {
                const updated = {
                    ...localState.value,
                    [key]: az(
                        localState.value[key] === ""
                            ? parseInt(state.value[key])
                            : parseInt(localState.value[key]) === 0 && key == "hours"
                            ? 12
                            : parseInt(localState.value[key])
                    ),
                };
                
                updateMainState(updated);
            };

            const updateMainState = (updated) => {
                if (maxTime) {
                    let compared = getMinTime(updated, maxTime);
                    state.onChange(compared);
                    localState.onChange(compared);
                } else {
                    let compared = getMaxTime(updated, minTime);
                    state.onChange(compared);
                    localState.onChange(compared);
                }
            };
            return (
                <div className="time-picker-input" style={inputStyle}>
                    <div className="input-groups">
                        <input
                            style={{color: componentTheme.inputTextColorRGB}}
                            ref={hourInputRef.set}
                            value={localState.value.hours}
                            onChange={(e) => handleInputChange(e.target.value, "hours", 12)}
                            onFocus={() => {
                                hourInputRef.get().select();
                            }}
                            onBlur={() => {
                                onBlur("hours");
                            }}
                            tabIndex={0}
                            onKeyDown={(e) => {
                                if (["ArrowUp", "ArrowDown"].includes(e.key)) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }
                                handleKeyDown(e.key, "hours");
                            }}
                        />

                        <div className="split">:</div>

                        <input
                            ref={minuteInputRef.set}
                            style={{color: componentTheme.inputTextColorRGB}}
                            value={localState.value.minutes}
                            onChange={(e) => handleInputChange(e.target.value, "minutes", 59)}
                            onFocus={() => {
                                minuteInputRef.get().select();
                            }}
                            onBlur={() => {
                                onBlur("minutes");
                            }}
                            onKeyDown={(e) => {
                                if (["ArrowUp", "ArrowDown"].includes(e.key)) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }
                                handleKeyDown(e.key, "minutes");
                            }}
                        />
                    </div>

                    {DropdownSelectLive({
                        closeAllOtherDropdownWhenOpen: false,
                        prioritizeSelect: false,
                        list: ["AM", "PM"],
                        className: "select-dropdown",
                        onChange: (v) => {
                            const updated = {
                                ...localState.value,
                                isAM: v == "AM",
                            };

                            updateMainState(updated);
                        },
                        isSelected: (v) => (v == "AM" ? localState.value.isAM : !localState.value.isAM),
                        toggleStyle: {
                            ...inputStyle,
                            border: 0,
                        },
                        iconColor: componentTheme.inputIconColorRGB,
                        dropdownCss: cssClass,
                        borderRadius: componentTheme.menuCornerRadius,
                        overrideWidth: 80,
                    })}
                </div>
            );
        }
    );
