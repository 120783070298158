import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {consumeContext} from "@common/react/context";
import {fragments} from "@common/react/fragments";
import {Invoke} from "@common/react/invoke";
import {downloadBlob} from "@common/utils/browser/download-service";

export const DownloadTileData = ({tileID, collection, filterVals}) => {
    return cs(
        consumeContext("apis"),
        [
            "state",
            ({}, next) =>
                UseState({
                    next,
                    initValue: {loading: true, error: false},
                }),
        ],
        ({state, apis}) => {
            const {loading, error} = state.value;
            return fragments(
                Invoke({
                    action: async () => {
                        const {format, timeZoneId, filters} = filterVals;

                        const {tile: currentTile} = collection.gridLocations.find((g) => g.tile.id == tileID) ?? {};

                        if (!currentTile) {
                            state.onChange({
                                loading: false,
                                error: true,
                            });
                            return;
                        }

                        try {
                            const {blob, filename} = await apis.collection.downloadTileData({
                                tileId: tileID,
                                colId: collection.id,
                                filterValues: filters,
                                format,
                                timeZoneId,
                            });

                            downloadBlob(blob, {
                                name: filename || `${currentTile.title}.${new Date().toISOString()}.${format.toLowerCase()}`,
                            });
                            state.onChange({
                                loading: false,
                            });
                        } catch (e) {
                            state.onChange({
                                loading: false,
                                error: true,
                            });
                        }
                    },
                }),
                loading ? "Downloading..." : error ? "Error downloading data" : "Downloaded"
            );
        }
    );
};
