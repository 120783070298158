const {minArrayLength} = require("@common/react/cs-form/validators/min-length-array");
const {textFilter} = require("../../../../../collection/common/filters/filter-form-config/text-filter-config");
const {numericFilter} = require("../../../../../collection/common/filters/filter-form-config/numeric-filter-config");
const {dateFilter} = require("../../../../../collection/common/filters/filter-form-config/date-filter-config");

const filterFormConfig = {
    fields: {
        label: {
            debounce: true,
        },
        ...dateFilter(),
        // ...numericFilter(),
        ...textFilter(),
        ...[
            {
                path: ["columns"],
                validators: [minArrayLength(1)],
            },
        ],
    },
};
exports.filterFormConfig = filterFormConfig;
