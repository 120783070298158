import * as React from "react";

import {cs} from "@common/react/chain-services";
import {provideContext} from "@common/react/context";
import {scope} from "@common/react/scope";

import {Expandable} from "../../../../../../../common/expandable/expandable";

import {getTileActionsUpdated} from "../actions/get-tile-actions-updated";
import {getTileFields} from "./common/get-tile-fields";
import {getLimitUpdated} from "./common/limit-sorting/limit/get-limit-updated";
import {Limit} from "./common/limit-sorting/limit/limit";
import {getColumnType} from "./common/select-field/select-field";
import {SingleFieldWell} from "./common/single-field-well/single-field-well";
import {updateSingleFieldInTile} from "./common/update-tile";

export const VennDiagramFields = ({tile, isSubTile, tileActions, debugFunction, form, next}) =>
    cs(
        [
            "tileFields",
            ({}, next) =>
                next(
                    getTileFields({
                        tile: tile.value,
                        measureSingleAttrs: ["valueField"],
                        groupFieldAttr: "groupField",
                    })
                ),
        ],
        ({tileFields}, next) => provideContext("tileFields", tileFields, next),

        [
            "valueField",
            ({tileFields}, next) =>
                SingleFieldWell({
                    field: scope(tile, ["valueField"]),
                    $type: "DimensionlessMeasureTileField",
                    onUpdateTile: (newVal, oriVal) =>
                        updateSingleFieldInTile({
                            fieldAttr: "valueField",
                            fieldVal: {newVal, oriVal},
                            tileFields,
                            tile,
                            updateLimit: getLimitUpdated,
                        }),
                    label: "Value",
                    notAllowNoneAggregation: true,
                    next,
                }),
        ],
        [
            "groupField",
            ({tileFields}, next) =>
                SingleFieldWell({
                    field: scope(tile, ["groupField"]),
                    onUpdateTile: (newVal, oriVal) =>
                        updateSingleFieldInTile({
                            fieldAttr: "groupField",
                            fieldVal: {newVal, oriVal},
                            tileFields,
                            tile,
                            updateLimit: getLimitUpdated,
                            updateTileActions: getTileActionsUpdated,
                        }),
                    $type: "CategoryTileField",
                    label: "Group",
                    filterColumn: (column) => {
                        if (column.$type === "AggregatedMeasureModelColumn") {
                            return false;
                        }
                        const type = getColumnType(column);
                        return type !== "date";
                    },
                    next,
                }),
        ],

        [
            "fieldsSection",
            ({valueField, groupField}, next) =>
                next({
                    render: () =>
                        Expandable({
                            label: "Fields",
                            render: () => (
                                <>
                                    {valueField.render()}
                                    {groupField.render()}
                                </>
                            ),
                        }),
                    override: valueField.override || groupField.override,
                }),
        ],

        [
            "limit",
            ({tileFields}, next) => {
                const formField = form.field(["limit"]);
                return next({
                    render: () =>
                        Expandable({
                            initExpand: false,
                            label: "Limit",
                            render: () =>
                                Limit({
                                    field: formField.state,
                                    allFields: tileFields.allFieldsWithoutUniq,
                                    isHiddenRankOption: () => false,
                                }),
                        }),
                });
            },
        ],

        ({fieldsSection, limit}) =>
            next({
                render: () => (
                    <div className="tile-fields-3vr">
                        {fieldsSection.render()}
                        {limit.render({})}
                        {tileActions?.render?.()}
                        {isSubTile && debugFunction?.render?.({})}
                    </div>
                ),
                override:
                    fieldsSection.override ||
                    debugFunction?.override() ||
                    tileActions?.override({
                        fieldType: "DimensionlessMeasureTileField",
                    }),
                overrideAll: debugFunction?.overrideAll || tileActions?.overrideAll,
            })
    );
