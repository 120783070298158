import React from "react";

const ShowIcon = ({className, fill = "#919EB0"}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="25.259" height="20" viewBox="0 0 25.259 20">
        <path
            id="Show_Icon"
            data-name="Show Icon"
            d="M-2432.5,10c2.384-5.667,6.623-10,12.631-10s10.244,4.333,12.628,10c-2.384,5.665-6.62,10-12.628,10S-2430.115,15.665-2432.5,10Zm6.209-5.432A18.25,18.25,0,0,0-2430.247,10a15.342,15.342,0,0,0,3.957,5.431,9.92,9.92,0,0,0,6.421,2.347,9.921,9.921,0,0,0,6.422-2.347A15.2,15.2,0,0,0-2409.493,10a17.3,17.3,0,0,0-3.954-5.432,9.927,9.927,0,0,0-6.422-2.345A9.926,9.926,0,0,0-2426.29,4.568Zm1.421,5.357a4.962,4.962,0,0,1,4.963-4.963,4.961,4.961,0,0,1,4.962,4.963,4.961,4.961,0,0,1-4.962,4.963A4.962,4.962,0,0,1-2424.869,9.925Zm2.221,0a2.744,2.744,0,0,0,2.742,2.741,2.743,2.743,0,0,0,2.74-2.741,2.743,2.743,0,0,0-2.74-2.74A2.743,2.743,0,0,0-2422.647,9.925Z"
            transform="translate(2432.5)"
        />
    </svg>
);

export default ShowIcon;
