import "./select-view-profile.scss";

import {cx} from "emotion";
import React, {createElement} from "react";

import {scope} from "@common/react/scope";
import {keyed} from "@common/react/keyed";
import {Static2} from "@common/react/static-2";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {AnyAction} from "@common/react/any-action";
import {consumeContext} from "@common/react/context";

import {RadioLine} from "../../../common/radio-line/radio-line";
import {Button} from "../../../../../../common/form/buttons/button/button";
import {TextInput} from "../../../../../../common/form/text-input/text-input";

import {Dropdown} from "@common/ui-components/dropdown/dropdown";
import {PlusIcon} from "@common/ui-components/icons/global-icons";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";

import {SettingIcon} from "../../edit/layout/theme-select/theme-select-icons";
import {ManagePreviewProfileDialog} from "../../../dashboard/env/settings/preview-profiles/manage-profile/manage-preview-profile-dialog";
import {flatten1} from "@common/utils/collections";

import {ArrowDownIcon} from "../collection-preview-icons";
import SearchIcon from "assets/icons/common/SearchIcon";
import {isMatchText} from "@common/utils/strings";

export const SelectViewProfile = ({keys = [], viewAs, folders}) =>
    cs(
        consumeContext("apis"),
        consumeContext("routing"),
        consumeContext("authEndpoint"),
        ["toggleRef", (_, next) => Static2({next})],
        [
            "opened",
            (_, next) =>
                UseState({
                    initValue: false,
                    next,
                }),
        ],
        ["keyword", (_, next) => UseState({initValue: "", next})],
        ({opened}, next) =>
            createElement(AnyAction, {
                disabled: !opened.value,
                fn: () => opened.onChange(false),
                next,
            }),
        ["endpointAuthConfig", ({authEndpoint}, next) => next((authEndpoint.value || []).find((t) => t.$type === "EndpointAuthConfig") || {})],
        [
            "defaultUserValues",
            ({endpointAuthConfig}, next) => {
                let ret = {};
                if (endpointAuthConfig) {
                    for (let key in endpointAuthConfig.resultMapping) {
                        const mappingType = endpointAuthConfig.resultMapping[key].mappingType;
                        ret[key] = {
                            $type: mappingType == "SingleValue" ? "UserStringValue" : "UserArrayValue",
                            ...(mappingType == "SingleValue"
                                ? {value: ""}
                                : {
                                      values: [
                                          {
                                              $type: "UserStringValue",
                                              value: "",
                                          },
                                      ],
                                  }),
                        };
                    }
                    return next(ret);
                }
                return next(null);
            },
        ],
        ["managePreviewProfileDialog", (_, next) => ManagePreviewProfileDialog({next})],
        [
            "viewProfileDropdown",
            ({toggleRef, keyword, managePreviewProfileDialog, defaultUserValues}, next) =>
                Dropdown({
                    registryRender: true,
                    customToggleRef: toggleRef,
                    next,
                    renderExpand: ({close}) => {
                        const profiles = flatten1(folders.value, (folder) => folder.keys);
                        return (
                            <div className="select-view-profile-a33 show">
                                <div className="search-box">
                                    <div className="search-icon">
                                        <SearchIcon />
                                    </div>

                                    {TextInput({
                                        placeholder: "Search for a key or profile",
                                        state: scope(keyword),
                                    })}
                                </div>

                                <VerbScrollbar className="view-body" style={{maxHeight: 500}}>
                                    <div className="option">
                                        <RadioLine
                                            label="ADMIN"
                                            selected={viewAs?.value?.value === null}
                                            onClick={() => {
                                                viewAs.onChange({
                                                    label: "Admin",
                                                    value: null,
                                                    themeID: null,
                                                });
                                                close();
                                            }}
                                        />
                                    </div>

                                    {!!keys.length && (
                                        <>
                                            <div className="header-text">IFRAME KEYS</div>

                                            {keys
                                                .filter((item) => isMatchText(item.name, keyword.value))
                                                .sort((a, b) => (a.name > b.name ? 1 : -1))
                                                .map((key) =>
                                                    cs(keyed(key.id), () => (
                                                        <div className="option">
                                                            <RadioLine
                                                                label={key.name.toUpperCase()}
                                                                selected={viewAs?.value?.value === key.apiKey}
                                                                onClick={() => {
                                                                    viewAs.onChange({
                                                                        label: key.name,
                                                                        value: key.apiKey,
                                                                        themeID: key.themeID,
                                                                    });
                                                                    close();
                                                                }}
                                                            />
                                                        </div>
                                                    ))
                                                )}
                                        </>
                                    )}

                                    {profiles && (
                                        <>
                                            <div className="header-text">PREVIEW PROFILES</div>

                                            {profiles
                                                .filter((item) => isMatchText(item.name, keyword.value))
                                                .map((profile, index) => (
                                                    <div className="option profile" key={index}>
                                                        <RadioLine
                                                            label={profile.name}
                                                            selected={viewAs?.value?.value === profile.apiKey}
                                                            onClick={() => {
                                                                viewAs.onChange({
                                                                    label: profile.name,
                                                                    value: profile.apiKey,
                                                                });
                                                                close();
                                                            }}
                                                            content={
                                                                <SettingIcon
                                                                    className="setting-button"
                                                                    onClick={async (e) => {
                                                                        e.preventDefault();
                                                                        e.stopPropagation();
                                                                        const resp = await managePreviewProfileDialog.show({
                                                                            folders: folders.value,
                                                                            profile: profile,
                                                                        });

                                                                        if (resp) {
                                                                            folders.change((oldFolders) =>
                                                                                oldFolders.map((f) =>
                                                                                    f.id === resp.folderID
                                                                                        ? {
                                                                                              ...f,
                                                                                              keys: f.keys.map((key) => {
                                                                                                  if (key.id == resp.id) return resp;
                                                                                                  return key;
                                                                                              }),
                                                                                          }
                                                                                        : f
                                                                                )
                                                                            );
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                        />
                                                    </div>
                                                ))}

                                            <div className="button-footer">
                                                <Button
                                                    className="button"
                                                    size="medium"
                                                    onClick={async () => {
                                                        close();

                                                        const resp = await managePreviewProfileDialog.show({
                                                            folders: folders.value,
                                                            profile: {
                                                                name: "",
                                                                userValues: defaultUserValues,
                                                                folderID: null,
                                                            },
                                                        });

                                                        if (resp) {
                                                            folders.change((oldFolders) =>
                                                                oldFolders.map((f) =>
                                                                    f.id === resp.folderID
                                                                        ? {
                                                                              ...f,
                                                                              keys: [...f.keys, resp],
                                                                          }
                                                                        : f
                                                                )
                                                            );
                                                        }
                                                    }}
                                                    btnType="secondary"
                                                    iconLeft={<PlusIcon />}
                                                >
                                                    New Preview Profile
                                                </Button>
                                            </div>
                                        </>
                                    )}
                                </VerbScrollbar>
                            </div>
                        );
                    },
                }),
        ],
        ({viewProfileDropdown, toggleRef}) => {
            return (
                <div className="view-as-profile-88p">
                    <div className="view-as-col" ref={toggleRef.set}>
                        <div className="view-as-text">View As...</div>

                        <div className="select-wrapper" onClick={() => viewProfileDropdown.showExpand()}>
                            {viewAs?.value?.label} {ArrowDownIcon({})}
                        </div>
                    </div>
                </div>
            );
        }
    );
