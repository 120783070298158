import {Watch} from "@common/react/watch";
import {consumeContext} from "@common/react/context";
import {cs} from "@common/react/chain-services";

export const gaPageView = ({next}) =>
    cs(() =>
        Watch({
            // initRun: true,
            value: (() => {
                const hash = window.location.hash;
                return hash ? hash.substring(1) : "/";
            })(),
            onChanged: (page) => {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: "pageview",
                    path: page,
                });
            },
            next,
        })
    );
