import "./account-details-integrations.scss";

import React from "react";

import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {Button} from "../../../../../common/form/buttons/button/button";
import {SlackIntegrationDialog} from "./modal/slack-integration-dialog";
import {consumeContext} from "@common/react/context";
import {Load2} from "@common/react/load2";
import {Invoke} from "@common/react/invoke";
import {BasicPanel} from "@common/ui-components/panel/panel";
import {ConfirmDialog} from "../../common/confirm-dialog/confirm-dialog";
import {spc} from "@common/react/state-path-change";

export const AccountDetailsIntegrations = () => (
    <BasicPanel title="Integrations">
        {() =>
            cs(
                consumeContext("apis"),
                [
                    "integrations",
                    ({apis}, next) =>
                        Load2({
                            captureException: true,
                            next,
                            fetch: apis.tenant.getIntegrations,
                        }),
                ],
                // ({integrations}, next) => integrations.value ? next () : null,
                ({integrations}) => {
                    const slackIntegration = (integrations.value ?? []).find((i) => i.id == 1);

                    return (
                        slackIntegration &&
                        SlackIntegration({
                            integrations,
                            config: slackIntegration,
                        })
                    );
                }
            )
        }
    </BasicPanel>
);

const redirectURI = window.location.origin + `/integrations/slack/index.html`;

const getSlackEndPoint = ({clientId}) => {
    return `https://slack.com/oauth/v2/authorize?scope=channels:join,channels:read,channels:manage,chat:write&client_id=${clientId}&redirect_uri=${encodeURIComponent(
        redirectURI
    )}`;
};

const SlackIntegration = ({config, integrations}) => {
    const {configured, properties} = config;

    return cs(
        consumeContext("apis"),
        consumeContext("routing"),
        ["state", (_, next) => UseState({next, initValue: {loading: false}})],
        [
            "notifications",
            ({apis}, next) =>
                Load2({
                    next,
                    fetch: apis.tenantEnv.getNotifications,
                }),
        ],
        [
            "slackIntegrationDialog",
            ({notifications}, next) =>
                configured
                    ? SlackIntegrationDialog({
                          next,
                          integrations,
                          notifications,
                          config,
                      })
                    : next(),
        ],
        [
            "errorDialog",
            (_, next) =>
                ConfirmDialog({
                    next,
                    submitText: "Close",
                    hideCancel: true,
                    title: "Error",
                }),
        ],
        ({state, apis, routing, slackIntegrationDialog}, next) => {
            const {code, integration} = routing.params;

            if (!configured && integration == "slack" && code?.length > 0) {
                return Invoke({
                    next,
                    fn: async () => {
                        spc(state, ["loading"], () => true);
                        try {
                            await apis.tenant.addSlackIntegration({
                                code,
                                referrerUri: redirectURI,
                            });
                            await integrations.reload();
                        } catch (e) {}
                        routing.pushParams({
                            setting: "integrations",
                            envId: routing.params.envId,
                        });
                        state.onChange({
                            loading: false,
                            showDialog: true,
                        });
                    },
                });
            }

            if (configured && state.value.showDialog) {
                return Invoke({
                    next,
                    fn: async () => {
                        slackIntegrationDialog.show();
                        spc(state, ["showDialog"], () => false);
                    },
                });
            }

            return next();
        },
        ({slackIntegrationDialog, notifications, apis, routing, errorDialog, state}) => {
            return (
                <div className="account-details-integrations-09i">
                    <div className="integration">
                        <img className="slack-icon" src={require("./slack-icon.svg")} />
                        <div className="type">
                            <div className="label">Slack Alerts</div>
                        </div>

                        {configured ? (
                            <>
                                {notifications.value?.length > 0 && (
                                    <Button
                                        btnType="secondary"
                                        disabled={state.value.loading}
                                        style={{marginRight: 10}}
                                        onClick={async () => {
                                            try {
                                                const {success, errorMessage} = await apis.tenantEnv.testSlackConnection();

                                                if (success) {
                                                    errorDialog.show({
                                                        confirmTitle: "Success",
                                                        description:
                                                            "The connection is active. A test message was successfully sent to the channel.",
                                                    });
                                                } else {
                                                    errorDialog.show({
                                                        description: errorMessage,
                                                    });
                                                }
                                            } catch (e) {
                                                errorDialog.show({
                                                    description: e.message,
                                                });
                                            }
                                        }}
                                    >
                                        Test
                                    </Button>
                                )}

                                <Button btnType="primary" disabled={state.value.loading} onClick={() => slackIntegrationDialog.show()}>
                                    Edit
                                </Button>
                            </>
                        ) : (
                            <Button
                                btnType="primary"
                                disabled={state.value.loading}
                                onClick={() => {
                                    window.location = getSlackEndPoint({
                                        clientId: properties.client_id,
                                        envId: routing.params.envId,
                                    });
                                }}
                            >
                                Connect
                            </Button>
                        )}
                    </div>
                </div>
            );
        }
    );
};
