import * as React from "react";
import {cs} from "@common/react/chain-services";
import "./theme-tab.scss";
import {keyed} from "@common/react/keyed";
import {DashboardStyles} from "./dashboard-styles";
import {TextStyles} from "./text-styles";
import {TileStyles} from "./tile-styles";
import {ChartStyles} from "./chart-styles";
import {DataColors} from "./data-colors";
import {ContainerTiles} from "./container-tiles";
import {LegendStyles} from "./legend-styles";
import {ButtonStyles} from "./button-styles";
import {TableStyles} from "./table-styles";
import {ThemeName} from "./theme-name";
import {TileTitle} from "./tile-title";
import {HoverTooltips} from "./hover-tooltips";

export const ThemeTab = ({form, controls, sectionInitClosed}) =>
    cs(() => (
        <div className="theme-tab-531">
            {[DashboardStyles, TextStyles, TileTitle, TileStyles, ChartStyles, DataColors, ContainerTiles, LegendStyles, HoverTooltips, ButtonStyles, TableStyles, ThemeName].map(
                (comp, i) => cs(keyed(i), () => comp({form, sectionInitClosed}))
            )}

            <div className="controls">{controls}</div>
        </div>
    ));
