import React from "react";
import {chain} from "@common/utils/fs";
import "./child-dataset-configuration.scss";
import {TableSelect} from "../../../../common/table-select/table-select";
import {ffToDropdown} from "../../../../../../../../../common/form/ff-to-dropdown";
import {keepOnly} from "@common/utils/objects";

export const ChildDatasetConfiguration = ({step, form, transformation, model}) => {
    const inputStep = transformation.value.steps.find((s) => s.id === step.value.inputStepID);

    const inputTable = model.tables.find((t) => t.id === inputStep?.modelTableID);

    //const relationshipColumnIds = inputTable
    //    ? inputTable.columns.map((c) => chain(
    //        model.relationships?.find((r) => [r.leftColumnID, r.rightColumnID].includes(c.id)),
    //        (r) => r && [r.leftColumnID, r.rightColumnID].filter((cid) => cid !== c.id)[0],
    //    )).filter(v => v)
    //    : [];

    let relationshipColumnIds = [];
    for (const c of inputTable.columns) {
        for (const rel of model.relationships?.filter((r) => [r.leftColumnID, r.rightColumnID].includes(c.id)))
            relationshipColumnIds.push(rel.leftColumnID === c.id ? rel.rightColumnID : rel.leftColumnID);
    }

    const isDirectChildTable = (table) => {
        const colIds = table.columns.map((c) => c.id);
        for (const cid of relationshipColumnIds) {
            if (colIds.includes(cid)) {
                return true;
            }
        }
        return false;
    };

    const directChildTables = model.tables.filter((t) => t.id !== inputTable?.id && isDirectChildTable(t));

    return (
        <div className="child-dataset-configuration-fyj">
            <div className="config-group">
                <div className="label">Select child collection</div>
                <div className="select-table">
                    {TableSelect({
                        tables: model.tables,
                        label: "Table or View",
                        state: {
                            value: step.value.modelTableID,
                            onChange: (table) =>
                                step.onChange({
                                    ...step.value,
                                    modelID: model.id,
                                    modelTableID: table.id,
                                }),
                        },
                        isUnavailable: (t) => !directChildTables.includes(t),
                        ...keepOnly(ffToDropdown(form.field(["modelTableID"])), ["domRef", "hasError", "errorMessage"]),
                    })}
                </div>
            </div>
        </div>
    );
};
