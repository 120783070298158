import {Whiskers} from "./panels/whiskers/whiskers";
import {TileTitle} from "../common/tile-title/tile-title";
import {Colors} from "../vertical-bar/panels/colors/colors";
import {OtherOptions} from "../common/other-options/other-options";
import {YAxis} from "./panels/y-axis/y-axis";
import {XAxis} from "./panels/x-axis/x-axis";

export const BoxPlot = ({tile, size, form, parentTile, colorsTileConfig, tileAction, isContainerTile}) => [
    Whiskers,
    TileTitle,
    XAxis,
    YAxis,
    Colors,
    OtherOptions
].map((panel) => panel({tile, size, form, parentTile, colorsTileConfig, tileAction, isContainerTile}))
