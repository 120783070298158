exports.gaugeTemplate = {
    $type: "GaugeTile",
    style: {
        $type: "GaugeTileStyle",
        tickness: "Thick",
        arcSize: "Deg180",
        reservedPadding: null,
        valueLabelShown: true,
        // valueLabel: use field name
        actualValueShown: true,
        autoRound: true,

        // compareValueShown: true,
        compareValueMethod: "Difference",
        // previousPeriodShown: true,
        // compareIndicatorShown: true,
        // comparisonGaugeShown: true,
        // minMaxLabelsShown: true,
        // valueLabelsShown: true,

        positiveChangeGood: true,

        dataLabels: {
            $type: "DataLabelsTileStyle",
            show: true,
            fontSize: null,
        },
        legendStyle: {
            legendShown: false,
            legendPosition: "Auto",
            legendTitleShown: false,
            legendTitle: "Legend",
        },
        title: {
            show: true,
            titleLocation: "Above",
        },
    },
};
