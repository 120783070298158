import "./select-columns.scss";

import React from "react";

import {cs} from "@common/react/chain-services";
import {DnDList} from "../../../../common/dnd-list/dnd-list";
import {rColumnControl} from "../../main/right-panel/common/column-control/column-control";
import {rColumnSelectFromStepOutput} from "../column-select-from-step-output/column-select-from-step-output";
import {rColumnValueSelectFromStepOutput} from "../column-value-select-from-step-output/column-value-select-from-step-output";
import {removeIndex} from "@common/utils/collections";
import {getPath} from "@common/utils/arr-path";
import {ffToDropdown} from "@common/form/ff-to-dropdown";
import {keepOnly} from "@common/utils/objects";
import {spc} from "@common/react/state-path-change";
import {GhostButton} from "@common/form/buttons/ghost-button/ghost-button";
import {PlusIcon} from "@common/ui-components/icons/global-icons";
import {ffToBasicInput} from "@common/form/ff-to-basic-input";
import {DebounceTextInput} from "@common/form/debounce-input/debounce-text-input";
import {DebounceNumberInput} from "@common/form/debounce-input/debounce-number-input";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {IgnoreUpdate} from "@common/react/ignore-update";
import {keyed} from "@common/react/keyed";

export const SelectColumns = ({
    step,
    form,
    model,
    path,
    columns,
    allowDuplicate = false,

    // object form is used when the column is a prop value of an object, not the object itself
    // column prop is defined in objectForm.columnProp
    // e.g., SelectColumnsStep, columns: [{column, renameTo}]
    objectForm,

    drillingSelect,
    enableStaticValue,
    staticValueType,
    filterColumnFn,
    isValueNotAllowed,
}) => {
    const columnSelect = drillingSelect
        ? drillingSelect()
        : enableStaticValue
        ? rColumnValueSelectFromStepOutput({
              label: "Column / Value",
              columns,
              model,
              disableSelectingTable: true,
              filterColumnFn,
              isValueNotAllowed,
          })
        : rColumnSelectFromStepOutput({
              label: "Column",
              columns,
              filterColumnFn,
              isValueNotAllowed,
              ...(!allowDuplicate && {
                  isUnavailable: (c) => {
                      const getCol = (c) => (objectForm?.columnProp ? c[objectForm?.columnProp] : c);
                      const doesInclude = (prop) =>
                          getPath(step.value, path)
                              ?.map((c1) => getCol(c1)?.[prop])
                              .includes(c[prop]);
                      return ["ViewStepColumn", "GroupAggColumn"].includes(c.$type) ? doesInclude("name") : doesInclude("modelColumnID");
                  },
              }),
          });

    // const objectForm = {
    //     ...objectForm1,
    //     ...((!drillingSelect && !enableStaticValue) ? {
    //         columnProp: "column",
    //         propertyInput: ({state, ...props}) => TextInput({
    //             label: "Rename To (optional)",
    //             state,
    //             ...props
    //         }),
    //         propertyPropInput: "renameTo"
    //     } : {})
    // };

    const toColumnPath = (index) => {
        return [...path, index, objectForm?.columnProp].filter((v) => v != null);
    };

    const toPropertyPath = (index) => {
        if (objectForm?.propertySelector && objectForm?.propertyProp) {
            return [...path, index, objectForm.propertyProp];
        }
    };

    const toPropertyInputPath = (index) => {
        if (objectForm?.propertyInput && objectForm?.propertyPropInput) {
            return [...path, index, objectForm.propertyPropInput];
        }
    };

    const columnsList = getPath(step.value, path) || [];

    return cs(
        // ["dnd", ({}, next) => DnDList({
        //     list: columnsList?.length > 0 ? columnsList : [{}],
        //     onChange: (newCols) => spc(step, path, () => newCols),
        //     renderDrag: ({index}) => (
        //         <div className="column">
        //             {rColumnControl({
        //                 onStartDragging: () => {},
        //                 dragging: true,
        //                 columnSelector: columnSelect({
        //                     state: {
        //                         value: getPath(step.value, toColumnPath(index)),
        //                         onChange: () => {},
        //                     }
        //                 }),
        //                 ...((() => {
        //                     const propertyPath = toPropertyPath(index);
        //                     const propertyPropInput = toPropertyInputPath(index);
        //
        //                     return {
        //                         ...(propertyPath ? {
        //                             propertySelector: objectForm.propertySelector({
        //                                 state: {
        //                                     value: getPath(step.value, toPropertyPath(index)),
        //                                     onChange: () => {}
        //                                 }
        //                             })
        //                         } : {}),
        //                         ...(propertyPropInput ? {
        //                             propertyInput: objectForm.propertyInput({
        //                                 state: {
        //                                     value: getPath(step.value, toPropertyInputPath(index)),
        //                                     onChange: () => {}
        //                                 }
        //                             })
        //                         } : {})
        //
        //                     }
        //                 })()),
        //             })}
        //         </div>
        //     ),
        //     next,
        // })],
        [
            "renderItem",
            (_, next) =>
                next(({i: ci, item}) =>
                    cs(
                        (_, next) =>
                            IgnoreUpdate({
                                props: {columnsList, step, item},
                                when: (pp) =>
                                    JSON.stringify({
                                        columnsList: pp.columnsList,
                                        errors: pp.step.value.errors,
                                        item: pp.item,
                                    }) ===
                                    JSON.stringify({
                                        columnsList,
                                        errors: step.value.errors,
                                        item,
                                    }),
                                next,
                            }),
                        keyed(ci),
                        () => {
                            const columnSelector = () => {
                                const columnDropdownProps = ffToDropdown(form.field(toColumnPath(ci)));
                                return columnSelect({
                                    state: {
                                        value: columnDropdownProps.value,
                                        onChange: columnDropdownProps.onChange,
                                    },
                                    ...keepOnly(columnDropdownProps, ["domRef", "hasError", "errorMessage"]),
                                });
                            };

                            const propertySelector = (() => {
                                const propertyPath = toPropertyPath(ci);
                                if (propertyPath) {
                                    const propertyDropdownProps = ffToDropdown(form.field(propertyPath));
                                    return objectForm.propertySelector({
                                        state: {
                                            value: propertyDropdownProps.value,
                                            onChange: propertyDropdownProps.onChange,
                                        },
                                        ...keepOnly(propertyDropdownProps, ["domRef", "hasError", "errorMessage"]),
                                    });
                                }
                            })();

                            const propertyInput = (() => {
                                // if static value instead of column
                                if (getPath(step.value, toColumnPath(ci))?.$type === "StaticValue") {
                                    const InputComp = {
                                        text: DebounceTextInput,
                                        number: DebounceNumberInput,
                                    }[staticValueType || "text"];

                                    const staticValueProps = ffToBasicInput(form.field([...toColumnPath(ci), "value"]));
                                    return InputComp({
                                        state: staticValueProps.state,
                                        ...keepOnly(staticValueProps, ["domRef", "hasError", "errorMessage"]),
                                        label: "Value",
                                    });
                                }

                                const propertyPath = toPropertyInputPath(ci);
                                if (propertyPath) {
                                    const propertyInputProps = ffToBasicInput(form.field(propertyPath));
                                    return objectForm.propertyInput({
                                        state: propertyInputProps.state,
                                        ...keepOnly(propertyInputProps, ["domRef", "hasError", "errorMessage"]),
                                    });
                                }
                            })();

                            return rColumnControl({
                                onStartDragging: () => {},
                                columnSelector: columnSelector(),
                                propertySelector,
                                propertyInput,
                                onRemove: () => spc(step, path, (cols) => removeIndex(ci, cols)),
                            });
                        }
                    )
                ),
        ],
        ({dnd, renderItem}) => (
            <div className="select-columns-e6r select-columns">
                {/*{dnd.list?.map(({index: ci, ref, start}) => {*/}
                {/*    if (ci != null) {*/}
                {/*        const columnSelector = (() => {*/}
                {/*            const columnDropdownProps = ffToDropdown(form.field(toColumnPath(ci)));*/}
                {/*            return columnSelect({*/}
                {/*                state: {*/}
                {/*                    value: columnDropdownProps.value,*/}
                {/*                    onChange: columnDropdownProps.onChange,*/}
                {/*                },*/}
                {/*                ...keepOnly(columnDropdownProps, ["domRef", "hasError", "errorMessage"]),*/}
                {/*            })*/}
                {/*        })();*/}

                {/*        const propertySelector = (() => {*/}
                {/*            const propertyPath = toPropertyPath(ci);*/}
                {/*            if (propertyPath) {*/}
                {/*                const propertyDropdownProps = ffToDropdown(form.field(propertyPath));*/}
                {/*                return objectForm.propertySelector({*/}
                {/*                    state: {*/}
                {/*                        value: propertyDropdownProps.value,*/}
                {/*                        onChange: propertyDropdownProps.onChange,*/}
                {/*                    },*/}
                {/*                    ...keepOnly(propertyDropdownProps, ["domRef", "hasError", "errorMessage"]),*/}
                {/*                });*/}
                {/*            }*/}
                {/*        })();*/}

                {/*        const propertyInput = (() => {*/}
                {/*            // if static value instead of column*/}
                {/*            if (getPath(step.value, toColumnPath(ci))?.$type === "StaticValue") {*/}
                {/*                const InputComp = {*/}
                {/*                    "text": DebounceTextInput,*/}
                {/*                    "number": DebounceNumberInput,*/}
                {/*                }[staticValueType || "text"];*/}

                {/*                const staticValueProps = ffToBasicInput(form.field([...toColumnPath(ci), "value"]));*/}
                {/*                return InputComp({*/}
                {/*                    state: staticValueProps.state,*/}
                {/*                    ...keepOnly(staticValueProps, ["domRef", "hasError", "errorMessage"]),*/}
                {/*                    label: "Value",*/}
                {/*                })*/}
                {/*            }*/}

                {/*            const propertyPath = toPropertyInputPath(ci);*/}
                {/*            if (propertyPath) {*/}
                {/*                const propertyInputProps = ffToBasicInput(form.field(propertyPath));*/}
                {/*                return objectForm.propertyInput({*/}
                {/*                    state: propertyInputProps.state,*/}
                {/*                    ...keepOnly(propertyInputProps, ["domRef", "hasError", "errorMessage"]),*/}
                {/*                });*/}
                {/*            }*/}
                {/*        })();*/}

                {/*        return (*/}
                {/*            <div className="column" key={"column" + ci} ref={ref}>*/}
                {/*                {rColumnControl({*/}
                {/*                    onStartDragging: start,*/}
                {/*                    columnSelector,*/}
                {/*                    propertySelector,*/}
                {/*                    propertyInput,*/}
                {/*                    onRemove: () => spc(step, path, (cols) => removeIndex(ci, cols)),*/}
                {/*                })}*/}
                {/*            </div>*/}
                {/*        )*/}
                {/*    } else {*/}
                {/*        return (*/}
                {/*            <div className="column blank" key={-1}/>*/}
                {/*        )*/}
                {/*    }*/}
                {/*})}*/}

                <DragDropContext
                    onDragStart={() => {}}
                    onDragEnd={(result) => {
                        const {destination, source, draggableId} = result;

                        if (!destination) {
                            return;
                        }

                        if (destination.droppableId === source.droppableId && destination.index === source.index) {
                            return;
                        }

                        const reorder = (list, startIndex, endIndex) => {
                            const result = Array.from(list);
                            const [removed] = result.splice(startIndex, 1);
                            result.splice(endIndex, 0, removed);

                            return result;
                        };

                        const start = columnsList;
                        const newFields = Array.from(start);

                        const [modelColumnID, itemIndex] = draggableId.split("|");
                        let newField = start.find((s, index) => modelColumnID === s?.modelColumnID && index === parseInt(itemIndex));

                        newFields.splice(source.index, 1);
                        newFields.splice(destination.index, 0, newField);

                        spc(step, path, () => newFields);
                    }}
                >
                    <Droppable droppableId="droppable-list">
                        {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                {columnsList.map((item, i) => {
                                    return (
                                        <Draggable key={`${item?.modelColumnID}|${i}`} draggableId={`${item?.modelColumnID}|${i}`} index={i}>
                                            {(provided) => {
                                                return (
                                                    <div
                                                        className="column"
                                                        key={item?.modelColumnID ?? `draggable-${i}`}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided?.dragHandleProps}
                                                    >
                                                        {renderItem({i, item})}
                                                    </div>
                                                );
                                            }}
                                        </Draggable>
                                    );
                                })}

                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>

                {columnsList?.length < columns?.length && (
                    <GhostButton
                        iconLeft={<PlusIcon />}
                        onClick={() => {
                            const newColumn = !objectForm
                                ? {}
                                : {
                                      [objectForm.columnProp]: {},
                                      ...(objectForm.propertyProp ? {[objectForm.propertyProp]: null} : {}),
                                      ...(objectForm.propertyPropInput
                                          ? {
                                                [objectForm.propertyPropInput]: null,
                                            }
                                          : {}),
                                  };

                            return spc(step, path, (cols) => [...(cols.length > 0 ? cols : [newColumn]), newColumn]);
                        }}
                    >
                        Add
                    </GhostButton>
                )}
            </div>
        )
    );
};
