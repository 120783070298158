import "./model-table-more-control-popup.scss";

import React from "react";

import {cs} from "@common/react/chain-services";
import {Static2} from "@common/react/static-2";
import {AnyAction2} from "@common/react/any-action-2";
import {UseState} from "@common/react/use-state";
import {cx} from "emotion";
import {ShortestPossiblePathDialog} from "../../../tabs/left-panel-overrides/table-panel/shortest-possible-path/shortest-possible-path-dialog";
import {consumeContext} from "@common/react/context";
import {ModelPanelHelper} from "../../../../common/model-panel-helper";
import {
    AddAggregatedMeasureIcon,
    AddCalculatedColumnIcon,
    HighlightShortestPathsIcon,
    RemoveIcon,
    RowSecurityIcon,
    SuggestRelationshipIcon,
} from "./icons/more-control-icons";
import {spc} from "@common/react/state-path-change";
import {renderCompInRegistry} from "@common/ui-components/registry/common-registry";
import {UseEffect} from "@common/react/use-effect";
import {RAFLoop} from "@common/utils/loop";
import {equalDeep} from "@common/utils/objects";

export const ModelTableMoreControlPopup = ({next, model}) =>
    cs(
        ["domRef", (_, next) => Static2({next})],
        ["shortestPossiblePathDialog", (_, next) => ShortestPossiblePathDialog({next})],
        [
            "state",
            (_, next) => {
                return UseState({
                    next,
                    initValue: {
                        open: false,
                        pos: null,
                        data: null,
                    },
                });
            },
        ],
        ({state, shortestPossiblePathDialog, domRef}) => {
            const {open, pos, data, targetElem, customActions = []} = state.value;

            return next({
                render: ({model, interactions}) =>
                    !open
                        ? null
                        : cs(consumeContext("routing"), ({routing}) => {
                              const {table, relationships, dataSources, suggestedRelationships, removeTableService} = data;

                              const suggestColumns = ModelPanelHelper.getSuggestColumns({
                                  table,
                                  suggestedRelationships: suggestedRelationships || [],
                                  relationships,
                              });

                              const relationshipColumns = table?.columns.filter((c) =>
                                  relationships.find((r) => r.leftColumnID == c.id || r.rightColumnID == c.id)
                              );

                              const dataSource = dataSources.find((ds) => ds.id === table.dataSourceID);

                              const isStructured = dataSource && dataSource.structured && dataSource.type !== "File";

                              const dsTableHavingPrimaryKey = isStructured
                                  ? dataSource.tables
                                        .find((t) => t.id === table.dataSourceTableID)
                                        ?.columns.findIndex((c) => c.primaryKey) > -1 || null
                                  : true;

                              const actions = (customActions ?? []).concat([
                                  {
                                      label: "Row-Level Security",
                                      icon: RowSecurityIcon({}),
                                      onClick: () =>
                                          interactions.openRowLevelSecurity({
                                              tableId: table.id,
                                              dataSourceID: table.dataSourceID,
                                              dataSourceTableID: table.dataSourceTableID,
                                          }),
                                      hidden: () => table.$type != "ViewModelTable",
                                  },
                                  {
                                      label: "Suggested Relationships",
                                      icon: SuggestRelationshipIcon({
                                          fill: !suggestColumns || suggestColumns.length == 0 ? "#9DAAB7" : "#294661",
                                      }),
                                      disabled: !suggestColumns || suggestColumns.length == 0,
                                      tooltip: "There are no suggested relationships.",
                                      onClick: () => {
                                          spc(state, ["open"], () => false);
                                          interactions.viewSuggestRelationship({
                                              suggestedRelationships: suggestColumns,
                                              relationships,
                                              tables: model.value.tables,
                                              table,
                                          });
                                      },
                                  },
                                  {
                                      label: "Highlight Shortest Paths",
                                      icon: HighlightShortestPathsIcon({
                                          fill: relationshipColumns.length == 0 ? "#9DAAB7" : "#294661",
                                      }),
                                      tooltip: "There are no existing relationships.",
                                      disabled: relationshipColumns.length == 0,
                                      onClick: () => {
                                          spc(state, ["open"], () => false);
                                          shortestPossiblePathDialog.show({
                                              table,
                                              relationships,
                                              tables: model.value.tables,
                                          });
                                      },
                                  },
                                  {
                                      label: `Add Calculated Column`,
                                      icon: AddCalculatedColumnIcon({
                                          fill: !dsTableHavingPrimaryKey ? "#9DAAB7" : "#294661",
                                      }),
                                      disabled: !dsTableHavingPrimaryKey,
                                      tooltip: "Calculated Columns are not allowed on a table without a primary key.",
                                      onClick: () => {
                                          routing.goto("edit-transformed-column", {
                                              tab: "build",
                                              modelTableId: table.id,
                                              isNew: true,
                                              type: "calculated-column",
                                          });
                                      },
                                      hidden: () => table.$type !== "DataSourceModelTable",
                                  },
                                  {
                                      label: `Add Aggregated Measure`,
                                      icon: AddAggregatedMeasureIcon({
                                          fill: !dsTableHavingPrimaryKey ? "#9DAAB7" : "#294661",
                                      }),
                                      disabled: !dsTableHavingPrimaryKey,
                                      tooltip: "Aggregated Measures are not allowed on a table without a primary key.",
                                      onClick: () => {
                                          routing.goto("edit-transformed-column", {
                                              tab: "build",
                                              modelTableId: table.id,
                                              isNew: true,
                                              type: "aggregated-measure",
                                          });
                                      },
                                      //hidden: () => table.$type !== "DataSourceModelTable"
                                  },
                                  //{
                                  //    label: "Column-Level Security",
                                  //    icon: ColumnSecurityIcon({}),
                                  //    onClick: () => {
                                  //        open && spc(state, ['open'], () => false);
                                  //        interactions.openColumnSettings({model: model, tableId: table.id})
                                  //    }
                                  //},
                                  {
                                      label: "Remove Table",
                                      className: "failure",
                                      icon: RemoveIcon({fill: "#FF5959"}),
                                      onClick: () => {
                                          open && spc(state, ["open"], () => false);
                                          removeTableService?.show?.({table});
                                      },
                                  },
                              ]);

                              return renderCompInRegistry({
                                  comp: (
                                      <div
                                          ref={domRef.set}
                                          style={{
                                              top: pos.y + 5,
                                              left: pos.x,
                                              transformOrigin: "top left",
                                          }}
                                          className="model-table-more-controls"
                                      >
                                          {UseEffect({
                                              fn: () => {
                                                  return RAFLoop.addTarget({
                                                      update: () => {
                                                          if (targetElem) {
                                                              const {pos} = state.get();
                                                              const newPos = getElemCenterPoint(targetElem);
                                                              if (!equalDeep(newPos, pos)) {
                                                                  spc(state, ["open"], () => false);
                                                              }
                                                          }
                                                      },
                                                  });
                                              },
                                              deps: [],
                                          })}
                                          {AnyAction2({
                                              disabled: !state?.value?.open,
                                              getDom: domRef.get,
                                              fn: (e) => {
                                                  state?.value?.open && spc(state, ["open"], () => false);
                                              },
                                          })}

                                          {actions
                                              .filter((a) => !a.hidden || !a.hidden())
                                              .map((action, index) => (
                                                  <div
                                                      className={cx("action-item", action.disabled && "disabled", action?.className)}
                                                      onClick={() => {
                                                          if (!action.disabled) {
                                                              action.onClick();
                                                              spc(state, ["open"], () => false);
                                                          }
                                                      }}
                                                      key={index}
                                                  >
                                                      <div className="icon">{action.icon}</div>
                                                      {action.label}
                                                      {action.disabled && action.tooltip && <div className="tooltip">{action.tooltip}</div>}
                                                  </div>
                                              ))}
                                      </div>
                                  ),
                              });
                          }),
                showControl: ({targetElem, data, customActions = []}) => {
                    state.onChange({
                        open: true,
                        customActions,
                        targetElem,
                        pos: getElemCenterPoint(targetElem),
                        data,
                    });
                },
                forceClose: () => open && spc(state, ["open"], () => false),
                open,
            });
        }
    );

const getElemCenterPoint = (elem) => {
    if (!elem) {
        return {x: 0, y: 0};
    }
    const {top, left, width, height} = elem?.getBoundingClientRect();
    return {
        x: left + width / 2,
        y: top + height / 2,
    };
};
