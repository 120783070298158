import moment from "moment";
import {formatDateTime, formatSecondstoMMss} from "../../../../../../../common/logic/date-time/format-date-time";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {createArray, last, sort} from "@common/utils/collections";
import {SkeletonBox} from "../../../../common/skeleton/skeleton-box";
import {SyncStatusIcon} from "../common/sync-status-icon/sync-status-icon";
import {InteractionTable} from "../common/tables/interaction-table";
import {InteractionChart, InteractionChartNoData} from "./chart/interaction-chart";
import {CacheSync} from "./ds-sync-cache";
import {chain} from "@common/utils/fs";
import {capitalize} from "@common/utils/strings";

export const SyncDetailInteraction = ({dateRange, tableId, status, next}) =>
    cs(
        consumeContext("apis"),
        consumeContext("routing"),

        [
            "formatMDateTime",
            (_, next) =>
                next((m) => {
                    const format = (d) => ({
                        year: d.get("year"),
                        month: d.get("month") + 1,
                        day: d.get("date"),
                    });

                    const formatTime = (d) => ({
                        hours: d.get("hour"),
                        minutes: d.get("minutes"),
                        // seconds: d.get('second'),
                    });

                    return {date: format(m), time: formatTime(m)};
                }),
        ],
        [
            "localDateRange",
            ({formatMDateTime}, next) => {
                const utcOffset = new Date().getTimezoneOffset();
                let start = dateRange.start.clone();
                let end = dateRange.end.clone();
                let prevDayStart = dateRange.start.clone().subtract(2, "d");

                const startedUtc = start.startOf("day").utcOffset(-utcOffset).utc();
                const endedUtc = end.endOf("day").utcOffset(-utcOffset).utc();
                const prevDayStartedUtc = prevDayStart.startOf("day").utcOffset(-utcOffset).utc();

                return next({
                    utcOffset,
                    start: formatDateTime(formatMDateTime(startedUtc)),
                    end: formatDateTime(formatMDateTime(endedUtc)),
                    prevDayStart: formatDateTime(formatMDateTime(prevDayStartedUtc)),
                });
            },
        ],
        [
            "tablesHistories",
            ({apis, routing, localDateRange, formatMDateTime}, next) =>
                cs(
                    [
                        "histories",
                        (_, next) =>
                            CacheSync({
                                _key: JSON.stringify({
                                    ...localDateRange,
                                    status,
                                }),
                                fetch: () =>
                                    apis.data.getTableHistories(routing.params.dsId, tableId, {
                                        ...localDateRange,
                                        start: localDateRange.prevDayStart,
                                    }),
                                next,
                            }),
                    ],
                    ({histories}) => {
                        const synchronizations = histories
                            ? chain(
                                  histories.synchronizations,
                                  (_) =>
                                      _.filter(
                                          (h) =>
                                              new Date(h.startedUtc).getTime() >= new Date(localDateRange.start).getTime() &&
                                              ((new Date(localDateRange.start).getTime() <= new Date(h.endedUtc).getTime() &&
                                                  new Date(h.endedUtc).getTime() <= new Date(localDateRange.end).getTime()) ||
                                                  h.endedUtc === null)
                                      ),
                                  (list) =>
                                      list.map((s, k) => {
                                          if (k < list.length - 1 && s.status === "Curating") {
                                              return {
                                                  ...s,
                                                  oriStatus: "Curating",
                                                  endedUtc: list[k + 1].endedUtc,
                                                  status: "Success",
                                              };
                                          }

                                          return {
                                              ...s,
                                              endedUtc: s.endedUtc ?? formatDateTime(formatMDateTime(moment.utc())),
                                          };
                                      }),
                                  (_) => sort(_, (t) => new Date(t.startedUtc).getTime())
                              )
                            : [];

                        const prevSynchronizations = histories
                            ? chain(
                                  histories.synchronizations.filter(
                                      (s) => new Date(s.startedUtc).getTime() < new Date(localDateRange.start).getTime(),
                                      (_) => sort(_, (t) => new Date(t.startedUtc).getTime())
                                  )
                              )
                            : [];

                        return next(
                            histories
                                ? {
                                      ...histories,
                                      synchronizations,
                                      prevSynchronizations,
                                  }
                                : null
                        );
                    }
                ),
        ],
        ({tablesHistories, localDateRange}) => {
            const formatSync = (current, prev) =>
                current.length > 0
                    ? current
                    : prev.length > 0
                    ? [
                          {
                              ...last(prev),
                              startedUtc: localDateRange.start,
                              endedUtc: localDateRange.end,
                          },
                      ]
                    : [
                          // {
                          //     startedUtc: localDateRange.start,
                          //     endedUtc: localDateRange.end,
                          //     status: capitalize(status),
                          //     errorMessage: null,
                          //     errorType: null,
                          //     records: 0,
                          //     sizeMB: 0,
                          //     syncDuration: 0,
                          //     syncMethod: "None",
                          // },
                      ];

            return next({
                loading: !tablesHistories,
                renderLoading: () => {
                    const skeleton = () => SkeletonBox({style: {width: 52, height: 16}});
                    return (
                        <>
                            {InteractionChartNoData({})}

                            {InteractionTable({
                                list: createArray(3),
                                columns: [
                                    {
                                        label: "Start",
                                        format: (row) => (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {SkeletonBox({
                                                    style: {
                                                        width: 6,
                                                        height: 6,
                                                        borderRadius: `50%`,
                                                    },
                                                })}
                                                &nbsp;&nbsp;
                                                {skeleton()}
                                            </div>
                                        ),
                                    },
                                    {label: "End", format: skeleton},
                                    {label: "Sync Duration", format: skeleton},
                                    {label: "Data", format: skeleton},
                                    {label: "Records", format: skeleton},
                                    {label: "Method", format: skeleton},
                                ],
                            })}
                        </>
                    );
                },
                renderChart: () =>
                    InteractionChart({
                        dateRange,
                        tablesHistories,
                        data: formatSync(tablesHistories?.synchronizations, tablesHistories.prevSynchronizations),
                        prevData: tablesHistories?.prevSynchronizations,
                    }),
                renderTable: ({height}) => {
                    const list = [
                        ...tablesHistories.synchronizations,
                        ...(tablesHistories.nextSync &&
                        !tablesHistories.paused &&
                        tablesHistories.errors.length === 0 &&
                        dateRange.start.isSame(new Date(tablesHistories.nextSync), "day")
                            ? [
                                  {
                                      status: "will-sync",
                                      startedUtc: tablesHistories.nextSync,
                                  },
                              ]
                            : []),
                    ];

                    return InteractionTable({
                        style: {height},
                        list: sort(list, (t) => -new Date(t.startedUtc).getTime()) || [],
                        isFullRow: (row) => row.status === "Success",
                        subColumns: [
                            {
                                alignCenter: true,
                                format: (row) => (
                                    <>
                                        {SyncStatusIcon({
                                            type: row.status === "Error" || row.status === "ErrorsCleared" ? "error-table" : row.status.toLowerCase(),
                                            tooltipContent: row.errorMessage,
                                        })}
                                    </>
                                ),
                            },
                            {
                                className: "error-message",
                                label: "Start",
                                format: (row) => (
                                    <>
                                        {moment(new Date(row.startedUtc)).format("hh:mm A")}

                                        {row.status === "Error" && row.errorMessage && (
                                            <>
                                                {row.errorType && <span className="text"> -</span>}
                                                <span className="error-cleared"> {row.errorMessage}</span>
                                            </>
                                        )}

                                        {row.status === "ErrorsCleared" && (
                                            <>
                                                {row.errorType && <span className="text"> - {row.errorType}</span>}
                                                {row.endedUtc && <span className="error-cleared"> - Error cleared at {moment(new Date(row.endedUtc)).format("hh:mm A")}</span>}
                                            </>
                                        )}

                                        {row.status === "Paused" && (
                                            <>
                                                {<span className="text"> - Sync Paused</span>}
                                                {!tablesHistories.paused && (
                                                    <span className="error-cleared"> - Unpaused at at {moment(new Date(row.endedUtc)).format("hh:mm A")}</span>
                                                )}
                                            </>
                                        )}
                                    </>
                                ),
                            },
                        ],
                        columns: [
                            {
                                alignCenter: true,
                                style: {maxWidth: 15},
                                format: (row) => SyncStatusIcon({type: "sync"}),
                            },
                            {
                                label: "Start",
                                format: (row) => moment(new Date(row.startedUtc)).format("hh:mm A"),
                            },
                            {
                                label: "End",
                                format: (row) => row?.endedUtc && moment(new Date(row.endedUtc)).format("hh:mm A"),
                            },
                            {
                                label: "Sync Duration",
                                format: (row) => {
                                    if (row.syncDuration < 60 * 1000) return `${Number(row.syncDuration / 1000)} secs`;
                                    return `${formatSecondstoMMss(row.syncDuration / 1000)} mins`;
                                },
                                alignRight: true,
                            },
                            {
                                label: "Data",
                                format: (row) => `${Number(row?.sizeMB).toLocaleString()} MB`,
                                alignRight: true,
                            },
                            {
                                label: "Records",
                                format: (row) => Number(row?.records).toLocaleString(),
                                alignRight: true,
                            },
                            {
                                label: "Method",
                                format: (row) => row?.syncMethod,
                                alignRight: true,
                            },
                        ],
                        footerColumns: cs(
                            ["getValue", (_, next) => next((prop) => tablesHistories?.synchronizations.reduce((prev, item) => (prev += item[prop]), 0))],
                            ({getValue}) => [
                                {format: () => null},
                                {format: () => `TOTALS IN PERIOD`},
                                {format: () => null},
                                {
                                    format: () => {
                                        const duration = getValue("syncDuration") / 1000;
                                        if (duration < 60) return `${Number(duration)} secs`;
                                        return `${formatSecondstoMMss(duration)} mins`;
                                    },
                                    alignRight: true,
                                },
                                {
                                    format: () => `${Number(getValue("sizeMB")).toLocaleString()} MB`,
                                    alignRight: true,
                                },
                                {
                                    format: () => Number(getValue("records")).toLocaleString(),
                                    alignRight: true,
                                },
                                {format: () => null},
                            ]
                        ),
                    });
                },
            });
        }
    );
