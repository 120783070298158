import "./progress-button.scss";

import React from "react";

import {Button} from "../button/button";

export const ProgressBtn = ({progress, children, ...other}) => {
    return (
        <div className="progress-btn-00f">
            <Button {...other}>{children}</Button>

            <div className="progress-bar" style={{width: `${progress * 100}%`}} />
        </div>
    );
};
