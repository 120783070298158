import * as React from "react";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {stateToSelect} from "@common/ui-components/form/state-to-select";
import {ShowHideToggle} from "../../../../../../../../../../../common/form/toggles/show-hide-toggle";

export const LineMarkerSelect = (state) =>
    DropdownSelect({
        label: "Marker Shape",
        list: [
            // "None",
            "Circle",
            "Triangle",
            "Square",
            "Diamond",
            "Hollow Circle",
            "Hollow Triangle",
            "Hollow Square",
            "Hollow Diamond",
        ].map((label) => ({
            label: label.startsWith("Hollow") ? label : `Filled ${label}`,
            value: label.startsWith("Hollow") ? label.replace(/ /g, "") : label,
        })),
        valueToLabel: (v) => v.label,
        ...stateToSelect(state, ["value"]),
    });

export const LineMarkerToggle = (state) => {
    const {value, onChange} = state;
    return ShowHideToggle({
        state: {
            value: value !== "None",
            onChange: () => onChange(value === "None" ? "Circle" : "None"),
        },
    });
};
