import {cs} from "@common/react/chain-services";
import * as React from "react";
import {UseState} from "@common/react/use-state";
import {AddingFilterLeftPanel} from "./left-panel/adding-filter-left-panel";
import {FilterEditMain} from "../../../../common/filters/filter-edit-main/filter-edit-main";
import {changePath} from "@common/utils/arr-path";
import {consumeContext} from "@common/react/context";
import {scope} from "@common/react/scope";
import "./adding-filter.scss";
import {FadeRight} from "../../../../../common/animation/fade-right/fade-right";
import {Form2} from "@common/react/cs-form/form2";
import {filterFormConfig} from "../../../../common/filters/filter-form-config/filter-form-config";
import {LeaveDialog} from "../../../../../common/leave-dialog/leave-dialog";
import {cx} from "emotion";
import {Button} from "../../../../../../../../common/form/buttons/button/button";
import {FixedTooltip} from "../../../../../common/fixed-tooltip/fixed-tooltip";
import {createGetFieldInfo} from "../../../../common/field-info/create-get-field-info";
import {SingleEditorRegister} from "../../../../../common/single-editor/single-editor";
import {fragments} from "@common/react/fragments";

//#738: Do not allow multiple date compare ranges
const applyFilterRules = (filters, adding) => {
    const newFilters = filters.map((f) => {
        if (f.$type === "DateFilter" && adding.$type === "DateFilter" && adding.compareEnabled) {
            return {...f, compareEnabled: false};
        }
        return f;
    });

    return [...newFilters, adding];
};

export const AddingFilter = ({next, collection}) =>
    cs(
        consumeContext("apis"),

        ["state", (_, next) => UseState({next})],

        [
            "form",
            ({state, apis}, next) =>
                Form2({
                    ...filterFormConfig,
                    data: scope(state, ["adding"]),
                    disabled: !state.value?.adding,
                    initShowErrors: true,
                    hideErrorMessage: true,
                    onSubmit: async () => {
                        const newCollection = changePath(collection.value, ["filters"], (filters) => applyFilterRules(filters, state.value.adding));

                        const savedCollection = await apis.collection.upsertCollection(newCollection);
                        collection.onChange(savedCollection);

                        state.onChange(null);
                    },
                    next,
                }),
        ],
        ({form, state}) =>
            next({
                show: () => state.onChange({}),

                ...(state.value &&
                    (() => {
                        const adding = scope(state, ["adding"]);

                        return {
                            leftPanel: AddingFilterLeftPanel({
                                adding,
                                form,
                                onCancel: () => {
                                    state.onChange(null);
                                },
                            }),
                            main:
                                adding.value && adding.value.columns?.length > 0
                                    ? cs(
                                          ({}, next) =>
                                              FadeRight({
                                                  key: adding.value.$type,
                                                  next,
                                              }),
                                          () =>
                                              FilterEditMain({
                                                  filter: adding,
                                                  form,
                                              })
                                      )
                                    : cs(
                                          ({}, next) => FadeRight({key: 0, next}),
                                          () =>
                                              rAddingFilterGuide({
                                                  adding: adding.value,
                                              })
                                      ),
                        };
                    })()),
            })
    );

export const AddingFilterRoute = ({}) =>
    cs(
        consumeContext("apis"),
        consumeContext("collection"),
        consumeContext("routing"),

        ({routing, collection}, next) =>
            fragments(
                collection?.value
                    ? SingleEditorRegister({
                          goBack: () => routing.goto("dashboard", {tab: "collections"}),
                          getItemId: () => routing.params.colId,
                          otherButtons: [
                              {
                                  label: "Go to Preview",
                                  function: () => {
                                      window.open(`/#/env/${routing.params.envId}/collection/${routing.params.colId}/preview`, "_blank");
                                  },
                              },
                          ],
                          item: {
                              type: "collection",
                              parent: "Collections",
                              name: collection?.value.name,
                              id: collection?.value.id,
                          },
                      })
                    : null,
                next()
            ),
        ({}, next) => createGetFieldInfo({next}),
        ["state", (_, next) => UseState({initValue: null, next})],
        [
            "leaveDialog",
            (_, next) =>
                LeaveDialog({
                    title: "Filter is incomplete",
                    messages: ["This filter will not be created without saving. Do you want to delete this filter?"],
                    buttons: {
                        save: {label: "Return to Editing"},
                    },
                    next,
                }),
        ],
        [
            "form",
            ({state, apis, collection, routing}, next) =>
                Form2({
                    ...filterFormConfig,
                    data: scope(state, ["adding"]),
                    disabled: !state.value?.adding,
                    initShowErrors: true,
                    hideErrorMessage: true,
                    onSubmit: async () => {
                        const newCollection = changePath(collection.value, ["filters"], (filters) => applyFilterRules(filters, state.value.adding));

                        const savedCollection = await apis.collection.upsertCollection(newCollection);

                        collection.onChange(savedCollection);
                        // state.onChange(null);
                        routing.goto("edit-collection", {collectionTab: "filters"});
                    },
                    next,
                }),
        ],
        ({state, form, routing, leaveDialog}) => {
            const adding = scope(state, ["adding"]);
            return AddingLayout({
                className: "adding-filter-5u4",
                validAdding: form.immediateValid && !!state.value?.adding,
                adding: {
                    label: "Add New Filter",
                    button: {
                        label: "Add Filter",
                        disabled: !form.looksValid || !state.value?.adding,
                        click: () => state.value?.adding && form.submit({}),
                    },
                },
                back: {
                    onClick: async () => {
                        if (state.value != null) {
                            const answer = await leaveDialog.show();

                            if (answer === "discard") {
                                routing.goto("edit-collection", {
                                    collectionTab: "filters",
                                });
                            } else if (answer === "save") {
                                // form.submit();
                            } else {
                                // cancel
                            }
                        } else {
                            routing.goto("edit-collection", {collectionTab: "filters"});
                        }
                    },
                },
                leftPanel: {
                    content: AddingFilterLeftPanel({
                        adding,
                        form,
                        onCancel: () => {
                            state.onChange(null);
                        },
                    }),
                },
                main:
                    adding.value && adding.value.columns?.length > 0
                        ? cs(
                              ({}, next) => FadeRight({key: adding.value.$type, next}),
                              () => FilterEditMain({filter: adding, form})
                          )
                        : cs(
                              ({}, next) => FadeRight({key: 0, next}),
                              () => rAddingFilterGuide({adding: adding.value})
                          ),
            });
        }
    );

const AddingLayout = ({back, labelText, leftPanel, main, validAdding, rightPanel, onPublish, className, editing = null, adding = null}) =>
    cs(consumeContext("routeTransitioning"), ({routeTransitioning}) => {
        return (
            <div
                className={cx(
                    "layout-2-2su",
                    className,
                    routeTransitioning?.transitioning && {
                        transitioning: true,
                        [routeTransitioning.entering ? "entering" : "exiting"]: true,
                        ["phase-" + routeTransitioning.phase]: true,
                    }
                )}
            >
                <div className="header">
                    <div className="left">
                        <div className="label">{adding.label}</div>
                    </div>

                    <div
                        className={cx("right", {
                            overlap: rightPanel?.overlapHeader && rightPanel.content,
                        })}
                    >
                        <div className="buttons adding">
                            <Button btnType="secondary" size="medium" onClick={back.onClick}>
                                Cancel
                            </Button>
                            &nbsp;&nbsp;
                            {FixedTooltip({
                                value: "Required fields are missing",
                                noTooltip: validAdding,
                                content: (
                                    <Button className="btn" onClick={adding.button.click} disabled={adding.button.disabled}>
                                        {adding.button.label}
                                    </Button>
                                ),
                            })}
                        </div>
                    </div>
                </div>

                <div className="center">
                    {leftPanel && (
                        <div className="left-panel" style={{width: leftPanel.width || 320}}>
                            {leftPanel.content}
                        </div>
                    )}

                    <div className="content">{main}</div>
                </div>
            </div>
        );
    });

const rAddingFilterGuide = ({adding}) => (
    <div className="adding-filter-blank-42e">
        <div className={cx("guide", {"select-field": adding})}>
            <div className="line">
                <div className="point" />
            </div>
            <div className="content">{!adding ? "Select a Filter Type" : "Select a Value Field"}</div>
        </div>
    </div>
);
