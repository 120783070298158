import {cx} from "emotion";
import React from "react";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {Load2} from "@common/react/load2";
import {UseState} from "@common/react/use-state";
import {LoadingIndicator} from "@common/ui-components/loading-indicator/loading-indicator";
import {ButtonIconWithTooltip} from "@common/form/buttons/button-icon/button-icon";
import {DocsIcon, NewFolderIcon, PlusIcon} from "@common/ui-components/icons/global-icons";
import {PopupMenu} from "../../../common/popup-menu/popup-menu";
import {RecursiveTable} from "../../../common/recursive-table/recursive-table";
import {RemoveSourceDialog} from "../../../data-source/remove-source-dialog/remove-source-dialog";
import {CreateFolderDialog} from "../collections/folder-cm/create-folder/create-folder-dialog";
import {FolderCM} from "../collections/folder-cm/folder-cm";
import {buildFoldersTree} from "../collections/folders-tree-structure";
import {MoveToDialog} from "../common/move-to-dialog/move-to-dialog";
import {AddDataSourceService} from "./add-datasource-service";
import "./data-sources-dashboard.scss";
import FolderOpenIcon from "assets/icons/common/FolderOpenIcon";
import FolderIcon from "assets/icons/common/FolderIcon";
import {SearchInputBar} from "@common/ui-components/search-input-bar/search-input-bar";
import {isBlank} from "@common/utils/strings";
import {ButtonDropdown} from "../../../data-source/common/button-dropdown/button-dropdown";
import {dataSourceTypes, unstructuredDSTypes} from "../../../data-source/common/data-source-type";
import {StaticInfoBox} from "../../../common/tooltip3/static-info-box";
import {noun} from "@common/utils/plural";
import {cSsJson} from "@common/react/ss-json";
import {MultiSelect} from "../../common/multi-select";

const {createDateFormatter} = require("@common/ui-components/charts/common/formatters/date-formatter");
const {flatten1} = require("@common/utils/collections");

export const DataSourcesDashboard = ({next, updateEvents, environment}) =>
    cs(
        consumeContext("routing"),
        consumeContext("apis"),
        [
            "folders",
            ({apis, routing}, next) =>
                Load2({
                    _key: routing.params?.envId,
                    fetch: () => apis.data.getFolders(),
                    next,
                }),
        ],
        [
            "dataSources",
            ({folders}, next) => {
                let dataSources = flatten1(folders.value, (folder) => folder.dataSources);
                return next({value: dataSources});
            },
        ],
        [
            "addDataSourceService",
            ({dataSources}, next) =>
                AddDataSourceService({
                    currentDataSources: dataSources.value,
                    next,
                }),
        ],
        [
            "createFolderDialog",
            ({folders}, next) =>
                CreateFolderDialog({
                    apiType: "data",
                    folders: folders.value,
                    onCreate: (newFolder) => folders.onChange([...(folders.value || []), newFolder]),
                    next,
                }),
        ],
        [
            "onUpdateFolders",
            ({folders}, next) =>
                next((newFolders) => {
                    const res = folders.onChange(newFolders);
                    updateEvents.emit("reload");
                    return res;
                }),
        ],
        [
            "moveDsDialog",
            ({onUpdateFolders, folders}, next) =>
                MoveToDialog({
                    label: "DataSource",
                    apiType: "data",
                    folders: folders.value,
                    onDone: onUpdateFolders,
                    next,
                }),
        ],
        [
            "removeSourceDialog",
            ({onUpdateFolders, folders}, next) =>
                RemoveSourceDialog({
                    reloadFolderOnDone: true,
                    onDone: onUpdateFolders,
                    next,
                }),
        ],
        [
            "keyword",
            (_, next) =>
                UseState({
                    initValue: cSsJson("SEARCH_VALUE").get()?.datasources || "",
                    next,
                }),
        ],
        [
            "multiSelectCtrl",
            ({moveDsDialog, removeSourceDialog}, next) =>
                MultiSelect({
                    next,
                    itemName: "Source",
                    resolveItemKey: (item) => `${item.type}_${item.id}`,
                    actions: [
                        {
                            label: "Move To",
                            isShow: () => true,
                            onClick: async (selectedItems) => {
                                const updateItems = Object.values(selectedItems);
                                return await moveDsDialog.show({
                                    isUpdateMany: true,
                                    label: `${updateItems.length} ${noun(updateItems.length, "Source")}`,
                                    params: updateItems,
                                });
                            },
                        },
                        {
                            label: "Delete",
                            isShow: () => true,
                            onClick: async (selectedItems) => {
                                const updateItems = Object.values(selectedItems);
                                return await removeSourceDialog.show({
                                    dataSources: updateItems,
                                });
                            },
                        },
                    ],
                }),
        ],
        ({
            routing,
            dataSources,
            addDataSourceService,
            keyword,
            folders,
            createFolderDialog,
            removeSourceDialog,
            moveDsDialog,
            multiSelectCtrl,
        }) =>
            next({
                number: dataSources.value && dataSources.value.length,
                actions: !environment?.readOnly && (
                    <div className="data-sources-dashboard-actions-32d">
                        {SearchInputBar({state: keyword, type: "datasources"})}

                        {ButtonDropdown({
                            label: "New",
                            list: [
                                {
                                    value: 0,
                                    label: (
                                        <>
                                            <PlusIcon fill="#919EB0" /> Add New Data Source
                                        </>
                                    ),
                                },
                                {
                                    value: 1,
                                    label: (
                                        <>
                                            <NewFolderIcon fill="#919EB0" /> Create New Folder
                                        </>
                                    ),
                                },
                            ],
                            valueToLabel: (item) => item.label,
                            onChange: (item) => {
                                const maps = {
                                    0: async () => {
                                        if (dataSources.value) {
                                            await addDataSourceService.createNew({
                                                onDone: () => {
                                                    routing.goto("add-data-source");
                                                },
                                            });
                                        }
                                    },
                                    1: createFolderDialog.show,
                                };

                                return maps[item.value]();
                            },
                        })}
                    </div>
                ),
                content: cs(
                    [
                        "folderCM",
                        (_, next) =>
                            FolderCM({
                                apiType: "data",
                                folders,
                                next,
                                updateEvents,
                            }),
                    ],
                    [
                        "dataSourceCM",
                        ({}, next) =>
                            PopupMenu({
                                getCommands: (ds) => [
                                    ...(![dataSourceTypes.FILE].includes(ds.type)
                                        ? [
                                              {
                                                  label: "Connection Config",
                                                  onClick: () =>
                                                      routing.goto("edit-connection-settings", {
                                                          dsId: ds.id,
                                                          tab: "connection",
                                                          type: ds.type,
                                                      }),
                                              },
                                          ]
                                        : []),
                                    {
                                        label: "Data Settings",
                                        onClick: () =>
                                            routing.goto("edit-data-source", {
                                                dsId: ds.id,
                                                tab: "tables",
                                                type: ds.type,
                                            }),
                                    },
                                    {
                                        label: "Move To",
                                        onClick: () => moveDsDialog.show({params: ds}),
                                    },
                                    ...(!environment?.readOnly
                                        ? [
                                              {
                                                  label: "Delete",
                                                  className: "text-danger",
                                                  onClick: () => {
                                                      removeSourceDialog.show({
                                                          dataSources: [ds],
                                                      });
                                                  },
                                              },
                                          ]
                                        : []),
                                ],
                                next,
                            }),
                    ],
                    ({dataSourceCM, folderCM}) => {
                        const hasSelectedItems = multiSelectCtrl.numSelectedItems > 0;
                        const tableHeight = `calc(100vh - ${78 + 54 + 40 + (hasSelectedItems ? 52 : 0)}px)`;
                        const isFolder = (item) => item?.hasOwnProperty("children") || item?.hasOwnProperty("dataSources");
                        return (
                            <div className="data-sources-dashboard-content-32d">
                                {hasSelectedItems && multiSelectCtrl.render()}

                                {!folders.value
                                    ? LoadingIndicator()
                                    : RecursiveTable({
                                          label: "Data Sources",
                                          showNoResult: keyword.value?.length > 0,
                                          onClearSearch: () => keyword.onChange(""),
                                          maxHeight: tableHeight,
                                          getTrClass: (n) => multiSelectCtrl.isSelected(n) && "is-selected-tr",
                                          getChildTrClass: (n) => (isFolder(n) && multiSelectCtrl.isSelected(n) ? "is-selected-tr" : ""),
                                          structure: buildFoldersTree({
                                              itemsProp: "dataSources",
                                              folders: folders.value,
                                              keyword: keyword.value,
                                          }),
                                          isGroupNode: (n) => n?.dataSources,
                                          isAlwaysExpanded: () => !isBlank(keyword.value),
                                          onClickRow: (item, event) => {
                                              if (isFolder(item)) {
                                                  return;
                                              }
                                              event.preventDefault();
                                              event.stopPropagation();
                                              multiSelectCtrl.toggleSelect(item);
                                          },
                                          columns: [
                                              {
                                                  label: "Name",
                                                  indented: true,
                                                  format: (ds, {expanding}) => {
                                                      if (!ds.type) {
                                                          return (
                                                              <div
                                                                  className={cx("icon-name-34n", "folder", {
                                                                      expanding,
                                                                  })}
                                                              >
                                                                  {expanding ? FolderOpenIcon({}) : FolderIcon({})}
                                                                  {/* <img src={expanding ? require("../collections/icons/folder-open-icon.svg") : require("../collections/icons/folder-icon.svg")} alt={""}/> */}
                                                                  {ds.name}
                                                              </div>
                                                          );
                                                      }

                                                      return (
                                                          <div
                                                              className="database-name"
                                                              onClick={(e) => {
                                                                  e.preventDefault();
                                                                  e.stopPropagation();
                                                                  routing.goto("edit-data-source", {
                                                                      dsId: ds.id,
                                                                      type: ds.type,
                                                                  });
                                                              }}
                                                          >
                                                              {ds.name}
                                                          </div>
                                                      );
                                                  },
                                                  className: "data-source-name-f4h",
                                              },
                                              {
                                                  label: "Type",
                                                  format: (ds) => {
                                                      const typeNames = {
                                                          InboundAPI: "Inbound API",
                                                          OutboundAPI: "Outbound API",
                                                          AmazonDSP: "Amazon DSP",
                                                          SqlServer: "SQL Server",
                                                          MySql: "MySQL",
                                                          GoogleSheets: "Google Sheets",
                                                      };
                                                      return typeNames[ds.type] || ds.type;
                                                  },
                                                  className: "data-source-type-wr5",
                                              },
                                              {
                                                  label: "Tables/Objects",
                                                  format: (ds) => ds.tables,
                                              },
                                              {
                                                  label: "Latest Sync",
                                                  format: (ds) => {
                                                      if (!ds.type) return null;

                                                      if (!ds.lastSuccessfulSyncUtc && !ds.lastSyncAttemptUtc) return "";

                                                      const lastSuccessfulSyncUtc = new Date(
                                                          ds.lastSuccessfulSyncUtc || new Date().toUTCString()
                                                      );
                                                      const lastSyncAttemptUtc = new Date(
                                                          ds.lastSyncAttemptUtc || new Date().toUTCString()
                                                      );

                                                      if (!!environment?.readOnly) return <div>n/a</div>;

                                                      return (
                                                          <>
                                                              {ds.lastSuccessfulSyncUtc && (
                                                                  <div>
                                                                      {createDateFormatter("MMM d, yyyy @ h:mm tt").format(
                                                                          lastSuccessfulSyncUtc
                                                                      )}
                                                                      <img
                                                                          src={require("../../../common/icons/published-icon.svg")}
                                                                          alt="Last Successful Sync"
                                                                      />
                                                                  </div>
                                                              )}
                                                              {!ds.lastSuccessfulSyncUtc && (
                                                                  <div>
                                                                      {createDateFormatter("MMM d, yyyy @ h:mm tt").format(
                                                                          lastSyncAttemptUtc
                                                                      )}
                                                                      <img
                                                                          src={require("../../../common/icons/not-published-icon.svg")}
                                                                          alt="Latest Sync Attempt"
                                                                      />
                                                                  </div>
                                                              )}
                                                          </>
                                                      );
                                                  },
                                                  className: "data-source-last-sync-23t",
                                              },
                                              // {
                                              //     label: "Recent Records",
                                              //     format: (ds) => ds.type && (!!environment?.readOnly ? "n/a" : Number(ds.recentRecords).toLocaleString()),
                                              //     alignRight: true,
                                              //     className: "recent-records-y4g",
                                              //     info: "Last 24 Hours"
                                              // },
                                              // {
                                              //     label: "Total Records",
                                              //     format: (ds) => ds.type && Number(ds.records).toLocaleString(),
                                              //     alignRight: true,
                                              //     className: "total-records-6d3",
                                              // },
                                              {
                                                  label: "Status",
                                                  noPadding: true,
                                                  format: (ds) => {
                                                      const dsErrors = (ds.errors || []).filter((e) => !e.dataSourceTableID);

                                                      return (
                                                          ds.type &&
                                                          cs(
                                                              ["status", (_, next) => next(ds.status.toLowerCase())],
                                                              [
                                                                  "getConfigs",
                                                                  (_, next) =>
                                                                      next((list) => ({
                                                                          error: {
                                                                              list: list.filter(
                                                                                  (s) => !s.rootTableReferenceName && s.hasError
                                                                              ),
                                                                              text: "in error",
                                                                          },
                                                                          pendingfirstsync: {
                                                                              list: list.filter(
                                                                                  (s) =>
                                                                                      !s.rootTableReferenceName && s.paused && !s.hasError
                                                                              ),
                                                                              text: "paused",
                                                                          },
                                                                          healthy: {
                                                                              list: list.filter(
                                                                                  (s) =>
                                                                                      !s.rootTableReferenceName && !s.paused && !s.hasError
                                                                              ),
                                                                              text: [dataSourceTypes.FILE].includes(ds.type)
                                                                                  ? "synced"
                                                                                  : "syncing normally",
                                                                          },
                                                                      })),
                                                              ],
                                                              [
                                                                  "infoBox",
                                                                  ({status, getConfigs}, next) => {
                                                                      const getTooltipContent = () => {
                                                                          if (dsErrors.length > 0) {
                                                                              return (
                                                                                  <div className="data-sources-statuses-99o">
                                                                                      <div className="item error">
                                                                                          <i className="fa-solid fa-triangle-exclamation" />
                                                                                          <div>{dsErrors[0].title}</div>
                                                                                      </div>
                                                                                  </div>
                                                                              );
                                                                          }

                                                                          if (ds.tableStatuses) {
                                                                              const configs = getConfigs(ds.tableStatuses || []);

                                                                              return (
                                                                                  <div className="data-sources-statuses-99o">
                                                                                      {Object.keys(configs)
                                                                                          .filter((key) => configs[key].list.length > 0)
                                                                                          .map((key) => {
                                                                                              const tLength = configs[key].list.length;
                                                                                              return (
                                                                                                  <div
                                                                                                      className={cx("item", {
                                                                                                          key,
                                                                                                      })}
                                                                                                      key={key}
                                                                                                  >
                                                                                                      <div
                                                                                                          className={cx(
                                                                                                              "status-light",
                                                                                                              key
                                                                                                          )}
                                                                                                      />
                                                                                                      <div>
                                                                                                          {tLength}{" "}
                                                                                                          {noun(
                                                                                                              tLength,
                                                                                                              unstructuredDSTypes.includes(
                                                                                                                  ds.type
                                                                                                              )
                                                                                                                  ? `object`
                                                                                                                  : `table`
                                                                                                          )}{" "}
                                                                                                          {configs[key].text}
                                                                                                      </div>
                                                                                                  </div>
                                                                                              );
                                                                                          })}
                                                                                  </div>
                                                                              );
                                                                          }
                                                                          return null;
                                                                      };

                                                                      return StaticInfoBox({
                                                                          next,
                                                                          tooltipContent: getTooltipContent(),
                                                                      });
                                                                  },
                                                              ],
                                                              ({status, infoBox, getConfigs}) =>
                                                                  infoBox.render({
                                                                      content: ({active, ...props}) => {
                                                                          if (dsErrors.length > 0) {
                                                                              return (
                                                                                  <div
                                                                                      className={cx("status error", {
                                                                                          active: active,
                                                                                      })}
                                                                                      {...props}
                                                                                  >
                                                                                      <i className="fa-solid fa-triangle-exclamation" />
                                                                                  </div>
                                                                              );
                                                                          }

                                                                          const configs = getConfigs(ds.tableStatuses || []);
                                                                          const statuses = ds.tableStatuses
                                                                              ? Object.keys(configs).filter(
                                                                                    (key) => configs[key].list.length > 0
                                                                                )
                                                                              : [status];

                                                                          return (
                                                                              <div
                                                                                  className={cx("status", statuses[0], {
                                                                                      active: active,
                                                                                  })}
                                                                                  {...props}
                                                                              >
                                                                                  {statuses.map((s, i) => (
                                                                                      <div key={i} className={cx("status-light", s)} />
                                                                                  ))}
                                                                              </div>
                                                                          );
                                                                      },
                                                                  })
                                                          )
                                                      );
                                                  },
                                                  alignCenter: true,
                                                  className: "data-source-status-57s",
                                              },
                                              {
                                                  label: "Size*",
                                                  format: (ds) => ds.type && Number(ds.sizeMB).toLocaleString() + " MB",
                                                  alignRight: true,
                                                  className: "data-source-size-f4d",
                                              },
                                              {
                                                  format: (ds) => (
                                                      <div className="cm">
                                                          {ds.type ? dataSourceCM.render({params: ds}) : folderCM.render({params: ds})}
                                                      </div>
                                                  ),
                                                  className: "data-source-cm-12s",
                                                  shy: true,
                                                  alignRight: true,
                                              },
                                          ],
                                      })}
                            </div>
                        );
                    }
                ),
            })
    );
