import * as React from "react";
import {equalDeep} from "../../utils/objects";
import {VerticalBar} from "./vertical-bar/vertical-bar";
import {HorizontalBar} from "./horizontal-bar/horizontal-bar";
import {TableChart} from "./table/table-chart";
import {TableKPI} from "./table-kpi/table-kpi";
import {SingleKPI} from "./single-kpi/single-kpi";
import {ListKPI} from "./list-kpi/list-kpi";
import {Line} from "./line/line";
import {PieChart} from "./pie/pie-chart";
import {GaugeChart} from "./gauge/gauge-chart";
import {DownloadableReport} from "./downloadable-report/downloadable-report";
import {ComboChart} from "./combo-chart/combo-chart";
import {pieChartRestyling} from "./chart-restyling/pie-chart-restyling";
import {chartRestyling} from "./chart-restyling/chart-restyling";
import {MapTile} from "./map-tile/map-tile";
import {mapTileRestyling} from "./chart-restyling/map-tile-restyling";
import {PivotTableChart} from "./pivot-table/pivot-table-chart";
import {FunnelChart} from "./funnel/funnel-chart";
import {funnelChartRestyling} from "./chart-restyling/funnel-chart-restyling";
import {ScatterPlot} from "./scatter-plot/scatter-plot";
import {VennDiagram} from "./venn-diagram/venn-diagram";
import {vennDiagramRestyling} from "./chart-restyling/venn-diagram-restyling";
import {TextChart} from "./text-chart/text-chart";
import {scatterPlotRestyling} from "./chart-restyling/scatter-plot-restyling";
import {BubbleChart} from "./bubble/bubble-chart";
import {SparkLineKpi} from "@common/ui-components/charts/spark-line-kpi/spark-line-kpi";
import {BoxPlot} from "@common/ui-components/charts/box-plot/box-plot";
import {TextLabel} from "@common/ui-components/charts/text-label/text-label";

const applyMemoComponent = (compFunc) => {
    return React.memo(compFunc, (prevProps, nextProps) => {
        if (!equalDeep(prevProps.tileFilters?.invalid, nextProps.tileFilters?.invalid)) {
            return false;
        }
        if (!equalDeep(prevProps.tileFilters?.key, nextProps.tileFilters?.key)) {
            return false;
        }

        if (!equalDeep(prevProps.loadData, nextProps.loadData)) {
            return false;
        }

        if (!equalDeep(prevProps.tileFilters?.getValue(), nextProps.tileFilters?.getValue())) {
            return false;
        }
        if (!equalDeep(prevProps.tile, nextProps.tile)) {
            return false;
        }

        if (!equalDeep(prevProps.theme, nextProps.theme)) {
            return false;
        }

        if (!equalDeep(prevProps.size, nextProps.size)) {
            return false;
        }

        return true;
    });
};

export const chartTypes = {
    VerticalBarChartTile: applyMemoComponent(VerticalBar),
    HorizontalBarChartTile: applyMemoComponent(HorizontalBar),
    TableTile: applyMemoComponent(TableChart),
    TableKPITile: applyMemoComponent(TableKPI),
    SingleKPITile: applyMemoComponent(SingleKPI),
    ListKPITile: applyMemoComponent(ListKPI),
    LineChartTile: applyMemoComponent(Line),
    PieChartTile: applyMemoComponent(PieChart),
    DonutChartTile: applyMemoComponent(PieChart),
    GaugeTile: applyMemoComponent(GaugeChart),
    DownloadReportTile: applyMemoComponent(DownloadableReport),
    ComboChartTile: applyMemoComponent(ComboChart),
    MapTile: applyMemoComponent(MapTile),
    PivotTableTile: applyMemoComponent(PivotTableChart),
    FunnelChartTile: applyMemoComponent(FunnelChart),
    ScatterPlotTile: applyMemoComponent(ScatterPlot),
    BubbleChartTile: applyMemoComponent(BubbleChart),
    VennDiagramTile: applyMemoComponent(VennDiagram),
    TextTile: applyMemoComponent(TextChart),
    BoxPlotTile: applyMemoComponent(BoxPlot),
    SparkLineKPITile: applyMemoComponent(SparkLineKpi),
    TextLabelTile: applyMemoComponent(TextLabel),
};

export const getChartRestyling = (type) =>
    ({
        PieChartTile: pieChartRestyling,
        DonutChartTile: pieChartRestyling,
        MapTile: mapTileRestyling,
        FunnelChartTile: funnelChartRestyling,
        VennDiagramTile: vennDiagramRestyling,
        ScatterPlotTile: scatterPlotRestyling,
    }[type] || chartRestyling);

const tileTypes = [
    {
        types: ["SingleKPITile"],
        name: "Single KPI",
    },
    {
        types: ["TableKPITile"],
        name: "Table KPI",
    },
    {
        types: ["ListKPITile"],
        name: "KPI List",
    },
    {
        types: ["GaugeTile"],
        name: "Gauge",
    },
    {
        types: ["VerticalBarChartTile"],
        name: "Vertical Bar",
    },
    {
        types: ["HorizontalBarChartTile"],
        name: "Horizontal Bar",
    },
    {
        types: ["LineChartTile"],
        name: "Line",
    },
    {
        types: ["LineChartTile"],
        name: "Area Line",
    },
    {
        types: ["PieChartTile", "DonutChartTile"],
        name: "Pie/Donut",
    },
    {
        types: ["DownloadReportTile"],
        name: "Downloadable Report",
    },
    {
        types: ["TableTile"],
        name: "Table",
    },
    {
        types: ["ComboChartTile"],
        name: "Combo",
    },
    {
        types: ["MapTile"],
        name: "Map",
    },
    {
        types: ["FunnelChartTile"],
        name: "Funnel",
    },
    {
        types: ["ScatterPlotTile"],
        name: "Scatter Plot",
    },
    {
        types: ["BoxPlotTile"],
        name: "Box Plot",
    },
    {
        types: ["BubbleChartTile"],
        name: "Bubble",
    },
    {
        types: ["VennDiagramTile"],
        name: "Venn Diagram",
    },
    {
        types: ["SparkLineKPITile"],
        name: "Spark Line KPI",
    },
    {
        types: ["TextLabelTile"],
        name: "Text Label",
    },
];

export const getTileName = (type) => tileTypes.find((tt) => tt.types.includes(type))?.name ?? type;
