import React from "react";
import {cs} from "@common/react/chain-services";
import {DropdownSelect2} from "@common/ui-components/dropdown-select/dropdown-select2";
import {Expandable} from "../../../../../../common/expandable/expandable";
import "./append-rows-configuration.scss";
import {rFieldTypeIcon} from "../../../../../../common/field-type-icon/get-field-type-icon";
import {cx} from "emotion";
import {keyed} from "@common/react/keyed";
import {spc} from "@common/react/state-path-change";
import {TableSelect} from "../../../../common/table-select/table-select";
import {consumeContext} from "@common/react/context";
import {unique} from "@common/utils/collections";
import {equalDeep} from "@common/utils/objects";

export const AppendRowsConfiguration = ({step, transformation, model, form}) =>
    cs(
        consumeContext("getModelColumn"),
        [
            "renderColumns",
            (_, next) =>
                next(({list: columns}) =>
                    cs(() => (
                        <div className="columns-details">
                            {columns.map((column, index) => {
                                return cs(keyed(index), () => (
                                    <div className="column-item">
                                        {rFieldTypeIcon(column?.dataType)}
                                        <div className="name">
                                            {column?.visualNameShort || column?.name}
                                            {column?.reason === "MissingFromSource" ? (
                                                <span className="issue">Missing in Source</span>
                                            ) : column?.reason === "DataTypeMismatch" ? (
                                                <span className="issue">Data Type Mismatch</span>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </div>
                                ));
                            })}
                        </div>
                    ))
                ),
        ],
        ({renderColumns, getModelColumn}) => {
            const getDetailPrevStep = (step) => {
                if (["ChildDatasetStep", "ModelTableDatasetStep"].includes(step.$type)) {
                    return {
                        ...step,
                        visualName: model.tables.find((t) => t.id === step.modelTableID)?.name,
                    };
                }
                return step;
            };

            const prevStep1 = getDetailPrevStep(transformation.value.steps.find((s) => s.id === step.value.inputStep1ID));
            const prevStep2 = getDetailPrevStep(transformation.value.steps.find((s) => s.id === step.value.inputStep2ID));

            const decorateColumn = (column, sourceStep) => {
                const col = column.$type === "ModelStepColumn" ? getModelColumn(column) : column;
                column.name = col.name;
                column.dataType = col.dataType;
                column.visualNameShort = col.visualNameShort || column.name;
                column.stepID = sourceStep.id;

                return column;
            };

            const colsInPrimary = (step?.value?.primaryStepID == prevStep1?.id ? prevStep1 : prevStep2)?.outputColumns?.map((column) => decorateColumn(column, prevStep1)) || [];

            return (
                <div className="append-rows-configuration-4rt">
                    <div className="config-group">
                        <div className="label">Select Source Step</div>

                        {DropdownSelect2({
                            label: "Source Step",
                            list: [
                                {
                                    name: prevStep1.visualName ?? prevStep1.name,
                                    id: prevStep1.id,
                                },
                                {
                                    name: prevStep2.visualName ?? prevStep2.name,
                                    id: prevStep2.id,
                                },
                            ]
                                .filter((s) => s.id)
                                .sort((x, y) => (x.name.toLowerCase() < y.name.toLowerCase() ? -1 : 1)),
                            isSelected: (v) => v.id === step?.value?.primaryStepID,
                            valueToLabel: (v) => v.name,
                            onChange: (v) => spc(step, ["primaryStepID"], () => v.id),
                        })}
                    </div>

                    <div className="config-group">
                        <div className="label">Manage Duplicate rows</div>
                        {DropdownSelect2({
                            label: "Action",
                            list: ["Keep Duplicate Rows", "Remove Duplicate Rows"],
                            isSelected: (v) => v === (step?.value?.removeDuplicates ? "Remove Duplicate Rows" : "Keep Duplicate Rows"),
                            onChange: (v) => spc(step, ["removeDuplicates"], () => v === "Remove Duplicate Rows"),
                        })}
                    </div>

                    {step?.value?.primaryStepID && (
                        <>
                            {(() => {
                                return Expandable({
                                    label: "Included columns",
                                    control: `${colsInPrimary.length} column(s) will be included`,
                                    render: () =>
                                        renderColumns({
                                            list: colsInPrimary,
                                            getModelColumn,
                                        }),
                                });
                            })()}

                            {(() => {
                                return step?.value?.unavailableColumns?.length
                                    ? Expandable({
                                          label: "Removed columns",
                                          control: (
                                              <>
                                                  {step.value.unavailableColumns.length} column(s) will be removed &nbsp; <img src={require("../alert-octagon.svg")} />
                                              </>
                                          ),
                                          render: () =>
                                              renderColumns({
                                                  list: step.value.unavailableColumns,
                                                  getModelColumn,
                                                  addTableName: true,
                                              }),
                                      })
                                    : null;
                            })()}
                        </>
                    )}
                </div>
            );
        }
    );
