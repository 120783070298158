import "./vertical-bar.scss";

import * as React from "react";

import {cs} from "../../../react/chain-services";
import {UseState} from "../../../react/use-state";
import {consumeContext} from "../../../react/context";
import {getFormatters} from "../factory/formatters/formatters";
import {getMeasurementAxesOptions} from "../factory/measurement-axes/measurement-axes";
import {getTooltipPerSeriesOption} from "../factory/tooltip/tooltip-series-option";
import {isComparing} from "../factory/check-compare";
import {getDateFilterValue} from "../factory/get-date-filter-value";
import {getDimensionAxisOptions} from "../factory/dimension-axis/dimension-axis";
import {chartElementTooltipService} from "../factory/custom-events/chart-element-tooltip-service";
import {isEmpty, keepOnly} from "../../../utils/objects";
import {isDataValid, loadTileData} from "../common/load-tile-data";
import {getFieldType} from "../common/get-field-type";
import {SwapAxis} from "../common/swap-axis/swap-axis";
import {MenuOptions} from "../common/menu-options/menu-options";
import {NoDataScreen} from "../common/no-data-screen/no-data-screen";
import {ActionMenu} from "../common/action-menu/action-menu";
import {RenderChart} from "../render-chart/render-chart";
import {getVerticalBarOptions} from "./chart-options/get-vertical-bar-options";
import {groupCompareBarSeries} from "../bar-series-processors/group-compare-bar-series";
import {groupNonCompareBarSeries} from "../bar-series-processors/group-non-compare-bar-series";
import {cascadeFind} from "../../../utils/cascade";
import {isDimRealDateTime} from "../factory/is-dim-real-date-time";
import {rawDataProcessor} from "../data-processor/raw-data-processor";
import {rawDataManipulator} from "../raw-data-manipulator/raw-data-manipulator";
import {flatten1} from "../../../utils/collections";
import {ChartInnerLayout} from "../chart-layout/chart-inner-layout";
import {loadTileFields} from "../get-field-color";
import {generateReferenceSeries} from "../reference-lines/generate-reference-series";
import {IgnoreUpdate} from "../../../react/ignore-update";
// import {ControlFilterWrapper} from "../common/control-filter/control-filter-wrapper";
// import {applyControlFilterForVerticalBar} from "../common/control-filter/control-filter-common";
// import {RemoveTileControlFilter} from "../common/control-filter/remove-tile-control-filter";
// import { tooltipService3 } from "../../../../web-client/src/routes/common/tooltip3/tooltip-service-3";
import {BlankDateWarning} from "../common/blank-date-warning";
import {RemoveTileControlFilter} from "../common/control-filter/remove-tile-control-filter";
import {CurrencyConversionWarning, getCurrencyConversion} from "../common/currency-conversion-warning/currency-conversion-warning";
import {fragments} from "../../../react/fragments";
import {Watch} from "../../../react/watch";

export const VerticalBar = ({tile, tileFilters, hasSwapAxis, theme, loadData, size, downloadData, chartRef, defaultData, overrideTile, disabledTileActions = false}) =>
    cs(
        consumeContext("sdkStaticUrl"),
        consumeContext("tileActionControlFilter"),
        [
            "chartData",
            ({tileActionControlFilter}, next) =>
                defaultData
                    ? next(defaultData)
                    : loadTileData({
                          next,
                          tileFilters,
                          loadData,
                          size,
                          tile,
                          theme,
                          tileKey: JSON.stringify([keepOnly(tile, ["limit", "sort", "xAxisField", "yAxisFields", "groupField", "filters", "limit", "sort", "categorySort"]), tile.style.xAxis.dateGaps]),
                          disabled: tileActionControlFilter?.value?.tile?.id == tile.id,
                          removeJSCursor: true,
                      }),
        ],
        consumeContext("selectedTimezone"),

        ["rawData", ({chartData}, next) => next(rawDataManipulator({tile, rawData: chartData}))],

        ({rawData}, next) => (!isDataValid(rawData) ? NoDataScreen({size, theme}) : next()),

        ["isCompare", ({rawData}, next) => next(isComparing(rawData, tile))],
        [
            "isDimensionRealDate",
            ({rawData}, next) =>
                next(
                    isDimRealDateTime({
                        tile,
                        dimensionAttr: "xAxisField",
                        series: rawData.series,
                    })
                ),
        ],

        [
            "rawData2",
            ({isCompare, rawData, isDimensionRealDate}, next) =>
                next(
                    rawDataProcessor({
                        tile,
                        rawData,
                        isDimensionRealDate,
                        isCompare,
                    })
                ),
        ],

        [
            "formatters",
            ({rawData2, selectedTimezone}, next) =>
                next(
                    getFormatters({
                        tile,
                        timezone: selectedTimezone?.value,
                        dimensionAttr: "xAxisField",
                        measurementAttrs: ["yAxisFields"],
                        rawData: rawData2,
                    })
                ),
        ],

        ["chartElementTooltip", (_, next) => next(chartElementTooltipService())],

        [
            "tooltipOptions",
            ({formatters, isCompare, rawData2, isDimensionRealDate}, next) =>
                next(
                    getTooltipPerSeriesOption({
                        tile,
                        theme,
                        styleMeasurementAttr: "yAxis",
                        formatters,
                        isDimensionDate: getFieldType(tile["xAxisField"]) === "date",
                        isDimensionRealDate,
                        isCompare,
                        dateFilterValue: getDateFilterValue(rawData2.series),
                        isBarClassic: !tile.style.displayType || tile.style.displayType === "Classic",
                        rawData: rawData2,
                    })
                ),
        ],

        [
            "tileFields",
            ({}, next) =>
                loadTileFields({
                    next,
                    configs: {
                        tile,
                        dimensionAttr: "xAxisField",
                        measureGroupAttrs: ["yAxisFields"],
                        groupFieldAttr: "groupField",
                        isBarFields: ["yAxisFields"],
                        checkUnique: false,
                    },
                }),
        ],

        [
            "data",
            ({rawData2, rawData, tooltipOptions, formatters, isCompare, tileFields, tileActionControlFilter}, next) => {
                const groupFn = isCompare ? groupCompareBarSeries : groupNonCompareBarSeries;
                return next(
                    groupFn({
                        series: rawData2.series,
                        tile,
                        theme,
                        tileFields,
                        isDimensionDate: getFieldType(tile["xAxisField"]) === "date",
                        isBarDataColorAppliedByDimension: tile?.style?.barDataColorAppliedBy === "Dimension" &&
                            !cascadeFind(tile.yAxisFields, "[*].measureFields", (value) => value.length > 1) &&
                            getFieldType(tile["xAxisField"]) !== "date" &&
                            isEmpty(tile?.groupField),
                        isCompare,
                        tooltipOptions,
                        measurementFormatters: formatters.measurementFormatters,
                    })
                );
            },
        ],

        [
            "yAxisKey",
            ({data}, next) =>
                UseState({
                    next: ({value, onChange}) =>
                        next({
                            value: value == null || data[value] == null ? Object.keys(data)[0] : value,
                            onChange,
                        }),
                }),
        ],

        [
            "referenceSeries",
            ({yAxisKey, data, formatters}, next) =>
                next(
                    generateReferenceSeries({
                        tile,
                        theme,
                        formatters,
                        series: tile.style.multipleAxisOption === "IndependentAxes" ? flatten1(Object.values(data).map(({series}) => series)) : data[yAxisKey.value].series,
                    })
                ),
        ],

        [
            "yAxisOptions",
            ({formatters, data, rawData2, referenceSeries, isCompare, chartElementTooltip}, next) =>
                next(
                    getMeasurementAxesOptions({
                        tile,
                        theme,
                        data,
                        rawData: rawData2,
                        referenceSeries,
                        isCompare,
                        styleMeasurementAttr: "yAxis",
                        measurementFormatters: formatters.measurementFormatters,
                        chartElementTooltip,
                    })
                ),
        ],

        [
            "xAxisOptions",
            ({formatters, rawData2, data, yAxisKey, isCompare, chartElementTooltip}, next) =>
                next(
                    getDimensionAxisOptions({
                        tile,
                        theme,
                        rawSeries: rawData2.series.filter((s) => s.stack === yAxisKey.value),
                        series: data[yAxisKey.value].series,
                        isCompare,
                        dimensionAttr: "xAxisField",
                        styleDimensionAttr: "xAxis",
                        dimensionFormatter: formatters.dimensionFormatter,
                        chartElementTooltip,
                    })
                ),
        ],

        [
            "controls",
            ({data, yAxisKey, tileActionControlFilter, rawData, tileFields}, next) => {
                const seriesKeys = Object.keys(data);
                const isIndependentAxes = tile.style.multipleAxisOption === "IndependentAxes";
                const hasMenuOptions = tile.style.showDownloadData || tile.style.showDownloadImage;
                // const hideRemoveTileControlFilter = tileActionControlFilter.value?.action?.value && tileActionControlFilter.value?.tile.id == tile.id;
                const hasAlternativeAxes = !isIndependentAxes && seriesKeys.length > 1;

                return next(
                    fragments(
                        Watch({
                            value: !!hasAlternativeAxes,
                            onChanged: (changed) => hasSwapAxis?.onChange?.(!!changed),
                            initRun: true,
                        }),
                        <div className="controls">
                            {CurrencyConversionWarning({
                                currencyConversion: getCurrencyConversion({
                                    $type: rawData.$type,
                                    data: rawData,
                                    tileFields,
                                }),
                                theme,
                            })}

                            {BlankDateWarning({
                                theme,
                                isDimensionRealDate: isDimRealDateTime({
                                    tile,
                                    dimensionAttr: "xAxisField",
                                    series: rawData?.series,
                                    noLimit: true,
                                }),
                                filters: tileFilters.getValue(),
                            })}

                            {RemoveTileControlFilter({
                                tile,
                                hideBorder: !hasAlternativeAxes && !hasMenuOptions,
                            })}

                            {hasMenuOptions &&
                                MenuOptions({
                                    chartRef,
                                    theme,
                                    tile,
                                    downloadData,
                                    tileFilters,
                                })}

                            {hasAlternativeAxes &&
                                SwapAxis({
                                    theme,
                                    tile,
                                    list: [
                                        {
                                            choices: seriesKeys,
                                            onSelect: (k) => yAxisKey.onChange(k),
                                            isSelected: (k) => yAxisKey.value === k,
                                            valueToLabel: (key) => tile.style.yAxis[key].label || data[key].label,
                                        },
                                    ],
                                })}
                        </div>
                    )
                );
            },
        ],

        ({rawData, data, controls}, next) =>
            ChartInnerLayout({
                size,
                tile,
                theme,
                next,
                noData: !isDataValid(rawData),
                hasControls: !!controls,
            }),

        [
            "actionMenu",
            ({formatters}, next) =>
                ActionMenu({
                    tile,
                    overrideTile,
                    dimensionFormatter: formatters.dimensionFormatter,
                    disabledTileActions,
                    next,
                }),
        ],

        [
            "onClickPoint",
            ({actionMenu, tileFields, data}, next) => {
                if (!actionMenu.hasActions()) {
                    return next();
                }
                return next(({point, ...other}) => {
                    const dimensionField = tileFields.find((f) => f.$type == "DimensionTileField");
                    const dimensionValue = point.name ?? point.category;

                    const groupField = tileFields.find((f) => f.$type == "CategoryTileField");
                    let groupValue = null;
                    let fieldToValue = {
                        [dimensionField.id]: dimensionValue,
                    };

                    if (groupField) {
                        groupValue = point.series.name;
                        fieldToValue[groupField.id] = groupValue;
                    }

                    const _fieldToValue = tileFields
                        .filter((f) => f.$type == "MeasureTileField")
                        .reduce((obj, field) => {
                            let items = null;
                            if (groupField) {
                                const series = data[field.id].series;
                                items = series.find((s) => s.name == groupValue)?.data;
                            } else {
                                const key = Object.keys(data).find((k) => k.indexOf(field.id) >= 0);
                                items = data[key].series.find((s) => s.tileFieldID == field.id)?.data;
                            }

                            obj[field.id] = items?.find((d) => d[0] == dimensionValue)?.[1] ?? null;
                            return obj;
                        }, {});

                    const tileActionData = point.series?.userOptions?.tileActionData;

                    tileActionData?.columns.forEach((c, index) => {
                        fieldToValue[c.tileFieldID] = tileActionData.data[index];
                    });

                    actionMenu.show({
                        point,
                        ...other,
                        fieldToValue: {
                            ...fieldToValue,
                            ..._fieldToValue,
                        },
                    });
                });
            },
        ],

        ({selectedTimezone}, next) =>
            IgnoreUpdate({
                next,
                props: {timezoneId: selectedTimezone?.value},
                when: ({timezoneId}) => timezoneId != selectedTimezone?.value,
            }),

        ({yAxisKey, data, rawData2, referenceSeries, isCompare, isDimensionRealDate, yAxisOptions, xAxisOptions, formatters, chartElementTooltip, actionMenu, onClickPoint, controls}) => {
            const isIndependentAxes = tile.style.multipleAxisOption == "IndependentAxes";

            let options = {
                ...getVerticalBarOptions({
                    tile,
                    theme,
                    isCompare,
                    rawData: rawData2,
                    isDimensionRealDate,
                    formatters,
                    chartElementTooltip,
                    onClickPoint,
                }),
                xAxis: xAxisOptions,
                yAxis: !isIndependentAxes ? yAxisOptions[yAxisKey.value].options : Object.values(yAxisOptions).map(({options}) => options),
                series: [...(!isIndependentAxes ? data[yAxisKey.value].series : flatten1(Object.values(data).map(({series}) => series))), ...(referenceSeries || [])],
            };

            const currentXAxis = Array.isArray(options.xAxis) ? options.xAxis.find((item) => item.visible !== false) : options.xAxis;

            if (actionMenu?.hasActions() && currentXAxis.labels.enabled) {
                currentXAxis.labels.events = {
                    click: function ({x, y}) {
                        actionMenu?.show?.({
                            x,
                            y,
                            point: {category: this.pos, y: null},
                            onlyDimensionField: true,
                            chart: this.chart,
                        });
                    },
                };
            }

            return (
                <div
                    className="vertical-bar-2wd"
                    key={getRefreshKey({
                        tile,
                        theme,
                        tileFiltersValue: tileFilters.getValue(),
                        key: yAxisKey.value,
                    })}
                >
                    <div className="chart">
                        <RenderChart
                            {...{
                                chartRef,
                                options,
                                size,
                                theme,
                                tile,
                                formatters,
                            }}
                        />
                    </div>

                    {controls}
                </div>
            );
        }
    );

const getRefreshKey = ({tile, theme, tileFiltersValue, key}) => JSON.stringify([keepOnly(tile, ["id", "style", "filters", "groupField", "xAxisField", "yAxisFields", "limit", "sort", "categorySort", "referenceLines"]), theme.dataVisualization.toolTipsAndLegends, key, tileFiltersValue]);
