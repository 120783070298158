import * as React from "react";
import {cs} from "@common/react/chain-services";
import {ShowHideToggle} from "../../../../../../../../../../../../../common/form/toggles/show-hide-toggle";
import {scope} from "@common/react/scope";
import "./legend.scss";
import {MapLegendPositionSelect} from "./map-legend-position-select";
import {LegendSizeSelect} from "../../../common/legend-size-select";

export const Legend = ({tile, size}) => {
    const rControl = () => {
        return ShowHideToggle({
            state: scope(tile, ["style", "legendStyle", "legendShown"]),
        });
    };

    return {
        label: "Legend",
        control: rControl(),
        render: () =>
            cs(() => (
                <div className="map-tile-legend-4fs">
                    <div className="position-select has-tooltip">
                        {MapLegendPositionSelect(scope(tile, ["style", "legendStyle", "legendPosition"]), size)}
                        {LegendSizeSelect(
                            scope(tile, ["style", "legendStyle", "legendSize"]),
                            tile.value.style.legendStyle,
                            true // useWidthDescription
                        )}
                    </div>
                </div>
            )),
    };
};
