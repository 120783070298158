import React from "react";
import {flatten1} from "../../../../../utils/collections";
import {cx} from "emotion";
import {formatMoney} from "../../../../../utils/math-util";
import {cs} from "../../../../../react/chain-services";
import {consumeContext} from "../../../../../react/context";
import {UseState} from "../../../../../react/use-state";
import {Watch} from "../../../../../react/watch";

export const LegendItemView = (props) => {
    const {tile, series, hiddenSeries, chart, options, formatters, theme, getMaxLegendSubText, key, renderComparison} = props;
    return cs(
        consumeContext("tileActionControlFilter"),
        ["defaultColor", (_, next) => UseState({next, initValue: null})],
        ({tileActionControlFilter, defaultColor}, next) =>
            Watch({
                next,
                initRun: true,
                value: series,
                onChanged: (series) => {
                    defaultColor.onChange(series.userOptions ? (series.userOptions?.color?.linearGradient ? series.userOptions?._baseColor : series.userOptions?.color) : series.color);
                },
            }),
        ({tileActionControlFilter, defaultColor}) => {
            const {$type, style} = tile;

            const isLineTypeSeries = series.type?.indexOf("line") > -1 || series.userOptions?.customType?.includes("ReferenceLine");

            const isHidden = (series) => hiddenSeries.value.find((s) => s.name === series.name && s.color === series.color);
            const iconClassName = ["PieChartTile", "DonutChartTile", "GaugeTile", "VennDiagramTile"].includes($type) ? "bar-icon" : isLineTypeSeries ? "line-icon" : "bar-icon";

            const color = isHidden(series) ? "rgb(204, 204, 204)" : tileActionControlFilter.value?.tile?.id == tile.id ? defaultColor.value : series.userOptions ? (series.userOptions?.color?.linearGradient ? series.userOptions?._baseColor : series.userOptions?.color) : series.color;
            return (
                <div
                    className="legend-item"
                    key={key}
                    onClick={() => {
                        if ($type !== "GaugeTile") {
                            const visible = !isHidden(series);

                            const newHiddenSeries = !visible ? hiddenSeries.value.filter((s) => s.name !== series.name || s.color !== series.color) : [...hiddenSeries.value, {name: series.name, color: series.color}];

                            hiddenSeries.onChange(newHiddenSeries);

                            if (["PieChartTile", "DonutChartTile", "VennDiagramTile"].includes($type)) {
                                let _s = flatten1(chart.series.map((s) => s.data)).filter((s) => s.name === series.name);
                                _s.forEach((item) => item.setVisible(!visible));
                            } else {
                                if (["ConstantValueReferenceLine", "AggregateValueReferenceLine"].includes(series.userOptions?.customType)) {
                                    if (series.visible) {
                                        series.setVisible(false);
                                        series.yAxis.removePlotLine(series.userOptions.plotLineOptions.id);
                                    } else {
                                        series.setVisible(true);
                                        series.yAxis.addPlotLine(series.userOptions.plotLineOptions);
                                    }
                                    return;
                                }

                                let _s = chart.series.filter((s) => s.name === series.name && s.color === series.color);
                                _s.forEach((item) => item.setVisible(!visible));

                                // for bars, lines, combo chart with classic comparison bars, remove the old comparisons bars
                                chart.comparisonBars?.remove();

                                chart.axes
                                    .filter((axis) => !axis.isXAxis)
                                    .forEach((yAxis) => {
                                        if (yAxis.userOptions.lineWidth || yAxis.axisTitle) {
                                            for (const s of yAxis.series) {
                                                // using "update" method of chart object mutates the chart and the options passed into HighchartsReact,
                                                // so manually adjust the svg element instead.
                                                if (!newHiddenSeries.includes(s.name)) {
                                                    yAxis.userOptions.lineWidth && yAxis.axisLine.element.setAttribute("stroke-width", 1);
                                                    yAxis.axisTitle?.element.setAttribute("opacity", 1);
                                                    return;
                                                }
                                            }
                                            yAxis.axisLine.element.setAttribute("stroke-width", 0);
                                            yAxis.axisTitle?.element.setAttribute("opacity", 0);
                                        }
                                    });
                            }

                            // for bars, lines, combo chart with classic comparison bars, render new comparisons bars,
                            // highcharts animation duration is 500 so the latter 500 is set here
                            setTimeout(
                                () =>
                                    chart.comparisonBars?.rerender({
                                        chart,
                                        duration: 500,
                                    }),
                                500
                            );
                        }
                    }}
                >
                    <div
                        className={cx("icon", iconClassName)}
                        style={{
                            background: color,
                            borderRadius: theme.dataVisualization.toolTipsAndLegends.legendSymbol == "Circle" && !isLineTypeSeries ? "50%" : "",
                        }}
                    />
                    <div
                        className="legend-text"
                        style={{
                            fontSize: `${options.legend.itemStyle.fontSize}px`,
                            color: `${theme.general.canvas.fontColorRGB}`,
                            paddingRight: `${getMaxLegendSubText() + 5}px`,
                        }}
                    >
                        {getLegendItemName({tile, formatters})(series)}&nbsp;
                        <span className="legend-sub-text">
                            {
                                {
                                    LabelAndValue: (formatters?.valueFormatter || formatMoney)(getLegendItemValue(tile)(series)),
                                    LabelAndPercent: formatters?.percentFormatter ? formatters.percentFormatter(getLegendItemPercent(tile)(series)) : `${Math.ceil(getLegendItemPercent(tile)(series))}%`,
                                }[style.legendStyle.legendContent]
                            }

                            {renderComparison?.(series)}
                        </span>
                    </div>
                </div>
            );
        }
    );
};

export const getLegendItemName = ({tile, formatters}) => {
    if (["PieChartTile", "DonutChartTile"].includes(tile.$type) || (["VerticalBarChartTile", "HorizontalBarChartTile", "ComboChartTile", "LineChartTile"].includes(tile.$type) && tile.groupField != null)) {
        return (series) => formatters.groupFieldFormatter(series.name);
    }

    if (tile.$type === "VennDiagramTile") {
        return (item) => item.custom.displayName; // ref. decorate-venn-data.js
    }

    return (series) => series.name;
};

export const getLegendItemValue = (tile) => {
    // sometimes we need to use another value, e.g. venn diagram tile
    if (tile.$type === "VennDiagramTile") {
        return (series) => series.custom.exclusiveValue;
    }
    return (series) => series.y;
};

const getLegendItemPercent = (tile) => {
    // sometimes there are values defined in `data.custom`, e.g. "percent" of venn diagram tile
    if (tile.$type === "VennDiagramTile") {
        return (series) => series.custom.exclusivePercent;
    }

    if (["PieChartTile", "DonutChartTile"].includes(tile.$type)) {
        return (dataPoint) => dataPoint.realPercentage;
    }

    return (series) => series.percentage;
};
