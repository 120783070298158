import {cs} from "../../react/chain-services";
import * as React from "react";
import {Toggle} from "../toggle/toggle";

export const OnOffToggle = ({state, disabled, allowUndefined, colors, ...other}) =>
    Toggle({
        state,
        disabled,
        ...other,
        left: {
            label: "On",
            color: "#18c96e",
        },
        right: {
            label: "Off",
            color: colors?.right || "#E9ECEF",
        },
        ...(allowUndefined && {
            undefined: {
                color: "#9caab5",
            },
        }),
    });
