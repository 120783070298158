import {flatten1, sum, unique} from "../../../../utils/collections";
import {chain} from "../../../../utils/fs";
import {getAutoMaxValuesInView} from "./get-auto-max-values-in-view";
import {isChartBoosted} from "../render-chart-helper";
import {getBoxPlotAutoMaxValuesWidthInView} from "@common/ui-components/charts/render-chart/scrolling/get-box-plot-auto-max-values-width-in-view";

export const addScrollingOptions = ({tile, options, chartWidth, chartHeight}) => {
    // when chart is in boost mode, the columns are rendered as 1px lines
    // apply scrolling makes some of the lines disappear since they are too thin.
    if (isChartBoosted(options)) {
        return options;
    }

    const {$type, style} = tile;

    // AutoMax: show all, no scrolling
    if ((style.xAxis.maxValuesInView || style.yAxis.maxValuesInView) === "AutoMax") {
        return options;
    }

    // if max values in view is fixed but no quantity provided then no scrolling (except BoxPlotTile b.c we're not display this config)
    if (
        !["BoxPlotTile"].includes($type) &&
        (style.xAxis.maxValuesInView || style.yAxis.maxValuesInView) === "Fixed" &&
        !(style.xAxis.maxValuesInViewFixed || style.yAxis.maxValuesInViewFixed)
    ) {
        return options;
    }

    // Line chart: Auto is the same as before (show all)
    if ($type === "LineChartTile" && style.xAxis.maxValuesInView.startsWith("Auto")) {
        return options;
    }

    // Combo chart without bars: any Auto option shows all
    if ($type === "ComboChartTile" && !tile.yAxisBarFields?.length && style.xAxis.maxValuesInView.startsWith("Auto")) {
        return options;
    }

    if (["VerticalBarChartTile", "LineChartTile", "ComboChartTile", "BoxPlotTile"].includes($type)) {
        return {
            ...options,
            chart: {
                ...options.chart,
                spacingBottom: 20, // for scrollbar not covering x-axis
                scrollablePlotArea: {
                    minWidth: getScrollPlotAreaSpace({
                        tile,
                        options,
                        chartWidth,
                        styleDimensionAttr: "xAxis",
                    }),
                    scrollPositionX: 1,
                    opacity: 1,
                },
            },
        };
    }

    if ($type === "HorizontalBarChartTile") {
        return {
            ...options,
            chart: {
                ...options.chart,
                scrollablePlotArea: {
                    minHeight: getScrollPlotAreaSpace({
                        tile,
                        options,
                        chartHeight,
                        styleDimensionAttr: "yAxis",
                    }),
                    scrollPositionY: 0,
                    opacity: 1,
                },
            },
        };
    }

    return options;
};

const getScrollPlotAreaSpace = ({tile, options, chartWidth, chartHeight, styleDimensionAttr}) => {
    const {maxValuesInView, maxValuesInViewFixed} = tile.style[styleDimensionAttr];
    const getValuesInView = (availableSpace) => {
        return maxValuesInView === "Fixed" ? maxValuesInViewFixed : getAutoMaxValuesInView({tile, options, availableSpace});
    };

    const eachMeasureAxisWidth = [
        {
            when: (yAxis) => yAxis?.title.text && yAxis.labels?.enabled,
            width: 70,
        },
        {
            when: (yAxis) => yAxis?.title.text,
            width: 30,
        },
        {
            when: (yAxis) => yAxis?.labels?.enabled,
            width: 40,
        },
    ];

    // default margin left and right of chart is 10
    const getMeasureAxisWidth = (measureAxis) => eachMeasureAxisWidth.find((c) => c.when(measureAxis))?.width || 10;

    const numberOfDataPoints = chain(
        options.series.filter((s) => !s.isCompare && !s.customType),
        (_) => flatten1(_.map((s) => s.data.map((d) => d[0]))),
        (_) => unique(_).length
    );

    const measureAxesWidth = Array.isArray(options.yAxis) ? sum(options.yAxis.map(getMeasureAxisWidth)) : getMeasureAxisWidth(options.yAxis);
    const getScrollablePlotAreaSpace = ({valuesInView, plotWidth, plotHeight}) => {
        // console.log(maxValuesInView, "values in view", valuesInView);

        const space = valuesInView >= numberOfDataPoints ? plotWidth || plotHeight : (numberOfDataPoints * (plotWidth || plotHeight)) / valuesInView;

        return Math.min(space, 99998);
    };

    //only for BoxPlotTile
    if (["BoxPlotTile"].includes(tile.$type)) {
        //with of each box plot is fixed 30

        const plotWidth = chartWidth - 30;
        const boxesWidthInView = getBoxPlotAutoMaxValuesWidthInView({tile, options, availableSpace: plotWidth});
        return Math.min(boxesWidthInView, 99998);
    }

    if (["VerticalBarChartTile", "LineChartTile"].includes(tile.$type)) {
        const plotWidth = chartWidth - measureAxesWidth;
        return getScrollablePlotAreaSpace({
            plotWidth,
            valuesInView: getValuesInView(plotWidth),
        });
    }

    if (tile.$type === "HorizontalBarChartTile") {
        const plotHeight = chartHeight - measureAxesWidth;
        return getScrollablePlotAreaSpace({
            plotHeight,
            valuesInView: getValuesInView(plotHeight),
        });
    }

    if (tile.$type === "ComboChartTile") {
        const plotWidth = chartWidth - measureAxesWidth;
        return getScrollablePlotAreaSpace({
            plotWidth,
            valuesInView: getValuesInView(plotWidth),
        });
    }
};
