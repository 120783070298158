import {cs} from "@common/react/chain-services";
import moment from "moment";
import {Button} from "../../../../../../../common/form/buttons/button/button";
import {GhostButton} from "../../../../../../../common/form/buttons/ghost-button/ghost-button";
import DataSourceErrorIcon from "assets/icons/data-sources/DataSourceErrorIcon";
import {ErrorConnectionDialog} from "../../../add/dialogs/error-connection-dialog/error-connection-dialog";
import {consumeContext} from "@common/react/context";
import {errorsMessages, shortErrorsMessages} from "../../common/errors-messages";
import {DsClearErrorDialog} from "../../common/clear-error-dialog/ds-clear-error-dialog";
import {cx} from "emotion";
import {NavigateSyncSettings} from "../../sync/common/navigate-sync-settings";

export const SyncSettingErrorItem = ({
    shortVersion = false,
    error,
    hasDataSourceErrors = false,
    tableId,
    tableName = null,
    editColumn,
    disabledSync,
    editSyncMethod,
    onReload,
}) =>
    cs(
        consumeContext("routing"),
        ["errorDialog", (_, next) => ErrorConnectionDialog({next})],
        ["clearErrorDialog", (_, next) => DsClearErrorDialog({next})],
        [
            "goSyncSettings",
            ({routing}, next) =>
                cs(["navigateSyncSettings", (_, next) => NavigateSyncSettings({next})], ({navigateSyncSettings}) =>
                    next(() => navigateSyncSettings.goto())
                ),
        ],
        ({errorDialog, routing, clearErrorDialog, goSyncSettings}) => {
            const messages = shortVersion
                ? shortErrorsMessages({
                      syncSetting: (
                          <span className="link" onClick={goSyncSettings}>
                              Sync Settings
                          </span>
                      ),
                      clearErrors: () => (
                          <span
                              className="link"
                              onClick={async () => {
                                  const resp = await clearErrorDialog.show({
                                      dsID: routing.params.dsId,
                                      goSyncSettings,
                                      errorTablesIDsSelected: [tableId],
                                  });

                                  if (resp) onReload?.();
                              }}
                          >
                              clear the error
                          </span>
                      ),
                  })
                : errorsMessages;

            const errorConfig = messages["table"][error.error];
            // const hasActions = (tableId || editColumn || disabledSync);

            return (
                <div
                    className={cx("error-sync-99o", {
                        disabled: hasDataSourceErrors,
                    })}
                >
                    <div className="main">
                        <div className="header">
                            <div className="sub-text"></div>
                            <div className="text">
                                {tableName ? `${tableName} table - ` : ``}
                                {errorConfig?.label}
                            </div>
                        </div>

                        <div className="content">
                            <div className="left-side">
                                <div className="content">
                                    {errorConfig?.solutions && (
                                        <>
                                            <div className="label">Possible solutions:</div>
                                            {errorConfig?.solutions.map((item, i) => (
                                                <div className="item" key={i}>
                                                    {i + 1}) {item}
                                                </div>
                                            ))}
                                        </>
                                    )}

                                    {errorConfig?.message}
                                </div>

                                {cs(
                                    [
                                        "config",
                                        (_, next) =>
                                            next({
                                                disable: <GhostButton onClick={disabledSync}>Disable Sync / Delete Data</GhostButton>,
                                                clear: (
                                                    <Button
                                                        btnType="danger"
                                                        onClick={async () => {
                                                            const resp = await clearErrorDialog.show({
                                                                goSyncSettings: null,
                                                                dsID: routing.params.dsId,
                                                                errorTablesIDsSelected: [tableId],
                                                            });

                                                            if (resp) onReload?.();
                                                        }}
                                                    >
                                                        Clear Error
                                                    </Button>
                                                ),
                                                syncMethod: <Button onClick={editSyncMethod}>Sync Method</Button>,
                                                connection: (
                                                    <GhostButton
                                                        onClick={() =>
                                                            routing.goto("edit-connection-settings", {
                                                                envId: routing.params.envId,
                                                                dsId: routing.params.dsId,
                                                            })
                                                        }
                                                    >
                                                        Connection Settings
                                                    </GhostButton>
                                                ),
                                            }),
                                    ],
                                    ({config}) => (
                                        <div className="buttons">
                                            {(errorConfig?.actions || [])?.map((action) => config[action] || null)}
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    );
