import React from "react";
import {cs} from "@common/react/chain-services";
import "./convert-timezone-operations.scss";
import {rColumnValueSelectFromStepOutput} from "../../../../../common/column-value-select-from-step-output/column-value-select-from-step-output";
import {ffToDropdown} from "../../../../../../../../../../common/form/ff-to-dropdown";
import {keepOnly} from "@common/utils/objects";
import {ResultActionSelect} from "../add/add-date-operations";
import {DatePicker} from "../../../../../../../../../../common/form/date-picker/date-picker";
import {ffToDatePicker} from "../../../../../../../../../../common/form/date-picker/ff-to-date-picker";
import {DropdownSelectSearch} from "@common/ui-components/dropdown-select/dropdown-select-search";

export const ConvertTimezoneOperations = ({operation, inputStep, model, form}) =>
    cs(() => {
        const timeZones = [
            {value: "Etc/GMT+12", text: "(UTC-12:00) Etc/GMT+12"},
            {value: "Etc/GMT+11", text: "(UTC-11:00) Etc/GMT+11"},
            {value: "Pacific/Niue", text: "(UTC-11:00) Pacific/Niue"},
            {value: "US/Samoa", text: "(UTC-11:00) US/Samoa (SST)"},
            {
                value: "Pacific/Pago_Pago",
                text: "(UTC-11:00) Pacific/Pago Pago (SST)",
            },
            {value: "Pacific/Midway", text: "(UTC-11:00) Pacific/Midway (SST)"},
            {value: "Pacific/Samoa", text: "(UTC-11:00) Pacific/Samoa (SST)"},
            {value: "Etc/GMT+10", text: "(UTC-10:00) Etc/GMT+10"},
            {value: "Pacific/Rarotonga", text: "(UTC-10:00) Pacific/Rarotonga"},
            {value: "Pacific/Tahiti", text: "(UTC-10:00) Pacific/Tahiti"},
            {value: "US/Hawaii", text: "(UTC-10:00) US/Hawaii (HST)"},
            {
                value: "Pacific/Honolulu",
                text: "(UTC-10:00) Pacific/Honolulu (HST)",
            },
            {
                value: "Pacific/Johnston",
                text: "(UTC-10:00) Pacific/Johnston (HST)",
            },
            {value: "Pacific/Marquesas", text: "(UTC-09:30) Pacific/Marquesas"},
            {value: "Etc/GMT+9", text: "(UTC-09:00) Etc/GMT+9"},
            {value: "Pacific/Gambier", text: "(UTC-09:00) Pacific/Gambier"},
            {value: "US/Aleutian", text: "(UTC-09:00) US/Aleutian (HDT)"},
            {value: "America/Atka", text: "(UTC-09:00) America/Atka (HDT)"},
            {value: "America/Adak", text: "(UTC-09:00) America/Adak (HDT)"},
            {value: "Etc/GMT+8", text: "(UTC-08:00) Etc/GMT+8"},
            {value: "Pacific/Pitcairn", text: "(UTC-08:00) Pacific/Pitcairn"},
            {value: "US/Alaska", text: "(UTC-08:00) US/Alaska (AKDT)"},
            {value: "America/Nome", text: "(UTC-08:00) America/Nome (AKDT)"},
            {
                value: "America/Metlakatla",
                text: "(UTC-08:00) America/Metlakatla (AKDT)",
            },
            {
                value: "America/Juneau",
                text: "(UTC-08:00) America/Juneau (AKDT)",
            },
            {value: "America/Sitka", text: "(UTC-08:00) America/Sitka (AKDT)"},
            {
                value: "America/Anchorage",
                text: "(UTC-08:00) America/Anchorage (AKDT)",
            },
            {
                value: "America/Yakutat",
                text: "(UTC-08:00) America/Yakutat (AKDT)",
            },
            {value: "Etc/GMT+7", text: "(UTC-07:00) Etc/GMT+7"},
            {value: "US/Arizona", text: "(UTC-07:00) US/Arizona (MST)"},
            {
                value: "America/Fort_Nelson",
                text: "(UTC-07:00) America/Fort Nelson (MST)",
            },
            {
                value: "America/Hermosillo",
                text: "(UTC-07:00) America/Hermosillo (MST)",
            },
            {
                value: "America/Phoenix",
                text: "(UTC-07:00) America/Phoenix (MST)",
            },
            {
                value: "America/Dawson_Creek",
                text: "(UTC-07:00) America/Dawson Creek (MST)",
            },
            {
                value: "America/Creston",
                text: "(UTC-07:00) America/Creston (MST)",
            },
            {value: "PST8PDT", text: "(UTC-07:00) PST8PDT (PDT)"},
            {value: "US/Pacific-New", text: "(UTC-07:00) US/Pacific-New (PDT)"},
            {value: "US/Pacific", text: "(UTC-07:00) US/Pacific (PDT)"},
            {
                value: "America/Los_Angeles",
                text: "(UTC-07:00) America/Los Angeles (PDT)",
            },
            {
                value: "America/Santa_Isabel",
                text: "(UTC-07:00) America/Santa Isabel (PDT)",
            },
            {
                value: "America/Ensenada",
                text: "(UTC-07:00) America/Ensenada (PDT)",
            },
            {
                value: "America/Vancouver",
                text: "(UTC-07:00) America/Vancouver (PDT)",
            },
            {
                value: "America/Whitehorse",
                text: "(UTC-07:00) America/Whitehorse (PDT)",
            },
            {
                value: "America/Tijuana",
                text: "(UTC-07:00) America/Tijuana (PDT)",
            },
            {value: "America/Dawson", text: "(UTC-07:00) America/Dawson (PDT)"},
            {
                value: "Mexico/BajaNorte",
                text: "(UTC-07:00) Mexico/BajaNorte (PDT)",
            },
            {value: "Canada/Yukon", text: "(UTC-07:00) Canada/Yukon (PDT)"},
            {value: "Canada/Pacific", text: "(UTC-07:00) Canada/Pacific (PDT)"},
            {
                value: "Chile/EasterIsland",
                text: "(UTC-06:00) Chile/EasterIsland",
            },
            {value: "Etc/GMT+6", text: "(UTC-06:00) Etc/GMT+6"},
            {value: "Pacific/Easter", text: "(UTC-06:00) Pacific/Easter"},
            {value: "Pacific/Galapagos", text: "(UTC-06:00) Pacific/Galapagos"},
            {
                value: "America/Swift_Current",
                text: "(UTC-06:00) America/Swift Current (CST)",
            },
            {
                value: "America/Tegucigalpa",
                text: "(UTC-06:00) America/Tegucigalpa (CST)",
            },
            {
                value: "America/Costa_Rica",
                text: "(UTC-06:00) America/Costa Rica (CST)",
            },
            {
                value: "America/El_Salvador",
                text: "(UTC-06:00) America/El Salvador (CST)",
            },
            {
                value: "America/Guatemala",
                text: "(UTC-06:00) America/Guatemala (CST)",
            },
            {value: "America/Regina", text: "(UTC-06:00) America/Regina (CST)"},
            {value: "America/Belize", text: "(UTC-06:00) America/Belize (CST)"},
            {
                value: "America/Managua",
                text: "(UTC-06:00) America/Managua (CST)",
            },
            {
                value: "Canada/Saskatchewan",
                text: "(UTC-06:00) Canada/Saskatchewan (CST)",
            },
            {value: "US/Mountain", text: "(UTC-06:00) US/Mountain (MDT)"},
            {value: "Navajo", text: "(UTC-06:00) Navajo (MDT)"},
            {
                value: "America/Yellowknife",
                text: "(UTC-06:00) America/Yellowknife (MDT)",
            },
            {
                value: "America/Ojinaga",
                text: "(UTC-06:00) America/Ojinaga (MDT)",
            },
            {
                value: "America/Mazatlan",
                text: "(UTC-06:00) America/Mazatlan (MDT)",
            },
            {value: "America/Denver", text: "(UTC-06:00) America/Denver (MDT)"},
            {value: "America/Boise", text: "(UTC-06:00) America/Boise (MDT)"},
            {
                value: "America/Cambridge_Bay",
                text: "(UTC-06:00) America/Cambridge Bay (MDT)",
            },
            {
                value: "America/Shiprock",
                text: "(UTC-06:00) America/Shiprock (MDT)",
            },
            {
                value: "America/Chihuahua",
                text: "(UTC-06:00) America/Chihuahua (MDT)",
            },
            {value: "America/Inuvik", text: "(UTC-06:00) America/Inuvik (MDT)"},
            {
                value: "America/Edmonton",
                text: "(UTC-06:00) America/Edmonton (MDT)",
            },
            {value: "Mexico/BajaSur", text: "(UTC-06:00) Mexico/BajaSur (MDT)"},
            {
                value: "Canada/Mountain",
                text: "(UTC-06:00) Canada/Mountain (MDT)",
            },
            {value: "MST7MDT", text: "(UTC-06:00) MST7MDT (MDT)"},
            {value: "America/Lima", text: "(UTC-05:00) America/Lima"},
            {
                value: "America/Rio_Branco",
                text: "(UTC-05:00) America/Rio Branco",
            },
            {value: "America/Guayaquil", text: "(UTC-05:00) America/Guayaquil"},
            {value: "America/Bogota", text: "(UTC-05:00) America/Bogota"},
            {
                value: "America/Porto_Acre",
                text: "(UTC-05:00) America/Porto Acre",
            },
            {value: "America/Eirunepe", text: "(UTC-05:00) America/Eirunepe"},
            {value: "Etc/GMT+5", text: "(UTC-05:00) Etc/GMT+5"},
            {value: "Brazil/Acre", text: "(UTC-05:00) Brazil/Acre"},
            {value: "US/Central", text: "(UTC-05:00) US/Central (CDT)"},
            {
                value: "US/Indiana-Starke",
                text: "(UTC-05:00) US/Indiana-Starke (CDT)",
            },
            {
                value: "America/Bahia_Banderas",
                text: "(UTC-05:00) America/Bahia Banderas (CDT)",
            },
            {
                value: "America/Mexico_City",
                text: "(UTC-05:00) America/Mexico City (CDT)",
            },
            {
                value: "America/Rankin_Inlet",
                text: "(UTC-05:00) America/Rankin Inlet (CDT)",
            },
            {
                value: "America/Indiana/Knox",
                text: "(UTC-05:00) America/Indiana/Knox (CDT)",
            },
            {
                value: "America/Indiana/Tell_City",
                text: "(UTC-05:00) America/Indiana/Tell City (CDT)",
            },
            {
                value: "America/Menominee",
                text: "(UTC-05:00) America/Menominee (CDT)",
            },
            {
                value: "America/Monterrey",
                text: "(UTC-05:00) America/Monterrey (CDT)",
            },
            {value: "America/Merida", text: "(UTC-05:00) America/Merida (CDT)"},
            {
                value: "America/Matamoros",
                text: "(UTC-05:00) America/Matamoros (CDT)",
            },
            {
                value: "America/Chicago",
                text: "(UTC-05:00) America/Chicago (CDT)",
            },
            {
                value: "America/Knox_IN",
                text: "(UTC-05:00) America/Knox IN (CDT)",
            },
            {
                value: "America/Winnipeg",
                text: "(UTC-05:00) America/Winnipeg (CDT)",
            },
            {
                value: "America/North_Dakota/Center",
                text: "(UTC-05:00) America/North Dakota/Center (CDT)",
            },
            {
                value: "America/North_Dakota/New_Salem",
                text: "(UTC-05:00) America/North Dakota/New Salem (CDT)",
            },
            {
                value: "America/North_Dakota/Beulah",
                text: "(UTC-05:00) America/North Dakota/Beulah (CDT)",
            },
            {
                value: "America/Resolute",
                text: "(UTC-05:00) America/Resolute (CDT)",
            },
            {
                value: "America/Rainy_River",
                text: "(UTC-05:00) America/Rainy River (CDT)",
            },
            {value: "Mexico/General", text: "(UTC-05:00) Mexico/General (CDT)"},
            {value: "Canada/Central", text: "(UTC-05:00) Canada/Central (CDT)"},
            {value: "CST6CDT", text: "(UTC-05:00) CST6CDT (CDT)"},
            {value: "America/Cayman", text: "(UTC-05:00) America/Cayman (EST)"},
            {
                value: "America/Atikokan",
                text: "(UTC-05:00) America/Atikokan (EST)",
            },
            {value: "America/Cancun", text: "(UTC-05:00) America/Cancun (EST)"},
            {
                value: "America/Jamaica",
                text: "(UTC-05:00) America/Jamaica (EST)",
            },
            {
                value: "America/Coral_Harbour",
                text: "(UTC-05:00) America/Coral Harbour (EST)",
            },
            {value: "America/Panama", text: "(UTC-05:00) America/Panama (EST)"},
            {value: "Jamaica", text: "(UTC-05:00) Jamaica (EST)"},
            {value: "Chile/Continental", text: "(UTC-04:00) Chile/Continental"},
            {value: "America/Boa_Vista", text: "(UTC-04:00) America/Boa Vista"},
            {value: "America/Caracas", text: "(UTC-04:00) America/Caracas"},
            {value: "America/La_Paz", text: "(UTC-04:00) America/La Paz"},
            {value: "America/Cuiaba", text: "(UTC-04:00) America/Cuiaba"},
            {value: "America/Manaus", text: "(UTC-04:00) America/Manaus"},
            {
                value: "America/Campo_Grande",
                text: "(UTC-04:00) America/Campo Grande",
            },
            {value: "America/Guyana", text: "(UTC-04:00) America/Guyana"},
            {
                value: "America/Porto_Velho",
                text: "(UTC-04:00) America/Porto Velho",
            },
            {value: "America/Asuncion", text: "(UTC-04:00) America/Asuncion"},
            {value: "America/Santiago", text: "(UTC-04:00) America/Santiago"},
            {value: "Etc/GMT+4", text: "(UTC-04:00) Etc/GMT+4"},
            {value: "Brazil/West", text: "(UTC-04:00) Brazil/West"},
            {
                value: "America/Guadeloupe",
                text: "(UTC-04:00) America/Guadeloupe (AST)",
            },
            {
                value: "America/Santo_Domingo",
                text: "(UTC-04:00) America/Santo Domingo (AST)",
            },
            {
                value: "America/St_Lucia",
                text: "(UTC-04:00) America/St Lucia (AST)",
            },
            {
                value: "America/Puerto_Rico",
                text: "(UTC-04:00) America/Puerto Rico (AST)",
            },
            {
                value: "America/Grenada",
                text: "(UTC-04:00) America/Grenada (AST)",
            },
            {
                value: "America/Kralendijk",
                text: "(UTC-04:00) America/Kralendijk (AST)",
            },
            {
                value: "America/Dominica",
                text: "(UTC-04:00) America/Dominica (AST)",
            },
            {
                value: "America/Port_of_Spain",
                text: "(UTC-04:00) America/Port of Spain (AST)",
            },
            {value: "America/Virgin", text: "(UTC-04:00) America/Virgin (AST)"},
            {
                value: "America/Barbados",
                text: "(UTC-04:00) America/Barbados (AST)",
            },
            {
                value: "America/St_Kitts",
                text: "(UTC-04:00) America/St Kitts (AST)",
            },
            {
                value: "America/St_Vincent",
                text: "(UTC-04:00) America/St Vincent (AST)",
            },
            {
                value: "America/St_Thomas",
                text: "(UTC-04:00) America/St Thomas (AST)",
            },
            {value: "America/Aruba", text: "(UTC-04:00) America/Aruba (AST)"},
            {
                value: "America/Blanc-Sablon",
                text: "(UTC-04:00) America/Blanc-Sablon (AST)",
            },
            {
                value: "America/Anguilla",
                text: "(UTC-04:00) America/Anguilla (AST)",
            },
            {
                value: "America/Curacao",
                text: "(UTC-04:00) America/Curacao (AST)",
            },
            {
                value: "America/Montserrat",
                text: "(UTC-04:00) America/Montserrat (AST)",
            },
            {
                value: "America/Marigot",
                text: "(UTC-04:00) America/Marigot (AST)",
            },
            {
                value: "America/Antigua",
                text: "(UTC-04:00) America/Antigua (AST)",
            },
            {
                value: "America/Lower_Princes",
                text: "(UTC-04:00) America/Lower Princes (AST)",
            },
            {
                value: "America/Tortola",
                text: "(UTC-04:00) America/Tortola (AST)",
            },
            {
                value: "America/St_Barthelemy",
                text: "(UTC-04:00) America/St Barthelemy (AST)",
            },
            {
                value: "America/Martinique",
                text: "(UTC-04:00) America/Martinique (AST)",
            },
            {value: "America/Havana", text: "(UTC-04:00) America/Havana (CDT)"},
            {value: "Cuba", text: "(UTC-04:00) Cuba (CDT)"},
            {value: "US/Michigan", text: "(UTC-04:00) US/Michigan (EDT)"},
            {
                value: "US/East-Indiana",
                text: "(UTC-04:00) US/East-Indiana (EDT)",
            },
            {value: "US/Eastern", text: "(UTC-04:00) US/Eastern (EDT)"},
            {
                value: "America/Fort_Wayne",
                text: "(UTC-04:00) America/Fort Wayne (EDT)",
            },
            {
                value: "America/Port-au-Prince",
                text: "(UTC-04:00) America/Port-au-Prince (EDT)",
            },
            {
                value: "America/Nipigon",
                text: "(UTC-04:00) America/Nipigon (EDT)",
            },
            {
                value: "America/Grand_Turk",
                text: "(UTC-04:00) America/Grand Turk (EDT)",
            },
            {
                value: "America/Montreal",
                text: "(UTC-04:00) America/Montreal (EDT)",
            },
            {
                value: "America/Indiana/Winamac",
                text: "(UTC-04:00) America/Indiana/Winamac (EDT)",
            },
            {
                value: "America/Indiana/Petersburg",
                text: "(UTC-04:00) America/Indiana/Petersburg (EDT)",
            },
            {
                value: "America/Indiana/Indianapolis",
                text: "(UTC-04:00) America/Indiana/Indianapolis (EDT)",
            },
            {
                value: "America/Indiana/Vincennes",
                text: "(UTC-04:00) America/Indiana/Vincennes (EDT)",
            },
            {
                value: "America/Indiana/Marengo",
                text: "(UTC-04:00) America/Indiana/Marengo (EDT)",
            },
            {
                value: "America/Indiana/Vevay",
                text: "(UTC-04:00) America/Indiana/Vevay (EDT)",
            },
            {
                value: "America/Indianapolis",
                text: "(UTC-04:00) America/Indianapolis (EDT)",
            },
            {
                value: "America/Detroit",
                text: "(UTC-04:00) America/Detroit (EDT)",
            },
            {
                value: "America/New_York",
                text: "(UTC-04:00) America/New York (EDT)",
            },
            {
                value: "America/Louisville",
                text: "(UTC-04:00) America/Louisville (EDT)",
            },
            {value: "America/Nassau", text: "(UTC-04:00) America/Nassau (EDT)"},
            {
                value: "America/Iqaluit",
                text: "(UTC-04:00) America/Iqaluit (EDT)",
            },
            {
                value: "America/Pangnirtung",
                text: "(UTC-04:00) America/Pangnirtung (EDT)",
            },
            {
                value: "America/Thunder_Bay",
                text: "(UTC-04:00) America/Thunder Bay (EDT)",
            },
            {
                value: "America/Toronto",
                text: "(UTC-04:00) America/Toronto (EDT)",
            },
            {
                value: "America/Kentucky/Louisville",
                text: "(UTC-04:00) America/Kentucky/Louisville (EDT)",
            },
            {
                value: "America/Kentucky/Monticello",
                text: "(UTC-04:00) America/Kentucky/Monticello (EDT)",
            },
            {value: "EST5EDT", text: "(UTC-04:00) EST5EDT (EDT)"},
            {value: "Canada/Eastern", text: "(UTC-04:00) Canada/Eastern (EDT)"},
            {
                value: "America/Paramaribo",
                text: "(UTC-03:00) America/Paramaribo",
            },
            {value: "America/Fortaleza", text: "(UTC-03:00) America/Fortaleza"},
            {value: "America/Mendoza", text: "(UTC-03:00) America/Mendoza"},
            {value: "America/Recife", text: "(UTC-03:00) America/Recife"},
            {value: "America/Rosario", text: "(UTC-03:00) America/Rosario"},
            {value: "America/Jujuy", text: "(UTC-03:00) America/Jujuy"},
            {
                value: "America/Montevideo",
                text: "(UTC-03:00) America/Montevideo",
            },
            {
                value: "America/Argentina/ComodRivadavia",
                text: "(UTC-03:00) America/Argentina/ComodRivadavia",
            },
            {
                value: "America/Argentina/Mendoza",
                text: "(UTC-03:00) America/Argentina/Mendoza",
            },
            {
                value: "America/Argentina/Salta",
                text: "(UTC-03:00) America/Argentina/Salta",
            },
            {
                value: "America/Argentina/Jujuy",
                text: "(UTC-03:00) America/Argentina/Jujuy",
            },
            {
                value: "America/Argentina/San_Luis",
                text: "(UTC-03:00) America/Argentina/San Luis",
            },
            {
                value: "America/Argentina/La_Rioja",
                text: "(UTC-03:00) America/Argentina/La Rioja",
            },
            {
                value: "America/Argentina/Cordoba",
                text: "(UTC-03:00) America/Argentina/Cordoba",
            },
            {
                value: "America/Argentina/Catamarca",
                text: "(UTC-03:00) America/Argentina/Catamarca",
            },
            {
                value: "America/Argentina/Rio_Gallegos",
                text: "(UTC-03:00) America/Argentina/Rio Gallegos",
            },
            {
                value: "America/Argentina/San_Juan",
                text: "(UTC-03:00) America/Argentina/San Juan",
            },
            {
                value: "America/Argentina/Ushuaia",
                text: "(UTC-03:00) America/Argentina/Ushuaia",
            },
            {
                value: "America/Argentina/Buenos_Aires",
                text: "(UTC-03:00) America/Argentina/Buenos Aires",
            },
            {
                value: "America/Argentina/Tucuman",
                text: "(UTC-03:00) America/Argentina/Tucuman",
            },
            {value: "America/Cordoba", text: "(UTC-03:00) America/Cordoba"},
            {
                value: "America/Punta_Arenas",
                text: "(UTC-03:00) America/Punta Arenas",
            },
            {value: "America/Catamarca", text: "(UTC-03:00) America/Catamarca"},
            {value: "America/Maceio", text: "(UTC-03:00) America/Maceio"},
            {value: "America/Bahia", text: "(UTC-03:00) America/Bahia"},
            {value: "America/Santarem", text: "(UTC-03:00) America/Santarem"},
            {value: "America/Cayenne", text: "(UTC-03:00) America/Cayenne"},
            {value: "America/Araguaina", text: "(UTC-03:00) America/Araguaina"},
            {value: "America/Sao_Paulo", text: "(UTC-03:00) America/Sao Paulo"},
            {value: "America/Belem", text: "(UTC-03:00) America/Belem"},
            {
                value: "America/Buenos_Aires",
                text: "(UTC-03:00) America/Buenos Aires",
            },
            {
                value: "Antarctica/Rothera",
                text: "(UTC-03:00) Antarctica/Rothera",
            },
            {value: "Antarctica/Palmer", text: "(UTC-03:00) Antarctica/Palmer"},
            {value: "Etc/GMT+3", text: "(UTC-03:00) Etc/GMT+3"},
            {value: "Atlantic/Stanley", text: "(UTC-03:00) Atlantic/Stanley"},
            {value: "Brazil/East", text: "(UTC-03:00) Brazil/East"},
            {
                value: "America/Glace_Bay",
                text: "(UTC-03:00) America/Glace Bay (ADT)",
            },
            {
                value: "America/Moncton",
                text: "(UTC-03:00) America/Moncton (ADT)",
            },
            {value: "America/Thule", text: "(UTC-03:00) America/Thule (ADT)"},
            {
                value: "America/Halifax",
                text: "(UTC-03:00) America/Halifax (ADT)",
            },
            {
                value: "America/Goose_Bay",
                text: "(UTC-03:00) America/Goose Bay (ADT)",
            },
            {
                value: "Canada/Atlantic",
                text: "(UTC-03:00) Canada/Atlantic (ADT)",
            },
            {
                value: "Atlantic/Bermuda",
                text: "(UTC-03:00) Atlantic/Bermuda (ADT)",
            },
            {
                value: "America/St_Johns",
                text: "(UTC-02:30) America/St Johns (NDT)",
            },
            {
                value: "Canada/Newfoundland",
                text: "(UTC-02:30) Canada/Newfoundland (NDT)",
            },
            {value: "America/Noronha", text: "(UTC-02:00) America/Noronha"},
            {value: "America/Miquelon", text: "(UTC-02:00) America/Miquelon"},
            {value: "America/Godthab", text: "(UTC-02:00) America/Godthab"},
            {value: "Etc/GMT+2", text: "(UTC-02:00) Etc/GMT+2"},
            {
                value: "Atlantic/South_Georgia",
                text: "(UTC-02:00) Atlantic/South Georgia",
            },
            {value: "Brazil/DeNoronha", text: "(UTC-02:00) Brazil/DeNoronha"},
            {value: "Etc/GMT+1", text: "(UTC-01:00) Etc/GMT+1"},
            {
                value: "Atlantic/Cape_Verde",
                text: "(UTC-01:00) Atlantic/Cape Verde",
            },
            {
                value: "America/Scoresbysund",
                text: "(UTC+00:00) America/Scoresbysund",
            },
            {value: "Africa/El_Aaiun", text: "(UTC+00:00) Africa/El Aaiun"},
            {value: "Africa/Casablanca", text: "(UTC+00:00) Africa/Casablanca"},
            {value: "Atlantic/Azores", text: "(UTC+00:00) Atlantic/Azores"},
            {value: "Factory", text: "(UTC+00:00) Factory"},
            {value: "Iceland", text: "(UTC+00:00) Iceland (GMT)"},
            {
                value: "America/Danmarkshavn",
                text: "(UTC+00:00) America/Danmarkshavn (GMT)",
            },
            {value: "GMT+0", text: "(UTC+00:00) GMT+0 (GMT)"},
            {value: "Etc/GMT+0", text: "(UTC+00:00) Etc/GMT+0 (GMT)"},
            {value: "Etc/Greenwich", text: "(UTC+00:00) Etc/Greenwich (GMT)"},
            {value: "Etc/GMT-0", text: "(UTC+00:00) Etc/GMT-0 (GMT)"},
            {value: "Etc/GMT0", text: "(UTC+00:00) Etc/GMT0 (GMT)"},
            {value: "Etc/GMT", text: "(UTC+00:00) Etc/GMT (GMT)"},
            {value: "Greenwich", text: "(UTC+00:00) Greenwich (GMT)"},
            {value: "GMT-0", text: "(UTC+00:00) GMT-0 (GMT)"},
            {value: "GMT0", text: "(UTC+00:00) GMT0 (GMT)"},
            {value: "Africa/Dakar", text: "(UTC+00:00) Africa/Dakar (GMT)"},
            {
                value: "Africa/Nouakchott",
                text: "(UTC+00:00) Africa/Nouakchott (GMT)",
            },
            {
                value: "Africa/Timbuktu",
                text: "(UTC+00:00) Africa/Timbuktu (GMT)",
            },
            {
                value: "Africa/Monrovia",
                text: "(UTC+00:00) Africa/Monrovia (GMT)",
            },
            {value: "Africa/Bissau", text: "(UTC+00:00) Africa/Bissau (GMT)"},
            {value: "Africa/Conakry", text: "(UTC+00:00) Africa/Conakry (GMT)"},
            {
                value: "Africa/Freetown",
                text: "(UTC+00:00) Africa/Freetown (GMT)",
            },
            {value: "Africa/Lome", text: "(UTC+00:00) Africa/Lome (GMT)"},
            {value: "Africa/Accra", text: "(UTC+00:00) Africa/Accra (GMT)"},
            {
                value: "Africa/Sao_Tome",
                text: "(UTC+00:00) Africa/Sao Tome (GMT)",
            },
            {value: "Africa/Abidjan", text: "(UTC+00:00) Africa/Abidjan (GMT)"},
            {value: "Africa/Banjul", text: "(UTC+00:00) Africa/Banjul (GMT)"},
            {
                value: "Africa/Ouagadougou",
                text: "(UTC+00:00) Africa/Ouagadougou (GMT)",
            },
            {value: "Africa/Bamako", text: "(UTC+00:00) Africa/Bamako (GMT)"},
            {
                value: "Atlantic/Reykjavik",
                text: "(UTC+00:00) Atlantic/Reykjavik (GMT)",
            },
            {
                value: "Atlantic/St_Helena",
                text: "(UTC+00:00) Atlantic/St Helena (GMT)",
            },
            {value: "Zulu", text: "(UTC+00:00) Zulu (UTC)"},
            {value: "Universal", text: "(UTC+00:00) Universal (UTC)"},
            {value: "UCT", text: "(UTC+00:00) UCT (UTC)"},
            {value: "Etc/Zulu", text: "(UTC+00:00) Etc/Zulu (UTC)"},
            {value: "Etc/Universal", text: "(UTC+00:00) Etc/Universal (UTC)"},
            {value: "Etc/UCT", text: "(UTC+00:00) Etc/UCT (UTC)"},
            {value: "Etc/UTC", text: "(UTC+00:00) Etc/UTC (UTC)"},
            {value: "Etc/GMT-1", text: "(UTC+01:00) Etc/GMT-1"},
            {value: "GB-Eire", text: "(UTC+01:00) GB-Eire (BST)"},
            {value: "GB", text: "(UTC+01:00) GB (BST)"},
            {value: "Europe/London", text: "(UTC+01:00) Europe/London (BST)"},
            {value: "Europe/Belfast", text: "(UTC+01:00) Europe/Belfast (BST)"},
            {
                value: "Europe/Isle_of_Man",
                text: "(UTC+01:00) Europe/Isle of Man (BST)",
            },
            {value: "Europe/Jersey", text: "(UTC+01:00) Europe/Jersey (BST)"},
            {
                value: "Europe/Guernsey",
                text: "(UTC+01:00) Europe/Guernsey (BST)",
            },
            {value: "Africa/Tunis", text: "(UTC+01:00) Africa/Tunis (CET)"},
            {value: "Africa/Algiers", text: "(UTC+01:00) Africa/Algiers (CET)"},
            {value: "Eire", text: "(UTC+01:00) Eire (IST)"},
            {value: "Europe/Dublin", text: "(UTC+01:00) Europe/Dublin (IST)"},
            {value: "Africa/Lagos", text: "(UTC+01:00) Africa/Lagos (WAT)"},
            {
                value: "Africa/Porto-Novo",
                text: "(UTC+01:00) Africa/Porto-Novo (WAT)",
            },
            {value: "Africa/Niamey", text: "(UTC+01:00) Africa/Niamey (WAT)"},
            {value: "Africa/Luanda", text: "(UTC+01:00) Africa/Luanda (WAT)"},
            {value: "Africa/Malabo", text: "(UTC+01:00) Africa/Malabo (WAT)"},
            {
                value: "Africa/Brazzaville",
                text: "(UTC+01:00) Africa/Brazzaville (WAT)",
            },
            {value: "Africa/Bangui", text: "(UTC+01:00) Africa/Bangui (WAT)"},
            {value: "Africa/Douala", text: "(UTC+01:00) Africa/Douala (WAT)"},
            {
                value: "Africa/Kinshasa",
                text: "(UTC+01:00) Africa/Kinshasa (WAT)",
            },
            {
                value: "Africa/Ndjamena",
                text: "(UTC+01:00) Africa/Ndjamena (WAT)",
            },
            {
                value: "Africa/Libreville",
                text: "(UTC+01:00) Africa/Libreville (WAT)",
            },
            {value: "Portugal", text: "(UTC+01:00) Portugal (WEST)"},
            {value: "WET", text: "(UTC+01:00) WET (WEST)"},
            {value: "Europe/Lisbon", text: "(UTC+01:00) Europe/Lisbon (WEST)"},
            {
                value: "Atlantic/Canary",
                text: "(UTC+01:00) Atlantic/Canary (WEST)",
            },
            {
                value: "Atlantic/Madeira",
                text: "(UTC+01:00) Atlantic/Madeira (WEST)",
            },
            {
                value: "Atlantic/Faeroe",
                text: "(UTC+01:00) Atlantic/Faeroe (WEST)",
            },
            {
                value: "Atlantic/Faroe",
                text: "(UTC+01:00) Atlantic/Faroe (WEST)",
            },
            {value: "Antarctica/Troll", text: "(UTC+02:00) Antarctica/Troll"},
            {value: "Etc/GMT-2", text: "(UTC+02:00) Etc/GMT-2"},
            {
                value: "Africa/Bujumbura",
                text: "(UTC+02:00) Africa/Bujumbura (CAT)",
            },
            {
                value: "Africa/Lubumbashi",
                text: "(UTC+02:00) Africa/Lubumbashi (CAT)",
            },
            {value: "Africa/Maputo", text: "(UTC+02:00) Africa/Maputo (CAT)"},
            {
                value: "Africa/Blantyre",
                text: "(UTC+02:00) Africa/Blantyre (CAT)",
            },
            {
                value: "Africa/Khartoum",
                text: "(UTC+02:00) Africa/Khartoum (CAT)",
            },
            {
                value: "Africa/Windhoek",
                text: "(UTC+02:00) Africa/Windhoek (CAT)",
            },
            {value: "Africa/Harare", text: "(UTC+02:00) Africa/Harare (CAT)"},
            {value: "Africa/Lusaka", text: "(UTC+02:00) Africa/Lusaka (CAT)"},
            {value: "Africa/Kigali", text: "(UTC+02:00) Africa/Kigali (CAT)"},
            {
                value: "Africa/Gaborone",
                text: "(UTC+02:00) Africa/Gaborone (CAT)",
            },
            {
                value: "Arctic/Longyearbyen",
                text: "(UTC+02:00) Arctic/Longyearbyen (CEST)",
            },
            {value: "Poland", text: "(UTC+02:00) Poland (CEST)"},
            {value: "CET", text: "(UTC+02:00) CET (CEST)"},
            {
                value: "Europe/Podgorica",
                text: "(UTC+02:00) Europe/Podgorica (CEST)",
            },
            {
                value: "Europe/Copenhagen",
                text: "(UTC+02:00) Europe/Copenhagen (CEST)",
            },
            {
                value: "Europe/Stockholm",
                text: "(UTC+02:00) Europe/Stockholm (CEST)",
            },
            {
                value: "Europe/Sarajevo",
                text: "(UTC+02:00) Europe/Sarajevo (CEST)",
            },
            {
                value: "Europe/Brussels",
                text: "(UTC+02:00) Europe/Brussels (CEST)",
            },
            {
                value: "Europe/Andorra",
                text: "(UTC+02:00) Europe/Andorra (CEST)",
            },
            {value: "Europe/Vaduz", text: "(UTC+02:00) Europe/Vaduz (CEST)"},
            {
                value: "Europe/Busingen",
                text: "(UTC+02:00) Europe/Busingen (CEST)",
            },
            {
                value: "Europe/Luxembourg",
                text: "(UTC+02:00) Europe/Luxembourg (CEST)",
            },
            {value: "Europe/Monaco", text: "(UTC+02:00) Europe/Monaco (CEST)"},
            {value: "Europe/Malta", text: "(UTC+02:00) Europe/Malta (CEST)"},
            {
                value: "Europe/Amsterdam",
                text: "(UTC+02:00) Europe/Amsterdam (CEST)",
            },
            {value: "Europe/Tirane", text: "(UTC+02:00) Europe/Tirane (CEST)"},
            {value: "Europe/Warsaw", text: "(UTC+02:00) Europe/Warsaw (CEST)"},
            {value: "Europe/Madrid", text: "(UTC+02:00) Europe/Madrid (CEST)"},
            {value: "Europe/Prague", text: "(UTC+02:00) Europe/Prague (CEST)"},
            {value: "Europe/Berlin", text: "(UTC+02:00) Europe/Berlin (CEST)"},
            {value: "Europe/Zurich", text: "(UTC+02:00) Europe/Zurich (CEST)"},
            {
                value: "Europe/Ljubljana",
                text: "(UTC+02:00) Europe/Ljubljana (CEST)",
            },
            {value: "Europe/Rome", text: "(UTC+02:00) Europe/Rome (CEST)"},
            {value: "Europe/Vienna", text: "(UTC+02:00) Europe/Vienna (CEST)"},
            {
                value: "Europe/Bratislava",
                text: "(UTC+02:00) Europe/Bratislava (CEST)",
            },
            {
                value: "Europe/San_Marino",
                text: "(UTC+02:00) Europe/San Marino (CEST)",
            },
            {value: "Europe/Zagreb", text: "(UTC+02:00) Europe/Zagreb (CEST)"},
            {
                value: "Europe/Gibraltar",
                text: "(UTC+02:00) Europe/Gibraltar (CEST)",
            },
            {
                value: "Europe/Belgrade",
                text: "(UTC+02:00) Europe/Belgrade (CEST)",
            },
            {value: "Europe/Skopje", text: "(UTC+02:00) Europe/Skopje (CEST)"},
            {
                value: "Europe/Budapest",
                text: "(UTC+02:00) Europe/Budapest (CEST)",
            },
            {value: "Europe/Paris", text: "(UTC+02:00) Europe/Paris (CEST)"},
            {
                value: "Europe/Vatican",
                text: "(UTC+02:00) Europe/Vatican (CEST)",
            },
            {value: "Europe/Oslo", text: "(UTC+02:00) Europe/Oslo (CEST)"},
            {value: "Africa/Ceuta", text: "(UTC+02:00) Africa/Ceuta (CEST)"},
            {
                value: "Atlantic/Jan_Mayen",
                text: "(UTC+02:00) Atlantic/Jan Mayen (CEST)",
            },
            {value: "Libya", text: "(UTC+02:00) Libya (EET)"},
            {
                value: "Europe/Kaliningrad",
                text: "(UTC+02:00) Europe/Kaliningrad (EET)",
            },
            {value: "Africa/Cairo", text: "(UTC+02:00) Africa/Cairo (EET)"},
            {value: "Africa/Tripoli", text: "(UTC+02:00) Africa/Tripoli (EET)"},
            {value: "Egypt", text: "(UTC+02:00) Egypt (EET)"},
            {value: "MET", text: "(UTC+02:00) MET (MEST)"},
            {value: "Africa/Maseru", text: "(UTC+02:00) Africa/Maseru (SAST)"},
            {
                value: "Africa/Mbabane",
                text: "(UTC+02:00) Africa/Mbabane (SAST)",
            },
            {
                value: "Africa/Johannesburg",
                text: "(UTC+02:00) Africa/Johannesburg (SAST)",
            },
            {value: "Asia/Bahrain", text: "(UTC+03:00) Asia/Bahrain"},
            {value: "Asia/Riyadh", text: "(UTC+03:00) Asia/Riyadh"},
            {value: "Asia/Istanbul", text: "(UTC+03:00) Asia/Istanbul"},
            {value: "Asia/Qatar", text: "(UTC+03:00) Asia/Qatar"},
            {value: "Asia/Aden", text: "(UTC+03:00) Asia/Aden"},
            {value: "Asia/Baghdad", text: "(UTC+03:00) Asia/Baghdad"},
            {value: "Asia/Kuwait", text: "(UTC+03:00) Asia/Kuwait"},
            {value: "Turkey", text: "(UTC+03:00) Turkey"},
            {value: "Antarctica/Syowa", text: "(UTC+03:00) Antarctica/Syowa"},
            {value: "Etc/GMT-3", text: "(UTC+03:00) Etc/GMT-3"},
            {value: "Europe/Kirov", text: "(UTC+03:00) Europe/Kirov"},
            {value: "Europe/Istanbul", text: "(UTC+03:00) Europe/Istanbul"},
            {value: "Europe/Minsk", text: "(UTC+03:00) Europe/Minsk"},
            {
                value: "Indian/Antananarivo",
                text: "(UTC+03:00) Indian/Antananarivo (EAT)",
            },
            {value: "Indian/Comoro", text: "(UTC+03:00) Indian/Comoro (EAT)"},
            {value: "Indian/Mayotte", text: "(UTC+03:00) Indian/Mayotte (EAT)"},
            {value: "Africa/Asmara", text: "(UTC+03:00) Africa/Asmara (EAT)"},
            {value: "Africa/Juba", text: "(UTC+03:00) Africa/Juba (EAT)"},
            {
                value: "Africa/Addis_Ababa",
                text: "(UTC+03:00) Africa/Addis Ababa (EAT)",
            },
            {
                value: "Africa/Djibouti",
                text: "(UTC+03:00) Africa/Djibouti (EAT)",
            },
            {value: "Africa/Kampala", text: "(UTC+03:00) Africa/Kampala (EAT)"},
            {value: "Africa/Nairobi", text: "(UTC+03:00) Africa/Nairobi (EAT)"},
            {value: "Africa/Asmera", text: "(UTC+03:00) Africa/Asmera (EAT)"},
            {
                value: "Africa/Mogadishu",
                text: "(UTC+03:00) Africa/Mogadishu (EAT)",
            },
            {
                value: "Africa/Dar_es_Salaam",
                text: "(UTC+03:00) Africa/Dar es Salaam (EAT)",
            },
            {
                value: "Asia/Famagusta",
                text: "(UTC+03:00) Asia/Famagusta (EEST)",
            },
            {value: "Asia/Beirut", text: "(UTC+03:00) Asia/Beirut (EEST)"},
            {value: "Asia/Damascus", text: "(UTC+03:00) Asia/Damascus (EEST)"},
            {value: "Asia/Nicosia", text: "(UTC+03:00) Asia/Nicosia (EEST)"},
            {value: "Asia/Hebron", text: "(UTC+03:00) Asia/Hebron (EEST)"},
            {value: "Asia/Gaza", text: "(UTC+03:00) Asia/Gaza (EEST)"},
            {value: "Asia/Amman", text: "(UTC+03:00) Asia/Amman (EEST)"},
            {value: "Europe/Sofia", text: "(UTC+03:00) Europe/Sofia (EEST)"},
            {value: "Europe/Riga", text: "(UTC+03:00) Europe/Riga (EEST)"},
            {
                value: "Europe/Bucharest",
                text: "(UTC+03:00) Europe/Bucharest (EEST)",
            },
            {value: "Europe/Athens", text: "(UTC+03:00) Europe/Athens (EEST)"},
            {
                value: "Europe/Tallinn",
                text: "(UTC+03:00) Europe/Tallinn (EEST)",
            },
            {
                value: "Europe/Nicosia",
                text: "(UTC+03:00) Europe/Nicosia (EEST)",
            },
            {value: "Europe/Kiev", text: "(UTC+03:00) Europe/Kiev (EEST)"},
            {
                value: "Europe/Chisinau",
                text: "(UTC+03:00) Europe/Chisinau (EEST)",
            },
            {
                value: "Europe/Zaporozhye",
                text: "(UTC+03:00) Europe/Zaporozhye (EEST)",
            },
            {
                value: "Europe/Uzhgorod",
                text: "(UTC+03:00) Europe/Uzhgorod (EEST)",
            },
            {
                value: "Europe/Vilnius",
                text: "(UTC+03:00) Europe/Vilnius (EEST)",
            },
            {
                value: "Europe/Helsinki",
                text: "(UTC+03:00) Europe/Helsinki (EEST)",
            },
            {
                value: "Europe/Mariehamn",
                text: "(UTC+03:00) Europe/Mariehamn (EEST)",
            },
            {
                value: "Europe/Tiraspol",
                text: "(UTC+03:00) Europe/Tiraspol (EEST)",
            },
            {value: "EET", text: "(UTC+03:00) EET (EEST)"},
            {value: "Asia/Jerusalem", text: "(UTC+03:00) Asia/Jerusalem (IDT)"},
            {value: "Asia/Tel_Aviv", text: "(UTC+03:00) Asia/Tel Aviv (IDT)"},
            {value: "Israel", text: "(UTC+03:00) Israel (IDT)"},
            {value: "W-SU", text: "(UTC+03:00) W-SU (MSK)"},
            {
                value: "Europe/Simferopol",
                text: "(UTC+03:00) Europe/Simferopol (MSK)",
            },
            {value: "Europe/Moscow", text: "(UTC+03:00) Europe/Moscow (MSK)"},
            {value: "Asia/Muscat", text: "(UTC+04:00) Asia/Muscat"},
            {value: "Asia/Dubai", text: "(UTC+04:00) Asia/Dubai"},
            {value: "Asia/Baku", text: "(UTC+04:00) Asia/Baku"},
            {value: "Asia/Yerevan", text: "(UTC+04:00) Asia/Yerevan"},
            {value: "Asia/Tbilisi", text: "(UTC+04:00) Asia/Tbilisi"},
            {value: "Indian/Mahe", text: "(UTC+04:00) Indian/Mahe"},
            {value: "Indian/Mauritius", text: "(UTC+04:00) Indian/Mauritius"},
            {value: "Indian/Reunion", text: "(UTC+04:00) Indian/Reunion"},
            {value: "Etc/GMT-4", text: "(UTC+04:00) Etc/GMT-4"},
            {value: "Europe/Volgograd", text: "(UTC+04:00) Europe/Volgograd"},
            {value: "Europe/Astrakhan", text: "(UTC+04:00) Europe/Astrakhan"},
            {value: "Europe/Samara", text: "(UTC+04:00) Europe/Samara"},
            {value: "Europe/Ulyanovsk", text: "(UTC+04:00) Europe/Ulyanovsk"},
            {value: "Europe/Saratov", text: "(UTC+04:00) Europe/Saratov"},
            {value: "Asia/Kabul", text: "(UTC+04:30) Asia/Kabul"},
            {value: "Asia/Tehran", text: "(UTC+04:30) Asia/Tehran"},
            {value: "Iran", text: "(UTC+04:30) Iran"},
            {value: "Asia/Aqtau", text: "(UTC+05:00) Asia/Aqtau"},
            {value: "Asia/Tashkent", text: "(UTC+05:00) Asia/Tashkent"},
            {value: "Asia/Atyrau", text: "(UTC+05:00) Asia/Atyrau"},
            {value: "Asia/Samarkand", text: "(UTC+05:00) Asia/Samarkand"},
            {
                value: "Asia/Yekaterinburg",
                text: "(UTC+05:00) Asia/Yekaterinburg",
            },
            {value: "Asia/Ashgabat", text: "(UTC+05:00) Asia/Ashgabat"},
            {value: "Asia/Dushanbe", text: "(UTC+05:00) Asia/Dushanbe"},
            {value: "Asia/Ashkhabad", text: "(UTC+05:00) Asia/Ashkhabad"},
            {value: "Asia/Aqtobe", text: "(UTC+05:00) Asia/Aqtobe"},
            {value: "Asia/Qyzylorda", text: "(UTC+05:00) Asia/Qyzylorda"},
            {value: "Asia/Oral", text: "(UTC+05:00) Asia/Oral"},
            {value: "Indian/Kerguelen", text: "(UTC+05:00) Indian/Kerguelen"},
            {value: "Indian/Maldives", text: "(UTC+05:00) Indian/Maldives"},
            {value: "Antarctica/Mawson", text: "(UTC+05:00) Antarctica/Mawson"},
            {value: "Etc/GMT-5", text: "(UTC+05:00) Etc/GMT-5"},
            {value: "Asia/Karachi", text: "(UTC+05:00) Asia/Karachi (PKT)"},
            {value: "Asia/Colombo", text: "(UTC+05:30) Asia/Colombo"},
            {value: "Asia/Kolkata", text: "(UTC+05:30) Asia/Kolkata (IST)"},
            {value: "Asia/Calcutta", text: "(UTC+05:30) Asia/Calcutta (IST)"},
            {value: "Asia/Kathmandu", text: "(UTC+05:45) Asia/Kathmandu"},
            {value: "Asia/Katmandu", text: "(UTC+05:45) Asia/Katmandu"},
            {value: "Asia/Urumqi", text: "(UTC+06:00) Asia/Urumqi"},
            {value: "Asia/Thimbu", text: "(UTC+06:00) Asia/Thimbu"},
            {value: "Asia/Dacca", text: "(UTC+06:00) Asia/Dacca"},
            {value: "Asia/Qostanay", text: "(UTC+06:00) Asia/Qostanay"},
            {value: "Asia/Bishkek", text: "(UTC+06:00) Asia/Bishkek"},
            {value: "Asia/Kashgar", text: "(UTC+06:00) Asia/Kashgar"},
            {value: "Asia/Dhaka", text: "(UTC+06:00) Asia/Dhaka"},
            {value: "Asia/Almaty", text: "(UTC+06:00) Asia/Almaty"},
            {value: "Asia/Omsk", text: "(UTC+06:00) Asia/Omsk"},
            {value: "Asia/Thimphu", text: "(UTC+06:00) Asia/Thimphu"},
            {value: "Indian/Chagos", text: "(UTC+06:00) Indian/Chagos"},
            {value: "Antarctica/Vostok", text: "(UTC+06:00) Antarctica/Vostok"},
            {value: "Etc/GMT-6", text: "(UTC+06:00) Etc/GMT-6"},
            {value: "Asia/Rangoon", text: "(UTC+06:30) Asia/Rangoon"},
            {value: "Asia/Yangon", text: "(UTC+06:30) Asia/Yangon"},
            {value: "Indian/Cocos", text: "(UTC+06:30) Indian/Cocos"},
            {value: "Asia/Barnaul", text: "(UTC+07:00) Asia/Barnaul"},
            {value: "Asia/Vientiane", text: "(UTC+07:00) Asia/Vientiane"},
            {value: "Asia/Bangkok", text: "(UTC+07:00) Asia/Bangkok"},
            {value: "Asia/Phnom_Penh", text: "(UTC+07:00) Asia/Phnom Penh"},
            {value: "Asia/Hovd", text: "(UTC+07:00) Asia/Hovd"},
            {value: "Asia/Krasnoyarsk", text: "(UTC+07:00) Asia/Krasnoyarsk"},
            {value: "Asia/Tomsk", text: "(UTC+07:00) Asia/Tomsk"},
            {value: "Asia/Saigon", text: "(UTC+07:00) Asia/Saigon"},
            {value: "Asia/Ho_Chi_Minh", text: "(UTC+07:00) Asia/Ho Chi Minh"},
            {value: "Asia/Novokuznetsk", text: "(UTC+07:00) Asia/Novokuznetsk"},
            {value: "Asia/Novosibirsk", text: "(UTC+07:00) Asia/Novosibirsk"},
            {value: "Indian/Christmas", text: "(UTC+07:00) Indian/Christmas"},
            {value: "Antarctica/Davis", text: "(UTC+07:00) Antarctica/Davis"},
            {value: "Etc/GMT-7", text: "(UTC+07:00) Etc/GMT-7"},
            {value: "Asia/Jakarta", text: "(UTC+07:00) Asia/Jakarta (WIB)"},
            {value: "Asia/Pontianak", text: "(UTC+07:00) Asia/Pontianak (WIB)"},
            {value: "Asia/Irkutsk", text: "(UTC+08:00) Asia/Irkutsk"},
            {value: "Asia/Brunei", text: "(UTC+08:00) Asia/Brunei"},
            {value: "Asia/Ulaanbaatar", text: "(UTC+08:00) Asia/Ulaanbaatar"},
            {value: "Asia/Kuala_Lumpur", text: "(UTC+08:00) Asia/Kuala Lumpur"},
            {value: "Asia/Choibalsan", text: "(UTC+08:00) Asia/Choibalsan"},
            {value: "Asia/Kuching", text: "(UTC+08:00) Asia/Kuching"},
            {value: "Asia/Singapore", text: "(UTC+08:00) Asia/Singapore"},
            {value: "Asia/Ulan_Bator", text: "(UTC+08:00) Asia/Ulan Bator"},
            {value: "Antarctica/Casey", text: "(UTC+08:00) Antarctica/Casey"},
            {value: "Etc/GMT-8", text: "(UTC+08:00) Etc/GMT-8"},
            {value: "Singapore", text: "(UTC+08:00) Singapore"},
            {
                value: "Australia/West",
                text: "(UTC+08:00) Australia/West (AWST)",
            },
            {
                value: "Australia/Perth",
                text: "(UTC+08:00) Australia/Perth (AWST)",
            },
            {value: "Asia/Shanghai", text: "(UTC+08:00) Asia/Shanghai (CST)"},
            {value: "Asia/Chongqing", text: "(UTC+08:00) Asia/Chongqing (CST)"},
            {value: "Asia/Macau", text: "(UTC+08:00) Asia/Macau (CST)"},
            {value: "Asia/Chungking", text: "(UTC+08:00) Asia/Chungking (CST)"},
            {value: "Asia/Harbin", text: "(UTC+08:00) Asia/Harbin (CST)"},
            {value: "Asia/Macao", text: "(UTC+08:00) Asia/Macao (CST)"},
            {value: "Asia/Taipei", text: "(UTC+08:00) Asia/Taipei (CST)"},
            {value: "PRC", text: "(UTC+08:00) PRC (CST)"},
            {value: "ROC", text: "(UTC+08:00) ROC (CST)"},
            {value: "Asia/Hong_Kong", text: "(UTC+08:00) Asia/Hong Kong (HKT)"},
            {value: "Hongkong", text: "(UTC+08:00) Hongkong (HKT)"},
            {value: "Asia/Manila", text: "(UTC+08:00) Asia/Manila (PST)"},
            {
                value: "Asia/Ujung_Pandang",
                text: "(UTC+08:00) Asia/Ujung Pandang (WITA)",
            },
            {value: "Asia/Makassar", text: "(UTC+08:00) Asia/Makassar (WITA)"},
            {value: "Australia/Eucla", text: "(UTC+08:45) Australia/Eucla"},
            {value: "Asia/Dili", text: "(UTC+09:00) Asia/Dili"},
            {value: "Asia/Chita", text: "(UTC+09:00) Asia/Chita"},
            {value: "Asia/Yakutsk", text: "(UTC+09:00) Asia/Yakutsk"},
            {value: "Asia/Khandyga", text: "(UTC+09:00) Asia/Khandyga"},
            {value: "Etc/GMT-9", text: "(UTC+09:00) Etc/GMT-9"},
            {value: "Pacific/Palau", text: "(UTC+09:00) Pacific/Palau"},
            {value: "Asia/Tokyo", text: "(UTC+09:00) Asia/Tokyo (JST)"},
            {value: "Japan", text: "(UTC+09:00) Japan (JST)"},
            {value: "Asia/Seoul", text: "(UTC+09:00) Asia/Seoul (KST)"},
            {value: "Asia/Pyongyang", text: "(UTC+09:00) Asia/Pyongyang (KST)"},
            {value: "ROK", text: "(UTC+09:00) ROK (KST)"},
            {value: "Asia/Jayapura", text: "(UTC+09:00) Asia/Jayapura (WIT)"},
            {
                value: "Australia/South",
                text: "(UTC+09:30) Australia/South (ACST)",
            },
            {
                value: "Australia/Adelaide",
                text: "(UTC+09:30) Australia/Adelaide (ACST)",
            },
            {
                value: "Australia/Broken_Hill",
                text: "(UTC+09:30) Australia/Broken Hill (ACST)",
            },
            {
                value: "Australia/Darwin",
                text: "(UTC+09:30) Australia/Darwin (ACST)",
            },
            {
                value: "Australia/North",
                text: "(UTC+09:30) Australia/North (ACST)",
            },
            {
                value: "Australia/Yancowinna",
                text: "(UTC+09:30) Australia/Yancowinna (ACST)",
            },
            {value: "Asia/Ust-Nera", text: "(UTC+10:00) Asia/Ust-Nera"},
            {value: "Asia/Vladivostok", text: "(UTC+10:00) Asia/Vladivostok"},
            {
                value: "Antarctica/DumontDUrville",
                text: "(UTC+10:00) Antarctica/DumontDUrville",
            },
            {value: "Etc/GMT-10", text: "(UTC+10:00) Etc/GMT-10"},
            {value: "Pacific/Chuuk", text: "(UTC+10:00) Pacific/Chuuk"},
            {value: "Pacific/Truk", text: "(UTC+10:00) Pacific/Truk"},
            {
                value: "Pacific/Port_Moresby",
                text: "(UTC+10:00) Pacific/Port Moresby",
            },
            {value: "Pacific/Yap", text: "(UTC+10:00) Pacific/Yap"},
            {
                value: "Australia/Canberra",
                text: "(UTC+10:00) Australia/Canberra (AEST)",
            },
            {
                value: "Australia/Victoria",
                text: "(UTC+10:00) Australia/Victoria (AEST)",
            },
            {
                value: "Australia/Sydney",
                text: "(UTC+10:00) Australia/Sydney (AEST)",
            },
            {
                value: "Australia/Brisbane",
                text: "(UTC+10:00) Australia/Brisbane (AEST)",
            },
            {value: "Australia/NSW", text: "(UTC+10:00) Australia/NSW (AEST)"},
            {
                value: "Australia/Melbourne",
                text: "(UTC+10:00) Australia/Melbourne (AEST)",
            },
            {value: "Australia/ACT", text: "(UTC+10:00) Australia/ACT (AEST)"},
            {
                value: "Australia/Lindeman",
                text: "(UTC+10:00) Australia/Lindeman (AEST)",
            },
            {
                value: "Australia/Queensland",
                text: "(UTC+10:00) Australia/Queensland (AEST)",
            },
            {
                value: "Australia/Hobart",
                text: "(UTC+10:00) Australia/Hobart (AEST)",
            },
            {
                value: "Australia/Tasmania",
                text: "(UTC+10:00) Australia/Tasmania (AEST)",
            },
            {
                value: "Australia/Currie",
                text: "(UTC+10:00) Australia/Currie (AEST)",
            },
            {
                value: "Pacific/Saipan",
                text: "(UTC+10:00) Pacific/Saipan (ChST)",
            },
            {value: "Pacific/Guam", text: "(UTC+10:00) Pacific/Guam (ChST)"},
            {value: "Australia/LHI", text: "(UTC+10:30) Australia/LHI"},
            {
                value: "Australia/Lord_Howe",
                text: "(UTC+10:30) Australia/Lord Howe",
            },
            {
                value: "Asia/Srednekolymsk",
                text: "(UTC+11:00) Asia/Srednekolymsk",
            },
            {value: "Asia/Sakhalin", text: "(UTC+11:00) Asia/Sakhalin"},
            {value: "Asia/Magadan", text: "(UTC+11:00) Asia/Magadan"},
            {
                value: "Antarctica/Macquarie",
                text: "(UTC+11:00) Antarctica/Macquarie",
            },
            {value: "Etc/GMT-11", text: "(UTC+11:00) Etc/GMT-11"},
            {value: "Pacific/Pohnpei", text: "(UTC+11:00) Pacific/Pohnpei"},
            {value: "Pacific/Ponape", text: "(UTC+11:00) Pacific/Ponape"},
            {value: "Pacific/Noumea", text: "(UTC+11:00) Pacific/Noumea"},
            {value: "Pacific/Efate", text: "(UTC+11:00) Pacific/Efate"},
            {
                value: "Pacific/Bougainville",
                text: "(UTC+11:00) Pacific/Bougainville",
            },
            {
                value: "Pacific/Guadalcanal",
                text: "(UTC+11:00) Pacific/Guadalcanal",
            },
            {value: "Pacific/Kosrae", text: "(UTC+11:00) Pacific/Kosrae"},
            {value: "Pacific/Norfolk", text: "(UTC+11:00) Pacific/Norfolk"},
            {value: "Asia/Kamchatka", text: "(UTC+12:00) Asia/Kamchatka"},
            {value: "Asia/Anadyr", text: "(UTC+12:00) Asia/Anadyr"},
            {value: "Etc/GMT-12", text: "(UTC+12:00) Etc/GMT-12"},
            {value: "Kwajalein", text: "(UTC+12:00) Kwajalein"},
            {value: "Pacific/Funafuti", text: "(UTC+12:00) Pacific/Funafuti"},
            {value: "Pacific/Fiji", text: "(UTC+12:00) Pacific/Fiji"},
            {value: "Pacific/Majuro", text: "(UTC+12:00) Pacific/Majuro"},
            {value: "Pacific/Wallis", text: "(UTC+12:00) Pacific/Wallis"},
            {value: "Pacific/Nauru", text: "(UTC+12:00) Pacific/Nauru"},
            {value: "Pacific/Kwajalein", text: "(UTC+12:00) Pacific/Kwajalein"},
            {value: "Pacific/Wake", text: "(UTC+12:00) Pacific/Wake"},
            {value: "Pacific/Tarawa", text: "(UTC+12:00) Pacific/Tarawa"},
            {value: "NZ", text: "(UTC+12:00) NZ (NZST)"},
            {
                value: "Antarctica/South_Pole",
                text: "(UTC+12:00) Antarctica/South Pole (NZST)",
            },
            {
                value: "Antarctica/McMurdo",
                text: "(UTC+12:00) Antarctica/McMurdo (NZST)",
            },
            {
                value: "Pacific/Auckland",
                text: "(UTC+12:00) Pacific/Auckland (NZST)",
            },
            {value: "NZ-CHAT", text: "(UTC+12:45) NZ-CHAT"},
            {value: "Pacific/Chatham", text: "(UTC+12:45) Pacific/Chatham"},
            {value: "Etc/GMT-13", text: "(UTC+13:00) Etc/GMT-13"},
            {value: "Pacific/Tongatapu", text: "(UTC+13:00) Pacific/Tongatapu"},
            {value: "Pacific/Fakaofo", text: "(UTC+13:00) Pacific/Fakaofo"},
            {value: "Pacific/Enderbury", text: "(UTC+13:00) Pacific/Enderbury"},
            {value: "Pacific/Apia", text: "(UTC+13:00) Pacific/Apia"},
            {value: "Etc/GMT-14", text: "(UTC+14:00) Etc/GMT-14"},
            {
                value: "Pacific/Kiritimati",
                text: "(UTC+14:00) Pacific/Kiritimati",
            },
        ];

        const columnDateValueSelect = rColumnValueSelectFromStepOutput({
            columns: inputStep.outputColumns,
            model,
            filterColumnFn: (c) => ["DateTime", "DateTimeOffset"].includes(c.dataType),
            isValueNotAllowed: (c) => !["DateTime", "DateTimeOffset"].includes(c.dataType),
        });
        const columnValueSelect = rColumnValueSelectFromStepOutput({
            columns: inputStep.outputColumns,
            model,
            filterColumnFn: (c) => ["Text"].includes(c.dataType),
            isValueNotAllowed: (c) => !["Text"].includes(c.dataType),
        });

        const sourceTZDropdownProps = ffToDropdown(form.field(["operation", "sourceTimeZone"]));
        const targetTZDropdownProps = ffToDropdown(form.field(["operation", "targetTimeZone"]));

        const hasStatic = sourceTZDropdownProps.value?.$type === "StaticValue" || targetTZDropdownProps.value?.$type === "StaticValue";

        return (
            <div className="convert-timezone-operations-9ew">
                <div className="config-group">
                    <div className="label">Date/Time Input</div>

                    <div className="select-group">
                        {(() => {
                            const dropdownProps = ffToDropdown(form.field(["operation", "input"]));
                            return (
                                <>
                                    {columnDateValueSelect({
                                        state: {
                                            value: dropdownProps.value,
                                            onChange: dropdownProps.onChange,
                                        },
                                        ...keepOnly(dropdownProps, ["domRef", "hasError", "errorMessage"]),
                                    })}

                                    {dropdownProps.value?.$type === "StaticValue" &&
                                        DatePicker({
                                            label: "Date",
                                            ...ffToDatePicker(form.field(["operation", "input", "value"])),
                                        })}
                                </>
                            );
                        })()}
                    </div>
                </div>

                <div className="config-group">
                    <div className="label label-group">
                        <div>Original Time Zone</div>
                        <div>New Time Zone</div>
                    </div>

                    <div className={hasStatic ? "timezone-group has-static" : "timezone-group"}>
                        {(() => {
                            return columnValueSelect({
                                state: {
                                    value: sourceTZDropdownProps.value,
                                    onChange: sourceTZDropdownProps.onChange,
                                },
                                ...keepOnly(sourceTZDropdownProps, ["domRef", "hasError", "errorMessage"]),
                            });
                        })()}

                        {(() => {
                            return columnValueSelect({
                                state: {
                                    value: targetTZDropdownProps.value,
                                    onChange: targetTZDropdownProps.onChange,
                                },
                                ...keepOnly(targetTZDropdownProps, ["domRef", "hasError", "errorMessage"]),
                            });
                        })()}
                    </div>

                    {hasStatic && (
                        <div className="timezone-group is-static">
                            {(() => {
                                return (
                                    sourceTZDropdownProps.value?.$type === "StaticValue" &&
                                    DropdownSelectSearch({
                                        label: "Original Time Zone",
                                        list: timeZones,
                                        valueToLabel: (v) => v.text,
                                        valueToSearch: (v) => (v.text && v.text.length > 12 ? v.text.substring(12) : v.text),
                                        isSelected: (v) => v.value === sourceTZDropdownProps.value?.value,
                                        onChange: (v) =>
                                            sourceTZDropdownProps.onChange({
                                                $type: "StaticValue",
                                                value: v.value,
                                            }),
                                        ...keepOnly(sourceTZDropdownProps, ["hasError", "errorMessage", "domRef"]),
                                    })
                                );
                            })()}
                            {(() => {
                                return sourceTZDropdownProps.value?.$type !== "StaticValue" && <div className="dropdown-select-search static-filler"></div>;
                            })()}

                            {(() => {
                                return (
                                    targetTZDropdownProps.value?.$type === "StaticValue" &&
                                    DropdownSelectSearch({
                                        label: "New Time Zone",
                                        list: timeZones,
                                        valueToLabel: (v) => v.text,
                                        valueToSearch: (v) => (v.text && v.text.length > 12 ? v.text.substring(12) : v.text),
                                        isSelected: (v) => v.value === targetTZDropdownProps.value?.value,
                                        onChange: (v) =>
                                            targetTZDropdownProps.onChange({
                                                $type: "StaticValue",
                                                value: v.value,
                                            }),
                                        ...keepOnly(targetTZDropdownProps, ["hasError", "errorMessage", "domRef"]),
                                    })
                                );
                            })()}
                            {(() => {
                                return targetTZDropdownProps.value?.$type !== "StaticValue" && <div className="dropdown-select-search static-filler"></div>;
                            })()}
                        </div>
                    )}
                </div>

                {ResultActionSelect({
                    state: operation,
                    form,
                    path: "operation",
                })}
            </div>
        );
    });
