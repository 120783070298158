import "./select-tile-type.scss";

import {cx} from "emotion";
import * as React from "react";

import {keyed} from "@common/react/keyed";
import {getELoc} from "@common/react/get-e-loc";
import {cs} from "@common/react/chain-services";
import {UseMemo} from "@common/react/use-memo";

import {DragWatcher} from "../../../model/common/drag-watcher";
import {Expandable} from "../../../common/expandable/expandable";
import {tooltipService3} from "../../../common/tooltip3/tooltip-service-3";

import {disableSelectTileType} from "./disable-select-tile-type";
import {tileTypes} from "./tile-types";
import {categorizedTiles} from "./utils";
import {keepOnly} from "@common/utils/objects";
import {consumeContext} from "@common/react/context";

export const TileTypeList = ({
    tiles,
    tooltip,
    value,
    onChange,
    form,
    oldType = null,
    usingClick = false,
    disabledChecking = false,
    panel,
    changeTitleWhenChangeTile,
}) =>
    tiles.map((type, i) =>
        cs(
            keyed(i),
            consumeContext("collection"),
            [
                "dragWatcher",
                ({collection}, next) =>
                    DragWatcher({
                        onDragged: (e) => {
                            if (!usingClick && panel) {
                                panel?.startDraggingNewTile({
                                    tile: {
                                        ...type.template,
                                        title: `${type.name} ${Math.floor(Math.random() * (999 - 100 + 1) + 100)}`,
                                        icon: type.icon,
                                        name: type.name,
                                        modelID: collection.value.modelID,
                                    },
                                    pos: getELoc(e),
                                });
                            }
                        },
                        next,
                    }),
            ],
            ({dragWatcher}) => {
                const onClick = () => {
                    if (usingClick) {
                        if ((!disableSelectTileType(value) || disabledChecking) && !isOldType) {
                            // can change $type if allowed, if not, change to same $type (only LineChart for now)
                            onChange({
                                // ...(value || {}),
                                ...type.template,
                                ...(changeTitleWhenChangeTile
                                    ? {
                                          title: `${type.name} ${Math.floor(Math.random() * (999 - 100 + 1) + 100)}`,
                                      }
                                    : {}),
                            });
                        } else {
                            // will have rules to convert fields later when change to different $type
                            type.change && onChange(type.change(value));
                        }

                        if (form) {
                            type?.afterChange?.(form.field("size"));
                        }
                    }
                };

                const isDisabledOption = !disabledChecking && disableSelectTileType(value) && !type.types.includes(value?.$type);
                const isOldType = oldType && type.types.includes(oldType);

                return (
                    <div
                        {...{
                            className: cx("type", type.types[0], {
                                disabled: isDisabledOption || isOldType, // enable option of same $type (only LineChart for now)
                                selected: usingClick
                                    ? type.types.includes(value?.$type) &&
                                      (!type.displayTypes ||
                                          !value?.style.displayType ||
                                          type.displayTypes.includes(value?.style.displayType))
                                    : false,
                                "allow-drag": !usingClick,
                            }),
                            onClick,
                            onMouseDown: (e) => {
                                if (!isDisabledOption && !usingClick) {
                                    dragWatcher.onMouseDown(e);
                                }
                            },
                            ...tooltip(() => (isOldType ? `${type.name}: Current tile type` : type.name)),
                        }}
                    >
                        {type.icon}
                    </div>
                );
            }
        )
    );

export const SelectTileType = ({list = tileTypes, ...props}) =>
    cs(
        tooltipService3(),
        [
            "categories",
            (_, next) =>
                UseMemo({
                    fn: () => categorizedTiles(list),
                    deps: [],
                    next,
                }),
        ],
        ({tooltip, categories}) => {
            return (
                <div className="select-tile-types-4gs">
                    {categories.map((c) => (
                        <Expandable
                            {...{
                                alwaysShow: true,
                                initExpand: true,
                                className: "",
                                label: c.label,
                                render: () => (
                                    <div className="tile-category">
                                        {TileTypeList({
                                            ...props,
                                            tiles: c.tiles,
                                            tooltip,
                                        })}
                                    </div>
                                ),
                            }}
                        />
                    ))}
                </div>
            );
        }
    );
