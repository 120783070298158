import * as React from "react";

import {chain} from "@common/utils/fs";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {Button} from "@common/form/buttons/button/button";
import {DropdownSelectSearch} from "@common/ui-components/dropdown-select/dropdown-select-search";

import {DialogService} from "../../../../common/dialog/dialog-service";
import {rFieldTypeIcon} from "../../../../common/field-type-icon/get-field-type-icon";
import {AlertMessage} from "../../../../common/alert-message/alert-message";

export const SelectPrimaryKeyModal = ({next: rootNext}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    registryRender: true,
                    render: ({resolve, args: {columns}}) => ({
                        title: "SELECT A PRIMARY KEY",
                        width: 620,
                        content: next({resolve, columns}),
                        className: "",
                    }),
                    next: rootNext,
                }),
        ],
        ({modal}) =>
            cs(["selectedColumn", (_, next) => UseState({next, initValue: null})], ({selectedColumn}) => (
                <div className="select-primary-key-modal-n45">
                    <div className="dialog-body">
                        <AlertMessage message="Only select a field that contains data that is guaranteed to be unique. If it is not unique this will cause duplication issues with transformations." />

                        <div className="sub-padding">
                            <div className="text">Select the column to use as the primary key. (This cannot be undone.)</div>

                            <br />

                            {DropdownSelectSearch({
                                searchPlaceholder: "Find Column",
                                label: "Column",
                                valueToSearch: (v) => v.name,
                                list: chain(modal.columns, (_) => _.filter((c) => ["IDInt", "IDUUID", "Text", "Int"].indexOf(c.dataType) > -1)),
                                valueToLabel: (v) => (
                                    <>
                                        {rFieldTypeIcon(v.dataType)} {v.name}
                                    </>
                                ),
                                isSelected: (v) => (selectedColumn.value?.id ? v.id === selectedColumn.value?.id : v.name === selectedColumn.value?.name),
                                onChange: (v) => selectedColumn.change(() => v),
                            })}
                        </div>
                    </div>

                    <div className="buttons">
                        <Button size="large" onClick={() => modal.resolve(false)}>
                            Cancel
                        </Button>

                        <Button size="large" btnType="secondary" disabled={selectedColumn.value == null} onClick={() => modal.resolve(selectedColumn.value)}>
                            Done
                        </Button>
                    </div>
                </div>
            ))
    );
