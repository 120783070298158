const {Rerender} = require("./rerender");
const {Static2} = require("./static-2");
const {cs} = require("./chain-services");

const DownwardProxy = ({value, allow, next}) => cs(["proxy", (_, next) => DownwardProxy2({value, allow, next})], ({proxy}) => next(proxy.value));
exports.DownwardProxy = DownwardProxy;

const DownwardProxy2 = ({value, allow, next}) =>
    cs(
        ["ref", (_, next) => Static2({next})],
        ["rerender", (_, next) => Rerender({next})],
        [
            "chosen",
            ({ref}, next) => {
                const r = ref.get();
                if (!r || allow(value, r.value)) {
                    ref.set({value});
                    return next(value);
                } else {
                    return next(r.value);
                }
            },
        ],
        ({chosen, ref, rerender}) =>
            next({
                value: chosen,
                oldValue: ref.get()?.value,
                rawValue: value,
                flush: () => {
                    ref.set(null);

                    return new Promise((resolve, reject) => {
                        rerender.invoke(resolve);
                    });
                },
            })
    );
exports.DownwardProxy2 = DownwardProxy2;
