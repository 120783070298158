const {chain} = require("../../utils/fs");
const {fullMonths} = require("../../utils/dates/full-months");
const moment = require("moment");

const formatDateTimeFull = ({date, time} = {}) => {
    if(date == null) {
        return null;
    }

    return (
        fullMonths[date.month - 1] +
        " " +
        date.day +
        chain(date.day > 10 && date.day < 20 ? 0 : date.day % 10, (_) => (_ === 1 ? "st" : _ === 2 ? "nd" : _ === 3 ? "rd" : "th")) +
        " " +
        (new Date().getFullYear() === date.year ? "" : date.year + " ") +
        "at " +
        (time.hours <= 12 ? time.hours : time.hours % 12) +
        ":" +
        (time.minutes < 10 ? "0" + time.minutes : time.minutes) +
        " " +
        (time.hours >= 12 ? "PM" : "AM") +
        ""
    );
};
exports.formatDateTimeFull = formatDateTimeFull;

const formatDateTimeFromISOStr = (date, format = "MMMM Do YYYY [at] h:mm A") => {
    if(date == null) {
        return null;
    }
    return moment(date).format(format);
};

exports.formatDateTimeFromISOStr = formatDateTimeFromISOStr;
