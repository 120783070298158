import * as React from "react";
import {Display} from "./panels/display/display";
import {Legend} from "./panels/legend/legend";
import {ValueFormat} from "./panels/value-format/value-format";
import {DataLabels} from "./panels/data-labels/data-labels";
import {Colors} from "./panels/colors/colors";
import {OtherOptions} from "../common/other-options/other-options";
import {TileTitle} from "../common/tile-title/tile-title";

export const Gauge = ({tile, size, form, theme, parentTile, colorsTileConfig, tileAction, isContainerTile}) =>
    [Display, TileTitle, ValueFormat, DataLabels, Colors, Legend, OtherOptions].map((panel) =>
        panel({
            tile,
            size,
            form,
            theme,
            parentTile,
            colorsTileConfig,
            tileAction,
            isContainerTile,
        })
    );
