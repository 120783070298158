import * as React from "react";
import {cs} from "@common/react/chain-services";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {ffToDropdown} from "../../../../../../../../../../../../common/form/ff-to-dropdown";
import {upperCase1} from "@common/utils/strings";
import "../number-filter/number-filter-options";

export const BooleanFilterOptions = ({form}) =>
    cs(({}) => (
        <div className="local-filter-options local-number-filter-options-dg5">
            {DropdownSelect({
                label: "Boolean Value",
                list: [true, false],
                valueToLabel: (op) => upperCase1(op.toString()),
                ...ffToDropdown(form.field("defaultValue")),
            })}
        </div>
    ));
