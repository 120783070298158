import {chain} from "@common/utils/fs";
import {cs} from "@common/react/chain-services";
import * as React from "react";
import "./dnd-grid-panel.scss";
import {cx} from "emotion";
import {ObserveDomSize} from "@common/react/observe-dom-size";
import {createArray} from "@common/utils/collections";
import {DnD} from "./dnd/dnd";
import {Tiles} from "./tiles/tiles";
import {inside} from "@common/utils/rectangles";
import {RejectScrollbarWidth} from "@common/ui-components/live/common/reject-scrollbar-width";
import {addingTileSize, gridHeight, numColumnsOfGrid} from "./grid-constants";
import {DraggingNewTile} from "../../edit/tabs/tiles/dragging-new-tile/dragging-new-tile";
import {scope} from "@common/react/scope";
import {UseState} from "@common/react/use-state";
import {Watch} from "@common/react/watch";
import {conflictRect, getClonedTile, getEmptyNInsertableRows, updatedCollectionAfterAdd} from "./dnd-common";
import {isInsideFiltersArea} from "./dnd/moving";
// import {Static2} from "@common/react/static-2";
import {OnMounted} from "@common/react/on-mounted";
import {OnUnmounted} from "@common/react/on-unmounted";
import {TrashIconBold} from "../../../common/icons/trash-icon";
import {ButtonIcon} from "../../../../../../common/form/buttons/button-icon/button-icon";
// import {changePath} from "@common/utils/arr-path";
import {PlusIcon} from "@common/ui-components/icons/global-icons";
import {dragDownloadReportTileToFilters, removeAllTiles, removeTiles, resizeTile, windowAddNewTile} from "./dnd-support-unit-test";
import {consumeContext, provideContext} from "@common/react/context";
import ReactDOM from "react-dom";
import {exactDimensionTileRule, tileDefaultSizeRules, tileMinSizeRules} from "./tile-rule";
import {StyledClass} from "@common/react/styled-class";

export const DummyReportTileID = "dummy-report-tile-id";

export const DnDGridPanel = ({tiles, getMinHeight, fixedWidth, spacing = 3, theme, draggingState, collection, filterDropArea, showFilterDrop}) =>
    cs(
        consumeContext("tutorialBoxRef"),
        consumeContext("routing"),
        consumeContext("apis"),
        (_, next) => provideContext("editingMode", true, next),
        [
            "css",
            ({}, next) =>
                StyledClass({
                    content: {
                        margin: `-${spacing}px`,
                    },
                    next,
                }),
        ],
        [
            "layout",
            ({dndGridContainer, css}, next) =>
                cs(
                    ["size", ({}, next) => ObserveDomSize({next, delay: 200, debounce: 150, useDebounce: true})],
                    [
                        "size",
                        ({size}, next) => (
                            <div
                                className="dnd-grid-panel-3si"
                                ref={(elem) => {
                                    size.ref(elem);
                                }}
                            >
                                <div
                                    // className={cx("extrude-content", css`margin: -${spacing}px; height: calc(100% + ${spacing*2}px);`)}
                                    className={cx("extrude-content", css)}
                                >
                                    {size.value &&
                                        next({
                                            width: size.value.width + spacing * 2,
                                            height: size.value.height + spacing * 2,
                                        })}
                                </div>
                            </div>
                        ),
                    ],
                    ({size}) => RejectScrollbarWidth({size, next})
                ),
        ],
        ["blockSize", ({layout}, next) => next(Math.max(1, (fixedWidth ?? layout.width) / numColumnsOfGrid))],
        [
            "dnd",
            ({blockSize}, next) =>
                DnD({
                    tiles,
                    blockSize,
                    spacing,
                    draggingState,
                    filterDropArea,
                    allowFilterDrops: (tile) => collection.value.filterStyle.display != "SDK" && tile.$type == "DownloadReportTile",
                    restrictResize: (rect) => ({
                        ...rect,
                        width: Math.min(rect.width, numColumnsOfGrid - rect.x),
                        x: Math.max(0, rect.x),
                        y: Math.max(0, rect.y),
                    }),
                    restrictMove: (rect) => ({
                        ...rect,
                        x: Math.min(Math.max(0, rect.x), numColumnsOfGrid - rect.width),
                        y: Math.max(0, rect.y),
                    }),
                    rejectRect: ({tile, rect, skipRule}) => {
                        if (!skipRule) {
                            const exactRule = exactDimensionTileRule[tile.$type];

                            if (exactRule && (rect.width != exactRule.width || rect.height != exactRule.height)) {
                                return true;
                            }

                            const getTileSizeRuleKey = () => {
                                if (tile.$type == "SparkLineKPITile") {
                                    return `${tile.$type}_${tile.style.sparkLineOrientation}`;
                                }

                                return tile.$type;
                            };

                            const rule = tileMinSizeRules[getTileSizeRuleKey()];
                            if (
                                rect.width + rect.x > numColumnsOfGrid ||
                                rect.width < 1 ||
                                rect.height < 1 ||
                                (rule && rule.width > rect.width) ||
                                (rule && rule.height > rect.height)
                            ) {
                                return true;
                            }
                        }

                        return conflictRect({rect, tiles: tiles.filter((t) => t.key !== tile.key)});
                    },
                    onMoved: ({tile, rect}) => tile.onChangePosition?.(rect),
                    onResized: ({tile, rect}) => {
                        tile.onChangeSize(rect);
                    },
                    next,
                    onDropToFilters: (tile) => tile.onDropToFilters(),
                }),
        ],
        ["maxHeightState", (_, next) => UseState({next})],
        ({layout, blockSize: blockWidth, dnd, maxHeightState, tutorialBoxRef, routing, apis}) => {
            const maxTileHeight = !tiles?.length ? 0 : Math.max(...tiles.map((tile) => tile.position.y + tile.size.height));
            const getSize = (draggingNew) => {
                if (draggingNew.movingFilter) {
                    return {width: 6, height: 2};
                }

                const rule = tileDefaultSizeRules[draggingNew.tile.$type];

                return {
                    width: draggingNew.tile.defaultColSpan ?? rule?.width ?? addingTileSize,
                    height: draggingNew.tile.defaultRowSpan ?? rule?.height ?? addingTileSize,
                };
            };

            const rows = chain(
                Math.max(
                    ...[
                        maxTileHeight,
                        Math.floor((getMinHeight ? getMinHeight() : layout.height) / blockWidth),
                        dnd.activeRect && dnd.activeRect.y + dnd.activeRect.height,
                        draggingState.value?.draggingNew &&
                            draggingState.value?.draggingNew.draggingPos &&
                            draggingState.value?.draggingNew.draggingPos.y + getSize(draggingState.value?.draggingNew).height,
                        maxHeightState.value,
                    ].filter((v) => !isNaN(v))
                ),
                (_) => _
            );

            const getActiveRect = () => {
                const dragState = draggingState?.value?.draggingNew ?? draggingState?.value?.moving;

                if (dragState) {
                    if (dragState.pos && showFilterDrop && isInsideFiltersArea({pos: dragState.pos, filterAreaRect: filterDropArea.get().getBoundingClientRect()})) {
                        return false;
                    }

                    if (draggingState.value?.draggingNew?.draggingPos) {
                        const {width, height} = getSize(draggingState.value?.draggingNew);

                        return {...draggingState.value?.draggingNew.draggingPos, width, height};
                    }
                }

                return dnd.activeRect;
            };

            return (
                <>
                    <div style={{height: maxTileHeight * (gridHeight + spacing * 2)}} />

                    {Watch({
                        value: dnd.activeRect,
                        onChanged: (value) => {
                            if (value) {
                                maxHeightState.change((v) => Math.max(v || 0, value.y + value.height));
                            } else {
                                maxHeightState.onChange(0);
                            }
                        },
                    })}

                    {GridBlocks({
                        blockWidth,
                        rows,
                        spacing,
                        activeRect: getActiveRect(),
                        theme,
                        collection,
                    })}

                    {Tiles({
                        tiles,
                        blockWidth,
                        rows,
                        spacing,
                        dnd,
                        theme,
                        collection,
                        onClone: async (tile) => {
                            const newTile = getClonedTile({tile, tiles, collection});
                            return await collection.change(() => updatedCollectionAfterAdd({tile: newTile.tile, rect: newTile.rect, collection}));
                        },
                    })}

                    {OnMounted({
                        action: () => {
                            window.addNewTile = (tileType, x, y) => windowAddNewTile(tileType, x, y, collection, tiles);
                            window.removeAllTiles = () => removeAllTiles(collection);
                            window.removeTiles = (ids) => removeTiles(collection, ids);
                            window.resizeTile = (id, size) => resizeTile(tiles, id, size);
                            window.dragDownloadReportTileToFilters = (id) => dragDownloadReportTileToFilters(collection, tiles, id);
                        },
                    })}

                    {OnUnmounted({
                        action: () => {
                            window.addNewTile = null;
                            window.removeAllTiles = null;
                            window.resizeTile = null;
                            window.removeTiles = null;
                            window.dragDownloadReportTileToFilters = null;
                        },
                    })}

                    {tutorialBoxRef.get?.() &&
                        tiles.length == 0 &&
                        !draggingState.value?.draggingNew &&
                        (routing.params.collectionTab == "tiles" || !routing.params.collectionTab) &&
                        ReactDOM.createPortal(
                            <div className="adding-tile-tutorial">
                                <div className="box">Drag a tile onto the canvas to begin</div>
                            </div>,
                            tutorialBoxRef.get()
                        )}

                    {draggingState.value?.draggingNew &&
                        DraggingNewTile({
                            dnd,
                            blockWidth,
                            spacing,
                            tiles,
                            showFilterDrop,
                            filterDropArea,
                            maxHeightState,
                            draggingNew: scope(draggingState, ["draggingNew"]),
                            onDrop: async (newTilePos) => {
                                draggingState.onChange(null);
                                if (newTilePos?.x !== undefined) {
                                    if (draggingState.value.draggingNew.movingFilter) {
                                        if (collection.value.filterDownloadTiles.length == 1) {
                                            collection.change(() => ({
                                                ...updatedCollectionAfterAdd({
                                                    tile: collection.value.filterDownloadTiles[0],
                                                    rect: newTilePos,
                                                    collection,
                                                }),
                                                filterDownloadTiles: [],
                                            }));
                                        } else {
                                            collection.changeWithoutSync(() => updatedCollectionAfterAdd({tile: {id: DummyReportTileID}, rect: newTilePos, collection}));
                                        }
                                    } else {
                                        let newTile = draggingState.value.draggingNew.tile;

                                        const asyncReduce = newTile.isShared
                                            ? async () => {
                                                  const newSharedTileDetail = await apis.collectionTiles.getTile(newTile.id);
                                                  return () =>
                                                      updatedCollectionAfterAdd({
                                                          tile: newSharedTileDetail,
                                                          rect: newTilePos,
                                                          collection,
                                                      });
                                              }
                                            : null;

                                        await collection.change(
                                            () =>
                                                updatedCollectionAfterAdd({
                                                    tile: {
                                                        ...newTile,
                                                        ...(newTile.id ? {temp: Date.now()} : {}),
                                                    },
                                                    rect: newTilePos,
                                                    collection,
                                                }),
                                            true,
                                            asyncReduce
                                        );
                                    }
                                }
                            },
                            onDropToFilters: async () => {
                                let {tile} = draggingState.value.draggingNew;
                                const {show, fontSize} = tile.style.title;

                                if (show && !fontSize) {
                                    tile.style.title.fontSize = "Medium";
                                }

                                draggingState.onChange(null);
                                await collection.change((c) => ({
                                    ...c,
                                    filterDownloadTiles: c.filterDownloadTiles.concat(tile),
                                }));
                            },
                        })}
                </>
            );
        }
    );

const GridBlocks = ({blockWidth, rows, spacing, activeRect, theme, collection}) =>
    cs(({}) => {
        const {emptyRows, insertableRows} = getEmptyNInsertableRows(collection.value.gridLocations);

        return (
            <div className="grid-blocks">
                {createArray(rows).map((y) => (
                    <React.Fragment key={y}>
                        {createArray(numColumnsOfGrid).map((x) => (
                            <div
                                key={x}
                                className={cx("block", {active: activeRect && inside(activeRect, {x, y})})}
                                style={{
                                    padding: spacing,
                                    top: Math.floor(y * (gridHeight + spacing * 2)),
                                    left: Math.floor(x * blockWidth),
                                    width: blockWidth,
                                    height: `${gridHeight + spacing * 2}px`,
                                }}
                            >
                                <div
                                    className="inner-box"
                                    style={{
                                        borderRadius: theme.general.tile.styles.tileCornerRadius ?? 3,
                                    }}
                                />
                            </div>
                        ))}

                        {emptyRows.includes(y) && (
                            <div
                                className="row"
                                style={{
                                    // marginLeft: spacing,
                                    top: Math.floor(y * (gridHeight + spacing * 2)),
                                    left: 0,
                                    width: blockWidth * numColumnsOfGrid - spacing + Math.min(6, spacing),
                                    height: `${gridHeight + spacing * 2}px`,
                                }}
                            >
                                <ButtonIcon
                                    icon={TrashIconBold()}
                                    className="delete-btn"
                                    size="small"
                                    btnType="border"
                                    onClick={() => {
                                        collection.change((c) => ({
                                            ...c,
                                            gridLocations: c.gridLocations.map((gL) => (gL.rowStart - 1 > y ? {...gL, rowStart: gL.rowStart - 1} : gL)),
                                        }));
                                    }}
                                />
                            </div>
                        )}

                        {insertableRows.includes(y) && (
                            <div
                                className="insert-line"
                                style={{
                                    top: Math.floor(y * (gridHeight + spacing * 2)),
                                    left: spacing,
                                    width: blockWidth * numColumnsOfGrid - spacing * 2 + Math.min(6, spacing),
                                }}
                            >
                                <div className="visible-line" />

                                <ButtonIcon
                                    className="add-line-btn"
                                    btnType="primary"
                                    icon={<PlusIcon />}
                                    size="small"
                                    onClick={() => {
                                        collection.change((c) => ({
                                            ...c,
                                            gridLocations: c.gridLocations.map((gL) => (gL.rowStart - 1 >= y ? {...gL, rowStart: gL.rowStart + 1} : gL)),
                                        }));
                                    }}
                                />
                            </div>
                        )}
                    </React.Fragment>
                ))}
            </div>
        );
    });
