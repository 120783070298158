export const settingKey = {
    DATA_ACCESS: "data_access",
    IFRAME: "iframe",
};

export const settingKeyLabels = {
    [settingKey.DATA_ACCESS]: {
        label: "Data Access API",
        rollKey: {
            reveal: "Reveal New Key",
            send: "Send New Key",
        },
    },
    [settingKey.IFRAME]: {
        label: "iFrame",
        rollKey: {
            reveal: "Show Updated URLs",
            send: "Send Updated URLs",
        },
    },
};
