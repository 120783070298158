import {cs} from "../../react/chain-services";
import * as React from "react";
import {Toggle} from "../toggle/toggle";

export const ShowHideToggle = ({state, defaultValue, disabled, isReversed}) =>
    cs(
        // ["state", (_, next) => UseState({initValue: true, next})],
        ["state", (_, next) => next(state)],
        ({state}) =>
            Toggle({
                isReversed,
                disabled,
                state: {
                    ...state,
                    value: state.value === undefined ? defaultValue : state.value,
                },
                smallToggle: true,
                left: {
                    label: "Show",
                    color: "#18c96e",
                },
                right: {
                    label: "Hide",
                    color: "#294661", //"#E9ECEF"
                },
            })
    );
