import React from "react";
import {cx} from "emotion";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {Load2} from "@common/react/load2";
import {UseState} from "@common/react/use-state";
import {createDateFormatter} from "@common/ui-components/charts/common/formatters/date-formatter";
import {LoadingIndicator} from "@common/ui-components/loading-indicator/loading-indicator";
import {PopupMenu} from "../../../common/popup-menu/popup-menu";
import {RecursiveTable} from "../../../common/recursive-table/recursive-table";
import {FinishedAddingDsDialog} from "../../../data-source/add/dialogs/finished-adding-ds-dialog/finished-adding-ds-dialog";
import {CreateFolderDialog} from "../collections/folder-cm/create-folder/create-folder-dialog";
import {buildFoldersTree} from "../collections/folders-tree-structure";
import {CreateModelService} from "./create-model-service";
import {DeleteModelDialog} from "./delete-model-dialog";
import {FolderCM} from "../collections/folder-cm/folder-cm";
import {MoveToDialog} from "../common/move-to-dialog/move-to-dialog";
import {SearchInputBar} from "@common/ui-components/search-input-bar/search-input-bar";
import "./models-dashboard.scss";
import {isBlank} from "@common/utils/strings";
import {ButtonDropdown} from "../../../data-source/common/button-dropdown/button-dropdown";
import {ButtonIconWithTooltip} from "@common/form/buttons/button-icon/button-icon";
import {DocsIcon, NewFolderIcon, PlusIcon} from "@common/ui-components/icons/global-icons";
import {cSsJson} from "@common/react/ss-json";
import {MultiSelect} from "../../common/multi-select";
import {noun} from "@common/utils/plural";

export const ModelsDashboard = ({next, updateEvents, environment}) =>
    cs(
        consumeContext("apis"),
        consumeContext("routing"),
        consumeContext("auth"),
        ({apis}, next) => (apis.model == null ? "Loading..." : next()),
        [
            "models",
            ({apis, routing}, next) =>
                Load2({
                    _key: routing.params?.envId,
                    fetch: () => apis.model.getModels(),
                    next,
                }),
        ],
        [
            "folders",
            ({apis, routing}, next) =>
                Load2({
                    _key: routing.params?.envId,
                    fetch: () => apis.model.getFolders(),
                    next,
                }),
        ],
        [
            "onUpdateFolders",
            ({folders}, next) =>
                next((newFolders) => {
                    const res = folders.onChange(newFolders);
                    updateEvents.emit("reload");
                    return res;
                }),
        ],
        [
            "moveModelDialog",
            ({onUpdateFolders, folders}, next) =>
                MoveToDialog({
                    apiType: "model",
                    folders: folders.value,
                    onDone: onUpdateFolders,
                    next,
                }),
        ],
        [
            "deleteModelDialog",
            ({onUpdateFolders, folders}, next) =>
                DeleteModelDialog({
                    onDone: onUpdateFolders,
                    reloadFolderOnDone: true,
                    next,
                }),
        ],
        ["createModelDialog", ({}, next) => FinishedAddingDsDialog({next, createModel: true})],
        ["createModelService", ({models}, next) => CreateModelService({next, currentLength: models.value?.length})],
        [
            "createFolderDialog",
            ({folders}, next) =>
                CreateFolderDialog({
                    apiType: "model",
                    folders: folders.value,
                    onCreate: (newFolder) => folders.onChange([...(folders.value || []), newFolder]),
                    next,
                }),
        ],
        [
            "keyword",
            (_, next) =>
                UseState({
                    initValue: cSsJson("SEARCH_VALUE").get()?.models || "",
                    next,
                }),
        ],
        [
            "multiSelectCtrl",
            ({moveModelDialog, deleteModelDialog}, next) =>
                MultiSelect({
                    next,
                    itemName: "Model",
                    resolveItemKey: (item) => `${item.type}_${item.id}`,
                    actions: [
                        {
                            label: "Move To",
                            isShow: () => true,
                            onClick: async (selectedItems) => {
                                const updateItems = Object.values(selectedItems);
                                return await moveModelDialog.show({
                                    isUpdateMany: true,
                                    label: `${updateItems.length} ${noun(updateItems.length, "Model")}`,
                                    params: updateItems,
                                });
                            },
                        },
                        {
                            label: "Delete",
                            isShow: () => true,
                            onClick: async (selectedItems) => {
                                const updateItems = Object.values(selectedItems);
                                return await deleteModelDialog.show({
                                    models: updateItems,
                                });
                            },
                        },
                    ],
                }),
        ],
        ({
            createModelDialog,
            multiSelectCtrl,
            moveModelDialog,
            deleteModelDialog,
            routing,
            createModelService,
            createFolderDialog,
            keyword,
            folders,
        }) => {
            return next({
                // number: models.value && models.value.length,
                actions: (
                    <div className="models-dashboard-actions-2rf">
                        {SearchInputBar({state: keyword, type: "models"})}

                        {!environment?.readOnly && (
                            <>
                                {ButtonDropdown({
                                    label: "New",
                                    list: [
                                        {
                                            value: 0,
                                            label: (
                                                <>
                                                    <PlusIcon fill="#919EB0" /> Add New Model
                                                </>
                                            ),
                                        },
                                        {
                                            value: 1,
                                            label: (
                                                <>
                                                    <NewFolderIcon fill="#919EB0" /> Create New Folder
                                                </>
                                            ),
                                        },
                                    ],
                                    valueToLabel: (item) => item.label,
                                    onChange: (item) => {
                                        const maps = {
                                            0: async () => {
                                                await createModelService.createNew({
                                                    onDone: async () => {
                                                        const modelCreated = await createModelDialog.show();
                                                        if (modelCreated?.id) {
                                                            routing.goto("edit-model", {
                                                                modelId: modelCreated.id,
                                                            });
                                                        }
                                                    },
                                                });
                                            },
                                            1: createFolderDialog.show,
                                        };

                                        return maps[item.value]();
                                    },
                                })}
                            </>
                        )}
                    </div>
                ),
                content: cs(
                    [
                        "folderCM",
                        (_, next) =>
                            FolderCM({
                                apiType: "model",
                                folders,
                                next,
                                updateEvents,
                            }),
                    ],
                    [
                        "modelCM",
                        ({}, next) =>
                            PopupMenu({
                                getCommands: ({model}) => {
                                    return [
                                        {
                                            label: "Edit",
                                            onClick: () =>
                                                routing.goto("edit-model", {
                                                    modelId: model.id,
                                                }),
                                        },
                                        ...(!environment?.readOnly
                                            ? [
                                                  {
                                                      label: "Move to",
                                                      onClick: () =>
                                                          moveModelDialog.show({
                                                              params: model,
                                                          }),
                                                  },
                                                  {
                                                      label: "Delete",
                                                      className: "text-danger",
                                                      onClick: () => deleteModelDialog.show({models: [model]}),
                                                  },
                                              ]
                                            : []),
                                    ];
                                },
                                next,
                            }),
                    ],
                    ({modelCM, folderCM}) => {
                        const hasSelectedItems = multiSelectCtrl.numSelectedItems > 0;
                        const tableHeight = `calc(100vh - ${78 + 54 + 40 + (hasSelectedItems ? 52 : 0)}px)`;
                        const isFolder = (item) => item?.hasOwnProperty("children") || item?.hasOwnProperty("models");

                        return (
                            <div className="models-dashboard-content-2rf">
                                {hasSelectedItems && multiSelectCtrl.render()}
                                {!folders.value
                                    ? LoadingIndicator()
                                    : RecursiveTable({
                                          // className: "models-table",
                                          label: "Models",
                                          showNoResult: keyword.value?.length > 0,
                                          onClearSearch: () => keyword.onChange(""),
                                          maxHeight: tableHeight,
                                          getTrClass: (n) => multiSelectCtrl.isSelected(n) && "is-selected-tr",
                                          getChildTrClass: (n) => (isFolder(n) && multiSelectCtrl.isSelected(n) ? "is-selected-tr" : ""),
                                          structure: buildFoldersTree({
                                              itemsProp: "models",
                                              folders: folders.value,
                                              keyword: keyword.value,
                                          }),
                                          isGroupNode: (n) => isFolder(n),
                                          isAlwaysExpanded: () => !isBlank(keyword.value),
                                          onClickRow: (item, event) => {
                                              if (isFolder(item)) {
                                                  return;
                                              }
                                              event.preventDefault();
                                              event.stopPropagation();

                                              multiSelectCtrl.toggleSelect(item);
                                          },
                                          columns: [
                                              {
                                                  label: "Name",
                                                  indented: true,
                                                  format: (m, {expanding}) => {
                                                      if (!m.hasOwnProperty("versionInfo")) {
                                                          return (
                                                              <div
                                                                  className={cx("icon-name-34n", "folder", {
                                                                      expanding,
                                                                  })}
                                                              >
                                                                  <img
                                                                      src={
                                                                          expanding
                                                                              ? require("../collections/icons/folder-open-icon.svg")
                                                                              : require("../collections/icons/folder-icon.svg")
                                                                      }
                                                                      alt={""}
                                                                  />
                                                                  {m.name}
                                                              </div>
                                                          );
                                                      }

                                                      return (
                                                          <div
                                                              className="model-name-89e"
                                                              onClick={(e) => {
                                                                  e.preventDefault();
                                                                  e.stopPropagation();
                                                                  routing.goto("edit-model", {
                                                                      modelId: m.id,
                                                                  });
                                                              }}
                                                          >
                                                              {m.name}
                                                              {m.error && <i className="fa fa-exclamation" />}
                                                          </div>
                                                      );
                                                  },
                                              },
                                              {
                                                  label: "Number of sources",
                                                  format: (m) => m.hasOwnProperty("versionInfo") && m.numSources,
                                              },
                                              {
                                                  label: "Last modified by",
                                                  format: (m) => m.hasOwnProperty("versionInfo") && m.versionInfo?.editedBy,
                                              },
                                              {
                                                  label: "Last modified on",
                                                  format: (m) => {
                                                      if (!m.versionInfo) {
                                                          return ``;
                                                      }

                                                      const editedOn = new Date(m.versionInfo?.editedOnUtc);
                                                      return (
                                                          m.hasOwnProperty("versionInfo") &&
                                                          `${createDateFormatter("MMM d, yyyy @ h:mm tt").format(editedOn)}`
                                                      );
                                                  },
                                              },
                                              {
                                                  format: (m) => (
                                                      <div className="cm">
                                                          {!m.hasOwnProperty("versionInfo")
                                                              ? folderCM.render({params: m})
                                                              : modelCM.render({
                                                                    params: {
                                                                        model: m,
                                                                    },
                                                                })}
                                                      </div>
                                                  ),
                                                  className: "models-cm-35v",
                                                  shy: true,
                                                  alignRight: true,
                                              },
                                          ],
                                      })}
                            </div>
                        );
                    }
                ),
            });
        }
    );
