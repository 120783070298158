import "./table-label.scss";

import React from "react";
import {cx} from "emotion";

import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";

import {maxNumberDisplayedCharacters, EllipsisText} from "@common/ui-components/text/text";

import {tooltipService3} from "../../../../../common/tooltip3/tooltip-service-3";
import {UsageStatusIndicator} from "../usage-status-indicator";
import {getDSColorBasedOnIndex} from "../data-source-colors";
import {TruncatingTooltip} from "../../../../../common/truncating-tooltip/truncating-tooltip";
import {UnpublishedIcon} from "../../../../../common/icons/unpublished-icon";

export const TableLabel = ({
    name,
    highlights = [],
    usageStatus,
    dsColorIndex,
    className,
    customColor,
    table,
    hasCalculatedColumns,
    hasCalculatedColumnNotPublished,
    publishInfo,
    hasAggregatedMeasures,
}) => {
    const isView = table?.$type === "ViewModelTable";
    name = name || table.visualName;

    const color = customColor ? customColor : getDSColorBasedOnIndex(dsColorIndex);

    return cs(
        consumeContext("interactions"),
        tooltipService3({direction: "below"}),
        [
            "truncatingTooltip",
            (_, next) => {
                if (name.length <= maxNumberDisplayedCharacters) {
                    return next();
                }

                return TruncatingTooltip({
                    direction: isView ? "above" : "below",
                    next,
                });
            },
        ],
        ({tooltip, truncatingTooltip, interactions}) => (
            <div
                {...{
                    className: cx("table-label-wh6 table-label", className, {
                        "data-view": isView,
                        "never-been-published": isView && !publishInfo?.isActive,
                        "latest-not-published": isView && publishInfo?.isActive && publishInfo?.latestNotPublished(),
                    }),
                    // ...(publishInfo?.getTooltipContent && tooltip(publishInfo.getTooltipContent)),
                }}
            >
                {usageStatus && <UsageStatusIndicator status={usageStatus} color={color} />}

                <img
                    className="table-icon"
                    src={
                        isView
                            ? require("../../../../../common/icons/data-view-icon.svg")
                            : require("../../../../../common/icons/table-icon.svg")
                    }
                    alt=""
                />

                {interactions && table?.rowLevelSecurity?.Endpoint && (
                    <div
                        className="row-security"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            interactions.openRowLevelSecurity({
                                tableId: table.id,
                                dataSourceID: table.dataSourceID,
                                dataSourceTableID: table.dataSourceTableID,
                            });
                        }}
                    >
                        <img
                            {...{
                                ...tooltip("Row-Level Security Configured"),
                            }}
                            src={require("../column-line/column-line-actions/row-level-security-icon.svg")}
                            alt=""
                        />
                    </div>
                )}

                <div
                    {...{
                        className: "name",
                        ref: truncatingTooltip?.ref,
                        ...truncatingTooltip?.tooltip(name),
                    }}
                >
                    <span
                        {...{
                            ...(isView && publishInfo?.isActive && publishInfo?.latestNotPublished()
                                ? tooltip(
                                      <>
                                          Data view is out of date.
                                          <br />
                                          Latest version is not published.
                                      </>
                                  )
                                : {}),
                        }}
                    >
                        <EllipsisText text={name} highlights={highlights} highlightColor={color} />
                    </span>
                    {hasCalculatedColumns && (
                        <img className="calculated-column" src={require("../../common/icons/calculated-plus-icon.svg")} alt="" />
                    )}
                    {hasAggregatedMeasures && (
                        <img className="aggregated-measure-icon" src={require("../../common/icons/aggregated-measure-icon.svg")} alt="" />
                    )}

                    {publishInfo?.renderIndicator()}

                    {!isView && hasCalculatedColumnNotPublished && (
                        <span
                            {...{
                                className: "not-published-icon",
                                ...tooltip(
                                    "Calculated column not included in the model. Edit the column and publish it to include it in the model."
                                ),
                            }}
                        >
                            {UnpublishedIcon({fillColor: "#546B81"})}
                        </span>
                    )}
                </div>
            </div>
        )
    );
};
