import {UseState} from "@common/react/use-state";
import {cs} from "@common/react/chain-services";
import "./conditional-formatting-config.scss";
import {Button} from "@common/form/buttons/button/button";
import {LeftPanelClosable} from "../../../../../../../../../../common/left-panel-closable/left-panel-closable";
import {ManageConditionalRule} from "./manage-conditional-rule";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {LpColorLine} from "../../../../../../../../../../common/left-panel/lp-color-line";
import React from "react";
import {ArrowRightIcon, DragIcon, StopIfTrueIcon} from "@common/ui-components/charts/common/icons/icons";
import {consumeContext} from "@common/react/context";
import {generateRuleTitle} from "./manage-conditional-rule-common";
import {StaticTooltipService} from "../../../../../../../../../../common/tooltip3/static-tooltip-service";
import {Watch} from "@common/react/watch";

export const ConditionalFormattingConfig = ({next: rootNext, tile, form, leftPanelWidth}) =>
    cs(
        consumeContext("theme"),
        ["selecting", (_, next) => UseState({next})],
        [
            "localState",
            (_, next) =>
                UseState({
                    next,
                    initValue: (tile.value?.style?.conditionalFormattingRules || []).map((rule) => ({
                        value: rule,
                        id: Date.now() + Math.random(),
                    })),
                }),
        ],
        [
            "staticTooltip",
            (_, next) =>
                StaticTooltipService({
                    direction: "right",
                    info: "Stop if True applied. Subsequent rule styles will not be applied where this rule is applied",
                    tooltipBoxWidth: 200,
                    next,
                }),
        ],
        ({selecting}, next) =>
            Watch({
                value: leftPanelWidth.value,
                onChanged: (v) => {
                    if (v === null) {
                        selecting.onChange(null);
                    }
                },
                next,
            }),
        ({selecting, localState, theme, staticTooltip}) => {
            const close = () => {
                selecting.onChange(null);
                leftPanelWidth.onChange(null);
            };

            const reorder = (list, startIndex, endIndex) => {
                const result = Array.from(list);
                const [removed] = result.splice(startIndex, 1);
                result.splice(endIndex, 0, removed);

                return result;
            };

            const onDragEnd = (result) => {
                if (!result.destination) {
                    return;
                }

                const items = reorder(localState.value, result.source.index, result.destination.index);

                localState.onChange(items);
                tile.onChange({
                    ...tile.value,
                    style: {
                        ...tile.value?.style,
                        conditionalFormattingRules: items,
                    },
                });
            };

            const {fontColorsRGB, singleColorsRGB, colorScaleSchemes, cellBackgroundColorRGB, cellFontColorRGB} = theme?.dataVisualization.tables || {};

            return rootNext({
                render: () => {
                    return (
                        theme && (
                            <div className="conditional-formatting-config-a33">
                                <div className="conditional-rules-wrapper">
                                    <DragDropContext onDragEnd={onDragEnd}>
                                        <Droppable droppableId="droppable">
                                            {(provided) => (
                                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                                    {localState.value.map((item, index) => {
                                                        const rule = item.value;

                                                        return (
                                                            <Draggable key={item.id.toString()} draggableId={item.id.toString()} index={index}>
                                                                {(provided) => (
                                                                    <div
                                                                        className="rule-item"
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        onClick={() => {
                                                                            selecting.onChange({
                                                                                ...rule,
                                                                                id: item.id,
                                                                            });
                                                                            leftPanelWidth.onChange(864);
                                                                        }}
                                                                    >
                                                                        <div className="drag-icon" {...provided.dragHandleProps}>
                                                                            <DragIcon />
                                                                        </div>

                                                                        <div className="rule-box-card">
                                                                            <div className="rule-content">
                                                                                <div className="rule-title">
                                                                                    {index + 1}.{" "}
                                                                                    {generateRuleTitle(rule, tile.value?.actualValueFields.concat(tile.value.aggregatedValues))}
                                                                                </div>

                                                                                <div className="rule-display">
                                                                                    <div
                                                                                        className="rule-color"
                                                                                        style={{
                                                                                            background:
                                                                                                rule.colorType == "SingleColor"
                                                                                                    ? rule.backgroundColor
                                                                                                        ? singleColorsRGB[rule.backgroundColor.index]
                                                                                                        : cellBackgroundColorRGB
                                                                                                    : rule.backgroundColorScale
                                                                                                    ? `linear-gradient(to right, ${colorScaleSchemes[
                                                                                                          rule.backgroundColorScale?.index
                                                                                                      ].colorsRGB.join(",")})`
                                                                                                    : cellBackgroundColorRGB,
                                                                                            color: rule.fontColor ? fontColorsRGB[rule.fontColor.index] : cellFontColorRGB,
                                                                                        }}
                                                                                    >
                                                                                        Text Color
                                                                                    </div>

                                                                                    {rule.stopIfTrue &&
                                                                                        staticTooltip.renderIcon({
                                                                                            icon: <StopIfTrueIcon fill="#000000" />,
                                                                                            className: "info-icon",
                                                                                        })}
                                                                                </div>
                                                                            </div>

                                                                            <div className="arrow-btn">
                                                                                <ArrowRightIcon />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        );
                                                    })}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </div>

                                <div className="action-button">
                                    <Button
                                        btnType="secondary"
                                        size="small"
                                        onClick={() => {
                                            leftPanelWidth.onChange(864);
                                            selecting.onChange({
                                                colorType: "SingleColor",
                                                conditions: [{fieldConditions: [{}]}],
                                                highlightPlacement: null,
                                                fontColor: null,
                                                backgroundColor: null,
                                                backgroundColorScale: {
                                                    index: 0,
                                                },
                                            });
                                        }}
                                    >
                                        Add Rule
                                    </Button>
                                </div>
                            </div>
                        )
                    );
                },
                override:
                    selecting.value &&
                    LeftPanelClosable({
                        label: !selecting.value?.id ? "Create Rule" : "Edit Rule",
                        hideCloseXButton: true,
                        content: ManageConditionalRule({
                            rule: selecting.value,
                            tile,
                            onSave: (rule) => {
                                close();
                                const updated = localState.value.map((r) => (r.id == rule.id ? {...r, value: rule} : r));
                                localState.onChange(updated);
                                tile.onChange({
                                    ...tile.value,
                                    style: {
                                        ...tile.value?.style,
                                        conditionalFormattingRules: updated.map((u) => u.value),
                                    },
                                });
                            },
                            onDelete: (rule) => {
                                close();
                                const updated = localState.value.filter((r) => r.id != rule.id);

                                localState.onChange(updated);
                                tile.onChange({
                                    ...tile.value,
                                    style: {
                                        ...tile.value?.style,
                                        conditionalFormattingRules: updated.map((u) => u.value),
                                    },
                                });
                            },
                            onAdd: (rule) => {
                                close();
                                tile.onChange({
                                    ...tile.value,
                                    style: {
                                        ...tile.value?.style,
                                        conditionalFormattingRules: (tile.value?.style.conditionalFormattingRules || []).concat([rule]),
                                    },
                                });
                                localState.onChange(
                                    localState.value.concat([
                                        {
                                            id: Date.now() + Math.random(),
                                            value: rule,
                                        },
                                    ])
                                );
                            },
                            onClose: close,
                        }),
                    }),
            });
        }
    );
