import * as React from "react";
import {cs} from "@common/react/chain-services";
import {ShowHideToggle} from "../../../../../../../../../../../../../common/form/toggles/show-hide-toggle";
import "./x-axis.scss";
import {LpLine} from "../../../../../../../../../../common/left-panel/line/lp-line";
import {scope} from "@common/react/scope";
import {GridStepTypeSelect} from "../../../common/grid-step-type-select";
import {OrientationSelect} from "../../../common/orientation-select";
import {AxisLocationSelect} from "../../../common/axis-location-select";
import {AxisTitle, XAxisLabel, XGridStepLabel} from "../../../combo-chart/panels/x-axis/x-axis-rules-comp";
import {AxisRangeSelect} from "../../../common/axis-range-select";
import {getPath} from "@common/utils/arr-path";
import {AxisScaleSelect} from "../../../common/axis-scale-select";

export const XAxis = ({tile, size}) => ({
    label: "X-Axis",
    render: () => (
        <div className="scatter-plot-x-axis-98f">
            <div className="subsection">
                {XAxisLabel({
                    tile,
                    size,
                    state: scope(tile, ["style", "xAxis", "labelShown"]),
                })}

                {getPath(tile.value, ["style", "xAxis", "labelShown"]) && (
                    <div className="content">
                        {AxisTitle({
                            tile,
                            size,
                            state: scope(tile, ["style", "xAxis", "label"]),
                        })}
                    </div>
                )}
            </div>
            <div className="subsection">
                <div className="header">Range</div>
                <div className="content">{AxisRangeSelect(scope(tile, ["style", "xAxis"]))}</div>
            </div>
            <div className="subsection grid-step">
                <div className="header">Grid Step</div>
                <div className="content">
                    {GridStepTypeSelect({
                        state: scope(tile, ["style", "xAxis"]),
                        // fieldType: "number",
                    })}
                </div>
            </div>
            <div className="subsection">
                {XGridStepLabel({
                    size,
                    state: scope(tile, ["style", "xAxis", "gridStepLabelsShown"]),
                })}

                {getPath(tile.value, ["style", "xAxis", "gridStepLabelsShown"]) && (
                    <div className="content">{OrientationSelect(scope(tile, ["style", "xAxis", "gridStepLabelOrientation"]))}</div>
                )}
            </div>

            <div className="subsection">
                <div className="header">Other Options</div>
                <div className="content">
                    {AxisLocationSelect(scope(tile, ["style", "xAxis", "location"]), true)}

                    {LpLine({
                        label: "Axis Line",
                        control: ShowHideToggle({
                            state: scope(tile, ["style", "xAxis", "lineShown"]),
                        }),
                    })}

                    {LpLine({
                        label: "Grid Lines",
                        control: ShowHideToggle({
                            state: scope(tile, ["style", "xAxis", "gridLinesShown"]),
                        }),
                    })}

                    {AxisScaleSelect(scope(tile, ["style", "xAxis", "logarithmic"]))}
                </div>
            </div>
        </div>
    ),
});
