import React from "react";
import {cs} from "@common/react/chain-services";
import {DropdownSelect2} from "@common/ui-components/dropdown-select/dropdown-select2";
import {ffToDropdown} from "@common/form/ff-to-dropdown";
import "./static-value-configuration.scss";
import {DebounceTextInput} from "@common/form/debounce-input/debounce-text-input";
import {ffToBasicInput} from "@common/form/ff-to-basic-input";
import {rNewColumnNameInput} from "../../common/new-column-name-input";
export const StaticValueConfiguration = ({transformation, step, form, model}) =>
    cs((_, next) => {
        return (
            <div className="static-value-configuration-86y">
                <div className="fields">
                    {DropdownSelect2({
                        label: "Data Type",
                        list: [
                            {label: "Text", value: "Text"},
                            {label: "Integer", value: "Int"},
                            {label: "Decimal", value: "Double"},
                            {label: "DateTime", value: "DateTime"},
                            {label: "DateTime (Offset)", value: "DateTimeOffset"},
                            {label: "Boolean", value: "Bool"},
                        ],
                        valueToLabel: (v) => v.label,
                        ...ffToDropdown(form.field(["dataType"]), ["value"]),
                    })}

                    {DebounceTextInput({
                        label: "Value",
                        ...ffToBasicInput(form.field(["staticValue"])),
                    })}
                </div>

                <div className="config-group">
                    <div className="label">New column name</div>
                    {rNewColumnNameInput({
                        form,
                        newColumnPath: ["newColumn"],
                        newColumnNamePath: ["newColumn", "name"],
                    })}
                </div>
            </div>
        );
    });
