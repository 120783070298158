import {cs} from "@common/react/chain-services";
import "./sync-status-icon.scss";
import SyncOnce from "assets/icons/data-sources/SyncOnce";
import {tooltipService3} from "../../../../../common/tooltip3/tooltip-service-3";

export const SYNC_STATUSES = {
    ERROR: "error",
    SYNCING: "syncing",
    CURATING: "curating",
    ERROR_TABLE: "error-table",
    PAUSED: "paused",
    SYNC_ONCE: "syncOnce",
    SYNC: "sync",
    WILL_SYNC: "will-sync",
};

export const SyncStatusIcon = ({type = "error", tooltipContent = null}) =>
    cs(tooltipService3({direction: "above"}), ({tooltip}) => {
        const status = {
            error: <span className="material-icons-outlined error">sync_problem</span>,
            syncing: <span className="material-icons-outlined syncing">sync</span>,
            ingesting: <span className="material-icons-outlined curating">sync</span>,
            "error-table": <div {...(tooltipContent ? tooltip(() => tooltipContent) : {})} className="error-table" />,
            paused: <div className="paused" />,
            syncOnce: <SyncOnce />,
            sync: <div className="sync" />,
            "will-sync": <div className="will-sync" />,
        };

        const comp = status[type];

        if (!comp) return null;
        return React.cloneElement(comp, {
            className: `${comp?.props?.className} sync-status-icon-88o`,
        });
    });
