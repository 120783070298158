import * as React from "react";
import "./rows.scss";
import {scope} from "@common/react/scope";
import {RowHeightSelect} from "./row-height-select";
import {FontSizeSelect} from "../../../common/font-size-select";
import {CheckboxLine} from "../../../../../../../../../../common/checkbox-line/checkbox-line";
import {LpShowHideLine} from "../../../../../../../../../../common/left-panel/lp-show-hide-line";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {spc} from "@common/react/state-path-change";
import {stateToSelect} from "@common/ui-components/form/state-to-select";
import {StaticTooltipService} from "../../../../../../../../../../common/tooltip3/static-tooltip-service";
import {cs} from "@common/react/chain-services";

export const Rows = ({tile}) =>
    cs(
        [
            "staticTooltip",
            (_, next) =>
                StaticTooltipService({
                    direction: "top",
                    info: "Grand Total options will not be fixed on small screen sizes to prevent readability issues.",
                    tooltipBoxWidth: 200,
                    topOffset: 20,
                    leftOffset: -5,
                    next,
                }),
        ],
        ({staticTooltip}) => (
            <div className="table-rows-2va">
                {RowHeightSelect(scope(tile, ["style", "rowHeight"]), "no-margin")}

                {tile.value.$type != "PivotTableTile" &&
                    CheckboxLine({
                        label: "Allow Word Wrap",
                        state: scope(tile, ["style", "allowWordWrap"]),
                        className: "has-margin-bottom",
                    })}

                {tile.value.$type == "PivotTableTile" && (
                    <>
                        {DropdownSelect({
                            list: [
                                {label: "Rows Expanded", value: true},
                                {label: "Rows Collapsed", value: false},
                            ],
                            label: "Default View",
                            valueToLabel: (v) => v.label,
                            isSelected: (v) => v.value == tile.value.style.rowsExpanded,
                            onChange: (v) =>
                                spc(tile, ["style"], (style) => ({
                                    ...style,
                                    rowsExpanded: v.value,
                                })),
                        })}

                        {LpShowHideLine({
                            state: scope(tile, ["style", "grandTotalsRowShow"]),
                            label: (
                                <span>
                                    Grand Totals{" "}
                                    {tile.value.style.grandTotalsRowShow &&
                                        staticTooltip.renderIcon({
                                            icon: <i className="fa fa-mobile-alt" style={{color: "#FF7A59"}} />,
                                            className: "info-tooltip",
                                        })}
                                </span>
                            ),
                        })}

                        {tile.value.style.grandTotalsRowShow && (
                            <>
                                {DropdownSelect({
                                    list: ["Inline", "Fixed"],
                                    label: "Grand Totals Position",
                                    valueToLabel: (v) => v,
                                    ...stateToSelect(scope(tile, ["style", "grandTotalRowPosition"])),
                                })}
                            </>
                        )}
                    </>
                )}
            </div>
        )
    );
