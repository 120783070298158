import {getMapLegendPosition} from "../../../../web-client/src/routes/collection/tile/edit/left-panel/tabs/tile-tab/chart-types/map-tile/panels/legend/map-legend-display-rules";

export const mapTileRestyling = ({tile, size}) => {
    let legendStyle = tile.style.legendStyle;
    if (tile.style.legendStyle) {
        const legendPosition = getMapLegendPosition(tile.style.legendStyle.legendPosition, size, tile.$type);
        legendStyle = {
            ...tile.style.legendStyle,
            legendPosition,
            legendShown: !legendPosition ? false : tile.style.legendStyle.legendShown,
        };
    }

    return {
        ...tile,
        style: {
            ...tile.style,
            legendStyle,
        },
    };
};
