import { Button } from "@common/form/buttons/button/button";
import { cs } from "@common/react/chain-services";
import { consumeContext } from "@common/react/context";
import { getELoc } from "@common/react/get-e-loc";
import { Invoke } from "@common/react/invoke";
import { keyed } from "@common/react/keyed";
import { Load2 } from "@common/react/load2";
import { UseState } from "@common/react/use-state";
import { cx } from "emotion";
import * as React from "react";
import { tGetEnvironment } from "../../../../../../verb-web/auth/load-auth-routes";
import { Expandable } from "../../../../../common/expandable/expandable";
import { StaticTooltipService } from "../../../../../common/tooltip3/static-tooltip-service";
import { tooltipService3 } from "../../../../../common/tooltip3/tooltip-service-3";
import { DragWatcher } from "../../../../common/drag-watcher";
import { ColumnLine } from "../../common/column-line/column-line";
import { dataViewColor } from "../../common/data-source-colors";
import { cGetSearchedTables } from "../../common/get-searched-tables";
import { TableLabel } from "../../common/table-label/table-label";
import { TransformationPublishInfo2 } from "../../common/transformation-publish-info2";
import { IgnoreUpdateModelPositions } from "./common";
import { MoreActionPopup } from "./more-action/more-action-popup";

export const DataViewsMenu = ({
    model,
    searchValue,
    chart,
    removeTableService,
    shortestPossiblePathDialog,
    tooltipText = "Data Views allow you to combine fields from many data sources and apply transformations that make your data easier to work with and display in charts.",
}) =>
    cs(
        consumeContext("apis"),
        consumeContext("routing"),
        consumeContext("interactions"),
        consumeContext("dataSourceModelContext"),
        ({dataSourceModelContext, interactions}, next) => {
            return IgnoreUpdateModelPositions({
                dataSourceModelContext,
                extraProps: {
                    interactionTimestamp: interactions.setting?.timestamp,
                },
                next,
            });
        },
        ["environment", (_, next) => tGetEnvironment({next})],
        consumeContext("dataViewTransforms"),
        tooltipService3({direction: "below"}),
        ["openPopupOption", (_, next) => UseState({next})],
        [
            "dataViewTransforms",
            ({apis}, next) =>
                Load2({
                    fetch: () => apis.transformation.getAllTransformations(model.value.id),
                    next,
                    _key: model.value.id,
                }),
        ],
        ["dataViews", (_, next) => next(model.value.tables.filter((t) => t.$type === "ViewModelTable"))],
        [
            "getTogglePosition",
            (_, next) =>
                next((toggleElem) => {
                    const parentElem = toggleElem.closest(".view-tables");
                    const {height, top: toggleTopOffset} = toggleElem.getBoundingClientRect();
                    const parentElemTopOffset = parentElem.getBoundingClientRect().top;
                    return toggleTopOffset - parentElemTopOffset + height;
                }),
        ],
        [
            "getDataViewSummaries",
            ({dataViewTransforms}, next) =>
                next((table) => {
                    let res = {
                        isValid: true,
                        isPublished: table.versionDetails != "NotPublished",
                    };

                    if (dataViewTransforms.value) {
                        const currentTransform = dataViewTransforms.value[table.transformationID];

                        if (currentTransform) {
                            for (let i = 0; i < currentTransform.steps.length; i++) {
                                const step = currentTransform.steps[i];
                                if (step.errors?.length > 0) {
                                    res.isValid = false;
                                    break;
                                }
                            }
                        }
                    }

                    return res;
                }),
        ],
        ({
            routing,
            getDataViewSummaries,
            environment,
            dataSourceModelContext,
            tooltip,
            openPopupOption,
            getTogglePosition,
            interactions,
        }) => {
            const {getEntities, entities} = dataSourceModelContext;
            const searchedTables = getEntities("modelTables", {
                filterFn: (modelTable) => modelTable.$type == "ViewModelTable",
            });

            const {$type, tableId} = interactions.setting?.data ?? {};

            return (
                <Expandable
                    {...{
                        label: (
                            <span className="data-view-label">
                                Data Views{" "}
                                <i {...tooltip(tooltipText)} className="material-icons">
                                    info
                                </i>
                            </span>
                        ),
                        updateState:
                            interactions.setting == null || !tableId || $type != "ViewModelTable"
                                ? null
                                : {value: $type == "ViewModelTable", timestamp: interactions.setting.timestamp},
                        render: () => (
                            <div className="view-tables">
                                {openPopupOption.value?.table &&
                                    MoreActionPopup({
                                        openPopupOption,
                                        dataSource: null,
                                        model,
                                        removeTableService,
                                        isDataView: true,
                                        shortestPossiblePathDialog,
                                        table: openPopupOption.value?.table,
                                        isActive: openPopupOption.value?.isActive,
                                        top: openPopupOption.value?.top,
                                        currentToggle: openPopupOption.value?.currentToggle,
                                    })}
                                {searchedTables.map((table, i) => {
                                    const isOpenPopupMenu = openPopupOption.value?.table?.id == table.id;
                                    const {isValid, isPublished} = getDataViewSummaries(table);
                                    const hasRowSecurity = table?.rowLevelSecurity?.Endpoint;

                                    const resolvedDataView = {
                                        ...table,
                                        columns: table.columns.map((columnID) => entities.columns[columnID]),
                                    };
                                    const searchedColumns = resolvedDataView.columns;
                                    const matchedColumns = searchedColumns.filter((c) => c.ranges);
                                    return cs(
                                        keyed(table.id),
                                        ["publishInfo", (_, next) => TransformationPublishInfo2({table, next})],
                                        ({publishInfo}) => {
                                            const menuButton = (
                                                <button
                                                    className={cx("action-btn more-option", hasRowSecurity && "has-row-security")}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        if (openPopupOption.value) {
                                                            openPopupOption.onChange(null);
                                                        } else {
                                                            const currentToggle = e.target.closest("button");
                                                            openPopupOption.onChange({
                                                                table: resolvedDataView,
                                                                top: getTogglePosition(currentToggle),
                                                                currentToggle,
                                                                isActive: publishInfo?.isActive,
                                                            });
                                                        }
                                                    }}
                                                >
                                                    <img src={require("../../common/icons/more-option.svg")} alt="" />
                                                </button>
                                            );

                                            return Expandable({
                                                initExpand: false,
                                                style: {zIndex: isOpenPopupMenu ? 12 : ""},
                                                updateState: {
                                                    value: table.id === tableId,
                                                    timestamp: interactions.setting?.timestamp,
                                                },
                                                level: 2,
                                                className: cx("data-view", {"active-popup": isOpenPopupMenu}),
                                                dataID: `${table.dataSourceTableID ?? table.id}_${table.dataSourceID}`,
                                                noTopBorder: true,
                                                passiveExpand: !!searchValue && matchedColumns.length > 1,
                                                label: cs(
                                                    [
                                                        "dragWatcher",
                                                        (_, next) =>
                                                            DragWatcher({
                                                                onDragged: (e) => {
                                                                    chart.startDraggingNewTable({
                                                                        table: resolvedDataView,
                                                                        color: dataViewColor,
                                                                        pos: getELoc(e),
                                                                    });
                                                                },
                                                                next,
                                                            }),
                                                    ],
                                                    ({dragWatcher}) => {
                                                        return (
                                                            <>
                                                                <div
                                                                    className="table-label-wrapper"
                                                                    onMouseDown={(e) => {
                                                                        if (table.excludeFromModel && isValid && isPublished) {
                                                                            dragWatcher.onMouseDown(e);
                                                                        }
                                                                    }}
                                                                >
                                                                    {TableLabel({
                                                                        usageStatus: table.excludeFromModel ? "none" : "all",
                                                                        customColor: dataViewColor,
                                                                        highlights: table.ranges,
                                                                        table: {
                                                                            ...resolvedDataView,
                                                                            isPublished,
                                                                            isValid,
                                                                        },
                                                                        model,
                                                                        publishInfo,
                                                                    })}
                                                                </div>
                                                            </>
                                                        );
                                                    }
                                                ),
                                                control: cs(() =>
                                                    !publishInfo?.isActive ? (
                                                        <>
                                                            <div className="never-published-btn">
                                                                <Button size="tiny" btnType="border" disabled>
                                                                    Add
                                                                </Button>
                                                            </div>

                                                            {menuButton}
                                                        </>
                                                    ) : table.excludeFromModel ? (
                                                        <>
                                                            <div className={cx("exclude-model", hasRowSecurity && "has-row-security")}>
                                                                <Button
                                                                    size="tiny"
                                                                    btnType="border"
                                                                    className="action-btn"
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        let newTables = model.value.tables.map((t) =>
                                                                            t.id == table.id ? {...t, excludeFromModel: false} : t
                                                                        );

                                                                        model.change(() => ({
                                                                            ...model.value,
                                                                            tables: newTables,
                                                                        }));
                                                                    }}
                                                                >
                                                                    Add
                                                                </Button>
                                                            </div>

                                                            {menuButton}
                                                        </>
                                                    ) : (
                                                        menuButton
                                                    )
                                                ),
                                                render: () => (
                                                    <DataViewColumns
                                                        model={model}
                                                        columns={!searchValue ? searchedColumns : matchedColumns}
                                                        resolvedDataView={resolvedDataView}
                                                    />
                                                ),
                                            });
                                        }
                                    );
                                })}

                                {!environment?.readOnly && (
                                    <div className="add-data-view-button">
                                        <Button
                                            btnType="border"
                                            size="small"
                                            onClick={() => {
                                                routing.goto("edit-data-view", {tab: "build", modelId: model.value.id, isNew: true});
                                            }}
                                        >
                                            Add Data View
                                        </Button>
                                    </div>
                                )}
                            </div>
                        ),
                    }}
                />
            );
        }
    );

const DataViewColumns = ({columns, resolvedDataView, model}) => {
    return cs(consumeContext("dataSourceModelContext"), ({dataSourceModelContext}) => {
        const {getRelationshipUsage} = dataSourceModelContext;

        return columns
            .sort((a, b) => (a.$type == b.$type ? (a.name > b.name ? 1 : -1) : a.$type == "AggregatedMeasureModelColumn" ? -1 : 1))
            .map((column) => (
                <div className="column-line-wrapper" key={column.id}>
                    {ColumnLine({
                        type: column.$type,
                        name: column.name,
                        dataType: column.dataType,
                        hasRelationship: getRelationshipUsage({columnId: column.id}),
                        column,
                        model,
                        table: resolvedDataView,
                        modelTable: resolvedDataView,
                        transformationId: column.transformationID,
                        versionDetails: column.versionDetails,
                        publishedOn: column.publishedOn,
                    })}
                </div>
            ));
    });
};
