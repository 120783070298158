import {getErrorsInTableDeleted, getTableErrors} from "../../../../layout/error-badge/error-badge-helper";
import * as React from "react";
import {Button} from "@common/form/buttons/button/button";
import {getTableCalculatedColumn, isTableHasRowLevelSecurity} from "../../../../main/model-utils";
import {AlertIcon} from "@common/ui-components/icons/global-icons";
import {CloseIcon} from "../../../../../../common/icons/close-icon";
import {groupBy} from "@common/utils/collections";
import {noun} from "@common/utils/plural";

export const DependenciesFound = ({collectionErrors, table, model, onRemove, dataViewTransforms, onDismiss}) => {
    let errorsInModel = getErrorsInTableDeleted({
        table: {...table, deleted: true},
        relationships: model.value.relationships,
        allTables: model.value.tables.concat(model.value.disabledTables).map((t) => (t.id == table.id ? {...t, deleted: true} : t)),
        dataViewTransforms: dataViewTransforms,
    });

    if (isTableHasRowLevelSecurity(table)) {
        errorsInModel.push({
            dependencyError: <span>Row level security on this table will be removed</span>,
            level: "warning",
        });
    }

    const errorsInCollection = groupBy(collectionErrors, (e) => e.collectionID);

    if (errorsInModel.length + errorsInCollection.length == 0) {
        return (
            <>
                <div className="header dialog-header">
                    No Dependencies Found
                    <div className="close" onClick={() => onDismiss()}>
                        <CloseIcon fill="#919EB0" />
                    </div>
                </div>

                <div className="main remove-table-dialog-kl8">
                    <div className="dialog-body">
                        <div className="dialog-description">Deleting this table will have no affect on this model or any collections.</div>
                    </div>

                    <div className="buttons">
                        <Button btnType="border" onClick={onDismiss}>
                            Cancel
                        </Button>

                        <Button btnType="primary" onClick={onRemove}>
                            Remove Table
                        </Button>
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            <div className="header dialog-header">
                {errorsInModel.length + errorsInCollection.length} Dependencies Found
                <div className="close" onClick={() => onDismiss()}>
                    <CloseIcon fill="#919EB0" />
                </div>
            </div>

            <div className="main remove-table-dialog-kl8">
                <div className="dialog-body">
                    {errorsInModel.length > 0 && (
                        <div className="heading-text">{errorsInModel.length} dependencies in the model will be removed or in error</div>
                    )}

                    {errorsInModel.map((error, index) => (
                        <div className="error-box" key={index}>
                            <AlertIcon fill={error.level == "warning" ? "#ECCD39" : undefined} />
                            {error.dependencyError}
                        </div>
                    ))}

                    <div className="section-box">
                        {errorsInCollection.length > 0 && (
                            <div className="heading-text">
                                {errorsInCollection.length} {noun(errorsInCollection.length, "collection")} have dependencies that will
                                cause errors
                            </div>
                        )}

                        {errorsInCollection.map((error, index) => (
                            <div className="error-box" key={index}>
                                <AlertIcon />
                                Collection - <b>{error[0].collectionName}</b> will have {error.length} {noun(error.length, "error")}
                            </div>
                        ))}
                    </div>
                </div>

                <div className="buttons">
                    <Button btnType="border" onClick={onDismiss}>
                        Cancel
                    </Button>

                    <Button btnType="primary" onClick={onRemove}>
                        Remove Table
                    </Button>
                </div>
            </div>
        </>
    );
};
