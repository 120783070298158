const {lineWidths} = require("../line-series-processors/group-series-by-stack-line");
const {camelCaseToSpaces} = require("../../../utils/strings");
const {getDataLabelsStyle} = require("../factory/data-labels");
const {findMinValue} = require("../../../utils/collections");
const {MAX_NUM_COLOR} = require("../get-field-color");
const {chain} = require("../../../utils/fs");

const regressionTypes = ["LinearRegression", "PolynomialRegression", "ExponentialRegression", "PowerRegression", "LogarithmicRegression"];
exports.regressionTypes = regressionTypes;

const movingAverageTypes = ["SimpleMovingAverage", "ExponentialMovingAverage", "WeightedMovingAverage", "AccelerationBand"];
exports.movingAverageTypes = movingAverageTypes;

const regressionCalculators = {
    LinearRegression: (data) => regression.linear(data),
    PolynomialRegression: (data, order) => regression.polynomial(data, {order}),
    ExponentialRegression: (data) => regression.exponential(data),
    LogarithmicRegression: (data) => regression.logarithmic(data),
    PowerRegression: (data) => regression.power(data),
};
exports.regressionCalculators = regressionCalculators;

const generateIndicatorSeries = ({tile, theme, formatters, series, config}) => {
    const getOptions = ({config, dataLabelFormatter, name}) => {
        const seriesStyle = getReferenceSeriesStyleOptions({config, theme});
        const dataLabelStyle = getDataLabelsStyle({
            dataLabelsConfig: {
                ...(tile.style.dataLabels || tile.style.yAxisLineDataLabels),
                show: config.dataLabelShown,
            },
            theme,
        });

        return {
            // showInLegend: true,
            marker: {
                enabled: false,
                states: {
                    hover: {
                        enabled: false,
                    },
                },
            },
            name: config.label || name,
            dataLabels: {
                ...dataLabelStyle,
                formatter: function () {
                    return dataLabelFormatter(this.y);
                },
            },

            ...(config.labelShown
                ? {
                      label: {
                          enabled: tile.$type !== "HorizontalBarChartTile", // indicator series label on horizontal bar chart is drawn manually
                          drawManually: tile.$type === "HorizontalBarChartTile",
                          style: {
                              fontSize: dataLabelStyle.style.fontSize,
                              fontWeight: dataLabelStyle.style.fontWeight,
                              fontFamily: dataLabelStyle.style.fontFamily,
                              color: seriesStyle.color,
                          },
                      },
                  }
                : {
                      label: {enabled: false},
                  }),

            states: {
                hover: {
                    lineWidthPlus: 0,
                },
            },

            ...seriesStyle,
        };
    };

    return series
        .map((s) => {
            const dataLabelFormatter = formatters.measurementFormatters[s.customStack][s.indexInCustomStack];

            if (regressionTypes.includes(config.indicatorType)) {
                const dateTransformer = cDimTransformerForRegression(s, config.indicatorType, tile.xAxisField?.dataType || tile.yAxisField?.dataType);
                const dataFilter = cDataFilter(config.indicatorType);

                const data = chain(
                    s.data,
                    (_) => _.map((dp, i) => [dateTransformer.toward(dp[0], i), dp[1]]),
                    (_) => (dataFilter ? _.filter(dataFilter) : _)
                );

                const regressionResult = regressionCalculators[config.indicatorType](data, config.order || 2);

                return {
                    type: "spline",
                    id: `${config.id}_${s.customStack}_${s.indexInCustomStack}_${s.name}`,
                    customType: config.$type,
                    customStack: s.customStack,
                    indexInCustomStack: s.indexInCustomStack,
                    measureAxisTitle: s.measureAxisTitle,
                    data: regressionResult.points.map((rp) => [dateTransformer.backward(rp[0]), rp[1]]),
                    ...getOptions({
                        config,
                        dataLabelFormatter,
                        name: `${camelCaseToSpaces(config.indicatorType)} (${s.name})`,
                    }),
                };
            }

            if (movingAverageTypes.includes(config.indicatorType) && config.period) {
                const typeAbbr = {
                    SimpleMovingAverage: "SMA",
                    ExponentialMovingAverage: "EMA",
                    WeightedMovingAverage: "WMA",
                    AccelerationBand: "ABands",
                }[config.indicatorType];

                return {
                    type: typeAbbr.toLowerCase(),
                    id: `${config.id}_${s.customStack}_${s.indexInCustomStack}_${s.name}`,
                    customType: config.$type,
                    customStack: s.customStack,
                    indexInCustomStack: s.indexInCustomStack,
                    measureAxisTitle: s.measureAxisTitle,
                    linkedTo: s.id,
                    ...getOptions({
                        config,
                        dataLabelFormatter,
                        name: `${typeAbbr} (${config.period}, ${s.name})`,
                    }),
                    params: {
                        period: config.period,
                        factor: config.factor,
                    },
                };
            }
        })
        .filter((v) => v);
};
exports.generateIndicatorSeries = generateIndicatorSeries;

const getReferenceSeriesStyleOptions = ({config, theme}) => {
    const width = lineWidths[config.lineWidth.toLowerCase()].primary;
    return {
        dashStyle: config.lineStyle.toLowerCase(),
        color: theme.dataVisualization.dataColorPalettes.discreteColorsRGB[config.colorApplication?.index ?? MAX_NUM_COLOR.DiscreteColorApplication - 1],
        lineWidth: width,
        width,
    };
};
exports.getReferenceSeriesStyleOptions = getReferenceSeriesStyleOptions;

const cDimTransformerForRegression = (series, indicatorType, dataType) => {
    if (typeof series.data[0][0] === "string" || ["PolynomialRegression", "ExponentialRegression", "PowerRegression"].includes(indicatorType)) {
        return {
            toward: (timestamp, index) => index + 1,
            backward: (transformed) => series.data[transformed - 1][0],
        };
    }

    if (["Int", "Double"].includes(dataType)) {
        return {
            toward: (v) => v,
            backward: (v) => v,
        };
    }

    const xAxisUnit = findMinValue(series.data.map((dp, i) => i > 0 && dp[0] - series.data[i - 1][0]).filter((v) => v));

    return {
        toward: (timestamp) => timestamp / xAxisUnit,
        backward: (transformed) => transformed * xAxisUnit,
    };
};

// exponential and power regression won't fit a dataset having zero-value y-value,
// hence filter those points out.
const cDataFilter = (indicatorType) => {
    const nonZero = (point) => point[1] !== 0;

    const maps = {
        ExponentialRegression: nonZero,
        PowerRegression: nonZero,
    };

    return maps[indicatorType];
};
exports.cDataFilter = cDataFilter;
