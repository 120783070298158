import React from "react";
import {cs} from "@common/react/chain-services";
import "./suggest-relationship.scss";
import {LeftPanelClosable} from "../../../../../common/left-panel-closable/left-panel-closable";
import {ArrowLeftIcon} from "../../../../../../assets/icons/ArrowLeftIcon";
import {SuggestRelationshipLine} from "./suggest-relationship-line";

export const SuggestRelationshipLeftPanel = ({
    onCancel,
    model,
    dataSources,
    suggestedRelationships,
    tables,
    allowBack,
    deSelectTable,
    viewSuggestRelationship: onUpdate,
}) =>
    cs(
        ({}, next) => (
            <LeftPanelClosable
                {...{
                    onClose: () => deSelectTable(),
                    label: (
                        <div className="suggest-relationship-fss4 ">
                            {allowBack && (
                                <div
                                    className="back-arrow"
                                    onClick={() =>
                                        onCancel({
                                            toName: "create-new-relationship",
                                        })
                                    }
                                >
                                    {ArrowLeftIcon({fillColor: "#294661"})}
                                </div>
                            )}
                            SUGGESTED RELATIONSHIPS
                        </div>
                    ),
                    content: next(),
                }}
            />
        ),
        () => {
            return (
                <div className="suggest-relationship-content">
                    <div className="description">
                        Click each suggested relationship to relate the tables and select the join type and direction
                    </div>

                    <div className="suggest-relationship">
                        {suggestedRelationships.map((suggest, index) => (
                            <SuggestRelationshipLine
                                suggestRelationship={suggest}
                                key={index}
                                tables={tables}
                                model={model}
                                dataSources={dataSources}
                                onUpdate={(updatedRelationship) => {
                                    onUpdate({
                                        suggestedRelationships: suggestedRelationships.map((s, i) => {
                                            if (i == index) {
                                                return {
                                                    ...s,
                                                    ...updatedRelationship,
                                                };
                                            }

                                            return s;
                                        }),
                                        tables,
                                    });
                                }}
                            />
                        ))}
                    </div>
                </div>
            );
        }
    );

export const ArrowIcon = ({fill = "#105ca9"}) => (
    <svg width="30.5" height="11" viewBox="0 0 30.5 11">
        <g data-name="Polygon 1" transform="translate(30.5) rotate(90)" fill={fill}>
            <path d="M 10.29941177368164 15.5 L 0.7005881071090698 15.5 L 5.5 1.538074493408203 L 10.29941177368164 15.5 Z" stroke="none" />
            <path
                d="M 5.5 3.076178550720215 L 1.401185989379883 15 L 9.598814010620117 15 L 5.5 3.076178550720215 M 5.5 0 L 11 16 L 0 16 L 5.5 0 Z"
                stroke="none"
                fill={fill}
            />
        </g>
        <line data-name="Line 307" x1="15" transform="translate(0 5.5)" fill="none" stroke={fill} strokeWidth="2" />
    </svg>
);
