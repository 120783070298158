import React from "react";

export const ArrowLeftIcon = ({fillColor} = {}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16.333" height="14" viewBox="0 0 16.333 14">
        <path
            id="Icon_metro-arrow-left"
            data-name="Icon metro-arrow-left"
            d="M10.674,19.442,4.84,13.609a1.167,1.167,0,0,1,0-1.65l5.833-5.833a1.167,1.167,0,0,1,1.65,1.65L8.482,11.617H19.665a1.167,1.167,0,0,1,0,2.333H8.482l3.842,3.842a1.167,1.167,0,1,1-1.65,1.65Z"
            transform="translate(-4.499 -5.784)"
            fill={fillColor || "#fff"}
        />
    </svg>
);
