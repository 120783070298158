import React from "react";
import {cs} from "@common/react/chain-services";
import {StaticTooltipService} from "../../../../common/tooltip3/static-tooltip-service";
import {cx} from "emotion";
import {InfoIconFull} from "@common/ui-components/charts/common/icons/icons";

const tooltipInfos = {
    null: {
        label: "Authentication Setup",
        content:
            "The Auth Endpoint is configured as a callback to your API. After a user is authenticated by your application and the Verb javascript is rendered and token information is supplied, this is the endpoint Verb will call to verify the user's legitimate and retrieve any user-specific data used for data filtering.",
    },
    collection_types: {
        label: "Collection Types",
        content: (
            <span>
                The Embedding Type enables specific authentication requirements based on how collections will be shown to your users.
                <p>It's easy to update if you decide to switch from one option to another.</p>
            </span>
        ),
    },
    method: {
        label: "Endpoint & Method",
        content: (
            <>
                The method and the URL Verb will call on every authentication attempt.
                <p>
                    Wrap any request parameters you expect to be replaced in curly braces, like this: <code>{`{paramName}`}</code>
                    <p>
                        Example:
                        <br />
                        <code>
                            https://{`{sdomain}`}.myapp.com/ auth?uid=
                            {`{userID}`}
                        </code>
                    </p>
                </p>
            </>
        ),
    },
    headers: {
        label: "Headers",
        content: (
            <>
                Any headers required by the auth endpoint
                <p>
                    Wrap any request parameters you expect to be replaced in curly braces, like this: <code>{`{paramName}`}</code>
                    <p>
                        Example:
                        <br />
                        <code>Bearer {`{bearerToken}`}</code>
                    </p>
                </p>
            </>
        ),
    },
    "body-type": {
        label: "Body Type",
        content: (
            <>
                The body to be sent with the request (for POST, PUT, etc HTTP methods). This can be formatted as raw JSON/XML or as Form Data (optionally URL encoded)."
                <p>
                    Wrap any request parameters you expect to be replaced in curly braces, like this: <code>{`{paramName}`}</code>
                    <p>
                        Example:
                        <br />
                        <pre>
                            {`{`}
                            <br />
                            &nbsp; userID: {`{userID}`}, <br />
                            &nbsp; otherVal: {`{otherVal}`}
                            <br />
                            {`}`}
                        </pre>
                    </p>
                </p>
            </>
        ),
    },
    "request-params": {
        label: "Request Parameters",
        content: "The parameters that will be replaced in the request that's made. These will be supplied in the client-side code your team embeds into your platform.",
    },
    "response-mapping": {
        label: "Response Mapping",
        content: "The properties from the response that will be processed by Verb and available for segmentation (row & column level security).",
    },
    "static-value": {
        content: "The Static Value will be applied to security rules set on the model. You can use this to demonstration segmentation that can be used in the Active Auth method.",
    },
};

export const AuthStaticTooltip = ({name}) =>
    cs(
        ["getInfo", (_, next) => next(() => tooltipInfos[name].content)],
        [
            "staticTooltip",
            ({getInfo}, next) =>
                StaticTooltipService({
                    direction: "top",
                    tooltipBoxWidth: 300,
                    info: getInfo(),
                    next,
                }),
        ],
        ({staticTooltip}) => {
            return staticTooltip.renderIcon({
                icon: InfoIconFull({width: "15px"}),
            });
        }
    );
