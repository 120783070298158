const {Component} = require("react");

class TimedRefresh extends Component {
    constructor(props, context) {
        super(props, context);

        this.setupTimeout();
    }

    setupTimeout() {
        this.clear && this.clear();
        const delay = this.props.delay || this.props.getRefreshTime() - Date.now();

        // console.log(`Refresh: delay ${delay / (60*60*1000)}`);
        const timeout = setTimeout(() => {
            this.clear = null;

            this.forceUpdate();

            this.setupTimeout();
        }, delay);
        this.clear = () => clearTimeout(timeout);
    }

    componentWillUnmount() {
        this.clear && this.clear();
    }
    componentDidUpdate(prevProps) {
        if ((this.props.getRefreshTime && prevProps.getRefreshTime() !== this.props.getRefreshTime()) || (this.props.delay && prevProps.delay !== this.props.delay)) {
            this.setupTimeout();
        }
    }
    render() {
        const {render, next} = this.props;
        return next ? next() : render();
    }
}
exports.TimedRefresh = TimedRefresh;
