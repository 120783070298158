import React from "react";
import {mix} from "@common/utils/color-util";

export const CircularProgressBar = ({size, progressColor, backgroundColor, holeColor, progress}) => {
    return (
        <div className="circular-progress-bar">
            <svg width={size} height={size}>
                <g transform={`translate(${size * 0.5} ${size * 0.5})`}>
                    {rCircularProgressBar({
                        size,
                        progressColor,
                        backgroundColor,
                        holeColor,
                        progress,
                    })}
                </g>
            </svg>
        </div>
    );
};

const rCircularProgressBar = ({size, progressColor, backgroundColor, holeColor, progress}) => {
    const endAngle = progressToAngle(progress);
    const radius = size * 0.5;
    const innerRadius = radius * 0.5;

    return (
        <g>
            <circle fill={backgroundColor || mix(holeColor, progressColor, 0.5)} cx={0} cy={0} r={radius} />
            {progress && <path fill={progressColor} d={describeArc(0, 0, radius, 0, endAngle)} />}
            <circle fill={holeColor} cx={0} cy={0} r={innerRadius} />
            {/*<text*/}
            {/*    x={0} y={size * 2.9 / 30}*/}
            {/*    textAnchor="middle"*/}
            {/*    fill="black"*/}
            {/*    style={{fontSize: size * 10 / 30, letterSpacing: 0}}*/}
            {/*>*/}
            {/*    {info}*/}
            {/*</text>*/}
        </g>
    );
};

const polarToCartesian = (cx, cy, radius, angleInDegrees) => {
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180; //change 0 rad position to 12 o'clock direction

    return {
        x: cx + radius * Math.cos(angleInRadians),
        y: cy + radius * Math.sin(angleInRadians),
    };
};

const describeArc = (cx, cy, radius, startAngle, endAngle) => {
    const startPoint = polarToCartesian(cx, cy, radius, startAngle);
    const endPoint = polarToCartesian(cx, cy, radius, endAngle);

    const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";
    const sweepFlag = endAngle <= startAngle ? "0" : "1";

    const d = ["M", cx, cy, "L", startPoint.x, startPoint.y, "A", radius, radius, 0, largeArcFlag, sweepFlag, endPoint.x, endPoint.y, "Z"].join(" ");

    return d;
};

const progressToAngle = (progress) => progress * 360;
