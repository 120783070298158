import "./select-field-base.scss";

import {cx} from "emotion";
import * as React from "react";

import {UseState} from "@common/react/use-state";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {SearchInput} from "../../../../../../common/form/search-input/search-input";
import {Expandable} from "../../../common/expandable/expandable";
import {keyed} from "@common/react/keyed";
import {rFieldTypeIcon} from "../../../common/field-type-icon/get-field-type-icon";
import {chain} from "@common/utils/fs";
import {getFieldType} from "@common/ui-components/charts/common/get-field-type";
import {isMatchText} from "@common/utils/strings";
import {TruncatingTooltip} from "../../../common/truncating-tooltip/truncating-tooltip";

export const SelectFieldBase = ({model, onSelect, allowedFieldType, allowAggregatedMeasure = true, notAllowedFields = []}) =>
    cs(consumeContext("routing"), ["search", (_, next) => UseState({next})], ({search, routing}) => {
        const searchedTables = search.value
            ? model?.tables
                  .map((table) => {
                      const filteredColumns = table.columns.filter((column) => isMatchText(column.visualNameShort, search.value));
                      if (filteredColumns.length > 0) {
                          return {
                              ...table,
                              columns: filteredColumns,
                          };
                      }
                  })
                  .filter((v) => v)
            : model?.tables;
        const isDisabled = (table, column) => {
            if (!notAllowedFields?.length) {
                return false;
            }

            return chain(notAllowedFields, (naf) => naf.findIndex((f) => f.modelTableID === table.id && f.modelColumnID === column.id) > -1);
        };

        return (
            <div className="select-field-base-97r">
                <div className="search">
                    <SearchInput
                        {...{
                            state: search,
                            label: "Search",
                            placeholder: "Find Fields",
                        }}
                    />
                </div>

                <Expandable
                    {...{
                        label: model?.name,
                        render: () =>
                            searchedTables
                                ?.filter((table) => !(table.$type === "ViewModelTable" && table.versionDetails === "NotPublished"))
                                .filter((table) => !allowedFieldType || table.columns.filter((c) => getFieldType(c) === allowedFieldType).length > 0)
                                .map((table, i) =>
                                    cs(keyed(i), ({}) => (
                                        <Expandable
                                            {...{
                                                label: table.visualName,
                                                icon: (
                                                    <img
                                                        src={
                                                            table.$type === "ViewModelTable"
                                                                ? require("../../../common/icons/data-view-icon.svg")
                                                                : require("../../../common/icons/table-icon.svg")
                                                        }
                                                        alt=""
                                                    />
                                                ),
                                                control: table.$type === "ViewModelTable" && (
                                                    <div
                                                        className="edit-transformation-link"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            routing.goto("edit-data-view", {
                                                                modelId: model.id,
                                                                transformationId: table.transformationID,
                                                            });
                                                        }}
                                                    >
                                                        <img src={require("../../../model/edit/tabs/common/column-line/column-line-actions/edit-column-icon.svg")} />
                                                    </div>
                                                ),
                                                level: 2,
                                                noTopBorder: i === 0,
                                                initExpand: false,
                                                passiveExpand: !!search.value,
                                                render: () => {
                                                    return chain(
                                                        table.columns,
                                                        (_columns) => (allowedFieldType ? _columns.filter((column) => getFieldType(column) === allowedFieldType) : _columns),
                                                        (_columns) =>
                                                            _columns.filter(
                                                                (column) =>
                                                                    !(
                                                                        column.$type === "CalculatedModelColumn" &&
                                                                        column.calculations?.filter((cal) => cal.versionDetails !== "NotPublished").length === 0
                                                                    ) && !(column.$type === "AggregatedMeasureModelColumn" && column.versionDetails === "NotPublished")
                                                            ),
                                                        (_columns) =>
                                                            !allowAggregatedMeasure ? _columns.filter((column) => column.$type !== "AggregatedMeasureModelColumn") : _columns
                                                    ).map((column, j) =>
                                                        RenderColumn({
                                                            column,
                                                            key: j,
                                                            model,
                                                            table,
                                                            onSelect,
                                                            disabled: isDisabled(table, column),
                                                        })
                                                    );
                                                },
                                            }}
                                        />
                                    ))
                                ),
                    }}
                />
            </div>
        );
    });

const RenderColumn = ({column, key, model, table, onSelect, disabled}) =>
    cs(consumeContext("routing"), ["truncatingTooltip", (_, next) => TruncatingTooltip({direction: "above", next})], ({routing, truncatingTooltip}) => (
        <div
            key={key}
            className={cx("field-line-3sw", {disabled})}
            onClick={() => {
                if (disabled) {
                    return;
                }
                onSelect({column, table});
            }}
            {...truncatingTooltip.tooltip(column.visualNameShort)}
        >
            {column.$type === "CalculatedModelColumn" && (
                <img className="calculated-plus-icon" src={require("../../../model/edit/tabs/common/icons/calculated-plus-icon.svg")} alt="" />
            )}
            {column.$type === "AggregatedMeasureModelColumn" && (
                <img className="aggregated-measure-icon" src={require("../../../model/edit/tabs/common/icons/aggregated-measure-icon.svg")} alt="" />
            )}
            {rFieldTypeIcon(column.dataType)}
            <div className="name">{column.visualNameShort}</div>
            {["CalculatedModelColumn", "AggregatedMeasureModelColumn"].indexOf(column.$type) > -1 && (
                <div
                    className="edit-transformation-link"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        routing.goto("edit-transformed-column", {
                            tab: "build",
                            modelId: model.id,
                            modelTableId: table.id,
                            transformationId: column.transformationID || column.calculations[0].transformationID,
                        });
                    }}
                >
                    <img src={require("../../../model/edit/tabs/common/column-line/column-line-actions/edit-column-icon.svg")} />
                </div>
            )}
        </div>
    ));
