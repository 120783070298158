import React from "react";

export const BoldIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.66667 9.16667H10.4167C10.9692 9.16667 11.4991 8.94717 11.8898 8.55647C12.2805 8.16577 12.5 7.63587 12.5 7.08333C12.5 6.5308 12.2805 6.0009 11.8898 5.61019C11.4991 5.21949 10.9692 5 10.4167 5H6.66667V9.16667ZM15 12.9167C15 13.4091 14.903 13.8968 14.7145 14.3517C14.5261 14.8067 14.2499 15.2201 13.9016 15.5683C13.5534 15.9165 13.14 16.1928 12.6851 16.3812C12.2301 16.5697 11.7425 16.6667 11.25 16.6667H5V3.33333H10.4167C11.1508 3.33336 11.8688 3.54888 12.4816 3.95315C13.0944 4.35742 13.575 4.93267 13.8639 5.60756C14.1528 6.28245 14.2373 7.0273 14.1068 7.74974C13.9764 8.47219 13.6367 9.14045 13.13 9.67167C13.6989 10.0011 14.1711 10.4743 14.4993 11.0439C14.8276 11.6134 15.0002 12.2593 15 12.9167ZM6.66667 10.8333V15H11.25C11.8025 15 12.3324 14.7805 12.7231 14.3898C13.1138 13.9991 13.3333 13.4692 13.3333 12.9167C13.3333 12.3641 13.1138 11.8342 12.7231 11.4435C12.3324 11.0528 11.8025 10.8333 11.25 10.8333H6.66667Z"
            fill="currentColor"
        />
    </svg>
);

export const ItalicIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.5002 16.6667H5.8335V15H8.27266L10.036 5H7.50016V3.33333H14.1668V5H11.7277L9.96433 15H12.5002V16.6667Z" fill="currentColor" />
    </svg>
);

export const UnderlineIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.66683 2.5V10C6.66683 10.8841 7.01802 11.7319 7.64314 12.357C8.26826 12.9821 9.11611 13.3333 10.0002 13.3333C10.8842 13.3333 11.7321 12.9821 12.3572 12.357C12.9823 11.7319 13.3335 10.8841 13.3335 10V2.5H15.0002V10C15.0002 11.3261 14.4734 12.5979 13.5357 13.5355C12.598 14.4732 11.3262 15 10.0002 15C8.67408 15 7.40231 14.4732 6.46463 13.5355C5.52695 12.5979 5.00016 11.3261 5.00016 10V2.5H6.66683ZM3.3335 16.6667H16.6668V18.3333H3.3335V16.6667Z"
            fill="currentColor"
        />
    </svg>
);
