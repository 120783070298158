export const themeDark = {
    general: {
        canvas: {
            fontFamily: "Roboto",
            fontSize: "ExtraSmall",
            fontColorRGB: "#FFFFFF",
            backgroundColorRGB: "#121212",
            tileSpacing: "Narrow",
        },
        tile: {
            styles: {
                titleFontSize: "ExtraSmall",
                titleFontColorRGB: "#FFFFFF",
                titleFontOptions: "Bold",
                titleCaseOptions: "Uppercase",
                tileBackgroundColorRGB: "#2A2A2A",
                tileBorderColorRGB: "#DEE2E7",
                tileCornerRadius: 8,
                tileBorderWidth: 0,
                tileShadow: false,
            },
            containerTiles: {
                buttonStyle: {
                    backgroundColorRGB: "#2A2A2A",
                    borderColorRGB: "#2A2A2A",
                    fontColorRGB: "#FFFFFF",
                    hoverBackgroundColorRGB: "#414141",
                    hoverBorderColorRGB: "#414141",
                    hoverFontColorRGB: "#FFFFFF",
                    activeBackgroundColorRGB: "#FFFFFF",
                    activeBorderColorRGB: "#FFFFFF",
                    activeFontColorRGB: "#212121",
                    borderWidth: 0,
                    cornerRadius: 8,
                },
                tabStyle: {
                    backgroundColorRGB: "#2A2A2A",
                    borderColorRGB: "#414141",
                    fontColorRGB: "#FFFFFF",
                    bottomBorderHeight: 2,
                    hoverBackgroundColorRGB: "#414141",
                    hoverBorderColorRGB: "#FFFFFF",
                    hoverFontColorRGB: "#FFFFFF",
                    activeBackgroundColorRGB: "#2A2A2A",
                    activeBorderColorRGB: "#11A1FD",
                    activeFontColorRGB: "#FFFFFF",
                },
                dropdownStyle: {
                    backgroundColorRGB: "#2A2A2A",
                    borderColorRGB: "#414141",
                    fontColorRGB: "#FFFFFF",
                    borderWidth: 1,
                    cornerRadius: 32,
                },
            },
        },
        buttons: {
            primaryButton: {
                backgroundColorRGB: "#0276F2",
                fontColorRGB: "#FFFFFF",
                borderColorRGB: "#0276F2",
                hoverBackgroundColorRGB: "#0294F2",
                hoverFontColorRGB: "#FFFFFF",
                hoverBorderColorRGB: "#0294F2",
                cornerRadius: 4,
                borderWidth: 0,
            },
            secondaryButton: {
                backgroundColorRGB: "#2A2A2A",
                fontColorRGB: "#FFFFFF",
                borderColorRGB: "#414141",
                hoverBackgroundColorRGB: "#414141",
                hoverFontColorRGB: "#FFFFFF",
                hoverBorderColorRGB: "#0276F2",
                cornerRadius: 4,
                borderWidth: 1,
            },
            downloadButton: {
                backgroundColorRGB: "#2A2A2A",
                fontColorRGB: "#FFFFFF",
                borderColorRGB: "#414141",
                hoverBackgroundColorRGB: "#414141",
                hoverFontColorRGB: "#FFFFFF",
                hoverBorderColorRGB: "#0276F2",
                cornerRadius: 4,
                borderWidth: 1,
            },
            buttonToggleGroup: {
                backgroundColorRGB: "#2A2A2A",
                fontColorRGB: "#FFFFFF",
                borderColorRGB: "#DEE2E7",
                activeBackgroundColorRGB: "#FFFFFF",
                activeFontColorRGB: "#121212",
                activeBorderColorRGB: "#0276F2",
                cornerRadius: 4,
                borderWidth: 0,
            },
            hyperlinkButton: {
                fontColorRGB: "#0276F2",
                hoverFontColorRGB: "#0294F2",
            },
        },
        components: {
            inputBackgroundColorRGB: "#121212",
            inputBorderColorRGB: "#414141",
            inputBorderWidth: 1,
            inputCornerRadius: 4,
            inputTextColorRGB: "#FFFFFF",
            inputHintTextColorRGB: "#717171",
            inputIconColorRGB: "#FFFFFF",
            inputLabelTextColorRGB: "#FFFFFF",
            menuBackgroundColorRGB: "#2A2A2A",
            menuCornerRadius: 4,
            menuTextColorRGB: "#FFFFFF",
            menuHoverBackgroundColorRGB: "#414141",
        },
    },
    dataVisualization: {
        dataColorPalettes: {
            discreteColorsRGB: ["#0276F2", "#D457D0", "#FF4E87", "#FF8638", "#F5D400", "#35C06D", "#00ECFD", "#00B5BE"],
            colorSchemes: {
                categoricalColorSchemes: [
                    {
                        colorsRGB: ["#FC4EF6", "#FF4E87", "#FF8B42", "#35C06D", "#00ECFD", "#00B5BE"],
                    },
                    {
                        colorsRGB: ["#00B5BE", "#00ECFD", "#35C06D", "#FF8B42", "#FF4E87", "#FC4EF6"],
                    },
                    {
                        colorsRGB: ["#1ACCEF", "#D6FFE9", "#FFDA33", "#FA694C", "#FF4775", "#FA9EDE"],
                    },
                ],
                sequentialColorSchemes: [
                    {
                        colorsRGB: ["#0074F0", "#029EF2"],
                    },
                    {
                        colorsRGB: ["#0074F0", "#02F2B8"],
                    },
                    {
                        colorsRGB: ["#0074F0", "#AF31FC"],
                    },
                ],
            },
            kpiColorsRGB: ["#FFFFFF", "#595959", "#0276F2", "#FF5959", "#1AC689"],
            otherColors: {
                emptyFillColorRGB: "#414141",
                conditionalGoodColorRGB: "#1AC689",
                conditionalBadColorRGB: "#FF5959",
            },
        },
        fonts: {
            fontFamily: null,
            fontColorRGB: null,
            axisFontSize: null,
            dataLabelFontSize: null,
            gridStepsFontSize: null,
        },
        toolTipsAndLegends: {
            tooltipTheme: "Dark",
            position: "Above",
            indicatorLineShown: true,
            indicatorLineStyle: "Solid",
            indicatorLineColorRGB: "#FFFFFF",
            indicatorLineWidth: 1.0,
            legendFontSize: null,
            legendSymbol: "Square",
        },
        axisCharts: {
            axisLineColorRGB: "#595959",
            gridStepLineColorRGB: "#595959",
            gridStepLineStyle: "Dashed",
            dimensionAxisTickMarks: false,
            barChartCornerRadius: 12,
            barChartCornerRadiusLocation: "Top",
            areaLineChartFill: "Gradient",
        },
        gauges: {
            showBorder: false,
            borderWidth: 2,
            borderColorRGB: "#FFFFFF",
            calloutLineWidth: 1,
            calloutLineColorRGB: "#EAEAEA",
        },
        pieAndDonutCharts: {
            showBorder: false,
            borderWidth: 20,
            borderColorRGB: "#FFFFFF",
            calloutLineWidth: 1,
            calloutLineColorRGB: "#EAEAEA",
        },
        tables: {
            headerBackgroundColorRGB: "#414141",
            headerFontColorRGB: "#FFFFFF",
            headerFontSize: null,
            headerFontOptions: "Bold",
            headerCaseOptions: "Uppercase",
            cellFontColorRGB: "#e3e3e3",
            cellBackgroundColorRGB: "#2A2A2A",
            cellAlternateType: "AlternateRows",
            cellAltCellColorRGB: "#414141",
            totalsBackgroundColorRGB: "#2A2A2A",
            totalsFontColorRGB: "#FFFFFF",

            totalsFontSize: null,
            totalsFontOptions: "Bold",
            borderVerticalLines: false,
            borderHorizontalLines: false,
            borderOutsideLines: false,
            borderWidth: 1,
            borderColorRGB: "#595959",
            tables: {
                headerBackgroundColorRGB: "#F4F5F7",
                headerFontColorRGB: "#919EB0",
                headerFontSize: null,
                headerFontOptions: "Bold",
                headerCaseOptions: "Uppercase",
                cellFontColorRGB: "#294661",
                cellBackgroundColorRGB: "#FFFFFF",
                cellAlternateType: "AlternateRows",
                cellAltCellColorRGB: "#F9FBFC",
                totalsBackgroundColorRGB: "#FFFFFF",
                totalsFontColorRGB: "#294661",
                totalsFontOptions: "Bold",
                totalsFontSize: null,
                borderVerticalLines: true,
                borderHorizontalLines: true,
                borderOutsideLines: true,
                borderWidth: 1,
                borderColorRGB: "#E9EEF3",
                colorScaleSchemes: [
                    {
                        colorsRGB: ["#FF5959", "#FDED5B", "#1AC689"],
                    },
                    {
                        colorsRGB: ["#1AC689", "#FDED5B", "#FF5959"],
                    },
                    {
                        colorsRGB: ["#1AC689", "#FF5959"],
                    },
                    {
                        colorsRGB: ["#FFFFFF", "#1AC689"],
                    },
                    {
                        colorsRGB: ["#FF5959", "#FFFFFF"],
                    },
                ],
                singleColorsRGB: ["#1AC689", "#FF5959", "#FDED5B", "#0074F0", "#FF8638"],
                fontColorsRGB: ["#294661", "#000000", "#ffffff", "#1AC689", "#FF5959"],
            },
        },
        maps: {
            emptyLocationColorRGB: "#2A2A2A",
            borderColorRGB: "#E9ECEF",
        },
        sectionTile: {
            backgroundColorRGB: null,
            fontColorRGB: null,
            fontStyle: "Bold",
            caseStyle: "Uppercase",
            fontSize: null,
        },
        textTile: {
            fontColorRGB: null,
            fontSize: null,
        },
    },
};
