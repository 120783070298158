import * as React from "react";

import {keyed} from "../../react/keyed";
import {Static2} from "../../react/static-2";
import {cs} from "../../react/chain-services";
import {UseState} from "../../react/use-state";
import {IgnoreUpdate} from "../../react/ignore-update";
import {CaptureException} from "../../react/capture-exception";

import {isDevQuan} from "../../../tools/dev/is-dev-quan";
import {equalDeep} from "../../utils/objects";

import {isCompareMode} from "./common/is-compare-mode";
import {ErrorMessage} from "./error-message/error-message";
import {cChartOuterLayout} from "./chart-layout/chart-outer-layout";
import {chartTypes, getChartRestyling, getTileName} from "./chart-types";
import {LoadingSkeleton} from "./common/loading-wrapper/loading-skeletion";

import {comparisonObserver} from "../../../web-client/src/routes/collection/tile/edit/comparison-observer";
import {consumeContext} from "@common/react/context";
import {TileErrorsBadge} from "@common/ui-components/charts/chart-layout/tile-errors-badge/tile-errors-badge";
import {isMobile} from "@common/ui-components/charts/factory/tooltip/positioners/is-mobile";
import {zeroID} from "../../../web-client/src/common/constant";

export const ChartDraw = ({
    tile,
    size,
    onEdit,
    isEditTile = false,
    tileFilters,
    loadData,
    downloadData,
    theme,
    isInContainerTile,
    sdkDashboard,
    isLive = false,
    disabledTileActions = false,
    renderOverlay,
    defaultData,
}) =>
    cs(
        keyed(tile.id),
        consumeContext("collection"),
        consumeContext("editingMode"),
        [
            "layout",
            ({collection, editingMode}, next) => {
                const chartOuterLayout = cChartOuterLayout({
                    tile,
                    size,
                    tileFilters,
                    theme,
                    isLive,
                    sdkDashboard,
                    renderOverlay,
                });

                const tileModelErrors = (collection.value?.id !== zeroID ? collection.value : tile)?.tileModelErrors;
                const tileErrors = (tileModelErrors || []).filter((e) => e.tileID == tile?.id && ["DataTab", "FilterTab"].indexOf(e.location) > -1);

                const withLayoutLoading = () => {
                    return chartOuterLayout({
                        content: () => <LoadingSkeleton animated={!!loadData.load} tile={tile} theme={theme} />,
                    });
                };

                const withLayoutErrorMessage = (msgObj) =>
                    chartOuterLayout({
                        content: () => ErrorMessage(msgObj),
                    });

                return !tile.id
                    ? withLayoutErrorMessage({message: "Saving...."})
                    : editingMode && tileErrors.length > 0
                    ? withLayoutErrorMessage({
                          message: "Cannot render tile until all errors are resolved",
                          iconOnly: size.width <= 4 && size.height <= 2,
                          renderIcon: (tooltip) => TileErrorsBadge({tile, tileErrors, tooltip}),
                      })
                    : tile.fieldValidity === "CannotDisplay"
                    ? withLayoutErrorMessage({
                          message: (
                              <>
                                  {isInContainerTile ? `Add another tile or add data to format this` : `Please add data to format this `}
                                  <div style={{display: "inline-block"}}>{getTileName(tile.$type)}</div>

                                  {isDevQuan() && (
                                      <>
                                          <pre>{`Invalid filters: ${tileFilters?.invalid}`}</pre>
                                          <pre>{`filters key: ${tileFilters?.key}`}</pre>
                                          <pre>{JSON.stringify(tileFilters?.getValue?.(), null, 4)}</pre>
                                      </>
                                  )}
                              </>
                          ),
                          button: onEdit && {
                              label: "Edit Tile",
                              onClick: () => onEdit(tile),
                          },
                      })
                    : tile.fieldValidity !== "Valid"
                    ? withLayoutErrorMessage({message: tile.fieldValidity})
                    : !tileFilters
                    ? withLayoutLoading()
                    : // withLayoutErrorMessage({message: "Loading filters..."})
                    tileFilters.invalid
                    ? withLayoutErrorMessage({message: "Invalid filters data."})
                    : chartOuterLayout({
                          content: ({tileAction, modalTileAction, headerCenterSlotRef, hasSwapAxis, headerRightSlotRef}) =>
                              next({
                                  tileAction,
                                  hasSwapAxis,
                                  modalTileAction,
                                  headerCenterSlotRef,
                                  headerRightSlotRef,
                              }),
                      });
            },
        ],
        ({}, next) =>
            CaptureException({
                renderException: () => "Exception",
                //onException: ({error, info}) => console.log({error, info}),
                next,
            }),

        ({}, next) =>
            IgnoreUpdate({
                props: {size, tileFilters, tile, theme, loadData},
                when: (pp) =>
                    equalDeep(pp.size, size) &&
                    equalDeep(pp.theme, theme) &&
                    equalDeep(pp.tile, tile) &&
                    pp.tileFilters.key === tileFilters.key &&
                    equalDeep(pp.loadData, loadData, {ignoreFunction: true}),
                next,
            }),
        ["chartRef", (_, next) => Static2({next})],
        ["childTile", (_, next) => UseState({initValue: null, next})],
        ({chartRef, layout}) => {
            const isCompare =
                tile.style?.showCompare !== false &&
                isCompareMode({
                    tileFilters: tile.filters,
                    collectionFiltersValue: !tileFilters.invalid && tileFilters?.getValue?.(),
                });

            // temporary solution to share isCompare with edit tile page's left panel.
            // only know the isCompare when getting here, when collectionFiltersValue is set
            // didn't use state b/c it messes up the rendering
            comparisonObserver.set({tileId: tile.id, isCompare});

            const ChartComponent = chartTypes[tile.$type];

            if (!ChartComponent) {
                return null;
            }

            return (
                <ChartComponent
                    {...{
                        overrideTile: ({tileAction}) => {
                            if (tileAction?.tile) {
                                if (tileAction?.openType === "Modal") {
                                    // on mobile, clicking a point triggers both tooltip and tile action
                                    // on mobile, there's no 'mouseleave' event on chart to invoke tooltip hide method
                                    // so here, do closing tooltip when tile action modal open
                                    if (isMobile()) {
                                        const chart = chartRef.get()?.chart;
                                        chart?.tooltip.manuallyHide?.();
                                    }

                                    layout.modalTileAction.show({tileAction});
                                } else {
                                    layout.tileAction.onChange(tileAction);
                                }
                            }
                        },
                        sdkDashboard,
                        isLive,
                        isEditTile,
                        chartRef,
                        tileFilters,
                        loadData,
                        downloadData,
                        theme,
                        size,
                        disabledTileActions,
                        tile: getChartRestyling(tile.$type)({tile, size, isCompare}),
                        headerCenterSlotRef: layout.headerCenterSlotRef,
                        headerRightSlotRef: layout.headerRightSlotRef,
                        defaultData: (defaultData || []).find((data) => data.tileID == tile.id),
                        hasSwapAxis: layout.hasSwapAxis,
                    }}
                />
            );
        }
    );
