import * as React from "react";
import {cs} from "@common/react/chain-services";
import {Static2} from "@common/react/static-2";
import {UseState} from "@common/react/use-state";
import {cx} from "emotion";
import "./static-tooltip-service.scss";
import {TooltipConsumer} from "./tooltip-context-3";
import {OnUnmounted} from "@common/react/on-unmounted";

export const StaticTooltipService = ({
    info,
    direction,
    topOffset,
    leftOffset,
    tooltipBoxWidth,
    detectOverScreen = true,
    next,
    iconTooltip,
}) =>
    cs(
        ["iconRef", (_, next) => Static2({next})],
        ["pos", (_, next) => UseState({next})],
        ["registry", ({}, next) => React.createElement(TooltipConsumer, {}, next)],

        ({iconRef, pos, registry}) => {
            const getInfoBoxPos = ({top, bottom, left, right, width, height}, contentRef) => {
                if (direction === "right" && detectOverScreen) {
                    const contentRect = contentRef?.get?.().getBoundingClientRect();
                    const isOverScreen = window.innerHeight - top < contentRect?.height;

                    return {
                        box: {
                            top:
                                (isOverScreen ? window.innerHeight - contentRect?.height : top - contentRect?.height / 2 + width / 2) -
                                (topOffset || 0),
                            left: right + 15 - (leftOffset || 0),
                        },
                        arrow: {
                            top: top - (window.innerHeight - contentRect?.height) + width / 2,
                        },
                        isOverScreen: isOverScreen,
                    };
                } else {
                    const positions = {
                        right: {
                            top: top - (topOffset || 0),
                            left: right + 15,
                        },
                        top: {
                            bottom: window.innerHeight - top + 10,
                            left: left - (tooltipBoxWidth || 200) * 0.5 + width * 0.5,
                        },
                        bottom: {
                            top: window.innerHeight - top + height + 30,
                            left: left - (tooltipBoxWidth || 200) * 0.5 + width * 0.5,
                        },
                    };

                    return {
                        box: positions[direction],
                        isOverScreen: false,
                    };
                }
            };

            return (
                <>
                    {next({
                        renderIcon: ({icon, className, onClick}) => {
                            return cs(
                                ["contentRef", (_, next) => Static2({next})],
                                ({contentRef}, next) =>
                                    cs(() => {
                                        // get dimension of content to prevent cut off content
                                        return (
                                            <>
                                                {direction === "right" && detectOverScreen && (
                                                    <div
                                                        style={{
                                                            fontSize: 12,
                                                            lineHeight: "normal",
                                                            letterSpacing: "0.04em",
                                                            width: tooltipBoxWidth || 200,
                                                            padding: 10,
                                                            position: "fixed",
                                                            zIndex: -999,
                                                            top: -999,
                                                        }}
                                                        ref={contentRef.set}
                                                    >
                                                        {info}
                                                    </div>
                                                )}

                                                {next()}
                                            </>
                                        );
                                    }),
                                ({contentRef}) => {
                                    return (
                                        <>
                                            <div
                                                onClick={onClick}
                                                className={cx("static-tooltip-icon-yf4 static-tooltip-icon", className)}
                                                ref={iconRef.set}
                                                onMouseEnter={() => {
                                                    pos.onChange(getInfoBoxPos(iconRef.get().getBoundingClientRect(), contentRef));
                                                }}
                                                onMouseLeave={() => {
                                                    pos.onChange(null);
                                                }}
                                            >
                                                {icon}
                                            </div>

                                            {OnUnmounted({
                                                action: () => {
                                                    pos.onChange(null);
                                                },
                                            })}
                                        </>
                                    );
                                }
                            );
                        },
                    })}

                    {pos.value &&
                        registry.render(
                            <div
                                className={cx("static-tooltip-box-57w", direction, {
                                    "is-over-screen": pos.value.isOverScreen,
                                })}
                                style={{
                                    ...pos.value.box,
                                    width: tooltipBoxWidth || 200,
                                }}
                            >
                                {info}

                                {pos.value.isOverScreen && <div className="arrow" style={{...pos.value.arrow}} />}
                            </div>
                        )}
                </>
            );
        }
    );

//tooltip for item with fixed position
export const StaticTooltipService2 = ({next, tooltipContent}) =>
    cs(
        ["position", (_, next) => UseState({initValue: {top: -99999, left: -999999}, next})],
        ["contentRef", (_, next) => Static2({next})],
        ["registry", ({}, next) => React.createElement(TooltipConsumer, {}, next)],
        ({position, registry, contentRef}) => {
            const getInfo = ({top, left, bottom, height}) => {
                const isOverScreen = window.innerHeight - top < contentRef.get().getBoundingClientRect?.()?.height;

                return {
                    ...(isOverScreen ? {bottom: window.innerHeight - top - height} : {top}),
                    left: left,
                };
            };

            return (
                <>
                    {next({
                        render: ({content}) =>
                            cs(["static1", (_, next) => Static2({next})], ({static1}) =>
                                content({
                                    ref: static1.set,
                                    onMouseEnter: () => {
                                        position.onChange(getInfo(static1.get().getBoundingClientRect()));
                                    },
                                })
                            ),
                    })}

                    {registry.render(
                        tooltipContent({
                            style: {
                                ...position.value,
                                width: 288,
                            },
                            ref: contentRef.set,
                            onMouseLeave: () => {
                                position.onChange({top: -99999, left: -99999});
                            },
                        })
                    )}
                </>
            );
        }
    );
