import "./live-filters-layout-desktop.scss";

import { cx } from "emotion";
import * as React from "react";

import { cs } from "@common/react/chain-services";
import { consumeContext } from "@common/react/context";
import { fragments } from "@common/react/fragments";
import { OnMounted } from "@common/react/on-mounted";
import { OnUnmounted } from "@common/react/on-unmounted";
import { Static2 } from "@common/react/static-2";
import { StyledClass } from "@common/react/styled-class";
import { UseState } from "@common/react/use-state";
import { CollectionErrorBadgeWithTooltip } from "@common/ui-components/live/live-dashboard/common/collection-error-badge/collection-error-badge-with-tooltip";
import { sort } from "@common/utils/collections";
import { RAFLoop } from "@common/utils/loop";
import { equalDeep, keepOnly } from "@common/utils/objects";
import { Expandable } from "../../../../../../web-client/src/routes/common/expandable/expandable";
import { tooltipService3 } from "../../../../../../web-client/src/routes/common/tooltip3/tooltip-service-3";
import { InfoIconTooltip } from "../../../../charts/common/info-icon-tooltip/info-icon-tooltip";
import { cGetFontSize } from "../../../../charts/common/style-map/font-size";
import { FilterLabel } from "./common/label/filter-label";
import { LiveFiltersDesktop } from "./live-filters-desktop/live-filters-desktop";
import { MoreFilterDialog } from "./live-filters-desktop/more/more-filter-dialog";
import { LiveFiltersMobile } from "./live-filters-mobile/live-filters-mobile";
import { MobileFiltersPanel } from "./live-filters-mobile/mobile-filters-panel/mobile-filters-panel";

export const hasValueValidators = {
    TextFilter: (value) => value?.freeFormValue?.length > 0 || value?.selectableListValues?.length > 0,
    NumericFilter: (value) => value?.value != null || (value?.range ? value?.range.from && value?.range.to : false),
    BooleanFilter: () => true,
    DateFilter: () => true,
};

export const LiveFiltersLayout = ({
    filtersConfig,
    noLabel,
    filters,
    runReportButton,
    onRunReport,
    reportButton,
    showTimezoneConversion,
    downLoadButton,
    noFilter,
    theme,
    sdkDashboard,
    filterForms,
    wrapLine,
    customWindowWidth,
    disabledFilters,
}) =>
    cs(
        tooltipService3({direction: "below"}),
        consumeContext("editMode"),
        consumeContext("collection"),
        ["removeFunc", (_, next) => Static2({next})],
        ["rootSize", (_, next) => UseState({next, initValue: "mobile"})],
        ({rootSize, removeFunc}, next) =>
            fragments(
                next(),
                OnMounted({
                    action: () => {
                        if (customWindowWidth) {
                            rootSize.onChange(customWindowWidth >= 768 ? "desktop" : "mobile");
                        } else {
                            const removeInterval = RAFLoop.addTarget({
                                update: () => {
                                    const _currentSize = window.innerWidth >= 768 ? "desktop" : "mobile";
                                    if (rootSize.get() != _currentSize) {
                                        rootSize.onChange(_currentSize);
                                    }
                                },
                            });

                            removeFunc.set(removeInterval);
                        }
                    },
                }),
                OnUnmounted({
                    action: () => {
                        const func = removeFunc.get();
                        func?.();
                    },
                })
            ),
        ["size", ({rootSize}, next) => next(rootSize.value)],
        ({size, tooltip, editMode, collection}) => {
            const dateTimeFilters = filters.filter((f) => f.$type == "DateFilter");
            const numDateFiltersBehindMore = dateTimeFilters.filter((f) => f.displayArea == "BehindMore").length;
            const numDateFiltersVisible = dateTimeFilters.filter((f) => f.displayArea == "Visible").length;

            // const showTimezoneDropdownInDateRangePopup = showTimezoneConversion && dateTimeFilters.length == 1 && (numDateFiltersBehindMore > 0 || numDateFiltersVisible > 0);
            const showTimezoneDropdownInModal = showTimezoneConversion && numDateFiltersBehindMore >= 1;

            const showTimezoneDropdownInFilterVisible =
                showTimezoneConversion && (numDateFiltersBehindMore >= 1 ? numDateFiltersVisible >= 1 : numDateFiltersVisible !== 1);

            const filters1 = sort(
                filters
                    .map((filter) => ({
                        ...filter,
                        lines: filter.control({size}),
                        priority: ["Hidden", "BehindMore", "Visible"].findIndex((v) => v === filter.displayArea),
                    }))
                    .filter((filter) => {
                        return !(noFilter ? true : filter.displayArea === "Hidden");
                    }),
                (v) => -v.priority
            );
            const isMobile = size === "mobile";

            const renderFilter = (filter, i) => {
                const isHiddenFilter = noFilter ? true : filter.displayArea === "Hidden";
                const hasError = editMode && collection.value.tileModelErrors?.find((e) => e.filterID == filter.id);

                if (sdkDashboard && isHiddenFilter) return null;
                const isDisabled =
                    disabledFilters.findIndex(
                        (df) =>
                            df.$type === filter.$type &&
                            equalDeep(
                                keepOnly(df.columns[0], ["modelColumnID", "modelID", "modelTableID"]),
                                keepOnly(filter.columns[0], ["modelColumnID", "modelID", "modelTableID"])
                            )
                    ) > -1;

                return (
                    <div
                        className={cx(
                            "filter",
                            {noLabel, isHiddenFilter},
                            `verb-filter-${filter.$type.toLowerCase().replace("filter", "")}`
                        )}
                        key={filter.id}
                        x-filter-id={filter.id}
                        style={{
                            fontFamily: `"${theme.general.canvas.fontFamily || "Roboto"}", sans-serif`,
                            color: theme.general.canvas.fontColorRGB || "#294661",
                        }}
                        {...{...(isDisabled ? tooltip(() => "This filter is disabled because it does not control this tile.") : {})}}
                    >
                        {isDisabled && <div className="disabled-overlay" />}

                        {!noLabel && (
                            <div className="label verb-filter-label">
                                {!isMobile && FilterLabel({text: filter.label})}

                                {InfoIconTooltip({
                                    theme,
                                    width: cGetFontSize(theme.general.canvas.fontSize, theme)(),
                                    color: theme.general.components.inputLabelTextColorRGB,
                                    showInfoIcon: () => filter.showLabelInfoIcon && filter?.labelInfoIconText,
                                    infoText: filter?.labelInfoIconText,
                                })}

                                {isHiddenFilter && (
                                    <div className="hidden-filter">
                                        <img src={require("./hidden-filter-icon.svg")} alt="" />

                                        <div className="tooltip">This filter will not show when embedded.</div>
                                    </div>
                                )}
                            </div>
                        )}

                        {(() =>
                            cs(
                                [
                                    "cssClass",
                                    (_, next) =>
                                        StyledClass({
                                            content: {
                                                position: "relative",
                                                "padding-left": "35px",

                                                ".error-filter-icon": {
                                                    position: "absolute",
                                                    top: "50%",
                                                    left: 0,
                                                    transform: "translate(0, -50%)",
                                                },
                                            },
                                            next,
                                        }),
                                ],
                                ({cssClass}) => (
                                    <div className={cx("control", hasError && cssClass)}>
                                        {filter?.lines?.map?.((line, i) => (
                                            <div className="line" key={i}>
                                                {line}
                                            </div>
                                        ))}

                                        {hasError && (
                                            <CollectionErrorBadgeWithTooltip
                                                className="error-filter-icon"
                                                error={hasError}
                                                title="Broken Filter"
                                            />
                                        )}
                                    </div>
                                )
                            ))()}
                    </div>
                );
            };
            const componentTheme = theme.general.components;
            const buttonTheme = theme.general.buttons;
            const renderMobileFilter = (filter) => {
                const preview = filter.mobileFilter.preview();
                return cs(
                    [
                        "cssClass",
                        (_, next) =>
                            StyledClass({
                                content: {
                                    ".toggle": {
                                        ".reset": {
                                            color: `${buttonTheme.hyperlinkButton.fontColorRGB}`,
                                            "&:hover": {
                                                color: `${buttonTheme.hyperlinkButton.hoverFontColorRGB}`,
                                            },
                                        },
                                        ".filter-preview": {
                                            color: `${componentTheme.menuTextColorRGB}`,
                                        },
                                        "& > i": {
                                            color: `${componentTheme.inputLabelTextColorRGB} !important`,
                                        },
                                        "& > .label": {
                                            "& .filter-label": {
                                                color: `${componentTheme.inputLabelTextColorRGB} !important`,
                                            },
                                        },
                                    },
                                },
                                next,
                            }),
                    ],
                    ({cssClass}) => (
                        <div className={cx("mobile-filter-item")}>
                            {Expandable({
                                label: ({isExpand}) => (
                                    <div>
                                        <div className="filter-label">{filter.label}</div>
                                        {filter.resetable() && (
                                            <span
                                                className="reset"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    filter.reset();
                                                }}
                                            >
                                                Reset
                                            </span>
                                        )}
                                        {preview && !isExpand && <div className="filter-preview">{preview}</div>}
                                    </div>
                                ),
                                className: cx("mobile-filter-expand", cssClass),
                                initExpand: filters1.length === 1,
                                render: () => renderFilter(filter),
                            })}
                        </div>
                    )
                );
            };
            const hasValueFilters = filters1.filter((filter) =>
                hasValueValidators[filter.$type](filterForms.getForm(filter.id).data.value)
            );
            const valueCount = hasValueFilters.length;
            const highlightFilterMobile = hasValueFilters.find((f) => f.$type === "DateFilter") ?? hasValueFilters[0];
            return cs(
                [
                    "filterDialog",
                    (_, next) =>
                        !isMobile
                            ? MoreFilterDialog({
                                  next,
                                  filters: filters1,
                                  runReportButton,
                                  filterForms,
                                  renderFilter,
                                  theme,
                                  size,
                                  noFilter,
                                  showTimezoneDropdownInModal,
                                  filtersConfig,
                              })
                            : MobileFiltersPanel({
                                  next,
                                  filterForms,
                                  renderFilter: renderMobileFilter,
                                  theme,
                                  size,
                                  noFilter,
                                  reportButton,
                                  showTimezoneDropdownInModal,
                                  filtersConfig,
                              }),
                ],
                ({filterDialog}) => {
                    return !isMobile
                        ? LiveFiltersDesktop({
                              renderFilter,
                              filters: filters1,
                              downLoadButton,
                              noLabel,
                              runReportButton: filters1.length > 0 && runReportButton,
                              theme,
                              filterForms,
                              wrapLine,
                              customWindowWidth,
                              filterDialog,
                              valueCount,
                              showTimezoneDropdown: showTimezoneDropdownInFilterVisible,
                          })
                        : LiveFiltersMobile({
                              filters: filters1,
                              downLoadButton,
                              noLabel,
                              onRunReport,
                              runReportButton: filters1.length > 0 && runReportButton,
                              theme,
                              filterForms,
                              wrapLine,
                              customWindowWidth,
                              filterDialog,
                              showTimezoneDropdown: showTimezoneDropdownInFilterVisible,
                              highlightFilterMobile,
                          });
                }
            );
        }
    );
