import * as React from "react";
import {cs} from "@common/react/chain-services";
import {tooltipService3} from "../tooltip3/tooltip-service-3";
import {UseState} from "@common/react/use-state";
import {RAFLoop} from "@common/utils/loop";

export const TruncatingTooltip = ({
    direction,
    next,
    alternateStyle = false,
    alternativeActiveTest,
    className,
    tooltipContentStyle,
    forceUpdate = false,
    delay = 500,
}) =>
    cs(
        ["active", (_, next) => UseState({next})],
        [
            "tooltip",
            ({active}, next) =>
                cs(
                    tooltipService3({
                        direction: direction || "below",
                        disabled: !active.value,
                        alternateStyle,
                        className,
                        tooltipContentStyle,
                    }),
                    ({tooltip}) => next(tooltip)
                ),
        ],
        ({tooltip, active}) =>
            next({
                ref: (dom) => {
                    if ((forceUpdate ? true : active.value == null) && dom) {
                        const newValue = dom.offsetWidth < dom.scrollWidth || (alternativeActiveTest ? alternativeActiveTest(dom) : false);

                        if (newValue != active.value) {
                            const remove = RAFLoop.addTarget({
                                update: () => {
                                    active.onChange(newValue);
                                    remove();
                                },
                            });
                        }
                    }

                    // setTimeout(() => {
                    //     if ((forceUpdate ? true : active.value == null) && dom) {
                    //         const newValue =
                    //             dom.offsetWidth < dom.scrollWidth || (alternativeActiveTest ? alternativeActiveTest(dom) : false);
                    //
                    //         if (newValue != active.value) {
                    //             console.log(23423423);
                    //             active.onChange(newValue);
                    //         }
                    //     }
                    // }, delay);
                },
                tooltip,
                isActive: active.value,
            })
    );
