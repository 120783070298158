import {getTextDimension} from "../single-kpi/font-auto-resizing";

export const drawIndicatorSeriesLabel = ({chart}) => {
    // only used for horizontal bar chart tile for now
    if (chart.userOptions.chart.customType !== "HorizontalBarChartTile") {
        return;
    }

    const referenceSeries = chart.series.filter((s) => s.userOptions.customType?.includes("ReferenceLine"));

    if (!referenceSeries?.length) {
        return;
    }

    referenceSeries.forEach((rs) => {
        if (rs.userOptions.label?.drawManually && rs.userOptions.customType === "IndicatorReferenceLine") {
            if (rs.customLineLabel) {
                rs.customLineLabel.destroy();
            }

            const chartContainerRect = chart.container.getBoundingClientRect();
            const seriesRect = rs.group.element.getBoundingClientRect();

            rs.customLineLabel = chart.renderer.g(`reference-line-label-${rs.userOptions.id}`).add().toFront();

            const translate = (() => {
                const textDimension = getTextDimension(12, rs.userOptions.name);
                if (!rs.yAxis.opposite) {
                    // measure axis at bottom position
                    const xDelta = chartContainerRect.x + chartContainerRect.width - (seriesRect.x + seriesRect.width);
                    const yDelta = chartContainerRect.y + chartContainerRect.height - (seriesRect.y + seriesRect.height);
                    return {
                        x: chartContainerRect.width - xDelta + textDimension.height,
                        y: chartContainerRect.height - yDelta - textDimension.width,
                    };
                } else {
                    // measure axis at top position
                    return {
                        x: seriesRect.x - chartContainerRect.x + textDimension.height,
                        y: seriesRect.y - chartContainerRect.y,
                    };
                }
            })();

            rs.group.renderer
                .text(rs.userOptions.name)
                .attr({
                    transform: `translate(${translate.x}, ${translate.y}) rotate(90)`,
                })
                .css(rs.userOptions.label.style)
                .add(rs.customLineLabel);
        }
    });
};
