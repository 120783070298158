const {cache0} = require("../../common/utils/cache0");
const {isTrue} = require("./is-true");

const isDevSonPham = cache0(() => {
    try {
        return isTrue(localStorage.getItem("DEV_SONPHAM"));
    } catch (e) {
        return false;
    }
});
exports.isDevSonPham = isDevSonPham;
