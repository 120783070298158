exports.funnelChartTemplate = {
    $type: "FunnelChartTile",
    style: {
        autoRound: true,
        stageStyle: "Constant",
        dataLabels: {
            show: false,
            categoryShown: true,
            metrics: "Percent,Value",
        },
        legendStyle: {
            legendShown: false,
            legendPosition: "Right",
        },
        title: {
            show: true,
        },
    },
};
