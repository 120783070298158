import React from "react";
import {cs} from "@common/react/chain-services";
import {DialogService} from "../../../../../common/dialog/dialog-service";
import {consumeContext} from "@common/react/context";
import {UseState} from "@common/react/use-state";
import "./setting-roll-key-dialog.scss";
import {settingKeyLabels} from "../../settings-constants";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";
import {ArrowRightIcon, RevealKeyIcon, ShareKeyIcon} from "./setting-roll-key-icons";

export const SettingRollKeyDialog = ({$type, next: rootNext}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    render: ({resolve, args: {accessKey}}) => ({
                        title: `Roll ${accessKey.name} Key`,
                        width: 480,
                        content: next({
                            resolve,
                        }),
                        initShow: true,
                        className: "manage-access-key-dialog-wrapper-a23",
                    }),
                    next: rootNext,
                }),
        ],
        ({modal}) =>
            cs(consumeContext("apis"), ["saving", (_, next) => UseState({next})], () => (
                <div className="roll-key-dialog-a23">
                    <VerbDialogBodyScrollbar>
                        <div className="option" onClick={() => modal.resolve("reveal")}>
                            <div className="left-icon">
                                <RevealKeyIcon />
                            </div>

                            {settingKeyLabels[$type].rollKey.reveal}

                            <div className="arrow-right">
                                <ArrowRightIcon />
                            </div>
                        </div>

                        <div className="option" onClick={() => modal.resolve("send")}>
                            <div className="left-icon">
                                <ShareKeyIcon />
                            </div>

                            {settingKeyLabels[$type].rollKey.send}

                            <div className="arrow-right">
                                <ArrowRightIcon />
                            </div>
                        </div>
                    </VerbDialogBodyScrollbar>
                </div>
            ))
    );
