import {cs} from "../../../../react/chain-services";
import React, {createElement as h} from "react";
import "./dp-mask-input.scss";
import {bindInput} from "../../../../react/bind-input";
import {cx} from "emotion";
import {today} from "@common/utils/dates/date-object";
import {formatDateTimeFromISOStr} from "@common/logic/date-time/format-date-time-full";

const emptyListener = () => null;

const getDisplayValue = dateStr => {
    return formatDateTimeFromISOStr(dateStr, 'MMM DD, YYYY')
}

export const DpMaskInput = ({onKeyUp, showDisplayValue, className = '', state, domRef, onFocus, onBlur, onClick, noKeyboard, style, inputStyle, disabled = false, }) => cs(
    () => {
        const displayValue = getDisplayValue(state.value ?? (() => {
            const {day, month, year} = today();
            return `${month}/${day}/${year}`;
        })());
        return (
            <div className={cx("input text-input dp-mask-input-3sw", className)} style={style}>
                {h((noKeyboard || showDisplayValue) ? "div" : "input", {
                    ref: domRef,
                    onFocus: function (e) {
                        setTimeout(function () {e.target?.select?.()});
                        onFocus?.(e);
                    },
                    onBlur,
                    onKeyUp,
                    autoFocus: true,
                    disabled,
                    style: inputStyle,
                    ...(noKeyboard || showDisplayValue) ? {
                        tabIndex: 0,
                        onClick,
                        onTouchStart: onClick,
                        className: cx("fake-input", {disabled}),
                        children: displayValue ?? "",
                        ...(disabled ? {
                            onFocus: emptyListener,
                            onBlur: emptyListener,
                            onKeyUp: emptyListener,
                            onClick: emptyListener,
                        } : {})
                    } : {
                        ...bindInput(state),
                        placeholder: 'MM/DD/YYYY',
                    },
                })}

            </div>
        );
    }
)