import "./legend-top-bottom-vertical-display.scss";

import React from "react";
import {cx} from "emotion";

import {Static2} from "../../../../../react/static-2";
import {cs} from "../../../../../react/chain-services";
import {UseState} from "../../../../../react/use-state";

import {maxBy} from "../../../../../utils/math-util";
import {sort, unique} from "../../../../../utils/collections";
import {LegendItemView} from "../legend-view/legend-item-view";
import {LegendControl} from "../control/legend-control";
import {cRenderComparison} from "./render-comparison";
import {groupLegendItems, mapLegendItem} from "../../render-chart-helper";
import {RevalidateChartService} from "./revalidate-chart-service";

export const LegendTopBottomVerticalDisplay = ({size, chartRef, legendRoom, tile, options, theme, isCompare, formatters}) =>
    cs(
        [
            "hiddenSeries",
            (_, next) =>
                UseState({
                    next,
                    initValue: [
                        // {name, color}
                    ],
                }),
        ],
        ["translateX", (_, next) => UseState({next, initValue: 0})],
        ["legendContainerRef", (_, next) => Static2({next})],
        ["scrollLeft", (_, next) => UseState({next, initValue: null})],
        [
            "chart",
            (_, next) =>
                RevalidateChartService({
                    props: {tile, theme},
                    chartRef,
                    next,
                }),
        ],
        [
            "data",
            ({chart}, next) => {
                const data = ["PieChartTile", "DonutChartTile", "GaugeTile", "VennDiagramTile"].includes(tile.$type)
                    ? chart.series[0].data
                    : sort(
                          unique(chart.series, (s) => s.name + s.color),
                          (item) => item.userOptions.legendIndex
                      );

                if (size.width < 7)
                    return next(
                        data.map((item) =>
                            mapLegendItem({
                                item,
                                tile,
                                formatters,
                                isCompare,
                                options,
                            })
                        )
                    );

                const numberPerCol = Math.floor(legendRoom.legendHeight / (options.legend.itemStyle.fontSize + 7));

                return next(
                    groupLegendItems({
                        numberPerCol,
                        tile,
                        formatters,
                        isCompare,
                        options,
                        data,
                    })
                );
            },
        ],
        ({data, hiddenSeries, legendContainerRef, scrollLeft, chart}) => {
            const renderComparison = ["PieChartTile", "DonutChartTile"].includes(tile.$type) && isCompare && tile.style.legendStyle.legendContent?.startsWith("LabelAnd") ? cRenderComparison({chart, formatters}) : undefined;
            return (
                <div
                    className={cx(`legend-top-bottom-vertical-display-a55 verb-tile-legend verb-tile-legend-${legendRoom.paddingTop ? "top" : "bottom"}`, size.width < 7 ? "vertical-scroll" : "horizontal-scroll")}
                    style={{
                        height: `${legendRoom.legendHeight}px`,
                        top: legendRoom.paddingTop ? "0" : "",
                        bottom: legendRoom.paddingBottom ? "0" : "",
                        justifyContent: legendContainerRef.get()?.scrollWidth - legendContainerRef.get()?.clientWidth > 0 ? "" : "center",
                    }}
                >
                    {size.width < 7 ? (
                        <div className="legend-container">
                            <div className="legend-scroller-wrapper">
                                {data.map((item, index) => (
                                    <div
                                        className="legend-item-group"
                                        key={index}
                                        style={{
                                            width: `${maxBy(data, (item) => item.textWidth + 20)}px`,
                                            margin: "0 auto",
                                        }}
                                    >
                                        {LegendItemView({
                                            getMaxLegendSubText: () => maxBy(data, (item) => item.subTextPadding),
                                            chart,
                                            tile,
                                            options,
                                            theme,
                                            hiddenSeries,
                                            series: item.value,
                                            formatters,
                                            renderComparison,
                                        })}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <>
                            <div
                                className="legend-container"
                                ref={(elem) => {
                                    legendContainerRef.set(elem);
                                    setTimeout(() => {
                                        if (scrollLeft.value === null) {
                                            scrollLeft.onChange(0);
                                        }
                                    });
                                }}
                                onScroll={() => {
                                    scrollLeft.onChange(legendContainerRef.get().scrollLeft);
                                }}
                            >
                                {data.map((item, index) => (
                                    <div
                                        className="legend-item-group"
                                        key={index}
                                        style={{
                                            width: item.groupWidth,
                                        }}
                                    >
                                        {item.values.map((series, index) =>
                                            LegendItemView({
                                                getMaxLegendSubText: () => item.subTextPadding,
                                                chart,
                                                tile,
                                                options,
                                                theme,
                                                formatters,
                                                renderComparison,
                                                hiddenSeries,
                                                series,
                                                key: index,
                                            })
                                        )}
                                    </div>
                                ))}
                            </div>

                            {scrollLeft.value !== null &&
                                LegendControl({
                                    legendContainerRef,
                                    scrollLeft,
                                })}
                        </>
                    )}
                </div>
            );
        }
    );
