import {getChildGroup} from "./pivot-table-common";

const attachAgg = (headers, item, ret, aggregates, deep) => {
    const childGroup = getChildGroup(headers[deep + 1], item.id);
    if (childGroup) {
        for (let item of childGroup.items) {
            attachAgg(headers, item, ret, aggregates, deep + 1);
        }
    } else {
        let items = [];
        for (let j = 0; j < aggregates.length; j++) {
            let aggregate = aggregates[j];
            items.push({
                agg: true,
                name: aggregate.friendlyName,
                columnName: aggregate.columnName,
                aggregateName: aggregate.aggregateName,
                rowSpan: 1,
                aggIndex: j,
                parent: item,
            });
        }

        ret.push({
            groupID: "agg",
            items,
            parentID: item.id,
        });
    }
};

const attachAggregates = (headers, aggregates, showGrandTotal) => {
    let ret = [];

    for (let group of headers[0]) {
        for (let item of group.items) {
            if (item.name != "Grand Total") {
                attachAgg(headers, item, ret, aggregates, 0);
            }
        }
    }

    if (showGrandTotal) {
        ret.push({
            groupID: "agg",
            items: aggregates.map((aggregate, index) => ({
                agg: true,
                name: aggregate.friendlyName,
                columnName: aggregate.columnName,
                rowSpan: 1,
                aggIndex: index,
                parent: "Grand Total",
            })),
            parentID: "Grand Total",
        });
    }

    return ret;
};

export const aggregatesMapping = {
    attachAggregates: attachAggregates,
};
