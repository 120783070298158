import { cx } from "emotion";
import * as React from "react";
import { cs } from "../../../../../common/react/chain-services";
import "./dialog.scss";

export const DIALOG_STYLES = {
    SCALE_IN: 'scale-in',
    SLIDE_UP: 'slide-up'
}

export const Dialog = ({
    title, noHeader = false, width, content, resolve, strong,
    disabledClickOverlay = false,
    active, className, customizeDialog, customCloseIcon,
    forceShowCloseButton = false, styleType = DIALOG_STYLES.SCALE_IN,
}) => {
    return cs(
        ({}) => (
            <div className={cx("dialog-4dj", className, {active}, styleType)} onMouseDown={(e) => {
                e.stopPropagation()
                return !strong && !disabledClickOverlay && resolve(null);
            }}>
                <div className="overlay" />

                <div className="vertical-align-box">
                    <div 
                        className="box" 
                        style={{width}} 
                        onClick={(e) => e.stopPropagation()} 
                        onMouseDown={(e) => e.stopPropagation()}
                    >
                        {!customizeDialog ? (
                            <>
                                {!noHeader && <div className="header dialog-header">
                                    {title}

                                    {(!strong || forceShowCloseButton) && (
                                        <div className="close" onClick={() => resolve(null)}>
                                            {customCloseIcon ? customCloseIcon : <CloseIcon />}
                                        </div>
                                    )}
                                </div>}
                                <div className={cx("main", {'no-header': noHeader})}>
                                    {content}
                                </div>
                            </>
                        ) : content}
                    </div>
                </div>
            </div>
        )
    );
}


const CloseIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg"
        width="15" height="15" viewBox="0 0 15 15">
        <path
            fill="#919EB0"
            data-name="Icon material-close" d="M22.5,9.011,20.989,7.5,15,13.489,9.011,7.5,7.5,9.011,13.489,15,7.5,20.989,9.011,22.5,15,16.511,20.989,22.5,22.5,20.989,16.511,15Z" transform="translate(-7.5 -7.5)" />
    </svg>

)