import React from "react";
import {cs} from "./chain-services";
import {Static2} from "./static-2";
import {UseState} from "./use-state";

export const UseStaticState = ({next: rootNext, ...stateConfig}) => {
    return cs(
        ["state", (_, next) => UseState({...stateConfig, next})],
        [
            "staticState",
            ({state}, next) => {
                return Static2({
                    getInitValue: () => state.value,
                    next,
                });
            },
        ],
        ({state, staticState}) => {
            return rootNext({
                ...state,
                onChange: (...params) => {
                    staticState.set(params[0]);
                    return state.onChange(...params);
                },
                change: (fn, cb) => {
                    return state.change((value) => {
                        const newValue = fn(value);
                        staticState.set(newValue);
                        return newValue;
                    }, cb);
                },
                getStaticValue: () => staticState.get(),
            });
        }
    );
};
