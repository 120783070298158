import {ffToTextInput} from "../../../../../../../common/form/ff-to-text-input";
import {TextInput} from "../../../../../../../common/form/text-input/text-input";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {Form2} from "@common/react/cs-form/form2";
import {Load2} from "@common/react/load2";
import {dataSourceFormConfig} from "../../../common/data-source-form-config";
import {DsFolderField} from "../../../common/folders-dialog/ds-folder-field";

export const FlatFileNameLocation = ({dataSource}) =>
    cs(
        consumeContext("apis"),
        [
            "form",
            ({apis}, next) =>
                Form2({
                    data: dataSource,
                    fields: dataSourceFormConfig({apis, dataSource}),
                    next,
                }),
        ],
        ({form}) => {
            return (
                <>
                    <div className="label">Import Configuration</div>

                    {TextInput({
                        label: "Display Name",
                        ...ffToTextInput(form.field("name")),
                    })}

                    {DsFolderField({state: form.field("folderID").state})}
                </>
            );
        }
    );
