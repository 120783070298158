const {Load2} = require("@common/react/load2");
const {provideContext} = require("@common/react/context");
const {consumeContext} = require("@common/react/context");
const {cs} = require("@common/react/chain-services");

const loadAuthEndpoint = ({next}) =>
    cs(
        consumeContext("apis"),
        consumeContext("routing"),
        [
            "authEndpoint",
            ({apis, routing}, next) =>
                Load2({
                    _key: routing.params?.envId,
                    fetch: () => apis && apis.authEndpoint && apis.authEndpoint.getAuthEndpoint(),
                    next,
                }),
        ],
        ({authEndpoint}) => provideContext("authEndpoint", authEndpoint, next)
    );
exports.loadAuthEndpoint = loadAuthEndpoint;
