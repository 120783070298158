import * as React from "react";
import "./edit-container-tile.scss";

import {cs} from "@common/react/chain-services";
import {ChangeQueue1} from "@common/react/change-queue-1";
import {consumeContext, provideContext} from "@common/react/context";
import {fragments} from "@common/react/fragments";

import {loadAutoRefreshProvider} from "@common/ui-components/live/live-dashboard/live-grid/auto-refresh-controller";
import {LoadingIndicator} from "@common/ui-components/loading-indicator/loading-indicator";

import {getPath, setPath} from "@common/utils/arr-path";
import {equalDeep} from "@common/utils/objects";

import {SingleEditorRegister} from "../../../common/single-editor/single-editor";

import {loadViewAsContext} from "../../edit/layout/view-as-context";
import {AddingTile} from "../../edit/tabs/tiles/adding-tile/adding-tile";
import {loadTheme} from "../../tile/edit/edit-tile";

import {spc} from "@common/react/state-path-change";
import {UseState} from "@common/react/use-state";
import {ConvertToSharedTileModal} from "../../../common/convert-to-shared-tile-modal/convert-to-shared-tile-modal";
import {ToastWithAction} from "../../../common/toast/toast-service";
import {loadSharedTileFolders} from "../../../dashboard/common/load-shared-tile-folders";
import {DeleteTileModal} from "../../common/delete-tile-modal";
import {EditTileLayout} from "../../common/edit-tile-layout/edit-tile-layout";
import {createGetFieldInfo} from "../../common/field-info/create-get-field-info";
import ViewCollections from "../../shared-tile/view-collections/view-collections";
import {EditContainerTilePanel} from "./left-panels/edit-container-tile-panel";
import {EditSubTilePanel} from "./left-panels/edit-sub-tile-panel/edit-sub-tile-panel";
import {LeftPanels} from "./left-panels/left-panels";

export const EditContainerTile = ({}) =>
    cs(
        consumeContext("apis"),
        consumeContext("routing"),
        consumeContext("collection"),
        ({}, next) => loadSharedTileFolders({next}),
        (_, next) => provideContext("editingMode", true, next),
        (_, next) => loadViewAsContext({next}),
        [
            "convertToSharedTileDialog",
            (_, next) =>
                ConvertToSharedTileModal({
                    next,
                }),
        ],

        ({collection}, next) =>
            loadAutoRefreshProvider({
                next,
                collection: collection.value,
                isSDKDashboard: false,
            }),
        [
            "convertToShared",
            ({convertToSharedTileDialog, collection}, next) => {
                return next(async (gridLoc) => {
                    const sharedTile = await convertToSharedTileDialog.show({
                        tile: {
                            ...gridLoc.tile,
                            modelID: collection.value?.modelID,
                        },
                        defaultRowSpan: gridLoc.rowSpan,
                        defaultColSpan: gridLoc.colSpan,
                    });

                    if (sharedTile) {
                        spc(collection, ["gridLocations", (gl) => gl.tile.id === gridLoc.tile.id], (gl) => ({
                            ...gl,
                            tile: sharedTile,
                        }));
                    }
                });
            },
        ],
        ({routing, collection}, next) =>
            fragments(
                collection?.value
                    ? SingleEditorRegister({
                          goBack: () => routing.goto("dashboard", {tab: "collections"}),
                          getItemId: () => routing.params.colId,
                          otherButtons: [
                              {
                                  label: "Go to Preview",
                                  function: () => {
                                      window.open(`/#/env/${routing.params.envId}/collection/${routing.params.colId}/preview`, "_blank");
                                  },
                              },
                          ],
                          item: {
                              type: "collection",
                              parent: "Collections",
                              name: collection?.value.name,
                              id: collection?.value.id,
                          },
                      })
                    : null,
                next()
            ),
        consumeContext("viewAsContext"),
        (_, next) => provideContext("isContainerTile", true, next),
        (_, next) => createGetFieldInfo({next}),
        ["viewAs", ({viewAsContext}, next) => next(viewAsContext?.viewAs?.value ?? null)],
        [
            "savingQueue",
            ({apis, collection}, next) =>
                ChangeQueue1({
                    fetchLatest: () => apis.collection.getCollection(collection.value.id),
                    save: apis.collection.upsertCollection,
                    next,
                }),
        ],

        [
            "theme",
            ({apis, collection, viewAs}, next) =>
                loadTheme({
                    collection: collection.value,
                    viewAs,
                    apis,
                    next,
                }),
        ],
        [
            "removeTile",
            ({remoteCollection, savingQueue, routing, collection}, next) => {
                const gridLoc = getPath(collection.value, ["gridLocations", (gl) => gl.tile.id === routing.params.cTileId]);
                const isSharedTile = gridLoc?.tile?.isShared;
                return DeleteTileModal({
                    next,
                    isRemove: isSharedTile,
                    onConfirm: async (tile) => {
                        if (!tile) {
                            return;
                        }
                        const pathUpdate = ["gridLocations"];
                        const gridLocations = getPath(collection.value, pathUpdate);
                        const newCol = await savingQueue.push((c) =>
                            setPath(
                                c,
                                pathUpdate,
                                gridLocations.filter((t) => t.tile.id != tile.id)
                            )
                        );
                        if (newCol) {
                            collection.reload?.();
                            routing.goto("edit-collection");
                        }
                    },
                });
            },
        ],
        ({collection, theme, routing, removeTile, convertToShared}, next) => {
            const gridLoc = getPath(collection.value, ["gridLocations", (gl) => gl.tile.id === routing.params.cTileId]);
            return collection.value && theme ? (
                next()
            ) : (
                <EditTileLayout
                    {...{
                        editing: {
                            label: "Container Title",
                            title: collection?.value?.name,
                            commands: [
                                {
                                    label: "Convert to Shared",
                                    onClick: () => {
                                        convertToShared(gridLoc);
                                    },
                                },

                                {
                                    label: "Delete",
                                    className: "delete-action",
                                    onClick: () => {
                                        removeTile?.(getPath(gridLoc?.tile));
                                    },
                                },
                            ],
                        },
                        className: "edit-container-tile-fxc",
                        back: {
                            onClick: () => routing.goto("edit-collection"),
                            label: "Done editing container tile",
                        },
                        main: <div className="edit-tile-main-52s">{LoadingIndicator()}</div>,
                        syncState: {
                            type: "saved",
                            onClick: () => {},
                        },
                    }}
                />
            );
        },

        ({collection, routing}, next) => {
            const cTile = getPath(collection.value, ["gridLocations", (gl) => gl.tile.id === routing.params.cTileId, "tile"]);

            if (collection.value && !cTile) {
                routing.goto("edit-collection");
                return null;
            }

            return next();
        },

        [
            "addingTilePanel",
            ({tiles, collection, routing, savingQueue}, next) =>
                AddingTile({
                    isCallFromEditContainer: true,
                    containerTileID: routing.params.cTileId,
                    collection,
                    next,
                    updateFunc: async (newCol) => {
                        const _newCol = await savingQueue.push(() => newCol);
                        collection.onChange(_newCol);
                        return _newCol;
                    },
                }),
        ],
        [
            "editContainerTilePanel",
            ({collection, savingQueue, theme, addingTilePanel, routing}, next) =>
                EditContainerTilePanel({
                    collection,
                    theme,
                    addingTilePanel,
                    next,
                    cTileId: routing.params.cTileId,
                    updateFunc: async (newCol) => {
                        const _newCol = await savingQueue.push(() => newCol);
                        collection.onChange(_newCol);
                        return _newCol;
                    },
                }),
        ],

        [
            "editSubTilePanel",
            ({collection, savingQueue, theme, editContainerTilePanel, viewAs, routing}, next) => {
                const tile = getPath(collection.value, ["gridLocations", (gl) => gl.tile.id === routing.params.cTileId, "tile"]);
                const isSharedTile = tile?.isShared;
                const getSubTilePath = (tileId) => ["gridLocations", (gl) => gl.tile.id === routing.params.cTileId, "tile", "tiles", (subTile) => subTile.id == tileId];
                return EditSubTilePanel({
                    collection,
                    savingQueue,
                    viewAs,
                    theme,
                    editContainerTilePanel,
                    cTileId: routing.params.cTileId,
                    getSubTilePath,
                    updateFunc: async (newTile, tileID) => {
                        const newCol = await savingQueue.push((c) =>
                            setPath(c, getSubTilePath(tileID), {
                                ...newTile,
                                isShared: isSharedTile,
                                modelID: tile?.modelID ?? "00000000-0000-0000-0000-000000000000",
                            })
                        );
                        collection.onChange(newCol);
                        return getPath(newCol, getSubTilePath(tileID));
                    },
                    next,
                });
            },
        ],
        ["interactions", (_, next) => UseState({initValue: null, next})],

        ({routing, savingQueue, addingTilePanel, editContainerTilePanel, editSubTilePanel, collection, removeTile, interactions, convertToShared}) => {
            const gridLoc = getPath(collection.value, ["gridLocations", (gl) => gl.tile.id === routing.params.cTileId]);
            const isSharedTile = gridLoc?.tile?.isShared;

            return (
                <EditTileLayout
                    {...{
                        isSharedTile,
                        editing: {
                            label: gridLoc?.tile?.title,
                            title: collection?.value?.name,
                            commands: [
                                {
                                    label: "Convert to Shared",
                                    onClick: () => {
                                        convertToShared(gridLoc);
                                    },
                                    hide: isSharedTile,
                                },
                                {
                                    label: "Go to Shared Tile View",
                                    onClick: () => {
                                        routing.goto("edit-shared-tile", {sharedTileId: gridLoc?.tile?.id});
                                    },
                                    hide: !isSharedTile,
                                },
                                {
                                    label: isSharedTile ? "Remove" : "Delete",
                                    className: "delete-action",
                                    onClick: () => removeTile?.(gridLoc?.tile),
                                },
                            ].filter((item) => !item.hide),
                        },
                        className: "edit-container-tile-fxc",
                        back: {
                            onClick: () => routing.goto("edit-collection"),
                            label: "Done editing container tile",
                        },
                        leftPanel: {
                            content: <LeftPanels editSubTilePanel={editSubTilePanel} overrideLeftPanels={[editContainerTilePanel, addingTilePanel]} />,
                            width: editSubTilePanel.leftPanelWidth,
                        },
                        main: editSubTilePanel.main(),
                        rightPanel: isSharedTile && {
                            overlapHeader: true,
                            content: interactions.value?.name === "view-collections" && <ViewCollections onClose={() => interactions.onChange(null)} sharedTile={gridLoc?.tile} />,
                        },
                        syncState: {
                            type: savingQueue.executing
                                ? "saving"
                                : equalDeep(editSubTilePanel?.editTileLogic?.tile?.value, editSubTilePanel?.remoteSubTile?.value)
                                ? "saved"
                                : "unsaved",
                            onClick: editSubTilePanel?.editTileLogic?.form?.showErrors,
                        },
                    }}
                />
            );
        }
    );
