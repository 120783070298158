import {findMaxE, sort, sum, unique} from "../../../../utils/collections";
import {maxBy} from "../../../../utils/math-util";
import {getTextWidth} from "../../render-chart/render-chart-helper";
import {chain} from "@common/utils/fs";

// the room cases match legend cases in scatter-legend-item.jsx
export const getScatterLegendRoom = ({tile, series, chartWidth, chartHeight, options}) => {
    if (!tile.style.legendStyle.legendShown || (!tile.colorGroupField && !tile.shapeGroupField)) {
        return;
    }

    const {legendPosition, legendShown} = tile.style.legendStyle || {};

    if (["Left", "Right"].includes(legendPosition)) {
        return getLeftRightRoom({
            tile,
            series,
            legendPosition,
            fontSize: options.legend.itemStyle.fontSize,
        });
    }

    if (legendPosition === "Top") {
        return {paddingTop: 26};
    }

    if (legendPosition === "Bottom") {
        return {paddingBottom: 26};
    }

    const legendHeight = getLegendAreaHeight({
        tile,
        series,
        chartWidth,
        chartHeight,
        fontSize: options.legend.itemStyle.fontSize,
    });

    if (legendPosition === "TopVertical") {
        return {
            paddingTop: legendHeight,
            legendWidth: "100%",
            legendHeight: legendHeight,
        };
    }

    if (legendPosition === "BottomVertical") {
        return {
            paddingBottom: legendHeight,
            legendWidth: "100%",
            legendHeight: legendHeight,
        };
    }
};

const getLeftRightRoom = ({tile, series, legendPosition, fontSize}) => {
    // const cases = [
    //     {
    //         condition: tile.colorGroupField != null || tile.shapeGroupField != null,
    //         getTexts: getLegendItemsTextWithGroups,
    //     },
    //     // {
    //     //     condition: tile.sizeFields == null && tile.colorGroupField == null && tile.shapeGroupField == null,
    //     //     getTexts: getLegendItemsTextNoGroupsAndNoSize,
    //     // },
    //     // {
    //     //     condition: tile.sizeFields != null && tile.colorGroupField == null && tile.shapeGroupField == null,
    //     //     getTexts: getLegendItemsTextNoGroupsWithSize,
    //     // },
    // ];

    // const getTexts = cases.find((c) => c.condition).getTexts;
    const getTexts = getLegendItemsTextWithGroups;

    const legendWidth = getLegendWidth(getTexts({tile, series}), fontSize);
    if (legendPosition === "Left") {
        return {paddingLeft: legendWidth, legendWidth};
    }
    return {paddingRight: legendWidth + 10, legendWidth: legendWidth};
};

// const getLegendItemsTextNoGroupsAndNoSize = ({tile, series, }) => {
//     const mainSeries = series.find((s) => !s.isCompare);
//     return mainSeries.data.map((dp) => {
//         // return dp.name;
//         return mainSeries.name;
//     })
// };
//
// const getLegendItemsTextNoGroupsWithSize = ({tile, series, formatters}) => {
//     const mainSeries = series.find((s) => !s.isCompare);
//     return mainSeries.data.map((dp) => {
//         return `${mainSeries.name} ${formatters.measurementFormatters[mainSeries.sizeStack][0](dp.z)}`;
//     })
// };

const getLegendItemsTextWithGroups = ({tile, series}) => {
    const colorGroups = unique(series.map((s) => s.colorGroupName));
    const shapeGroups = unique(series.map((s) => s.shapeGroupName));
    return [...colorGroups, ...shapeGroups].map((group) => {
        return group;
    });
};

const getLegendWidth = (texts, fontSize) => {
    return maxBy(texts.map((t) => getTextWidth(fontSize, t))) + 30; // 30 is space for the icon before text and paddings
};

const getLegendAreaHeight = ({tile, series, chartWidth, chartHeight, fontSize}) => {
    const colorGroups = getScatterGroupsForLegend({series, groupNameAttr: "colorGroupName"});
    const shapeGroups = getScatterGroupsForLegend({series, groupNameAttr: "shapeGroupName"});
    const referenceLineSeries = series
        .filter((s) => (
            ["ConstantValueReferenceLine", "AggregateValueReferenceLine", "IndicatorReferenceLine"].includes(s.customType)
        ))
        .map((s) => ({name: s.name}))

    let maxHeight = (chartHeight * 20) / 100;
    let numberPerCol = 0;
    let currentHeight = 0;
    let totalLegendWidth = Infinity;
    let maxNumberPerCol = 10;

    // there are 3 separate sections of legend items (color, shape and reference lines).
    // if only one type is present, the width available should be whole chart width.
    // if there are multi types, the width available for each type should be chart width divided by the number of types.
    const numberOfGroupTypes = [colorGroups.length, shapeGroups.length, referenceLineSeries.length].filter((v) => v > 0).length;
    const widthAvailable = chartWidth / numberOfGroupTypes;
    const mostItems = findMaxE([colorGroups, shapeGroups, referenceLineSeries], (g) => g.length);

    while (1) {
        if (totalLegendWidth < widthAvailable || maxNumberPerCol < numberPerCol) {
            return Math.max(Math.min(currentHeight, maxHeight), 30);
        }
        numberPerCol++;
        currentHeight = numberPerCol * (fontSize + 7);
        const columns = groupScatterLegendItems({
            numberPerCol,
            items: mostItems,
            fontSize
        });
        totalLegendWidth = sum(columns, g => g.columnWidth);
    }
};

export const groupScatterLegendItems = ({numberPerCol, items, fontSize}) => {
    let columns = [];

    items.forEach((item) => {
        if (columns.length === 0) {
            columns.push([item]);
        } else {
            if (columns[columns.length - 1].length < numberPerCol) {
                columns[columns.length - 1].push(item);
            } else {
                columns.push([item]);
            }
        }
    });

    return columns.map((items, index) => ({
        items,
        columnWidth: getLegendWidth(
            items.map((e) => e.name),
            fontSize
        ), // + (columns.length - 1 === index ? 0 : 40), // last column should be 40px wider
    }));
};

export const getScatterGroupsForLegend = ({series, groupNameAttr, areSeriesFromChart}) => {
    return chain(
        series,
        (_) =>
            _.map((s) => {
                const valuesContainer = areSeriesFromChart ? s.userOptions : s;
                return {
                    name: valuesContainer[groupNameAttr],
                    ...{
                        colorGroupName: {
                            color: valuesContainer.marker.color,
                        },
                        shapeGroupName: {
                            shape: valuesContainer.marker.symbol,
                        },
                    }[groupNameAttr],
                };
            }),
        (_) => _.filter(({name}) => name != null),
        (_) => unique(_, ({name}) => name),
        (_) => sort(_, ({name}) => (isNaN(name) ? name.toLowerCase() : name.toString())) // sort in alphabetical order
    );
};
