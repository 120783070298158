import React from "react";
import {cs} from "@common/react/chain-services";
import {DropdownSelect2} from "@common/ui-components/dropdown-select/dropdown-select2";
import {stateToSelect} from "@common/ui-components/form/state-to-select";
import {scope} from "@common/react/scope";
import {RadioLine} from "../../../../../../../common/radio-line/radio-line";
import {TextInput} from "../../../../../../../../../../common/form/text-input/text-input";
import {CheckboxLine} from "../../../../../../../common/checkbox-line/checkbox-line";
import {spc} from "@common/react/state-path-change";
import {UseState} from "@common/react/use-state";
import {rColumnControl} from "../../../common/column-control/column-control";
import {removeIndex} from "@common/utils/collections";
import "./split-configuration.scss";
import {DnDList} from "../../../../../../../common/dnd-list/dnd-list";
import {ffToDropdown} from "../../../../../../../../../../common/form/ff-to-dropdown";
import {keepOnly} from "@common/utils/objects";
import {rColumnValueSelectFromStepOutput} from "../../../../../common/column-value-select-from-step-output/column-value-select-from-step-output";
import {rNewColumnNameInput} from "../../../common/new-column-name-input";

const delimiters = [
    {value: ",", text: "Comma"},
    {value: ";", text: "Semicolon"},
    {value: " ", text: "Space"},
    {value: "\t", text: "Tab"},
    {value: null, text: "Other"},
];

export const SplitConfiguration = ({textManipulation, inputStep, model, form}) =>
    cs(
        [
            "state1",
            (_, next) => {
                return UseState({
                    initValue:
                        textManipulation?.value.splitTextOn?.delimiter == null
                            ? delimiters[0]
                            : delimiters.filter((x) => x.value === textManipulation?.value.splitTextOn.delimiter).length == 1
                            ? delimiters.filter((x) => x.value === textManipulation?.value.splitTextOn.delimiter)[0]
                            : delimiters.filter((x) => x.text === "Other")[0],
                    next,
                });
            },
        ],
        ({state1}) => {
            return (
                <div className="split-configuration-9re">
                    <div className="config-group">
                        <div className="label">Input Column</div>
                        {(() => {
                            const dropdownProps = ffToDropdown(form.field(["textManipulation", "inputValue"]));
                            return rColumnValueSelectFromStepOutput({
                                label: "Column",
                                columns: inputStep.outputColumns,
                                model,
                                disableStaticValue: true,
                                filterColumnFn: (c) => ["Text", "IDUUID"].includes(c.dataType),
                                isValueNotAllowed: (c) => !["Text", "IDUUID"].includes(c.dataType),
                            })({
                                state: {
                                    value: dropdownProps.value,
                                    onChange: dropdownProps.onChange,
                                },
                                ...keepOnly(dropdownProps, ["domRef", "hasError", "errorMessage"]),
                            });
                        })()}
                    </div>

                    <div className="config-group">
                        <div className="label">Split Text On</div>

                        <div className="radio-group">
                            {delimiters.map((item) =>
                                cs(() => {
                                    return (
                                        <div className="radio-item">
                                            {RadioLine({
                                                selected:
                                                    item.value === state1.value.value ||
                                                    (item.text === "Other" && delimiters.filter((x) => x.value === state1.value.value).length == 0),
                                                label: item.text,
                                                onClick: () => {
                                                    spc(textManipulation, ["splitTextOn"], (s) => ({
                                                        $type: "SplitTextOnDelimiter",
                                                        delimiter: item.value,
                                                    }));
                                                    state1.onChange(item);
                                                },
                                            })}

                                            {state1.value.text === "Other" && item.text === "Other" && (
                                                <>
                                                    {TextInput({
                                                        state: scope(textManipulation, ["splitTextOn", "delimiter"]),
                                                    })}

                                                    {CheckboxLine({
                                                        label: "Case Sensitive",
                                                        state: scope(textManipulation, ["splitTextOn", "caseSensitive"]),
                                                    })}
                                                </>
                                            )}
                                        </div>
                                    );
                                })
                            )}
                        </div>
                    </div>

                    <div className="config-group">
                        <div className="label">New Columns</div>

                        {newColumns({
                            state: scope(textManipulation, ["newColumns"]),
                            form,
                        })}
                    </div>

                    <div className="config-group">
                        <div className="label">Leftover values from split</div>

                        {DropdownSelect2({
                            label: "Action",
                            list: [
                                {value: "Discard", label: "Discard"},
                                {
                                    value: "AddToLastColumn",
                                    label: "Add leftover values to last column",
                                },
                            ],
                            valueToLabel: (v) => v.label,
                            ...stateToSelect(scope(textManipulation, ["leftoverValues"]), ["value"]),
                        })}
                    </div>
                </div>
            );
        }
    );

const newColumns = ({state, form}) => {
    // const columnNameInput = ({state}) => cs(
    //     ["debounce", ({}, next) => DebounceCache({
    //         state,
    //         next,
    //     })],
    //     ({debounce}) => (
    //         TextInput({
    //             label: "Column Name",
    //             state: debounce.state,
    //             onBlur: debounce.flush,
    //         })
    //     )
    // );

    const columnNameInput2 = ({path}) =>
        rNewColumnNameInput({
            form,
            newColumnNamePath: ["textManipulation", "newColumns", ...path],
        });

    return cs(
        [
            "dnd",
            (_, next) =>
                DnDList({
                    list: state.value,
                    onChange: state.onChange,
                    renderDrag: ({index}) =>
                        rColumnControl({
                            onStartDragging: () => {},
                            dragging: true,
                            // columnSelector: columnNameInput({
                            //     state: scope(state, [index, "name"]),
                            // }),
                            columnSelector: columnNameInput2({
                                path: [index, "name"],
                            }),
                            onRemove: () => state.onChange(removeIndex(index, state.value)),
                        }),
                    next,
                }),
        ],
        ({dnd}) => (
            <>
                <div className="columns">
                    {dnd.list?.map(({index: ci, ref, start}) =>
                        ci != null ? (
                            <div className="column" key={ci} ref={ref}>
                                {rColumnControl({
                                    onStartDragging: start,
                                    // columnSelector: columnNameInput({
                                    //     state: scope(state, [ci, "name"]),
                                    // }),
                                    columnSelector: columnNameInput2({
                                        path: [ci, "name"],
                                    }),
                                    onRemove: () => state.onChange(removeIndex(ci, state.value)),
                                })}
                            </div>
                        ) : (
                            <div className="column blank" key={-1} />
                        )
                    )}
                </div>

                <button className="btn-add" onClick={() => state.onChange([...(state.value || []), {}])}>
                    Add
                </button>
            </>
        )
    );
};
