export const numberFilterControlTypes = [
    {
        label: "Input",
        name: "Input",
    },
    {
        label: "Slider",
        name: "Slider",
    },
];
