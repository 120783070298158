import * as React from "react";

import {chain} from "../../../../utils/fs";
import {scope} from "../../../../react/scope";
import {Watch} from "../../../../react/watch";
import {cs} from "../../../../react/chain-services";
import {UseState} from "../../../../react/use-state";
import {fragments} from "../../../../react/fragments";
import {spc} from "../../../../react/state-path-change";
import {StateProxy} from "../../../../react/state-proxy";

import {setPath} from "../../../../utils/arr-path";
import {equalDeep} from "../../../../utils/objects";
import {waitTimeout} from "../../../../utils/wait-timeout";
import {addDate, addMonth, compares, today} from "../../../../utils/dates/date-object";
import {parseDateLong} from "../../../formats/parse-date-long";
import {formatDateLong} from "../../../formats/format-date-long";
import {DualCalendar} from "../../../calendars/dual-calendar/dual-calendar";

import {applyAutoRange, applyRangesMod} from "./apply-ranges-mod";
import {InlineCalendar} from "./inline-calendar/inline-calendar";
import {CalendarPopup} from "./calendar-popup/calendar-popup";
import {flatten1} from "../../../../utils/collections";

export const DateRangesPicker = ({next, ranges, size, onChange}) =>
    cs(
        // (_, next) => {
        //     console.log(ranges);
        //     return next()
        // },
        [
            "state",
            (_, next) =>
                UseState({
                    getInitValue: () => ({
                        month: ranges[0].range?.from ?? addMonth(today(), -1),
                    }),
                    next,
                }),
        ],
        ["hover", (_, next) => UseState({next})],

        [
            "ranges1",
            ({hover, state}, next) =>
                next(
                    hover.value == null || !state.value.selecting
                        ? ranges
                        : applyRangesMod({
                              focus: hover.value,
                              selecting: state.value.selecting,
                              ranges,
                          })
                ),
        ],
        ({state}, next) =>
            fragments(
                ranges[0].range &&
                    Watch({
                        value: JSON.stringify(ranges[0].range?.from),
                        onChanged: async (newMonth, oldMonth) => {
                            try {
                                if (!equalDeep(newMonth, oldMonth)) {
                                    spc(state, ["month"], () => JSON.parse(newMonth) ?? addMonth(today(), -1));
                                }
                            } catch (e) {}
                        },
                    }),
                next()
            ),
        ({state, hover, ranges1}) => {
            return next({
                getRangeInputs: ({name, limit}, next) => {
                    const r = ranges1.find((r) => r.name === name);
                    let doms = {};
                    const rangeSelected = state.value.selecting?.[0] === name;
                    const selectedAttr = state.value.selecting?.[1];
                    const input = ({attr, delta = 0, next}) =>
                        cs(
                            [
                                "proxy",
                                (_, next) =>
                                    StateProxy({
                                        state: {
                                            value: r?.range?.[attr]
                                                ? formatDateLong(addDate(r.range[attr], delta))
                                                : formatDateLong(today()),
                                            onChange: (str) => {
                                                onChange({
                                                    name,
                                                    range: setPath(
                                                        r.range,
                                                        [attr],
                                                        !str ? null : chain(parseDateLong(str, limit), (_) => _ && addDate(_, -delta))
                                                    ),
                                                });
                                            },
                                        },
                                        condition: (str) => str && parseDateLong(str, limit),
                                        next,
                                    }),
                            ],
                            ({proxy}) => {
                                const range = ranges.find((r) => r.name === name);
                                const target = range?.range?.[attr] || range?.range?.[attr === "from" ? "to" : "from"];
                                return next({
                                    state: proxy.state,
                                    onFocus: () => {
                                        spc(state, ["month", "month"], () => target?.month);
                                        spc(state, ["month", "year"], () => target?.year);
                                        spc(state, ["selecting"], () => [name, attr]);
                                    },
                                    onClick: () => {
                                        spc(state, ["month", "month"], () => target?.month);
                                        spc(state, ["month", "year"], () => target?.year);
                                        spc(state, ["selecting"], () => [name, attr]);
                                    },
                                    onBlur: () => {
                                        proxy.flush();
                                    },
                                    outline: rangeSelected && selectedAttr === attr && r?.color,
                                    domRef: (r) => (doms[attr] = r),
                                    getRef: () => doms[attr],
                                });
                            }
                        );

                    return (
                        <>
                            {cs(
                                ["from", (_, next) => input({attr: "from", next})],
                                ["to", (_, next) => input({attr: "to", delta: 0, next})],
                                ({from, to}) =>
                                    next({
                                        from,
                                        to,
                                        rangeSelected,
                                        showCalendar: () =>
                                            InlineCalendar({
                                                month: addDate(r.range[selectedAttr], selectedAttr === "to" ? -1 : 0),
                                                // onHover,
                                                onSelect: async (date) => {
                                                    onChange({
                                                        name,
                                                        range: setPath(
                                                            r.range,
                                                            [selectedAttr],
                                                            addDate(date, selectedAttr === "to" ? 1 : 0)
                                                        ),
                                                    });

                                                    // await waitTimeout(0);
                                                    spc(state, ["selecting"], () => (selectedAttr === "from" ? [name, "to"] : null));
                                                },
                                                ranges,
                                            }),
                                        showCalendarPopup: (onClose) =>
                                            CalendarPopup({
                                                from,
                                                to,
                                                month: r.range ? addDate(r.range[selectedAttr], selectedAttr === "to" ? -1 : 0) : today(),
                                                // onHover,
                                                onSelect: async (date) => {
                                                    onChange({
                                                        name,
                                                        range: setPath(
                                                            r.range,
                                                            [selectedAttr],
                                                            addDate(date, selectedAttr === "to" ? 1 : 0)
                                                        ),
                                                    });

                                                    // await waitTimeout(0);
                                                    spc(state, ["selecting"], () => [name, "to"]);
                                                },
                                                ranges,
                                                onClose,
                                            }),
                                    })
                            )}

                            {Watch({
                                value: JSON.stringify(state.value.selecting),
                                onChanged: async () => {
                                    if (state.value.selecting && state.value.selecting[0] === name) {
                                        await waitTimeout(0);
                                        doms[state.value.selecting[1]]?.focus();
                                    }
                                },
                            })}
                        </>
                    );
                },
                renderDualCalendar: (config) => {
                    const {pickableRange} = config ?? {};
                    return DualCalendar({
                        month: scope(state, ["month"]),
                        onHover: hover.onChange,
                        ranges: ranges1.filter((r) => r.range),
                        selecting: state.value.selecting,
                        isDisabledDay: (day) => {
                            const {selecting} = state.value;
                            if (selecting?.[1] == "from") {
                                const currRange = ranges.find((r) => r.name == selecting[0]);
                                return currRange && currRange.range?.to ? compares.ge(day, currRange.range.to) : false;
                            }
                            if (selecting?.[1] == "to") {
                                const currRange = ranges.find((r) => r.name == selecting[0]);
                                return currRange && currRange.range?.from ? compares.le(day, currRange.range.from) : false;
                            }
                            return false;
                        },
                        selectabledRange: {
                            from: pickableRange?.allowedStartDate,
                            to: pickableRange?.allowedEndDate,
                        },
                        onSelect: async () => {
                            const selecting = state.value.selecting ?? [ranges[0].name, "from"];
                            const inputs = getInputs(ranges).filter((i) => i[0] == selecting[0]);
                            const inputIndex = inputs.findIndex((i) => i[0] == selecting[0] && i[1] == selecting[1]);

                            const newRange = setPath(
                                ranges.find((r) => r.name === selecting[0]).range,
                                [selecting[1]],
                                selecting[1] === "from" ? hover.value : addDate(hover.value, 1)
                            );

                            onChange({
                                name: selecting[0],
                                range: newRange,
                            });

                            await waitTimeout(0);
                            spc(state, ["selecting"], () => inputs[(inputIndex + 1) % inputs.length]);

                            // if (!state.value.selecting || !newRange.to || !newRange.from) {
                            //     await waitTimeout(0);
                            //     spc(state, ["selecting"], () => inputs[(inputIndex + 1) % inputs.length]);
                            // }
                        },
                    });
                },
            });
        }
    );

const getInputs = (ranges) =>
    chain(
        ranges,
        (_) =>
            _.map((item) => [
                [item.name, "from"],
                [item.name, "to"],
            ]),
        (_) => flatten1(_)
    );
