import {tileCategoriesConfig, TILE_CATEGORY_MAP} from "./tile-types";

export const categorizedTiles = (tiles) => {
    const tileKeys = tiles.map((t) => t.key);
    return tileCategoriesConfig
        .map((tcc) => {
            return {
                ...tcc,
                tiles: TILE_CATEGORY_MAP[tcc.key].filter((tk) => tileKeys.includes(tk)).map((tk) => tiles.find((t) => t.key === tk)),
            };
        })
        .filter((tcc) => tcc.tiles.length > 0);
};
