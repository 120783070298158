import {defaultDateRangeModes} from "../../../../common/logic/default-date-range-modes";
import {getCustomRange} from "./get-custom-range";

export const initDateFilterState = (filter) => {
    const {range, getComparingRange} = (() => {
        let options = {};

        if (filter.defaultOption === "Custom") {
            options = getCustomRange(filter.customRange);
        } else {
            const mode = defaultDateRangeModes.find((mode) => mode.name === filter.defaultOption);

            options = {
                range: mode?.getMainRange?.(),
                getComparingRange: mode?.getComparingRange,
            };
        }

        return {
            ...options,
            columns: filter.columns.filter((c) => c.default),
        };
    })();

    return {
        main: range && {
            from: range.from,
            to: {
                ...range.to,
                hours: 23,
                minutes: 59,
                seconds: 59,
            },
        },
        columns: filter.columns.filter((c) => c.default),
        ...(filter.compareEnabled &&
            filter.compareOnByDefault &&
            getComparingRange && {
                comparing: {
                    ...getComparingRange(),
                    to: {
                        ...getComparingRange().to,
                        hours: 23,
                        minutes: 59,
                        seconds: 59,
                    },
                },
                enableComparing: true,
            }),
        includeNullDates: false,
    };
};
