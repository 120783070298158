import { CalendarPicker } from "@common/form/date-picker/calendar-picker/calendar-picker";
import { Calendar1 } from "@common/form/date-picker/calendar1/calendar1";
import { DatePicker } from "@common/form/date-picker/date-picker";
import { plusDate } from "@common/logic/date-time/date-util";
import { formatDateTime, parseDateToObject } from "@common/logic/date-time/format-date-time";
import { parseDateTime } from "@common/logic/date-time/parse-date-time";
import { cs } from "@common/react/chain-services";
import { scope } from "@common/react/scope";
import { StyledClass } from "@common/react/styled-class";
import { UseState } from "@common/react/use-state";
import { FilterSubmitButtons } from "@common/ui-components/charts/table/table/header/filter-types/filter-submit-buttons";
import { DropdownSelectLive } from "@common/ui-components/live/filters/common/dropdown-select/dropdown-select-live";
import { NumberInputLive } from "@common/ui-components/live/live-dashboard/live-filters/common/number-input-live/number-input-live";
import { dateDiff, today, tomorrow, yesterday } from "@common/utils/dates/date-object";
import { css, cx } from "emotion";

const searchTypes = [
    {
        label: "Before",
        value: "IsBefore",
    },
    {
        label: "After",
        value: "IsAfter",
    },
    {
        label: "Date Is",
        value: "Is",
        default: true,
    },
];

export const HeaderFilterByDate = ({search, theme, close, columnIndex, defaultSelected, dateFilterSelectedDateType}) =>
    cs(
        [
            "state",
            (_, next) => {
                return UseState({
                    next,
                    initValue: {
                        search: defaultSelected?.search ?? formatDateTime({date: today()}),
                        searchType: defaultSelected?.searchType ?? searchTypes.find((s) => s.default).value,
                    },
                });
            },
        ],
        [
            "selectedDateType",
            ({state}, next) => {
                const getDefaultSelectedDateType = () => {
                    const diff = dateDiff(parseDateToObject(new Date(state.value.search)), today());

                    const ret = {
                        1: "Tomorrow",
                        0: "Today",
                        "-1": "Yesterday",
                    };

                    return ret[diff.toString()] || "On Date";
                };

                return UseState({next, initValue: dateFilterSelectedDateType.value ?? getDefaultSelectedDateType()});
            },
        ],
        [
            "cssClass",
            (_, next) => {
                const componentTheme = theme.general.components;
                return StyledClass({
                    next,
                    content: {
                        background: `${componentTheme.menuBackgroundColorRGB}`,
                        color: `${componentTheme.menuTextColorRGB}`,

                        "&:hover": {
                            background: `${componentTheme.menuHoverBackgroundColorRGB}`,
                        },

                        "&.selected": {
                            background: `${componentTheme.menuHoverBackgroundColorRGB}`,
                        },
                    },
                });
            },
        ],
        ({state, selectedDateType, cssClass}) => {
            const componentTheme = theme.general.components;

            const inputStyle = {
                border: `${componentTheme.inputBorderWidth}px solid ${componentTheme.inputBorderColorRGB}`,
                background: `${componentTheme.inputBackgroundColorRGB}`,
                color: `${componentTheme.inputTextColorRGB}`,
                borderRadius: `${componentTheme.inputCornerRadius}px`,
            };

            return (
                <>
                    <div className="dropdown-wrapper">
                        <div
                            className="dropdown-label"
                            style={{
                                color: theme.general.components.inputLabelTextColorRGB,
                            }}
                        >
                            Filter By
                        </div>

                        {DropdownSelectLive({
                            className: cx("dropdown-select"),
                            list: searchTypes.map((c) => c.value),
                            valueToLabel: (v) => searchTypes.find((r) => r.value == v)?.label,
                            isSelected: (v) => state.value.searchType == v,
                            onChange: (v) => {
                                state.change((state) => ({
                                    ...state,
                                    searchType: v,
                                }));
                            },
                            toggleStyle: inputStyle,
                            iconColor: componentTheme.inputIconColorRGB,
                            dropdownCss: cssClass,
                            borderRadius: componentTheme.menuCornerRadius,
                        })}

                        {DropdownSelectLive({
                            prioritizeSelect: false,
                            className: cx("dropdown-select"),
                            list: ["Today", "Tomorrow", "Yesterday", "On Date"],
                            closeAllOtherDropdownWhenOpen: false,
                            valueToLabel: (v) => v,
                            isSelected: (v) => selectedDateType.value == v,
                            onChange: (v) => {
                                const textToDateObj = {
                                    Today: today(),
                                    Tomorrow: tomorrow(),
                                    Yesterday: yesterday(),
                                    "On Date": parseDateTime(state.value.search)?.date ?? today(),
                                };

                                state.change((state) => ({
                                    ...state,
                                    search: formatDateTime({date: textToDateObj[v]}),
                                }));

                                selectedDateType.onChange(v);
                            },
                            toggleStyle: inputStyle,
                            iconColor: componentTheme.inputIconColorRGB,
                            dropdownCss: cssClass,
                            borderRadius: componentTheme.menuCornerRadius,
                        })}

                        {selectedDateType.value == "On Date" &&
                            CalendarPicker({
                                state: scope(state, ["search"]),
                                theme,
                            })}
                    </div>

                    <FilterSubmitButtons
                        disabled={(state.value.search || "").length == 0}
                        onReset={() => {
                            close?.();
                            search.searchColumn({
                                cIndex: columnIndex,
                                search: null,
                            });
                        }}
                        onSubmit={() => {
                            close?.();
                            dateFilterSelectedDateType.onChange(selectedDateType.value);
                            search.searchColumn({
                                cIndex: columnIndex,
                                search: state.value.search,
                                searchType: state.value.searchType,
                                $type: "DateTableSearch",
                            });
                        }}
                    />
                </>
            );
        }
    );
