import "./flap-filters-dialog.scss";

import { cx } from "emotion";
import React from "react";

import { cs } from "@common/react/chain-services";
import { consumeContext } from "@common/react/context";
import { csArr } from "@common/react/cs-arr";

import { InfoIconTooltip } from "@common/ui-components/charts/common/info-icon-tooltip/info-icon-tooltip";
import { filterTypes } from "@common/ui-components/live/live-dashboard/live-filters/filter-types/filter-types";
import { VerbDialogBodyScrollbar } from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";
import { keepOnly } from "@common/utils/objects";
import { Button } from "../../../../../../common/form/buttons/button/button";
import { DialogService } from "../../../common/dialog/dialog-service";

export const FlapFiltersDialog = ({filters: filters1, collection, filterForms, onRunReport, modalTitle, next: rootNext}) =>
    cs(
        consumeContext("theme"),
        [
            "modal",
            ({}, next) =>
                DialogService({
                    registryRender: true,
                    render: ({resolve}) => ({
                        title: modalTitle,
                        width: 560,
                        content: next({resolve}),
                    }),
                    next: rootNext,
                }),
        ],
        [
            "filters",
            (_, next) => {
                const dateTimeFilters = filters1.filter((f) => f.$type == "DateFilter");
                return csArr(
                    filters1.map(
                        (filter) => (next) =>
                            cs(
                                [
                                    "display",
                                    (_, next) =>
                                        filterTypes[filter.$type]({
                                            filter,
                                            collection,
                                            form: filterForms.getForm(filter.id),
                                            showSelectTimezone: collection.showTimezoneConversion && dateTimeFilters.length > 0,
                                            next,
                                        }),
                                ],
                                ({display}) =>
                                    next({
                                        ...display,
                                        label: filter.label,
                                        hidden: filter.hidden,
                                        id: filter.id,
                                        $type: filter.$type,
                                        ...keepOnly(filter, ["labelInfoIconText", "showLabelInfoIcon"]),
                                    })
                            )
                    ),
                    next
                );
            },
        ],
        ({theme, filters, modal}) => {
            const filters1 = filters.map((filter) => ({
                ...filter,
                lines: filter.control({size: "desktop"}),
            }));
            const renderFilter = (filter, i) => {
                const isHiddenFilter = filter?.hidden;

                return (
                    <div
                        className={cx("filter", {isHiddenFilter}, `verb-filter-${filter.$type.toLowerCase().replace("filter", "")}`)}
                        key={i}
                        x-filter-id={filter.id}
                        style={{
                            fontFamily: `"${theme.general.canvas.fontFamily || "Roboto"}", sans-serif`,
                        }}
                    >
                        {/* {!noLabel && ( */}
                        <div className="label verb-filter-label" style={{color: theme.general.canvas.fontColorRGB}}>
                            {filter.label}

                            {InfoIconTooltip({
                                theme,
                                width: 8,
                                color: theme.general.canvas.fontColorRGB || "#707070",
                                showInfoIcon: () => filter.showLabelInfoIcon && filter?.labelInfoIconText,
                                infoText: filter?.labelInfoIconText,
                            })}
                        </div>
                        {/* )} */}

                        {isHiddenFilter && (
                            <div className="hidden-filter">
                                Hidden Filter
                                <div className="tooltip">This filter will not show when embedded.</div>
                            </div>
                        )}

                        <div className="control">
                            {filter.lines.map((line, i) => (
                                <div className="line" key={i}>
                                    {line}
                                </div>
                            ))}
                        </div>
                    </div>
                );
            };

            return (
                <div className="flap-filters-dialog-8wq">
                    <VerbDialogBodyScrollbar>
                        <div
                            className="filters"
                            style={{
                                fontFamily: `"${theme.general.canvas.fontFamily || "Roboto"}", sans-serif`,
                            }}
                        >
                            {filters1.map(renderFilter)}
                        </div>
                    </VerbDialogBodyScrollbar>

                    <div className="buttons">
                        <Button
                            btnType="border"
                            onClick={() => {
                                filterForms.resetToDefault();
                                // modal.resolve();
                            }}
                        >
                            Clear All
                        </Button>
                        <Button
                            onClick={() => {
                                onRunReport();
                                modal.resolve();
                            }}
                            disabled={filterForms.invalid}
                        >
                            Submit
                        </Button>
                    </div>
                </div>
            );
        }
    );

export const FlapFilters = ({collection, filterForms, onRunReport, forceShowFlap = false}) =>
    cs(() => {
        const hasFilters = collection.value.filters.length > 0;
        const isSDKDisplay = collection.value.filterStyle.display === "SDK";
        const label = isSDKDisplay || forceShowFlap ? "Filters" : "Hidden";
        const hiddenFilters = forceShowFlap ? collection.value.filters : collection.value.filters.filter((f) => f.displayArea === "Hidden");
        // const filterCount = hiddenFilters.filter((filter) => hasValueValidators[filter.$type](filterForms.getForm(filter.id).data.value)).length
        const isShowFlap = isSDKDisplay || hiddenFilters.length > 0;

        return (
            isShowFlap &&
            cs(
                [
                    "flapFiltersDialog",
                    (_, next) =>
                        FlapFiltersDialog({
                            collection: collection.value,
                            modalTitle: isSDKDisplay || forceShowFlap ? "Filters" : "Hidden Filters",
                            filters: isSDKDisplay || forceShowFlap ? collection.value.filters : hiddenFilters,
                            filterForms,
                            onRunReport,
                            next,
                        }),
                ],
                ({flapFiltersDialog}) => (
                    <div
                        className={cx("flap-filters-8as", {
                            "has-filters": hasFilters,
                        })}
                        onClick={() => hasFilters && flapFiltersDialog.show()}
                    >
                        {label} {!isSDKDisplay && `(${hiddenFilters.length})`}
                    </div>
                )
            )
        );
    });
