import "./manage-access-key-dialog.scss";
import React from "react";
import {cs} from "@common/react/chain-services";
import {DialogService} from "../../../../../common/dialog/dialog-service";
import {UseState} from "@common/react/use-state";
import {consumeContext} from "@common/react/context";
import {Form2} from "@common/react/cs-form/form2";
import {required} from "@common/react/cs-form/validators/required";
import {AccessKeyParameters} from "./key-parameters/access-key-parameters";
import {SelectCollections} from "./select-collections/select-collections";
import {ShareAccessKey} from "./share-access-key/share-access-key";
import {settingKey, settingKeyLabels} from "../../settings-constants";
import {Load} from "@common/react/load";

export const keyToArray = (obj) => {
    let ret = [];

    for (let key in obj) {
        ret.push({
            key,
            value: obj[key],
        });
    }
    return ret;
};

export const ManageAccessKeyDialog = ({folders, next: rootNext}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    render: ({resolve, args: {$type, accessKey, collections, editStep}}) => ({
                        customizeDialog: true,
                        width: 480,
                        content: next({
                            $type,
                            resolve,
                            accessKey,
                            collections,
                            editStep,
                        }),
                        initShow: true,
                        className: "manage-access-key-dialog-wrapper-a23",
                    }),
                    next: rootNext,
                }),
        ],
        ({modal}) =>
            cs(
                consumeContext("apis"),
                consumeContext("tenant"),
                consumeContext("routing"),
                ["saving", (_, next) => UseState({next})],
                ["stepIndex", (_, next) => UseState({next, initValue: modal.editStep || 0})],
                ["error", (_, next) => UseState({next})],
                [
                    "params",
                    ({routing, auth}, next) => {
                        return UseState({
                            next,
                            initValue: {
                                activeAuth: false,
                                genericUrl: false,
                                folderID: null,
                                ...modal.accessKey,
                            },
                        });
                    },
                ],
                [
                    "form",
                    ({params, saving, apis, auth, error, confirmDialog, tenant, stepIndex}, next) =>
                        Form2({
                            fields: {
                                name: {
                                    transforms: ["trim"],
                                    validators: [required],
                                    asyncValidators: [
                                        {
                                            validate: (name) =>
                                                (modal.$type === settingKey.DATA_ACCESS ? apis.accessKey.checkAccessApiKeyName : apis.iframeKey.checkIframeKeyName)(name, {
                                                    currentID: params.value.id,
                                                }),
                                            getMessage: () => `Existed`,
                                        },
                                    ],
                                    debounce: true,
                                },
                                folderID: [],
                                themeID: [],
                            },
                            data: params,
                            onSubmit: async (share) => {
                                saving.onChange(true);
                                const submitFn = modal.$type === settingKey.DATA_ACCESS ? apis.accessKey.upsertAccessApiKey : apis.iframeKey.upsertIframeKey;
                                const collectionsProps = modal.$type === settingKey.DATA_ACCESS ? "apiCollections" : "collections";

                                const submitData = {
                                    ...params.value,
                                    [collectionsProps]: params.value[collectionsProps].filter((id) => modal.collections.value.findIndex((c) => c.id === id) >= 0),
                                };

                                const resp = await submitFn(submitData);

                                modal.resolve({accessKey: resp, share});
                            },
                            next,
                        }),
                ],
                [
                    "themes",
                    ({apis, routing, form}, next) =>
                        modal?.$type != settingKey.DATA_ACCESS
                            ? Load({
                                  _key: routing.params?.envId,
                                  fetch: () => apis.collection.getThemes(),
                                  next,
                              })
                            : next({}),
                ],
                ({themes, saving, form, params, stepIndex}) => {
                    const steps = [
                        {
                            component: AccessKeyParameters,
                        },
                        {
                            component: SelectCollections,
                        },
                        {
                            component: ShareAccessKey,
                        },
                    ];

                    const activeStep = steps[stepIndex.value];
                    const editMode = modal.editStep !== undefined;

                    return (
                        <>
                            <div className="header">
                                {editMode ? (
                                    <div className="title">
                                        {stepIndex.value == 0
                                            ? `${modal.accessKey.name} Key Parameters`
                                            : stepIndex.value == 2
                                            ? `Share ${params.value.name} Access Key`
                                            : `${modal.accessKey.name} Collections Access`}
                                    </div>
                                ) : (
                                    <div className="title">
                                        {stepIndex.value == 2 ? `Share ${params.value.name} Access Key` : `Create New ${settingKeyLabels[modal.$type].label} Key`}
                                    </div>
                                )}

                                <div className="close" onClick={() => modal.resolve(null)}>
                                    <img src={require("../../../../../common/icons/close-icon.svg")} alt="" />
                                </div>
                            </div>

                            <div className="manage-access-key-dialog-z33 main">
                                {React.createElement(activeStep.component, {
                                    form,
                                    params,
                                    collections: modal.collections,
                                    stepIndex,
                                    saving,
                                    modal,
                                    editMode,
                                    folders,
                                    themes,
                                })}
                            </div>
                        </>
                    );
                }
            )
    );
