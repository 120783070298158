import {DateFilterOptions} from "./date-filter/date-filter-options";
import {NumberFilterOptions} from "./number-filter/number-filter-options";
import {TextFilterOptions} from "./text-filter/text-filter-options";
import {cs} from "@common/react/chain-services";
import * as React from "react";
import {BooleanFilterOptions} from "./boolean-filter/boolean-filter-options";

export const LocalFilterOptions = ({tile, filter, form, tileType}) =>
    cs(({}) => <div className="local-filter-options-5us">{optionsTypes[filter.value.$type]?.({tile, filter, form, tileType})}</div>);

const optionsTypes = {
    DateFilter: DateFilterOptions,
    TextFilter: TextFilterOptions,
    NumericFilter: NumberFilterOptions,
    BooleanFilter: BooleanFilterOptions,
};
