import React from "react";
import {cs} from "@common/react/chain-services";
import {DialogService} from "../../../../common/dialog/dialog-service";
import {consumeContext} from "@common/react/context";
import {UseState} from "@common/react/use-state";
import {TextInput} from "../../../../../../../common/form/text-input/text-input";
import "./delete-env-dialog.scss";
import {Button} from "../../../../../../../common/form/buttons/button/button";

export const DeleteEnvDialog = ({next: rootNext}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    render: ({resolve, args: {env}}) => ({
                        title: "Delete Environment?",
                        width: 500,
                        content: next({
                            resolve,
                            env,
                        }),
                    }),
                    next: rootNext,
                }),
        ],
        consumeContext("apis"),
        ["confirmName", (_, next) => UseState({next})],
        ["submitting", (_, next) => UseState({next})],
        ({modal, confirmName, submitting, apis}) => {
            return (
                <form
                    onSubmit={async (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        submitting.onChange(true);
                        await apis.tenantEnv.removeEnvironment({
                            envID: modal.env.id,
                            confirmName: confirmName.value,
                        });
                        submitting.onChange(false);
                        modal.resolve(true);
                    }}
                >
                    <div className="delete-env-dialog-57a">
                        <div className="main-part dialog-body">
                            <div className="warning">
                                Deleting the "{modal.env.name}" environment cannot be undone.
                                <div>• All data source connections and associated data will be deleted.</div>
                                <div>• All models will be deleted. </div>
                                <div>• All collections will be deleted and unavailable immediately.</div>
                                <br />
                                Type the name of the environment to confirm deletion.
                            </div>

                            {TextInput({
                                label: "Environment Name",
                                state: confirmName,
                            })}
                        </div>

                        <div className="buttons">
                            <Button btnType="secondary" onClick={() => modal.resolve()}>
                                Cancel
                            </Button>
                            <Button type="submit" disabled={!confirmName.value || confirmName.value !== modal.env.name || submitting.value}>
                                Confirm
                            </Button>
                        </div>
                    </div>
                </form>
            );
        }
    );
