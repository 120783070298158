import React from "react";
import {Form2} from "@common/react/cs-form/form2";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {TextInput} from "../../../../../../../../../common/form/text-input/text-input";
import {ffToTextInput} from "../../../../../../../../../common/form/ff-to-text-input";
import {NumberInput} from "../../../../../../../../../common/form/number-input/number-input";
import {ffToNumberInput} from "../../../../../../../../../common/form/ff-to-number-input";
import {ffToBasicInput} from "../../../../../../../../../common/form/ff-to-basic-input";
import {databaseConnectionConfig} from "./database-connection-config";
import {keyed} from "@common/react/keyed";
import {dataSourceTypes} from "../../../../../common/data-source-type";
import {omit} from "@common/utils/objects";
import "./data-source-configuration-form.scss";
import {ConnectionSettingsLayout} from "../connection-settings-layout";
import ShowIcon from "../../../../../../../assets/icons/data-sources/ShowIcon";
import HideIcon from "../../../../../../../assets/icons/data-sources/HideIcon";
import EncryptedIcon from "../../../../../../../assets/icons/data-sources/EncryptedIcon";
import {tooltipService3} from "../../../../../../common/tooltip3/tooltip-service-3";
import {DsFolderField} from "../../../../../common/folders-dialog/ds-folder-field";

const {required} = require("@common/react/cs-form/validators/required");

export const DatabaseConnectionForm = ({next, data, formConfig, adding, dsApi, connectionStatus}) =>
    cs(
        consumeContext("apis"),
        ["databaseConfig", (_, next) => next(databaseConnectionConfig(data.value?.type))],
        [
            "form",
            ({state, databaseConfig, apis}, next) =>
                Form2({
                    data: {
                        value: {
                            ...data.value,
                            connectionDetails: {
                                port: databaseConfig.port,
                                // ... isDevSonPham() ? {"$type":"MongoDBConnectionDetails","username":"verbdashboard-stg","password":"gtIbdXgqxM-I456N1-W9zZifmEqgVV4nh8kLK6-2","database":"verb-stg","hosts":{"verb-mongodb.lg2j3.mongodb.net":0},"srvConnection":true,"ssl":true,"readPreference":"Secondary"} : {},
                                ...data.value.connectionDetails,
                                ...(databaseConfig?.modifyConnectionDetails?.({
                                    port: databaseConfig.port,
                                    ...data.value.connectionDetails,
                                }) || {}),
                                ...(data.value.id && data.value.connectionDetails?.password === null
                                    ? {password: "••••••••••••"}
                                    : {}),
                            },
                        },
                        onChange: data.onChange,
                    },
                    fields: {
                        ...formConfig,
                        "connectionDetails.host": [required],
                        "connectionDetails.database": [required],
                        "connectionDetails.username": [required],
                        "connectionDetails.password": [
                            {
                                validate: (value, {data}) => {
                                    if (data.id) return true;
                                    return value != null && value !== "";
                                },
                            },
                        ],
                        "connectionDetails.port": [required],
                    },
                    next,
                }),
        ],
        [
            "testConnection",
            ({form}, next) =>
                next(() => {
                    const _connectionDetails = form.data.value?.connectionDetails;
                    const databaseConfigData = {
                        $type: data.value.$type,
                        ..._connectionDetails,
                        password: _connectionDetails.password.includes("•") ? null : _connectionDetails.password,
                    };

                    return dsApi.testConnection(
                        {
                            submitConnectionDetails: databaseConfigData,
                            connectionDetails: databaseConfigData,
                        },
                        {
                            id: data.value.id,
                            type: data.value?.type,
                            name: form.data.value?.name,
                        }
                    );
                }),
        ],
        ({form, databaseConfig, testConnection}) => {
            const getDBConnection = () => {
                const _connectionDetails = form.data.value?.connectionDetails;
                return {
                    $type: data.value.$type,
                    ...(data.value?.type === dataSourceTypes.MONGODB
                        ? omit(_connectionDetails, ["host", "port"])
                        : _connectionDetails),
                };
            };

            return next({
                valid: form.valid,
                beforeGoNext: testConnection,
                render: () => {
                    return ConnectionSettingsLayout({
                        type: data.value.type,
                        dataSourceError: (data.value?.errors || []).filter((e) => !e.dataSourceTableID),
                        adding,
                        connectionStatus,
                        controls: {
                            valid: form.valid,
                            onTestConnection: testConnection,
                            onDiscard: () =>
                                form.data.onChange({
                                    ...data.value,
                                    connectionDetails: {},
                                }),
                            onGoNext: dsApi?.onNextStep,
                        },
                        content: (
                            <>
                                {TextInput({
                                    label: "Display Name",
                                    ...ffToTextInput(form.field("name")),
                                })}

                                {DsFolderField({
                                    state: form.field("folderID").state,
                                })}

                                <div className="form-group-flex">
                                    {TextInput({
                                        label: "Host",
                                        ...ffToTextInput(form.field("connectionDetails.host")),
                                    })}

                                    {(databaseConfig?.config1 || []).map((config, i) =>
                                        cs(keyed(i), () =>
                                            config.input({
                                                label: config.label,
                                                ...config.binding(form.field(config.field)),
                                            })
                                        )
                                    )}
                                </div>

                                {!form.field("connectionDetails.srvConnection").state.value &&
                                    NumberInput({
                                        label: "Port",
                                        ...ffToNumberInput(form.field("connectionDetails.port")),
                                    })}

                                {TextInput({
                                    label: "Database Name",
                                    ...ffToTextInput(form.field("connectionDetails.database")),
                                })}

                                {databaseConfig?.config2 && (
                                    <div className="form-group-flex">
                                        {databaseConfig?.config2.map((config, i) =>
                                            cs(keyed(i), () => {
                                                const {label, binding, field, ...otherProps} = config;
                                                return config.input({
                                                    label,
                                                    ...binding(form.field(field)),
                                                    ...otherProps,
                                                });
                                            })
                                        )}
                                    </div>
                                )}

                                {databaseConfig?.config3 && (
                                    <div className="form-group-flex">
                                        {databaseConfig?.config3.map((config, i) =>
                                            cs(keyed(i), () => {
                                                const {label, binding, field, ...otherProps} = config;
                                                return config.input({
                                                    label,
                                                    ...binding(form.field(field)),
                                                    ...otherProps,
                                                });
                                            })
                                        )}
                                    </div>
                                )}

                                {TextInput({
                                    className: "username-88o",
                                    label: "Username",
                                    renderToggle: () =>
                                        cs(
                                            tooltipService3({
                                                direction: "right",
                                                tooltipContentStyle: {
                                                    width: 150,
                                                },
                                            }),
                                            ({tooltip}) => (
                                                <span
                                                    {...tooltip(
                                                        () =>
                                                            `Connections between Verb and your data source will be encrypted.`
                                                    )}
                                                >
                                                    {EncryptedIcon({})}
                                                </span>
                                            )
                                        ),
                                    ...ffToTextInput(form.field("connectionDetails.username")),
                                })}

                                {TextInput({
                                    className: "password-input-9ai",
                                    label: "Password",
                                    type: "password",
                                    renderToggle: data.value.id
                                        ? () => {}
                                        : (state1) => {
                                              return (
                                                  <div
                                                      onClick={() =>
                                                          state1.onChange(
                                                              state1.value === "password" ? "text" : "password"
                                                          )
                                                      }
                                                  >
                                                      {state1.value === "password" ? HideIcon({}) : ShowIcon({})}
                                                  </div>
                                              );
                                          },
                                    ...ffToBasicInput(form.field("connectionDetails.password")),
                                })}
                            </>
                        ),
                    });
                },
            });
        }
    );
