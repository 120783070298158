import React from "react";

const NotSyncIcon = ({className, fill = "#919EB0"}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
        <path
            id="NotSync_Icon"
            data-name="NotSync Icon"
            d="M-2592-2941a12,12,0,0,1,12-12,12,12,0,0,1,12,12,12,12,0,0,1-12,12A12,12,0,0,1-2592-2941Zm2.285,0a9.726,9.726,0,0,0,9.715,9.714,9.668,9.668,0,0,0,6.01-2.088l-13.638-13.637A9.666,9.666,0,0,0-2589.715-2941Zm17.341,6.01a9.665,9.665,0,0,0,2.088-6.01,9.725,9.725,0,0,0-9.714-9.715,9.664,9.664,0,0,0-6.01,2.088Zm-14.831-12.521.695-.695A9.767,9.767,0,0,0-2587.2-2947.51Z"
            transform="translate(2592 2953)"
        />
    </svg>
);

export default NotSyncIcon;
