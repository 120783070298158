import "./verb-scrollbar.scss";

import React, {Component} from 'react';
import {cx} from "emotion";

import {Interval} from "../../react/interval";

import {VerticalScrollbar} from "./vertical-scrollbar";
import {HorizontalScrollbar} from "./horizontal-scrollbar";

export class VerbScrollbar extends Component {
    scrollbarContainer = null;

    constructor(props) {
        super(props);
        this.state = {
            scrollHeight: 0,
            containerHeight: 0,
            scrollTop: 0,
            verticalThumb: false,
            horizontalThumb: false,
            showScrollbars: false,
        }
    }

    componentDidMount() {
        this.props.onInit?.(this.getDom());

        this.resize_ob = new ResizeObserver(entries => {
            let rect = entries[0].target.getBoundingClientRect();

            this.setState({
                scrollHeight: entries[0].target.scrollHeight,
                scrollWidth: entries[0].target.scrollWidth,
                containerHeight: rect.height,
                containerWidth: rect.width,
                scrollTop: entries[0].target.scrollTop,
                scrollLeft: entries[0].target.scrollLeft
            })
        });

        this.resize_ob.observe(this.scrollbarContainer);

        this._timeout = setTimeout(() => {
            this.setState({showScrollbars: true})
        }, 500);
    }

    listeners = [];
    listenersDomSize = [];


    getBoundingClientRect() {
        return this.scrollbarContainer?.getBoundingClientRect();
    }

    getDom() {
        return this.scrollbarContainer;
    }

    setScrollLeft(value) {
        this.scrollbarContainer.scrollLeft = value;
    }

    setScrollTop(value) {
        this.scrollbarContainer.scrollTop = value;
    }

    autoScrollingTop(value) {
        this.scrollbarContainer.scrollTop += value;
    }

    autoScrollingLeft(value) {
        this.scrollbarContainer.scrollLeft += value;
    }

    componentWillUnmount() {
        this.resize_ob?.unobserve(this.scrollbarContainer);
        clearTimeout(this._timeout);
        clearTimeout(this.horizontalTimeout);
        clearTimeout(this.verticalTimeout);
    }

    onScrollListener(listenerFunc) {
        this.listeners.push(listenerFunc);
    }

    onDomSizeChange = (listenerFunc) => {
        this.listenersDomSize.push(listenerFunc)
    }

    getElementsByTagName(tagName) {
        return this.scrollbarContainer.getElementsByTagName(tagName);
    }

    updateScrollBarsWhenDomSizeChange = () => {
        const {scrollHeight, containerHeight, scrollWidth, containerWidth} = this.state;

        const dom = this.scrollbarContainer;

        if(!dom) {
            return;
        }

        const rect = dom.getBoundingClientRect();

        if(
            rect.width != containerWidth
            || rect.height != containerHeight
            || dom.scrollHeight != scrollHeight
            || dom.scrollWidth != scrollWidth
        ) {
            this.setState({
                scrollHeight: dom.scrollHeight,
                scrollWidth: dom.scrollWidth,
                containerHeight: rect.height,
                containerWidth: rect.width,
                scrollTop: dom.scrollTop,
                scrollLeft: dom.scrollLeft
            }, () => {
                this.listenersDomSize.forEach(f => f())
            })
        }
    }

    onMouseEnter = () => {
        clearTimeout(this.horizontalTimeout);
        clearTimeout(this.verticalTimeout);
        this.setState({horizontalThumb: true, verticalThumb: true});

        this.horizontalTimeout = setTimeout(() => {
            this.setState({horizontalThumb: false});
        }, 1000)

        this.verticalTimeout = setTimeout(() => {
            this.setState({verticalThumb: false});
        }, 1000)
    }

    onScroll = () => {
        const {scrollTop, scrollLeft} = this.state;

        this.listeners.forEach(f => f())

        let update = {
            scrollTop: this.scrollbarContainer.scrollTop,
            scrollLeft: this.scrollbarContainer.scrollLeft,
            scrollHeight: this.scrollbarContainer.scrollHeight,
            scrollWidth: this.scrollbarContainer.scrollWidth
        }

        if(this.scrollbarContainer.scrollLeft != scrollLeft) {
            clearTimeout(this.horizontalTimeout);
            update.horizontalThumb = true;

            this.horizontalTimeout = setTimeout(() => {
                this.setState({horizontalThumb: false});
            }, 1000)
        }

        if(this.scrollbarContainer.scrollTop != scrollTop) {
            clearTimeout(this.verticalTimeout);
            update.verticalThumb = true;
            this.verticalTimeout = setTimeout(() => {
                this.setState({verticalThumb: false});
            }, 1000)
        }

        this.setState(update);
    }

    render() {
        const {className, children, maxHeight, style, tagName, applyStyleOnParent, getRef = (elem) => {}} = this.props;
        const {
            scrollHeight,
            containerHeight,
            scrollTop,
            verticalThumb,
            horizontalThumb,
            scrollWidth,
            containerWidth,
            scrollLeft,
            showScrollbars
        } = this.state;

        return (
            <div className={cx(className, "verb-scrollbar-a33")} style={applyStyleOnParent ? style : {}}>
                {this.scrollbarContainer && (
                    Interval({
                        delay: 100,
                        fn: this.updateScrollBarsWhenDomSizeChange,
                    })
                )}

                <div
                    ref={elem => {
                        getRef(elem);
                        this.scrollbarContainer = elem;
                    }}
                    onMouseEnter={this.onMouseEnter}
                    onScroll={this.onScroll}
                    style={{maxHeight, ...(!applyStyleOnParent ? style : {})}}
                    className={cx("content-container")}>
                    {children}
                </div>

                {showScrollbars && (
                    <>
                        <VerticalScrollbar
                            verticalThumb={verticalThumb}
                            scrollHeight={scrollHeight}
                            containerHeight={containerHeight}
                            scrollTop={scrollTop}
                            scrollbarContainer={this.scrollbarContainer}
                        />

                        <HorizontalScrollbar
                            horizontalThumb={horizontalThumb}
                            scrollWidth={scrollWidth}
                            containerWidth={containerWidth}
                            scrollLeft={scrollLeft}
                            scrollbarContainer={this.scrollbarContainer}
                        />
                    </>
                )}
            </div>
        )
    }
}
