import "./info-dialog.scss";

import * as React from "react";

import {cs} from "@common/react/chain-services";
import {Button} from "../../../../../common/form/buttons/button/button";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";

import {DialogService} from "../dialog/dialog-service";

export const InfoDialog = ({noHeader, noFooter, next: rootNext}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    noHeader,
                    render: ({resolve, args: {title, content}}) => ({
                        title: title ?? "",
                        width: 540,
                        content: next({
                            resolve,
                            content,
                        }),
                    }),
                    next: rootNext,
                }),
        ],
        ({modal}) => (
            <div className="info-dialog-9ok info-dialog">
                <VerbDialogBodyScrollbar>
                    <div className="content">{modal.content}</div>
                </VerbDialogBodyScrollbar>
                {!noFooter && (
                    <div className="buttons">
                        <Button onClick={() => modal.resolve()}>Okay</Button>
                    </div>
                )}
            </div>
        )
    );
