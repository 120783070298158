import {getLegendPosition} from "../../../../web-client/src/routes/collection/tile/edit/left-panel/tabs/tile-tab/chart-types/combo-chart/panels/legend/legend-display-rules";
import {getLegendContentValue} from "../../../../web-client/src/routes/collection/tile/edit/left-panel/tabs/tile-tab/chart-types/common/legend-display-select";
import {isOutsideSliceLabelsHidden, isTitleShown} from "../../../../web-client/src/routes/collection/tile/edit/left-panel/tabs/tile-tab/chart-types/pie-chart/display-size-rules";

export const pieChartRestyling = ({tile, size, isCompare}) => {
    const newTitleStyle = {
        ...tile.style.title,
        show: isTitleShown({size}) ? tile.style.title.show : false,
    };

    const legendPosition = getLegendPosition(tile.style.legendStyle.legendPosition, size, tile.$type);

    const newLegendStyle = {
        ...tile.style.legendStyle,
        legendPosition,
        legendShown: !legendPosition ? false : tile.style.legendStyle.legendShown,
        legendContent: getLegendContentValue(tile.style.legendStyle.legendContent, tile.style.legendStyle),
    };

    const newDataLabels = {
        ...tile.style.dataLabels,
        position: isOutsideSliceLabelsHidden({
            size,
            legendStyle: newLegendStyle,
            titleStyle: newTitleStyle,
            isCompare,
        })
            ? tile.style.dataLabels.position.includes("Inside")
                ? tile.style.dataLabels.position
                : "InsideRight"
            : tile.style.dataLabels.position,
    };

    return {
        ...tile,
        style: {
            ...tile.style,
            legendStyle: newLegendStyle,
            title: newTitleStyle,
            dataLabels: newDataLabels,
        },
    };
};
