import React from "react";
import {cs} from "@common/react/chain-services";
import {consumeContext, provideContext} from "@common/react/context";
import {EditModelLayout} from "./layout/edit-model-layout";
import {ModelTabs} from "./tabs/model-tabs";
import {Load} from "@common/react/load";
import {ChangeQueue1} from "@common/react/change-queue-1";
import {createAutoSaveModel} from "../common/auto-save-model";
import {Queue1} from "@common/react/queue-1";
import {ErrorDialog} from "../../../verb-web/auth/tile-data-api-error-handler/error-dialog/error-dialog";
import {UseState} from "@common/react/use-state";
import {Load2} from "@common/react/load2";

export const EditModel = ({environment}) =>
    cs(
        consumeContext("apis"),
        consumeContext("routing"),
        consumeContext("model"),
        ({model}, next) => (!model.value ? EditModelLayout({}) : next(model)),
        ["dataSources", ({apis}, next) => loadDataSources({next, apis, environment})],
        [
            "autoSuggest",
            ({routing, model}, next) =>
                UseState({
                    next,
                    initValue: model.value.tables.length == 0 && routing.params.autoSuggest == "true",
                }),
        ],
        ["loadTransformation", ({apis, model}, next) => loadAllTransformations({next, apis, model})],
        ["errorDialog", (_, next) => ErrorDialog({next})],
        [
            "savingQueue",
            ({apis, model, routing}, next) =>
                ChangeQueue1({
                    save: apis.model.upsertModel,
                    fetchLatest: () => apis.model.getModel(routing.params.modelId),
                    next,
                }),
        ],
        [
            "updatePositionQueue",
            ({apis}, next) =>
                Queue1({
                    fn: ({modelId, updated}) => (!!environment?.readOnly ? () => null : apis.model.upsertTablePosition(modelId, updated)),
                    next,
                }),
        ],
        [
            "tabs",
            ({model, dataSources, savingQueue, apis, updatePositionQueue, errorDialog, autoSuggest}, next) =>
                ModelTabs({
                    environment,
                    model: createAutoSaveModel({
                        model,
                        savingQueue,
                        errorDialog,
                        readOnly: !!environment?.readOnly,
                    }),
                    dataSources,
                    next,
                    apis,
                    updatePositionQueue,
                    savingQueue,
                    autoSuggest,
                }),
        ],
        ({tabs, savingQueue, routing, model, updatePositionQueue, autoSuggest, dataSources}) => {
            return EditModelLayout({
                model,
                labelText: model.value?.name,
                leftPanelAction: tabs.leftPanelAction,
                leftPanelOverride: tabs.leftPanelOverride,
                leftPanelWidth: tabs.leftPanelWidth,
                main: tabs.main,
                saving: savingQueue.executing || updatePositionQueue.executing,
                onBack: () => routing.goto("dashboard", {tab: "models"}),
                autoSuggest,
                dataSources,
            });
        }
    );

export const loadDataSources = ({next, apis}) =>
    cs(
        (_, next) => apis?.data && next(),
        [
            "dataSources",
            (_, next) =>
                Load({
                    fetch: () => apis?.data?.getAllDataSources(),
                    next,
                }),
        ],
        ({dataSources}) => next(dataSources)
    );

export const loadAllTransformations = ({next, apis, model}) =>
    cs(
        [
            "dataViewTransforms",
            ({}, next) =>
                Load2({
                    fetch: () => apis.transformation.getAllTransformations(model.value.id),
                    next,
                    _key: model.value.id,
                }),
        ],
        ({dataViewTransforms}, next) => provideContext("dataViewTransforms", dataViewTransforms, next),
        () => next()
    );
