import * as React from "react";
import {cs} from "@common/react/chain-services";
import {RadioLine} from "../radio-line/radio-line";
import {keyed} from "@common/react/keyed";

export const RadioLineGroup = ({list, state}) =>
    cs(({}) =>
        list?.map((line, i) =>
            cs(keyed(i), () =>
                RadioLine({
                    selected: line.value === state.value,
                    onClick: () => state.onChange(line.value),
                    label: line.label,
                })
            )
        )
    );
