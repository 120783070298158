import {cs} from "@common/react/chain-services";
import ColumnIcon from "assets/icons/data-sources/ColumnIcon";
import {keyed} from "@common/react/keyed";
import {noun} from "@common/utils/plural";
import {UseState} from "@common/react/use-state";
import {cx} from "emotion";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {YesNoToggle} from "../../../../../../../common/form/toggles/yes-no-toggle";
import {scope} from "@common/react/scope";
import {rFieldTypeIcon} from "../../../../common/field-type-icon/get-field-type-icon";
import {isBlank} from "@common/utils/strings";
import {stateToSelect} from "@common/ui-components/form/state-to-select";
import {chain} from "@common/utils/fs";
import {arrEquals, createArray, sort, unique} from "@common/utils/collections";
import "./flat-file-columns-mapping.scss";
import {ExpandedColumns} from "../../flat-file-status/import-configuration/flat-file-columns-import";
import {Button} from "../../../../../../../common/form/buttons/button/button";
import {getDuplicatesMapping} from "../flat-file-mapping-helpers";

export const FlatFileColumnsMapping = ({editingDs, originalDs}) =>
    cs(
        [
            "expandingList",
            (_, next) =>
                UseState({
                    initValue: createArray(editingDs.value.tables.length).map(() => false),
                    next,
                }),
        ],
        ({expandingList}) => {
            const oriTables = [...originalDs.value.tables, ...(originalDs.value?.disabledTables || [])];

            return (
                <div className="flat-file-columns-import-99o flat-file-columns-mapping-88o">
                    <div className="header">
                        <div className="label">Import Configuration</div>

                        <div className="actions">
                            <Button
                                disabled={arrEquals(unique(expandingList.value), [true])}
                                btnType="secondary"
                                size="small"
                                onClick={() => expandingList.change((prev) => prev.map(() => true))}
                            >
                                Expand All
                            </Button>
                            <Button
                                disabled={arrEquals(unique(expandingList.value), [false])}
                                btnType="secondary"
                                size="small"
                                onClick={() => expandingList.change((prev) => prev.map(() => false))}
                            >
                                Collapse All
                            </Button>
                        </div>
                    </div>

                    <div className="columns-list">
                        {editingDs.value.tables.map((table, i) =>
                            cs(keyed(i), ["expanding", (_, next) => next(scope(expandingList, [i]))], ({expanding}) => {
                                const oriTable = oriTables.find((t) => t.name === table.name);
                                const duplicatesMapping = getDuplicatesMapping(table.columns);

                                return (
                                    <div
                                        className={cx("item", {
                                            "has-errors": duplicatesMapping.length > 0,
                                        })}
                                    >
                                        <div
                                            className={cx("left", {
                                                expanded: expanding.value,
                                            })}
                                            onClick={() => expanding.change((prev) => !prev)}
                                        >
                                            {duplicatesMapping.length > 0 ? (
                                                <span
                                                    className="material-icons-outlined"
                                                    style={{
                                                        color: "#fff",
                                                        fontSize: 20,
                                                    }}
                                                >
                                                    sync_problem
                                                </span>
                                            ) : (
                                                ColumnIcon({})
                                            )}

                                            {
                                                <i
                                                    className={cx("far fa-angle-down expand-icon", {
                                                        expanded: expanding.value,
                                                    })}
                                                />
                                            }
                                        </div>
                                        <div
                                            className={cx("right", {
                                                expanded: expanding.value,
                                            })}
                                            onClick={() => !expanding.value && expanding.change((prev) => !prev)}
                                        >
                                            <div className="overview">
                                                <span className="text">{table.name}</span> ({table.columns.length} {noun(table.columns.length, "column")})
                                            </div>

                                            {cs((_, next) =>
                                                !expanding.value ? (
                                                    <div className="info">
                                                        {table.id ? (
                                                            <>
                                                                <div>Columns Mapped: {table.columns.length}</div>
                                                                <div className="separator" />
                                                                <div>New: {table.disabledColumns.length}</div>
                                                                <div className="separator" />
                                                                <div>Excluded: {table.disabledColumns.length}</div>
                                                            </>
                                                        ) : (
                                                            <div>New</div>
                                                        )}
                                                    </div>
                                                ) : (
                                                    <div className="expanding-section">
                                                        {cs(
                                                            (_, next) =>
                                                                !table.mapsToName
                                                                    ? ExpandedColumns({
                                                                          table: scope(editingDs, ["tables", i]),
                                                                      })
                                                                    : next(),
                                                            () =>
                                                                ExpandedMappingColumns({
                                                                    tables: editingDs.value.tables,
                                                                    duplicatesMapping,
                                                                    table: scope(editingDs, ["tables", i]),
                                                                })
                                                        )}
                                                    </div>
                                                )
                                            )}
                                        </div>

                                        {table.id && (
                                            <div
                                                className={cx("extra", {
                                                    expanded: expanding.value,
                                                })}
                                                onClick={() => !expanding.value && expanding.change((prev) => !prev)}
                                            >
                                                {!expanding.value ? (
                                                    <>
                                                        {/* <div className="mapping-status">
                                                    <strong>1</strong> - Original not mapped
                                                </div> */}
                                                    </>
                                                ) : (
                                                    <div className="mapping-status-expanded">
                                                        <div className="label">Original File Column Mapping</div>
                                                        <div className="columns">(2 columns)</div>
                                                        <div className="list">
                                                            {oriTable.columns.map((c, j) =>
                                                                cs(keyed(j), () => (
                                                                    <div className="item">
                                                                        <span className="material-icons-outlined">done</span>
                                                                        <span className="text">{c.name}</span>
                                                                    </div>
                                                                ))
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                );
                            })
                        )}
                    </div>
                </div>
            );
        }
    );

const ExpandedMappingColumns = ({tables, table, duplicatesMapping}) =>
    cs(() => {
        const columns = [...table.value.columns, ...table.value.disabledColumns];

        const sortedColumns = chain(
            columns,
            (_) => [
                ..._.filter((c) => c.primaryKey),
                ...sort(
                    _.filter((c) => !c.primaryKey),
                    (c) => c.name
                ),
            ]
            // (_) => _.filter((c) => c.name.toLowerCase().indexOf((keyword.value || "").toLowerCase()) > -1)
        );

        return (
            <div className="expanded-columns-88o expanded-columns-mapping-99o">
                <div className="labels">
                    <div className="name">Columns</div>
                    <div className="import">Import</div>
                </div>

                <div className="list">
                    {sortedColumns.map((column, i) =>
                        cs(keyed(i), () => {
                            const disabledColumn = (column) => table.value.disabledColumns.findIndex((c) => c.name === column.name) > -1;

                            return (
                                <>
                                    <div className="item">
                                        <div className="flex-item">
                                            <div className="name">
                                                {rFieldTypeIcon(column.dataType)}
                                                <div className="text">{column.name}</div>
                                            </div>

                                            {YesNoToggle({
                                                state: {
                                                    value: !disabledColumn(column),
                                                    onChange: (value) =>
                                                        table.change((oldTable) => ({
                                                            ...oldTable,
                                                            columns: value ? [...oldTable.columns, column] : oldTable.columns.filter((c) => c.name !== column.name),
                                                            disabledColumns: !value
                                                                ? [...oldTable.disabledColumns, column]
                                                                : oldTable.disabledColumns.filter((c) => c.name !== column.name),
                                                        })),
                                                },
                                            })}
                                        </div>

                                        {!disabledColumn(column) &&
                                            cs(
                                                ["selected", (_, next) => next(scope(table, ["columns", table.value.columns.findIndex((c) => c.name === column.name)]))],
                                                [
                                                    "list",
                                                    (_, next) => {
                                                        const _mappingTable = tables.find((t) => t.name === (table.value.mapsToName || null));

                                                        return next([
                                                            ...(_mappingTable
                                                                ? _mappingTable?.columns.map((c) => ({
                                                                      value: c.name,
                                                                      columnReferenceName: c.columnReferenceName,
                                                                      dataType: c.dataType,
                                                                      label: c.name,
                                                                  }))
                                                                : []),
                                                            ...(!table.value.id
                                                                ? [
                                                                      {
                                                                          value: null,
                                                                          columnReferenceName: null,
                                                                          label: "New",
                                                                      },
                                                                  ]
                                                                : []),
                                                        ]);
                                                    },
                                                ],
                                                ({list, selected}) => {
                                                    return DropdownSelect({
                                                        hasError: duplicatesMapping.includes(selected.value.mapsToName),
                                                        label: "Maps To",
                                                        list,
                                                        valueToLabelList: (v) =>
                                                            v.value ? (
                                                                <>
                                                                    {rFieldTypeIcon(v.dataType)} {v.value}
                                                                </>
                                                            ) : (
                                                                <span className="new">{v.label}</span>
                                                            ),
                                                        valueToLabel: (v) => (v.value ? v.label : <div className="new">{v.label}</div>),
                                                        isSelected: (v) => v.value === selected.value.mapsToName,
                                                        onChange: (v) =>
                                                            selected.change((old) => ({
                                                                ...old,
                                                                mapsToName: v.value,
                                                                mapsToSchema: v.columnReferenceName,
                                                            })),
                                                    });
                                                }
                                            )}
                                    </div>
                                </>
                            );
                        })
                    )}
                </div>
            </div>
        );
    });

{
    /* <div className="interaction-row-types">
    {cs(
        () => {
            const isDisabledColumn = disabledColumn(column);

            const config = {
                "Text": {
                    list: ["NonPII", "PII", "None"],
                    valueToLabel: (v) => <>{rFieldTypeIcon(`Text`)} Text ({v === "None" ? "Unqualified" : v === "NonPII" ? "Non-PII" : v})</>
                },
                "DateTime": {
                    list: ["LocalTime", "UTC", "None"],
                    valueToLabel: (v) => <>{rFieldTypeIcon(`DateTime`)} DateTime ({v === "None" ? "Unqualified" : v === "LocalTime" ? "Local Time" : v})</>
                },
                "DateTimeOffset": {
                    list: ["LocalTime", "UTC", "None"],
                    valueToLabel: (v) => <>{rFieldTypeIcon(`DateTimeOffset`)} DateTimeOffset ({v === "None" ? "Unqualified" : v === "LocalTime" ? "Local Time" : v})</>
                },
            }[column.dataType] || undefined;

            if (config) {
                let columnIndex = table.value.columns.findIndex(c => c.name === column.name);
                let disabledColumnIndex = table.value.disabledColumns?.findIndex(c => c.name === column.name);

                return (
                    DropdownSelect({
                        ...config,
                        disabled: isDisabledColumn,
                        label: null,
                        errorMessage: `This field is required`,
                        hasError: isBlank(column.dataTypeProperties),
                        ...stateToSelect(scope(table, [
                            isDisabledColumn ? "disabledColumns" : "columns",
                            isDisabledColumn ? disabledColumnIndex : columnIndex,
                            "dataTypeProperties"
                        ]))
                    })
                );
            } else {
                return <span className={cx("value", { "id-uuid": column.dataType === "IDUUID" })}>
                    {config ?
                        config.valueToLabel(column.dataTypeProperties) :
                        <>{rFieldTypeIcon(column.dataType)} {column.dataType}</>}
                </span>;
            }
        }
    )}
</div> */
}
