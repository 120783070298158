const cSsJson = !(typeof window !== "undefined")
    ? () => ({
          get: () => {},
          set: () => {},
      })
    : (key) => ({
          get: () => {
              const cacheStr = sessionStorage.getItem(key);

              if (cacheStr == null) {
                  return undefined;
              }

              return JSON.parse(cacheStr);
          },
          set: (value) => {
              if (value == null) {
                  sessionStorage.removeItem(key);
              } else {
                  sessionStorage.setItem(key, JSON.stringify(value));
              }
          },
      });
exports.cSsJson = cSsJson;
