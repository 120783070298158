const addVector = (p, v) => {
    return {
        x: p.x + v.x,
        y: p.y + v.y,
    };
};
exports.addVector = addVector;

const changeVector = (v, fn) => {
    return {
        x: fn(v.x, "x"),
        y: fn(v.y, "y"),
    };
};
exports.changeVector = changeVector;

const multiplyVector = (v, d) => {
    return {
        x: v.x + d,
        y: v.y + d,
    };
};
exports.multiplyVector = multiplyVector;

const toVector = (p1, p2) => {
    return {
        x: p2.x - p1.x,
        y: p2.y - p1.y,
    };
};
exports.toVector = toVector;

const distance = (p1, p2) => {
    return Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2));
};

exports.distance = distance;
