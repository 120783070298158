// const {initTooltipConnectorHelper} = require("../../factory/tooltip/connector-helpers/tooltip-connector-helper");
const {getDataLabelsOptions} = require("../../factory/data-labels");
const {DATA_BOOST_THRESHOLD, DATA_TURBO_THRESHOLD} = require("../../common/axis-chart-boost-turbo-constants");

const getPlotOptions = ({tile, theme, formatters, onClickPoint}) => {
    const areaOptions = {
        fillOpacity: 0.5,
        ...(() => {
            const displayType = tile.style.displayType;

            if (displayType.includes("Stacked")) {
                return {stacking: "normal"};
            }

            if (displayType.includes("HundredPercent")) {
                return {stacking: "percent"};
            }

            return {};
        })(),
        connectNulls: true,
        // dataGrouping: {
        //     enabled: false,
        // }
    };

    // const tooltipConnectorHelper = theme.dataVisualization.toolTipsAndLegends.indicatorLineShown && initTooltipConnectorHelper({tooltipStyle: theme.dataVisualization.toolTipsAndLegends});

    return {
        series: {
            cursor: onClickPoint ? "pointer" : "default",
            boostThreshold: DATA_BOOST_THRESHOLD,
            turboThreshold: DATA_TURBO_THRESHOLD,
            dataLabels: getDataLabelsOptions({tile, theme, formatters}),
            // grouping: false,
            // shadow: false,
            // marker: {
            //     enabled: tile.style.marker && tile.style.marker !== "None",
            //     ...(tile.style.marker && tile.style.marker !== "None" && {
            //         symbol: tile.style.marker.toLowerCase(),
            //     }),
            // },
            getExtremesFromAll: true, // to prevent measurement axis from scaling on scroll
            point: {
                events: {
                    // ...(tooltipConnectorHelper && {
                    //     mouseOver: function() {
                    //         setTimeout(() => tooltipConnectorHelper.render({chart: this.series.chart}), 0)
                    //     },
                    //     mouseOut: function() {
                    //         setTimeout(() => tooltipConnectorHelper.remove(), 0)
                    //     }
                    // }),
                    click: function ({x, y, point}) {
                        onClickPoint?.({x, y, point, chart: this.series.chart});
                    },
                },
            },
        },
        area: areaOptions,
        areaspline: areaOptions,
    };
};
exports.getPlotOptions = getPlotOptions;
