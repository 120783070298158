import "./container-tile.scss";

import * as React from "react";
import {UseState1} from "../../react/use-state";
import {cs} from "../../react/chain-services";
import {ContainerHeader} from "./container-tile-header";
import {Watch} from "../../react/watch";
import {equalDeep} from "../../utils/objects";
import {ErrorMessage} from "../charts/error-message/error-message";

export const ContainerTile = ({containerTile, onSelectTile, theme, isDonePreLoad = false, isLiveGrid = true, renderChart = () => null, onEdit = () => null}) =>
    cs(
        [
            "currentTile",
            (_, next) =>
                UseState1({
                    next,
                    initValue: (containerTile.default_tile_id && containerTile.tiles.find((t) => t.id == containerTile.default_tile_id)) || containerTile.tiles[0],
                }),
        ],
        [
            "header",
            ({currentTile}, next) =>
                ContainerHeader({
                    containerTile,
                    theme,
                    currentTile,
                    onSelectTile,
                    next,
                }),
        ],
        ({currentTile, header}) => {
            return (
                <div className="container-tile-f43">
                    {onSelectTile &&
                        Watch({
                            value: containerTile.default_tile_id,
                            onChanged: (newSelectedSubTileID) => {
                                if (!equalDeep(newSelectedSubTileID, currentTile.value?.id)) {
                                    currentTile.onChange(containerTile.tiles.find((t) => t.id == newSelectedSubTileID));
                                }
                            },
                        })}

                    {containerTile?.tiles.length > 0 ? (
                        header.render()
                    ) : isLiveGrid ? (
                        <div className="add-tile-msg">Select "Add New Tile" to add a chart to this container tile</div>
                    ) : (
                        ErrorMessage({
                            message: "Please add tiles to this container tile",
                            button: {
                                label: "Edit Container Tile",
                                onClick: () => onEdit(containerTile),
                            },
                        })
                    )}

                    {/*{currentTile.value && header.height != undefined && (*/}
                    {/*    <div style={{height: `calc(100% - ${header.height}px)`}}>*/}
                    {/*        {renderChart?.({...currentTile.value, isContainerTile: true})}*/}
                    {/*    </div>*/}
                    {/*)}*/}

                    {header.height != undefined &&
                        containerTile.tiles.map((t) => {
                            if (currentTile.value?.id != t.id && !isDonePreLoad) {
                                return null;
                            }

                            return (
                                <div
                                    style={{
                                        height: `calc(100% - ${header.height}px)`,
                                        display: currentTile.value?.id != t.id ? "none" : "block",
                                        pointerEvents: currentTile.value?.id != t.id ? "none" : "all",
                                        position: "relative",
                                    }}
                                    key={t.id}
                                >
                                    {renderChart?.({
                                        ...t,
                                        isContainerTile: true,
                                        parentID: containerTile.id,
                                    })}
                                </div>
                            );
                        })}
                </div>
            );
        }
    );
