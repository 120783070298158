import * as React from "react";
import {cs} from "@common/react/chain-services";
import {TextInput} from "../../../../../../../../../../../../../common/form/text-input/text-input";
import {ffToTextInput} from "../../../../../../../../../../../../../common/form/ff-to-text-input";
import {LpColorLine} from "../../../../../../../../../../common/left-panel/lp-color-line";
import {scope} from "@common/react/scope";
import "./button-design.scss";
import {consumeContext} from "@common/react/context";

export const ButtonDesign = ({tile, form}) => ({
    label: "Button Label",
    render: () =>
        cs(consumeContext("theme"), ({theme}) => {
            const rLpColorLine = (attr, label) => {
                const {value, onChange} = scope(tile, ["style", attr]);
                return LpColorLine({
                    label,
                    state: {
                        value: value || theme?.[attr],
                        onChange,
                    },
                });
            };

            return (
                theme && (
                    <div className="download-report-button-design-s4p">
                        {TextInput({
                            label: "Button Text",
                            ...ffToTextInput(form.field(["style", "buttonText"])),
                        })}

                        {/*{rLpColorLine("buttonColorRGB", "Button Color")}*/}

                        {/*{rLpColorLine("buttonHoverColorRGB", "Button Hover Color")}*/}

                        {/*{rLpColorLine("buttonFontColorRGB", "Button Font Color")}*/}

                        {/*{FontSizeSelect(scope(tile, ["style", "buttonFontSize"]))}*/}
                    </div>
                )
            );
        }),
});
