import * as React from "react";
import {Display} from "./panels/display/display";
import {XAxis} from "./panels/x-axis/x-axis";
import {YAxesLines} from "./panels/y-axis-lines/y-axes-lines";
import {YAxesBars} from "./panels/y-axis-bars/y-axes-bars";
import {DataLabels} from "./panels/data-labels/data-labels";
import {Colors} from "./panels/colors/colors";
import {Legend} from "./panels/legend/legend";
import {TileTitle} from "./panels/tile-title/tile-title";
import {OtherOptions} from "../common/other-options/other-options";
import {ReferenceLines} from "../common/reference-lines/reference-lines";

export const ComboChart = ({tile, size, form, parentTile, colorsTileConfig, referenceLinesPanel, tileAction, isContainerTile}) =>
    [
        Display,
        TileTitle,
        (props) => ReferenceLines({...props, referenceLinesPanel}),
        XAxis,
        ...YAxesLines(tile.value.style.yAxisLine),
        ...YAxesBars(tile.value.style.yAxisBar),
        DataLabels,
        Colors,
        Legend,
        OtherOptions,
    ]
        .map((panel) =>
            panel({
                tile,
                form,
                size,
                colorsTileConfig,
                parentTile,
                tileAction,
                isContainerTile,
            })
        )
        .filter((v) => v);
