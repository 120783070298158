// import {cx} from "emotion";
import * as React from "react";
import ReactDOM from "react-dom";

import {cs} from "@common/react/chain-services";
import {StandardSingleKPI} from "./standard";
// import {CondensedSingleKPI} from "./condensed";
import {loadTileData} from "../common/load-tile-data";
import {getValueFormatter} from "../table-kpi/get-value-formatter";
import {keepOnly} from "@common/utils/objects";
import {ActionMenu} from "../common/action-menu/action-menu";
import {consumeContext} from "@common/react/context";
import {IgnoreUpdate} from "@common/react/ignore-update";
import {loadTileFields} from "../get-field-color";
import {CurrencyConversionWarning, getCurrencyConversion} from "../common/currency-conversion-warning/currency-conversion-warning";
import {cx} from "emotion";
import {isHttpsUrl} from "@common/utils/strings";
import {cGetFontSize} from "../common/style-map/font-size";

export const SingleKPI = ({tile, tileFilters, loadData, theme, size, defaultData, overrideTile, disabledTileActions}) =>
    cs(
        // ["data", (_, next) => next(data)],
        [
            "data",
            (_, next) =>
                defaultData
                    ? next(defaultData)
                    : loadTileData({
                          next,
                          tileFilters,
                          loadData,
                          size,
                          tile,
                          theme,
                          tileKey: JSON.stringify(keepOnly(tile, ["valueField", "filters"])),
                      }),
        ],
        consumeContext("selectedTimezone"),
        ({selectedTimezone}, next) =>
            IgnoreUpdate({
                next,
                props: {timezoneId: selectedTimezone?.value},
                when: ({timezoneId}) => timezoneId != selectedTimezone?.value,
            }),
        [
            "formatter",
            ({selectedTimezone}, next) =>
                next(
                    getValueFormatter({
                        valueField: tile.valueField,
                        style: tile.style,
                        timezone: selectedTimezone?.value,
                        tile,
                    })
                ),
        ],

        [
            "tileFields",
            ({}, next) =>
                loadTileFields({
                    next,
                    configs: {
                        tile,
                        measureSingleAttrs: ["valueField"],
                        checkUnique: false,
                    },
                }),
        ],
        [
            "actionMenu",
            ({formatter}, next) =>
                ActionMenu({
                    tile,
                    overrideTile,
                    disabledTileActions,
                    next,
                }),
        ],
        [
            "onClickPoint",
            ({actionMenu, tileFields, data}, next) => {
                if (!actionMenu.hasActions()) {
                    return next();
                }
                return next((other) => {
                    let fieldToValue = {};
                    const valueField = tile.valueField;
                    if (valueField) {
                        fieldToValue[valueField.id] = other.row.value;
                    }

                    const tileActionData = other.row?.tileActionData;

                    tileActionData?.columns.forEach((c, index) => {
                        fieldToValue[c.tileFieldID] = tileActionData.data[index];
                    });

                    actionMenu?.show?.({
                        ...other,
                        fieldToValue,
                    });
                });
            },
        ],
        ({data, formatter, actionMenu, onClickPoint, tileFields}) => {
            const row = data.summaryRow;
            const style = tile.style;

            const getFontSize = cGetFontSize(theme.general.canvas.fontSize, theme);
            const iconColor = theme.dataVisualization.dataColorPalettes.kpiColorsRGB[tile.style.titleIconColor?.index || 0];

            const filtersValue = tileFilters.getValue();
            const dateFilters = Object.keys(filtersValue).filter((key) => filtersValue[key].$type === "DateFilterValue");

            //TODO: hide condensed when wait design to fix
            // const type = (!style.template || style.template === "Standard")
            //     ? "standard" : "condensed";
            const getIcon = () => {
                if (tile.style.titleIconUrl) {
                    const iconObj = JSON.parse(tile.style.titleIconUrl || "{}");
                    if (iconObj.icon?.length > 0)
                        return () => (
                            <i
                                style={{
                                    fontSize: `${getFontSize({
                                        group: tile.style.iconFontSize,
                                    })}px`,
                                    color: parseInt(tile.style.titleIconBackgroundOpacity ?? 0) / 100 === 1 ? "#fff" : iconColor,
                                }}
                                className={cx("fa", iconObj.icon)}
                            />
                        );
                    if (isHttpsUrl(iconObj.url))
                        return () => (
                            <img
                                height={`${getFontSize({
                                    group: tile.style.iconFontSize,
                                })}px`}
                                src={iconObj.url}
                                alt=""
                            />
                        );
                }
                return null;
            };

            const icon = getIcon();

            return (
                <>
                    {StandardSingleKPI({
                        title: tile.title,
                        titleStyle: tile.style.title,
                        size,
                        theme,
                        actionMenu,
                        onClickPoint,
                        // hideComparison: dateFilters.length > 0 && dateFilters.length === dateFilters.filter((key) => !filtersValue[key].compareRange).length,
                        hideComparison:
                            !defaultData &&
                            dateFilters.length > 0 &&
                            dateFilters.length === dateFilters.filter((key) => !filtersValue[key].compareRange).length,
                        row,
                        style,
                        formatter,
                        tile,
                        currencyConversion: getCurrencyConversion({
                            $type: data.$type,
                            data,
                            tileFields,
                        }),
                    })}

                    {cs(
                        ["dom", (_, next) => next(document.getElementById(`single-kpi-controls-portal-${tile.id}`))],
                        ({dom}, next) => (dom ? next() : null),
                        ({dom}) =>
                            ReactDOM.createPortal(
                                <>
                                    {icon && tile.style.showTitleIcon && (
                                        <div className="icon">
                                            <div className="icon-wrapper">{icon()}</div>
                                            <div
                                                className="icon-bg"
                                                style={{
                                                    background: iconColor,
                                                    opacity: parseInt(tile.style.titleIconBackgroundOpacity ?? 0) / 100,
                                                }}
                                            />
                                        </div>
                                    )}

                                    {CurrencyConversionWarning({
                                        theme,
                                        currencyConversion: getCurrencyConversion({
                                            $type: data.$type,
                                            data,
                                            tileFields,
                                        }),
                                    })}
                                </>,
                                dom
                            )
                    )}
                </>
            );
        }
    );

// const templates = {
//     "standard": StandardSingleKPI,
//     "condensed": CondensedSingleKPI,
// };

// const tile = {
//     style: {
//         // template: "Standard",
//         // template: "Condensed",
//         actualValueShown: true,
//         autoRound: true,
//         compareValueShown: true,
//         compareValueMethod: "Percent",
//         indicatorShown: true,
//         positiveChangeGood: true,
//         previousPeriodShown: true,
//     }
// };
// const data = {
//     summaryRow: {
//         name: "Total Tickets Sold",
//         value: 100.234,
//         change: "50",
//         previousValue: 50,
//     },
// };
