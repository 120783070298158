// import {cx} from "emotion";
import * as React from "react";

import {cs} from "@common/react/chain-services";
import {loadTileData} from "../common/load-tile-data";
import {getValueFormatter} from "../table-kpi/get-value-formatter";
import {keepOnly} from "@common/utils/objects";
import {consumeContext} from "@common/react/context";
import {IgnoreUpdate} from "@common/react/ignore-update";
import {loadTileFields} from "../get-field-color";
import {SingleKPIChart} from "@common/ui-components/charts/spark-line-kpi/single-kpi-chart";
import {getCalculatedStyles} from "@common/ui-components/charts/spark-line-kpi/spark-line-kpi-utils";
import {SparkLineChart} from "@common/ui-components/charts/spark-line-kpi/spark-line-chart";

export const SparkLineKpi = ({tile, tileFilters, loadData, theme, size, defaultData, ...others}) => cs(
    ["data", (_, next) => defaultData ? next(defaultData) : loadTileData({
        next, tileFilters, loadData, size, tile, theme,
        tileKey: JSON.stringify(keepOnly(tile, ["xAxisField", "yAxisField", "filters"])),
    })],
    consumeContext('selectedTimezone'),
    ({selectedTimezone}, next) => IgnoreUpdate({
        next,
        props: {timezoneId: selectedTimezone?.value},
        when: ({timezoneId}) => timezoneId != selectedTimezone?.value,
    }),
    ["formatter", ({selectedTimezone}, next) => next(getValueFormatter({
        valueField: tile.yAxisField?.measureFields?.[0],
        style: tile.style,
        timezone: selectedTimezone?.value,
        tile
    }))],

    ["tileFields", ({}, next) => loadTileFields({
        next,
        configs: {
            tile,
            measureSingleAttrs: ["yAxisField"],
            checkUnique: false,
        }
    })],
    ({data, formatter, tileFields}) => {
        const {spaceStyle, kpiStyle, sparkLineStyle, flexDirection} = getCalculatedStyles(tile.style);

        const containerStyle = {
            flexDirection,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: '100%',
            height: '100%',
        };

        return <div style={containerStyle}>
            <div style={kpiStyle}>
                {SingleKPIChart({
                    tile,
                    data: data.kpiData,
                    formatter,
                    tileFields,
                    tileFilters,
                    size,
                    theme,
                    ...others
                })}
            </div>
            <div style={spaceStyle}/>
            <div style={sparkLineStyle}>
                {SparkLineChart({
                    tile,
                    chartData: data.chartData,
                    formatter,
                    tileFields,
                    tileFilters,
                    size,
                    theme,
                    ...others
                })}
            </div>
        </div>;
    }
)
