import React from "react";
import {cs} from "@common/react/chain-services";
import {keyed} from "@common/react/keyed";
import {diagramUtils} from "../common/diagram-utils";
import {equalDeep} from "@common/utils/objects";
import {IgnoreUpdate} from "@common/react/ignore-update";

const colors = {
    selected: "#002CA6",
    hovered: "#546B81",
};

export const TransformationDiagramRelationships = ({interactions, hoverValue, diagrams, selectedConnectionPath, relationships}) =>
    cs(() => {
        return (
            <>
                {relationships
                    .filter((r) => r.source !== null)
                    .map((relationship, index) =>
                        cs(
                            keyed(index),
                            (_, next) => {
                                const source = diagrams.find((d) => d.id === relationship.source);
                                const target = diagrams.find((d) => d.id === relationship.target);

                                return IgnoreUpdate({
                                    props: {
                                        source,
                                        target,
                                        interactions,
                                        selectedConnectionPath,
                                    },
                                    when: (pp) =>
                                        equalDeep(pp.source, source) &&
                                        equalDeep(pp.target, target) &&
                                        equalDeep(pp.interactions.setting?.data.stepId, interactions.setting?.data.stepId) &&
                                        equalDeep(pp.selectedConnectionPath.value, selectedConnectionPath.value),
                                    next,
                                });
                            },
                            () => {
                                const connectedPath = diagramUtils.calculateConnectedPath({
                                    relationships,
                                    relationship,
                                    diagrams,
                                });

                                const isSelected = interactions.setting?.data.stepId === relationship.target;
                                const isSelectedPath = equalDeep(selectedConnectionPath.value, relationship);
                                const isHovered = hoverValue.value === relationship.source;
                                const connectedMarkerSize = isHovered ? 5 : 3;

                                const getConnectedDotCss = () => {
                                    if (isSelectedPath) {
                                        return {
                                            stroke: "#002CA6",
                                            fill: "#fff",
                                            strokeWidth: 2,
                                        };
                                    }

                                    if (isSelected) {
                                        return {
                                            fill: colors["selected"],
                                        };
                                    }

                                    if (isHovered) {
                                        return {
                                            fill: "#fff",
                                            stroke: colors["hovered"],
                                            strokeWidth: 3,
                                        };
                                    }

                                    return {
                                        fill: "#8F9EAC",
                                    };
                                };

                                const getPathProperty = () => {
                                    if (isSelectedPath) {
                                        return {
                                            stroke: "#002CA6",
                                        };
                                    }

                                    if (isSelected) {
                                        return {
                                            stroke: colors["selected"],
                                        };
                                    }

                                    if (isHovered) {
                                        return {
                                            stroke: colors["hovered"],
                                            strokeWidth: 3,
                                        };
                                    }

                                    return {
                                        stroke: "#8F9EAC",
                                    };
                                };

                                const relationshipProps = {
                                    style: {cursor: "pointer"},
                                    ref: (elem) =>
                                        d3.select(elem).on("click", (e) => {
                                            selectedConnectionPath.onChange(relationship);
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }),
                                };

                                return (
                                    <g className="relationships" {...relationshipProps}>
                                        <path className="link" d={connectedPath.path} strokeWidth="20" stroke="#8F9EAC" strokeOpacity={0} />
                                        <path className="link" d={connectedPath.path} strokeWidth="2.5" strokeDasharray={isSelected ? "5,5" : "0,0"} {...getPathProperty()} />
                                    </g>
                                );
                            }
                        )
                    )}
            </>
        );
    });
