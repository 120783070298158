const {isSameField} = require("@common/ui-components/charts/factory/is-same-field");

const getTileActionsUpdated = ({tile, fieldVal: {newVal, oriVal}}) => {
    return newVal === null
        ? {
              tileActions: tile.value.tileActions.map((tileAction) => ({
                  ...tileAction,
                  enabledTileFieldIDs: tileAction.enabledTileFieldIDs.filter((fieldId) => fieldId !== oriVal.id),
                  filters: tileAction.filters?.filter((tileActionFiter) => !isSameField(oriVal, tileActionFiter.columns[0])),
              })),
          }
        : {};
};

exports.getTileActionsUpdated = getTileActionsUpdated;
