import { StyledClass } from "@common/react/styled-class";
import { css, cx } from "emotion";
import * as React from "react";
import { bindInput } from "../../../../../../../../react/bind-input";
import { cs } from "../../../../../../../../react/chain-services";
import { Invoke } from "../../../../../../../../react/invoke";
import { scope } from "../../../../../../../../react/scope";
import { Static2 } from "../../../../../../../../react/static-2";
import { UseState } from "../../../../../../../../react/use-state";
import { chain } from "../../../../../../../../utils/fs";
import { isMatchText } from "../../../../../../../../utils/strings";
import { Dropdown } from "../../../../../../../dropdown/dropdown";
import { VerbScrollbar } from "../../../../../../../verb-scrollbar/verb-scrollbar";
import "./selectable-list-chips.scss";

export const SelectableListChips = ({
    className,
    list,
    isSelected,
    theme,
    onSelect,
    hasError = {},
    valueToLabel = (v) => v,
    domRef,
    searchable = true,
    closeAllOtherDropdownWhenOpen = true,
}) =>
    cs(
        ["inputRef", (_, next) => Static2({next})],
        ["searching", (_, next) => UseState({next})],
        [
            "toggleClass",
            ({theme}, next) => {
                const componentTheme = theme?.general?.components;
                return StyledClass({
                    content: componentTheme
                        ? {
                              border: `${componentTheme.inputBorderWidth}px solid ${componentTheme.inputBorderColorRGB} !important`,
                              background: `${componentTheme.inputBackgroundColorRGB} !important`,
                              color: `${componentTheme.inputTextColorRGB} !important`,
                              "border-radius": `${componentTheme.inputCornerRadius}px !important`,
                          }
                        : {},
                    next,
                });
            },
        ],
        [
            "menuItemClass",
            (_, next) => {
                const componentTheme = theme?.general?.components;

                return StyledClass({
                    next,
                    content: componentTheme
                        ? {
                              background: `${componentTheme.menuBackgroundColorRGB} !important`,
                              color: `${componentTheme.menuTextColorRGB} !important`,
                              "&:hover": {
                                  background: `${componentTheme.menuHoverBackgroundColorRGB} !important`,
                              },

                              "&.selected": {
                                  background: `${componentTheme.menuHoverBackgroundColorRGB} !important`,
                              },
                          }
                        : {},
                });
            },
        ],
        ({inputRef, searching, toggleClass, menuItemClass}) => {
            const componentTheme = theme?.general?.components;
            return Dropdown({
                closeAllOtherDropdownWhenOpen,
                borderRadius: componentTheme?.menuCornerRadius,
                className: cx("selectable-list-chips-0s9", className, hasError.value ? "has-error" : ""),
                renderToggle: ({showExpand, showingExpand}) => (
                    <>
                        {hasError.value && (
                            <i className="material-icons warning-icon" onClick={() => hasError.onClick()}>
                                warning
                            </i>
                        )}

                        <div className={cx("toggle", toggleClass)} onClick={() => inputRef.get().focus()} ref={domRef}>
                            <div className="chips">
                                {list?.filter(isSelected).map((item, i) => (
                                    <div className="chip" key={i}>
                                        {valueToLabel(item)}

                                        <i
                                            className="fa fa-times-circle"
                                            onMouseDown={(e) => {
                                                e.preventDefault();
                                                onSelect(item);
                                                // list.change((list) => list.filter((i1) => i1 !== item));
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>

                            <input
                                style={{
                                    background: componentTheme?.inputBackgroundColorRGB,
                                }}
                                {...{
                                    readOnly: !searchable,
                                    ref: inputRef.set,
                                    onFocus: () => searching.change((s) => s ?? {text: ""}),
                                    onBlur: () => searching.onChange(null),
                                    ...bindInput(scope(searching, ["text"])),
                                }}
                            />

                            <i className="fa fa-chevron-down" />

                            {!showingExpand && searching.value && Invoke({fn: () => showExpand()})}
                        </div>
                    </>
                ),
                renderExpand: ({width}) => (
                    <VerbScrollbar maxHeight="350px" className="list" style={{minWidth: width}}>
                        {chain(
                            list,
                            (_) => _?.filter((item) => isMatchText(item, searching.value?.text)),
                            (_) =>
                                _?.map((l, i) => (
                                    <div
                                        key={i}
                                        className={cx("item", menuItemClass, {
                                            selected: isSelected?.(l),
                                            focused: searching.value?.focusIndex === i,
                                        })}
                                        onMouseDown={() => {
                                            onSelect(l);
                                            // close();
                                        }}
                                    >
                                        {valueToLabel(l)}
                                    </div>
                                ))
                        )}
                    </VerbScrollbar>
                ),
            });
        }
    );
