import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {stateToSelect} from "@common/ui-components/form/state-to-select";
import {scope} from "@common/react/scope";

export const AxisLocationSelect = (state, isHorizontal) =>
    DropdownSelect({
        label: "Axis Location",
        list: isHorizontal ? horizontal : vertical,
        ...stateToSelect(state, ["value"]),
        valueToLabel: (v) => v.label,
    });

const vertical = [
    {label: "Left", value: "LeftTop"},
    {label: "Right", value: "RightBottom"},
];

const horizontal = [
    {label: "Top", value: "LeftTop"},
    {label: "Bottom", value: "RightBottom"},
];
