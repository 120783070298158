const {spc} = require("@common/react/state-path-change");
const {cs} = require("@common/react/chain-services");
const {isDataView, isAggregatedMeasure} = require("../common/transformation-utils");
const {consumeContext} = require("@common/react/context");
const {UseStaticState} = require("@common/react/static-state");

const TransformationInteractions = ({transformation, updatePositionQueue, next}) =>
    cs(
        consumeContext("routing"),
        [
            "setting",
            ({routing}, next) =>
                UseStaticState({
                    getInitValue: () => {
                        const firstStep = transformation.value?.steps?.[0];

                        const initOpenForView = isDataView(transformation.value) && transformation.value?.steps?.length === 2 && firstStep?.$type === "ModelTableDatasetStep";

                        const initOpenForAggregatedMeasure =
                            isAggregatedMeasure(transformation.value) &&
                            transformation.value?.steps?.length === 2 &&
                            firstStep?.$type === "AggregatedModelTableDatasetStep" &&
                            routing.params.isNew;

                        if (initOpenForView || initOpenForAggregatedMeasure) {
                            return {
                                data: {stepId: firstStep.id},
                                tab: "configuration",
                            };
                        }
                    },
                    next,
                }),
        ],
        ({setting}) => {
            return next({
                openRightPanel: ({stepId, tab}) => {
                    setting.onChange({
                        data: {stepId},
                        tab: tab || "configuration",
                    });
                },
                openRightPanelTab: (tab) =>
                    setting.onChange({
                        ...setting.value,
                        tab,
                        showPreview: false,
                    }),
                onCancel: () => {
                    setting.onChange(null);
                },
                togglePreview: (bool) => {
                    spc(setting, ["showPreview"], (showPreview) => bool);
                },
                upsertTransformationPosition: (updated) => {
                    updatePositionQueue.push({updated});
                },
                setting: setting.value,
                getStaticSetting: setting.getStaticValue,
            });
        }
    );
exports.TransformationInteractions = TransformationInteractions;
