import {css, cx} from "emotion";
import {Dropdown} from "../../../dropdown/dropdown";
import * as React from "react";
import "./swap-axis.scss";
import {cs} from "../../../../react/chain-services";
import {GearIcon} from "./icons/gear-icon";
import {RadioButton} from "../../../../../web-client/src/routes/common/radio-button/radio-button";
import {consumeContext} from "../../../../react/context";
import {StyledClass} from "@common/react/styled-class";

export const SwapAxis = ({list, theme, tile}) =>
    cs(
        consumeContext("tileActionControlFilter"),
        [
            "toggleClass",
            ({}, next) =>
                StyledClass({
                    content: {
                        ".gear-icon": {
                            path: {
                                stroke: `${theme.general.components.inputIconColorRGB}`,
                            },
                        },
                    },
                    next,
                }),
        ],
        [
            "renderToggle",
            ({toggleClass}, next) =>
                next(({showExpand, showingExpand}) => (
                    <div className={cx("toggle", {expanding: showingExpand}, toggleClass)} onClick={() => showExpand(!showingExpand)}>
                        {GearIcon()}
                    </div>
                )),
        ],
        [
            "cssClass",
            ({}, next) => {
                const componentTheme = theme.general.components;

                return StyledClass({
                    content: {
                        background: `${componentTheme.menuBackgroundColorRGB}`,
                        color: `${componentTheme.menuTextColorRGB}`,
                        "&:hover": {
                            background: `${componentTheme.menuHoverBackgroundColorRGB} !important`,
                        },

                        "&.selected": {
                            background: `${componentTheme.menuHoverBackgroundColorRGB} !important`,
                        },
                    },
                    next,
                });
            },
        ],
        [
            "renderExpand",
            ({tileActionControlFilter, cssClass}, next) =>
                next(({close, width}) => {
                    const componentTheme = theme.general.components;

                    const textStyle = {
                        color: componentTheme.menuTextColorRGB || "#546B81",
                        fontFamily: `"${theme.general.canvas.fontFamily}", sans-serif`,
                    };

                    return (
                        <div
                            className="list"
                            style={{
                                minWidth: width,
                                background: componentTheme.menuBackgroundColorRGB,
                            }}
                        >
                            {list.map((sublist, i) => (
                                <div className="sublist" key={i}>
                                    {sublist.label && (
                                        <div className="label" style={{...textStyle}}>
                                            {sublist.label}
                                        </div>
                                    )}
                                    <div className="items">
                                        {sublist.choices.map((choice, j) => (
                                            <div
                                                key={`${i}${j}`}
                                                className={cx(
                                                    "item",
                                                    {
                                                        selected: sublist.isSelected(choice),
                                                    },
                                                    cssClass
                                                )}
                                                style={{...textStyle}}
                                                onMouseDown={() => {
                                                    sublist.onSelect(choice);
                                                    if (
                                                        tileActionControlFilter?.value?.action?.value &&
                                                        tileActionControlFilter?.value?.tile.id == tile.id
                                                    ) {
                                                        tileActionControlFilter.onChange({
                                                            tile: tileActionControlFilter?.value?.hasPrevTileAction
                                                                ? null
                                                                : tileActionControlFilter?.value.tile,
                                                            action: {
                                                                ...tileActionControlFilter?.value.action,
                                                                value: null,
                                                            },
                                                        });
                                                    }
                                                    setTimeout(() => {
                                                        close();
                                                    }, 100);
                                                }}
                                            >
                                                {RadioButton({
                                                    color: theme.general.buttons.primaryButton.backgroundColorRGB,
                                                    selected: sublist.isSelected(choice),
                                                })}
                                                {sublist.valueToLabel(choice)}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    );
                }),
        ],
        ({renderToggle, renderExpand}) => (
            <div className={cx("swap-axis swap-axis-90s verb-tile-menu")}>
                {Dropdown({
                    registryRender: true,
                    detectOnWheelEvent: true,
                    expandClassNames: "swap-axis-expand-f45",
                    renderToggle,
                    renderExpand,
                    borderRadius: theme.general.components.menuCornerRadius,
                })}
            </div>
        )
    );
