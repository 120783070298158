import { az } from "@common/utils/common";

export const parseHourWithAMAndPM = (hoursNumber, isAM) => (hoursNumber === 12 ? (isAM ? 0 : 12) : hoursNumber + (!isAM ? 12 : 0));

export const timePickerGoingIn = ({hours, minutes, seconds, ...rest}) => {
    return {
        ...rest,
        hours: az(hours === 0 || hours === 12 ? 12 : hours % 12),
        minutes: az(minutes),
        seconds: az(seconds),
        isAM: hours < 12,
    };
};

export const timepickerGettingOut = ({hours, minutes, seconds, isAM, ...rest}) => {
    const hoursNumber = parseInt(hours);

    return {
        ...rest,
        hours: parseHourWithAMAndPM(hoursNumber, isAM),
        minutes: parseInt(minutes),
        seconds: parseInt(seconds),
    };
};

export const getMinTime = (t1, t2) => {
    const time1 = timepickerGettingOut(t1);
    const time2 = timepickerGettingOut(t2);
    
    

    const _t1 = new Date(time1.year, time1.month - 1, time1.day, time1.hours, time1.minutes).getTime();
    const _t2 = new Date(time2.year, time2.month - 1, time2.day, time2.hours, time2.minutes).getTime();


    if (_t1 < _t2) {
        return t1;
    }

    return t2;
};

export const getMaxTime = (t1, t2) => {
    const time1 = timepickerGettingOut(t1);
    const time2 = timepickerGettingOut(t2);

    const _t1 = new Date(time1.year, time1.month - 1, time1.day, time1.hours, time1.minutes).getTime();
    const _t2 = new Date(time2.year, time2.month - 1, time2.day, time2.hours, time2.minutes).getTime();

    if (_t1 > _t2) {
        return t1;
    }

    return t2;
};
