const {getDPath} = require("../render-chart/render-chart-helper");
const {groupBy, sum, reverse} = require("../../../utils/collections");
const Highcharts = require("highcharts/highstock");

const rel = Highcharts.relativeLength;

const isColumnType = (series) => ["bar", "column"].includes(series.__proto__.type);

const isValidPoint = (point) => point?.shapeArgs?.x != undefined || point?.graphic;

const generateRoundCornerClipPaths = (stack, isStack, chart, pointsType = "points") => {
    const renderer = chart.renderer;
    const {plotOptions} = chart.options;
    let roundBorderClipPaths = [];
    const width = Math.round(stack[0].barW);

    const topLeftR = rel(plotOptions.bar.borderRadiusTopLeft || plotOptions.column.borderRadiusTopLeft || 0);
    const topRightR = rel(plotOptions.bar.borderRadiusTopRight || plotOptions.column.borderRadiusTopRight || 0);
    const bottomRightR = rel(plotOptions.bar.borderRadiusBottomRight || plotOptions.column.borderRadiusBottomRight || 0);
    const bottomLeftR = rel(plotOptions.bar.borderRadiusBottomLeft || plotOptions.column.borderRadiusBottomLeft || 0);

    const cornerOptions = {
        rTopLeft: topLeftR,
        rTopRight: topRightR,
        rBottomRight: bottomRightR,
        rBottomLeft: bottomLeftR,
    };

    const negativeCornerOptions = {
        rTopLeft: bottomLeftR,
        rTopRight: bottomRightR,
        rBottomRight: topRightR,
        rBottomLeft: topLeftR,
    };

    const hasBorder = sum(Object.values(cornerOptions).map((v) => parseInt(v)));

    if (hasBorder <= 0) {
        return roundBorderClipPaths;
    }

    if (width > 2) {
        if (isStack) {
            const points = stack.reduce((arrPoints, currSeries) => {
                return arrPoints.concat(currSeries[pointsType]?.filter((p) => isValidPoint(p)) || []);
            }, []);

            const columns = groupBy(points, (point) => point.shapeArgs.x);

            roundBorderClipPaths = columns
                .map((points) => {
                    const isNegativeColumn = !!points.find((p) => p.y < 0);

                    const {x, y, width, height} = (isNegativeColumn ? reverse(points) : points).reduce((config, point) => {
                        if (config.x == undefined && point.shapeArgs) {
                            config = {
                                ...point.shapeArgs,
                            };
                        } else {
                            config.height += point.shapeArgs.height;
                        }

                        return config;
                    }, {});

                    if (x == undefined || y == undefined || width == undefined || height == undefined) {
                        return null;
                    }

                    const id = Highcharts.uniqueKey();
                    const clipPath = renderer
                        .createElement("clipPath")
                        .attr({
                            id,
                        })
                        .add(renderer.defs);

                    renderer
                        .path(
                            getDPath({
                                ...(isNegativeColumn ? negativeCornerOptions : cornerOptions),
                                x,
                                y,
                                width,
                                height,
                            })
                        )
                        .add(clipPath);

                    points.forEach((point) => {
                        point.graphic?.element.setAttribute("clip-path", `url(#${id})`);
                    });

                    return clipPath;
                })
                .filter((c) => !!c);
        } else {
            stack.forEach((series) => {
                if (series[pointsType]?.length > 0) {
                    roundBorderClipPaths = roundBorderClipPaths.concat(
                        series[pointsType]
                            .filter((p) => p.shapeArgs && p.graphic)
                            .map(({shapeArgs, graphic, y}) => {
                                const id = Highcharts.uniqueKey();
                                const clipPath = renderer
                                    .createElement("clipPath")
                                    .attr({
                                        id,
                                    })
                                    .add(renderer.defs);

                                renderer
                                    .path(
                                        getDPath({
                                            ...(y < 0 ? negativeCornerOptions : cornerOptions),
                                            x: shapeArgs.x,
                                            y: shapeArgs.y,
                                            width: shapeArgs.width,
                                            height: shapeArgs.height,
                                        })
                                    )
                                    .add(clipPath);

                                graphic?.element.setAttribute("clip-path", `url(#${id})`);

                                return clipPath;
                            })
                    );
                }
            });
        }
    }

    return roundBorderClipPaths;
};

const drawRoundCorners = function () {
    const chart = this;
    const stacks = groupBy(
        chart.series.filter((s) => s.visible && isColumnType(s)),
        (serie) => serie.stackKey,
        true
    );

    if (chart.roundBorderClipPaths) {
        chart.roundBorderClipPaths.forEach((clipPath) => clipPath.destroy());
        chart.roundBorderClipPaths = chart.roundBorderClipPaths.filter((item) => item.element);
    }

    for (let key in stacks) {
        let roundBorderClipPaths = generateRoundCornerClipPaths(stacks[key], key != "undefined", chart);
        roundBorderClipPaths = roundBorderClipPaths.concat(generateRoundCornerClipPaths(stacks[key], key != "undefined", chart, "comparisonPoints"));

        if (roundBorderClipPaths?.length > 0) {
            chart.roundBorderClipPaths = (chart.roundBorderClipPaths || []).concat(roundBorderClipPaths);
        }
    }
};

exports.drawRoundCorners = drawRoundCorners;
