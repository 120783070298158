import * as React from "react";
import {cs} from "../../../../../../../../../../common/react/chain-services";
import {SingleFieldWell} from "./common/single-field-well/single-field-well";
import {formToSingleFieldWell} from "./common/single-field-well/form-to-single-field-well";
import {updateLimitSortingInTile, updateMeasureFieldsGroupsInTile, updateSingleFieldInTile} from "./common/update-tile";
import {getAxisSortingUpdated, getAxisSortingUpdatedByLimit} from "./common/limit-sorting/sorting/axis/get-axis-sorting-updated";
import {getLimitUpdated} from "./common/limit-sorting/limit/get-limit-updated";
import {getTileActionsUpdated} from "../actions/get-tile-actions-updated";
import {consumeContext, provideContext} from "../../../../../../../../../../common/react/context";
import {getTileFields} from "./common/get-tile-fields";
import {MeasureFieldGroups} from "./common/measure-field-groups/measure-field-groups";
import {isEmpty} from "../../../../../../../../../../common/utils/objects";
import {Expandable} from "../../../../../../../common/expandable/expandable";
import {Limit} from "./common/limit-sorting/limit/limit";
import {getPath} from "../../../../../../../../../../common/utils/arr-path";
import {AxisSorting} from "./common/limit-sorting/sorting/axis/axis-sorting";
import {GroupSorting} from "./common/limit-sorting/sorting/group/group-sorting";
import {scope} from "@common/react/scope";
import {getColumnType} from "./common/select-field/select-field";

export const BoxPlotFields = ({tileActions, tile, form, next}) =>
    cs(
        consumeContext("getFieldInfo"),
        [
            "tileFields",
            ({getFieldInfo}, next) =>
                next(
                    getTileFields({
                        tile: tile.value,
                        dimensionAttr: "xAxisField",
                        measureGroupAttrs: ["yAxisFields"],
                        getFieldInfo,
                    })
                ),
        ],
        ({tileFields}, next) => provideContext("tileFields", tileFields, next),

        [
            "xAxisField",
            ({tileFields}, next) =>
                SingleFieldWell({
                    ...formToSingleFieldWell(form, ["xAxisField"]),
                    $type: "DimensionTileField",
                    onUpdateTile: (newVal, oriVal) =>
                        updateSingleFieldInTile({
                            fieldAttr: "xAxisField",
                            fieldVal: {newVal, oriVal},
                            tileFields,
                            tile,
                            updateSorting: getAxisSortingUpdated,
                            updateLimit: getLimitUpdated,
                            updateTileActions: getTileActionsUpdated,
                        }),
                    label: "X-Axis",
                    next,
                }),
        ],

        [
            "yAxisField",
            ({tileFields}, next) =>
                SingleFieldWell({
                    hideAggregationSelect: true,
                    hideAggregationType: true,
                    field: scope(tile, ["yAxisField"]),
                    filterColumn: (column) => {
                        const type = getColumnType(column);
                        return type === "number";
                    },
                    onUpdateTile: (newVal, oriVal) =>
                        updateSingleFieldInTile({
                            fieldAttr: "yAxisField",
                            fieldVal: {newVal, oriVal},
                            tileFields,
                            tile,
                            updateSorting: getAxisSortingUpdated,
                            updateLimit: getLimitUpdated,
                            updateTileActions: getTileActionsUpdated,
                        }),
                    $type: "MeasureTileField",
                    label: "Y-Axis",
                    next,
                }),
        ],

        [
            "fieldsSection",
            ({xAxisField, yAxisField}, next) =>
                next({
                    render: () =>
                        Expandable({
                            label: "Fields",
                            render: () => (
                                <>
                                    {xAxisField.render()}
                                    {yAxisField.render()}
                                </>
                            ),
                        }),
                    override: xAxisField.override || yAxisField.override,
                }),
        ],

        [
            "limitSorting",
            ({tileFields}, next) =>
                cs(
                    [
                        "sorting",
                        (_, next) =>
                            next(
                                <>
                                    {AxisSorting({fieldGroups: form.field(["sort"]).state, title: "X-Axis"})}
                                    {tile.value.groupField && GroupSorting({form, tile})}
                                </>
                            ),
                    ],
                    ({limit, sorting}) =>
                        next({
                            render: () =>
                                Expandable({
                                    initExpand: false,
                                    label: "Limit",
                                    render: () => <>{sorting}</>,
                                }),
                        })
                ),
        ],

        ({limitSorting, fieldsSection}) =>
            next({
                render: () => {
                    return (
                        <div className="tile-fields-3vr">
                            {fieldsSection.render()}
                            {limitSorting.render()}
                            {tileActions?.render?.()}
                        </div>
                    );
                },
                // hasError: !form.group("fields").looksValid,
                override: fieldsSection.override || tileActions?.override({fieldType: "MeasureTileField"}),
                overrideAll: tileActions?.overrideAll,
            })
    );
