import "./mobile-filters-panel.scss";

import { css, cx } from "emotion";
import moment from "moment-timezone";
import React from "react";

import { cs } from "@common/react/chain-services";
import { consumeContext } from "@common/react/context";

import { StyledClass } from "@common/react/styled-class";
import { FilterForms } from "@common/ui-components/live/live-dashboard/data/filter-forms/filter-forms";
import { getCollectionFilters } from "@common/ui-components/live/live-dashboard/live-filters/get-collection-filters";
import { getCssDialog } from "@common/ui-components/live/live-dashboard/live-filters/layout/live-filters-desktop/more/more-filter-dialog";
import { sort } from "@common/utils/collections";
import { DIALOG_STYLES } from "../../../../../../../../web-client/src/routes/common/dialog/dialog";
import { DialogService } from "../../../../../../../../web-client/src/routes/common/dialog/dialog-service";
import { getCurrentTzName } from "../../../../../../../utils/dates/date-object";
import { VerbDialogBodyScrollbar } from "../../../../../../verb-scrollbar/verb-dialog-body-scrollbar";
import { SingleSelectDropdown } from "../../../filter-types/text/selectable-list/single-select-dropdown/single-select-dropdown";
import { FilterLabel } from "../../common/label/filter-label";
import { ObserveWindowHeight } from "../../observe-window-height";

export const MobileFiltersPanel = ({
    next: rootNext,
    filtersConfig,
    noFilter,
    filterForms,
    renderFilter,
    reportButton,
    theme,
    showTimezoneDropdownInModal = false,
    size,
}) =>
    cs(
        (_, next) =>
            ObserveWindowHeight({
                getSize: (wh) => {
                    const doc = document.documentElement;
                    doc.style.setProperty("--vh", wh + "px");
                },
                next,
            }),
        consumeContext("theme"),
        [
            "dialogCss",
            ({theme}, next) =>
                StyledClass({
                    content: getCssDialog(theme),
                    next,
                }),
        ],
        [
            "modal",
            ({collection, dialogCss}, next) => {
                return DialogService({
                    render: ({resolve, args: {customWindowWidth, forceToShowTimezoneDropdown = false} = {}}) => ({
                        title: "Filters",
                        ...(window.innerWidth > 768 ? {width: customWindowWidth || 560} : {width: "100%"}),
                        content: next({
                            resolve,
                            forceToShowTimezoneDropdown,
                        }),
                    }),
                    styleType: DIALOG_STYLES.SLIDE_UP,
                    next: rootNext,
                    className: dialogCss,
                });
            },
        ],
        consumeContext("selectedTimezone"),
        [
            "localFilterForms",
            (_, next) =>
                FilterForms({
                    filters: filtersConfig.collection.value.filters,
                    filterVals: filterForms.getAllFilterValues(),
                    next,
                }),
        ],
        [
            "localCollectionFilters",
            ({localFilterForms}, next) =>
                getCollectionFilters({
                    filtersConfig,
                    filterStyle: {
                        runType: "OnSubmit",
                    },
                    filterForms: localFilterForms,
                    next,
                }),
        ],
        ({modal, selectedTimezone, theme, localCollectionFilters, localFilterForms}) =>
            cs(() => {
                const localTimezone = getCurrentTzName();

                const isSelected = (item) => item.value === selectedTimezone.value;

                const filters1 = sort(
                    localCollectionFilters
                        .map((filter) => ({
                            ...filter,
                            lines: filter.control({size}),
                            priority: ["Hidden", "BehindMore", "Visible"].findIndex((v) => v === filter.displayArea),
                        }))
                        .filter((filter) => {
                            const isHiddenFilter = noFilter ? true : filter.displayArea === "Hidden";
                            return !(filtersConfig.sdkDashboard && isHiddenFilter);
                        }),
                    (v) => -v.priority
                );

                return (
                    <div
                        className={cx("mobile-filters-panel-a99")}
                        style={{
                            background: `${theme.general.tile.styles.tileBackgroundColorRGB}`,
                        }}
                    >
                        <VerbDialogBodyScrollbar maxHeight={"unset"}>
                            {filters1.map(renderFilter)}

                            {(showTimezoneDropdownInModal || modal.forceToShowTimezoneDropdown) && (
                                <div
                                    className={cx("filter", `verb-filter-timezone`)}
                                    style={{
                                        fontFamily: `"${theme.general.canvas.fontFamily || "Roboto"}", sans-serif`,
                                        color: theme.general.canvas.fontColorRGB || "#294661",
                                    }}
                                >
                                    <div className="label verb-filter-label">{FilterLabel({text: "Time Zone"})}</div>

                                    <div className="control">
                                        <div className="line">
                                            {SingleSelectDropdown({
                                                data: [
                                                    {
                                                        value: null,
                                                        label: "UTC",
                                                    },
                                                    {
                                                        value: localTimezone,
                                                        label: `Local (${moment.tz.zone(localTimezone).abbr(Date.now())})`,
                                                    },
                                                    ...moment.tz.names().map((zone) => ({value: zone, label: zone})),
                                                ],
                                                valueToLabel: (item, isToggle) =>
                                                    !isToggle && isSelected(item) ? (
                                                        <>
                                                            <i
                                                                className={cx("far fa-check")}
                                                                style={{
                                                                    "font-size": "16px",
                                                                    position: "absolute",
                                                                    right: "24px",
                                                                    top: "17px",
                                                                    color: "#18c96e",
                                                                }}
                                                            />{" "}
                                                            {item.label}
                                                        </>
                                                    ) : (
                                                        item.label
                                                    ),
                                                valueToSearch: (item) => item.label,
                                                isSelected,
                                                // onChange: item => selectedTimezone.onChange(item.value),
                                                state: {
                                                    change: (reducer) => {
                                                        const [item] = reducer();
                                                        selectedTimezone.onChange(item.value);
                                                    },
                                                },
                                            })}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </VerbDialogBodyScrollbar>

                        <div className="dialog-footer">
                            {reportButton?.({
                                onClick: () => {
                                    let allFilterValues = localFilterForms.getAllFilterValues();
                                    Object.keys(allFilterValues).forEach((key) => {
                                        filterForms.setLiveValue(key, allFilterValues[key]);
                                    });

                                    modal.resolve();
                                },
                            })}
                        </div>
                    </div>
                );
            })
    );
