// const {sort} = require("../../../../../../../common/utils/collections");

const getFolderDepthOrderList = (folders, ignoreFolderID) => {
    if (!folders) {
        return [];
    }

    const leveledFolders = folders
        .filter((f) => !f.type)
        .map((f) => ({...f, level: getLevel(f, folders)}))
        .filter((f) => f.level != null);
    const tree = listToTree(leveledFolders);
    const list2 = [];
    depthFirstTraversal2(tree, (child) => list2.push(child), ignoreFolderID ? (child) => child.id != ignoreFolderID : null);
    return list2;

    // const [root, ...folders1] = sort(leveledFolders, (f) => !f.id ? 0 : f.level);

    // return [
    //     root,
    //     ...depthFirstTreeSort(folders1, (f1, f2) => f2.name > f1.name),
    // ];
};
exports.getFolderDepthOrderList = getFolderDepthOrderList;

// const getFullPathFolderDepthList = (folders) => {
//     if (!folders) {
//         return [];
//     }
//
//     const leveledFolders = folders.filter((f) => !f.type).map((f) => ({...f, level: getLevel(f, folders)}));
//
//     const [root, ...folders1] = sort(leveledFolders, (f) => !f.id ? 0 : f.level);
//
//     return [
//         root,
//         ...depthFirstTreeSort(folders1, (f1, f2) => f2.name > f1.name),
//     ];
// };
// exports.getFullPathFolderDepthList = getFullPathFolderDepthList;

const makeTree = (arr) => {
    let tree = {};
    for (let i = 0; i < arr.length; i++) {
        const key = arr[i].parentFolderID;
        if (!tree[key]) {
            tree[key] = [];
        }
        tree[key].push(arr[i]);
    }
    return tree;
};

const depthFirstTraversal = (tree, id, compareFn, cb) => {
    let children = tree[id];
    if (children) {
        children.sort(compareFn);
        for (let i = 0; i < children.length; i++) {
            cb(children[i]);
            depthFirstTraversal(tree, children[i].id, compareFn, cb);
        }
    }
};

const depthFirstTraversal2 = (tree, cb, filter) => {
    for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (filter && !filter(node)) {
            continue;
        }

        cb(node);
        let children = node.children;
        if (children) {
            depthFirstTraversal2(children, cb, filter);
        }
    }
};

const depthFirstTreeSort = (arr, compareFn) => {
    let ret = [];

    depthFirstTraversal(makeTree(arr), null, compareFn, (node) => ret.push(node));

    return ret;
};

const getLevel = (folder, folders, level = 1) => {
    // to handle data error, for some reason a QA folder has these ids as the same.
    if (folder.id && folder.parentFolderID && folder.id === folder.parentFolderID) {
        return;
    }

    if (!folder.id) {
        return 0;
    }

    if (folder.parentFolderID == null) {
        return level;
    }

    return getLevel(
        folders.find((f) => f.id === folder.parentFolderID),
        folders,
        level + 1
    );
};

function listToTree(_list, sortBy) {
    const map = {};
    let node;
    const roots = [];
    let i;

    const rootNode = _list.find((node) => !node.id);
    const list = _list.filter((node) => node.id);

    for (i = 0; i < list.length; i += 1) {
        map[list[i].id] = i; // initialize the map
        list[i].children = []; // initialize the children
    }

    for (i = 0; i < list.length; i += 1) {
        node = list[i];
        if (node.parentFolderID !== null) {
            // if you have dangling branches check that map[node.parentFolderID] exists
            list[map[node.parentFolderID]].children = list[map[node.parentFolderID]].children.concat(node);
            if (sortBy) {
                list[map[node.parentFolderID]].children = list[map[node.parentFolderID]].children.sort(sortBy);
            }
        } else {
            roots.push(node);
        }
    }

    return [
        {
            ...rootNode,
            children: roots,
        },
    ];
}
