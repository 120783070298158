const tileDefaultSizeRules = {
    SingleKPITile: {width: 4, height: 3},
    TableKPITile: {width: 5, height: 5},
    ListKPITile: {width: 5, height: 5},
    GaugeTile: {width: 5, height: 5},
    VerticalBarChartTile: {width: 5, height: 5},
    HorizontalBarChartTile: {width: 5, height: 5},
    LineChartTile: {width: 5, height: 5},
    PieChartTile: {width: 5, height: 5},
    DownloadReportTile: {width: 3, height: 2},
    TableTile: {width: 5, height: 5},
    PivotTableTile: {width: 12, height: 8},
    ComboChartTile: {width: 5, height: 5},
    ContainerTile: {width: 6, height: 5},
    MapTile: {width: 5, height: 5},
    FunnelChartTile: {width: 5, height: 5},
    VennDiagramTile: {width: 5, height: 5},
    SectionTile: {width: 24, height: 1},
    TextTile: {width: 3, height: 2},
    BoxPlotTile: {width: 5, height: 5},
    ScatterPlotTile: {width: 8, height: 7},
    BubbleChartTile: {width: 8, height: 7},
    TextLabelTile: {width: 4, height: 3},
};

exports.tileDefaultSizeRules = tileDefaultSizeRules;

const tileMinSizeRules = {
    SingleKPITile: {width: 3, height: 2},
    TableKPITile: {width: 3, height: 2},
    ListKPITile: {width: 3, height: 3},
    GaugeTile: {width: 3, height: 3},
    VerticalBarChartTile: {width: 3, height: 3},
    HorizontalBarChartTile: {width: 3, height: 3},
    LineChartTile: {width: 3, height: 3},
    PieChartTile: {width: 3, height: 3},
    DownloadReportTile: {width: 2, height: 1},
    TableTile: {width: 4, height: 3},
    PivotTableTile: {width: 12, height: 4},
    ComboChartTile: {width: 3, height: 3},
    ContainerTile: {width: 3, height: 3},
    MapTile: {width: 3, height: 3},
    FunnelChartTile: {width: 4, height: 3},
    VennDiagramTile: {width: 4, height: 3},
    SectionTile: {width: 24, height: 1},
    TextTile: {width: 3, height: 2},
    BoxPlotTile: {width: 3, height: 2},
    ScatterPlotTile: {width: 8, height: 5},
    BubbleChartTile: {width: 8, height: 5},
    SparkLineKPITile_LeftRight: {width: 4, height: 2},
    SparkLineKPITile_RightLeft: {width: 4, height: 2},
    SparkLineKPITile_TopBottom: {width: 3, height: 3},
    SparkLineKPITile_BottomTop: {width: 3, height: 3},
    TextLabelTile: {width: 2, height: 1},
};

exports.tileMinSizeRules = tileMinSizeRules;

const exactDimensionTileRule = {
    SectionTile: {width: 24, height: 1},
};

exports.exactDimensionTileRule = exactDimensionTileRule;
