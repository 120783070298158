import "./filter-options-tab.scss";

import * as React from "react";

import {cs} from "@common/react/chain-services";
import {Options} from "../options/options";
// import {Customizations} from "../customizations/customizations";

export const FilterOptionsTab = ({filter, form, showCustomizations}) =>
    cs(({}) => {
        return (
            <div className="filter-options-tab-5y3">
                {Options({filter, form})}

                {/*{showCustomizations && Customizations({filter})}*/}
            </div>
        );
    });
