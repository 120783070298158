import React from "react";
import "./toast-with-action.scss";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {cx} from "emotion";
import {waitTimeout} from "@common/utils/wait-timeout";
import {Static2} from "@common/react/static-2";

export const ToastWithAction = ({next}) =>
    cs(
        ["initDisplay", (_, next) => UseState({next})],
        ["display", (_, next) => UseState({next})],
        ["timeout", (_, next) => Static2({next})],
        [
            "alert",
            (_, next) =>
                UseState({
                    initValue: {
                        text: "",
                        action: {text: "", onClick: () => {}},
                        // isError
                    },
                    next,
                }),
        ],
        ({initDisplay, display, alert, timeout}) =>
            next({
                render: () =>
                    initDisplay.value && (
                        <div className={cx("toast-with-action", display.value && "display")}>
                            <div className="toast-title">{alert.value?.text}</div>

                            <div
                                className={cx("icon", {
                                    success: !alert.value?.isError,
                                })}
                            >
                                {alert.value?.isError ? (
                                    <img src={require("@common/ui-components/charts/common/loading-wrapper/error-handler/data-error-icon.svg")} alt="" />
                                ) : (
                                    <img src={require("./check-icon.svg")} alt="" />
                                )}
                            </div>

                            {alert.value?.action && (
                                <div className="bottom-action">
                                    <span onClick={() => alert.value?.action?.onClick?.()}>{alert.value?.action.text}</span>
                                </div>
                            )}
                        </div>
                    ),
                show: async (data) => {
                    const close = async () => {
                        clearTimeout(timeout.get());
                        display.onChange(false);
                        await waitTimeout(300);
                        initDisplay.onChange(false);
                    };

                    if (initDisplay.value) {
                        await close();
                    }

                    alert.onChange(data);
                    initDisplay.onChange(true);
                    await waitTimeout(10);
                    display.onChange(true);

                    let t = setTimeout(() => {
                        close();
                    }, 5000);
                    timeout.set(t);
                },
            })
    );
