import React from "react";
import {cs} from "@common/react/chain-services";
import "./group-data-configuration.scss";
import {SelectColumns} from "../../../../common/select-columns/select-columns";
import {rColumnControl} from "../../common/column-control/column-control";
import {rColumnSelectFromStepOutput} from "../../../../common/column-select-from-step-output/column-select-from-step-output";
import {removeIndex} from "@common/utils/collections";
import {rFieldTypeIcon} from "../../../../../../common/field-type-icon/get-field-type-icon";
import {AggregationSelect} from "../../../../common/aggregation-select/aggregation-select";
import {cx} from "emotion";
import {ffToDropdown} from "@common/form/ff-to-dropdown";
import {keepOnly} from "@common/utils/objects";
import {spc} from "@common/react/state-path-change";
import {getPath} from "@common/utils/arr-path";
import {rNewColumnNameInput} from "../../common/new-column-name-input";
import {GhostButton} from "@common/form/buttons/ghost-button/ghost-button";
import {PlusIcon} from "@common/ui-components/icons/global-icons";
import {IgnoreUpdate} from "@common/react/ignore-update";
import {UseMemo} from "@common/react/use-memo";

export const GroupDataConfiguration = ({transformation, step, form}) => {
    const prevStep = transformation.value.steps.find((s) => s.id === step.value.inputStepID);

    return (
        <div className="group-data-configuration-3sf">
            <div className="config-group">
                <div className="label">Select Columns to group by</div>

                {SelectColumns({
                    step,
                    form,
                    path: ["groupByColumns"],
                    columns: prevStep.outputColumns,
                })}
            </div>

            <div className="config-group">
                <div className="label">New aggregate columns</div>
                {AggregateColumns({
                    step,
                    form,
                    path: ["aggregateColumns"],
                    columns: prevStep.outputColumns,
                    isColumnUnavailable: (c) => {
                        const doesInclude = (prop) =>
                            step.value.groupByColumns
                                ?.map((c1) => c1?.[prop])
                                .filter((v) => v)
                                .includes(c[prop]);
                        return doesInclude("modelColumnID") || doesInclude("name");
                    },
                })}
            </div>
        </div>
    );
};

const AggregateColumns = ({step, form, path, columns, isColumnUnavailable}) =>
    cs(
        [
            "columnSelect",
            (_, next) =>
                next(
                    rColumnSelectFromStepOutput({
                        label: "Column",
                        columns,
                        valueToLabel: ({column, modelColumns}) => {
                            const unavailable = isColumnUnavailable(column);
                            const column1 = ["ViewStepColumn", "GroupAggColumn"].includes(column.$type) ? column : modelColumns.find((c) => c.id === column.modelColumnID);
                            return (
                                <div
                                    className={cx("column-label", {
                                        // "not-model-column": ["ViewStepColumn", "GroupAggColumn"].includes(column.$type),
                                        unavailable,
                                    })}
                                >
                                    {rFieldTypeIcon(column1?.dataType)}
                                    <div className="text">
                                        {column1?.name}
                                        {modelColumns.find((c) => c.id !== column.modelColumnID && c.name === column1.name) && ` [${column1.tableName}]`}
                                    </div>

                                    {unavailable && <div className="note">Unavailable</div>}
                                </div>
                            );
                        },
                        isUnavailable: isColumnUnavailable,
                    })
                ),
        ],
        [
            "columnSelector",
            ({columnSelect}, next) =>
                next((ci) =>
                    cs(
                        [
                            "selector",
                            (_, next) =>
                                UseMemo({
                                    fn: () => {
                                        const dropdownProps = ffToDropdown(form.field([...path, ci, "columnToAggregate"]));

                                        return columnSelect({
                                            state: {
                                                value: dropdownProps.value,
                                                onChange: (c) => {
                                                    const {value, onChange} = form.field([...path, ci]).state;
                                                    onChange({
                                                        ...value,
                                                        columnToAggregate: c,
                                                        aggregationFunc: ["Int", "Double"].includes(c.dataType) ? "Sum" : "Count",
                                                    });
                                                },
                                            },
                                            ...keepOnly(dropdownProps, ["domRef", "hasError", "errorMessage"]),
                                        });
                                    },
                                    deps: [JSON.stringify(form.field([...path, ci]))],
                                    next,
                                }),
                        ],
                        ({selector}) => selector
                    )
                ),
        ],
        ({columnSelector}) => {
            const aggCols = getPath(step.value, path);

            return (
                <div className="aggregate-columns">
                    {(aggCols?.length > 0 ? aggCols : [{}]).map((agc, ci) =>
                        cs(
                            (_, next) =>
                                IgnoreUpdate({
                                    props: {ci, form},
                                    when: (pp) => pp.ci === ci && JSON.stringify(form.field([...path, ci])) === JSON.stringify(pp.form.field([...path, ci])),
                                    next,
                                }),
                            () => {
                                const propertySelector = (() => {
                                    const dropdownProps = ffToDropdown(form.field([...path, ci, "aggregationFunc"]));

                                    return AggregationSelect({
                                        state: {
                                            value: dropdownProps.value,
                                            onChange: dropdownProps.onChange,
                                        },
                                        hideNone: true,
                                        exceptAggFuncs: ["None", "FirstValueAsc", "FirstValueDesc", "LastValueAsc", "LastValueDesc"],
                                        dataType: agc?.columnToAggregate?.dataType || "Unsupported",
                                        extraAggFuncs: [
                                            {
                                                label: "List Aggregate (GROUP_CONCAT)",
                                                value: "ListAgg",
                                                dataTypes: ["Int", "Double", "Bool", "Text", "IDInt", "IDUUID"],
                                            },
                                        ],
                                        ...keepOnly(dropdownProps, ["domRef", "hasError", "errorMessage"]),
                                    });
                                })();
                                const propertySelector2 = rNewColumnNameInput({
                                    form,
                                    newColumnNamePath: [...path, ci, "name"],
                                    label: "Column Name",
                                });
                                return (
                                    <div className="column" key={ci}>
                                        {rColumnControl({
                                            columnSelector: columnSelector(ci),
                                            propertySelector,
                                            propertySelector2,
                                            onRemove: () => spc(step, path, (cols) => removeIndex(ci, cols)),
                                        })}
                                    </div>
                                );
                            }
                        )
                    )}
                    <GhostButton iconLeft={<PlusIcon />} onClick={() => spc(step, path, (cols) => [...(cols || []), {$type: "GroupAggColumn"}])}>
                        Add
                    </GhostButton>
                </div>
            );
        }
    );
