import {getTooltipFontSizes} from "./get-tooltip-font-sizes";
import {getIndicatorShapes} from "./indicator-shapes";
import {getFieldType} from "../../../common/get-field-type";

/**
 * used in bar chart and combo chart without comparison
 * */
export const getColumnPointsTable = ({tile, theme, extractedPoints, generalInfo, rawData, formatters}) => {
    return `${rBarLinePoints({extractedPoints, tile, theme, formatters})}
        ${rTotalFooter({extractedPoints, generalInfo, theme})}`;
};

/**
 * used in bar chart, line chart and line part of combo chart without comparison
 * */
export const rBarLinePoints = ({extractedPoints, useLineIndicator, tile, theme, formatters}) => {
    const fontSizes = getTooltipFontSizes(theme);
    const indicatorShapes = getIndicatorShapes(theme);
    const showPointName = usePointName({extractedPoints, tile});

    const maxLen = (name) => {
        return !name || ("" + name).length < 50 ? name : (name || "").substring(0, 50).trim() + "...";
    };

    return extractedPoints
        .map((p) => {
            return `<div class="point table-row" style="font-size: ${fontSizes.medium}px">
            <div class="name">
                <span class="indicator" style="color: ${p.color}">
                    ${useLineIndicator ? indicatorShapes["line"] : indicatorShapes["bar"]}
                </span>
                ${maxLen(showPointName ? p.name : p.dimCategory)}
            </div>
            <div class="main-value">${p.valueFormatter(p.value || 0)}</div>
        </div>`;
        })
        .join("");
};

/**
 * decide to use point name or point category
 * */
export const usePointName = ({extractedPoints, tile}) => {
    const isDimDate = getFieldType(tile.xAxisField || tile.yAxisField) === "date";
    const hasGroupField = tile.groupField != null;
    return extractedPoints.length > 1 || isDimDate || hasGroupField;
};

/**
 * footer of table, shows the total
 * used in bar chart, line chart and column part of combo chart without comparison
 * */
export const rTotalFooter = ({extractedPoints, generalInfo, theme}) => {
    if (extractedPoints.length < 2) {
        return "";
    }

    const fontSizes = getTooltipFontSizes(theme);
    const mainTotal = extractedPoints.reduce((total, p) => total + p.value, 0);
    return `
        <div class="total-row table-row" style="font-size: ${fontSizes.small}px">
            <div></div>
            <div class="main-total">${generalInfo.valueFormatter(mainTotal)}</div>
        </div>
    `;
};
