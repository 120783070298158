import React from "react";
import {cs} from "@common/react/chain-services";
import {Load} from "@common/react/load";
import {loadScript} from "../load-script";
import {UseState} from "@common/react/use-state";
import {ErrorGoogleApiAuthDialog} from "../../routes/data-source/common/dialogs/error-google-api-auth-dialog/error-google-api-auth-dialog";

const {scope, developerKey, clientId, appId} = process.env.gDriveConfig;

export const GDrivePicker = ({next, onChange, submit, onUnauthorized = () => {}, afterLoadPicker = () => {}}) =>
    cs(["errorGoogleApiAuthDialog", (_, next) => ErrorGoogleApiAuthDialog({next})], ({errorGoogleApiAuthDialog}) => {
        const gapi = window.gapi;
        let pickerType = "GoogleSheets";

        const onAuthApiLoad = () => {
            const authInstance = gapi.auth2.init({
                client_id: clientId,
                scope: scope,
            });

            const handleAuthResult = async (user, resp) => {
                if (resp) {
                    try {
                        await submit(resp.code);
                    } catch (e) {
                        errorGoogleApiAuthDialog.show({content: e.message});
                        return;
                    }
                }

                const authResult = user.getAuthResponse();

                if (authResult && !authResult.error) {
                    if (onChange) {
                        showPicker({token: authResult.access_token});
                    }
                }
            };

            //TODO: check send auth
            if (authInstance) {
                if (authInstance.isSignedIn.get()) {
                    // authInstance.signIn();
                    //
                    // authInstance.isSignedIn.listen((val) => {
                    //     console.log('is Signed in');
                    //     return handleAuthResult(authInstance.currentUser.get(), null);
                    // });
                    //
                    // authInstance.currentUser.listen((user) => {
                    //     console.log('listen changed');
                    //     return handleAuthResult(user, null);
                    // });
                    handleAuthResult(authInstance.currentUser.get(), null);
                } // need to sign in
                else authInstance.grantOfflineAccess().then((resp) => handleAuthResult(authInstance.currentUser.get(), resp));
            }
        };

        let showPicker = ({token}) => {
            if (token) {
                const viewID = pickerType === "GoogleSheets" ? google.picker.ViewId.SPREADSHEETS : google.picker.ViewId.DOCS;

                const view = new google.picker.DocsView(viewID);
                view.setIncludeFolders(true);
                view.setSelectFolderEnabled(true);

                if (pickerType !== "GoogleSheets") {
                    view.setMimeTypes(
                        "application/vnd.google-apps.spreadsheet,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/vnd.ms-excel.sheet.macroEnabled.12,application/vnd.ms-excel.sheet.binary.macroEnabled.12,text/csv"
                    );
                }

                const pickerCallback = (data) => {
                    if (data.action == google.picker.Action.PICKED) {
                        onChange({...data.docs[0], token});
                    }
                };

                const picker = new google.picker.PickerBuilder()
                    .enableFeature(google.picker.Feature.NAV_HIDDEN)
                    .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
                    .setAppId(appId)
                    .setOAuthToken(token)
                    .addView(view)
                    .setInitialView(view)
                    .setDeveloperKey(developerKey)
                    .setCallback(pickerCallback)
                    .build();
                picker.setVisible(true);
            }
        };

        return next({
            show: ({type}) => {
                pickerType = type;

                if (gapi) {
                    gapi.load("auth2", {
                        callback: () => onAuthApiLoad({type}),
                    });
                    gapi.load("picker");

                    afterLoadPicker?.();
                }
            },
        });
    });
