import {css, cx} from "emotion";
import {tooltipService3, tooltipService4} from "../../../../web-client/src/routes/common/tooltip3/tooltip-service-3";
import {cs} from "../../../react/chain-services";
import {chain} from "../../../utils/fs";
import {getTooltipFontSizes} from "../factory/tooltip/shared-tooltip-formatter/get-tooltip-font-sizes";
import {StyledClass} from "@common/react/styled-class";

export const BlankDateWarning = ({theme, isDimensionRealDate, filters}) =>
    cs(
        (_, next) => {
            const hasIncludeNullDates =
                chain(
                    Object.entries(filters).map(([key, value]) => value),
                    (_) => _.filter((t) => t.$type === "DateFilterValue"),
                    (_) => _.map((e) => e.includeNullDates),
                    (_) => _.filter((v) => v)
                ).length > 0;

            if (isDimensionRealDate && hasIncludeNullDates) return next();
            return null;
        },
        [
            "tooltip",
            (_, next) => {
                const themeMode = theme.dataVisualization.toolTipsAndLegends.tooltipTheme.toLowerCase();
                return tooltipService4({
                    direction: theme?.tooltipHover?.position?.toLowerCase() || "below",
                    className: `${themeMode}-theme`,
                    tooltipContentStyle: {
                        fontSize: getTooltipFontSizes(theme).small,
                        fontFamily: theme.general.canvas.fontFamily,
                        color: themeMode === "light" ? theme.general.canvas.fontColorRGB || "#546B81" : "#E2E2E2",
                    },
                    next,
                });
            },
        ],
        [
            "iconClass",
            ({}, next) =>
                StyledClass({
                    content: {
                        color: `${theme.general.components.inputIconColorRGB}`,
                        "font-size": `17px`,
                    },
                    next,
                }),
        ],
        ({tooltip, iconClass}) => (
            <i
                className={cx("material-icons", iconClass)}
                {...tooltip(() => (
                    <>
                        <strong>Data may be missing.</strong> Data with blank dates will not show up when the X or Y axis is a date value.
                    </>
                ))}
            >
                warning
            </i>
        )
    );
