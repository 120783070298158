import {getTooltipDimensions} from "./get-tooltip-dimensions";
import {isMobile} from "./is-mobile";

export const tooltipPositionerNormal = ({chart, tile, theme, tooltipContent}) => {
    // sometimes the screen is scrolled and that lifts the tooltip position. use this to balance
    const topScroll = window.document.scrollingElement?.scrollTop || 0;

    const chartContainerRect = chart.container.getBoundingClientRect();

    // try inspect body > div.highcharts-tooltip-container to see this padding
    const padding = getHighchartsTooltipPadding(tile);
    const tooltipDims = getTooltipDimensions({
        htmlString: tooltipContent,
        padding,
    });

    let posX;
    let posY;

    //---- determine if tooltip is placed above or below, posY
    const bottomEdgeThreshold = 30;
    const plotBackgroundRect = chart.plotBackground.element.getBoundingClientRect();
    const tempAbovePosY = chartContainerRect.y - tooltipDims.height + 10; // add 10 so that the arrow touches the connector line
    const tempBelowPosY = plotBackgroundRect.y + plotBackgroundRect.height + 10;

    if (theme.dataVisualization.toolTipsAndLegends.position === "Below") {
        // if tooltip fits down bottom then show it at the bottom, else show on top
        const isFitToBottom = tempBelowPosY + tooltipDims.height < window.innerHeight - bottomEdgeThreshold;
        if (isFitToBottom) {
            posY = tempBelowPosY;
            chart.customTooltipPosition = "Below"; // this prop is used in tooltip-connector-helper.js
        } else {
            posY = tempAbovePosY;
            chart.customTooltipPosition = "Above";
        }

        // instead of the above, keep staying on bottom since scrolling is applied. ref. tooltip-options.js
        // posY = tempBelowPosY;
        // chart.customTooltipPosition = "Below"; // this prop is used in tooltip-connector-helper.js
    } else {
        // if tooltip fits on top then show it at the top, else show at bottom
        // const isFitToTop = tooltipDims.height < plotBackgroundRect.y + plotBackgroundRect.height - 10;
        // if (isFitToTop) {
        //      posY = Math.max(tempAbovePosY, 0); // for tooltip to always stay below top edge of screen
        //      chart.customTooltipPosition = "Above";
        // } else {
        //      posY = tempBelowPosY;
        //      chart.customTooltipPosition = "Below";
        // }

        // instead of the above, keep staying on top since scrolling is applied. ref. tooltip-options.js
        posY = Math.max(tempAbovePosY, 0); // for tooltip to always stay below top edge of screen
        chart.customTooltipPosition = "Above";
    }

    // const posY = chartContainerRect.y + point.plotY + tooltipDims.height > window.innerHeight - 50
    //     ? Math.min(chartContainerRect.bottom - 10, window.innerHeight - 50) - tooltipDims.height
    //     : chartContainerRect.y + point.plotY;

    const offsetParentRect = chart.container.offsetParent.getBoundingClientRect();

    //--- determine posX
    const isOnMobile = isMobile();
    // need to investigate more on this threshold, this is for not causing scrollbar
    const leftRightEdgeThreshold = isOnMobile ? 0 : 20;

    if (chart.hoverPoint) {
        if (chart.scrollingContainer) {
            const scrollingContainerRect = chart.scrollingContainer.getBoundingClientRect();
            const plotBackgroundRect = chart.plotBackground.element.getBoundingClientRect();
            const distanceFromLeftEdge = chart.hoverPoint.plotX - (scrollingContainerRect.x - plotBackgroundRect.x);
            const tempPosX = Math.floor(distanceFromLeftEdge + scrollingContainerRect.x) - tooltipDims.width / 2;

            if (tempPosX + tooltipDims.width > window.innerWidth - leftRightEdgeThreshold) {
                // right edge
                posX = isOnMobile
                    ? scrollingContainerRect.right - tooltipDims.width // need padding?
                    : window.innerWidth - tooltipDims.width; // use this if use the right screen edge as the limit
                // : offsetParentRect.right - tooltipDims.width; // need padding? // use this if use the right border of tile as the limit
            } else if (tempPosX < leftRightEdgeThreshold) {
                // left edge
                posX = isOnMobile ? 0 : scrollingContainerRect.x;
            } else {
                posX = tempPosX;
            }
        } else {
            const plotBackgroundRect = chart.plotBackground.element.getBoundingClientRect();
            const tempPosX = chart.hoverPoint.plotX + plotBackgroundRect.x - tooltipDims.width / 2;

            if (tempPosX + tooltipDims.width > window.innerWidth - leftRightEdgeThreshold) {
                // right edge
                // posX = offsetParentRect.right - tooltipDims.width; // need padding? // use this if use the right border of tile as the limit
                posX = window.innerWidth - tooltipDims.width; // use this if use the right screen edge as the limit
            } else if (tempPosX < leftRightEdgeThreshold) {
                // left edge
                posX = isOnMobile ? 0 : chartContainerRect.x;
            } else {
                posX = tempPosX;
            }
        }
    }

    return {
        x: posX,
        y: posY + topScroll,
    };
};

// try inspect body > div.highcharts-tooltip-container to see this padding as the top position of elem
export const getHighchartsTooltipPadding = (tile) => {
    return ["LineChartTile", "PieChartTile", "DonutChartTile", "MapTile", "FunnelChartTile", "VennDiagramTile"].includes(tile.$type)
        ? 16
        : 6;
};
