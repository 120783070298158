const {equalDeep} = require("../../../../common/utils/objects");
const {Static} = require("@common/react/static");
const {Interval} = require("@common/react/interval");
const {changePath} = require("../../../../common/utils/arr-path");
const {changeContext} = require("@common/react/context");
const {cs} = require("@common/react/chain-services");

const cacheTileDataApi = ({next}) =>
    cs(
        [
            "cache",
            (_, next) =>
                Static({
                    getInitValue: () => [], // {params, promise, createdAt, apiName}
                    next,
                }),
        ],
        ({cache}, next) =>
            Interval({
                fn: () => {
                    for (let i = cache.length - 1; i > -1; i--) {
                        const r = cache[i];
                        if (r.createdAt < Date.now() - 15 * 60 * 1000) {
                            cache.splice(i, 1);
                        }
                    }
                },
                delay: 60 * 1000,
                next,
            }),
        ({cache}, next) =>
            changeContext(
                "apis",
                (apis) => {
                    if (!apis.collection) {
                        return apis;
                    }

                    const cacheGetTileData = (getTileData, apiName) => async (params) => {
                        const c1 = cache.find((r) => r.apiName === apiName && equalDeep(r.params, params));
                        if (c1) {
                            return c1.data;
                        } else {
                            try {
                                const data = await getTileData(params);
                                cache.push({
                                    params: JSON.parse(JSON.stringify(params)),
                                    data,
                                    apiName,
                                    createdAt: Date.now(),
                                });
                                // console.log("Not found, pushing");
                                return data;
                            } catch (err) {
                                throw err;
                            }
                        }
                    };

                    return changePath(apis, ["collection"], (collectionApis) => ({
                        ...collectionApis,
                        getTileData: cacheGetTileData(collectionApis.getTileData, "getTileData"),
                        getTileData2: cacheGetTileData(collectionApis.getTileData2, "getTileData2"),
                    }));
                },
                next
            ),

        next
    );
exports.cacheTileDataApi = cacheTileDataApi;
