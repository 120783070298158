import React from "react";
import {cs} from "@common/react/chain-services";
import {DropdownSelectSearch} from "@common/ui-components/dropdown-select/dropdown-select-search";
import {sort} from "@common/utils/collections";
import "./table-select.scss";

export const TableSelect = ({state, tables, label, isUnavailable, ...props}) =>
    cs(({}) => {
        return (
            <div className="table-select table-select-hd2">
                {DropdownSelectSearch({
                    ...props,
                    label,
                    searchPlaceholder: "Find table",
                    list: sort(tables, (t) => (t.$type === "DataSourceModelTable" ? 0 : 1)),
                    valueToLabel: (t) => (
                        <div className="table-label">
                            <img
                                className="table-icon"
                                src={t.$type === "ViewModelTable" ? require("../../../../common/icons/data-view-icon.svg") : require("../../../../common/icons/table-icon.svg")}
                                alt=""
                            />
                            {t.visualName}
                        </div>
                    ),
                    valueToSearch: (t) => t.visualName,
                    isSelected: (t) => state.value === t.id,
                    onChange: state.onChange,
                    isUnavailable,
                })}
            </div>
        );
    });
