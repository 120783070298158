import {cs} from "@common/react/chain-services";
import {provideContext} from "@common/react/context";
import {fragments} from "@common/react/fragments";
import {OnMounted} from "@common/react/on-mounted";
import {isDev} from "../../tools/dev/is-dev";
import {loadScript} from "@common/utils/browser/load-script";

const gaConfig = process.env.gaConfig;

window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
window.gtag = gtag;
gtag('js', new Date());

const getGoogleAnalyticsID = (app) => {
    const map = {
        "iframe:ad-reporting.net": "G-KR6YBKJ5W7",
        "iframe:data.revivemedia.us": "G-CKZJSVEWJ8",
    }

    return map[`${app}:${window.location.hostname}`] ?? gaConfig[app]["trackingId"]
}
export const loadGTag = ({app, next}) =>
    isDev()
        ? next()
        : cs(
            [
                "gtag",
                ({}, next) =>
                    GTagRoot({
                        getTrackingId: () => {
                            return getGoogleAnalyticsID(app);
                        },
                        next,
                    }),
            ],
            ({gtag}) => provideContext("gtag", gtag, next)
        );

export const GTagRoot = ({getTrackingId, next}) =>
    cs(
        ({}, next) =>
            fragments(
                next(),

                OnMounted({
                    action: async () => {
                        const trackingId = getTrackingId();
                        gtag('config', trackingId);

                        await loadScript({
                            src: `https://www.googletagmanager.com/gtag/js?id=${trackingId}`,
                            id: "google-analytics",
                            async: true,
                        });
                    },
                })
            ),
        ({}) => next(gtag)
    );
