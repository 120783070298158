import {tooltipLayout} from "./tooltip-layout";
import {getTooltipFontSizes} from "./get-tooltip-font-sizes";
import {getIndicatorShapes} from "./indicator-shapes";
import {getDifference} from "./get-difference";

export const renderFunnelChartTooltip = (
    {
        points,
        tile, theme,
        rawData,
        formatters,
        isCompare,
    }
) => {
    if (isCompare) {
        const extractedPoint = getExtractedFunnelPoint({points, tile, isCompare, rawData});
        return extractedPoint && tooltipLayout({
            content: (
                `<div class="table">
                    ${getFunnelPointTableCompare({tile, extractedPoint, rawData, formatters, theme})}
                </div>`
            ),
            theme, isCompare,
        });

    }

    const extractedPoint = getExtractedFunnelPoint({points, tile});

    return extractedPoint && tooltipLayout({
        content: (
            `<div class="non-compare-header">
                <div class="title">${extractedPoint.title}</div>
            </div>
            <div class="table">
                ${getFunnelPointTable({tile, theme, extractedPoint, formatters,})}
            </div>`
        ),
        theme,
    })
}

const getExtractedFunnelPoint = ({points, tile, isCompare, rawData}) => {
    const point = points[0];

    if (!point.point) {
        return;
    }

    const series = point.series;
    const {value, percentOfPrevious, percentOfTotal} = point.point;

    const percentValues = tile.style.dataLabels.comparisonType === "PercentOfTotal"
        ? {percentOfTotal}
        : {
            percentOfPrevious,
            previousSection: point.colorIndex > 0 && point.series.data.find((d) => d.colorIndex === point.colorIndex -1).name,
        }

    const compareValues = isCompare && (() => {
        const comparePoint = rawData.series.find((s) => s.isCompare).data.find((d) => d.name === point.key);
        return {
            compareValue: comparePoint.value, // ref. decorate-funnel-data.js
            comparePercentOfPrevious: comparePoint.percentOfPrevious,
            comparePercentOfTotal: comparePoint.percentOfTotal,
        }
    })();

    return {
        title: series.name,
        name: point.key,
        color: point.color,
        value,
        ...percentValues,
        ...compareValues,
    };
};

const getFunnelPointTable = ({tile, theme, extractedPoint, formatters, }) => {
    const rPoint = () => {
        const fontSizes = getTooltipFontSizes(theme);
        const indicatorShapes = getIndicatorShapes(theme);

        const mainValue = tile.style.dataLabels.comparisonType === "PercentOfTotal"
            ? (
                `<div class="main-value">
                    ${formatters.valueFormatter(extractedPoint.value)}
                    ${tile.style.dataLabels.metrics?.includes("Percent") 
                        ? `<span className="percent" style="font-size: ${fontSizes.small}px">(${formatters.percentFormatter(extractedPoint.percentOfTotal)})</span>`
                        : ""
                    }
                </div>`
            )
            : !extractedPoint.percentOfPrevious // first stage does not have previous
                ? (
                    `<div class="main-value">
                        ${formatters.valueFormatter(extractedPoint.value)}
                    </div>`
                )
                : (
                    `<div class="main-value">
                        ${formatters.valueFormatter(extractedPoint.value)}
                        ${tile.style.dataLabels.metrics?.includes("Percent") 
                            ? `<div class="percent-of-previous" style="font-size: ${fontSizes.small}px; font-weight: normal; margin-top: 2px;">
                                ${formatters.percentFormatter(extractedPoint.percentOfPrevious)} of
                                </br>
                                ${extractedPoint.previousSection} 
                            </div>` 
                            : ""
                        }
                    </div>`
                )

        return (
            `<div class="point table-row first-point" style="font-size: ${fontSizes.medium}px">
                <div class="name">
                    <span class="indicator" style="color: ${extractedPoint.color}">${indicatorShapes["bar"]}</span>
                    ${extractedPoint.name}
                </div>
                ${mainValue}
            </div>`
        )
    };

    return `${rPoint()}`;
};

const getFunnelPointTableCompare = ({tile, extractedPoint, formatters, rawData, theme}) => {
    const fontSizes = getTooltipFontSizes(theme);

    const dateRangeFormatter = (range) => formatters.dateFormatter(range?.dateStart) + " - " + "</br>" + formatters.dateFormatter(range?.dateEnd);

    const rHeader = () => {
        const seriesTitle = (() => {
            const {diff, diffIcon, stateColor} = getDifference({
                ...(tile.style.dataLabels.comparisonType === "PercentOfTotal" ? {
                    value: extractedPoint.percentOfTotal,
                    compareValue: extractedPoint.comparePercentOfTotal,
                } : {
                    value: extractedPoint.percentOfPrevious,
                    compareValue: extractedPoint.comparePercentOfPrevious,
                }),
                theme
            });

            return (`
                <div class="title">
                    ${extractedPoint.title}
                </div>
                <div class="difference" style="color: ${stateColor}">
                    ${diffIcon}
                    ${formatters.percentFormatter(diff)}
                </div>
            `)
        })();

        const mainColumnTitle = dateRangeFormatter(rawData.series.find((s) => !s.isCompare)?.range);

        const compareColumnTitle = dateRangeFormatter(rawData.series.find((s) => s.isCompare)?.range);

        return (
            `<div class="header table-row" style="font-size: ${fontSizes.small}px">
                <div class="series-title">${seriesTitle}</div>
                    <div class="main-title">
                        ${mainColumnTitle}
                    </div>
                    <div class="compare-title">
                        ${compareColumnTitle}
                    </div>
            </div>`
        )
    };

    const rPoint = () => {
        const indicatorShapes = getIndicatorShapes(theme);
        const getValue = ({value, percentOfTotal, percentOfPrevious, previousSection}) => {
            if (tile.style.dataLabels.comparisonType === "PercentOfTotal") {
                return `
                    ${formatters.valueFormatter(value)}
                    ${tile.style.dataLabels.metrics?.includes("Percent")
                        ? `<span class="percent" style="font-size: ${fontSizes.small}px">(${formatters.percentFormatter(percentOfTotal)})</span>`
                        : ""
                    }
                `
            }

            if (!percentOfPrevious) { // first stage does not have previous
                return formatters.valueFormatter(value);
            }

            return `
                ${formatters.valueFormatter(value)}
                ${tile.style.dataLabels.metrics?.includes("Percent")
                    ? `<div class="percent-of-previous" style="font-size: ${fontSizes.small}px; font-weight: normal; margin-top: 2px;">
                        ${formatters.percentFormatter(percentOfPrevious)} of
                        </br>
                        ${previousSection} 
                    </div>`
                    : ""
                }
            `
        };

        return (
            `<div class="point table-row first-point" style="font-size: ${fontSizes.medium}px">
                <div class="name">
                    <span class="indicator" style="color: ${extractedPoint.color}">${indicatorShapes["bar"]}</span>
                    ${extractedPoint.name}
                </div>
                <div class="main-value">
                    ${getValue({
                        value: extractedPoint.value, 
                        percentOfTotal: extractedPoint.percentOfTotal, 
                        percentOfPrevious: extractedPoint.percentOfPrevious, 
                        previousSection: extractedPoint.previousSection
                    })}
                </div>
                <div class="compare-value">
                    ${getValue({
                        value: extractedPoint.compareValue,
                        percentOfTotal: extractedPoint.comparePercentOfTotal,
                        percentOfPrevious: extractedPoint.comparePercentOfPrevious,
                        previousSection: extractedPoint.previousSection
                    })}
                </div>
            </div>`
        )
    };

    return (
        `${rHeader()}
         ${rPoint()}`
    )
};
