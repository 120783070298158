import {stringToList} from "../../../../../../common/data-logic/string-to-list";

export const enforceDefault = (
    filterState,
    {attr, attrDefaultValue, defaultAttr} = {
        attr: "options",
        defaultAttr: "defaultOption",
    }
) => {
    return {
        value: filterState.value[attr] || attrDefaultValue,
        onChange: (listStr) => {
            const list = stringToList(listStr);
            if (list.length > 0) {
                filterState.onChange({
                    ...filterState.value,
                    [attr]: listStr,
                    [defaultAttr]: (() => {
                        if (!filterState.value[defaultAttr] || !list.includes(filterState.value[defaultAttr])) {
                            return list[0];
                        } else {
                            return filterState.value[defaultAttr];
                        }
                    })(),
                });
            }
        },
    };
};
