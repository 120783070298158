import {cascade, cascadeCount} from "../../utils/cascade";
import {flatten1, unique} from "../../utils/collections";
import {chain} from "../../utils/fs";
import {changePath, getPath} from "../../utils/arr-path";
import {cs} from "../../react/chain-services";
import {provideContext} from "../../react/context";

export const isDisableColorConfigs = (tile) => {
    if(!tile || !tile.$type) {
        return true;
    }

    const disabledTileTypes = [
        "DownloadReportTile",
        "TableTile",
        "SingleKPITile",
        "TableKPITile",
        "ListKPITile",
        "ScatterPlotTile",
        "BubbleChartTile",
        "ContainerTile",
        "MapTile",
        "PivotTableTile",
        "SectionTile",
        "TextTile",
    ];

    return disabledTileTypes.includes(tile.$type);
};

export const MAX_NUM_COLOR = {
    SchemeColorApplication: 3,
    DiscreteColorApplication: 8,
};

export const mapColorApplicationToThemeColor = (colorPalettes, application) => {
    const mapApplicationColorSchemeTypeToThemeColor = {
        Categorical: "categoricalColorSchemes",
        Sequential: "sequentialColorSchemes",
    };

    const maps = {
        SchemeColorApplication: () => {
            return colorPalettes.colorSchemes[mapApplicationColorSchemeTypeToThemeColor[application.type]][application.index].colorsRGB;
        },
        DiscreteColorApplication: () => colorPalettes.discreteColorsRGB[application.index],
    };

    return maps[application.$type]();
};

export function getFieldColorConfigs({field, dataColorPalettes, colorConfigs}) {
    try {
        const {configs, ...others} = colorConfigs;
        const colorConfig = colorConfigs.isDictionary ? configs[field.id] : configs;

        return {
            ...colorConfig,
            ...others,
            displayColor: dataColorPalettes ? mapColorApplicationToThemeColor(dataColorPalettes, colorConfig) : null,
        };
    } catch(e) {
        return {};
    }
}

const getBarChartColorConfigFactory =
    ({measureFieldProp, dimensionFieldProp, tile, theme, colorConfigs}) =>
        () => {
            const noGroupField = !tile.value.groupField;
            const {dataColorPalettes} = theme.dataVisualization;

            if(!tile.value[measureFieldProp]) {
                return [];
            }

            const numberOfFields = cascadeCount(tile.value[measureFieldProp] || [], "[*].measureFields[*]");

            if(numberOfFields == 1 && noGroupField) {
                const field = tile.value[measureFieldProp][0].measureFields[0];
                return [
                    {
                        field,
                        isBarField: true,
                        showColorAppliedBy: true,
                        dimensionFieldProp,
                        measureFieldProp,
                        colorConfig: getFieldColorConfigs({
                            field,
                            dataColorPalettes,
                            colorConfigs,
                        }),
                    },
                ];
            }

            if(noGroupField) {
                return flatten1((tile.value[measureFieldProp] || []).map((f) => f.measureFields)).map((f) => {
                    return {
                        field: f,
                        isBarField: true,
                        colorConfig: getFieldColorConfigs({
                            field: f,
                            dataColorPalettes,
                            colorConfigs,
                        }),
                    };
                });
            }

            const field = tile.value.groupField;
            return tile.value.groupField
                ? [
                    {
                        field,
                        colorConfig: getFieldColorConfigs({
                            field,
                            dataColorPalettes,
                            colorConfigs,
                        }),
                    },
                ]
                : [];
        };

export function getColorConfigs(tile) {
    const maps = {
        // "ScatterPlotTile": ["xAxisFields", "yAxisFields", "sizeFields", "groupField"],
        VerticalBarChartTile: {
            prop: "colorApplications",
            isDictionary: true,
        },
        HorizontalBarChartTile: {
            prop: "colorApplications",
            isDictionary: true,
        },
        LineChartTile: {
            prop: "colorApplications",
            isDictionary: true,
        },
        ComboChartTile: {
            prop: ["barColorApplications", "lineColorApplications"],
            isDictionary: true,
        },
        PieChartTile: {
            prop: "colorApplication",
            isDictionary: false,
        },
        DonutChartTile: {
            prop: "colorApplication",
            isDictionary: false,
        },
        GaugeTile: {
            prop: "valueColorApplication",
            isDictionary: false,
        },
        ListKPITile: {
            prop: ["iconColorApplication", "labelColorApplication", "valueColorApplication"],
            isDictionary: false,
        },
        SingleKPITile: {
            prop: "valueColorApplication",
            isDictionary: false,
        },
        TableKPITile: {
            prop: ["summaryColorApplication", "valueColorApplication"],
            isDictionary: false,
        },
        FunnelChartTile: {
            prop: "colorApplication",
            isDictionary: false,
        },
        // "ContainerTile": ["tiles"],
        VennDiagramTile: {
            prop: "valueColorApplication",
            isDictionary: false,
        },
        BoxPlotTile: {
            prop:
                tile.style?.boxPlotColorAppliedBy === "Measure"
                    ? "valueColorApplication" // discrete
                    : "colorApplication",
            isDictionary: false,
        },
        SparkLineKPITile: {
            prop: "colorApplications",
            isDictionary: true,
        },
        TextLabelTile: {
            prop: "valueColorApplication",
            isDictionary: false,
        },
    };

    const config = maps[tile.$type];

    if(!config) {
        return null;
    }

    const configs = Array.isArray(config.prop)
        ? config.prop
            .map((p) => {
                let obj = {};
                if(config.isDictionary) {
                    for(let fieldId in tile.style[p]) {
                        obj[fieldId] = {
                            ...tile.style[p][fieldId],
                            parent: p,
                        };
                    }
                } else {
                    obj[p] = {
                        ...tile.style[p],
                        parent: p,
                    };
                }

                return obj;
            })
            .reduce((obj, curr) => ({...obj, ...curr}), {})
        : tile.style[config.prop];
    return {
        ...config,
        configs,
    };
}

export function getAvailableFields({tile, theme}) {
    const {dataColorPalettes} = theme.dataVisualization;
    const colorConfigs = getColorConfigs(tile.value);

    const getDefaultSingleField = (prop, isBarField = false) => {
        const _field = Array.isArray(prop) ? getPath(tile.value, prop) : tile.value[prop];

        return _field
            ? [
                {
                    field: _field,
                    isBarField,
                    colorConfig: getFieldColorConfigs({
                        field: _field,
                        dataColorPalettes,
                        colorConfigs,
                    }),
                },
            ]
            : [];
    };

    const maps = {
        // "ScatterPlotTile": ["xAxisFields", "yAxisFields", "sizeFields", "groupField"],
        VerticalBarChartTile: getBarChartColorConfigFactory({
            measureFieldProp: "yAxisFields",
            dimensionFieldProp: "xAxisField",
            tile,
            theme,
            colorConfigs,
        }),
        HorizontalBarChartTile: getBarChartColorConfigFactory({
            measureFieldProp: "xAxisFields",
            dimensionFieldProp: "yAxisField",
            tile,
            theme,
            colorConfigs,
        }),
        LineChartTile: () => {
            const noGroupField = !tile.value.groupField;
            if(noGroupField) {
                return flatten1((tile.value.yAxisFields || []).map((f) => f.measureFields)).map((f) => ({
                    field: f,
                    colorConfig: getFieldColorConfigs({
                        field: f,
                        dataColorPalettes,
                        colorConfigs,
                    }),
                }));
            }

            const field = tile.value.groupField;
            return [
                {
                    field,
                    colorConfig: getFieldColorConfigs({
                        field,
                        dataColorPalettes,
                        colorConfigs,
                    }),
                },
            ];
        },
        PieChartTile: () => getDefaultSingleField("groupField"),
        DonutChartTile: () => getDefaultSingleField("groupField"),
        ComboChartTile: () => {
            const noGroupField = !tile.value.groupField;
            if(noGroupField) {
                return [
                    ...flatten1((tile.value.yAxisBarFields || []).map((f) => f.measureFields)).map((f) => ({
                        field: f,
                        isBarField: true,
                        dimensionFieldProp: "xAxisField",
                        colorConfig: getFieldColorConfigs({
                            field: f,
                            dataColorPalettes,
                            colorConfigs,
                        }),
                    })),
                    ...flatten1((tile.value.yAxisLineFields || []).map((f) => f.measureFields)).map((f) => ({
                        field: f,
                        colorConfig: getFieldColorConfigs({
                            field: f,
                            dataColorPalettes,
                            colorConfigs,
                        }),
                    })),
                ];
            }

            const numLineFields = cascadeCount(tile.value.yAxisLineFields || [], "[*].measureFields[*]");

            if(numLineFields > 0) {
                return getDefaultSingleField("groupField", true).concat(
                    flatten1((tile.value.yAxisLineFields || []).map((f) => f.measureFields)).map((f) => ({
                        field: f,
                        colorConfig: getFieldColorConfigs({
                            field: f,
                            dataColorPalettes,
                            colorConfigs,
                        }),
                    }))
                );
            }

            return getDefaultSingleField("groupField", true);
        },
        GaugeTile: () => getDefaultSingleField("valueField"),
        FunnelChartTile: () => getDefaultSingleField("groupField"),
        VennDiagramTile: () => getDefaultSingleField("groupField"),
        SparkLineKPITile: () => getDefaultSingleField(["yAxisField", "measureFields", 0], tile.value.sparkLineChartType == "Bar"),
        BoxPlotTile: () => getDefaultSingleField("xAxisField", true),
        // "SingleKPITile": ["valueField"],
        // "ContainerTile": ["tiles"],
    };

    return maps[tile.value.$type]?.();
}

export const listAllFields = ({
    tile,
    dimensionAttr,
    measureGroupAttrs,
    measureSingleAttrs,
    measureMultiAttr,
    groupFieldAttr,
    groupFieldAttrs,
    isBarFields = [],
    checkUnique = true,
}) => {
    if(!tile) {
        return;
    }

    const measureGroupFields =
        measureGroupAttrs &&
        chain(
            measureGroupAttrs.map((attr) =>
                cascade(tile[attr] || [], "[*].measureFields[*]", (value, path, objs) => ({
                    ...value,
                    fieldPath: path,
                    parent: attr,
                    isBarField: isBarFields.includes(attr),
                    objs,
                }))
            ),
            (_) => flatten1(_).filter((v) => v),
            (_) => (checkUnique ? unique(_, (v) => v.modelColumnID) : _)
        );

    const measureSingleFields = measureSingleAttrs?.map((attr) => {
        if(tile?.[attr]?.measureFields) {
            return tile[attr].measureFields[0];
        }

        return tile[attr];
    });

    return chain(
        [
            ...(dimensionAttr && Array.isArray(dimensionAttr) ? dimensionAttr.map((attr) => tile[attr]) : [tile[dimensionAttr]]),
            ...(measureSingleFields || []),
            ...(measureMultiAttr ? flatten1(measureMultiAttr.map((attr) => tile[attr])) || [] : []),
            ...(measureGroupFields || []),
            ...(groupFieldAttr ? [tile[groupFieldAttr]] || [] : []),
            ...(groupFieldAttrs ? flatten1(groupFieldAttrs.map((attr) => tile[attr])) || [] : []),
        ],
        (_) => _.filter((v) => v),
        (_) => (checkUnique ? unique(_, (v) => v.modelColumnID) : _)
    );
};

export const resetColorIndexes = (tile) => {
    let newTile = {...tile};
    const tileColorConfigs = getColorConfigs(tile);

    if(!tileColorConfigs) {
        return tile;
    }

    const {configs, isDictionary, prop} = tileColorConfigs;

    function updateApplication(obj) {
        let newObj = {...obj, index: 0};

        if(newObj.type && newObj.$type == "SchemeColorApplication") {
            newObj.type = "Categorical";
        }

        return newObj;
    }

    if(Array.isArray(prop) || isDictionary) {
        for(let key in configs) {
            let updatePath = ["style"];

            if(isDictionary) {
                const fieldColorConfig = configs[key];
                updatePath = updatePath.concat(fieldColorConfig.parent ? [fieldColorConfig.parent, key] : [prop, key]);
            } else {
                updatePath = updatePath.concat(key);
            }
            newTile = changePath(newTile, updatePath, updateApplication);
        }
    } else {
        let updatePath = ["style", prop];
        newTile = changePath(newTile, updatePath, updateApplication);
    }

    // reset reference lines colors
    if(newTile.referenceLines?.length) {
        newTile = changePath(newTile, ["referenceLines"], (referenceLines) => {
            return referenceLines.map((rl, i) => ({
                ...rl,
                colorApplication: {
                    ...rl.colorApplication,
                    index: Math.abs(MAX_NUM_COLOR.DiscreteColorApplication - 1 - i),
                },
            }));
        });
    }

    return newTile;
};

export const loadTileFields = ({next: rootNext, configs}) =>
    cs(
        ["tileFields", ({}, next) => next(listAllFields(configs))],
        ({tileFields}, next) => provideContext("tileFields", tileFields, next),
        ({tileFields}) => rootNext(tileFields)
    );
