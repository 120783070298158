const {
    CalculatorIcon,
    AppendRowsIcon,
    DateOperationIcon,
    TextManipulationIcon,
    MapValuesIcon,
    RemoveDuplicatesIcon,
    SelectColumnsIcon,
    RenameColumnsIcon,
    ChangeColumnTypeIcon,
    GroupDataIcon,
    FilterDataIcon,
    JoinDataIcon,
    PivotDataIcon,
    UnPivotDataIcon,
    RankDataIcon,
    WindowDataIcon,
    CaseWhenIcon,
    StaticValueIcon,
} = require("./icons/icons");

const transformationStepTypes = [
    {
        $type: "CalculatorStep",
        name: "Calculator",
        iconSrc: require("./icons/calculator-icon.svg"),
        icon: CalculatorIcon,
        allowOnAggMeasure: true,
    },
    {
        $type: "TextManipulationStep",
        name: "Manipulate Text",
        iconSrc: require("./icons/text-manipulation-icon.svg"),
        icon: TextManipulationIcon,
        allowOnAggMeasure: false,
    },
    {
        $type: "MapValuesTransformStep",
        name: "Map Values",
        iconSrc: require("./icons/map-values-icon.svg"),
        icon: MapValuesIcon,
        allowOnAggMeasure: false,
    },
    {
        $type: "RemoveDuplicatesStep",
        name: "Remove Duplicates",
        iconSrc: require("./icons/remove-duplicates-icon.svg"),
        icon: RemoveDuplicatesIcon,
    },
    {
        $type: "SelectColumnsStep",
        name: "Select Columns",
        iconSrc: require("./icons/select-columns-icon.svg"),
        icon: SelectColumnsIcon,
    },
    {
        $type: "RenameColumnsStep",
        name: "Rename Columns",
        iconSrc: require("./icons/rename-columns-icon.svg"),
        icon: RenameColumnsIcon,
    },
    {
        $type: "DateOperationStep",
        name: "Date Operation",
        iconSrc: require("./icons/date-operation-icon.svg"),
        icon: DateOperationIcon,
        allowOnAggMeasure: false,
    },
    {
        $type: "ChangeColumnTypeStep",
        name: "Change Type",
        iconSrc: require("./icons/change-column-icon.svg"),
        icon: ChangeColumnTypeIcon,
        allowOnAggMeasure: false,
    },
    {
        $type: "GroupDataStep",
        name: "Group Data",
        iconSrc: require("./icons/group-data-icon.svg"),
        icon: GroupDataIcon,
    },
    {
        $type: "FilterDataStep",
        name: "Filter Data",
        iconSrc: require("./icons/filter-data-icon.svg"),
        icon: FilterDataIcon,
    },
    {
        $type: "CaseWhenStep",
        name: "Case/When",
        iconSrc: require("./icons/case-when-icon.svg"),
        icon: CaseWhenIcon,
    },
    {
        $type: "StaticValueStep",
        dataType: "Text",
        name: "Static Value",
        iconSrc: require("./icons/static-value-icon.svg"),
        icon: StaticValueIcon,
    },
    {
        $type: "JoinDataStep",
        inputStep1ID: null,
        inputStep2ID: null,
        name: "Join Data",
        iconSrc: require("./icons/join-data-icon.svg"),
        icon: JoinDataIcon,
    },
    {
        $type: "AppendRowsStep",
        name: "Append Rows",
        inputStep1ID: null,
        inputStep2ID: null,
        iconSrc: require("./icons/append-rows-icon.svg"),
        icon: AppendRowsIcon,
    },
    {
        $type: "PivotDataStep",
        name: "Pivot Data",
        iconSrc: require("./icons/pivot-data-icon.svg"),
        icon: PivotDataIcon,
    },
    {
        $type: "UnpivotDataStep",
        name: "Un-Pivot / Normalize",
        iconSrc: require("./icons/unpivot-data-icon.svg"),
        icon: UnPivotDataIcon,
    },
    {
        $type: "RankDataStep",
        name: "Rank Data",
        iconSrc: require("./icons/rank-data-icon.svg"),
        icon: RankDataIcon,
    },
    {
        $type: "WindowDataStep",
        name: "Window Data",
        iconSrc: require("./icons/window-data-icon.svg"),
        icon: WindowDataIcon,
    },
];
exports.transformationStepTypes = transformationStepTypes;

// const transformationStepTypesClasses = {
//     "ModelTableDatasetStep": [
//         "ModelTableDatasetStep",
//     ],
//     "SelectColumnsStep": [
//         "SelectColumnsStep", // one input
//     ],
//     "OneInputStepTransformationStep": [
//         "CalculatorStep",
//         "ChangeColumnTypeStep",
//         "ChildDatasetStep",
//         "DateOperationStep",
//         "GroupDataStep",
//         "MapValuesTransformStep",
//         "RemoveDuplicatesStep",
//         "TextManipulationStep",
//         // "OutputStep",
//     ],
//     "TwoInputStepTransformationStep": [
//         "AppendRowsStep",
//         "JoinDataStep",
//     ],
// };
