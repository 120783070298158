const {Alarming} = require("./alarming");
const {IgnoreUpdate} = require("./ignore-update");
const {cs} = require("./chain-services");

const SuspendUpdate = ({active, delay = 300, next}) =>
    cs(
        [
            "alarmingActive",
            (_, next) =>
                Alarming({
                    active,
                    duration: delay,
                    next,
                }),
        ],
        ({alarmingActive}, next) => IgnoreUpdate({next, when: () => alarmingActive}),
        () => next()
    );
exports.SuspendUpdate = SuspendUpdate;
