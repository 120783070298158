const {replaceFind} = require("../../../../common/utils/collections");
const {Load2} = require("@common/react/load2");
const {provideContext} = require("@common/react/context");
const {cs} = require("@common/react/chain-services");
const {consumeContext} = require("@common/react/context");
const {Load} = require("@common/react/load");
const {Invoke} = require("@common/react/invoke");

const loadTenant = ({next}) =>
    cs(
        consumeContext("apis"),
        [
            "environments",
            ({apis}, next) =>
                Load2({
                    fetch: () => apis.tenant.getEnvironments(),
                    next,
                }),
        ],
        [
            "tenant",
            ({apis}, next) =>
                Load({
                    fetch: () => apis.tenant.getTenant(),
                    next,
                }),
        ],
        ({tenant}, next) => {
            if (!tenant) {
                return next();
            }

            return Invoke({
                next,
                onMounted: () => {
                    document.title = `Reporting - Revive`;
                },
            });
        },
        ({environments, tenant}) =>
            provideContext(
                "tenant",
                {
                    environments: environments.value,
                    reloadEnvironments: environments.reload,
                    updateEnvironment: (env) => environments.onChange(replaceFind(environments.value, env, (e) => e.id === env.id)),
                    createNewEnvironment: (env) => environments.change((environments) => environments.concat(env)),
                    removeEnvironment: (env) => environments.change((environments) => environments.filter((e) => e.id != env.id)),
                    tenant,
                },
                next
            )
    );
exports.loadTenant = loadTenant;
