const {cs} = require("../react/chain-services");
const {chain} = require("../utils/fs");
const {DebounceCache} = require("../react/debounce-cache");

const DebounceNumber = ({state, next}) =>
    cs(
        [
            "state",
            (_, next) =>
                next(
                    chain(state, ({value, onChange}) => ({
                        value,
                        onChange: (v) => {
                            !isNaN(v) && onChange(+v); // && v >= 1 && v <= 9999
                        },
                    }))
                ),
        ],
        [
            "debounce",
            ({state}) =>
                DebounceCache({
                    state,
                    next,
                }),
        ]
    );
exports.DebounceNumber = DebounceNumber;
