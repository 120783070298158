const {chain} = require("../../../../utils/fs");
const {avg} = require("../../../../utils/collections");
const {getClassFromName} = require("@common/ui-components/charts/render-chart/add-classname-to-data-point");
const {getFieldType} = require("@common/ui-components/charts/common/get-field-type");

const initComparisonBarsHelper = ({compareSeries, barStack, tile}) => {
    let barGroups = [];

    const render = ({chart, animationDisabled, duration} = {}) => {
        const drawEachSeries = ({series, animationDisabled, duration}) => {
            if (!series.visible) {
                return;
            }

            const respTileSeries = compareSeries.find((s) => s.name === series.name && s.stack === series.userOptions.stack && s.isCompare);

            if (!respTileSeries || respTileSeries.isEmptyData) {
                return;
            }

            const heightValueRatio = getHeightValueRatio(series);

            if (!heightValueRatio) {
                // console.error("Series with null or zeros data");
                return;
            }

            const group = chart.renderer.g(`${series.name}-comparison`).add(chart.seriesGroup);

            series.data.forEach((d, i) => {
                const rectElem = d.graphic?.element.getBBox();

                if (!d.isInside || !rectElem) {
                    return;
                }

                const respDp = respTileSeries.data[i];

                if (!respDp || respDp[1] == null) {
                    return;
                }

                const mainRect = {
                    x: rectElem.x + chart.plotLeft,
                    y: rectElem.y + chart.plotTop,
                    width: rectElem.width,
                    height: rectElem.height,
                };

                const getRelativeValue = cGetRelativeValue({series});
                const compareHeight = (respDp[1] ? getRelativeValue(Math.abs(respDp[1])) : 0) * heightValueRatio;

                let leftBottom, leftTop, animateConfigs;
                const isNotNegative = respDp[1] >= 0;

                if (isNotNegative) {
                    leftBottom = [mainRect.x + mainRect.width / 2, mainRect.y + mainRect.height];

                    leftTop = [mainRect.x + mainRect.width / 2, mainRect.y + mainRect.height - compareHeight];

                    animateConfigs = {
                        y: leftTop[1],
                        height: compareHeight,
                    };
                } else {
                    leftBottom = [mainRect.x + mainRect.width / 2, mainRect.y + compareHeight];

                    leftTop = [mainRect.x + mainRect.width / 2, mainRect.y];

                    animateConfigs = {
                        height: compareHeight,
                    };
                }

                let compareRect = chart.renderer
                    .rect(leftTop[0], isNotNegative ? leftBottom[1] : leftTop[1], mainRect.width, 0)
                    .attr({
                        fill: series.userOptions.color,
                        id: `comparison-bars-${series.name}${i}`,
                        opacity: 0.25,
                        class: getComparisonBarClassName({
                            tile,
                            series,
                            dataPoint: d,
                        }),
                    })
                    .on("mouseover", () => {
                        d.onMouseOver();
                    })
                    .add(group);
                if (!animationDisabled) {
                    compareRect.animate(animateConfigs, {
                        duration: duration || 1000,
                    });
                } else {
                    compareRect.attr(animateConfigs);
                }

                const comparisonPoint = {
                    shapeArgs: {
                        x: leftTop[0],
                        y: leftTop[1],
                        width: mainRect.width,
                        height: compareHeight,
                    },
                    graphic: compareRect,
                    y: respDp[1], // used when draw round corners. ref. draw-round-corners.js
                };

                series.comparisonPoints = (series.comparisonPoints || []).concat(comparisonPoint);
            });

            barGroups.push(group);
        };

        // console.log("render comparison bars");
        chain(
            chart.series,
            (_) => (barStack ? _.filter((s) => s.userOptions.stack === barStack) : _),
            (_) => _.forEach((s) => drawEachSeries({series: s, animationDisabled, duration}))
        );
    };

    const remove = () => {
        barGroups.forEach((g) => g.destroy());
        barGroups = [];
    };

    return {
        render,
        remove,
        rerender: (options) => {
            remove();
            render(options);
        },
    };
};
exports.initComparisonBarsHelper = initComparisonBarsHelper;

const logBase = (base, value) => {
    const denominator = base === 2 ? Math.LN2 : base === 10 ? Math.LN10 : Math.log(base);
    return Math.log(value) / denominator;
};

const cGetRelativeValue = ({series, base = 10}) => (series.yAxis.logarithmic ? (value) => logBase(base, value) - series.yAxis.min : (v) => v);
exports.cGetRelativeValue = cGetRelativeValue;

const getHeightValueRatio = (series) => {
    const getRelativeValue = cGetRelativeValue({series});

    const allHeightValueRatio = chain(
        series.data,
        (_) => _?.filter((d) => d.y && d.graphic?.element && d.graphic.element.getBBox().height),
        (_) => _?.map((d) => d.graphic.element.getBBox().height / getRelativeValue(Math.abs(d.y)))
    );

    return allHeightValueRatio?.length > 0 && avg(allHeightValueRatio);
};
exports.getHeightValueRatio = getHeightValueRatio;

// AB#5510 AB#5527
// ref. add-classname-to-data-point.js
const getComparisonBarClassName = ({tile, series, dataPoint}) => {
    if (tile.groupField) {
        return getClassFromName(series.userOptions.name);
    }
    if (getFieldType(tile.xAxisField || tile.yAxisField) !== "date") {
        return getClassFromName(dataPoint.name || dataPoint.x);
    }
};
exports.getComparisonBarClassName = getComparisonBarClassName;
