import { cs } from "@common/react/chain-services";
import { consumeContext } from "@common/react/context";
import { Form2 } from "@common/react/cs-form/form2";
import { SubcribeKeyEvents } from "@common/react/keys/global-key-down";
import { UseState } from "@common/react/use-state";
import { VerbDialogBodyScrollbar } from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";
import * as React from "react";
import { Button } from "../../../../../../common/form/buttons/button/button";
import { ffToTextInput } from "../../../../../../common/form/ff-to-text-input";
import { TextInput } from "../../../../../../common/form/text-input/text-input";
import { DialogService } from "../../../common/dialog/dialog-service";
import { dataSourceFormConfig } from "../data-source-form-config";

export const RenameDataSourceDialog = ({onDone, next: rootNext}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    render: ({resolve, args: {dataSource}}) => ({
                        title: "Rename DataSource",
                        width: 540,
                        content: next({
                            resolve,
                            dataSource,
                        }),
                    }),
                    next: rootNext,
                }),
        ],
        consumeContext("apis"),
        [
            "editing",
            ({modal}, next) =>
                UseState({
                    initValue: {
                        name: modal.dataSource.name,
                    },
                    next,
                }),
        ],
        [
            "form",
            ({modal, editing, apis, fullCol}, next) =>
                Form2({
                    data: editing,
                    fields: {
                        ...dataSourceFormConfig({apis}),
                    },
                    onSubmit: async () => {
                        const newDataSource = await apis.data.upsertDataSource({
                            ...modal.dataSource,
                            connectionDetails: null,
                            name: editing.value.name,
                        });

                        onDone?.({});
                        modal.resolve();
                    },
                    next,
                }),
        ],
        ({modal, form}) => (
            <div className="rename-collection-dialog-zd9">
                <VerbDialogBodyScrollbar>
                    <div className="main-part">
                        {TextInput({
                            label: "Rename DataSource",
                            ...ffToTextInput(form.field("name")),
                        })}
                    </div>
                </VerbDialogBodyScrollbar>
                {SubcribeKeyEvents({
                    events: [
                        {
                            keyCombo: "Enter",
                            onKeyDown: () => form.looksValid && form.submit(),
                        },
                    ]
                })}
                <div className="buttons">
                    <Button btnType="secondary" onClick={() => modal.resolve()}>
                        Cancel
                    </Button>
                    <Button disabled={!form.looksValid} onClick={form.submit}>
                        Rename
                    </Button>
                </div>
            </div>
        )
    );
