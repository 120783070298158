const getFontOptions = (attr) => ({
    ...(attr?.includes("Italics") && {
        fontStyle: "italic",
    }),
    ...(attr?.includes("Bold") && {
        fontWeight: "bold",
    }),
    ...(attr?.includes("Underline") && {
        textDecoration: "underline",
    }),
});
exports.getFontOptions = getFontOptions;
