import * as React from "react";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {SearchInput} from "../../../../../../../../../common/form/search-input/search-input";
import "./left-column-picker.scss";
import {consumeContext} from "@common/react/context";
import {cGetSearchedTables} from "../../../common/get-searched-tables";
import {cx} from "emotion";
import {TableLabel} from "../../../common/table-label/table-label";
import {ColumnLine} from "../../../common/column-line/column-line";
import {dataViewColor} from "../../../common/data-source-colors";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";

export const LeftColumnPicker = ({table, dsIndex, dvIndex, state, watchCol}) =>
    cs(
        consumeContext("dataSourceModelContext"),
        ["search", (_, next) => UseState({next})],
        ({search, dataSourceModelContext}) => {
            const {getRelationshipUsage} = dataSourceModelContext;
            const getSearchedTable = (table) => cGetSearchedTables(search.value)([table])[0];
            return (
                <div className="left-column-picker-54e left-column-picker">
                    <div className="search">
                        <SearchInput
                            {...{
                                state: search,
                                label: "Search",
                                placeholder: "Find Fields",
                            }}
                        />
                    </div>
                    <VerbScrollbar className="column-table" ref={watchCol.containerRef}>
                        {TableLabel({
                            className: "header",
                            table,
                            usageStatus: "all",
                            dsColorIndex: dsIndex,
                            customColor: dvIndex != null && dataViewColor,
                        })}
                        <div className="column-list">
                            {getSearchedTable(table).columns.map((column) => {
                                return (
                                    <div
                                        key={column.id}
                                        className={cx("column-line-wrapper", {
                                            selected: column.id === state.value,
                                        })}
                                        onClick={() => state.onChange(column.id)}
                                    >
                                        {ColumnLine({
                                            type: column.$type,
                                            name: column.name,
                                            transformationId: column.calculations?.[0]?.transformationID,
                                            versionDetails: column.calculations?.[0]?.versionDetails,
                                            publishedOn: column.calculations?.[0]?.publishedOn,
                                            dataType: column.dataType,
                                            hasRelationship: getRelationshipUsage({columnId: column.id}),
                                            highlighted: column.id === state.value,
                                        })}
                                    </div>
                                );
                            })}
                        </div>
                    </VerbScrollbar>
                </div>
            );
        }
    );
