import {LpColorLine} from "../../../../../../common/left-panel/lp-color-line";
import {ffToDropdown} from "../../../../../../../../../common/form/ff-to-dropdown";
import {ffToColorPickerLine} from "../../../../../../../../../common/form/ff-to-color-picker-line";

export const OtherColorsTheme = ({form}) => {
    return (
        <div className="content">
            {LpColorLine({
                label: "Empty Fill Color",
                ...ffToColorPickerLine(form.field(["dataVisualization", "dataColorPalettes", "otherColors", "emptyFillColorRGB"])),
            })}

            {LpColorLine({
                label: "Comparison - Good",
                ...ffToColorPickerLine(form.field(["dataVisualization", "dataColorPalettes", "otherColors", "conditionalGoodColorRGB"])),
            })}

            {LpColorLine({
                label: "Comparison - Bad",
                ...ffToColorPickerLine(form.field(["dataVisualization", "dataColorPalettes", "otherColors", "conditionalBadColorRGB"])),
            })}
        </div>
    );
};
