import * as React from "react";

import {consumeContext} from "@common/react/context";
import {UseState} from "@common/react/use-state";
import {cs} from "@common/react/chain-services";
import {keyed} from "@common/react/keyed";
import {GuestLayout} from "../guest-routes/common/layout/guest-layout";
import {SetupMFAModal} from "../account-management/personal/setup-mfa-modal/setup-mfa-modal";
import {CheckIconRed} from "@common/ui-components/icons/global-icons";

export const SetupMFA = ({}) =>
    cs(
        consumeContext("apis"),
        consumeContext("auth"),
        consumeContext("routing"),
        consumeContext("routeTransitioning"),
        [
            "state",
            ({routing}, next) =>
                UseState({
                    initValue: {
                        loading: false,
                    },
                    next,
                }),
        ],
        ["mfaModal", (_, next) => SetupMFAModal({next})],

        ({state, routing, mfaModal}) => {
            const {loading} = state.value;
            return GuestLayout({
                header: "Set up Multi-Factor Authentication",
                smallHeader: true,
                className: "login-route-3d9",
                pageDescription:
                    "Your customers trust you to keep their information safe. MFA makes your account more secure by verifying your identity when you log in from a new device.",
                error: null,
                message: null,
                onSubmit: async () => {
                    await mfaModal.show({});
                    routing.goto("dashboard");
                },
                submitBtn: {
                    label: "Setup MFA",
                    disabled: loading,
                },
                otherButton: (
                    <a className="skip-btn" onClick={() => routing.goto("dashboard")}>
                        Skip for now
                    </a>
                ),
                rightLabel: "Dashboards sell SaaS!",
                rightContent: (() => {
                    const list = [
                        {
                            label: "Prove ROI",
                            content: "Showing customers their data when they want it shows them the value your platform provides.",
                        },
                        {
                            label: "Retention",
                            content: "Keep everyone, not just your power users coming back for more with great data experiences.",
                        },
                        {
                            label: "Sales",
                            content: "Your sales team is going to love you for giving them a killer feature to help them sell.",
                        },
                    ];

                    return (
                        <div className="list">
                            {list.map((item, index) =>
                                cs(keyed(index), () => (
                                    <div className="item">
                                        <div>
                                            <CheckIconRed />
                                        </div>
                                        <div className="text">
                                            <strong>{item.label}</strong> <br />
                                            {item.content}
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    );
                })(),
            });
        }
    );
