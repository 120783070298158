import React from "react";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {scope} from "@common/react/scope";
import {spc} from "@common/react/state-path-change";
import {LpColorLine, LpColorSelectLine} from "../../../../../../../../../../common/left-panel/lp-color-line";
import {FontSizeSelect} from "../../../common/font-size-select";

export const FontDisplayOptions = ({tile, theme, form, kpiColorsTileConfig}) => ({
    label: "Font Display Options",
    render: () =>
        cs(consumeContext("theme"), ({theme}) => {
            return (
                <div className="table-kpi-summary-number-pi3">
                    <div className="subsection">
                        <div className="header">Icon Options</div>

                        <div className="content">
                            {FontSizeSelect(scope(tile, ["style", "iconFontSize"]))}

                            {kpiColorsTileConfig?.render(0)}
                        </div>
                    </div>

                    <div className="subsection">
                        <div className="header">Label Font Options</div>

                        <div className="content">
                            {FontSizeSelect(scope(tile, ["style", "labelFontSize"]))}

                            {kpiColorsTileConfig?.render(1)}
                        </div>
                    </div>

                    <div className="subsection">
                        <div className="header">Value Font Options</div>

                        <div className="content">
                            {FontSizeSelect(scope(tile, ["style", "valueFontSize"]))}

                            {kpiColorsTileConfig?.render(2)}
                        </div>
                    </div>
                </div>
            );
        }),
});
