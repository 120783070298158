export const joinTypes = [
    {
        value: "Inner",
        label: "Inner Join",
        iconSrc: require("../join-icons/inner-join-icon.svg"),
        note: "All matching rows found in both data sets.",
    },
    {
        value: "LeftOuter",
        label: "Left Outer Join",
        iconSrc: require("../join-icons/left-outer-join-icon.svg"),
        note: "All rows from left and matching from right.",
    },
    {
        value: "RightOuter",
        label: "Right Outer Join",
        iconSrc: require("../join-icons/right-outer-join-icon.svg"),
        note: "All rows from right and matching from left.",
    },
    {
        value: "FullOuter",
        label: "Full Outer Join",
        iconSrc: require("../join-icons/full-outer-join-icon.svg"),
        note: "All rows from both data sets.",
    },
    {
        value: "Cross",
        label: "Cross Join",
        iconSrc: require("../join-icons/cross-join-icon.svg"),
        note: "Create a row to connect every left table row to every right table row.",
    },
];
