import "./tiles.scss";

import * as React from "react";
import {cx} from "emotion";

import {keyed} from "@common/react/keyed";
import {cs} from "@common/react/chain-services";
import {provideContext} from "@common/react/context";

import {gridHeight as DefaultGridHeight} from "../../../../../web-client/src/routes/collection/common/dnd-grid-panel/grid-constants";

export const Tiles = ({tiles, blockWidth, spacing, gridHeight = DefaultGridHeight, numViewableRows}) =>
    cs(
        (_, next) => {
            return next();
        },
        ({}) => (
            // data attributes are used for download PDF
            <div className="tiles tiles-2s2" data-grid-height={gridHeight} data-spacing={spacing}>
                {tiles?.map((tile) =>
                    cs(
                        keyed(tile.key),
                        ({}) =>
                            tile.name && (
                                <>
                                    <div
                                        className={cx(
                                            "tile",
                                            `verb-tile verb-tile-${tile.name.toLowerCase().replace(/[^\d\w]/g, "")} verb-tile-${tile.$type
                                                .toLowerCase()
                                                .replace("tile", "")}`
                                        )}
                                        data-title={tile.name}
                                        style={{
                                            padding: spacing,
                                            top: tile.position.y * (gridHeight + spacing * 2),
                                            left: tile.position.x * blockWidth,
                                            width: tile.size.width * blockWidth,
                                            height: (tile.size.height ?? numViewableRows) * (gridHeight + spacing * 2),
                                        }}
                                    >
                                        <div className="inner-box verb-tile-border">
                                            <div className="content">
                                                {cs(
                                                    (_, next) => provideContext("tileWidthPx", tile.size.width * blockWidth, next),
                                                    () => tile.render()
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                    )
                )}
            </div>
        )
    );
