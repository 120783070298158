const { generateDefaultTimeRange } = require("@common/logic/date-time/date-util");
const {
    addDate,
    addMonth,
    getMonthRange,
    getWeekRange,
    today,
    getMonthLength,
    getMonthStart,
    getYearRange,
    currentYear,
    addYear,
} = require("../../../../../utils/dates/date-object");
const { getPreviousDays } = require("../../live-filters/filter-types/date/expand/common/time/get-previous-days");

const lastWeek = () => {
    const getMainRange = () => {
        return getWeekRange(addDate(today(), -7));
    };

    return {
        getMainRange,
        getComparingRange: () => {
            const range = getMainRange();
            return {
                from: addDate(range.from, -7),
                to: {
                    ...addDate(range.to, -7),
                    hours: 23,
                    minutes: 59,
                    seconds: 59,
                },
            };
        },
    };
};
exports.lastWeek = lastWeek;

// const lastMonth = () => {
//     const lastDayOfMainMonth = addDate(getMonthStart(today()), -1);
//
//     const getMainRange = () => getMonthRange(lastDayOfMainMonth);
//
//     return {
//         getMainRange,
//         getComparingRange: () => {
//             const mainMonth = getMainRange();
//             const compareMonthTo = addMonth(mainMonth.to, -1);
//             return {
//                 from: addDate(compareMonthTo, -1 * getMonthLength(lastDayOfMainMonth)),
//                 to: {
//                     ...compareMonthTo,
//                     hours: 23,
//                     minutes: 59,
//                     seconds: 59,
//                 },
//             };
//         },
//     };
// };
// exports.lastMonth = lastMonth;

const lastMonth = () => {
    const lastDayOfMainMonth = addDate(getMonthStart(today()), -1);

    const getMainRange = () => ({
        ...getMonthRange(lastDayOfMainMonth),
        to: lastDayOfMainMonth,
    });

    return {
        getMainRange,
        getComparingRange: () => {
            const mainMonth = getMainRange();
            return getPreviousDays(generateDefaultTimeRange(mainMonth));
        },
    };
};
exports.lastMonth = lastMonth;

const lastYear = () => {
    const year = currentYear();

    return {
        getMainRange: () => getYearRange(year - 1),
        getComparingRange: () => getYearRange(year - 2),
    };
};
exports.lastYear = lastYear;

// (() => {
//     const getToday = () => ({ year: 2021, month: 9, day: 1 });
//     // const {getMainRange, getComparingRange} = lastWeek();
//     const {getMainRange, getComparingRange} = lastMonth(getToday);
//     console.log("today", getToday())
//     console.log("main range", getMainRange())
//     console.log("compare range", getComparingRange())
// })()
