import "./single-select-dropdown.scss";

import { css, cx } from "emotion";
import * as React from "react";

import { cs } from "../../../../../../../../react/chain-services";
import { consumeContext } from "../../../../../../../../react/context";

import { StyledClass } from "@common/react/styled-class";
import { DropdownSelectLive } from "../../../../../../filters/common/dropdown-select/dropdown-select-live";

export const SingleSelectDropdown = ({
    state,
    isSelected,
    data,
    valueToLabel,
    valueToSearch,
    placeholder,
    className,
    enableSearch = true,
    closeAllOtherDropdownWhenOpen = true,
}) =>
    cs(
        consumeContext("theme"),
        [
            "cssClass",
            ({theme}, next) => {
                const componentTheme = theme.general.components;

                return StyledClass({
                    content: {
                        position: "relative",
                        background: `${componentTheme.menuBackgroundColorRGB}`,
                        color: `${componentTheme.menuTextColorRGB}`,
                        "&:hover": {
                            background: `${componentTheme.menuHoverBackgroundColorRGB}`,
                        },

                        "&.selected": {
                            background: `${componentTheme.menuHoverBackgroundColorRGB}`,
                        },
                    },
                    next,
                });
            },
        ],
        ({theme, cssClass}) => {
            const componentTheme = theme.general.components;

            const inputStyle = {
                border: `${componentTheme.inputBorderWidth}px solid ${componentTheme.inputBorderColorRGB}`,
                background: `${componentTheme.inputBackgroundColorRGB}`,
                color: `${componentTheme.inputTextColorRGB}`,
                borderRadius: `${componentTheme.inputCornerRadius}px`,
            };

            return DropdownSelectLive({
                className: cx("single-select-dropdown-73f single-select-dropdown", className),
                enableSearch,
                closeAllOtherDropdownWhenOpen,
                allowUnselect: true,
                list: data,
                valueToLabel: valueToLabel || ((v) => (v === "" ? "Blank / Empty" : v)),
                valueToSearch,
                placeholder: (
                    <span
                        className={cx("hint")}
                        style={{
                            "fontFamily": `${theme.general.canvas.fontFamily || "Roboto"}, sans-serif`,
                            color: `${componentTheme.inputHintTextColorRGB}`,
                        }}
                    >
                        {placeholder || "Select..."}
                    </span>
                ),
                toggleStyle: inputStyle,
                iconColor: componentTheme.inputIconColorRGB,
                dropdownCss: cssClass,
                borderRadius: componentTheme.menuCornerRadius,
                isSelected: (d) => (isSelected ? isSelected(d) : state.value?.includes(d)),
                onChange: (d) => state.change(() => (d != null ? [d] : [])),
            });
        }
    );
