import * as React from "react";

import {cs} from "@common/react/chain-services";
import {provideContext} from "@common/react/context";

import {keepOnly} from "@common/utils/objects";
import {cascadeFind} from "@common/utils/cascade";

import {DataMenu} from "./data-menu/data-menu";

export const ModelDataTab = ({chart, model, dataSources, autoSuggest, next}) =>
    next({
        render: () =>
            DataMenu({
                model,
                dataSources,
                chart,
                autoSuggest,
            }),
        // render: () => null,
    });

export const cGetModelColumn = ({model, dataSources, name = "getModelColumn", next}) =>
    cs(
        [
            "getModelColumn",
            (_, next) => {
                const getModelColumn = (colId) =>
                    cascadeFind(
                        model,
                        "tables[*].columns[*]",
                        (column, {}, {1: table}) =>
                            column.id === colId && {
                                column,
                                table: keepOnly(table, ["$type", "id", "name", "visualName"]),
                                ...(table.$type === "ViewModelTable"
                                    ? {
                                          dvIndex: dataSources?.length,
                                      }
                                    : {
                                          dsIndex: dataSources?.findIndex((ds) => ds.id === table.dataSourceID),
                                      }),
                            }
                    );
                return next(getModelColumn);
            },
        ],
        ({getModelColumn}) => provideContext(name, getModelColumn, next)
    );
