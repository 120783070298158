const {getColorConfigs, getFieldColorConfigs} = require("./get-field-color");
const {generateColorScale} = require("../../utils/color-util");

export const prepareColor = ({tile, dataColorPalettes, usingColorGroupField = true, isBarDataColorAppliedByDimension = false, tileFields}) => {
    let colorScale = null;
    const colorConfigs = getColorConfigs(tile);
    const measureFields = tileFields.filter((f) => f.$type == "MeasureTileField" && f.isBarField);
    const hasOnlyOneMeasureBarField = measureFields.length == 1;
    const hasGroupField = !!tile.groupField;
    if ((usingColorGroupField && hasGroupField) || (hasOnlyOneMeasureBarField && isBarDataColorAppliedByDimension)) {
        const field = tile.groupField || measureFields[0];
        const {displayColor, type} = getFieldColorConfigs({
            field,
            dataColorPalettes,
            colorConfigs,
        });

        if (!displayColor) {
            // console.log(`~~~~~~~`);
            // console.log(field);
            // console.log(colorConfigs);
        } else {
            colorScale = generateColorScale(displayColor, type == "Sequential");
        }
    }

    return {
        colorConfigs,
        colorScale,
        getDisplayColor: ({series, index}) => {
            if (isBarDataColorAppliedByDimension && colorScale) {
                return {
                    colorByPoint: true,
                    colors: colorScale.generateAllColors(),
                };
            }

            const displayColor = (() => {
                if (colorScale) {
                    return colorScale.getColor(index);
                }
                const currentField = tileFields.find((f) => (series.tileFieldID ? f.id == series.tileFieldID : f.displayName == series.name && f.objs[0].id == series.stack));
                const colorConfig = getFieldColorConfigs({
                    field: currentField,
                    dataColorPalettes,
                    colorConfigs,
                });
                return colorConfig.displayColor;
            })();

            return isBarDataColorAppliedByDimension
                ? {colorByPoint: true, colors: displayColor}
                : {
                      [Array.isArray(displayColor) ? "colors" : "color"]: displayColor,
                  };
        },
    };
};
