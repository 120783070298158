import React from "react";
import {inputStepTypes} from "../../../../common/input-step-types";
import {transformationStepTypes} from "../../../../common/transformation-step-types";
import "./left-right-input-select.scss";
import {DropdownSelect2} from "@common/ui-components/dropdown-select/dropdown-select2";

export const LeftRightInputSelect = ({steps, getStepName, isSelected, onChange, ...props}) => (
    <div className="left-right-input-select left-right-input-select-frs">
        {DropdownSelect2({
            ...props,
            label: "Input",
            list: steps,
            valueToLabel: (s) => {
                const type = [...inputStepTypes, ...transformationStepTypes].find((t) => t.$type === s.$type);
                return (
                    <div className="step-label">
                        <img src={type.iconSrc} alt="" />
                        <div className="text">{getStepName(s) || s.name}</div>
                    </div>
                );
            },
            isSelected,
            onChange,
        })}
    </div>
);
