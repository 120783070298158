const {paddingLeft} = require("../../../../utils/strings");
const {monthShortLabels} = require("../../../../utils/dates/month-short-labels");
const {fullMonths} = require("../../../../utils/dates/full-months");
const {BasicFormatter} = require("./basic-formatter");
const {chain} = require("../../../../utils/fs");

// const tokens = [
//     {
//         token: "yyyy",
//         format: (date) => ""+date.getFullYear(),
//     },
//     {
//         token: "yy",
//         format: (date) => (""+date.getFullYear()).substring(2),
//     },
//
//     {
//         token: "Q",
//         format: (date) => "Q" + Math.ceil((date.getMonth() + 1) / 3),
//     },
//
//     {
//         token: "MMM",
//         format: (date) => monthShortLabels[date.getMonth()],
//     },
//     {
//         token: "MMMM",
//         format: (date) => fullMonths[date.getMonth()],
//     },
//     {
//         token: "M",
//         format: (date) => ""+(date.getMonth()+1),
//     },
//     {
//         token: "MM",
//         format: (date) => paddingLeft(date.getMonth()+1),
//     },
//
//
//     {
//         token: "d",
//         format: (date) => ""+date.getDate(),
//     },
//     {
//         token: "dd",
//         format: (date) => paddingLeft(date.getDate()),
//     },
//     {
//         token: "dddd",
//         format: (date) => ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"][date.getDay()],
//     },
//
//     {
//         token: "Week",
//         format: (date) => "Week"
//     },
//     {
//         token: "W",
//         format: (date) => {
//             const jan01 = new Date(date.getFullYear(), 0, 1);
//             const yearToDate = ((date - jan01)/86400000 + 1);
//             return Math.ceil(yearToDate/7)
//         }
//     }
// ];

const getTokens = (useUTC) => {
    const _getDay = (date) => (useUTC ? date.getUTCDay() : date.getDay());
    const _getDate = (date) => (useUTC ? date.getUTCDate() : date.getDate());
    const _getMonth = (date) => (useUTC ? date.getUTCMonth() : date.getMonth());
    const _getFullYear = (date) => (useUTC ? date.getUTCFullYear() : date.getFullYear());
    const _getMinutes = (date) => (useUTC ? date.getUTCMinutes() : date.getMinutes());
    const _getSeconds = (date) => (useUTC ? date.getUTCSeconds() : date.getSeconds());
    const _getHours = (date) => (useUTC ? date.getUTCHours() : date.getHours());
    const smallH = (date) => chain(_getHours(date), (_) => (_ == 0 ? 12 : _ <= 12 ? _ : _ - 12));

    return [
        {
            token: "dddd",
            format: (date) => ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"][_getDay(date)],
        },
        {
            token: "ddd",
            format: (date) => ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][_getDay(date)],
        },
        {
            token: "dd",
            format: (date) => paddingLeft(_getDate(date)),
        },
        {
            token: "d",
            format: (date) => "" + _getDate(date),
        },
        {
            token: "yyyy",
            format: (date) => "" + _getFullYear(date),
        },
        {
            token: "yy",
            format: (date) => ("" + _getFullYear(date)).substring(2),
        },

        {
            token: "Q",
            format: (date) => "Q" + Math.ceil((_getMonth(date) + 1) / 3),
        },
        {
            token: "MMMM",
            format: (date) => fullMonths[_getMonth(date)],
        },
        {
            token: "MMM",
            format: (date) => monthShortLabels[_getMonth(date)],
        },
        {
            token: "MM",
            format: (date) => paddingLeft(_getMonth(date) + 1),
        },
        {
            token: "M",
            format: (date) => "" + (_getMonth(date) + 1),
        },
        {
            token: "Week",
            format: (date) => "Week",
        },
        {
            token: "W",
            format: (date) => {
                const jan01 = new Date(_getFullYear(date), 0, 1);
                const yearToDate = (date - jan01) / 86400000 + 1;
                return Math.ceil(yearToDate / 7);
            },
        },
        {
            token: "HH",
            format: (date) => paddingLeft(_getHours(date)),
        },
        {
            token: "H",
            format: (date) => "" + _getHours(date),
        },
        {
            token: "hh",
            format: (date) => {
                return paddingLeft(smallH(date));
            },
        },
        {
            token: "h",
            format: (date) => {
                return "" + smallH(date);
            },
        },
        {
            token: "tt",
            format: (date) => {
                return _getHours(date) >= 12 ? "PM" : "AM";
            },
        },
        {
            token: "t",
            format: (date) => {
                return _getHours(date) >= 12 ? "P" : "A";
            },
        },
        {
            token: "mm",
            format: (date) => {
                return paddingLeft(_getMinutes(date));
            },
        },
        {
            token: "m",
            format: (date) => {
                return _getMinutes(date);
            },
        },
        {
            token: "ss",
            format: (date) => {
                return paddingLeft(_getSeconds(date));
            },
        },
        {
            token: "s",
            format: (date) => {
                return _getSeconds(date);
            },
        },
    ];
};

const createDateFormatter = (pattern, useUTC) => {
    const tokens = getTokens(useUTC);
    return BasicFormatter(tokens)(pattern);
};
exports.createDateFormatter = createDateFormatter;

// console.log(createDateFormatter("dddd, d MMMM, yyyy").format(new Date()));
// console.log(createDateFormatter("dddd, MMM d, yyyy").format(new Date()));
// console.log(createDateFormatter("Week W").format(new Date()));
// console.log(createDateFormatter("MMM d, yyyy", true).format(new Date("2021-1-1")))
