import {cs} from "@common/react/chain-services";
import {Checkbox} from "@common/ui-components/form/checkbox/checkbox";
import {createArray} from "@common/utils/collections";
import {DataTable} from "../../../../common/data-table/data-table";
import {SkeletonBox} from "../../../../common/skeleton/skeleton-box";

const list = createArray(8).map(() => ({
    id: "3e922093-e8b2-56aa-78c8-d56254e501bc",
    name: "baddate",
    syncMethod: "Full",
    syncInProgress: false,
    nextSync: "9999-12-31T23:59:59.9999999Z",
    lastSync: null,
    startedUtc: "2022-03-15T15:29:56.738Z",
    endedUtc: "2022-03-15T15:31:09.115Z",
    status: "Error",
    errorType: "ConnectionError",
    errorMessage: "Connect Timeout expired. | Unable to connect to any of the specified MySQL hosts.",
    records: 0,
    sizeMB: 0.0,
    paused: false,
    syncSchedule: {$type: "OneTimeSync"},
    syncDuration: 72377,
    visualName: "Baddate",
}));

export const SyncStatusTableSkeletons = ({}) =>
    cs(() =>
        DataTable({
            list,
            className: "sync-status-table-88e",
            columns: [
                {
                    labelF: () =>
                        Checkbox({
                            state: {
                                value: false,
                                onChange: () => {},
                            },
                        }),
                    format: () => {},
                },
                {
                    label: "Next Sync",
                    format: () => (
                        <div style={{display: "flex", alignItems: "center"}}>
                            {SkeletonBox({
                                style: {
                                    width: 6,
                                    height: 6,
                                    borderRadius: `50%`,
                                },
                            })}
                            &nbsp;&nbsp;
                            {SkeletonBox({style: {width: 52, height: 16}})}
                        </div>
                    ),
                },
                {
                    label: "Table",
                    className: "table-row-99e",
                    format: () => SkeletonBox({style: {width: `80%`, height: 30}}),
                },
                {
                    label: "Latest Sync",
                    format: () => SkeletonBox({style: {width: 85, height: 16}}),
                },
                {
                    label: "Latest Sync Data",
                    format: () => SkeletonBox({style: {width: 52, height: 16}}),
                },
                {
                    label: "Latest Sync Records",
                    format: () => SkeletonBox({style: {width: 52, height: 16}}),
                },
            ],
        })
    );
