const {chain2} = require("../utils/fs");
const {Static2} = require("./static-2");
const {UseState} = require("./use-state");
const {cs} = require("./chain-services");

/**
 * Accept reducer and will always fetch latest first before applying that reducer and save
 */
const ChangeQueue1 = ({next, fetchLatest, save}) =>
    cs(["waitings", (_, next) => Static2({getInitValue: () => [], next})], ["executing", (_, next) => UseState({next})], ({executing, waitings}) =>
        next({
            executing: executing.value,
            push: async (reducer) => {
                if (!executing.value) {
                    executing.onChange(true);
                    const promise = new Promise((resolve, reject) => {
                        waitings.set([...(waitings.get() || []), {reducer, resolve, reject}]);
                    });

                    setTimeout(async () => {
                        let latest = await fetchLatest();
                        for (;;) {
                            const list = waitings.get();

                            if (!list?.length) {
                                break;
                            }
                            waitings.set([]);

                            latest = chain2(
                                latest,
                                list.map((r) => r.reducer)
                            );

                            const applyAll = (fn) => {
                                const list2 = waitings.get();
                                if (!list2?.length) {
                                    list.forEach(fn);
                                }
                            };

                            try {
                                latest = await save(latest);

                                applyAll((r) => r.resolve(latest));
                            } catch (e) {
                                applyAll((r) => r.reject(e));
                            }
                        }
                        executing.onChange(false);
                    }, 0);

                    return promise;
                } else {
                    return new Promise((resolve, reject) => {
                        // console.log("Queueing")
                        waitings.set([...(waitings.get() || []), {reducer, resolve, reject}]);
                    });
                }
            },
        })
    );
exports.ChangeQueue1 = ChangeQueue1;
