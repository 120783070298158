import * as React from "react";
import {cs} from "@common/react/chain-services";
import {ShowHideToggle} from "../../../../../../../../../../../../../common/form/toggles/show-hide-toggle";
import "./table-format.scss";
import {scope} from "@common/react/scope";
import {CheckboxLine} from "../../../../../../../../../../common/checkbox-line/checkbox-line";
import {DebounceCache} from "@common/react/debounce-cache";
import {TextInput} from "../../../../../../../../../../../../../common/form/text-input/text-input";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {stateToSelect} from "@common/ui-components/form/state-to-select";
import {getPath} from "@common/utils/arr-path";
import {ComparisonDateRange} from "../../../single-kpi/panels/value-format/comparison-date-range/comparison-date-range";

export const TableFormat = ({tile}) => ({
    label: "Table Format",
    render: () => (
        <div className="table-kpi-table-format-tyi">
            <div className="subsection">
                <div className="header">
                    Table Title
                    <div className="control">
                        {ShowHideToggle({
                            state: scope(tile, ["style", "tableTitleShown"]),
                        })}
                    </div>
                </div>
                {getPath(tile.value, ["style", "tableTitleShown"]) && (
                    <div className="content">
                        {cs(
                            [
                                "debounce",
                                ({state}, next) =>
                                    DebounceCache({
                                        state: scope(tile, ["style", "tableTitle"]),
                                        next,
                                    }),
                            ],
                            ({debounce}) =>
                                TextInput({
                                    label: "Table Title",
                                    state: debounce.state,
                                    onBlur: debounce.flush,
                                })
                        )}
                    </div>
                )}
            </div>
            <div className="subsection">
                <div className="header">
                    Value
                    <div className="control">
                        {ShowHideToggle({
                            state: scope(tile, ["style", "actualValueShown"]),
                        })}
                    </div>
                </div>
                {getPath(tile.value, ["style", "actualValueShown"]) && (
                    <div className="content">
                        {DropdownSelect({
                            label: "Show as",
                            list: [
                                {label: "Actual Value", value: "ActualValue"},
                                {
                                    label: "Percent of Total",
                                    value: "PercentOfTotal",
                                },
                            ],
                            valueToLabel: (v) => v.label,
                            ...stateToSelect(scope(tile, ["style", "actualValueFormat"]), ["value"]),
                        })}
                        {CheckboxLine({
                            label: "Auto Round",
                            state: scope(tile, ["style", "autoRound"]),
                        })}
                    </div>
                )}
            </div>

            {ComparisonDateRange({
                tile,
            })}
        </div>
    ),
});
