import {cs} from "@common/react/chain-services";
import {DialogService} from "../../../../../common/dialog/dialog-service";
import {consumeContext} from "@common/react/context";
import {UseState} from "@common/react/use-state";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";
import {TextInput} from "@common/form/text-input/text-input";
import {Button} from "@common/form/buttons/button/button";
import * as React from "react";
export const DeleteSharedTileDialog = ({onDone, next: rootNext, reloadFolderOnDone = false}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    render: ({resolve, args: {tiles}}) => ({
                        title: "Delete Shared Tile",
                        width: 500,
                        content: next({
                            resolve,
                            tiles,
                        }),
                    }),
                    next: rootNext,
                }),
        ],
        consumeContext("apis"),
        consumeContext("toast"),
        ["confirmText", (_, next) => UseState({next})],
        ["submitting", (_, next) => UseState({next})],
        ({modal, confirmText, submitting, apis, toast}) => {
            const {tiles} = modal;

            const numberOfTiles = tiles.length;
            const message = numberOfTiles == 1 ? `Deleting the "${tiles[0].adminTileName}" tile cannot be undone.` : `Are you sure you want to delete ${numberOfTiles} tiles?`;
            return (
                <form
                    onSubmit={async (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (!confirmText.value || confirmText.value.toLowerCase() !== "delete" || submitting.value) {
                            return;
                        }

                        submitting.onChange(true);
                        await Promise.all(tiles.map((tile) => apis.collectionTiles.deleteTile(tile.id)));
                        const newFolders = reloadFolderOnDone ? await apis.collectionTiles.getFolders() : null;
                        onDone?.(newFolders);

                        toast.show(`${numberOfTiles == 1 ? tiles[0].adminTileName : `${numberOfTiles} tiles`} deleted`);

                        submitting.onChange(false);
                        modal.resolve(true);
                    }}
                >
                    <div className="delete-collection-dialog-57a">
                        <VerbDialogBodyScrollbar>
                            <div className="main-part">
                                <div className="warning">{message}</div>

                                {TextInput({
                                    label: <span className="confirm-input-label-5sj">Please type DELETE to confirm</span>,
                                    state: confirmText,
                                })}
                            </div>
                        </VerbDialogBodyScrollbar>

                        <div className="buttons">
                            <Button type="button" btnType="secondary" onClick={() => modal.resolve()}>
                                Cancel
                            </Button>
                            <Button type="submit" btnType="primary-danger" disabled={!confirmText.value || confirmText.value.toLowerCase() !== "delete" || submitting.value}>
                                Delete
                            </Button>
                        </div>
                    </div>
                </form>
            );
        }
    );
