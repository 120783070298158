import * as React from "react";
import {cs} from "@common/react/chain-services";
import {Static2} from "@common/react/static-2";

export const RelationshipLine = ({leftEnd, rightEnd}) =>
    cs(["ref", (_, next) => Static2({next})], ({ref}) => {
        const canvas = ref.get() && ref.get().getBoundingClientRect();
        return (
            <svg height="100%" width="100%" style={{background: "transparent"}} ref={ref.set}>
                <defs>
                    <marker id="semi-circle-end" viewBox="0 0 5 10" refX="0" refY="5" markerUnits="strokeWidth" markerWidth="0.5" markerHeight="1" fill="#002CA6" orient="auto">
                        {/*<path d="M 0 0 L 5 0 A 5 5 0 0 0 5 10 L 0 10 z" />*/}
                        <path d="M 0 0 A 5 5, 1, 1 1, 0 10 z" />
                    </marker>
                </defs>

                {leftEnd &&
                    leftEnd.x !== 0 &&
                    rightEnd &&
                    rightEnd.x !== 0 &&
                    canvas &&
                    (() => {
                        const x1 = leftEnd.x;
                        const y1 = leftEnd.y - canvas.top;
                        const x2 = rightEnd.x;
                        const y2 = rightEnd.y - canvas.top;
                        const c1 = {x: x1 + 30, y: y1};
                        const c2 = {x: x2 - 30, y: y2};
                        const anchorSize = 3;
                        const anchorStyle = {
                            stroke: "#002CA6",
                            strokeWidth: 6,
                            markerEnd: "url(#semi-circle-end)",
                        };
                        return (
                            <>
                                <line x1={x1} y1={y1} x2={x1 + anchorSize} y2={y1} style={anchorStyle} />
                                <line x1={x2} y1={y2} x2={x2 - anchorSize} y2={y2} style={anchorStyle} />
                                <path
                                    d={`M ${x1}, ${y1} C ${c1.x}, ${c1.y} ${c2.x}, ${c2.y} ${x2}, ${y2}`}
                                    style={{
                                        stroke: "#002CA6",
                                        strokeWidth: 2,
                                        strokeDasharray: 5,
                                        fill: "none",
                                    }}
                                />
                            </>
                        );
                    })()}
            </svg>
        );
    });
