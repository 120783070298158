import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {flatten1} from "@common/utils/collections";
import {TabsHeader} from "../../../../../common/tabs/tabs-header";
import {NoSearchesFound} from "../../list/searches/no-searches-found";
import {SyncSettingsUnstructuredList} from "../sync-settings-unstructured-list";
import {getRealNameChildTable} from "../unstructured-list-helpers";
import {SyncSettingsPropertiesSearches} from "./sync-settings-properties-searches";
import {isMatchText} from "@common/utils/strings";
// import { SyncSettingsColumnsSearches } from "./sync-settings-columns-searches";

export const Searches = {
    OBJECT: "objects",
    PROPERTIES: "properties",
};

export const SyncSettingsUnstructuredSearches = ({dataSource, searchState, interactions, selectedTables, oriDs, clearSearch, adding}) =>
    cs(
        ["searchTab", (_, next) => UseState({initValue: Searches.OBJECT, next})],
        [
            "getSearchesNestedAndProperties",
            (_, next) =>
                next(() => {
                    const filteredProperties = (c) => (searchState.value.filter ? false : isMatchText(c.name, searchState.value.value));

                    return [
                        ...flatten1(
                            [...dataSource.value.tables, ...dataSource.value.disabledTables].map((table, i) =>
                                flatten1([
                                    table.columns.filter(filteredProperties).map((c) => ({
                                        ...c,
                                        tableName: table.name,
                                    })),
                                    table.disabledColumns.filter(filteredProperties).map((c) => ({
                                        ...c,
                                        tableName: table.name,
                                    })),
                                ])
                            )
                        ),
                        ...dataSource.value.tables.filter(
                            (t) =>
                                t.isChildTable &&
                                filteredProperties({
                                    name: getRealNameChildTable(t),
                                })
                        ),
                        ...(dataSource.value.disabledTables || [])?.filter(
                            (t) =>
                                t.isChildTable &&
                                filteredProperties({
                                    name: getRealNameChildTable(t),
                                })
                        ),
                    ];
                }),
        ],
        ({searchTab, getSearchesNestedAndProperties}) => {
            const nestedAndProperties = getSearchesNestedAndProperties();
            const searchVal = searchState.value.value;

            return (
                <div className="sync-settings-searches-88p">
                    <div className="header">
                        <div className="search">
                            <div className="text">“{searchVal}” search results</div>
                            <div className="clear" onClick={clearSearch}>
                                Clear result
                            </div>
                        </div>

                        {TabsHeader({
                            tabs: [
                                {
                                    label: "Objects",
                                    name: Searches.OBJECT,
                                    numbers: () => {
                                        const filtered = searchState.value.filter ?? ((t) => isMatchText(t.name, searchVal));
                                        return (
                                            dataSource.value.tables.filter((t) => !t.isChildTable).filter(filtered).length +
                                            dataSource.value.disabledTables
                                                .filter((t) => !t.isChildTable)
                                                .map((t) => ({
                                                    ...t,
                                                    disabled: true,
                                                }))
                                                .filter(filtered).length
                                        );
                                    },
                                },
                                {
                                    label: "Nested Objects & Properties",
                                    name: Searches.PROPERTIES,
                                    numbers: () => nestedAndProperties.length,
                                },
                            ].map((item) => ({
                                ...item,
                                active: item.name === searchTab.value,
                                label: (
                                    <>
                                        {item.label} ({item.numbers()})
                                    </>
                                ),
                                onClick: (item) => searchTab.onChange(item.name),
                            })),
                        })}
                    </div>

                    {Searches.OBJECT === searchTab.value &&
                        SyncSettingsUnstructuredList({
                            searchState,
                            dataSource,
                            interactions,
                            selectedTables,
                            oriDs,
                            adding,
                            noSearch: NoSearchesFound({type: "objects"}),
                        })}

                    {Searches.PROPERTIES === searchTab.value &&
                        SyncSettingsPropertiesSearches({
                            dataSource,
                            columns: nestedAndProperties,
                            noSearch: NoSearchesFound({type: "properties"}),
                        })}
                </div>
            );
        }
    );
