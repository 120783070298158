// const {initTooltipConnectorHorizontalHelper} = require("../../factory/tooltip/connector-helpers/tooltip-connector-horizontal-helper");
const {getThemeBorderRadiusOptions} = require("../../bar-series-processors/get-border-radius-options");
const {getDataLabelsOptions} = require("../../factory/data-labels");
const {DATA_BOOST_THRESHOLD, DATA_TURBO_THRESHOLD} = require("../../common/axis-chart-boost-turbo-constants");

const getPlotOptions = ({tile, theme, formatters, isCompare, onClickPoint}) => {
    // const tooltipConnectorHelper = theme.dataVisualization.toolTipsAndLegends.indicatorLineShown && initTooltipConnectorHorizontalHelper({
    //     isStacking: tile.style.displayType && tile.style.displayType !== "Classic",
    //     tooltipStyle: theme.dataVisualization.toolTipsAndLegends,
    // });

    return {
        bar: {
            dataLabels: getDataLabelsOptions({tile, formatters, theme}),
            ...(theme.dataVisualization.axisCharts.barChartCornerRadius > 0 && getThemeBorderRadiusOptions(theme.dataVisualization.axisCharts)),
            ...(isCompare &&
                ["Stacked", "HundredPercent"].includes(tile.style.displayType) && {
                    grouping: false, // to stick primary column and compare column together
                }),
            shadow: false,
            borderWidth: 0,
            // maxPointWidth: 30,
            // stacking: "normal",
            ...(() => {
                const displayType = tile.style.displayType;

                if (!displayType || displayType === "Classic") {
                    return {};
                }

                return displayType === "Stacked"
                    ? {
                          stacking: "normal",
                      }
                    : {
                          stacking: "percent",
                      };
            })(),
            getExtremesFromAll: true, // to prevent measurement axis from scaling on scroll
        },
        series: {
            cursor: onClickPoint ? "pointer" : "default",
            boostThreshold: DATA_BOOST_THRESHOLD,
            turboThreshold: DATA_TURBO_THRESHOLD,
            point: {
                events: {
                    // ...(tooltipConnectorHelper && {
                    //     mouseOver: function() {
                    //         setTimeout(() => tooltipConnectorHelper.render({chart: this.series.chart}), 0)
                    //     },
                    //     mouseOut: function() {
                    //         setTimeout(() => tooltipConnectorHelper.remove(), 0)
                    //     }
                    // }),
                    click: function ({x, y, point}) {
                        onClickPoint?.({x, y, point, chart: this.series.chart});
                    },
                },
            },
        },
    };
};
exports.getPlotOptions = getPlotOptions;
