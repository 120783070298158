import {cs} from "@common/react/chain-services";
import * as React from "react";
import "./text-filter-options.scss";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {stateToSelect} from "@common/ui-components/form/state-to-select";
import {scope} from "@common/react/scope";
import {DefaultFilterCriteria} from "./default-filter-criteria/default-filter-criteria";
import {consumeContext} from "@common/react/context";
import {TextInput} from "../../../../../../../../../common/form/text-input/text-input";
import {ffToTextInput} from "../../../../../../../../../common/form/ff-to-text-input";
import {MpLine} from "../../common/mp-line/mp-line";
import {getPath} from "@common/utils/arr-path";
import {TextareaInput} from "../../../../../tile/edit/left-panel/tabs/tile-tab/chart-types/common/textarea-input/textarea-input";
import {ShowHideToggle} from "../../../../../../../../../common/form/toggles/show-hide-toggle";
import {ComparisonMethods} from "./comparison-methods/comparison-methods";
import {ControlTypes} from "./control-types/control-types";
import {OnOffToggle} from "../../../../../../../../../common/form/toggles/on-off-toggle";
import {ffToDropdown} from "../../../../../../../../../common/form/ff-to-dropdown";
import {DebounceTextInput} from "../../../../../../../../../common/form/debounce-input/debounce-text-input";
import {ListSortOrder} from "./list-sort-order/list-sort-order";

export const TextFilterOptions = ({filter, form}) =>
    cs(consumeContext("collection"), ({collection}) => (
        <div className="text-filter-options-732">
            <div className="option-group label">
                <div className="label">Filter Label</div>
                <div className="dual-line">
                    <div className="left">
                        {TextInput({
                            label: "Label",
                            ...ffToTextInput(form.field(["label"])),
                        })}
                    </div>
                    <div className="right">
                        {DebounceTextInput({
                            label: "Hint",
                            state: scope(filter, ["hint"]),
                        })}
                    </div>
                </div>

                {filter.value.label && (
                    <>
                        {MpLine({
                            left: ShowHideToggle({
                                state: scope(filter, ["showLabelInfoIcon"]),
                            }),
                            right: "Filter Label Info Icon",
                        })}
                        {getPath(filter.value, ["showLabelInfoIcon"]) &&
                            TextareaInput({
                                state: scope(filter, ["labelInfoIconText"]),
                            })}
                    </>
                )}
            </div>

            <div className="option-group">
                <div className="label">Filter Settings</div>

                {filter.value.columns?.length > 1 &&
                    DropdownSelect({
                        label: "Filter Matching Criteria",
                        list: ["Any", "All"],
                        info: "The filter criteria may either be found in at least one of the fields or must be found in all of the fields searched. ",
                        tooltipTopOffset: -5,
                        valueToLabel: (op) =>
                            [
                                {
                                    label: "Show results meeting ANY criteria",
                                    value: "Any",
                                },
                                {
                                    label: "Show results meeting ALL criteria",
                                    value: "All",
                                },
                            ].find((item) => item.value === op).label,
                        ...ffToDropdown(form.field(["multiSelectionOption"])),
                    })}

                {collection.value.$type !== "ApiCollection" &&
                    DropdownSelect({
                        label: "Text Filter Type",
                        list: [
                            {
                                label: "Free Form Text",
                                name: "FreeFormText",
                            },
                            {
                                label: "Selectable List",
                                name: "SelectableList",
                            },
                        ],
                        valueToLabel: (item) => item.label,
                        ...stateToSelect(scope(filter, ["option"]), ["name"]),
                    })}

                {filter.value.option === "FreeFormText" && ComparisonMethods({filter})}

                {filter.value.option === "SelectableList" && (
                    <>
                        {ControlTypes({filter, form})}

                        {ListSortOrder({filter, form})}
                    </>
                )}

                {/*{MpLine({*/}
                {/*    left: OnOffToggle({state: scope(filter, ["required"])}),*/}
                {/*    right: "Require value to run this dashboard report",*/}
                {/*})}*/}
            </div>

            {filter.value.option === "FreeFormText" && DefaultFilterCriteria({filter, form})}
        </div>
    ));
