import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {Static2} from "@common/react/static-2";
import React, {createElement as h} from "react";
import {TooltipConsumer} from "./tooltip-context-3";
import {AnyAction} from "@common/react/any-action";
import "./static-info-box.scss";
import {GlobalEvent} from "@common/react/global-event";

let defaultPos = {top: -9999, left: -9999, zIndex: -999};

export const StaticInfoBox = ({next, tooltipContent, tooltipWidth = 288}) => {
    return cs(
        [
            "position",
            (_, next) =>
                UseState({
                    initValue: defaultPos,
                    next,
                }),
        ],
        ["contentRef", (_, next) => Static2({next})],
        ["registry", ({}, next) => React.createElement(TooltipConsumer, {}, next)],
        [
            "content",
            ({contentRef, position}, next) =>
                next(
                    <div
                        {...{
                            className: "static-info-box-99o",
                            style: {
                                ...(position.value ?? {}),
                                zIndex: 10,
                                width: tooltipWidth,
                                position: "fixed",
                            },
                        }}
                        ref={contentRef.set}
                    >
                        {tooltipContent}
                    </div>
                ),
        ],
        ({position}, next) =>
            h(AnyAction, {
                disabled: position.value?.top < 0,
                fn: () => position.onChange(defaultPos),
                next,
            }),
        ({position, registry, contentRef, content}) => {
            const getInfo = ({top, left, bottom, height, width}) => {
                const contentRect = contentRef.get().getBoundingClientRect();
                return {
                    top: top - contentRect.height - 10,
                    left: left - tooltipWidth / 2 + width / 2 + 2,
                };
            };

            return (
                <>
                    {next({
                        render: ({content}) =>
                            cs(["static1", (_, next) => Static2({next})], ({static1}) =>
                                content({
                                    ref: static1.set,
                                    active: position.value.top > 0,
                                    onMouseDown: () => {
                                        return tooltipContent && position.onChange(position.value.top > 0 ? defaultPos : getInfo(static1.get().getBoundingClientRect()));
                                    },
                                })
                            ),
                    })}

                    {position.value.top > 0 &&
                        GlobalEvent({
                            eventName: "wheel",
                            fn: () => position.onChange(defaultPos),
                        })}

                    {tooltipContent && registry.render(content)}
                </>
            );
        }
    );
};
