import * as React from "react";
import {FieldInfoProvider} from "../../../common/field-info-provider/field-info-provider";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {cs} from "@common/react/chain-services";
import {getHiddenMapLegendPositions} from "./map-legend-display-rules";

export const MapLegendPositionSelect = (state, size) =>
    cs(({}) => {
        const hiddenPos = getHiddenMapLegendPositions(size);
        return FieldInfoProvider({
            className: "margin-bottom",
            info: (
                <span>
                    <b>Top & Bottom Horizontal:</b> minimum chart width is 6 squares and minimum chart height is 6 squares high.
                    <br />
                    <br />
                    <b>Left & Right Vertical:</b> minimum chart width is 7 squares and minimum chart height is 6 squares high.
                </span>
            ),
            tooltipBoxWidth: 170,
            topOffset: 0,
            field: DropdownSelect({
                label: "Position",
                list: [
                    {
                        value: "Auto",
                        label: "Auto",
                    },
                    {
                        value: "Top",
                        label: "Top Horizontal",
                    },
                    {
                        value: "Bottom",
                        label: "Bottom Horizontal",
                    },
                    {
                        value: "Left",
                        label: "Left Vertical",
                    },
                    {
                        value: "Right",
                        label: "Right Vertical",
                    },
                ].map(({label, value}) => {
                    const disabled = hiddenPos.includes(value);
                    return {
                        label: !disabled ? label : `${label} (unavailable)`,
                        value,
                        disabled,
                    };
                }),
                isSelected: (v) => v.value === (hiddenPos.includes(state.value) ? "Auto" : state.value),
                onChange: (v) => state.onChange(v.value),
                valueToLabel: (v) => v.label,
            }),
        });
    });
