import React from "react";
import {cs} from "@common/react/chain-services";
import {ManageMemberDialog, userRoles} from "./manage-member/manage-member-dialog";
import {Load2} from "@common/react/load2";
import {consumeContext} from "@common/react/context";
import "./org-active-members.scss";
import {DataTable} from "../../../common/data-table/data-table";
import {PopupMenu} from "../../../common/popup-menu/popup-menu";
// import {Toast} from "../../../../common/toast/toast";
import {DeleteMemberDialog} from "./delete-member/delete-member-dialog";
import {LoadingIndicator} from "@common/ui-components/loading-indicator/loading-indicator";
import {InviteMemberService} from "./invite-member-service";
import {Button} from "../../../../../../common/form/buttons/button/button";
import {cx} from "emotion";
import {capitalize} from "@common/utils/strings";
import {ConfirmDialog} from "../../../common/confirm-dialog/confirm-dialog";

export const OrgActiveMembers = ({next}) =>
    cs(
        consumeContext("apis"),
        consumeContext("auth"),
        consumeContext("toast"),
        [
            "users",
            ({apis}, next) =>
                Load2({
                    fetch: () => apis.tenant.getUsers(),
                    next,
                }),
        ],
        ["createMemberDialog", (_, next) => ManageMemberDialog({next})],
        [
            "inviteMemberService",
            ({users}, next) =>
                InviteMemberService({
                    next,
                    currentLength: users?.value?.length,
                }),
        ],
        ({createMemberDialog, users, toast, apis, auth, inviteMemberService}) =>
            next({
                actions: () =>
                    cs(() => (
                        <div className="models-dashboard-actions-2rf">
                            <Button
                                iconLeft={<i className="fal fa-plus" />}
                                className="create-model-btn"
                                onClick={async () => {
                                    await inviteMemberService.invite({
                                        onDone: async () => {
                                            const resp = await createMemberDialog.show({
                                                user: {roles: ["Full"]},
                                            });
                                            if (resp) {
                                                toast.show("Invitation Sent");
                                                users.onChange(resp);
                                            }
                                        },
                                    });
                                }}
                            >
                                Add New Member
                            </Button>
                        </div>
                    )),
                render: ({height}) =>
                    cs(
                        ["updateMemberDialog", (_, next) => ManageMemberDialog({next})],
                        ["deleteMemberDialog", (_, next) => DeleteMemberDialog({next})],
                        ["disableMFADialog", (_, next) => DeleteMemberDialog({next})],
                        [
                            "disableMFADialog",
                            (_, next) =>
                                ConfirmDialog({
                                    next,
                                    submitText: "Disable",
                                    title: "Disable MFA",
                                }),
                        ],
                        [
                            "userCM",
                            ({routing, updateMemberDialog, deleteMemberDialog, disableMFADialog}, next) =>
                                PopupMenu({
                                    getCommands: ({user}) => {
                                        let commands = [
                                            {
                                                label: "Resend invitation",
                                                onClick: async () => {
                                                    await apis.tenant.resendInvite(user.id);
                                                    const resp = await apis.tenant.getUsers();
                                                    users.onChange(resp);
                                                    toast.show("Invitation Sent");
                                                },
                                                isShow: () => user.$type == "PendingUserInvite",
                                            },
                                            {
                                                label: "Disable MFA",
                                                onClick: async () => {
                                                    const results = await disableMFADialog.show({
                                                        description: `Disabling MFA for ${user.firstName} will delete their MFA configuration and send them an email notification. They will be prompted to re-configure their MFA upon login.`,
                                                    });

                                                    if (!results) {
                                                        return;
                                                    }

                                                    const resp = await apis.user.enableMFA(user.id, false);
                                                    if (resp) {
                                                        toast.show("Disabled MFA and sent an email notification");
                                                        const resp2 = await apis.tenant.getUsers();
                                                        users.onChange(resp2);
                                                    }
                                                },
                                                isShow: () => user.isMfaEnabled,
                                                // isShow: () => true,
                                            },
                                            //{
                                            //    label: "Edit permission",
                                            //    onClick: () => updateMemberDialog.show({user}),
                                            //    isShow: () => user.$type != "PendingUserInvite"
                                            //},
                                            {
                                                label: `Delete ${user.$type == "PendingUserInvite" ? "invite" : "User"}`,
                                                className: "delete-action",
                                                onClick: async () => {
                                                    const resp = await deleteMemberDialog.show({
                                                        user,
                                                    });
                                                    if (resp == "confirm") {
                                                        users.change((us) => us.filter((u) => u.id != user.id));
                                                    }
                                                },
                                                isShow: () => true,
                                            },
                                        ];

                                        return commands.filter((c) => c.isShow());
                                    },
                                    next,
                                }),
                        ],
                        ({userCM}) => {
                            if (!users.value) {
                                return <div className="org-active-members-a00">{LoadingIndicator()}</div>;
                            }

                            return (
                                <>
                                    {DataTable({
                                        className: "org-active-members-a00",
                                        list: users.value,
                                        maxHeight: height,
                                        useVerbScrollbar: false,
                                        rowClassName: (user) => (user.$type == "PendingUserInvite" ? "pending-row" : ""),
                                        columns: [
                                            {
                                                label: "Name",
                                                format: (user) => (user.$type == "PendingUserInvite" ? "Pending Invitation" : `${user.firstName} ${user.lastName}`),
                                            },
                                            {
                                                label: "Email address",
                                                format: (user) => user.email,
                                            },
                                            {
                                                label: "MFA Status",
                                                format: (user) => {
                                                    const status = user.isMfaEnabled ? "enabled" : "disabled";
                                                    return (
                                                        <div className="mfa-status">
                                                            <div className={cx("status-light", status)} />
                                                            {capitalize(status)}
                                                        </div>
                                                    );
                                                },
                                            },
                                            {
                                                label: "Access",
                                                format: (user) => user.roles.map((r) => userRoles.find((role) => role.value == r)?.name || r).join(", "),
                                            },
                                            {
                                                format: (user) => (
                                                    <div className="cm">
                                                        {auth.user.id != user.id &&
                                                            userCM.render({
                                                                params: {
                                                                    user: user,
                                                                },
                                                            })}
                                                    </div>
                                                ),
                                                className: "user-cm",
                                                shy: true,
                                                alignRight: true,
                                            },
                                        ],
                                    })}
                                </>
                            );
                        }
                    ),
            })
    );
