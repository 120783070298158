import {isBlank} from "@common/utils/strings";
import {first, last, sort, toMap} from "@common/utils/collections";
import {equalDeep, isEmpty, keepOnly} from "@common/utils/objects";
// import {dataSourceTypes} from "../../common/data-source-type";

export const dsSyncHelpers = {
    columnChanged: (oriColumns, updatedColumns) => {
        if (oriColumns.length !== updatedColumns.length) return true;
        //defaultAggregationFunc, displayColumnReferenceName
        const validationProps = ["primaryKey", "primaryKeyManual", "defaultAggregationFunc", "displayColumnReferenceName", "dataTypeProperties"];

        for (let i = 0; i < oriColumns.length; i++) {
            const a1 = oriColumns[i];
            const a2 = updatedColumns[i];

            if (!equalDeep(keepOnly(a1, validationProps), keepOnly(a2, validationProps))) return true;
        }
        return false;
    },
    getPausedTables: (oriDs, updatedDs) => {
        const pauseds = [];
        const resumes = [];

        const oriTables = sort(oriDs.tables, (c) => c.name);
        const updatedTables = sort(updatedDs.tables, (c) => c.name);

        for (let i = 0; i < oriTables.length; i++) {
            const a1 = oriTables[i];
            const a2 = updatedTables[i];

            if (!a1.paused && a2.paused) {
                pauseds.push(a1.id);
            }

            if (a1.paused && !a2.paused) {
                resumes.push(a2.id);
            }
        }
        return {pauseds, resumes};
    },
    hasChanged: (oriDs, updatedDs) => {
        const oriTables = sort(oriDs.tables, (c) => c.name);
        const updatedTables = sort(updatedDs.tables, (c) => c.name);

        if (oriTables.length !== updatedTables.length) return true;
        //syncMethod, syncSchedule, columns
        const validationProps = ["syncMethod", "syncSchedule", "whereFilter", "paused"];

        for (let i = 0; i < oriTables.length; i++) {
            const a1 = oriTables[i];
            const a2 = updatedTables[i];

            if (!equalDeep(keepOnly(a1, validationProps), keepOnly(a2, validationProps)) || dsSyncHelpers.columnChanged(a1.columns, a2.columns)) {
                return true;
            }
        }
        return false;
    },
    getTablesChanged: (oriDs, updatedDs) => {
        let oriTables = toMap(oriDs.tables, (t) => t.name);
        let updatedTables = toMap(updatedDs.tables, (t) => t.name);

        //syncMethod, syncSchedule, columns
        const validationProps = ["syncMethod", "syncSchedule", "whereFilter", "paused"];

        const reduceFunc = (acc, tableName) => {
            const oriTable = oriTables[tableName];
            const updatedTable = updatedTables[tableName];

            if (!oriTable && updatedTable) {
                acc = acc.concat({
                    ori: null,
                    updated: updatedTable,
                });
            } else if (oriTable && !updatedTable) {
                acc = acc.concat({
                    ori: oriTable,
                    updated: updatedTable ?? {name: tableName},
                });
            } else if (oriTable && updatedTable) {
                if (
                    !equalDeep(keepOnly(oriTable, validationProps), keepOnly(updatedTable, validationProps)) ||
                    dsSyncHelpers.columnChanged(oriTable.columns, updatedTable.columns)
                ) {
                    acc = acc.concat({
                        ori: oriTable,
                        updated: updatedTable,
                    });
                }

                delete updatedTables[tableName];
            }

            return acc;
        };

        let result = Object.keys(oriTables).reduce(reduceFunc, []);
        const result2 = Object.keys(updatedTables).reduce(reduceFunc, []);

        return result.concat(result2);
    },
    getColumnsChanged: (oriColumns1, updatedColumns1) => {
        const oriColumns = sort(oriColumns1, (c) => c.name);
        let updatedColumns = updatedColumns1;
        let result = [];

        if (oriColumns.length < updatedColumns1.length) {
            const newSyncColumns = updatedColumns1.filter((t) => oriColumns.map((t1) => t1.name).indexOf(t.name) === -1);
            newSyncColumns.forEach((newTable) => {
                result.push({
                    ori: null,
                    updated: newTable,
                });
            });
            updatedColumns = updatedColumns1.filter((t) => newSyncColumns.map((t1) => t1.name).indexOf(t.name) === -1);
        } else if (oriColumns.length > updatedColumns1.length) {
            updatedColumns = oriColumns.map(
                (oriT) =>
                    updatedColumns1.find((ut) => ut.name === oriT.name) || {
                        name: oriT.name,
                    }
            );
        }

        updatedColumns = sort(updatedColumns, (c) => c.name);

        const validationProps = ["primaryKey", "primaryKeyManual", "defaultAggregationFunc", "displayColumnReferenceName", "dataTypeProperties"];

        for (let i = 0; i < oriColumns.length; i++) {
            const a1 = oriColumns[i];
            const a2 = updatedColumns[i];

            if (!equalDeep(keepOnly(a1, validationProps), keepOnly(a2, validationProps))) {
                result.push({
                    ori: a1,
                    updated: a2,
                });
            }
        }
        return result;
    },
    validTables: (tables) => {
        if (tables?.length === 0 || !tables) return false;
        // if(type === dataSourceTypes.FILE) return true;
        for (let i = 0; i < tables?.length || 0; i++) {
            const t = tables[i];
            if (isEmpty(t.syncSchedule) || !t.syncSchedule.$type) return false;
            if (isBlank(t.syncMethod) || t.syncMethod === "None") return false;
        }
        return true;
    },
    getInvalidTables: (tables) => {
        let ret = [];
        if (tables?.length === 0 || !tables) return ret;
        for (let i = 0; i < tables?.length || 0; i++) {
            const t = tables[i];
            // if (isEmpty(t.syncSchedule) || !t.syncSchedule.$type || isBlank(t.syncMethod) || t.syncMethod === "None") {
            //     ret.push(t.name);
            // }
            if (isBlank(t.syncMethod) || t.syncMethod === "None") {
                ret.push(t.name);
            }
        }
        return ret;
    },
};

export const getFoldersPath = (folderID, folders) => {
    if (folderID == null || !folders) return [{id: null, parentFolderID: null, name: "Data Sources"}];

    const getPath = (folderID, result) => {
        const _item = folders.find((f) => f.id === folderID);
        if (_item.id === null)
            return [
                {
                    ...keepOnly(_item, ["id", "parentFolderID"]),
                    name: "Data Sources",
                },
                ...result,
            ];

        if (_item) {
            result = [keepOnly(_item, ["id", "name", "parentFolderID"]), ...result];
            return getPath(_item.parentFolderID, result);
        }

        return result;
    };

    return getPath(folderID, []);
};

export const formatFoldersPath = (paths) => {
    if (paths.length <= 2) return paths.reduce((prev, item, index) => `${prev}${index > 0 ? ` / ` : ``}${item.name}`, "");
    return first(paths).name + " / ... / " + last(paths).name;
};
