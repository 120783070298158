const {booleanFormatter} = require("../factory/formatters/boolean-formatter");
const {createDateFormatter} = require("../common/formatters/date-formatter");
const {chain} = require("../../../utils/fs");
const {roundTo} = require("../common/formatters/numeric/round-to");
const {getNumericFormatter} = require("../factory/formatters/get-numeric-formatter");
const {getDateTimeFormatter} = require("../factory/formatters/get-date-time-formatter");
const {getFieldType} = require("../common/get-field-type");

const getValueFormatters = ({valueField, groupField, timezone, tile}) => {
    const groupFieldFormatter = groupField
        ? {
              date: groupField?.isDateGroupCategory ? (v) => v : getDateTimeFormatter(groupField.dateProperties, timezone),
              bool: booleanFormatter,
          }[getFieldType(groupField)] || ((v) => v)
        : (v) => v;
    const numericProperties = valueField.numericProperties;
    const isAggCount = valueField.aggregationFunc.startsWith("Count");

    const valueFormatter = getNumericFormatter({
        numericProperties: {
            ...numericProperties,
            currencyOverrides: tile?.currencyOverrides,
        },
        useDefaultCountFormat: isAggCount,
    });
    const valueRoundFormatter = getNumericFormatter({
        numericProperties: {
            ...numericProperties,
            currencyOverrides: tile?.currencyOverrides,
        },
        autoRound: true,
        useDefaultCountFormat: isAggCount,
    });

    return {
        valueFormatter: (v) =>
            v != null &&
            chain(
                v,
                (v) => (numericProperties?.displayType === "Percentage" ? v * 100 : v),
                (v) => (numericProperties?.decimalPlaces != null ? roundTo(v, numericProperties.decimalPlaces) : roundTo(v, 2)),
                (v) => valueFormatter(v)
            ),
        valueRoundFormatter: (v) =>
            v != null &&
            chain(
                v,
                (v) => (numericProperties?.displayType === "Percentage" ? v * 100 : v),
                (v) => (numericProperties?.decimalPlaces != null ? roundTo(v, numericProperties.decimalPlaces) : roundTo(v, 2)),
                (v) => valueRoundFormatter(v)
            ),
        groupFieldFormatter,
        percentFormatter: (v) => roundTo(v, 1) + "%",
        percentRoundFormatter: (v) => Math.round(v) + "%",
        dateFormatter: (v) => createDateFormatter("MMM d, yyyy").format(new Date(v)),
    };
};
exports.getValueFormatters = getValueFormatters;
