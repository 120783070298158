import * as React from "react";

import {keyed} from "@common/react/keyed";
import {Load2} from "@common/react/load2";
import {cs} from "@common/react/chain-services";
import {changeContext, consumeContext, provideContext} from "@common/react/context";

import {changePath} from "@common/utils/arr-path";
import {isValidTimezone, LiveDashboard} from "@common/ui-components/live/live-dashboard/live-dashboard";
import {LoadingIndicator} from "@common/ui-components/loading-indicator/loading-indicator";
import {SDKErrorWhenLoad} from "@common/ui-components/sdk-error-when-load/sdk-error-when-load";
import {dropdownRegistry} from "@common/ui-components/dropdown/dropdown-registry";

import {modalRegistry} from "../../web-client/src/routes/common/modal/modal-registry";
import {tooltipRegistry3} from "../../web-client/src/routes/common/tooltip3/tooltip-registry-3";

import {loadApis} from "./loaders/load-apis";
import {sdkStaticUrl} from "./loaders/sdk-static-url";
import {DetachedFilterDoms} from "./detached-filter-doms";
import {commonRegistry} from "@common/ui-components/registry/common-registry";
import {StyledClassParentDom} from "@common/react/styled-class";
import {cssLinks} from "./loaders/constrant";
import {UseState} from "@common/react/use-state";
import {VerbShadowRoot} from "./verb-shadow-root";
import {Invoke} from "@common/react/invoke";
import {DownloadTileData} from "./download-tile-data";
import {loadGTag} from "@common/utils/load-gtag";

export const VerbShadowRootContainer = ({containerDOM, ...otherProps}) =>
    VerbShadowRoot({
        containerDOM,
        render: (shadowRoot) => (
            <>
                {cssLinks.map((link) => (
                    <link rel="stylesheet" href={link} type="text/css" />
                ))}

                {/* adding a wrapper div to fix print css not working */}
                <div className="verb-shadow-root-wrapper">
                    {VerbDashboard({
                        shadowRoot,
                        ...otherProps,
                    })}
                </div>
            </>
        ),
    });

export const VerbDashboard = ({
    tenantId,
    envId,
    sdkStaticUrl,
    collectionId,
    version,
    apiKey,
    filterVals,
    staticAuth,
    authParams,
    hiddenFilterIds,
    timezone = null,
    printPDF = false,
    downloadTileData = null,
    conversion,
    themeID,
    ref,
    shadowRoot,
    iframeKeyName = null,
}) =>
    cs(
        ({}, next) =>
            shadowRoot
                ? StyledClassParentDom({
                      dom: shadowRoot,
                      next,
                  })
                : next(),
        ({}, next) => tooltipRegistry3({next, shadowRoot}),
        ({}, next) => dropdownRegistry({next, shadowRoot}),
        ({}, next) => modalRegistry({next, shadowRoot}),
        ({}, next) => commonRegistry({next, shadowRoot}),
        ({}, next) => provideContext("shadowRoot", shadowRoot, next),
        ({}, next) => provideContext("sdkStaticUrl", sdkStaticUrl, next),
        ({}, next) => provideContext("authParams", authParams, next),
        [
            "currencyOverrides",
            (_, next) => {
                // modify currency conversion
                if (!conversion) return next(null);

                const currencyConversion = JSON.parse(conversion);
                return next({
                    baseRateIso: currencyConversion?.baseCode,
                    displayIso: currencyConversion?.displayCode,
                    decimals: 2,
                    currencyConversions: (currencyConversion?.rates ?? []).map((r) => ({
                        isoCode: r.code,
                        rate: r.rate,
                    })),
                });
            },
        ],
        ({currencyOverrides}, next) => provideContext("currencyOverrides", currencyOverrides, next),
        ({currencyOverrides}, next) =>
            loadApis({
                currencyOverrides,
                tenantId,
                envId,
                version,
                apiKey,
                staticAuth,
                authParams,
                themeID,
                next,
            }),
        (_, next) => {
            if (!timezone) {
                return next();
            }
            return isValidTimezone(timezone)
                ? next()
                : SDKErrorWhenLoad({
                      error: {
                          message: "Sorry, the time zone was not recognized. Please refresh your page or contact support",
                      },
                  });
        },
        ["collection", ({}, next) => loadCollection({collectionId, staticAuth, next})],
        ({collection}, next) => (collection == null ? "Loading collection..." : next()),
        ({collection}, next) =>
            cs(
                keyed(iframeKeyName),
                (_, next) =>
                    Invoke({
                        props: {iframeKeyName},
                        action: ({getLatestProps}) => {
                            let {iframeKeyName} = getLatestProps();
                            document.title = iframeKeyName ?? `Dashboard | ${collection.name}`;
                        },
                        next,
                    }),
                () => next()
            ),
        ({}, next) => loadGTag({app: "iframe", next}),

        ({collection}, next) => {
            if (downloadTileData) {
                return DownloadTileData({
                    collection,
                    tileID: downloadTileData,
                    filterVals,
                });
            }

            return next();
        },
        [
            "detachedFilterDoms",
            ({collection}, next) =>
                collection.filterStyle.display === "Detached" ? DetachedFilterDoms({collectionId: collection.id, next}) : next(null),
        ],

        ({collection}, next) =>
            changeContext(
                "apis",
                (apis) =>
                    changePath(apis, ["collection"], (collectionApi) => ({
                        ...collectionApi,
                        getValidTiles: async ({filterId}) => collection.filterConnectedTileValidity[filterId],
                        getFilterData: async ({filterId, columns, filterValues}) =>
                            collectionApi.getFilterData({
                                filterId,
                                collectionId: collection.id,
                                columns,
                                filterValues,
                            }),
                        getNumericFilterData: async ({filterId, columns, filterValues}) =>
                            collectionApi.getNumericFilterData({
                                filterId,
                                collectionId: collection.id,
                                columns,
                                filterValues,
                            }),
                    })),
                next
            ),

        ["fontState", (_, next) => UseState({next})],
        ({collection, detachedFilterDoms, fontState}) => (
            <>
                {LiveDashboard({
                    sdkDashboard: true,
                    collection,
                    ...(detachedFilterDoms && {
                        renderFilter: ({content}) => DetachedFilters({doms: detachedFilterDoms.doms, content}),
                    }),
                    hiddenFilterIds,
                    timezone,
                    filterVals,
                    printPDF,
                    theme: collection.theme,
                    loadFont: (font) => {
                        if (font && font !== "Roboto") {
                            fontState.onChange(font);
                        }
                    },
                    ref: (r) =>
                        ref?.({
                            ...r,
                            addFilterDom: detachedFilterDoms?.addFilterDom,
                            removeFilterDom: detachedFilterDoms?.removeFilterDom,
                        }),
                })}

                {fontState.value && (
                    <link
                        rel="stylesheet"
                        href={`https://fonts.googleapis.com/css?family=${fontState.value.split(" ").join("+")}:300,400,700`}
                        type="text/css"
                    />
                )}
            </>
        )
    );

const DetachedFilters = ({doms, content}) => {
    const ReactDOM = require("react-dom");
    return doms.map(({dom, key}) => cs(keyed(key), () => ReactDOM.createPortal(content, dom)));
};

const loadCollection = ({collectionId, staticAuth, next}) =>
    cs(
        consumeContext("apis"),
        [
            "collection",
            ({apis}, next) =>
                Load2({
                    fetch: () => apis.getCollection(collectionId),
                    next,
                    captureException: true,
                }),
        ],
        ({collection}, next) => {
            return collection.loading
                ? LoadingIndicator()
                : collection.error
                ? SDKErrorWhenLoad({
                      error: collection.error,
                      staticAuth,
                  })
                : next();
        },
        ({collection}, next) => provideContext("collection", collection, next),
        ({collection}) => next(collection.value)
    );

//TODO: will remove default rate
const rates = {
    // "base": "USD",
    // "last_updated": 1659574800,
    exchange_rates: {
        C: 1000,
        A: 10,
        B: 100,
        // "EUR": 0.980488,
        // "JPY": 134.934798,
        // "BGN": 1.917639,
        // "CZK": 24.03667,
        // "DKK": 7.295323,
        // "GBP": 0.825228,
        // "HUF": 386.577115,
        // "PLN": 4.612511,
        // "RON": 4.820375,
        // "SEK": 10.162761,
        // "CHF": 0.957251,
        // "ISK": 137.366408,
        // "NOK": 9.746544,
        // "HRK": 7.365722,
        // "RUB": 104.99999999999999,
        // "TRY": 17.960094,
        // "AUD": 1.432199,
        // "BRL": 5.135798,
        // "CAD": 1.287773,
        // "CNY": 6.758604,
        // "HKD": 7.849887,
        // "IDR": 14852.093342,
        // "ILS": 3.323365,
        // "INR": 79.582312,
        // "KRW": 1303.980782,
        // "MXN": 20.277478,
        // "MYR": 4.458967,
        // "NZD": 1.588587,
        // "PHP": 55.450534,
        // "SGD": 1.37837,
        // "THB": 35.670164,
        // "ZAR": 16.638298,
        // "ARS": 75.269373,
        // "DZD": 124.445887,
        // "MAD": 8.83269,
        // "TWD": 27.466513,
        // "BTC": 0.000042,
        // "ETH": 0.00057,
        // "BNB": 0.003126,
        // "DOGE": 14.307501,
        // "XRP": 2.624839,
        // "BCH": 0.006997,
        // "LTC": 0.01599
    },
};
