import "./child-tile.scss";

import React from "react";
import {cx} from "emotion";

import {cs} from "@common/react/chain-services";
import {getELoc} from "@common/react/get-e-loc";
import {GlobalMouseMove} from "@common/react/global-mouse-move";
import {GlobalMouseUp} from "@common/react/global-mouse-up";
import {scope} from "@common/react/scope";
import {spc} from "@common/react/state-path-change";
import {Static2} from "@common/react/static-2";
import {UseState} from "@common/react/use-state";
import {renderChildTile} from "@common/ui-components/charts/render-child-tile";
import {inside} from "@common/utils/rectangles";
import {CloseIcon} from "../../../../common/icons/close-icon";
import {MaximizeIcon, MinimizeIcon} from "./icons";
import {buildString} from "@common/utils/strings";
import {provideContext} from "@common/react/context";
import {OnMounted} from "@common/react/on-mounted";

export const ChildModalTile = ({next}) =>
    cs(["draggingState", (_, next) => UseState({next})], ["staticRef", (_, next) => Static2({next})], ({draggingState, staticRef}) =>
        next({
            startDraggingNewTile: ({tile, pos}) =>
                draggingState.onChange({
                    draggingNew: {tile, pos},
                }),
            render: ({tile, tileActionId, remoteTile}) => {
                const ti = tile.value.tileActions.findIndex((ta) => ta?.id === tileActionId);
                const childTile = scope(tile, ["tileActions", ti, "tile"]);

                const insideRect = () => {
                    if (!staticRef.get()) {
                        return false;
                    }

                    return (
                        draggingState.value?.draggingNew &&
                        inside(staticRef.get().getBoundingClientRect(), draggingState.value?.draggingNew?.pos)
                    );
                };

                return cs(
                    [
                        "childTileComp",
                        (_, next) => {
                            return childTile?.value?.id
                                ? renderChildTile({
                                      isEditTile: true,
                                      tile: {
                                          ...remoteTile.value.tileActions[ti].tile,
                                          title: buildString([
                                              tile.value.tileActions[ti].tileTitleBefore ?? "",
                                              "{{Value}}",
                                              tile.value.tileActions[ti].tileTitleAfter ?? "",
                                          ]),
                                      },
                                      next,
                                  })
                                : next(null);
                        },
                    ],
                    ({childTileComp}) =>
                        ModalChildTileLayout({
                            className: {hovering: insideRect()},
                            staticRef,
                            content: childTileComp && childTileComp.render(),
                            otherContent: (
                                <>
                                    {childTileComp && childTileComp.filter()}
                                    {draggingState.value?.draggingNew &&
                                        DraggingNewChildTile({
                                            draggingNew: scope(draggingState, ["draggingNew"]),
                                            onDrop: () => {
                                                const {draggingNew} = draggingState.value;

                                                if (insideRect()) {
                                                    childTile.onChange({
                                                        ...draggingNew.tile,
                                                    });
                                                }
                                                draggingState.onChange(null);
                                            },
                                        })}
                                </>
                            ),
                        })
                );
            },
        })
    );

export const ModalChildTileLayout = ({className, staticRef, content, otherContent, onClose}) =>
    cs(
        ["minimize", (_, next) => UseState({initValue: true, next})],
        ["tileRef", (_, next) => Static2({next})],
        ["tileWidthPx", (_, next) => UseState({initValue: 0, next})],
        ({tileWidthPx, minimize}, next) => provideContext("tileWidthPx", tileWidthPx.value / (minimize.value ? 2 : 1), next),
        ({minimize, tileRef, tileWidthPx}) => (
            <div className="child-tile-99i" ref={(elem) => tileRef.set(elem)}>
                {OnMounted({
                    action: () => {
                        setTimeout(() => {
                            if (tileRef.get()) {
                                tileWidthPx.onChange(tileRef.get().offsetWidth);
                            }
                        }, 10);
                    },
                })}

                <div className="control-bar">
                    <div className="controls">
                        <div className="control" onClick={() => minimize.onChange(!minimize?.value)}>
                            {minimize.value ? (
                                <>
                                    {MaximizeIcon({})}&nbsp;
                                    <div className="text" style={{marginLeft: 9}}>
                                        Maximize
                                    </div>
                                </>
                            ) : (
                                <>
                                    {MinimizeIcon({})}&nbsp;
                                    <div className="text" style={{marginLeft: 9}}>
                                        Minimize
                                    </div>
                                </>
                            )}
                        </div>
                        {onClose && (
                            <div className="control" onClick={() => onClose()}>
                                {CloseIcon()}&nbsp;
                                <div className="text" style={{marginLeft: 12}}>
                                    Close
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="content">
                    <div
                        className={cx("tile", {minimize: minimize.value, "no-content": !content}, className)}
                        ref={(el) => {
                            staticRef?.set(el);
                        }}
                    >
                        {content ?? "Drag a tile type here to start"}
                    </div>
                </div>
                {otherContent}
            </div>
        )
    );

export const DraggingNewChildTile = ({draggingNew, onDrop}) =>
    cs(() => {
        const {pos, tile} = draggingNew.value;
        return (
            <>
                <div
                    className="dragging-new-child-tile-88o"
                    style={{
                        top: pos.y,
                        left: pos.x,
                    }}
                >
                    {tile.icon}
                    <div className="name">{tile.name}</div>
                </div>

                {GlobalMouseMove({
                    fn: (e) => spc(draggingNew, ["pos"], () => getELoc(e)),
                })}

                {GlobalMouseUp({
                    fn: (e) => onDrop(),
                })}
            </>
        );
    });
