import React from "react";
import {css, cx} from "emotion";

import {Watch} from "@common/react/watch";
import {keyed1} from "@common/react/keyed";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {spc} from "@common/react/state-path-change";
import {ObserveDomSize} from "@common/react/observe-dom-size";
import {consumeContext, provideContext} from "@common/react/context";

import {hexToRGB} from "@common/utils/color-util";
import {equalDeep, keepOnly} from "@common/utils/objects";
import {LocalEvent} from "@common/react/local-event";
import {fragments} from "@common/react/fragments";
import {Invoke} from "@common/react/invoke";
import {OnUnmounted} from "@common/react/on-unmounted";
import {Static2} from "@common/react/static-2";
import {StyledClass} from "@common/react/styled-class";

let cachedTiles = null;

export const loadAutoRefreshProvider = ({next: rootNext, collection, isSDKDashboard = false}) => {
    let {clientRefreshIntervalMins = 0} = collection || {};
    let intervalTime = clientRefreshIntervalMins * 60 * 1000;

    return cs(
        consumeContext("routing"),
        ["clearEvents", (_, next) => Static2({next})],
        ["events", (_, next) => (isSDKDashboard && clientRefreshIntervalMins > 0 ? LocalEvent({next}) : next())],
        [
            "state",
            ({routing}, next) => {
                let loadedTiles = cachedTiles ?? new Set();
                if (
                    routing &&
                    routing.previousRoutes?.[0]?.routeName != "edit-tile" &&
                    routing.previousRoutes?.[0]?.routeName != "edit-collection"
                ) {
                    loadedTiles = new Set();
                    cachedTiles = null;
                }

                return UseState({
                    next,
                    initValue: {
                        key: Date.now(),
                        loadedTiles,
                    },
                });
            },
        ],
        // setup auto-trigger silent reload event
        ({events, clearEvents}, next) => {
            if (!isSDKDashboard || !collection || clientRefreshIntervalMins == 0) {
                return next();
            }

            return fragments(
                Invoke({
                    onMounted: () => {
                        let interval = setInterval(() => {
                            events.emit("silentReload");
                        }, intervalTime);

                        clearEvents.set(() => {
                            clearInterval(interval);
                            events.removeAllListeners();
                        });
                    },
                }),
                OnUnmounted({
                    action: () => {
                        const clearFn = clearEvents.get();
                        clearFn?.();
                    },
                }),
                next()
            );
        },
        ({state, events}) =>
            provideContext(
                "autoRefreshProvider",
                {
                    ...state.value,
                    events,
                    refresh: () => spc(state, ["key"], () => Date.now()),
                    addLoadedTile: (tileID) => {
                        state.value.loadedTiles.add(tileID);
                        cachedTiles = state.value.loadedTiles;
                    },
                },
                rootNext
            )
    );
};

const excludedTileTypes = ["DownloadReportTile", "TextTile", "SectionTile"];

export const AutoRefreshController = ({tileID, enabledAutoRefresh, loadDataOnInit = false, render, backgroundColor, ...other}) => {
    if (excludedTileTypes.includes(other.tile.$type)) {
        return render(other, true, false);
    }

    const {r, g, b} = hexToRGB(backgroundColor ?? "#ffffff");
    const isEnabledAutoDataRefresh = enabledAutoRefresh == null ? true : enabledAutoRefresh;

    return cs(
        consumeContext("autoRefreshProvider"),
        [
            "state",
            ({autoRefreshProvider}, next) => {
                const isLoadedTile = autoRefreshProvider?.loadedTiles.has(tileID);

                return UseState({
                    initValue: {
                        store: {
                            ...other,
                            refreshKey: autoRefreshProvider?.key,
                        },
                        hasChanges: isLoadedTile ? false : !isEnabledAutoDataRefresh ?? !loadDataOnInit,
                        loadData: isLoadedTile || isEnabledAutoDataRefresh || loadDataOnInit,
                    },
                    next,
                });
            },
        ],
        ({state, autoRefreshProvider}, next) => {
            return Watch({
                next,
                value: {
                    ...other,
                    refreshKey: autoRefreshProvider?.key,
                    isEnabledAutoDataRefresh,
                },
                initRun: !loadDataOnInit,
                onChanged: (newProps, oldPros, isInitRun) => {
                    const {isEnabledAutoDataRefresh: isEnabledAutoDataRefresh1, refreshKey, ...other1} = newProps ?? {};
                    const {isEnabledAutoDataRefresh: isEnabledAutoDataRefresh2, refreshKey: refreshKey2, ...other2} = oldPros ?? {};
                    const keepFields = [
                        "xAxisField",
                        "xAxisFields",
                        "yAxisFields",
                        "yAxisField",
                        "yAxisBarFields",
                        "yAxisLineFields",
                        "groupField",
                        "filters",
                        "limit",
                        "sort",
                        "categorySort",
                        "valueField",
                        "valueFields",
                        "targetValueField",
                        "rowFields",
                        "columnFields",
                        "tileActions",
                        "customSortValues",
                    ];

                    if (!isEnabledAutoDataRefresh) {
                        if (state.value.hasChanges && refreshKey == refreshKey2) {
                            return;
                        }
                        let isTileChanges = !equalDeep(keepOnly(other1.tile, keepFields), keepOnly(other2.tile, keepFields));

                        const isConditionalFormattingChanges = !equalDeep(
                            other1.tile?.style?.conditionalFormattingRules,
                            other2.tile?.style?.conditionalFormattingRules
                        );

                        const newFilter = other1.tileFilters?.getValue?.();
                        const oldFilter = other2.tileFilters?.getValue?.();

                        let isFilterChanges =
                            other1.tileFilters && other2.tileFilters && !equalDeep(newFilter, oldFilter, {ignoreFunction: true});

                        if (isTileChanges || isFilterChanges || isConditionalFormattingChanges) {
                            spc(state, ["hasChanges"], () => (isInitRun ? !state.value.loadData : true));
                            return;
                        } else {
                        }
                    }

                    const newState = {
                        ...(refreshKey != refreshKey2
                            ? {
                                  hasChanges: false,
                                  loadData: true,
                              }
                            : state.value),
                        store: {
                            ...other,
                            refreshKey,
                        },
                    };

                    state.onChange(newState);

                    if (newState.loadData) {
                        autoRefreshProvider?.addLoadedTile?.(tileID);
                    }
                },
            });
        },
        ({state, autoRefreshProvider}) => {
            const {store, loadData, hasChanges} = state.value;
            const {tile} = store;
            const oriTile = other.tile;

            const refresh = () => {
                state.onChange({
                    store: {
                        ...other,
                        refreshKey: autoRefreshProvider?.key,
                    },
                    hasChanges: false,
                    loadData: true,
                });

                autoRefreshProvider?.addLoadedTile?.(tileID);
            };

            const refreshLayoutVisible =
                (tile.fieldValidity !== oriTile.fieldValidity || tile.fieldValidity !== "CannotDisplay") && hasChanges;

            return (
                <>
                    {render(store, loadData, hasChanges)}
                    {refreshLayoutVisible &&
                        cs(
                            [
                                "layoutRef",
                                ({}, next) =>
                                    ObserveDomSize({
                                        next,
                                        ignoreChange: (newSize, oldSize) =>
                                            newSize.height === oldSize.height && Math.abs(newSize.width - oldSize.width) <= 20,
                                    }),
                            ],
                            ({layoutRef}) => (
                                <div
                                    className="auto-refresh-layout-fv5"
                                    style={{
                                        backgroundColor: `rgba(${r}, ${g}, ${b}, 0.8)`,
                                    }}
                                    ref={layoutRef.ref}
                                >
                                    <div
                                        className={cx("btn-refresh", layoutRef.value?.width < 90 && "icon-only")}
                                        onClick={refresh}
                                        style={{
                                            borderColor: backgroundColor,
                                        }}
                                    >
                                        <RefreshIcon />
                                        {layoutRef.value?.width >= 90 && "Refresh Data"}
                                    </div>
                                </div>
                            )
                        )}
                </>
            );
        }
    );
};

export const RefreshIcon = ({className, fillColor = "#919EB0"}) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
        <path
            fill={fillColor}
            d="M2.94355 2.06806C4.48593 0.731569 6.45914 -0.00283888 8.5 8.24726e-06C13.1946 8.24726e-06 17 3.80546 17 8.50001C17 10.3156 16.4305 11.9986 15.4615 13.379L12.75 8.50001H15.3C15.3001 7.16689 14.9084 5.86314 14.1735 4.75087C13.4386 3.63861 12.3929 2.76689 11.1666 2.24411C9.94026 1.72133 8.58731 1.57055 7.27596 1.81052C5.96462 2.05049 4.75274 2.67062 3.791 3.59381L2.94355 2.06806ZM14.0565 14.932C12.5141 16.2684 10.5409 17.0029 8.5 17C3.80545 17 0 13.1946 0 8.50001C0 6.68441 0.5695 5.00141 1.5385 3.62101L4.25 8.50001H1.7C1.69989 9.83313 2.09164 11.1369 2.82654 12.2491C3.56143 13.3614 4.60706 14.2331 5.8334 14.7559C7.05974 15.2787 8.41269 15.4295 9.72404 15.1895C11.0354 14.9495 12.2473 14.3294 13.209 13.4062L14.0565 14.932Z"
        />
    </svg>
);

export const RefreshSpinnerIcon = ({className}) =>
    cs(
        [
            "spinnerCss",
            ({}, next) =>
                StyledClass({
                    content: {
                        "@keyframes rotateCW": {
                            "0%": {
                                transform: "rotate(0deg)",
                            },
                            "100%": {
                                transform: "rotate(360deg)",
                            },
                        },
                        animation: "rotateCW 1.2s infinite linear",
                    },
                    next,
                }),
        ],
        ({spinnerCss}) => {
            return <RefreshIcon className={cx(className, spinnerCss)} fillColor={"#11a1fd"} />;
        }
    );
