const {createDateFormatter} = require("../../common/formatters/date-formatter");
const moment = require("moment-timezone");

const getDateTimeFromZone = (v, timezone) => {
    const obj = moment.tz(v, timezone);
    return obj.toDate();
};

const getDateTimeFormatter = (dateProperties, timezone) => {
    const useUTC = !timezone;

    if (!dateProperties) {
        // return (v) => v;
        const formatter = createDateFormatter("M/d/yy", useUTC);
        return (v) => {
            return formatter.format(getDateTimeFromZone(v, timezone));
        };
    }

    const {aggregation, format} = dateProperties;

    if (aggregation === "None") {
        return (v) => {
            const date = getDateTimeFromZone(v, timezone);
            return createDateFormatter(format || "M/d/yy | h:mm tt", useUTC).format(date);
        };
    }

    const isDate = dates.includes(aggregation);
    const defaultFormat = isDate ? "M/d/yy" : "h:mm tt";
    return (v) => {
        const date = getDateTimeFromZone(v, timezone);
        return createDateFormatter(format || defaultFormat, useUTC).format(date);
    };
};
exports.getDateTimeFormatter = getDateTimeFormatter;

const dates = ["Days", "Weeks", "Months", "Quarters", "Years"];
