import {cs} from "@common/react/chain-services";
import React from "react";
import {Tabs} from "@common/ui-components/tabs/tabs";
import {keyed} from "@common/react/keyed";
import {consumeContext} from "@common/react/context";

export const ReportBuilderLayout = ({activeTab, tabs}) =>
    cs(consumeContext("routing"), ({routing}) => {
        const {render} = tabs.find((t) => t.name === activeTab.value);

        return (
            <div className="env-dashboard-layout-2re">
                <div className="env-dashboard-header">
                    <div className="env-name">
                        <span className="tab-name">Report Builder</span>
                    </div>

                    {Tabs({
                        activeTab: {
                            ...activeTab,
                            onChange: (tabValue) =>
                                routing.goto("report-builder", {
                                    reportTab: tabValue,
                                }),
                        },
                        list: tabs,
                    })}
                </div>

                <div className="env-dashboard-content">{cs(keyed(activeTab.value), () => render())}</div>
            </div>
        );
    });
