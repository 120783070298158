import * as React from "react";
import {Display} from "./panels/display/display";
import {XAxis} from "./panels/x-axis/x-axis";
import {YAxes} from "./panels/y-axis/y-axes";
import {Colors} from "./panels/colors/colors";
import {TileTitle} from "../common/tile-title/tile-title";
import {DataLabels} from "./panels/data-labels/data-labels";
import {Legend} from "../combo-chart/panels/legend/legend";
import {OtherOptions} from "../common/other-options/other-options";
import {ReferenceLines} from "../common/reference-lines/reference-lines";

export const LineChart = ({tile, colorsTileConfig, referenceLinesPanel, form, size, parentTile, tileAction, isContainerTile}) =>
    [Display, TileTitle, (props) => ReferenceLines({...props, referenceLinesPanel}), XAxis, ...YAxes(tile.value.style.yAxis), DataLabels, Colors, Legend, OtherOptions]
        .map((panel) =>
            panel({
                tile,
                colorsTileConfig,
                form,
                size,
                parentTile,
                tileAction,
                isContainerTile,
            })
        )
        .filter((v) => v);
