import "./display-name.scss";

import * as React from "react";
import {cs} from "@common/react/chain-services";
import {Expandable} from "../../../../../../../../../../common/expandable/expandable";
import {TextInput} from "@common/form/text-input/text-input";
import {scope} from "@common/react/scope";
import {DebounceCache} from "@common/react/debounce-cache";

export const DisplayName = ({field, getFieldInfo, constantField}) =>
    cs(({}) => (
        <Expandable
            {...{
                label: "Display Name",
                alwaysShow: true,
                initExpand: true,
                render: () => (
                    <div className="display-name-3ps">
                        {cs(
                            [
                                "debounce",
                                (_, next) => {
                                    if (constantField) {
                                        return DebounceCache({
                                            state: scope(constantField, ["displayName"]),
                                            next,
                                        });
                                    }

                                    const {value, onChange} = scope(field, ["displayName"]);
                                    const fieldInfo = getFieldInfo(field.value);
                                    return DebounceCache({
                                        state: {
                                            value: value || fieldInfo.name,
                                            onChange: (v) => (v === fieldInfo.columnName ? onChange(null) : onChange(v)),
                                        },
                                        next,
                                    });
                                },
                            ],
                            ({debounce}) =>
                                TextInput({
                                    label: constantField ? "Value Display Name" : "Field Display Name",
                                    state: debounce.state,
                                    onBlur: debounce.flush,
                                })
                        )}
                    </div>
                ),
            }}
        />
    ));
