const operations = [
    {
        label: "Range",
        name: "Range",
    },
    {
        label: "Equal to",
        name: "EqualTo",
    },
    {
        label: "Not Equal to",
        name: "NotEqualTo",
    },
    {
        label: "Greater than",
        name: "GreaterThan",
    },
    {
        label: "Greater than or equal to",
        name: "GreaterThanOrEqualTo",
    },
    {
        label: "Less than",
        name: "LessThan",
    },
    {
        label: "Less than or equal to",
        name: "LessThanOrEqualTo",
    },
    {
        label: "Is Null",
        name: "IsNull",
    },
    {
        label: "Is Not Null",
        name: "IsNotNull",
    },
    {
        label: "In List",
        name: "InList",
        aggregationFuncs: ["None"],
    },
    {
        label: "Not In List",
        name: "NotInList",
        aggregationFuncs: ["None"],
    },
];
exports.operations = operations;
