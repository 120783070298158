import {cx} from "emotion";
import {Button} from "../../../../../common/form/buttons/button/button";
import {cs} from "@common/react/chain-services";
import {keyed} from "@common/react/keyed";
import {SuspendUpdate} from "@common/react/suspend-update";
import {tooltipService3} from "../../common/tooltip3/tooltip-service-3";
import "./ds-layout.scss";

export const DsLayout = ({leftHeader, rightFn, rightPanel, rightButtons, content, className}) =>
    cs(() => (
        <div className={`ds-layout-88o ${className}`}>
            <div className="header">
                <div className="left">
                    <div className="mode">
                        <div className="text">{leftHeader.type}</div>
                        <div className="label">{leftHeader.label}</div>
                    </div>
                </div>

                <div className="right">
                    {rightFn}
                    <div className="buttons">
                        {rightButtons.map((button, i) =>
                            cs(
                                keyed(i),
                                tooltipService3({
                                    direction: "below",
                                    tooltipContentStyle: {
                                        width: 150,
                                    },
                                }),
                                ({tooltip}) => (
                                    <Button
                                        {...(button.disabled && button.tooltip ? tooltip(() => button.tooltip) : {})}
                                        className={cx({
                                            disabled: button.disabled,
                                        })}
                                        btnType={button.btnType}
                                        onClick={() => !button.disabled && button.onClick()}
                                        iconRight={button.iconRight}
                                    >
                                        {button.label}
                                    </Button>
                                )
                            )
                        )}
                    </div>
                </div>
            </div>

            <div className="main">
                {content}

                {rightPanel && (
                    <div
                        className={cx("right-panel", {
                            active: rightPanel.content,
                        })}
                    >
                        {cs(
                            ({}, next) =>
                                SuspendUpdate({
                                    active: !rightPanel.content,
                                    delay: 500,
                                    next,
                                }),
                            () => rightPanel.content
                        )}
                    </div>
                )}
            </div>
        </div>
    ));
