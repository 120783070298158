exports.verticalBarTemplate = {
    $type: "VerticalBarChartTile",
    style: {
        $type: "VerticalBarChartTileStyle",
        displayType: "Classic",
        xAxis: {
            $type: "BarChartDimensionAxisStyle",
            labelShown: false,
            // label: use field name
            // gridStepType: "Count",
            gridStepLabelsShown: true,
            gridStepLabelFontSize: "Small",
            gridStepLabelOrientation: "Auto",
            lineShown: true,
            gridLinesShown: false,
            maxValuesInView: "Auto",
        },
        dataLabels: {
            $type: "DataLabelsTileStyle",
            show: false,
            position: "OutsideAbove",
            fontSize: null,
            allowOverlap: false,
        },
        legendStyle: {
            legendShown: false,
            legendPosition: "Auto",
            legendTitleShown: false,
            legendTitle: "Legend",
        },
        title: {
            show: true,
            titleLocation: "Above",
        },
    },
};
