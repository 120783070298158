import * as React from "react";
import {cs} from "@common/react/chain-services";
import {RenameFolderDialog} from "./rename-folder/rename-folder-dialog";
import {replaceFind} from "@common/utils/collections";
import {MoveFolderDialog} from "./move-folder/move-folder-dialog";
import {chain} from "@common/utils/fs";
import {PopupMenu} from "../../../../common/popup-menu/popup-menu";
import {consumeContext} from "@common/react/context";
import {DeleteFolderDialog} from "./delete-folder/delete-folder-dialog";

export const FolderCM = ({apiType, folders, next, updateEvents}) =>
    cs(
        consumeContext("apis"),
        [
            "renameFolderDialog",
            (_, next) =>
                RenameFolderDialog({
                    folders: folders.value,
                    apiType,
                    onDone: (newFolder) => folders.onChange(replaceFind(folders.value, newFolder, (f) => f.id === newFolder.id)),
                    next,
                }),
        ],
        [
            "moveFolderDialog",
            (_, next) =>
                MoveFolderDialog({
                    apiType,
                    folders: folders.value,
                    onDone: ({newFolder, movedFolder}) =>
                        folders.onChange(
                            chain(
                                folders.value,
                                (_) => (newFolder ? [..._, newFolder] : _),
                                (_) => replaceFind(_, movedFolder, (f) => f.id === movedFolder.id)
                            )
                        ),
                    next,
                }),
        ],
        [
            "deleteFolder",
            (_, next) =>
                DeleteFolderDialog({
                    apiType,
                    onDone: (newFolders) => {
                        folders.onChange(newFolders);
                        updateEvents?.emit("reload");
                    },
                    next,
                }),
        ],
        [
            "folderCM",
            ({renameFolderDialog, moveFolderDialog, deleteFolder}) =>
                PopupMenu({
                    getCommands: (folder) => [
                        {
                            label: "Rename",
                            onClick: () => renameFolderDialog.show({folder}),
                        },
                        {
                            label: "Move to",
                            onClick: () => moveFolderDialog.show({folder}),
                        },
                        {
                            label: "Delete",
                            className: "text-danger",
                            onClick: () => deleteFolder.show({folder}),
                        },
                    ],
                    next,
                }),
        ]
    );
