import "./other-collection-fields.scss";

import * as React from "react";
import {cs} from "@common/react/chain-services";
import {ffToDropdown} from "../../../../../../../../../common/form/ff-to-dropdown";
import {scope} from "@common/react/scope";
import {TextInput} from "../../../../../../../../../common/form/text-input/text-input";
import {ffToTextInput} from "../../../../../../../../../common/form/ff-to-text-input";
import {SelectFolderService} from "../../../folder-cm/select-folder-service/select-folder-service";
import {consumeContext} from "@common/react/context";
import {SearchableSelect} from "@common/ui-components/searchable-select/searchable-select";
import {isDataOrSparkCollection} from "../../../../../../getting-started/build-data/create-dashboards/create-collection-with-env-dialog";
import {Button} from "../../../../../../../../../common/form/buttons/button/button";

export const OtherCollectionFields = ({models, folders, collection, form, next, themes}) =>
    cs(
        consumeContext("apis"),

        // ["models", ({apis}, next) => Load({
        //     fetch: () => apis.model.getModels(),
        //     next,
        // })],

        [
            "selectFolderService",
            (_, next) =>
                SelectFolderService({
                    apiType: "collection",
                    state: scope(collection, ["folderID"]),
                    label: "Select a folder",
                    folders,
                    next,
                }),
        ],

        ({selectFolderService}) =>
            next({
                main: (
                    <div className="other-collection-fields-3gz">
                        {TextInput({
                            label: "Collection Name",
                            autoFocus: true,
                            ...ffToTextInput(form.field(["name"])),
                        })}

                        {selectFolderService.render()}

                        {SearchableSelect({
                            label: "Select the data model to build from",
                            list: models.value?.filter((m) => m.numTables == null || m.numTables > 0),
                            valueToLabel: (model) => model.name,
                            ...ffToDropdown(form.field(["modelID"]), ["id"]),
                        })}

                        {isDataOrSparkCollection(collection.value) &&
                            SearchableSelect({
                                label: "Select a theme",
                                list: themes.filter((t) => t.publishedOnUtc),
                                valueToLabel: (theme) => theme.name,
                                ...ffToDropdown(form.field(["themeID"]), ["id"]),
                            })}
                    </div>
                ),
                button: (
                    <Button
                        disabled={!selectFolderService.valid || !form.looksValid}
                        onClick={async () => {
                            if (selectFolderService.selectNewFolder) {
                                try {
                                    const folder = await selectFolderService.selectNewFolder();
                                    return folder?.id && form.submit(folder.id);
                                } catch (error) {
                                    //return console.log(error);
                                }
                            }

                            return form.submit();
                        }}
                    >
                        Create
                    </Button>
                ),
            })
    );
