import {Button} from "@common/form/buttons/button/button";
import {YesNoToggle} from "@common/form/toggles/yes-no-toggle";
import {cs} from "@common/react/chain-services";
import {scope} from "@common/react/scope";
import {UseState} from "@common/react/use-state";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {stateToSelect} from "@common/ui-components/form/state-to-select";
import {SearchInputBar} from "@common/ui-components/search-input-bar/search-input-bar";
import {sort} from "@common/utils/collections";
import {chain} from "@common/utils/fs";
import {isBlank, isMatchText} from "@common/utils/strings";
import {cx} from "emotion";
import {rFieldTypeIcon} from "../../../../common/field-type-icon/get-field-type-icon";
import {InteractionTable} from "../../sync/common/tables/interaction-table";
import {SyncSettingStatus} from "../list/sync-settings-status";
import {SyncColumnAlertIcon} from "../unstructured-interaction/sync-column-alert-icon";
import {SelectPrimaryKeyModal} from "./select-primary-key-modal";
import "./sync-settings-interaction.scss";

export const SyncSettingsInteraction = ({table: remoteTable, interactions, dataSource, oriTable}) =>
    cs(
        ["keyword", (_, next) => UseState({initValue: "", next})],
        ["table", (_, next) => UseState({initValue: remoteTable.value, next})],
        ["selectPrimaryKeyModal", (_, next) => SelectPrimaryKeyModal({next})],
        ({keyword, table, selectPrimaryKeyModal}) => {
            const hasError = dataSource.value.errors?.map((x) => x.dataSourceTableID).indexOf(table.value.id) > -1;
            const columns = [...table.value.columns, ...table.value.disabledColumns];
            const sortedColumns = chain(
                columns,
                (_) => [
                    ..._.filter((c) => c.primaryKey),
                    ...sort(
                        _.filter((c) => !c.primaryKey),
                        (c) => c.name
                    ),
                ],
                (_) => _.filter((c) => isMatchText(c.name, keyword.value))
            );

            const needCheckPrimaryKey = dataSource?.value?.structured && dataSource?.value?.type !== "File";
            const hasPrimaryKey = !!sortedColumns.find((c) => c.primaryKey);

            const openSelectPKModal = async () => {
                const selectedColumn = await selectPrimaryKeyModal.show({
                    columns: sortedColumns,
                });

                const isSelectedColumn = (c) => (selectedColumn?.id ? c.id === selectedColumn?.id : c.name === selectedColumn?.name);

                if (selectedColumn) {
                    table.change((oldTable) => {
                        const filteredColumns = oldTable.columns.filter((c) => !isSelectedColumn(c));

                        return {
                            ...oldTable,
                            disabledColumns: oldTable.disabledColumns.filter((c) => !isSelectedColumn(c)),
                            columns: filteredColumns.concat({
                                ...selectedColumn,
                                primaryKey: true,
                                primaryKeyManual: true,
                            }),
                        };
                    });
                }
            };

            return (
                <div className="sync-settings-interaction-88o">
                    <div className="header">
                        <div className="left-side">
                            {cs(
                                [
                                    "syncType",
                                    (_, next) => {
                                        const getSyncType = () => {
                                            // add constant status
                                            if (hasError) return "error";
                                            if (table.paused) return "paused";
                                            return "sync";
                                        };

                                        return next(getSyncType());
                                    },
                                ],
                                ({syncType}) => SyncSettingStatus({type: syncType, onlyIcon: true})
                            )}
                            <div className="label">{table.value.name}</div>
                        </div>

                        <span className="material-icons-outlined close-icon" onClick={() => interactions.onChange({selectedTable: null})}>
                            close
                        </span>
                    </div>

                    <div className="content">
                        <div className="header">
                            <div className="label">
                                Columns ({!isBlank(keyword.value) ? `${sortedColumns.length} OF ` : ""}
                                {columns.length}){" "}
                                {needCheckPrimaryKey && !hasPrimaryKey && (
                                    <a className="select-pk-btn" onClick={() => openSelectPKModal()}>
                                        <PrimaryKeyIconSVG /> Select a primary key
                                    </a>
                                )}
                            </div>

                            {SearchInputBar({state: keyword})}
                        </div>

                        {cs(
                            (_, next) => next(),
                            () => {
                                const disabledColumn = (row) => table.value.disabledColumns.findIndex((c) => c.name === row.name) > -1;

                                const errorColumns = (dataSource.value.errors || [])
                                    .filter((e) => e.dataSourceTableID == remoteTable.value.id)
                                    .map((error) => ({
                                        name: error.dataSourceColumnName,
                                        deleted_in_remote_column: true,
                                    }));

                                return InteractionTable({
                                    list: sortedColumns.concat(errorColumns),
                                    rowClassName: (row) => (row.deleted_in_remote_column ? "error" : disabledColumn(row) ? "disabled" : ""),
                                    columns: [
                                        {
                                            label: "Name",
                                            className: "column-name",
                                            format: (row) => (
                                                <div className="column-name-cell">
                                                    {row.primaryKey && rFieldTypeIcon(`IDUUID`)} {row.name}
                                                    {row.primaryKey && (
                                                        <div className="tooltip">
                                                            {row.primaryKeyManual ? `Primary Key set manually user in Verb` : `Primary Key set in data source`}
                                                        </div>
                                                    )}
                                                </div>
                                            ),
                                            sortValue: (row) => row.name,
                                        },
                                        {
                                            label: "Type",
                                            className: "interaction-row-types",
                                            format: (row) => {
                                                if (row.deleted_in_remote_column) return null;

                                                const isDisabledColumn = disabledColumn(row);

                                                const config =
                                                    {
                                                        Text: {
                                                            list: ["NonPII", "PII", "None"],
                                                            valueToLabel: (v) => (
                                                                <>
                                                                    {rFieldTypeIcon(`Text`)} Text ({v === "None" ? "Unqualified" : v === "NonPII" ? "Non-PII" : v})
                                                                </>
                                                            ),
                                                        },
                                                        DateTime: {
                                                            list: ["LocalTime", "UTC", "None"],
                                                            valueToLabel: (v) => (
                                                                <>
                                                                    {rFieldTypeIcon(`DateTime`)} DateTime ({v === "None" ? "Unqualified" : v === "LocalTime" ? "Local Time" : v})
                                                                </>
                                                            ),
                                                        },
                                                        DateTimeOffset: {
                                                            list: ["LocalTime", "UTC", "None"],
                                                            valueToLabel: (v) => (
                                                                <>
                                                                    {rFieldTypeIcon(`DateTimeOffset`)} DateTimeOffset (
                                                                    {v === "None" ? "Unqualified" : v === "LocalTime" ? "Local Time" : v})
                                                                </>
                                                            ),
                                                        },
                                                    }[row.dataType] || undefined;

                                                if (config && !isDisabledColumn) {
                                                    let columnIndex = table.value.columns.findIndex((c) => c.name === row.name);
                                                    let disabledColumnIndex = table.value.disabledColumns?.findIndex((c) => c.name === row.name);

                                                    return DropdownSelect({
                                                        ...config,
                                                        disabled: isDisabledColumn,
                                                        label: null,
                                                        errorMessage: `This field is required`,
                                                        hasError: isBlank(row.dataTypeProperties),
                                                        ...stateToSelect(
                                                            scope(table, [
                                                                isDisabledColumn ? "disabledColumns" : "columns",
                                                                isDisabledColumn ? disabledColumnIndex : columnIndex,
                                                                "dataTypeProperties",
                                                            ])
                                                        ),
                                                    });
                                                } else {
                                                    return (
                                                        <span className={cx("value", {"id-uuid": row.dataType === "IDUUID"})}>
                                                            {config ? (
                                                                config.valueToLabel(row.dataTypeProperties)
                                                            ) : (
                                                                <>
                                                                    {rFieldTypeIcon(row.dataType)} {row.dataType}
                                                                </>
                                                            )}
                                                        </span>
                                                    );
                                                }
                                            },
                                            sortValue: (row) => -row.dataType,
                                        },
                                        {
                                            labelF: () => (
                                                <>
                                                    Sync &nbsp;&nbsp;
                                                    <Button
                                                        btnType="border"
                                                        size="tiny"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();

                                                            return table.change((oldTable) => ({
                                                                ...oldTable,
                                                                columns: [...oldTable.columns, ...oldTable.disabledColumns],
                                                                disabledColumns: [],
                                                            }));
                                                        }}
                                                    >
                                                        ALL
                                                    </Button>{" "}
                                                    &nbsp;&nbsp;
                                                    <Button
                                                        btnType="border"
                                                        size="tiny"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();

                                                            return table.change((oldTable) => ({
                                                                ...oldTable,
                                                                disabledColumns: [...oldTable.columns.filter((c) => !c.primaryKey), ...oldTable.disabledColumns],
                                                                columns: oldTable.columns.filter((c) => c.primaryKey),
                                                            }));
                                                        }}
                                                    >
                                                        NONE
                                                    </Button>
                                                </>
                                            ),
                                            headClassName: "column-sync-toggle-head",
                                            format: (row) => {
                                                return (
                                                    <div className="toggle-sync">
                                                        {!row.deleted_in_remote_column &&
                                                            YesNoToggle({
                                                                colors: {right: "#294661"},
                                                                tooltipInfo: row.primaryKey ? `Primary Key column must be set to sync.` : null,
                                                                disabled: row.primaryKey,
                                                                state: {
                                                                    value: !disabledColumn(row),
                                                                    onChange: (value) =>
                                                                        table.change((oldTable) => ({
                                                                            ...oldTable,
                                                                            columns: value ? [...oldTable.columns, row] : oldTable.columns.filter((c) => c.name !== row.name),
                                                                            disabledColumns: !value
                                                                                ? [...oldTable.disabledColumns, row]
                                                                                : oldTable.disabledColumns.filter((c) => c.name !== row.name),
                                                                        })),
                                                                },
                                                            })}

                                                        {dataSource.value.id &&
                                                            SyncColumnAlertIcon({
                                                                column: row,
                                                                oriColumn: [...(oriTable.value?.columns ?? []), ...(oriTable.value?.disabledColumns ?? [])].find(
                                                                    (c) => c.name == row.name
                                                                ),
                                                                table: table.value,
                                                                oriTable: oriTable.value,
                                                            })}
                                                    </div>
                                                );
                                            },
                                            sortValue: (row) => !disabledColumn(row),
                                        },
                                    ],
                                });
                            }
                        )}
                    </div>

                    <div className="footer">
                        <Button
                            onClick={() => {
                                if (table.value.columns.filter((c) => c.primaryKey).length === 0 && table.value.columns.length === 0) {
                                    dataSource.change((ds) => ({
                                        ...ds,
                                        tables: ds.tables.filter((t) => t.name !== table.value.name),
                                        disabledTables: [...(ds.disabledTables || []), table.value],
                                    }));
                                } else {
                                    remoteTable.onChange(table.value);
                                }
                                interactions.onChange({selectedTable: null});
                            }}
                        >
                            Done
                        </Button>
                    </div>
                </div>
            );
        }
    );
const PrimaryKeyIconSVG = ({className}) => {
    return (
        <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.313 11.566L18.253 3.626L20.374 5.747L18.96 7.161L21.081 9.282L17.546 12.818L15.425 10.697L12.435 13.687C12.9855 14.7463 13.1391 15.9676 12.8679 17.1302C12.5966 18.2928 11.9185 19.3201 10.9561 20.0264C9.99363 20.7327 8.81029 21.0715 7.61985 20.9816C6.42942 20.8916 5.31041 20.3789 4.46499 19.536C3.61914 18.6915 3.1038 17.572 3.01235 16.3802C2.92089 15.1885 3.25938 14.0034 3.96647 13.0397C4.67356 12.0761 5.70246 11.3976 6.86673 11.1272C8.031 10.8569 9.25361 11.0125 10.313 11.566ZM9.41399 17.414C9.60501 17.2295 9.75737 17.0088 9.86219 16.7648C9.96701 16.5208 10.0222 16.2584 10.0245 15.9928C10.0268 15.7272 9.97619 15.4639 9.87563 15.2181C9.77507 14.9723 9.62656 14.749 9.43878 14.5612C9.25099 14.3734 9.02769 14.2249 8.7819 14.1244C8.5361 14.0238 8.27274 13.9732 8.00718 13.9755C7.74163 13.9778 7.47919 14.033 7.23518 14.1378C6.99117 14.2426 6.77048 14.395 6.58599 14.586C6.22167 14.9632 6.02008 15.4684 6.02464 15.9928C6.0292 16.5172 6.23953 17.0188 6.61035 17.3896C6.98117 17.7605 7.48279 17.9708 8.00718 17.9753C8.53158 17.9799 9.03678 17.7783 9.41399 17.414Z"
                fill="#919EB0"
            />
        </svg>
    );
};
