import "./tile-actions.scss";

import React from "react";

import {keyed} from "@common/react/keyed";
import {scope} from "@common/react/scope";
import {Watch} from "@common/react/watch";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {consumeContext} from "@common/react/context";
import {spc} from "@common/react/state-path-change";

import {Expandable} from "../../../../../../../common/expandable/expandable";
import {Button} from "@common/form/buttons/button/button";
import {LoadingIndicator} from "@common/ui-components/loading-indicator/loading-indicator";
import {isValidTileAction} from "@common/ui-components/charts/common/action-menu/action-menu-utils";
import {last} from "@common/utils/collections";
import {FixedPopupMenu} from "../../../../../../../common/fixed-popup-menu/fixed-popup-menu";
import {StaticTooltipService} from "../../../../../../../common/tooltip3/static-tooltip-service";
import {ChildModalTile} from "../../../../child-tile/child-modal-tile";
import {ChildSameTile} from "../../../../child-tile/child-same-tile";
import {filterIcons} from "../../../../../../edit/tabs/filters/left-panel/filters-in-collection/filters-in-collection";
import {FieldItem} from "../../../../../../edit/common/field-item/field-item";

import {EditTileAction, getPreSelectFieldIds} from "./add/edit-tile-action";
import {ManageTile} from "./add/manage-tile/manage-tile";
import {getActionFields} from "./add/action-fields/action-field-utils";
import {ProvideDefaultTimezone} from "../../../../../../common/provide-default-timezone";

export const TileActions = ({tile, remoteTile, size, next}) =>
    cs(
        consumeContext("getFieldInfo"),
        consumeContext("collection"),
        ["tileActionId", (_, next) => UseState({next})],
        ["addingTileAction", (_, next) => UseState({next})],
        ["addingTile", (_, next) => UseState({initValue: false, next})],
        [
            "panelTile",
            ({tileActionId, addingTile}, next) => {
                if (tileActionId.value && addingTile.value) {
                    const tileAction = tile.value.tileActions.find((ta) => ta?.id === tileActionId.value);
                    return tileAction?.openType == "Modal" ? ChildModalTile({next}) : ChildSameTile({next});
                }
                return next({});
            },
        ],

        ({collection}, next) =>
            ProvideDefaultTimezone({
                next,
                timezone: null,
                defaultTimezoneToUtc: !collection.value.showTimezoneConversion ? true : collection.value.defaultTimezoneToUtc,
            }),

        [
            "manageChildTile",
            ({collection, addingTileAction, addingTile, tileActionId, panelTile}, next) => {
                if (addingTileAction.value && addingTile.value) {
                    const ti = tile.value.tileActions.findIndex((ta) => ta?.id === tileActionId.value);
                    const tileAction = scope(tile, ["tileActions", ti]);

                    return ManageTile({
                        next,
                        tile,
                        tileAction,
                        panelTile,
                        size,
                        onCancel: () => addingTile.onChange(false),
                    });
                }

                return next();
            },
        ],

        ({addingTile, tileActionId, panelTile, addingTileAction, manageChildTile, collection}) =>
            next({
                render: () =>
                    Expandable({
                        initExpand: false,
                        label: "Tile Actions",
                        render: () => (
                            <div className="tile-actions-86o">
                                {Watch({
                                    value: tile.value.tileActions.length > 0 && last(tile.value.tileActions)?.id,
                                    onChanged: () => tileActionId.onChange(last(tile.value.tileActions)?.id),
                                })}

                                <div className="tile-actions-list">
                                    {tile.value.tileActions.map((tileAction, i) =>
                                        cs(keyed(i), () =>
                                            cs(
                                                [
                                                    "tileActionCM",
                                                    (_, next) =>
                                                        FixedPopupMenu({
                                                            getCommands: () => [
                                                                {
                                                                    label: "Edit",
                                                                    onClick: () => {
                                                                        addingTileAction.onChange(true);
                                                                        tileActionId.onChange(tileAction.id);
                                                                    },
                                                                },
                                                                {
                                                                    label: "Delete Tile Action",
                                                                    className: "delete-action",
                                                                    onClick: () => {
                                                                        spc(tile, ["tileActions"], (oldState) =>
                                                                            oldState?.filter((o) => o.id !== tileAction.id)
                                                                        );
                                                                    },
                                                                },
                                                            ],
                                                            next,
                                                        }),
                                                ],
                                                [
                                                    "staticTooltip",
                                                    (_, next) =>
                                                        StaticTooltipService({
                                                            direction: "right",
                                                            info: "Tile Action is incomplete and will not display to users.",
                                                            tooltipBoxWidth: 200,
                                                            topOffset: -10,
                                                            next,
                                                        }),
                                                ],
                                                ({tileActionCM, staticTooltip}) => (
                                                    <FieldItem
                                                        leftIcon={<img src={filterIcons.DateFilter} width="26px" alt="" />}
                                                        rightIcon={<i className="far fa-arrow-right" aria-hidden="true" />}
                                                        label="Date Filter Date Filter Date Filter Date Filter Date Filter Date Filter "
                                                    >
                                                        {!isValidTileAction(tileAction, {
                                                            tile,
                                                            collectionFilters: collection.value.filters,
                                                        }) &&
                                                            staticTooltip.renderIcon({
                                                                icon: <span className="material-icons-outlined">warning</span>,
                                                                className: "warning-icon",
                                                            })}
                                                        <div className="text">
                                                            <div className="title">{tileAction?.name || `[ACTION]`}</div>
                                                        </div>
                                                        {tileActionCM.render()}
                                                    </FieldItem>
                                                )
                                            )
                                        )
                                    )}
                                </div>

                                <div className="action">
                                    <Button
                                        btnType="secondary"
                                        onClick={() => {
                                            addingTileAction.onChange(true);
                                            spc(tile, ["tileActions"], (tileActions) => {
                                                const fields = getActionFields(tile);

                                                return [
                                                    ...(tileActions || []),
                                                    {
                                                        $type:
                                                            tile.value.$type == "TableKPITile"
                                                                ? "ControlCollectionFiltersAction"
                                                                : "OpenNewTileAction",
                                                        menuTitle: null,
                                                        enabledTileFieldIDs: getPreSelectFieldIds(
                                                            tile.value.$type == "TableKPITile"
                                                                ? "ControlCollectionFiltersAction"
                                                                : "OpenNewTileAction",
                                                            fields,
                                                            tile
                                                        ),
                                                        name:
                                                            tile.value.$type == "TableKPITile"
                                                                ? "Control Collection Filters"
                                                                : "Open New Tile",
                                                        openType: "Modal",
                                                        menuTitleBefore: tile.value.$type == "TableKPITile" ? "Filter on" : "View",
                                                        menuTitleAfter: tile.value.$type == "TableKPITile" ? "" : "Details",
                                                    },
                                                ];
                                            });
                                        }}
                                    >
                                        Create an Action
                                    </Button>
                                </div>
                            </div>
                        ),
                    }),
                override: ({fieldType}) => {
                    if (addingTileAction.value) {
                        return cs(
                            (_, next) =>
                                !!tileActionId.value
                                    ? next()
                                    : LoadingIndicator({
                                          className: "tile-action-loading-53s",
                                      }),
                            () => {
                                const ti = tile.value.tileActions.findIndex((ta) => ta?.id === tileActionId.value);
                                const tileAction = scope(tile, ["tileActions", ti]);

                                if (addingTile.value) {
                                    return manageChildTile.override;
                                }

                                return cs(() => {
                                    const dismissTileAction = () => {
                                        addingTileAction.onChange(false);
                                        tileActionId.onChange(null);
                                    };

                                    return EditTileAction({
                                        tile,
                                        tileAction,
                                        fieldType,
                                        onRemove: () => {
                                            dismissTileAction();
                                            spc(tile, ["tileActions"], (oldState) => oldState.filter((o) => o.id !== tileAction.value.id));
                                        },
                                        onCancel: dismissTileAction,
                                        onManageChildTile: () => addingTile.onChange(true),
                                    });
                                });
                            }
                        );
                    }

                    return null;
                },
                overrideAll: (() => {
                    if (addingTile.value && tileActionId.value) {
                        if (manageChildTile.overrideAll) {
                            return manageChildTile.overrideAll;
                        }

                        return {
                            overlay: panelTile.overlay,
                            main: ({size}) =>
                                panelTile?.render({
                                    isEditTile: true,
                                    size,
                                    tileActionId: tileActionId.value,
                                    tile,
                                    remoteTile,
                                }),
                        };
                    }

                    return null;
                })(),
            })
    );
