/**
 *  adjust margin so that the data labels fit inside and not be cut off
 * */
const adjustGaugeMargin = ({chart}) => {
    let marginAdjusted;

    const chartRect = chart.chartBackground.element.getBoundingClientRect();

    chart.yAxis[0].plotLinesAndBands.forEach((p) => {
        if (!p.label) return;

        const labelRect = p.label.element.getBoundingClientRect();

        if (labelRect.y < chartRect.y && (!marginAdjusted?.marginTop || marginAdjusted.marginTop < labelRect.height)) {
            marginAdjusted = {
                ...(marginAdjusted || {}),
                marginTop: labelRect.height,
            };
        }

        if (labelRect.x < chartRect.x && (!marginAdjusted?.marginLeft || marginAdjusted.marginLeft < labelRect.width)) {
            marginAdjusted = {
                ...(marginAdjusted || {}),
                marginLeft: labelRect.width,
            };
        }

        if (labelRect.x + labelRect.width > chartRect.x + chartRect.width && (!marginAdjusted?.marginRight || marginAdjusted.marginRight < labelRect.width)) {
            marginAdjusted = {
                ...(marginAdjusted || {}),
                marginRight: labelRect.width,
            };
        }
    });

    marginAdjusted && chart.update({chart: marginAdjusted});
};
exports.adjustGaugeMargin = adjustGaugeMargin;
