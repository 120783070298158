import {cs} from "@common/react/chain-services";
import {DialogService} from "../../../common/dialog/dialog-service";
import {consumeContext} from "@common/react/context";
import {Load2} from "@common/react/load2";
import {UseState} from "@common/react/use-state";
import {Form2} from "@common/react/cs-form/form2";
import {required} from "@common/react/cs-form/validators/required";
import {SelectFolderService} from "../collections/folder-cm/select-folder-service/select-folder-service";
import {scope} from "@common/react/scope";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";
import {Watch} from "@common/react/watch";
import {TextInput} from "../../../../../../common/form/text-input/text-input";
import {ffToTextInput} from "../../../../../../common/form/ff-to-text-input";
import {Button} from "../../../../../../common/form/buttons/button/button";
import React from "react";
import {initTheme} from "../../../theme/edit/common/theme-init";

export const CreateThemeDialog = ({next: rootNext}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    render: ({resolve}) => ({
                        title: "Create Theme",
                        width: 540,
                        content: next({
                            resolve,
                        }),
                    }),
                    next: rootNext,
                }),
        ],
        ({modal}) =>
            cs(
                consumeContext("apis"),
                consumeContext("routing"),
                ["theme", (_, next) => UseState({next, initValue: initTheme({name: ""})})],
                ["saving", (_, next) => UseState({next})],
                ["error", (_, next) => UseState({next})],
                [
                    "folders",
                    ({apis, routing}, next) =>
                        Load2({
                            _key: routing.params?.envId,
                            fetch: () => apis.theme.getFolders(),
                            next,
                        }),
                ],
                [
                    "form",
                    ({theme, saving, apis}, next) =>
                        Form2({
                            fields: {
                                name: {
                                    transforms: ["trim"],
                                    validators: [required],
                                    debounce: true,
                                    asyncValidators: [
                                        {
                                            validate: (name) => apis.collection.checkThemeName(name),
                                            getMessage: () => `Existed`,
                                        },
                                    ],
                                },
                            },
                            data: theme,
                            onSubmit: async (folderID) => {
                                saving.onChange(true);
                                const resp = await apis.collection.upsertTheme(theme.value);
                                await apis.theme.changeFolder(folderID || theme.value.folderID, resp.id);
                                modal.resolve(resp.id);
                            },
                            next,
                        }),
                ],
                [
                    "selectFolderService",
                    ({theme, folders, apis}, next) =>
                        folders.value
                            ? SelectFolderService({
                                  state: scope(theme, ["folderID"]),
                                  label: "Select a folder",
                                  folders: folders.value,
                                  apiType: "theme",
                                  next,
                              })
                            : next(),
                ],
                ({theme, selectFolderService, error, form, saving}) => (
                    <div className="remove-block-dialog-a32">
                        <VerbDialogBodyScrollbar>
                            {Watch({
                                value: theme.value,
                                onChanged: async () => {
                                    error.onChange(null);
                                },
                            })}

                            <div className="messages">
                                <div className="error-message">{error.value}</div>

                                <div className="message">
                                    {TextInput({
                                        label: "Theme Name",
                                        ...ffToTextInput(form.field(["name"])),
                                    })}
                                </div>

                                <div className="message">{selectFolderService?.render()}</div>
                            </div>
                        </VerbDialogBodyScrollbar>

                        <div className="buttons">
                            <Button btnType="secondary" onClick={() => modal.resolve()}>
                                Cancel
                            </Button>
                            <Button
                                disabled={!selectFolderService?.valid || !form.looksValid || saving.value}
                                onClick={async () => {
                                    if (selectFolderService.selectNewFolder) {
                                        try {
                                            const folder = await selectFolderService.selectNewFolder();
                                            return folder?.id && form.submit(folder.id);
                                        } catch (error) {
                                            //return console.log(error);
                                        }
                                    }
                                    form.submit();
                                }}
                            >
                                Create Theme
                            </Button>
                        </div>
                    </div>
                )
            )
    );
