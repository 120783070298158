const {getPath} = require("../../../utils/arr-path");
const {parseValueNodes} = require("../../../utils/path-pattern");

const equalsPath = (path) => {
    let p = parseValueNodes(path);
    return {
        name: "equals-path",
        validate: (v, {data}) => getPath(data, p) === v,
        path,
    };
};
exports.equalsPath = equalsPath;
