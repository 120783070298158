import React from "react";
import {cs} from "../../../../../react/chain-services";
import {UseState} from "../../../../../react/use-state";

export const SortColumn = ({next}) => cs(
    ["sort", (_, next) => UseState({
        initValue: null,
        next,
    })],
    ({sort}) => next({
        sortColumn: (cIndex, cDirection) => {
            const {direction, columnIndex} = sort.value || {};
            if (cDirection) {
                if (columnIndex == cIndex && cDirection == direction) {
                    sort.onChange(null);
                } else {
                    sort.onChange({
                        columnIndex: cIndex,
                        direction: cDirection
                    });
                }
            } else {
                if (columnIndex === undefined) {
                    sort.onChange({
                        columnIndex: cIndex,
                        direction: "Desc"
                    });

                    return;
                }

                if (columnIndex == cIndex) {

                    if (direction == "Desc") {
                        sort.onChange({
                            columnIndex: cIndex,
                            direction: "Asc"
                        });

                        return;
                    }
                    sort.onChange(null);
                    return;
                }

                sort.onChange({
                    columnIndex: cIndex,
                    direction: "Desc"
                })
            }

        },
        tableSortOverride: sort.value
    })
);
