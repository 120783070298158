import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import * as React from "react";
import {cs} from "../../../react/chain-services";
import {UseState} from "../../../react/use-state";
import {ObserveDomSize0} from "../../../react/observe-dom-size-0";
import "./render-map-chart.scss";
import {MapLegend} from "./legend/map-legend";
import {arrMapToO} from "../../../utils/objects";
import {getMapLegendRoom} from "./legend/get-map-legend-room";
import {MapZoomControls} from "./zoom-controls/map-zoom-controls";
import {Watch} from "../../../react/watch";
import {ControlFilterWrapper} from "../common/control-filter/control-filter-wrapper";

export const RenderMapChart = ({tile, theme, size, formatters, options, chartRef}) =>
    cs(
        ["chartRect", (_, next) => UseState({next})],
        [
            "sizeRef",
            ({chartRect}, next) =>
                ObserveDomSize0({
                    startImmediately: true,
                    onChange: (containerSize, element) => {
                        const chart = chartRef.get()?.chart;
                        chartRect.onChange(element.getBoundingClientRect());
                        if (chart && chartRect.value) {
                            chart.reflow();
                        }
                    },
                    next,
                }),
        ],
        ["chartLoaded", ({}, next) => UseState({next})],
        ({sizeRef, chartLoaded, chartRect}) => {
            const legendRoom = getMapLegendRoom({
                legend: options.legend,
                series: options.series,
                size,
                chartHeight: chartRect.value?.height,
                chartWidth: chartRect.value?.width,
                isCompare: false,
                formatters,
                options,
                tile,
            });

            return (
                <div
                    className="render-map-chart-53d"
                    style={{
                        width: "100%",
                        height: "100%",

                        ...(legendRoom && {
                            position: "relative",
                            ...arrMapToO(["paddingTop", "paddingBottom", "paddingLeft", "paddingRight"], (k) => legendRoom[k] || 0),
                        }),
                    }}
                    ref={sizeRef.ref}
                >
                    <ControlFilterWrapper tile={tile} series={options.series} dimensionAxisType={options.xAxis?.[0] ? options.xAxis[0].type : options.xAxis?.type}>
                        {(series) => {
                            return (
                                <HighchartsReact
                                    {...{
                                        highcharts: Highcharts,
                                        constructorType: "mapChart",
                                        options: {
                                            ...options,
                                            series,
                                        },
                                        ref: chartRef.set,
                                        containerProps: {
                                            style: {
                                                width: "100%",
                                                height: "100%",
                                            },
                                        },
                                        callback: () => {
                                            setTimeout(() => {
                                                chartLoaded.onChange(true);
                                            });
                                        },
                                    }}
                                />
                            );
                        }}
                    </ControlFilterWrapper>

                    {chartLoaded.value &&
                        MapLegend({
                            tile,
                            theme,
                            size,
                            formatters,
                            options,
                            chartRef,
                            legendRoom,
                        })}

                    {chartLoaded.value &&
                        tile.style.showMapControls &&
                        MapZoomControls({
                            chartRef,
                            legendRoom,
                        })}

                    {/*when legend room changes, reflow the map to fit the available space*/}
                    {Watch({
                        value: JSON.stringify(legendRoom),
                        onChanged: () => {
                            const chart = chartRef.get()?.chart;
                            chart.reflow();
                        },
                        initRun: false,
                    })}
                </div>
            );
        }
    );
