import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {Invoke} from "@common/react/invoke";
import {scope} from "@common/react/scope";
import {UseState} from "@common/react/use-state";
import {noun} from "@common/utils/plural";
import {isBlank} from "@common/utils/strings";
import SpinnerIcon from "../../../../assets/icons/data-sources/SpinnerIcon";
import {ReviewChangesDialog} from "../../common/review-changes-dialog/review-changes-dialog";
import {dsSyncHelpers} from "../common/helpers";
import {DSLoadingDialog} from "../common/loading-dialog/ds-loading-dialog";
import {SyncSettingsInteraction} from "./interaction/sync-settings-interaction";
import {DsSyncSettingsLayout} from "./layout/ds-sync-settings-layout";
import {SyncSettingsSearchHeader} from "./list/searches/sync-settings-search-header";
import {SyncSettingsSearches} from "./list/searches/sync-settings-searches";
import {SyncSettingsList} from "./list/sync-settings-list";
import {SyncSettingsUnstructuredInteraction} from "./unstructured-interaction/sync-settings-unstructured-interaction";
import {SyncSettingsUnstructuredSearches} from "./unstructured-list/searches/sync-settings-unstructured-searches";
import {SyncSettingsUnstructuredList} from "./unstructured-list/sync-settings-unstructured-list";
import {isDevSonPham} from "../../../../../../tools/dev/is-dev-sonpham";
import datasources from "../data-sources.json";
import {getSyncLatestTables} from "../common/data-sources-helpers";

let staticDsValue = null;
export let _staticDsValue = () => ({
    get: () => staticDsValue,
    set: (v) => (staticDsValue = v),
});

export const EditSyncSettings = ({}) =>
    cs(
        consumeContext("routing"),
        consumeContext("apis"),
        consumeContext("toast"),

        ["loadingDialog", (_, next) => DSLoadingDialog({next})],
        [
            "dataSource",
            (_, next) => (isDevSonPham() ? UseState({initValue: datasources, next}) : UseState({initValue: _staticDsValue().get(), next})),
        ],
        [
            "onLoadDatasource",
            ({dataSource, loadingDialog, routing, apis}, next) =>
                next(async (reload) => {
                    const resp = await loadingDialog.show({
                        title: `${reload ? `Refresh` : `Enter`} Sync Settings`,
                        content: (
                            <>
                                {SpinnerIcon({})}
                                &nbsp;&nbsp;
                                <div>Connecting to your data source…</div>
                            </>
                        ),
                        onLoading: async () => {
                            const remoteDs = await apis.data.getDataSource(routing.params.dsId);

                            const latestTables =
                                // remoteDs.tables ??
                                await apis.data.getTablesColumns({
                                    dataSourceID: routing.params.dsId,
                                    data: null,
                                });

                            return getSyncLatestTables(latestTables)(remoteDs);
                        },
                    });

                    dataSource.onChange(resp);
                }),
        ],
        ({dataSource, onLoadDatasource}, next) => {
            return (
                <>
                    {!dataSource.value &&
                        Invoke({
                            fn: () => onLoadDatasource(),
                        })}

                    {next()}
                </>
            );
        },
        ({dataSource}, next) =>
            dataSource.value ? next() : DsSyncSettingsLayout({editing: {label: dataSource.value?.name}, rightFn: null}),

        ["editingDs", ({dataSource}, next) => UseState({initValue: dataSource.value, next})],
        ["interactions", (_, next) => UseState({initValue: {selectedTable: null}, next})],

        ["selectedTables", (_, next) => UseState({initValue: [], next})],
        ["searchState", (_, next) => UseState({initValue: {value: ""}, next})],

        ["loading", (_, next) => UseState({initValue: false, next})],

        ["showErrors", (_, next) => UseState({initValue: false, next})],

        [
            "controls",
            ({dataSource, editingDs, apis, loading, toast, routing, showErrors}, next) =>
                cs(["reviewChanges", (_, next) => ReviewChangesDialog({next})], ({reviewChanges}) =>
                    next({
                        onBack: () =>
                            routing.goto("edit-data-source", {
                                envId: routing.params.envId,
                                dsId: routing.params.dsId,
                                type: routing.params.type,
                            }),
                        hasChanged: () => dsSyncHelpers.hasChanged(dataSource.value, editingDs.value),
                        onSaveDone: async () => {
                            if (dsSyncHelpers.hasChanged(dataSource.value, editingDs.value)) {
                                if (!dsSyncHelpers.validTables(editingDs.value.tables.filter((t) => !t.isChildTable))) {
                                    showErrors.onChange(true);
                                    return;
                                }

                                const resp = await reviewChanges.show({
                                    oriDs: dataSource.value,
                                    updatedDs: editingDs.value,
                                });
                                if (resp) {
                                    if (resp === "reset") editingDs.onChange(dataSource.value);
                                    if (resp === "save") {
                                        try {
                                            loading.onChange(true);

                                            // const { pauseds, resumes } = dsSyncHelpers.getPausedTables(dataSource.value, editingDs.value)
                                            // if(pauseds.length > 0 ) await apis.data.pauseTables(dataSource.value.id, pauseds)
                                            // if(resumes.length > 0 ) await apis.data.resumeTables(dataSource.value.id, resumes)

                                            const result = await apis.data.upsertDataSource(editingDs.value);
                                            dataSource.onChange(result);

                                            toast.show("Sync Settings Saved");
                                            routing.goto("edit-data-source", {
                                                envId: routing.params.envId,
                                                dsId: routing.params.dsId,
                                            });
                                        } catch (e) {
                                            editingDs.onChange(dataSource.value);
                                            toast.show(e.message, {
                                                isError: true,
                                            });
                                        } finally {
                                            loading.onChange(false);
                                        }
                                    }
                                }
                            } else {
                                routing.goto("edit-data-source", {
                                    envId: routing.params.envId,
                                    dsId: routing.params.dsId,
                                    type: routing.params.type,
                                });
                            }
                        },
                    })
                ),
        ],

        [
            "settingsSearch",
            ({searchState, dataSource, editingDs}, next) =>
                cs(
                    [
                        "list",
                        (_, next) => {
                            const isUnstructuredDs = !dataSource.value.structured;
                            return next([
                                {
                                    icon: <span className="material-icons-outlined">sync_problem</span>,
                                    label: isUnstructuredDs ? "Errors in objects and properties" : "Errors in tables and columns",
                                    filter: (t) => (dataSource.value?.errors || []).map((e) => e.dataSourceTableID).indexOf(t.id) > -1,
                                },
                                ...(editingDs.value.tables.filter((t) => t.paused).length > 0
                                    ? [
                                          {
                                              icon: <span className="material-icons-outlined">pause</span>,
                                              label: isUnstructuredDs ? "Paused objects" : "Paused tables",
                                              filter: (t) =>
                                                  editingDs.value.disabledTables.map((t1) => t1.name).indexOf(t.name) === -1 && t.paused,
                                              // disabled: editingDs.value.tables.filter((t) => t.paused).length > 0,
                                              // disabledInfo: "No Paused Tables"
                                          },
                                      ]
                                    : []),
                                {
                                    icon: <span className="material-icons-outlined">not_interested</span>,
                                    label: isUnstructuredDs ? "Objects not syncing" : "Tables not syncing",
                                    filter: (t) => editingDs.value.disabledTables.map((t1) => t1.name).indexOf(t.name) > -1,
                                },
                            ]);
                        },
                    ],
                    ({list}) =>
                        SyncSettingsSearchHeader({
                            list,
                            state: searchState,
                            next,
                        })
                ),
        ],

        [
            "errorMessage",
            ({searchList, settingsSearch, editingDs}, next) => {
                const isUnstructuredDs = !editingDs.value.structured;
                const tables = editingDs.value?.tables.filter((t) => !t.isChildTable);
                const invalidTables = dsSyncHelpers.getInvalidTables(tables);

                const unit = noun(invalidTables.length, isUnstructuredDs ? "object" : "table");

                if (tables.length > 0 && invalidTables.length === 0) return next(null);

                return next(
                    <div className="error-message-88p">
                        <span className="material-icons-outlined">warning</span>
                        <div className="right-side">
                            {tables.length === 0 ? (
                                <>
                                    <div className="label">No Active {isUnstructuredDs ? `Objects` : `Tables`}</div>
                                    <div className="details">
                                        <div className="error">
                                            At least one {isUnstructuredDs ? `object` : `table`} must be active or paused to keep this data
                                            source connection.{" "}
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="label">
                                        {invalidTables.length} {unit} in error
                                    </div>
                                    <div className="details">
                                        <span className="error">
                                            Sync Method not set on {invalidTables.length} {unit}.
                                        </span>
                                        <span
                                            className="search"
                                            onClick={() => {
                                                // const item = searchList[0];
                                                settingsSearch.updateSearch({
                                                    id: 1,
                                                    value: `${isUnstructuredDs ? `Objects` : `Tables`} with Sync Method not set`,
                                                    filter: (t) => isBlank(t.syncMethod) || t.syncMethod === "None",
                                                });
                                            }}
                                        >
                                            Show ${isUnstructuredDs ? `Objects` : `Tables`} with Sync Method not set
                                        </span>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                );
            },
        ],
        ({
            dataSource,
            interactions,
            editingDs,
            searchState,
            selectedTables,
            routing,
            controls,
            settingsSearch,
            loading,
            errorMessage,
            showErrors,
            onLoadDatasource,
        }) => {
            const isUnstructuredDs = !dataSource.value.structured;
            const _syncSettings = isUnstructuredDs ? SyncSettingsUnstructuredList : SyncSettingsList;
            const _syncSearches = isUnstructuredDs ? SyncSettingsUnstructuredSearches : SyncSettingsSearches;

            const renderSyncView = (props) =>
                isBlank(searchState.value.value) ? (
                    <>{_syncSettings(props)}</>
                ) : (
                    _syncSearches({
                        ...props,
                        clearSearch: settingsSearch.clearSearch,
                    })
                );

            return (
                <>
                    {DsSyncSettingsLayout({
                        editing: {
                            label: dataSource.value?.name,
                        },
                        rightFn: settingsSearch.render(),
                        rightButtons: [
                            {
                                label: "Cancel",
                                btnType: "secondary",
                                disabled: loading.value,
                                onClick: controls.onBack,
                            },
                            {
                                label: "Save & Done",
                                disabled: loading.value || !controls.hasChanged(),
                                iconRight: loading.value && <i className="fad fa-spinner-third fa-spin" />,
                                btnType: "primary",
                                onClick: controls.onSaveDone,
                            },
                        ],
                        content: renderSyncView({
                            errorMessage,
                            hasErrors: showErrors.value && !dsSyncHelpers.validTables(editingDs.value.tables),
                            searchState,
                            oriDs: dataSource.value,
                            dataSource: {
                                ...editingDs,
                                errors: dataSource.value.errors,
                            },
                            interactions,
                            selectedTables,
                            onReload: () => onLoadDatasource(true),
                        }),
                        rightPanel: {
                            style: isUnstructuredDs ? {width: 800, right: -800} : {},
                            content: cs(
                                (_, next) => (interactions.value.selectedTable == null ? null : next()),
                                () => {
                                    const tIndex = editingDs.value.tables.findIndex(
                                        (t) => t.name === interactions.value.selectedTable.name
                                    );
                                    return isUnstructuredDs
                                        ? SyncSettingsUnstructuredInteraction({
                                              dataSource: editingDs,
                                              table: scope(editingDs, ["tables", tIndex]),
                                              oriTable: scope(dataSource, ["tables", tIndex]),
                                              interactions,
                                          })
                                        : SyncSettingsInteraction({
                                              dataSource: editingDs,
                                              table: scope(editingDs, ["tables", tIndex]),
                                              oriTable: scope(dataSource, ["tables", tIndex]),
                                              interactions,
                                          });
                                }
                            ),
                        },
                    })}
                </>
            );
        }
    );
