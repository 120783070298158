import {getTextDimension} from "../../single-kpi/font-auto-resizing";
import {getLegendAreaWidth, getLegendWidth, getTextWidth} from "../../render-chart/render-chart-helper";
import {useColorAxis} from "../map-helper";
import {findMaxE} from "../../../../utils/collections";

export const getMapLegendRoom = (props) => {
    if (
        !props.tile.style.legendStyle.legendShown ||
        props.options.matchIndex == null || // null matchIndex means blank map, so do not show legend
        props.series[0].isDummy // empty data
    ) {
        return;
    }

    if (useColorAxis(props.tile)) {
        return getColorAxisRoom(props);
    }

    return getMapGroupLegendRoom(props);
};

const getMapGroupLegendRoom = ({tile, options}) => {
    const {legendSize, legendPosition} = tile.style.legendStyle || {};
    const groups = options.series[0].groups;

    if (["Left", "Right"].includes(legendPosition)) {
        let legendWidth = getLegendWidth(groups, options.legend.itemStyle.fontSize, (g) => {
            return g.group;
        });

        const width = getLegendAreaWidth(legendWidth, legendSize);

        if (legendPosition === "Left") {
            return {paddingLeft: width, legendWidth: width};
        }
        return {paddingRight: width + 10, legendWidth: width};
    }

    if (legendPosition === "Top") {
        return {paddingTop: 26};
    }

    if (legendPosition === "Bottom") {
        return {paddingBottom: 26};
    }
};

const getColorAxisRoom = (props) => {
    const {series, options, tile, formatters} = props;
    const {legendSize, legendPosition, legendShown, legendContent} = tile.style.legendStyle;
    const formatter = formatters.measurementFormatters[series[0].customStack];

    if (["Left", "Right", "Auto"].includes(legendPosition)) {
        const maxValue = findMaxE(series[0].data.map((d) => d.value));
        const maxLabelWidth = getTextWidth(options.legend.itemStyle.fontSize, formatter(maxValue));
        const requiredWidth = colorAxisDimensions.thickness + colorAxisDimensions.labelDistance + colorAxisDimensions.anchorSize + maxLabelWidth;

        const width = getLegendAreaWidth(requiredWidth, legendSize);

        return {
            legendWidth: width,
            maxLabelWidth,
            [legendPosition === "Right" ? "paddingRight" : "paddingLeft"]: width,
        };
    }

    const labelHeight = getTextDimension(options.legend.itemStyle.fontSize, series[0].data[0].value).height;
    const height = colorAxisDimensions.thickness + colorAxisDimensions.labelDistance + colorAxisDimensions.anchorSize + labelHeight;

    return {
        legendHeight: height,
        labelHeight,
        [legendPosition === "Top" ? "paddingTop" : "paddingBottom"]: height,
    };
};

export const colorAxisDimensions = {
    thickness: 5,
    labelDistance: 5,
    anchorSize: 10, // fixed with css in color-axis.scss
};
