import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {TabsHeader} from "../../../../common/tabs/tabs-header";
import {SyncStatusIcon} from "../common/sync-status-icon/sync-status-icon";
import "./ds-sync-interaction.scss";
import {InteractionError} from "./error/interaction-error";
import moment from "moment";
import {cx} from "emotion";
import {keyed, keyed1} from "@common/react/keyed";
import {consumeContext} from "@common/react/context";
import {Load2} from "@common/react/load2";
import {SyncDetailInteraction} from "./sync-detail-interaction";
import {SyncSummaryInteraction} from "./sync-summary-interaction";
import {FixedPopupMenu} from "../../../../common/fixed-popup-menu/fixed-popup-menu";
import {DsClearErrorDialog} from "../../common/clear-error-dialog/ds-clear-error-dialog";
import {ForceSyncDialog} from "../../../common/force-sync-dialog/force-sync-dialog";
import {IgnoreUpdate} from "@common/react/ignore-update";
import {arrEquals} from "@common/utils/collections";
import {equalDeep, keepOnly} from "@common/utils/objects";
import {NavigateSyncSettings} from "../common/navigate-sync-settings";
import {ObserveDomSize} from "@common/react/observe-dom-size";

const interactionRanges = {
    DAY_1: 1,
    DAY_30: 30,
};

export const DsSyncInteraction = ({dsSettings, dataSource, tablesStatus, onReload}) =>
    cs(
        (_, next) => (dsSettings.value.selectedTable == null || !tablesStatus.value?.tables ? null : next()),
        consumeContext("apis"),
        consumeContext("routing"),
        consumeContext("toast"),
        [
            "latestTable",
            (_, next) => {
                return next(tablesStatus.value.tables.find((t) => t.name === dsSettings.value.selectedTable.name));
            },
        ],
        ({latestTable}, next) => keyed1({key: JSON.stringify(latestTable), next}),
        ({latestTable}, next) =>
            IgnoreUpdate({
                props: {dataSource, latestTable},
                when: (pp) =>
                    arrEquals(dataSource.value.errors || [], pp.dataSource.value.errors || []) &&
                    equalDeep(
                        JSON.stringify(keepOnly(latestTable, ["paused", "syncInProgress"])),
                        JSON.stringify(keepOnly(pp.latestTable, ["paused", "syncInProgress"]))
                    ),
                next,
            }),
        ["activeTab", (_, next) => UseState({initValue: 1, next})],
        [
            "goSyncSettings",
            ({routing}, next) =>
                cs(["navigateSyncSettings", (_, next) => NavigateSyncSettings({next})], ({navigateSyncSettings}) =>
                    next(() => navigateSyncSettings.goto())
                ),
        ],
        consumeContext("syncingProcess"),
        [
            "getStatus",
            ({syncingProcess}, next) => {
                const {syncingProcessObserver} = syncingProcess;

                return next((row) => {
                    if ((dataSource.value?.errors || []).findIndex((e) => e.dataSourceTableID === row.id) > -1) return "error";
                    if (syncingProcessObserver.tablesSyncing.indexOf(row.id) > -1) return "syncing";
                    if (row.paused) return "paused";
                    if (row.syncSchedule.$type === "OneTimeSync") return "syncOnce";
                    if (row.lastSync) return "sync";
                    return "will-sync";
                });
            },
        ],
        ({activeTab, apis, routing, latestTable, goSyncSettings, syncingProcess, toast, getStatus}) => {
            const {syncingProcessObserver} = syncingProcess;

            return (
                <div className="ds-sync-interaction-88t">
                    <div className="header">
                        <div className="left-side">
                            {SyncStatusIcon({type: getStatus(latestTable)})}

                            <div className="label">
                                <div className="text">{dsSettings.value.selectedTable.name}</div>

                                {cs(
                                    ["clearErrorsDialog", (_, next) => DsClearErrorDialog({next})],
                                    [
                                        "onTogglePause",
                                        ({}, next) =>
                                            next(async (paused) => {
                                                try {
                                                    const apisData = paused ? apis.data.pauseTables : apis.data.resumeTables;
                                                    await apisData(dataSource.value.id, [latestTable.id]);

                                                    toast.show(`${paused ? `Pause` : `Unpause`} ${latestTable.name} table`);
                                                    onReload();
                                                } catch (e) {
                                                    toast.show(e.message, {
                                                        isError: true,
                                                    });
                                                } finally {
                                                    // loading.onChange(false)
                                                }
                                            }),
                                    ],
                                    ["forceSyncDialog", (_, next) => ForceSyncDialog({next})],
                                    [
                                        "commands",
                                        ({clearErrorsDialog, onTogglePause, forceSyncDialog}, next) =>
                                            next([
                                                {
                                                    label: (
                                                        <>
                                                            <span className="text">Sync Settings</span>
                                                        </>
                                                    ),
                                                    onClick: goSyncSettings,
                                                },
                                                ...cs(
                                                    (_, next) =>
                                                        (dataSource.value?.errors || []).findIndex(
                                                            (e) => e.dataSourceTableID === latestTable.id
                                                        ) > -1
                                                            ? [
                                                                  {
                                                                      label: (
                                                                          <>
                                                                              <span className="text">Clear Errors</span>
                                                                          </>
                                                                      ),
                                                                      onClick: async () => {
                                                                          const resp = await clearErrorsDialog.show({
                                                                              dsID: routing.params.dsId,
                                                                              goSyncSettings,
                                                                              errorTablesIDsSelected: [latestTable.id],
                                                                          });

                                                                          if (resp) {
                                                                              syncingProcessObserver.start();
                                                                              onReload();
                                                                          }
                                                                      },
                                                                  },
                                                              ]
                                                            : next(),
                                                    (_) => [
                                                        {
                                                            label: (
                                                                <>
                                                                    <span className="text">Pause</span>
                                                                </>
                                                            ),
                                                            disabled: latestTable.paused,
                                                            onClick: () => onTogglePause(true),
                                                        },
                                                        {
                                                            label: (
                                                                <>
                                                                    <span className="text">Unpause</span>
                                                                </>
                                                            ),
                                                            disabled: !latestTable.paused,
                                                            onClick: () => onTogglePause(false),
                                                        },
                                                        {
                                                            label: (
                                                                <>
                                                                    <span className="text">Force Sync</span>
                                                                </>
                                                            ),
                                                            onClick: () =>
                                                                forceSyncDialog.show({
                                                                    dsid: dataSource.value.id,
                                                                    tables: [latestTable.id],
                                                                }),
                                                        },
                                                    ]
                                                ),
                                            ]),
                                    ],
                                    [
                                        "syncInteractionCM",
                                        ({commands}, next) =>
                                            FixedPopupMenu({
                                                offsetLeft: 136,
                                                position: {top: `44px`},
                                                getCommands: () => commands,
                                                minWidth: 160,
                                                next,
                                            }),
                                    ],
                                    ({syncInteractionCM}) => syncInteractionCM.render({})
                                )}
                            </div>
                        </div>

                        <span className="material-icons-outlined close-icon" onClick={() => dsSettings.onChange({selectedTable: null})}>
                            close
                        </span>
                    </div>

                    <div className="content">
                        {TabsHeader({
                            tabs: [
                                {
                                    value: interactionRanges.DAY_1,
                                    label: "1 Day",
                                },
                                {
                                    value: interactionRanges.DAY_30,
                                    label: "30 Days",
                                },
                            ].map((t) => ({
                                ...t,
                                active: activeTab.value === t.value,
                                onClick: (tab) => activeTab.onChange(tab.value),
                            })),
                        })}

                        <div className="main">
                            {cs(
                                ["range", (_, next) => cs(keyed(activeTab.value), () => UseState({initValue: 0, next}))],
                                [
                                    "dateRange",
                                    ({range}, next) =>
                                        next(
                                            {
                                                [interactionRanges.DAY_1]: {
                                                    start: moment().add(range.value * activeTab.value, "d"),
                                                    end: moment().add(range.value * activeTab.value, "d"),
                                                },
                                                [interactionRanges.DAY_30]: {
                                                    start: moment().add(range.value * activeTab.value - 29, "d"),
                                                    end: moment().add(range.value * activeTab.value, "d"),
                                                    list: [
                                                        ...Array.from(Array(30)).map((_, index) => {
                                                            return moment()
                                                                .startOf("day")
                                                                .subtract(
                                                                    Math.abs(range.value) * activeTab.value + (30 - (index + 1)),
                                                                    "d"
                                                                );
                                                        }),
                                                    ],
                                                },
                                            }[activeTab.value]
                                        ),
                                ],

                                [
                                    "syncInteraction",
                                    ({dateRange}, next) => {
                                        const syncView =
                                            activeTab.value === interactionRanges.DAY_1 ? SyncDetailInteraction : SyncSummaryInteraction;
                                        return syncView({
                                            dateRange,
                                            tableId: dsSettings.value.selectedTable.id,
                                            status: getStatus(latestTable),
                                            next,
                                        });
                                    },
                                ],

                                ({syncInteraction, range, dateRange}, next) => {
                                    return (
                                        <>
                                            <div className="range">
                                                <span
                                                    className={cx("material-icons-outlined arrow-icon")}
                                                    onClick={() => !syncInteraction.loading && range.change((r) => r - 1)}
                                                >
                                                    keyboard_arrow_left
                                                </span>

                                                {activeTab.value === interactionRanges.DAY_1 && (
                                                    <div className="value">
                                                        {range.value === 0 && `Today, `} {dateRange.end.format("MMM D")}
                                                    </div>
                                                )}

                                                {activeTab.value === interactionRanges.DAY_30 && (
                                                    <div className="values">
                                                        {dateRange.start.format("MMMM D")} - {dateRange.end.format("MMMM D")}
                                                    </div>
                                                )}

                                                <span
                                                    className={cx("material-icons-outlined arrow-icon", {
                                                        disabled: range.value === 0,
                                                    })}
                                                    onClick={() =>
                                                        !syncInteraction.loading && range.value < 0 && range.change((r) => r + 1)
                                                    }
                                                >
                                                    keyboard_arrow_right
                                                </span>
                                            </div>

                                            {syncInteraction.loading ? syncInteraction.renderLoading() : next()}
                                        </>
                                    );
                                },

                                ({syncInteraction}) =>
                                    cs(["overviewRef", (_, next) => ObserveDomSize({next})], ({overviewRef}) => {
                                        return (
                                            <>
                                                <div className="overview" ref={overviewRef.ref}>
                                                    {syncInteraction.renderChart({})}

                                                    {cs(
                                                        [
                                                            "syncError",
                                                            (_, next) =>
                                                                next(
                                                                    (dataSource.value.errors || []).find(
                                                                        (e) => e.dataSourceTableID === dsSettings.value.selectedTable.id
                                                                    )
                                                                ),
                                                        ],
                                                        ({syncError}) =>
                                                            syncError
                                                                ? InteractionError({
                                                                      error: syncError,
                                                                      table: dsSettings.value.selectedTable,
                                                                      onReload,
                                                                  })
                                                                : null
                                                    )}
                                                </div>

                                                {syncInteraction.renderTable({
                                                    height: `calc(100% - ${overviewRef.value?.height ?? 0}px - 40px)`,
                                                })}
                                            </>
                                        );
                                    })
                            )}
                        </div>
                    </div>

                    <div className="footer">
                        {cs(
                            [
                                "tableSummary",
                                (_, next) => next(dataSource.value.tables.find((t) => t.name === dsSettings.value.selectedTable.name)),
                            ],
                            ({tableSummary}) => {
                                const t = dsSettings.value.selectedTable;
                                return (
                                    <>
                                        <div className="item">Total Data: {tableSummary?.sizeMB} MB</div>
                                        <div className="item">Total Records: {Number(tableSummary?.records).toLocaleString()}</div>
                                        <div className="item">
                                            Sync Interval:{" "}
                                            {t.syncSchedule.$type === "OneTimeSync"
                                                ? "Sync Once"
                                                : `${t.syncSchedule.every} ${t.syncSchedule.interval}`}
                                        </div>
                                        <div className="item">Sync Method: {t.syncMethod}</div>
                                    </>
                                );
                            }
                        )}
                    </div>
                </div>
            );
        }
    );
