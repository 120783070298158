let loadedIds = [];

export const loadScript = ({src, id, ...attrs}) => {
    return new Promise((resolve, reject) => {
        if (~loadedIds.indexOf(id)) {
            return resolve();
        }

        let script = document.createElement("script");
        script.type = "text/javascript";
        script.id = id;
        script.src = src;

        for (const key in attrs) {
            script[key] = attrs[key];
        }

        // @ts-ignore
        if (script.readyState) {
            // @ts-ignore
            script.onreadystatechange = () => {
                // @ts-ignore
                if (script.readyState === "loaded" || script.readyState === "complete") {
                    // @ts-ignore
                    script.onreadystatechange = null;
                    resolve();
                }
            };
        } else {
            script.onload = resolve;
            script.onerror = function () {
                console.error(arguments);
                if (typeof reject === "function") {
                    reject(arguments);
                }
            };
        }

        document.getElementsByTagName("head")[0].appendChild(script);
        loadedIds.push(id);
    });
};
