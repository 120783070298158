import {Load} from "@common/react/load";
import {cs} from "@common/react/chain-services";
import {consumeContext, provideContext} from "@common/react/context";
import {cGetFieldInfo} from "../common/field-info/get-field-info";
import {Load2} from "@common/react/load2";
import {initTheme} from "../../theme/edit/common/theme-init";

export const createGetFieldInfo = ({next, sharedTile}) =>
    cs(
        consumeContext("apis"),
        consumeContext("modelForCollection"),
        [
            "related",
            ({collection, apis}, next) => {
                return sharedTile.value?.id
                    ? Load({
                          fetch: () => apis.collectionTiles.getRelatedTo(sharedTile.value?.id),
                          next,
                      })
                    : next({});
            },
        ],
        ["getFieldInfo", ({modelForCollection: model, related}, next) => next(cGetFieldInfo(model, related?.DataSource))],
        ({getFieldInfo}) => provideContext("getFieldInfo", getFieldInfo, next),
        ({getFieldInfo}) => next(getFieldInfo)
    );

export const loadSharedTile = ({next}) =>
    cs(
        consumeContext("apis"),
        consumeContext("routing"),
        [
            "sharedTile",
            ({apis, routing}, next) =>
                Load2({
                    _key: routing.params.sharedTileId ?? null,
                    fetch: async () => {
                        if (!routing.params.sharedTileId) {
                            return null;
                        }
                        return apis.collectionTiles.getTile(routing.params.sharedTileId);
                    },
                    captureException: true,
                    next,
                }),
        ],
        ({sharedTile, routing}, next) => {
            if (sharedTile.apiError) {
                routing.goto("dashboard");
                return null;
            }
            return next();
        },
        [
            "theme",
            ({apis}, next) =>
                Load({
                    fetch: async () => {
                        const themes = await apis.collection.getThemes();
                        const defaultTheme = themes?.find((t) => t.default);

                        if (!defaultTheme) {
                            return initTheme({});
                        }

                        return apis.collection.getTheme(defaultTheme.id);
                    },
                    next,
                }),
        ],
        ({theme}, next) => {
            return provideContext("theme", theme, next);
        },
        ({sharedTile}) => provideContext("sharedTile", sharedTile, next)
    );

const mockCollection = {
    id: "00000000-0000-0000-0000-000000000000",
    name: "fake-collection",
    isIndependentContext: true,
    $type: "DashboardCollection",
    showTimezoneConversion: false,
    defaultTimezoneToUtc: false,
    filters: [],
    filterStyle: {
        display: "Attached",
        wrapLines: false,
        showLabels: true,
        position: "Left",
        runType: "OnSubmit",
        runButtonText: "Submit",
        enableSavingFilterSets: false,
    },
};

export const createDefaultCollection = (sharedTile) => ({
    ...mockCollection,
    gridLocations: [
        {
            colStart: 1,
            rowStart: 1,
            colSpan: sharedTile.defaultColSpan ?? 5,
            rowSpan: sharedTile.defaultRowSpan ?? 5,
            tile: sharedTile,
        },
    ],
});
export const loadDefaultCollection = ({next: rootNext, sharedTile}) =>
    cs(
        consumeContext("apis"),
        (_, next) =>
            provideContext(
                "collection",
                {
                    value: createDefaultCollection(sharedTile.value),
                },
                next
            ),
        [
            "modelForCollection",
            ({apis, collection}, next) =>
                Load({
                    _key: sharedTile.value?.modelID,
                    fetch: async () => {
                        if (sharedTile.value?.modelID) {
                            const model = await apis.model.getModel(sharedTile.value.modelID);
                            return {
                                ...model,
                                tables: model.tables.map((table) => ({
                                    ...table,
                                    columns: table.columns.concat(table.disabledColumns),
                                })),
                            };
                        }

                        return null;
                    },
                    next,
                }),
        ],
        ({modelForCollection}) => provideContext("modelForCollection", modelForCollection, rootNext)
    );
