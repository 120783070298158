const re = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;
const validateWebUrl = (url) => {
    return String(url).match(re)?.length === 1;
};

exports.validateWebUrl = validateWebUrl;
const isWebsiteUrl = {
    name: "is-website-url",
    validate: (url) => url !== null && url !== "" && !!validateWebUrl(url),
};
exports.isWebsiteUrl = isWebsiteUrl;
