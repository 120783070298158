const {cs} = require("../../chain-services");
const {Load} = require("../../load");

const AsyncValidator = ({disabled, value, validate, next}) =>
    cs(
        [
            "check",
            ({apis}, next) =>
                Load({
                    _key: !!disabled + "|" + value,
                    fetch: () => (disabled ? true : validate(value)),
                    next,
                }),
        ],
        ({check}) =>
            next({
                status: disabled ? "success" : check == null ? "pending" : check ? "success" : "failed",
            })
    );
exports.AsyncValidator = AsyncValidator;
