import React from "react";

import {fragments} from "@common/react/fragments";
import {percentToHex} from "@common/utils/color-util";

export const maxNumberDisplayedCharacters = 39;
const numAvailableCharacters = 16;

const renderHighLight = ({text, highlights, color = "#105CA9"}) => {
    const _mark = (part, matched) => (matched ? <mark style={{background: color + percentToHex(20)}}>{part}</mark> : <span>{part}</span>);
    if (!highlights || highlights.length == 0) {
        return text;
    }

    let accumulated = text.substring(0, highlights[0][0]).length > 0 ? [_mark(text.substring(0, highlights[0][0]), false)] : [];
    for (let i = 0; i < highlights.length; i += 1) {
        let fr = highlights[i][0];
        let to = highlights[i][1];

        accumulated = accumulated.concat(_mark(text.substring(fr, to), true)) ?? accumulated;

        if (highlights[i + 1] && highlights[i][1] < highlights[i + 1][0]) {
            accumulated = accumulated.concat(_mark(text.substring(highlights[i][1], highlights[i + 1][0]), false)) ?? accumulated;
        }
    }

    const remainText = text.substring(highlights[highlights.length - 1][1]);

    if (remainText.length > 0) {
        accumulated = accumulated.concat(_mark(remainText, false)) ?? accumulated;
    }

    return fragments(...accumulated);
};

export const EllipsisText = React.memo(({text = "", highlights = [], highlightColor: color}) => {
    if (text.length <= maxNumberDisplayedCharacters) {
        return renderHighLight({text, highlights, color});
    }

    const startStr = renderHighLight({
        text: text.substring(0, numAvailableCharacters),
        highlights: highlights.filter((h) => h[1] < numAvailableCharacters),
        color,
    });

    const endStr = renderHighLight({
        text: text.substring(text.length - numAvailableCharacters, numAvailableCharacters),
        highlights: highlights.filter((h) => h[0] >= text.length - numAvailableCharacters),
        color,
    });

    return fragments(startStr, <span>...</span>, endStr);
});
