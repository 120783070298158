import "./expandable.scss";

import {cx} from "emotion";
import * as React from "react";

import {Watch} from "@common/react/watch";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";

export const Expandable = (props) => {
    const {
        label,
        icon,
        initExpand = true,
        noTopBorder,
        render,
        level,
        control,
        passiveExpand,
        iconTooltip,
        updateState,
        dataID,
        popover,
        style,
        className,
        customClick,
        alwaysShow = false,
        // debug = false,
    } = props;
    return cs(
        [
            "expanding",
            (_, next) => (
                <UseState
                    {...{
                        initValue: alwaysShow || initExpand || updateState?.value,
                        next,
                    }}
                />
            ),
        ],
        ({expanding}) => (
            <div
                className={cx(
                    "expandable expandable-2si",
                    {
                        expanding: passiveExpand || expanding.value,
                        noTopBorder,
                        "has-popover": popover,
                        "always-show": alwaysShow,
                    },
                    `level-${level}`,
                    className
                )}
                style={style}
                data-id={dataID}
            >
                {popover && <div className="popover">{popover}</div>}

                {Watch({
                    value: JSON.stringify(updateState),
                    onChanged: (changed) => {
                        const {value: newVal} = JSON.parse(changed) || {value: false};
                        // if (debug) {
                        //     console.log({dataID, newVal, old: expanding.value, changed});
                        // }
                        if(expanding.value != !!newVal) {
                            expanding.onChange(!!newVal);
                        }
                    },
                })}

                <div
                    className={cx("toggle", {hasIcon: icon})}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if(!render || alwaysShow) {
                            return;
                        }

                        if(customClick) {
                            customClick(expanding.value);
                        } else {
                            expanding.onChange(!expanding.value);
                        }
                    }}
                >
                    {render && !alwaysShow && (
                        <i className={cx("fa fa-angle-right", !passiveExpand && !expanding.value ? "right" : "down")} />
                    )}

                    {icon && <div className="icon">{icon}</div>}

                    <div className="label">
                        {typeof label === "function" ? label?.({isExpand: passiveExpand || expanding.value}) : label}
                    </div>

                    {iconTooltip}

                    {control && <div className="control">{control}</div>}
                </div>
                {render && (passiveExpand || expanding.value) && <div className="content">{render?.()}</div>}
            </div>
        )
    );
};

export const ExpandableContent = ({content, render, initExpand, passiveExpand}) =>
    cs(["expanding", (_, next) => <UseState {...{initValue: initExpand, next}} />], ({expanding}) => (
        <div
            className={cx("expandable-content expandable-content-2si", {
                expanding: passiveExpand || expanding.value,
            })}
        >
            <div
                className={cx("toggle", {
                    expanding: passiveExpand || expanding.value,
                })}
            >
                <img
                    onClick={() => expanding.onChange(!expanding.value)}
                    className={cx("chevron", !passiveExpand && !expanding.value ? "right" : "down")}
                    src={require("../icons/chevron-thin-right.svg")}
                    alt=""
                />

                {content?.()}
            </div>

            {(passiveExpand || expanding.value) && <div className="expanded-content">{render?.()}</div>}
        </div>
    ));

export const ExpandableText = ({text, render, initExpand, passiveExpand, className}) =>
    cs(["expanding", (_, next) => <UseState {...{initValue: initExpand, next}} />], ({expanding}) => (
        <div className={cx("expandable-text expandable-text-2si", {expanding: passiveExpand || expanding.value}, className)}>
            <div
                className={cx("toggle", {
                    expanding: passiveExpand || expanding.value,
                })}
                onClick={() => expanding.onChange(!expanding.value)}
            >
                <i className={cx("fal fa-angle-right", !passiveExpand && !expanding.value ? "right" : "down")} />

                {text}
            </div>

            {(passiveExpand || expanding.value) && <div className="expanded-content">{render?.()}</div>}
        </div>
    ));
