import {TileTitle} from "./panels/tile-title/tile-title";
import {ValueDisplay} from "./panels/value-display/value-display";
import {Comparison} from "./panels/comparison/comparison";
import {Display} from "./panels/display/display";
import {SparkDisplay} from "./panels/spark-display/spark-display";

export const SparkLineKpi = ({tile, size, form, parentTile, tileAction, kpiColorsTileConfig, colorsTileConfig, isContainerTile}) => [
    Display,
    TileTitle,
    SparkDisplay,
    ValueDisplay,
    Comparison,
    // ValueFormat,
].map((panel) => panel({tile, size, form, parentTile, tileAction, kpiColorsTileConfig, colorsTileConfig, isContainerTile}));
