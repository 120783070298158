import React from "react";

export const TrashIcon = () => (
    <svg className="trash-icon" id="TrashCan" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
        <g id="Group_687" data-name="Group 687" transform="translate(-1336 -573)">
            <path
                id="Path_183"
                data-name="Path 183"
                d="M265.256,391.64h-2.308v-.646a1.571,1.571,0,0,0-1.568-1.569h-2.215a1.571,1.571,0,0,0-1.569,1.569v.646h-2.308a.461.461,0,1,0,0,.923h.646v7.293a1.571,1.571,0,0,0,1.569,1.569h5.538a1.571,1.571,0,0,0,1.569-1.569v-7.293h.645a.461.461,0,0,0,0-.923Zm-6.737-.646a.647.647,0,0,1,.646-.646h2.215a.647.647,0,0,1,.645.646v.646h-3.507Zm5.169,8.862a.647.647,0,0,1-.646.646H257.5a.647.647,0,0,1-.646-.646v-7.293h6.83Z"
                transform="translate(1087.173 189.575)"
                fill="currentColor"
            />
            <path
                id="Path_184"
                data-name="Path 184"
                d="M259.527,394.825a.5.5,0,0,0-.5.5v3.6a.5.5,0,0,0,1,0v-3.6A.5.5,0,0,0,259.527,394.825Z"
                transform="translate(1086.82 188.932)"
                fill="currentColor"
            />
            <path
                id="Path_185"
                data-name="Path 185"
                d="M261.927,394.825a.5.5,0,0,0-.5.5v3.6a.5.5,0,0,0,1,0v-3.6A.5.5,0,0,0,261.927,394.825Z"
                transform="translate(1086.618 188.932)"
                fill="currentColor"
            />
        </g>
    </svg>
);

export const TrashIconBold = () => (
    <svg className="trash-icon-bold" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5.83329 3.33317V1.6665H14.1666V3.33317H18.3333V4.99984H16.6666V17.4998C16.6666 17.7209 16.5788 17.9328 16.4225 18.0891C16.2663 18.2454 16.0543 18.3332 15.8333 18.3332H4.16663C3.94561 18.3332 3.73365 18.2454 3.57737 18.0891C3.42109 17.9328 3.33329 17.7209 3.33329 17.4998V4.99984H1.66663V3.33317H5.83329ZM4.99996 4.99984V16.6665H15V4.99984H4.99996ZM7.49996 7.49984H9.16663V14.1665H7.49996V7.49984ZM10.8333 7.49984H12.5V14.1665H10.8333V7.49984Z"
            fill="currentColor"
        />
    </svg>
);

export const TrashIconBlue = () => (
    <svg className="trash-icon" id="TrashCan" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
        <g id="Group_687" data-name="Group 687" transform="translate(-1336 -573)">
            <path
                id="Path_183"
                data-name="Path 183"
                d="M265.256,391.64h-2.308v-.646a1.571,1.571,0,0,0-1.568-1.569h-2.215a1.571,1.571,0,0,0-1.569,1.569v.646h-2.308a.461.461,0,1,0,0,.923h.646v7.293a1.571,1.571,0,0,0,1.569,1.569h5.538a1.571,1.571,0,0,0,1.569-1.569v-7.293h.645a.461.461,0,0,0,0-.923Zm-6.737-.646a.647.647,0,0,1,.646-.646h2.215a.647.647,0,0,1,.645.646v.646h-3.507Zm5.169,8.862a.647.647,0,0,1-.646.646H257.5a.647.647,0,0,1-.646-.646v-7.293h6.83Z"
                transform="translate(1087.173 189.575)"
                fill="#2692F5"
            />
            <path
                id="Path_184"
                data-name="Path 184"
                d="M259.527,394.825a.5.5,0,0,0-.5.5v3.6a.5.5,0,0,0,1,0v-3.6A.5.5,0,0,0,259.527,394.825Z"
                transform="translate(1086.82 188.932)"
                fill="#2692F5"
            />
            <path
                id="Path_185"
                data-name="Path 185"
                d="M261.927,394.825a.5.5,0,0,0-.5.5v3.6a.5.5,0,0,0,1,0v-3.6A.5.5,0,0,0,261.927,394.825Z"
                transform="translate(1086.618 188.932)"
                fill="#2692F5"
            />
        </g>
    </svg>
);

export const DeleteIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5 3.2V2H11V3.2H14V4.4H12.8V13.4C12.8 13.5591 12.7368 13.7117 12.6243 13.8243C12.5117 13.9368 12.3591 14 12.2 14H3.8C3.64087 14 3.48826 13.9368 3.37574 13.8243C3.26321 13.7117 3.2 13.5591 3.2 13.4V4.4H2V3.2H5ZM4.4 4.4V12.8H11.6V4.4H4.4ZM6.2 6.2H7.4V11H6.2V6.2ZM8.6 6.2H9.8V11H8.6V6.2Z"
            fill="currentColor"
        />
    </svg>
);
