import * as React from "react";
import {cs} from "@common/react/chain-services";
import "./alert-card.scss";

export const AlertCard = ({message}) =>
    cs(({}) => (
        <div className="alert-card-89e alert-card">
            <div className="exclamation-mark">
                <img src={require("../../common/icons/exclamation-icon.svg")} alt="" />
            </div>
            <div className="message">{message}</div>
        </div>
    ));
