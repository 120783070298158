import React from "react";
import {rFieldTypeIcon} from "../../common/field-type-icon/get-field-type-icon";
import {dataSourceTypes} from "./data-source-type";

let coveredTooltip = {
    icon: <span className="material-icons check">check_circle_outline</span>,
    message: "This column is covered by an index",
};

let unCoveredTooltip = {
    icon: <span className="material-icons error">error_outline</span>,
    message: "This column isn't covered by an index. Queries may take significantly longer on large tables.",
};

export const SyncMethodTypesToLabel = {
    Incremental: "Incremental",
    ChangeDataCapture: "CDC",
    ChangeTracking: "CT",
    Full: "Full",
    None: "None",
};
export const SyncMethodTypesToValue = {
    Incremental: "Incremental",
    ChangeDataCapture: "ChangeDataCapture",
    ChangeTracking: "ChangeTracking",
    Full: "Full",
    None: "None",
};

export const methodList = ({item, type, tooltip}) => [
    ...([dataSourceTypes.GOOGLE_SHEETS, dataSourceTypes.FILE].indexOf(type) == -1
        ? [
              {
                  name: "Incremental",
                  value: "Incremental",
                  //disabled: item.columns.findIndex(c => c.primaryKey) == -1,
                  children: item.columns
                      .filter((c) => ["DateTime", "DateTimeOffset", "Int", "Double", "IDInt"].indexOf(c.dataType) > -1)
                      .map((c) => {
                          const colTooltip = c.indexed ? coveredTooltip : unCoveredTooltip;
                          return {
                              name: c.name,
                              value: "Incremental",
                              icon: rFieldTypeIcon(c.dataType),
                              rightIcon: React.cloneElement(colTooltip.icon, {
                                  ...tooltip(() => colTooltip.message),
                              }),
                          };
                      }),
                  disabled: item.columns.filter((c) => ["DateTime", "DateTimeOffset", "Int", "Double", "IDInt"].indexOf(c.dataType) > -1).length == 0,
                  disabledText: "(No Numeric/Date Columns)",
              },
              {
                  name: "CDC",
                  value: "ChangeDataCapture",
                  disabled: !item.cdcAvailable,
                  disabledText: "(Not Available)",
              },
              {
                  name: "CT",
                  value: "ChangeTracking",
                  disabled: !item.ctAvailable,
                  disabledText: "(Not Available)",
              },
          ]
        : []),
    {name: "Full", value: "Full"},
];

export const methodList2 = ({groupItems, type}) => {
    return [
        ...([dataSourceTypes.GOOGLE_SHEETS, dataSourceTypes.FILE].indexOf(type) == -1
            ? [
                  {
                      name: "Incremental",
                      value: "Incremental",
                      disabled: groupItems.find((item) => item.columns.findIndex((c) => c.primaryKey) == -1),
                      disabledText: "(Not Available)",
                  },
                  {
                      name: "CDC",
                      value: "ChangeDataCapture",
                      disabled: groupItems.find((item) => !item.cdcAvailable),
                      disabledText: "(Not Available)",
                  },
                  {
                      name: "CT",
                      value: "ChangeTracking",
                      disabled: groupItems.find((item) => !item.ctAvailable),
                      disabledText: "(Not Available)",
                  },
              ]
            : []),
        {name: "Full", value: "Full"},
    ];
};
