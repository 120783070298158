import {getTooltipFontSizes} from "./get-tooltip-font-sizes";
import {getIndicatorShapes} from "./indicator-shapes";
import {getDifference} from "./get-difference";
import {getColors} from "../../../gauge/chart-options/gauge-helpers";

export const rGaugeTableCompare = ({tile, rawData, formatters, theme}) => {
    const fontSizes = getTooltipFontSizes(theme);
    const dateRangeFormatter = (range) => formatters.dateFormatter(range.dateStart) + " - " + "</br>" + formatters.dateFormatter(range.dateEnd);
    const indicatorShapes = getIndicatorShapes(theme);

    const rHeader = () => {
        const seriesTitle = (() => {
            const {percentDiff, diffIcon, stateColor} = getDifference({
                value: rawData.value,
                compareValue: rawData.previousValue,
                positiveChangeGood: tile.style.positiveChangeGood,
                theme,
            });

            return `
                <div class="title">
                    ${tile.valueField.displayName}
                </div>
                <div class="difference" style="color: ${stateColor}">
                    ${diffIcon}
                    ${formatters.percentFormatter(percentDiff)}
                </div>
            `;
        })();

        return `<div class="header table-row" style="font-size: ${fontSizes.small}px">
                <div class="series-title">${seriesTitle}</div>
                    <div class="main-title">
                        ${rawData.range && dateRangeFormatter(rawData.range)}
                    </div>
                    <div class="compare-title">
                        ${rawData.previousRange && dateRangeFormatter(rawData.previousRange)}
                    </div>
            </div>`;
    };

    const rPoints = () => {
        const {dataColor, compareDataColor} = getColors({tile, theme});
        return [
            `<div 
                class="point table-row first-point"
                style="font-size: ${fontSizes.medium}px"
            >
                <div class="name">
                    <span class="indicator" style="color: ${dataColor}">
                        ${indicatorShapes["bar"]}
                    </span>
                    ${tile.valueField.displayName}
                </div>
                <div class="main-value">${rawData.value == null ? "N/A" : formatters.valueFormatter(rawData.value)}</div>
                <div class="compare-value">${rawData.previousValue == null ? "N/A" : formatters.valueFormatter(rawData.previousValue)}</div>
            </div>`,
            `<div 
                class="point table-row last-point"
                style="font-size: ${fontSizes.medium}px"
            >
                <div class="name">
                    <span class="indicator" style="color: ${compareDataColor}">
                        ${indicatorShapes["dashed-line"]}
                    </span>
                    ${tile.targetValueField?.displayName || tile.targetValueConstant?.displayName || "Target"}
                </div>
                <div class="main-value">${rawData.target == null ? "N/A" : formatters.targetValueFormatter(rawData.target)}</div>
                <div class="compare-value">${rawData.previousTarget == null ? "N/A" : formatters.targetValueFormatter(rawData.previousTarget)}</div>
            </div>`,
        ].join("");
    };

    const rDifference = () => {
        const valueNA = rawData.value == null || rawData.target == null;
        const previousValueNA = rawData.previousValue == null || rawData.previousTarget == null;
        return `
            <div class="total-row table-row" style="font-size: ${fontSizes.medium}px">
                <div style="font-weight: normal; text-align: left">Difference</div>
                <div class="main-total">
                    ${valueNA ? "N/A" : formatters.valueFormatter(rawData.value - rawData.target)}
                    </br>
                    <span style="font-weight: normal; font-size: ${fontSizes.small}px">
                        (${valueNA || rawData.target === 0 ? "N/A" : formatters.percentFormatter((rawData.value / rawData.target) * 100)})
                    </span>
                </div>
                <div class="compare-total">
                    ${previousValueNA ? "N/A" : formatters.valueFormatter(rawData.previousValue - rawData.previousTarget)}
                    </br>
                    <span style="font-weight: normal; font-size: ${fontSizes.small}px">
                        (${previousValueNA || rawData.previousTarget === 0 ? "N/A" : formatters.percentFormatter((rawData.previousValue / rawData.previousTarget) * 100)})
                    </span>
                </div>
            </div>
        `;
    };

    return `<div class="table">
            ${rHeader()}
            ${rPoints()}
            ${rDifference()}
        </div>`;
};
