import * as React from "react";
import {BasicInputLive} from "../basic-input-live/basic-input-live";
import {bindInput} from "../../../../../../react/bind-input";
import {cx} from "emotion";

export const TextInputLive = ({...props}) =>
    BasicInputLive({
        ...props,
        className: cx("text-input-live", props.className),
        bind: bindInput,
    });
