import "./choose-interval-dialog.scss";

import React from "react";
import {cx} from "emotion";

import {cs} from "@common/react/chain-services";
import {keyed, keyed1} from "@common/react/keyed";
import {UseState} from "@common/react/use-state";
import {noun} from "@common/utils/plural";
import {spc} from "@common/react/state-path-change";
import {deepTrim, isBlank} from "@common/utils/strings";
import {consumeContext} from "@common/react/context";
import {Button} from "@common/form/buttons/button/button";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";

import {DialogService} from "../../../../../../../../../common/dialog/dialog-service";
import {CheckboxLine} from "../../../../../../../../../common/checkbox-line/checkbox-line";
import {RadioLine} from "../../../../../../../../../common/radio-line/radio-line";
import {tooltipService3} from "../../../../../../../../../common/tooltip3/tooltip-service-3";

export const ChooseIntervalDialog = ({next: rootNext, isCreateNew = false}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    className: "choose-interval-wrapper-dialog-99p",
                    render: ({resolve, args: {value, syncMethod, title, showWarning}}) => ({
                        title: title ?? "Order Table Sync Interval",
                        width: 540,
                        content: next({
                            resolve,
                            value,
                            syncMethod,
                            showWarning,
                        }),
                    }),
                    next: rootNext,
                }),
        ],
        [
            "state",
            ({modal}, next) =>
                UseState({
                    initValue: {
                        every: "",
                        interval: "",
                        ...modal.value,
                        $type: modal?.value?.$type ?? "SyncSchedule",
                    },
                    next,
                }),
        ],
        ["attemptSubmit", ({}, next) => UseState({initValue: false, next})],
        ({modal, state, attemptSubmit}) => {
            const stateToCheckboxList = (value1, state, path) => ({
                value: deepTrim(state.value?.[path]).split(",").indexOf(value1) > -1,
                onChange: (value) =>
                    spc(state, [path], (oldV) => {
                        if (value1 === "All") return value ? value1 : ``;
                        return value
                            ? deepTrim(`${oldV.split(",").indexOf("All") > -1 ? `` : `${oldV}${!isBlank(oldV) ? `,` : ``}`}${value1}`)
                            : deepTrim(oldV)
                                  .split(",")
                                  .filter((v) => v !== value1)
                                  .join(",");
                    }),
            });

            const getInvalids = (value) => {
                let ret = [];
                if (isBlank(value.interval)) ret.push("every");
                if (value.interval === "Minutes") {
                    if (isBlank(value.duringHours)) ret.push("duringHours");
                }
                if (isBlank(value.onDays)) ret.push("onDays");
                return ret;
            };

            const invalidFields = getInvalids(state.value);

            const onChangeSelect = ({item, label}) => {
                state.change((s) =>
                    item.$type === "SyncSchedule"
                        ? {
                              duringHours: null,
                              onDays: "All",
                              ...s,
                              $type: "SyncSchedule",
                              every: item.value,
                              interval: label,
                          }
                        : {$type: "OneTimeSync"}
                );
            };

            const pauseSyncLabel = isCreateNew ? "Sync only once" : "Pause Sync";

            return (
                <div
                    className={cx("choose-interval-dialog-4qw", {
                        "attempt-submit": attemptSubmit.value,
                    })}
                >
                    <VerbDialogBodyScrollbar>
                        {/* { modal.showWarning && state.value.interval == "Minutes" && (
                        <div className="alert">
                            <img src={require("./exclamation-solid.svg")} alt=""/>
                            Updates will only apply to tables with sync methods that support the intervals selected. You've selected full and partial sync methods. Selecting an interval less than 1 hour will not be applied to tables with the Full sync method selected.
                        </div>
                    )} */}

                        <div className="flex-group">
                            <div className="flex-item">
                                <div
                                    className={cx("title-text", {
                                        error: attemptSubmit.value && invalidFields.indexOf("every") > -1,
                                    })}
                                >
                                    Sync Data Every
                                </div>

                                <div className="times">
                                    {cs(tooltipService3({direction: "below"}), ({tooltip}) => {
                                        return (
                                            <div className="every-minutes">
                                                {[1, 2, 3, 4, 5, 6, 10, 15, 30].map((item) =>
                                                    cs(keyed(item), () => (
                                                        <div
                                                            {...((modal.syncMethod === "Full" && item < 15) || modal.showWarning
                                                                ? tooltip(
                                                                      () =>
                                                                          `One or more of the tables selected has Full sync method set. Minute intervals are not supported with Full sync.`
                                                                  )
                                                                : {})}
                                                        >
                                                            {RadioLine({
                                                                scale: "md",
                                                                selected: state.value.every === item && state.value.interval === "Minutes",
                                                                onClick: () =>
                                                                    onChangeSelect({
                                                                        item: {
                                                                            value: item,
                                                                            $type: "SyncSchedule",
                                                                        },
                                                                        label: "Minutes",
                                                                    }),
                                                                disabled: (modal.syncMethod === "Full" && item < 15) || modal.showWarning,
                                                                label: `${item} ${noun(item, "Minute")}`,
                                                            })}
                                                        </div>
                                                    ))
                                                )}
                                            </div>
                                        );
                                    })}

                                    <div className="every-hours">
                                        {[1, 2, 3, 4, 6, 12, 24].map((item) =>
                                            cs(keyed(item), () =>
                                                RadioLine({
                                                    scale: "md",
                                                    selected: state.value.every === item && state.value.interval === "Hours",
                                                    onClick: () =>
                                                        onChangeSelect({
                                                            item: {
                                                                value: item,
                                                                $type: "SyncSchedule",
                                                            },
                                                            label: "Hours",
                                                        }),
                                                    label: `${item} ${noun(item, "Hour")}`,
                                                })
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="flex-item">
                                {state.value?.$type === "SyncSchedule" && (
                                    <>
                                        {state.value.interval === "Minutes" && (
                                            <>
                                                <div
                                                    className={cx("title-text", {
                                                        error: attemptSubmit.value && invalidFields.indexOf("duringHours") > -1,
                                                    })}
                                                >
                                                    During Hours of Day (UTC - 4:00)
                                                </div>
                                                <div className="hours">
                                                    {RadioLine({
                                                        scale: "md",
                                                        selected: state.value.duringHours === "All",
                                                        onClick: () =>
                                                            state.change((s) => ({
                                                                ...s,
                                                                duringHours: "All",
                                                            })),
                                                        label: `All Day`,
                                                    })}

                                                    {RadioLine({
                                                        scale: "md",
                                                        selected: state.value.duringHours !== "All" && state.value.duringHours !== null,
                                                        onClick: () =>
                                                            state.change((s) => ({
                                                                ...s,
                                                                duringHours: s.duringHours === "All" ? "" : isBlank(s.duringHours) ? "" : s.duringHours,
                                                            })),
                                                        label: `Select Hours`,
                                                    })}

                                                    {/* {CheckboxLine({state: stateToCheckboxList(`All`, state, "duringHours"), label: "All Day"})} */}

                                                    {cs(() => {
                                                        const disabled = state.value.duringHours === "All" || state.value.duringHours === null;
                                                        const disabledState = {
                                                            value: state.value.duringHours === "All",
                                                            onChange: () => {},
                                                        };

                                                        return (
                                                            <div
                                                                className={cx("hours-group-wrapper", {
                                                                    disabled,
                                                                })}
                                                            >
                                                                <div className="hours-group">
                                                                    <div>
                                                                        {[12, ...[...Array(11)].map((item, index) => index + 1)].map((item, index) =>
                                                                            cs(
                                                                                ["realValue", (_, next) => next((index === 0 ? index : item) + 4)],
                                                                                ({realValue}, next) =>
                                                                                    keyed1({
                                                                                        key: realValue,
                                                                                        next,
                                                                                    }),
                                                                                ({realValue}) =>
                                                                                    CheckboxLine({
                                                                                        state: disabled
                                                                                            ? disabledState
                                                                                            : stateToCheckboxList(`Hour${realValue}`, state, "duringHours"),
                                                                                        label: `${item} AM`,
                                                                                    })
                                                                            )
                                                                        )}
                                                                    </div>
                                                                </div>

                                                                <div className="hours-group">
                                                                    <div>
                                                                        {[12, ...[...Array(11)].map((item, index) => index + 1)].map((item, index) =>
                                                                            cs(
                                                                                [
                                                                                    "realValue",
                                                                                    (_, next) => {
                                                                                        let result = 12 + (index === 0 ? index : item) + 4;
                                                                                        return next(result >= 24 ? result - 24 : result);
                                                                                    },
                                                                                ],
                                                                                ({realValue}, next) =>
                                                                                    keyed1({
                                                                                        key: realValue,
                                                                                        next,
                                                                                    }),
                                                                                ({realValue}) =>
                                                                                    CheckboxLine({
                                                                                        state: disabled
                                                                                            ? disabledState
                                                                                            : stateToCheckboxList(`Hour${realValue}`, state, "duringHours"),
                                                                                        label: `${item} PM`,
                                                                                    })
                                                                            )
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>

                        {state.value?.$type === "SyncSchedule" && (
                            <>
                                <div
                                    className={cx("title-text", {
                                        error: attemptSubmit.value && invalidFields.indexOf("onDays") > -1,
                                    })}
                                >
                                    During Days of Week
                                </div>

                                <div className="days">
                                    <div>
                                        {RadioLine({
                                            scale: "md",
                                            selected: state.value.onDays === "All",
                                            onClick: () =>
                                                state.change((s) => ({
                                                    ...s,
                                                    onDays: "All",
                                                })),
                                            label: `Every Day`,
                                        })}
                                    </div>

                                    <div className="select-days">
                                        {cs(() => {
                                            const disabled = state.value.onDays === "All" || state.value.onDays === null;
                                            const disabledState = {
                                                value: state.value.onDays === "All",
                                                onChange: () => {},
                                            };

                                            return (
                                                <>
                                                    {RadioLine({
                                                        scale: "md",
                                                        selected: state.value.onDays !== "All" && state.value.onDays !== null,
                                                        onClick: () =>
                                                            state.change((s) => ({
                                                                ...s,
                                                                onDays: s.onDays === "All" ? "" : isBlank(s.onDays) ? "" : s.onDays,
                                                            })),
                                                        label: `Select Days`,
                                                    })}

                                                    <div className={cx("days-group", {disabled})}>
                                                        {["Sunday", "Monday", "Tuesday"].map((item, index) =>
                                                            cs(keyed(item), () =>
                                                                CheckboxLine({
                                                                    state: disabled ? disabledState : stateToCheckboxList(item, state, "onDays"),
                                                                    label: item,
                                                                })
                                                            )
                                                        )}
                                                    </div>

                                                    <div className={cx("days-group", {disabled})}>
                                                        {["Wednesday", "Thursday", "Friday"].map((item, index) =>
                                                            cs(keyed(item), () =>
                                                                CheckboxLine({
                                                                    state: disabled ? disabledState : stateToCheckboxList(item, state, "onDays"),
                                                                    label: item,
                                                                })
                                                            )
                                                        )}
                                                    </div>

                                                    <div className={cx("days-group", {disabled})}>
                                                        {["Saturday"].map((item, index) =>
                                                            cs(keyed(item), () =>
                                                                CheckboxLine({
                                                                    state: disabled ? disabledState : stateToCheckboxList(item, state, "onDays"),
                                                                    label: item,
                                                                })
                                                            )
                                                        )}
                                                    </div>
                                                </>
                                            );
                                        })}
                                    </div>
                                </div>
                            </>
                        )}
                    </VerbDialogBodyScrollbar>

                    <div className="buttons">
                        <Button btnType="secondary" onClick={() => modal.resolve(null)}>
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                attemptSubmit.onChange(true);
                                if (invalidFields.length > 0) return;
                                modal.resolve(state.value);
                            }}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            );
        }
    );
