const {TimeoutAction} = require("./timeout-action");
const {Invoke} = require("./invoke");
const {fragments} = require("./fragments");
const {UseState} = require("./use-state");
const {cs} = require("./chain-services");

const Alarming = ({active, duration = 300, next}) =>
    cs(["tired", (_, next) => UseState({next})], ({tired}) =>
        fragments(
            !tired.value &&
                active &&
                TimeoutAction({
                    delay: duration,
                    action: () => {
                        tired.onChange(true);
                    },
                }),
            tired.value &&
                !active &&
                Invoke({
                    fn: () => tired.onChange(false),
                }),
            next(active && !tired.value)
        )
    );
exports.Alarming = Alarming;
