import {conflict, toRange} from "../../../../../../common/utils/ranges";
import {changePath} from "../../../../../../common/utils/arr-path";
import {sum} from "../../../../../../common/utils/collections";
import {numColumnsOfGrid} from "./grid-constants";
import {removeIdsInTile} from "../../../common/remove-ids";
import {omit} from "../../../../../../common/utils/objects";

export const conflictRect = ({rect, tiles}) =>
    tiles.find((t) => conflict(toRange(rect.x, rect.width), toRange(t.position.x, t.size.width)) && conflict(toRange(rect.y, rect.height), toRange(t.position.y, t.size.height)));

export const updatedCollectionAfterAdd = ({tile, collection, rect}) => {
    return changePath(collection.value, ["gridLocations"], (gls) => [
        ...gls,
        {
            colStart: rect.x + 1,
            rowStart: rect.y + 1,
            colSpan: rect.width,
            rowSpan: rect.height,
            tile: omit(tile, ['icon']),
        }
    ])
}

export const getPositionToAdd = ({tiles, tile}) => {
    let rect = {...tile.position, ...tile.size};

    while (true) {
        if (conflictRect({rect, tiles})) {
            if (rect.x + 1 + rect.width > numColumnsOfGrid) {
                rect.x = 0;
                rect.y++;
            } else {
                rect.x++;
            }
        } else {
            return {
                rect,
                tile: tile,
            };
        }
    }
};

export const getClonedTile = ({tiles, collection, tile, sourceTile}) => {
    let rect = {...tile.position, ...tile.size};

    while (true) {
        if (conflictRect({rect, tiles})) {
            if (rect.x + 1 + rect.width > numColumnsOfGrid) {
                rect.x = 0;
                rect.y++;
            } else {
                rect.x++;
            }
        } else {
            let source = sourceTile ? sourceTile : collection.value.gridLocations.find((g) => g.tile.id == tile.key).tile;

            // console.log(removeIdsInTile(source));
            return {
                rect,
                tile: {
                    ...removeIdsInTile(source),
                    title: `${source.title} (Copy)`,
                },
            };
        }
    }
};

export const moveTileToDashboard = ({tiles, tile}) => {
    let rect = {...tile.position, ...tile.size};

    while (true) {
        if (conflictRect({rect, tiles})) {
            if (rect.x + 1 + rect.width > numColumnsOfGrid) {
                rect.x = 0;
                rect.y++;
            } else {
                rect.x++;
            }
        } else {
            return {
                rect,
                tile,
            };
        }
    }
};

export const getEmptyNInsertableRows = (tiles) => {
    if (!tiles || tiles.length == 0) {
        return {
            insertableRows: [],
            emptyRows: [],
        };
    }

    const maxWidth = Math.max(...tiles.map(({colSpan, colStart}) => colStart + colSpan - 1));
    const maxHeight = Math.max(...tiles.map(({rowSpan, rowStart}) => rowStart + rowSpan - 1));

    let grid = Array(maxHeight)
        .fill(0)
        .map(() => Array(maxWidth).fill(0));

    tiles.forEach(({colSpan, colStart, rowSpan, rowStart}, index) => {
        for (let i = rowStart - 1; i < rowStart - 1 + rowSpan; i++) {
            for (let j = colStart - 1; j < colSpan + colStart - 1; j++) {
                grid[i][j] = index + 1;
            }
        }
    });

    const emptyRows = grid.reduce((rows, currRow, index) => {
        if (sum(currRow) == 0) {
            rows.push(index);
        }
        return rows;
    }, []);
    let insertableRows = new Set();
    insertableRows.add(0);

    emptyRows.forEach((r) => {
        insertableRows.add(r);
        insertableRows.add(r + 1);
    });

    for (let i = 0; i < maxHeight; i++) {
        if (insertableRows.has(i)) {
            continue;
        } else {
            let count = 0;
            for (let j = 0; j < maxWidth; j++) {
                if (grid[i - 1][j] && grid[i][j] && grid[i - 1][j] - grid[i][j] == 0) {
                    break;
                } else {
                    count++;
                }
            }

            if (count == maxWidth) {
                insertableRows.add(i);
            }
        }
    }

    return {
        insertableRows: [...insertableRows],
        emptyRows,
    };
};

export const isInsideBox = (pos, boxRef, offset = 50) => {
    const {x, y} = pos;
    return (
        ((boxRef.x <= x && boxRef.x + boxRef.width >= x) || (boxRef.x <= x + offset && boxRef.x + boxRef.width >= x + offset)) &&
        ((boxRef.y <= y && boxRef.y + boxRef.height >= y) || (boxRef.y <= y + offset && boxRef.y + boxRef.height >= y + offset))
    );
};
