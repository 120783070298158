import * as React from "react";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {AddingTileDashboard} from "./adding-tile/adding-tile-dashboard";

export const TilesTab = ({next, panel}) =>
    cs(consumeContext("collection"), ({collection}) =>
        next({
            renderLeftPanel: () => (
                <AddingTileDashboard
                    {...{
                        panel,
                        collection,
                    }}
                />
            ),
        })
    );
