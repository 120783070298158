import React, {Fragment} from "react";
import {consumeContext} from "@common/react/context";
import {cs} from "@common/react/chain-services";
import "./api-collection-docs.scss";
import {keyToArray} from "../../dashboard/env/settings/data-access-key/manage-key/manage-access-key-dialog";
import {UseState} from "@common/react/use-state";
import {cx} from "emotion";
import {Static2} from "@common/react/static-2";
import {RequestFilterObject} from "./request-filter-object";
import {AuthParamsObject} from "./auth-params-object";
import {SampleRequestObject} from "./sample-request-object";

export const ApiCollectionDocs = ({apiDocs}) =>
    cs(
        consumeContext("routing"),
        ({routing}, next) => {
            if (!apiDocs.value) routing.goto("api-collection-login");
            else return next();
        },
        () => {
            const {docs, apiKey} = apiDocs.value;
            return (
                <div className="api-collection-docs">
                    <div>
                        <div className="page-header">
                            <div className="tenant-title">
                                <h2>{docs.tenantName}</h2>
                            </div>
                        </div>
                        <div className="api-header">
                            <div className="api-header-wrapper">
                                <div className="api-title">
                                    <h1>Your API API Key</h1>
                                </div>

                                <div className="api-warning">
                                    Once the API invitation expires you must use this key to access your documentation. Please save this key somewhere safe.
                                </div>

                                <div className="api-header-key">
                                    <pre>
                                        <code>{apiKey}</code>
                                    </pre>
                                </div>
                                <div className="api-header-key-desc">
                                    This key is sent in the <code>x-api-key</code> header of your API requests.
                                </div>
                            </div>
                        </div>

                        {docs.apis.map((api, index) =>
                            ApiSection({
                                api,
                                key: index,
                                apiKey,
                            })
                        )}
                    </div>
                    <div className="footer">
                        Powered by{" "}
                        <a className="verb" href="https://revivemedia.us/" target="_blank">
                            Revive
                        </a>
                    </div>
                </div>
            );
        }
    );

const ApiSection = ({api, apiKey}) =>
    cs(
        ["versions", (_, next) => next(keyToArray(api.versions))],
        [
            "selectedVersion",
            ({versions}, next) =>
                UseState({
                    initValue: versions.sort((a, b) => (parseFloat(a.key) > parseFloat(b.key) ? -1 : 1))[0].key,
                    next,
                }),
        ],
        ["copyElem", (_, next) => Static2({next})],
        [
            "copy",
            ({copyElem}, next) =>
                next(() => {
                    let el = copyElem.get();
                    let range = document.createRange();
                    range.selectNodeContents(el);
                    let selection = window.getSelection();
                    selection.removeAllRanges();
                    selection.addRange(range);
                    document.execCommand("copy");
                    selection.removeAllRanges();
                }),
        ],
        ({versions, selectedVersion, copyElem, copy}) => {
            const versionDisplay = versions.find((v) => v.key == selectedVersion.value).value;
            const hasAuthParams = versionDisplay.request.activeAuthParams && versionDisplay.request.activeAuthParams.length > 0;

            return (
                <>
                    <div className="api-header">
                        <div className="api-title">
                            <h1>{api.name}</h1>
                            <a name="{collection.versionGroupID}" />
                            <span>
                                <select value={selectedVersion.value} onChange={(e) => selectedVersion.onChange(e.target.value)}>
                                    {versions
                                        .sort((a, b) => (parseFloat(a.key) > parseFloat(b.key) ? -1 : 1))
                                        .map((v, index) => (
                                            <option key={index} value={v.key}>
                                                v{v.key}
                                            </option>
                                        ))}
                                </select>
                            </span>
                        </div>
                        <div className="api-header-url">
                            <pre>
                                <code className="action">{versionDisplay.request.httpMethod}</code> <code className="url">{versionDisplay.request.endpoint}</code>
                            </pre>
                        </div>
                    </div>

                    <div className="api-area api-area-expanded">
                        <div className="api-copy">
                            <div className="api-list">
                                <h5 className="api-list-title">Request Headers</h5>
                                <ul className="api-list-group">
                                    <li className="api-list-item">
                                        <h3 className="api-list-item-label">
                                            <span className="api-list-item-label-name">Content-Type</span> <span className="api-list-item-validation">string</span>
                                        </h3>
                                        <div className="api-list-item-description-and-children">
                                            <p className="api-list-item-description">
                                                <span>
                                                    MIME type identifying body as JSON:
                                                    <wbr /> <code>application/json</code>
                                                </span>
                                            </p>
                                        </div>
                                    </li>
                                    <li className="api-list-item">
                                        <h3 className="api-list-item-label">
                                            <span className="api-list-item-label-name">x-api-key</span> <span className="api-list-item-validation">uuid</span>
                                        </h3>
                                        <div className="api-list-item-description-and-children">
                                            <p className="api-list-item-description">
                                                <span>
                                                    API key identifying your user:
                                                    <wbr /> <code>{apiKey}</code>
                                                </span>
                                            </p>
                                        </div>
                                    </li>
                                </ul>

                                {(versionDisplay.request.filters.length > 0 || hasAuthParams) && (
                                    <>
                                        <h5 className="api-list-title">Request Object</h5>
                                        {hasAuthParams &&
                                            AuthParamsObject({
                                                authParams: versionDisplay.request.activeAuthParams,
                                            })}
                                        {versionDisplay.request.filters.map((filter, index) =>
                                            RequestFilterObject({
                                                filter,
                                                key: index,
                                            })
                                        )}
                                    </>
                                )}

                                {versionDisplay.responseAttributes.length > 0 && (
                                    <>
                                        <h5 className="api-list-title" id="subscription_object-attributes">
                                            Response Object Attributes
                                        </h5>
                                        <ul className="api-list-group">
                                            {versionDisplay.responseAttributes.map((att, index) => (
                                                <li className="api-list-item" key={index}>
                                                    <h3 className="api-list-item-label">
                                                        <span className="api-list-item-label-name">{att.name}</span>
                                                        <span className="api-list-item-validation">{att.type}</span>
                                                        {att.displayName && <span className="api-list-item-label-desc"> - {att.displayName}</span>}
                                                    </h3>
                                                </li>
                                            ))}
                                        </ul>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="api-example">
                            <div className="api-example-part">
                                {versionDisplay.request.filters.length > 0 && (
                                    <div className="api-example-response">
                                        <div className="api-example-request-topbar">
                                            <div className="api-example-request-title">
                                                <pre>
                                                    <code>Sample Request Object</code>
                                                </pre>
                                            </div>
                                        </div>
                                        <div className="api-example-request-body">
                                            {SampleRequestObject({
                                                filters: versionDisplay.request.filters,
                                                authParams: versionDisplay.request.activeAuthParams,
                                            })}
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="api-example-part">
                                <div className="api-example-response">
                                    <div className="api-example-response-topbar">
                                        <div className="api-example-response-title">
                                            <pre>
                                                <code>Sample Response Object</code>
                                            </pre>
                                        </div>
                                    </div>
                                    <div className="api-example-response-body">
                                        <div
                                            className="code"
                                            style={{
                                                minHeight: "90px",
                                                maxHeight: "30vh",
                                            }}
                                        >
                                            <div className="code-scroll">
                                                <div className="code-lines">
                                                    <div>1</div>

                                                    {versionDisplay.responseAttributes.map((_, index) => (
                                                        <div key={index}>{index + 2}</div>
                                                    ))}

                                                    {<div>{versionDisplay.responseAttributes.length + 2}</div>}
                                                </div>
                                                <pre className="code-pre language-json">
                                                    <code className="language-json">
                                                        <span className="token punctuation">{"{"}</span>
                                                        {versionDisplay.responseAttributes.map((att, index) => (
                                                            <div
                                                                style={{
                                                                    paddingLeft: 25,
                                                                }}
                                                                key={index}
                                                            >
                                                                <span className="token json-key">"{att.name}"</span>
                                                                <span className="token punctuation">:</span>{" "}
                                                                <span className={cx("token", ["double", "int", "float"].indexOf(att.type) == -1 ? "json-string" : "number")}>
                                                                    {["double", "int", "float"].indexOf(att.type) == -1 ? `"${att.sampleData}"` : att.sampleData}
                                                                </span>
                                                                <span className="token punctuation">,</span>
                                                            </div>
                                                        ))}
                                                        <span className="token punctuation">}</span>
                                                    </code>
                                                </pre>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        }
    );

const CopyButton = () => (
    <svg style={{width: "36px", overflow: "hidden"}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" x="0px" y="0px">
        <path d="M60,44H40a2,2,0,0,0,0,4H60a2,2,0,0,0,0-4Z" />
        <path d="M60,52H40a2,2,0,0,0,0,4H60a2,2,0,0,0,0-4Z" />
        <path d="M52,60H40a2,2,0,0,0,0,4H52a2,2,0,0,0,0-4Z" />
        <path d="M64,32H58a6,6,0,0,0-6-6H48a6,6,0,0,0-6,6H36a6,6,0,0,0-6,6V68a6,6,0,0,0,6,6H64a6,6,0,0,0,6-6V38A6,6,0,0,0,64,32ZM46,32a2,2,0,0,1,2-2h4a2,2,0,0,1,2,2v4H46ZM66,68a2,2,0,0,1-2,2H36a2,2,0,0,1-2-2V38a2,2,0,0,1,2-2h6v2a2,2,0,0,0,2,2H56a2,2,0,0,0,2-2V36h6a2,2,0,0,1,2,2Z" />
    </svg>
);
