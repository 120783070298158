export const getPivotTableSortingAfterDeleteValueUpdated = (tile, attr, field) => {
    if (attr == "valueFields") {
        const index = tile.valueFields.findIndex((f) => f.id == field.id);
        return {
            rowFields: tile.rowFields.map((field) => {
                if (field.aggregateSortIndex === index) return {...field, aggregateSortIndex: null};
                return field;
            }),
            columnFields: tile.columnFields.map((field) => {
                if (field.aggregateSortIndex === index) return {...field, aggregateSortIndex: null};
                return field;
            }),
        };
    }

    return {};
};

export const getPivotTableSortingAfterReorderValueUpdated = (currentTile, updatedTile) => {
    const updatedField = (field) => {
        if (field.aggregateSortIndex === null) return field;
        if (!currentTile.valueFields[field.aggregateSortIndex]) return {...field, aggregateSortIndex: null};

        const updatedIndex = updatedTile.valueFields.findIndex((f) => f.id == currentTile.valueFields[field.aggregateSortIndex].id);
        return {...field, aggregateSortIndex: updatedIndex};
    };

    return {
        rowFields: updatedTile.rowFields.map((field) => updatedField(field)),
        columnFields: updatedTile.columnFields.map((field) => updatedField(field)),
    };
};

export const getPivotTableSortingAfterMoveValueToAnotherSection = (updatedTile, currentIndex) => {
    return {
        rowFields: updatedTile.rowFields.map((field) => {
            if (field.aggregateSortIndex === currentIndex) return {...field, aggregateSortIndex: null};
            return field;
        }),
        columnFields: updatedTile.columnFields.map((field) => {
            if (field.aggregateSortIndex === currentIndex) return {...field, aggregateSortIndex: null};
            return field;
        }),
    };
};
