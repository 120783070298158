import {Expandable} from "../../../../common/expandable/expandable";
import {LpColorLine} from "../../../../common/left-panel/lp-color-line";
import {ffToColorPickerLine} from "../../../../../../../common/form/ff-to-color-picker-line";
import * as React from "react";

export const ButtonStyles = ({form, sectionInitClosed}) => (
    <Expandable
        {...{
            initExpand: !sectionInitClosed,
            label: "Button Styles",
            render: () => (
                <div className="panel-22">
                    {LpColorLine({
                        label: "Button Color",
                        ...ffToColorPickerLine(form.field(["buttonColorRGB"])),
                    })}
                    {LpColorLine({
                        label: "Button Hover Color",
                        ...ffToColorPickerLine(form.field(["buttonHoverColorRGB"])),
                    })}
                    {LpColorLine({
                        label: "Button Font Color",
                        ...ffToColorPickerLine(form.field(["buttonFontColorRGB"])),
                    })}
                </div>
            ),
        }}
    />
);
