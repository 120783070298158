import {Expandable} from "../../../../common/expandable/expandable";
import {FontSizeSelect} from "../../../tile/edit/left-panel/tabs/tile-tab/chart-types/common/font-size-select";
import {ffToDropdown} from "../../../../../../../common/form/ff-to-dropdown";
import {FontOptionsSelect} from "../../../tile/edit/left-panel/tabs/tile-tab/chart-types/table/panels/font-options-select/font-options-select";
import {CaseOptionSelect} from "../case-options/case-options-select";
import * as React from "react";

export const TileTitle = ({form, sectionInitClosed}) => (
    <Expandable
        {...{
            initExpand: !sectionInitClosed,
            label: "Tile Title",
            render: () => (
                <div className="panel-22">
                    {FontSizeSelect({
                        ...ffToDropdown(form.field(["titleFontSize"])),
                        themeFontSizeGroup: form.field(["fontSizeGroup"]).state.value,
                    })}

                    {FontOptionsSelect({
                        label: "Font Options",
                        state: form.field(["titleFontOptions"]).state,
                    })}

                    {CaseOptionSelect({
                        label: "Title Case",
                        state: form.field(["titleCaseOptions"]).state,
                    })}
                </div>
            ),
        }}
    />
);
