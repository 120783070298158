import React from "react";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {Static2} from "@common/react/static-2";
import {cx} from "emotion";
import {IgnoreUpdate} from "@common/react/ignore-update";
import {equalDeep} from "@common/utils/objects";

export const OutputConnection = ({onSelect, transform: rootTransform, isTransformationStep, width, height, css, size, diagram, drawingLine}) =>
    cs(
        // (_, next) => IgnoreUpdate({
        //     props: {rootTransform, css, size, drawingLine},
        //     when: (pp) => equalDeep(rootTransform, pp.rootTransform) && equalDeep(pp.css, css) && size === pp.size && equalDeep(drawingLine.value, pp.drawingLine.value),
        //     next
        // }),
        ["deltaHover", (_, next) => UseState({initValue: 0, next})],
        ({deltaHover}) => {
            const hasDrawingLine = diagram.id == drawingLine.value?.inputStep?.id;
            const delta1 = size + (hasDrawingLine ? 1 : deltaHover.value);

            const transform = isTransformationStep ? {x: rootTransform.x + width / 2, y: rootTransform.y + height} : {x: rootTransform.x, y: rootTransform.y + height};
            return (
                <>
                    <g
                        transform={`translate(${transform.x - delta1}, ${transform.y - delta1})`}
                        className={cx("output-connection-5ui", {
                            "has-drawing-line": hasDrawingLine,
                        })}
                        onMouseOver={() => deltaHover.onChange(1)}
                        onMouseOut={() => deltaHover.onChange(0)}
                    >
                        <rect width={delta1 * 2} height={delta1 * 2} rx={1} style={{cursor: "pointer"}} onClick={() => onSelect({x: transform.x, y: transform.y + 4})} {...css} />
                    </g>
                </>
            );
        }
    );
