const {singleDateRange} = require("../../../../../../../../utils/dates/date-object");
const {chain} = require("../../../../../../../../utils/fs");
const {dateDiff, compares, addDate, ge} = require("../../../../../../../../utils/dates/date-object");
const {conflict} = require("../../../../../../../../utils/ranges");

const avoidRange = (target, self) => {
    if (target.from == null) {
        target = singleDateRange(addDate(target.to, -1));
    } else if (target.to == null) {
        target = singleDateRange(target.from);
    }
    if (!conflict(target, self, compares)) {
        return self;
    }

    return chain(
        (() => {
            const deltaFrom = dateDiff(target.to, self.from);
            const deltaTo = dateDiff(self.to, target.from);

            if (deltaFrom < deltaTo) {
                return {
                    ...self,
                    from: addDate(self.from, deltaFrom),
                };
            } else {
                return {
                    ...self,
                    to: addDate(self.to, -deltaTo),
                };
            }
        })(),
        (range) => (ge(range.from, range.to) ? null : range)
    );
};

const avoidRange2 = (target, self) => {
    if (target.from == null) {
        let d = addDate(target.to, -1)
        target = {from: d, to: d};
    } else if (target.to == null) {
        target = {from: target.from, to: target.from};
    }
    if (!conflict(target, self, compares)) {
        return self;
    }

    return chain(
        (() => {
            const deltaFrom = dateDiff(target.to, self.from);
            const deltaTo = dateDiff(self.to, target.from);

            if (deltaFrom < deltaTo) {
                return {
                    ...self,
                    from: addDate(self.from, deltaFrom),
                };
            } else {
                return {
                    ...self,
                    to: addDate(self.to, -deltaTo),
                };
            }
        })(),
        (range) => (ge(range.from, range.to) ? null : range)
    );
};

exports.avoidRange = avoidRange;

exports.avoidRange2 = avoidRange2;
