import {cs} from "@common/react/chain-services";
import {createArray} from "@common/utils/collections";
import {SkeletonBox} from "../../../../common/skeleton/skeleton-box";

export const SyncStatusSummarySkeletons = ({}) =>
    cs(() => (
        <div className="sync-status-summary-99y">
            <div className="content">
                <div className="card summary">
                    <div className="label">{SkeletonBox({style: {width: `60%`, height: 20}})}</div>
                    <div className="content">{SkeletonBox({style: {width: `70%`, height: 46}})}</div>
                </div>

                {SyncInfoSkeletons()}

                {/*{ SyncLast30Skeletons() }*/}
            </div>
        </div>
    ));

export const SyncLast30Skeletons = () =>
    cs(() => (
        <div className="card">
            <div className="label">{SkeletonBox({style: {width: `60%`, height: 20}})}</div>

            <div
                className="content"
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "end",
                }}
            >
                {createArray(11).map((i) => SkeletonBox({style: {width: 18, height: 70 + i * 6}}))}
            </div>
        </div>
    ));

export const SyncInfoSkeletons = () =>
    cs(() => (
        <>
            <div className="card stats">
                <div className="label">{SkeletonBox({style: {width: `60%`, height: 20}})}</div>
                <div className="content">{SkeletonBox({style: {width: `70%`, height: 46}})}</div>
            </div>
            <div className="card stats">
                <div className="label">{SkeletonBox({style: {width: `60%`, height: 20}})}</div>
                <div className="content">{SkeletonBox({style: {width: `70%`, height: 46}})}</div>
            </div>

            <div className="card">
                <div className="label">{SkeletonBox({style: {width: `60%`, height: 20}})}</div>

                <div
                    className="content"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "end",
                    }}
                >
                    {createArray(11).map((i) => SkeletonBox({style: {width: 18, height: 70 + i * 6}}))}
                </div>
            </div>
        </>
    ));
