const autoMove = {
    init: () => {
        let interval = null;
        let triggerCount = 0;
        return {
            move: (mousePos, endFunc, cb) => {
                clearInterval(interval);
                triggerCount = 0;
                if (!endFunc(mousePos)) {
                    interval = setInterval(() => {
                        if (endFunc(mousePos)) {
                            clearInterval(interval);
                            triggerCount = 0;
                        } else {
                            cb(triggerCount);
                            triggerCount++;
                        }
                    }, 2);
                }
            },
            forceStop: () => {
                clearInterval(interval);
                triggerCount = 0;
            },
        };
    },
};

exports.autoMove = autoMove;
