const {consumeContext} = require("@common/react/context");
const {spc} = require("@common/react/state-path-change");
const {cs} = require("@common/react/chain-services");
const {UseState} = require("@common/react/use-state");
const {UseStaticState} = require("@common/react/static-state");

// initValue: {"name":"column","data":{"columnId":"478c817f-beaf-482a-a084-652cf3f3fad8","tableId":"9f5ed170-390d-4f8c-9fd5-00d697bbf6c9","dsInfo":{"dsTableName":"Account","dsColumnName":"AccountID","dsColumnVisualName":"Account ID","dsColumnDataType":"IDUUID"},"tab":"Blank"},"wideLeftPanel":"column-security"}

const Interactions = ({next, model, dataSources, updatePositionQueue}) =>
    cs(
        consumeContext("routing"),
        ["viewFullWidth", ({}, next) => UseState({next, initValue: false})],
        [
            "setting",
            ({routing}, next) =>
                UseStaticState({
                    next,
                    // setting: {name, data, wideLeftPanel, showShortestPath}
                    getInitValue: () => {
                        if (routing.params.tableID && routing.params.targetTableID) {
                            return {
                                showShortestPath: {
                                    tableID: routing.params.tableID,
                                    targetTableID: routing.params.targetTableID,
                                },
                            };
                        }
                        // if (routing.params.tableID && !routing.params.targetTableID && !routing.params.columnID) {
                        //     return {
                        //         name: "table",
                        //         data: {tableId: routing.params.tableID},
                        //     };
                        // }
                        // if (routing.params.tableID && routing.params.columnID) {
                        //     return {
                        //         name: "column",
                        //         data: {tableId: routing.params.tableID, columnId: routing.params.columnID},
                        //     };
                        // }
                    },
                }),
        ],

        ({setting, viewFullWidth}) => {
            const leftPanelWidths = {
                "manage-relationships": 480,
                "column-settings": 790,
                "column-security": 840,
                "row-level-security": 840,
                "suggest-relationship": 480,
                "create-new-relationship": 500,
                "edit-relationship": 500,
                "all-suggested-relationships": 500,
                "preview-data": 1100,
            };

            return next({
                toggleViewFullWidth: () => viewFullWidth.change((v) => !v),
                isViewFullWidth: viewFullWidth.value,
                selectTable: (table) => {
                    const nameObj = {
                        "column-security": "column-settings",
                        "suggest-relationship": null,
                        "create-new-relationship": null,
                        "edit-relationship": null,
                    };

                    setting.change((c) => ({
                        ...c,
                        data: ["suggest-relationship", "create-new-relationship"].includes(setting.value?.name)
                            ? table
                            : {...setting.data, ...table},
                        name: nameObj.hasOwnProperty(setting.value?.name) ? nameObj[setting.value?.name] : setting.value?.name,
                        timestamp: new Date().getTime(),
                    }));
                },
                deSelectTable: () => {
                    setting.change((v) => ({
                        ...v,
                        name: null,
                        data: null,
                        timestamp: null,
                    }));
                },
                viewAllSuggestedRelationships: (suggestedRelationships) => {
                    setting.onChange({
                        name: "all-suggested-relationships",
                        data: {
                            suggestedRelationships,
                            model,
                        },
                    });
                },
                viewSuggestRelationship: (data) => {
                    setting.onChange({
                        ...setting.value,
                        data,
                        name: "suggest-relationship",
                    });
                },
                selectColumn: ({columnId}) => {
                    setting.onChange({
                        data: {columnId},
                    });
                },
                previewData: (data) => {
                    setting.onChange({
                        data: {...setting.data, ...data, model, dataSources},
                        name: "preview-data",
                    });
                },
                addRelationship: (suggestedRelationship) => {
                    setting.onChange({
                        ...setting.value,
                        data: {
                            ...setting.value.data,
                            suggestedRelationships: setting.value.data.suggestedRelationships.map((s) =>
                                s.timestamp == suggestedRelationship.timestamp
                                    ? {
                                          ...s,
                                          added: true,
                                      }
                                    : s
                            ),
                            model,
                        },
                    });
                },
                createNewRelationship: (data) => {
                    setting.onChange({
                        name: "create-new-relationship",
                        data: data,
                    });
                },
                editRelationship: (data) => {
                    setting.onChange({
                        name: "edit-relationship",
                        data: data,
                    });
                },
                openColumnPanel: (column) => {
                    setting.change((c) => ({
                        ...c,
                        name: "column",
                        data: column,
                        wideLeftPanel: null,
                    }));
                },
                selectRelationship: (relationship) => {
                    setting.change((c) => ({
                        ...c,
                        name: null,
                        data: relationship,
                    }));
                },
                openManageRelationships: () => {
                    spc(setting, ["wideLeftPanel"], () => "manage-relationships");
                },
                openColumnSettings: (data) => {
                    setting.onChange({
                        data: {...setting.data, ...data},
                        name: "column-settings",
                    });
                },
                openColumnSecurity: ({columnId, tab, ...otherInfo}) => {
                    setting.onChange({
                        ...setting.value,
                        data: {
                            ...setting.value?.data,
                            ...otherInfo,
                            columnId,
                            tab,
                            model,
                        },
                        name: "column-security",
                    });
                },
                openRowLevelSecurity: (data) => {
                    setting.onChange({
                        data: {...setting.data, ...data, model, dataSources},
                        name: "row-level-security",
                    });
                },
                onCancel: ({name, toName}) => {
                    if (setting.value?.name === name) {
                        if (toName) {
                            setting.onChange({
                                ...setting.value,
                                name: toName,
                            });
                        } else {
                            setting.change(() => ({name: null, data: null}));
                        }
                        viewFullWidth.onChange(false);
                    }
                },
                upsertTablePosition: (modelId, obj) => {
                    updatePositionQueue.push({
                        modelId,
                        updated: obj,
                    });
                },
                onHideShortestPath: () => {
                    spc(setting, ["showShortestPath"], () => null);
                },
                showShortestPath: ({tableID, targetTableID}) => {
                    setting.onChange({
                        showShortestPath: {tableID, targetTableID},
                    });
                },
                setting: setting.value,
                getStaticSetting: setting.getStaticValue,
                leftPanelWidth: viewFullWidth.value ? window.innerWidth : leftPanelWidths[setting.value?.name] || 320,
            });
        }
    );
exports.Interactions = Interactions;
