import * as React from "react";
import { cs } from "../../../../../../react/chain-services";
import { consumeContext } from "../../../../../../react/context";
import { ButtonGroup } from "../text/selectable-list/button-group/button-group";
import { SingleSelectDropdown } from "../text/selectable-list/single-select-dropdown/single-select-dropdown";
import { RadioButtons } from "./radio-buttons/radio-buttons";

export const BooleanFilter = ({filter, form, next}) => {
    const state = form.field("value").state;
    const v = (state.value ?? [])[0];
    const preview = !v ? "All" : v === "None" ? `${v} (Blanks)` : v;
    return cs(
        consumeContext("theme"),
        ({theme}) => next({
            mobileFilter: {
                label: filter?.label,
                shortPreview: () => preview,
                preview: () => preview
            },
            control: ({size}) => {
                return [
                    (() => {

                        return controlTypes[filter.design || "Dropdown"]({
                            state,
                            isSelected: (d) => (!d && !state.value?.length) || state.value?.includes(d),
                            data: [null, "True", "False", "None"],
                            valueToLabel: (v) => v === null ? "All" : v === "None" ? `${v} (Blanks)` : v,
                            placeholder: filter.hint,
                            theme, size,
                        })
                    })()
                ]
            },
        }),
    );
}

const controlTypes = {
    "Dropdown": (props) => SingleSelectDropdown({...props, className: "boolean-filter-dropdown", enableSearch: false, notPrependingEmptyStringToList: true}),
    "ButtonToggle": (props) => ButtonGroup({...props, isToggle: true}),
    "RadioButton": RadioButtons,
};

// SelectableListChips({
//     list: ["True", "False"],
//     className: cx("selectable-list-19w chips boolean-dropdown", {desktop: size === "desktop"}),
//     ...(() => {
//         const isSelected = (v) => form.field("value").state.value?.includes(v);
//         return {
//             isSelected,
//             onSelect: (v) => isSelected(v) ? (
//                 form.field("value").state.change(list => list.filter((v1) => v1 !== v))
//             ) : (
//                 form.field("value").state.change(list => ([...list || [], v]))
//             ),
//         };
//     })()
// })