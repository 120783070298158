import {Expandable} from "../../../../common/expandable/expandable";
import {TextInput} from "../../../../../../../common/form/text-input/text-input";
import {ffToTextInput} from "../../../../../../../common/form/ff-to-text-input";
import * as React from "react";

export const ThemeName = ({form}) => (
    <Expandable
        {...{
            label: "Theme Name",
            render: () => (
                <div className="panel-22">
                    {TextInput({
                        label: "Name",
                        ...ffToTextInput(form.field(["name"])),
                    })}
                </div>
            ),
        }}
    />
);
