import {cs} from "@common/react/chain-services";
import {Invoke} from "@common/react/invoke";
import {DialogService} from "../../../../common/dialog/dialog-service";
import "./ds-loading-dialog.scss";

export const DSLoadingDialog = ({delay = 3 * 1000, next: rootNext}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    className: "ds-loading-dialog-88o",
                    strong: true,
                    render: ({resolve, reject, args: {content, title, onLoading}}) => ({
                        title,
                        content: next({
                            onLoading,
                            content,
                            resolve,
                            reject,
                        }),
                    }),
                    next: rootNext,
                }),
        ],
        ({modal}) => {
            const {content, onLoading, resolve, reject} = modal;

            return (
                <div className="ds-loading-content-99o">
                    <div className="content">{content}</div>

                    {Invoke({
                        fn: async () => {
                            try {
                                const resp = await onLoading();
                                setTimeout(() => resolve(resp), delay);
                            } catch (e) {
                                reject(e);
                            }
                        },
                    })}
                </div>
            );
        }
    );
