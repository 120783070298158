import {findMaxE, findMaxValue, flatten1} from "@common/utils/collections";

export const extendProportionalFunnel = (H) => {
    H.wrap(H.seriesTypes.pyramid.prototype, "redrawPoints", function (proceed) {
        proceed.apply(this);

        if (!this.options.stageStyle || this.options.stageStyle === "Constant") {
            return;
        }

        if (!this.data?.length) {
            return;
        }

        let topSides = [];

        // base stage is the stage having max value
        const baseStage = findMaxE(this.data, (s) => s.value);
        const baseStageIndex = this.data.indexOf(baseStage);
        // base width is the largest stage width
        const baseWidth = findMaxValue(this.data, (s) => s.graphic.element.getBoundingClientRect().width);

        // calculate the top sides proportionally to the base stage's top side
        for (let i = 0; i < this.data.length; i++) {
            const stage = this.data[i];

            if (!stage.graphic) {
                return;
            }

            // calculate new top width of this stage, which is also the bottom width of the previous stage
            const width = stage.graphic.element.getBoundingClientRect().width;
            const newWidth =
                i === baseStageIndex
                    ? baseWidth // top side of base stage should be of base width length
                    : (baseWidth / baseStage.value) * stage.value;
            const diff = (newWidth - width) / 2;

            // new top side ends of this stage, which is also the bottom side ends of the previous stage
            const {0: topLeft, 1: topRight, 2: bottomRight, 3: bottomLeft} = stage.graphic.pathArray;
            const newTopLeft = [topLeft[0], topLeft[1] - diff, topLeft[2]];
            const newTopRight = [topRight[0], topRight[1] + diff, topRight[2]];

            topSides[i] = [newTopLeft.slice(1), newTopRight.slice(1)]; // slice to trim "M" and "L"
        }

        // update top side and bottom side of stages
        for (let i = 0; i < this.data.length; i++) {
            const stage = this.data[i];

            if (!stage.graphic) {
                return;
            }

            const {0: topLeft, 1: topRight, 2: bottomRight, 3: bottomLeft} = stage.graphic.pathArray.map((pa) => pa.slice(1)); // slice to trim "M" and "L"

            const newTopSide = topSides[i];
            const newBottomSide = topSides[i + 1]; // bottom side of this stage is the top side of next stage

            const newPath = flatten1([
                "M",
                newTopSide?.[0] || topLeft,
                "L",
                newTopSide?.[1] || topRight,
                "L",
                newBottomSide?.[1] || bottomRight,
                "L",
                newBottomSide?.[0] || bottomLeft,
                "Z",
            ]).join(" ");

            stage.graphic.element.setAttribute("d", newPath);
        }
    });
};
