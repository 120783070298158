import {cs} from "@common/react/chain-services";
import "./blanks-message.scss";

export const BlanksMessage = ({value = ""}) =>
    cs(() => (
        <div className="blanks-message-88i">
            <i className="material-icons">info</i>
            <div>{value}</div>
        </div>
    ));
