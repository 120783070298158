export const CircleMarkerIcon = ({stroke, fill = "#919EB0"}) => (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="6" cy="6" r="6" strokeWidth="1" stroke={stroke} fill={fill} />
    </svg>
);

export const SquareMarkerIcon = ({stroke, fill = "#919EB0"}) => (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="12" height="12" strokeWidth="1" stroke={stroke} fill={fill} />
    </svg>
);

export const TriangleMarkerIcon = ({stroke, fill = "#919EB0"}) => (
    <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 0L13.0622 10.5H0.937822L7 0Z" strokeWidth="1" stroke={stroke} fill={fill} />
    </svg>
);

export const TriangleDownMarkerIcon = ({stroke, fill = "#919EB0"}) => (
    <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 11L0.937823 0.499999L13.0622 0.5L7 11Z" strokeWidth="1" stroke={stroke} fill={fill} />
    </svg>
);

// original width and height: 29
// 0.4137 = 12/29
// 2 = 1 / 0.4137
export const DiamondMarkerIcon = ({stroke, fill = "#919EB0"}) => (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.707107 14.1421L14.1421 0.707107L27.5772 14.1421L14.1421 27.5772L0.707107 14.1421Z" transform="scale(0.42)" strokeWidth="2" stroke={stroke} fill={fill} />
    </svg>
);
