import React from "react";
import {cs} from "@common/react/chain-services";
import {scope} from "@common/react/scope";
import {TextInput} from "../../../../../../../common/form/text-input/text-input";
import {UseState} from "@common/react/use-state";
import {consumeContext} from "@common/react/context";
import "./env-details.scss";
import {Form2} from "@common/react/cs-form/form2";
import {required} from "@common/react/cs-form/validators/required";
import {ffToTextInput} from "../../../../../../../common/form/ff-to-text-input";
import {keyed} from "@common/react/keyed";
import {Invoke} from "@common/react/invoke";
import {YesNoToggle} from "../../../../../../../common/form/toggles/yes-no-toggle";
import {ffToShowHideLine} from "../../../../../../../common/form/ff-to-show-hide-line";
import {ConfirmDialog} from "../../../../common/confirm-dialog/confirm-dialog";
import {DeleteEnvDialog} from "./delete-env-dialog";
import {sort} from "@common/utils/collections";
import {Button} from "../../../../../../../common/form/buttons/button/button";
import {BasicPanel} from "@common/ui-components/panel/panel";

export const EnvDetails = () =>
    cs(
        consumeContext("tenant"),
        ({tenant}, next) => (tenant.environments == null || tenant.tenant == null ? "Loading..." : next()),
        consumeContext("routing"),
        consumeContext("apis"),
        [
            "equipoiseEnv",
            ({routing, tenant}, next) =>
                UseState({
                    initValue: tenant.environments.find((e) => e.id === routing.params.envId),
                    next: ({value, onChange}) =>
                        next({
                            value,
                            onChange: (v) => {
                                onChange(v);
                                tenant.updateEnvironment(v);
                            },
                        }),
                }),
        ],
        [
            "env",
            ({equipoiseEnv}, next) =>
                UseState({
                    getInitValue: () => ({
                        value: equipoiseEnv.value,
                        lastUpdatedAt: null,
                    }),
                    next: ({value, onChange}) =>
                        next(
                            scope({
                                value,
                                onChange: (v) => {
                                    // console.log(v)
                                    return onChange({
                                        value: v.value,
                                        lastUpdatedAt: Date.now(),
                                    });
                                },
                            })
                        ),
                }),
        ],
        [
            "form",
            ({env, equipoiseEnv, apis}, next) =>
                Form2({
                    initShowErrors: true,
                    data: scope(env, ["value"]),
                    fields: {
                        name: {
                            transforms: ["trim"],
                            validators: [required],
                            asyncValidators: [
                                {
                                    validate: (name, {data}) =>
                                        apis.tenant.checkEnvironmentName(name, {
                                            envId: data?.id,
                                        }),
                                    getMessage: () => `Existed`,
                                },
                            ],
                            debounce: true,
                        },
                    },
                    onSubmit: async () => {
                        // console.log("submit")
                        const newEnv = await apis.tenant.upsertEnvironment(env.value.value);
                        if (newEnv.id) {
                            env.onChange({...env.value, value: newEnv});
                        }
                    },
                    next,
                }),
        ],
        ["deleteEnvDialog", (_, next) => DeleteEnvDialog({next})],
        [
            "confirmDialog",
            ({tenant: {environments}}, next) =>
                ConfirmDialog({
                    next,
                    title: "Change Production Environment?",
                    body: `There can only be one production environment. Updating this environment will change the ${
                        environments?.find((e) => e.isProd)?.name
                    } so that it's no longer your organization's production environment.`,
                }),
        ],
        consumeContext("toast"),
        ({env, equipoiseEnv, form, tenant: {environments}, confirmDialog, tenant, apis, deleteEnvDialog, routing, toast}) => {
            return (
                <BasicPanel
                    title="Details"
                    className="env-details-6mc"
                    rightControl={
                        <div className="toggle-content">
                            <div className="toggle">
                                {YesNoToggle({
                                    warningBeforeChange: async (value) => {
                                        const isHaveAnotherPRodEnv = environments.find((e) => e.isProd && e.id != env.value.value.id);
                                        if (value && isHaveAnotherPRodEnv) {
                                            const confirm = await confirmDialog.show();
                                            if (confirm) {
                                                const updated = await apis.tenant.upsertEnvironment({
                                                    ...isHaveAnotherPRodEnv,
                                                    isProd: false,
                                                });
                                                tenant.updateEnvironment(updated);
                                                return true;
                                            }

                                            return false;
                                        }
                                        return true;
                                    },
                                    ...ffToShowHideLine(form.field(["isProd"])),
                                })}
                            </div>

                            <div className="name">Production Environment</div>
                        </div>
                    }
                >
                    {() => (
                        <>
                            <div className="form-group">
                                <div className="form-content">
                                    <TextInput
                                        {...{
                                            readOnly: equipoiseEnv.value.readOnly,
                                            label: "Environment name",
                                            ...ffToTextInput(form.field("name")),
                                        }}
                                    />
                                </div>
                            </div>

                            {environments.length > 1 && (
                                <>
                                    <div className="form-group delete-env">
                                        <Button
                                            className="delete-btn"
                                            btnType="danger"
                                            onClick={async () => {
                                                const confirm = await deleteEnvDialog.show({
                                                    env: env.value.value,
                                                });
                                                const envs = sort(
                                                    environments.filter((e) => e.id != env.value.value.id),
                                                    (e) => (e.isProd ? null : e.name)
                                                );

                                                if (confirm) {
                                                    tenant.removeEnvironment(env.value.value);
                                                    routing.goto("dashboard", {
                                                        envId: envs[0].id,
                                                    });
                                                    toast.show(`${env.value.value.name} deleted`);
                                                }
                                            }}
                                        >
                                            Delete Environment
                                        </Button>
                                    </div>
                                </>
                            )}

                            {env.value.lastUpdatedAt &&
                                env.value.value !== equipoiseEnv.value &&
                                form.valid &&
                                cs(keyed(env.value.lastUpdatedAt), () =>
                                    Invoke({
                                        fn: () => {
                                            form.submit();
                                            equipoiseEnv.onChange(env.value.value);
                                        },
                                    })
                                )}
                        </>
                    )}
                </BasicPanel>
            );
        }
    );
