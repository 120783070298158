exports.sampleDataSourcesSummary = [
    {
        id: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
        type: "SqlServer",
        name: "Groupmatics SQL DB",
        tables: 10,
        sizeMB: 222,
        records: 8065868,
        recentRecords: 0,
        status: "Error",
        lastSuccessfulSyncUtc: null,
        lastSyncAttemptUtc: "2020-06-29T23:09:02.711Z",
    },
    {
        id: "3ec1cb6f-20c9-47fa-95b7-efb69cb7be6c",
        type: "File",
        name: "Leagues.xlsx",
        tables: 1,
        sizeMB: 1,
        records: 320636,
        recentRecords: 0,
        status: "Healthy",
        lastSuccessfulSyncUtc: "2020-07-15T21:37:19.115Z",
        lastSyncAttemptUtc: "2020-07-15T21:37:19.115Z",
    },
    {
        id: "076b802f-ef41-4d96-b538-495ca2113bc0",
        type: "GoogleSheets",
        name: "Google Sheets Test",
        tables: 2,
        sizeMB: 1,
        records: 8704226,
        recentRecords: 0,
        status: "Healthy",
        lastSuccessfulSyncUtc: "2020-08-30T02:21:05.6Z",
        lastSyncAttemptUtc: "2020-08-30T02:21:05.6Z",
    },
];

exports.sampleSBDataSourcesSummary = [
    {
        id: "97736ab7-3eb4-4a69-9324-9bd093021ddb",
        type: "SqlServer",
        name: "SB Prod SQL DB",
        tables: 21,
        sizeMB: 21,
        records: 3314162,
        recentRecords: 7102783,
        status: "Healthy",
        lastSuccessfulSyncUtc: "2020-10-07T01:03:33.257Z",
        lastSyncAttemptUtc: "2020-10-07T01:03:33.257Z",
    },
];
