import * as React from "react";

import {cs} from "@common/react/chain-services";
import {ContextualCollectionMenu} from "./left-panel/contextual-collection-menu";
// import {UseState} from "@common/react/use-state";
// import {consumeContext} from "@common/react/context";
// import {Form2} from "@common/react/cs-form/form2";
// import {scope} from "@common/react/scope";
// import {LeftPanelClosable} from "../../../../common/left-panel-closable/left-panel-closable";
// import {SelectTileType} from "../../../../collection/common/select-tile-type/select-tile-type";
// import {tileTypes} from "../../../../collection/common/select-tile-type/tile-types";
// import {Button} from "../../../../../../../common/form/buttons/button/button";

export const ContextualCollectionTab = ({next, collection, panel}) =>
    cs(({changeTile}) =>
        next({
            renderLeftPanel: () => (
                <ContextualCollectionMenu
                    {...{
                        changeTile,
                        panel,
                        collection,
                    }}
                />
            ),
        })
    );
