import "./account-details-dashboard.scss";

import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";

import {AccountSettingsPersonal} from "./personal/account-settings-personal";
import {AccountSettingsLayout} from "./dashboard/account-settings-layout";
import {SdkKeys} from "./dashboard/system-api-keys/sdk-key/sdk-keys";
import {AccountDetailsIntegrations} from "./integrations/account-details-integrations";
import {Organization} from "./organization/organization";
import {consumeContext} from "@common/react/context";
import {getUnauthorizedParamValues} from "../../verb-web/authorization/authorization";

export const AccountDetailsDashboard = ({defaultTab}) =>
    cs(
        consumeContext("auth"),
        [
            "tabs",
            ({auth}, next) => {
                const unauthorizedTabs = getUnauthorizedParamValues({
                    roles: auth.user.roles,
                    routeName: "account-management",
                    param: "setting",
                });

                const tabs = [
                    {
                        name: "personal",
                        label: "Personal",
                        content: AccountSettingsPersonal,
                    },
                    {
                        name: "organization",
                        secondLabel: "Organization",
                        label: "Organization",
                        content: Organization,
                    },
                    // {
                    //     name: "plan",
                    //     secondLabel: "Plan",
                    //     content: Plan,
                    // },
                    // {
                    //     name: "billingInformation",
                    //     label: "Billing Information",
                    //     content: OrgBillingInformation,
                    // },
                    // {
                    //     name: "invoiceHistory",
                    //     label: "Invoice History",
                    //     content: OrgBillingInvoiceHistory,
                    // },
                    {
                        name: "apiKeys",
                        label: "SDK API Keys",
                        content: SdkKeys,
                    },
                    {
                        name: "integrations",
                        label: "Integrations",
                        content: AccountDetailsIntegrations,
                    },
                ];

                return next(tabs.filter((t) => !unauthorizedTabs.includes(t.name)));
            },
        ],
        [
            "tab",
            ({tabs}, next) => {
                let initValue = "personal";

                if (defaultTab && tabs.map((t) => t.name).includes(defaultTab)) {
                    initValue = defaultTab;
                }

                return UseState({initValue, next});
            },
        ],
        ({tabs, tab}) => {
            return AccountSettingsLayout({
                header: "Account Settings",
                activeTab: tab,
                tabs,
            });
        }
    );
