import * as React from "react";
import {cs} from "@common/react/chain-services";
import "./add-theme.scss";
import {consumeContext} from "@common/react/context";
import {UseState} from "@common/react/use-state";
import {equalDeep} from "@common/utils/objects";
import {LeftPanelTabs} from "../../../common/left-panel-tabs/left-panel-tabs";
import {ThemeTab} from "../common/theme-tab/theme-tab";
import {AddControls} from "./add-controls/add-controls";
import {LeaveAddThemeDialog} from "./leave-add-theme-dialog/leave-add-theme-dialog";
import {ThemePreview} from "../common/theme-preview/theme-preview";
import {defaultTheme} from "./default-theme";
import {Form2} from "@common/react/cs-form/form2";
import {themeFormConfig} from "../common/theme-form-config";
import {Layout2} from "../../../common/layout2/layout-2";

export const AddTheme = ({}) =>
    cs(
        consumeContext("apis"),
        consumeContext("routing"),
        consumeContext("collection"),
        ["leaveDialog", (_, next) => LeaveAddThemeDialog({next})],
        [
            "theme",
            (_, next) =>
                cs(["localTheme", ({}, next) => UseState({next})], ({localTheme}) =>
                    next({
                        value: localTheme.value ?? defaultTheme,
                        onChange: (t) => localTheme.onChange(equalDeep(t, defaultTheme) ? null : t),
                        isChanged: localTheme.value != null,
                        reset: () => localTheme.onChange(null),
                    })
                ),
        ],
        [
            "form",
            ({theme, apis, routing}, next) =>
                Form2({
                    ...themeFormConfig({apis}),
                    data: theme,
                    onSubmit: async () => {
                        await apis.collection.upsertTheme(theme.value);
                        routing.goto("edit-collection");
                    },
                    next,
                }),
        ],
        ({leaveDialog, routing, form, theme, collection, apis}) => {
            return (
                <Layout2
                    {...{
                        adding: {
                            label: "Add New Theme",
                            button: {
                                label: "Add Theme",
                                click: form.submit,
                            },
                        },
                        back: {
                            onClick: async () => {
                                if (theme.isChanged) {
                                    const answer = await leaveDialog.show();

                                    if (answer === "discard") {
                                        routing.goto("edit-collection");
                                    } else if (answer === "save") {
                                        form.submit();
                                    } else {
                                        // cancel
                                    }
                                } else {
                                    routing.goto("edit-collection");
                                }
                            },
                            label: "Cancel adding theme",
                        },
                        leftPanel: {
                            content: (
                                <LeftPanelTabs
                                    {...{
                                        tabs: [
                                            {
                                                id: null,
                                                label: "Theme",
                                                render: () =>
                                                    ThemeTab({
                                                        form,
                                                        controls: AddControls({
                                                            save: {
                                                                disabled: !form.looksValid,
                                                                doSave: () => form.submit(),
                                                            },
                                                            reset: {
                                                                disabled: !theme.isChanged,
                                                                doReset: () => theme.reset(),
                                                            },
                                                        }),
                                                    }),
                                            },
                                        ],
                                        selected: {value: null},
                                    }}
                                />
                            ),
                        },
                        syncState: {
                            type: theme.isChanged ? "unsaved" : "saved",
                        },
                        main: ThemePreview({theme: theme.value}),
                    }}
                />
            );
        }
    );
