import {CircleRadius} from "../model-panel";

export const MouseControlHelper = {
    init: () => {
        let interval = null;
        let triggerCount = 0;
        return {
            move: (mousePos, endFunc, cb) => {
                clearInterval(interval);
                triggerCount = 0;
                if (!endFunc(mousePos)) {
                    interval = setInterval(() => {
                        if (endFunc(mousePos)) {
                            clearInterval(interval);
                            triggerCount = 0;
                        } else {
                            cb(triggerCount);
                            triggerCount++;
                        }
                    }, 2);
                } else {
                    cb(triggerCount);
                }
            },
            forceStop: () => {
                clearInterval(interval);
                triggerCount = 0;
            },
        };
    },
    getMoveMousePosition: (transform, {x, y}, {width, height}, scale) => {
        let func = {
            getX: (x) => x,
            getY: (y) => y,
        };

        let sides = [];

        if (x <= (0 - transform.x + CircleRadius) / scale) {
            func.getX = (x, triggerCount) => x - 1 * triggerCount;
            sides.push("left");
        }
        if (x >= (width - transform.x - CircleRadius) / scale) {
            func.getX = (x, triggerCount) => x + 1 * triggerCount;
            sides.push("right");
        }

        if (y <= (0 - transform.y + CircleRadius) / scale) {
            func.getY = (y, triggerCount) => y - 1 * triggerCount;
            sides.push("top");
        }
        if (y >= (height - transform.y - CircleRadius * 2) / scale) {
            func.getY = (y, triggerCount) => y + 1 * triggerCount;
            sides.push("bottom");
        }

        return {
            sides,
            getNextPos: func,
        };
    },
};
