import {cx} from "emotion";
import moment from "moment";
import React from "react";
import {GhostButton} from "@common/form/buttons/ghost-button/ghost-button";
import {CachedLoad} from "@common/react/cached-api/cached-load";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {Load2} from "@common/react/load2";
import {ObserveDomSize} from "@common/react/observe-dom-size";
import {getFieldType} from "@common/ui-components/charts/common/get-field-type";
import {TableFuncs} from "@common/ui-components/charts/table/paged/search/search-columns";
import {SortColumn} from "@common/ui-components/charts/table/paged/sort/sort-column";
import {SortIcon} from "@common/ui-components/charts/table/table/header/header";
import {SearchHeader} from "@common/ui-components/charts/table/table/header/search-header";
import {RefreshSpinnerIcon} from "@common/ui-components/live/live-dashboard/live-grid/auto-refresh-controller";
import "./preview-tab.scss";

const extractColumnsFromStep = (step, map) => {
    return step?.value?.outputColumns?.map((s) => map?.[s?.modelTableID]?.[s?.modelColumnID]).filter((c) => !!c) ?? [];
};

const TRANSFORM_DATA_PREVIEW = "transform-data-preview";

export const PreviewTab = (props) => {
    const {transformation, step, setting, togglePreview, openRightPanel, tablesColumnsMap} = props;

    return cs(
        consumeContext("apis"),
        consumeContext("actionToast"),
        ["headerSize", (_, next) => ObserveDomSize({next})],
        ["search", ({}, next) => TableFuncs({next})],
        [
            "preview",
            ({apis, search, actionToast}, next) => {
                return CachedLoad({
                    keepOutdatedValue: true,
                    getCacheKey: (k) => JSON.stringify(k),
                    key: {
                        apiKey: TRANSFORM_DATA_PREVIEW,
                        apiParams: {
                            tID: transformation.value.id,
                            search: search.tableSearches,
                            stepId: step.value.id,
                        },
                    },
                    fetch: () => apis.transformation.previewStep(transformation.value.id, step.value.id, search.tableSearches),
                    captureException: true,
                    onCached: () => {
                        actionToast.show({
                            text: ` Preview data for ${step.value.name} ready.`,
                            action: {
                                text: "View preview data",
                                onClick: () =>
                                    openRightPanel({
                                        stepId: step.value.id,
                                        tab: setting.tab,
                                    }),
                            },
                            extraData: {
                                stepId: step.value.id,
                            },
                        });
                    },
                    next,
                });
            },
        ],
        ({preview, search, headerSize}) => {
            const {data, lastCachedAt} = preview.value || {};
            const columns = preview?.value ? data?.columns : extractColumnsFromStep(step, tablesColumnsMap);

            const previewTable = () => {
                if (preview.loading && !data) return null;
                return (
                    <table>
                        <thead ref={headerSize.ref}>
                            <tr>
                                {columns.map((c, i) => {
                                    const {columnIndex, sortDirection} = search.tableSearches?.find((s) => s.columnIndex === i) || {
                                        columnIndex: null,
                                        sortDirection: null,
                                    };

                                    return (
                                        <th className="header-a323" key={i}>
                                            <div>
                                                <div className="name" onClick={() => search.sortColumn(i)}>
                                                    {c.name}
                                                    <div className="sort-icon">
                                                        <div className={cx("sort-up", columnIndex == i && sortDirection == "Asc" && "active")}>
                                                            <SortIcon />
                                                        </div>

                                                        <div className={cx("sort-down", columnIndex == i && sortDirection == "Desc" && "active")}>
                                                            <SortIcon />
                                                        </div>
                                                    </div>
                                                </div>

                                                {c.type !== "number" && (
                                                    <div className="actions">
                                                        {SearchHeader({
                                                            search,
                                                            columnName: c.name,
                                                            columnIndex: i,
                                                            containerRef: headerSize.ref,
                                                        })}
                                                    </div>
                                                )}
                                            </div>
                                        </th>
                                    );
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {!preview.loading &&
                                data?.data.map((row, i) => (
                                    <tr key={i}>
                                        {columns.map((c, j) => (
                                            <td key={j} className={getFieldType(c)}>
                                                {row[j]}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                );
            };

            const previewError = () => (
                <div className="preview-error-screen">
                    <div className="icon">
                        <img src={require("../../../common/icons/warning-step-icon.svg")} alt="" />
                    </div>
                    <div className="title">Preview Unavailable</div>
                    <div className="text">
                        {preview.error.statusCode == 500
                            ? preview.error.message
                                  .split("|")
                                  .map((t) => t.trim())
                                  .join(" ")
                            : "Error loading preview."}
                    </div>
                    {data?.data?.StepID && (
                        <div className="redirect-btn" onClick={() => openRightPanel({stepId: data?.data.StepID})}>
                            <button>Go To Invalid Step</button>
                        </div>
                    )}
                </div>
            );

            return (
                <div className="preview-tab-57j">
                    <div className="preview-table">
                        <div className="title">
                            <div className="top-100-rows">Top 100 rows</div>
                            <div className="fetching-box">
                                {preview.loading ? (
                                    <>
                                        <RefreshSpinnerIcon />
                                        <span className="loading-text">Fetching Data...</span>
                                    </>
                                ) : (
                                    <>
                                        Data retrieved at {moment(lastCachedAt).format("hh:mm A")}{" "}
                                        <GhostButton onClick={() => preview.refetch()} className="ghost-btn">
                                            Refresh
                                        </GhostButton>
                                    </>
                                )}
                            </div>
                            <GhostButton
                                iconLeft={
                                    !setting?.showPreview ? (
                                        <i className="fal fa-arrows-h" />
                                    ) : (
                                        <span
                                            className="fa-rotate-by"
                                            style={{
                                                "--fa-rotate-angle": "45deg",
                                                display: "inline-block",
                                            }}
                                        >
                                            <i className="fal fa-compress-alt" />
                                        </span>
                                    )
                                }
                                className="screen-control"
                                onClick={() => togglePreview(!setting?.showPreview)}
                            >
                                {setting?.showPreview ? "Collapse Table" : "Expand Table"}
                            </GhostButton>
                        </div>

                        <div className="table-container">
                            {preview.loading && (
                                <div className="loading-text">
                                    <div>
                                        IT MAY TAKE SEVERAL SECONDS TO FETCH THE DATA. <br />
                                        YOU CAN CLOSE THE PREVIEW AND CHECK BACK SHORTLY.
                                    </div>
                                </div>
                            )}

                            {preview.error?.statusCode ? previewError() : previewTable()}
                        </div>
                    </div>
                </div>
            );
        }
    );
};
