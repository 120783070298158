import * as React from "react";
import {cs} from "../../../react/chain-services";
import {loadTileData} from "../common/load-tile-data";
import {keepOnly} from "../../../utils/objects";
import {getValueFormatter} from "../table-kpi/get-value-formatter";
import {cGetFontSize} from "../common/style-map/font-size";
import "./list-kpi.scss";
import {css, cx} from "emotion";
import {isHttpsUrl} from "../../../utils/strings";
import {tooltipService3, tooltipService4} from "../../../../web-client/src/routes/common/tooltip3/tooltip-service-3";
import {consumeContext} from "../../../react/context";
import {getScrollbarStyle} from "../render-chart/chart-scrolling-style";
import {VerbScrollbar} from "../../verb-scrollbar/verb-scrollbar";
import {IgnoreUpdate} from "../../../react/ignore-update";
import {getTooltipFontSizes} from "../factory/tooltip/shared-tooltip-formatter/get-tooltip-font-sizes";
import {CurrencyConversionWarning, getCurrencyConversion} from "../common/currency-conversion-warning/currency-conversion-warning";
import {loadTileFields} from "../get-field-color";

export const ListKPI = ({tile, tileFilters, loadData, theme, size, defaultData}) =>
    cs(
        consumeContext("sdkStaticUrl"),
        // ["data", (_, next) => next(data)],
        [
            "data",
            (_, next) =>
                defaultData
                    ? next(defaultData)
                    : loadTileData({
                          next,
                          tileFilters,
                          loadData,
                          size,
                          tile,
                          theme,
                          tileKey: JSON.stringify(keepOnly(tile, ["filters", "valueFields"])),
                      }),
        ],
        [
            "tileFields",
            ({}, next) =>
                loadTileFields({
                    next,
                    configs: {
                        tile,
                        measureMultiAttr: ["valueFields"],
                        checkUnique: false,
                    },
                }),
        ],
        consumeContext("selectedTimezone"),
        ({selectedTimezone}, next) =>
            IgnoreUpdate({
                next,
                props: {timezoneId: selectedTimezone?.value},
                when: ({timezoneId}) => timezoneId != selectedTimezone?.value,
            }),
        [
            "formatter",
            ({selectedTimezone}, next) =>
                next((index) => {
                    let fieldID = tile.valueFields[index].id;

                    return getValueFormatter({
                        valueField: tile.valueFields[index],
                        timezone: selectedTimezone?.value,
                        style: {
                            ...tile.style,
                            ...tile.style.tileFields.find((item) => item.tileFieldID == fieldID),
                        },
                        tile,
                    });
                }),
        ],
        [
            "getRowStyle",
            ({}, next) =>
                next((index) => {
                    let fieldID = tile.valueFields[index]?.id;
                    if (!fieldID) return {};
                    return tile.style.tileFields.find((t) => t.tileFieldID == fieldID) || {};
                }),
        ],
        [
            "tooltip",
            (_, next) => {
                const themeMode = theme.dataVisualization.toolTipsAndLegends.tooltipTheme.toLowerCase() || "light";
                return tooltipService4({
                    direction: theme?.tooltipHover?.position?.toLowerCase() || "above",
                    className: `${themeMode}-theme`,
                    tooltipContentStyle: {
                        fontSize: getTooltipFontSizes(theme).small,
                        fontFamily: theme.general.canvas.fontFamily,
                        color: themeMode === "light" ? theme.general.canvas.fontColorRGB || "#546B81" : "#E2E2E2",
                    },
                    next,
                });
            },
        ],
        [
            "controls",
            ({data, tileFields}, next) => {
                return next(
                    <>
                        {CurrencyConversionWarning({
                            theme,
                            currencyConversion: getCurrencyConversion({
                                $type: data.$type,
                                data,
                                tileFields,
                            }),
                        })}
                    </>
                );
            },
        ],
        ({data, formatter, getRowStyle, tooltip, sdkStaticUrl, controls}) => {
            const titleStyle = tile.style.title;
            const style = tile.style;
            const getFontSize = cGetFontSize(theme.general.canvas.fontSize, theme);
            const colors = theme.dataVisualization.dataColorPalettes.kpiColorsRGB;
            const iconColor = colors[style.iconColorApplication?.index || 0];
            const labelColor = colors[style.labelColorApplication?.index || 0];
            const valueColor = colors[style.valueColorApplication?.index || 0];

            return (
                <>
                    <VerbScrollbar
                        className={cx("list-kpi-a33", {
                            "show-tile-title": titleStyle.show,
                        })}
                        style={{
                            fontFamily: `"${theme.dataVisualization.fonts.fontFamily || theme.general.canvas.fontFamily}", sans-serif`,
                        }}
                    >
                        {data.rows.map((item, index) => {
                            const rowStyle = getRowStyle(index);
                            const getIcon = () => {
                                if (rowStyle.showValueIcon) {
                                    const iconObj = JSON.parse(rowStyle.valueIconUrl || "{}");
                                    if (iconObj.icon?.length > 0)
                                        return {
                                            render: () => (
                                                <i
                                                    style={{
                                                        fontSize: `${getFontSize({
                                                            group: tile.style.iconFontSize,
                                                        })}px`,
                                                        color: iconColor,
                                                    }}
                                                    className={cx("fa", iconObj.icon)}
                                                />
                                            ),
                                        };
                                    if (isHttpsUrl(iconObj.url))
                                        return {
                                            render: () => (
                                                <img
                                                    height={`${getFontSize({
                                                        group: tile.style.iconFontSize,
                                                    })}px`}
                                                    src={iconObj.url}
                                                    alt=""
                                                />
                                            ),
                                        };
                                }
                                return null;
                            };

                            const icon = getIcon();

                            const isGood =
                                (rowStyle.positiveGood && (item.change || item.value) > 0) ||
                                (!rowStyle.positiveGood && (item.change || item.value) < 0);
                            const isUnchanged = () =>
                                !rowStyle.conditionalValueFontColor || formatter(index).valueFormatter(Math.abs(item.difference)) === 0;

                            return (
                                <div
                                    className={cx("item-row verb-list-kpi-row", {
                                        "has-icon": !!icon,
                                    })}
                                    key={index}
                                >
                                    {icon && <div className="icon verb-list-kpi-row-icon">{icon.render()}</div>}

                                    <div
                                        className="label verb-list-kpi-row-label"
                                        style={{
                                            fontSize: `${getFontSize({
                                                group: tile.style.labelFontSize,
                                            })}px`,
                                            color: labelColor,
                                            fontFamily: `${
                                                theme.dataVisualization.fonts.fontFamily || theme.general.canvas.fontFamily
                                            }, sans-serif`,
                                        }}
                                    >
                                        {rowStyle.displayText || item.name}

                                        {rowStyle.showInfoIcon && rowStyle.infoIconText?.length > 0 && (
                                            <div
                                                {...{
                                                    className: "info-icon",
                                                    ...tooltip(() => rowStyle.infoIconText),
                                                }}
                                            >
                                                <img
                                                    width="8px"
                                                    src={sdkStaticUrl(
                                                        require("../../../../web-client/src/routes/common/icons/info-icon.svg")
                                                    )}
                                                    alt=""
                                                />
                                            </div>
                                        )}
                                    </div>

                                    <div
                                        className="value verb-list-kpi-row-value"
                                        style={{
                                            fontFamily: `${
                                                theme.dataVisualization.fonts.fontFamily || theme.general.canvas.fontFamily
                                            }, sans-serif`,
                                            fontSize: `${getFontSize({
                                                group: tile.style.valueFontSize || theme.general.canvas.fontSize,
                                                elemType: "label",
                                            })}px`,
                                            color: `${
                                                isUnchanged()
                                                    ? valueColor
                                                    : isGood
                                                    ? theme.dataVisualization.dataColorPalettes.otherColors.conditionalGoodColorRGB ||
                                                      "#18C96E"
                                                    : theme.dataVisualization.dataColorPalettes.otherColors.conditionalBadColorRGB ||
                                                      "#E95A5A"
                                            }`,
                                        }}
                                    >
                                        {formatter(index).valueFormatter(item.value)}

                                        {cs(["condition", (_, next) => next(item.currencyConversionFailures)], ({condition}) => {
                                            return condition ? (
                                                <span
                                                    className={cx("material-icons-round currency-failures")}
                                                    style={{
                                                        color: "#ff5959",
                                                        fontSize: 10,
                                                    }}
                                                >
                                                    priority_high
                                                </span>
                                            ) : null;
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                    </VerbScrollbar>

                    <div className="list-kpi-controls-99o">{controls}</div>
                </>
            );
        }
    );
