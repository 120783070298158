import React from "react";
import {cs} from "@common/react/chain-services";
import "./dragging-new-tile.scss";
import {addingTileSize, gridHeight, numColumnsOfGrid} from "../../../../common/dnd-grid-panel/grid-constants";
import {GlobalMouseMove} from "@common/react/global-mouse-move";
import {spc} from "@common/react/state-path-change";
import {getELoc} from "@common/react/get-e-loc";
import {GlobalMouseUp} from "@common/react/global-mouse-up";
import {consumeContext} from "@common/react/context";
import {getTilePosition, isInsideFiltersArea, searchSpaceToDrop} from "../../../../common/dnd-grid-panel/dnd/moving";
import {autoMove} from "@common/utils/auto-move";
import {autoScrollCanvas} from "../../../../common/dnd-grid-panel/dnd/moving-helper";
import {cx} from "emotion";
import {conflictRect} from "../../../../common/dnd-grid-panel/dnd-common";
import {createPortal} from "react-dom";
import {tileDefaultSizeRules} from "../../../../common/dnd-grid-panel/tile-rule";
const autoScroll = autoMove.init();
export const DraggingNewTile = ({draggingNew, onDrop, blockWidth, spacing, dnd, tiles, maxHeightState, filterDropArea, showFilterDrop, onDropToFilters}) =>
    cs(consumeContext("containerBoxRef"), ({containerBoxRef}) => {
        const {pos, draggingPos, movingFilter, tile} = draggingNew.value;

        const rule = tileDefaultSizeRules[draggingNew.value.tile?.$type];

        const size = movingFilter
            ? {width: 6, height: 2}
            : {
                  width: tile.defaultColSpan ?? rule?.width ?? addingTileSize,
                  height: tile.defaultRowSpan ?? rule?.height ?? addingTileSize,
              };

        const isInvalidPos = (rect) => {
            if (showFilterDrop) {
                if (
                    isInsideFiltersArea({
                        pos,
                        filterAreaRect: filterDropArea.get().getBoundingClientRect(),
                    })
                ) {
                    return false;
                }
            }

            return rect.x < 0 || rect.y < 0 || rect.x + size.width > numColumnsOfGrid;
        };

        return createPortal(
            <>
                <div
                    className={cx(
                        "dragging-new-tile-a32",
                        isInvalidPos({
                            ...draggingPos,
                            width: size.width,
                            height: size.height,
                        }) && "has-error"
                    )}
                    style={{
                        top: pos.y,
                        left: pos.x,
                        width: size.width * blockWidth,
                        height: size.height * (gridHeight + spacing * 2),
                    }}
                />

                {GlobalMouseMove({
                    fn: (e) => {
                        spc(draggingNew, ["pos"], () => getELoc(e));

                        const newTilePos = getTilePosition({
                            tile: {position: {x: 0, y: 0}},
                            blockSize: blockWidth,
                            pos1: dnd.getRootPos(),
                            pos2: getELoc(e),
                            rootPos: dnd.getRootPos(),
                            rootPos0: dnd.getRootPos(),
                            spacing,
                        });

                        spc(draggingNew, ["draggingPos"], () => newTilePos);
                        maxHeightState.change((v) => Math.max(v || 0, newTilePos.y + size.height));
                        const container = containerBoxRef.get();
                        autoScrollCanvas({
                            pos1: dnd.getRootPos(),
                            blockSize: blockWidth,
                            container,
                            e,
                            gridHeight,
                            pos0: dnd.getRootPos(),
                            spacing,
                            tileSize: {width: size.width, height: size.height},
                            autoScroll,
                        });
                    },
                })}

                {GlobalMouseUp({
                    fn: (e) => {
                        autoScroll.forceStop();

                        if (showFilterDrop) {
                            if (
                                isInsideFiltersArea({
                                    pos,
                                    filterAreaRect: filterDropArea.get().getBoundingClientRect(),
                                })
                            ) {
                                onDropToFilters();
                                return;
                            }
                        }

                        const rejectFunc = ({rect}) => conflictRect({rect, tiles});
                        const tilePos = {
                            ...draggingNew.value.draggingPos,
                            width: size.width,
                            height: size.height,
                        };

                        if (rejectFunc?.({rect: tilePos})) {
                            const fuzzyRect = searchSpaceToDrop({
                                isReject: (position) => rejectFunc({rect: position}),
                                position: tilePos,
                                // noResize: true,
                            });
                            if (fuzzyRect) {
                                onDrop(!isInvalidPos(fuzzyRect) ? fuzzyRect : null);
                                return;
                            }
                        }

                        if (!rejectFunc({rect: tilePos})) {
                            onDrop(!isInvalidPos(tilePos) ? tilePos : null);
                            return;
                        }

                        onDrop(null);
                    },
                })}
            </>,
            document.getElementById("dragging-portal")
        );
    });
