import {cs} from "@common/react/chain-services";
import {Dropdown} from "@common/ui-components/dropdown/dropdown";
import {cx} from "emotion";
import {rFieldTypeIcon} from "../../../../../../../../../../../../../common/field-type-icon/get-field-type-icon";
import {SearchInput} from "@common/form/search-input/search-input";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";
import {isMatchText} from "@common/utils/strings";
import React from "react";
import {UseState} from "@common/react/use-state";
import {Static2} from "@common/react/static-2";
import "./table-select-field.scss";

export const TableSelectField = ({useStatic, value = null, columns, label, onChange, className, isBooleanTableField, staticValue, enable = true, highlightPlacementSelect}) =>
    cs(
        ["search", (_, next) => UseState({next, initValue: ""})],
        ["scrollContainerRef", (_, next) => Static2({next})],
        ({}, next) => <div className={cx("table-select-dropdown-a33 column-select column-select-o13", className)}>{next()}</div>,
        ({search, scrollContainerRef}) => {
            const selectedColumn = columns.find((c) => c.modelColumnID == value?.modelColumnID);
            const hasStaticValue = staticValue != null;

            return Dropdown({
                minExpandHeight: 350,
                minWidth: 160,
                expandClassNames: "table-select-expand",
                renderToggle: ({showExpand, showingExpand}) => (
                    <div
                        className={cx("toggle", {
                            expanding: showingExpand,
                            "with-value": value || hasStaticValue || (value === null && highlightPlacementSelect),
                        })}
                    >
                        <div
                            className={cx("display-value", {
                                "use-static": hasStaticValue || (value === null && highlightPlacementSelect),
                            })}
                            onClick={() => showExpand(true)}
                        >
                            {value === null && highlightPlacementSelect && <span>Entire Row</span>}
                            {hasStaticValue && <span>{isBooleanTableField ? staticValue : "Use Static Value"}</span>}
                            {selectedColumn && <>{selectedColumn.displayName}</>}
                        </div>

                        {label && <div className="label">{label}</div>}
                        <i className="fa fa-chevron-down" />
                    </div>
                ),
                renderExpand: ({close}) =>
                    enable && (
                        <div className={cx("list")} style={{width: "100%"}}>
                            <VerbScrollbar maxHeight="250px" className="list-wrapper" ref={scrollContainerRef.set}>
                                {highlightPlacementSelect && (
                                    <div className={cx("item")}>
                                        <div
                                            className={cx("item-wrapper", {
                                                selected: !value,
                                            })}
                                            onClick={() => {
                                                onChange(null);
                                                close();
                                            }}
                                        >
                                            Entire Row
                                        </div>
                                    </div>
                                )}

                                {isBooleanTableField &&
                                    ["True", "False"]
                                        .filter((_) => isMatchText(_, search.value))
                                        .map((_, index) => (
                                            <div
                                                key={index}
                                                className={cx("item")}
                                                style={{
                                                    paddingRight: 0,
                                                }}
                                            >
                                                <div
                                                    className={cx("item-wrapper", {
                                                        selected: staticValue == _,
                                                    })}
                                                    style={{paddingLeft: 30}}
                                                    onClick={() => {
                                                        onChange(_);
                                                        close();
                                                    }}
                                                >
                                                    {_}
                                                </div>
                                            </div>
                                        ))}

                                {columns
                                    .filter((c) => isMatchText(c.displayName, search.value))
                                    .map((column, index) => {
                                        return (
                                            <div key={index} className={cx("item")}>
                                                <div
                                                    className={cx("item-wrapper", {
                                                        selected: column.modelColumnID == value?.modelColumnID,
                                                    })}
                                                    onClick={() => {
                                                        onChange({
                                                            modelColumnID: column.modelColumnID,
                                                            modelTableID: column.modelTableID,
                                                            modelID: column.modelID,
                                                        });
                                                        close();
                                                    }}
                                                >
                                                    {column.displayName}
                                                </div>
                                            </div>
                                        );
                                    })}
                            </VerbScrollbar>

                            {useStatic && !isBooleanTableField && (
                                <div
                                    className={cx("use-static-value", hasStaticValue && "selected")}
                                    onClick={() => {
                                        onChange("STATIC_VALUE");
                                        close();
                                    }}
                                >
                                    Use Static Value
                                </div>
                            )}
                        </div>
                    ),
            });
        }
    );
