import React from "react";
import {cs} from "@common/react/chain-services";
import {CircleRadius} from "../model-panel";
import {Static2} from "@common/react/static-2";
import {Invoke} from "@common/react/invoke";
import {MouseControlHelper} from "./mouse-controls-helper";
import {ModelPanelHelper} from "../../../common/model-panel-helper";
import {UseState} from "@common/react/use-state";
import {UnpublishedIcon} from "../../../../common/icons/unpublished-icon";
import {UseEffect} from "@common/react/use-effect";
import {RAFLoop} from "@common/utils/loop";
import {renderCompInRegistry} from "@common/ui-components/registry/common-registry";
import {equalDeep} from "@common/utils/objects";
import {lighten} from "@common/utils/color-util";
import {cache2} from "@common/utils/cache2";
import {ModelErrorBox} from "../common/model-error-box";
import {DEFAULT_RELATIONSHIP} from "../model-constrants";

export const MCH = MouseControlHelper.init();
const speed = 4;

export const ModelTable = React.memo(
    ({
        table,
        isGrab,
        isWarning,
        container,
        onGrab,
        invalidTables,
        transform,
        scale,
        onMove,
        lastTableGrabPosition,
        onDrop,
        onMovePan,
        interactions,
        dataSources,
        tooltip,
        hoverInteractions,
        autoSuggest,
        relationships,
        errors,
        modelErrorBoxRef,
    }) => {
        // console.log(`re-render table: ${table?.name}`);

        const color = ModelPanelHelper.getTableColor({
            table,
            dataSources,
        });

        const dataSource = (dataSources || []).find((d) => d.id === table.dataSourceID);

        const tableColor = table.deleted ? lighten(color, 90) : color;

        const isHighLighted = ModelPanelHelper.isHighLightTable({
            table,
            hoverInteractions,
            interactions,
            relationships,
            autoSuggest,
        });

        const isHovered = hoverInteractions.value?.tableId == table.id;
        const isHighLighted2 = interactions.setting?.data?.tableId == table.id;

        return cs(
            [
                "dragHandle",
                (_, next) =>
                    next({
                        dragStart: (getLatestProps) => {
                            const {onGrab} = getLatestProps();
                            onGrab();
                        },
                        dragging: (getLatestProps, e) => {
                            const {onMove, transform, container, scale} = getLatestProps();
                            const position = {x: e.x, y: e.y};
                            onMove(position);
                            const {getNextPos} = MouseControlHelper.getMoveMousePosition(transform, {x: e.x, y: e.y}, container, scale);
                            const isStopAutoMove = ({x, y}) =>
                                MouseControlHelper.getMoveMousePosition(transform, {x, y}, container, scale)?.sides?.length == 0;

                            MCH.move(position, isStopAutoMove, (triggerCount) => {
                                const {onMovePan, scale} = getLatestProps();

                                onMovePan({
                                    transform: {
                                        x: getNextPos.getX(transform.x, -triggerCount * speed),
                                        y: getNextPos.getY(transform.y, -triggerCount * speed),
                                    },
                                    tablePos: {
                                        x: getNextPos.getX(position.x, (triggerCount * speed) / scale),
                                        y: getNextPos.getY(position.y, (triggerCount * speed) / scale),
                                    },
                                });
                            });
                        },
                        drop: (getLatestProps) => {
                            MCH.forceStop();
                            const {onDrop, invalidTables, lastTableGrabPosition, table} = getLatestProps();
                            if (invalidTables?.length > 0) {
                                onDrop(lastTableGrabPosition);
                            } else {
                                onDrop(table.position);
                            }
                        },
                    }),
            ],
            ["forceUpdate", ({db}, next) => UseState({next, initValue: 0})],
            ["tableRef", (_, next) => Static2({next})],
            ({dragHandle, tableRef, forceUpdate, dataViewTransforms}) => {
                const isValid = invalidTables.findIndex((id) => id == table.id) == -1;

                const {dragStart, dragging, drop} = dragHandle;

                const tableElem = tableRef.get();

                let transformStr = `translate(0, 0) scale(1)`;

                if (tableElem && scale < 0.3 && (isHovered || isHighLighted2)) {
                    const rect = tableElem?.getBBox();

                    const cx = rect.x + rect.width / 2;
                    const cy = rect.y + rect.height / 2;

                    const newScale = (1 / scale) * 0.3;

                    transformStr = `translate(${(1 - newScale) * cx}, ${(1 - newScale) * cy}) scale(${newScale})`;
                }

                return (
                    <>
                        {tableRef.get() &&
                            Invoke({
                                props: {
                                    invalidTables,
                                    table,
                                    onMove,
                                    onGrab,
                                    onDrop,
                                    lastTableGrabPosition,
                                    transform,
                                    scale,
                                    container,
                                    onMovePan,
                                    interactions,
                                    hoverInteractions,
                                    dataSources,
                                },
                                fn: ({getLatestProps}) => {
                                    d3.select(tableRef.get())
                                        .call(
                                            d3
                                                .drag()
                                                .on("start", (e) => {
                                                    const {table, interactions} = getLatestProps();
                                                    if (!table.id || interactions.setting?.name == "create-new-relationship") return;
                                                    dragStart(getLatestProps, e);
                                                })
                                                .on("drag", (e) => {
                                                    const {table, interactions} = getLatestProps();
                                                    if (!table.id || interactions.setting?.name == "create-new-relationship") return;
                                                    dragging(getLatestProps, e);
                                                })
                                                .on("end", () => {
                                                    const {table, interactions} = getLatestProps();
                                                    if (!table.id || interactions.setting?.name == "create-new-relationship") return;
                                                    drop(getLatestProps);
                                                })
                                        )
                                        .on("click", (e) => {
                                            const {interactions, table, dataSources} = getLatestProps();
                                            if (!dataSources || e.defaultPrevented || !table.id) return;
                                            if (interactions.setting?.name == "create-new-relationship") {
                                                if (interactions.setting.data?.table.id == table.id) {
                                                    interactions.deSelectTable();
                                                } else {
                                                    interactions.createNewRelationship({
                                                        ...interactions.setting.data,
                                                        target: table,
                                                        relationship: {
                                                            ...DEFAULT_RELATIONSHIP,
                                                            ...interactions.setting.data.relationship,
                                                            rightColumnID: null,
                                                        },
                                                    });
                                                }
                                            } else {
                                                if (interactions.setting?.data?.tableId == table.id) {
                                                    interactions.deSelectTable();
                                                } else {
                                                    interactions.selectTable({
                                                        tableId: table.id,
                                                        $type: table.$type,
                                                        dataSourceTableID: table.dataSourceTableID || table.id,
                                                        dataSourceID: table.dataSourceID,
                                                    });
                                                }
                                            }

                                            e.preventDefault();
                                            e.stopPropagation();
                                        })
                                        .on("mouseover", () => {
                                            const {hoverInteractions, table} = getLatestProps();

                                            hoverInteractions.onChange({
                                                tableId: table.id,
                                            });
                                        })
                                        .on("mouseout", (e) => {
                                            const {hoverInteractions} = getLatestProps();
                                            hoverInteractions.onChange(null);
                                        });
                                },
                            })}

                        <g
                            id={table.id}
                            filter={isGrab ? `url(#drop-shadow)` : ""}
                            ref={(elem) => {
                                if (!tableRef.get()) {
                                    tableRef.set(elem);
                                    setTimeout(() => forceUpdate.change((c) => c + 1));
                                }
                            }}
                            transform={transformStr}
                            style={{
                                cursor: !table.id
                                    ? "not-allowed"
                                    : isGrab
                                    ? "grabbing"
                                    : interactions.setting?.name == "create-new-relationship"
                                    ? "pointer"
                                    : "grab",
                            }}
                        >
                            <circle
                                cx={table.position.x}
                                cy={table.position.y}
                                r={CircleRadius}
                                fill={tableColor}
                                strokeWidth={15}
                                stroke={isHovered ? tableColor : "transparent"}
                                strokeOpacity={0.2}
                            />

                            {!table.id && (
                                <text
                                    x={table.position.x}
                                    y={table.position.y}
                                    textAnchor="middle"
                                    fontSize={9}
                                    alignmentBaseline="central"
                                    fill="black"
                                >
                                    Adding
                                </text>
                            )}

                            {table.deleted && dataSource && (
                                <text
                                    x={table.position.x}
                                    y={table.position.y + CircleRadius + 45}
                                    textAnchor="middle"
                                    fontSize={12}
                                    alignmentBaseline="central"
                                    fill="black"
                                    fontStyle="italic"
                                >
                                    {dataSource.name}
                                </text>
                            )}

                            {TableNameText({
                                table,
                                tooltip,
                                isWarning,
                                scale,
                                isHovered: isHovered || isHighLighted2,
                                tableColor,
                            })}

                            {!isValid && (
                                <circle
                                    cx={table.position.x}
                                    cy={table.position.y}
                                    r={CircleRadius * 3}
                                    fill="rgba(212,218,223,.4)"
                                    strokeDasharray="4"
                                    strokeWidth={2}
                                />
                            )}

                            {errors?.length > 0 &&
                                ModelErrorBox({
                                    position: {
                                        x: table.position.x,
                                        y: table.position.y + CircleRadius + 50 + (table.deleted ? 15 : 0),
                                    },
                                    errors,
                                    modelErrorBoxRef,
                                })}
                        </g>
                    </>
                );
            }
        );
    },
    (prevProps, nextProps) => {
        if (!!prevProps.dataSources != !!nextProps.dataSources) return false;
        if (!equalDeep(prevProps.table.position, nextProps.table.position)) return false;
        if (!equalDeep(prevProps.transform, nextProps.transform)) return false;

        const isHovered = nextProps.hoverInteractions.value?.tableId == nextProps.table.id;
        const oldIsHovered = prevProps.hoverInteractions.value?.tableId == prevProps.table.id;

        if (isHovered != oldIsHovered) {
            return false;
        }

        const isHighLighted2 = nextProps.interactions.setting?.data?.tableId == nextProps.table.id;
        const oldIsHighLighted2 = prevProps.interactions.setting?.data?.tableId == prevProps.table.id;

        if (isHighLighted2 != oldIsHighLighted2) {
            return false;
        }

        if (!compareInvalidTableIDs(nextProps.invalidTables, prevProps.invalidTables)) {
            return false;
        }

        if (prevProps.isGrab != nextProps.isGrab) return false;
        if (prevProps.isWarning != nextProps.isWarning) return false;
        if (prevProps.scale != nextProps.scale) return false;
        return true;
    }
);

const compareInvalidTableIDs = (ids, ids2) => {
    const str1 = ids.sort().join(",");
    const str2 = ids2.sort().join(",");
    return str1 === str2;
};

const lightenCache = cache2(lighten, (color, percentage) => `${color}_${percentage}`);

const TableNameText = ({table, isHovered, scale, tooltip, isWarning, tableColor}) => {
    const showAlternativeTableName = scale < 0.9 && isHovered;

    return cs(
        ["textRef", (_, next) => Static2({next})],
        ["state", (_, next) => UseState({next, initValue: {textLength: 0}})],
        [
            "tableState",
            (_, next) => {
                const isView = table.$type === "ViewModelTable";
                const versionDetails = table.versionDetails;
                const isNotPublished = isView && (!versionDetails || versionDetails === "NotPublished");
                const hasRowSecurity = table?.rowLevelSecurity?.Endpoint;
                const hasIcons = isNotPublished || hasRowSecurity || isWarning;
                return next({
                    hasIcons,
                    isNotPublished,
                    hasRowSecurity,
                });
            },
        ],
        [
            "getTextWidth",
            ({state, textRef, tableState}, next) =>
                UseEffect({
                    next,
                    fn: () => {
                        return RAFLoop.addTarget({
                            update: () => {
                                const textElem = textRef.get();

                                let oldState = state.get();
                                let newState = {...oldState};
                                if (tableState.hasIcons && textElem) {
                                    const {textLength} = state.get();
                                    let length = textElem?.getComputedTextLength?.();
                                    if (length && textLength != length) {
                                        newState.textLength = length;
                                    }
                                }

                                if (textElem && showAlternativeTableName) {
                                    const rect = textElem.getBoundingClientRect();

                                    newState.pos = {
                                        top: rect.top,
                                        left: rect.left + rect.width / 2,
                                    };
                                }

                                if (!equalDeep(oldState, newState)) {
                                    state.onChange(newState);
                                }
                            },
                        });
                    },
                    deps: [showAlternativeTableName],
                }),
        ],
        ({textRef, state, tableState}) => {
            const {hasIcons, isNotPublished, hasRowSecurity} = tableState;
            const defaultWidth = 14;
            const defaultPaddingLeft = 5;
            const secondIconWidth = defaultWidth - (isNotPublished ? 0 : defaultPaddingLeft);
            const extraLength = !hasIcons
                ? 0
                : 10 + (isNotPublished ? defaultWidth : 0) + (hasRowSecurity ? secondIconWidth : 0) + (isWarning ? secondIconWidth : 0);
            const {textLength, pos} = state.value;

            const textElem = textRef.get();

            return (
                <>
                    <text
                        ref={textRef.set}
                        x={table.position.x}
                        y={table.position.y + CircleRadius + 20}
                        textAnchor="middle"
                        fontSize={16}
                        alignmentBaseline="central"
                        fill={textLength == 0 || !hasIcons ? "#546B81" : "transparent"}
                    >
                        {table.visualName || table.name}
                    </text>

                    {textElem &&
                        showAlternativeTableName &&
                        pos &&
                        renderCompInRegistry({
                            comp: (
                                <div className="text-item-00b override" style={{...pos, borderColor: lightenCache(tableColor, 80)}}>
                                    {table.visualName || table.name}
                                </div>
                            ),
                        })}

                    {hasIcons && textLength > 0 && (
                        <foreignObject
                            x={table.position.x - (textLength + extraLength) / 2}
                            y={table.position.y + CircleRadius + 10}
                            width={textLength + extraLength}
                            height={20}
                        >
                            <div className="text-item-00b">
                                {table.visualName || table.name}

                                <div className="icons">
                                    {isNotPublished && (
                                        <div
                                            {...{
                                                className: "not-published-icon",
                                                ...tooltip("Never Published"),
                                            }}
                                        >
                                            {UnpublishedIcon({
                                                fillColor: "#546B81",
                                            })}
                                        </div>
                                    )}

                                    {hasRowSecurity ? (
                                        <img
                                            style={{
                                                paddingLeft: isNotPublished ? defaultPaddingLeft : 0,
                                                top: -1,
                                                height: 14,
                                            }}
                                            {...{
                                                ...tooltip("Row-Level Security Configured"),
                                            }}
                                            src={require("./row-level-security-icon.svg")}
                                            alt=""
                                        />
                                    ) : isWarning ? (
                                        <img
                                            style={{
                                                paddingLeft: isNotPublished ? defaultPaddingLeft : 0,
                                                top: -1,
                                            }}
                                            {...{
                                                ...tooltip("Not associated to row-level security"),
                                            }}
                                            src={require("./row-level-security-alert.svg")}
                                            alt=""
                                        />
                                    ) : null}
                                </div>
                            </div>
                        </foreignObject>
                    )}
                </>
            );
        }
    );
};
