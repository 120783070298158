const {sum} = require("../../../utils/collections");
const {chain} = require("../../../utils/fs");

const rawDataManipulator = ({tile, rawData}) => {
    const showCompare = (series) => series.filter((s) => (tile.style?.showCompare === false ? !s.isCompare : true));
    const getCumulativeData = cGetCumulativeData(tile);

    let _rawData = {
        ...rawData,
        series: chain(
            [...rawData.series],
            (_series) => showCompare(_series),
            (_series) => patchEmptyCompareData(_series, tile),
            (_series) => getCumulativeData(_series)
        ),
    };
    // console.log(_rawData);
    // console.log(rawData);
    return _rawData;
};
exports.rawDataManipulator = rawDataManipulator;

const cGetCumulativeData = (tile) => {
    // only allow cumulative when dimension is datetime and tile has no limit
    const allowCumulative = ["DateTime", "DateTimeOffset"].includes((tile.xAxisField || tile.yAxisField).dataType) && !tile.limit;

    if (!allowCumulative) {
        return (series) => series;
    }

    const measureFieldsCumulativeMap = (() => {
        const getMap = (measureAxisStyleObjs) => {
            let ret = {};
            Object.keys(measureAxisStyleObjs).forEach((k) => (ret[k] = measureAxisStyleObjs[k].cumulative));
            return ret;
        };

        if (["VerticalBarChartTile", "LineChartTile", "SparkLineKPITile"].includes(tile.$type)) {
            return getMap(tile.style.yAxis);
        }

        if (tile.$type === "HorizontalBarChartTile") {
            return getMap(tile.style.xAxis);
        }

        if (tile.$type === "ComboChartTile") {
            return getMap({...tile.style.yAxisBar, ...tile.style.yAxisLine});
        }
    })();

    return (series) => {
        return series.map((s) => {
            if (measureFieldsCumulativeMap[s.stack]) {
                return {
                    ...s,
                    isCumulative: true, // prop "cumulative" is already used by highcharts since v. 9.3.0
                    data: s.data.map((dp, i) => [dp[0], sum(s.data.slice(0, i + 1).map((dp) => dp[1] || 0))]),
                };
            }
            return s;
        });
    };
};

const patchEmptyCompareData = (series, tile) => {
    if (tile.$type === "LineChartTile") {
        return series;
    }

    if ((tile.style.yAxisBarDisplayType || tile.style.displayType) !== "Classic") {
        // stacking mode works fine with empty compare data
        return series;
    }

    if (!series.find((s) => s.isCompare && s.data.length === 0)) {
        return series;
    }

    return series.map((s) => {
        if (!s.isCompare) {
            return s;
        }
        if (s.data.length === 0) {
            return {...s, data: [[null, null]], isEmptyData: true};
        }
    });
};

// const fakeNegativeValues = (series) => {
//     return series.map((s, si) => {
//         return {...s, data: s.data.map((d, i) => ([d[0], i % 2 === 0 ? d[1] : d[1]*(-1)]))}
//     });
// };

// const fakeEmptyCompareData = (series) => {
//     return series.map((s) => {
//         if (!s.isCompare) {
//             return s;
//         }
//         return {...s, data: []}
//     })
// };
