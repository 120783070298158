import {dataSourceTypes} from "../common/data-source-type";

export const syncScheduleDefault = {
    ChangeDataCapture: {
        $type: "SyncSchedule",
        interval: "Minutes",
        every: 10,
        onDays: "All",
        duringHours: "All",
    },
    ChangeTracking: {
        $type: "SyncSchedule",
        interval: "Minutes",
        every: 10,
        onDays: "All",
        duringHours: "All",
    },
    Incremental: {
        $type: "SyncSchedule",
        interval: "Minutes",
        every: 15,
        onDays: "All",
        duringHours: "All",
    },
    Full: {
        $type: "SyncSchedule",
        interval: "Hours",
        every: 12,
        onDays: "All",
        duringHours: "All",
    },
};

export const syncScheduleFileDefault = {
    Full: {
        $type: "OneTimeSync",
    },
};

export const modifiedRawTables = ({tables, type}) =>
    tables.map((table) => {
        let syncMethod = table.cdcAvailable
            ? "ChangeDataCapture"
            : table.ctAvailable
            ? "ChangeTracking"
            : [dataSourceTypes.GOOGLE_SHEETS, dataSourceTypes.FILE].indexOf(type) > -1
            ? "Full"
            : table.syncMethod;
        return {
            ...table,
            syncMethod: syncMethod,
            syncSchedule: [dataSourceTypes.FILE || dataSourceTypes.GOOGLE_SHEETS].includes(type)
                ? syncScheduleFileDefault[syncMethod]
                : syncScheduleDefault[syncMethod] ?? {
                      ...table.syncSchedule,
                      onDays: "All",
                      duringHours: "All",
                  },
            columns: table.columns.map((column) => ({
                ...column,
                displayColumnReferenceName: column.name,
                dataTypeProperties: ["Text", "DateTime", "DateTimeOffset"].indexOf(column.dataType) > -1 ? (column.dataType === "Text" ? "NonPII" : "UTC") : "None",
            })),
            disabledColumns: [],
        };
    });
