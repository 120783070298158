import React from "react";
import {cs} from "@common/react/chain-services";
import {TextInput} from "../../../../../../../../../common/form/text-input/text-input";
import {ffToTextInput} from "../../../../../../../../../common/form/ff-to-text-input";
import {Form2} from "@common/react/cs-form/form2";
import {ConnectionSettingsLayout} from "../connection-settings-layout";
import {tooltipService3} from "../../../../../../common/tooltip3/tooltip-service-3";
import {Button} from "../../../../../../../../../common/form/buttons/button/button";
import {DsFolderField} from "../../../../../common/folders-dialog/ds-folder-field";
import {cx} from "emotion";
const {required} = require("@common/react/cs-form/validators/required");

export const FirestoreConnectionForm = ({data, adding, dsApi, connectionStatus, formConfig, next}) =>
    cs(
        [
            "form",
            ({state, databaseConfig}, next) =>
                Form2({
                    data,
                    fields: {
                        ...formConfig,
                        "connectionDetails.privateKeyJson": [required],
                    },
                    next,
                }),
        ],
        [
            "testConnection",
            ({form}, next) =>
                next(() => {
                    const _connectionDetails = form.data.value?.connectionDetails;
                    const databaseConfigData = {
                        $type: data.value.$type,
                        ..._connectionDetails,
                    };

                    return dsApi.testConnection(
                        {
                            submitConnectionDetails: databaseConfigData,
                            connectionDetails: databaseConfigData,
                        },
                        {
                            type: data.value?.type,
                            name: form.data.value?.name,
                        }
                    );
                }),
        ],
        ({form, testConnection}) => {
            return next({
                valid: form.valid,
                beforeGoNext: testConnection,
                render: () =>
                    ConnectionSettingsLayout({
                        type: data.value.type,
                        adding,
                        connectionStatus,
                        controls: {
                            valid: form.valid,
                            onTestConnection: testConnection,
                            onDiscard: () =>
                                form.data.onChange({
                                    ...data.value,
                                    connectionDetails: {},
                                }),
                            onGoNext: dsApi.onNextStep,
                        },
                        content: (
                            <>
                                {TextInput({
                                    label: "Display Name",
                                    ...ffToTextInput(form.field("name")),
                                })}

                                {DsFolderField({
                                    state: form.field("folderID").state,
                                })}

                                <div className="form-group-flex">
                                    {TextInput({
                                        label: "Private Key Json",
                                        placeholder:
                                            '{\n  "type": "service_account",\n  "project_id": "...",\n  "private_key_id": "...",\n  "private_key": "...",\n  "client_email": "...",\n  "client_id": "...",\n  "auth_uri": "https://accounts.google.com/o/oauth2/auth",\n  "token_uri": "https://oauth2.googleapis.com/token",\n  "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",\n  "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/..."\n}',
                                        multiline: true,
                                        rows: 12,
                                        ...ffToTextInput(form.field("connectionDetails.privateKeyJson")),
                                    })}
                                </div>

                                {/*<div className="buttons">*/}
                                {/*    {cs(*/}
                                {/*        tooltipService3({*/}
                                {/*            direction: "above",*/}
                                {/*            tooltipContentStyle: {*/}
                                {/*                width: 150*/}
                                {/*            }*/}
                                {/*        }),*/}
                                {/*        ({tooltip}) => (*/}
                                {/*            <Button*/}
                                {/*                btnType="secondary"*/}
                                {/*                size="small"*/}
                                {/*                {...!form.valid ? tooltip(() => `All required fields must be filled before testing the connection`) : {}}*/}
                                {/*                className={cx({disabled: !form.valid    })}*/}
                                {/*                onClick={() => form.valid && testConnection()}*/}
                                {/*            >Test Connection</Button>*/}
                                {/*        )*/}
                                {/*    )}*/}
                                {/*    */}
                                {/*    <Button*/}
                                {/*        btnType="danger"*/}
                                {/*        size="small"*/}
                                {/*        onClick={() => form.data.onChange({ ...data.value, connectionDetails: {} })}*/}
                                {/*    >*/}
                                {/*        Discard Database</Button>*/}
                                {/*</div>*/}
                            </>
                        ),
                    }),

                // <div className="firestore-connection-form data-source-configuration-form-5rq">
                //     <div className="col-left">
                //         <div className="header">Data Source Details</div>

                //         {TextInput({
                //             label: "Display Name",
                //             ...ffToTextInput(form.field("name"))
                //         })}

                //         <div className="header">Firestore Data Set Details</div>

                //         <div className="form-group-flex">
                //             {TextInput({
                //                 label: "Private Key Json",
                //                 placeholder: "{\n  \"type\": \"service_account\",\n  \"project_id\": \"...\",\n  \"private_key_id\": \"...\",\n  \"private_key\": \"...\",\n  \"client_email\": \"...\",\n  \"client_id\": \"...\",\n  \"auth_uri\": \"https://accounts.google.com/o/oauth2/auth\",\n  \"token_uri\": \"https://oauth2.googleapis.com/token\",\n  \"auth_provider_x509_cert_url\": \"https://www.googleapis.com/oauth2/v1/certs\",\n  \"client_x509_cert_url\": \"https://www.googleapis.com/robot/v1/metadata/x509/...\"\n}",
                //                 multiline: true,
                //                 rows: 12,
                //                 ...ffToTextInput(form.field("connectionDetails.privateKeyJson"))
                //             })}
                //         </div>
                //     </div>

                //     <div className="col-right">
                //         <div className="section information">
                //             <div className="text-header">Information</div>
                //         </div>

                //         <div className="section getting-connected">
                //             <div className="text-header active">
                //                 Getting Connected
                //             </div>

                //             <div className="content">
                //                 <div>
                //                     Provide the details and credentials for the data source you're trying to connect to.
                //                     <p>Verb will query the schema and give you the option to include/exclude data like tables, columns and rows.</p>
                //                     <p>All of the fields are required unless explicitly noted.</p>
                //                 </div>
                //             </div>
                //         </div>
                //     </div>
                // </div>
            });
        }
    );
