//TODO: update template
exports.boxPlotTemplate = {
    $type: "BoxPlotTile",
    style: {
        $type: "BoxPlotTileStyle",
        xAxis: {
            // $type: "BoxPlotDimensionAxisStyle",
            // labelShown: true,
            range: "AutoFromData",
            // label: use field name
            gridStepType: "Count",
            gridStepLabelsShown: true,
            gridStepLabelOrientation: "Auto",
            lineShown: true,
            location: "LeftTop",
            marker: "None",
            gridLinesShown: false,
        },
        yAxis: {
            $type: "BoxPlotMeasureAxisStyle",
            // labelShown: true,
            range: "AutoFromData",
            // label: use field name
            gridStepType: "Count",
            gridStepLabelsShown: true,
            gridStepLabelOrientation: "Auto",
            lineShown: true,
            location: "LeftTop",
            marker: "None",
            gridLinesShown: false,
        },
        //     yAxisBarDisplayType: "Classic",
        //     yAxisBarDataLabels: {
        //         $type: "DataLabelsTileStyle",
        //         show: false,
        //         position: "OutsideAbove",
        //         fontSize: null,
        //         allowOverlap: false,
        //     },
        //     yAxisLineDataLabels: {
        //         $type: "DataLabelsTileStyle",
        //         show: false,
        //         position: "OutsideAbove",
        //         fontSize: null,
        //         allowOverlap: false,
        //     },
        legendStyle: {
            legendShown: false,
            legendPosition: "Auto",
            legendTitleShown: false,
            legendTitle: "Legend",
        },
        title: {
            show: true,
            titleLocation: "Above",
        },
    },
};
