const {min, minBy, quadraticEquations} = require("../../../../../../../common/utils/math-util");

const linearEquations = ({a, b, c, d}) => {
    return {
        a: b - d,
        b: c - a,
        c: d * a - c * b,
    };
};

const getDistance = ({source, target}) => Math.sqrt((target.x - source.x) * (target.x - source.x) + (target.y - source.y) * (target.y - source.y));

const CreateNewRelationshipHelper = {
    getPointWhenHoveringTable: ({source, target}) => {
        const distance = getDistance({source, target});

        const x1 = source.x;
        const y1 = source.y;
        const x2 = target.x;
        const y2 = target.y;

        if (y1 == y2) {
            return min(
                [
                    {x: x1 + distance - 10, y: y1},
                    {x: x1 - (distance - 10), y: y1},
                ],
                (source) => getDistance({source, target})
            );
        }

        const R = distance - 10;

        const {a, b, c} = linearEquations({a: x1, b: y1, c: x2, d: y2});

        const C = x1 * x1 + y1 * y1 - R * R;

        const value = (c * c) / (a * a) + (2 * c * x1) / a + C;

        const A = (b * b) / (a * a) + 1;

        const B = (2 * b * c) / (a * a) + (2 * b * x1) / a - 2 * y1;

        const [v1y, v2y] = quadraticEquations(A, B, value);

        const v1x = (-c - b * v1y) / a;
        const v2x = (-c - b * v2y) / a;

        return min(
            [
                {x: v1x, y: v1y},
                {x: v2x, y: v2y},
            ],
            (source) => getDistance({source, target})
        );
    },
};

exports.CreateNewRelationshipHelper = CreateNewRelationshipHelper;
