const createDownloadTileData =
    ({tileId, collectionId, apis, apiKey}) =>
    ({filters, format, timeZoneId}) =>
        apis.collection.downloadTileData({
            timeZoneId,
            tileId,
            colId: collectionId,
            filterValues: filters,
            format,
            apiKey,
        });
exports.createDownloadTileData = createDownloadTileData;
