import "./guest-layout.scss";

import {cx} from "emotion";
import * as React from "react";

import {keyed} from "@common/react/keyed";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";

import {Link} from "../../../common/routing/link";
import {TextInput} from "../../../../../../common/form/text-input/text-input";
import {ffToBasicInput} from "../../../../../../common/form/ff-to-basic-input";

// import {Button} from "../../../../../../common/form/buttons/button/button";
// import {GhostButton} from "../../../../../../common/form/buttons/ghost-button/ghost-button";
// import {ButtonIcon} from "../../../../../../common/form/buttons/button-icon/button-icon";
// import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";

export const GuestLayout = ({
    header,
    smallHeader = false,
    className,
    form,
    error,
    message,
    link,
    onSubmit,
    submitBtn,
    otherButton,
    pageDescription,
    rightLabel = null,
    rightContent = null,
    policyText = null,
    otherLink,
    adjustPadding,
    renderOverride,
}) =>
    cs(consumeContext("routeTransitioning"), ({routeTransitioning}) => {
        const onKeyDown = (e) => {
            if (e.key === "Enter") {
                onSubmit();
            }
        };

        return (
            <div className={cx("guest-layout-7d3", className, {transitioning: routeTransitioning?.transitioning}, routeTransitioning?.transitioning)}>
                <div className="decoration">
                    <img src={require("./background.svg")} />
                </div>

                <div className="header">
                    <div className="container">
                        <div className="logo"><img src="/logo-white.png" alt="Revive"></img></div>
                        <div className="right">
                            <a href="https://www.revivemedia.us/" target="_blank">
                                Talk to an expert
                            </a>
                        </div>
                    </div>
                </div>

                <div
                    className={cx("container", {
                        "has-right-side": rightLabel || rightContent,
                    })}
                >
                    <div className="left-side">
                        {renderOverride ? (
                            <div
                                className={cx("box override", {
                                    "adjust-padding": adjustPadding,
                                })}
                            >
                                {renderOverride({})}
                            </div>
                        ) : (
                            <div
                                className={cx("box", {
                                    "adjust-padding": adjustPadding,
                                })}
                            >
                                <div className={cx("header", smallHeader && "small")}>{header}</div>

                                {pageDescription && <div className="page-description">{pageDescription}</div>}

                                {error ? <div className="error">{error}</div> : message && <div className="message">{message}</div>}

                                {form?.length > 0 && (
                                    <div className="form">
                                        {form?.map((control, i) =>
                                            cs(keyed(i), () =>
                                                TextInput({
                                                    label: control.label,
                                                    ...ffToBasicInput(control.field),
                                                    // state: control.field.state,
                                                    type: control.type,
                                                    disabled: control.disabled,
                                                    subText: control.subText,
                                                    onKeyDown,
                                                    info: control.info,
                                                })
                                            )
                                        )}
                                    </div>
                                )}

                                <div className="links">{link && <Link to={link.to}>{link.label}</Link>}</div>

                                {onSubmit && (
                                    <div className="controls">
                                        <button disabled={submitBtn.disabled} onClick={onSubmit}>
                                            {submitBtn.label}
                                        </button>

                                        {otherButton}
                                    </div>
                                )}

                                {policyText && <div className="policy">{policyText}</div>}
                            </div>
                        )}

                        <div className="links">
                            {otherLink && (
                                <>
                                    <span className="text">{otherLink.text}</span>
                                    &nbsp;
                                    <a href={otherLink.to}>{otherLink.label}</a>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="right-side">
                        {(rightLabel || rightContent) && (
                            <div className="section">
                                <div className="box-content">
                                    <div className="box-boundary">
                                        <div className="label">{rightLabel}</div>
                                        {rightContent}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    });

// const Test = ({}) => cs(
//     ["count", (_, next) => UseState({initValue: 0, next})],
//     ({count}) => (
//         <div className="">
//             <div className="">
//                 {count.value}
//             </div>
//
//             {cs(
//                 ["proxy", ({}, next) => DownwardProxy2({
//                     allow: () => false,
//                     value: count.value,
//                     next,
//                 })],
//                 ({proxy}, next) => fragments(
//                     next(),
//                     proxy.value !== proxy.rawValue && (
//                         <div className="">
//                             <button onClick={() => proxy.flush()}>Clear</button>
//                         </div>
//                     ),
//                 ),
//                 ["value", ({proxy}, next) => KeyedTransition({
//                     active: proxy.value !== proxy.rawValue,
//                     renderOld: () => next(proxy.value),
//                     renderNew: () => next(proxy.rawValue),
//                 })],
//                 ["random", (_, next) => UseState({getInitValue: () => Math.floor(Math.random() * 100), next})],
//                 ({value, random}) => (
//                     <div className="">
//                         [{random.value}]: {value}
//                     </div>
//                 )
//             )}
//
//             <button
//                 onClick={() => count.change(v=>v+1)}
//             >Plus</button>
//         </div>
//     ),
// );
