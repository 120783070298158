import React from "react";
import "./and-config.scss";
import {DropdownSelect2} from "@common/ui-components/dropdown-select/dropdown-select2";
import {cs} from "@common/react/chain-services";
import {DebounceTextInput} from "../../../../../../../../../common/form/debounce-input/debounce-text-input";
import {TrashIcon} from "../../../../../../common/icons/trash-icon";
import {OrConfig} from "./or-config";
import {ffToDropdown} from "../../../../../../../../../common/form/ff-to-dropdown";
import {spc} from "@common/react/state-path-change";
import {last, removeIndex} from "@common/utils/collections";
import {getPath} from "@common/utils/arr-path";
import {cx} from "emotion";
import {ffToBasicInput} from "../../../../../../../../../common/form/ff-to-basic-input";
import {keyed} from "@common/react/keyed";
import {rColumnValueDrillingSelectFromStepOutput} from "../../../../common/column-value-drilling-select-from-step-output/column-value-drilling-select-from-step-output";
import {rColumnValueSelectFromStepOutput} from "../../../../common/column-value-select-from-step-output/column-value-select-from-step-output";
import {keepOnly} from "@common/utils/objects";
import {rColumnSelectFromStepOutput} from "../../../../common/column-select-from-step-output/column-select-from-step-output";
import {Button} from "../../../../../../../../../common/form/buttons/button/button";
import {cGetFieldInfo} from "../../../../../../collection/common/field-info/get-field-info";

export const AndConfig = ({step, form, parentPath, path, onRemove, columns, model}) => {
    const getFieldInfo = cGetFieldInfo(model);
    const andConfig = getPath(step.value, path);
    const thisAndConfigIndex = last(path);
    const isInside = path.filter((n) => n?.toString().includes("andConfigs")).length > 1;

    const comparisonSelect = ({dataType, ...props}) =>
        DropdownSelect2({
            label: "Match Criteria",
            list: [
                "Equals",
                "Not Equals",
                ...(["Text", "Bool"].includes(dataType) ? [] : ["Less Than", "Less Than Or Equals", "Greater Than", "Greater Than Or Equals"]),
                "Is Null",
                "Is Null Or Default",
                "Is Not Null",
                "Is Not Null Or Default",
                ...(["Text"].includes(dataType) ? ["Contains", "Does Not Contain"] : []),
            ].map((label) => ({
                label,
                value: label === "Does Not Contain" ? "NotContains" : label.replace(/ /g, ""),
            })),
            valueToLabel: (v) => v.label,
            ...props,
        });

    const columnValueDrillingSelect = rColumnValueDrillingSelectFromStepOutput({
        columns,
        model,
        useColumn: true,
    });

    const columnValueDrillingSelect2 = rColumnValueDrillingSelectFromStepOutput({
        columns,
        model,
        useStatic: true,
    });

    const columnsFromSelectedChildTable = (() => {
        if (!parentPath) {
            return null;
        }
        const selectedChildTable = model.tables.find((t) => t.id === getPath(step.value, [...parentPath, "left"])?.modelTableID);
        // fake output columns from selected child table's column
        return selectedChildTable?.columns.map((c) => ({
            $type: "ModelStepColumn",
            modelID: model.id,
            modelTableID: selectedChildTable.id,
            modelColumnID: c.id,
        }));
    })();

    const columnSelectFromChildTable = rColumnSelectFromStepOutput({
        label: "Column/Value",
        columns: columnsFromSelectedChildTable,
    });

    const nonCollectionColumnValueSelectFromChildTable = rColumnValueSelectFromStepOutput({
        label: "Column/Value",
        columns: columnsFromSelectedChildTable,
    });

    const nonCollectionColumnValueSelect = rColumnValueSelectFromStepOutput({
        label: "Column/Value",
        columns,
    });

    const pathForForm = path.map((n) => (!isNaN(n) ? n : n.replace("!arr", "")));

    const isColFromNestedTable = (() => {
        const isFromOutputColumns = (modelColumnId) =>
            columns
                .map((c) => c.modelColumnID)
                .filter((c) => c)
                .includes(modelColumnId);
        return andConfig?.right?.config?.left && !isFromOutputColumns(andConfig.right.config.left.modelColumnID);
    })();

    return (
        <div
            className={cx("and-config-68s", {
                "has-border": thisAndConfigIndex > 0,
                "is-inside": isInside,
                "is-col-from-nested-table": isColFromNestedTable,
            })}
        >
            <div className="fields">
                {(() => {
                    const dropdownProps = ffToDropdown(form.field([...pathForForm, "left"]));
                    if (isInside) {
                        return columnSelectFromChildTable({
                            state: {
                                value: dropdownProps.value,
                                onChange: dropdownProps.onChange,
                            },
                            ...keepOnly(dropdownProps, ["hasError", "errorMessage", "domRef"]),
                        });
                    }
                    const nestedDropdownProps = ffToDropdown(form.field([...pathForForm, "right", "config", "left"]));
                    return columnValueDrillingSelect({
                        state: {
                            value: isColFromNestedTable ? nestedDropdownProps.value : dropdownProps.value,
                            onChange: (v) => {
                                const isFromNestedTable =
                                    v?.$type === "ModelStepColumn" &&
                                    !columns
                                        .map((c) => c.modelTableID)
                                        .filter((c) => c)
                                        .includes(v?.modelTableID);
                                spc(
                                    step,
                                    path,
                                    () =>
                                        v && {
                                            $type: v.$type === "ModelTableValue" || isFromNestedTable ? "CollectionFilterConfig" : "ColumnFilterConfig",
                                            left: isFromNestedTable
                                                ? {
                                                      $type: "ModelTableValue",
                                                      ...keepOnly(v, ["modelID", "modelTableID"]),
                                                  }
                                                : v,
                                            ...(isFromNestedTable && {
                                                right: {
                                                    $type: "AllAnyValue",
                                                    operator: "All",
                                                    config: {
                                                        left: v,
                                                    },
                                                },
                                            }),
                                        }
                                );
                            },
                        },
                        ...keepOnly(isColFromNestedTable ? nestedDropdownProps : dropdownProps, ["hasError", "errorMessage", "domRef"]),
                    });
                })()}

                {/*If the user selects a column from the previous step's output columns*/}
                {andConfig?.$type === "ColumnFilterConfig" && (
                    <>
                        {cs(() => {
                            const fieldInfo = getFieldInfo(form.field([...pathForForm]).state.value.left);

                            return comparisonSelect({
                                dataType: fieldInfo?.dataType,
                                ...ffToDropdown(form.field([...pathForForm, "comparison"]), ["value"]),
                            });
                        })}

                        {(() => {
                            const comparison = getPath(step.value, [...path, "comparison"]);
                            if (!comparison || comparison.includes("Null")) {
                                return;
                            }
                            const dropdownProps = ffToDropdown(form.field([...pathForForm, "right"]));
                            const selector = isInside ? nonCollectionColumnValueSelectFromChildTable : columnValueDrillingSelect2;
                            return (
                                <>
                                    {selector({
                                        state: {
                                            value: dropdownProps.value,
                                            onChange: dropdownProps.onChange,
                                        },
                                        ...keepOnly(dropdownProps, ["hasError", "errorMessage", "domRef"]),
                                    })}
                                    {dropdownProps.value?.$type === "StaticValue" &&
                                        DebounceTextInput({
                                            label: "Value",
                                            ...ffToBasicInput(form.field([...pathForForm, "right", "value"])),
                                        })}
                                </>
                            );
                        })()}
                    </>
                )}

                {andConfig?.$type === "CollectionFilterConfig" &&
                    (() => {
                        if (isColFromNestedTable) {
                            // If the user selects a column from a nested table then show a new "Operation" dropdown with choices of "Any" and "All"
                            return (
                                <>
                                    {DropdownSelect2({
                                        label: "Operator",
                                        list: ["All", "Any"],
                                        ...ffToDropdown(form.field([...pathForForm, "right", "operator"])),
                                    })}
                                    {getPath(step.value, [...path, "right"])?.$type === "AllAnyValue" && (
                                        <>
                                            {cs(() => {
                                                const fieldInfo = getFieldInfo(form.field([...pathForForm]).state.value.right.config.left);

                                                return comparisonSelect({
                                                    dataType: fieldInfo.dataType,
                                                    ...ffToDropdown(form.field([...pathForForm, "right", "config", "comparison"]), ["value"]),
                                                });
                                            })}

                                            {(() => {
                                                const comparison = getPath(step.value, [...path, "right", "config", "comparison"]);
                                                if (!comparison || comparison.includes("Null")) {
                                                    return;
                                                }
                                                const dropdownProps = ffToDropdown(form.field([...pathForForm, "right", "config", "right"]));
                                                return (
                                                    <>
                                                        {nonCollectionColumnValueSelect({
                                                            state: {
                                                                value: dropdownProps.value,
                                                                onChange: dropdownProps.onChange,
                                                            },
                                                            ...keepOnly(dropdownProps, ["hasError", "errorMessage", "domRef"]),
                                                        })}

                                                        {dropdownProps.value?.$type === "StaticValue" &&
                                                            DebounceTextInput({
                                                                label: "Value",
                                                                ...ffToBasicInput(form.field([...pathForForm, "right", "config", "right", "value"])),
                                                            })}
                                                    </>
                                                );
                                            })()}
                                        </>
                                    )}
                                </>
                            );
                        } else {
                            // if the user selects a related table, the Operator list should contain only "Contains" and "Does Not Contain"
                            return (
                                <>
                                    {DropdownSelect2({
                                        label: "Operator",
                                        list: ["Contains", "Does Not Contain"].map((label) => ({
                                            label,
                                            value: label.replace(/ /g, ""),
                                        })),
                                        valueToLabel: (v) => v.label,
                                        ...ffToDropdown(form.field([...pathForForm, "right", "operator"]), ["value"]),
                                        onChange: (v) =>
                                            spc(step, [...path, "right"], () => {
                                                return {
                                                    $type: "ContainsValue",
                                                    operator: v.value,
                                                    orConfigs: [
                                                        {
                                                            $type: "ContainsOrFilterConfig",
                                                            andConfigs: [
                                                                {
                                                                    $type: "ColumnFilterConfig",
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                };
                                            }),
                                    })}
                                </>
                            );
                        }
                    })()}

                {andConfig?.$type && (
                    <>
                        {thisAndConfigIndex + 1 === getPath(step.value, path.slice(0, path.length - 1))?.length ? (
                            <Button
                                btnType="secondary"
                                size="tiny"
                                className="and-btn"
                                onClick={() => spc(step, path.slice(0, path.length - 1), (andConfigs) => [...(andConfigs || []), {}])}
                            >
                                AND
                            </Button>
                        ) : (
                            <div className="and-text">AND</div>
                        )}
                    </>
                )}

                {step.value.orConfigs?.length > 0 && (
                    <div className="delete-btn" onClick={onRemove}>
                        {TrashIcon()}
                    </div>
                )}
            </div>

            {andConfig?.right?.orConfigs && (
                <>
                    <div className="or-configs">
                        {andConfig.right.orConfigs.map((orConfig, oci) =>
                            cs(keyed(oci), ({}) =>
                                OrConfig({
                                    headerText: oci > 0 ? "OR contains..." : "",
                                    step,
                                    form,
                                    parentPath: path,
                                    path: [...path, "right", "orConfigs", oci],
                                    onRemove: () => spc(step, [...path, "right", "orConfigs"], (orConfigs) => removeIndex(oci, orConfigs)),
                                    columns,
                                    model,
                                })
                            )
                        )}
                    </div>
                    <Button
                        btnType="border"
                        size="tiny"
                        onClick={() =>
                            spc(step, [...path, "right", "orConfigs"], (orConfigs) => [
                                ...(orConfigs || []),
                                {
                                    $type: "ContainsOrFilterConfig",
                                    andConfigs: [{$type: "ColumnFilterConfig"}],
                                },
                            ])
                        }
                    >
                        + OR
                    </Button>
                </>
            )}
        </div>
    );
};
