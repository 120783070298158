import "./wysiwyg-editor.scss";

import {css, cx} from "emotion";
import {ListItemNode, ListNode} from "@lexical/list";
import {AutoLinkNode, LinkNode} from "@lexical/link";
import {HeadingNode, QuoteNode} from "@lexical/rich-text";
import {LinkPlugin} from "@lexical/react/LexicalLinkPlugin";
import {ListPlugin} from "@lexical/react/LexicalListPlugin";
import {LexicalComposer} from "@lexical/react/LexicalComposer";
import {HistoryPlugin} from "@lexical/react/LexicalHistoryPlugin";
import {OnChangePlugin} from "@lexical/react/LexicalOnChangePlugin";
import {RichTextPlugin} from "@lexical/react/LexicalRichTextPlugin";
import {ContentEditable} from "@lexical/react/LexicalContentEditable";
import {AutoFocusPlugin} from "@lexical/react/LexicalAutoFocusPlugin";
import {$convertFromMarkdownString, $convertToMarkdownString, TRANSFORMERS} from "@lexical/markdown";

import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";

import editorTheme from "@common/ui-components/charts/text-chart/editor-theme";
import {cGetFontSize} from "@common/ui-components/charts/common/style-map/font-size";

import {EditorToolbar} from "./toolbar/editor-toolbar";
import ListMaxIndentLevelPlugin from "./plugin/list-max-indent-level-plugin";

export const WysiwygEditor = ({state, readOnly}) =>
    cs(consumeContext("theme"), ({theme}) => {
        const editorConfig = {
            readOnly: readOnly,
            theme: editorTheme,
            onError: (error) => {
                console.error(error);
            },
            nodes: [HeadingNode, ListNode, ListItemNode, QuoteNode, AutoLinkNode, LinkNode],
        };

        const getFontSize = readOnly ? cGetFontSize(theme.dataVisualization.textTile.fontSize || theme.general.canvas.fontSize) : null;

        return (
            <LexicalComposer initialConfig={editorConfig}>
                <div className={cx("editor-container-a33", readOnly && "readonly")}>
                    {!readOnly && <EditorToolbar />}

                    <RichTextPlugin
                        initialEditorState={() => {
                            $convertFromMarkdownString(state.value || "", TRANSFORMERS);
                        }}
                        contentEditable={
                            <ContentEditable
                                className={cx(
                                    "editor-input",
                                    readOnly &&
                                        css`
                                            a {
                                                color: ${theme.general.buttons.hyperlinkButton.fontColorRGB};

                                                &:hover {
                                                    color: ${theme.general.buttons.hyperlinkButton.fontColorRGB};
                                                }
                                            }
                                            color: ${theme.dataVisualization.textTile.fontColorRGB || theme.general.canvas.fontColorRGB} !important;
                                            font-family: ${theme.general.canvas.fontFamily}, sans-serif;
                                            font-size: ${getFontSize({
                                                elemType: "text",
                                            })}px !important;

                                            .editor-heading-h1 {
                                                font-size: ${getFontSize({
                                                    elemType: "title",
                                                })}px !important;
                                            }
                                        `
                                )}
                                spellCheck={false}
                            />
                        }
                    />
                    <OnChangePlugin
                        onChange={(editor) => {
                            editor.read(() => {
                                const markdown = $convertToMarkdownString(TRANSFORMERS);
                                state.onChange(markdown);
                            });
                        }}
                    />
                    <HistoryPlugin />
                    <AutoFocusPlugin />
                    <ListPlugin />
                    <LinkPlugin />
                    {/*<AutoDetectLinkPlugin/>*/}
                    <ListMaxIndentLevelPlugin maxDepth={4} />
                </div>
            </LexicalComposer>
        );
    });
