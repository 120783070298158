import React from "react";
import {RemoveGuidanceButton} from "./model-tutorial-step-1";
import {Button} from "../../../../../../../../common/form/buttons/button/button";

export const ModelTutorialStep2 = ({onNext, onBack}) => {
    return (
        <div className="step-1">
            <img src={require("../images/step-2.gif")} className="img-bg" />

            <div className="text">
                When columns are named similarly, Verb will provide relationship suggestions.
                <br />
                <br />
                Tip: You don’t have to create relationships between all of the tables, but when tables are related, you can combine the data from them for more complex and powerful
                visualizations.{" "}
            </div>

            <div className="step-footer">
                {RemoveGuidanceButton({})}

                <Button btnType="border" iconLeft={<i className="fas fa-chevron-left" />} onClick={onBack}>
                    Previous
                </Button>

                <Button iconRight={<i className="fas fa-chevron-right" />} onClick={onNext}>
                    Next
                </Button>
            </div>
        </div>
    );
};
