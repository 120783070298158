import React from "react";
import {css} from "emotion";

import {cs} from "@common/react/chain-services";
import {spc} from "@common/react/state-path-change";

import {Dropdown} from "@common/ui-components/dropdown/dropdown";

import {OnOffToggle} from "../../../../../../common/form/toggles/on-off-toggle";
import {ButtonIconWithTooltip} from "../../../../../../common/form/buttons/button-icon/button-icon";

import {Badge} from "../../../common/badge/badge";

import {SettingIcon} from "./theme-select/theme-select-icons";
import {RefreshIcon, RefreshOffIcon} from "./edit-collection-layout";
import {consumeContext} from "@common/react/context";
import {StyledClass} from "@common/react/styled-class";

export const AutoRefreshBadge = ({isEnabledAutoRefresh}) =>
    Badge({
        size: "lg",
        type: "default",
        icon: isEnabledAutoRefresh ? <RefreshIcon /> : <RefreshOffIcon />,
        tooltipClassName: "tooltip-white-f3z",
        tooltip: isEnabledAutoRefresh ? (
            <>
                <b>"Auto Data Refresh"</b> enabled. <br />
                View Configuration in <SettingIcon /> <b>Settings</b>.
            </>
        ) : (
            <>
                <b>"Auto Data Refresh"</b> disabled to <br />
                optimize performance. View <br />
                Configuration in <SettingIcon /> <b>Settings</b>.
            </>
        ),
    });

export const AutoRefreshSettingBtns = ({isEnabledAutoRefresh, collectionToast, collection}) =>
    cs(
        consumeContext("dataSummary"),
        consumeContext("autoRefreshProvider"),
        [
            "refreshIconCss",
            ({}, next) =>
                StyledClass({
                    content: {
                        width: "24px",
                        height: "24px",
                        path: {
                            fill: "#11a1fd",
                        },
                    },
                    next,
                }),
        ],
        ({dataSummary, autoRefreshProvider, refreshIconCss}) => (
            <>
                {Dropdown({
                    registryRender: true,
                    forcedExpandLeft: true,
                    expandClassNames: "setting-popup-bt4",
                    expandDistance: 5,
                    renderToggle: ({showExpand, showingExpand}) => (
                        <ButtonIconWithTooltip
                            tooltipClassName="tooltip-white-f3z small"
                            content="Settings"
                            icon={<SettingIcon />}
                            className="btn"
                            size="medium"
                            btnType="ghost-no-border"
                            onClick={() => showExpand()}
                        />
                    ),
                    renderExpand: () => (
                        <>
                            <div className="popup-header">Settings</div>

                            <div className="popup-content">
                                <div className="auto-refresh-toggle">
                                    {OnOffToggle({
                                        state: {
                                            value: isEnabledAutoRefresh,
                                            onChange: (value) => {
                                                spc(collection, ["autoDataRefresh"], () =>
                                                    value ? (dataSummary.isLessThan1GB ? null : true) : false
                                                );
                                                !value &&
                                                    collectionToast.show({
                                                        hasClose: true,
                                                        customIcon: <RefreshIcon className={refreshIconCss} />,
                                                        text: (
                                                            <>
                                                                <b>"Auto Data Refresh"</b> disabled to <br />
                                                                optimize performance. View <br />
                                                                Configuration in <SettingIcon /> <b>Settings</b>.
                                                            </>
                                                        ),
                                                    });
                                            },
                                        },
                                    })}

                                    <div>Auto Data Refresh</div>
                                </div>

                                <div className="text">
                                    <i className="material-icons">info</i>
                                    <div>
                                        To optimize performance during collection building, <b>"Auto Data Refresh"</b> automatically
                                        disables when your environment contains a large amount of data.
                                    </div>
                                </div>
                            </div>
                        </>
                    ),
                })}

                <ButtonIconWithTooltip
                    tooltipClassName="tooltip-white-f3z small"
                    content="Refresh Data"
                    icon={<RefreshIcon />}
                    className="btn"
                    size="medium2"
                    btnType="ghost-no-border"
                    onClick={() => autoRefreshProvider.refresh()}
                />
            </>
        )
    );
