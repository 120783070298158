import "./join-additional-fields.scss";
import {LeftPanelClosable} from "../../../../../../common/left-panel-closable/left-panel-closable";
import {cs} from "@common/react/chain-services";
import {Button} from "@common/form/buttons/button/button";
import {CrTableSection} from "../table-section/cr-table-section";
import React from "react";
import {RELATIONSHIP_INNER_JOIN} from "../../../../main/model-constrants";
import {UseState} from "@common/react/use-state";
import {CRColumnList} from "../column-list/cr-column-list";
import {ModelPanelHelper} from "../../../../../common/model-panel-helper";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";

export const JoinAdditionalFields = ({onClose, table, target, dataSources, relationships, onSubmit}) => {
    return cs(
        [
            "relationship",
            (_, next) =>
                UseState({
                    next,
                    initValue: {
                        joinType: relationships[0].joinType,
                    },
                }),
        ],
        ({relationship}, next) => (
            <LeftPanelClosable
                label="JOIN ON ADDITIONAL FIELDS"
                onClose={() => {
                    onClose();
                    relationship.onChange({});
                }}
                content={next()}
            />
        ),
        ({relationship}) => (
            <div className="join-on-additional-fields">
                <div className="text-title">Select Related Fields</div>

                <CrTableSection
                    table={table}
                    target={target}
                    relationship={{...relationship.value, joinType: RELATIONSHIP_INNER_JOIN}}
                    dataSources={dataSources}
                    onSwap={() => {}}
                />

                <VerbScrollbar className="columns-select-section">
                    <div className="columns-select-wrapper">
                        <div className="column-select-col">
                            {CRColumnList({
                                table,
                                selected: (columnID) => relationship.value.leftColumnID == columnID,
                                onSelect: (columnID) =>
                                    relationship.onChange({
                                        ...relationship.value,
                                        leftColumnID: columnID,
                                    }),
                                disabled: (columnID) =>
                                    relationships.find((r) =>
                                        ModelPanelHelper.isEqualsRelationship(r, {
                                            leftColumnID: columnID,
                                            rightColumnID: relationship.value.rightColumnID,
                                        })
                                    ),
                            })}
                        </div>

                        <div className="column-select-col">
                            {CRColumnList({
                                table: target,
                                selected: (columnID) => relationship.value.rightColumnID == columnID,
                                onSelect: (columnID) =>
                                    relationship.onChange({
                                        ...relationship.value,
                                        rightColumnID: columnID,
                                    }),
                                disabled: (columnID) =>
                                    relationships.find((r) =>
                                        ModelPanelHelper.isEqualsRelationship(r, {
                                            leftColumnID: relationship.value.leftColumnID,
                                            rightColumnID: columnID,
                                        })
                                    ),
                            })}
                        </div>
                    </div>
                </VerbScrollbar>

                <div className="bottom-action-button">
                    <Button
                        disabled={!relationship.value.leftColumnID || !relationship.value.rightColumnID}
                        className="update-btn"
                        onClick={() => {
                            onSubmit(relationship.value);
                            relationship.onChange({});
                        }}
                    >
                        Update Relationship
                    </Button>
                </div>
            </div>
        )
    );
};
