import * as React from "react";
import {cs} from "@common/react/chain-services";
import {DashboardLayout} from "../dashboard/layout/dashboard-layout";
import {consumeContext} from "@common/react/context";
import "./main-dashboard-route.scss";
import {VerbDashboard} from "../../../../sdk/main/verb-dashboard";
import {RoutingParam} from "../common/routing/routing-param";
import {sdkStaticUrl} from "../../../../sdk/main/loaders/sdk-static-url";
const verbAppConfig = process.env.appConfig.verb;

export const MainDashboardRoute = () =>
    cs(
        consumeContext("routing"),
        consumeContext("auth"),
        ["envId", ({tab}, next) => RoutingParam({param: "envId", next})],
        ({routing, envId}, next) =>
            DashboardLayout({
                mainStyle: {overflow: "auto", width: "100%"},
                tab: {
                    value: null,
                    onChange: (tab) => routing.goto("dashboard", {envId: envId.value, tab}),
                },
                envId: {
                    value: envId.value,
                    onChange: (envId) => routing.goto("dashboard", {envId}),
                },
                next,
            }),
        ({auth}) => {
            return (
                <div className="main-dashboard-route-sd2">
                    <div className="header">Insights</div>
                    <div className="content">
                        {VerbDashboard({
                            collectionId: verbAppConfig.dashboard.collectionId,
                            apiKey: verbAppConfig.dashboard.apiKey,
                            authParams: {
                                btoken: auth.access,
                                tenantid: auth.tenantId || auth.user.tenantID,
                            },
                            version: "v1.0",
                            sdkStaticUrl: sdkStaticUrl(window.location.href),
                            hideFilters: false,
                            hiddenFilterIds: null,
                        })}
                    </div>
                </div>
            );
        }
    );
