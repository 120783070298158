import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import React from "react";
import {createDataAccessIframeApis} from "../../../apis/data-access/data-acess-iframe-api";
import {Load2} from "@common/react/load2";
import {CopySettingKey} from "../../dashboard/env/settings/commons/copy-setting-key/copy-setting-key";
import "./iframe-docs.scss";

export const IframeDocs = ({}) =>
    cs(
        ["iframeApi", (_, next) => next(createDataAccessIframeApis())],
        consumeContext("routing"),
        [
            "iframeDocs",
            ({iframeApi, routing}, next) =>
                Load2({
                    fetch: () => iframeApi.renderDocs(routing.params.key),
                    next,
                }),
        ],
        ({iframeDocs}) => {
            return (
                <div className="api-collection-docs iframe-docs-9ew">
                    <div className="iframe-docs-content">
                        <div className="page-header">
                            <div className="title">
                                <h2>Dashboard Collections URLs</h2>
                            </div>
                        </div>

                        <div className="iframe-urls">
                            {Object.keys(iframeDocs?.value || {}).map((key, index) => (
                                <div className="iframe-url" key={index}>
                                    <div className="col-name">{key}</div>
                                    <div className="col-url">
                                        <a target="_blank" href={iframeDocs.value[key]}>
                                            {iframeDocs.value[key]}
                                        </a>
                                    </div>

                                    {CopySettingKey({
                                        value: iframeDocs.value[key],
                                        text: "URL",
                                    })}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="footer">
                        Powered by{" "}
                        <a className="verb" href="https://revivemedia.us/" target="_blank">
                        Revive
                        </a>
                    </div>
                </div>
            );
        }
    );
 