import "./setup-mfa-modal.scss";

import React from "react";

import {consumeContext} from "@common/react/context";
import {cs} from "@common/react/chain-services";
import {DialogService} from "../../../common/dialog/dialog-service";
import {UseState} from "@common/react/use-state";
import {spc} from "@common/react/state-path-change";
import {scope} from "@common/react/scope";
import {Button} from "@common/form/buttons/button/button";
import {EnterPasswordStep} from "./steps/enter-password-step";

export const DisableMfaModal = ({next: rootNext}) =>
    cs(
        consumeContext("apis"),
        consumeContext("auth"),
        [
            "modal",
            (_, next) =>
                DialogService({
                    registryRender: true,
                    render: ({resolve}) => ({
                        width: 500,
                        title: "Deactivate Multi-Factor Authentication",
                        content: next({
                            resolve,
                        }),
                    }),
                    next: rootNext,
                }),
        ],
        [
            "state",
            (_, next) =>
                UseState({
                    next,
                    initValue: {
                        password: null,
                        done: false,
                    },
                }),
        ],
        ({state, apis, auth, modal}) => {
            const {done, password} = state.value;

            const deactivateMFA = async (password) => {
                if (!password) {
                    return;
                }
                const resp = await apis.user.enableMFAUser(password, false);

                if (resp) {
                    await auth.refetchUser();
                    spc(state, ["done"], () => true);
                } else {
                    throw new Error("Cannot disable MFA!");
                }
            };

            return (
                <div className="setup-mfa-modal-b90">
                    {!done ? (
                        EnterPasswordStep({
                            description: "To deactivate multi-factor authentication on your account, please verify your password.",
                            password: scope(state, ["password"]),
                            onNext: deactivateMFA,
                        })
                    ) : (
                        <>
                            <div className="content">Multi Factor Authentication Deactivated!</div>

                            <div className="buttons">
                                <div className="space" />
                                <Button onClick={() => modal.resolve()}>Done</Button>
                            </div>
                        </>
                    )}
                </div>
            );
        }
    );
