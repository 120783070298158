import * as React from "react";
import "../number-filter/number-filter-options";
import {cs} from "@common/react/chain-services";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {upperCase1} from "@common/utils/strings";
import {ffToDropdown} from "../../../../../../../../../../common/form/ff-to-dropdown";
import {ShowHideToggle} from "../../../../../../../../../../common/form/toggles/show-hide-toggle";

export function isNil(value) {
    return value === null || value === undefined;
}

export const BooleanFilterOptions = ({form}) =>
    cs(({}) => {
        const defaultValueState = form.field("defaultValue").state;

        return (
            <div className="contextual-number-filter-options-l35">
                <div className="header">
                    Default Filter Value
                    <div className="control">
                        {ShowHideToggle({
                            state: {
                                value: !isNil(defaultValueState.value),
                                onChange: () => defaultValueState.onChange(isNil(defaultValueState.value) || null),
                            },
                        })}
                    </div>
                </div>

                {!isNil(defaultValueState.value) && (
                    <div className="content">
                        {DropdownSelect({
                            label: "Boolean Value",
                            list: [true, false],
                            valueToLabel: (op) => upperCase1(op.toString()),
                            ...ffToDropdown(form.field("defaultValue")),
                        })}
                    </div>
                )}
            </div>
        );
    });
