import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {isMatchText} from "@common/utils/strings";
import {isAggregatedMeasure, isNotPublishedCalculatedColumn} from "../../../common/column-utils";
import {TruncatingTooltip} from "../../../../../../common/truncating-tooltip/truncating-tooltip";
import {keyed} from "@common/react/keyed";
import {cx} from "emotion";
import React from "react";

export const CRColumnList = ({table, selected, onSelect, disabled}) =>
    cs(["keyword", (_, next) => UseState({next, initValue: ""})], ({keyword}) => {
        const filteredColumns = table.columns.filter(
            (c) => isMatchText(c.name, keyword.value) && !isNotPublishedCalculatedColumn(c) && !isAggregatedMeasure(c) && !c.deleted
        );
        return (
            <div className="column-list">
                <div className="input-search">
                    <i className="material-icons search-icon">search</i>
                    <input
                        type="text"
                        value={keyword.value}
                        onChange={(e) => keyword.onChange(e.target.value)}
                        placeholder="Search table fields"
                    />

                    {keyword.value?.length > 0 && (
                        <i className="material-icons close-icon" onClick={() => keyword.onChange("")}>
                            close
                        </i>
                    )}
                </div>

                <div className="list">
                    {filteredColumns.map((column) =>
                        cs(
                            [
                                "truncatingTooltip",
                                (_, next) =>
                                    TruncatingTooltip({
                                        direction: "below",
                                        next,
                                    }),
                            ],
                            keyed(column.id),
                            ({truncatingTooltip}) => (
                                <div
                                    {...{
                                        className: cx("column", {
                                            disabled: disabled && disabled(column.id),
                                            selected: selected(column.id),
                                        }),
                                        ...truncatingTooltip.tooltip(column.name),
                                    }}
                                    ref={truncatingTooltip.ref}
                                    key={column.id}
                                    onClick={() => {
                                        if (!disabled || !disabled(column.id)) {
                                            onSelect(column.id);
                                        }
                                    }}
                                >
                                    <div className={cx("radio")} />

                                    {column.name}
                                </div>
                            )
                        )
                    )}
                </div>
            </div>
        );
    });
