const {cs} = require("./chain-services");
const {Static2} = require("./static-2");

const AsyncCache = ({next, action}) =>
    cs(["cache", (_, next) => Static2({next})], ({cache}) =>
        next(() => {
            if (cache.get()) {
                return cache.get();
            }

            const promise = action();
            cache.set(promise);

            promise.then(() => cache.set(null));

            return promise;
        })
    );
exports.AsyncCache = AsyncCache;
