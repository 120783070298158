const initNumericFilterState = (filter) => {
    if (filter.design === "Slider") {
        return {
            comparisonOperation: "Range",
            range: null,
            columns: filter.columns,
            // columns: filter.columns.filter(c => c.default)
        };
    } else if (filter.defaultOption === "Range") {
        return {
            comparisonOperation: "Range",
            range: {
                from: filter.defaultValueOrMin,
                to: filter.defaultMax,
            },
            columns: filter.columns,
            // columns: filter.columns.filter(c => c.default)
        };
    } else if (["InList", "NotInList"].includes(filter.defaultOption)) {
        return {
            comparisonOperation: filter.defaultOption,
            columns: filter.columns,
            // columns: filter.columns.filter(c => c.default),
            list: filter.defaultList,
        };
    } else {
        return {
            comparisonOperation: filter.defaultOption,
            value: filter.defaultValueOrMin === 0 ? null : filter.defaultValueOrMin,
            columns: filter.columns,
            // columns: filter.columns.filter(c => c.default)
        };
    }
};
exports.initNumericFilterState = initNumericFilterState;
