import * as React from "react";
import {Expandable} from "../../../../../../../../../../../common/expandable/expandable";
import {cs} from "@common/react/chain-services";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {NumberInput} from "../../../../../../../../../../../../../../common/form/number-input/number-input";
import {scope} from "@common/react/scope";
import {spc} from "@common/react/state-path-change";
import {DebounceNumber} from "../../../../../../../../../../../../../../common/form/debounce-number";
import {UseState} from "@common/react/use-state";
import {StaticTooltipService} from "../../../../../../../../../../../common/tooltip3/static-tooltip-service";

export const DecimalPlaces = ({field}) =>
    cs(
        [
            "isAuto",
            (_, next) =>
                UseState({
                    next,
                    initValue: field.value.numericProperties?.decimalPlaces == 0,
                }),
        ],
        ({isAuto}) => (
            <>
                <div className="decimal-placement">
                    {DropdownSelect({
                        label: "Decimal Placement",
                        className: "decimal-placement",
                        list: [
                            {
                                label: "Auto",
                                check: (f) => isAuto.value,
                            },
                            {
                                label: "Custom",
                                check: (f) => !isAuto.value,
                            },
                        ],
                        valueToLabel: (v) => v.label,
                        isSelected: (v) => v.check(field.value),
                        onChange: (v) => {
                            if (v.label === "Custom") {
                                isAuto.onChange(false);
                                spc(field, ["numericProperties", "decimalPlaces"], () => 1);
                            } else {
                                isAuto.onChange(true);
                                spc(field, ["numericProperties", "decimalPlaces"], () => 0);
                            }
                        },
                    })}

                    {cs(
                        [
                            "debounce",
                            (_, next) =>
                                DebounceNumber({
                                    state: scope(field, ["numericProperties", "decimalPlaces"]),
                                    next,
                                }),
                        ],
                        ({debounce}) =>
                            NumberInput({
                                state: debounce.state,
                                label: "Custom",
                                disabled: isAuto.value,
                                showZero: false,
                            })
                    )}
                </div>

                {ShiftDecimalPlaceLeft({field})}
            </>
        )
    );

export const ShiftDecimalPlaceLeft = ({field}) =>
    cs(() => (
        <>
            {(() => {
                // TODO validate 0 <= v <= 10
                const {value, onChange} = scope(field, ["numericProperties", "shiftDecimalPlaceLeft"]);
                return cs(
                    [
                        "debounce",
                        (_, next) =>
                            DebounceNumber({
                                state: {
                                    value,
                                    onChange: (v) => v >= 0 && v <= 10 && onChange(v),
                                },
                                next,
                            }),
                    ],
                    [
                        "staticTooltip",
                        (_, next) =>
                            StaticTooltipService({
                                direction: "right",
                                info: "If currency values are stored as integers without decimals in your data source, select the number of decimal places to that should be shifted when the currency is displayed.",
                                tooltipBoxWidth: 200,
                                next,
                            }),
                    ],
                    ({debounce, staticTooltip}) => (
                        <div className="decimal-places-input-a33">
                            {NumberInput({
                                className: "",
                                state: debounce.state,
                                label: "Shift Decimal # Places Left",
                                showZero: true,
                            })}

                            {staticTooltip.renderIcon({
                                icon: <i className="material-icons">info</i>,
                                className: "info-tooltip",
                            })}
                        </div>
                    )
                );
            })()}
        </>
    ));
