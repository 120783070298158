const {getTooltipBoxStyle} = require("./tooltip-box-style");

const getTooltipPerSeriesOption = ({tile, theme, styleMeasurementAttr, formatters, dateFilterValue, isDimensionDate, isDimensionRealDate, isCompare, rawData, isBarClassic}) => {
    let ret = {};

    Object.keys(tile.style[styleMeasurementAttr]).forEach((stackKey) => {
        ret[stackKey] = (index) => ({
            tooltip: {
                ...getTooltipFormat({
                    theme,
                    isDimensionDate,
                    isDimensionRealDate,
                    isCompare,
                    // dateFormatter: formatters.dimensionFormatter,
                    dateFormatter: formatters.dateFormatter,
                    valueFormatter: formatters.tooltipValueFormatters[stackKey][index],
                    dateFilterValue,
                    rawData,
                    isBarClassic,
                }),
            },
        });
    });

    return ret;
};
exports.getTooltipPerSeriesOption = getTooltipPerSeriesOption;

const getTooltipFormat = ({theme, tile, isDimensionDate, isDimensionRealDate, isCompare, dateFormatter, valueFormatter, dateFilterValue, rawData, isBarClassic}) => {
    // console.log(valueFormatter)

    return {
        pointFormatter: function () {
            // console.log(this)
            const point = this;
            const format = isCompare ? tooltipCompare : tooltipNonCompare;
            return format(
                {point},
                {
                    theme,
                    tile,
                    isDimensionDate,
                    isDimensionRealDate,
                    dateFormatter,
                    valueFormatter,
                    dateFilterValue,
                    rawData,
                    isBarClassic,
                }
            );
        },
    };
};
exports.getTooltipFormat = getTooltipFormat;

const tooltipCompare = ({point}, {theme, isDimensionDate, isDimensionRealDate, dateFormatter, valueFormatter, dateFilterValue, rawData, isBarClassic}) => {
    // console.log(111, point)
    // no tooltip on compare series
    // if (point.graphic.opacity < 1) {
    //     return;
    // }

    const getInfoFn = isBarClassic ? getTooltipInfoBarClassicCompare : getTooltipInfo;
    const {valueLabel, seriesName, value, color, compareSeries, compareValueLabel, compareValue, isLine} = getInfoFn(point, rawData);

    const {dateRange, compareDateRange} = dateFilterValue ? getDateRangeFromDateFilterValue({dateFilterValue, dateFormatter}) : {};

    const labelProp = isDimensionRealDate ? {valueLabel: dateFormatter(valueLabel)} : isDimensionDate ? {valueLabel: valueLabel} : {dateRange};
    const compareLabelProp = isDimensionRealDate ? {valueLabel: dateFormatter(compareValueLabel)} : isDimensionDate ? {valueLabel: compareValueLabel} : {dateRange: compareDateRange};

    const header = cHeader(isDimensionRealDate ? dateFormatter(valueLabel) : valueLabel);

    const seriesName1 = cSeriesName(seriesName);

    const primaryLine = cSeriesLine({
        ...labelProp,
        value: value && valueFormatter(value),
        isLine,
        color,
    });

    const compareLine =
        compareSeries &&
        compareValueLabel &&
        cSeriesLine({
            ...compareLabelProp,
            value: compareValue && valueFormatter(compareValue),
            color,
            opacity: compareSeries.userOptions?.opacity || 0.25, //temp, for bar classic compare
            isLine,
            noMargin: true,
        });

    return (
        `<div class="chart-tooltip-box" style="${getTooltipBoxStyle({
            theme,
        })}">` +
        [header, seriesName1, primaryLine, compareLine].filter((v) => v).join(" ") +
        `<div class="tooltip-anchor"></div>` +
        "</div>"
    );
};

const tooltipNonCompare = ({point}, {theme, isDimensionDate, isDimensionRealDate, dateFormatter, valueFormatter}) => {
    const {valueLabel, seriesName, value, color} = getTooltipInfo(point);

    const header = cHeader(isDimensionRealDate ? dateFormatter(valueLabel) : valueLabel);
    const primaryLine = cSeriesLine({
        seriesName,
        value: valueFormatter(value),
        color,
    });

    return (
        `<div class="chart-tooltip-box" style="${getTooltipBoxStyle({
            theme,
        })}">` +
        [header, primaryLine].join(" ") +
        `<div class="tooltip-anchor"></div>` +
        "</div>"
    );
};

const cHeader = (v) => `<div style="margin-bottom: 5px; color: #605F5E">${v}</div>`;

const cSeriesName = (v) => `<div style="font-weight: bold; margin-bottom: 5px; color: #605F5E">${v}</div>`;

const cSeriesLine = ({seriesName, dateRange, valueLabel, value, color, opacity, noMargin, isLine}) => {
    const bullet = color && `<span style="color: ${color}; opacity: ${opacity || 1}">\u25CF</span>`;
    const line = color && `<span style="color: ${color}; font-weight: bold">${opacity ? "\u2504" : "\u2501"}</span>`;
    const dateRange1 = dateRange && `<span style="color: #707070">${dateRange}</span>`;
    const seriesName1 = seriesName && `<span style="color: #707070">${seriesName}</span>`;
    const valueLabel1 = valueLabel && `<span>${valueLabel}</span>`;
    const ghost = `<span style="font-weight: bold; margin-left: 10px; color: #FFFFFF">${value}</span>`;
    const value1 = `<span style="font-weight: bold; margin-left: 10px; color: #605F5E; position: absolute; right: 0; top: 0">${value ?? "-"}</span>`; //display: block; float: right

    const ret = [isLine ? line : bullet, seriesName1, dateRange1, valueLabel1, ghost, value1].filter((v) => v).join(" ");

    return `<div style="color: #707070; margin-bottom: ${noMargin ? 0 : "5px"}; position: relative">${ret}</div>`;
};

// TODO real datetime dimension case: connecting main and comparison by index is wrong when data's dates are missing.
// need to normalize the data's length, or find another way other than using index
const getTooltipInfo = (point) => {
    const isLine = point.series.type?.includes("line");
    const isComparisonPoint = point.graphic?.opacity < 1; // || point.series.graph.opacity < 1;

    if (isComparisonPoint) {
        const index = point.index;
        const seriesName = point.series.name;
        const respectiveSeries = point.series.yAxis.series.find((s) => s.name === seriesName && !s.userOptions.opacity);
        const valueLabel = respectiveSeries?.data[index]?.options.x || respectiveSeries?.data[index]?.options.name;
        const value = respectiveSeries?.data[index]?.y;
        const color = point.color;
        const compareSeries = point.series;
        const compareValueLabel = point.options.x || point.options.name;
        const compareValue = point.y;

        return {
            index,
            valueLabel,
            seriesName,
            value,
            color,
            compareSeries,
            compareValueLabel,
            compareValue,
            isLine,
        };
    }

    const index = point.index;
    // const valueLabel = point.x;
    const valueLabel = point.options.x || point.options.name;
    const seriesName = point.series.name;
    const value = point.y;
    const color = point.color;
    const compareSeries = point.series.yAxis.series.find((s) => s.name === seriesName && s.userOptions.opacity < 1);
    // const compareValueLabel = compareSeries?.data[index]?.x;
    const compareValueLabel = compareSeries?.data[index]?.options.x || compareSeries?.data[index]?.options.name;
    const compareValue = compareSeries?.data[index]?.y;

    return {
        index,
        valueLabel,
        seriesName,
        value,
        color,
        compareSeries,
        compareValueLabel,
        compareValue,
        isLine,
    };
};

const getTooltipInfoBarClassicCompare = (point, rawData) => {
    // console.log(point)
    // console.log(rawData)
    const index = point.index;
    const valueLabel = point.options.x || point.options.name;
    const seriesName = point.series.name;
    const value = point.y;
    const color = point.color;
    const compareSeries = rawData.series.find((s) => s.isCompare && s.name === point.series.name);
    // console.log(compareSeries)
    const compareValueLabel = compareSeries?.data[index]?.[0];
    const compareValue = compareSeries?.data[index]?.[1];

    return {
        index,
        valueLabel,
        seriesName,
        value,
        color,
        compareSeries,
        compareValueLabel,
        compareValue,
    };
};

const getDateRangeFromDateFilterValue = ({dateFilterValue, dateFormatter}) => {
    const formatter = (range) => dateFormatter(range.dateStart) + " - " + dateFormatter(range.dateEnd);

    return {
        dateRange: formatter(dateFilterValue.range),
        compareDateRange: formatter(dateFilterValue.compareRange),
    };
};

// const formatDateRange = (dateFormatter) => (range) => {
//     const inTheLastOrNext = (range) => {
//         const prep = range.next ? "Next" : "Last";
//         const unit = range.value > 1 ? range.interval.toLowerCase() : range.interval.toLowerCase().slice(0, range.interval.length-1);
//         return prep + " " + range.value + " " + unit;
//     };
//
//     const beforeOrAfter = (range) => {
//         const prep = range.after ? "After" : "Before";
//         return prep + " " + dateFormatter(range.date);
//     };
//
//     const inRange = (range) => dateFormatter(range.dateStart) + " - " + dateFormatter(range.dateEnd);
//
//     const inTheYear = (range) => "" + range.year;
//
//     const formatter = {
//         "InTheLastOrNextCustomDateFilterRange": inTheLastOrNext,
//         "BeforeOrAfterCustomDateFilterRange": beforeOrAfter,
//         "InRangeCustomDateFilterRange": inRange,
//         "InTheYearCustomDateFilterRange": inTheYear,
//     }[range.$type];
//
//     return formatter(range);
// };
