import {cs} from "@common/react/chain-services";
import {RoutingParam} from "../../../common/routing/routing-param";
import {ApiCollectionTab} from "./collection/api-collection-tab";
import {ApiFiltersTab} from "./filters/api-filters-tab";
import {ApiFieldsTab} from "./fields/api-fields-tab";
import {createGetFieldInfo} from "../../../collection/common/field-info/create-get-field-info";

export const ApiCollectionTabs = ({next, savingQueue, collection}) =>
    cs(
        ({}, next) => createGetFieldInfo({next}),
        [
            "selectedTab",
            (_, next) =>
                RoutingParam({
                    param: "collectionTab",
                    defaultValue: "fields",
                    next,
                }),
        ],
        [
            "tabs",
            (_, next) =>
                cs(
                    ["filtersTab", ({}, next) => ApiFiltersTab({next, savingQueue})],
                    ["fieldsTab", ({}, next) => ApiFieldsTab({next, savingQueue, collection})],
                    ({filtersTab, fieldsTab}) =>
                        next([
                            {
                                id: "filters",
                                label: "Filters",
                                control: filtersTab,
                            },
                            {
                                id: "fields",
                                label: "Fields",
                                control: fieldsTab,
                            },
                        ])
                ),
        ],
        ({tabs, selectedTab}) => {
            const tab1 = tabs.find((tab) => tab.id === selectedTab.value) || tabs[0];
            if (tabs.filter((t) => t.id == selectedTab.value).length == 0) selectedTab.value = tabs[0].id;

            return next({
                tabs: tabs.map((tab) => ({
                    ...tab,
                    render: tab.control?.renderLeftPanel,
                })),
                selectedTab,
                panelOverride: tab1?.control.panelOverride,
            });
        }
    );
