import {cs} from "../../common/react/chain-services";
import {randomId} from "../../common/utils/random";
import {UseState} from "../../common/react/use-state";

export const DetachedFilterDoms = ({collectionId, next}) =>
    cs(
        [
            "state",
            (_, next) =>
                UseState({
                    getInitValue: () => {
                        const doms = document.querySelectorAll("[x-verb-filters]");
                        return Array.from(doms)
                            .filter((dom) => dom.getAttribute("x-collection-id") === collectionId)
                            .map((dom) => ({
                                dom,
                                key: randomId(),
                            }));
                    },
                    next,
                }),
        ],
        ({state}) =>
            next({
                doms: state.value,
                addFilterDom: (dom) =>
                    state.change((_) => [
                        ..._,
                        {
                            dom,
                            key: randomId(),
                        },
                    ]),
                removeFilterDom: (dom) => state.change((_) => _.filter((r) => r.dom !== dom)),
            })
    );
