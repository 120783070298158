exports.KPIListTemplate = {
    $type: "ListKPITile",
    style: {
        $type: "ListKPITileStyle",
        title: {
            show: true,
            titleLocation: "Above",
        },
        valueFields: [],
    },
};
