const {deserializeFilterState} = require("../serialize/deserialize-filter-state");
const {initFilterState} = require("./init-filter-state/init-filter-state");
const {Form2} = require("../../../../../react/cs-form/form2");
const {numericFilterFormConfig} = require("./numeric-form-config");
const {textFilterFormConfig} = require("./text-form-config");

const {UseState} = require("../../../../../react/use-state");
const {csArr} = require("../../../../../react/cs-arr");
const {cs} = require("../../../../../react/chain-services");
const {chain} = require("../../../../../utils/fs");
const {keyed} = require("../../../../../react/keyed");
const {serializeFilterState} = require("../serialize/serialize-filter-state");
const {sort} = require("../../../../../utils/collections");
const {equalDeep, omit} = require("@common/utils/objects");
const {sameDateRange} = require("@common/utils/dates/date-ranges");

const filterChangedCheck = {
    DateFilter: (value, defaultValue) => {
        const sameMain = sameDateRange(value.main, defaultValue.main);
        const sameComparing = sameDateRange(value.comparing, defaultValue.comparing);
        return sameMain && sameComparing;
    },
    TextFilter: (value, defaultValue) => {
        return equalDeep(omit(value, ["columns", "option", "$type"]), omit(defaultValue, ["columns", "option", "$type"]));
    },
    NumericFilter: (value, defaultValue) => {
        return equalDeep(omit(value, ["columns", "option", "$type"]), omit(defaultValue, ["columns", "option", "$type"]));
    },
    BooleanFilter: (value, defaultValue) => {
        return value.value && defaultValue.value ? value.value?.[0] === defaultValue?.value?.[0] : true;
    },
};

const isFilterChanged = (filter) => {
    if (!filter || !filter?.form?.data?.value) {
        return false;
    }
    return !filterChangedCheck[filter["$type"]](filter.form.data.value, filter.defaultValue);
};

const isSameFilter = (type, filter1, filter2) => {
    return filterChangedCheck[type](filter1, filter2);
};

const FilterForms = ({filters: filters1, filterVals, next}) => {
    return cs(
        ["saveToLocalMode", (_, next) => UseState({next, initValue: false})],
        [
            "filters",
            ({saveToLocalMode}, next) =>
                csArr(
                    sort(filters1, (f) => f.id).map(
                        (filter) => (next) =>
                            cs(
                                keyed(filter.id),
                                [
                                    "state",
                                    (_, next) =>
                                        UseState({
                                            getInitValue: () => ({
                                                updatedAt: Date.now(),
                                                value: chain(filterVals?.[filter.id], (_) =>
                                                    _ != null
                                                        ? deserializeFilterState({
                                                              value: _,
                                                              filter,
                                                          })
                                                        : initFilterState(filter)
                                                ),
                                            }),
                                            next,
                                        }),
                                ],
                                [
                                    "localState",
                                    ({state}, next) =>
                                        UseState({
                                            initValue: state.value.value,
                                            next,
                                        }),
                                ],
                                [
                                    "defaultState",
                                    ({state}, next) =>
                                        UseState({
                                            next,
                                            initValue: state.value.value,
                                        }),
                                ],
                                [
                                    "form",
                                    ({state, localState}, next) =>
                                        Form2({
                                            initShowErrors: true,
                                            data: {
                                                value: saveToLocalMode.value
                                                    ? localState.value
                                                    : {
                                                          ...state.value.value,
                                                      },
                                                onChange: (value) => {
                                                    if (saveToLocalMode.value && !value?.updateLive) {
                                                        localState.onChange(value);
                                                    } else {
                                                        state.onChange({
                                                            updatedAt: Date.now(),
                                                            value,
                                                        });
                                                    }
                                                },
                                            },
                                            ...configs[filter.$type]?.(filter),
                                            next,
                                        }),
                                ],
                                ({state, form, defaultState, localState}) =>
                                    next({
                                        filterId: filter.id,
                                        updatedAt: state.value.updatedAt,
                                        form,
                                        defaultValue: defaultState.value,
                                        localState,
                                        $type: filter["$type"],
                                    })
                            )
                    ),
                    next
                ),
        ],
        ({filters, saveToLocalMode}) => {
            const freeze = () =>
                filters.map(({filterId, updatedAt, form}) => ({
                    filterId,
                    updatedAt,
                    invalid: !form.validData,
                    value: form.data.value,
                }));
            return next({
                freeze,
                getForm: (filterId) => filters.find((r) => r.filterId === filterId).form,
                invalid: !!filters.find(({form}) => !form.valid),
                setLiveValue: (filterId, liveValue) => {
                    const filterStateValue = deserializeFilterState({
                        value: liveValue,
                        filter: filters1.find((f) => f.id === filterId),
                    });

                    filters.find((r) => r.filterId === filterId).form.data.onChange(filterStateValue);

                    return freeze().map((r) =>
                        r.filterId !== filterId
                            ? r
                            : {
                                  ...r,
                                  value: filterStateValue,
                              }
                    );
                },
                resetToDefault: () => {
                    filters.forEach((filter) => {
                        filter.form.data.onChange(filter.defaultValue);
                    });
                },
                getChangedFilters: () => {
                    return filters.reduce((result, cur) => {
                        if (isFilterChanged(cur)) {
                            return [...result, cur.filterId];
                        }
                        return result;
                    }, []);
                },
                toggleSaveToLocalMode: (value) => {
                    if (value) {
                        filters.forEach((filter) => {
                            filter.localState.onChange(filter.form.data.value);
                        });
                    }
                    saveToLocalMode.onChange(value);
                },
                applyLocalChangeToLive: () => {
                    filters.forEach((filter) => {
                        filter.form.data.onChange({
                            ...filter.localState.value,
                            updateLive: true,
                        });
                    });
                },
                getAllFilterValues: () => {
                    let obj = {};
                    for (let filter of filters) {
                        const filterObj = filters1.find((f) => f.id == filter.filterId);
                        obj[filter.filterId] = serializeFilterState(filter.form.data.value, {filter: filterObj});
                    }

                    return obj;
                },
            });
        }
    );
};
exports.FilterForms = FilterForms;

exports.isSameFilter = isSameFilter;

const configs = {
    NumericFilter: numericFilterFormConfig,
    TextFilter: textFilterFormConfig,
    // "DateFilter": () => ({fields: {}}),
};
