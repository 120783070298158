import "./organization.scss";

import React from "react";

import {cs} from "@common/react/chain-services";

import {OrgActiveMembers} from "./active-members/org-active-members";
import {OrgDetails} from "./details/org-details";
import {PanelTabs} from "../common/panel-tabs";

export const Organization = () =>
    cs(
        ["orgMembers", (_, next) => OrgActiveMembers({next})],
        [
            "tabs",
            ({orgMembers}, next) =>
                next([
                    {name: "general", label: "General", render: OrgDetails},
                    {
                        name: "members",
                        label: "Members",
                        render: orgMembers.render,
                        actions: orgMembers.actions,
                    },
                ]),
        ],
        ({tabs}) =>
            PanelTabs({
                tabs,
                title: "Organization",
                className: "org-settings-uyb",
            })
    );
