import {cs} from "@common/react/chain-services";
import {LeftPanelClosable} from "../../../../../common/left-panel-closable/left-panel-closable";
import React from "react";
import {UseState} from "@common/react/use-state";
import {JoinTypeDropdown} from "./join-type-dropdown/join-type-dropdown";
import {spc} from "@common/react/state-path-change";
import {CrTableSection} from "./table-section/cr-table-section";
import {rFieldTypeIcon} from "../../../../../common/field-type-icon/get-field-type-icon";
import {TrashIcon} from "../../../../../common/icons/trash-icon";
import {ModelPanelHelper} from "../../../../common/model-panel-helper";
import {lighten} from "@common/utils/color-util";
import {StaticTooltipService} from "../../../../../common/tooltip3/static-tooltip-service";
import {Button} from "@common/form/buttons/button/button";
import {JoinAdditionalFields} from "./join-additional-fields/join-additional-fields";
import {cx} from "emotion";
import {RemoveRelationshipDialog} from "../remove-relationship-dialog/remove-relationship-dialog";

export const EditRelationshipLeftPanel = ({
    onCancel,
    groupedRelationships: relationships,
    model,
    editRelationship: onUpdate,
    deSelectTable,
    table,
    target,
    dataSources,
}) =>
    cs(
        [
            "removeRelationshipService",
            (_, next) =>
                RemoveRelationshipDialog({
                    model,
                    onDone: (resp) => {
                        spc(model, ["relationships"], (_relationships) =>
                            _relationships.filter(
                                (r) => !resp.find((_r) => _r.leftColumnID == r.leftColumnID && _r.rightColumnID == r.rightColumnID)
                            )
                        );

                        const updated = relationships.filter(
                            (r) => !resp.find((_r) => _r.leftColumnID == r.leftColumnID && _r.rightColumnID == r.rightColumnID)
                        );

                        if (updated.length == 0) {
                            deSelectTable();
                        } else {
                            onUpdate({
                                table,
                                target,
                                relationships: updated,
                            });
                        }
                    },
                    next,
                }),
        ],
        ["openJoinAnotherFields", (_, next) => UseState({next, initValue: false})],
        ({openJoinAnotherFields}, next) => (
            <>
                <LeftPanelClosable
                    {...{
                        onClose: onCancel,
                        label: <div className="create-relationship-03s">EDIT RELATIONSHIP</div>,
                        content: next(),
                    }}
                />

                <div className={cx("edit-relationship-override", openJoinAnotherFields.value && "active")}>
                    <JoinAdditionalFields
                        table={table}
                        target={target}
                        relationships={relationships}
                        onClose={() => openJoinAnotherFields.onChange(false)}
                        dataSources={dataSources}
                        onSubmit={(relationship) => {
                            openJoinAnotherFields.onChange(false);

                            onUpdate({
                                relationships: relationships.concat(relationship),
                                target,
                                table,
                            });

                            spc(model, ["relationships"], (relationships) => [...(relationships || []), relationship]);
                        }}
                    />
                </div>
            </>
        ),
        ({openJoinAnotherFields, removeRelationshipService}) => {
            const joinType = relationships[0].joinType;

            const update = (getUpdated, target, table) => {
                const updatedRelationships = relationships.map((r) => ({
                    ...r,
                    ...getUpdated(r),
                }));

                onUpdate({
                    groupedRelationships: updatedRelationships,
                    target,
                    table,
                });

                spc(model, ["relationships"], (relationships) =>
                    relationships.map((r) => {
                        if (updatedRelationships.find((_r) => _r.leftColumnID == r.leftColumnID && _r.rightColumnID == r.rightColumnID)) {
                            return {
                                ...r,
                                ...getUpdated(r),
                            };
                        }

                        return r;
                    })
                );
            };

            return (
                <div className="create-relationship-content edit-relationship-content">
                    <div className="join-type-section">
                        {JoinTypeDropdown({
                            value: joinType,
                            onChange: (joinType) => update(() => ({joinType}), target, table),
                        })}
                    </div>

                    <div>
                        <CrTableSection
                            table={table}
                            target={target}
                            relationship={relationships[0]}
                            dataSources={dataSources}
                            onSwap={() => {
                                update(
                                    (relationship) => ({
                                        ...relationship,
                                        leftColumnID: relationship.rightColumnID,
                                        rightColumnID: relationship.leftColumnID,
                                    }),
                                    table,
                                    target
                                );
                            }}
                        />

                        <div className="relationship-lines">
                            {relationships.map((relationship) => {
                                const leftColumn = table.columns.find((c) => c.id == relationship.leftColumnID);
                                const rightColumn = target.columns.find((c) => c.id == relationship.rightColumnID);
                                const sourceColor = ModelPanelHelper.getTableColor({table, dataSources});
                                const targetColor = ModelPanelHelper.getTableColor({table: target, dataSources});

                                return (
                                    <div className="relationship-line" key={relationship.id}>
                                        <div
                                            className="left-column column"
                                            style={{
                                                background: lighten(sourceColor, 80),
                                            }}
                                        >
                                            {rFieldTypeIcon(leftColumn.dataType)}
                                            {leftColumn.name}
                                        </div>

                                        <div className="center-action">
                                            {relationships.length > 1 &&
                                                cs(
                                                    [
                                                        "staticTooltip",
                                                        (_, next) =>
                                                            StaticTooltipService({
                                                                direction: "top",
                                                                info: "Delete the join on these fields",
                                                                tooltipBoxWidth: 195,
                                                                next,
                                                            }),
                                                    ],
                                                    ({staticTooltip}) =>
                                                        staticTooltip.renderIcon({
                                                            icon: <TrashIcon />,
                                                            className: "remove-btn",
                                                            onClick: () => {
                                                                removeRelationshipService.show({
                                                                    tables: [table, target],
                                                                    relationships: [relationship],
                                                                });
                                                            },
                                                        })
                                                )}
                                        </div>

                                        <div
                                            className="right-column column"
                                            style={{
                                                background: lighten(targetColor, 80),
                                            }}
                                        >
                                            {rFieldTypeIcon(rightColumn.dataType)}
                                            {rightColumn.name}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>

                        <div className="join-additional-fields">
                            <Button btnType="secondary" onClick={() => openJoinAnotherFields.onChange(true)}>
                                Join On Additional Fields
                            </Button>
                        </div>
                    </div>

                    <div className="delete-container">
                        <Button
                            btnType="danger"
                            size="medium"
                            onClick={() =>
                                removeRelationshipService.show({
                                    tables: [table, target],
                                    relationships,
                                })
                            }
                        >
                            Delete Relationship
                        </Button>
                    </div>
                </div>
            );
        }
    );
