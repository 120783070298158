const {getSeriesColorByLegendIndex} = require("./get-series-color-by-legend-index");
const {getSeriesBorderRadiusOptions} = require("./get-border-radius-options");
const {sort} = require("../../../utils/collections");
const {flatten1} = require("../../../utils/collections");
// const {groupSeriesByAggregation} = require("../data-processor/group-series-by-aggregation");
const {unique} = require("../../../utils/collections");
const {arrMapToO} = require("../../../utils/objects");
const {chain} = require("../../../utils/fs");
const {prepareColor} = require("../prepare-color");
const {generateSeriesId} = require("./generate-series-id");

const groupCompareBarSeries = ({series, type, tile, theme, getYAxis, tileFields, tooltipOptions, measurementFormatters, isCompare, isBarDataColorAppliedByDimension, isDimensionDate}) => {
    const {dataColorPalettes} = theme.dataVisualization;
    const colors = dataColorPalettes.discreteColorsRGB;
    const isStacked = ["Stacked", "HundredPercent"].includes(tile.style.displayType || tile.style.yAxisBarDisplayType);
    const stackKeys = unique(series.map((s) => s.stack));
    const stackKeyTitleMap = arrMapToO(stackKeys, (sk) => unique(series.filter((s) => s.stack === sk).map((s) => s.measureAxisTitle)));

    return arrMapToO(stackKeys, (sk) => ({
        label: stackKeyTitleMap[sk].join(" & "),
        series: chain(
            series.filter((s) => s.stack === sk),
            (_series) => {
                const getSeriesFn = isStacked ? getSeriesStacked : getSeriesNonStacked;
                return getSeriesFn({
                    series: _series,
                    getYAxis,
                    yAxis: stackKeys.findIndex((s) => s === sk),
                    tile,
                    type,
                    tooltipOptions,
                    measurementFormatters,
                    colors,
                    theme,
                    tileFields,
                    hasGroupField: tile.groupField != null,
                    isCompare,
                    isBarDataColorAppliedByDimension,
                    isDimensionDate,
                });
            },
            // highcharts set reversedStacks on measurement axis to true by default,
            // so have to sort series by legendIndex in reverse order if stacked
            (_series) => sort(_series, (s) => s.legendIndex * (isStacked ? -1 : 1))
        ),
    }));
};

exports.groupCompareBarSeries = groupCompareBarSeries;

const getSeriesStacked = ({series, ...props}) => {
    const {tile, type, yAxis, getYAxis, tileFields, hasGroupField, measurementFormatters, theme, isBarDataColorAppliedByDimension} = props;
    const {dataColorPalettes} = theme.dataVisualization;
    const {displayType, yAxisBarDisplayType, dataLabels: _dataLabels, yAxisBarDataLabels} = tile.style;
    const dataLabels = _dataLabels || yAxisBarDataLabels;
    const isStackedColumn = ["Stacked", "HundredPercent"].includes(displayType || yAxisBarDisplayType);
    const {colorScale, getDisplayColor} = prepareColor({
        tile,
        tileFields,
        dataColorPalettes,
        isBarDataColorAppliedByDimension,
    });

    const getSeries = (series1, isCompare) => {
        colorScale?.setRange(0, series1.length - 1);

        return series1.map((s, i) => {
            if (isBarDataColorAppliedByDimension) {
                colorScale?.setRange(0, s.data.length - 1);
            }
            const colorOptions = getDisplayColor({series: s, index: i});
            const id = generateSeriesId(s);
            const indexInCustomStack = hasGroupField ? 0 : i;

            return {
                ...s,
                ...(tile.$type === "ComboChartTile"
                    ? {
                          yAxis: getYAxis(s),
                      }
                    : tile.style.multipleAxisOption === "IndependentAxes" && {
                          yAxis,
                      }),
                ...(type && {type}),
                customStack: s.stack, // customStack is used to store the original stack, since HC already uses "stack" to define stack
                indexInCustomStack, // index of this series in an axis group (or stack). this only makes sense when tile has no group field.
                ...(isStackedColumn && getSeriesBorderRadiusOptions(i, series1.length, theme)),
                stack: `${s.stack}_${!isCompare ? 0 : 1}`,
                xAxis: !isCompare ? 0 : 1,
                ...colorOptions,
                ...(!isCompare
                    ? {
                          id,
                          pointPadding: 0.25,
                          zIndex: 2,
                          // data labels formatter in series level does not work anymore, since HC v.10.2.0
                          // ...(dataLabels?.show && {
                          //     dataLabels: {
                          //         formatter: function() {
                          //             if (this.y === 0) {
                          //                 return;
                          //             }
                          //             return measurementFormatters[s.stack][indexInCustomStack](this.y);
                          //         }
                          //     },
                          // }),
                          states: {
                              inactive: {
                                  opacity: 1,
                              },
                          },
                          maxPointWidth: 70,
                      }
                    : {
                          id: `${id}_compare`,
                          linkedTo: id,
                          opacity: 0.5,
                          pointPadding: 0.25,
                          enableMouseTracking: false, // disable mouse tracking to lead tooltip to the main series
                          dataLabels: {
                              enabled: false,
                          },
                          states: {
                              inactive: {
                                  opacity: 0.5,
                              },
                          },
                          maxPointWidth: 70,
                      }),
            };
        });
    };

    return [
        ...getSeries(
            series.filter((s) => s.isCompare),
            true
        ),
        ...getSeries(series.filter((s) => !s.isCompare)),
    ];
};

// new way for compare classic
// TODO Luan: if the main series is all null or zeros, render the comparison series instead
const getSeriesNonStacked = ({series, ...props}) => {
    const {tile, theme, type, yAxis, getYAxis, tileFields, hasGroupField, measurementFormatters, colors, isBarDataColorAppliedByDimension, isDimensionDate} = props;
    const groupNames = unique(series.map((s) => s.name));
    const dataLabels = tile.style.dataLabels || tile.style.yAxisBarDataLabels;
    const {dataColorPalettes} = theme.dataVisualization;
    const {colorScale, getDisplayColor} = prepareColor({
        tile,
        tileFields,
        dataColorPalettes,
        isBarDataColorAppliedByDimension,
    });

    colorScale?.setRange(0, groupNames.length - 1);

    const getSeries = (gn, i) =>
        series
            .filter((s) => s.name === gn && !s.isCompare)
            .map((s, j) => {
                // just take the main series, drawing compare series is done later

                if (isBarDataColorAppliedByDimension) {
                    colorScale?.setRange(0, s.data.length - 1);
                }

                const colorOptions = getDisplayColor({series: s, index: i});
                const indexInCustomStack = hasGroupField ? 0 : j;

                return {
                    id: generateSeriesId(s),
                    ...s,
                    ...(tile.$type === "ComboChartTile"
                        ? {
                              yAxis: getYAxis(s),
                          }
                        : tile.style.multipleAxisOption === "IndependentAxes" && {yAxis}),
                    ...(type && {type}),
                    customStack: s.stack,
                    indexInCustomStack,
                    xAxis: 0,
                    ...colorOptions,
                    pointPadding: 0.25,
                    // ...(dataLabels?.show && {
                    //     dataLabels: {
                    //         formatter: function() {
                    //             if (this.y === 0) {
                    //                 return;
                    //             }
                    //             return measurementFormatters[s.stack][indexInCustomStack](this.y);
                    //         }
                    //     },
                    // }),
                    states: {
                        inactive: {
                            opacity: 1,
                        },
                    },
                    maxPointWidth: 70,
                };
            });

    return chain(
        flatten1(groupNames.map(getSeries)),
        (_) => sort(_, (s) => (s.isCompare ? 0 : 1)) // to make rendered primary bar in front of the comparison bar
    );
};

// old way: use 2 dim axis
// const getSeriesNonStacked = ({series, ...props}) => {
//     const {tile, type, tooltipOptions, hasGroupField, measurementFormatters, colors, isCompare, isDimensionDate} = props;
//     const groupNames = unique(series.map((s) => s.name));
//     const dataLabels = tile.style.dataLabels || tile.style.yAxisBarDataLabels;
//
//     const noNeedPointPlacement = hasGroupField && !isDimensionDate;
//
//     const getSeries = (gn, i) => series.filter((s) => s.name === gn).map((s, j) => ({
//         ...s,
//         ...(type && {type}),
//         // ...(isDimensionDate && {data: sort(s.data, (d) => d[0])}),
//         xAxis: !s.isCompare ? 0 : 1,
//         ...(isCompare && {
//             pointPlacement: (noNeedPointPlacement ? 0 : i) * 0.4,
//         }),
//         ...tooltipOptions[s.stack](hasGroupField ? 0 : i),
//         // color: colors[i],
//         color: colors[s.legendIndex],
//         ...(!s.isCompare ? {
//             pointPadding: 0.2,
//             // maxPointWidth: 20,
//             // zIndex: 2,
//             id: `${i}${j}`,
//             ...(dataLabels?.show && {
//                 dataLabels: {
//                     formatter: function() {
//                         return measurementFormatters[s.stack][hasGroupField ? 0 : i](this.y);
//                     }
//                 },
//             }),
//             states: {
//                 inactive: {
//                     opacity: 1
//                 }
//             },
//             maxPointWidth: 70,
//         } : {
//             opacity: 0.7,
//             pointPadding: 0,
//             // maxPointWidth: 20,
//             linkedTo: `${i}${j}`,
//             // enableMouseTracking: false,
//             dataLabels: {
//                 enabled: false,
//             },
//             states: {
//                 inactive: {
//                     opacity: 0.7
//                 }
//             },
//             maxPointWidth: 90,
//         }),
//     }));
//
//     return chain(
//         flatten1(groupNames.map(getSeries)),
//         (_) => sort(_, (s) => s.isCompare ? 0 : 1), // to make rendered primary bar in front of the comparison bar
//     );
// };

// if with groupField then do aggregating grouping,
// (_) => isGrouped
//     ? groupSeriesByAggregation({series: _, measureAxisTitles: stackKeyTitleMap[sk]})
//     : _.map((s, i) => ({
//         yAxis: i,
//         ...s,
//     }))
