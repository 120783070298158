import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";

export const AxisScaleSelect = (state) =>
    DropdownSelect({
        registryRender: true,
        label: "Axis Scale",
        list: ["Linear", "Logarithmic"],
        isSelected: (v) => v === (state.value ? "Logarithmic" : "Linear"),
        onChange: (v) => state.onChange(v === "Logarithmic"),
    });
