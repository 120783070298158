import "./embed-code.scss";

import * as React from "react";
import {cx} from "emotion";

import {Load} from "@common/react/load";
import {Load2} from "@common/react/load2";
import {scope} from "@common/react/scope";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {spc} from "@common/react/state-path-change";
import {consumeContext} from "@common/react/context";

import {copyStringToClipboard} from "@common/utils/browser/copy-string-to-clipboard";
import {isEmpty, omit} from "@common/utils/objects";
import {Button} from "@common/form/buttons/button/button";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {serializeFilterState} from "@common/ui-components/live/live-dashboard/data/serialize/serialize-filter-state";

import {EditIcon2} from "../../../../../model/edit/main/controls/more-controls/icons/more-control-icons";
import {envAuthSegments} from "../../../../../model/edit/tabs/left-panel-overrides/column-security/column-security";

import {Collapsible} from "../../../../../getting-started/collapsible/collapsible";

import {templateEmbedCodes} from "./codes";
import {CodeIconSvg} from "./code-icon-svg";

// import {OnOffToggle} from "@common/form/toggles/on-off-toggle";
// import {StaticTooltipService} from "../../../../../common/tooltip3/static-tooltip-service";
// import {Segmented} from "@common/ui-components/segmented/segmented";
// import {InfoIconFull} from "@common/ui-components/charts/common/icons/icons";

const EmbedTypes = {
    vanilla: "Vanilla JS",
    react: "React",
    angular: "Angular",
    vue: "Vue",
};

const EMBED_CODE_TYPE_KEY = "verb-embed-code-type";

export const EmbedCode = ({collection, onClose, next}) =>
    cs(
        [
            "state",
            (_, next) =>
                UseState({
                    initValue: {
                        options: "include-filter",
                        embedCodeType: localStorage.getItem(EMBED_CODE_TYPE_KEY),
                        embedCodeSection: true,
                        sampleCodeSection: false,
                        authenMethodSection: false,
                        componentsSection: false,
                    },
                    next,
                }),
        ],
        consumeContext("authEndpoint"),
        consumeContext("apis"),
        consumeContext("routing"),
        consumeContext("collectionFilter"),
        [
            "remoteCollection",
            ({apis}, next) =>
                collection.value?.isOverview
                    ? Load2({
                          fetch: async () => apis.collection.getCollection(collection.value.id),
                          next,
                      })
                    : next(collection),
        ],
        [
            "apiKey",
            ({apis}, next) =>
                Load({
                    fetch: async () => apis.tenantEnv.getSDKApiKey(),
                    next,
                }),
        ],
        ({remoteCollection}, next) => (remoteCollection.loading ? null : next()),
        [
            "envAuthState",
            ({authEndpoint}, next) =>
                UseState({
                    // initValue: authEndpoint.value.find?.((a) => a.$type === `EndpointAuthConfig`) ? envAuthSegments[1] : envAuthSegments[0],
                    initValue: envAuthSegments[1],
                    next,
                }),
        ],
        [
            "renderSnippets",
            ({envAuthState, state, authEndpoint, apiKey, routing, remoteCollection, collectionFilter, apis}, next) =>
                next(() => {
                    // const staticAuthConfig = authEndpoint.value.find((t) => t.$type === "StaticAuthConfig");
                    const endpointAuthConfig = authEndpoint.value.find((t) => t.$type === "EndpointAuthConfig");

                    // const authItems = envAuthSegments;
                    const authAvailable = {
                        Static: remoteCollection.value.staticAuthEnabled,
                        Endpoint: !isEmpty(endpointAuthConfig?.parameters),
                        // "Static": false,
                        // "Endpoint": false
                    };

                    const template = templateEmbedCodes[state.value.embedCodeType];

                    const filterVals = remoteCollection?.value?.filters
                        .map((f) => {
                            if (!collectionFilter?.value) {
                                return f;
                            }

                            let currentFilterValue = collectionFilter?.value.find((f2) => f2.filterId == f.id);

                            if (!currentFilterValue) {
                                return {
                                    ...f,
                                    value: null,
                                };
                            }

                            return {
                                ...f,
                                value: omit(serializeFilterState(currentFilterValue.value, {filter: f}), ["columns"]),
                            };
                        })
                        .filter((f) => f.value);

                    const showFilterCodes = remoteCollection?.value.filterStyle.display === "Detached" && filterVals.length > 0;

                    return (
                        <>
                            <div className="change-embed-type-vvf">
                                <b>Framework:</b>&nbsp;
                                {EmbedTypes[state.value.embedCodeType]}&nbsp;
                                <EditIcon2 onClick={() => spc(state, ["embedCodeType"], () => null)} />
                            </div>
                            <div className="embed-code-42w">
                                {filterVals.length > 0 &&
                                    (remoteCollection.value.filterStyle.display == "SDK" ||
                                        filterVals.filter((f) => f.displayArea === "Hidden").length > 0) &&
                                    Collapsible({
                                        className: "level-1",
                                        separatedStyle: true,
                                        open: scope(state, ["sampleCodeSection"]),
                                        renderHeader: () => SectionTitle({text: "Sample"}),
                                        children: (
                                            <div className="section">
                                                <SectionItem
                                                    // title="Control filter via SDK Sample"
                                                    content={template.sample({
                                                        collection: remoteCollection.value,
                                                        filterVals,
                                                    })}
                                                />
                                            </div>
                                        ),
                                    })}

                                {Collapsible({
                                    className: "level-1",
                                    separatedStyle: true,
                                    open: scope(state, ["embedCodeSection"]),
                                    renderHeader: () => SectionTitle({text: "Embed Code"}),
                                    children: (
                                        <div className="section">
                                            <SectionItem
                                                title="Dashboard"
                                                description="Place this code where you want Verb dashboard to appear"
                                                content={template.dashboard({
                                                    collection: remoteCollection.value,
                                                    filterVals,
                                                })}
                                            />

                                            {showFilterCodes && (
                                                <SectionItem
                                                    title="Filter"
                                                    description="Place this code where you want filter to appear"
                                                    content={template.filters({
                                                        collection: remoteCollection.value,
                                                        filterVals,
                                                    })}
                                                />
                                            )}
                                        </div>
                                    ),
                                })}

                                {Collapsible({
                                    className: "level-1",
                                    separatedStyle: true,
                                    open: scope(state, ["authenMethodSection"]),
                                    renderHeader: () =>
                                        SectionTitle({
                                            text: "Authentication Method",
                                            // text: cs(
                                            //     [
                                            //         "staticTooltip",
                                            //         (_, next) =>
                                            //             StaticTooltipService({
                                            //                 direction: "top",
                                            //                 info: "Static Auth requires no setup to embed a collection but may not limit data. Active Auth requires configuration to segment data for your users. To learn more, view the documentation with the help button below",
                                            //                 next,
                                            //             }),
                                            //     ],
                                            //     ({staticTooltip}) => (
                                            //         <>
                                            //             Authentication Method&nbsp;
                                            //             {staticTooltip.renderIcon({
                                            //                 icon: InfoIconFull({
                                            //                     width: "15px",
                                            //                 }),
                                            //             })}
                                            //         </>
                                            //     )
                                            // ),
                                        }),
                                    children: (
                                        <div className="section authentication-method">
                                            {/*{Segmented({*/}
                                            {/*    items: authItems,*/}
                                            {/*    bgColor: "#11A1FD",*/}
                                            {/*    isSelected: (v) => v.value === envAuthState.value.value,*/}
                                            {/*    onChange: envAuthState.onChange,*/}
                                            {/*})}*/}

                                            {/*{envAuthState.value.value === "Static" && (*/}
                                            {/*    <SectionItem*/}
                                            {/*        title="Static Auth"*/}
                                            {/*        additionalContent={*/}
                                            {/*            <>*/}
                                            {/*                {isEmpty(staticAuthConfig?.resultMapping || {}) && remoteCollection.value?.staticAuthEnabled && (*/}
                                            {/*                    <div className="alert-messages">*/}
                                            {/*                        <div className="warning">*/}
                                            {/*                            <span className="material-icons">warning</span>*/}
                                            {/*                        </div>*/}

                                            {/*                        <div>*/}
                                            {/*                            We noticed that you haven't configured any static auth settings.*/}
                                            {/*                            <br />*/}
                                            {/*                            The static auth method will still work but no data will be segmented.*/}
                                            {/*                        </div>*/}
                                            {/*                    </div>*/}
                                            {/*                )}*/}

                                            {/*                <div className="enabled-static-auth">*/}
                                            {/*                    {collection.value?.isOverview*/}
                                            {/*                        ? OnOffToggle({*/}
                                            {/*                              state: {*/}
                                            {/*                                  value: remoteCollection.value?.staticAuthEnabled,*/}
                                            {/*                                  onChange: async (value) => {*/}
                                            {/*                                      const newValue = {*/}
                                            {/*                                          ...remoteCollection.value,*/}
                                            {/*                                          staticAuthEnabled: value,*/}
                                            {/*                                      };*/}
                                            {/*                                      remoteCollection.onChange(newValue);*/}
                                            {/*                                      await apis.collection.upsertCollection(newValue);*/}
                                            {/*                                  },*/}
                                            {/*                              },*/}
                                            {/*                          })*/}
                                            {/*                        : OnOffToggle({*/}
                                            {/*                              state: scope(remoteCollection, ["staticAuthEnabled"]),*/}
                                            {/*                          })}*/}

                                            {/*                    <div className="text">*/}
                                            {/*                        Static Authentication must be ON to enable the Static Authentication SDK for this collection.{" "}*/}
                                            {/*                    </div>*/}
                                            {/*                </div>*/}
                                            {/*            </>*/}
                                            {/*        }*/}
                                            {/*        description={*/}
                                            {/*            authAvailable.Static && (*/}
                                            {/*                <span>*/}
                                            {/*                    Place this before the <code>&lt;/body&gt;</code> closing tag or in <code>&lt;head&gt;</code> tag*/}
                                            {/*                </span>*/}
                                            {/*            )*/}
                                            {/*        }*/}
                                            {/*        content={*/}
                                            {/*            authAvailable.Static &&*/}
                                            {/*            template.script({*/}
                                            {/*                apiKey,*/}
                                            {/*                staticAuth: true,*/}
                                            {/*                authEndpoint: endpointAuthConfig,*/}
                                            {/*            })*/}
                                            {/*        }*/}
                                            {/*    />*/}
                                            {/*)}*/}

                                            {envAuthState.value.value === "Endpoint" && (
                                                <SectionItem
                                                    title={
                                                        <span
                                                            className={cx({
                                                                "danger-text": !authAvailable.Endpoint,
                                                            })}
                                                        >
                                                            Active Auth
                                                        </span>
                                                    }
                                                    additionalContent={
                                                        <>
                                                            {!authAvailable.Endpoint && (
                                                                <div className="endpoint-unavail-message">
                                                                    The active auth method embed code is not available because the active
                                                                    auth method is not configured properly.
                                                                    <br />
                                                                    <br />
                                                                    Please review the live auth method and try again.
                                                                    <br />
                                                                    <br />
                                                                    <div
                                                                        className="link"
                                                                        onClick={() =>
                                                                            routing.goto("dashboard", {
                                                                                tab: "settings",
                                                                            })
                                                                        }
                                                                    >
                                                                        Go To Auth Settings
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </>
                                                    }
                                                    description={
                                                        authAvailable.Endpoint && (
                                                            <span>
                                                                Place this before the <code>&lt;/body&gt;</code> closing tag or in{" "}
                                                                <code>&lt;head&gt;</code> tag
                                                            </span>
                                                        )
                                                    }
                                                    content={
                                                        authAvailable.Endpoint &&
                                                        template.script({
                                                            apiKey,
                                                            staticAuth: false,
                                                            authEndpoint: endpointAuthConfig,
                                                        })
                                                    }
                                                />
                                            )}
                                        </div>
                                    ),
                                })}

                                {template.components &&
                                    Collapsible({
                                        className: "level-1",
                                        separatedStyle: true,
                                        open: scope(state, ["componentsSection"]),
                                        renderHeader: () => SectionTitle({text: "Components"}),
                                        children: (
                                            <div className="section">
                                                <SectionItem
                                                    title="Dashboard"
                                                    description="Create the VerbDashboard component in your project"
                                                    content={template.components.dashboard({
                                                        collection: remoteCollection.value,
                                                        filterVals,
                                                    })}
                                                />

                                                {showFilterCodes && (
                                                    <SectionItem
                                                        title="Filter"
                                                        description="Create the VerbFilter component in your project"
                                                        content={template.components.filter({
                                                            collection: remoteCollection.value,
                                                            filterVals,
                                                        })}
                                                    />
                                                )}
                                            </div>
                                        ),
                                    })}
                            </div>
                        </>
                    );
                }),
        ],
        [
            "renderDropdown",
            ({state}, next) =>
                next(() => {
                    return (
                        <div className="select-embed-type-vb6">
                            <div className="description">Please pick a framework for the code snippets to continue.</div>
                            {DropdownSelect({
                                label: "Framework",
                                placeholder: "Select...",
                                list: Object.keys(EmbedTypes),
                                valueToLabel: (val) => EmbedTypes[val],
                                isSelected: (v) => v === state.value?.embedCodeType,
                                onChange: (v) => {
                                    localStorage.setItem(EMBED_CODE_TYPE_KEY, v);
                                    spc(state, ["embedCodeType"], () => v);
                                },
                            })}
                        </div>
                    );
                }),
        ],
        ({renderSnippets, renderDropdown, state}) =>
            next({
                template: state.value?.embedCodeType ? templateEmbedCodes[state.value.embedCodeType] : null,
                tab: {
                    id: "embed-code",
                    icon: ({selected}) => CodeIconSvg({fill: selected ? "#2692f5" : "#294661"}),
                    label: "Embed Code",
                    render: () => (state.value?.embedCodeType ? renderSnippets() : renderDropdown()),
                },
                renderButtons: () => (
                    <>
                        <Button onClick={onClose}>Done</Button>
                    </>
                ),
            })
    );

const SectionTitle = ({text}) => (
    <>
        {text}
        <div className="connect-line" />
    </>
);

const SectionItem = ({content, title, description, additionalContent}) =>
    cs(consumeContext("toast"), ({toast}) => (
        <div className="section-item">
            {title && <div className="title">{title}</div>}

            {additionalContent}

            <div className="directions">{description}</div>

            {content && (
                <>
                    <div className="controls">
                        <Button
                            btnType="secondary"
                            size="small"
                            onClick={() => {
                                copyStringToClipboard(content);
                                toast.show("Copied");
                            }}
                        >
                            Copy Code
                        </Button>
                    </div>
                    <div className="pre-code">
                        <pre>
                            {content.split("\n").map((line, i) => (
                                <span key={i}>{line}</span>
                            ))}
                        </pre>
                    </div>
                </>
            )}
        </div>
    ));
