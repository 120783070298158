exports.dateAggregations = [
    {
        label: "Day",
        value: "Days",
        format: "M/d",
    },
    {
        label: "Week",
        value: "Weeks",
        format: "M/d",
    },
    {
        label: "Month",
        value: "Months",
        format: "MMM yyyy",
    },
    {
        label: "Quarter",
        value: "Quarters",
        format: "Q yy",
    },
    {
        label: "Year",
        value: "Years",
        format: "yyyy",
    },
];
