const {setPath} = require("../../../../common/utils/arr-path");

const {handleUnauthWithRefreshAuthToken} = require("./handle-unauth-with-refresh-auth-token");
const {createFetcher} = require("../../../../tools/fetcher/fetcher");
const {initTheme} = require("../../routes/theme/edit/common/theme-init");
const {cs} = require("../../../../common/react/chain-services");
const {DataQueryFetcher2} = require("@common/api/data-query/data-query-fetcher-2");
const {getChannels} = require("@common/api/data-query/get-channels");
const {AutoRecreateStatic} = require("@common/api/data-query/auto-recreate-static");
const {urlPath} = require("../../../../tools/fetcher/url-path");
const {CookieStorage} = require("@common/logic/cookie-storage");

const createEnvApis = ({authToken, envId, tenantId, refreshAuthToken, onUnauthenticated, next}) => {
    return cs(
        [
            "dataQueryFetcher",
            ({}, next) => {
                return AutoRecreateStatic({
                    _key: `${tenantId}${envId}`,
                    create: () =>
                        DataQueryFetcher2({
                            httpConnectionOptions: {
                                headers: {
                                    "x-tenant-id": tenantId,
                                    "x-env-id": envId,
                                },
                            },
                            configBuilder: (builder, options) => {
                                return builder.withUrl(urlPath("/api/collection/data-query"), options);
                            },
                            channels: getChannels({
                                tenantId,
                                envId,
                                type: "collection",
                                // type: "render",
                            }),
                        }),
                    destroy: (v) => v.destroy(),
                    next,
                });
            },
        ],
        ({dataQueryFetcher}) =>
            next(
                (() => {
                    const fetcher = createFetcher({
                        onUnauthenticated: handleUnauthWithRefreshAuthToken({refreshAuthToken, onUnauthenticated}),
                        headers: {
                            "x-env-id": envId,
                            ...(tenantId && {
                                "x-tenant-id": tenantId,
                            }),
                        },
                    });

                    const fetcherGettingStarted = (envId) =>
                        createFetcher({
                            onUnauthenticated: handleUnauthWithRefreshAuthToken({refreshAuthToken, onUnauthenticated}),
                            headers: {
                                "x-env-id": envId,
                                ...(tenantId && {
                                    "x-tenant-id": tenantId,
                                }),
                            },
                        });

                    return {
                        tenantEnv: {
                            removeEnvironment: ({envID, confirmName}) =>
                                fetcher.delete(`/api/user/tenant/environment/${envID}/${confirmName}`),
                            getSDKApiKey: () => fetcher.get(`/api/user/tenant/sdk-key`),
                            getNotifications: () => fetcher.get(`/api/user/tenant/notifications`),
                            addNotification: (data) => fetcher.post(`/api/user/tenant/notifications`, data),
                            updateNotification: (data) => fetcher.put(`/api/user/tenant/notifications`, data),
                            testSlackConnection: () => fetcher.post(`/api/user/tenant/integrations/slack/test`),
                        },
                        collection: {
                            getFolders: () => fetcher.get("/api/collection/folders"),
                            getCollectionsSummary: () => fetcher.get(`/api/collection`),
                            upsertFolder: (folder) => fetcher.put(`/api/collection/folder`, folder),
                            changeFolder: (folderID, colID) => fetcher.post(`/api/collection/${colID}/folder?folderID=${folderID}`),
                            checkFolderName: (name, {folderId, parentFolderId} = {}) => {
                                const queryObj = {
                                    currentID: folderId,
                                    parentFolderID: parentFolderId,
                                };
                                return fetcher.put(`/api/collection/folder/available${cQueryString(queryObj)}`, {name});
                            },
                            deleteFolder: ({folderId, confirmName}) => fetcher.delete(`/api/collection/folder/${folderId}/${confirmName}`),
                            getCollection: (colId) => fetcher.get(`/api/collection/${colId}`),
                            upsertCollection: (col) => fetcher.put(`/api/collection`, col),
                            deleteCollection: ({colId, colName}) =>
                                fetcher.delete(`/api/collection/${colId}/${encodeURIComponent(colName)}`),
                            checkCollectionName: async (name, {collectionId, version} = {}) =>
                                fetcher.put(
                                    `/api/collection/available${collectionId ? "?currentID=" + encodeURIComponent(collectionId) : ""}${
                                        version ? "?version=" + encodeURIComponent(version) : ""
                                    }`,
                                    {name}
                                ),
                            getThemes: () => fetcher.get(`/api/collection/themes`),
                            getTheme: async (themeId, published = true) => {
                                const remoteTheme = await fetcher.get(`/api/collection/theme/${themeId}?published=${published}`);
                                return initTheme(remoteTheme);
                            },
                            checkThemeName: async (name, {themeId} = {}) =>
                                fetcher.put(
                                    `/api/collection/theme/available${themeId ? "?currentID=" + encodeURIComponent(themeId) : ""}`,
                                    {name}
                                ),
                            upsertTheme: (theme) => fetcher.put(`/api/collection/theme`, theme),
                            deleteTheme: (themeID, confirmName, alternateThemeID) =>
                                fetcher.delete(`/api/collection/theme/${themeID}/${encodeURIComponent(confirmName)}/${alternateThemeID}`),

                            // getTileData: ({collectionId, tileId, filterValues, page, apiKey, timeZoneId = null, extraCacheKey}) => fetcher.put(
                            //     `/api/collection/${collectionId}/data/${tileId}` + cQueryString({page, timeZoneId}),
                            //     filterValues,
                            //     (headers) => ({ ...headers, ...(apiKey ? {"x-api-key": apiKey} : {})})
                            // ),
                            // getTileData2: ({collectionId, tileId, filterValues, tableOverrides, page, apiKey, timeZoneId = null, extraCacheKey}) => fetcher.put(
                            //     `/api/collection/${collectionId}/data-2/${tileId}` + cQueryString({page}),
                            //     {filterValues, tableOverrides, timeZoneId},
                            //     (headers) => ({ ...headers, ...(apiKey ? {"x-api-key": apiKey} : {})})
                            // ),

                            getTileData: ({collectionId, tileId, isShared, filterValues, page, apiKey, timeZoneId = null, extraCacheKey}) =>
                                dataQueryFetcher.channelInvoke("tileData", {
                                    type: "collection",
                                    collectionId,
                                    tileId,
                                    isShared,
                                    filterValues,
                                    page,
                                    timeZoneId,
                                    apiKey,
                                    ignoreServerSideCache: CookieStorage.get("verb.ignore.cache.server")?.toLowerCase() == "true",
                                    ignoreWarehouseCache: CookieStorage.get("verb.ignore.cache.warehouse")?.toLowerCase() == "true",
                                }),
                            getTileData2: ({
                                collectionId,
                                isShared,
                                tileId,
                                filterValues,
                                tableOverrides,
                                page,
                                apiKey,
                                timeZoneId = null,
                                extraCacheKey,
                            }) =>
                                dataQueryFetcher.channelInvoke("tileData", {
                                    type: "collection",
                                    isShared,
                                    collectionId,
                                    tileId,
                                    filterValues,
                                    tableOverrides,
                                    page,
                                    timeZoneId,
                                    apiKey,
                                    ignoreServerSideCache: CookieStorage.get("verb.ignore.cache.server")?.toLowerCase() == "true",
                                    ignoreWarehouseCache: CookieStorage.get("verb.ignore.cache.warehouse")?.toLowerCase() == "true",
                                }),

                            getFilterData: async ({collectionId, filterId, columns, filterValues, apiKey}) => {
                                const {$values} = await dataQueryFetcher.channelInvoke("textFilterValues", {
                                    collectionId,
                                    filterId,
                                    columns,
                                    filterValues,
                                    apiKey,
                                    ignoreServerSideCache: CookieStorage.get("verb.ignore.cache.server")?.toLowerCase() == "true",
                                    ignoreWarehouseCache: CookieStorage.get("verb.ignore.cache.warehouse")?.toLowerCase() == "true",
                                });

                                return $values;
                            },
                            getNumericFilterData: ({collectionId, filterId, columns, filterValues, apiKey}) =>
                                dataQueryFetcher.channelInvoke("numericFilterRange", {
                                    collectionId,
                                    filterId,
                                    filterValues,
                                    columns,
                                    apiKey,
                                    ignoreServerSideCache: CookieStorage.get("verb.ignore.cache.server")?.toLowerCase() == "true",
                                    ignoreWarehouseCache: CookieStorage.get("verb.ignore.cache.warehouse")?.toLowerCase() == "true",
                                }),

                            downloadTileData: ({colId, tileId, filterValues, format, apiKey, timeZoneId}) =>
                                fetcher.put(
                                    `/api/collection/${colId}/data/${tileId}/download` +
                                        cQueryString({
                                            format,
                                            timeZoneId,
                                        }),
                                    filterValues,
                                    (headers) => ({...headers, ...(apiKey ? {"x-api-key": apiKey} : {})})
                                ),

                            getValidTiles: ({collectionID, filterId}) =>
                                fetcher.get(`/api/collection/${collectionID}/filter/${filterId}/valid-tiles`),
                            // Only use this when a filter id is not available AB#963
                            getValidTilesBasedOnFilterColumns: ({collectionID, filterColumns}) =>
                                fetcher.put(`/api/collection/${collectionID}/filter/valid-tiles`, filterColumns),

                            //
                            // getFilterData: ({collectionId, filterId, columns, apiKey}) => fetcher.post(
                            //     `/api/collection/${collectionId}/data/filter/${filterId}/text`,
                            //     columns,
                            //     (headers) => ({ ...headers, ...(apiKey ? {"x-api-key": apiKey} : {})})
                            // ),

                            // getNumericFilterData: ({collectionId, filterId, columns, apiKey}) => fetcher.post(
                            //     `/api/collection/${collectionId}/data/filter/${filterId}/range`,
                            //     columns,
                            //     (headers) => ({ ...headers, ...(apiKey ? {"x-api-key": apiKey} : {})})
                            // ),

                            shareCollection: ({collectionId, emails, code1, code2}) =>
                                fetcher.put(`/api/collection/${collectionId}/share`, {emails, code1, code2}),
                            getPublishInfo: ({collectionId}) => fetcher.get(`/api/collection/${collectionId}/publish`),
                            publishCollection: ({collectionId}) => fetcher.put(`/api/collection/${collectionId}/publish`),
                            getRelatedTo: (cid) => fetcher.get(`/api/collection/${cid}/related`),
                            getApiCollectionsGroup: () => fetcher.get(`/api/collection/api`),
                            getCollectionsOverview: () => fetcher.get(`/api/collection/`),
                            getDebugSteps: ({collectionID, tileID, filters}) =>
                                fetcher.put(`/api/collection/${collectionID}/debug/${tileID}`, filters),
                            getDebugStepPreview: ({collectionID, tileID, stepIndex, filters}) =>
                                fetcher.put(`/api/collection/${collectionID}/debug/${tileID}/preview/${stepIndex}`, filters),
                            destroyDataQuery: () => dataQueryFetcher.destroy(),
                        },
                        collectionTiles: {
                            getTile: (tileID) => fetcher.get(`/api/collection/tiles/${tileID}`),
                            upsertTile: (data) => fetcher.put("/api/collection/tiles", data),
                            getFolders: () => fetcher.get("/api/collection/tiles/folders"),
                            upsertFolder: (folder) => fetcher.put(`/api/collection/tiles/folder`, folder),
                            changeFolder: (folderID, tileID) => fetcher.post(`/api/collection/tiles/${tileID}/folder?folderID=${folderID}`),
                            checkFolderName: (name, {folderId, parentFolderId} = {}) => {
                                const queryObj = {
                                    currentID: folderId,
                                    parentFolderID: parentFolderId,
                                };
                                return fetcher.put(`/api/collection/tiles/folder/available${cQueryString(queryObj)}`, {name});
                            },
                            deleteFolder: ({folderId, confirmName}) =>
                                fetcher.delete(`/api/collection/tiles/folder/${folderId}/${confirmName}`),
                            deleteTile: (tileID) => fetcher.delete(`/api/collection/tiles/${tileID}/`),
                            getRelatedTo: (tileID) => fetcher.get(`/api/collection/tiles/${tileID}/related`),
                            getDebugSteps: ({tileID, filters}) => fetcher.put(`/api/collection/tiles/${tileID}/debug`, filters),
                            getDebugStepPreview: ({tileID, stepIndex, filters}) =>
                                fetcher.put(`/api/collection/tiles/${tileID}/debug/preview/${stepIndex}`, filters),
                        },
                        model: {
                            getFolders: () => fetcher.get("/api/model/folders"),
                            upsertFolder: (folder) => fetcher.put(`/api/model/folder`, folder),
                            changeFolder: (folderID, modelID) => fetcher.post(`/api/model/${modelID}/folder?folderID=${folderID}`),
                            checkFolderName: (name, {folderId, parentFolderId} = {}) => {
                                const queryObj = {
                                    currentID: folderId,
                                    parentFolderID: parentFolderId,
                                };
                                return fetcher.put(`/api/model/folder/available${cQueryString(queryObj)}`, {name});
                            },
                            deleteFolder: ({folderId, confirmName}) => fetcher.delete(`/api/model/folder/${folderId}/${confirmName}`),
                            getModel: (modelId) => fetcher.get(`/api/model/${modelId}`),
                            getModels: () => fetcher.get("/api/model"),
                            getSuggestedRelationships: (modelId) => fetcher.get(`/api/model/${modelId}/relationships/recommend`),
                            upsertModel: (model) => fetcher.put(`/api/model`, model),
                            upsertTablePosition: (modelId, data) => fetcher.put(`/api/model/${modelId}/positions`, data),
                            checkModelName: (name, {modelId}) =>
                                fetcher.put(`/api/model/available${modelId ? "?currentID=" + encodeURIComponent(modelId) : ""}`, {name}),
                            removeModel: ({modelId, confirmName}) =>
                                fetcher.delete(`/api/model/${modelId}/${encodeURIComponent(confirmName)}`),
                            getImpactedCollection: ({modelID, tables}) => fetcher.put(`/api/model/${modelID}/impacted-collections`, tables),
                            getRelatedTo: (mid) => fetcher.get(`/api/model/${mid}/related`),
                            previewTable: (modelID, modelTableID, search) =>
                                fetcher.post(`/api/model/${modelID}/preview/${modelTableID}`, search),
                            runDependencyCheckBeforeDeleteTable: ({modelID, modelTableID}) =>
                                fetcher.get(`/api/model/${modelID}/table/${modelTableID}/errors`),
                        },
                        transformation: {
                            getTransformation: (transformationId) => fetcher.get(`/api/model/transformation/${transformationId}`),
                            getAllTransformations: (modelId) => fetcher.get(`/api/model/transformation/all/model/${modelId}`),
                            upsertTransformation: (transformation) => fetcher.put(`/api/model/transformation`, transformation),
                            checkTransformationName: async (name, {transformationId} = {}) =>
                                fetcher.put(
                                    `/api/model/transformation/available${
                                        transformationId ? "?currentID=" + encodeURIComponent(transformationId) : ""
                                    }`,
                                    {name}
                                ),
                            deleteTransformation: ({transformationId, confirmName}) =>
                                fetcher.delete(`/api/model/transformation/${transformationId}/${confirmName}`),
                            getPublishInfo: (transformationId) => fetcher.get(`/api/model/transformation/${transformationId}/publish`),
                            publishTransformation: (transformationId) => fetcher.put(`/api/model/transformation/${transformationId}/publish`),
                            upsertTransformationPosition: (transformationId, data) =>
                                fetcher.put(`/api/model/transformation/${transformationId}/positions`, data),
                            previewStep: (transformationId, stepId, search) =>
                                fetcher.post(`/api/model/transformation/${transformationId}/step/${stepId}/preview`, search),
                        },
                        data: {
                            getSummary: () => fetcher.get(`/api/data?namesOnly=false`),
                            getFolders: () => fetcher.get(`/api/data/folders`),
                            upsertFolder: (folder) => fetcher.put(`/api/data/folder`, folder),
                            changeFolder: (folderID, dsID) => fetcher.post(`/api/data/${dsID}/folder?folderID=${folderID}`),
                            checkFolderName: (name, {folderId, parentFolderId} = {}) => {
                                const queryObj = {
                                    currentID: folderId,
                                    parentFolderID: parentFolderId,
                                };
                                return fetcher.put(`/api/data/folder/available${cQueryString(queryObj)}`, {name});
                            },
                            deleteFolder: ({folderId, confirmName}) => fetcher.delete(`/api/data/folder/${folderId}/${confirmName}`),
                            checkDataSourceName: (name) => fetcher.put(`/api/data/available`, {name}),
                            getDataSources: ({namesOnly} = {}) => fetcher.get(`/api/data` + (namesOnly ? "?namesOnly=true" : "")),
                            getDataSource: (dataSourceId) => fetcher.get(`/api/data/${dataSourceId}`),
                            getAllDataSources: () => fetcher.get(`/api/data/all`),
                            getDataSourceHistory: (dataSourceId) => fetcher.get(`/api/data/${dataSourceId}/upload-history`),
                            rowFilter: (data) => fetcher.post(`/api/data/query/rowfilter`, data),
                            uploadFile: async (file, dataSourceID) => {
                                if (file.size > 1048576 * 100) throw "size";

                                let nameSplit = file.name.toLowerCase().split(".");
                                if (["xlsx", "xlsm", "xlsb", "xls", "csv", "txt"].indexOf(nameSplit[nameSplit.length - 1]) == -1)
                                    throw "type";

                                let data = {File: file};

                                let formData = new FormData();

                                for (let p in data) {
                                    if (p != null) {
                                        formData.append(p, data[p]);
                                    }
                                }
                                return await fetcher.postMultipart(
                                    `/api/data/upload` + (dataSourceID ? `?dataSourceID=${encodeURIComponent(dataSourceID)}` : ``),
                                    formData
                                );
                            },

                            getErrorHistories: (dataSourceID) => fetcher.get(`/api/data/${dataSourceID}/error-history`),

                            getTablesColumns: ({dataSourceID, data}) =>
                                fetcher.put(
                                    `/api/data/query/tables` + (dataSourceID ? `?dataSourceID=${encodeURIComponent(dataSourceID)}` : ``),
                                    data
                                ),
                            upsertDataSource: (data) => fetcher.put(`/api/data`, data),
                            removeDataSource: (dataSourceID, confirmName) =>
                                fetcher.delete(`/api/data/${dataSourceID}/${encodeURIComponent(confirmName)}`),
                            getRelatedTo: (dsid) => fetcher.get(`/api/data/${dsid}/related`),
                            getTableSyncing: (dsid) => fetcher.post(`/api/data/${dsid}/tables-syncing`),
                            forceSync: (dsid, dsTables) => fetcher.post(`/api/data/${dsid}/force-sync`, dsTables),

                            downloadDataSource: (dataSourceID, versionId) => fetcher.get(`/api/data/${dataSourceID}/download/${versionId}`),

                            getTablesStatus: async (datasourceID) => {
                                return fetcher.get(`/api/data/${datasourceID}/status`);
                            },
                            getTableHistories: (dataSourceID, tableID, {start, end}) =>
                                fetcher.get(`/api/data/${dataSourceID}/tables/${tableID}/history?start=${start}&end=${end}`),
                            getTableHistoriesSummary: (dataSourceID, tableID, {start, end}) =>
                                fetcher.get(`/api/data/${dataSourceID}/tables/${tableID}/history/summary?start=${start}&end=${end}`),
                            getTableHistoriesSummaries: (dataSourceID, {start, end}) =>
                                fetcher.get(`/api/data/${dataSourceID}/history/summary?start=${start}&end=${end}`),
                            getTablesSyncing: (dataSourceID) => fetcher.post(`/api/data/${dataSourceID}/tables-syncing`),
                            clearErrorTables: (dataSourceID, data) => fetcher.post(`/api/data/${dataSourceID}/tables/clear`, data),

                            pauseTables: (dataSourceID, dataSourceIDs) =>
                                fetcher.post(`/api/data/${dataSourceID}/tables/pause`, dataSourceIDs),
                            resumeTables: (dataSourceID, dataSourceIDs) =>
                                fetcher.post(`/api/data/${dataSourceID}/tables/resume`, dataSourceIDs),
                        },
                        authEndpoint: {
                            getAuthEndpoint: () => fetcher.get("/api/user/tenant/auth"),
                            upsertAuthEndpoint: (authEndpoint) => fetcher.put("/api/user/tenant/auth", authEndpoint),
                            authorizeTenantGGDrive: (code) => {
                                return fetcher.post(`/api/user/tenant/google/authorize?code=${encodeURIComponent(code)}`, null, (headers) =>
                                    setPath(headers, ["x-google-redirect-uri"], window.location.origin)
                                );
                            },
                        },
                        accessKey: {
                            getFolders: () => fetcher.get(`/api/user/tenant/data-access-api-key/folders`),
                            upsertFolder: (folder) => fetcher.put(`/api/user/tenant/data-access-api-key/folder`, folder),
                            changeFolder: (folderID, colID) =>
                                fetcher.post(`/api/user/tenant/data-access-api-key/${colID}/folder?folderID=${folderID}`),
                            checkFolderName: (name, {folderId, parentFolderId} = {}) => {
                                const queryObj = {
                                    currentID: folderId,
                                    parentFolderID: parentFolderId,
                                };
                                return fetcher.put(`/api/user/tenant/data-access-api-key/folder/available${cQueryString(queryObj)}`, {
                                    name,
                                });
                            },
                            deleteFolder: ({folderId, confirmName}) =>
                                fetcher.delete(`/api/user/tenant/data-access-api-key/folder/${folderId}/${confirmName}`),
                            getAccessApiKeys: () => fetcher.get(`/api/user/tenant/data-access-api-keys`),
                            upsertAccessApiKey: (body) => fetcher.put(`/api/user/tenant/data-access-api-key`, body),
                            checkAccessApiKeyName: (name, {currentID} = {}) =>
                                fetcher.put(
                                    `/api/user/tenant/data-access-api-key/available${
                                        currentID ? "?currentID=" + encodeURIComponent(currentID) : ""
                                    }`,
                                    {name}
                                ),
                            removeAccessApiKey: (keyID) => fetcher.delete(`/api/user/tenant/data-access-api-key/${keyID}`),
                            share: (keyID, email) => fetcher.post(`/api/user/tenant/data-access-api-key/${keyID}/share`, email),
                            rollKey: (ID) => fetcher.post(`/api/user/tenant/data-access-api-key/${ID}/roll`),
                            //iframe key
                            // getIframeKeys: () => fetcher.get(`/api/user/tenantdata-access-iframe-keys`),
                            // checkIframeKeyName: (name, {currentID} = {}) => fetcher.put(`/api/user/tenantdata-access-iframe-key/available${currentID ? "?currentID=" + encodeURIComponent(currentID) : ""}`, {name}),
                            // upsertIframeKey: (body) => fetcher.put(`/api/user/tenantdata-access-iframe-key`, body),
                            // shareIframe: (keyID, email) => fetcher.post(`/api/user/tenantdata-access-iframe-key/${keyID}/share`, email),
                            // removeIframeKey: (keyID) => fetcher.delete(`/api/user/tenantdata-access-iframe-key/${keyID}`),
                            // rollIframeKey: (ID) => fetcher.post(`/api/user/tenantdata-access-iframe-key/${ID}/roll`),
                        },
                        previewProfile: {
                            getFolders: () => fetcher.get(`/api/user/tenant/data-access-preview-profile-key/folders`),
                            getProfiles: () => fetcher.get(`/api/user/tenant/data-access-preview-profile-keys`),
                            upsertFolder: (folder) => fetcher.put(`/api/user/tenant/data-access-preview-profile-key/folder`, folder),
                            upsertProfile: (profile) => fetcher.put(`/api/user/tenant/data-access-preview-profile-key`, profile),
                            changeFolder: (folderID, colID) =>
                                fetcher.post(`/api/user/tenant/data-access-preview-profile-key/${colID}/folder?folderID=${folderID}`),
                            checkFolderName: (name, {folderId, parentFolderId} = {}) => {
                                const queryObj = {
                                    currentID: folderId,
                                    parentFolderID: parentFolderId,
                                };
                                return fetcher.put(
                                    `/api/user/tenant/data-access-preview-profile-key/folder/available${cQueryString(queryObj)}`,
                                    {name}
                                );
                            },
                            deleteFolder: ({folderId, confirmName}) =>
                                fetcher.delete(`/api/user/tenant/data-access-preview-profile-key/folder/${folderId}/${confirmName}`),
                            removeProfile: (id) => fetcher.delete(`/api/user/tenant/data-access-preview-profile-key/${id}`),
                            checkProfileName: (name, {currentID} = {}) =>
                                fetcher.put(
                                    `/api/user/tenant/data-access-preview-profile-key/available${
                                        currentID ? "?currentID=" + encodeURIComponent(currentID) : ""
                                    }`,
                                    {name}
                                ),
                        },
                        iframeKey: {
                            getFolders: () => fetcher.get(`/api/user/tenant/data-access-iframe-key/folders`),
                            upsertFolder: (folder) => fetcher.put(`/api/user/tenant/data-access-iframe-key/folder`, folder),
                            changeFolder: (folderID, colID) =>
                                fetcher.post(`/api/user/tenant/data-access-iframe-key/${colID}/folder?folderID=${folderID}`),
                            checkFolderName: (name, {folderId, parentFolderId} = {}) => {
                                const queryObj = {
                                    currentID: folderId,
                                    parentFolderID: parentFolderId,
                                };
                                return fetcher.put(`/api/user/tenant/data-access-iframe-key/folder/available${cQueryString(queryObj)}`, {
                                    name,
                                });
                            },
                            deleteFolder: ({folderId, confirmName}) =>
                                fetcher.delete(`/api/user/tenant/data-access-iframe-key/folder/${folderId}/${confirmName}`),
                            getIframeKeys: () => fetcher.get(`/api/user/tenant/data-access-iframe-keys`),
                            checkIframeKeyName: (name, {currentID} = {}) =>
                                fetcher.put(
                                    `/api/user/tenant/data-access-iframe-key/available${
                                        currentID ? "?currentID=" + encodeURIComponent(currentID) : ""
                                    }`,
                                    {name}
                                ),
                            upsertIframeKey: (body) => fetcher.put(`/api/user/tenant/data-access-iframe-key`, body),
                            shareIframe: (keyID, email) => fetcher.post(`/api/user/tenant/data-access-iframe-key/${keyID}/share`, email),
                            removeIframeKey: (keyID) => fetcher.delete(`/api/user/tenant/data-access-iframe-key/${keyID}`),
                            rollIframeKey: (ID) => fetcher.post(`/api/user/tenant/data-access-iframe-key/${ID}/roll`),
                        },
                        gettingStarted: {
                            upsertModel: (model, envID) => fetcherGettingStarted(envID).put(`/api/model`, model),
                            getDataSources: ({namesOnly} = {}, envID) =>
                                fetcherGettingStarted(envID).get(`/api/data` + (namesOnly ? "?namesOnly=true" : "")),
                            getModels: (envID) => fetcherGettingStarted(envID).get(`/api/model`),
                            checkModelName: (name, envID) => fetcherGettingStarted(envID).put(`/api/model/available`, {name}),
                            getFolders: (envID) => fetcherGettingStarted(envID).get("/api/collection/folders"),
                            getThemes: (envID) => fetcherGettingStarted(envID).get(`/api/collection/themes`),
                            checkCollectionName: async (name, {collectionId, version} = {}, envID) =>
                                fetcherGettingStarted(envID).put(
                                    `/api/collection/available${collectionId ? "?currentID=" + encodeURIComponent(collectionId) : ""}${
                                        version ? "?version=" + encodeURIComponent(version) : ""
                                    }`,
                                    {name}
                                ),
                            upsertCollection: (col, envID) => fetcherGettingStarted(envID).put(`/api/collection`, col),
                            upsertFolder: (folder, envID) => fetcherGettingStarted(envID).put(`/api/collection/folder`, folder),
                            checkFolderName: (name, {folderId, parentFolderId} = {}, envID) => {
                                const queryObj = {
                                    currentID: folderId,
                                    parentFolderID: parentFolderId,
                                };
                                return fetcherGettingStarted(envID).put(`/api/collection/folder/available${cQueryString(queryObj)}`, {
                                    name,
                                });
                            },
                        },
                        environment: {
                            getEnvironmentOverview: () => fetcher.get(`/api/user/tenant/environment-overview`),
                        },
                        theme: {
                            checkFolderName: (name, {folderId, parentFolderId} = {}) => {
                                const queryObj = {
                                    currentID: folderId,
                                    parentFolderID: parentFolderId,
                                };
                                return fetcher.put(`/api/collection/theme/folder/available${cQueryString(queryObj)}`, {name});
                            },
                            upsertFolder: (folder) => fetcher.put(`/api/collection/theme/folder`, folder),
                            changeFolder: (folderID, themeID) =>
                                fetcher.post(`/api/collection/theme/${themeID}/folder?folderID=${folderID}`),
                            getFolders: () => fetcher.get(`/api/collection/theme/folders`),
                            deleteFolder: ({folderId, confirmName}) =>
                                fetcher.delete(`/api/collection/theme/folder/${folderId}/${confirmName}`),
                            getPublishInfo: (themeID) => fetcher.get(`/api/collection/theme/${themeID}/publish`),
                            publishTheme: (themeID) => fetcher.put(`/api/collection/theme/${themeID}/publish`),
                        },
                    };
                })()
            )
    );
};
exports.createEnvApis = createEnvApis;

const cQueryString = (queryObj) => {
    const query = Object.entries(queryObj)
        .map(([key, value]) => value && `${key}=${encodeURIComponent(value)}`) // string value only
        .filter((v) => v)
        .join("&");
    return query ? "?" + query : "";
};
exports.cQueryString = cQueryString;
