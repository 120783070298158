import "./data-labels.scss";

import * as React from "react";

import {ShowHideToggle} from "../../../../../../../../../../../../../common/form/toggles/show-hide-toggle";
import {scope} from "@common/react/scope";
import {CheckboxLine} from "../../../../../../../../../../common/checkbox-line/checkbox-line";
import {CheckboxLineGroup} from "../../../../../../../../../../common/checkbox-line-group/checkbox-line-group";
import {stateToCheckboxLineGroup} from "../../../../../../../../../../common/checkbox-line-group/state-to-checkbox-line-group";
import {stringListState} from "../../../../../../../../../../common/data-logic/string-list-state";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {stateToSelect} from "@common/ui-components/form/state-to-select";
import {getPath} from "@common/utils/arr-path";
import {FieldInfoProvider} from "../../../common/field-info-provider/field-info-provider";
import {MobileWarning} from "../../../common/mobile-warning/mobile-warning";
import {isOutsideSliceLabelsHidden} from "../../display-size-rules";
import {comparisonObserver} from "../../../../../../../comparison-observer";
import {LpColorLine} from "../../../../../../../../../../common/left-panel/lp-color-line";
import {DebounceNumberInput} from "../../../../../../../../../../../../../common/form/debounce-input/debounce-number-input";
import {HiddenFieldAlert} from "../../../common/hidden-field-alert/hidden-field-alert";

export const DataLabels = ({tile, size}) => ({
    label: "Slice labels",
    control: ShowHideToggle({
        state: scope(tile, ["style", "dataLabels", "show"]),
    }),
    render: () => {
        const enableConnectorLines = getPath(tile.value, ["style", "dataLabels", "position"])?.startsWith("Outside");

        return (
            <div className="pie-chart-data-labels-fg5">
                <div className="panel">
                    {CheckboxLine({
                        label: "Label",
                        state: scope(tile, ["style", "dataLabels", "categoryShown"]),
                    })}

                    {CheckboxLineGroup({
                        list: [
                            {label: "Value", name: "Value"},
                            {label: "Percent", name: "Percent"},
                        ].filter((v) => v),
                        ...stateToCheckboxLineGroup(stringListState(scope(tile, ["style", "dataLabels", "metrics"])), ["name"]),
                    })}
                </div>
                <div className="subsection">
                    <div className="header">
                        Other Options
                        {MobileWarning({
                            info: "Outside labels will not be shown on mobile devices.",
                        })}
                    </div>
                    <div className="content">
                        {(() => {
                            // console.log("edit tile compare", comparisonObserver.get(tile.value.id))
                            return FieldInfoProvider({
                                className: "slice-labels-position-select",
                                info: (
                                    <div>
                                        Outside labels are only available when the chart is at least 7 squares wide and 4 squares high when no legend or left/right vertical legend
                                        is shown. It must be 5 squares high for other legend positions.
                                        <br />
                                        <br />
                                        Fully inside will only display when the label fits fully inside the slice.
                                    </div>
                                ),
                                topOffset: 82,
                                field: DropdownSelect({
                                    label: "Position",
                                    list: [
                                        {
                                            label: "Fully Inside",
                                            value: "InsideMiddle",
                                        },
                                        {label: "Inside", value: "InsideRight"},
                                        {
                                            label: "Outside",
                                            value: "OutsideAbove",
                                            disabled: isOutsideSliceLabelsHidden({
                                                size,
                                                legendStyle: tile.value.style.legendStyle,
                                                titleStyle: tile.value.style.title,
                                                isCompare: comparisonObserver.get(tile.value.id),
                                            }),
                                        },
                                    ],
                                    ...stateToSelect(scope(tile, ["style", "dataLabels", "position"]), ["value"]),
                                    valueToLabel: (v) => (v.disabled ? v.label + " (unavailable)" : v.label),
                                }),
                            });
                        })()}

                        {getPath(tile.value, ["style", "dataLabels", "position"]) !== "InsideMiddle" &&
                            CheckboxLine({
                                label: "Allow Overlapping Labels",
                                state: scope(tile, ["style", "dataLabels", "allowOverlap"]),
                            })}
                    </div>
                </div>

                {/*<div className="subsection">*/}
                {/*    <div className="header">*/}
                {/*        Metrics*/}
                {/*        <div className="control">*/}
                {/*            {ShowHideToggle({state: scope(tile, ["style", "dataLabels", "metricShown"])})}*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    {getPath(tile.value, ["style", "dataLabels", "metricShown"]) && (*/}
                {/*        <div className="content">*/}
                {/*            {CheckboxLineGroup({*/}
                {/*                list: [*/}
                {/*                    {label: "Percent", name: "Percent"},*/}
                {/*                    {label: "Value", name: "Value"},*/}
                {/*                    tile.value.$type === "DonutChartTile" && {label: "Show Total", name: "Total"},*/}
                {/*                ].filter(v => v),*/}
                {/*                ...stateToCheckboxLineGroup(stringListState(scope(tile, ["style", "dataLabels", "metrics"])), ["name"]),*/}
                {/*            })}*/}
                {/*        </div>*/}
                {/*    )}*/}
                {/*</div>*/}

                <div className="subsection">
                    <div className="header">
                        Connector Lines
                        <div className="control">
                            {enableConnectorLines
                                ? ShowHideToggle({
                                      state: scope(tile, ["style", "calloutLineShown"]),
                                  })
                                : HiddenFieldAlert({
                                      info: "Connector Lines are only available when the Slice Label Position is set to Outside.",
                                  })}
                        </div>
                    </div>
                    {enableConnectorLines && getPath(tile.value, ["style", "calloutLineShown"]) && (
                        <div className="content">
                            {(() => {
                                const {value, onChange} = scope(tile, ["style", "calloutLineWidth"]);
                                return DebounceNumberInput({
                                    state: {
                                        value: value || 1,
                                        onChange,
                                    },
                                    label: "Line Thickness",
                                    unit: "pixels",
                                });
                            })()}
                            {(() => {
                                const {value, onChange} = scope(tile, ["style", "calloutLineColorRGB"]);
                                return LpColorLine({
                                    label: "Border Color",
                                    state: {
                                        value: value || "#EAEAEA",
                                        onChange,
                                    },
                                });
                            })()}
                        </div>
                    )}
                </div>
            </div>
        );
    },
});
