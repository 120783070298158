import {cs} from "@common/react/chain-services";
import {DialogService} from "../../../../../common/dialog/dialog-service";
import {TextInput} from "../../../../../../../../common/form/text-input/text-input";
import {ffToBasicInput} from "../../../../../../../../common/form/ff-to-basic-input";
import {settingKey} from "../../settings-constants";
import {SearchableSelect} from "@common/ui-components/searchable-select/searchable-select";
import {ffToDropdown} from "../../../../../../../../common/form/ff-to-dropdown";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {keyToArray} from "../../data-access-key/manage-key/manage-access-key-dialog";
import {ButtonIcon} from "../../../../../../../../common/form/buttons/button-icon/button-icon";
import {TrashIcon} from "../../../../../common/icons/trash-icon";
import {PlusIcon} from "@common/ui-components/icons/global-icons";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";
import React from "react";
import {SelectFolderService} from "../../../collections/folder-cm/select-folder-service/select-folder-service";
import {Form2} from "@common/react/cs-form/form2";
import {required} from "@common/react/cs-form/validators/required";
import {consumeContext} from "@common/react/context";
import {UseState} from "@common/react/use-state";
import "../../data-access-key/manage-key/manage-access-key-dialog.scss";
import {Button} from "../../../../../../../../common/form/buttons/button/button";
import {AccessKeyParameter} from "../../data-access-key/manage-key/key-parameters/access-key-parameters";

export const ManagePreviewProfileDialog = ({next: rootNext}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    render: ({resolve, args: {profile, folders}}) => ({
                        width: 480,
                        content: next({
                            profile,
                            folders,
                            resolve,
                        }),
                        initShow: true,
                        title: "CREATE NEW PREVIEW PROFILE",
                        className: "manage-access-key-dialog-wrapper-a23",
                    }),
                    registryRender: true,
                    next: rootNext,
                }),
        ],
        ({modal}) =>
            cs(
                consumeContext("apis"),
                ["profile", (_, next) => UseState({next, initValue: modal.profile})],
                ["saving", (_, next) => UseState({next, initValue: false})],
                [
                    "form",
                    ({apis, profile, saving}, next) =>
                        Form2({
                            fields: {
                                name: {
                                    transforms: ["trim"],
                                    validators: [required],
                                    asyncValidators: [
                                        {
                                            validate: (name) =>
                                                apis.previewProfile.checkProfileName(name, {
                                                    currentID: modal.profile.id,
                                                }),
                                            getMessage: () => `Existed`,
                                        },
                                    ],
                                    debounce: true,
                                },
                            },
                            data: profile,
                            onSubmit: async () => {
                                saving.onChange(true);
                                const resp = await apis.previewProfile.upsertProfile(profile.value);
                                modal.resolve(resp);
                            },
                            next,
                        }),
                ],
                [
                    "selectFolderService",
                    ({form}, next) =>
                        SelectFolderService({
                            apiType: "accessKey",
                            state: form.field("folderID").state,
                            label: "Select a folder",
                            folders: modal.folders,
                            next,
                        }),
                ],
                ({selectFolderService, form, profile, saving}) => {
                    return (
                        <div className="manage-access-key-dialog-z33 main">
                            <VerbDialogBodyScrollbar>
                                <div className="input-wrapper">
                                    {TextInput({
                                        label: "Profile Name",
                                        placeholder: "e.g. Manager",
                                        ...ffToBasicInput(form.field("name")),
                                    })}
                                </div>

                                {selectFolderService?.render?.()}

                                {AccessKeyParameter({params: profile})}
                            </VerbDialogBodyScrollbar>

                            <div className="buttons">
                                <Button btnType="secondary" onClick={() => modal.resolve()}>
                                    Cancel
                                </Button>
                                <Button disabled={!form.valid || saving.value} onClick={() => form.submit()}>
                                    Save
                                </Button>
                            </div>
                        </div>
                    );
                }
            )
    );
