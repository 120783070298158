import {cs} from "@common/react/chain-services";
import {DropZone} from "./drop-zone";
import "./upload-flat-file.scss";
import {consumeContext} from "@common/react/context";

export const UploadFlatFile = ({title, data, dsApi, next}) =>
    cs(consumeContext("routing"), ({routing}) => {
        return next({
            render: () => {
                return (
                    <div className="upload-flat-file-88o">
                        <div className="col-left">
                            <div className="header">{title ?? `Upload File`}</div>

                            {DropZone({data, dsApi})}
                        </div>
                        <div className="col-right">
                            <div className="label large">Importing flat data files</div>

                            <div className="main">
                                <div className="content">
                                    <div>Files must include headers in the first row and we encourage you to name tabs clearly.</div>
                                    <div>
                                        You may upload a new version of this static file in the future. Just select import data. The new
                                        data file will replace all of the data from your previous file.
                                    </div>
                                    <div>The file must be no more than 100 MB in size.</div>
                                </div>

                                {routing.routeName !== "add-data-source" && (
                                    <div className="content-box">
                                        <span className="material-icons">info</span>

                                        <div className="right">
                                            <div className="label">Need to append data to a flat file data source?</div>
                                            <div className="text">
                                                Add the additional data as a{" "}
                                                <a
                                                    className="link"
                                                    onClick={() =>
                                                        routing.goto("add-data-source", {
                                                            envId: routing.params.envId,
                                                            defaultDataSource: "file",
                                                        })
                                                    }
                                                >
                                                    new data source
                                                </a>{" "}
                                                then use the append rows transformation in your model to combine the 2 data sources.
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                );
            },
        });
    });
