exports.tileFieldAttrs = {
    VerticalBarChartTile: ["xAxisField", "yAxisFields", "groupField"],
    HorizontalBarChartTile: ["yAxisField", "xAxisFields", "groupField"],
    LineChartTile: ["xAxisField", "yAxisFields", "groupField"],
    ScatterPlotTile: ["dimensionField", "xAxisField", "yAxisField", "colorGroupField", "shapeGroupField"],
    BubbleChartTile: ["dimensionField", "xAxisField", "yAxisField", "zAxisField", "colorGroupField", "shapeGroupField"],
    PieChartTile: ["groupField", "valueField"],
    DonutChartTile: ["groupField", "valueField"],
    DownloadReportTile: ["actualValueFields", "aggregatedValues"],
    TableTile: ["actualValueFields", "aggregatedValues"],
    ComboChartTile: ["xAxisField", "yAxisBarFields", "yAxisLineFields", "groupField"],
    GaugeTile: ["valueField", "targetValueField"],
    SingleKPITile: ["valueField"],
    TableKPITile: ["valueField", "groupField"],
    ListKPITile: ["valueFields"],
    ApiTile: ["actualValueFields", "aggregatedValues"],
    MapTile: ["countryField", "provinceField", "countyField", "postalCodeField", "valueFields", "groupField"],
    ContainerTile: ["tiles"],
    PivotTableTile: ["rowFields", "columnFields", "valueFields"],
    FunnelChartTile: ["valueField", "groupField"],
    VennDiagramTile: ["valueField", "groupField"],
    SectionTile: [],
    TextTile: [],
    TextLabelTile: ["valueField"],
};

const fieldAttrTypes = {
    SingleField: "SingleField",
    MultiField: "MultiField",
    FieldGroup: "FieldGroup",
};
exports.fieldAttrTypes = fieldAttrTypes;

const getFieldAttrType = (attr, tileType) => {
    // all fields of these tiles are multi field well
    if (["PivotTableTile", "ListKPITile"].includes(tileType)) {
        return fieldAttrTypes.MultiField;
    }
    // these are multi field well
    if (["actualValueFields", "aggregatedValues"].includes(attr)) {
        return fieldAttrTypes.MultiField;
    }
    // field attrs that ends with "Fields" apart from the above are all measure field groups
    if (attr.includes("Fields")) {
        return fieldAttrTypes.FieldGroup;
    }
    // field attrs apart from the above are single field well
    return fieldAttrTypes.SingleField;
};
exports.getFieldAttrType = getFieldAttrType;
