import {createContext} from "react";
import {registryFactory} from "../registry/registry-factory";

const {Provider, Consumer} = createContext();

export const DropdownProvider = Provider;
export const DropdownConsumer = Consumer;

export const dropdownRegistry = registryFactory({
    className: "dropdown-registry",
    Provider: DropdownProvider,
});
