import {cs} from "../../../../../../../react/chain-services";
import {ChipInput} from "../../../../../../../form/chip-input/chip-input";

export const NumericChipInput = ({filter, form, next}) => cs(
    () => {
        const state = form.field("list").state;
        return next({
            mobileFilter: {
                label: filter?.label,
                shortPreview: () => state.value ?? '',
                preview: () => state.value ?? ''
            },
            control: ({}) => [
                ChipInput({
                    state,
                    numericOnly: true
                })
            ]
        })
    }
)