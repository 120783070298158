const defaultTheme = {
    backgroundColorRGB: "#FFFFFF",
    tileSpacing: "Narrow",
    font: "Roboto",
    fontColorRGB: "#000000",
    fontSizeGroup: "Medium",
    buttonFontColorRGB: "#FFFFFF",
    buttonColorRGB: "#2692F5",
    buttonHoverColorRGB: "#49A7FD",
    tileBackgroundColorRGB: "#FFFFFF",
    tileBorderColorRGB: "#E9ECEF",
    tileCornerRadius: 5,
    tileBorderWidth: 1,
    dataColorPalette: [
        "#0074F0",
        "#D457D0",
        "#FF4E87",
        "#FF8638",
        "#C2B700",
        "#35C06D",
        "#00ECFD",
        "#1172A6",
        "#9F329B",
        "#E62D68",
        "#D96417",
        "#888211",
        "#0F8A40",
        "#10A0C3",
        "#01456A",
        "#6C0E69",
        "#E40149",
        "#A74301",
        "#574300",
        "#006320",
        "#0C7089",
    ],
    emptyFillColorRGB: "#E9ECEF",
    tableBorderWidth: 1,
    tableBorderColorRGB: "#EAEAEA",
    tableCellColorRGB: "#EAEAEA",
    tableAlternateType: "None",
    tableAltCellColorRGB: null,
    barCornerRadius: 2,
    barCornerRadiusLocation: "All",
    areaLineChartFill: "Semitransparent",
    legendSymbol: "Circle",
    titleFontSize: "Medium",
    valueFontColorGoodRGB: "#18C96E",
    valueFontColorBadRGB: "#E95A5A",

    container: {
        button: {
            backgroundColorRGB: "#FFFFFF",
            borderColorRGB: "#FFFFFF",
            fontColorRGB: "#000000",
            hoverBackgroundColorRGB: "#FFFFFF",
            hoverBorderColorRGB: "#000000",
            hoverFontColorRGB: "#000000",
            activeBackgroundColorRGB: "#000000",
            activeBorderColorRGB: "#000000",
            activeFontColorRGB: "#FFFFFF",
            borderWidth: 2, // [0, 5]
            cornerRadius: 20, // [0, 30]
        },
        tab: {
            backgroundColorRGB: "#FFFFFF",
            borderColorRGB: "#FFFFFF",
            fontColorRGB: "#000000",
            bottomBorderHeight: 0, // min 0
            hoverBackgroundColorRGB: "#E9F4FE",
            hoverBorderColorRGB: "#E9F4FE",
            hoverFontColorRGB: "#898B90",
            activeBackgroundColorRGB: "#E9F4FE",
            activeBorderColorRGB: "#2692F5",
            activeFontColorRGB: "#2692F5",
        },
        dropDown: {
            backgroundColorRGB: "#FFFFFF",
            borderColorRGB: "#C0C0C0",
            fontColorRGB: "#6D6F78",
            borderWidth: 1, // [0, 5]
            cornerRadius: 20, // [0, 30]
            activeBackgroundColorRGB: "#FFFFFF",
            activeBorderColorRGB: "#000000",
            activeFontColorRGB: "#000000",
            hoverBackgroundColorRGB: "#E9ECEF",
            shadowSize: 4, // [0, 5]
            shadowTransparency: 15, // [0, 100]
        },
    },

    tooltipHover: {
        theme: "Light",
        position: "Above",
        // indicatorLineShown: true,
        indicatorLineStyle: "Solid",
        indicatorLineColor: "#000000",
        indicatorLineWidth: 0.5,
    },
};
exports.defaultTheme = defaultTheme;
