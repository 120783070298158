import * as React from "react";
import {cs} from "@common/react/chain-services";
import "./table-column-line.scss";
import {rFieldTypeIcon} from "../../../../../common/field-type-icon/get-field-type-icon";
import {TruncatingTooltip} from "../../../../../common/truncating-tooltip/truncating-tooltip";

export const TableColumnLine = ({color, table, column}) => {
    return (
        <div className="table-column-line-8sf table-column-line" style={{border: `1px solid ${color}`}}>
            {cs(["truncatingTooltip", (_, next) => TruncatingTooltip({next})], ({truncatingTooltip}) => {
                const tooltipContent = (
                    <div className="table-tooltip-75s">
                        <img
                            className="table-icon"
                            src={
                                table.$type === "ViewModelTable" ? require("../../../../../common/icons/data-view-icon.svg") : require("../../../../../common/icons/table-icon.svg")
                            }
                            alt=""
                        />
                        {table.visualName}
                    </div>
                );
                return (
                    <div
                        {...{
                            className: "table",
                            ...truncatingTooltip.tooltip(tooltipContent),
                        }}
                    >
                        <img
                            className="table-icon"
                            src={
                                table.$type === "ViewModelTable" ? require("../../../../../common/icons/data-view-icon.svg") : require("../../../../../common/icons/table-icon.svg")
                            }
                            alt=""
                        />
                        <div
                            {...{
                                className: "text",
                                ref: truncatingTooltip.ref,
                            }}
                        >
                            {table.visualName}
                        </div>
                    </div>
                );
            })}
            {cs(["truncatingTooltip", (_, next) => TruncatingTooltip({next})], ({truncatingTooltip}) => {
                const tooltipContent = (
                    <div className="column-tooltip-68d">
                        {rFieldTypeIcon(column.dataType)}
                        {column.name}
                    </div>
                );
                return (
                    <div
                        {...{
                            className: "column",
                            ...truncatingTooltip.tooltip(tooltipContent),
                        }}
                    >
                        {rFieldTypeIcon(column.dataType)}
                        <div
                            {...{
                                className: "text",
                                ref: truncatingTooltip.ref,
                            }}
                        >
                            {column.name}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
