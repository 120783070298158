import {TileTitle} from "../common/tile-title/tile-title";
import {Design} from "../table/panels/design/design";
import {OtherOptions} from "../common/other-options/other-options";
import {PivotTableFormatting} from "./pivot-table-formatting";

export const PivotTable = ({tile, size, form, tableFieldConfigOverride, parentTile, tileAction, isContainerTile}) =>
    [TileTitle, Design, PivotTableFormatting, OtherOptions].map((panel) =>
        panel({
            tile,
            size,
            form,
            tableFieldConfigOverride,
            parentTile,
            tileAction,
            isContainerTile,
        })
    );
