import {cs} from "@common/react/chain-services";
import {provideContext} from "@common/react/context";
import {scope} from "@common/react/scope";
import {isSameField} from "@common/ui-components/charts/factory/is-same-field";
import * as React from "react";
import {Expandable} from "../../../../../../../common/expandable/expandable";
import {getTileActionsUpdated} from "../actions/get-tile-actions-updated";
import {getTileFields} from "./common/get-tile-fields";
import {Limit} from "./common/limit-sorting/limit/limit";
import {getColumnType} from "./common/select-field/select-field";
import {SingleFieldWell} from "./common/single-field-well/single-field-well";
import {updateSingleFieldInTile} from "./common/update-tile";
import "./tile-fields.scss";

export const BubbleChartFields = ({tile, isSubTile, form, next, tileActions, debugFunction}) => {
    return cs(
        [
            "tileFields",
            ({}, next) =>
                next(
                    getTileFields({
                        tile: tile.value,
                        dimensionAttr: "dimensionField",
                        measureSingleAttrs: ["xAxisField", "yAxisField", "zAxisField"],
                        groupFieldAttr: "colorGroupField",
                    })
                ),
        ],
        ({tileFields}, next) => provideContext("tileFields", tileFields, next),

        [
            "fieldsSection",
            ({tileFields}, next) => {
                const measureFieldColumnFilter = (column) => {
                    if (tile.value.dimensionField != null) {
                        return true;
                    }
                    const type = getColumnType(column);
                    return type === "number";
                };

                const cOnUpdateMeasureField = (measureAttr) => (newField, oriField) => {
                    return tile.onChange({
                        ...tile.value,
                        [measureAttr]: newField,
                        // update limit if it is the measure field
                        limit: isSameField(tile.value.limit, oriField) ? null : tile.value.limit,
                    });
                };

                return cs(
                    [
                        "dimensionField",
                        (_, next) => {
                            return SingleFieldWell({
                                field: scope(tile, ["dimensionField"]),
                                $type: "CategoryTileField",
                                label: "Bubble Aggregator",
                                info: "The bubble aggregator is used to aggregate measure values.",
                                onUpdateTile: (newVal, oriVal) => {
                                    updateSingleFieldInTile({
                                        fieldAttr: "dimensionField",
                                        fieldVal: {newVal, oriVal},
                                        tileFields,
                                        tile,
                                        updateLimit: ({tile, fieldVal: {newVal, oriVal}}) => {
                                            return {
                                                limit: isSameField(tile.value.limit, oriVal) ? null : tile.value.limit,
                                            };
                                        },
                                    });
                                },
                                next,
                            });
                        },
                    ],
                    [
                        "xAxisField",
                        (_, next) => {
                            const field = scope(tile, ["xAxisField"]);
                            return SingleFieldWell({
                                field,
                                $type: "MeasureTileField",
                                label: "X-Axis",
                                filterColumn: measureFieldColumnFilter,
                                allowAllFieldTypesWhenChangingField: true,
                                onUpdateTile: cOnUpdateMeasureField("xAxisField"),
                                notAllowNoneAggregation: true,
                                next,
                            });
                        },
                    ],
                    [
                        "yAxisField",
                        (_, next) => {
                            const field = scope(tile, ["yAxisField"]);
                            return SingleFieldWell({
                                field,
                                $type: "MeasureTileField",
                                label: "Y-Axis",
                                filterColumn: measureFieldColumnFilter,
                                allowAllFieldTypesWhenChangingField: true,
                                onUpdateTile: cOnUpdateMeasureField("yAxisField"),
                                notAllowNoneAggregation: true,
                                next,
                            });
                        },
                    ],
                    [
                        "zAxisField",
                        (_, next) => {
                            const field = scope(tile, ["zAxisField"]);
                            return SingleFieldWell({
                                field,
                                $type: "MeasureTileField",
                                label: (
                                    <span>
                                        Bubble Size <i style={{textTransform: "none"}}>(optional)</i>
                                    </span>
                                ),
                                fieldLabel: "Bubble Size",
                                filterColumn: measureFieldColumnFilter,
                                allowAllFieldTypesWhenChangingField: true,
                                onUpdateTile: cOnUpdateMeasureField("zAxisField"),
                                notAllowNoneAggregation: true,
                                next,
                            });
                        },
                    ],
                    [
                        "colorGroupField",
                        (_, next) =>
                            SingleFieldWell({
                                field: scope(tile, ["colorGroupField"]),
                                $type: "CategoryTileField",
                                label: (
                                    <span>
                                        Color Group <i style={{textTransform: "none"}}>(optional)</i>
                                    </span>
                                ),
                                fieldLabel: "Color Group",
                                info: "Use the Color Group option if you would like to color the markers by a separate field. This field is optional.",
                                onUpdateTile: (newVal, oriVal) =>
                                    updateSingleFieldInTile({
                                        fieldAttr: "colorGroupField",
                                        fieldVal: {newVal, oriVal},
                                        tileFields,
                                        tile,
                                        updateTileActions: getTileActionsUpdated,
                                    }),
                                next,
                            }),
                    ],
                    ({xAxisField, yAxisField, zAxisField, dimensionField, colorGroupField}) =>
                        next({
                            render: () =>
                                Expandable({
                                    label: "Fields",
                                    render: () => (
                                        <>
                                            {dimensionField.render()}
                                            {xAxisField.render()}
                                            {yAxisField.render()}
                                            {zAxisField.render()}
                                            {colorGroupField.render()}
                                        </>
                                    ),
                                }),
                            override: dimensionField.override || xAxisField.override || yAxisField.override || zAxisField.override || colorGroupField.override,
                        })
                );
            },
        ],

        [
            "limit",
            ({tileFields}, next) => {
                const formField = form.field(["limit"]);

                const isMeasureFieldSelected = [tile.value.xAxisField, tile.value.yAxisField, tile.value.zAxisField].find(
                    (f) => f && formField.state.value && isSameField(f, formField.state.value)
                );

                return next({
                    render: () =>
                        Expandable({
                            initExpand: false,
                            label: "Limit",
                            render: () =>
                                Limit({
                                    field: formField.state,
                                    allFields: tileFields.allFieldsWithoutUniq,
                                    fieldsForLimit: ["xAxisField", "yAxisField", "zAxisField", "dimensionField"].map((attr) => tile.value[attr]).filter((v) => v),
                                    isHiddenRankOption: () => !isMeasureFieldSelected,
                                    showOther: false,
                                }),
                        }),
                });
            },
        ],

        ({fieldsSection, limit}) =>
            next({
                render: () => (
                    <div className="tile-fields-3vr">
                        {fieldsSection.render()}
                        {limit.render()}
                        {tileActions?.render?.()}
                        {isSubTile && debugFunction?.render?.({})}
                    </div>
                ),
                override: fieldsSection.override || debugFunction?.override() || tileActions?.override({}),
                overrideAll: debugFunction?.overrideAll || tileActions?.overrideAll,
            })
    );
};
