import {cs} from "@common/react/chain-services";
import * as React from "react";
import "./color-palette.scss";
import {DnDList} from "../../dnd-list/dnd-list";
import {ColorPicker} from "../../color-picker/color-picker";
import {scope} from "@common/react/scope";
import {Transformer} from "@common/react/transformer";
import {equalDeep} from "@common/utils/objects";
import {spc} from "@common/react/state-path-change";

export const ColorPalette = ({state, addMore}) =>
    cs(
        [
            "buffer",
            (_, next) =>
                Transformer({
                    state,
                    transform: (colors) => colors.filter((c) => c != null),
                    same: equalDeep,
                    next,
                }),
        ],
        ({buffer}) => (
            <div className="color-palette-2s8">
                <div className="header">Data Color Palette</div>

                <div className="colors">
                    {cs(
                        [
                            "dnd",
                            (_, next) =>
                                DnDList({
                                    list: buffer.state.value,
                                    onChange: buffer.state.onChange,
                                    renderDrag: ({index, value: color}) => (
                                        <div className="drag-box-3sd">
                                            <div className="index">{index + 1}</div>

                                            <ColorPicker {...{value: color}} />

                                            <div className="drag-handler">=</div>
                                        </div>
                                    ),
                                    next,
                                }),
                        ],
                        ({dnd}) =>
                            dnd.list?.map(({index, ref, start}) =>
                                index != null ? (
                                    <div className="box" key={index} ref={ref}>
                                        <div className="index">{index + 1}</div>

                                        <ColorPicker
                                            {...{
                                                ...scope(buffer.state, [index]),
                                                required: true,
                                            }}
                                        />

                                        <div className="drag-handler" onMouseDown={start}>
                                            =
                                        </div>
                                    </div>
                                ) : (
                                    <div className="box blank" key={-1} />
                                )
                            )
                    )}
                </div>

                {addMore && (
                    <div className="add-more">
                        <button onClick={() => spc(buffer.state, [], (colors) => [...(colors || []), null])}>Add More</button>
                    </div>
                )}
            </div>
        )
    );
