import {ApiCollectionLogin} from "../routes/api-collection/login/api-collection-login";
import {ApiCollectionDocs} from "../routes/api-collection/docs/api-collection-docs";
import {IframeDocs} from "../routes/data-access-iframe/iframe-docs/iframe-docs";
import {IframeView} from "../routes/data-access-iframe/iframe-view/iframe-view";
import {IntegrationSlackRedirect} from "../routes/integrations/slack";

export const commonRoutes = [
    {
        name: "api-collection-login",
        path: "/api/login",
        query: ["token"],
        route: {
            component: ApiCollectionLogin,
        },
    },
    {
        name: "api-collection-docs",
        path: "/api/docs",
        route: {
            component: ApiCollectionDocs,
        },
    },
    {
        name: "iframe-docs",
        path: "/iframe/docs",
        query: ["key"],
        route: {
            component: IframeDocs,
        },
    },
    {
        name: "iframe-view",
        path: "/iframe/view",
        query: ["key", "collectionID"],
        route: {
            component: IframeView,
        },
    },
    {
        name: "integrations-slack",
        path: "/integrations/slack",
        route: {
            component: IntegrationSlackRedirect,
        },
    },
];
