export const numericFilterFormConfig = (filter) => ({
    fields: {
        value: {
            validators: [
                // {
                //     when: (state) => filter.required && state?.comparisonOperation !== "Range",
                //     validate: (value) => value != null,
                // }
            ],
            debounce: true,
        },

        "range.from": {
            validators: [
                {
                    when: (state) =>
                        state?.comparisonOperation === "Range" &&
                        // filter.required ||
                        state?.range?.to != null,
                    validate: (value) => value != null,
                },
            ],
            debounce: true,
        },
        "range.to": {
            validators: [
                {
                    when: (state) =>
                        state?.comparisonOperation === "Range" &&
                        // filter.required ||
                        state?.range?.from != null,
                    validate: (value) => value != null,
                },
                {
                    when: (state) => state?.range?.from != null,
                    validate: (value, {data}) => value == null || value >= data.range.from,
                },
            ],
            debounce: true,
        },
    },
});
