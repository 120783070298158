import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {FieldSection} from "../../../../../../collection/tile/edit/left-panel/tabs/fields-tab/chart-types/common/field-section/field-section";
import {FieldInfoProvider} from "../../../../../../collection/tile/edit/left-panel/tabs/tile-tab/chart-types/common/field-info-provider/field-info-provider";
import {LpColorLine} from "../../../../../../common/left-panel/lp-color-line";
import {ffToColorPickerLine} from "@common/form/ff-to-color-picker-line";
import {FontSizeSelect} from "../../../../../../collection/tile/edit/left-panel/tabs/tile-tab/chart-types/common/font-size-select";
import {ffToDropdown} from "@common/form/ff-to-dropdown";
import {FontOptionsSelect} from "../../../../../../collection/tile/edit/left-panel/tabs/tile-tab/chart-types/table/panels/font-options-select/font-options-select";
import {CaseOptionSelect} from "../../../../../../collection/theme/common/case-options/case-options-select";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {LpShowHideLine} from "../../../../../../common/left-panel/lp-show-hide-line";
import {ffToShowHideLine} from "@common/form/ff-to-show-hide-line";
import {NumberInput} from "@common/form/number-input/number-input";
import {ffToNumberInput} from "@common/form/ff-to-number-input";
import React from "react";

export const TableFormatTheme = ({form}) => (
    <>
        {FieldSection({
            header: FieldInfoProvider({
                field: <div className="text">HEADER</div>,
                noCollapsible: true,
            }),
            content: (
                <>
                    {LpColorLine({
                        label: "Background Color",
                        ...ffToColorPickerLine(form.field(["dataVisualization", "tables", "headerBackgroundColorRGB"])),
                    })}

                    {LpColorLine({
                        label: "Font Color",
                        ...ffToColorPickerLine(form.field(["dataVisualization", "tables", "headerFontColorRGB"])),
                    })}

                    {FontSizeSelect({
                        label: "Font Size",
                        ...ffToDropdown(form.field(["dataVisualization", "tables", "headerFontSize"])),
                    })}

                    {FontOptionsSelect({
                        label: "FONT STYLE",
                        state: form.field(["dataVisualization", "tables", "headerFontOptions"]).state,
                    })}

                    {CaseOptionSelect({
                        label: "CASE STYLE",
                        state: form.field(["dataVisualization", "tables", "headerCaseOptions"]).state,
                    })}
                </>
            ),
        })}

        {FieldSection({
            header: FieldInfoProvider({
                field: <div className="text">CELLS</div>,
                noCollapsible: true,
            }),
            content: (
                <>
                    {LpColorLine({
                        label: "Cell Font color",
                        ...ffToColorPickerLine(form.field(["dataVisualization", "tables", "cellFontColorRGB"])),
                    })}

                    {LpColorLine({
                        label: "Background Color",
                        ...ffToColorPickerLine(form.field(["dataVisualization", "tables", "cellBackgroundColorRGB"])),
                    })}

                    {DropdownSelect({
                        label: "Alternate Cell Color",
                        list: ["None", "Alternate Rows", "Alternate Cols"].map((label) => ({
                            label,
                            value: label.replace(/\s+/g, ""),
                        })),
                        valueToLabel: (v) => v.label,
                        ...ffToDropdown(form.field(["dataVisualization", "tables", "cellAlternateType"]), ["value"]),
                    })}

                    {/* {LpShowHideLine({
                            label: "USE ALTERNATING CELL COLOR",
                            ...ffToColorPickerLine(form.field(["dataVisualization", "tables", "cellAlternateType"])),
                        })} */}

                    {LpColorLine({
                        label: "Alt Cell color",
                        ...ffToColorPickerLine(form.field(["dataVisualization", "tables", "cellAltCellColorRGB"])),
                    })}
                </>
            ),
        })}

        {FieldSection({
            header: FieldInfoProvider({
                field: <div className="text">TOTALS</div>,
                noCollapsible: true,
            }),
            content: (
                <>
                    {LpColorLine({
                        label: "Background Color",
                        ...ffToColorPickerLine(form.field(["dataVisualization", "tables", "totalsBackgroundColorRGB"])),
                    })}

                    {LpColorLine({
                        label: "Font Color",
                        ...ffToColorPickerLine(form.field(["dataVisualization", "tables", "totalsFontColorRGB"])),
                    })}

                    {FontSizeSelect({
                        label: "Font Size",
                        ...ffToDropdown(form.field(["dataVisualization", "tables", "totalsFontSize"])),
                    })}

                    {FontOptionsSelect({
                        label: "FONT STYLE",
                        state: form.field(["dataVisualization", "tables", "totalsFontOptions"]).state,
                    })}
                </>
            ),
        })}

        {FieldSection({
            header: FieldInfoProvider({
                field: <div className="text">BORDERS</div>,
                noCollapsible: true,
            }),
            content: (
                <>
                    {LpShowHideLine({
                        label: "VERTICAL LINES",
                        ...ffToShowHideLine(form.field(["dataVisualization", "tables", "borderVerticalLines"])),
                    })}

                    {LpShowHideLine({
                        label: "HORIZONTAL LINES",
                        ...ffToShowHideLine(form.field(["dataVisualization", "tables", "borderHorizontalLines"])),
                    })}

                    {LpShowHideLine({
                        label: "OUTSIDE BORDER",
                        ...ffToShowHideLine(form.field(["dataVisualization", "tables", "borderOutsideLines"])),
                    })}

                    {NumberInput({
                        label: "Border Width",
                        unit: "px",
                        ...ffToNumberInput(form.field(["dataVisualization", "tables", "borderWidth"])),
                        showZero: true,
                    })}

                    {LpColorLine({
                        label: "Border Color",
                        ...ffToColorPickerLine(form.field(["dataVisualization", "tables", "borderColorRGB"])),
                    })}
                </>
            ),
        })}
    </>
);
