const promiseCache = (promise, getKey = (v) => v, timeout) => {
    let cache = {};

    return function () {
        const key = JSON.stringify(getKey(arguments));

        if (cache[key]) {
            return cache[key];
        } else {
            cache[key] = promise(...arguments);

            cache[key]
                .then(() => {
                    if (timeout > 0) {
                        setTimeout(() => {
                            delete cache[key];
                        }, timeout);
                    }
                })
                .catch(() => {
                    // won't do cache if async func is error
                    delete cache[key];
                });

            return cache[key];
        }
    };
};
exports.promiseCache = promiseCache;
