import {moveTileToDashboard, updatedCollectionAfterAdd} from "../../common/dnd-grid-panel/dnd-common";

export const moveFiltersToDashboard = (collection, additional = () => {}) => {
    let updatedCollection = {...collection.value};
    for (let tile of updatedCollection.filterDownloadTiles) {
        const newTile = moveTileToDashboard({
            tile: {
                ...tile,
                position: {x: 0, y: 0},
                size: {width: 6, height: 2},
            },
            tiles: updatedCollection.gridLocations.map((gridLoc) => ({
                position: {
                    x: gridLoc.colStart - 1,
                    y: gridLoc.rowStart - 1,
                },
                size: {
                    width: gridLoc.colSpan,
                    height: gridLoc.rowSpan,
                },
            })),
        });

        updatedCollection = updatedCollectionAfterAdd({
            tile: newTile.tile,
            rect: newTile.rect,
            collection: {value: updatedCollection},
        });
    }

    collection.change((c) => ({
        ...updatedCollection,
        filterDownloadTiles: [],
        ...additional(c),
    }));
};
