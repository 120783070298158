import React from "react"
import {CheckboxLine} from "../../../../../../../../../../common/checkbox-line/checkbox-line";
import {scope} from "../../../../../../../../../../../../../common/react/scope";
import {DropdownSelect} from "../../../../../../../../../../../../../common/ui-components/dropdown-select/dropdown-select";
import {ffToDropdown} from "../../../../../../../../../../../../../common/form/ff-to-dropdown";
import {ComparisonDateRange} from "../value-format/comparison-date-range/comparison-date-range";

export const ValueDisplay = ({tile, form, kpiColorsTileConfig}) => ({
    label: "KPI Value Display",
    render: () => (
        <div className="single-kpi-value-format-yio">
            <div className="subsection">
                <div className="content">
                    {DropdownSelect({
                        label: "Position",
                        list: ["Left", "Center", "Right"],
                        valueToLabel: (v) => v,
                        ...ffToDropdown(form.field(["style", "valuePosition"]))
                    })}

                    {CheckboxLine({
                        label: "Auto Round",
                        state: scope(tile, ["style", "autoRound"]),
                    })}

                    {kpiColorsTileConfig?.render(0)}
                </div>
            </div>

            {/*{ComparisonDateRange({*/}
            {/*    tile*/}
            {/*})}*/}
        </div>
    ),
})
