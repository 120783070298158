import "./info-icon-tooltip.scss";

import React from "react";

import {cs} from "../../../../react/chain-services";
import {getTooltipFontSizes} from "../../factory/tooltip/shared-tooltip-formatter/get-tooltip-font-sizes";
import {tooltipService4} from "../../../../../web-client/src/routes/common/tooltip3/tooltip-service-3";
import {InfoIconFull} from "../icons/icons";

export const InfoIconTooltip = ({showInfoIcon = () => {}, theme, width, color, infoText}) =>
    cs(
        [
            "tooltip",
            (_, next) => {
                const themeMode = theme.dataVisualization.toolTipsAndLegends.tooltipTheme.toLowerCase() || "light";
                return tooltipService4({
                    direction: theme?.tooltipHover?.position?.toLowerCase() || "above",
                    className: `${themeMode}-theme`,
                    tooltipContentStyle: {
                        fontSize: getTooltipFontSizes(theme).small,
                        fontFamily: `"${theme.general.canvas.fontFamily || "Roboto"}", sans-serif`,
                        color: themeMode === "light" ? theme.general.canvas.fontColorRGB || "#546B81" : "#E2E2E2",
                    },
                    next,
                });
            },
        ],
        ({tooltip}) => {
            return (
                showInfoIcon() && (
                    <span
                        {...{
                            className: "info-icon-tooltip",
                            ...tooltip(() => infoText),
                            style: {
                                display: "inline-block",
                                width: width,
                                height: width,
                            },
                        }}
                    >
                        {InfoIconFull({width, color})}
                    </span>
                )
            );
        }
    );
