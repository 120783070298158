import * as React from "react";

export const KpiColors = ({kpiColorsTileConfig}) => ({
    label: "KPI COLORS",
    render: () => (
        <div className="tile-title-8as">
            <div className="subsection">
                <div className="content">{kpiColorsTileConfig?.render(0)}</div>
            </div>
        </div>
    ),
});
