import React from "react";

import {omit} from "@common/utils/objects";
import {waitTimeout} from "@common/utils/wait-timeout";
import {canPrint} from "@common/utils/browser/can-download-directly";

import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";

import {serializeFilterState} from "../../data/serialize/serialize-filter-state";

import {DownloadMenuItem} from "./download-menu-item";
import jsPDF from "jspdf";
import * as htmlToImage from "html-to-image";

export const downloadPDFFunc = async (name, includeFilters = true, stopToast, shadowRoot) => {
    const isSDK = !!shadowRoot;
    let wrapper = isSDK ? shadowRoot : document;
    let element = wrapper.querySelector(".dnd-grid-panel-3si");
    if (!element) {
        element = wrapper.querySelector(".live-grid-6qr").parentNode;
    }
    if (!element) return;

    element.style.width = '1920px';
    await waitTimeout(2 * 1000);
    const {width, height} = element.getBoundingClientRect();

    const pageSize = {
        width,
        height,
    };

    const imgData = await htmlToImage.toJpeg(element);

    const doc = new jsPDF({
        orientation: "p",
        unit: "px",
        width,
        height,
        format: [width, height]
    });
    doc.addImage(imgData, "PNG", 0, 8, pageSize.width, pageSize.height, `image${0}`);
    doc.save(`${name || Date.now()}.pdf`);

    element.style.width = '';
    stopToast?.();
};

export const genFilterVals = (collectionFilters, rawFilterValues) => {
    const values = collectionFilters
        .map((f) => {
            if (!rawFilterValues) {
                return f;
            }

            let currentFilterValue = rawFilterValues.find((f2) => f2.filterId == f.id);

            if (!currentFilterValue) {
                return {
                    ...f,
                    value: null,
                };
            }

            return {
                ...f,
                value: omit(serializeFilterState(currentFilterValue.value, {filter: f}), ["columns"]),
            };
        })
        .filter((f) => f.value);

    return values.reduce((ret, currFilterVal) => {
        if (currFilterVal?.value) {
            ret[currFilterVal.id] = currFilterVal.value;
        }

        return ret;
    }, {});
};

export const DownloadPdf = ({isLiveDashboard, dropdown, alertDownloadPDFModal, iframeModal, collection}) =>
    cs(
        consumeContext("shadowRoot"),
        consumeContext("routing"),
        consumeContext("collectionFilter"),
        consumeContext("downloadToast"),
        ({routing, shadowRoot, collectionFilter, downloadToast}) => {
            return (
                <>
                    {DownloadMenuItem({
                        label: "Full Dashboard PDF",
                        onClick: async () => {
                            if (!isLiveDashboard) {
                                dropdown.onChange(false);
                                const res = await alertDownloadPDFModal?.show({});
                                if (res) {
                                    window.open(`/#/env/${routing.params.envId}/collection/${routing.params.colId}/preview`, "_blank");
                                }
                                return;
                            }

                            if (!canPrint()) {
                                dropdown.onChange(false);
                                let filterVals = genFilterVals((collection?.value || collection).filters, collectionFilter?.value);
                                iframeModal.show({
                                    href:
                                        window.location.href +
                                        "&printPDF=true&filterVals=" +
                                        encodeURIComponent(JSON.stringify(filterVals)),
                                });
                            } else {
                                const _key = collection?.value.id;
                                downloadToast.add({id: _key});
                                await downloadPDFFunc(
                                    (collection?.value || collection).name,
                                    (collection?.value || collection).pdfDownloadIncludeFilter,
                                    () => {
                                        downloadToast?.update({id: _key});
                                        setTimeout(
                                            () =>
                                                downloadToast?.remove({
                                                    id: _key,
                                                }),
                                            0
                                        );
                                    },
                                    shadowRoot
                                );
                                dropdown.onChange(false);
                            }
                        },
                    })}
                </>
            );
        }
    );
