const sampleFolders = [
    {
        name: "ROOT",
        collections: [],
    },
    {
        id: "ce0dec10-8e1b-4b74-b1d9-21d1f52c59cc",
        name: "Customer Dashboards",
        collections: [
            {
                id: "b9c0ca20-2532-4c9f-a73a-3953d5f22eed",
                name: "Main Customer Dashboard 637290679341918596",
                type: "DashboardCollection",
                versionInfo: {
                    editedBy: "Oleg Fridman",
                    editedByID: "99ffddee-5f12-4b2d-bf35-a75bf9160715",
                    editedOnUtc: "2020-06-29T22:52:14.486Z",
                },
            },
        ],
    },
    {
        id: "79e0dfc4-9fde-4d88-91be-13cfceaf6d71",
        name: "Downloadable Reports",
        collections: [],
    },
    {
        id: "d5c103b1-7acd-4902-bcfe-6452ed6341f2",
        name: "Custom Reports",
        parentFolderID: "79e0dfc4-9fde-4d88-91be-13cfceaf6d71",
        collections: [],
    },
    {
        id: "a91d7f78-bac6-4029-8067-6726a94c4280",
        name: "Standard Reports",
        parentFolderID: "79e0dfc4-9fde-4d88-91be-13cfceaf6d71",
        collections: [],
    },
];
exports.sampleFolders = sampleFolders;
