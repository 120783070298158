import {cs} from "@common/react/chain-services";

import {ManageRelationships} from "./manage-relationships/manage-relationships";
import {ColumnSettings} from "./column-settings/column-settings";
import {RowLevelSecurity} from "./row-level-security/row-level-security";
import {ColumnSecurity} from "./column-security/column-security";
import {SuggestRelationshipLeftPanel} from "./suggest-relationship/suggest-relationship-left-panel";
import {CreateRelationshipLeftPanel} from "./create-relationship/create-relationship-left-panel";
import {AllSuggestedRelationshipsLeftPanel} from "./all-suggested-relationships/all-suggested-relationships-left-panel";
import {PreviewData} from "./preview-data/preview-data";
import {DataSourceModelContext} from "../data/data-menu/data-source-model-context";
import {EditRelationshipLeftPanel} from "./create-relationship/edit-relationship-left-panel";

export const LeftPanelOverrides = ({model, dataSources, interactions, next, savingQueue}) => {
    const {
        setting,
        onCancel,
        openManageRelationships,
        openColumnSettings,
        openColumnSecurity,
        openRowLevelSecurity,
        addRelationship,
        createNewRelationship,
        deSelectTable,
        toggleViewFullWidth,
        isViewFullWidth,
        previewData,
        editRelationship,
        viewSuggestRelationship,
        viewAllSuggestedRelationships,
    } = interactions;

    const primitivePanels = {
        "manage-relationships": ManageRelationships,
        "column-settings": ColumnSettings,
        "column-security": ColumnSecurity,
        "row-level-security": RowLevelSecurity,
        "suggest-relationship": SuggestRelationshipLeftPanel,
        "create-new-relationship": CreateRelationshipLeftPanel,
        "edit-relationship": EditRelationshipLeftPanel,
        "all-suggested-relationships": AllSuggestedRelationshipsLeftPanel,
        "preview-data": PreviewData,
    };

    // if (setting && setting.wideLeftPanel) {
    //     return next(nonPrimitivePanels[setting.wideLeftPanel]({
    //         ...setting.data,
    //         model, dataSources,
    //         openColumnSecurity,
    //         onCancel: ({toName}={}) => onCancel({name: setting.wideLeftPanel, toName})
    //     }))
    // }

    if (setting && setting.name) {
        return next({
            render: () =>
                cs(
                    ({}, next) => DataSourceModelContext({dataSources, model: model.value, next}),
                    () =>
                        primitivePanels[setting.name]({
                            ...setting.data,
                            model,
                            dataSources,
                            addRelationship,
                            openManageRelationships,
                            openColumnSettings,
                            openRowLevelSecurity,
                            openColumnSecurity,
                            createNewRelationship,
                            deSelectTable,
                            viewSuggestRelationship,
                            savingQueue,
                            toggleViewFullWidth,
                            isViewFullWidth,
                            editRelationship,
                            viewAllSuggestedRelationships,
                            onPreviewData: previewData,
                            onCancel: ({toName} = {}) => onCancel({name: setting.name, toName}),
                        })
                ),
        });
    }

    return next();
};
