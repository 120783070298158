import "./tile-fields.scss";

import * as React from "react";

import {cs} from "@common/react/chain-services";
import {consumeContext, provideContext} from "@common/react/context";

import {Expandable} from "../../../../../../../common/expandable/expandable";

import {getTileFields} from "./common/get-tile-fields";
import {Limit} from "./common/limit-sorting/limit/limit";
import {getGroupSortingUpdated} from "./common/limit-sorting/sorting/group/get-group-sorting-updated";
import {GroupSorting} from "./common/limit-sorting/sorting/group/group-sorting";
import {MapLocationFields} from "./common/map-location-fields/map-location-fields";
import {MeasureFieldGroups} from "./common/measure-field-groups/measure-field-groups";
import {formToSingleFieldWell} from "./common/single-field-well/form-to-single-field-well";
import {SingleFieldWell} from "./common/single-field-well/single-field-well";
import {updateSingleFieldInTile} from "./common/update-tile";

export const MapTileFields = ({tile, isSubTile, tileActions, debugFunction, form, next}) =>
    cs(
        consumeContext("getFieldInfo"),
        [
            "tileFields",
            ({getFieldInfo}, next) =>
                next(
                    getTileFields({
                        tile: tile.value,
                        dimensionAttr: ["countryField", "provinceField", "countyField", "postalCodeField"],
                        measureGroupAttrs: ["valueFields"],
                        groupFieldAttr: "groupField",
                        getFieldInfo,
                    })
                ),
        ],
        ["tileFields", ({tileFields}, next) => provideContext("tileFields", tileFields, next)],
        [
            "locationFields",
            ({}, next) =>
                MapLocationFields({
                    tile,
                    next,
                }),
        ],
        [
            "valueFields",
            ({tileFields}, next) =>
                (() => {
                    const formField = form.field(["valueFields"]);
                    return MeasureFieldGroups({
                        fieldGroups: formField.state,
                        hasError: formField.error,
                        domRef: formField.domRef,
                        label: "Measures",
                        notAllowAndGroup: true,
                        notAllowDnd: true,
                        next,
                    });
                })(),
        ],
        [
            "groupField",
            ({tileFields}, next) => {
                return SingleFieldWell({
                    ...formToSingleFieldWell(form, ["groupField"]),
                    onUpdateTile: (newVal, oriVal) =>
                        updateSingleFieldInTile({
                            fieldAttr: "groupField",
                            fieldVal: {newVal, oriVal},
                            tileFields,
                            tile,
                            updateSorting: getGroupSortingUpdated,
                        }),
                    $type: "CategoryTileField",
                    label: "Group",
                    next,
                });
            },
        ],
        [
            "fieldsSection",
            ({locationFields, valueFields, groupField}, next) =>
                next({
                    render: () =>
                        Expandable({
                            label: "Fields",
                            render: () => (
                                <>
                                    {locationFields.render()}
                                    {valueFields.render()}
                                    {groupField.render()}
                                </>
                            ),
                        }),
                    override: locationFields.override || valueFields.override || groupField.override,
                }),
        ],

        [
            "limitSorting",
            ({tileFields}, next) =>
                cs(
                    [
                        "limit",
                        (_, next) => {
                            const formField = form.field(["limit"]);
                            return next(
                                Limit({
                                    field: formField.state,
                                })
                            );
                        },
                    ],
                    ["sorting", (_, next) => next(tile.value.groupField && GroupSorting({form, tile}))],
                    ({limit, sorting}) =>
                        next({
                            render: () =>
                                Expandable({
                                    initExpand: false,
                                    // label: "Limit & Sorting",
                                    label: "Sorting",
                                    render: () => (
                                        <>
                                            {/*{limit}*/}
                                            {sorting}
                                        </>
                                    ),
                                }),
                        })
                ),
        ],

        ({limitSorting, fieldsSection}) =>
            next({
                render: () => {
                    return (
                        <div className="tile-fields-3vr">
                            {fieldsSection.render()}
                            {limitSorting.render()}
                            {tileActions?.render?.()}
                            {isSubTile && debugFunction?.render?.({})}
                        </div>
                    );
                },
                // hasError: !form.group("fields").looksValid,
                override: fieldsSection.override || debugFunction?.override() || tileActions?.override({fieldType: "valueFields"}),
                overrideAll: debugFunction?.overrideAll || tileActions?.overrideAll,
            })
    );
