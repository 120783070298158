import React from "react";
import {cs} from "@common/react/chain-services";
import {rColumnValueSelectFromStepOutput} from "../../../../../common/column-value-select-from-step-output/column-value-select-from-step-output";
import {ffToDropdown} from "../../../../../../../../../../common/form/ff-to-dropdown";
import {keepOnly} from "@common/utils/objects";
import {rNewColumnNameInput} from "../../../common/new-column-name-input";

export const LengthConfiguration = ({inputStep, model, form}) =>
    cs(() => {
        return (
            <>
                <div className="config-group">
                    <div className="label">Input Column</div>
                    {(() => {
                        const dropdownProps = ffToDropdown(form.field(["textManipulation", "inputValue"]));
                        return rColumnValueSelectFromStepOutput({
                            label: "Column",
                            columns: inputStep.outputColumns,
                            model,
                            disableStaticValue: true,
                            filterColumnFn: (c) => ["Text", "IDUUID"].includes(c.dataType),
                            isValueNotAllowed: (c) => !["Text", "IDUUID"].includes(c.dataType),
                        })({
                            state: {
                                value: dropdownProps.value,
                                onChange: dropdownProps.onChange,
                            },
                            ...keepOnly(dropdownProps, ["domRef", "hasError", "errorMessage"]),
                        });
                    })()}
                </div>

                <div className="config-group">
                    <div className="label">Column Name</div>

                    {rNewColumnNameInput({
                        form,
                        newColumnPath: ["textManipulation", "newColumn"],
                        newColumnNamePath: ["textManipulation", "newColumn", "name"],
                    })}
                </div>
            </>
        );
    });
