exports.containerTileTemplate = {
    $type: "ContainerTile",
    style: {
        $type: "ContainerTileStyle",
        title: {
            show: true,
            titleLocation: "Above",
            fontSize: "Medium",
        },
        displayType: "Tabs",
        fontSize: "Medium",
    },
    tiles: [],
};
