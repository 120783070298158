import {cs} from "@common/react/chain-services";
import * as React from "react";
import "./control-types.scss";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {textFilterControlTypes} from "./text-filter-control-types";
import {stateToSelect} from "@common/ui-components/form/state-to-select";
import {StaticTooltipService} from "../../../../../../../common/tooltip3/static-tooltip-service";
import {NumberInput} from "../../../../../../../../../../common/form/number-input/number-input";
import {ffToNumberInput} from "../../../../../../../../../../common/form/ff-to-number-input";

export const ControlTypes = ({filter, form}) =>
    cs(({}) => {
        return (
            <div className="control-types-42y control-types">
                <div className="left">
                    {DropdownSelect({
                        label: "Control Type",
                        list: textFilterControlTypes,
                        valueToLabel: (item) => item.label,
                        ...stateToSelect(
                            {
                                value: filter.value.design || "ButtonGroup",
                                onChange: (v) =>
                                    filter.onChange({
                                        ...filter.value,
                                        design: v,
                                        ...(["ButtonGroup", "ButtonToggle", "Checkbox"].includes(v) && {
                                            itemsVisible: filter.value.itemsVisible || 5,
                                        }),
                                    }),
                            },
                            ["name"]
                        ),
                    })}
                </div>

                {["ButtonGroup", "ButtonToggle", "Checkbox"].includes(filter.value.design) &&
                    cs(
                        [
                            "staticTooltip",
                            (_, next) =>
                                StaticTooltipService({
                                    direction: "right",
                                    info: "When there are more than the designated number of items the control type will be changed to a dropdown to provide a more simple UX. The minimum value is 2 and maximum value is 10. We suggest 5.",
                                    tooltipBoxWidth: 220,
                                    topOffset: -14,
                                    next,
                                }),
                        ],
                        ({staticTooltip}) => (
                            <div className="right">
                                Limit visibility to
                                {NumberInput({
                                    ...ffToNumberInput(form.field("itemsVisible")),
                                })}
                                items
                                {staticTooltip.renderIcon({
                                    icon: <i className="material-icons">info</i>,
                                    className: "info-tooltip",
                                })}
                            </div>
                        )
                    )}
            </div>
        );
    });
