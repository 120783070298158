import React from "react";
import "./button.scss";
import {cx} from "emotion";
import {cs} from "../../../react/chain-services";

import {tooltipService4} from "../../../../web-client/src/routes/common/tooltip3/tooltip-service-3";

// Preview: https://www.figma.com/file/lLyR8h0WfMXZsnVjeXKtgJ/Components?node-id=2505%3A14305
//size: "large" | "medium" | "small" | "tiny",
//btnType: "primary" | "secondary" | "border" | "danger" | "primary-danger",

export const Button = ({size = "medium", btnType = "primary", disabled, children, className, iconLeft, iconRight, type = "button", ...otherProps}) => {
    return (
        <button className={cx("verb-button", `type-${btnType}`, className, `size-${size}`)} disabled={disabled} type={type} {...otherProps}>
            {iconLeft && <span className="icon-left">{iconLeft}</span>}

            {children}

            {iconRight && <span className="icon-right">{iconRight}</span>}
        </button>
    );
};

export const ButtonWithTooltip = ({tooltipClassName, content = "", direction = "below", ...others}) =>
    cs(
        [
            "tooltip",
            ({}, next) =>
                content
                    ? tooltipService4({
                          direction,
                          className: tooltipClassName,
                          next,
                      })
                    : next(),
        ],
        ({tooltip}) => Button({...others, ...(tooltip?.(() => content) ?? {})})
    );
