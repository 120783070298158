const getScrollbarStyle = (theme) => {
    const bgColor = theme.general.tile.styles.tileBackgroundColorRGB;
    return {
        "&::-webkit-scrollbar": {
            "background-color": `${bgColor}`,
            width: `13px`,
            height: `14px`,
        },
        "&::-webkit-scrollbar-track": {
            "background-color": `${bgColor}`,
        },
        "&::-webkit-scrollbar-thumb": {
            "background-color": "#babac0",
            "border-radius": "16px",
            border: `4px solid ${bgColor}`,
        },
        "&::-webkit-scrollbar-button": {
            display: "none",
        },
        "scrollbar-width": "thin",
        "scrollbar-color": `#babac0 transparent`,
    };
};
exports.getScrollbarStyle = getScrollbarStyle;

const getScrollbarStyleHC = (theme) => {
    return {
        ".highcharts-scrolling-parent": {
            ".highcharts-scrolling": {
                "@media (min-width: 769px)": getScrollbarStyle(theme),
            },
            ".highcharts-scrollable-mask": {
                fill: `${theme.general.tile.styles.tileBackgroundColorRGB}`,
            },
        },
    };
};
exports.getScrollbarStyleHC = getScrollbarStyleHC;

const getScrollbarStyleLegend = (theme) => {
    return {
        "@media (min-width: 769px)": getScrollbarStyle(theme),
    };
};
exports.getScrollbarStyleLegend = getScrollbarStyleLegend;
