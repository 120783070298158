const addMap = (m1, m2) => {
    let ret = {...m2};
    for (const k in m1) {
        const value = m1[k];
        if (typeof value === "object") {
            ret[k] = addMap(value, ret[k]);
        } else {
            ret[k] = value + (ret[k] || 0);
        }
    }
    return ret;
};

const randomNumber = (min, max, interval = 1) => {
    let n = Math.floor((Math.random() * (max - min + interval)) / interval);
    return n * interval + min;
};

const quadraticEquations = (a, b, c) => {
    let discriminant = b * b - 4 * a * c;

    if (discriminant == 0) {
        let x = -b / (2 * a);
        return [x, x];
    }

    if (discriminant > 0) {
        let x1 = (-b + Math.sqrt(discriminant)) / (2 * a);
        let x2 = (-b - Math.sqrt(discriminant)) / (2 * a);

        return [x1, x2];
    }

    return [];
};

const betweenNumbers = (from, to, numb) => (numb < from ? from : numb > to ? to : numb);

const max = (arr, fn = (v) => v) => {
    let ret = arr[0];
    for (let item of arr) {
        if (fn(item) > fn(ret)) ret = item;
    }

    return ret;
};

const min = (arr, fn = (v) => v) => {
    let ret = arr[0];
    for (let item of arr) {
        if (fn(item) < fn(ret)) ret = item;
    }

    return ret;
};

const minBy = (arr, fn = (v) => v) => Math.min(...arr.map(fn));
const maxBy = (arr, fn = (v) => v) => Math.max(...arr.map(fn));
const randomColor = (ignoreColors = []) => {
    const random = () => {
        let color = Math.floor(Math.random() * 16777215).toString(16);
        if (ignoreColors.indexOf(color) > -1) {
            return random();
        }

        return `#${color}`;
    };

    return random();
};

const formatMoney = (amount, decimalCount = 0, decimal = ".", thousands = ",") => {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString();
        let j = i.length > 3 ? i.length % 3 : 0;

        return (
            negativeSign +
            (j ? i.substr(0, j) + thousands : "") +
            i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
            (decimalCount
                ? decimal +
                  Math.abs(amount - i)
                      .toFixed(decimalCount)
                      .slice(2)
                : "")
        );
    } catch (e) {
        //console.log(e);
    }
};

const getAngleInThreePoint = (p1, p2, p3) => {
    // Calculate the vectors
    const v1 = [p2[0] - p1[0], p2[1] - p1[1]];
    const v2 = [p3[0] - p2[0], p3[1] - p2[1]];

    // Calculate the dot product and magnitudes
    const dotProduct = v1[0] * v2[0] + v1[1] * v2[1];
    const magnitude1 = Math.sqrt(v1[0] * v1[0] + v1[1] * v1[1]);
    const magnitude2 = Math.sqrt(v2[0] * v2[0] + v2[1] * v2[1]);

    // Calculate the angle in radians
    const angleInRadians = Math.acos(dotProduct / (magnitude1 * magnitude2));

    // Convert the angle to degrees
    return (angleInRadians * 180) / Math.PI;
};

exports.formatMoney = formatMoney;
exports.addMap = addMap;
exports.randomNumber = randomNumber;
exports.quadraticEquations = quadraticEquations;
exports.betweenNumbers = betweenNumbers;
exports.minBy = minBy;
exports.maxBy = maxBy;
exports.randomColor = randomColor;
exports.max = max;
exports.min = min;
exports.getAngleInThreePoint = getAngleInThreePoint;
