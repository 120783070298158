// ref. AB #312, #431, #432, #485, #1068
const getLayoutPadding = ({tile, noData, hasControls}) => {
    const {style} = tile;

    const hasLegendBottom = style?.legendStyle?.legendShown && (style?.legendStyle?.legendPosition === "Bottom" || style?.legendStyle?.legendPosition === "Auto");

    const noLegendBottomPaddingBottom =
        {
            PieChartTile: 12,
            DonutChartTile: 12,
            VennDiagramTile: 12,
        }[tile.$type] || 5;

    let returnStyle = {
        paddingTop: style.title?.show && !tile.isContainerTile ? (noData ? 54 : 0) : tile.$type === "DownloadReportTile" ? 0 : hasControls ? 26 : 20,
    };

    if (!tile.style.noPadding) {
        returnStyle = {
            ...returnStyle,
            ...(style.title?.show
                ? {
                      paddingBottom: hasLegendBottom ? 20 : noData ? 0 : noLegendBottomPaddingBottom,
                      ...(tile.$type === "TableTile"
                          ? {}
                          : {
                                paddingLeft: 10,
                                paddingRight: 10,
                            }),
                  }
                : {
                      paddingBottom: hasLegendBottom ? 20 : noData ? 0 : noLegendBottomPaddingBottom,
                      ...(tile.$type === "TableTile"
                          ? {}
                          : {
                                paddingLeft: 20,
                                paddingRight: 20,
                            }),
                  }),
        };
    }

    return returnStyle;
};
exports.getLayoutPadding = getLayoutPadding;
