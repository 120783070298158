import {cs} from "@common/react/chain-services";
import {Invoke} from "@common/react/invoke";
import {spc} from "@common/react/state-path-change";
import {UseState} from "@common/react/use-state";
import {waitUntil} from "@common/utils/wait-until";

export const ObserveProgressService = ({fetch, calculateProgress, checkDone, onDone, checkError, onError, onApiError, onProgress, next}) =>
    cs(
        [
            "state",
            (_, next) =>
                UseState({
                    initValue: {
                        // progress, args, error, apiError
                    },
                    next,
                }),
        ],
        ({state}) => {
            const fetchProgressTillDone = async (args) => {
                let info;

                const isDoneOrError = await waitUntil(
                    async () => {
                        try {
                            info = await fetch();
                        } catch (e) {
                            state.onChange({
                                progress: null,
                                apiError: e,
                            });
                            onProgress?.({
                                progress: null,
                                loading: null,
                                apiError: e,
                            });
                            onApiError(e, args);
                            return true;
                        }

                        const progressVal = calculateProgress(info);

                        if (checkDone(info) || checkError(info)) {
                            return true;
                        } else {
                            spc(state, ["progress"], () => progressVal);
                            onProgress?.({progress: progressVal, loading: isLoading(progressVal)});
                        }
                    },
                    {timeLimit: 5 * 60 * 1000, intervalDelay: 5 * 1000}
                );

                if (isDoneOrError && info) {
                    if (checkDone(info)) {
                        state.onChange({progress: null});
                        onDone(info, args);
                        onProgress?.({
                            progress: null,
                            loading: null,
                            error: true,
                        });
                    } else if (checkError(info)) {
                        state.onChange({
                            progress: null,
                            error: true,
                        });
                        onError(info, args);
                        onProgress?.({
                            progress: null,
                            loading: null,
                            error: true,
                        });
                    }
                }
            };

            const isLoading = (progress) => progress != null && progress >= 0 && progress < 1;

            const getReturned = (state) => {
                return {
                    progress: state.value.progress,
                    loading: isLoading(state.value.progress),
                    error: state.value.error,
                    apiError: state.value.apiError,
                    start: ({args, delayFetching}) => {
                        state.onChange({
                            progress: 0,
                            error: false,
                            apiError: false,
                            args,
                            delayFetching,
                        });
                        onProgress?.({
                            progress: 0,
                            loading: isLoading(0),
                        });
                    },
                };
            };

            return (
                <>
                    {next({
                        ...getReturned(state),
                        getLatest: () =>
                            getReturned({
                                ...state,
                                value: state.get(),
                            }),
                    })}

                    {state.value.progress != null &&
                        !state.value.delayFetching &&
                        Invoke({
                            fn: () => fetchProgressTillDone(state.value.args),
                        })}
                </>
            );
        }
    );
