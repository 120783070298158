import * as React from "react";
import {ShowHideToggle} from "@common/form/toggles/show-hide-toggle";
import {LpLine} from "../../../../../../../../../../common/left-panel/line/lp-line";
import {scope} from "@common/react/scope";
import {OrientationSelect} from "../../../common/orientation-select";
import {AxisTitle, XAxisLabel} from "../../../combo-chart/panels/x-axis/x-axis-rules-comp";
import {YGridStepLabel} from "../../../horizontal-bar/panels/y-axis/y-axis-rules-comp";
import {getPath} from "@common/utils/arr-path";

export const YAxis = ({tile, size}) => ({
    label: "Y-Axis",
    render: () => (
        <div className="scatter-plot-y-axis-3sf">
            <div className="subsection">
                <div className="content">

                    {AxisTitle({
                        tile,
                        size,
                        state: scope(tile, ["style", "yAxis", "label"])
                    })}
                </div>
            </div>

            <div className="subsection">
                {XAxisLabel({
                    tile,
                    size,
                    state: scope(tile, ["style", "yAxis", "labelShown"])
                })}
            </div>


            <div className="subsection">
                {YGridStepLabel({
                    tile, size,
                    state: scope(tile, ["style", "yAxis", "gridStepLabelsShown"])
                })}

                {getPath(tile.value, ["style", "yAxis", "gridStepLabelsShown"]) && (
                    <div className="content">
                        {OrientationSelect(scope(tile, ["style", "yAxis", "gridStepLabelOrientation"]))}
                    </div>
                )}
            </div>

            <div className="subsection">
                <div className="header">
                    Other Options
                </div>
                <div className="content">
                    {LpLine({
                        label: "Axis Line",
                        control: ShowHideToggle({state: scope(tile, ["style", "yAxis", "lineShown"])}),
                    })}

                    {LpLine({
                        label: "Grid Lines",
                        control: ShowHideToggle({state: scope(tile, ["style", "yAxis", "gridLinesShown"])}),
                    })}
                </div>
            </div>
        </div>
    ),
});
