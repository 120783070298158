import {cs} from "../../../../../../common/react/chain-services";
import * as React from "react";
import "./lp-line.scss";
import {cx} from "emotion";
import {UseState} from "../../../../../../common/react/use-state";
import {Static2} from "../../../../../../common/react/static-2";
import {StaticTooltipService} from "../../tooltip3/static-tooltip-service";

export const LpLine = ({label, domRef, hasError, control, info, colorPicker = false, className}) =>
    cs(
        ["hovering", (_, next) => UseState({next})],
        ["ref", (_, next) => Static2({next})],
        [
            "staticTooltip",
            (_, next) =>
                StaticTooltipService({
                    direction: "top",
                    info: info,
                    tooltipBoxWidth: 200,
                    next,
                }),
        ],
        ({hovering, ref, staticTooltip}) => (
            <div className={cx("lp-line-1s7 lp-line", {hasError}, {"color-picker": colorPicker}, className)} ref={domRef}>
                <div className="label">
                    <span className="label-text">
                        {label}

                        {info &&
                            staticTooltip.renderIcon({
                                icon: (
                                    <img
                                        ref={ref.set}
                                        src={require("../../../common/icons/info-icon-full.svg")}
                                        onMouseEnter={() => hovering.onChange(true)}
                                        onMouseLeave={() => hovering.onChange(false)}
                                        alt=""
                                    />
                                ),
                                className: "info-tooltip",
                            })}
                    </span>
                </div>
                <div className="control">{control}</div>
            </div>
        )
    );

const getStyle = ({left, top, bottom}) => ({
    left: `${left}px`,
    top: `${bottom + 10}px`,
});
