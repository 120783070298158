import "./in-the-current.scss";

import {scope} from "@common/react/scope";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {stateToSelect} from "@common/ui-components/form/state-to-select";
import * as React from "react";

const OPTIONS = [{
    value: "Days",
    label: "Day",
}, {
    value: "Weeks",
    label: "Week (starts Sunday)",
}, {
    value: "Months",
    label: "Month",
}, {
    value: "Years",
    label: "Year",
}];

export const InTheCurrent = ({customRange}) => {
    return (
        <div className="edit-custom-range inline">
            {DropdownSelect({
                list: OPTIONS,
                valueToLabel: (item) => item.label,
                ...stateToSelect(scope(customRange, ["interval"]), ["value"]),
            })}
        </div>
    );
};
