import "./ds-sync-settings-layout.scss";

import {cx} from "emotion";

import {cs} from "@common/react/chain-services";
import {SuspendUpdate} from "@common/react/suspend-update";

import {omit} from "@common/utils/objects";
import {Button} from "@common/form/buttons/button/button";

export const DsSyncSettingsLayout = ({mode = null, className, editing, content, rightPanel, rightFn, rightButtons}) =>
    cs(() => {
        return (
            <div className={cx("ds-sync-settings-layout-88w", className)}>
                <div className="header">
                    <div className="left">
                        <div className="mode">
                            <div className="text">{mode ?? `Edit Mode`}</div>
                            <div className="label">{editing.label}</div>
                        </div>
                    </div>

                    <div className="right">
                        {rightFn}
                        <div className="buttons">
                            {rightButtons?.map((button, i) => (
                                <Button key={i} {...omit(button, ["label"])}>
                                    {button.label}
                                </Button>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="main">
                    <div className="content">{content}</div>
                </div>

                {rightPanel && (
                    <>
                        <div
                            className={cx("right-panel", {
                                active: rightPanel.content,
                            })}
                            style={rightPanel?.style || {}}
                        >
                            {cs(
                                ({}, next) =>
                                    SuspendUpdate({
                                        active: !rightPanel.content,
                                        delay: 500,
                                        next,
                                    }),
                                () => rightPanel.content
                            )}
                        </div>

                        {rightPanel.content && <div className="overlay" />}
                    </>
                )}
            </div>
        );
    });
