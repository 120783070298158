import {cs} from "@common/react/chain-services";
import {scope} from "@common/react/scope";
import {LeftPanelClosable} from "../../../../../../../../../common/left-panel-closable/left-panel-closable";
import {LeftPanel} from "../../../../../left-panel";
import {ChildTileTabs} from "./child-tile-tabs/child-tile-tabs";
import {SelectTileType} from "../../../../../../../../common/select-tile-type/select-tile-type";
import {TILES, tileTypes} from "../../../../../../../../common/select-tile-type/tile-types";

export const ManageTile = ({tile, size, tileAction, panelTile, onCancel, next}) =>
    cs(
        [
            "tabs",
            (_, next) =>
                ChildTileTabs({
                    tile,
                    size,
                    childTile: scope(tileAction, ["tile"]),
                    tileAction,
                    next,
                }),
        ],
        ({tabs}) => {
            return next({
                override: (() => {
                    if (!tileAction.value?.tile?.id) {
                        return LeftPanelClosable({
                            onClose: onCancel,
                            label: "Manage Tile",
                            content: SelectTileType({
                                panel: panelTile,
                                list: tileTypes.filter(
                                    (t) => ![TILES.TEXT, TILES.SECTION_TITLE, TILES.CONTAINER].includes(t.key)
                                ),
                            }),
                        });
                    }

                    const override = tabs.getOverride?.() || null;

                    if (override) {
                        return override;
                    }

                    return LeftPanelClosable({
                        onClose: onCancel,
                        label: "Manage Tile",
                        content: LeftPanel(tabs),
                    });
                })(),
                overrideAll: (() => {
                    const override = tabs.getOverrideAll?.() || null;

                    if (override) {
                        return override;
                    }
                })(),
            });
        }
    );
