import React from "react";

export const UpperCaseIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.8335 15.8333V5.83333H9.16683V4.16666H0.833496V5.83333H4.16683V15.8333H5.8335ZM15.8335 15.8333V5.83333H19.1668V4.16666H10.8335V5.83333H14.1668V15.8333H15.8335Z"
            fill="currentColor"
        />
    </svg>
);

export const TitleCaseIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.33317 5V17.5H6.6665V5H1.6665V3.33334H13.3332V5H8.33317ZM14.9998 11.6667V17.5H13.3332V11.6667H10.8332V10H17.4998V11.6667H14.9998Z" fill="currentColor" />
    </svg>
);
