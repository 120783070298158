const {createEnvApis} = require("./env-apis");
const {createBasicApis} = require("./basic-apis");
const {cs} = require("@common/react/chain-services");

const AuthApis = ({tenantId, refreshAuthToken, onUnauthenticated, next}) =>
    cs(({}) => {
        const params = {tenantId, refreshAuthToken, onUnauthenticated};

        const basicApis = createBasicApis(params);

        return next({
            ...basicApis,

            withEnv: ({envId, next}) => createEnvApis({...params, envId, next}),
        });
    });
exports.AuthApis = AuthApis;
