import {cs} from "@common/react/chain-services";
import {consumeContext, provideContext} from "@common/react/context";
import ViewCollections from "./view-collections/view-collections";
import {equalDeep} from "@common/utils/objects";
import {IndependentContextLayout} from "./independent-context-layout";
import {AddingTile} from "../edit/tabs/tiles/adding-tile/adding-tile";
import {EditContainerTilePanel} from "../container-tile/edit/left-panels/edit-container-tile-panel";
import {EditSubTilePanel} from "../container-tile/edit/left-panels/edit-sub-tile-panel/edit-sub-tile-panel";
import {UseState} from "@common/react/use-state";
import {LeftPanels} from "../container-tile/edit/left-panels/left-panels";
import {createDefaultCollection, createGetFieldInfo} from "./common";
import {getPath, setPath} from "@common/utils/arr-path";

export const ContainerTileLayout = ({savingQueue, selectModelForCloningDialog, moveSharedTileDialog, deleteSharedTileDialog, renameSharedTileDialog}) => {
    return cs(
        consumeContext("routing"),
        consumeContext("sharedTile"),
        consumeContext("collection"),
        consumeContext("theme"),
        (_, next) => provideContext("isContainerTile", true, next),
        ({sharedTile}, next) => createGetFieldInfo({next, sharedTile}),
        [
            "addingTilePanel",
            ({tiles, collection, sharedTile}, next) =>
                AddingTile({
                    isCallFromEditContainer: true,
                    containerTileID: sharedTile.value.id,
                    updateFunc: async (newCollection) => {
                        const _tile = newCollection.gridLocations[0].tile;
                        const newSharedTile = await savingQueue.push(() => _tile);
                        sharedTile.onChange(newSharedTile);
                        return createDefaultCollection(newSharedTile);
                    },
                    collection,
                    next,
                }),
        ],
        [
            "editContainerTilePanel",
            ({collection, theme, addingTilePanel, sharedTile}, next) => {
                return EditContainerTilePanel({
                    collection,
                    theme,
                    addingTilePanel,
                    next,
                    cTileId: sharedTile.value.id,
                    updateFunc: async (newCollection) => {
                        let _tile = newCollection.gridLocations[0].tile;
                        _tile = setPath(
                            _tile,
                            ["tiles"],
                            _tile.tiles.map((t) => ({...t, modelID: _tile.modelID}))
                        );
                        const newSharedTile = await savingQueue.push(() => _tile);
                        sharedTile.onChange(newSharedTile);
                        return createDefaultCollection(newSharedTile);
                    },
                });
            },
        ],
        [
            "editSubTilePanel",
            ({collection, theme, editContainerTilePanel, sharedTile}, next) => {
                const getSubTilePath = (tileId) => ["tiles", (subTile) => subTile.id == tileId];
                return EditSubTilePanel({
                    isSharedTile: true,
                    collection,
                    savingQueue,
                    theme,
                    editContainerTilePanel,
                    cTileId: sharedTile.value.id,
                    getSubTilePath,
                    updateFunc: async (newTile, tileID) => {
                        const newSharedTile = await savingQueue.push((_sharedTile) =>
                            setPath(_sharedTile, getSubTilePath(tileID), {
                                ...newTile,
                                isShared: true,
                                modelID: sharedTile.value?.modelID,
                            })
                        );
                        sharedTile.onChange(newSharedTile);
                        return getPath(newSharedTile, getSubTilePath(tileID));
                    },
                    next,
                });
            },
        ],
        ["interactions", (_, next) => UseState({initValue: null, next})],
        ({sharedTile, editSubTilePanel, editContainerTilePanel, addingTilePanel, tabs, interactions}) => {
            return IndependentContextLayout({
                tileCommands: [
                    {
                        label: "Rename",
                        onClick: () => renameSharedTileDialog.show({tile: sharedTile.value}),
                    },
                    {
                        label: "Clone Shared Tile",
                        onClick: () =>
                            selectModelForCloningDialog.show({
                                tile: sharedTile.value,
                            }),
                    },
                    {
                        label: "Move To",
                        onClick: () => moveSharedTileDialog.show({params: sharedTile.value}),
                    },
                    {
                        label: "Delete Shared Tile",
                        className: "delete-action",
                        onClick: () => deleteSharedTileDialog.show({tiles: [sharedTile.value]}),
                    },
                ],
                interactions,
                sharedTile: {
                    value: sharedTile.value,
                    onChange: async (_sharedTile) => {
                        sharedTile.onChange(_sharedTile);
                        await savingQueue.push(() => _sharedTile);
                    },
                    change: async (reducer) => {
                        sharedTile.change(reducer);
                        await savingQueue.push(reducer);
                    },
                },
                leftPanel: {
                    content: <LeftPanels editSubTilePanel={editSubTilePanel} overrideLeftPanels={[editContainerTilePanel, addingTilePanel]} />,
                    width: editSubTilePanel.leftPanelWidth,
                },
                main: editSubTilePanel.main(),
                syncState: {
                    type: savingQueue.executing ? "saving" : equalDeep(editSubTilePanel?.editTileLogic?.tile?.value, editSubTilePanel?.remoteSubTile?.value) ? "saved" : "unsaved",
                    onClick: editSubTilePanel?.editTileLogic?.form?.showErrors,
                },
                rightPanel: {
                    content: interactions.value?.name === "view-collections" && (
                        <ViewCollections onClose={() => interactions.onChange(null)} sharedTile={editSubTilePanel?.editTileLogic?.tile.value} />
                    ),
                },
            });
        }
    );
};
