import {cs} from "@common/react/chain-services";
import * as React from "react";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {ffToDropdown} from "../../../../../../../../../common/form/ff-to-dropdown";
import {TextInput} from "../../../../../../../../../common/form/text-input/text-input";
import {ffToTextInput} from "../../../../../../../../../common/form/ff-to-text-input";
import {MpLine} from "../../common/mp-line/mp-line";
import {ShowHideToggle} from "../../../../../../../../../common/form/toggles/show-hide-toggle";
import {scope} from "@common/react/scope";
import {getPath} from "@common/utils/arr-path";
import {TextareaInput} from "../../../../../tile/edit/left-panel/tabs/tile-tab/chart-types/common/textarea-input/textarea-input";
import "./boolean-filter-options.scss";
import {stateToSelect} from "@common/ui-components/form/state-to-select";
import {booleanFilterControlTypes} from "./boolean-filter-control-types";
import {OnOffToggle} from "../../../../../../../../../common/form/toggles/on-off-toggle";
import {consumeContext} from "@common/react/context";
import {upperCase1} from "@common/utils/strings";
import {BlanksMessage} from "../number-filter/blanks-message/blanks-message";

export const BooleanFilterOptions = ({filter, form}) =>
    cs(consumeContext("collection"), ({collection}) => {
        return (
            <div className="boolean-filter-options-73f">
                {BlanksMessage({
                    value: `Blank boolean values will be counted as a “False” value.`,
                })}

                <div className="option-group label">
                    <div className="label">Filter Label</div>
                    {TextInput({
                        label: "Label",
                        ...ffToTextInput(form.field(["label"])),
                    })}

                    {filter.value.label && (
                        <>
                            {MpLine({
                                left: ShowHideToggle({
                                    state: scope(filter, ["showLabelInfoIcon"]),
                                }),
                                right: "Filter Label Info Icon",
                            })}
                            {getPath(filter.value, ["showLabelInfoIcon"]) &&
                                TextareaInput({
                                    state: scope(filter, ["labelInfoIconText"]),
                                })}
                        </>
                    )}
                </div>

                {collection.value?.$type !== "ApiCollection" && (
                    <div className="option-group">
                        <div className="label">Filter Settings</div>

                        {filter.value.columns?.length > 1 &&
                            DropdownSelect({
                                label: "Filter Matching Criteria",
                                list: ["Any", "All"],
                                info: "The filter criteria may either be found in at least one of the fields or must be found in all of the fields searched. ",
                                tooltipTopOffset: -5,
                                valueToLabel: (op) =>
                                    [
                                        {
                                            label: "Show results meeting ANY criteria",
                                            value: "Any",
                                        },
                                        {
                                            label: "Show results meeting ALL criteria",
                                            value: "All",
                                        },
                                    ].find((item) => item.value === op).label,
                                ...ffToDropdown(form.field(["multiSelectionOption"])),
                            })}

                        {(() => {
                            const {value, onChange} = scope(filter, ["design"]);
                            return DropdownSelect({
                                label: "Control Type",
                                list: booleanFilterControlTypes,
                                valueToLabel: (item) => item.label,
                                ...stateToSelect(
                                    {
                                        value: value || "Dropdown",
                                        onChange,
                                    },
                                    ["name"]
                                ),
                            });
                        })()}

                        {/*{MpLine({*/}
                        {/*    left: OnOffToggle({state: scope(filter, ["required"])}),*/}
                        {/*    right: "Require value to run this dashboard report",*/}
                        {/*})}*/}
                    </div>
                )}

                <div className="option-group default-criteria">
                    <div className="label">Default Filter Criteria</div>

                    {DropdownSelect({
                        label: "Value",
                        list: [null, "True", "False"],
                        valueToLabel: (op) => (op === null ? "All" : op),
                        ...ffToDropdown(form.field("defaultValue")),
                        isSelected: (v) => {
                            const state = form.field("defaultValue").state;
                            if (state.value !== null) {
                                return upperCase1(state.value.toString()) == v;
                            }
                            return v === null;
                        },
                    })}
                </div>
            </div>
        );
    });
