import {cs} from "@common/react/chain-services";
import {stateToCheckboxLineGroup} from "../../../../../../../common/checkbox-line-group/state-to-checkbox-line-group";
import * as React from "react";
import {dateRanges} from "../date-ranges";
import "./date-ranges.scss";
import {stringListState} from "../../../../../../../common/data-logic/string-list-state";
import {enforceDefault} from "../../common/enforce-default";
import {CheckboxLineGroupFlex} from "../../../../../../../common/checkbox-line-group/checkbox-line-group-flex";
import {getDateRangesFromMaxMinSelection} from "../date-picker-selectable-range/utils";

export const DateRanges = ({filter}) =>
    cs(({}) => {
        const allowedDateRanges = getDateRangesFromMaxMinSelection(filter).map((each) => each.name);
        return cs(() => (
            <div className="date-ranges date-ranges-5qq">
                <div className="header">Date Ranges</div>

                <div className="content">
                    {CheckboxLineGroupFlex({
                        list: dateRanges,
                        isDisabled: (option) => {
                            return !allowedDateRanges.includes(option.name);
                        },
                        // rowMap: [3, 3, 3, 3],
                        colMap: [3, 4, 4, 4, 5],
                        colInfo: {
                            3: "Range includes today", // lastXDays col
                            4: "Range ends yesterday", // PreviousXDays col
                        },
                        ...stateToCheckboxLineGroup(stringListState(enforceDefault(filter)), ["name"]),
                    })}
                </div>
            </div>
        ));
    });
