import React from "react";
import {cs} from "@common/react/chain-services";
import {Load} from "@common/react/load";
import {loadScript} from "../load-script";

export const GAPIRoot = ({next}) =>
    cs(
        (_, next) =>
            Load({
                fetch: async () =>
                    await loadScript({
                        src: "https://apis.google.com/js/client:platform.js",
                        async: true,
                        defer: true,
                    }),
                next,
            }),
        () => next()
    );

export const loadGApi = ({next}) => cs(() => GAPIRoot({next}));
