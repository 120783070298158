// fix dimension axis label on scrolling
const fixDimAxisLabel = (chart) => {
    const name = "fixedDimAxisLabel";
    chart[name]?.remove();

    const realLabel = chart.xAxis[0].axisGroup?.element?.getElementsByClassName("highcharts-axis-title")?.[0];

    if (!realLabel) {
        return;
    }

    const visibleAreaWidth = chart.chartWidth - chart.axisOffset[1] - chart.axisOffset[3];
    const leftPos = visibleAreaWidth / 2 + chart.axisOffset[3] / 2 + chart.marginRight;
    const topPos = realLabel.getBoundingClientRect().y - chart.container.parentElement.getBoundingClientRect().y;

    if (!chart.container.parentElement.classList.contains("highcharts-inner-container")) {
        chart.container.parentElement.style.position = "relative";
    }

    chart[name] = chart.container.parentElement.appendChild(
        createFixedDimAxisLabel({
            cssText: realLabel.style.cssText,
            leftPos,
            topPos,
            text: realLabel.textContent,
        })
    );

    // hide the moving label
    realLabel.style.opacity = "0";
};
exports.fixDimAxisLabel = fixDimAxisLabel;

const createFixedDimAxisLabel = ({cssText, leftPos, topPos, text, rotated}) => {
    const fakeDimAxisLabel = document.createElement("div");

    fakeDimAxisLabel.style = cssText;
    fakeDimAxisLabel.style.opacity = "1";
    fakeDimAxisLabel.style.zIndex = "1";
    fakeDimAxisLabel.innerText = text;

    fakeDimAxisLabel.style.position = "absolute";
    fakeDimAxisLabel.style.left = `${leftPos}px`;
    fakeDimAxisLabel.style.top = `${topPos}px`;

    if (rotated) {
        fakeDimAxisLabel.style.transform = "rotate(-90deg) translateX(-50%)";
        fakeDimAxisLabel.style["transform-origin"] = "left";
    } else {
        fakeDimAxisLabel.style.transform = "translateX(-50%)";
    }

    return fakeDimAxisLabel;
};
exports.createFixedDimAxisLabel = createFixedDimAxisLabel;
