import React from "react";

import {cs} from "@common/react/chain-services";
import {RoutingParam} from "../common/routing/routing-param";
import {DashboardLayout} from "../dashboard/layout/dashboard-layout";
import {consumeContext} from "@common/react/context";
import {AccountDetailsDashboard} from "./account-details-dashboard";
// import {OrgDetailsDashboard} from "./dashboard/org-details/org-details-dashboard";
// import {SystemApiKeysDashboard} from "./dashboard/system-api-keys/system-api-keys-dashboard";

export const AccountManagementRoute = () =>
    cs(
        consumeContext("routing"),
        [
            "tab",
            (_, next) =>
                RoutingParam({
                    param: "personal",
                    next,
                }),
        ],
        ["envId", (_, next) => RoutingParam({param: "envId", next})],
        ({routing, envId}, next) =>
            DashboardLayout({
                tab: {
                    value: null,
                    onChange: (tab) => routing.goto("dashboard", {envId: envId.value, tab}),
                },
                envId: {
                    value: envId.value,
                    onChange: (envId) => routing.goto("dashboard", {envId}),
                },
                next,
            }),
        ({routing}) => AccountDetailsDashboard({defaultTab: routing.params.setting})
    );
