import { Button } from "@common/form/buttons/button/button";
import { SearchInput2 } from "@common/form/search-input/search-input-2";
import { cs } from "@common/react/chain-services";
import { consumeContext } from "@common/react/context";
import { getELoc } from "@common/react/get-e-loc";
import { Load2 } from "@common/react/load2";
import { UseState } from "@common/react/use-state";
import { createDateFormatter } from "@common/ui-components/charts/common/formatters/date-formatter";
import { VerbScrollbar } from "@common/ui-components/verb-scrollbar/verb-scrollbar";
import { css, cx } from "emotion";
import * as React from "react";
import { Fragment } from "react";
import { Expandable } from "../../../../common/expandable/expandable";
import { StaticTooltipService } from "../../../../common/tooltip3/static-tooltip-service";
import { tooltipService3 } from "../../../../common/tooltip3/tooltip-service-3";
import { buildFoldersTree } from "../../../../dashboard/env/collections/folders-tree-structure";
import { DragWatcher } from "../../../../model/common/drag-watcher";
import { DataMenuSkeletonLoading } from "../../../../model/edit/tabs/data/data-menu/skeleton-loading/data-menu-skeleton-loading";
import { SingleKPIIcon } from "../../../common/select-tile-type/tile-icons";
import { tileTypes } from "../../../common/select-tile-type/tile-types";
import { AddingTileDashboard } from "../tiles/adding-tile/adding-tile-dashboard";
import "./shared-tiles-tab.scss";

export const SharedTilesTab = ({next, panel}) =>
    cs(
        consumeContext("collection"),
        consumeContext("routing"),
        consumeContext("apis"),
        consumeContext("sharedTileFolders"),
        ["keyword", (_, next) => UseState({next, initValue: ""})],
        ({keyword, sharedTileFolders, collection}) =>
            next({
                renderLeftPanel: () => {
                    if (!sharedTileFolders.value) {
                        return <DataMenuSkeletonLoading />;
                    }

                    const folders = {
                        value: sharedTileFolders.value
                            .map((folder) => ({
                                ...folder,
                                tiles: folder.tiles.filter((t) => t.modelID == collection.value.modelID),
                            }))
                            .filter((f) => f.tiles.length > 0),
                    };
                    const foldersTree = buildFoldersTree({
                        itemsProp: "tiles",
                        folders: folders.value,
                        keyword: keyword.value,
                    })[0];
                    
                    const renderFolder = (data, deep) => {
                        return Expandable({
                            className: css`
                                .toggle {
                                    padding-left: ${34 + 8 * deep}px !important;

                                    i {
                                        left: ${14 + deep * 8}px !important;
                                    }
                                }
                            `,
                            label: () => <div className={cx("folder-name")}>{data.name}</div>,
                            render: () => (
                                <div className="folder-items-rendered">
                                    {data.children.map((children, index) => (
                                        <Fragment key={index}>{renderFolder(children, deep + 1)}</Fragment>
                                    ))}

                                    {data.tiles.map((tile, index) => (
                                        <Fragment key={index}>{renderTile(tile, deep + 1)}</Fragment>
                                    ))}
                                </div>
                            ),
                        });
                    };

                    const renderTile = (tile, deep) =>
                        cs(
                            [
                                "dragWatcher",
                                (_, next) =>
                                    DragWatcher({
                                        onDragged: (e) => {
                                            if (panel) {
                                                panel?.startDraggingNewTile({
                                                    tile: {
                                                        ...tileTypes.find((t) => t.types.includes(tile.type)).template,
                                                        title: tile.name,
                                                        $type: tile.type,
                                                        adminTileName: tile.adminTileName,
                                                        isShared: true,
                                                        id: tile.id,
                                                        defaultColSpan: tile.defaultColSpan,
                                                        defaultRowSpan: tile.defaultRowSpan,
                                                        modelID: tile.modelID,
                                                    },
                                                    pos: getELoc(e),
                                                });
                                            }
                                        },
                                        next,
                                    }),
                            ],
                            tooltipService3(),
                            ({dragWatcher, tooltip}) => {
                                const disabled = collection.value.gridLocations.find((gl) => gl.tile.id == tile.id);

                                return (
                                    <div
                                        className="tile-wrapper"
                                        style={{
                                            marginLeft: `${20 + 8 * deep}px`,
                                        }}
                                    >
                                        <div
                                            {...(disabled
                                                ? {
                                                      ...tooltip("Shared Tile already used in collection"),
                                                  }
                                                : {})}
                                            className={cx("tile-box", disabled && "disabled")}
                                            onMouseDown={(e) => {
                                                if (!disabled) {
                                                    dragWatcher.onMouseDown(e);
                                                }
                                            }}
                                        >
                                            {tileTypes.find((t) => t.types.includes(tile.type))?.icon}
                                            {tile.adminTileName || tile.name}
                                        </div>

                                        <Button btnType="border" size="small">
                                            Open
                                        </Button>
                                    </div>
                                );
                            }
                        );

                    return (
                        <VerbScrollbar className="shared-tiles-tab-a33">
                            <div className="search-header">
                                <SearchInput2 state={keyword} className={"search-input-wrapper"} />
                            </div>

                            {foldersTree?.children.map((children, index) => (
                                <Fragment key={index}>{renderFolder(children, 0)}</Fragment>
                            ))}

                            {foldersTree?.tiles.map((tile, index) => (
                                <Fragment key={index}>{renderTile(tile, 0)}</Fragment>
                            ))}
                        </VerbScrollbar>
                    );
                },
            })
    );
