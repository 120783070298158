import React from "react";
import {cs} from "@common/react/chain-services";
import {IgnoreUpdate} from "@common/react/ignore-update";
import {UseState} from "@common/react/use-state";
import {equalDeep} from "@common/utils/objects";

export const InputConnection = ({transform, isTransformationStep, onConnected, width, css, size}) =>
    cs(
        // (_, next) => IgnoreUpdate({
        //     props: {transform, css, size},
        //     when: (pp) => equalDeep(transform, pp.transform) && equalDeep(pp.css, css) && size === pp.size,
        //     next
        // }),
        ["deltaHover", (_, next) => UseState({initValue: 0, next})],
        ({deltaHover}) => {
            const delta1 = size + deltaHover.value;
            return (
                <g
                    transform={`translate(${transform.x}, ${transform.y})`}
                    onClick={() => onConnected()}
                    style={{cursor: "pointer"}}
                    onMouseOver={() => deltaHover.onChange(1)}
                    onMouseOut={() => deltaHover.onChange(0)}
                    className="input-connection-7er"
                >
                    {isTransformationStep ? (
                        <path
                            d={`
                        M ${width / 2} ${delta1} 
                        L ${width / 2 - delta1} -${delta1} 
                        L ${width / 2 + delta1} -${delta1} z`}
                            {...css}
                        />
                    ) : (
                        <path d={`M ${0} ${-width + delta1} L ${0 - delta1} ${-width - delta1} L ${delta1} ${-width - delta1} z`} {...css} />
                    )}
                </g>
            );
        }
    );
