import {getTooltipOptions} from "../../factory/tooltip/tooltip-options";
import {getFunnelSectionColors} from "./funnel-section-colors";

const {getSectionLabelsOptions} = require("./section-labels");
const {cGetFontSize} = require("../../common/style-map/font-size");

export const getFunnelChartOptions = ({tile, theme, rawData, formatters, isCompare, onClickPoint}) => {
    const getFontSize = cGetFontSize(theme.general.canvas.fontSize, theme);

    const chart = {
        type: "pyramid",
        backgroundColor: theme.general.tile.styles.tileBackgroundColorRGB,
    };

    const title = {
        text: undefined,
    };

    const plotOptions = {
        series: {
            cursor: onClickPoint ? "pointer" : "default",
            dataLabels: getSectionLabelsOptions({tile, theme, formatters}),
            // center: ['40%', '50%'],
            // width: '80%'

            // sections' behaviour on hover
            states: {
                inactive: {
                    opacity: 1,
                },
            },
            point: {
                events: {
                    mouseOver: function () {
                        const haloSize = 7;
                        const halo = this.series.halo;

                        // this is the "constant" style one
                        // const [topLeft, topRight, bottomRight, bottomLeft, end] = this.graphic.pathArray;

                        // this is the latest one if stage style is Proportional. support both constant and proportional stage style.
                        const pathArray = this.graphic.element.getAttribute("d").split(" ");
                        const topLeft = pathArray.slice(0, 3);
                        const topRight = pathArray.slice(3, 6);
                        const bottomRight = pathArray.slice(6, 9);
                        const bottomLeft = pathArray.slice(9, 12);
                        const end = pathArray.slice(12);

                        const haloTopLeft = [topLeft[0], +topLeft[1] - haloSize, topLeft[2]];
                        const haloTopRight = [topRight[0], +topRight[1] + haloSize, topRight[2]];
                        const haloBottomRight = [bottomRight[0], +bottomRight[1] + haloSize, bottomRight[2]];
                        const haloBottomLeft = [bottomLeft[0], +bottomLeft[1] - haloSize, bottomLeft[2]];

                        const haloPath = [haloTopLeft, haloTopRight, haloBottomRight, haloBottomLeft, end]
                            .map((v) => v.join(" "))
                            .join(" ");

                        halo.attr({d: haloPath});
                    },
                    click: function ({x, y, point}) {
                        onClickPoint?.({x, y, point, chart: this.series.chart});
                    },
                },
            },
        },
        pyramid: {
            reversed: tile.style.displayType === "Pyramid",
            colors: getFunnelSectionColors({rawData, tile, theme}),
            borderColor: theme.general.tile.styles.tileBackgroundColorRGB,
            stageStyle: tile.style.stageStyle,
        },
    };

    const legend = {
        enabled: false, // draw legend manually
        itemStyle: {
            fontSize: getFontSize({
                group: theme.dataVisualization.toolTipsAndLegends.legendFontSize,
            }),
        },
    };

    const tooltip = getTooltipOptions({
        tile,
        theme,
        formatters,
        rawData,
        isCompare,
    });

    return {
        chart,
        title,
        plotOptions,
        legend,
        tooltip,
        credits: {
            enabled: false,
        },
    };
};
