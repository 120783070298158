const defaultDateValue = () => ({
    $type: "DateFilter",
    options: "Custom",
    defaultOption: "Custom",
    // compareEnabled: true,
    // compareOnByDefault: true,
    customRange: {
        $type: "InTheLastOrNextCustomDateFilterRange",
        value: 90,
        interval: "Days",
        negative: false,
    },
});
const defaultTextValue = () => ({
    $type: "TextFilter",
    // options: "FreeFormText", // SelectableList
    // design: "Dropdown" "ButtonBar"
    // defaultOperator: Equals, DoesNotEqual, StartsWith, EndsWith, Contains, DoesNotContain, IsBlank, IsNotBlank
    // defaultValues
});
const defaultNumberValue = () => ({
    $type: "NumericFilter",
    aggregationFunc: "None",
    defaultOption: "Range",
});

const defaultBooleanValue = () => ({
    $type: "BooleanFilter",
    defaultValue: true,
});

const contextualFilterDefaultValues = (type) =>
    ({
        DateFilter: defaultDateValue,
        TextFilter: defaultTextValue,
        NumericFilter: defaultNumberValue,
        BooleanFilter: defaultBooleanValue,
    }[type]());
exports.contextualFilterDefaultValues = contextualFilterDefaultValues;
