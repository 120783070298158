import * as React from "react";
import {cs} from "../../../../react/chain-services";
import {downloadBlob} from "../../../../utils/browser/download-service";
import {UseState} from "../../../../react/use-state";
import "./download-button.scss";
import {consumeContext} from "../../../../react/context";
import {cGetFontSize} from "../../common/style-map/font-size";
import {css, cx} from "emotion";
import {Dropdown} from "../../../dropdown/dropdown";
import {downloadWithToast} from "@common/ui-components/toast/downloadWithToast";
import {StyledClass} from "@common/react/styled-class";

export const DownloadReportButton = ({tile, theme, tileFilters, downloadData}) =>
    cs(
        consumeContext("sdkStaticUrl"),
        consumeContext("selectedTimezone"),
        consumeContext("downloadToast"),
        ["downloading", (_, next) => UseState({next})],
        [
            "buttonCss",
            ({}, next) => {
                const primaryButtonTheme = theme.general.buttons.primaryButton;
                const getFontSize = cGetFontSize(theme.general.canvas.fontSize, theme);

                return StyledClass({
                    content: {
                        background: `${tile.style.buttonColorRGB || primaryButtonTheme.backgroundColorRGB}`,
                        border: `${primaryButtonTheme.borderWidth}px solid ${
                            tile.style.buttonColorRGB || primaryButtonTheme.borderColorRGB
                        }`,
                        color: `${tile.style.buttonFontColorRGB || primaryButtonTheme.fontColorRGB}`,
                        "font-size": `${getFontSize({
                            group: tile.style.buttonFontSize,
                            elemType: "label",
                        })}px`,
                        "font-family": `${theme.general.canvas.fontFamily}, sans-serif`,
                        "border-radius": `${primaryButtonTheme.cornerRadius}px`,

                        "&:hover": {
                            background: `${tile.style.buttonHoverColorRGB || primaryButtonTheme.hoverBackgroundColorRGB}`,
                            color: `${primaryButtonTheme.hoverFontColorRGB}`,
                            "border-color": `${primaryButtonTheme.hoverBorderColorRGB}`,
                        },
                    },
                    next,
                });
            },
        ],
        [
            "itemCss",
            ({}, next) => {
                return StyledClass({
                    content: {
                        background: `${theme.general.components.menuBackgroundColorRGB}`,
                        color: `${theme.general.components.menuTextColorRGB}`,

                        "&:first-child": {
                            "border-top-left-radius": `${theme.general.components.menuCornerRadius}px`,
                            "border-top-right-radius": `${theme.general.components.menuCornerRadius}px`,
                        },

                        "&:last-child": {
                            "border-bottom-left-radius": `${theme.general.components.menuCornerRadius}px`,
                            "border-bottom-right-radius": `${theme.general.components.menuCornerRadius}px`,
                        },

                        "&:hover": {
                            background: `${theme.general.components.menuHoverBackgroundColorRGB}`,
                        },

                        "&.selected": {
                            background: `${theme.general.components.menuHoverBackgroundColorRGB}`,
                        },
                    },
                    next,
                });
            },
        ],
        ({downloading, selectedTimezone, downloadToast, buttonCss, itemCss}) => {
            const formats = tile.style.downloadFormats.split(",").map((f) => ({
                format: f.trim(),
                label: f.trim() === "XLSX" ? "XLSX (Excel)" : f.trim(),
            }));

            // formats: XLSX, CSV, JSON
            const download = async ({format}) => {
                downloading.onChange(true);

                const {blob, filename} = await downloadWithToast({
                    fetch: () =>
                        downloadData({
                            format,
                            timeZoneId: selectedTimezone?.value,
                            filters: tileFilters.getValue(),
                        }),
                    toast: downloadToast,
                    _key: `${tile.id}${Date.now()}`,
                });

                //console.log("downloaded", filename)
                if (blob) {
                    downloadBlob(blob, {
                        name: filename || `${tile.title}.${new Date().toISOString()}.${format.toLowerCase()}`,
                    });
                }
                downloading.onChange(false);
            };

            return (
                <div
                    className="download-button-2tu"
                    style={{
                        backgroundColor: theme.general.tile.styles.tileBackgroundColorRGB,
                    }}
                >
                    {formats.length === 1
                        ? SingleFormatButton({
                              text: tile.style.buttonText,
                              buttonCss,
                              disabled: downloading.value,
                              onClick: () => download({format: formats[0].format}),
                          })
                        : MultiFormatButton({
                              text: tile.style.buttonText,
                              buttonCss,
                              disabled: downloading.value,
                              itemCss,
                              formats,
                              onClick: (format) => download({format}),
                              borderRadius: theme.general.components.menuCornerRadius,
                          })}

                    {/*<div className={cx("controls", {"high": tile.style.title.show && tile.style.title.titleLocation === "Above"})}>*/}
                    {/*    {tile.filters?.length > 0 && (*/}
                    {/*        <img className="local-filter-icon"*/}
                    {/*             src={sdkStaticUrl(require("../../common/local-filter-icon.svg"))} alt={""}/>*/}
                    {/*    )}*/}
                    {/*</div>*/}
                </div>
            );
        }
    );

const SingleFormatButton = ({text, disabled, onClick, buttonCss}) => (
    <button
        {...{
            className: cx("single-format-button", buttonCss),
            disabled,
            onClick,
        }}
    >
        {text}
    </button>
);

const MultiFormatButton = ({text, formats, onMouseOver, onMouseOut, disabled, onClick, buttonCss, itemCss, borderRadius}) =>
    cs(
        [
            "renderToggle",
            (_, next) =>
                next(({showExpand, showingExpand}) => (
                    <button
                        {...{
                            className: cx("toggle", buttonCss),
                            onMouseOver,
                            onMouseOut,
                            disabled,
                            onClick: () => showExpand(!showingExpand),
                        }}
                    >
                        <span>{text}</span>
                        <i className={cx("fa", !showingExpand ? "fa-caret-down" : "fa-caret-up")} />
                    </button>
                )),
        ],
        [
            "renderExpand",
            (_, next) =>
                next(({close, width}) => (
                    <div className="format-list" style={{minWidth: width, borderRadius}}>
                        {formats.map((format, i) => (
                            <div
                                key={i}
                                className={cx("item", itemCss)}
                                onClick={() => {
                                    onClick(format.format);
                                    close();
                                }}
                            >
                                {format.label}
                            </div>
                        ))}
                    </div>
                )),
        ],
        ({renderToggle, renderExpand}) => (
            <div className="multi-format-button multi-format-button-423">
                {Dropdown({
                    renderToggle,
                    renderExpand,
                    useTogglePosition: true,
                    borderRadius,
                })}
            </div>
        )
    );
