import "./total-row.scss";

import * as React from "react";
import {css, cx} from "emotion";

import {cs} from "../../../../../react/chain-services";
import {cGetFontSize} from "../../../common/style-map/font-size";
import {getFontOptions} from "../common/get-font-options";
import {chain} from "../../../../../utils/fs";
import {keyed} from "../../../../../react/keyed";
import {TableBorder} from "../table-border/table-border";
import {ArrowDown, ArrowUp, Triangle} from "../data-rows/data-rows";
import {isNil} from "../../../../../utils/common";
import {StyledClass} from "@common/react/styled-class";

export const TotalRow = ({data, tile, theme, columns}) => {
    const getFontSize = cGetFontSize(theme.general.canvas.fontSize, theme);
    const tableStyle = theme.dataVisualization.tables;
    const colorPalettes = theme.dataVisualization.dataColorPalettes;
    const fontSize = getFontSize({
        group: tableStyle.totalsFontSize || theme.general.canvas.fontSize,
        elemType: "label",
    });

    const renderBorderLeftRight = (i, total) => (
        <>
            {i == 0 && tableStyle.borderOutsideLines && (
                <div
                    className="border-left"
                    style={{
                        width: tableStyle.borderWidth,
                        background: tableStyle.borderColorRGB,
                    }}
                />
            )}

            {i == total - 1 && tableStyle.borderOutsideLines && (
                <div
                    className="border-right"
                    style={{
                        width: tableStyle.borderWidth,
                        background: tableStyle.borderColorRGB,
                    }}
                />
            )}
        </>
    );

    return cs(
        [
            "innerCellClass",
            (_, next) =>
                StyledClass({
                    content: {
                        backgroundColor: tableStyle.totalsBackgroundColorRGB,
                        textAlign: "right",
                        fontSize,
                        ...getFontOptions(tableStyle.totalsFontOptions),
                        color: tableStyle.totalsFontColorRGB,
                        padding: "12px 15px",
                    },
                    next,
                }),
        ],
        ({innerCellClass}) => (
            <tr className="total-row-5w2">
                {data.summaryRow.columns.map((sumCol, i) =>
                    cs(keyed(i), () => {
                        const column = columns[i];

                        if (!column) {
                            return null;
                        }

                        const {columnSettings = {}} = column;

                        if (!sumCol || !columnSettings.showSummaryAggregation) {
                            return (
                                <td className={innerCellClass}>
                                    <TableBorder
                                        hideVertical
                                        forceBorderTop
                                        isFirstColumn={i == 0}
                                        isFirstRow
                                        isLastRow
                                        isLastColumn={data.summaryRow.columns.length - 1 == i}
                                    />
                                </td>
                            );
                        }

                        const hasComparisonValue = tile.style.showCompare && column.type == "number" && columnSettings.showComparisonValues;
                        const formatter = chain(columns[i], (column) => (column ? column.formatter : (v) => v));

                        return (
                            <td className={innerCellClass}>
                                <TableBorder
                                    hideVertical
                                    forceBorderTop
                                    isFirstColumn={i == 0}
                                    isFirstRow
                                    isLastRow
                                    isLastColumn={data.summaryRow.columns.length - 1 == i}
                                />
                                {renderBorderLeftRight()}

                                <span>{formatter(sumCol.value)}</span>

                                {hasComparisonValue &&
                                    ComparisonValueText({
                                        ...sumCol,
                                        formatterFunc: formatter,
                                        columnSettings,
                                        colorPalettes,
                                        fontSize,
                                    })}
                            </td>
                        );
                    })
                )}
            </tr>
        )
    );
};

const ComparisonValueText = ({change, difference, previousValue, value, formatterFunc, columnSettings, colorPalettes, fontSize}) => {
    if (isNil(previousValue)) {
        return (
            <div className="comparison-value-h67">
                <span>-</span>
            </div>
        );
    }

    const isGood = (columnSettings.positiveValueGood && difference > 0) || (!columnSettings.positiveValueGood && difference < 0);

    const diffColor = isGood
        ? colorPalettes.otherColors.conditionalGoodColorRGB || "#18C96E"
        : colorPalettes.otherColors.conditionalBadColorRGB || "#E95A5A";

    const usePercentage = columnSettings?.comparisonType == "Percent";
    // const usePercentage = true;

    return (
        <div className="comparison-value-h67">
            <Triangle size={fontSize - 4} />
            <span className="value-change">{usePercentage ? `${(change * 100).toFixed(0)}%` : formatterFunc(difference)}</span>
            {difference > 0 ? ArrowUp({color: diffColor, size: fontSize}) : ArrowDown({color: diffColor, size: fontSize})}
        </div>
    );
};
