import * as React from "react";

import {cs} from "@common/react/chain-services";
import {provideContext} from "@common/react/context";
import {scope} from "@common/react/scope";

import {Expandable} from "../../../../../../../common/expandable/expandable";

import {getPivotTableSortingAfterDeleteValueUpdated} from "./common/limit-sorting/sorting/pivot-table-sorting/get-pivotable-sorting-updated";
import {PivotTableSorting} from "./common/limit-sorting/sorting/pivot-table-sorting/pivot-table-sorting";
import {MultiFieldWell} from "./common/multi-field-well/multi-field-well";
import {MultipleFieldGroup} from "./common/multi-field-well/multiple-field-group";

import {getTileFields} from "./common/get-tile-fields";

const allowDateAggregations = ["Years", "Quarters", "Months", "Days"];

export const PivotTableFields = ({tile, isSubTile, tileActions, debugFunction, form, next}) =>
    cs(
        [
            "tileFields",
            ({}, next) =>
                next(
                    getTileFields({
                        tile: tile.value,
                        measureMultiAttr: ["actualValueFields", "aggregatedValues"],
                    })
                ),
        ],
        ({tileFields}, next) => provideContext("tileFields", tileFields, next),

        [
            "onRemoveField",
            (_, next) =>
                next((attr, field) =>
                    tile.onChange({
                        ...tile.value,
                        [attr]: tile.value[attr].filter((v) => v.id != field.id),
                        ...getPivotTableSortingAfterDeleteValueUpdated(tile.value, attr, field),
                    })
                ),
        ],
        [
            "rowFields",
            ({onRemoveField}, next) =>
                MultiFieldWell({
                    fields: scope(tile, ["rowFields"]),
                    $type: "PivotCategoryTileField",
                    label: "Rows",
                    addLabel: "Add Row",
                    onRemove: (field) => onRemoveField("rowFields", field),
                    dataKey: "rowFields",
                    next,
                    maxFields: 4,
                    filterAggregations: (agg) => allowDateAggregations.indexOf(agg.value) > -1,
                    notAllowedFields: [...(tile.value.columnFields || []), ...(tile.value.valueFields || [])],
                    allowSameFieldAndAggregation: true,
                    hideAggregatedMeasureColumn: true,
                }),
        ],
        [
            "columnFields",
            ({onRemoveField}, next) =>
                MultiFieldWell({
                    fields: scope(tile, ["columnFields"]),
                    $type: "PivotCategoryTileField",
                    label: "Columns",
                    notAllowNoneAggregation: true,
                    addLabel: "Add Column",
                    onRemove: (field) => onRemoveField("columnFields", field),
                    dataKey: "columnFields",
                    next,
                    maxFields: 4,
                    filterAggregations: (agg) => allowDateAggregations.indexOf(agg.value) > -1,
                    notAllowedFields: [...(tile.value.valueFields || []), ...(tile.value.rowFields || [])],
                    allowSameFieldAndAggregation: true,
                    hideAggregatedMeasureColumn: true,
                }),
        ],
        [
            "valueFields",
            ({onRemoveField}, next) =>
                MultiFieldWell({
                    fields: scope(tile, ["valueFields"]),
                    $type: "MeasureTileField",
                    label: "Values",
                    notAllowNoneAggregation: true,
                    addLabel: "Add Value",
                    dataKey: "valueFields",
                    onRemove: (field) => onRemoveField("valueFields", field),
                    next,
                    maxFields: 10,
                    filterAggregations: (agg) => allowDateAggregations.indexOf(agg.value) > -1,
                    notAllowedFields: [...(tile.value.columnFields || []), ...(tile.value.rowFields || [])],
                    allowSameFieldAndAggregation: true,
                    hideAggregatedMeasureColumn: true,
                }),
        ],
        [
            "multipleFieldsGroup",
            ({rowFields, columnFields, valueFields}, next) =>
                MultipleFieldGroup({
                    group: [rowFields, columnFields, valueFields],
                    allowDragToAnotherGroup: true,
                    tile,
                    next,
                }),
        ],
        [
            "fieldsSection",
            ({multipleFieldsGroup, rowFields, columnFields, valueFields}, next) =>
                next({
                    render: () =>
                        Expandable({
                            label: "Fields",
                            render: () => multipleFieldsGroup.render(),
                        }),
                    override: rowFields.override || columnFields.override || valueFields.override,
                }),
        ],

        [
            "limitSorting",
            ({tileFields}, next) =>
                cs(["sorting", (_, next) => next(PivotTableSorting({tile}))], ({sorting}) =>
                    next({
                        render: () =>
                            Expandable({
                                initExpand: false,
                                label: "Sorting",
                                render: () => <>{sorting}</>,
                            }),
                    })
                ),
        ],

        ({limitSorting, fieldsSection}) =>
            next({
                render: () => (
                    <div className="tile-fields-3vr">
                        {fieldsSection.render()}
                        {limitSorting.render()}
                        {/*{tileActions?.render?.()}*/}
                        {isSubTile && debugFunction?.render?.({})}
                    </div>
                ),
                override: fieldsSection.override || debugFunction?.override(),
                overrideAll: debugFunction?.overrideAll,
            })
    );
