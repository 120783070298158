import React from "react";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {scope} from "@common/react/scope";
import {ExpandableContent} from "../../../../common/expandable/expandable";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {TextInput} from "../../../../../../../common/form/text-input/text-input";
import "./outbound-api-steps.scss";
import {removeIndex} from "@common/utils/collections";
import {TrashIconBold} from "../../../../common/icons/trash-icon";
import {TabsHeader} from "../../../../common/tabs/tabs-header";
import {keyed} from "@common/react/keyed";
import {RadioLine} from "../../../../common/radio-line/radio-line";
import {spc} from "@common/react/state-path-change";
import {rOutboundAPIStepResultMapping} from "./outboud-api-step-result-mapping";
import {TextareaInput} from "../../../../collection/tile/edit/left-panel/tabs/tile-tab/chart-types/common/textarea-input/textarea-input";
import {DnDList} from "../../../../common/dnd-list/dnd-list";
import {cx} from "emotion";
import {MoveIconSvg} from "../../../../common/icons/move-icon-svg";
import {getPath} from "@common/utils/arr-path";
import {isBlank} from "@common/utils/strings";
import {OutboundKeyValueInputs} from "../outbound-api-inputs";
import {Button} from "../../../../../../../common/form/buttons/button/button";

const methodWithoutContent = ["Get", "Delete", "Head", "Options"];
const methodWithContent = ["Post", "Put", "Patch"];
export const dictionaryTypes = ["FormHttpRequestContent", "FormUrlEncodedHttpRequestContent", "DictionaryJsonHttpRequestContent"];

export const OutboundAPISteps = ({state, hasError}) => {
    return cs(
        [
            "dnd",
            (_, next) =>
                DnDList({
                    list: state.value,
                    onChange: (newCols) => state.onChange(newCols),
                    renderDrag: ({index}) => (
                        <div className="outbound-api-steps-9qw">
                            <div className="drag-wrapper" key={"column" + index}>
                                {rOutboundAPIStep({
                                    lastStep: index + 1 === (state.value || [])?.length,
                                    hasError,
                                    onStartDragging: () => {},
                                    dragging: true,
                                    index,
                                    field: {
                                        value: state.value[index],
                                        onChange: () => {},
                                    },
                                    // onDelete: () => state.change((old) => removeIndex(index, old))
                                })}
                            </div>
                        </div>
                    ),
                    next,
                }),
        ],
        ({dnd}) => {
            return (
                <div className="outbound-api-steps-9qw">
                    {dnd.list?.map(({index: ci, ref, start}) => {
                        if (ci != null) {
                            return (
                                <div className="drag-wrapper" key={"column" + ci} ref={ref}>
                                    {rOutboundAPIStep({
                                        lastStep: ci + 1 === (state.value || [])?.length,
                                        hasError,
                                        onStartDragging: start,
                                        index: ci,
                                        field: scope(state, [ci]),
                                        onDelete: () =>
                                            state.change((old) =>
                                                old.length === 1
                                                    ? [
                                                          {
                                                              ID: new Date().getTime(),
                                                          },
                                                      ]
                                                    : removeIndex(ci, old)
                                            ),
                                    })}
                                </div>
                            );
                        } else {
                            return <div className="column blank" key={-1} />;
                        }
                    })}

                    <Button
                        onClick={() =>
                            state.change((old) => [
                                ...(old ?? []),
                                {
                                    httpMethod: "Get",
                                    resultMapping: [{ID: new Date().getTime()}],
                                },
                            ])
                        }
                    >
                        Add Step
                    </Button>
                </div>
            );
        }
    );
};

const rOutboundAPIStep = ({field, onDelete, index, onStartDragging, dragging, hasError, lastStep}) =>
    cs(() =>
        ExpandableContent({
            initExpand: true,
            content: () => {
                return (
                    <div className="content">
                        <div className="counter-section">
                            <div className="counter">{index + 1}</div>

                            {onStartDragging && (
                                <div
                                    className={cx("dragging-anchor", {
                                        dragging,
                                    })}
                                    onMouseDown={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        onStartDragging(e);
                                    }}
                                >
                                    {MoveIconSvg({fill: "#546B81"})}
                                </div>
                            )}
                        </div>

                        {DropdownSelect({
                            className: "method",
                            list: ["Get", "Post", "Put", "Patch", "Delete", "Head", "Options"],
                            valueToLabel: (v) => v.toUpperCase(),
                            placeholder: "Method",
                            hasError: hasError && isBlank(field.value.httpMethod),
                            isSelected: (v) => field.value.httpMethod === v,
                            onChange: (v) =>
                                field.change((old) => ({
                                    ...old,
                                    httpMethod: v,
                                    ...(methodWithContent.includes(v)
                                        ? {
                                              content: {
                                                  $type: "DictionaryJsonHttpRequestContent",
                                              },
                                          }
                                        : {}),
                                })),
                        })}

                        {TextInput({
                            state: scope(field, ["uri"]),
                            placeholder: "Request URL",
                            className: "request-url",
                            hasError: hasError && isBlank(field.value.uri),
                        })}

                        <div className="delete-btn" onClick={onDelete}>
                            {TrashIconBold()}
                        </div>
                    </div>
                );
            },
            render: () => {
                return cs(
                    [
                        "tabs",
                        ({activeTab}, next) =>
                            next([
                                {
                                    label: "Headers",
                                    render: rOutboundAPIStepHeaders,
                                    numbers: (getPath(field.value, ["headers"]) || [])?.length,
                                },
                                ...(methodWithContent.includes(field.value.httpMethod)
                                    ? [
                                          {
                                              label: "Content",
                                              render: rOutboundAPIStepContent,
                                              numbers: dictionaryTypes.includes(getPath(field.value, ["content", "$type"]))
                                                  ? (getPath(field.value, ["content", "data"]) || [])?.length
                                                  : getPath(field.value, ["content", "data"])?.length
                                                  ? 1
                                                  : 0,
                                          },
                                      ]
                                    : []),
                                ...(!lastStep
                                    ? [
                                          {
                                              label: "Result Mapping",
                                              render: rOutboundAPIStepResultMapping,
                                              numbers: (getPath(field.value, ["resultMapping"]) || [])?.length,
                                          },
                                      ]
                                    : []),
                            ]),
                    ],
                    ["activeTab", ({tabs}, next) => UseState({initValue: tabs[0], next})],
                    ({tabs, activeTab}) => (
                        <>
                            <>
                                {TabsHeader({
                                    tabs: tabs.map((t) => ({
                                        label: (
                                            <span>
                                                {t.label} <span className="amount">{!!t.numbers ? `(${t.numbers})` : ``}</span>
                                            </span>
                                        ),
                                        active: activeTab.value.label === t.label,
                                        onClick: () => activeTab.onChange(t),
                                    })),
                                })}
                                <div className="tab-content">{activeTab.value.render?.({field})}</div>
                            </>
                        </>
                    )
                );
            },
        })
    );

const rOutboundAPIStepHeaders = ({field}) =>
    cs(() =>
        OutboundKeyValueInputs({
            state: scope(field, ["headers"]),
            labels: {
                key: "Key",
                value: "Value",
            },
            placeholders: {
                key: "Key",
                value: "Value",
            },
        })
    );

const rOutboundAPIStepContent = ({field}) =>
    cs(() => {
        const types = [
            {label: "Form", $type: "FormHttpRequestContent"},
            {
                label: "Form (URL Encoded)",
                $type: "FormUrlEncodedHttpRequestContent",
            },
            {label: "JSON (Raw)", $type: "JsonHttpRequestContent"},
            {label: "JSON (Flat)", $type: "DictionaryJsonHttpRequestContent"},
            {label: "XML (Raw)", $type: "XmlHttpRequestContent"},
        ];
        return (
            <div className="outbound-step-content">
                <div className="radio-group">
                    {types.map((type, i) =>
                        cs(keyed(i), () =>
                            RadioLine({
                                label: type.label,
                                selected: field.value?.content?.$type === type.$type,
                                onClick: () =>
                                    spc(field, ["content"], (c) => ({
                                        $type: type.$type,
                                    })),
                            })
                        )
                    )}
                </div>
                {/*TODO: Son Pham using keyValueInput (array transform)*/}
                {field.value?.content?.$type &&
                    (dictionaryTypes.indexOf(field.value?.content?.$type) > -1
                        ? OutboundKeyValueInputs({
                              state: scope(field, ["content", "data"]),
                              labels: {
                                  key: "Key",
                                  value: "Value",
                              },
                              placeholders: {
                                  key: "Key",
                                  value: "Value",
                              },
                          })
                        : TextInput({
                              state: scope(field, ["content", "data"]),
                              multiline: true,
                              rows: 15,
                          }))}

                {field.value?.content?.$type &&
                    dictionaryTypes.indexOf(field.value?.content?.$type) === -1 &&
                    TextInput({
                        state: scope(field, ["content", "contentType"]),
                        label: "Content Type",
                    })}
            </div>
        );
    });
