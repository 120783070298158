import {cs} from "@common/react/chain-services";
import {provideContext} from "@common/react/context";
import "../buttons/design-theme-buttons-auto.scss";

import {css} from "emotion";
import {UseState} from "@common/react/use-state";
import {LiveDashboard} from "@common/ui-components/live/live-dashboard/live-dashboard";
import {FilterLabel} from "@common/ui-components/live/live-dashboard/live-filters/layout/common/label/filter-label";
import {DateFilterPreview} from "@common/ui-components/live/live-dashboard/live-filters/filter-types/date/date-filter-preview";
import {FreeFormTextPreview} from "@common/ui-components/live/live-dashboard/live-filters/filter-types/text/free-form-text/free-form-text-preview";
import {SingleSelectDropdown} from "@common/ui-components/live/live-dashboard/live-filters/filter-types/text/selectable-list/single-select-dropdown/single-select-dropdown";

import data from "./example-tile-data.json";
import collection from "./example-tile-collection.json";

export const initDateFilter = {
    main: {
        from: {year: 2022, month: 4, day: 8, hours: 0, minutes: 0, seconds: 0},
        to: {year: 2022, month: 4, day: 15, hours: 23, minutes: 59, seconds: 0},
    },
    columns: [
        {
            modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
            modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
            modelColumnID: "5035cb8a-55ef-45e5-9772-4a2a019d89b4",
            displayName: null,
            default: true,
        },
    ],
    comparing: {
        to: {year: 2022, month: 4, day: 8, hours: 0, minutes: 0, seconds: 0},
        from: {year: 2022, month: 4, day: 1, hours: 23, minutes: 59, seconds: 59},
    },
    enableComparing: true,
};
export const initFreeFormTextFilter = {
    freeFormValue: null,
    operator: "Contains",
    columns: [
        {
            modelID: "2863ada0-8e18-4384-a291-c19a7f417b62",
            modelTableID: "3976bbea-7989-45fa-b28e-09fd3cd70052",
            modelColumnID: "710505f7-a43f-4efc-85bd-742163f931c1",
            displayName: null,
            default: true,
        },
    ],
};

export const DesignThemeComponentsAuto = ({theme, forceAutoRefresh}) =>
    cs(
        (_, next) => provideContext("theme", theme, next),
        ["dropdownState", (_, next) => UseState({next, initValue: []})],
        ["dateFilterState", (_, next) => UseState({next, initValue: initDateFilter})],
        ["freeFormFilterState", (_, next) => UseState({next, initValue: initFreeFormTextFilter})],

        ({dateFilterState, dropdownState, freeFormFilterState}) => {
            return (
                <>
                    <div
                        className="design-theme-buttons-auto"
                        style={{
                            background: theme.general.canvas.backgroundColorRGB,
                            height: "auto",
                        }}
                    >
                        <div className="text-description">Filters</div>

                        <div className="filter-buttons-wrapper">
                            <div className="col">
                                {FilterLabel({text: "Date Place (Order)"})}

                                {DateFilterPreview({state: dateFilterState})}

                                <div className="text-label">Date Filter</div>
                            </div>

                            <div className="col">
                                {FilterLabel({text: "Email (Account)"})}

                                <div className="filter-line">
                                    {FreeFormTextPreview({
                                        state: freeFormFilterState,
                                    })}
                                </div>

                                <div className="text-label">Text Filter Comparison Options</div>
                            </div>

                            <div className="col">
                                {FilterLabel({text: "Email (Account)"})}

                                <div className="filter-line single-select">
                                    {SingleSelectDropdown({
                                        state: dropdownState,
                                        data: ["Item #1", "Item #2", "Item #3", "Item #4"],
                                    })}
                                </div>

                                <div className="text-label">Text filter with dropdown</div>
                            </div>

                            <div className="col">
                                {FilterLabel({text: "Total Price (Order)"})}

                                <div className="filter-line">
                                    {FreeFormTextPreview({
                                        state: freeFormFilterState,
                                    })}
                                </div>

                                <div className="text-label">Number Filter Comparison Options</div>
                            </div>
                        </div>

                        <div
                            className="buttons-wrapper"
                            style={{
                                paddingBottom: 0,
                            }}
                        >
                            <div
                                className="text-description"
                                style={{
                                    marginBottom: 0,
                                }}
                            >
                                Tiles
                            </div>
                        </div>
                    </div>

                    {LiveDashboard({
                        collection,
                        defaultData: data,
                        theme,
                        disabledTileActions: true,
                        forceAutoRefresh,
                    })}
                </>
            );
        }
    );
