import {cs} from "../../../../react/chain-services";
import {fragments} from "../../../../react/fragments";
import {UseState} from "../../../../react/use-state";
import {Invoke} from "../../../../react/invoke";
import {Interval} from "../../../../react/interval";
import {toRange} from "../../../../utils/ranges";
import {Static2} from "../../../../react/static-2";
import {OnUnmounted} from "../../../../react/on-unmounted";

export const WatchScroll = ({disabled, action, next}) =>
    cs(["state", (_, next) => UseState({next})], ({state}) =>
        fragments(
            next({
                ref: !state.value ? (ref) => ref && state.onChange({ref}) : null,
            }),
            state.value &&
                !disabled &&
                fragments(
                    cs(["listener", (_, next) => Static2({next})], ({listener}) => {
                        const dom = state.value.ref;
                        return fragments(
                            Invoke({
                                props: {disabled},
                                fn: ({getLatestProps}) => {
                                    listener.set(() => {
                                        const {disabled} = getLatestProps();
                                        if (disabled) {
                                            return;
                                        }
                                        action(getEvent(dom));
                                    });

                                    dom?.onScrollListener?.(() => listener.get());
                                },
                            })
                        );
                    }),

                    Interval({
                        fn: () => {
                            const dom = state.value.ref;
                            action(getEvent(dom));
                        },
                        delay: 500,
                    })
                )
        )
    );

const getEvent = (dom) => {
    const {scrollTop, offsetHeight, scrollHeight} = dom.getDom?.() ?? dom;

    return {
        range: toRange(scrollTop, offsetHeight),
        total: scrollHeight,
    };
};
