const cGetFieldInfoWrtTileFields =
    ({tileFields, getFieldInfo}) =>
    (field) => {
        // Wrt means "with respect to"
        if (!field) {
            return;
        }

        const rspTileField = [...tileFields.fieldsForSort, ...tileFields.fieldsForLimit].find(
            (tf) => tf.modelID === field.modelID && tf.modelTableID === field.modelTableID && tf.modelColumnID === field.modelColumnID
        );
        const fieldInfo = getFieldInfo(field);
        return {
            ...fieldInfo,
            type: rspTileField?.aggregationFunc?.startsWith("Count") ? "number" : fieldInfo.type,
        };
    };
exports.cGetFieldInfoWrtTileFields = cGetFieldInfoWrtTileFields;
