import {cs} from "@common/react/chain-services";
import {consumeContext, provideContext} from "@common/react/context";
import {ErrorDialog} from "./error-dialog/error-dialog";
import {ConfirmDialog} from "../../../routes/common/confirm-dialog/confirm-dialog";
import {AlertIcon} from "@common/ui-components/icons/global-icons";
import * as React from "react";
import {Button} from "@common/form/buttons/button/button";

export const loadTileDataApiErrorHandler = ({next}) =>
    cs(
        [
            "learnMore",
            (_, next) =>
                cs(
                    consumeContext("routing"),
                    consumeContext("collection"),
                    [
                        "errorDialog",
                        (_, next) =>
                            ErrorDialog({
                                next,
                            }),
                    ],
                    [
                        "confirmDialog",
                        (_, next) =>
                            ConfirmDialog({
                                next,
                                submitText: "Go To Model",
                                cancelText: "Learn More",
                                title: "Conflicting Relationship Paths",
                            }),
                    ],
                    ({errorDialog, confirmDialog, collection, routing}) =>
                        next({
                            condition: (error) => [471, 472, 474, 475, 476].includes(error.statusCode),
                            onClick: (error, tile) => {
                                if (error.statusCode === 472) {
                                    const [table1, table2] = error.data.Tables.$values[0].$values.map((t) => t);

                                    confirmDialog.show({
                                        description: (
                                            <div style={{fontSize: "14px"}}>
                                                There are {error.data.Relationships.$values.length} shortest possible paths between the{" "}
                                                <b>
                                                    <u>{table1.name}</u>
                                                </b>{" "}
                                                and{" "}
                                                <b>
                                                    <u>{table2.name}</u>
                                                </b>
                                                .
                                                <br />
                                                <br />
                                                To support this tile there can only be 1 shortest path. Please update the model by removing
                                                a relationship or creating a new data view with the required data.
                                            </div>
                                        ),
                                        confirmClick: () => {
                                            routing.goto("edit-model", {
                                                modelId: collection.value.modelID,
                                                tableID: table1.id,
                                                targetTableID: table2.id,
                                            });
                                        },
                                    });

                                    return;
                                }

                                if (error.statusCode == 476) {
                                    errorDialog.show({
                                        title: "Invalid Relationship Path",
                                        content: (
                                            <div className="invalid-relationships">
                                                The relationship path required for this tile includes outer joins between inner joins making
                                                it invalid. Please review and update the model relationships.
                                            </div>
                                        ),
                                        renderButtons: (modal) => (
                                            <div className="buttons">
                                                <Button
                                                    btnType="primary"
                                                    onClick={() => {
                                                        routing.goto("edit-model", {
                                                            modelId: collection.value.modelID,
                                                            invalidRelationships: JSON.stringify({
                                                                relationships: error.data.Invalid.$values.map((v) => v.id),
                                                                tile,
                                                            }),
                                                        });
                                                        modal.resolve();
                                                    }}
                                                >
                                                    Fix
                                                </Button>
                                            </div>
                                        ),
                                    });

                                    return;
                                }

                                errorDialog.show({
                                    title:
                                        error.statusCode == 475
                                            ? "Invalid Column Config"
                                            : error.statusCode == 474
                                            ? "Table too large to render"
                                            : "Unrelated Tables",
                                    content: error.message,
                                });
                            },
                        })
                ),
        ],

        ({learnMore}) => provideContext("tileDataApiErrorHandler", learnMore, next)
    );
