import {sort, sum} from "../../../utils/collections";
import {chain} from "@common/utils/fs";

export const decorateFunnelData = ({tile, series, theme}) => {
    const sortedMainData = chain(
        series.find((s) => !s.isCompare),
        (mainSeries) => !tile.customSortValues?.length
            ? sort(mainSeries.data, (d) => d[1], (v1,v2) => v1 < v2 ? 1 : -1) // data should be sorted in descending order
            : mainSeries.data, // preserve order from back-end
    );


    const getDecoratedData = (sortedData) => {
        const total = sum(sortedData, (d) => d[1]);

        // using array and keys does not work anymore. use object like below instead.
        // data: sortedData.map((d, index) => ([
        //     d[0] || "null", // section name, replace with "null" if null since highcharts will replace it with "Slice" otherwise
        //     1, // same y for all sections to create sections with same height
        //     d[1], // real value of section,
        //     index === 0 ? null : (d[1] / sortedData[index - 1][1]) * 100, // percent of previous
        //     (d[1] / total) * 100, // percent of total
        // ])),
        // keys: ["name", "y", "value", "percentOfPrevious", "percentOfTotal"],

        return sortedData.map((d, index) => ({
            name: d[0] || "null", // section name, replace with "null" if null since highcharts will replace it with "Slice" otherwise
            y: 1, // same y for all sections to create sections with same height
            value: d[1], // real value of section,
            percentOfPrevious: index === 0 ? null : (d[1] / sortedData[index - 1][1]) * 100, // percent of previous
            percentOfTotal: (d[1] / total) * 100, // percent of total
        }))
    }

    return series.map((s) => {
        const sortedData = (() => {
            if (!s.isCompare) {
                return sortedMainData;
            }
            const order = sortedMainData.map((d) => d[0]);
            return sort(
                s.data, (d) => d[0],
                // sort compare data based on main data order
                (v1, v2) => order.indexOf(v1) > order.indexOf(v2) ? 1 : -1
            )
        })()

        return {
            ...s,
            data: getDecoratedData(sortedData),
        }
    }).filter(v => v);
};
