import {generateColorScale} from "@common/utils/color-util";
import {maxBy} from "@common/utils/math-util";

export const getColorScale = ({theme, rules}) => {
    if (!rules || rules.length == 0) {
        return () => ({});
    }
    const {fontColorsRGB, singleColorsRGB, colorScaleSchemes} = theme?.dataVisualization.tables || {};

    const maps = rules.reduce((_maps, rule, currentIndex) => {
        const func = (() => {
            if (rule.colorType == "SingleColor") {
                return () => ({
                    background: rule.backgroundColor ? singleColorsRGB[rule.backgroundColor.index] : "",
                    color: rule.fontColor ? fontColorsRGB[rule.fontColor.index] : "",
                });
            }
            const colors = generateColorScale(colorScaleSchemes[rule.backgroundColorScale?.index]?.colorsRGB || [], true, 0, 100);

            return ({column}) => {
                const scale = column.passedConditionIndices.find((r) => r.index == currentIndex)?.scale;

                return {
                    background: colors.getColor(scale),
                    color: rule.fontColor ? fontColorsRGB[rule.fontColor.index] : "",
                };
            };
        })();
        return {
            ..._maps,
            [currentIndex]: func,
        };
    }, {});

    return ({column}) => {
        const passedIndex = maxBy(column.passedConditionIndices, (r) => r.index);
        return maps[passedIndex]?.({column}) ?? {};
    };
};
