import {Button} from "../../../../../../../common/form/buttons/button/button";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {UseState} from "@common/react/use-state";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";
import {DialogService} from "../../../../common/dialog/dialog-service";
import "./ds-clear-error-dialog.scss";

export const DsClearErrorDialog = ({next: rootNext}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    strong: true,
                    registryRender: true,
                    render: ({resolve, reject, args: {goSyncSettings, dsID, errorTablesIDsSelected}}) => ({
                        title: "Are you sure?",
                        content: next({
                            dsID,
                            errorTablesIDsSelected,
                            goSyncSettings,
                            resolve,
                            reject,
                        }),
                    }),
                    next: rootNext,
                }),
        ],
        consumeContext("apis"),
        ["loading", (_, next) => UseState({next})],
        [
            "onClearErrors",
            ({apis, modal, loading}, next) =>
                next(async () => {
                    const delay = 10 * 1000; //delay time to fetch latest data
                    try {
                        loading.onChange(true);
                        await apis.data.clearErrorTables(modal.dsID, modal.errorTablesIDsSelected);
                        setTimeout(() => modal.resolve(true), delay);
                    } catch (e) {
                    } finally {
                        setTimeout(() => loading.onChange(false), delay);
                    }
                }),
        ],
        ({modal, onClearErrors, loading}) => {
            return (
                <div className="ds-clear-error-dialog-99i">
                    <VerbDialogBodyScrollbar>
                        Clearing the error will only resolve the problem if you’ve fixed the issue in your database and just need to
                        reenable syncing. Otherwise, click Sync Settings for guidance.
                    </VerbDialogBodyScrollbar>
                    <div className="buttons">
                        <Button btnType="secondary" disabled={loading.value} onClick={() => modal.resolve(null)}>
                            Cancel
                        </Button>{" "}
                        &nbsp;
                        {modal.goSyncSettings && (
                            <>
                                <Button
                                    btnType="secondary"
                                    disabled={loading.value}
                                    onClick={() => {
                                        modal.goSyncSettings();
                                        modal.resolve(null);
                                    }}
                                >
                                    Sync Settings
                                </Button>{" "}
                                &nbsp;
                            </>
                        )}
                        <Button
                            onClick={() => onClearErrors()}
                            iconRight={loading.value && <i className="fad fa-spinner-third fa-spin" />}
                            disabled={loading.value}
                        >
                            Clear Error
                        </Button>
                    </div>
                </div>
            );
        }
    );
