import {cs} from "../../react/chain-services";
import React from "react";
import "./number-input.scss";
import {BasicInput} from "../common/basic-input";

import {UseState} from "../../react/use-state";
import {chain} from "../../utils/fs";
import {Watch} from "../../react/watch";
import {fragments} from "../../react/fragments";
import fromExponential from "from-exponential";

import {isNil} from "../../utils/common";

const MATCHED_INPUT_PARTTERN = /^-?(\d+)?(\.)?(\d+)?$/g;

// Control Decimal/ Non Decimal and Negative/ Non negative by provide new pattern

const blockedInput = (parttern) => (v) => {
    if(v && !v.match?.(parttern)) {
        throw new Error();
    }
    return v;
};
const normalizedValue = (v) => (!isNil(v) && v !== "" ? fromExponential(v)?.trim() : "");

const fromStringToValue = () => (v) => ["-", ".", "-."].includes(v) ? 0 : +v;

const withMaxMinToValue =
    ({maxValue, minValue}) =>
        (v) =>
            chain(
                v,
                (_) => Math.max(v, minValue),
                (_) => Math.min(v, maxValue)
            );

const fromEmptyStringToValue =
    ({emptyValue}) =>
        (v) =>
            v === "" ? emptyValue : v;

export const NumberInputState = ({
    rootState,
    forceToEmptyValue = true,
    emptyValue = 0,
    minValue = -Infinity,
    maxValue = Infinity,
    next,
}) =>
    cs(
        [
            "utils",
            (_, next) =>
                next({
                    getSubmittedValue: withMaxMinToValue({maxValue, minValue}),
                    getDisplayString: (v) => {
                        const realValue = fromStringToValue()(v);
                        if(realValue < minValue) {
                            return minValue;
                        }
                        if(realValue > maxValue) {
                            return maxValue;
                        }
                        return v;
                    },
                    getValueFromString: fromStringToValue(),
                    normalizedValue,
                    getValueFromEmptyString: fromEmptyStringToValue({
                        emptyValue,
                    }),
                    removeE: (v) => (v.toLowerCase().includes("e") ? v.replace("e", "") : v),
                    filterInput: blockedInput(MATCHED_INPUT_PARTTERN),
                }),
        ],
        [
            "displayed",
            ({utils}, next) => {

                return UseState({
                    next,
                    getInitValue: () => {
                        return utils.normalizedValue(rootState?.value)
                    },
                })
            },
        ],
        ({displayed, utils}) => {
            return fragments(
                Watch({
                    value: rootState.value,
                    onChanged: (changed) => {

                        if(
                            displayed.value
                                ? changed !== utils.getValueFromString(displayed.value)
                                : changed !== utils.getValueFromEmptyString(displayed.value)
                        ) {
                            displayed.onChange(utils.normalizedValue(changed));
                        }
                    },
                }),
                next({
                    displayedValue: displayed.value,
                    value: displayed.value,
                    onChange: (e) => {
                        const newValue = e.target.value;
                        if(!forceToEmptyValue && (!newValue || newValue == "")) {
                            displayed.onChange(e.target.value);
                            return;
                        }

                        try {
                            chain(newValue, utils.removeE, utils.normalizedValue, utils.filterInput, (_) => {
                                if(!_) {
                                    displayed.onChange(_);
                                    rootState?.onChange?.(utils.getValueFromEmptyString(_));
                                } else {
                                    const actual = utils.getValueFromString(_);
                                    if(!isNaN(actual)) {
                                        rootState?.onChange?.(utils.getSubmittedValue(actual));
                                        displayed.onChange(utils.getDisplayString(_));
                                    }
                                }
                            });
                        } catch(err) {
                            return;
                        }
                    },
                })
            );
        }
    );

// TODO material.io input anatomy: error msg, require
export const NumberInput = ({
    state,
    disabled,
    onBlur,
    onKeyDown,
    showZero,
    minValue = -Infinity,
    maxValue = +Infinity,
    emptyValue,
    className,
    watchPropsState = false,
    forceToEmptyValue = true,
    ...props
}) => {
    return cs(
        [
            "inputState",
            (_, next) =>
                NumberInputState({
                    next,
                    showZero,
                    rootState: state,
                    emptyValue,
                    minValue,
                    maxValue,
                    forceToEmptyValue,
                }),
        ],
        ({inputState}) =>
            BasicInput({
                ...props,
                hasError:
                    props.hasError ||
                    (Number(inputState.displayedValue) ? Number(inputState.displayedValue) < minValue : false),
                className: `number-input number-input-8tr ${className}`,
                disabled,
                renderInput: () => (
                    <input
                        {...{
                            value: inputState.value,
                            onChange: inputState.onChange,
                            disabled,
                            onBlur: () => {
                                onBlur?.();
                            },
                            onKeyDown,
                        }}
                    />
                ),
                hasValue: inputState.displayedValue != null,
            })
    );
};
