import React from "react";

export const UnpublishedIcon = ({fillColor} = {}) => {
    const fill = fillColor || "#546b81";
    return (
        <div className="unpublished-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                <g transform="translate(-241.485 -121.559)">
                    <circle className="a" fill="none" cx="6" cy="6" r="6" transform="translate(241.485 121.559)" />
                    <path
                        className="b"
                        fill={fill}
                        d="M247.485,133.559a6,6,0,1,1,6-6A6.007,6.007,0,0,1,247.485,133.559Zm0-10.8a4.8,4.8,0,1,0,4.8,4.8A4.806,4.806,0,0,0,247.485,122.759Z"
                    />
                    <path className="b" fill={fill} d="M244.985,126.615a1,1,0,1,0,1,1A1,1,0,0,0,244.985,126.615Z" />
                    <path className="b" fill={fill} d="M247.485,126.615a1,1,0,1,0,1,1A1,1,0,0,0,247.485,126.615Z" />
                    <path className="b" fill={fill} d="M249.985,126.615a1,1,0,1,0,1,1A1,1,0,0,0,249.985,126.615Z" />
                </g>
            </svg>
        </div>
    );
};
