import * as React from "react";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {DialogService} from "../../../../../common/dialog/dialog-service";
import "./create-folder-dialog.scss";
import {CreateFolderService} from "../create-folder-service/create-folder-service";
import {Button} from "../../../../../../../../common/form/buttons/button/button";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";

export const CreateFolderDialog = ({folders, onCreate, apiType, customUpsertApi, customCheckFolderApi, next: rootNext}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    render: ({resolve}) => ({
                        title: "Create Folder",
                        width: 540,
                        content: next({
                            resolve,
                        }),
                    }),
                    next: rootNext,
                }),
        ],

        [
            "createFolderService",
            (_, next) =>
                CreateFolderService({
                    apiType,
                    folders,
                    next,
                    customUpsertApi,
                    customCheckFolderApi,
                }),
        ],

        ["submitting", (_, next) => UseState({next})],

        ({modal, createFolderService, submitting}) => (
            <div className="create-folder-dialog-3mv">
                <VerbDialogBodyScrollbar>
                    <div className="main-part">{createFolderService.render()}</div>
                </VerbDialogBodyScrollbar>

                <div className="buttons">
                    <Button btnType="secondary" onClick={() => modal.resolve()}>
                        Cancel
                    </Button>
                    <Button
                        disabled={submitting.value || !createFolderService.validName}
                        onClick={async () => {
                            submitting.onChange(true);
                            try {
                                const {folder} = await createFolderService.create();
                                if (folder) {
                                    onCreate(folder);
                                    modal.resolve();
                                }
                            } catch (e) {}
                            submitting.onChange(false);
                        }}
                    >
                        Create
                    </Button>
                </div>
            </div>
        )
    );
