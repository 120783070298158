import "../relationships/error-badge.scss";

import React, {createElement as h} from "react";
import ReactDOM from "react-dom";

import {AlertIcon} from "@common/ui-components/icons/global-icons";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {Static2} from "@common/react/static-2";
import {Button} from "@common/form/buttons/button/button";
import {UseState} from "@common/react/use-state";
import {OnMounted} from "@common/react/on-mounted";
import {AnyAction} from "@common/react/any-action";

export const ModelErrorBox = ({position, modelErrorBoxRef, errors}) =>
    cs(
        consumeContext("fixIssuesDialog"),
        consumeContext("modelErrorBoxSelectedRef"),
        ["ref", (_, next) => Static2({next})],
        ["open", (_, next) => UseState({next})],
        ["boxHeight", (_, next) => UseState({next})],
        ({boxHeight, ref}, next) =>
            OnMounted({
                next,
                action: () => {
                    setTimeout(() => {
                        boxHeight.onChange(ref.get().offsetHeight);
                    }, 500);
                },
            }),
        ({ref, open, boxHeight, fixIssuesDialog, modelErrorBoxSelectedRef}) => {
            return ReactDOM.createPortal(
                <foreignObject
                    style={{outline: "none"}}
                    x={position.x - (open.value ? 150 : 26)}
                    y={position.y - 12}
                    width={open.value ? 300 : 52}
                    height={24 + (open.value ? boxHeight.value + 10 : 0)}
                >
                    <div className="model-error-box" onClick={() => open.onChange(true)}>
                        {(() =>
                            cs(
                                (_, next) =>
                                    h(AnyAction, {
                                        disabled: !open.value,
                                        fn: () => {
                                            setTimeout(() => {
                                                open.onChange(false);
                                            }, 100);
                                        },
                                        next,
                                    }),
                                () => (
                                    <div className="error-badge-a33">
                                        <AlertIcon />
                                        &nbsp;{errors.length}
                                    </div>
                                )
                            ))()}

                        <div
                            ref={ref.set}
                            style={{
                                opacity: open.value ? "1" : 0,
                                pointerEvents: open.value ? "" : "none",
                            }}
                            className="error-badge-box"
                        >
                            {errors.map((error, index) => (
                                <div className="error-row" key={index}>
                                    <AlertIcon className="icon" />

                                    <div className="error-label">{error.label}</div>

                                    <Button
                                        className="fix-btn"
                                        btnType="border"
                                        size="small"
                                        onClick={() => {
                                            fixIssuesDialog.show({
                                                tableID: error.tableID,
                                                columnID: error.columnID,
                                                brokenOnTable: error.brokenOnTable,
                                            });
                                        }}
                                    >
                                        Fix
                                    </Button>
                                </div>
                            ))}
                        </div>
                    </div>
                </foreignObject>,
                open.value ? modelErrorBoxSelectedRef.get() : modelErrorBoxRef.get()
            );
        }
    );
