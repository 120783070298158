import * as React from "react";
import {cs} from "@common/react/chain-services";
import "./manage-relationships.scss";
import {LeftPanelClosable} from "../../../../../common/left-panel-closable/left-panel-closable";
import {Expandable} from "../../../../../common/expandable/expandable";
import {CreateRelationship} from "./create-relationship/create-relationship";
import {ExistingRelationship} from "./existing-relationships/existing-relationships";
import {SuggestedRelationships} from "./suggested-relationships/suggested-relationships";

export const ManageRelationships = ({dataSources, tableId, model, onCancel}) => {
    const table = model.value.tables.find((t) => t.id === tableId);
    return cs(
        ({}, next) => (
            <LeftPanelClosable
                {...{
                    onClose: onCancel,
                    className: "manage-relationships-left-panel-8fs",
                    label: `Manage Relationships to ${table?.visualName}`,
                    // enableTruncatingTooltip: true,
                    content: next(),
                }}
            />
        ),
        ({}) =>
            dataSources && (
                <div className="manage-relationships-09f">
                    <Expandable
                        {...{
                            level: 1,
                            initExpand: false,
                            noTopBorder: true,
                            label: "Suggested Relationships",
                            render: () =>
                                SuggestedRelationships({
                                    model,
                                    table,
                                    dataSources,
                                }),
                        }}
                    />
                    <Expandable
                        {...{
                            level: 1,
                            initExpand: false,
                            noTopBorder: true,
                            label: "Existing Relationships",
                            render: () =>
                                ExistingRelationship({
                                    model,
                                    table,
                                    dataSources,
                                }),
                        }}
                    />
                    <Expandable
                        {...{
                            level: 1,
                            initExpand: false,
                            noTopBorder: true,
                            label: "Create Relationship",
                            render: () => CreateRelationship({dataSources, table, model}),
                        }}
                    />
                </div>
            )
    );
};
