import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {Load2} from "@common/react/load2";
import {DsSyncSettingsLayout} from "../sync-settings/layout/ds-sync-settings-layout";
import {DsErrorsSummary} from "./ds-errors-summary";

export const EditDataSourceErrors = () =>
    cs(
        consumeContext("apis"),
        consumeContext("routing"),
        [
            "dataSource",
            ({apis, routing}, next) =>
                Load2({
                    _key: routing.params.dsId,
                    fetch: () => apis.data.getDataSource(routing.params.dsId),
                    next,
                }),
        ],
        ({dataSource, routing}) =>
            DsSyncSettingsLayout({
                rightButtons: [
                    {
                        label: "Done",
                        onClick: () =>
                            routing.goto("edit-data-source", {
                                dsId: routing.params.dsId,
                                envId: routing.params.envId,
                            }),
                    },
                ],
                mode: "Errors",
                editing: {
                    label: dataSource.value?.name,
                },
                content: DsErrorsSummary({dataSource}),
            })
    );
