import {createFetcher} from "../../../../tools/fetcher/fetcher";

export const createDataAccessIframeApis = () => {
    const fetcher = createFetcher({});

    return {
        validateToken: (token) => fetcher.put(`/api/user/tenant/data-access-iframe-key/validate-token`, token),
        renderDocs: (apiKey) =>
            createFetcher({
                headers: {
                    "x-api-key": apiKey,
                },
            }).post(`/api/render/iframe`),
    };
};
