import {DatePicker} from "@common/form/date-picker/date-picker";
import {BasicInput} from "../../../../../../../../../../../../../../../../common/form/common/basic-input";
import {NumberInput} from "@common/form/number-input/number-input";
import {TextInput} from "@common/form/text-input/text-input";

export const StaticValueInput = ({value, onChange, dataType, className}) => {
    if (["DateTime", "DateTimeOffset"].indexOf(dataType) > -1) {
        return <DatePicker label="Static Value" state={{value, onChange}} className={className} />;
    }

    if (["Double", "Int"].indexOf(dataType) > -1) {
        return <NumberInput label="Static Value" state={{value, onChange}} className={className} />;
    }

    return <TextInput label="Static Value" state={{value, onChange}} className={className} />;
};
