import * as React from "react";
import {cs} from "@common/react/chain-services";
import {scope} from "@common/react/scope";
import "./request-parameter-values.scss";
import {DataTable} from "../../../../../common/data-table/data-table";
import {TextInput} from "../../../../../../../../common/form/text-input/text-input";
import {Button} from "../../../../../../../../common/form/buttons/button/button";

export const RequestParameterValues = ({parameters, onRunTest}) =>
    cs(({}) => (
        <div className="request-param-values-3wl">
            {DataTable({
                className: "req-param-values-table",
                list: Object.keys(parameters.value),
                columns: [
                    {
                        label: "Parameter",
                        format: (e) => <div className="param">{`{{` + e + `}}`}</div>,
                    },
                    {
                        label: "Values",
                        format: (e) =>
                            TextInput({
                                state: scope(parameters, [e]),
                                placeholder: `enter ${e} value`,
                            }),
                    },
                ],
            })}
            <div className="run-test-btn">
                <Button onClick={onRunTest} iconLeft={<img src={require("./white-play-icon.svg")} alt="" />}>
                    Run Test
                </Button>
            </div>
        </div>
    ));
