import {getErrorInRelationships} from "./relationships-common";
import {ModelPanelHelper} from "../../../common/model-panel-helper";
import {ModelErrorBox} from "../common/model-error-box";

export const RelationshipErrors = ({tables, relationship, modelErrorBoxRef, centerPos}) => {
    const errors = getErrorInRelationships({tables, relationships: relationship.relationships});

    if (errors.length == 0) return null;

    return ModelErrorBox({
        key: relationship.id,
        position: centerPos,
        modelErrorBoxRef,
        errors: errors,
    });
};
