import {tooltipLayout} from "./tooltip-layout";
import {getTooltipFontSizes} from "./get-tooltip-font-sizes";
import {getIndicatorShapes} from "./indicator-shapes";
import {getDifference} from "./get-difference";

export const renderMapTileTooltip = ({points, tile, theme, formatters, isCompare}) => {
    const point = points[0].points?.[0] || points[0];

    if (isCompare) {
        return tooltipLayout({
            content: `<div class="table">
                    ${getMapPointTableCompare({
                        tile,
                        point,
                        formatters,
                        theme,
                    })}
                </div>`,
            theme,
            isCompare,
            noAnchor: true,
        });
    }

    return tooltipLayout({
        content: `<div class="non-compare-header">
                <div class="title">${getTitle({
                    point,
                    tile,
                    formatters,
                })}</div>
            </div>
            <div class="table">
                ${getMapPointTable({tile, point, formatters, theme})}
            </div>`,
        theme,
        noAnchor: true,
    });
};

const getMapPointTable = ({tile, point, formatters, theme}) => {
    const formatter = formatters.measurementFormatters[point.series.userOptions.customStack];
    const fontSizes = getTooltipFontSizes(theme);
    const indicatorShapes = getIndicatorShapes(theme);

    const rPoint = (point) => {
        // point can be a point or a group point in valueGroups
        return `<div class="point table-row" style="font-size: ${fontSizes.medium}px">
                <div class="name">
                    <span class="indicator" style="color: ${point.color}">${indicatorShapes["bar"]}</span>
                    ${getDisplayName({point, formatters})}
                </div>
                <div class="main-value">
                    ${formatter(point.point && point.point.options.value != null ? point.point.options.value : point.value)}
                </div>
            </div>`;
    };

    if (tile.groupField == null) {
        return `${rPoint(point)}`;
    }

    const groupPoints = point.point.options.valueGroups.map((p) => rPoint(p));
    return `
        ${groupPoints.join("")}
        <div class="total-row table-row" style="font-size: ${fontSizes.small}px">
            <div></div>
            <div class="main-total">${formatter(point.point?.options.value)}</div>
        </div>
    `;
};

const getMapPointTableCompare = ({tile, point, formatters, theme}) => {
    const fontSizes = getTooltipFontSizes(theme);
    const dateRangeFormatter = (range) => formatters.dateFormatter(range?.dateStart) + " - " + "</br>" + formatters.dateFormatter(range?.dateEnd);
    const formatter = formatters.measurementFormatters[point.series.userOptions.customStack];

    const {value, previousValue, change} = point.point?.options || {};

    const rHeader = () => {
        const {range, previousRange, name} = point.series.userOptions;

        const seriesTitle = (() => {
            const {diff, diffIcon, percentDiff, stateColor} = getDifference({
                value,
                compareValue: previousValue,
                theme,
            });

            return `
                <div class="title">
                    ${getTitle({point, tile, formatters})}
                </div>
                <div class="difference" style="color: ${stateColor}">
                    ${diffIcon}
                    ${formatters.percentFormatter(percentDiff)}
                </div>
            `;
        })();

        return `<div class="header table-row" style="font-size: ${fontSizes.small}px">
                <div class="series-title">${seriesTitle}</div>
                    <div class="main-title">
                        ${range && dateRangeFormatter(range)}
                    </div>
                    <div class="compare-title">
                        ${previousRange && dateRangeFormatter(previousRange)}
                    </div>
            </div>`;
    };

    const rPoint = (point) => {
        // point can be a point or a group point in valueGroups
        const indicatorShapes = getIndicatorShapes(theme);
        const {value, previousValue} = point.point?.options || point;

        const diffPercent = (() => {
            const {percentDiff, diffIcon, stateColor} = getDifference({
                value,
                compareValue: previousValue,
                theme,
            });
            return `<div class="difference" style="color: ${stateColor}">
                    ${diffIcon}
                    ${formatters.percentFormatter(percentDiff)}
                </div>`;
        })();

        return `<div class="point table-row first-point" style="font-size: ${fontSizes.medium}px">
                <div class="name">
                    <span class="indicator" style="color: ${point.color}">${indicatorShapes["bar"]}</span>
                    ${getDisplayName({point, formatters})}
                    ${diffPercent}
                </div>
                <div class="main-value">
                    ${formatter(value)}
                </div>
                <div class="compare-value">
                    ${previousValue != null ? formatter(previousValue) : "N/A"}
                </div>
            </div>`;
    };

    if (tile.groupField == null) {
        return `
            ${rHeader()}
            ${rPoint(point)}
        `;
    }

    const groupPoints = point.point.options.valueGroups.map((p) => rPoint(p));
    return `
        ${rHeader()}
        ${groupPoints.join("")}
        <div class="total-row table-row" style="font-size: ${fontSizes.small}px">
            <div></div>
            <div class="main-total">${formatter(point.point?.options.value)}</div>
            <div class="compare-total">${formatter(point.point?.options.previousValue)}</div>
        </div>
    `;
};

const getTitle = ({point, tile, formatters}) => point.series.userOptions.name + (tile.groupField != null ? ` - ${getDisplayName({point, formatters})}` : "");

// before: point.key || point.point?.postalCode || point.group
// point can be a point or a group point in valueGroups
const getDisplayName = ({point, formatters}) => {
    if (point.group) {
        return point.group;
    }
    return point.point?.properties?.postalCode?.code || (point.point?.properties.county || point.point?.properties.province || point.point?.properties.country)?.name;
};
