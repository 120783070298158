import React from "react";
import {cs} from "@common/react/chain-services";
import {DialogService} from "../../../../common/dialog/dialog-service";
import {consumeContext} from "@common/react/context";
import "./error-google-api-auth-dialog.scss";

export const ErrorGoogleApiAuthDialog = ({next: rootNext}) =>
    cs(
        consumeContext("apis"),
        [
            "modal",
            ({}, next) =>
                DialogService({
                    render: ({resolve, reject, args: {content}}) => ({
                        title: "Unable to connect your Google Account!",
                        content: next({reject, resolve, content}),
                    }),
                    next: rootNext,
                }),
        ],
        ({modal}) => (
            <div className="error-google-api-auth-dialog-9ew">
                <div className="message">
                    <i className="fa fa-exclamation" />
                    <div className="text">Unable to connect your Google Account!</div>
                </div>
            </div>
        )
    );
