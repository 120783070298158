import React from "react";
import {spc} from "@common/react/state-path-change";
import {FieldSection} from "../../fields-tab/chart-types/common/field-section/field-section";
import {FieldInfoProvider} from "../chart-types/common/field-info-provider/field-info-provider";
import {FieldItem} from "../../../../../../edit/common/field-item/field-item";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";

export const MapLocationColorsConfig = ({tile, theme}) => {
    const hasGroupField = tile.value.groupField != null;
    const {colorSchemes} = theme.dataVisualization.dataColorPalettes;

    // when there's no group field, the location color is controlled by locationColorApplication.
    const sequentialColor = tile.value.style.locationColorApplication || {
        $type: "SchemeColorApplication",
        index: 0,
        type: "Sequential",
    };

    // when there's a group field,
    // - if apply color by measure, the location color is controlled by locationColorApplication,
    // the group color in tooltip and legend is controlled by groupColorApplication.
    // - if apply color by group, both the location color and group color are controlled by locationColorApplication.
    const categoricalColor =
        hasGroupField &&
        ((tile.value.style.applyColorBy === "Group" ? tile.value.style.locationColorApplication : tile.value.style.groupColorApplication) || {
            $type: "SchemeColorApplication",
            index: 0,
            type: "Categorical",
        });

    return (
        <div className="map-colors-config-4tg colors-tile-config-vrf">
            {hasGroupField && (
                <div className="applied-color-by">
                    {DropdownSelect({
                        list: [
                            {label: "Measure", value: "Measure"},
                            {label: "Group", value: "Group"},
                        ],
                        valueToLabel: (v) => v.label,
                        isSelected: (v) => v.value === tile.value?.style.applyColorBy,
                        onChange: (v) => {
                            spc(tile, ["style"], (style) => ({
                                ...style,
                                applyColorBy: v.value,
                                locationColorApplication: {
                                    $type: "SchemeColorApplication",
                                    index: v.value === "Group" ? style.groupColorApplication?.index ?? 0 : style.locationColorApplication?.index ?? 0,
                                    type: v.value === "Group" ? "Categorical" : "Sequential",
                                },
                            }));
                        },
                        label: "Apply Color By",
                    })}
                </div>
            )}

            {hasGroupField &&
                FieldSection({
                    header: FieldInfoProvider({
                        field: <div className="text">CATEGORICAL</div>,
                        noCollapsible: true,
                        info: "This color scheme is applied to the categories in the tooltips.",
                    }),
                    content: colorSchemes.categoricalColorSchemes.map((section, index) => (
                        <FieldItem
                            label={
                                <div>
                                    CATEGORICAL SCHEME {index + 1}
                                    <div className="colors-list">
                                        {section.colorsRGB.map((color, index) => (
                                            <div
                                                className="color"
                                                key={index}
                                                style={{
                                                    background: color,
                                                }}
                                            />
                                        ))}
                                    </div>
                                </div>
                            }
                            key={index}
                            rightIcon={categoricalColor.index === index && <i className="material-icons">done</i>}
                            onClick={() => {
                                const newGroupColorApplication = {
                                    $type: "SchemeColorApplication",
                                    index,
                                    type: "Categorical",
                                };
                                spc(tile, ["style"], (style) => ({
                                    ...style,
                                    groupColorApplication: newGroupColorApplication,
                                    ...(style.applyColorBy === "Group" && {
                                        locationColorApplication: newGroupColorApplication,
                                    }),
                                }));
                            }}
                        />
                    )),
                })}

            {tile.value.style.applyColorBy === "Measure" &&
                FieldSection({
                    header: FieldInfoProvider({
                        field: <div className="text">SEQUENTIAL</div>,
                        noCollapsible: true,
                        info: "This color scheme is applied to map locations from least to most.",
                    }),
                    content: colorSchemes.sequentialColorSchemes.map((section, index) => (
                        <FieldItem
                            label={
                                <div>
                                    SEQUENTIAL SCHEME {index + 1}
                                    <div
                                        className="color-gradient"
                                        style={{
                                            background: `linear-gradient(to right, ${section.colorsRGB.join(",")})`,
                                        }}
                                    />
                                </div>
                            }
                            key={index}
                            rightIcon={sequentialColor.index === index && <i className="material-icons">done</i>}
                            onClick={() =>
                                spc(tile, ["style", "locationColorApplication"], () => ({
                                    $type: "SchemeColorApplication",
                                    index,
                                    type: "Sequential",
                                }))
                            }
                        />
                    )),
                })}
        </div>
    );
};
