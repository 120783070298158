import React from "react";
import {cs} from "@common/react/chain-services";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {stateToSelect} from "@common/ui-components/form/state-to-select";
import {scope} from "@common/react/scope";
import "./body-type.scss";
import {bindInput} from "@common/react/bind-input";
import {KeyValueInput} from "../../../../../../../common/form/key-value-input/key-value-input";
import {AuthStaticTooltip} from "./auth-static-tooltip";

export const BodyType = ({authEndpoint, form, disableEditing}) =>
    cs(({}) => (
        <div className="body-type-0fa">
            <div className="body-type-select">
                {DropdownSelect({
                    disabled: disableEditing,
                    label: (
                        <div className="body-type-select-label">
                            Body Type
                            {AuthStaticTooltip({name: "body-type"})}
                        </div>
                    ),
                    list: [
                        {label: "None", value: "None"},
                        {label: "JSON", value: "Json"},
                        {label: "XML", value: "Xml"},
                        {label: "Form Data", value: "FormData"},
                        {label: "Form URL Encoded", value: "FormUrlEncoded"},
                    ],
                    valueToLabel: (v) => v.label,
                    ...stateToSelect(scope(authEndpoint, ["bodyContentType"]), ["value"]),
                })}
            </div>
            {(() => {
                const bodyContentType = authEndpoint.value.bodyContentType;
                if (bodyContentType && bodyContentType !== "None") {
                    return (
                        <div className="body-type-editor">
                            <div className="title">Body</div>
                            {["Json", "Xml"].includes(bodyContentType) &&
                                BodyRawEditor({
                                    disabled: disableEditing,
                                    // state: scope(authEndpoint, ["bodyRaw"]),
                                    state: form.field("bodyRaw").state,
                                })}
                            {["FormData", "FormUrlEncoded"].includes(bodyContentType) &&
                                BodyFormEditor({
                                    disabled: disableEditing,
                                    state: scope(authEndpoint, ["bodyFormData"]),
                                })}
                        </div>
                    );
                }
            })()}
        </div>
    ));

const BodyRawEditor = ({state, disabled}) => (
    <div className="body-raw-editor-yt9">
        <div className="header"/>
        <div className="edit-area">
            <textarea {...{...bindInput(state), disabled}} />
        </div>
    </div>
);

const BodyFormEditor = ({state, disabled}) => (
    <div className="body-form-editor-4sl">
        {KeyValueInput({
            disableEditing: disabled,
            state,
            placeholders: {
                key: "UserID",
                value: "{userid}",
            },
        })}
    </div>
);
