import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {noun} from "@common/utils/plural";
import {ConfirmDialog} from "../../../common/confirm-dialog/confirm-dialog";
import {CreateNewEnvDialog} from "../../layout/top-section/add/create-new-env-dialog";

export const CreateModelService = ({next, currentLength}) =>
    cs(
        consumeContext("subscriptionBrief"),
        consumeContext("routing"),
        [
            "confirmDialog",
            (_, next) =>
                ConfirmDialog({
                    next,
                    cancelText: "Contact Support",
                    submitText: "Learn More",
                    title: "Model Limit Reached",
                }),
        ],
        ({subscriptionBrief, confirmDialog, routing}) => {
            const currentPlan = subscriptionBrief?.value?.primaryPlanName;
            const limiter = subscriptionBrief?.value?.allowances.find((item) => item.$type == "ModelLimiter");
            // const limit = limiter?.isMaximum ? limiter.value : null;
            const limit = null;
            // const currentPlan = "Free Plan";

            return next({
                createNew: async ({onDone}) => {
                    if (limit && currentLength >= limit) {
                        const resp = await confirmDialog.show({
                            description: `The ${currentPlan.replace("Plan", "plan")} allows for ${limit} ${noun(
                                limit,
                                "model"
                            )} per environment. If you would like to add more models please upgrade your organization.`,
                        });

                        if (resp) {
                            routing.goto("account-management", {
                                setting: "organization-details",
                                tab: "billing",
                            });
                        }
                    } else {
                        onDone();
                    }
                },
            });
        }
    );
