import * as React from "react";
import "./edit-sub-tile-panel.scss";
import {equalDeep} from "@common/utils/objects";
import {changePath, getPath, setPath} from "@common/utils/arr-path";
import {Watch} from "@common/react/watch";
import {cs} from "@common/react/chain-services";
import {EditTileLogic} from "../../../../tile/edit/edit-tile-logic";
import {UseState} from "@common/react/use-state";
import {consumeContext, provideContext} from "@common/react/context";
import {LeftPanel} from "../../../../tile/edit/left-panel/left-panel";
import {LoadingIndicator} from "@common/ui-components/loading-indicator/loading-indicator";
import {LiveDashboard} from "@common/ui-components/live/live-dashboard/live-dashboard";
import {chain} from "@common/utils/fs";
import {cascadeChange} from "@common/utils/cascade";
import {Invoke} from "@common/react/invoke";
import {Tabs} from "../../../../tile/edit/left-panel/tabs/tabs";
import {keyed1} from "@common/react/keyed";
import {DebugFunction} from "../../../../tile/edit/left-panel/tabs/fields-tab/chart-types/common/debug-function/debug-function";

export const EditSubTilePanel = ({collection, theme, cTileId, getSubTilePath, updateFunc, viewAs, editContainerTilePanel, isSharedTile = false, next}) =>
    cs(
        consumeContext("routing"),
        ["leftPanelWidth", (_, next) => UseState({next, initValue: null})],
        ({leftPanelWidth}, next) => provideContext("leftPanelWidth", leftPanelWidth, next),
        [
            "remoteSubTile",
            ({routing}, next) => {
                const parentTile = getPath(collection.value, ["gridLocations", (gl) => gl.tile.id === cTileId, "tile", "tiles"]);

                return UseState({
                    next,
                    initValue: routing.params.childTile ? (parentTile || []).find((t) => t.id == routing.params.childTile) : parentTile?.[0] || null,
                });
            },
        ],
        ({remoteSubTile}, next) => keyed1({key: remoteSubTile.value?.id, next}),
        [
            "editTileLogic",
            ({tilePath, remoteSubTile}, next) =>
                EditTileLogic({
                    next,
                    tile: remoteSubTile,
                    updateFunc: async (newTile) => {
                        if (newTile) {
                            const _newTile = await updateFunc(newTile, remoteSubTile.value.id);
                            remoteSubTile.onChange(_newTile);
                            return _newTile;
                        }
                    },
                }),
        ],
        [
            "debugFunction",
            ({editTileLogic}, next) =>
                DebugFunction({
                    tile: editTileLogic?.tile,
                    isChildTile: false,
                    apiType: isSharedTile ? "collectionTiles" : "collection",
                    next,
                }),
        ],
        ({debugFunction}, next) => provideContext("debugFunction", debugFunction, next),
        [
            "goEditTile",
            ({remoteSubTile, routing}, next) =>
                next((tile) => {
                    editContainerTilePanel.hide();
                    remoteSubTile.onChange(tile);
                    routing.updateParams({tileTab: "tile"});
                }),
        ],
        [
            "tabs",
            ({editTileLogic, remoteSubTile, goEditTile, leftPanelWidth}, next) => {
                const {colSpan, rowSpan} = getPath(collection.value, ["gridLocations", (gl) => gl.tile.id === cTileId]);
                const removeTile = async () => {
                    const newCol = await editContainerTilePanel.removeTile(remoteSubTile.value);

                    if (newCol) {
                        const newTiles = getPath(newCol, ["gridLocations", (gl) => gl.tile.id === cTileId, "tile", "tiles"]);
                        if (newTiles.length > 0) {
                            remoteSubTile.onChange(getPath(newCol, ["gridLocations", (gl) => gl.tile.id === cTileId, "tile", "tiles"])[0]);
                        } else {
                            remoteSubTile.onChange(null);
                        }
                    }
                };

                return Tabs({
                    ...editTileLogic,
                    isSubTile: true,
                    remoteTile: remoteSubTile,
                    leftPanelWidth,
                    size: {
                        width: colSpan,
                        height: rowSpan,
                    },
                    tileFuncs: {
                        removeTile,
                    },
                    renderMain: ({renderOverlay}) => {
                        return !theme
                            ? LoadingIndicator()
                            : LiveDashboard({
                                  renderOverlay,
                                  isEditTile: true,
                                  theme,
                                  apiKey: viewAs?.value,
                                  collection: changePath(collection.value, ["gridLocations"], (gls) =>
                                      chain(
                                          gls,
                                          (_) => _?.filter((gl) => gl.tile.id === cTileId),
                                          (_) =>
                                              cascadeChange(_, "[*]", (gl) => {
                                                  gl.tile.default_tile_id = remoteSubTile.value?.id;
                                                  return {
                                                      ...gl,
                                                      colStart: 1,
                                                      rowStart: 1,
                                                  };
                                              })
                                      )
                                  ),
                                  noBackgroundColor: false,
                                  onEdit: goEditTile,
                                  onSelectTile: goEditTile,
                              });
                    },
                    next,
                });
            },
        ],
        ({remoteSubTile, editTileLogic, routing, tabs, goEditTile, leftPanelWidth}) => {
            let openEditCTPanel = () => {
                leftPanelWidth.onChange(null);
                editContainerTilePanel.show(getPath(collection.value, ["gridLocations", (gl) => gl.tile.id === cTileId, "tile"]));
            };
            return next({
                editTileLogic,
                remoteSubTile,
                goEditTile,
                leftPanel: (
                    <div className="edit-sub-tile-panel-j98">
                        {remoteSubTile.value &&
                            Watch({
                                value: getPath(collection.value, getSubTilePath(remoteSubTile.value.id)),
                                onChanged: (newSubTile) => {
                                    if (!equalDeep(newSubTile, remoteSubTile.value)) {
                                        remoteSubTile.onChange(newSubTile);
                                        editTileLogic.tile.onChange(newSubTile);
                                    }
                                },
                            })}

                        {Invoke({
                            fn: () => {
                                editContainerTilePanel.editTile.set((tile) => {
                                    remoteSubTile.onChange(tile);
                                    routing.updateParams({tileTab: "tile"});
                                });

                                const tiles = getPath(collection.value, ["gridLocations", (gl) => gl.tile.id === cTileId, "tile", "tiles"]);

                                if (!tiles || tiles.length == 0) {
                                    openEditCTPanel();
                                }
                            },
                        })}

                        <div className="edit-container-tile-btn" onClick={openEditCTPanel}>
                            <img src={require("../../../../common/select-tile-type/icons/container-icon.svg")} width="26px" alt="" />
                            Edit Container or Add Tile
                        </div>

                        {remoteSubTile.value && LeftPanel(tabs, leftPanelWidth)}
                    </div>
                ),
                leftPanelWidth: leftPanelWidth.value,
                main: () => tabs.main(),
            });
        }
    );
