import React from "react";
import {cs} from "@common/react/chain-services";
import "./column-multi-select-search.scss";
import {DropdownMultiSelectSearch} from "./dropdown-multi-select-search";
import {rFieldTypeIcon} from "../../../../common/field-type-icon/get-field-type-icon";

/**
 * choose a column from a set of model columns, no relationships counted
 * support multi selecting and searching
 * */
export const ColumnMultiSelectSearch = ({columns, isSelected, onSelect, label, hasError, errorMessage}) => {
    return (
        <div className="column-multi-select-search-4gy">
            {DropdownMultiSelectSearch({
                label: "Search",
                list: (columns || []).filter((c) => !c.deleted),
                valueToLabel: (c) => (
                    <div className="column-label">
                        {rFieldTypeIcon(c.dataType)}
                        <div className="text">{c.name}</div>
                    </div>
                ),
                valueToSearch: (c) => c.name,
                isSelected,
                onSelect,
                hasError,
                errorMessage,
            })}
        </div>
    );
};
