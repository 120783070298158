import React from "react";

const SearchIcon = ({className, fill = "#919EB0"}) => (
    <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15.0258 13.8488L18.595 17.4171L17.4158 18.5963L13.8475 15.0271C12.5197 16.0915 10.8683 16.6704 9.16663 16.668C5.02663 16.668 1.66663 13.308 1.66663 9.16797C1.66663 5.02797 5.02663 1.66797 9.16663 1.66797C13.3066 1.66797 16.6666 5.02797 16.6666 9.16797C16.669 10.8696 16.0901 12.5211 15.0258 13.8488ZM13.3541 13.2305C14.4117 12.1429 15.0023 10.685 15 9.16797C15 5.94464 12.3891 3.33464 9.16663 3.33464C5.94329 3.33464 3.33329 5.94464 3.33329 9.16797C3.33329 12.3905 5.94329 15.0013 9.16663 15.0013C10.6837 15.0037 12.1415 14.4131 13.2291 13.3555L13.3541 13.2305Z"
            fill={fill}
        />
    </svg>
);

export default SearchIcon;
