import React from "react";

//Please insert img property when you insert new data sources. It's for getting started page.

export const listTypes = ({filesUploadDialog, cloudConnectionsDialog}) => ({
    database: {
        label: "Engines",
        list: [
            {
                title: "SQL Server",
                src: "./icons/SQLServer.png",
                img: <img src={require("./icons/SQLServer.png")} />,
                type: "SqlServer",
            },
            {
                title: "Postgre SQL",
                src: "./icons/PostgreSQL.png",
                img: <img src={require("./icons/PostgreSQL.png")} />,
                connectionDetailsType: "PostgreSqlConnectionDetails",
                type: "PostgreSQL",
            },
            {
                title: "Mongo DB",
                src: "./icons/MongoDB.png",
                img: <img src={require("./icons/MongoDB.png")} />,
                type: "MongoDB",
            },
            {
                title: "MySQL",
                src: "./icons/MySQL.png",
                img: <img src={require("./icons/MySQL.png")} />,
                type: "MySql",
            },
            {
                title: "Snowflake",
                src: "./icons/Snowflake.png",
                img: <img src={require("./icons/Snowflake.png")} />,
                type: "Snowflake",
            },
            {
                title: "Elasticsearch",
                src: "./icons/Elasticsearch.png",
                img: <img src={require("./icons/Elasticsearch.png")} />,
                type: "Elasticsearch",
            },
            {
                title: "DynamoDB",
                src: "./icons/DynamoDB.png",
                img: <img src={require("./icons/DynamoDB.png")} />,
                type: "DynamoDB",
            },
            {
                title: "Google BigQuery",
                src: "./icons/GoogleBigQuery.png",
                img: <img src={require("./icons/GoogleBigQuery.png")} />,
                type: "BigQuery",
            },
            {
                title: "Firestore",
                src: "./icons/Firebase.png",
                img: <img src={require("./icons/Firebase.png")} />,
                type: "Firestore",
            },
            {
                title: "Redshift",
                src: "./icons/Redshift.png",
                img: <img src={require("./icons/Redshift.png")} />,
                type: "Redshift",
            },
            {
                title: "Supabase",
                src: "./icons/Supabase.png",
                img: <img src={require("./icons/Supabase.png")} />,
                connectionDetailsType: "SupabaseConnectionDetails",
                type: "Supabase",
            },
        ],
    },
    api: {
        label: "API Integrations",
        list: [
            {
                title: "Outbound REST API",
                src: "./icons/OutboundAPI.png",
                img: <img src={require("./icons/OutboundAPI.png")} />,
                connectionDetailsType: "OutboundApiConnectionDetails",
                type: "OutboundAPI",
            },
            {
                title: "Amazon DSP Reports",
                src: "./icons/AmazonDSP.png",
                img: <img src={require("./icons/AmazonDSP.png")} />,
                type: "AmazonDSP",
            },
        ],
    },
    cloud: {
        label: "Cloud Connections",
        list: [
            {
                title: "Google Sheets",
                src: "./icons/GoogleSheet.png",
                img: <img src={require("./icons/GoogleSheet.png")} />,
                type: "GoogleSheets",
            },
            {
                title: "Google Drive",
                src: "./icons/GoogleDrive.png",
                img: <img src={require("./icons/GoogleDrive.png")} />,
                type: "GoogleDrive",
                connectionDetailsType: "GoogleSheetsConnectionDetails",
            },
        ],
        // action: cloudConnectionsDialog ? cloudConnectionsDialog.show : null
    },
    files: {
        label: "Flat File",
        list: [
            {
                title: "Excel File",
                src: "./icons/Excel.svg",
                img: <img src={require("./icons/Excel.svg")} />,
                style: {padding: `20px`},
                type: "File",
                format: "Excel",
            },
            {
                title: "CSV",
                src: "./icons/CSVIcon.svg",
                img: <img src={require("./icons/CSVIcon.svg")} />,
                style: {position: "relative", top: 30, left: 30},
                type: "File",
                format: "Csv",
            },
            // {
            //     title: "Tab Delimited", src: "./icons/TabIcon.svg",
            //     img: <img src={require("./icons/TabIcon.svg")} />,
            //     style: {position: 'relative', top: 30, left: 30},
            //     type: "File",
            //     format: "Tab"
            // },
        ],
        // action: filesUploadDialog ? filesUploadDialog.show : null
    },
});
