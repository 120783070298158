import "./window-data-configuration.scss";

import React from "react";

import {DropdownSelect2} from "@common/ui-components/dropdown-select/dropdown-select2";
import {stateToSelect} from "@common/ui-components/form/state-to-select";
import {getFieldType} from "@common/ui-components/charts/common/get-field-type";

import {SelectColumns} from "../../../../common/select-columns/select-columns";
import {ffToDropdown} from "../../../../../../../../../common/form/ff-to-dropdown";
import {rColumnSelectFromStepOutput} from "../../../../common/column-select-from-step-output/column-select-from-step-output";
import {keepOnly} from "@common/utils/objects";
import {rNewColumnNameInput} from "../../common/new-column-name-input";

const functions = [
    {
        value: "Count",
        filterColumn: (c) => true,
    },
    {
        value: "Minimum",
        filterColumn: (c) => getFieldType(c) != "text",
    },
    {
        value: "Maximum",
        filterColumn: (c) => getFieldType(c) != "text",
    },
    {
        value: "Average",
        filterColumn: (c) => getFieldType(c) != "text",
    },
    {
        value: "Median",
        filterColumn: (c) => getFieldType(c) != "text",
    },
    {
        value: "Sum",
        filterColumn: (c) => getFieldType(c) != "text",
    },
    {
        value: "First",
        filterColumn: (c) => true,
    },
    {
        value: "Last",
        filterColumn: (c) => true,
    },
];

export const WindowDataConfiguration = ({transformation, step, form}) => {
    const inputStep = transformation.value.steps.find((s) => s.id === step.value.inputStepID);
    const functionField = form.field(["function"]);
    const currentFunction = functions.find((f) => f.value == functionField.state.value);

    return (
        <div className="window-data-configuration-4te">
            <div className="config-group">
                <div className="label">Window Function</div>

                {DropdownSelect2({
                    label: "Type",
                    list: functions.map((f) => f.value),
                    ...ffToDropdown(functionField),
                    onChange: (v) => {
                        const _currentFn = functions.find((f) => f.value == v);
                        let inputCol = v === "Count" ? null : step.value.input;

                        if (!_currentFn.filterColumn(inputCol)) {
                            inputCol = inputStep?.outputColumns?.filter(_currentFn.filterColumn)?.[0] ?? null;
                        }

                        step.onChange({
                            ...step.value,
                            function: v,
                            input: inputCol,
                        });
                    },
                })}
            </div>

            {step.value.function !== "Count" && (
                <div className="config-group">
                    <div className="label">Input</div>
                    {(() => {
                        const dropdownProps = ffToDropdown(form.field(["input"]));
                        return rColumnSelectFromStepOutput({
                            label: "Column",
                            columns: inputStep?.outputColumns.filter(currentFunction.filterColumn),
                        })({
                            state: {
                                value: dropdownProps.value,
                                onChange: dropdownProps.onChange,
                            },
                            ...keepOnly(dropdownProps, ["domRef", "hasError", "errorMessage"]),
                        });
                    })()}
                </div>
            )}

            <div className="config-group">
                <div className="label">New Window Column Name</div>

                {rNewColumnNameInput({
                    form,
                    newColumnPath: ["newColumn"],
                    newColumnNamePath: ["newColumn", "name"],
                })}
            </div>

            <div className="config-group">
                <div className="label">Select columns to Partition by</div>

                {SelectColumns({
                    step,
                    form,
                    path: ["partitionColumns"],
                    columns: inputStep?.outputColumns,
                })}
            </div>

            <div className="config-group">
                <div className="label">Select columns to Order by (Optional)</div>

                {SelectColumns({
                    step,
                    form,
                    path: ["orderColumns"],
                    columns: inputStep?.outputColumns,
                    objectForm: {
                        columnProp: "column",
                        propertySelector: ({state, ...props}) =>
                            DropdownSelect2({
                                label: "Order",
                                list: [
                                    {label: "Ascending", value: "Asc"},
                                    {label: "Descending", value: "Desc"},
                                ],
                                valueToLabel: (v) => v.label,
                                ...stateToSelect(state, ["value"]),
                                ...props,
                            }),
                        propertyProp: "direction",
                    },
                })}
            </div>
        </div>
    );
};
