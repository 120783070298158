import React from "react";
import {cs} from "@common/react/chain-services";
import {TextInput} from "../../../../../../../common/form/text-input/text-input";
import {ffToTextInput} from "../../../../../../../common/form/ff-to-text-input";
import {DataTable} from "../../../../common/data-table/data-table";
import "./edit-cloud-connection.scss";
import {Form2} from "@common/react/cs-form/form2";
import {scope} from "@common/react/scope";
import {consumeContext} from "@common/react/context";
import {RemoveSourceDialog} from "../../../remove-source-dialog/remove-source-dialog";
import {pick} from "@common/utils/objects";
import {GDrivePicker} from "../../../../../common/gdrive-picker/gdrive-picker";
import {modifiedRawTables} from "../../../add/default-data-source";
import {ConnectingDataSourceDialog} from "../../../add/dialogs/connecting-data-source-dialog/connecting-data-source-dialog";
import {ErrorConnectionDialog} from "../../../add/dialogs/error-connection-dialog/error-connection-dialog";
import {Button} from "../../../../../../../common/form/buttons/button/button";
const {required} = require("@common/react/cs-form/validators/required");

export const EditCloudConnection = ({next, data}) =>
    cs(
        consumeContext("routing"),
        consumeContext("auth"),
        consumeContext("apis"),
        ["connectingDataSourceDialog", ({}, next) => ConnectingDataSourceDialog({next})],
        ["errorConnectionDialog", ({}, next) => ErrorConnectionDialog({next})],
        [
            "ggDrivePicker",
            ({selectedFile, apis, auth, connectingDataSourceDialog, errorConnectionDialog}, next) => {
                const submit = async (code) => await apis.authEndpoint.authorizeTenantGGDrive(code);

                const upload = async (file) => {
                    let submitD = {
                        fileID: file.id,
                        authorizingUserID: auth.auth.userID,
                        $type: "GoogleSheetsConnectionDetails",
                    };

                    try {
                        const tables = await connectingDataSourceDialog.show({
                            type: data.value.type,
                            onConnect: async () =>
                                await apis.data.getTablesColumns({
                                    dataSourceID: data.value.id,
                                    data: submitD,
                                }),
                        });
                        if (tables) {
                            data.change((oldData) => ({
                                ...oldData,
                                tables: modifiedRawTables({
                                    tables,
                                    type: data.value.type,
                                }),
                            }));

                            //TODO: go next step
                        }
                    } catch (e) {
                        errorConnectionDialog.show();
                    }
                };

                return GDrivePicker({next, onChange: upload, submit});
            },
        ],
        [
            "removeSourceDialog",
            ({routing}, next) =>
                RemoveSourceDialog({
                    onDone: () => routing.goto("dashboard", {tab: "data-sources"}),
                    next,
                }),
        ],
        [
            "form",
            (_, next) =>
                Form2({
                    data,
                    fields: {
                        name: [required],
                    },
                    next,
                }),
        ],
        ({form, removeSourceDialog, ggDrivePicker}) =>
            next({
                render: () => (
                    <div className="edit-cloud-connection-6ti">
                        <div className="col-left">
                            <div className="header">Cloud Connection Details</div>

                            {TextInput({
                                label: "Display Name",
                                ...ffToTextInput(form.field("name")),
                            })}

                            {DataTable({
                                list: [
                                    {
                                        account: "owebtech@gmail.com",
                                        filePath: "Verb Data / Finances / Customer Details / Google Sheet 1",
                                    },
                                ],
                                columns: [
                                    {
                                        label: "Connect Account",
                                        format: (item) => item.account,
                                    },
                                    {
                                        label: "File Path",
                                        format: (item) => item.filePath,
                                    },
                                ],
                            })}

                            <div className="buttons">
                                <Button
                                    btnType="danger"
                                    className="btn-remove"
                                    onClick={() =>
                                        removeSourceDialog.show({
                                            dataSources: [pick(data.value, ["name", "id"])],
                                        })
                                    }
                                >
                                    Remove Source
                                </Button>
                                <Button
                                    onClick={() =>
                                        ggDrivePicker.show({
                                            type: data.value.type,
                                        })
                                    }
                                >
                                    Update Credentials
                                </Button>
                            </div>
                        </div>

                        <div className="col-right">
                            <div className="section information">
                                <div className="text-header">Information</div>
                            </div>

                            <div className="section getting-connected">
                                <div className="text-header active">Google Sheet Connections</div>

                                <div className="content">
                                    <div>
                                        <div>
                                            Verb uses a token to sync data from your Google Sheet. This token has a time limit and you will
                                            be asked to re-establish the connection before the token expires.
                                        </div>
                                        <br />
                                        <div>
                                            If you are updating the token with a different Google account, you will grant permission for
                                            Verb to access the file. Please ensure that your Google account has access to this file.
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="section getting-connected">
                                <div className="text-header active">Making Changes</div>

                                <div className="content">
                                    <div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                ),
            })
    );
