import React, {createContext} from "react";
import {registryFactory} from "./registry-factory";
import {cs} from "../../react/chain-services";

const {Provider, Consumer} = createContext();

export const CommonProvider = Provider;
export const CommonConsumer = Consumer;

export const commonRegistry = registryFactory({
    className: "common-registry",
    Provider: CommonProvider,
});

export const renderCompInRegistry = ({comp}) => cs(["registry", ({}, next) => React.createElement(CommonConsumer, {}, next)], ({registry}) => registry.render(comp));
