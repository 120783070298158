import {BlockQuoteIcon, BoldIcon, HeadingIcon, ItalicIcon, LinkIcon, ListIndentIcon, ListNumberIcon, StrikeIcon} from "./icons/editor-icons";
import {cx} from "emotion";
import {useCallback, useEffect, useRef, useState} from "react";
import {$createParagraphNode, $getSelection, $isRangeSelection, CAN_REDO_COMMAND, CAN_UNDO_COMMAND, FORMAT_TEXT_COMMAND, SELECTION_CHANGE_COMMAND} from "lexical";
import {$isListNode, INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND, ListNode, REMOVE_LIST_COMMAND} from "@lexical/list";
import {$getNearestNodeOfType, mergeRegister} from "@lexical/utils";
import {$createHeadingNode, $createQuoteNode, $isHeadingNode} from "@lexical/rich-text";
import {$isCodeNode, getDefaultCodeLanguage} from "@lexical/code";
import {$isAtNodeEnd, $isParentElementRTL, $wrapLeafNodesInElements} from "@lexical/selection";
import {$isLinkNode} from "@lexical/link";
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import {EditorLinkToolbar} from "./link/editor-link-toolbar";
import {getSelectedNode} from "./editor-toolbar-common";
import {cs} from "@common/react/chain-services";
import {UseCallback} from "@common/react/use-callback";
import {UseEffect} from "@common/react/use-effect";
import {UseState} from "@common/react/use-state";

const UseLexicalComposerContextComp = ({next}) => {
    const [editor] = useLexicalComposerContext();
    return next(editor);
};

export const EditorToolbar = () =>
    cs(
        ["editor", (_, next) => UseLexicalComposerContextComp({next})],
        ["isBold", (_, next) => UseState({next})],
        ["isItalic", (_, next) => UseState({next})],
        ["isStrikethrough", (_, next) => UseState({next})],
        ["blockType", (_, next) => UseState({next, initValue: "paragraph"})],
        ["isLink", (_, next) => UseState({next, initValue: false})],
        ["selectionTextContent", (_, next) => UseState({next, initValue: ""})],
        [
            "updateToolbar",
            ({editor, blockType, isBold, isItalic, isStrikethrough, isLink, selectionTextContent}, next) =>
                UseCallback({
                    next,
                    fn: () => {
                        const selection = $getSelection();
                        selectionTextContent.onChange(selection.getTextContent());
                        if ($isRangeSelection(selection)) {
                            const anchorNode = selection.anchor.getNode();
                            const element = anchorNode.getKey() === "root" ? anchorNode : anchorNode.getTopLevelElementOrThrow();
                            const elementKey = element.getKey();
                            const elementDOM = editor.getElementByKey(elementKey);
                            if (elementDOM !== null) {
                                if ($isListNode(element)) {
                                    const parentList = $getNearestNodeOfType(anchorNode, ListNode);
                                    const type = parentList ? parentList.getTag() : element.getTag();
                                    blockType.onChange(type);
                                } else {
                                    const type = $isHeadingNode(element) ? element.getTag() : element.getType();
                                    blockType.onChange(type);
                                }
                            }
                            // Update text format
                            isBold.onChange(selection.hasFormat("bold"));
                            isItalic.onChange(selection.hasFormat("italic"));
                            isStrikethrough.onChange(selection.hasFormat("strikethrough"));

                            const node = getSelectedNode(selection);
                            const parent = node.getParent();

                            if ($isLinkNode(parent) || $isLinkNode(node)) {
                                isLink.onChange(true);
                            } else {
                                isLink.onChange(false);
                            }
                        }
                    },
                    deps: [editor],
                }),
        ],
        ({editor, updateToolbar}, next) =>
            UseEffect({
                next,
                fn: () => {
                    return mergeRegister(
                        editor.registerUpdateListener(({editorState}) => {
                            editorState.read(() => {
                                updateToolbar();
                            });
                        }),
                        editor.registerCommand(
                            SELECTION_CHANGE_COMMAND,
                            (_payload, newEditor) => {
                                updateToolbar();
                                return false;
                            },
                            1
                        )
                    );
                },
                deps: [editor, updateToolbar],
            }),
        ({editor, blockType, isBold, isItalic, isStrikethrough, isLink, selectionTextContent}) => {
            const toolbarActions = [
                {
                    groupItems: [
                        {
                            icon: <BoldIcon />,
                            isActive: isBold.value,
                            label: "Bold",
                            onClick: () => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold"),
                        },
                        {
                            icon: <StrikeIcon />,
                            isActive: isStrikethrough.value,
                            label: "Strikethrough",
                            onClick: () => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough"),
                        },
                        {
                            icon: <ItalicIcon />,
                            isActive: isItalic.value,
                            label: "Italic",
                            onClick: () => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic"),
                        },
                        {
                            icon: <HeadingIcon />,
                            isActive: blockType.value == "h1",
                            label: "Heading",
                            onClick: () => {
                                editor.update(() => {
                                    const selection = $getSelection();

                                    if ($isRangeSelection(selection)) {
                                        $wrapLeafNodesInElements(selection, () => {
                                            if (blockType.value !== "h1") {
                                                return $createHeadingNode("h1");
                                            }

                                            return $createParagraphNode();
                                        });
                                    }
                                });
                            },
                        },
                    ],
                },
                {
                    groupItems: [
                        {
                            icon: <ListIndentIcon />,
                            isActive: blockType.value == "ul",
                            label: "Bulleted List",
                            onClick: () => {
                                if (blockType.value !== "ul") {
                                    editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND);
                                } else {
                                    editor.dispatchCommand(REMOVE_LIST_COMMAND);
                                }
                            },
                        },
                        {
                            icon: <ListNumberIcon />,
                            isActive: blockType.value == "ol",
                            label: "Ordered List",
                            onClick: () => {
                                if (blockType.value !== "ol") {
                                    editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND);
                                } else {
                                    editor.dispatchCommand(REMOVE_LIST_COMMAND);
                                }
                            },
                        },
                    ],
                },
                {
                    groupItems: [
                        {
                            icon: <BlockQuoteIcon />,
                            isActive: blockType.value == "quote",
                            label: "Block Quote",
                            onClick: () => {
                                if (blockType.value !== "quote") {
                                    editor.update(() => {
                                        const selection = $getSelection();
                                        if ($isRangeSelection(selection)) {
                                            $wrapLeafNodesInElements(selection, () => $createQuoteNode());
                                        }
                                    });
                                }
                            },
                        },
                    ],
                },
            ];

            return (
                <div className="editor-toolbar">
                    {toolbarActions.map((group, index) => (
                        <div className="group-actions" key={index}>
                            {group.groupItems.map((action, index) => (
                                <div className={cx("action", action.isActive && "active")} onClick={() => action.onClick()} key={index}>
                                    {action.icon}

                                    <div className="tooltip-box">{action.label}</div>
                                </div>
                            ))}
                        </div>
                    ))}

                    <EditorLinkToolbar editor={editor} isLink={isLink} selectionTextContent={selectionTextContent} />
                </div>
            );
        }
    );
