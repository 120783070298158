import {sort, unique} from "../../../../../utils/collections";
import {chain} from "../../../../../utils/fs";

export const getExtractedPointsCompare = ({points, formatters, rawData, keepZeroValuePoints}) => {
    if (points.length === 0) {
        return {};
    }

    const extractedPoints = points.map((point) => {
        const series = point.series;
        const valueFormatter = formatters.tooltipValueFormatters[series.userOptions.customStack][series.userOptions.indexInCustomStack];
        const compareSeries = rawData.series.find((s) => s.name === series.name && s.stack === series.userOptions.customStack && s.isCompare);
        const comparePoint = compareSeries?.data[point.index ?? point.point.index];
        return {
            name: series.name,
            color: series.userOptions.colorByPoint ? point.color : series.userOptions.color?.linearGradient ? series.userOptions._baseColor : series.userOptions.color || point.color,
            dimCategory: point.key || point.name,
            dimValue: point.x,
            compareDimValue: comparePoint?.[0],
            value: point.y,
            compareValue: comparePoint?.[1],
            valueFormatter,
            title: series.userOptions.measureAxisTitle,
            customStack: series.userOptions.customStack,
            legendIndex: series.userOptions.legendIndex,
        };
    });

    const generalInfo = {
        name: extractedPoints[0].name,
        title: extractedPoints[0].title,
        dimValue: extractedPoints.find((p) => p.dimValue)?.dimValue,
        dimCategory: extractedPoints.find((p) => p.dimCategory)?.dimCategory,
        compareDimValue: extractedPoints.find((p) => p.compareDimValue)?.compareDimValue,
        customStack: extractedPoints[0].customStack,
        defaultMeasureAxisTitle: unique(extractedPoints.map((p) => p.name)).join(" & "),
        valueFormatter: extractedPoints[0].valueFormatter,
    };

    return {
        extractedPoints: chain(
            extractedPoints,
            (_) => (keepZeroValuePoints ? _ : _.filter((p) => p.value !== 0 || (p.compareValue && p.compareValue !== 0))),
            (_) => sort(_, (p) => p.legendIndex)
        ),
        generalInfo,
    };
};
