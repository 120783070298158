const defaultDSColors = [
    "#105ca9",
    "#ebc304",
    "#10adc9",
    "#db2277",
    "#fc9d36",
    "#a83a9e",
    "#81c904",
    "#1e1d42",
    "#bf67f2",
    "#39dfb2",
    "#1b8108",
    "#aa3b5d",
    "#fe9ea1",
    "#ff8b66",
    "#18441b",
    "#f231fc",
    "#20f53d",
    "#66050d",
    "#c6c0fe",
    "#3a2006",
    "#c2cba1",
    "#6108e8",
    "#6d7d4c",
    "#1d1793",
    "#916030",
    "#5f004e",
    "#fe2b1c",
    "#b94403",
];

exports.defaultDSColors = defaultDSColors;

exports.dataSourceColors = [...defaultDSColors, ...defaultDSColors, ...defaultDSColors, ...defaultDSColors];

exports.dataViewColor = "#FF5C60";

exports.getDSColorBasedOnIndex = function (index) {
    const _index = index % defaultDSColors.length;
    return defaultDSColors[_index];
};
