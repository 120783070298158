exports.timeAggregations = [
    {
        label: "Hour",
        value: "Hours",
        format: "h tt",
    },
    {
        label: "30 Minutes",
        value: "Minutes30",
        format: "h:mm tt",
    },
    {
        label: "15 Minutes",
        value: "Minutes15",
        format: "h:mm tt",
    },
    {
        label: "Minute",
        value: "Minutes",
        format: "h:mm tt",
    },
];
