import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {stateToSelect} from "@common/ui-components/form/state-to-select";

export const ComparisonMethodSelect = (state) =>
    DropdownSelect({
        label: "Comparison Method",
        list: [
            {label: "Difference (+/-)", name: "Difference"},
            {label: "Percent of", name: "Percent"},
        ],
        valueToLabel: (v) => v.label,
        ...stateToSelect(state, ["name"]),
    });
