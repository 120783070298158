import * as React from "react";

import {cs} from "@common/react/chain-services";
import {DialogService} from "../../../common/dialog/dialog-service";
import {UseState} from "@common/react/use-state";
import {Form2} from "@common/react/cs-form/form2";
import {required} from "@common/react/cs-form/validators/required";
import {email} from "@common/react/cs-form/validators/email";
import {TextInput} from "../../../../../../common/form/text-input/text-input";
import {ffToBasicInput} from "../../../../../../common/form/ff-to-basic-input";
import {consumeContext} from "@common/react/context";
import {password} from "@common/react/cs-form/validators/password";
import {Button} from "../../../../../../common/form/buttons/button/button";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";
import {notEquals} from "@common/react/cs-form/validators/not-equals";
// import {getUser} from "../../../guest-routes/login/login-route";

export const ChangeEmailDialog = ({email: oldEmail, next: rootNext}) =>
    cs(
        consumeContext("auth"),
        [
            "modal",
            (_, next) =>
                DialogService({
                    registryRender: true,
                    render: ({resolve}) => ({
                        title: "Update Email",
                        width: 480,
                        content: next({
                            resolve,
                        }),
                        initShow: true,
                        className: "change-password-dialog-wrapper-z33",
                    }),
                    next: rootNext,
                }),
        ],
        ({modal, auth}) =>
            cs(
                consumeContext("apis"),
                ["params", (_, next) => UseState({next})],
                ["saving", (_, next) => UseState({next})],
                ["error", (_, next) => UseState({next})],
                [
                    "form",
                    ({params, saving, apis, error}, next) =>
                        Form2({
                            fields: {
                                newEmail: [required, email, notEquals(oldEmail)],
                                password: [required, password],
                            },
                            data: params,
                            onSubmit: async () => {
                                if (oldEmail == params.value.newEmail) {
                                    return;
                                }

                                try {
                                    saving.onChange(true);
                                    error.onChange(null);
                                    const resp = await apis.user.changeEmail(params.value);
                                    if (resp) {
                                        modal.resolve();
                                        auth.logout();
                                        await apis.user.signOut();
                                    } else {
                                        throw new Error("Cannot update new email address. Please try again.");
                                    }
                                } catch (err) {
                                    error.onChange(err.message);
                                    saving.onChange(false);
                                }
                            },
                            next,
                        }),
                ],
                ({form, saving, error, params}) => (
                    <div className="change-password-dialog-z33">
                        <VerbDialogBodyScrollbar>
                            <div className="error-message">{error.value}</div>

                            <div className="messages">
                                {TextInput({
                                    label: "New Email",
                                    ...ffToBasicInput(form.field("newEmail")),
                                })}

                                {TextInput({
                                    label: "Password",
                                    type: "password",
                                    ...ffToBasicInput(form.field("password")),
                                })}
                            </div>
                        </VerbDialogBodyScrollbar>

                        <div className="buttons">
                            <Button btnType="secondary" onClick={() => modal.resolve()}>
                                Cancel
                            </Button>
                            <Button disabled={saving.value || oldEmail == params.value?.newEmail} onClick={() => form.submit()}>
                                Update
                            </Button>
                        </div>
                    </div>
                )
            )
    );
