import React from "react";
import {cs} from "@common/react/chain-services";
import {TextManipulationResultActionSelect} from "../../common/text-manipulation-result-action-select";
import {rColumnValueSelectFromStepOutput} from "../../../../../common/column-value-select-from-step-output/column-value-select-from-step-output";
import {ffToDropdown} from "../../../../../../../../../../common/form/ff-to-dropdown";
import {keepOnly} from "@common/utils/objects";
import {ffToTextInput} from "../../../../../../../../../../common/form/ff-to-text-input";
import {DebounceTextInput} from "../../../../../../../../../../common/form/debounce-input/debounce-text-input";
import {rNewColumnNameInput} from "../../../common/new-column-name-input";
import {SelectColumns} from "../../../../../common/select-columns/select-columns";
import {DropdownSelect2} from "@common/ui-components/dropdown-select/dropdown-select2";
import {stateToSelect} from "@common/ui-components/form/state-to-select";

import "./list-agg-configuration.scss";

export const ListAggConfiguration = ({textManipulation, step, inputStep, state, model, form}) =>
    cs(() => {
        return (
            <div className="list-agg-configuration-8re">
                <div className="config-group">
                    <div className="label">Column To Aggregate</div>
                    {(() => {
                        const dropdownProps = ffToDropdown(form.field(["textManipulation", "inputValue"]));
                        return rColumnValueSelectFromStepOutput({
                            label: "Column To Aggregate",
                            columns: inputStep.outputColumns,
                            model,
                            disableStaticValue: true,
                            filterColumnFn: (c) => ["Text", "IDUUID", "IDInt", "Int", "Double"].includes(c.dataType),
                            isValueNotAllowed: (c) => !["Text", "IDUUID", "IDInt", "Int", "Double"].includes(c.dataType),
                        })({
                            state: {
                                value: dropdownProps.value,
                                onChange: dropdownProps.onChange,
                            },
                            ...keepOnly(dropdownProps, ["domRef", "hasError", "errorMessage"]),
                        });
                    })()}
                </div>

                <div className="config-group">
                    <div className="label">Delimiter</div>
                    <div className="formula-group">
                        {(() => {
                            const inputProps = ffToTextInput(form.field(["textManipulation", "delimiter"]));
                            return DebounceTextInput({
                                label: "Delimiter",
                                ...inputProps,
                                maxLength: 3,
                                state: {
                                    value: inputProps.state.value,
                                    onChange: (v) => inputProps.state.onChange(v),
                                },
                            });
                        })()}
                    </div>
                </div>

                <div className="config-group">
                    <div className="label">Select columns to Group by</div>

                    {SelectColumns({
                        step,
                        form,
                        path: ["textManipulation", "groupByColumns"],
                        columns: inputStep?.outputColumns,
                    })}
                </div>

                <div className="config-group">
                    <div className="label">Select columns to Order by (Optional)</div>

                    {SelectColumns({
                        step,
                        form,
                        path: ["textManipulation", "orderColumns"],
                        columns: inputStep?.outputColumns,
                        objectForm: {
                            columnProp: "column",
                            propertySelector: ({state, ...props}) =>
                                DropdownSelect2({
                                    label: "Order",
                                    list: [
                                        {label: "Ascending", value: "Asc"},
                                        {label: "Descending", value: "Desc"},
                                    ],
                                    valueToLabel: (v) => v.label,
                                    ...stateToSelect(state, ["value"]),
                                    ...props,
                                }),
                            propertyProp: "direction",
                        },
                    })}
                </div>

                <div className="config-group">
                    <div className="label">New Column Name</div>

                    {rNewColumnNameInput({
                        form,
                        newColumnPath: ["textManipulation", "newColumn"],
                        newColumnNamePath: ["textManipulation", "newColumn", "name"],
                    })}
                </div>
            </div>
        );
    });
