const {getFieldType} = require("@common/ui-components/charts/common/get-field-type");
// const {getType} = require("./get-type");
const {cGetField} = require("./get-field");

const cGetFieldInfo = (model, dataSources = []) => {
    const getModelColumn = cGetField(model);
    return (field) => {
        if (!field) {
            return {};
        }

        const column = getModelColumn([field.modelID, field.modelTableID, field.modelColumnID]) ?? {};
        return {
            $type: column.$type,
            columnName: column.displayName || column.name,
            name: field.displayName || column.displayName || column.name,
            visualNameFull: column.visualNameFull,
            visualNameShort: column.visualNameShort,
            type: getFieldType(column) || "text",
            dataType: column.dataType,
            modelTableID: column.modelTableID,
            modelTableType: column.modelTableType,
            modelColumnID: column.modelColumnID,
            modelTableName: column.modelTableName,
            dataSourceID: column.dataSourceID,
            dataSourceName: dataSources.find((ds) => ds.id == column.dataSourceID)?.name,
            dateProperties: field.dateProperties,
            isTableDeleted: column.isTableDeleted,
            isColumnDeleted: column.deleted,
        };
    };
};
exports.cGetFieldInfo = cGetFieldInfo;
