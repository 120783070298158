import {cs} from "@common/react/chain-services";
import {cascadeChange} from "@common/utils/cascade";
import {createUrlFormat} from "@common/utils/http/url-format";
import {GlobalEvent} from "@common/react/global-event";
import {Rerender} from "@common/react/rerender";

const getCurrentLocationState = ({routes1, url}) => {
    url =
        url ??
        (() => {
            const hash = window.location.hash;
            return hash ? hash.substring(1) : null;
        })();

    const match = /^([^?]*)(\?.*)?/.exec(url);
    const path = match[1];
    const search = match[2];
    // console.log("Searching")
    for (const {urlFormat, route, ...others} of routes1) {
        const params = urlFormat.match(path);
        if (params) {
            return {props: {match: {params}, location: {search}}, route, routeConfig: {urlFormat, route, ...others}};
        }
    }
};

export const Router = ({routes, next}) =>
    cs(
        [
            "routes",
            (_, next) =>
                next(
                    cascadeChange(routes, "[*].query[*]", (paramName) =>
                        paramName.endsWith("~")
                            ? {
                                  paramName: paramName.substring(0, paramName.length - 1),
                                  carryOn: true,
                              }
                            : {
                                  paramName,
                              }
                    )
                ),
        ],
        [
            "routes1",
            ({routes}, next) =>
                next(
                    routes.map((route) => ({
                        route,
                        urlFormat: createUrlFormat(route.path),
                    }))
                ),
        ],

        ["rerender", (_, next) => Rerender({next})],
        ["current", ({routes1}, next) => next(getCurrentLocationState({routes1}))],

        ({rerender}, next) =>
            GlobalEvent({
                eventName: "hashchange",
                fn: () => rerender.invoke(),
                next,
            }),

        ({routes, current, rerender}) =>
            next({
                routes,
                current,
                goBack: () => history.back?.(),
                pushHistory: (url) => {
                    if (history.pushState) {
                        history.pushState(null, null, "#" + url);
                    } else {
                        location.hash = "#" + url;
                    }
                    rerender.invoke();
                },
            })
    );
