import {required} from "@common/react/cs-form/validators/required";
import {max} from "@common/react/cs-form/validators/max";
import {min} from "@common/react/cs-form/validators/min";
import {inRangeValidator} from "@common/react/cs-form/validators/in-range";

export const themeFormConfig = () => {
    const buttonKeys = ["primaryButton", "secondaryButton", "downloadButton", "buttonToggleGroup"];
    const chartKeys = ["gauges", "pieAndDonutCharts"];

    const borderWidthPaths = [...buttonKeys.map((key) => ["general", "buttons", key, "borderWidth"]), ["general", "tile", "styles", "tileBorderWidth"]];

    const requiredInputNumberPaths = [
        ...buttonKeys.map((key) => ["general", "buttons", key, "cornerRadius"]),
        ...chartKeys.map((key) => ["dataVisualization", key, "borderWidth"]),
        ["general", "tile", "styles", "tileCornerRadius"],
        ["general", "tile", "containerTiles", "buttonStyle", "cornerRadius"],
        ["general", "tile", "containerTiles", "dropdownStyle", "cornerRadius"],
        ["dataVisualization", "axisCharts", "barChartCornerRadius"],
        ["general", "components", "inputCornerRadius"],
        ["general", "components", "menuCornerRadius"],
        ["dataVisualization", "toolTipsAndLegends", "indicatorLineWidth"],
        ["general", "tile", "containerTiles", "tabStyle", "bottomBorderHeight"],
        ["general", "tile", "containerTiles", "buttonStyle", "borderWidth"],
        ["general", "tile", "containerTiles", "dropdownStyle", "borderWidth"],
        ["dataVisualization", "tables", "borderWidth"],
    ];

    return {
        fields: [
            ...borderWidthPaths.map((path) => ({
                path: path,
                validators: [required, inRangeValidator(0, 5)],
            })),
            ...requiredInputNumberPaths.map((path) => ({
                path: path,
                validators: [required],
            })),
            {
                path: ["general", "components", "inputBorderWidth"],
                validators: [required, inRangeValidator(0, 2)],
            },
        ],
    };
};
