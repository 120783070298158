const {omit} = require("../../../../../../../../common/utils/objects");

const purifyCollection = (collection) => {
    if (collection.$type === "ApiCollection") {
        return {
            ...omit(collection, ["slug", "version", "versionGroupID"]),
            version: "1.0",
        };
    }
    return collection;
};
exports.purifyCollection = purifyCollection;
