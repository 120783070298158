import moment from "moment";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {Load2} from "@common/react/load2";
import {downloadBlob} from "@common/utils/browser/download-service";
import {DataTable} from "../../../common/data-table/data-table";
import {EditIcon2} from "../../../model/edit/main/controls/more-controls/icons/more-control-icons";
import {SyncStatusSummarySkeletons} from "../sync/summary/sync-status-summary-skeletons";
import "./edit-flat-file-status.scss";
import {FlatFileSkeletons} from "./flat-file-skeletons";
import {FlatFileSummary} from "./summary/flat-file-summary";
import {createArray} from "@common/utils/collections";
import {ObserveDomSize} from "@common/react/observe-dom-size";

export const EditFlatFileStatus = ({dataSource, interactions}) =>
    cs(
        (_, next) => (dataSource.loading ? null : next()),
        consumeContext("apis"),
        consumeContext("routing"),
        consumeContext("downloadToast"),
        [
            "histories",
            ({apis, routing}, next) =>
                Load2({
                    fetch: () => apis.data.getDataSourceHistory(routing.params.dsId),
                    next,
                }),
        ],
        [
            "onDownload",
            ({apis, routing, downloadToast}, next) =>
                next(async (versionId) => {
                    downloadToast.add({id: versionId});
                    const {blob, filename} = await apis.data.downloadDataSource(routing.params.dsId, versionId);
                    downloadToast.update({id: versionId});
                    if (blob) {
                        downloadBlob(blob, {name: filename});
                    }
                    downloadToast.remove({id: versionId});
                }),
        ],
        ({histories}, next) =>
            histories.loading ? (
                <div className="edit-sync-status-8uo edit-flat-file-status-88i">
                    <div className="left-side">{FlatFileSkeletons({})}</div>

                    <div className="right-side">{SyncStatusSummarySkeletons({})}</div>
                </div>
            ) : (
                next()
            ),
        ({histories, onDownload}) =>
            cs(["syncScreenSize", (_, next) => ObserveDomSize({next})], ({syncScreenSize}) => {
                return (
                    <div className="edit-sync-status-8uo edit-flat-file-status-88i" ref={syncScreenSize.ref}>
                        <div className="left-side">
                            <div className="version">
                                <div className="label">Current Version</div>

                                <div className="box">
                                    <img className="icon" src={require("../../add/select-data-source/list/icons/Excel.svg")} />

                                    <div className="name">
                                        <div className="type">{dataSource.value.type}</div>
                                        <div className="text">{dataSource.value.name}</div>
                                        {histories.value[0] && (
                                            <div className="overview">
                                                <div>Tables: {histories.value[0].tables}</div>
                                            </div>
                                        )}
                                    </div>

                                    {histories.value[0] && (
                                        <>
                                            <div className="section">
                                                <div className="label">Imported</div>
                                                <div className="value">{moment(histories.value[0].timestamp).format("MMM DD, YYYY")}</div>
                                                <div className="sub-value">{moment(histories.value[0].timestamp).format("hh:mm A")}</div>
                                            </div>
                                            <div className="section">
                                                <div className="label">Data</div>
                                                <div className="value">{histories.value[0].sizeMB} MB</div>
                                                <div className="sub-value" />
                                            </div>
                                            <div className="section">
                                                <div className="label">Records</div>
                                                <div className="value">{histories.value[0].records}</div>
                                                <div className="sub-value" />
                                            </div>
                                        </>
                                    )}

                                    {EditIcon2({
                                        className: "edit-icon",
                                        onClick: () => interactions.onChange(dataSource.value),
                                    })}

                                    {histories.value[0]?.canDownload && (
                                        <span className="material-icons-outlined download-icon" onClick={() => onDownload(histories.value[0].id)}>
                                            file_download
                                        </span>
                                    )}
                                </div>
                            </div>

                            <div className="version">
                                <div className="label">Previous Version</div>

                                {cs(
                                    [
                                        "height",
                                        (_, next) => {
                                            const minHeight = 40 + histories.value.length * 56;
                                            const maxHeight = syncScreenSize.value?.height - 210;
                                            return next(maxHeight >= minHeight ? minHeight : maxHeight);
                                        },
                                    ],
                                    ({height}) =>
                                        DataTable({
                                            applyStyleOnParent: true,
                                            style: {height: `${height}px`},
                                            maxHeight: `100%`,
                                            className: "edit-flat-file-table-99p sync-status-table-88e",
                                            list: histories.value || [],
                                            columns: [
                                                {
                                                    className: "type-icon",
                                                    format: () => <img className="icon" src={require("../../add/select-data-source/list/icons/Excel.svg")} />,
                                                },
                                                {
                                                    label: "File",
                                                    className: "file-name",
                                                    format: (row) => (
                                                        <>
                                                            <div className="text">{row.originalFileName}</div>
                                                            <div className="overview">
                                                                <div>Tables: {row.tables}</div>
                                                            </div>
                                                        </>
                                                    ),
                                                    sortValue: (row) => row.originalFileName,
                                                },
                                                {
                                                    label: "Imported",
                                                    format: (row) => (
                                                        <>
                                                            <div>{moment(row.timestamp).format("MMM DD, YYYY")}</div>
                                                            <div>{moment(row.timestamp).format("hh:mm A")}</div>
                                                        </>
                                                    ),
                                                    sortValue: (row) => row.timestamp,
                                                },
                                                {
                                                    label: "Data",
                                                    format: (row) => <>{Number(row.sizeMB).toLocaleString()} MB</>,
                                                    sortValue: (row) => row.sizeMB,
                                                },
                                                {
                                                    label: "Records",
                                                    format: (row) => <>{Number(row.records).toLocaleString()}</>,
                                                    sortValue: (row) => row.records,
                                                },
                                                {
                                                    label: "",
                                                    className: "download",
                                                    format: (row) =>
                                                        row.canDownload ? (
                                                            <span className="material-icons-outlined download-icon" onClick={() => onDownload(row.id)}>
                                                                file_download
                                                            </span>
                                                        ) : null,
                                                },
                                            ],
                                        })
                                )}
                            </div>
                        </div>

                        <div className="right-side">{FlatFileSummary({histories: histories.value})}</div>
                    </div>
                );
            })
    );
