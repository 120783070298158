import * as React from "react";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {DialogService} from "../../../../../common/dialog/dialog-service";
import {consumeContext} from "@common/react/context";
import {Load} from "@common/react/load";
import {SelectFolderService} from "../../folder-cm/select-folder-service/select-folder-service";
import "./move-collection-dialog.scss";
import {waitTimeout} from "@common/utils/wait-timeout";
import {Load2} from "@common/react/load2";
import {Button} from "../../../../../../../../common/form/buttons/button/button";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";

export const MoveCollectionDialog = ({folders, onDone, next: rootNext}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    render: ({resolve, args: {collection}}) => ({
                        title: "Move Collection",
                        width: 540,
                        content: next({
                            resolve,
                            collection,
                        }),
                    }),
                    next: rootNext,
                }),
        ],

        consumeContext("apis"),

        [
            "remoteFolders",
            ({apis}, next) =>
                Load2({
                    fetch: () => apis.collection.getFolders(),
                    next,
                }),
        ],

        [
            "fullCol",
            ({modal, apis}, next) =>
                Load({
                    fetch: () => apis.collection.getCollection(modal.collection.id),
                    next,
                }),
        ],

        [
            "destination",
            ({modal}, next) =>
                UseState({
                    initValue: modal.collection.parentId || null,
                    next,
                }),
        ],

        [
            "selectFolderService",
            ({destination, remoteFolders}, next) =>
                SelectFolderService({
                    apiType: "collection",
                    state: destination,
                    label: "Move collection to",
                    folders: folders ?? remoteFolders.value,
                    next,
                }),
        ],

        ["submitting", (_, next) => UseState({next})],

        ({destination, fullCol, modal, selectFolderService, submitting, apis}) => (
            <div className="move-collection-dialog-8we">
                <VerbDialogBodyScrollbar>
                    <div className="main-part">{selectFolderService.render()}</div>
                </VerbDialogBodyScrollbar>

                <div className="buttons">
                    <Button btnType="secondary" onClick={() => modal.resolve()}>
                        Cancel
                    </Button>
                    <Button
                        disabled={submitting.value || !selectFolderService.valid}
                        onClick={async () => {
                            submitting.onChange(true);

                            if (!fullCol) {
                                await waitTimeout(2000);
                            }

                            if (fullCol) {
                                if (selectFolderService.selectNewFolder) {
                                    try {
                                        const newFolder = await selectFolderService.selectNewFolder();
                                        await apis.collection.upsertCollection({
                                            ...fullCol,
                                            folderID: newFolder.id,
                                        });
                                    } catch (error) {
                                        //return console.log(error);
                                    }
                                } else {
                                    if (destination.value !== fullCol.folderID) {
                                        await apis.collection.upsertCollection({
                                            ...fullCol,
                                            folderID: destination.value,
                                        });
                                    }
                                }

                                const newFolders = await apis.collection.getFolders();
                                onDone(newFolders);
                                submitting.onChange(false);
                                return modal.resolve();
                            }

                            submitting.onChange(false);
                        }}
                    >
                        Move
                    </Button>
                </div>
            </div>
        )
    );
