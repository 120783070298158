export const themeCool = {
    general: {
        canvas: {
            fontFamily: "Roboto",
            fontSize: "ExtraSmall",
            fontColorRGB: "#000000",
            backgroundColorRGB: "#FFFFFF",
            tileSpacing: "Narrow",
        },
        tile: {
            styles: {
                titleFontSize: "ExtraSmall",
                titleFontColorRGB: "#294661",
                titleFontOptions: "Bold",
                titleCaseOptions: "Uppercase",
                tileBackgroundColorRGB: "#FFFFFF",
                tileBorderColorRGB: "#DEE2E7",
                tileCornerRadius: 8,
                tileBorderWidth: 1,
                tileShadow: false,
            },
            containerTiles: {
                buttonStyle: {
                    backgroundColorRGB: "#FFFFFF",
                    borderColorRGB: "#DEE2E7",
                    fontColorRGB: "#294661",
                    hoverBackgroundColorRGB: "#E7F6FF",
                    hoverBorderColorRGB: "#FFFFFF",
                    hoverFontColorRGB: "#294661",
                    activeBackgroundColorRGB: "#294661",
                    activeBorderColorRGB: "#294661",
                    activeFontColorRGB: "#FFFFFF",
                    borderWidth: 1,
                    cornerRadius: 32,
                },
                tabStyle: {
                    backgroundColorRGB: "#FFFFFF",
                    borderColorRGB: "#FFFFFF",
                    fontColorRGB: "#919EB0",
                    bottomBorderHeight: 2,
                    hoverBackgroundColorRGB: "#E7F6FF",
                    hoverBorderColorRGB: "#FFFFFF",
                    hoverFontColorRGB: "#919EB0",
                    activeBackgroundColorRGB: "#FFFFFF",
                    activeBorderColorRGB: "#11A1FD",
                    activeFontColorRGB: "#11A1FD",
                },
                dropdownStyle: {
                    backgroundColorRGB: "#FFFFFF",
                    borderColorRGB: "#DEE2E7",
                    fontColorRGB: "#294661",
                    borderWidth: 1,
                    cornerRadius: 32,
                },
            },
        },
        buttons: {
            primaryButton: {
                backgroundColorRGB: "#0276F2",
                fontColorRGB: "#FFFFFF",
                borderColorRGB: "#0276F2",
                hoverBackgroundColorRGB: "#0294F2",
                hoverFontColorRGB: "#FFFFFF",
                hoverBorderColorRGB: "#0294F2",
                cornerRadius: 4,
                borderWidth: 0,
            },
            secondaryButton: {
                backgroundColorRGB: "#FFFFFF",
                fontColorRGB: "#919EB0",
                borderColorRGB: "#DEE2E7",
                hoverBackgroundColorRGB: "#FFFFFF",
                hoverFontColorRGB: "#0276F2",
                hoverBorderColorRGB: "#0276F2",
                cornerRadius: 4,
                borderWidth: 1,
            },
            downloadButton: {
                backgroundColorRGB: "#FFFFFF",
                fontColorRGB: "#919EB0",
                borderColorRGB: "#DEE2E7",
                hoverBackgroundColorRGB: "#FFFFFF",
                hoverFontColorRGB: "#0276F2",
                hoverBorderColorRGB: "#0276F2",
                cornerRadius: 4,
                borderWidth: 1,
            },
            buttonToggleGroup: {
                backgroundColorRGB: "#FFFFFF",
                fontColorRGB: "#919EB9",
                borderColorRGB: "#DEE2E7",
                activeBackgroundColorRGB: "#E7F6FF",
                activeFontColorRGB: "#0276F2",
                activeBorderColorRGB: "#0276F2",
                cornerRadius: 4,
                borderWidth: 1,
            },
            hyperlinkButton: {
                fontColorRGB: "#0276F2",
                hoverFontColorRGB: "#0294F2",
            },
        },
        components: {
            inputBackgroundColorRGB: "#FFFFFF",
            inputBorderColorRGB: "#DEE2E7",
            inputBorderWidth: 1,
            inputCornerRadius: 4,
            inputTextColorRGB: "#294661",
            inputHintTextColorRGB: "#919EB0",
            inputIconColorRGB: "#294661",
            inputLabelTextColorRGB: "#546B81",
            menuBackgroundColorRGB: "#FFFFFF",
            menuCornerRadius: 4,
            menuTextColorRGB: "#546B81",
            menuHoverBackgroundColorRGB: "#EFF3F6",
        },
    },
    dataVisualization: {
        dataColorPalettes: {
            discreteColorsRGB: ["#0074F0", "#D457D0", "#FF4E87", "#FF8638", "#C2B700", "#35C06D", "#00ECFD", "#00B5BE"],
            colorSchemes: {
                categoricalColorSchemes: [
                    {
                        colorsRGB: ["#D457D0", "#FF4E87", "#FF8638", "#35C06D", "#00ECFD", "#00B5BE"],
                    },
                    {
                        colorsRGB: ["#00B5BE", "#00ECFD", "#35C06D", "#FF8638", "#FF4E87", "#D457D0"],
                    },
                    {
                        colorsRGB: ["#136695", "#409890", "#C18826", "#D84F00", "#8B4D6C", "#7D6A73"],
                    },
                ],
                sequentialColorSchemes: [
                    {
                        colorsRGB: ["#0074F0", "#029EF2"],
                    },
                    {
                        colorsRGB: ["#0074F0", "#02F2B8"],
                    },
                    {
                        colorsRGB: ["#0074F0", "#AF31FC"],
                    },
                ],
            },
            kpiColorsRGB: ["#294661", "#919EB0", "#0074F0", "#FF5959", "#1AC689"],
            otherColors: {
                emptyFillColorRGB: "#DEE2E7",
                conditionalGoodColorRGB: "#1AC689",
                conditionalBadColorRGB: "#FF5959",
            },
        },
        fonts: {
            fontFamily: null,
            fontColorRGB: null,
            axisFontSize: null,
            dataLabelFontSize: null,
            gridStepsFontSize: null,
        },
        toolTipsAndLegends: {
            tooltipTheme: "Dark",
            position: "Above",
            indicatorLineShown: true,
            indicatorLineStyle: "Solid",
            indicatorLineColorRGB: "#EFF3F6",
            indicatorLineWidth: 1,
            legendSymbol: "Square",
        },
        axisCharts: {
            axisLineColorRGB: "#EFF3F6",
            gridStepLineColorRGB: "#EFF3F6",
            gridStepLineStyle: "Dashed",
            dimensionAxisTickMarks: false,
            barChartCornerRadius: 12,
            barChartCornerRadiusLocation: "Top",
            areaLineChartFill: "Gradient",
        },
        gauges: {
            showBorder: false,
            borderWidth: 2,
            borderColorRGB: "#FFFFFF",
            calloutLineWidth: 1,
            calloutLineColorRGB: "#EAEAEA",
        },
        pieAndDonutCharts: {
            showBorder: false,
            borderWidth: 2,
            borderColorRGB: "#FFFFFF",
            calloutLineWidth: 1,
            calloutLineColorRGB: "#EAEAEA",
        },
        tables: {
            headerBackgroundColorRGB: "#F4F5F7",
            headerFontColorRGB: "#919EB0",
            headerFontSize: null,
            headerFontOptions: "Bold",
            headerCaseOptions: "Uppercase",
            cellFontColorRGB: "#294661",
            cellBackgroundColorRGB: "#FFFFFF",
            cellAlternateType: "AlternateRows",
            cellAltCellColorRGB: "#F9FBFC",
            totalsBackgroundColorRGB: "#FFFFFF",
            totalsFontColorRGB: "#294661",
            totalsFontOptions: "Bold",
            totalsFontSize: null,
            borderVerticalLines: true,
            borderHorizontalLines: true,
            borderOutsideLines: true,
            borderWidth: 1,
            borderColorRGB: "#E9EEF3",
            colorScaleSchemes: [
                {
                    colorsRGB: ["#FF5959", "#FDED5B", "#1AC689"],
                },
                {
                    colorsRGB: ["#1AC689", "#FDED5B", "#FF5959"],
                },
                {
                    colorsRGB: ["#1AC689", "#FF5959"],
                },
                {
                    colorsRGB: ["#FFFFFF", "#1AC689"],
                },
                {
                    colorsRGB: ["#FF5959", "#FFFFFF"],
                },
            ],
            singleColorsRGB: ["#1AC689", "#FF5959", "#FDED5B", "#0074F0", "#FF8638"],
            fontColorsRGB: ["#294661", "#000000", "#ffffff", "#1AC689", "#FF5959"],
        },
        maps: {
            emptyLocationColorRGB: "#FFFFFF",
            borderColorRGB: "#E9ECEF",
        },
        sectionTile: {
            backgroundColorRGB: null,
            fontColorRGB: null,
            fontStyle: "Bold",
            caseStyle: "Uppercase",
            fontSize: null,
        },
        textTile: {
            fontColorRGB: null,
            fontSize: null,
        },
    },
};
