import "./account-settings-personal.scss";

import React from "react";

import {cs} from "@common/react/chain-services";

import {PersonalGeneral} from "./tabs/personal-general";
import {PanelTabs} from "../common/panel-tabs";
import {PersonalSecurity} from "./tabs/personal-security";

export const AccountSettingsPersonal = () =>
    cs(
        [
            "tabs",
            ({}, next) =>
                next([
                    {
                        name: "general",
                        label: "General",
                        render: PersonalGeneral,
                    },
                    {
                        name: "security",
                        label: "Security",
                        render: PersonalSecurity,
                    },
                ]),
        ],
        ({tabs}) =>
            PanelTabs({
                tabs,
                title: "Personal",
                className: "personal-59f",
                defaultTabIndex: 0,
            })
    );
