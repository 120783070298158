export const fixOverlappingXAxisGridLabels = (chart) => {
    const labelGroup = chart.xAxis[0].labelGroup;
    const labels = labelGroup.element.children;

    // const rotationAngle = getRotation(labels[0]);
    const rotationAngle = Math.abs(chart.xAxis[0].labelRotation);

    if (!rotationAngle) {
        return;
    }

    const firstLabelDomRect = labels[0].getBoundingClientRect();
    const secondLabelDomRect = labels[1].getBoundingClientRect();
    const firstLabelSvgRect = labels[0].getBBox();

    // idea: if two first labels are tight together, which means their rects overlap,
    // which means the distance between their left length sides is smaller than the height of label text.

    const distance = Math.abs(getTopRightX(secondLabelDomRect) - getTopRightX(firstLabelDomRect)) * Math.cos(((90 - rotationAngle) * Math.PI) / 180);

    if (distance <= firstLabelSvgRect.height) {
        // console.log("tight")
        const ticks = Object.values(chart.xAxis[0].ticks);

        for (let i = 0; i < labels.length; i++) {
            if (i % 2 !== 0) {
                labels[i].style.display = "none";
                if (ticks[i].mark) {
                    ticks[i].mark.element.style.display = "none";
                }
            }
        }
    }
};

const getTopRightX = (labelDomRect) => {
    return labelDomRect.x + labelDomRect.width;
};

// const getRotation = (labelDom) => {
//     const transform = labelDom.getAttribute("transform");
//     const rotationMatch = transform.match(/rotate\(([^\s)]*)[^)]*\)/);
//     if (!rotationMatch) {
//         return;
//     }
//     const rotation = rotationMatch[1];
//     return Math.abs(Number(rotation));
// };
