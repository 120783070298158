import {cs} from "@common/react/chain-services";
import "./collection-tiles-dropdown.scss";
import React from "react";
import SearchIcon from "../../../../../assets/icons/common/SearchIcon";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";
import {UseState} from "@common/react/use-state";
import {bindInput} from "@common/react/bind-input";
import {consumeContext} from "@common/react/context";
import {chain} from "@common/utils/fs";
import {sort} from "@common/utils/collections";
import {isMatchText} from "@common/utils/strings";

export const CollectionTilesDropdown = ({collection, domRef}) =>
    cs(["keyword", (_, next) => UseState({next})], consumeContext("routing"), ({keyword, routing}) => {
        return (
            <div
                className="collection-tiles-dropdown-a33"
                style={{
                    ...(window.innerHeight - domRef.get().getBoundingClientRect().top < 300 ? {bottom: "100%"} : {top: "100%"}),
                }}
            >
                <div className="input-search-wrapper">
                    <SearchIcon />

                    <input
                        className="input-search"
                        placeholder="Search"
                        {...{
                            ...bindInput(keyword),
                        }}
                    />
                </div>

                <VerbScrollbar className="item-wrapper" maxHeight="200px">
                    {chain(
                        collection.tiles,
                        (_) => sort(_, (tile) => tile.name),
                        (_) => _.filter((tile) => isMatchText(tile.name, keyword.value))
                    ).map((tile) => (
                        <div
                            className="item"
                            key={tile.tileID}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                routing.goto(tile.$type == "ContainerTile" ? "edit-container-tile" : "edit-tile", {
                                    tileId: tile.tileID,
                                    cTileId: tile.tileID,
                                    colId: collection.id,
                                });
                            }}
                        >
                            {tile.name}
                        </div>
                    ))}
                </VerbScrollbar>
            </div>
        );
    });
