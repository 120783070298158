import {CheckboxLine} from "../../../../../../../../../common/checkbox-line/checkbox-line";
import {FieldInfoProvider} from "../field-info-provider/field-info-provider";
import "./cumulative-select.scss";

export const CumulativeSelect = ({tile, state}) => {
    if (
        // respective logic for allowing cumulative is in raw-data-manipulator.js -> cGetCumulativeData
        ["DateTime", "DateTimeOffset"].includes((tile.value.xAxisField || tile.value.yAxisField)?.dataType) &&
        !tile.value.limit
    ) {
        return FieldInfoProvider({
            className: "cumulative-select-42d cumulative-select",
            info: "Cumulative will sum previous values in the series.",
            tooltipBoxWidth: 150,
            field: CheckboxLine({
                label: "Cumulative",
                state,
            }),
        });
    }
};
