import * as React from "react";
import {CheckboxLine} from "../../../../../../../../../common/checkbox-line/checkbox-line";
import {scope} from "@common/react/scope";

export const Display = ({tile, form}) => ({
    initExpand: true,
    label: "Display",
    render: () => (
        <div className="venn-diagram-section-lg3">
            {CheckboxLine({
                label: "Auto-round values",
                state: scope(tile, ["style", "autoRound"]),
            })}
        </div>
    ),
});
