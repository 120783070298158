import React from "react";
import {css, cx} from "emotion";
import "./case-options-select.scss";
import {LpLine} from "../../../../common/left-panel/line/lp-line";
import {Static2} from "@common/react/static-2";
import {UseState} from "@common/react/use-state";
import {cs} from "@common/react/chain-services";
import {TitleCaseIcon, UpperCaseIcon} from "./icons/case-options-icons";
import {Button} from "../../../../../../../common/form/buttons/button/button";
import {ButtonIcon} from "../../../../../../../common/form/buttons/button-icon/button-icon";
import {StyledClass} from "@common/react/styled-class";

export const CaseOptionSelect = ({label, state}) =>
    LpLine({
        label,
        control: CaseOptions({
            isSelected: (v) => state.value == v.replace(/ /g, ""),
            onChange: (v) => state.onChange(v.replace(/ /g, "")),
        }),
    });

const CaseOptions = ({isSelected, onChange}) =>
    cs(
        ["ref", (_, next) => Static2({next})],
        [
            "styledClass",
            ({}, next) =>
                StyledClass({
                    content: {
                        "&:hover": {
                            background: "#e8f6ff !important",
                            "border-color": "transparent !important",
                            color: "#919eb0 !important",
                        },
                    },
                    next,
                }),
        ],
        ["state", (_, next) => UseState({next})],
        ({ref, state, styledClass}) => (
            <div className="case-options-24c" ref={ref.set}>
                {caseOptions.map((co, i) => (
                    <ButtonIcon
                        key={i}
                        btnType={isSelected(co.name) ? "secondary" : "ghost"}
                        className={cx("case-icon", !isSelected(co.name) ? styledClass : "")}
                        onClick={() => (isSelected(co.name) ? onChange("None") : onChange(co.name))}
                        onMouseEnter={() => state.onChange(co.name)}
                        onMouseLeave={() => state.onChange(null)}
                        icon={co.iconPath}
                    >
                        {/*{ state.value == co.name && (*/}
                        {/*    <div className={cx("tooltip", co.toolTipClassName)}*/}
                        {/*        style={{*/}
                        {/*            ...co.toolTipPos(ref.get().getBoundingClientRect())*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        {co.name}*/}
                        {/*    </div>*/}
                        {/*)}*/}

                        {/*<img className={"option-icon"} src={co.iconPath} alt=""/>*/}
                    </ButtonIcon>
                ))}
            </div>
        )
    );

const caseOptions = [
    {
        name: "Title Case",
        iconPath: <TitleCaseIcon />,
        toolTipPos: ({left, bottom}) => ({
            left: `${left}px`,
            top: `${bottom + 10}px`,
        }),
        toolTipClassName: "bottom",
    },
    {
        name: "Uppercase",
        iconPath: <UpperCaseIcon />,
        toolTipPos: ({left, width, top}) => ({
            left: `${left + width + 10}px`,
            top: `${top + 2}px`,
        }),
        toolTipClassName: "left",
    },
];
