import * as React from "react";
import {cs} from "../../../react/chain-services";
import {ChartTable} from "./table/chart-table";
import {keepOnly} from "../../../utils/objects";
import {PagedTableLayout} from "./paged/paged-table-layout";
import {InfiniteTableLayout} from "./infinite/infinite-table-layout";
import {keyed} from "../../../react/keyed";
import {Static2} from "../../../react/static-2";
import {MenuOptions} from "../common/menu-options/menu-options";
import {cascade} from "../../../utils/cascade";
import {ActionMenu} from "../common/action-menu/action-menu";
import {loadTileFields} from "../get-field-color";
import {RemoveTileControlFilter} from "../common/control-filter/remove-tile-control-filter";
import {CurrencyConversionWarning, getCurrencyConversion} from "../common/currency-conversion-warning/currency-conversion-warning";

export const TableChart = ({
    tile,
    size,
    isEditTile,
    loadData,
    downloadData,
    tileFilters,
    theme,
    defaultData,
    overrideTile,
    disabledTileActions,
}) =>
    cs(
        ["totalRef", (_, next) => Static2({next})],
        [
            "tileFields",
            ({}, next) =>
                loadTileFields({
                    next,
                    configs: {
                        tile,
                        measureMultiAttr: ["actualValueFields", "aggregatedValues"],
                        checkUnique: false,
                    },
                }),
        ],
        [
            "layout",
            ({totalRef, tileFields}, next) =>
                cs(
                    keyed(tile?.style?.paginationType ?? "Paged"),
                    [
                        "component",
                        (_, next) => next((tile?.style?.paginationType ?? "Paged") === "Paged" ? PagedTableLayout : InfiniteTableLayout),
                    ],
                    ({component}) =>
                        component({
                            next,
                            tileFilters,
                            loadData,
                            size,
                            tile,
                            theme,
                            tileFields,
                            totalRef,
                            isEditTile,
                            tileKey: getTableTileKey({tile}),
                            defaultData,
                            controls: ({data}) =>
                                cs(() => {
                                    return (
                                        <>
                                            {!tile.style.headerShown &&
                                                CurrencyConversionWarning({
                                                    theme,
                                                    currencyConversion: getCurrencyConversion({
                                                        $type: data.$type,
                                                        data,
                                                        tileFields,
                                                    }),
                                                })}

                                            {RemoveTileControlFilter({
                                                tile,
                                                hideBorder: !tile.style.showDownloadData,
                                            })}

                                            {MenuOptions({
                                                theme,
                                                tile,
                                                downloadData,
                                                tileFilters,
                                                chartRef: null,
                                            })}
                                        </>
                                    );
                                }),
                        })
                ),
        ],
        [
            "actionMenu",
            (_, next) =>
                ActionMenu({
                    tile,
                    overrideTile,
                    disabledTileActions,
                    next,
                }),
        ],
        ({layout, totalRef, actionMenu, tileFields}) =>
            ChartTable({
                data: layout.data,
                sort: layout.sort,
                search: layout.search,
                loadingIndicator: layout.loadingIndicator,
                tileFields,
                tile,
                theme,
                totalRef,
                actionMenu,
            })
    );

const getTableTileKey = ({tile, page}) =>
    JSON.stringify([
        page,
        {removeDuplicates: tile.style.removeDuplicates},
        tile.filters,
        tile.style.paginationType,
        tile.style.rowsPerPage,
        tile.style.conditionalFormattingRules,
        tile.sort,
        tile.limit,
        cascade(tile.style.columnSettings, "[*].summaryAggregation", (v) => v),
        [...(tile.actualValueFields || []), ...(tile.aggregatedValues || [])].map((f) => ({
            id: f.id,
            dateAggregation: f.dateProperties?.aggregation,
            ...keepOnly(f, ["sortDirection", "aggregationFunc", "displayName"]),
        })),
    ]);

// const rowPerPage = 10;
// const colNo = 6;
// const rowNo = 20;
// const mockData = {
//     columnNames: createArray(colNo).map((i) => `Col ${i+1}`),
//     rows: createArray(rowNo).map((i) => ({
//         columns: createArray(colNo).map((j) =>
//             // `r${i+1}c${j+1}`
//             Math.random()*100 + "very long long long"
//         )
//     })),
// };
//
// const totals = createArray(colNo).map((i) => `Total ${i+1}`);
// const prevs = createArray(colNo).map((i) => ({
//     prevValue: Math.floor(Math.random()*100),
//     difference: Math.floor(Math.random()*10),
//     isBad: Math.pow(-1, i) === 1,
// }));
