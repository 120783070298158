export const interactionComboChartTile = {
    id: "b4eee7bf-4985-4f42-b895-82ba7f231b0c",
    xAxisField: {
        id: "7e35fda1-d25e-47ef-aaeb-75521a1d0600",
        modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
        modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
        modelColumnID: "5035cb8a-55ef-45e5-9772-4a2a019d89b4",
        displayName: "DatePlacedUtc",
        dataType: "DateTime",
        numericProperties: null,
        dateProperties: {aggregation: "Days", format: "M/d"},
        $type: "DimensionTileField",
    },
    yAxisBarFields: [
        {
            id: "830ec105-317c-4673-acc7-65c8faf93b8f",
            measureFields: [
                {
                    id: "830ec105-317c-4673-acc7-65c8faf93b8f",
                    aggregationFunc: "Sum",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelColumnID: "48a106a4-074a-45af-b01a-1cddf52da60a",
                    displayName: "Data Synced",
                    dataType: "Double",
                    numericProperties: null,
                    dateProperties: null,
                    $type: "MeasureTileField",
                },
            ],
        },
    ],
    yAxisLineFields: [
        {
            id: "ef14ffce-147a-434a-a42a-09a370979185",
            measureFields: [
                {
                    id: "ef14ffce-147a-434a-a42a-09a370979185",
                    aggregationFunc: "Sum",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelColumnID: "994c76be-b379-4171-ae7f-e5d4eeae1e99",
                    displayName: "Sync Attempts",
                    dataType: "Double",
                    numericProperties: {
                        displayType: "Number",
                        decimalSeperator: "Dot",
                        thousandsSeperator: "Comma",
                        decimalPlaces: 0,
                        shiftDecimalPlaceLeft: null,
                        negativeFormat: "Parentheses",
                        currencyProperties: null,
                    },
                    dateProperties: null,
                    $type: "MeasureTileField",
                },
            ],
        },
        {
            id: "7a88c4f1-3e59-44e4-99ab-39be8352556c",
            measureFields: [
                {
                    id: "7a88c4f1-3e59-44e4-99ab-39be8352556c",
                    aggregationFunc: "Sum",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelColumnID: "87cdc4c5-aa8f-4ef8-a0cd-1df2a0dd7940",
                    displayName: "Errors",
                    dataType: "Double",
                    numericProperties: {
                        displayType: "Number",
                        decimalSeperator: "Dot",
                        thousandsSeperator: "Comma",
                        decimalPlaces: 0,
                        shiftDecimalPlaceLeft: null,
                        negativeFormat: "Parentheses",
                        currencyProperties: null,
                    },
                    dateProperties: null,
                    $type: "MeasureTileField",
                },
            ],
        },
    ],
    groupField: null,
    referenceLines: [],
    style: {
        multipleAxisBarOption: "IndependentAxes",
        multipleAxisLineOption: "IndependentAxes",
        legendStyle: {
            legendShown: false,
            legendTitleShown: true,
            legendTitle: "Legend has title",
            legendPosition: "Bottom",
            legendSize: null,
            legendContent: "Label",
            legendComparisonType: null,
        },
        yAxisBar: {
            "830ec105-317c-4673-acc7-65c8faf93b8f": {
                labelShown: true,
                label: null,
                range: "AutoFrom0",
                rangeCustomMin: null,
                rangeCustomMax: null,
                gridStepType: null,
                gridStepNumeric: null,
                gridStepLabelsShown: true,
                gridStepLabelOrientation: null,
                lineShown: false,
                location: "LeftTop",
                gridLinesShown: false,
                showInfoIcon: false,
                infoIconText: null,
                cumulative: false,
                logarithmic: false,
                $type: "ComboChartMeasureAxisBarStyle",
            },
        },
        yAxisBarDataLabels: {
            show: false,
            allowOverlap: false,
            position: "OutsideAbove",
            $type: "DataLabelsTileStyle",
        },
        yAxisBarDisplayType: "Classic",
        barDataColorAppliedBy: "Measure",
        barColorApplications: {
            "830ec105-317c-4673-acc7-65c8faf93b8f": {
                index: 10,
                $type: "DiscreteColorApplication",
            },
        },
        lineColorApplications: {
            "ef14ffce-147a-434a-a42a-09a370979185": {
                index: 0,
                $type: "DiscreteColorApplication",
            },
            "7a88c4f1-3e59-44e4-99ab-39be8352556c": {
                index: 16,
                $type: "DiscreteColorApplication",
            },
        },
        yAxisLine: {
            "ef14ffce-147a-434a-a42a-09a370979185": {
                thickness: "Medium",
                displayType: "Spline",
                marker: "Circle",
                labelShown: true,
                label: null,
                range: "AutoFrom0",
                rangeCustomMin: null,
                rangeCustomMax: null,
                gridStepType: null,
                gridStepNumeric: null,
                gridStepLabelsShown: true,
                gridStepLabelOrientation: null,
                lineShown: false,
                location: "RightBottom",
                gridLinesShown: false,
                showInfoIcon: false,
                infoIconText: null,
                cumulative: false,
                logarithmic: false,
                $type: "ComboChartMeasureAxisLineStyle",
            },
            "7a88c4f1-3e59-44e4-99ab-39be8352556c": {
                thickness: "Medium",
                displayType: "Line",
                marker: "Circle",
                labelShown: false,
                label: null,
                range: "AutoFrom0",
                rangeCustomMin: null,
                rangeCustomMax: null,
                gridStepType: null,
                gridStepNumeric: null,
                gridStepLabelsShown: false,
                gridStepLabelOrientation: null,
                lineShown: false,
                location: "LeftTop",
                gridLinesShown: false,
                showInfoIcon: false,
                infoIconText: null,
                cumulative: false,
                logarithmic: false,
                $type: "ComboChartMeasureAxisLineStyle",
            },
        },
        yAxisLineDataLabels: {
            show: false,
            allowOverlap: false,
            position: "OutsideAbove",
            $type: "DataLabelsTileStyle",
        },
        xAxis: {
            labelShown: true,
            label: null,
            gridStepType: null,
            gridStepNumeric: null,
            gridStepLabelsShown: true,
            gridStepLabelOrientation: "Auto",
            maxValuesInView: "Auto",
            maxValuesInViewFixed: null,
            lineShown: true,
            gridLinesShown: false,
            dateGaps: "FillWithZero",
            showInfoIcon: false,
            infoIconText: null,
            $type: "ComboChartDimensionAxisStyle",
        },
        title: {show: false, titleLocation: "Above"},
        dataColorPalette: [
            "#C2B700",
            "#D457D0",
            "#9468E7",
            "#83BF3C",
            "#FF4AAE",
            "#FF4E87",
            "#FF665F",
            "#0074F0",
            "#FF8638",
            "#FFA600",
            "#35C06D",
            "#00BD9B",
            "#00B5BE",
            "#00AAD2",
            "#349DD4",
            "#00ECFD",
            "#F33912",
            "#F63F98",
            "#57D6FB",
            "#FF89AD",
        ],
        showCompare: false,
        showDownloadData: false,
        downloadFormat: "XLSX",
        showDownloadImage: false,
        showTitleInfoIcon: false,
        titleInfoIconText: null,
        $type: "ComboChartTileStyle",
    },
    title: "5",
    filters: [
        {
            id: "fbefc8e2-35ee-488b-a165-c426bff9afa7",
            options: 0,
            defaultOption: "Custom",
            customRange: {
                dateStart: "2016-03-01T00:00:00Z",
                dateEnd: "2016-03-31T00:00:00Z",
                $type: "InRangeCustomDateFilterRange",
            },
            compareEnabled: true,
            compareOnByDefault: true,
            compareDefaultRange: "PreviousPeriod",
            includeNullDates: false,
            label: "Date Placed Utc (Order)",
            showLabelInfoIcon: false,
            labelInfoIconText: null,
            columns: [
                {
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelColumnID: "5035cb8a-55ef-45e5-9772-4a2a019d89b4",
                    displayName: null,
                    default: true,
                },
            ],
            multiSelectionOption: "SingleSelect",
            required: false,
            displayArea: "Visible",
            disconnectedTiles: [],
            $type: "DateFilter",
        },
    ],
    sort: [
        {
            direction: "Asc",
            modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
            modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
            modelColumnID: "5035cb8a-55ef-45e5-9772-4a2a019d89b4",
            aggregationFunc: "None",
        },
    ],
    categorySort: null,
    categorySortBasis: null,
    limit: null,
    tileActions: [],
    fieldValidity: "Valid",
    $type: "ComboChartTile",
};

export const interactionComboChartRawData = (data) => ({
    $type: "ChartTileData",
    series: [
        {
            tileFieldID: "830ec105-317c-4673-acc7-65c8faf93b8f",
            name: "Data Synced",
            measureAxisTitle: "Data Synced",
            stack: "830ec105-317c-4673-acc7-65c8faf93b8f",
            data: data.dataSynced,
            isCompare: false,
            range: {
                dateStart: "2016-03-01T00:00:00Z",
                dateEnd: "2016-03-31T00:00:00Z",
            },
            legendIndex: 0,
            tileActionData: {data: [], columns: []},
        },
        {
            tileFieldID: "ef14ffce-147a-434a-a42a-09a370979185",
            name: "Sync Attempts",
            measureAxisTitle: "Sync Attempts",
            stack: "ef14ffce-147a-434a-a42a-09a370979185",
            data: data.attempts,
            isCompare: false,
            range: {
                dateStart: "2016-03-01T00:00:00Z",
                dateEnd: "2016-03-31T00:00:00Z",
            },
            legendIndex: 2,
            tileActionData: {data: [], columns: []},
        },
        {
            tileFieldID: "7a88c4f1-3e59-44e4-99ab-39be8352556c",
            name: "Errors",
            measureAxisTitle: "Errors",
            stack: "7a88c4f1-3e59-44e4-99ab-39be8352556c",
            data: data.errors,
            isCompare: false,
            range: {
                dateStart: "2016-03-01T00:00:00Z",
                dateEnd: "2016-03-31T00:00:00Z",
            },
            legendIndex: 1,
            tileActionData: {data: [], columns: []},
        },
    ],
    tileID: "c6f24c31-d42f-7486-a703-b0c9a0347fa8",
    fieldValidity: "Valid",
    resultsTruncated: false,
    publishIDs: [],
});

export const interactionComboChartTheme = {
    id: "f884aa9c-9a1a-4c3b-ae6a-1daebac7a593",
    name: "Demo Staging Theme",
    baseTheme: null,
    folderID: null,
    default: false,
    general: {
        canvas: {
            fontFamily: "Lato",
            fontSize: "ExtraSmall",
            fontColorRGB: "#000000",
            backgroundColorRGB: "#ffffff",
            tileSpacing: "Narrow",
        },
        tile: {
            styles: {
                titleFontFamily: null,
                titleFontSize: null,
                titleFontColorRGB: null,
                titleFontOptions: "Bold",
                titleCaseOptions: "TitleCase",
                tileBackgroundColorRGB: "#FFFFFF",
                tileBorderColorRGB: "#ffffff",
                tileCornerRadius: 4,
                tileBorderWidth: 0,
                tileShadow: true,
            },
            containerTiles: {
                buttonStyle: {
                    backgroundColorRGB: "#FFFFFF",
                    borderColorRGB: "#FFFFFF",
                    fontColorRGB: "#000000",
                    hoverBackgroundColorRGB: "#FFFFFF",
                    hoverBorderColorRGB: "#000000",
                    hoverFontColorRGB: "#000000",
                    activeBackgroundColorRGB: "#000000",
                    activeBorderColorRGB: "#000000",
                    activeFontColorRGB: "#FFFFFF",
                    borderWidth: 2,
                    cornerRadius: 20,
                },
                tabStyle: {
                    backgroundColorRGB: "#FFFFFF",
                    borderColorRGB: "#FFFFFF",
                    fontColorRGB: "#000000",
                    bottomBorderHeight: 0,
                    hoverBackgroundColorRGB: "#E9F4FE",
                    hoverBorderColorRGB: "#E9F4FE",
                    hoverFontColorRGB: "#898B90",
                    activeBackgroundColorRGB: "#E9F4FE",
                    activeBorderColorRGB: "#2692F5",
                    activeFontColorRGB: "#2692F5",
                },
                dropdownStyle: {
                    backgroundColorRGB: "#FFFFFF",
                    borderColorRGB: "#C0C0C0",
                    fontColorRGB: "#6D6F78",
                    borderWidth: 1,
                    cornerRadius: 20,
                },
            },
        },
        buttons: {
            primaryButton: {
                backgroundColorRGB: "#a8008c",
                fontColorRGB: "#FFFFFF",
                borderColorRGB: null,
                hoverBackgroundColorRGB: "#0034C4",
                hoverFontColorRGB: null,
                hoverBorderColorRGB: null,
                cornerRadius: 0,
                borderWidth: 0,
            },
            secondaryButton: {
                backgroundColorRGB: null,
                fontColorRGB: null,
                borderColorRGB: null,
                hoverBackgroundColorRGB: null,
                hoverFontColorRGB: null,
                hoverBorderColorRGB: null,
                cornerRadius: 0,
                borderWidth: 0,
            },
            downloadButton: {
                backgroundColorRGB: null,
                fontColorRGB: null,
                borderColorRGB: null,
                hoverBackgroundColorRGB: null,
                hoverFontColorRGB: null,
                hoverBorderColorRGB: null,
                cornerRadius: 0,
                borderWidth: 0,
            },
            buttonToggleGroup: {
                backgroundColorRGB: null,
                fontColorRGB: null,
                borderColorRGB: null,
                activeBackgroundColorRGB: null,
                activeFontColorRGB: null,
                activeBorderColorRGB: null,
                cornerRadius: 0,
                borderWidth: 0,
            },
            hyperlinkButton: {fontColorRGB: null, hoverFontColorRGB: null},
        },
        components: {
            inputBackgroundColorRGB: null,
            inputBorderColorRGB: null,
            inputBorderWidth: 0,
            inputCornerRadius: 0,
            inputTextColorRGB: null,
            inputHintTextColorRGB: null,
            inputIconColorRGB: null,
            inputLabelTextColorRGB: null,
            menuBackgroundColorRGB: null,
            menuCornerRadius: 0,
            menuTextColorRGB: null,
            menuHoverBackgroundColorRGB: null,
        },
    },
    dataVisualization: {
        dataColorPalettes: {
            discreteColorsRGB: [
                "#0074F0",
                "#9468E7",
                "#D457D0",
                "#FF4AAE",
                "#FF4E87",
                "#FF665F",
                "#FF8638",
                "#FFA600",
                "#C2B700",
                "#83BF3C",
                "#35C06D",
                "#00BD9B",
                "#00B5BE",
                "#00AAD2",
                "#349DD4",
                "#00ECFD",
                "#F33912",
                "#F63F98",
                "#57D6FB",
                "#FF89AD",
            ],
            colorSchemes: {
                categoricalColorSchemes: [],
                sequentialColorSchemes: [],
            },
            kpiColorsRGB: [],
            otherColors: {
                emptyFillColorRGB: "#E9ECEF",
                conditionalGoodColorRGB: "#18C96E",
                conditionalBadColorRGB: "#E95A5A",
            },
        },
        fonts: {
            fontFamily: null,
            fontColorRGB: "#294661",
            fontWeight: "Bold",
            axisFontSize: "ExtraSmall",
            dataLabelFontSize: null,
            gridStepsFontSize: null,
        },
        toolTipsAndLegends: {
            fontSize: null,
            indicatorLineColorRGB: "#000000",
            indicatorLineShown: false,
            indicatorLineStyle: "Solid",
            indicatorLineWidth: 0.5,
            legendFontSize: null,
            legendSymbol: "Circle",
            position: "Above",
            tooltipTheme: "Light",

            // tooltipTheme: "Dark",
            // position: "Below",
            // fontSize: null,
            // indicatorLineShown: false,
            // indicatorLineStyle: "Solid",
            // indicatorLineColorRGB: "#000000",
            // indicatorLineWidth: 0.5,
            // legendFontSize: null,
            // legendSymbol: "Circle",
        },
        axisCharts: {
            axisLineColorRGB: "#D6D0CA",
            gridStepLineColorRGB: null,
            gridStepLineStyle: "Solid",
            dimensionAxisTickMarks: true,
            barChartCornerRadius: 10,
            barChartCornerRadiusLocation: "Top",
            areaLineChartFill: "Semitransparent",
        },
        gauges: {
            showBorder: false,
            borderWidth: null,
            borderColorRGB: null,
            calloutLineWidth: null,
            calloutLineColorRGB: null,
        },
        pieAndDonutCharts: {
            showBorder: false,
            borderWidth: null,
            borderColorRGB: null,
            calloutLineWidth: null,
            calloutLineColorRGB: null,
        },
        tables: {
            headerBackgroundColorRGB: null,
            headerFontColorRGB: null,
            headerFontSize: null,
            headerFontOptions: "Bold",
            headerCaseOptions: null,
            cellFontColorRGB: null,
            cellBackgroundColorRGB: null,
            cellAlternateType: "None",
            cellAltCellColorRGB: "#FFFFFF",
            totalsBackgroundColorRGB: null,
            totalsFontColorRGB: null,
            totalsFontSize: null,
            totalsFontOptions: "Bold",
            borderVerticalLines: false,
            borderHorizontalLines: true,
            borderOutsideLines: true,
            borderWidth: 1,
            borderColorRGB: "#EAEAEA",
        },
        maps: {emptyLocationColorRGB: null, borderColorRGB: null},
    },
};
