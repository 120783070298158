const selectRandom = (col) => col[Math.floor(Math.random() * col.length)];

exports.selectRandom = selectRandom;

function randomId(length = 10) {
    let text = "";
    let possible = "abcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < length; i++) text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
}
exports.randomId = randomId;

function getRandomInt(min, max) {
    const min1 = Math.ceil(min);
    const max1 = Math.floor(max);
    return Math.floor(Math.random() * (max1 - min1 + 1)) + min1;
}
exports.getRandomInt = getRandomInt;
