const {getDataLabelAbsRect} = require("./show-data-labels");
const {showDataLabels} = require("./show-data-labels");

const adjustOutsideDataLabels = ({chart, allowOverlap}) => {
    const adjustEachSeries = (series) => {
        const [centerX, centerY] = series.center;

        series.data.forEach((point) => {
            if (point.dataLabel) {
                const {dataLabel, shapeArgs, labelPosition, labelDistance} = point;
                // console.log(dataLabel.div.children[0])
                // console.log(labelPosition)
                const bbox = dataLabel.getBBox();
                const angle = (shapeArgs.end - shapeArgs.start) / 2 + shapeArgs.start;
                // const extendFactor = 1 + 10/shapeArgs.r;
                const posX = centerX + (shapeArgs.r + labelDistance) * Math.cos(angle);
                const posY = centerY + (shapeArgs.r + labelDistance) * Math.sin(angle); //  + Math.min(30, bbox.width / 2)

                // this does not modify the real dom elem
                // point.dataLabel._pos.x = posX;
                // point.dataLabel._pos.x = posX + ((labelPosition.alignment === "right" ? -1 : 1) * (bbox.width / 2));
                // point.dataLabel._pos.y = posY - bbox.height/2;

                // this causes wrong placing after resizing
                // dataLabel.div.style.left = `${posX + ((labelPosition.alignment === "right" ? -1.5 : 0) * (bbox.width / 2))}px`;
                // dataLabel.div.style.top = `${posY - bbox.height/2}px`;

                dataLabel.attr({
                    x: posX, // + ((labelPosition.alignment === "right" ? -0.5 : 0) * (bbox.width / 2)),
                    y: posY - bbox.height / 2,
                });
            }
        });

        // this does not modify the real dom elem
        // series.placeDataLabels();

        showDataLabels({
            points: series.data.filter((point) => point.dataLabel),
            allowOverlap,
            getRect: getDataLabelAbsRect,
        });
    };

    // chart.series.forEach((series) => adjustEachSeries(series));
    adjustEachSeries(chart.series.find((s) => !s.options.isCompare));
};
exports.adjustOutsideDataLabels = adjustOutsideDataLabels;
