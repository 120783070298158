import moment from "moment";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {DsClearErrorDialog} from "../../../common/clear-error-dialog/ds-clear-error-dialog";
import {errorsMessages, shortErrorsMessages} from "../../../common/errors-messages";
import {NavigateSyncSettings} from "../../common/navigate-sync-settings";
import "./interaction-error.scss";
import {divide} from "@common/utils/collections";

export const InteractionError = ({error, table, onReload}) =>
    cs(
        consumeContext("routing"),
        ["clearErrorDialog", (_, next) => DsClearErrorDialog({next})],
        [
            "goSyncSettings",
            ({routing}, next) =>
                cs(["navigateSyncSettings", (_, next) => NavigateSyncSettings({next})], ({navigateSyncSettings}) =>
                    next(() => navigateSyncSettings.goto())
                ),
        ],
        ({clearErrorDialog, goSyncSettings, routing}) => {
            const _errorConfig = shortErrorsMessages({
                syncSetting: (
                    <span className="link" onClick={goSyncSettings}>
                        Sync Settings
                    </span>
                ),
                clearErrors: () => (
                    <span
                        className="link"
                        onClick={async () => {
                            const resp = await clearErrorDialog.show({
                                dsID: routing.params.dsId,
                                goSyncSettings,
                                errorTablesIDsSelected: [table.id],
                            });

                            if (resp) {
                                onReload();
                            }
                        }}
                    >
                        clear the error
                    </span>
                ),
            })["table"][error.error];

            return (
                <div className="interaction-error-99r">
                    {/* {error.error === "SyncDisabled" && ( */}
                    <div className="status">
                        <span className="text">Sync Disabled on {moment(new Date(table.lastSync)).format("MMM D @ hh:mm A")}</span> -{" "}
                        {moment(new Date(table.lastSync)).fromNow()}
                    </div>
                    {/* )} */}

                    <div className="error-message">{_errorConfig?.label}</div>

                    <div className="desc">
                        {_errorConfig?.message}

                        {/*Go to <span className="link" onClick={goSyncSettings}>Sync Settings</span> for possible solutions. Or{' '} */}
                        {/*<span */}
                        {/*    className="link" */}
                        {/*    onClick={() => clearErrorDialog.show({ */}
                        {/*        dsID: routing.params.dsId,*/}
                        {/*        goSyncSettings,*/}
                        {/*        errorTablesIDsSelected: [table.id] */}
                        {/*    })}>*/}
                        {/*        clear the error</span> to resume syncing and see if the issue is already resolved.*/}
                    </div>
                </div>
            );
        }
    );
