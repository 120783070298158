import React from "react";
import {cs} from "../../react/chain-services";
import {UseState} from "../../react/use-state";
import {OnMounted} from "../../react/on-mounted";
import {OnUnmounted} from "../../react/on-unmounted";

export const ModalScrollSupport = () =>
    cs(["lastScrollTop", (_, next) => UseState({next})], ({lastScrollTop}) => (
        <>
            {OnMounted({
                action: async () => {
                    const doc = document.documentElement;
                    const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
                    lastScrollTop.onChange(top);
                    document.documentElement.style.overflow = "hidden";
                    document.documentElement.style.position = "relative";
                    document.documentElement.style.height = "100%";
                },
            })}

            {OnUnmounted({
                action: () => {
                    document.documentElement.style.overflow = "";
                    document.documentElement.style.position = "";
                    document.documentElement.style.height = "";
                    window.scroll(0, lastScrollTop.value);
                },
            })}
        </>
    ));
