import {cs} from "@common/react/chain-services";
import moment from "moment";
import {Button} from "../../../../../../../common/form/buttons/button/button";
import {GhostButton} from "../../../../../../../common/form/buttons/ghost-button/ghost-button";
import DataSourceErrorIcon from "assets/icons/data-sources/DataSourceErrorIcon";
import "./sync-errors.scss";
import {ErrorConnectionDialog} from "../../../add/dialogs/error-connection-dialog/error-connection-dialog";
import {consumeContext} from "@common/react/context";
import {errorsMessages} from "../../common/errors-messages";

export const SyncErrors = ({errors, hasActions = false}) =>
    cs(consumeContext("routing"), ["errorDialog", (_, next) => ErrorConnectionDialog({next})], ({errorDialog, routing}) => {
        const error = errors[0];

        if (!error) return <></>;

        const errorConfig = errorsMessages["dataSource"][error.error];

        return (
            <div className="error-sync-99o">
                <div className="icon">{DataSourceErrorIcon({})}</div>

                <div className="main">
                    <div className="header">
                        <div className="sub-text">
                            {/* {error?.message} */}
                            {/* Sync Disabled on {moment().format("MMM D @ hh:mm A")} */}
                        </div>
                        <div className="text">{errorConfig.label}</div>
                    </div>

                    <div className="content">
                        <div className="left-side">
                            <div className="content">
                                <div className="label">Possible solutions:</div>
                                {errorConfig.solutions.map((item, i) => (
                                    <div className="item" key={i}>
                                        {i + 1}) {item}
                                    </div>
                                ))}
                            </div>

                            {hasActions && (
                                <div className="buttons">
                                    <Button
                                        {...{
                                            onClick: () =>
                                                routing.goto("edit-connection-settings", {
                                                    envId: routing.params.envId,
                                                    dsId: routing.params.dsId,
                                                }),
                                        }}
                                    >
                                        Connection Settings
                                    </Button>
                                    {/*<GhostButton>Attempt Connection Now</GhostButton>*/}
                                    <GhostButton
                                        onClick={() =>
                                            errorDialog.show({
                                                title: "Sync Error",
                                                content: error.message,
                                            })
                                        }
                                    >
                                        View Full Error
                                    </GhostButton>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    });
