const link = document.createElement("a");
link.style.display = "none";
document.body.appendChild(link); // Required for FF

const download = (href, {name} = {}) => {
    window.location.href = href;

    // link.setAttribute("download", name);
    // link.setAttribute("href", href);
    //
    // setTimeout(() => {
    //     link.click();
    //     link.click();
    // }, 100);
};
exports.download = download;

const downloadBlob = (blob, {name} = {}) => {
    const url = URL.createObjectURL(blob);
    console.log(url);

    download(url, {name});
};
exports.downloadBlob = downloadBlob;
