import {cGetFontSize} from "../../common/style-map/font-size";
import {getDataLabelsStyle} from "../../factory/data-labels";

export const getSectionLabelsOptions = ({tile, theme, formatters}) => {
    const getFontSize = cGetFontSize(theme.general.canvas.fontSize, theme);
    return {
        enabled: tile.style.dataLabels.show,
        allowOverlap: true,

        connectorWidth: 0, // disable connector
        // bring section labels inside
        inside: true,
        align: "center",
        verticalAlign: "middle",

        comparisonType: tile.style.dataLabels.comparisonType,

        style: getDataLabelsStyle({
            dataLabelsConfig: tile.style.dataLabels,
            theme,
        }).style,
        autoRound: tile.style.autoRound,
        metrics: tile.style.dataLabels.metrics,

        useHTML: true,
        formatter: function () {
            const point = this.point;
            // const data = this.series.data;
            const fontSize = getFontSize({
                group: theme.dataVisualization.fonts.dataLabelFontSize,
            });
            const valueFmt = tile.style.autoRound ? formatters.valueRoundFormatter : formatters.valueFormatter;
            const percentFmt = tile.style.autoRound ? formatters.percentRoundFormatter : formatters.percentFormatter;

            const showCategory = tile.style.dataLabels.categoryShown;
            const showValue = tile.style.dataLabels.metrics?.includes("Value");
            const showPercent = tile.style.dataLabels.metrics?.includes("Percent");

            const firstLine =
                showCategory &&
                `
                <div>${point.name}</div>
            `;

            const percent = showValue ? `(${percentFmt(point.percentOfTotal)})` : percentFmt(point.percentOfTotal);
            const secondLine = `
                <div style="
                    font-weight: ${tile.style.dataLabels.comparisonType === "PercentOfTotal" ? "normal" : "bold"};
                ">
                    ${showValue ? valueFmt(point.value) : ""}
                    ${tile.style.dataLabels.comparisonType === "PercentOfTotal" && showPercent ? `${percent}` : ""}
                </div>
            `;

            return `<div style="font-size: ${fontSize}px; text-align: center;">` + [firstLine, secondLine].filter((v) => v).join("") + "</div>";
        },
    };
};
