import React from "react";
import {cs} from "@common/react/chain-services";
import "./transformations-menu.scss";
import {keyed} from "@common/react/keyed";
import {transformationStepTypes} from "../../../../common/transformation-step-types";
import {DragWatcher} from "../../../../../common/drag-watcher";
import {getELoc} from "@common/react/get-e-loc";

export const TransformationsMenu = ({startDraggingNewStep, isAggregated}) =>
    cs(({}) => (
        <div className="transformations-menu-fdg">
            {transformationStepTypes
                .filter((s) => !isAggregated || s.allowOnAggMeasure)
                .map((step, i) =>
                    cs(
                        keyed(i),
                        [
                            "dragWatcher",
                            (_, next) =>
                                DragWatcher({
                                    onDragged: (e) => {
                                        startDraggingNewStep({
                                            ...step,
                                            position: getELoc(e),
                                        });
                                    },
                                    next,
                                }),
                        ],
                        ({dragWatcher}) => (
                            <div className="input-step" onMouseDown={dragWatcher.onMouseDown}>
                                <div className="icon">
                                    <img src={step.iconSrc} alt="" draggable={false} />
                                </div>
                                <div className="label">{step.name}</div>
                            </div>
                        )
                    )
                )}
        </div>
    ));
