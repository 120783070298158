const {getRange} = require("./get-range");
const {getGridStepOption} = require("./grid-step");
const {getOrientation} = require("../../common/style-map/orientation");
const {cGetFontSize} = require("../../common/style-map/font-size");
const {mapGridStyleToHC} = require("../dimension-axis/dimension-axis");

const getMeasurementAxesOptions = ({tile, theme, data, rawData, referenceSeries, isCompare, styleMeasurementAttr, measurementFormatters, chartElementTooltip}) => {
    const getFontSize = cGetFontSize(theme.general.canvas.fontSize, theme);
    const {axisCharts} = theme.dataVisualization;

    let ret = {};

    Object.keys(tile.style[styleMeasurementAttr]).forEach((stackKey) => {
        const series = data[stackKey].series;
        const style = tile.style[styleMeasurementAttr][stackKey];

        const constantOrAggregateValueLines = referenceSeries?.filter(
            (s) =>
                s.customStack === stackKey &&
                ["ConstantValueReferenceLine", "AggregateValueReferenceLine"].includes(s.customType) &&
                // when range min/max is set, only show lines having value within the range.
                (style.rangeCustomMin == null || s.plotLineOptions.value >= style.rangeCustomMin) &&
                (style.rangeCustomMax == null || s.plotLineOptions.value <= style.rangeCustomMax)
        );

        ret[stackKey] = {
            options: {
                title: {
                    text: style?.labelShown ? style.label || data[stackKey].label : undefined,
                    style: {
                        fontSize: getFontSize({
                            group: theme.dataVisualization.fonts.axisFontSize,
                            elemType: "label",
                        }),
                        color: theme.dataVisualization.fonts.fontColorRGB || theme.general.canvas.fontColorRGB,
                        fontWeight: theme.dataVisualization.fonts.fontWeight ?? null,
                        fontFamily: `"${theme.dataVisualization.fonts.fontFamily || theme.general.canvas.fontFamily}", sans-serif`,
                    },
                    // events: {
                    //     mouseover: function(e) {
                    //         chartElementTooltip.show(e, style.label || data[stackKey].label)
                    //     },
                    //     mouseout: function(e) {
                    //         chartElementTooltip.close()
                    //     },
                    // },
                },

                lineWidth: style.lineShown ? 1 : 0,
                lineColor: axisCharts.axisLineColorRGB,

                gridLineColor: axisCharts.gridStepLineColorRGB,
                gridLineDashStyle: mapGridStyleToHC[axisCharts.gridStepLineStyle],

                states: {
                    hover: {
                        lineWidthPlus: 0,
                    },
                },
                opposite: tile.$type === "HorizontalBarChartTile" ? style.location === "LeftTop" : style.location === "RightBottom",
                labels: {
                    enabled: !!style?.gridStepLabelsShown,
                    style: {
                        fontSize: getFontSize({
                            group: theme.dataVisualization.fonts.gridStepsFontSize,
                        }),
                        color: theme.dataVisualization.fonts.fontColorRGB || theme.general.canvas.fontColorRGB,
                        fontFamily: `"${theme.dataVisualization.fonts.fontFamily || theme.general.canvas.fontFamily}", sans-serif`,
                    },
                    ...(() => {
                        const orientation = style?.gridStepLabelOrientation;

                        if (!orientation) {
                            return {};
                        }

                        return orientation === "Auto"
                            ? {
                                  autoRotation: [0, -30, -45, -60, -90],
                              }
                            : {
                                  rotation: getOrientation(orientation),
                              };
                    })(),

                    formatter: function () {
                        if (constantOrAggregateValueLines?.length) {
                            const cal = constantOrAggregateValueLines.find((l) => l.plotLineOptions.value === this.value);
                            if (cal) {
                                return `<span style="color: ${cal.color}">${measurementFormatters[stackKey][0](this.value)}</span>`;
                            }
                        }

                        return measurementFormatters[stackKey][0](this.value);
                    },

                    // events: {
                    //     mouseover: function(e) {
                    //         chartElementTooltip.show(e, this.value)
                    //     },
                    //     mouseout: function(e) {
                    //         chartElementTooltip.close()
                    //     },
                    // },
                },
                className: `verb-tile-axis verb-tile-axis-${style.location.toLowerCase().includes("left") ? "left" : "right"} verb-tile-axis-measure`,

                gridLineWidth: style?.gridLinesShown ? 1 : 0,

                margin: 10, // If there are multiple axes on the same side of the chart, the pixel margin between the axes

                // don't do this, weird errors happen.
                // manually sort series by legendIndex in group-compare-bar-series.js, group-non-compare-bar-series.js, group-series-by-stack-line.js
                // reversedStacks: true,

                ...getGridStepOption({
                    series,
                    barDisplay: tile.style.displayType || tile.style.yAxisBarDisplayType,
                    gridStepType: style.gridStepType,
                    gridStepNumeric: style.gridStepNumeric,
                }),

                ...getRange({
                    series,
                    rawData,
                    stackKey,
                    barDisplay: tile.style.displayType || tile.style.yAxisBarDisplayType,
                    isCompare,
                    range: style.range,
                    rangeCustomMin: style.rangeCustomMin,
                    rangeCustomMax: style.rangeCustomMax,
                    gridStepType: style.gridStepType,
                    gridStepNumeric: style.gridStepNumeric,
                    constantOrAggregateValueLines,
                }),

                ...(constantOrAggregateValueLines?.length && {
                    plotLines: constantOrAggregateValueLines.map((s) => s.plotLineOptions),

                    // add constant or aggregate values to y-Axis line
                    tickPositioner: function () {
                        let ticks = this.tickPositions;
                        constantOrAggregateValueLines.forEach((l) => l.dataLabelShown && ticks.push(l.plotLineOptions.value));
                        ticks.sort((a, b) => a - b);
                        return ticks;
                    },
                }),
            },
        };
        if (style?.logarithmic) ret[stackKey].options.type = "logarithmic"; // Set conditionally, otherwise Highcharts renders text labels as numbers (index)
    });

    return ret;
};
exports.getMeasurementAxesOptions = getMeasurementAxesOptions;
