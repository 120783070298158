import * as React from "react";
import {cx} from "emotion";

import {isNil} from "@common/utils/common";
import {cache1} from "@common/utils/cache1";
import {cyrb53} from "@common/utils/strings";
import {getColorScale} from "@common/ui-components/charts/table/table/data-rows/conditional-format-helper";

import {cGetFontSize} from "../../../common/style-map/font-size";
import {TableBorder} from "../table-border/table-border";
import {cs} from "@common/react/chain-services";
import {keyed} from "@common/react/keyed";
import {StyledClass} from "@common/react/styled-class";

const defaultFormatter = (v) => v;

export const DataRows = ({data, theme, tile, columns, search, actionMenu, totalsShown}) => {
    // const getEmptyFillColor = (v) => v == null
    //     ? {background: tile.style.emptyFillColorRGB || theme.emptyFillColorRGB || tile.style.tableCellColorRGB || theme.tableCellColorRGB || "#EAEAEA"}
    //     : {}
    // ;

    const tablesStyle = theme.dataVisualization.tables;
    const colorPalettes = theme.dataVisualization.dataColorPalettes;

    const hasActions = actionMenu?.hasActions?.();
    const getRowClass = tablesStyle.cellAlternateType !== "AlternateRows" ? null : cGetAltBackground({tile, theme});
    const getColClass = tablesStyle.cellAlternateType !== "AlternateCols" ? null : cGetAltBackground({tile, theme});
    const colorScale = getColorScale({theme, rules: tile.style.conditionalFormattingRules});

    return data.rows.map((r, rowIndex) =>
        cs(
            keyed(`${rowIndex}_${cyrb53(JSON.stringify(tile.style.conditionalFormattingRules))}`),
            [
                "rowClass",
                (_, next) =>
                    StyledClass({
                        content: getRowClass?.(rowIndex) || {},
                        next,
                    }),
            ],
            ({rowClass}) => (
                <tr
                    className={rowClass}
                    style={{
                        height: tile.style.rowHeight ?? 32,
                    }}
                >
                    {r.columns.map((tileColumn, j) => {
                        const column = columns[j];
                        const {columnSettings = {}} = column;

                        const tableSearch = search.tableSearches.find((c) => c.columnIndex == j);

                        const searchText = tableSearch?.$type == "TextTableSearch" ? tableSearch?.search : null;
                        const formatterFunc = column?.formatter ?? defaultFormatter;
                        let columnText = tileColumn?.value == null ? "" : formatterFunc(tileColumn.value);

                        const hasComparisonValue = tile.style.showCompare && column.type == "number" && columnSettings.showComparisonValues;
                        if (searchText) {
                            const reg = new RegExp(searchText, "gi");
                            columnText = columnText?.toString().replace(reg, (match, p1) => `<span class="highlight">${match}</span>`);
                        }

                        const style = colorScale({
                            // rules: tile.style.conditionalFormattingRules,
                            // theme,
                            column: tileColumn,
                        });

                        const hashKey = `${rowIndex}_${j}_${cyrb53(JSON.stringify(style))}`;

                        return cs(
                            [
                                "colClass",
                                (_, next) =>
                                    StyledClass({
                                        content: getColClass?.(j) || {},
                                        next,
                                    }),
                            ],
                            [
                                "cellClass",
                                (_, next) =>
                                    StyledClass({
                                        content: getCellClass({
                                            tile,
                                            theme,
                                            hasActions:
                                                hasActions &&
                                                actionMenu.isFieldHasActions({
                                                    id: data.columns[j].tileFieldID,
                                                }),
                                        }),
                                        next,
                                    }),
                            ],
                            ({colClass, cellClass}) => (
                                <td
                                    key={hashKey}
                                    className={cx(cellClass, colClass, {
                                        "align-right": column.type === "number",
                                    })}
                                    style={style}
                                    onClick={(e) => {
                                        if (!hasActions) {
                                            return;
                                        }

                                        let fieldToValue = data.columns.reduce((obj, col, index) => {
                                            obj[col.tileFieldID] = r.columns[index];
                                            return obj;
                                        }, {});

                                        if (r.tileActionData) {
                                            r.tileActionData?.columns.forEach((c, index) => {
                                                fieldToValue[c.tileFieldID] = r.tileActionData.data[index];
                                            });
                                        }

                                        actionMenu.show({
                                            x: e.clientX,
                                            y: e.clientY,
                                            fieldName: data.columns[j].name,
                                            row: {
                                                name: columnText,
                                                rawData: r.columns.map((c, i) => {
                                                    const formatterFunc1 = columns[i]?.formatter ?? defaultFormatter;
                                                    return {
                                                        ...c,
                                                        formattedValue: c?.value == null ? "" : formatterFunc1(c.value),
                                                        tileFieldID: data.columns[i].tileFieldID,
                                                    };
                                                }),
                                                columns: data.columns,
                                            },
                                            fieldToValue,
                                        });
                                    }}
                                >
                                    <TableBorder
                                        isFirstRow={!tile.style.headerShown && rowIndex == 0}
                                        isFirstColumn={j == 0}
                                        isLastColumn={j == r.columns.length - 1}
                                        isLastRow={rowIndex == data.rows.length - 1}
                                        hideHorizontal={totalsShown && rowIndex == data.rows.length - 1}
                                    />

                                    {hasComparisonValue ? (
                                        ComparisonValueText({
                                            ...tileColumn,
                                            formatterFunc,
                                            columnSettings,
                                            colorPalettes,
                                        })
                                    ) : (
                                        <span dangerouslySetInnerHTML={{__html: columnText}} />
                                    )}
                                </td>
                            )
                        );
                    })}
                </tr>
            )
        )
    );
};

const getCellClass = ({tile, theme, hasActions = false}) => {
    const {general, dataVisualization} = theme;
    const getFontSize = cGetFontSize(general.canvas.fontSize, theme);
    const {cellAlternateType, cellBackgroundColorRGB, cellFontColorRGB} = dataVisualization.tables;
    return {
        fontSize: `${getFontSize({group: tile.style.rowFontSize})}px`,
        color: hasActions ? cellFontColorRGB ?? "#294661" : cellFontColorRGB ?? "#294661", // TODO: update the action color per dave's decision https://verbdata.slack.com/archives/C02S5NT5W6R/p1649899858230949
        ...(tile.style.allowWordWrap
            ? {
                  wordWrap: "break-word",
              }
            : {
                  overflow: "hidden",
                  whiteSpace: "noWrap",
                  // wordWrap: "normal",
              }),

        ...(cellAlternateType === "None" && {
            backgroundColor: cellBackgroundColorRGB || "#FFFFFF",
        }),

        padding: "5px",
        ...(hasActions && {
            cursor: "pointer",
            fontWeight: 500,
            color: theme.general.buttons.hyperlinkButton.fontColorRGB || "#0276F2",
            "&:hover": {
                color: theme.general.buttons.hyperlinkButton.hoverFontColorRGB || "#0294F2",
                textDecoration: "underline",
            },
        }),
        ...(tile.style.columnWidths === "EqualDist" && {
            width: `${Math.floor(100 / ((tile.aggregatedValues?.length || 0) + (tile.actualValueFields?.length || 0)))}%`,
        }),
    };
};

const cGetAltBackground = ({tile, theme}) => {
    const tablesStyle = theme.dataVisualization.tables;

    const c = cache1((i1) => {
        const getAltColor = (index) => ((index + 1) % 2 === 0 ? tablesStyle.cellAltCellColorRGB || "#EAEAEA" : tablesStyle.cellBackgroundColorRGB || "#FFFFFF");
        return {backgroundColor: getAltColor(i1)};
    });
    return (rowIndex) => c(rowIndex % 2);
};

const ComparisonValueText = ({change, difference, previousValue, value, formatterFunc, columnSettings, colorPalettes}) => {
    if (isNil(previousValue)) {
        return (
            <div className="comparison-value-h67">
                <span style={{marginRight: 10}}>{formatterFunc(value)}</span>
                <span>-</span>
            </div>
        );
    }

    const isGood = (columnSettings.positiveValueGood && difference > 0) || (!columnSettings.positiveValueGood && difference < 0);

    const diffColor = isGood ? colorPalettes.otherColors.conditionalGoodColorRGB || "#18C96E" : colorPalettes.otherColors.conditionalBadColorRGB || "#E95A5A";

    const usePercentage = columnSettings?.comparisonType == "Percent";

    return (
        <div className="comparison-value-h67">
            <span style={{marginRight: 10}}>{formatterFunc(value)}</span>
            <Triangle />
            <span className="value-change">{usePercentage ? `${(change * 100).toFixed(0)}%` : formatterFunc(difference)}</span>
            {difference > 0 ? ArrowUp({color: diffColor}) : ArrowDown({color: diffColor})}
        </div>
    );
};

export const Triangle = ({size = 8}) => (
    <svg className="triangle" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 8 8" fill="none">
        <path d="M7.59549 7.75H0.404508L4 0.559017L7.59549 7.75Z" stroke="#294661" strokeWidth="0.5" />
    </svg>
);

export const ArrowUp = ({color, size = 12}) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={size} height={size}>
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M13 12v8h-2v-8H4l8-8 8 8z" fill={color} />
    </svg>
);

export const ArrowDown = ({color, size = 12}) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={size} height={size}>
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M13 12h7l-8 8-8-8h7V4h2z" fill={color} />
    </svg>
);
