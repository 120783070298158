import "./edit-tile-layout.scss";
import {cs} from "@common/react/chain-services";
import {consumeContext, provideContext} from "@common/react/context";
import {TruncatingTooltip} from "../../../common/truncating-tooltip/truncating-tooltip";
import {FixedPopupMenu} from "../../../common/fixed-popup-menu/fixed-popup-menu";
import {cx} from "emotion";
import {SelectViewProfile} from "../../preview/select-view-profile/select-view-profile";
import {Badge} from "../../../common/badge/badge";
import {RefreshIcon, RefreshOffIcon} from "../../edit/layout/edit-collection-layout";
import {SettingIcon} from "../../edit/layout/theme-select/theme-select-icons";
import {Dropdown} from "@common/ui-components/dropdown/dropdown";
import {ButtonIconWithTooltip} from "@common/form/buttons/button-icon/button-icon";
import {OnOffToggle} from "@common/form/toggles/on-off-toggle";
import {spc} from "@common/react/state-path-change";
import {Button} from "@common/form/buttons/button/button";
import {SuspendUpdate} from "@common/react/suspend-update";
import * as React from "react";
import {LayoutContent, SyncState} from "../../../common/layout2/layout-2";
import {UseState} from "@common/react/use-state";

export const EditTileLayout = ({
    back,
    collection,
    labelText,
    leftPanel,
    main,
    syncState,
    rightPanel,
    onPublish,
    className,
    editing = null,
    adding = null,
    isSharedTile,
    interactions,
}) =>
    cs(
        consumeContext("autoRefreshProvider"),
        consumeContext("viewAsContext"),
        consumeContext("dataSummary"),
        ["truncatingTooltip", (_, next) => TruncatingTooltip({direction: "below", next})],
        [
            "popupMenu",
            ({}, next) =>
                FixedPopupMenu({
                    next,
                    getCommands: () => editing?.commands,
                }),
        ],
        ({autoRefreshProvider, viewAsContext, dataSummary, truncatingTooltip, popupMenu, layoutLeftPanelWidth}) => {
            const {folders, iframeKeys, viewAs} = viewAsContext || {};

            const {autoDataRefresh} = collection?.value ?? {};
            const isEnabledAutoRefresh = autoDataRefresh == null ? true : autoDataRefresh;

            return (
                <div className={cx("edit-tile-layout-hy4", className, isSharedTile && "shared-tile")}>
                    {isSharedTile && (
                        <div className="shared-tile-badge" style={{zIndex: 6}}>
                            Editing Shared Tile
                        </div>
                    )}
                    <div className="header">
                        <div className="left">
                            {editing && (
                                <div className="mode">
                                    <div className="label" ref={truncatingTooltip.ref}>
                                        {editing?.title && <div className="editing-title">{editing.title}</div>}
                                        <span {...truncatingTooltip?.tooltip?.(editing.label)}>{editing.label}</span>
                                        {editing?.commands && popupMenu.render()}
                                    </div>
                                    <div className="separator" />
                                    {(iframeKeys?.value || folders?.value) &&
                                        cs(() => {
                                            const keys = iframeKeys.value?.filter((k) => k.collections.indexOf(collection?.value?.id) > -1);
                                            if (keys?.length === 0 && folders.value?.length == 0) return null;
                                            return SelectViewProfile({
                                                keys,
                                                viewAs,
                                                folders,
                                            });
                                        })}
                                </div>
                            )}

                            {adding && (
                                <div className="back-button">
                                    <div className="arrow-btn" onClick={back.onClick}>
                                        <i className="far fa-arrow-left" />
                                    </div>
                                    {adding.label}
                                </div>
                            )}

                            {labelText && <div className="label-text">{labelText}</div>}
                        </div>

                        <div className="right">
                            {editing && (
                                <>
                                    {Badge({
                                        size: "lg",
                                        type: "default",
                                        icon: isEnabledAutoRefresh ? <RefreshIcon /> : <RefreshOffIcon />,
                                        tooltipClassName: "tooltip-white-f3z",
                                        tooltip: isEnabledAutoRefresh ? (
                                            <>
                                                <b>"Auto Data Refresh"</b> enabled. <br />
                                                View Configuration in <SettingIcon /> <b>Settings</b>.
                                            </>
                                        ) : (
                                            <>
                                                <b>"Auto Data Refresh"</b> disabled to <br />
                                                optimize performance. View <br />
                                                Configuration in <SettingIcon /> <b>Settings</b>.
                                            </>
                                        ),
                                    })}

                                    <SyncState state={syncState} />

                                    <div className="group-buttons">
                                        {Dropdown({
                                            registryRender: true,
                                            forcedExpandLeft: true,
                                            expandClassNames: "setting-popup-bt4",
                                            expandDistance: 5,
                                            renderToggle: ({showExpand, showingExpand}) => (
                                                <ButtonIconWithTooltip
                                                    tooltipClassName="tooltip-white-f3z small"
                                                    content="Settings"
                                                    icon={<SettingIcon />}
                                                    className="btn"
                                                    size="medium"
                                                    btnType="ghost-no-border"
                                                    onClick={() => showExpand()}
                                                />
                                            ),
                                            renderExpand: () => {
                                                return (
                                                    <>
                                                        <div className="popup-header">Settings</div>

                                                        <div className="popup-content">
                                                            <div className="auto-refresh-toggle">
                                                                {OnOffToggle({
                                                                    state: {
                                                                        value: isEnabledAutoRefresh,
                                                                        onChange: (value) => {
                                                                            spc(collection, ["autoDataRefresh"], () => (value ? (dataSummary.isLessThan1GB ? null : true) : false));
                                                                        },
                                                                    },
                                                                })}

                                                                <div>Auto Data Refresh</div>
                                                            </div>

                                                            <div className="text">
                                                                <i className="material-icons">info</i>
                                                                <div>
                                                                    To optimize performance during collection building, <b>"Auto Data Refresh"</b> automatically disables when your
                                                                    environment contains a large amount of data.
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                );
                                            },
                                        })}

                                        <ButtonIconWithTooltip
                                            tooltipClassName="tooltip-white-f3z small"
                                            content="Refresh Data"
                                            icon={<RefreshIcon />}
                                            className="btn"
                                            size="medium2"
                                            btnType="ghost-no-border"
                                            onClick={() => autoRefreshProvider.refresh()}
                                        />
                                    </div>

                                    <div className="group-buttons">
                                        {isSharedTile && (
                                            <Button size="medium" btnType="secondary" className="btn btn-publish" onClick={() => interactions.onChange({name: "view-collections"})}>
                                                View Collections
                                            </Button>
                                        )}

                                        {onPublish && (
                                            <Button btnType="secondary" size="medium" className="btn btn-publish" onClick={onPublish}>
                                                Publish
                                            </Button>
                                        )}

                                        <Button btnType="primary" size="medium" className="btn" onClick={back.onClick}>
                                            Done
                                        </Button>
                                    </div>
                                </>
                            )}

                            {adding?.button && (
                                <div className="group-buttons adding">
                                    <Button className="btn" onClick={adding.button.click}>
                                        {adding.button.label}
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>

                    <LayoutContent
                        {...{
                            main,
                            leftPanel,
                        }}
                    />

                    {rightPanel && (
                        <div
                            className={cx("right-panel", {
                                active: !!rightPanel?.content,
                            })}
                        >
                            {cs(
                                ({}, next) =>
                                    SuspendUpdate({
                                        active: !rightPanel?.content,
                                        delay: 500,
                                        next,
                                    }),
                                () => rightPanel?.content
                            )}
                        </div>
                    )}
                </div>
            );
        }
    );
