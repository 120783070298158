import React from "react";

const TableIcon = ({className, fill = "#919EB0"}) => (
    <svg className={className} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10 7V11H16V7H10ZM8 7H2V11H8V7ZM10 16H16V13H10V16ZM8 16V13H2V16H8ZM10 2V5H16V2H10ZM8 2H2V5H8V2ZM1 0H17C17.2652 0 17.5196 0.105357 17.7071 0.292893C17.8946 0.48043 18 0.734784 18 1V17C18 17.2652 17.8946 17.5196 17.7071 17.7071C17.5196 17.8946 17.2652 18 17 18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0Z"
            fill={fill}
        />
    </svg>
);

export default TableIcon;
