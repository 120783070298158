const {cache0} = require("../../common/utils/cache0");
const {isTrue} = require("./is-true");

const isDevQuan = cache0(() => {
    try {
        return isTrue(localStorage.getItem("DEV_QUAN"));
    } catch (e) {
        return false;
    }
});
exports.isDevQuan = isDevQuan;
