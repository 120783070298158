import "./more-filter-dialog.scss";

import React from "react";
import {css, cx} from "emotion";
import moment from "moment-timezone";

import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";

import {getCurrentTzName} from "../../../../../../../utils/dates/date-object";
import {VerbDialogBodyScrollbar} from "../../../../../../verb-scrollbar/verb-dialog-body-scrollbar";
import {DialogService} from "../../../../../../../../web-client/src/routes/common/dialog/dialog-service";
import {SingleSelectDropdown} from "../../../filter-types/text/selectable-list/single-select-dropdown/single-select-dropdown";
import {FilterLabel} from "../../common/label/filter-label";
import {getCollectionFilters} from "@common/ui-components/live/live-dashboard/live-filters/get-collection-filters";
import {FilterForms} from "@common/ui-components/live/live-dashboard/data/filter-forms/filter-forms";
import {sort} from "@common/utils/collections";
import {StyledClass} from "@common/react/styled-class";

export const MoreFilterDialog = ({
    next: rootNext,
    filtersConfig,
    noFilter,
    filters,
    filterForms,
    runReportButton,
    renderFilter,
    showTimezoneDropdownInModal = false,
    size,
}) =>
    cs(
        consumeContext("theme"),
        [
            "dialogCss",
            ({theme}, next) =>
                StyledClass({
                    content: getCssDialog(theme),
                    next,
                }),
        ],
        [
            "modal",
            ({collection, dialogCss}, next) =>
                DialogService({
                    render: ({resolve, args: {customWindowWidth, forceToShowTimezoneDropdown = false, visibleFilters = []} = {}}) => ({
                        title: "Filters",
                        ...(window.innerWidth > 768 ? {width: customWindowWidth || 560} : {width: "100%"}),
                        content: next({
                            resolve,
                            forceToShowTimezoneDropdown,
                            visibleFilters,
                        }),
                    }),
                    next: rootNext,
                    // className: dialogCss,
                }),
        ],
        consumeContext("selectedTimezone"),
        //prevent set local in root filterForms
        [
            "localFilterForms",
            (_, next) =>
                FilterForms({
                    filters: filtersConfig.collection.value.filters,
                    filterVals: filterForms.getAllFilterValues(),
                    next,
                }),
        ],
        [
            "localCollectionFilters",
            ({localFilterForms}, next) =>
                getCollectionFilters({
                    filtersConfig,
                    filterStyle: {
                        runType: "OnSubmit",
                    },
                    filterForms: localFilterForms,
                    next,
                }),
        ],
        ({modal, selectedTimezone, theme, localCollectionFilters, localFilterForms}) =>
            cs(() => {
                const localTimezone = getCurrentTzName();
                const secondaryButtonTheme = theme.general.buttons.secondaryButton;
                const primaryButtonTheme = theme.general.buttons.primaryButton;

                const isSelected = (item) => item.value === selectedTimezone.value;

                const filters1 = sort(
                    localCollectionFilters
                        .map((filter) => ({
                            ...filter,
                            lines: filter.control({size}),
                            priority: ["Hidden", "BehindMore", "Visible"].findIndex((v) => v === filter.displayArea),
                        }))
                        .filter((filter) => modal.visibleFilters.findIndex((v) => v.id === filter.id) === -1)
                        .filter((filter) => {
                            const isHiddenFilter = noFilter ? true : filter.displayArea === "Hidden";
                            return !(filtersConfig.sdkDashboard && isHiddenFilter);
                        }),
                    (v) => -v.priority
                );

                return (
                    <div
                        className="more-filter-dialog-a33"
                        style={{
                            background: `${theme.general.tile.styles.tileBackgroundColorRGB}`,
                        }}
                    >
                        <VerbDialogBodyScrollbar>
                            {filters1.map(renderFilter)}

                            {(showTimezoneDropdownInModal || modal.forceToShowTimezoneDropdown) && (
                                <div
                                    className={cx("filter", `verb-filter-timezone`)}
                                    style={{
                                        fontFamily: `"${theme.general.canvas.fontFamily || "Roboto"}", sans-serif`,
                                        color: theme.general.canvas.fontColorRGB || "#294661",
                                    }}
                                >
                                    <div className="label verb-filter-label">{FilterLabel({text: "Time Zone"})}</div>

                                    <div className="control">
                                        <div className="line">
                                            {SingleSelectDropdown({
                                                data: [
                                                    {
                                                        value: null,
                                                        label: "UTC",
                                                    },
                                                    {
                                                        value: localTimezone,
                                                        label: `Local (${moment.tz.zone(localTimezone).abbr(Date.now())})`,
                                                    },
                                                    ...moment.tz.names().map((zone) => ({
                                                        value: zone,
                                                        label: zone,
                                                    })),
                                                ],
                                                valueToLabel: (item, isToggle) =>
                                                    !isToggle && isSelected(item) ? (
                                                        <>
                                                            <i
                                                                className={cx("far fa-check")}
                                                                style={{
                                                                    "font-size": "16px",
                                                                    position: "absolute",
                                                                    right: "24px",
                                                                    top: "17px",
                                                                    color: "#18c96e",
                                                                }}
                                                            />{" "}
                                                            {item.label}
                                                        </>
                                                    ) : (
                                                        item.label
                                                    ),
                                                valueToSearch: (item) => item.label,
                                                isSelected,
                                                // onChange: item => selectedTimezone.onChange(item.value),
                                                state: {
                                                    change: (reducer) => {
                                                        const [item] = reducer();
                                                        selectedTimezone.onChange(item.value);
                                                    },
                                                },
                                            })}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </VerbDialogBodyScrollbar>

                        <div className="dialog-footer">
                            {(() =>
                                cs(
                                    [
                                        "btnCss",
                                        (_, next) =>
                                            StyledClass({
                                                content: getCss(secondaryButtonTheme, theme),
                                                next,
                                            }),
                                    ],
                                    ({btnCss}) => (
                                        <button
                                            className={cx("btn-clear", btnCss)}
                                            onClick={() => {
                                                localFilterForms.resetToDefault();
                                            }}
                                        >
                                            Clear All
                                        </button>
                                    )
                                ))()}
                            <div className="run-report-btn">
                                {runReportButton
                                    ? runReportButton({
                                          onClick: () => {
                                              let allFilterValues = localFilterForms.getAllFilterValues();
                                              Object.keys(allFilterValues).forEach((key) => {
                                                  filterForms.setLiveValue(key, allFilterValues[key]);
                                              });

                                              modal.resolve();
                                          },
                                      })
                                    : cs(
                                          [
                                              "btnCss",
                                              (_, next) =>
                                                  StyledClass({
                                                      content: getCss(primaryButtonTheme, theme),
                                                      next,
                                                  }),
                                          ],
                                          ({btnCss}) => (
                                              <button
                                                  className={cx("" + "run-report-btn-3qo", "verb-filter-run-button", btnCss)}
                                                  onClick={() => {
                                                      let allFilterValues = localFilterForms.getAllFilterValues();
                                                      Object.keys(allFilterValues).forEach((key) => {
                                                          filterForms.setLiveValue(key, allFilterValues[key]);
                                                      });
                                                      modal.resolve();
                                                  }}
                                              >
                                                  Apply
                                              </button>
                                          )
                                      )}
                            </div>
                        </div>
                    </div>
                );
            })
    );

const getCss = (selectedTheme, theme) => ({
    background: `${selectedTheme.backgroundColorRGB}`,
    color: `${selectedTheme.fontColorRGB}`,
    border: `${selectedTheme.borderWidth}px solid ${selectedTheme.borderColorRGB}`,
    "border-radius": `${selectedTheme.cornerRadius}px`,
    "font-family": `"${theme.general.canvas.fontFamily}", sans-serif`,

    "&:hover": {
        background: `${selectedTheme.hoverBackgroundColorRGB}`,
        color: `${selectedTheme.hoverFontColorRGB}`,
        "border-color": `${selectedTheme.hoverBorderColorRGB}`,
    },
});

export const getCssDialog = (theme) => ({
    ".dialog-header": {
        background: `${theme.general.tile.styles.tileBackgroundColorRGB} !important`,
        color: `${theme.general.tile.styles.titleFontColorRGB} !important`,
        "font-family": `${theme.general.tile.styles.titleFontFamily || theme.general.canvas.fontFamily}, sans-serif !important`,
        "border-color": `${theme.general.tile.styles.tileBorderColorRGB} !important`,
        "box-shadow": "none !important",
        "border-bottom": `1px solid ${theme.general.components.inputIconColorRGB} !important`,

        ".close": {
            svg: {
                path: {
                    fill: `${theme.general.components.inputIconColorRGB}`,
                },
            },
        },
    },

    ".dialog-footer": {
        "border-color": `${theme.general.components.inputIconColorRGB}`,
    },
});
