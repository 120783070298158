import "./tile-fields.scss";

import * as React from "react";

import {cs} from "@common/react/chain-services";
import {provideContext} from "@common/react/context";
import {scope} from "@common/react/scope";

import {Expandable} from "../../../../../../../common/expandable/expandable";

import {getTileActionsUpdated} from "../actions/get-tile-actions-updated";
import {getTileFields} from "./common/get-tile-fields";
import {getLimitUpdatedWithMultipleAgg} from "./common/limit-sorting/limit/get-limit-updated";
import {Limit} from "./common/limit-sorting/limit/limit";
import {getSortUpdatedWithMultipleAgg} from "./common/limit-sorting/sorting/get-sort-updated";
import {TableSorting} from "./common/limit-sorting/sorting/table/table-sorting";
import {MultiFieldWell} from "./common/multi-field-well/multi-field-well";
import {MultipleFieldGroup} from "./common/multi-field-well/multiple-field-group";

export const DownloadReportFields = ({tile, isSubTile, debugFunction, form, next}) =>
    cs(
        [
            "tileFields",
            ({}, next) =>
                next(
                    getTileFields({
                        tile: tile.value,
                        measureMultiAttr: ["actualValueFields", "aggregatedValues"],
                    })
                ),
        ],
        ({tileFields}, next) => provideContext("tileFields", tileFields, next),

        [
            "onRemoveField",
            (_, next) =>
                next((attr, field) =>
                    tile.onChange({
                        ...tile.value,
                        [attr]: tile.value[attr].filter((v) => v.id != field.id),
                        ...getSortUpdatedWithMultipleAgg({
                            tile,
                            oriVal: field,
                            allFields: [...(tile.value.aggregatedValues || []), ...(tile.value.actualValueFields || [])],
                        }),
                        ...getLimitUpdatedWithMultipleAgg({
                            tile,
                            oriVal: field,
                            allFields: [...(tile.value.aggregatedValues || []), ...(tile.value.actualValueFields || [])],
                        }),
                        ...getTileActionsUpdated({
                            tile,
                            fieldVal: {newVal: null, oriVal: field},
                        }),
                    })
                ),
        ],

        [
            "actualValueFields",
            ({onRemoveField, tileFields}, next) =>
                MultiFieldWell({
                    fields: scope(tile, ["actualValueFields"]),

                    $type: "CategoryTileField",
                    label: "Actual Value",
                    onRemove: (field) => onRemoveField("actualValueFields", field),
                    dataKey: "actualValueFields",
                    next,
                    notAllowNoneAggregation: true,
                    allowSameFieldAndAggregation: false,
                    notAllowedFields: tileFields.allFields,
                }),
        ],
        [
            "aggregatedValues",
            ({onRemoveField}, next) =>
                MultiFieldWell({
                    fields: scope(tile, ["aggregatedValues"]),
                    $type: "MeasureTileField",
                    label: "Aggregated",
                    notAllowNoneAggregation: true,
                    onRemove: (field) => onRemoveField("aggregatedValues", field),
                    dataKey: "aggregatedValues",
                    next,
                    allowSameFieldAndAggregation: true,
                    notAllowedFields: tile.value.actualValueFields,
                }),
        ],
        [
            "multipleFieldsGroup",
            ({actualValueFields, aggregatedValues}, next) =>
                MultipleFieldGroup({
                    group: [actualValueFields, aggregatedValues],
                    allowDragToAnotherGroup: false,
                    tile,
                    next,
                }),
        ],
        [
            "fieldsSection",
            ({aggregatedValues, actualValueFields, multipleFieldsGroup}, next) =>
                next({
                    render: () =>
                        Expandable({
                            label: "Fields",
                            render: () => multipleFieldsGroup.render(),
                        }),
                    override: aggregatedValues.override || actualValueFields.override,
                }),
        ],
        [
            "limitSorting",
            ({tileFields}, next) =>
                cs(
                    [
                        "limit",
                        (_, next) => {
                            const fieldState = form?.field(["limit"]).state || scope(tile, ["limit"]);
                            return next(
                                Limit({
                                    field: fieldState,
                                    isHiddenRankOption: () => false,
                                    showOther: false,
                                    allFields: [...(tile.value.aggregatedValues || []), ...(tile.value.actualValueFields || [])],
                                })
                            );
                        },
                    ],
                    [
                        "sorting",
                        (_, next) => {
                            const fieldGroupsState = form?.field(["sort"]).state || scope(tile, ["sort"]);
                            return next(
                                TableSorting({
                                    fieldGroups: fieldGroupsState,
                                    allFields: [...(tile.value.aggregatedValues || []), ...(tile.value.actualValueFields || [])],
                                })
                            );
                        },
                    ],
                    ({limit, sorting}) =>
                        next({
                            render: () =>
                                Expandable({
                                    initExpand: false,
                                    label: "Limit & Sorting",
                                    render: () => (
                                        <>
                                            {limit}
                                            {sorting}
                                        </>
                                    ),
                                }),
                        })
                ),
        ],

        ({limitSorting, fieldsSection}) =>
            next({
                render: () => (
                    <div className="tile-fields-3vr">
                        {fieldsSection.render()}
                        {limitSorting.render()}
                        {isSubTile && debugFunction?.render?.({})}
                    </div>
                ),
                override: fieldsSection.override || debugFunction?.override(),
                overrideAll: debugFunction?.overrideAll,
            })
    );
