import "./right-panel.scss";

import React from "react";

import {cx} from "emotion";
import {cs} from "@common/react/chain-services";
import {LeftPanelClosable} from "../../../../common/left-panel-closable/left-panel-closable";
import {scc} from "@common/react/state-cascade-change";
import {getStepPanel} from "./get-step-panel";
import {TabsHeader} from "../../../../common/tabs/tabs-header";
import {keyed} from "@common/react/keyed";
import {scope} from "@common/react/scope";
import {EditableLabel} from "./editable-label/editable-label";
import {Form2} from "@common/react/cs-form/form2";
import {FixedPopupMenu} from "../../../../common/fixed-popup-menu/fixed-popup-menu";
import {TrashIconBold} from "../../../../common/icons/trash-icon";
import {isAggregatedMeasure, isDataView} from "../../common/transformation-utils";
import {Button} from "@common/form/buttons/button/button";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";
import {UseMemo} from "@common/react/use-memo";
import {IgnoreUpdate} from "@common/react/ignore-update";

export const cRightPanel = ({
    tablesColumnsMap,
    transformation,
    model,
    next: rootNext,
    onDeleteStep,
    interactions: {onCancel, togglePreview, setting, openRightPanel, openRightPanelTab},
}) =>
    cs(
        (_, next) => (!transformation.value || !setting?.data?.stepId ? rootNext() : next()),
        (_, next) =>
            IgnoreUpdate({
                props: {transformation, setting},
                when: (pp) => JSON.stringify(pp.transformation.value) === JSON.stringify(transformation.value) && JSON.stringify(pp.setting) === JSON.stringify(setting),
                next,
            }),
        [
            "stepPanel",
            (_, next) =>
                UseMemo({
                    fn: () => {
                        const step = transformation.value?.steps?.find((s) => s.id === setting?.data.stepId);
                        return getStepPanel(step.$type);
                    },
                    deps: [setting?.data.stepId],
                    next,
                }),
        ],
        ({stepPanel}) => {
            const step = {
                value: transformation.value?.steps?.find((s) => s.id === setting.data.stepId),
                onChange: (newStep, ...other) =>
                    scc(
                        transformation,
                        "steps[*]",
                        (step) => {
                            if (step.id === setting.data.stepId) {
                                return newStep;
                            }
                            return step;
                        },
                        ...other
                    ),
                change: (change, ...other) =>
                    scc(
                        transformation,
                        "steps[*]",
                        (step) => {
                            if (step.id === setting.data.stepId) {
                                return change(step);
                            }
                            return step;
                        },
                        ...other
                    ),
            };

            const ConfigurationScreen = () =>
                cs(
                    [
                        "form",
                        (_, next) =>
                            Form2({
                                data: step,
                                remoteErrors: step.value.errors?.map((e) => ({
                                    ...e,
                                    error: errorTranslates[e.error],
                                })),
                                next,
                            }),
                    ],
                    ["tabs", ({selected}, next) => next([{key: "configuration"}, {key: "preview"}])],
                    ({tabs, form}) => (
                        <div className="transformation-right-panel-24t">
                            <div className="tabs-header-wrapper">
                                {TabsHeader({
                                    tabs: tabs.map((tab) => ({
                                        ...tab,
                                        onClick: () => openRightPanelTab(tab.key),
                                        active: setting.tab === tab.key,
                                        label: tab.key,
                                    })),
                                })}
                            </div>
                            <div className={cx("main", setting.tab)}>
                                <div className="tab-content">
                                    {cs(keyed(setting.tab), ({}) =>
                                        stepPanel.tabs[setting.tab]({
                                            transformation,
                                            step,
                                            form,
                                            model,
                                            setting,
                                            togglePreview,
                                            openRightPanel,
                                            tablesColumnsMap,
                                        })
                                    )}
                                </div>
                            </div>
                        </div>
                    )
                );

            const WarningScreen = () => (
                <div className="warning-screen-53s">
                    <div className="icon">
                        <img src={require("../../common/icons/warning-step-icon.svg")} alt="" />
                    </div>
                    <div className="title">
                        {step.value.status
                            .split(",")[0]
                            .split(/(?=[A-Z])/)
                            .join(" ")}
                    </div>

                    {(() => {
                        if (step.value.stepCausingErrorID && step.value.stepCausingErrorID !== step.value.id) {
                            const invalidStep = transformation.value?.steps?.find((s) => s.id === step.value.stepCausingErrorID);
                            const warningText = `The ${invalidStep.name} step is invalid. Please review and correct the configuration issues to configure this step.`;
                            return (
                                <>
                                    <div className="text">{warningText}</div>
                                    <div
                                        className="redirect-btn"
                                        onClick={() =>
                                            openRightPanel({
                                                stepId: invalidStep.id,
                                            })
                                        }
                                    >
                                        <Button btnType="secondary">Go To Invalid Step</Button>
                                    </div>
                                </>
                            );
                        }
                        return (
                            <div className="text">
                                {["AppendRowsStep", "JoinDataStep"].includes(step.value.$type)
                                    ? "This step requires 2 inputs to configure."
                                    : "In order to configure this transformation step, you must connect a valid input."}
                            </div>
                        );
                    })()}
                </div>
            );

            const isAggregated = isAggregatedMeasure(transformation.value);

            return rootNext(
                cs(
                    keyed(setting.data.stepId),
                    [
                        "transformationCM",
                        ({}, next) =>
                            FixedPopupMenu({
                                position: {top: `20px`},
                                getCommands: ({onEdit}) => [
                                    {
                                        label: (
                                            <>
                                                <i className="fal fa-edit" /> <span className="text">Rename</span>
                                            </>
                                        ),
                                        onClick: onEdit,
                                    },
                                    ...(!step.value.$type.includes("OutputStep") && step.value.$type !== "AggregatedModelTableDatasetStep"
                                        ? [
                                              {
                                                  className: "delete-action",
                                                  label: (
                                                      <>
                                                          {TrashIconBold()} <span className="text">Delete</span>
                                                      </>
                                                  ),
                                                  onClick: onDeleteStep,
                                              },
                                          ]
                                        : []),
                                ],
                                next,
                            }),
                    ],
                    ({transformationCM}, next) => (
                        <LeftPanelClosable
                            {...{
                                onClose: onCancel,
                                label: (
                                    <div className="step-name-64h">
                                        <div className="step-icon">
                                            <img src={stepPanel.getIconSrc ? stepPanel.getIconSrc(isAggregated) : stepPanel.iconSrc} alt="" />
                                        </div>
                                        {["ViewOutputStep", "ColumnOutputStep"].includes(step.value.$type) ? (
                                            <div
                                                className={cx("output-step-name", {
                                                    view: step.value.$type === "ViewOutputStep",
                                                })}
                                            >
                                                {isDataView(transformation.value) ? "New View" : isAggregated ? "New Measure" : "New Column"}
                                            </div>
                                        ) : (
                                            <>
                                                {EditableLabel({
                                                    label: scope(step, ["name"]),
                                                    action: transformationCM,
                                                })}
                                            </>
                                        )}
                                    </div>
                                ),
                                content: next(),
                            }}
                        />
                    ),
                    ({}) => {
                        const twoInputStepValid = (() => {
                            if (["AppendRowsStep", "JoinDataStep"].includes(step.value.$type)) {
                                return step.value.inputStep1ID !== step.value.inputStep2ID;
                            }
                            return true;
                        })();

                        const canConfig =
                            (!step.value.status || step.value.status.split(", ").indexOf("InvalidInput") === -1) && !step.value.stepCausingErrorID && twoInputStepValid;
                        if (canConfig) {
                            return ConfigurationScreen();
                        }
                        return WarningScreen();
                    }
                )
            );
        }
    );

const errorTranslates = {
    Missing: "Required",
    Invalid: "Invalid",
    DataType: "Data type not valid",
    DuplicateValue: "Value already exists",
};
