import React from "react";
import {ShowHideToggle} from "../../../../../../../../../../../../common/form/toggles/show-hide-toggle";
import {scope} from "@common/react/scope";
import {cs} from "@common/react/chain-services";
import "./other-options.scss";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {getPath, setPath} from "@common/utils/arr-path";
import {StaticTooltipService} from "../../../../../../../../../common/tooltip3/static-tooltip-service";
import {LpShowHideLine} from "../../../../../../../../../common/left-panel/lp-show-hide-line";

export const OtherOptions = ({tile}) => ({
    label: "Other Options",
    render: () => {
        const isPivotTable = tile.value.$type == "PivotTableTile";
        return (
            <div className="other-options-v34">
                {!["PivotTableTile", "GaugeTile", "BoxPlotTile"].includes(tile.value.$type) && (
                    <div className="subsection">
                        {ComparisonDateRange({tile})}
                    </div>
                )}
                <div className="subsection">
                    {EndUserDataExport({tile})}
                </div>

                {!isPivotTable && <div className="subsection">{EndUserImageDownload({tile})}</div>}

                {isPivotTable && (
                    <div className="subsection">
                        <div className="header">
                            Maximize Tile View
                            <div className="control">
                                {ShowHideToggle({
                                    state: {
                                        value: getPath(tile.value, ["style", "maximizeTile"]),
                                        onChange: (v) => tile.onChange(setPath(tile.value, ["style", "maximizeTile"], v)),
                                    },
                                    defaultValue: false,
                                })}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    },
});

export const ComparisonDateRange = ({tile}) => {
    return cs(
        [
            "staticTooltip",
            (_, next) =>
                StaticTooltipService({
                    direction: "top",
                    info: "When a dashboard level date field filter is applied with a comparison range, the comparison data can be hidden.",
                    tooltipBoxWidth: 200,
                    topOffset: 20,
                    leftOffset: -5,
                    next,
                }),
        ],
        ({staticTooltip}) => (
            <div className="header">
                Comparison Date Range
                {staticTooltip.renderIcon({
                    icon: <i className="material-icons">info</i>,
                    className: "info-tooltip",
                })}
                <div className="control">
                    {ShowHideToggle({
                        state: scope(tile, ["style", "showCompare"]),
                        defaultValue: true,
                    })}
                </div>
            </div>
        )
    );
};

export const EndUserDataExport = ({tile}) => {
    return cs(
        [
            "staticTooltip",
            (_, next) =>
                StaticTooltipService({
                    direction: "top",
                    info: "End-User Data Export allows your users to export the data for this tile. The export will apply the current filter set.",
                    tooltipBoxWidth: 200,
                    topOffset: 20,
                    leftOffset: -5,
                    next,
                }),
        ],
        ({staticTooltip}) => (
            <>
                <div className="header">
                    End-User Data Export
                    {staticTooltip.renderIcon({
                        icon: <i className="material-icons">info</i>,
                        className: "info-tooltip",
                    })}
                    <div className="control">
                        {ShowHideToggle({
                            state: {
                                value: getPath(tile.value, ["style", "showDownloadData"]),
                                onChange: (v) => {
                                    let newTile = setPath(tile.value, ["style", "showDownloadData"], v);
                                    if (v && !getPath(newTile, ["style", "downloadFormat"])) {
                                        newTile = setPath(newTile, ["style", "downloadFormat"], "XLSX");
                                    }

                                    tile.onChange(newTile);
                                },
                            },
                            defaultValue: false,
                        })}
                    </div>
                </div>
                {getPath(tile.value, ["style", "showDownloadData"]) && <div className="content">{FileType({tile})}</div>}
            </>
        )
    );
};

export const EndUserImageDownload = ({tile}) =>
    cs(
        [
            "staticTooltip",
            (_, next) =>
                StaticTooltipService({
                    direction: "top",
                    info: "End-User Image Download allows your users to export a PNG of the chart in this tile. The image will apply the current view and filter set.",
                    topOffset: 20,
                    leftOffset: -5,
                    next,
                }),
        ],
        ({staticTooltip}) => (
            <div className="header">
                End-User Image Download
                {staticTooltip.renderIcon({
                    icon: <i className="material-icons">info</i>,
                    className: "info-tooltip",
                })}
                <div className="control">
                    {ShowHideToggle({
                        state: {
                            value: getPath(tile.value, ["style", "showDownloadImage"]),
                            onChange: (v) => tile.onChange(setPath(tile.value, ["style", "showDownloadImage"], v)),
                        },
                        defaultValue: false,
                    })}
                </div>
            </div>
        )
    );

export const FileType = ({tile}) => {
    const state = scope(tile, ["style", "downloadFormat"]);
    const downloadFormats = [
        {
            value: "XLSX",
            label: "XLSX",
            condition: () => true,
        },
        {
            value: "CSV",
            label: "CSV",
            condition: () => true,
        },
        {
            value: "JSON",
            label: "JSON",
            condition: () => tile.value?.$type != "PivotTableTile",
        },
    ];

    return DropdownSelect({
        label: "File Type",
        list: downloadFormats.filter((item) => item.condition()),
        valueToLabel: (v) => v.label,
        isSelected: (v) => v.value === state.value,
        onChange: (v) => state.onChange(v.value),
    });
};
