const {getMax, getRadii, getColors, isCompareEnabled} = require("./gauge-helpers");

const seriesOptions = ({tile, data, theme}) => {
    const {style} = tile;

    const {leastInnerRadius, splitPointRadius} = getRadii(style);

    const {emptyColor, dataColor, compareDataColor} = getColors({
        tile,
        theme,
    });

    const {max} = getMax({data, style});

    const compareEnabled = isCompareEnabled({style, data});

    const commonAttrs = {
        states: {
            inactive: {
                opacity: 1,
            },
        },
    };

    return {
        series: [
            {
                // name: 'Speed',
                ...commonAttrs,
                yAxis: 0,
                data: [
                    {
                        y: data.value,
                        color: dataColor,
                        radius: "100%",
                        innerRadius: `${compareEnabled ? splitPointRadius : leastInnerRadius}%`,
                        name: tile.valueField.displayName || style.valueLabel || "Series 1",
                    },
                ],
                showInLegend: style?.legendStyle?.legendShown,
            },

            ...(compareEnabled
                ? [
                      // use this as a fake pane
                      {
                          enableMouseTracking: false,
                          animation: false,
                          yAxis: 1,
                          data: [
                              {
                                  y: max,
                                  color: emptyColor,
                                  radius: `${splitPointRadius}%`,
                                  innerRadius: `${leastInnerRadius}%`,
                                  name: tile.valueField.displayName || style.valueLabel || "Series 2",
                              },
                          ],
                          showInLegend: style.legendStyle.legendShown,
                      },
                      {
                          ...commonAttrs,
                          yAxis: 1,
                          data: [
                              {
                                  y: data.previousValue,
                                  color: compareDataColor,
                                  radius: `${splitPointRadius}%`,
                                  innerRadius: `${leastInnerRadius}%`,
                                  name: tile.valueField.displayName || style.valueLabel || "Series 2",
                              },
                          ],
                          showInLegend: style.legendStyle.legendShown,
                      },
                  ]
                : []),
        ],
    };
};
exports.seriesOptions = seriesOptions;
