import * as React from "react";
import {cx} from "emotion";

const fieldTypes = {
    Int: require("./field-type-icons/number-icon.svg"),
    Double: require("./field-type-icons/decimal-icon.svg"),
    DateTime: require("./field-type-icons/date-icon.svg"),
    DateTimeOffset: require("./field-type-icons/date-icon.svg"),
    Text: require("./field-type-icons/text-uuids-icon.svg"),
    IDInt: require("./field-type-icons/id-int-icon.svg"),
    IDUUID: require("./field-type-icons/id-uuid-icon.svg"),
    Bool: require("./field-type-icons/boolean-icon.svg"),
    Location: require("./field-type-icons/location-icon.svg"),
};

const getIcon = (dataType) => fieldTypes[dataType] || fieldTypes["Text"];

export const rFieldTypeIcon = (dataType) => {
    return (
        <img
            className={cx("field-type-icon", {
                "id-uuid": dataType === "IDUUID",
                bool: dataType === "Bool",
                decimal: dataType === "Double",
            })}
            src={getIcon(dataType)}
            alt=""
            draggable={false}
        />
    );
};
// dataTypes: Unsupported, IDInt, IDUUID, Text, Int, Bool, Double, DateTime, DateTimeOffset, GeoPoint, PostalCode
