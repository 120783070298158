const adjustMinMaxTickLabels = ({chart}) => {
    const yAxis = chart.yAxis[0];
    const minTick = yAxis.ticks[yAxis.tickPositions[0]];
    const maxTick = yAxis.ticks[yAxis.tickPositions[1]];

    if (!minTick.label.element || !maxTick.label.element) {
        return;
    }

    const minTickRect = minTick.label.element.getBoundingClientRect();
    const maxTickRect = maxTick.label.element.getBoundingClientRect();

    // if min label overlaps max label then adjust them
    if (minTickRect.x + minTickRect.width > maxTickRect.x) {
        const adjustLabel = (tick, isMinTick) => {
            const gridLineRect = tick.gridLine.element.getBBox();
            const newTickLabelTranslateX = gridLineRect.width * (isMinTick ? -1 : 1) + tick.label.translateX;
            const newTickLabelTranslateY = gridLineRect.height + tick.label.translateY + (isMinTick ? minTickRect.height : maxTickRect.height) * 0.5;
            tick.label.attr({
                "text-anchor": isMinTick ? "end" : "start",
                transform: `translate(${newTickLabelTranslateX}, ${newTickLabelTranslateY})`,
            });
        };

        adjustLabel(minTick, true);
        adjustLabel(maxTick);
    }
};
exports.adjustMinMaxTickLabels = adjustMinMaxTickLabels;
