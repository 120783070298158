import React from "react";
import {cs} from "@common/react/chain-services";
import {Form2} from "@common/react/cs-form/form2";
import {required} from "@common/react/cs-form/validators/required";
import {email} from "@common/react/cs-form/validators/email";
import {UseState} from "@common/react/use-state";
import {consumeContext} from "@common/react/context";
import {TextInput} from "../../../../../../../../../common/form/text-input/text-input";
import {ffToBasicInput} from "../../../../../../../../../common/form/ff-to-basic-input";
import {settingKey} from "../../../settings-constants";
import {Button} from "../../../../../../../../../common/form/buttons/button/button";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";
import {GlobalEvent} from "@common/react/global-event";

export const ShareAccessKey = ({params, modal}) =>
    cs(
        consumeContext("apis"),
        consumeContext("toast"),
        ["data", (_, next) => UseState({next, initValue: {}})],
        ["sending", (_, next) => UseState({next})],
        [
            "form",
            ({data, toast, apis}, next) =>
                Form2({
                    fields: {
                        email: [required, email],
                    },
                    data,
                    onSubmit: async () => {
                        modal.resolve();
                        // sending.onChange({sending: true});
                        await (modal.$type === settingKey.DATA_ACCESS ? apis.accessKey.share : apis.iframeKey.shareIframe)(params.value.id, data.value.email);
                        // sending.onChange({sent: true});

                        toast.show("Email sent");
                    },
                    next,
                }),
        ],
        ({form, sending}) => (
            <>
                <div className="dialog-body" style={{paddingTop: "10px"}}>
                    <div className="key-description">
                        Send an email with time sensitive link for the recipient to acquire their key. They can use their key to access documentation for accessible collections.
                    </div>

                    {TextInput({
                        label: "Email Address",
                        ...ffToBasicInput(form.field("email")),
                    })}
                </div>

                <div className="buttons">
                    {GlobalEvent({
                        fn: (e) => {
                            if (e.key == "Enter") {
                                form.submit();
                            }
                        },
                        eventName: "keydown",
                    })}

                    <Button btnType="secondary" onClick={() => modal.resolve()}>
                        {sending.value?.sent ? "Done" : "Cancel"}
                    </Button>
                    <Button disabled={sending.value} onClick={() => form.submit()}>
                        {sending.value == null ? "Send" : sending.value.sending ? "Sending..." : "Sent"}
                    </Button>
                </div>
            </>
        )
    );
