import {getDifference} from "./get-difference";
import {getFieldType} from "../../../common/get-field-type";
import {getBarLineMeasureLabel} from "./bar-line-non-compare-header";
import {getTooltipFontSizes} from "./get-tooltip-font-sizes";
import {getIndicatorShapes} from "./indicator-shapes";
import {usePointName} from "./column-points-table";
import {sort} from "@common/utils/collections";

/**
 * used in bar chart and combo chart with comparison
 * */
export const getColumnPointsTableCompare = ({tile, extractedPoints, generalInfo, formatters, rawData, theme}) => {
    return `${rBarLineHeaderCompare({
        extractedPoints,
        generalInfo,
        rawData,
        formatters,
        tile,
        theme,
        showTotalDiffPercent: true,
    })}
         ${rBarLinePointsCompare({
             extractedPoints,
             generalInfo,
             formatters,
             tile,
             theme,
             showDiffPercent: true,
         })}
         ${rTotalFooterCompare({extractedPoints, generalInfo, theme})}`;
};

/**
 * used in bar chart, line chart and line part of combo chart with comparison
 * */
export const rBarLineHeaderCompare = ({extractedPoints, generalInfo, rawData, formatters, tile, theme, showTotalDiffPercent, useLineIndicator, hideMainCompareTitle}) => {
    const fontSizes = getTooltipFontSizes(theme);

    const dateRangeFormatter = (range) => formatters.dateFormatter(range?.dateStart) + " - " + "</br>" + formatters.dateFormatter(range?.dateEnd);

    const isDimDate = getFieldType(tile.xAxisField || tile.yAxisField) === "date";

    const seriesTitle = (() => {
        const dimStepLabel = !isDimDate && extractedPoints.find((p) => p.dimCategory)?.dimCategory;

        if (showTotalDiffPercent) {
            const mainTotal = extractedPoints.reduce((total, p) => total + p.value, 0);
            const compareTotal = extractedPoints.reduce((total, p) => total + (p.compareValue || 0), 0);
            const {percentDiff, diffIcon, stateColor} = getDifference({
                value: mainTotal,
                compareValue: compareTotal,
                theme,
            });

            return `<div class="title">
                    ${[getBarLineMeasureLabel({generalInfo, tile}), dimStepLabel].filter((v) => v).join(" - ")}
                </div>
                <div class="difference" style="color: ${stateColor}">
                    ${diffIcon}
                    ${formatters.percentFormatter(percentDiff)}
                </div>`;
        }

        return `<div class="title">
                ${[getBarLineMeasureLabel({generalInfo, tile}), dimStepLabel].filter((v) => v).join(" - ")}
            </div>`;
    })();

    if (hideMainCompareTitle) {
        return `<div class="header table-row" style="font-size: ${fontSizes.small}px">
                <div class="series-title">${seriesTitle}</div>
                <div class="main-title"></div>
                <div class="compare-title"></div>
            </div>`;
    }

    const mainColumnTitle = isDimDate
        ? typeof generalInfo.dimCategory === "number"
            ? formatters.dateFormatter(generalInfo.dimCategory) || "N/A"
            : generalInfo.dimCategory
        : dateRangeFormatter(rawData.series.find((s) => !s.isCompare).range);

    const compareColumnTitle = isDimDate
        ? typeof generalInfo.compareDimValue === "number"
            ? generalInfo.compareDimValue
                ? formatters.dateFormatter(generalInfo.compareDimValue)
                : "N/A"
            : generalInfo.compareDimValue || "N/A"
        : dateRangeFormatter(rawData.series.find((s) => s.isCompare).range);

    const rLineIndicator = ({isCompare} = {}) => {
        const indicatorShapes = getIndicatorShapes(theme);
        return `<span class="line-indicator" style="font-size: 12px">${isCompare ? indicatorShapes["dashed-line"] : indicatorShapes["line"]}</span>`;
    };

    return `<div class="header table-row" style="font-size: ${fontSizes.small}px">
            <div class="series-title">${seriesTitle}</div>
            <div class="main-title">
                <div class="text ${useLineIndicator ? "has-line-indicator" : ""} ${isDimDate ? "horizontal" : "vertical"}">
                    ${useLineIndicator ? rLineIndicator() : ""}
                    ${mainColumnTitle}
                </div>
            </div>
            <div class="compare-title">
                <div class="text ${useLineIndicator ? "has-line-indicator" : ""} ${isDimDate ? "horizontal" : "vertical"}">
                    ${useLineIndicator ? rLineIndicator({isCompare: true}) : ""}
                    ${compareColumnTitle}
                </div>
            </div>
        </div>`;
};

/**
 * used in bar chart, line chart and line part of combo chart with comparison
 * */
export const rBarLinePointsCompare = ({extractedPoints, generalInfo, formatters, showDiffPercent, useLineIndicator, tile, theme}) => {
    const fontSizes = getTooltipFontSizes(theme);
    const indicatorShapes = getIndicatorShapes(theme);
    const showPointName = usePointName({extractedPoints, tile});

    const extractedPointsSortedByDiff = sort(extractedPoints, (p) => {
        const diff = (p.value ?? 0) - (p.compareValue ?? 0);
        // const percentDiff = !p.compareValue ? 100 : (diff / p.compareValue) * 100;
        // return -1 * percentDiff;
        return -1 * diff;
    });

    return extractedPointsSortedByDiff
        .map((p, i) => {
            const diffPercent =
                showDiffPercent &&
                (() => {
                    const {percentDiff, diffIcon, stateColor} = getDifference({
                        value: p.value,
                        compareValue: p.compareValue,
                        theme,
                    });
                    return `<div class="difference" style="color: ${stateColor}">
                    ${diffIcon}
                    ${formatters.percentFormatter(percentDiff)}
                </div>`;
                })();
            return `<div 
                class="point table-row ${i === 0 ? "first-point" : i === extractedPoints.length - 1 ? "last-point" : ""}"
                style="font-size: ${fontSizes.medium}px"
            >
                <div class="name">
                    <span class="indicator" style="color: ${p.color}">
                        ${useLineIndicator ? indicatorShapes["line"] : indicatorShapes["bar"]}
                    </span>
                    ${showPointName ? p.name : p.dimCategory}
                    ${diffPercent || ""}
                </div>
                <div class="main-value">${p.valueFormatter(p.value || 0)}</div>
                <div class="compare-value">${p.compareValue != null ? p.valueFormatter(p.compareValue) : "N/A"}</div>
            </div>`;
        })
        .join("");
};

/**
 * footer of table, shows the total
 * used in bar chart, line chart and column part of combo chart with comparison
 * */
export const rTotalFooterCompare = ({extractedPoints, generalInfo, theme}) => {
    if (extractedPoints.length < 2) {
        return "";
    }

    const fontSizes = getTooltipFontSizes(theme);
    const mainTotal = extractedPoints.reduce((total, p) => total + p.value, 0);
    const compareTotal = extractedPoints.reduce((total, p) => total + (p.compareValue || 0), 0);
    return `
        <div class="total-row table-row" style="font-size: ${fontSizes.small}px">
            <div></div>
            <div class="main-total">${generalInfo.valueFormatter(mainTotal)}</div>
            <div class="compare-total">${generalInfo.valueFormatter(compareTotal)}</div>
        </div>
    `;
};
