import {cs} from "@common/react/chain-services";
import ColumnIcon from "assets/icons/data-sources/ColumnIcon";
import {keyed} from "@common/react/keyed";
import "./flat-file-columns-import.scss";
import {noun} from "@common/utils/plural";
import {UseState} from "@common/react/use-state";
import {cx} from "emotion";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {YesNoToggle} from "../../../../../../../common/form/toggles/yes-no-toggle";
import {scope} from "@common/react/scope";
import {rFieldTypeIcon} from "../../../../common/field-type-icon/get-field-type-icon";
import {isBlank} from "@common/utils/strings";
import {stateToSelect} from "@common/ui-components/form/state-to-select";
import {chain} from "@common/utils/fs";
import {arrEquals, createArray, sort, unique} from "@common/utils/collections";
import {Button} from "../../../../../../../common/form/buttons/button/button";

export const FlatFileColumnsImport = ({dataSource}) =>
    cs(
        [
            "expandingList",
            (_, next) =>
                UseState({
                    initValue: createArray(dataSource.value.tables.length).map(() => false),
                    next,
                }),
        ],
        ({expandingList}) => {
            return (
                <div className="flat-file-columns-import-99o">
                    <div className="header">
                        <div className="label">Import Configuration</div>

                        <div className="actions">
                            <Button
                                disabled={arrEquals(unique(expandingList.value), [true])}
                                btnType="secondary"
                                size="small"
                                onClick={() => expandingList.change((prev) => prev.map(() => true))}
                            >
                                Expand All
                            </Button>
                            <Button
                                disabled={arrEquals(unique(expandingList.value), [false])}
                                btnType="secondary"
                                size="small"
                                onClick={() => expandingList.change((prev) => prev.map(() => false))}
                            >
                                Collapse All
                            </Button>
                        </div>
                    </div>

                    <div className="columns-list">
                        {dataSource.value.tables.map((table, i) =>
                            cs(keyed(i), ["expanding", (_, next) => next(scope(expandingList, [i]))], ({expanding}) => (
                                <div className="item">
                                    <div
                                        className={cx("left", {
                                            expanded: expanding.value,
                                        })}
                                        onClick={() => expanding.change((prev) => !prev)}
                                    >
                                        {ColumnIcon({})}

                                        {
                                            <i
                                                className={cx("far fa-angle-down expand-icon", {
                                                    expanded: expanding.value,
                                                })}
                                            />
                                        }
                                    </div>
                                    <div
                                        className={cx("right", {
                                            expanded: expanding.value,
                                        })}
                                        onClick={() => !expanding.value && expanding.change((prev) => !prev)}
                                    >
                                        <div className="overview">
                                            <span className="text">{table.name}</span> ({table.columns.length} {noun(table.columns.length, "column")})
                                        </div>

                                        {!expanding.value ? (
                                            <div className="info">
                                                <div>Import: {table.columns.length}</div>
                                                <div className="separator" />
                                                <div>Exculde: {table.disabledColumns.length}</div>
                                            </div>
                                        ) : (
                                            <div className="expanding-section">
                                                {ExpandedColumns({
                                                    table: scope(dataSource, ["tables", i]),
                                                })}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            );
        }
    );

export const ExpandedColumns = ({table}) =>
    cs(() => {
        const columns = [...table.value.columns, ...table.value.disabledColumns];

        const sortedColumns = chain(
            columns,
            (_) => [
                ..._.filter((c) => c.primaryKey),
                ...sort(
                    _.filter((c) => !c.primaryKey),
                    (c) => c.name
                ),
            ]
            // (_) => _.filter((c) => c.name.toLowerCase().indexOf((keyword.value || "").toLowerCase()) > -1)
        );

        return (
            <div className="expanded-columns-88o">
                <div className="labels">
                    <div className="name">Columns</div>
                    <div className="type">Type</div>
                    <div className="import">Import</div>
                </div>

                <div className="list">
                    {sortedColumns.map((column, i) =>
                        cs(keyed(i), () => {
                            const disabledColumn = (column) => table.value.disabledColumns.findIndex((c) => c.name === column.name) > -1;

                            return (
                                <div
                                    className={cx("item", {
                                        disabled: disabledColumn(column),
                                    })}
                                >
                                    <div className="flex-item">
                                        <div className="name">{column.name}</div>

                                        <div className="interaction-row-types">
                                            {cs(() => {
                                                const isDisabledColumn = disabledColumn(column);

                                                const config =
                                                    {
                                                        Text: {
                                                            list: ["NonPII", "PII", "None"],
                                                            valueToLabel: (v) => (
                                                                <>
                                                                    {rFieldTypeIcon(`Text`)} Text ({v === "None" ? "Unqualified" : v === "NonPII" ? "Non-PII" : v})
                                                                </>
                                                            ),
                                                        },
                                                        DateTime: {
                                                            list: ["LocalTime", "UTC", "None"],
                                                            valueToLabel: (v) => (
                                                                <>
                                                                    {rFieldTypeIcon(`DateTime`)} DateTime ({v === "None" ? "Unqualified" : v === "LocalTime" ? "Local Time" : v})
                                                                </>
                                                            ),
                                                        },
                                                        DateTimeOffset: {
                                                            list: ["LocalTime", "UTC", "None"],
                                                            valueToLabel: (v) => (
                                                                <>
                                                                    {rFieldTypeIcon(`DateTimeOffset`)} DateTimeOffset (
                                                                    {v === "None" ? "Unqualified" : v === "LocalTime" ? "Local Time" : v})
                                                                </>
                                                            ),
                                                        },
                                                    }[column.dataType] || undefined;

                                                if (config) {
                                                    let columnIndex = table.value.columns.findIndex((c) => c.name === column.name);
                                                    let disabledColumnIndex = table.value.disabledColumns?.findIndex((c) => c.name === column.name);

                                                    return DropdownSelect({
                                                        ...config,
                                                        disabled: isDisabledColumn,
                                                        label: null,
                                                        errorMessage: `This field is required`,
                                                        hasError: isBlank(column.dataTypeProperties),
                                                        ...stateToSelect(
                                                            scope(table, [
                                                                isDisabledColumn ? "disabledColumns" : "columns",
                                                                isDisabledColumn ? disabledColumnIndex : columnIndex,
                                                                "dataTypeProperties",
                                                            ])
                                                        ),
                                                    });
                                                } else {
                                                    return (
                                                        <span
                                                            className={cx("value", {
                                                                "id-uuid": column.dataType === "IDUUID",
                                                            })}
                                                        >
                                                            {config ? (
                                                                config.valueToLabel(column.dataTypeProperties)
                                                            ) : (
                                                                <>
                                                                    {rFieldTypeIcon(column.dataType)} {column.dataType}
                                                                </>
                                                            )}
                                                        </span>
                                                    );
                                                }
                                            })}
                                        </div>

                                        {YesNoToggle({
                                            colors: {
                                                right: "#294661",
                                            },
                                            state: {
                                                value: !disabledColumn(column),
                                                onChange: (value) =>
                                                    table.change((oldTable) => ({
                                                        ...oldTable,
                                                        columns: value ? [...oldTable.columns, column] : oldTable.columns.filter((c) => c.name !== column.name),
                                                        disabledColumns: !value
                                                            ? [...oldTable.disabledColumns, column]
                                                            : oldTable.disabledColumns.filter((c) => c.name !== column.name),
                                                    })),
                                            },
                                        })}
                                    </div>
                                </div>
                            );
                        })
                    )}
                </div>
            </div>
        );
    });
