import "./infinite-table-layout.scss";

import * as React from "react";
import {cx} from "emotion";

import {cs} from "../../../../react/chain-services";
import {MultiPageLoader} from "./multi-page-loader";
import {LoadingWrapper} from "../../common/loading-wrapper/loading-wrapper";
import {WatchScroll} from "./watch-scroll";
import {IgnoreUpdate} from "../../../../react/ignore-update";
// import {DownwardProxy} from "../../../../react/downward-proxy";
import {ErrorHandler} from "../../common/loading-wrapper/error-handler/error-handler";
import {SortColumn} from "../paged/sort/sort-column";
import {Static2} from "../../../../react/static-2";
import {SearchColumns} from "../paged/search/search-columns";
import {VerbScrollbar} from "../../../verb-scrollbar/verb-scrollbar";
import {consumeContext} from "../../../../react/context";
import {fragments} from "../../../../react/fragments";
import {Watch} from "../../../../react/watch";
import {GetFiltersData} from "../../../live/live-dashboard/data/get-filters-data";

export const InfiniteTableLayout = ({
    next,
    tileFilters,
    loadData,
    size,
    tile,
    theme,
    tileKey,
    totalRef,
    controls,
    defaultData,
    isEditTile,
}) =>
    cs(
        consumeContext("selectedTimezone"),
        ["tableContainer", (_, next) => Static2({next})],
        ["sort", ({}, next) => SortColumn({next})],
        ["search", ({}, next) => SearchColumns({next, tile})],
        [
            "loader",
            ({sort, search, selectedTimezone}, next) => {
                if (!loadData.load) {
                    return next({
                        loadingFull: true,
                    });
                }

                const tableOverrides = {
                    tableSortOverride: sort.tableSortOverride,
                    tableSearches: search.tableSearches,
                };

                return fragments(
                    // expose filters data for debug function when editing a tile
                    // isEditTile && Watch({
                    //     initRun: true,
                    //     value: {
                    //         tableOverrides,
                    //     },
                    //     onChanged: ({tableOverrides}) => {
                    //         if (tableOverrides) {
                    //             GetFiltersData.setTableOverrides(tableOverrides)
                    //         }
                    //     }
                    // }),
                    MultiPageLoader({
                        _key: JSON.stringify({
                            key: loadData.key,
                            tileFilters: {
                                key: tileFilters.key,
                            },
                            tileKey,
                            tableOverrides: tableOverrides,
                        }),
                        getTotalPageCount: (data) => (data.totalPages == 0 ? data.totalPages + 1 : data.totalPages),
                        load: (page) =>
                            defaultData
                                ? defaultData
                                : loadData.load?.({
                                      apiKey: loadData.key,
                                      filters: tileFilters.getValue(),
                                      extraCacheKey: tileKey,
                                      page,
                                      tableOverrides: tableOverrides,
                                      timeZoneId: selectedTimezone?.value,
                                  }),
                        next,
                    })
                );
            },
        ],
        ({loader}, next) => (loader.error ? ErrorHandler({error: loader.error, size}) : next()),

        // ["hadData", ({loader}, next) => DownwardProxy({next, value: !!loader.pages?.length, allow: v=>v})],
        ({loader}, next) =>
            LoadingWrapper({
                animated: !!loadData.load,
                loading: loader.loadingFull,
                apiError: loader.apiError,
                error: loader.loadingFull && "Loading data...",
                tile,
                size,
                next,
                theme,
                tileBackgroundColorRGB: theme.general.tile.styles.tileBackgroundColorRGB,
            }),

        ({loader}, next) =>
            IgnoreUpdate({
                when: () => loader.loadingFull,
                next,
            }),

        [
            "watchScroll",
            ({loader}, next) =>
                WatchScroll({
                    disabled: loader.loadingPartial || loader.fullyLoaded,
                    action: ({range, total}) => {
                        if (range.to > total - 20) {
                            loader.loadMore();
                        }
                    },
                    next,
                }),
        ],
        [
            "data",
            ({loader}, next) =>
                next({
                    ...loader.pages[0],
                    rows: loader.pages.map((p) => p.rows).flat(),
                }),
        ],
        ({loader, watchScroll, tableContainer, data}, next) => {
            const heightControls = !tile?.style?.title?.show && controls ? 26 : 0;
            const tableControlHeight = tile?.style?.title?.show ? 52 : controls ? 26 : 0;
            return (
                <>
                    <div className={cx("table-common-vf5", !tile?.style?.title?.show && "hide-title")} style={{height: heightControls}}>
                        {controls && <div className="controls">{controls?.({data})}</div>}
                    </div>
                    <div
                        className="infinite-table-layout-75y"
                        style={{
                            fontFamily: `"${theme.general.canvas.fontFamily || "Roboto"}", sans-serif`,
                            height: `calc(100% - ${tableControlHeight}px)`,
                        }}
                    >
                        <VerbScrollbar
                            ref={(elem) => {
                                watchScroll.ref?.(elem);
                                tableContainer.set(elem);
                            }}
                            className="table-container"
                            style={{height: "100%"}}
                        >
                            {next()}
                        </VerbScrollbar>
                    </div>
                </>
            );
        },
        ({loader, data, sort, search}) =>
            next({
                data,
                loadingIndicator: !loader.fullyLoaded && {
                    loading: loader.loadingPartial,
                },
                sort,
                search,
            })
    );
