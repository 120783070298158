import React from "react";
import {cs} from "@common/react/chain-services";
import {CollectionsDashboard} from "../collections/collections-dashboard";
import {EnvDashboardLayout} from "../env-layout/env-dashboard-layout";
import {DataSourcesDashboard} from "../data-sources/data-sources-dashboard";
import {SettingsDashboard} from "../settings/settings-dashboard";
import {ModelsDashboard} from "../models/models-dashboard";
// import {OverviewDashboard} from "../overview/overview-dashboard";
import {LocalEvent} from "@common/react/local-event";
import {consumeContext, provideContext} from "@common/react/context";
import {Load2} from "@common/react/load2";
import {ThemesDashboard} from "../themes/themes-dashboard";
import {getUnauthorizedParamValues} from "../../../../verb-web/authorization/authorization";
import {RecentSection} from "../recent-section/recent-section";
import {SharedTilesDashboard} from "../shared-tiles/shared-tiles-dashboard";

export const EnvDashboard = ({tab, envId, environment}) =>
    cs(
        consumeContext("apis"),
        consumeContext("routing"),
        consumeContext("auth"),
        [
            "collectionFolders",
            ({apis, routing}, next) =>
                Load2({
                    _key: routing.params?.envId,
                    fetch: () => apis.collection.getFolders(),
                    next,
                }),
        ],
        [
            "apiCollections",
            ({apis, routing}, next) =>
                cs(
                    [
                        "remoteCollections",
                        (_, next) =>
                            Load2({
                                _key: routing.params?.envId,
                                fetch: () => apis.collection.getApiCollectionsGroup(),
                                next,
                            }),
                    ],
                    ({remoteCollections}) =>
                        next({
                            ...remoteCollections,
                            value: remoteCollections.value?.filter((r) => r.id),
                        })
                ),
        ],
        [
            "accessApiKeyFolders",
            ({apis, routing}, next) =>
                Load2({
                    _key: routing.params?.envId,
                    fetch: () => apis.accessKey.getFolders(),
                    next,
                }),
        ],
        [
            "dashboardCollectionsOverview",
            ({apis, routing}, next) =>
                cs(
                    [
                        "remoteCollection",
                        (_, next) =>
                            Load2({
                                _key: `iframe${routing.params?.envId}`,
                                fetch: () => apis.collection.getCollectionsOverview(),
                                next,
                            }),
                    ],
                    ({remoteCollection}) =>
                        next({
                            ...remoteCollection,
                            value: (remoteCollection.value || [])?.filter((c) => c.type === "DashboardCollection"),
                        })
                ),
        ],
        [
            "iframeKeyFolders",
            ({apis, routing}, next) =>
                Load2({
                    _key: routing.params?.envId,
                    fetch: () => apis.iframeKey.getFolders(),
                    next,
                }),
        ],
        ({collectionFolders}, next) => provideContext("collectionFolders", collectionFolders.value, next),
        ["updateEvents", (_, next) => LocalEvent({next})],
        [
            "tabs",
            ({updateEvents, collectionFolders, gettingStartedStatus, auth, apiCollections, accessApiKeyFolders, dashboardCollectionsOverview, iframeKeyFolders}, next) => {
                const unauthorizedTabs = getUnauthorizedParamValues({
                    roles: auth.user.roles,
                    routeName: "dashboard",
                    param: "tab",
                });
                const tabs = [
                    // {
                    //     name: "overview",
                    //     csName: "overview",
                    //     csFn: (_, next) =>
                    //         OverviewDashboard({
                    //             next,
                    //             updateEvents,
                    //             environment,
                    //             folders: collectionFolders,
                    //         }),
                    // },
                    {
                        name: "recent",
                        csName: "recent",
                        csFn: (_, next) =>
                            RecentSection({
                                apiCollections,
                                accessApiKeyFolders,
                                dashboardCollectionsOverview,
                                iframeKeyFolders,
                                updateEvents,
                                environment,
                                folders: collectionFolders,
                                next,
                            }),
                    },
                    {
                        name: "collections",
                        csName: "collections",
                        csFn: (_, next) =>
                            CollectionsDashboard({
                                next,
                                updateEvents,
                                environment,
                                folders: collectionFolders,
                            }),
                    },
                    {
                        name: "models",
                        csName: "models",
                        csFn: (_, next) => ModelsDashboard({next, updateEvents, environment}),
                    },
                    {
                        name: "shared-tiles",
                        csName: "shareTiles",
                        csFn: (_, next) => SharedTilesDashboard({next}),
                    },
                    {
                        name: "data-sources",
                        csName: "dataSources",
                        csFn: (_, next) =>
                            DataSourcesDashboard({
                                next,
                                updateEvents,
                                environment,
                            }),
                    },
                    {
                        name: "settings",
                        csName: "settings",
                        csFn: (_, next) =>
                            SettingsDashboard({
                                next,
                                folders: collectionFolders.value,
                                apiCollections,
                                accessApiKeyFolders,
                                dashboardCollectionsOverview,
                                iframeKeyFolders,
                            }),
                    },
                    {
                        name: "themes",
                        csName: "themes",
                        csFn: (_, next) => ThemesDashboard({next, updateEvents, environment}),
                    },
                ].filter((t) => !unauthorizedTabs.includes(t.name));

                return cs(...tabs.map(({csName, csFn}) => [csName, csFn]), ({collections, dataSources, models, overview, settings, themes, recent, shareTiles, reportBuilder}) => {
                    return next([
                        {
                            name: "recent",
                            showTabName: true,
                            label: "Recent Collections and Keys",
                            ...recent,
                        },
                        {
                            name: "collections",
                            showTabName: true,
                            label: "Collections",
                            ...collections,
                        },
                        {
                            name: "models",
                            showTabName: true,
                            label: "Models",
                            ...models,
                        },
                        {
                            name: "data-sources",
                            showTabName: true,
                            label: "Data Sources",
                            ...dataSources,
                        },
                        {
                            name: "shared-tiles",
                            showTabName: true,
                            label: "Shared Tiles",
                            ...shareTiles,
                        },
                        {
                            name: "settings",
                            showTabName: true,
                            label: "Settings",
                            ...settings,
                        },
                        {
                            name: "themes",
                            showTabName: true,
                            label: "Themes",
                            ...themes,
                        },
                        {
                            name: "report-builder",
                            showTabName: true,
                            label: "Report Builder",
                            ...reportBuilder,
                        },
                    ]);
                });
            },
        ],
        ({tabs}) => {
            return EnvDashboardLayout({
                tab,
                envId,
                tabs,
            });
        }
    );
