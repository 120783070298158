const {createFixedDimAxisLabel} = require("../../vertical-bar/chart-options/fix-dim-axis-label");

// fix horizontal bar chart dimension axis label on scrolling
const fixHorizontalDimAxisLabel = (chart) => {
    const name = "fixedDimAxisLabel";
    chart[name]?.remove();

    const realLabel = chart.xAxis[0].axisGroup.element?.getElementsByClassName("highcharts-axis-title")?.[0];

    if (!realLabel) {
        return;
    }

    const visibleAreaHeight = chart.chartHeight - chart.axisOffset[0] - chart.axisOffset[2];
    const topPos = visibleAreaHeight / 2 + chart.axisOffset[0] / 2; //+ chart.marginBottom;
    const leftPos = realLabel.getBoundingClientRect().x - chart.container.parentElement.getBoundingClientRect().x;

    if (!chart.container.parentElement.classList.contains("highcharts-inner-container")) {
        chart.container.parentElement.style.position = "relative";
    }

    chart[name] = chart.container.parentElement.appendChild(
        createFixedDimAxisLabel({
            cssText: realLabel.style.cssText,
            leftPos,
            topPos,
            text: realLabel.textContent,
            rotated: true,
        })
    );

    // hide the moving label
    realLabel.style.opacity = "0";
};
exports.fixHorizontalDimAxisLabel = fixHorizontalDimAxisLabel;
