import {cs} from "@common/react/chain-services";
import {provideContext} from "@common/react/context";
import {scope} from "@common/react/scope";
import * as React from "react";
import {Expandable} from "../../../../../../../../common/expandable/expandable";
import {getTileFields} from "../common/get-tile-fields";
import {Limit} from "../common/limit-sorting/limit/limit";
import {getColumnType} from "../common/select-field/select-field";
import {SingleFieldWell} from "../common/single-field-well/single-field-well";
import {FunnelSorting} from "./funnel-sorting";
// import {updateLimitSortingInTile} from "./common/update-tile";
// import {
//     getDimensionlessSortingUpdatedByLimit
// } from "./common/limit-sorting/sorting/dimensionless/get-dimensionless-sorting-updated";
// import {getTileActionsUpdated} from "../actions/get-tile-actions-updated";
// import {getColumnType} from "./common/select-field/select-field";
// import {updateSingleFieldInTile} from "./common/update-tile";
// import {
//     getDimensionlessSortingUpdated
// } from "./common/limit-sorting/sorting/dimensionless/get-dimensionless-sorting-updated";
// import {getLimitUpdated} from "./common/limit-sorting/limit/get-limit-updated";

export const FunnelChartFields = ({tile, isSubTile, tileActions, debugFunction, form, next}) =>
    cs(
        [
            "tileFields",
            ({}, next) =>
                next(
                    getTileFields({
                        tile: tile.value,
                        measureSingleAttrs: ["valueField"],
                        groupFieldAttr: "groupField",
                    })
                ),
        ],
        ["tileFields", ({tileFields}, next) => provideContext("tileFields", tileFields, next)],

        [
            "valueField",
            ({tileFields}, next) =>
                SingleFieldWell({
                    field: scope(tile, ["valueField"]),
                    $type: "DimensionlessMeasureTileField",
                    // onUpdateTile: (newVal, oriVal) => updateSingleFieldInTile({
                    //     fieldAttr: "valueField", fieldVal: {newVal, oriVal}, tileFields, tile,
                    //     updateSorting: getDimensionlessSortingUpdated,
                    //     updateLimit: getLimitUpdated,
                    // }),
                    label: "Values",
                    notAllowNoneAggregation: true,
                    hideAggregatedMeasureColumn: true,
                    filterColumn: (column) => {
                        const type = getColumnType(column);
                        return type !== "date";
                    },
                    next,
                }),
        ],

        [
            "groupField",
            ({tileFields}, next) =>
                SingleFieldWell({
                    field: scope(tile, ["groupField"]),
                    // onUpdateTile: (newVal, oriVal) => updateSingleFieldInTile({
                    //     fieldAttr: "groupField", fieldVal: {newVal, oriVal}, tileFields, tile,
                    //     updateSorting: getDimensionlessSortingUpdated,
                    //     updateLimit: getLimitUpdated,
                    //     updateTileActions: getTileActionsUpdated
                    // }),
                    $type: "CategoryTileField",
                    label: "Group",
                    hideAggregatedMeasureColumn: true,
                    filterColumn: (column) => {
                        const type = getColumnType(column);
                        return type !== "date";
                    },
                    next,
                }),
        ],

        [
            "fieldsSection",
            ({valueField, groupField}, next) =>
                next({
                    render: () =>
                        Expandable({
                            label: "Fields",
                            render: () => (
                                <>
                                    {valueField.render()}
                                    {groupField.render()}
                                </>
                            ),
                        }),
                    override: valueField.override || groupField.override,
                }),
        ],

        [
            "limitSorting",
            ({tileFields}, next) =>
                cs(
                    [
                        "limit",
                        ({}, next) => {
                            const formField = form.field(["limit"]);
                            return next(
                                Limit({
                                    field: formField.state,
                                    allFields: tileFields.allFieldsWithoutUniq,
                                    isHiddenRankOption: () => false,
                                })
                            );
                        },
                    ],
                    [
                        "sorting",
                        (_, next) => {
                            return next(FunnelSorting({tile}));
                        },
                    ],
                    ({limit, sorting}) =>
                        next({
                            render: () =>
                                Expandable({
                                    initExpand: false,
                                    label: "Limit & Sorting",
                                    render: () => (
                                        <>
                                            {limit}
                                            {sorting}
                                        </>
                                    ),
                                }),
                        })
                ),
        ],

        ({fieldsSection, limitSorting}) =>
            next({
                render: () => {
                    return (
                        <div className="tile-fields-3vr">
                            {fieldsSection.render()}
                            {limitSorting.render({})}
                            {tileActions?.render?.({})}
                            {isSubTile && debugFunction?.render?.({})}
                        </div>
                    );
                },
                override: fieldsSection.override || debugFunction?.override() || tileActions?.override({fieldType: "DimensionlessMeasureTileField"}),
                overrideAll: debugFunction?.overrideAll || tileActions?.overrideAll,
            })
    );
