import * as React from "react";
import {cs} from "@common/react/chain-services";
import {keyed} from "@common/react/keyed";
import {CheckboxLine} from "../checkbox-line/checkbox-line";
import {css, cx} from "emotion";
import "./checkbox-line-group-flex.scss";
import {findMaxE, sum} from "@common/utils/collections";
import {InfoIcon} from "@common/ui-components/icons/global-icons";

export const CheckboxLineGroupFlex = ({
    list,
    isSelected,
    onChange,
    hasError,
    domRef,
    numberOfColumns = 1,
    rowMap,
    colMap,
    colInfo,
    isDisabled = () => false,
}) =>
    cs(({}) => {
        const rItem = (item, itemsPerRow) => (
            <div
                className={cx("item")}
                style={{
                    width: `calc(100% / ${itemsPerRow})`,
                }}
            >
                {CheckboxLine({
                    state: {
                        value: isSelected(item),
                        onChange: () => onChange(item),
                    },
                    label: item.label,
                    disabled: isDisabled(item),
                    className: cx({disabled: isDisabled(item)}),
                })}
            </div>
        );

        return (
            <div
                className={cx("checkbox-line-group-flex checkbox-line-group-flex-5g3", {
                    hasError,
                })}
                ref={domRef}
                style={
                    !!colMap
                        ? {
                              display: "grid",
                              gridTemplateColumns: `repeat(${colMap.length}, 1fr)`,
                          }
                        : {}
                }
            >
                {hasError && <div className="error-message">Required</div>}
                {rowMap
                    ? (() => {
                          const maxWidth = findMaxE(rowMap);
                          return rowMap.map((r, i) => (
                              <div className="row" key={i}>
                                  {list
                                      ?.slice(i === 0 ? i : sum(rowMap.slice(0, i)), i < rowMap.length ? sum(rowMap.slice(0, i + 1)) : null)
                                      .map((item, j) => cs(keyed(j), () => rItem(item, maxWidth)))}
                              </div>
                          ));
                      })()
                    : colMap
                    ? (() => {
                          return colMap.map((r, i) => (
                              <div className="col" key={i}>
                                  {list
                                      ?.slice(i === 0 ? i : sum(colMap.slice(0, i)), i < colMap.length ? sum(colMap.slice(0, i + 1)) : null)
                                      .map((item, j) => cs(keyed(j), () => rItem(item, 1)))}
                                  {colInfo?.[i] && (
                                      <div className="col-info">
                                          <InfoIcon />
                                          {colInfo[i]}
                                      </div>
                                  )}
                              </div>
                          ));
                      })()
                    : list?.map((item, i) => cs(keyed(i), () => rItem(item, numberOfColumns)))}
            </div>
        );
    });
