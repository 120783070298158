import * as React from "react";
import {cs} from "@common/react/chain-services";
import {FieldControl} from "../render-field-control/field-control";
import {UseState} from "@common/react/use-state";
import {SelectField} from "../select-field/select-field";
import {ConfigureField} from "../configure-field/configure-field";
import "./single-field-well.scss";
import {cx} from "emotion";
import {consumeContext} from "@common/react/context";
import {FieldSection} from "../field-section/field-section";
import {FieldInfoProvider} from "../../../../tile-tab/chart-types/common/field-info-provider/field-info-provider";
import {getFieldType} from "@common/ui-components/charts/common/get-field-type";
import {Button} from "@common/form/buttons/button/button";

export const SingleFieldWell = ({
    next,
    field,
    hasError,
    $type,
    onSelect,
    onRemove,
    onUpdateTile,
    domRef,
    label,
    fieldLabel,
    errorMessage,
    notAllowNoneAggregation,
    hideAggregationSelect,
    filterColumn,
    hideAggregatedMeasureColumn,
    allowAllFieldTypesWhenChangingField,
    unavailable,
    showUnavailableMessage,
    hideSort = false,
    hideDisplayName = false,
    info,
    direction,
    enableConstantValue,
    constantField,
    constantFieldSelector, // only field or constantField is specified at a time for a single field well
    notAllowNullBlankHandling,
    hideAggregationType, // to hide the aggregation type on field control card
    hasDateGroupsConfiguration, //to hide date groups configuration
    hideConfiguration, // hide configuration on field control card actions
    customListAgg, // custom aggregation list on field configuration
}) =>
    cs(
        consumeContext("getFieldInfo"),
        consumeContext("tileFields"),
        [
            "selecting",
            (_, next) =>
                UseState({
                    // initValue: {type},
                    next,
                }),
        ],
        ["configuring", (_, next) => UseState({next})],
        ({configuring, selecting, getFieldInfo, tileFields}) =>
            next({
                render: () =>
                    FieldSection({
                        header: FieldInfoProvider({
                            field: <div className="text">{label}</div>,
                            info,
                            direction,
                        }),
                        content: (
                            <div
                                className={cx("single-field-well-9xc", {
                                    hasError,
                                })}
                                ref={domRef}
                            >
                                {errorMessage && <div className="error-message">{errorMessage}</div>}

                                {field.value &&
                                    FieldControl({
                                        field: field.value,
                                        getFieldInfo,
                                        hideAggregationType,
                                        hideConfiguration,
                                        onConfigure: () => configuring.onChange(true),
                                        onChangeField: () =>
                                            selecting.onChange({
                                                type: "change",
                                            }),
                                        onRemove: () =>
                                            onUpdateTile
                                                ? onUpdateTile(null, field.value)
                                                : onRemove
                                                ? onRemove(field.value)
                                                : field.onChange(null),
                                    })}

                                {enableConstantValue &&
                                    constantField.value != null &&
                                    FieldControl({
                                        constantValue: constantField.value.value,
                                        onConfigure: () => configuring.onChange(true),
                                        onChangeField: () =>
                                            selecting.onChange({
                                                type: "change",
                                            }),
                                        onRemove: () => constantField.onChange(null),
                                    })}

                                {!field.value && (!enableConstantValue || constantField.value == null) && (
                                    <Button
                                        size="tiny"
                                        btnType="secondary"
                                        onClick={() =>
                                            unavailable?.()
                                                ? showUnavailableMessage?.()
                                                : selecting.onChange({
                                                      type: "add",
                                                  })
                                        }
                                    >
                                        {enableConstantValue ? `Set ${label}` : `Add ${fieldLabel || label} Field`}
                                    </Button>
                                )}
                            </div>
                        ),
                    }),
                override: (() => {
                    if (selecting.value) {
                        const insertField = (newField) => {
                            if (onUpdateTile) {
                                onUpdateTile(newField, field.value);
                            } else {
                                let onChange1 = onSelect ?? field.onChange;
                                onChange1(newField);
                            }
                        };

                        const handleAddField = (field1) => {
                            insertField({...field1, $type});
                            selecting.onChange(null);
                        };

                        const handleChangeField = (field1) => {
                            insertField({
                                ...field.value,
                                ...field1,
                            });
                            selecting.onChange(null);
                        };

                        return (
                            <SelectField
                                {...{
                                    label: `Select ${fieldLabel || label} Field`,
                                    onCancel: () => selecting.onChange(null),
                                    actionType: selecting.value.type,
                                    onSelect: selecting.value.type === "add" ? handleAddField : handleChangeField,
                                    ...(selecting.value.type === "change" && {
                                        allowedFieldType: allowAllFieldTypesWhenChangingField ? null : getFieldType(field.value),
                                    }),
                                    filterColumn,
                                    $type,
                                    notAllowedFields: tileFields.allFields,
                                    hideAggregatedMeasureColumn,
                                    field,
                                    enableConstantValue,
                                    constantField,
                                    constantFieldSelector,
                                }}
                            />
                        );
                    } else if (configuring.value) {
                        return (
                            <ConfigureField
                                {...{
                                    hasDateGroupsConfiguration,
                                    hideSort,
                                    hideDisplayName,
                                    field,
                                    ...(enableConstantValue &&
                                        constantField?.value != null && {
                                            constantField,
                                            label: `${label} Configuration`,
                                        }),
                                    getFieldInfo,
                                    notAllowNoneAggregation,
                                    hideAggregationSelect,
                                    notAllowNullBlankHandling,
                                    customListAgg,
                                    onRemove: () => {
                                        if (onRemove) {
                                            onRemove(field.value);
                                        } else {
                                            field.onChange(null);
                                        }

                                        configuring.onChange(null);
                                    },
                                    onCancel: () => configuring.onChange(null),
                                }}
                            />
                        );
                    }
                })(),
            })
    );
