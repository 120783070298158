exports.sparkLineKPITemplate = {
    $type: "SparkLineKPITile",
    style: {
        $type: "SparkLineKPITileStyle",

        title: {
            show: true,
            titleLocation: "Above",
        },

        // showCompare: false,
        sparkLineOrientation: "TopBottom", // KPI|SparkLine: LeftRight, RightLeft, TopBottom, BottomTop
        sparkLineChartType: "Line", // Line, Area, Bar
        positiveChangeGood: true,

        // chart configs
        // multipleAxisOption: 'AlternateAxis',
        // dataColorPalette: [],

        // yAxis: {},
        // xAxis: {},

        // colorApplications: {},

        // line chart default

        // bar chart default
        // displayType: 'Classic', //Classic, Stacked, HundredPercent,
        // barDataColorAppliedBy: ''
    },
    yAxisField: {
        measureFields: [],
    },
};
