import * as React from "react";
import "./ff-to-basic-input.scss";

export const ffToBasicInput = (field, customErrorMessage) => ({
    state: field.state,
    onBlur: field.flush,
    hasError: field.error,
    domRef: field.domRef,
    errorMessage: field.getErrorMessage?.() || customErrorMessage,

    ...(field.debounce &&
        field.hasValidators && {
            status: field.pending ? <i className="ff-to-basic-input-status-3ww loading fa fa-spinner fa-pulse" /> : field.rawError ? <i className="ff-to-basic-input-status-3ww invalid fa fa-close" /> : <i className="ff-to-basic-input-status-3ww valid fa fa-check" />,
        }),
});
