import * as React from "react";
import {cs} from "@common/react/chain-services";
import "./default-view.scss";
import {FieldInfoProvider} from "../../../common/field-info-provider/field-info-provider";
import {scope} from "@common/react/scope";
import {RegionSelect} from "./region-select";
import {RadioLine} from "../../../../../../../../../../common/radio-line/radio-line";
import {OnOffToggle} from "../../../../../../../../../../../../../common/form/toggles/on-off-toggle";
import {InfoProvider} from "../../../common/info-provider/info-provider";
import {keyed} from "@common/react/keyed";
import {spc} from "@common/react/state-path-change";

export const DefaultView = ({tile}) => ({
    initExpand: true,
    label: "Top-Level Region",
    render: () => (
        <div className="map-tile-default-view-4zs">
            <div className="subsection">
                <div className="content">
                    {defaultViews.map(({label, info, tooltipBoxWidth}) =>
                        cs(keyed(label), ({}) =>
                            FieldInfoProvider({
                                className: "default-view-select-6fd default-view-select",
                                info,
                                tooltipBoxWidth,
                                field: RadioLine({
                                    label: label.toUpperCase(),
                                    selected: tile.value.style.defaultLocation === label,
                                    onClick: () => spc(tile, ["style", "defaultLocation"], () => label),
                                }),
                            })
                        )
                    )}

                    {tile.value.style.defaultLocation === "Region" &&
                        RegionSelect({
                            ...scope(tile, ["style", "region"]),
                        })}
                </div>
            </div>

            <div className="subsection">
                <div className="header">
                    Map Controls
                    {InfoProvider({
                        info: "Map controls allow a user to pan and zoom on the tile. When off the map cannot be moved or zoomed.",
                    })}
                    <div className="control">
                        {OnOffToggle({
                            state: scope(tile, ["style", "showMapControls"]),
                        })}
                    </div>
                </div>
            </div>
        </div>
    ),
});

const defaultViews = [
    {
        label: "Auto",
        info: "The map will default to the lowest location that will allow the viewer to see all of their data. For example, if the viewer only has data in the US the map will begin on the US, instead of a global map.",
        tooltipBoxWidth: 300,
    },
    {
        label: "Global",
        info: "The top level region will always start at the global level.",
        tooltipBoxWidth: 150,
    },
    {
        label: "Region",
        info: "Select the region that will be the highest level view available. The viewer will be able to zoom into more specific levels of data but not zoom out past the selected region.",
        tooltipBoxWidth: 150,
    },
];
