import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {scope} from "@common/react/scope";
import {UseState} from "@common/react/use-state";
import {equalDeep, isEmpty, omit} from "@common/utils/objects";
import {SyncSettingsInteraction} from "../../edit/sync-settings/interaction/sync-settings-interaction";
import {DsLayout} from "../../layout/ds-layout";
import {modifiedRawTables} from "../default-data-source";
import {DsAddingConnectionSettings} from "./connection-settings/ds-adding-connection-settings";
import {DsAddingSyncSettings} from "./sync-settings/ds-adding-sync-settings";
import {defaultDSColors} from "../../../model/edit/tabs/common/data-source-colors";
import "./ds-adding-steps.scss";
import {cx} from "emotion";
import {FinishedAddingDsDialog} from "../dialogs/finished-adding-ds-dialog/finished-adding-ds-dialog";
import {isDevSonPham} from "../../../../../../tools/dev/is-dev-sonpham";
import {dataSourceTypes, unstructuredDSTypes} from "../../common/data-source-type";
import {DsAddingImportConfiguration} from "./import-configuration/ds-adding-import-configuration";
import {outboundAPITransformData} from "../../common/outbound-api/outbound-api-transform-data";
import {randomNumber} from "@common/utils/math-util";

const addingStatus = {
    error: null,
    success: false,
    loading: false,
    connectionDetails: {},
};

export const DsAddingSteps = ({dataSource, dataSourceList}) =>
    cs(
        consumeContext("routing"),
        consumeContext("toast"),

        ["connectionStatus", (_, next) => UseState({initValue: addingStatus, next})],
        ["currentStep", (_, next) => UseState({initValue: 1, next})],

        [
            "dsApi",
            ({connectionStatus, currentStep, routing}, next) =>
                cs(consumeContext("apis"), ["finishedAddingDsDialog", ({}, next) => FinishedAddingDsDialog({next})], ({apis, finishedAddingDsDialog}) =>
                    next({
                        onNextStep: () => currentStep.change((prev) => prev + 1),

                        testConnection: async ({submitConnectionDetails, connectionDetails}, dsInfo = {}, autoNextStep = false) => {
                            try {
                                connectionStatus.onChange({
                                    ...addingStatus,
                                    loading: true,
                                });
                                const tables = await apis.data.getTablesColumns({
                                    dataSourceID: dsInfo.id,
                                    data: submitConnectionDetails,
                                });

                                connectionStatus.onChange({
                                    ...addingStatus,
                                    success: true,
                                    connectionDetails,
                                });

                                if (tables) {
                                    const newDs = (ds) => ({
                                        ...dsInfo,
                                        connectionDetails,
                                        structured: !unstructuredDSTypes.includes(ds.type),
                                        tables: modifiedRawTables({
                                            tables,
                                            type: ds.type ?? dsInfo.type,
                                        }),
                                        disabledTables: [],
                                    });

                                    dataSource.change((ds) => ({
                                        ...ds,
                                        ...newDs(ds),
                                    }));

                                    if (autoNextStep) {
                                        currentStep.change((prev) => prev + 1);
                                    }
                                }
                            } catch (e) {
                                connectionStatus.onChange({
                                    ...addingStatus,
                                    error: e,
                                });
                                throw e;
                            } finally {
                                connectionStatus.change((s) => ({
                                    ...s,
                                    loading: false,
                                }));
                            }
                        },

                        createNewDS: async () => {
                            const getConnectionDetails = (data) => {
                                if ((Array.isArray(data.$type) ? data.$type : [data.$type]).includes("OutboundApiConnectionDetails")) {
                                    return outboundAPITransformData(data.$type, data.connectionDetails);
                                }
                                return data.connectionDetails;
                            };

                            const generateDSColor = (dataSources) => {
                                const availableColors = defaultDSColors.filter((c) => !dataSources.find((ds) => ds.colorRGB === c));
                                return availableColors.length > 0 ? availableColors[0] : defaultDSColors[randomNumber(0, defaultDSColors.length - 1)];
                            };

                            const dsData = {
                                ...omit(dataSource?.value, ["groupType", "location"]),
                                connectionDetails: getConnectionDetails(dataSource?.value),
                                enabled: true,
                                disabledTables: (dataSource.value?.disabledTables || []).map((t) => ({...t, syncSchedule: null})),
                                colorRGB: generateDSColor(dataSourceList),
                            };

                            try {
                                await apis.data.upsertDataSource(dsData);
                                const models = await apis.model.getModels({
                                    namesOnly: true,
                                });

                                if (models.length > 0) {
                                    routing.goto("dashboard", {
                                        tab: "data-sources",
                                    });
                                } else {
                                    const resp = await finishedAddingDsDialog.show();

                                    if (!resp) {
                                        routing.goto("dashboard", {
                                            tab: "data-sources",
                                        });
                                    }
                                }
                            } catch (e) {
                                throw e;
                            }
                        },
                    })
                ),
        ],

        ["interactions", (_, next) => UseState({initValue: {selectedTable: null}, next})],
        ["showErrors", (_, next) => UseState({initValue: false, next})],

        [
            "addingSteps",
            ({dsApi, connectionStatus, interactions, showErrors}, next) =>
                cs(
                    [
                        "connectionSettings",
                        (_, next) =>
                            DsAddingConnectionSettings({
                                next,
                                dsApi,
                                dataSource,
                                connectionStatus: connectionStatus.value,
                            }),
                    ],

                    [
                        "configSettings",
                        (_, next) =>
                            [dataSourceTypes.FILE, dataSourceTypes.GOOGLE_SHEETS].includes(dataSource.value.type)
                                ? DsAddingImportConfiguration({
                                      dataSource,
                                      next,
                                  })
                                : DsAddingSyncSettings({
                                      dataSource,
                                      hasErrors: showErrors.value,
                                      interactions,
                                      next,
                                  }),
                    ],
                    ({connectionSettings, configSettings}) =>
                        next([
                            {
                                id: 1,
                                className: "connection-settings-99i",
                                ...connectionSettings,
                            },
                            {
                                id: 2,
                                ...configSettings,
                            },
                        ])
                ),
        ],

        ["activeStep", ({currentStep, addingSteps}, next) => next(addingSteps.find((s) => s.id === currentStep.value))],

        [
            "controls",
            ({activeStep, addingSteps, connectionStatus, routing, currentStep, dsApi, showErrors, toast}, next) => {
                if (activeStep.id < addingSteps.length) {
                    return next([
                        ...(dataSource.value.type
                            ? [
                                  {
                                      label: "Previous",
                                      btnType: "secondary",
                                      onClick: () => {
                                          if (!connectionStatus.value.loading) {
                                              connectionStatus.onChange(addingStatus);
                                              dataSource.onChange({
                                                  connectionDetails: {},
                                              });
                                          }
                                      },
                                  },
                                  {
                                      label: "Next",
                                      disabled: !activeStep.valid || connectionStatus.value.loading,
                                      tooltip: [dataSourceTypes.FILE].includes(dataSource.value.type)
                                          ? null
                                          : connectionStatus.value.loading
                                          ? null
                                          : "All required fields must be filled in before proceeding",
                                      onClick: async () => {
                                          if (!activeStep.valid || connectionStatus.value.loading) {
                                              // showErrors.onChange(true);
                                              return;
                                          }

                                          if (
                                              equalDeep(connectionStatus.value.connectionDetails, dataSource.value.connectionDetails) &&
                                              !isEmpty(dataSource.value.connectionDetails) &&
                                              connectionStatus.value.success
                                          ) {
                                              // No longer run test connection
                                              currentStep.change((s) => s + 1);
                                          } else {
                                              if (activeStep.beforeGoNext) {
                                                  try {
                                                      await activeStep.beforeGoNext();
                                                      currentStep.change((s) => s + 1);
                                                  } catch (e) {
                                                      // console.log(e);
                                                  }
                                              }
                                          }
                                      },
                                  },
                              ]
                            : [
                                  {
                                      label: "Cancel",
                                      btnType: "secondary",
                                      onClick: () =>
                                          routing.goto("dashboard", {
                                              tab: "data-sources",
                                          }),
                                  },
                              ]),
                    ]);
                }

                return cs(() =>
                    next([
                        {
                            label: [dataSourceTypes.FILE].includes(dataSource.value.type) ? "Cancel" : "Previous",
                            btnType: "secondary",
                            onClick: () => {
                                if ([dataSourceTypes.FILE].includes(dataSource.value.type)) {
                                    dataSource.onChange({
                                        connectionDetails: {},
                                    });
                                }
                                return !connectionStatus.value.loading && currentStep.change((s) => s - 1);
                            },
                        },
                        {
                            label: "Save & Done",
                            iconRight: connectionStatus.value.loading && <i className="fad fa-spinner-third fa-spin"></i>,
                            disabled: connectionStatus.value.loading,
                            onClick: async () => {
                                if (!activeStep.valid) {
                                    showErrors.onChange(true);
                                    return;
                                }

                                connectionStatus.change((o) => ({
                                    ...o,
                                    loading: true,
                                }));
                                try {
                                    await dsApi.createNewDS();
                                } catch (e) {
                                    toast.show(e.message, {isError: true});
                                } finally {
                                    connectionStatus.change((o) => ({
                                        ...o,
                                        loading: false,
                                    }));
                                }
                            },
                        },
                    ])
                );
            },
        ],

        ({activeStep, interactions, controls}) => {
            return DsLayout({
                className: "ds-adding-steps-99o",
                leftHeader: {
                    type: "New Data Source",
                    label: dataSource?.value?.name ?? dataSource?.value?.type,
                },
                rightFn: activeStep.renderHeader?.(),
                rightButtons: controls,
                content: <div className={cx("content", activeStep.className)}>{activeStep?.render({})}</div>,
                rightPanel: {
                    content: cs(
                        (_, next) => (interactions.value.selectedTable == null ? null : next()),
                        () => {
                            const tIndex = dataSource.value.tables.findIndex((t) => t.name === interactions.value.selectedTable.name);
                            return SyncSettingsInteraction({
                                dataSource,
                                table: scope(dataSource, ["tables", tIndex]),
                                interactions,
                            });
                        }
                    ),
                },
            });
        }
    );
