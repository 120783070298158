import "./select-tenant-dialog.scss";
import * as React from "react";

import {Load} from "@common/react/load";
import {Invoke} from "@common/react/invoke";
import {Static2} from "@common/react/static-2";
import {cs} from "@common/react/chain-services";
import {fragments} from "@common/react/fragments";
import {OnUnmounted} from "@common/react/on-unmounted";

import {SearchableSelect} from "@common/ui-components/searchable-select/searchable-select";
import {createDateFormatter} from "@common/ui-components/charts/common/formatters/date-formatter";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";

import {createFetcher} from "../../../../../../tools/fetcher/fetcher";
import {DialogService} from "../../../common/dialog/dialog-service";

export const SelectTenantDialog = ({next: rootNext}) =>
    cs(
        [
            "modal",
            ({}, next) =>
                DialogService({
                    render: ({args: authToken, resolve}) => ({
                        title: "Hi Verb Admin",
                        content: next({
                            resolve,
                            authToken,
                        }),
                    }),
                    next: rootNext,
                }),
        ],

        [
            "tenants",
            ({modal}, next) =>
                Load({
                    fetch: () => getTenants({authToken: modal.authToken}),
                    next,
                }),
        ],
        ["inputRef", (_, next) => Static2({next})],

        ({modal, tenants, inputRef}) => (
            <div className="select-tenant-dialog-2zz">
                <VerbDialogBodyScrollbar>
                    <div className="messages">
                        {SearchableSelect({
                            label: "Please select a tenant to login",
                            list: tenants,
                            valueToLabel: (tenant) =>
                                tenant.name +
                                (tenants.filter((t) => t.name.toLowerCase() == tenant.name.toLowerCase()).length > 1 && tenant.billingEmail
                                    ? " (" + tenant.billingEmail + ")"
                                    : "") +
                                " | " +
                                createDateFormatter("M/d/yy").format(new Date(tenant.created)),
                            onChange: (tenant) => modal.resolve(tenant.id),
                            forcedExpandBottom: true,
                            inputRef,
                        })}
                    </div>
                </VerbDialogBodyScrollbar>
                {cs(["clearRef", (_, next) => Static2({next})], ({clearRef}) =>
                    fragments(
                        OnUnmounted({
                            action: () => {
                                const clear = clearRef.get();
                                if (clear) {
                                    clear();
                                }
                            },
                        }),
                        Invoke({
                            fn: () => {
                                const listener = () => {
                                    inputRef.get()?.blur?.();
                                };
                                window.addEventListener("resize", listener, true);
                                clearRef.set(() => {
                                    window.removeEventListener("resize", listener);
                                });
                            },
                        })
                    )
                )}
            </div>
        )
    );

const getTenants = async ({}) => {
    const fetcher = createFetcher({
        headers: {
            "x-tenant-id": "00000000-0000-0000-0000-000000000000",
        },
    });
    return await fetcher.get("/api/user/tenant/list");
};
