export const getFunnelLegendRoom = ({tile, chartWidth, chartHeight}) => {
    if (!tile.style.legendStyle.legendShown) {
        return;
    }

    if (tile.style.legendStyle.legendPosition === "Left") {
        return {paddingLeft: chartWidth * 0.4};
    }

    return {paddingRight: chartWidth * 0.4};
};
