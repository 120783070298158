import {BasicPanel} from "@common/ui-components/panel/panel";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {Form2} from "@common/react/cs-form/form2";
import {required} from "@common/react/cs-form/validators/required";
import {TextInput} from "@common/form/text-input/text-input";
import {ffToBasicInput} from "@common/form/ff-to-basic-input";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {Button} from "@common/form/buttons/button/button";
import {DatePicker} from "@common/form/date-picker/date-picker";
import {Load} from "@common/react/load";
import {consumeContext} from "@common/react/context";
import {LoadingIndicator} from "@common/ui-components/loading-indicator/loading-indicator";
import {CheckboxLineGroup} from "../../common/checkbox-line-group/checkbox-line-group";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";
import "./report-builder.scss";
import {minArrayLength} from "@common/react/cs-form/validators/min-length-array";
import {ffToDatePicker} from "@common/form/date-picker/ff-to-date-picker";
import {InfoDialog} from "../../common/info-dialog/info-dialog";
import {JSONTree} from "react-json-tree";
import {isEmpty} from "@common/utils/objects";
import {ffToDropdown} from "@common/form/ff-to-dropdown";
import {isBefore, today} from "@common/utils/dates/date-object";
import {InfoIconFull} from "@common/ui-components/charts/common/icons/icons";

export const ReportBuilder = ({reportSections}) =>
    cs(() =>
        BasicPanel({
            style: {height: "100%"},
            title: "Report Builder",
            children: () => {
                return cs(
                    consumeContext("auth"),
                    consumeContext("reportApis"),
                    consumeContext("routing"),
                    [
                        "existingReportData",
                        ({routing, reportApis}, next) =>
                            routing.params.reportId
                                ? cs(
                                      [
                                          "data",
                                          (_, next) =>
                                              Load({
                                                  fetch: () => reportApis.getReportById(routing.params.reportId),
                                                  next,
                                              }),
                                      ],
                                      ({data}) => (data ? next(data) : LoadingIndicator({}))
                                  )
                                : next({}),
                    ],
                    [
                        "state",
                        ({existingReportData}, next) =>
                            UseState({
                                initValue: existingReportData,
                                next,
                            }),
                    ],
                    ["loading", (_, next) => UseState({next})],
                    ["infoDialog", (_, next) => InfoDialog({noFooter: true, next})],
                    [
                        "form",
                        ({state, reportApis, auth, loading, infoDialog}, next) =>
                            Form2({
                                fields: {
                                    isRecurring: [],
                                    advertiserId: [required],
                                    notionReportingPageUrl: [required],
                                    name: [required],
                                    "dateConfig.startDate": {
                                        validators: [
                                            {
                                                when: (data) => data.dateConfig?.recurringType !== "BiMonthly",
                                                validate: (value) => value != null,
                                            },
                                        ],
                                        debounce: true,
                                    },
                                    "dateConfig.endDate": {
                                        validators: [
                                            {
                                                when: (data) => !data.isRecurring,
                                                validate: (value) => value != null,
                                            },
                                        ],
                                        debounce: true,
                                    },
                                    "dateConfig.recurringType": {
                                        validators: [
                                            {
                                                when: (data) => data.isRecurring,
                                                validate: (value) => value != null,
                                            },
                                        ],
                                    },
                                    sections: [required, minArrayLength(1)],
                                },
                                data: state,
                                onSubmit: async () => {
                                    loading.onChange(true);
                                    try {
                                        const res = await reportApis.saveReport({
                                            ...state.value,
                                            createdBy: [auth.user.firstName, auth.user.lastName].filter((v) => v).join(" "),
                                        });

                                        infoDialog.show({
                                            title: "Success",
                                            content: (
                                                <>
                                                    {res.message && <div className="">
                                                        {res.message}
                                                    </div>}
                                                    {res.notionReport && (
                                                        <a href={res.notionReport.reportUrl} target="_blank">
                                                            {res.notionReport.reportUrl}
                                                        </a>
                                                    )}
                                                </>
                                            ),
                                        });

                                        if (state.value.isRecurring) {
                                            state.onChange(res.report);

                                            window.history.replaceState(
                                                null,
                                                null,
                                                `#/report-builder?reportId=${res.report.id}`,
                                            );
                                        }
                                    } catch (e) {
                                        infoDialog.show({
                                            title: "Error",
                                            content: <JSONTree data={e} />,
                                        });
                                    } finally {
                                        loading.onChange(false);
                                    }
                                },
                                next,
                            }),
                    ],
                    ({form, state, loading, infoDialog, reportApis, routing, existingReportData}) => {
                        return (
                            <div className="report-builder-88k">
                                <VerbScrollbar className="main">
                                    <div className="account-settings-general-88i">
                                        <div className="section">
                                            <div className="section-header">Partner Information</div>
                                            <div className="section-content">
                                                <div className="flex-group">
                                                    {TextInput({
                                                        label: "Advertiser ID",
                                                        ...ffToBasicInput(form.field("advertiserId")),
                                                    })}

                                                    {TextInput({
                                                        label: "Order ID",
                                                        ...ffToBasicInput(form.field("orderId")),
                                                    })}
                                                </div>
                                                <div className="flex-group">
                                                    {TextInput({
                                                        label: "Notion Partner Reporting Page Link",
                                                        ...ffToBasicInput(form.field("notionReportingPageUrl")),
                                                    })}

                                                    {TextInput({
                                                        label: "Report Name",
                                                        ...ffToBasicInput(form.field("name")),
                                                    })}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="section">
                                            <div className="section-header">Report Date Configuration</div>
                                            <div className="section-content">
                                                {(() => {
                                                    const ffProps = ffToDropdown(form.field("isRecurring"), ["value"]);
                                                    return DropdownSelect({
                                                        label: "Report type",
                                                        list: [
                                                            {
                                                                value: true,
                                                                label: "Recurring Report",
                                                            },
                                                            {
                                                                value: false,
                                                                label: "One Time Report",
                                                            },
                                                        ],
                                                        valueToLabel: (v) => v.label,
                                                        ...ffProps,
                                                        onChange: ({value}) => {
                                                            form.data.change(prev => ({
                                                                ...prev,
                                                                isRecurring: value,
                                                                dateConfig: null,
                                                            }));
                                                        },
                                                    });
                                                })()}

                                                {form.data.value?.isRecurring &&
                                                    (() => {
                                                        const { state: recurringTypeState, ...others} = ffToDropdown(form.field(["dateConfig", "recurringType"]), ["value"])
                                                        return DropdownSelect({
                                                            label: "Report Frequency",
                                                            list: [
                                                                {
                                                                    value: "Weekly",
                                                                    label: "Weekly (day of week)",
                                                                },
                                                                {
                                                                    value: "BiWeekly",
                                                                    label: "Bi-Weekly (day of week)",
                                                                },
                                                                {
                                                                    value: "BiMonthly",
                                                                    label: "Bi-Monthly (first and middle of month)",
                                                                },
                                                                {
                                                                    value: "Monthly",
                                                                    label: "Monthly (date based)",
                                                                },
                                                            ],
                                                            valueToLabel: (v) => v.label,
                                                            ...others,
                                                            onChange: ({value}) => {
                                                                form.data.change(prev => ({
                                                                    ...prev,
                                                                    dateConfig: {
                                                                        recurringType: value,
                                                                    },
                                                                }));
                                                            },
                                                        })
                                                    })()}

                                                {(() => {
                                                    let _recurringType = form.data.value?.dateConfig?.recurringType;
                                                    if (_recurringType === "BiMonthly") return null;

                                                    const { state : startDateState, ...others} = ffToDatePicker(form.field(["dateConfig", "startDate"]))

                                                    return <div>
                                                        {(form.data.value.isRecurring === false || !!_recurringType) && DatePicker({
                                                            className: "date-picker",
                                                            label: "Start date",
                                                            state: {
                                                                ...startDateState,
                                                                onChange: (value) => {
                                                                    form.data.change(prev => ({
                                                                        ...prev,
                                                                        dateConfig: {
                                                                            ...prev.dateConfig,
                                                                            startDate: value,
                                                                            ...["Weekly", "BiWeekly"].includes(_recurringType) ? {
                                                                                dayOfWeek: new Date(value).getDay(),
                                                                            } : ["Monthly"].includes(_recurringType) ? {
                                                                                dayOfMonth: new Date(value).getDate(),
                                                                            } : {},
                                                                        },
                                                                    }))
                                                                }
                                                            },
                                                            ...others,
                                                            calendarProps: {
                                                                isDisabledDay: (d) => {
                                                                    const isPast = isBefore(d, today());
                                                                    return (form.data.value.isRecurring && isPast) || (_recurringType === "Monthly" && [29, 30, 31].includes(d.day));
                                                                },
                                                            },
                                                        })}

                                                        {form.data.value.isRecurring === false && (() => {
                                                            const ffProps = ffToDatePicker(form.field(["dateConfig", "endDate"]));
                                                            return DatePicker({
                                                                className: "date-picker",
                                                                label: "End date",
                                                                ...ffProps,
                                                                state: {
                                                                    ...ffProps.state,
                                                                    onChange: (v) => ffProps.state.onChange(v.split("T")[0] + "T23:59:59Z"),
                                                                },
                                                            });
                                                        })()}
                                                    </div>
                                                })()}
                                            </div>
                                        </div>

                                        <div className="section">
                                            <div className="section-header">Report Data</div>
                                            <div className="section-content">
                                                {cs(() => {
                                                    if (!reportSections) return LoadingIndicator({});
                                                    const {
                                                        state: {value, onChange},
                                                        error,
                                                    } = form.field("sections");

                                                    const rSectionInfo = (section) => {
                                                        return (
                                                            <span
                                                                style={{
                                                                    position: "relative",
                                                                    top: 4,
                                                                }}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    e.preventDefault();
                                                                    infoDialog.show({
                                                                        title: "Section",
                                                                        content: (
                                                                            <div className="edit-section-modal-0c2">
                                                                                {/*<JSONTree data={section} />*/}
                                                                                {section.children.map((c) => (
                                                                                    <div className="each-child">
                                                                                        <div
                                                                                            className="">
                                                                                            Collection
                                                                                            URL:{" "}
                                                                                            <a href={c.config.collectionUrl}
                                                                                               target={"_blank"}>{c.config.collectionUrl}</a>
                                                                                        </div>
                                                                                        <div
                                                                                            className="">
                                                                                            Data
                                                                                            API:{" "}
                                                                                            <a>{c.config.dataApi}</a>
                                                                                        </div>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                        ),
                                                                    });
                                                                }}
                                                            >
                                                                {InfoIconFull({
                                                                    width: "16px",
                                                                    color: "#bcbcbc",
                                                                })}
                                                            </span>
                                                        );
                                                    };

                                                    const isSelected = (l) => value?.findIndex((v) => v.sectionId === l.id) > -1;
                                                    return cs(() => (
                                                        <div>
                                                            <div onClick={() => onChange(reportSections.map((r) => ({sectionId: r.id})))}>Select all</div>
                                                            {CheckboxLineGroup({
                                                                hasError: !!error,
                                                                list: reportSections.map((l) => ({
                                                                    ...l,
                                                                    label: (
                                                                        <>
                                                                            {l.name}{" "}
                                                                            {/*{l.children[0].type !== "File" && rSectionInfo(l)}*/}
                                                                        </>
                                                                    ),
                                                                })),
                                                                isSelected,
                                                                onChange: (l) => {
                                                                    if (isSelected(l)) {
                                                                        onChange(value.filter((v) => v.sectionId !== l.id));
                                                                    } else {
                                                                        onChange([...(value || []), {sectionId: l.id}]);
                                                                    }
                                                                },
                                                            })}
                                                        </div>
                                                    ));
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </VerbScrollbar>

                                <div className="btns">
                                    <div className="left-side">
                                        <Button disabled={loading.value} onClick={() => form.submit()}>
                                            {loading.value ? "Saving and building" : "Save"}
                                        </Button>
                                        {/*<Button onClick={() => reportApis.testRecurring()}>test recurring</Button>*/}
                                        {/*<Button onClick={() => reportApis.stopTestRecurring()}>stop test*/}
                                        {/*    recurring</Button>*/}
                                        {/*<Button onClick={() => reportApis.listRecurringJobs()}>list recurring*/}
                                        {/*    jobs</Button>*/}
                                    </div>

                                    <div className="right-side">
                                        {state.value?.id && state.value?.isRecurring &&
                                            <>
                                                <Button
                                                    btnType="secondary"
                                                    onClick={async () => {
                                                        // loading.onChange(true)
                                                        const res = await (state.value.isDisabled ? reportApis.enableReport : reportApis.disableReport)(state.value.id);

                                                        state.onChange({
                                                            ...state.value,
                                                            isDisabled: !state.value.isDisabled,
                                                        });

                                                        infoDialog.show({
                                                            title: "Success",
                                                            content: res.message,
                                                        });
                                                    }}
                                                    disabled={state.value.isDeleted}
                                                >{state.value.isDisabled ? `Enabled` : `Disabled`}</Button>

                                                &nbsp;&nbsp;

                                                <Button
                                                    btnType="danger"
                                                    onClick={async () => {
                                                        await reportApis.deleteReport(state.value.id);
                                                        state.onChange({
                                                            ...state.value,
                                                            isDeleted: true,
                                                            isDisabled: true,
                                                        });
                                                        // routing.goto("report-builder", {reportTab: "builder"});
                                                    }}
                                                    disabled={state.value.isDeleted}
                                                >{state.value.isDeleted ? "Deleted" : "Delete"}</Button>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        );
                    }
                );
            },
        })
    );
