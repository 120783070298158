import * as React from "react";
import {cs} from "@common/react/chain-services";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import "./max-val-in-view.scss";
import {scope} from "@common/react/scope";
import {DebounceNumberInput} from "../../../../../../../../../../../../common/form/debounce-input/debounce-number-input";

export const MaxValInView = ({state, noMultiAutoOptions}) => {
    const options = noMultiAutoOptions
        ? ["Auto", "Fixed"].map((label) => ({label, value: label}))
        : ["Auto", "Auto Dense", "Auto Spacious", "Auto Max", "Fixed"].map((label) => ({
              label,
              value: label.replace(/ /g, ""),
          }));
    return (
        <div className="max-values-in-view-2fa">
            <div className="col1">
                {DropdownSelect({
                    label: "Maximum Values in View",
                    list: options,
                    isSelected: (v) => v.value === state.value.maxValuesInView,
                    valueToLabel: (v) => v.label,
                    onChange: (v) => {
                        state.onChange({
                            ...state.value,
                            maxValuesInView: v.value,
                            ...(v.value === "Fixed" && {
                                maxValuesInViewFixed: 5,
                            }),
                        });
                    },
                })}
            </div>
            <div className="col2">
                {DebounceNumberInput({
                    state: scope(state, ["maxValuesInViewFixed"]),
                    label: "Quantity",
                    disabled: state.value.maxValuesInView !== "Fixed",
                })}
            </div>
        </div>
    );
};
