import React from "react";

const FolderOpenIcon = ({className}) => (
    <svg className={className} width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M2.00004 14C1.82323 14 1.65366 13.9298 1.52864 13.8047C1.40361 13.6797 1.33337 13.5101 1.33337 13.3333V2.66667C1.33337 2.48986 1.40361 2.32029 1.52864 2.19526C1.65366 2.07024 1.82323 2 2.00004 2H6.94271L8.27604 3.33333H13.3334C13.5102 3.33333 13.6798 3.40357 13.8048 3.5286C13.9298 3.65362 14 3.82319 14 4V6H12.6667V4.66667H7.72404L6.39071 3.33333H2.66671V11.332L3.66671 7.33333H15L13.46 13.4953C13.4239 13.6395 13.3407 13.7675 13.2235 13.8589C13.1063 13.9503 12.962 14 12.8134 14H2.00004ZM13.292 8.66667H4.70804L3.70804 12.6667H12.292L13.292 8.66667Z"
            fill="#546B81"
        />
    </svg>
);

export default FolderOpenIcon;
