import React from "react";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {DropdownSelect2} from "@common/ui-components/dropdown-select/dropdown-select2";
import {scope} from "@common/react/scope";
import {AddDateOperations} from "./add/add-date-operations";
import {ConvertTimezoneOperations} from "./convert-timezone/convert-timezone-operations";
import {FromUnixEpochOperations} from "./from-unix-epoch/from-unix-epoch-operations";
import "./date-operation-configuration.scss";
import {GetPartOfDateOperations} from "./get-part-of-date/get-part-of-date-operations";
import {TruncateDateOperations} from "./truncate-date/truncate-date-operations";
import {ffToDropdown} from "../../../../../../../../../common/form/ff-to-dropdown";
import {keepOnly} from "@common/utils/objects";

export const DateOperationConfiguration = ({step, model, transformation, form}) =>
    cs(["state", (_, next) => UseState({initValue: {}, next})], ({state}) => {
        const list = [
            {value: "AddToDate", label: "Add To Date"},
            {value: "SubtractFromDate", label: "Subtract From Date"},
            {
                value: "DifferenceBetweenDates",
                label: "Difference Between Dates",
            },
            {value: "DatePart", label: "Get Part of Date"},
            {value: "DateTruncate", label: "Truncate/Round Date"},
            {value: "ConvertTimeZone", label: "Convert Time Zone"},
            {value: "FromUnixEpoch", label: "From Unix Epoch"},
        ];

        const operationConfig = (type) =>
            ({
                DatePart: GetPartOfDateOperations,
                DateTruncate: TruncateDateOperations,
                ConvertTimeZone: ConvertTimezoneOperations,
                FromUnixEpoch: FromUnixEpochOperations,
            }[type] || AddDateOperations);

        //TODO: difference type will always return action new
        const inputStep = transformation.value.steps.find((s) => s.id === step.value.inputStepID);
        const $type = step?.value?.operation?.$type;

        return (
            <div className="data-operations-configuration-4er">
                <div className="config-group">
                    <div className="label">Date Operation</div>

                    {(() => {
                        const dropdownProps = ffToDropdown(form.field(["operation"]));
                        return DropdownSelect2({
                            label: "Type",
                            list: list,
                            valueToLabel: (v) => v.label,
                            isSelected: (v) => v.value === dropdownProps.value?.$type,
                            onChange: (v) => dropdownProps.onChange({$type: v.value}),
                            ...keepOnly(dropdownProps, ["hasError", "errorMessage", "domRef"]),
                        });
                    })()}
                </div>

                {$type &&
                    operationConfig($type)({
                        operation: scope(step, ["operation"]),
                        form,
                        state,
                        model,
                        inputStep,
                    })}

                {/*{$type && comp($type)({*/}
                {/*    operation: scope(step, ["operation"]),*/}
                {/*    state*/}
                {/*})}*/}
            </div>
        );
    });
