import { cs } from "@common/react/chain-services";
import { consumeContext, provideContext } from "@common/react/context";
import { Load2 } from "@common/react/load2";

export const loadSharedTileFolders = ({ next: rootNext}) =>
    cs(
        consumeContext("routing"),
        consumeContext("apis"),
        [
            "sharedTileFolders",
            ({routing, apis}, next) =>
                Load2({
                    _key: routing.params?.envId,
                    fetch: () => apis.collectionTiles.getFolders(),
                    next,
                }),
        ],
        ({sharedTileFolders}) => provideContext("sharedTileFolders", sharedTileFolders, rootNext)
    );
