import "./filters-in-collection.scss";

import {cx} from "emotion";
import * as React from "react";

import {cs} from "@common/react/chain-services";
import {flatten1, groupBy} from "@common/utils/collections";
import {Button} from "../../../../../../../../../common/form/buttons/button/button";

import {DndFilters} from "./dnd/dnd-filters";

export const FiltersInCollection = ({collection, onAddFilter}) =>
    cs(() => {
        const noFilters = collection.value.filters.length === 0;

        return (
            <div className="filters-in-collection-3qt">
                <div className={cx("add-new-filter", {"no-filters": noFilters})}>
                    <Button className={cx("btn")} onClick={onAddFilter} btnType={!noFilters ? "secondary" : "primary"}>
                        Add New Filter
                    </Button>
                </div>

                {!noFilters &&
                    DndFilters({
                        filtersGroup: {
                            value: {
                                Visible: [],
                                BehindMore: [],
                                Hidden: [],
                                ...groupBy(collection.value?.filters || [], (v) => v.displayArea, true),
                            },
                            onChange: (newGroup) =>
                                collection.change((oldCollection) => ({
                                    ...oldCollection,
                                    filters: flatten1(
                                        Object.keys(newGroup).map((groupKey) =>
                                            newGroup[groupKey].map((item) => ({
                                                ...item,
                                                displayArea: groupKey,
                                            }))
                                        )
                                    ),
                                })),
                        },
                        collection,
                    })}
            </div>
        );
    });

export const filterIcons = {
    DateFilter: require("../../../../../common/filters/icons/date-range-icon.svg"),
    NumericFilter: require("../../../../../common/filters/icons/number-icon.svg"),
    TextFilter: require("../../../../../common/filters/icons/text-icon.svg"),
    BooleanFilter: require("../../../../../common/filters/icons/boolean-icon.svg"),
};
