import * as React from "react";
import "./add-controls.scss";
import {cx} from "emotion";
import {Button} from "../../../../../../../common/form/buttons/button/button";
import {GhostButton} from "../../../../../../../common/form/buttons/ghost-button/ghost-button";
import {ResetIcon} from "@common/ui-components/icons/global-icons";

export const AddControls = ({save, reset}) => (
    <div className="add-theme-controls-238">
        <div className="buttons">
            <Button className={cx("save-new", {disabled: save.disabled})} onClick={save.doSave}>
                Save New Theme
            </Button>
        </div>
        <GhostButton disabled={reset.disabled} iconLeft={<ResetIcon />} className={cx("reset")} onClick={reset.doReset}>
            Reset to default settings
        </GhostButton>
    </div>
);
