import * as React from "react";
import {Colors} from "./panels/colors/colors";
import {Legend} from "./panels/legend/legend";
import {OtherOptions} from "../common/other-options/other-options";
import {DefaultView} from "./panels/default-view/default-view";
import {TileTitle} from "../common/tile-title/tile-title";
// import {Locations} from "./panels/locations/locations";

export const MapTile = ({tile, form, size, mapTileColorsConfig}) =>
    [
        DefaultView,
        TileTitle,
        // Locations,
        (props) => Colors({...props, mapTileColorsConfig}),
        Legend,
        OtherOptions,
    ].map((panel) => panel({tile, form, size}));
