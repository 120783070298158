const {cascadeFind} = require("../../../utils/cascade");
const {isEmpty} = require("../../../utils/objects");
const {getFieldType} = require("../common/get-field-type");

const {groupClassicBarCompareLineSeries} = require("../line-series-processors/group-classic-bar-compare-line-series");
const {groupNonCompareBarSeries} = require("../bar-series-processors/group-non-compare-bar-series");
const {groupCompareBarSeries} = require("../bar-series-processors/group-compare-bar-series");
const {groupSeriesByStackLine} = require("../line-series-processors/group-series-by-stack-line");

const processComboChartData = ({tile, theme, rawData, tooltipOptions, formatters, isCompare, tileFields}) => {
    const lineKeys = Object.keys(tile.style.yAxisLine);
    const barKeys = Object.keys(tile.style.yAxisBar);
    const lineSeries = rawData.series.filter((s) => lineKeys.includes(s.stack));
    const barSeries = rawData.series.filter((s) => barKeys.includes(s.stack));

    const isBarDataColorAppliedByDimension = tile?.style?.barDataColorAppliedBy === "Dimension" && !cascadeFind(tile?.yAxisBarFields, "[*].measureFields", (value) => value.length > 1) && getFieldType(tile?.["xAxisField"]) !== "date" && isEmpty(tile?.groupField);
    const barGroupFn = isCompare ? groupCompareBarSeries : groupNonCompareBarSeries;
    const lineGroupFn = isCompare && (!tile.style.yAxisBarDisplayType || tile.style.yAxisBarDisplayType === "Classic") ? groupClassicBarCompareLineSeries : groupSeriesByStackLine;

    const getLineYAxis = (series) => {
        // bars first, lines second. ref. combo-chart.jsx
        const {multipleAxisBarOption, multipleAxisLineOption} = tile.style;

        if (multipleAxisBarOption !== "IndependentAxes" && multipleAxisLineOption !== "IndependentAxes") {
            return 1;
        }

        if (multipleAxisBarOption !== "IndependentAxes" && multipleAxisLineOption === "IndependentAxes") {
            return 1 + lineKeys.findIndex((s) => s === series.stack);
        }

        if (multipleAxisBarOption === "IndependentAxes" && multipleAxisLineOption === "IndependentAxes") {
            return barKeys.length + lineKeys.findIndex((s) => s === series.stack);
        }

        if (multipleAxisBarOption === "IndependentAxes" && multipleAxisLineOption !== "IndependentAxes") {
            return barKeys.length;
        }
    };

    const getBarYAxis = (series) => {
        // bars first, lines second. ref. combo-chart.jsx
        const {multipleAxisBarOption} = tile.style;

        if (multipleAxisBarOption !== "IndependentAxes") {
            return 0;
        }

        if (multipleAxisBarOption === "IndependentAxes") {
            return barKeys.findIndex((s) => s === series.stack);
        }
    };

    return {
        lineData: lineGroupFn({
            series: lineSeries,
            // shiftColor: flatten1(barKeys.map((k) => k.split("|"))).length,
            zIndex: 3,
            getYAxis: getLineYAxis,
            yAxis: 1, // connect to second yAxis, which is line yAxis
            tile,
            theme,
            tileFields: tileFields.filter((f) => f.parent == "yAxisLineFields"),
            tooltipOptions: tooltipOptions.lineTooltipOptions,
            measurementFormatters: formatters.measurementFormatters,
            usingColorGroupField: false,
        }),
        barData: barGroupFn({
            series: barSeries,
            type: "column",
            isBarDataColorAppliedByDimension,
            getYAxis: getBarYAxis,
            tile,
            theme,
            isCompare,
            tileFields: tileFields.filter((f) => f.parent != "yAxisLineFields"),
            tooltipOptions: tooltipOptions.barTooltipOptions,
            measurementFormatters: formatters.measurementFormatters,
        }),
    };
};
exports.processComboChartData = processComboChartData;
