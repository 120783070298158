import "./model-column-card.scss";

import * as React from "react";

import {cs} from "@common/react/chain-services";
import {rFieldTypeIcon} from "../field-type-icon/get-field-type-icon";
import {TruncatingTooltip} from "../truncating-tooltip/truncating-tooltip";

export const ModelColumnCard = ({modelColumn, dsInfo, nameTooltipEnabled, dataSourceTooltipEnabled}) =>
    cs(({}) => {
        return (
            <div className="model-column-card-ye2 model-column-card">
                {modelColumn.$type === "CalculatedModelColumn" && (
                    <img className="calculated-plus-icon" src={require("../../model/edit/tabs/common/icons/calculated-plus-icon.svg")} alt="" />
                )}
                {modelColumn.$type === "AggregatedMeasureModelColumn" && (
                    <img className="aggregated-measure-icon" src={require("../../model/edit/tabs/common/icons/aggregated-measure-icon.svg")} alt="" />
                )}
                {rFieldTypeIcon(modelColumn.dataType)}
                {cs(["truncatingTooltip", (_, next) => (nameTooltipEnabled ? TruncatingTooltip({next}) : next())], ({truncatingTooltip}) => (
                    <div
                        {...{
                            className: "name",
                            ...(nameTooltipEnabled && {
                                ...truncatingTooltip.tooltip(modelColumn.name),
                                ref: truncatingTooltip.ref,
                            }),
                        }}
                    >
                        {modelColumn.name}
                    </div>
                ))}
                {cs(["truncatingTooltip", (_, next) => (dataSourceTooltipEnabled ? TruncatingTooltip({next}) : next())], ({truncatingTooltip}) => (
                    <div
                        {...{
                            className: "data-source-info",
                            ...(dataSourceTooltipEnabled && {
                                ...truncatingTooltip.tooltip(
                                    <div className="">
                                        {!dsInfo ? (
                                            "No data source found"
                                        ) : (
                                            <>
                                                {dsInfo.dsName}
                                                <br />
                                                {dsInfo.dsTableName}
                                            </>
                                        )}
                                    </div>
                                ),
                                ref: truncatingTooltip.ref,
                            }),
                        }}
                    >
                        {modelColumn.$type === "ViewModelColumn"
                            ? `View Tables  >  ${modelColumn.tableName}`
                            : // DataSourceModelColumn, CalculatedModelColumn, AggregatedMeasureModelColumn
                            !dsInfo
                            ? "No data source found"
                            : `${dsInfo.dsName}  >  ${dsInfo.dsTableName}`}
                    </div>
                ))}
            </div>
        );
    });
