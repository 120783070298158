const {consumeContext} = require("../../../../react/context");
const {Load} = require("../../../../react/load");
const {cs} = require("../../../../react/chain-services");

const GetConnectedFilters = ({collection, filterForms, next}) =>
    cs(
        consumeContext("apis"),
        [
            "validTiles",
            ({apis}, next) =>
                Load({
                    _key: collection.filters.length,
                    fetch: () =>
                        Promise.all(
                            collection.filters.map(async (filter) => {
                                return {
                                    filterId: filter.id,
                                    validTiles: await apis.collection.getValidTiles({
                                        collectionID: collection.id,
                                        filterId: filter.id,
                                    }),
                                };
                            })
                        ),
                    next,
                }),
        ],
        ({validTiles}) =>
            next(
                !validTiles
                    ? null
                    : (tileId) =>
                          collection.filters
                              .map((filter) => {
                                  if (filter.disconnectedTiles?.includes(tileId)) {
                                      return null;
                                  }
                                  if (!validTiles.find((r) => r.filterId === filter.id)?.validTiles?.validTileIDs?.includes?.(tileId)) {
                                      return null;
                                  }
                                  return filter.id;
                              })
                              .filter((v) => v)
            )
    );
exports.GetConnectedFilters = GetConnectedFilters;
