import {cs} from "@common/react/chain-services";
import * as React from "react";
import {IgnoreUpdate} from "@common/react/ignore-update";

export const MoveIconSvg = ({fill = "#1a3748"}) =>
    cs(
        ({}, next) => IgnoreUpdate({next, unless: (pp) => pp !== fill, props: fill}),
        () => (
            <svg width="14" height="5" viewBox="0 0 14 5">
                <g transform="translate(14) rotate(90)">
                    <circle cx="1" cy="1" r="1" transform="translate(3 9)" fill={fill} />
                    <circle cx="1" cy="1" r="1" transform="translate(3 6)" fill={fill} />
                    <circle cx="1" cy="1" r="1" transform="translate(3 12)" fill={fill} />
                    <circle cx="1" cy="1" r="1" transform="translate(0 9)" fill={fill} />
                    <circle cx="1" cy="1" r="1" transform="translate(0 6)" fill={fill} />
                    <circle cx="1" cy="1" r="1" transform="translate(0 12)" fill={fill} />
                </g>
            </svg>
        )
    );

export const MoveIconVertical = ({fill = "#D1D8DE"}) => (
    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.27085 10.3751C3.27085 11.1772 2.6146 11.8334 1.81252 11.8334C1.01044 11.8334 0.354187 11.1772 0.354187 10.3751C0.354187 9.573 1.01044 8.91675 1.81252 8.91675C2.6146 8.91675 3.27085 9.573 3.27085 10.3751ZM1.81252 4.54175C1.01044 4.54175 0.354187 5.198 0.354187 6.00008C0.354187 6.80217 1.01044 7.45842 1.81252 7.45842C2.6146 7.45842 3.27085 6.80217 3.27085 6.00008C3.27085 5.198 2.6146 4.54175 1.81252 4.54175ZM1.81252 0.166748C1.01044 0.166748 0.354187 0.822998 0.354187 1.62508C0.354187 2.42716 1.01044 3.08341 1.81252 3.08341C2.6146 3.08341 3.27085 2.42716 3.27085 1.62508C3.27085 0.822998 2.6146 0.166748 1.81252 0.166748ZM6.18752 3.08341C6.9896 3.08341 7.64585 2.42716 7.64585 1.62508C7.64585 0.822998 6.9896 0.166748 6.18752 0.166748C5.38544 0.166748 4.72919 0.822998 4.72919 1.62508C4.72919 2.42716 5.38544 3.08341 6.18752 3.08341ZM6.18752 4.54175C5.38544 4.54175 4.72919 5.198 4.72919 6.00008C4.72919 6.80217 5.38544 7.45842 6.18752 7.45842C6.9896 7.45842 7.64585 6.80217 7.64585 6.00008C7.64585 5.198 6.9896 4.54175 6.18752 4.54175ZM6.18752 8.91675C5.38544 8.91675 4.72919 9.573 4.72919 10.3751C4.72919 11.1772 5.38544 11.8334 6.18752 11.8334C6.9896 11.8334 7.64585 11.1772 7.64585 10.3751C7.64585 9.573 6.9896 8.91675 6.18752 8.91675Z"
            fill={fill}
        />
    </svg>
);
