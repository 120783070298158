import * as React from "react";
import {cs} from "../../react/chain-services";
import {Dropdown} from "../../ui-components/dropdown/dropdown";
import "./date-picker.scss";
import {BasicInput} from "../common/basic-input";
import {StateProxy} from "../../react/state-proxy";
import {transformState} from "../../react/transform-state";
import {parseDateTime} from "../../logic/date-time/parse-date-time";
import {formatDateTime} from "../../logic/date-time/format-date-time";
import {formatDateLong} from "../../ui-components/formats/format-date-long";
import {parseDateLong} from "../../ui-components/formats/parse-date-long";
import {bindInput} from "../../react/bind-input";
import {Calendar1} from "./calendar1/calendar1";
import {cx} from "emotion";

export const DatePicker = ({label, state, domRef, hasError, className, calendarProps = {}}) =>
    cs(
        [
            "state",
            (_, next) =>
                next(
                    transformState({
                        state,
                        goingIn: (strDate) => parseDateTime(strDate)?.date,
                        gettingOut: (dateObj) => formatDateTime({date: dateObj}),
                    })
                ),
        ],
        ({state}) => (
            <Dropdown
                minExpandHeight={180}
                className={cx("date-picker-2dm", className)}
                renderToggle={({showExpand, showingExpand}) => (
                    <div className="toggle" onClick={() => showExpand(!showingExpand)} ref={domRef}>
                        {BasicInput({
                            label,
                            hasError,
                            renderInput: () =>
                                cs(
                                    [
                                        "proxy",
                                        (_, next) =>
                                            StateProxy({
                                                state: transformState({
                                                    state: {
                                                        value: state.value,
                                                        onChange: (v) => {
                                                            state.onChange(v);
                                                            showExpand(false);
                                                        },
                                                    },
                                                    goingIn: (date) => (!date ? "" : formatDateLong(date)),
                                                    gettingOut: (str) => (!str ? null : parseDateLong(str)),
                                                }),
                                                condition: (str) => str && parseDateLong(str),
                                                next,
                                            }),
                                    ],
                                    ({proxy}) => (
                                        <input
                                            {...{
                                                ...bindInput(proxy.state),
                                                onBlur: proxy.flush,
                                            }}
                                        />
                                    )
                                ),
                            hasValue: state.value,
                        })}
                        <img src={require("./calendar-icon.svg")} alt="" />
                    </div>
                )}
                renderExpand={({close}) => (
                    <div className="calendar">
                        {Calendar1({
                            calendarProps,
                            state: {
                                ...state,
                                onChange: (d) => {
                                    state.onChange(d);
                                    close();
                                },
                            },
                        })}
                    </div>
                )}
            />
        )
    );
