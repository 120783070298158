import React from "react";
import {css, cx} from "emotion";

import {cs} from "../../../../../react/chain-services";
import {consumeContext} from "../../../../../react/context";
import {StyledClass} from "@common/react/styled-class";

export const DownloadMenuItem = ({label, onClick, otherCtrl = null}) =>
    cs(consumeContext("theme"), ({theme}) => {
        const {menuBackgroundColorRGB, menuTextColorRGB, menuCornerRadius, menuHoverBackgroundColorRGB} = theme.general.components;

        return cs(
            [
                "downloadMenuCss",
                (_, next) =>
                    StyledClass({
                        content: {
                            background: `${menuBackgroundColorRGB}`,
                            color: `${menuTextColorRGB}`,

                            "&:first-child": {
                                "border-top-left-radius": `${menuCornerRadius}px`,
                                "border-top-right-radius": `${menuCornerRadius}px`,
                            },

                            "&:last-child": {
                                "border-bottom-left-radius": `${menuCornerRadius}px`,
                                "border-bottom-right-radius": `${menuCornerRadius}px`,
                            },

                            "&:hover": {
                                background: `${menuHoverBackgroundColorRGB}`,
                            },

                            "&.selected": {
                                background: `${menuHoverBackgroundColorRGB}`,
                            },
                        },
                        next,
                    }),
            ],
            ({downloadMenuCss}) => (
                <div className={cx("filter-report-tile", downloadMenuCss)} onClick={onClick}>
                    {label}
                    {otherCtrl?.({
                        downloadMenuCss,
                        componentsStyle: theme.general.components,
                    })}
                </div>
            )
        );
    });
