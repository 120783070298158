import {cLsJson} from "@common/react/ls-json";

const lsKey = "VERB_RECENT_SECTION";
const lsJson = cLsJson(lsKey);

if (Array.isArray(lsJson.get())) {
    lsJson.set({});
}
// limit 10 items latest
export const recentStorage = {
    add: (item) => {
        const lsStore = lsJson.get();
        let ret = [];

        if (!lsStore?.[item.envId]) {
            ret = [item];
        } else {
            ret = [item, ...(lsStore[item.envId] ?? []).filter((ci) => ci.item.id !== item.item.id)];
            if (ret.length > 10) {
                ret = ret.slice(1);
            }
        }

        lsJson.set({
            ...(lsStore ?? {}),
            [item.envId]: ret,
        });
    },
    remove: (envId, fn) => {
        const lsStore = lsJson.get();
        lsJson.set({
            ...(lsStore ?? {}),
            [envId]: (lsStore?.[envId] ?? []).filter(fn),
        });
    },
    get: (envId) => {
        const lsStore = lsJson.get();
        return lsStore?.[envId] ?? [];
    },
};
