import "./create-relationship.scss";

import React from "react";

import {cs} from "@common/react/chain-services";
import {LeftPanelClosable} from "../../../../../common/left-panel-closable/left-panel-closable";
import {ModelPanelHelper} from "../../../../common/model-panel-helper";
import {spc} from "@common/react/state-path-change";
import {Button} from "@common/form/buttons/button/button";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";
import {CRColumnList} from "./column-list/cr-column-list";
import {CrTableSection} from "./table-section/cr-table-section";
import {JoinTypeDropdown} from "./join-type-dropdown/join-type-dropdown";

export const CreateRelationshipLeftPanel = ({
    onCancel,
    relationship,
    suggestedRelationships,
    table,
    target,
    dataSources,
    editRelationship,
    createNewRelationship: onUpdate,
    model,
    deSelectTable,
    tables,
    savingQueue,
}) => {
    if (table && target) {
        let groupedRelationships = [];
        for (let relationship of model.value.relationships) {
            if (
                (table.columns.find((c) => c.id == relationship.leftColumnID) &&
                    target.columns.find((c) => c.id == relationship.rightColumnID)) ||
                (table.columns.find((c) => c.id == relationship.rightColumnID) &&
                    target.columns.find((c) => c.id == relationship.leftColumnID))
            ) {
                groupedRelationships.push(relationship);
            }
        }

        if (groupedRelationships.length > 0) {
            const isTableLeft = table.columns.find((c) => c.id == groupedRelationships[0].leftColumnID);

            if (isTableLeft) {
                editRelationship({
                    groupedRelationships,
                    table,
                    target,
                });
            } else {
                editRelationship({
                    groupedRelationships,
                    table: target,
                    target: table,
                });
            }

            return;
        }
    }

    return cs(
        ({}, next) => (
            <LeftPanelClosable
                {...{
                    onClose: onCancel,
                    label: <div className="create-relationship-03s">CREATE RELATIONSHIP</div>,
                    content: next(),
                }}
            />
        ),
        () => {
            const isValidRelationship = relationship?.leftColumnID && relationship?.rightColumnID;
            const relationshipFound = ModelPanelHelper.isHasRelationship({relationship, relationships: model.value.relationships});

            const select = (updated) =>
                onUpdate({
                    table,
                    suggestedRelationships,
                    target,
                    tables,
                    allowBack: true,
                    relationship: updated,
                });

            return (
                <div className="create-relationship-content">
                    <div className="join-type-section">
                        <div className="text-heading">1. Confirm Join Type</div>

                        {JoinTypeDropdown({
                            value: relationship.joinType,
                            onChange: (joinType) =>
                                select({
                                    ...relationship,
                                    joinType,
                                }),
                        })}
                    </div>

                    <div className="select-related-fields-section">
                        <div className="text-heading">2. Select Related Fields</div>

                        <CrTableSection
                            table={table}
                            target={target}
                            relationship={relationship}
                            dataSources={dataSources}
                            onSwap={() => {
                                onUpdate({
                                    relationship: {
                                        ...relationship,
                                        leftColumnID: relationship.rightColumnID,
                                        rightColumnID: relationship.leftColumnID,
                                    },
                                    table: target,
                                    target: table,
                                    suggestedRelationships,
                                    allowBack: true,
                                });
                            }}
                        />

                        <VerbScrollbar className="columns-select-section">
                            <div className="columns-select-wrapper">
                                <div className="column-select-col">
                                    {CRColumnList({
                                        table,
                                        selected: (columnID) => relationship?.leftColumnID == columnID,
                                        onSelect: (columnID) =>
                                            select({
                                                ...relationship,
                                                leftColumnID: columnID,
                                            }),
                                        disabled: (columnID) =>
                                            model.value.relationships.find((r) =>
                                                ModelPanelHelper.isEqualsRelationship(r, {
                                                    leftColumnID: columnID,
                                                    rightColumnID: relationship?.rightColumnID,
                                                })
                                            ) || relationshipFound,
                                    })}
                                </div>

                                <div className="column-select-col">
                                    {target &&
                                        CRColumnList({
                                            table: target,
                                            selected: (columnID) => relationship?.rightColumnID == columnID,
                                            onSelect: (columnID) =>
                                                select({
                                                    ...relationship,
                                                    rightColumnID: columnID,
                                                }),
                                            disabled: (columnID) =>
                                                model.value.relationships.find((r) =>
                                                    ModelPanelHelper.isEqualsRelationship(r, {
                                                        leftColumnID: relationship.leftColumnID,
                                                        rightColumnID: columnID,
                                                    })
                                                ) || relationshipFound,
                                        })}
                                </div>
                            </div>
                        </VerbScrollbar>
                    </div>

                    <div className="save-action">
                        <Button
                            btnType="primary"
                            disabled={!isValidRelationship || savingQueue.value}
                            onClick={() => {
                                spc(model, ["relationships"], (relationships) => [...(relationships || []), relationship]);
                                onCancel();
                            }}
                        >
                            Save Relationship
                        </Button>
                    </div>
                </div>
            );
        }
    );
};
