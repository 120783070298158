import {isEmpty as isObjectEmpty} from "@common/utils/objects";

export const actionFieldsType = {
    VerticalBarChartTile: ["xAxisField", "groupField"],
    HorizontalBarChartTile: ["yAxisField", "groupField"],
    LineChartTile: ["xAxisField", "groupField"],
    ComboChartTile: ["xAxisField", "groupField"],
    PieChartTile: ["groupField"],
    DonutChartTile: ["groupField"],
    TableTile: ["actualValueFields"],
    SingleKPITile: ["valueField"],
    TableKPITile: ["groupField"],
    GaugeTile: ["valueField"],
    PivotTableTile: ["valueFields", "rowFields", "columnFields"],
    FunnelChartTile: ["groupField"],
    MapTile: ["countryField", "provinceField", "countyField", "postalCodeField"],
    VennDiagramTile: ["groupField"],
    ScatterPlotTile: ["dimensionField", "colorGroupField", "shapeGroupField"],
    BubbleChartTile: ["dimensionField", "colorGroupField"],
    BoxPlotTile: ["xAxisField"],
    SparkLineKPITile: ["yAxisField"],
};

export const getActionFields = (tile, getFieldInfo = (v) => v) =>
    actionFieldsType[tile.value.$type].reduce((listField, field) => {
        const value = tile.value[field];

        if (!value || isObjectEmpty(value)) {
            return listField;
        }

        const enhancementField = (_field) => ({
            ...getFieldInfo(_field),
            id: _field.id,
            _fieldProp: field,
        });

        if (Array.isArray(value)) {
            return listField.concat(value.map(enhancementField));
        }

        if (value.measureFields) {
            return listField.concat(value.measureFields.map(enhancementField));
        }

        return listField.concat(enhancementField(value));
    }, []);

export const getErrorMessageFieldControlFilter = (tileAction, filters, field) => {
    if (tileAction.$type == "ControlCollectionFiltersAction") {
        if (field.dateProperties && ["Days", "Weeks", "Months", "Quarters", "Years"].indexOf(field.dateProperties.aggregation) == -1) {
            return "This field aggregated is aggregated under 1 day so filters cannot apply. The action will not be shown to the end-user.";
        }

        const foundFilter = filters.find((f) =>
            f.columns.find((column) => {
                if (column.modelColumnID == field.modelColumnID && column.modelTableID == field.modelTableID) return true;
                return (
                    field.measureFields &&
                    field.measureFields.find((f) => column.modelColumnID == f.modelColumnID && column.modelTableID == f.modelTableID)
                );
            })
        );

        if (!foundFilter) {
            return "There is no dashboard filter on this field.";
        }
    }

    return null;
};
