// const {drawComparisonMarks} = require("./draw-comparison-marks");
// const {forceMaxValuesInView} = require("./force-max-values-in-view");
// const {adjustBarDataLabels} = require("./adjust-bar-data-labels");
const {getTooltipOptions} = require("../../factory/tooltip/tooltip-options");
const {SERIES_BOOST_THRESHOLD} = require("../../common/axis-chart-boost-turbo-constants");
const Highcharts = require("highcharts/highstock");
const {getDPath} = require("@common/ui-components/charts/render-chart/render-chart-helper");

const rel = Highcharts.relativeLength;
const getBoxPlotOptions = ({tile, theme, isCompare, rawData, formatters, onClickPoint}) => {

    const chart = {
        type: 'boxplot',
        backgroundColor: theme.general.tile.styles.tileBackgroundColorRGB,
        events: {
            render: function() {
                let chart = this;
                const renderer = chart.renderer;
                let points = chart.series[0].points

                points.forEach((p) => {
                    let htmlCollection = p.graphic.element.children;

                    if(htmlCollection) {
                        let hasEndCap = htmlCollection.length === 4;

                        let path = htmlCollection.item(hasEndCap ? 2 : 1);
                        let pathBBox = path.getBBox()

                        const id = Highcharts.uniqueKey();
                        const clipPath = renderer.createElement('clipPath').attr({
                            id,
                        }).add(renderer.defs);

                        renderer.path(getDPath({
                            rTopLeft: rel(1),
                            rTopRight: rel(1),
                            rBottomRight: rel(1),
                            rBottomLeft: rel(1),
                            x: pathBBox.x,
                            y: pathBBox.y,
                            width: pathBBox.width,
                            height: pathBBox.height,
                        })).add(clipPath);
                        path.setAttribute('clip-path', `url(#${id})`);
                    }
                })
            }
        }
    };

    const title = {
        text: undefined,
    };

    const tooltip = getTooltipOptions({tile, theme, isCompare, formatters, rawData});

    // const legend = getLegendOptions({style: tile.style.legendStyle, theme});

    return {
        boost: {
            enabled: true,
            seriesThreshold: SERIES_BOOST_THRESHOLD,
            useGPUTranslations: true,
            usePreAllocated: true
        },
        chart,
        title,
        plotOptions: {
            "boxplot" : {
                cursor: onClickPoint ? 'pointer' : 'default',
                pointWidth: 30,
                lineWidth: 0,
                stemWidth: 1,
                ...!tile.style.isEndCapLineEnabled ? { whiskerLength: false } : {},
                point: {
                    events: {
                        click: function({x, y, point}) {
                            onClickPoint?.({x, y, point, chart: this.series.chart});
                        }
                    }
                }
            }
        },
        tooltip,
        // legend,
        credits: {
            enabled: false
        },
    };
};
exports.getBoxPlotOptions = getBoxPlotOptions;
