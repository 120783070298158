import "./env-dashboard-layout.scss";

import React from "react";
import {cs} from "@common/react/chain-services";
import {keyed} from "@common/react/keyed";
import {consumeContext, provideContext} from "@common/react/context";
import {Tabs} from "@common/ui-components/tabs/tabs";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";
import {ToastWithAction} from "../../../common/toast/toast-with-action";
import {cx} from "emotion";
import {SuspendUpdate} from "@common/react/suspend-update";
import {Watch} from "@common/react/watch";

export const EnvDashboardLayout = ({tab, envId, tabs}) =>
    cs(
        consumeContext("tenant"),
        ["envDashboardToast", (_, next) => ToastWithAction({next})],
        ({envDashboardToast}, next) => provideContext("envDashboardToast", envDashboardToast, next),
        ({tenant, envDashboardToast}) => {
            const {
                name,
                label,
                content,
                actions,
                hasSubTabs = false,
                activeTab,
                subTabs,
                showTabName,
                rightPanel,
            } = tabs.find((t) => t.name === tab.value) || {};

            return (
                <>
                    {/* close right panel when changing tab on left panel */}
                    {Watch({
                        value: tab.value,
                        onChanged: () => rightPanel?.onClose(),
                    })}

                    {envDashboardToast.render()}
                    <div className="env-dashboard-layout-2re">
                        <div className="env-dashboard-header">
                            <div className="env-name">
                                <span
                                    style={{
                                        color: showTabName ? "#919EB0" : "",
                                    }}
                                >
                                    {tenant.environments?.find((e) => e.id === envId)?.name}
                                </span>

                                {showTabName && <span className="tab-name">&nbsp;/ {label}</span>}
                            </div>

                            {actions && <div className="actions">{actions}</div>}

                            {hasSubTabs && Tabs({activeTab, list: subTabs})}
                        </div>

                        <div className="env-dashboard-content">{cs(keyed(tab.value), () => content)}</div>

                        {rightPanel && (
                            <div
                                className={cx("right-panel", {
                                    active: !!rightPanel?.content,
                                })}
                            >
                                {cs(
                                    ({}, next) =>
                                        SuspendUpdate({
                                            active: !rightPanel?.content,
                                            delay: 500,
                                            next,
                                        }),
                                    () => rightPanel?.content
                                )}
                            </div>
                        )}
                    </div>
                </>
            );
        }
    );
