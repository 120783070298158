import {Static2} from "@common/react/static-2";
const {equalDeep} = require("../../../../common/utils/objects");
const {isDev} = require("../../../../tools/dev/is-dev");
const {Invoke} = require("@common/react/invoke");
const {cs} = require("@common/react/chain-services");

export const loadFullStory = ({next, auth}) =>
    cs(
        [
            "isReady",
            (_, next) => {
                return Static2({
                    next,
                    getInitValue: () => {
                        let triggerResolve = null;
                        let result = new Promise((resolve) => {
                            triggerResolve = resolve;
                        });

                        return {
                            isReady: result,
                            resolve: triggerResolve,
                        };
                    },
                });
            },
        ],
        ({isReady}, next) =>
            Invoke({
                onMounted: async () => {
                    if (isDev()) {
                        isReady.get().resolve(null);
                    } else {
                        window["_fs_debug"] = false;
                        window["_fs_host"] = "fullstory.com";
                        window["_fs_script"] = "edge.fullstory.com/s/fs.js";
                        window["_fs_org"] = "X9H5C";
                        window["_fs_namespace"] = "FS";
                        (function (m, n, e, t, l, o, g, y) {
                            if (e in m) {
                                if (m.console && m.console.log) {
                                    m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');
                                }
                                return;
                            }
                            g = m[e] = function (a, b, s) {
                                g.q ? g.q.push([a, b, s]) : g._api(a, b, s);
                            };
                            g.q = [];
                            o = n.createElement(t);
                            o.async = 1;
                            o.crossOrigin = "anonymous";
                            o.src = "https://" + _fs_script;
                            y = n.getElementsByTagName(t)[0];
                            y.parentNode.insertBefore(o, y);
                            g.identify = function (i, v, s) {
                                g(l, {uid: i}, s);
                                if (v) g(l, v, s);
                            };
                            g.setUserVars = function (v, s) {
                                g(l, v, s);
                            };
                            g.event = function (i, v, s) {
                                g("event", {n: i, p: v}, s);
                            };
                            g.anonymize = function () {
                                g.identify(!!0);
                            };
                            g.shutdown = function () {
                                g("rec", !1);
                            };
                            g.restart = function () {
                                g("rec", !0);
                            };
                            g.log = function (a, b) {
                                g("log", [a, b]);
                            };
                            g.consent = function (a) {
                                g("consent", !arguments.length || a);
                            };
                            g.identifyAccount = function (i, v) {
                                o = "account";
                                v = v || {};
                                v.acctId = i;
                                g(o, v);
                            };
                            g.clearUserCookie = function () {};
                            g._w = {};
                            y = "XMLHttpRequest";
                            g._w[y] = m[y];
                            y = "fetch";
                            g._w[y] = m[y];
                            if (m[y])
                                m[y] = function () {
                                    return g._w[y].apply(this, arguments);
                                };
                            g._v = "1.2.0";
                        })(window, document, window["_fs_namespace"], "script", "user");

                        window["_fs_ready"] = function () {
                            isReady.get().resolve(window.FS);
                        };
                    }
                },
                next,
            }),
        ({isReady}) => {
            if (auth.user === null || typeof auth.user === "undefined") return next();
            const fs1 = isReady.get();

            fs1.isReady.then((fs) => {
                if (fs == null) return;
                if (!isDev()) {
                    // if (equalDeep(auth.user?.roles, ["Verb"])) {
                    //     fs.shutdown();
                    // } else
                    if (auth.user?.id) {
                        fs.identify(auth.user.id, {
                            displayName: `${auth.user.firstName} ${auth.user.lastName}`,
                            email: auth.user.email,
                        });
                    }
                }
            });

            return next(fs1);
        }
    );
