import * as React from "react";
import {cs} from "@common/react/chain-services";
import {DialogService} from "../../../../../../common/dialog/dialog-service";
import "./remove-table-dialog.scss";
import {consumeContext} from "@common/react/context";
import {Button} from "@common/form/buttons/button/button";
import {CircularProgressBar} from "../../../../../../common/radial-progress/circular-progress-bar";
import {UseState} from "@common/react/use-state";
import {DependenciesFound} from "./dependencies-found";
import {CloseIcon} from "../../../../../../common/icons/close-icon";

export const RemoveTableDialog = ({model, onDone, next: rootNext}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    render: ({resolve, args: {table}}) => ({
                        title: "Remove table confirmation?",
                        width: 480,
                        content: next({
                            resolve,
                            table,
                        }),
                    }),
                    next: rootNext,
                    customizeDialog: true,
                    registryRender: true,
                }),
        ],
        consumeContext("getModelColumn"),
        (props) =>
            cs(
                consumeContext("apis"),
                consumeContext("dataViewTransforms"),
                ["progress", (_, next) => UseState({next, initValue: 0})],
                ["collectionErrors", (_, next) => UseState({next, initValue: null})],
                ({progress, apis, dataViewTransforms, collectionErrors}) => {
                    const {
                        modal: {table, resolve},
                        getModelColumn,
                    } = props;

                    const removeTable = () => {
                        let newTables;
                        if (table.$type == "ViewModelTable") {
                            newTables = model.value.tables.map((t) => (t.id == table.id ? {...t, excludeFromModel: true} : t));
                        } else {
                            newTables = model.value.tables.filter((t) => t.id !== table.id);
                        }

                        const newRelationships = model.value.relationships
                            .map((r) => {
                                const tableIds = [r.leftColumnID, r.rightColumnID]
                                    .map((id) => getModelColumn?.(id)?.table.id || null)
                                    .filter((id) => !!id);
                                if (!tableIds.includes(table.id)) {
                                    return r;
                                }
                            })
                            .filter((v) => v);

                        model.change(() => ({
                            ...model.value,
                            tables: newTables,
                            relationships: newRelationships,
                        }));

                        resolve();
                        onDone?.();
                    };

                    if (collectionErrors.value) {
                        return DependenciesFound({
                            table,
                            model,
                            dataViewTransforms: dataViewTransforms.value,
                            collectionErrors: collectionErrors.value,
                            onRemove: removeTable,
                            onDismiss: resolve,
                        });
                    }

                    return (
                        <>
                            <div className="header dialog-header">
                                Remove table confirmation?
                                <div className="close" onClick={() => resolve()}>
                                    <CloseIcon fill="#919EB0" />
                                </div>
                            </div>

                            <div className="remove-table-dialog-kl8 main">
                                <div className="dialog-body">
                                    <div className="heading-text">Are you sure you want to remove this table?</div>

                                    <div className="dialog-description">
                                        <div>
                                            If this model or any collection has a dependency on this table, the dependency will be removed
                                            or will be in error. To see everything that will be affected, run the dependency check before
                                            removing the table.
                                        </div>

                                        <div>
                                            Any published collections that are affected will continue to work until you next publish them.
                                        </div>
                                    </div>
                                </div>

                                <div className="buttons">
                                    <Button
                                        btnType="border"
                                        onClick={() => {
                                            resolve();
                                        }}
                                    >
                                        Cancel
                                    </Button>

                                    <Button btnType="secondary" onClick={removeTable}>
                                        Remove Table
                                    </Button>

                                    <Button
                                        btnType="primary"
                                        iconRight={
                                            !!progress.value &&
                                            CircularProgressBar({
                                                progressColor: "#FFFFFF",
                                                holeColor: "#11A1FD",
                                                progress: progress.value / 100,
                                                size: 16,
                                            })
                                        }
                                        disabled={progress.value}
                                        onClick={async () => {
                                            progress.onChange(progress.value + 1);
                                            const interval = setInterval(() => {
                                                progress.change((v) => Math.min(v + 1, 100));
                                            }, 100);

                                            const resp = await apis.model.runDependencyCheckBeforeDeleteTable({
                                                modelID: model.value.id,
                                                modelTableID: table.id,
                                            });
                                            clearInterval(interval);
                                            progress.onChange(100);
                                            collectionErrors.onChange(resp);
                                        }}
                                    >
                                        Run Dependency Check
                                    </Button>
                                </div>
                            </div>
                        </>
                    );
                }
            )
    );
