import {cs} from "../../../../../../../../react/chain-services";
import * as React from "react";
import "./checkboxes.scss";
import {css, cx} from "emotion";
import {Checkbox} from "../../../../../../../form/checkbox/checkbox";

export const Checkboxes = ({state, data, theme}) =>
    cs(({}) => {
        const inputTheme = theme.general.components;

        return (
            <div
                className={cx("checkboxes-j53")}
                style={{
                    border: `${inputTheme.inputBorderWidth}px solid ${inputTheme.inputBorderColorRGB}`,
                    "border-radius": `${inputTheme.inputCornerRadius}px`,
                }}
            >
                {data?.map((d, i) => {
                    const selected = state.value?.includes(d);
                    return (
                        <div
                            key={i}
                            className={cx("checkbox-item", {selected})}
                            style={{
                                color: `${inputTheme.inputTextColorRGB}`,
                                "fontFamily": `${theme.general.canvas.fontFamily || "Roboto"}, sans-serif`,
                            }}
                        >
                            {Checkbox({
                                state: {
                                    value: selected,
                                    onChange: () => state.change((list) => (selected ? list.filter((e) => e !== d) : [...list, d])),
                                },
                                background: theme.general.buttons.primaryButton.backgroundColorRGB,
                            })}
                            {d || "Blank / Empty"}
                        </div>
                    );
                })}
            </div>
        );
    });
