import * as React from "react";

import {FreeFormText} from "./free-form-text";
import {Invoke} from "@common/react/invoke";
import {cs} from "@common/react/chain-services";
import {spc} from "@common/react/state-path-change";

export const TextFilterOptions = ({filter, form}) =>
    cs(({}) => (
        <>
            {filter.value?.option !== "FreeFormText" &&
                Invoke({
                    fn: () => spc(filter, ["option"], () => "FreeFormText"),
                })}

            {filter.value?.option === "FreeFormText" && FreeFormText({filter, form})}
        </>
    ));
