const {fragments} = require("../../../../../react/fragments");
const {UseState} = require("../../../../../react/use-state");
const {GlobalEvent} = require("../../../../../react/global-event");
const {cs} = require("../../../../../react/chain-services");

const ObserveWindowHeight = ({getSize, next}) =>
    cs(
        [
            "state",
            (_, next) =>
                UseState({
                    getInitValue: () => getSize(window.innerHeight),
                    next,
                }),
        ],
        ({state}) =>
            fragments(
                next(state.value),

                GlobalEvent({
                    eventName: "resize",
                    fn: () => {
                        const newSize = getSize(window.innerHeight);
                        if(state.value !== newSize) {
                            state.onChange(newSize);
                        }
                    },
                })
            )
    );
exports.ObserveWindowHeight = ObserveWindowHeight;
