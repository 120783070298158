import {ValueFormat} from "./panels/value-format/value-format";
import {TileTitle} from "./panels/tile-title/tile-title";
import {KpiColors} from "../table-kpi/panels/kpi-colors/kpi-colors";
import {ValueDisplay} from "./panels/value-display/value-display";
import {Comparison} from "./panels/comparison/comparison";

export const SingleKPI = ({tile, size, form, parentTile, tileAction, kpiColorsTileConfig, isContainerTile}) =>
    [
        // Display,
        TileTitle,
        ValueDisplay,
        Comparison,
        // ValueFormat,
        // KpiColors
    ].map((panel) =>
        panel({
            tile,
            size,
            form,
            parentTile,
            tileAction,
            kpiColorsTileConfig,
            isContainerTile,
        })
    );
