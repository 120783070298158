import React from "react";
import ReactDOM from "react-dom";
import {cs} from "@common/react/chain-services";
import {FieldSection} from "../../../../../../collection/tile/edit/left-panel/tabs/fields-tab/chart-types/common/field-section/field-section";
import {FieldInfoProvider} from "../../../../../../collection/tile/edit/left-panel/tabs/tile-tab/chart-types/common/field-info-provider/field-info-provider";
import {LpColorLine} from "../../../../../../common/left-panel/lp-color-line";
import {ffToColorPickerLine} from "../../../../../../../../../common/form/ff-to-color-picker-line";
import {NumberInput} from "../../../../../../../../../common/form/number-input/number-input";
import {UseState} from "@common/react/use-state";
import {cx} from "emotion";
import {SuspendUpdate} from "@common/react/suspend-update";
import {LeftPanelClosable} from "../../../../../../common/left-panel-closable/left-panel-closable";
import {ComponentAndInputTheme} from "../../component-and-input/component-and-input-theme";
import {ffToNumberInput} from "../../../../../../../../../common/form/ff-to-number-input";

export const ContainerTileDropdownTheme = ({form, overrideLeftPanelRef, viewTree}) =>
    cs(["selected", (_, next) => UseState({next, initValue: false})], ({selected}) => {
        return (
            <div className="content">
                {LpColorLine({
                    label: "Background color",
                    ...ffToColorPickerLine(form.field(["general", "tile", "containerTiles", "dropdownStyle", "backgroundColorRGB"])),
                })}

                {LpColorLine({
                    label: "Text color",
                    ...ffToColorPickerLine(form.field(["general", "tile", "containerTiles", "dropdownStyle", "fontColorRGB"])),
                })}

                {LpColorLine({
                    label: "Border color",
                    ...ffToColorPickerLine(form.field(["general", "tile", "containerTiles", "dropdownStyle", "borderColorRGB"])),
                })}

                {NumberInput({
                    label: "Border Width",
                    unit: "px",
                    ...ffToNumberInput(form.field(["general", "tile", "containerTiles", "dropdownStyle", "borderWidth"])),
                    showZero: true,
                })}

                {NumberInput({
                    label: "Corner Radius",
                    unit: "px",
                    ...ffToNumberInput(form.field(["general", "tile", "containerTiles", "dropdownStyle", "cornerRadius"])),
                    showZero: true,
                })}

                <div className="instruction-text">
                    To edit dropdown menu style go to
                    <div
                        className="text-primary"
                        onClick={() => {
                            selected.onChange(true);
                            viewTree.change((v) => v.concat("components"));
                        }}
                    >
                        Components & Inputs
                    </div>
                </div>

                {overrideLeftPanelRef.get?.() &&
                    ReactDOM.createPortal(
                        <div
                            className={cx("override", {
                                active: selected.value,
                            })}
                            style={{}}
                        >
                            {cs(
                                ({}, next) =>
                                    SuspendUpdate({
                                        active: selected.value,
                                        next,
                                    }),
                                () =>
                                    LeftPanelClosable({
                                        onClose: () => {
                                            selected.onChange(false);
                                            viewTree.change((v) => v.splice(0, v.length - 1));
                                        },
                                        label: "COMPONENTS & INPUTS",
                                        content: ComponentAndInputTheme({
                                            form,
                                        }),
                                    })
                            )}
                        </div>,
                        overrideLeftPanelRef.get()
                    )}
            </div>
        );
    });
