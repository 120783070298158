import "./field-item.scss";

import React from "react";
import {cx} from "emotion";

import {cs} from "@common/react/chain-services";
import {TruncatingTooltip} from "../../../../common/truncating-tooltip/truncating-tooltip";
import {AlertIcon} from "@common/ui-components/icons/global-icons";
import {CollectionErrorBadgeWithTooltip} from "@common/ui-components/live/live-dashboard/common/collection-error-badge/collection-error-badge-with-tooltip";

export const FieldItem = ({label, onClick, rightIcon, leftIcon, children, style, disabled = false, className, provided, dragging, hoveringGroup, fieldID, hasError}) =>
    cs(
        [
            "truncatingTooltip",
            (_, next) =>
                TruncatingTooltip({
                    delay: 500,
                    forceUpdate: true,
                    className: "field-control-tooltip",
                    next,
                }),
        ],
        ({truncatingTooltip}) => (
            <div
                className={cx("field-item-vew", className, {"has-on-click": !!onClick, "has-dnd": !!provided, disabled, "has-error": hasError})}
                onClick={disabled ? null : onClick}
                style={style}
                ref={provided?.innerRef}
                onMouseDown={() => {
                    hoveringGroup?.onChange(fieldID);
                }}
                {...provided?.draggableProps}
                {...provided?.dragHandleProps}
                onMouseEnter={() => {
                    if (!dragging) {
                        hoveringGroup?.onChange(fieldID);
                    }
                }}
            >
                {label && !children ? (
                    <>
                        <div className="text">
                            {leftIcon}
                            <div
                                {...{
                                    className: "title",
                                    ref: truncatingTooltip?.ref,
                                    ...truncatingTooltip?.tooltip(label),
                                }}
                            >
                                {label}
                            </div>
                        </div>

                        {hasError && <CollectionErrorBadgeWithTooltip className="alert-icon" error={hasError.details} title={hasError.title} />}

                        {rightIcon}
                    </>
                ) : (
                    children
                )}
            </div>
        )
    );
