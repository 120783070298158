import "../sorting.scss";

import {cx} from "emotion";
import * as React from "react";

import {Invoke} from "@common/react/invoke";
import {cs} from "@common/react/chain-services";
import {spc} from "@common/react/state-path-change";
import {consumeContext} from "@common/react/context";

import {keepOnly} from "@common/utils/objects";
import {getPath} from "@common/utils/arr-path";
import {FieldInfoProvider} from "../../../../../../tile-tab/chart-types/common/field-info-provider/field-info-provider";
import {FieldSection} from "../../../field-section/field-section";
import {sortIconsTypes} from "../sort-icons-types";
import {tooltipService4} from "../../../../../../../../../../../common/tooltip3/tooltip-service-3";
import {CollectionErrorBadgeWithTooltip} from "@common/ui-components/live/live-dashboard/common/collection-error-badge/collection-error-badge-with-tooltip";

export const GroupSorting = ({tile, form}) =>
    cs(
        consumeContext("tileFields"),
        consumeContext("getFieldInfo"),
        consumeContext("collection"),
        [
            "tooltip",
            ({theme}, next) =>
                tooltipService4({
                    direction: "above",
                    next,
                }),
        ],
        (_, next) =>
            Invoke({
                fn: () => {
                    const categorySortField = form.field(["categorySort"]).state;
                    if (categorySortField.value == null) {
                        categorySortField.onChange({
                            ...keepOnly(tile.value.groupField, ["modelID", "modelTableID", "modelColumnID"]),
                            direction: "None",
                        });
                    }
                },
                next,
            }),
        ({getFieldInfo, tooltip, collection}) => {
            return FieldSection({
                className: "sort-by-8yk",
                header: FieldInfoProvider({
                    field: <div className="text">Sorting within group</div>,
                }),
                content: (
                    <>
                        <div className="sort-by-section">
                            <div className="label">Sort by</div>

                            {(() => {
                                const categorySortField = form.field(["categorySort"]).state;
                                const fieldInfo = getFieldInfo?.(categorySortField.value);
                                const sortIcons = sortIconsTypes[fieldInfo.type || "text"];

                                return (
                                    <div className="sort-by has-border">
                                        <div className="text">{fieldInfo?.visualNameFull}</div>
                                        <div className="sorts">
                                            <div
                                                className={cx("asc", {
                                                    selected: getPath(categorySortField.value, ["direction"]) === "Asc",
                                                })}
                                                onClick={() => spc(categorySortField, ["direction"], (sort) => "Asc")}
                                                {...tooltip(() => "Ascending")}
                                            >
                                                {sortIcons?.asc}
                                            </div>

                                            <div
                                                className={cx("desc", {
                                                    selected: getPath(categorySortField.value, ["direction"]) === "Desc",
                                                })}
                                                onClick={() => spc(categorySortField, ["direction"], (sort) => "Desc")}
                                                {...tooltip(() => "Descending")}
                                            >
                                                {sortIcons?.desc}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })()}
                        </div>

                        {/*<div className="sort-by-section">*/}
                        {/*    <div className="sort-by">*/}
                        {/*        {FieldInfoProvider({*/}
                        {/*            className: "margin-bottom",*/}
                        {/*            info: <span><b>Each Individual Group:</b> sorts the values within each group separately so they're always sorted correctly within the group. This method prioritizes the value order over the color order.*/}
                        {/*                <br/><br/>*/}
                        {/*                <b>Entire Series:</b> sorts the values within each group based on the series total values. This method prioritizes the color order over the value order.</span> ,*/}
                        {/*            field: DropdownSelect({*/}
                        {/*                label: "Sort Basis",*/}
                        {/*                valueToLabel: v => v.label,*/}
                        {/*                list: [{label: "Each Individual Group", value: "EachIndividualGroup"}, {label: "Entire Series", value: "EntireSeries"}],*/}
                        {/*                ...ffToDropdown(form.field(["categorySortBasis"]), ["value"])*/}
                        {/*            })*/}
                        {/*        })}*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </>
                ),
            });
        }
        // ({tileFields}) => <Expandable {...{
        //     label: "Sort by",
        //     render: () =>
        // }}/>
    );
