import {consumeContext, provideContext} from "@common/react/context";
import {ChangeQueue1} from "@common/react/change-queue-1";

import {loadDefaultCollection, loadSharedTile} from "./common";

import {cs} from "@common/react/chain-services";
import {NormalTileLayout} from "./normal-tile-layout";
import {ContainerTileLayout} from "./container-tile-layout";
import {MoveToDialog} from "../../dashboard/env/common/move-to-dialog/move-to-dialog";
import {RenameSharedTileDialog} from "../../dashboard/env/shared-tiles/cm/rename/rename-shared-tile-dialog";
import {SelectModelForCloningSharedTileDialog} from "../../dashboard/env/shared-tiles/cm/clone/select-model-for-cloning-shared-tile-dialog";
import {DeleteSharedTileDialog} from "../../dashboard/env/shared-tiles/cm/delete/delete-shared-tile-dialog";
import {UseState} from "@common/react/use-state";

export const EditSharedTile = ({}) =>
    cs(
        consumeContext("apis"),
        consumeContext("routing"),
        (_, next) => loadSharedTile({next}),
        consumeContext("sharedTile"),
        consumeContext("theme"),
        ["leftPanelWidth", (_, next) => UseState({next, initValue: null})],
        ({leftPanelWidth}, next) => provideContext("leftPanelWidth", leftPanelWidth, next),
        ({sharedTile, routing, theme}, next) => {
            if (sharedTile.loading || !theme) {
                return null;
            }

            if (!sharedTile.value || sharedTile.apiError) {
                routing.goto("dashboard");
                return null;
            }

            return next();
        },
        ({sharedTile}, next) => loadDefaultCollection({sharedTile, next}),
        consumeContext("modelForCollection"),
        ({modelForCollection, routing}, next) => {
            if (!modelForCollection) {
                return null;
            }

            return next();
        },
        (_, next) => provideContext("editingMode", true, next),

        [
            "moveSharedTileDialog",
            (_, next) =>
                MoveToDialog({
                    apiType: "collectionTiles",
                    onDone: () => null,
                    next,
                }),
        ],
        [
            "renameSharedTileDialog",
            ({sharedTile}, next) =>
                RenameSharedTileDialog({
                    onDone: () => sharedTile.reload(),
                    reloadFolderOnDone: false,
                    next,
                }),
        ],
        [
            "deleteSharedTileDialog",
            ({routing}, next) =>
                DeleteSharedTileDialog({
                    onDone: () => routing.goto("dashboard"),
                    next,
                }),
        ],
        [
            "selectModelForCloningDialog",
            ({routing}, next) =>
                SelectModelForCloningSharedTileDialog({
                    onDone: () => {},
                    next,
                }),
        ],
        [
            "savingQueue",
            ({apis, sharedTile}, next) =>
                ChangeQueue1({
                    fetchLatest: () => apis.collectionTiles.getTile(sharedTile.value.id),
                    save: apis.collectionTiles.upsertTile,
                    next,
                }),
        ],
        ({savingQueue, sharedTile, selectModelForCloningDialog, moveSharedTileDialog, deleteSharedTileDialog, renameSharedTileDialog}) => {
            const SharedTileLayout = sharedTile.value.hasOwnProperty("tiles") ? ContainerTileLayout : NormalTileLayout;
            return <SharedTileLayout {...{savingQueue, selectModelForCloningDialog, moveSharedTileDialog, deleteSharedTileDialog, renameSharedTileDialog}} />;
        }
    );
