const {cascadeChange} = require("../utils/cascade");

const scc = (state, pattern, change, ...other) => {
    if (state.change) {
        state.change((v) => cascadeChange(v, pattern, change), ...other);
    } else {
        state.onChange(cascadeChange(state.value, pattern, change), ...other);
    }
};
exports.scc = scc;
