import "./fixed-popup-menu.scss";

import {cx} from "emotion";
import React, {createElement as h} from "react";

import {keyed} from "@common/react/keyed";
import {cs} from "@common/react/chain-services";
import {GlobalKeyDown} from "@common/react/keys/global-key-down";

import {Dropdown} from "@common/ui-components/dropdown/dropdown";
import {ArrowChervonRight} from "../../../assets/icons/common/ArrowChervonDown";
import {tooltipService3} from "../tooltip3/tooltip-service-3";

export const FixedPopupMenu = ({next, getCommands, minWidth, position, offsetLeft = 0, offsetTop = 0, icon}) =>
    next({
        render: ({params, img, content} = {}) => {
            return Dropdown({
                registryRender: true,
                detectOnWheelEvent: true,
                useTogglePosition: true,
                minWidth,
                className: "fixed-popup-menu-toggle-1ws",
                expandClassNames: "fixed-popup-menu-expand-49c",
                renderToggle: ({showExpand, showingExpand}) =>
                    content ? (
                        content({
                            onMouseDown: (e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                showExpand();
                            },
                        })
                    ) : (
                        <div
                            className={cx("fixed-popup-menu-toggle", {
                                show: showingExpand,
                            })}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                showExpand();
                            }}
                        >
                            {icon ?? <i className="far fa-ellipsis-v" />}
                        </div>
                    ),
                renderExpand: ({close}) => (
                    <div className="fixed-popup-menu-commands">
                        {cs(
                            ({}, next) =>
                                h(GlobalKeyDown, {
                                    keyCombo: "Escape",
                                    onKeyDown: close,
                                    next,
                                }),
                            tooltipService3({direction: "below"}),
                            ({tooltip}) =>
                                getCommands(params).map((cm, i) =>
                                    cs(keyed(i), () => (
                                        <div
                                            {...(cm.disabledTooltip && cm.disabled ? tooltip(() => cm.disabledTooltip) : {})}
                                            className={cx("command", {last: cm.last}, cm.className, cm.disabled && "disabled")}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();

                                                if (cm.disabled) return;

                                                if (cm.onClick) {
                                                    cm.onClick();
                                                    close();
                                                }
                                            }}
                                        >
                                            <div className="label">
                                                {cm.icon?.()}
                                                {cm.label}
                                            </div>
                                            <div className="sub-label">{cm.subLabel}</div>

                                            {cm.renderChildComponent && (
                                                <>
                                                    <div className="arrow-right">
                                                        <ArrowChervonRight />
                                                    </div>

                                                    <div className="child-component-wrapper">{cm.renderChildComponent(close)}</div>
                                                </>
                                            )}
                                        </div>
                                    ))
                                )
                        )}
                    </div>
                ),
            });
        },
    });
