import "./tile-size.scss";

import React from "react";

import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {UseState} from "@common/react/use-state";
import {Form2} from "@common/react/cs-form/form2";
import {required} from "@common/react/cs-form/validators/required";
import {keyed} from "@common/react/keyed";
import {Invoke} from "@common/react/invoke";
import {NumberInput} from "../../../../../../../../common/form/number-input/number-input";
import {ffToNumberInput} from "../../../../../../../../common/form/ff-to-number-input";

export const TileSize = ({collection}) =>
    cs(
        consumeContext("apis"),
        [
            "size",
            (_, next) =>
                UseState({
                    initValue: {
                        value: collection.value || null,
                        lastUpdatedAt: null,
                    },
                    next: ({onChange, value}) =>
                        next({
                            value: {
                                ...value,
                                value: value.value ? value.value : collection.value,
                            },
                            flush: () => onChange({}),
                            onChange: ({value, lastUpdatedAt}) => {
                                onChange({
                                    value,
                                    lastUpdatedAt,
                                });
                            },
                        }),
                }),
        ],
        [
            "form",
            ({size, tenant, saving, apis, auth}, next) =>
                Form2({
                    fields: {
                        viewWidthPixels: {
                            validators: [required],
                        },
                        viewHeightPixels: {
                            validators: [required],
                        },
                    },
                    data: {
                        value: size.value.value,
                        onChange: (v) => {
                            size.onChange({
                                value: v,
                                lastUpdatedAt: Date.now(),
                            });
                        },
                    },
                    next,
                }),
        ],
        ({size, tilePath, form}, next) =>
            cs(["lastSave", (_, next) => UseState({next})], ({lastSave}) => (
                <>
                    {next()}
                    {size.value.lastUpdatedAt &&
                        (!lastSave.value || lastSave.value < size.value.lastUpdatedAt) &&
                        form.valid &&
                        cs(keyed(size.value.lastUpdatedAt), () =>
                            Invoke({
                                fn: async ({isMounted}) => {
                                    collection.change(() => size.value.value);
                                    if (isMounted()) {
                                        size.flush();
                                        lastSave.onChange(size.value.lastUpdatedAt);
                                    }
                                },
                            })
                        )}
                </>
            )),
        ({form}) => (
            <div className="tile-size-45g">
                <div className="dual-line">
                    <div className="col1">
                        {NumberInput({
                            label: "Width",
                            ...ffToNumberInput(form.field(["viewWidthPixels"])),
                            unit: "px",
                        })}
                    </div>

                    <div className="col2">
                        {NumberInput({
                            label: "Height",
                            ...ffToNumberInput(form.field(["viewHeightPixels"])),
                            unit: "px",
                        })}
                    </div>
                </div>
            </div>
        )
    );
