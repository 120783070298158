import "./collection-theme-select.scss";

import React from "react";

import {cs} from "@common/react/chain-services";
import {CheckIcon, SettingIcon} from "./theme-select-icons";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";
import {consumeContext} from "@common/react/context";
import {UseState} from "@common/react/use-state";
import {PlusIcon} from "@common/ui-components/icons/global-icons";

export const CollectionThemeSelect = ({themes = [], collection, onClose, createThemeService}) =>
    cs(consumeContext("routing"), ["hovering", (_, next) => UseState({next})], ({routing, hovering}) => {
        const editTheme = (e, themeID) => {
            e.preventDefault();
            e.stopPropagation();
            routing.goto("edit-theme", {
                themeId: themeID,
                collectionID: collection.value.id,
            });
        };

        return (
            <div className="collection-theme-select-a33">
                <VerbScrollbar maxHeight={300}>
                    <div className="item selected" onClick={onClose} onMouseEnter={() => hovering.onChange(true)} onMouseLeave={() => hovering.onChange(false)}>
                        {themes.find((t) => t.id == collection.value?.themeID)?.name}
                        {hovering.value ? <SettingIcon onClick={(e) => editTheme(e, collection.value?.themeID)} /> : <CheckIcon />}
                    </div>

                    {themes
                        .filter((t) => t.id != collection.value?.themeID)
                        .map((theme) => (
                            <div
                                className="item"
                                key={theme.id}
                                onClick={() => {
                                    collection.change((c) => ({
                                        ...c,
                                        themeID: theme.id,
                                    }));
                                    onClose();
                                }}
                            >
                                {theme.name}
                                <SettingIcon onClick={(e) => editTheme(e, theme.id)} />
                            </div>
                        ))}
                </VerbScrollbar>

                <div
                    className="item-footer"
                    onClick={async () => {
                        onClose();
                        const themeID = await createThemeService.show();
                        if(themeID) {
                            routing.goto("edit-theme", {
                                themeId: themeID,
                                collectionID: collection.value.id,
                            });
                        }
                    }}
                >
                    <PlusIcon />
                    Create New Theme
                </div>
            </div>
        );
    });
