import React from "react";
import {cs} from "@common/react/chain-services";
import {Expandable} from "../../../../../common/expandable/expandable";
import {ApiCollectionFilters} from "./local-filters/api-collection-filters";
import {Button} from "../../../../../../../../common/form/buttons/button/button";
import {LpShowHideLine} from "../../../../../common/left-panel/lp-show-hide-line";
import {scope} from "@common/react/scope";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {consumeContext} from "@common/react/context";
import {getCurrentTzName} from "@common/utils/dates/date-object";

export const ApiFiltersMenu = ({onAddFilter, collection}) =>
    cs(consumeContext("selectedTimezone"), ({selectedTimezone}) => (
        <div className="filters-menu-4xm">
            <div className="add-new-filter">
                <Button className="btn" onClick={onAddFilter}>
                    Add New Filter
                </Button>
            </div>

            <Expandable
                {...{
                    label: "Local Filters",
                    render: () => ApiCollectionFilters({collection}),
                }}
            />

            {Expandable({
                label: "Time Zone Display",
                render: () => (
                    <div className="filters-display-8wq">
                        <div className="embedded-filters">
                            {LpShowHideLine({
                                label: "Time Zone Conversion",
                                state: scope(collection, ["showTimezoneConversion"]),
                            })}
                            {collection.value.showTimezoneConversion &&
                                DropdownSelect({
                                    className: "default-time-zone",
                                    label: "Default Time Zone",
                                    list: [
                                        {
                                            timezoneValue: getCurrentTzName(),
                                            value: false,
                                            label: "Local Browser Time Zone",
                                        },
                                        {
                                            timezoneValue: null,
                                            value: true,
                                            label: "UTC",
                                        },
                                    ],
                                    valueToLabel: (v) => v.label,
                                    isSelected: (v) => collection.value.defaultTimezoneToUtc == v.value,
                                    onChange: (v) => {
                                        collection.change((c) => ({
                                            ...c,
                                            defaultTimezoneToUtc: v.value,
                                        }));
                                        selectedTimezone.onChange(v.timezoneValue);
                                    },
                                })}
                        </div>
                    </div>
                ),
            })}
        </div>
    ));
