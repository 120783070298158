import React from "react";
import {cs} from "@common/react/chain-services";

export const initFontSizeList = (maxFontSize, minFontSize, text) => {
    let ret = [];
    for (let i = maxFontSize; i >= minFontSize; i--) {
        ret.push({
            font: i,
            ...getTextDimension(i, text),
        });
    }
    return ret;
};

//TODO: ask Dave icon/export
export const FontAutoResizing = ({noTitle, width, height, text, maxFontSize, minFontSize, fontSizeGroupDimension, attached}) =>
    cs(
        // ["fontSizeGroupDimension", (_, next) => UseState({next, initValue: initFontSizeList(maxFontSize, minFontSize, text)})],
        // ({fontSizeGroupDimension}, next) => Watch({
        //     next,
        //     value: text,
        //     onChanged: (v) => {
        //         fontSizeGroupDimension.onChange(initFontSizeList(maxFontSize, minFontSize, v))
        //     }
        // }),
        [
            "getFontSize",
            (_, next) =>
                next(() => {
                    const fontValues = fontSizeGroupDimension.value;

                    let matchWidthSize = fontValues.find((v) => v.width <= width - 32);
                    let matchHeightSize = fontValues.find((v) => v.height <= height);

                    return matchWidthSize?.font || (fontSizeGroupDimension.value ? minFontSize : maxFontSize);

                    // return Math.max(matchWidthSize?.font || (fontSizeGroupDimension.value ? minFontSize : maxFontSize), matchHeightSize?.font || (fontSizeGroupDimension.value ? minFontSize : maxFontSize));
                }),
        ],
        ({getFontSize}) => {
            return (
                <div style={{fontSize: getFontSize()}}>
                    {text}

                    {attached?.({fontSize: getFontSize()})}
                </div>
            );
        }
    );

export const getTextDimension = (fontSize, innerText) => {
    let text = document.createElement("span");
    document.body.appendChild(text);

    text.style.fontSize = `${fontSize}px`;
    text.style.height = "auto";
    text.style.width = "auto";
    text.style.position = "absolute";
    text.style.whiteSpace = "no-wrap";
    text.innerHTML = innerText;

    let width = Math.ceil(text.clientWidth);
    let height = Math.ceil(text.clientHeight);
    document.body.removeChild(text);

    return {width, height};
};

export const getTextDimensionFromHtml = (html) => {
    const template = document.createElement("div");
    document.body.appendChild(template);
    template.style.height = "auto";
    template.style.width = "auto";
    template.style.position = "absolute";
    template.innerHTML = html;
    let width = Math.ceil(template.clientWidth);
    let height = Math.ceil(template.clientHeight);
    document.body.removeChild(template);
    return {width, height};
};
