import "./date-filter.scss";

import {cx} from "emotion";
import * as React from "react";

import {cs} from "../../../../../../react/chain-services";
import {consumeContext} from "../../../../../../react/context";

import {formatDateLong} from "../../../../../formats/format-date-long";
import {addDate} from "../../../../../../utils/dates/date-object";
import {DateFilterExpand} from "./expand/date-filter-expand";
import {Dropdown} from "../../../../../dropdown/dropdown";
import {expandDistance} from "../../../../filters/common/control-styles-config";

export const DateFilterPreview = ({state}) =>
    cs(consumeContext("theme"), ({theme}) => {
        const inputTheme = theme.general.components;

        const filter = {
            id: "cf386972-43e7-4392-a559-c06f695780a5",
            options: "Last7Days, YearToDate, Custom",
            defaultOption: "Custom",
            customRange: {
                interval: "Days",
                value: 90,
                next: false,
                negative: false,
                $type: "InTheLastOrNextCustomDateFilterRange",
            },
            compareEnabled: true,
            compareOnByDefault: true,
            compareDefaultRange: null,
            label: "Date Created",
            showLabelInfoIcon: false,
            labelInfoIconText: null,
            columns: [
                {
                    modelID: "2863ada0-8e18-4384-a291-c19a7f417b62",
                    modelTableID: "3976bbea-7989-45fa-b28e-09fd3cd70052",
                    modelColumnID: "3a3e2dad-298a-421b-8c91-90a75230dcac",
                    displayName: "Date Created",
                    default: true,
                },
            ],
            multiSelectionOption: "SingleSelect",
            required: false,
            displayArea: "Visible",
            disconnectedTiles: [],
            $type: "DateFilter",
        };
        const value = state.value;

        const renderToggle = ({showExpand, showingExpand}) => {
            const renderRange = (range) =>
                !range.from ? (
                    `Before ${formatDateLong(addDate(range.to, -1))}`
                ) : !range.to ? (
                    `After ${formatDateLong(range.from)}`
                ) : (
                    <>
                        {formatDateLong(range.from)}
                        <span className="dash">&ndash;</span>
                        {formatDateLong(addDate(range.to, -1))}
                    </>
                );
            return (
                <div
                    className={cx("toggle", {
                        comparing: value.comparing && value.enableComparing,
                    })}
                    onClick={() => showExpand(!showingExpand)}
                    style={{
                        border: `${inputTheme.inputBorderWidth}px solid ${inputTheme.inputBorderColorRGB}`,
                        background: `${inputTheme.inputBackgroundColorRGB}`,
                        color: `${inputTheme.inputTextColorRGB}`,
                        borderRadius: `${inputTheme.inputCornerRadius}px`,
                    }}
                >
                    <div className="main">{renderRange(value.main)}</div>

                    {value.comparing && value.enableComparing && (
                        <div
                            className="comparing"
                            style={{
                                color: inputTheme.inputTextColorRGB,
                            }}
                        >
                            <div className="label">Compare to:</div>
                            <div className="range">{renderRange(value.comparing)}</div>
                        </div>
                    )}

                    <i
                        className="fa fa-chevron-down"
                        style={{
                            color: inputTheme.inputIconColorRGB,
                        }}
                    />
                </div>
            );
        };
        const renderExpand = ({close, width}) =>
            DateFilterExpand({
                size: "desktop",
                width,
                state,
                filter,
                onClose: close,
                showApplyBtn: true,
            });

        return Dropdown({
            minExpandHeight: 200,
            expandDistance,
            detectOnWheelEvent: true,
            className: cx("date-filter-24c"),
            renderToggle,
            renderExpand,
        });
    });
