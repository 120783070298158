import "./dropdown-with-search.scss";

import { cx } from "emotion";
import React from "react";

import { SearchInput } from "../../form/search-input/search-input";
import { cs } from "../../react/chain-services";
import { UseState } from "../../react/use-state";
import { isMatchText } from "../../utils/strings";
import { Dropdown } from "../dropdown/dropdown";
import { VerbScrollbar } from "../verb-scrollbar/verb-scrollbar";

export const DropdownWithSearch = ({inputClass, closeAllOtherDropdownWhenOpen = true, noResultClass, dropdownCss, borderRadius, list, onChange, isSelected = (v) => false, disabled, valueToLabel = (v) => v, valueToSearch = (v) => v, renderToggle = () => null, className}) =>
    cs(
        [
            "renderExpand",
            ({chosenIndex}, next) =>
                next(({close, width}) =>
                    cs(["searching", (_, next) => UseState({next})], ({searching}) => {
                        const searched = list.filter((l) => isMatchText(valueToSearch(l), searching.value?.behind));
                        const _width = width > 290 ? width : 290;

                        return (
                            <div className="btn-dd-w-search-menu-fv3" style={{width: _width}}>
                                {SearchInput({
                                    state: {
                                        value: searching.value?.front,
                                        onChange: (v) =>
                                            searching.onChange({
                                                front: v,
                                                behind: v,
                                            }),
                                    },
                                    placeholder: "Search...",
                                    delay: 300,
                                    autoFocus: true,
                                    className: inputClass,
                                })}

                                <div className="menu">
                                    <VerbScrollbar maxHeight="300px" className="list" style={{minWidth: _width}}>
                                        {searched &&
                                            (!searched?.length ? (
                                                <div className={cx("no-results item", noResultClass)}>No results found.</div>
                                            ) : (
                                                searched.map((l, i) => (
                                                    <div
                                                        key={i}
                                                        className={cx(
                                                            "item",
                                                            {
                                                                selected: isSelected(l),
                                                            },
                                                            dropdownCss
                                                        )}
                                                        onClick={(e) => {
                                                            onChange(l);
                                                            searching.onChange({
                                                                ...searching.value,
                                                                front: "",
                                                            });
                                                            close();
                                                        }}
                                                    >
                                                        {valueToLabel(l)}
                                                    </div>
                                                ))
                                            ))}
                                    </VerbScrollbar>
                                </div>
                            </div>
                        );
                    })
                ),
        ],
        ({renderExpand}) => (
            <div className={cx("button-dropdown dropdown-w-search-k73", className, disabled && "disabled")}>
                {Dropdown({
                    renderToggle: (props) => renderToggle({...props, disabled}),
                    minExpandHeight: 300,
                    renderExpand,
                    disabled,
                    closeAllOtherDropdownWhenOpen,
                    detectOnWheelEvent: true,
                    borderRadius,
                    registryRender: true,
                    customZIndex: 100,
                })}
            </div>
        )
    );
