import {findMaxE, findMinE} from "../../../../utils/collections";
import {isColorAxisVertical, useColorAxis} from "../map-helper";

export const mapColorAxis = ({tile, theme, series, matchIndex}) => {
    if(
        matchIndex == null || // null matchIndex means blank map, so do not show color axis
        series[0].isDummy // empty data
    ) {
        return;
    }

    if(!useColorAxis(tile)) {
        return {};
    }

    const {sequentialColorSchemes} = theme.dataVisualization.dataColorPalettes.colorSchemes;
    const locationColorApplicationIndex = tile.style.locationColorApplication?.index ?? 0;
    const values = series[0].data.map(({value}) => value);
    return {
        colorAxis: {
            minColor: sequentialColorSchemes[locationColorApplicationIndex].colorsRGB[0],
            maxColor: sequentialColorSchemes[locationColorApplicationIndex].colorsRGB[1],
            // if there is only 1 value, treat that value as max
            // set `min` to FALSE (not null), highcharts will define min for color axis
            min: values.length > 1 ? findMinE(values) : false,
            max: findMaxE(values),
        },
    };
};
