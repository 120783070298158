import {Display} from "./panels/display/display";
import {TileTitle} from "../common/tile-title/tile-title";
import {SectionLabels} from "./panels/section-labels";
import {Colors} from "../pie-chart/panels/colors/colors";
import {Legend} from "./panels/legend";
import {OtherOptions} from "../common/other-options/other-options";
import "./funnel-chart.scss";

export const FunnelChart = ({tile, form, theme, size, parentTile, colorsTileConfig, tileAction, isContainerTile}) =>
    [Display, TileTitle, SectionLabels, Colors, Legend, OtherOptions]
        .filter((v) => v)
        .map((panel) =>
            panel({
                tile,
                form,
                theme,
                size,
                parentTile,
                colorsTileConfig,
                tileAction,
                isContainerTile,
            })
        );
