/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
function isObject(item) {
    return item && typeof item === "object" && !Array.isArray(item);
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
function mergeDeep(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) Object.assign(target, {[key]: {}});
                mergeDeep(target[key], source[key]);
            } else {
                Object.assign(target, {[key]: source[key]});
            }
        }
    }

    return mergeDeep(target, ...sources);
}

const objectToCss = (object, className) => {
    let ret = [];

    const isMediaTag = (key) => key.indexOf("@media") === 0;
    const isKeyframeTag = (key) => key.indexOf("@keyframes") === 0;

    const iframeTagParse = (key, object) => {
        let values = [];
        const kfObject = object[key];
        for (let kfKey in kfObject) {
            const obj = kfObject[kfKey];
            let objValues = [];
            for (let ko in obj) {
                objValues.push(`${ko.replace(/([A-Z])/g, "-$1").toLowerCase()}: ${obj[ko]}`);
            }

            values.push(`${kfKey} {${objValues.join(";")}}`);
        }

        ret.push(`${key} {${values.join(" ")}}`);
    };

    const parseObjectToCssClass = (object, parentClass, mediaKey) => {
        let variables = [];
        for (let key in object) {
            if (isObject(object[key])) {
                if (isKeyframeTag(key)) {
                    iframeTagParse(key, object);
                } else {
                    const arr = key.split(",");
                    for (let item1 of arr) {
                        const item = item1.trim();
                        if (isMediaTag(item)) {
                            parseObjectToCssClass(object[key], parentClass, item);
                        } else {
                            const space = item[0] === "&" ? "" : " ";
                            const parsedKey = item[0] === "&" ? item.substr(1) : item;
                            parseObjectToCssClass(object[key], `${parentClass}${space}${parsedKey}`, mediaKey);
                        }
                    }
                }
            } else {
                variables.push(`${key.replace(/([A-Z])/g, "-$1").toLowerCase()}: ${object[key]}`);
            }
        }

        if (variables.length > 0) {
            if (mediaKey) {
                ret.push(`${mediaKey} {${parentClass} {${variables.join(";")}}}`);
            } else {
                ret.push(`${parentClass} {${variables.join(";")}}`);
            }
        }
    };

    parseObjectToCssClass(object, className);

    return ret.join("\n");
};

module.exports = {
    isObject,
    mergeDeep,
    objectToCss,
};
