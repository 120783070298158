import {cGetCommonScatterSeriesProps, cGetScatterColor, getScatterGroupNames} from "../scatter-plot/decorate-scatter-data";

export const decorateBubbleData = ({rawData, tile, theme}) => {
    const seriesDecorator = seriesDecorators.find((d) => d.condition(tile)).decorator;
    return {
        ...rawData,
        series: seriesDecorator({rawData, tile, theme}),
    };
};

const seriesDecorators = [
    {
        condition: (tile) => !tile.colorGroupField,
        decorator: ({rawData, tile, theme}) => {
            const getCommonProps = cGetCommonScatterSeriesProps({tile});

            const getColor = cGetScatterColor({
                tile,
                theme,
                colorApplication: tile.style.valueColorApplication, // discrete
            });

            const color = getColor();

            return rawData.series.map((s) => {
                return {
                    ...s,
                    data: s.data.map((dp) => ({
                        x: dp[0],
                        y: dp[1],
                        ...(tile.zAxisField && {z: dp[2]}),
                    })),
                    color,
                    marker: {
                        color,
                    },
                    ...getCommonProps(s),
                };
            });
        },
    },
    {
        condition: (tile) => tile.colorGroupField,
        decorator: ({rawData, tile, theme}) => {
            const colorGroupNames = getScatterGroupNames(rawData, "colorGroupName");

            const getColor = cGetScatterColor({
                tile,
                theme,
                colorApplication: tile.style.colorApplication, // scheme
                numberOfColors: colorGroupNames.length || 1,
            });

            const getCommonProps = cGetCommonScatterSeriesProps({tile});

            return rawData.series.map((s) => {
                const color = getColor(colorGroupNames.indexOf(s.colorGroupName));
                return {
                    ...s,
                    data: s.data.map((dp) => ({
                        x: dp[0],
                        y: dp[1],
                        ...(tile.zAxisField && {z: dp[2]}),
                    })),
                    color,
                    marker: {
                        color,
                    },
                    custom: {
                        colorGroup: s.colorGroupName,
                    },
                    ...getCommonProps(s),
                };
            });
        },
    },
];
