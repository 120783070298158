import {getCategories} from "@common/ui-components/charts/factory/dimension-axis/get-categories";
import {cGetFontSize} from "@common/ui-components/charts/common/style-map/font-size";
import {getOrientation} from "@common/ui-components/charts/common/style-map/orientation";
import {mapGridStyleToHC} from "@common/ui-components/charts/factory/dimension-axis/dimension-axis";

const getTitleOption = ({tile, style, theme, getFontSize, field}) => {
    return {
        title: {
            text: style?.labelShown ? (style.label || tile?.[field]?.displayName) : undefined,
            style: {
                fontSize: getFontSize({group: theme.dataVisualization.fonts.axisFontSize, elemType: "label"}),
                color: theme.dataVisualization.fonts.fontColorRGB || theme.general.canvas.fontColorRGB,
                fontWeight: theme.dataVisualization.fonts.fontWeight ?? null,
                fontFamily: `"${theme.dataVisualization.fonts.fontFamily || theme.general.canvas.fontFamily}", sans-serif`
            },
        },
    }
}

const getLabelsOption = ({style, theme, getFontSize, isXAxis, formatter}) => {
    const orientation = style?.gridStepLabelOrientation;

    return {
        labels: {
            enabled: !!style?.gridStepLabelsShown,
            style: {
                fontSize: getFontSize({group: theme.dataVisualization.fonts.gridStepsFontSize}),
                color: theme.dataVisualization.fonts.fontColorRGB || theme.general.canvas.fontColorRGB,
                fontFamily: `"${theme.dataVisualization.fonts.fontFamily || theme.general.canvas.fontFamily}", sans-serif`
            },

            ...(orientation === "Auto" ? {
                autoRotation: isXAxis
                    ? [-45, -50, -55, -60, -65, -70, -75, -80, -85, -90]
                    : [0, -30, -45, -60, -90],
            } : {
                rotation: getOrientation(orientation),
            }),
            formatter: function() {
                return formatter(this.value);
            }
        }
    }
};

const getAxisLinesOption = ({style, theme, isXAxis}) => {
    const {axisCharts} = theme.dataVisualization;
    return {
        lineWidth: style.lineShown ? 1 : 0,
        lineColor: axisCharts.axisLineColorRGB,

        gridLineWidth: style?.gridLinesShown ? 1 : 0,
        gridLineColor: axisCharts.gridStepLineColorRGB,
        gridLineDashStyle: mapGridStyleToHC[axisCharts.gridStepLineStyle],

        opposite: isXAxis ? style.location === "LeftTop" : style.location === "RightBottom",

        tickWidth: 0,
        tickLength: 0,
    }
};

export const getBoxPlotXAxis = ({tile, theme, rawData, formatters}) => {
    const getFontSize = cGetFontSize(theme.general.canvas.fontSize, theme);

    return {
        type: "category",
        ...getCategories({ rawSeries: rawData.series }),
        min: 0,
        ...getTitleOption({tile, style: tile.style.xAxis, theme, getFontSize, field: 'xAxisField'}),
        ...getLabelsOption({
            style: tile.style.xAxis, theme, getFontSize, isXAxis: true,
            formatter: formatters.dimensionFormatter}),
        ...getAxisLinesOption({style: tile.style.xAxis, theme, isXAxis: true}),
    }
}

export const getBoxPlotYAxis = ({tile, theme, rawData, formatters}) => {
    const getFontSize = cGetFontSize(theme.general.canvas.fontSize, theme);
    return {
        ...getTitleOption({tile, style: tile.style.yAxis, theme, getFontSize, field: 'yAxisField'}),
        ...getLabelsOption({
            style: tile.style.yAxis, theme, getFontSize, isXAxis: false,
            formatter: formatters.measurementFormatters[tile.yAxisField.id]
        }),
        ...getAxisLinesOption({style: tile.style.yAxis, theme}),
    }
}
