const IconDownSvgStr = ({fill}) =>
    `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">` +
    `<g fill="none" fill-rule="evenodd">` +
    `<path fill=${fill} fill-rule="nonzero" d="M8.481 12.805c.015.014.03.026.045.037l.025.018.012.008c.122.083.273.132.437.132.164 0 .315-.05.437-.131l.016-.012.02-.014.047-.038 3.265-2.962c.287-.26.287-.682 0-.943-.287-.26-.752-.26-1.04 0l-2.01 1.824V5.667C9.735 5.298 9.406 5 9 5c-.405 0-.735.298-.735.667v5.057L6.255 8.9c-.287-.26-.752-.26-1.04 0-.287.26-.287.683 0 .943l3.265 2.962h.001z" transform="translate(-435 -211) translate(337 156) translate(21 22) translate(77 26) translate(0 7)"/>` +
    `<path fill=${fill} fill-rule="nonzero" d="M9 1C4.589 1 1 4.589 1 9c0 4.412 3.589 8 8 8s8-3.588 8-8c0-4.411-3.589-8-8-8zm0 15.078c-3.902 0-7.077-3.175-7.077-7.078 0-3.902 3.175-7.077 7.077-7.077S16.077 5.098 16.077 9c0 3.903-3.175 7.078-7.077 7.078z" transform="translate(-435 -211) translate(337 156) translate(21 22) translate(77 26) translate(0 7)"/>` +
    `</g>` +
    `</svg>`;
exports.IconDownSvgStr = IconDownSvgStr;

const IconUpSvgStr = ({fill}) =>
    `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">` +
    `<g fill="none" fill-rule="evenodd">` +
    `<path fill=${fill} fill-rule="nonzero" d="M8.481 12.805c.015.014.03.026.045.037l.025.018.012.008c.122.083.273.132.437.132.164 0 .315-.05.437-.131l.016-.012.02-.014.047-.038 3.265-2.962c.287-.26.287-.682 0-.943-.287-.26-.752-.26-1.04 0l-2.01 1.824V5.667C9.735 5.298 9.406 5 9 5c-.405 0-.735.298-.735.667v5.057L6.255 8.9c-.287-.26-.752-.26-1.04 0-.287.26-.287.683 0 .943l3.265 2.962h.001z" transform="translate(-687 -211) translate(337 156) translate(224 22) translate(126 26) translate(0 7) matrix(1 0 0 -1 0 18)"/>` +
    `<path fill=${fill} fill-rule="nonzero" d="M9 1C4.589 1 1 4.589 1 9c0 4.412 3.589 8 8 8s8-3.588 8-8c0-4.411-3.589-8-8-8zm0 15.078c-3.902 0-7.077-3.175-7.077-7.078 0-3.902 3.175-7.077 7.077-7.077S16.077 5.098 16.077 9c0 3.903-3.175 7.078-7.077 7.078z" transform="translate(-687 -211) translate(337 156) translate(224 22) translate(126 26) translate(0 7)"/>` +
    `</g>` +
    `</svg>`;
exports.IconUpSvgStr = IconUpSvgStr;

const IconUnchangedSvgStr = ({fill} = {}) =>
    `<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
        <defs>
        <style>
            .cls-1 {
                opacity: 0.5;
            }

            .cls-2 {
                fill: none;
                stroke: #000;
                stroke-linecap: round;
                stroke-linejoin: round;
            }
        </style>
    </defs>
    <g id="Icon_feather-arrow-down-circle" class="cls-1" transform="translate(9.5 0.5) rotate(90)">
        <path id="Path_482" class="cls-2" d="M12,7.5A4.5,4.5,0,1,1,7.5,3,4.5,4.5,0,0,1,12,7.5Z" transform="translate(-3 -3)"/>
        <path id="Path_483" class="cls-2" d="M12,18l1.8,1.8L15.6,18" transform="translate(-9.3 -13.5)"/>
        <path id="Path_484" class="cls-2" d="M18,12v3.6" transform="translate(-13.5 -9.3)"/>
        </g>
        </svg>
    `;
exports.IconUnchangedSvgStr = IconUnchangedSvgStr;
