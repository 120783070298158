const {ChangeColumnTypeStepPanelTabs} = require("./change-column-type/change-column-type-step-panel-tabs");
const {OutputStepTabs} = require("./output/output-step-tabs");
const {outputStepTypes} = require("../../common/output-step-types");
const {FilterDataStepPanelTabs} = require("./filter-data/filter-data-step-panel-tabs");
const {MapValuesStepTabs} = require("./map-values/map-values-step-tabs");
const {WindowDataStepPanelTabs} = require("./window-data/window-data-step-panel-tabs");
const {JoinDataStepTabs} = require("./join-data/join-data-step-tabs");
const {GroupDataStepTabs} = require("./group-data/group-data-step-tabs");
const {ChildDatasetStepTabs} = require("./child-dataset/child-dataset-step-tabs");
const {ModelTableDatasetStepTabs} = require("./model-table-dataset/model-table-dataset-step-tabs");
const {inputStepTypes} = require("../../common/input-step-types");
const {RankStepPanelTabs} = require("./rank/rank-step-panel-tabs");
const {UnpivotDataStepPanelTabs} = require("./unpivot-data/unpivot-data-step-panel-tabs");
const {PivotDataStepPanelTabs} = require("./pivot-data/pivot-data-step-panel-tabs");
const {SelectColumnsStepPanelTabs} = require("./select-columns/select-columns-step-panel-tabs");
const {RenameColumnsStepPanelTabs} = require("./rename-columns/rename-columns-step-panel-tabs");
const {TextManipulationStepPanelTabs} = require("./text-manipulation/text-manipulation-step-panel-tabs");
const {RemoveDuplicatesStepPanelTabs} = require("./remove-duplicates/remove-duplicates-step-panel-tabs");
const {CalculatorStepPanelTabs} = require("./calculator/calculator-step-panel-tabs");
const {DateOperationStepPanelTabs} = require("./date-operation/date-operation-step-panel-tabs");
const {AppendRowsStepPanelTabs} = require("./append-rows/append-rows-step-panel-tabs");
const {transformationStepTypes} = require("../../common/transformation-step-types");
const {AggregatedModelTableDatasetStepTabs} = require("./aggregated-model-table-dataset/aggregated-model-table-dataset-step-tabs");
const {CaseWhenStepTabs} = require("./case-when-data/case-when-step-tabs");
const {StaticValueStepTabs} = require("./static-value/static-value-step-tabs");

const getStepPanel = (type) => {
    const stepPanels = {
        ModelTableDatasetStep: ModelTableDatasetStepTabs,
        AggregatedModelTableDatasetStep: AggregatedModelTableDatasetStepTabs,
        ChildDatasetStep: ChildDatasetStepTabs,
        CalculatorStep: CalculatorStepPanelTabs,
        TextManipulationStep: TextManipulationStepPanelTabs,
        RemoveDuplicatesStep: RemoveDuplicatesStepPanelTabs,
        DateOperationStep: DateOperationStepPanelTabs,
        AppendRowsStep: AppendRowsStepPanelTabs,
        SelectColumnsStep: SelectColumnsStepPanelTabs,
        RenameColumnsStep: RenameColumnsStepPanelTabs,
        PivotDataStep: PivotDataStepPanelTabs,
        UnpivotDataStep: UnpivotDataStepPanelTabs,
        RankDataStep: RankStepPanelTabs,
        GroupDataStep: GroupDataStepTabs,
        JoinDataStep: JoinDataStepTabs,
        WindowDataStep: WindowDataStepPanelTabs,
        MapValuesTransformStep: MapValuesStepTabs,
        FilterDataStep: FilterDataStepPanelTabs,
        ChangeColumnTypeStep: ChangeColumnTypeStepPanelTabs,
        ColumnOutputStep: OutputStepTabs,
        ViewOutputStep: OutputStepTabs,
        CaseWhenStep: CaseWhenStepTabs,
        StaticValueStep: StaticValueStepTabs,
    };

    return {
        tabs: stepPanels[type] || {
            configuration: ({step}) => `step ${step.value.id} ${step.value.$type} configuration`,
            preview: ({step}) => `step ${step.value.id} ${step.value.$type} preview`,
        },
        ...[...inputStepTypes, ...transformationStepTypes, ...outputStepTypes].find((t) => t.$type === type),
    };
};
exports.getStepPanel = getStepPanel;
