const {changeTileFields} = require("./change-tile-fields");
const {arrMapToO, omit} = require("../../../../common/utils/objects");
const {renameAttribute} = require("../../../../common/utils/objects");
const {tileFieldAttrs} = require("./tile-field-attrs");

/**
 * remove ids of a tile or a collection for cloning
 * */

const removeIdsInTile = (tile, isSharedTile = false) => {
    const fieldAttrs = tileFieldAttrs[tile.$type];

    isSharedTile = isSharedTile || tile.isShared;

    return omit(
        {
            ...renameAttribute(tile, "id", isSharedTile ? "id" : "idBeforeClone"),
            filters: tile.filters?.map((filter) => renameAttribute(filter, "id", isSharedTile ? "id" : "idBeforeClone")),
            tileActions: tile.tileActions?.map((tileAction) => ({
                ...omit(tileAction, ["id"]),
                idBeforeClone: tileAction.id,
                tile: tileAction.tile ? removeIdsInTile(tileAction.tile) : null,
                filters: tileAction.filters?.map((filter) => renameAttribute(filter, "id", isSharedTile ? "id" : "idBeforeClone")),
            })),
            referenceLines: tile.referenceLines?.map((rl) => renameAttribute(rl, "id", isSharedTile ? "id" : "idBeforeClone")),
            ...(tile.$type === "ContainerTile"
                ? {tiles: tile.tiles?.map((t) => removeIdsInTile(t))}
                : arrMapToO(fieldAttrs || [], (attr) =>
                      changeTileFields(attr, tile, (field) => renameAttribute(field, "id", isSharedTile ? "id" : "idBeforeClone"))
                  )),
        },
        isSharedTile ? [] : ["isShared", "defaultColSpan", "defaultRowSpan", "adminTileName"]
    );
};
exports.removeIdsInTile = removeIdsInTile;

const removeIdsInCollection = (collection) => {
    return {
        ...renameAttribute(collection, "id", "idBeforeClone"),
        filters: collection.filters?.map((filter) => renameAttribute(filter, "id", "idBeforeClone")),
        // DashboardCollection
        ...(collection.gridLocations && {
            gridLocations: collection.gridLocations.map((gl) => ({
                ...gl,
                tile: removeIdsInTile(gl.tile),
            })),
        }),
        // ApiCollection, SparkCollection
        ...(collection.tile && {
            tile: removeIdsInTile(collection.tile),
        }),
    };
};
exports.removeIdsInCollection = removeIdsInCollection;
