import React from "react";
import SearchIcon from "assets/icons/common/SearchIcon";
import {cs} from "../../react/chain-services";
import {bindInput} from "../../react/bind-input";
import {DebounceCache} from "../../react/debounce-cache";
import {cLsJson} from "../../react/ls-json";
import "./search-input-bar.scss";
import {UseState} from "../../react/use-state";
import {cx} from "emotion";
import {cSsJson} from "../../react/ss-json";

const ssJson = (key) => cSsJson(key);

export const SearchInputBar = ({state, placeholder, autoFocus, delay = 500, type, onBlur, onFocus, className, inputClassName, iconColor, ...restProps}) =>
    cs(
        [
            "debounce",
            (_, next) =>
                DebounceCache({
                    state: {
                        value: state.value,
                        onChange: (v) => {
                            state.onChange(v);
                            type && ssJson("SEARCH_VALUE").set({[type]: v});
                        },
                    },
                    next,
                    delay,
                }),
        ],
        ({debounce}) => (
            <div className={cx("search-input--bar-88o search-input-bar", className)}>
                <div className="input-box">
                    {SearchIcon({fill: iconColor})}

                    <input
                        {...{
                            className: inputClassName,
                            ...bindInput(debounce.state),
                            placeholder: placeholder ?? "Search",
                            onFocus: () => {
                                // focused.onChange(true);
                                onFocus?.();
                            },
                            onBlur: () => {
                                onBlur?.();
                                // focused.onChange(false);
                                debounce.flush();
                            },
                            autoFocus,
                        }}
                        {...restProps}
                    />

                    {(debounce.active || debounce.state.value) && (
                        <i
                            className="fal fa-times"
                            style={{color: iconColor}}
                            onMouseDown={(e) => e.preventDefault()}
                            onClick={() => {
                                type && ssJson("SEARCH_VALUE").set({[type]: null});
                                state.onChange(null);
                            }}
                        />
                    )}
                </div>
            </div>
        )
    );

export const SearchInputBar2 = ({state, placeholder, autoFocus, delay = 500, type, onBlur, onFocus, onClear, ...restProps}) =>
    cs(
        // ["debounce", (_, next) => DebounceCache({
        //     state: {
        //         value: state.value,
        //         onChange: (v) => {
        //             state.onChange(v);
        //             type && lsJson("SEARCH_VALUE").set({[type]: v});
        //         }
        //     },
        //     next,
        //     delay
        // })],
        ({debounce}) => (
            <div className={cx("search-input--bar-88o search-input-bar")}>
                <div className="input-box">
                    {SearchIcon({})}

                    <input
                        {...{
                            ...bindInput(state),
                            placeholder: placeholder ?? "Search",
                            onFocus: () => {
                                // focused.onChange(true);
                                onFocus?.();
                            },
                            onBlur: () => {
                                onBlur?.();
                                // focused.onChange(false);
                                // debounce.flush();
                            },
                            autoFocus,
                        }}
                        {...restProps}
                    />

                    {state.value && (
                        <i
                            className="fal fa-times"
                            onMouseDown={(e) => e.preventDefault()}
                            onClick={() => {
                                onClear();
                                // state.onChange(null);
                            }}
                        />
                    )}
                </div>
            </div>
        )
    );
