const {IconUnchangedSvgStr} = require("./icons-svg-string");
const {IconDownSvgStr} = require("./icons-svg-string");
const {IconUpSvgStr} = require("./icons-svg-string");
const {cGetFontSize} = require("../../common/style-map/font-size");
const {getTextWidth, getHtmlWidth} = require("../../render-chart/render-chart-helper");
const {getDataLabelsStyle} = require("../../factory/data-labels");

const getDataLabelsOptions = ({tile, theme, formatters, isCompare}) => {
    const dataLabelsStyle = tile.style.dataLabels;
    const getFontSize = cGetFontSize(theme.general.canvas.fontSize, theme);
    return {
        // enabled: dataLabelsStyle.show,

        // for inside slice labels not being cut off when tile width is narrow
        crop: false,
        overflow: "allow",

        // alignTo: "connectors", // "toPlotEdges",

        // when connector lines are not shown, this need to be true, take care of the overlaps myself
        allowOverlap: tile.style.calloutLineShown ? dataLabelsStyle.allowOverlap : true,

        padding: 0,

        connectorWidth: !dataLabelsStyle.position.startsWith("Inside") && tile.style.calloutLineShown ? tile.style.calloutLineWidth || 1 : 0,
        connectorColor: tile.style.connectorColor || "#EAEAEA",
        softConnector: false,

        // distance: dataLabelsStyle.position?.includes("Inside") ? `-${Math.min(Math.ceil((100 - (tile.style.holeDiameter || 0)) / 2), 30)}%` : 20,

        style: getDataLabelsStyle({dataLabelsConfig: dataLabelsStyle, theme}).style,

        useHTML: true,
        formatter: function () {
            const chart = this.series.chart;
            const center = this.series.center;
            const point = this.point;
            const data = this.series.data.map((d) => d.y);

            //hide label if some of value = 0
            if (data.filter((v) => v > 0).length > 0 && point.y === 0) return null;

            const fontSize = getFontSize({
                group: theme.dataVisualization.fonts.dataLabelFontSize,
            });

            const pointName = dataLabelsStyle.categoryShown ? formatters.groupFieldFormatter(point.name) : "";
            const maxLabelWidth = tile.style.dataLabels.position?.includes("Inside")
                ? chart.chartWidth / 3
                : (chart.plotWidth - center[2]) / 2;

            const valueFmt = tile.style.autoRound ? formatters.valueRoundFormatter : formatters.valueFormatter;
            const percentFmt = tile.style.autoRound ? formatters.percentRoundFormatter : formatters.percentFormatter;

            const changeIndicator = (() => {
                if (!isCompare) {
                    return;
                }

                const respHeight = (fontSize * 15) / 11;
                const respData = chart.series.find((s) => s.options.isCompare).data.find((p) => p.name === point.name);

                return respData?.realPercentage == null
                    ? IconUnchangedSvgStr({})
                    : `<div style="transform: scale(${
                          respHeight / 21
                      }); height: ${respHeight}; display: inline-block; vertical-align: middle; margin-left: ${fontSize / 3}px">` +
                          `${
                              point.realPercentage > respData.realPercentage
                                  ? IconUpSvgStr({
                                        fill: theme.dataVisualization.dataColorPalettes.otherColors.conditionalGoodColorRGB || "#18C96E",
                                    })
                                  : IconDownSvgStr({
                                        fill: theme.dataVisualization.dataColorPalettes.otherColors.conditionalBadColorRGB || "#E95A5A",
                                    })
                          }` +
                          `</div>`;
            })();

            const firstLine = `<div>` + pointName + (changeIndicator || "") + `</div>`;

            const value =
                dataLabelsStyle.metricShown &&
                `
                ${dataLabelsStyle.metrics?.includes("Value") ? valueFmt(point.y) : ""} 
                ${dataLabelsStyle.metrics?.includes("Percent") ? "(" + percentFmt(point.realPercentage) + ")" : ""}
            `;
            const label = `<div style="font-size: ${fontSize}px">` + [firstLine, value].filter((v) => v).join("") + "</div>";
            // hide data label if it's too long
            if (getHtmlWidth(label) > maxLabelWidth) {
                return null;
            }
            return label;
        },
    };
};
exports.getDataLabelsOptions = getDataLabelsOptions;
