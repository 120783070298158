import React from "react";
import {singleKPITemplate} from "../tile-templates/single-kpi/single-kpi-template";
import {tableKPITemplate} from "../tile-templates/table-kpi/table-kpi-template";
import {KPIListTemplate} from "../tile-templates/kpi-list/kpi-list-template";
import {gaugeTemplate} from "../tile-templates/gauge/gauge-template";
import {verticalBarTemplate} from "../tile-templates/vertical-bar/vertical-bar-template";
import {horizontalBarTemplate} from "../tile-templates/horizontal-bar/horizontal-bar-template";
import {lineChartTemplate} from "../tile-templates/line-chart/line-chart-template";
import {areaChartTemplate} from "../tile-templates/area-chart/area-chart-template";
import {pieChartTemplate} from "../tile-templates/pie-chart/pie-chart-template";
import {downloadReportTemplate} from "../tile-templates/download-report/download-report-template";
import {tableTemplate} from "../tile-templates/table/table-template";
import {comboChartTemplate} from "../tile-templates/combo-chart/combo-chart-template";
import {containerTileTemplate} from "../tile-templates/container-tile/container-tile-template";
import {mapTileTemplate} from "../tile-templates/map-tile/map-tile-template";
import {scatterPlotTemplate} from "../tile-templates/scatter-plot/scatter-plot-template";
import {bubbleChartTemplate} from "../tile-templates/scatter-plot/bubble-template";

import {
    AreaLineIcon,
    ComboChartIcon,
    ContainerIcon,
    DownloadReportIcon,
    FunnelChartIcon,
    GaugeIcon,
    GroupKPIIcon,
    HorizontalBarIcon,
    KPIListIcon,
    LineChartIcon,
    PieChartIcon,
    PivotTableIcon,
    SingleKPIIcon,
    TableIcon,
    VerticalBarIcon,
    MapIcon,
    VennDiagramIcon,
    ScatterPlotIcon,
    SectionTileIcon,
    TextTileIcon,
    BubbleChartIcon,
    BoxPlotIcon,
    SparkKPIIcon,
    TextLabelTileIcon,
} from "./tile-icons";
import {pivotTableTemplate} from "../tile-templates/pivot-table/pivot-table-template";
import {funnelChartTemplate} from "../tile-templates/funnel-chart/funnel-chart-template";
import {vennDiagramTemplate} from "../tile-templates/venn-diagram/venn-diagram-template";
import {sectionTileTemplate} from "../tile-templates/section-tile/section-tile-temaplate";
import {textTileTemplate} from "../tile-templates/text-tile/text-tile-template";
import {boxPlotTemplate} from "../tile-templates/box-plot/box-plot-template";
import {sparkLineKPITemplate} from "../tile-templates/sparkline-kpi/sparkline-kpi-template";
import {textLabelTileTemplate} from "../tile-templates/text-label-tile/text-label-tile-template";

export const TILES = {
    SINGLE_KPI: "SINGLE_KPI",
    GROUP_KPI: "GROUP_KPI",
    LIST_KPI: "LIST_KPI",
    SPARKLINE_KPI: "SPARKLINE_KPI",
    GAUGE: "GAUGE",
    VERTICAL_BAR: "VERTICAL_BAR",
    HORIZONTAL_BAR: "HORIZONTAL_BAR",
    LINE: "LINE",
    AREA_LINE: "AREA_LINE",
    COMBO: "COMBO",
    PIE: "PIE",
    TABLE: "TABLE",
    PIVOT_TABLE: "PIVOT_TABLE",
    DOWNLOADABLE_REPORT: "DOWNLOADABLE_REPORT",
    MAP: "MAP",
    FUNNEL: "FUNNEL",
    VENN: "VENN",
    SCATTER_PLOT: "SCATTER_PLOT",
    BUBBLE: "BUBBLE",
    BOX_PLOT: "BOX_PLOT",
    CONTAINER: "CONTAINER",
    TEXT: "TEXT",
    SECTION_TITLE: "SECTION_TITLE",
    TEXT_LABEL: "TEXT_LABEL",
};

export const TILE_CATEGORIES = {
    KPI: "KPI",
    BAR_AND_LINE: "BAR_AND_LINE",
    GAUGE_AND_PIE: "GAUGE_AND_PIE",
    TABLE_AND_DOWNLOAD: "TABLE_AND_DOWNLOAD",
    LOCATION: "LOCATION",
    SPECIALTY: "SPECIALTY",
    ORGANIZATION: "ORGANIZATION",
    DATA_SCIENCE: "DATA_SCIENCE",
};

export const tileCategoriesConfig = [
    {
        key: TILE_CATEGORIES.KPI,
        label: "KPI Tiles",
    },
    {
        key: TILE_CATEGORIES.BAR_AND_LINE,
        label: "Bar & Line Charts",
    },
    {
        key: TILE_CATEGORIES.GAUGE_AND_PIE,
        label: "Gauge & Pie",
    },
    {
        key: TILE_CATEGORIES.TABLE_AND_DOWNLOAD,
        label: "Data Tables & Downloads",
    },
    {
        key: TILE_CATEGORIES.LOCATION,
        label: "Location Based",
    },
    {
        key: TILE_CATEGORIES.SPECIALTY,
        label: "Specialty",
    },
    {
        key: TILE_CATEGORIES.ORGANIZATION,
        label: "Organization Tiles",
    },
    {
        key: TILE_CATEGORIES.DATA_SCIENCE,
        label: "Data Science",
    },
];

export const tileTypes = [
    {
        key: TILES.SINGLE_KPI,
        types: ["SingleKPITile"],
        name: "Single KPI/Number",
        template: singleKPITemplate,
        icon: <SingleKPIIcon />,
    },
    {
        key: TILES.GROUP_KPI,
        types: ["TableKPITile"],
        name: "Group KPI/Number",
        template: tableKPITemplate,
        icon: <GroupKPIIcon />,
    },
    {
        key: TILES.LIST_KPI,
        types: ["ListKPITile"],
        name: "KPI List",
        template: KPIListTemplate,
        icon: <KPIListIcon />,
    },
    {
        key: TILES.GAUGE,
        types: ["GaugeTile"],
        name: "Gauge",
        template: gaugeTemplate,
        icon: <GaugeIcon />,
    },
    {
        key: TILES.VERTICAL_BAR,
        types: ["VerticalBarChartTile"],
        displayTypes: ["Classic", "Stacked", "HundredPercent"],
        name: "Vertical Bar",
        template: verticalBarTemplate,
        icon: <VerticalBarIcon />,
    },
    {
        key: TILES.HORIZONTAL_BAR,
        types: ["HorizontalBarChartTile"],
        displayTypes: ["Classic", "Stacked", "HundredPercent"],
        name: "Horizontal Bar",
        template: horizontalBarTemplate,
        icon: <HorizontalBarIcon />,
    },
    {
        key: TILES.LINE,
        types: ["LineChartTile"],
        displayTypes: ["Line", "Spline"],
        name: "Line",
        template: lineChartTemplate,
        icon: <LineChartIcon />,
        change: (tile) => ({
            ...tile,
            style: {
                ...tile.style,
                displayType: tile.style.displayType.includes("SplineArea") ? "Spline" : "Line",
            },
        }),
    },
    {
        key: TILES.AREA_LINE,
        types: ["LineChartTile"],
        displayTypes: ["Area", "SplineArea", "AreaStacked", "SplineAreaStacked", "AreaHundredPercent", "SplineAreaHundredPercent"],
        name: "Area Line",
        template: areaChartTemplate,
        icon: <AreaLineIcon />,
        change: (tile) => ({
            ...tile,
            style: {
                ...tile.style,
                displayType: tile.style.displayType === "Spline" ? "SplineArea" : "Area",
            },
        }),
    },
    {
        key: TILES.SCATTER_PLOT,
        types: ["ScatterPlotTile"],
        name: "Scatter Plot",
        template: scatterPlotTemplate,
        icon: <ScatterPlotIcon />,
    },
    {
        key: TILES.BUBBLE,
        types: ["BubbleChartTile"],
        name: "Bubble",
        template: bubbleChartTemplate,
        icon: <BubbleChartIcon />,
    },
    {
        key: TILES.PIE,
        types: ["PieChartTile", "DonutChartTile"],
        name: "Pie/Donut",
        template: pieChartTemplate,
        icon: <PieChartIcon />,
    },
    {
        key: TILES.DOWNLOADABLE_REPORT,
        types: ["DownloadReportTile"],
        name: "Downloadable Report",
        template: downloadReportTemplate,
        icon: <DownloadReportIcon />,
    },
    {
        key: TILES.TABLE,
        types: ["TableTile"],
        name: "Table",
        template: tableTemplate,
        icon: <TableIcon />,
    },
    {
        key: TILES.PIVOT_TABLE,
        types: ["PivotTableTile"],
        name: "Pivot Table",
        icon: <PivotTableIcon />,
        template: pivotTableTemplate,
    },
    {
        key: TILES.COMBO,
        types: ["ComboChartTile"],
        name: "Combo",
        template: comboChartTemplate,
        icon: <ComboChartIcon />,
    },
    {
        key: TILES.CONTAINER,
        types: ["ContainerTile"],
        displayTypes: ["Buttons", "Tabs", "Dropdown"],
        name: "Container Tile",
        template: containerTileTemplate,
        icon: <ContainerIcon />,
        afterChange: (sizeField) => {
            if (sizeField?.state?.value) {
                const {width, height} = sizeField?.state?.value;

                if (width < 12 || height < 6) {
                    sizeField.state.onChange({
                        width: 12,
                        height: 6,
                    });
                }
            }
        },
    },
    {
        key: TILES.MAP,
        types: ["MapTile"],
        name: "Map Tile",
        template: mapTileTemplate,
        icon: <MapIcon />,
    },
    {
        key: TILES.FUNNEL,
        types: ["FunnelChartTile"],
        name: "Funnel",
        template: funnelChartTemplate,
        icon: <FunnelChartIcon />,
    },
    {
        key: TILES.VENN,
        types: ["VennDiagramTile"],
        name: "Venn Diagram",
        template: vennDiagramTemplate,
        icon: <VennDiagramIcon />,
    },
    {
        key: TILES.SECTION_TITLE,
        types: ["SectionTile"],
        name: "Section",
        template: sectionTileTemplate,
        icon: <SectionTileIcon />,
    },
    {
        key: TILES.TEXT,
        types: ["TextTile"],
        name: "Text",
        template: textTileTemplate,
        icon: <TextTileIcon />,
    },
    {
        key: TILES.BOX_PLOT,
        types: ["BoxPlotTile"],
        name: "Box Plot",
        template: boxPlotTemplate,
        icon: <BoxPlotIcon />,
    },
    {
        key: TILES.SPARKLINE_KPI,
        types: ["SparkLineKPITile"],
        name: "KPI Sparkline",
        template: sparkLineKPITemplate,
        icon: <SparkKPIIcon />,
    },
    {
        key: TILES.TEXT_LABEL,
        types: ["TextLabelTile"],
        name: "Text Label",
        template: textLabelTileTemplate,
        icon: <TextLabelTileIcon />,
    },
];

export const TILE_CATEGORY_MAP = {
    [TILE_CATEGORIES.KPI]: [TILES.SINGLE_KPI, TILES.SPARKLINE_KPI, TILES.GROUP_KPI, TILES.LIST_KPI],
    [TILE_CATEGORIES.BAR_AND_LINE]: [TILES.VERTICAL_BAR, TILES.HORIZONTAL_BAR, TILES.LINE, TILES.AREA_LINE, TILES.COMBO],
    [TILE_CATEGORIES.GAUGE_AND_PIE]: [TILES.PIE, TILES.GAUGE],
    [TILE_CATEGORIES.TABLE_AND_DOWNLOAD]: [TILES.TABLE, TILES.PIVOT_TABLE, TILES.DOWNLOADABLE_REPORT],
    [TILE_CATEGORIES.LOCATION]: [TILES.MAP],
    [TILE_CATEGORIES.SPECIALTY]: [TILES.FUNNEL, TILES.VENN],
    [TILE_CATEGORIES.ORGANIZATION]: [TILES.TEXT_LABEL, TILES.TEXT, TILES.SECTION_TITLE, TILES.CONTAINER],
    [TILE_CATEGORIES.DATA_SCIENCE]: [TILES.SCATTER_PLOT, TILES.BUBBLE, TILES.BOX_PLOT],
};

export const NO_DATA_TILES = [TILES.DOWNLOADABLE_REPORT, TILES.TEXT_LABEL, TILES.TEXT, TILES.SECTION_TITLE];