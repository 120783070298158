import {sort, unique} from "../../../../../utils/collections";
import {chain} from "../../../../../utils/fs";

export const getExtractedPoints = ({points, formatters, keepZeroValuePoints}) => {
    if (points.length === 0) {
        return {};
    }

    const extractedPoints = points.map((point) => {
        const series = point.series;
        const valueFormatter = formatters.tooltipValueFormatters[series.userOptions.customStack][series.userOptions.indexInCustomStack];
        return {
            name: series.name,
            color: series.userOptions.colorByPoint ? point.color : series.userOptions.color?.linearGradient ? series.userOptions._baseColor : series.userOptions.color || point.color,
            dimCategory: point.key || point.name,
            dimValue: point.x,
            value: point.y,
            valueFormatter,
            title: series.userOptions.measureAxisTitle,
            customStack: series.userOptions.customStack,
            legendIndex: series.userOptions.legendIndex,
        };
    });

    const generalInfo = {
        name: extractedPoints[0].name,
        title: extractedPoints[0].title,
        dimValue: extractedPoints.find((p) => p.dimValue)?.dimValue,
        dimCategory: extractedPoints.find((p) => p.dimCategory)?.dimCategory,
        customStack: extractedPoints[0].customStack,
        defaultMeasureAxisTitle: unique(extractedPoints.map((p) => p.name)).join(" & "),
        valueFormatter: extractedPoints[0].valueFormatter,
    };

    return {
        extractedPoints: chain(
            extractedPoints,
            (_) => (keepZeroValuePoints ? _ : _.filter((p) => p.value !== 0)),
            (_) => sort(_, (p) => p.legendIndex)
        ),
        generalInfo,
    };
};
