import React from "react";
import {cs} from "@common/react/chain-services";
import {TestMaps} from "./test-maps";
import {TestChartElementTooltip} from "./test-chart-element-tooltip";
import {consumeContext} from "@common/react/context";
import {RoutingParam} from "../common/routing/routing-param";
import "./test-route.scss";
import {TestPivotTableRoute} from "./test-pivot-table";
import {TestURLEditor} from "./test-url-editor";
import {TabsHeader} from "../common/tabs/tabs-header";
import {WysiwygEditor} from "../common/wysiwyg-editor/wysiwyg-editor";
import NotionReport from "./notion-report/notion-report";

export const TestRoute = () =>
    cs(
        consumeContext("routing"),
        [
            "selectedTab",
            (_, next) =>
                RoutingParam({
                    param: "tab",
                    defaultValue: "notion-report",
                    // defaultValue: "tooltip-currency",
                    next,
                }),
        ],
        ({selectedTab}) => (
            <div className="test-route-5as">
                {TabsHeader({
                    className: "tabs-header",
                    tabs: tabs.map((tab) => ({
                        ...tab,
                        onClick: () => selectedTab.onChange(tab.name),
                        active: selectedTab.value === tab.name,
                        label: tab.name,
                    })),
                })}
                {tabs.find((tab) => tab.name === selectedTab.value).comp()}
            </div>
        )
    );

const tabs = [
    // {
    //     name: "map",
    //     comp: TestMaps,
    // },
    // {
    //     name: "tooltip-currency",
    //     comp: TestChartElementTooltip,
    // },
    // {
    //     name: "pivot-table",
    //     comp: TestPivotTableRoute,
    // },
    // {
    //     name: "test-editor",
    //     comp: WysiwygEditor,
    // },
    // {
    //     name: "url-editor",
    //     comp: TestURLEditor,
    // },
    {
        name: "notion-report",
        comp: NotionReport,
    },
];
