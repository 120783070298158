import {defaultDateRangeModes} from "../../../../../../../../../../common/ui-components/live/live-dashboard/common/logic/default-date-range-modes";
import {isNil} from "../../../../../../../../../../common/utils/common";
import {today, addDate, ge, le, tomorrow, dateDiff} from "../../../../../../../../../../common/utils/dates/date-object";

export const getPickableRangesFromToday = ({pickerMaxDaysInPast, pickerMaxDaysInFuture}) => {
    let allowedStartDate, allowedEndDate;
    if (!isNil(pickerMaxDaysInPast)) {
        allowedStartDate = addDate(today(), -pickerMaxDaysInPast);
    }
    if (!isNil(pickerMaxDaysInFuture)) {
        allowedEndDate = addDate(today(), pickerMaxDaysInFuture);
    }

    return {
        allowedStartDate,
        allowedEndDate,
        message: "Cannot pick outside pickable range.",
    };
};

export const getPickableCompareRange = (main) => {
    if (!main) return null;
    // const minStart = addDate(main.from, -dateDiff(main.to, main.from));
    return {
        allowedStartDate: null,
        allowedEndDate: addDate(main.from, -1),
        message: "Comparison start date must be earlier so no overlap occurs with the primary date range.",
    };
};

const moreThanXDaysInPast =
    (x) =>
    (d, {filter, allowedStartDate}) =>
        !allowedStartDate || filter.pickerMaxDaysInPast >= x;

const dateRangesWithMaxMinSelectionValidators = {
    Custom: () => true,
    Today: () => true,
    Yesterday: () => true,
    Tomorrow: () => true,
    AllTime: (d, {allowedEndDate, allowedStartDate}) => !allowedEndDate && !allowedStartDate,
    MonthToDate: moreThanXDaysInPast(30),
    MonthToYesterday: moreThanXDaysInPast(30),
    YearToDate: moreThanXDaysInPast(365),
    LastWeek: moreThanXDaysInPast(13),
    LastMonth: moreThanXDaysInPast(61),
    LastFullYear: moreThanXDaysInPast(730),
};

const checkDateRangeInSelectionRange = (d, {allowedEndDate, allowedStartDate}) => {
    const range = d.getMainRange();
    return (!allowedStartDate || ge(range.from, allowedStartDate)) && (!allowedEndDate || le(addDate(range.to, -1), allowedEndDate));
};

export const getDateRangesFromMaxMinSelection = (filter) => {
    const {allowedEndDate, allowedStartDate} = getPickableRangesFromToday(filter.value);
    return defaultDateRangeModes.filter((d) => {
        const validator = dateRangesWithMaxMinSelectionValidators[d.name];
        return validator
            ? validator(d, {allowedEndDate, allowedStartDate, filter: filter.value})
            : checkDateRangeInSelectionRange(d, {allowedEndDate, allowedStartDate});
    });
};
