import * as React from "react";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {SearchInput} from "@common/form/search-input/search-input";
import "./right-column-picker.scss";
import {consumeContext} from "@common/react/context";
import {keyed} from "@common/react/keyed";
import {Expandable} from "../../../../../../common/expandable/expandable";
import {UsageStatusIndicator} from "../../../common/usage-status-indicator";
import {dataViewColor, getDSColorBasedOnIndex} from "../../../common/data-source-colors";
import {cGetSearchedTables} from "../../../common/get-searched-tables";
import {TableLabel} from "../../../common/table-label/table-label";
import {ColumnLine} from "../../../common/column-line/column-line";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";

export const RightColumnPicker = ({modelTables, dataSources, state, watchCol}) =>
    cs(consumeContext("dataSourceModelContext"), ["search", (_, next) => UseState({next})], ({search, dataSourceModelContext}) => {
        const {getDataSourceUsage, getRelationshipUsage, getEntities} = dataSourceModelContext;

        const getSearchedTables = cGetSearchedTables(search.value);

        const mixedDsTables = (() => {
            const dsMap = {};
            modelTables
                .filter((t) => t.$type !== "ViewModelTable")
                .forEach((t) => {
                    if (t.dataSourceID) {
                        dsMap[t.dataSourceID] = [...(dsMap[t.dataSourceID] || []), t];
                    }
                });
            return Object.keys(dsMap).map((dsId) => {
                const dsIndex = dataSources.findIndex((ds) => ds.id === dsId);
                const ds = dataSources.find((ds) => ds.id === dsId);
                return {
                    id: dsId,
                    dsIndex,
                    name: ds?.name,
                    tables: dsMap[dsId],
                };
            });
        })();

        // const hasSelectedColumn = (table) => {
        //     for (const col of table.columns) {
        //         if (col.id === state.value) {
        //             return true;
        //         }
        //     }
        //     return false;
        // };

        const rColumnLine = (column) => {
            return (
                <div key={column.id} className="column-line-wrapper" onClick={() => state.onChange(column.id)}>
                    {ColumnLine({
                        type: column.$type,
                        name: column.name,
                        transformationId: column.calculations?.[0]?.transformationID,
                        versionDetails: column.calculations?.[0]?.versionDetails,
                        publishedOn: column.calculations?.[0]?.publishedOn,
                        dataType: column.dataType,
                        hasRelationship: getRelationshipUsage({columnId: column.id}),
                        highlighted: column.id === state.value,
                    })}
                </div>
            );
        };

        return (
            <div className="right-column-picker-2ut right-column-picker">
                <div className="search">
                    <SearchInput
                        {...{
                            state: search,
                            label: "Search",
                            placeholder: "Find Fields",
                        }}
                    />
                </div>
                <VerbScrollbar className="data-lists" ref={watchCol.containerRef}>
                    <Expandable
                        {...{
                            label: "Data Sources",
                            render: () =>
                                mixedDsTables.map((ds) =>
                                    cs(keyed(ds.id), () => {
                                        // const hasSelectedCol = (() => {
                                        //     for (const table of ds.tables) {
                                        //         if (hasSelectedColumn(table)) {
                                        //             return true;
                                        //         }
                                        //     }
                                        //     return false;
                                        // })();
                                        return Expandable({
                                            label: (
                                                <div className="data-source-label">
                                                    <UsageStatusIndicator
                                                        status={getDataSourceUsage(ds)}
                                                        color={ds.colorRGB || getDSColorBasedOnIndex(ds.dsIndex)}
                                                    />

                                                    {ds.name}
                                                </div>
                                            ),
                                            level: 1,
                                            noTopBorder: true,
                                            // passiveExpand: hasSelectedCol,
                                            render: () => (
                                                <div className="ds-tables">
                                                    {getSearchedTables(ds.tables).map((table) =>
                                                        cs(keyed(table.id), ({}) =>
                                                            Expandable({
                                                                initExpand: false,
                                                                label: TableLabel({
                                                                    table,
                                                                    usageStatus: "all",
                                                                    dsColorIndex: ds.dsIndex,
                                                                }),
                                                                level: 2,
                                                                noTopBorder: true,
                                                                passiveExpand: !!search.value, // || hasSelectedColumn(table),
                                                                render: () => table.columns.map((column) => rColumnLine(column)),
                                                            })
                                                        )
                                                    )}
                                                </div>
                                            ),
                                        });
                                    })
                                ),
                        }}
                    />
                    {Expandable({
                        label: "Data Views",
                        render: () => (
                            <div className="view-tables">
                                {getSearchedTables(modelTables.filter((t) => t.$type === "ViewModelTable")).map((table) =>
                                    cs(keyed(table.id), ({}) =>
                                        Expandable({
                                            initExpand: false,
                                            label: TableLabel({
                                                table,
                                                usageStatus: "all",
                                                dsColorIndex: dataSources.length,
                                                customColor: dataViewColor,
                                            }),
                                            level: 2,
                                            noTopBorder: true,
                                            passiveExpand: !!search.value,
                                            render: () => table.columns.map((column) => rColumnLine(column)),
                                        })
                                    )
                                )}
                            </div>
                        ),
                    })}
                </VerbScrollbar>
            </div>
        );
    });
