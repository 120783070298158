import React from "react";
import {cs} from "@common/react/chain-services";
import {cx} from "emotion";
import "./column-value-drilling-select.scss";
import {UseState} from "@common/react/use-state";
import {Static2} from "@common/react/static-2";
import {rFieldTypeIcon} from "../../../../common/field-type-icon/get-field-type-icon";
import {Watch} from "@common/react/watch";
import {Dropdown} from "@common/ui-components/dropdown/dropdown";
import {SearchInput} from "../../../../../../../common/form/search-input/search-input";
import {ColumnValueDrillingSelectHelper} from "./column-value-drilling-select-helper";
import {RadioButton} from "../../../../common/radio-button/radio-button";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";
import {isMatchText} from "@common/utils/strings";

/**
 * this takes relationships into account, allows choosing column through relationships.
 * to simply choose a column from a table, use column-select-from-model-columns.jsx
 * to simply choose column value from step outputColumns, use column-value-select-from-step-output.jsx
 */
export const ColumnValueDrillingSelect = ({
    label,
    onChange,
    model,
    inputModelTables,
    nonModelStepColumns,
    domRef,
    hasError,
    errorMessage,
    disableSelectingTable,
    filterColumnFn,
    isValueNotAllowed,
    ...otherProps
}) => {
    const {formatValue, parseValue} = otherProps;
    const {tables, relationships} = model;

    return cs(
        ["scrollContainerRef", (_, next) => Static2({next})],
        ["search", (_, next) => UseState({next, initValue: ""})],
        ["active", (_, next) => UseState({next})],
        ["tablesTree", (_, next) => next(inputModelTables.map((table) => ColumnValueDrillingSelectHelper.buildTree(table, tables, relationships)))],
        ["getStack", ({tablesTree}, next) => next((value) => ColumnValueDrillingSelectHelper.getPath(value || {}, tablesTree))],
        [
            "handleSelect",
            ({scrollContainerRef}, next) =>
                next(({column, table, stack}) => {
                    onChange(
                        parseValue({
                            table,
                            column,
                            isDrilling: stack?.length > 0,
                        })
                    );
                }),
        ],
        [
            "renderColumns",
            ({}, next) =>
                next(({stack, value, columns, onSelect, onExpand}) => {
                    const columns1 = filterColumnFn ? columns.filter(filterColumnFn) : columns;
                    return columns1.map((column) => {
                        const isNotAllowedToChoose = isValueNotAllowed?.(column);
                        return (
                            <div key={column.id} className="item">
                                <div
                                    className={cx("item-main", {
                                        selected: column.id === value?.columnId,
                                        wider: stack?.length > 0 && !disableSelectingTable,
                                        "not-allowed-to-choose": isNotAllowedToChoose,
                                    })}
                                    onClick={() => !isNotAllowedToChoose && onSelect(column)}
                                >
                                    {!isNotAllowedToChoose &&
                                        RadioButton({
                                            color: "#294661",
                                            selected: column.id === value?.columnId,
                                        })}

                                    {rFieldTypeIcon(column.dataType)}

                                    <div className="name">{column.name}</div>
                                </div>

                                {column.childs.length > 0 && (
                                    <div className="have-child" onClick={() => onExpand(column)}>
                                        Expand <i className="fa fa-chevron-right" />
                                    </div>
                                )}
                            </div>
                        );
                    });
                }),
        ],
        [
            "renderNonModelStepColumns",
            ({}, next) =>
                next(({value, columns, onSelect}) =>
                    columns.map((column, index) => {
                        const isNotAllowedToChoose = isValueNotAllowed?.(column);
                        // column.id === value?.columnId,
                        const isSelected = (column, value) => column.name === value?.name && column.$type === value?.$type;

                        return (
                            <div key={`view-step-column-${index}`} className="item">
                                <div
                                    className={cx("item-main", {
                                        selected: isSelected(column, value),
                                        "not-allowed-to-choose": isNotAllowedToChoose,
                                    })}
                                    onClick={() => !isNotAllowedToChoose && onSelect(column)}
                                >
                                    {!isNotAllowedToChoose &&
                                        RadioButton({
                                            color: "#294661",
                                            selected: isSelected(column, value),
                                        })}

                                    {rFieldTypeIcon(column.dataType)}

                                    <div className="name">{column.name}</div>
                                </div>
                            </div>
                        );
                    })
                ),
        ],
        [
            "getSelected",
            (_, next) =>
                next((value) => {
                    const selectedTable = value?.tableId ? tables.find((t) => t.id === value.tableId) : null;
                    const selectedColumn = selectedTable?.columns.find((c) => c.id === value?.columnId) || nonModelStepColumns.find((vsc) => vsc.name === value?.name);
                    return {
                        table: selectedTable,
                        column: selectedColumn,
                    };
                }),
        ],
        [
            "renderValue",
            ({}, next) =>
                next(({selected, stack}) => {
                    const getInfo = ({tableId, columnId}) => {
                        let table = tables.find((t) => t.id === tableId);
                        let column = table.columns.find((c) => c.id === columnId);
                        return {
                            table,
                            column,
                        };
                    };

                    if (stack.length === 0) {
                        return selected.column?.name;
                    }

                    let columnText = stack
                        .map((s, index) => {
                            let {table, column} = getInfo(s);
                            if (index === 0) {
                                return column.name;
                            }

                            return `${table.name}${column ? `.${column.name}` : ""}`;
                        })
                        .join(".");

                    const selectedTableText = selected.table ? (stack[stack.length - 1]?.tableId !== selected.table.id ? `.${selected.table.name}` : "") : "";
                    const selectedColumnText = selected.column ? (stack[stack.length - 1]?.columnId !== selected.column.id ? `.${selected.column.name}` : "") : "";
                    return `${columnText}${selectedTableText}${selectedColumnText}`;
                }),
        ],
        ["trackingVal", (_, next) => UseState({next})],
        [
            "handleExpand",
            ({trackingVal}, next) =>
                next(({columnId, tableId}) => {
                    trackingVal.onChange({columnId, tableId});
                }),
        ],
        ({tablesTree, search, getStack, getSelected, renderColumns, renderNonModelStepColumns, handleSelect, handleExpand, scrollContainerRef, renderValue, trackingVal}) => {
            const value = formatValue(otherProps.value);
            const valueStack = getStack(value);
            const trackingValue = trackingVal.value || {
                tableId: value?.tableId,
            };
            const trackingStack = getStack(trackingValue);
            const {header, list} = ColumnValueDrillingSelectHelper.getListByPath(trackingStack, tablesTree, []);

            const getSearchedColumns = (columns) => columns.filter((c) => isMatchText(c.name, search.value));

            return (
                <>
                    <div className={cx("column-value-drilling-select-dt3 column-value-drilling-select", {hasError})} ref={domRef}>
                        {Watch({
                            value: trackingStack,
                            onChanged: async (newStack, prevStack) => {
                                if (scrollContainerRef.get() && newStack.length !== prevStack.length) {
                                    scrollContainerRef.get()?.getDom()?.setScrollTop(0);
                                    search.onChange(null);
                                }
                            },
                        })}

                        {Dropdown({
                            minExpandHeight: 350,
                            renderToggle: ({showExpand, showingExpand}) => (
                                <div
                                    className={cx("toggle", {
                                        expanding: showingExpand,
                                        "with-value": value || ["StaticValue", "PreviousResult"].includes(otherProps.value?.$type),
                                    })}
                                >
                                    <div
                                        className={cx("display-value", {
                                            "use-static": ["StaticValue", "PreviousResult"].includes(value?.$type),
                                            // "view-step-column": value?.$type === "ViewStepColumn",
                                        })}
                                        onClick={() => showExpand(true)}
                                    >
                                        {value?.$type === "StaticValue" && otherProps.useStatic && <span>Static Value</span>}
                                        {value?.$type === "PreviousResult" && otherProps.usePreviousResult && <span>Previous Result</span>}

                                        {(() => {
                                            const selected = getSelected(value);

                                            if (selected.column?.deleted || !selected.column?.dataType) return null;

                                            if (selected.column || selected.table) {
                                                return (
                                                    <>
                                                        {selected.column?.dataType && rFieldTypeIcon(selected.column?.dataType)}
                                                        {selected.table && !selected.column && (
                                                            <img
                                                                className="table-icon"
                                                                src={
                                                                    selected.table.$type === "ViewModelTable"
                                                                        ? require("../../../../common/icons/data-view-icon.svg")
                                                                        : require("../../../../common/icons/table-icon.svg")
                                                                }
                                                                alt=""
                                                            />
                                                        )}
                                                        <div className="name">
                                                            {renderValue({
                                                                selected,
                                                                stack: valueStack,
                                                            })}
                                                        </div>
                                                    </>
                                                );
                                            }
                                        })()}
                                    </div>

                                    {label && <div className="label">{label}</div>}
                                    <i className="fa fa-chevron-down" />
                                </div>
                            ),
                            onPassiveClose: () => {
                                trackingVal.onChange(null);
                                search.onChange(null);
                            },
                            renderExpand: ({close, width}) => {
                                return (
                                    <div
                                        className={cx("list")}
                                        style={{
                                            minWidth: width,
                                            ...(search.value && {
                                                width: scrollContainerRef.get()?.getBoundingClientRect().width,
                                            }),
                                        }}
                                    >
                                        <div className="list-header">
                                            <SearchInput
                                                {...{
                                                    state: search,
                                                    label: "Search",
                                                    placeholder: "Find Column",
                                                    autoFocus: true,
                                                }}
                                            />
                                        </div>

                                        {header.length > 0 && (
                                            <div
                                                className="header-text"
                                                onClick={() => {
                                                    const {tableId, columnId} = trackingStack[trackingStack.length - 2] || {};
                                                    handleExpand({
                                                        columnId,
                                                        tableId,
                                                    });
                                                }}
                                            >
                                                <div className="chevron-left">
                                                    <img src={require("../../../../common/icons/chevron-thin-right.svg")} alt="" />
                                                </div>

                                                {header.slice(1).join(".")}
                                            </div>
                                        )}

                                        <VerbScrollbar maxHeight={250} className="list-wrapper" ref={scrollContainerRef.set}>
                                            {trackingStack.length === 0 ? (
                                                <>
                                                    {list.map((table) =>
                                                        renderColumns({
                                                            value,
                                                            columns: getSearchedColumns(table.columns),
                                                            onSelect: (column) => {
                                                                handleSelect({
                                                                    column,
                                                                    table,
                                                                    stack: trackingStack,
                                                                });
                                                                close();
                                                                search.onChange(null);
                                                            },
                                                            onExpand: (column) => {
                                                                handleExpand({
                                                                    columnId: column.id,
                                                                    tableId: table.id,
                                                                });
                                                            },
                                                        })
                                                    )}
                                                    {renderNonModelStepColumns({
                                                        value,
                                                        columns: getSearchedColumns(nonModelStepColumns),
                                                        onSelect: (column) => {
                                                            handleSelect({
                                                                column,
                                                                stack: trackingStack,
                                                            });
                                                            close();
                                                            search.onChange(null);
                                                        },
                                                    })}
                                                </>
                                            ) : (
                                                list.map((table, index) => {
                                                    const searchedColumns = getSearchedColumns(table.columns);
                                                    return (
                                                        searchedColumns.length > 0 && (
                                                            <div className="table" key={index}>
                                                                <div
                                                                    className={cx("table-name", {
                                                                        selected: value?.tableId === table.id && !value?.columnId,
                                                                        "allow-selecting": !disableSelectingTable,
                                                                    })}
                                                                    onClick={() => {
                                                                        if (!disableSelectingTable) {
                                                                            handleSelect({
                                                                                column: null,
                                                                                table,
                                                                                stack: trackingStack,
                                                                            });
                                                                            close();
                                                                            search.onChange(null);
                                                                        }
                                                                    }}
                                                                >
                                                                    {!disableSelectingTable &&
                                                                        RadioButton({
                                                                            color: "#294661",
                                                                            selected: value?.tableId === table.id && !value?.columnId,
                                                                        })}

                                                                    <div className="icon-name">
                                                                        <img
                                                                            className="table-icon"
                                                                            src={
                                                                                table.$type === "ViewModelTable"
                                                                                    ? require("../../../../common/icons/data-view-icon.svg")
                                                                                    : require("../../../../common/icons/table-icon.svg")
                                                                            }
                                                                            alt=""
                                                                        />

                                                                        {table.name}
                                                                    </div>
                                                                </div>

                                                                <div className="column-list">
                                                                    {renderColumns({
                                                                        stack: trackingStack,
                                                                        value,
                                                                        columns: searchedColumns,
                                                                        onSelect: (column) => {
                                                                            handleSelect({
                                                                                column,
                                                                                table,
                                                                                stack: trackingStack,
                                                                            });
                                                                            close();
                                                                            search.onChange(null);
                                                                        },
                                                                        onExpand: (column) => {
                                                                            handleExpand({
                                                                                columnId: column.id,
                                                                                tableId: table.id,
                                                                            });
                                                                        },
                                                                    })}
                                                                </div>
                                                            </div>
                                                        )
                                                    );
                                                })
                                            )}
                                        </VerbScrollbar>

                                        {otherProps.usePreviousResult && (
                                            <div
                                                className="use-static-value"
                                                onClick={() => {
                                                    onChange({
                                                        $type: "PreviousResult",
                                                    });
                                                    close();
                                                    search.onChange(null);
                                                }}
                                            >
                                                Previous Result
                                            </div>
                                        )}

                                        {otherProps.useStatic && (
                                            <div
                                                className="use-static-value"
                                                onClick={() => {
                                                    onChange({
                                                        $type: "StaticValue",
                                                    });
                                                    close();
                                                    search.onChange(null);
                                                }}
                                            >
                                                Use Static Value
                                            </div>
                                        )}
                                    </div>
                                );
                            },
                        })}

                        {hasError && errorMessage && <div className="error-message">{errorMessage}</div>}
                    </div>

                    {value?.$type === "StaticValue" &&
                        otherProps.useStatic &&
                        otherProps.renderStaticInput?.({
                            state: {
                                value: otherProps.value.value,
                                onChange: (v) => onChange({...otherProps.value, value: v}),
                            },
                        })}
                </>
            );
        }
    );
};
