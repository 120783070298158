import {unique} from "../../../../../../../../../../../../../common/utils/collections";

// AB#316
export const getHiddenPositionFields = (size) => {
    if (!size) return [];

    const {width, height} = size;

    let ret = [];

    if (!(width >= 12 && height >= 2) && !(width >= 6 && height >= 4)) {
        return ["Bottom", "Top", "BottomVertical", "TopVertical", "Left", "Right"];
    }

    if (width <= 11) {
        ret = ret.concat(["Left", "Right"]);
    }

    if (width <= 5) {
        ret = ret.concat(["Top", "Bottom"]);
    }

    if (height <= 3) {
        ret = ret.concat(["Top", "Bottom"]);
    }

    if (height <= 4) {
        ret = ret.concat(["TopVertical", "BottomVertical"]);
    }

    return unique(ret);
};

// AB#316, AB#474
export const getLegendPosition = (currentPos, size, chartType) => {
    if (["PieChartTile", "DonutChartTile", "VennDiagram"].includes(chartType) && currentPos == "Auto") {
        if (size.height >= 6 && size.width < 12) {
            return "BottomVertical";
        }
    }

    const positions = ["Bottom", "Top", "BottomVertical", "TopVertical", "Left", "Right"];
    const hiddenPos = getHiddenPositionFields(size);
    if (hiddenPos.indexOf(currentPos) == -1 && currentPos != "Auto") return currentPos;
    return positions.filter((p) => hiddenPos.indexOf(p) == -1)[0];
};
