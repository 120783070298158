exports.tableKPITemplate = {
    $type: "TableKPITile",
    style: {
        $type: "TableKPITileStyle",
        template: "Vertical",
        tableTitleShown: true,
        // tableTitle: display fields name
        actualValueShown: true,
        actualValueFormat: "ActualValue",
        autoRound: true,
        compareValueShown: true,
        compareValueMethod: "Percent",
        indicatorShown: true,
        positiveChangeGood: true,
        // previousPeriodShown: true,
        summaryShown: true,
        summaryAggregationFunc: "Sum",
        summaryLocation: "Top",
        summaryCompareValueShown: true,
        summaryCompareValueMethod: "Percent",
        summaryIndicatorShown: true,
        summaryPositiveChangeGood: true,
        summaryTitleShown: true,
        // summaryTitle: aggre type + name of fields list in valueField well
        title: {
            show: true,
            titleLocation: "Above",
        },
    },
};
