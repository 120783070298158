import * as React from "react";
import {cs} from "@common/react/chain-services";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {operations} from "../../../../../../../../common/filters/filter-edit-main/options/number-filter/operations";
import {stateToSelect} from "@common/ui-components/form/state-to-select";
import {scope} from "@common/react/scope";
import {NumberInput} from "../../../../../../../../../../../../common/form/number-input/number-input";
import {ChipInput} from "../../../../../../../../../../../../common/form/chip-input/chip-input";
import {ffToNumberInput} from "../../../../../../../../../../../../common/form/ff-to-number-input";
import "./number-filter-options.scss";

export const NumberFilterOptions = ({filter, form}) =>
    cs(({}) => {
        return (
            <div className="local-filter-options local-number-filter-options-dg5">
                {DropdownSelect({
                    label: "Comparison Method",
                    list: operations.filter((o) => {
                        if (o.aggregationFuncs) return o?.aggregationFuncs.includes(filter.value.aggregationFunc);
                        return true;
                    }),
                    valueToLabel: (v) => v.label,
                    ...stateToSelect(scope(filter, ["defaultOption"]), ["name"]),
                })}

                <div className="value-inputs">
                    {["EqualTo", "NotEqualTo", "GreaterThanOrEqualTo", "LessThanOrEqualTo", "GreaterThan", "LessThan"].includes(filter.value.defaultOption) ? (
                        <>
                            {NumberInput({
                                label: "Value",
                                showZero: true,
                                ...ffToNumberInput(form.field(["defaultValueOrMin"])),
                            })}
                        </>
                    ) : (
                        filter.value.defaultOption === "Range" && (
                            <>
                                {NumberInput({
                                    label: "Minimum",
                                    showZero: true,
                                    ...ffToNumberInput(form.field(["defaultValueOrMin"])),
                                })}
                                {NumberInput({
                                    label: "Maximum",
                                    showZero: true,
                                    ...ffToNumberInput(form.field(["defaultMax"])),
                                })}
                            </>
                        )
                    )}

                    {["InList", "NotInList"].includes(filter.value.defaultOption) &&
                        ChipInput({
                            state: form.field(["defaultList"]).state,
                            numericOnly: true,
                        })}
                </div>
            </div>
        );
    });
