import React from "react";
import {cs} from "../../../../../react/chain-services";
import {UseState} from "../../../../../react/use-state";
import {OnMounted} from "../../../../../react/on-mounted";

export const LegendControl = ({legendContainerRef, scrollLeft}) => {
    const container = legendContainerRef.get();
    const maxScrollLeft = container.scrollWidth - container.clientWidth;
    const containerWidth = container.getBoundingClientRect().width;

    return (
        <>
            {scrollLeft.value > 0 && (
                <div
                    className="action-left action-btn"
                    onClick={() => {
                        container.scrollLeft = scrollLeft.value - (containerWidth * 80) / 100;
                    }}
                >
                    <i className="material-icons">keyboard_arrow_left</i>
                </div>
            )}

            {scrollLeft.value < maxScrollLeft && (
                <div
                    className="action-right action-btn"
                    onClick={() => {
                        container.scrollLeft = scrollLeft.value + (containerWidth * 80) / 100;
                    }}
                >
                    <i className="material-icons">keyboard_arrow_right</i>
                </div>
            )}
        </>
    );
};
