import "./tile-fields.scss";

import * as React from "react";

import {cs} from "@common/react/chain-services";
import {provideContext} from "@common/react/context";
import {scope} from "@common/react/scope";

import {keepOnly} from "@common/utils/objects";

import {Expandable} from "../../../../../../../common/expandable/expandable";

import {getTileActionsUpdated} from "../actions/get-tile-actions-updated";

import {getTileFields} from "./common/get-tile-fields";
import {hasDateGroupsConfiguration} from "./common/has-date-groups-configuration";
import {Limit} from "./common/limit-sorting/limit/limit";
import {DimensionlessSorting} from "./common/limit-sorting/sorting/dimensionless/dimensionless-sorting";
import {getColumnType} from "./common/select-field/select-field";
import {SingleFieldWell} from "./common/single-field-well/single-field-well";
import {updateSingleFieldInTile} from "./common/update-tile";

export const TableKPIFields = ({tile, isSubTile, tileActions, debugFunction, form, next}) =>
    cs(
        [
            "tileFields",
            ({}, next) =>
                next(
                    getTileFields({
                        tile: tile.value,
                        measureSingleAttrs: ["valueField"],
                        groupFieldAttr: "groupField",
                    })
                ),
        ],
        ({tileFields}, next) => provideContext("tileFields", tileFields, next),

        [
            "groupField",
            (_, next) =>
                SingleFieldWell({
                    field: scope(tile, ["groupField"]),
                    hasDateGroupsConfiguration: hasDateGroupsConfiguration(tile.value.$type),
                    $type: "CategoryTileField",
                    label: "Group",
                    hideDisplayName: true,
                    filterColumn: (column) => column.$type !== "AggregatedMeasureModelColumn",
                    onUpdateTile: (newVal, oriVal) =>
                        updateSingleFieldInTile({
                            fieldAttr: "groupField",
                            fieldVal: {newVal, oriVal},
                            tile,
                            updateTileActions: getTileActionsUpdated,
                        }),
                    next,
                }),
        ],
        [
            "valueField",
            (_, next) =>
                SingleFieldWell({
                    field: scope(tile, ["valueField"]),
                    $type: "DimensionlessMeasureTileField",
                    label: "Value",
                    hideDisplayName: true,
                    next,
                }),
        ],
        [
            "fieldsSection",
            ({valueField, groupField}, next) =>
                next({
                    render: () =>
                        Expandable({
                            label: "Fields",
                            render: () => (
                                <>
                                    {valueField.render()}
                                    {groupField.render()}
                                </>
                            ),
                        }),
                    override: valueField.override || groupField.override,
                }),
        ],

        [
            "limitSorting",
            ({tileFields}, next) =>
                cs(
                    [
                        "limit",
                        (_, next) => {
                            const formField = form.field(["limit"]);
                            return next(
                                Limit({
                                    field: formField.state,
                                    onChange: (newLimit, v) =>
                                        form.data.change((d) => ({
                                            ...d,
                                            limit: newLimit,
                                            sort: [keepOnly(newLimit, ["modelID", "direction", "modelTableID", "modelColumnID"])],
                                        })),
                                    isHiddenRankOption: () => false,
                                    showOther: false,
                                })
                            );
                        },
                    ],
                    [
                        "sorting",
                        (_, next) =>
                            next(
                                DimensionlessSorting({
                                    fieldGroups: form.field(["sort"]).state,
                                    title: "Sorting on List",
                                    allFields: tileFields.allFieldsWithoutUniq,
                                })
                            ),
                    ],
                    ({limit, sorting}) =>
                        next({
                            render: () =>
                                Expandable({
                                    initExpand: false,
                                    label: "Limit & Sorting",
                                    render: () => (
                                        <>
                                            {limit}
                                            {sorting}
                                        </>
                                    ),
                                }),
                        })
                ),
        ],

        ({fieldsSection, limitSorting}) =>
            next({
                render: () => (
                    <div className="tile-fields-3vr">
                        {fieldsSection.render()}
                        {limitSorting.render()}
                        {tileActions?.render?.()}
                        {isSubTile && debugFunction?.render?.({})}
                    </div>
                ),
                override:
                    fieldsSection.override ||
                    debugFunction?.override() ||
                    tileActions?.override({
                        fieldType: "DimensionlessMeasureTileField",
                    }),
                overrideAll: debugFunction?.overrideAll || tileActions?.overrideAll,
            })
    );
