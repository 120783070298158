import React from "react";
import {cs} from "@common/react/chain-services";
import {cx} from "emotion";
import {concatArr} from "@common/utils/objects";
import {isAllRelationshipsRelated} from "./auto-suggest-helper";
import "./model-auto-suggest-overlay.scss";

export const SuggestionProgressPopup = ({progress, total}) =>
    cs(() => {
        return (
            <div className={cx("suggesting-progress-popup-a33")}>
                <div className="progress-title">One moment please…</div>

                <div className="progress-body">
                    <div className="chart-container">
                        <div
                            className="progress-bar"
                            style={{
                                width: `${(progress.value / total.value) * 100}%`,
                            }}
                        />
                    </div>

                    <div className="progress-description">
                        We’re building your model for you with up to 10 tables from your data sources.
                        <br />
                        <br />
                        Where possible, we are also setting up relationships between each table so you can combine the data from various tables in your charts.
                    </div>
                </div>
            </div>
        );
    });
