const {required} = require("../../../../../../../common/react/cs-form/validators/required");

exports.boxPlotFormConfig = {
    fields: {
        "xAxisField": {
            inGroups: ["fields"],
            validators: [],
        },
        "yAxisFields": {
            inGroups: ["fields"],
            validators: [],
        },
        "groupField": {
            inGroups: ["fields"],
            validators: [
            ],
        },
        title: {
            transforms: ["trim"],
            validators: [required],
            debounce: true,
        },
    },
};