import {cs} from "@common/react/chain-services";
import {Button} from "../../../../../../common/form/buttons/button/button";
import React from "react";
import {UseState} from "@common/react/use-state";
import {CircularProgressBar} from "../../../common/radial-progress/circular-progress-bar";
import {formatDateTimeFromISOStr} from "../../../../../../common/logic/date-time/format-date-time-full";
import {Load} from "@common/react/load";
import {consumeContext} from "@common/react/context";
import {Load2} from "@common/react/load2";

export const PublishThemeButton = ({themeID, name, collectionToast}) =>
    cs(
        consumeContext("apis"),
        ["publishing", (_, next) => UseState({next, initValue: false})],
        ["progress", (_, next) => UseState({next, initValue: 0})],
        ["showCheckIcon", (_, next) => UseState({next, initValue: false})],
        [
            "publishInfo",
            ({apis}, next) =>
                Load2({
                    next,
                    fetch: () => apis.theme.getPublishInfo(themeID),
                    _key: themeID,
                }),
        ],
        ["showTooltip", (_, next) => UseState({next})],
        ({publishing, publishInfo, showTooltip, apis, progress, showCheckIcon}) => {
            const iconAndTooltip = (() => {
                if (!publishInfo.value) {
                    return {
                        icon: null,
                        tooltip: "Checking publish status",
                    };
                }

                if (publishing.value) {
                    return {
                        icon: CircularProgressBar({
                            progressColor: "#FFFFFF",
                            holeColor: "#11A1FD",
                            progress: progress.value,
                            size: 16,
                        }),
                        tooltip: `Publish ${progress.value}% complete`,
                    };
                }

                const lastPublishedText = !publishInfo.value.publishedOn ? (
                    "Never published"
                ) : (
                    <>
                        Last published on <strong>{formatDateTimeFromISOStr(publishInfo.value.publishedOn, "MMM Do YYYY [at] h:mm A")}</strong> by {publishInfo.value.publishedBy}
                    </>
                );

                return {
                    icon: showCheckIcon.value ? <i className="far fa-check" style={{fontSize: 16}} /> : null,
                    tooltip: lastPublishedText,
                };
            })();

            return (
                <div
                    {...{
                        className: "publish-button-5hg publish-button",
                        onMouseOver: () => showTooltip.onChange(true),
                        onMouseOut: () => showTooltip.onChange(false),
                    }}
                >
                    <Button
                        className="collection-publish-btn"
                        size="medium"
                        btnType="primary"
                        disabled={!publishInfo.value || publishing.value}
                        iconLeft={iconAndTooltip.icon}
                        onClick={async () => {
                            publishing.onChange(true);
                            showCheckIcon.onChange(true);
                            progress.change((v) => 0);
                            const interval = setInterval(() => {
                                progress.change((v) => Math.min(v + 5, 99));
                            }, 100);
                            await apis.theme.publishTheme(themeID);
                            await publishInfo.reload();
                            setTimeout(() => {
                                progress.change((v) => 100);
                                clearInterval(interval);
                                publishing.onChange(false);
                                collectionToast.show({
                                    text: `${name} is published!`,
                                });
                            }, 1200);
                        }}
                    >
                        Publish{publishing.value ? `ing` : ``}
                    </Button>

                    {showTooltip.value && <div className="progress-tooltip bottom">{iconAndTooltip.tooltip}</div>}
                </div>
            );
        }
    );
