const {getFieldType} = require("../../common/get-field-type");
const {booleanFormatter} = require("./boolean-formatter");
const {isDimRealDateTime} = require("../is-dim-real-date-time");
const {getNumericFormatter} = require("./get-numeric-formatter");
const {getDateTimeFormatter} = require("./get-date-time-formatter");
const {roundTo} = require("../../common/formatters/numeric/round-to");
const {flatten1} = require("../../../../utils/collections");
const {chain} = require("../../../../utils/fs");

const getFormatters = ({tile, dimensionAttr, measurementAttrs, rawData, timezone}) => {
    const isDimensionRealDate = isDimRealDateTime({
        tile,
        dimensionAttr,
        series: rawData.series,
    });

    const dimensionFormatter = isDimensionRealDate
        ? getDateTimeFormatter(tile[dimensionAttr].dateProperties, timezone)
        : getFieldType(tile[dimensionAttr]) === "bool"
        ? booleanFormatter
        : (v) => v;

    const groupFieldFormatter = tile.groupField
        ? {
              date: tile.groupField?.isDateGroupCategory ? (v) => v : getDateTimeFormatter(tile.groupField.dateProperties, timezone),
              bool: booleanFormatter,
          }[getFieldType(tile.groupField)] || ((v) => v)
        : (v) => v;
    let measurementFormatters = {};
    let tooltipValueFormatters = {};

    const measureFieldGroups = flatten1(measurementAttrs.map((attr) => tile[attr]));

    measureFieldGroups.forEach((mfg) => {
        const stackKey = mfg.measureFields
            .map((mf) => mf.id)
            .sort()
            .join("|");
        measurementFormatters[stackKey] = [];
        tooltipValueFormatters[stackKey] = [];

        mfg.measureFields.forEach((f, i) => {
            // the index "i" here will be referred as "indexInCustomStack" later when formatters is used.

            const numericProperties = {
                ...f?.numericProperties,
            };
            const isAggCount = f?.aggregationFunc.startsWith("Count");

            const numericFormatter = getNumericFormatter({
                numericProperties: {
                    ...numericProperties,
                    currencyOverrides: tile?.currencyOverrides,
                },
                autoRound: true,
                useDefaultCountFormat: isAggCount,
            });
            const tooltipValueFormatter = getNumericFormatter({
                numericProperties: {
                    ...numericProperties,
                    currencyOverrides: tile?.currencyOverrides,
                },
                useDefaultCountFormat: isAggCount,
            });

            measurementFormatters[stackKey][i] = (v) =>
                v != null &&
                chain(
                    v,
                    (v) => (numericProperties?.displayType === "Percentage" ? v * 100 : v),
                    (v) => (numericProperties?.decimalPlaces != null ? roundTo(v, numericProperties.decimalPlaces) : roundTo(v, 2)),
                    (v) => numericFormatter(v)
                );

            tooltipValueFormatters[stackKey][i] = (v) =>
                v != null &&
                chain(
                    v,
                    (v) => (numericProperties?.displayType === "Percentage" ? v * 100 : v),
                    (v) => (numericProperties?.decimalPlaces != null ? roundTo(v, numericProperties.decimalPlaces) : roundTo(v, 2)),
                    (v) => tooltipValueFormatter(v)
                );
        });
    });

    const dateTimeFormatter = getDateTimeFormatter(tile[dimensionAttr].dateProperties, timezone);

    return {
        dimensionFormatter: (v) => v != null && dimensionFormatter(v),
        measurementFormatters,
        groupFieldFormatter,
        tooltipValueFormatters,
        dateFormatter: (v) => v != null && dateTimeFormatter(v),
        percentFormatter: (v) => roundTo(v, 1) + "%",
    };
};
exports.getFormatters = getFormatters;
