import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {cs} from "@common/react/chain-services";

export const BarCornerTypeSelect = ({value, onChange, ...props}) =>
    cs(({}) => {
        return DropdownSelect({
            label: props.label || "Apply Corner Radius To",
            list: [
                {
                    value: "Top",
                    label: "Top Corners",
                },
                {
                    value: "All",
                    label: "All Corners",
                },
            ],
            valueToLabel: (v) => v.label,
            isSelected: (v) => v.value === value,
            onChange: (v) => onChange(v.value),
        });
    });
