import "./account-suspended-dialog.scss";

import React from "react";

import {cs} from "@common/react/chain-services";
import {DialogService} from "../dialog/dialog-service";
import {consumeContext} from "@common/react/context";
import {createDateFormatter} from "@common/ui-components/charts/common/formatters/date-formatter";
import {Button} from "../../../../../common/form/buttons/button/button";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";

export const AccountSuspendedDialog = ({next: rootNext}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    render: ({resolve, args: {subscriptionBrief}}) => ({
                        title: "Account Suspended",
                        width: 480,
                        content: next({
                            resolve,
                            subscriptionBrief,
                        }),
                        initShow: false,
                        strong: true,
                        className: "account-suspended-wrapper-a33",
                    }),
                    next: rootNext,
                }),
        ],
        ({modal}) =>
            cs(
                consumeContext("tenant"),
                consumeContext("auth"),
                consumeContext("apis"),
                consumeContext("routing"),
                ({tenant, auth, routing, apis}) => {
                    const isCanUpdatePaymentMethod = auth.user.roles[0] == "Full";

                    return (
                        <div className="account-suspended-dialog-a33">
                            <VerbDialogBodyScrollbar>
                                {!isCanUpdatePaymentMethod && (
                                    <div className="alert">
                                        <img src={require("./alerts.svg")} alt="" />
                                        You do not have access to update your organization's billing information. Please contact a team
                                        member who has access.
                                    </div>
                                )}
                                {tenant?.tenant?.name}'s account is suspended because your payment is more than 7 days past its due date of{" "}
                                {createDateFormatter("MMM d, yyyy").format(new Date(modal.subscriptionBrief.pastDueAsOf || new Date()))}.
                                Your account has a total outstanding invoice balance of {modal.subscriptionBrief.outstandingBalance}.
                                <br />
                                <br />
                                Your service is paused until your billing information is updated. This means data will not sync and
                                collections are not available to your users.
                            </VerbDialogBodyScrollbar>

                            <div className="buttons">
                                <Button
                                    btnType={!isCanUpdatePaymentMethod ? "primary" : "secondary"}
                                    onClick={() => {
                                        modal.resolve();
                                        auth.logout();
                                        apis.user.signOut();
                                    }}
                                >
                                    Logout
                                </Button>

                                {isCanUpdatePaymentMethod && (
                                    <Button
                                        onClick={() => {
                                            modal.resolve();
                                            routing.goto("account-management", {
                                                setting: "organization-details",
                                                tab: "billing",
                                            });
                                        }}
                                    >
                                        Update Payment Method
                                    </Button>
                                )}
                            </div>
                        </div>
                    );
                }
            )
    );

export const AccountCanceledDialog = ({next: rootNext}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    render: ({resolve, args: {subscriptionBrief}}) => ({
                        title: "Account Canceled",
                        width: 480,
                        content: next({
                            resolve,
                            subscriptionBrief,
                        }),
                        initShow: false,
                        strong: true,
                        className: "account-suspended-wrapper-a33",
                    }),
                    next: rootNext,
                }),
        ],
        ({modal}) =>
            cs(consumeContext("auth"), consumeContext("apis"), ({auth, apis}) => {
                return (
                    <div className="account-suspended-dialog-a33">
                        <VerbDialogBodyScrollbar>
                            This account has been canceled. To use Verb services again, please sign up again.
                        </VerbDialogBodyScrollbar>

                        <div className="buttons">
                            <Button
                                btnType="secondary"
                                onClick={() => {
                                    modal.resolve();
                                    auth.logout();
                                    apis.user.signOut();
                                }}
                            >
                                Logout
                            </Button>

                            {/*<Button*/}
                            {/*    onClick={() => {*/}
                            {/*        modal.resolve();*/}
                            {/*        apis.user.signOut();*/}
                            {/*        window.location.replace("https://www.revivemedia.us/");*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    Sign Up Again*/}
                            {/*</Button>*/}
                        </div>
                    </div>
                );
            })
    );
