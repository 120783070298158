import {consumeContext} from "@common/react/context";
import {cs} from "@common/react/chain-services";
import {authRoutes} from "../auth/auth-routes";
import {AccessDeniedPage} from "./access-denied-page";

export const ProtectedRoute = ({children}) =>
    cs(consumeContext("auth"), consumeContext("routing"), ({auth, routing}) => {
        const isAllowed = isAuthorized({
            roles: auth.user.roles,
            routeName: routing.routeName,
            routeParams: routing.params,
        });

        if (isAllowed) {
            return children;
        }

        return <AccessDeniedPage />;
    });

const hasRole = ({roles, requireAnyOfRoles}) => requireAnyOfRoles.some(r => roles.includes(r));

export const isAuthorized = ({roles, routeName, routeParams}) => {
    // check for single route access role first
    const singleRouteAccessRole = false;//checkSingleRouteAccessRole(roles);
    if (singleRouteAccessRole && singleRouteAccessRole.routeName !== routeName) {
        return false;
    }

    const routeAuthorization = authRoutes.find((r) => r.name === routeName).authorization;

    if (!routeAuthorization) {
        return true;
    }

    const isPassed = (ra) => {
        // 1. if having the required role then passed
        if (hasRole({roles, requireAnyOfRoles: ra.requireAnyOfRoles})) {
            return true;
        }

        // 2. if not having the required role, continue to check

        // 2.1. if there is no `forQuery`, then the role is required for whole route, hence not passed
        if (!ra.forQuery) {
            return false;
        }

        // 2.2. if there is `forQuery`, then the role is required only for those query values
        // that means, for each route param, the condition to pass is that its value is not contained in `forQuery[param]` list
        const areRouteParamsPassed = (() => {
            if (!routeParams) {
                return true;
            }
            // all route params has to pass
            for (const k of Object.keys(routeParams)) {
                if (!ra.forQuery[k]) {
                    // if a route param is not in forQuery then move on to next param
                } else if (ra.forQuery[k].includes(routeParams[k])) {
                    // if a route param value is contained in forQuery[k] list then that route param is not passed
                    return false;
                }
            }
            return true;
        })();

        return areRouteParamsPassed;
    };

    return routeAuthorization.findIndex(isPassed) > -1;
};

export const getUnauthorizedParamValues = ({roles, routeName, param}) => {
    const routeAuthorization = authRoutes.find((r) => r.name === routeName).authorization;
    let ret = [];

    const collectUnauthorizedValues = (ra) => {
        // 1. if not having `forQuery` for the param, then there's nothing to collect
        if (!ra.forQuery?.[param]) {
            return;
        }

        // 2. if there is `forQuery`

        // 2.1. if having role, then there's nothing to collect
        if (hasRole({roles, requireAnyOfRoles: ra.requireAnyOfRoles})) {
            return;
        }

        // 2.2. if not having role, then the unauthorized values are the values of forQuery[param]
        ret.push(...ra.forQuery[param]);
    };

    for (const ra of routeAuthorization) {
        collectUnauthorizedValues(ra);
    }

    return ret;
};

// roles that are only allowed to access a specific route
const singleRouteAccessRoles = [
    // AB#6512: "ReportBuilder" is the user role that only allows the user to access report builder
    {
        role: "ReportBuilder",
        routeName: "report-builder",
        routePath: "/report-builder",
    },
];

export const checkSingleRouteAccessRole = (userRoles) => {
    return singleRouteAccessRoles.find((r) => userRoles.includes(r.role));
};
