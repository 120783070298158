import "./suggest-relationship-line.scss";
import {ModelPanelHelper} from "../../../../common/model-panel-helper";
import {lighten} from "@common/utils/color-util";
import React from "react";
import {RELATIONSHIP_INNER_JOIN, RELATIONSHIP_LEFT_JOIN} from "../../../main/model-constrants";
import {InnerJoinIcon, OuterJoinChildCanvas} from "../create-relationship/icons";
import {spc} from "@common/react/state-path-change";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {OuterJoinIcons} from "../../../main/relationships/outer-join-icons";
import {AnyAction} from "@common/react/any-action";
import {createElement as h} from "react";

export const SuggestRelationshipLine = ({suggestRelationship, model, tables, dataSources, onUpdate}) =>
    cs(["saving", (_, next) => UseState({next, initValue: false})], ({saving}) => {
        const table = tables.find((table) => table.columns.find((c) => c.id == suggestRelationship.leftColumnID));
        const target = tables.find((table) => table.columns.find((c) => c.id == suggestRelationship.rightColumnID));

        const sourceColor = ModelPanelHelper.getTableColor({
            table,
            dataSources,
        });
        const targetColor = ModelPanelHelper.getTableColor({
            table: target,
            dataSources,
        });

        const relationshipSynced = ModelPanelHelper.isHasRelationship({
            relationship: suggestRelationship,
            relationships: model.value.relationships,
        });

        const relationshipsBetweenTables = ModelPanelHelper.getRelationshipsBetweenTables({
            table,
            target,
            relationships: model.value.relationships,
        });

        const addRelationship = (relationship) => {
            saving.onChange(true);
            onUpdate(relationship);
            spc(model, ["relationships"], (relationships) => [
                ...(relationships || []),
                {
                    leftColumnID: relationship.leftColumnID,
                    rightColumnID: relationship.rightColumnID,
                    joinType: relationship.joinType,
                },
            ]);
        };

        const options = [
            {
                label: "Inner Join",
                icon: <InnerJoinIcon />,
                action: () => {
                    addRelationship({
                        leftColumnID: suggestRelationship.leftColumnID,
                        rightColumnID: suggestRelationship.rightColumnID,
                        joinType: RELATIONSHIP_INNER_JOIN,
                    });
                },
                show: relationshipsBetweenTables.length == 0 || relationshipsBetweenTables[0]?.joinType == RELATIONSHIP_INNER_JOIN,
            },
            {
                label: (
                    <>
                        Outer Join
                        <div className="tables">
                            {table.visualName} - {target.visualName}
                        </div>
                    </>
                ),
                icon: <OuterJoinIcons />,
                show:
                    relationshipsBetweenTables.length == 0 ||
                    (relationshipsBetweenTables[0]?.joinType == RELATIONSHIP_LEFT_JOIN &&
                        table.columns.find((c) => c.id == relationshipsBetweenTables[0]?.leftColumnID)),
                action: () => {
                    addRelationship({
                        leftColumnID: suggestRelationship.leftColumnID,
                        rightColumnID: suggestRelationship.rightColumnID,
                        joinType: RELATIONSHIP_LEFT_JOIN,
                    });
                },
            },
            {
                label: (
                    <>
                        Outer Join
                        <div className="tables">
                            {target.visualName} - {table.visualName}
                        </div>
                    </>
                ),
                icon: <OuterJoinIcons />,
                show:
                    relationshipsBetweenTables.length == 0 ||
                    (relationshipsBetweenTables[0]?.joinType == RELATIONSHIP_LEFT_JOIN &&
                        target.columns.find((c) => c.id == relationshipsBetweenTables[0]?.leftColumnID)),
                action: () => {
                    addRelationship({
                        leftColumnID: suggestRelationship.rightColumnID,
                        rightColumnID: suggestRelationship.leftColumnID,
                        joinType: RELATIONSHIP_LEFT_JOIN,
                    });
                },
            },
        ];

        return (
            <div className="suggest-relationship-line">
                <div
                    className="table-col left-side"
                    style={{
                        background: lighten(sourceColor, 70),
                        borderColor: sourceColor,
                    }}
                >
                    <img
                        src={require(`./${table.$type == "ViewModelTable" ? "data-view-icon.svg" : "table-icon.svg"}`)}
                        className="table-icon"
                    />

                    <div className="table-info">
                        <b className="table-name">{table.visualName}</b>
                        <div className="column-name">{table.columns.find((c) => c.id == suggestRelationship.leftColumnID).name}</div>
                    </div>

                    <div className="left-join-icon" />
                </div>

                <div className="relationship-line" />

                {!relationshipSynced &&
                    cs(
                        ["open", (_, next) => UseState({next, initValue: false})],
                        ({open}, next) =>
                            h(AnyAction, {
                                disabled: !open.value,
                                fn: () => {
                                    open.onChange(false);
                                },
                                next,
                            }),
                        ({open}) => (
                            <button
                                className="btn-add"
                                disabled={saving.value}
                                style={{
                                    zIndex: open.value ? 4 : "",
                                }}
                                onClick={() => {
                                    open.onChange(true);

                                    // addRelationship(suggest);
                                    // suggestState.change((suggests) =>
                                    //   suggests.map((s, i) =>
                                    //     i == index
                                    //       ? {
                                    //           ...s,
                                    //           added: true,
                                    //       }
                                    //       : s
                                    //   )
                                    // );
                                }}
                            >
                                {open.value && (
                                    <div className="add-popup-options">
                                        {options
                                            .filter((o) => o.show)
                                            .map((option, index) => (
                                                <div
                                                    className="option"
                                                    key={index}
                                                    onClick={() => {
                                                        open.onChange(false);
                                                        option.action();
                                                    }}
                                                >
                                                    {option.icon}
                                                    <div className="text">{option.label}</div>
                                                </div>
                                            ))}
                                    </div>
                                )}

                                {saving.value ? <i className="fa fa-spinner fa-pulse" /> : <img src={require("./plus-icon.svg")} alt="" />}
                            </button>
                        )
                    )}

                <div
                    className="table-col right-side"
                    style={{
                        background: lighten(targetColor, 70),
                        borderColor: targetColor,
                    }}
                >
                    <img
                        src={require(`./${target.$type == "ViewModelTable" ? "data-view-icon.svg" : "table-icon.svg"}`)}
                        className="table-icon"
                    />

                    <div className="table-info">
                        <b className="table-name">{target.visualName}</b>
                        <div className="column-name">{target.columns.find((c) => c.id == suggestRelationship.rightColumnID).name}</div>
                    </div>

                    {relationshipSynced?.joinType == RELATIONSHIP_LEFT_JOIN ? (
                        <OuterJoinChildCanvas className="outer-join-icon" />
                    ) : (
                        <div className="circle" />
                    )}
                </div>
            </div>
        );
    });
