import React from "react";
import {cx} from "emotion";
import {sum} from "@common/utils/collections";

export const SampleRequestObject = ({filters, authParams}) => {
    let list = [
        {
            type: "DateFilter",
            component: () => SampleDateFilter,
            totalLines: () => 8,
        },
        {
            type: "TextFilter",
            component: (filter) => (filter.options[0] == "FreeFormText" ? SampleTextFilter : SampleTextSelectFilter),
            totalLines: (filter) => (filter.options[0] == "FreeFormText" ? 6 : 8),
        },
        {
            type: "NumericFilter",
            component: () => SampleNumericFilter,
            totalLines: () => 6,
        },
        {
            type: "BooleanFilter",
            component: () => SampleBooleanFilter,
            totalLines: () => 4,
        },
    ];

    const hasAuthParams = authParams && authParams.length > 0;

    const totalLines = sum(filters, (filter) => list.find((l) => l.type == filter.type).totalLines(filter)) + (hasAuthParams ? authParams.length + 2 : 0) + 3;

    let AuthParamView = hasAuthParams ? SampleAuthParam : null;

    return (
        <div className="code" style={{minHeight: "72px", maxHeight: "30vh"}}>
            <div className="code-scroll">
                <div className="code-lines">
                    <div>1</div>
                    {Array.from(Array(totalLines).keys()).map((_, index) => (
                        <div key={index}>{index + 2}</div>
                    ))}
                </div>

                <pre className="code-pre language-json">
                    <code className="language-json">
                        <span className="token punctuation">{"{"}</span>
                        <div style={{paddingLeft: 20}}>
                            <span className="token json-key">"filterValues"</span>
                            <span className="token punctuation">: </span>
                            <span className="token punctuation">{"{"}</span>
                        </div>
                        {AuthParamView && <AuthParamView isLastChild={filters.length == 0} authParams={authParams} />}
                        {filters.map((filter, index) => {
                            let View = list.find((l) => l.type == filter.type).component(filter);
                            return <View key={index} id={filter.id} isLastChild={index == filters.length - 1} />;
                        })}
                        <div style={{paddingLeft: 20}}><span className="token punctuation">{"}"}</span></div>
                        <span className="token punctuation">{"}"}</span>
                    </code>
                </pre>
            </div>
        </div>
    );
};

const SampleDateFilter = ({id, isLastChild}) => {
    const endDate = new Date();
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 30);

    return (
        <>
            <div style={{paddingLeft: 40}}>
                <span className="token json-key">"{id}"</span>
                <span className="token punctuation">: </span>
                <span className="token punctuation">{"{"}</span>
            </div>
            <div style={{paddingLeft: 60}}>
                <span className="token json-key">"$type"</span>
                <span className="token punctuation">: </span>
                <span className="token json-string">"DateFilterValue"</span>
                <span className="token punctuation">,</span>
            </div>
            <div style={{paddingLeft: 60}}>
                <span className="token json-key">"range"</span>
                <span className="token punctuation">: </span>
                <span className="token punctuation">{"{"}</span>
            </div>
            <div style={{paddingLeft: 80}}>
                <span className="token json-key">"$type"</span>
                <span className="token punctuation">: </span>
                <span className="token json-string">"InRangeCustomDateFilterRange"</span>
                <span className="token punctuation">,</span>
            </div>
            <div style={{paddingLeft: 80}}>
                <span className="token json-key">"dateStart"</span>
                <span className="token punctuation">: </span>
                <span className="token json-date">"{startDate.toISOString()}"</span>
                <span className="token punctuation">,</span>
            </div>
            <div style={{paddingLeft: 80}}>
                <span className="token json-key">"dateEnd"</span>
                <span className="token punctuation">: </span>
                <span className="token json-date">"{endDate.toISOString()}"</span>
            </div>
            <div style={{paddingLeft: 60}}>
                <span className="token punctuation">{"}"}</span>
            </div>
            <div style={{paddingLeft: 40}}>
                <span className="token punctuation">{"}"}</span>
                {!isLastChild && <span className="token punctuation">,</span>}
            </div>
        </>
    );
};

const SampleTextSelectFilter = ({id, isLastChild}) => (
    <>
        <div style={{paddingLeft: 40}}>
            <span className="token json-key">"{id}"</span>
            <span className="token punctuation">: </span>
            <span className="token punctuation">{"{"}</span>
        </div>
        <div style={{paddingLeft: 60}}>
            <span className="token json-key">"$type"</span>
            <span className="token punctuation">: </span>
            <span className="token json-string">"TextFilterValue"</span>
            <span className="token punctuation">,</span>
        </div>
        <div style={{paddingLeft: 60}}>
            <span className="token json-key">"option"</span>
            <span className="token punctuation">: </span>
            <span className="token json-string">"SelectableList"</span>
            <span className="token punctuation">,</span>
        </div>
        <div style={{paddingLeft: 60}}>
            <span className="token json-key">"selectableListValues"</span>
            <span className="token punctuation">: </span>
            <span className="token punctuation">[</span>
        </div>
        <div style={{paddingLeft: 80}}>
            <span className="token json-string">"ABC"</span>
            <span className="token punctuation">,</span>
        </div>
        <div style={{paddingLeft: 80}}>
            <span className="token json-string">"DEF"</span>
        </div>
        <div style={{paddingLeft: 60}}>
            <span className="token punctuation">]</span>
        </div>

        <div style={{paddingLeft: 40}}>
            <span className="token punctuation">{"}"}</span>
            {!isLastChild && <span className="token punctuation">,</span>}
        </div>
    </>
);

const SampleTextFilter = ({id, isLastChild}) => (
    <>
        <div style={{paddingLeft: 40}}>
            <span className="token json-key">"{id}"</span>
            <span className="token punctuation">: </span>
            <span className="token punctuation">{"{"}</span>
        </div>
        <div style={{paddingLeft: 60}}>
            <span className="token json-key">"$type"</span>
            <span className="token punctuation">: </span>
            <span className="token json-string">"TextFilterValue"</span>
            <span className="token punctuation">,</span>
        </div>
        <div style={{paddingLeft: 60}}>
            <span className="token json-key">"option"</span>
            <span className="token punctuation">: </span>
            <span className="token json-string">"FreeFormText"</span>
            <span className="token punctuation">,</span>
        </div>
        <div style={{paddingLeft: 60}}>
            <span className="token json-key">"operation"</span>
            <span className="token punctuation">: </span>
            <span className="token json-string">"Equals"</span>
            <span className="token punctuation">,</span>
        </div>
        <div style={{paddingLeft: 60}}>
            <span className="token json-key">"freeFormValue"</span>
            <span className="token punctuation">: </span>
            <span className="token json-string">"abcdefg"</span>
        </div>

        <div style={{paddingLeft: 40}}>
            <span className="token punctuation">{"}"}</span>
            {!isLastChild && <span className="token punctuation">,</span>}
        </div>
    </>
);

const SampleNumericFilter = ({id, isLastChild}) => (
    <>
        <div style={{paddingLeft: 40}}>
            <span className="token json-key">"{id}"</span>
            <span className="token punctuation">: </span>
            <span className="token punctuation">{"{"}</span>
        </div>
        <div style={{paddingLeft: 60}}>
            <span className="token json-key">"$type"</span>
            <span className="token punctuation">: </span>
            <span className="token json-string">"NumericFilterValue"</span>
            <span className="token punctuation">,</span>
        </div>
        <div style={{paddingLeft: 60}}>
            <span className="token json-key">"option"</span>
            <span className="token punctuation">: </span>
            <span className="token json-string">"Range"</span>
            <span className="token punctuation">,</span>
        </div>
        <div style={{paddingLeft: 60}}>
            <span className="token json-key">"valueOrMin"</span>
            <span className="token punctuation">: </span>
            <span className="token number">5</span>
            <span className="token punctuation">,</span>
        </div>
        <div style={{paddingLeft: 60}}>
            <span className="token json-key">"max"</span>
            <span className="token punctuation">: </span>
            <span className="token number">15</span>
        </div>

        <div style={{paddingLeft: 40}}>
            <span className="token punctuation">{"}"}</span>
            {!isLastChild && <span className="token punctuation">,</span>}
        </div>
    </>
);

const SampleBooleanFilter = ({id, isLastChild}) => (
    <>
        <div style={{paddingLeft: 40}}>
            <span className="token json-key">"{id}"</span>
            <span className="token punctuation">: </span>
            <span className="token punctuation">{"{"}</span>
        </div>
        <div style={{paddingLeft: 60}}>
            <span className="token json-key">"$type"</span>
            <span className="token punctuation">: </span>
            <span className="token json-string">"BooleanFilterValue"</span>
            <span className="token punctuation">,</span>
        </div>
        <div style={{paddingLeft: 60}}>
            <span className="token json-key">"value"</span>
            <span className="token punctuation">: </span>
            <span className="token boolean">true</span>
        </div>

        <div style={{paddingLeft: 40}}>
            <span className="token punctuation">{"}"}</span>
            {!isLastChild && <span className="token punctuation">,</span>}
        </div>
    </>
);

const SampleAuthParam = ({isLastChild, authParams}) => (
    <>
        <div style={{paddingLeft: 40}}>
            <span className="token json-key">authParams</span>
            <span className="token punctuation">: </span>
            <span className="token punctuation">{"{"}</span>
        </div>
        {authParams.map((a, i) => (
            <div style={{paddingLeft: 60}}>
                <span className="token json-key">"{a}"</span>
                <span className="token punctuation">: </span>
                <span className="token json-string">"some value"</span>
                {i != authParams.length - 1 && <span className="token punctuation">,</span>}
            </div>
        ))}
        <div style={{paddingLeft: 40}}>
            <span className="token punctuation">{"}"}</span>
            {!isLastChild && <span className="token punctuation">,</span>}
        </div>
    </>
);
