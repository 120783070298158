import {sort, unique} from "../../../../utils/collections";
import {headerDelim} from "@common/ui-components/charts/pivot-table/common/pivot-table-column-value";

const generateFlattenID = (headers, deep) => {
    let arr = [];
    for (let i = 0; i <= deep; i++) {
        arr.push(headers[i].groupIndex);
    }

    return arr.length > 0 ? arr.join(headerDelim) : null;
};

const generateParentGroupIndex = (headers, deep) => {
    let arr = [];
    for (let i = 0; i <= deep; i++) {
        arr.push(`${headers[i].groupIndex}`);
    }

    return arr.length > 0 ? arr.join(headerDelim) : null;
};

const mapHeader = (cols, noTotal) => {
    let deep = 0;
    let headers = [];

    while (true) {
        let isFinished = true;

        for (let col of cols) {
            if (col.headers[deep]) isFinished = false;
        }

        if (isFinished) break;

        let mappedColumns = cols
            .map((col) => {
                if (col.headers[deep]) {
                    return {
                        ...col.headers[deep],
                        flattenID: generateFlattenID(col.headers, deep),
                        parentID: generateParentGroupIndex(col.headers, deep - 1),
                        id: generateParentGroupIndex(col.headers, deep),
                        parentName: col.headers[deep - 1]?.name,
                        deep,
                        maxDeep: col.headers.length,
                    };
                }

                return null;
            })
            .filter((v) => v);

        let groups = [];

        if (deep == 0) {
            for (let column of mappedColumns) {
                let found = groups.find((g) => g.groupID == column.parentID);
                if (found) {
                    found.items.push(column);
                } else {
                    groups.push({
                        groupID: column.parentID,
                        parentID: column.parentID,
                        items: [column],
                    });
                }
            }

            groups = groups.map((g) => ({
                ...g,
                items: sort(
                    unique(g.items, (item) => item.groupIndex),
                    (item) => item.groupIndex
                ),
            }));
        } else {
            for (let parentGroup of headers[deep - 1]) {
                let items = parentGroup.items;
                for (let item of items) {
                    let group = {
                        groupID: item.id,
                        parentID: item.id,
                        items: unique(
                            sort(
                                mappedColumns.filter((column) => column.parentID == item.id),
                                (c) => c.groupIndex
                            ),
                            (g) => g.name
                        ),
                    };

                    groups.push(group);
                }
            }

            groups = groups.filter((g) => g.items.length > 0);
        }

        headers.push(groups);

        deep++;
    }

    if (!noTotal) {
        for (let i = 0; i < headers.length; i++) {
            let header = headers[i];
            if (i > 0) {
                for (let group of header) {
                    if (group.items.length > 1) {
                        group.items.push({
                            name: `${group.items[0].parentName || group.items[0].name} Total`,
                            id: `total-${group.parentID}`,
                            flattenID: group.parentID,
                            deep: i,
                            totalColumn: true,
                        });
                    }
                }
            }
        }
    }

    return headers;
};

export const headerMapping = {
    mapHeader: mapHeader,
};
