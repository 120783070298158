const {isEmpty} = require("../utils/objects");
const createEventEmitter = () => {
    const _events = {};
    const on = (event, listener) => {
        if (isEmpty(_events[event])) {
            _events[event] = [];
        }
        _events[event].push(listener);
        return () => removeListener(event, listener);
    };
    const removeListener = (event, listener) => {
        if (isEmpty(_events[event])) {
            return;
        }
        let i = _events[event].indexOf(listener);
        _events[event].splice(i, 1);
    };
    return {
        on,
        events: () => _events,
        emit: (event, ...args) => {
            if (isEmpty(_events[event])) {
                return;
            }
            return Promise.all((_events[event] || []).map((listener) => Promise.resolve(listener(...args))));
        },
        removeListener,
        removeAllListeners: () => {
            for (let key in _events) {
                delete _events[key];
            }
        },
        once: (event, listener) => {
            on(event, function handler(...args) {
                removeListener(event, handler);
                listener(args);
            });
        },
    };
};

exports.createEventEmitter = createEventEmitter;
