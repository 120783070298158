import "./column-line-actions.scss";
import React from "react";
import {cs} from "@common/react/chain-services";
import {TransformationPublishInfo2} from "../../transformation-publish-info2";
import {tooltipService3} from "../../../../../../common/tooltip3/tooltip-service-3";
import {isEmpty} from "@common/utils/objects";
import {consumeContext} from "@common/react/context";

export const ColumnLineActions = ({next, type, transformationId, versionDetails, publishedOn, column, hasRelationship, table, modelColumnID, modelTable}) => {
    const isTransformed = ["CalculatedModelColumn", "AggregatedMeasureModelColumn"].includes(type);

    return cs(consumeContext("interactions"), consumeContext("routing"), tooltipService3({direction: "below"}), ({routing, interactions, tooltip}) => {
        const actions = [
            //     {
            //     render: () => transformationIcon.renderIndicator(),
            //     hidden: () => !isCalculated,
            //     onClick: () => {}
            // },
            {
                render: () => (
                    <img
                        {...{
                            ...tooltip("Column-Level Security Configured"),
                        }}
                        src={require("./row-level-security-icon.svg")}
                        alt=""
                    />
                ),
                hidden: () => isEmpty(column.columnLevelSecurity?.Endpoint),
                onClick: () => {
                    const getActiveTab = () => {
                        const tabs = ["Blank", "Hide", "Mask", "Error"];
                        for (let tab of tabs) {
                            if (column.columnLevelSecurity["Endpoint"] && !!column.columnLevelSecurity["Endpoint"][tab]) return tab;
                        }
                    };

                    interactions.openColumnSecurity({
                        tableId: modelTable?.id || table.id,
                        columnId: modelColumnID || column.id,
                        tab: getActiveTab(),
                    });
                },
            },
            {
                render: () => (
                    <img
                        {...{
                            ...tooltip("Column used in relationship path"),
                        }}
                        src={require("../../../../../../common/icons/relationship-icon.svg")}
                        alt=""
                    />
                ),
                hidden: () => !hasRelationship,
                onClick: () =>
                    interactions.selectColumn({
                        columnId: modelColumnID || column.id,
                    }),
            },
            {
                render: () => <img src={require("./edit-column-icon.svg")} alt="" />,
                hidden: () => !isTransformed || (!column?.transformationID && !column?.calculations?.[0]?.transformationID),
                onClick: () => {
                    routing.goto("edit-transformed-column", {
                        tab: "build",
                        modelTableId: modelTable.id,
                        transformationId: column.transformationID || column.calculations[0].transformationID,
                    });
                },
            },
        ];

        return next({
            render: () => (
                <div className="column-line-actions">
                    {actions
                        .filter((a) => !a.hidden())
                        .map((action, index) => (
                            <div className="action" key={index} onClick={() => action.onClick()}>
                                {action.render()}
                            </div>
                        ))}
                </div>
            ),
            width: 20 * actions.filter((a) => !a.hidden()).length + 10,
        });
    });
};
