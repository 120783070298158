import {Expandable} from "../../../../common/expandable/expandable";
import {NumberInput} from "../../../../../../../common/form/number-input/number-input";
import {ffToNumberInput} from "../../../../../../../common/form/ff-to-number-input";
import {BarCornerTypeSelect} from "../../../tile/edit/left-panel/tabs/tile-tab/chart-types/common/bar-corner-type-select";
import {ffToDropdown} from "../../../../../../../common/form/ff-to-dropdown";
import {AreaFillTypeSelect} from "../../../tile/edit/left-panel/tabs/tile-tab/chart-types/common/area-fill-type-select";
import * as React from "react";

export const ChartStyles = ({form, sectionInitClosed}) => (
    <Expandable
        {...{
            initExpand: !sectionInitClosed,
            label: "Chart Styles",
            render: () => (
                <>
                    <div className="panel-33">
                        <div className="header">Bar</div>

                        <div className="content">
                            {NumberInput({
                                label: "Bar Corner Radius",
                                ...ffToNumberInput(form.field(["barCornerRadius"])),
                                unit: "pixels",
                                showZero: true,
                            })}

                            {BarCornerTypeSelect(ffToDropdown(form.field(["barCornerRadiusLocation"])))}
                        </div>
                    </div>

                    <div className="panel-33">
                        <div className="header">Area Lines</div>

                        <div className="content">{AreaFillTypeSelect(ffToDropdown(form.field(["areaLineChartFill"])))}</div>
                    </div>
                </>
            ),
        }}
    />
);
