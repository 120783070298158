import {cs} from "@common/react/chain-services";
import DataSourceErrorIcon from "assets/icons/data-sources/DataSourceErrorIcon";
import {cx} from "emotion";
import SpinnerIcon from "../../../../../../assets/icons/data-sources/SpinnerIcon";
import "./connection-settings-layout.scss";
import {ErrorConnectionDialog} from "../../../dialogs/error-connection-dialog/error-connection-dialog";
import {structuredDSTypes} from "../../../../common/data-source-type";
import {tooltipService3} from "../../../../../common/tooltip3/tooltip-service-3";
import {Button} from "../../../../../../../../common/form/buttons/button/button";
import {GhostButton} from "../../../../../../../../common/form/buttons/ghost-button/ghost-button";
import React from "react";

export const ConnectionSettingsLayout = ({
    type,
    dataSourceError,
    content,
    className,
    connectionStatus: {loading, success, error},
    adding,
    controls,
}) =>
    cs(() => {
        return (
            <div
                className={cx("connection-settings-layout-99o connection-settings-layout", {
                    className,
                })}
            >
                <div className="col-left">
                    <div className="header">{adding && `2. `}Connection settings</div>

                    <div className="status">
                        {loading ? (
                            <div className={cx("box", {loading: loading})}>
                                {SpinnerIcon({})}
                                <div className="text">Testing connection...</div>
                            </div>
                        ) : success ? (
                            <div className={cx("box success")}>
                                <i className="fas fa-check-circle" />
                                <div className="text">Connection successful!</div>
                            </div>
                        ) : error ? (
                            <div className={cx("box error")}>
                                {DataSourceErrorIcon({})}
                                <div className="info">
                                    <div className="text">{error.message}</div>
                                </div>
                            </div>
                        ) : (
                            (dataSourceError || []).length > 0 &&
                            cs(["errorDialog", (_, next) => ErrorConnectionDialog({next})], ({errorDialog}) => (
                                <div className={cx("box error")}>
                                    {DataSourceErrorIcon({})}

                                    <div className="info">
                                        <div className="text">{dataSourceError[0].name}</div>
                                        <div
                                            className="full-error"
                                            onClick={() =>
                                                errorDialog.show({
                                                    title: "Data Source Full Error",
                                                    content: dataSourceError[0].message,
                                                })
                                            }
                                        >
                                            View Full Error
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>

                    <div className={cx("content", {loading: loading})}>
                        {loading && <div className="overlay" />}

                        {content}

                        <div className="buttons">
                            {cs(
                                tooltipService3({
                                    direction: "above",
                                    tooltipContentStyle: {width: 200},
                                }),
                                ({tooltip}) => (
                                    <Button
                                        btnType={success ? "secondary" : "primary"}
                                        size="small"
                                        {...(!controls.valid
                                            ? tooltip(() => `All required fields must be filled before testing the connection`)
                                            : {})}
                                        className={cx({
                                            disabled: !controls.valid,
                                        })}
                                        onClick={() => controls.valid && controls.onTestConnection()}
                                    >
                                        Test Connection
                                    </Button>
                                )
                            )}

                            <Button btnType="danger" size="small" onClick={() => controls.onDiscard()}>
                                Discard Database
                            </Button>

                            {success && controls.onGoNext && (
                                <GhostButton size="small" onClick={() => controls.onGoNext?.()}>
                                    Next: Configure Sync Settings
                                </GhostButton>
                            )}
                        </div>
                    </div>
                </div>

                <div className="col-right">
                    <div className="label">Getting Connected</div>

                    <div className="content">
                        <p>
                            {structuredDSTypes.includes(type)
                                ? `Provide the details and credentials for the data source that you are connecting. Verb will query the schema and give you the option to include/exclude data like tables, columns and rows. All of the fields are required unless explicitly noted.`
                                : `Provide the details and credentials for the data source that you are connecting. Verb will query the schema if provided by the database or take a sample of data to generate a schema of objects, nested objects, and properties.  All of the fields are required unless explicitly noted.`}
                        </p>
                    </div>
                </div>
            </div>
        );
    });
