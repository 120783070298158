import {LiveDashboard} from "@common/ui-components/live/live-dashboard/live-dashboard";
import collection from "./map-chart-collection.json";
import data from "./map-chart-data.json";
import {cs} from "@common/react/chain-services";
import {Load} from "@common/react/load";
import {fetchMapData} from "@common/ui-components/charts/map-tile/map-helper";

export const MapChartThemeAuto = (props) =>
    cs(
        [
            "mapData",
            (_, next) =>
                Load({
                    fetch: () => fetchMapData(data.mapGeoJson),
                    next,
                }),
        ],
        ({mapData}) => {
            return (
                mapData &&
                LiveDashboard({
                    collection,
                    defaultData: [{mapData, tileData: data, tileID: data.tileID}],
                    disabledTileActions: true,
                    ...props,
                })
            );
        }
    );
