import "./edit-filter.scss";
import {UseState} from "@common/react/use-state";
import * as React from "react";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {changePath} from "@common/utils/arr-path";
import {Form2} from "@common/react/cs-form/form2";
import {keyed} from "@common/react/keyed";
import {Invoke} from "@common/react/invoke";
import {scope} from "@common/react/scope";
import {FadeRight} from "../../../common/animation/fade-right/fade-right";
import {FilterEditMain} from "../../common/filters/filter-edit-main/filter-edit-main";
import {filterFormConfig} from "../../common/filters/filter-form-config/filter-form-config";
import {LeaveUnsavedChangesDialog} from "../../common/leave-unsaved-changes-dialog/leave-unsaved-changes-dialog";
import {ChangeQueue1} from "@common/react/change-queue-1";
import {Layout2} from "../../../common/layout2/layout-2";
import {LoadingIndicator} from "@common/ui-components/loading-indicator/loading-indicator";
import {EditFilterLeftPanel} from "./left-panel/edit-filter-left-panel";
import {createGetFieldInfo} from "../../common/field-info/create-get-field-info";
import {SingleEditorRegister} from "../../../common/single-editor/single-editor";
import {fragments} from "@common/react/fragments";

export const EditFilter = ({}) =>
    cs(
        consumeContext("apis"),
        consumeContext("routing"),
        consumeContext("collection"),
        ({routing, collection}, next) =>
            fragments(
                collection?.value
                    ? SingleEditorRegister({
                          goBack: () => routing.goto("dashboard", {tab: "collections"}),
                          getItemId: () => routing.params.colId,
                          otherButtons: [
                              {
                                  label: "Go to Preview",
                                  function: () => {
                                      window.open(`/#/env/${routing.params.envId}/collection/${routing.params.colId}/preview`, "_blank");
                                  },
                              },
                          ],
                          item: {
                              type: "collection",
                              parent: "Collections",
                              name: collection?.value.name,
                              id: collection?.value.id,
                          },
                      })
                    : null,
                next()
            ),
        ({}, next) => createGetFieldInfo({next}),
        [
            "savingQueue",
            ({collection, apis}, next) =>
                cs(({}) =>
                    ChangeQueue1({
                        fetchLatest: () => apis.collection.getCollection(collection.value.id),
                        save: apis.collection.upsertCollection,
                        next,
                    })
                ),
        ],
        ({collection, routing}, next) =>
            !collection?.value
                ? Layout2({
                      editing: {
                          label: "Edit Filter",
                      },
                      back: {
                          label: "Done editing filter",
                          onClick: () => routing.goto("edit-collection", {collectionTab: "filters"}),
                      },
                      main: LoadingIndicator({className: "loading-filter-df1"}),
                  })
                : next(),

        ["filterPath", ({routing}, next) => next(["filters", (filter) => filter.id === routing.params.filterId])],
        ["remoteFilter", ({collection, filterPath}, next) => next(scope(collection, filterPath))],
        [
            "localFilter",
            ({filterPath, remoteFilter, routing}, next) =>
                UseState({
                    initValue: {
                        value: remoteFilter.value,
                        lastUpdatedAt: null,
                    },
                    next: ({value, onChange}) =>
                        next(
                            scope({
                                value,
                                onChange: ({value}) =>
                                    onChange({
                                        value: {
                                            ...value,
                                            id: routing.params.filterId,
                                        },
                                        lastUpdatedAt: Date.now(),
                                    }),
                            })
                        ),
                }),
        ],

        [
            "form",
            ({localFilter}, next) =>
                Form2({
                    data: scope(localFilter, ["value"]),
                    ...filterFormConfig,
                    initShowErrors: true,
                    hideErrorMessage: true,
                    next,
                }),
        ],

        ({localFilter, filterPath, collection, remoteFilter, savingQueue, form}, next) => {
            return (
                <>
                    {next()}

                    {localFilter.value &&
                        localFilter.value.value !== remoteFilter.value &&
                        form.valid &&
                        cs(keyed(localFilter.value.lastUpdatedAt), () =>
                            Invoke({
                                fn: async () => {
                                    const _collections = await savingQueue.push((c) =>
                                        changePath(c, ["filters"], (oldFilters) =>
                                            oldFilters.map((f) => {
                                                if (localFilter.value.value.id !== f.id) {
                                                    if (f.$type === "DateFilter" && localFilter.value.value.$type === "DateFilter" && localFilter.value.value.compareEnabled) {
                                                        return {
                                                            ...f,
                                                            compareEnabled: false,
                                                        };
                                                    } else {
                                                        return f;
                                                    }
                                                } else {
                                                    return {
                                                        ...localFilter.value.value,
                                                        displayArea: f.displayArea,
                                                    };
                                                }
                                            })
                                        )
                                    );

                                    collection.onChange(_collections);
                                    remoteFilter.onChange(localFilter.value.value);
                                },
                            })
                        )}
                </>
            );
        },
        ["leaveDialog", (_, next) => LeaveUnsavedChangesDialog({next})],
        ({routing, localFilter, remoteFilter, form, savingQueue, leaveDialog, collection}) => {
            const syncState = savingQueue.executing ? "saving" : localFilter.value?.value === remoteFilter.value ? "saved" : "unsaved";
            return (
                <Layout2
                    {...{
                        className: "edit-filter-89h",
                        editing: {
                            label: localFilter.value.value.label,
                        },
                        back: {
                            onClick: async () => {
                                if (syncState === "unsaved") {
                                    const answer = await leaveDialog.show();

                                    if (answer === "discard") {
                                        routing.goto("edit-collection", {
                                            collectionTab: "filters",
                                        });
                                    } else if (answer === "continue") {
                                        form.showErrors();
                                    } else {
                                        // cancel
                                    }
                                } else {
                                    // Add to reload to prevent filter not update latest
                                    collection.reload();
                                    routing.goto("edit-collection", {
                                        collectionTab: "filters",
                                    });
                                }
                            },
                            label: "Done editing filter",
                        },
                        leftPanel: {
                            content: EditFilterLeftPanel({
                                filter: scope(localFilter, ["value"]),
                                form,
                            }),
                        },
                        main:
                            localFilter.value?.value &&
                            cs(
                                ({}, next) =>
                                    FadeRight({
                                        key: localFilter.value.value.$type,
                                        next,
                                    }),
                                () =>
                                    FilterEditMain({
                                        form,
                                        filter: scope(localFilter, ["value"]),
                                    })
                            ),
                        syncState: {
                            type: syncState,
                            onClick: syncState === "unsaved" && form.showErrors,
                        },
                    }}
                />
            );
        }
    );
