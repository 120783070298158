const {getReferenceSeriesStyleOptions} = require("./indicator-series");
const {lighten} = require("../../../utils/color-util");
const {getDataLabelsStyle} = require("../factory/data-labels");
const {getTextWidth} = require("../render-chart/render-chart-helper");

const aggregateValueTypes = ["Average", "Median", "Minimum", "Maximum", "Percentile"];
exports.aggregateValueTypes = aggregateValueTypes;

const aggregateTypes = {
    Average: (data) => jStat.mean(data),
    Median: (data) => jStat.median(data),
    Minimum: (data) => jStat.min(data),
    Maximum: (data) => jStat.max(data),
    // jStat.percentile uses linear interpolation between closest ranks, not nearest-rank method
    // add 3rd argument as true for exclusive
    Percentile: (data, percentile) => jStat.percentile(data, percentile / 100),
};
exports.aggregateTypes = aggregateTypes;

// mimic series carrying plot line options
const generateConstantOrAggregateValueSeries = ({tile, theme, series, config}) => {

    // the plot lines will be added to measure axes options for displaying
    const getPlotLinesOptions = ({series, value, name}) => {
        const seriesStyle = getReferenceSeriesStyleOptions({config, theme});
        const dataLabelStyle = getDataLabelsStyle({
            dataLabelsConfig: tile.style.dataLabels || tile.style.yAxisLineDataLabels,
            theme,
        }).style;

        const verticalMeasureAxisStyle = tile.style.yAxis?.[series.customStack] || tile.style.yAxisBar?.[series.customStack] || tile.style.yAxisLine?.[series.customStack];
        const isMeasureAxisOpposite = verticalMeasureAxisStyle?.location.includes("Right") || tile.style.xAxis[series.customStack]?.location.includes("Bottom");

        return {
            id: `${series.id}_${config.id}`,
            value,
            name,
            ...seriesStyle,
            ...(config.labelShown && {
                label: {
                    text: name,
                    style: {
                        ...dataLabelStyle,
                        color: seriesStyle.color,
                    },

                    ...(tile.$type === "HorizontalBarChartTile"
                        ? {
                              verticalAlign: isMeasureAxisOpposite ? "bottom" : "top",
                          }
                        : {
                              align: isMeasureAxisOpposite ? "right" : "left",
                          }),

                    ...(tile.$type === "HorizontalBarChartTile"
                        ? {
                              y: isMeasureAxisOpposite ? -1 * (getTextWidth(dataLabelStyle.fontSize, name) - 4) : 4,
                              x: 6,
                          }
                        : {
                              y: -7,
                              x: isMeasureAxisOpposite ? -4 : 4,
                          }),
                },
            }),
            zIndex: 4,
        };
    };

    if (config.$type === "ConstantValueReferenceLine") {
        const name = config.label || `Constant (${config.value}, ${series[0].measureAxisTitle})`;
        const plotLineOptions = getPlotLinesOptions({
            series: series[0],
            value: config.value,
            name,
        });

        return [
            {
                type: "line",
                customType: config.$type,
                customStack: series[0].customStack,
                name,
                plotLineOptions,
                color: plotLineOptions.color,
                dataLabelShown: config.dataLabelShown,
                marker: {
                    enabled: false,
                },
            },
        ];
    }

    return series.map((s) => {
        const data = s.data.map((d) => d[1]);
        const value = aggregateTypes[config.aggregation](data, config.value);
        const name = config.label || (config.value ? `${config.type} (${config.value}, ${s.name})` : `${config.type} (${s.name})`);
        const plotLineOptions = getPlotLinesOptions({series: s, value, name});

        return {
            type: "line",
            customType: config.$type,
            customStack: s.customStack,
            name,
            plotLineOptions,
            color: plotLineOptions.color,
            dataLabelShown: config.dataLabelShown,
            marker: {
                enabled: false,
            },
        };
    });
};
exports.generateConstantOrAggregateValueSeries = generateConstantOrAggregateValueSeries;
