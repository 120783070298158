const {sort} = require("../../../../utils/collections");

const BasicFormatter = (tokens) => {
    tokens = sort(tokens, (t) => -t.token.length);

    return (pattern) => {
        const parts = [];
        for (let i = 0; i < pattern.length; i++) {
            if (pattern[i] == "\\") {
                let spaceIndex = pattern.substring(i).indexOf(" ");
                if (spaceIndex == -1) {
                    let formatted = pattern.substring(i + 1);
                    i = pattern.length - 1;
                    parts.push(() => formatted);
                } else {
                    let formatted = pattern.substring(i + 1, i + 1 + spaceIndex);
                    i += formatted.length;
                    parts.push(() => formatted);
                }
            } else {
                const token = tokens.find((t) => pattern.startsWith(t.token, i));
                if (token) {
                    i += token.token.length - 1;
                    parts.push(token.format);
                } else {
                    parts.push(() => pattern[i]);
                }
            }
        }
        return {
            format: (obj) => parts.map((p) => p(obj)).join(""),
        };
    };
};
exports.BasicFormatter = BasicFormatter;
