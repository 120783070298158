import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {loginSso} from "../../apis/user-apis/login-sso";
import {WholePageLoading} from "@common/ui-components/whole-page-loading/whole-page-loading";
import {getUser} from "../../routes/guest-routes/login/login-route";
import {fetchUser} from "../../apis/user-apis/fetch-user";
import {waitTimeout} from "@common/utils/wait-timeout";
import {CookieStorage} from "@common/logic/cookie-storage";
export const loadAuthSso = ({next: rootNext}) =>
    cs(
        ["searchParams", (_, next) => next(new URLSearchParams(window.location.search))],
        [
            "state",
            ({searchParams}, next) =>
                UseState({
                    initValue: {
                        loading: searchParams.has("code"),
                    },
                    next,
                }),
        ],
        ({state, searchParams}) => {
            if (state.value.loading) {
                loginSso({
                    code: searchParams.get("code"),
                    redirectUrl: window.location.origin + "/sso/index.html",
                }).then(async (user) => {
                    if (user) {
                        CookieStorage.set("verb.admin.tenantid", user?.tenantID);
                        window.location.replace(window.location.origin);
                        await waitTimeout(1000);

                        state.onChange({loading: false});
                    }
                });
                return WholePageLoading({content: "Loading auth..."});
            }
            return rootNext();
        }
    );
