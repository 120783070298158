import React, {createElement as h} from "react";
import {sort, reverse} from "@common/utils/collections";
import {chain} from "@common/utils/fs";
import {UseState} from "@common/react/use-state";
import {cx} from "emotion";
import {InfoIcon} from "@common/ui-components/icons/global-icons";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";
import {cs} from "@common/react/chain-services";
import {tooltipService3} from "../../../../../common/tooltip3/tooltip-service-3";
import "./interaction-table.scss";

export const InteractionTable = ({
    list,
    sorting,
    initSorting,
    columns,
    rowKey,
    className,
    rowClassName,
    onUpdateList,
    hideHeader = false,
    style,
    maxHeight,
    onActionEntireRow,
    isFullRow = () => true,
    subColumns,
    footerColumns,
}) =>
    cs(["sorting", (_, next) => (sorting ? next(sorting) : h(UseState, {initValue: initSorting, next}))], tooltipService3({direction: "above"}), ({sorting, tooltip}) => {
        const sortedList = onUpdateList ? list : applySorting(list, {sorting: sorting.value, columns});

        return (
            <VerbScrollbar applyStyleOnParent style={style} maxHeight={maxHeight || `100%`} className="interaction-table-wrapper-88w interaction-table-wrapper">
                <table className={"interaction-table interaction-table-88w " + (className || "")}>
                    {!hideHeader && (
                        <thead>
                            <tr>
                                {columns?.map((column, i) => (
                                    <th
                                        key={i}
                                        className={cx(
                                            column?.headClassName,
                                            {
                                                "align-right": column.alignRight,
                                                "align-center": column.alignCenter,
                                            },
                                            (sorting.value?.columnIndex === i ? " sorting-active " : "") + (column.sortValue ? " sorting-enabled " : "")
                                        )}
                                        onClick={
                                            column.sortValue &&
                                            (() => {
                                                const sortValue =
                                                    sorting.value?.columnIndex !== i
                                                        ? {
                                                              columnIndex: i,
                                                              asc: false,
                                                          }
                                                        : {
                                                              columnIndex: i,
                                                              asc: !sorting.value.asc,
                                                          };
                                                sorting.onChange(sortValue);
                                                onUpdateList &&
                                                    onUpdateList(
                                                        applySorting(list, {
                                                            sorting: sortValue,
                                                            columns,
                                                        })
                                                    );
                                            })
                                        }
                                    >
                                        <div
                                            className={cx("th-info", {
                                                "has-sort": column.sortValue,
                                            })}
                                        >
                                            {column.labelF ? column.labelF() : column.label}
                                            {column.sortValue && (
                                                <>
                                                    &nbsp;
                                                    <div className="sort-value">
                                                        {sorting.value?.columnIndex !== i ? (
                                                            <>
                                                                <i className="far fa-angle-up"></i>
                                                                <i className="far fa-angle-down"></i>
                                                            </>
                                                        ) : sorting.value.asc ? (
                                                            <>
                                                                <i className="far fa-angle-up"></i>
                                                                <i />
                                                            </>
                                                        ) : (
                                                            <>
                                                                <i />
                                                                <i className="far fa-angle-down"></i>
                                                            </>
                                                            // <i class="fal fa-sort-down"></i>
                                                        )}
                                                    </div>
                                                </>
                                            )}

                                            {column.info && (
                                                <div
                                                    {...{
                                                        className: "info-icon",
                                                        ...tooltip(() => column.info),
                                                    }}
                                                >
                                                    <InfoIcon />
                                                </div>
                                            )}
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                    )}

                    <tbody>
                        {sortedList?.map((item, i) => (
                            <>
                                <tr
                                    className={cx(rowClassName ? rowClassName(item) : "")}
                                    key={rowKey ? rowKey(item) : i}
                                    {...(onActionEntireRow
                                        ? {
                                              onClick: (e) => {
                                                  e.preventDefault();
                                                  e.stopPropagation();
                                                  onActionEntireRow(item);
                                              },
                                          }
                                        : {})}
                                >
                                    {isFullRow(item)
                                        ? columns.map((column, j) => (
                                              <td
                                                  key={j}
                                                  style={column.style || {}}
                                                  className={cx(column.classNameFn?.(item), column.className, {
                                                      "align-right": column.alignRight,
                                                      "align-center": column.alignCenter,
                                                      shy: column.shy || column?.shyF?.(item),
                                                  })}
                                              >
                                                  {(column.format || column.sortValue)(item)}
                                              </td>
                                          ))
                                        : subColumns.map((subColumn, k) => (
                                              <td
                                                  key={k}
                                                  colSpan={k === subColumns.length - 1 ? columns.length - k : null}
                                                  style={subColumn.style || {}}
                                                  className={cx(subColumn.className, {
                                                      "align-right": subColumn.alignRight,
                                                      "align-center": subColumn.alignCenter,
                                                      shy: subColumn.shy || subColumn?.shyF?.(item),
                                                  })}
                                              >
                                                  {(subColumn.format || subColumn.sortValue)(item)}
                                              </td>
                                          ))}
                                </tr>
                            </>
                        ))}
                    </tbody>

                    {footerColumns && (
                        <tfoot>
                            <tr>
                                {footerColumns.map((footColumn, l) => (
                                    <td
                                        key={l}
                                        className={cx({
                                            "align-right": footColumn.alignRight,
                                        })}
                                    >
                                        {footColumn.format()}
                                    </td>
                                ))}
                            </tr>
                        </tfoot>
                    )}
                </table>
            </VerbScrollbar>
        );
    });

//Used only for data source columns
const applySorting = (list, {sorting, columns}) => {
    return sorting == null
        ? list
        : chain(
              [
                  ...sort(
                      list.filter((c) => !c.primaryKey),
                      (item) => columns[sorting.columnIndex].sortValue(item)
                  ),
              ],
              sorting.asc ? (list) => list : reverse,
              (list1) => [...list.filter((c) => c.primaryKey), ...list1]
          );
};
