const {getPath} = require("../../common/utils/arr-path");
const ffToDropdown = (field, dropdownValuePath, defaultValue) => {
    let value = defaultValue && typeof defaultValue == "function"
        ? defaultValue(field.state.value)
        : field.state.value;
    return {
        isSelected: (v) => getPath(v, dropdownValuePath) === value,
        onChange: (v) => field.state.onChange(getPath(v, dropdownValuePath)),
        value: value,
        hasError: field.error,
        domRef: field.domRef,
        errorMessage: field.getErrorMessage?.(),
    }
};

exports.ffToDropdown = ffToDropdown;
