import { generateDefaultTimeRange } from "@common/logic/date-time/date-util";
import { addDate, dateDiff, getDow, today, tomorrow, truncateMonth, yesterday } from "@common/utils/dates/date-object";
import { getPreviousDays } from "../../live-filters/filter-types/date/expand/common/time/get-previous-days";

export const xToDate = ({truncate, add, subtractFromTo = 0}) => {
    const getMainRange = () => ({
        from: truncate(today()),
        to: {
            ...(subtractFromTo == 1 ? yesterday() : today()),
            hours: 23,
            minutes: 59,
            seconds: 59,
        },
    });

    return {
        getMainRange,
        getComparingRange: () => {
            const range = getMainRange();
            return {
                from: add(range.from, -1),
                to: {
                    ...add(range.to, -1),
                    hours: 23,
                    minutes: 59,
                    seconds: 59,
                },
            };
        },
    };
};

const xSameRanges = ({from}) => {
    const getMainRange = () => ({
        from: from,
        to: {
            ...from,
            hours: 23,
            minutes: 59,
            seconds: 59,
        },
    });

    return {
        getMainRange,
        getComparingRange: () => {
            const main = getMainRange();
           
            return getPreviousDays(generateDefaultTimeRange(main));
        },
    };
};
export const xYesterday = xSameRanges({from: yesterday()});
export const xTomorrow = xSameRanges({from: tomorrow()});
export const xToday = xSameRanges({from: today()});

const xWeek = ({start, delta = 0}) => {
    const date1 = today();
    const dow = getDow(date1);

    const getMainRange = () => ({
        from: addDate(date1, -(dow - start - delta)),
        to: {
            ...addDate(date1, 6 + start - dow + delta),
            hours: 23,
            minutes: 59,
            seconds: 59,
        },
    });

    return {
        getMainRange,
        getComparingRange: () => {
            const main = getMainRange();
            return getPreviousDays(generateDefaultTimeRange(main));
        },
    };
};

export const xThisWeekSunSat = xWeek({start: 0});
export const xThisWeekMonSun = xWeek({start: 1});

export const xLastWeekSunSat = xWeek({start: 0, delta: -7});
export const xLastWeekMonSun = xWeek({start: 1, delta: -7});
