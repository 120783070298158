import * as React from "react";
import "./tile-title.scss";
import {scope} from "@common/react/scope";
import {ffToTextInput} from "../../../../../../../../../../../../../common/form/ff-to-text-input";
import {ffToDropdown} from "../../../../../../../../../../../../../common/form/ff-to-dropdown";
import {ShowHideToggle} from "../../../../../../../../../../../../../common/form/toggles/show-hide-toggle";
import {TextInput} from "../../../../../../../../../../../../../common/form/text-input/text-input";
import {getPath} from "@common/utils/arr-path";
import {ValueChipInput} from "../../../../../fields-tab/actions/add/common/value-chip-input";
import {isChartTitleHidden} from "@common/ui-components/charts/chart-layout/chart-title-display-rule";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {stateToSelect} from "@common/ui-components/form/state-to-select";
import {IconPicker} from "../../../common/icon-picker/icon-picker";
import {FieldInfoProvider} from "../../../common/field-info-provider/field-info-provider";
import {TextareaInput} from "../../../common/textarea-input/textarea-input";
import {HiddenFieldAlert} from "../../../common/hidden-field-alert/hidden-field-alert";
import {spc} from "@common/react/state-path-change";
import {TITLE_ALIGNMENT} from "../../../common/tile-title/tile-title";

const getSectionTitle = (type) => {
    if (type === "SectionTile") return "Section Title";
    return `${type === "ListKPITile" ? "Chart" : "Tile"} Title`;
};

export const TileTitle = ({tile, size, parentTile, tileAction, form, isContainerTile, kpiColorsTileConfig}) => ({
    label: getSectionTitle(tile.value.$type),
    control: !isContainerTile && tile.value.$type !== "SectionTile" ? ShowHideToggle({state: scope(tile, ["style", "title", "show"])}) : null,
    render: () => {
        const isTileTitleShown = getPath(tile.value, ["style", "title", "show"]) && !isChartTitleHidden({tile: tile.value, size});
        const iconOpacityField = form.field(["style", "titleIconBackgroundOpacity"]);

        const titleIconUrl = {
            value: JSON.parse(tile.value.style.titleIconUrl || "{}"),
            onChange: (value) => spc(tile, ["style", "titleIconUrl"], () => JSON.stringify(value)),
        };

        return (
            <div className="tile-title-8as">
                <div className="subsection">
                    <div className="content">
                        {parentTile
                            ? ValueChipInput({
                                  tile: parentTile,
                                  tileAction,
                                  label: "Title",
                                  beforeField: "tileTitleBefore",
                                  afterField: "tileTitleAfter",
                              })
                            : TextInput({
                                  label: "Title",
                                  ...ffToTextInput(form.field(["title"])),
                              })}

                        {scope(tile, ["style", "title", "show"]).value &&
                            DropdownSelect({
                                list: [
                                    {
                                        label: "Left Aligned",
                                        value: TITLE_ALIGNMENT.LEFT,
                                    },
                                    {
                                        label: "Center Aligned",
                                        value: TITLE_ALIGNMENT.CENTER,
                                    },
                                ],
                                label: "Title Alignment",
                                valueToLabel: (v) => v?.label ?? "",
                                ...ffToDropdown(form.field("style.title.titleAlign"), ["value"]),
                            })}

                        {!isContainerTile &&
                            DropdownSelect({
                                label: "Position",
                                list: ["Top", "Bottom"],
                                valueToLabel: (v) => v,
                                ...ffToDropdown(form.field(["style", "titlePosition"])),
                            })}
                    </div>
                </div>

                <div className="subsection">
                    <div className="header">
                        Title Icon
                        <div className="control">
                            {ShowHideToggle({
                                state: scope(tile, ["style", "showTitleIcon"]),
                            })}
                        </div>
                    </div>

                    {tile.value.style.showTitleIcon && (
                        <div className="content">
                            {DropdownSelect({
                                label: "Icon Options",
                                list: [
                                    {
                                        label: "Use FontAwesome",
                                        isSelected: () => titleIconUrl.value.icon !== undefined,
                                        onChange: () => titleIconUrl.onChange({icon: ""}),
                                    },
                                    {
                                        label: "Customize via URL",
                                        isSelected: () => titleIconUrl.value.url !== undefined,
                                        onChange: () => titleIconUrl.onChange({url: ""}),
                                    },
                                ],
                                isSelected: (v) => v.isSelected(),
                                onChange: (v) => v.onChange(),
                                valueToLabel: (v) => v.label,
                            })}

                            {titleIconUrl.value.icon !== undefined &&
                                IconPicker({
                                    label: "Select icon",
                                    value: titleIconUrl.value.icon,
                                    onChange: (icon) => titleIconUrl.onChange({icon}),
                                })}

                            {titleIconUrl.value.url !== undefined &&
                                FieldInfoProvider({
                                    className: "margin-bottom",
                                    info: "Custom Icons must be hosted on a secure site (https) and can only be in the format of; JPEG, PNG, GIF, SVG, WEBP files.",
                                    tooltipBoxWidth: 170,
                                    topOffset: 35,
                                    field: TextInput({
                                        label: "Icon URL",
                                        state: scope(titleIconUrl, ["url"]),
                                    }),
                                })}

                            {DropdownSelect({
                                label: "Background Opacity",
                                list: [0, 10, 25, 40, 100],
                                valueToLabel: (v) => `${v}%`,
                                ...ffToDropdown(iconOpacityField),
                                isSelected: (v) => v === parseInt(iconOpacityField.state.value),
                            })}

                            {kpiColorsTileConfig?.render(1)}
                        </div>
                    )}
                </div>

                {tile.value.title && (
                    <div className="subsection">
                        <div className="header">
                            Title Info Icon
                            <div className="control">
                                {isTileTitleShown
                                    ? ShowHideToggle({
                                          state: scope(tile, ["style", "showTitleInfoIcon"]),
                                      })
                                    : HiddenFieldAlert({
                                          info: "Unavailable when the Tile Title is hidden.",
                                      })}
                            </div>
                        </div>

                        {isTileTitleShown && tile.value?.style.showTitleInfoIcon && (
                            <div className="content">
                                {TextareaInput({
                                    state: scope(tile, ["style", "titleInfoIconText"]),
                                })}
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    },
});
