import * as React from "react";
import {ShowHideToggle} from "../../../../../../../../../../../../../common/form/toggles/show-hide-toggle";
import {scope} from "@common/react/scope";
import {cs} from "@common/react/chain-services";
import {DebounceNumber} from "../../../../../../../../../../../../../common/form/debounce-number";
import {NumberInput} from "../../../../../../../../../../../../../common/form/number-input/number-input";
import {LpColorLine} from "../../../../../../../../../../common/left-panel/lp-color-line";
import "./donut-hole.scss";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {createArray} from "@common/utils/collections";
import {ffToDropdown} from "../../../../../../../../../../../../../common/form/ff-to-dropdown";
import {stateToSelect} from "@common/ui-components/form/state-to-select";
import {DebounceCache} from "@common/react/debounce-cache";
import {TextInput} from "../../../../../../../../../../../../../common/form/text-input/text-input";
import {FontSizeSelect} from "../../../common/font-size-select";
import {getPath} from "@common/utils/arr-path";

export const DonutHole = ({tile, form}) => ({
    label: "Donut hole",
    render: () => (
        <div className="donut-hole-ur2">
            {DropdownSelect({
                label: "Hole Diameter",
                list: createArray(19).map((i) => (i + 1) * 5),
                valueToLabel: (v) => `${v}%`,
                ...ffToDropdown(form.field(["style", "holeDiameter"])),
            })}

            {DropdownSelect({
                label: "Center Display",
                list: ["Nothing", "Label only", "Label then Total", "Total only", "Total then Label"].map((label) => ({
                    label,
                    value: label
                        .split(" ")
                        .map((w) => w[0].toUpperCase() + w.slice(1))
                        .join(""),
                })),
                valueToLabel: (v) => v.label,
                ...stateToSelect(scope(tile, ["style", "centerDisplay"]), ["value"]),
            })}

            {getPath(tile.value, ["style", "centerDisplay"])?.includes("Label") &&
                cs(
                    [
                        "debounce",
                        ({state}, next) =>
                            DebounceCache({
                                state: scope(tile, ["style", "centerLabel"]),
                                next,
                            }),
                    ],
                    ({debounce}) =>
                        TextInput({
                            label: "Label",
                            state: debounce.state,
                            onBlur: debounce.flush,
                        })
                )}

            {FontSizeSelect(scope(tile, ["style", "centerLabelFontSize"]))}
        </div>
    ),
});
