import {unique} from "../../../../../../../../../common/utils/collections";

export const getTargetedTables = ({table, tables, relationships}) => {
    let ret = [];
    let _relationships = [...relationships];

    const getTableRelatedTo = (table) => {
        for (let column of table.columns) {
            for (let relationship of _relationships) {
                const getTableChild = (from, target) => {
                    if (relationship[from] == column.id) {
                        let tableFound = tables.find((t) => t.columns.find((c) => c.id == relationship[target]));
                        if (tableFound) {
                            _relationships = _relationships.filter((r) => r.id != relationship.id);
                            ret.push(tableFound);
                            getTableRelatedTo(tableFound);
                        }
                    }
                };

                getTableChild("leftColumnID", "rightColumnID");
                getTableChild("rightColumnID", "leftColumnID");
            }
        }
    };

    getTableRelatedTo(table);

    return unique(ret, (r) => r.id).filter((t) => t.id != table.id);
};

export const getValidTables = ({tables, relationships}) => {
    return tables.filter((table) => {
        return relationships.find((relationship) => {
            return table.columns.find((column) => [relationship.leftColumnID, relationship.rightColumnID].indexOf(column.id) > -1);
        });
    });
};
