const React = require("react");
const {equalDeep} = require("../utils/objects");

const Watch = ({onChanged, value, initRun, next}) => React.createElement(Watch1, {onChanged, value, initRun, next});
exports.Watch = Watch;

class Watch1 extends React.Component {
    constructor(props, context) {
        super(props, context);

        if (props.initRun) {
            props.onChanged(this.props.value, undefined, true);
        }
    }

    componentDidUpdate(prevProps) {
        const {value, onChanged, ignoreFunction = false} = this.props;
        if (!equalDeep(prevProps.value, value, {ignoreFunction})) {
            onChanged(value, prevProps.value, false);
        }
    }

    render() {
        const {next} = this.props;

        return next ? next() : null;
    }
}
