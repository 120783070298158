import React from "react";

export default {
    component: SuggestRelationshipIcons,
    title: "SVG Icons/Suggest Relationship Icons",
};

export const SuggestRelationshipIcons = () => {
    return (
        <div className="">
            <div className="">Verb.Web/web-client/src/routes/model/edit/tabs/left-panel-overrides/suggest-relationship</div>
            {Object.entries(iconSvgFiles).map(([name, src], i) => (
                <div className="" key={i}>
                    {name}
                    <img style={{width: 30, marginLeft: 30}} src={src} alt={""} />
                </div>
            ))}
        </div>
    );
};

const iconSvgFiles = {
    "check-icon.svg": require("./check-icon.svg"),
    "plus-icon.svg": require("./plus-icon.svg"),
    "data-view-icon.svg": require("./data-view-icon.svg"),
    "table-icon.svg": require("./table-icon.svg"),
};
