import {cx} from "emotion";
import React from "react";
import "@common/ui-components/charts/render-chart/legend/display/legend-top-bottom-vertical-display.scss";
import {
    getScatterGroupsForLegend,
    groupScatterLegendItems
} from "@common/ui-components/charts/scatter-plot/legend/scatter-legend-room";
import {
    ColorGroupItem,
    ReferenceLineItem,
    ShapeGroupItem
} from "@common/ui-components/charts/scatter-plot/legend/scatter-legend-item";
import {LegendControl} from "@common/ui-components/charts/render-chart/legend/control/legend-control";

export const ScatterLegendTopBottomVerticalDisplay = (
    {
        tile, theme, size, formatters,
        chart, hidden,
        options,
        legendRoom, legendContainerRef, scrollLeft
    }
) => {
    const hasColorGroup = tile.colorGroupField != null;
    const hasShapeGroup = tile.shapeGroupField != null;
    const hasReferenceLines = !!tile.referenceLines?.length;

    // legend only available when having one group or both groups or reference lines
    if (!hasColorGroup && !hasShapeGroup && !hasReferenceLines) {
        return;
    }

    const colorGroups = getScatterGroupsForLegend({
        series: chart.series,
        groupNameAttr: "colorGroupName",
        areSeriesFromChart: true
    });
    const shapeGroups = getScatterGroupsForLegend({
        series: chart.series,
        groupNameAttr: "shapeGroupName",
        areSeriesFromChart: true
    });

    const referenceLineSeries = chart.series
        .filter((s) => (
            ["ConstantValueReferenceLine", "AggregateValueReferenceLine", "IndicatorReferenceLine"].includes(s.userOptions.customType)
        ))

    const rSizeWidthLessThan7 = () => {
        return (
            <div className="legend-container">
                {hasColorGroup && colorGroups.map(({name, color}) => {
                    return ColorGroupItem({
                        name, color,
                        fontSize: options.legend.itemStyle.fontSize,
                        chart, hidden, theme
                    })
                })}
                {hasShapeGroup && shapeGroups.map(({name, shape}) => {
                    return ShapeGroupItem({
                        name, shape,
                        fontSize: options.legend.itemStyle.fontSize,
                        chart, hidden, theme
                    })
                })}
                {hasReferenceLines && (
                    chart.series
                        .filter((s) => ["ConstantValueReferenceLine", "AggregateValueReferenceLine", "IndicatorReferenceLine"].includes(s.userOptions.customType))
                        .map((s) => ReferenceLineItem({
                            series: s,
                            chart, tile, theme, hidden,
                            fontSize: options.legend.itemStyle.fontSize,
                        }))
                )}
            </div>
        )
    };

    const rSizeWidthGreaterThan7 = () => {
        const numberPerCol = Math.floor(legendRoom.legendHeight / (options.legend.itemStyle.fontSize + 7));
        const getColumns = (items) => groupScatterLegendItems({
            numberPerCol,
            items,
            fontSize: options.legend.itemStyle.fontSize
        })

        return (
            <>
                <div className="legend-container">
                    {hasColorGroup && getColumns(colorGroups).map((column, i) => (
                        <div
                            key={i}
                            className="legend-item-group"
                            style={{width: column.columnWidth, paddingRight: 0}}
                        >
                            {column.items.map(({name, color}) => {
                                return ColorGroupItem({
                                    name, color,
                                    fontSize: options.legend.itemStyle.fontSize,
                                    chart, hidden, theme
                                })
                            })}
                        </div>
                    ))}

                    {hasShapeGroup && getColumns(shapeGroups).map((column, i) => (
                        <div
                            key={i}
                            className="legend-item-group"
                            style={{width: column.columnWidth, paddingRight: 0}}
                        >
                            {column.items.map(({name, shape}) => {
                                return ShapeGroupItem({
                                    name, shape,
                                    fontSize: options.legend.itemStyle.fontSize,
                                    chart, hidden, theme
                                })
                            })}
                        </div>
                    ))}

                    {hasReferenceLines && (
                        groupScatterLegendItems({
                            numberPerCol,
                            items: referenceLineSeries,
                            fontSize: options.legend.itemStyle.fontSize
                        }).map((column, i) => (
                            <div
                                key={i}
                                className="legend-item-group"
                                style={{width: column.columnWidth, paddingRight: 0}}
                            >
                                {column.items.map((s) => {
                                    return ReferenceLineItem({
                                        series: s,
                                        chart, tile, theme, hidden,
                                        fontSize: options.legend.itemStyle.fontSize,
                                    })
                                })}
                            </div>
                        ))
                    )}
                </div>

                {scrollLeft.value !== null && LegendControl({
                    legendContainerRef,
                    scrollLeft
                })}
            </>
        )
    };

    return (
        <div
            className={cx(
                `legend-top-bottom-vertical-display-a55 verb-tile-legend verb-tile-legend-${legendRoom.paddingTop ? "top" : "bottom"}`,
                size.width < 7 ? "vertical-scroll" : "horizontal-scroll"
            )}
            style={{
                height: `${legendRoom.legendHeight}px`,
                top: legendRoom.paddingTop ? "0" : "",
                bottom: legendRoom.paddingBottom ? "0" : "",
                justifyContent: legendContainerRef.get()?.scrollWidth - legendContainerRef.get()?.clientWidth > 0 ? "" : "center"
            }}
        >
            {size.width < 7 ? rSizeWidthLessThan7() : rSizeWidthGreaterThan7()}
        </div>
    )
};
