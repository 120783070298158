import React from "react";
import {cs} from "@common/react/chain-services";
import {DropdownSelect2} from "@common/ui-components/dropdown-select/dropdown-select2";
import {cx} from "emotion";
import {UseState} from "@common/react/use-state";
import {spc} from "@common/react/state-path-change";
import {ffToDropdown} from "../../../../../../../../../../common/form/ff-to-dropdown";
import {keepOnly} from "@common/utils/objects";
import {rColumnValueSelectFromStepOutput} from "../../../../../common/column-value-select-from-step-output/column-value-select-from-step-output";
import {NumberInput} from "../../../../../../../../../../common/form/number-input/number-input";
import {ffToNumberInput} from "../../../../../../../../../../common/form/ff-to-number-input";
import {DatePicker} from "../../../../../../../../../../common/form/date-picker/date-picker";
import {ffToDatePicker} from "../../../../../../../../../../common/form/date-picker/ff-to-date-picker";
import {rNewColumnNameInput} from "../../../common/new-column-name-input";

export const AddDateOperations = ({state, operation, form, inputStep, model}) =>
    cs(() => {
        const columnDateValueSelect = rColumnValueSelectFromStepOutput({
            columns: inputStep.outputColumns,
            model,
            filterColumnFn: (c) => ["DateTime", "DateTimeOffset"].includes(c.dataType),
            isValueNotAllowed: (c) => !["DateTime", "DateTimeOffset"].includes(c.dataType),
        });
        const columnIntValueSelect = rColumnValueSelectFromStepOutput({
            columns: inputStep.outputColumns,
            model,
            filterColumnFn: (c) => ["Int", "Double"].includes(c.dataType),
            isValueNotAllowed: (c) => !["Int", "Double"].includes(c.dataType),
        });

        const isDateDiff = operation.value.$type === "DifferenceBetweenDates";
        const isAdd = operation.value.$type === "AddToDate";
        const isSub = operation.value.$type === "SubtractFromDate";

        return (
            <>
                <div className="config-group">
                    {isDateDiff ? <div className="label">Difference in unit</div> : <div className="label">Units to {isAdd ? "add" : "subtract"}</div>}

                    {(() => {
                        const dropdownProps = ffToDropdown(form.field(["operation", "unit"]));

                        return DropdownSelect2({
                            label: "Unit of Measure",
                            list: ["Years", "Months", "Weeks", "Days", "Hours", "Minutes", "Seconds", "Milliseconds"],
                            isSelected: (v) => v === dropdownProps.value,
                            onChange: (v) => dropdownProps.onChange(v),
                            ...keepOnly(dropdownProps, ["hasError", "errorMessage", "domRef"]),
                        });
                    })()}
                </div>

                <div className="config-group">
                    <div className="label">Build formula</div>

                    <div className="formula">
                        <div className="item">
                            {(() => {
                                const dropdownProps = ffToDropdown(form.field(["operation", isDateDiff ? "input1" : "input"]));
                                return (
                                    <>
                                        {columnDateValueSelect({
                                            state: {
                                                value: dropdownProps.value,
                                                onChange: dropdownProps.onChange,
                                            },
                                            ...keepOnly(dropdownProps, ["domRef", "hasError", "errorMessage"]),
                                        })}

                                        {dropdownProps.value?.$type === "StaticValue" &&
                                            DatePicker({
                                                label: "Date",
                                                ...ffToDatePicker(form.field(["operation", isDateDiff ? "input1" : "input", "value"])),
                                            })}
                                    </>
                                );
                            })()}
                        </div>

                        <div className="item">
                            {(() => {
                                if (isDateDiff) {
                                    const dropdownProps = ffToDropdown(form.field(["operation", "input2"]));
                                    return (
                                        <>
                                            {columnDateValueSelect({
                                                state: {
                                                    value: dropdownProps.value,
                                                    onChange: dropdownProps.onChange,
                                                },
                                                ...keepOnly(dropdownProps, ["domRef", "hasError", "errorMessage"]),
                                            })}

                                            {dropdownProps.value?.$type === "StaticValue" &&
                                                DatePicker({
                                                    label: "Date",
                                                    ...ffToDatePicker(form.field(["operation", "input2", "value"])),
                                                })}
                                        </>
                                    );
                                } else {
                                    const dropdownProps = ffToDropdown(form.field(["operation", "valueToAdd"]));
                                    return (
                                        <>
                                            {columnIntValueSelect({
                                                state: {
                                                    value: dropdownProps.value,
                                                    onChange: dropdownProps.onChange,
                                                },
                                                ...keepOnly(dropdownProps, ["domRef", "hasError", "errorMessage"]),
                                            })}

                                            {dropdownProps.value?.$type === "StaticValue" &&
                                                NumberInput({
                                                    label: operation?.value.unit || "Years",
                                                    ...ffToNumberInput(form.field(["operation", "valueToAdd", "value"])),
                                                })}
                                        </>
                                    );
                                }
                            })()}
                        </div>

                        <div className="math-icon">
                            <i
                                className={cx("fa", {
                                    "fa-plus": isAdd,
                                    "fa-minus": isSub || isDateDiff,
                                })}
                            />
                        </div>
                    </div>
                </div>

                {!isDateDiff ? (
                    ResultActionSelect({
                        state: operation,
                        form,
                        path: "operation",
                    })
                ) : (
                    <div className="config-group">
                        <div className="label">New Column Name</div>

                        {rNewColumnNameInput({
                            form,
                            newColumnPath: ["operation", "newColumn"],
                            newColumnNamePath: ["operation", "newColumn", "name"],
                        })}
                    </div>
                )}
            </>
        );
    });

export const ResultActionSelect = ({state, form, path}) =>
    cs(
        [
            "state1",
            (_, next) =>
                UseState({
                    initValue: state?.value?.overrideColumn ? "Override Input Column" : state?.value?.newColumn?.name ? "Save to New Column" : null,
                    next,
                }),
        ],
        ({state1}) => (
            <>
                <div className="config-group">
                    <div className="label">Result</div>

                    {DropdownSelect2({
                        label: "Action",
                        list: ["Override Input Column", "Save to New Column"],
                        isSelected: (v) => v === state1.value,
                        onChange: (v) => {
                            spc(state, ["overrideColumn"], () => v === "Override Input Column");
                            state1.onChange(v);
                        },
                    })}
                </div>

                {state1.value === "Save to New Column" && (
                    <div className="config-group">
                        <div className="label">Column Name</div>

                        {rNewColumnNameInput({
                            form,
                            newColumnPath: [path, "newColumn"],
                            newColumnNamePath: [path, "newColumn", "name"],
                        })}
                    </div>
                )}
            </>
        )
    );
