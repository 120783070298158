import * as React from "react";
import {cs} from "@common/react/chain-services";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {stateToSelect} from "@common/ui-components/form/state-to-select";
import {scope} from "@common/react/scope";
import "./free-form-text.scss";
import {comparisonMethods} from "@common/ui-components/live/filters/text-filter/comparison-methods";
import {TextInput} from "../../../../../../../../../../../../common/form/text-input/text-input";
import {ffToTextInput} from "../../../../../../../../../../../../common/form/ff-to-text-input";

export const FreeFormText = ({filter, form}) =>
    cs(({}) => {
        const currentType = filter.value?.defaultOperator && comparisonMethods.find((m) => m.name == filter.value?.defaultOperator);

        return (
            <div className="free-form-text-2h9">
                {DropdownSelect({
                    label: "Comparison Method",
                    list: comparisonMethods,
                    valueToLabel: (item) => item.label,
                    ...stateToSelect(scope(filter, ["defaultOperator"]), ["name"]),
                })}

                {currentType?.hasDefaultValue &&
                    TextInput({
                        label: "Default Value",
                        ...ffToTextInput(form.field("defaultFreeFormValue")),
                    })}
            </div>
        );
    });
