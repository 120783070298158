import {cs} from "@common/react/chain-services";
import {stateToCheckboxLineGroup} from "../../../../../../../common/checkbox-line-group/state-to-checkbox-line-group";
import * as React from "react";
import {operations} from "../operations";
import "./comparison-options.scss";
import {stringListState} from "../../../../../../../common/data-logic/string-list-state";
import {chain} from "@common/utils/fs";
import {enforceDefault} from "../../common/enforce-default";
import {CheckboxLineGroupFlex} from "../../../../../../../common/checkbox-line-group/checkbox-line-group-flex";
import {StaticTooltipService} from "../../../../../../../common/tooltip3/static-tooltip-service";

export const ComparisonOptions = ({filter}) =>
    cs(
        [
            "staticTooltip",
            (_, next) =>
                StaticTooltipService({
                    direction: "right",
                    info: "These comparison options will display to the user. If only 1 option is selected the user will not be shown the comparison method.",
                    tooltipBoxWidth: 200,
                    topOffset: -7,
                    next,
                }),
        ],
        ({staticTooltip}) => {
            return (
                <div className="comparison-options-6rd comparison-options">
                    <div className="header">
                        Comparison Methods
                        {staticTooltip.renderIcon({
                            icon: <i className="material-icons">info</i>,
                            className: "info-tooltip",
                        })}
                    </div>

                    <div className="content">
                        {CheckboxLineGroupFlex({
                            list: operations.filter((o) => {
                                if (o.aggregationFuncs) return o?.aggregationFuncs.includes(filter.value.aggregationFunc);
                                return true;
                            }),
                            numberOfColumns: 2,
                            ...chain(enforceDefault(filter), (_) => stateToCheckboxLineGroup(stringListState(_), ["name"])),
                        })}
                    </div>
                </div>
            );
        }
    );
