export const applyControlFilterForChart = ({$type, series, action, emptyColor, dimensionAxisType, isApplyForChart}) => {
    const isScatterBubble = ["ScatterPlotTile", "BubbleChartTile"].includes($type);
    const useNameFormDataPoint = isScatterBubble ? false : (!dimensionAxisType || (dimensionAxisType === "category"));

    return series.map((s) => ({
        ...s,
        data:
            $type === "BoxPlotTile"
                ? s.data
                : (s.data ?? []).map((d) => {
                      if (!action || !action.value) {
                          if (d[0]) {
                              return {...useNameFormDataPoint ? {name: d[0]} : {x: d[0]}, y: d[1], color: s.color}
                          }
                          if (isScatterBubble) {
                              // for the colors to come back when control filter is removed
                              return {...d, color: d.color || s.color};
                          }
                          return d;
                      }

                      if (d[0]) {
                          if (d[0] == action.value || s.name == action.value) {
                              return {
                                  ...(useNameFormDataPoint ? {name: d[0]} : {x: d[0]}),
                                  y: d[1],
                                  color: s.color,
                              };
                          }

                          return {
                              ...(useNameFormDataPoint ? {name: d[0]} : {x: d[0]}),
                              y: d[1],
                              color: isApplyForChart ? emptyColor : s.color,
                          };
                      }

                      if (d.name == action.value || s.name == action.value) {
                          return {...d, color: s.color || d.color};
                      }

                      return {
                          ...d,
                          color: isApplyForChart ? emptyColor : s.color || d.color,
                      };
                  }),
        color: action?.value && isApplyForChart ? emptyColor : s.color,
        lineColor: action?.value && isApplyForChart ? emptyColor : s.lineColor,
        turboThreshold: 1000000000,
    }));
};

export const applyControlFilterForMapTile = ({series, action, emptyColor, isApplyForChart}) => {
    if (isApplyForChart) {
        return series.map((s) => ({
            ...s,
            data: s.data.map((item) => {
                if (action?.value) {
                    if (action?.value.toLowerCase() != item.matchKey.toLowerCase()) {
                        return {...item, color: emptyColor};
                    }
                }

                return item;
            }),
        }));
    }

    return series;
};
