import React from "react";
import {cs} from "@common/react/chain-services";
import {tooltipService3} from "../../../../../common/tooltip3/tooltip-service-3";
import {consumeContext} from "@common/react/context";
import {Button} from "../../../../../../../../common/form/buttons/button/button";

export const ModelTutorialStep1 = ({onNext}) => {
    return (
        <div className="step-1">
            <img src={require("../images/step-1.gif")} className="img-bg" />

            <div className="text">Drag each table onto the canvas that contains data you intend to show in your charts.</div>

            <div className="step-footer">
                {RemoveGuidanceButton({})}

                <Button iconRight={<i className="fas fa-chevron-right" />} className="btn btn-primary" onClick={onNext}>
                    Next
                </Button>
            </div>
        </div>
    );
};

export const RemoveGuidanceButton = () =>
    cs(consumeContext("apis"), consumeContext("auth"), consumeContext("showTutorial"), tooltipService3({direction: "below"}), ({tooltip, apis, auth, showTutorial}) => (
        <div
            className="remove-guidance"
            onClick={async () => {
                apis.user.updateUserPreferences({modelGuidance: null});
                auth.setAuth({
                    user: {
                        ...auth.user,
                        preferences: {
                            ...auth.user.preferences,
                            modelGuidance: null,
                        },
                    },
                });
                showTutorial.onChange(false);
            }}
        >
            I don't need guidance{" "}
            <i
                className="material-icons"
                {...tooltip(() => "Your preferences will be updated and this tutorial will not show in the future unless you update your profile preferences")}
            >
                info
            </i>
        </div>
    ));
