const {chain} = require("../../../../utils/fs");

const adjustComparisonBars = ({chart, barStack, isHorizontal}) => {
    const compareBarSeries = chain(
        chart.series.filter((s) => s.userOptions.isCompare),
        (_) => (!barStack ? _ : _.filter((s) => s.userOptions.customStack === barStack))
    );

    compareBarSeries.forEach((s) => {
        const halfBarWidth = Math.ceil(s.barW) / 2;
        s.points?.forEach((p) =>
            p.graphic?.attr({
                transform: `translate(${isHorizontal ? "-" : ""}${halfBarWidth},0)`,
            })
        );
    });
};
exports.adjustComparisonBars = adjustComparisonBars;
