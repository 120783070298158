import {cascade} from "../../../../../../../../../../../../common/utils/cascade";
import {flatten1, unique} from "../../../../../../../../../../../../common/utils/collections";
import {chain} from "../../../../../../../../../../../../common/utils/fs";

export const getTablesInTile = ({tile, dimensionAttr, measureGroupAttrs, measureSingleAttrs, measureMultiAttr, groupFieldAttr, isBarFields = [], checkUnique = true}) => {
    if (!tile) {
        return;
    }

    const measureGroupFields =
        measureGroupAttrs &&
        chain(
            measureGroupAttrs.map((attr) =>
                cascade(tile[attr] || [], "[*].measureFields[*]", (value, path, objs) => ({
                    ...value,
                    fieldPath: path,
                    parent: attr,
                    isBarField: isBarFields.includes(attr),
                    objs,
                }))
            ),
            (_) => flatten1(_).filter((v) => v),
            (_) => (checkUnique ? unique(_, (v) => v.modelTableID) : _)
        );

    const measureSingleFields = measureSingleAttrs?.map((attr) => tile[attr]);

    return chain(
        [
            ...(dimensionAttr && Array.isArray(dimensionAttr) ? dimensionAttr.map((attr) => tile[attr]) : [tile[dimensionAttr]]),
            ...(measureSingleFields || []),
            ...(measureMultiAttr ? flatten1(measureMultiAttr.map((attr) => tile[attr])) || [] : []),
            ...(measureGroupFields || []),
            ...(groupFieldAttr ? [tile[groupFieldAttr]] || [] : []),
        ],
        (_) => _.filter((v) => v),
        (_) => _.map((v) => v.modelTableID),
        (_) => (checkUnique ? unique(_, (v) => v) : _)
    );
};

export const fieldsInTile = {
    HorizontalBarChartTile: {
        dimensionAttr: "yAxisField",
        measureGroupAttrs: ["xAxisFields"],
        groupFieldAttr: "groupField",
        isBarFields: ["xAxisFields"],
    },
    VerticalBarChartTile: {
        dimensionAttr: "xAxisField",
        measureGroupAttrs: ["yAxisFields"],
        groupFieldAttr: "groupField",
        isBarFields: ["yAxisFields"],
    },
    LineChartTile: {
        dimensionAttr: "xAxisField",
        measureGroupAttrs: ["yAxisFields"],
        groupFieldAttr: "groupField",
    },
    ComboChartTile: {
        dimensionAttr: "xAxisField",
        measureGroupAttrs: ["yAxisBarFields", "yAxisLineFields"],
        isBarFields: ["yAxisBarFields"],
        groupFieldAttr: "groupField",
    },
    ScatterPlotTile: {
        dimensionAttr: "dimensionField",
        measureSingleAttrs: ["xAxisField", "yAxisField"],
        groupFieldAttrs: ["colorGroupField", "shapeGroupField"],
    },
    BubbleChartTile: {
        dimensionAttr: "dimensionField",
        measureSingleAttrs: ["xAxisField", "yAxisField", "zAxisField"],
        groupFieldAttrs: ["colorGroupField"],
    },
    PieChartTile: {
        measureSingleAttrs: ["groupField", "valueField"]
    },
    DonutChartTile: {
        measureSingleAttrs: ["groupField", "valueField"],
    },
    DownloadReportTile: {
        measureMultiAttr: ["actualValueFields", "aggregatedValues"],
    },
    TableTile: {
        measureMultiAttr: ["actualValueFields", "aggregatedValues"],
    },
    GaugeTile: {
        measureSingleAttrs: ["valueField", "targetValueField"],
    },
    SingleKPITile: {
        measureSingleAttrs: ["valueField"],
    },
    TableKPITile: {
        measureSingleAttrs: ["valueField", "groupField"],
    },
    FunnelChartTile: {
        measureSingleAttrs: ["valueField", "groupField"],
    },
    ListKPITile: {
        measureMultiAttr: ["valueFields"],
    },
    ApiTile: {
        measureMultiAttr: ["actualValueFields", "aggregatedValues"],
    },
    // "ContainerTile": ["tiles"],
    PivotTableTile: {
        measureMultiAttr: ["rowFields", "columnFields", "valueFields"],
    },
    MapTile: {
        measureGroupAttrs: ["valueFields"],
    },
};
