const {getDataLabelRect} = require("./show-data-labels");
const {showDataLabels} = require("./show-data-labels");

const adjustFullyInsideDataLabels = ({chart, theme}) => {
    // const svgRoot = chart.renderer.box;

    // const createSvgRect = ({x, y, width, height}) => {
    //     let rect1 = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
    //
    //     rect1.setAttribute('x', x);
    //     rect1.setAttribute('y', y);
    //     rect1.setAttribute('width', width);
    //     rect1.setAttribute('height', height);
    //
    //     return rect1;
    // };

    const adjustEachSeries = (series) => {
        // console.log(series)
        const [centerX, centerY, diameter, innerDiameter] = series.center;

        showDataLabels({
            points: series.data.filter((point) => point.dataLabel),
            getRect: getDataLabelRect,
        });

        series.data.forEach((point) => {
            if (point.dataLabel) {
                // this is used for checking if the label rect is generated correctly
                // const fakeRect = createSvgRect({
                //     x: point.dataLabel.xSetting + 10,
                //     y: point.dataLabel.y + 10,
                //     width: point.dataLabel.width,
                //     height: point.dataLabel.height,
                // });
                // svgRoot.appendChild(fakeRect);

                const rectVertices = getDataLabelRectVertices({
                    point,
                    useXSetting: true,
                });

                const isNotCutOff =
                    isRectFullyInsideCircle({
                        rectVertices,
                        circleCenter: {
                            x: centerX + chart.plotLeft,
                            y: centerY + chart.plotTop,
                        },
                        circleRadius: diameter * 0.5,
                        offset: 5,
                    }) &&
                    isRectFullyOutsideCircle({
                        rectVertices,
                        circleCenter: {
                            x: centerX + chart.plotLeft,
                            y: centerY + chart.plotTop,
                        },
                        circleRadius: innerDiameter * 0.5,
                    });

                if (isNotCutOff && point.percentage >= 5) {
                    // if percentage < 5 then also not shown, this is a temporary solution for label width exceeding slice width
                    point.dataLabel.text.element.style.color = `${theme.general.tile.styles.tileBackgroundColorRGB}`;
                } else {
                    point.dataLabel.div.style.display = "none";
                }

                // if (point.dataLabel.y === 152) {
                //     console.log(point.dataLabel)
                //     console.log(rectVertices)
                //     console.log("outerR", diameter * 0.5)
                //     console.log("innerR", innerDiameter * 0.5)
                //     for (const vertex of rectVertices) {
                //         console.log(Math.hypot(centerX + chart.plotLeft - vertex.x, centerY + chart.plotTop - vertex.y))
                //     }
                //     console.log(isNotCutOff)
                // }
                // console.log(point.dataLabel.textStr)
                // console.log(isNotCutOff)
            }
        });
    };

    // chart.series.forEach((series) => adjustEachSeries(series));
    adjustEachSeries(chart.series.find((s) => !s.options.isCompare));
};
exports.adjustFullyInsideDataLabels = adjustFullyInsideDataLabels;

const getDataLabelRectVertices = ({point, useXSetting}) => {
    const {x, y, width, height} = getDataLabelRect({point, useXSetting});

    return [
        {x, y},
        {x: x + width, y},
        {x, y: y + height},
        {x: x + width, y: y + height},
    ];
};

const isRectFullyInsideCircle = ({rectVertices, circleCenter, circleRadius, offset}) => {
    for (const vertex of rectVertices) {
        if (Math.hypot(circleCenter.x - vertex.x, circleCenter.y - vertex.y) - (offset || 0) > circleRadius) {
            return false;
        }
    }
    return true;
};
exports.isRectFullyInsideCircle = isRectFullyInsideCircle;

const isRectFullyOutsideCircle = ({rectVertices, circleCenter, circleRadius}) => {
    for (const vertex of rectVertices) {
        if (Math.hypot(circleCenter.x - vertex.x, circleCenter.y - vertex.y) < circleRadius) {
            return false;
        }
    }
    return true;
};
