import React from "react";
import {cs} from "@common/react/chain-services";
import {DialogService} from "../../../../common/dialog/dialog-service";
import {CopySettingKey} from "../commons/copy-setting-key/copy-setting-key";
import "./manage-key/manage-access-key-dialog.scss";
import {Button} from "../../../../../../../common/form/buttons/button/button";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";

export const ViewAccessApiKeyDialog = ({next: rootNext}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    render: ({resolve, args: {accessKey}}) => ({
                        title: `${accessKey.name} API Key`,
                        width: 480,
                        content: next({
                            resolve,
                            accessKey,
                        }),
                        initShow: true,
                        className: "manage-access-key-dialog-wrapper-a23",
                    }),
                    next: rootNext,
                }),
        ],
        ({modal}) => (
            <div className="manage-access-key-dialog-z33">
                <VerbDialogBodyScrollbar>
                    <div className="copy-key-wrapper">
                        <div className="content">{modal.accessKey.apiKey}</div>

                        {CopySettingKey({
                            value: modal.accessKey.apiKey,
                            text: "Key",
                        })}
                    </div>
                </VerbDialogBodyScrollbar>

                <div className="buttons">
                    <Button onClick={() => modal.resolve()}>Done</Button>
                </div>
            </div>
        )
    );
