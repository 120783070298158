import {TileTitle} from "../common/tile-title/tile-title";
import {Content} from "./panels/content";
import {SectionOptions} from "../common/section-options/section-options";
import "./text-label.scss";

export const TextLabelTile = ({tile, form, theme, size, parentTile, kpiColorsTileConfig, tileAction, isContainerTile}) =>
    [TileTitle, Content, SectionOptions]
        .filter((v) => v)
        .map((panel) =>
            panel({
                tile,
                form,
                theme,
                size,
                parentTile,
                kpiColorsTileConfig,
                tileAction,
                isContainerTile,
            })
        );
