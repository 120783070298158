import * as React from "react";

export const CloseIcon = ({fill, onClick} = {}) => {
    return (
        <svg onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
            <path
                fill={fill || "#FFFFFF"}
                data-name="Icon material-close"
                d="M22.5,9.011,20.989,7.5,15,13.489,9.011,7.5,7.5,9.011,13.489,15,7.5,20.989,9.011,22.5,15,16.511,20.989,22.5,22.5,20.989,16.511,15Z"
                transform="translate(-7.5 -7.5)"
            />
        </svg>
    );
};
