import {getTooltipOptions} from "../../factory/tooltip/tooltip-options";
import {getLegendOptions} from "../../factory/legend";
import {flatten1} from "../../../../utils/collections";
import {getSectionLabelsOptions} from "./section-labels";

export const getVennDiagramOptions = ({tile, data, theme, formatters, isCompare, onClickPoint}) => {
    const chart = {
        type: "venn",
        backgroundColor: theme.general.tile.styles.tileBackgroundColorRGB,
    };

    const title = {
        text: undefined,
    };

    const plotOptions = {
        series: {
            opacity: 0.8, // default is 0.75. show see-through colors at overlapping areas
            borderColor: "#FFFFFF", // always show white border
            states: {
                hover: {
                    borderColor: "#FFFFFF",
                },
                // inactive: {
                //     opacity: 0.2 // default is 0.075
                // }
            },
            point: {
                events: {
                    // highlight border on hover
                    mouseOver: function () {
                        const {graphic, shapeArgs} = this;
                        const chart = this.series.chart;

                        const style = {
                            stroke: "#FFFFFF", // border is always white
                            "stroke-width": 1,
                            fill: "none",
                            zIndex: 2, // to make this the last child of series group and lie above other elements
                        };

                        if (shapeArgs.d) {
                            // path
                            chart.borderHighlight = chart.renderer.path(flatten1(shapeArgs.d)).attr(style).add(graphic.parentGroup).show();
                            return;
                        }

                        if (shapeArgs.x) {
                            // circle
                            chart.borderHighlight = chart.renderer.circle(shapeArgs.x, shapeArgs.y, shapeArgs.r).attr(style).add(graphic.parentGroup).show();
                        }
                    },
                    // remove highlight border on hover
                    mouseOut: function () {
                        this.series.chart.borderHighlight?.destroy();
                    },
                    click: function ({x, y, point}) {
                        onClickPoint?.({x, y, point, chart: this.series.chart});
                    },
                },
            },
        },
        venn: {
            dataLabels: getSectionLabelsOptions({tile, theme, formatters}),
        },
    };

    const tooltip = getTooltipOptions({
        tile,
        theme,
        formatters,
        rawData: data,
        isCompare,
    });

    const legend = getLegendOptions({style: tile.style.legendStyle, theme});

    return {
        chart,
        title,
        plotOptions,
        tooltip,
        legend,
        credits: {
            enabled: false,
        },
        accessibility: {
            enabled: false,
        },
    };
};
