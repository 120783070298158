import React from "react";
import {cs} from "@common/react/chain-services";
import "./auth-method.scss";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {stateToSelect} from "@common/ui-components/form/state-to-select";
import {scope} from "@common/react/scope";
import {TextInput} from "../../../../../../../common/form/text-input/text-input";
import {ffToTextInput} from "../../../../../../../common/form/ff-to-text-input";
import {AuthStaticTooltip} from "./auth-static-tooltip";

export const AuthMethod = ({authEndpoint, form, disableEditing}) =>
    cs(({}) => (
        <div className="auth-method-9sf">
            {DropdownSelect({
                disabled: disableEditing,
                label: (
                    <div className="auth-method-select-label">
                        HTTP Method
                        {AuthStaticTooltip({name: "method"})}
                    </div>
                ),
                list: ["Get", "Post", "Put"],
                ...stateToSelect(scope(authEndpoint, ["method"])),
            })}
            {TextInput({
                disabled: disableEditing,
                label: "Endpoint",
                placeholder: "https://api.mysite.com/getuser",
                ...ffToTextInput(form.field("endpoint")),
            })}
        </div>
    ));
