import "./shared-tile.scss";

import React from "react";
import {cx} from "emotion";
import {HeaderBar} from "../../common/header-bar/header-bar";
import {cs} from "@common/react/chain-services";
import {Badge} from "../../common/badge/badge";
import {Button} from "@common/form/buttons/button/button";
import {consumeContext} from "@common/react/context";
import {LayoutContent} from "../../common/layout2/layout-2";
import {Form2} from "@common/react/cs-form/form2";
import {required} from "@common/react/cs-form/validators/required";
import {max} from "@common/react/cs-form/validators/max";
import {min} from "@common/react/cs-form/validators/min";
import {ffToTextInput} from "@common/form/ff-to-text-input";
import {UseState} from "@common/react/use-state";
import {DebounceNumberInput} from "@common/form/debounce-input/debounce-number-input";
import {SuspendUpdate} from "@common/react/suspend-update";

export const IndependentContextLayout = ({tileCommands, sharedTile, main, leftPanel, rightPanel, interactions, syncState}) =>
    cs(consumeContext("tenant"), consumeContext("apis"), consumeContext("routing"), ({}) => {
        const isSaving = syncState?.type == "saving";
        return (
            <div className="edit-shared-tile-443 edit-collection-layout-4rw">
                <div className="shared-tile-badge">Editing Shared Tile</div>

                {HeaderBar({
                    ID: sharedTile.value?.id,
                    type: "sharedTile",
                    commands: tileCommands,
                    name: sharedTile.value?.adminTileName ?? "",
                    previousRoutes: ["dashboard", "edit-data-source", "edit-model"],
                    shareActions: () => (
                        <>
                            <div className="space" />

                            <DefaultSize sharedTile={sharedTile} />

                            <div className="group-buttons">
                                {isSaving ? (
                                    <div className={cx("save-status")}>
                                        <i className="fa fa-spinner fa-pulse" />
                                        Saving
                                    </div>
                                ) : (
                                    Badge({
                                        size: "lg",
                                        icon: <i className="far fa-check" />,
                                        type: "success",
                                        label: "Saved",
                                    })
                                )}
                            </div>

                            <div className="group-buttons">
                                <Button size="medium" btnType="primary" className="btn publish-button" onClick={() => interactions.onChange({name: "view-collections"})}>
                                    View Collections
                                </Button>
                            </div>
                        </>
                    ),
                })}

                <LayoutContent
                    {...{
                        main,
                        leftPanel,
                    }}
                />

                {rightPanel && (
                    <div
                        className={cx("right-panel", {
                            active: !!rightPanel?.content,
                        })}
                    >
                        {cs(
                            ({}, next) =>
                                SuspendUpdate({
                                    active: !rightPanel?.content,
                                    delay: 500,
                                    next,
                                }),
                            () => rightPanel?.content
                        )}
                    </div>
                )}
            </div>
        );
    });

const DefaultSize = ({sharedTile}) => {
    return cs(
        [
            "form",
            (_, next) =>
                Form2({
                    fields: {
                        defaultColSpan: [required, min(1), max(24)],
                        defaultRowSpan: [required, min(1), max(24)],
                    },
                    data: sharedTile,
                    onSubmit: async (...args) => {
                        // console.log(args);
                    },
                    next,
                }),
        ],
        ({form}) => {
            return (
                <div className="tile-default-size">
                    <div className="text">Default Size</div>
                    {DebounceNumberInput({
                        noLabel: true,
                        minValue: 1,
                        maxValue: 24,
                        ...ffToTextInput(form.field(["defaultColSpan"])),
                    })}
                    <span className="material-icons">close</span>
                    {DebounceNumberInput({
                        noLabel: true,
                        minValue: 1,
                        maxValue: 24,
                        ...ffToTextInput(form.field(["defaultRowSpan"])),
                    })}
                </div>
            );
        }
    );
};
