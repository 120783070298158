import {TableFormat} from "./panels/table-format/table-format";
import {SummaryNumber} from "./panels/summary-number/summary-number";
import {TileTitle} from "../common/tile-title/tile-title";
import {KpiColors} from "./panels/kpi-colors/kpi-colors";

export const TableKPI = ({tile, size, form, parentTile, tileAction, kpiColorsTileConfig, isContainerTile}) =>
    [
        // Display,
        TileTitle,
        TableFormat,
        SummaryNumber,
        KpiColors,
    ].map((panel) =>
        panel({
            tile,
            size,
            form,
            parentTile,
            tileAction,
            kpiColorsTileConfig,
            isContainerTile,
        })
    );
