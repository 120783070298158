import "./remove-source-dialog.scss";

import React from "react";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {DialogService} from "../../common/dialog/dialog-service";
import {TextInput} from "@common/form/text-input/text-input";
import {consumeContext} from "@common/react/context";
import {Button} from "@common/form/buttons/button/button";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";

export const RemoveSourceDialog = ({next: rootNext, onDone, reloadFolderOnDone = false}) =>
    cs(
        consumeContext("apis"),
        [
            "modal",
            ({}, next) =>
                DialogService({
                    render: ({resolve, reject, args: {dataSources}}) => ({
                        title: "Delete Data Source?",
                        content: next({reject, resolve, dataSources}),
                    }),
                    next: rootNext,
                }),
        ],
        consumeContext("toast"),
        ["confirmName", (_, next) => UseState({next})],
        ["submitting", (_, next) => UseState({next})],
        ({confirmName, submitting, modal, apis, toast}) => {
            const {dataSources} = modal;

            const numberOfDatasources = dataSources.length;
            const hasManyDataSources = numberOfDatasources > 1;
            const isDisabled = !confirmName.value || confirmName.value?.toLowerCase() !== "delete" || submitting.value;
            const message = !hasManyDataSources
                ? `Deleting the "${dataSources[0].name}" cannot be undone.`
                : `Are you sure you want to delete ${numberOfDatasources} data sources?`;

            return (
                <form
                    onSubmit={async (e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        if (isDisabled) {
                            return;
                        }

                        submitting.onChange(true);
                        await Promise.all(dataSources.map((d) => apis.data.removeDataSource(d.id, d.name)));

                        const newFolders = reloadFolderOnDone ? await apis.data.getFolders() : null;
                        onDone(newFolders);
                        toast.show(`${numberOfDatasources == 1 ? dataSources[0].name : `${numberOfDatasources} datasources`} deleted`);
                        submitting.onChange(false);
                        modal.resolve(true);
                    }}
                >
                    <div className="remove-source-dialog-8re">
                        <VerbDialogBodyScrollbar>
                            <div className="text">{message}</div>

                            {TextInput({
                                label: (
                                    <div>
                                        Please type DELETE to confirm
                                        {/*{hasManyDataSources ? "Please type DELETE to confirm" : "Type the display name of the data source to confirm deletion"}*/}
                                    </div>
                                ),
                                state: confirmName,
                            })}
                        </VerbDialogBodyScrollbar>

                        <div className="buttons">
                            <Button className="btn-cancel" btnType="secondary" onClick={modal.reject}>
                                Cancel
                            </Button>
                            <Button type="submit" disabled={isDisabled} btnType="primary-danger">
                                Delete
                            </Button>
                        </div>
                    </div>
                </form>
            );
        }
    );
