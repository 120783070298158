import React from "react";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {CircleRadius} from "../../model-panel";
import {lighten} from "@common/utils/color-util";
import {getELoc} from "@common/react/get-e-loc";
import {Static2} from "@common/react/static-2";
import {cx} from "emotion";

export const MoreAction = ({table, color, modelActionTooltip, toggleMoreActionMenu}) =>
    cs(["hovering", (_, next) => UseState({next})], ["actionRef", (_, next) => Static2({next})], ({hovering, actionRef}) => {
        const disabled = table.deleted;

        return (
            <g strokeOpacity={disabled ? 0.5 : 1} fillOpacity={disabled ? 0.5 : 1}>
                <path
                    ref={actionRef.set}
                    onMouseEnter={() => {
                        hovering.onChange(true);
                        const position = actionRef.get().getBoundingClientRect();
                        modelActionTooltip.showTooltip({
                            pos: {
                                x: position.x + position.width,
                                y: position.y,
                            },
                            info: "More Options",
                        });
                    }}
                    onMouseLeave={() => {
                        hovering.onChange(false);
                        modelActionTooltip.hideToolTip();
                    }}
                    onClick={toggleMoreActionMenu}
                    className={cx("action-btn", disabled && "disabled")}
                    x={table.position.x + CircleRadius - 0.5}
                    y={table.position.y - CircleRadius - 4}
                    d="M49.057-32.779a59,59,0,0,1,5.452,55.357l-23.1-9.567a34,34,0,0,0-3.142-31.9Z"
                    transform="translate(60.011 60)"
                    fill={hovering.value ? lighten(color, 80) : lighten(color, 70)}
                    stroke="#fff"
                    strokeWidth="2"
                />

                <svg style={{pointerEvents: "none"}} x={103.7} y={46.8} width="3.484" height="12" viewBox="0 0 3.484 12">
                    <g transform="translate(-703.098 -174.146)">
                        <path
                            data-name="Icon awesome-ellipsis-v"
                            d="M3.429,4.821A1.742,1.742,0,1,1,1.688,6.563,1.741,1.741,0,0,1,3.429,4.821ZM1.688,2.3A1.742,1.742,0,1,0,3.429.563,1.741,1.741,0,0,0,1.688,2.3Zm0,8.516A1.742,1.742,0,1,0,3.429,9.079,1.741,1.741,0,0,0,1.688,10.821Z"
                            transform="translate(701.411 173.583)"
                            fill="#546b81"
                        />
                    </g>
                </svg>
            </g>
        );
    });
