import {cs} from "@common/react/chain-services";
import {Form2} from "@common/react/cs-form/form2";
import {UseState} from "@common/react/use-state";
import {getTileFormConfig} from "../../../../../../../tiles-form-config/get-tile-form-config";
import {FilterTab} from "../../../../../filter-tab/filter-tab";
import {TileTab} from "../../../../../tile-tab/tile-tab";
import {FieldsTab} from "../../../../fields-tab";
import {consumeContext} from "@common/react/context";
import {ExpandableFilters} from "../../../../../filter-tab/local-filters/local-filters";
import {rFieldTypeIcon} from "../../../../../../../../../../common/field-type-icon/get-field-type-icon";
import {getActionFields} from "../../action-fields/action-field-utils";

export const ChildTileTabs = ({tile, childTile, tileAction, size, next}) =>
    cs(
        [
            "form",
            (_, next) =>
                Form2({
                    data: childTile,
                    ...getTileFormConfig(childTile.value?.$type),
                    next,
                }),
        ],
        ["selectedTab", (_, next) => UseState({initValue: "fields", next})],
        consumeContext("getFieldInfo"),
        consumeContext("leftPanelWidth"),
        [
            "tabs",
            ({form, getFieldInfo, leftPanelWidth}, next) => {
                let _size = size;
                if (tileAction.value.openType == "Modal") {
                    _size = {
                        width: 8,
                        height: 8,
                    };
                }

                return cs(
                    [
                        "tileTab",
                        (_, next) =>
                            TileTab({
                                tile: childTile,
                                form,
                                parentTile: tile,
                                tileAction,
                                size: _size,
                                next,
                                leftPanelWidth,
                            }),
                    ],
                    [
                        "filterTab",
                        (_, next) =>
                            FilterTab({
                                tile: childTile,
                                next,
                                childTileFilters: () => {
                                    const fields = getActionFields(tile, getFieldInfo).filter((af) => tileAction.value.enabledTileFieldIDs.indexOf(af?.id) !== -1);
                                    return (
                                        <ExpandableFilters
                                            disabled
                                            list={fields}
                                            renderLabel={({renderTooltip}) => (
                                                <>
                                                    Tile Action Filters &nbsp;
                                                    {renderTooltip()}
                                                </>
                                            )}
                                            direction="top"
                                            leftOffset={0}
                                            topOffset={0}
                                            tooltipBoxWidth={200}
                                            toolTip="Tile Action Filters are used to filter the data users view when opening the new tile based on the dimension or category they selected."
                                            renderIcon={(item) => rFieldTypeIcon(item.dataType)}
                                            itemLabel={(item) => item?.visualNameFull}
                                        />
                                    );
                                },
                            }),
                    ],
                    [
                        "fieldsTab",
                        (_, next) =>
                            FieldsTab({
                                tile: childTile,
                                form,
                                parentTile: tile,
                                size: _size,
                                next,
                            }),
                    ],
                    ({tileTab, filterTab, fieldsTab}) =>
                        next([
                            {
                                id: "tile",
                                label: "Display",
                                control: tileTab,
                            },
                            {
                                id: "filters",
                                label: "Filters",
                                control: filterTab,
                            },
                            {
                                id: "fields",
                                label: "Data",
                                control: fieldsTab,
                            },
                        ])
                );
            },
        ],
        ({tabs, selectedTab}) => {
            const activeTab = tabs.find((tab) => tab.id === selectedTab.value);
            return next({
                tabs: tabs.map((tab) => ({
                    ...tab,
                    hasError: tab.control?.hasError,
                    render: tab.control ? tab.control.render : () => "ZZ",
                })),
                selectedTab,
                getOverride: () => activeTab?.control?.override,
                getOverrideAll: () => activeTab?.control?.overrideAll,
            });
        }
    );
