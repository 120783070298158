import {cs} from "../../../../react/chain-services";
import React, {createElement as h} from "react";
import "./dp-text-input.scss";
import {bindInput} from "../../../../react/bind-input";
import {cx} from "emotion";

const emptyListener = () => null;

export const DpTextInput = ({onKeyUp, className = '', state, domRef, onFocus, onBlur, onClick, outline, noKeyboard, style, inputStyle, disabled = false, }) => cs(
    ({}) => (
        <div className={cx("input text-input dp-text-input-2sw", className)} style={style}>
            {h(noKeyboard ? "div" : "input", {
                ref: domRef,
                onFocus,
                onBlur,
                onKeyUp,
                onClick,
                disabled,
                style: inputStyle,
                ...noKeyboard ? {
                    tabIndex: 0,
                    className: cx("fake-input", {disabled}),
                    children: state.value ?? "",
                    ...(disabled ? {
                        onFocus: emptyListener,
                        onBlur: emptyListener,
                        onKeyUp: emptyListener,
                        onClick: emptyListener,
                    } : {})
                } : {
                    ...bindInput(state),
                },
            })}

            {/*{outline && (*/}
            {/*    <div className="outline" style={{borderColor: outline}}/>*/}
            {/*)}*/}
        </div>
    ),
);