import * as React from "react";
import { cs } from "../../../react/chain-services";
import { RenderChart } from "../render-chart/render-chart";
import { ChartInnerLayout } from "../chart-layout/chart-inner-layout";
import { isDataValid, loadTileData } from "../common/load-tile-data";
import "./funnel-chart.scss";
import { getFunnelChartOptions } from "./chart-options/funnel-chart-options";
import { decorateFunnelData } from "./decorate-funnel-data";
import { keepOnly } from "../../../utils/objects";
import { getValueFormatters } from "../pie/get-value-formatters";
import { consumeContext } from "../../../react/context";
import { isComparing } from "../factory/check-compare";
import { IgnoreUpdate } from "../../../react/ignore-update";
import { MenuOptions } from "../common/menu-options/menu-options";
import { loadTileFields } from "../get-field-color";
import { ActionMenu } from "../common/action-menu/action-menu";
import { NoDataScreen } from "../common/no-data-screen/no-data-screen";
import {
    CurrencyConversionWarning,
    getCurrencyConversion
} from "../common/currency-conversion-warning/currency-conversion-warning";
import { fragments } from "@common/react/fragments";
import { Watch } from "@common/react/watch"

export const FunnelChart = (
    {
        tile,
        chartRef, size, theme,
        tileFilters,
        loadData, downloadData, defaultData,
        overrideTile, disabledTileActions = false, sdkDashboard, isLive
    }
) => cs(
    ["chartData", ({}, next) => defaultData ? next(defaultData) : loadTileData({
        next, tileFilters, loadData, size, tile, theme,
        tileKey: JSON.stringify([
            keepOnly(tile, ["groupField", "valueField", "filters", "limit", "customSortValues"])
        ]),
    })],

    ({chartData}, next) => !isDataValid(chartData) ? NoDataScreen({size, theme}) : next(),

    ["data", ({chartData}, next) => {
        return next({
            ...chartData,
            series: decorateFunnelData({series: chartData.series, tile, theme}),
        })
    }],

    consumeContext('selectedTimezone'),
    ["formatters", ({selectedTimezone}, next) => next(getValueFormatters({
        valueField: tile.valueField,
        groupField: tile.groupField,
        timezone: selectedTimezone?.value,
        tile
    }))],

    ["tileFields", ({}, next) => loadTileFields({
        next,
        configs: {
            tile,
            measureSingleAttrs: ["valueField"],
            groupFieldAttr: "groupField",
            checkUnique: false,
        }
    })],

    ["controls", ({ chartData, tileFields }, next) => {
        const hasMenuOptions = tile.style.showDownloadData || tile.style.showDownloadImage;

        // if (!hasMenuOptions) {
        //     return next(null);
        // }

        return next(
            <div className="controls">
                {CurrencyConversionWarning({
                    currencyConversion: getCurrencyConversion({ $type: chartData.$type, data: chartData, tileFields }),
                    theme
                })}

                {hasMenuOptions && MenuOptions({
                    chartRef,
                    theme,
                    tile,
                    downloadData,
                    tileFilters
                })}
            </div>
        )
    }],

    ['innerSize', ({ chartData, controls }, next) => ChartInnerLayout({
        size, tile, theme, next,
        noData: !isDataValid(chartData),
        hasControls: !!controls
    })],
    ({ innerSize }, next) => {
        return fragments(
            next(),
            Watch({
                onChanged: () => {
                    const {chart} = chartRef?.get?.() || {};
                    if (sdkDashboard && isLive) chart?.reflow?.();
                },
                value: JSON.stringify(innerSize),
            })
        );
    },
    ["actionMenu", ({ formatters }, next) => ActionMenu({ tile, overrideTile, dimensionFormatter: formatters.dimensionFormatter, disabledTileActions, next })],
    ["onClickPoint", ({ actionMenu, tileFields }, next) => {
        if (!actionMenu.hasActions()) {
            return next();
        }
        return next(({ point, ...other }) => {
            let fieldToValue = tileFields.reduce((obj, field) => {
                obj[field.id] = field.$type === "CategoryTileField" ? point.name : point.y;
                return obj;
            }, {});

            const tileActionData = point.series?.userOptions?.tileActionData;

            tileActionData?.columns.forEach((c, index) => {
                fieldToValue[c.tileFieldID] = tileActionData.data[index];
            });

            actionMenu.show({
                point,
                ...other,
                fieldToValue,
            });
        })
    }],

    ({ selectedTimezone }, next) => IgnoreUpdate({
        next,
        props: { timezoneId: selectedTimezone?.value },
        when: ({ timezoneId }) => timezoneId != selectedTimezone?.value,
    }),

    ({ data, formatters, controls, onClickPoint }) => {

        const isCompare = isComparing(data, tile);

        const options = {
            ...getFunnelChartOptions({ tile, theme, rawData: data, formatters, isCompare, onClickPoint }),
            series: data.series.filter(s => !s.isCompare),
            formatters,
        };

        return (
            <div
                className="funnel-chart-fl3"
                key={getRefreshKey({
                    tile, theme,
                    tileFiltersValue: tileFilters.getValue(),
                })}
            >
                <div className="chart">
                    <RenderChart
                        {...{
                            chartRef,
                            tile,
                            options, size, theme,
                            isCompare,
                            formatters,
                        }}
                    />
                </div>
                {controls}
            </div>
        )
    }
);

const getRefreshKey = ({tile, theme, tileFiltersValue}) =>
    JSON.stringify([
        keepOnly(tile, ["id", "filters", "groupField", "valueField", "limit", "customSortValues"]),
        keepOnly(tile.style, [
            "displayType", // for extend-function-section-pill position the labels correctly
            "stageStyle",
            "showCompare",
            "dataLabels",
            "legendStyle",
            "autoRound",
        ]),
        theme.dataVisualization.toolTipsAndLegends,
        tileFiltersValue,
    ]);

// [
//     "chartData",
//     ({chartData1}, next) => {
//         // return next(chartData1);
//         return next({
//             ...chartData1,
//             series: [
//                 {
//                     ...chartData1.series[0],
//                     data: [
//                         ["mid", 73240],
//                         ["upper", 238000],
//                         ["mid", 37300],
//                     ],
//                 },
//             ],
//         });
//     },
// ],
