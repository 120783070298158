import * as React from "react";
import {cs} from "@common/react/chain-services";
import "./existing-relationships.scss";
import {consumeContext} from "@common/react/context";
import {RemoveRelationshipDialog} from "../../remove-relationship-dialog/remove-relationship-dialog";
import {rRelationshipLine} from "../render-relationship-line/render-relationship-line";
import {keyed} from "@common/react/keyed";

export const ExistingRelationship = ({model, table, dataSources}) =>
    cs(
        [
            "removeRelationshipService",
            (_, next) =>
                RemoveRelationshipDialog({
                    model,
                    next,
                }),
        ],
        consumeContext("dataSourceModelContext"),
        ({dataSourceModelContext, removeRelationshipService}) => {
            const {getModelColumn} = dataSourceModelContext;
            const relationshipLine = rRelationshipLine({
                getModelColumn,
                originTableId: table.id,
                dataSources,
            });

            const tableColumnIds = table.columns.map((c) => c.id);
            const tableRelationships = model.value.relationships?.filter((r) => tableColumnIds.includes(r.leftColumnID) || tableColumnIds.includes(r.rightColumnID));

            return (
                <div className="existing-relationships-wr9">
                    {!tableRelationships?.length ? (
                        <div className="no-existing-relationships">There are not any relationships.</div>
                    ) : (
                        tableRelationships.map((relationship, i) =>
                            cs(keyed(relationship.id || i), ({}) =>
                                relationshipLine({
                                    relationship,
                                    control: (
                                        <div
                                            className="break-relationship"
                                            onClick={() =>
                                                removeRelationshipService.show({
                                                    relationship,
                                                })
                                            }
                                        >
                                            <img src={require("../../../common/icons/break-relationship-icon.svg")} alt="" />
                                        </div>
                                    ),
                                })
                            )
                        )
                    )}
                </div>
            );
        }
    );
