import "./non-numeric.scss";

import React, {Fragment} from "react";
import {spc} from "@common/react/state-path-change";
import {removeIndex} from "@common/utils/collections";
import {TrashIcon} from "../../../../../../../../common/icons/trash-icon";
import {DebounceTextInput} from "../../../../../../../../../../../common/form/debounce-input/debounce-text-input";
import {ffToBasicInput} from "../../../../../../../../../../../common/form/ff-to-basic-input";
import {cs} from "@common/react/chain-services";
import {SidePanel} from "../../../../../../../../common/side-panel/side-panel";
import {UseState} from "@common/react/use-state";
import {changePath} from "@common/utils/arr-path";
import {Button} from "../../../../../../../../../../../common/form/buttons/button/button";
import {GhostButton} from "../../../../../../../../../../../common/form/buttons/ghost-button/ghost-button";
import {PlusIcon} from "@common/ui-components/icons/global-icons";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";
import {removeDuplicatedSpace} from "@common/utils/strings";

export const NonNumeric = ({step, form}) =>
    cs(
        [
            "pasteValuesPanel",
            (_, next) =>
                PasteValuesPanel({
                    next,
                    onAddNewValues: (newValues) => {
                        const {onChange, value} = form.field(["valueLookup", "valueMappings"]).state;
                        onChange(value.concat(newValues));
                    },
                }),
        ],
        ({pasteValuesPanel}) => {
            const valueLookup = step.value.valueLookup;

            return (
                <div className="config-group list-non-numeric-7if">
                    <div className="label">Map values to replace</div>
                    <div className="value-mappings-table">
                        <div className="flex-line header">
                            <div className="column" style={{width: "47%"}}>
                                Original value
                            </div>
                            <div className="column">New value</div>
                        </div>
                        <VerbScrollbar maxHeight="400px" className="mapped-values">
                            {(valueLookup?.valueMappings || []).map((vm, vmi) => (
                                <div className="flex-line value-mapping" key={vmi}>
                                    <div className="column">
                                        <div className="original-value-input">
                                            {DebounceTextInput({
                                                ...ffToBasicInput(form.field(["valueLookup", "valueMappings", vmi, "lookup"])),
                                            })}
                                        </div>
                                    </div>
                                    <div className="column">
                                        <div className="new-value-input">
                                            <div className="equal-sign">=</div>
                                            {DebounceTextInput({
                                                ...ffToBasicInput(form.field(["valueLookup", "valueMappings", vmi, "value"])),
                                            })}
                                        </div>
                                    </div>
                                    <div className="delete-btn" onClick={() => spc(step, ["valueLookup", "valueMappings"], (valueMappings) => removeIndex(vmi, valueMappings))}>
                                        {TrashIcon()}
                                    </div>
                                </div>
                            ))}
                        </VerbScrollbar>
                        <div className="add-btn">
                            <GhostButton
                                iconLeft={<PlusIcon />}
                                // disabled={
                                //     valueLookup.valueMappings?.length > 0 &&
                                //     (!last(valueLookup.valueMappings).lookup || !last(valueLookup.valueMappings).value)
                                // }
                                onClick={() => spc(step, ["valueLookup", "valueMappings"], (valueMappings) => [...(valueMappings || []), {compare: "Equals"}])}
                            >
                                Add
                            </GhostButton>
                            <Button size="tiny" btnType="secondary" onClick={() => pasteValuesPanel.open()}>
                                {"Paste multiple values"}
                            </Button>
                        </div>
                    </div>

                    {pasteValuesPanel.render()}
                </div>
            );
        }
    );

const numMockRows = 20;
const PasteValuesPanel = ({next: rootNext, onAddNewValues = () => {}}) =>
    cs(
        ["values", (_, next) => UseState({next, initValue: []})],
        [
            "handleOnPaste",
            ({values}, next) =>
                next((e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    if (!e.clipboardData) return;

                    let htmlText = e.clipboardData.getData("text/html");

                    if (htmlText?.length > 0) {
                        const res = htmlText.match(/\<body\s[a-zA-Z0-9\"\#\=\s]+\>([\w\W]*)\<\/body\>/);

                        if (res && res[1].length > 0) {
                            const div = document.createElement("div");
                            div.innerHTML = res[1];
                            const rows = div.querySelector("table").rows;

                            const newValues = [].reduce.call(
                                rows,
                                function (arr, row) {
                                    const lookup = removeDuplicatedSpace(row.cells[0].textContent.replace(/[\n\t\r]/, ""));
                                    const value = removeDuplicatedSpace(row.cells[1].textContent.replace(/[\n\t\r]/, ""));
                                    if (lookup.length > 0 || value.length > 0) {
                                        if (values.value.find((v) => v.lookup == lookup) || arr.find((v) => v.lookup == lookup)) {
                                            return arr;
                                        }

                                        return arr.concat({
                                            compare: "Equals",
                                            lookup,
                                            lookup2: null,
                                            value,
                                        });
                                    }
                                    return arr;
                                },
                                []
                            );

                            values.onChange(values.value.concat(newValues));

                            return 1;
                        }
                    }

                    const plainText = e.clipboardData.getData("text/plain");

                    if (plainText.length > 0) {
                        const rows = plainText.split("\n");
                        const newValues = rows.reduce((arr, row) => {
                            try {
                                const [lookup, value] = row.split("\t");

                                if (values.value.find((v) => v.lookup == lookup) || arr.find((v) => v.lookup == lookup)) {
                                    return arr;
                                }

                                if (lookup.length > 0 || value.length > 0) {
                                    return arr.concat({
                                        compare: "Equals",
                                        lookup,
                                        lookup2: null,
                                        value,
                                    });
                                }
                            } catch (e) {}

                            return arr;
                        }, []);
                        values.onChange(values.value.concat(newValues));
                        return 1;
                    }
                }),
        ],
        [
            "handleOnChange",
            ({values}, next) =>
                next(({text, index, prop}) => {
                    const newValues = changePath(values.value, [index, prop], () => text);
                    values.onChange(newValues.filter((row) => row.lookup?.length > 0 || row.value?.length > 0));
                }),
        ],
        [
            "handleOnBlur",
            ({values}, next) =>
                next((e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    const str = e.target.value;

                    if (str.length > 0) {
                        e.target.value = "";
                        values.onChange(
                            values.value.concat({
                                compare: "Equals",
                                lookup: str,
                                lookup2: null,
                                value: "",
                            })
                        );
                    }
                }),
        ],
        ({values, handleOnPaste, handleOnChange, handleOnBlur}) =>
            SidePanel({
                className: "paste-values-panel-ffg",
                title: "Paste Multiple Values",
                onClose: () => {
                    values.onChange([]);
                },
                renderFooter: ({close}) => {
                    return (
                        <Fragment>
                            <button className="cancel" onClick={() => close()}>
                                Cancel
                            </button>
                            <button
                                className="ok"
                                onClick={async () => {
                                    await onAddNewValues(values.value);
                                    close();
                                }}
                            >
                                Add Values
                            </button>
                        </Fragment>
                    );
                },
                renderContent: () => {
                    const avaiMockRows = numMockRows - values.value.length - 1;
                    return (
                        <div>
                            <div className="text">
                                Copy two columns of values from your spreadsheet and paste below. Ensure original values are on the left and new values are on the right.
                            </div>
                            <div className="rows">
                                {values.value.map((v, index) => (
                                    <div className="row active" key={`real_value_${index}`}>
                                        <div className="ori-value">
                                            <input
                                                type="text"
                                                value={v.lookup}
                                                onChange={(e) =>
                                                    handleOnChange({
                                                        text: e.target.value,
                                                        index,
                                                        prop: "lookup",
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className="new-value">
                                            <input
                                                type="text"
                                                value={v.value}
                                                onChange={(e) =>
                                                    handleOnChange({
                                                        text: e.target.value,
                                                        index,
                                                        prop: "value",
                                                    })
                                                }
                                            />
                                        </div>
                                    </div>
                                ))}
                                <div className="row">
                                    <div className="new-input">
                                        <input type="text" placeholder="Paste values here" onPaste={(e) => handleOnPaste(e)} onBlur={(e) => handleOnBlur(e)} />
                                    </div>
                                    <div className="new-value"></div>
                                </div>
                                {avaiMockRows > 0 &&
                                    Array(avaiMockRows)
                                        .fill(1)
                                        .map((_, i) => (
                                            <div className="row" key={`mock_${i}`}>
                                                <div className="ori-value"></div>
                                                <div className="new-value"></div>
                                            </div>
                                        ))}
                            </div>
                        </div>
                    );
                },
                next: rootNext,
            })
    );
