import React from "react";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {keyed} from "@common/react/keyed";
import {DebounceNumber} from "../../../../../../../../../../../../../common/form/debounce-number";
import {scope} from "@common/react/scope";
import {NumberInput} from "../../../../../../../../../../../../../common/form/number-input/number-input";
import {sum} from "@common/utils/collections";
import {Watch} from "@common/react/watch";

export const IndividualColumns = ({tile, getFieldInfo, state}) =>
    cs(["columnCustomWidths", (_, next) => UseState({next, initValue: state.value || {}})], ({columnCustomWidths}) => {
        const isValid = () => sum([...(tile.value.actualValueFields || []), ...(tile.value.aggregatedValues || [])], (f) => columnCustomWidths.value[f.id] || -Infinity) == 100;

        return (
            <div className="subsection">
                {Watch({
                    value: isValid(),
                    onChanged: (val) => {
                        if (val) state.onChange(columnCustomWidths.value);
                    },
                })}

                <div className="header">Individual Columns</div>
                <div className="content">
                    {[...(tile.value.actualValueFields || []), ...(tile.value.aggregatedValues || [])]?.map((f, i) =>
                        cs(
                            keyed(i),
                            [
                                "debounce",
                                (_, next) =>
                                    DebounceNumber({
                                        state: scope(columnCustomWidths, [f.id]),
                                        next,
                                    }),
                            ],
                            ({debounce}) =>
                                NumberInput({
                                    label: getFieldInfo(f).visualNameShort,
                                    state: debounce.state,
                                    onBlur: debounce.flush,
                                    unit: "%",
                                    hasError: !isValid(),
                                })
                        )
                    )}
                </div>
            </div>
        );
    });
