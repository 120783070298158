import {tileTypes} from "../select-tile-type/tile-types";
import {conflictRect, getPositionToAdd, updatedCollectionAfterAdd} from "./dnd-common";
import {numColumnsOfGrid} from "./grid-constants";
import {searchSpaceToDrop} from "./dnd/moving";

export const windowAddNewTile = (tileType, x, y, collection, tiles) => {
    const tile = tileTypes.find((t) => t.name == tileType);

    if (!tile) return "Invalid Tile Type";

    if (!x || !y) {
        const newTile = getPositionToAdd({
            tile: {
                ...tile,
                position: {x: 0, y: 0},
                size: {width: 5, height: 5},
            },
            tiles,
        });
        collection.change(() =>
            updatedCollectionAfterAdd({
                tile: {
                    ...tile.template,
                    title: `${tile.name} ${Math.floor(Math.random() * (999 - 100 + 1) + 100)}`,
                },
                rect: newTile.rect,
                collection,
            })
        );
        return "Added Tile";
    }

    const drop = (pos) => {
        collection.change(() =>
            updatedCollectionAfterAdd({
                tile: {
                    ...tile.template,
                    title: `${tile.name} ${Math.floor(Math.random() * (999 - 100 + 1) + 100)}`,
                },
                rect: pos,
                collection,
            })
        );
    };

    const isInvalidPos = (rect) => rect.x < 0 || rect.y < 0 || rect.x + 5 > numColumnsOfGrid;
    const rejectFunc = ({rect}) => conflictRect({rect, tiles});
    const tilePos = {x, y, width: 5, height: 5};

    if (rejectFunc?.({rect: tilePos})) {
        const fuzzyRect = searchSpaceToDrop({
            isReject: (position) => rejectFunc({rect: position}),
            position: tilePos,
        });
        if (fuzzyRect) {
            if (!isInvalidPos(fuzzyRect)) {
                drop(fuzzyRect);
                return "Dropped";
            }

            return "Cannot Drop";
        }
    }

    if (!rejectFunc({rect: tilePos})) {
        if (!isInvalidPos(tilePos)) {
            drop(tilePos);
            return "Dropped";
        }
        return "Cannot Drop";
    }
};

export const removeAllTiles = (collection) => {
    collection.change((c) => ({...c, gridLocations: []}));
    return "Removed All Tiles";
};

export const removeTiles = (collection, ids) => {
    collection.change((c) => ({
        ...c,
        gridLocations: c.gridLocations.filter((grid) => ids.indexOf(grid.tile.id) == -1),
    }));
    return `Removed ${ids.length} Tiles`;
};

export const resizeTile = (tiles, id, size) => {
    const tile = tiles.find((t) => t.key == id);
    if (tile) {
        tile.onChangeSize({...size, ...tile.position});
        // console.log("Resized");
    } else {
        console.log("Not found tile");
    }
};

export const dragDownloadReportTileToFilters = (collection, tiles, id) => {
    const tile = tiles.find((t) => t.key == id);

    if (!tile) {
        console.log("Tile not found!");
        return;
    }

    if (tile.$type != "DownloadReportTile") {
        console.log("Invalid Tile Type");
        return;
    }

    collection.change((c) => ({
        ...c,
        gridLocations: c.gridLocations.filter((grid) => grid.tile.id != id),
        filterDownloadTiles: c.filterDownloadTiles.concat(tile),
    }));

    console.log("Moved to filters");
};
