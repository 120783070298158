// service for getting filters data from dashboard
export const GetFiltersData = (() => {
    let parentTileFilterFunc = null;
    let childTileFilterFunc = null;
    let tableOverrides = null;
    return {
        setTileFiltersFunc: (func) => (parentTileFilterFunc = func),
        setChildTileFiltersFunc: (func) => (childTileFilterFunc = func),
        getTileFilters: (tileID, isChildTile) => {
            if (!isChildTile && parentTileFilterFunc) {
                return parentTileFilterFunc(tileID);
            }
            if (isChildTile && childTileFilterFunc) {
                return childTileFilterFunc(tileID);
            }

            return {};
        },
        setTableOverrides: (val) => (tableOverrides = val),
        getTableOverrides: () => ({...tableOverrides} || {}),
    };
})();
