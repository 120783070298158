import React from "react";
import {cs} from "@common/react/chain-services";
import "./register-route.scss";
import {consumeContext} from "@common/react/context";
import {Load} from "@common/react/load";
import {UseState} from "@common/react/use-state";
import {Form2} from "@common/react/cs-form/form2";
import {required} from "@common/react/cs-form/validators/required";
import {email} from "@common/react/cs-form/validators/email";
import {equalDeep, omit} from "@common/utils/objects";
import {equalsPath} from "@common/react/cs-form/validators/equals-path";
import {GuestLayout} from "../common/layout/guest-layout";
import {getUser} from "../login/login-route";
import {SelectTenantDialog} from "../login/select-tenant-dialog/select-tenant-dialog";
import {password} from "@common/react/cs-form/validators/password";
import {billingHost} from "../../account-management/common/org-billing-constant";
import {Load2} from "@common/react/load2";
const {CookieStorage} = require("../../../../../common/logic/cookie-storage");

export const RegisterRoute = () =>
    cs(
        consumeContext("apis"),
        consumeContext("auth"),
        consumeContext("routing"),
        [
            "invitation",
            ({apis, routing}, next) =>
                Load2({
                    fetch: () => apis.getTenantViaInviteID(routing.params.inviteID),
                    captureException: true,
                    next,
                }),
        ],
        ({invitation}, next) => (!invitation.loading ? next(invitation) : "Loading invitation..."),
        ({invitation}, next) => {
            if (invitation.error) {
                return GuestLayout({
                    adjustPadding: true,
                    header: "This invitation is expired",
                    pageDescription: `Please contact the admin who invited you to resend your invitation.`,
                    rightLabel: "Accepting an invitation",
                    rightContent: (
                        <>
                            If you need help with your invitation you can contact us at <a href="mailto:support@revivemedia.us">support@revivemedia.us</a> or reach out to the team
                            member that invited you.
                            <br />
                            <br />
                            If you want to learn more about Verb,{" "}
                            <a href="https://www.revivemedia.us/" target="_blank">
                                click here
                            </a>
                            .
                        </>
                    ),
                });
            } else return next();
        },
        ["state", ({routing}, next) => UseState({next})],
        [
            "params",
            ({routing, invitation}, next) =>
                UseState({
                    getInitValue: () => ({
                        email: invitation?.value.email,
                        inviteToken: routing.params.inviteToken,
                    }),
                    next,
                }),
        ],
        ["selectTenantDialog", ({}, next) => SelectTenantDialog({next})],
        [
            "form",
            ({params, state, auth, apis, selectTenantDialog, routing}, next) =>
                Form2({
                    fields: {
                        email: [required, email],
                        firstName: [required],
                        lastName: [required],
                        password: [required, password],
                        passwordConfirm: [required, equalsPath("password")],
                    },
                    data: params,
                    onSubmit: async () => {
                        try {
                            state.onChange({loading: true});
                            await apis.register(params.value);
                            const auth1 = await apis.login({
                                email: params.value.email,
                                password: params.value.password,
                            });

                            if (auth1.loginStatus === "Success") {
                                const access = CookieStorage.get("verb.auth.access");
                                const user1 = CookieStorage.get("verb.user");

                                if (user1.temp) {
                                    window.location.replace(billingHost);
                                }

                                const user = await getUser();
                                auth.setAuth({user, access});
                            } else {
                                if (auth1.loginStatus === "SubscriptionCanceled") {
                                    state.onChange({
                                        loading: false,
                                        error: "subscription_canceled",
                                    });
                                } else {
                                    state.onChange({
                                        loading: false,
                                        error: "invalid_credentials",
                                    });
                                }
                            }
                        } catch (err) {
                            state.onChange({error: err.message});
                            //console.log(err);
                        }
                    },
                    next,
                }),
        ],
        ({state, form, invitation}) =>
            GuestLayout({
                header: "Welcome to Verb!",
                error: state.value?.error,
                pageDescription: `You've been invited to ${invitation?.value.tenantName}'s organization. Please complete the fields below to get started.`,
                form: [
                    {
                        label: "Email Address",
                        field: form.field("email"),
                        disabled: true,
                    },
                    {
                        label: "First Name",
                        field: form.field("firstName"),
                    },
                    {
                        label: "Last Name",
                        field: form.field("lastName"),
                    },
                    {
                        label: "Password",
                        field: form.field("password"),
                        type: "password",
                        info: "The password must be at least 8 characters and contain at least one upper case letter, lower case letter, number and special character.",
                    },
                    {
                        label: "Confirm Password",
                        field: form.field("passwordConfirm"),
                        type: "password",
                    },
                ],
                onSubmit: form.submit,
                submitBtn: {
                    label: "Accept Invite",
                    disabled: state.value?.loading,
                },
                rightLabel: "Accepting an invitation",
                rightContent: (
                    <>
                        If you need help with your invitation you can contact us at <a href="mailto:support@revivemedia.us">support@revivemedia.us</a> or reach out to the team member
                        that invited you.
                        <br />
                        <br />
                        If you want to learn more about Verb,{" "}
                        <a href="https://www.revivemedia.us/" target="_blank">
                            click here
                        </a>
                        .
                    </>
                ),
                policyText: (
                    <>
                        By registering, you agree to our{" "}
                        <a href="https://www.revivemedia.us/terms-of-use" target="_blank">
                            Terms of Service
                        </a>{" "}
                        and{" "}
                        <a href="https://www.revivemedia.us/privacy" target="_blank">
                            Privacy Policy
                        </a>
                        .
                    </>
                ),
            })
    );
