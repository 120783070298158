import {cs} from "@common/react/chain-services";
import {scope} from "@common/react/scope";
import {Expandable} from "../../../../../../../../../../common/expandable/expandable";
import {StaticTooltipService} from "../../../../../../../../../../common/tooltip3/static-tooltip-service";
import React from "react";
import "./null-blank-handling.scss";
import {RadioLine} from "../../../../../../../../../../common/radio-line/radio-line";
import {spc} from "@common/react/state-path-change";
import {DebounceTextInput} from "../../../../../../../../../../../../../common/form/debounce-input/debounce-text-input";

export const NullBlankHandling = ({field}) =>
    cs(({}) => {
        const configs = [
            {
                name: "Null",
                info: "If there are null values, the following configuration will display to users.",
                prop: "nullReplacementValue",
            },
            {
                name: "Blank",
                info: "If there are blank or empty text values, the following configuration will display to users.",
                prop: "emptyReplacementValue",
            },
        ];

        return configs.map(({name, info, prop}) => (
            <Expandable
                {...{
                    label: `${name} handling`,
                    alwaysShow: true,
                    initExpand: true,
                    iconTooltip: cs(
                        [
                            "staticTooltip",
                            (_, next) =>
                                StaticTooltipService({
                                    direction: "right",
                                    info,
                                    tooltipBoxWidth: 250,
                                    topOffset: -8,
                                    next,
                                }),
                        ],
                        ({staticTooltip}) => (
                            <div className="expandable-tooltip-info-icon">
                                {staticTooltip.renderIcon({
                                    icon: <i className="material-icons">info</i>,
                                    className: "info-tooltip",
                                })}
                            </div>
                        )
                    ),
                    render: () => (
                        <div className="null-blank-handling-0d2">
                            {RadioLine({
                                label: "Don't Change Value",
                                selected: field.value[prop] == null,
                                onClick: () => spc(field, [prop], () => null),
                            })}
                            {RadioLine({
                                label: "Show Custom Value",
                                selected: field.value[prop] != null,
                                onClick: () => spc(field, [prop], (v) => (!v ? name : v)),
                            })}
                            {field.value[prop] != null &&
                                DebounceTextInput({
                                    label: `${name} Display Text`,
                                    state: scope(field, [prop]),
                                })}
                        </div>
                    ),
                }}
            />
        ));
    });
