import "./rename-folder-dialog.scss";

import * as React from "react";

import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {DialogService} from "../../../../../common/dialog/dialog-service";
import {consumeContext} from "@common/react/context";
import {TextInput} from "../../../../../../../../common/form/text-input/text-input";
import {Form2} from "@common/react/cs-form/form2";
import {ffToTextInput} from "../../../../../../../../common/form/ff-to-text-input";
import {Button} from "../../../../../../../../common/form/buttons/button/button";

export const RenameFolderDialog = ({apiType, folders, onDone, next: rootNext}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    render: ({resolve, args: {folder}}) => ({
                        title: "Rename Folder",
                        width: 540,
                        content: next({
                            resolve,
                            folder,
                        }),
                    }),
                    next: rootNext,
                }),
        ],
        consumeContext("apis"),
        [
            "editing",
            ({modal}, next) =>
                UseState({
                    initValue: {
                        name: modal.folder.name,
                    },
                    next,
                }),
        ],
        [
            "form",
            ({editing, modal, apis}, next) =>
                Form2({
                    data: editing,
                    fields: {
                        name: {
                            transforms: ["trim"],
                            asyncValidators: [
                                {
                                    validate: async (name, {data}) => {
                                        if (!name || name.length == 0) {
                                            return false;
                                        }
                                        const newFolderName = name.trim();
                                        const isExistName = folders.find((f) => f.name == newFolderName);
                                        if (isExistName) {
                                            return false;
                                        }

                                        return apis.collection.checkFolderName(name, {
                                            parentFolderId: data?.parentFolderID,
                                            folderId: data?.id,
                                        });
                                    },
                                    getMessage: () => `Existed`,
                                },
                            ],
                        },
                    },
                    onSubmit: async () => {
                        const newFolder = await apis[apiType].upsertFolder({
                            ...modal.folder,
                            name: editing.value.name,
                        });
                        onDone(newFolder);
                        modal.resolve();
                    },
                    next,
                }),
        ],
        ({modal, form}) => (
            <div className="rename-folder-dialog-4ad">
                <div className="main-part dialog-body">
                    {TextInput({
                        label: "Rename Folder",
                        ...ffToTextInput(form.field("name")),
                    })}
                </div>

                <div className="buttons">
                    <Button btnType="secondary" onClick={() => modal.resolve()}>
                        Cancel
                    </Button>
                    <Button disabled={!form.valid} onClick={form.submit}>
                        Rename
                    </Button>
                </div>
            </div>
        )
    );
