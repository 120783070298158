import {getColorConfigs, getFieldColorConfigs} from "../../get-field-color";
import {generateColorScale} from "../../../../utils/color-util";

export const getFunnelSectionColors = ({rawData, tile, theme}) => {
    const {dataColorPalettes} = theme.dataVisualization;
    const colorConfigs = getColorConfigs(tile);
    const {displayColor, type} = getFieldColorConfigs({
        dataColorPalettes,
        colorConfigs,
    });
    const colorScale = generateColorScale(displayColor, type === "Sequential", 0, rawData.series[0].data.length);
    return colorScale.generateAllColors();
};
