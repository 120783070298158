import "./live-grid-panel.scss";
import * as React from "react";
import {css, cx} from "emotion";

import {chain} from "../../../utils/fs";
import {cs} from "../../../react/chain-services";
import {ObserveDomSize} from "../../../react/observe-dom-size";

import {Tiles} from "./tiles/tiles";
import {RejectScrollbarWidth} from "../common/reject-scrollbar-width";
import {responsiveTiles} from "./responsive-tiles/responsive-tiles";
import {gridHeight} from "../../../../web-client/src/routes/collection/common/dnd-grid-panel/grid-constants";
import {getLayoutChartsHeight, getLayoutColumns} from "./utils";
import {consumeContext} from "../../../react/context";

const LayoutSize = ({next: rootNext, spacing}) =>
    cs(
        ["size", ({}, next) => ObserveDomSize({next})],
        [
            "size",
            ({size}) => {
                return (
                    <div className="live-grid-panel-2sz" ref={size.ref}>
                        <div
                            className={cx("extrude-content")}
                            style={{
                                margin: `-${spacing}px`,
                            }}
                        >
                            {size.value &&
                                rootNext({
                                    width: size.value.width + spacing * 2,
                                    height: size.value.height + spacing * 2,
                                })}
                        </div>
                    </div>
                );
            },
        ]
    );

export const LiveGridPanel = ({collection, tiles, spacing: rootSpacing}) =>
    cs(
        consumeContext("dashboardRef"),
        ["spacing", (_, next) => next(collection.$type === "SparkCollection" ? 0 : rootSpacing || 3)],
        [
            "layout",
            ({spacing}, next) =>
                cs(
                    [
                        "size",
                        ({}, next) =>
                            collection.$type === "DashboardCollection"
                                ? LayoutSize({next, spacing, collection})
                                : next({
                                      width: collection.viewWidthPixels,
                                      height: collection.viewHeightPixels,
                                  }),
                    ],
                    ({size}) => RejectScrollbarWidth({size, next})
                ),
        ],
        [
            "gridConfig",
            ({layout, dashboardRef}, next) => {
                const dashboardElem = dashboardRef.get();
                const customWindowWidth = collection.customWindowWidth || dashboardElem?.getBoundingClientRect().width;

                return next({
                    ...getLayoutColumns(layout.width, customWindowWidth),
                    ...getLayoutChartsHeight(dashboardElem),
                });
            },
        ],
        [
            "tiles",
            ({gridConfig}, next) =>
                next(
                    gridConfig.numColumns == null
                        ? tiles
                        : responsiveTiles({
                              tiles,
                              colCount: gridConfig.numColumns,
                          })
                ),
        ],
        ({tiles, gridConfig, spacing}) => {
            const _gridHeight = gridHeight;
            const {blockWidth, availableChartsHeight} = gridConfig;
            const maxTileHeight = !tiles ? 0 : Math.max(...tiles.map((tile) => tile.position.y + tile.size.height));
            const numViewableRows = Math.floor(availableChartsHeight / (_gridHeight + spacing * 2));

            const rows = chain(Math.max(...[maxTileHeight, numViewableRows].filter((v) => !isNaN(v))), (_) => Math.min(50, _));

            return (
                <>
                    <div
                        style={{
                            height: maxTileHeight * (_gridHeight + spacing * 2),
                            position: "relative",
                        }}
                    >
                        {Tiles({
                            tiles,
                            blockWidth,
                            numViewableRows,
                            rows,
                            spacing,
                            gridHeight: _gridHeight,
                        })}
                    </div>
                </>
            );
        }
    );
