import {cs} from "@common/react/chain-services";
import * as React from "react";
import "./connected-tiles.scss";
import {OnOffToggle} from "../../../../../../../../common/form/toggles/on-off-toggle";
import {consumeContext} from "@common/react/context";
import {MpLine} from "../common/mp-line/mp-line";
import {spc} from "@common/react/state-path-change";
import {Load} from "@common/react/load";
import {tooltipService3} from "../../../../../common/tooltip3/tooltip-service-3";
import {keepOnly} from "@common/utils/objects";

export const ConnectedTiles = ({filter}) =>
    cs(
        consumeContext("apis"),
        consumeContext("collection"),
        [
            "tilesValidity",
            ({collection, apis}, next) => {
                if (filter.value.id) {
                    const params = {
                        collectionID: collection.value.id,
                        filterId: filter.value.id,
                    };

                    return Load({
                        _key: JSON.stringify(params),
                        fetch: () => apis.collection.getValidTiles(params),
                        next,
                    });
                }

                const params = {
                    collectionID: collection.value.id,
                    filterColumns: filter.value?.columns?.map((col) => keepOnly(col, ["modelID", "modelTableID", "modelColumnID"])),
                };

                return Load({
                    _key: JSON.stringify(params),
                    fetch: () => (params.filterColumns?.length > 0 ? apis.collection.getValidTilesBasedOnFilterColumns(params) : null),
                    next,
                });
            },
        ],
        tooltipService3(),
        ({collection, tilesValidity, tooltip}) => {
            const tiles = collection.value?.gridLocations?.map((gl) => gl.tile);

            return (
                <div className="connected-tiles-2ws">
                    <div className="label">Connected Tiles</div>
                    <div className="sub-label">Select the tiles in this collection to update when this filter is changed.</div>
                    <div className="content">
                        <div className="set-all">
                            {MpLine({
                                left: OnOffToggle({
                                    ...(!tilesValidity?.validTileIDs?.length
                                        ? {
                                              disabled: true,
                                              state: {value: false},
                                          }
                                        : {
                                              state: (() => {
                                                  const isDisconnected = (tile) => filter.value.disconnectedTiles?.includes(tile.id);
                                                  const disconnectedTiles = tiles.filter(isDisconnected);

                                                  return {
                                                      value: !tilesValidity?.validTileIDs?.length
                                                          ? false
                                                          : disconnectedTiles.length === 0
                                                          ? true
                                                          : disconnectedTiles.length === tilesValidity.validTileIDs?.length
                                                          ? false
                                                          : null,
                                                      onChange: () =>
                                                          tilesValidity &&
                                                          tilesValidity.validTileIDs &&
                                                          spc(filter, ["disconnectedTiles"], () => (disconnectedTiles.length ? [] : tilesValidity.validTileIDs)),
                                                  };
                                              })(),
                                          }),
                                    allowUndefined: true,
                                }),
                                right: "Set all",
                            })}
                        </div>

                        <div className="tiles">
                            {tiles?.map((tile) => {
                                const rTile = (tile, rootTile) => (
                                    <div className="tile" key={tile.id}>
                                        {MpLine({
                                            left: OnOffToggle({
                                                ...(!tilesValidity?.validTileIDs?.includes(tile.id)
                                                    ? {
                                                          disabled: true,
                                                          state: {value: false},
                                                      }
                                                    : {
                                                          state: (() => {
                                                              const disconnected = filter.value.disconnectedTiles?.includes(tile.id);
                                                              return {
                                                                  value: !disconnected,
                                                                  onChange: () =>
                                                                      spc(filter, ["disconnectedTiles"], (dts) =>
                                                                          disconnected ? dts.filter((dt) => dt !== tile.id) : [...(dts || []), tile.id]
                                                                      ),
                                                              };
                                                          })(),
                                                      }),
                                            }),
                                            right: (
                                                <div className="tile-info">
                                                    {/* TODO Icons for each tile type */}
                                                    {/*<img src={require("./donut-icon.svg")} className="chart" />*/}

                                                    <div className="text">
                                                        {rootTile ? `${rootTile.title} - ` : ``}
                                                        {tile.title}
                                                    </div>

                                                    {(() => {
                                                        const invalidity = tilesValidity?.invalidTiles?.find((t) => t.id === tile.id);
                                                        return (
                                                            invalidity && (
                                                                <div
                                                                    {...{
                                                                        className: "invalid-tile-warning",
                                                                        ...tooltip(() => invalidity.reason),
                                                                    }}
                                                                >
                                                                    <img src={require("./warning.svg")} alt="" />
                                                                </div>
                                                            )
                                                        );
                                                    })()}
                                                </div>
                                            ),
                                        })}
                                    </div>
                                );
                                if (tile.tiles) {
                                    return tile.tiles.map((tile1) => rTile(tile1, tile));
                                }
                                return rTile(tile);
                            })}
                        </div>
                    </div>
                </div>
            );
        }
    );
