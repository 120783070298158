const {adjustBarDataLabels} = require("../../vertical-bar/chart-options/adjust-bar-data-labels");
const {drawRoundCorners} = require("../../bar-series-processors/draw-round-corners");
const {hideOverflowTickLabel} = require("../../vertical-bar/chart-options/hide-overflow-tick-label");
const {adjustComparisonBars} = require("../../vertical-bar/chart-options/adjust-comparison-bars");
const {initComparisonBarsHelper} = require("../../vertical-bar/chart-options/draw-comparison-bars");
const {hideOriginYaxisGridLine} = require("./hide-origin-yaxis-grid-line");
const {getTooltipOptions} = require("../../factory/tooltip/tooltip-options");
const {getLegendOptions} = require("../../factory/legend");
const {getPlotOptions} = require("./plot-options");
const {hideHorizontalScrollbar} = require("../../vertical-bar/chart-options/hide-horizontal-scrollbar");
const {fixDimAxisLabel} = require("../../vertical-bar/chart-options/fix-dim-axis-label");
const {fixOverlappingXAxisGridLabels} = require("../../vertical-bar/chart-options/fix-overlapping-x-axis-grid-labels");
const {SERIES_BOOST_THRESHOLD} = require("../../common/axis-chart-boost-turbo-constants");

const getComboChartOptions = ({tile, theme, isCompare, rawData, barStack, isDimensionRealDate, formatters, onClickPoint, selectedTimezone}) => {
    const chartEventsFn = (chart) => {
        !tile.style.xAxis.lineShown && hideOriginYaxisGridLine(chart);
        isCompare && tile.style.yAxisBarDisplayType && tile.style.yAxisBarDisplayType !== "Classic" && adjustComparisonBars({chart, barStack});
        tile.style.yAxisBarDataLabels.show &&
            adjustBarDataLabels({
                chart,
                position: tile.style.yAxisBarDataLabels.position,
            });

        if (tile.style.xAxis.maxValuesInView !== "AutoMax" && tile.yAxisBarFields?.length) {
            // logic for applying scrolling on combo chart is in add-scrolling-options.js
            hideHorizontalScrollbar(chart);
            tile.style.xAxis.labelShown && tile.style.xAxis.label && fixDimAxisLabel(chart);
        }

        if (
            isDimensionRealDate &&
            tile.style.xAxis.gridStepLabelsShown
            // && !tile.style.xAxis.gridStepType // auto grid step (no gridStepType on xAxis since #1003)
        ) {
            hideOverflowTickLabel(chart);
            // when maxValuesInView is AutoMax, all grid labels are shown.
            // overlapping x-axis grid labels often happen on real date dim, so put it here
            tile.style.xAxis.gridStepLabelsShown && tile.style.xAxis.maxValuesInView === "AutoMax" && fixOverlappingXAxisGridLabels(chart);
        }
    };

    const chart = {
        marginTop: 20,
        customType: "ComboChartTile",
        alignTicks: false, // for grid step of bar y-axis and line y-axis not to be aligned
        backgroundColor: theme.general.tile.styles.tileBackgroundColorRGB,
        events: {
            load: function () {
                const chart = this;
                chartEventsFn(chart);

                if (isCompare && (!tile.style.yAxisBarDisplayType || tile.style.yAxisBarDisplayType === "Classic")) {
                    chart.comparisonBars = initComparisonBarsHelper({
                        compareSeries: rawData.series.filter((s) => s.isCompare),
                        ...(tile.style.multipleAxisBarOption !== "IndependentAxes" && {
                            barStack,
                        }),
                        tile,
                    });

                    chart.comparisonBars.render({chart});
                }
            },
            redraw: function () {
                const chart = this;
                chartEventsFn(chart);

                if (chart.comparisonBars) {
                    chart.comparisonBars.rerender({chart});
                }
            },
            render: function () {
                drawRoundCorners.apply(this);
            },
        },
        zooming: {
            mouseWheel: false,
        },
    };

    const title = {
        text: undefined,
    };

    const tooltip = getTooltipOptions({
        tile,
        theme,
        isCompare,
        formatters,
        rawData,
    });

    const plotOptions = getPlotOptions({
        tile,
        theme,
        isCompare,
        formatters,
        onClickPoint,
    });

    const legend = getLegendOptions({style: tile.style.legendStyle, theme});

    return {
        boost: {
            enabled: true,
            seriesThreshold: SERIES_BOOST_THRESHOLD,
            useGPUTranslations: true,
            usePreAllocated: true,
        },
        chart,
        title,
        plotOptions,
        tooltip,
        legend,
        time: {
            timezone: selectedTimezone.value,
            useUTC: !selectedTimezone.value,
        },
        credits: {
            enabled: false,
        },
    };
};
exports.getComboChartOptions = getComboChartOptions;
