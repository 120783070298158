import "./select-tree-folders-item.scss";

import {css, cx} from "emotion";
import * as React from "react";

import {SearchInput} from "@common/form/search-input/search-input";
import {cs} from "@common/react/chain-services";
import {keyed} from "@common/react/keyed";
import {UseState} from "@common/react/use-state";

import {flatten1, last, sort} from "@common/utils/collections";
import {Dropdown} from "@common/ui-components/dropdown/dropdown";
import {Checkbox} from "@common/ui-components/form/checkbox/checkbox";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";

import FolderIcon from "assets/icons/common/FolderIcon";
import {TruncatingTooltip} from "../../../../../common/truncating-tooltip/truncating-tooltip";
import {isMatchText} from "@common/utils/strings";
import {LoadingIndicator} from "@common/ui-components/loading-indicator/loading-indicator";

export const SelectTreeFoldersItem = ({
    list,
    displayValue = null,
    onChange,
    isSelected,
    hasError,
    domRef,
    className,
    forcedExpandBottom,
    info,
    valueItemToLabel,
    unSelectAll,
    sortBy,
}) =>
    cs(
        ["chosenIndex", (_, next) => next(!isSelected ? -1 : list?.findIndex(isSelected))],
        ({}, next) => (
            <div
                className={cx("select-tree-folders-item select-tree-folders-item-5gy", className, {"has-info": info}, {hasError})}
                ref={domRef}
            >
                {next()}
            </div>
        ),
        ({chosenIndex}) =>
            Dropdown({
                forcedExpandBottom,
                minExpandHeight: 350,
                renderToggle: ({showExpand, showingExpand}) => (
                    <div
                        className={cx("toggle", {
                            expanding: showingExpand,
                            "with-value": chosenIndex > -1,
                        })}
                        onClick={() => showExpand(!showingExpand)}
                    >
                        {displayValue && (
                            <div className="value">
                                {displayValue}&nbsp;
                                <span
                                    className="clear-icon"
                                    onMouseDown={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}
                                    onClick={() => unSelectAll()}
                                >
                                    <i className="fal fa-times" />
                                </span>
                            </div>
                        )}

                        <div className="placeholder">Filter by Collection</div>

                        <i className="fa fa-chevron-down" />
                    </div>
                ),
                renderExpand: ({close, width}) =>
                    cs(
                        ["search", (_, next) => UseState({next})],
                        [
                            "stack",
                            (_, next) =>
                                UseState({
                                    initValue: [{id: null, name: null}],
                                    next,
                                }),
                        ],
                        ({search, stack}) => {
                            const allCollections = list
                                ? flatten1(
                                      list.map((l) =>
                                          l.collections.map((c) => ({
                                              ...c,
                                              folderName: l.name,
                                          }))
                                      )
                                  )
                                : null;

                            const rLevel = () => {
                                const parentFolder = last(stack.value);

                                let folders = list
                                    .filter((item) => item.parentFolderID === parentFolder.id && item.id !== null)
                                    .filter(
                                        (item) => item.collections.length > 0 || list.findIndex((l) => l.parentFolderID === item.id) > -1
                                    );

                                let collections = list.find((item) => item.id === parentFolder.id).collections;

                                if (sortBy) {
                                    folders = folders.sort(sortBy);
                                    collections = collections.sort(sortBy);
                                }

                                return (
                                    <>
                                        {parentFolder.id && (
                                            <div
                                                className="back-to-folders"
                                                onClick={() => stack.change((old) => old.filter((o) => o.id !== parentFolder.id))}
                                            >
                                                <i className="far fa-arrow-left" />
                                                <div className="text">{parentFolder.name}</div>
                                            </div>
                                        )}

                                        {folders.map((f) =>
                                            cs(
                                                [
                                                    "truncatingTooltip",
                                                    (_, next) =>
                                                        TruncatingTooltip({
                                                            delay: 500,
                                                            forceUpdate: true,
                                                            className: "field-control-tooltip",
                                                            next,
                                                        }),
                                                ],
                                                ({truncatingTooltip}) => (
                                                    <div
                                                        key={f.id}
                                                        className="item folder"
                                                        onClick={() =>
                                                            stack.change((old) => [
                                                                ...old,
                                                                {
                                                                    id: f.id,
                                                                    name: f.name,
                                                                },
                                                            ])
                                                        }
                                                        {...truncatingTooltip?.tooltip(f.name)}
                                                    >
                                                        {FolderIcon({})}
                                                        <div className="text" ref={truncatingTooltip?.ref}>
                                                            {f.name}
                                                        </div>
                                                        <i className="far fa-angle-right" />
                                                    </div>
                                                )
                                            )
                                        )}

                                        {sort(collections, (c) => (isSelected(c) ? 0 : 1)).map((collection, i) =>
                                            cs(
                                                keyed(i),
                                                [
                                                    "truncatingTooltip",
                                                    (_, next) =>
                                                        TruncatingTooltip({
                                                            delay: 500,
                                                            forceUpdate: true,
                                                            className: "field-control-tooltip",
                                                            // className: 'select-tree-tooltip',
                                                            next,
                                                        }),
                                                ],
                                                ({truncatingTooltip}) => (
                                                    <div
                                                        className="item"
                                                        onMouseDown={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            onChange(collection, !isSelected(collection));
                                                        }}
                                                    >
                                                        <Checkbox
                                                            state={{
                                                                value: isSelected(collection),
                                                                onChange: () => null,
                                                            }}
                                                            readOnly
                                                        />
                                                        <div
                                                            className="text"
                                                            {...{
                                                                ref: truncatingTooltip?.ref,
                                                                ...truncatingTooltip?.tooltip(valueItemToLabel?.(collection)),
                                                            }}
                                                        >
                                                            {valueItemToLabel?.(collection)}
                                                        </div>
                                                    </div>
                                                )
                                            )
                                        )}
                                    </>
                                );
                            };

                            const rSearchedItems = () => {
                                let searchedList = allCollections.filter((c) => isMatchText(c.name, search.value));

                                if (searchedList.length === 0) {
                                    return <div className="no-result">No result found</div>;
                                }

                                if (sortBy) {
                                    searchedList = searchedList.sort(sortBy);
                                }

                                searchedList = sort(searchedList, (c) => (isSelected(c) ? 0 : 1));

                                return searchedList.map((item, i) =>
                                    cs(
                                        [
                                            "truncatingTooltip",
                                            (_, next) =>
                                                TruncatingTooltip({
                                                    delay: 500,
                                                    forceUpdate: true,
                                                    className: "field-control-tooltip",
                                                    // className: 'select-tree-tooltip',
                                                    next,
                                                }),
                                        ],
                                        ({truncatingTooltip}) => (
                                            <div
                                                className="item search"
                                                key={i}
                                                onMouseDown={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();

                                                    onChange(item, !isSelected(item));
                                                }}
                                            >
                                                <Checkbox
                                                    state={{
                                                        value: isSelected(item),
                                                        onChange: () => {},
                                                    }}
                                                    readOnly
                                                />
                                                <div
                                                    className="text"
                                                    {...truncatingTooltip?.tooltip(
                                                        <>
                                                            <div className="main">Collection: {item.name}</div>
                                                            <div className="sub-text">Folder: {item.folderName}</div>
                                                        </>
                                                    )}
                                                >
                                                    <div className="main" ref={truncatingTooltip?.ref}>
                                                        {item.name}
                                                    </div>
                                                    <div className="sub-text">{item.folderName}</div>
                                                </div>
                                            </div>
                                        )
                                    )
                                );
                            };

                            return (
                                <>
                                    {SearchInput({
                                        state: search,
                                        placeholder: "Search for collections",
                                        delay: 300,
                                        variant: "secondary",
                                    })}
                                    <VerbScrollbar className="list" maxHeight="360px">
                                        {list ? (
                                            search.value ? (
                                                rSearchedItems()
                                            ) : (
                                                rLevel()
                                            )
                                        ) : (
                                            <LoadingIndicator
                                                className={cx(
                                                    css`
                                                        display: flex;
                                                        align-items: center;
                                                        height: 45px;
                                                    `
                                                )}
                                            />
                                        )}
                                    </VerbScrollbar>
                                </>
                            );
                        }
                    ),
            })
    );
