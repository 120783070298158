import "./preview-table.scss";

import {cx} from "emotion";
import * as React from "react";

import {Load2} from "@common/react/load2";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";

import {keepOnly} from "@common/utils/objects";

import {getColumns} from "../../table/table/common/get-columns";
import {LoadingIndicator} from "../../../loading-indicator/loading-indicator";
import {ErrorHandler} from "../../common/loading-wrapper/error-handler/error-handler";

export const PreviewTable = ({tile, isDownloadReportTile = false, loadData, tileFilters = {}, size}) =>
    cs(
        consumeContext("selectedTimezone"),
        [
            "tileData",
            ({selectedTimezone}, next) =>
                Load2({
                    // keepOutdatedValue: true,
                    disabled: tileFilters.invalid,
                    captureException: true,
                    _key: JSON.stringify({
                        tileFilters: {
                            key: tileFilters.key,
                        },
                        tileKey: getTileKey({tile}),
                        apiKey: loadData.key,
                    }),
                    fetch: () =>
                        loadData.load({
                            apiKey: loadData.key,
                            filters: tileFilters.getValue(),
                            extraCacheKey: getTileKey({tile}),
                            page: 0,
                            timeZoneId: selectedTimezone?.value,
                        }),
                    next,
                }),
        ],
        ({tileData, selectedTimezone}) => {
            const data = tileData.value;
            const error = tileData.error;
            const columnsInfo = getColumns({
                tile,
                timezone: selectedTimezone?.value,
            });

            if (error) {
                return (
                    <div className="preview-table-86d">
                        <div className="error-panel">
                            {ErrorHandler({
                                error,
                                size: {width: 10, height: 10},
                            })}
                        </div>
                    </div>
                );
            }

            return (
                <div className={cx("preview-table-86d", isDownloadReportTile && "is-download-report-tile")}>
                    {!data || !data.rows || !data.columns || tileData.loading ? (
                        LoadingIndicator()
                    ) : (
                        <>
                            <div className="first-100-rows">First 100 rows</div>
                            <div className="table-container">
                                <table>
                                    <thead>
                                        <tr>
                                            {data.columns.map((c, i) => (
                                                <th key={i}>{c.name}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.rows.slice(0, 100).map((row, i) => (
                                            <tr key={i}>
                                                {row.columns.map((c, j) => (
                                                    <td key={j} className={columnsInfo[j].type}>
                                                        {columnsInfo[j].formatter(c.value ?? c)}
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </>
                    )}
                </div>
            );
        }
    );

const getTileKey = ({tile}) =>
    JSON.stringify([
        tile.filters,
        [...(tile.actualValueFields || []), ...(tile.aggregatedValues || [])].map((f) => ({
            id: f.id,
            dateAggregation: f.dateProperties?.aggregation,
            ...keepOnly(f, ["sort", "limit", "sortDirection", "aggregationFunc", "displayName"]),
        })),
    ]);
