import "./login-route.scss";

import * as React from "react";

import {keyed} from "@common/react/keyed";
import {scope} from "@common/react/scope";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {Form2} from "@common/react/cs-form/form2";
import {spc} from "@common/react/state-path-change";
import {consumeContext} from "@common/react/context";
import {email} from "@common/react/cs-form/validators/email";
import {required} from "@common/react/cs-form/validators/required";
import {minLength} from "@common/react/cs-form/validators/min-length";

import {CheckIconRed} from "@common/ui-components/icons/global-icons";

import {equalDeep} from "@common/utils/objects";
import {TextInput} from "../../../../../common/form/text-input/text-input";
import {ffToBasicInput} from "../../../../../common/form/ff-to-basic-input";

import {fetchUser} from "../../../apis/user-apis/fetch-user";
import {billingHost} from "../../account-management/common/org-billing-constant";
import {GuestLayout} from "../common/layout/guest-layout";

import {SelectTenantDialog} from "./select-tenant-dialog/select-tenant-dialog";
import {CheckboxLine} from "../../common/checkbox-line/checkbox-line";
import {LoginSSO} from "./login-sso";
import {cx} from "emotion";

const {CookieStorage} = require("../../../../../common/logic/cookie-storage");

// "email": "oleg@revivemedia.us",
// "email": "owebtech+t-4938824209-full@gmail.com",
// "email": "owebtech+t-4475318254-full@gmail.com",
// "email": "owebtech+t-4808593818-full@gmail.com", // TenantID: aa15d315-13f5-40aa-99ca-71998712bc78

const errors = {
    subscription_canceled: "Subscription Canceled",
    invalid_credentials: "Invalid user name or password",
    network: "Error connecting to Verb server",
    invalid_code: "Invalid Code",
    expired_code: "Expired Code",
};

const messages = {
    resetPasswordSuccess: "Your password has been changed successfully. Please use the new password to login.",
};

export const LoginRoute = ({setIntendedRoute}) =>
    cs(
        consumeContext("apis"),
        consumeContext("auth"),
        consumeContext("routing"),
        [
            "state",
            ({routing}, next) =>
                UseState({
                    getInitValue: () => ({
                        message: routing.params.message,
                        loading: false,
                        code: null,
                        requiredMFA: false,
                        mfaType: "AuthenticatorApp", // AuthenticatorApp
                    }),
                    next,
                }),
        ],
        [
            "params",
            ({routing}, next) =>
                UseState({
                    getInitValue: () => ({
                        email: routing.params.email,
                        rememberMe: false,
                        ...require("./login-prefill"),
                    }),
                    next,
                }),
        ],

        ["selectTenantDialog", ({}, next) => SelectTenantDialog({next})],

        [
            "handleLoginStatus",
            ({state, selectTenantDialog, auth, routing}, next) =>
                next(async (newAuth) => {
                    const {loginStatus} = newAuth;

                    const maps = {
                        Success: async () => {
                            const access = CookieStorage.get("verb.auth.access");
                            const user1 = CookieStorage.get("verb.user");
                            // CookieStorage.set('verb:first-time-login', true);

                            if (user1.temp) {
                                window.location.replace(billingHost);
                            }

                            if (routing.params.intendedRoute) {
                                setIntendedRoute(routing.params.intendedRoute);
                            } else {
                                setIntendedRoute(null);
                            }

                            let user = await getUser();
                            auth.setAuth({user, access, tenantId: user?.tenantID});
                            if (user?.tenantID) {
                                CookieStorage.set("verb.admin.tenantid", user?.tenantID);
                            }

                            // if (equalDeep(user1.roles, ["Verb"])) {
                            //     const [_user, tenantId] = await Promise.all([getUser(), selectTenantDialog.show()]);
                            //
                            //     user = _user;
                            //
                            //     if (tenantId) {
                            //         auth.setAuth({user, tenantId, access});
                            //         CookieStorage.set("verb.admin.tenantid", tenantId.toString());
                            //     } else {
                            //         spc(state, ["loading"], () => false);
                            //     }
                            // } else {
                            // }
                        },

                        SubscriptionCanceled: async () => {
                            state.change((_state) => ({
                                ..._state,
                                loading: false,
                                error: "subscription_canceled",
                            }));
                        },

                        Requires2FA: async () => {
                            state.change((_state) => ({
                                ..._state,
                                loading: false,
                                code: null,
                                requiredMFA: true,
                                mfaType: "AuthenticatorApp", // AuthenticatorApp
                                last4Phone: newAuth.loginInfo.last4Phone,
                                tenantName: newAuth.loginInfo.tenantName,
                            }));
                        },

                        default: async () => {
                            state.change((_state) => ({
                                ..._state,
                                loading: false,
                                error: "invalid_credentials",
                            }));
                        },
                    };

                    const method = Object.keys(maps).includes(loginStatus) ? loginStatus : "default";

                    return await maps[method]();
                }),
        ],

        [
            "form",
            ({params, state, auth, apis, selectTenantDialog, routing, handleLoginStatus}, next) =>
                Form2({
                    fields: {
                        email: [required, email],
                        password: [required],
                        code: state.value?.requiredMFA ? [required, minLength(6)] : [],
                    },
                    data: params,
                    onSubmit: async () => {
                        state.change((_state) => ({
                            ..._state,
                            error: null,
                            loading: true,
                        }));

                        try {
                            const auth1 = await apis.login(params.value);
                            await handleLoginStatus(auth1);
                        } catch (e) {
                            //console.log(e);
                            state.change((_state) => ({
                                ..._state,
                                loading: false,
                                error: "network",
                            }));
                        }
                    },
                    next,
                }),
        ],

        [
            "renderOverride",
            ({state, apis, form, routing, params, handleLoginStatus}, next) => {
                const {email, rememberMe} = params.value;
                const {mfaType, requiredMFA, isLostDevice, error, message, loading, last4Phone = "0000", tenantName, lastSentSMSAt} = state.value ?? {};

                const doMFASignIn = async () => {
                    const code = form.field("code").state.value;
                    if (loading || !code || code.length < 6) {
                        return;
                    }

                    try {
                        state.change((_state) => ({
                            ..._state,
                            loading: true,
                        }));
                        const resp = await apis.mfaSignIn(form.field("code").state.value, rememberMe);
                        await handleLoginStatus(resp);
                    } catch (e) {
                        state.change((_state) => ({
                            ..._state,
                            loading: false,
                            error: mfaType == "SMS" && Date.now() - lastSentSMSAt >= 3 * 60 * 100 ? "expired_code" : "invalid_code",
                        }));
                    }
                };

                const sendMail = async () => {
                    try {
                        if (!email) {
                            return;
                        }
                        state.change((_state) => ({
                            ..._state,
                            loading: true,
                            error: null,
                            message: null,
                        }));

                        const resp = await apis.sendRecoveryEmail(email);

                        state.change((_state) => ({
                            ..._state,
                            loading: false,
                            error: !resp ? "Can not send the email" : null,
                            message: resp ? "Sent email" : null,
                        }));
                    } catch (e) {
                        state.change((_state) => ({
                            ..._state,
                            loading: false,
                            error: "Can not send the email",
                        }));
                    }
                };

                const switchMethod = async (mfaType = "AuthenticatorApp") => {
                    if (mfaType == "SMS" && !last4Phone) {
                        return;
                    }

                    if (loading) {
                        return;
                    }

                    try {
                        state.change((_state) => ({
                            ..._state,
                            loading: true,
                            error: null,
                            code: null,
                            mfaType,
                            lastSentSMSAt: mfaType == "SMS" ? Date.now() : null,
                        }));
                        await apis.login({
                            ...params.value,
                            mfaType,
                        });
                        state.change((_state) => ({
                            ..._state,
                            loading: false,
                        }));
                    } catch (e) {
                        state.change((_state) => ({
                            ..._state,
                            loading: false,
                            error: mfaType == "SMS" ? "Can not send SMS code." : e.message,
                        }));
                    }
                };

                const onKeyDown = async (e) => {
                    if (!loading && e.key === "Enter") {
                        await doMFASignIn();
                    }
                };

                const doBack = (prop, value) => {
                    state.change((_state) => ({
                        ..._state,
                        [prop]: value,
                        loading: false,
                        error: null,
                        code: null,
                        message: null,
                    }));
                };

                if (!requiredMFA && !isLostDevice) {
                    return next();
                }

                if (isLostDevice) {
                    return next(() => (
                        <>
                            <a className="back-to-login" onClick={() => doBack("isLostDevice", false)}>
                                Back
                            </a>
                            <div className="header small">Lost Authentication Device</div>

                            <div className="page-description text-left">
                                We'll send an email to the other administrators on the {tenantName} account with instructions to disable your MFA.
                                <br />
                                <br />
                                You may also reach out to them directly.
                            </div>

                            {error ? <div className="error">{error}</div> : message && <div className="message">{message}</div>}

                            {!message && (
                                <div className="controls">
                                    <button disabled={loading} onClick={sendMail}>
                                        Send Email
                                    </button>
                                </div>
                            )}

                            <div className="links">
                                <span className="text">Having Trouble?</span>
                                <br />
                                <a href="mailto:support@revivemedia.us">Contact Support</a>
                            </div>
                        </>
                    ));
                }

                if (requiredMFA) {
                    return next(() => (
                        <>
                            <a className="back-to-login" onClick={() => doBack("requiredMFA", false)}>
                                Back
                            </a>
                            <div className="header small">Authentication Code</div>

                            <div className="page-description">
                                {mfaType == "AuthenticatorApp"
                                    ? "Enter the code from your authenticator app"
                                    : `Enter the code we sent to the phone number ending in * ${last4Phone} *`}
                            </div>

                            {error ? <div className="error">{errors[error] ?? error}</div> : message && <div className="message">{message}</div>}

                            <div className="form">
                                {TextInput({
                                    label: "Enter Code",
                                    ...ffToBasicInput(form.field("code")),
                                    onKeyDown,
                                    maxLength: 6,
                                    icon: null,
                                })}

                                {CheckboxLine({
                                    label: "Remember me for 30 days",
                                    state: scope(params, ["rememberMe"]),
                                })}
                            </div>

                            <div className="controls">
                                <button disabled={loading || !form.valid} onClick={doMFASignIn}>
                                    Log in
                                </button>
                            </div>

                            <div className="links">
                                <span className="text">Having Trouble?</span>
                                <br />
                                <a onClick={() => spc(state, ["isLostDevice"], () => true)}>Lost your authentication device?</a>
                            </div>
                        </>
                    ));
                }

                return next();
            },
        ],

        ({state, form, routing, params, renderOverride}) => {
            const {error, message, loading} = state.value ?? {};

            return GuestLayout({
                header: "Login",
                className: "login-route-3d9",
                error: error && errors[error],
                message: message && messages[message],
                // renderOverride,
                renderOverride: ({}) => (
                    <>
                        <div className={cx("header")}>Login</div>
                        {LoginSSO()}
                    </>
                ),
                // form: [
                //     {
                //         label: "Email Address",
                //         field: form.field("email"),
                //     },
                //     {
                //         label: "Password",
                //         field: form.field("password"),
                //         type: "password",
                //     },
                // ],
                onSubmit: form.submit,
                // link: {
                //     label: "Forgot password?",
                //     to: routing.toPath("forgot-password", {
                //         email: params.value?.email,
                //     }),
                // },
                // otherLink: {
                //     label: "Sign up for Verb",
                //     to: "https://www.revivemedia.us/pricing",
                //     text: "Don't have an account?",
                // },
                submitBtn: {
                    label: "Sign In",
                    disabled: loading,
                },
                rightLabel: "Dashboards sell SaaS!",
                rightContent: (() => {
                    const list = [
                        {
                            label: "Prove ROI",
                            content: "Showing customers their data when they want it shows them the value your platform provides.",
                        },
                        {
                            label: "Retention",
                            content: "Keep everyone, not just your power users coming back for more with great data experiences.",
                        },
                        {
                            label: "Sales",
                            content: "Your sales team is going to love you for giving them a killer feature to help them sell.",
                        },
                    ];

                    return (
                        <div className="list">
                            {list.map((item, index) =>
                                cs(keyed(index), () => (
                                    <div className="item">
                                        <div>
                                            <CheckIconRed />
                                        </div>
                                        <div className="text">
                                            <div className="strong">{item.label}</div>
                                            {item.content}
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    );
                })(),
            });
        }
    );

export const getUser = async (auth) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: "login",
    });
    return await (await fetchUser(auth)).json();
};
