import {cs} from "../../../../react/chain-services";
import {consumeContext} from "../../../../react/context";
import {UseState} from "../../../../react/use-state";
import {Watch} from "../../../../react/watch";
import {applyControlFilterForChart, applyControlFilterForMapTile} from "./control-filter-common";

export const ControlFilterWrapper = ({children, series, tile, dimensionAxisType}) =>
    cs(
        consumeContext("tileActionControlFilter"),
        consumeContext("theme"),
        ["state", (_, next) => UseState({next, initValue: series})],
        ({state, tileActionControlFilter}, next) =>
            Watch({
                next,
                value: JSON.stringify({
                    tileID: tileActionControlFilter?.value?.tile?.id,
                    series,
                }),
                onChanged: (updated) => {
                    const {tileID, series} = JSON.parse(updated);
                    if (tileID != tile.id) state.onChange(series);
                },
            }),
        ({tileActionControlFilter, state, theme}) => {
            const applyFunc = tile.$type == "MapTile" ? applyControlFilterForMapTile : applyControlFilterForChart;

            return children(
                applyFunc({
                    $type: tile.$type,
                    series: JSON.parse(JSON.stringify(state?.value)),
                    action: tileActionControlFilter?.value?.action,
                    emptyColor: theme?.dataVisualization?.dataColorPalettes.otherColors.emptyFillColorRGB,
                    dimensionAxisType,
                    isApplyForChart: tileActionControlFilter?.value?.tile?.id === tile.id,
                })
            );
        }
    );
