import {cs} from "@common/react/chain-services";
import {ButtonDropdown} from "../../../data-source/common/button-dropdown/button-dropdown";
import {NewFolderIcon, PlusIcon} from "@common/ui-components/icons/global-icons";
import React from "react";
import {CreateSharedTileDialog} from "./cm/create/create-shared-tile-dialog";
import ViewCollections from "../../../collection/shared-tile/view-collections/view-collections";
import {UseState} from "@common/react/use-state";
import {Load2} from "@common/react/load2";
import {consumeContext} from "@common/react/context";
import {LoadingIndicator} from "@common/ui-components/loading-indicator/loading-indicator";
import {RecursiveTable} from "../../../common/recursive-table/recursive-table";
import {buildFoldersTree} from "../collections/folders-tree-structure";
import {isBlank} from "@common/utils/strings";
import {cx} from "emotion";
import {createDateFormatter} from "@common/ui-components/charts/common/formatters/date-formatter";
import {noun} from "@common/utils/plural";
import FolderOpenIcon from "../../../../assets/icons/common/FolderOpenIcon";
import FolderIcon from "../../../../assets/icons/common/FolderIcon";
import {CreateFolderDialog} from "../collections/folder-cm/create-folder/create-folder-dialog";
import {cSsJson} from "@common/react/ss-json";
import {SearchInputBar} from "@common/ui-components/search-input-bar/search-input-bar";
import {SharedTilesCm} from "./cm/shared-tiles-cm";
import {MoveToDialog} from "../common/move-to-dialog/move-to-dialog";
import {tileTypes} from "../../../collection/common/select-tile-type/tile-types";
import "./shared-tiles-dashboard.scss";
import {MultiSelect} from "../../common/multi-select";
import {DeleteSharedTileDialog} from "./cm/delete/delete-shared-tile-dialog";
import {recentStorage} from "../recent-section/recent-storage";
import {FolderCM} from "../collections/folder-cm/folder-cm";

export const loadSharedTileDashboardActions = ({next}) =>
    cs(
        consumeContext("apis"),
        consumeContext("routing"),
        ["interactions", (_, next) => UseState({initValue: null, next})],

        [
            "folders",
            ({apis, routing}, next) =>
                Load2({
                    _key: routing.params?.envId,
                    fetch: () => apis.collectionTiles.getFolders(),
                    next,
                }),
        ],
        [
            "moveSharedTileDialog",
            ({onUpdateFolders, folders}, next) =>
                MoveToDialog({
                    apiType: "collectionTiles",
                    folders: folders.value,
                    onDone: () => folders.reload(),
                    next,
                }),
        ],
        [
            "deleteSharedTileDialog",
            ({folders}, next) =>
                DeleteSharedTileDialog({
                    onDone: () => folders.reload(),
                    reloadFolderOnDone: true,
                    next,
                }),
        ],
        [
            "sharedTileCm",
            ({folders, moveSharedTileDialog, deleteSharedTileDialog, interactions}, next) =>
                SharedTilesCm({
                    folders,
                    moveSharedTileDialog,
                    deleteSharedTileDialog,
                    interactions,
                    next,
                }),
        ],
        ({folders, moveSharedTileDialog, deleteSharedTileDialog, interactions, sharedTileCm}) =>
            next({folders, moveSharedTileDialog, deleteSharedTileDialog, interactions, sharedTileCm})
    );

export const SharedTilesDashboard = ({next}) =>
    cs(
        consumeContext("routing"),
        ["sharedTileActions", (_, next) => loadSharedTileDashboardActions({next})],
        ["keyword", (_, next) => UseState({initValue: cSsJson("SEARCH_VALUE").get()?.collectionTiles || "", next})],
        [
            "multiSelectCtrl",
            ({sharedTileActions: {moveSharedTileDialog, deleteSharedTileDialog}}, next) =>
                MultiSelect({
                    next,
                    itemName: "Tile",
                    resolveItemKey: (item) => `${item.type}_${item.id}`,
                    actions: [
                        {
                            label: "Move To",
                            isShow: () => true,
                            onClick: async (selectedItems) => {
                                const updateItems = Object.values(selectedItems);
                                return await moveSharedTileDialog.show({
                                    isUpdateMany: true,
                                    label: `${updateItems.length} ${noun(updateItems.length, "Shared Tile")}`,
                                    params: updateItems,
                                });
                            },
                        },
                        {
                            label: "Delete",
                            isShow: () => true,
                            onClick: async (selectedItems) => {
                                const updateItems = Object.values(selectedItems);
                                return await deleteSharedTileDialog.show({
                                    tiles: updateItems,
                                });
                            },
                        },
                    ],
                }),
        ],
        ({sharedTileActions: {interactions, sharedTileCm, folders}, routing, keyword, multiSelectCtrl}) => {
            return next({
                actions: cs(
                    [
                        "createSharedTileDialog",
                        (_, next) =>
                            CreateSharedTileDialog({
                                folders: folders.value,
                                next,
                            }),
                    ],
                    [
                        "createFolderDialog",
                        (_, next) =>
                            CreateFolderDialog({
                                apiType: "collectionTiles",
                                folders: folders.value,
                                onCreate: (newFolder) => folders.onChange([...(folders.value || []), newFolder]),
                                next,
                            }),
                    ],
                    ({createSharedTileDialog, createFolderDialog}) => (
                        <div className="collections-dashboard-actions-98y">
                            {SearchInputBar({state: keyword, type: "collectionTiles"})}

                            {ButtonDropdown({
                                label: "New",
                                list: [
                                    {
                                        value: 0,
                                        label: (
                                            <>
                                                <PlusIcon fill="#919EB0" /> Add New Shared Tile
                                            </>
                                        ),
                                    },
                                    {
                                        value: 1,
                                        label: (
                                            <>
                                                <NewFolderIcon fill="#919EB0" /> Create New Folder
                                            </>
                                        ),
                                    },
                                ],
                                valueToLabel: (item) => item.label,
                                onChange: (item) => {
                                    const maps = {
                                        0: createSharedTileDialog.show,
                                        1: createFolderDialog.show,
                                    };

                                    return maps[item.value]();
                                },
                            })}
                        </div>
                    )
                ),
                content: cs(["folderCM", (_, next) => FolderCM({apiType: "collectionTiles", folders, next})], ({folderCM}) => {
                    const tableHeight = `calc(100vh - ${78 + 54 + 40}px)`;
                    const hasSelectedItems = multiSelectCtrl.numSelectedItems > 0;
                    const isFolder = (item) => item?.hasOwnProperty("children") || item?.hasOwnProperty("tiles");

                    return (
                        <div>
                            {hasSelectedItems && multiSelectCtrl.render()}

                            {!folders.value
                                ? LoadingIndicator()
                                : RecursiveTable({
                                      label: "Shared Tiles",
                                      showNoResult: keyword.value?.length > 0,
                                      onClearSearch: () => keyword.onChange(""),
                                      maxHeight: tableHeight,
                                      getTrClass: (n) => {
                                          let classNames = "";
                                          if (n.type && multiSelectCtrl.isSelected(n)) {
                                              classNames += " is-selected-tr";
                                          }
                                          return classNames;
                                      },
                                      // getChildTrClass: (n) => (isFolder(n) && multiSelectCtrl.isSelected(n) ? "is-selected-tr" : ""),
                                      structure: buildFoldersTree({
                                          itemsProp: "tiles",
                                          folders: folders.value,
                                          keyword: keyword.value,
                                          filterFn: (col, keyword) => col.adminTileName.indexOf(keyword) > -1,
                                      }),
                                      isGroupNode: (n) => isFolder(n),
                                      isAlwaysExpanded: () => !isBlank(keyword.value),
                                      onClickRow: (item, event, path) => {
                                          if (isFolder(item)) return;
                                          event.preventDefault();
                                          event.stopPropagation();
                                          multiSelectCtrl.toggleSelect(item);
                                      },
                                      sortedProperty: "adminTileName",
                                      columns: [
                                          {
                                              label: "Name",
                                              indented: true,
                                              format: (t, {expanding, path}) => {
                                                  if (!t.type) {
                                                      return (
                                                          <div
                                                              className={cx("icon-name-34n", "folder", {
                                                                  expanding,
                                                              })}
                                                          >
                                                              {expanding ? FolderOpenIcon({}) : FolderIcon({})}
                                                              {t.name}
                                                          </div>
                                                      );
                                                  }

                                                  return (
                                                      <div
                                                          className="shared-tile-name-88u"
                                                          onClick={(e) => {
                                                              e.preventDefault();
                                                              e.stopPropagation();

                                                              recentStorage.add({
                                                                  envId: routing.params?.envId,
                                                                  type: "shared-tile",
                                                                  item: t,
                                                                  path,
                                                              });

                                                              routing.goto("edit-shared-tile", {sharedTileId: t.id});
                                                          }}
                                                      >
                                                          {tileTypes.find((tt) => tt.types.includes(t.type))?.icon}
                                                          <span>{t.adminTileName}</span>
                                                      </div>
                                                  );
                                              },
                                          },
                                          {
                                              label: "Last Modified By",
                                              format: (t) => {
                                                  return t.versionInfo?.editedBy;
                                              },
                                          },
                                          {
                                              label: "Last modified on",
                                              format: (t) => {
                                                  if (!t.versionInfo) return "";

                                                  const editedOn = new Date(t.versionInfo?.editedOnUtc);

                                                  return <div className="last-modified-on-23c">{createDateFormatter("MMM d, yyyy @ h:mm tt").format(editedOn)}</div>;
                                              },
                                          },
                                          {
                                              format: (t) => (t.type ? sharedTileCm.render({params: t}) : folderCM.render({params: t})),
                                              className: "data-source-cm-12s",
                                              shy: true,
                                              alignRight: true,
                                          },
                                      ],
                                  })}
                        </div>
                    );
                }),
                rightPanel: {
                    onClose: () => interactions.onChange(null),
                    content: interactions.value?.name === "view-collections" && (
                        <ViewCollections onClose={() => interactions.onChange(null)} sharedTile={interactions.value.tile} />
                    ),
                },
            });
        }
    );
