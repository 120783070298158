import {chain} from "../../../utils/fs";
import {getColorConfigs, getFieldColorConfigs} from "../get-field-color";
import {generateColorScale} from "../../../utils/color-util";
import {flatten1, sum, unique} from "../../../utils/collections";
import {omit} from "../../../utils/objects";

export const decorateVennData = ({chartData, tile, theme}) => {
    const transformToHCVennSeries = (series) => {
        // receive a set of series (compare or non-compare) and return a highcharts venn series
        const getVennSeries = (series) => {
            if (!series?.length) {
                return;
            }
            return {
                type: "venn",
                name: series[0].measureAxisTitle,
                isCompare: series[0].isCompare,
                range: series[0].range,
                data: series.map((s) => {
                    const dataPoint = s.data[0][0];
                    const sets = s.name.replace(/ /g, "").split(",");
                    return {
                        sets,
                        name: s.name,
                        value: dataPoint[1],
                        custom: {
                            displayName: sets.join(" & "),
                            exclusiveValue: dataPoint[0],
                        },
                    };
                }),
            };
        };

        return [getVennSeries(series.filter((s) => !s.isCompare)), getVennSeries(series.filter((s) => s.isCompare))].filter((v) => v);
    };

    const addPercentValues = (data) => {
        const total = sum(data, (dp) => dp.custom.exclusiveValue); // exclusive total
        return data.map((p) => ({
            ...p,
            custom: {
                ...p.custom,
                percent: (p.value / total) * 100, // inclusive percent
                exclusivePercent: (p.custom.exclusiveValue / total) * 100, // exclusive percent
            },
        }));
    };

    const colorizeData = (data) => {
        const colors = getColors({data, tile, theme});
        // keep original index to make sure the colors of whole points when legend is on and off are the same.
        const dataWithOriginalIndex = data.map((p, i) => ({
            ...p,
            originalIndex: i,
        }));

        // do specific colors for intersections if legend is shown
        if (tile.style.legendStyle.legendShown) {
            return dataWithOriginalIndex.map((p) => ({
                ...omit(p, ["originalIndex"]),
                color: colors[p.originalIndex],
            }));
        }

        // else, only use colors enough for whole points. the intersections' colors will inherit their parent.
        const wholePoints = dataWithOriginalIndex.filter((p) => p.sets.length === 1).map((p) => ({...p, color: colors[p.originalIndex]}));
        let intersectionPoints = [];
        dataWithOriginalIndex.forEach((p) => {
            if (p.sets.length === 1) {
                return;
            }
            // inherit color from parent
            const color = wholePoints.find((wp) => p.sets.includes(wp.sets[0])).color;
            intersectionPoints.push({...p, color});
        });
        return [...wholePoints, ...intersectionPoints].map((p) => omit(p, ["originalIndex"]));
    };

    const vennSeries = transformToHCVennSeries(chartData.series);
    return {
        series: vennSeries.map((vs) => ({
            ...vs,
            data: chain(
                vs.data,
                (_) => colorizeData(_),
                (_) => addPercentValues(_)
            ),
        })),
    };
};

const getColors = ({data, tile, theme}) => {
    // // only do specific colors for intersections when legend is shown
    // const numberOfColors = tile.style.legendStyle.legendShown
    //     ? data.length
    //     : data.filter((p) => p.sets.length === 1).length;

    const numberOfColors = data.length;

    const {dataColorPalettes} = theme.dataVisualization;
    const colorConfigs = getColorConfigs(tile);
    const {displayColor, type} = getFieldColorConfigs({
        dataColorPalettes,
        colorConfigs,
    });
    const colorScale = generateColorScale(displayColor, type === "Sequential", 0, numberOfColors - 1);
    return colorScale.generateAllColors();
};

// get total using inclusive-exclusive principle
// const getTotal = ({data}) => {
//     const numberOfSets = chain(
//         data.map((p) => p.sets),
//         (_) => flatten1(_),
//         (_) => unique(_),
//         (_) => _.length
//     );
//
//     let res = 0;
//
//     const getSumOfIntersectionsOfLength = (length) => {
//         return chain(
//             data.filter((p) => p.sets.length === length),
//             (_) => !_.length ? 0 : sum(_, (p) => p.value)
//         );
//     }
//
//     for (let i = 1; i <= numberOfSets; i++) {
//         res = res + Math.pow(-1, i+1) * getSumOfIntersectionsOfLength(i);
//     }
//
//     return res;
// };
