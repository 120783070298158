import {EditCollection} from "../../routes/collection/edit/edit-collection";
import {EditTile} from "../../routes/collection/tile/edit/edit-tile";
import {AddTheme} from "../../routes/collection/theme/add/add-theme";
import {EditModel} from "../../routes/model/edit/edit-model";
// import {EditView} from "../../routes/add-edit-element/edit-view";
// import {EditColumn} from "../../routes/add-edit-element/edit-column";
import {DashboardRoute} from "../../routes/dashboard/dashboard-route";
import {EditFilter} from "../../routes/collection/filter/edit/edit-filter";
// import {TestRoute} from "../../routes/test-route/test-route";
import {AddDataSource} from "../../routes/data-source/add/add-data-source";
import {EditDataSource} from "../../routes/data-source/edit/edit-data-source";
import {AccountManagementRoute} from "../../routes/account-management/account-management-route";
// import {GettingStartedRoute} from "../../routes/getting-started/getting-started-route";
import {MainDashboardRoute} from "../../routes/main-dashboard/main-dashboard-route";
import {EditContainerTile} from "../../routes/collection/container-tile/edit/edit-container-tile";
import {TestRoute} from "../../routes/test-route/test-route";
import {commonRoutes} from "../common-routes";
import {AddingFilterRoute} from "../../routes/collection/edit/tabs/filters/adding-filter/adding-filter";
import {EditTransformedColumn} from "../../routes/model/transformation/edit/edit-transformed-colum";
import {EditDataView} from "../../routes/model/transformation/edit/edit-data-view";
import {CollectionPreview} from "../../routes/collection/preview/collection-preview";
import {WaitingSetUp} from "../../routes/waiting-setup/waiting-setup";
import {EditSyncSettings} from "../../routes/data-source/edit/sync-settings/edit-sync-settings";
import {EditTheme} from "../../routes/theme/edit/edit-theme";
import {EditConnectionSettings} from "../../routes/data-source/edit/connection-settings/edit-connection-settings";
import {EditImportNewFlatFile} from "../../routes/data-source/edit/import-new-flat-file/edit-import-new-flat-file";
import {EditDataSourceErrors} from "../../routes/data-source/edit/errors/edit-data-source-errors";
import {SetupMFA} from "../../routes/setup-mfa/setup-mfa";
import {EditSharedTile} from "../../routes/collection/shared-tile/edit-shared-tile";
import {ReportBuilderRoute} from "../../routes/report-builder/report-builder-route";

const collectionAndTileRoutes = [
    {
        name: "edit-collection",
        path: "/env/:envId/collection/:colId",
        query: ["collectionTab", "action", "mode", "cTileID", "tileTab", "previewID"],
        route: {
            component: EditCollection,
        },
    },
    {
        name: "preview-collection",
        path: "/env/:envId/collection/:colId/preview",
        query: ["previewID"],
        route: {
            component: CollectionPreview,
        },
    },
    {
        name: "add-filter",
        path: "/env/:envId/collection/:colId/add-filter",
        route: {
            component: AddingFilterRoute,
        },
    },
    {
        name: "edit-filter",
        path: "/env/:envId/collection/:colId/filter/:filterId/edit",
        route: {
            component: EditFilter,
        },
    },
    {
        name: "edit-tile",
        path: "/env/:envId/collection/:colId/tile/:tileId/edit",
        query: ["tileTab", "tileActionId"],
        route: {
            component: EditTile,
        },
    },
    {
        name: "edit-container-tile",
        path: "/env/:envId/collection/:colId/container-tile/:cTileId/edit",
        query: ["tileTab", "childTile"],
        route: {
            component: EditContainerTile,
        },
    },
    {
        name: "edit-shared-tile",
        path: "/env/:envId/shared-tile/:sharedTileId/edit",
        query: ["tileTab", "tileActionId", "collectionId", "childTile"],
        route: {
            component: EditSharedTile,
        },
    },
];

const themeRoutes = [
    {
        name: "add-theme",
        path: "/env/:envId/collection/:colId/add-theme",
        route: {
            component: AddTheme,
        },
    },
    {
        name: "edit-theme",
        path: "/env/:envId/theme/:themeId",
        query: ["collectionID", "tab"],
        route: {
            component: EditTheme,
        },
    },
];

const dataSourceRoutes = [
    {
        name: "edit-data-source",
        path: "/env/:envId/data-source/:dsId",
        query: ["tab", "type"],
        route: {
            component: EditDataSource,
        },
    },
    {
        name: "edit-data-source-errors",
        path: "/env/:envId/data-source/:dsId/errors",
        route: {
            component: EditDataSourceErrors,
        },
    },
    {
        name: "edit-connection-settings",
        path: "/env/:envId/connection-settings/:dsId",
        route: {
            component: EditConnectionSettings,
        },
    },
    {
        name: "edit-import-new-flat-file",
        path: "/env/:envId/import-new/:dsId",
        route: {
            component: EditImportNewFlatFile,
        },
    },
    {
        name: "edit-sync-settings",
        path: "/env/:envId/sync-settings/:dsId",
        route: {
            component: EditSyncSettings,
        },
    },
    {
        name: "add-data-source",
        path: "/env/:envId/add-data-source",
        query: ["defaultDataSource"],
        route: {
            component: AddDataSource,
        },
    },
].map((r) => ({...r, requireFullVerbRoles: true}));

const modelRoutes = [
    {
        name: "edit-model",
        path: "/env/:envId/model/:modelId",
        query: ["tableID", "targetTableID", "columnID", "transformationID", "autoSuggest", "invalidRelationships"],
        route: {
            component: EditModel,
        },
    },
    {
        name: "edit-data-view",
        path: "/env/:envId/model/:modelId/transformation/:transformationId/edit",
        query: ["tab", "isNew"],
        route: {
            component: EditDataView,
        },
    },
    {
        name: "edit-transformed-column",
        path: "/env/:envId/model/:modelId/modelTable/:modelTableId/transformation/:transformationId/edit",
        query: ["tab", "isNew", "type"],
        route: {
            component: EditTransformedColumn,
        },
    },
    // {
    //     name: "model-add-view"  ,
    //     path: "/env/:envId/model/:modelId/add-view",
    //     route: {
    //         component: EditView
    //     },
    // },
    // {
    //     name: "model-add-column",
    //     path: "/env/:envId/model/:modelId/add-column",
    //     route: {
    //         component: EditColumn
    //     },
    // },
].map((r) => ({...r, requireFullVerbRoles: true}));

export const authRoutes = [
    // {
    //     name: "getting-started",
    //     path: "/getting-started",
    //     query: ["envId~"],
    //     route: {
    //         component: GettingStartedRoute,
    //     },
    // },
    {
        name: "insights",
        path: "/insights",
        query: ["envId~"],
        route: {
            component: MainDashboardRoute,
        },
    },
    {
        name: "report-builder",
        path: "/report-builder",
        query: ["reportTab", "reportId"],
        route: {
            component: ReportBuilderRoute,
        },
        authorization: [
            {
                requireAnyOfRoles: ["Full", "ReportBuilder"],
            },
        ],
    },
    {
        name: "dashboard",
        path: "/dashboard",
        query: ["envId~", "tab"],
        route: {
            component: DashboardRoute,
        },
        authorization: [
            {
                requireAnyOfRoles: ["Full"],
                forQuery: {
                    tab: ["overview", "data-sources", "models"],
                },
            },
        ],
    },
    {
        name: "account-management",
        path: "/account-management",
        query: ["envId~", "setting~", "integration~", "tab", "subTab"],
        route: {
            component: AccountManagementRoute,
        },
        authorization: [
            {
                requireAnyOfRoles: ["Full"],
                forQuery: {
                    setting: ["organization", "plan", "billingInformation", "invoiceHistory", "apiKeys", "integrations"],
                },
            },
        ],
    },

    ...collectionAndTileRoutes,

    ...dataSourceRoutes,

    ...modelRoutes,

    ...themeRoutes,

    ...commonRoutes,

    {
        name: "test-route",
        path: "/test-route",
        query: ["tab"],
        route: {
            component: TestRoute,
        },
    },
    {
        name: "waiting-setup",
        path: "/waiting-setup",
        route: {
            component: WaitingSetUp,
        },
    },
    {
        name: "setup-mfa",
        path: "/setup-mfa",
        route: {
            component: SetupMFA,
        },
    },
].map((ar) => {
    if (ar.requireFullVerbRoles) {
        return {
            ...ar,
            authorization: [
                {
                    requireAnyOfRoles: ["Full"],
                },
            ],
        };
    }
    return ar;
});
