import * as React from "react";
import {cs} from "@common/react/chain-services";
import "./suggested-relationships.scss";
import {consumeContext} from "@common/react/context";
import {rRelationshipLine} from "../render-relationship-line/render-relationship-line";
import {keyed} from "@common/react/keyed";
import {spc} from "@common/react/state-path-change";
import {UseState} from "@common/react/use-state";
import {cx} from "emotion";
import {Load2} from "@common/react/load2";

export const SuggestedRelationships = ({model, table, dataSources}) =>
    cs(
        consumeContext("apis"),
        [
            "suggestedRelationships",
            ({apis}, next) =>
                Load2({
                    fetch: () => apis.model.getSuggestedRelationships(model.value.id),
                    next,
                }),
        ],
        consumeContext("dataSourceModelContext"),
        ["addeds", (_, next) => UseState({next})],
        ({suggestedRelationships, dataSourceModelContext, addeds}) => {
            const {getModelColumn} = dataSourceModelContext;
            const relationshipLine = rRelationshipLine({
                getModelColumn,
                originTableId: table.id,
                dataSources,
            });

            const tableColumnIds = table.columns.map((c) => c.id);
            const suggestedTableRelationships = suggestedRelationships.value?.filter((r) => tableColumnIds.includes(r.leftColumnID) || tableColumnIds.includes(r.rightColumnID));

            return (
                <div className="suggested-relationships-fs4">
                    {suggestedRelationships.loading ? (
                        <div className="loading">Loading...</div>
                    ) : !suggestedTableRelationships?.length ? (
                        <div className="no-suggested-relationships">There are not any suggested relationships.</div>
                    ) : (
                        suggestedTableRelationships?.map((relationship, i) =>
                            cs(keyed(relationship.id || i), ({}) =>
                                relationshipLine({
                                    relationship,
                                    control: (
                                        <div
                                            className={cx("add-relationship", {
                                                added: addeds.value?.includes(relationship),
                                            })}
                                            onClick={() => {
                                                spc(model, ["relationships"], (relationships) => [...(relationships || []), relationship]);
                                                addeds.onChange([...(addeds.value || []), relationship]);
                                            }}
                                        >
                                            <img src={require("../../../common/icons/add-relationship-icon.svg")} alt="" />
                                        </div>
                                    ),
                                })
                            )
                        )
                    )}
                </div>
            );
        }
    );
