import {chain} from "@common/utils/fs";
import {cs} from "@common/react/chain-services";
import * as React from "react";
import "./color-picker.scss";
import {bindInput} from "@common/react/bind-input";
import {DebounceCache} from "@common/react/debounce-cache";
import {cx} from "emotion";
import {UseState} from "@common/react/use-state";
import {StaticTooltipService} from "../tooltip3/static-tooltip-service";

export const ColorPicker = ({value, onChange, hasError, domRef, required, className, onRemove, inheritColor}) =>
    cs(
        ["state", (_, next) => next({value: value || inheritColor, onChange})],
        ["initColor", (_, next) => UseState({next, initValue: value || inheritColor})],
        [
            "debounce",
            ({state}, next) =>
                DebounceCache({
                    state: {
                        value: state.value?.toUpperCase(),
                        onChange: (v) => state.onChange(!v ? null : v.toUpperCase()),
                    },
                    disableAutoFlush: (v) => v && v.length !== 7,
                    next,
                }),
        ],
        [
            "staticTooltip",
            (_, next) =>
                StaticTooltipService({
                    direction: "right",
                    info: "Reset to default color",
                    tooltipBoxWidth: 150,
                    next,
                }),
        ],
        ({debounce, state, initColor, staticTooltip}) => {
            return (
                <div
                    className={cx("color-picker color-picker-2e8", className, {
                        hasError: hasError || (!state.value && required) || chain(debounce.state.value, (v) => v != null && v.length !== 7),
                    })}
                    ref={domRef}
                >
                    <div
                        className="indicator"
                        style={{
                            background: debounce.state.value?.length === 7 ? debounce.state.value : state.value || undefined,
                        }}
                    >
                        <input
                            {...{
                                type: "color",
                                readOnly: onChange == null,
                                value: debounce.state.value?.length === 7 ? debounce.state.value : state.value || "#ffffff",
                                onChange: (e) => state.onChange(e.target.value),
                            }}
                        />
                    </div>
                    <input
                        {...{
                            className: "hex",
                            ...bindInput(debounce.state),
                            onBlur: debounce.flush,
                            maxLength: 7,
                            readOnly: onChange == null,
                        }}
                    />

                    <div className="actions-icon">
                        {initColor.value != state.value &&
                            staticTooltip.renderIcon({
                                icon: (
                                    <img
                                        {...{
                                            src: require("./reset-color.svg"),
                                            onClick: () => state.onChange(initColor.value),
                                        }}
                                    />
                                ),
                                className: "reset-color",
                            })}

                        {onRemove && <img src={require("./delete-line.svg")} className="remove-color" onClick={() => onRemove()} />}
                    </div>
                </div>
            );
        }
    );
