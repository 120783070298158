import {Expandable} from "../../../../common/expandable/expandable";
import {LpColorLine} from "../../../../common/left-panel/lp-color-line";
import {ffToColorPickerLine} from "../../../../../../../common/form/ff-to-color-picker-line";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {ffToDropdown} from "../../../../../../../common/form/ff-to-dropdown";
import * as React from "react";

export const DashboardStyles = ({form, sectionInitClosed}) => (
    <Expandable
        {...{
            initExpand: !sectionInitClosed,
            label: "Dashboard Styles",
            render: () => (
                <div className="panel-22">
                    {LpColorLine({
                        label: "Dashboard Background Color",
                        ...ffToColorPickerLine(form.field(["backgroundColorRGB"])),
                    })}

                    {DropdownSelect({
                        label: "Tile Spacing",
                        ...ffToDropdown(form.field(["tileSpacing"])),
                        list: ["Narrow", "Standard", "Wide"],
                    })}
                </div>
            ),
        }}
    />
);
