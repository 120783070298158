import "./calendar-2.scss";

import * as React from "react";
import {css, cx} from "emotion";

import {cs} from "../../../react/chain-services";
import {addDate, compares, getMonthWeeks, sameDate, today} from "../../../utils/dates/date-object";
import {inRange} from "../../../utils/ranges";
import {findLastIndex, flatten1} from "../../../utils/collections";
import {fullDaysInWeek} from "../../../utils/dates/day-in-weeks";
import {hexToRgb} from "@common/ui-components/styles/colors";

export const DpCalendar = ({month, theme, onHover, hover, value, size = 16, space = 4, onSelect}) => cs(

    ({}) => {
        const borderRadius = size / 3;
        const position = (i) => i * (size + space);
        const componentTheme = theme?.general?.components;
        const buttonTheme = theme?.general?.buttons;
        const rgb = hexToRgb(buttonTheme?.primaryButton?.backgroundColorRGB ?? "#0276F2");
        const dayClass = css`
            width: ${size}px !important;
            height: ${size}px;
            line-height: ${size}px;
            font-size: ${size * 0.4}px;
            color: ${componentTheme?.menuTextColorRGB ?? 'black'} !important;
            &.dayHighlight{
                color: ${buttonTheme?.primaryButton?.fontColorRGB ?? 'white'} !important;
                background-color: rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.5) !important;
                border-radius:  ${borderRadius}px;
            }
            &.head{
                color: ${buttonTheme?.primaryButton?.fontColorRGB ?? "white"} !important;
                background-color: rgba(${rgb.r}, ${rgb.g}, ${rgb.b}) !important;
            }

            &.today > span{
                position: absolute;
                width: ${size * 0.8}px;
                height: ${size * 0.8}px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border: 1px solid ${componentTheme?.menuTextColorRGB ?? 'black'};
                border-radius: 50%;
            }
            &.today.dayHighlight > span{
                border-color: ${buttonTheme?.primaryButton?.fontColorRGB ?? 'white'};
            }
            &.today.head > span{
                border-color: ${buttonTheme?.primaryButton?.fontColorRGB ?? 'white'};
            }

        `;
        const lineClass = css`
            height: ${size}px;
        `;

        const monthWeeks = getMonthWeeks(month);
        const now = today();

        return (
            <div className={cx("calendar-2 calendar-2-69z", css`width: ${size * 7 + space * 6}px;`)}>

                <div className={cx("day-header", lineClass)}>
                    {fullDaysInWeek.map((label, i) => (
                        <div className={cx("day", dayClass)} key={i} style={{left: position(i)}}>
                            {label}
                        </div>
                    ))}
                </div>
                <div className="lines" onMouseLeave={() => onHover?.(null)}>
                    {monthWeeks.map((line, i) => (
                        <div className={cx("line", lineClass)} key={i}>
                            {(() => {
                                const days = line.map((day, i) => ({
                                    day,
                                    pos: i
                                }))

                                return <>

                                    {days.map(({day, pos}) => {
                                        const dayHighlight = value ? (sameDate(day, value) || sameDate(day, hover.value)) : false;
                                        const isCurrentMonth = month.month === day.month && month.year === day.year;
                                        const isToday = sameDate(now, day);
                                        const head = value ? sameDate(day, value) : false;
                                        return (
                                            <div
                                                className={cx("day", dayClass, {head, dayHighlight, other: !isCurrentMonth, today: isToday})} key={pos}
                                                style={{
                                                    left: position(pos),
                                                }}
                                                onMouseDown={(e) => {
                                                    e.preventDefault();
                                                    onSelect(day);
                                                }}
                                                onMouseEnter={() => {
                                                    onHover?.(day)
                                                }}
                                            >
                                                <span />
                                                {day.day}
                                            </div>
                                        );
                                    })}
                                </>;
                            })()}
                        </div>
                    ))}


                </div>
            </div>
        );
    }
);

