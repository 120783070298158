import {cx, css} from "emotion";
import {SearchInput} from "../../../../../../../../../../../../../common/form/search-input/search-input";
import {cs} from "@common/react/chain-services";
import {keyed} from "@common/react/keyed";
import {Static2} from "@common/react/static-2";
import {UseState} from "@common/react/use-state";
import {getFieldType} from "@common/ui-components/charts/common/get-field-type";
import {Dropdown} from "@common/ui-components/dropdown/dropdown";
import {chain} from "@common/utils/fs";
import {keepOnly} from "@common/utils/objects";
import {rFieldTypeIcon} from "../../../../../../../../../../common/field-type-icon/get-field-type-icon";
import {TableIcon} from "../../../../../../../../../../data-source/common/icons";
import "./dropdown-field-mapping.scss";
import {isMatchText} from "@common/utils/strings";

export const DropdownFieldMapping = ({label, list, className, state: {onChange, value}, valueToLabel}) =>
    cs(
        ["dropdownRef", (_, next) => Static2({next})],
        ({dropdownRef}, next) => (
            <div className={cx("dropdown-select dropdown-select-5gy dropdown-field-mapping-99t", className)}>
                {label && <div className={cx("label")}>{label}</div>}

                {next()}
            </div>
        ),
        ["selectedValue", (_, next) => next()],
        ({dropdownRef}) =>
            Dropdown({
                dropdownRef,
                registryRender: true,
                renderToggle: ({showExpand, showingExpand}) => (
                    <div
                        className={cx("toggle", {
                            expanding: showingExpand,
                            // "with-value": chosenIndex > -1
                        })}
                        onClick={() => showExpand(!showingExpand)}
                    >
                        {/* {displayValue && <div className="value">
                    {displayValue}&nbsp;
                    <span
                        className="clear-icon"
                        onMouseDown={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                        onClick={() => unSelectAll()}
                    >
                        <i className="fal fa-times"/>
                    </span>
                </div>} */}

                        <div className="value">{valueToLabel(value)}</div>
                        {/*
                <div className="placeholder">
                    Filter by Collection
                </div> */}

                        <i className="fa fa-chevron-down" />
                    </div>
                ),
                renderExpand: ({close, width}) =>
                    cs(
                        ["search", (_, next) => UseState({next})],
                        [
                            "stack",
                            (_, next) =>
                                UseState({
                                    initValue: [{id: null, name: null}],
                                    next,
                                }),
                        ],
                        ["selected", (_, next) => UseState({initValue: null, next})],
                        ({search, selected}) => {
                            const isAllowedColumn = (c) => {
                                const columnType = getFieldType(c);
                                return columnType !== "date";
                            };

                            const renderSearches = () => (
                                <>
                                    {cs(
                                        [
                                            "searches",
                                            (_, next) =>
                                                next(
                                                    chain(
                                                        list,
                                                        (_) =>
                                                            _.map((t) => ({
                                                                ...t,
                                                                columns: t.columns.filter((c) => isMatchText(c.name, search.value)),
                                                            })),
                                                        (_) => _.filter((t) => t.columns.length > 0)
                                                    )
                                                ),
                                        ],
                                        ({searches}) => (
                                            <div className="searches-list">
                                                {searches.length === 0 ? (
                                                    <div className="no-search">No search found</div>
                                                ) : (
                                                    searches.map((item, j) =>
                                                        cs(keyed(j), () => (
                                                            <>
                                                                <div className="table">
                                                                    {TableIcon({
                                                                        fill: "#919EB0",
                                                                    })}
                                                                    Data Source {">"} {item.name}
                                                                </div>

                                                                <div className="columns-list">
                                                                    {item.columns.map((column, k) =>
                                                                        cs(keyed(k), () => (
                                                                            <div
                                                                                className="item"
                                                                                onClick={() => {
                                                                                    onChange(column);
                                                                                    close();
                                                                                }}
                                                                            >
                                                                                {rFieldTypeIcon(column.dataType)}
                                                                                <div className="name">
                                                                                    {Highlighted({
                                                                                        text: column.name,
                                                                                        highlight: search.value,
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                    )}
                                                                </div>
                                                            </>
                                                        ))
                                                    )
                                                )}
                                            </div>
                                        )
                                    )}
                                </>
                            );

                            const renderList = () => (
                                <>
                                    {cs(
                                        (_, next) =>
                                            selected.value?.id ? (
                                                <>
                                                    <div className="back-to-list" onClick={() => selected.onChange(null)}>
                                                        <i className="far fa-angle-left" />
                                                        <div className="name">
                                                            Data Source {">"} {selected.value.name}
                                                        </div>
                                                    </div>

                                                    <div className="list">
                                                        {chain(
                                                            list,
                                                            (_) => _.find((t) => t.id === selected.value?.id),
                                                            (_) => _.columns.filter((c) => isAllowedColumn(c))
                                                        ).map((column, i) =>
                                                            cs(keyed(i), () => (
                                                                <div
                                                                    className="item"
                                                                    onClick={() => {
                                                                        onChange(column);
                                                                        close();
                                                                    }}
                                                                >
                                                                    {rFieldTypeIcon(column.dataType)}
                                                                    <div className="name">{column.name}</div>
                                                                </div>
                                                            ))
                                                        )}
                                                    </div>
                                                </>
                                            ) : (
                                                next()
                                            ),
                                        () => (
                                            <>
                                                <div className="list">
                                                    {list.map((table, i) =>
                                                        cs(keyed(i), () => (
                                                            <div
                                                                className="item"
                                                                onClick={() => selected.onChange(keepOnly(table, ["name", "id"]))}
                                                            >
                                                                <img
                                                                    src={require("../../../../../../../../../../common/icons/data-source-table-icon.svg")}
                                                                />
                                                                <div className="name">{table.name}</div>
                                                                <i className="far fa-angle-right" />
                                                            </div>
                                                        ))
                                                    )}
                                                </div>
                                            </>
                                        )
                                    )}
                                </>
                            );

                            return (
                                <div className="dropdown-field-expand-v56">
                                    {SearchInput({
                                        autoFocus: true,
                                        state: search,
                                        placeholder: "Search for fields",
                                        delay: 300,
                                    })}

                                    {search.value ? renderSearches() : renderList()}
                                </div>
                            );
                        }
                    ),
            })
    );

const Highlighted = ({text = "", highlight = ""}) => {
    if (!highlight.trim()) {
        return <span>{text}</span>;
    }
    const regex = new RegExp(`(${highlight})`, "gi");
    const parts = text.split(regex);

    return (
        <span>
            {parts.filter(String).map((part, i) => {
                return regex.test(part) ? (
                    <mark
                        style={{
                            background: "unset !important",
                            color: "#11a1fd",
                            "font-weight": 500,
                        }}
                        key={i}
                    >
                        {part}
                    </mark>
                ) : (
                    <span key={i}>{part}</span>
                );
            })}
        </span>
    );
};
