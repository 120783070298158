import {cs} from "@common/react/chain-services";
import {DialogService} from "../../../../common/dialog/dialog-service";
import "./fix-issues-dialog.scss";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";
import {AlertIcon, UserIcon} from "@common/ui-components/icons/global-icons";
import {Button} from "@common/form/buttons/button/button";
import * as React from "react";
import {createDateFormatter} from "@common/ui-components/charts/common/formatters/date-formatter";
import {consumeContext} from "@common/react/context";

export const CollectionMissingColumnsError = ({next: rootNext, collectionID}) =>
    cs(
        consumeContext("routing"),
        [
            "modal",
            (_, next) =>
                DialogService({
                    render: ({resolve, args: {errorGroup}}) => ({
                        title: "MISSING COLUMN ERRORS",
                        width: 620,
                        content: next({
                            resolve,
                            errorGroup,
                        }),
                    }),
                    next: rootNext,
                    registryRender: true,
                }),
        ],
        ({modal, routing}) => {
            const {errorGroup} = modal;

            const getLabel = (error) => {
                if (error.location == "CollectionFilter") {
                    return (
                        <span>
                            Filter - {error.filterName} - <b className="error-text">Field</b>
                        </span>
                    );
                }

                if (error.location == "DataTab") {
                    if (error.fieldLocation == "Filter") {
                        return (
                            <span>
                                {error.tileName} - Filter - <b className="error-text">Field</b>
                            </span>
                        );
                    }

                    if (error.tileActionID) {
                        return (
                            <span>
                                {error.tileName} - Tile Action - <b className="error-text">Field</b>
                            </span>
                        );
                    }

                    return (
                        <span>
                            {error.tileName} - Data - <b className="error-text">{error.fieldLocation}</b>
                        </span>
                    );
                }

                return (
                    <span>
                        {error.tileName} - Filter - <b className="error-text">Field</b>
                    </span>
                );
            };

            const alertContent = {
                title: <span>Column removed from model: {errorGroup.columnName}</span>,
                dataSource: (
                    <span>
                        <span className="link" onClick={() => routing.goto("edit-model", {modelId: errorGroup.modelID})}>
                            {errorGroup.modelName}
                        </span>{" "}
                        > {errorGroup.tableName}
                    </span>
                ),
                removedOn: createDateFormatter("MMM d, yyyy @ h:mm tt").format(new Date(errorGroup.deletedInfo.deletedDateUtc)),
                deletedBy: errorGroup.deletedInfo.deletedBy,
                description: `This collection has ${errorGroup.childs.length} ${
                    errorGroup.childs.length > 1 ? "dependencies" : "dependency"
                } on this column that need to be fixed before you publish your collection`,
                heading1: "Missing Column Errors",
                errors: errorGroup.childs.map((error) => ({
                    label: getLabel(error),
                    fix: () => {
                        if (error.location == "CollectionFilter") {
                            routing.goto("edit-filter", {
                                filterId: error.filterID,
                                colId: collectionID,
                            });
                        } else {
                            const tab = error.fieldLocation == "Filter" ? "filters" : "fields";

                            if (!error.parentID) {
                                routing.goto("edit-tile", {
                                    tileId: error.tileID,
                                    tileTab: tab,
                                    colId: collectionID,
                                });
                            } else {
                                routing.goto("edit-container-tile", {
                                    cTileId: error.parentID,
                                    tileTab: tab,
                                    colId: collectionID,
                                    childTile: error.tileID,
                                });
                            }
                        }
                    },
                })),
            };

            return (
                <div className="fix-issues-dialog-a33">
                    <VerbDialogBodyScrollbar>
                        <div className="removed-column-error-card">
                            <div className="header-card">
                                <AlertIcon className="icon" />

                                <div className="title-card">
                                    {alertContent.title}

                                    <div className="column-info">{alertContent.dataSource}</div>
                                </div>
                            </div>

                            <div className="description-card">
                                Removed on <b>{alertContent.removedOn}</b>
                            </div>

                            <div className="deleted-by">
                                <UserIcon /> {alertContent.deletedBy}
                            </div>
                        </div>

                        <p>{alertContent.description}</p>

                        <div className="section">
                            <div className="section-title">{alertContent.heading1}</div>

                            {alertContent.errors.map((error, index) => (
                                <div className="section-row" key={index}>
                                    <AlertIcon className="icon" />

                                    <span>{error.label}</span>

                                    <Button className="fix-btn" btnType="border" size="medium" onClick={() => error.fix()}>
                                        Fix
                                    </Button>
                                </div>
                            ))}
                        </div>
                    </VerbDialogBodyScrollbar>

                    <div className="buttons">
                        <Button onClick={() => modal.resolve()}>Close</Button>
                    </div>
                </div>
            );
        }
    );
