import React from "react";
import {cx} from "emotion";

import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";

import {CollectionDashboardIcon} from "@common/ui-components/icons/global-icons";

import SearchIcon from "assets/icons/common/SearchIcon";
import {isMatchText} from "@common/utils/strings";

export const CollectionList = ({selected, collections, collection}) =>
    cs(["keyword", (_, next) => UseState({next, initValue: ""})], ({keyword}) => (
        <div className="collection-list-wrapper">
            <div className="search-bar">
                {SearchIcon({className: "search-icon"})}
                <input className="input-search" placeholder="Search for a collection..." value={keyword.value} onChange={(e) => keyword.onChange(e.target.value)} />
            </div>

            <div className="collection-list">
                <div
                    className="new-collection-section"
                    onClick={() => {
                        selected.onChange({
                            themeID: collection.value.themeID,
                            folderID: collection.value.folderID,
                            modelID: collection.value.modelID,
                            $type: "DashboardCollection",
                            name: `${collection.value.name} copy`,
                        });
                    }}
                >
                    <div className="add-icon">
                        <img src={require("./icons/add-icon.svg")} alt="" />
                    </div>
                    New Collection
                </div>

                {!collections ? (
                    <SkeletonLoading />
                ) : (
                    collections
                        .filter((c) => c.type == "DashboardCollection" && c.id != collection.value.id && isMatchText(c.name, keyword.value))
                        .map((collection, index) => (
                            <div className={cx("collection-row", selected.value?.id == collection.id && "selected")} onClick={() => selected.onChange(collection)} key={index}>
                                <div className="collection-icon">
                                    <CollectionDashboardIcon />
                                </div>

                                {collection.name}

                                {selected.value?.id == collection.id && <img src={require("./icons/check-icon.svg")} className="check-icon" alt="" />}
                            </div>
                        ))
                )}
            </div>
        </div>
    ));

const SkeletonLoading = () =>
    [1, 2, 3].map((_) => (
        <div className="skeleton-loading" key={_}>
            <div className="loading-avatar" />
            <div className="loading-text" />
        </div>
    ));
