import {NumberInput} from "../../../../../../../../../../common/form/number-input/number-input";
import {cs} from "@common/react/chain-services";
import {keyed} from "@common/react/keyed";
import {scope} from "@common/react/scope";
import {UseMemo} from "@common/react/use-memo";
import {Watch} from "@common/react/watch";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {listToString} from "../../../../../../../common/data-logic/list-to-string";
import {stringToList} from "../../../../../../../common/data-logic/string-to-list";
import {DEFAULT_LAST_OR_NEXT_DAYS_VALUE} from "../default-filter-criteria/default-custom-ranges";

import "./date-picker-selectable-range.scss";
import {getDateRangesFromMaxMinSelection} from "./utils";

const SELECTABLE_RANGE_OPTIONS = [
    {
        label: "Not Limited",
        value: null,
    },
    {
        label: "Custom",
        value: 0,
    },
];

export const isCustomSelectableRanges = (v) => Number.isInteger(v);

const isSelected =
    ({current}) =>
        (o) => {
            if(current === null || current === undefined) {
                return o.value === null;
            }

            if(isCustomSelectableRanges(o.value)) {
                return isCustomSelectableRanges(current);
            }
            return false;
        };

export const DatePickerSelectableRange = ({filter}) => {
    return cs(
        [
            "allowedDateRanges",
            (_, next) =>
                UseMemo({
                    next,
                    fn: () => getDateRangesFromMaxMinSelection(filter).map((each) => each.name),
                    deps: [filter.value.pickerMaxDaysInFuture, filter.value.pickerMaxDaysInPast],
                }),
        ],
        [
            "newOptions",
            ({allowedDateRanges}, next) =>
                UseMemo({
                    next,
                    fn: () => stringToList(filter.value.options).filter((o) => allowedDateRanges.includes(o)),
                    deps: [allowedDateRanges],
                }),
        ],
        ({newOptions}) => {
            const isPastCustom = isCustomSelectableRanges(filter.value.pickerMaxDaysInPast);
            const isFutureCustom = isCustomSelectableRanges(filter.value.pickerMaxDaysInFuture);
            const rangeConfigs = [
                {
                    label: "Max Days In Past",
                    state: scope(filter, ["pickerMaxDaysInPast"]),
                },
                {
                    label: "Max Days In Future",
                    state: scope(filter, ["pickerMaxDaysInFuture"]),
                },
            ];

            const updateFilter = (customRange) => {
                filter.change((f) => ({
                    ...f,
                    customRange: customRange ?? f.customRange,
                    options: listToString(newOptions),
                    defaultOption: !newOptions.includes(f.defaultOption) ? newOptions[0] : f.defaultOption,
                }));
            };

            return (
                <div className="date-picker-selectable-range">
                    {Watch({
                        value: filter.value.pickerMaxDaysInFuture,
                        onChanged: (changed, prev) => {
                            updateFilter(
                                filter.value.customRange?.next
                                    ? {
                                        ...filter.value.customRange,
                                        $type: "InTheLastOrNextCustomDateFilterRange",
                                        value: Math.min(isFutureCustom ? changed : DEFAULT_LAST_OR_NEXT_DAYS_VALUE, DEFAULT_LAST_OR_NEXT_DAYS_VALUE),
                                        interval: "Days",
                                    }
                                    : null
                            );
                        },
                    })}
                    {Watch({
                        value: filter.value.pickerMaxDaysInPast,
                        onChanged: (changed) => {
                            updateFilter(
                                !filter.value.customRange?.next
                                    ? {
                                        ...filter.value.customRange,
                                        $type: "InTheLastOrNextCustomDateFilterRange",
                                        value: Math.min(isPastCustom ? changed : DEFAULT_LAST_OR_NEXT_DAYS_VALUE, DEFAULT_LAST_OR_NEXT_DAYS_VALUE),
                                        interval: "Days",
                                    }
                                    : null
                            );
                        },
                    })}
                    <div className="header">DATE PICKER SELECTABLE RANGE</div>

                    <div className="content">
                        {rangeConfigs.map((c) =>
                            cs(keyed(c.label), () => (
                                <div className="max-range-picker">
                                    {DropdownSelect({
                                        label: c.label,
                                        list: SELECTABLE_RANGE_OPTIONS,
                                        valueToLabel: (op) => op.label,
                                        isSelected: isSelected({
                                            current: c.state.value,
                                        }),
                                        onChange: (op) => c.state.onChange(op.value),
                                    })}
                                    {isCustomSelectableRanges(c.state.value) && (
                                        <div className="max-days">
                                            {NumberInput({
                                                label: "Period",
                                                state: {
                                                    value: c.state.value,
                                                    onChange: (v) => {
                                                        const newV = isCustomSelectableRanges(v) ? v : 0;
                                                        c.state.onChange(newV);
                                                    },
                                                },
                                                showZero: true,
                                            })}
                                            <span>Days</span>
                                        </div>
                                    )}
                                </div>
                            ))
                        )}
                    </div>
                </div>
            );
        }
    );
};
