import * as React from "react";
import {scope} from "@common/react/scope";
import {ShowHideToggle} from "../../../../../../../../../../../../common/form/toggles/show-hide-toggle";
import {CheckboxLine} from "../../../../../../../../../common/checkbox-line/checkbox-line";
import {CheckboxLineGroup} from "../../../../../../../../../common/checkbox-line-group/checkbox-line-group";
import {stateToCheckboxLineGroup} from "../../../../../../../../../common/checkbox-line-group/state-to-checkbox-line-group";
import {stringListState} from "../../../../../../../../../common/data-logic/string-list-state";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {stateToSelect} from "@common/ui-components/form/state-to-select";
import {HiddenFieldAlert} from "../../common/hidden-field-alert/hidden-field-alert";
import {isFunnelLegendHiddenBySize} from "./legend";
import {spc} from "@common/react/state-path-change";

export const SectionLabels = ({tile, size}) => ({
    label: "Slice labels",
    control: isFunnelSectionLabelsHidden({tile: tile.value, size})
        ? HiddenFieldAlert({
              info: "Section labels can only be shown when legend is turned off.",
          })
        : ShowHideToggle({
              state: {
                  value: tile.value.style.dataLabels.show,
                  onChange: (v) =>
                      spc(tile, ["style"], (style) => ({
                          ...style,
                          dataLabels: {...style.dataLabels, show: v},
                          legendStyle: {
                              ...style.legendStyle,
                              legendShown: v ? false : style.legendStyle.legendShown,
                          },
                      })),
              },
          }),
    render: () => {
        return (
            <div className="funnel-chart-section-59f section-labels">
                {CheckboxLine({
                    label: "Label",
                    state: scope(tile, ["style", "dataLabels", "categoryShown"]),
                })}

                {CheckboxLineGroup({
                    list: [
                        {label: "Value", name: "Value"},
                        {label: "Percent", name: "Percent"},
                    ].filter((v) => v),
                    ...stateToCheckboxLineGroup(stringListState(scope(tile, ["style", "dataLabels", "metrics"])), ["name"]),
                })}

                {DropdownSelect({
                    label: "Comparison Type",
                    list: [
                        {
                            label: "Percent of Previous",
                            value: "PercentOfPrevious",
                        },
                        {label: "Percent of Total", value: "PercentOfTotal"},
                    ],
                    ...stateToSelect(scope(tile, ["style", "dataLabels", "comparisonType"]), ["value"]),
                    valueToLabel: (v) => v.label,
                })}
            </div>
        );
    },
});

export const isFunnelSectionLabelsHidden = ({tile, size}) => tile.style.legendStyle.legendShown && !isFunnelLegendHiddenBySize({size});
