export const ColumnSelectHelper = {
    buildTree: (table, tables, relationships) => {
        let _relationships = [...relationships];
        let _table = {...table};

        const generateChildCol = (column) => {
            let ret = [];

            for (let relationship of _relationships) {
                const generateChild = (from, target) => {
                    if (relationship[from] == column.id) {
                        let tableFound = tables.find((t) => t.columns.find((c) => c.id == relationship[target]));
                        if (tableFound && !tableFound.deleted) {
                            _relationships = _relationships.filter((r) => r.id != relationship.id);
                            ret.push(buildTree(tableFound, tables));
                        }
                    }
                };

                generateChild("leftColumnID", "rightColumnID");
                generateChild("rightColumnID", "leftColumnID");
            }

            return ret;
        };

        const buildTree = (table) => {
            let ret = [];
            for (let column of table.columns) {
                ret.push({
                    ...column,
                    childs: generateChildCol(column),
                });
            }

            return {
                ...table,
                columns: ret,
            };
        };

        return buildTree(_table);
    },
    getListByPath: (stack, tablesTree, header) => {
        if (stack.length == 0) {
            return {
                header: header,
                list: tablesTree,
            };
        }

        let table = tablesTree.find((c) => c.id == stack[0].tableId);
        if (table) {
            let column = table.columns.find((c) => c.id == stack[0].columnId);
            if (column) {
                let h = header.concat([table.name, column.name]);
                return ColumnSelectHelper.getListByPath(stack.slice(1), column.childs, h);
            }
        }

        return {error: "Cannot find this path"};
    },
    getPath: ({tableId, columnId}, tablesTree, viewExpand) => {
        if (!tableId || !columnId) return [];
        let path = [];

        const seek = (tablesTree, cb) => {
            for (let table of tablesTree) {
                if (table.id == tableId) {
                    let columnFound = table.columns.find((c) => c.id == columnId);
                    if (columnFound && columnFound.childs.length > 0 && viewExpand) {
                        cb([
                            {
                                tableId: tableId,
                                columnId: columnId,
                            },
                        ]);
                    } else {
                        cb([]);
                    }
                } else {
                    for (let column of table.columns) {
                        if (column.childs.length > 0) {
                            seek(column.childs, (childPath) => {
                                cb(
                                    [
                                        {
                                            tableId: table.id,
                                            columnId: column.id,
                                        },
                                    ].concat(childPath)
                                );
                            });
                        }
                    }
                }
            }
        };

        seek(tablesTree, (p) => {
            path = p;
        });

        return path;
    },
};
