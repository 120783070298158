const {formatDateTimeFromISOStr} = require("@common/logic/date-time/format-date-time-full");
const {monthShortLabels} = require("../../utils/dates/month-short-labels");
const {az} = require("@common/utils/common");

const formatDateLong = (date) => {
    return date ? `${monthShortLabels[date.month - 1]} ${date.day}, ${date.year}` : null;
};
exports.formatDateLong = formatDateLong;

const formatDateLong2 = (date) => {
    return date ? formatDateTimeFromISOStr(`${date.month}/${date.day}/${date.year}`, "MM/DD/YYYY") : null;
};
exports.formatDateLong2 = formatDateLong2;

const formatTimeLong = ({hours, minutes}) => {
    return `${az(hours == 0 || hours == 12 ? 12 : hours % 12)}:${az(minutes)}${hours >= 12 ? "PM" : "AM"}`;
};

exports.formatTimeLong = formatTimeLong;
