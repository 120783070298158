const {getDataLabelRect} = require("./show-data-labels");
const {showDataLabels} = require("./show-data-labels");

const adjustInsideDataLabels = ({chart, allowOverlap}) => {
    const adjustEachSeries = (series) => {
        showDataLabels({
            points: (series?.data || []).filter((point) => point.dataLabel),
            allowOverlap,
            getRect: getDataLabelRect,
        });
    };
    adjustEachSeries(chart.series.find((s) => !s.options.isCompare));
};
exports.adjustInsideDataLabels = adjustInsideDataLabels;
