import {cs} from "@common/react/chain-services";
import {Static2} from "@common/react/static-2";
import {UseState} from "@common/react/use-state";
import {fragments} from "@common/react/fragments";
import {OnUnmounted} from "@common/react/on-unmounted";
import {Invoke} from "@common/react/invoke";
import {debounce} from "@common/utils/debounce";
import {IgnoreUpdate} from "@common/react/ignore-update";

export const IsActiveUser = ({next: rootNext, delta = 60 * 2 /* seconds */}) =>
    cs(
        ["removeEventRef", (_, next) => Static2({next})],
        [
            "state",
            (_, next) =>
                UseState({
                    next,
                    initValue: {
                        lastUpdatedAt: Date.now(),
                    },
                }),
        ],
        ({state, removeEventRef}) => {
            const stopTimer = () => {
                const removeFunc = removeEventRef.get();
                if (removeFunc) {
                    removeFunc();
                }
            };

            return fragments(
                OnUnmounted({
                    action: stopTimer,
                }),
                Invoke({
                    fn: () => {
                        const listener = debounce(() => {
                            state.onChange({
                                lastUpdatedAt: Date.now(),
                            });
                        }, 50);

                        document.body.addEventListener("change", listener, true);
                        document.addEventListener("scroll", listener, true);
                        document.addEventListener("click", listener, true);
                        document.addEventListener("keypress", listener, true);
                        window.addEventListener("resize", listener, true);
                        window.addEventListener("mousemove", listener, true);

                        removeEventRef.set(() => {
                            window.removeEventListener("mousemove", listener);
                            window.removeEventListener("resize", listener);
                            document.removeEventListener("keypress", listener);
                            document.removeEventListener("click", listener);
                            document.removeEventListener("scroll", listener);
                            document.body.removeEventListener("change", listener);
                        });
                    },
                }),
                ({}, next) =>
                    IgnoreUpdate({
                        when: () => true,
                        next,
                    }),
                rootNext({
                    isActive: () => {
                        const {lastUpdatedAt = 0} = state.get() ?? {};
                        return lastUpdatedAt + delta * 1000 >= Date.now();
                    },
                })
            );
        }
    );
