import React from "react";
import {cs} from "@common/react/chain-services";
import {provideContext} from "@common/react/context";
import {UseState} from "@common/react/use-state";
import {isValidTimezone} from "@common/ui-components/live/live-dashboard/live-dashboard";
import {getCurrentTzName} from "@common/utils/dates/date-object";

export const ProvideDefaultTimezone = ({timezone, next: rootNext, defaultTimezoneToUtc}) => {
    return cs(
        [
            "selectedTimezone",
            ({}, next) => {
                const _timezone = isValidTimezone(timezone) ? timezone : defaultTimezoneToUtc ? null : getCurrentTzName();
                return UseState({next, initValue: _timezone});
            },
        ],
        ({selectedTimezone}) => provideContext("selectedTimezone", selectedTimezone, rootNext)
    );
};
