import * as React from "react";
import {cs} from "@common/react/chain-services";
import "./create-relationship.scss";
import {RelationshipPicker} from "./relationship-picker";
import {UseState} from "@common/react/use-state";
import {spc} from "@common/react/state-path-change";
import {Static2} from "@common/react/static-2";
import {OnMounted} from "@common/react/on-mounted";
import {Button} from "../../../../../../../../../common/form/buttons/button/button";

export const CreateRelationship = ({dataSources, table, model}) =>
    cs(
        ["state", (_, next) => UseState({next})],
        ["lineEnds", (_, next) => UseState({next})],
        ["creatingTime", (_, next) => UseState({next})],
        ["containerRef", (_, next) => Static2({next})],
        ["containerHeight", (_, next) => UseState({next})],
        ({state, lineEnds, creatingTime, containerRef, containerHeight}) => (
            <>
                <div className="create-relationship-0qz" ref={containerRef.set}>
                    {containerHeight.value && (
                        <div className="choose-relationship" key={creatingTime.value} style={{height: containerHeight.value}}>
                            {RelationshipPicker({
                                table,
                                modelTables: model.value.tables,
                                dataSources,
                                state,
                                lineEnds,
                            })}
                        </div>
                    )}
                    <div className="action-button">
                        <Button
                            disabled={!state.value?.leftColumnId || !state.value?.rightColumnId || lineEnds.value?.rightEnd?.x === 0 || lineEnds.value?.leftEnd?.x === 0}
                            onClick={() => {
                                const {leftColumnId, rightColumnId} = state.value;

                                spc(model, ["relationships"], (rls) => [
                                    ...rls,
                                    {
                                        leftColumnID: leftColumnId,
                                        rightColumnID: rightColumnId,
                                    },
                                ]);

                                state.onChange(null);
                                lineEnds.onChange(null);
                                creatingTime.onChange(Date.now());
                            }}
                        >
                            Create Relationship
                        </Button>
                    </div>
                </div>

                {OnMounted({
                    action: () => {
                        const containerDom = containerRef.get();
                        if (containerDom) {
                            const rect = containerDom.getBoundingClientRect();
                            const availHeight = window.innerHeight - rect.y - 94; // 94 is action button height
                            containerHeight.onChange(availHeight);
                        }
                    },
                })}
            </>
        )
    );
