import React from "react";
import {cs} from "@common/react/chain-services";
import {ToastWithAction} from "./toast-with-action";

export const ToastWithAction2 = ({next: rootNext, onShow}) => {
    return cs(
        [
            "rootToast",
            (_, next) =>
                ToastWithAction({
                    next,
                }),
        ],
        ({rootToast}) =>
            rootNext({
                ...rootToast,
                show: ({extraData, ...rest}) => onShow({...rest}, rootToast.show, extraData),
            })
    );
};
