import React from "react";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {lighten} from "@common/utils/color-util";
import {CircleRadius} from "../../model-panel";
import {Static2} from "@common/react/static-2";
import {cx} from "emotion";
import {PreviewDataIcon} from "../more-controls/icons/more-control-icons";

export const PreviewDataAction = ({table, color, modelActionTooltip, interactions, scale}) =>
    cs(["hovering", (_, next) => UseState({next})], ["actionRef", (_, next) => Static2({next})], ({hovering, actionRef}) => {
        const disabled = (table?.$type === "ViewModelTable" && table?.publishedOn === null) || table.deleted;

        return (
            <g fillOpacity={disabled ? 0.5 : 1} strokeOpacity={disabled ? 0.5 : 1}>
                <path
                    ref={actionRef.set}
                    className={cx("action-btn", disabled && "disabled")}
                    onMouseEnter={() => {
                        hovering.onChange(true);
                        const {x, y, width} = actionRef.get()?.getBoundingClientRect();
                        modelActionTooltip.showTooltip({
                            pos: {
                                x: x + width,
                                y,
                                // transform: `translate(-100%)`,
                            },
                            info: disabled ? "Preview not available" : "Preview Data",
                        });
                    }}
                    onMouseLeave={() => {
                        hovering.onChange(false);
                        modelActionTooltip.hideToolTip();
                    }}
                    x={table.position.x + CircleRadius / 2 - 15}
                    y={table.position.y - CircleRadius * 2 - 2}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (!disabled) {
                            interactions.previewData({table, tableId: table.id});
                        }
                    }}
                    d="M0-59A59,59,0,0,1,49.057-32.779L28.27-18.889A34,34,0,0,0,0-34Z"
                    transform="translate(60.011 60)"
                    fill={hovering.value ? lighten(color, 80) : lighten(color, 70)}
                    stroke="#fff"
                    strokeWidth="2"
                />

                <PreviewDataIcon style={{pointerEvents: "none"}} x={75.6} y={12.3} />
            </g>
        );
    });
