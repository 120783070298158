import {normalize} from "./normalize";
import {isMatchText} from "./strings";
import {isEmpty} from "./objects";

// only support for data that contains a child node has only one parent node.
// schema should add a processStrategy func that resolve parent's id and _metadata.
// entities are sorted by ID by default.

export class SimpleSearch {
    constructor({data, schema, searchProperty}) {
        this.entities = null;
        this.ids = null;
        this.schemas = null;
        this._schema = schema;
        this.searchProperty = searchProperty ?? "name";
        this.searchResults = {};
        this._searchText = null;

        if (data) {
            this.processData(data);
        }
    }

    processData(data) {
        let {entities, schemas, ids} = normalize(this._schema, data);
        this.entities = entities;
        this.ids = ids;
        this.schemas = schemas;
        return this;
    }

    reset(newData) {
        return this.processData(newData);
    }

    getEntities(key, {filterFn, isSearchResults = true} = {}) {
        try {
            if (!this.entities.hasOwnProperty(key) || Object.keys(this.entities[key]).length == 0) {
                return [];
            }

            if (!this.searchResults && !filterFn) {
                return Object.values(this.entities[key] ?? {});
            }

            let results = isEmpty(this.searchResults)
                ? Object.values(this.entities[key] ?? {})
                : Object.keys(isSearchResults ? this.searchResults[key] || {} : this.entities[key]).map((itemID) => {
                      return {
                          ...(this.entities[key]?.[itemID] ?? {}),
                          ...(this.searchResults[key]?.[itemID] ?? {}),
                      };
                  });
            if (filterFn) {
                results = results.filter(filterFn);
            }

            return results;
        } catch (e) {
            console.log({key, entities: this.entities, searchResults: this.searchResults});

            throw e;
        }
    }

    resolveText(item) {
        return typeof this.searchProperty === "function" ? this.searchProperty(item) : item[this.searchProperty];
    }

    search(searchText) {
        this.searchResults = {};
        this._searchText = searchText;

        if (!searchText || searchText.length == 0) {
            return this.searchResults;
        }

        let matchedParentIDs = {};

        for (let i = 0; i < this.ids.length; i++) {
            const {schemaKey: key, id} = this.ids[i];

            if (!this.searchResults[key]) {
                this.searchResults[key] = {};
            }

            if (this.searchResults[key][id]) {
                continue;
            }

            const item = this.entities[key][id];

            const resolvedSourceText = this.resolveText(item);
            const result = isMatchText(resolvedSourceText, searchText, true);

            const addParent = () => {
                if (item.parent && item._metadata?.parentSchema) {
                    if (!matchedParentIDs[item.parent]) {
                        matchedParentIDs[item.parent] = {
                            schemaKey: item._metadata.parentSchema,
                            children: [],
                        };
                    }

                    matchedParentIDs[item.parent].children.push(id);
                }
            };

            if (result?.ranges) {
                if (!this.searchResults[key][id]) {
                    this.searchResults[key][id] = {};
                }
                this.searchResults[key][id].ranges = result?.ranges;

                addParent();
            }

            if (matchedParentIDs[id]?.children.length > 0) {
                if (!this.searchResults[key][id]) {
                    this.searchResults[key][id] = {};
                }
                this.searchResults[key][id].children = matchedParentIDs[id].children;

                addParent();
            }

            delete matchedParentIDs[id];
        }

        Object.keys(matchedParentIDs).forEach((id) => {
            const {schemaKey: key, children} = matchedParentIDs[id];

            if (!this.entities.hasOwnProperty(key) || Object.keys(this.entities[key]).length == 0) {
                return;
            }

            if (children.length > 0) {
                if (!this.searchResults[key]) {
                    this.searchResults[key] = {};
                }

                if (!this.searchResults[key][id]) {
                    this.searchResults[key][id] = {};
                }

                this.searchResults[key][id].children = children;
            }
        });

        return this.searchResults;
    }
}
