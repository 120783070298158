// manually hide tooltip. this is the need from supporting scrolling inside tooltip, which means tooltip must be hoverabled.
// since hovering tooltip making points losing hovering and tooltip is hidden, disable the original tooltip hide method and do manual hide.
export function extendTooltipHiding(H) {
    // copy tooltip hide method
    H.Tooltip.prototype.manuallyHide = H.Tooltip.prototype.hide;

    // disable the original tooltip hide method
    H.Tooltip.prototype.hide = function () {};

    H.Chart.prototype.callbacks.push(function (chart) {
        let hideTimeout;

        // when moving mouse out of chart, hide tooltip
        H.addEvent(chart.container, "mouseleave", function (e) {
            // console.log('mouse leaves chart container')

            // the timeout delay is for the case tooltip appear at the very mouse position,
            // then chart container loses hovering and the tooltip is started being hovered.
            // there should be a duration for this transition. that's why timeout delay.
            hideTimeout = setTimeout(() => chart?.tooltip?.manuallyHide?.(), 300);
        });

        H.addEvent(chart.container, "mousemove", function (e) {
            if (chart.tooltip?.container) {
                // clear hiding on hovering the tooltip
                H.addEvent(chart.tooltip.container, "mouseenter", function (e) {
                    // console.log('mouse enters tooltip')
                    clearTimeout(hideTimeout);
                });

                // when moving mouse from tooltip to a place outside of chart, hide tooltip
                H.addEvent(chart.tooltip.container, "mouseleave", function (e) {
                    chart.tooltip.manuallyHide?.();
                });
            }
        });
    });
}
