const addMap = require("./math-util").addMap;
const {cascadeNodesChange} = require("./cascade-nodes");

const {parseNodes} = require("./path-pattern");
const {cascadeNodes, cascadeNodesFind} = require("./cascade-nodes");

const cascade = (obj, pathStr, map) => cascadeNodes(obj, parseNodes(pathStr), map);
exports.cascade = cascade;

const cascadeFind = (obj, pathStr, check) => cascadeNodesFind(obj, parseNodes(pathStr), check);
exports.cascadeFind = cascadeFind;

const cascadeCollect = (obj, pathStr, fn = (v) => v || 0) => {
    let ret = [];
    cascadeNodesFind(obj, parseNodes(pathStr), (...args) => {
        const found = fn(...args);
        if (found) {
            ret.push(found);
        }
    });
    return ret;
};
exports.cascadeCollect = cascadeCollect;

const cascadeEach = (obj, pathStr, fn) =>
    cascadeNodesFind(obj, parseNodes(pathStr), (...args) => {
        fn(...args);
    });
exports.cascadeEach = cascadeEach;

const cascadeChange = (obj, pathStr, fn) => {
    return cascadeNodesChange(obj, parseNodes(pathStr), fn);
};
exports.cascadeChange = cascadeChange;

const cascadeSum = (obj, pathStr, fn = (v) => v || 0) => {
    let total = 0;
    cascadeNodesFind(obj, parseNodes(pathStr), (...args) => {
        total += fn(...args);
    });
    return total;
};
exports.cascadeSum = cascadeSum;

const cascadeSumMap = (obj, pathStr, fn = (v) => v || 0) => {
    let total = {};
    cascadeNodesFind(obj, parseNodes(pathStr), (...args) => {
        total = addMap(total, fn(...args));
    });
    return total;
};
exports.cascadeSumMap = cascadeSumMap;

const cascadeCount = (obj, pathStr, fn = (v) => v) => {
    let total = 0;
    cascadeNodesFind(obj, parseNodes(pathStr), (...args) => {
        if (fn(...args)) {
            total++;
        }
    });
    return total;
};
exports.cascadeCount = cascadeCount;
