import {cs} from "../../../../../../../../react/chain-services";
import * as React from "react";
import "./button-group.scss";
import {css, cx} from "emotion";
import {lighten} from "../../../../../../../../utils/color-util";
import {StyledClass} from "@common/react/styled-class";

export const ButtonGroup = ({state, isSelected, data, valueToLabel = (v) => v || "Blank / Empty", theme, size, isToggle}) =>
    cs(
        [
            "buttonCss",
            (_, next) => {
                const toggleButtonTheme = theme.general.buttons.buttonToggleGroup;
                return StyledClass({
                    content: {
                        color: `${toggleButtonTheme.fontColorRGB}`,
                        "font-family": `${theme.general.canvas.fontFamily || "Roboto"}, sans-serif`,
                        "font-weight": 500,
                        background: `${toggleButtonTheme.backgroundColorRGB}`,
                        border: `${toggleButtonTheme.borderWidth}px solid ${toggleButtonTheme.borderColorRGB}`,
                        "border-radius": `${toggleButtonTheme.cornerRadius}px`,

                        "&.toggle": {
                            "&:first-child": {
                                "border-radius": `${toggleButtonTheme.cornerRadius}px 0 0 ${toggleButtonTheme.cornerRadius}px !important`,
                            },

                            "&:last-child": {
                                "border-radius": `0 ${toggleButtonTheme.cornerRadius}px ${toggleButtonTheme.cornerRadius}px 0 !important`,
                            },
                        },

                        "&.selected": {
                            background: `${toggleButtonTheme.activeBackgroundColorRGB} !important`,
                            color: `${toggleButtonTheme.activeFontColorRGB}`,
                            "border-color": `${toggleButtonTheme.activeBorderColorRGB}`,
                            "font-weight": 700,
                        },
                    },
                    next,
                });
            },
        ],
        ({buttonCss}) => {
            return (
                <div className={cx("button-group-85f", {desktop: size === "desktop"}, isToggle ? "toggle" : "group")}>
                    {data?.map((d, i) => {
                        const selected = isSelected ? isSelected(d) : state.value?.includes(d);

                        return (
                            <button
                                key={i}
                                className={cx({selected}, isToggle ? "toggle" : "", buttonCss)}
                                onClick={() => {
                                    if (!isToggle) {
                                        state.change((list) => (selected ? list.filter((e) => e !== d) : [...list, d]));
                                    } else {
                                        state.change((list) => (selected ? [] : [d].filter((v) => v)));
                                    }
                                }}
                            >
                                {valueToLabel(d)}
                            </button>
                        );
                    })}
                </div>
            );
        }
    );
