import "./calendar.scss";

import * as React from "react";
import {css, cx} from "emotion";

import {cs} from "../../../react/chain-services";
import {addDate, compares, getMonthWeeks, sameDate} from "../../../utils/dates/date-object";
import {monthShortLabels} from "../../../utils/dates/month-short-labels";
import {inRange} from "../../../utils/ranges";
import {findLastIndex, flatten1} from "../../../utils/collections";
import {consumeContext} from "../../../react/context";
import {StyledClass} from "@common/react/styled-class";

export const Calendar = ({
    selecting,
    month,
    onHover,
    ranges,
    size = 16,
    space = 4,
    getHighlight,
    onSelect,
    isDisabledDay = () => false,
    monthStyle,
    selectControl,
}) =>
    cs(
        consumeContext("theme"),
        [
            "dayClass",
            ({}, next) =>
                StyledClass({
                    content: {
                        width: `${size}px !important`,
                        height: `${size}px`,
                        "line-height": `${size}px`,
                        "font-size": `${(10 / 16) * size}px`,
                    },
                    next,
                }),
        ],
        [
            "lineClass",
            ({}, next) =>
                StyledClass({
                    content: {
                        height: `${size}px`,
                    },
                    next,
                }),
        ],
        [
            "calendarClass",
            ({}, next) =>
                StyledClass({
                    content: {
                        width: `${size <= 16 ? (140 / 16) * size : (237 / 30) * size}px`,
                    },
                    next,
                }),
        ],
        [
            "monthClass",
            ({}, next) =>
                StyledClass({
                    content: {
                        "font-size": `${(12 / 16) * size}px`,
                    },
                    next,
                }),
        ],
        ({theme, dayClass, lineClass, calendarClass, monthClass}) => {
            const position = (i) => i * (size + space);
            const componentTheme = theme?.general?.components;

            const monthWeeks = getMonthWeeks(month);

            return (
                <div className={cx("calendar calendar-5w2", calendarClass)}>
                    <div className={cx("month", monthClass)} style={monthStyle}>
                        {monthShortLabels[month.month - 1]} {month.year}
                    </div>
                    <div className={cx("day-header", lineClass)}>
                        {["S", "M", "T", "W", "T", "F", "S"].map((label, i) => (
                            <div className={cx("day", dayClass)} key={i} style={{left: position(i)}}>
                                {label}
                            </div>
                        ))}
                    </div>
                    <div className="lines" onMouseLeave={() => onHover?.(null)}>
                        {monthWeeks.map((line, i) => (
                            <div className={cx("line", lineClass)} key={i}>
                                {(() => {
                                    const days = line
                                        .map((day, i) => ({
                                            day,
                                            pos: i,
                                        }))
                                        .filter(({day}) => day.month === month.month);

                                    return (
                                        <>
                                            {ranges &&
                                                RangesHighlight({
                                                    ranges,
                                                    days,
                                                    position,
                                                    size,
                                                })}

                                            {days.map(({day, pos}) => {
                                                const dayHighlight = getHighlight
                                                    ? getHighlight(day)
                                                    : ranges &&
                                                      getDayHighlightByRanges(day, {
                                                          ranges,
                                                      });
                                                const isSelectabledDay = selectControl?.selectable?.(day)?.value ?? true;
                                                return (
                                                    <div
                                                        className={cx("day", dayClass, {
                                                            dayHighlight,
                                                            unselectabled: !isSelectabledDay,
                                                        })}
                                                        key={pos}
                                                        style={{
                                                            background: dayHighlight,
                                                            left: position(pos),
                                                            color: componentTheme?.menuTextColorRGB ?? "black",
                                                            ...(isDisabledDay(day) && {
                                                                opacity: 0.5,
                                                                cursor: "default",
                                                            }),
                                                        }}
                                                        onMouseDown={(e) => {
                                                            e.preventDefault();
                                                            isSelectabledDay && !isDisabledDay(day) && onSelect(day);
                                                        }}
                                                        onMouseEnter={() => isSelectabledDay && !isDisabledDay(day) && onHover?.(day)}
                                                    >
                                                        {day.day}
                                                    </div>
                                                );
                                            })}
                                        </>
                                    );
                                })()}
                            </div>
                        ))}

                        {[...Array(6 - monthWeeks.length)].map((_, index) => (
                            <div className="extra-line" key={index} />
                        ))}
                    </div>
                </div>
            );
        }
    );

const RangesHighlight = ({ranges, days, position, size}) =>
    ranges.map((range, rangeIndex) => {
        if (!range.range?.from || !range.range?.to) {
            return null;
        }
        const start = days.findIndex(({day}) => inRange(day, range.range, compares));
        if (start === -1) {
            return null;
        }

        const end = findLastIndex(days, ({day}) => inRange(day, range.range, compares));

        const left = position(days[start].pos);
        return (
            <div
                key={rangeIndex}
                className={cx("range-highlight")}
                style={{
                    height: `${size}px`,
                    borderRadius: `${size / 2}px`,
                    background: range.color,
                    left,
                    width: position(days[end].pos) + size - left,
                }}
            />
        );
    });

const getDayHighlightByRanges = (date, {ranges}) => {
    return flatten1(
        ranges.map((r) => [
            {date: r.range.from, color: r.color},
            {date: r.range.to && addDate(r.range.to, 0), color: r.color},
        ])
    ).find((r) => sameDate(r.date, date))?.color;
};
