import { cx } from "emotion";
import { createElement as h, Fragment } from "react";
import { cs } from "../../../../../common/react/chain-services";
import { getELoc } from "../../../../../common/react/get-e-loc";
import { Static } from "../../../../../common/react/static";
import { Static2 } from "../../../../../common/react/static-2";
import { UseState } from "../../../../../common/react/use-state";
import { debounce } from "../../../../../common/utils/debounce";
import { decorateFunction } from "../../../../../common/utils/decorate-function";
import { oMapToArr } from "../../../../../common/utils/objects";
import { TooltipConsumer } from "./tooltip-context-3";
import "./tooltip-service-3.scss";
export const tooltipService3 = ({direction, disabled, alternateStyle, className, tooltipContentStyle, allowTooltipInteraction} = {}) => [
    "tooltip",
    ({}, next) => tooltipService4({direction, disabled, alternateStyle, className, tooltipContentStyle, allowTooltipInteraction, next}),
];
export const tooltipService4 = ({
    direction: direction1,
    disabled,
    alternateStyle,
    className,
    tooltipContentStyle = {},
    allowTooltipInteraction,
    next,
}) =>
    cs(
        ["registry", ({}, next) => h(TooltipConsumer, {}, next)],
        ["state", (_, next) => h(UseState, {next})],
        ["tempOff", (_, next) => h(UseState, {next})],
        ["domRef", ({}, next) => Static2({next})],
        [
            "debounceHide",
            ({state, domRef}, next) =>
                h(Static, {
                    // getInitValue: () => () => state.onChange(null),
                    getInitValue: () => {
                        const timeout = allowTooltipInteraction ? 200 : 0;
                        return debounce(() => {
                            state.onChange(null);
                        }, timeout);
                    },
                    // getInitValue: () => nullDebounce(() => state.onChange(null), 0),
                    next,
                }),
        ],
        ({state, domRef, debounceHide, registry, tempOff}) => {
            const show = (e, flex) => {
                if (disabled || !e || !flex) {
                    return;
                }
                debounceHide.cancel();
                state.onChange({
                    point: getELoc(e),
                    ...parse(flex),
                    direction: direction1,
                });
            };
            const moveDirectly = (e) => {
                if (!state.value || disabled) {
                    return;
                }
                const point = getELoc(e);
                const direction = state.value.direction || determineDirection(point.x, direction1);
                const dom = domRef.get();
                const {className: directionClassName, style} = directions[direction]({point, alternateStyle, dom});
                if (dom) {
                    dom.className =
                        "tooltip-service tooltip-service-3pm " +
                        (!alternateStyle ? directionClassName : "alternate-style") +
                        " " +
                        className;
                    dom.style = oMapToArr(style, (v, k) => `${k}:${v}px;`).join("");
                }
            };
            const bind = (flex) => ({
                onMouseEnter: (e) =>
                    show(e, () => (flex === null ? null : typeof flex === "function" ? flex({close: () => state.onChange(null)}) : flex)),
                onMouseMove: moveDirectly,
                onMouseLeave: () => debounceHide(),
            });
            return h(
                Fragment,
                {},
                next(
                    decorateFunction(bind, {
                        show,
                        moveDirectly,
                        hide: debounceHide,
                        active: !disabled && !!state.value,
                        tempOff: () => tempOff.onChange(true),
                        backon: () => tempOff.onChange(false),
                    })
                ),
                state.value &&
                    !disabled &&
                    !tempOff.value &&
                    registry.render(
                        (() => {
                            const tooltip = state.value;
                            const direction = determineDirection(tooltip.point.x, direction1);
                            const {className: directionClassName, style} = directions[direction]({
                                point: tooltip.point,
                                alternateStyle,
                                dom: domRef?.get?.(),
                            });
                            return h(
                                "div",
                                {
                                    className: cx(
                                        "tooltip-service tooltip-service-3pm",
                                        !alternateStyle ? directionClassName : "alternate-style",
                                        className
                                    ),
                                    style,
                                    ref: domRef.set,
                                },
                                h(
                                    "div",
                                    {
                                        className: "tooltip-content",
                                        style: tooltipContentStyle,
                                        ...(allowTooltipInteraction
                                            ? {onMouseEnter: () => debounceHide.cancel(), onMouseLeave: () => state.onChange(null)}
                                            : {}),
                                    },
                                    tooltip.content
                                )
                            );
                        })()
                    )
            );
        }
    );
const parse = (flex) => {
    if (typeof flex === "function") {
        return {content: flex()};
    }
    return {content: flex};
};
const directions = {
    below: ({point, alternateStyle, dom}) => {
        const {height} = dom?.getBoundingClientRect() ?? {height: 0, width: 0};
        
        return {
            className: "below-tooltip",
            style: {
                top: Math.min(point.y + (!alternateStyle ? 25 : 15), window.innerHeight - height),
                left: point.x - 15,
            },
        }
    },
    above: ({point, dom}) => {
        const {height} = dom?.getBoundingClientRect() ?? {height: 0, width: 0};
        return {
            className: "above-tooltip",
            style: {
                bottom: Math.min(window.innerHeight - point.y + 10, window.innerHeight - height),
                left: point.x - 10,
            },
        };
    },
    left: ({point}) => ({
        className: isNearBottom(point.y) ? "left-tooltip-near-bottom" : "left-tooltip",
        style: {
            top: point.y - 10,
            right: window.innerWidth - point.x + 30,
        },
    }),
    right: ({point}) => ({
        className: isNearBottom(point.y) ? "right-tooltip-near-bottom" : "right-tooltip",
        style: {
            top: point.y - 10,
            left: point.x + 30,
        },
    }),
};
const isNearBottom = (y) => window.innerHeight - y < 300;
const isNearRight = (x) => window.innerWidth - x < 220;
const isNearLeft = (x) => x < 0;
const determineDirection = (xPos, defaultDirection) => {
    if (isNearRight(xPos)) {
        return "left";
    }
    if (isNearLeft(xPos)) {
        return "right";
    }
    return defaultDirection || "right";
};