import {cs} from "@common/react/chain-services";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {getPath} from "@common/utils/arr-path";
import {upperCase1} from "@common/utils/strings";

const sizes = ["Auto", "Small", "Medium", "Large"];

export const LegendSizeSelect = (state, style, useWidthDescription) =>
    cs(() =>
        ["Top", "Bottom"].indexOf(style.legendPosition) > -1
            ? null
            : DropdownSelect({
                  label: "Size",
                  list: sizes,
                  isSelected: (v) => getPath(v) === (state.value || "Auto"),
                  onChange: (v) => state.onChange(getPath(v)),
                  valueToLabel: (v) => {
                      if (["Left", "Right"].indexOf(style.legendPosition) > -1 || useWidthDescription) {
                          const label = ["Auto", "Narrow", "Medium", "Wide"];
                          return label[sizes.findIndex((s) => s == v)];
                      }

                      const label = ["Auto", "Short", "Medium", "Tall"];
                      return label[sizes.findIndex((s) => s == v)];
                  },
                  registryRender: true,
              })
    );
