import * as React from "react";
import {cs} from "@common/react/chain-services";
import {ShowHideToggle} from "../../../../../../../../../../../../../common/form/toggles/show-hide-toggle";
import "./summary-number.scss";
import {scope} from "@common/react/scope";
import {NumberAggregationFunctionSelect} from "../../../../../../../../../common/number-aggregation-function-select/number-aggregation-function-select";
import {ComparisonDateRange} from "../../../single-kpi/panels/value-format/comparison-date-range/comparison-date-range";
import {upperCase1} from "@common/utils/strings";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {ffToDropdown} from "../../../../../../../../../../../../../common/form/ff-to-dropdown";

export const SummaryNumber = ({tile, form}) => ({
    label: "Summary Number",
    control: ShowHideToggle({state: scope(tile, ["style", "summaryShown"])}),
    render: () => (
        <div className="table-kpi-summary-number-pi3">
            <div className="subsection">
                <div className="header">KPI Value</div>

                <div className="content">
                    {NumberAggregationFunctionSelect({
                        state: scope(tile, ["style", "summaryAggregationFunc"]),
                    })}

                    {DropdownSelect({
                        label: "Position",
                        list: ["Left", "Center", "Right"],
                        valueToLabel: (v) => v,
                        ...ffToDropdown(form.field(["style", "actualValuePosition"])),
                    })}
                </div>
            </div>

            {/*<div className="panel">*/}
            {/*    */}
            {/*</div>*/}

            {ComparisonDateRange({
                tile,
                label: "Comparison Date Range",
                getKey: (key) => {
                    if (key == "showCompare") return "summaryCompareValueShown";
                    return `summary${upperCase1(key)}`;
                },
            })}
        </div>
    ),
});
