import {tooltipLayout} from "./tooltip-layout";
import {getTooltipFontSizes} from "./get-tooltip-font-sizes";
import {getIndicatorShapes} from "./indicator-shapes";
import {getDifference} from "./get-difference";

export const renderVennDiagramTooltip = ({points, tile, theme, rawData, formatters, isCompare}) => {
    if (isCompare) {
        const extractedPoint = getExtractedVennPoint({
            points,
            tile,
            isCompare,
            rawData,
        });
        return (
            extractedPoint &&
            tooltipLayout({
                content: `<div class="table">
                    ${getVennPointTableCompare({
                        tile,
                        extractedPoint,
                        rawData,
                        formatters,
                        theme,
                    })}
                </div>`,
                theme,
                isCompare,
            })
        );
    }

    const extractedPoint = getExtractedVennPoint({points, tile});

    return (
        extractedPoint &&
        tooltipLayout({
            content: `<div class="non-compare-header">
                <div class="title">${extractedPoint.title}</div>
            </div>
            <div class="table">
                ${getVennPointTable({
                    tile,
                    theme,
                    extractedPoint,
                    formatters,
                })}
            </div>`,
            theme,
        })
    );
};

const getExtractedVennPoint = ({points, tile, isCompare, rawData}) => {
    const point = points[0];

    if (!point.point) {
        return;
    }

    const series = point.series;
    // ref. decorate-venn-data.js
    const {
        value,
        custom: {displayName, exclusiveValue, percent, exclusivePercent},
    } = point.point;

    const compareValues =
        isCompare &&
        (() => {
            const comparePoint = rawData.series.find((s) => s.isCompare).data.find((d) => d.name === point.key);
            const {
                value,
                custom: {exclusiveValue, percent, exclusivePercent},
            } = comparePoint;
            return {
                compareValue: value,
                compareExclusiveValue: exclusiveValue,
                comparePercent: percent,
                compareExclusivePercent: exclusivePercent,
            };
        })();

    return {
        title: series.name,
        name: displayName,
        color: point.color,
        value,
        exclusiveValue,
        percent,
        exclusivePercent,
        ...compareValues,
    };
};

const getVennPointTable = ({tile, theme, extractedPoint, formatters}) => {
    const rPoint = () => {
        const fontSizes = getTooltipFontSizes(theme);
        const indicatorShapes = getIndicatorShapes(theme);

        const rRow = ({name, value, percent, isFirstRow}) => `
            <div class="point table-row ${isFirstRow ? "first-point" : ""}" style="font-size: ${fontSizes.medium}px">
                <div class="name">
                    <span class="indicator" style="color: ${extractedPoint.color}">${indicatorShapes["bar"]}</span>
                    ${name}
                </div>
                <div class="main-value">
                    ${formatters.valueFormatter(value)}
                    <span className="percent" style="font-size: ${fontSizes.small}px">(${formatters.percentFormatter(percent)})</span>
                </div>
            </div>
        `;

        return [
            // inclusive
            rRow({
                name: extractedPoint.name,
                value: extractedPoint.value,
                percent: extractedPoint.percent,
                isFirstRow: true,
            }),
            // exclusive
            rRow({
                name: `${extractedPoint.name} only`,
                value: extractedPoint.exclusiveValue,
                percent: extractedPoint.exclusivePercent,
            }),
        ].join("");
    };

    return `${rPoint()}`;
};

const getVennPointTableCompare = ({tile, theme, extractedPoint, formatters, rawData}) => {
    const fontSizes = getTooltipFontSizes(theme);
    const dateRangeFormatter = (range) => formatters.dateFormatter(range?.dateStart) + " - " + "</br>" + formatters.dateFormatter(range?.dateEnd);

    const rHeader = () => {
        const seriesTitle = (() => {
            const {diff, diffIcon, stateColor} = getDifference({
                value: extractedPoint.percent,
                compareValue: extractedPoint.comparePercent,
                theme,
            });

            return `
                <div class="title">
                    ${extractedPoint.title}
                </div>
                <div class="difference" style="color: ${stateColor}">
                    ${diffIcon}
                    ${formatters.percentFormatter(diff)}
                </div>
            `;
        })();

        const mainColumnTitle = dateRangeFormatter(rawData.series.find((s) => !s.isCompare)?.range);

        const compareColumnTitle = dateRangeFormatter(rawData.series.find((s) => s.isCompare)?.range);

        return `<div class="header table-row" style="font-size: ${fontSizes.small}px">
                <div class="series-title">${seriesTitle}</div>
                    <div class="main-title">
                        ${mainColumnTitle}
                    </div>
                    <div class="compare-title">
                        ${compareColumnTitle}
                    </div>
            </div>`;
    };

    const rPoint = () => {
        const indicatorShapes = getIndicatorShapes(theme);

        const rRow = ({name, value, percent, compareValue, comparePercent, isFirstRow}) => `
            <div class="point table-row ${isFirstRow ? "first-point" : ""}" style="font-size: ${fontSizes.medium}px">
                <div class="name">
                    <span class="indicator" style="color: ${extractedPoint.color}">${indicatorShapes["bar"]}</span>
                    ${name}
                </div>
                <div class="main-value">
                    ${formatters.valueFormatter(value)}
                    </br>
                    <span style="font-weight: normal; font-size: ${fontSizes.small}px">
                        (${formatters.percentFormatter(percent)})
                    </span>
                </div>
                <div class="compare-value">
                    ${compareValue != null ? formatters.valueFormatter(compareValue) : "N/A"}
                    </br>
                    <span style="font-weight: normal; font-size: ${fontSizes.small}px">
                        ${comparePercent != null ? "(" + formatters.percentFormatter(comparePercent) + ")" : ""}
                    </span>
                </div>
            </div>
        `;

        return [
            // inclusive
            rRow({
                name: extractedPoint.name,
                value: extractedPoint.value,
                percent: extractedPoint.percent,
                compareValue: extractedPoint.compareValue,
                comparePercent: extractedPoint.comparePercent,
                isFirstRow: true,
            }),
            // exclusive
            rRow({
                name: `${extractedPoint.name} only`,
                value: extractedPoint.exclusiveValue,
                percent: extractedPoint.exclusivePercent,
                compareValue: extractedPoint.compareExclusiveValue,
                comparePercent: extractedPoint.compareExclusivePercent,
            }),
        ].join("");
    };

    return `${rHeader()}
         ${rPoint()}`;
};
