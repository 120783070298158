import "./y-axis.scss";

import * as React from "react";

import {ShowHideToggle} from "../../../../../../../../../../../../../common/form/toggles/show-hide-toggle";
import {LpLine} from "../../../../../../../../../../common/left-panel/line/lp-line";
import {scope} from "@common/react/scope";
import {OrientationSelect} from "../../../common/orientation-select";
import {AxisRangeSelect} from "../../../common/axis-range-select";
import {GridStepTypeSelect} from "../../../common/grid-step-type-select";
import {AxisLocationSelect} from "../../../common/axis-location-select";
import {AxisTitle} from "../../../combo-chart/panels/x-axis/x-axis-rules-comp";
import {YAxisLabel, YGridStepLabel} from "../../../horizontal-bar/panels/y-axis/y-axis-rules-comp";
import {getPath} from "@common/utils/arr-path";
import {CumulativeSelect} from "../../../common/cumulative-select/cumulative-select";
import {AxisScaleSelect} from "../../../common/axis-scale-select";
// import {CheckboxLine} from "../../../../../../../../../../common/checkbox-line/checkbox-line";
// import {TextareaInput} from "../../../common/textarea-input/textarea-input";

export const rYAxis =
    (fieldsKey) =>
    ({tile, size}) => ({
        getLabel: (getField) =>
            `Y-Axis (${fieldsKey
                .split("|")
                .map((f) => getField(f)?.visualNameShort)
                .join(" & ")})`,
        render: () => (
            <div className="vertical-y-axis-7qp">
                <div className="subsection">
                    <div className="content">
                        {AxisTitle({
                            tile,
                            size,
                            state: scope(tile, ["style", "yAxis", fieldsKey, "label"]),
                        })}
                    </div>
                </div>

                <div className="subsection">
                    {YAxisLabel({
                        tile,
                        size,
                        state: scope(tile, ["style", "yAxis", fieldsKey, "labelShown"]),
                    })}
                </div>

                {/*{scope(tile, ["style", "yAxis", fieldsKey, "labelShown"]).value && <>*/}
                {/*    <div className="subsection">*/}
                {/*        <div className="header">*/}
                {/*            Axis Label Info Icon*/}
                {/*            <div className="control">*/}
                {/*                {ShowHideToggle({state: scope(tile, ["style", "yAxis", fieldsKey, "labelInfoShown"])})}*/}
                {/*            </div>*/}
                {/*        </div>*/}

                {/*        <div className="content">*/}
                {/*            {TextareaInput({})}*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</>}*/}

                <div className="subsection">
                    <div className="header">Range</div>
                    <div className="content">{AxisRangeSelect(scope(tile, ["style", "yAxis", fieldsKey]))}</div>
                </div>

                <div className="subsection">
                    <div className="header">Grid Step</div>
                    <div className="content">
                        {GridStepTypeSelect({
                            state: scope(tile, ["style", "yAxis", fieldsKey]),
                            fieldType: "number",
                        })}
                    </div>
                </div>

                <div className="subsection">
                    {YGridStepLabel({
                        tile,
                        size,
                        state: scope(tile, ["style", "yAxis", fieldsKey, "gridStepLabelsShown"]),
                    })}

                    {getPath(tile.value, ["style", "yAxis", fieldsKey, "gridStepLabelsShown"]) && (
                        <div className="content">{OrientationSelect(scope(tile, ["style", "yAxis", fieldsKey, "gridStepLabelOrientation"]))}</div>
                    )}
                </div>

                <div className="subsection">
                    <div className="header">Other Options</div>
                    <div className="content">
                        {AxisLocationSelect(scope(tile, ["style", "yAxis", fieldsKey, "location"]))}

                        {LpLine({
                            label: "Axis Line",
                            control: ShowHideToggle({
                                state: scope(tile, ["style", "yAxis", fieldsKey, "lineShown"]),
                            }),
                        })}

                        {LpLine({
                            label: "Grid Lines",
                            control: ShowHideToggle({
                                state: scope(tile, ["style", "yAxis", fieldsKey, "gridLinesShown"]),
                            }),
                        })}

                        {AxisScaleSelect(scope(tile, ["style", "yAxis", fieldsKey, "logarithmic"]))}

                        {CumulativeSelect({
                            tile,
                            state: scope(tile, ["style", "yAxis", fieldsKey, "cumulative"]),
                        })}
                    </div>
                </div>
            </div>
        ),
    });
