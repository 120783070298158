import React from "react";
import {cs} from "@common/react/chain-services";
import "./inner-block.scss";
import {UseState} from "@common/react/use-state";
import {InnerValueDropdown} from "./inner-value-dropdown/inner-value-dropdown";
import {DropdownSelect2} from "@common/ui-components/dropdown-select/dropdown-select2";
import {cx} from "emotion";
import {RemoveBlockDialog} from "./remove-block-dialog";
import {OuterBlock} from "../outer-block";
import {unique} from "@common/utils/collections";
import {getTimestamp} from "../../column-security-main";
import {Button} from "../../../../../../../../../../../common/form/buttons/button/button";

const compareOPs = [
    "Equal",
    "NotEqual",
    "GreaterThan",
    "GreaterThanOrEqual",
    "LessThan",
    "LessThanOrEqual",
    "Contains",
    "DoesNotContain",
    "Filter",
];

const generateDefaultFilterInnerBlock = (table, innerBlock, dataSources) => {
    let found = dataSources.find((d) => d.tables.find((t) => t.id == table.dataSourceTableID));

    return {
        ...innerBlock,
        leftValue: {
            $type: "TableBlockValue",
            dataSourceID: found?.id,
            dataSourceTableID: table.dataSourceTableID,
        },
        compareOP: "Contains",
        rightValue: {
            $type: "FilterBlockValue",
            orAndBlock: {
                outerBlocks: [
                    {
                        timestamp: getTimestamp(),
                        innerBlocks: [
                            {
                                leftValue: {
                                    $type: "ColumnBlockValue",
                                },
                                timestamp: getTimestamp(),
                            },
                        ],
                    },
                ],
            },
        },
    };
};

export const InnerBlock = ({innerBlock, onChange, hasBorder, onAdd, isLastChild, onRemove, hideRemove, filterInnerBlock, ...otherProps}) =>
    cs(
        [
            "state",
            (_, next) =>
                UseState({
                    next,
                    initValue: {
                        leftTypeSelect: filterInnerBlock ? "Column" : null,
                        rightTypeSelect: null,
                    },
                }),
        ],
        ["confirmDialog", (_, next) => RemoveBlockDialog({next})],
        [
            "tabledRelatedTo",
            (_, next) => {
                if (filterInnerBlock) return next([]);
                let ret = [];
                const getTables = (field, anotherField) => {
                    const {relationships, tables, table} = otherProps;
                    let rs = relationships.filter((r) => table.columns.find((t) => t.id == r[field]));
                    return tables.filter((t) => t.columns.find((column) => rs.find((r) => r[anotherField] == column.id)) && !t.deleted);
                };

                ret = ret.concat(getTables("leftColumnID", "rightColumnID"));
                ret = ret.concat(getTables("rightColumnID", "leftColumnID"));

                return next(unique(ret, (r) => r.id));
            },
        ],
        [
            "$typeDisplay",
            ({tabledRelatedTo}, next) =>
                next({
                    onChange: (dropdownType, type) => {
                        if (type == "FilterBlockValue") {
                            onChange(generateDefaultFilterInnerBlock(tabledRelatedTo[0], innerBlock, otherProps.dataSources));
                            return;
                        }

                        if (innerBlock.leftValue?.$type == "TableBlockValue") {
                            onChange({
                                timestamp: innerBlock.timestamp,
                                leftValue: {
                                    $type: type,
                                },
                                compareOp: "Equal",
                            });

                            return;
                        }

                        onChange({
                            ...innerBlock,
                            [dropdownType]: {
                                $type: type,
                            },
                        });
                    },
                    isSelected: (dropdownType, type) => {
                        if (type == "FilterBlockValue") {
                            return innerBlock.leftValue?.$type == "TableBlockValue";
                        }

                        if (type == "ColumnBlockValue") {
                            return (
                                innerBlock[dropdownType]?.$type == "ColumnBlockValue" ||
                                innerBlock[dropdownType]?.$type == "ViewColumnBlockValue"
                            );
                        }

                        return innerBlock[dropdownType]?.$type == type;
                    },
                    generateList: (dropdownType) => {
                        let types = [
                            {
                                label: "Column",
                                $type: "ColumnBlockValue",
                            },
                            {
                                label: "User Value",
                                $type: "UserBlockValue",
                            },
                        ];

                        if (dropdownType == "leftValue" && tabledRelatedTo.length > 0) {
                            types = types.concat({
                                label: "Filter Value",
                                $type: "FilterBlockValue",
                            });
                        }

                        if (dropdownType == "rightValue") {
                            types = types.concat({
                                label: "Static Value",
                                $type: "StaticBlockValue",
                            });
                        }

                        return types;
                    },
                }),
        ],
        [
            "renderFilterSelect",
            ({tabledRelatedTo}, next) =>
                next(() => {
                    return (
                        <>
                            <div className="col col-large">
                                {DropdownSelect2({
                                    label: "Table",
                                    className: "filter-select",
                                    list: tabledRelatedTo.map((t) => t.dataSourceTableID),
                                    valueToLabel: (s, isOnToggle) => {
                                        const selectedTable = tabledRelatedTo.find((t) => t.dataSourceTableID == s);
                                        return (
                                            <div className="filter-table-name">
                                                {isOnToggle && (
                                                    <img
                                                        className="table-icon"
                                                        src={
                                                            selectedTable.$type === "ViewModelTable"
                                                                ? require("../../../../../../../../common/icons/data-view-icon.svg")
                                                                : require("../../../../../../../../common/icons/table-icon.svg")
                                                        }
                                                        alt=""
                                                    />
                                                )}
                                                {selectedTable.name}
                                            </div>
                                        );
                                    },
                                    onChange: (dataSourceTableID) => {
                                        if (dataSourceTableID != innerBlock.leftValue?.dataSourceTableID) {
                                            onChange(
                                                generateDefaultFilterInnerBlock(
                                                    tabledRelatedTo.find((t) => t.dataSourceTableID == dataSourceTableID),
                                                    innerBlock,
                                                    otherProps.dataSources
                                                )
                                            );
                                        }
                                    },
                                    isSelected: (dataSourceTableID) => dataSourceTableID == innerBlock.leftValue?.dataSourceTableID,
                                })}
                            </div>

                            <div className="col col-small">
                                <div className="text-only">contains</div>
                            </div>
                        </>
                    );
                }),
        ],
        [
            "renderOtherOption",
            ({state, $typeDisplay}, next) =>
                next(() => {
                    const rightColumnList = $typeDisplay.generateList("rightValue");
                    return (
                        <>
                            <div className="col col-large">
                                {InnerValueDropdown({
                                    filterType: innerBlock.leftValue?.$type,
                                    value: innerBlock.leftValue,
                                    onChange: (leftValue) =>
                                        onChange({
                                            ...innerBlock,
                                            leftValue: {
                                                $type: leftValue.modelTableID ? "ViewColumnBlockValue" : innerBlock.leftValue.$type,
                                                ...leftValue,
                                            },
                                        }),
                                    ...otherProps,
                                })}
                            </div>

                            <div className="col col-small">
                                {DropdownSelect2({
                                    label: "Comparison",
                                    list: compareOPs,
                                    valueToLabel: (s) => s.replace(/([A-Z])/g, " $1").trim(),
                                    onChange: (compareOp) => onChange({...innerBlock, compareOp}),
                                    isSelected: (type) => type == innerBlock.compareOp,
                                })}
                            </div>

                            <div className="col col-small">
                                {DropdownSelect2({
                                    label: "Value Type",
                                    list: rightColumnList.map((l) => l.$type),
                                    valueToLabel: (type) => rightColumnList.find((v) => v.$type == type)?.label,
                                    onChange: (type) => $typeDisplay.onChange("rightValue", type),
                                    isSelected: (type) => $typeDisplay.isSelected("rightValue", type),
                                })}
                            </div>

                            {innerBlock.rightValue?.$type && (
                                <div className="col col-large">
                                    {InnerValueDropdown({
                                        filterType: innerBlock.rightValue?.$type,
                                        value: innerBlock.rightValue,
                                        onChange: (rightValue) =>
                                            onChange({
                                                ...innerBlock,
                                                rightValue: {
                                                    $type: rightValue.modelTableID ? "ViewColumnBlockValue" : innerBlock.rightValue.$type,
                                                    ...rightValue,
                                                },
                                            }),
                                        ...otherProps,
                                    })}
                                </div>
                            )}
                        </>
                    );
                }),
        ],
        ({state, confirmDialog, renderFilterSelect, renderOtherOption, $typeDisplay}) => {
            const {leftTypeSelect, rightTypeSelect} = state.value;

            const leftColumnList = $typeDisplay.generateList("leftValue");
            const rightColumnLust = $typeDisplay.generateList("rightValue");

            return (
                <div className={cx("inner-block-o13", {"has-border": hasBorder}, {"filter-inner-block": filterInnerBlock})}>
                    <div className="row">
                        {!filterInnerBlock && (
                            <div className="col col-small">
                                {DropdownSelect2({
                                    label: "Value Type",
                                    list: leftColumnList.map((l) => l.$type),
                                    valueToLabel: (type) => leftColumnList.find((v) => v.$type == type)?.label,
                                    onChange: (type) => $typeDisplay.onChange("leftValue", type),
                                    isSelected: (type) => $typeDisplay.isSelected("leftValue", type),
                                })}
                            </div>
                        )}

                        {innerBlock.leftValue && (
                            <>{innerBlock.leftValue.$type == "TableBlockValue" ? renderFilterSelect() : renderOtherOption()}</>
                        )}

                        <div className="action">
                            {isLastChild ? (
                                <Button btnType="secondary" size="tiny" className="adn-btn" onClick={() => onAdd()}>
                                    AND
                                </Button>
                            ) : (
                                <div className="add-text">
                                    <b>ADD</b>
                                </div>
                            )}

                            {!hideRemove && (
                                <img
                                    className="remove-icon"
                                    onClick={async () => {
                                        const allowRemove = async () => {
                                            const answer = await confirmDialog.show();
                                            return answer === "confirm";
                                        };

                                        if (await allowRemove()) {
                                            onRemove();
                                        }
                                    }}
                                    src={require("../../../../../../../../common/icons/trash.svg")}
                                    alt=""
                                />
                            )}
                        </div>
                    </div>

                    {innerBlock.leftValue?.$type == "TableBlockValue" && (
                        <div className="filter-inner-block">
                            {innerBlock.rightValue.orAndBlock.outerBlocks.map((block, index) => (
                                <OuterBlock
                                    outerBlocksLength={innerBlock.rightValue.orAndBlock.outerBlocks.length}
                                    headerText={index == 0 ? null : "OR contains..."}
                                    filterInnerBlock
                                    outerBlock={block}
                                    key={block.timestamp}
                                    onChange={(updated) => {
                                        if (updated.innerBlocks.length == 0) {
                                            onChange({
                                                ...innerBlock,
                                                rightValue: {
                                                    $type: "FilterBlockValue",
                                                    orAndBlock: {
                                                        outerBlocks: innerBlock.rightValue.orAndBlock.outerBlocks.filter(
                                                            (o) => o.timestamp != block.timestamp
                                                        ),
                                                    },
                                                },
                                            });
                                        } else {
                                            onChange({
                                                ...innerBlock,
                                                rightValue: {
                                                    $type: "FilterBlockValue",
                                                    orAndBlock: {
                                                        outerBlocks: innerBlock.rightValue.orAndBlock.outerBlocks.map((o) =>
                                                            o.timestamp == block.timestamp ? updated : o
                                                        ),
                                                    },
                                                },
                                            });
                                        }
                                    }}
                                    {...otherProps}
                                    table={otherProps.tables.find((t) => t.dataSourceTableID == innerBlock.leftValue?.dataSourceTableID)}
                                />
                            ))}

                            <Button
                                btnType="border"
                                size="tiny"
                                onClick={() => {
                                    onChange({
                                        ...innerBlock,
                                        rightValue: {
                                            $type: "FilterBlockValue",
                                            orAndBlock: {
                                                outerBlocks: innerBlock.rightValue.orAndBlock.outerBlocks.concat({
                                                    timestamp: getTimestamp(),
                                                    innerBlocks: [
                                                        {
                                                            leftValue: {
                                                                $type: "ColumnBlockValue",
                                                            },
                                                            compareOp: "Equal",
                                                            timestamp: getTimestamp(),
                                                        },
                                                    ],
                                                }),
                                            },
                                        },
                                    });
                                }}
                            >
                                OR
                            </Button>
                        </div>
                    )}
                </div>
            );
        }
    );
