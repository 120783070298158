import {cs} from "@common/react/chain-services";
import {transformState} from "@common/react/transform-state";
import {parseDateTime} from "@common/logic/date-time/parse-date-time";
import {formatDateTime} from "@common/logic/date-time/format-date-time";
import {Calendar1} from "@common/form/date-picker/calendar1/calendar1";
import {AccessibleDatePicker} from "@common/ui-components/live/filters/date-picker/accessible-date-picker";

export const CalendarPicker = ({state: rootState, theme}) =>
    cs(
        [
            "state",
            (_, next) => {
                return next(
                    transformState({
                        state: rootState,
                        goingIn: (strDate) => parseDateTime(strDate)?.date,
                        gettingOut: (dateObj) => formatDateTime({date: dateObj}),
                    })
                )
            }
        ],
        [
            "picker",
            ({state}, next) => {
                return AccessibleDatePicker({
                    next,
                    value: state.value,
                    theme
                })
            },
        ],
        ({state, picker}) =>
            picker.render({
                onChange: (date) => {
                    state.onChange(date)
                },
                space: 4,
                width: 280
            })
    );
