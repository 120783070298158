import React from "react";
import {cs} from "@common/react/chain-services";
import {FieldSection} from "../field-section/field-section";
import "./map-location-fields.scss";
import {FieldInfoProvider} from "../../../../tile-tab/chart-types/common/field-info-provider/field-info-provider";
import {UseState} from "@common/react/use-state";
import {FieldControl} from "../render-field-control/field-control";
import {consumeContext} from "@common/react/context";
import {omit} from "@common/utils/objects";
import {SelectField} from "../select-field/select-field";
import {getFieldType} from "@common/ui-components/charts/common/get-field-type";
import {ConfigureField} from "../configure-field/configure-field";
import {scope} from "@common/react/scope";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";

export const MapLocationFields = ({tile, $type = "DimensionTileField", next, onRemove}) =>
    cs(
        consumeContext("tileFields"),
        consumeContext("getFieldInfo"),
        [
            "selecting",
            (_, next) =>
                UseState({
                    // initValue: {type, attr},
                    next,
                }),
        ],
        ["configuring", (_, next) => UseState({next})],
        ({configuring, selecting, getFieldInfo, tileFields}) => {
            return next({
                render: () =>
                    FieldSection({
                        header: FieldInfoProvider({
                            field: <div className="text">Location</div>,
                        }),
                        content: (
                            <div className="map-location-fields-24e">
                                {fieldList.map((f, i) => (
                                    <div className="field" key={i}>
                                        <div className="label">{f.label}</div>
                                        {!tile.value[f.attr] && (!f.constantAttr || !tile.value[f.constantAttr]) && (
                                            <div
                                                className="add-btn"
                                                onClick={() =>
                                                    selecting.onChange({
                                                        type: "add",
                                                        attr: f.attr,
                                                        constantAttr: f.constantAttr,
                                                    })
                                                }
                                            >
                                                <img src={require("./add-field-button.svg")} alt="" />
                                            </div>
                                        )}
                                        {tile.value[f.attr] &&
                                            FieldControl({
                                                field: tile.value[f.attr],
                                                getFieldInfo,
                                                onConfigure: () =>
                                                    configuring.onChange({
                                                        attr: f.attr,
                                                    }),
                                                onChangeField: () =>
                                                    selecting.onChange({
                                                        type: "change",
                                                        attr: f.attr,
                                                        constantAttr: f.constantAttr,
                                                    }),
                                                onRemove: () => (onRemove ? onRemove(tile.value[f.attr]) : tile.onChange(omit(tile.value, [f.attr]))),
                                            })}
                                        {f.constantAttr &&
                                            tile.value[f.constantAttr] &&
                                            FieldControl({
                                                constantValue: tile.value[f.constantAttr].value,
                                                onConfigure: () =>
                                                    configuring.onChange({
                                                        constantAttr: f.constantAttr,
                                                    }),
                                                onChangeField: () =>
                                                    selecting.onChange({
                                                        type: "change",
                                                        attr: f.attr,
                                                        constantAttr: f.constantAttr,
                                                    }),
                                                onRemove: () => (onRemove ? onRemove(tile.value[f.constantAttr]) : tile.onChange(omit(tile.value, [f.constantAttr]))),
                                            })}
                                    </div>
                                ))}
                            </div>
                        ),
                    }),
                override: (() => {
                    if (selecting.value) {
                        // the handleAddField and handleChangeField are used for data fields only
                        const handleAddField = (field1) => {
                            tile.onChange({
                                ...tile.value,
                                [selecting.value.attr]: {...field1, $type},
                                ...(selecting.value.constantAttr && {
                                    [selecting.value.constantAttr]: null,
                                }),
                            });
                            selecting.onChange(null);
                        };

                        const handleChangeField = (field1) => {
                            tile.onChange({
                                ...tile.value,
                                [selecting.value.attr]: {
                                    ...tile.value[selecting.value.attr],
                                    ...field1,
                                },
                                ...(selecting.value.constantAttr && {
                                    [selecting.value.constantAttr]: null,
                                }),
                            });
                            selecting.onChange(null);
                        };

                        return (
                            <SelectField
                                {...{
                                    label: `Select Field`,
                                    onCancel: () => selecting.onChange(null),
                                    actionType: selecting.value.type,
                                    onSelect: selecting.value.type === "add" ? handleAddField : handleChangeField,
                                    ...(selecting.value.type === "change" && {
                                        allowedFieldType: getFieldType(tile.value[selecting.value.attr]),
                                    }),
                                    $type,
                                    notAllowedFields: tileFields.allFields,
                                    field: scope(tile, [selecting.value.attr]),
                                    ...(selecting.value.constantAttr &&
                                        (() => {
                                            const {constantList, label} = fieldList.find((f) => f.constantAttr === selecting.value.constantAttr);
                                            const constantField = scope(tile, [selecting.value.constantAttr]);
                                            return {
                                                enableConstantValue: true,
                                                onChooseValueType: (useConstantValue) => {
                                                    // set default constant value when choosing to use constant value
                                                    // do not let both data field and constant value be specified at a same time
                                                    if (useConstantValue) {
                                                        const defaultValue = constantList.find((c) => c.isDefault);
                                                        tile.onChange({
                                                            ...tile.value,
                                                            [selecting.value.attr]: null,
                                                            [selecting.value.constantAttr]: {
                                                                value: defaultValue.value,
                                                                displayName: defaultValue.label,
                                                            },
                                                        });
                                                    } // else do nothing
                                                },
                                                constantField,
                                                constantFieldSelector: () => {
                                                    return DropdownSelect({
                                                        label: label.replace(" level", ""),
                                                        list: constantList,
                                                        valueToLabel: (v) => v.label,
                                                        isSelected: (v) => v.value === constantField.value?.value,
                                                        onChange: (v) =>
                                                            constantField.onChange({
                                                                value: v.value,
                                                                displayName: v.label,
                                                            }),
                                                    });
                                                },
                                            };
                                        })()),
                                }}
                            />
                        );
                    } else if (configuring.value != null) {
                        return ConfigureField({
                            field: configuring.value.attr && scope(tile, [configuring.value.attr]),
                            getFieldInfo,
                            ...(configuring.value.constantAttr && {
                                constantField: scope(tile, [configuring.value.constantAttr]),
                                label: "Constant Value Configuration",
                            }),
                            // hideDisplayName,
                            // hideSort,
                            // notAllowNoneAggregation,
                            onRemove: () => {
                                if (onRemove) {
                                    onRemove(tile.value[configuring.value.attr || configuring.value.constantAttr]);
                                } else {
                                    tile.onChange(omit(tile.value, [configuring.value.attr || configuring.value.constantAttr]));
                                }
                                configuring.onChange(null);
                            },
                            onCancel: () => configuring.onChange(null),
                        });
                    }
                })(),
            });
        }
    );

const fieldList = [
    {
        label: "Country level",
        attr: "countryField",
        constantAttr: "countryValueConstant",
        constantList: [
            {label: "Australia", value: "AUS"},
            {label: "Canada", value: "CAN"},
            {label: "China", value: "CHN"},
            {label: "United Kingdom", value: "GBR"},
            {label: "India", value: "IND"},
            {label: "United States of America", value: "USA", isDefault: true},
        ],
    },
    {
        label: "State/Province level",
        attr: "provinceField",
    },
    {
        label: "County level",
        attr: "countyField",
    },
    {
        label: "Zip code / Postal code level",
        attr: "postalCodeField",
    },
];
