// elem types to map: title, label, other
// title: tile titles
// label: Axis Label, Table Header and Table Total (medium weight) and Download button text = same size but regular weight.
// other: legends, data labels, grid step label, hover tooltips, Table Rows

const fontSizeGroups = {
    ExtraSmall: {
        title: 12,
        title_icon: 12,
        label: 11,
        other: 10,
        text: 11,
        section: 16,
        section_title_icon: 16,
        hover: 10,
    },
    Small: {
        title: 15,
        title_icon: 14,
        label: 13,
        other: 11,
        text: 13,
        section: 20,
        section_title_icon: 18,
        hover: 11,
    },
    Medium: {
        title: 18,
        title_icon: 16,
        label: 15,
        other: 12,
        text: 15,
        section: 24,
        section_title_icon: 20,
        hover: 12,
    },
    Large: {
        title: 21,
        title_icon: 18,
        label: 17,
        other: 13,
        text: 17,
        section: 28,
        section_title_icon: 24,
        hover: 13,
    },
    ExtraLarge: {
        title: 24,
        title_icon: 20,
        label: 19,
        other: 14,
        text: 19,
        section: 32,
        section_title_icon: 24,
        hover: 14,
    },
};

const cGetFontSize =
    (themeFontSizeGroup) =>
    ({group, elemType} = {}) => {
        return fontSizeGroups[group || themeFontSizeGroup || "Medium"][elemType || "other"];
    };
exports.cGetFontSize = cGetFontSize;

// const fontSizeMap = {
//     "ExtraSmall": "x-small",
//     "Small": "small",
//     "Medium": "medium",
//     "Large": "large",
//     "ExtraLarge": "x-large",
// };
//
// const fontSizeMap2 = {
//     "ExtraSmall": 9,
//     "Small": 12,
//     "Medium": 15,
//     "Large": 18,
//     "ExtraLarge": 24,
// };

// const getFontSize = (fontsize) => fontSizeMap[fontsize];
// exports.getFontSize = getFontSize;

// const getFontSize2 = (fontsize) => fontSizeMap2[fontsize];
// exports.getFontSize2 = getFontSize2;
