import "./edit-container-form.scss";

import * as React from "react";

import {cs} from "@common/react/chain-services";
import {Expandable} from "../../../../../common/expandable/expandable";
import {scope} from "@common/react/scope";
import {FontSizeSelect} from "../../../../tile/edit/left-panel/tabs/tile-tab/chart-types/common/font-size-select";
import {ShowHideToggle} from "@common/form/toggles/show-hide-toggle";
import {TextInput} from "@common/form/text-input/text-input";
import {ffToTextInput} from "@common/form/ff-to-text-input";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {MobileWarning} from "../../../../tile/edit/left-panel/tabs/tile-tab/chart-types/common/mobile-warning/mobile-warning";
import {FieldControl} from "../../../../tile/edit/left-panel/tabs/fields-tab/chart-types/common/render-field-control/field-control";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {UseState} from "@common/react/use-state";
import {consumeContext} from "@common/react/context";

export const EditContainerForm = ({containerTile, form, onAddTile, onEditTile, onRemoveTile}) =>
    cs(({}) => (
        <div className="edit-container-form-de3">
            <div className="add-new-tile">
                <div className="btn" onClick={onAddTile}>
                    Add New Tile
                </div>
            </div>

            <Expandable
                {...{
                    label: "Display",
                    render: () => (
                        <div className="edit-container-display">
                            {DisplayTypeSelect(scope(containerTile, ["style", "displayType"]))}
                            {FontSizeSelect(scope(containerTile, ["style", "fontSize"]))}
                        </div>
                    ),
                }}
            />

            <Expandable
                {...{
                    label: "Arrangement",
                    render: () =>
                        ArrangementTiles({
                            tiles: scope(containerTile, ["tiles"]),
                            onRemoveTile,
                            onEditTile,
                        }),
                }}
            />

            <Expandable
                {...{
                    label: "Container Title",
                    control: ShowHideToggle({disabled: true, state: {value: false, onChange: () => null}}),
                    render: () => (
                        <div className="combo-chart-tile-title-43l">
                            {TextInput({
                                label: "Tile Title",
                                ...ffToTextInput(form.field(["title"])),
                            })}
                        </div>
                    ),
                }}
            />
        </div>
    ));

const DisplayTypeSelect = ({value, onChange}) => {
    return DropdownSelect({
        info: "You can customize the display settings in the theme.",
        directionTooltip: "top",
        label: (
            <div>
                Display Type &nbsp;
                {MobileWarning({
                    info: "To avoid scrolling navigation, display type will convert to dropdown for user with screens too small to display the full navigation.",
                })}
            </div>
        ),
        list: [
            {label: "Tabs", value: "Tabs"},
            {label: "Buttons", value: "Buttons"},
            {label: "Dropdown", value: "Dropdown"},
        ],
        valueToLabel: (v) => v.label,
        isSelected: (v) => v.value === value,
        onChange: (v) => onChange(v.value),
    });
};

const ArrangementTiles = ({tiles, onRemoveTile, onEditTile}) =>
    cs(consumeContext("collection"), ["dragging", (_, next) => UseState({next, initValue: false})], ({dragging, collection}) => {
        return (
            <DragDropContext
                onDragStart={() => {
                    dragging.onChange(true);
                }}
                onDragEnd={(result) => {
                    const {destination, source, draggableId} = result;
                    dragging.onChange(false);

                    if (!destination) {
                        return;
                    }

                    if (destination.droppableId === source.droppableId && destination.index === source.index) {
                        return;
                    }

                    const start = tiles.value;
                    const newFields = Array.from(start);
                    let newField = start.find((s) => s.id == draggableId);

                    newFields.splice(source.index, 1);
                    newFields.splice(destination.index, 0, newField);
                    tiles.onChange(newFields);
                }}
            >
                <Droppable droppableId="droppable-place" type="droppable-place">
                    {(provided) => (
                        <div className="tiles-dnd-4fs" {...provided.droppableProps} ref={provided.innerRef}>
                            {tiles.value.map((tile, index) => (
                                <Draggable key={tile.id} draggableId={tile.id} index={index}>
                                    {(provided) => (
                                        <TileItem
                                            key={index}
                                            {...{
                                                tile: tiles.value[index],
                                                onEdit: onEditTile,
                                                onRemove: onRemoveTile,
                                                provided,
                                                dragging: dragging.value,
                                                hasError: (collection.value.tileModelErrors ?? []).find((e) => e.tileID == tile.id),
                                            }}
                                        />
                                    )}
                                </Draggable>
                            ))}

                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    });
const TileItem = ({tile, onEdit, onRemove, getRef, provided, dragging, hasError}) =>
    FieldControl({
        provided,
        dragging,
        getRef,
        field: {id: tile.id},
        title: tile.title,
        onEdit: () => onEdit(tile),
        onRemove: () => onRemove(tile),
        hasTileError: hasError,
    });
