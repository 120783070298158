import "./colors-tile-config.scss";

import React from "react";

import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {changePath, getPath} from "@common/utils/arr-path";
import {getAvailableFields} from "@common/ui-components/charts/get-field-color";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {isDisableColorConfigs} from "@common/ui-components/charts/get-field-color";
import {getFieldType} from "@common/ui-components/charts/common/get-field-type";

import {FieldItem} from "../../../../../../edit/common/field-item/field-item";
import {LeftPanelClosable} from "../../../../../../../common/left-panel-closable/left-panel-closable";
import {FieldSection} from "../../fields-tab/chart-types/common/field-section/field-section";
import {FieldInfoProvider} from "../chart-types/common/field-info-provider/field-info-provider";

export const ColorsTileConfig = ({next: rootNext, tile, theme}) =>
    cs(
        (_, next) => (isDisableColorConfigs(tile?.value) ? rootNext(null) : next()),
        ["selecting", (_, next) => UseState({next})],
        ["fields", (_, next) => next(tile && theme ? getAvailableFields({tile, theme}) : [])],
        [
            "renderAppliedColorDropDown",
            ({fields, selecting}, next) => {
                return next(({updatePath, dimensionFieldProp}) => {
                    const isTypeDate = getFieldType(tile.value[dimensionFieldProp]) === "date";

                    if (isTypeDate || !["VerticalBarChartTile", "HorizontalBarChartTile", "ComboChartTile", "BoxPlotTile"].includes(tile.value.$type)) {
                        return null;
                    }

                    const colorAppliedProps = ["BoxPlotTile"].includes(tile.value.$type) ? "boxPlotColorAppliedBy" : "barDataColorAppliedBy";

                    const list = [
                        {
                            label: "Single Color",
                            value: "Measure",
                            color: {
                                $type: "DiscreteColorApplication",
                                index: 0,
                            },
                        },
                        {
                            label: "Color Per Dimension",
                            value: "Dimension",
                            color: {
                                $type: "SchemeColorApplication",
                                index: 0,
                                type: "Categorical",
                            },
                        },
                    ];

                    return (
                        <div className="applied-color-by">
                            {DropdownSelect({
                                list,
                                valueToLabel: (v) => v.label,
                                isSelected: (v) => v.value === tile?.value?.style[colorAppliedProps],
                                // disabled: isTypeDate,
                                onChange: (v) => {
                                    let _tile = changePath(tile.value, ["style", "legendStyle", "legendShown"], () =>
                                        v.value === "Dimension" ? false : tile.value.style.legendStyle.legendShown
                                    );

                                    _tile = changePath(_tile, ["style", colorAppliedProps], () => v.value);

                                    _tile = changePath(_tile, updatePath, () => v.color);

                                    tile.onChange(_tile);
                                    const _fields = getAvailableFields({
                                        tile: {value: _tile},
                                        theme,
                                    });
                                    selecting.onChange(_fields.find((item) => item.field.id == selecting.value.field.id));
                                },
                                label: "Color Application",
                            })}
                        </div>
                    );
                });
            },
        ],
        ({selecting, renderAppliedColorDropDown, fields}) =>
            rootNext({
                render: () => {
                    const isComboChart = tile.value.$type == "ComboChartTile";

                    const renderField = (item) => {
                        const {field, colorConfig} = item;
                        if (!colorConfig.$type) {
                            return null;
                        }

                        return (
                            <FieldItem
                                className="field-color-bbg"
                                label={
                                    <div>
                                        <div className="field-name">{field.displayName}</div>
                                        <div className="selected-color">{renderColor[colorConfig.$type](colorConfig)}</div>
                                    </div>
                                }
                                key={field.id}
                                rightIcon={<i className="far fa-arrow-right" aria-hidden="true" />}
                                onClick={() => selecting.onChange(item)}
                            />
                        );
                    };

                    return !isComboChart ? (
                        fields.map(renderField)
                    ) : (
                        <>
                            {FieldSection({
                                header: FieldInfoProvider({
                                    field: <div className="text">BARS</div>,
                                }),
                                content: fields.filter((f) => f.isBarField).map(renderField),
                            })}
                            {FieldSection({
                                header: FieldInfoProvider({
                                    field: <div className="text">LINES</div>,
                                }),
                                content: fields.filter((f) => !f.isBarField).map(renderField),
                            })}
                        </>
                    );
                },
                override:
                    selecting.value &&
                    cs(
                        ({}, next) => (
                            <LeftPanelClosable
                                {...{
                                    onClose: () => selecting.onChange(false),
                                    label: selecting.value.field.displayName + " Color",
                                    content: next(),
                                }}
                            />
                        ),
                        ({}) => {
                            const {field, colorConfig, dimensionFieldProp, isBarField} = selecting.value;
                            const {colorSchemes, discreteColorsRGB} = getPath(theme, ["dataVisualization", "dataColorPalettes"]);
                            let updatePath = ["style"];

                            if (colorConfig.isDictionary) {
                                updatePath = updatePath.concat(colorConfig.parent ? [colorConfig.parent, field.id] : [colorConfig.prop, field.id]);
                            } else {
                                updatePath = updatePath.concat(colorConfig.prop);
                            }

                            const updateFieldColorConfig = (value) => {
                                const _tile = changePath(tile.value, updatePath, () => value);
                                tile.onChange(_tile);
                                const _fields = getAvailableFields({
                                    tile: {value: _tile},
                                    theme,
                                });
                                selecting.onChange(_fields.find((item) => item.field.id == selecting.value.field.id));
                            };

                            const showApplyColorsDropDown = !tile.value.groupField && fields.filter((f) => f.isBarField).length == 1 && isBarField;
                            return (
                                <div className="colors-tile-config-vrf">
                                    {showApplyColorsDropDown &&
                                        renderAppliedColorDropDown({
                                            dimensionFieldProp,
                                            updatePath,
                                        })}

                                    {colorConfig.$type == "DiscreteColorApplication" && (
                                        <div className="default-padding">
                                            {discreteColorsRGB.map((color, index) => (
                                                <FieldItem
                                                    label={
                                                        <div>
                                                            COLOR {index + 1}
                                                            <div
                                                                className="discrete-color"
                                                                style={{
                                                                    background: color,
                                                                }}
                                                            />
                                                        </div>
                                                    }
                                                    key={index}
                                                    rightIcon={colorConfig.index == index && <i className="material-icons">done</i>}
                                                    onClick={() =>
                                                        updateFieldColorConfig({
                                                            $type: colorConfig.$type,
                                                            index,
                                                        })
                                                    }
                                                />
                                            ))}
                                        </div>
                                    )}

                                    {colorConfig.$type == "SchemeColorApplication" && (
                                        <>
                                            {FieldSection({
                                                header: FieldInfoProvider({
                                                    field: <div className="text">CATEGORICAL</div>,
                                                    noCollapsible: true,
                                                }),
                                                content: colorSchemes.categoricalColorSchemes.map((section, index) => (
                                                    <FieldItem
                                                        label={
                                                            <div>
                                                                CATEGORICAL SCHEME {index + 1}
                                                                <div className="colors-list">
                                                                    {section.colorsRGB.map((color, index) => (
                                                                        <div
                                                                            className="color"
                                                                            key={index}
                                                                            style={{
                                                                                background: color,
                                                                            }}
                                                                        />
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        }
                                                        key={index}
                                                        rightIcon={colorConfig.type == "Categorical" && colorConfig.index == index && <i className="material-icons">done</i>}
                                                        onClick={() =>
                                                            updateFieldColorConfig({
                                                                $type: colorConfig.$type,
                                                                index,
                                                                type: "Categorical",
                                                            })
                                                        }
                                                    />
                                                )),
                                            })}

                                            {FieldSection({
                                                header: FieldInfoProvider({
                                                    field: <div className="text">SEQUENTIAL</div>,
                                                    noCollapsible: true,
                                                }),
                                                content: colorSchemes.sequentialColorSchemes.map((section, index) => (
                                                    <FieldItem
                                                        label={
                                                            <div>
                                                                SEQUENTIAL SCHEME {index + 1}
                                                                <div
                                                                    className="color-gradient"
                                                                    style={{
                                                                        background: `linear-gradient(to right, ${section.colorsRGB.join(",")})`,
                                                                    }}
                                                                />
                                                            </div>
                                                        }
                                                        key={index}
                                                        rightIcon={colorConfig.type == "Sequential" && colorConfig.index == index && <i className="material-icons">done</i>}
                                                        onClick={() =>
                                                            updateFieldColorConfig({
                                                                $type: colorConfig.$type,
                                                                index,
                                                                type: "Sequential",
                                                            })
                                                        }
                                                    />
                                                )),
                                            })}
                                        </>
                                    )}
                                </div>
                            );
                        }
                    ),
            })
    );

export const renderColor = {
    DiscreteColorApplication: ({index, displayColor}) => (
        <>
            <div className="discrete-color" style={{background: displayColor}} />
            <div className="color-name">Color {index + 1}</div>
        </>
    ),
    SchemeColorApplication: ({index, displayColor, type}) =>
        type == "Sequential" ? (
            <>
                <div
                    className="color-gradient"
                    style={{
                        background: `linear-gradient(to right, ${displayColor.join(",")})`,
                    }}
                />
                <div className="color-name">Color Gradient {index + 1}</div>
            </>
        ) : (
            <>
                <div className="colors-list">
                    {displayColor.map((color, index) => (
                        <div className="color" key={index} style={{background: color}} />
                    ))}
                </div>

                <div className="color-name">Color Scheme {index + 1}</div>
            </>
        ),
};
