import {LoginRoute} from "../../routes/guest-routes/login/login-route";
import {ForgotPasswordRoute} from "../../routes/guest-routes/forgot-password/forgot-password-route";
import {ResetPasswordRoute} from "../../routes/guest-routes/reset-password/reset-password-route";
import {RegisterRoute} from "../../routes/guest-routes/register/register-route";
import {ApiCollectionLogin} from "../../routes/api-collection/login/api-collection-login";
import {ApiCollectionDocs} from "../../routes/api-collection/docs/api-collection-docs";
import {commonRoutes} from "../common-routes";
import {TestRoute} from "../../routes/test-route/test-route";

export const guestRoutes = [
    ...commonRoutes,
    {
        name: "login",
        path: "/login",
        query: ["email", "intendedRoute~", "message"],
        route: {
            component: LoginRoute,
        },
    },
    {
        name: "register",
        path: "/register",
        query: ["inviteToken", "inviteID"],
        route: {
            component: RegisterRoute,
        },
    },
    {
        name: "forgot-password",
        path: "/forgot-password",
        query: ["email~", "intendedRoute~"],
        route: {
            component: ForgotPasswordRoute,
        },
    },
    {
        name: "reset-password",
        path: "/reset-password",
        query: ["email~", "intendedRoute~", "resetToken"],
        route: {
            component: ResetPasswordRoute,
        },
    },
    {
        name: "test-route",
        path: "/test-route",
        query: ["tab"],
        route: {
            component: TestRoute,
        },
    },
];
