import React from "react";
import {cx, css} from "emotion";
import {cs} from "@common/react/chain-services";
import {StyledClass} from "@common/react/styled-class";

const SpinnerIcon = ({className, fill = "#919EB0"}) =>
    cs(
        [
            "spinnerCss",
            ({}, next) =>
                StyledClass({
                    content: {
                        "@keyframes rotateCW": {
                            "0%": {
                                transform: "rotate(0deg)",
                            },
                            "100%": {
                                transform: "rotate(360deg)",
                            },
                        },
                        animation: "rotateCW 1.2s infinite linear",
                    },
                    next,
                }),
        ],
        ({spinnerCss}) => {
            return (
                <svg
                    className={cx(spinnerCss)}
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="24.415"
                    height="24.415"
                    viewBox="0 0 24.415 24.415"
                    fill="#919eb1"
                >
                    <defs>
                        <clipPath id="clip-path">
                            <rect id="Rectangle_2326" data-name="Rectangle 2326" width="24" height="24" />
                        </clipPath>
                    </defs>
                    <g
                        id="Loading_Animated_Icon"
                        data-name="Loading Animated Icon"
                        transform="matrix(1, -0.017, 0.017, 1, 0, 0.419)"
                        clipPath="url(#clip-path)"
                    >
                        <path
                            id="Path_682"
                            data-name="Path 682"
                            d="M12,24A12,12,0,1,1,24,12a1.975,1.975,0,0,1-3.951,0A8.049,8.049,0,1,0,12,20.049,1.975,1.975,0,0,1,12,24"
                            transform="translate(0 0)"
                        />
                    </g>
                </svg>
            );
        }
    );

export default SpinnerIcon;
