import * as React from "react";
import {cs} from "@common/react/chain-services";
import {UnpublishedIcon} from "../../../../common/icons/unpublished-icon";
import {createDateFormatter} from "@common/ui-components/charts/common/formatters/date-formatter";
import {tooltipService3} from "../../../../common/tooltip3/tooltip-service-3";

export const TransformationPublishInfo2 = ({table, next}) => {
    const isCalculated = table?.$type === "CalculatedModelColumn";

    const {versionDetails, publishedOn, isPublished = true, isValid = true} = table;
    const lastPublishedOn =
        publishedOn &&
        (() => {
            const publishedOn1 = new Date(publishedOn);
            return `${createDateFormatter("MMM d, yyyy h:mm tt").format(publishedOn1)}`;
        })();

    const latestNotPublished = () =>
        !(!isValid || !isPublished || !versionDetails || versionDetails === "NotPublished" || versionDetails === "LatestPublished");

    const getTooltipContent = () => {
        if (!isValid) {
            return "Cannot Add. Incomplete Transformation";
        }

        if (!isPublished || !versionDetails || versionDetails === "NotPublished") {
            return "Cannot Add. Never Published";
        }
        if (versionDetails === "LatestPublished") {
            return (
                <div className="publishing-tooltip">
                    <strong>Current Version Live</strong>
                    <br />
                    Last Published: {lastPublishedOn}
                </div>
            );
        }

        return (
            <div className="publishing-tooltip">
                <strong>Unpublished changes</strong>
                <br />
                Last Published: {lastPublishedOn}
            </div>
        );
    };

    const getTooltipCalculatedContent = () => {
        if (!isValid) {
            return "Cannot Add. Incomplete Transformation";
        }

        if (!isPublished || !versionDetails || versionDetails === "NotPublished") {
            return "Calculated column not included in the model. Edit the column and publish it to include it in the model.";
        }
        if (versionDetails === "LatestPublished") {
            return (
                <div className="publishing-tooltip">
                    <strong>Current Version Live</strong>
                    <br />
                    Last Published: {lastPublishedOn}
                </div>
            );
        }

        return (
            <div className="publishing-tooltip">
                <strong>
                    Calculated column is out of date. Latest version is not included in the model. Edit the column and publish it to include
                    it in the model.
                </strong>
                <br />
                Last Published: {lastPublishedOn}
            </div>
        );
    };

    return cs(tooltipService3({direction: "below"}), ({tooltip}) =>
        next({
            renderIndicator: () =>
                versionDetails === "LatestPublished" ? (
                    <span
                        {...{
                            className: "published-icon",
                            ...tooltip(isCalculated ? getTooltipCalculatedContent() : getTooltipContent()),
                        }}
                    >
                        <img src={require("../../../../common/icons/published-icon.svg")} alt="" />
                    </span>
                ) : (
                    <span
                        {...{
                            className: "not-published-icon",
                            ...tooltip(isCalculated ? getTooltipCalculatedContent() : getTooltipContent()),
                        }}
                    >
                        {UnpublishedIcon({fillColor: "#546B81"})}
                    </span>
                ),
            isActive: versionDetails !== "NotPublished",
            latestNotPublished,
        })
    );
};
