import "./list-sort-order.scss";

import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {scope} from "@common/react/scope";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {SearchableSelect} from "@common/ui-components/searchable-select/searchable-select";
import {cascadeCollect} from "@common/utils/cascade";
import {keepOnly} from "@common/utils/objects";
import {NumberAggregationFunctionSelect} from "../../../../../number-aggregation-function-select/number-aggregation-function-select";
import {filterTypesToColumnTypes} from "../../../../filter-left-panel/filter-configs";

const FieldSelect = ({filter, value, onChange, field, label}) =>
    cs(consumeContext("modelForCollection"), ({modelForCollection: model}) =>
        SearchableSelect({
            label: label || "Field",
            forcedExpandBottom: false,
            hasError: field.error,
            domRef: field.domRef,
            list: cascadeCollect(
                model,
                "tables[*].columns[*]",
                (column, {}, {1: table}) =>
                    filterTypesToColumnTypes[filter.value.$type].includes(column.dataType) &&
                    column.$type !== "AggregatedMeasureModelColumn" && {
                        table,
                        column,
                    }
            ),
            valueToLabel: ({column}) => column.visualNameFull,
            isSelected: ({column, table}) =>
                value.modelID === model.id && value.modelTableID === table.id && value.modelColumnID === column.id,
            onChange: ({column, table}) =>
                onChange({
                    modelID: model.id,
                    modelTableID: table.id,
                    modelColumnID: column.id,
                }),
        })
    );

export const ListSortOrder = ({filter, form}) =>
    cs(() => {
        const selectableListSort = scope(filter, ["selectableListSort"]);

        return (
            <div className="list-sort-order-89o list-sort-order">
                <div className="section">
                    {DropdownSelect({
                        label: "List Sort Order",
                        list: [
                            {label: "Sort List Alphabetically", value: null},
                            {
                                label: "Sort by Other Value",
                                value: selectableListSort.value ?? {},
                            },
                        ],
                        valueToLabel: (item) => item.label,
                        isSelected: (v) => (v.value == null ? v.value === selectableListSort.value : selectableListSort.value),
                        onChange: (v) =>
                            selectableListSort.onChange(
                                v.value == null
                                    ? null
                                    : selectableListSort.value ?? {
                                          aggregationFunc: "None",
                                          sortDirection: "Asc",
                                      }
                            ),
                    })}
                </div>

                {selectableListSort.value && (
                    <div className="sort-criteria">
                        <div className="header">Sort Criteria</div>
                        <div className="content">
                            <div className="left">
                                {FieldSelect({
                                    filter,
                                    value: keepOnly(selectableListSort.value, ["modelID", "modelColumnID", "modelTableID"]),
                                    onChange: (value) =>
                                        selectableListSort.change((old) => ({
                                            ...old,
                                            ...value,
                                        })),
                                    label: "Value Field",
                                    field: form.field(["selectableListSort"]),
                                })}
                            </div>

                            <div className="right">
                                {NumberAggregationFunctionSelect({
                                    label: "Field Aggregation Type",
                                    state: form.field(["selectableListSort", "aggregationFunc"]).state,
                                })}
                            </div>

                            <div className="right">
                                {DropdownSelect({
                                    label: "Direction",
                                    list: [
                                        {label: "Ascending", value: "Asc"},
                                        {label: "Descending", value: "Desc"},
                                    ],
                                    valueToLabel: (v) => v.label,
                                    isSelected: (v) => selectableListSort.value.sortDirection == v.value,
                                    onChange: (v) =>
                                        selectableListSort.change((old) => ({
                                            ...old,
                                            sortDirection: v.value,
                                        })),
                                })}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    });
