import * as React from "react";
import {ShowHideToggle} from "../../../../../../../../../../../../../common/form/toggles/show-hide-toggle";
import "./legend.scss";
import {scope} from "@common/react/scope";
import {LegendPositionSelect} from "../../../common/legend-position-select";
import {HiddenFieldAlert} from "../../../common/hidden-field-alert/hidden-field-alert";

export const Legend = ({tile, size}) => {
    const control = (() => {
        if (isScatterLegendHidden({size})) {
            return HiddenFieldAlert({
                info: "Legend can only be shown when the chart is at least 8 squares wide and 7 squares high.",
            });
        }
        if (!tile.value.colorGroupField && !tile.value.shapeGroupField) {
            return HiddenFieldAlert({
                info: "Legend not available when there is no Group.",
            });
        }
        return ShowHideToggle({
            state: scope(tile, ["style", "legendStyle", "legendShown"]),
        });
    })();

    return {
        label: "Legend",
        control,
        render: () => (
            <div className="scatter-plot-legend-ad4">
                <div className="position-select">{LegendPositionSelect(scope(tile, ["style", "legendStyle", "legendPosition"]), size)}</div>
            </div>
        ),
    };
};

export const isScatterLegendHidden = ({size}) => size.width < 8 || size.height < 7;
