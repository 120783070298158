import * as React from "react";

import {cs} from "@common/react/chain-services";
import {getELoc} from "@common/react/get-e-loc";
import {TransformationPublishInfo2} from "../../common/transformation-publish-info2";
import {DragWatcher} from "../../../../common/drag-watcher";
import {TableLabel} from "../../common/table-label/table-label";

export const TableLabelSection = ({status, modelTable, table, ds, chart, color}) => {
    return cs(
        [
            "publishInfo",
            (_, next) =>
                table?.$type === "ViewModelTable"
                    ? TransformationPublishInfo2({
                          table,
                          next,
                      })
                    : next(),
        ],
        [
            "dragWatcher",
            (_, next) =>
                DragWatcher({
                    onDragged: (e) => {
                        chart.startDraggingNewTable({
                            table,
                            datasource: ds,
                            color,
                            pos: getELoc(e),
                        });
                    },
                    next,
                }),
        ],
        ({dragWatcher, publishInfo}) => {
            const calculatedColumns = modelTable?.columns.filter((c) => c.$type === "CalculatedModelColumn") || [];
            const aggregatedMeasures =
                modelTable?.columns.filter((c) => c.$type === "AggregatedMeasureModelColumn") || [];

            return (
                <div
                    className="table-label-wrapper"
                    data-table-id={table.id}
                    onMouseDown={(e) => {
                        if (status === "none") {
                            dragWatcher.onMouseDown(e);
                        }
                    }}
                >
                    {TableLabel({
                        table: modelTable,
                        name: modelTable?.visualName || table.visualName,
                        highlights: table?.ranges,
                        usageStatus: status,
                        customColor: color,
                        publishInfo,
                        hasCalculatedColumns: calculatedColumns?.length > 0,
                        hasCalculatedColumnNotPublished: calculatedColumns.find(
                            (c) =>
                                !c.calculations[0]?.versionDetails ||
                                c.calculations[0]?.versionDetails === "NotPublished"
                        ),
                        hasAggregatedMeasures: aggregatedMeasures?.length > 0,
                    })}
                </div>
            );
        }
    );
};
