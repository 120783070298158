import {Expandable} from "../../../../common/expandable/expandable";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {ffToDropdown} from "../../../../../../../common/form/ff-to-dropdown";
import * as React from "react";

export const LegendStyles = ({form, sectionInitClosed}) => (
    <Expandable
        {...{
            initExpand: !sectionInitClosed,
            label: "Legend Styles",
            render: () => (
                <div className="panel-22">
                    {DropdownSelect({
                        label: "Symbol Shapes",
                        ...ffToDropdown(form.field(["legendSymbol"])),
                        list: ["Circle", "Square"],
                    })}
                </div>
            ),
        }}
    />
);
