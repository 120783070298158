import {cs} from "@common/react/chain-services";
import {StaticTooltipService} from "../../../../../../web-client/src/routes/common/tooltip3/static-tooltip-service";
import {AlertIcon} from "@common/ui-components/icons/global-icons";
import {createDateFormatter} from "@common/ui-components/charts/common/formatters/date-formatter";

export const CollectionErrorBadgeWithTooltip = ({className, error}) =>
    !error
        ? null
        : cs(
              [
                  "staticTooltip",
                  (_, next) =>
                      StaticTooltipService({
                          direction: "right",
                          info: (
                              <div>
                                  <b>
                                      {error.tableName}.{error.columnName}
                                  </b>{" "}
                                  Removed on{" "}
                                  {createDateFormatter("MMM d, yyyy @ h:mm tt").format(new Date(error?.deletedInfo?.deletedDateUtc))} by{" "}
                                  {error.deletedInfo?.deletedBy}
                              </div>
                          ),
                          next,
                      }),
              ],
              ({staticTooltip}) =>
                  staticTooltip.renderIcon({
                      icon: <AlertIcon />,
                      className: className,
                  })
          );
