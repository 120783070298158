import {cs} from "@common/react/chain-services";
import * as React from "react";
import {ComparisonOptions} from "./comparison-options/comparison-options";
import {DefaultFilterCriteria} from "./default-filter-criteria/default-filter-criteria";
import {consumeContext} from "@common/react/context";
import {TextInput} from "../../../../../../../../../common/form/text-input/text-input";
import {ffToTextInput} from "../../../../../../../../../common/form/ff-to-text-input";
import {scope} from "@common/react/scope";
import {MpLine} from "../../common/mp-line/mp-line";
import {ShowHideToggle} from "../../../../../../../../../common/form/toggles/show-hide-toggle";
import {getPath} from "@common/utils/arr-path";
import {TextareaInput} from "../../../../../tile/edit/left-panel/tabs/tile-tab/chart-types/common/textarea-input/textarea-input";
import {DebounceTextInput} from "../../../../../../../../../common/form/debounce-input/debounce-text-input";
import "./number-filter-options.scss";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {numberFilterControlTypes} from "./number-filter-control-types";
import {stateToSelect} from "@common/ui-components/form/state-to-select";
import {OnOffToggle} from "../../../../../../../../../common/form/toggles/on-off-toggle";
import {ffToDropdown} from "../../../../../../../../../common/form/ff-to-dropdown";
import {BlanksMessage} from "./blanks-message/blanks-message";
import {Watch} from "@common/react/watch";

export const NumberFilterOptions = ({filter, form}) =>
    cs(
        consumeContext("collection"),
        (_, next) => (
            <>
                {Watch({
                    value: filter.value.aggregationFunc,
                    onChanged: (value) => {
                        if (value !== "None" && ["InList", "NotInList"].includes(filter.value.defaultOption)) {
                            filter.change((f) => ({
                                ...f,
                                defaultOption: null,
                            }));
                        }
                    },
                })}
                {next()}
            </>
        ),
        ({collection}) => {
            return (
                <div className="number-filter-options-42t">
                    {BlanksMessage({
                        value: `Any number values left blank will not be visible. To avoid missing data please transform your data to make all blank number values “0”.`,
                    })}

                    <div className="option-group label">
                        <div className="label">Filter Label</div>
                        <div className="dual-line">
                            <div className="left">
                                {TextInput({
                                    label: "Label",
                                    ...ffToTextInput(form.field(["label"])),
                                })}
                            </div>
                            <div className="right">
                                {DebounceTextInput({
                                    label: "Hint",
                                    state: scope(filter, ["hint"]),
                                })}
                            </div>
                        </div>

                        {filter.value.label && (
                            <>
                                {MpLine({
                                    left: ShowHideToggle({
                                        state: scope(filter, ["showLabelInfoIcon"]),
                                    }),
                                    right: "Filter Label Info Icon",
                                })}
                                {getPath(filter.value, ["showLabelInfoIcon"]) &&
                                    TextareaInput({
                                        state: scope(filter, ["labelInfoIconText"]),
                                    })}
                            </>
                        )}
                    </div>

                    <div className="option-group label">
                        <div className="label">Filter Settings</div>

                        {filter.value.columns?.length > 1 &&
                            DropdownSelect({
                                label: "Filter Matching Criteria",
                                list: ["Any", "All"],
                                info: "The filter criteria may either be found in at least one of the fields or must be found in all of the fields searched. ",
                                tooltipTopOffset: -5,
                                valueToLabel: (op) =>
                                    [
                                        {
                                            label: "Show results meeting ANY criteria",
                                            value: "Any",
                                        },
                                        {
                                            label: "Show results meeting ALL criteria",
                                            value: "All",
                                        },
                                    ].find((item) => item.value === op).label,
                                ...ffToDropdown(form.field(["multiSelectionOption"])),
                            })}

                        {(() => {
                            const {value, onChange} = scope(filter, ["design"]);
                            return DropdownSelect({
                                label: "Control Type",
                                list: numberFilterControlTypes,
                                valueToLabel: (item) => item.label,
                                ...stateToSelect(
                                    {
                                        value: value || "Input",
                                        onChange,
                                    },
                                    ["name"]
                                ),
                            });
                        })()}

                        {(!filter.value?.design || filter.value.design === "Input") && collection.value.$type !== "ApiCollection" && ComparisonOptions({filter})}

                        {/*{MpLine({*/}
                        {/*    left: OnOffToggle({state: scope(filter, ["required"])}),*/}
                        {/*    right: "Require value to run this dashboard report",*/}
                        {/*})}*/}
                    </div>

                    {(!filter.value?.design || filter.value.design === "Input") && DefaultFilterCriteria({filter, form})}
                </div>
            );
        }
    );
