import React from "react";
import {cx} from "emotion";

import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {Load2} from "@common/react/load2";
import {createDateFormatter} from "@common/ui-components/charts/common/formatters/date-formatter";
import {PopupMenu} from "../../../../common/popup-menu/popup-menu";
import {ConfirmDialog} from "../../../../common/confirm-dialog/confirm-dialog";
import {isEmpty} from "@common/utils/collections";
import {LoadingIndicator} from "@common/ui-components/loading-indicator/loading-indicator";
import {ManageAccessKeyDialog} from "../data-access-key/manage-key/manage-access-key-dialog";
import {ViewAccessApiKeyDialog} from "../data-access-key/view-access-api-key-dialog";
import {CannotCreateApiKeyDialog} from "../data-access-key/cannot-create-api-key-dialog";
import {settingKey} from "../settings-constants";
import {ShowUrlsDialog} from "./show-urls-dialog/show-urls-dialog";
import {SettingRollKeyDialog} from "../data-access-key/roll-key/setting-roll-key-dialog";
import {Button} from "@common/form/buttons/button/button";
import {BasicPanel} from "@common/ui-components/panel/panel";
import {keyed} from "@common/react/keyed";
import {UseState} from "@common/react/use-state";
import {StaticTooltipService} from "../../../../common/tooltip3/static-tooltip-service";
import {SearchInput} from "@common/form/search-input/search-input";
import {FolderCM} from "../../collections/folder-cm/folder-cm";
import {MoveToDialog} from "../../common/move-to-dialog/move-to-dialog";
import {RecursiveTable} from "../../../../common/recursive-table/recursive-table";
import {buildFoldersTree} from "../../collections/folders-tree-structure";
import {CreateFolderDialog} from "../../collections/folder-cm/create-folder/create-folder-dialog";
import {SelectTreeFoldersItem} from "../commons/select-tree-folders-item/select-tree-folders-item";
import {getFolderDepthOrderList} from "../../collections/folder-cm/get-folder-depth-order-list";
import {chain} from "@common/utils/fs";
import {isBlank} from "@common/utils/strings";
import {cascade} from "@common/utils/cascade";
import {ButtonDropdown} from "../../../../data-source/common/button-dropdown/button-dropdown";
import {NewFolderIcon, PlusIcon} from "@common/ui-components/icons/global-icons";
import SearchIcon from "assets/icons/common/SearchIcon";
import FolderIcon from "assets/icons/common/FolderIcon";
import FolderOpenIcon from "assets/icons/common/FolderOpenIcon";
import {DeleteKeyDialog} from "../commons/delete-key-dialog";
import {MultiSelect} from "../../../common/multi-select";
import {noun} from "@common/utils/plural";
import {recentStorage} from "../../recent-section/recent-storage";

export const loadIframeKeyActions = ({iframeKeyFolders, dashboardCollectionsOverview, next}) =>
    cs(
        consumeContext("apis"),
        consumeContext("routing"),
        consumeContext("auth"),
        ["folders", (_, next) => next(iframeKeyFolders)],
        [
            "manageKeyDialog",
            ({folders}, next) =>
                ManageAccessKeyDialog({
                    next,
                    $type: settingKey.IFRAME,
                    folders: folders.value,
                }),
        ],
        ["viewApiKeyDialog", (_, next) => ViewAccessApiKeyDialog({next})],
        ["showUrlsDialog", (_, next) => ShowUrlsDialog({next})],
        ["rollKeyDialog", (_, next) => SettingRollKeyDialog({$type: settingKey.IFRAME, next})],
        ["confirmDialog", ({}, next) => ConfirmDialog({next, title: "Delete Key?"})],
        [
            "viewKeyParameters",
            ({manageKeyDialog, accessKeys, collections, folders}, next) =>
                next(async (ak, editStep) => {
                    const resp = await manageKeyDialog.show({
                        $type: settingKey.IFRAME,
                        accessKey: ak,
                        collections: dashboardCollectionsOverview,
                        editStep,
                    });

                    if (resp) {
                        // const {accessKey} = resp;
                        folders.reload();
                    }
                }),
        ],
        [
            "onUpdateFolders",
            ({folders}, next) =>
                next((newFolders) => {
                    return folders.onChange(newFolders);
                }),
        ],
        [
            "moveIframeKeyDialog",
            ({folders, onUpdateFolders}, next) =>
                MoveToDialog({
                    apiType: "iframeKey",
                    folders: folders.value,
                    onDone: onUpdateFolders,
                    next,
                }),
        ],
        [
            "deleteIframeKeyDialog",
            ({onUpdateFolders}, next) =>
                DeleteKeyDialog({
                    entityName: "iframeKey",
                    entityDisplayName: "Iframe Key",
                    title: "Delete Iframe Key",
                    onDone: onUpdateFolders,
                    reloadFolderOnDone: true,
                    next,
                }),
        ],
        [
            "akCM",
            (
                {
                    routing,
                    folders,
                    moveIframeKeyDialog,
                    deleteIframeKeyDialog,
                    viewApiKeyDialog,
                    manageKeyDialog,
                    confirmDialog,
                    apis,
                    collections,
                    viewKeyParameters,
                    rollKeyDialog,
                    showUrlsDialog,
                },
                next
            ) =>
                PopupMenu({
                    itemHeight: 48,
                    getCommands: ({ak}) => {
                        return [
                            {
                                label: "Move to",
                                onClick: () => moveIframeKeyDialog.show({params: ak}),
                            },
                            {
                                label: "Key Parameters",
                                onClick: () => viewKeyParameters(ak, 0),
                            },
                            {
                                label: "Collection Access",
                                onClick: async () => viewKeyParameters(ak, 1),
                            },
                            {
                                label: "Show URLs",
                                onClick: () =>
                                    showUrlsDialog.show({
                                        accessKey: ak,
                                        collections: dashboardCollectionsOverview,
                                    }),
                            },
                            {
                                label: "Share URLs",
                                onClick: () => viewKeyParameters(ak, 2),
                            },
                            {
                                label: "Roll Key",
                                onClick: async () => {
                                    confirmDialog.show({
                                        confirmTitle: `Roll ${ak.name} Key`,
                                        submitBtn: "Roll Key",
                                        description: `The current key will be deleted immediately. Are you sure you want to proceed? You may reveal or share the key next.`,
                                        confirmClick: async () => {
                                            const newKey = await apis.iframeKey.rollIframeKey(ak.id);

                                            folders.change((oldFolders) =>
                                                oldFolders.map((f) => ({
                                                    ...f,
                                                    keys: cascade(f.keys, "[*]", (key) =>
                                                        key.id === ak.id
                                                            ? {
                                                                  ...ak,
                                                                  apiKey: newKey,
                                                              }
                                                            : key
                                                    ),
                                                }))
                                            );

                                            const resp = await rollKeyDialog.show({
                                                accessKey: {
                                                    ...ak,
                                                    apiKey: newKey,
                                                },
                                            });
                                            if (resp) {
                                                if (resp == "reveal")
                                                    viewApiKeyDialog.show({
                                                        accessKey: {
                                                            ...ak,
                                                            apiKey: newKey,
                                                        },
                                                    });
                                                else viewKeyParameters(ak, 2);
                                            }
                                        },
                                    });
                                },
                            },
                            {
                                label: "Delete",
                                className: "delete-action",
                                onClick: async () => deleteIframeKeyDialog.show({items: [ak]}),
                            },
                        ];
                    },
                    minWidth: 150,
                    next,
                }),
        ],
        ({manageKeyDialog, viewKeyParameters, showUrlsDialog, akCM, moveIframeKeyDialog, deleteIframeKeyDialog}) =>
            next({
                manageKeyDialog,
                viewKeyParameters,
                showUrlsDialog,
                akCM,
                moveIframeKeyDialog,
                deleteIframeKeyDialog,
            })
    );

export const IFrameKey = ({tab, collectionFolders, dashboardCollectionsOverview, iframeKeyFolders}) =>
    cs(
        keyed("iframe-key"),
        consumeContext("auth"),
        consumeContext("authEndpoint"),
        consumeContext("apis"),
        consumeContext("routing"),
        ["searchText", (_, next) => UseState({initValue: "", next})],
        ["selectedCollections", (_, next) => UseState({initValue: [], next})],
        [
            "accessKeys",
            ({apis, routing}, next) =>
                Load2({
                    _key: `${routing.params?.envId}-${tab.name}`,
                    fetch: () => apis.iframeKey.getIframeKeys(),
                    next,
                }),
        ],
        // [
        //     "collections",
        //     ({apis, routing}, next) =>
        //         cs(
        //             [
        //                 "remoteCollection",
        //                 (_, next) =>
        //                     Load2({
        //                         _key: `iframe${routing.params?.envId}`,
        //                         fetch: () => apis.collection.getCollectionsOverview(),
        //                         next,
        //                     }),
        //             ],
        //             ({remoteCollection}) =>
        //                 next({
        //                     ...remoteCollection,
        //                     value: (remoteCollection.value || [])?.filter((c) => c.type === "DashboardCollection"),
        //                 })
        //         ),
        // ],
        ["folders", (_, next) => next(iframeKeyFolders)],
        ["endpointAuthConfig", ({authEndpoint}, next) => next(authEndpoint.value?.find?.((t) => t.$type === "EndpointAuthConfig") || {})],
        [
            "defaultUserValues",
            ({endpointAuthConfig}, next) => {
                let ret = {};
                if (endpointAuthConfig) {
                    for (let key in endpointAuthConfig.resultMapping) {
                        const mappingType = endpointAuthConfig.resultMapping[key].mappingType;
                        ret[key] = {
                            $type: mappingType == "SingleValue" ? "UserStringValue" : "UserArrayValue",
                            ...(mappingType == "SingleValue"
                                ? {value: ""}
                                : {
                                      values: [
                                          {
                                              $type: "UserStringValue",
                                              value: "",
                                          },
                                      ],
                                  }),
                        };
                    }
                    return next(ret);
                }

                return next(null);
            },
        ],
        [
            "modifyUserValues",
            ({endpointAuthConfig}, next) =>
                next((userValues) => {
                    let ret = {};
                    for (let key in endpointAuthConfig?.resultMapping) {
                        const map = {
                            SingleValue: "UserStringValue",
                            ValueArray: "UserArrayValue",
                        };

                        const mappingType = map[endpointAuthConfig?.resultMapping[key].mappingType];
                        if (userValues[key] && userValues[key].$type == mappingType) ret[key] = userValues[key];
                        else {
                            ret[key] = {
                                $type: mappingType,
                                ...(mappingType == "UserStringValue"
                                    ? {value: ""}
                                    : {
                                          values: [
                                              {
                                                  $type: "UserStringValue",
                                                  value: "",
                                              },
                                          ],
                                      }),
                            };
                        }
                    }

                    return ret;
                }),
        ],
        ["warningDialog", (_, next) => CannotCreateApiKeyDialog({next})],
        [
            "viewKeyParameters",
            ({manageKeyDialog, accessKeys, collections, folders}, next) =>
                next(async (ak, editStep) => {
                    const resp = await manageKeyDialog.show({
                        $type: settingKey.IFRAME,
                        accessKey: ak,
                        collections: dashboardCollectionsOverview,
                        editStep,
                    });

                    if (resp) {
                        const {accessKey} = resp;
                        folders.reload();
                    }
                }),
        ],
        ["folderCM", ({folders}, next) => FolderCM({apiType: "iframeKey", folders, next})],
        [
            "createFolderDialog",
            ({folders}, next) =>
                CreateFolderDialog({
                    apiType: "iframeKey",
                    folders: folders.value,
                    onCreate: (newFolder) => folders.onChange([...(folders.value || []), newFolder]),
                    next,
                }),
        ],
        ["iframeKeyActions", (_, next) => loadIframeKeyActions({next, iframeKeyFolders, dashboardCollectionsOverview})],
        [
            "multiSelectCtrl",
            ({iframeKeyActions: {moveIframeKeyDialog, deleteIframeKeyDialog}}, next) =>
                MultiSelect({
                    next,
                    itemName: "Key",
                    resolveItemKey: (item) => `${item.type}_${item.id}`,
                    actions: [
                        {
                            label: "Move To",
                            isShow: () => true,
                            onClick: async (selectedItems) => {
                                const updateItems = Object.values(selectedItems);
                                return await moveIframeKeyDialog.show({
                                    isUpdateMany: true,
                                    label: `${updateItems.length} ${noun(updateItems.length, "Iframe Key")}`,
                                    params: updateItems,
                                });
                            },
                        },
                        {
                            label: "Delete",
                            isShow: () => true,
                            onClick: async (selectedItems) => {
                                const updateItems = Object.values(selectedItems);
                                return await deleteIframeKeyDialog.show({
                                    items: updateItems,
                                });
                            },
                        },
                    ],
                }),
        ],
        ({
            iframeKeyActions: {manageKeyDialog, viewKeyParameters, showUrlsDialog, akCM},
            defaultUserValues,
            endpointAuthConfig,
            warningDialog,
            searchText,
            folders,
            folderCM,
            selectedCollections,
            createFolderDialog,
            auth,
            multiSelectCtrl,
            routing,
        }) => {
            const hasSelectedItems = multiSelectCtrl.numSelectedItems > 0;
            const isFolder = (item) => item?.hasOwnProperty("children") || item?.hasOwnProperty("keys");

            const filteredItems = (list) => {
                if (selectedCollections.value.length > 0) {
                    return list.map((l) => ({
                        ...l,
                        keys: l.keys.filter((k) => k.collections.filter((c) => selectedCollections.value.findIndex((sc) => sc.id === c) !== -1).length > 0),
                    }));
                }
                return list;
            };

            const allowEditing = ["Full"].includes(auth.user.roles[0]);

            const addRecent = (item, path) => {
                recentStorage.add({
                    envId: routing.params?.envId,
                    type: "iframe-key",
                    item,
                    path,
                });
            };

            return (
                <BasicPanel
                    className="data-access-api-key-a23"
                    title={cs(
                        [
                            "staticTooltip",
                            (_, next) =>
                                StaticTooltipService({
                                    direction: "top",
                                    info: "iFrame Keys are appended to the collection URL to apply the row-level security from the model.",
                                    // topOffset: tooltipTopOffset ?? 20,
                                    next,
                                }),
                        ],
                        ({staticTooltip}) => (
                            <div className="key-title">
                                <div>iFrame Key</div>

                                {staticTooltip.renderIcon({
                                    icon: <i className="material-icons">info</i>,
                                    className: "info-tooltip",
                                })}
                            </div>
                        )
                    )}
                    rightControl={
                        <>
                            {SearchInput({
                                placeholder: "Search Key & Key Name",
                                state: searchText,
                                icon: SearchIcon({}),
                            })}

                            {SelectTreeFoldersItem({
                                childrenProp: "collections",
                                inputProps: {
                                    placeholder: "Filter by Collection",
                                },
                                list: collectionFolders
                                    ? getFolderDepthOrderList(
                                          chain(collectionFolders, (_) =>
                                              _.map((f) => ({
                                                  ...f,
                                                  collections: f.collections.filter((c) => c.type === "DashboardCollection"),
                                              }))
                                          )
                                      )
                                    : null,
                                displayValue: selectedCollections.value.length > 0 ? `${selectedCollections.value.length} selected` : null,
                                valueToInputLabel: (f) => f.name,
                                valueToLabel: (f) => f.name,
                                valueItemToLabel: (f) => f.name,
                                onChange: (item, selected) => selectedCollections.change((old) => (selected ? [item, ...old] : old.filter((o) => o.id !== item.id))),
                                isSelected: (item) => selectedCollections.value.findIndex((s) => s.id === item.id) > -1,
                                unSelectAll: () => selectedCollections.onChange([]),
                                sortBy: (x, y) => (x.name.toLowerCase() < y.name.toLowerCase() ? -1 : 1),
                            })}

                            {allowEditing &&
                                ButtonDropdown({
                                    label: "New",
                                    list: [
                                        {
                                            value: 0,
                                            label: (
                                                <>
                                                    <PlusIcon fill="#919EB0" /> Create New Key
                                                </>
                                            ),
                                        },
                                        {
                                            value: 1,
                                            label: (
                                                <>
                                                    <NewFolderIcon fill="#919EB0" /> Create New Folder
                                                </>
                                            ),
                                        },
                                    ],
                                    valueToLabel: (item) => item.label,
                                    onChange: (item) => {
                                        const maps = {
                                            0: async () => {
                                                if (endpointAuthConfig && !isEmpty(endpointAuthConfig?.resultMapping)) {
                                                    const resp = await manageKeyDialog.show({
                                                        $type: settingKey.IFRAME,
                                                        accessKey: {
                                                            userValues: defaultUserValues,
                                                            collections: [],
                                                            themeID: null,
                                                        },
                                                        collections: dashboardCollectionsOverview,
                                                    });
                                                    if (resp) {
                                                        const {accessKey, share} = resp;

                                                        folders.change((oldFolders) =>
                                                            oldFolders.map((f) =>
                                                                f.id === accessKey.folderID
                                                                    ? {
                                                                          ...f,
                                                                          keys: [...f.keys, accessKey],
                                                                      }
                                                                    : f
                                                            )
                                                        );

                                                        addRecent(
                                                            accessKey,
                                                            (folders.value ?? []).filter((f) => f.id === f.folderID)
                                                        );

                                                        if (share) {
                                                            await viewKeyParameters(accessKey, 2);
                                                        }
                                                    }
                                                } else {
                                                    const resp = await warningDialog.show();
                                                    if (resp) tab.onChange("authentication");
                                                }
                                            },
                                            1: createFolderDialog.show,
                                        };

                                        return maps[item.value]();
                                    },
                                })}

                            {hasSelectedItems && multiSelectCtrl.render({style: {marginBottom: 0, marginLeft: 16}})}
                        </>
                    }
                >
                    {({height}) => {
                        return (
                            <div className="data-access-api-keys-wrapper">
                                {!dashboardCollectionsOverview.value || !folders.value
                                    ? LoadingIndicator()
                                    : RecursiveTable({
                                          showNoResult: searchText.value?.length > 0,
                                          onClearSearch: () => searchText.onChange(""),
                                          maxHeight: height,
                                          getTrClass: (n) => multiSelectCtrl.isSelected(n) && "is-selected-tr",
                                          getChildTrClass: (n) => (isFolder(n) && multiSelectCtrl.isSelected(n) ? "is-selected-tr" : ""),
                                          structure: buildFoldersTree({
                                              itemsProp: "keys",
                                              folders: filteredItems(folders.value),
                                              keyword: searchText.value,
                                              hideEmptyFolder: selectedCollections.value.length > 0,
                                              filterFn: (col, keyword) => col.apiKey.indexOf(keyword) > -1,
                                          }),
                                          isGroupNode: (n) => n?.keys,
                                          isAlwaysExpanded: () => !isBlank(searchText.value),
                                          onClickRow: (item, event, path) => {
                                              if (isFolder(item)) {
                                                  return;
                                              }
                                              event.preventDefault();
                                              event.stopPropagation();
                                              multiSelectCtrl.toggleSelect(item);
                                          },
                                          columns: [
                                              {
                                                  label: "Name",
                                                  indented: true,
                                                  format: (key, {expanding, path}) => {
                                                      if (key.keys) {
                                                          return (
                                                              <div
                                                                  className={cx("icon-name-34n", "folder", {
                                                                      expanding,
                                                                  })}
                                                              >
                                                                  {expanding ? FolderOpenIcon({}) : FolderIcon({})}
                                                                  {key.name}
                                                              </div>
                                                          );
                                                      }

                                                      return (
                                                          <div
                                                              className={allowEditing && "link"}
                                                              onClick={(e) => {
                                                                  e.preventDefault();
                                                                  e.stopPropagation();
                                                                  addRecent(key, path);
                                                                  allowEditing && viewKeyParameters(key, 0);
                                                              }}
                                                          >
                                                              {key.name}
                                                          </div>
                                                      );
                                                  },
                                              },
                                              {
                                                  label: "URLs",
                                                  className: "btn-urls",
                                                  format: (key, {path}) =>
                                                      key.keys ? null : (
                                                          <div className="api-key-item">
                                                              <Button
                                                                  btnType="border"
                                                                  size="small"
                                                                  onClick={(e) => {
                                                                      e.preventDefault();
                                                                      e.stopPropagation();
                                                                      addRecent(key, path);
                                                                      showUrlsDialog.show({
                                                                          accessKey: key,
                                                                          collections: dashboardCollectionsOverview,
                                                                      });
                                                                  }}
                                                              >
                                                                  Show URLs
                                                              </Button>
                                                              {/*<span*/}
                                                              {/*    className="item show-urls"*/}
                                                              {/*    onClick={}*/}
                                                              {/*>*/}
                                                              {/*    Show URLs*/}
                                                              {/*</span>*/}
                                                          </div>
                                                      ),
                                              },
                                              {
                                                  label: "Collections",
                                                  format: (key, {path}) =>
                                                      key.keys ? null : (
                                                          <div
                                                              className={allowEditing && "link"}
                                                              onClick={(e) => {
                                                                  e.preventDefault();
                                                                  e.stopPropagation();
                                                                  addRecent(key, path);
                                                                  allowEditing && viewKeyParameters(key, 1);
                                                              }}
                                                          >
                                                              {key.collections?.length}
                                                          </div>
                                                      ),
                                              },
                                              {
                                                  label: "Date generated",
                                                  format: (key, {path}) => {
                                                      if (key.keys) return null;
                                                      return (
                                                          <div
                                                              className="api-key-item"
                                                              onClick={(e) => {
                                                                  e.preventDefault();
                                                                  e.stopPropagation();
                                                                  addRecent(key, path);
                                                                  viewKeyParameters(key);
                                                              }}
                                                          >
                                                              {createDateFormatter("MMM d, yyyy @ h:mm tt").format(new Date(key.created))}
                                                          </div>
                                                      );
                                                  },
                                              },
                                              {
                                                  format: (key) => {
                                                      if (!allowEditing) {
                                                          return;
                                                      }
                                                      return (
                                                          <div className="cm">
                                                              {key.keys
                                                                  ? folderCM.render({
                                                                        params: key,
                                                                    })
                                                                  : akCM.render({
                                                                        params: {
                                                                            ak: key,
                                                                        },
                                                                        img: <i className="far fa-ellipsis-h" />,
                                                                    })}
                                                          </div>
                                                      );
                                                  },
                                                  className: "models-cm-35v",
                                                  shy: true,
                                                  alignRight: true,
                                              },
                                          ],
                                      })}
                            </div>
                        );
                    }}
                </BasicPanel>
            );
        }
    );
