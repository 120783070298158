import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {noun} from "@common/utils/plural";
import {isBlank} from "@common/utils/strings";
import {dsSyncHelpers} from "../../../edit/common/helpers";
import {SyncSettingsSearchHeader} from "../../../edit/sync-settings/list/searches/sync-settings-search-header";
import {SyncSettingsSearches} from "../../../edit/sync-settings/list/searches/sync-settings-searches";
import {SyncSettingsList} from "../../../edit/sync-settings/list/sync-settings-list";
import {AddingGuidance} from "./guidance/adding-guidance";
import {SyncSettingsUnstructuredList} from "../../../edit/sync-settings/unstructured-list/sync-settings-unstructured-list";
import {SyncSettingsUnstructuredSearches} from "../../../edit/sync-settings/unstructured-list/searches/sync-settings-unstructured-searches";

export const DsAddingSyncSettings = ({dataSource, hasErrors, interactions, next}) =>
    cs(
        ["searchState", (_, next) => UseState({initValue: {value: ""}, next})],
        ["selectedTables", (_, next) => UseState({initValue: [], next})],
        ["showTutorial", (_, next) => UseState({initValue: true, next})],
        [
            "searchList",
            (_, next) =>
                next([
                    {
                        icon: <span className="material-icons-outlined">warning</span>,
                        label: "Tables with Sync Method not set",
                        filter: (t) => isBlank(t.syncMethod) || t.syncMethod === "None",
                    },
                    {
                        icon: <i className="fal fa-repeat-1"></i>,
                        label: "Tables set to Sync Once",
                        filter: (t) => t.syncSchedule?.$type === "OneTimeSync",
                    },
                ]),
        ],

        [
            "settingsSearch",
            ({searchState, searchList}, next) =>
                SyncSettingsSearchHeader({
                    list: searchList,
                    state: searchState,
                    adding: true,
                    next,
                }),
        ],
        [
            "errorMessage",
            ({searchList, settingsSearch}, next) => {
                const invalidTables = dsSyncHelpers.getInvalidTables(dataSource.value?.tables);

                return next(
                    <div className="error-message-88p">
                        <span className="material-icons-outlined">warning</span>
                        <div className="right-side">
                            <div className="label">
                                {invalidTables.length} {noun(invalidTables.length, "table")} in error
                            </div>
                            <div className="details">
                                <span className="error">
                                    Sync Method not set on {invalidTables.length} {noun(invalidTables.length, "table")}.
                                </span>
                                <span
                                    className="search"
                                    onClick={() => {
                                        const item = searchList[0];
                                        settingsSearch.updateSearch({
                                            filter: item.filter,
                                            value: item.label,
                                            id: 1,
                                        });
                                    }}
                                >
                                    Show Tables with Sync Method not set
                                </span>
                            </div>
                        </div>
                    </div>
                );
            },
        ],
        ({searchState, selectedTables, settingsSearch, errorMessage, showTutorial}) => {
            const renderSyncView = (props) => {
                const isUnstructuredDs = !dataSource.value.structured;
                const _syncSettings = isUnstructuredDs ? SyncSettingsUnstructuredList : SyncSettingsList;
                const _syncSearches = isUnstructuredDs ? SyncSettingsUnstructuredSearches : SyncSettingsSearches;

                return isBlank(searchState.value.value) ? (
                    <>
                        {showTutorial.value &&
                            AddingGuidance({
                                dataSource: dataSource.value,
                                showTutorial,
                            })}
                        {_syncSettings(props)}
                    </>
                ) : (
                    _syncSearches({
                        ...props,
                        clearSearch: settingsSearch.clearSearch,
                    })
                );
            };

            const isValid = dsSyncHelpers.validTables(dataSource.value.tables);
            return next({
                valid: isValid,
                renderHeader: () => settingsSearch.render(),
                render: ({}) =>
                    renderSyncView({
                        errorMessage,
                        hasErrors: hasErrors && !isValid,
                        adding: true,
                        searchState,
                        oriDs: dataSource.value,
                        dataSource,
                        interactions,
                        selectedTables,
                    }),
            });
        }
    );
