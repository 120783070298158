const dateRanges = [
    {
        label: "Yesterday",
        name: "Yesterday",
    },
    {
        label: "Today",
        name: "Today",
    },
    {
        label: "Tomorrow",
        name: "Tomorrow",
    },
    {
        label: "This Week (Mon-Sun)",
        name: "ThisWeekMondayFirst",
    },
    {
        label: "This Week (Sun-Sat)",
        name: "ThisWeekSundayFirst",
    },
    {
        label: "Last Week (Mon-Sun)",
        name: "LastWeekMondayFirst",
    },
    {
        label: "Last Week (Sun-Sat)",
        name: "LastWeek",
    },

    {
        label: "Last month",
        name: "LastMonth",
    },
    {
        label: "Month to date",
        name: "MonthToDate",
    },
    {
        label: "Month to yesterday",
        name: "MonthToYesterday",
    },
    {
        label: "Last Full Year",
        name: "LastFullYear",
    },
    {
        label: "Year to date",
        name: "YearToDate",
    },

    {
        label: "Last 7 days",
        name: "Last7Days",
    },
    {
        label: "Last 30 days",
        name: "Last30Days",
    },
    {
        label: "Last 90 days",
        name: "Last90Days",
    },
    {
        label: "Last 365 days",
        name: "Last365Days",
    },
    // {
    //     label: "Last week",
    //     name: "LastWeek",
    // },
    {
        label: "Previous 7 Days",
        name: "Previous7Days",
    },
    {
        label: "Previous 30 Days",
        name: "Previous30Days",
    },
    {
        label: "Previous 60 Days",
        name: "Previous60Days",
    },
    {
        label: "Previous 90 Days",
        name: "Previous90Days",
    },
    {
        label: "Previous 365 Days",
        name: "Previous365Days",
    },
    // {
    //     label: "Previous 7 Days",
    //     name: "Previous7Days",
    // },
    // {
    //     label: "Previous 30 Days",
    //     name: "Previous30Days",
    // },
    // {
    //     label: "Previous 60 Days",
    //     name: "Previous60Days",
    // },
    // {
    //     label: "Previous 365 Days",
    //     name: "Previous365Days",
    // },
    // {
    //     label: "Custom",
    //     name: "Custom",
    // },
    // {
    //     label: "All Time",
    //     name: "AllTime",
    // },
];

// const oldDateRanges = [
//     {
//         label: "Last 7 days",
//         name: "Last7Days",
//     },
//     {
//         label: "Last 30 days",
//         name: "Last30Days",
//     },
//     {
//         label: "Last 90 days",
//         name: "Last90Days",
//     },
//     {
//         label: "Last 365 days",
//         name: "Last365Days",
//     },
//     {
//         label: "Month to date",
//         name: "MonthToDate",
//     },
//     {
//         label: "Year to date",
//         name: "YearToDate",
//     },
//     {
//         label: "Last week",
//         name: "LastWeek",
//     },
//     {
//         label: "Last month",
//         name: "LastMonth",
//     },
//     {
//         label: "Custom",
//         name: "Custom",
//     },
//     {
//         label: "All Time",
//         name: "AllTime",
//     },
//     {
//         label: "Last Full Year",
//         name: "LastFullYear",
//     },
// ];

exports.dateRanges = dateRanges;
