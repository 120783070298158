import {Fragment, createElement as h} from "react";
import * as ReactDOM from "react-dom";
import {Static2} from "../../react/static-2";
import {cs} from "../../react/chain-services";
import {Invoke} from "../../react/invoke";
import {OnUnmounted} from "../../react/on-unmounted";

export const registryFactory =
    ({className, Provider}) =>
    ({next, shadowRoot}) =>
        cs(["domRef", ({}, next) => Static2({next})], ({domRef}) =>
            h(
                Fragment,
                {},
                Invoke({
                    onMounted: () => {
                        const container = shadowRoot ?? document.body;

                        let newEl = document.createElement("div");
                        newEl.className = className;
                        domRef.set(newEl);
                        container.appendChild(newEl);
                    },
                }),
                OnUnmounted({
                    action: () => {
                        let oldElem = domRef.get();
                        oldElem.remove();
                    },
                }),
                h(
                    Provider,
                    {
                        value: {
                            render: (element) => ReactDOM.createPortal(element, domRef.get()),
                        },
                    },
                    next()
                )
            )
        );
