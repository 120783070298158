import React, {Component} from "react";
import {GlobalMouseMove} from "../../react/global-mouse-move";
import {GlobalMouseUp} from "../../react/global-mouse-up";
import {cx} from "emotion";

export class VerticalScrollbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dragging: false,
        };
    }

    render() {
        const {dragging} = this.state;
        const {scrollHeight, containerHeight, scrollTop, scrollbarContainer, verticalThumb} = this.props;

        return (
            <>
                {dragging &&
                    GlobalMouseMove({
                        fn: (e) => {
                            const nextScrollTop = scrollHeight * ((e.clientY - this.grabClientY) / containerHeight);
                            scrollbarContainer.scrollTop = this.lastScrollTop + nextScrollTop;
                        },
                    })}

                {dragging &&
                    GlobalMouseUp({
                        fn: (e) => {
                            this.setState({dragging: false});
                        },
                    })}

                {scrollHeight && Math.abs(containerHeight - scrollHeight) > 3 ? (
                    <div className={cx("scrollbar-container-track", dragging && "dragging")}>
                        <div
                            className={cx("scrollbar-thumb", verticalThumb && "visible")}
                            onMouseDown={(e) => {
                                this.grabClientY = e.clientY;
                                this.lastScrollTop = scrollbarContainer.scrollTop;
                                this.setState({dragging: true});
                            }}
                            style={{
                                height: `${(containerHeight / scrollHeight) * 100}%`,
                                top: `${(scrollTop / scrollHeight) * 100}%`,
                            }}
                        />
                    </div>
                ) : null}
            </>
        );
    }
}
