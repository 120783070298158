import {ShowHideToggle} from "../../../../../../../../../../../../common/form/toggles/show-hide-toggle";
import {scope} from "@common/react/scope";
import {getPath} from "@common/utils/arr-path";
import {isChartTitleHidden} from "@common/ui-components/charts/chart-layout/chart-title-display-rule";
import {ValueChipInput} from "../../../../fields-tab/actions/add/common/value-chip-input";
import {TextInput} from "../../../../../../../../../../../../common/form/text-input/text-input";
import {ffToTextInput} from "../../../../../../../../../../../../common/form/ff-to-text-input";
import {HiddenFieldAlert} from "../hidden-field-alert/hidden-field-alert";
import {TextareaInput} from "../textarea-input/textarea-input";
import * as React from "react";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {omit} from "@common/utils/objects";
import {ffToDropdown} from "../../../../../../../../../../../../common/form/ff-to-dropdown";

export const SectionOptions = ({tile, size, parentTile, tileAction, form}) => ({
    label: "Options",
    render: () => {
        return (
            <div className="table-columns-35g">
                <div className="panel">
                    {DropdownSelect({
                        label: "Background Style",
                        list: [
                            {
                                label: "Match Tiles",
                                value: "MatchTile",
                            },
                            {
                                label: "No Background",
                                value: "NoBackground",
                            },
                        ],
                        valueToLabel: (v) => v.label,
                        ...ffToDropdown(form.field(["style", "backgroundStyle"]), ["value"]),
                    })}
                </div>
            </div>
        );
    },
});
