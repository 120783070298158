import {tooltipLayout} from "./tooltip-layout";
import {getTooltipFontSizes} from "./get-tooltip-font-sizes";

// used for both scatter plot and bubble chart
export const renderScatterPlotTooltip = (
    {
        points,
        tile, theme,
        rawData,
        formatters,
        isCompare,
    }
) => {
    const renderer = tile.dimensionField ? rTooltipContentWithDimension : rTooltipContentNoDimension;
    return tooltipLayout({
        content: renderer({tile, theme, formatters, points, rawData, isCompare}),
        theme, isCompare,
    })
};

// tooltip when there is no dimension, used for both non-compare and compare mode
// if there are groups, a series may contain multiple data points, so use this, too.
const rTooltipContentNoDimension = ({tile, theme, formatters, points, isCompare, rawData}) => {
    const {point, series} = points[0];

    if (series.userOptions.customType === "IndicatorReferenceLine") {
        return rIndicatorReferenceLineRows({tile, theme, formatters, points, rawData, isCompare});
    }

    const fontSizes = getTooltipFontSizes(theme);

    const dateRangeFormatter = (range) => formatters.dateFormatter(range?.dateStart) + " - " + formatters.dateFormatter(range?.dateEnd);
    const dateRange = series.userOptions.range && dateRangeFormatter(series.userOptions.range);

    const header = dateRange && (
        `<div class="non-compare-header" style="font-size: ${fontSizes.small}px">
            <div class="title">
                ${dateRange}
            </div>
        </div>`
    );

    return (
        `${header || ""}
        <div class="table non-compare">
            ${rScatterRows({points, tile, formatters, theme})}
        </div>`
    );
};

// the table rows for tooltip without dimension
const rScatterRows = ({points, tile, formatters, theme}) => {
    const {point, series} = points[0];

    const fontSizes = getTooltipFontSizes(theme);

    const xAxisValueFormatter = formatters.tooltipValueFormatters[tile.xAxisField.id];
    const yAxisValueFormatter = formatters.tooltipValueFormatters[tile.yAxisField.id];
    const zAxisValueFormatter = formatters.tooltipValueFormatters[tile.zAxisField?.id];

    return [
        series.userOptions.custom?.colorGroup && {name: "Color group name", value: series.userOptions.custom.colorGroup},
        series.userOptions.custom?.shapeGroup && {name: "Shape group name", value: series.userOptions.custom.shapeGroup},
        {name: tile.xAxisField.displayName, value: xAxisValueFormatter(point.x)},
        {name: tile.yAxisField.displayName, value: yAxisValueFormatter(point.y)},
        tile.zAxisField && point.z != null && {name: tile.zAxisField.displayName, value: zAxisValueFormatter(point.z)},
    ].filter(v => v).map((r) => {
        return (
            `<div class="point table-row" style="font-size: ${fontSizes.medium}px">
                <div class="name">
                    ${r.name}:
                    <strong>${r.value}</strong>
                </div>
            </div>`
        )
    }).join("");
}

// tooltip when there is dimension, used for both non-compare and compare mode
const rTooltipContentWithDimension = ({tile, theme, formatters, points, rawData, isCompare}) => {
    const {point, series} = points[0];

    if (series.userOptions.customType === "IndicatorReferenceLine") {
        return rIndicatorReferenceLineRows({tile, theme, formatters, points, rawData, isCompare});
    }

    const fontSizes = getTooltipFontSizes(theme);

    if (!isCompare) {
        const header = (
            `<div class="non-compare-header" style="font-size: ${fontSizes.small}px">
            <div class="title">
                ${series.name}
            </div>
        </div>`
        );

        return (
            `${header}
            <div class="table non-compare">
                ${rScatterRows({points, tile, formatters, theme})}
            </div>`
        );
    }

    return (
        `<div class="table">
            ${rScatterHeaderCompare({points, tile, formatters, theme, rawData})}
            ${rScatterRowsCompare({points, tile, formatters, theme, rawData})}
        </div>`
    );

}

// the header in compare mode for tooltip with dimension
const rScatterHeaderCompare = ({points, tile, formatters, theme, rawData, hideSeriesTitle}) => {
    const {point, series} = points[0];

    const fontSizes = getTooltipFontSizes(theme);

    const respRange = rawData.series.find((s) => s.isCompare !== series.userOptions.isCompare)?.range;
    const dateRangeFormatter = (range) => formatters.dateFormatter(range?.dateStart) + " - " + "</br>" + formatters.dateFormatter(range?.dateEnd);

    return (
        `<div class="header table-row" style="font-size: ${fontSizes.small}px">
            <div class="series-title">${!hideSeriesTitle ? series.name : ""}</div>
            <div class="main-title">
                <div className="text">
                    ${dateRangeFormatter(series.userOptions.isCompare ? respRange : series.userOptions.range)}
                </div>
            </div>
            <div class="compare-title">
                <div className="text">
                    ${dateRangeFormatter(series.userOptions.isCompare ? series.userOptions.range : respRange)}
                </div>
            </div>
        </div>`
    );
}

// the table rows in compare mode for tooltip with dimension
const rScatterRowsCompare = ({points, tile, formatters, theme}) => {
    const {point, series} = points[0];

    const respSeries = series.chart.series.find((s) => s.userOptions.measureAxisTitle === series.userOptions.measureAxisTitle && s.userOptions.isCompare !== series.userOptions.isCompare);
    const respPoint = respSeries && respSeries.data[0];

    const mainSeries = series.userOptions.isCompare ? respSeries : series;
    const compareSeries = series.userOptions.isCompare ? series : respSeries;

    const mainPoint = series.userOptions.isCompare ? respPoint : point;
    const comparePoint = series.userOptions.isCompare ? point : respPoint;

    const fontSizes = getTooltipFontSizes(theme);
    const xAxisValueFormatter = formatters.tooltipValueFormatters[tile.xAxisField.id];
    const yAxisValueFormatter = formatters.tooltipValueFormatters[tile.yAxisField.id];
    const zAxisValueFormatter = formatters.tooltipValueFormatters[tile.zAxisField?.id];

    // if (series.userOptions.measureAxisTitle === "M2,C2,S2") {
    //     console.log("series", series)
    //     console.log("respSeries", respSeries)
    //     console.log("mainPoint", mainPoint)
    //     console.log("comparePoint", comparePoint)
    // }

    return [
        series.userOptions.custom?.colorGroup && {
            name: tile.colorGroupField.displayName,
            value: mainSeries?.userOptions.custom.colorGroup || "N/A",
            compareValue: compareSeries?.userOptions.custom.colorGroup || "N/A",
        },
        series.userOptions.custom?.shapeGroup && {
            name: tile.shapeGroupField.displayName,
            value: mainSeries?.userOptions.custom.shapeGroup || "N/A",
            compareValue: compareSeries?.userOptions.custom.shapeGroup || "N/A",
        },
        {
            name: tile.xAxisField.displayName,
            value: mainPoint ? xAxisValueFormatter(mainPoint.x) : "N/A",
            compareValue: comparePoint ? xAxisValueFormatter(comparePoint.x) : "N/A",
        },
        {
            name: tile.yAxisField.displayName,
            value: mainPoint ? yAxisValueFormatter(mainPoint.y) : "N/A",
            compareValue: comparePoint ? yAxisValueFormatter(comparePoint.y) : "N/A",
        },
        tile.zAxisField && {
            name: tile.zAxisField.displayName,
            value: mainPoint ? zAxisValueFormatter(mainPoint.z) : "N/A",
            compareValue: comparePoint ? zAxisValueFormatter(comparePoint.z) : "N/A",
        },
    ].filter(v => v).map((r) => {
        return (
            `<div class="point table-row" style="font-size: ${fontSizes.medium}px">
                <div class="name">
                    ${r.name}
                </div>
                <div className="main-value">
                    ${r.value}
                </div>
                <div className="compare-value">
                    ${r.compareValue}
                </div>
            </div>`
        )
    }).join("");
}

const rIndicatorReferenceLineRows = ({points, tile, formatters, theme, rawData, isCompare}) => {
    const {point, series} = points[0];

    const fontSizes = getTooltipFontSizes(theme);
    const dateRangeFormatter = (range) => formatters.dateFormatter(range?.dateStart) + " - " + formatters.dateFormatter(range?.dateEnd);

    const header = (
        `<div class="non-compare-header" style="font-size: ${fontSizes.small}px">
            <div class="title">
                ${series.name}
                ${isCompare ? ` - ${dateRangeFormatter(rawData.series.find((s) => !s.isCompare)?.range)}` : ""}
            </div>
        </div>`
    )

    return (
        `${header || ""}
        <div class="table non-compare">
            ${rScatterRows({points, tile, formatters, theme})}
        </div>`
    );
};
