exports.scatterPlotTemplate = {
    $type: "ScatterPlotTile",
    style: {
        $type: "ScatterPlotTileStyle",
        xAxis: {
            // labelShown: true,
            gridStepLabelsShown: false,
            gridStepLabelOrientation: "Auto",
            lineShown: false,
            gridLinesShown: false,
            location: "RightBottom",
            range: "AutoFromData",
        },
        yAxis: {
            // labelShown: true,
            gridStepLabelsShown: false,
            gridStepLabelOrientation: "Auto",
            lineShown: false,
            gridLinesShown: false,
            range: "AutoFromData",
        },
        dataLabels: {
            // $type: "ScatterPlotDataLabelsTileStyle",
            show: false,
            position: "OutsideAbove",
            fontSize: "ExtraSmall",
            allowOverlap: false,
        },
        legendStyle: {
            legendShown: false,
            legendPosition: "Auto",
            legendTitleShown: false,
            legendTitle: "Legend",
        },
        title: {
            show: true,
            titleLocation: "Above",
        },
    },
};
