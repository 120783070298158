import React from "react";
import {VerbScrollbar} from "./verb-scrollbar";
import {cx} from "emotion";

export const VerbDialogBodyScrollbar = ({children, noPadding = false, className, maxHeight = 'calc(100vh - 100px - 56px - 56px)', ...otherProps}) => (
    <VerbScrollbar
        maxHeight={maxHeight}
        {...otherProps}
    >
        <div className={cx("dialog-body", className, noPadding && "no-padding")}>
            {children}
        </div>
    </VerbScrollbar>
);
