const getFieldType = (field) => {
    if (!field) {
        return;
    }

    const typeMap = {
        number: ["Double", "Int"],
        date: ["DateTime", "DateTimeOffset"],
        bool: ["Bool"],
    };

    for (const type in typeMap) {
        if (typeMap[type].includes(field.dataType)) {
            return type;
        }
    }

    // if (field["numericProperties"]) {
    //     return "number";
    // }
    //
    // if (field["dateProperties"]) {
    //     return "date";
    // }

    return "text";
};
exports.getFieldType = getFieldType;

// dataTypes: Unsupported, IDInt, IDUUID, Text, Int, Bool, Double, DateTime, DateTimeOffset, GeoPoint, PostalCode
