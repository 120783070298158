const {isSameField} = require("@common/ui-components/charts/factory/is-same-field");

const getLimitUpdated = ({tile, fieldVal: {newVal, oriVal}}) =>
    newVal === null
        ? {
              limit: isSameField(tile.value.limit, oriVal) ? null : tile.value.limit,
          }
        : {};

exports.getLimitUpdated = getLimitUpdated;

const getLimitUpdatedWithMultipleAgg = ({tile, oriVal, allFields}) => {
    const currentAggs = allFields.filter((f) => f.id != oriVal.id && isSameField(tile.value.limit, f));

    if (currentAggs.length == 0) return {limit: null};
    if (tile.value.limit.aggregationFunc == oriVal.aggregationFunc) {
        return {
            limit: {
                ...tile.value.limit,
                aggregationFunc: currentAggs[0].aggregationFunc,
            },
        };
    }

    return {
        limit: tile.value.limit,
    };
};

exports.getLimitUpdatedWithMultipleAgg = getLimitUpdatedWithMultipleAgg;
