import {TILES, tileTypes} from "../../../../../../../common/select-tile-type/tile-types";
import {unique} from "@common/utils/collections";

const allowTiles = unique(
    tileTypes
        .filter((t) =>
            [TILES.GROUP_KPI, TILES.HORIZONTAL_BAR, TILES.VERTICAL_BAR, TILES.LINE, TILES.PIE, TILES.AREA_LINE, TILES.COMBO].includes(t.key)
        )
        .reduce((prev, item) => {
            prev = [...prev, ...item.types];
            return prev;
        }, [])
);
export const hasDateGroupsConfiguration = (tileType) => allowTiles.includes(tileType);
