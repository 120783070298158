import * as React from "react";
import {cs} from "@common/react/chain-services";
import {scope} from "@common/react/scope";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {operations} from "../../../../../../../collection/common/filters/filter-edit-main/options/number-filter/operations";
import {stateToSelect} from "@common/ui-components/form/state-to-select";
import {ffToNumberInput} from "../../../../../../../../../../common/form/ff-to-number-input";
import {NumberInput} from "../../../../../../../../../../common/form/number-input/number-input";
import {ShowHideToggle} from "../../../../../../../../../../common/form/toggles/show-hide-toggle";
import {isNil} from "../boolean-filter/boolean-filter-options";

export const NumberFilterOptions = ({filter, form}) =>
    cs(({}) => {
        const {defaultValueOrMin, defaultMax} = filter.value;
        const hasDefault = !isNil(defaultValueOrMin) || !isNil(defaultMax);
        return (
            <div className="contextual-number-filter-options-l35">
                <div className="header">Filter Type</div>

                <div className="content">
                    {DropdownSelect({
                        label: "Comparison Method",
                        list: operations,
                        valueToLabel: (v) => v.label,
                        ...stateToSelect(scope(filter, ["defaultOption"]), ["name"]),
                    })}
                </div>

                <div className="header">
                    Default Filter Value
                    <div className="control">
                        {ShowHideToggle({
                            state: {
                                value: hasDefault,
                                onChange: () =>
                                    filter.onChange({
                                        ...filter.value,
                                        defaultValueOrMin: hasDefault ? null : 1,
                                    }),
                            },
                        })}
                    </div>
                </div>

                {hasDefault && (
                    <div className="content">
                        <div className="value-inputs-vlv">
                            {["EqualTo", "NotEqualTo", "GreaterThanOrEqualTo", "LessThanOrEqualTo", "GreaterThan", "LessThan"].includes(filter.value.defaultOption) ? (
                                <>
                                    {NumberInput({
                                        label: "Value",
                                        showZero: true,
                                        ...ffToNumberInput(form.field(["defaultValueOrMin"])),
                                    })}
                                </>
                            ) : (
                                filter.value.defaultOption === "Range" && (
                                    <>
                                        {NumberInput({
                                            label: "Minimum",
                                            showZero: true,
                                            ...ffToNumberInput(form.field(["defaultValueOrMin"])),
                                        })}
                                        {NumberInput({
                                            label: "Maximum",
                                            showZero: true,
                                            ...ffToNumberInput(form.field(["defaultMax"])),
                                        })}
                                    </>
                                )
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    });
