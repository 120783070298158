import * as React from "react";
import {FieldControl} from "../render-field-control/field-control";
import {UseState} from "@common/react/use-state";
import {SelectField} from "../select-field/select-field";
import {ConfigureField} from "../configure-field/configure-field";
import {cs} from "@common/react/chain-services";
import {scope} from "@common/react/scope";
import "./multi-field-well.scss";
import {removeIndex, replaceFind} from "@common/utils/collections";
import {consumeContext} from "@common/react/context";
import {FieldSection} from "../field-section/field-section";
import {FieldInfoProvider} from "../../../../tile-tab/chart-types/common/field-info-provider/field-info-provider";
import {getFieldType} from "@common/ui-components/charts/common/get-field-type";
import {Button} from "../../../../../../../../../../../../common/form/buttons/button/button";
import {FieldItem} from "../../../../../../../../edit/common/field-item/field-item";
import {Draggable, Droppable} from "react-beautiful-dnd";
import {dateAggregates} from "../configure-field/configuration/date/date-field";
import {getTextAggregationFuncs} from "../../../../../../../../../common/aggregation-funcs/text-aggregation-funcs";
import {getNumberAggregationFuncs} from "../../../../../../../../../common/aggregation-funcs/number-aggregation-funcs";
import {getBoolAggregationFuncs} from "../../../../../../../../../common/aggregation-funcs/bool-aggregation-funcs";

export const MultiFieldWell = ({
    fields,
    $type,
    label,
    hideDisplayName,
    hideSort,
    onRemove,
    notAllowNoneAggregation,
    addLabel,
    next,
    dataKey,
    maxFields = Infinity,
    filterAggregations = (v) => v,
    notAllowedFields = [],
    allowSameFieldAndAggregation,
    hideAggregatedMeasureColumn,
}) =>
    cs(
        consumeContext("getFieldInfo"),
        consumeContext("tileFields"),
        [
            "selecting",
            (_, next) =>
                UseState({
                    next,
                }),
        ],
        ["configuring", (_, next) => UseState({next})],
        ["onRemoveField", (_, next) => next((field) => fields.onChange(fields.value.filter((f) => f.id !== field.id)))],
        ({configuring, selecting, getFieldInfo, tileFields}) =>
            next({
                render: (placeholderState, allowDragToAnotherGroup, hoveringGroup, dragging) => {
                    const isAllowDragToAnother = () => {
                        if (!allowDragToAnotherGroup) return false;

                        const found = fields.value.find((v) => v.id == hoveringGroup.value);
                        if (found) {
                            const isMultiple = fields.value.filter((v) => v.modelTableID == found.modelTableID && v.modelColumnID == found.modelColumnID).length > 1;
                            if (isMultiple) {
                                return false;
                            }
                        }

                        if (found || hoveringGroup.value == dataKey) return true;
                        return fields.value.length < maxFields;
                    };

                    return FieldSection({
                        header: FieldInfoProvider({
                            field: <div className="text">{label}</div>,
                        }),
                        content: (
                            <Droppable droppableId={dataKey} type={isAllowDragToAnother() ? "Field" : label}>
                                {(provided, snapshot) => (
                                    <div
                                        className="multi-field-well-4nd"
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        onMouseEnter={() => {
                                            if (!dragging) {
                                                hoveringGroup.onChange(dataKey);
                                            }
                                        }}
                                    >
                                        {fields.value.map((field, index) => {
                                            return (
                                                <Draggable key={fields.value[index].id} draggableId={fields.value[index].id || new Date().getTime().toString()} index={index}>
                                                    {(provided, snapshot) => (
                                                        <FieldControl
                                                            dragging={dragging}
                                                            hoveringGroup={hoveringGroup}
                                                            provided={provided}
                                                            key={fields.value[index].id}
                                                            field={fields.value[index]}
                                                            getFieldInfo={getFieldInfo}
                                                            onConfigure={() => configuring.onChange(index)}
                                                            onChangeField={() =>
                                                                selecting.onChange({
                                                                    type: "change",
                                                                    oldField: fields.value[index],
                                                                })
                                                            }
                                                            onRemove={() => (onRemove ? onRemove(fields.value[index]) : fields.onChange(removeIndex(index, fields.value)))}
                                                        />
                                                    )}
                                                </Draggable>
                                            );
                                        })}

                                        {provided.placeholder}

                                        {placeholderState.value && snapshot.isDraggingOver && (
                                            <FieldItem
                                                className="blank"
                                                style={{
                                                    top: placeholderState.value.clientY,
                                                    left: placeholderState.value.clientX,
                                                    height: placeholderState.value.clientHeight,
                                                    width: placeholderState.value.clientWidth,
                                                    position: "absolute",
                                                }}
                                            >
                                                <div className="line-indicator">
                                                    <div className="left" />
                                                    <div className="right" />
                                                </div>
                                            </FieldItem>
                                        )}

                                        {fields.value.length < maxFields && (
                                            <Button
                                                size="tiny"
                                                btnType="secondary"
                                                className="add-field-btn"
                                                onClick={() =>
                                                    selecting.onChange({
                                                        type: "add",
                                                    })
                                                }
                                            >
                                                {addLabel ? addLabel : `Add ${label} Field`}
                                            </Button>
                                        )}
                                    </div>
                                )}
                            </Droppable>
                        ),
                    });
                },
                override: (() => {
                    const allowAggregations = (type, fieldType, table, column, currentSelected) => {
                        if (type == "date" && ["CategoryTileField", "DimensionlessMeasureTileField", "PivotCategoryTileField"].includes(fieldType)) {
                            const selectedAgg = fields.value
                                .filter((f) => f.modelTableID === table.id && f.modelColumnID === column.id)
                                .map((column) => {
                                    return column.dateProperties?.aggregation;
                                });

                            return {
                                list: dateAggregates.filter(filterAggregations).filter((a) => selectedAgg.indexOf(a.value) == -1),
                                currentSelected: dateAggregates.find((v) => v.value == currentSelected),
                            };
                        }

                        if (fieldType == "CategoryTileField") {
                            return null;
                        }

                        const selectedAgg = fields.value
                            .filter((f) => f.modelTableID === table.id && f.modelColumnID === column.id)
                            .map((column) => {
                                return column.aggregationFunc;
                            });

                        const notAllowNone = notAllowNoneAggregation || ["MeasureTileField", "DimensionlessMeasureTileField"].includes(fieldType);

                        let list = getTextAggregationFuncs({notAllowNone});

                        if (type == "number") {
                            list = getNumberAggregationFuncs({notAllowNone});
                        } else if (type === "bool") {
                            list = getBoolAggregationFuncs({notAllowNone});
                        }

                        return {
                            list: list.filter((a) => selectedAgg.indexOf(a.value) == -1),
                            currentSelected: list.find((l) => l.value == currentSelected),
                        };
                    };

                    if (selecting.value) {
                        const handleAddField = (field1) => {
                            fields.onChange([
                                ...(fields.value || []),
                                {
                                    ...field1,
                                    $type,
                                },
                            ]);

                            selecting.onChange(null);
                        };

                        const handleChangeField = (field1) => {
                            fields.onChange(
                                replaceFind(
                                    fields.value,
                                    {
                                        ...selecting.value.oldField,
                                        ...field1,
                                    },
                                    (_field) => _field === selecting.value.oldField
                                )
                            );
                            selecting.onChange(null);
                        };

                        return (
                            <SelectField
                                {...{
                                    label: `Select ${label} Field`,
                                    onCancel: () => selecting.onChange(null),
                                    actionType: selecting.value.type,
                                    onSelect: selecting.value.type === "add" ? handleAddField : handleChangeField,
                                    ...(selecting.value.type === "change" && {
                                        allowedFieldType: getFieldType(selecting.value.oldField),
                                    }),
                                    $type,
                                    notAllowedFields:
                                        [
                                            ...(notAllowedFields || []),
                                            ...(fields.value.filter((field) => field.$type.indexOf("CategoryTileField") > -1 && field.dataType != "DateTime") || []),
                                        ] ?? tileFields.allFields,
                                    filterAggregations,
                                    notAllowNoneAggregation,
                                    allowAggregations: !allowSameFieldAndAggregation ? null : allowAggregations,
                                    hideAggregatedMeasureColumn,
                                }}
                            />
                        );
                    } else if (configuring.value != null) {
                        return ConfigureField({
                            field: scope(fields, [configuring.value]),
                            getFieldInfo,
                            hideDisplayName,
                            hideSort,
                            notAllowNoneAggregation,
                            filterAggregations,
                            hideAggregatedMeasureColumn,
                            onRemove: () => {
                                if (onRemove) {
                                    onRemove(fields.value[configuring.value]);
                                } else {
                                    fields.onChange(removeIndex(configuring.value, fields.value));
                                }
                                configuring.onChange(null);
                            },
                            onCancel: () => configuring.onChange(null),
                            listFieldsAggregation: fields.value,
                            allowAggregations: allowSameFieldAndAggregation,
                            customListAgg: !allowSameFieldAndAggregation
                                ? null
                                : () => {
                                      const field = scope(fields, [configuring.value]).value;
                                      const fieldInfo = getFieldInfo(field);
                                      const allow = allowAggregations(
                                          fieldInfo.type,
                                          $type,
                                          {id: field.modelTableID},
                                          {id: field.modelColumnID},
                                          field.dateProperties?.aggregation || field.aggregationFunc
                                      );
                                      return [...allow.list, ...(allow.currentSelected ? [allow.currentSelected] : [])];
                                  },
                        });
                    }
                })(),
            })
    );
