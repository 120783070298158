import {cGetFontSize} from "../../../common/style-map/font-size";

export const getTooltipFontSizes = (theme) => {
    const getFontSize = cGetFontSize(theme?.dataVisualization.toolTipsAndLegends.fontSize || theme.general.canvas.fontSize, theme);
    const small = getFontSize({elemType: "hover"});
    return {
        small, // this one is used in header and total row
        medium: theme.sdkDashboard ? small : small + 1, // this one is used in the list
    };
};
