import "./select-collection-dialog.scss";

import React from "react";

import {ffToDropdown} from "../../../../../../../../../common/form/ff-to-dropdown";
import {ffToTextInput} from "../../../../../../../../../common/form/ff-to-text-input";
import {TextInput} from "../../../../../../../../../common/form/text-input/text-input";

import {SearchableSelect} from "@common/ui-components/searchable-select/searchable-select";

export const CreateNewCollection = ({selected, models, form}) => (
    <div className="create-new-collection">
        <div className="step-header">
            <img src={require("./icons/back-arrow.svg")} onClick={() => selected.onChange()} className="back-icon" alt="" />
            New Collection
        </div>

        <div className="step-body">
            {TextInput({
                label: "Collection Name",
                ...ffToTextInput(form.field(["name"])),
            })}

            {SearchableSelect({
                label: "Select the data model to build from",
                list: models,
                valueToLabel: (model) => model.name,
                ...ffToDropdown(form.field(["modelID"]), ["id"]),
            })}
        </div>
    </div>
);
