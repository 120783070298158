import "./searchable-select.scss";

import {cx} from "emotion";
import * as React from "react";

import {cs} from "../../react/chain-services";
import {UseState} from "../../react/use-state";
import {spc} from "../../react/state-path-change";

import {Dropdown} from "../dropdown/dropdown";
import {chain} from "../../utils/fs";
import {VerbScrollbar} from "../verb-scrollbar/verb-scrollbar";

import {StaticTooltipService} from "../../../web-client/src/routes/common/tooltip3/static-tooltip-service";
import {isMatchText} from "../../utils/strings";

export const SearchableSelect = ({
    inputRef,
    inputProps = {},
    label,
    list,
    valueToLabel = (v) => v,
    valueToInputLabel,
    onChange,
    isSelected,
    hasError,
    domRef,
    className,
    forcedExpandBottom,
    disabledOption = (v) => false,
    icon,
    info,
}) =>
    cs(
        ["chosenIndex", (_, next) => next(!isSelected ? -1 : list?.findIndex(isSelected))],
        ["active", (_, next) => UseState({next})],
        ({}, next) => (
            <div className={cx("searchable-select searchable-select-5gy", className, {"has-info": info}, {hasError})} ref={domRef}>
                {next()}
            </div>
        ),
        [
            "staticTooltip",
            (_, next) =>
                StaticTooltipService({
                    direction: "right",
                    info,
                    tooltipBoxWidth: 200,
                    topOffset: 20,
                    next,
                }),
        ],
        ({chosenIndex, active, staticTooltip}) =>
            Dropdown({
                registryRender: true,
                forcedExpandBottom,
                minExpandHeight: 350,
                renderToggle: ({showExpand, showingExpand}) => (
                    <div
                        className={cx("toggle", {
                            expanding: showingExpand,
                            "with-value": chosenIndex > -1,
                        })}
                    >
                        {icon && <span className="icon">{icon}</span>}

                        {info &&
                            staticTooltip.renderIcon({
                                icon: <i className="material-icons">info</i>,
                                className: "info-tooltip",
                            })}

                        <input
                            {...inputProps}
                            ref={inputRef?.set}
                            value={
                                active.value?.search != null
                                    ? active.value.search
                                    : (chosenIndex > -1 && (valueToInputLabel || valueToLabel)(list[chosenIndex])) || ""
                            }
                            onChange={(e) => {
                                active.onChange({
                                    search: e.target.value,
                                    focusIndex: 0,
                                });
                            }}
                            onKeyDown={(e) => {
                                const getList = () =>
                                    getSearchedList(list, {
                                        search: active.value.search,
                                        valueToLabel: valueToInputLabel || valueToLabel,
                                    });

                                if (e.key === "Escape") {
                                    active.onChange({});
                                    e.target.select();
                                } else if (e.key === "ArrowDown") {
                                    const list1 = getList();
                                    spc(active, ["focusIndex"], (v) => (v == null ? 0 : (v + 1) % list1.length));
                                } else if (e.key === "ArrowUp") {
                                    const list1 = getList();
                                    spc(active, ["focusIndex"], (v) => (v == null ? 0 : (v - 1 + list1.length) % list1.length));
                                } else if (e.key === "Enter") {
                                    if (active.value.focusIndex > -1) {
                                        const list1 = getList();
                                        list1.length && onChange(list1[active.value.focusIndex]);
                                        active.onChange(null);
                                        showExpand(false);
                                        e.target.blur();
                                    }
                                }
                            }}
                            onFocus={(e) => {
                                e.target.select();
                                showExpand(true);
                                active.onChange({});
                            }}
                            onBlur={() => {
                                active.onChange(null);
                                showExpand(false);
                            }}
                        />

                        {label && <div className="label">{label}</div>}
                        <i className="fa fa-chevron-down" />
                    </div>
                ),
                renderExpand: ({close, width}) => (
                    <VerbScrollbar className="searchable-select-list-f5f" maxHeight="350px" style={{width}}>
                        {chain(
                            list,
                            (_) =>
                                active.value?.search == null
                                    ? _
                                    : getSearchedList(_, {
                                          search: active.value.search,
                                          valueToLabel: valueToInputLabel || valueToLabel,
                                      }),
                            (_) =>
                                _?.map((l, i) => (
                                    <div
                                        key={i}
                                        className={cx("item", {
                                            selected: isSelected?.(l),
                                            focused: active.value?.focusIndex === i,
                                            disabled: disabledOption(l),
                                        })}
                                        onMouseDown={() => {
                                            if (!disabledOption(l)) {
                                                onChange(l);
                                                active.onChange(null);
                                                close();
                                            }
                                        }}
                                    >
                                        {valueToLabel(l)}
                                    </div>
                                ))
                        )}
                    </VerbScrollbar>
                ),
            })
    );

const getSearchedList = (list = [], {search, valueToLabel}) =>
    !search ? list : list.filter((item) => isMatchText(valueToLabel(item), search));
