import * as React from "react";
import {cs} from "@common/react/chain-services";
import "./field-tree.scss";
import {spc} from "@common/react/state-path-change";
import {removeField} from "../remove-field";
import {FieldControl} from "../../render-field-control/field-control";
import {replaceIndex} from "@common/utils/collections";
import {Button} from "../../../../../../../../../../../../../common/form/buttons/button/button";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {UseState} from "@common/react/use-state";
import {cx} from "emotion";

export const FieldTree = ({fieldGroups, getFieldInfo, addField, changeField, configureField, unavailable, showUnavailableMessage, onUpdateTile, notAllowAndGroup, notAllowDnd}) =>
    cs(
        [
            "dragging",
            (_, next) =>
                UseState({
                    next,
                }),
        ],
        ({dragging}) => {
            const handleDragStart = () => {
                dragging.onChange(true);
            };

            const onDragEnd = (result) => {
                dragging.onChange(false);
                const {destination, source, draggableId} = result;

                if (!destination) {
                    return;
                }

                if (destination.droppableId === source.droppableId && destination.index === source.index) {
                    return;
                }

                let updated;

                const start = fieldGroups.value[source.droppableId].measureFields;
                const finish = fieldGroups.value[destination.droppableId].measureFields;

                if (source.droppableId === destination.droppableId) {
                    const newFields = Array.from(start);

                    let newField = start.find((s) => s.id == draggableId);

                    newFields.splice(source.index, 1);
                    newFields.splice(destination.index, 0, newField);

                    updated = fieldGroups.value.map((group, index) => {
                        if (index == parseInt(source.droppableId)) {
                            return {
                                ...group,
                                measureFields: newFields,
                            };
                        }

                        return group;
                    });
                } else {
                    // Moving from one list to another
                    const startFields = Array.from(start);
                    startFields.splice(source.index, 1);

                    const newStart = [...startFields];
                    const newField = start.find((s) => s.id == draggableId);

                    const finishedFields = Array.from(finish);
                    finishedFields.splice(destination.index, 0, newField);
                    const newFinish = [...finishedFields];

                    updated = fieldGroups.value.map((group, index) => {
                        if (index == parseInt(source.droppableId)) {
                            return {
                                ...group,
                                measureFields: newStart,
                            };
                        }

                        if (index == parseInt(destination.droppableId)) {
                            return {
                                ...group,
                                measureFields: newFinish,
                            };
                        }

                        return group;
                    });
                }

                fieldGroups.onChange(updated.filter((group) => group.measureFields.length > 0));
            };

            const renderFields = (groupIndex, fields, hideOrSeparator) => (
                <>
                    <Droppable droppableId={`${groupIndex}`} type="droppable-area-fields">
                        {(provided) => (
                            <div className="draggable-group" {...provided.droppableProps} ref={provided.innerRef}>
                                {fields.map((field, index) => (
                                    <Draggable key={field.id} draggableId={field.id} index={index}>
                                        {(provided) => (
                                            <div className="and-group">
                                                <div className="field-line" key={index}>
                                                    <FieldControl
                                                        provided={!notAllowDnd && provided}
                                                        field={field}
                                                        dragging={dragging.value}
                                                        getFieldInfo={getFieldInfo}
                                                        onConfigure={() =>
                                                            configureField({
                                                                groupIndex,
                                                                fieldIndex: index,
                                                            })
                                                        }
                                                        onChangeField={async () => {
                                                            const changedField = await changeField(field);
                                                            spc(fieldGroups, [groupIndex, "measureFields"], (fields) => replaceIndex(index, fields, changedField));
                                                        }}
                                                        onRemove={() =>
                                                            onUpdateTile
                                                                ? onUpdateTile(null, {
                                                                      groupIndex,
                                                                      fieldIndex: index,
                                                                  })
                                                                : removeField({
                                                                      groupIndex,
                                                                      fieldIndex: index,
                                                                      fieldGroups,
                                                                  })
                                                        }
                                                    />

                                                    {!notAllowAndGroup && !dragging.value && (
                                                        <div className="and">
                                                            {index === fields.length - 1 ? (
                                                                <Button
                                                                    size="tiny"
                                                                    btnType="secondary"
                                                                    onClick={async () => {
                                                                        if (unavailable?.()) {
                                                                            showUnavailableMessage?.();
                                                                        } else {
                                                                            const field = await addField();
                                                                            spc(fieldGroups, [groupIndex, "measureFields"], (fields) => [...fields, field]);
                                                                        }
                                                                    }}
                                                                >
                                                                    AND
                                                                </Button>
                                                            ) : (
                                                                <div className="static">AND</div>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}

                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>

                    {!hideOrSeparator && <div className="or-separator">- OR -</div>}
                </>
            );

            return (
                <DragDropContext onDragStart={handleDragStart} onDragEnd={onDragEnd}>
                    <div className={cx("field-tree-2xe", {notAllowAndGroup})}>
                        {fieldGroups.value.map((field, index) => renderFields(index, field.measureFields, fieldGroups.value.length - 1 == index))}

                        <div className="or">
                            <Button
                                size="tiny"
                                btnType="border"
                                onClick={async () => {
                                    const field = await addField();
                                    spc(fieldGroups, [], (groups) => [
                                        ...groups,
                                        {
                                            measureFields: [field],
                                        },
                                    ]);
                                }}
                            >
                                OR
                            </Button>
                        </div>
                    </div>
                </DragDropContext>
            );
        }
    );
