const {last, findMaxValue} = require("../../../../utils/collections");
const {flatten1} = require("../../../../utils/collections");
const {sort} = require("../../../../utils/collections");
const {chain} = require("../../../../utils/fs");

const getRange = ({series, rawData, range, rangeCustomMax, rangeCustomMin, gridStepType, gridStepNumeric, barDisplay, isCompare, stackKey, constantOrAggregateValueLines}) => {
    // const getTickPositions = (step, min, max) => {
    //     let ret = [];
    //     let current = min;
    //     while (current < max - step) {
    //         ret.push(current);
    //         current += step;
    //     }
    //     ret.push(max);
    //     return ret;
    // };

    if (barDisplay === "HundredPercent") {
        return {};
    }

    const isClassicBar = !["Stacked", "HundredPercent"].includes(barDisplay);

    // when bar chart is in compare mode and has classic display, the compare series is not passed to HC, they are drawn manually.
    // so the max compare value should be checked to assure space for compare bars.
    // the constant and aggregate values (from reference lines) are also counted when define the min and max.

    const {min: mainMin, max: mainMax} = getExtremeValuesFromSeries(series.filter((s) => s.stack === stackKey));
    const {min: compareMin, max: compareMax} = isCompare && isClassicBar ? getExtremeValuesFromSeries(rawData.series.filter((s) => s.isCompare && s.stack === stackKey)) : {};

    const maxConstantOrAggregateValue = constantOrAggregateValueLines?.length ? findMaxValue(constantOrAggregateValueLines.map((l) => l.plotLineOptions.value)) : null;

    // padding for constant or aggregate value line label
    const needPadding = (max) => constantOrAggregateValueLines?.find((l) => l.plotLineOptions.value === max && l.plotLineOptions.label);

    if (!range || range === "AutoFrom0") {
        const max = isCompare && isClassicBar ? Math.max(compareMax, mainMax, maxConstantOrAggregateValue) : maxConstantOrAggregateValue != null ? Math.max(mainMax, maxConstantOrAggregateValue) : null;

        //fix line charts always in middle of chart if values is 0
        const allValuesIsZero = series?.[0]?.data?.reduce((prev, item) => (prev += item[1] ?? 0), 0) === 0;

        return {
            min: null,
            // ...(gridStepType !== "Count" && {
            //     max: null,
            // }),
            max: needPadding(max) ? max * 1.05 : max,
            tickPositions: null,
            // tickInterval: gridStepNumeric,
            tickPositioner: null,

            ...(allValuesIsZero
                ? {
                      minPadding: 0,
                      maxPadding: 0,
                      softMax: 1,
                  }
                : {}),
        };
    }

    if (range === "AutoFromData") {
        const max = compareMax != null ? Math.max(compareMin, mainMax, maxConstantOrAggregateValue) : Math.max(mainMax, maxConstantOrAggregateValue);
        return {
            min: compareMin != null ? Math.min(mainMin, compareMin) : mainMin,
            max: needPadding(max) ? max * 1.05 : max,
            // startOnTick: false,
            // endOnTick: false,
            // maxPadding: 0,
            // tickInterval: gridStepNumeric,
            // ...(gridStepNumeric && {
            //     tickPositions: getTickPositions(gridStepNumeric, minData, maxData)
            // }),
            tickPositions: null,
            tickPositioner: null,
        };
    }

    if (range === "Custom") {
        return {
            min: rangeCustomMin,
            max: rangeCustomMax,
            tickPositioner: function () {
                let tickPositions = this.tickPositions;
                const first = tickPositions[0];
                const last = tickPositions[tickPositions.length - 1];
                tickPositions[0] = rangeCustomMin || first;
                tickPositions[tickPositions.length - 1] = rangeCustomMax || last;
                return tickPositions;
            },
        };
    }
};
exports.getRange = getRange;

const getExtremeValuesFromSeries = (series) => {
    const getValues = (s) => s.data?.map((d) => d[1]) || [];

    return {
        min: chain(sort(flatten1(series.map((s) => getValues(s))))[0], (_) => Math.floor(_)),
        max: chain(last(sort(flatten1(series.map((s) => getValues(s))))), (_) => Math.ceil(_)),
    };
};
