import "./setup-mfa-modal.scss";

import React from "react";

import {consumeContext} from "@common/react/context";
import {cs} from "@common/react/chain-services";
import {DialogService} from "../../../common/dialog/dialog-service";
import {UseState} from "@common/react/use-state";
import {spc} from "@common/react/state-path-change";
import {ScanQrcodeStep} from "./steps/scan-qrcode-step";
import {VerifyAppStep} from "./steps/verify-app-step";
import {scope} from "@common/react/scope";
import {Button} from "@common/form/buttons/button/button";

export const SetupMFAModal = ({next: rootNext, password = null}) =>
    cs(
        consumeContext("apis"),
        consumeContext("toast"),
        consumeContext("auth"),
        [
            "modal",
            (_, next) =>
                DialogService({
                    strong: true,
                    registryRender: true,
                    forceShowCloseButton: true,
                    render: ({resolve}) => ({
                        width: 500,
                        title: "Set up Multi-Factor Authentication",
                        content: next({
                            resolve,
                        }),
                    }),
                    next: rootNext,
                }),
        ],
        [
            "state",
            (_, next) =>
                UseState({
                    next,
                    initValue: {
                        // stepIndex: 4,
                        stepIndex: 0,
                        password,
                    },
                }),
        ],
        ({state, auth, toast, modal}) => {
            const {stepIndex} = state.value;

            const onNext = () => spc(state, ["stepIndex"], (v) => v + 1);
            const onBack = () => spc(state, ["stepIndex"], (v) => Math.max(0, v - 1));

            return (
                <div className="setup-mfa-modal-b90">
                    {stepIndex == 0 &&
                        ScanQrcodeStep({
                            password: scope(state, ["password"]),
                            onNext,
                            onBack: () => null,
                        })}

                    {stepIndex == 1 &&
                        VerifyAppStep({
                            password: scope(state, ["password"]),
                            onNext,
                            onBack,
                        })}

                    {stepIndex == 2 && (
                        <>
                            <div className="content">You have set up Multi Factor Authentication successfully!</div>

                            <div className="buttons">
                                <div className="space" />
                                <Button onClick={() => modal.resolve()}>Done</Button>
                            </div>
                        </>
                    )}
                </div>
            );
        }
    );
