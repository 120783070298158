const {Component, createElement: h} = require("react");

const CaptureException = ({next, renderException, renderNull, onException}) => h(CaptureException1, {next, onException, renderNull, renderException});
exports.CaptureException = CaptureException;
exports.CaptureException1 = CaptureException;

class CaptureException1 extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            error: null,
            info: null,
        };
    }

    static getDerivedStateFromError(error) {
        return {error};
    }

    componentDidCatch(error, info) {
        this.props.onException?.({error, info});
    }

    render() {
        const {next, renderException, renderNull} = this.props;
        const {error} = this.state;

        return error == null ? next() ?? renderNull?.() ?? "<<null>>" : renderException(error);
    }
}
