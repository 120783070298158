import {UseState} from "@common/react/use-state";
import {cs} from "@common/react/chain-services";
import * as React from "react";
import {consumeContext} from "@common/react/context";
import {Form2} from "@common/react/cs-form/form2";
import {required} from "@common/react/cs-form/validators/required";
import {GuestLayout} from "../common/layout/guest-layout";
import {email} from "@common/react/cs-form/validators/email";

export const ForgotPasswordRoute = ({}) =>
    cs(
        consumeContext("apis"),
        consumeContext("routing"),
        ["state", (_, next) => UseState({next})],
        [
            "params",
            ({routing}, next) =>
                UseState({
                    getInitValue: () => ({
                        email: routing.params.email,
                    }),
                    next,
                }),
        ],

        [
            "form",
            ({params, state, routing, apis}, next) =>
                Form2({
                    fields: {
                        email: [required, email],
                    },
                    data: params,
                    onSubmit: async () => {
                        state.onChange({loading: true});

                        try {
                            await apis.sendPasswordResetEmail(params.value);
                            routing.goto("reset-password", {
                                email: params.value.email,
                            });
                        } catch (error) {
                            state.onChange({
                                loading: false,
                                error: error.message || "The input email is not associated with any account",
                            });
                        }
                    },
                    next,
                }),
        ],

        ({state, form, routing}) =>
            GuestLayout({
                header: "Forgot Password",
                error: state.value?.error,
                form: [
                    {
                        label: "Email Address",
                        field: form.field("email"),
                    },
                ],
                onSubmit: form.submit,
                link: {
                    label: "Back to login",
                    to: routing.toPath("login"),
                },
                submitBtn: {
                    label: "Send reset code",
                    disabled: state.value?.loading,
                },
            })
    );
