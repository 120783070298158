import {cs} from "@common/react/chain-services";
import {keyed} from "@common/react/keyed";
import "./ds-list-types.scss";
import {listTypes} from "./list-types";
import {CloudConnectionsDialog} from "../../wizard/steps/connection-configuration/list/dialog/cloud-connections/cloud-connections-dialog";

export const DsListTypes = ({data, dsApi}) =>
    cs(["cloudConnectionsDialog", ({}, next) => CloudConnectionsDialog({next, data, dsApi})], ({cloudConnectionsDialog}) => {
        const dsListTypes = listTypes({});

        return (
            <div className="ds-list-types-88u">
                {Object.keys(dsListTypes).map((type, i) =>
                    cs(keyed(i), () => {
                        const dsType = dsListTypes[type];
                        return (
                            <>
                                <div className="label">{dsType.label}</div>
                                <div className="items">
                                    {dsType.list.map((item, j) =>
                                        cs(keyed(j), () => (
                                            <div
                                                className="item-type"
                                                onClick={() => {
                                                    if (type === "cloud") {
                                                        cloudConnectionsDialog.show({
                                                            type: item.type,
                                                            groupType: type,
                                                        });
                                                    } else {
                                                        data.change((d) => ({
                                                            ...d,
                                                            type: item.type,
                                                            groupType: type,
                                                            $type: item.connectionDetailsType ?? `${item.type}ConnectionDetails`,
                                                        }));
                                                    }
                                                }}
                                            >
                                                <img src={require(`${item.src}`)} style={item.style} />
                                            </div>
                                        ))
                                    )}
                                </div>
                            </>
                        );
                    })
                )}
            </div>
        );
    });
