import {generateIndicatorSeries} from "./indicator-series";
import {generateConstantOrAggregateValueSeries} from "./constant-or-aggregate-value-series";
import {isDimDate, isHundredPercent, isStacked} from "../common/bar-line-chart-helpers";
import {flatten1} from "../../../utils/collections";

// generate reference series based on the current series and tile's reference lines config.
// 3 types of reference lines: constant/aggregate value, regressions, moving averages.
// generation mechanism:
// constant or aggregate value: plot lines with mimic series to control via legend
// regressions: manually generate series using regression library
// moving averages: use built-in function from Highcharts

// NOTICE: any generated series must have customType

export const generateReferenceSeries = ({tile, theme, formatters, series}) => {
    if (!tile.referenceLines || isHundredPercent(tile)) {
        return;
    }

    const stacked = isStacked(tile);

    const getReferenceSeries = (rl) => {
        const involvedSeries = series.filter((s) => !s.isCompare && s.customStack === rl.measureGroupID);

        if (!involvedSeries?.length) {
            return;
        }

        // respective logic for availability: reference-lines.jsx, edit-reference-line.jsx
        if ((rl.$type === "ConstantValueReferenceLine" && rl.value != null) || (rl.$type === "AggregateValueReferenceLine" && !stacked && (rl.aggregation !== "Percentile" || (rl.aggregation === "Percentile" && rl.value != null)))) {
            return generateConstantOrAggregateValueSeries({
                tile,
                theme,
                series: involvedSeries,
                config: rl,
            });
        }

        if (rl.$type === "IndicatorReferenceLine" && !stacked && isDimDate(tile)) {
            return generateIndicatorSeries({
                tile,
                theme,
                formatters,
                series: involvedSeries,
                config: rl,
            });
        }
    };

    return flatten1(tile.referenceLines.map(getReferenceSeries)).filter((v) => v);
};
