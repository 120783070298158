import "./select-folder-service.scss";

import * as React from "react";

import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {getFolderDepthOrderList} from "../get-folder-depth-order-list";
import {CreateFolderService} from "../create-folder-service/create-folder-service";
import {SearchableSelect} from "@common/ui-components/searchable-select/searchable-select";

export const SelectFolderService = ({apiType, state, folders, ignoreFolder, label, next, customUpsertApi, customCheckFolderApi}) =>
    cs(
        [
            "createFolderService",
            (_, next) =>
                CreateFolderService({
                    apiType,
                    folders,
                    next,
                    customUpsertApi,
                    customCheckFolderApi,
                }),
        ],
        ["newFolder", (_, next) => UseState({next})],
        ({newFolder, createFolderService}) =>
            next({
                render: () => (
                    <div className="select-folder-service-6sf select-folder-service">
                        {SearchableSelect({
                            label: label || "Select a folder",
                            list: [
                                {
                                    name: "Create New Folder",
                                    id: "create-new-folder",
                                },
                                ...getFolderDepthOrderList(folders, ignoreFolder?.id),
                            ],
                            valueToInputLabel: (f) => f.name,
                            valueToLabel: (f) => (
                                <span
                                    style={{
                                        paddingLeft: (f.level ?? 0) * 15,
                                        ...(f.id === "create-new-folder" && {
                                            fontWeight: "bold",
                                        }),
                                    }}
                                >
                                    {f.name}
                                </span>
                            ),
                            isSelected: (f) => (newFolder.value && f.id === "create-new-folder") || (!newFolder.value && f.id === state.value),
                            onChange: (f) => {
                                if (f.id === "create-new-folder") {
                                    return newFolder.onChange(true);
                                }
                                newFolder.onChange(false);
                                state.onChange(f.id);
                            },
                        })}

                        {newFolder.value && createFolderService.render()}
                    </div>
                ),
                selectNewFolder:
                    newFolder.value &&
                    (() =>
                        new Promise(async (resolve, reject) => {
                            const {folder, error} = await createFolderService.create();
                            if (folder) {
                                resolve(folder);
                            } else {
                                reject(error);
                            }
                        })),
                valid: !newFolder.value || createFolderService.validName,
            })
    );
