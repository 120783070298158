import {LpShowHideLine} from "../../../../../common/left-panel/lp-show-hide-line";
import {ffToShowHideLine} from "../../../../../../../../common/form/ff-to-show-hide-line";
import {NumberInput} from "../../../../../../../../common/form/number-input/number-input";
import {LpColorLine} from "../../../../../common/left-panel/lp-color-line";
import {ffToDropdown} from "../../../../../../../../common/form/ff-to-dropdown";
import {ffToColorPickerLine} from "../../../../../../../../common/form/ff-to-color-picker-line";
import {ffToNumberInput} from "../../../../../../../../common/form/ff-to-number-input";

export const PieAndGaugesTheme = ({form, key}) => {
    return (
        <div className="content">
            {LpShowHideLine({
                label: "BORDER",
                ...ffToShowHideLine(form.field(["dataVisualization", key, "showBorder"])),
            })}

            {NumberInput({
                label: "Border Width",
                unit: "px",
                ...ffToNumberInput(form.field(["dataVisualization", key, "borderWidth"])),
                showZero: true,
            })}

            {LpColorLine({
                label: "Border Color",
                ...ffToColorPickerLine(form.field(["dataVisualization", key, "borderColorRGB"])),
            })}
        </div>
    );
};
