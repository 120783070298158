import {cs} from "@common/react/chain-services";
import * as React from "react";
import {DialogService} from "../../../common/dialog/dialog-service";
import "./leave-unsaved-changes-dialog.scss";
import {Button} from "../../../../../../common/form/buttons/button/button";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";

export const LeaveUnsavedChangesDialog = ({next: rootNext}) =>
    cs(
        [
            "modal",
            ({}, next) =>
                DialogService({
                    render: ({resolve}) => ({
                        title: "Save changes?",
                        content: next({
                            resolve,
                        }),
                    }),
                    next: rootNext,
                }),
        ],
        ({modal}) => (
            <div className="leave-unsaved-changes-dialog-94e">
                <VerbDialogBodyScrollbar>
                    <div className="messages">
                        <div className="message">You have unsaved changes.</div>

                        <div className="message">Do you want to continue editing or discard it?</div>
                    </div>
                </VerbDialogBodyScrollbar>

                <div className="buttons">
                    <Button onClick={() => modal.resolve("continue")}>Continue editing</Button>
                    <Button btnType="secondary" onClick={() => modal.resolve("discard")}>
                        Discard
                    </Button>
                </div>
            </div>
        )
    );
