import React from "react";
import {css, cx} from "emotion";

import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {Static2} from "@common/react/static-2";
import {isNotBlank} from "@common/utils/strings";

import {Dropdown} from "../../../../dropdown/dropdown";
import SearchIcon from "assets/icons/common/SearchIcon";
import {SearchInputBar} from "@common/ui-components/search-input-bar/search-input-bar";
import {initTheme} from "../../../../../../web-client/src/routes/theme/edit/common/theme-init";
import {StyledClass} from "@common/react/styled-class";

export const SearchHeader = ({theme = initTheme({}), search, columnName, columnIndex}) =>
    cs(
        ["keyword", (_, next) => UseState({next})],
        ["searchRef", (_, next) => Static2({next})],
        ["inputRef", (_, next) => Static2({next})],
        ["focusing", (_, next) => UseState({next})],
        ["hovering", (_, next) => UseState({next})],
        [
            "inputCss",
            (_, next) => {
                const componentTheme = theme.general.components;

                return StyledClass({
                    next,
                    content: {
                        background: `${componentTheme.inputBackgroundColorRGB}`,
                        color: `${componentTheme.inputTextColorRGB}`,
                        border: `${componentTheme.inputBorderWidth}px solid ${componentTheme.inputBorderColorRGB}`,
                        "border-radius": `${componentTheme.inputCornerRadius}px`,
                        "font-family": `${theme.general.canvas.fontFamily}, sans-serif !important`,
                        "::-webkit-input-placeholder": {
                            color: `${componentTheme.inputHintTextColorRGB}`,
                        },
                        ":-ms-input-placeholder": {
                            color: `${componentTheme.inputHintTextColorRGB}`,
                        },
                        "::placeholder": {
                            color: `${componentTheme.inputHintTextColorRGB}`,
                        },
                    },
                });
            },
        ],
        ({hovering, focusing, inputRef, keyword, inputCss}) => {
            const buttonsStyle = theme.general.buttons;
            const tablesStyle = theme.dataVisualization.tables;
            const componentTheme = theme.general.components;

            const secondaryButtonTheme = theme.general.buttons.secondaryButton;
            const primaryButtonTheme = theme.general.buttons.primaryButton;

            const getCss = (selectedTheme) => ({
                background: `${selectedTheme.backgroundColorRGB}`,
                color: `${selectedTheme.fontColorRGB}`,
                border: `${selectedTheme.borderWidth}px solid ${selectedTheme.borderColorRGB}`,
                "border-radius": `${selectedTheme.cornerRadius}px`,
                "font-family": `"${theme.general.canvas.fontFamily}", sans-serif`,

                "&:hover": {
                    background: `${selectedTheme.hoverBackgroundColorRGB}`,
                    color: `${selectedTheme.hoverFontColorRGB}`,
                    "border-color": `${selectedTheme.hoverBorderColorRGB}`,
                },
            });

            return Dropdown({
                registryRender: true,
                detectOnWheelEvent: true,
                useTogglePosition: true,
                forcedExpandLeft: true,
                className: "search-header",
                expandDistance: -15,
                customZIndex: 100,
                renderToggle: ({showExpand, showingExpand}) => {
                    return (
                        <div
                            className="search-header"
                            onClick={() => {
                                showExpand?.();
                                if (!showingExpand) {
                                    keyword.onChange(search.tableSearches.find((c) => c.columnIndex == columnIndex)?.searchText || "");
                                }
                            }}
                            onMouseEnter={() => hovering.onChange(true)}
                            onMouseLeave={() => hovering.onChange(false)}
                        >
                            {SearchIcon({
                                fill:
                                    showingExpand ||
                                    hovering.value ||
                                    isNotBlank(search.tableSearches.find((c) => c.columnIndex == columnIndex)?.search || "")
                                        ? buttonsStyle.primaryButton.backgroundColorRGB
                                        : tablesStyle?.headerFontColorRGB || "#bcbcbc",
                            })}
                        </div>
                    );
                },
                renderExpand: ({close}) => {
                    return (
                        <div
                            className="search-input-container-ff5"
                            style={{
                                borderRadius: theme.general.components.menuCornerRadius,
                                background: theme.general.components.menuBackgroundColorRGB,
                                zIndex: 10,
                            }}
                        >
                            <SearchInputBar
                                iconColor={componentTheme.inputIconColorRGB}
                                inputClassName={inputCss}
                                className="search-input"
                                onKeyDown={(e) => {
                                    if (e.key == "Enter") {
                                        close?.();
                                        search.searchColumn({
                                            cIndex: columnIndex,
                                            search: keyword.value,
                                            $type: "TextTableSearch",
                                        });
                                    }
                                }}
                                state={keyword}
                                placeholder={`Search ${columnName}`}
                                autoFocus
                                delay={0}
                            />

                            <div className="btn-wrapper">
                                {(() =>
                                    cs(
                                        [
                                            "css",
                                            (_, next) =>
                                                StyledClass({
                                                    next,
                                                    content: getCss(secondaryButtonTheme),
                                                }),
                                        ],
                                        ({css}) => (
                                            <button
                                                className={cx("btn btn-reset", css)}
                                                onClick={() => {
                                                    close?.();
                                                    keyword.onChange("");
                                                    search.searchColumn({
                                                        cIndex: columnIndex,
                                                        search: null,
                                                    });
                                                }}
                                            >
                                                Reset
                                            </button>
                                        )
                                    ))()}

                                {(() =>
                                    cs(
                                        [
                                            "css",
                                            (_, next) =>
                                                StyledClass({
                                                    next,
                                                    content: getCss(primaryButtonTheme),
                                                }),
                                        ],
                                        ({css}) => (
                                            <button
                                                className={cx("btn btn-search", css)}
                                                onClick={() => {
                                                    close?.();
                                                    search.searchColumn({
                                                        cIndex: columnIndex,
                                                        search: keyword.value,
                                                        $type: "TextTableSearch",
                                                    });
                                                }}
                                            >
                                                Search
                                            </button>
                                        )
                                    ))()}
                            </div>
                        </div>
                    );
                },
            });
        }
    );
