import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {cs} from "@common/react/chain-services";

export const AreaFillTypeSelect = ({value, onChange, ...props}) =>
    cs(({}) => {
        return DropdownSelect({
            label: props.label || "Area Fill",
            list: [
                {
                    value: "Semitransparent",
                    label: "Semitransparent",
                },
                {
                    value: "Gradient",
                    label: "Gradient",
                },
                {
                    value: "Solid",
                    label: "Solid",
                },
            ],
            valueToLabel: (v) => v.label,
            isSelected: (v) => v.value === value,
            onChange: (v) => onChange(v.value),
        });
    });
