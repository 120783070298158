import React from "react";
import {cs} from "@common/react/chain-services";
import {DialogService} from "../../../../common/dialog/dialog-service";
import "./error-google-api-connection-dialog.scss";
import {consumeContext} from "@common/react/context";
import {GDrivePicker} from "../../../../../common/gdrive-picker/gdrive-picker";
import {Button} from "../../../../../../../common/form/buttons/button/button";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";

export const ErrorGoogleApiConnectionDialog = ({next: rootNext}) =>
    cs(
        consumeContext("apis"),
        [
            "modal",
            ({}, next) =>
                DialogService({
                    render: ({resolve, reject, args: {content}}) => ({
                        title: "Updating Data Source",
                        content: next({reject, resolve, content}),
                    }),
                    next: rootNext,
                }),
        ],
        [
            "ggDrivePicker",
            ({apis, modal}, next) =>
                cs(() =>
                    GDrivePicker({
                        next,
                        onChange: null,
                        submit: apis.authEndpoint.authorizeTenantGGDrive,
                        onUnauthorized: () => modal.resolve(),
                    })
                ),
        ],
        ({ggDrivePicker, submitting, modal, apis}) => (
            <div className="error-google-api-connection-dialog-9ew">
                <VerbDialogBodyScrollbar>
                    <div className="message">
                        <i className="fa fa-exclamation" />
                        <div className="text">{modal.content}</div>
                    </div>
                </VerbDialogBodyScrollbar>

                <div className="buttons">
                    <Button className="btn-cancel" btnType="secondary" onClick={modal.reject}>
                        Cancel
                    </Button>
                    <Button
                        onClick={async () => {
                            ggDrivePicker.show({type: null});
                            modal.resolve();
                        }}
                    >
                        Update credentials
                    </Button>
                </div>
            </div>
        )
    );
