import * as React from "react";

import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";

import {omit} from "@common/utils/objects";
import {unique} from "@common/utils/collections";
import {Expandable} from "../../../../../common/expandable/expandable";
import {IgnoreUpdateModelPositions} from "./common";
import {DataSourceItem} from "./data-source-item";

export const DataSourcesMenu = ({
    model,
    chart,
    searchValue,
    removeTableService,
    shortestPossiblePathDialog,
    autoSuggestTables,
    usedInModel = false,
}) =>
    cs(
        consumeContext("interactions"),
        consumeContext("dataSourceModelContext"),
        ({dataSourceModelContext, interactions}, next) => {
            return IgnoreUpdateModelPositions({
                dataSourceModelContext,
                extraProps: {
                    interactionTimestamp: interactions.setting?.timestamp,
                    usedInModel,
                },
                next,
            });
        },
        ({interactions, dataSourceModelContext}) => {
            const interactionsData = omit(interactions.setting ?? {}, ["timestamp"]);

            const usedDataSourceIDs = usedInModel
                ? unique(Object.values(dataSourceModelContext.entities.modelTables ?? {}).map((modelTable) => modelTable.dataSourceID))
                : [];

            const searchedDataSources = dataSourceModelContext.getEntities(
                "dataSources",
                usedInModel ? {filterFn: (dataSource) => usedDataSourceIDs.includes(dataSource.id)} : {}
            );

            const toggleSelectedTable = (isExpanded, table) => {
                if (!isExpanded) {
                    interactions.selectTable({
                        tableId: table.id,
                        $type: table.$type,
                        dataSourceTableID: table.dataSourceTableID || table.id,
                        dataSourceID: table.dataSourceID,
                    });
                } else {
                    interactions.deSelectTable();
                }
            };

            return (
                <Expandable
                    {...{
                        passiveExpand: !!searchValue,
                        updateState: {
                            value: !interactionsData || interactionsData?.data?.$type != "ViewModelTable",
                        },
                        label: "Data Sources",
                        render: () =>
                            searchedDataSources.map((ds, i) => {
                                return (
                                    <DataSourceItem
                                        key={ds.id}
                                        {...{
                                            dataSource: ds,
                                            interactionsData: ds.id == interactionsData?.data?.dataSourceID ? interactionsData : null,
                                            dataSources: searchedDataSources,
                                            model,
                                            chart,
                                            searchValue,
                                            removeTableService,
                                            shortestPossiblePathDialog,
                                            autoSuggestTables,
                                            toggleSelectedTable,
                                        }}
                                    />
                                );
                            }),
                    }}
                />
            );
        }
    );
