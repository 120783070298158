const {unique} = require("../../../../utils/collections");
const {findMaxE} = require("../../../../utils/collections");
const {DATA_BOOST_THRESHOLD} = require("../../common/axis-chart-boost-turbo-constants");

/**
 * define categories for category dimension axis, so that HC preserves the categories order.
 */
const getCategories = ({rawSeries, series}) => {
    const dataLengthArr = unique(rawSeries.map((s) => s.data.length));

    // boost mode treats grid labels as index. need to define categories to show correct grid labels
    if (rawSeries.find((s) => s.data.length > DATA_BOOST_THRESHOLD)) {
        return {
            categories: findMaxE(rawSeries, (s) => s.data.length && !s.isCompare).data.map((d) => d[0]),
        };
    }

    if (dataLengthArr.length === 1) {
        return dataLengthArr[0] === 1 ? {categories: unique(rawSeries.map((s) => s.data[0][0]))} : {};
    }

    return {
        categories: findMaxE(rawSeries, (s) => s.data.length && !s.isCompare).data.map((d) => d[0]),
    };
};
exports.getCategories = getCategories;
