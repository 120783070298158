const {chain2} = require("../../../utils/fs");
const {cbc} = require("../../callback-chain");
const {Transformer} = require("../../transformer");
const {DebounceCache} = require("../../debounce-cache");
const {AsyncValidator} = require("./async-validator");
const {csArr} = require("../../cs-arr");
const {equalDeep} = require("../../../utils/objects");
const {scope} = require("../../scope");
const {cs} = require("../../chain-services");

const PerField = ({data, disableValidations, fields, next}) =>
    cs(
        [
            "validatedFields",
            (_, next) =>
                csArr(
                    (fields || []).map(({path, transforms: transformsConfig, debounce, validators, asyncValidators}) => (next) => {
                        const rawState = scope(data, path);

                        const failedSyncValidator = disableValidations
                            ? null
                            : validators?.find(
                                  (validator) =>
                                      (!validator.when || validator.when(data.value)) &&
                                      !validator.validate(rawState.value, {
                                          data: data.value,
                                      })
                              );

                        return cs(
                            [
                                "asyncValidators",
                                (_, next) =>
                                    csArr(
                                        (asyncValidators || []).map(
                                            ({validate}) =>
                                                (next) =>
                                                    AsyncValidator({
                                                        value: rawState.value,
                                                        disabled: disableValidations || failedSyncValidator,
                                                        validate: (value) =>
                                                            validate(value, {
                                                                data: data.value,
                                                            }),
                                                        next,
                                                    })
                                        ),
                                        next
                                    ),
                            ],
                            [
                                "state1",
                                (_, next) =>
                                    cbc({state: rawState}, [
                                        (_, next) =>
                                            !asyncValidators?.length && !debounce
                                                ? next(_)
                                                : DebounceCache({
                                                      state: _.state,
                                                      delay: debounce === true || !debounce ? undefined : debounce,
                                                      next: (debounce) =>
                                                          next({
                                                              state: debounce.state,
                                                              debounce,
                                                              pending: debounce.active,
                                                              flush: debounce.flush,
                                                          }),
                                                  }),
                                        (_, next) =>
                                            !transformsConfig?.length
                                                ? next(_)
                                                : Transformer({
                                                      state: _.state,
                                                      transform: (v) =>
                                                          chain2(
                                                              v,
                                                              transformsConfig.map((t) => transforms[t])
                                                          ),
                                                      next: (buffer) =>
                                                          next({
                                                              ..._,
                                                              state: buffer.state,
                                                              buffer,
                                                              flush: () => {
                                                                  _.flush?.();
                                                                  buffer.flush();
                                                              },
                                                          }),
                                                  }),
                                        next,
                                    ]),
                            ],
                            ({asyncValidators, state1}) =>
                                next({
                                    path,
                                    rawState,
                                    hasValidators: !!validators?.length || !!asyncValidators?.length,
                                    ...state1,
                                    pending: state1.pending || asyncValidators?.find((av) => av.status === "pending"),
                                    error: failedSyncValidator ?? asyncValidators?.find((av) => av.status === "failed"),
                                })
                        );
                    }),
                    next
                ),
        ],
        ({validatedFields}) =>
            next({
                field: (path) => {
                    const field1 = validatedFields.find((f1) => equalDeep(f1.path, path));
                    return (
                        field1 ??
                        (() => {
                            const state = scope(data, path);
                            return {
                                state,
                                rawState: state,
                            };
                        })()
                    );
                },
                pending: validatedFields.find((f) => f.pending),
                valid: !validatedFields.find((f) => f.pending || f.error),
                validData: !validatedFields.find((f) => f.error),
                errorField: validatedFields.find((f) => f.pending || f.error),
            })
    );
exports.PerField = PerField;

const transforms = {
    uppercase: (v) => v?.toUpperCase(),
    lowercase: (v) => v?.toLowerCase(),
    trim: (v) => v?.trim(),
};
