import React from "react";
import {cs} from "@common/react/chain-services";
import {scope} from "@common/react/scope";
import "./output-configuration.scss";
import {rColumnSelectFromStepOutput} from "../../../../common/column-select-from-step-output/column-select-from-step-output";
import {Form2} from "@common/react/cs-form/form2";
import {UseState} from "@common/react/use-state";
import {required} from "@common/react/cs-form/validators/required";
import {consumeContext} from "@common/react/context";
import {TextInput} from "../../../../../../../../../common/form/text-input/text-input";
import {ffToTextInput} from "../../../../../../../../../common/form/ff-to-text-input";
import {keyed} from "@common/react/keyed";
import {Invoke} from "@common/react/invoke";
import {keepOnly} from "@common/utils/objects";
import {ffToDropdown} from "../../../../../../../../../common/form/ff-to-dropdown";
import {rNewColumnNameInput} from "../../common/new-column-name-input";

export const OutputConfiguration = ({transformation, form: transformationForm, step}) =>
    cs(
        consumeContext("apis"),
        [
            "transformationName",
            (_, next) =>
                UseState({
                    getInitValue: () => ({
                        name: transformation.value.name,
                        lastUpdatedAt: null,
                    }),
                    next: ({value, onChange}) =>
                        next(
                            scope({
                                value,
                                onChange: (v) => {
                                    return onChange({
                                        name: v.name,
                                        lastUpdatedAt: Date.now(),
                                    });
                                },
                            })
                        ),
                }),
        ],
        [
            "form",
            ({transformationName, apis}, next) =>
                Form2({
                    data: transformationName,
                    fields: {
                        name: {
                            transforms: ["trim"],
                            validators: [required],
                            asyncValidators: [
                                {
                                    validate: (name, {data}) =>
                                        apis.transformation.checkTransformationName(name, {
                                            transformationId: transformation.value.id,
                                        }),
                                    getMessage: () => `Existed`,
                                },
                            ],
                            debounce: true,
                        },
                    },
                    onSubmit: () => {
                        transformation.change((transformation) => ({
                            ...transformation,
                            name: transformationName.value.name,
                            steps: transformation.steps.map((_step) => {
                                if (_step.id !== step.value.id) {
                                    return _step;
                                } else {
                                    return {
                                        ..._step,
                                        name: transformationName.value.name,
                                    };
                                }
                            }),
                        }));
                    },
                    next,
                }),
        ],
        ({form, transformationName}) => {
            const inputStep = transformation.value.steps.find((s) => s.id === step.value.inputStepID);

            return (
                <>
                    <div className="output-configuration-t4r">
                        {step.value.$type === "ColumnOutputStep" && (
                            <div className="config-group">
                                <div className="label">Column</div>
                                {rColumnSelectFromStepOutput({
                                    label: "Select",
                                    columns: inputStep?.outputColumns, //?.filter((c) => c.$type === "ViewStepColumn"),
                                })({
                                    state: scope(step, ["column"]),
                                    ...keepOnly(ffToDropdown(transformationForm.field(["column"])), ["hasError", "errorMessage", "domRef"]),
                                })}
                            </div>
                        )}

                        <div className="config-group">
                            <div className="label">{step.value.$type === "ColumnOutputStep" ? "Column" : "View"} Name</div>

                            {(() => {
                                const newColumnNameProps = ffToTextInput(transformationForm.field(["name"]));
                                const inputProps = ffToTextInput(form.field("name"));
                                return TextInput({
                                    label: "Name",
                                    ...inputProps,
                                    hasError: inputProps.hasError || newColumnNameProps.hasError,
                                    errorMessage: inputProps.errorMessage || newColumnNameProps.errorMessage,
                                });
                            })()}
                        </div>
                    </div>

                    {transformationName.value.lastUpdatedAt &&
                        transformationName.value.name !== transformation.value.name &&
                        form.valid &&
                        cs(keyed(transformationName.value.lastUpdatedAt), () =>
                            Invoke({
                                fn: () => {
                                    form.submit();
                                },
                            })
                        )}
                </>
            );
        }
    );
