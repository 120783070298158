const defaultUnits = [0, 1];

export function simpleLinearScale(range = defaultUnits, domain = defaultUnits) {
    let minValue = domain[0];
    let maxValue = domain[1];
    let minScale = range[0];
    let maxScale = range[1];

    function scale(value) {
        if (value < minValue) return minScale;
        if (value > maxValue) return maxScale;

        const ratio = (maxScale - minScale) / (maxValue - minValue);

        return minScale + ratio * (value - minValue);
    }

    scale.domain = (newDomain) => {
        minValue = newDomain[0];
        maxValue = newDomain[1];
    };

    scale.range = (newRange) => {
        minScale = newRange[0];
        maxScale = newRange[1];
    };

    return scale;
}
