const {chain} = require("./fs");
const {findMinValue, findMaxValue} = require("./collections");

const sum = require("./collections").sum;

const {sort} = require("./collections");

const calculateRange = (col) => {
    if (col == null || col.length === 0) {
        return null;
    }
    let ret = {from: col[0], to: col[0]};
    for (let i = 1; i < col.length; i++) {
        const e = col[i];
        if (e < ret.from) {
            ret.from = e;
        } else if (e > ret.to) {
            ret.to = e;
        }
    }
    return ret;
};
exports.calculateRange = calculateRange;

const inRange = (value, range, {ge, lt} = compares) => {
    if (range == null) {
        return false;
    }
    return (range.from == null || ge(value, range.from)) && (range.to == null || lt(value, range.to));
};
exports.inRange = inRange;

const createRangeGross = (v1, v2) => {
    return {
        from: Math.min(v1, v2),
        to: Math.max(v1, v2) + 1,
    };
};
exports.createRangeGross = createRangeGross;

const orRanges = (ranges) => {
    ranges = ranges.slice(0);
    for (let i = 0; i < ranges.length; i++) {
        for (let j = i + 1; j < ranges.length; j++) {
            const r1 = ranges[i];
            const r2 = ranges[j];

            if (conflict(r1, r2)) {
                j--;
                ranges[i] = createRangeGross(r1, r2);
                ranges.splice(j, 1);
            }
        }
    }
    return ranges;
};
exports.orRanges = orRanges;

const toArr = (range) => {
    return new Array(range.to - range.from).fill(0).map((_, i) => i + range.from);
};
exports.toArr = toArr;

const sumRanges = (ranges) => {
    let ret = {from: 0, to: 0};
    for (const range of ranges) {
        ret.from += range.from;
        ret.to += range.to;
    }
    return ret;
};
exports.sumRanges = sumRanges;

const rangeSingle = (v) => ({from: v, to: v + 1});
exports.rangeSingle = rangeSingle;

const range0 = (v) => ({from: v, to: v});
exports.range0 = range0;

const toRange = (v, length) => {
    if (length > 0) {
        return {from: v, to: v + length};
    } else {
        return {from: v, to: v};
    }
};
exports.toRange = toRange;

const rangeLength = (r) => (r == null ? 0 : r.to - r.from);
exports.rangeLength = rangeLength;

const conflict12 = (r1, rs) => {
    return rs.find((r2) => conflict(r1, r2));
};
exports.conflict12 = conflict12;

const conflict = (r1, r2, {ge} = compares) => {
    r1["r1 is null"];
    r2["r2 is null"];

    if (r1.from != null && r2.to != null && ge(r1.from, r2.to)) {
        return false;
    }
    if (r2.from != null && r1.to != null && ge(r2.from, r1.to)) {
        return false;
    }
    return true;
};
exports.conflict = conflict;

const conflictStrict = (r1, r2, {gt} = compares) => {
    if (r1.from != null && r2.to != null && gt(r1.from, r2.to)) {
        return false;
    }
    if (r2.from != null && r1.to != null && gt(r2.from, r1.to)) {
        return false;
    }
    return true;
};
exports.conflictStrict = conflictStrict;

/**
 * Add all layers of ranges (2-dimention) to each other so we have a single layer of ranges
 * @param ranges2
 * @returns {Array}
 */
const merge = (...ranges2) => {
    let ret = [];

    for (;;) {
        ranges2 = ranges2.filter((arr) => arr != null && arr.length > 0);

        if (ranges2.length === 0) {
            break;
        }

        ranges2 = sort(ranges2, (ranges) => ranges[0].from);

        let range = ranges2[0][0];
        ranges2[0] = ranges2[0].slice(1);

        for (let i = 1; i < ranges2.length; i++) {
            consumeRanges(ranges2[i], range);
        }

        ret.push(range);
    }

    return ret;
};
exports.merge = merge;

const rangesGross = (ranges) => {
    return {
        from: Math.min(...ranges.map((r) => r.from)),
        to: Math.max(...ranges.map((r) => r.to)),
    };
};
exports.rangesGross = rangesGross;

const consumeRanges = (ranges, range) => {
    for (; ranges.length > 0; ) {
        const tr = ranges[0];
        if (tr.from <= range.to) {
            range.to = Math.max(range.to, tr.to);
            ranges.splice(0, 1);
        } else {
            break;
        }
    }
};

const exclude22 = (oriRanges, holes, compares) => {
    if (oriRanges == null) {
        return null;
    }

    let ret = [];
    for (const oriRange of oriRanges) {
        ret = [...ret, ...exclude12(oriRange, holes, compares)];
    }
    return ret;
};
exports.exclude = exclude22;

const exclude12 = (oriRange, holes, compares) => {
    let ret = [oriRange];

    for (const hole of holes) {
        for (let i = ret.length - 1; i > -1; i--) {
            const oriRange = ret[i];

            ret.splice(i, 1, ...exclude11(oriRange, hole, compares));
        }
    }
    return ret;
};
exports.exclude12 = exclude12;

const compares = {
    le: (v1, v2) => v1 <= v2,
    lt: (v1, v2) => v1 < v2,
    ge: (v1, v2) => v1 >= v2,
    gt: (v1, v2) => v1 > v2,
};

const exclude11 = (oriRange, hole, c) => {
    if (!conflict(oriRange, hole, c)) {
        return [oriRange];
    }

    // They have to conflict at some point...

    const {le, ge, lt} = c || compares;
    if (hole.to == null || (oriRange.to != null && le(oriRange.to, hole.to))) {
        // hole cover ori to
        if (hole.from != null && (oriRange.from == null || lt(oriRange.from, hole.from))) {
            // hole does not cover ori from
            // hole cuts later half of oriRange
            return [{...oriRange, from: oriRange.from, to: hole.from}];
        } else {
            // Hole cover all ori -> Vanishes
            return [];
        }
    } else {
        // hole does not cover ori to
        if (hole.from == null || (oriRange.from != null && ge(oriRange.from, hole.from))) {
            // Hole cuts first half of ori
            return [{...oriRange, from: hole.to, to: oriRange.to}];
        } else {
            // ori range cover hole
            return [
                {...oriRange, from: oriRange.from, to: hole.from},
                {...oriRange, from: hole.to, to: oriRange.to},
            ];
        }
    }
};
exports.exclude11 = exclude11;

const sortRanges = (ranges) => sort(ranges, (r) => r.from);
exports.sortRanges = sortRanges;

const equalRanges = (r1, r2) => {
    if (r1 == null) {
        return r2 == null;
    } else if (r2 == null) {
        return false;
    }
    return r1.from === r2.from && r1.to === r2.to;
};
exports.equalRanges = equalRanges;

/**
 * Non conflicting
 */
const insertSorted = (ranges, range, {le} = compares) => {
    if (range == null) return ranges;
    if (ranges == null) {
        return [range];
    }
    for (let i = 0; i < ranges.length; i++) {
        const r1 = ranges[i];
        if (range.from == null || (range.to != null && le(range.to, r1.from))) {
            return [...ranges.slice(0, i), range, ...ranges.slice(i)];
        }
    }
    return [...ranges, range];
};
exports.insertSorted = insertSorted;

const rangeAdd = (range, delta) => ({
    from: range.from + delta,
    to: range.to + delta,
});

exports.rangeAdd = rangeAdd;

/**
 * 1-5 + 3-8 = 1-3,3-5,5-8
 * @param ranges
 */
const fragmentRanges = (ranges) => {
    if (ranges == null) return null;

    let fragmenteds = [];
    for (const range of ranges) {
        fragmenteds = addFraRanges([range], fragmenteds);
    }

    return sortRanges(fragmenteds);
};
exports.fragmentRanges = fragmentRanges;

const addFraRange = (rsToAdd, rFra) => {
    let oris = [rFra];
    for (let i = oris.length - 1; rsToAdd.length && i > -1; i--) {
        const rO = oris[i];

        let remains = [];
        for (const rToAdd of rsToAdd) {
            if (conflict(rO, rToAdd)) {
                oris.splice(i, 1, ...exclude11(rO, rToAdd), common(rO, rToAdd));
                remains = [...remains, ...exclude11(rToAdd, rO)];
            } else {
                remains.push(rToAdd);
            }
        }
        rsToAdd = remains;
    }
    return {
        oris,
        remains: rsToAdd,
    };
};
const addFraRanges = (rsToAdd, fragmenteds) => {
    let rsFra = fragmenteds.slice(0);

    for (let i = rsFra.length - 1; i > -1; i--) {
        const {oris, remains} = addFraRange(rsToAdd, rsFra[i]);

        rsToAdd = remains;
        rsFra.splice(i, 1, ...oris);
    }

    rsFra = [...rsFra, ...rsToAdd];
    return rsFra;
};

const common = (r1, r2) =>
    !conflict(r1, r2)
        ? null
        : {
              from: Math.max(r1.from, r2.from),
              to: Math.min(r1.to, r2.to),
          };
exports.commonRange = common;

const sumRangeWeights = (rws) =>
    rws &&
    chain(fragmentRanges(rws.map((rw) => rw.range)), (ranges) =>
        ranges.map((range) => ({
            range,
            weight: sum(rws.map((rw) => (conflict(range, rw.range) ? rw.weight : 0))),
        }))
    );
exports.sumRangeWeights = sumRangeWeights;

const combineRangeWeights = (rws1, rws2, fn) => {
    if (!rws1 || !rws2) {
        return null;
    }
    return chain(fragmentRanges([...rws1.map((rw) => rw.range), ...rws2.map((rw) => rw.range)]), (ranges) =>
        ranges.map((range) => ({
            range,
            weight: fn(sum(rws1.map((rw) => (conflict(range, rw.range) ? rw.weight : 0))), sum(rws2.map((rw) => (conflict(range, rw.range) ? rw.weight : 0)))),
        }))
    );
};
exports.combineRangeWeights = combineRangeWeights;

const getWeight = (x, rws) => {
    const rw = rws.find((rw) => inRange(x, rw.range));
    return rw && rw.weight;
};
exports.getWeight = getWeight;

const getMinRangeWeight = (range, rws) => chain(sumRangeWeights(rws), (rangeWeights) => findMinValue(rangeWeights.filter((rw) => range == null || conflict(range, rw.range)).map((rw) => rw.weight)) || 0);
exports.getMinRangeWeight = getMinRangeWeight;

const getMaxRangeWeight = (range, rws) => chain(sumRangeWeights(rws), (rangeWeights) => findMaxValue(rangeWeights.filter((rw) => range == null || conflict(range, rw.range)).map((rw) => rw.weight)) || 0);
exports.getMaxRangeWeight = getMaxRangeWeight;

const extendsRange = (range, length) => ({
    from: range.from - length,
    to: range.to + length,
});
exports.extendsRange = extendsRange;

const expandRange = (range, amount) => {
    return {
        from: range.from - amount,
        to: range.to + amount,
    };
};
exports.expandRange = expandRange;

const moveRange = (range, delta) => {
    return {
        from: range.from != null ? range.from + delta : null,
        to: range.to != null ? range.to + delta : null,
    };
};
exports.moveRange = moveRange;
