import {cs} from "@common/react/chain-services";
import {createNumericFormatter} from "@common/ui-components/charts/common/formatters/numeric/numeric-formatter";
import {chain} from "@common/utils/fs";
import {roundTo} from "@common/ui-components/charts/common/formatters/numeric/round-to";
import {FieldSection} from "../../../field-section/field-section";
import {FieldInfoProvider} from "../../../../../../tile-tab/chart-types/common/field-info-provider/field-info-provider";
import * as React from "react";

export const NumberFieldPreview = ({field}) =>
    cs(() => {
        const numericProperties = field.value.numericProperties;

        const numericFormatter = createNumericFormatter({
            displayType: numericProperties?.displayType || "Number",

            decimalSeperator: numericProperties?.decimalSeperator || "Dot",
            thousandsSeperator: numericProperties?.thousandsSeperator || "Comma",
            decimalPlaces: numericProperties?.decimalPlaces ?? ((field.value.aggregationFunc ?? "").startsWith("Count") ? 0 : 2),

            negativeFormat: numericProperties?.negativeFormat || "Parentheses",

            currencyProperties: {
                currency: numericProperties?.currencyProperties?.currency || "USD",
                currencySymbolPlacement: numericProperties?.currencyProperties?.currencySymbolPlacement || "Left",
                currencySymbol: numericProperties?.currencyProperties?.currencySymbol || "$",
                spaceBetweenSymbolAndNumber: numericProperties?.currencyProperties?.spaceBetweenSymbolAndNumber,
            },
        });

        const formatter = (v) =>
            chain(
                v,
                (v) => (numericProperties?.displayType === "Percentage" ? v * 100 : v),
                (_) => (numericProperties?.decimalPlaces != null ? roundTo(_, numericProperties.decimalPlaces) : roundTo(_, 2)),
                (_) => numericFormatter(_)
            );

        return FieldSection({
            header: FieldInfoProvider({
                field: <div className="text">PREVIEW</div>,
            }),
            content: (
                <div className="number-format-preview-content-a33">
                    <div className="value-wrapper">
                        <div className="positive">{formatter(numericProperties?.displayType === "Percentage" ? 0.123456 : 1234.56)}</div>
                        <div className="negative">{formatter(numericProperties?.displayType === "Percentage" ? -0.123456 : -1234.56)}</div>
                    </div>
                </div>
            ),
        });
    });
