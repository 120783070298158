import {cs} from "@common/react/chain-services";
import {DebounceCache} from "@common/react/debounce-cache";
import {TextInput} from "../../../../../../../../../../../../../common/form/text-input/text-input";
import * as React from "react";
import {Static2} from "@common/react/static-2";
import {UseState} from "@common/react/use-state";
import "./x-axis.scss";
import {ShowHideToggle} from "../../../../../../../../../../../../../common/form/toggles/show-hide-toggle";

export const AxisTitle = ({state}) =>
    cs(
        ["legendRef", (_, next) => Static2({next})],
        ["pos", (_, next) => UseState({next})],
        [
            "debounce",
            (_, next) =>
                DebounceCache({
                    state,
                    next,
                }),
        ],
        ({debounce, legendRef, pos}) => (
            <div className="axis-title-a22">
                {TextInput({
                    label: "Axis Title",
                    state: debounce.state,
                    onBlur: debounce.flush,
                })}

                <div className="tooltip">
                    <i
                        className="material-icons"
                        ref={legendRef.set}
                        onMouseEnter={() => {
                            const {top, right} = legendRef.get().getBoundingClientRect();
                            pos.onChange({top: top - 90, left: right - 90});
                        }}
                        onMouseLeave={() => {
                            pos.onChange(null);
                        }}
                    >
                        info
                    </i>
                </div>

                {pos.value && (
                    <div
                        className="tooltip-box-a33 bottom"
                        style={{
                            ...pos.value,
                        }}
                    >
                        Axis Title is displayed in:
                        <div>- Axis Label </div>
                        <div>- Chart tooltips</div>
                        <div>- Alternate display options</div>
                    </div>
                )}
            </div>
        )
    );

export const isShowXAxisLabel = ({size, tile}) => {
    if (!size) {
        return false;
    }

    const {height} = size;
    if (height < 3) return false;
    if (tile.style.title?.show) {
        return height >= 4;
    } else {
        return height >= 3;
    }
};

export const XAxisLabel = ({size, tile, state}) =>
    cs(["legendRef", (_, next) => Static2({next})], ["pos", (_, next) => UseState({next})], ({legendRef, pos}) => (
        <div className="header">
            Axis Label
            <div className="control">
                {isShowXAxisLabel({size, tile: tile.value}) ? (
                    ShowHideToggle({state})
                ) : (
                    <div
                        className="hide-btn-a22"
                        ref={legendRef.set}
                        onMouseEnter={() => {
                            const {top, right} = legendRef.get().getBoundingClientRect();
                            pos.onChange({
                                top: top - 150,
                                left: right - 110,
                            });
                        }}
                        onMouseLeave={() => {
                            pos.onChange(null);
                        }}
                    >
                        Hide
                    </div>
                )}
            </div>
            {pos.value && (
                <div
                    className="tooltip-box-a33 bottom"
                    style={{
                        ...pos.value,
                    }}
                >
                    AX-Axis Label can only be shown when the chart is at least 3 squares high and the chart title is hidden.
                    <br />
                    <br />
                    When the chart title is shown, the chart must be at least 4 squares high.
                </div>
            )}
        </div>
    ));

export const isShowGridStepLabel = ({size}) => {
    const {height} = size;
    return height >= 3;
};

export const XGridStepLabel = ({size, state}) =>
    cs(["legendRef", (_, next) => Static2({next})], ["pos", (_, next) => UseState({next})], ({legendRef, pos}) => (
        <div className="header">
            Grid Step Labels
            <div className="control">
                {isShowGridStepLabel({size}) ? (
                    ShowHideToggle({state})
                ) : (
                    <div
                        className="hide-btn-a22"
                        ref={legendRef.set}
                        onMouseEnter={() => {
                            const {top, right} = legendRef.get().getBoundingClientRect();
                            pos.onChange({
                                top: top - 75,
                                left: right - 110,
                            });
                        }}
                        onMouseLeave={() => {
                            pos.onChange(null);
                        }}
                    >
                        Hide
                    </div>
                )}
            </div>
            {pos.value && (
                <div
                    className="tooltip-box-a33 bottom"
                    style={{
                        ...pos.value,
                    }}
                >
                    X-Axis Step Labels can only be shown when the chart is at least 3 squares high.
                </div>
            )}
        </div>
    ));
