import * as React from "react";
import {ModalService} from "../modal-service";
import {Dialog} from "./dialog";

export const DialogService = ({render, styleType, initShow, strong, className, forceShowCloseButton = false, registryRender = false, disabledClickOverlay = false, next, customCloseIcon, noHeader, customizeDialog}) =>
    ModalService({
        registryRender,
        initShow,
        strong,
        disabledClickOverlay,
        forceShowCloseButton,
        noHeader,
    render: ({args, resolve, reject, active}) => Dialog({
        disabledClickOverlay,
        forceShowCloseButton,
        noHeader,
                strong,styleType,
                active,
                resolve,
                className,
                customCloseIcon,
                customizeDialog,
        ...render({args, resolve, reject, active}),
    }),
    next,
});
