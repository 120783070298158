import {scope} from "@common/react/scope";
import {ShowHideToggle} from "../../../../../../../../../../../../common/form/toggles/show-hide-toggle";
import {isSectionLabelHidden} from "../display-rules";
import {HiddenFieldAlert} from "../../common/hidden-field-alert/hidden-field-alert";

export const SectionLabels = ({tile, size}) => ({
    label: "Section labels",
    control: isSectionLabelHidden({size})
        ? HiddenFieldAlert({
              info: "Section labels can only be shown when the chart is at least 8 squares wide and 5 squares high.",
          })
        : ShowHideToggle({state: scope(tile, ["style", "dataLabels", "show"])}),
});
