import "./map-model-fields-service.scss";

import * as React from "react";

import {Load} from "@common/react/load";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {consumeContext} from "@common/react/context";

import {LoadingIndicator} from "@common/ui-components/loading-indicator/loading-indicator";
import {flatten1, replaceFind, unique} from "@common/utils/collections";
import {cascadeFind} from "@common/utils/cascade";
import {ModelColumnCard} from "./model-column-card";
import {getFieldType} from "@common/ui-components/charts/common/get-field-type";
import {cModelColumnDataSourcesSelect, isPublishedTransformedColumn, isPublishedView} from "./model-column-data-sources-select";

/**
 * this is used to map a set of tile/collection fields to a set of fields belonging to an another model
 * */
export const MapModelFieldsService = ({
    originFields, // origin collection fields or tile fields
    originModelId,
    destinationModelId,
    next: rootNext,
}) =>
    cs(
        (_, next) =>
            !originFields?.length > 0
                ? rootNext({
                      renderMapTable: () => "There are not any fields to map.",
                      fieldsMap: [],
                  })
                : next(),
        consumeContext("apis"),
        [
            "models",
            ({apis}, next) =>
                Load({
                    fetch: () =>
                        Promise.all(
                            [originModelId, destinationModelId].map(async (modelId) => {
                                return await apis.model.getModel(modelId);
                            })
                        ),
                    next,
                }),
        ],
        [
            "dataSources",
            ({models, apis}, next) => {
                const dsIds = models?.length > 0 && unique(flatten1(models.map((model) => model.dataSourceIDs)));
                return Load({
                    _key: JSON.stringify(dsIds),
                    fetch: () =>
                        dsIds &&
                        Promise.all(
                            dsIds.map(async (dsId) => {
                                return await apis.data.getDataSource(dsId);
                            })
                        ),
                    next,
                });
            },
        ],
        ({models, dataSources}, next) =>
            !models?.length > 0 || !dataSources?.length > 0 ? rootNext({renderMapTable: () => LoadingIndicator({})}) : next(),
        [
            "fieldsMap",
            ({models}, next) =>
                UseState({
                    initValue: getInitFieldsMap(originFields, models[0], models[1]),
                    next,
                }),
        ],
        ({models, dataSources, fieldsMap}) => {
            return rootNext({
                fieldsMap: fieldsMap.value,
                renderMapTable: () => {
                    // console.log("origin model", models?.[0])
                    // console.log("destination model", models?.[1])
                    // console.log("dataSources", dataSources)
                    // console.log("fieldsMap", fieldsMap.value)

                    const getOriginModelColumn = cGetModelColumn(models[0]);
                    // const getDestinationModelColumn = cGetModelColumn(models[1]);
                    const getDataSourceInfo = cGetDataSourceInfo(dataSources);

                    const rOriginModelField = (tileField) => {
                        const modelColumn = getOriginModelColumn(tileField);
                        try {
                            const dsInfo = getDataSourceInfo(modelColumn);
                            return ModelColumnCard({
                                modelColumn,
                                dsInfo,
                                nameTooltipEnabled: true,
                                dataSourceTooltipEnabled: true,
                            });
                        } catch (e) {
                            console.log({modelColumn, tileField});
                        }
                    };

                    const modelColumnDataSourcesSelect = cModelColumnDataSourcesSelect({
                        model: models[1],
                        dataSources,
                        getDataSourceInfo,
                        getValueModelColumn: getOriginModelColumn,
                    });

                    return (
                        <div className="map-model-fields-y83 map-model-fields">
                            <table>
                                <thead>
                                    <tr>
                                        <th className="origin-model">{models[0].name}</th>
                                        <th className="arrow" />
                                        <th className="destination-model">{models[1].name}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {originFields.map((oriField, i) => (
                                        <tr key={(oriField.id || "") + i} className="map-table">
                                            <td className="origin-field">{rOriginModelField(oriField)}</td>
                                            <td className="arrow">
                                                <img src={require("./mapping-arrow.svg")} alt={""} />
                                            </td>
                                            <td className="destination-field">
                                                {(() => {
                                                    const fieldMap = fieldsMap.value.find(
                                                        (fm) =>
                                                            (fm.oriField.id && oriField.id && fm.oriField.id === oriField.id) ||
                                                            // a tile field may not have id (sort, limit, ...)
                                                            (fm.oriField.modelColumnID === oriField.modelColumnID &&
                                                                fm.oriField.modelTableID === oriField.modelTableID)
                                                    );
                                                    return modelColumnDataSourcesSelect({
                                                        value: fieldMap.destinationModelField,
                                                        onChange: (column) =>
                                                            fieldsMap.onChange(
                                                                replaceFind(
                                                                    fieldsMap.value,
                                                                    {
                                                                        ...fieldMap,
                                                                        destinationModelField: column,
                                                                    },
                                                                    (fm) => fm === fieldMap
                                                                )
                                                            ),
                                                        allowedType: getFieldType(getOriginModelColumn(oriField)),
                                                    });
                                                })()}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    );
                },
            });
        }
    );

const cGetModelColumn = (model) => (tileField) => {
    return cascadeFind(model, "tables[*].columns[*]", (column, {}, {1: table}) => {
        if (model.id === tileField.modelID && table.id === tileField.modelTableID && column.id === tileField.modelColumnID) {
            return {
                ...column,
                tableName: table.name,
                ...(["CalculatedModelColumn", "AggregatedMeasureModelColumn"].includes(column.$type) && {
                    dataSourceID: table.dataSourceID,
                    dataSourceTableID: table.dataSourceTableID,
                }),
            };
        }
    });
};

export const cGetDataSourceInfo = (dataSources) => (modelColumn) => {
    if (modelColumn.$type === "ViewModelColumn") {
        return;
    }
    return cascadeFind(dataSources, "[*].tables[*].columns[*]", (column, {}, {0: dataSource, 2: table}) => {
        if (
            modelColumn.dataSourceID === dataSource.id &&
            modelColumn.dataSourceTableID === table.id &&
            (modelColumn.dataSourceColumnID === column.id ||
                ["CalculatedModelColumn", "AggregatedMeasureModelColumn"].includes(modelColumn.$type))
        ) {
            return {
                dsName: dataSource.name,
                dsTableName: table.name,
            };
        }
    });
};

const getInitFieldsMap = (originFields, originModel, destinationModel) => {
    const getOriginModelColumn = cGetModelColumn(originModel);
    return originFields.map((oriField) => {
        return {
            oriField,
            destinationModelField: cascadeFind(destinationModel, "tables[*].columns[*]", (column, {}, {1: table}) => {
                const originColumn = getOriginModelColumn(oriField);
                if (
                    originColumn &&
                    column &&
                    // column.dataSourceID === originColumn.dataSourceID &&
                    // column.dataSourceTableID === originColumn.dataSourceTableID &&
                    // column.dataSourceColumnID === originColumn.dataSourceColumnID &&
                    originColumn.tableName == table.name &&
                    originColumn.name == column.name &&
                    getFieldType(column) === getFieldType(originColumn) &&
                    isPublishedTransformedColumn(column) &&
                    isPublishedView(table)
                ) {
                    return {
                        ...column,
                        tableId: table.id,
                        tableName: table.name,
                        modelId: destinationModel.id,
                        // for navigating calculated column and aggregated measure column in the dropdown select
                        ...(["CalculatedModelColumn", "AggregatedMeasureModelColumn"].includes(column.$type) && {
                            dataSourceID: table.dataSourceID,
                            dataSourceTableID: table.dataSourceTableID,
                        }),
                    };
                }
                return null;
            }),
        };
    });
};
