const {unique} = require("../../../utils/collections");
const {sum} = require("../../../utils/collections");

const addUpSameCategories = ({series}) => {
    const addUpCat = (data) => {
        const categories = unique(data.map((d) => d[0]));

        if (categories.length === data.length) {
            return data;
        }

        return categories.map((c) => {
            return [c, sum(data.filter((d) => d[0] === c).map((d) => d[1]))];
        });
    };

    return series.map((s) => ({
        ...s,
        data: addUpCat(s.data),
    }));
};
exports.addUpSameCategories = addUpSameCategories;
