import {cs} from "../../../../../../react/chain-services";
import * as React from "react";
import "./numeric-filter.scss";
import {NumericFilterSlider} from "./slider/numeric-filter-slider";
import {NumericFilterInput} from "./input/numeric-filter-input";
import {NumericChipInput} from "./chip-input/numeric-chip-input";

export const NumericFilter = ({filter, form, providedFilterData, next, filterValues, apiKey}) =>
    cs(({}) => {
        const comp = filter.design === "Slider" ? NumericFilterSlider : ["InList", "NotInList"].includes(filter.defaultOption) ? NumericChipInput : NumericFilterInput;

        return comp({
            form,
            filter,
            providedFilterData,
            next,
            filterValues,
            apiKey,
        });
    });
