import {cs} from "@common/react/chain-services";
import * as React from "react";
import "./adding-filter-left-panel.scss";
import {FilterLeftPanel} from "../../../../../common/filters/filter-left-panel/filter-left-panel";
import {cx} from "emotion";
import {SuspendUpdate} from "@common/react/suspend-update";
import {ConfigFieldOverride} from "../../../../../common/filters/filter-left-panel/config-field-override";
import {UseState} from "@common/react/use-state";
import {SelectFilterField} from "../../../../../common/filters/filter-left-panel/select-filter-field";
import {consumeContext} from "@common/react/context";

export const AddingFilterLeftPanel = ({adding: filter, form}) =>
    cs(consumeContext("collection"), ["override", (_, next) => UseState({next})], ({override}) => (
        <div className="adding-filter-left-panel-23e">
            {FilterLeftPanel({filter, form, override})}

            <div className={cx("override", {active: override.value})}>
                {cs(
                    ({}, next) => SuspendUpdate({active: !override.value, next}),
                    ({}) => {
                        if (override.value?.type === "edit") {
                            return ConfigFieldOverride({
                                label: override.value?.label,
                                state: {
                                    value: override.value ? filter.value.columns.find((c) => c.modelColumnID === override.value.modelColumnID) : {},
                                    onChange: (val) =>
                                        filter.change((filter) => ({
                                            ...filter,
                                            columns: filter.columns.map((col) => (col.modelColumnID === override.value.modelColumnID ? val : col)),
                                        })),
                                },
                                onClose: () => override.onChange(null),
                            });
                        }

                        if (override.value?.type === "add") {
                            return SelectFilterField({
                                filter,
                                allowedFieldType: override.value.allowedFieldType,
                                onClose: () => override.onChange(null),
                            });
                        }
                    }
                )}
            </div>
        </div>
    ));
