import React from "react";
import {cs} from "@common/react/chain-services";
import "./dashboard-layout.scss";
import {TopSection} from "./top-section/top-section";
import {BottomSection} from "./bottom-section/bottom-section";
import {consumeContext} from "@common/react/context";
import {cx} from "emotion";

export const DashboardLayout = ({envId, next, tab, mainStyle = {}}) =>
    cs(consumeContext("routeTransitioning"), ({routeTransitioning}) => (
        <div className={cx("dashboard-layout-4r1", {transitioning: routeTransitioning?.transitioning}, routeTransitioning?.transitioning)}>
            <div className="left-nav">
                <div className="left-nav-header">
                    <div className="verb-brand" onClick={() => envId.onChange(null)}><img src="/logo.png" alt="Revive" /></div>
                </div>

                <TopSection {...{envId, tab}} />

                <BottomSection />
            </div>

            <div className="content" style={{...mainStyle}}>
                {next()}
            </div>
        </div>
    ));
