const {CookieStorage} = require("../../../../common/logic/cookie-storage");
const {billingHost} = require("../../routes/account-management/common/org-billing-constant");
const {WholePageLoading} = require("@common/ui-components/whole-page-loading/whole-page-loading");
const {scope} = require("@common/react/scope");
const {Invoke} = require("@common/react/invoke");
const {fragments} = require("@common/react/fragments");
const {refreshAuthToken} = require("../../apis/user-apis/refresh-auth-token");
const {AsyncCache} = require("@common/react/async-cache");
const {spc} = require("@common/react/state-path-change");
const {isDev} = require("../../../../tools/dev/is-dev");
const {GlobalKeyDown} = require("@common/react/keys/global-key-down");
const {equalDeep} = require("../../../../common/utils/objects");
const {fetchUser} = require("../../apis/user-apis/fetch-user");
const {waitTimeout} = require("../../../../common/utils/wait-timeout");
const {UseState} = require("@common/react/use-state");
const {cs} = require("@common/react/chain-services");
const {cSsJson} = require("@common/react/ss-json");
const {LoadingIndicator} = require("@common/ui-components/loading-indicator/loading-indicator");

const Authentication = ({isPreviewIframe, next}) =>
    cs(
        [
            "state",
            (_, next) =>
                UseState({
                    getInitValue: () => {
                        return {
                            xTenantId: CookieStorage.get("verb.admin.tenantid"),
                            verifiedAuthToken: null,
                            user: CookieStorage.get("verb.user"),
                            access: CookieStorage.get("verb.auth.access"),
                            //hasRefresh: (CookieStorage.get("verb.auth.refresh")?.length || 0) > 0,
                            rejectCurrentAuthRoute: false,
                        };
                    },
                    next,
                }),
        ],
        ({state}, next) => {
            if (state.value?.user?.temp) {
                window.location.replace(billingHost);
            }
            return next();
        },
        ({state}, next) => {
            return fragments(
                next(),

                // Watch({
                //     value: state.value.auth,
                //     onChanged: (auth) => CookieStorage.set("auth", auth),
                // }),

                !state.verifiedAuthToken &&
                    Invoke({
                        fn: async () => {
                            state.onChange({
                                verifiedAuthToken: true,
                                ...(await (async () => {
                                    try {
                                        const {user} = await (async () => {
                                            const user = await confirmUser();
                                            if (user) {
                                                return {user};
                                            }
                                            try {
                                                const auth1 = await refreshAuthToken();
                                                if (auth1) {
                                                    return {
                                                        user: await confirmUser(),
                                                    };
                                                }
                                            } catch {}
                                            return {};
                                        })();

                                        if (!user) {
                                            return {};
                                        } else {
                                            let access1 = CookieStorage.get("verb.auth.access");
                                            // if (equalDeep(user.roles, ["Verb"])) {
                                            //     const xTenantId = CookieStorage.get("verb.admin.tenantid") ?? user.tenantID;
                                            //     if (xTenantId)
                                            //         return {
                                            //             user,
                                            //             access: access1,
                                            //             xTenantId,
                                            //         };
                                            //     else {
                                            //         return {
                                            //             user: null,
                                            //             access: null,
                                            //         };
                                            //     }
                                            // } else {
                                            return {user, access: access1, xTenantId: user.tenantID};
                                            // }
                                        }
                                    } catch (e) {
                                        //console.log(e);
                                        return {error: "network"};
                                    }
                                })()),
                            });
                        },
                    })
            );
        },

        ({state}, next) =>
            state.value.error
                ? "Network error, cannot connect to Verb server!"
                : !state.value.verifiedAuthToken
                ? isPreviewIframe
                    ? LoadingIndicator({})
                    : WholePageLoading({content: "Loading auth..."})
                : next(),
        ({state}, next) =>
            isDev()
                ? GlobalKeyDown({
                      keyCombo: "shift+F9",
                      onKeyDown: () => {
                          //console.log("Invalidated authToken");
                          spc(state, ["auth", "authToken"], (_) => "Invalidated!" + _);
                      },
                      next,
                  })
                : next(),
        [
            "refreshAuthToken",
            ({state, auth}, next) =>
                AsyncCache({
                    action: async () => {
                        const result = await refreshAuthToken();
                        return result.loginStatus === "Success";
                    },
                    next,
                }),
        ],
        ({state, refreshAuthToken}) =>
            next({
                user: state.value.user,
                access: state.value.access,
                tenantId: state.value.xTenantId,
                rejectCurrentAuthRoute: scope(state, ["rejectCurrentAuthRoute"]),
                refreshAuthToken,
                invalidate: () => spc(state, ["auth"], () => null),
                logout: () => {
                    state.change((s) => ({
                        ...s,
                        rejectCurrentAuthRoute: true,
                        user: null,
                        access: null,
                    }));

                    cSsJson("SEARCH_VALUE").set(null);
                    //remove verb.admin.tenantid
                    CookieStorage.set("verb.admin.tenantid", null);
                },
                refetchUser: async () => {
                    const user = await confirmUser();

                    state.change((s) => ({
                        ...s,
                        user: user ?? s.user,
                    }));
                },
                setAuth: ({user, tenantId, access}) => {
                    state.change((s) => ({
                        ...s,
                        user: user ?? s.user,
                        access: access ?? s.access,
                        xTenantId: tenantId ?? s.xTenantId,
                    }));
                },
            })
    );
exports.Authentication = Authentication;

const confirmUser = async () => {
    return await Promise.race([
        (async () => {
            const res = await fetchUser();

            if (res.status !== 401 && res.status !== 403) {
                return await res.json();
            } else {
                return null;
            }
        })(),
        (async () => {
            await waitTimeout(!isDev ? 10000 : 25000);
            return null;
        })(),
    ]);
};
