import React from "react";
import {cs} from "@common/react/chain-services";
import {TextInput} from "../../../../../../../common/form/text-input/text-input";
import {ToggleWithLabel} from "../../../../../../../common/form/toggles/yes-no-toggle";
import "./outbound-api-step-result-mapping.scss";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {removeIndex, replaceIndex} from "@common/utils/collections";
import {omit} from "@common/utils/objects";
import {TrashIconBold} from "../../../../common/icons/trash-icon";
import {scope} from "@common/react/scope";
import {stateToSelect} from "@common/ui-components/form/state-to-select";
import {getPath} from "@common/utils/arr-path";
import {keyed} from "@common/react/keyed";
import {cx} from "emotion";

const transformLabels = (prefix) =>
    ["TrueIfHasValue", "TrueIfNotHasValue", "TrueIfEqualsValue", "TrueIfNotEqualsValue", "TrueIfValueInList", "TrueIfNotValueInList"].map((l) => ({
        value: l,
        label: replaceIndex(0, l.split(/(?=[A-Z])/), prefix).join(" "),
    }));

const placeholders = {
    TrueIfEqualsValue: "Value",
    TrueIfNotEqualsValue: "Value",
    TrueIfValueInList: "Values (comma separated)",
    TrueIfNotValueInList: "Values (comma separated)",
};

const hasValue = ["TrueIfEqualsValue", "TrueIfNotEqualsValue", "TrueIfValueInList", "TrueIfNotValueInList"];

export const rOutboundAPIStepResultMapping = ({field}) =>
    cs(() => {
        let state = scope(field, ["resultMapping"]);
        return (
            <div className="outbound-api-step-result-mapping-1qw">
                {state.value.map((_, i) =>
                    cs(keyed(i), () => (
                        <>
                            {rResultMappingItem({
                                field: scope(state, [i]),
                                onAdd: () => state.change((s) => [...s, {ID: new Date().getTime()}]),
                                onDelete: () => state.change((old) => (old.length === 1 ? [{ID: new Date().getTime()}] : removeIndex(i, old))),
                            })}
                        </>
                    ))
                )}

                <div className="retry-delay">
                    <div className="header">Retry And Delay</div>

                    <div className="flex-row">
                        {TextInput({
                            state: scope(field, ["maxRetries"]),
                            label: "Max Number of Retries",
                        })}

                        {TextInput({
                            state: scope(field, ["delaySeconds"]),
                            label: "Delay Between Retries (seconds)",
                        })}
                    </div>
                </div>
            </div>
        );
    });

const rResultMappingItem = ({field, onAdd, onDelete}) =>
    cs(() => {
        const InputRsFieldValue = (fieldPath, fieldValuePath) => {
            const fieldValue = field.value[fieldPath];
            const inList = ["TrueIfValueInList", "TrueIfNotValueInList"].includes(fieldValue);

            return (
                hasValue.includes(fieldValue) &&
                TextInput({
                    className: cx({"width-lg": inList}),
                    placeholder: placeholders[fieldValue] || "",
                    state: scope(field, [fieldValuePath]),
                    label: `Success Value${inList ? `s` : ``}`,
                })
            );
        };

        return (
            <div className="outbound-result-mapping">
                <div className="flex-row">
                    {TextInput({state: scope(field, ["name"]), label: "Name"})}

                    {TextInput({
                        state: scope(field, ["selector"]),
                        label: "Selector",
                    })}

                    {ToggleWithLabel({
                        label: "Required",
                        state: scope(field, ["required"]),
                    })}
                </div>

                <div className="flex-row">
                    {DropdownSelect({
                        label: "Success Condition",
                        placeholder: "Condition",
                        list: transformLabels("Success"),
                        valueToLabel: (v) => v.label,
                        ...stateToSelect(scope(field, ["successCondition"]), ["value"]),
                    })}

                    {InputRsFieldValue("successCondition", "successConditionValue")}
                </div>

                <div className="flex-row">
                    {DropdownSelect({
                        label: "Retry Condition",
                        placeholder: "Condition",
                        list: transformLabels("Retry"),
                        valueToLabel: (v) => v.label,
                        ...stateToSelect(scope(field, ["retryCondition"]), ["value"]),
                    })}

                    {InputRsFieldValue("retryCondition", "retryConditionValue")}

                    {/*{TextInput({state: scope(field, ["retryConditionValue"]), label: "Success Values"})}*/}
                </div>

                <div className="actions-col">
                    <div className="delete-btn" onClick={onDelete}>
                        {TrashIconBold()}
                    </div>
                    <div className="add-btn" onClick={onAdd}>
                        <span className="material-icons">add</span>
                    </div>
                </div>
            </div>
        );
    });
