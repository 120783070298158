import React from "react";
import {cs} from "@common/react/chain-services";
import "./select-columns-configuration.scss";
import {scope} from "@common/react/scope";
import {spc} from "@common/react/state-path-change";
import {SelectColumns} from "../../../../common/select-columns/select-columns";
import {GhostButton} from "../../../../../../../../../common/form/buttons/ghost-button/ghost-button";
import {PlusIcon} from "@common/ui-components/icons/global-icons";
import {TextInput} from "../../../../../../../../../common/form/text-input/text-input";

export const SelectColumnsConfiguration = ({transformation, step, form, model}) => {
    const prevStep = transformation.value.steps.find((s) => s.id === step.value.inputStepID);

    return (
        <div className="select-columns-configurations-42t">
            <div className="config-group">
                <div className="label">Select Columns to keep</div>

                {SelectColumns({
                    step,
                    form,
                    path: ["columns"],
                    columns: prevStep.outputColumns,
                    objectForm: {
                        columnProp: "column",
                        propertyInput: ({state, ...props}) =>
                            TextInput({
                                label: "Rename To (optional)",
                                state,
                                ...props,
                            }),
                        propertyPropInput: "renameTo",
                    },
                })}

                {step.value.columns.length < prevStep.outputColumns.length && (
                    <GhostButton
                        iconLeft={<PlusIcon />}
                        className="btn-add-all"
                        onClick={() =>
                            spc(step, ["columns"], () =>
                                prevStep.outputColumns.map((c) => ({
                                    column: c,
                                    renameTo: null,
                                }))
                            )
                        }
                    >
                        Add All Columns
                    </GhostButton>
                )}
            </div>
        </div>
    );
};
