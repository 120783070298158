import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import * as React from "react";
import {Slider} from "./slider";
import {FilterDataErrorHandler} from "../../../common/filter-data-error-handler/filter-data-error-handler";
import "./numeric-filter-slider.scss";
import {removeKey} from "@common/utils/objects";
import {CachingRequest} from "@common/react/load-with-cache-params";
import {Load2} from "@common/react/load2";

export const NumericFilterSlider = ({filter, form, providedFilterData, next, filterValues, apiKey}) => {
    let filtersKey = {...filterValues};
    const {from, to} = form.data.value?.range ?? {};
    const preview = from && to && `${from} - ${to}`;
    delete filtersKey[filter.id];
    return cs(
        consumeContext("apis"),
        consumeContext("collection"),
        consumeContext("theme"),
        [
            "filterData",
            ({apis, collection}, next) =>
                providedFilterData
                    ? next({value: providedFilterData})
                    : Load2({
                        _key: JSON.stringify({
                            apiKey,
                            filtersKey,
                        }),
                        next,
                        fetch: async () =>
                            apis.collection.getNumericFilterData({
                                apiKey,
                                collectionId: collection?.value?.id,
                                filterId: filter.id,
                                columns: filter.columns,
                                filterValues,
                            }),
                    }),
        ],
        ({filterData, theme}) =>
            next({
                mobileFilter: {
                    label: filter?.label,
                    shortPreview: () => preview,
                    preview: () => preview
                },
                control: ({size}) => {
                    if(filterData.error) {
                        return [FilterDataErrorHandler({error: filterData.error})];
                    }

                    return [
                        <div className="numeric-filter-slider-4td numeric-filter-slider">
                            {Slider({
                                range: form.field("range").state,
                                minValue: filterData.value?.min,
                                maxValue: filterData.value?.max,
                                theme,
                            })}
                        </div>,
                    ];
                },
            })
    );
};
