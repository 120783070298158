import {LiveDashboard} from "@common/ui-components/live/live-dashboard/live-dashboard";
import collection from "./color-schemes-collection.json";
import data from "./color-schemes-data.json";

const appliedColorToCollection = (selectedColorPalettes) => {
    if (selectedColorPalettes) {
        return {
            ...collection,
            gridLocations: collection.gridLocations.map((c) => {
                let tile = c.tile;
                if (tile.style.colorApplications) {
                    for (let item of Object.keys(tile.style.colorApplications)) {
                        tile.style.colorApplications[item] = {
                            index: selectedColorPalettes.index,
                            type: selectedColorPalettes.type,
                            $type: "SchemeColorApplication",
                        };
                    }
                }

                if (tile.style.barColorApplications) {
                    for (let item of Object.keys(tile.style.barColorApplications)) {
                        tile.style.barColorApplications[item] = {
                            index: selectedColorPalettes.index,
                            type: selectedColorPalettes.type,
                            $type: "SchemeColorApplication",
                        };
                    }
                }

                if (tile.style.colorApplication) {
                    tile.style.colorApplication = {
                        index: selectedColorPalettes.index,
                        type: selectedColorPalettes.type,
                        $type: "SchemeColorApplication",
                    };
                }
                return {...c, tile};
            }),
        };
    }
    return collection;
};

export const ColorSchemesThemeAuto = (props) => {
    return LiveDashboard({
        collection: appliedColorToCollection(props.theme.selectedColorPalettes),
        defaultData: data,
        disabledTileActions: true,
        ...props,
    });
};
