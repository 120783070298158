import {cs} from "@common/react/chain-services";
import {ChangeTypeDialog} from "./change-type-dialog/change-type-dialog";
import {BooleanIcon, DaterangeIcon, NumberIcon, TextUuidsIcon} from "../icons/filter-type-icons";
import {FiltersSelect} from "./filters-select/filters-select";

export const FilterIcons = [
    {
        icon: DaterangeIcon,
        label: "Date",
        type: "DateFilter",
    },
    {
        icon: TextUuidsIcon,
        label: "Text",
        type: "TextFilter",
    },
    {
        icon: NumberIcon,
        label: "Number",
        type: "NumericFilter",
    },
    {
        icon: BooleanIcon,
        label: "Boolean",
        type: "BooleanFilter",
        width: 21,
    },
];

export const getFilterIcon = (filter) => FilterIcons.find((item) => item.type === filter?.$type);

export const SelectFilterType = ({filter, onChange}) =>
    cs(["confirmDialog", (_, next) => ChangeTypeDialog({next})], ({confirmDialog}) =>
        FiltersSelect({
            list: FilterIcons,
            isSelected: (item) => item.type === filter?.$type,
            onChange: async (item) => {
                const allowChange = async () => {
                    if (filter?.$type == null) {
                        return true;
                    }
                    const answer = await confirmDialog.show();
                    return answer === "confirm";
                };

                if (await allowChange()) {
                    onChange(item.type);
                }
            },
        })
    );
