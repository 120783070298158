import {cGetFontSize} from "../../common/style-map/font-size";
import {getGridStepOption} from "../../factory/measurement-axes/grid-step";
import {getOrientation} from "../../common/style-map/orientation";
import {mapGridStyleToHC} from "../../factory/dimension-axis/dimension-axis";
import {findMinE, flatten1, last} from "../../../../utils/collections";
import {chain} from "../../../../utils/fs";

// used for both scatter plot and bubble chart
export const getScatterMeasurementAxesOptions = (
    {
        tile, theme, rawData, formatters,
        referenceSeries,
    }
) => {
    const getFontSize = cGetFontSize(theme.general.canvas.fontSize, theme);

    const getOptions = (axisProp) => {
        const style = tile.style[axisProp];
        const isXAxis = axisProp === "xAxis";
        const axisField = isXAxis ? tile.xAxisField : tile.yAxisField;

        const constantOrAggregateValueLines = referenceSeries
            ?.filter((s) =>
                ["ConstantValueReferenceLine", "AggregateValueReferenceLine"].includes(s.customType) &&
                s.customStack === axisField.id &&
                // when range min/max is set, only show lines having value within the range.
                (style.rangeCustomMin == null || s.plotLineOptions.value >= style.rangeCustomMin) &&
                (style.rangeCustomMax == null || s.plotLineOptions.value <= style.rangeCustomMax)
            );

        const range = getRange({rawData, isXAxis, style});

        return {
            ...getClassName({style, isXAxis}),
            ...getTitleOption({style, theme, getFontSize}),
            ...getAxisLinesOption({style, theme, isXAxis}),
            ...getGridStepOption({
                gridStepType: style.gridStepType,
                gridStepNumeric: style.gridStepNumeric,
            }),
            // this should go after the grid step options since it overrides
            ...range,
            ...getLabelsOption({
                style, theme, getFontSize, isXAxis,
                formatter: formatters.measurementFormatters[axisField.id],
            }),
            ...(style.logarithmic && {
                type: "logarithmic",
                // zero is not allowed on logarithmic type
                ...(range.min === 0 && {
                    min: null
                })
            }),
            ...(!isXAxis && style.axisDirection === "MaxToMin" && {
                reversed: true,
            }),

            ...(constantOrAggregateValueLines?.length && {
                plotLines: constantOrAggregateValueLines.map((s) => s.plotLineOptions),

                // add constant or aggregate values to y-Axis line
                tickPositioner: function(){
                    let ticks = this.tickPositions;
                    constantOrAggregateValueLines.forEach((l) => l.dataLabelShown && ticks.push(l.plotLineOptions.value));
                    ticks.sort((a, b) => a-b);
                    return ticks;
                }
            })
        }
    }

    return {
        "xAxis": getOptions("xAxis"),
        "yAxis": getOptions("yAxis"),
    }
};

const getClassName = ({style, isXAxis}) => {
    const position = style.location.toLowerCase().includes("left")
        ? (isXAxis ? "top" : "left")
        : (isXAxis ? "bottom" : "right")

    return {
        className: `verb-tile-axis verb-tile-axis-${position} verb-tile-axis-measure`,
    }
};

const getTitleOption = ({style, theme, getFontSize}) => {
    return {
        title: {
            text: style?.labelShown ? style.label : undefined,
            style: {
                fontSize: getFontSize({group: theme.dataVisualization.fonts.axisFontSize, elemType: "label"}),
                color: theme.dataVisualization.fonts.fontColorRGB || theme.general.canvas.fontColorRGB,
                fontWeight: theme.dataVisualization.fonts.fontWeight ?? null,
                fontFamily: `"${theme.dataVisualization.fonts.fontFamily || theme.general.canvas.fontFamily}", sans-serif`
            },
        },
    }
}

const getRange = ({rawData, isXAxis, style}) => {
    const {range, rangeCustomMin, rangeCustomMax, gridStepType, gridStepNumeric} = style;

    // let highcharts decide based on the data
    if (
        !range ||
        range === "AutoFromData" ||
        (range === "Custom" && (rangeCustomMin == null || rangeCustomMax == null))
    ) {
        return {}
    }

    // AutoFromData
    // const vIndex = isXAxis ? 0 : 1;
    //
    // const min = chain(
    //     rawData.series,
    //     (series) => flatten1(series.map((s) => s.data.map((d) => d[vIndex]))),
    //     (values) => findMinE(values)
    // )

    const getTickPositions = ({numberOfTicks, tickInterval, min, max}) => {
        let tickPositions = [];

        tickPositions.push(min);

        const interval = tickInterval || (Math.abs(max - min) / (numberOfTicks - 1));

        if (tickInterval) {
            for (;;) {
                const nextTick = last(tickPositions) + interval;
                if (
                    nextTick < max
                    // && max - nextTick >= interval // for the last added tick here not too close to the max tick
                ) {
                    tickPositions.push(nextTick);
                } else {
                    break;
                }
            }
        }

        if (numberOfTicks) {
            for (;;) {
                if (tickPositions.length <= numberOfTicks - 2) { // minus the 2 ticks: min and max
                    tickPositions.push(last(tickPositions) + interval);
                } else {
                    break;
                }
            }
        }

        tickPositions.push(max);

        return tickPositions;
    };

    if (range === "Custom") {
        return {
            min: rangeCustomMin,
            max: rangeCustomMax,
            tickPositioner: function() {
                // using tickAmount and letting Highcharts decide does not always show the custom min and max.
                if (gridStepType === "Count" && gridStepNumeric != null && gridStepNumeric > 1) {
                    return getTickPositions({
                        numberOfTicks: gridStepNumeric,
                        min: rangeCustomMin,
                        max: rangeCustomMax,
                    });
                }

                // using tickInterval and letting Highcharts decide does not show the custom min and max.
                if (gridStepType === "Size" && gridStepNumeric != null) {
                    return getTickPositions({
                        tickInterval: gridStepNumeric,
                        min: rangeCustomMin,
                        max: rangeCustomMax,
                    });
                }

                return this.tickPositions;
            },
        }
    }

    // AutoFrom0
    return {
        min: 0,
        tickPositioner: function() {
            // only do "Count" here.
            // for the "Size", let Highcharts handle it since the 0 is shown well.
            if (gridStepType === "Count" && gridStepNumeric != null && gridStepNumeric > 1) {
                return getTickPositions({
                    numberOfTicks: gridStepNumeric,
                    min: 0,
                    max: this.max,
                });
            }
            return this.tickPositions;
        },
        // endOnTick: true, // for the last tick to be shown
    };
};

const getLabelsOption = ({style, theme, getFontSize, isXAxis, formatter}) => {
    const orientation = style?.gridStepLabelOrientation;

    return {
        labels: {
            enabled: !!style?.gridStepLabelsShown,
            style: {
                fontSize: getFontSize({group: theme.dataVisualization.fonts.gridStepsFontSize}),
                color: theme.dataVisualization.fonts.fontColorRGB || theme.general.canvas.fontColorRGB,
                fontFamily: `"${theme.dataVisualization.fonts.fontFamily || theme.general.canvas.fontFamily}", sans-serif`
            },

            ...(orientation === "Auto" ? {
                autoRotation: isXAxis
                    ? [-45, -50, -55, -60, -65, -70, -75, -80, -85, -90]
                    : [0, -30, -45, -60, -90],
            } : {
                rotation: getOrientation(orientation),
            }),
            formatter: function() {
                return formatter(this.value);
            }
        }
    }
};

const getAxisLinesOption = ({style, theme, isXAxis}) => {
    const {axisCharts} = theme.dataVisualization;
    return {
        lineWidth: style.lineShown ? 1 : 0,
        lineColor: axisCharts.axisLineColorRGB,

        gridLineWidth: style?.gridLinesShown ? 1 : 0,
        gridLineColor: axisCharts.gridStepLineColorRGB,
        gridLineDashStyle: mapGridStyleToHC[axisCharts.gridStepLineStyle],

        opposite: isXAxis ? style.location === "LeftTop" : style.location === "RightBottom",

        tickWidth: 0,
        tickLength: 0,
    }
};
