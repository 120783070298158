export const paddingLeft = (num, length = 2) => {
    num = "" + (num || 0);

    for (; num.length < length; ) {
        num = "0" + num;
    }
    return num;
};

export const paddingRight = (num, length = 2) => {
    num = "" + (num || 0);

    for (; num.length < length; ) {
        num += "0";
    }
    return num;
};

export const reverse = function (str) {
    let ret = "";
    for (let i = str.length - 1; i > -1; i--) {
        ret += str[i];
    }
    return ret;
};

export const hashCode = function (str) {
    let hash = 0,
        i,
        chr;
    if (str.length === 0) return hash;
    for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash < 0 ? -hash : hash;
};

export const selectByHash = (col, feed) => {
    return col[hashCode(feed) % col.length];
};

// https://github.com/bryc/code/blob/master/jshash/experimental/cyrb53.js
export const cyrb53 = (str, seed = 0) => {
    let h1 = 0xdeadbeef ^ seed,
        h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str?.length; i++) {
        ch = str.charCodeAt(i);
        h1 = Math.imul(h1 ^ ch, 2654435761);
        h2 = Math.imul(h2 ^ ch, 1597334677);
    }

    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);

    return (4294967296 * (2097151 & h2) + (h1 >>> 0)).toString(16);
};

export const commonStart = (...strs) => {
    for (let i = 0; ; i++) {
        if (strs[0] == null ? i === 0 : i === strs[0].length) {
            return strs[0];
        }
        for (const str of strs) {
            if (str != null && str[i] !== strs[0][i]) {
                return strs[0].substring(0, i);
            }
        }
    }
};

export const upperCase1 = (str) => (!str ? "" : str[0].toUpperCase() + str.substring(1));

export const lowerCase1 = (str) => (!str ? "" : str[0].toLowerCase() + str.substring(1));

export const isNotBlank = (str) => str != null && typeof str === "string" && str.trim() !== "";

export const deepTrim = (str) => (!str ? "" : str.replace(/\s+/g, ""));

export const removeDuplicatedSpace = (str) => (!str ? "" : str.replace(/\s+/g, " "));

export const isBlank = (str) => !isNotBlank(str);

export const countHappens = (target, str) => {
    let index = 0;
    let count = 0;
    for (let i; (i = str.indexOf(target, index)) > -1; ) {
        count++;
        index = i + target.length;
    }
    return count;
};

export const createString = (str, count) => {
    let ret = "";
    for (let i = 0; i < count; i++) {
        ret += str;
    }
    return ret;
};

export const parseQueryString = (str) => {
    const splitStr = str.substring(str.indexOf("?") + 1).split("&");
    let params = {};

    for (let i = splitStr.length - 1; i >= 0; i--) {
        const index = splitStr[i].indexOf("=");
        const val = decodeURIComponent(splitStr[i].substring(index + 1) || "");
        params[splitStr[i].substring(0, index)] = val == "true" ? true : val == "false" ? false : val;
    }

    return params;
};

export const isHttpsUrl = (str = "") => {
    const pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$",
        "i"
    ); // fragment locator
    return !!pattern.test(str);
};

export const replaceCharacters = (str, obj) => {
    let retStr = str;
    for (let x in obj) {
        retStr = retStr.split(x).join(obj[x]);
    }
    return retStr;
};

export const capitalize = (w) => w[0].toUpperCase() + w.slice(1);

export const camelCaseToSpaces = (str) => str.replace(/([A-Z])/g, " $1").trim();

export const buildString = (arr) => (arr || []).filter((i) => i?.length > 0).join(" ");

export const buildQueryString = (params) => {
    let ret = "";

    for (const k in params) {
        let v = params[k];
        if (v == null) {
            continue;
        }
        if (ret.length > 0) {
            ret += "&";
        }
        ret += `${k}=${encodeURIComponent(v)}`;
    }
    return ret.length == 0 ? ret : "?" + ret;
};

// const normalizeText = (t = "") => t.toLowerCase().replace(/ /g, "");
export const normalizeText = (t = "") => t.toLowerCase();

export function isMatchText(source, text, returnRanges = false) {
    if (isBlank(text)) {
        return true;
    }

    if (!returnRanges) {
        return normalizeText(source).includes(normalizeText(text));
    }

    let ranges = [];
    let startIndex = 0;
    let index = null;
    let textLen = text.length;
    const _source = normalizeText(source);
    const _text = normalizeText(text);

    while ((index = _source.indexOf(_text, startIndex)) > -1) {
        startIndex = index + textLen;
        ranges.push([index, startIndex]);
    }

    return ranges.length > 0 ? {ranges} : null;
}
