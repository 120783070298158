import React from "react";

export const AddCalculatedColumnIcon = ({fill = "#546b81", x = 0, y = 0, style = {}}) => (
    <svg xmlns="http://www.w3.org/2000/svg" x={x} y={y} width="13.362" height="15" viewBox="0 0 13.362 15" style={style}>
        <g data-name="Calculated Column Icon" transform="translate(0.5)">
            <g data-name="Group 1109" transform="translate(1.5)">
                <g data-name="Group 1107">
                    <line data-name="Line 294" y2="3" transform="translate(0 12)" fill="none" stroke={fill} strokeWidth="1.2" />
                    <line data-name="Line 295" y2="3" transform="translate(2.69 12)" fill="none" stroke={fill} strokeWidth="1.2" />
                    <line data-name="Line 296" y2="3" transform="translate(5.381 12)" fill="none" stroke={fill} strokeWidth="1.2" />
                    <line data-name="Line 297" y2="15" transform="translate(8.071)" fill="none" stroke={fill} strokeWidth="1.2" />
                    <line data-name="Line 298" y2="15" transform="translate(10.762)" fill="none" stroke={fill} strokeWidth="1.2" />
                </g>
            </g>
            <path
                data-name="Path 470"
                d="M279.226,395.426h1v-3h3v-1h-3v-3h-1v3h-3v1h3Z"
                transform="translate(-276.226 -386.926)"
                fill={fill}
                stroke="rgba(0,0,0,0)"
                strokeWidth="1"
            />
        </g>
    </svg>
);

export const AddAggregatedMeasureIcon = ({fill = "#546b81", x = 0, y = 0, style = {}}) => {
    const objs = {
        a: {fill: "none", stroke: fill, strokeWidth: 1.2},
        b: {fill: fill},
    };
    return (
        <svg xmlns="http://www.w3.org/2000/svg" x={x} y={y} width="12.862" height="15" viewBox="0 0 12.862 15" style={style}>
            <g transform="translate(1.5)">
                <line {...objs["a"]} y2="3" transform="translate(0 12)" />
                <line {...objs["a"]} y2="3" transform="translate(2.69 12)" />
                <line {...objs["a"]} y2="3" transform="translate(5.381 12)" />
                <line {...objs["a"]} y2="15" transform="translate(8.071)" />
                <line {...objs["a"]} y2="15" transform="translate(10.762)" />
            </g>
            <g transform="translate(-2 -0.8)">
                <path
                    {...objs["b"]}
                    d="M6.147,2.432A.776.776,0,0,0,5.676,2.3a.407.407,0,0,0-.132.015,1.565,1.565,0,0,0-1.059,1c-.088.206-.176.426-.25.647-.044.1-.074.206-.118.309a.971.971,0,0,1-.074.221H3.515a.132.132,0,0,0,0,.265h.456l-.265,1.1c-.235,1.206-.574,2.6-.662,2.853s-.206.382-.368.382A.11.11,0,0,1,2.6,9.079a.076.076,0,0,1-.029-.059.256.256,0,0,1,.044-.1.284.284,0,0,0,.044-.147.274.274,0,0,0-.1-.235.3.3,0,0,0-.221-.088.361.361,0,0,0-.235.088.3.3,0,0,0-.1.25.466.466,0,0,0,.176.368.655.655,0,0,0,.456.147,1.087,1.087,0,0,0,.809-.382A2,2,0,0,0,3.75,8.4a9.841,9.841,0,0,0,.544-1.809c.147-.618.294-1.235.426-1.868h.515a.132.132,0,0,0,0-.265H4.779a9.422,9.422,0,0,1,.6-1.735c.1-.162.206-.25.309-.25a.124.124,0,0,1,.088.029.111.111,0,0,1,.015.059.383.383,0,0,1-.044.1.392.392,0,0,0-.044.176.267.267,0,0,0,.088.206.32.32,0,0,0,.441,0,.346.346,0,0,0,.088-.25A.368.368,0,0,0,6.147,2.432Z"
                />
                <path
                    {...objs["b"]}
                    d="M28.15,16.447a.58.58,0,0,0,.552-.639c0-.479-.349-.508-.45-.508q-.327,0-.61.479c-.189.334-.392.683-.392.683h0c-.044-.232-.087-.421-.1-.508a.815.815,0,0,0-.755-.639,1.948,1.948,0,0,0-.915.276h0a.285.285,0,0,0-.131.247.291.291,0,0,0,.29.29.424.424,0,0,0,.131-.029h0s.363-.2.436,0a.833.833,0,0,1,.058.2c.087.32.174.683.247,1.017l-.32.45a2.153,2.153,0,0,0-.537-.131.58.58,0,0,0-.552.639c0,.494.349.508.45.508q.327,0,.61-.479c.189-.334.392-.683.392-.683.058.29.116.537.145.639a.773.773,0,0,0,.77.537,1.9,1.9,0,0,0,.828-.247.3.3,0,0,0,.189-.276.291.291,0,0,0-.29-.29.424.424,0,0,0-.131.029h0s-.32.174-.421.044a2.271,2.271,0,0,1-.189-.581c-.044-.218-.1-.465-.145-.712l.32-.465A1.443,1.443,0,0,0,28.15,16.447Z"
                    transform="translate(-20 -10)"
                />
            </g>
        </svg>
    );
};

export const ColumnSecurityIcon = ({fill = "#546b81", x = 0, y = 0, style = {}}) => (
    <svg xmlns="http://www.w3.org/2000/svg" x={x} y={y} width="13.198" height="15" viewBox="0 0 13.198 15" style={style}>
        <g data-name="Column-Level Security Icon" transform="translate(-2115.664 -762.5)">
            <g data-name="Group 1109">
                <g data-name="Group 1107">
                    <line data-name="Line 294" y2="3" transform="translate(2117.5 774.5)" fill="none" stroke={fill} strokeWidth="1.2" />
                    <line data-name="Line 295" y2="3" transform="translate(2120.19 774.5)" fill="none" stroke={fill} strokeWidth="1.2" />
                    <line data-name="Line 296" y2="3" transform="translate(2122.881 774.5)" fill="none" stroke={fill} strokeWidth="1.2" />
                    <line data-name="Line 297" y2="15" transform="translate(2125.571 762.5)" fill="none" stroke={fill} strokeWidth="1.2" />
                    <line data-name="Line 298" y2="15" transform="translate(2128.262 762.5)" fill="none" stroke={fill} strokeWidth="1.2" />
                </g>
            </g>
            <path
                data-name="Icon material-security"
                d="M8.591,1.5,4.5,3.318V6.045A5.657,5.657,0,0,0,8.591,11.5a5.657,5.657,0,0,0,4.091-5.455V3.318Zm0,5h3.182a4.866,4.866,0,0,1-3.182,4.064V6.5H5.409V3.909L8.591,2.5Z"
                transform="translate(2111.164 761.461)"
                fill={fill}
            />
        </g>
    </svg>
);

export const HighlightShortestPathsIcon = ({fill = "#546b81", x = 0, y = 0, style = {}}) => (
    <svg xmlns="http://www.w3.org/2000/svg" x={x} y={y} width="16.448" height="14.848" viewBox="0 0 16.448 14.848" style={style}>
        <g data-name="Highlight Shortest Paths Icon" transform="translate(-687.152 -227.652)">
            <line data-name="Line 299" y1="14" transform="translate(695.5 228.5)" fill="none" stroke={fill} strokeWidth="1.2" />
            <path
                data-name="Path 465"
                d="M688,227.5h4v4"
                transform="translate(45.315 556.951) rotate(-45)"
                fill="none"
                stroke={fill}
                strokeWidth="1.2"
            />
            <path data-name="Path 466" d="M688,227.5h4v4" transform="translate(11 7)" fill="none" stroke={fill} strokeWidth="1.2" />
            <path
                data-name="Path 467"
                d="M688,227.5h4v4"
                transform="translate(1016.451 884.685) rotate(-135)"
                fill="none"
                stroke={fill}
                strokeWidth="1.2"
            />
            <path
                data-name="Path 468"
                d="M695.5,244.573c-.261-6.546-2.867-7.717-7.708-7.47"
                transform="translate(0 -2.573)"
                fill="none"
                stroke={fill}
                strokeWidth="1.2"
            />
            <path data-name="Path 469" d="M703,234.5c-5.253,5.253-7.5,5.371-7.5,7.805" fill="none" stroke={fill} strokeWidth="1.2" />
        </g>
    </svg>
);

export const SuggestRelationshipIcon = ({fill = "#546b81", x = 0, y = 0, style = {}}) => (
    <svg xmlns="http://www.w3.org/2000/svg" x={x} y={y} width="16" height="15.196" viewBox="0 0 16 15.196" style={style}>
        <g data-name="Suggested Relationships" transform="translate(-161.5 -67.5)">
            <path
                data-name="Icon awesome-lightbulb"
                d="M2.067,9.776a.689.689,0,0,0,.115.381c.5.745,2.714.745,3.21,0a.688.688,0,0,0,.115-.381V8.951H2.066v.825ZM0,3.787A3.766,3.766,0,0,0,.937,6.278,6.183,6.183,0,0,1,2.061,8.246l0,.017H5.511l0-.017A6.183,6.183,0,0,1,6.637,6.278,3.786,3.786,0,1,0,0,3.787ZM3.787,2.066A1.723,1.723,0,0,0,2.066,3.787a.344.344,0,1,1-.689,0,2.412,2.412,0,0,1,2.41-2.41.344.344,0,1,1,0,.689Z"
                transform="translate(165.7 71.981)"
                fill={fill}
            />
            <line data-name="Line 313" y2="3" transform="translate(169.5 67.5)" fill="none" stroke={fill} strokeWidth="1" />
            <line data-name="Line 314" y2="3" transform="translate(175.621 69.939) rotate(45)" fill="none" stroke={fill} strokeWidth="1" />
            <line data-name="Line 315" y1="3" transform="translate(165.621 72.061) rotate(135)" fill="none" stroke={fill} strokeWidth="1" />
            <line data-name="Line 316" y2="3" transform="translate(177.5 75) rotate(90)" fill="none" stroke={fill} strokeWidth="1" />
            <line data-name="Line 317" y1="3" transform="translate(164.5 75) rotate(90)" fill="none" stroke={fill} strokeWidth="1" />
        </g>
    </svg>
);

export const EditIcon = ({x = 0, y = 0, style = {}}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15.003" viewBox="0 0 16 15.003" style={style}>
        <g transform="translate(0 0.003)">
            <path className="a" fill="none" d="M0,0H15V15H0Z" />
            <path
                className="b"
                fill="#546b81"
                d="M3,14.873V18H6.125L15.34,8.782,12.215,5.658ZM17.756,6.366a.83.83,0,0,0,0-1.175l-1.95-1.95a.83.83,0,0,0-1.175,0L13.107,4.766l3.125,3.125,1.525-1.525Z"
                transform="translate(-2 -3)"
            />
        </g>
    </svg>
);

export const EditIcon2 = (props) => (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M4.276 10.6666L11.0373 3.90531L10.0947 2.96264L3.33333 9.72398V10.6666H4.276ZM4.82867 12H2V9.17131L9.62333 1.54797C9.74835 1.42299 9.91789 1.35278 10.0947 1.35278C10.2714 1.35278 10.441 1.42299 10.566 1.54797L12.452 3.43397C12.577 3.55899 12.6472 3.72853 12.6472 3.90531C12.6472 4.08208 12.577 4.25162 12.452 4.37664L4.82867 12V12ZM2 13.3333H14V14.6666H2V13.3333Z"
            fill="#919EB0"
        />
    </svg>
);

export const DeleteIcon = ({fill = "#546b81", x = 0, y = 0, style = {}}) => (
    <svg xmlns="http://www.w3.org/2000/svg" x={x} y={y} width="12.799" height="14.001" viewBox="0 0 12.799 14.001" style={style}>
        <g transform="translate(0.5 0.5)">
            <path
                data-name="Path 183"
                d="M266.126,391.825h-2.5v-.7a1.7,1.7,0,0,0-1.7-1.7h-2.4a1.7,1.7,0,0,0-1.7,1.7v.7h-2.5a.5.5,0,0,0,0,1h.7v7.9a1.7,1.7,0,0,0,1.7,1.7h6a1.7,1.7,0,0,0,1.7-1.7v-7.9h.7a.5.5,0,0,0,0-1Zm-7.3-.7a.7.7,0,0,1,.7-.7h2.4a.7.7,0,0,1,.7.7v.7h-3.8Zm5.6,9.6a.7.7,0,0,1-.7.7h-6a.7.7,0,0,1-.7-.7v-7.9h7.4Z"
                transform="translate(-254.827 -389.425)"
                fill={fill}
                stroke="rgba(0,0,0,0)"
                strokeWidth="1"
            />
            <path
                data-name="Path 184"
                d="M259.527,394.825a.5.5,0,0,0-.5.5v3.6a.5.5,0,0,0,1,0v-3.6A.5.5,0,0,0,259.527,394.825Z"
                transform="translate(-254.827 -389.425)"
                fill={fill}
                stroke="rgba(0,0,0,0)"
                strokeWidth="1"
            />
            <path
                data-name="Path 185"
                d="M261.927,394.825a.5.5,0,0,0-.5.5v3.6a.5.5,0,0,0,1,0v-3.6A.5.5,0,0,0,261.927,394.825Z"
                transform="translate(-254.827 -389.425)"
                fill={fill}
                stroke="rgba(0,0,0,0)"
                strokeWidth="1"
            />
        </g>
    </svg>
);

export const CreateRelationshipIcon = ({fill = "#546b81", x = 0, y = 0, style = {}}) => (
    <svg xmlns="http://www.w3.org/2000/svg" x={x} y={y} width="14.1" height="14.1" viewBox="0 0 14.1 14.1" style={style}>
        <g data-name="Create Relationship Icon" transform="translate(-590 -172.9)">
            <g data-name="Icon ionic-md-add-circle-outline" transform="translate(586.625 171.625)">
                <path
                    data-name="Path 452"
                    d="M16.65,14.169H14.169V16.65H12.957V14.169H10.477V12.957h2.481V10.477h1.212v2.481H16.65Z"
                    transform="translate(-4.188 -4.188)"
                    fill="#546b81"
                />
                <path
                    data-name="Path 453"
                    d="M9.375,4.587a4.786,4.786,0,1,1-3.387,1.4,4.769,4.769,0,0,1,3.387-1.4m0-1.212a6,6,0,1,0,6,6,6,6,0,0,0-6-6Z"
                    fill={fill}
                />
            </g>
            <path
                data-name="Path 455"
                d="M2017.072,963.243h5v5"
                transform="translate(-1418.571 -789.743)"
                fill="none"
                stroke={fill}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.2"
            />
            <line data-name="Line 288" x1="4" y2="4" transform="translate(599.5 173.5)" fill="none" stroke={fill} strokeWidth="1.2" />
        </g>
    </svg>
);

export const RowSecurityIcon = ({fill = "#546b81", x = 0, y = 0, style = {}}) => (
    <svg xmlns="http://www.w3.org/2000/svg" x={x} y={y} width="15.119" height="13.1" viewBox="0 0 15.119 13.1" style={style}>
        <g data-name="Row-Level Security Icon" transform="translate(-638.381 -130.9)">
            <path
                data-name="Icon material-lock-outline"
                d="M9.81,9.119a.952.952,0,1,0-.952-.952A.955.955,0,0,0,9.81,9.119Zm2.857-4.286H12.19V3.881a2.381,2.381,0,0,0-4.762,0v.952H6.952A.955.955,0,0,0,6,5.786v4.762a.955.955,0,0,0,.952.952h5.714a.955.955,0,0,0,.952-.952V5.786A.955.955,0,0,0,12.667,4.833ZM8.333,3.881a1.476,1.476,0,0,1,2.952,0v.952H8.333Zm4.333,6.667H6.952V5.786h5.714Z"
                transform="translate(632.381 132.5)"
                fill={fill}
            />
            <line data-name="Line 289" x2="15" transform="translate(638.5 131.5)" fill="none" stroke={fill} strokeWidth="1.2" />
            <line data-name="Line 291" x2="7" transform="translate(646.5 134.5)" fill="none" stroke={fill} strokeWidth="1.2" />
            <line data-name="Line 292" x2="5.887" transform="translate(647.613 137.5)" fill="none" stroke={fill} strokeWidth="1.2" />
            <line data-name="Line 293" x2="5.887" transform="translate(647.613 140.5)" fill="none" stroke={fill} strokeWidth="1.2" />
        </g>
    </svg>
);

export const PreviewDataIcon = ({x = 0, y = 0, style = {}}) => (
    <svg xmlns="http://www.w3.org/2000/svg" x={x} y={y} width="12.949" height="12.949" viewBox="0 0 12.949 12.949" style={style}>
        <g transform="translate(12.6 0.6) rotate(90)">
            <path
                className="a"
                fill="none"
                stroke="#546b81"
                strokeWidth="1.2px"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.367,6H4.193A1.193,1.193,0,0,0,3,7.193v8.349a1.193,1.193,0,0,0,1.193,1.193h8.349a1.193,1.193,0,0,0,1.193-1.193V11.367"
                transform="translate(-3 -4.735)"
            />
            <g className="b" stroke="#546b81" strokeWidth="1.2px" fill="none" transform="translate(4 8) rotate(-90)">
                <circle className="d" stroke="none" cx="3" cy="3" r="3" />
                <circle className="e" fill="none" cx="3" cy="3" r="2.4" />
            </g>
            <line
                className="c"
                stroke="#546b81"
                strokeWidth="1.2px"
                strokeLinecap="round"
                fill="none"
                x2="2.5"
                y2="2.5"
                transform="translate(9 3) rotate(-90)"
            />
            <line
                className="c"
                stroke="#546b81"
                strokeLinecap="round"
                strokeWidth="1.2px"
                fill="none"
                x2="6"
                transform="translate(2.5 9.5) rotate(-90)"
            />
        </g>
    </svg>
);

export const RemoveIcon = ({fill = "#546b81", x = 0, y = 0, style = {}}) => (
    <svg width="12.799" height="14.001" x={x} y={y} viewBox="0 0 12.799 14.001" style={style}>
        <g transform="translate(0.5 0.5)">
            <path
                data-name="Path 183"
                d="M266.126,391.825h-2.5v-.7a1.7,1.7,0,0,0-1.7-1.7h-2.4a1.7,1.7,0,0,0-1.7,1.7v.7h-2.5a.5.5,0,0,0,0,1h.7v7.9a1.7,1.7,0,0,0,1.7,1.7h6a1.7,1.7,0,0,0,1.7-1.7v-7.9h.7a.5.5,0,0,0,0-1Zm-7.3-.7a.7.7,0,0,1,.7-.7h2.4a.7.7,0,0,1,.7.7v.7h-3.8Zm5.6,9.6a.7.7,0,0,1-.7.7h-6a.7.7,0,0,1-.7-.7v-7.9h7.4Z"
                transform="translate(-254.827 -389.425)"
                fill={fill}
                stroke="rgba(0,0,0,0)"
                strokeWidth="1"
            />
            <path
                data-name="Path 184"
                d="M259.527,394.825a.5.5,0,0,0-.5.5v3.6a.5.5,0,0,0,1,0v-3.6A.5.5,0,0,0,259.527,394.825Z"
                transform="translate(-254.827 -389.425)"
                fill={fill}
                stroke="rgba(0,0,0,0)"
                strokeWidth="1"
            />
            <path
                data-name="Path 185"
                d="M261.927,394.825a.5.5,0,0,0-.5.5v3.6a.5.5,0,0,0,1,0v-3.6A.5.5,0,0,0,261.927,394.825Z"
                transform="translate(-254.827 -389.425)"
                fill={fill}
                stroke="rgba(0,0,0,0)"
                strokeWidth="1"
            />
        </g>
    </svg>
);
