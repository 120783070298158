import {arrEquals, isNotEmpty, sort} from "../../../../../../common/utils/collections";
import {equalDeep, isEmpty, pick} from "../../../../../../common/utils/objects";
import {isBlank} from "@common/utils/strings";
import {dataSourceTypes} from "../../common/data-source-type";

const {keepOnly} = require("../../../../../../common/utils/objects");

export const dataSourcesHelpers = {
    columns: {
        hasChanged: (oriColumns, updatedColumns) => {
            if (oriColumns.length !== updatedColumns.length) return true;
            //defaultAggregationFunc, displayColumnReferenceName
            const validationProps = ["defaultAggregationFunc", "displayColumnReferenceName", "dataTypeProperties"];

            for (let i = 0; i < oriColumns.length; i++) {
                const a1 = oriColumns[i];
                const a2 = updatedColumns[i];

                if (!equalDeep(pick(a1, validationProps), pick(a2, validationProps))) {
                    return true;
                }
            }
            return false;
        },
        valid: (data, selectedTable) => {
            const columns = data.tables.find((t) => t.id === selectedTable.id).columns;
            // const validationProps = ["defaultAggregationFunc", "displayColumnReferenceName", "dataTypeProperties"];
            for (let i = 0; i < columns.length; i++) {
                const column = columns[i];
                if (
                    isEmpty(column.defaultAggregationFunc) ||
                    isEmpty(column.displayColumnReferenceName) ||
                    isEmpty(column.dataTypeProperties)
                ) {
                    return false;
                }
            }
            return true;
        },
        getSelectedColumns: (data, selectedTable) => sort(data.tables.find((t) => t.id === selectedTable.id).columns, (c) => c.name),
        getSelectedDisabledColumns: (data, selectedTable) =>
            sort(data.tables.find((t) => t.id === selectedTable.id).disabledColumns, (c) => c.name),
    },
    tables: {
        getTablesWithoutChildren: (data) =>
            sort(
                data.tables.filter((t) => !t.isChildTable),
                (c) => c.name
            ),
        sortByName: (tables) => sort(tables, (c) => c.name),
        hasChanged: (oriTables, updatedTables) => {
            if (oriTables.length !== updatedTables.length) return true;
            //syncMethod, syncSchedule, columns
            const validationProps = ["syncMethod", "syncSchedule"];

            for (let i = 0; i < oriTables.length; i++) {
                const a1 = oriTables[i];
                const a2 = updatedTables[i];

                if (!equalDeep(keepOnly(a1, validationProps), keepOnly(a2, validationProps)) || !arrEquals(a1.columns, a2.columns)) {
                    return true;
                }
            }
            return false;
        },
        valid: (tables, type) => {
            if (tables?.length === 0 || !tables) return false;
            if (type === dataSourceTypes.FILE) return true;
            for (let i = 0; i < tables?.length || 0; i++) {
                const t = tables[i];
                if (isEmpty(t.syncSchedule)) return false;
                if (isBlank(t.syncMethod) || t.syncMethod === "None") return false;
            }
            return true;
        },
        getTablesChanged: (oriTables, updatedTables, fields) => {
            const _oriTables = dataSourcesHelpers.tables.sortByName(oriTables);
            const _updatedTables = dataSourcesHelpers.tables.sortByName(updatedTables);

            //syncMethod, syncSchedule, columns
            let ret = [];

            for (let i = 0; i < _oriTables.length; i++) {
                const a1 = _oriTables[i];
                const a2 = _updatedTables[i];

                for (let f = 0; f < fields.length; f++) {
                    if (!equalDeep(fields[f](a1), fields[f](a2)) && ret.filter((x) => x.ori.name === a1.name).length === 0) {
                        ret.push({
                            ori: a1,
                            updated: a2,
                        });
                        continue;
                    }
                }
            }
            return ret;
        },
        getInvalidTables: (tables) => {
            let ret = [];
            if (tables?.length === 0 || !tables) return ret;
            for (let i = 0; i < tables?.length || 0; i++) {
                const t = tables[i];
                if (isEmpty(t.syncSchedule) || isBlank(t.syncMethod) || t.syncMethod === "None") {
                    ret.push(t.name);
                }
            }
            return ret;
        },
        validColumns: (tables) => {
            if (tables?.length === 0 || !tables) return false;
            for (let i = 0; i < tables?.length || 0; i++) {
                const t = tables[i];
                if (!isNotEmpty(t.columns)) return false;
            }
            return true;
        },
    },
    filters: {
        getSelectedFilter: (data, selectedTable) => data.tables.find((t) => t.id === selectedTable.id).whereFilter,
        hasChanged: (oriFilter, updatedFilter) => {
            if (isBlank(oriFilter) && isBlank(updatedFilter)) return false;
            return !equalDeep(oriFilter, updatedFilter);
        },
    },
};

export const getSyncLatestTables = (latestTables) => {
    const getNewTables = (tables, existingTables) => {
        return tables.filter(
            (t) =>
                existingTables.findIndex(
                    (et) => et.schemaReferenceName === t.schemaReferenceName && et.tableReferenceName === t.tableReferenceName
                ) === -1
        );
    };

    const getNewColumns = (columns, existingColumns) => {
        return (columns || []).filter((c) => existingColumns.findIndex((ec) => ec.columnReferenceName === c.columnReferenceName) === -1);
    };

    const getSyncTables = (tables) => {
        const getSyncColumns = (columns, props, latestT) => {
            const latestColumnsByProps = !latestT
                ? []
                : props === "disabledColumns"
                ? [...latestT.columns, ...(latestT?.disabledColumns || [])]
                : latestT.columns || [];

            return columns.map((c) => {
                const latestC = latestColumnsByProps?.find((lc) => lc.columnReferenceName === c.columnReferenceName);
                return {
                    ...c,
                    indexed: latestC?.indexed ?? c.indexed,
                    primaryKey: latestC?.primaryKey ?? c.primaryKey,
                    dataType: latestC?.dataType ?? c.dataType,
                };
            });
        };

        return tables.map((t) => {
            const latestT = (latestTables || []).find(
                (lt) => lt.schemaReferenceName === t.schemaReferenceName && lt.tableReferenceName === t.tableReferenceName
            );

            return {
                ...t,
                syncErrorTable: latestT == null,
                approxSyncSizeMB: latestT?.approxSyncSizeMB || 0,
                records: latestT?.records || 0,
                ctAvailable: latestT?.ctAvailable || false,
                cdcAvailable: latestT?.cdcAvailable || false,
                columns: getSyncColumns(t.columns, "columns", latestT),
                disabledColumns: getSyncColumns(
                    [...(t?.disabledColumns || []), ...getNewColumns(latestT?.columns, [...t.columns, ...(t?.disabledColumns || [])])],
                    "disabledColumns",
                    latestT
                ),
            };
        });
    };

    return (data) => ({
        ...data,
        tables: getSyncTables(data.tables),
        disabledTables: getSyncTables(
            sort([...data.disabledTables, ...getNewTables(latestTables, [...data.tables, ...data.disabledTables])], (t) => t.name)
        ),
    });
};
