import React from "react";
import {cs} from "../../../../../react/chain-services";
import {UseState} from "../../../../../react/use-state";
import {keyed} from "@common/react/keyed";

export const SearchColumns = ({next, tile}) =>
    cs(
        keyed(tile.style.columnFilters),
        [
            "searchState",
            (_, next) =>
                UseState({
                    initValue: [],
                    next,
                }),
        ],
        ({searchState}) =>
            next({
                searchColumn: ({cIndex, search, searchType, $type}) => {
                    let updated = [...searchState.value];
                    let found = updated.find((c) => c.columnIndex == cIndex);
                    if (found) {
                        if (search === null && searchType !== "IsNull") {
                            updated = updated.filter((c) => c.columnIndex != cIndex);
                        } else {
                            found.search = search;
                            found.$type = $type;
                            if (searchType) {
                                found.searchType = searchType;
                            }
                        }
                    } else {
                        if (search !== null || searchType === "IsNull") {
                            updated.push({
                                columnIndex: cIndex,
                                search,
                                $type,
                                ...(searchType ? {searchType} : {}),
                            });
                        }
                    }

                    searchState.onChange(updated);
                },
                tableSearches: searchState.value,
            })
    );

export const TableFuncs = ({next, singleSort = false}) =>
    cs(
        [
            "tableFuncs",
            (_, next) =>
                UseState({
                    initValue: [],
                    next,
                }),
        ],
        ({tableFuncs}) =>
            next({
                sortColumn: (cIndex) => {
                    let updated = [...tableFuncs.value];
                    const columnHasSortIndex = updated.findIndex((c) => c.sortDirection);

                    if (singleSort && columnHasSortIndex >= 0 && cIndex != updated[columnHasSortIndex].columnIndex) {
                        if (updated[columnHasSortIndex]?.searchText) {
                            updated[columnHasSortIndex].sortDirection = null;
                        } else {
                            updated = updated.filter((_, i) => columnHasSortIndex != i);
                        }
                    }

                    let found = updated.find((c) => c.columnIndex == cIndex);

                    if (found) {
                        if (found?.sortDirection == "Desc") {
                            found.sortDirection = "Asc";
                        } else if (found.sortDirection === null) {
                            found.sortDirection = "Desc";
                        } else {
                            found.sortDirection = null;
                        }
                    } else {
                        updated.push({
                            columnIndex: cIndex,
                            sortDirection: "Desc",
                        });
                    }
                    tableFuncs.onChange(updated);
                },
                searchColumn: ({cIndex, searchText}) => {
                    let updated = [...tableFuncs.value];
                    let found = updated.find((c) => c.columnIndex == cIndex);
                    if (found) {
                        if (searchText == null || searchText.length == 0) {
                            // updated = updated.filter(c => c.columnIndex != cIndex);
                            found.searchText = null;
                        } else {
                            found.searchText = searchText;
                        }
                    } else {
                        updated.push({
                            columnIndex: cIndex,
                            searchText,
                        });
                    }

                    tableFuncs.onChange(updated);
                },
                tableSearches: tableFuncs.value,
            })
    );
