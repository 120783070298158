exports.lineChartTemplate = {
    $type: "LineChartTile",
    style: {
        $type: "LineChartTileStyle",
        displayType: "Spline",
        thickness: "Medium",
        marker: "None",
        xAxis: {
            $type: "LineChartDimensionAxisStyle",
            labelShown: false,
            // gridStepType: "Count",
            gridStepLabelsShown: true,
            gridStepLabelFontSize: "Small",
            gridStepLabelOrientation: "Auto",
            maxValuesInViewFixed: null,
            lineShown: true,
            gridLinesShown: false,
            maxValuesInView: "Auto",
        },
        dataLabels: {
            $type: "DataLabelsTileStyle",
            show: false,
            position: "OutsideAbove",
            fontSize: null,
            allowOverlap: false,
        },
        legendStyle: {
            legendShown: false,
            legendPosition: "Auto",
            legendTitleShown: false,
            legendTitle: "Legend",
        },
        title: {
            show: true,
            titleLocation: "Above",
        },
    },
};
