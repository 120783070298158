import {UseState} from "@common/react/use-state";
import React from "react";
import {cs} from "@common/react/chain-services";
import {DialogService} from "../../../../../../../../common/dialog/dialog-service";
import "./files-upload-dialog.scss";
import {consumeContext} from "@common/react/context";
import {Static2} from "@common/react/static-2";
import {Button} from "../../../../../../../../../../../common/form/buttons/button/button";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";

export const FilesUploadDialog = ({next: rootNext, services}) =>
    cs(
        consumeContext("apis"),
        ["loading", (_, next) => UseState({next, initValue: {isLoading: false, error: null}})],
        [
            "modal",
            ({}, next) =>
                DialogService({
                    strong: true,
                    render: ({resolve, args: {type, groupType, id = null}}) => ({
                        title: "File Upload",
                        content: next({resolve, type, groupType, id}),
                    }),
                    next: rootNext,
                }),
        ],
        ["fileSelector", (_, next) => FileSelector({next})],
        ({apis, fileSelector, modal, loading}) => {
            return (
                <div className="files-upload-dialog-8ew">
                    <VerbDialogBodyScrollbar>
                        <div className="text">
                            Files must include headers in the first row and we encourage you to name tabs clearly. <br />
                            <br />
                            You may upload a new version of this static file in the future by creating a new version on this data source configuration. <br />
                            <br />
                            The file must be no more than 100 MB in size.
                            {loading.value.error && (
                                <div className="error">
                                    <span className="i">
                                        <img src={require("../../Warning.svg")} alt="" />
                                    </span>
                                    <span className="t">{loading.value.error}</span>
                                </div>
                            )}
                        </div>
                    </VerbDialogBodyScrollbar>

                    <div className="buttons">
                        <Button btnType="secondary" className="btn-cancel" disabled={loading.value.isLoading} onClick={() => modal.resolve()}>
                            Cancel
                        </Button>
                        &nbsp;
                        <Button
                            disabled={loading.value.isLoading}
                            onClick={() =>
                                fileSelector.show({
                                    onSelected: async (e) => {
                                        loading.onChange({
                                            isLoading: true,
                                            error: null,
                                        });
                                        try {
                                            let resp = await apis.data.uploadFile(e.target.files[0], modal.id);

                                            loading.onChange({
                                                isLoading: false,
                                                error: null,
                                            });
                                            modal.resolve({});

                                            await services.getTablesColumns({
                                                dataSourceID: resp.dataSourceID,
                                                submit: resp.details,
                                                dataSource: {
                                                    id: resp.dataSourceID,
                                                    type: modal.type,
                                                    groupType: modal.groupType,
                                                    name: resp.details.originalFileName,
                                                    connectionDetails: {
                                                        ...resp.details,
                                                    },
                                                },
                                            });
                                        } catch (e) {
                                            let error = "There was an error uploading the file. Please try again or contact our support team.";
                                            if (e == "size") error = "The selected file is too large. Only files less than 100 MB are allowed.";
                                            else if (e == "type") error = "The selected file type is not allowed.";
                                            loading.onChange({
                                                isLoading: false,
                                                error: error,
                                            });
                                        }
                                    },
                                })
                            }
                        >
                            {loading.value.isLoading ? `Upload` : `Select File`} {loading.value.isLoading && <i className="fa fa-spinner fa-pulse" />}
                        </Button>
                    </div>
                </div>
            );
        }
    );

const FileSelector = ({next}) =>
    cs(["domRef", ({}, next) => Static2({next})], ["state", (_, next) => UseState({next})], ({domRef, state}) => (
        <>
            {next({
                show: ({onSelected}) => {
                    state.onChange({onSelected});
                    domRef.get()?.click();
                },
            })}

            <input type="file" style={{display: "none"}} accept="*" ref={domRef.set} onChange={state.value?.onSelected} />
        </>
    ));
