import React from "react";

export const CalculatorIcon = ({fill = "#546b81"}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="21.875" height="25" viewBox="0 0 21.875 25">
        <path
            fill={fill}
            d="M19.531,0H2.344A2.4,2.4,0,0,0,0,2.344V22.656A2.4,2.4,0,0,0,2.344,25H19.531a2.4,2.4,0,0,0,2.344-2.344V2.344A2.4,2.4,0,0,0,19.531,0ZM6.25,21.25a.672.672,0,0,1-.625.625H3.75a.672.672,0,0,1-.625-.625V19.375a.672.672,0,0,1,.625-.625H5.625a.672.672,0,0,1,.625.625Zm0-6.25a.672.672,0,0,1-.625.625H3.75A.672.672,0,0,1,3.125,15V13.125A.672.672,0,0,1,3.75,12.5H5.625a.672.672,0,0,1,.625.625Zm6.25,6.25a.672.672,0,0,1-.625.625H10a.672.672,0,0,1-.625-.625V19.375A.672.672,0,0,1,10,18.75h1.875a.672.672,0,0,1,.625.625Zm0-6.25a.672.672,0,0,1-.625.625H10A.672.672,0,0,1,9.375,15V13.125A.672.672,0,0,1,10,12.5h1.875a.672.672,0,0,1,.625.625Zm6.25,6.25a.672.672,0,0,1-.625.625H16.25a.672.672,0,0,1-.625-.625V13.125a.672.672,0,0,1,.625-.625h1.875a.672.672,0,0,1,.625.625V21.25Zm0-12.5a.672.672,0,0,1-.625.625H3.75a.672.672,0,0,1-.625-.625v-5a.672.672,0,0,1,.625-.625H18.125a.672.672,0,0,1,.625.625Z"
        />
    </svg>
);

export const AppendRowsIcon = ({fill = "#546b81"}) => {
    const objs = {
        a: {fill: fill},
        b: {fill: "#fff", stroke: "#fff"},
        c: {fill: "#fff", stroke: fill},
        d: {stroke: "none"},
        e: {fill: "none"},
    };

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="29.501" height="26.477" viewBox="0 0 29.501 26.477">
            <g id="Append_Rows_Icon" data-name="Append Rows Icon" transform="translate(-387.499 -215.26)">
                <path
                    d="M9,22H0V0H9V22ZM1.5,15.232v5.076h6V15.232Zm0-6.769v5.076h6V8.463Zm0-6.769V6.769h6V1.694Z"
                    transform="translate(387.499 232) rotate(-90)"
                    {...objs["a"]}
                />
                <path
                    d="M-3300.5-2361.262h-5a1,1,0,0,1-1-1v-.74H-3324v-9h22v3.74h1.5a1,1,0,0,1,1,1v5A1,1,0,0,1-3300.5-2361.262Zm-3-3v1h1v-1h1v-1h-1v-1h-1v1h-1v1Zm-5.269-6.24v6h2.27v-2.761a1,1,0,0,1,1-1h1.806v-2.241Zm-6.769,0v6h5.076v-6Zm-6.769,0v6h5.075v-6Z"
                    transform="translate(3716.499 2603)"
                    {...objs["a"]}
                />
                <path
                    d="M9,22H0V0H9V22ZM1.5,15.232v5.076h6V15.232Zm0-6.769v5.076h6V8.463Zm0-6.769V6.769h6V1.694Z"
                    transform="translate(387.499 224.26) rotate(-90)"
                    {...objs["a"]}
                />
            </g>
        </svg>
    );
};

export const RemoveDuplicatesIcon = ({fill = "#546b81"}) => {
    const objs = {
        a: {fill: fill},
        b: {fill, stroke: fill},
    };

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="35.501" height="23" viewBox="0 0 35.501 23">
            <g transform="translate(-385.499 -223)">
                <path
                    className="a"
                    d="M6804,2615h-10v-26h10v26Zm-8.333-8v6h6.667v-6Zm0-8v6h6.667v-6Zm0-8v6h6.667v-6Z"
                    transform="translate(-2203.5 7027) rotate(-90)"
                    {...objs["a"]}
                />
                <g>
                    <path
                        className="a"
                        d="M-3463-2217h-26v-10h26v10Zm-8-8.333v6.666h6v-6.666Zm-8,0v6.666h6v-6.666Zm-8,0v6.666h6v-6.666Z"
                        transform="translate(3882.5 2459)"
                        {...objs["a"]}
                    />
                </g>
                <g>
                    <path
                        className="b"
                        d="M-3243-2220.5h-5a.5.5,0,0,1-.5-.5v-5a.5.5,0,0,1,.5-.5h5a.5.5,0,0,1,.5.5v5A.5.5,0,0,1-3243-2220.5Zm-4.5-4v2h4v-2Z"
                        transform="translate(3663 2466)"
                        {...objs["b"]}
                    />
                </g>
            </g>
        </svg>
    );
};

export const ChangeColumnTypeIcon = ({fill = "#546b81"}) => {
    const objs = {
        a: {fill: "none", stroke: fill},
        b: {fill},
        c: {fill: "#fff"},
        d: {stroke: "none"},
        e: {fill: "none"},
    };

    {
        /*<style>.a,.c{fill:#fff;}.a{stroke:#546b81;}.b{fill:#546b81;}.d{stroke:none;}.e{fill:none;}</style>*/
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="20" viewBox="0 0 40 20">
            <g transform="translate(-387 -224)">
                <g className="a" transform="translate(387 224)" {...objs["a"]} strokeWidth="1">
                    <rect className="d" width="40" height="20" rx="2" {...objs["d"]} />
                    <rect className="e" x="0.5" y="0.5" width="39" height="19" rx="1.5" {...objs["e"]} />
                </g>
                <path
                    className="b"
                    d="M-3231-2278h-18v-20h18a2,2,0,0,1,2,2v16A2,2,0,0,1-3231-2278Zm-10.165-6.572h2.643l-.392,2.194a.32.32,0,0,0,.07.262.321.321,0,0,0,.247.115h1.088a.321.321,0,0,0,.316-.266l.412-2.307h2.119a.322.322,0,0,0,.317-.265l.191-1.071a.321.321,0,0,0-.07-.263.321.321,0,0,0-.247-.115h-2l.612-3.429h2.119a.322.322,0,0,0,.317-.265l.192-1.072a.321.321,0,0,0-.07-.263.321.321,0,0,0-.247-.115h-2l.393-2.193a.321.321,0,0,0-.07-.263.321.321,0,0,0-.247-.115h-1.088a.322.322,0,0,0-.317.265l-.411,2.307h-2.642l.391-2.193a.321.321,0,0,0-.07-.263.32.32,0,0,0-.246-.115h-1.089a.32.32,0,0,0-.316.265l-.412,2.307h-2.118a.32.32,0,0,0-.316.266l-.192,1.071a.321.321,0,0,0,.07.263.321.321,0,0,0,.247.115h2l-.612,3.429h-2.118a.322.322,0,0,0-.317.265l-.191,1.071a.32.32,0,0,0,.07.263.32.32,0,0,0,.246.115h2l-.392,2.194a.319.319,0,0,0,.07.262.321.321,0,0,0,.247.116h1.088a.322.322,0,0,0,.317-.266l.411-2.306Zm2.948-1.714h-2.641l.612-3.429h2.643l-.613,3.428Z"
                    transform="translate(3656 2522)"
                    {...objs["b"]}
                />
                <path
                    className="b"
                    d="M7.383,9.656V2.344A1.408,1.408,0,0,1,8.789.938h.469V0H8.789A2.342,2.342,0,0,0,6.914.939,2.342,2.342,0,0,0,5.039,0H4.57V.938h.469A1.408,1.408,0,0,1,6.445,2.344v.491H1.406A1.408,1.408,0,0,0,0,4.241v3.75A1.408,1.408,0,0,0,1.406,9.4H6.445v.259a1.408,1.408,0,0,1-1.406,1.406H4.57V12h.469a2.342,2.342,0,0,0,1.875-.939A2.342,2.342,0,0,0,8.789,12h.469v-.937H8.789A1.408,1.408,0,0,1,7.383,9.656ZM1.406,8.46a.469.469,0,0,1-.469-.469V4.241a.469.469,0,0,1,.469-.469H6.445V8.46ZM12,4.241v3.75A1.408,1.408,0,0,1,10.594,9.4H8.32V8.46h2.273a.469.469,0,0,0,.469-.469V4.241a.469.469,0,0,0-.469-.469H8.32V2.835h2.273A1.408,1.408,0,0,1,12,4.241Z"
                    transform="translate(391 228)"
                    {...objs["b"]}
                />
            </g>
        </svg>
    );
};

export const JoinDataIcon = ({fill = "#546b81"}) => {
    const objs = {
        a: {fill: "#fff"},
        b: {fill: "none"},
        c: {stroke: "none", fill: fill},
    };

    // .a{fill:#fff;}.b{fill:none},.c{stroke:none;}.c{fill:#546b81;}

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="17.999" viewBox="0 0 28 17.999">
            <defs>
                <style></style>
            </defs>
            <g className="a" transform="translate(-6562 923)" {...objs["a"]}>
                <path
                    className="b"
                    {...objs["b"]}
                    d="M 6581 -906.0006713867188 C 6579.40869140625 -906.0006713867188 6577.8720703125 -906.4662475585938 6576.5556640625 -907.3471069335938 L 6575.99951171875 -907.7192993164063 L 6575.443359375 -907.3469848632813 C 6574.12744140625 -906.4662475585938 6572.59130859375 -906.0006713867188 6571.00048828125 -906.0006713867188 C 6566.58935546875 -906.0006713867188 6563.00048828125 -909.5888671875 6563.00048828125 -913.9993896484375 C 6563.00048828125 -918.4108276367188 6566.58935546875 -921.999755859375 6571.00048828125 -921.999755859375 C 6572.59130859375 -921.999755859375 6574.12744140625 -921.5341796875 6575.443359375 -920.6533813476563 L 6575.99951171875 -920.2810668945313 L 6576.5556640625 -920.6533203125 C 6577.8720703125 -921.5341796875 6579.40869140625 -921.999755859375 6581 -921.999755859375 C 6585.41162109375 -921.999755859375 6589.00048828125 -918.4108276367188 6589.00048828125 -913.9993896484375 C 6589.00048828125 -909.5888671875 6585.41162109375 -906.0006713867188 6581 -906.0006713867188 Z"
                />
                <path
                    className="c"
                    {...objs["c"]}
                    d="M 6581 -907.0006713867188 C 6584.85986328125 -907.0006713867188 6588.00048828125 -910.1402587890625 6588.00048828125 -913.9993896484375 C 6588.00048828125 -917.859375 6584.85986328125 -920.999755859375 6581 -920.999755859375 C 6579.607421875 -920.999755859375 6578.26318359375 -920.5925903320313 6577.11181640625 -919.8222045898438 L 6575.99951171875 -919.0776977539063 L 6574.88671875 -919.8223876953125 C 6573.736328125 -920.5926513671875 6572.392578125 -920.999755859375 6571.00048828125 -920.999755859375 C 6567.140625 -920.999755859375 6564.00048828125 -917.859375 6564.00048828125 -913.9993896484375 C 6564.00048828125 -910.1402587890625 6567.140625 -907.0006713867188 6571.00048828125 -907.0006713867188 C 6572.392578125 -907.0006713867188 6573.736328125 -907.4077758789063 6574.88671875 -908.177978515625 L 6575.99951171875 -908.922607421875 L 6577.11181640625 -908.1781616210938 C 6578.26318359375 -907.4078369140625 6579.607421875 -907.0006713867188 6581 -907.0006713867188 M 6581 -905.0006713867188 C 6579.14990234375 -905.0006713867188 6577.4296875 -905.5589599609375 6575.99951171875 -906.5159912109375 C 6574.56982421875 -905.5589599609375 6572.8505859375 -905.0006713867188 6571.00048828125 -905.0006713867188 C 6566.029296875 -905.0006713867188 6562.00048828125 -909.0294799804688 6562.00048828125 -913.9993896484375 C 6562.00048828125 -918.970947265625 6566.029296875 -922.999755859375 6571.00048828125 -922.999755859375 C 6572.8505859375 -922.999755859375 6574.56982421875 -922.4414672851563 6575.99951171875 -921.484375 C 6577.4296875 -922.4414672851563 6579.14990234375 -922.999755859375 6581 -922.999755859375 C 6585.9697265625 -922.999755859375 6590.00048828125 -918.970947265625 6590.00048828125 -913.9993896484375 C 6590.00048828125 -909.0294799804688 6585.9697265625 -905.0006713867188 6581 -905.0006713867188 Z"
                />
            </g>
            <g id="Intersection_3" data-name="Intersection 3" transform="translate(-6562 923)" fill="none" opacity="0.25">
                <path
                    d="M6572-914a8.991,8.991,0,0,1,4-7.485,8.991,8.991,0,0,1,4,7.485,8.989,8.989,0,0,1-4,7.483A8.989,8.989,0,0,1,6572-914Z"
                    stroke="none"
                />
                <path
                    d="M 6575.99951171875 -909.1004028320312 C 6577.2705078125 -910.396240234375 6577.99951171875 -912.1461791992188 6577.99951171875 -913.9993896484375 C 6577.99951171875 -915.8531494140625 6577.27001953125 -917.6036987304688 6575.99951171875 -918.8997802734375 C 6574.72900390625 -917.6036987304688 6573.99951171875 -915.8531494140625 6573.99951171875 -913.9993896484375 C 6573.99951171875 -912.1461791992188 6574.728515625 -910.396240234375 6575.99951171875 -909.1004028320312 M 6575.99951171875 -906.5159912109375 C 6573.58740234375 -908.1302490234375 6571.99951171875 -910.8792724609375 6571.99951171875 -913.9993896484375 C 6571.99951171875 -917.1205444335938 6573.58740234375 -919.8699340820312 6575.99951171875 -921.484375 C 6578.41162109375 -919.8699340820312 6579.99951171875 -917.1205444335938 6579.99951171875 -913.9993896484375 C 6579.99951171875 -910.8792724609375 6578.41162109375 -908.1302490234375 6575.99951171875 -906.5159912109375 Z"
                    stroke="none"
                    fill="#546b81"
                />
            </g>
        </svg>
    );
};

export const PivotDataIcon = ({fill = "#546b81"}) => {
    const objs = {
        a: {fill: fill},
        b: {fill: "none", stroke: fill},
        c: {stroke: "none"},
        d: {fill: "none"},
    };

    // .a{fill:#fff;}.b{fill:none},.c{stroke:none;}.c{fill:#546b81;}

    return (
        <svg
            id="Pivot_Data_Icon"
            data-name="Pivot Data Icon"
            xmlns="http://www.w3.org/2000/svg"
            width="25.001"
            height="25.001"
            viewBox="0 0 25.001 25.001"
        >
            <path className="a" d="M25,25H0V0H25V25ZM1,3V24H24V3Z" {...objs["a"]} />
            <g transform="translate(1)">
                <path
                    className="a"
                    d="M6,3l-.529-.529-2.1,2.092V-3.388h-.75V4.564L.532,2.467,0,3,3,6Z"
                    transform="translate(16 16.001) rotate(90)"
                    {...objs["a"]}
                />
                <path
                    className="a"
                    d="M6,3l-.529-.529-2.1,2.092V-3.388h-.75V4.564L.532,2.467,0,3,3,6Z"
                    transform="translate(22 16.001) rotate(180)"
                    {...objs["a"]}
                />
                <g className="b" transform="translate(2 4.001)" {...objs["b"]} strokeWidth="1">
                    <rect className="c" width="4" height="4" {...objs["c"]} />
                    <rect className="d" x="0.5" y="0.5" width="3" height="3" {...objs["d"]} />
                </g>
                <g className="b" transform="translate(7 4.001)" {...objs["b"]} strokeWidth="1">
                    <rect className="c" width="15" height="4" {...objs["c"]} />
                    <rect className="d" x="0.5" y="0.5" width="14" height="3" {...objs["d"]} />
                </g>
                <g className="b" transform="translate(6 9.001) rotate(90)" {...objs["b"]} strokeWidth="1">
                    <rect className="c" width="13" height="4" {...objs["c"]} />
                    <rect className="d" x="0.5" y="0.5" width="12" height="3" {...objs["d"]} />
                </g>
            </g>
        </svg>
    );
};

export const UnPivotDataIcon = ({fill = "#546b81"}) => {
    const objs = {
        a: {fill: fill},
        b: {fill: "none", stroke: fill},
        c: {stroke: "none"},
        d: {fill: "none"},
    };

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25.001" height="25.001" viewBox="0 0 25.001 25.001">
            <path className="a" d="M25,25H0V0H25V25ZM1,3V24H24V3Z" {...objs["a"]} />
            <g transform="translate(1)">
                <path
                    className="a"
                    d="M5,1.935l-.441-.441L2.812,3.239V-3.388H2.187V3.239L.444,1.492,0,1.935l2.5,2.5Z"
                    transform="translate(12.827 21.541) rotate(-90)"
                    {...objs["a"]}
                />
                <path
                    className="a"
                    d="M5,1.935l-.441-.441L2.812,3.239V-3.388H2.187V3.239L.444,1.492,0,1.935l2.5,2.5Z"
                    transform="translate(17 13.388)"
                    {...objs["a"]}
                />
                <g className="b" transform="translate(2 4.001)" {...objs["b"]}>
                    <rect className="c" width="4" height="4" {...objs["c"]} />
                    <rect className="d" x="0.5" y="0.5" width="3" height="3" {...objs["d"]} />
                </g>
                <g className="b" transform="translate(7 4.001)" {...objs["b"]}>
                    <rect className="c" width="15" height="4" {...objs["c"]} />
                    <rect className="d" x="0.5" y="0.5" width="14" height="3" {...objs["d"]} />
                </g>
                <g className="b" transform="translate(6 9.001) rotate(90)" {...objs["b"]}>
                    <rect className="c" width="13" height="4" {...objs["c"]} />
                    <rect className="d" x="0.5" y="0.5" width="12" height="3" {...objs["d"]} />
                </g>
            </g>
        </svg>
    );
};

export const RankDataIcon = ({fill = "#546b81"}) => {
    const objs = {
        a: {stroke: fill, fill: "none"},
        b: {fill: fill},
        c: {stroke: "none"},
        d: {fill: "none"},
    };

    {
        /*<defs>*/
    }
    {
        /*    <style>.a{fill:#fff;stroke:#546b81;}.b{fill:#546b81;}.c{stroke:none;}.d{fill:none;}</style>*/
    }
    {
        /*</defs>*/
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
            <g transform="translate(-393 -217)">
                <g className="a" transform="translate(393 217)" {...objs["a"]}>
                    <rect className="c" width="25" height="25" rx="1" {...objs["c"]} />
                    <rect className="d" x="0.5" y="0.5" width="24" height="24" rx="0.5" {...objs["d"]} />
                </g>
                <rect className="b" width="4" height="19" transform="translate(397 221)" {...objs["b"]} />
                <rect className="b" width="4" height="15" transform="translate(404 225)" {...objs["b"]} />
                <rect className="b" width="4" height="10" transform="translate(411 230)" {...objs["b"]} />
            </g>
        </svg>
    );
};

export const WindowDataIcon = ({fill = "#546b81"}) => {
    const objs = {
        a: {
            fill: fill,
            fontSize: 5,
            fontFamily: "SegoeUISymbol, Segoe UI Symbol",
        },
        b: {fill: fill},
        // c : {stroke: "none"},
        // d : {fill: "none"},
    };

    {
        /*<defs>*/
    }
    {
        /*    <style>.a{fill:#fff;stroke:#546b81;}.b{fill:#546b81;}.c{stroke:none;}.d{fill:none;}</style>*/
    }
    {
        /*</defs>*/
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="28.429" height="24" viewBox="0 0 28.429 24">
            {/*<defs>*/}
            {/*    <style>.a,.b{fill:#546b81;}.a{font - size:5px;font-family:SegoeUISymbol, Segoe UI Symbol;}</style>*/}
            {/*</defs>*/}
            <g transform="translate(-388.5 -223)">
                <g transform="translate(-2 -2)">
                    <g transform="translate(-3 -11)">
                        <text className="a" transform="translate(416 243)" {...objs["a"]}>
                            <tspan x="0" y="0">
                                𝑓
                            </tspan>
                        </text>
                        <text className="a" transform="translate(416 250)" {...objs["a"]}>
                            <tspan x="0" y="0">
                                𝑓
                            </tspan>
                        </text>
                        <text className="a" transform="translate(416 257)" {...objs["a"]}>
                            <tspan x="0" y="0">
                                𝑓
                            </tspan>
                        </text>
                    </g>
                    <path
                        className="b"
                        {...objs["b"]}
                        d="M6803.429,2611H6794v-24h9.429v24Zm-7.858-6.857v5.143h6.287v-5.143Zm0-6.857v5.143h6.287v-5.143Zm0-6.856v5.142h6.287v-5.142Z"
                        transform="translate(-6384.5 -2362)"
                    />
                </g>
                <g transform="translate(2)">
                    <path
                        className="b"
                        {...objs["b"]}
                        d="M6803.429,2609H6794v-22h9.429v22Zm-7.858-6.286v4.715h6.287v-4.715Zm0-6.286v4.715h6.287v-4.715Zm0-6.285v4.713h6.287v-4.713Z"
                        transform="translate(-6407.5 -2363)"
                    />
                    <path
                        className="b"
                        {...objs["b"]}
                        d="M6803.429,2609H6794v-22h9.429v22Zm-7.858-6.286v4.715h6.287v-4.715Zm0-6.286v4.715h6.287v-4.715Zm0-6.285v4.713h6.287v-4.713Z"
                        transform="translate(-6399.5 -2363)"
                    />
                </g>
            </g>
        </svg>
    );
};

export const DateOperationIcon = ({fill}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g id="Group_698" data-name="Group 698" transform="translate(4708 2288)">
            <g id="calendar_today-24px" transform="translate(-4708 -2288)">
                <path id="Path_391" data-name="Path 391" d="M0,0H24V24H0Z" fill="none" />
                <path
                    id="Path_392"
                    data-name="Path 392"
                    d="M20,3H19V1H17V3H7V1H5V3H4A2.006,2.006,0,0,0,2,5V21a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V5A2.006,2.006,0,0,0,20,3Zm0,18H4V8H20Z"
                    fill={fill}
                />
            </g>
            <g id="edit-24px" transform="translate(-4702 -2282)">
                <path
                    id="Path_258"
                    data-name="Path 258"
                    d="M3,9.331V11H4.666L9.581,6.083,7.915,4.416Zm7.87-4.537a.443.443,0,0,0,0-.627L9.83,3.127a.443.443,0,0,0-.627,0l-.813.813,1.666,1.666Z"
                    transform="translate(2 3)"
                    fill={fill}
                />
            </g>
        </g>
    </svg>
);

export const TextManipulationIcon = ({fill = "#546b81"}) => (
    <svg id="Component_77_1" data-name="Component 77 – 1" xmlns="http://www.w3.org/2000/svg" width="31" height="25" viewBox="0 0 31 25">
        <path
            id="Subtraction_33"
            data-name="Subtraction 33"
            d="M6804.715,2612H6794v-25h10.715v25Zm-8.93-7.143v5.357h7.144v-5.357Zm0-7.144v5.358h7.144v-5.358Zm0-7.142v5.356h7.144v-5.356Z"
            transform="translate(-6784 -2587)"
            fill={fill}
        />
        <g id="Group_702" data-name="Group 702" transform="translate(-389 -220)">
            <g id="Rectangle_1637" data-name="Rectangle 1637" transform="translate(411 230)" fill="none" stroke={fill} strokeWidth="1">
                <rect width="9" height="7" stroke="none" />
                <rect x="0.5" y="0.5" width="8" height="6" fill="none" />
            </g>
            <g id="Rectangle_1638" data-name="Rectangle 1638" transform="translate(413 233)" fill={fill} stroke={fill} strokeWidth="1">
                <rect width="5" height="1" stroke="none" />
                <rect x="0.5" y="0.5" width="4" fill="none" />
            </g>
        </g>
        <g id="Group_703" data-name="Group 703" transform="translate(-411 -220)">
            <g id="Rectangle_1637-2" data-name="Rectangle 1637" transform="translate(411 230)" fill="none" stroke={fill} strokeWidth="1">
                <rect width="9" height="7" stroke="none" />
                <rect x="0.5" y="0.5" width="8" height="6" fill="none" />
            </g>
            <g id="Rectangle_1638-2" data-name="Rectangle 1638" transform="translate(413.5 233)" fill={fill} stroke={fill} strokeWidth="1">
                <rect width="4" height="1" stroke="none" />
                <rect x="0.5" y="0.5" width="3" fill="none" />
            </g>
            <g
                id="Rectangle_1639"
                data-name="Rectangle 1639"
                transform="translate(416 231.5) rotate(90)"
                fill={fill}
                stroke={fill}
                strokeWidth="1"
            >
                <rect width="4" height="1" stroke="none" />
                <rect x="0.5" y="0.5" width="3" fill="none" />
            </g>
        </g>
    </svg>
);

export const MapValuesIcon = ({fill = "#546b81"}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="21" viewBox="0 0 30 21">
        <path
            className="a"
            d="M13.515,21H3v3H13.515v4.5l5.985-6-5.985-6V21Zm8.97-1.5V15H33V12H22.485V7.5l-5.985,6Z"
            fill={fill}
            transform="translate(-3 -7.5)"
        />
    </svg>
);

export const GroupDataIcon = ({fill = "#546b81"}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="21.875" viewBox="0 0 25 21.875">
        <path
            className="a"
            fill={fill}
            d="M23.438,6.938V5.375h.977A.586.586,0,0,0,25,4.789V2.836a.586.586,0,0,0-.586-.586H22.461a.586.586,0,0,0-.586.586v.977H3.125V2.836a.586.586,0,0,0-.586-.586H.586A.586.586,0,0,0,0,2.836V4.789a.586.586,0,0,0,.586.586h.977V21H.586A.586.586,0,0,0,0,21.586v1.953a.586.586,0,0,0,.586.586H2.539a.586.586,0,0,0,.586-.586v-.977h18.75v.977a.586.586,0,0,0,.586.586h1.953A.586.586,0,0,0,25,23.539V21.586A.586.586,0,0,0,24.414,21h-.977ZM4.688,14.164V7.523a.586.586,0,0,1,.586-.586h8.2a.586.586,0,0,1,.586.586v6.641a.586.586,0,0,1-.586.586h-8.2A.586.586,0,0,1,4.688,14.164Zm15.625,4.688a.586.586,0,0,1-.586.586h-8.2a.586.586,0,0,1-.586-.586V16.313h3.516a1.172,1.172,0,0,0,1.172-1.172V11.625h4.1a.586.586,0,0,1,.586.586Z"
            transform="translate(0 -2.25)"
        />
    </svg>
);

export const FilterDataIcon = ({fill = "#546b81"}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="24.5" viewBox="0 0 27 24.5">
        <path
            className="a"
            fill="none"
            stroke={fill}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M28,4.5H3L13,16.325V24.5L18,27V16.325Z"
            transform="translate(-2 -3.5)"
        />
    </svg>
);

export const SelectColumnsIcon = ({fill = "#546b81"}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="31.429" height="25" viewBox="0 0 31.429 25">
        <g transform="translate(-175.5 -388)">
            <path
                className="a"
                fill={fill}
                d="M6803.429,2609H6794v-22h9.429v22Zm-7.858-6.286v4.715h6.287v-4.715Zm0-6.286v4.715h6.287v-4.715Zm0-6.285v4.713h6.287v-4.713Z"
                transform="translate(-6618.5 -2197)"
            />
            <path
                className="a"
                fill={fill}
                d="M6803.429,2609H6794v-22h9.429v22Zm-7.858-6.286v4.715h6.287v-4.715Zm0-6.286v4.715h6.287v-4.715Zm0-6.285v4.713h6.287v-4.713Z"
                transform="translate(-6596.5 -2197)"
            />
            <path
                className="a"
                fill={fill}
                d="M6804.715,2612H6794v-25h10.715v25Zm-8.93-7.143v5.357h7.144v-5.357Zm0-7.144v5.358h7.144v-5.358Zm0-7.142v5.356h7.144v-5.356Z"
                transform="translate(-6608 -2199)"
            />
        </g>
    </svg>
);

export const RenameColumnsIcon = ({fill = "#546b81"}) => (
    <svg id="Layer_2" data-name="Layer 2" width="31.429" height="25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 349.97 287.57">
        <g id="Layer_1-2" data-name="Layer 1">
            <g>
                <path
                    fill={fill}
                    d="M230.43,191.88v23.4c-1.88,.08-3.5,.22-5.13,.22-71.18,.01-142.37,.01-213.55,0-9.31,0-11.74-2.48-11.75-11.94,0-39.86,0-79.71,0-119.57,0-9.51,2.42-11.98,11.7-11.98,71.18,0,142.37,0,213.55,0,1.63,0,3.25,0,5.16,0v23.41H23.38v96.46H230.43Z"
                />
                <path fill={fill} d="M40.36,112.25H230.38v62.85H40.36v-62.85Z" />
                <path
                    fill={fill}
                    d="M276.41,287.57c-6.84-.4-13.28-1.97-18.45-6.67-2.17-1.97-3.55-2.14-5.81-.1-5.59,5.05-12.49,6.81-19.86,6.71-5.05-.07-8.85-3.52-8.85-7.65,0-4.14,3.74-7.4,8.83-7.71,11.94-.71,15.03-4.02,15.03-16.1,0-75.2,0-150.39,0-225.59,0-10.81-3.68-14.61-14.55-15.07-5.44-.23-9.33-3.47-9.31-7.74C223.46,3.35,227.44-.02,232.81,0c7.2,.02,13.94,1.73,19.36,6.73,2.26,2.09,3.65,1.81,5.81-.13C263.75,1.44,270.85-.19,278.39,.02c5.3,.15,8.67,3.41,8.55,7.84-.11,4.23-3.38,6.92-8.42,7.57-3.7,.48-7.71,.81-10.87,2.52-4.31,2.33-5,6.97-4.99,11.76,.07,63.36,.05,126.72,.05,190.08,0,12.84-.03,25.68,.01,38.52,.03,9.21,4.53,13.43,13.82,13.96,2.93,.17,6.5,1.12,8.44,3.05,1.48,1.47,1.69,5.33,.96,7.65-1.38,4.37-5.61,4.6-9.53,4.6Z"
                />
                <path
                    fill={fill}
                    d="M279.49,215.28v-23.04h46.83V95.61h-46.66v-23.6h4.6c18.16,0,36.32-.01,54.47,0,8.42,0,11.18,2.61,11.2,11,.05,40.49,.05,80.99,0,121.48-.01,8.37-2.8,11-11.2,11-18.16,.02-36.32,.01-54.47,0-1.46,0-2.92-.13-4.77-.21Z"
                />
                <path fill={fill} d="M279.57,112.23h30.07v62.96h-30.07v-62.96Z" />
            </g>
        </g>
    </svg>
);

export const ModelTableIcon = ({fill = "#546b81"}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="21.875" viewBox="0 0 25 21.875">
        <path
            fill={fill}
            d="M2.571,3.856V25.731h25V3.856h-25Zm9.375,14.062V13.231H18.2v4.687Zm6.25,1.563v4.688h-6.25V19.481Zm0-12.5v4.687h-6.25V6.981Zm-7.813,0v4.687H4.133V6.981Zm-6.25,6.25h6.25v4.687H4.133Zm15.625,0h6.25v4.687h-6.25Zm0-1.562V6.981h6.25v4.687ZM4.133,19.481h6.25v4.688H4.133V19.481Zm15.625,4.688V19.481h6.25v4.688Z"
            transform="translate(-2.571 -3.856)"
        />
    </svg>
);

export const NewColumnIcon = ({fill = "#546b81"}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="29.501" height="28" viewBox="0 0 29.501 28">
        <g id="Add_Column_Icon" data-name="Add Column Icon" transform="translate(-590.5 -677)">
            <path
                id="Subtraction_2"
                data-name="Subtraction 2"
                d="M6806,2615h-12v-28h12v28Zm-10-8v6h8v-6Zm0-8v6h8v-6Zm0-8v6h8v-6Z"
                transform="translate(-6186 -1910)"
                fill={fill}
            />
            <path
                id="Union_4"
                data-name="Union 4"
                d="M6799.5,2601v-5.5H6794v-3h5.5V2587h3v5.5h5.5v3h-5.5v5.5Z"
                transform="translate(-6203.5 -1903)"
                fill={fill}
            />
        </g>
    </svg>
);

export const NewAggregatedMeasureIcon = ({fill = "#546b81"}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="29.163" height="28" viewBox="0 0 29.163 28">
        <g transform="translate(-590.837 -677)">
            <path fill={fill} d="M6806,2615h-12v-28h12v28Zm-10-8v6h8v-6Zm0-8v6h8v-6Zm0-8v6h8v-6Z" transform="translate(-6186 -1910)" />
            <g transform="translate(572.917 672.7)">
                <path
                    fill={fill}
                    d="M10.294,2.565A1.551,1.551,0,0,0,9.353,2.3a.814.814,0,0,0-.265.029,3.129,3.129,0,0,0-2.118,2c-.176.412-.353.853-.5,1.294-.088.206-.147.412-.235.618a1.943,1.943,0,0,1-.147.441H5.029a.265.265,0,0,0,0,.529h.912L5.412,9.418c-.471,2.412-1.147,5.206-1.324,5.706s-.412.765-.735.765a.22.22,0,0,1-.147-.029.153.153,0,0,1-.059-.118.512.512,0,0,1,.088-.206.568.568,0,0,0,.088-.294.548.548,0,0,0-.206-.471.6.6,0,0,0-.441-.176.721.721,0,0,0-.471.176.606.606,0,0,0-.206.5.932.932,0,0,0,.353.735,1.31,1.31,0,0,0,.912.294,2.175,2.175,0,0,0,1.618-.765A3.99,3.99,0,0,0,5.5,14.506a19.682,19.682,0,0,0,1.088-3.618c.294-1.235.588-2.471.853-3.735H8.471a.265.265,0,0,0,0-.529H7.559A18.844,18.844,0,0,1,8.765,3.153c.206-.324.412-.5.618-.5a.249.249,0,0,1,.176.059.222.222,0,0,1,.029.118.765.765,0,0,1-.088.206.783.783,0,0,0-.088.353.534.534,0,0,0,.176.412.64.64,0,0,0,.882,0,.692.692,0,0,0,.176-.5A.736.736,0,0,0,10.294,2.565Z"
                    transform="translate(15.92 9)"
                />
                <path
                    fill={fill}
                    d="M31.2,17.595a1.16,1.16,0,0,0,1.1-1.278c0-.959-.7-1.017-.9-1.017q-.654,0-1.22.959c-.378.668-.784,1.365-.784,1.365h0c-.087-.465-.174-.842-.2-1.017a1.63,1.63,0,0,0-1.51-1.278,3.9,3.9,0,0,0-1.83.552h0a.571.571,0,0,0-.261.494.583.583,0,0,0,.581.581.848.848,0,0,0,.261-.058h0s.726-.407.871,0a1.665,1.665,0,0,1,.116.407c.174.639.349,1.365.494,2.033l-.639.9a4.305,4.305,0,0,0-1.075-.261,1.16,1.16,0,0,0-1.1,1.278c0,.988.7,1.017.9,1.017q.654,0,1.22-.959c.378-.668.784-1.365.784-1.365.116.581.232,1.075.29,1.278A1.546,1.546,0,0,0,29.834,22.3a3.793,3.793,0,0,0,1.656-.494.609.609,0,0,0,.378-.552.583.583,0,0,0-.581-.581.848.848,0,0,0-.261.058h0s-.639.349-.842.087a4.542,4.542,0,0,1-.378-1.162c-.087-.436-.2-.929-.29-1.423l.639-.929A2.885,2.885,0,0,0,31.2,17.595Z"
                    transform="translate(-1.27 1)"
                />
            </g>
        </g>
    </svg>
);

export const NewViewIcon = ({fill = "#546b81"}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="49" height="26.25" viewBox="0 0 49 26.25">
        <g id="Add_Column_Icon" data-name="Add Column Icon" transform="translate(-590.5 -677.583)">
            <path
                id="Union_4"
                data-name="Union 4"
                d="M6799.5,2601v-5.5H6794v-3h5.5V2587h3v5.5h5.5v3h-5.5v5.5Z"
                transform="translate(-6203.5 -1903)"
                fill={fill}
            />
            <path
                id="Table_Icon"
                data-name="Table Icon"
                d="M2.571,3.856v26.25h30V3.856h-30Zm11.25,16.875V15.106h7.5v5.625Zm7.5,1.875v5.625h-7.5V22.606Zm0-15v5.625h-7.5V7.606Zm-9.375,0v5.625h-7.5V7.606Zm-7.5,7.5h7.5v5.625h-7.5Zm18.75,0h7.5v5.625H23.2Zm0-1.875V7.606h7.5v5.625ZM4.446,22.606h7.5v5.625h-7.5V22.606ZM23.2,28.231V22.606h7.5v5.625Z"
                transform="translate(606.929 673.727)"
                fill={fill}
            />
        </g>
    </svg>
);

export const DataViewIcon = ({fill = "#546b81", width = 41, height = 26}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 13.7 12">
        <g transform="translate(-220.38 -121.797)">
            <path className="a" fill={fill} d="M220.38,121.8v12h13.7v-12Zm12.7,11h-11.7v-9h11.7Z" />
            <path
                className="a"
                fill={fill}
                d="M227.38,131.615c2.821,0,4.382-3.055,4.447-3.185a.5.5,0,0,0,0-.448c-.065-.129-1.626-3.185-4.447-3.185s-4.382,3.056-4.447,3.185a.5.5,0,0,0,0,.448C223,128.56,224.559,131.615,227.38,131.615Zm0-5.818c1.813,0,3.043,1.772,3.426,2.409-.383.637-1.613,2.409-3.426,2.409s-3.044-1.773-3.426-2.409C224.334,127.568,225.558,125.8,227.38,125.8Z"
            />
            <path
                className="a"
                fill={fill}
                d="M227.38,129.8a1.591,1.591,0,1,0-1.591-1.591A1.593,1.593,0,0,0,227.38,129.8Zm0-2.182a.591.591,0,1,1-.591.591A.592.592,0,0,1,227.38,127.615Z"
            />
        </g>
    </svg>
);

export const ChildDatasetIcon = ({fill = "#546b81"}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="25.001" height="25.001" viewBox="0 0 25.001 25.001">
        <path className="a" fill={fill} d="M25,25H0V0H25V25ZM1,3V24H24V3Z" />
        <path
            fill={fill}
            d="M2.571,3.856v12H16.285v-12H2.571ZM7.714,11.57V9h3.429V11.57Zm3.429.857V15H7.714V12.427Zm0-6.857V8.142H7.714V5.57Zm-4.286,0V8.142H3.428V5.57ZM3.428,9H6.856V11.57H3.428ZM12,9h3.429V11.57H12Zm0-.857V5.57h3.429V8.142ZM3.428,12.427H6.856V15H3.428V12.427ZM12,15V12.427h3.429V15Z"
            transform="translate(6.43 6.728)"
        />
        <path fill={fill} d="M12,9l-.529-.529-2.1,2.092V6h-.75v4.564l-2.092-2.1L6,9l3,3Z" transform="translate(6.688 -1.999)" />
        <path fill={fill} d="M6,3l-.529-.529-2.1,2.092V0h-.75V4.564L.532,2.467,0,3,3,6Z" transform="translate(2.688 20.584) rotate(-90)" />
    </svg>
);

export const CaseWhenIcon = ({fill = "#546b81"}) => (
    <svg width="30" height="30" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M35.7384 31.1524L35.9042 30.9786L35.7371 30.806L34.7889 29.8261L34.608 29.6392L34.4284 29.8275L31.2706 33.1382L30.1296 31.9413L29.95 31.7529L29.769 31.94L28.8203 32.9204L28.6533 33.093L28.8191 33.2668L31.0896 35.6474L31.2705 35.8371L31.4515 35.6474L35.7384 31.1524Z"
            fill={fill}
            stroke="#546B81"
            strokeWidth="0.5"
        />
        <path
            d="M1.65992 35.1889L1.84084 35.3787L2.02177 35.189L3.39227 33.7519L4.76314 35.189L4.9441 35.3787L5.12499 35.1889L5.96562 34.307L6.13009 34.1345L5.96558 33.962L4.57895 32.5081L5.96559 31.0539L6.13012 30.8814L5.96557 30.7089L5.12494 29.8275L4.94406 29.6378L4.76315 29.8274L3.39227 31.2643L2.02176 29.8274L1.84087 29.6378L1.65997 29.8274L0.819111 30.7088L0.654508 30.8814L0.819086 31.0539L2.20591 32.5081L0.819099 33.962L0.654539 34.1345L0.819066 34.3071L1.65992 35.1889Z"
            fill={fill}
            stroke="#546B81"
            strokeWidth="0.5"
        />
        <path
            d="M25.4636 20.5378H32.2908V26.9994V27.2494H32.5408H34H34.25V26.9994V18.9716V18.7216L34 18.7216L25.464 18.7221L19.3738 13.2285V10V9.75H19.1238H17.6646H17.4146V10V13.2287L11.3241 18.7222H3H2.75V18.9722V27V27.25H3H4.45915H4.70914L4.70915 27L4.7094 20.5378H11.3236L18.2262 26.7637L18.3936 26.9148L18.5611 26.7637L25.4636 20.5378ZM18.394 24.3804L13.1275 19.6303L18.394 14.8801L23.6605 19.6302L18.394 24.3804Z"
            fill={fill}
            stroke="#546B81"
            strokeWidth="0.5"
        />
        <path
            d="M22.25 4.50004V4.5C22.25 2.43178 20.5679 0.75 18.5 0.75C16.4318 0.75 14.75 2.43214 14.75 4.5C14.75 6.56823 16.4325 8.25 18.5 8.25C20.5679 8.25 22.2497 6.56819 22.25 4.50004ZM16.6105 4.50065C16.6105 3.45861 17.4582 2.61112 18.5 2.61112C19.5418 2.61112 20.3895 3.45861 20.3895 4.50065C20.3895 5.54269 19.5418 6.39018 18.5 6.39018C17.458 6.39018 16.6105 5.54241 16.6105 4.50065Z"
            fill={fill}
            stroke="#546B81"
            strokeWidth="0.5"
        />
    </svg>
);

export const StaticValueIcon = ({fill = "#546b81"}) => (
    <svg width="27" height="30" viewBox="0 0 27 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_4238_4610)">
            <path
                d="M7.73999 7.50098H16.74L16.74 29.501H7.73999L7.73999 7.50098ZM15.24 14.269V9.19298H9.23999L9.23999 14.269L15.24 14.269ZM15.24 21.038V15.962L9.23999 15.962L9.23999 21.038H15.24ZM15.24 27.807V22.732H9.23999V27.807H15.24Z"
                fill={fill}
            />
            <path
                d="M26.478 1.00098V6.00098C26.478 6.26619 26.3726 6.52047 26.1851 6.70801C25.9975 6.89554 25.7432 7.00098 25.478 7.00098H24.738V24.501H15.738L15.738 2.50098H19.478V1.00098C19.478 0.73576 19.5835 0.481482 19.771 0.293945C19.9585 0.106409 20.2128 0.000976562 20.478 0.000976562H25.478C25.7432 0.000976562 25.9975 0.106409 26.1851 0.293945C26.3726 0.481482 26.478 0.73576 26.478 1.00098ZM23.478 4.00098H24.478V3.00098H23.478V2.00098H22.478V3.00098H21.478V4.00098H22.478V5.00098H23.478V4.00098ZM17.238 9.27002H23.238V7H20.4771C20.2118 7 19.9573 6.89457 19.7698 6.70703C19.5822 6.51949 19.4771 6.26522 19.4771 6V4.19409H17.2361L17.238 9.27002ZM17.238 16.0391H23.238V10.9629H17.238V16.0391ZM17.238 22.8079H23.238V17.7329H17.238V22.8079Z"
                fill={fill}
            />
            <path
                d="M0 7.50098H9L9 29.501H0L0 7.50098ZM7.5 14.269L7.5 9.19298H1.5L1.5 14.269L7.5 14.269ZM7.5 21.038L7.5 15.962L1.5 15.962L1.5 21.038H7.5ZM7.5 27.807L7.5 22.732H1.5L1.5 27.807H7.5Z"
                fill={fill}
            />
        </g>
        <defs>
            <clipPath id="clip0_4238_4610">
                <rect width="29.501" height="26.477" fill="white" transform="matrix(0 -1 1 0 0 29.501)" />
            </clipPath>
        </defs>
    </svg>
);
