import "./top-section.scss";

import React from "react";
import {cx} from "emotion";

import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";

import {CreateEnvService} from "../../../getting-started/manage-org/sdlc-env/create-env-service";
import {EnvironmentSelect} from "./environment-select";
import {CollectionsIcon, DataSourcesIcon, GuidedSetup, InsightIcon, ModelsIcon, OverviewIcon, RecentIcon, SettingsIcon, ShareTilesIcon, ThemeIcon} from "./icons";
import {getUnauthorizedParamValues, isAuthorized} from "../../../../verb-web/authorization/authorization";

const navs = [
    // {name: "Overview", icon: OverviewIcon, value: "overview"},
    {name: "Recent", icon: RecentIcon, value: "recent"},
    {name: "Data Sources", icon: DataSourcesIcon, value: "data-sources"},
    {name: "Models", icon: ModelsIcon, value: "models"},
    {name: "Collections", icon: CollectionsIcon, value: "collections"},
    {name: "Shared Tiles", icon: ShareTilesIcon, value: "shared-tiles"},
    {name: "Themes", icon: ThemeIcon, value: "themes"},
    {name: "Settings", icon: SettingsIcon, value: "settings"},
];

export const TopSection = ({envId, tab}) =>
    cs(consumeContext("routing"), consumeContext("apis"), consumeContext("gettingStarted"), consumeContext("auth"), (
        {
            routing,
            gettingStarted,
            auth,
        },
    ) => {
        return (
            <div className="top-section top-section-1s9">
                {/* <div
                    className={cx("insights", {
                        active: routing.routeName === "insights",
                    })}
                    onClick={() => routing.goto("insights")}
                >
                    {InsightIcon({})}
                    <span className="text">Insights</span>
                </div> */}

                <div className="env-section">
                    {/* only show this section for report builder user */}
                    {isAuthorized({roles: auth.user.roles, routeName: "report-builder"}) && DashboardNav({
                        tab,
                        envId,
                        navs: [
                            {
                                name: "Report Builder",
                                isActive: () => routing.routeName === "report-builder",
                                icon: SettingsIcon,
                                onClick: () => routing.goto("report-builder", {reportTab: "builder"}),
                            },
                        ],
                    })}

                    {/* since the report builder use the same DashboardLayout as the main dashboard, need to hide these sections for report builder user */}
                    {!auth.user.roles.includes("ReportBuilder") && (
                        <>
                            {Environments({envId})}
                            {DashboardNav({tab, envId, navs})}
                        </>
                    )}
                </div>
            </div>
        );
    });

const Environments = ({envId}) =>
    cs(
        consumeContext("tenant"),
        consumeContext("auth"),
        consumeContext("routing"),
        ["createEnvService", (_, next) => CreateEnvService({next})],
        ({tenant: {environments}, createEnvService, routing}) =>
            EnvironmentSelect({
                list: environments,
                envId,
                onCreate: () =>
                    createEnvService.createEnv({
                        onDone: (resp) =>
                            routing.goto("dashboard", {
                                envId: resp,
                                tab: "data-sources",
                            }),
                    }),
            })
    );

const DashboardNav = ({envId, tab, navs}) =>
    cs(consumeContext("auth"), ({auth}) => {
        const unauthorizedTabs = getUnauthorizedParamValues({
            roles: auth.user.roles,
            routeName: "dashboard",
            param: "tab",
        });
        const allowedTabs = navs.filter(({value}) => !unauthorizedTabs.includes(value));
        return (
            <div className="dashboard-nav">
                {tab &&
                    allowedTabs.map((nav, i) => (
                        <div
                            className={cx("dashboard-item", {
                                active: nav.isActive ? nav.isActive() : nav.value === tab?.value,
                            })}
                            key={i}
                            onClick={() => envId.value && (nav.onClick ? nav.onClick() : tab.onChange(nav.value))}
                        >
                            {nav.icon({})}
                            <div className="text">{nav.name}</div>
                        </div>
                    ))}
            </div>
        );
    });
