import "./tile-fields.scss";

import * as React from "react";

import {DebounceNumberInput} from "@common/form/debounce-input/debounce-number-input";
import {cs} from "@common/react/chain-services";
import {provideContext} from "@common/react/context";
import {scope} from "@common/react/scope";
import {Expandable} from "../../../../../../../common/expandable/expandable";
import {getTileActionsUpdated} from "../actions/get-tile-actions-updated";
import {getTileFields} from "./common/get-tile-fields";
import {SingleFieldWell} from "./common/single-field-well/single-field-well";
import {updateSingleFieldInTile} from "./common/update-tile";

export const GaugeFields = ({tile, isSubTile, tileActions, debugFunction, next}) =>
    cs(
        [
            "tileFields",
            ({}, next) =>
                next(
                    getTileFields({
                        tile: tile.value,
                        measureSingleAttrs: ["valueField", "targetValueField"],
                    })
                ),
        ],
        ({tileFields}, next) => provideContext("tileFields", tileFields, next),

        [
            "valueField",
            (_, next) =>
                SingleFieldWell({
                    field: scope(tile, ["valueField"]),
                    $type: "MeasureTileField",
                    label: "Value",
                    notAllowNoneAggregation: true,
                    onUpdateTile: (newVal, oriVal) =>
                        updateSingleFieldInTile({
                            fieldAttr: "valueField",
                            fieldVal: {newVal, oriVal},
                            tile,
                            updateTileActions: getTileActionsUpdated,
                        }),
                    next,
                }),
        ],
        [
            "targetValueField",
            (_, next) => {
                // targetValueField and targetValueConstant are not allowed to be specified at the same time
                const constantField = {
                    value: tile.value.targetValueConstant,
                    onChange: (newObj) =>
                        tile.onChange({
                            ...tile.value,
                            targetValueConstant:
                                newObj != null
                                    ? {
                                          ...newObj,
                                          displayName: newObj.displayName || "Target",
                                      }
                                    : null,
                            targetValueField: null,
                        }),
                };

                return SingleFieldWell({
                    field: {
                        value: tile.value.targetValueField,
                        onChange: (f) =>
                            tile.onChange({
                                ...tile.value,
                                targetValueField: f,
                                targetValueConstant: null,
                            }),
                    },
                    $type: "MeasureTileField",
                    label: "Target Value",
                    notAllowNoneAggregation: true,
                    enableConstantValue: true,
                    constantField,
                    constantFieldSelector: () => {
                        return DebounceNumberInput({
                            state: scope(constantField, ["value"]),
                            label: "Value",
                            showZero: true,
                        });
                    },
                    next,
                });
            },
        ],

        [
            "fieldsSection",
            ({valueField, targetValueField}, next) =>
                next({
                    render: () =>
                        Expandable({
                            label: "Fields",
                            render: () => (
                                <>
                                    {valueField.render()}
                                    {targetValueField.render()}
                                </>
                            ),
                        }),
                    override: valueField.override || targetValueField.override,
                }),
        ],

        ({fieldsSection}) =>
            next({
                render: () => (
                    <div className="tile-fields-3vr">
                        {fieldsSection.render()}
                        {tileActions?.render?.()}
                        {isSubTile && debugFunction?.render?.({})}
                    </div>
                ),
                override: fieldsSection.override || debugFunction?.override() || tileActions?.override({fieldType: "MeasureTileField"}),
                overrideAll: debugFunction?.overrideAll || tileActions?.overrideAll,
            })
    );
