export const FunnelIcon = () => (
    <svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.26759 5.5L0.934259 0.5H27.0657L23.7324 5.5H4.26759Z" stroke="#4C5F71" />
        <path d="M9.26759 13.5L5.93426 8.5H22.0657L18.7324 13.5H9.26759Z" stroke="#4C5F71" />
        <path d="M14 21.0986L10.9343 16.5H17.0657L14 21.0986Z" stroke="#4C5F71" />
    </svg>
);

export const PyramidIcon = () => (
    <svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M23.7324 16.5L27.0657 21.5L0.934259 21.5L4.26759 16.5L23.7324 16.5Z" stroke="#4C5F71" />
        <path d="M18.7324 8.5L22.0657 13.5L5.93426 13.5L9.26759 8.5L18.7324 8.5Z" stroke="#4C5F71" />
        <path d="M14 0.901387L17.0657 5.5L10.9343 5.5L14 0.901387Z" stroke="#4C5F71" />
    </svg>
);
