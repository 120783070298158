import {isBlank} from "@common/utils/strings";

export const outboundApiValidation = {
    getInvalidRequests: (requests) => {
        let ret = [];
        for (let i = 0; i < requests.length; i++) {
            const request = requests[i];
            if (isBlank(request?.httpMethod) || isBlank(request?.uri)) {
                ret.push(request);
            }
        }
        return ret;
    },
    valid: (data) => {
        return outboundApiValidation.getInvalidRequests(data.connectionDetails.requests).length === 0;
    },
};
