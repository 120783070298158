import {cs} from "@common/react/chain-services";
import {scope} from "@common/react/scope";
import * as React from "react";
import "./comparison.scss";
import {CheckboxLineGroupFlex} from "../../../../../../../common/checkbox-line-group/checkbox-line-group-flex";

export const Comparison = ({filter}) =>
    cs(({}) => (
        <div className="comparison comparison-5qq">
            <div className="header">Comparison</div>

            <div className="content">
                {CheckboxLineGroupFlex({
                    list: [
                        {
                            label: "Allow to compare over range",
                            attr: "compareEnabled",
                        },
                        {
                            label: "On by default",
                            attr: "compareOnByDefault",
                        },
                    ],
                    numberOfColumns: 2,
                    isSelected: (item) => filter.value[item.attr],
                    onChange: (item) => scope(filter, [item.attr]).change((v) => !v),
                })}
            </div>
        </div>
    ));
