import React from "react";
import {cs} from "@common/react/chain-services";
import {CircleRadius} from "../../model-panel";
import {lighten} from "@common/utils/color-util";
import {UseState} from "@common/react/use-state";
import {Static2} from "@common/react/static-2";
import {ModelPanelHelper} from "../../../../common/model-panel-helper";
import {Invoke} from "@common/react/invoke";
import {GlobalKeyDown} from "@common/react/keys/global-key-down";
import {cx} from "emotion";
import {CreateRelationshipIcon} from "../more-controls/icons/more-control-icons";
import {DEFAULT_RELATIONSHIP} from "../../model-constrants";

export const CreateNewRelationship = ({
    table,
    color,
    modelActionTooltip,
    display,
    suggestedRelationships,
    model,
    interactions,
    createRelationshipLine,
    hoverInteractions,
}) =>
    cs(["hovering", (_, next) => UseState({next})], ["actionRef", (_, next) => Static2({next})], ({hovering, actionRef}) => {
        const disabled = table.deleted;

        return (
            <g fillOpacity={disabled ? 0.5 : 1} strokeOpacity={disabled ? 0.5 : 1}>
                {actionRef.get() &&
                    Invoke({
                        props: hoverInteractions,
                        fn: ({getLatestProps}) => {
                            if (table.deleted) return;
                            d3.select(actionRef.get())
                                .call(
                                    d3
                                        .drag()
                                        .on("start", (e) => {
                                            createRelationshipLine.startDrag({
                                                e: e.sourceEvent,
                                            });
                                        })
                                        .on("drag", (e) => {
                                            display.onChange(false);
                                            createRelationshipLine.dragging(e.sourceEvent);
                                        })
                                        .on("end", () => {
                                            display.onChange(true);
                                            const suggestColumns = ModelPanelHelper.getSuggestColumns({
                                                table,
                                                suggestedRelationships: suggestedRelationships || [],
                                                relationships: model.value.relationships,
                                            });

                                            createRelationshipLine.stop(getLatestProps()?.value?.tableId, {
                                                suggestedRelationships: suggestColumns,
                                                tables: model.value.tables,
                                                allowBack: true,
                                            });
                                        })
                                )
                                .on("click", (e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    display.onChange(false);
                                    setTimeout(() => {
                                        modelActionTooltip.hideToolTip();
                                    });

                                    const suggestColumns = ModelPanelHelper.getSuggestColumns({
                                        table,
                                        suggestedRelationships: suggestedRelationships || [],
                                        relationships: model.value.relationships,
                                    });
                                    interactions.createNewRelationship({
                                        table,
                                        relationship: DEFAULT_RELATIONSHIP,
                                        suggestedRelationships: suggestColumns,
                                        tables: model.value.tables,
                                        allowBack: true,
                                    });
                                    createRelationshipLine.startClick(e);
                                });
                        },
                    })}

                {GlobalKeyDown({
                    keyCombo: "Escape",
                    onKeyDown: () => {
                        display.onChange(true);
                        createRelationshipLine.stop();
                    },
                })}

                <path
                    className={cx("action-btn", table.deleted && "disabled")}
                    onMouseEnter={(e) => {
                        hovering.onChange(true);
                        const {x, y} = actionRef.get()?.getBoundingClientRect();
                        modelActionTooltip.showTooltip({
                            pos: {
                                x,
                                y,
                                transform: `translate(-100%)`,
                            },
                            info: "Create Relationship",
                        });
                    }}
                    onMouseLeave={() => {
                        hovering.onChange(false);
                        modelActionTooltip.hideToolTip();
                    }}
                    x={table.position.x - CircleRadius * 2 - 3}
                    y={table.position.y - CircleRadius - 4}
                    width="33.129"
                    height="58.051"
                    viewBox="0 0 33.129 58.051"
                    ref={actionRef.set}
                    d="M-54.509,22.578a59,59,0,0,1,5.452-55.357L-28.27-18.889c-.259.387-.509.778-.907,1.434a33.858,33.858,0,0,0-2.235,30.467Z"
                    transform="translate(60.011 60)"
                    fill={table.deleted ? "#E9ECEF" : hovering.value ? lighten(color, 80) : lighten(color, 70)}
                    stroke="#fff"
                    strokeWidth="2"
                />
                <g>
                    <CreateRelationshipIcon x={6.6} y={46.8} />
                </g>
            </g>
        );
    });
