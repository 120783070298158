import {flatten1} from "@common/utils/collections";
import {
    generateAggregationSeries,
    generateConstantSeries
} from "@common/ui-components/charts/scatter-plot/reference-lines/constant-or-aggregate-value-series";
import {generateIndicatorSeries} from "@common/ui-components/charts/scatter-plot/reference-lines/indicator-series";

export const generateReferenceSeries = ({tile, theme, formatters, series}) => {
    if (!tile.referenceLines) {
        return;
    }

    const dataPoints = flatten1(
        series
            .map((s) => !s.isCompare && s.data) // only take main series
            .filter((v) => v)
    );

    const getReferenceSeries = (rl) => {
        if (rl.$type === "ConstantValueReferenceLine" && rl.value != null) {
            return generateConstantSeries({tile, theme, formatters, config: rl, dataPoints});
        }

        if (
            rl.$type === "AggregateValueReferenceLine"
            && (
                rl.aggregation !== "Percentile"
                || (rl.aggregation === "Percentile" && rl.value != null)
            )
        ) {
            return generateAggregationSeries({tile, theme, formatters, config: rl, dataPoints});
        }

        if (rl.$type === "IndicatorReferenceLine") {
            return generateIndicatorSeries({tile, theme, formatters, config: rl, dataPoints});
        }
    };

    return flatten1(tile.referenceLines.map(getReferenceSeries)).filter(v => v);
}
