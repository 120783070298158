import {cs} from "@common/react/chain-services";
import {FlatFileImportConfiguration} from "../../../edit/flat-file-status/import-configuration/flat-file-import-configuration";

export const DsAddingImportConfiguration = ({dataSource, next}) =>
    cs(() =>
        next({
            valid: true,
            render: () => FlatFileImportConfiguration({dataSource}),
        })
    );
