const {spc} = require("@common/react/state-path-change");
const {removeIndex} = require("../../../../../../../../../../../../common/utils/collections");

const removeField = ({groupIndex, fieldIndex, fieldGroups}) => {
    const subFields = fieldGroups.value[groupIndex]["measureFields"];

    if (subFields.length > 1) {
        spc(fieldGroups, [groupIndex, "measureFields"], (fields) => removeIndex(fieldIndex, fields));
    } else {
        spc(fieldGroups, [], (groups) => removeIndex(groupIndex, groups));
    }
};
exports.removeField = removeField;
