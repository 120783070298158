import * as React from "react";

import {getPath} from "@common/utils/arr-path";

import {AreaDisplay} from "./area-display";
import {LineDisplay} from "./line-display";

export const Display = ({tile}) => {
    const type = getPath(tile.value, ["style", "displayType"])?.includes("Area") ? "area" : "line";
    return {
        initExpand: true,
        label: type === "line" ? "Line Display" : "Display",
        render: () => sections[type]({tile}),
    };
};

export const sections = {
    area: AreaDisplay,
    line: LineDisplay,
};
