import {cs} from "../../../react/chain-services";
import * as React from "react";
import "./error-message.scss";
import {Button} from "../../../form/buttons/button/button";
import {consumeContext} from "../../../react/context";
import {tooltipService3} from "../../../../web-client/src/routes/common/tooltip3/tooltip-service-3";
import {getTooltipFontSizes} from "@common/ui-components/charts/factory/tooltip/shared-tooltip-formatter/get-tooltip-font-sizes";

export const ErrorMessage = ({icon, renderIcon, message, button, color, iconOnly}) => {
    return cs(
        consumeContext("theme"),
        [
            "tooltip",
            ({active, theme}, next) => {
                const themeMode = theme.dataVisualization.toolTipsAndLegends.tooltipTheme.toLowerCase() || "light";

                return cs(
                    tooltipService3({
                        direction: "below",
                        disabled: !iconOnly,
                        className: `${themeMode}-theme`,
                        tooltipContentStyle: {
                            fontSize: getTooltipFontSizes(theme).small,
                            fontFamily: theme.general.canvas.fontFamily,
                            color: themeMode === "light" ? theme.general.canvas.fontColorRGB || "#546B81" : "#E2E2E2",
                        },
                    }),
                    ({tooltip}) => next(tooltip)
                );
            },
        ],
        ({theme, tooltip}) => {
            const tileStyles = theme?.general?.tile?.styles;
            return (
                <div className="error-message-2s9">
                    {(icon || renderIcon) && (
                        <>
                            <div className="icon">{icon || renderIcon(tooltip)}</div>
                        </>
                    )}

                    {message && !iconOnly && (
                        <div
                            className="message"
                            style={{
                                color: color || tileStyles?.titleFontColorRGB,
                            }}
                        >
                            {message}
                        </div>
                    )}

                    {button && !iconOnly && (
                        <Button className="error-btn" size="tiny" btnType="border" onClick={button.onClick}>
                            {button.label}
                        </Button>
                    )}
                </div>
            );
        }
    );
};
