import React from "react";
import {cs} from "@common/react/chain-services";
import {cx} from "emotion";
import {Dropdown} from "@common/ui-components/dropdown/dropdown";
import "./button-dropdown.scss";
import {Button} from "../../../../../../common/form/buttons/button/button";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";

export const ButtonDropdown = ({
    label,
    list,
    onChange,
    size = "medium",
    btnType = "primary",
    disabled,
    valueToLabel = (v) => v,
    iconRight = <i className="fal fa-angle-down"></i>,
    className,
}) =>
    cs(
        [
            "renderToggle",
            ({chosenIndex}, next) =>
                next(({showExpand, showingExpand}) => (
                    <Button
                        className={cx("toggle", {expanding: showingExpand}, className)}
                        onClick={() => showExpand(!showingExpand)}
                        iconRight={iconRight}
                        size={size}
                        btnType={btnType}
                        disabled={disabled}
                    >
                        {label}
                    </Button>
                )),
        ],
        [
            "renderExpand",
            ({chosenIndex}, next) =>
                next(({close, width}) => (
                    <VerbScrollbar maxHeight="300px" className="list" style={{minWidth: width}}>
                        {list?.map((l, i) => (
                            <div
                                key={i}
                                className={cx("item")}
                                onClick={() => {
                                    onChange(l);
                                    close();
                                }}
                            >
                                {valueToLabel(l)}
                            </div>
                        ))}
                    </VerbScrollbar>
                )),
        ],
        ({renderToggle, renderExpand}) => (
            <div className={cx("button-dropdown button-dropdown-9wq", disabled && "disabled")}>
                {Dropdown({
                    renderToggle,
                    minExpandHeight: 300,
                    renderExpand,
                    disabled,
                })}
            </div>
        )
    );
