import {cs} from "../../../../react/chain-services";
import {Watch} from "../../../../react/watch";
import {UseState} from "../../../../react/use-state";
import {OnMounted} from "../../../../react/on-mounted";
import {generateArrayBetweenNumbers} from "../../../../utils/arr-util";

export const VirtualizedList = ({scrollContainerRef, render, itemHeight, total}) =>
    cs(
        ["offsetTop", (_, next) => UseState({next, initValue: 0})],
        ["viewHeight", (_, next) => UseState({next, initValue: 0})],
        ({offsetTop, viewHeight}, next) =>
            Watch({
                next,
                value: total,
                onChanged: () => {
                    const dom = scrollContainerRef.get()?.getDom?.();
                    if (dom) {
                        offsetTop.onChange(dom.scrollTop);
                        viewHeight.onChange(dom.offsetHeight);
                    }
                },
            }),
        ({offsetTop, viewHeight}) => {
            const additionalItem = 5;
            const numberItemPerView = Math.ceil(viewHeight.value / itemHeight);

            const minViewPixel = Math.max(0, offsetTop.value - additionalItem * itemHeight);
            const maxViewPixel = offsetTop.value + numberItemPerView * itemHeight + additionalItem * itemHeight;

            const minIndexView = Math.ceil(minViewPixel / itemHeight);
            const maxIndexView = Math.min(Math.ceil(maxViewPixel / itemHeight), total - 1);

            return (
                <>
                    <tbody
                        style={{
                            height: itemHeight * total,
                        }}
                    >
                        {minIndexView > 0 && <tr style={{height: minIndexView * itemHeight}} />}

                        {generateArrayBetweenNumbers(minIndexView, maxIndexView).map((index) => render(index, itemHeight))}

                        {total - (maxIndexView + 1) > 0 && (
                            <tr
                                style={{
                                    height: (total - (maxIndexView + 1)) * itemHeight,
                                }}
                            />
                        )}
                    </tbody>

                    {OnMounted({
                        action: () => {
                            const updateView = () => {
                                const dom = scrollContainerRef.get()?.getDom?.();
                                if (dom) {
                                    offsetTop.onChange(Math.ceil(dom.scrollTop));
                                    viewHeight.onChange(Math.ceil(dom.offsetHeight));
                                }
                            };

                            setTimeout(() => {
                                updateView();

                                if (scrollContainerRef.get()) {
                                    scrollContainerRef.get().onScrollListener(() => {
                                        updateView();
                                    });

                                    scrollContainerRef.get().onDomSizeChange(() => {
                                        updateView();
                                    });
                                }
                            }, 5);
                        },
                    })}
                </>
            );
        }
    );
