import {cs} from "@common/react/chain-services";
import {keyed} from "@common/react/keyed";
import {UseState} from "@common/react/use-state";
import {Dropdown} from "@common/ui-components/dropdown/dropdown";
import {SearchInputBar2} from "@common/ui-components/search-input-bar/search-input-bar";
import {isBlank} from "@common/utils/strings";
import SearchIcon from "assets/icons/common/SearchIcon";
import {cLsJson} from "@common/react/ls-json";
import "./sync-settings-search-header.scss";
import {removeIndex, reverse} from "@common/utils/collections";
import {omit} from "@common/utils/objects";
import {cx} from "emotion";
import {tooltipService3} from "../../../../../common/tooltip3/tooltip-service-3";

const lsJson = (key) => cLsJson(key);
const lsKey = "SYNC_RECENT_SEARCHES";

export const SyncSettingsSearchHeader = ({list, state, adding, next}) =>
    cs(
        ["searchState", (_, next) => UseState({initValue: {value: ""}, next})],
        [
            "updateSearch",
            ({searchState}, next) =>
                next((v) => {
                    state.onChange(v);
                    searchState.onChange(v);
                }),
        ],

        [
            "renderToggle",
            ({searchState, updateSearch}, next) =>
                next(({showExpand, showingExpand}) => {
                    return SearchInputBar2({
                        disabled: searchState.value.id,
                        state: {
                            value: searchState.value.value,
                            onChange: (v) => searchState.change((s) => ({...s, value: v})),
                        },
                        onKeyDown: (e) => {
                            if(e.key === "Enter") {
                                const recentSearches = lsJson(lsKey).get() || [];
                                const _recentSearches =
                                    recentSearches.length > 6
                                        ? [...removeIndex(0, recentSearches), searchState.value.value]
                                        : [...recentSearches, searchState.value.value];

                                if(!adding) lsJson(lsKey).set(_recentSearches);
                                updateSearch({
                                    id: Date.now(),
                                    value: searchState.value.value,
                                });
                                showExpand(false);
                            }
                        },
                        onFocus: () => setTimeout(() => !showingExpand && showExpand(!showingExpand), 300),
                        onBlur: () => showExpand(!showingExpand),
                        onClear: () => updateSearch({value: ""}),
                    });
                }),
        ],
        tooltipService3({direction: "below"}),
        [
            "renderExpand",
            ({updateSearch, searchState, tooltip}, next) =>
                next(({close, width}) => {
                    const recentSearches = lsJson(lsKey).get() || [];

                    const options = [
                        ...list.map((l, i) => ({
                            ...l,
                            onClick: () =>
                                updateSearch({
                                    ...omit(l, ["icon", "label"]),
                                    value: l.label,
                                    id: i + 1,
                                }),
                        })),
                        ...(isBlank(searchState.value.value)
                            ? reverse(recentSearches).map((recent, i) => ({
                                icon: <span className="material-icons-outlined">schedule</span>,
                                label: `"${recent}" search results`,
                                onClick: () =>
                                    updateSearch({
                                        id: Date.now(),
                                        value: recent,
                                    }),
                            }))
                            : [
                                {
                                    icon: SearchIcon({}),
                                    label: (
                                        <span className="view-result">
                                            View result with <strong>{searchState.value.value}</strong>
                                        </span>
                                    ),
                                    onClick: () => {
                                        const _recentSearches =
                                            recentSearches.length > 6
                                                ? [...removeIndex(0, recentSearches), searchState.value.value]
                                                : [...recentSearches, searchState.value.value];

                                        if(!adding) lsJson(lsKey).set(_recentSearches);

                                        updateSearch({
                                            id: Date.now(),
                                            value: searchState.value.value,
                                        });
                                    },
                                },
                            ]),
                    ];

                    return (
                        <div className="list" style={{width: 480}}>
                            {options.map((item, j) =>
                                cs(keyed(j), () => (
                                    <div
                                        // {...item.disabled ? tooltip(() => item.disabledInfo) : {}}
                                        className={cx("item", {
                                            disabled: item.disabled,
                                        })}
                                        onMouseDown={() => {
                                            if(!item.disabled) {
                                                close();
                                                item.onClick();
                                            }
                                        }}
                                    >
                                        {item?.icon}
                                        <span className="label">{item.label}</span>
                                    </div>
                                ))
                            )}
                        </div>
                    );
                }),
        ],
        ({renderToggle, renderExpand, updateSearch}) =>
            next({
                updateSearch,
                clearSearch: () => updateSearch({value: ""}),
                render: () => (
                    <div className="sync-settings-search-header-88o">
                        {Dropdown({
                            renderToggle,
                            renderExpand,
                        })}
                    </div>
                ),
            })
    );
