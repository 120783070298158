import "../sorting.scss";

import {cx} from "emotion";
import * as React from "react";

import {cs} from "@common/react/chain-services";
import {spc} from "@common/react/state-path-change";
import {consumeContext} from "@common/react/context";

import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {getPath} from "@common/utils/arr-path";
import {FieldInfoProvider} from "../../../../../../tile-tab/chart-types/common/field-info-provider/field-info-provider";
import {sortIconsTypes} from "../sort-icons-types";
import {FieldSection} from "../../../field-section/field-section";
import {cGetFieldInfoWrtTileFields} from "../../../get-field-info-wrt-tile-fields";
import {tooltipService4} from "../../../../../../../../../../../common/tooltip3/tooltip-service-3";
import {CollectionErrorBadgeWithTooltip} from "@common/ui-components/live/live-dashboard/common/collection-error-badge/collection-error-badge-with-tooltip";

export const AxisSorting = ({fieldGroups, title}) =>
    cs(
        consumeContext("tileFields"),
        consumeContext("getFieldInfo"),
        consumeContext("collection"),
        [
            "tooltip",
            ({theme}, next) =>
                tooltipService4({
                    direction: "above",
                    next,
                }),
        ],
        ({tileFields, getFieldInfo, tooltip, collection}) => {
            const {fieldsForSort, allFieldsWithoutUniq: allFields} = tileFields;

            const getFieldInfoWrtTileFields = cGetFieldInfoWrtTileFields({
                tileFields,
                getFieldInfo,
            });

            const sortIcons = sortIconsTypes[getFieldInfoWrtTileFields(fieldGroups.value[0])?.type ?? "text"];

            const field = {
                value: fieldGroups.value[0],
                onChange: (data) =>
                    fieldGroups.onChange(
                        fieldGroups.value.map((_, index) => {
                            if (index == 0) return data;
                            return _;
                        })
                    ),
            };

            const getAggList = (field) => {
                if (!field) {
                    return [];
                }

                return allFields
                    .filter(
                        (f) => f.modelColumnID == field.modelColumnID && f.modelTableID == field.modelTableID && f.modelID == field.modelID
                    )
                    .map((f) => f.aggregationFunc)
                    .filter((v) => v);
            };

            return FieldSection({
                className: "sort-by-8yk",
                header: FieldInfoProvider({
                    info: <span>When {title} is a date field, the tile must be sorted by date, either ascending or descending</span>,
                    field: <div className="text">Sorting on {title}</div>,
                }),
                content: (
                    <>
                        <div className="sort-by-section">
                            <div className="label">Sort by</div>

                            <div className="sort-by has-background-border">
                                <div className="dropdown-wrapper">
                                    {DropdownSelect({
                                        detectOnWheelEvent: true,
                                        list: fieldsForSort.map((f) => ({
                                            ...f,
                                            label: getFieldInfo(f).visualNameFull,
                                        })),
                                        valueToLabel: (v) => v.label,
                                        isSelected: (v) => v.modelColumnID === getPath(fieldGroups.value, [0, "modelColumnID"]),
                                        onChange: (v) => {
                                            return spc(fieldGroups, [], (s) => {
                                                if (v.$type == "MeasureTileField") {
                                                    const dimensionField = fieldsForSort.find((t) => t.$type === "DimensionTileField");
                                                    return [
                                                        {
                                                            direction: "Asc",
                                                            modelID: v.modelID,
                                                            modelTableID: v.modelTableID,
                                                            modelColumnID: v.modelColumnID,
                                                            aggregationFunc: getAggList(v)[0],
                                                        },
                                                        ...(dimensionField
                                                            ? [
                                                                  {
                                                                      direction: "Asc",
                                                                      modelID: dimensionField.modelID,
                                                                      modelTableID: dimensionField.modelTableID,
                                                                      modelColumnID: dimensionField.modelColumnID,
                                                                  },
                                                              ]
                                                            : []),
                                                    ];
                                                } else {
                                                    return [
                                                        {
                                                            direction: "Asc",
                                                            modelID: v.modelID,
                                                            modelTableID: v.modelTableID,
                                                            modelColumnID: v.modelColumnID,
                                                            aggregationFunc: getAggList(v)[0],
                                                        },
                                                    ];
                                                }
                                            });
                                        },
                                    })}

                                    {getAggList(field.value).length > 0 &&
                                        DropdownSelect({
                                            detectOnWheelEvent: true,
                                            label: "Aggregate",
                                            className: "aggregate-field",
                                            list: getAggList(field.value),
                                            valueToLabel: (v) => v,
                                            isSelected: (v) => v === getPath(field.value, ["aggregationFunc"]),
                                            onChange: (v) =>
                                                field.onChange({
                                                    ...field.value,
                                                    aggregationFunc: v,
                                                }),
                                        })}
                                </div>

                                <div className="sorts">
                                    <div
                                        className={cx("asc", {
                                            selected: getPath(fieldGroups.value, [0, "direction"]) === "Asc",
                                        })}
                                        onClick={() => spc(fieldGroups, [0, "direction"], (sort) => "Asc")}
                                        {...tooltip(() => "Ascending")}
                                    >
                                        {sortIcons?.asc}
                                    </div>

                                    <div
                                        className={cx("desc", {
                                            selected: getPath(fieldGroups.value, [0, "direction"]) === "Desc",
                                        })}
                                        onClick={() => spc(fieldGroups, [0, "direction"], (sort) => "Desc")}
                                        {...tooltip(() => "Descending")}
                                    >
                                        {sortIcons?.desc}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {fieldGroups.value[1] &&
                            (() => {
                                const fieldInfo = getFieldInfoWrtTileFields(fieldGroups.value[1]);
                                return (
                                    <div className="sort-by-section">
                                        <div className="label">Then by</div>

                                        <div className="sort-by has-border">
                                            <div className="text">{fieldInfo?.visualNameFull}</div>
                                            <div className="sorts">
                                                <div
                                                    className={cx("asc", {
                                                        selected: getPath(fieldGroups.value, [1, "direction"]) === "Asc",
                                                    })}
                                                    onClick={() => spc(fieldGroups, [1, "direction"], (sort) => "Asc")}
                                                    {...tooltip(() => "Ascending")}
                                                >
                                                    {sortIconsTypes[fieldInfo.type || "text"]?.asc}
                                                </div>

                                                <div
                                                    className={cx("desc", {
                                                        selected: getPath(fieldGroups.value, [1, "direction"]) === "Desc",
                                                    })}
                                                    onClick={() => spc(fieldGroups, [1, "direction"], (sort) => "Desc")}
                                                    {...tooltip(() => "Descending")}
                                                >
                                                    {sortIconsTypes[fieldInfo.type || "text"]?.desc}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })()}
                    </>
                ),
            });
        }
    );
