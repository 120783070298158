import "./value-chip-input.scss";

import {scope} from "@common/react/scope";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";

import {StaticTooltipService2} from "../../../../../../../../../common/tooltip3/static-tooltip-service";
import {rFieldTypeIcon} from "../../../../../../../../../common/field-type-icon/get-field-type-icon";
import {DebounceTextInput} from "../../../../../../../../../../../../common/form/debounce-input/debounce-text-input";

import {actionFieldsType, getActionFields} from "../action-fields/action-field-utils";

export const ValueChipInput = ({tile, tileAction, label, beforeField, afterField, hideAfterField}) =>
    cs(
        consumeContext("getFieldInfo"),
        [
            "staticTooltip",
            ({getFieldInfo}, next) =>
                cs(
                    [
                        "tooltipContent",
                        (_, next) =>
                            next((props) => {
                                const fields = getActionFields(tile, getFieldInfo)
                                    .map(field => ({
                                        ...field,
                                        labelField: field._fieldProp.replace(/fields?/i, "").split(/(?=[A-Z])/).join("-")
                                    }))
                                ;

                                return (
                                    <div className="value-chip-tooltip-content-89i" {...props}>
                                        <div className="label">
                                            <div className="parenthesis">{`{ }`}</div>
                                            <div className="text">Value from one of the fields below will be inserted here</div>
                                        </div>

                                        <div className="values">
                                            {fields.map((field, i) => (
                                                <div className="value" key={i}>
                                                    <div className="label">{field.labelField}</div>
                                                    <div className="field">
                                                        {rFieldTypeIcon(field.type)}
                                                        <div className="name">{field?.visualNameFull}</div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                );
                            }),
                    ],
                    ({tooltipContent}) =>
                        StaticTooltipService2({
                            tooltipContent,
                            next,
                        })
                ),
        ],
        ({staticTooltip}) => {
            return (
                <div className="value-chip-input-88i value-chip-input">
                    <div className="label">{label}</div>

                    {/* {TextInputAutoGrow({
                placeholder: 'Prefix',
                state: scope(tileAction, [beforeField]),
            })} */}

                    <div className="content">
                        {/* {TextInputAutoGrow({
                    placeholder: 'Prefix',
                    state: scope(tileAction, [beforeField]),
                })} */}

                        {DebounceTextInput({
                            className: "prefix",
                            placeholder: "Prefix",
                            state: scope(tileAction, [beforeField]),
                        })}

                        {staticTooltip.render({
                            content: (props) => (
                                <div className="value-chip" {...props}>
                                    {`{ }`} Value
                                </div>
                            ),
                        })}

                        {!hideAfterField &&
                            DebounceTextInput({
                                className: "suffix",
                                placeholder: "Suffix",
                                state: scope(tileAction, [afterField]),
                            })}
                    </div>
                </div>
            );
        }
    );
