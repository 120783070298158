import React, {createElement as h} from "react";
import {cs} from "@common/react/chain-services";
import {sort, reverse} from "@common/utils/collections";
import {chain} from "@common/utils/fs";
import {UseState} from "@common/react/use-state";
import "./data-table.scss";
import {cx} from "emotion";
import {InfoIcon} from "@common/ui-components/icons/global-icons";
import {tooltipService3} from "../tooltip3/tooltip-service-3";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";

export const DataTable = ({
    list,
    useVerbScrollbar = true,
    sorting,
    initSorting,
    columns,
    rowKey,
    className,
    rowClassName,
    onUpdateList,
    hideHeader = false,
    style,
    maxHeight,
    onActionEntireRow,
    applyStyleOnParent,
}) =>
    cs(
        (_, next) =>
            !useVerbScrollbar ? (
                <div className="data-table-wrapper-f24 data-table-wrapper">{next()}</div>
            ) : (
                <VerbScrollbar
                    applyStyleOnParent={applyStyleOnParent}
                    style={style}
                    maxHeight={maxHeight || "calc(100vh - 78px - 54px)"}
                    className="data-table-wrapper-f24 data-table-wrapper"
                >
                    {next()}
                </VerbScrollbar>
            ),
        ["sorting", (_, next) => (sorting ? next(sorting) : h(UseState, {initValue: initSorting, next}))],
        tooltipService3({direction: "above"}),
        ({sorting, tooltip}) => {
            const sortedList = onUpdateList ? list : applySorting(list, {sorting: sorting.value, columns});

            return (
                <table className={"data-table data-table-f24 " + (className || "")}>
                    {!hideHeader && (
                        <thead>
                            <tr>
                                {columns?.map((column, i) => (
                                    <th
                                        key={i}
                                        className={cx(
                                            column?.headClassName,
                                            {
                                                "align-right": column.alignRight,
                                                "align-center": column.alignCenter,
                                            },
                                            (sorting.value?.columnIndex === i ? " sorting-active " : "") + (column.sortValue ? " sorting-enabled " : "")
                                        )}
                                        onClick={
                                            column.sortValue &&
                                            (() => {
                                                const sortValue =
                                                    sorting.value?.columnIndex !== i
                                                        ? {
                                                              columnIndex: i,
                                                              asc: false,
                                                          }
                                                        : {
                                                              columnIndex: i,
                                                              asc: !sorting.value.asc,
                                                          };
                                                sorting.onChange(sortValue);
                                                onUpdateList &&
                                                    onUpdateList(
                                                        applySorting(list, {
                                                            sorting: sortValue,
                                                            columns,
                                                        })
                                                    );
                                            })
                                        }
                                    >
                                        <div
                                            className={cx("th-info", {
                                                "has-sort": column.sortValue,
                                            })}
                                        >
                                            {column.labelF ? column.labelF() : column.label}
                                            &nbsp;
                                            {column.sortValue && (
                                                <div className="sort-value">
                                                    {sorting.value?.columnIndex !== i ? (
                                                        <>
                                                            <i className="far fa-angle-up"></i>
                                                            <i className="far fa-angle-down"></i>
                                                        </>
                                                    ) : sorting.value.asc ? (
                                                        <>
                                                            <i className="far fa-angle-up"></i>
                                                            <i />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <i />
                                                            <i className="far fa-angle-down"></i>
                                                        </>
                                                    )}
                                                </div>
                                            )}
                                            {column.info && (
                                                <div
                                                    {...{
                                                        className: "info-icon",
                                                        ...tooltip(() => column.info),
                                                    }}
                                                >
                                                    <InfoIcon />
                                                </div>
                                            )}
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                    )}

                    <tbody>
                        {sortedList.length > 0 ? (
                            sortedList?.map((item, i) => (
                                <>
                                    <tr
                                        className={cx(rowClassName ? rowClassName(item) : "")}
                                        key={rowKey ? rowKey(item) : i}
                                        {...(onActionEntireRow
                                            ? {
                                                  onClick: (e) => {
                                                      e.preventDefault();
                                                      e.stopPropagation();
                                                      onActionEntireRow(item);
                                                  },
                                              }
                                            : {})}
                                    >
                                        {columns.map((column, j) => (
                                            <td
                                                key={j}
                                                className={cx(column.className, {
                                                    "align-right": column.alignRight,
                                                    "align-center": column.alignCenter,
                                                    shy: column.shy || column?.shyF?.(item),
                                                })}
                                            >
                                                {(column.format || column.sortValue)(item)}
                                            </td>
                                        ))}
                                    </tr>
                                </>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={columns.length} align="center">
                                    <strong>No result</strong>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            );
        }
    );

export const applySorting = (list, {sorting, columns}) =>
    sorting == null
        ? list
        : chain(
              sort(list, (item) => columns[sorting.columnIndex].sortValue(item)),
              sorting.asc ? (list) => list : reverse
          );
