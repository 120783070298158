import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {Load2} from "@common/react/load2";
import {UseState} from "@common/react/use-state";
import {LoadingIndicator} from "@common/ui-components/loading-indicator/loading-indicator";
import {isDevSonPham} from "../../../../../../tools/dev/is-dev-sonpham";
import {loadDataFolders} from "../../add/add-data-source";
import {modifiedRawTables} from "../../add/default-data-source";
import {UploadFlatFile} from "../../add/steps/connection-settings/flat-file-step/upload-flat-file";
import {dataSourceTypes} from "../../common/data-source-type";
import {DsSyncSettingsLayout} from "../sync-settings/layout/ds-sync-settings-layout";
import {BannerClosable} from "./banner-closable/banner-closable";
import {FlatFileMapping} from "./mapping/flat-file-mapping";
import {validImportFlatFile} from "./flat-file-mapping-helpers";

const _tables = [
    {
        name: "Table1",
        tableReferenceName: "[status_by_day_2022_03_29t17_10_04].[1].[table1]",
        schemaName: "status_by_day_2022_03_29t17_10_04",
        schemaReferenceName: "[status_by_day_2022_03_29t17_10_04]",
        records: 5,
        approxSyncSizeMB: 0,
        ctAvailable: false,
        cdcAvailable: false,
        columns: [
            {
                name: "_id",
                columnReferenceName: "[status_by_day_2022_03_29t17_10_04].[1].[table1].[id]",
                dataType: "IDUUID",
                primaryKey: true,
                indexed: false,
                suggestedName: "Id",
                suggestedReferenceName: "[status_by_day_2022_03_29t17_10_04].[1].[status_by_day_2022_03_29t17_10_04].[id]",
            },
            {
                name: "Color",
                columnReferenceName: "[status_by_day_2022_03_29t17_10_04].[1].[table1].[color]",
                dataType: "Text",
                primaryKey: false,
                indexed: false,
                suggestedName: "Id",
                suggestedReferenceName: "[status_by_day_2022_03_29t17_10_04].[1].[status_by_day_2022_03_29t17_10_04].[id]",
            },
            {
                name: "Value",
                columnReferenceName: "[status_by_day_2022_03_29t17_10_04].[1].[table1].[value]",
                dataType: "Int",
                primaryKey: false,
                indexed: false,
                suggestedName: "Id",
                suggestedReferenceName: "[status_by_day_2022_03_29t17_10_04].[1].[status_by_day_2022_03_29t17_10_04].[id]",
            },
        ],
        isChildTable: false,
        suggestedName: "status_by_day_2022_03_29t17_10_04",
        suggestedReferenceName: "[status_by_day_2022_03_29t17_10_04]",
    },
    {
        name: "Table2",
        tableReferenceName: "[status_by_day_2022_03_29t17_10_04].[2].[table2]",
        schemaName: "status_by_day_2022_03_29t17_10_04",
        schemaReferenceName: "[status_by_day_2022_03_29t17_10_04]",
        records: 3,
        approxSyncSizeMB: 0,
        ctAvailable: false,
        cdcAvailable: false,
        columns: [
            {
                name: "_id",
                columnReferenceName: "[status_by_day_2022_03_29t17_10_04].[2].[table2].[id]",
                dataType: "IDUUID",
                primaryKey: true,
                indexed: false,
                suggestedName: "Id",
                suggestedReferenceName: "[status_by_day_2022_03_29t17_10_04].[1].[status_by_day_2022_03_29t17_10_04].[id]",
            },
            {
                name: "Size",
                columnReferenceName: "[status_by_day_2022_03_29t17_10_04].[2].[table2].[size]",
                dataType: "Text",
                primaryKey: false,
                indexed: false,
                suggestedName: "Id",
                suggestedReferenceName: "[status_by_day_2022_03_29t17_10_04].[1].[status_by_day_2022_03_29t17_10_04].[id]",
            },
            {
                name: "Count",
                columnReferenceName: "[status_by_day_2022_03_29t17_10_04].[2].[table2].[count]",
                dataType: "Int",
                primaryKey: false,
                indexed: false,
                suggestedName: "Id",
                suggestedReferenceName: "[status_by_day_2022_03_29t17_10_04].[1].[status_by_day_2022_03_29t17_10_04].[id]",
            },
        ],
        isChildTable: false,
        suggestedName: "status_by_day_2022_03_29t17_10_04",
        suggestedReferenceName: "[status_by_day_2022_03_29t17_10_04]",
    },
];

export const EditImportNewFlatFile = ({}) =>
    cs(
        consumeContext("routing"),
        consumeContext("apis"),
        consumeContext("toast"),
        (_, next) => loadDataFolders({next}),
        [
            "dataSource",
            ({apis, routing}, next) =>
                Load2({
                    fetch: () => apis.data.getDataSource(routing.params.dsId),
                    next,
                }),
        ],

        ({dataSource}, next) => (dataSource.loading ? LoadingIndicator({}) : next()),

        [
            "editingDs",
            ({dataSource}, next) =>
                UseState({
                    getInitValue: () => {
                        const mappingTables = modifiedRawTables({
                            tables: _tables,
                            type: dataSource.value.type,
                        });

                        return {
                            ...dataSource.value,
                            ...(isDevSonPham()
                                ? {
                                      tables: [
                                          ...dataSource.value.tables.map((t) => {
                                              const mappingTable = mappingTables.find((mt) => mt.name === t.name);
                                              return {
                                                  ...t,
                                                  mapsToName: mappingTable?.suggestedName || null,
                                                  mapsToSchema: mappingTable?.suggestedReferenceName || null,
                                                  columns: [
                                                      ...t.columns.map((c) => {
                                                          const mappingColumn = mappingTable?.columns.find((mc) => mc.name === c.name);
                                                          return {
                                                              ...c,
                                                              mapsToName: mappingColumn?.suggestedName || null,
                                                              mapsToSchema: mappingColumn?.suggestedReferenceName || null,
                                                          };
                                                      }),
                                                      ...(mappingTable ? mappingTable?.columns : []).filter((mc) => !t.columns.map((t) => t.name).includes(mc.name)),
                                                  ],
                                              };
                                          }),
                                          ...mappingTables
                                              .filter((mt) => !dataSource.value.tables.map((t) => t.name).includes(mt.name))
                                              .map((mt) => ({
                                                  ...mt,
                                                  mapsToName: mt?.suggestedName || null,
                                                  mapsToSchema: mt?.suggestedReferenceName || null,
                                                  columns: [
                                                      ...mt.columns.map((c) => ({
                                                          ...c,
                                                          mapsToName: c?.suggestedName || null,
                                                          mapsToSchema: c?.suggestedReferenceName || null,
                                                      })),
                                                  ],
                                              })),
                                      ],
                                  }
                                : {}),
                        };
                    },
                    next,
                }),
        ],
        ["attemptSubmit", (_, next) => UseState({next})],
        [
            "importView",
            ({dataSource, apis, toast, editingDs, attemptSubmit}, next) => {
                return dataSource.loading
                    ? next({
                          render: () => LoadingIndicator({}),
                      })
                    : cs(
                          [
                              "imported",
                              (_, next) =>
                                  UseState({
                                      initValue: isDevSonPham() ? true : false,
                                      next,
                                  }),
                          ],
                          [
                              "dsApi",
                              ({imported}, next) => {
                                  return cs((_) =>
                                      next({
                                          testConnection: async ({submitConnectionDetails, connectionDetails}, dsInfo = {}) => {
                                              try {
                                                  const tables = await apis.data.getTablesColumns({
                                                      dataSourceID: dsInfo.id,
                                                      data: submitConnectionDetails,
                                                  });

                                                  if (tables) {
                                                      const mappingTables = modifiedRawTables({
                                                          tables,
                                                          type: dataSource.value.type,
                                                      });

                                                      const newDs = (ds) => ({
                                                          ...dsInfo,
                                                          connectionDetails,
                                                          tables: [
                                                              ...ds.tables.map((t) => {
                                                                  const mappingTable = mappingTables.find((mt) => mt.name === t.name);
                                                                  return {
                                                                      ...t,
                                                                      mapsToName: mappingTable?.suggestedName || null,
                                                                      mapsToSchema: mappingTable?.suggestedReferenceName || null,
                                                                      columns: [
                                                                          ...t.columns.map((c) => {
                                                                              const mappingColumn = mappingTable?.columns.find((mc) => mc.name === c.name);
                                                                              return {
                                                                                  ...c,
                                                                                  mapsToName: mappingColumn?.suggestedName || null,
                                                                                  mapsToSchema: mappingColumn?.suggestedReferenceName || null,
                                                                              };
                                                                          }),
                                                                          ...(mappingTable ? mappingTable.columns : []).filter(
                                                                              (mc) => !t.columns.map((t) => t.name).includes(mc.name)
                                                                          ),
                                                                      ],
                                                                  };
                                                              }),
                                                              ...mappingTables
                                                                  .filter((mt) => !dataSource.value.tables.map((t) => t.name).includes(mt.name))
                                                                  .map((mt) => ({
                                                                      ...mt,
                                                                      mapsToName: mt?.suggestedName,
                                                                      mapsToSchema: mt?.suggestedReferenceName,
                                                                      columns: [
                                                                          ...mt.columns.map((c) => ({
                                                                              ...c,
                                                                              mapsToName: c?.suggestedName || null,
                                                                              mapsToSchema: c?.suggestedReferenceName || null,
                                                                          })),
                                                                      ],
                                                                  })),
                                                          ],
                                                          mappingTables,
                                                      });
                                                      editingDs.change((ds) => ({
                                                          ...ds,
                                                          ...newDs(ds),
                                                      }));
                                                      imported.onChange(true);
                                                      // lastDs.change((ds) => ({ ...ds, ...newDs(ds) }))
                                                  }
                                              } catch (e) {
                                                  toast.show(e.message, {
                                                      isError: true,
                                                  });
                                              } finally {
                                              }
                                          },
                                      })
                                  );
                              },
                          ],
                          [
                              "uploadSection",
                              ({dsApi}, next) =>
                                  UploadFlatFile({
                                      title: "Import New Version",
                                      data: dataSource,
                                      dsApi,
                                      next,
                                  }),
                          ],
                          [
                              "mappingImport",
                              (_, next) =>
                                  next({
                                      render: () =>
                                          FlatFileMapping({
                                              editingDs,
                                              originalDs: dataSource,
                                              attemptSubmit,
                                          }),
                                  }),
                          ],
                          ({uploadSection, mappingImport, imported}) =>
                              next({
                                  imported: imported.value,
                                  render: () => (imported.value ? mappingImport.render() : <div className="flat-file-import-configuration-88y">{uploadSection.render()}</div>),
                              })
                      );
            },
        ],
        ["loading", (_, next) => UseState({next})],

        ({dataSource, importView, editingDs, loading, toast, apis, routing, attemptSubmit}) => {
            return DsSyncSettingsLayout({
                rightButtons: importView?.imported
                    ? [
                          {
                              label: "Save & Done",
                              btnType: "primary",
                              // disabled: !renderConnectionSettingsView.valid || loading.value,
                              iconRight: loading.value && <i className="fad fa-spinner-third fa-spin" />,
                              onClick: async () => {
                                  if (!validImportFlatFile(editingDs.value)) {
                                      attemptSubmit.onChange(true);
                                      return;
                                  }

                                  try {
                                      loading.onChange(true);
                                      await apis.data.upsertDataSource(editingDs.value);
                                      routing.goto("edit-data-source", {
                                          envId: routing.params.envId,
                                          dsId: routing.params.dsId,
                                      });
                                  } catch (e) {
                                      toast.show(e.message, {isError: true});
                                  } finally {
                                      loading.onChange(false);
                                  }
                              },
                          },
                      ]
                    : [
                          {
                              label: "Cancel",
                              btnType: "secondary",
                              onClick: () =>
                                  routing.goto("edit-data-source", {
                                      envId: routing.params.envId,
                                      dsId: routing.params.dsId,
                                      type: dataSourceTypes.FILE,
                                  }),
                          },
                      ],
                mode: "Import Data",
                editing: {
                    label: dataSource.value?.name,
                },
                content: <>{importView.render()}</>,
            });
        }
    );
