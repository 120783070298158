import "./left-panel.scss";

import React from "react";

import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";

import {Button} from "@common/form/buttons/button/button";

import {RoutingParam} from "../../../../common/routing/routing-param";
import {LeftPanelTabs} from "../../../../common/left-panel-tabs/left-panel-tabs";

import {cGetModelColumn} from "../../../edit/tabs/data/model-data-tab";
import {DeleteTransformationDialog} from "../../../edit/tabs/left-panel-overrides/delete-transformation-dialog/delete-transformation-dialog";

import {isAggregatedMeasure, isDataView} from "../../common/transformation-utils";

import {BuildTab} from "./build-tab/build-tab";
import {DeleteDataViewDialog} from "./delete-data-view-dialog";

export const LeftPanel = ({transformation, model, startDraggingNewStep, dataSources, interactions}) =>
    cs(
        consumeContext("routing"),
        ({}, next) =>
            cGetModelColumn({
                model: model.value,
                name: "getModelColumn2",
                dataSources,
                next,
            }),
        [
            "selectedTab",
            (_, next) =>
                RoutingParam({
                    param: "tab",
                    defaultValue: "build",
                    next,
                }),
        ],
        [
            "deleteTransformationDialog",
            ({routing}, next) =>
                DeleteTransformationDialog({
                    model,
                    next,
                    onDone: () => {
                        routing.goto("edit-model", {
                            modelId: routing.params.modelId,
                        });
                        model.reload();
                    },
                }),
        ],
        [
            "removeTableDialog",
            ({routing}, next) =>
                DeleteDataViewDialog({
                    model,
                    getModelColumnProviderName: "getModelColumn2",
                    next,
                    onDone: () => {
                        routing.goto("edit-model", {
                            modelId: routing.params.modelId,
                        });
                    },
                }),
        ],
        [
            "onDelete",
            ({deleteTransformationDialog, removeTableDialog}, next) =>
                next(async () => {
                    if (!transformation.value) {
                        return;
                    }

                    const isView = isDataView(transformation.value);
                    let currentTable = model.value.tables.find((t) =>
                        isView ? t.transformationID == transformation.value.id : t.id == transformation.value.modelTableID
                    );

                    if (!currentTable) {
                        const newModel = await model.reload();
                        currentTable = newModel.tables.find((t) =>
                            isView ? t.transformationID == transformation.value.id : t.id == transformation.value.modelTableID
                        );
                    }

                    if (isView) {
                        removeTableDialog.show({table: currentTable});
                    } else {
                        deleteTransformationDialog.show({
                            column: currentTable.columns.find(
                                (c) =>
                                    ["CalculatedModelColumn", "AggregatedMeasureModelColumn"].indexOf(c.$type) > -1 &&
                                    c.name == transformation.value.name
                            ),
                        });
                    }
                }),
        ],
        [
            "tabs",
            ({onDelete}, next) =>
                next([
                    // {
                    //     id: "view",
                    //     label: transformation.value.$type === "ViewTransformation" ? "View" : "Column",
                    //     render: () => "View",
                    // },
                    {
                        id: "build",
                        label: "Build",
                        render: () =>
                            BuildTab({
                                transformation,
                                model: model.value,
                                startDraggingNewStep,
                                interactions,
                                controlComp: () =>
                                    transformation.value && (
                                        <Button size="medium" btnType="danger" className="btn-remove" onClick={() => onDelete()}>
                                            Delete{" "}
                                            {isDataView(transformation.value)
                                                ? "Data View"
                                                : isAggregatedMeasure(transformation.value)
                                                ? "Transformation"
                                                : "Column"}
                                        </Button>
                                    ),
                            }),
                    },
                ]),
        ],
        ({selectedTab, tabs}) => {
            return (
                <div className="transformation-left-panel-4ys">
                    {LeftPanelTabs({
                        tabs,
                        selected: selectedTab,
                        hideHeaderIfOneTab: true,
                    })}
                </div>
            );
        }
    );
