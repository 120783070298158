exports.singleKPITemplate = {
    $type: "SingleKPITile",
    style: {
        $type: "SingleKPITileStyle",
        template: "Standard",
        actualValueShown: true,
        autoRound: true,
        compareValueShown: true,
        compareValueMethod: "Percent",
        indicatorShown: true,
        positiveChangeGood: true,
        previousPeriodShown: true,

        title: {
            show: true,
            titleLocation: "Above",
        },
    },
};
