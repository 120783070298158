const roundTo = (number, digits) => {
    const factor = Math.pow(10, digits);
    return Math.round(number * factor) / factor;
};
exports.roundTo = roundTo;

const roundToDown = (number, digits) => {
    const factor = Math.pow(10, digits);
    return Math.floor(number * factor) / factor;
};
exports.roundToDown = roundToDown;
