import "./button-icon.scss";

import React from "react";
import {cx} from "emotion";

import {cs} from "../../../react/chain-services";
import {tooltipService4} from "../../../../web-client/src/routes/common/tooltip3/tooltip-service-3";

// Preview: https://www.figma.com/file/lLyR8h0WfMXZsnVjeXKtgJ/Components?node-id=2505%3A14305
//size: "large" | "medium" | "medium2" | "small",
//btnType: "primary" | "secondary" | "border" | "danger" | "ghost" | "ghost-no-border",

export const ButtonIcon = ({icon, disabled, size = "medium", btnType = "primary", className, ...otherProps}) => (
    <button className={cx("verb-button-icon", `type-${btnType}`, className, `size-${size}`)} disabled={disabled} {...otherProps}>
        {icon}
    </button>
);

export const ButtonIconWithTooltip = ({tooltipClassName, content = "", direction = "below", ...others}) =>
    cs(
        [
            "tooltip",
            ({}, next) =>
                content
                    ? tooltipService4({
                          direction,
                          className: tooltipClassName,
                          next,
                      })
                    : next(),
        ],
        ({tooltip}) => ButtonIcon({...others, ...(tooltip?.(() => content) ?? {})})
    );
