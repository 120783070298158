import React from "react";
import {cs} from "@common/react/chain-services";
import {DialogService} from "../../../../common/dialog/dialog-service";
import "./embed-dialog.scss";
import {cx} from "emotion";
import {EmbedCode} from "../share-dialog/embed-code/embed-code";
import {consumeContext} from "@common/react/context";
import {PublishCollection} from "./publish-collection/publish-collection";
import {UseState} from "@common/react/use-state";
import {Load2} from "@common/react/load2";
import {isEmpty} from "@common/utils/objects";
import {EmailInstructionsDialog} from "../email-instructions-dialog/email-instructions-dialog";
import {LoadingIndicator} from "@common/ui-components/loading-indicator/loading-indicator";
import {Button} from "../../../../../../../common/form/buttons/button/button";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";
import {ObserveDomSize} from "@common/react/observe-dom-size";

export const EmbedDialog = ({collection, next: rootNext, onClose, hidePublishProcess}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    registryRender: true,
                    render: ({resolve}) => {
                        return {
                            title: `Embed ${collection.value.name}`,
                            width: 620,
                            content: next({
                                resolve,
                            }),
                        };
                    },
                    className: "embed-dialog-9ew",
                    next: rootNext,
                }),
        ],
        consumeContext("apis"),
        consumeContext("publishProcess"),
        [
            "emailInstructionsDialog",
            ({modal}, next) =>
                EmailInstructionsDialog({
                    next,
                    onClose: () => {
                        modal.resolve();
                        onClose();
                    },
                }),
        ],
        [
            "publishedData",
            ({apis}, next) =>
                Load2({
                    fetch: () =>
                        apis.collection.getPublishInfo({
                            collectionId: collection.value.id,
                        }),
                    next,
                }),
        ],
        [
            "containerRef",
            ({}, next) =>
                ObserveDomSize({
                    next,
                    delay: 50,
                    invokeOnStart: true,
                    ignoreChange: (newSize, oldSize) => newSize.height === oldSize.height,
                    onChange: () => {
                        let height = null;
                        const lineElem = document.querySelector(".line-with-custom-height-f34");
                        const embedCodesContainer = document.querySelector(".embed-code-42w");
                        if (embedCodesContainer) {
                            const items = embedCodesContainer.querySelectorAll(".collapsible-as3");
                            const {height: lastItemHeight} = getComputedStyle(items[items.length - 1]);
                            height = `calc(100% - 32px - ${lastItemHeight})`;
                            lineElem.style.height = height;
                        }
                    },
                }),
        ],
        [
            "embedCodeComp",
            ({publishedData}, next) => {
                return !isEmpty(publishedData.value) && publishedData.value?.publishedOn != null
                    ? EmbedCode({collection, onClose: () => {}, next})
                    : next(null);
            },
        ],
        ({containerRef, publishProcess, publishedData, emailInstructionsDialog, embedCodeComp, modal}) => {
            if (publishedData.loading) return <VerbScrollbar className="embed-dialog-content-9io">{LoadingIndicator({})}</VerbScrollbar>;

            return (
                <>
                    <VerbScrollbar className="embed-dialog-content-9io">
                        <div className="embed-steps" ref={containerRef.ref}>
                            {cs(() => {
                                const {publishProcessObserver} = publishProcess || {
                                    publishProcessObserver: null,
                                };
                                return (
                                    <div className="publish-step">
                                        <div className="line" />
                                        <div className="publish-step-icon">
                                            {publishProcessObserver?.loading ? (
                                                <div className="loading">
                                                    <i className="fad fa-spinner-third fa-spin" />
                                                </div>
                                            ) : publishedData.value.publishedOn ? (
                                                <div className="published">
                                                    <span className="material-icons">check</span>
                                                </div>
                                            ) : (
                                                (isEmpty(publishedData.value) || publishedData.value?.publishedOn == null) && (
                                                    <div className="unpublished">
                                                        <span className="material-icons">warning</span>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                        <div className="publish-step-content">
                                            {PublishCollection({
                                                hidePublishProcess,
                                                publishing: publishProcessObserver?.loading,
                                                data: publishedData.value,
                                            })}
                                        </div>
                                    </div>
                                );
                            })}

                            <div className="embed-step">
                                {embedCodeComp?.template && <div className="line line-with-custom-height-f34" />}

                                <div
                                    className={cx("embed-step-icon", {
                                        "not-available": isEmpty(publishedData.value) || publishedData.value?.publishedOn == null,
                                    })}
                                >
                                    <span className="material-icons">code</span>
                                </div>

                                <div className="embed-step-content">
                                    <div
                                        className={cx("label", {
                                            "not-available": isEmpty(publishedData.value) || publishedData.value?.publishedOn == null,
                                        })}
                                    >
                                        Snippets
                                    </div>
                                    {embedCodeComp?.tab?.render?.()}
                                </div>
                            </div>
                        </div>
                    </VerbScrollbar>

                    <div className="embed-dialog-buttons">
                        <div className="space" />
                        <div>
                            {embedCodeComp && embedCodeComp.template && (
                                <Button
                                    btnType="border"
                                    onClick={() =>
                                        emailInstructionsDialog.show({
                                            template: embedCodeComp.template,
                                        })
                                    }
                                >
                                    E-mail Instructions
                                </Button>
                            )}
                            <Button onClick={() => modal.resolve()}>Done</Button>
                        </div>
                    </div>
                </>
            );
        }
    );
