import {cs} from "@common/react/chain-services";
import {fragments} from "@common/react/fragments";
import {UseState} from "@common/react/use-state";
import {Static2} from "@common/react/static-2";
import {Invoke} from "@common/react/invoke";
import {OnUnmounted} from "@common/react/on-unmounted";
import {Interval} from "@common/react/interval";

export const WatchColumnInteraction = ({action, disabled, next}) =>
    cs(["state", (_, next) => UseState({next})], ({state}) =>
        fragments(
            next({
                containerRef: !state.value ? (ref) => ref && state.onChange({...state.value, containerRef: ref}) : null,
            }),
            state.value?.containerRef &&
                !disabled &&
                fragments(
                    cs(
                        ["dom", (_, next) => UseState({next})],
                        ["scrollListener", (_, next) => Static2({next})],
                        ["clickListener", (_, next) => Static2({next})],
                        ({scrollListener, clickListener, dom}) => {
                            const container = state.value.containerRef;
                            return fragments(
                                Invoke({
                                    props: {disabled, dom},
                                    fn: ({getLatestProps}) => {
                                        const listener = () => {
                                            const {disabled, dom} = getLatestProps();
                                            if (disabled) {
                                                return;
                                            }
                                            dom.value && action(getEvent(dom.value));
                                        };

                                        scrollListener.set(listener);

                                        clickListener.set((e) => {
                                            if (
                                                // e.target.className?.includes?.("column-line")
                                                e.target.className?.includes?.("truncating-tooltip-wrapper")
                                            ) {
                                                dom.onChange(e.target);
                                            }
                                            listener();
                                        });

                                        container.addEventListener("scroll", scrollListener.get());
                                        container.addEventListener("click", clickListener.get());
                                    },
                                }),
                                OnUnmounted({
                                    action: () => {
                                        if (scrollListener.get()) {
                                            container.removeEventListener("scroll", scrollListener.get());
                                        }

                                        if (clickListener.get()) {
                                            container.removeEventListener("click", clickListener.get());
                                        }
                                    },
                                }),
                                Interval({
                                    fn: () => {
                                        const dom1 = dom.value;
                                        dom1 && action(getEvent(dom1));
                                    },
                                    delay: 500,
                                })
                            );
                        }
                    )
                )
        )
    );

const getEvent = (dom) => {
    const {x, y, width, height} = dom.getBoundingClientRect();
    return {x, y, width, height};
};
