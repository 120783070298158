import {getReferenceSeriesStyleOptions} from "@common/ui-components/charts/reference-lines/indicator-series";
import {getDataLabelsStyle} from "@common/ui-components/charts/factory/data-labels";
import {aggregateTypes} from "@common/ui-components/charts/reference-lines/constant-or-aggregate-value-series";

// mimic series carrying plot line options

export const generateConstantSeries = ({tile, theme, config, dataPoints}) => {
    const axisField = config.measureGroupID === tile.yAxisField.id ? tile.yAxisField : tile.xAxisField;
    const name = config.label || `Constant (${config.value}, ${axisField.displayName})`;
    const plotLineOptions = getPlotLineOptions({
        tile, theme, config, name,
        value: config.value,
    });

    return [{
        type: "line",
        id: config.id,
        customType: config.$type,
        customStack: config.measureGroupID,
        name,
        plotLineOptions,
        color: plotLineOptions.color,
        dataLabelShown: config.dataLabelShown,
        marker: {
            enabled: false
        },
    }]
};

export const generateAggregationSeries = ({tile, theme, config, dataPoints}) => {
    const axisField = config.measureGroupID === tile.yAxisField.id ? tile.yAxisField : tile.xAxisField;
    const valueProp = config.measureGroupID === tile.yAxisField.id ? "y" : "x";

    const data = dataPoints.map((d) => d[valueProp]);
    const value = aggregateTypes[config.aggregation](data, config.value);
    const name = config.label ||
        (config.value ? `${config.type} (${config.value}, ${axisField.displayName})` : `${config.type} (${axisField.displayName})`);
    const plotLineOptions = getPlotLineOptions({tile, theme, config, name, value});

    return [{
        type: "line",
        id: config.id,
        customType: config.$type,
        customStack: config.measureGroupID,
        name,
        plotLineOptions,
        color: plotLineOptions.color,
        dataLabelShown: config.dataLabelShown,
        marker: {
            enabled: false
        },
    }]
};

// the plot lines will be added to measure axes options for displaying
const getPlotLineOptions = ({tile, theme, config, name, value}) => {
    const seriesStyle = getReferenceSeriesStyleOptions({config, theme});
    const dataLabelStyle = getDataLabelsStyle({dataLabelsConfig: tile.style.dataLabels, theme}).style;
    const isMeasureAxisOpposite = tile.style.yAxis.location.includes("Right");

    return {
        id: config.id,
        value,
        name,
        ...seriesStyle,
        ...(config.labelShown && {
            label: {
                text: name,
                style: {
                    ...dataLabelStyle,
                    color: seriesStyle.color,
                },
                align: isMeasureAxisOpposite ? "right" : "left",
                // y: -7,
                // x: isMeasureAxisOpposite ? -4 : 4,
            }
        }),
        // zIndex: 4,
    }
}
