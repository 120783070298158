const {cGetFontSize} = require("../../common/style-map/font-size");
const {lighten} = require("../../../../utils/color-util");
const {getMax, getColors, isCompareEnabled} = require("./gauge-helpers");

const yAxisOptions = ({tile, theme, data, formatters}) => {
    const {style} = tile;

    const getFontSize = cGetFontSize(theme.general.canvas.fontSize, theme);

    const {value, previousValue, target, previousTarget} = data;

    const {max} = getMax({data, style});

    const {emptyColor, dataColor, compareDataColor} = getColors({
        tile,
        theme,
    });

    const valuesPlotLines = [
        {
            value: target,
            color: value < target ? dataColor : lighten(emptyColor, 50),
            isTargetValue: true,
        },
        // take advantage of this to render value data label
        {
            value,
            color: dataColor,
            width: 0,
        },
    ].map((vpl) => ({
        ...vpl,
        noLabel: value === max || value === 0 || !style.valueLabelsShown,
    }));

    const compareValuesPlotLines = [
        {
            value: previousTarget,
            color: previousValue < previousTarget ? compareDataColor : lighten(emptyColor, 50),
            noLabel: true,
        },
    ];

    let fontSize = getFontSize({
        group: theme.dataVisualization.fonts.dataLabelFontSize,
    });

    const labelStyle = {
        fontSize,
        color: theme.dataVisualization.fonts.fontColorRGB || theme.general.canvas.fontColorRGB,
        // fontWeight: "normal",
        fontFamily: `"${theme.dataVisualization.fonts.fontFamily || theme.general.canvas.fontFamily}", sans-serif`,
    };

    const getPlotLine = ({value, isTargetValue, color, noLabel, width}) => ({
        value,
        color,
        zIndex: 4,
        width: width ?? 3,
        dashStyle: "ShortDot",
        label: noLabel
            ? {
                  enabled: false,
              }
            : {
                  text: isTargetValue ? formatters.targetValueFormatter(value) : formatters.valueFormatter(value),
                  style: labelStyle,
              },
    });

    const commonAttrs = {
        min: 0,
        max,

        title: {
            text: null,
        },

        lineWidth: 0,
        lineColor: emptyColor,
        states: {
            hover: {
                lineWidthPlus: 0,
            },
        },

        minorTickInterval: null, // to hide other ticks
        tickLength: 0, // hide ticks
    };

    return {
        yAxis: [
            {
                pane: 0,
                ...commonAttrs,

                tickPositions: style.minMaxLabelsShown ? [0, max] : [],
                labels: {
                    // y: getFontSize({group: theme.dataVisualization.fonts.dataLabelFontSize}) || 13,
                    enabled: style.minMaxLabelsShown,
                    color: theme.dataVisualization.fonts.fontColorRGB || theme.general.canvas.fontColorRGB,
                    align: "auto",
                    style: labelStyle,
                    formatter: function () {
                        return formatters.valueFormatter(this.value);
                        // return addCommas(this.value);
                    },
                    allowOverlap: true,
                },

                plotLines: valuesPlotLines.map(getPlotLine),
            },
            isCompareEnabled({style, data}) && {
                zIndex: 3,
                pane: 1,

                ...commonAttrs,

                labels: {
                    enabled: false,
                },

                plotLines: compareValuesPlotLines.map(getPlotLine),
            },
        ].filter((v) => v),
    };
};
exports.yAxisOptions = yAxisOptions;

// const addCommas = (num1) => {
//     let num = isNaN(num1) ? num1 : num1.toString();
//     let ret = "";
//     for (let i = 0; i < num.length; i++) {
//         if (i > 0 && i % 3 === 0) {
//             ret = "," + ret;
//         }
//         ret = num[num.length - 1 - i] + ret;
//     }
//     return ret;
// };
