// const {initTooltipConnectorHelper} = require("../../factory/tooltip/connector-helpers/tooltip-connector-helper");
const {getThemeBorderRadiusOptions} = require("../../bar-series-processors/get-border-radius-options");
const {getDataLabelsOptions} = require("../../factory/data-labels");
const {DATA_BOOST_THRESHOLD, DATA_TURBO_THRESHOLD} = require("../../common/axis-chart-boost-turbo-constants");

const getPlotOptions = ({tile, theme, isCompare, formatters, chartElementTooltip, onClickPoint}) => {
    // const tooltipConnectorHelper = theme.dataVisualization.toolTipsAndLegends.indicatorLineShown && initTooltipConnectorHelper({tooltipStyle: theme.dataVisualization.toolTipsAndLegends});

    return {
        column: {
            dataLabels: getDataLabelsOptions({
                tile,
                theme,
                formatters,
                chartElementTooltip,
            }),
            ...(theme.dataVisualization.axisCharts.barChartCornerRadius > 0 && getThemeBorderRadiusOptions(theme.dataVisualization.axisCharts)),
            ...(isCompare &&
                ["Stacked", "HundredPercent"].includes(tile.style.displayType) && {
                    grouping: false, // to stick primary column and compare column together
                }),
            borderWidth: 0,
            // groupPadding: 0.1,
            // maxPointWidth: 90,
            // stacking: "normal",
            ...(() => {
                const displayType = tile.style.displayType;

                if (!displayType || displayType === "Classic") {
                    return {};
                }

                return displayType === "Stacked"
                    ? {
                          stacking: "normal",
                          // maxPointWidth: 25,
                      }
                    : {
                          stacking: "percent",
                          // maxPointWidth: 25,
                      };
            })(),
            getExtremesFromAll: true, // to prevent measurement axis from scaling on scroll, get extremes from all first
        },
        series: {
            cursor: onClickPoint ? "pointer" : "default",
            boostThreshold: DATA_BOOST_THRESHOLD,
            turboThreshold: DATA_TURBO_THRESHOLD,
            point: {
                events: {
                    // ...(tooltipConnectorHelper && {
                    //     mouseOver: function() {
                    //         setTimeout(() => tooltipConnectorHelper.render({chart: this.series.chart}), 0)
                    //     },
                    //     mouseOut: function() {
                    //         setTimeout(() => tooltipConnectorHelper.remove(), 0)
                    //     }
                    // }),
                    click: function ({x, y, point}) {
                        onClickPoint?.({x, y, point, chart: this.series.chart});
                    },
                },
            },
        },
    };
};
exports.getPlotOptions = getPlotOptions;
