import React from "react";
import "./api-collection-share-dialog.scss";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {DialogService} from "../../../../common/dialog/dialog-service";
import {Load2} from "@common/react/load2";
import {Load} from "@common/react/load";
import {Button} from "../../../../../../../common/form/buttons/button/button";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";
import {sort} from "@common/utils/collections";
import {DeleteIcon} from "../../../../common/icons/trash-icon";
import {ManageAccessKeyDialog} from "../../../../dashboard/env/settings/data-access-key/manage-key/manage-access-key-dialog";
import {settingKey} from "../../../../dashboard/env/settings/settings-constants";
import {AddExistingKeyDialog} from "./add-existing-key-dialog";

export const ApiCollectionShareDialog = ({collectionOverview, next: rootNext}) =>
    cs(
        consumeContext("collection"),
        consumeContext("apis"),
        [
            "modal",
            ({collection}, next) =>
                DialogService({
                    render: ({resolve, args: {$type}}) => ({
                        title: "Share " + (collection?.value?.name || collectionOverview?.name),
                        width: 560,
                        content: next({
                            $type,
                            resolve,
                        }),
                    }),
                    next: rootNext,
                }),
        ],
        [
            "remoteCollection",
            ({collection, apis}, next) =>
                !collection.value
                    ? Load2({
                          fetch: () => apis.collection.getCollection(collectionOverview.id),
                          next,
                      })
                    : next(collection),
        ],
        ({modal, remoteCollection, apis}) =>
            cs(
                consumeContext("authEndpoint"),
                consumeContext("routing"),
                ({authEndpoint, routing}, next) => {
                    const rContent = ({content, btn}) => (
                        <div className="share-collection-dialog-8sx">
                            <VerbDialogBodyScrollbar>{content}</VerbDialogBodyScrollbar>
                            <div className="buttons">
                                <Button btnType="secondary" onClick={() => modal.resolve()}>
                                    Close
                                </Button>

                                {btn}
                            </div>
                        </div>
                    );

                    if (authEndpoint.loading || remoteCollection.loading) {
                        return rContent({content: "Loading..."});
                    } else if (Object.keys(authEndpoint.value.find((e) => e.$type === "EndpointAuthConfig")?.resultMapping || {}).length === 0) {
                        return rContent({
                            content: (
                                <div className="message" style={{fontSize: 14}}>
                                    This environment's Authentication Method is not configured properly. Please ensure the Authentication Method is properly configured and try
                                    again.
                                </div>
                            ),
                            btn: (
                                <Button
                                    onClick={() => {
                                        routing.goto("dashboard", {
                                            tab: "settings",
                                        });
                                        modal.resolve();
                                    }}
                                >
                                    Go to Auth Method
                                </Button>
                            ),
                        });
                    }

                    return next();
                },
                [
                    "accessKeys",
                    ({routing}, next) =>
                        Load2({
                            fetch: () => apis.accessKey.getAccessApiKeys(),
                            next,
                        }),
                ],
                [
                    "folders",
                    ({routing}, next) =>
                        Load2({
                            _key: routing.params?.envId,
                            fetch: () => apis.accessKey.getFolders(),
                            next,
                        }),
                ],
                ["manageKeyDialog", ({folders}, next) => ManageAccessKeyDialog({next, folders: folders.value})],
                ["addExistingKeyDialog", ({folders}, next) => AddExistingKeyDialog({next})],
                [
                    "data",
                    (_, next) =>
                        Load({
                            fetch: () =>
                                apis.collection.getPublishInfo({
                                    collectionId: remoteCollection?.value?.id,
                                }),
                            next,
                        }),
                ],
                [
                    "apiCollections",
                    ({routing}, next) =>
                        cs(
                            [
                                "remoteCollections",
                                (_, next) =>
                                    Load2({
                                        fetch: () => apis.collection.getApiCollectionsGroup(),
                                        next,
                                    }),
                            ],
                            ({remoteCollections}) =>
                                next({
                                    ...remoteCollections,
                                    value: remoteCollections.value?.filter((r) => r.id),
                                })
                        ),
                ],
                ["endpointAuthConfig", ({authEndpoint}, next) => next(authEndpoint.value.find((t) => t.$type === "EndpointAuthConfig") || {})],
                [
                    "defaultUserValues",
                    ({endpointAuthConfig}, next) => {
                        let ret = {};
                        if (endpointAuthConfig) {
                            for (let key in endpointAuthConfig.resultMapping) {
                                const mappingType = endpointAuthConfig.resultMapping[key].mappingType;
                                ret[key] = {
                                    $type: mappingType == "SingleValue" ? "UserStringValue" : "UserArrayValue",
                                    ...(mappingType == "SingleValue"
                                        ? {value: ""}
                                        : {
                                              values: [
                                                  {
                                                      $type: "UserStringValue",
                                                      value: "",
                                                  },
                                              ],
                                          }),
                                };
                            }
                            return next(ret);
                        }
                        return next(null);
                    },
                ],
                ({data}, next) =>
                    !data?.publishedOn ? (
                        <div className="api-collection-share-dialog-a32">
                            <VerbDialogBodyScrollbar>
                                {!data ? (
                                    "Loading..."
                                ) : (
                                    <div className="messages">
                                        <div className="message">This collection has never been published before.</div>
                                        <div className="message">In order to share and embed this collection you must first publish the collection.</div>
                                    </div>
                                )}
                            </VerbDialogBodyScrollbar>
                        </div>
                    ) : (
                        next()
                    ),
                ({accessKeys, manageKeyDialog, apiCollections, defaultUserValues, addExistingKeyDialog}) => {
                    const preSelectCollection = (apiCollections.value || []).find((c) => c.id == remoteCollection.value.versionGroupID)?.id;
                    const list = preSelectCollection ? (accessKeys.value || []).filter((a) => a.apiCollections.indexOf(preSelectCollection) > -1) : [];
                    return (
                        <div className="api-collection-share-dialog-a32">
                            {!accessKeys.value || !apiCollections.value ? (
                                <VerbDialogBodyScrollbar>Loading...</VerbDialogBodyScrollbar>
                            ) : (
                                <>
                                    <div className="action-group">
                                        <div className="action-group-wrapper">
                                            <Button
                                                size="large"
                                                onClick={async () => {
                                                    const resp = await addExistingKeyDialog.show({
                                                        accessKeys: accessKeys.value.filter((a) => a.apiCollections.indexOf(preSelectCollection) == -1),
                                                        preSelectCollection,
                                                    });

                                                    if (resp) {
                                                        accessKeys.change((c) => c.map((c) => (c.id == resp.id ? resp : c)));
                                                        modal.resolve(resp);
                                                    }
                                                }}
                                            >
                                                Add Existing Key
                                            </Button>

                                            <Button
                                                size="large"
                                                btnType="secondary"
                                                onClick={async () => {
                                                    const resp = await manageKeyDialog.show({
                                                        $type: settingKey.DATA_ACCESS,
                                                        accessKey: {
                                                            userValues: defaultUserValues,
                                                            apiCollections: [preSelectCollection],
                                                        },
                                                        collections: apiCollections,
                                                    });

                                                    if (resp) {
                                                        accessKeys.onChange(accessKeys.value.concat(resp.accessKey));
                                                        if (resp.share) {
                                                            modal.resolve(resp.accessKey);
                                                        }
                                                    }
                                                }}
                                            >
                                                Create New Key
                                            </Button>
                                        </div>
                                    </div>

                                    <div className="access-keys-container">
                                        <div className="access-header-text">Keys With Access</div>

                                        <VerbScrollbar maxHeight="250px" className="access-list">
                                            {sort(list, (l) => l.name).map((item, index) => (
                                                <div className="access-key" key={index}>
                                                    {item.name}

                                                    <Button
                                                        btnType="border"
                                                        size="small"
                                                        className="remove-btn"
                                                        iconLeft={<DeleteIcon />}
                                                        onClick={() => {
                                                            const updated = {
                                                                ...item,
                                                                apiCollections: item.apiCollections.filter((a) => preSelectCollection != a),
                                                            };
                                                            apis.accessKey.upsertAccessApiKey(updated);
                                                            accessKeys.change((c) => c.map((c) => (c.id == item.id ? updated : c)));
                                                        }}
                                                    >
                                                        Remove Access
                                                    </Button>
                                                </div>
                                            ))}
                                        </VerbScrollbar>
                                    </div>
                                </>
                            )}
                        </div>
                    );
                }
            )
    );
