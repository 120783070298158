import "./delete-transformation-dialog.scss";

import * as React from "react";

import {Load} from "@common/react/load";
import {joinJsx} from "@common/react/join-jsx";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {consumeContext} from "@common/react/context";

import {Button} from "@common/form/buttons/button/button";

import {loadCollections} from "../../common/load-collections";
import {DialogService} from "../../../../../common/dialog/dialog-service";
import {keyToArray} from "../../../../../dashboard/env/settings/data-access-key/manage-key/manage-access-key-dialog";

export const DeleteTransformationDialog = ({next: rootNext, model, onDone}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    render: ({resolve, args: {column}}) => ({
                        title: "Delete Column?",
                        width: 480,
                        content: next({
                            resolve,
                            column,
                        }),
                    }),
                    next: rootNext,
                }),
        ],
        consumeContext("apis"),
        [
            "impactedCollections",
            ({apis, modal: {column}}, next) =>
                Load({
                    fetch: async () => {
                        const tables = model.value.tables.filter((t) => t.columns.find((c) => c.transformationID == column.id));
                        const resp = await apis.model.getImpactedCollection({
                            modelID: model.value.id,
                            tables,
                        });
                        return keyToArray(resp).map((item) => item.value.name);
                    },
                    next,
                }),
        ],
        ["deleting", (_, next) => UseState({next})],
        ({modal: {column, resolve}, impactedCollections, apis, deleting}) => {
            const deletingName = column?.visualNameFull?.toLowerCase();
            const deletingTitle = "column";

            return (
                <div className="delete-transformation-dialog-ry6">
                    {/* <div className="dialog-body"> */}
                    {!impactedCollections && !impactedCollections?.length ? (
                        <div className="checking dialog-body">Checking...</div>
                    ) : (
                        <>
                            <div className="main-part dialog-body">
                                {!impactedCollections?.length ? (
                                    `The ${deletingName} ${deletingTitle} is not used in any collections but deleting it cannot be undone.`
                                ) : (
                                    <>
                                        The {deletingName} {deletingTitle} is used in the following collections;
                                        <br />
                                        <br />
                                        {joinJsx(
                                            impactedCollections.map((n) => "- " + n),
                                            <br />
                                        )}
                                        <br />
                                        <br />
                                        Deleting this {deletingTitle} will not be reflected in the embedded collection until the next
                                        publish.
                                    </>
                                )}
                                <br />
                                <br />
                                Are you sure that you want to remove the {deletingName} {deletingTitle}?
                            </div>
                        </>
                    )}
                    {/* </div> */}

                    {(impactedCollections || impactedCollections?.length) && (
                        <div className="buttons">
                            <Button btnType="secondary" onClick={() => resolve()}>
                                Cancel
                            </Button>
                            <Button
                                className="primary"
                                disabled={deleting.value}
                                onClick={async () => {
                                    deleting.onChange(true);

                                    await apis.transformation.deleteTransformation({
                                        transformationId: column?.transformationID || column?.calculations[0]?.transformationID,
                                        confirmName: column?.name,
                                    });

                                    deleting.onChange(false);
                                    resolve();
                                    onDone();
                                }}
                            >
                                Delete {deletingTitle}
                            </Button>
                        </div>
                    )}
                </div>
            );
        }
    );
