import React, {Fragment} from "react";
import {LpColorLine} from "../../../../../../common/left-panel/lp-color-line";

export const KpiColorsTheme = ({form}) => {
    const defaultColors = ["#294661", "#7EBD36", "#F7931E", "#FB3640", "#605F5E", "#01295F"];

    const formColorsState = form.field(["dataVisualization", "dataColorPalettes", "kpiColorsRGB"]).state;

    const colors = [0, 1, 2, 3, 4].map((index) => {
        return formColorsState.value[index] || defaultColors[index];
    });

    return (
        <div className="content">
            {colors.map((color, index) => (
                <Fragment key={index}>
                    {LpColorLine({
                        label: `Color ${index + 1}`,
                        state: {
                            value: color,
                            onChange: (v) => formColorsState.onChange(colors.map((c, i) => (i == index ? v : c))),
                        },
                        key: index,
                    })}
                </Fragment>
            ))}
        </div>
    );
};
