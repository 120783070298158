// highcharts ^9 filters some attributes and tags from AST, so we have to do this
export const allowHtmlElements = (Highcharts) => {
    const allowedAttributes = ["xmlns", "viewBox", "fill-rule", "transform"];

    const allowedTags = ["g"];

    // Allow links to the `tel` protocol
    // Highcharts.AST.allowedReferences.push('tel:');

    Highcharts.AST.allowedTags.push(...allowedTags);
    Highcharts.AST.allowedAttributes.push(...allowedAttributes);
};
