import "./header.scss";

import * as React from "react";
import {css, cx} from "emotion";

import {cs} from "../../../../../react/chain-services";
import {UseState} from "../../../../../react/use-state";

import {cGetFontSize} from "../../../common/style-map/font-size";
import {InfoIconTooltip} from "../../../common/info-icon-tooltip/info-icon-tooltip";
import {getFontOptions} from "../common/get-font-options";
import {SearchHeader} from "./search-header";
import {TableBorder} from "../table-border/table-border";
import {
    CurrencyConversionWarning,
    getCurrencyConversion,
    getTableTileColumnCurrencyConversion,
} from "../../../common/currency-conversion-warning/currency-conversion-warning";
import {flatten1, unique} from "../../../../../utils/collections";
import {FilterHeader} from "@common/ui-components/charts/table/table/header/filter-header";
import {StyledClass} from "@common/react/styled-class";

export const Header = ({data, tileFields, tile, theme, columns, domRef, sort, search}) =>
    cs(() => {
        const getFontSize = cGetFontSize(theme.general.canvas.fontSize, theme);
        const tablesStyle = theme.dataVisualization.tables;
        const buttonsStyle = theme.general.buttons;

        return cs(
            [
                "cellClass",
                (_, next) =>
                    StyledClass({
                        content: {
                            padding: "10px 5px",
                            textAlign: "left",
                            textTransform:
                                tablesStyle.headerCaseOptions === "TitleCase"
                                    ? "capitalize"
                                    : tablesStyle.headerCaseOptions === "Uppercase"
                                    ? "uppercase"
                                    : "",
                            backgroundColor: tablesStyle.headerBackgroundColorRGB || "#FFF",
                            position: "sticky",
                            top: 0,
                        },
                        next,
                    }),
            ],
            ({cellClass}) => (
                <thead ref={domRef}>
                    <tr>
                        {(data.columns || []).map((tileColumn, i) => {
                            const column = columns[i];
                            const {columnIndex, direction} = sort.tableSortOverride || {};
                            const columnInfo = column.columnSettings;

                            const allowFilter = () => {
                                if (tile.style.columnFilters) {
                                    return !(column.type == "text" && !tileColumn.textFilterValues);
                                }

                                return false;
                            };

                            return (
                                <th
                                    key={i}
                                    className={cx(
                                        cellClass,
                                        // {"align-right": column.type === "number" && !theme.sdkDashboard},
                                        "header-a323"
                                    )}
                                >
                                    <TableBorder isFirstColumn={i == 0} isLastColumn={i == data.columns.length - 1} isFirstRow />

                                    <div
                                        className={cx("column-text", columnInfo?.showInfoIcon && columnInfo?.infoIconText && "has-info")}
                                        onClick={() => sort.sortColumn(i)}
                                        style={{
                                            fontSize: `${getFontSize({
                                                group: tablesStyle.headerFontSize || theme.general.canvas.fontSize,
                                                elemType: "label",
                                            })}px`,
                                            ...getFontOptions(tablesStyle.headerFontOptions),
                                            color: tablesStyle.headerFontColorRGB || "#bcbcbc",
                                        }}
                                    >
                                        <span>{tileColumn.name}</span>

                                        {InfoIconTooltip({
                                            theme,
                                            width: getFontSize({
                                                group: tablesStyle.headerFontSize || theme.general.canvas.fontSize,
                                                elemType: "title_icon",
                                            }),
                                            color: tablesStyle.headerFontColorRGB || "#bcbcbc",
                                            showInfoIcon: () => columnInfo?.showInfoIcon && columnInfo?.infoIconText,
                                            infoText: columnInfo?.infoIconText,
                                        })}
                                    </div>

                                    <div className="actions">
                                        <div className="action-item">
                                            {cs(() =>
                                                CurrencyConversionWarning({
                                                    theme,
                                                    currencyConversion: getTableTileColumnCurrencyConversion({
                                                        index: i,
                                                        data,
                                                        tileFields,
                                                    }),
                                                })
                                            )}
                                        </div>

                                        {(() =>
                                            cs(
                                                [
                                                    "cssClass",
                                                    (_, next) =>
                                                        StyledClass({
                                                            next,
                                                            content: {
                                                                "&:hover": {
                                                                    path: {
                                                                        fill: `${buttonsStyle.primaryButton.backgroundColorRGB}`,
                                                                    },
                                                                },
                                                            },
                                                        }),
                                                ],
                                                ({cssClass}) => (
                                                    <div className="action-item sort-icons">
                                                        <SortIconUp
                                                            onClick={() => sort.sortColumn(i, "Asc")}
                                                            fill={
                                                                columnIndex == i && direction == "Asc"
                                                                    ? buttonsStyle.primaryButton.backgroundColorRGB
                                                                    : tablesStyle?.headerFontColorRGB
                                                            }
                                                            className={cssClass}
                                                        />

                                                        <SortIconDown
                                                            onClick={() => sort.sortColumn(i, "Desc")}
                                                            fill={
                                                                columnIndex == i && direction == "Desc"
                                                                    ? buttonsStyle.primaryButton.backgroundColorRGB
                                                                    : tablesStyle?.headerFontColorRGB
                                                            }
                                                            className={cssClass}
                                                        />
                                                    </div>
                                                )
                                            ))()}

                                        {allowFilter() && (
                                            <div className="action-item">
                                                {FilterHeader({
                                                    theme,
                                                    search,
                                                    tileColumn: tileColumn,
                                                    columnIndex: i,
                                                    style: tile.style,
                                                    columnType: column.type,
                                                })}
                                            </div>
                                        )}

                                        {column.type == "text" && !allowFilter() && (
                                            <div className="action-item">
                                                {SearchHeader({
                                                    style: tile.style,
                                                    theme,
                                                    search,
                                                    columnName: tileColumn.name,
                                                    columnIndex: i,
                                                })}
                                            </div>
                                        )}
                                    </div>
                                </th>
                            );
                        })}
                    </tr>
                </thead>
            )
        );
    });

const SortIconDown = ({fill = "#919EB0", onClick, className}) => (
    <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick} className={className}>
        <path
            d="M4.40005 3.14568L7.20017 0.600037L8.00005 1.32722L4.40005 4.60004L0.800049 1.32722L1.59992 0.600037L4.40005 3.14568Z"
            fill={fill}
        />
    </svg>
);

const SortIconUp = ({fill = "#919EB0", onClick, className}) => (
    <svg className={className} onClick={onClick} width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.4 1.85438L1.59987 4.40002L0.800001 3.67284L4.4 0.400025L8 3.67285L7.20013 4.40002L4.4 1.85438Z" fill={fill} />
    </svg>
);

export const SortIcon = ({fill = "#d8d8d8"}) => (
    <svg xmlns="http://www.w3.org/2000/svg" height="15" fill={fill} viewBox="0 0 24 24" width="15">
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M7 14l5-5 5 5z" />
    </svg>
);
