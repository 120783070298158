/**
 *  adjust gauge's value and target value labels
 * */
const adjustValueLabels = ({chart}) => {
    // chart.yAxis[0].plotLinesAndBands.forEach((p) => {
    //     const moveToPoint = getMoveToPoint(p.svgElem.d);
    //     const lineToPoint = getLineToPoint(p.svgElem.d);
    //     const slope1 = slope(moveToPoint, lineToPoint);
    //
    //     if (!p.label) return;
    //
    //     const bbox = p.label.getBBox();
    //     const alignX = p.label.alignAttr.x;
    //
    //     const newX = isNaN(slope1) ? alignX : (() => {
    //         if (Math.min(moveToPoint.x, lineToPoint.x) > chart.yAxis[0].center[0]) {
    //             return Math.max(moveToPoint.x, lineToPoint.x) + 5;
    //         }
    //         return Math.min(moveToPoint.x, lineToPoint.x) - bbox.width - 15;
    //     })();
    //
    //     p.label.attr({
    //         x: newX
    //     })
    // });

    // using translate to preserve the label position when updating chart margin
    const chartCenterX = (() => {
        const chartRect = chart.plotBackground.element.getBoundingClientRect();
        return chartRect.x + chartRect.width * 0.5;
    })();

    chart.yAxis[0].plotLinesAndBands.forEach((p) => {
        if (!p.label) return;

        const lineRect = p.svgElem.element.getBoundingClientRect();
        const labelRect = p.label.element.getBoundingClientRect();

        const newLabelX =
            labelRect.x < chartCenterX // label is in the left half of the gauge
                ? lineRect.x - labelRect.width - 5
                : lineRect.x + lineRect.width + 5;
        const translateX = newLabelX - labelRect.x;

        if (translateX !== 0) {
            p.label.element.setAttribute("transform", `translate(${newLabelX - labelRect.x},0)`);
        }
    });
};
exports.adjustValueLabels = adjustValueLabels;

const getMoveToPoint = (segmentPath) => ({
    // M x y L x' y'
    x: +segmentPath.split(" ")[1],
    y: +segmentPath.split(" ")[2],
});

const getLineToPoint = (segmentPath) => ({
    x: +segmentPath.split(" ")[4],
    y: +segmentPath.split(" ")[5],
});

const slope = (p1, p2) => {
    return (p2.y - p1.y) / (p2.x - p1.x);
};

// x: isNaN(slope1) ? alignX : (
//     // slope1 < 0 ? alignX + bbox.width - bbox.width*0.1 : alignX - bbox.width - 15
//     slope1 < 0 ? +lineToPoint.x + 5 : alignX - bbox.width - 15
// )
