export const getTrianglePath = ({x, y}) => {
    const sideLength = 8;
    const diff = sideLength / Math.sqrt(2);

    const topLeft = {
        x: x - diff,
        y: y - diff,
    };

    const topRight = {
        x: x + diff,
        y: y - diff,
    };

    return ["M", x, y, topLeft.x, topLeft.y, topRight.x, topRight.y, "Z"];
};

export const dashArrays = {
    Dashed: "4",
    Dotted: "2",
};
