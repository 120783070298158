import {addDate, addSecond, dateDiff, secondDiff} from "@common/utils/dates/date-object";
import {omit} from "@common/utils/objects";

export const getPreviousDays = (range) => {
    const newTo = addSecond(range.from, -1);

    return {
        to: newTo,
        from: addSecond(newTo, -secondDiff(range.to, range.from)),
    };
};

export const getPreviousDays1 = (range) => {
    const _range = {
        from: {
            ...omit(range.from, ["dow"]),
            hours: 0,
            minutes: 0,
            seconds: 0,
        },
        to: {
            ...omit(range.to, ["dow"]),
            hours: 23,
            minutes: 59,
            seconds: 59,
        },
    };

    const newTo = addDate(_range.from, -1);

    return {
        to: newTo,
        from: addDate(newTo, -dateDiff(_range.to, _range.from)),
    };
};
