import "./collection-errors.scss";
import {AlertIcon} from "@common/ui-components/icons/global-icons";
import {ArrowDownIcon} from "../../../../collection/preview/collection-preview-icons";
import {cs} from "@common/react/chain-services";
import {PopupMenu} from "../../../../common/popup-menu/popup-menu";
import {CollectionPreviewProfileDropdown} from "../preview-profile-dropdown/collection-preview-profile-dropdown";
import * as React from "react";
import {noun} from "@common/utils/plural";
import {cx} from "emotion";
import {CollectionMissingColumnsError} from "../../../../model/edit/main/fix-issues-dialog/collection-missing-columns-error";
import {sort} from "@common/utils/collections";

export const CollectionErrors = ({errors, size, collectionID}) =>
    cs(
        [
            "groupErrors",
            (_, next) => {
                let ret = [];
                for(const error of errors) {
                    const found = ret.find((r) => r.modelColumnID == error.modelColumnID);
                    if(found) {
                        found.childs.push(error);
                    } else {
                        ret.push({
                            ...error,
                            childs: [error],
                        });
                    }
                }

                return next(ret);
            },
        ],
        ["errorModalDialog", (_, next) => CollectionMissingColumnsError({next, collectionID})],
        [
            "errorsPopup",
            ({groupErrors, errorModalDialog}, next) =>
                PopupMenu({
                    getCommands: () =>
                        sort(groupErrors, (g) => g.tableName).map((group, index) => ({
                            label: (
                                <span className="error-item" key={index}>
                                    <AlertIcon />{" "}
                                    <span className="link-item">
                                        {group.childs.length} {noun(group.childs.length, "error")}
                                    </span>
                                    &nbsp;-&nbsp;
                                    <b>
                                        {group.tableName}.{group.columnName}
                                    </b>
                                    &nbsp;column not found in model
                                </span>
                            ),
                            onClick: () => errorModalDialog.show({errorGroup: group}),
                        })),
                    popupClassName: "error-popup-expanded-a55",
                    maxHeight: 200,
                    overflow: 'auto',
                    next,
                    customizeBadge: (
                        <div className={cx("collection-errors-a33", size)}>
                            <AlertIcon className="alert-icon" />
                            <span>{errors.length}</span>
                            <ArrowDownIcon className="arrow-down-icon" />
                        </div>
                    ),
                }),
        ],
        ({errorsPopup}) => errorsPopup.render()
    );
