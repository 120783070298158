import "./model-panel.scss";

import React from "react";
import {cx} from "emotion";

import {scope} from "@common/react/scope";
import {cs} from "@common/react/chain-services";
import {Static2} from "@common/react/static-2";
import {UseState} from "@common/react/use-state";
import {Invoke} from "@common/react/invoke";
import {consumeContext, provideContext} from "@common/react/context";
import {Watch} from "@common/react/watch";
import {avg} from "@common/utils/collections";
import {MaxZoom, MinZoom, ZoomControls} from "../../common/zoom-controls/zoom-controls";
import {getModelTableFromDsTable} from "../tabs/data/data-menu/data-source-item";
import {autoOrganizeModel} from "../../common/auto-organize-model";
import {ModelTutorial} from "./tutorial/model-tutorial";
import {AlertMessage} from "../../../common/alert-message/alert-message";
import {ModelTableMoreControlPopup} from "./controls/more-controls/model-table-more-control-popup";
import {DraggingNew} from "./model-dragging-new";
import {ModelActionTooltip} from "./controls/tooltip/model-action-tooltip";
import {tooltipService3} from "../../../common/tooltip3/tooltip-service-3";
import {OnMounted} from "@common/react/on-mounted";
import {ModelAutoSuggestOverlay} from "./auto-suggest/model-auto-suggest-overlay";
import {buildAutoSuggestModel} from "../../common/build-auto-suggest-model";
import {max, min} from "@common/utils/math-util";
import {ModelPanelHelper} from "../../common/model-panel-helper";
import {FixIssuesDialog} from "./fix-issues-dialog/fix-issues-dialog";
import {getHasSecurityWarningTables, getTablesDisplayInCanvas} from "./model-utils";
import {spc} from "@common/react/state-path-change";
import {DebounceCache} from "@common/react/debounce-cache";
import {ModelTables} from "./model-tables";
import {InvalidRelationshipsBanner} from "./invalid-relationships-banner/invalid-relationships-banner";

export const CircleRadius = 30;
export const svgHeight = 3000;
export const svgWidth = 3000;
export const headerHeight = 54;

export const ModelPanel = ({model, interactions, next, dataSources, environment, savingQueue, autoSuggest}) =>
    cs(
        ["state1", (_, next) => UseState({next})],
        ["fixIssuesDialog", (_, next) => FixIssuesDialog({next, model, dataSources, interactions})],
        ({fixIssuesDialog}, next) => provideContext("fixIssuesDialog", fixIssuesDialog, next),
        (_, next) => provideContext("model", model, next),
        [
            "transformState",
            (_, next) =>
                UseState({
                    next,
                    initValue: {
                        scale: 1,
                        transform: {x: 0, y: 0},
                    },
                }),
        ],
        ({state1, transformState}) =>
            next({
                startDraggingNewTable: ({table, pos, datasource, color}) =>
                    state1.onChange({
                        draggingNew: {
                            table,
                            pos,
                            datasource,
                            color,
                            scale: transformState.value.scale,
                        },
                    }),
                render: !model.value
                    ? () => null
                    : () =>
                          cs(
                              consumeContext("routing"),
                              consumeContext("auth"),
                              consumeContext("apis"),
                              ["zoomRef", (_, next) => Static2({next})],
                              ["modelRef", (_, next) => Static2({next})],
                              ["svgRef", (_, next) => Static2({next})],
                              ["textBoxRef", (_, next) => Static2({next})],
                              [
                                  "state",
                                  ({routing, auth}, next) => {
                                      return UseState({
                                          next,
                                          initValue: {
                                              grabID: null,
                                              lastTableGrabPosition: {},
                                              hoveringData: null,
                                              displayAutoSuggestOverlay: null,
                                              hoverInteractions: null,
                                              movedToCenter: false,
                                              showTutorial: autoSuggest.value
                                                  ? null
                                                  : !environment?.readOnly && auth.user.preferences.modelGuidance
                                                  ? {hasSuggestionStep: false}
                                                  : null,
                                          },
                                      });
                                  },
                              ],
                              [
                                  "localModelState",
                                  ({}, next) => {
                                      return DebounceCache({
                                          state: {
                                              ...model,
                                              onChange: (value, saveToLocal) => model.change(() => value, saveToLocal),
                                          },
                                          next,
                                      });
                                  },
                              ],
                              ["tables", ({localModelState}, next) => next(getTablesDisplayInCanvas(localModelState.state.value))],
                              [
                                  "buildTablePosition",
                                  ({modelRef, state, tables, localModelState}, next) => {
                                      let container = modelRef.get()?.getBoundingClientRect();
                                      if (container) {
                                          const {updatedTables, needUpsert} = ModelPanelHelper.generatePosition(
                                              container,
                                              tables,
                                              transformState.value
                                          );
                                          if (needUpsert) {
                                              localModelState.state.change((t) => ({
                                                  ...t,
                                                  tables: updatedTables,
                                              }));
                                          }
                                      }

                                      return next();
                                  },
                              ],
                              [
                                  "draggingNewTablePosition",
                                  ({modelRef}, next) => {
                                      if (state1.value && state1.value.draggingNew) {
                                          const {draggingNew} = state1.value;
                                          let {left} = modelRef.get()?.getBoundingClientRect() || {
                                              left: 9999999,
                                          };
                                          let {transform, scale} = transformState.value;

                                          if (draggingNew && draggingNew.pos.x > left && draggingNew.pos.y > headerHeight) {
                                              return next({
                                                  x: (draggingNew.pos.x - left - transform.x) / scale,
                                                  y: (draggingNew.pos.y - headerHeight - transform.y) / scale,
                                              });
                                          }
                                      }

                                      return next(null);
                                  },
                              ],
                              [
                                  "invalidTables",
                                  ({state, draggingNewTablePosition, tables}, next) => {
                                      const {grabID} = state.value;

                                      if (draggingNewTablePosition) {
                                          return next(
                                              ModelPanelHelper.isDraggingInvalid({
                                                  grabID,
                                                  grabPos: draggingNewTablePosition,
                                                  tables,
                                              })
                                          );
                                      }

                                      if (!grabID) return next([]);
                                      const tableGrab = tables.find((m) => m.id == grabID);
                                      return next(
                                          ModelPanelHelper.isDraggingInvalid({
                                              grabID,
                                              grabPos: tableGrab.position,
                                              tables,
                                          })
                                      );
                                  },
                              ],
                              [
                                  "tableMoreControls",
                                  (_, next) =>
                                      ModelTableMoreControlPopup({
                                          next,
                                          model,
                                          savingQueue,
                                      }),
                              ],
                              ["modelActionTooltip", (_, next) => ModelActionTooltip({next})],
                              tooltipService3({direction: "below"}),

                              ({
                                  modelRef,
                                  svgRef,
                                  zoomRef,
                                  state,
                                  invalidTables,
                                  draggingNewTablePosition,
                                  routing,
                                  tableMoreControls,
                                  modelActionTooltip,
                                  textBoxRef,
                                  tooltip,
                                  tables,
                                  localModelState,
                              }) => {
                                  let container = modelRef.get()?.getBoundingClientRect();
                                  const {grabCanvasPos, showTutorial, displayAutoSuggestOverlay} = state.value;
                                  const {scale, transform} = transformState.value;
                                  const {relationships, tables: unfilteredTables} = localModelState.state.value;
                                  const zoom = zoomRef.get();

                                  const warningTables = getHasSecurityWarningTables({
                                      tables: tables
                                          .filter((t) => !t.excludeFromModel)
                                          .map((t) => ({
                                              id: t.id,
                                              type: t.$type,
                                              columns: t.columns.map((c) => c.id),
                                              rowLevelSecurity: t.rowLevelSecurity,
                                          })),
                                      relationships,
                                  });

                                  return (
                                      <>
                                          {Invoke({
                                              onMounted: () => {
                                                  zoomRef.set(
                                                      d3
                                                          .zoom()
                                                          .scaleExtent([MinZoom, MaxZoom])
                                                          .on("zoom", (event) => {
                                                              tableMoreControls.forceClose();
                                                              modelActionTooltip.hideToolTip();
                                                              const {k, x, y} = event.transform;

                                                              transformState.onChange({
                                                                  scale: parseFloat(k.toFixed(3)),
                                                                  transform: {x: x || 0, y: y || 0},
                                                              });
                                                          })
                                                  );
                                              },
                                          })}

                                          {routing.params.invalidRelationships && (
                                              <InvalidRelationshipsBanner relationships={relationships} tables={tables} />
                                          )}

                                          {interactions.setting?.showShortestPath && (
                                              <div className="show-shortest-path-info-a99">
                                                  <div className="path-text">
                                                      Highlighting shortest possible paths between{" "}
                                                      <b>
                                                          <u>
                                                              {
                                                                  tables.find((t) => t.id == interactions.setting.showShortestPath.tableID)
                                                                      .name
                                                              }
                                                          </u>
                                                      </b>{" "}
                                                      and{" "}
                                                      <b>
                                                          <u>
                                                              {
                                                                  tables.find(
                                                                      (t) => t.id == interactions.setting.showShortestPath.targetTableID
                                                                  ).name
                                                              }
                                                          </u>
                                                      </b>
                                                      . Each path is highlighted with a different colored line.
                                                  </div>

                                                  <div className="actions">
                                                      <a>Learn More</a>
                                                      <a onClick={() => interactions.onHideShortestPath()}>Close View</a>
                                                  </div>
                                              </div>
                                          )}

                                          {!!environment?.readOnly &&
                                              AlertMessage({
                                                  message: "The model in the sample environment cannot be edited.",
                                              })}

                                          {Watch({
                                              value: interactions.leftPanelWidth,
                                              onChanged: (value) => {
                                                  if (value && zoom) {
                                                      if (interactions.setting.name == "create-new-relationship") return null;

                                                      setTimeout(() => {
                                                          const {height, width} = svgRef.get().getBoundingClientRect();
                                                          const table =
                                                              tables.find((t) => t.id == interactions.setting.data?.tableId) ||
                                                              interactions.setting.data?.table;
                                                          if (table) {
                                                              d3.select(svgRef.get())
                                                                  .transition()
                                                                  .duration(300)
                                                                  .call(
                                                                      zoom.transform,
                                                                      d3.zoomIdentity
                                                                          .translate(
                                                                              -table.position.x * scale + width / 2,
                                                                              -table.position.y * scale + height / 2
                                                                          )
                                                                          .scale(scale)
                                                                  );
                                                          }
                                                      }, 300);
                                                  }
                                              },
                                          })}

                                          {showTutorial &&
                                              localModelState.state.value &&
                                              ModelTutorial({
                                                  model: localModelState.state.value,
                                                  lowPosition: interactions.setting?.showShortestPath,
                                                  showTutorial: scope(state, ["showTutorial"]),
                                                  dataSources,
                                              })}

                                          {autoSuggest.value &&
                                              dataSources &&
                                              displayAutoSuggestOverlay &&
                                              ModelAutoSuggestOverlay({
                                                  dataSources,
                                                  CircleRadius,
                                                  svgRef,
                                                  scale,
                                                  model,
                                                  autoSuggest,
                                                  showTutorial: scope(state, ["showTutorial"]),
                                                  transform,
                                              })}

                                          {/* select table when coming to edit model page with pre-selected table or column */}
                                          {zoom &&
                                              dataSources &&
                                              OnMounted({
                                                  action: () => {
                                                      if (autoSuggest.value) {
                                                          const {tables: ts, relationships} = buildAutoSuggestModel(dataSources);
                                                          if (!(ts.length > 10 && relationships.length == 0)) {
                                                              const updatedTables = autoOrganizeModel({
                                                                  tables: ts,
                                                                  relationships,
                                                                  container,
                                                              });
                                                              const {height, width} = container;

                                                              const positionsWidth =
                                                                  max(updatedTables.map((t) => t.position?.x || 0)) +
                                                                  100 -
                                                                  (min(updatedTables.map((t) => t.position?.x || 0)) - 100);
                                                              const positionHeight =
                                                                  max(updatedTables.map((t) => t.position?.y || 0)) +
                                                                  100 -
                                                                  (min(updatedTables.map((t) => t.position?.y || 0)) - 100);
                                                              const scale = min([width / positionsWidth, height / positionHeight, 1]);
                                                              d3.select(svgRef.get()).call(zoom.transform, d3.zoomIdentity.scale(scale));
                                                          }

                                                          spc(state, ["displayAutoSuggestOverlay"], () => true);
                                                      }

                                                      if (routing.params.tableID) {
                                                          const table = localModelState.state.value.tables.find(
                                                              (t) => t.id === routing.params.tableID
                                                          );
                                                          setTimeout(() => {
                                                              interactions.selectTable({
                                                                  tableId: table.id,
                                                                  $type: table.$type,
                                                                  dataSourceTableID: table.dataSourceTableID || table.id,
                                                                  dataSourceID: table.dataSourceID,
                                                              });
                                                          }, 200);
                                                      }
                                                  },
                                              })}

                                          <div
                                              className={cx(
                                                  "model-panel-23a",
                                                  grabCanvasPos && "grabbing",
                                                  interactions.setting?.showShortestPath && "has-shortest-path"
                                              )}
                                              ref={modelRef.set}
                                          >
                                              {svgRef.get() &&
                                                  zoom &&
                                                  Invoke({
                                                      props: {},
                                                      fn: () => {
                                                          d3.select(svgRef.get()).call(zoom).on("dblclick.zoom", null);
                                                          if (tables.length > 0) {
                                                              const posMove = {
                                                                  x: avg(tables, (t) => t.position?.x || 0),
                                                                  y: avg(tables, (t) => t.position?.y || 0),
                                                              };
                                                              const {height, width} = svgRef.get().getBoundingClientRect();

                                                              d3.select(svgRef.get()).call(
                                                                  zoom.transform,
                                                                  d3.zoomIdentity
                                                                      .translate(-posMove.x + width / 2, -posMove.y + height / 2)
                                                                      .scale(scale)
                                                              );
                                                          }

                                                          state.change((c) => ({
                                                              ...c,
                                                              movedToCenter: true,
                                                          }));
                                                      },
                                                  })}

                                              {tables && zoom && (
                                                  <>
                                                      {/* this watch is for opening a newly created data view when going back to model page from transformation page */}
                                                      {routing.params.transformationID &&
                                                          Watch({
                                                              value: tables.length,
                                                              onChanged: () => {
                                                                  // make sure that the tables length changes because of a new data view is created, not deleted.
                                                                  const newlyCreatedDataView = tables.find(
                                                                      (t) => t.transformationID === routing.params.transformationID
                                                                  );
                                                                  if (newlyCreatedDataView) {
                                                                      interactions.selectTable({
                                                                          tableId: newlyCreatedDataView.id,
                                                                      });
                                                                  }
                                                              },
                                                          })}

                                                      {ZoomControls({
                                                          nodes: tables,
                                                          zoom,
                                                          scale: transformState.value.scale,
                                                          svgRef: svgRef,
                                                          containerRef: modelRef,
                                                          warningTables,
                                                          tables,
                                                          relationships,
                                                          autoOrganize: () => {
                                                              const updatedTables = autoOrganizeModel({
                                                                  tables: unfilteredTables,
                                                                  relationships,
                                                                  container: modelRef.get()?.getBoundingClientRect(),
                                                                  ...transformState.value,
                                                              });
                                                              localModelState.state.change((m) => ({
                                                                  ...m,
                                                                  tables: updatedTables,
                                                              }));
                                                          },
                                                      })}

                                                      {ModelTables({
                                                          model: localModelState.state,
                                                          tables,
                                                          dataSources,
                                                          autoSuggest,
                                                          interactions,
                                                          transformState: transformState.value,
                                                          state,
                                                          textBoxRef,
                                                          svgRef,
                                                          modelRef,
                                                          zoomRef,
                                                          invalidTables,
                                                          warningTables,
                                                          tableMoreControls,
                                                          modelActionTooltip,
                                                          tooltip,
                                                      })}

                                                      {tableMoreControls.render({
                                                          scale,
                                                          interactions,
                                                          model,
                                                      })}
                                                      {modelActionTooltip.render({})}
                                                  </>
                                              )}

                                              {state1.value?.draggingNew &&
                                                  DraggingNew({
                                                      draggingNew: scope(state1, ["draggingNew"]),
                                                      draggingNewTablePosition,
                                                      onDrop: ({table, datasource}) => {
                                                          if (draggingNewTablePosition && invalidTables.length == 0) {
                                                              if (draggingNewTablePosition.x && draggingNewTablePosition.y) {
                                                                  if (datasource) {
                                                                      const newTable = getModelTableFromDsTable({
                                                                          ds: datasource,
                                                                          dsTable: {
                                                                              ...table,
                                                                              position: draggingNewTablePosition,
                                                                          },
                                                                      });
                                                                      localModelState.state.change((m) => ({
                                                                          ...m,
                                                                          tables: m.tables.concat({
                                                                              ...newTable,
                                                                              position: draggingNewTablePosition,
                                                                          }),
                                                                      }));
                                                                  } else {
                                                                  }
                                                                  localModelState.state.change((m) => ({
                                                                      ...m,
                                                                      tables: m.tables.map((t) =>
                                                                          t.id == table.id
                                                                              ? {
                                                                                    ...t,
                                                                                    position: draggingNewTablePosition,
                                                                                    excludeFromModel: false,
                                                                                }
                                                                              : t
                                                                      ),
                                                                  }));
                                                              }
                                                          }

                                                          state1.onChange({
                                                              draggingNew: null,
                                                          });
                                                      },
                                                  })}
                                          </div>
                                      </>
                                  );
                              }
                          ),
            })
    );
