import {FieldSection} from "../../../../../../collection/tile/edit/left-panel/tabs/fields-tab/chart-types/common/field-section/field-section";
import {FieldInfoProvider} from "../../../../../../collection/tile/edit/left-panel/tabs/tile-tab/chart-types/common/field-info-provider/field-info-provider";
import React, {Fragment} from "react";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import ReactDOM from "react-dom";
import {cx} from "emotion";
import {SuspendUpdate} from "@common/react/suspend-update";
import {LeftPanelClosable} from "../../../../../../common/left-panel-closable/left-panel-closable";
import {CategoricalTheme} from "../../color-palettes/color-schemes/categorical-theme";
import {SequentialTheme} from "../../color-palettes/color-schemes/sequential-theme";
import {LpColorLine} from "../../../../../../common/left-panel/lp-color-line";

export const TableConditionalFormatTheme = ({overrideLeftPanelRef, form}) =>
    cs(["panelOverride", (_, next) => UseState({next, initValue: null})], ["selected", (_, next) => UseState({next, initValue: false})], ({panelOverride, selected}) => {
        const colorScaleState = form.field(["dataVisualization", "tables", "colorScaleSchemes"]).state;
        const singleColorsState = form.field(["dataVisualization", "tables", "singleColorsRGB"]).state;
        const fontColorsState = form.field(["dataVisualization", "tables", "fontColorsRGB"]).state;

        const singleColors = [0, 1, 2, 3, 4].map((index) => {
            return singleColorsState.value[index];
        });

        const fontColors = [0, 1, 2, 3, 4].map((index) => {
            return fontColorsState.value[index];
        });

        const render = () => {
            return (
                <SequentialTheme
                    state={{
                        value: colorScaleState.value[panelOverride.value.index].colorsRGB,
                        onChange: (value) =>
                            colorScaleState.onChange(
                                colorScaleState.value.map((_, index) => {
                                    if (index == panelOverride.value.index) return {colorsRGB: value};
                                    return _;
                                })
                            ),
                    }}
                    key={panelOverride.value.index}
                />
            );
        };

        return (
            <>
                {FieldSection({
                    header: FieldInfoProvider({
                        field: <div className="text">COLOR SCALE</div>,
                        noCollapsible: true,
                    }),
                    content: (
                        <>
                            {colorScaleState.value.map((section, index) => (
                                <div
                                    className="section-item"
                                    key={index}
                                    onClick={() => {
                                        panelOverride.onChange({
                                            type: "colorScale",
                                            index,
                                            label: `COLOR SCALE ${index + 1}`,
                                        });

                                        setTimeout(() => {
                                            selected.onChange(true);
                                        });
                                    }}
                                >
                                    <div>
                                        COLOR SCALE {index + 1}
                                        <div
                                            className="color-gradient"
                                            key={index}
                                            style={{
                                                background: `linear-gradient(to right, ${section.colorsRGB.join(",")})`,
                                            }}
                                        />
                                    </div>

                                    <img src={require("../../../../common/icons/arrow-right.svg")} className="arrow-right" />
                                </div>
                            ))}
                        </>
                    ),
                })}

                {FieldSection({
                    header: FieldInfoProvider({
                        field: <div className="text">SINGLE COLORS</div>,
                        noCollapsible: true,
                    }),
                    content: singleColors.map((color, index) => (
                        <Fragment key={index}>
                            {LpColorLine({
                                label: `Color ${index + 1}`,
                                state: {
                                    value: color,
                                    onChange: (v) => singleColorsState.onChange(singleColors.map((c, i) => (i == index ? v : c))),
                                },
                                key: index,
                            })}
                        </Fragment>
                    )),
                })}

                {FieldSection({
                    header: FieldInfoProvider({
                        field: <div className="text">FONT COLORS</div>,
                        noCollapsible: true,
                    }),
                    content: fontColors.map((color, index) => (
                        <Fragment key={index}>
                            {LpColorLine({
                                label: `Color ${index + 1}`,
                                state: {
                                    value: color,
                                    onChange: (v) => fontColorsState.onChange(fontColors.map((c, i) => (i == index ? v : c))),
                                },
                                key: index,
                            })}
                        </Fragment>
                    )),
                })}

                {overrideLeftPanelRef.get?.() &&
                    ReactDOM.createPortal(
                        <div
                            className={cx("override", {
                                active: selected.value,
                            })}
                        >
                            {cs(
                                ({}, next) =>
                                    SuspendUpdate({
                                        active: selected.value,
                                        next,
                                    }),
                                () =>
                                    LeftPanelClosable({
                                        onClose: () => selected.onChange(null),
                                        label: (panelOverride.value?.label || "").toUpperCase(),
                                        content: panelOverride.value && render(),
                                    })
                            )}
                        </div>,
                        overrideLeftPanelRef.get()
                    )}
            </>
        );
    });
