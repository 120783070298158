import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {stateToSelect} from "@common/ui-components/form/state-to-select";
import {cs} from "@common/react/chain-services";
import {getHiddenPositionFields} from "../combo-chart/panels/legend/legend-display-rules";
import {getPath} from "@common/utils/arr-path";
import {FieldInfoProvider} from "./field-info-provider/field-info-provider";

export const LegendPositionSelect = (state, size) =>
    cs(["hiddenFields", (_, next) => next(getHiddenPositionFields(size))], ({hiddenFields}) =>
        !size
            ? DropdownSelect({
                  label: "Position",
                  list: ["Auto", "Right", "Left", "Bottom", "Top"],
                  ...stateToSelect(state),
              })
            : FieldInfoProvider({
                  className: "margin-bottom",
                  info: (
                      <span>
                          <b>Top & Bottom Horizontal:</b> minimum chart height is 4 squares high.
                          <br />
                          <br />
                          <b>Top & Bottom Vertical:</b> minimum chart height is 5 squares high.
                          <br />
                          <br />
                          <b>Left & Right Vertical:</b> minimum chart width is 12 squares.
                      </span>
                  ),
                  tooltipBoxWidth: 170,
                  // topOffset: 90,
                  topOffset: 0,
                  field: DropdownSelect({
                      label: "Position",
                      registryRender: true,
                      list: [
                          {label: "Auto", disabled: false},
                          {
                              label: "Right",
                              disabled: hiddenFields.indexOf("Right") > -1,
                          },
                          {
                              label: "Left",
                              disabled: hiddenFields.indexOf("Left") > -1,
                          },
                          {
                              label: "Bottom",
                              disabled: hiddenFields.indexOf("Bottom") > -1,
                          },
                          {
                              label: "Top",
                              disabled: hiddenFields.indexOf("Top") > -1,
                          },
                          {
                              label: "BottomVertical",
                              disabled: hiddenFields.indexOf("BottomVertical") > -1,
                          },
                          {
                              label: "TopVertical",
                              disabled: hiddenFields.indexOf("TopVertical") > -1,
                          },
                      ],
                      isSelected: (v) => getPath(v.label) === (hiddenFields.indexOf(state.value) > -1 ? "Auto" : state.value),
                      onChange: (v) => state.onChange(getPath(v.label)),
                      valueToLabel: (v) => {
                          if (v.label == "Auto") return v.label;
                          if (["Left", "Right"].indexOf(v.label) > -1) return `${v.label} Vertical ${v.disabled ? "(unavailable)" : ""}`;
                          if (["Top", "Bottom"].indexOf(v.label) > -1) return `${v.label} Horizontal ${v.disabled ? "(unavailable)" : ""}`;
                          return `${v.label.replace(/([A-Z])/g, " $1").trim()} ${v.disabled ? "(unavailable)" : ""}`;
                      },
                  }),
              })
    );
