const isCompareMode = ({collectionFiltersValue, tileFilters}) => {
    const tileDateFilters = tileFilters?.filter((f) => f.$type === "DateFilter");

    const tileHasDateFilterCompare = (() => {
        if (tileDateFilters?.length > 0) {
            for (const tf of tileDateFilters) {
                if (tf.compareEnabled) {
                    return true;
                }
            }
        }
        return false;
    })();

    const collectionHasDateFilterCompare = (() => {
        if (collectionFiltersValue) {
            for (const k in collectionFiltersValue) {
                if (collectionFiltersValue[k].$type === "DateFilterValue" && collectionFiltersValue[k].compareRange) {
                    return true;
                }
            }
        }
        return false;
    })();

    // should check if tile filter override collection filter
    return tileDateFilters?.length > 0 ? tileHasDateFilterCompare : collectionHasDateFilterCompare;
};
exports.isCompareMode = isCompareMode;
