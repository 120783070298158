import {Button} from "@common/form/buttons/button/button";
import {ffToDropdown} from "@common/form/ff-to-dropdown";
import {ffToNumberInput} from "@common/form/ff-to-number-input";
import {ffToTextInput} from "@common/form/ff-to-text-input";
import {NumberInput} from "@common/form/number-input/number-input";
import {TextInput} from "@common/form/text-input/text-input";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {Form2} from "@common/react/cs-form/form2";
import {required} from "@common/react/cs-form/validators/required";
import {Load2} from "@common/react/load2";
import {scope} from "@common/react/scope";
import {spc} from "@common/react/state-path-change";
import {UseState} from "@common/react/use-state";
import {LoadingIndicator} from "@common/ui-components/loading-indicator/loading-indicator";
import {SearchableSelect} from "@common/ui-components/searchable-select/searchable-select";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";
import {omit} from "@common/utils/objects";
import * as React from "react";
import {tileMinSizeRules} from "../../../../../collection/common/dnd-grid-panel/tile-rule";
import {DialogService} from "../../../../../common/dialog/dialog-service";
import {SelectFolderService} from "../../../collections/folder-cm/select-folder-service/select-folder-service";
import "./create-shared-tile-dialog.scss";
import {SharedTileTypeSelect} from "./shared-tile-type-select";
import {recentStorage} from "../../../recent-section/recent-storage";
import moment from "moment";

export const CreateSharedTileDialog = ({folders, next: rootNext}) =>
    cs(
        consumeContext("apis"),
        consumeContext("routing"),
        [
            "modal",
            (_, next) =>
                DialogService({
                    render: ({resolve}) => ({
                        title: "Create Shared Tiles",
                        width: 400,
                        content: next({
                            resolve,
                        }),
                    }),
                    next: rootNext,
                }),
        ],
        [
            "models",
            ({apis, routing}, next) => {
                return Load2({
                    fetch: () => apis.model.getModels({namesOnly: true}),
                    next,
                });
            },
        ],
        ({models}, next) => {
            if (models.loading) {
                return <div style={{minHeight: 50, paddingTop: 50, paddingBottom: 50}}>{LoadingIndicator()}</div>;
            } else return next();
        },
        [
            "sharedTile",
            ({models}, next) =>
                UseState({
                    initValue: {
                        adminTileName: "",
                        defaultColSpan: null,
                        defaultRowSpan: null,
                        modelID: models.value?.length == 1 ? models.value[0].id : null,
                        folderID: null,
                    },
                    next,
                }),
        ],
        ["submitting", (_, next) => UseState({next})],
        [
            "form",
            ({sharedTile, apis, modal, submitting, routing}, next) =>
                Form2({
                    data: sharedTile,
                    fields: {
                        adminTileName: [required],
                        defaultColSpan: [required],
                        defaultRowSpan: [required],
                        modelID: [required],
                        tile: [required],
                    },
                    onSubmit: async () => {
                        submitting.onChange(true);
                        const newTile = await apis.collectionTiles.upsertTile({
                            ...omit(sharedTile.value, ["tile"]),
                            ...sharedTile.value.tile,
                            isShared: true,
                        });
                        submitting.onChange(false);

                        recentStorage.add({
                            envId: routing.params?.envId,
                            type: "shared-tile",
                            item: {
                                ...newTile,
                                type: newTile.$type,
                                versionInfo: {
                                    editedOnUtc: moment.utc().toISOString(),
                                },
                            },
                            path: (folders ?? []).filter((f) => f.id === newTile.folderID),
                        });

                        modal.resolve();
                        routing.goto("edit-shared-tile", {sharedTileId: newTile.id});
                    },
                    next,
                }),
        ],
        [
            "selectFolderService",
            ({sharedTile}, next) =>
                SelectFolderService({
                    apiType: "",
                    state: scope(sharedTile, ["folderID"]),
                    label: "Select a folder",
                    folders,
                    next,
                }),
        ],
        ({modal, selectFolderService, models, submitting, form, apis}) => {
            return (
                <div className="create-shared-tile-dialog-99o">
                    <VerbDialogBodyScrollbar>
                        <div className="shared-tile-form">
                            {TextInput({
                                label: "Tile Admin Name",
                                autoFocus: true,
                                ...ffToTextInput(form.field(["adminTileName"])),
                            })}

                            {selectFolderService.render()}

                            {SearchableSelect({
                                label: "Select the data model to build from",
                                list: models.value?.filter((m) => m.numTables == null || m.numTables > 0),
                                valueToLabel: (model) => model.name,
                                ...ffToDropdown(form.field(["modelID"]), ["id"]),
                            })}

                            {SharedTileTypeSelect({
                                ...form.field(["tile"]),
                                state: {
                                    ...form.field(["tile"]).state,
                                    onChange: (tile) => {
                                        const size = tileMinSizeRules[tile.$type] || {width: 3, height: 3};

                                        return form.data.change((prev) => ({
                                            ...prev,
                                            tile,
                                            defaultColSpan: size.width,
                                            defaultRowSpan: size.height,
                                        }));
                                    },
                                },
                            })}

                            <div className="default-size">
                                <div className="label">Default Tile Size</div>
                                <div className="dual">
                                    {NumberInput({
                                        ...ffToNumberInput(form.field(["defaultColSpan"])),
                                        label: "Width",
                                    })}

                                    {NumberInput({
                                        ...ffToNumberInput(form.field(["defaultRowSpan"])),
                                        label: "Height",
                                    })}
                                </div>
                            </div>
                        </div>
                    </VerbDialogBodyScrollbar>

                    <div className="buttons">
                        <Button btnType="secondary" onClick={() => modal.resolve()}>
                            Cancel
                        </Button>

                        <Button disabled={submitting.value} onClick={() => form.submit()}>
                            Create
                        </Button>

                        {/*{!collection.value.$type ? chooseType.button : otherFields.button}*/}
                    </div>
                </div>
            );
        }
    );
