const formatPhoneUS = (phoneStr) => {
    if (!phoneStr) return null;

    phoneStr = phoneStr.replace(/[^\d]/g, "");

    if (phoneStr.length == 10) {
        return phoneStr.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    }
    return phoneStr;
};

exports.formatPhoneUS = formatPhoneUS;

const formatNumber = (number, toFixed = 2) => {
    if (isNaN(number)) return number;

    return Number(number).toLocaleString({
        maximumFractionDigits: toFixed,
        minimumFractionDigits: toFixed,
    });
};

exports.formatNumber = formatNumber;
