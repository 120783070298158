import * as React from "react";
import {cs} from "@common/react/chain-services";
import "./banner-service.scss";
import {cx} from "emotion";
import {CloseIcon} from "../icons/close-icon";
import {UseState} from "@common/react/use-state";
import {consumeContext} from "@common/react/context";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";

export const BannerService = ({next}) =>
    cs(consumeContext("subscriptionBrief"), consumeContext("routing"), ({subscriptionBrief, routing}) => {
        // const subscriptionBrief = {
        //     value: {
        //         ...brief1.value,
        //         status: "PastDue",
        //         // dataUsedPercent: 76,
        //         // dataUsedPercent: 100,
        //     },
        // };
        // console.log(subscriptionBrief.value)

        const banners = [
            {
                isUsedWhen: () => subscriptionBrief.value?.status === "PastDue",
                getPackage: () => ({
                    message: "Your last payment failed. Please update your payment method to avoid service interruptions.",
                    action: "Update Payment Method",
                    onAct: () =>
                        routing.goto("account-management", {
                            setting: "organization-details",
                            tab: "billing",
                            subTab: "billing",
                        }),
                    dismissible: false,
                }),
            },
            {
                isUsedWhen: () => subscriptionBrief.value?.dataUsedPercent >= 100,
                getPackage: () => ({
                    message: "Your data ingestion has been suspended! To restart data ingestion please upgrade your account.",
                    action: "Upgrade Account",
                    onAct: () =>
                        routing.goto("account-management", {
                            setting: "organization-details",
                            tab: "billing",
                        }),
                    dismissible: false,
                }),
            },
            {
                isUsedWhen: () => subscriptionBrief.value?.dataUsedPercent >= 75,
                getPackage: () => ({
                    message: `You've used ${subscriptionBrief.value.dataUsedPercent}% of included managed data. To avoid service interruptions when exceeding your plan limit please consider upgrading now.`,
                    action: "Upgrade Account",
                    onAct: () =>
                        routing.goto("account-management", {
                            setting: "organization-details",
                            tab: "billing",
                        }),
                    dismissible: true,
                }),
            },
        ];

        const banner = banners.find((b) => b.isUsedWhen())?.getPackage();

        return cs(["showBanner", (_, next) => UseState({initValue: !!banner, next})], ({showBanner}) => (
            <div className={cx("banner-service-a1h", showBanner.value ? "has-banner" : "no-banner")}>
                <div className="banner">
                    {banner && (
                        <div className="banner-content">
                            <div className="message">
                                {banner.message}{" "}
                                <a className="link-to-action" onClick={banner.onAct}>
                                    {banner.action}
                                </a>
                            </div>
                            {banner.dismissible && (
                                <div
                                    className="dismiss-btn"
                                    onClick={() => {
                                        showBanner.onChange(false);
                                        // setTimeout(() => showBanner.onChange(true), 3000);
                                    }}
                                >
                                    {CloseIcon()}
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <VerbScrollbar style={{height: "100%"}} className="route-content">
                    {next()}
                </VerbScrollbar>
            </div>
        ));
    });
