import React from "react";
import {FieldSection} from "../../../../../../collection/tile/edit/left-panel/tabs/fields-tab/chart-types/common/field-section/field-section";
import {FieldInfoProvider} from "../../../../../../collection/tile/edit/left-panel/tabs/tile-tab/chart-types/common/field-info-provider/field-info-provider";
import {FontFamilyDropdown} from "../../../../../../collection/theme/common/theme-tab/font-family-dropdown/font-family-dropdown";
import {ffToDropdown} from "../../../../../../../../../common/form/ff-to-dropdown";
import {FontSizeSelect} from "../../../../../../collection/tile/edit/left-panel/tabs/tile-tab/chart-types/common/font-size-select";
import {LpColorLine} from "../../../../../../common/left-panel/lp-color-line";
import {ffToColorPickerLine} from "../../../../../../../../../common/form/ff-to-color-picker-line";
import {FontOptionsSelect} from "../../../../../../collection/tile/edit/left-panel/tabs/tile-tab/chart-types/table/panels/font-options-select/font-options-select";
import {CaseOptionSelect} from "../../../../../../collection/theme/common/case-options/case-options-select";
import {NumberInput} from "../../../../../../../../../common/form/number-input/number-input";
import {ffToNumberInput} from "../../../../../../../../../common/form/ff-to-number-input";

export const ContainerTileButtonTheme = ({form}) => {
    return (
        <>
            {FieldSection({
                header: FieldInfoProvider({
                    field: <div className="text">DEFAULT</div>,
                    noCollapsible: true,
                }),
                content: (
                    <>
                        {LpColorLine({
                            label: "Background color",
                            ...ffToColorPickerLine(form.field(["general", "tile", "containerTiles", "buttonStyle", "backgroundColorRGB"])),
                        })}

                        {LpColorLine({
                            label: "Text color",
                            ...ffToColorPickerLine(form.field(["general", "tile", "containerTiles", "buttonStyle", "fontColorRGB"])),
                        })}

                        {LpColorLine({
                            label: "Border color",
                            ...ffToColorPickerLine(form.field(["general", "tile", "containerTiles", "buttonStyle", "borderColorRGB"])),
                        })}

                        {NumberInput({
                            label: "Border Width",
                            unit: "px",
                            ...ffToNumberInput(form.field(["general", "tile", "containerTiles", "buttonStyle", "borderWidth"])),
                            showZero: true,
                        })}

                        {NumberInput({
                            label: "Corner Radius",
                            unit: "px",
                            ...ffToNumberInput(form.field(["general", "tile", "containerTiles", "buttonStyle", "cornerRadius"])),
                            showZero: true,
                        })}
                    </>
                ),
            })}

            {FieldSection({
                header: FieldInfoProvider({
                    field: <div className="text">HOVER</div>,
                    noCollapsible: true,
                }),
                content: (
                    <>
                        {LpColorLine({
                            label: "Background color",
                            ...ffToColorPickerLine(form.field(["general", "tile", "containerTiles", "buttonStyle", "hoverBackgroundColorRGB"])),
                        })}

                        {LpColorLine({
                            label: "Text color",
                            ...ffToColorPickerLine(form.field(["general", "tile", "containerTiles", "buttonStyle", "hoverFontColorRGB"])),
                        })}

                        {LpColorLine({
                            label: "Border color",
                            ...ffToColorPickerLine(form.field(["general", "tile", "containerTiles", "buttonStyle", "hoverBorderColorRGB"])),
                        })}
                    </>
                ),
            })}

            {FieldSection({
                header: FieldInfoProvider({
                    field: <div className="text">ACTIVE</div>,
                    noCollapsible: true,
                }),
                content: (
                    <>
                        {LpColorLine({
                            label: "Background color",
                            ...ffToColorPickerLine(form.field(["general", "tile", "containerTiles", "buttonStyle", "activeBackgroundColorRGB"])),
                        })}

                        {LpColorLine({
                            label: "Text color",
                            ...ffToColorPickerLine(form.field(["general", "tile", "containerTiles", "buttonStyle", "activeFontColorRGB"])),
                        })}

                        {LpColorLine({
                            label: "Border color",
                            ...ffToColorPickerLine(form.field(["general", "tile", "containerTiles", "buttonStyle", "activeBorderColorRGB"])),
                        })}
                    </>
                ),
            })}
        </>
    );
};
