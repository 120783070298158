import "./paged-table-layout.scss";

import * as React from "react";
import {cx} from "emotion";

import {cs} from "../../../../react/chain-services";
import {Pagination} from "./pagination/pagination";
import {loadTileData} from "../../common/load-tile-data";
import {Static2} from "../../../../react/static-2";
import {SortColumn} from "./sort/sort-column";
import {ObserveDomSize} from "../../../../react/observe-dom-size";
import {SearchColumns} from "./search/search-columns";
import {VerbScrollbar} from "../../../verb-scrollbar/verb-scrollbar";
import {fragments} from "../../../../react/fragments";
import {Watch} from "../../../../react/watch";
import {GetFiltersData} from "../../../live/live-dashboard/data/get-filters-data";

export const PagedTableLayout = ({next, tileFilters, isEditTile, loadData, size, tile, theme, tileKey, defaultData, controls}) =>
    cs(
        ["containerSize", ({}, next) => ObserveDomSize({next})],
        ["tableContainer", (_, next) => Static2({next})],
        [
            "pagination",
            ({containerSize}, next) =>
                Pagination({
                    tile,
                    next,
                    theme,
                    containerSize,
                }),
        ],
        ["sort", ({}, next) => SortColumn({next})],
        ["search", ({}, next) => SearchColumns({next, tile})],
        [
            "data",
            ({pagination, sort, search}, next) => {
                if (defaultData) return next(defaultData);

                const tableOverrides = {
                    tableSortOverride: sort.tableSortOverride,
                    tableSearches: search.tableSearches,
                };

                return fragments(
                    // expose filters data for debug function when editing a tile
                    // isEditTile && Watch({
                    //     initRun: true,
                    //     value: {
                    //         tableOverrides,
                    //     },
                    //     onChanged: ({tableOverrides}) => {
                    //         if (tableOverrides) {
                    //             GetFiltersData.setTableOverrides(tableOverrides)
                    //         }
                    //     }
                    // }),
                    loadTileData({
                        keepOutdatedValue: true,
                        next,
                        tileFilters,
                        loadData,
                        size,
                        tableOverrides: tableOverrides,
                        page: pagination.page,
                        tileKey,
                        tile,
                        theme,
                    })
                );
            },
        ],
        ({pagination, tfootRef, tableContainer, containerSize, search, data}, next) => {
            const heightControls = !tile?.style?.title?.show && controls ? 26 : 0;
            const tableControlHeight = tile?.style?.title?.show ? 52 : controls ? 26 : 0;

            return (
                <>
                    <div className={cx("table-common-vf5", !tile?.style?.title?.show && "hide-title")} style={{height: heightControls}}>
                        {controls && <div className="controls">{controls?.({data})}</div>}
                    </div>

                    <div
                        className="paged-table-layout-14q"
                        style={{
                            fontFamily: `"${theme?.general.canvas.fontFamily || "Roboto"}", sans-serif`,
                            height: `calc(100% - ${tableControlHeight}px)`,
                        }}
                    >
                        <VerbScrollbar
                            ref={(elem) => {
                                containerSize.ref(elem);
                                tableContainer.set(elem);
                            }}
                            className="table-container"
                            style={{height: "100%"}}
                        >
                            {next()}
                        </VerbScrollbar>

                        {pagination.renderControl({
                            numberOfPage: data.totalPages,
                            data,
                        })}
                    </div>
                </>
            );
        },
        ({data, sort, search}) =>
            next({
                data,
                sort,
                search,
            })
    );
