import React from "react";
import {cs} from "@common/react/chain-services";
import {DropdownSelect2} from "@common/ui-components/dropdown-select/dropdown-select2";
import "./pad-configuration.scss";
import {TextManipulationResultActionSelect} from "../../common/text-manipulation-result-action-select";
import {rColumnValueSelectFromStepOutput} from "../../../../../common/column-value-select-from-step-output/column-value-select-from-step-output";
import {ffToDropdown} from "../../../../../../../../../../common/form/ff-to-dropdown";
import {keepOnly} from "@common/utils/objects";
import {ffToTextInput} from "../../../../../../../../../../common/form/ff-to-text-input";
import {DebounceTextInput} from "../../../../../../../../../../common/form/debounce-input/debounce-text-input";
import {NumberInput} from "../../../../../../../../../../common/form/number-input/number-input";

export const PadConfiguration = ({textManipulation, state, inputStep, form, model}) =>
    cs(() => {
        return (
            <div className="pad-configuration-8re">
                <div className="config-group">
                    <div className="label">Input Column</div>
                    {(() => {
                        const dropdownProps = ffToDropdown(form.field(["textManipulation", "inputValue"]));
                        return rColumnValueSelectFromStepOutput({
                            label: "Column",
                            columns: inputStep.outputColumns,
                            model,
                            disableStaticValue: true,
                            filterColumnFn: (c) => ["Text", "IDUUID"].includes(c.dataType),
                            isValueNotAllowed: (c) => !["Text", "IDUUID"].includes(c.dataType),
                        })({
                            state: {
                                value: dropdownProps.value,
                                onChange: dropdownProps.onChange,
                            },
                            ...keepOnly(dropdownProps, ["domRef", "hasError", "errorMessage"]),
                        });
                    })()}
                </div>

                <div className="config-group">
                    <div className="label">Pad Formula</div>
                    <div className="formula-group">
                        <div className="flex-group">
                            {(() => {
                                const dropdownProps = ffToDropdown(form.field(["textManipulation", "length"]));
                                return rColumnValueSelectFromStepOutput({
                                    label: "Length (Column/Value)",
                                    columns: inputStep.outputColumns,
                                    model,
                                    filterColumnFn: (c) => ["Int"].includes(c.dataType),
                                    isValueNotAllowed: (c) => !["Int"].includes(c.dataType),
                                    renderStaticInput: ({state}) => NumberInput({state, label: "Value"}),
                                })({
                                    state: {
                                        value: dropdownProps.value,
                                        onChange: dropdownProps.onChange,
                                    },
                                    ...keepOnly(dropdownProps, ["domRef", "hasError", "errorMessage"]),
                                });
                            })()}
                        </div>

                        {DropdownSelect2({
                            label: "Padding Side",
                            list: [
                                {value: "Right", label: "Pad Right Side"},
                                {value: "Left", label: "Pad Left Side"},
                            ],
                            valueToLabel: (v) => v.label,
                            ...ffToDropdown(form.field(["textManipulation", "side"]), ["value"]),
                        })}

                        {(() => {
                            const inputProps = ffToTextInput(form.field(["textManipulation", "character"]));
                            return DebounceTextInput({
                                label: "Character to Pad With (single character)",
                                ...inputProps,
                                maxLength: 1,
                                state: {
                                    value: inputProps.state.value,
                                    onChange: (v) => inputProps.state.onChange(v),
                                },
                            });
                        })()}
                    </div>
                </div>

                {TextManipulationResultActionSelect({
                    state: textManipulation,
                    form,
                })}
            </div>
        );
    });
