import {ColumnLine} from "../../common/column-line/column-line";
import {cs} from "@common/react/chain-services";
import {keyed} from "@common/react/keyed";
import * as React from "react";
import {consumeContext} from "@common/react/context";
import {IgnoreUpdateModelPositions} from "./common";

export const TableColumns = ({table, modelTable, searchedColumns, color, getRelationshipUsage}) => {
    return cs(({}) => {
        return (
            <>
                {searchedColumns
                    ?.filter((c) => c.$type === "CalculatedModelColumn")
                    ?.map((column, k) => (
                        <div key={`calculated${k}`} className="column-line-wrapper">
                            {ColumnLine({
                                type: column.$type,
                                name: column.name,
                                transformationId: column.calculations[0]?.transformationID,
                                versionDetails: column.calculations[0]?.versionDetails,
                                publishedOn: column.calculations[0]?.publishedOn,
                                dataType: column.dataType,
                                hasRelationship: getRelationshipUsage({
                                    dsID: table.dataSourceID,
                                    dsTableId: table.id,
                                    columnId: column.id,
                                }),
                                column,
                                color,
                                table,
                                modelTable,
                            })}
                        </div>
                    ))}

                {searchedColumns
                    ?.filter((c) => c.$type === "AggregatedMeasureModelColumn")
                    ?.map((column, k) => (
                        <div key={`aggregated${k}`} className="column-line-wrapper">
                            {ColumnLine({
                                type: column.$type,
                                name: column.name,
                                transformationId: column.transformationID,
                                versionDetails: column.versionDetails,
                                publishedOn: column.publishedOn,
                                dataType: column.dataType,
                                hasRelationship: false,
                                column,
                                color,
                                table,
                                modelTable,
                            })}
                        </div>
                    ))}

                {searchedColumns
                    .filter((c) => c.$type == "OriginalColumn")
                    .map((column, k) =>
                        cs(keyed(k), ({}) => {
                            const modelColumn = modelTable?.columns.find((c) => column.id === c.id);
                            return (
                                <div className="column-line-wrapper">
                                    {ColumnLine({
                                        type: modelColumn?.$type,
                                        name: modelColumn?.visualNameShort || column.visualName || column.visualNameShort,
                                        dataType: column.dataType,
                                        hasRelationship: getRelationshipUsage({
                                            dsID: table.dataSourceID,
                                            dsTableId: table.id,
                                            dsColumnId: column.id,
                                        }),
                                        column: {
                                            ...column,
                                            columnLevelSecurity: modelColumn?.columnLevelSecurity,
                                        },
                                        color,
                                        modelColumnID: modelColumn?.id,
                                        modelTable,
                                        table,
                                    })}
                                </div>
                            );
                        })
                    )}
            </>
        );
    });
};
