import "./fields-selected.scss";

import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import { cs } from "@common/react/chain-services";
import { consumeContext } from "@common/react/context";
import { UseState } from "@common/react/use-state";
import { cascadeCollect } from "@common/utils/cascade";

import { FieldControl } from "../../../../tile/edit/left-panel/tabs/fields-tab/chart-types/common/render-field-control/field-control";

import { chain } from "@common/utils/fs";
import { filterTypesToColumnTypes } from "../filter-configs";

export const FieldsSelected = ({filter, override}) =>
    cs(
        consumeContext("modelForCollection"),
        consumeContext("getFieldInfo"),
        [
            "fields",
            ({modelForCollection: model}, next) =>
                next(
                    cascadeCollect(
                        model,
                        "tables[*].columns[*]",
                        (column, {}, {1: table}) =>
                            filterTypesToColumnTypes[filter.value.$type].includes(column.dataType) && {table, column}
                    )
                ),
        ],
        ["dragging", (_, next) => UseState({next})],
        ({getFieldInfo, dragging}) => {
            return (
                <DragDropContext
                    onDragStart={() => {
                        dragging.onChange(true);
                    }}
                    onDragEnd={(result) => {
                        dragging.onChange(false);
                        const {destination, source, draggableId} = result;

                        if (!destination) {
                            return;
                        }

                        if (destination.droppableId === source.droppableId && destination.index === source.index) {
                            return;
                        }

                        const start = filter.value.columns;
                        const newFields = Array.from(start);

                        const [modelColumnID, itemIndex] = draggableId.split("|");
                        let newField = start.find((s, index) => modelColumnID == s.modelColumnID && index == parseInt(itemIndex));

                        newFields.splice(source.index, 1);
                        newFields.splice(destination.index, 0, newField);
                        filter.onChange({
                            ...filter.value,
                            columns: newFields,
                        });
                    }}
                >
                    <Droppable droppableId="droppable-place" type="droppable-place">
                        {(provided) => (
                            <div className="fields-selected-a23" {...provided.droppableProps} ref={provided.innerRef}>
                                {filter.value.columns.map((field, index) => (
                                    <Draggable
                                        key={`${field.modelColumnID}|${index}`}
                                        draggableId={`${field.modelColumnID}|${index}`}
                                        index={index}
                                    >
                                        {(provided) => (
                                            <FieldControl
                                                key={index}
                                                {...{
                                                    field,
                                                    provided,
                                                    dragging: dragging.value,
                                                    getFieldInfo,
                                                    onRename: filter.value.$type === 'DateFilter' ? () => override.onChange({
                                                        type: "edit",
                                                        modelColumnID: field.modelColumnID,
                                                        label: field.displayName
                                                    }) : null,
                                                    overrideDisplayName: field.displayName,
                                                    onRemove: () => {
                                                        filter.change((filter) => {
                                                            const newColumns = chain(
                                                                filter.columns,
                                                                (cols) => cols.filter((item) => item.modelColumnID !== field.modelColumnID),
                                                                (cols) => {
                                                                    const alreadyHaveDefault =
                                                                        cols.filter((item) => item.default).length > 0;
                                                                    return alreadyHaveDefault
                                                                        ? cols
                                                                        : cols.map((item, i) =>
                                                                              i > 0
                                                                                  ? item
                                                                                  : {
                                                                                        ...item,
                                                                                        default: true,
                                                                                    }
                                                                          );
                                                                }
                                                            );

                                                            return {
                                                                ...filter,
                                                                columns: newColumns,
                                                                multiSelectionOption:
                                                                    newColumns.length == 1
                                                                        ? "SingleSelect"
                                                                        : filter.multiSelectionOption ?? "Any",
                                                            };
                                                        });
                                                    },
                                                }}
                                            />
                                        )}
                                    </Draggable>
                                ))}

                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            );
        }
    );
