import React from "react";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {consumeContext} from "@common/react/context";

import {DialogService} from "../../../../../../common/dialog/dialog-service";
import {MapModelFieldsService} from "../../../../../../common/map-model-fields/map-model-fields-service";
import {getCollectionWithMappedFields} from "../../../../../../common/map-model-fields/get-clone-with-mapped-fields";
import {getCollectionFields} from "../../../../../../dashboard/env/collections/collection-cm/clone-collection/map-fields-for-cloning-collection-dialog";
import {chain} from "@common/utils/fs";
import {removeIdsInCollection} from "../../../../../../common/remove-ids";
import {Button} from "../../../../../../../../../common/form/buttons/button/button";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";

export const MapFieldsForCloningTileDialog = ({onDone, next: rootNext}) =>
    cs(
        consumeContext("apis"),
        [
            "modal",
            (_, next) =>
                DialogService({
                    render: ({resolve, args: {collection, modelID, selected}}) => ({
                        title: "Map Fields",
                        width: 720,
                        content: next({
                            resolve,
                            collection,
                            modelID,
                            selected,
                        }),
                    }),
                    next: rootNext,
                }),
        ],
        ["cloning", (_, next) => UseState({next})],
        ["error", (_, next) => UseState({next})],
        [
            "mapFieldsService",
            ({modal}, next) =>
                MapModelFieldsService({
                    originFields: getCollectionFields(modal.collection),
                    originModelId: modal.collection.modelID,
                    destinationModelId: modal.modelID,
                    next,
                }),
        ],
        ({modal, mapFieldsService, apis, error, cloning}) => {
            return (
                <div className="map-fields-for-cloning-collection-dialog-5s3">
                    <VerbDialogBodyScrollbar>
                        <div className="messages">
                            <div className="message">Creating a new tile that uses a different model requires you to map the fields from this model to the new model.</div>
                            {error.value && <div className="message error">Sorry, there is an issue cloning this tile.</div>}
                        </div>
                        {mapFieldsService.renderMapTable()}
                    </VerbDialogBodyScrollbar>
                    <div className="buttons">
                        <Button
                            disabled={
                                mapFieldsService.fieldsMap == null ||
                                mapFieldsService.fieldsMap.map((fm) => fm.destinationModelField).filter((v) => v).length !== mapFieldsService.fieldsMap.length ||
                                cloning.value
                            }
                            onClick={async () => {
                                const cloneCollection = chain(
                                    {
                                        ...modal.collection,
                                        name: modal.cloneName,
                                        modelID: modal.modelID,
                                    },
                                    (col) => (mapFieldsService.fieldsMap?.length > 0 ? getCollectionWithMappedFields(col, mapFieldsService.fieldsMap) : col),
                                    (col) => removeIdsInCollection(col)
                                );
                                try {
                                    cloning.onChange(true);
                                    await onDone({
                                        collection: cloneCollection,
                                        selected: modal.selected,
                                    });
                                    modal.resolve();
                                } catch (e) {
                                    error.onChange(e.message);
                                    cloning.onChange(false);
                                }
                            }}
                        >
                            Done
                        </Button>
                    </div>
                </div>
            );
        }
    );
