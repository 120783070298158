import * as React from "react";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {DialogService} from "../../../../../common/dialog/dialog-service";
import "./move-folder-dialog.scss";
import {SelectFolderService} from "../select-folder-service/select-folder-service";
import {consumeContext} from "@common/react/context";
import {Button} from "../../../../../../../../common/form/buttons/button/button";

export const MoveFolderDialog = ({apiType, folders, onDone, next: rootNext}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    render: ({resolve, args: {folder}}) => ({
                        title: "Move Folder",
                        width: 540,
                        content: next({
                            resolve,
                            folder,
                        }),
                    }),
                    next: rootNext,
                }),
        ],

        consumeContext("apis"),

        [
            "destination",
            ({modal}, next) =>
                UseState({
                    initValue: modal.folder.parentFolderID,
                    next,
                }),
        ],

        [
            "selectFolderService",
            ({destination, modal}, next) =>
                SelectFolderService({
                    apiType,
                    state: destination,
                    ignoreFolder: modal.folder,
                    label: "Move folder to",
                    folders,
                    next,
                }),
        ],

        ["submitting", (_, next) => UseState({next})],

        ({destination, modal, selectFolderService, submitting, apis}) => (
            <div className="move-folder-dialog-9sv">
                <div className="main-part dialog-body">{selectFolderService.render()}</div>

                <div className="buttons">
                    <Button btnType="secondary" onClick={() => modal.resolve()}>
                        Cancel
                    </Button>
                    <Button
                        disabled={submitting.value || !selectFolderService.valid}
                        onClick={async () => {
                            submitting.onChange(true);

                            if (selectFolderService.selectNewFolder) {
                                try {
                                    const newFolder = await selectFolderService.selectNewFolder();
                                    const movedFolder = await apis[apiType].upsertFolder({
                                        ...modal.folder,
                                        parentFolderID: newFolder.id,
                                    });
                                    onDone({newFolder, movedFolder});
                                } catch (error) {
                                    //return console.log(error);
                                }
                            } else {
                                if (destination.value !== modal.folder.parentFolderID) {
                                    const movedFolder = await apis[apiType].upsertFolder({
                                        ...modal.folder,
                                        parentFolderID: destination.value,
                                    });
                                    onDone({movedFolder});
                                }
                            }

                            submitting.onChange(false);
                            modal.resolve();
                        }}
                    >
                        Move
                    </Button>
                </div>
            </div>
        )
    );
