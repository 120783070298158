import "./configure-field.scss";

import * as React from "react";

import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {spc} from "@common/react/state-path-change";
import {consumeContext, provideContext} from "@common/react/context";

import {chain} from "@common/utils/fs";
import {unique} from "@common/utils/collections";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";

import {FieldFormat} from "./field-format/field-format";
import {Configuration} from "./configuration/configuration";
import {DisplayName} from "./display-name/display-name";
import {NullBlankHandling} from "./null-blank-handling/null-blank-handling";
import {SelectField} from "../select-field/select-field";
import {ConfirmDialog} from "../../../../../../../../../common/confirm-dialog/confirm-dialog";
import {LeftPanelClosable} from "../../../../../../../../../common/left-panel-closable/left-panel-closable";
import {DateGroupsConfiguration} from "./date-groups-configuration/date-groups-configuration";

export const ConfigureField = ({
    listFieldsAggregation,
    field,
    getFieldInfo,
    constantField, // place for constant value object like the gauge's targetValueConstant
    label,
    notAllowNoneAggregation,
    hideAggregationSelect,
    onCancel,
    onRemove,
    hideSort = false,
    hideDisplayName = false,
    filterAggregations,
    allowAggregations = true,
    customListAgg,
    notAllowNullBlankHandling,
    hasDateGroupsConfiguration = false,
}) =>
    cs(
        [
            "numericCurrency",
            (_, next) =>
                UseState({
                    initValue: {isoOverride: false, isISOCode: false},
                    next,
                }),
        ],
        ({numericCurrency}, next) => provideContext("numericCurrencyConfig", numericCurrency, next),

        ["more", (_, next) => UseState({next})],

        ({more}, next) =>
            more.value
                ? FieldFormat({
                      field,
                      oriField: more.value,
                      getFieldInfo,
                      onCancel: () => more.onChange(null),
                  })
                : next(),

        ({numericCurrency}, next) =>
            numericCurrency?.value?.isoOverride
                ? cs(consumeContext("tileFields"), ({tileFields}) => {
                      const currencyConversionFields = chain(
                          listFieldsAggregation || [],
                          (_) => _.map((a) => a.currencyConversion),
                          (_) => _.filter((v) => v),
                          (_) => _.filter((v) => ![v.modelColumnID, v.modelID, v.modelTableID].includes("00000000-0000-0000-0000-000000000000")),
                          (_) => unique(_, (v) => v.modelColumnID + v.modelID + v.modelTableID)
                      );

                      const handleChangeField = (field1) => {
                          spc(field, ["currencyConversion"], (v) => ({
                              ...v,
                              ...field1,
                          }));

                          numericCurrency.change((v) => ({
                              ...v,
                              isoOverride: false,
                          }));
                      };

                      return SelectField({
                          label: `Select ISO Field`,
                          onCancel: () =>
                              numericCurrency.change((v) => ({
                                  ...v,
                                  isoOverride: false,
                              })),
                          actionType: "change",
                          onSelect: handleChangeField,
                          allowedFieldType: "text",
                          $type: null,
                          hideAggregatedMeasureColumn: false,
                          ...(currencyConversionFields.length === 1
                              ? {
                                    filterColumn: (c) =>
                                        c.modelColumnID === currencyConversionFields[0].modelColumnID &&
                                        c.modelTableID === currencyConversionFields[0].modelTableID &&
                                        c.modelID === currencyConversionFields[0].modelID,
                                }
                              : {}),
                      });
                  })
                : next(),

        [
            "confirmDialog",
            (_, next) =>
                ConfirmDialog({
                    title: "Configuration Cannot Be Saved",
                    body: <>The ISO Field is empty. If no field is selected the currency format will be reset to US dollars.</>,
                    submitText: "Reset Configuration",
                    next,
                }),
        ],

        ({confirmDialog, numericCurrency}, next) => (
            <LeftPanelClosable
                {...{
                    className: "closeable-configure-field-f41",
                    onClose: async () => {
                        const currencyConversionField = field.value.currencyConversion;
                        const hasCurrencyConversion = !(
                            !currencyConversionField ||
                            [currencyConversionField.modelColumnID, currencyConversionField.modelID, currencyConversionField.modelTableID].includes(
                                "00000000-0000-0000-0000-000000000000"
                            )
                        );

                        if (numericCurrency?.value?.isISOCode && field.value.numericProperties?.displayType?.startsWith("Currency") && !hasCurrencyConversion) {
                            const resp = await confirmDialog.show();
                            if (resp) {
                                numericCurrency.change((v) => ({
                                    ...v,
                                    isISOCode: false,
                                }));
                                spc(field, ["numericProperties", "currencyProperties"], () => ({
                                    currency: "USD",
                                    currencySymbolPlacement: "Left",
                                    currencySymbol: "$",
                                    spaceBetweenSymbolAndNumber: false,
                                }));
                            }
                        }

                        onCancel();
                    },
                    label: label || getFieldInfo(field.value).visualNameShort + " Field",
                    content: next(),
                }}
            />
        ),

        consumeContext("tileFields"),

        ({more, tileFields}) => (
            <>
                <VerbScrollbar style={{height: "100%"}} className="configure-x-axis-field-9qm">
                    {!hideDisplayName && DisplayName({field, getFieldInfo, constantField})}

                    {/*hide sort config on measures and categories if dim is datetime and has no limit*/}
                    {/*{!hideSort && !(tileFields.dimNoLimit && ["MeasureTileField", "CategoryTileField"].includes(field.value.$type)) && (*/}
                    {/*    SortBy({field, getFieldInfo})*/}
                    {/*)}*/}

                    {["DimensionTileField", "CategoryTileField"].includes(field.value.$type) &&
                        getFieldInfo(field.value).type !== "date" &&
                        !notAllowNullBlankHandling &&
                        NullBlankHandling({field})}

                    {Configuration({
                        field,
                        getFieldInfo,
                        constantField,
                        allowAggregations,
                        notAllowNoneAggregation,
                        hideAggregationSelect,
                        filterAggregations,
                        customListAgg,
                        onAddFormat: () => more.onChange(field.value),
                    })}

                    {["CategoryTileField"].includes(field.value.$type) && hasDateGroupsConfiguration && DateGroupsConfiguration({field})}

                    {/*{["DimensionTileField", "DimensionlessMeasureTileField"].includes(field.value.$type) && Limit({field, getFieldInfo})}*/}
                </VerbScrollbar>
            </>
        )
    );
