import {cs} from "@common/react/chain-services";
import * as React from "react";
import "./before-or-after.scss";
import {ffToDatePicker} from "../../../../../../../../../../../../common/form/date-picker/ff-to-date-picker";
import {DatePickerForm} from "@common/form/date-picker/date-picker-form";

export const BeforeOrAfter = ({form}) =>
    cs(({}) => (
        <div className="edit-custom-range before-or-after-7e3 inline">
            <DatePickerForm
                key={form.field('$type').state.value}
                {...{
                    label: "Date",
                    ...ffToDatePicker(form.field(["date"])),
                }}
            />
        </div>
    ));
