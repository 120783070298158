import "./zoom-controls.scss";

import { cx } from "emotion";
import React, { createElement } from "react";

import { ButtonIcon } from "@common/form/buttons/button-icon/button-icon";
import { DebounceNumberInput } from "@common/form/debounce-input/debounce-number-input";
import { AnyAction } from "@common/react/any-action";
import { cs } from "@common/react/chain-services";
import { UseMemo } from "@common/react/use-memo";
import { UseState } from "@common/react/use-state";
import { AlertIcon } from "@common/ui-components/icons/global-icons";
import { avg } from "@common/utils/collections";
import { max, min } from "@common/utils/math-util";
import Plural from "@common/utils/plural";
import { generateAnchorsAndRelationships, getPotentiallyInvalidRelationships } from "../../edit/main/relationships/relationships-common";

export const MaxZoom = 1.25;
export const MinZoom = 0.1;

export const ZoomControls = ({
    className,
    scale,
    svgRef,
    warningTables,
    zoom,
    nodes,
    autoOrganize,
    hideInputScale = false,
    tables,
    relationships,
}) =>
    cs(
        [
            "zoomIn",
            (_, next) =>
                next(() => {
                    d3.select(svgRef.get())
                        .transition()
                        .call(zoom.scaleTo, Math.min(MaxZoom, scale + 0.25));
                }),
        ],
        [
            "zoomOut",
            ({state1}, next) =>
                next(() => {
                    d3.select(svgRef.get())
                        .transition()
                        .call(zoom.scaleTo, Math.max(MinZoom, scale - 0.25));
                }),
        ],
        [
            "reset",
            ({state1}, next) =>
                next(() => {
                    if (nodes.length == 0) return;

                    const {height, width} = svgRef.get().getBoundingClientRect();

                    const positionsWidth =
                        max(nodes.map((t) => t.position?.x || 0)) + 100 - (min(nodes.map((t) => t.position?.x || 0)) - 100);
                    const positionHeight =
                        max(nodes.map((t) => t.position?.y || 0)) + 100 - (min(nodes.map((t) => t.position?.y || 0)) - 100);

                    const scale = Math.min(width / positionsWidth, height / positionHeight, MaxZoom);
                    const posMove = {
                        x: avg(nodes, (t) => (t.position?.x || 0) * scale),
                        y: avg(nodes, (t) => (t.position?.y || 0) * scale),
                    };

                    d3.select(svgRef.get())
                        .transition()
                        .duration(750)
                        .call(zoom.transform, d3.zoomIdentity.translate(-posMove.x + width / 2, -posMove.y + height / 2).scale(scale));
                }),
        ],
        ["state", (_, next) => UseState({next, initValue: {scale, isTransforming: false}})],
        [
            "invalidInnerJoinRelationships",
            ({}, next) => {
                if(!tables) return next([])
                const data = generateAnchorsAndRelationships({relationships, tables});
                return UseMemo({
                    fn: () => getPotentiallyInvalidRelationships({relationships: data.relationshipsTable}),
                    deps: [JSON.stringify(data.relationshipsTable)],
                    next,
                });
            },
        ],
        ({zoomIn, reset, zoomOut, state, invalidInnerJoinRelationships}) => {
            const {scale: _scale, isTransforming} = state.value;
            return (
                <>
                    <div className={cx("zoom-controls zoom-controls-23a", className)}>
                        <div className="group">
                            <ButtonIcon icon={<img src={require("./icons/zoom-in.svg")} />} btnType="border" onClick={() => zoomIn()} />
                            <ButtonIcon icon={<img src={require("./icons/zoom-out.svg")} />} btnType="border" onClick={() => zoomOut()} />
                        </div>

                        {!hideInputScale && (
                            <div className="group" style={{width: 50}}>
                                <DebounceNumberInput
                                    noLabel
                                    forceToEmptyValue={false}
                                    maxValue={MaxZoom * 100}
                                    minValue={MinZoom * 100}
                                    disabled={isTransforming}
                                    state={{
                                        value: Math.round((isTransforming ? _scale : scale) * 100),
                                        onChange: (v) => {
                                            if (v == 0) {
                                                return;
                                            }
                                            let value = v / 100;

                                            if (value < MinZoom) {
                                                value = MinZoom;
                                            }

                                            if (value > MaxZoom) {
                                                value = MaxZoom;
                                            }

                                            const newScale = parseFloat(value.toFixed(3));

                                            state.onChange({
                                                isTransforming: true,
                                                scale: newScale,
                                            });

                                            d3.select(svgRef.get())
                                                .transition()
                                                .on("end", () => {
                                                    state.change((v) => ({...v, isTransforming: false}));
                                                })
                                                .call(zoom.scaleTo, newScale);
                                        },
                                    }}
                                />
                                %
                            </div>
                        )}

                        <div className="group">
                            <ButtonIcon icon={<img src={require("./icons/refresh.svg")} />} btnType="border" onClick={() => reset()} />
                            {autoOrganize && (
                                <ButtonIcon
                                    icon={<img src={require("./icons/align.svg")} />}
                                    btnType="border"
                                    onClick={() => {
                                        autoOrganize();
                                        setTimeout(() => {
                                            reset();
                                        }, 100);
                                    }}
                                />
                            )}
                        </div>

                        {(warningTables?.length > 0 || invalidInnerJoinRelationships.length > 0) &&
                            ModelAlertButton({
                                invalidInnerJoinRelationships,
                                warningTables,
                            })}
                    </div>
                </>
            );
        }
    );

const ModelAlertButton = ({warningTables, invalidInnerJoinRelationships}) =>
    cs(
        ["open", (_, next) => UseState({next, initValue: false})],
        ({open}, next) =>
            createElement(AnyAction, {
                disabled: !open.value,
                fn: () => {
                    open.onChange(false);
                },
                next,
            }),
        ({open}) => (
            <div className="group">
                {open.value && (
                    <div className="alert-box">
                        {warningTables.length > 0 && (
                            <div className="alert-row">
                                <div className="alert-icon">
                                    <img src={require("./icons/row-level-security-alert.svg")} />
                                </div>

                                <div className="alert-text">
                                    <b>
                                        {warningTables.length} {Plural.noun(warningTables.length, "table")} not associated with row level
                                        security
                                    </b>
                                    — If any data is used from these tables in a collection, there will be no segmentation applied.
                                </div>
                            </div>
                        )}

                        {invalidInnerJoinRelationships.length > 0 && (
                            <div className="alert-row">
                                <div className="alert-icon">
                                    <img src={require("./icons/relationship-path-warning.svg")} />
                                </div>

                                <div className="alert-text">
                                    <b>
                                        {invalidInnerJoinRelationships.length} potentially invalid{" "}
                                        {Plural.noun(invalidInnerJoinRelationships.length, "relationship")}
                                    </b>{" "}
                                    — There are relationship paths with outer joins between inner joins. If any collection requires data
                                    from fields in these paths, an error will occur.
                                </div>
                            </div>
                        )}
                    </div>
                )}

                <ButtonIcon
                    onClick={() => open.onChange(!open.value)}
                    btnType="border"
                    icon={
                        <>
                            <AlertIcon fill="#ECCD39" />
                            <div className="red-dot" />
                        </>
                    }
                />
            </div>
        )
    );

// {...{
// ...tooltip(
//       `${warningTables.length} table${
//         warningTables.length == 1 ? "" : "s"
//       } in this model are not associated with row-level security.`
//     ),
// }}
