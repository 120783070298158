export const ITEM_SIZE_HEIGHT = 52;
export const MAX_HEIGHT = 300;
export const MAX_WIDTH = 300;

export const getHeightOfDropdown = (list) => {
    const originalHeight = list.length * ITEM_SIZE_HEIGHT;
    return originalHeight >= MAX_HEIGHT ? MAX_HEIGHT : originalHeight;
};

export const getInputSearchCss = (componentTheme, theme) => ({
    ".input-box": {
        background: `${componentTheme.menuBackgroundColorRGB} !important`,
        "border-color": `${componentTheme.menuBackgroundColorRGB} !important`,
        ".search-icon": {
            path: {
                fill: `${componentTheme.inputIconColorRGB} !important`,
            },
        },
        ".clear-icon i": {
            color: `${componentTheme.inputIconColorRGB} !important`,
        },

        input: {
            "font-family": `${theme.general.canvas.fontFamily}, sans-serif !important`,
            color: `${componentTheme.inputTextColorRGB} !important`,
            "::-webkit-input-placeholder": {
                color: `${componentTheme.inputHintTextColorRGB}`,
            },
            ":-ms-input-placeholder": {
                color: `${componentTheme.inputHintTextColorRGB}`,
            },
            "::placeholder": {
                color: `${componentTheme.inputHintTextColorRGB}`,
            },
        },
    },
});

export const getDropdownCss = (componentTheme) => ({
    background: `${componentTheme.menuBackgroundColorRGB}`,
    color: `${componentTheme.menuTextColorRGB}`,
    "&:hover": {
        background: `${componentTheme.menuHoverBackgroundColorRGB}`,
    },

    "&.selected": {
        background: `${componentTheme.menuHoverBackgroundColorRGB}`,
    },
});
