const {toJsDate, isDateExisted} = require("../../utils/dates/date-object");
const {monthShortLabels} = require("../../utils/dates/month-short-labels");

const parseDateLong2 = (str) => {
    const m = new RegExp("^(?:(0?[1-9]|1[012])[\/.](0?[1-9]|[12][0-9]|3[01])[\/.]((19|20)[0-9]{2}))$").exec(str);
    if(!m) {
        return null;
    }


    if(+m[1] > 12) {
        return null;
    }
    const date = {
        day: +m[2],
        month: +m[1],
        year: +m[3],
    };
    if(!isDateExisted(date)) {
        return null;
    }

    const jsDate = toJsDate(date);
    if(jsDate.getMonth() + 1 !== date.month) {
        return null;
    }

    return date;
};
exports.parseDateLong2 = parseDateLong2;


const parseDateLong = (str, limit = {max: Infinity, min: -Infinity}) => {
    const m = new RegExp("^(" + monthShortLabels.join("|") + ") (\\d{1,2}), (\\d{4})$").exec(str);
    if(!m) {
        return null;
    }

    if(+m[3] > limit.max || +m[3] < limit.min) {
        return null
    }
    const date = {
        day: +m[2],
        month: monthShortLabels.indexOf(m[1]) + 1,
        year: +m[3],
    };

    const jsDate = toJsDate(date);
    if(jsDate.getMonth() + 1 !== date.month) {
        return null;
    }

    return date;
};
exports.parseDateLong = parseDateLong;

