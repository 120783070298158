const {avoidRange, avoidRange2} = require("../../live-dashboard/live-filters/filter-types/date/expand/common/avoid-range");
const {chain} = require("../../../../utils/fs");
const {addDate, ge, singleDateRange, } = require("../../../../utils/dates/date-object");

const applyRangesMod = ({focus, selecting, ranges}) => {
    const r1 = ranges.find((r) => r.name === selecting[0]);
    const otherRanges = ranges.filter((r) => r !== r1);

    const r11 = chain(
        ranges.find((r) => r.name === selecting[0]),
        (_) => ({
            ..._,
            range: applyRangeMod({
                oriRange: _?.range,
                replacing: {target: selecting[1], date: focus},
            }),
        })
    );
    return [
        r11,
        ...otherRanges.map((r) => ({
            ...r,
            range: r.reactToOtherRangeChange ? r.reactToOtherRangeChange(r11.range) : avoidRange(r11.range, r.range),
        })),...otherRanges.map((r) => ({
            ...r,
            range: r.reactToOtherRangeChange ? r.reactToOtherRangeChange(r11.range) : avoidRange(r11.range, r.range),
        })),
    ];
};
exports.applyRangesMod = applyRangesMod;

const applyAutoRange = ({focus, range, diff}) => {
    if(!focus) return range;

    const range1 = {
        ...range,
        range: {
            ...applyAutoRangeMod({oriRange: range?.range, replacing: {target: 'from', date: focus}, diff}),
        }
    }
    return range1
}
exports.applyAutoRange = applyAutoRange;


const applyRangeMod = ({oriRange, replacing}) => {
    if(oriRange == null || !oriRange[replacing.target === "from" ? "to" : "from"]) {
        return {from: replacing.date, to: replacing.date};
    }
    const r1 = {
        ...oriRange,
        [replacing.target]: replacing.date
    };

    if(ge(r1.from, r1.to)) {
        return {from: replacing.date, to: replacing.date};
    }
    return r1;
}
const applyAutoRangeMod = ({oriRange, replacing, diff}) => {
    if(oriRange == null || !oriRange[replacing.target === "from" ? "to" : "from"]) {
        return {from: replacing.date, to: replacing.date};
    }

    const oposite = replacing.target === 'from' ? 'to' : 'from';
    const r1 = {
        ...oriRange,
        [replacing.target]: replacing.date,
        [oposite]: oposite === "to" ? addDate(replacing.date, diff) : addDate(replacing.date,  -diff),
    };


    if(ge(r1.from, r1.to)) {
        return {from: replacing.date, to: replacing.date};
    }
    return r1;
}
