import {findMaxE, flatten1, reverse, sum, unique} from "../../../utils/collections";
import {chain} from "../../../utils/fs";
import {arrMapToO} from "../../../utils/objects";
import {camelCaseToSpaces, capitalize} from "../../../utils/strings";

const {promiseCache} = require("../../../utils/promise-cache");
const {mapColorApplicationToThemeColor} = require("../get-field-color");
const {generateColorScale} = require("../../../utils/color-util");

const JOIN_KEY = "matchKey";

// join/match key must be a direct prop of `properties`. so need to fill this prop.
export const addMapJoinKey = (mapData, matchIndex) => {
    if (matchIndex == null) {
        return mapData;
    }
    return {
        ...mapData,
        features: mapData.features.map((feature) => ({
            ...feature,
            properties: {
                ...feature.properties,
                [JOIN_KEY]: feature.properties.match?.[matchIndex],
            },
        })),
    };
};

// const getJoinPair = (location, mapGeoJson) => {
//     const {
//         country, // iso3
//         province, // code
//         county, // name
//         postalCode, // postalCode
//     } = location;
//
//     if (country != null && province && county && postalCode && mapGeoJson.startsWith("postal")) {
//         return {
//             joinKey: "postalCode",
//             getJoinValue: (loc) => loc.postalCode,
//         };
//     }
//
//     if (country != null && province && county) {
//         return {
//             joinKey: "name",
//             getJoinValue: (loc) => loc.county,
//         };
//     }
//
//     if (country != null && province) {
//         return {
//             joinKey: "code",
//             getJoinValue: (loc) => loc.province,
//         };
//     }
//
//     if (country != null) {
//         return {
//             joinKey: "iso3",
//             getJoinValue: (loc) => loc.country,
//         };
//     }
//
//     return "code";
// };

// const getJoinPair2 = (mapData) => {
//     const mapProps = mapData.features[0].properties;
//     const joinValueProp = mapProps.postalCode
//         ? "postalCode"
//         : mapProps.county
//             ? "county"
//             : mapProps.province
//                 ? "province"
//                 : mapProps.country ? "country" : "code"
//
//     return {
//         joinKey: "name",
//         getJoinValue: (loc) => loc[joinValueProp],
//     }
// };

// find usable index of key from `match` array
export const getMapLocationMatchIndex = ({chartData, mapData}) => {
    if (!chartData.values?.length) {
        return;
    }

    let matchIndex;

    const findMatchIndex = (loc) => {
        for (const feature of mapData.features) {
            if (!feature.properties.match) {
                continue;
            }
            const mi = feature.properties.match.indexOf(loc.match.toString().toLowerCase());
            if (mi >= 0) {
                return mi;
            }
        }
    };

    for (const loc of chartData.values) {
        matchIndex = findMatchIndex(loc.location);
        if (matchIndex != null) {
            break;
        }
    }

    return matchIndex;
};

// get the chart data values that can be shown on the map (since some value locations do not match any location on map, e.g. wrong iso num)
const getPresentChartDataValues = ({chartData, mapData, matchIndex}) => {
    const allMatches = mapData.features.map((feature) => feature.properties.match?.[matchIndex]).filter((v) => v);
    return chartData.values.filter((loc) => allMatches.includes(loc.location.match.toString().toLowerCase()));
};

// get color of a value group.
// if apply color by group, color of a location by its largest group.
const cGetMapCategoricalValueColor = ({tile, theme, allValueGroups}) => {
    const usedColorApplication = (() => {
        if (tile.groupField == null) {
            return tile.style.locationColorApplication;
        }
        if (tile.style.applyColorBy === "Group") {
            return tile.style.locationColorApplication;
        }
        return tile.style.groupColorApplication;
    })();

    const colorApplication = usedColorApplication || {
        $type: "SchemeColorApplication",
        index: 0,
        type: "Categorical",
    };

    const displayColor = mapColorApplicationToThemeColor(theme.dataVisualization.dataColorPalettes, colorApplication);

    const colorScale = generateColorScale(displayColor, false); // isSequential = false

    const groupIndexMap = arrMapToO(allValueGroups, (_, i) => i);

    return (group) => {
        return colorScale.getColor(groupIndexMap[group]);
    };
};

const getAllValueGroups = (values) => {
    // old. longest valueGroups may not contain all the valueGroups. e.g., countries data grouped by provinces
    // return chain(
    //     values,
    //     (values) => findMaxE(values, (v) => v.valueGroups.length),
    //     (mostGroupsValue) => mostGroupsValue.valueGroups.map((vg) => vg.group),
    // )

    return chain(
        values,
        // get groups of each location
        (_) => _.map(({valueGroups}) => valueGroups.map(({group}) => group)),
        // flatten the list
        (_) => flatten1(_),
        // make unique since locations may contain same groups
        (_) => unique(_)
    );
};

export const getMapSeries = ({tile, theme, chartData, mapData, matchIndex}) => {
    // if no data is available, generate a dummy series to show the map with all empty locations
    if (!chartData.values?.length) {
        return [
            {
                isDummy: true,
            },
        ];
    }

    // const {joinKey, getJoinValue} = getJoinPair(chartData.values[0].location, chartData.mapGeoJson);
    // const {joinKey, getJoinValue} = getJoinPair2(mapData);
    const hasGroupField = tile.groupField != null;

    const getTotalValue = (valueGroups, prop) => sum(valueGroups, (vg) => vg[prop]);

    // if a location does not match any location on the map, exclude.
    // since it may makes color axis confusing with unrelated values.
    const presentChartDataValues = getPresentChartDataValues({
        chartData,
        mapData,
        matchIndex,
    });

    // just count the groups belonging to present locations
    const allValueGroups = hasGroupField && getAllValueGroups(presentChartDataValues);
    const getValueGroupColor = hasGroupField && cGetMapCategoricalValueColor({tile, theme, allValueGroups});

    const processedData = presentChartDataValues
        .map(({location, valueGroups, tileActionData}) => {
            return {
                // [joinKey]: getJoinValue(location),
                [JOIN_KEY]: location.match.toString().toLowerCase(),

                hasProvinces: location.hasProvinces,
                hasCounties: location.hasCounties,
                // opacity: !location.hasProvinces && !location.hasCounties ? 0.5 : 1,

                value: getTotalValue(valueGroups, "value"),
                previousValue: getTotalValue(valueGroups, "previousValue"),

                tileActionData,

                ...(hasGroupField &&
                    (() => {
                        const coloredValueGroups = chain(
                            valueGroups,
                            // groups is in asc. reverse if category sort is desc
                            (_) => (tile.categorySort?.direction === "Desc" ? reverse(_) : _),
                            (_) =>
                                _.map((vg) => ({
                                    ...vg,
                                    color: getValueGroupColor(vg.group),
                                }))
                        );
                        const largestGroup = findMaxE(coloredValueGroups, (vg) => vg.value);
                        // these are used in tooltip and for coloring location by largest group
                        return {
                            valueGroups: coloredValueGroups,
                            ...(tile.style.applyColorBy === "Group"
                                ? {
                                      largestGroup,
                                      // only set location color here when apply color by group, otherwise set in color axis
                                      color: largestGroup.color,
                                  }
                                : {
                                      color: null,
                                  }),
                        };
                    })()),
            };
        })
        .filter((v) => v);

    return [
        {
            name: getMapSeriesName(tile, chartData.values[0].measureGroup),
            data: processedData,
            joinBy: JOIN_KEY,
            ...(hasGroupField && {
                // this is used in legend
                groups: chain(
                    allValueGroups,
                    // groups is in asc. reverse if category sort is desc
                    (_) => (tile.categorySort?.direction === "Desc" ? reverse(_) : _),
                    (_) =>
                        _.map((group) => ({
                            group,
                            color: getValueGroupColor(group),
                        }))
                ),
            }),
            range: chartData.range,
            previousRange: chartData.previousRange,
            customStack: chartData.values[0].measureGroup,
            mapGeoJson: chartData.mapGeoJson,
        },
    ];
};

export const getMapSeriesName = (tile, measureGroupId) => {
    return tile.valueFields.find((vf) => vf.id === measureGroupId).measureFields[0].displayName;
};

export const useColorAxis = (tile) => {
    return !tile.groupField || tile.style.applyColorBy === "Measure";
};

export const isColorAxisVertical = (tile) => {
    return ["Left", "Right"].includes(tile.style.legendStyle.legendPosition);
};

// check if the tile fields are available for drilling
// export const isDrillingAllowed = (tile, locProperties) => {
//     const {iso3, code, country, province, nameClean, postalCode, name} = locProperties;
//     // no more drilling at postal code level
//     if (postalCode) {
//         return false;
//     }
//     // only drilling from global to country when provinceField is available
//     if (iso3) {
//         return !!tile.provinceField;
//     }
//     // only drilling from country to province when countyField is available
//     if (country && code) {
//         return !!tile.countyField;
//     }
//     // only drilling from province to county when postalCodeField is available
//     if (country && province && nameClean) {
//         return !!tile.postalCodeField;
//     }
// };

export const isDrillingAllowed2 = ({mapLocProperties, dataOptions, tile}) => {
    const {hasProvinces, hasCounties} = dataOptions;
    const {country, province, county, postalCode} = mapLocProperties;

    if (postalCode) {
        return false;
    }
    if (country && province && county) {
        return !!tile.postalCodeField;
    }
    if (country && province) {
        return hasCounties && (!!tile.countyField || !!tile.postalCodeField);
    }
    if (country) {
        return hasProvinces && (!!tile.provinceField || !!tile.countyField || !!tile.postalCodeField);
    }
    return true;
};

// get the step for the next drilling
// export const getDrillingStepProps = (locProperties) => {
//     const {iso3, code, country, province, nameClean, name} = locProperties;
//     if (iso3) {
//         return {country: iso3, name};
//     }
//     if (country && code) {
//         return {country, province: code, name};
//     }
//     if (country && province && nameClean) {
//         return {country, province, county: nameClean, name};
//     }
// };

// get values used in `tableOverrides` for drilling
export const getDrillingStepProps2 = ({mapLocProperties, dataOptions}) => {
    const {matchKey} = dataOptions;
    const {country, province, county, postalCode} = mapLocProperties;
    const drillingCountryProp = Object.keys(country).find((prop) => {
        return country[prop].toString().toLowerCase() === matchKey.split("|")[0];
    });

    if (country && province && county && postalCode) {
        return {
            country: country[drillingCountryProp],
            province: province.code,
            county: county.nameClean,
            name: county.name,
            postalCode: postalCode.code,
        };
    }
    if (country && province && county) {
        return {
            country: country[drillingCountryProp],
            province: province.code,
            county: county.nameClean,
            name: county.name,
        };
    }
    if (country && province) {
        return {
            country: country[drillingCountryProp],
            province: province.code,
            name: province.name,
        };
    }
    if (country) {
        return {country: country[drillingCountryProp], name: country.name};
    }
};

// get the very first step. need to extract the step from default region or map name.
export const getFirstStep = ({tile, mapGeoJson}) => {
    const mapName = mapGeoJson.replace(".geojson", "");

    if (tile.style.defaultLocation === "Region") {
        if (tile.style.region) {
            return {name: camelCaseToSpaces(tile.style.region)};
        }
        const nameArr = mapName.split("-");
        return {
            name: nameArr
                .slice(0, nameArr.length - 1)
                .map((w) => capitalize(w))
                .join(" "),
        };
    }

    const {0: level, 1: country, 2: province, 3: county} = mapName.split("-");

    if (level === "countries") {
        return {isGlobal: true};
    }
    if (level === "province") {
        return {country, name: country.toUpperCase()};
    }
    if (level === "county") {
        return {country, province, name: province};
    }
    if (level === "postalcode") {
        return {country, province, county, name: county};
    }
};

export const fetchMapData = promiseCache((mapGeoJson) => fetch(`https://maps.verbdata.app/${mapGeoJson}`).then((res) => res.json()), undefined, 0);
