const {rearrangeRects} = require("./rearrange-rects");
const {chain} = require("../../../../utils/fs");

const responsiveTiles = ({tiles, colCount}) => {
    const rects = chain(
        tiles.map((tile) => ({...tile.position, ...tile.size})), // position is used for checking order
        (_) => rearrangeRects(_, {colCount})
    );
    return tiles.map((tile, i) => ({
        ...tile,

        ...(() => {
            const rect = rects[i];
            return {
                position: {
                    x: rect.x,
                    y: rect.y,
                },
                size: {
                    width: rect.width,
                    height: rect.height,
                },
            };
        })(),
    }));
};
exports.responsiveTiles = responsiveTiles;
