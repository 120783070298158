import "./date-filter-expand-desktop.scss";
import "./date-filter-expand-mobile.scss";

import {cx} from "emotion";
import * as React from "react";

import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {Form2} from "@common/react/cs-form/form2";
import {UseState} from "@common/react/use-state";

import {filterTypesToColumnTypes} from "../../../../../../../../web-client/src/routes/collection/common/filters/filter-left-panel/filter-configs";
import {cascadeCollect} from "@common/utils/cascade";
import {ModalScrollSupport} from "../../../../../../modal-scroll-support/modal-scroll-support";
import {DropdownSelectLive} from "../../../../../filters/common/dropdown-select/dropdown-select-live";
import {SelectableListChips} from "../../text/selectable-list/chips/selectable-list-chips";

import {OnUnmounted} from "@common/react/on-unmounted";
import {StyledClass} from "@common/react/styled-class";
import {AccessibleDateRangesPicker} from "@common/ui-components/live/filters/date-ranges-picker/accessible-date-ranges-picker";
import {CheckboxLine} from "../../../../../../../../web-client/src/routes/common/checkbox-line/checkbox-line";
import {mainToComparing} from "@common/logic/date-time/date-util";
import {scope} from "@common/react/scope";

import {Controller} from "./controller/controller";

export const DateFilterExpand = ({state: oriState, width, size, filter, onClose, showApplyBtn, noComparing, showSelectTimezone = false}) => {
    const state1 = {
        value: oriState.value,
        onChange: (value) => {
            oriState.onChange({
                ...value,
                main: value.main
                    ? {
                          ...value.main,
                          to: {
                              ...value.main.to,
                              ...(value.allowTimeFilter ? {} : {hours: 23, minutes: 59, seconds: 59}),
                          },
                      }
                    : null,
                comparing: value.comparing
                    ? {
                          ...value.comparing,
                          to: {
                              ...value.comparing.to,
                              ...(value.allowTimeFilter ? {} : {hours: 23, minutes: 59, seconds: 59}),
                          },
                      }
                    : null,
            });
        },
    };

    return cs(
        consumeContext("theme"),
        consumeContext("modelForCollection"),
        consumeContext("viewWidth"),
        [
            "fields",
            ({modelForCollection: model}, next) =>
                next(
                    cascadeCollect(model, "tables[*].columns[*]", (column, {}, {1: table}) => filterTypesToColumnTypes[filter.$type].includes(column.dataType) && {table, column})
                ),
        ],
        [
            "state",
            ({}, next) =>
                UseState({
                    initValue: state1.value,
                    next,
                }),
        ],
        [
            "form",
            ({state}, next) =>
                Form2({
                    fields: [
                        {
                            path: ["selectedFields"],
                            validators: [
                                {
                                    when: () => true,
                                    validate: (v, {data}) => (data.columns || []).length > 0,
                                },
                            ],
                        },
                    ],
                    data: state,
                    onSubmit: async () => {
                        state1.onChange(state.value);
                        onClose();
                    },
                    next,
                }),
        ],
        [
            "dateRangesPicker",
            ({state, theme}, next) => {
                const translateMainToComparing = (mainRange) => {
                    if (mainRange == null || !state.value.comparing) {
                        return null;
                    } else {
                        return mainToComparing(mainRange, filter.defaultOption);
                    }
                };
                const buttonTheme = theme?.general?.buttons;

                return AccessibleDateRangesPicker({
                    theme,

                    ranges: [
                        {
                            color: buttonTheme?.primaryButton?.backgroundColorRGB,
                            name: "main",
                            range: state.value.main,
                        },
                        state.value.enableComparing &&
                            !noComparing && {
                                color: buttonTheme?.primaryButton?.backgroundColorRGB,
                                name: "comparing",
                                range: state.value.comparing || state.value.main,
                                reactToOtherRangeChange: translateMainToComparing,
                            },
                    ].filter((v) => v),
                    isMobile: size === "mobile",
                    next,
                });
            },
        ],
        ({dateRangesPicker, state, theme, fields, form, viewWidth}) => {
            const componentTheme = theme.general.components;

            const resetDateFilter = () => {
                state.onChange(state1.value);
            };

            const inputStyle = {
                border: `${componentTheme.inputBorderWidth}px solid ${componentTheme.inputBorderColorRGB}`,
                background: `${componentTheme.inputBackgroundColorRGB}`,
                color: `${componentTheme.inputTextColorRGB}`,
                borderRadius: `${componentTheme.inputCornerRadius}px`,
            };

            const primaryButton = theme.general.buttons.primaryButton;
            const secondaryButton = theme.general.buttons.secondaryButton;
            const hyperLinkButton = theme.general.buttons.hyperlinkButton;

            const getCss = (selectedTheme) => ({
                background: `${selectedTheme.backgroundColorRGB} !important`,
                color: ` ${selectedTheme.fontColorRGB} !important`,
                border: `${selectedTheme.borderWidth}px solid ${selectedTheme.borderColorRGB} !important`,
                "border-radius": `${selectedTheme.cornerRadius}px !important`,
                "font-family": `"${theme.general.canvas.fontFamily}", sans-serif !important`,

                "&:hover": {
                    background: `${selectedTheme.hoverBackgroundColorRGB} !important`,
                    color: `${selectedTheme.hoverFontColorRGB} !important`,
                    "border-color": `${selectedTheme.hoverBorderColorRGB} !important`,
                },
            });

            return cs(
                [
                    "cssClass",
                    (_, next) =>
                        StyledClass({
                            content: {
                                background: `${componentTheme.menuBackgroundColorRGB}`,
                                color: `${componentTheme.menuTextColorRGB}`,

                                "&:hover": {
                                    background: `${componentTheme.menuHoverBackgroundColorRGB}`,
                                },

                                "&.selected": {
                                    background: `${componentTheme.menuHoverBackgroundColorRGB}`,
                                },
                            },
                            next,
                        }),
                ],
                [
                    "labelStyle",
                    (_, next) =>
                        StyledClass({
                            content: {
                                color: componentTheme.inputLabelTextColorRGB,
                            },
                            next,
                        }),
                ],
                ({cssClass, labelStyle}) => (
                    <div
                        className={cx("date-filter-expand-754 verb-filter-date-expand", size === "mobile" ? "mobile" : "desktop")}
                        style={{
                            width: (size === "mobile" ? viewWidth?.value || window.innerWidth - 20 : null) || width,
                            background: componentTheme.menuBackgroundColorRGB,
                            borderRadius: `${componentTheme.menuCornerRadius}px`,
                        }}
                    >
                        {size === "mobile" && (
                            <>
                                {ModalScrollSupport()}
                                <div className="close-btn" onClick={() => onClose()}>
                                    {CloseIcon()}
                                </div>
                            </>
                        )}

                        {filter.columns?.length > 1 && (
                            <>
                                {filter.multiSelectionOption != "SingleSelect" ? (
                                    <div className="select-multiple-field" ref={form.field(["selectedFields"]).domRef}>
                                        <div className={cx("label", labelStyle)}>
                                            {filter.multiSelectionOption === "Any" ? "Filter By (results meet any criteria)" : "Filter By (results meet all criteria)"}
                                        </div>

                                        {SelectableListChips({
                                            closeAllOtherDropdownWhenOpen: false,
                                            searchable: false,
                                            hasError: {value: form.field(["selectedFields"]).error},
                                            className: "selectable-chips",
                                            theme,
                                            list: filter.columns || [],
                                            ...(() => {
                                                const isSelected = (v) => state.value.columns.find((c) => c.modelColumnID == v.modelColumnID);
                                                return {
                                                    isSelected,
                                                    onSelect: (v) => {
                                                        if (isSelected(v)) {
                                                            state.change((state) => ({
                                                                ...state,
                                                                columns: state.columns.filter((c) => c.modelColumnID != v.modelColumnID),
                                                            }));
                                                        } else {
                                                            state.change((state) => ({
                                                                ...state,
                                                                columns: state.columns.concat(v),
                                                            }));
                                                        }
                                                    },
                                                    valueToLabel: (v) => v.displayName || fields.find((item) => item.column.id == v.modelColumnID)?.column?.visualNameFull,
                                                };
                                            })(),
                                        })}
                                    </div>
                                ) : (
                                    <div className="select-multiple-field">
                                        <div className={cx("label", labelStyle)}>Filter By</div>

                                        {DropdownSelectLive({
                                            closeAllOtherDropdownWhenOpen: false,
                                            hasError: form.field(["selectedFields"]).error,
                                            domRef: form.field(["selectedFields"]).domRef,
                                            list: filter.columns || [],
                                            valueToLabel: (v) => v.displayName || fields.find((item) => item.column.id == v.modelColumnID)?.column?.visualNameFull,
                                            isSelected: (v) => state.value.columns.find((c) => c.modelColumnID == v.modelColumnID),
                                            onChange: (v) => {
                                                state.change((state) => ({...state, columns: [v]}));
                                            },
                                            toggleStyle: inputStyle,
                                            iconColor: componentTheme.inputIconColorRGB,
                                            dropdownCss: cssClass,
                                            borderRadius: componentTheme.menuCornerRadius,
                                        })}
                                    </div>
                                )}
                            </>
                        )}

                        {Controller({
                            isMobile: size === "mobile",
                            filter,
                            state,
                            dateRangesPicker,
                            theme,
                            noComparing,
                            showSelectTimezone,
                            onReset: () => {
                                state.onChange(state1.value);
                            },
                            onChange: ({name, range}) => {
                                const translateMainToComparing = (mainRange) => {
                                    if (mainRange == null || !state.value.comparing) {
                                        return null;
                                    } else {
                                        return mainToComparing(mainRange, filter.defaultOption);
                                    }
                                };
                                if (name === "main") {
                                    state.onChange({
                                        ...state.value,
                                        main: range,
                                        ...(state.value.enableComparing &&
                                            !noComparing && {
                                                comparing: translateMainToComparing(range),
                                            }),
                                    });
                                } else {
                                    state.onChange({
                                        ...state.value,
                                        comparing: range,
                                    });
                                }
                            },
                        })}

                        {filter.includeNullDates &&
                            CheckboxLine({
                                state: scope(state, ["includeNullDates"]),
                                label: "Include Data without Dates",
                                background: theme.general.buttons.primaryButton.backgroundColorRGB,
                                textColor: componentTheme.inputLabelTextColorRGB,
                            })}

                        {showApplyBtn ? (
                            <div className="controls">
                                <div className="left">
                                    {(() =>
                                        cs(
                                            [
                                                "css",
                                                (_, next) =>
                                                    StyledClass({
                                                        content: getCss(hyperLinkButton),
                                                        next,
                                                    }),
                                            ],
                                            ({css}) => (
                                                <a onClick={resetDateFilter} className={cx("reset")}>
                                                    Reset
                                                </a>
                                            )
                                        ))()}
                                </div>
                                <div className="right">
                                    {(() =>
                                        cs(
                                            [
                                                "css",
                                                (_, next) =>
                                                    StyledClass({
                                                        content: getCss(secondaryButton),
                                                        next,
                                                    }),
                                            ],
                                            ({css}) => (
                                                <button onClick={onClose} className={cx("cancel", css)}>
                                                    Cancel
                                                </button>
                                            )
                                        ))()}
                                    {(() =>
                                        cs(
                                            [
                                                "css",
                                                (_, next) =>
                                                    StyledClass({
                                                        content: getCss(primaryButton),
                                                        next,
                                                    }),
                                            ],
                                            ({css}) => (
                                                <button
                                                    onClick={() => {
                                                        form.submit();
                                                    }}
                                                    className={css}
                                                >
                                                    Apply
                                                </button>
                                            )
                                        ))()}
                                </div>
                            </div>
                        ) : (
                            OnUnmounted({
                                action: () => {
                                    if (form.valid) {
                                        state1.onChange(state.value);
                                    }
                                },
                            })
                        )}
                    </div>
                )
            );
        }
    );
};

const CloseIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 15 15">
        <path
            data-name="Icon material-close"
            d="M22.5,9.011,20.989,7.5,15,13.489,9.011,7.5,7.5,9.011,13.489,15,7.5,20.989,9.011,22.5,15,16.511,20.989,22.5,22.5,20.989,16.511,15Z"
            transform="translate(-7.5 -7.5)"
            fill="#3B3B3B"
        />
    </svg>
);
