import * as React from "react";
import {cs} from "@common/react/chain-services";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {Expandable} from "../../../../../../../../../../../common/expandable/expandable";
import {UseState} from "@common/react/use-state";
import "./number-format.scss";
import {CurrencySymbol} from "./currency-symbol";
import {Separators} from "./separators";
import {DecimalPlaces} from "./decimal-places";
import {Negatives} from "./negatives";
import {createNumericFormatter} from "@common/ui-components/charts/common/formatters/numeric/numeric-formatter";
import {spc} from "@common/react/state-path-change";
import {chain} from "@common/utils/fs";
import {roundTo} from "@common/ui-components/charts/common/formatters/numeric/round-to";

export const NumberFormat = ({field}) =>
    cs(({}) => {
        const numericProperties = field.value.numericProperties;

        const numericFormatter = createNumericFormatter({
            displayType: numericProperties?.displayType || "Number",

            decimalSeperator: numericProperties?.decimalSeperator || "Dot",
            thousandsSeperator: numericProperties?.thousandsSeperator || "Comma",
            decimalPlaces: numericProperties?.decimalPlaces ?? ((field.value.aggregationFunc ?? "").startsWith("Count") ? 0 : 2),

            negativeFormat: numericProperties?.negativeFormat || "Parentheses",

            currencyProperties: {
                currency: numericProperties?.currencyProperties?.currency || "USD",
                currencySymbolPlacement: numericProperties?.currencyProperties?.currencySymbolPlacement || "Left",
                currencySymbol: numericProperties?.currencyProperties?.currencySymbol || "$",
                spaceBetweenSymbolAndNumber: numericProperties?.currencyProperties?.spaceBetweenSymbolAndNumber,
            },
        });

        const formatter = (v) =>
            chain(
                v,
                (v) => (numericProperties?.displayType === "Percentage" ? v * 100 : v),
                (_) => (numericProperties?.decimalPlaces != null ? roundTo(_, numericProperties.decimalPlaces) : roundTo(_, 2)),
                (_) => numericFormatter(_)
            );

        return (
            <div className="number-format-2gl">
                <div className="preview">
                    <div className="header">Preview</div>
                    <div className="content">
                        <div className="positive">{formatter(numericProperties?.displayType === "Percentage" ? 0.123456 : 1234.56)}</div>
                        <div className="negative">{formatter(numericProperties?.displayType === "Percentage" ? -0.123456 : -1234.56)}</div>
                    </div>
                </div>
                <Expandable
                    {...{
                        label: "Show As",
                        render: () => (
                            <div className="show-as-5qo">
                                {DropdownSelect({
                                    label: "Show As",
                                    list: ["Number", "Percentage", "Ratio", "Currency", "Currency Ratio"].map((label) => ({
                                        label,
                                        value: label.replace(/ /g, ""),
                                    })),
                                    valueToLabel: (v) => v.label,
                                    isSelected: (v) => v.value === (field.value.numericProperties?.displayType || "Number"),
                                    onChange: (v) => spc(field, ["numericProperties", "displayType"], () => v.value),
                                })}
                            </div>
                        ),
                    }}
                />

                {field.value.numericProperties?.displayType?.startsWith("Currency") && CurrencySymbol({field})}

                {Separators({field})}

                {DecimalPlaces({field})}

                {Negatives({field})}
            </div>
        );
    });
