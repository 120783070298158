import React from "react";
import {cs} from "@common/react/chain-services";
import {spc} from "@common/react/state-path-change";
import {DropdownSelect2} from "@common/ui-components/dropdown-select/dropdown-select2";
import "./numeric.scss";
import {keyed} from "@common/react/keyed";
import {last, removeIndex} from "@common/utils/collections";
import {TrashIcon} from "../../../../../../../../common/icons/trash-icon";
import {DebounceTextInput} from "../../../../../../../../../../../common/form/debounce-input/debounce-text-input";
import {DebounceNumberInput} from "../../../../../../../../../../../common/form/debounce-input/debounce-number-input";
import {ffToDropdown} from "../../../../../../../../../../../common/form/ff-to-dropdown";
import {ffToNumberInput} from "../../../../../../../../../../../common/form/ff-to-number-input";
import {ffToTextInput} from "../../../../../../../../../../../common/form/ff-to-text-input";
import {getPath} from "@common/utils/arr-path";

export const Numeric = ({step, form}) => {
    const valueLookup = step.value.valueLookup;
    return (
        <div className="config-group list-numeric-9gf">
            <div className="label">Mapping formula</div>

            {valueLookup?.valueMappings?.map((vm, vmi) =>
                cs(keyed(vmi), ({}) =>
                    processor({
                        step,
                        form,
                        path: ["valueLookup", "valueMappings", vmi],
                        onRemove: () => spc(step, ["valueLookup", "valueMappings"], (valueMappings) => removeIndex(vmi, valueMappings)),
                    })
                )
            )}

            <button
                // disabled={valueLookup.valueMappings?.length > 0 && !last(valueLookup.valueMappings).compare}
                className="btn-add"
                onClick={() => spc(step, ["valueLookup", "valueMappings"], (valueMappings) => [...(valueMappings || []), {}])}
            >
                Add
            </button>
        </div>
    );
};

const processor = ({step, form, path, onRemove}) => {
    const valueMapping = getPath(step.value, path);
    return (
        <div className="vm-processor">
            <div className="text">When the original value is...</div>
            <div className="details">
                {DropdownSelect2({
                    label: "Comparison",
                    list: comparisons,
                    valueToLabel: (v) => v.label,
                    ...ffToDropdown(form.field([...path, "compare"]), ["value"]),
                })}

                {valueMapping.compare && (
                    <>
                        {valueMapping.compare.includes("Between") && (
                            <>
                                {DebounceNumberInput({
                                    label: "Start",
                                    ...ffToNumberInput(form.field([...path, "lookup"])),
                                    showZero: true,
                                })}
                                {DebounceNumberInput({
                                    label: "End",
                                    ...ffToNumberInput(form.field([...path, "lookup2"])),
                                    showZero: true,
                                })}
                            </>
                        )}

                        {!valueMapping.compare.includes("Between") &&
                            !valueMapping.compare.includes("Null") &&
                            DebounceNumberInput({
                                label: "Match",
                                ...ffToNumberInput(form.field([...path, "lookup"])),
                                showZero: true,
                            })}

                        {DebounceTextInput({
                            label: "Show Value",
                            ...ffToTextInput(form.field([...path, "value"])),
                        })}
                    </>
                )}

                <div className="delete-btn" onClick={onRemove}>
                    {TrashIcon()}
                </div>
            </div>
        </div>
    );
};

const comparisons = [
    {
        label: "Equal to",
        value: "Equals",
    },
    {
        label: "Less Than",
        value: "LessThan",
    },
    {
        label: "Less Than or Equal to",
        value: "LessThanOrEquals",
    },
    {
        label: "Greater Than",
        value: "GreaterThan",
    },
    {
        label: "Greater Than or Equal to",
        value: "GreaterThanOrEquals",
    },
    {
        label: "Between (Inclusive)",
        value: "BetweenInc",
    },
    {
        label: "Between (Inclusive of Start)",
        value: "BetweenIncStart",
    },
    {
        label: "Between (Inclusive of End)",
        value: "BetweenIncEnd",
    },
    {
        label: "Between (Not Inclusive)",
        value: "BetweenNotInc",
    },
    {
        label: "Is Null",
        value: "IsNull",
    },
    {
        label: "Is Not Null",
        value: "IsNotNull",
    },
];
