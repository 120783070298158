import {LpColorLine} from "../../../../../common/left-panel/lp-color-line";
import {ffToColorPickerLine} from "../../../../../../../../common/form/ff-to-color-picker-line";
import {NumberInput} from "../../../../../../../../common/form/number-input/number-input";
import {ffToNumberInput} from "../../../../../../../../common/form/ff-to-number-input";
import React from "react";

export const HyperlinkButtonTheme = ({form}) => (
    <div className="content">
        {LpColorLine({
            label: "Default Text Color",
            ...ffToColorPickerLine(form.field(["general", "buttons", "hyperlinkButton", "fontColorRGB"])),
        })}

        {LpColorLine({
            label: "Hover Text Color",
            ...ffToColorPickerLine(form.field(["general", "buttons", "hyperlinkButton", "hoverFontColorRGB"])),
        })}
    </div>
);
