import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {consumeContext} from "@common/react/context";
import {Form2} from "@common/react/cs-form/form2";
import {scope} from "@common/react/scope";
import {changePath} from "@common/utils/arr-path";
import {last} from "@common/utils/collections";
import * as React from "react";
import {defaultValue, tileFormConfig} from "./adding-tile";
import {SelectTileType} from "../../../../common/select-tile-type/select-tile-type";
import {tileTypes} from "../../../../common/select-tile-type/tile-types";
import {TextInput} from "../../../../../../../../common/form/text-input/text-input";
import {ffToTextInput} from "../../../../../../../../common/form/ff-to-text-input";
import {Button} from "../../../../../../../../common/form/buttons/button/button";
import {ConfirmDialog} from "../../../../../common/confirm-dialog/confirm-dialog";
import {disableSelectTileType} from "../../../../common/select-tile-type/disable-select-tile-type";

export const AddingTileDashboard = ({panel, collection}) =>
    cs(
        [
            "confirmDialog",
            (_, next) =>
                ConfirmDialog({
                    next,
                    title: "Change Tile Type",
                    body: "When changing the tile type the current settings will be lost. Are you sure you want to change the tile type?",
                    submitText: "Change Tile Type",
                    cancelText: "Cancel",
                }),
        ],
        ({confirmDialog}) => {
            return (
                <div className="adding-tile-left-panel-content-2s9">
                    <div className="select-type">
                        {SelectTileType({
                            panel,
                            list: tileTypes,
                            usingClick: collection.value.tile,
                            value: collection.value.tile,
                            changeTitleWhenChangeTile: true,
                            disabledChecking: true,
                            onChange: async (tile) => {
                                if (
                                    collection.value.tile.fieldValidity != "CannotDisplay" &&
                                    (collection.value.tile.$type == "ContainerTile" ? collection.value.tile.tiles.length > 0 : true)
                                ) {
                                    const resp = await confirmDialog.show();
                                    if (resp) {
                                        collection.change((c) => ({
                                            ...c,
                                            tile,
                                        }));
                                    }
                                } else {
                                    collection.change((c) => ({
                                        ...c,
                                        tile,
                                    }));
                                }
                            },
                        })}
                    </div>
                </div>
            );
        }
    );
