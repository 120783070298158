import React from "react";
import {cs} from "@common/react/chain-services";
import "./model-table-dataset-configuration.scss";
import {TableSelect} from "../../../../common/table-select/table-select";

export const ModelTableDatasetConfiguration = ({transformation, step, model}) =>
    cs(({}) => {
        const allUsedModelTableIDs = transformation.value.steps.filter((s) => s.$type === "ModelTableDatasetStep" && s.id !== step.value.id).map((s) => s.modelTableID);

        const isDataView = (t) => t.$type === "ViewModelTable" && t.transformationID === transformation.value.id;

        return (
            <div className="model-table-dataset-configuration-f2r">
                <div className="config-group">
                    <div className="label">Select Table</div>
                    <div className="select-table">
                        {TableSelect({
                            tables: model.tables.filter((t) => !allUsedModelTableIDs.includes(t.id) && !isDataView(t)),
                            label: "Table",
                            state: {
                                value: step.value.modelTableID,
                                onChange: (table) =>
                                    step.onChange({
                                        ...step.value,
                                        modelID: model.id,
                                        modelTableID: table.id,
                                    }),
                            },
                            isUnavailable: isDataView,
                        })}
                    </div>
                </div>
            </div>
        );
    });
