import {cs} from "@common/react/chain-services";
import * as React from "react";
import "./api-collection-filters.scss";
import {consumeContext} from "@common/react/context";
import {cx} from "emotion";
import {filterIcons} from "../../../../../../collection/edit/tabs/filters/left-panel/filters-in-collection/filters-in-collection";
import {FieldItem} from "../../../../../../collection/edit/common/field-item/field-item";

export const ApiCollectionFilters = ({collection}) =>
    cs(consumeContext("routing"), ({routing}) => (
        <div className="api-collection-filters-56y">
            {collection.value?.filters.map((f, i) => (
                <FieldItem
                    key={i}
                    onClick={() => routing.goto("edit-filter", {filterId: f.id})}
                    leftIcon={<img src={filterIcons[f.$type]} width="26px" alt="" />}
                    rightIcon={<i className="far fa-arrow-right" aria-hidden="true" />}
                    label={f.label ?? "No label"}
                />
            ))}
        </div>
    ));
