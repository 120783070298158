export const comparisonMethods = [
    {
        label: "Equals",
        name: "Equals",
        hasDefaultValue: true,
    },
    {
        label: "Does Not Equal",
        name: "DoesNotEqual",
        hasDefaultValue: true,
    },
    {
        label: "Starts With",
        name: "StartsWith",
        hasDefaultValue: true,
    },
    {
        label: "Ends With",
        name: "EndsWith",
        hasDefaultValue: true,
    },
    {
        label: "Contains",
        name: "Contains",
        hasDefaultValue: true,
    },
    {
        label: "Does Not Contain",
        name: "DoesNotContain",
        hasDefaultValue: true,
    },
    {
        label: "Is Blank",
        name: "IsBlank",
        hasDefaultValue: false,
    },
    {
        label: "Is Not Blank",
        name: "IsNotBlank",
        hasDefaultValue: false,
    },
];

export const textFilterValueLabelMap = comparisonMethods.reduce((r, c) => ({...r, [c.name]: [c.label]}), {})