const stretch = (rects, {colCount}) => {
    let waitings = rects.slice(0);
    let ret = [];

    for (; waitings.length; ) {
        const rect = waitings.shift();

        ret.push(
            (() => {
                const blankWidth = getBlankWidth(rect, {colCount, waitings});

                if (!blankWidth) {
                    return rect;
                } else {
                    return {
                        ...rect,
                        width: rect.width + blankWidth,
                    };
                }
            })()
        );
    }

    return ret;
};
exports.stretch = stretch;

const getBlankWidth = (rect, {colCount, waitings}) => {
    const r = waitings.find((r) => r.y === rect.y);
    if (r) {
        return null;
    }
    return colCount - (rect.x + rect.width);
};
