import React from "react";
import {cs} from "@common/react/chain-services";
import {ArrowLeftIcon} from "../../../../../../assets/icons/ArrowLeftIcon";
import {LeftPanelClosable} from "../../../../../common/left-panel-closable/left-panel-closable";
import {UseState} from "@common/react/use-state";
import {ModelPanelHelper} from "../../../../common/model-panel-helper";
import {lighten} from "@common/utils/color-util";
import {spc} from "@common/react/state-path-change";
import {ArrowIcon} from "../suggest-relationship/suggest-relationship-left-panel";
import "../suggest-relationship/suggest-relationship.scss";
import {tooltipService3} from "../../../../../common/tooltip3/tooltip-service-3";
import {SuggestRelationshipLine} from "../suggest-relationship/suggest-relationship-line";

export const AllSuggestedRelationshipsLeftPanel = ({
    model,
    deSelectTable,
    dataSources,
    suggestedRelationships,
    viewAllSuggestedRelationships: onUpdate,
}) =>
    cs(
        (_, next) => (
            <LeftPanelClosable
                {...{
                    onClose: () => deSelectTable(),
                    label: <div className="suggest-relationship-fss4 ">SUGGESTED RELATIONSHIPS</div>,
                    content: next(),
                }}
            />
        ),
        tooltipService3({direction: "below"}),
        ({tooltip}) => {
            const {tables} = model.value;

            return (
                <div className="suggest-relationship-content">
                    <div className="description">
                        Click each suggested relationship to relate the tables.
                        <div
                            className="text-info"
                            onClick={() => {
                                deSelectTable();
                                spc(model, ["hideSuggestedRelationships"], () => true);
                            }}
                        >
                            <a>Stop featuring suggested relationships above Data Sources</a>
                            <i
                                className="material-icons"
                                {...{
                                    ...tooltip(
                                        "The suggested relationships button will no longer show above the data sources for this model, but you will still see suggestions when you select Create Relationship from each specific table."
                                    ),
                                }}
                            >
                                info
                            </i>
                        </div>
                    </div>

                    <div className="suggest-relationship">
                        {suggestedRelationships.map((suggest) => (
                            <SuggestRelationshipLine
                                suggestRelationship={suggest}
                                key={`${suggest.leftColumnID}-${suggest.rightColumnID}`}
                                tables={tables}
                                model={model}
                                dataSources={dataSources}
                                onUpdate={(updatedRelationship) => {
                                    onUpdate(
                                        suggestedRelationships.map((s) => {
                                            if (s.id == suggest.id) {
                                                return {
                                                    ...s,
                                                    updatedRelationship,
                                                };
                                            }

                                            return s;
                                        })
                                    );
                                }}
                            />
                        ))}
                    </div>
                </div>
            );
        }
    );
