import {UseState} from "@common/react/use-state";
import {cs} from "@common/react/chain-services";
import * as React from "react";
import {Invoke} from "@common/react/invoke";
import {GlobalKeyDown} from "@common/react/keys/global-key-down";
import {IgnoreUpdate} from "@common/react/ignore-update";
import {ModalConsumer} from "./modal/modal-context";

const {waitTimeout} = require("@common/utils/wait-timeout");

export const ModalService = ({render, initShow, strong, disabledClickOverlay, registryRender, next}) =>
    cs(["state", (_, next) => UseState({next})], ({state}) => {
        const hide = async () => {
            state.change((s) => ({...s, active: false}));
            await waitTimeout(300);
            state.onChange(null);
        };
        const show = (args) =>
            new Promise(async (resolve, reject) => {
                state.onChange({
                    args,
                    active: false,
                    resolve: async (a) => {
                        await hide();
                        resolve(a);
                    },
                    reject: async (a) => {
                        await hide();
                        //TODO: uncomment reject
                        // reject(a);
                    },
                });
                await waitTimeout(10);
                state.change((s) => ({...s, active: true}));
            });

        const isOpen = () => state.get()?.active;

        return (
            <>
                {cs(
                    ({}, next) => IgnoreUpdate({next, when: () => state.value}),
                    () =>
                        next({
                            show,
                            close: hide,
                            isOpen,
                        })
                )}

                {initShow &&
                    Invoke({
                        fn: show,
                    })}

                {state.value &&
                    cs(["registry", ({}, next) => (registryRender ? React.createElement(ModalConsumer, {}, next) : next(null))], ({registry}) => (
                        <>
                            {registry ? registry.render(render(state.value)) : render(state.value)}

                            {!strong &&
                                !disabledClickOverlay &&
                                GlobalKeyDown({
                                    keyCombo: "Escape",
                                    onKeyDown: () => state.value.resolve(null),
                                })}
                        </>
                    ))}
            </>
        );
    });
