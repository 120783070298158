import * as React from "react";
import {cs} from "@common/react/chain-services";
import {getPath} from "@common/utils/arr-path";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {spc} from "@common/react/state-path-change";
import {top5Currencies} from "./top-5-currencies";
import {numberFormats} from "./number-formats";
import {percentFormats} from "./percent-formats";
import {equalDeep, omit} from "@common/utils/objects";
import "./select-number-format.scss";
import {UseState} from "@common/react/use-state";
import {getFieldType} from "@common/ui-components/charts/common/get-field-type.js";
import {CurrencySymbol} from "../../field-format/number/currency-symbol";
import {Separators} from "../../field-format/number/separators";
import {DecimalPlaces, ShiftDecimalPlaceLeft} from "../../field-format/number/decimal-places";
import {Negatives} from "../../field-format/number/negatives";
import {Button} from "../../../../../../../../../../../../../../common/form/buttons/button/button";
import {FieldControl} from "../../../render-field-control/field-control";
import {consumeContext} from "@common/react/context";
import {StaticTooltipService} from "../../../../../../../../../../../common/tooltip3/static-tooltip-service";
import {ErrorHandling} from "./error-handling";
import {Watch} from "@common/react/watch";

export const SelectNumberFormat = ({field, onAddFormat}) =>
    cs(consumeContext("getFieldInfo"), consumeContext("numericCurrencyConfig"), ({getFieldInfo, numericCurrencyConfig}) => {
        let fieldType = getFieldType(field.value);
        const displayType = getPath(field.value, ["numericProperties", "displayType"]) || "Number";

        const currencyConversionField = field.value.currencyConversion;
        const hasCurrencyConversion = !(
            !currencyConversionField ||
            [currencyConversionField.modelColumnID, currencyConversionField.modelID, currencyConversionField.modelTableID].includes("00000000-0000-0000-0000-000000000000")
        );

        return (
            <div className="select-number-format-sw8">
                {DropdownSelect({
                    label: "Show As",
                    list: ["Number", "Percentage", "Ratio", "Currency", "Currency Ratio"].map((label) => ({
                        label,
                        value: label.replace(/ /g, ""),
                    })),
                    valueToLabel: (v) => v.label,
                    isSelected: (v) => displayType === v.value,
                    onChange: (v) => {
                        if (v.value.startsWith("Currency")) {
                            spc(field, ["numericProperties"], () => ({
                                displayType: v.value,
                                ...top5Currencies.find((c) => c.label.includes("US Dollar")).attrSet,
                            }));
                        } else {
                            spc(field, ["numericProperties"], () => ({
                                displayType: v.value,
                                ...(v.value === "Number" && numberFormats[0].attrSet),
                                ...(v.value === "Percentage" && percentFormats[0].attrSet),
                            }));
                        }
                    },
                })}

                {(() => {
                    const defaultNumberAttrSet = field.value.aggregationFunc?.startsWith("Count") ? numberFormats[2].attrSet : numberFormats[0].attrSet;

                    const formatOptions = [
                        ...formats.find((f) => f.types.includes(displayType))?.list,
                        {label: "Custom", isCustom: true},

                        ...(displayType === "Currency" && fieldType === "number"
                            ? [
                                  {
                                      label: "ISO Code Field - Localized Format",
                                      isISOCode: true,
                                      attrSet: {
                                          decimalPlaces: 2,
                                          decimalSeperator: "Dot",
                                          thousandsSeperator: "Comma",
                                          negativeFormat: "Parentheses",
                                          shiftDecimalPlaceLeft: 0,
                                          currencyProperties: {
                                              currency: "USD",
                                              currencySymbolPlacement: "Left",
                                              currencySymbol: "$",
                                              spaceBetweenSymbolAndNumber: false,
                                          },
                                      },
                                  },
                                  {
                                      label: "ISO Code Field - Custom Format",
                                      isISOCode: true,
                                      attrSet: {
                                          decimalPlaces: 2,
                                          decimalSeperator: "Dot",
                                          thousandsSeperator: "Comma",
                                          negativeFormat: "Parentheses",
                                          shiftDecimalPlaceLeft: 0,
                                          currencyProperties: null,
                                      },
                                  },
                              ]
                            : []),
                    ];

                    return (
                        formatOptions &&
                        cs(
                            [
                                "showISOCode",
                                (_, next) =>
                                    UseState({
                                        initValue:
                                            hasCurrencyConversion ||
                                            numericCurrencyConfig?.value?.isISOCode ||
                                            (field.value.numericProperties?.displayType?.startsWith("Currency") &&
                                                fieldType === "number" &&
                                                field.value.numericProperties.currencyProperties === null),
                                        next,
                                    }),
                            ],
                            ({showISOCode}, next) => (
                                <>
                                    {Watch({
                                        value: showISOCode.value,
                                        initRun: true,
                                        onChanged: (value) =>
                                            numericCurrencyConfig?.change((old) => ({
                                                ...old,
                                                isISOCode: value,
                                            })),
                                    })}
                                    {next()}
                                </>
                            ),
                            [
                                "showCustomFormat",
                                (_, next) =>
                                    UseState({
                                        next,
                                        initValue:
                                            numericCurrencyConfig?.value?.isCustom ||
                                            !formatOptions.find((v) =>
                                                isFormatSelected(v, {
                                                    displayType,
                                                    numericProperties: field.value.numericProperties || defaultNumberAttrSet,
                                                })
                                            ),
                                    }),
                            ],
                            ({showCustomFormat, showISOCode, showLocalizeFormat}) => {
                                return (
                                    <>
                                        {DropdownSelect({
                                            info: "You may enable currency conversions here and manage it through the SDK. There must be a Currency ISO Field applied in order for it to convert properly. This field must contain ISO codes that apply to the currency fields used in this tile. The field must exist on the same model table as this field.",
                                            label: "Format",
                                            list: formatOptions,
                                            valueToLabel: (v) => v.label,
                                            isSelected: (v) => {
                                                if (showISOCode.value) {
                                                    if (field.value.numericProperties.currencyProperties === null) {
                                                        return v.isISOCode && field.value.numericProperties.currencyProperties === v.attrSet.currencyProperties;
                                                    }
                                                    return v.isISOCode;
                                                }

                                                if (showCustomFormat.value) return v.isCustom;

                                                return isFormatSelected(v, {
                                                    displayType,
                                                    numericProperties: field.value.numericProperties || defaultNumberAttrSet,
                                                });
                                            },
                                            onChange: (v) => {
                                                if (v.isCustom) {
                                                    // showCustomFormat.onChange(true)
                                                } else {
                                                    field.onChange({
                                                        ...field.value,
                                                        numericProperties: {
                                                            ...v.attrSet,
                                                            displayType: field.value.numericProperties?.displayType || "Number",
                                                        },
                                                        ...(!v.isISOCode
                                                            ? {
                                                                  currencyConversion: {
                                                                      ...field.value.currencyConversion,
                                                                      modelColumnID: "00000000-0000-0000-0000-000000000000",
                                                                      modelID: "00000000-0000-0000-0000-000000000000",
                                                                      modelTableID: "00000000-0000-0000-0000-000000000000",
                                                                  },
                                                              }
                                                            : {}),
                                                    });

                                                    // showCustomFormat.onChange(false);
                                                }

                                                showCustomFormat.onChange(!!v.isCustom);
                                                showISOCode.onChange(!!v.isISOCode);
                                            },
                                        })}

                                        {field.value.numericProperties?.displayType?.startsWith("Currency") && fieldType === "number" && showISOCode.value ? (
                                            <div className="conversion-settings">
                                                {cs(
                                                    [
                                                        "staticTooltip",
                                                        (_, next) =>
                                                            StaticTooltipService({
                                                                direction: "right",
                                                                info:
                                                                    field.value.numericProperties.currencyProperties === null
                                                                        ? "Custom Format will apply the non-currency symbol format to all currency types. This means that all currency will show the same decimal and comma placements."
                                                                        : "Localized Format will format the currency using the standard local formatting. This means that USD values will show as $1,234.56 while Euros are sometimes formatted as €1.234,56.",
                                                                tooltipBoxWidth: 200,
                                                                // topOffset: tooltipTopOffset ?? 20,
                                                                next,
                                                            }),
                                                    ],
                                                    ({staticTooltip}) => (
                                                        <div className="label">
                                                            <div className="text">
                                                                {field.value.numericProperties.currencyProperties === null
                                                                    ? "Customized Conversion Settings"
                                                                    : "Localized Conversion Settings"}
                                                            </div>

                                                            {staticTooltip.renderIcon({
                                                                icon: <i className="material-icons">info</i>,
                                                                className: "info-tooltip",
                                                            })}
                                                        </div>
                                                    )
                                                )}

                                                <div className="content">
                                                    {cs(() => {
                                                        return !hasCurrencyConversion ? (
                                                            <div className="iso-field">
                                                                <Button
                                                                    btnType="secondary"
                                                                    onClick={() =>
                                                                        numericCurrencyConfig?.change((v) => ({
                                                                            ...v,
                                                                            isoOverride: true,
                                                                        }))
                                                                    }
                                                                >
                                                                    Select ISO Field
                                                                </Button>
                                                            </div>
                                                        ) : (
                                                            FieldControl({
                                                                field: currencyConversionField,
                                                                getFieldInfo,
                                                                onChangeField: () =>
                                                                    numericCurrencyConfig?.change((v) => ({
                                                                        ...v,
                                                                        isoOverride: true,
                                                                    })),
                                                            })
                                                        );
                                                    })}

                                                    {field.value.numericProperties.currencyProperties && (
                                                        <>
                                                            {field.value.numericProperties?.displayType?.startsWith("Currency") && CurrencySymbol({field})}

                                                            {Separators({
                                                                field,
                                                            })}

                                                            {DecimalPlaces({
                                                                field,
                                                            })}

                                                            {Negatives({
                                                                field,
                                                            })}

                                                            {ErrorHandling({
                                                                field,
                                                            })}
                                                        </>
                                                    )}

                                                    {field.value.numericProperties.currencyProperties === null && (
                                                        <>
                                                            {ErrorHandling({
                                                                field,
                                                            })}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                                {showCustomFormat.value && (
                                                    <>
                                                        {Separators({
                                                            field,
                                                        })}

                                                        {DecimalPlaces({
                                                            field,
                                                        })}

                                                        {Negatives({field})}
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </>
                                );
                            }
                        )
                    );
                })()}
            </div>
        );
    });

const isFormatSelected = (format, {displayType, numericProperties}) => {
    if (displayType === "Percentage") {
        return equalDeep(
            omit(numericProperties, ["displayType", "thousandsSeperator", "currencyProperties", "shiftDecimalPlaceLeft"]),
            omit(format.attrSet, ["shiftDecimalPlaceLeft"])
        );
    }

    if (["Number", "Ratio"].includes(displayType)) {
        return equalDeep(omit(numericProperties, ["displayType", "currencyProperties", "shiftDecimalPlaceLeft"]), omit(format.attrSet, ["shiftDecimalPlaceLeft"]));
    }

    return equalDeep(omit(numericProperties, ["displayType", "shiftDecimalPlaceLeft"]), omit(format.attrSet, ["shiftDecimalPlaceLeft"]));
};

const formats = [
    {
        types: ["Number", "Ratio"],
        list: numberFormats,
    },
    {
        types: ["Currency", "CurrencyRatio"],
        list: top5Currencies,
    },
    {
        types: ["Percentage"],
        list: percentFormats,
    },
];
