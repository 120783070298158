import React from "react";
import {cs} from "@common/react/chain-services";
import "./request-parameters.scss";
import {TitleInfo} from "./title-info";

export const RequestParameters = ({authEndpoint, authParams, focusingWrapper}) =>
    cs(() => (
        <div className="request-parameters-87q">
            {TitleInfo({
                title: "Request Parameters",
                infoName: "request-params",
            })}
            <div className="parameter-list">
                {/*<div className="header">*/}
                {/*    Parameter*/}
                {/*</div>*/}
                <div className="list">
                    {(authParams || authEndpoint.value.parameters)?.map((param, i) => (
                        <div className="param" key={i}>
                            <img src={require("../../../../common/icons/green-check-icon.svg")} alt="" />
                            {`{{${param}}}`}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    ));
