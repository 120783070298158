import "./display.scss";

import * as React from "react";

import {scope} from "@common/react/scope";
import {stateToSelect} from "@common/ui-components/form/state-to-select";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {ThumbnailSelect} from "../../../../../../../../../common/thumbnail-select/thumbnail-select";
import {spc} from "@common/react/state-path-change";

const ValueToLabel = {
    LeftRight: "KPI on Left, Sparkline on Right",
    RightLeft: "KPI on Right, Sparkline on Left",
    TopBottom: "KPI on Top, Sparkline on Bottom",
    BottomTop: "KPI on Bottom, Sparkline on Top",
};

export const Display = ({tile, form}) => ({
    initExpand: true,
    label: "Display",
    render: () => {
        const style = scope(tile, ["style"]);
        const chartType = scope(tile, ["style", "sparkLineChartType"]);
        const chartSize = scope(tile, ["style", "sparkLineSize"]);
        const sparkLineOrientation = scope(tile, ["style", "sparkLineOrientation"]);

        return (
            <div className="spark-line-kpi-tile-display-ch4">
                <div className="subsection">
                    <div className="content">
                        {DropdownSelect({
                            label: "Layout",
                            list: ["LeftRight", "RightLeft", "TopBottom", "BottomTop"],
                            valueToLabel: (v) => ValueToLabel[v],
                            ...stateToSelect(scope(tile, ["style", "sparkLineOrientation"])),
                            isSelected: (v) => v == sparkLineOrientation.value,
                            onChange: (v) => {
                                spc(tile, ["style"], (style) => ({
                                    ...style,
                                    sparkLineOrientation: v,
                                    compareValuePosition: ["LeftRight", "RightLeft"].includes(v) ? "Vertical" : style.compareValuePosition,
                                }));
                            },
                        })}
                    </div>
                </div>

                <div className="subsection">
                    <div className="header">Sparkline Type</div>

                    <div className="content">
                        {ThumbnailSelect({
                            list: [
                                {
                                    label: "Line",
                                    value: "Line",
                                    thumbnail: require("../../../line-chart/panels/display/icons/line-smooth-icon.svg"),
                                },
                                {
                                    label: "Area",
                                    value: "Area",
                                    thumbnail: require("../../../line-chart/panels/display/icons/area-smooth-icon.svg"),
                                },
                                {
                                    label: "Bar",
                                    value: "Bar",
                                    thumbnail: require("../../../vertical-bar/panels/display/icons/bar-vertical-icon.svg"),
                                },
                            ],
                            isSelected: (type) => type.value === chartType.value,
                            onChange: (type) => {
                                style.onChange({
                                    ...style.value,
                                    sparkLineChartType: type.value,
                                    lineChartDisplayType: type.value == "Line" ? "Line" : "Area",
                                    displayType: "Classic",
                                    thickness: "Medium",
                                    yAxis: {
                                        $type: type.value == "Bar" ? "BarChartMeasureAxisStyle" : "LineChartMeasureAxisStyle",
                                        range: type.value == "Line" ? "AutoFromData" : "AutoFrom0",
                                    },
                                    xAxis: {
                                        $type: type.value == "Bar" ? "BarChartDimensionAxisStyle" : "LineChartDimensionAxisStyle",
                                        maxValuesInView: type.value == "Bar" ? "AutoMax" : "Fixed",
                                    },
                                });
                            },
                        })}

                        <br />

                        {DropdownSelect({
                            label: "Sparkline Size",
                            list: ["Small", "Standard", "Large"].map((v, i) => ({label: v, value: i})),
                            isSelected: (v) => v.value === chartSize.value,
                            onChange: (v) => chartSize.onChange(v.value),
                            valueToLabel: (v) => v.label,
                        })}
                    </div>
                </div>
            </div>
        );
    },
});
