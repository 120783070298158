import "./x-axis.scss";

import * as React from "react";

import {ShowHideToggle} from "../../../../../../../../../../../../../common/form/toggles/show-hide-toggle";
import {LpLine} from "../../../../../../../../../../common/left-panel/line/lp-line";
import {scope} from "@common/react/scope";
import {AxisRangeSelect} from "../../../common/axis-range-select";
import {GridStepTypeSelect} from "../../../common/grid-step-type-select";
import {XAxisDateGapsSelect} from "../../../common/x-axis-date-gaps-select";
import {OrientationSelect} from "../../../common/orientation-select";
import {AxisLocationSelect} from "../../../common/axis-location-select";
import {AxisTitle, XAxisLabel, XGridStepLabel} from "../../../combo-chart/panels/x-axis/x-axis-rules-comp";
import {getPath} from "@common/utils/arr-path";
import {CumulativeSelect} from "../../../common/cumulative-select/cumulative-select";
import {AxisScaleSelect} from "../../../common/axis-scale-select";
import {getFieldType} from "@common/ui-components/charts/common/get-field-type";

export const rXAxis =
    (fieldsKey) =>
    ({tile, size}) => ({
        getLabel: (getField) =>
            `X-Axis (${fieldsKey
                .split("|")
                .map((f) => getField(f)?.visualNameShort)
                .join(" & ")})`,
        render: () => {
            return (
                <div className="horizontal-x-axis-4vp">
                    <div className="subsection">
                        <div className="content">
                            {AxisTitle({
                                tile,
                                size,
                                state: scope(tile, ["style", "xAxis", fieldsKey, "label"]),
                            })}
                        </div>
                    </div>

                    <div className="subsection">
                        {XAxisLabel({
                            tile,
                            size,
                            state: scope(tile, ["style", "xAxis", fieldsKey, "labelShown"]),
                        })}
                    </div>

                    {/*{scope(tile, ["style", "xAxis", fieldsKey, "labelShown"]).value && <>*/}
                    {/*    <div className="subsection">*/}
                    {/*        <div className="header">*/}
                    {/*            Axis Label Info Icon*/}
                    {/*            <div className="control">*/}
                    {/*                {ShowHideToggle({state: scope(tile, ["style", "xAxis", fieldsKey, "showInfoIcon"])})}*/}
                    {/*            </div>*/}
                    {/*        </div>*/}

                    {/*        {scope(tile, ["style", "xAxis", fieldsKey, "showInfoIcon"]).value && (*/}
                    {/*            <div className="content">*/}
                    {/*                {TextareaInput({state: scope(tile, ["style", "xAxis", fieldsKey, "infoIconText"])})}*/}
                    {/*            </div>*/}
                    {/*        )}*/}

                    {/*        <div className="content">*/}
                    {/*            {TextareaInput({})}*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</>}*/}

                    <div className="subsection">
                        <div className="header">Range</div>
                        <div className="content">{AxisRangeSelect(scope(tile, ["style", "xAxis", fieldsKey]))}</div>
                    </div>

                    <div className="subsection">
                        <div className="header">Grid Step</div>
                        <div className="content">
                            {GridStepTypeSelect({
                                state: scope(tile, ["style", "xAxis", fieldsKey]),
                                // fieldType: "number",
                            })}
                        </div>
                    </div>

                    <div className="subsection">
                        {XGridStepLabel({
                            tile,
                            size,
                            state: scope(tile, ["style", "xAxis", fieldsKey, "gridStepLabelsShown"]),
                        })}

                        {getPath(tile.value, ["style", "xAxis", fieldsKey, "gridStepLabelsShown"]) && (
                            <div className="content">{OrientationSelect(scope(tile, ["style", "xAxis", fieldsKey, "gridStepLabelOrientation"]))}</div>
                        )}
                    </div>

                    <div className="subsection">
                        <div className="header">Other Options</div>
                        <div className="content">
                            {AxisLocationSelect(scope(tile, ["style", "xAxis", fieldsKey, "location"]), true)}

                            {LpLine({
                                label: "Axis Line",
                                control: ShowHideToggle({
                                    state: scope(tile, ["style", "xAxis", fieldsKey, "lineShown"]),
                                }),
                            })}

                            {LpLine({
                                label: "Grid Lines",
                                control: ShowHideToggle({
                                    state: scope(tile, ["style", "xAxis", fieldsKey, "gridLinesShown"]),
                                }),
                            })}

                            {AxisScaleSelect(scope(tile, ["style", "xAxis", fieldsKey, "logarithmic"]))}

                            {/*{XAxisDateGapsSelect(scope(tile, ["style", "xAxis", "dateGaps"]))}*/}

                            {CumulativeSelect({
                                tile,
                                state: scope(tile, ["style", "xAxis", fieldsKey, "cumulative"]),
                            })}
                        </div>
                    </div>
                </div>
            );
        },
    });
