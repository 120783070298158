import "../pivot-table-chart.scss";
import "./maximize-chart-dialog.scss";

import {css, cx} from "emotion";

import {cs} from "../../../../react/chain-services";
import {DialogService} from "../../../../../web-client/src/routes/common/dialog/dialog-service";
import {PivotTableChart} from "../pivot-table-chart";
import {MenuOptions} from "../../common/menu-options/menu-options";
import {MinimizeIcon} from "../../../../../web-client/src/routes/collection/tile/edit/child-tile/icons";
import {cGetFontSize} from "../../common/style-map/font-size";
import {getFontOptions} from "../../table/table/common/get-font-options";
import {getFontFamilyOptions} from "../../table/table/common/get-font-family-options";
import {StyledClass} from "@common/react/styled-class";
import {consumeContext} from "@common/react/context";

export const MaximizeChartDialog = ({next: rootNext, render}) =>
    cs(
        consumeContext("theme"),
        [
            "charDialogClass",
            ({theme}, next) =>
                StyledClass({
                    content: {
                        ".box": {
                            "border-radius": `${theme?.general.tile.styles.tileCornerRadius}px`,
                            overflow: "hidden",
                        },
                    },
                    next,
                }),
        ],
        [
            "modal",
            ({charDialogClass}, next) =>
                DialogService({
                    registryRender: true,
                    render: ({resolve, args: props}) => ({
                        customizeDialog: true,
                        content: next({
                            resolve,
                            ...props,
                        }),
                        className: cx("maximize-chart-dialog-container-a33", charDialogClass),
                    }),
                    next: rootNext,
                }),
        ],
        ({modal}) => {
            const {theme, tile, downloadData, tileFilters} = modal;

            const getFontSize = cGetFontSize(theme.fontSizeGroup, theme);

            const titleStyle = {
                color: theme.general.tile.styles.titleFontColorRGB,
                ...getFontOptions(theme.general.tile.styles.titleFontOptions),
                fontSize: `${getFontSize({
                    group: theme.general.tile.styles.titleFontSize || theme.general.canvas.fontSize,
                    elemType: "title",
                })}px`,
                ...getFontFamilyOptions({
                    fontFamily: theme.general.tile.styles.titleFontFamily || theme.general.canvas.fontFamily,
                    fontOption: theme.general.tile.styles.titleFontOptions,
                }),
                textTransform:
                    theme.general.tile.styles.titleCaseOptions === "TitleCase"
                        ? "capitalize"
                        : theme.general.tile.styles.titleCaseOptions === "Uppercase"
                        ? "uppercase"
                        : "",
            };

            return cs(
                [
                    "actionMenu",
                    (_, next) =>
                        next(
                            MenuOptions({
                                theme,
                                tile,
                                downloadData,
                                tileFilters,
                            })
                        ),
                ],
                ({actionMenu}) => (
                    <div
                        className="maximize-pivot-table-dialog-a33"
                        style={{
                            backgroundColor: theme?.general.tile.styles.tileBackgroundColorRGB,
                            borderColor: theme?.general.tile.styles.tileBorderColorRGB,
                            borderRadius: theme?.general.tile.styles.tileCornerRadius,
                        }}
                    >
                        <div className="chart-title">
                            <span style={titleStyle}>{tile.title}</span>

                            <div className="controls">
                                {actionMenu}

                                <MinimizeIcon
                                    fill={theme.general.components.menuTextColorRGB}
                                    className={cx("minimize-icon")}
                                    onClick={() => modal.resolve()}
                                />
                            </div>
                        </div>

                        <div className="chart-wrapper">
                            <PivotTableChart {...modal} modalMode />
                        </div>
                    </div>
                )
            );
        }
    );
