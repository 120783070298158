import {cs} from "@common/react/chain-services";
import {Static2} from "@common/react/static-2";
import React from "react";
import {ContainerTileButtonTheme} from "./container-tile-button-theme";
import {ThemeLeftPanelSections} from "../../../../common/theme-left-panel-sections/theme-left-panel-sections";
import {ContainerTileTabTheme} from "./container-tile-tab-theme";
import {ContainerTileDropdownTheme} from "./container-tile-dropdown-theme";

export const ContainerTilesTheme = ({form, overrideLeftPanelRef, viewTree}) =>
    cs(
        ["ref", (_, next) => Static2({next})],
        [
            "sections",
            ({ref}, next) =>
                next([
                    {
                        label: "Button Style",
                        render: (form) => <ContainerTileButtonTheme form={form} key="button" />,
                        id: "button",
                    },
                    {
                        label: "Tab Style",
                        render: (form) => <ContainerTileTabTheme form={form} key="tab" />,
                        id: "tab",
                    },
                    {
                        label: "Dropdown Style",
                        render: (form) => <ContainerTileDropdownTheme key="dropdown" viewTree={viewTree} form={form} overrideLeftPanelRef={ref} />,
                        id: "dropdown",
                    },
                ]),
        ],
        ({sections, ref}) =>
            ThemeLeftPanelSections({
                sections,
                overrideLeftPanelRef,
                parentRef: ref,
                form,
                viewTree,
            })
    );
