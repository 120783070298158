import React from "react";
import {cs} from "@common/react/chain-services";
import {DialogService} from "../../../../common/dialog/dialog-service";
import {UseState} from "@common/react/use-state";
import {consumeContext} from "@common/react/context";
import {Button} from "../../../../../../../common/form/buttons/button/button";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";

export const DeleteMemberDialog = ({next: rootNext}) =>
    cs(
        consumeContext("apis"),
        [
            "modal",
            (_, next) =>
                DialogService({
                    render: ({resolve, args: {user}}) => ({
                        title: `Delete ${user.$type == "PendingUserInvite" ? "invite" : "User"}`,
                        width: 480,
                        content: next({
                            resolve,
                            user,
                        }),
                        className: "remove-block-wrapper-o23",
                    }),
                    next: rootNext,
                }),
        ],
        ["saving", (_, next) => UseState({next})],
        [
            "submit",
            ({saving, modal, apis}, next) =>
                next(async () => {
                    saving.onChange(true);
                    if (modal.user.$type == "PendingUserInvite") {
                        await apis.tenant.deletePendingInvite(modal.user.id);
                        modal.resolve("confirm");
                    } else {
                        await apis.user.deleteUser(modal.user.id);
                        modal.resolve("confirm");
                    }
                }),
        ],
        ({modal, saving, submit}) => (
            <div className="remove-block-dialog-a32">
                <VerbDialogBodyScrollbar>
                    <div className="messages">
                        <div className="message">
                            Are you sure you want to delete this {modal.user.$type == "PendingUserInvite" ? "invite" : "user"}. This action cannot be undone.
                        </div>
                    </div>
                </VerbDialogBodyScrollbar>

                <div className="buttons">
                    <Button btnType="secondary" onClick={() => modal.resolve()}>
                        Cancel
                    </Button>
                    <Button disabled={saving.value} onClick={() => submit()}>
                        Delete {modal.user.$type == "PendingUserInvite" ? "invite" : "User"}
                    </Button>
                </div>
            </div>
        )
    );
