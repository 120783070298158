import * as React from "react";
import {cs} from "../../../react/chain-services";
import {RenderChart} from "../render-chart/render-chart";
import "./venn-diagram.scss";
import {getVennDiagramOptions} from "./options/get-venn-diagram-options";
import {isDataValid, loadTileData} from "../common/load-tile-data";
import {keepOnly} from "../../../utils/objects";
import {ChartInnerLayout} from "../chart-layout/chart-inner-layout";
import {decorateVennData} from "./decorate-venn-data";
import {getValueFormatters} from "../pie/get-value-formatters";
import {isComparing} from "../factory/check-compare";
import {MenuOptions} from "../common/menu-options/menu-options";
import {loadTileFields} from "../get-field-color";
import {ActionMenu} from "../common/action-menu/action-menu";
import {NoDataScreen} from "../common/no-data-screen/no-data-screen";
import {CurrencyConversionWarning, getCurrencyConversion} from "../common/currency-conversion-warning/currency-conversion-warning";

export const VennDiagram = ({tile, theme, size, tileFilters, loadData, downloadData, defaultData, chartRef, overrideTile, disabledTileActions = false}) =>
    cs(
        [
            "chartData",
            ({}, next) =>
                defaultData
                    ? next(defaultData)
                    : loadTileData({
                          next,
                          tileFilters,
                          loadData,
                          size,
                          tile,
                          theme,
                          tileKey: JSON.stringify(keepOnly(tile, ["valueField", "groupField", "filters", "limit"])),
                      }),
        ],

        // ["chartData", (_, next) => next(chartData)],
        ({chartData}, next) => (!isDataValid(chartData, tile) ? NoDataScreen({size, theme}) : next()),

        ["data", ({chartData}, next) => next(decorateVennData({tile, chartData, theme}))],

        [
            "formatters",
            (_, next) =>
                next(
                    getValueFormatters({
                        valueField: tile.valueField,
                        groupField: tile.groupField,
                        tile,
                    })
                ),
        ],

        // --- for tile action ---
        [
            "tileFields",
            ({}, next) =>
                loadTileFields({
                    next,
                    configs: {
                        tile,
                        measureSingleAttrs: ["valueField"],
                        groupFieldAttr: "groupField",
                        checkUnique: false,
                    },
                }),
        ],

        [
            "controls",
            ({chartData, tileFields}, next) => {
                const hasMenuOptions = tile.style.showDownloadData || tile.style.showDownloadImage;

                // if (!hasMenuOptions) {
                //     return next(null);
                // }

                return next(
                    <div className="controls">
                        {CurrencyConversionWarning({
                            currencyConversion: getCurrencyConversion({
                                $type: chartData.$type,
                                data: chartData,
                                tileFields,
                            }),
                            theme,
                        })}

                        {hasMenuOptions &&
                            MenuOptions({
                                chartRef,
                                theme,
                                tile,
                                downloadData,
                                tileFilters,
                            })}
                    </div>
                );
            },
        ],

        ({chartData, controls}, next) =>
            ChartInnerLayout({
                size,
                tile,
                theme,
                next,
                noData: !isDataValid(chartData, tile),
                hasControls: !!controls,
            }),

        [
            "actionMenu",
            ({formatters}, next) =>
                ActionMenu({
                    tile,
                    overrideTile,
                    dimensionFormatter: formatters.dimensionFormatter,
                    disabledTileActions,
                    next,
                }),
        ],

        [
            "onClickPoint",
            ({actionMenu, tileFields}, next) => {
                if (!actionMenu.hasActions()) {
                    return next();
                }
                return next(({point, ...other}) => {
                    let fieldToValue = tileFields.reduce((obj, field) => {
                        obj[field.id] = field.$type === "CategoryTileField" ? point.name : point.y;
                        return obj;
                    }, {});

                    const tileActionData = point.series?.userOptions?.tileActionData;

                    tileActionData?.columns.forEach((c, index) => {
                        fieldToValue[c.tileFieldID] = tileActionData.data[index];
                    });

                    actionMenu.show({
                        point,
                        ...other,
                        fieldToValue,
                    });
                });
            },
        ],
        // ------

        ({formatters, data, controls, onClickPoint}) => {
            const isCompare = isComparing(data, tile);

            const options = {
                ...getVennDiagramOptions({
                    tile,
                    data,
                    theme,
                    formatters,
                    isCompare,
                    onClickPoint,
                }),
                series: data.series.filter((s) => !s.isCompare),
            };

            return (
                <div
                    className="venn-diagram-dl2"
                    key={getRefreshKey({
                        tile,
                        theme,
                    })}
                >
                    <div className="chart">
                        <RenderChart
                            {...{
                                chartRef,
                                tile,
                                options,
                                size,
                                theme,
                                isCompare,
                                formatters,
                            }}
                        />
                    </div>
                    {controls}
                </div>
            );
        }
    );

const getRefreshKey = ({tile, theme}) =>
    JSON.stringify([
        // keepOnly(tile, ["id", "filters", "valueField", "targetValueField"]),
        keepOnly(tile.style, [
            "legendStyle",
            "showCompare", // to update tooltip to suit compare or non-compare mode
        ]),
        theme.dataVisualization.toolTipsAndLegends,
    ]);

const tile = {
    $type: "VennDiagramTile",
    style: {
        legendStyle: {
            legendShown: false,
            legendPosition: "Auto",
            legendTitleShown: false,
            legendTitle: "Legend",
        },
    },
};

const data = {
    series: [
        {
            type: "venn",
            name: "The Unattainable Triangle",
            data: [
                {
                    sets: ["Good"],
                    value: 2,
                },
                {
                    sets: ["Fast"],
                    value: 2,
                },
                {
                    sets: ["Cheap"],
                    value: 2,
                },
                {
                    sets: ["Good", "Fast"],
                    value: 1,
                    name: "More expensive",
                },
                {
                    sets: ["Good", "Cheap"],
                    value: 1,
                    name: "Will take time to deliver",
                },
                {
                    sets: ["Fast", "Cheap"],
                    value: 1,
                    name: "Not the best quality",
                },
                {
                    sets: ["Fast", "Cheap", "Good"],
                    value: 1,
                    name: "They're dreaming",
                },
            ],
        },
    ],
};

const chartData = {
    $type: "ChartTileData",
    series: [
        {
            tileFieldID: "2f4ea132-f951-45b4-a198-7bd3bdeb84cb",
            name: "none",
            measureAxisTitle: "Users That Purchased",
            stack: "2f4ea132-f951-45b4-a198-7bd3bdeb84cb",
            data: [[[1739, 1739]]],
            isCompare: false,
            range: {
                dateEnd: "2016-04-15T00:00:00Z",
                dateStart: "2016-04-01T00:00:00Z",
            },
            legendIndex: 2,
            tileActionData: [],
        },
        {
            tileFieldID: "2f4ea132-f951-45b4-a198-7bd3bdeb84cb",
            name: "SP",
            measureAxisTitle: "Users That Purchased",
            stack: "2f4ea132-f951-45b4-a198-7bd3bdeb84cb",
            data: [[[37686, 72967]]],
            isCompare: false,
            range: null,
            legendIndex: 3,
            tileActionData: [],
        },
        {
            tileFieldID: "2f4ea132-f951-45b4-a198-7bd3bdeb84cb",
            name: "DSP",
            measureAxisTitle: "Users That Purchased",
            stack: "2f4ea132-f951-45b4-a198-7bd3bdeb84cb",
            data: [[[16745, 52026]]],
            isCompare: false,
            range: null,
            legendIndex: 0,
            tileActionData: [],
        },
        {
            tileFieldID: "2f4ea132-f951-45b4-a198-7bd3bdeb84cb",
            name: "DSP, SP",
            measureAxisTitle: "Users That Purchased",
            stack: "2f4ea132-f951-45b4-a198-7bd3bdeb84cb",
            data: [[[35281, 35281]]],
            isCompare: false,
            range: null,
            legendIndex: 1,
            tileActionData: [],
        },
        //
        {
            tileFieldID: "2f4ea132-f951-45b4-a198-7bd3bdeb84cb",
            name: "none",
            measureAxisTitle: "Users That Purchased",
            stack: "2f4ea132-f951-45b4-a198-7bd3bdeb84cb",
            data: [[[1739, 1739]]],
            isCompare: true,
            range: {
                dateEnd: "2016-04-15T00:00:00Z",
                dateStart: "2016-04-01T00:00:00Z",
            },
            legendIndex: 2,
            tileActionData: [],
        },
        {
            tileFieldID: "2f4ea132-f951-45b4-a198-7bd3bdeb84cb",
            name: "SP",
            measureAxisTitle: "Users That Purchased",
            stack: "2f4ea132-f951-45b4-a198-7bd3bdeb84cb",
            data: [[[37686, 72967]]],
            isCompare: true,
            range: null,
            legendIndex: 3,
            tileActionData: [],
        },
        {
            tileFieldID: "2f4ea132-f951-45b4-a198-7bd3bdeb84cb",
            name: "DSP",
            measureAxisTitle: "Users That Purchased",
            stack: "2f4ea132-f951-45b4-a198-7bd3bdeb84cb",
            data: [[[16745, 52026]]],
            isCompare: true,
            range: null,
            legendIndex: 0,
            tileActionData: [],
        },
        {
            tileFieldID: "2f4ea132-f951-45b4-a198-7bd3bdeb84cb",
            name: "DSP, SP",
            measureAxisTitle: "Users That Purchased",
            stack: "2f4ea132-f951-45b4-a198-7bd3bdeb84cb",
            data: [[[35281, 35281]]],
            isCompare: true,
            range: null,
            legendIndex: 1,
            tileActionData: [],
        },
    ],
    tileID: "d6cfe3d8-5bbe-4ad3-8747-efea563c4954",
    fieldValidity: "Valid",
    resultsTruncated: false,
};
