import React from "react";
import "./more-action-popup.scss";
import {cs} from "@common/react/chain-services";
import {AnyAction2} from "@common/react/any-action-2";
import {Static2} from "@common/react/static-2";
import {ModelPanelHelper} from "../../../../../common/model-panel-helper";
import {cx} from "emotion";
import {consumeContext} from "@common/react/context";
import {
    AddAggregatedMeasureIcon,
    AddCalculatedColumnIcon,
    ColumnSecurityIcon,
    CreateRelationshipIcon,
    DeleteIcon,
    HighlightShortestPathsIcon,
    PreviewDataIcon,
    RowSecurityIcon,
    SuggestRelationshipIcon,
} from "../../../../main/controls/more-controls/icons/more-control-icons";
import {DataViewIcon} from "../../../../../transformation/common/icons/icons";
import {omit} from "@common/utils/objects";
import {GlobalEvent} from "@common/react/global-event";
import {DEFAULT_RELATIONSHIP} from "../../../../main/model-constrants";

export const MoreActionPopup = ({
    openPopupOption,
    model,
    table,
    dataSource,
    removeTableService,
    isDataView,
    isActive = false,
    shortestPossiblePathDialog,
    top = 28,
    currentToggle,
}) =>
    cs(
        consumeContext("interactions"),
        ["domRef", (_, next) => Static2({next})],
        ({domRef}, next) =>
            AnyAction2({
                disabled: !openPopupOption.value,
                getDom: domRef.get,
                fn: (e) => {
                    if (currentToggle && currentToggle.contains(e.target)) {
                        return;
                    }
                    openPopupOption.onChange(false);
                },
                next,
            }),
        consumeContext("routing"),
        consumeContext("apis"),
        ({domRef, interactions, routing, apis}) => {
            const suggestedRelationships = ModelPanelHelper.recommendRelationships({
                tables: model.value.tables.map((t) => omit(t, ["position"])),
                relationships: model.value.relationships,
            });
            const selectedTable = model.value.tables.find((t) => (isDataView ? table.id == t.id : t.dataSourceTableID == table.id));
            const suggestColumns = ModelPanelHelper.getSuggestColumns({
                table: selectedTable,
                suggestedRelationships: suggestedRelationships || [],
                relationships: model.value.relationships,
            });
            const relationshipColumns =
                selectedTable?.columns.filter((c) =>
                    model.value.relationships.find((r) => r.leftColumnID == c.id || r.rightColumnID == c.id)
                ) || [];

            const isStructured = dataSource && dataSource.structured && dataSource.type !== "File";
            const dsTableHavingPrimaryKey = isStructured ? table?.columns.findIndex((c) => c.primaryKey) > -1 || null : true;

            const actions = [
                {
                    label: "Edit Data View",
                    icon: DataViewIcon({width: 13, height: 13}),
                    onClick: () => {
                        routing.goto("edit-data-view", {
                            tab: "build",
                            transformationId: selectedTable.transformationID,
                        });
                    },
                    hidden: () => selectedTable.$type != "ViewModelTable",
                },
                {
                    label: "Create Relationship",
                    icon: CreateRelationshipIcon({fill: "#294661"}),
                    disabled: selectedTable.$type == "ViewModelTable" && !isActive,
                    onClick: () => {
                        interactions.createNewRelationship({
                            suggestedRelationships: suggestColumns,
                            table: selectedTable,
                            tables: model.value.tables,
                            allowBack: true,
                            relationship: DEFAULT_RELATIONSHIP,
                        });
                    },
                },
                {
                    label: "Suggest Relationship",
                    icon: SuggestRelationshipIcon({
                        fill: suggestColumns.length == 0 ? "#9DAAB7" : "#294661",
                    }),
                    disabled: suggestColumns.length == 0,
                    tooltip: "There are no suggested relationships.",
                    onClick: () => {
                        interactions.viewSuggestRelationship({
                            suggestedRelationships: suggestColumns,
                            relationships: model.value.relationships,
                            tables: model.value.tables,
                            table: selectedTable,
                        });
                    },
                },
                {
                    label: "Preview Data",
                    icon: PreviewDataIcon({}),
                    onClick: () => {
                        interactions.previewData({
                            tableId: selectedTable.id,
                            table: selectedTable,
                        });
                    },
                },
                {
                    label: "Row-Level Security",
                    icon: RowSecurityIcon({fill: "#294661"}),
                    disabled: selectedTable.$type == "ViewModelTable" && !isActive,
                    onClick: () => {
                        interactions.openRowLevelSecurity({
                            tableId: selectedTable.id,
                            dataSourceID: selectedTable.dataSourceID,
                            dataSourceTableID: selectedTable.dataSourceTableID,
                        });
                    },
                },
                {
                    label: "Highlight Shortest Paths",
                    icon: HighlightShortestPathsIcon({
                        fill: relationshipColumns.length == 0 ? "#9DAAB7" : "#294661",
                    }),
                    tooltip: "There are no existing relationships.",
                    disabled: relationshipColumns.length == 0,
                    onClick: () => {
                        shortestPossiblePathDialog.show({
                            table: selectedTable,
                            relationships: model.value.relationships,
                            tables: model.value.tables,
                        });
                    },
                },
                {
                    label: `Add Calculated Column`,
                    disabled: !dsTableHavingPrimaryKey || (selectedTable.$type == "ViewModelTable" && !isActive),
                    tooltip: "Calculated Columns are not allowed on a table without a primary key.",
                    icon: AddCalculatedColumnIcon({
                        fill: !dsTableHavingPrimaryKey ? "#9DAAB7" : "#294661",
                    }),
                    onClick: () => {
                        routing.goto("edit-transformed-column", {
                            tab: "build",
                            modelTableId: selectedTable.id,
                            isNew: true,
                            type: "calculated-column",
                        });
                    },
                    hidden: () => isDataView,
                    displayOnClick: true,
                },
                {
                    label: `Add Aggregated Measure`,
                    disabled: !dsTableHavingPrimaryKey || (selectedTable.$type == "ViewModelTable" && !isActive),
                    tooltip: "Aggregated Measures are not allowed on a table without a primary key.",
                    icon: AddAggregatedMeasureIcon({
                        fill: !dsTableHavingPrimaryKey ? "#9DAAB7" : "#294661",
                    }),
                    onClick: () => {
                        routing.goto("edit-transformed-column", {
                            tab: "build",
                            modelTableId: selectedTable.id,
                            isNew: true,
                            type: "aggregated-measure",
                        });
                    },
                    //hidden: () => dataView,
                    displayOnClick: true,
                },
                //{
                //    label: "Column-Level Security",
                //    icon: ColumnSecurityIcon({fill: "#294661"}),
                //    onClick: () => {
                //        interactions.openColumnSettings({model: model, tableId: selectedTable.id})
                //    }
                //},
                {
                    label: "Remove from Model",
                    icon: DeleteIcon({fill: "#294661"}),
                    disabled: selectedTable.excludeFromModel,
                    onClick: () => removeTableService.show({table: selectedTable}),
                },
            ];

            return (
                <div ref={domRef.set}>
                    {/*{GlobalEvent({*/}
                    {/*    eventName: "wheel",*/}
                    {/*    fn: (e) => {*/}
                    {/*        openPopupOption.onChange(false);*/}
                    {/*    },*/}
                    {/*})}*/}
                    <div className="more-action-popup" style={{top}}>
                        {actions
                            .filter((a) => !a.hidden || !a.hidden())
                            .map((a, index) => (
                                <div
                                    className={cx("action", a.disabled && "disabled")}
                                    key={index}
                                    onClick={() => {
                                        if (!a.disabled) {
                                            a.onClick();
                                            if (!a.displayOnClick) {
                                                openPopupOption.onChange(false);
                                            }
                                        }
                                    }}
                                >
                                    <div className="icon">{a.icon}</div>
                                    {a.label}
                                    {a.disabled && a.tooltip && <div className="tooltip">{a.tooltip}</div>}
                                </div>
                            ))}
                    </div>
                </div>
            );
        }
    );
