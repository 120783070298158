const {roundToDown, roundTo} = require("./round-to");

const autoRoundNumber = (v) => {
    const absVal = Math.abs(v);
    const format = autoRoundFormats.find((f) => f.check(absVal));
    return {
        number: v < 0 ? -1 * format.format(absVal) : format.format(absVal),
        tail: format.tail,
    };
};
exports.autoRoundNumber = autoRoundNumber;

const autoRoundFormats = [
    {
        check: (v) => v < 1,
        format: (v) => getNumberLessThan1(v),
    },
    {
        check: (v) => v >= 1 && v < Math.pow(10, 3),
        format: (v) => (v < 2 ? roundTo(v, 2) : roundToDown(v, 2)),
    },
    {
        check: (v) => v >= Math.pow(10, 3) && v < Math.pow(10, 4),
        // format: (v) => Math.floor(v),
        format: (v) => roundToDown(v / 1000, 2),
        tail: "K",
    },
    {
        check: (v) => v >= Math.pow(10, 4) && v < Math.pow(10, 5),
        format: (v) => roundToDown(v / 1000, 2),
        tail: "K",
    },
    {
        check: (v) => v >= Math.pow(10, 5) && v < Math.pow(10, 6),
        format: (v) => Math.floor(v / 1000),
        tail: "K",
    },
    {
        check: (v) => v >= Math.pow(10, 6) && v < Math.pow(10, 8),
        format: (v) => roundToDown(v / (1000 * 1000), 2),
        tail: "M",
    },
    {
        check: (v) => v >= Math.pow(10, 8) && v < Math.pow(10, 9),
        format: (v) => Math.floor(v / (1000 * 1000)),
        tail: "M",
    },
    {
        check: (v) => v >= Math.pow(10, 9) && v < Math.pow(10, 12),
        format: (v) => roundToDown(v / (1000 * 1000 * 1000), 2),
        tail: "B",
    },
    {
        check: (v) => v >= Math.pow(10, 12),
        format: (v) => roundToDown(v / (1000 * 1000 * 1000 * 1000), 2),
        tail: "T",
    },
];

const countDecimals = (v) => {
    if (Math.floor(v) === v) {
        return 0;
    }
    return Number.parseFloat(v).toString().split(".")[1].length || 0;
};

const getNumberLessThan1 = (v) => {
    const decimalLength = Math.min(15, countDecimals(v));

    if (decimalLength < 3) {
        return v;
    }

    for (let i = 2; i < decimalLength; i++) {
        if (roundToDown(v, i) > 11 * Math.pow(10, i * -1)) {
            return roundToDown(v, i);
        }
    }

    return v;
};

// console.log(autoRoundNumber(0.0056234))
// console.log(autoRoundNumber(0.56234))
// console.log(autoRoundNumber(6.56234))
// console.log(autoRoundNumber(56.56234))
// console.log(autoRoundNumber(356.56234))
// console.log(autoRoundNumber(3356.56234))
// console.log(autoRoundNumber(23356.56234))
// console.log(autoRoundNumber(423356.56234))
// console.log(autoRoundNumber(3423356.56234))
// console.log(autoRoundNumber(34323323.56234))
// console.log(autoRoundNumber(153423423.56234))
// console.log(autoRoundNumber(2153423423.56234))
// console.log(autoRoundNumber(35232153423423.56234))
