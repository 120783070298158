import {Expandable} from "../../../../common/expandable/expandable";
import {NumberInput} from "../../../../../../../common/form/number-input/number-input";
import {ffToNumberInput} from "../../../../../../../common/form/ff-to-number-input";
import {LpColorLine} from "../../../../common/left-panel/lp-color-line";
import {ffToColorPickerLine} from "../../../../../../../common/form/ff-to-color-picker-line";
import {LpDualControlLine} from "../../../../common/left-panel/dual-control-line/lp-dual-control-line";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {ffToDropdown} from "../../../../../../../common/form/ff-to-dropdown";
import {ColorPicker} from "../../../../common/color-picker/color-picker";
import {ffToColorPicker} from "../../../../../../../common/form/ff-to-color-picker";
import * as React from "react";

export const TableStyles = ({form, sectionInitClosed}) => (
    <Expandable
        {...{
            initExpand: !sectionInitClosed,
            label: "Table Styles",
            render: () => (
                <>
                    <div className="panel-33">
                        <div className="header">Borders</div>

                        <div className="content">
                            {NumberInput({
                                label: "Border Size",
                                ...ffToNumberInput(form.field(["tableBorderWidth"])),
                                unit: "pixels",
                            })}

                            {LpColorLine({
                                label: "Border Color",
                                ...ffToColorPickerLine(form.field(["tableBorderColorRGB"])),
                            })}
                        </div>
                    </div>

                    <div className="panel-33">
                        <div className="header">Cell Color</div>

                        <div className="content">
                            {LpColorLine({
                                label: "Cell Color",
                                ...ffToColorPickerLine(form.field(["tableCellColorRGB"])),
                            })}

                            {LpDualControlLine({
                                left: DropdownSelect({
                                    label: "Alternate Cell Color",
                                    list: ["None", "Alternate Rows", "AlternateCols"].map((label) => ({
                                        label,
                                        value: label.replace(/ /g, ""),
                                    })),
                                    ...ffToDropdown(form.field(["tableAlternateType"]), ["value"]),
                                    valueToLabel: (r) => r.label,
                                }),
                                right: <ColorPicker {...ffToColorPicker(form.field(["tableAltCellColorRGB"]))} />,
                            })}
                        </div>
                    </div>
                </>
            ),
        }}
    />
);
