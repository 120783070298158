import "./collection-preview.scss";

import React from "react";
import {cx} from "emotion";

import {Load2} from "@common/react/load2";
import {Watch} from "@common/react/watch";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {OnUnmounted} from "@common/react/on-unmounted";
import {consumeContext, provideContext} from "@common/react/context";

import {Button} from "@common/form/buttons/button/button";
import {DebounceTextInput} from "@common/form/debounce-input/debounce-text-input";

import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";
import {LiveDashboard} from "@common/ui-components/live/live-dashboard/live-dashboard";
import {LoadingIndicator} from "@common/ui-components/loading-indicator/loading-indicator";
import {WholePageLoading} from "@common/ui-components/whole-page-loading/whole-page-loading";

import {ApiCollectionMain} from "../../api-collection/edit/main/api-collection-main";

import {SelectViewProfile} from "./select-view-profile/select-view-profile";
import {DesktopIcon, MobileIcon, RefreshIcon, TabletIcon} from "./collection-preview-icons";
import {loadViewAsContext} from "../edit/layout/view-as-context";

export const CollectionPreview = () =>
    cs(
        consumeContext("collection"),
        consumeContext("apis"),
        consumeContext("routing"),
        consumeContext("tenant"),
        ["viewAsContext", (_, next) => loadViewAsContext({next})],
        ({collection}, next) => {
            if (!collection?.loading) {
                document.title = `${collection.value.name} - Verb`;
            }
            return next();
        },
        ({collection, viewAsContext, routing}, next) => {
            const {folders, iframeKeys} = viewAsContext || {};
            if (!collection || !iframeKeys || !folders) {
                return WholePageLoading();
            }
            if (collection.loading || iframeKeys.loading || folders.loading) {
                return WholePageLoading();
            }
            return next();
        },
        [
            "remoteTheme",
            ({collection, apis, viewAsContext}, next) => {
                const {viewAs} = viewAsContext || {};
                return Load2({
                    captureException: true,
                    _key: JSON.stringify({
                        theme: viewAs?.value?.themeID ?? collection.value?.themeID,
                    }),
                    fetch: () =>
                        !collection.value?.themeID
                            ? null
                            : apis.collection.getTheme(viewAs?.value?.themeID ?? collection.value?.themeID),
                    next,
                });
            },
        ],
        ["refreshing", (_, next) => UseState({next})],
        ["refreshKey", (_, next) => UseState({next, initValue: 0})],
        ({tenant}, next) =>
            OnUnmounted({
                next,
                action: () => (document.title = `Reporting - Revive`),
            }),
        ["viewWidth", ({collection}, next) => UseState({next, initValue: 1240})],

        ({viewWidth}, next) => provideContext("viewWidth", viewWidth, next),
        ({collection, viewWidth}, next) =>
            Watch({
                next,
                value: collection.value?.viewWidthPixels || 1240,
                onChanged: (v) => viewWidth.onChange(v),
            }),
        ({collection, viewWidth, apis, routing, refreshing, remoteTheme, refreshKey, viewAsContext}) => {
            const {folders, iframeKeys, viewAs, collectionViewAs} = viewAsContext || {};
            const collectionView = collection;

            const sizes = [
                {
                    icon: <DesktopIcon />,
                    width: 1240,
                },
                {
                    icon: <TabletIcon />,
                    width: 991,
                },
                {
                    icon: <MobileIcon />,
                    width: 768,
                    className: "landscape-mobile-view",
                },
                {
                    icon: <MobileIcon />,
                    width: 478,
                },
            ];

            return (
                <div className="collection-preview-a33">
                    <div className="collection-preview-header">
                        <div className="preview-text-col">Preview</div>

                        <div className="collection-name">{collectionView?.value?.name || collection?.value.name}</div>

                        <div className="collection-view-control">
                            {(iframeKeys?.value || folders?.value) &&
                                cs(() => {
                                    const keys = iframeKeys.value?.filter(
                                        (k) => k.collections.indexOf(collection?.value?.id) > -1
                                    );
                                    if (keys?.length === 0 && folders.value?.length == 0) return null;
                                    return SelectViewProfile({
                                        keys,
                                        viewAs,
                                        folders,
                                    });
                                })}

                            {collectionView?.value?.$type != "ApiCollection" && (
                                <div className="dimension-value-col">
                                    <div className="dimension-value">
                                        {collectionView?.value?.$type == "SparkCollection"
                                            ? DebounceTextInput({
                                                  state: viewWidth,
                                              })
                                            : viewWidth.value}
                                    </div>

                                    <div className="unit">px</div>
                                </div>
                            )}
                        </div>

                        {collectionView?.value?.$type == "DashboardCollection" && (
                            <div className="collection-view-actions">
                                {sizes.map((size, index) => (
                                    <div
                                        className={cx(
                                            "view-action",
                                            size.className,
                                            size.width == viewWidth.value && "selected"
                                        )}
                                        key={index}
                                        onClick={() => viewWidth.onChange(size.width)}
                                    >
                                        {size.icon}
                                    </div>
                                ))}
                            </div>
                        )}

                        <div className="refresh-btn-view">
                            <Button
                                btnType="border"
                                iconRight={<RefreshIcon />}
                                disabled={refreshing.value}
                                onClick={async () => {
                                    refreshing.onChange(true);
                                    const updated =
                                        viewAs?.value?.value == null || !collectionViewAs.liveApis
                                            ? await apis.collection.getCollection(routing.params.colId)
                                            : await collectionViewAs.liveApis.getCollection(routing.params.colId);
                                    // const updatedTheme = await apis.collection.getTheme(updated.themeID);
                                    // theme.onChange(updatedTheme);
                                    remoteTheme.reload();

                                    collectionView?.onChange(updated);
                                    refreshing.onChange(false);
                                    refreshKey.change((c) => c + 1);
                                }}
                            >
                                Refresh
                            </Button>
                        </div>
                    </div>

                    <div className="dashboard-view">
                        <div
                            key={`profileID_${viewAs?.value?.value}_${refreshKey.value}_99o`}
                            className="dashboard-view-wrapper"
                            style={{
                                width:
                                    parseInt(viewWidth.value) +
                                    (collectionView?.value?.$type == "SparkCollection" ? 40 : 0),
                                margin: "0 auto",
                            }}
                        >
                            {!collectionView.loading && (
                                //using render iframe to support style match with sdk
                                <iframe
                                    src={`/#/iframe/preview?envId=${routing.params.envId}&colId=${
                                        collectionView.value.id
                                    }&width=${viewWidth.value}${
                                        viewAs?.value?.value ? `&apiKey=${viewAs?.value?.value}` : ``
                                    }`}
                                    width={viewWidth.value}
                                    height="100%"
                                />
                            )}
                        </div>
                    </div>

                    {/*<VerbScrollbar style={{height: "calc(100% - 63px)"}} className="dashboard-view">*/}
                    {/*    <div*/}
                    {/*        key={`profileID_${viewAs?.value?.value}_${refreshKey.value}`}*/}
                    {/*        className="dashboard-view-wrapper"*/}
                    {/*        style={{*/}
                    {/*            width: parseInt(viewWidth.value) + (collectionView?.value?.$type == "SparkCollection" ? 40 : 0),*/}
                    {/*            margin: "0 auto",*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*        {collectionView.loading || remoteTheme.loading || refreshing.value*/}
                    {/*            ? LoadingIndicator()*/}
                    {/*            : collectionView?.value.$type == "ApiCollection"*/}
                    {/*            ? ApiCollectionMain({*/}
                    {/*                  apiKey: viewAs?.value?.value,*/}
                    {/*                  collection: collectionView,*/}
                    {/*              })*/}
                    {/*            : LiveDashboard({*/}
                    {/*                  apiKey: viewAs?.value?.value,*/}
                    {/*                  collection: {*/}
                    {/*                      ...(collectionView?.value || {}),*/}
                    {/*                      customWindowWidth: viewWidth.value,*/}
                    {/*                      viewWidthPixels: viewWidth.value,*/}
                    {/*                  },*/}
                    {/*                  theme: remoteTheme.value,*/}
                    {/*                  forceAutoRefresh: true,*/}
                    {/*                  refreshing: refreshing.value,*/}
                    {/*              })}*/}
                    {/*    </div>*/}
                    {/*</VerbScrollbar>*/}
                </div>
            );
        }
    );
