import {cs} from "@common/react/chain-services";
import SpinnerIcon from "../../../../../assets/icons/data-sources/SpinnerIcon";
import {DSLoadingDialog} from "../../common/loading-dialog/ds-loading-dialog";
import {_staticDsValue} from "../../sync-settings/edit-sync-settings";
import {getSyncLatestTables} from "../../common/data-sources-helpers";
const {consumeContext} = require("@common/react/context");

export const NavigateSyncSettings = ({next}) =>
    cs(
        consumeContext("routing"),
        consumeContext("apis"),
        consumeContext("toast"),

        ["loadingDialog", (_, next) => DSLoadingDialog({next})],
        ({loadingDialog, routing, apis}) =>
            next({
                goto: async () => {
                    const resp = await loadingDialog.show({
                        title: "Enter Sync Settings",
                        content: (
                            <>
                                {SpinnerIcon({})}
                                &nbsp;&nbsp;
                                <div>Connecting to your data source…</div>
                            </>
                        ),
                        onLoading: async () => {
                            const remoteDs = await apis.data.getDataSource(routing.params.dsId);
                            const latestTables =
                                // remoteDs.tables ??
                                await apis.data.getTablesColumns({
                                    dataSourceID: routing.params.dsId,
                                    data: null,
                                });
                            return getSyncLatestTables(latestTables)(remoteDs);
                        },
                    });

                    if (resp) {
                        _staticDsValue().set(resp);
                        routing.goto("edit-sync-settings", {
                            envId: routing.params.envId,
                            dsId: routing.params.dsId,
                            ds: resp,
                        });
                    }
                },
            })
    );
