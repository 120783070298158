import * as React from "react";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {FilterLeftPanel} from "../../../common/filters/filter-left-panel/filter-left-panel";
import {cx} from "emotion";
import {SuspendUpdate} from "@common/react/suspend-update";
import {ConfigFieldOverride} from "../../../common/filters/filter-left-panel/config-field-override";
import {SelectFilterField} from "../../../common/filters/filter-left-panel/select-filter-field";
import {consumeContext} from "@common/react/context";
import "./edit-filter-left-panel.scss";
import {changePath} from "@common/utils/arr-path";
import {Button} from "../../../../../../../common/form/buttons/button/button";
import {ConfirmDialog} from "../../../../common/confirm-dialog/confirm-dialog";

export const EditFilterLeftPanel = ({filter, form}) =>
    cs(consumeContext("collection"), ["override", (_, next) => UseState({next})], ({override, collection}) => (
        <div className="edit-filter-left-panel-211">
            {FilterLeftPanel({
                filter,
                form,
                override,
                isApiCollection: collection.value.$type === "ApiCollection",
            })}

            <div className="controls">{DeleteFilter({})}</div>

            <div className={cx("override", {active: override.value})}>
                {cs(
                    ({}, next) => SuspendUpdate({active: !override.value, next}),
                    ({}) => {
                        if (override.value?.type === "edit") {
                            return ConfigFieldOverride({
                                label: override.value?.label,
                                state: {
                                    value: override.value
                                        ? filter.value.columns.find((c) => c.modelColumnID === override.value.modelColumnID)
                                        : {},
                                    onChange: (val) =>
                                        filter.change((filter) => ({
                                            ...filter,
                                            columns: filter.columns.map((col) =>
                                                col.modelColumnID === override.value.modelColumnID ? val : col
                                            ),
                                        })),
                                },
                                onClose: () => override.onChange(null),
                            });
                        }

                        if (override.value?.type === "add") {
                            return SelectFilterField({
                                filter,
                                allowedFieldType: override.value.allowedFieldType,
                                onClose: () => override.onChange(null),
                            });
                        }
                    }
                )}
            </div>
        </div>
    ));

const DeleteFilter = () =>
    cs(
        consumeContext("apis"),
        consumeContext("collection"),
        consumeContext("routing"),
        [
            "confirmDeleteDialog",
            (_, next) =>
                ConfirmDialog({
                    next,
                    title: "Delete Filter?",
                    body: `Are you sure that you want to delete this filter?`,
                }),
        ],
        ["deleting", (_, next) => UseState({next})],
        ({deleting, apis, collection, routing, confirmDeleteDialog}) => (
            <Button
                btnType="danger"
                className="btn-delete"
                disabled={deleting.value}
                onClick={() => {
                    confirmDeleteDialog.show({
                        confirmClick: async () => {
                            deleting.onChange(true);
                            const newCollection = changePath(collection.value, ["filters"], (filters) =>
                                filters.filter((filter) => filter.id !== routing.params.filterId)
                            );
                            await apis.collection.upsertCollection(newCollection);

                            collection.onChange(newCollection);

                            routing.goto("edit-collection", {collectionTab: "filters"});
                        },
                    });
                }}
            >
                Delete Filter
            </Button>
        )
    );
