import React from "react";
import {cs} from "@common/react/chain-services";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {TextInput} from "../../../../../../../../../common/form/text-input/text-input";
import {scope} from "@common/react/scope";
import "./filters-display.scss";
import {CheckboxLine} from "../../../../../../common/checkbox-line/checkbox-line";
import {LpShowHideLine} from "../../../../../../common/left-panel/lp-show-hide-line";
import {stateToSelect} from "@common/ui-components/form/state-to-select";
import {spc} from "@common/react/state-path-change";
import {ConfirmChangeFilterDisplayDialog} from "./confirm-change-filter-display-dialog";
import {getClonedTile, moveTileToDashboard, updatedCollectionAfterAdd} from "../../../../../common/dnd-grid-panel/dnd-common";
import {moveFiltersToDashboard} from "../../../../common/download-report-common";
import {FieldSection} from "../../../../../tile/edit/left-panel/tabs/fields-tab/chart-types/common/field-section/field-section";
import {FieldInfoProvider} from "../../../../../tile/edit/left-panel/tabs/tile-tab/chart-types/common/field-info-provider/field-info-provider";

export const FiltersDisplay = ({collection}) =>
    cs(
        [
            "confirmChange",
            (_, next) =>
                ConfirmChangeFilterDisplayDialog({
                    next,
                    onMove: () =>
                        moveFiltersToDashboard(collection, (c) => ({
                            filterStyle: {
                                ...c.filterStyle,
                                display: "SDK",
                            },
                        })),
                    onDelete: () => {
                        collection.change((c) => ({
                            ...c,
                            filterDownloadTiles: [],
                            filterStyle: {
                                ...c.filterStyle,
                                display: "SDK",
                            },
                        }));
                    },
                }),
        ],
        ({confirmChange}) => {
            return (
                <div className="filters-display-8wq">
                    <div className="embedded-filters">
                        {DropdownSelect({
                            label: "Embedded Filter Display",
                            labelInfo: "Control how filters will be embedded. Your selection will be reflected in the canvas, preview, and on the embed SDK snippet",
                            directionTooltip: "top",
                            list: [
                                {
                                    value: "Attached",
                                    label: "Include Filters with Collection",
                                },
                                {
                                    value: "Detached",
                                    label: "Separate Filter Embed Script",
                                },
                                {value: "SDK", label: "Control Filter via SDK"},
                            ],
                            valueToLabel: (v) => v.label,
                            isSelected: (v) => collection.value.filterStyle.display == v.value,
                            onChange: (v) => {
                                if (v.value == "SDK" && collection.value.filterDownloadTiles.length > 0) {
                                    confirmChange.show();
                                } else {
                                    const display = scope(collection, ["filterStyle", "display"]);
                                    display.onChange(v.value);
                                }
                            },
                        })}

                        {collection.value.filterStyle?.display !== "SDK" && (
                            <>
                                {DropdownSelect({
                                    label: "Grouping",
                                    tooltipTopOffset: 0,
                                    labelInfo: (
                                        <>
                                            On mobile;
                                            <br />
                                            <strong>Single Line</strong> will show the required filters in the Visible section. <br />
                                            <strong>Wrap Lines</strong> will show all filters in the Always Visible section.
                                        </>
                                    ),
                                    directionTooltip: "right",
                                    list: [
                                        {value: "single", label: "Single Line"},
                                        {value: "wrap", label: "Wrap Lines"},
                                    ],
                                    valueToLabel: (v) => v.label,
                                    isSelected: (v) => v.value === (collection.value.filterStyle?.wrapLines ? "wrap" : "single"),
                                    onChange: (v) => spc(collection, ["filterStyle", "wrapLines"], () => v.value === "wrap"),
                                })}

                                {LpShowHideLine({
                                    label: "FILTER LABELS",
                                    state: scope(collection, ["filterStyle", "showLabels"]),
                                })}
                            </>
                        )}
                    </div>

                    {collection.value.filterStyle?.display !== "SDK" && (
                        <FieldSection
                            header={FieldInfoProvider({
                                field: <div className="text">Apply filters</div>,
                            })}
                            content={
                                <div className="apply-filters-vfd">
                                    {DropdownSelect({
                                        label: "Apply After",
                                        list: [
                                            {
                                                label: "Each filter change",
                                                value: "OnChange",
                                            },
                                            {
                                                label: "Submit to Update",
                                                value: "OnSubmit",
                                            },
                                        ],
                                        valueToLabel: (v) => v.label,
                                        ...stateToSelect(scope(collection, ["filterStyle", "runType"]), ["value"]),
                                    })}

                                    {collection.value?.filterStyle?.runType === "OnSubmit" &&
                                        TextInput({
                                            label: "Button Label",
                                            state: scope(collection, ["filterStyle", "runButtonText"]),
                                        })}
                                </div>
                            }
                        />
                    )}
                </div>
            );
        }
    );
