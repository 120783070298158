import {cx} from "emotion";
import React from "react";
import {Button} from "@common/form/buttons/button/button";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {UseState} from "@common/react/use-state";
import {noun} from "@common/utils/plural";
import {tooltipService3} from "../../../../common/tooltip3/tooltip-service-3";
import {ButtonDualDropdown} from "../../../common/button-dropdown/button-dual-dropdown";
import {SyncMethodTypesToValue} from "../../../common/method-list-config";
import {DsClearErrorDialog} from "../../common/clear-error-dialog/ds-clear-error-dialog";
import {NavigateSyncSettings} from "../common/navigate-sync-settings";
import "./sync-status-summary.scss";
import {formatLast30Days, SyncSummaryLast30Days} from "./sync-summary-last-30-days";
import {formatDateTime} from "@common/logic/date-time/format-date-time";
import moment from "moment";
import {CacheSync} from "../interaction/ds-sync-cache";
import {SyncInfoSkeletons, SyncLast30Skeletons} from "./sync-status-summary-skeletons";
import {IconDown, IconUnchanged, IconUp} from "@common/ui-components/charts/common/icons/icons";
import {dataSourceTypes} from "../../../common/data-source-type";

export const SyncStatusSummary = ({selectedTables, dataSourceErrors, tablesErrorIDs, forceSyncDialog, tablesSyncing, dataSource, tablesStatus, onReload}) =>
    cs(consumeContext("theme"), ["selectedTablesIDs", (_, next) => next(selectedTables.value.map((s) => s.id))], ({selectedTablesIDs, theme}) => {
        const isUnstructuredType = !dataSource.value.structured;
        const tables = tablesStatus.value.tables;

        return (
            <div className="sync-status-summary-99y">
                {selectedTables.value.length > 0 && (
                    <div className="selected-tables">
                        <div className="text">
                            {selectedTables.value.length} {noun(selectedTables.value.length, isUnstructuredType ? "Object" : "Table")} Selected
                        </div>
                        <span className="material-icons-outlined close-icon" onClick={() => selectedTables.onChange([])}>
                            close
                        </span>
                    </div>
                )}

                <div className="content">
                    {cs(
                        consumeContext("routing"),
                        [
                            "goSyncSettings",
                            (_, next) =>
                                cs(["navigateSyncSettings", (_, next) => NavigateSyncSettings({next})], ({navigateSyncSettings}) => next(() => navigateSyncSettings.goto())),
                        ],
                        tooltipService3({direction: "above"}),
                        ({routing, goSyncSettings, tooltip}) => {
                            return selectedTables.value.length === 0 ? (
                                <div className="card summary">
                                    <div className="label">Summary</div>
                                    <div className="content">
                                        <div className="info">
                                            <div>
                                                {tablesStatus.value.tables.length} of{" "}
                                                {[...dataSource.value.tables, ...(dataSource.value.disabledTables || [])].filter((t) => !t.isChildTable).length}{" "}
                                                {isUnstructuredType ? "objects" : "tables"} syncing
                                            </div>
                                            <Button
                                                {...(dataSourceErrors.length > 0
                                                    ? tooltip(() => `Cannot enter Sync Settings because there is an error connecting to the database.`)
                                                    : {})}
                                                disabled={dataSourceErrors.length > 0}
                                                onClick={() => dataSourceErrors.length === 0 && goSyncSettings()}
                                            >
                                                Edit
                                            </Button>
                                        </div>

                                        <div className="separator" />

                                        <div className="errors">
                                            <span className="text">
                                                {dataSourceErrors.length > 0 ? (
                                                    <>Database in error </>
                                                ) : (
                                                    <>
                                                        {tablesErrorIDs.length} {noun(tablesErrorIDs.length, isUnstructuredType ? "object" : "table")} in error{" "}
                                                    </>
                                                )}
                                            </span>
                                            |{" "}
                                            <span
                                                className="view-all"
                                                onClick={() =>
                                                    routing.goto("edit-data-source-errors", {
                                                        dsId: routing.params.dsId,
                                                        envId: routing.params.envId,
                                                    })
                                                }
                                            >
                                                View all recent errors
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="buttons">
                                    {cs(
                                        consumeContext("apis"),
                                        consumeContext("toast"),
                                        consumeContext("syncingProcess"),
                                        ["loading", (_, next) => UseState({next})],

                                        [
                                            "onTogglePause",
                                            ({apis, loading, toast}, next) =>
                                                next(async (paused) => {
                                                    try {
                                                        loading.onChange(true);

                                                        const pausedTableIDs = dataSource.value.tables.filter((t) => selectedTablesIDs.includes(t.id)).map((t) => t.id);
                                                        const apisData = paused ? apis.data.pauseTables : apis.data.resumeTables;
                                                        await apisData(dataSource.value.id, pausedTableIDs);

                                                        toast.show(`${paused ? `Paused` : `Unpaused`} ${pausedTableIDs.length} ${noun(pausedTableIDs.length, "Table")}`);
                                                        tablesStatus.reload();
                                                    } catch (e) {
                                                        toast.show(e.message, {isError: true});
                                                    } finally {
                                                        loading.onChange(false);
                                                    }
                                                }),
                                        ],
                                        [
                                            "pausedSelectedTables",
                                            (_, next) => next(tables.filter((t) => selectedTables.value.map((s) => s.name).includes(t.name)).filter((t) => t.paused)),
                                        ],
                                        ({loading, syncingProcess}, next) => {
                                            const errorTablesIDsSelected = tablesErrorIDs.filter((id) => selectedTablesIDs.indexOf(id) > -1);
                                            return errorTablesIDsSelected.length > 0
                                                ? cs(["clearErrors", (_, next) => DsClearErrorDialog({next})], ({clearErrors}) => {
                                                      const {syncingProcessObserver} = syncingProcess;
                                                      return (
                                                          <>
                                                              <Button onClick={goSyncSettings}>Sync Settings</Button>
                                                              <Button
                                                                  disabled={loading.value}
                                                                  iconRight={loading.value && <i className="fad fa-spinner-third fa-spin" />}
                                                                  btnType="danger"
                                                                  onClick={async () => {
                                                                      const resp = clearErrors.show({
                                                                          goSyncSettings,
                                                                          errorTablesIDsSelected,
                                                                          dsID: dataSource.value.id,
                                                                      });

                                                                      if (resp) {
                                                                          syncingProcessObserver.start();
                                                                          onReload();
                                                                      }
                                                                  }}
                                                              >
                                                                  Clear Error
                                                              </Button>
                                                          </>
                                                      );
                                                  })
                                                : next();
                                        },
                                        [
                                            "renderForceSync",
                                            ({apis, toast, syncingProcess}, next) =>
                                                next(() => {
                                                    if (dataSource.value.type === dataSourceTypes.AMAZONDSP) return null;

                                                    const {syncingProcessObserver} = syncingProcess;
                                                    const onForceSync = async () => {
                                                        const availableToSyncTables = selectedTables.value.filter(
                                                            (t) => !(syncingProcessObserver.tablesSyncing || []).includes(t.id)
                                                        );
                                                        try {
                                                            await apis.data.forceSync(
                                                                routing.params.dsId,
                                                                availableToSyncTables.map((t) => ({
                                                                    dataSourceTableID: t.id,
                                                                    full: t.syncMethod === SyncMethodTypesToValue.Full,
                                                                }))
                                                            );

                                                            syncingProcessObserver.start();

                                                            toast?.show(
                                                                `Sync started on ${
                                                                    availableToSyncTables.length === 1 ? availableToSyncTables[0].name : availableToSyncTables.length
                                                                } ${noun(availableToSyncTables.length, "table")}`
                                                            );
                                                        } catch (e) {
                                                            console.log(e);
                                                        }
                                                    };

                                                    return ButtonDualDropdown({
                                                        size: "medium",
                                                        btnType: "secondary",
                                                        list: [
                                                            {
                                                                value: "Sync",
                                                                label: "Force Full Sync",
                                                            },
                                                        ],
                                                        label: "Sync Now",
                                                        onClick: () => onForceSync(),
                                                        valueToLabel: (v) => v.label,
                                                        onChange: (t) => {
                                                            switch (t.value) {
                                                                case "Sync": {
                                                                    const resp = forceSyncDialog.show({
                                                                        tables: selectedTables.value,
                                                                        dsid: routing.params.dsId,
                                                                        isForceFull: true,
                                                                    });
                                                                    if (resp) {
                                                                        syncingProcessObserver.start();
                                                                    }
                                                                }
                                                            }
                                                        },
                                                    });
                                                }),
                                        ],
                                        ({renderForceSync, onTogglePause, loading, pausedSelectedTables}, next) => {
                                            return pausedSelectedTables.length > 0 ? (
                                                <>
                                                    <Button
                                                        onClick={() => onTogglePause(false)}
                                                        disabled={loading.value}
                                                        iconRight={loading.value && <i className="fad fa-spinner-third fa-spin" />}
                                                    >
                                                        Unpause
                                                    </Button>

                                                    {pausedSelectedTables.length !== selectedTables.value.length && (
                                                        <Button
                                                            btnType="secondary"
                                                            disabled={loading.value}
                                                            iconRight={loading.value && <i className="fad fa-spinner-third fa-spin" />}
                                                            onClick={() => onTogglePause(true)}
                                                        >
                                                            Pause
                                                        </Button>
                                                    )}

                                                    <Button disabled={dataSourceErrors.length > 0} btnType="secondary" onClick={goSyncSettings}>
                                                        Sync Settings
                                                    </Button>

                                                    {pausedSelectedTables.length !== selectedTables.value.length && renderForceSync()}
                                                </>
                                            ) : (
                                                next()
                                            );
                                        },
                                        ({renderForceSync, onTogglePause, loading}) => {
                                            return (
                                                <>
                                                    <Button
                                                        btnType="secondary"
                                                        disabled={loading.value}
                                                        iconRight={loading.value && <i className="fad fa-spinner-third fa-spin" />}
                                                        onClick={() => onTogglePause(true)}
                                                    >
                                                        Pause
                                                    </Button>

                                                    <Button disabled={dataSourceErrors.length > 0} btnType="secondary" onClick={goSyncSettings}>
                                                        Sync Settings
                                                    </Button>

                                                    {renderForceSync()}
                                                </>
                                            );
                                        }
                                    )}
                                </div>
                            );
                        }
                    )}

                    {cs(
                        consumeContext("apis"),
                        [
                            "summary",
                            ({apis}, next) =>
                                cs(
                                    ["allTableIDs", (_, next) => next(tables.map((t) => t.id))],
                                    [
                                        "formatMDate",
                                        (_, next) =>
                                            next((d) => ({
                                                date: {
                                                    year: d.get("year"),
                                                    month: d.get("month") + 1,
                                                    day: d.get("date"),
                                                },
                                                time: {
                                                    hours: d.get("hour"),
                                                    minutes: d.get("minute"),
                                                    seconds: d.get("second"),
                                                },
                                            })),
                                    ],
                                    [
                                        "last30Syncs",
                                        ({formatMDate, allTableIDs}, next) => {
                                            const params = {
                                                start: formatDateTime(formatMDate(moment().startOf("day").subtract(29, "d").utc())),
                                                end: formatDateTime(formatMDate(moment().endOf("day").utc())),
                                            };

                                            return CacheSync({
                                                _key: JSON.stringify({
                                                    params,
                                                    selected: allTableIDs,
                                                }),
                                                fetch: async () => {
                                                    let summaries = await apis.data.getTableHistoriesSummaries(dataSource.value.id, params);
                                                    return allTableIDs.map((tID) => {
                                                        let resp = summaries.filter((t) => t.tableID == tID);
                                                        if (resp && resp.length) return resp[0];
                                                        return {tableID: tID, synchronizations: []};
                                                    });
                                                },
                                                next,
                                            });
                                        },
                                    ],
                                    ({last30Syncs}) => {
                                        const filterFn = (ss) => (selectedTablesIDs.length > 0 ? selectedTablesIDs.includes(ss.tableID) : true);

                                        return next({
                                            syncsSummary: tables.filter(filterFn),
                                            last30Syncs: last30Syncs ? last30Syncs.filter(filterFn) : null,
                                        });
                                    }
                                ),
                        ],
                        ({summary}) => {
                            if (!summary.last30Syncs) return SyncInfoSkeletons();

                            const kpiInfo = summary.last30Syncs.map((item) => ({
                                ...item,
                                synchronizations: item.synchronizations.filter(
                                    (s) => moment().isSame(new Date(s.date), "day") || moment(new Date(s.date)).isSame(moment().subtract(1, "day"), "day")
                                ),
                            }));

                            const syncInfo = kpiInfo
                                .map((ss) => {
                                    const getInfo = (sameDay, props) => {
                                        return ss.synchronizations
                                            ?.filter((s) => (sameDay ? moment().isSame(moment(s.date), "day") : moment().isAfter(moment(s.date), "day")))
                                            .reduce((prev, item) => (prev += item[props] ?? 0), 0);
                                    };

                                    const dataSyncedToday = getInfo(true, "sizeMB");
                                    const dataSyncedPrev = getInfo(false, "sizeMB");

                                    const recordsSyncedToday = getInfo(true, "records");
                                    const recordsSyncedPrev = getInfo(false, "records");

                                    return {
                                        dataSyncedToday,
                                        dataSyncedPrev,
                                        recordsSyncedToday,
                                        recordsSyncedPrev,
                                    };
                                })
                                .reduce(
                                    (prev, item) => ({
                                        dataSyncedToday: prev.dataSyncedToday + item.dataSyncedToday,
                                        dataSyncedPrev: prev.dataSyncedPrev + item.dataSyncedPrev,
                                        recordsSyncedToday: prev.recordsSyncedToday + item.recordsSyncedToday,
                                        recordsSyncedPrev: prev.recordsSyncedPrev + item.recordsSyncedPrev,
                                    }),
                                    {
                                        dataSyncedToday: 0,
                                        dataSyncedPrev: 0,
                                        recordsSyncedToday: 0,
                                        recordsSyncedPrev: 0,
                                    }
                                );

                            return (
                                <>
                                    {cs(
                                        [
                                            "getChanges",
                                            (_, next) =>
                                                next((prev, current) => {
                                                    if (prev === current)
                                                        return (
                                                            <>
                                                                {IconUnchanged({})}
                                                                <span className="unchanged">0%</span>
                                                            </>
                                                        );

                                                    if (prev > current) {
                                                        return (
                                                            <>
                                                                {IconDown({})}
                                                                <span className="down">{Number(((prev - current) / prev) * 100).toLocaleString()}%</span>
                                                            </>
                                                        );
                                                    } else {
                                                        return (
                                                            <>
                                                                {IconUp({})}
                                                                <span className="up">{Number(((current - prev) / current) * 100).toLocaleString()}%</span>
                                                            </>
                                                        );
                                                    }
                                                }),
                                        ],
                                        ({getChanges}) => (
                                            <>
                                                <div className="card stats">
                                                    <div className="label">Data Synced Today</div>
                                                    <div className="content">
                                                        <div className="value">{Number(syncInfo.dataSyncedToday).toLocaleString()} MB</div>
                                                        <div className="past">
                                                            <div className="value">{Number(syncInfo.dataSyncedPrev).toLocaleString()} MB</div>
                                                            <div className="changes">{getChanges(syncInfo.dataSyncedPrev, syncInfo.dataSyncedToday)}</div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="card stats">
                                                    <div className="label">Records Synced Today</div>
                                                    <div className="content">
                                                        <div className="value">{Number(syncInfo.recordsSyncedToday).toLocaleString()}</div>
                                                        <div className="past">
                                                            <div className="value">{Number(syncInfo.recordsSyncedPrev).toLocaleString()}</div>
                                                            <div className="changes"> {getChanges(syncInfo.recordsSyncedPrev, syncInfo.recordsSyncedToday)}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    )}

                                    {cs(
                                        // (_, next) => !summary.last30Syncs ? SyncLast30Skeletons() : next(),
                                        () => (
                                            <div className="card">
                                                <div className="label no-spacing">Last 30 Days</div>
                                                {SyncSummaryLast30Days({
                                                    data: formatLast30Days(summary.last30Syncs),
                                                })}
                                            </div>
                                        )
                                    )}
                                </>
                            );
                        }
                    )}
                </div>
            </div>
        );
    });
