import * as React from "react";

import {required} from "@common/react/cs-form/validators/required";
import {consumeContext} from "@common/react/context";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {getPath, setPath} from "@common/utils/arr-path";
import {Watch} from "@common/react/watch";
import {Invoke} from "@common/react/invoke";
import {keyed} from "@common/react/keyed";
import {scope} from "@common/react/scope";
import {Form2} from "@common/react/cs-form/form2";
import {LeftPanelClosable} from "../../../../common/left-panel-closable/left-panel-closable";
import {EditContainerForm} from "./edit-container-form/edit-container-form";
import {Static2} from "@common/react/static-2";
import {DeleteTileModal} from "../../../common/delete-tile-modal";

const tileFormConfig = {
    fields: {
        title: {
            transforms: ["trim"],
            validators: [required],
            debounce: true,
        },
    },
};

export const EditContainerTilePanel = ({collection, theme, next: rootNext, addingTilePanel, updateFunc, cTileId}) =>
    cs(
        consumeContext("apis"),
        consumeContext("routing"),
        (_, next) => collection.value && theme && next(),
        ["tilePath", (_, next) => next(["gridLocations", (gl) => gl.tile.id === cTileId, "tile"])],
        ["localContainerTile", ({routing, theme, tilePath}, next) => UseState({next, initValue: null})],
        ({routing, tilePath, localContainerTile}, next) => (
            <>
                {localContainerTile.value?.editing &&
                    Watch({
                        value: getPath(collection.value, tilePath),
                        onChanged: (tile) => {
                            if (tile) {
                                localContainerTile.onChange({
                                    editing: tile,
                                    lastUpdatedAt: null,
                                });
                            }
                        },
                    })}

                {next()}
            </>
        ),
        ["lastSave", (_, next) => UseState({next})],
        [
            "form",
            ({localContainerTile, apis, routing}, next) =>
                Form2({
                    ...tileFormConfig,
                    data: {
                        value: localContainerTile.value?.editing,
                        onChange: (v) => {
                            localContainerTile.onChange({
                                editing: v,
                                lastUpdatedAt: Date.now(),
                            });
                        },
                    },
                    next,
                }),
        ],
        [
            "removeTile",
            ({routing}, next) =>
                DeleteTileModal({
                    next,
                    onConfirm: async (tile) => {
                        if (!tile) {
                            return;
                        }
                        const pathUpdate = ["gridLocations", (gl) => gl.tile.id === cTileId, "tile", "tiles"];
                        const tiles = getPath(collection.value, pathUpdate);
                        return await updateFunc(
                            setPath(
                                collection.value,
                                pathUpdate,
                                tiles.filter((t) => t.id != tile.id)
                            )
                        );
                    },
                }),
        ],
        ["editTile", (_, next) => Static2({next})],
        ({form, localContainerTile, lastSave, tilePath, editTile, removeTile}) => {
            const hide = () => {
                if (localContainerTile.value) {
                    localContainerTile.onChange(null);
                    lastSave.onChange(null);
                }
            };

            return rootNext({
                removeTile,
                editTile,
                show: (containerTile) => {
                    localContainerTile.onChange({
                        editing: containerTile,
                        lastUpdatedAt: null,
                    });
                    lastSave.onChange(null);
                },
                hide,
                ...(localContainerTile.value &&
                    (() => {
                        const editing = scope(localContainerTile, ["editing"]);

                        return {
                            leftPanel: (
                                <>
                                    {(() =>
                                        localContainerTile.value?.lastUpdatedAt &&
                                        (!lastSave.value || lastSave.value < localContainerTile.value.lastUpdatedAt) &&
                                        form.valid &&
                                        cs(keyed(localContainerTile.value.lastUpdatedAt), () =>
                                            Invoke({
                                                fn: async ({isMounted}) => {
                                                    const newCollection = await updateFunc(
                                                        setPath(collection.value, tilePath, editing.value)
                                                    );

                                                    if (isMounted()) {
                                                        const lastUpdatedAt = Date.now();
                                                        lastSave.onChange(lastUpdatedAt);
                                                        localContainerTile.onChange({
                                                            editing: getPath(newCollection, tilePath),
                                                            lastUpdatedAt,
                                                        });
                                                    }
                                                },
                                            })
                                        ))()}

                                    <LeftPanelClosable
                                        {...{
                                            label: "Container",
                                            onClose: () => {
                                                localContainerTile.onChange(null);
                                                lastSave.onChange(null);
                                            },
                                            content: (
                                                <EditContainerForm
                                                    {...{
                                                        containerTile: {
                                                            value: editing.value,
                                                            onChange: (v) => {
                                                                localContainerTile.onChange({
                                                                    editing: v,
                                                                    lastUpdatedAt: Date.now(),
                                                                });
                                                            },
                                                        },
                                                        form,
                                                        onAddTile: () => addingTilePanel.show(),
                                                        onRemoveTile: removeTile,
                                                        onEditTile: (tile) => {
                                                            const editFunc = editTile.get();
                                                            if (editFunc) {
                                                                hide();
                                                                editFunc(tile);
                                                            }
                                                        },
                                                    }}
                                                />
                                            ),
                                        }}
                                    />
                                </>
                            ),
                        };
                    })()),
            });
        }
    );
