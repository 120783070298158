import * as React from "react";

import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {cascade, cascadeCollect} from "@common/utils/cascade";
import {SearchableSelect} from "@common/ui-components/searchable-select/searchable-select";
import {filterTypesToColumnTypes} from "./filter-configs";

export const FieldSelect = ({filter, field, includeAggregationField, isMultiple, label}) =>
    cs(
        consumeContext("modelForCollection"),
        [
            "model",
            ({modelForCollection}, next) =>
                next({
                    ...modelForCollection,
                    tables: (modelForCollection?.disabledTables ?? [])
                        .map((table) => ({
                            ...table,
                            columns: table.columns.concat(table.disabledColumns),
                        }))
                        .concat(modelForCollection.tables),
                }),
        ],
        ({model}) => {
            const options = cascadeCollect(model, "tables[*].columns[*]", (column, {}, {1: table}) => {
                if (
                    ((filterTypesToColumnTypes[filter.value.$type] || []).includes(column.dataType) &&
                        column.$type !== "AggregatedMeasureModelColumn") ||
                    (filter.value.columns?.[0]?.modelTableID === table.id && filter.value.columns?.[0]?.modelColumnID === column.id)
                ) {
                    return {
                        table,
                        column,
                    };
                }

                return null;
            }).filter((item) => (isMultiple ? !(filter.value.columns || []).find((col) => item.column.id == col.modelColumnID) : true));

            const isColumnSelected = ({column, table}) =>
                filter.value.columns?.[0]?.modelID === model.id &&
                filter.value.columns?.[0]?.modelTableID === table.id &&
                filter.value.columns?.[0]?.modelColumnID === column.id;

            const hasError =
                options.find((c) => isColumnSelected(c))?.column?.deleted ||
                options.find((c) => isColumnSelected(c))?.column?.isTableDeleted;

            return SearchableSelect({
                label: isMultiple ? "Select" : label || "Field",
                hasError: field.error || hasError,
                domRef: field.domRef,
                info: isMultiple ? "Add multiple date fields and end-users will be able to select from multiple date fields." : null,
                list: options.filter((item) =>
                    isMultiple ? !(filter.value.columns || []).find((col) => item.column.id == col.modelColumnID) : true
                ),
                valueToLabel: ({column}) => column.visualNameFull,
                isSelected: ({column, table}) => (isMultiple ? false : isColumnSelected({column, table})),
                disabledOption: ({column}) => column.deleted || column.isTableDeleted,
                onChange: ({column, table}) =>
                    filter.change((filter) => ({
                        ...filter,
                        label: isMultiple ? filter.label ?? column.visualNameFull : column.visualNameFull,
                        columns: isMultiple
                            ? (filter.columns || []).concat([
                                  {
                                      modelID: model.id,
                                      modelTableID: table.id,
                                      modelColumnID: column.id,
                                      default: (filter.columns || []).length == 0,
                                  },
                              ])
                            : [
                                  {
                                      modelID: model.id,
                                      modelTableID: table.id,
                                      modelColumnID: column.id,
                                      default: true,
                                  },
                              ],
                        ...(includeAggregationField && {
                            aggregationField: {
                                modelID: model.id,
                                modelTableID: table.id,
                                modelColumnID: column.id,
                            },
                        }),
                    })),
            });
        }
    );

export const AggregationFieldSelect = ({filter, field}) =>
    cs(consumeContext("modelForCollection"), ({modelForCollection: model}) =>
        SearchableSelect({
            label: "Aggregation Field",
            hasError: field.error,
            domRef: field.domRef,
            list: cascade(
                model,
                "tables[*].columns[*]",
                (column, {}, {1: table}) =>
                    column.$type !== "AggregatedMeasureModelColumn" && {
                        table,
                        column,
                    }
            ).filter((v) => v),
            valueToLabel: ({column}) => column.visualNameFull,
            isSelected: ({column, table}) =>
                filter.value.aggregationField?.modelID === model.id &&
                filter.value.aggregationField?.modelTableID === table.id &&
                filter.value.aggregationField?.modelColumnID === column.id,
            onChange: ({column, table}) =>
                filter.change((filter) => ({
                    ...filter,
                    aggregationField: {
                        modelID: model.id,
                        modelTableID: table.id,
                        modelColumnID: column.id,
                    },
                })),
        })
    );
