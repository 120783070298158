import {cs} from "@common/react/chain-services";
import styled from "styled-components";
import {AWS_COGNITO_CLIENT_ID, AWS_COGNITO_HOST} from "../../../common/constant";

const ggBtn = require("./btn_google.png");
const ggBtnDark = require("./btn_google_dark.png");

const SingleSignOnBtn = styled.div`
    margin: 0 auto;
    cursor: pointer;
    height: 46px;
    width: 191px;
    background: url(${ggBtn}) no-repeat;
    background-size: cover;
    &:hover {
        background: url(${ggBtnDark}) no-repeat;
        background-size: cover;
    }
`;

export const LoginSSO = () =>
    cs(() => {
        const REVIVE_SSO = `${AWS_COGNITO_HOST}/oauth2/authorize?identity_provider=ReviveSSO&redirect_uri=${window.location.origin}/sso/index.html&response_type=CODE&client_id=${AWS_COGNITO_CLIENT_ID}&scope=aws.cognito.signin.user.admin+email+openid+profile`;
        return <SingleSignOnBtn onClick={() => (window.location.href = `${REVIVE_SSO}`)} />;
    });
