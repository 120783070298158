import React, {createElement as h, Fragment} from "react";
import {cs} from "@common/react/chain-services";
import {createPortal} from "react-dom";
import {Static2} from "@common/react/static-2";
import {UseState} from "@common/react/use-state";
import "./walk-through-steps.scss";
import {keyed} from "@common/react/keyed";

const Portal = ({children}) =>
    cs(
        ["domRef", ({}, next) => Static2({next})],
        ({domRef}, next) => (
            <>
                {h("div", {
                    className: "walk-through-step-9wq walk-through-step",
                    ref: domRef.set,
                })}
                {next()}
            </>
        ),
        ({domRef}) => domRef.get() && createPortal(children, domRef.get())
    );

export const WalkThroughSteps = ({steps, next}) =>
    cs(
        ["showing", (_, next) => UseState({initValue: true, next})],
        ["stepIndex", (_, next) => UseState({initValue: 0, next})],
        [
            "renderStep",
            ({stepIndex, showing}, next) =>
                next(
                    !showing.value
                        ? () => null
                        : () => {
                              const currentStep = steps[stepIndex.value];
                              const selector = document.querySelector(currentStep.selector);

                              if (selector) {
                                  const rect = selector.getBoundingClientRect();
                                  const contentWidth = rect.width < 100 ? 285 : rect.width;

                                  return (
                                      <div
                                          className="step"
                                          style={{
                                              position: "fixed",
                                              top: rect.top + rect.height,
                                              ...(rect.left + contentWidth >= window.innerWidth
                                                  ? {
                                                        right: window.innerWidth - rect.left - rect.width,
                                                    }
                                                  : {left: rect.left}),
                                          }}
                                      >
                                          <div
                                              className="line"
                                              style={{
                                                  ...(rect.left + contentWidth >= window.innerWidth ? {right: rect.width / 2} : {left: rect.width / 2}),
                                              }}
                                          >
                                              <div className="point" />
                                          </div>
                                          <div className="content" style={{width: contentWidth}}>
                                              {currentStep.content}

                                              {!currentStep.noAction && (
                                                  <div className="actions">
                                                      <div
                                                          className="skip"
                                                          onClick={() => {
                                                              showing.onChange(false);
                                                              sessionStorage.setItem("SKIPPED", "true");
                                                          }}
                                                      >
                                                          Skip
                                                      </div>
                                                      <button className="btn-next" onClick={() => stepIndex.change((oldValue) => oldValue + 1)}>
                                                          Next
                                                      </button>
                                                  </div>
                                              )}
                                          </div>
                                      </div>
                                  );
                              } else return null;
                          }
                ),
        ],
        ({renderStep, stepIndex, showing}) =>
            next({
                showOff: () => showing.onChange(false),
                goNext: (showing1) => {
                    if (stepIndex.value < steps.length - 1) {
                        stepIndex.change((old) => old + 1);
                        setTimeout(() => showing.onChange(true), 200);
                    } else {
                        showing.onChange(false);
                    }
                },
                render: () => {
                    if (sessionStorage.getItem("SKIPPED")) return null;

                    return Portal({
                        children: <div className="steps">{steps.map((step, i) => cs(keyed(i), () => renderStep()))}</div>,
                    });
                },
            })
    );
