import "./filter-label.scss";

import * as React from "react";

import {cs} from "../../../../../../../react/chain-services";
import {consumeContext} from "../../../../../../../react/context";
import {Invoke} from "../../../../../../../react/invoke";
import {Static2} from "../../../../../../../react/static-2";
import {UseState} from "../../../../../../../react/use-state";
import {arrMapToO} from "../../../../../../../utils/objects";
import {waitTimeout} from "../../../../../../../utils/wait-timeout";
import {StaticTooltipService} from "../../../../../../../../web-client/src/routes/common/tooltip3/static-tooltip-service";
import {tooltipService4} from "../../../../../../../../web-client/src/routes/common/tooltip3/tooltip-service-3";

export const FilterLabel = ({text}) =>
    cs(
        consumeContext("theme"),
        ["staticRef", (_, next) => Static2({next})],
        ["isOverflowed", (_, next) => UseState({next})],
        ["staticTooltip", (_, next) => StaticTooltipService({direction: "top", info: text, next})],
        [
            "tooltip",
            ({theme}, next) =>
                tooltipService4({
                    direction: theme.dataVisualization.toolTipsAndLegends.position?.toLowerCase() || "above",
                    className: `${theme.dataVisualization.toolTipsAndLegends.tooltipTheme.toLowerCase() || "light"}-theme`,
                    // tooltipContentStyle: {fontSize: getTooltipFontSizes(theme).medium},
                    next,
                }),
        ],
        ({staticRef, isOverflowed, staticTooltip, tooltip, theme}) => {
            const checkOverflowed = (text) => {
                const dom = staticRef.get();
                const fullComputedStyle = getComputedStyle(dom);

                const computedStyle = arrMapToO(
                    ["font-size", "letter-spacing", "width"],
                    (v) => +fullComputedStyle.getPropertyValue(v).replace("px", ""),
                    (v) => v
                );
                const textLength = (text?.length || 0) * computedStyle["font-size"] * computedStyle["letter-spacing"];

                isOverflowed.onChange(textLength > computedStyle?.width);
            };

            return (
                <>
                    {Invoke({
                        fn: async ({isMounted}) => {
                            await waitTimeout(150);
                            if (isMounted()) {
                                checkOverflowed(text);
                            }
                        },
                    })}
                    <div
                        ref={staticRef.set}
                        className="filter-label-9op"
                        style={{
                            color: theme.general.components.inputLabelTextColorRGB,
                        }}
                        {...(isOverflowed.value ? tooltip(() => text) : {})}
                    >
                        {text}
                    </div>
                </>
            );
        }
    );
