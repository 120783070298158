import React from "react";
import {cs} from "@common/react/chain-services";
import {DropdownSelect2} from "@common/ui-components/dropdown-select/dropdown-select2";
import "./combine-text-configuration.scss";
import {UseState} from "@common/react/use-state";
import {ffToDropdown} from "../../../../../../../../../../common/form/ff-to-dropdown";
import {keepOnly} from "@common/utils/objects";
import {ffToTextInput} from "../../../../../../../../../../common/form/ff-to-text-input";
import {DebounceTextInput} from "../../../../../../../../../../common/form/debounce-input/debounce-text-input";
import {SelectColumns} from "../../../../../common/select-columns/select-columns";
import {rNewColumnNameInput} from "../../../common/new-column-name-input";

const separators = [
    {value: "", text: "None"},
    {value: ",", text: "Comma"},
    {value: ";", text: "Semicolon"},
    {value: " ", text: "Space"},
    {value: "\t", text: "Tab"},
    {value: null, text: "Other"},
];

export const CombineTextConfiguration = ({textManipulation, state, form, inputStep, step, model}) =>
    cs(
        [
            "state1",
            (_, next) =>
                UseState({
                    initValue:
                        textManipulation?.value.separator == null
                            ? separators[0]
                            : separators.filter((x) => x.value === textManipulation?.value.separator).length == 1
                            ? separators.filter((x) => x.value === textManipulation?.value.separator)[0]
                            : separators.filter((x) => x.text === "Other")[0],
                    next,
                }),
        ],
        ({state1}) => {
            return (
                <div className="combine-text-configuration-9er">
                    <div className="config-group">
                        <div className="label">Separator</div>

                        <div className="separator-group">
                            {(() => {
                                const dropdownProps = ffToDropdown(form.field(["textManipulation", "separator"]));

                                const x = (v) => {
                                    var sel = v.value === state1.value.value || (v.text === "Other" && separators.filter((x) => x.value === state1.value.value).length == 0);
                                    return sel;
                                };

                                return DropdownSelect2({
                                    label: "Type",
                                    list: separators,
                                    isSelected: (v) => x(v),
                                    valueToLabel: (v) => v.text,
                                    onChange: (v) => {
                                        if (v !== "Other") dropdownProps.onChange(v.value);
                                        state1.onChange(v);
                                    },
                                    ...keepOnly(dropdownProps, ["hasError", "errorMessage", "domRef"]),
                                });
                            })()}

                            {state1.value.text === "Other" &&
                                (() => {
                                    const inputProps = ffToTextInput(form.field(["textManipulation", "separator"]));
                                    return DebounceTextInput({
                                        label: "Name",
                                        ...inputProps,
                                    });
                                })()}
                        </div>
                    </div>

                    <div className="config-group">
                        <div className="label">Combined Columns</div>

                        {SelectColumns({
                            step,
                            form,
                            path: ["textManipulation", "inputValues"],
                            columns: inputStep?.outputColumns ?? [],
                            model,
                            enableStaticValue: true,
                            staticValueType: "text",
                        })}
                    </div>

                    <div className="config-group">
                        <div className="label">New Column Name</div>

                        {rNewColumnNameInput({
                            form,
                            newColumnPath: ["textManipulation", "newColumn"],
                            newColumnNamePath: ["textManipulation", "newColumn", "name"],
                        })}
                    </div>
                </div>
            );
        }
    );
