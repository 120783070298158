export const RAFLoop = (() => {
    let targets = [];
    let animationFrameID = 0;
    let _isActive = false;

    const rafCallback = (timestamp) => {
        if (!_isActive) {
            return 0;
        }

        for (let i = 0; i < targets.length; i++) {
            if (!targets[i]._unmounted) targets[i].update(timestamp);
        }

        animationFrameID = requestAnimationFrame(rafCallback);
        return animationFrameID;
    };

    const start = () => {
        if (!_isActive && targets.length) {
            _isActive = true;

            if (animationFrameID) cancelAnimationFrame(animationFrameID);
            animationFrameID = requestAnimationFrame(rafCallback);
        }

        return animationFrameID;
    };

    const stop = () => {
        if (_isActive) {
            _isActive = false;

            if (animationFrameID) cancelAnimationFrame(animationFrameID);
            animationFrameID = 0;
        }
        return animationFrameID;
    };

    const removeTarget = (target) => {
        const idx = targets.indexOf(target);

        if (idx !== -1) {
            targets.splice(idx, 1);

            if (targets.length === 0) stop();
        }

        return animationFrameID;
    };

    return {
        addTarget: (target, silent = false) => {
            if (!targets.includes(target)) {
                targets.push(target);

                if (targets.length === 1 && !silent) start();
            }
            return () => removeTarget(target);
        },
        removeTarget,
        isActive: () => _isActive,
        start,
        stop,
    };
})();

const DefaultOptions = {
    onStart: () => {},
    onProcess: () => {},
    onDone: () => {},
};

export const animate = (options = DefaultOptions, duration = 1000) => {
    let startTime = 0,
        percentage = 0,
        animationTime = 0;
    duration = duration || 1000;

    const animation = function (timestamp) {
        if (startTime === 0) {
            startTime = timestamp;
        } else {
            animationTime = timestamp - startTime;
        }

        if (typeof options.onStart === "function" && startTime === timestamp) {
            options.onStart();

            // requestAnimationFrame(animation);
        } else if (animationTime < duration) {
            if (typeof options.onProcess === "function") {
                percentage = animationTime / duration;
                options.onProcess(percentage);
            }

            // requestAnimationFrame(animation);
        } else if (typeof options.onDone === "function") {
            options.onDone();
            removeListener();
        }
    };

    const removeListener = RAFLoop.addTarget({
        update: animation,
    });

    return removeListener;
};
