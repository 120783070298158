import React from "react";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {Invoke} from "@common/react/invoke";
import {DialogService} from "../../../../common/dialog/dialog-service";
import "./new-user-dialog.scss";
import {keyed} from "@common/react/keyed";
import {Button} from "../../../../../../../common/form/buttons/button/button";

export const NewUserDialog = ({next: rootNext}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    render: ({resolve}) => ({
                        customizeDialog: true,
                        content: next({resolve}),
                        width: 888,
                    }),
                    strong: true,
                    next: rootNext,
                }),
        ],
        consumeContext("auth"),
        consumeContext("routing"),
        consumeContext("tenant"),
        ({auth, routing, tenant: {environments}, modal}) => {
            const list = [
                {
                    icon: <img src={require("./speed-test-icon.svg")} alt="" />,
                    label: "Try a Sample Environment",
                    content: "View your sample environment and embed a demo dashboard to see how it looks in your application.",
                    button: {
                        label: "View Sample Environment",
                        click: () => {
                            // routing.goto("dashboard", {envId: environments.find((e) => e.readOnly).id});
                            routing.goto("waiting-setup");
                            modal.resolve();
                        },
                    },
                },
                // {
                //     icon: <img src={require("./idea-icon.svg")} alt="" />,
                //     label: "Follow a Guided Setup",
                //     content: "Use our step by step walkthrough to setup your first dashboard using your own data.",
                //     button: {
                //         label: "View Guide",
                //         click: () => {
                //             routing.goto("getting-started");
                //             modal.resolve();
                //         },
                //     },
                // },
            ];

            return (
                <div className="getting-started-dialog-8io">
                    <div className="header">
                        <div className="text-lg">👋 Welcome to Verb, {auth.user.firstName}!</div>
                        <div className="text-sm">Let’s get you started</div>
                    </div>

                    <div className="gs-cards">
                        {list.map((item, i) =>
                            cs(keyed(i), () => (
                                <div className="gs-card">
                                    <div className="icon">{item.icon}</div>
                                    <div className="label">{item.label}</div>
                                    <div className="content">{item.content}</div>
                                    <div className="action">
                                        <Button btnType="secondary" onClick={() => item.button.click()}>
                                            {item.button.label}
                                        </Button>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>

                    <div className="skip" onClick={modal.resolve}>
                        Skip Onboarding
                    </div>
                </div>
            );
        }
    );

export const loadGsNewUser = ({next}) =>
    cs(["gettingStartedDialog", (_, next) => NewUserDialog({next})], ({gettingStartedDialog}) =>
        Invoke({
            onMounted: () => gettingStartedDialog.show(),
            next,
        })
    );
