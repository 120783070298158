import "./controller.scss";

import {css, cx} from "emotion";
import moment from "moment-timezone";
import * as React from "react";

import {cs} from "../../../../../../../../react/chain-services";
import {consumeContext} from "../../../../../../../../react/context";
import {scope} from "../../../../../../../../react/scope";
import {spc} from "../../../../../../../../react/state-path-change";
import {UseState} from "../../../../../../../../react/use-state";

import {generateDefaultTimeRange} from "@common/logic/date-time/date-util";
import {GlobalEvent} from "@common/react/global-event";
import {Invoke} from "@common/react/invoke";
import {SubcribeKeyEvents} from "@common/react/keys/global-key-down";
import {StyledClass} from "@common/react/styled-class";
import {DpMaskInput} from "@common/ui-components/live/filters/dp-mask-input/dp-mask-input";
import {lastXDays} from "@common/ui-components/live/live-dashboard/common/logic/last-x-days";
import {TimePickerInputs} from "@common/ui-components/live/live-dashboard/live-filters/filter-types/date/expand/controller/time-picker/time-picker-inputs";
import {
    getPickableCompareRange,
    getPickableRangesFromToday,
} from "../../../../../../../../../web-client/src/routes/collection/common/filters/filter-edit-main/options/date-filter/date-picker-selectable-range/utils";
import {CheckboxLine} from "../../../../../../../../../web-client/src/routes/common/checkbox-line/checkbox-line";
import {stringToList} from "../../../../../../../../../web-client/src/routes/common/data-logic/string-to-list";
import {Static2} from "../../../../../../../../react/static-2";
import {addDate, dateDiff, parseDate, toDate} from "../../../../../../../../utils/dates/date-object";
import {sameDateRange, sameExactDateRange} from "../../../../../../../../utils/dates/date-ranges";
import {chain} from "../../../../../../../../utils/fs";
import {Dropdown} from "../../../../../../../dropdown/dropdown";
import {DropdownSelectLive} from "../../../../../../filters/common/dropdown-select/dropdown-select-live";
import {defaultDateRangeModes} from "../../../../../common/logic/default-date-range-modes";
import {SelectTimezoneDropdown} from "../../../../layout/live-filters-desktop/select-timezone";
import {getPreviousDays} from "../common/time/get-previous-days";
import {getMode} from "../get-mode";
import {calculatedCompareRangeModes, COMPARE_MODES} from "./compare-modes";

export const Controller = ({state, filter, isMobile, dateRangesPicker, onChange, theme, noComparing, showSelectTimezone, onReset}) => {
    const allowModes = defaultDateRangeModes.filter((rp) => stringToList(filter.options).includes(rp.name));

    return cs(
        consumeContext("viewWidth"),
        ["getMode", (_, next) => next((mainRange) => getMode(mainRange, filter.defaultOption))],
        [
            "dropdowns",
            (_, next) =>
                Static2({
                    next,
                    getInitValue: () => ({}),
                }),
        ],
        ["compareRanges", (_, next) => calculatedCompareRangeModes({state, next})],
        ({getMode, viewWidth, dropdowns, compareRanges}) => {
            console.log({compareRanges});
            const {hyperlinkButton} = theme.general.buttons;
            const componentTheme = theme.general.components;
            const inputStyle = {
                border: `${componentTheme.inputBorderWidth}px solid ${componentTheme.inputBorderColorRGB}`,
                background: `${componentTheme.inputBackgroundColorRGB}`,
                color: `${componentTheme.inputTextColorRGB}`,
                borderRadius: `${componentTheme.inputCornerRadius}px`,
            };

            const currentMode = getMode(state.value.main);
            const isModeSelected = (mode) => {
                let expected = "AllTime";
                if (state.value.allowTimeFilter) {
                    return mode.name == "CustomRangeWithTime";
                }
                if (state.value.main) {
                    if (!currentMode || !allowModes.find((m) => m.name === currentMode.name)) {
                        expected = "Custom";
                    } else {
                        expected = currentMode.name;
                    }
                }

                return mode.name === expected;
            };
            const pickableRange = getPickableRangesFromToday(filter);
            const pickableComparingRange = getPickableCompareRange(state.value.main);
            const onCalendarChange = ({name, range}) => {
                if (filter.allowTimeOfDay) {
                    return onChange({
                        name,
                        range: {
                            from: {...(state.value?.[name]?.from ?? {}), ...(range?.from ?? {})},
                            to: {...(state.value?.[name]?.to ?? {}), ...(range?.to ?? {})},
                        },
                    });
                }
                return onChange({name, range});
            };

            const bindTimeInputChange = (state, name) => {
                return {
                    value: state.value?.[name],
                    onChange: (range) => {
                        onChange({name, range});
                    },
                };
            };

            return cs(
                [
                    "cssClass",
                    (_, next) =>
                        StyledClass({
                            content: {
                                background: `${componentTheme.menuBackgroundColorRGB}`,
                                color: `${componentTheme.menuTextColorRGB}`,
                                "&:hover": {
                                    background: `${componentTheme.menuHoverBackgroundColorRGB}`,
                                },

                                "&.selected": {
                                    background: `${componentTheme.menuHoverBackgroundColorRGB}`,
                                },
                            },
                            next,
                        }),
                ],
                [
                    "toggleClass",
                    (_, next) =>
                        StyledClass({
                            next,
                            content: {
                                color: `${hyperlinkButton.fontColorRGB} !important`,

                                "&:hover": {
                                    color: `${hyperlinkButton.hoverFontColorRGB} !important`,
                                },
                            },
                        }),
                ],
                ({toggleClass, cssClass}) => (
                    <div className="controller controller-1s9">
                        <div className={cx("ranges", {isDesktop: !isMobile})}>
                            <div className="range-1">
                                <div
                                    className="header"
                                    style={{
                                        color: componentTheme.inputLabelTextColorRGB,
                                    }}
                                >
                                    Range
                                    {showSelectTimezone &&
                                        SelectTimezoneDropdown({
                                            theme,
                                            toggle: ({showExpand, showingExpand, selectedTimezone, disabled}) => (
                                                <span className={cx("select-timezone", toggleClass)} onClick={() => !disabled && showExpand(!showingExpand)}>
                                                    Time Zone: {selectedTimezone ?? "UTC"} <i className="fa fa-chevron-down" aria-hidden="true" />
                                                </span>
                                            ),
                                        })}
                                </div>

                                <div className="controls">
                                    <div className="select">
                                        {DropdownSelectLive({
                                            closeAllOtherDropdownWhenOpen: false,
                                            list: chain(allowModes, (modes) => {
                                                if (state.value.main && (!currentMode || !allowModes.find((m) => m.name === currentMode.name))) {
                                                    if (!modes.find((m) => m.name === "Custom"))
                                                        return [
                                                            ...modes,
                                                            {
                                                                label: "Custom",
                                                                name: "Custom",
                                                            },
                                                        ];
                                                }

                                                return modes;
                                            }).concat(
                                                filter.allowTimeOfDay
                                                    ? [
                                                          {
                                                              label: "Custom range with time",
                                                              name: "CustomRangeWithTime",
                                                              getMainRange: () => state.value.main,
                                                              getComparingRange: () => getPreviousDays(generateDefaultTimeRange(state.value.main)),
                                                          },
                                                      ]
                                                    : []
                                            ),
                                            prioritizeSelect: false,
                                            valueToLabel: (mode) => mode.label,
                                            customExpandPosition: isMobile && viewWidth?.value ? {left: 12} : {},
                                            isSelected: (mode) => isModeSelected(mode, currentMode, allowModes),
                                            onChange: (mode) => {
                                                if (mode.name == "CustomRangeWithTime") {
                                                    state.onChange({
                                                        ...state.value,
                                                        main: generateDefaultTimeRange(mode.getMainRange?.(state.value)),
                                                        comparing: generateDefaultTimeRange(!state.value.enableComparing || !mode.getMainRange ? null : mode.getComparingRange()),
                                                        allowTimeFilter: true,
                                                    });
                                                } else {
                                                    state.onChange({
                                                        ...state.value,
                                                        main: mode.getMainRange?.(state.value),
                                                        comparing: !state.value.enableComparing || !mode.getMainRange ? null : mode.getComparingRange(),
                                                        allowTimeFilter: false,
                                                    });
                                                }
                                            },
                                            toggleStyle: inputStyle,
                                            iconColor: componentTheme.inputIconColorRGB,
                                            dropdownCss: cssClass,
                                            borderRadius: componentTheme.menuCornerRadius,
                                        })}
                                    </div>
                                    <div className="calendars">
                                        {state.value.main &&
                                            CalendarInputs({
                                                name: "main",
                                                dropdowns,
                                                onDropdownRegister: (dropdown) => dropdowns.set({...dropdowns.get(), main: dropdown}),
                                                isMobile,
                                                theme,
                                                onReset,
                                                onChange: (range) => {
                                                    onCalendarChange(range);
                                                },
                                                initCalendar: (next) =>
                                                    dateRangesPicker.initSingleCalendar({
                                                        next,
                                                        name: "main",
                                                        pickableRange,
                                                    }),
                                            })}
                                        {state.value.allowTimeFilter &&
                                            TimePickerInputs({
                                                state: bindTimeInputChange(state, "main"),
                                            })}
                                    </div>
                                </div>
                            </div>

                            {filter.compareEnabled &&
                                !noComparing &&
                                (() => {
                                    const currentCompareRange = (() => {
                                        if (!state.value.main) {
                                            return null;
                                        }
                                        if (!state.value.comparing) {
                                            return compareRanges.find((r) => r.key === COMPARE_MODES.PREVIOUS_PERIOD);
                                        }
                                        return (
                                            compareRanges.find((r) => sameExactDateRange(r.range, state.value.comparing)) ??
                                            compareRanges.find((r) => r.key === COMPARE_MODES.CUSTOM_PERIOD_START)
                                        );
                                    })();
                                    const enableComparing = scope(state, ["enableComparing"]);

                                    const havingMultipleFields = filter.multiSelectionOption !== "All" && state.value?.columns?.length > 1;

                                    const allowCompare = state.value.enableComparing && !havingMultipleFields && state.value.main;

                                    return (
                                        <div className="range-1">
                                            <div className="header">
                                                {CheckboxLine({
                                                    label: havingMultipleFields
                                                        ? "Compare To (Not available on multiple fields)"
                                                        : state.value.main
                                                        ? "Compare To"
                                                        : "Compare To (Cannot compare All-Time)",
                                                    state: {
                                                        value: enableComparing.value && !havingMultipleFields && state.value.main,
                                                        onChange: (val) => {
                                                            const comparing = scope(state, ["comparing"]);
                                                            if (!comparing.value && val) {
                                                                comparing.onChange(currentCompareRange?.range);
                                                            }
                                                            enableComparing.onChange(val);
                                                        },
                                                    },
                                                    background: theme.general.buttons.primaryButton.backgroundColorRGB,
                                                    disabled: havingMultipleFields || !state.value.main,
                                                    textColor: componentTheme.inputLabelTextColorRGB,
                                                })}
                                            </div>

                                            {allowCompare && (
                                                <div className="controls">
                                                    <div className="select">
                                                        {DropdownSelectLive({
                                                            list: compareRanges,
                                                            valueToLabel: (comparingMode) => comparingMode.label,
                                                            prioritizeSelect: false,
                                                            closeAllOtherDropdownWhenOpen: false,
                                                            customExpandPosition: isMobile && viewWidth?.value ? {left: 12} : {},
                                                            isSelected: (comparingMode) => {
                                                                return currentCompareRange?.key === comparingMode.key;
                                                            },
                                                            onChange: (comparingMode) => {
                                                                return spc(state, ["comparing"], () => comparingMode?.range);
                                                            },
                                                            toggleStyle: inputStyle,
                                                            iconColor: componentTheme.inputIconColorRGB,
                                                            dropdownCss: cssClass,
                                                            borderRadius: componentTheme.menuCornerRadius,
                                                        })}
                                                    </div>
                                                    <div className="calendars">
                                                        {CalendarInputs({
                                                            name: "comparing",
                                                            isMobile,
                                                            onDropdownRegister: (dropdown) => dropdowns.set({...dropdowns.get(), comparing: dropdown}),
                                                            dropdowns,
                                                            theme,
                                                            onChange: (range) => {
                                                                onCalendarChange(range);
                                                            },
                                                            disabled: {
                                                                from: false,
                                                                to: true,
                                                            },
                                                            onReset,
                                                            initCalendar: (next) =>
                                                                dateRangesPicker.initAutoRangeCalendar({
                                                                    next,
                                                                    pickableRange: pickableComparingRange,
                                                                    name: "comparing",
                                                                    diff: state.value.main ? dateDiff(state.value.main.to, state.value.main.from) : 0,
                                                                }),
                                                        })}
                                                        {state.value.allowTimeFilter &&
                                                            TimePickerInputs({
                                                                state: bindTimeInputChange(state, "comparing"),
                                                            })}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    );
                                })()}
                        </div>
                    </div>
                )
            );
        }
    );
};

const CalendarInputs = ({name, isMobile, theme, onReset, disabled = {}, initCalendar, onDropdownRegister, dropdowns, onChange}) => {
    return cs(
        ["toggleRef", (_, next) => Static2({next})],
        ["calendar", (_, next) => initCalendar(next)],
        ["rangeInputs", ({calendar}, next) => calendar.getRangeInputs({next, onChange})],
        [
            "closeBtnCss",
            (_, next) => {
                const componentTheme = theme.general.components;

                return StyledClass({
                    next,
                    content: {
                        color: `${componentTheme?.inputTextColorRGB ?? "#919EB0"}`,
                        background: `${componentTheme?.inputBackgroundColorRGB ?? "white"}`,
                        border: `${componentTheme?.inputBorderWidth ?? 1}px solid ${componentTheme?.inputBorderColorRGB ?? "#DEE2E7"}`,
                        "border-radius": `${componentTheme?.inputCornerRadius ?? 4}px`,
                    },
                });
            },
        ],
        [
            "calendarDropdown",
            ({toggleRef, calendar, rangeInputs, closeBtnCss}, next) => {
                const componentTheme = theme.general.components;
                const onClose = () => {
                    rangeInputs.from.resetInput();
                    rangeInputs.to.resetInput();
                    if (!rangeInputs.from.valid || !rangeInputs.to.valid) {
                        onReset();
                    }
                };

                return Dropdown({
                    registryRender: false,
                    customZIndex: 100,
                    disabledTab: true,
                    customExpandPosition: {
                        position: "absolute",
                    },
                    closeAllOtherDropdownWhenOpen: false,
                    customToggleRef: toggleRef,
                    next,
                    onPassiveClose: onClose,
                    onDropdownClose: onClose,
                    renderExpand: ({close, width}) => (
                        <div
                            className="calendar-box"
                            style={{
                                background: componentTheme.menuBackgroundColorRGB,
                                borderRadius: componentTheme.menuCornerRadius,
                            }}
                        >
                            {calendar.render({
                                onChange: ({attr, name, range}) => {
                                    onChange({name, range});
                                    if (attr === "to") {
                                        close();
                                    }
                                },
                                renderActions: isMobile
                                    ? () => (
                                          <button className={cx("close-btn", closeBtnCss)} onClick={close}>
                                              OK
                                          </button>
                                      )
                                    : null,
                                space: isMobile ? 16 : 4,
                                width,
                            })}
                        </div>
                    ),
                });
            },
        ],
        [
            "inputStyleCss",
            (_, next) => {
                const componentTheme = theme.general.components;
                const buttonTheme = theme.general.buttons;
                return StyledClass({
                    next,
                    content: {
                        "input, .fake-input": {
                            border: `${componentTheme.inputBorderWidth}px solid ${componentTheme.inputBorderColorRGB}`,
                            background: `${componentTheme.inputBackgroundColorRGB}`,
                            color: `${componentTheme.inputTextColorRGB}`,
                            "border-radius": `${componentTheme.inputCornerRadius}px`,
                        },
                        ".fake-input": {
                            "line-height": `calc(37px - ${(componentTheme?.inputBorderWidth ?? 2) * 2}px)`,
                        },
                        "&:not(.invalid).active": {
                            "input, .fake-input": {
                                "box-shadow": `0 0 4px ${buttonTheme.primaryButton.backgroundColorRGB}`,
                            },
                        },
                    },
                });
            },
        ],
        ({calendarDropdown, toggleRef, rangeInputs, inputStyleCss}) => {
            const {from, to} = rangeInputs;

            const isFromExpand = from.isSelected && calendarDropdown?.showingExpand;
            const isToExpand = to.isSelected && calendarDropdown?.showingExpand;
            return (
                <>
                    {!isMobile &&
                        SubcribeKeyEvents({
                            events: [
                                {
                                    keyCombo: "Tab",
                                    onKeyDown: () => {
                                        if (name === "main") {
                                            isFromExpand ? to.onClick() : calendarDropdown.closeDropdown();
                                        } else {
                                            calendarDropdown.closeDropdown();
                                        }
                                    },
                                },
                                {
                                    keyCombo: "Shift+Tab",
                                    onKeyDown: () => {
                                        if (name === "main") {
                                            isToExpand ? from.onClick() : calendarDropdown.closeDropdown();
                                        } else {
                                            calendarDropdown.closeDropdown();
                                        }
                                    },
                                },
                            ],
                        })}
                    {Invoke({
                        fn: () => {
                            onDropdownRegister(calendarDropdown);
                        },
                    })}
                    <div className="collection-inputs">
                        {
                            <div className="range" ref={toggleRef.set}>
                                {DpMaskInput({
                                    ...from,
                                    showDisplayValue: !isFromExpand,
                                    noKeyboard: isMobile,
                                    className: cx(inputStyleCss, {active: isFromExpand, invalid: !from.valid}),
                                    onClick: (e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        calendarDropdown.showExpand(true, {top: 6, left: 0});
                                        from.onClick(e);
                                    },

                                    disabled: disabled.from,
                                    state: from.state,
                                })}
                                <div className="dash">to</div>
                                {DpMaskInput({
                                    ...to,
                                    noKeyboard: isMobile,
                                    showDisplayValue: !isToExpand,
                                    className: cx(inputStyleCss, {active: isToExpand, invalid: !to.valid}),
                                    onClick: (e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        calendarDropdown.showExpand(true, {top: 6, left: 0});
                                        to.onClick(e);
                                    },

                                    state: to.state,
                                    disabled: disabled.to,
                                })}
                            </div>
                        }
                    </div>
                </>
            );
        }
    );
};
