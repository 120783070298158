import {cs} from "@common/react/chain-services";
import SpinnerIcon from "../../../../../../assets/icons/data-sources/SpinnerIcon";
import {cx} from "emotion";

export const FilterRowsInfoBox = ({queryResult, loading}) =>
    cs(
        (_, next) =>
            loading ? (
                <div className={cx("filter-rows-info-box-88i", {loading})}>
                    {SpinnerIcon({})}
                    <div className="text">
                        <div className="label">Running test...</div>
                    </div>
                </div>
            ) : (
                next()
            ),
        (_, next) => (queryResult ? next() : null),
        (_) =>
            queryResult.message == null ? (
                <div className={cx("filter-rows-info-box-88i")}>
                    <i className="fas fa-check-circle" />
                    <div className="text">
                        <div className="label">Returned {queryResult?.rowCount} rows</div>
                        <div className="details">{queryResult?.message}</div>
                    </div>
                </div>
            ) : (
                <div className={cx("filter-rows-info-box-88i error")}>
                    <i className="fas fa-exclamation-circle" />
                    <div className="text">
                        <div className="details">{queryResult?.message}</div>
                    </div>
                </div>
            )
    );
