import {cs} from "@common/react/chain-services";
import {ThemeLeftPanelSections} from "../../../common/theme-left-panel-sections/theme-left-panel-sections";
import {ButtonTheme} from "./button-theme";
import {ButtonToggleTheme} from "./button-toggle-theme";
import {HyperlinkButtonTheme} from "./hyperlink-button-theme";

export const ButtonsTheme = ({form, overrideLeftPanelRef}) =>
    cs(
        [
            "sections",
            ({ref}, next) =>
                next([
                    {
                        label: "PRIMARY BUTTON",
                        render: (form) => <ButtonTheme key="primaryButton" form={form} itemKey="primaryButton" />,
                    },
                    {
                        label: "SECONDARY BUTTON",
                        render: (form) => <ButtonTheme key="secondaryButton" form={form} itemKey="secondaryButton" />,
                    },
                    {
                        label: "DOWNLOAD BUTTON",
                        render: (form) => <ButtonTheme key="downloadButton" form={form} itemKey="downloadButton" />,
                    },
                    {
                        label: "BUTTON TOGGLE & GROUP",
                        render: (form) => ButtonToggleTheme({form}),
                    },
                    {
                        label: "HYPER LINK BUTTON",
                        render: (form) => <HyperlinkButtonTheme key="hyperLinkButton" form={form} />,
                    },
                ]),
        ],
        ({sections}) =>
            ThemeLeftPanelSections({
                sections,
                overrideLeftPanelRef,
                form,
            })
    );
