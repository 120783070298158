import React from "react";
import {cs} from "@common/react/chain-services";
import {DropdownSelect2} from "@common/ui-components/dropdown-select/dropdown-select2";
import {stateToSelect} from "@common/ui-components/form/state-to-select";
import {scope} from "@common/react/scope";
import {keyed} from "@common/react/keyed";
import "./remove-duplicates-configuration.scss";
import {UseState} from "@common/react/use-state";
import {SelectColumns} from "../../../../common/select-columns/select-columns";
import {spc} from "@common/react/state-path-change";
import {GhostButton} from "../../../../../../../../../common/form/buttons/ghost-button/ghost-button";
import {PlusIcon} from "@common/ui-components/icons/global-icons";

export const RemoveDuplicatesConfiguration = ({step, form, transformation}) =>
    cs(() => {
        const prevStep = transformation.value.steps.find((s) => s.id === step.value.inputStepID);
        return (
            <div className="remove-duplicates-configuration-0ew">
                <div className="config-group">
                    <div className="label">Select Columns to remove duplicates</div>

                    {SelectColumns({
                        step,
                        form,
                        path: ["columnsToDedup"],
                        columns: prevStep?.outputColumns ?? [],
                    })}

                    <GhostButton iconLeft={<PlusIcon />} className="btn-add" onClick={() => spc(step, ["columnsToDedup"], () => prevStep.outputColumns)}>
                        Add All Columns
                    </GhostButton>
                </div>

                <div className="config-group">
                    <div className="label">Search with letter case</div>

                    {DropdownSelect2({
                        label: "Case Sensitivity",
                        list: ["Case Sensitive Search", "Case Insensitive Search (Ignore Case)"],
                        isSelected: (v) => v == (step?.value?.caseSensitive ? "Case Sensitive Search" : "Case Insensitive Search (Ignore Case)"),
                        onChange: (v) => spc(step, ["caseSensitive"], () => v === "Case Sensitive Search"),
                    })}
                </div>
            </div>
        );
    });
