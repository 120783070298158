import React from "react";

import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {Form2} from "@common/react/cs-form/form2";
import {required} from "@common/react/cs-form/validators/required";
import {Queue1} from "@common/react/queue-1";
import {Watch} from "@common/react/watch";
import {consumeContext} from "@common/react/context";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";
import {TextInput} from "../../../../../../common/form/text-input/text-input";
import {ffToBasicInput} from "../../../../../../common/form/ff-to-basic-input";
import {DropdownSelect} from "../../../../../../common/ui-components/dropdown-select/dropdown-select";

export const PersonalGeneral = () =>
    cs(consumeContext("auth"), consumeContext("apis"), ({apis, auth}) => (
        <VerbScrollbar className="account-settings-general-88i">
            <div className="section">
                <div className="section-header">Personal Details</div>
                <div className="section-content">
                    {cs(
                        ["saving", (_, next) => UseState({next})],
                        [
                            "params",
                            (_, next) =>
                                UseState({
                                    initValue: auth.user,
                                    next,
                                }),
                        ],
                        [
                            "form",
                            ({params, saving}, next) =>
                                Form2({
                                    fields: {
                                        firstName: [required],
                                        lastName: [required],
                                    },
                                    data: params,
                                    onSubmit: async ({firstName, lastName}) => {
                                        auth.setAuth({
                                            auth: auth.auth,
                                            user: {
                                                ...auth.user,
                                                lastName,
                                                firstName,
                                            },
                                            tenantId: auth.tenantId,
                                        });
                                        return await apis.user.updateUser({
                                            firstName,
                                            lastName,
                                        });
                                    },
                                    next,
                                }),
                        ],
                        [
                            "updateQueue",
                            ({form}, next) =>
                                Queue1({
                                    fn: (params) => form.submit(params),
                                    next,
                                }),
                        ],
                        ({params, form, updateQueue}) => (
                            <>
                                {Watch({
                                    value: params.value,
                                    onChanged: (params) => updateQueue.push(params),
                                })}

                                <div className="flex-group">
                                    {TextInput({
                                        label: "First Name",
                                        ...ffToBasicInput(form.field("firstName")),
                                    })}

                                    {TextInput({
                                        label: "Last Name",
                                        ...ffToBasicInput(form.field("lastName")),
                                    })}
                                </div>
                            </>
                        )
                    )}
                </div>
            </div>

            <div className="section">
                <div className="section-header">Preferences</div>
                <div className="section-content">
                    {cs(
                        [
                            "preferences",
                            (_, next) =>
                                UseState({
                                    initValue: auth.user.preferences.modelGuidance,
                                    next,
                                }),
                        ],
                        ({preferences}) => {
                            const list = [
                                {label: "Show expanded", value: "ShowExpanded"},
                                {
                                    label: "Show minimized",
                                    value: "ShowMinimized",
                                },
                                {label: "Do not show", value: null},
                            ];

                            return DropdownSelect({
                                label: "Model Guidance",
                                onChange: async (v) => {
                                    preferences.onChange(v);
                                    const success = await apis.user.updateUserPreferences({
                                        modelGuidance: v,
                                    });
                                    if (!success) {
                                        auth.setAuth({
                                            user: {
                                                ...auth.user,
                                                preferences: {
                                                    ...auth.user.preferences,
                                                    modelGuidance: v,
                                                },
                                            },
                                        });
                                    } else {
                                        preferences.onChange(auth.user.preferences.modelGuidance);
                                    }
                                },
                                isSelected: (v) => preferences.value == v,
                                valueToLabel: (v) => list.find((l) => l.value == v).label,
                                list: list.map((l) => l.value),
                            });
                        }
                    )}
                </div>
            </div>
        </VerbScrollbar>
    ));
