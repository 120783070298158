import * as React from "react";
import {cs} from "@common/react/chain-services";
import {DialogService} from "../../../../../common/dialog/dialog-service";
import "./auth-test-dialog.scss";
import {Expandable} from "../../../../../common/expandable/expandable";
import {RequestParameterValues} from "./request-parameter-values";
import {ResponseDetails} from "./response-details";
import {UseState} from "@common/react/use-state";
import {arrMapToO} from "@common/utils/objects";
import {consumeContext} from "@common/react/context";

export const AuthTestDialog = ({next: rootNext}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    // initShow: true,
                    render: ({resolve, args: {authEndpoint, authParams}}) => ({
                        title: "Authentication Test",
                        width: 700,
                        content: next({
                            resolve,
                            authEndpoint,
                            authParams,
                        }),
                    }),
                    next: rootNext,
                }),
        ],
        consumeContext("apis"),
        [
            "parameters",
            ({modal}, next) =>
                UseState({
                    getInitValue: () =>
                        arrMapToO(
                            modal.authParams || [],
                            (_) => null,
                            (_) => _
                        ),
                    next,
                }),
        ],
        [
            "test",
            (_, next) =>
                UseState({
                    initValue: {
                        isTesting: false,
                        response: null,
                    },
                    next,
                }),
        ],
        ({modal, parameters, test, apis}) => (
            <div className="auth-test-dialog-9dn">
                <div className="guide">
                    To test the authentication setup please fill in request parameter values. After running the test you'll be able to verify the mapped values and response
                    content.
                </div>
                <Expandable
                    {...{
                        label: "Request Parameter Values",
                        initExpand: false,
                        passiveExpand: !test.value.isTesting && !test.value.response,
                        render: () =>
                            RequestParameterValues({
                                parameters,
                                onRunTest: async () => {
                                    test.onChange({isTesting: true});
                                    const testRes = await apis.tenant.authTest({
                                        authEndpoint: {
                                            ...modal.authEndpoint,
                                            parameters: modal.authParams,
                                        },
                                        parameters: parameters.value,
                                    });
                                    if (testRes) {
                                        // isTesting need to be still true for expanding this section again
                                        test.onChange({
                                            isTesting: true,
                                            response: testRes,
                                        });
                                    }
                                },
                            }),
                    }}
                />
                <Expandable
                    {...{
                        label: "Response Details",
                        initExpand: false,
                        passiveExpand: test.value.isTesting || test.value.response,
                        render: () =>
                            ResponseDetails({
                                response: test.value.response,
                                onFinish: () => modal.resolve(),
                            }),
                    }}
                />
            </div>
        )
    );
