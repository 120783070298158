const {cs} = require("./chain-services");
const {UseState} = require("./use-state");
const {fragments} = require("./fragments");
const {keyed} = require("./keyed");
const {Invoke} = require("./invoke");
const {scope} = require("./scope");
const {Watch} = require("./watch");

export const CachingRequest = ({fetch, useLatestValueWhenLoading = false, next: rootNext, params}) => {
    const key = JSON.stringify(params);
    return cs(
        ["state", (_, next) => UseState({next, initValue: {latestKey: key}})],
        ({state}, next) =>
            Watch({
                next,
                value: key,
                initRun: true,
                onChanged: (nextProps) => {
                    let v = state.value;
                    if (!v[nextProps]?.initial) {
                        state.change((latest) => ({
                            ...latest,
                            [nextProps]: {initial: true},
                        }));

                        fetch(params).then((data, error) => {
                            if (error) {
                                state.change((latest) => ({
                                    ...latest,
                                    latestKey: nextProps,
                                    [nextProps]: {error, initial: true},
                                }));
                            } else {
                                state.change((latest) => ({
                                    ...latest,
                                    latestKey: nextProps,
                                    [nextProps]: {data, initial: true},
                                }));
                            }
                        });
                    }
                },
            }),
        ({state}) => {
            const {latestKey} = state.value;
            let currentValue = state.value[key];
            const previousValue = state.value[latestKey];

            if (useLatestValueWhenLoading && previousValue?.data && !currentValue?.data) {
                currentValue = previousValue;
            }

            return rootNext({
                value: currentValue?.data,
                error: currentValue?.error,
            });
        }
    );
};
