import FolderIcon from "assets/icons/common/FolderIcon";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {formatFoldersPath, getFoldersPath} from "../../edit/common/helpers";
import "./ds-folder-field.scss";
import {DsFoldersDialog} from "./ds-folders-dialog";

export const DsFolderField = ({state}) =>
    cs(
        consumeContext("dataSourceFolders"),
        ({dataSourceFolders}, next) => (dataSourceFolders.value ? next() : null),
        ["folderDialog", ({dataSourceFolders}, next) => DsFoldersDialog({next, remoteFolders: dataSourceFolders})],
        ({folderDialog, dataSourceFolders}) => {
            return (
                <div className="ds-folder-field-99p">
                    <div className="label">Location</div>
                    <div
                        className="content"
                        onClick={async () => {
                            const resp = await folderDialog.show({
                                folderID: state.value,
                            });
                            if (resp) {
                                state.onChange(resp);
                            }
                        }}
                    >
                        {FolderIcon({})}
                        <div className="text">{formatFoldersPath(getFoldersPath(state.value, dataSourceFolders.value))}</div>
                    </div>
                </div>
            );
        }
    );
