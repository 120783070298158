import * as React from "react";
import "./slider.scss";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {GlobalMouseMove} from "@common/react/global-mouse-move";
import {spc} from "@common/react/state-path-change";
import {getELoc} from "@common/react/get-e-loc";
import {GlobalMouseUp} from "@common/react/global-mouse-up";
import {Static2} from "@common/react/static-2";
import {autoRoundNumber} from "@common/ui-components/charts/common/formatters/numeric/auto-round-number";

export const Slider = ({range, theme, minValue, maxValue}) =>
    cs(["barRef", (_, next) => Static2({next})], ["state", (_, next) => UseState({next})], ({state, barRef}) => {
        if (minValue == null || maxValue == null) {
            return (
                <div className="slider-76f">
                    <div className="blank-bar" />
                </div>
            );
        }

        const baseColor = theme?.general.buttons.primaryButton.backgroundColorRGB || "#11A1FD";

        const toPercent = (v, min, max) => (v != null ? (v - min) / (max - min) : null);

        const leftHandlePos = state.value?.fromPos ?? toPercent(Math.max(range.value?.from || minValue, minValue), minValue, maxValue) ?? 0;
        const rightHandlePos = state.value?.toPos ?? toPercent(Math.min(range.value?.to || maxValue, maxValue), minValue, maxValue) ?? 1;

        const handleChange = (e, isMaxHandle) => {
            const bar = barRef.get().getBoundingClientRect();
            const pos = getELoc(e);
            if (isMaxHandle) {
                spc(state, ["toPos"], () => Math.max(toPercent(Math.min(pos.x, bar.x + bar.width), bar.x, bar.x + bar.width), 0));
            } else {
                spc(state, ["fromPos"], () => Math.min(toPercent(Math.max(pos.x, bar.x), bar.x, bar.x + bar.width), 1));
            }
        };

        const formatNumber = (value) => {
            const {number, tail} = autoRoundNumber(value);
            return `${number}${tail ?? ``}`;
        };

        return (
            <>
                <div className="slider-76f">
                    <div className="bar" ref={barRef.set}>
                        <div
                            className="juice"
                            style={{
                                background: baseColor,
                                width: `${(rightHandlePos - leftHandlePos) * 100}%`,
                                left: `${leftHandlePos * 100}%`,
                            }}
                        />

                        <div
                            className="handle min"
                            style={{
                                left: `${leftHandlePos * 100}%`,
                                borderColor: baseColor,
                                zIndex: leftHandlePos > 0.9 ? 2 : 1,
                            }}
                            onMouseDown={(e) => handleChange(e)}
                        >
                            <div
                                className="value"
                                style={{
                                    color: theme.general.components.inputLabelTextColorRGB,
                                }}
                            >
                                {formatNumber(Math.max(range.value?.from || minValue, minValue))}
                            </div>
                        </div>

                        <div
                            className="handle max"
                            style={{
                                left: `${rightHandlePos * 100}%`,
                                borderColor: baseColor,
                                zIndex: rightHandlePos < 0.1 ? 2 : 1,
                            }}
                            onMouseDown={(e) => handleChange(e, true)}
                        >
                            <div
                                className="value"
                                style={{
                                    color: theme.general.components.inputLabelTextColorRGB,
                                }}
                            >
                                {formatNumber(Math.min(range.value?.to || maxValue, maxValue))}
                            </div>
                        </div>
                    </div>
                </div>

                {state.value && (
                    <>
                        {GlobalMouseMove({
                            fn: (e) => {
                                const {fromPos, toPos} = state.value;
                                if (fromPos != null) {
                                    handleChange(e);
                                } else if (toPos != null) {
                                    handleChange(e, true);
                                }
                            },
                        })}
                        {GlobalMouseUp({
                            fn: (e) => {
                                const {fromPos, toPos} = state.value;
                                const getValue = (percent) => (maxValue - minValue) * percent + minValue;
                                if (fromPos != null) {
                                    const from = getValue(fromPos);
                                    if (range.value?.to == null || from <= range.value.to) {
                                        range.onChange({
                                            from,
                                            to: range.value?.to ?? maxValue,
                                        });
                                    }
                                } else if (toPos != null) {
                                    const to = getValue(toPos);
                                    if (range.value?.from == null || to >= range.value.from) {
                                        range.onChange({
                                            from: range.value?.from ?? minValue,
                                            to,
                                        });
                                    }
                                }
                                state.onChange(null);
                            },
                        })}
                    </>
                )}
            </>
        );
    });
