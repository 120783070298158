import * as React from "react";
import {cs} from "@common/react/chain-services";
import {LeftPanelTabs} from "../../../../common/left-panel-tabs/left-panel-tabs";
import {cx} from "emotion";
import "./left-panel.scss";
import {SuspendUpdate} from "@common/react/suspend-update";

export const LeftPanel = (tabs) =>
    cs(({}) => {
        if (!tabs) {
            return null;
        }

        const override = tabs.getOverride?.() || null;

        return (
            <div className="left-panel-4wi">
                <div className={cx("tabs", {active: !override})}>
                    <LeftPanelTabs
                        {...{
                            tabs: tabs.tabs,
                            selected: tabs.selectedTab,
                        }}
                    />
                </div>

                <div className={cx("override", {active: override})}>
                    {cs(
                        ({}, next) => SuspendUpdate({active: !override, next}),
                        () => override
                    )}
                </div>
            </div>
        );
    });
