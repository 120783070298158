const createIndex = (attr) => (col) => {
    let ret = {};
    for (const e of col) {
        let sAtt = "";
        if (Array.isArray(attr)) for (const a of attr) sAtt += (sAtt.length > 0 ? "|" : "") + e[a];
        else sAtt = e[attr];
        ret[sAtt] = e;
    }
    return ret;
};
exports.createIndex = createIndex;

const cIndexedGetter = (col, attr) => {
    if (col == null) {
        return null;
    }
    const index = createIndex(attr)(col);
    return (id) => {
        if (Array.isArray(id)) {
            let sID = "";
            for (const a of id) sID += (sID.length > 0 ? "|" : "") + a;
            return index[sID];
        } else return index[id];
    };
};
exports.cIndexedGetter = cIndexedGetter;

// const createIndexMulti = (attr) => (col) => {
//     let ret = {};
//     for (const e of col) {
//         let list = ret[e[attr]];
//         if (list == null) {
//             list = [];
//             ret[e[attr]] = list;
//         }
//         list.push(e);
//     }
//
//     return ret;
// };
// exports.createIndexMulti = createIndexMulti;

const createIndexMulti = (col, getKeyList) => {
    let ret = {};
    for (const e of col) {
        let keys = getKeyList(e);
        for (const key of keys) {
            let list = ret[key];
            if (list == null) {
                list = [];
                ret[key] = list;
            }
            list.push(e);
        }
    }
    return ret;
};
exports.createIndexMulti = createIndexMulti;
