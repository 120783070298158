import {cs} from "@common/react/chain-services";
import {fragments} from "@common/react/fragments";
import {Interval} from "@common/react/interval";
import {Invoke} from "@common/react/invoke";
import {Static} from "@common/react/static";
import {UseState} from "@common/react/use-state";
import {equalDeep} from "../../../../../../../common/utils/objects";
import {Load2} from "@common/react/load2";

export const CacheSync = ({_key, fetch, next: rootNext, delay = 15 * 60 * 1000}) =>
    cs(
        [
            "cache",
            (_, next) =>
                Static({
                    getInitValue: () => [], // {params, promise, createdAt}
                    next,
                }),
        ],
        ({cache}, next) =>
            Interval({
                fn: () => {
                    for (let i = cache.length - 1; i > -1; i--) {
                        const r = cache[i];
                        if (r.createdAt < Date.now() - delay) {
                            cache.splice(i, 1);
                        }
                    }
                },
                delay: 60 * 1000,
                next,
            }),
        ({cache}, next) => {
            const _cache = cache.find((r) => equalDeep(r._key, _key));
            return _cache ? rootNext(_cache.data) : next();
        },
        ["data", (_, next) => Load2({_key, fetch, next})],
        ({data, cache}, next) => {
            if (data.value) {
                cache.push({
                    _key,
                    data: data.value,
                    createdAt: Date.now(),
                });
            }
            return next();
        },
        ({data}) => rootNext(data.value)
    );
