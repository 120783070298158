const {FComponent} = require("./f-component");
const {createElement: h} = require("react");

const Invoke = ({fn, action, props, onMounted, next, test}) => h(Invoke1, {fn: action ?? fn, props, onMounted, next, test});
exports.Invoke = Invoke;

class Invoke1 extends FComponent {
    constructor(props, context) {
        super(props, context);

        props.fn &&
            props.fn({
                getLatestProps: () => this.props.props,
                isMounted: () => this.mounted,
            });

        if (props.onMounted) {
            this.onMount(() => {
                setTimeout(() => {
                    this.props.onMounted({
                        getLatestProps: () => this.props.props,
                        isMounted: () => this.mounted,
                    });
                }, 0);
            });
        }
    }

    render() {
        const {next, test = false} = this.props;

        if (test) {
            console.log("render invoke");
        }
        return next ? next() : null;
    }
}
