import {cs} from "../../../../react/chain-services";
import {consumeContext} from "../../../../react/context";
import {css, cx} from "emotion";
import {StyledClass} from "@common/react/styled-class";

export const PivotTableLine = ({isFirstColumn, isFirstRow, isLatestRow, isLatestColumn, isFixedColumn, isFixedRow}) =>
    cs(
        consumeContext("theme"),
        [
            "verticalLineAfterClass",
            ({theme}, next) => {
                const {borderWidth, borderColorRGB} = theme.dataVisualization.tables;
                return StyledClass({
                    content: {
                        "&::after": {
                            width: `${borderWidth}px`,
                            background: `${borderColorRGB}`,
                            right: "0px",
                        },
                    },
                    next,
                });
            },
        ],
        [
            "verticalLineBeforeClass",
            ({theme}, next) => {
                const {borderWidth, borderColorRGB} = theme.dataVisualization.tables;
                return StyledClass({
                    content: {
                        "&::before": {
                            width: `${borderWidth}px`,
                            background: `${borderColorRGB}`,
                            left: "0px",
                        },
                    },
                    next,
                });
            },
        ],
        [
            "horizontalLineAfterClass",
            ({theme}, next) => {
                const {borderWidth, borderColorRGB} = theme.dataVisualization.tables;
                return StyledClass({
                    content: {
                        "&::after": {
                            height: `${borderWidth}px`,
                            background: `${borderColorRGB}`,
                            bottom: `0px`,
                        },
                    },
                    next,
                });
            },
        ],
        [
            "horizontalLineBeforeClass",
            ({theme}, next) => {
                const {borderWidth, borderColorRGB} = theme.dataVisualization.tables;
                return StyledClass({
                    content: {
                        "&::before": {
                            height: `${borderWidth}px`,
                            background: `${borderColorRGB}`,
                            top: `0px`,
                        },
                    },
                    next,
                });
            },
        ],
        ({theme, verticalLineAfterClass, verticalLineBeforeClass, horizontalLineAfterClass, horizontalLineBeforeClass}) => {
            const {borderWidth, borderColorRGB, borderVerticalLines, borderHorizontalLines, borderOutsideLines} =
                theme.dataVisualization.tables;

            return (
                <>
                    <div
                        className={cx(
                            "vertical-line",
                            borderVerticalLines || (borderOutsideLines && isLatestColumn) ? verticalLineAfterClass : {},
                            ((isFirstColumn && borderOutsideLines) || (isFixedColumn && borderVerticalLines)) && verticalLineBeforeClass
                        )}
                    />

                    <div
                        className={cx(
                            "horizontal-line",
                            borderHorizontalLines || (borderOutsideLines && isLatestRow) ? horizontalLineAfterClass : {},
                            ((isFirstRow && borderOutsideLines) || isFixedRow) && horizontalLineBeforeClass
                        )}
                    />
                </>
            );
        }
    );
