import {Expandable} from "../../../../common/expandable/expandable";
import {FontFamilyDropdown} from "./font-family-dropdown/font-family-dropdown";
import {ffToDropdown} from "../../../../../../../common/form/ff-to-dropdown";
import {FontSizeSelect} from "../../../tile/edit/left-panel/tabs/tile-tab/chart-types/common/font-size-select";
import {LpColorLine} from "../../../../common/left-panel/lp-color-line";
import {ffToColorPickerLine} from "../../../../../../../common/form/ff-to-color-picker-line";
import * as React from "react";

export const TextStyles = ({form, sectionInitClosed}) => (
    <Expandable
        {...{
            initExpand: !sectionInitClosed,
            label: "Text Styles",
            render: () => (
                <div className="panel-22">
                    {FontFamilyDropdown({
                        label: "Collection Font",
                        ...ffToDropdown(form.field(["font"])),
                    })}

                    {(() => {
                        const ffProps = ffToDropdown(form.field(["fontSizeGroup"]));
                        return FontSizeSelect({
                            label: "Font Size Group",
                            ...ffProps,
                            // this is the root theme font size group so do not show theme default option here, default to Medium instead
                            value: ffProps.value || "Medium",
                            noThemeDefaultOption: true,
                        });
                    })()}

                    {LpColorLine({
                        label: "Font Color",
                        ...ffToColorPickerLine(form.field(["fontColorRGB"])),
                    })}
                </div>
            ),
        }}
    />
);
