import "./table-kpi.scss";

import {cx} from "emotion";
import * as React from "react";

import {cs} from "../../../react/chain-services";
import {rSummary} from "./render-summary";
import {rTable} from "./render-table";
import {loadTileData} from "../common/load-tile-data";
import {getValueFormatter} from "./get-value-formatter";
import {keepOnly} from "../../../utils/objects";
import {VerbScrollbar} from "../../verb-scrollbar/verb-scrollbar";
import {ActionMenu} from "../common/action-menu/action-menu";
import {UseState} from "../../../react/use-state";
import {consumeContext} from "../../../react/context";
import {IgnoreUpdate} from "../../../react/ignore-update";
import {loadTileFields} from "../get-field-color";
import {RemoveTileControlFilter} from "../common/control-filter/remove-tile-control-filter";
import {CurrencyConversionWarning, getCurrencyConversion} from "../common/currency-conversion-warning/currency-conversion-warning";

export const TableKPI = ({tile, tileFilters, loadData, theme, size, defaultData, hideComparison, overrideTile, disabledTileActions}) =>
    cs(
        consumeContext("tileActionControlFilter"),
        [
            "data",
            ({tileActionControlFilter}, next) =>
                defaultData
                    ? next(defaultData)
                    : loadTileData({
                          tileKey: JSON.stringify([keepOnly(tile.style, ["summaryAggregationFunc"]), keepOnly(tile, ["valueField", "groupField", "filters", "sort", "limit"])]),
                          next,
                          tileFilters,
                          loadData,
                          size,
                          tile,
                          theme,
                          disabled: tileActionControlFilter?.value?.tile?.id == tile.id,
                      }),
        ],
        consumeContext("selectedTimezone"),
        ({selectedTimezone}, next) =>
            IgnoreUpdate({
                next,
                props: {timezoneId: selectedTimezone?.value},
                when: ({timezoneId}) => timezoneId != selectedTimezone?.value,
            }),
        [
            "hideComparison",
            (_, next) => {
                if (hideComparison) return next(false);
                const filtersValue = tileFilters.getValue();
                const dateFilters = Object.keys(filtersValue).filter((key) => filtersValue[key].$type == "DateFilterValue");
                return next(dateFilters.length > 0 && dateFilters.length == dateFilters.filter((key) => !filtersValue[key].compareRange).length);
            },
        ],
        [
            "formatter",
            ({selectedTimezone}, next) => {
                return next(
                    getValueFormatter({
                        valueField: tile.valueField,
                        groupField: tile.groupField,
                        style: tile.style,
                        timezone: selectedTimezone?.value,
                        tile,
                    })
                );
            },
        ],

        [
            "tileFields",
            ({}, next) =>
                loadTileFields({
                    next,
                    configs: {
                        tile,
                        measureSingleAttrs: ["valueField"],
                        groupFieldAttr: "groupField",
                        checkUnique: false,
                    },
                }),
        ],

        [
            "actionMenu",
            ({formatters}, next) =>
                ActionMenu({
                    tile,
                    overrideTile,
                    // dimensionFormatter: formatters.dimensionFormatter,
                    disabledTileActions,
                    next,
                }),
        ],

        [
            "onClickPoint",
            ({actionMenu, tileFields, data}, next) => {
                if (!actionMenu.hasActions()) {
                    return next();
                }
                return next((other) => {
                    let fieldToValue = {};
                    const valueField = tile.valueField;
                    if (valueField) {
                        fieldToValue[valueField.id] = other.row.value;
                    }
                    const groupField = tile.groupField;
                    if (groupField) {
                        fieldToValue[groupField.id] = other.row.name;
                    }

                    const tileActionData = other.row?.tileActionData;

                    tileActionData?.columns.forEach((c, index) => {
                        fieldToValue[c.tileFieldID] = tileActionData.data[index];
                    });

                    actionMenu?.show?.({
                        ...other,
                        fieldToValue,
                    });
                });
            },
        ],

        [
            "table",
            ({data, formatter, hideComparison, onClickPoint, tileFields}, next) =>
                rTable({
                    currencyConversion: getCurrencyConversion({
                        $type: data.$type,
                        data,
                        tileFields,
                    }),
                    style: tile.style,
                    theme,
                    rows: data.rows,
                    formatter,
                    hideComparison,
                    onClickPoint,
                    next,
                    tileID: tile.id,
                }),
        ],
        [
            "summary",
            ({data, formatter, hideComparison, onClickPoint}, next) =>
                rSummary({
                    style: tile.style,
                    theme,
                    row: data.summaryRow,
                    formatter,
                    size,
                    next,
                    hideComparison,
                    onClickPoint,
                }),
        ],
        ["maxSize", (_, next) => UseState({next})],
        [
            "controls",
            ({data, tileFields}, next) => {
                return next(
                    <div className="table-kpi-controls-a33">
                        {CurrencyConversionWarning({
                            theme,
                            currencyConversion: getCurrencyConversion({
                                $type: data.$type,
                                data,
                                tileFields,
                            }),
                        })}

                        {RemoveTileControlFilter({tile, hideBorder: true})}
                    </div>
                );
            },
        ],
        ({table, summary, maxSize, controls}) => {
            const hasTitle = typeof tile.style?.title?.show != "undefined" ? tile.style?.title.show : false;

            return (
                <>
                    <VerbScrollbar
                        className={cx("table-kpi-3gz", {"has-title": hasTitle})}
                        style={{
                            fontFamily: `"${theme.dataVisualization.fonts.fontFamily || theme.general.canvas.fontFamily}", sans-serif`,
                        }}
                        getRef={(ref) => {
                            if (ref && !maxSize.value) {
                                const {width} = ref.getBoundingClientRect();
                                maxSize.onChange(((width + 40) / size.width) * 7);
                            }
                        }}
                    >
                        <div
                            className={cx("main-area", {
                                horizontal: tile.style.template === "Horizontal",
                            })}
                            style={{
                                maxWidth: maxSize.value ?? "unset",
                                margin: "0 auto",
                            }}
                        >
                            {summary}
                            {table}
                        </div>
                    </VerbScrollbar>

                    {controls}
                </>
            );
        }
    );
