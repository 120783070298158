import * as React from "react";
import {cs} from "@common/react/chain-services";
import {LeftColumnPicker} from "./left-column-picker";
import {RightColumnPicker} from "./right-column-picker";
import "./relationship-picker.scss";
import {RelationshipLine} from "./relationship-line";
import {scope} from "@common/react/scope";
import {WatchColumnInteraction} from "./watch-column-interaction";
import {spc} from "@common/react/state-path-change";

export const RelationshipPicker = ({table, modelTables, dataSources, state, lineEnds}) =>
    cs(
        [
            "watchLeftCol",
            ({}, next) =>
                WatchColumnInteraction({
                    action: ({x, y, width, height}) => {
                        spc(lineEnds, ["leftEnd"], () => ({
                            x: x + width,
                            y: y + height / 2,
                        }));
                    },
                    next,
                }),
        ],
        [
            "watchRightCol",
            ({}, next) =>
                WatchColumnInteraction({
                    action: ({x, y, width, height}) => {
                        spc(lineEnds, ["rightEnd"], () => ({
                            x: x,
                            y: y + height / 2,
                        }));
                    },
                    next,
                }),
        ],
        ({watchLeftCol, watchRightCol}) => {
            return (
                <div className="relationship-picker-9fs relationship-picker">
                    <div className="relationship-line">
                        {RelationshipLine({
                            leftEnd: lineEnds.value?.leftEnd,
                            rightEnd: lineEnds.value?.rightEnd,
                        })}
                    </div>
                    <div className="parallel-cols">
                        <div className="left-col">
                            {LeftColumnPicker({
                                table,
                                ...(table.$type === "ViewModelTable"
                                    ? {
                                          dvIndex: dataSources.length,
                                      }
                                    : {
                                          dsIndex: dataSources.findIndex((ds) => ds.id === table.dataSourceID),
                                      }),
                                state: scope(state, ["leftColumnId"]),
                                watchCol: watchLeftCol,
                            })}
                        </div>
                        <div className="right-col">
                            {RightColumnPicker({
                                modelTables,
                                dataSources,
                                state: scope(state, ["rightColumnId"]),
                                watchCol: watchRightCol,
                            })}
                        </div>
                    </div>
                </div>
            );
        }
    );
