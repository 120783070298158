import {cs} from "@common/react/chain-services";
import {unique} from "@common/utils/collections";
import {ColumnValueDrillingSelect} from "./column-value-drilling-select";

export const rColumnValueDrillingSelectFromStepOutput =
    ({
        columns,
        model,
        filterColumnFn,
        useColumn,
        disableSelectingTable,
        isValueNotAllowed,
        usePreviousResult,
        useStatic,
        label = "Column/Value",
    }) =>
    ({state, ...props}) => {
        const inputModelTables = (() => {
            const modelStepColumns = columns.filter((c) => c.$type === "ModelStepColumn");
            const tableIds = unique(modelStepColumns.map((c) => c.modelTableID));
            const allColIds = columns.map((c) => c.modelColumnID);

            return tableIds.map((tid) => {
                const table = model.tables.find((t) => t.id === tid);
                return {
                    ...table,
                    columns: table.columns.filter((c) => allColIds.includes(c.id)),
                };
            });
        })();

        // this one is used in calculator step
        const formatParsersForChoosingValue = {
            formatValue: (value) => {
                if (!value) {
                    return null;
                }

                if (["StaticValue", "PreviousResult"].includes(value.$type)) {
                    return value;
                }

                if (value.$type === "ViewColumnValue") {
                    return {...value, $type: "ViewStepColumn"};
                }

                if (["ModelColumnValue", "AggregateValue"].includes(value.$type)) {
                    return {
                        tableId: value.modelTableID,
                        columnId:
                            value.modelColumnID &&
                            model.tables.find?.((t) => t.id === value.modelTableID)?.columns?.find?.((c) => c.id === value.modelColumnID)
                                ?.id,
                    };
                }

                if (value.$type === "ModelTableValue") {
                    return {
                        tableId: value.modelTableID,
                    };
                }
            },
            parseValue: ({table, column, isDrilling}) => {
                if (column?.$type === "ViewStepColumn") {
                    return {
                        $type: "ViewColumnValue",
                        name: column.name,
                    };
                }

                if (["DataSourceModelColumn", "ViewModelColumn"].includes(column?.$type)) {
                    return {
                        $type: isDrilling ? "AggregateValue" : "ModelColumnValue",
                        modelID: model.id,
                        modelTableID: table.id,
                        modelColumnID: column.id,
                    };
                }

                if (table) {
                    return {
                        $type: "ModelTableValue",
                        modelID: model.id,
                        modelTableID: table.id,
                    };
                }

                return null;
            },
        };

        const formatParserForChoosingColumn = {
            formatValue: (value) => {
                if (!value) {
                    return null;
                }

                if (["ViewStepColumn", "GroupAggColumn", "StaticValue"].includes(value.$type)) {
                    return value;
                }

                if (value.$type === "ModelStepColumn") {
                    return {
                        tableId: value.modelTableID,
                        columnId:
                            value.modelColumnID &&
                            model.tables.find?.((t) => t.id === value.modelTableID)?.columns?.find?.((c) => c.id === value.modelColumnID)
                                ?.id,
                    };
                }

                if (value.$type === "ModelTableValue") {
                    return {
                        tableId: value.modelTableID,
                    };
                }
            },
            parseValue: ({table, column}) => {
                if (["ViewStepColumn", "GroupAggColumn"].includes(column?.$type)) {
                    return column;
                }

                if (["DataSourceModelColumn", "ViewModelColumn"].includes(column?.$type)) {
                    return {
                        $type: "ModelStepColumn",
                        modelID: model.id,
                        modelTableID: table.id,
                        modelColumnID: column.id,
                    };
                }

                if (table) {
                    return {
                        $type: "ModelTableValue",
                        modelID: model.id,
                        modelTableID: table.id,
                    };
                }

                return null;
            },
        };

        return ColumnValueDrillingSelect({
            label,
            value: state.value,
            onChange: (value) => state.onChange(value),
            model,
            inputModelTables,
            nonModelStepColumns: columns.filter((c) => c.$type !== "ModelStepColumn"),
            useStatic,
            usePreviousResult,
            disableSelectingTable,
            isValueNotAllowed,
            filterColumnFn,
            ...(useColumn ? formatParserForChoosingColumn : formatParsersForChoosingValue),
            ...props,
        });
    };
