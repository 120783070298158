import {cs} from "@common/react/chain-services";
import {ModelDataTab} from "./data/model-data-tab";
import {ModelTab} from "./model/model-tab";
import {ModelPanel} from "../main/model-panel";
import {LeftPanelOverrides} from "./left-panel-overrides/left-panel-overrides";
import {Interactions} from "./interactions";
import {provideContext} from "@common/react/context";
import {fragments} from "@common/react/fragments";
import {ToastWithAction2} from "../../../common/toast/ToastWithAction2";
import {DataSourceModelContext} from "./data/data-menu/data-source-model-context";

export const ModelTabs = ({model, dataSources, next, updatePositionQueue, environment, savingQueue, autoSuggest}) =>
    cs(
        ({}, next) => DataSourceModelContext({dataSources, model: model.value, next}),
        ["interactions", (_, next) => Interactions({model: model.value, dataSources, next, updatePositionQueue})],
        [
            "actionToast",
            ({interactions}, next) =>
                ToastWithAction2({
                    next,
                    onShow: (config, fn, data) => {
                        const setting = interactions.getStaticSetting();

                        if (setting?.data?.tableId !== data?.tableId) {
                            fn(config);
                        }
                    },
                }),
        ],
        ({actionToast}, next) => provideContext("actionToast", actionToast, next),
        ({actionToast}, next) => fragments(actionToast.render(), next()),
        ({interactions}, next) => provideContext("interactions", interactions, next),
        [
            "chart",
            ({interactions}, next) =>
                ModelPanel({
                    environment,
                    model,
                    interactions,
                    dataSources,
                    next,
                    savingQueue,
                    autoSuggest,
                }),
        ],
        ["modelTab", ({chart}, next) => ModelTab({model, chart, autoSuggest, next})],
        [
            "modelDataTab",
            ({chart, interactions}, next) =>
                ModelDataTab({
                    chart,
                    model,
                    dataSources,
                    interactions,
                    autoSuggest,
                    next,
                }),
        ],
        [
            "leftPanelOverride",
            ({interactions}, next) =>
                LeftPanelOverrides({
                    model,
                    dataSources,
                    interactions,
                    next,
                    savingQueue,
                }),
        ],

        ({leftPanelOverride, interactions, chart, modelDataTab}) => {
            return next({
                leftPanelAction: modelDataTab.render(),
                leftPanelOverride: leftPanelOverride?.render(),
                leftPanelWidth: interactions.leftPanelWidth,
                main: chart.render(),
            });
        }
    );
