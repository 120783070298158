import "./tiles.scss";

import {cx} from "emotion";
import * as React from "react";

import {cs} from "@common/react/chain-services";
import {keyed} from "@common/react/keyed";
import {UseState} from "@common/react/use-state";
import {provideContext} from "@common/react/context";
import {IgnoreUpdate} from "@common/react/ignore-update";
import {ObserveDomSize} from "@common/react/observe-dom-size";

import {isDisabledChartSetting} from "@common/ui-components/charts/chart-restyling/chart-restyling";

import {MoveIconSvg} from "../../../../common/icons/move-icon-svg";
import {StaticTooltipService} from "../../../../common/tooltip3/static-tooltip-service";
import {DummyReportTileID} from "../dnd-grid-panel";
import {gridHeight, numColumnsOfGrid} from "../grid-constants";
import {TileControlBox} from "./controls/tile-control-box";
import {ChooseFilterToMoveBox} from "./choose-filter/choose-filter-to-move-box";

export const Tiles = ({tiles, blockWidth, spacing, dnd: dndRoot, theme, onClone, collection}) =>
    cs(({}) => (
        <div
            className={cx("tiles tiles-0r4", {dndActive: dndRoot.activeRect})}
            style={{
                fontFamily: `"${theme.general.canvas.fontFamily || "Roboto"}", sans-serif`,
            }}
            ref={dndRoot.rootDomRef}
        >
            {tiles?.map((tile, i) =>
                cs(
                    keyed(tile.key),
                    ["dnd", (_, next) => next(dndRoot.forTile({tileIndex: i, tile}))],
                    ["confirmDelete", (_, next) => UseState({next})],
                    ["size", ({}, next) => ObserveDomSize({next})],
                    ({size}, next) => provideContext("innerBoxSize", size, next),
                    [
                        "tileControls",
                        ({confirmDelete}, next) =>
                            TileControlBox({
                                next,
                                collection,
                                tile,
                                onDelete: () => confirmDelete.onChange(true),
                                onClone: () => onClone(tile),
                            }),
                    ],
                    ({dnd, confirmDelete, size, tileControls}) => {
                        const tileWidth = dnd.resizing ? Math.round(dnd.resizing.size.width / blockWidth) : tile.size.width;
                        const tileHeight = dnd.resizing
                            ? Math.round(dnd.resizing.size.height / (gridHeight + spacing * 2))
                            : tile.size.height;

                        const isControlsInline =
                            tile.size.width === numColumnsOfGrid ||
                            (tile.size.width + tile.position.x === numColumnsOfGrid &&
                                tile.position.x < numColumnsOfGrid / 2 &&
                                tile.position.y !== 0);
                        const isControlsLeft =
                            tile.position.x >= numColumnsOfGrid / 2 ||
                            (tile.size.width + tile.position.x === numColumnsOfGrid && tile.position.y === 0);

                        return cs(
                            (_, next) =>
                                provideContext("tileWidthPx", dnd.resizing ? dnd.resizing.size.width : tile.size.width * blockWidth, next),
                            ({}) => (
                                <div
                                    className={cx("tile", {
                                        resizing: dnd.resizing,
                                        moving: dnd.moving,
                                        dndWarning: dndRoot.rectRestricted,
                                        dndError: dnd.moving?.rejecting || dnd.resizing?.rejecting,
                                        notAllowActions: !tile.key,
                                        showHoverRefresh: tile.showHoverRefresh,
                                    })}
                                    data-title={tile.title}
                                    ref={dnd.ref}
                                    style={{
                                        padding: spacing,

                                        ...(dnd.moving
                                            ? {
                                                  top: dnd.moving.position.y,
                                                  left: dnd.moving.position.x,
                                              }
                                            : {
                                                  top: tile.position.y * (gridHeight + spacing * 2),
                                                  left: tile.position.x * blockWidth,
                                              }),

                                        ...(dnd.resizing
                                            ? {
                                                  width: dnd.resizing.size.width,
                                                  height: dnd.resizing.size.height,
                                                  top: dnd.resizing.size.y,
                                                  left: dnd.resizing.size.x,
                                              }
                                            : {
                                                  width: tile.size.width * blockWidth,
                                                  height: tile.size.height * (gridHeight + spacing * 2),
                                              }),
                                    }}
                                >
                                    <div
                                        className="inner-box"
                                        style={{
                                            borderRadius: theme.general.tile.styles.tileCornerRadius ?? 3,
                                        }}
                                        ref={size.ref}
                                    >
                                        <div
                                            className="inner-box-border"
                                            style={{
                                                borderRadius: theme.general.tile.styles.tileCornerRadius ?? 3,
                                            }}
                                        />

                                        <div
                                            className="content"
                                            style={{
                                                background: tile.key === "a" ? "white" : "transparent",
                                            }}
                                        >
                                            {cs(
                                                ({}, next) =>
                                                    IgnoreUpdate({
                                                        when: () => dndRoot.activeRect,
                                                        next,
                                                    }),
                                                () => tile.render({})
                                            )}
                                        </div>

                                        {confirmDelete.value &&
                                            cs(
                                                [
                                                    "isDeleting",
                                                    (_, next) =>
                                                        UseState({
                                                            next,
                                                            initValue: false,
                                                        }),
                                                ],
                                                ({isDeleting}) => (
                                                    <div
                                                        className="confirm-delete-overlay"
                                                        style={{
                                                            borderRadius: theme.general.tile.styles.tileCornerRadius ?? 3,
                                                        }}
                                                    >
                                                        <div className="remove-wrapper">
                                                            Delete Chart?
                                                            <div className="buttons">
                                                                <button
                                                                    className="btn btn-cancel"
                                                                    disabled={isDeleting.value}
                                                                    onClick={() => confirmDelete.onChange(false)}
                                                                >
                                                                    Cancel
                                                                </button>

                                                                <button
                                                                    className="btn btn-remove"
                                                                    disabled={isDeleting.value}
                                                                    onClick={async () => {
                                                                        isDeleting.onChange(true);
                                                                        await tile.onDelete();
                                                                        isDeleting.onChange(true);
                                                                        confirmDelete.onChange(false);
                                                                    }}
                                                                >
                                                                    {isDeleting.value ? "Deleting" : "Delete"}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            )}

                                        {tile.key != DummyReportTileID ? (
                                            <>
                                                <div className="controls">
                                                    {tile.onChangePosition && (
                                                        <>
                                                            <div className={"control drag-wrapper"} onMouseDown={dnd.startMoving}>
                                                                <div className={cx("drag-container", tile.isShared && "shared")}>
                                                                    {tile.adminTileName && (
                                                                        <span className="admin-tile-name">
                                                                            &#123;{tile.adminTileName}&#125;
                                                                        </span>
                                                                    )}

                                                                    <div className="drag">
                                                                        <MoveIconSvg />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="control edit-btn-container" onClick={() => tile.onEdit()}>
                                                                <div className="btn-area">
                                                                    <img src={require("./icons/icon-menu-edit.svg")} alt="" />
                                                                </div>
                                                            </div>

                                                            <div
                                                                className="control more-btn-container"
                                                                ref={tileControls.toggleRef.set}
                                                                onClick={() =>
                                                                    tileControls.showControl({
                                                                        side: "right",
                                                                    })
                                                                }
                                                            >
                                                                <div className="btn-area">
                                                                    <img src={require("./icons/icon-menu-more.svg")} alt="" />
                                                                </div>

                                                                {tileControls.render()}
                                                            </div>
                                                        </>
                                                    )}
                                                </div>

                                                {tile.onChangeSize &&
                                                    resizeBtn(theme.general.tile.styles.tileCornerRadius ?? 3).map((btn, index) => (
                                                        <div
                                                            className="resize-btn"
                                                            onMouseDown={(e) => dnd.startResizing(e, btn.position)}
                                                            key={index}
                                                            style={{
                                                                ...btn.style,
                                                            }}
                                                        />
                                                    ))}
                                            </>
                                        ) : (
                                            <ChooseFilterToMoveBox collection={collection} tile={tile} />
                                        )}

                                        {cs(
                                            [
                                                "staticTooltip",
                                                (_, next) =>
                                                    StaticTooltipService({
                                                        direction: "top",
                                                        info: "Some chart settings will be disabled or changed to auto at this size.",
                                                        tooltipBoxWidth: 150,
                                                        next,
                                                    }),
                                            ],
                                            ({staticTooltip}) => {
                                                const hasWarning = isDisabledChartSetting({
                                                    tile,
                                                    size: {
                                                        width: tileWidth,
                                                        height: tileHeight,
                                                    },
                                                });

                                                return (
                                                    <div className="dimension-tile-container">
                                                        <div
                                                            className={cx("dimension-tile", {
                                                                "has-warning": hasWarning,
                                                            })}
                                                        >
                                                            {hasWarning &&
                                                                staticTooltip.renderIcon({
                                                                    icon: <i className="material-icons">info</i>,
                                                                    className: "tooltip-icon",
                                                                })}
                                                            {tileWidth} x {tileHeight}
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        )}
                                    </div>
                                </div>
                            )
                        );
                    }
                )
            )}
        </div>
    ));

const resizeBtn = (borderRadius) => [
    {
        position: {left: true},
        style: {
            height: "100%",
            left: 0,
            top: 0,
            width: "5px",
            cursor: "ew-resize",
        },
    },
    {
        position: {top: true},
        style: {
            width: "100%",
            left: 0,
            top: 0,
            height: "5px",
            cursor: "ns-resize",
        },
    },
    {
        position: {right: true},
        style: {
            height: "100%",
            right: 0,
            top: 0,
            width: "5px",
            cursor: "ew-resize",
        },
    },
    {
        position: {bottom: true},
        style: {
            height: "5px",
            bottom: 0,
            left: 0,
            width: "100%",
            cursor: "ns-resize",
        },
    },
    {
        position: {left: true, top: true},
        style: {
            height: `${borderRadius + 3}px`,
            left: 0,
            top: 0,
            width: `${borderRadius + 3}px`,
            cursor: "nwse-resize",
        },
    },
    {
        position: {left: true, bottom: true},
        style: {
            height: `${borderRadius + 3}px`,
            left: 0,
            bottom: 0,
            width: `${borderRadius + 3}px`,
            cursor: "nesw-resize",
        },
    },
    {
        position: {top: true, right: true},
        style: {
            height: `${borderRadius + 3}px`,
            top: 0,
            right: 0,
            width: `${borderRadius + 3}px`,
            cursor: "nesw-resize",
        },
    },
    {
        position: {bottom: true, right: true},
        style: {
            height: `${borderRadius + 3}px`,
            bottom: 0,
            right: 0,
            width: `${borderRadius + 3}px`,
            cursor: "nwse-resize",
        },
    },
];
