import * as React from "react";
import {cs} from "@common/react/chain-services";
import "./response-details.scss";
import {UseState} from "@common/react/use-state";
import {cx} from "emotion";
import {DataTable} from "../../../../../common/data-table/data-table";
import {AlertCard} from "../../../../../common/alert-card/alert-card";
import {Button} from "../../../../../../../../common/form/buttons/button/button";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";

export const ResponseDetails = ({response, onFinish}) =>
    cs(
        ["selected", (_, next) => UseState({initValue: "mapped-response", next})],
        [
            "tabs",
            (_, next) =>
                next([
                    {
                        name: "mapped-response",
                        render: MappedResponse,
                        label: "Mapped Response",
                    },
                    {
                        name: "response-body",
                        render: ResponseBody,
                        label: "Response Body",
                    },
                ]),
        ],
        ({selected, tabs}) => (
            <div className="response-details-nm5">
                {!response ? (
                    <div className="loading-indicator">
                        <i className="loading fa fa-spinner fa-pulse" />
                    </div>
                ) : (
                    <>
                        <div className="response-tabs">
                            <div className="header">
                                {tabs.map((tab, i) => (
                                    <div
                                        className={cx("tab", {
                                            selected: selected.value === tab.name,
                                        })}
                                        key={i}
                                        onClick={() => selected.value !== tab.name && selected.onChange(tab.name)}
                                    >
                                        <div className="label">{tab.label}</div>
                                    </div>
                                ))}
                                <div className="status">
                                    Status
                                    <span
                                        className={cx("code", {
                                            good: response.responseCodeNum < 400,
                                        })}
                                    >
                                        {response.responseCodeNum} {response.responseCode}
                                    </span>
                                </div>
                            </div>
                            <div className="content">{tabs.find((tab) => tab.name === selected.value).render({response})}</div>
                        </div>
                        <div className="finish-test-btn">
                            <Button btnType="secondary" onClick={onFinish}>
                                Finish
                            </Button>
                        </div>
                    </>
                )}
            </div>
        )
    );

const MappedResponse = ({response}) => (
    <div className="mapped-response-6sk">
        {response.responseCodeNum >= 400
            ? AlertCard({
                  message: `Verb received a ${response.responseCodeNum} error and cannot map responses.
                    Please review the authentication setup and test again.`,
              })
            : DataTable({
                  className: "user-values-table",
                  list: response.userValues ? Object.entries(response.userValues) : [],
                  columns: [
                      {
                          label: "User Mapping",
                          format: (e) => e[0],
                      },
                      {
                          label: "Values",
                          format: (e) => {
                              if (!e[1]) {
                                  return <i>null</i>;
                              }
                              if (e[1].$type === "UserStringValue") {
                                  return e[1].value == null ? <i>null</i> : e[1].value;
                              }
                              if (e[1].$type === "UserArrayValue") {
                                  return e[1].values == null ? (
                                      <i>null</i>
                                  ) : e[1].values.length > 0 ? (
                                      e[1].values.map((v) => <div className="">{v?.value == null ? <i>null</i> : v.value}</div>)
                                  ) : (
                                      <i>empty array</i>
                                  );
                              }
                          },
                      },
                  ],
              })}
    </div>
);

const ResponseBody = ({response}) => {
    // const content = JSON.stringify(response.rawResponse, null, 2)?.split("\n");
    const content = response.rawResponse?.split("\n");
    return (
        content && (
            <div className="response-body-9wt">
                <pre className="pre-area">
                    {content?.map((l, i) => (
                        <div className="line" key={i}>
                            <div
                                className={cx("number", {
                                    wide: content?.length > 98,
                                })}
                            >
                                {i + 1}
                            </div>
                            <div className="string">{l}</div>
                        </div>
                    ))}
                </pre>
            </div>
        )
    );
};
