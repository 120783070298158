import {cs} from "@common/react/chain-services";
import React from "react";
import {consumeContext, provideContext} from "@common/react/context";
import {Load2} from "@common/react/load2";
import {LiveDashboard} from "@common/ui-components/live/live-dashboard/live-dashboard";
import {ApiCollectionMain} from "../../../api-collection/edit/main/api-collection-main";
import {LoadingIndicator} from "@common/ui-components/loading-indicator/loading-indicator";

export const CollectionPreviewIframe = () =>
    cs(
        consumeContext("auth"),
        consumeContext("apis"),
        consumeContext("routing"),
        [
            "collection",
            ({apis, routing}, next) =>
                Load2({
                    _key: routing.params.colId ?? null,
                    fetch: async () => {
                        if (!routing.params.colId) {
                            return null;
                        }
                        return apis.collection.getCollection(routing.params.colId);
                    },
                    captureException: true,
                    next,
                }),
        ],

        ({collection}, next) => provideContext("collection", collection, next),

        ({collection, routing}) => {
            if (collection.loading) return LoadingIndicator({});

            let apiKey = routing.params.apiKey;
            if (collection.value.$type == "ApiCollection") {
                return ApiCollectionMain({
                    ...(apiKey ? {apiKey} : {}),
                    collection: collection,
                });
            }

            return (
                <div className="default-scrollbar-434" style={{height: "100%", overflow: "auto"}}>
                    {LiveDashboard({
                        ...(apiKey ? {apiKey} : {}),
                        collection: {
                            ...(collection?.value || {}),
                            customWindowWidth: routing.params.width,
                            viewWidthPixels: routing.params.width,
                        },
                        forceAutoRefresh: true,
                        // refreshing: refreshing.value,
                    })}
                </div>
            );
        }
    );
