import "./data-labels.scss";

import * as React from "react";

import {ShowHideToggle} from "../../../../../../../../../../../../../common/form/toggles/show-hide-toggle";
import {scope} from "@common/react/scope";
import {CheckboxLine} from "../../../../../../../../../../common/checkbox-line/checkbox-line";
import {DataLabelPositionSelect} from "../../../common/data-label-position-select";
import {getPath} from "@common/utils/arr-path";
import {MobileWarning} from "../../../common/mobile-warning/mobile-warning";

export const DataLabels = ({tile}) => ({
    label: (
        <div className="combo-data-labels-label-8uy">
            Data labels
            {MobileWarning({
                info: "Labels will not be shown on small mobile devices.",
            })}
        </div>
    ),
    render: () => (
        <div className="combo-chart-data-labels-35b">
            <div className="subsection">
                <div className="header">
                    Bar Data Labels
                    <div className="control">
                        {ShowHideToggle({
                            state: scope(tile, ["style", "yAxisBarDataLabels", "show"]),
                        })}
                    </div>
                </div>

                {getPath(tile.value, ["style", "yAxisBarDataLabels", "show"]) && (
                    <div className="content">
                        {DataLabelPositionSelect(scope(tile, ["style", "yAxisBarDataLabels", "position"]))}

                        {CheckboxLine({
                            label: "Allow Overlapping Labels",
                            state: scope(tile, ["style", "yAxisBarDataLabels", "allowOverlap"]),
                        })}
                    </div>
                )}
            </div>
            <div className="subsection">
                <div className="header">
                    Line Data Labels
                    <div className="control">
                        {ShowHideToggle({
                            state: scope(tile, ["style", "yAxisLineDataLabels", "show"]),
                        })}
                    </div>
                </div>

                {getPath(tile.value, ["style", "yAxisLineDataLabels", "show"]) && (
                    <div className="content">
                        {DataLabelPositionSelect(scope(tile, ["style", "yAxisLineDataLabels", "position"]))}

                        {CheckboxLine({
                            label: "Allow Overlapping Labels",
                            state: scope(tile, ["style", "yAxisLineDataLabels", "allowOverlap"]),
                        })}
                    </div>
                )}
            </div>
        </div>
    ),
});
