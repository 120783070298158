const {listToString} = require("./list-to-string");
const {stringToList} = require("./string-to-list");

const stringListStateWithNoneValue = (state) => ({
    value: state.value == "None" ? "" : stringToList(state.value),
    onChange: (list) => {
        const update = list.length == 0 ? "None" : listToString(list);
        return state.onChange(update);
    },
    change: (reducer) => state.change((s) => reducer(s)?.join(", ")),
});
exports.stringListStateWithNoneValue = stringListStateWithNoneValue;
