const createAutoSaveCollection = ({collection, savingQueue}) => ({
    value: collection.value,
    change: async (reduce, updateLocalBeforeCallRequest = true, asyncReduce) => {
        if (updateLocalBeforeCallRequest) {
            collection.change(reduce);
        }

        const _reduce = asyncReduce ? await asyncReduce() : null;
        const newCollection = await savingQueue.push(_reduce ?? reduce);
        collection.change(() => newCollection);
        return newCollection;
    },
    changeWithoutSync: collection.change,
});
exports.createAutoSaveCollection = createAutoSaveCollection;
