import {cx} from "emotion";
import {cs} from "@common/react/chain-services";
import {keyed} from "@common/react/keyed";
import {Static2} from "@common/react/static-2";
import {Dropdown} from "@common/ui-components/dropdown/dropdown";
import {isEmpty} from "@common/utils/objects";
import InSyncIcon from "../../../../../assets/icons/data-sources/InSyncIcon";
import NotSyncIcon from "../../../../../assets/icons/data-sources/NotSyncIcon";
import PauseIcon from "../../../../../assets/icons/data-sources/PauseIcon";
import {CheckMarkIcon} from "../../../../common/icons/checkmark-icon";
import {syncScheduleDefault} from "../../../add/default-data-source";
import "./sync-settings-status.scss";

export const SyncSettingStatus = ({type = "error", onlyIcon = false}) =>
    cs(() => {
        const status = {
            error: {
                icon: <span className="material-icons-outlined error">sync_problem</span>,
                text: <div className="text">Fix</div>,
            },
            sync: {
                icon: InSyncIcon({fill: "#1AC689"}),
                text: <div className="text">Sync</div>,
            },
            paused: {
                icon: PauseIcon({className: "paused"}),
                text: <div className="text">Paused</div>,
            },
            syncOnce: {
                icon: (
                    <div className="sync-once">
                        <div className="arc arc_start"></div>
                        <div className="arc arc_end"></div>
                        <div className="number">1</div>
                    </div>
                ),
                text: <div className="text">Sync Once</div>,
            },
            inSync: {
                icon: <span className="material-icons-outlined">circle</span>,
                text: <div className="text">Sync</div>,
            },
            notSync: {
                icon: NotSyncIcon({}),
                text: <div className="text">Do not sync</div>,
            },
        }[type];

        return (
            <div className={cx("sync-settings-status-99o sync-settings-status", type)}>
                {status?.icon}
                {!onlyIcon && status?.text}
            </div>
        );
    });

export const SyncSettingsStatusDropdown = ({type = "error", disabled, remoteTable, onToggleSync, isSyncing}) =>
    cs(() => {
        // when adding mode (sync once)
        return cs(
            ["renderToggle", (_, next) => next(({showExpand, showingExpand}) => SyncSettingStatus({type}))],
            [
                "renderExpand",
                (_, next) =>
                    next(({close}) => {
                        const cTable = remoteTable.value;

                        const list = [
                            ...(disabled
                                ? [
                                      {
                                          label: "Enable Sync",
                                          action: () =>
                                              onToggleSync(true, {
                                                  syncSchedule: {
                                                      $type: "SyncSchedule",
                                                  },
                                              }),
                                      },
                                      {
                                          label: "Sync Once (then Pause)",
                                          action: () =>
                                              onToggleSync(true, {
                                                  syncSchedule: {
                                                      ...cTable.syncSchedule,
                                                      $type: "OneTimeSync",
                                                      every: null,
                                                  },
                                                  syncMethod: "Full",
                                              }),
                                      },
                                  ]
                                : cTable.id
                                ? !cTable.paused
                                    ? [
                                          ...(cTable.syncSchedule.$type === "SyncSchedule"
                                              ? [
                                                    // {
                                                    //     label: "Sync Once (then Pause)",
                                                    //     action: () => remoteTable.change((t) => ({
                                                    //         ...t,
                                                    //         syncSchedule: { ...t.syncSchedule, $type: "OneTimeSync", every: null},
                                                    //         syncMethod: "Full"
                                                    //     }))
                                                    // }
                                                ]
                                              : [
                                                    {
                                                        label: "Sync",
                                                        action: () =>
                                                            remoteTable.change((t) => ({
                                                                ...t,
                                                                syncSchedule: syncScheduleDefault[t.syncMethod] ?? {
                                                                    ...t.syncSchedule,
                                                                    $type: "SyncSchedule",
                                                                },
                                                            })),
                                                    },
                                                ]),
                                          {
                                              label: "Pause",
                                              action: () =>
                                                  remoteTable.change((t) => ({
                                                      ...t,
                                                      paused: true,
                                                      syncSchedule: {
                                                          ...t.syncSchedule,
                                                      },
                                                  })),
                                          },
                                      ]
                                    : [
                                          {
                                              label: "Sync Once (then Pause)",
                                              action: () =>
                                                  remoteTable.change((t) => ({
                                                      ...t,
                                                      paused: false,
                                                      syncSchedule: {
                                                          ...t.syncSchedule,
                                                          $type: "OneTimeSync",
                                                          every: null,
                                                      },
                                                      syncMethod: "Full",
                                                  })),
                                          },
                                          {
                                              label: "Unpause",
                                              action: () =>
                                                  remoteTable.change((t) => ({
                                                      ...t,
                                                      paused: false,
                                                      syncSchedule: syncScheduleDefault[t.syncMethod] ?? {
                                                          ...t.syncSchedule,
                                                      },
                                                  })),
                                          },
                                      ]
                                : []),
                            ...(!disabled
                                ? [
                                      {
                                          label: "Disable Sync/ Delete Data",
                                          action: () => onToggleSync(false),
                                      },
                                  ]
                                : []),
                        ];

                        const addingList = [
                            ...(disabled
                                ? [
                                      {
                                          label: "Enable Sync",
                                          action: () => onToggleSync(true),
                                      },
                                  ]
                                : cTable.syncSchedule.$type === "SyncSchedule" || (isSyncing && !cTable.syncSchedule.hasOwnProperty("$type"))
                                ? [
                                      {
                                          label: "Sync Once (then Pause)",
                                          action: () =>
                                              remoteTable.change((t) => ({
                                                  ...t,
                                                  syncMethod: "Full",
                                                  syncSchedule: {
                                                      ...t.syncSchedule,
                                                      $type: "OneTimeSync",
                                                      every: null,
                                                  },
                                              })),
                                      },
                                  ]
                                : [
                                      {
                                          label: "Sync",
                                          action: () =>
                                              remoteTable.change((t) => ({
                                                  ...t,
                                                  syncSchedule: syncScheduleDefault[t.syncMethod] ?? {
                                                      ...t.syncSchedule,
                                                      $type: "SyncSchedule",
                                                  },
                                              })),
                                      },
                                  ]),
                            ...(!disabled
                                ? [
                                      {
                                          label: "Do Not Sync",
                                          action: () => onToggleSync(false),
                                      },
                                  ]
                                : []),
                        ];

                        return (
                            <div className="list">
                                {(cTable.id ? list : addingList).map((item, j) =>
                                    cs(keyed(j), () => (
                                        <div
                                            className="item"
                                            onClick={() => {
                                                item.action();
                                                close();
                                            }}
                                        >
                                            {item.label}
                                        </div>
                                    ))
                                )}
                            </div>
                        );
                    }),
            ],
            ["dropdownRef", (_, next) => Static2({next})],
            ({renderToggle, renderExpand, dropdownRef}) => {
                const mouseActions = ({ref}) => {
                    let timer;

                    return {
                        onMouseEnter: () => {
                            timer = setTimeout(() => ref.get().showExpand(true), 250);
                        },
                        onMouseLeave: () => {
                            clearTimeout(timer);
                            ref.get().showExpand(false);
                        },
                    };
                };

                return (
                    <div className="sync-settings-status-dropdown-99o sync-settings-status-dropdown" {...(type !== "error" && mouseActions({ref: dropdownRef}))}>
                        {Dropdown({
                            detectOnWheelEvent: true,
                            dropdownRef,
                            renderToggle,
                            minExpandHeight: 300,
                            renderExpand,
                        })}
                    </div>
                );
            }
        );
    });
