import React from "react";
import {cs} from "@common/react/chain-services";
import {tooltipService3} from "../../../../../../common/tooltip3/tooltip-service-3";
import {consumeContext} from "@common/react/context";
import {Button} from "@common/form/buttons/button/button";

export const Step1 = ({onNext, structured, showTutorial}) => {
    return (
        <div className="step-1">
            <div className="box success">
                <div className="icon success">
                    <img src={require("../images/check-icon.svg")} />
                </div>
                <div className="text">
                    Sync has been enabled on all of the {structured ? `tables` : `objects`} for you. Ingestion will begin upon save
                </div>
            </div>

            <div className="box">
                <span className="material-icons">info</span>
                <div className="text">Sync Settings shows schema Verb has access to but not the actual data.</div>
            </div>

            <div className="do-not-sync">
                <div className="text">
                    <div>
                        <strong>First, set tables to “Do Not Sync” that is unnecessary or sensitive.</strong>
                    </div>
                    <br />
                    <div>You may also turn off column syncing at each {structured ? `table` : `object`}.</div>
                </div>
                <img src={require("../images/do-not-sync.png")} />
            </div>

            <div className="step-footer">
                {RemoveGuidanceButton({showTutorial})}

                <Button iconRight={<i className="fas fa-chevron-right" />} className="btn btn-primary" onClick={onNext}>
                    Next
                </Button>
            </div>
        </div>
    );
};

export const RemoveGuidanceButton = ({showTutorial}) =>
    cs(tooltipService3({direction: "below"}), ({tooltip}) => (
        <div className="remove-guidance" onClick={() => showTutorial.onChange(false)}>
            I don't need guidance{" "}
            <i
                className="material-icons"
                {...tooltip(
                    () =>
                        "Your preferences will be updated and this tutorial will not show in the future unless you update your profile preferences"
                )}
            >
                info
            </i>
        </div>
    ));
