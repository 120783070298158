import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import "./tile-errors-badge.scss";
import {AlertIcon} from "@common/ui-components/icons/global-icons";
import {UseState} from "@common/react/use-state";
import {AnyAction} from "@common/react/any-action";
import {createElement as h} from "react";
import {PopupMenu} from "../../../../../web-client/src/routes/common/popup-menu/popup-menu";
import {noun} from "@common/utils/plural";
import {ArrowDownIcon} from "../../../../../web-client/src/routes/collection/preview/collection-preview-icons";
import {cx} from "emotion";
import {Button} from "@common/form/buttons/button/button";
import {RoutingParam} from "../../../../../web-client/src/routes/common/routing/routing-param";

export const TileErrorsBadge = ({tileErrors, tooltip, tile}) =>
    cs(
        consumeContext("routing"),
        [
            "selectedTab",
            (_, next) =>
                RoutingParam({
                    param: "tileTab",
                    next,
                }),
        ],
        ["open", (_, next) => UseState({next, initValue: false})],
        ({open}, next) =>
            h(AnyAction, {
                disabled: !open.value,
                fn: () => {
                    open.onChange(false);
                },
                next,
            }),
        [
            "errorPopups",
            ({routing, selectedTab, open}, next) => {
                return PopupMenu({
                    registryRender: true,
                    customRenderMenu: ({top, right, className, onClose}) => (
                        <div
                            className={cx("error-popup-box-a43", className)}
                            style={{
                                top: top + 10,
                                right: right - 140,
                                width: 300,
                            }}
                        >
                            {tileErrors.map((error, index) => (
                                <div className="error-row" key={index}>
                                    <AlertIcon className="icon" />

                                    <div className="error-label">
                                        Broken {error.fieldLocation == "Filter" ? "Filter" : "Data Field"} - <b>{error.columnName}</b>
                                    </div>

                                    <Button
                                        className="fix-btn"
                                        btnType="border"
                                        size="small"
                                        onClick={() => {
                                            if (tile.isShared) {
                                                selectedTab.onChange(error.fieldLocation == "Filter" ? "filters" : "fields");
                                            } else {
                                                if (!error.parentID) {
                                                    routing.goto("edit-tile", {
                                                        tileId: error.tileID,
                                                        tileTab: error.fieldLocation == "Filter" ? "filters" : "fields",
                                                    });
                                                } else {
                                                    routing.goto("edit-container-tile", {
                                                        cTileId: error.parentID,
                                                        tileTab: error.fieldLocation == "Filter" ? "filters" : "fields",
                                                        childTile: error.tileID,
                                                    });
                                                }
                                            }
                                            onClose?.();
                                        }}
                                    >
                                        Fix
                                    </Button>
                                </div>
                            ))}
                        </div>
                    ),
                    getCommands: () => tileErrors,
                    popupClassName: "tile-error-popup-expanded-a55",
                    next,
                    customizeBadge: (
                        <div
                            className="alert-icon-wrapper"
                            ref={tooltip.ref}
                            {...{
                                ...tooltip("Cannot render tile until all errors are resolved. Click to view errors."),
                            }}
                        >
                            <AlertIcon className="alert-icon" />
                        </div>
                    ),
                });
            },
        ],
        ({errorPopups}) => <div className="tile-errors-badge-a88">{errorPopups.render()}</div>
    );
