import * as React from "react";
import {cs} from "@common/react/chain-services";
import {DialogService} from "../../../../../common/dialog/dialog-service";
import "./remove-relationship-dialog.scss";
import {consumeContext} from "@common/react/context";
import {getUsingCollections} from "../../common/get-using-collections";
import {joinJsx} from "@common/react/join-jsx";
import {loadCollections} from "../../common/load-collections";
import {flatten1} from "@common/utils/collections";
import {spc} from "@common/react/state-path-change";
import {Load} from "@common/react/load";
import {keyToArray} from "../../../../../dashboard/env/settings/data-access-key/manage-key/manage-access-key-dialog";
import {Button} from "../../../../../../../../common/form/buttons/button/button";
import {noun} from "@common/utils/plural";

export const RemoveRelationshipDialog = ({model, onDone, next: rootNext}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    render: ({resolve, args: {tables, relationships}}) => ({
                        title: "Remove Relationship?",
                        width: 480,
                        content: next({
                            resolve,
                            relationships,
                            tables,
                        }),
                    }),
                    next: rootNext,
                }),
        ],
        ({modal: {relationships, tables, resolve}}) =>
            cs(
                consumeContext("apis"),
                [
                    "impactedCollections",
                    ({apis}, next) =>
                        Load({
                            fetch: async () => {
                                const resp = await apis.model.getImpactedCollection({
                                    modelID: model.value.id,
                                    tables,
                                });
                                return keyToArray(resp).map((item) => item.value.name);
                            },
                            next,
                        }),
                ],
                ({impactedCollections}) => (
                    <div className="remove-relationship-dialog-yi5">
                        {!impactedCollections ? (
                            <div className="checking dialog-body">Checking...</div>
                        ) : (
                            <>
                                <div className="main-part dialog-body">
                                    {!impactedCollections?.length ? (
                                        `This ${noun(
                                            relationships.length,
                                            "relationship"
                                        )} is not used in any collections but removing this relationship will limit collection data in the future.`
                                    ) : (
                                        <>
                                            This {noun(relationships.length, "relationship")} is used in the following collections;
                                            <br />
                                            <br />
                                            {joinJsx(
                                                impactedCollections.map((n) => "- " + n),
                                                <br />
                                            )}
                                            <br />
                                            <br />
                                            Removing this {noun(relationships.length, "relationship")} will not be reflected in the embedded
                                            collection until the next publish.
                                        </>
                                    )}
                                    <br />
                                    <br />
                                    Are you sure that you want to remove this {noun(relationships.length, "relationship")}?
                                </div>
                                <div className="buttons">
                                    <Button btnType="secondary" onClick={() => resolve()}>
                                        Cancel
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            resolve();
                                            onDone?.(relationships);
                                        }}
                                    >
                                        Remove {noun(relationships.length, "Relationship")}
                                    </Button>
                                </div>
                            </>
                        )}
                    </div>
                )
            )
    );
