function isNotPublishedCalculatedColumn(col) {
    if (!col.calculations) {
        return false;
    }

    return !col.calculations[0]?.versionDetails || col.calculations[0]?.versionDetails === "NotPublished";
}

const isAggregatedMeasure = (col) => col.$type === "AggregatedMeasureModelColumn";

module.exports = {
    isNotPublishedCalculatedColumn,
    isAggregatedMeasure,
};
