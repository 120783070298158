import {Button} from "@common/form/buttons/button/button";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {scope} from "@common/react/scope";
import {RadioLine} from "../../../../../../../../../../common/radio-line/radio-line";
import {ConditionalFormatRules} from "./format-rules/conditional-format-rules";
import {ConditionalColorAndStyle} from "./color-and-style/conditional-color-and-style";
import {ConfirmDialog} from "../../../../../../../../../../common/confirm-dialog/confirm-dialog";
import {isValidConditionalRule} from "./manage-conditional-rule-common";
import {right} from "../../../../../../../../../../../../../storybook/stories/svg-icons/similar-svg-icons-clean";
import CloseIcon from "@common/form/common/close-black-icon.svg";
import React from "react";
import ReactDOM from "react-dom";
import {OnMounted} from "@common/react/on-mounted";
import {equalDeep} from "@common/utils/objects";
import {CheckboxLine} from "../../../../../../../../../../common/checkbox-line/checkbox-line";
import {StopIfTrueIcon} from "@common/ui-components/charts/common/icons/icons";
import {StaticTooltipService} from "../../../../../../../../../../common/tooltip3/static-tooltip-service";

const COLOR_TYPES = [
    {
        label: "Single Color",
        value: "SingleColor",
    },
    {
        label: "Color Scale",
        value: "ColorScale",
    },
];

export const ManageConditionalRule = ({rule, onSave, onAdd, tile, onClose, onDelete}) =>
    cs(
        ["state", (_, next) => UseState({next, initValue: rule})],
        ["mounted", (_, next) => UseState({next, initValue: false})],
        ({mounted}, next) =>
            OnMounted({
                next,
                action: () => {
                    setTimeout(() => {
                        mounted.onChange(true);
                    }, 100);
                },
            }),
        [
            "incompleteRuleModal",
            (_, next) =>
                ConfirmDialog({
                    next,
                    body: "Incomplete rules cannot be saved. Do you want to delete this rule?",
                    submitText: "Return to Rule",
                    title: "Conditional Rule is Incomplete",
                    cancelText: "Discard Rule",
                }),
        ],
        [
            "unSavedChangeRuleModal",
            (_, next) =>
                ConfirmDialog({
                    next,
                    body: "There are unsaved changes to this rule. Would you like to save these changes?",
                    submitText: "Save",
                    title: "Unsaved Changes",
                    cancelText: "Discard Updates",
                }),
        ],
        [
            "confirmDeleteRuleModal",
            (_, next) =>
                ConfirmDialog({
                    next,
                    body: "Are you sure you want to delete this conditional rule?",
                    submitText: "Delete Rule",
                    title: "Delete Rule",
                    cancelText: "Cancel",
                }),
        ],
        [
            "staticTooltip",
            (_, next) =>
                StaticTooltipService({
                    direction: "right",
                    info: (
                        <div className="stop-if-true-info-tooltip">
                            <div className="left-icon">
                                <StopIfTrueIcon />
                            </div>
                            When applied, no subsequent color rules will be applied to cells where these conditions are met.
                        </div>
                    ),
                    tooltipBoxWidth: 250,
                    next,
                }),
        ],
        ({state, incompleteRuleModal, confirmDeleteRuleModal, mounted, unSavedChangeRuleModal, staticTooltip}) => {
            const colorTypeState = scope(state, ["colorType"]);

            const isValidRule = async () => {
                const valid = isValidConditionalRule(state.value);
                if (valid) {
                    return "VALID";
                }

                const resp = await incompleteRuleModal.show();
                if (!resp) {
                    return "DISCARD";
                }

                return null;
            };

            const save = async () => {
                const resp = await isValidRule();
                if (resp == "VALID") {
                    rule.id ? onSave(state.value) : onAdd(state.value);
                    return;
                }

                if (resp == "DISCARD") {
                    onClose();
                }
            };

            return (
                <div className="manage-conditional-rule-a33">
                    {mounted.value &&
                        ReactDOM.createPortal(
                            <div
                                className="close"
                                onClick={async () => {
                                    if (!equalDeep(rule, state.value)) {
                                        const resp = await unSavedChangeRuleModal.show();

                                        if (resp) {
                                            await save();
                                        } else {
                                            onClose();
                                        }
                                    } else {
                                        onClose();
                                    }
                                }}
                            >
                                <img src={CloseIcon} alt="" />
                            </div>,
                            document.getElementById("left-panel-header")
                        )}

                    <VerbScrollbar className="manage-rule-body">
                        <div className="manage-rule-body-wrapper">
                            <div className="rule-section">
                                <div className="rule-heading">Color Type</div>

                                <div className="rule-content">
                                    {COLOR_TYPES.map((colorType, index) => (
                                        <RadioLine
                                            key={index}
                                            label={colorType.label}
                                            selected={colorTypeState.value == colorType.value}
                                            onClick={() =>
                                                state.onChange({
                                                    ...state.value,
                                                    conditions:
                                                        colorType.value == "ColorScale"
                                                            ? []
                                                            : [
                                                                  {
                                                                      fieldConditions: [{}],
                                                                  },
                                                              ],
                                                    colorType: colorType.value,
                                                    ...(colorType.value == "ColorScale" ? {stopIfTrue: false} : {}),
                                                })
                                            }
                                            className="color-type-radio"
                                        />
                                    ))}
                                </div>
                            </div>

                            <div className="rule-section">
                                <div className="rule-heading">Format Rules</div>

                                <ConditionalFormatRules state={state} tile={tile} />
                            </div>

                            <div className="rule-section">
                                <div className="rule-heading">Color and Style</div>

                                <ConditionalColorAndStyle state={state} tile={tile} />
                            </div>

                            {state.value.colorType == "SingleColor" && (
                                <div className="rule-section">
                                    <div className="rule-heading">Rule Application</div>

                                    <div className="stop-if-true-content">
                                        <CheckboxLine
                                            state={scope(state, ["stopIfTrue"])}
                                            label={
                                                <span className="stop-if-true">
                                                    Stop if True
                                                    {staticTooltip.renderIcon({
                                                        icon: <i className="material-icons">info</i>,
                                                        className: "info-tooltip",
                                                    })}
                                                </span>
                                            }
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </VerbScrollbar>

                    <div className="conditional-rule-buttons">
                        {rule.id != null && (
                            <Button
                                btnType="danger"
                                onClick={async () => {
                                    const resp = await confirmDeleteRuleModal.show();
                                    if (resp) {
                                        onDelete(rule);
                                    }
                                }}
                            >
                                Delete Rule
                            </Button>
                        )}

                        <Button className="save-btn" btnType="primary" onClick={save}>
                            Save
                        </Button>
                    </div>
                </div>
            );
        }
    );
