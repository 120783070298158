import {CircleMarkerIcon, DiamondMarkerIcon, SquareMarkerIcon, TriangleDownMarkerIcon, TriangleMarkerIcon} from "@common/ui-components/charts/scatter-plot/legend/scatter-marker-icons";
import React from "react";

export const ColorGroupItem = ({name, color, chart, hidden, theme, fontSize}) => {
    const fontColor = theme.general.canvas.fontColorRGB;
    const isHidden = hidden.value?.colorGroups?.includes(name);
    return (
        <div
            className="legend-item"
            key={color}
            onClick={() => {
                if (isHidden) {
                    hidden.onChange({
                        ...hidden.value,
                        colorGroups: hidden.value.colorGroups.filter((c) => c !== name),
                    });
                } else {
                    hidden.onChange({
                        ...hidden.value,
                        colorGroups: [...(hidden.value?.colorGroups || []), name],
                    });
                }
                chart.series.forEach((s) => {
                    if (s.userOptions.colorGroupName !== name) {
                        return;
                    }
                    const isHiddenByShape = hidden.value?.shapeGroups?.includes(s.userOptions.shapeGroupName);
                    if (isHidden && !isHiddenByShape) {
                        s.setVisible(true);
                    } else {
                        s.setVisible(false);
                    }
                });
            }}
        >
            <div className="icon">
                <SquareMarkerIcon stroke={isHidden ? color : undefined} fill={isHidden ? "none" : color} />
            </div>
            <div className="legend-text" style={{color: fontColor, fontSize}}>
                {name}
            </div>
        </div>
    );
};

export const ShapeGroupItem = ({name, shape, chart, hidden, theme, fontSize}) => {
    const fontColor = theme.general.canvas.fontColorRGB;
    const MarkerIcon = markerIcons[shape];
    const isHidden = hidden.value?.shapeGroups?.includes(name);
    return (
        <div
            className="legend-item"
            key={shape}
            onClick={() => {
                if (isHidden) {
                    hidden.onChange({
                        ...hidden.value,
                        shapeGroups: hidden.value.shapeGroups.filter((c) => c !== name),
                    });
                } else {
                    hidden.onChange({
                        ...hidden.value,
                        shapeGroups: [...(hidden.value?.shapeGroups || []), name],
                    });
                }
                chart.series.forEach((s) => {
                    if (s.userOptions.shapeGroupName !== name) {
                        return;
                    }
                    const isHiddenByColor = hidden.value?.colorGroups?.includes(s.userOptions.colorGroupName);
                    if (isHidden && !isHiddenByColor) {
                        s.setVisible(true);
                    } else {
                        s.setVisible(false);
                    }
                });
            }}
        >
            {MarkerIcon ? (
                <div className="icon">
                    <MarkerIcon stroke={isHidden ? fontColor : undefined} fill={isHidden ? "none" : fontColor} />
                </div>
            ) : (
                <div className="icon bar-icon" style={{background: "#919EB0"}} />
            )}
            <div className="legend-text" style={{color: fontColor, fontSize}}>
                {name}
            </div>
        </div>
    );
};

const markerIcons = {
    circle: CircleMarkerIcon,
    square: SquareMarkerIcon,
    triangle: TriangleMarkerIcon,
    "triangle-down": TriangleDownMarkerIcon,
    diamond: DiamondMarkerIcon,
};

export const ReferenceLineItem = ({series, chart, tile, theme, hidden, fontSize}) => {
    const fontColor = theme.general.canvas.fontColorRGB;
    const {id, name, color, customStack} = series.userOptions;
    const isHidden = hidden.value?.referenceLines?.includes(id);
    const axisProp = customStack === tile.yAxisField.id ? "yAxis" : "xAxis";
    return (
        <div
            className="legend-item" key={id}
            onClick={() => {
                if (isHidden) {
                    hidden.onChange({
                        ...hidden.value,
                        referenceLines: hidden.value.referenceLines.filter((rl) => rl !== id)
                    });
                } else {
                    hidden.onChange({
                        ...hidden.value,
                        referenceLines: [...(hidden.value?.referenceLines || []), id]
                    });
                }

                chart.series.forEach((s1) => {
                    if (s1.userOptions.id !== id) {
                        return;
                    }
                    if (["ConstantValueReferenceLine", "AggregateValueReferenceLine"].includes(s1.userOptions?.customType)) {
                        if (s1.visible) {
                            s1.setVisible(false);
                            s1[axisProp].removePlotLine(s1.userOptions.plotLineOptions.id);
                        } else {
                            s1.setVisible(true);
                            s1[axisProp].addPlotLine(s1.userOptions.plotLineOptions);
                        }
                    } else {
                        if (isHidden) {
                            series.setVisible(true);
                        } else {
                            series.setVisible(false);
                        }
                    }
                })
            }}
        >
            <div
                className="icon line-icon"
                style={{background: isHidden ? "#919EB0" : color}}
            />
            <div className="legend-text" style={{color: fontColor, fontSize}}>
                {name}
            </div>
        </div>
    )
}
