import React from "react";
import {cs} from "@common/react/chain-services";
import {DashboardLayout} from "./layout/dashboard-layout";
import {EnvDashboard} from "./env/env-dashboard/env-dashboard";
import {RoutingParam} from "../common/routing/routing-param";
import {consumeContext} from "@common/react/context";
import {UseState} from "@common/react/use-state";
import {Invoke} from "@common/react/invoke";

export const DashboardRoute = ({environment}) =>
    cs(
        consumeContext("apis"),
        consumeContext("tenant"),
        consumeContext("auth"),
        consumeContext("routing"),
        [
            "tab",
            ({auth}, next) => {
                // const defaultTab = ["Full", "Verb"].includes(auth.user.roles[0]) ? "overview" : "collections";
                const defaultTab = "recent";
                return RoutingParam({
                    defaultValue: defaultTab,
                    param: "tab",
                    next: ({value, onChange}) => next({value: value ?? defaultTab, onChange}),
                });
            },
        ],
        ["activeTab", ({tab}, next) => UseState({initValue: tab.value, next})],
        ["envId", ({tab}, next) => RoutingParam({param: "envId", next})],
        ({apis, routing, tenant, envId}, next) =>
            tenant.environments?.length > 0
                ? Invoke({
                      next,
                      fn: async () => {
                          if (tenant.environments) {
                              let found = tenant.environments.find((e) => e.id == routing.params.envId);
                              if (!found) {
                                  routing.params.envId && envId.onChange(null);
                              } else {
                                  if (!found.isProd) {
                                      return;
                                  }

                                  // const dataSources = await apis.data.getDataSources({namesOnly: true});
                                  //
                                  // if (dataSources.length == 0) {
                                  //     const sampleEnv = (tenant.environments || []).find?.((l) => l.name === "Sample Environment");
                                  //     sampleEnv && routing.pushParams({
                                  //         envId: sampleEnv.id
                                  //     })
                                  // }
                              }
                          }
                      },
                  })
                : next(),

        ({envId, tab}, next) => DashboardLayout({envId, tab, next}),

        ({envId, tab}) =>
            EnvDashboard({
                tab,
                envId: envId.value,
                environment,
            })
    );
