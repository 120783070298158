import "./delete-recent-dialog.scss";

import * as React from "react";

import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {consumeContext} from "@common/react/context";
import {TextInput} from "@common/form/text-input/text-input";
import {Button} from "@common/form/buttons/button/button";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";
import {DialogService} from "../../../../common/dialog/dialog-service";
import {noun} from "@common/utils/plural";

// support delete one or many collections
// params: can be one collection or many collections
export const DeleteRecentDialog = ({onDone, next: rootNext}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    render: ({resolve, args: {collections, dataAccessApiKeys, iframeKeys, sharedTiles}}) => ({
                        title: "Delete Collection",
                        width: 500,
                        content: next({
                            resolve,
                            collections,
                            dataAccessApiKeys,
                            iframeKeys,
                            sharedTiles,
                        }),
                    }),
                    next: rootNext,
                }),
        ],
        consumeContext("apis"),
        ["confirmText", (_, next) => UseState({next})],
        ["submitting", (_, next) => UseState({next})],
        ({modal, confirmText, submitting, apis}) => {
            const {collections, dataAccessApiKeys, iframeKeys, sharedTiles} = modal;

            const numberOfItems = collections.length + dataAccessApiKeys.length + iframeKeys.length + sharedTiles.length;
            const message = `Are you sure you want to delete ${numberOfItems} ${noun(numberOfItems, "item")}`;
            return (
                <form
                    onSubmit={async (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (!confirmText.value || confirmText.value.toLowerCase() !== "delete" || submitting.value) {
                            return;
                        }

                        submitting.onChange(true);
                        await Promise.all(
                            collections.map((c) =>
                                apis.collection.deleteCollection({
                                    colId: c.id,
                                    colName: c.name,
                                })
                            )
                        );

                        await Promise.all(dataAccessApiKeys.map((item) => apis.accessKey.removeKey(item.id)));
                        await Promise.all(iframeKeys.map((item) => apis.iframeKey.removeKey(item.id)));
                        await Promise.all(sharedTiles.map((item) => apis.collectionTiles.deleteTile(item.id)));

                        submitting.onChange(false);
                        modal.resolve(true);
                    }}
                >
                    <div className="delete-recent-dialog-57a">
                        <VerbDialogBodyScrollbar>
                            <div className="main-part">
                                <div className="warning">{message}</div>

                                {TextInput({
                                    label: <span className="confirm-input-label-5sj">Please type DELETE to confirm</span>,
                                    state: confirmText,
                                })}
                            </div>
                        </VerbDialogBodyScrollbar>

                        <div className="buttons">
                            <Button type="button" btnType="secondary" onClick={() => modal.resolve()}>
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                btnType="primary-danger"
                                disabled={!confirmText.value || confirmText.value.toLowerCase() !== "delete" || submitting.value}
                            >
                                Delete
                            </Button>
                        </div>
                    </div>
                </form>
            );
        }
    );
