import {parseDateTime} from "@common/logic/date-time/parse-date-time";
import {
    addDate,
    addMonth,
    addYear,
    dateDiff,
    getDayEnd,
    getDayStart,
    getMonthStart,
    getPreviousDayInWeek,
    getStartOfDay,
    getWeekStart,
    getYearStart,
    parseDate,
    today,
    todayWithTime,
    tomorrow,
} from "@common/utils/dates/date-object";

export const getCustomRange = (customRange) => {
    const interval = {
        Days: addDate,
        Weeks: (date, delta) => addDate(date, delta * 7),
        Months: addMonth,
        Quarters: (date, delta) => addMonth(date, delta * 3),
        Years: addYear,
    }[customRange.interval];

    if (customRange.$type === "InTheLastOrNextCustomDateFilterRange") {
        const range = !customRange.negative
            ? customRange.next
                ? {
                      from: today(),
                      to: interval(today(), customRange.value),
                  }
                : {
                      from: interval(today(), -customRange.value),
                      to: today(),
                  }
            : customRange.next
            ? {
                  from: interval(today(), customRange.value + 1),
                  to: parseDateTime("2999-12-31").date,
              }
            : {
                  from: parseDateTime("1900-01-01").date,
                  to: interval(today(), -customRange.value),
              };
        return {
            range,
            getComparingRange: () => ({
                from: interval(range.from, -customRange.value - 1),
                to: addDate(range.from, -1),
            }),
        };
    } else if (customRange.$type === "InThePreviousCustomDateFilterRange") {
        const _today = today();
        let range;

        if (customRange.interval === "Days" && customRange.negative) {
            range = {
                from: parseDateTime("1900-01-01").date,
                to: addDate(_today, -customRange.value - 1),
            };
        } else {
            const lastSaturday = parseDate(getPreviousDayInWeek(6));

            const previousInterval = {
                Days: {
                    from: addDate(_today, -customRange.value - 1),
                    to: addDate(_today, -1),
                },
                Weeks: {
                    from: addDate(lastSaturday, -customRange.value * 7 + 1),
                    to: lastSaturday,
                },
                Months: {
                    from: getMonthStart(addMonth(_today, -customRange.value)),
                    to: addDate(getMonthStart(_today), -1),
                },
                Years: {
                    from: getYearStart(addYear(_today, -customRange.value)),
                    to: addDate(getYearStart(_today), -1),
                },
            }[customRange.interval];

            range = {
                from: previousInterval.from,
                to: previousInterval.to,
            };
        }

        const result = {
            from: getDayStart(range.from),
            to: getDayEnd(range.to),
        };

        return {
            range: result,
            getComparingRange: () => ({
                from: addDate(result.from, -dateDiff(range.to, range.from) - 1),
                to: addDate(result.from, -1),
            }),
        };
    } else if (customRange.$type === "BeforeOrAfterCustomDateFilterRange") {
        return {
            range: customRange.after
                ? {
                      from: {
                          ...parseDateTime(customRange.date).date,
                          hours: 0,
                          minutes: 0,
                          seconds: 0,
                      },
                      to: {
                          ...today(),
                          hours: 23,
                          minutes: 59,
                          seconds: 59,
                      },
                  }
                : {
                      from: {
                          day: 1,
                          month: 1,
                          year: 2000,
                          hours: 0,
                          minutes: 0,
                          seconds: 0,
                      },
                      to: {
                          ...parseDateTime(customRange.date).date,
                          hours: 23,
                          minutes: 59,
                          seconds: 59,
                      },
                  },
        };
    } else if (customRange.$type === "InTheYearCustomDateFilterRange") {
        const getYearRange = (year) => ({
            from: {day: 1, month: 1, year: year},
            to: {day: 1, month: 1, year: year + 1},
        });
        return {
            range: getYearRange(customRange.year),
            getComparingRange: () => getYearRange(customRange.year - 1),
        };
    } else if (customRange.$type === "InRangeCustomDateFilterRange") {
        const range = {
            from: parseDateTime(customRange.dateStart).date,
            to: addDate(parseDateTime(customRange.dateEnd).date, 1), // date-end
        };
        return {
            range,
            getComparingRange: () => prevDateRange(range),
        };
    } else if (customRange.$type === "InTheCurrentCustomDateFilterRange") {
        const _today = todayWithTime();

        let _from = null;

        if (customRange.interval == "Days") {
            _from = getStartOfDay(_today);
        } else if (customRange.interval == "Weeks") {
            _from = getWeekStart(_today);
        } else if (customRange.interval == "Months") {
            _from = getMonthStart(_today);
        } else if (customRange.interval == "Years") {
            _from = getYearStart(_today);
        }

        const range = {
            from: _from,
            to: _today, // date-end
        };
        return {
            range,
            getComparingRange: () => prevDateRange(range),
        };
    }
};

const prevDateRange = (range) => ({
    to: range.from,
    from: addDate(range.from, -dateDiff(range.to, range.from)),
});
