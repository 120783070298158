import "./data-menu.scss";

import * as React from "react";

import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {SearchInput} from "@common/form/search-input/search-input";
import {DataViewsMenu} from "./data-views-menu";
import {DataSourcesMenu} from "./data-sources-menu";
import {consumeContext} from "@common/react/context";
import {Watch} from "@common/react/watch";
import {Static2} from "@common/react/static-2";
import {ShortestPossiblePathDialog} from "../../left-panel-overrides/table-panel/shortest-possible-path/shortest-possible-path-dialog";
import {ModelPanelHelper} from "../../../../common/model-panel-helper";
import {buildAutoSuggestModel} from "../../../../common/build-auto-suggest-model";
import {DataMenuSkeletonLoading} from "./skeleton-loading/data-menu-skeleton-loading";
import {Button} from "@common/form/buttons/button/button";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";
import {isEmpty, omit} from "@common/utils/objects";
import {IgnoreUpdateModelPositions, scrollTopTo} from "./common";
import {Tabs} from "@common/ui-components/tabs/tabs";
import {cx} from "emotion";
import {UseEffect} from "@common/react/use-effect";
import {RAFLoop} from "@common/utils/loop";
import {spc} from "@common/react/state-path-change";
import {unique} from "@common/utils/collections";
import {IgnoreUpdate} from "@common/react/ignore-update";
import NoFieldFoundImg from "./no-field-found.svg";
import {LoadingIndicator} from "@common/ui-components/loading-indicator/loading-indicator";
import {RemoveTableDialog} from "../../left-panel-overrides/table-panel/remove-table/remove-table-dialog";

const tabs = [
    {
        name: "all",
        label: "All",
    },
    {
        name: "usedInModel",
        label: "Used In Model",
    },
];

export const DataMenu = ({model, dataSources, chart, autoSuggest}) =>
    cs(
        consumeContext("routing"),
        consumeContext("apis"),
        consumeContext("interactions"),
        consumeContext("dataSourceModelContext"),
        ({dataSourceModelContext}, next) => {
            const {entities} = dataSourceModelContext;
            if (!dataSources || isEmpty(entities.dataSources)) {
                return DataMenuSkeletonLoading({});
            }

            return next();
        },
        ({dataSourceModelContext, interactions}, next) => {
            return IgnoreUpdateModelPositions({
                dataSourceModelContext,
                extraProps: {
                    interactionTimestamp: interactions.setting?.timestamp,
                },
                next,
            });
        },
        ["shortestPossiblePathDialog", (_, next) => ShortestPossiblePathDialog({next})],
        [
            "removeTableService",
            ({interactions}, next) =>
                RemoveTableDialog({
                    model,
                    onDone: () => interactions.deSelectTable(),
                    next,
                }),
        ],
        ["scrollContainerRef", (_, next) => Static2({next})],
        ["renderComp", (_, next) => UseState({next, initValue: 0})],
        [
            "state",
            (_, next) =>
                UseState({
                    next,
                    initValue: {
                        search: null,
                        // activeTab: tabs[1].name,
                        activeTab: tabs[0].name,
                        fakeLoading: false,
                        disabledDetectScrollDirection: false,
                        scrollDirection: 0, // 1: down, -1: up, 0: no scrolling
                    },
                }),
        ],
        ["containerTop", (_, next) => UseState({next, initValue: 0})],
        ({state, dataSourceModelContext, shortestPossiblePathDialog, interactions, scrollContainerRef, removeTableService}, next) => {
            const {search, activeTab, scrollDirection, disabledDetectScrollDirection} = state.value;
            const {search: contextSearch} = dataSourceModelContext;

            const suggestedRelationships = ModelPanelHelper.recommendRelationships({
                tables: model.value.tables.map((t) => omit(t, ["position"])),
                relationships: model.value.relationships,
            });

            return (
                <VerbScrollbar
                    className={cx("data-menu-57j", [0, -1].includes(scrollDirection) && "show-search")}
                    getRef={scrollContainerRef.set}
                    style={{minHeight: "100%"}}
                >
                    {suggestedRelationships.length > 0 && !model.value.hideSuggestedRelationships && !autoSuggest.value && (
                        <div
                            className="suggested-relationships-btn"
                            onClick={() => interactions.viewAllSuggestedRelationships(suggestedRelationships)}
                        >
                            <Button size="medium" btnType="primary" className="btn btn-blue">
                                Suggested Relationships
                            </Button>
                        </div>
                    )}

                    {Tabs({
                        list: tabs,
                        activeTab: {
                            value: activeTab,
                            onChange: (tabName) => {
                                if (search != "") {
                                    contextSearch("");
                                }

                                state.change((s) => {
                                    return {
                                        ...s,
                                        activeTab: tabName,
                                        search: "",
                                    };
                                });
                            },
                        },
                    })}

                    <div className="search">
                        <SearchInput
                            {...{
                                state: {
                                    value: search,
                                    onChange: (v) => {
                                        contextSearch(v);
                                        spc(state, ["search"], () => v);

                                        const container = scrollContainerRef.get();

                                        if (container) {
                                            container.scrollTop = 0;
                                        }
                                    },
                                },
                                label: "Search",
                                placeholder: "Find Fields",
                                flushOnBlur: false,
                            }}
                        />
                    </div>

                    {!disabledDetectScrollDirection &&
                        UseEffect({
                            fn: () => {
                                let oldScrollTop = undefined;
                                return RAFLoop.addTarget({
                                    update: () => {
                                        const container = scrollContainerRef.get();
                                        if (container) {
                                            if (oldScrollTop === undefined) {
                                                oldScrollTop = container.scrollTop;
                                            }

                                            let _scrollDirection = scrollDirection;
                                            if (oldScrollTop > container.scrollTop) {
                                                _scrollDirection = -1;
                                            }
                                            if (oldScrollTop < container.scrollTop) {
                                                _scrollDirection = 1;
                                            }

                                            oldScrollTop = container.scrollTop;

                                            if (_scrollDirection != scrollDirection) {
                                                spc(state, ["scrollDirection"], () => _scrollDirection);
                                            }
                                        }
                                    },
                                });
                            },
                        })}

                    {Watch({
                        value: interactions.setting?.timestamp,
                        onChanged: () => {
                            const {dataSourceTableID, dataSourceID, tableId, $type} = interactions.setting?.data ?? {};
                            if (dataSourceTableID || tableId) {
                                let newState = {
                                    scrollDirection: 1,
                                    disabledDetectScrollDirection: true,
                                };

                                if (search != null) {
                                    contextSearch(null);
                                    newState = {
                                        ...(newState ?? {}),
                                        search: null,
                                    };
                                }

                                if (activeTab == "all" && $type == "ViewModelTable") {
                                    newState = {
                                        ...(newState ?? {}),
                                        activeTab: "usedInModel",
                                    };
                                }

                                if (newState) {
                                    state.change((s) => ({...s, ...newState}));
                                }

                                setTimeout(() => {
                                    const container = scrollContainerRef.get();
                                    const elem = document.querySelector(`[data-id='${dataSourceTableID ?? tableId}_${dataSourceID}']`);

                                    if (elem) {
                                        scrollTopTo({
                                            // disabledAnimation: true,
                                            containerElem: container,
                                            targetElem: elem,
                                            // offset: (scrollDirection) => -38 - 57 - (scrollDirection > 0 ? -4 : 74),
                                            offset: () => -38 - 57,
                                            onDone: () => spc(state, ["disabledDetectScrollDirection"], () => false),
                                        });
                                    }
                                }, 500);
                            }
                        },
                    })}

                    {next()}
                </VerbScrollbar>
            );
        },
        ({state}, next) => {
            return IgnoreUpdate({
                next,
                props: {scrollDirection: state.value.scrollDirection},
                when: ({scrollDirection}) => scrollDirection != state.value.scrollDirection,
            });
        },
        ({state, dataSourceModelContext, shortestPossiblePathDialog, interactions, removeTableService}) => {
            const {search, activeTab, fakeLoading} = state.value;
            const {openTablePanel, openColumnPanel} = interactions;
            const {tables} = autoSuggest.value ? buildAutoSuggestModel(dataSources) : {tables: []};

            if (fakeLoading) {
                return (
                    <div className="fake-loading-data-sources">
                        <LoadingIndicator />
                    </div>
                );
            }

            const CurrentTabComp = activeTab == "all" ? AllDataSourcesTab : UsedInModelTab;

            return CurrentTabComp({
                activeTab,
                autoSuggestTables: tables,
                model,
                dataSources,
                dataSourceModelContext,
                chart,
                searchValue: search,
                openTablePanel,
                openColumnPanel,
                removeTableService,
                shortestPossiblePathDialog,
                autoSuggest,
                changeToTab: (tabName) => {
                    state.change((s) => ({...s, activeTab: tabName, fakeLoading: true}));
                    setTimeout(() => spc(state, ["fakeLoading"], () => false), 500);
                },
            });
        }
    );

const AllDataSourcesTab = (props) => {
    const {dataSourceModelContext, searchValue} = props;
    const searchedDataSources = dataSourceModelContext.getEntities("dataSources");
    const modelTables = dataSourceModelContext.getEntities("modelTables", {isSearchResults: false});
    const dataViews = modelTables.filter(
        (modelTable) =>
            modelTable.$type == "ViewModelTable" && (searchValue ? modelTable.ranges?.length > 0 || modelTable.children?.length > 0 : true)
    );

    if (!!searchValue && searchedDataSources.length == 0 && dataViews.length == 0) {
        return (
            <div className="no-data-sources">
                <img src={NoFieldFoundImg} alt="no field found" />
                <div className="text">No fields matched your search</div>
            </div>
        );
    }

    return (
        <>
            {DataSourcesMenu({
                ...props,
                usedInModel: false,
            })}
            {(!!searchValue ? dataViews.length > 0 : true) &&
                DataViewsMenu({
                    ...props,
                    tooltipText: "Data Views are bound to a model. These Data Views are only available to be used on this model.",
                })}
        </>
    );
};
const UsedInModelTab = (props) => {
    const {dataSourceModelContext, changeToTab, searchValue} = props;

    const modelTables = dataSourceModelContext.getEntities("modelTables", {isSearchResults: false});
    const usedDataSourceIDs = unique(
        modelTables.filter((t) => t.$type == "DataSourceModelTable").map((modelTable) => modelTable.dataSourceID)
    );
    const searchedDataSources = dataSourceModelContext.getEntities("dataSources", {
        filterFn: (dataSource) => usedDataSourceIDs.includes(dataSource.id),
    });
    const dataViews = modelTables.filter(
        (modelTable) =>
            modelTable.$type == "ViewModelTable" && (searchValue ? modelTable.ranges?.length > 0 || modelTable.children?.length > 0 : true)
    );

    if (!!searchValue && searchedDataSources.length == 0 && dataViews.length == 0) {
        return (
            <div className="no-data-sources">
                <img src={NoFieldFoundImg} alt="no field found" />
                <div className="text">No fields matched your search</div>
                <a onClick={() => changeToTab("all")}>Try searching all data sources instead?</a>
            </div>
        );
    }

    return (
        <>
            {(!!searchValue ? searchedDataSources.length > 0 : true) &&
                DataSourcesMenu({
                    ...props,
                    usedInModel: true,
                })}
            {(!!searchValue ? dataViews.length > 0 : true) && DataViewsMenu(props)}
        </>
    );
};
