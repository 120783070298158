import "./gauge-chart.scss";

import {cx} from "emotion";
import * as React from "react";

import {cs} from "@common/react/chain-services";
import {Interval} from "@common/react/interval";
import {UseState} from "@common/react/use-state";
import {consumeContext} from "@common/react/context";
import {IgnoreUpdate} from "@common/react/ignore-update";
import {Watch} from "@common/react/watch";
import {keepOnly} from "@common/utils/objects";

import {ChartInnerLayout} from "../chart-layout/chart-inner-layout";

import {ActionMenu} from "../common/action-menu/action-menu";
import {MenuOptions} from "../common/menu-options/menu-options";
import {isDataValid, loadTileData} from "../common/load-tile-data";
import {NoDataScreen} from "../common/no-data-screen/no-data-screen";

import {loadTileFields} from "../get-field-color";
import {RenderChart} from "../render-chart/render-chart";

import {getGaugeChartOptions} from "./chart-options/get-gauge-chart-options";
import {getValueFormatters} from "./get-value-formatters";
import {CurrencyConversionWarning, getCurrencyConversion} from "../common/currency-conversion-warning/currency-conversion-warning";

export const GaugeChart = ({
    tile,
    tileFilters,
    theme,
    loadData,
    downloadData,
    size,
    chartRef,
    defaultData,
    overrideTile,
    disabledTileActions,
}) =>
    cs(
        [
            "data",
            ({}, next) =>
                defaultData
                    ? next(defaultData)
                    : loadTileData({
                          next,
                          tileFilters,
                          loadData,
                          size,
                          tile,
                          theme,
                          tileKey: JSON.stringify(keepOnly(tile, ["valueField", "targetValueField", "targetValueConstant", "filters"])),
                      }),
        ],
        consumeContext("selectedTimezone"),

        [
            "tileFields",
            ({}, next) =>
                loadTileFields({
                    next,
                    configs: {
                        tile,
                        measureSingleAttrs: ["valueField", "targetValueField"],
                    },
                }),
        ],

        ({data, chartOuterLayout}, next) => (!isDataValid(data) ? NoDataScreen({size, theme}) : next()),
        [
            "actionMenu",
            ({formatter}, next) =>
                ActionMenu({
                    tile,
                    overrideTile,
                    disabledTileActions,
                    next,
                }),
        ],

        [
            "onClickPoint",
            ({actionMenu, data}, next) => {
                if (!actionMenu.hasActions()) {
                    return next();
                }
                return next((other) => {
                    const {valueField, targetValueField} = tile;
                    const fieldToValue = {};

                    if (valueField) {
                        fieldToValue[valueField.id] = data.value;
                    }

                    if (targetValueField) {
                        fieldToValue[targetValueField.id] = data.target;
                    }

                    const tileActionData = other.row?.tileActionData;

                    tileActionData?.columns.forEach((c, index) => {
                        fieldToValue[c.tileFieldID] = tileActionData.data[index];
                    });

                    actionMenu.show({
                        ...other,
                        fieldToValue,
                    });
                });
            },
        ],

        [
            "controls",
            ({data, tileFields}, next) => {
                const hasMenuOptions = tile.style.showDownloadData || tile.style.showDownloadImage;

                // if (!hasMenuOptions) {
                //     return next(null);
                // }

                return next(
                    <div className="controls">
                        {CurrencyConversionWarning({
                            theme,
                            currencyConversion: getCurrencyConversion({
                                $type: data.$type,
                                data,
                                tileFields,
                            }),
                        })}

                        {hasMenuOptions &&
                            MenuOptions({
                                chartRef,
                                theme,
                                tile,
                                downloadData,
                                tileFilters,
                            })}
                    </div>
                );
            },
        ],

        [
            "innerSize",
            ({data, controls}, next) =>
                ChartInnerLayout({
                    size,
                    tile,
                    theme,
                    next,
                    noData: !isDataValid(data),
                    hasControls: !!controls,
                }),
        ],
        [
            "drawableSpace",
            ({innerSize}, next) => {
                if (tile.style.valueLabelsShown) {
                    return UseState({next, initValue: innerSize});
                }
                return next({value: innerSize});
            },
        ],
        ({selectedTimezone}, next) =>
            IgnoreUpdate({
                next,
                props: {timezoneId: selectedTimezone?.value},
                when: ({timezoneId}) => timezoneId != selectedTimezone?.value,
            }),
        ({data, actionMenu, controls, selectedTimezone, onClickPoint, innerSize, drawableSpace}) => {
            const hasActions = actionMenu?.hasActions?.();
            const formatters = getValueFormatters({
                valueField: tile.valueField,
                targetValueField: tile.targetValueField,
                targetValueConstant: tile.targetValueConstant,
                timezone: selectedTimezone.value,
                tile,
            });

            const options = getGaugeChartOptions({
                tile,
                theme,
                data,
                formatters,
                innerSize: drawableSpace.value,
                tileSize: size,
            });
            const showValueLabels = tile.style.valueLabelsShown;

            return (
                <div
                    className="gauge-chart-0zd"
                    key={getRefreshKey({
                        tile,
                        tileFiltersValue: tileFilters.getValue(),
                    })}
                >
                    <div
                        // className="chart"
                        className={cx("chart", hasActions && "has-actions")}
                        onClick={(e) =>
                            onClickPoint?.({
                                x: e.clientX,
                                y: e.clientY,
                                noFilter: true,
                                row: {
                                    ...data,
                                    value: formatters.valueFormatter(data.value),
                                    rawValue: data.value,
                                },
                                // fieldName: row.name,
                            })
                        }
                    >
                        {showValueLabels && (
                            <>
                                {Interval({
                                    delay: 1000,
                                    fn: () => {
                                        const {width, height} = drawableSpace.value;
                                        const elem = chartRef.get().chart.container.querySelector(".highcharts-plot-background");
                                        const highchartsPlotArea = elem.getBoundingClientRect();

                                        // if (Math.abs(highchartsPlotArea.height - height) > 1 || Math.abs(highchartsPlotArea.width - width) > 1) {
                                        if (highchartsPlotArea.width < width) {
                                            drawableSpace.onChange({
                                                width: highchartsPlotArea.width,
                                                height: highchartsPlotArea.height,
                                            });
                                        }
                                    },
                                })}
                                {Watch({
                                    value: size,
                                    onChanged: () => {
                                        const {width, height} = drawableSpace.value;
                                        const elem = chartRef.get().chart.container.querySelector(".highcharts-plot-background");
                                        const highchartsPlotArea = elem.getBoundingClientRect();

                                        if (
                                            Math.abs(highchartsPlotArea.height - height) > 1 ||
                                            Math.abs(highchartsPlotArea.width - width) > 1
                                        ) {
                                            // if (highchartsPlotArea.width < width) {
                                            drawableSpace.onChange({
                                                width: highchartsPlotArea.width,
                                                height: highchartsPlotArea.height,
                                            });
                                        }
                                    },
                                })}
                            </>
                        )}

                        <RenderChart
                            {...{
                                chartRef,
                                tile,
                                options,
                                size,
                                theme,
                                formatters,
                            }}
                        />
                    </div>
                    {controls}
                </div>
            );
        }
    );

const getRefreshKey = ({tile, tileFiltersValue}) => JSON.stringify([keepOnly(tile, ["id", "style", "filters"]), tileFiltersValue]);
