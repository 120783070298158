import {cx} from "emotion";

import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {
    CurrencyConversionWarning,
    getCurrencyConversion,
} from "@common/ui-components/charts/common/currency-conversion-warning/currency-conversion-warning";
import {consumeContext} from "@common/react/context";
import {rawDataManipulator} from "@common/ui-components/charts/raw-data-manipulator/raw-data-manipulator";
import {isDataValid} from "@common/ui-components/charts/common/load-tile-data";
import {NoDataScreen} from "@common/ui-components/charts/common/no-data-screen/no-data-screen";
import {isDimRealDateTime} from "@common/ui-components/charts/factory/is-dim-real-date-time";
import {rawDataProcessor} from "@common/ui-components/charts/data-processor/raw-data-processor";
import {groupSeriesByStackLine} from "@common/ui-components/charts/line-series-processors/group-series-by-stack-line";
import {getMeasurementAxesOptions} from "@common/ui-components/charts/factory/measurement-axes/measurement-axes";
import {getDimensionAxisOptions} from "@common/ui-components/charts/factory/dimension-axis/dimension-axis";
import {BlankDateWarning} from "@common/ui-components/charts/common/blank-date-warning";
import {ChartInnerLayout} from "@common/ui-components/charts/chart-layout/chart-inner-layout";
import {IgnoreUpdate} from "@common/react/ignore-update";
import {getLineChartOptions} from "@common/ui-components/charts/line/chart-options/get-line-chart-options";
import {flatten1} from "@common/utils/collections";
import {keepOnly} from "@common/utils/objects";
import {RenderChart} from "@common/ui-components/charts/render-chart/render-chart";
import {getFormatters} from "@common/ui-components/charts/factory/formatters/formatters";
import {isComparing} from "@common/ui-components/charts/factory/check-compare";
import {groupCompareBarSeries} from "@common/ui-components/charts/bar-series-processors/group-compare-bar-series";
import {groupNonCompareBarSeries} from "@common/ui-components/charts/bar-series-processors/group-non-compare-bar-series";
import {getVerticalBarOptions} from "@common/ui-components/charts/vertical-bar/chart-options/get-vertical-bar-options";

export const SparkLineChart = ({tile, chartData, tileFields, tileFilters, theme, size, chartRef}) =>
    cs(
        consumeContext("sdkStaticUrl"),

        [
            "localTile",
            (_, next) => {
                const yAxisField = tile.yAxisField?.measureFields?.[0];

                if (!yAxisField) {
                    return null;
                }

                return next({
                    ...tile,
                    style: {
                        ...tile.style,
                        yAxis: {
                            [yAxisField.id]: tile.style.yAxis,
                        },
                    },
                    yAxisField: [tile.yAxisField],
                });
            },
        ],

        ["rawData", ({localTile}, next) => next(rawDataManipulator({tile: localTile, rawData: chartData}))],
        ({rawData}, next) => (!isDataValid({...rawData, $type: "ChartTileData"}) ? NoDataScreen({size, theme}) : next()),
        ["isCompare", ({rawData}, next) => next(isComparing(rawData, tile))],
        [
            "isDimensionRealDate",
            ({rawData}, next) =>
                next(
                    isDimRealDateTime({
                        tile,
                        dimensionAttr: "xAxisField",
                        series: rawData.series,
                    })
                ),
        ],

        [
            "rawData2",
            ({isCompare, localTile, rawData, isDimensionRealDate}, next) =>
                next(
                    rawDataProcessor({
                        tile: localTile,
                        rawData,
                        isDimensionRealDate,
                        isCompare,
                    })
                ),
        ],

        [
            "formatters",
            ({localTile, rawData2, selectedTimezone}, next) =>
                next(
                    getFormatters({
                        tile: localTile,
                        dimensionAttr: "xAxisField",
                        measurementAttrs: ["yAxisField"],
                        rawData: rawData2,
                        timezone: selectedTimezone?.value,
                    })
                ),
        ],

        [
            "data",
            ({localTile, isCompare, rawData2, tooltipOptions, formatters}, next) => {
                const mapFunc = {
                    Area: groupSeriesByStackLine,
                    Line: groupSeriesByStackLine,
                    Bar: isCompare ? groupCompareBarSeries : groupNonCompareBarSeries,
                };

                return next(
                    mapFunc[tile.style.sparkLineChartType]({
                        series: rawData2.series,
                        tile: localTile,
                        theme,
                        tooltipOptions: null,
                        tileFields,
                        measurementFormatters: formatters.measurementFormatters,
                    })
                );
            },
        ],

        [
            "yAxisKey",
            ({data}, next) =>
                UseState({
                    next: ({value, onChange}) =>
                        next({
                            value: value == null || data[value] == null ? Object.keys(data)[0] : value,
                            onChange,
                        }),
                }),
        ],

        [
            "yAxisOptions",
            ({localTile, formatters, isCompare, data, rawData2, referenceSeries}, next) => {
                return next(
                    getMeasurementAxesOptions({
                        tile: localTile,
                        theme,
                        data,
                        rawData: rawData2,
                        referenceSeries,
                        isCompare,
                        styleMeasurementAttr: "yAxis",
                        measurementFormatters: formatters.measurementFormatters,
                    })
                );
            },
        ],

        [
            "xAxisOptions",
            ({localTile, formatters, rawData2, data, yAxisKey, isCompare}, next) =>
                next(
                    getDimensionAxisOptions({
                        tile: localTile,
                        theme,
                        rawSeries: rawData2.series.filter((s) => s.stack === yAxisKey.value),
                        series: data[yAxisKey.value].series,
                        isCompare,
                        dimensionAttr: "xAxisField",
                        styleDimensionAttr: "xAxis",
                        dimensionFormatter: formatters.dimensionFormatter,
                    })
                ),
        ],

        [
            "controls",
            ({localTile, data, yAxisKey, rawData}, next) =>
                next(
                    <div className="controls">
                        {CurrencyConversionWarning({
                            currencyConversion: getCurrencyConversion({
                                $type: rawData.$type,
                                data: rawData,
                                tileFields,
                            }),
                            theme,
                        })}

                        {BlankDateWarning({
                            isDimensionRealDate: isDimRealDateTime({
                                tile: localTile,
                                dimensionAttr: "xAxisField",
                                series: rawData.series,
                                noLimit: true,
                            }),
                            filters: tileFilters.getValue(),
                        })}
                    </div>
                ),
        ],

        ({rawData, localTile, data, controls}, next) =>
            ChartInnerLayout({
                size,
                tile: localTile,
                theme,
                next,
                noData: !isDataValid({...rawData, $type: "ChartTileData"}),
                hasControls: !!controls,
                noPadding: true,
            }),

        ({selectedTimezone}, next) =>
            IgnoreUpdate({
                next,
                props: {timezoneId: selectedTimezone?.value},
                when: ({timezoneId}) => timezoneId != selectedTimezone?.value,
            }),

        ({
            data,
            localTile,
            yAxisKey,
            yAxisOptions,
            xAxisOptions,
            referenceSeries,
            isCompare,
            isDimensionRealDate,
            formatters,
            rawData2,
            controls,
        }) => {
            const isBarChart = tile.style.sparkLineChartType == "Bar";
            const isIndependentAxes = tile.style.multipleAxisOption == "IndependentAxes";
            const isAreaLine = !isBarChart && ["Area", "SplineArea"].includes(tile.style.lineChartDisplayType);
            const createColoringSeries = (s, prefix = "fake") => {
                if (tile.style.sparkLineChartType) {
                    return s;
                }

                return {
                    ...s,
                    color: s._baseColor,
                    type: s.type.includes("spline") ? "spline" : "line",
                    ...(s.id && {id: prefix + s.id}),
                    linkedTo: s.linkedTo || s.id,
                    enableMouseTracking: false,
                    label: {enabled: false},
                };
            };

            const mapGetChartOptionsFunc = {
                Area: getLineChartOptions,
                Line: getLineChartOptions,
                Bar: getVerticalBarOptions,
            };

            let options = {
                ...mapGetChartOptionsFunc[tile.style.sparkLineChartType]({
                    tile: localTile,
                    theme,
                    isDimensionRealDate,
                    isCompare,
                    formatters,
                    rawData: rawData2,
                }),
                xAxis: tile.style.displayType.includes("Area")
                    ? !isCompare
                        ? {...xAxisOptions, maxPadding: 0, minPadding: 0}
                        : xAxisOptions?.map((axis) => ({
                              ...axis,
                              maxPadding: 0,
                              minPadding: 0,
                          }))
                    : xAxisOptions,
                yAxis: !isIndependentAxes ? yAxisOptions[yAxisKey.value].options : Object.values(yAxisOptions).map(({options}) => options),

                series: [
                    ...(!isIndependentAxes
                        ? [...data[yAxisKey.value].series, ...(isAreaLine ? data[yAxisKey.value].series.map(createColoringSeries) : [])]
                        : flatten1(
                              Object.values(data).map(({series}, i) =>
                                  series.concat(isAreaLine ? series.map((s) => createColoringSeries(s, `fake_${i}_`)) : [])
                              )
                          )),
                    ...(referenceSeries || []),
                ],
            };

            options.chart.marginBottom = 0;

            console.log(options);

            return (
                <div
                    className={cx("line-chart-533", !tile?.style?.title.show && "hide-title")}
                    key={getRefreshKey({
                        tile: localTile,
                        theme,
                        tileFiltersValue: tileFilters.getValue(),
                        key: yAxisKey.value,
                    })}
                >
                    <div className="chart">
                        <RenderChart
                            {...{
                                chartRef,
                                options,
                                size,
                                theme,
                                tile,
                                formatters,
                            }}
                        />
                    </div>

                    {controls}
                </div>
            );
        }
    );

const getRefreshKey = ({tile, theme, tileFiltersValue, key}) =>
    JSON.stringify([
        keepOnly(tile, ["id", "style", "filters", "limit", "sort", "categorySort"]),
        theme.dataVisualization.toolTipsAndLegends,
        key,
        tileFiltersValue,
    ]);
