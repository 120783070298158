import "./contextual-panel.scss";

import {cx} from "emotion";
import * as React from "react";
import ReactDOM from "react-dom";

import {Load} from "@common/react/load";
import {scope} from "@common/react/scope";
import {Static2} from "@common/react/static-2";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {consumeContext} from "@common/react/context";

import {LoadingIndicator} from "@common/ui-components/loading-indicator/loading-indicator";
import {LiveDashboard} from "@common/ui-components/live/live-dashboard/live-dashboard";
import {isInsideBox} from "../../../../../collection/common/dnd-grid-panel/dnd-common";
import {ContextualDraggingNewTile} from "./contextual-dragging-new-tile";
import {Invoke} from "@common/react/invoke";
import {recentStorage} from "../../../../../dashboard/env/recent-section/recent-storage";

export const ContextualPanel = ({collection, next}) =>
    cs(["draggingState", (_, next) => UseState({next})], ({draggingState}) => {
        return next({
            startDraggingNewTile: ({tile, pos}) => draggingState.onChange({draggingNew: {tile, pos}}),
            renderMain: () =>
                cs(
                    consumeContext("apis"),
                    consumeContext("routing"),
                    consumeContext("tutorialBoxRef"),
                    [
                        "theme",
                        ({apis}, next) => {
                            const themeId = collection.value?.themeID;
                            return Load({
                                _key: themeId,
                                fetch: () => themeId && apis.collection.getTheme(themeId),
                                next,
                            });
                        },
                    ],
                    ["boxRef", (_, next) => Static2({next})],
                    ({theme}, next) =>
                        !collection.value || !theme
                            ? LoadingIndicator({
                                  className: "loading-collection-46s",
                              })
                            : next(),
                    ({theme, boxRef, tutorialBoxRef, routing}) => {
                        if (!collection.value.tile) {
                            const inside =
                                draggingState.value &&
                                isInsideBox(draggingState.value.draggingNew.pos, boxRef.get().getBoundingClientRect(), 50);
                            const background = theme?.general.canvas.backgroundColorRGB;

                            return (
                                <div className="default-panel-a33" style={{background}}>
                                    <div
                                        className={cx("default-tiles", inside && "inside")}
                                        ref={boxRef.set}
                                        style={{
                                            width: collection.value.viewWidthPixels,
                                            height: collection.value.viewHeightPixels,
                                        }}
                                    >
                                        Drag a tile type here to start
                                    </div>

                                    {draggingState.value?.draggingNew && (
                                        <ContextualDraggingNewTile
                                            tile={draggingState.value.draggingNew.tile}
                                            draggingState={scope(draggingState, ["draggingNew", "pos"])}
                                            onDrop={() => {
                                                draggingState.onChange(null);
                                                if (inside) {
                                                    collection.change((c) => ({
                                                        ...c,
                                                        tile: draggingState.value.draggingNew.tile,
                                                    }));
                                                }
                                            }}
                                        />
                                    )}

                                    {tutorialBoxRef.get?.() &&
                                        !draggingState.value?.draggingNew &&
                                        (routing.params.tab == "collection" || !routing.params.tab) &&
                                        ReactDOM.createPortal(
                                            <div
                                                className="adding-tile-tutorial"
                                                style={{
                                                    top: 290,
                                                }}
                                            >
                                                <div className="box">Drag a tile onto the canvas to begin</div>
                                            </div>,
                                            tutorialBoxRef.get()
                                        )}
                                </div>
                            );
                        }

                        return LiveDashboard({
                            isEditTile: true,
                            theme,
                            collection: collection.value,
                        });
                    }
                ),
        });
    });
