import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {Load2} from "@common/react/load2";
import {UseState, UseState1} from "@common/react/use-state";
import {DialogService} from "../../../../common/dialog/dialog-service";
import {SelectFolderService} from "../../collections/folder-cm/select-folder-service/select-folder-service";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";
import {capitalize} from "@common/utils/strings";
import {Button} from "@common/form/buttons/button/button";
import {getPath} from "@common/utils/arr-path";

// support update one or many items
// params: can be one item or many items
export const MoveToDialog = ({label: defaultLabel, folders, onDone, apiType, next: rootNext}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    render: ({resolve, args: {params, isUpdateMany, label}}) => {
                        const title = `Move ${
                            label ??
                            defaultLabel ??
                            capitalize(apiType)
                                .replace(/([a-z])([A-Z])/g, "$1 $2")
                                .trim()
                        }`;

                        return {
                            isUpdateMany,
                            title,
                            width: 540,
                            content: next({
                                resolve,
                                params,
                                isUpdateMany,
                                label,
                                title,
                            }),
                        };
                    },
                    next: rootNext,
                }),
        ],

        consumeContext("apis"),

        [
            "remoteFolders",
            ({apis}, next) =>
                folders
                    ? next(folders)
                    : Load2({
                          fetch: () => apis[apiType].getFolders(),
                          next,
                      }),
        ],

        [
            "destination",
            ({modal}, next) =>
                UseState({
                    initValue: modal.isUpdateMany ? null : modal.params?.folderID ?? null,
                    next,
                }),
        ],

        [
            "selectFolderService",
            ({destination, remoteFolders, modal}, next) =>
                SelectFolderService({
                    apiType,
                    state: destination,
                    label: `${modal.title} to`,
                    folders: folders ?? remoteFolders.value,
                    next,
                }),
        ],

        ["submitting", (_, next) => UseState1({next})],

        ({destination, modal, selectFolderService, submitting, apis}) => {
            const {isUpdateMany, params} = modal;

            return (
                <div className="move-collection-dialog-8we">
                    <VerbDialogBodyScrollbar>
                        <div className="main-part">{selectFolderService.render()}</div>
                    </VerbDialogBodyScrollbar>

                    <div className="buttons">
                        <Button btnType="secondary" onClick={() => modal.resolve(false)}>
                            Cancel
                        </Button>
                        <Button
                            disabled={submitting.value || !selectFolderService.valid}
                            onClick={async () => {
                                submitting.onChange(true);
                                const apiFunc = getPath(apis, [apiType, "changeFolder"]);
                                let folderID = destination.value;

                                if (selectFolderService.selectNewFolder) {
                                    try {
                                        const newFolder = await selectFolderService.selectNewFolder();
                                        folderID = newFolder.id;
                                    } catch (error) {
                                        submitting.onChange(false);
                                        return;
                                    }
                                }

                                const doUpdate = async (item) => {
                                    if (selectFolderService.selectNewFolder) {
                                        await apiFunc(folderID, item.id);
                                    } else {
                                        if (destination.value !== item.folderID) {
                                            await apiFunc(folderID, item.id);
                                        }
                                    }
                                };

                                if (isUpdateMany) {
                                    await Promise.all(params.map((item) => doUpdate(item)));
                                } else {
                                    await doUpdate(params);
                                }

                                const newFolders = await apis[apiType].getFolders();
                                onDone?.(newFolders);
                                submitting.onChange(false);
                                return modal.resolve(true);
                            }}
                        >
                            Move
                        </Button>
                    </div>
                </div>
            );
        }
    );
