import "./map-tile.scss";

import React from "react";
import {cx} from "emotion";
import ReactDOM from "react-dom";

import {keyed} from "../../../react/keyed";
import {Invoke} from "../../../react/invoke";
import {cs} from "../../../react/chain-services";
import {UseState} from "../../../react/use-state";
import {spc} from "../../../react/state-path-change";
import {consumeContext} from "../../../react/context";

import {getMapOptions} from "./options/get-map-options";
import {keepOnly} from "../../../utils/objects";
import {last, unique} from "../../../utils/collections";

import {RenderMapChart} from "./render-map-chart";
import {getFirstStep, getMapSeries, getMapSeriesName, addMapJoinKey, getMapLocationMatchIndex, isDrillingAllowed2, getDrillingStepProps2} from "./map-helper";
import {ChartInnerLayout} from "../chart-layout/chart-inner-layout";
import {LoadingSkeleton} from "../common/loading-wrapper/loading-skeletion";
import {SwapAxis} from "../common/swap-axis/swap-axis";
import {MenuOptions} from "../common/menu-options/menu-options";
import {getMapTileFormatters} from "./get-map-tile-formatters";
import {MapTracker} from "./trackers/map-tracker";
import {loadMapTileData} from "./load-map-tile-data";
import {LoadingIndicator} from "../../loading-indicator/loading-indicator";
import {ActionMenu} from "../common/action-menu/action-menu";
import {loadTileFields} from "../get-field-color";
import {RemoveTileControlFilter} from "../common/control-filter/remove-tile-control-filter";

export const MapTile = ({tile, theme, isEditTile, tileFilters, loadData, size, downloadData, defaultData, chartRef, headerCenterSlotRef, headerRightSlotRef, overrideTile, disabledTileActions}) =>
    cs(
        consumeContext("tileActionControlFilter"),
        ["measureKeys", ({}, next) => next(unique(tile.valueFields.map((vf) => vf.id)))],

        [
            "measureKey",
            ({measureKeys}, next) =>
                UseState({
                    initValue: measureKeys[0],
                    next,
                }),
        ],
        ["mapTileDataState", (_, next) => UseState({next})],
        // re-render the drillingTracker and whole tile when defaultLocation or region or fields or filters change
        keyed(JSON.stringify([tile.style.defaultLocation, tile.style.region, keepOnly(tile, ["countryField", "countryValueConstant", "provinceField", "countyField", "postalCodeField", "valueFields", "groupField", "filters", "limit", "sort", "categorySort"]), tileFilters.getValue()])),
        [
            "drillingTracker",
            (_, next) =>
                UseState({
                    initValue: {
                        steps: null, // [{country: "USA", name: "United States"}, {country: "USA", province: "OH", name: "Ohio"}]
                        autoSteps: null, // temporarily store the first step here. any drillings later will be stored in "steps".
                        firstLoadDone: false, // at first load, use loading map skeleton. any drilling loading after the first load uses loading overlay.
                    },
                    next,
                }),
        ],

        [
            "mapTileData",
            ({drillingTracker, mapTileDataState, tileActionControlFilter}, next) => {
                const lastStep = drillingTracker.value.steps && last(drillingTracker.value.steps);
                const {tile: currentTileUseControlFilter, action} = tileActionControlFilter?.value || {};
                let tileKey = JSON.stringify([lastStep, tile.style.defaultLocation, tile.style.region, keepOnly(tile, ["countryField", "countryValueConstant", "provinceField", "countyField", "postalCodeField", "valueFields", "groupField", "filters", "limit", "sort", "categorySort"])]);

                return defaultData
                    ? next(defaultData)
                    : tileKey == mapTileDataState.value?.tileKey && currentTileUseControlFilter?.id == tile.id && action?.value
                    ? next(mapTileDataState.value)
                    : loadMapTileData({
                          next,
                          size,
                          tile,
                          theme,
                          mapTileDataState,
                          isEditTile,
                          loadData,
                          tileFilters,
                          tableOverrides: {
                              tableSearches: [
                                  {
                                    $type: "TextTableSearch",
                                    columnIndex: 0,
                                    search: lastStep?.country,
                                  },
                                  {
                                    $type: "TextTableSearch",
                                    columnIndex: 1,
                                    search: lastStep?.province,
                                  },
                                  {
                                    $type: "TextTableSearch",
                                    columnIndex: 2,
                                    search: lastStep?.county,
                                  },
                                  {
                                    $type: "TextTableSearch",
                                    columnIndex: 3,
                                    search: lastStep?.postalCode,
                                  },
                                ],
                          },
                          tileKey,
                          keepOutdatedValue: true,
                      });
            },
        ],

        [
            "controls",
            ({measureKey, measureKeys, tileActionControlFilter}, next) => {
                const hasMenuOptions = tile.style.showDownloadData || tile.style.showDownloadImage;
                const hasAlternateMeasures = measureKeys.length > 1;
                const hasRemoveControlFilter = tileActionControlFilter.value?.tile?.id == tile.id;

                if (!hasMenuOptions && !hasAlternateMeasures && !hasRemoveControlFilter) {
                    return next(null);
                }

                return next(
                    <div className={cx("controls", hasMenuOptions && hasAlternateMeasures ? "multi" : "single")}>
                        {RemoveTileControlFilter({
                            tile,
                            hideBorder: !hasAlternateMeasures && !hasMenuOptions,
                        })}

                        {hasMenuOptions &&
                            MenuOptions({
                                chartRef,
                                theme,
                                tile,
                                downloadData,
                                tileFilters,
                            })}

                        {hasAlternateMeasures &&
                            SwapAxis({
                                theme,
                                tile,
                                list: [
                                    {
                                        choices: measureKeys,
                                        onSelect: (k) => measureKey.onChange(k),
                                        isSelected: (k) => measureKey.value === k,
                                        valueToLabel: (k) => getMapSeriesName(tile, k),
                                    },
                                ],
                            })}
                    </div>
                );
            },
        ],

        ({controls}, next) =>
            ChartInnerLayout({
                size,
                tile,
                theme,
                next,
                noData: false,
                hasControls: !!controls,
            }),

        [
            "tileFields",
            ({}, next) =>
                loadTileFields({
                    next,
                    configs: {
                        tile,
                        measureGroupAttrs: ["valueFields"],
                        measureSingleAttrs: ["countryField", "provinceField", "countyField", "postalCodeField"],
                        groupFieldAttr: "groupField",
                        checkUnique: false,
                    },
                }),
        ],

        [
            "formatters",
            ({mapTileData}, next) =>
                next(
                    mapTileData?.tileData
                        ? getMapTileFormatters({
                              tile,
                              mapName: mapTileData?.tileData?.mapGeoJson,
                          })
                        : null
                ),
        ],

        [
            "actionMenu",
            ({formatters}, next) =>
                ActionMenu({
                    tile,
                    overrideTile,
                    dimensionFormatter: formatters?.dimensionFormatter,
                    disabledTileActions,
                    next,
                }),
        ],

        [
            "onClickPoint",
            ({actionMenu, drillingTracker, tileFields}, next) => {
                const disabledDrilling = !!defaultData;

                const onDrill = (keyObj) => {
                    if (disabledDrilling) {
                        return;
                    }
                    drillingTracker.onChange({
                        ...drillingTracker.value,
                        firstLoadDone: true,
                        steps: [...(drillingTracker.value.steps || drillingTracker.value.autoSteps), keyObj],
                    });
                };
                return next((props) => {
                    const {point, ...other} = props;

                    const {properties: mapLocProperties, options: dataOptions} = point;

                    const canDrillDown = isDrillingAllowed2({
                        mapLocProperties,
                        dataOptions,
                        tile,
                    });

                    const location = getDrillingStepProps2({
                        mapLocProperties,
                        dataOptions,
                    });

                    const {country, province, county, postalCode} = mapLocProperties;

                    let fieldName = null;

                    if (country) {
                        fieldName = tile.countryField.displayName;
                    }
                    if (country && province) {
                        fieldName = tile.provinceField.displayName;
                    }
                    if (country && province && county) {
                        fieldName = tile.countyField.displayName;
                    }
                    if (country && province && county && postalCode) {
                        fieldName = tile.postalCodeField.displayName;
                    }

                    if (!actionMenu.hasActions()) {
                        location && onDrill(location);
                        return;
                    }

                    let fieldToValue = {};

                    if (location && tile.countryField) {
                        fieldToValue[tile.countryField.id] = location.country;
                    }

                    if (location && tile.provinceField) {
                        fieldToValue[tile.provinceField.id] = location.province;
                    }

                    if (location && tile.countyField) {
                        fieldToValue[tile.countyField.id] = location.county;
                    }

                    if (location && tile.postalCodeField) {
                        fieldToValue[tile.postalCodeField.id] = location.postalCode;
                    }

                    const tileActionData = point?.tileActionData;

                    tileActionData?.columns.forEach((c, index) => {
                        fieldToValue[c.tileFieldID] = tileActionData.data[index];
                    });

                    const {steps} = drillingTracker.get();
                    const previousStep = steps ? steps[Math.max(steps.length - 1 - 1, 0)] : null;

                    const mapActions = [
                        {
                            label: previousStep ? (
                                <span>
                                    Return to <b>{previousStep.isGlobal ? "Global" : previousStep.name}</b> Map
                                </span>
                            ) : null,
                            function: () => {
                                const stepIndex = steps.length - 1;
                                drillingTracker.onChange({
                                    ...drillingTracker.value,
                                    steps: steps.slice(0, stepIndex),
                                });
                            },
                            condition: () => steps?.length > 1,
                        },
                        {
                            label: (
                                <span>
                                    View <b>{location?.name}</b> Map
                                </span>
                            ),
                            function: () => canDrillDown && location && onDrill(location),
                            condition: () => canDrillDown && !!location,
                        },
                    ].filter((a) => a.condition());

                    actionMenu.show({
                        point: {
                            ...point,
                            name: location.name,
                        },
                        ...other,
                        fieldToValue,
                        fieldName,
                        additionalActions: mapActions.length > 0 && {
                            sectionName: "Map Action",
                            actions: mapActions,
                        },
                    });
                });
            },
        ],

        ({drillingTracker, mapTileData, controls, measureKey, formatters, onClickPoint}) => {
            return (
                <>
                    <div className={cx("map-tile-fr3", !tile?.style?.title.show && "hide-title")}>
                        <div className="chart">
                            {cs(
                                // to prevent stale drillingTracker value
                                keyed(JSON.stringify([drillingTracker.value.steps && last(drillingTracker.value.steps), drillingTracker.value.autoSteps])),
                                ({}) =>
                                    renderMap({
                                        tile,
                                        theme,
                                        size,
                                        chartRef,
                                        formatters,
                                        measureKey,
                                        drillingTracker,
                                        mapTileData,
                                        disabledDrilling: !!defaultData,
                                        onClickPoint,
                                    })
                            )}

                            {mapTileData.loading && (
                                <div className="loading-map">
                                    {drillingTracker.value.firstLoadDone ? (
                                        <div className="loading-overlay">
                                            <LoadingIndicator />
                                        </div>
                                    ) : (
                                        <LoadingSkeleton animated={!!loadData.load} tile={tile} theme={theme} />
                                    )}
                                </div>
                            )}
                        </div>

                        {renderTrackerAndControls({
                            controls,
                            drillingTracker,
                            headerCenterSlotRef,
                            headerRightSlotRef,
                        })}
                    </div>

                    {/* save the very first step to autoSteps */}
                    {((!drillingTracker.value.steps && !mapTileData.loading) || !!defaultData) &&
                        Invoke({
                            fn: () => {
                                // since not all maps have name, use mapGeoJson from tile data instead.
                                const step = getFirstStep({
                                    tile,
                                    mapGeoJson: mapTileData.tileData.mapGeoJson,
                                });
                                spc(drillingTracker, ["autoSteps"], () => [step]);
                            },
                        })}
                </>
            );
        }
    );

const renderMap = ({tile, theme, size, formatters, chartRef, measureKey, drillingTracker, mapTileData, disabledDrilling, onClickPoint}) => {
    if (!mapTileData.tileData || !mapTileData.mapData) {
        return;
    }

    const chartData = {
        ...mapTileData.tileData,
        values: mapTileData.tileData.values.filter((l) => l.measureGroup === measureKey.value),
    };

    const matchIndex = getMapLocationMatchIndex({
        chartData,
        mapData: mapTileData.mapData,
    });
    const series = getMapSeries({
        tile,
        theme,
        chartData,
        mapData: mapTileData.mapData,
        matchIndex,
    });

    const mapOptions = {
        ...getMapOptions({
            map: addMapJoinKey(mapTileData.mapData, matchIndex),
            chartData,
            series,
            matchIndex,
            tile,
            theme,
            formatters,
            onClickPoint,
        }),
        series,
        matchIndex,
    };

    return cs(
        keyed(
            getRefreshKey({
                measureKey: measureKey.value,
                mapName: chartData.mapGeoJson, // need this or on drilling a blank map due to empty data won't show
                tile,
            })
        ),
        ({}) => (
            <RenderMapChart
                {...{
                    options: mapOptions,
                    chartRef,
                    tile,
                    theme,
                    size,
                    formatters,
                }}
            />
        )
    );
};

const getRefreshKey = ({measureKey, mapName, tile}) =>
    JSON.stringify([
        measureKey, // to re-render legend upon changing measure
        mapName,
        tile.style.legendStyle,
        tile.style.applyColorBy, // to re-render legend
        tile.style.showMapControls, // to bring the map to initial state if it has been zoomed or moved
    ]);

const renderTrackerAndControls = ({controls, drillingTracker, headerCenterSlotRef, headerRightSlotRef}) => {
    return (
        <>
            {ReactDOM.createPortal(MapTracker({drillingTracker}), headerCenterSlotRef.get())}

            {ReactDOM.createPortal(controls, headerRightSlotRef.get())}
        </>
    );
};
