import "./sdk-keys.scss";

import React from "react";

import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {Load2} from "@common/react/load2";
import {DataTable} from "../../../../common/data-table/data-table";
import {sort} from "@common/utils/collections";
import {LoadingIndicator} from "@common/ui-components/loading-indicator/loading-indicator";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";
import {CopySettingKey} from "../../../../dashboard/env/settings/commons/copy-setting-key/copy-setting-key";
import {BasicPanel} from "@common/ui-components/panel/panel";

export const SdkKeys = () => (
    <BasicPanel title="SDK API Keys">
        {({height}) =>
            cs(
                consumeContext("apis"),
                [
                    "SDKKeys",
                    ({apis}, next) =>
                        Load2({
                            _key: "sdk-keys",
                            fetch: () => apis.tenant.getSDKKeys(),
                            next,
                        }),
                ],
                ({SDKKeys}) => {
                    if (!SDKKeys.value) {
                        return <VerbScrollbar className="sdk-keys-a23">{LoadingIndicator()}</VerbScrollbar>;
                    }

                    return (
                        <>
                            {DataTable({
                                maxHeight: height,
                                useVerbScrollbar: false,
                                className: "sdk-keys-table-323",
                                list: SDKKeys.value.length == 0 ? [{}] : sort(SDKKeys.value, (k) => k.environmentName),
                                columns: [
                                    {
                                        label: "Environment",
                                        format: (key) => key.environmentName,
                                    },
                                    {
                                        label: "Key",
                                        format: (key) => (
                                            <div className="sdk-key-cell">
                                                {key.id}
                                                {CopySettingKey({
                                                    value: key.id,
                                                    text: "Key",
                                                    toastMessage: "API Key Copied",
                                                })}
                                            </div>
                                        ),
                                    },
                                ],
                            })}
                        </>
                    );
                }
            )
        }
    </BasicPanel>
);
