import {ThumbnailSelect} from "../../../../../../../../../common/thumbnail-select/thumbnail-select";
import {getPath} from "@common/utils/arr-path";
import {CheckboxLine} from "../../../../../../../../../../common/checkbox-line/checkbox-line";
import {scope} from "@common/react/scope";
import * as React from "react";
import {FunnelIcon, PyramidIcon} from "./funnel-chart-display-icons";
import {spc} from "@common/react/state-path-change";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {stateToSelect} from "@common/ui-components/form/state-to-select";

export const Display = ({tile, form}) => ({
    initExpand: true,
    label: "Display",
    render: () => (
        <div className="funnel-chart-section-59f display">
            {ThumbnailSelect({
                list: types,
                isSelected: (v) => getPath(tile.value, ["style", "displayType"]) === v.name,
                onChange: (v) => spc(tile, ["style", "displayType"], () => v.name),
            })}
            {DropdownSelect({
                label: "Funnel Stage Style",
                list: ["Constant", "Proportional"],
                ...stateToSelect(scope(tile, ["style", "stageStyle"])),
            })}
            {CheckboxLine({
                label: "Auto-round values",
                state: scope(tile, ["style", "autoRound"]),
            })}
        </div>
    ),
});

const types = [
    {
        label: "Funnel",
        name: "Funnel",
        icon: <FunnelIcon />,
    },
    {
        label: "Pyramid",
        name: "Pyramid",
        icon: <PyramidIcon />,
    },
];
