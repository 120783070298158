import React from "react";
import {RemoveGuidanceButton} from "./model-tutorial-step-1";
import {cs} from "@common/react/chain-services";
import {CreateCollectionWithEnvDialog} from "../../../../../getting-started/build-data/create-dashboards/create-collection-with-env-dialog";
import {CreateCollectionDialog} from "../../../../../dashboard/env/collections/collection-cm/create-collection/create-collection-dialog";
import {Button} from "../../../../../../../../common/form/buttons/button/button";

export const ModelTutorialStep3 = ({onBack, folders, model}) =>
    cs(
        [
            "createCollectionDialog",
            (_, next) =>
                CreateCollectionDialog({
                    folders: folders.value,
                    model: model,
                    next,
                }),
        ],
        ({createCollectionDialog}) => (
            <div className="step-1">
                <div className="text">After you have created relationships between each of the tables on your canvas, you’re ready to add charts to a collection!</div>

                <div className="step-footer has-margin">
                    <Button iconLeft={<i className="fas fa-chevron-left" />} btnType="border" onClick={onBack}>
                        Previous
                    </Button>

                    <Button onClick={() => createCollectionDialog.show()}>Create Collection</Button>
                </div>

                {RemoveGuidanceButton({})}
            </div>
        )
    );
