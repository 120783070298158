import * as React from "react";
import "../collections/collection-cm/delete-collection/delete-collection-dialog.scss";
import {DialogService} from "../../../common/dialog/dialog-service";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {UseState} from "@common/react/use-state";
import {TextInput} from "@common/form/text-input/text-input";
import {Button} from "@common/form/buttons/button/button";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";

export const DeleteModelDialog = ({onDone, next: rootNext, reloadFolderOnDone = false}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    render: ({resolve, args: {models}}) => ({
                        title: "Delete Model",
                        width: 500,
                        content: next({
                            resolve,
                            models,
                        }),
                    }),
                    next: rootNext,
                }),
        ],
        consumeContext("apis"),
        consumeContext("toast"),
        ["confirmName", (_, next) => UseState({next})],
        ["submitting", (_, next) => UseState({next})],
        ({modal, confirmName, submitting, apis, toast}) => {
            const {models} = modal;

            const numberOfModels = models.length;
            const hasManyModels = numberOfModels > 1;
            const isDisabled = !confirmName.value || confirmName.value.toLowerCase() !== "delete" || submitting.value;
            const message = !hasManyModels ? `Deleting the "${models[0].name}" model cannot be undone.` : `Are you sure you want to delete ${numberOfModels} models?`;

            return (
                <form
                    onSubmit={async (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (isDisabled) {
                            return;
                        }

                        submitting.onChange(true);

                        await Promise.all(
                            models.map((m) =>
                                apis.model.removeModel({
                                    modelId: m.id,
                                    confirmName: m.name,
                                })
                            )
                        );

                        const newFolders = reloadFolderOnDone ? await apis.model.getFolders() : null;
                        onDone(newFolders);

                        toast.show(`${numberOfModels === 1 ? models[0].name : `${numberOfModels} models`} deleted`);

                        submitting.onChange(false);
                        modal.resolve(true);
                    }}
                >
                    <div className="delete-collection-dialog-57a">
                        <VerbDialogBodyScrollbar>
                            <div className="main-part">
                                <div className="warning">{message}</div>

                                {TextInput({
                                    label: (
                                        <span className="confirm-input-label-5sj">
                                            Please type DELETE to confirm
                                            {/*{hasManyModels ? "Please type DELETE to confirm" : "Type the name of the model to confirm deletion."}*/}
                                        </span>
                                    ),
                                    state: confirmName,
                                })}
                            </div>
                        </VerbDialogBodyScrollbar>

                        <div className="buttons">
                            <Button type="button" btnType="secondary" onClick={() => modal.resolve()}>
                                Cancel
                            </Button>
                            <Button disabled={isDisabled} type="submit" btnType="primary-danger">
                                Delete
                            </Button>
                        </div>
                    </div>
                </form>
            );
        }
    );
