import React from "react";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {upperCase1} from "@common/utils/strings";

export const RequestFilterObject = ({filter}) =>
    cs(["open", (_, next) => UseState({next, initValue: true})], ({open}) => {
        const list = [
            {
                name: "Date Filter",
                type: "DateFilter",
                component: FilterDateRange,
            },
            {
                name: "Numeric Filter",
                type: "NumericFilter",
                component: NumericFilter,
            },
            {
                name: filter.options?.[0] == "FreeFormText" ? "Text Filter" : "Text Select Filter",
                type: "TextFilter",
                component: FilterText,
            },
            {
                name: "Boolean Filter",
                type: "BooleanFilter",
                component: BooleanFilter,
            },
        ];

        const display = list.find((l) => l.type == filter.type);
        const View = display.component;

        return (
            <div className="filter">
                <h6 className="api-list-title" onClick={() => open.onChange(!open.value)}>
                    <i className="material-icons">{open.value ? "keyboard_arrow_down" : "keyboard_arrow_up"}</i> {filter.name} ({display.name})
                </h6>

                {open.value && (
                    <div className="filter-item">
                        <h4>Key</h4>
                        <ul className="api-list-group">
                            <li className="api-list-item">
                                <h3 className="api-list-item-label">
                                    <span className="api-list-item-label-name">"{filter.id}"</span> <span className="api-list-item-validation">uuid</span>
                                </h3>
                                <div className="api-list-item-description-and-children">
                                    <p className="api-list-item-description">
                                        <span>Constant identifier of this {upperCase1(display.name.toLowerCase())}.</span>
                                    </p>
                                </div>
                            </li>
                        </ul>

                        {View({filter})}
                    </div>
                )}
            </div>
        );
    });

const FilterDateRange = ({filter}) => (
    <>
        <h4>Value Object</h4>
        <ul className="api-list-group">
            <li className="api-list-item">
                <h3 className="api-list-item-label">
                    <span className="api-list-item-label-name">$type</span> <span className="api-list-item-validation">string</span>
                </h3>
                <div className="api-list-item-description-and-children">
                    <p className="api-list-item-description">
                        <span>
                            Constant type of filter: <code>DateFilterValue</code>
                        </span>
                    </p>
                </div>
            </li>
            <li className="api-list-item">
                <h3 className="api-list-item-label">
                    <span className="api-list-item-label-name">range</span> <span className="api-list-item-validation">object</span>
                </h3>
                <ul className="api-list-group">
                    <li className="api-list-item">
                        <h3 className="api-list-item-label">
                            <span className="api-list-item-label-name">$type</span>
                            <span className="api-list-item-validation">string</span>
                        </h3>
                        <div className="api-list-item-description-and-children">
                            <p className="api-list-item-description">
                                <span>
                                    Constant type of range: <code>InRangeCustomDateFilterRange</code>
                                </span>
                            </p>
                        </div>
                    </li>
                    <li className="api-list-item">
                        <h3 className="api-list-item-label">
                            <span className="api-list-item-label-name">dateStart</span> <span className="api-list-item-validation">datetime</span>
                        </h3>
                        <div className="api-list-item-description-and-children">
                            <p className="api-list-item-description">
                                <span>Starting date range (inclusive).</span>
                            </p>
                        </div>
                    </li>
                    <li className="api-list-item">
                        <h3 className="api-list-item-label">
                            <span className="api-list-item-label-name">endStart</span> <span className="api-list-item-validation">datetime</span>
                        </h3>
                        <div className="api-list-item-description-and-children">
                            <p className="api-list-item-description">
                                <span>Ending date range (inclusive).</span>
                            </p>
                        </div>
                    </li>
                </ul>
            </li>
        </ul>
    </>
);

const FilterText = ({filter}) => {
    if (filter.options?.[0] == "SelectableList") {
        return (
            <>
                <h4>Value Object</h4>
                <ul className="api-list-group">
                    <li className="api-list-item" id="subscription_object-id">
                        <h3 className="api-list-item-label">
                            <span className="api-list-item-label-name">$type</span> <span className="api-list-item-validation">string</span>
                        </h3>
                        <div className="api-list-item-description-and-children">
                            <p className="api-list-item-description">
                                <span>
                                    Constant type of filter: <code>TextFilterValue</code>
                                </span>
                            </p>
                        </div>
                    </li>
                    <li className="api-list-item" id="subscription_object-id">
                        <h3 className="api-list-item-label">
                            <span className="api-list-item-label-name">option</span> <span className="api-list-item-validation">text</span>
                        </h3>
                        <div className="api-list-item-description-and-children">
                            <p className="api-list-item-description">
                                <span>
                                    Constant filter option: <code>SelectableList</code>
                                </span>
                            </p>
                        </div>
                    </li>
                    <li className="api-list-item" id="subscription_object-id">
                        <h3 className="api-list-item-label">
                            <span className="api-list-item-label-name">selectableListValues</span> <span className="api-list-item-validation">text array</span>
                        </h3>
                        <div className="api-list-item-description-and-children">
                            <p className="api-list-item-description">
                                <span>Text values to include in results.</span>
                            </p>
                        </div>
                    </li>
                </ul>
            </>
        );
    }

    return (
        <>
            <h4>Value Object</h4>
            <ul className="api-list-group">
                <li className="api-list-item">
                    <h3 className="api-list-item-label">
                        <span className="api-list-item-label-name">$type</span> <span className="api-list-item-validation">string</span>
                    </h3>
                    <div className="api-list-item-description-and-children">
                        <p className="api-list-item-description">
                            <span>
                                Constant type of filter: <code>TextFilterValue</code>
                            </span>
                        </p>
                    </div>
                </li>
                <li className="api-list-item">
                    <h3 className="api-list-item-label">
                        <span className="api-list-item-label-name">option</span> <span className="api-list-item-validation">text</span>
                    </h3>
                    <div className="api-list-item-description-and-children">
                        <p className="api-list-item-description">
                            <span>
                                Constant filter option: <code>FreeFormText</code>
                            </span>
                        </p>
                    </div>
                </li>
                <li className="api-list-item">
                    <h3 className="api-list-item-label">
                        <span className="api-list-item-label-name">operator</span> <span className="api-list-item-validation">text</span>
                    </h3>
                    <div className="api-list-item-description-and-children">
                        <p className="api-list-item-description">
                            <span>
                                One of the following: <code>Equals</code>, <code>DoesNotEqual</code>, <code>StartsWith</code>, <code>EndsWith</code>, <code>Contains</code>,{" "}
                                <code>DoesNotContain</code>
                            </span>
                        </p>
                    </div>
                </li>
                <li className="api-list-item">
                    <h3 className="api-list-item-label">
                        <span className="api-list-item-label-name">freeFormValue</span>
                        <span className="api-list-item-validation">text</span>
                    </h3>
                    <div className="api-list-item-description-and-children">
                        <p className="api-list-item-description">
                            <span>Text to filter value on.</span>
                        </p>
                    </div>
                </li>
            </ul>
        </>
    );
};

const NumericFilter = ({filter}) => (
    <>
        <h4>Value Object</h4>
        <ul className="api-list-group">
            <li className="api-list-item">
                <h3 className="api-list-item-label">
                    <span className="api-list-item-label-name">$type</span> <span className="api-list-item-validation">string</span>
                </h3>
                <div className="api-list-item-description-and-children">
                    <p className="api-list-item-description">
                        <span>
                            Constant type of filter: <code>NumericFilterValue</code>
                        </span>
                    </p>
                </div>
            </li>
            <li className="api-list-item">
                <h3 className="api-list-item-label">
                    <span className="api-list-item-label-name">option</span> <span className="api-list-item-validation">text</span>
                </h3>
                <div className="api-list-item-description-and-children">
                    <p className="api-list-item-description">
                        <span>
                            One of the following:{" "}
                            {filter.options.map((option, index) => (
                                <span key={index}>
                                    <code>{option}</code>
                                    {index < filter.options.length - 1 && <span>, </span>}
                                </span>
                            ))}
                        </span>
                    </p>
                </div>
            </li>
            <li className="api-list-item">
                <h3 className="api-list-item-label">
                    <span className="api-list-item-label-name">minOrValue</span> <span className="api-list-item-validation">double</span>
                </h3>
                <div className="api-list-item-description-and-children">
                    <p className="api-list-item-description">
                        <span>Value or minimum value (inclusive).</span>
                    </p>
                </div>
            </li>
            <li className="api-list-item">
                <h3 className="api-list-item-label">
                    <span className="api-list-item-label-name">max</span> <span className="api-list-item-validation">double</span>
                </h3>
                <div className="api-list-item-description-and-children">
                    <p className="api-list-item-description">
                        <span>
                            Maximum value (inclusive) when using <code>Range</code> option. Ignored if using any other option.
                        </span>
                    </p>
                </div>
            </li>
        </ul>
    </>
);

const BooleanFilter = ({filter}) => (
    <>
        <h4>Value Object</h4>
        <ul className="api-list-group">
            <li className="api-list-item">
                <h3 className="api-list-item-label">
                    <span className="api-list-item-label-name">$type</span> <span className="api-list-item-validation">string</span>
                </h3>
                <div className="api-list-item-description-and-children">
                    <p className="api-list-item-description">
                        <span>
                            Constant type of filter: <code>BooleanFilterValue</code>
                        </span>
                    </p>
                </div>
            </li>
            <li className="api-list-item">
                <h3 className="api-list-item-label">
                    <span className="api-list-item-label-name">value</span> <span className="api-list-item-validation">bool</span>
                </h3>
                <div className="api-list-item-description-and-children">
                    <p className="api-list-item-description">
                        <span>Value to filter on.</span>
                    </p>
                </div>
            </li>
        </ul>
    </>
);
