import {getErrorViewTablesWhenTableDeleted} from "./fix-issues-common";
import * as React from "react";
import {createDateFormatter} from "@common/ui-components/charts/common/formatters/date-formatter";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {getErrorsInTableDeleted} from "../../layout/error-badge/error-badge-helper";

export const DeletedTableErrors = ({model, modal, dataSources, dataViewTransforms, children, interactions}) =>
    cs(consumeContext("routing"), ({routing}) => {
        const {relationships} = model.value;

        const allTables = model.value.tables.concat(model.value.disabledTables);
        const table = allTables.find((t) => t.id == modal.tableID);
        const dataSource = dataSources.find((d) => d.id == table.dataSourceID);

        const deletedTableErrors = getErrorsInTableDeleted({
            table,
            relationships: relationships,
            allTables,
            dataViewTransforms: dataViewTransforms.value,
        })
            .map((error) => {
                if (error.errorType == "relationship") {
                    return {
                        ...error,
                        fix: () =>
                            model.change((m) => ({
                                ...m,
                                relationships: m.relationships.filter((r) => r.id != error.relationship.id),
                            })),
                    };
                }

                if (error.errorType == "rowLevelSecurity") {
                    return {
                        ...error,
                        fix: () => {
                            interactions.openRowLevelSecurity({
                                dataSources,
                                tableId: error.errorInTable.errorInTable.id,
                                dataSourceID: error.errorInTable.errorInTable.dataSourceID,
                                model,
                            });
                        },
                    };
                }

                if (error.errorType == "transformation") {
                    return {
                        ...error,
                        fix: () => routing.goto("edit-data-view", {tab: "build", transformationId: error.transformationID}),
                    };
                }

                if (error.errorType == "transformedColumn") {
                    return error;
                }

                return null;
            })
            .filter((v) => v);

        return children({
            title: (
                <span>
                    Table removed from data source: <b>{table.visualName}</b>
                </span>
            ),
            dataSource: dataSource.name,
            removedOn: createDateFormatter("MMM d, yyyy @ h:mm tt").format(new Date(table.deletedInfo.deletedDateUtc)),
            deletedBy: table.deletedInfo.deletedBy,
            description: `There are ${deletedTableErrors.length} ${
                deletedTableErrors.length > 1 ? "dependencies" : "dependency"
            } on this table causing errors in this model that could be causing issues in collections. `,
            solutions: ["Turn the column back on in the data source.", "Update each of the following broken dependencies individually."],
            heading1: "Missing Table Errors",
            errors: deletedTableErrors,
        });
    });
