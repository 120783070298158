import "./recursive-table.scss";

import React, {createElement as h} from "react";
import {cx} from "emotion";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {keyed} from "@common/react/keyed";
import {ArrowChevDown, UnhappyIcon} from "@common/ui-components/icons/global-icons";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";
import {AnyAction} from "@common/react/any-action";
import {Static2} from "@common/react/static-2";
import {waitTimeout} from "@common/utils/wait-timeout";

export const RecursiveTable = ({
    label = "",
    structure,
    getTrClass,
    getChildTrClass,
    columns,
    isGroupNode,
    isAlwaysExpanded,
    maxHeight = null,
    onClearSearch,
    showNoResult = false,
    renderNoResult,
    style,
    onClickRow,
    className,
    sortedProperty
}) =>
    cs(
        (_, next) => {
            const isNoResult = showNoResult && structure[0].children.concat(structure[0][structure[0].itemsProp]).length == 0;

            return (
                <>
                    {!maxHeight ? (
                        <div className="recursive-table-68u-wrapper">{next()}</div>
                    ) : (
                        <VerbScrollbar maxHeight={maxHeight} style={{...(!isNoResult ? style : {})}} className={cx("recursive-table-67u-wrapper", className)}>
                            {next()}
                        </VerbScrollbar>
                    )}

                    {isNoResult && (
                        <div className="recursive-table-no-result-vb1">
                            {renderNoResult ? (
                                renderNoResult()
                            ) : (
                                <>
                                    <UnhappyIcon />

                                    <div className="text-message">
                                        No results found
                                        {label.length > 0 && ` in ${label}`}
                                    </div>
                                    {onClearSearch && (
                                        <div className="clear-search" onClick={() => onClearSearch()}>
                                            Clear Search
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    )}
                </>
            );
        },
        (_, next) => (
            <table className="recursive-table-67u">
                <thead>
                    <tr>
                        {columns.map((c, i) => (
                            <th
                                key={i}
                                className={cx({
                                    "align-right": c.alignRight,
                                    "align-center": c.alignCenter,
                                    "sticky-col": c.sticky,
                                })}
                                style={{...(i === 0 && {minWidth: c.headerMinWidth || 250})}}
                            >
                                {c.label}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>{next()}</tbody>
            </table>
        ),
        ({}) => {
            const renderColumns = (node, collapsed, path, {parent = null}) =>
                columns.map((c, j) =>
                    cs(
                        ["open", (_, next) => UseState({next})],
                        ["domRef", (_, next) => Static2({next})],
                        ({open}, next) =>
                            h(AnyAction, {
                                disabled: !open.value,
                                fn: () => {
                                    open.onChange(false);
                                },
                                next,
                            }),
                        ({open, domRef}) => (
                            <td
                                {...{
                                    key: j,
                                    className: cx(c.className, c.haveDropdown?.(node) && "has-dropdown-action", c.haveDropdown?.(node)?.fullColumn && "full-column-action", {
                                        "align-right": c.alignRight,
                                        indented: c.indented,
                                        "no-padding": c.noPadding,
                                        "align-center": c.alignCenter,
                                        "sticky-col": c.sticky,
                                        shy: c.shy,
                                    }),
                                    ...(c.indented && {
                                        style: {
                                            paddingLeft: path?.length * 22 + 16,
                                        },
                                    }),
                                    onClick: (e) => {
                                        if (c.indented) {
                                            collapsed.onChange?.(!collapsed.value);
                                        }
                                        if (c.haveDropdown?.(node)?.fullColumn) {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            open.onChange(true);
                                        }
                                    },
                                    ref: domRef.set,
                                }}
                            >
                                {collapsed.onChange && c.indented && (
                                    <div className="collapse-indicator">
                                        <ArrowChevDown className={cx("chevron", !collapsed.value ? "down" : "right")} />
                                    </div>
                                )}

                                {c.format(node, {expanding: !collapsed.value, path, parent})}

                                {c.haveDropdown?.(node, domRef) && (
                                    <div
                                        className={cx("column-dropdown", open.value && "open")}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            open.onChange(true);
                                        }}
                                    >
                                        <ArrowChevDown className={cx("chevron", "down")} />
                                    </div>
                                )}

                                {open.value && c.haveDropdown?.(node).render(domRef)}
                            </td>
                        )
                    )
                );

            const sortByName = (items) => items.sort((x, y) => {
                const _x = sortedProperty ? x[sortedProperty] : x.name;
                const _y = sortedProperty ? y[sortedProperty] : y.name;

                return _x.localeCompare(_y);
            });

            const renderTable = (folder, path) => {
                const items = sortByName(folder.children).concat(sortByName(folder[folder.itemsProp]));

                const clickRow = (e, node, item, path) => {
                    if (isGroupNode(node) || !onClickRow) {
                        return;
                    }
                    onClickRow?.(item ?? node, e, path);
                    const elem = e.currentTarget;
                    setTimeout(async () => {
                        if (!elem) return;
                        elem.classList.add("active");
                        await waitTimeout(50);
                        elem.classList.remove("active");
                    });
                };

                return items.map((node, i) => {
                    return cs(
                        keyed(i),
                        [
                            "collapsed",
                            (_, next) =>
                                !isGroupNode(node)
                                    ? next({value: true})
                                    : isAlwaysExpanded(node)
                                    ? next({value: false})
                                    : h(UseState, {
                                          initValue: true,
                                          next,
                                      }),
                        ],
                        ({collapsed}) => (
                            <>
                                <tr
                                    key={i}
                                    className={cx(getTrClass(node, {parents: path}), {
                                        expanding: !collapsed.value,
                                    })}
                                    onClick={(e) => clickRow(e, node, null, path)}
                                >
                                    {renderColumns(node, collapsed, path, {parent: null})}
                                </tr>

                                {node.childs?.length > 1 && (
                                    <>
                                        {node.childs.map((child, index) => (
                                            <tr
                                                className={cx("api-collection-child", getChildTrClass?.(child), index == node.childs.length - 1 && "last-child")}
                                                key={index}
                                                onClick={(e) => clickRow(e, node, child, path)}
                                            >
                                                {renderColumns(child, {}, path, {parent: node})}
                                            </tr>
                                        ))}
                                    </>
                                )}

                                {!collapsed.value && renderTable(node, [...path, node])}
                            </>
                        )
                    );
                });
            };

            return renderTable(structure[0], []);
        }
    );
