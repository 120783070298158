import React from "react";
import {cs} from "@common/react/chain-services";
import {rColumnValueSelectFromStepOutput} from "../../../../../common/column-value-select-from-step-output/column-value-select-from-step-output";
import {ffToDropdown} from "../../../../../../../../../../common/form/ff-to-dropdown";
import {keepOnly} from "@common/utils/objects";
import {rNewColumnNameInput} from "../../../common/new-column-name-input";

export const FromUnixEpochOperations = ({state, operation, form, inputStep, model}) =>
    cs(() => {
        const columnValueSelect = rColumnValueSelectFromStepOutput({
            columns: inputStep.outputColumns,
            model,
            disableStaticValue: true,
            filterColumnFn: (c) => ["Int", "Double"].includes(c.dataType),
            isValueNotAllowed: (c) => !["Int", "Double"].includes(c.dataType),
        });

        return (
            <>
                <div className="config-group">
                    <div className="label">Unix Epoch Input Column</div>

                    {(() => {
                        const dropdownProps = ffToDropdown(form.field(["operation", "input"]));
                        return columnValueSelect({
                            state: {
                                value: dropdownProps.value,
                                onChange: dropdownProps.onChange,
                            },
                            ...keepOnly(dropdownProps, ["hasError", "errorMessage", "domRef"]),
                        });
                    })()}
                </div>

                <div className="config-group">
                    <div className="label">New Column Name</div>

                    {rNewColumnNameInput({
                        form,
                        newColumnPath: ["operation", "newColumn"],
                        newColumnNamePath: ["operation", "newColumn", "name"],
                    })}
                </div>
            </>
        );
    });
