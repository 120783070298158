import "./waiting-setup.scss";

import { cs } from "@common/react/chain-services";
import { consumeContext } from "@common/react/context";
import { fragments } from "@common/react/fragments";
import { Interval } from "@common/react/interval";
import { LoadingIndicator } from "@common/ui-components/loading-indicator/loading-indicator";
import React from "react";
import { RoutingParam } from "../common/routing/routing-param";
import { DashboardLayout } from "../dashboard/layout/dashboard-layout";

export const WaitingSetUp = () =>
    cs(
        consumeContext("routing"),
        consumeContext("tenant"),
        ["envId", ({}, next) => RoutingParam({param: "envId", next})],
        ({envId}, next) => DashboardLayout({envId, next}),
        ({tenant, routing}, next) =>
            fragments(
                Interval({
                    delay: 5000,
                    fn: async () => {
                        const environments = await tenant.reloadEnvironments();
                        const sampleEnv = environments.find((e) => e.readOnly);

                        if (sampleEnv) {
                            routing.goto("dashboard", {envId: sampleEnv.id});
                        }
                    },
                }),
                next()
            ),
        ({}) => {
            const contactUsComp = <a href="mailto:support@revivemedia.us">contact us</a>;
            return (
                <div className="waiting-setup-f3b">
                    {LoadingIndicator({className: "loading"})}

                    <div className="title">Please wait while we setup your sample environment.</div>
                    <div className="sub-title">This should only take a few moments. Please {contactUsComp} if you encounter an issue.</div>
                </div>
            );
        }
    );
