const {cIndexedGetter} = require("../../../../../../../../../common/utils/indexing");
const {cascade} = require("../../../../../../../../../common/utils/cascade");
const {flatten1} = require("../../../../../../../../../common/utils/collections");

const cGetTileMeasureField = (tile) => {
    const measureAttrs = tileMeasureAttrs[tile.$type];
    const measureFields = flatten1(measureAttrs?.map((attr) => cascade(tile[attr], "[*].measureFields[*]")));
    return cIndexedGetter(measureFields, "id");
};
exports.cGetTileMeasureField = cGetTileMeasureField;

const tileMeasureAttrs = {
    VerticalBarChartTile: ["yAxisFields"],
    HorizontalBarChartTile: ["xAxisFields"],
    LineChartTile: ["yAxisFields"],
    ComboChartTile: ["yAxisBarFields", "yAxisLineFields"],
};
