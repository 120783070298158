import "./settings-dashboard.scss";

import React from "react";
import {UseState} from "@common/react/use-state";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {LoadingIndicator} from "@common/ui-components/loading-indicator/loading-indicator";
import {EnvDetails} from "./details/env-details";
import {DataAccessApiKey} from "./data-access-key/data-access-api-key";
import {Authentication} from "./authentication/authentication";
import {IFrameKey} from "./iframe-key/iframe-key";
import {Load2} from "@common/react/load2";
import {PreviewProfiles} from "./preview-profiles/preview-profiles";

export const SettingsDashboard = ({
    next: rootNext,
    folders,
    apiCollections,
    accessApiKeyFolders,
    dashboardCollectionsOverview,
    iframeKeyFolders,
}) => {
    return cs(
        consumeContext("auth"),
        consumeContext("routing"),
        consumeContext("apis"),
        consumeContext("authEndpoint"),
        consumeContext("subscriptionBrief"),
        // ({authEndpoint, subscriptionBrief}, next) => (authEndpoint.loading || !subscriptionBrief.value ? LoadingIndicator({}) : next()),
        [
            "tabs",
            ({auth, subscriptionBrief}, next) => {
                const tabs = [
                    {
                        name: "authentication",
                        label: "Authentication",
                        render: Authentication,
                    },
                    {
                        name: "data-access-key",
                        label: "Data Access API Key",
                        render: DataAccessApiKey,
                        isHidden: !subscriptionBrief.isBoughtFeature("ApiCollection"),
                    },
                    {
                        name: "iframe-key",
                        label: "iFrame Key",
                        render: IFrameKey,
                    },
                    {
                        name: "preview-profiles",
                        label: "Preview Profiles",
                        render: PreviewProfiles,
                    },
                    {
                        name: "details",
                        label: "Details",
                        render: EnvDetails,
                        isHidden: !auth.user || ["Full"].indexOf(auth.user.roles[0]) == -1,
                    },
                ];

                return next(tabs.filter((t) => !t.isHidden));
            },
        ],
        [
            "tab",
            (_, next) =>
                UseState({
                    initValue: "authentication",
                    // initValue: "iframe-key",
                    next,
                }),
        ],
        ({tabs, tab}) => {
            return rootNext({
                hasSubTabs: true,
                subTabs: tabs,
                activeTab: tab,
                content: (
                    <div className="settings-dashboard-content-e33">
                        {tabs
                            .find((t) => t.name === tab.value)
                            .render({
                                tab,
                                collectionFolders: folders,
                                apiCollections,
                                accessApiKeyFolders,
                                dashboardCollectionsOverview,
                                iframeKeyFolders,
                            })}
                    </div>
                ),
            });
        }
    );
};
