import {getTooltipOptions} from "../../factory/tooltip/tooltip-options";
import {cGetFontSize} from "../../common/style-map/font-size";
import {getScatterDataLabelsOptions} from "./data-labels";

export const getScatterPlotOptions = ({tile, theme, rawData, formatters, isCompare, onClickPoint}) => {
    const getFontSize = cGetFontSize(theme.general.canvas.fontSize, theme);

    const chart = {
        type: "scatter",
        backgroundColor: theme.general.tile.styles.tileBackgroundColorRGB,
    };

    const title = {
        text: undefined,
    };

    const plotOptions = {
        series: {
            dataLabels: getScatterDataLabelsOptions({tile, theme, formatters}),
            marker: {
                radius: 8,
                lineWidth: 1,
                lineColor: theme.general.tile.styles.tileBackgroundColorRGB,
            },
            cursor: onClickPoint ? "pointer" : "default",
        },
        scatter: {
            point: {
                events: {
                    // for highlighting dimension from both date ranges on hover
                    ...(() => {
                        if (!tile.dimensionField || !isCompare) {
                            return;
                        }
                        let respSeries;
                        return {
                            mouseOver: function () {
                                const {series} = this;
                                respSeries = series.chart.series.find((s) => (
                                    s.userOptions.measureAxisTitle === series.userOptions.measureAxisTitle &&
                                    s.userOptions.isCompare !== series.userOptions.isCompare
                                ));

                                if (respSeries) {
                                    respSeries.data[0].setState("hover");
                                    respSeries.setState("hover");
                                }
                            },
                            mouseOut: function () {
                                if (respSeries) {
                                    respSeries.data[0].setState();
                                    respSeries.setState();
                                }
                            }
                        }
                    })(),
                    click: function({x, y, point}) {
                        onClickPoint?.({x, y, point, chart: this.series.chart});
                    }
                }
            }
        }
    }

    const tooltip = getTooltipOptions({tile, theme, formatters, rawData, isCompare});

    const legend = {
        enabled: false, // draw legend manually
        itemStyle: {
            fontSize: getFontSize({group: theme.dataVisualization.toolTipsAndLegends.legendFontSize}),
        },
    };

    return {
        chart,
        title,
        plotOptions,
        tooltip,
        legend,
        credits: {
            enabled: false
        },
    }
}