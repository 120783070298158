const {required} = require("@common/react/cs-form/validators/required");
const {inRangeValidator} = require("@common/react/cs-form/validators/in-range");

exports.pieChartFormConfig = {
    fields: {
        title: {
            transforms: ["trim"],
            validators: [required],
            debounce: true,
        },
        "style.maxSlices": {
            validators: [inRangeValidator(1, 200)],
        },
    },
};
