const {sameDate, sameExactDate} = require("./date-object");

const sameDateRange = (r1 = {}, r2 = {}) => {
    return sameDate(r1?.from, r2?.from) && sameDate(r1?.to, r2?.to);
};
exports.sameDateRange = sameDateRange;

const sameExactDateRange = (r1 = {}, r2 = {}) => {
    return sameExactDate(r1?.from, r2?.from) && sameExactDate(r1?.to, r2?.to);
};
exports.sameExactDateRange = sameExactDateRange;
