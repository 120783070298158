export const SortNumberAscIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 16.6875L3 18.8125L16.5 18.8125L16.5 22L21 17.75L16.5 13.5L16.5 16.6875L3 16.6875Z" fill="currentColor" />
        <path d="M6.15217 10.5L6.15217 3.88214L4.65217 4.36786L4 2.97143L6.41304 2L8.5 2L8.5 10.5H6.15217Z" fill="currentColor" />
        <path
            d="M14.5263 8.67014C15.9719 8.78819 16.9895 8.84722 17.5789 8.84722C17.7404 8.84722 17.8667 8.79607 17.9579 8.69375C18.0561 8.58357 18.1053 8.43796 18.1053 8.25694V7.19444C17.4246 7.35185 16.8281 7.43056 16.3158 7.43056H15.5789C15.1018 7.43056 14.7193 7.26921 14.4316 6.94653C14.1439 6.62384 14 6.19491 14 5.65972V4.125C14 3.4088 14.1614 2.87755 14.4842 2.53125C14.807 2.17708 15.3123 2 16 2H18C18.6877 2 19.193 2.17708 19.5158 2.53125C19.8386 2.87755 20 3.4088 20 4.125V8.25694C20 9.02824 19.8386 9.59491 19.5158 9.95694C19.193 10.319 18.6877 10.5 18 10.5C17.0386 10.5 15.8807 10.4213 14.5263 10.2639V8.67014ZM16.8421 5.77778C17.2421 5.77778 17.6632 5.73843 18.1053 5.65972V4.36111C18.1053 3.88889 17.8947 3.65278 17.4737 3.65278L16.5263 3.65278C16.1053 3.65278 15.8947 3.88889 15.8947 4.36111V5.36458C15.8947 5.48264 15.9298 5.58102 16 5.65972C16.0702 5.73843 16.1579 5.77778 16.2632 5.77778H16.8421Z"
            fill="currentColor"
        />
    </svg>
);

export const SortNumberDescIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 16.6875L3 18.8125L16.5 18.8125L16.5 22L21 17.75L16.5 13.5L16.5 16.6875L3 16.6875Z" fill="currentColor" />
        <path d="M16.6522 10.5V3.88214L15.1522 4.36786L14.5 2.97143L16.913 2L19 2V10.5H16.6522Z" fill="currentColor" />
        <path
            d="M4.52632 8.67014C5.97193 8.78819 6.98947 8.84722 7.57895 8.84722C7.74035 8.84722 7.86667 8.79607 7.95789 8.69375C8.05614 8.58357 8.10526 8.43796 8.10526 8.25694V7.19444C7.42456 7.35185 6.82807 7.43056 6.31579 7.43056H5.57895C5.10175 7.43056 4.7193 7.26921 4.43158 6.94653C4.14386 6.62384 4 6.19491 4 5.65972V4.125C4 3.4088 4.1614 2.87755 4.48421 2.53125C4.80702 2.17708 5.31228 2 6 2H8C8.68772 2 9.19298 2.17708 9.51579 2.53125C9.8386 2.87755 10 3.4088 10 4.125V8.25694C10 9.02824 9.8386 9.59491 9.51579 9.95694C9.19298 10.319 8.68772 10.5 8 10.5C7.0386 10.5 5.8807 10.4213 4.52632 10.2639L4.52632 8.67014ZM6.84211 5.77778C7.24211 5.77778 7.66316 5.73843 8.10526 5.65972V4.36111C8.10526 3.88889 7.89474 3.65278 7.47368 3.65278L6.52632 3.65278C6.10526 3.65278 5.89474 3.88889 5.89474 4.36111V5.36458C5.89474 5.48264 5.92982 5.58102 6 5.65972C6.07018 5.73843 6.15789 5.77778 6.26316 5.77778H6.84211Z"
            fill="currentColor"
        />
    </svg>
);

export const SortDateAscIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.9034 13H10.6534V18.5H7.27844L11.7784 23L16.2784 18.5H12.9034V13Z" fill="currentColor" />
        <path
            d="M6.14971 8.85915C6.14971 9.5953 5.98656 10.1361 5.66026 10.4817C5.33396 10.8272 4.83032 11 4.14934 11C3.47545 11 2.75901 10.9624 2 10.8873V9.3662C2.5533 9.40376 3.12078 9.42253 3.70245 9.42253C3.8656 9.42253 3.99328 9.37371 4.0855 9.27606C4.18481 9.17089 4.23446 9.03192 4.23446 8.85915V4.57747H2.85122V3H6.14971V8.85915Z"
            fill="currentColor"
        />
        <path
            d="M8.85966 10.8873H6.7848L9.60448 3H11.7325L14.5522 10.8873H12.4774L11.9985 9.47887H9.33847L8.85966 10.8873ZM9.81729 8.01408H11.5197L10.6685 5.3662L9.81729 8.01408Z"
            fill="currentColor"
        />
        <path d="M22 10.8873H19.9783L17.4247 6.15493V10.8873H15.5094V3H17.5311L20.0847 7.73239V3H22V10.8873Z" fill="currentColor" />
    </svg>
);

export const SortDateDescIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.9034 2H10.6534V7.5H7.27844L11.7784 12L16.2784 7.5H12.9034V2Z" fill="currentColor" />
        <path
            d="M6.14971 19.8592C6.14971 20.5953 5.98656 21.1361 5.66026 21.4817C5.33396 21.8272 4.83032 22 4.14934 22C3.47545 22 2.75901 21.9624 2 21.8873V20.3662C2.5533 20.4038 3.12078 20.4225 3.70245 20.4225C3.8656 20.4225 3.99328 20.3737 4.0855 20.2761C4.18481 20.1709 4.23446 20.0319 4.23446 19.8592V15.5775H2.85122V14H6.14971V19.8592Z"
            fill="currentColor"
        />
        <path
            d="M8.85966 21.8873H6.7848L9.60448 14H11.7325L14.5522 21.8873H12.4774L11.9985 20.4789H9.33847L8.85966 21.8873ZM9.81729 19.0141H11.5197L10.6685 16.3662L9.81729 19.0141Z"
            fill="currentColor"
        />
        <path d="M22 21.8873H19.9783L17.4247 17.1549V21.8873H15.5094V14H17.5311L20.0847 18.7324V14H22V21.8873Z" fill="currentColor" />
    </svg>
);

export const SortTextAscIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 17.1875L3 19.3125L16.5 19.3125L16.5 22.5L21 18.25L16.5 14L16.5 17.1875L3 17.1875Z" fill="currentColor" />
        <path
            d="M5.40411 11L3 11L6.26712 2.5L8.73288 2.5L12 11L9.59589 11L9.0411 9.48214L5.9589 9.48214L5.40411 11ZM6.5137 7.90357L8.4863 7.90357L7.5 5.05L6.5137 7.90357Z"
            fill="currentColor"
        />
        <path d="M21 4.2L16.7018 9.3L21 9.3L21 11L14 11L14 9.3L18.2982 4.2L14 4.2L14 2.5L21 2.5L21 4.2Z" fill="currentColor" />
    </svg>
);

export const SortTextDescIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 17.1875L3 19.3125L16.5 19.3125L16.5 22.5L21 18.25L16.5 14L16.5 17.1875L3 17.1875Z" fill="currentColor" />
        <path
            d="M14.4041 11L12 11L15.2671 2.49998L17.7329 2.49998L21 11L18.5959 11L18.0411 9.48213L14.9589 9.48213L14.4041 11ZM15.5137 7.90356L17.4863 7.90356L16.5 5.04999L15.5137 7.90356Z"
            fill="currentColor"
        />
        <path d="M10 4.19999L5.70175 9.29998L10 9.29998L10 11L3 11L3 9.29998L7.29825 4.19999L3 4.19999L3 2.49998L10 2.49998L10 4.19999Z" fill="currentColor" />
    </svg>
);

export const SortBooleanAscIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="23">
        <rect width="100%" height="100%" x="0" y="0" fill="none" stroke="none" />

        <text
            className="cls-1"
            fill="currentColor"
            y="11.60235969722271"
            x="0.16326530277729034"
            transform="matrix(1.0039502382278442,0,0,1.0179804563522339,-0.0006172247231006622,0.0016856677830219269) "
        >
            <tspan x="0.16326530277729034" y="11.60235969722271">
                FT
            </tspan>
        </text>
        <path
            data-name="Sort A-Z Icon"
            fill="currentColor"
            className="cls-2"
            d="M14.977587142075276,14.750555045195771 v2.2527907499074935 H0.9042127025973264 a0.7409152758121496,0.7512695767879491 0 0 0 -0.7409152758121496,0.7512695767879491 v1.5025391535758972 a0.7409152758121496,0.7512695767879491 0 0 0 0.7409152758121496,0.7512695767879491 h14.073374439477922 V22.26528677398791 a0.7409152758121496,0.7512695767879491 0 0 0 1.2639733499288557,0.5303678177595139 l4.444487704634666,-3.755329903483391 a0.7399113255739217,0.7502515963315969 0 0 0 0,-1.0617536159753798 L16.243568392480555,14.223241168805284 a0.7419192260503774,0.7522875572443014 0 0 0 -1.2639733499288557,0.5313857982158661 z"
        />
    </svg>
);

export const SortBooleanDescIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="23">
        <rect width="100%" height="100%" x="0" y="0" fill="none" stroke="none" />

        <text
            className="cls-1"
            fill="currentColor"
            y="11.60235969722271"
            x="0.16326530277729034"
            transform="matrix(1.0039502382278442,0,0,1.0179804563522339,-0.0006172247231006622,0.0016856677830219269) "
        >
            <tspan x="0.16326530277729034" y="11.60235969722271">
                TF
            </tspan>
        </text>
        <path
            data-name="Sort A-Z Icon"
            fill="currentColor"
            className="cls-2"
            d="M14.977587142075276,14.750555045195771 v2.2527907499074935 H0.9042127025973264 a0.7409152758121496,0.7512695767879491 0 0 0 -0.7409152758121496,0.7512695767879491 v1.5025391535758972 a0.7409152758121496,0.7512695767879491 0 0 0 0.7409152758121496,0.7512695767879491 h14.073374439477922 V22.26528677398791 a0.7409152758121496,0.7512695767879491 0 0 0 1.2639733499288557,0.5303678177595139 l4.444487704634666,-3.755329903483391 a0.7399113255739217,0.7502515963315969 0 0 0 0,-1.0617536159753798 L16.243568392480555,14.223241168805284 a0.7419192260503774,0.7522875572443014 0 0 0 -1.2639733499288557,0.5313857982158661 z"
        />
    </svg>
);
