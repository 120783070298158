import React from "react";
import {cs} from "@common/react/chain-services";
import "./collection-settings.scss";
import {CollectionName} from "../../../../../../api-collection/edit/tabs/collection/api-collection-tab";

export const CollectionSettings = ({collection}) =>
    cs(({}) => (
        <div className="collection-settings-35g">
            <div className="line">
                {CollectionName({
                    collection,
                })}
            </div>
            {/*<div className="line cols">*/}
            {/*    <div className="col1">*/}
            {/*        {DropdownSelect({*/}
            {/*            list: [*/}
            {/*                {*/}
            {/*                    label: "Full Width (100%)",*/}
            {/*                    attr: "viewWidth100Percent",*/}
            {/*                    defaultValue: true,*/}
            {/*                },*/}
            {/*                {*/}
            {/*                    label: "Fixed Width",*/}
            {/*                    attr: "viewWidthPixels",*/}
            {/*                    defaultValue: 800,*/}
            {/*                }*/}
            {/*            ],*/}
            {/*            label: "View Width",*/}
            {/*            isSelected: (l) => !!collection.value?.[l.attr] || collection.value?.[l.attr] === 0,*/}
            {/*            valueToLabel: (l) => l.label,*/}
            {/*            onChange: (l) => collection.change((c) => ({*/}
            {/*                ...omit(c, ["viewWidth100Percent", "viewWidthPixels"]),*/}
            {/*                viewWidth100Percent: l.attr === "viewWidth100Percent",*/}
            {/*                ...(l.attr === "viewWidthPixels" && {*/}
            {/*                    viewWidthPixels: l.defaultValue,*/}
            {/*                }),*/}
            {/*            }))*/}
            {/*        })}*/}
            {/*    </div>*/}
            {/*    <div className="col2">*/}
            {/*        {cs(*/}
            {/*            ["debounce", (_, next) => DebounceNumber({state: scope(collection, ["viewWidthPixels"]), next})],*/}
            {/*            ({debounce}) => NumberInput({*/}
            {/*                label: "Pixels",*/}
            {/*                disabled: collection.value?.viewWidthPixels == null,*/}
            {/*                state: debounce.state,*/}
            {/*                onBlur: debounce.flush,*/}
            {/*            }),*/}
            {/*        )}*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    ));
