exports.top5Currencies = [
    {
        label: "$1,234.56 US Dollar",
        currency: "USD",
        currencySymbol: "$",
    },
    {
        label: "$1,234.56 Canadian Dollar",
        currency: "CAD",
        currencySymbol: "$",
    },
    {
        label: "€1,234.56 Euro",
        currency: "EUR",
        currencySymbol: "€",
    },
    {
        label: "£1,234.56 Pound Sterling",
        currency: "GBP",
        currencySymbol: "£",
    },
    {
        label: "¥1,234.56 Japanese Yen",
        currency: "JPY",
        currencySymbol: "¥",
    },
].map(({label, currency, currencySymbol}) => ({
    label,
    attrSet: {
        decimalPlaces: 2,
        decimalSeperator: "Dot",
        thousandsSeperator: "Comma",
        negativeFormat: "Parentheses",
        shiftDecimalPlaceLeft: 0,
        currencyProperties: {
            currency,
            currencySymbolPlacement: "Left",
            currencySymbol,
            spaceBetweenSymbolAndNumber: false,
        },
    },
}));
