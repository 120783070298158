import {cs} from "@common/react/chain-services";
import * as React from "react";
import "./local-filters.scss";
import {Expandable} from "../../../../../../../common/expandable/expandable";
import {StaticTooltipService} from "../../../../../../../common/tooltip3/static-tooltip-service";
import {FieldItem} from "../../../../../../edit/common/field-item/field-item";
import {getFilterIcon} from "../../../../../../common/filters/filter-left-panel/select-filter-type";

export const ExpandableFilters = ({
    list,
    onClickItem,
    label = "Filters",
    renderLabel,
    renderIcon,
    disabled = false,
    itemLabel = (item) => item.label,
    toolTip,
    direction = "right",
    tooltipBoxWidth = 230,
    topOffset = 40,
    leftOffset = -50,
    hasError = () => false,
}) =>
    cs(
        [
            "staticTooltip",
            (_, next) =>
                toolTip
                    ? StaticTooltipService({
                          direction,
                          info: toolTip,
                          tooltipBoxWidth,
                          topOffset,
                          leftOffset,
                          next,
                      })
                    : next(),
        ],
        ({staticTooltip}) => (
            <Expandable
                {...{
                    alwaysShow: true,
                    initExpand: true,
                    className: "expandable-filters-vcx",
                    label:
                        renderLabel?.({
                            renderTooltip: () => staticTooltip.renderIcon({icon: <i className="material-icons">info</i>, className: "info-tooltip"}),
                        }) ?? label,
                    render: () => (
                        <div className="local-filters-4au">
                            {list?.map((item, i) => {
                                return (
                                    <FieldItem
                                        key={i}
                                        disabled={disabled}
                                        onClick={!!onClickItem ? () => onClickItem(item) : null}
                                        label={itemLabel(item)}
                                        rightIcon={!!onClickItem ? <i className="far fa-arrow-right" aria-hidden="true" /> : null}
                                        leftIcon={renderIcon(item) ?? getFilterIcon(item)?.icon({style: {width: 16, height: 16}})}
                                        hasError={
                                            hasError(item) && {
                                                title: "Broken Field",
                                                details: hasError(item),
                                            }
                                        }
                                    />
                                );
                            })}
                        </div>
                    ),
                }}
            />
        )
    );
