import React from "react";
import "./gear-icon.scss";

export const GearIcon = () => {
    return (
        <svg className="gear-icon gear-icon-67h" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 17.176 17.181">
            {/*<defs>*/}
            {/*    <style>*/}
            {/*        .cls-1 {*/}
            {/*        fill: none;*/}
            {/*        stroke: #546b81;*/}
            {/*        opacity: 0.5;*/}
            {/*    }*/}
            {/*    </style>*/}
            {/*</defs>*/}
            <path
                className="cls-1"
                d="M19.179,12.5A2.059,2.059,0,0,1,20.5,10.579,8.161,8.161,0,0,0,19.513,8.2a2.086,2.086,0,0,1-.838.179A2.054,2.054,0,0,1,16.8,5.488,8.136,8.136,0,0,0,14.421,4.5a2.057,2.057,0,0,1-3.842,0A8.161,8.161,0,0,0,8.2,5.488,2.054,2.054,0,0,1,6.321,8.379,2.019,2.019,0,0,1,5.483,8.2,8.341,8.341,0,0,0,4.5,10.583a2.058,2.058,0,0,1,0,3.842A8.161,8.161,0,0,0,5.492,16.8,2.055,2.055,0,0,1,8.2,19.517a8.209,8.209,0,0,0,2.379.987,2.053,2.053,0,0,1,3.833,0,8.161,8.161,0,0,0,2.379-.987A2.057,2.057,0,0,1,19.508,16.8a8.209,8.209,0,0,0,.987-2.379A2.069,2.069,0,0,1,19.179,12.5Zm-6.642,3.329A3.333,3.333,0,1,1,15.871,12.5,3.332,3.332,0,0,1,12.537,15.829Z"
                transform="translate(-3.912 -3.911)"
            />
        </svg>
    );
};
