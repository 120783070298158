import {parseDateToObject} from "../../../../logic/date-time/format-date-time";
import {addDate} from "../../../../utils/dates/date-object";
import {generateDateRangeMatchWithAggregation, mainToComparing} from "../../../../logic/date-time/date-util";

export const modifyTileActionControlFilter = (filter, value, field, filterValue) => {
    const byTypes = {
        DateFilter: modifyDateFilter,
        TextFilter: modifyTextFilter,
        NumericFilter: modifyNumberFilter,
        BooleanFilter: modifyBooleanFilter,
    };

    return byTypes[filter.$type]?.(filter, value, field, filterValue);
};

export const modifyDateFilter = (filter, value, field, filterValue) => {
    return {
        columns: value ? [field] : [filter.columns.find((c) => c.default)],
        ...(value
            ? {
                  main: generateDateRangeMatchWithAggregation(new Date(value), field.dateProperties.aggregation),
                  comparing: filterValue.comparing ? mainToComparing(generateDateRangeMatchWithAggregation(new Date(value), field.dateProperties.aggregation)) : null,
              }
            : {}),
    };
};

export const modifyTextFilter = (filter, value) => {
    if (filter.option == "SelectableList") {
        return {selectableListValues: value ? [value] : []};
    }

    return {
        operator: value ? "Equals" : filter.defaultOperator,
        freeFormValue: value ?? filter.defaultFreeFormValue,
    };
};
export const modifyNumberFilter = (filter, value) => {
    if (filter.design == "Input") {
        if (value) {
            return {
                comparisonOperation: "EqualTo",
                value: parseFloat(value),
                range: null,
            };
        }

        return {
            comparisonOperation: filter.defaultOption,
            range: {from: filter.defaultValueOrMin, to: filter.defaultMax},
            value: filter.defaultValueOrMin,
        };
    }

    return {
        range: {
            from: value ? parseFloat(value) : filter.defaultValueOrMin,
            to: value ? parseFloat(value) : filter.defaultMax,
        },
    };
};
export const modifyBooleanFilter = (filter, value) => {
    return {value: value !== null ? value : filter.defaultValue};
};
