// const {stateToCheckboxLineGroup} = require("../checkbox-line-group/state-to-checkbox-line-group");
const {stringListState} = require("../../web-client/src/routes/common/data-logic/string-list-state");
const {stateToCheckboxLineGroup} = require("../../web-client/src/routes/common/checkbox-line-group/state-to-checkbox-line-group");

const ffToCheckboxGroup = (field, checkboxValuePath) => {
    const {isSelected, onChange} = stateToCheckboxLineGroup(stringListState(field.state), checkboxValuePath);
    return {
        isSelected,
        onChange,
        hasError: field.error,
        domRef: field.domRef,
    };
};
exports.ffToCheckboxGroup = ffToCheckboxGroup;
