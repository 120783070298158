import React from "react";
import {cs} from "@common/react/chain-services";
import {TextManipulationResultActionSelect} from "../../common/text-manipulation-result-action-select";
import {rColumnValueSelectFromStepOutput} from "../../../../../common/column-value-select-from-step-output/column-value-select-from-step-output";
import {ffToDropdown} from "../../../../../../../../../../common/form/ff-to-dropdown";
import {keepOnly} from "@common/utils/objects";

export const ExtractDomainConfiguration = ({textManipulation, inputStep, model, form}) =>
    cs(() => {
        return (
            <>
                <div className="config-group">
                    <div className="label">Input Column</div>
                    {(() => {
                        const dropdownProps = ffToDropdown(form.field(["textManipulation", "inputValue"]));
                        return rColumnValueSelectFromStepOutput({
                            label: "Column / Value",
                            columns: inputStep.outputColumns,
                            model,
                            disableStaticValue: true,
                            filterColumnFn: (c) => ["Text"].includes(c.dataType),
                            isValueNotAllowed: (c) => !["Text"].includes(c.dataType),
                        })({
                            state: {
                                value: dropdownProps.value,
                                onChange: dropdownProps.onChange,
                            },
                            ...keepOnly(dropdownProps, ["domRef", "hasError", "errorMessage"]),
                        });
                    })()}
                </div>

                {TextManipulationResultActionSelect({
                    state: textManipulation,
                    form,
                })}
            </>
        );
    });
