const {UseState} = require("./use-state");
const {Invoke} = require("./invoke");
const {fragments} = require("./fragments");
const {cs} = require("./chain-services");

const DelayRender = ({delay = 300, next}) =>
    cs(
        [
            "show",
            (_, next) =>
                UseState({
                    initValue: false,
                    next,
                }),
        ],
        ({show}) =>
            fragments(
                Invoke({
                    onMounted: () => {
                        setTimeout(() => {
                            show.onChange(true);
                        }, delay);
                    },
                }),
                show.value ? next() : null
            )
    );
exports.DelayRender = DelayRender;
