import * as React from "react";
import {cs} from "../../../react/chain-services";
import {Load2} from "../../../react/load2";
import {fetchMapData} from "./map-helper";
import {ErrorHandler} from "../common/loading-wrapper/error-handler/error-handler";
import "../common/loading-wrapper/loading-wrapper.scss";
import {fragments} from "../../../react/fragments";
import {GetFiltersData} from "../../live/live-dashboard/data/get-filters-data";
import {Watch} from "../../../react/watch";

export const loadMapTileData = ({next: rootNext, tile, theme, size, isEditTile, tileFilters, loadData, tileKey, tableOverrides, keepOutdatedValue = false, disabled, mapTileDataState}) =>
    cs(
        ({}, next) => {
            return fragments(
                // expose filters data for debug function when editing a tile
                // isEditTile && Watch({
                //     initRun: true,
                //     value: {
                //         tableOverrides,
                //     },
                //     onChanged: ({tableOverrides}) => {
                //         if (tableOverrides) {
                //             GetFiltersData.setTableOverrides(tableOverrides)
                //         }
                //     }
                // }),
                loadData?.load
                    ? next()
                    : rootNext({
                          tileData: null,
                          mapData: null,
                          loading: true,
                      })
            );
        },
        [
            "mapTileData",
            ({}, next) =>
                Load2({
                    keepOutdatedValue,
                    disabled: tileFilters.invalid || disabled,
                    captureException: true,
                    _key: JSON.stringify({
                        key: loadData.key,
                        tileFilters: {
                            key: tileFilters.key,
                        },
                        tileKey,
                        tableOverrides,
                    }),
                    fetch: async () => {
                        const tileData = await loadData.load({
                            filters: tileFilters.getValue(),
                            extraCacheKey: tileKey,
                            tableOverrides,
                        });

                        const mapData = await fetchMapData(tileData.mapGeoJson);
                        mapTileDataState.onChange({tileData, mapData, tileKey});

                        return {tileData, mapData};
                    },
                    next,
                }),
        ],
        ({mapTileData}, next) => {
            return mapTileData.error ? <div className="loading-wrapper-23e">{ErrorHandler({error: mapTileData.error, size})}</div> : next();
        },
        ({mapTileData}) => {
            return rootNext({
                tileData: mapTileData.value?.tileData,
                mapData: mapTileData.value?.mapData,
                loading: mapTileData.loading,
            });
        }
    );
