import { formatDateTime } from "@common/logic/date-time/format-date-time";
import { parseDateTime } from "@common/logic/date-time/parse-date-time";
import { Static2 } from "@common/react/static-2";
import { transformState } from "@common/react/transform-state";
import { AccessibleDatePicker } from "@common/ui-components/live/filters/date-picker/accessible-date-picker";
import { DpMaskInput } from "@common/ui-components/live/filters/dp-mask-input/dp-mask-input";
import { css, cx } from "emotion";
import * as React from "react";
import { cs } from "../../react/chain-services";
import { Dropdown } from "../../ui-components/dropdown/dropdown";
import "./date-picker-form.scss";

export const DatePickerForm = ({label, state: rootState, domRef, hasError, className}) =>
    cs(
        [
            "state",
            (_, next) => {
                return next(
                    transformState({
                        state: rootState,
                        goingIn: (strDate) => parseDateTime(strDate)?.date,
                        gettingOut: (dateObj) => formatDateTime({date: dateObj}),
                    })
                )
            }
        ],
        [
            "picker",
            ({state}, next) => {
                return AccessibleDatePicker({
                    next,
                    value: state.value,

                })
            },
        ],
        ["toggleRef", (_, next) => Static2({next})],
        ["input", ({state, picker}, next) => picker.getInput({
            next,
            onChange: date => {
                state.onChange(date);
            }
        })],

        ["dropdown", ({toggleRef, picker, state, input}, next) => {
            const reset = () => {
                input.reset();
            }
            return Dropdown({
                minExpandHeight: 242,
                next,
                onPassiveClose: reset,
                onDropdownClose: reset,
                customToggleRef: toggleRef,
                closeAllOtherDropdownWhenOpen: false,
                renderExpand: ({close, width}) => {

                    return (
                        <div
                            className="calendar-box"
                        >
                            {picker.render({
                                onChange: (date) => {
                                    state.onChange(date)
                                    close();
                                },

                                space: 4,
                                width
                            })}
                        </div>
                    )
                }
            })
        }],

        ({input, toggleRef, dropdown}) => {
            return (
                <div className={cx("date-picker-form-2z6", className)} ref={domRef}>
                    {label && <div className="label">{label}</div>}
                    <div className="toggle" ref={toggleRef.set}>

                        {DpMaskInput({
                            ...input,
                            showDisplayValue: !dropdown?.showingExpand,
                            className: cx({active: dropdown?.showingExpand, invalid: hasError}),
                            onClick: (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                dropdown.showExpand(true);
                            },

                            state: input.state,
                        })}
                    </div>
                </div>
            )
        }
    );
