import {omit} from "../../../../../../common/utils/objects";
import {dictionaryTypes} from "./steps/outbound-api-steps";

export const outboundAPITransformData = ($type, connectionDetails) => {
    return {
        $type: $type,
        ...connectionDetails,
        inputs: (connectionDetails.inputs || [])?.reduce((ret, item) => {
            ret[item.key] = omit(item, ["key", "ID"]);
            return ret;
        }, {}),
        requests: (connectionDetails.requests || [])?.map((r) => ({
            ...r,
            ...(r.content
                ? {
                      content: {
                          ...r.content,
                          ...(dictionaryTypes.includes(r.content.$type)
                              ? {
                                    data: r.content.data.reduce((ret, item, i) => {
                                        if (ret[item.key]) {
                                            ret[item.key] = item.value;
                                        }
                                        return ret;
                                    }, {}),
                                }
                              : {}),
                      },
                  }
                : {}),
            headers: (r.headers || [])?.reduce((ret, item, i) => {
                ret[item.key] = item.value;
                return ret;
            }, {}),
            resultMapping: (r.resultMapping || [])?.reduce((ret, item, i) => {
                if (ret[item.name]) {
                    ret[item.name] = omit(item, ["name", "ID"]);
                }
                return ret;
            }, {}),
        })),
    };
};

export const outboundAPITransformDataToView = (connectionDetails) => {
    return {
        ...connectionDetails,
        inputs: Object.keys(connectionDetails.inputs).map((key) => ({
            ...connectionDetails.inputs[key],
            key,
        })),
        requests: connectionDetails.requests.map((d) => ({
            ...d,
            ...(d.content
                ? {
                      content: {
                          ...d.content,
                          ...(dictionaryTypes.includes(d.content.$type)
                              ? {
                                    data: Object.keys(d.content.data).map((key) => ({
                                        value: d.content.data[key],
                                        key,
                                    })),
                                }
                              : {}),
                      },
                  }
                : {}),
            headers: Object.keys(d.headers).map((key) => ({
                value: d.headers[key],
                key,
            })),
            resultMapping: Object.keys(d.resultMapping).map((key) => ({
                ...d.resultMapping[key],
                name: key,
            })),
        })),
    };
};
