import {LeftOuterJoinIcon} from "../../tabs/left-panel-overrides/create-relationship/icons";

export const OuterJoinIcons = ({centerPos, color}) => {
    return (
        <g
            fill="#fff"
            style={{
                pointerEvents: "none",
            }}
        >
            <rect
                width="18.667"
                height="12"
                {...{
                    x: centerPos?.x - 9,
                    y: centerPos?.y - 6,
                }}
            />
            <LeftOuterJoinIcon position={centerPos} fill={color} />
        </g>
    );
};
