import {cx} from "emotion";
import {LinkIcon} from "../icons/editor-icons";
import {createElement as h, useCallback} from "react";
import {$isLinkNode, TOGGLE_LINK_COMMAND} from "@lexical/link";
import {cs} from "@common/react/chain-services";
import "./editor-link-toolbar.scss";
import {TextInput} from "../../../../../../../common/form/text-input/text-input";
import {Button} from "../../../../../../../common/form/buttons/button/button";
import {UseState} from "@common/react/use-state";
import {URL_MATCHER} from "../../plugin/auto-detect-link-plugin";
import {UseCallback} from "@common/react/use-callback";
import {$getSelection, $isRangeSelection} from "lexical";
import {UseEffect} from "@common/react/use-effect";
import {getSelectedNode} from "../editor-toolbar-common";
import {AnyAction2} from "@common/react/any-action-2";
import {Static2} from "@common/react/static-2";
import {AnyAction} from "@common/react/any-action";

export const EditorLinkToolbar = ({editor, isLink, selectionTextContent}) =>
    cs(["showPopup", (_, next) => UseState({next})], ({showPopup}) => {
        return (
            <>
                <div
                    className="group-actions"
                    style={{
                        borderRight: 0,
                    }}
                >
                    <div
                        className={cx("action", (isLink.value || showPopup.value) && "active")}
                        style={{
                            marginRight: 0,
                        }}
                        onClick={() => {
                            if (!isLink.value) {
                                if (selectionTextContent.value?.length > 0) {
                                    showPopup.onChange(true);
                                }
                            } else {
                                showPopup.onChange(true);
                            }
                        }}
                    >
                        <LinkIcon />

                        <div className="tooltip-box">{isLink.value ? "Edit" : "Add"} Link</div>
                    </div>

                    {showPopup.value && <EditorLinkPopup editor={editor} showPopup={showPopup} editMode={isLink.value} />}
                </div>
            </>
        );
    });

const EditorLinkPopup = ({editor, showPopup, editMode}) =>
    cs(
        ["url", (_, next) => UseState({next, initValue: ""})],
        ["domRef", (_, next) => Static2({next})],
        (_, next) =>
            h(AnyAction, {
                fn: () => {
                    showPopup.onChange(false);
                },
                next,
            }),
        [
            "updateLinkEditor",
            ({url}, next) =>
                UseCallback({
                    next,
                    fn: () => {
                        const selection = $getSelection();
                        if ($isRangeSelection(selection)) {
                            const node = getSelectedNode(selection);
                            const parent = node.getParent();
                            if ($isLinkNode(parent)) {
                                url.onChange(parent.getURL());
                            } else if ($isLinkNode(node)) {
                                url.onChange(node.getURL());
                            }
                        }
                    },
                    deps: [editor],
                }),
        ],
        ({updateLinkEditor}, next) =>
            UseEffect({
                next,
                fn: () => {
                    editor.getEditorState().read(() => {
                        updateLinkEditor();
                    });
                },
                deps: [editor, updateLinkEditor],
            }),
        ({url}) => {
            return (
                <div className="editor-link-popup-a33">
                    <div className="popup-title">{editMode ? "Edit" : "Add"} Link</div>

                    <TextInput className="url-input" label="URL" state={url} />

                    <div className="buttons">
                        <Button
                            size="small"
                            btnType="border"
                            onClick={() => {
                                showPopup.onChange(false);
                            }}
                        >
                            Cancel
                        </Button>

                        {editMode && (
                            <Button
                                size="small"
                                btnType="secondary"
                                onClick={() => {
                                    editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
                                    showPopup.onChange(false);
                                }}
                            >
                                Remove Link
                            </Button>
                        )}

                        <Button
                            size="small"
                            btnType="primary"
                            disabled={!URL_MATCHER.test(url.value)}
                            onClick={() => {
                                editor.dispatchCommand(TOGGLE_LINK_COMMAND, url.value);
                                showPopup.onChange(false);
                            }}
                        >
                            {editMode ? "Update" : "Save"}
                        </Button>
                    </div>
                </div>
            );
        }
    );
