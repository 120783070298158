import "./panel.scss";

import React from "react";
import {cx} from "emotion";

import {VerbScrollbar} from "../verb-scrollbar/verb-scrollbar";
import {ObserveDomSize} from "../../react/observe-dom-size";
import {cs} from "../../react/chain-services";
import {UseState} from "../../react/use-state";

export const defaultHeaderSizes = ["normal", "large"];

export const BasicPanel = (props) =>
    cs(
        ["size", (_, next) => UseState({next, initValue: null})],
        [
            "contentPanelRef",
            ({size}, next) =>
                ObserveDomSize({
                    next,
                    invokeOnStart: true,
                    ignoreChange: (newSize, oldSize) => newSize.width === oldSize.width,
                    onChange: (_size) => size.onChange(_size),
                }),
        ],
        ({contentPanelRef, size}) => {
            const {className = "", headerSize = "normal", title = null, subTitle = null, rightControl = null, children, style} = props;
            return (
                <div style={{height: "100%"}} className={cx("panel-f4x", className, defaultHeaderSizes.includes(headerSize) && `header-${headerSize}`)}>
                    {(title || rightControl || subTitle) && (
                        <div className={cx("header", subTitle && "has-subtitle")}>
                            <div className="title">
                                {title}
                                {subTitle && <div className="subtitle">{subTitle}</div>}
                            </div>

                            <div className="space" />

                            {rightControl && <div className="right-control">{rightControl}</div>}
                        </div>
                    )}

                    <VerbScrollbar getRef={contentPanelRef.ref} style={style} className="content">
                        {size.value && children(size)}
                    </VerbScrollbar>
                </div>
            );
        }
    );
