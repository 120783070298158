import React from "react";
import {cs} from "@common/react/chain-services";
import {StaticTooltipService} from "../../../../../../../../../../../common/tooltip3/static-tooltip-service";
import {ShowHideToggle} from "../../../../../../../../../../../../../../common/form/toggles/show-hide-toggle";
import {scope} from "@common/react/scope";
import {CheckboxLine} from "../../../../../../../../../../../common/checkbox-line/checkbox-line";
import {ComparisonMethodSelect} from "../../../../common/comparison-method-select";
import {PositiveChangeValueSelect} from "../../../../common/positive-change-value-select";
import {LpLine} from "../../../../../../../../../../../common/left-panel/line/lp-line";

export const ComparisonDateRange = ({tile, label = "Comparison Date Range", getKey = (k) => k}) => (
    <div className="single-kpi-value-format-yio">
        <div className="subsection">
            {(() =>
                cs(
                    [
                        "staticTooltip",
                        (_, next) =>
                            StaticTooltipService({
                                direction: "top",
                                info: "When a date filter is applied with a comparison range, the comparison data can be hidden.",
                                tooltipBoxWidth: 200,
                                topOffset: 20,
                                leftOffset: -5,
                                next,
                            }),
                    ],
                    ({staticTooltip}) => (
                        <div className="header">
                            {label}
                            {staticTooltip.renderIcon({
                                icon: <i className="material-icons">info</i>,
                                className: "info-tooltip",
                            })}
                            <div className="control">
                                {ShowHideToggle({
                                    state: scope(tile, ["style", getKey("showCompare")]),
                                    defaultValue: true,
                                })}
                            </div>
                        </div>
                    )
                ))()}
        </div>

        {scope(tile, ["style", getKey("showCompare")]).value && (
            <>
                <div className="subsection">
                    <div className="header">
                        Previous Period
                        <div className="control">
                            {ShowHideToggle({
                                state: scope(tile, ["style", getKey("previousPeriodShown")]),
                            })}
                        </div>
                    </div>
                </div>

                {(() => {
                    const compareValueMethod = scope(tile, ["style", getKey("compareValueMethod")]);

                    return (
                        <div className="subsection">
                            <div className="header">
                                Comparison Value
                                <div className="control">
                                    {ShowHideToggle({
                                        state: {
                                            value: !!compareValueMethod.value,
                                            onChange: (val) => compareValueMethod.onChange(val ? "Percent" : null),
                                        },
                                    })}
                                </div>
                            </div>
                            {compareValueMethod.value && (
                                <div className="content">
                                    {ComparisonMethodSelect(scope(tile, ["style", getKey("compareValueMethod")]))}

                                    {LpLine({
                                        label: "Comparison Indicator",
                                        control: ShowHideToggle({
                                            state: scope(tile, ["style", getKey("indicatorShown")]),
                                        }),
                                    })}

                                    {tile.value.style[getKey("indicatorShown")] && PositiveChangeValueSelect(scope(tile, ["style", getKey("positiveChangeGood")]))}
                                </div>
                            )}
                        </div>
                    );
                })()}

                {/*<div className="subsection">*/}
                {/*    <div className="header">*/}
                {/*        Comparison Indicator*/}
                {/*        <div className="control">*/}
                {/*            {ShowHideToggle({state: scope(tile, ["style", getKey("indicatorShown")])})}*/}
                {/*        </div>*/}
                {/*    </div>*/}

                {/*    /!* {tile.value.style[getKey("indicatorShown")] && ( *!/*/}
                {/*        <div className="content">*/}
                {/*            {PositiveChangeValueSelect(scope(tile, ["style", getKey("positiveChangeGood")]))}*/}
                {/*        </div>*/}
                {/*    /!* )} *!/*/}
                {/*</div>*/}
            </>
        )}
    </div>
);
