export const downloadWithToast = async ({_key, fetch, toast}) => {
    toast?.add({id: _key});

    const resp = await fetch();

    toast?.update({id: _key});
    setTimeout(() => toast?.remove({id: _key}), 2 * 1000);

    return resp;
};
