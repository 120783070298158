import React from "react";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {DropdownSelect2} from "@common/ui-components/dropdown-select/dropdown-select2";
import {keyed} from "@common/react/keyed";
import {CheckboxLine} from "../../../../../../../common/checkbox-line/checkbox-line";
import {TextInput} from "../../../../../../../../../../common/form/text-input/text-input";
import {spc} from "@common/react/state-path-change";
import {TextManipulationResultActionSelect} from "../../common/text-manipulation-result-action-select";
import {rColumnValueSelectFromStepOutput} from "../../../../../common/column-value-select-from-step-output/column-value-select-from-step-output";
import {ffToDropdown} from "../../../../../../../../../../common/form/ff-to-dropdown";
import {keepOnly} from "@common/utils/objects";
import "./trim-configuration.scss";

const characterValues = ["Space", "Semicolon", "Comma", "Tab"];

export const TrimConfiguration = ({textManipulation, inputStep, model, form, state}) =>
    cs(
        [
            "state1",
            ({}, next) =>
                UseState({
                    initValue: (textManipulation.value?.characters || []).filter((t) => !characterValues.includes(t.value)).length > 0 ? "Other" : null,
                    next,
                }),
        ],
        ({state1}) => {
            return (
                <div className="trim-configuration-5rt">
                    <div className="config-group">
                        <div className="label">Input Column</div>

                        {(() => {
                            const dropdownProps = ffToDropdown(form.field(["textManipulation", "inputValue"]));
                            return rColumnValueSelectFromStepOutput({
                                label: "Column",
                                columns: inputStep.outputColumns,
                                model,
                                disableStaticValue: true,
                                filterColumnFn: (c) => ["Text"].includes(c.dataType),
                                isValueNotAllowed: (c) => !["Text"].includes(c.dataType),
                            })({
                                state: {
                                    value: dropdownProps.value,
                                    onChange: dropdownProps.onChange,
                                },
                                ...keepOnly(dropdownProps, ["domRef", "hasError", "errorMessage"]),
                            });
                        })()}
                    </div>

                    <div className="config-group">
                        <div className="label">Trim Formula</div>

                        <div className="formula">
                            {DropdownSelect2({
                                label: "Trim From",
                                list: [
                                    {value: "Start", label: "Beginning"},
                                    {value: "End", label: "End"},
                                    {
                                        value: "Both",
                                        label: "Both Beginning & End",
                                    },
                                ],
                                valueToLabel: (v) => v.label,
                                ...ffToDropdown(form.field(["textManipulation", "trimFrom"]), ["value"]),
                            })}

                            <div className="list">
                                <div className="label">Value to Trim</div>
                                <div className="checkbox-list">
                                    {[...characterValues].map((item, index) =>
                                        cs(keyed(index), () => (
                                            <div className="checkbox-item">
                                                {CheckboxLine({
                                                    label: item,
                                                    state: {
                                                        value: ((textManipulation.value?.characters || []).map((c) => c.value) || []).indexOf(item) > -1,
                                                        onChange: (value) => {
                                                            spc(textManipulation, ["characters"], (values) =>
                                                                value
                                                                    ? [
                                                                          ...(values || []),
                                                                          {
                                                                              value: item,
                                                                              $type: "StaticValue",
                                                                          },
                                                                      ]
                                                                    : (values || []).filter((v) => v.value !== item)
                                                            );
                                                        },
                                                    },
                                                })}
                                            </div>
                                        ))
                                    )}

                                    <div className="checkbox-item">
                                        {CheckboxLine({
                                            label: "Other",
                                            state: {
                                                value: state1.value === "Other",
                                                onChange: (value) => {
                                                    spc(textManipulation, ["characters"], (values) =>
                                                        value
                                                            ? [
                                                                  ...(values || []),
                                                                  {
                                                                      value: "",
                                                                      $type: "StaticValue",
                                                                  },
                                                              ]
                                                            : (values || []).filter((v) => characterValues.indexOf(v.value) > -1)
                                                    );
                                                    state1.onChange(value ? "Other" : null);
                                                },
                                            },
                                        })}

                                        {state1.value === "Other" && (
                                            <>
                                                <div className="input-group">
                                                    {(textManipulation.value.characters || []).map((c, index) => {
                                                        if (characterValues.indexOf(c.value) > -1) return <></>;
                                                        return TextInput({
                                                            state: {
                                                                value: c.value,
                                                                onChange: (value) =>
                                                                    spc(textManipulation, ["characters", index], (v) => ({
                                                                        ...v,
                                                                        value,
                                                                    })),
                                                            },
                                                        });
                                                    })}

                                                    <button
                                                        className="btn-add"
                                                        onClick={() =>
                                                            spc(textManipulation, ["characters"], (values) => [
                                                                ...(values || []),
                                                                {
                                                                    value: "",
                                                                    $type: "StaticValue",
                                                                },
                                                            ])
                                                        }
                                                    >
                                                        Add
                                                    </button>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {TextManipulationResultActionSelect({
                        state: textManipulation,
                        form,
                    })}
                </div>
            );
        }
    );
