import {chain} from "@common/utils/fs";
import {UseState} from "@common/react/use-state";
import {cs} from "@common/react/chain-services";
import {DateRangesPicker} from "@common/ui-components/live/filters/date-ranges-picker/date-ranges-picker";
import {Dropdown} from "@common/ui-components/dropdown/dropdown";
import * as React from "react";
import "./date-range-picker.scss";
import {Colors} from "@common/ui-components/styles/colors";
import {cx} from "emotion";
import {AccessibleDateRangesPicker} from "@common/ui-components/live/filters/date-ranges-picker/accessible-date-ranges-picker";
import {DpMaskInput} from "@common/ui-components/live/filters/dp-mask-input/dp-mask-input";
import {Static2} from "@common/react/static-2";
import {SubcribeKeyEvents} from "@common/react/keys/global-key-down";

export const DateRangePicker = ({state: rootState, domRef, hasError}) => {
    return cs(
        ["state", (_, next) => UseState({initValue: rootState.value, next})],
        [
            "picker",
            ({state}, next) => {
                return AccessibleDateRangesPicker({
                    next,
                    ranges: [{color: Colors.blue, range: state.value}],
                });
            },
        ],
        ["toggleRef", (_, next) => Static2({next})],
        ["calendar", ({picker}, next) => picker.initSingleCalendar({next})],
        ["isCalPicker", (_, next) => UseState({next})],
        [
            "rangeInputs",
            ({calendar, state, isCalPicker}, next) =>
                calendar.getRangeInputs({
                    next,
                    onChange: ({range}) => {
                        isCalPicker.onChange(false);
                        state.onChange(range);
                    },
                }),
        ],
        [
            "dropdown",
            ({toggleRef, calendar, rangeInputs, state, calendarPicker, isCalPicker}, next) => {
                // TODO: recheck reset function contains old value state
                let reset = isCalPicker.value
                    ? null
                    : () => {
                          rangeInputs.from.resetInput();
                          rangeInputs.to.resetInput();
                          if (rangeInputs.from.valid && rangeInputs.to.valid) {
                              rootState.onChange(state.value);
                          } else {
                              state.onChange(rootState.value);
                          }
                      };

                return Dropdown({
                    minExpandHeight: 242,
                    // registryRender: false,
                    disabledTab: true,
                    // ...(isCalPicker.value
                    //     ? {}
                    //     : {
                    //           onPassiveClose: reset,
                    //           onDropdownClose: reset,
                    //       }),

                    onPassiveClose: reset,
                    onDropdownClose: reset,
                    next,
                    customToggleRef: toggleRef,
                    renderExpand: ({close, width}) => {
                        return (
                            <div className="calendar-box">
                                {calendar.render({
                                    onChange: ({range, attr}) => {
                                        isCalPicker.onChange(true, () => {
                                            rootState.onChange(range);
                                            state.onChange(range);
                                        });

                                        if (attr === "to") {
                                            close();
                                        }
                                    },
                                    space: 4,
                                    width,
                                })}
                            </div>
                        );
                    },
                });
            },
        ],
        ({rangeInputs: {from, to}, dropdown, toggleRef}) => {
            const isFromExpand = from.isSelected && dropdown?.showingExpand;
            const isToExpand = to.isSelected && dropdown?.showingExpand;
            return (
                <div className="date-range-picker-expand-8c8" ref={domRef}>
                    {SubcribeKeyEvents({
                        events: [
                            {
                                keyCombo: "Tab",
                                onKeyDown: () => {
                                    isFromExpand ? to.onClick() : dropdown.closeDropdown();
                                },
                            },
                            {
                                keyCombo: "Shift+Tab",
                                onKeyDown: () => {
                                    isToExpand ? from.onClick() : dropdown.closeDropdown();
                                },
                            },
                        ],
                    })}
                    <div className="range" ref={toggleRef.set}>
                        {DpMaskInput({
                            ...from,
                            showDisplayValue: !isFromExpand,
                            className: cx({active: isFromExpand, invalid: !from.valid || hasError}),
                            onClick: (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                dropdown.showExpand(true);
                                from.onClick(e);
                            },

                            state: from.state,
                        })}
                        <div className="dash">to</div>
                        {DpMaskInput({
                            ...to,
                            showDisplayValue: !isToExpand,
                            className: cx({active: isToExpand, invalid: !to.valid || hasError}),
                            onClick: (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                dropdown.showExpand(true);
                                to.onClick(e);
                            },

                            state: to.state,
                        })}
                    </div>
                </div>
            );
        }
    );
};
