import React from "react";
import {cs} from "@common/react/chain-services";
import {LpColorLine} from "../../../../../common/left-panel/lp-color-line";
import {ffToColorPickerLine} from "../../../../../../../../common/form/ff-to-color-picker-line";
import {NumberInput} from "../../../../../../../../common/form/number-input/number-input";
import {ffToNumberInput} from "../../../../../../../../common/form/ff-to-number-input";

export const ButtonToggleTheme = ({form}) => (
    <div className="content">
        {LpColorLine({
            label: "Default Background Color",
            ...ffToColorPickerLine(form.field(["general", "buttons", "buttonToggleGroup", "backgroundColorRGB"])),
        })}

        {LpColorLine({
            label: "Default Text Color",
            ...ffToColorPickerLine(form.field(["general", "buttons", "buttonToggleGroup", "fontColorRGB"])),
        })}

        {LpColorLine({
            label: "Active Background Color",
            ...ffToColorPickerLine(form.field(["general", "buttons", "buttonToggleGroup", "activeBackgroundColorRGB"])),
        })}

        {LpColorLine({
            label: "Active Text Color",
            ...ffToColorPickerLine(form.field(["general", "buttons", "buttonToggleGroup", "activeFontColorRGB"])),
        })}

        {LpColorLine({
            label: "Default Border Color",
            ...ffToColorPickerLine(form.field(["general", "buttons", "buttonToggleGroup", "borderColorRGB"])),
        })}

        {LpColorLine({
            label: "Active Border Color",
            ...ffToColorPickerLine(form.field(["general", "buttons", "buttonToggleGroup", "activeBorderColorRGB"])),
        })}

        {NumberInput({
            label: "Border Width",
            unit: "px",
            ...ffToNumberInput(form.field(["general", "buttons", "buttonToggleGroup", "borderWidth"])),
            showZero: true,
        })}

        {NumberInput({
            label: "Corner Radius",
            unit: "px",
            ...ffToNumberInput(form.field(["general", "buttons", "buttonToggleGroup", "cornerRadius"])),
            showZero: true,
        })}
    </div>
);
