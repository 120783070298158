import React from "react";
import {cs} from "../../../../react/chain-services";
import {UseState} from "../../../../react/use-state";
import {RevalidateChartService} from "../../render-chart/legend/display/revalidate-chart-service";
import {Static2} from "../../../../react/static-2";
import "./scatter-legend-display.scss";
import {ScatterLegendTopBottomVerticalDisplay} from "@common/ui-components/charts/scatter-plot/legend/scatter-legend-top-bottom-vertical-display";
import {ScatterLegendNormalDisplay} from "@common/ui-components/charts/scatter-plot/legend/scatter-legend-normal-display";

export const ScatterLegendDisplay = (props) => {
    const {chartRef, legendRoom, tile, size, options, theme, isCompare, formatters} = props;

    if (!tile.style.legendStyle.legendShown) {
        return null;
    }

    return cs(
        [
            "chart",
            (_, next) =>
                RevalidateChartService({
                    props: {tile, theme},
                    chartRef,
                    next,
                }),
        ],
        ["scrollLeft", (_, next) => UseState({next, initValue: null})],
        ["legendContainerRef", (_, next) => Static2({next})],
        ["hidden", (_, next) => UseState({next})],
        ({chart, hidden, legendContainerRef, scrollLeft}) => {
            const LegendDisplay = ["TopVertical", "BottomVertical"].includes(tile.style.legendStyle.legendPosition) ? ScatterLegendTopBottomVerticalDisplay : ScatterLegendNormalDisplay;

            return LegendDisplay({
                tile,
                theme,
                size,
                formatters,
                options,
                chart,
                hidden,
                legendRoom,
                legendContainerRef,
                scrollLeft,
            });
        }
    );
};
