import React from "react";

const SyncOnce = ({className}) => (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="11" height="6.43" viewBox="0 0 11 6.43">
        <defs>
            <clipPath id="clip-path">
                <rect id="Rectangle_2599" data-name="Rectangle 2599" width="11" height="6.43" transform="translate(0 0)" fill="none" />
            </clipPath>
        </defs>
        <g id="SyncOnce" transform="translate(0 0)">
            <g id="Group_1546" data-name="Group 1546" clipPath="url(#clip-path)">
                <path id="Path_836" data-name="Path 836" d="M2,6.218a.51.51,0,0,1-.2-.042A3,3,0,0,1,1.8.682a.5.5,0,0,1,.4.916,2,2,0,0,0,0,3.662.5.5,0,0,1-.2.958" fill="#1ac689" />
                <path
                    id="Path_837"
                    data-name="Path 837"
                    d="M9,6.218a.5.5,0,0,1-.2-.958A2,2,0,0,0,8.8,1.6.5.5,0,1,1,9.2.682a3,3,0,0,1,0,5.494.506.506,0,0,1-.2.042"
                    fill="#1ac689"
                />
                <path id="Path_838" data-name="Path 838" d="M6.151,6.43H5.334V1.012l-1.64.6V.875L6.024,0h.127Z" fill="#1ac689" />
            </g>
        </g>
    </svg>
);

export default SyncOnce;
