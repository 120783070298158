import {cs} from "@common/react/chain-services";
import "./prefix-suffix-input.scss";
import {DebounceTextInput} from "@common/form/debounce-input/debounce-text-input";
import {StaticTooltipService2} from "../tooltip3/static-tooltip-service";

export const PrefixSuffixInput = ({label, prefixState, suffixState, rTooltipContent}) =>
    cs(
        [
            "staticTooltip",
            (_, next) =>
                StaticTooltipService2({
                    tooltipContent: (props) => {
                        return (
                            <div className="value-chip-tooltip-content-93d" {...props}>
                                {rTooltipContent()}
                            </div>
                        );
                    },
                    next,
                }),
        ],
        ({staticTooltip}) => {
            return (
                <div className="prefix-suffix-input-9x2 prefix-suffix-input">
                    <div className="label">{label}</div>
                    <div className="content">
                        {DebounceTextInput({
                            className: "prefix",
                            placeholder: "Prefix",
                            state: prefixState,
                        })}

                        {staticTooltip.render({
                            content: (props) => (
                                <div className="value-chip" {...props}>
                                    {`{ }`} Value
                                </div>
                            ),
                        })}

                        {DebounceTextInput({
                            className: "suffix",
                            placeholder: "Suffix",
                            state: suffixState,
                        })}
                    </div>
                </div>
            );
        }
    );
