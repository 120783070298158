import {cs} from "@common/react/chain-services";
import {RoutingParam} from "../../../common/routing/routing-param";
import {ApiCollectionTab} from "../../../api-collection/edit/tabs/collection/api-collection-tab";
import {ApiFiltersTab} from "../../../api-collection/edit/tabs/filters/api-filters-tab";
import {ApiFieldsTab} from "../../../api-collection/edit/tabs/fields/api-fields-tab";
import {GeneralTab} from "./general/general-tab";
import {UseState} from "@common/react/use-state";
import {DataVizTab} from "./data-viz/data-viz-tab";

export const EditThemeTab = ({form, next, viewTree}) =>
    cs(
        [
            "selectedTab",
            (_, next) =>
                RoutingParam({
                    param: "tab",
                    defaultValue: "general",
                    next,
                }),
        ],
        [
            "tabs",
            (_, next) =>
                cs(
                    [
                        "generalTab",
                        ({}, next) =>
                            GeneralTab({
                                next,
                                form,
                                viewTree,
                            }),
                    ],
                    ["dataVizTab", ({}, next) => DataVizTab({next, form, viewTree})],
                    ({generalTab, dataVizTab}) =>
                        next([
                            {
                                id: "general",
                                label: "General",
                                control: generalTab,
                            },
                            {
                                id: "data-viz",
                                label: "Data Viz",
                                control: dataVizTab,
                            },
                        ])
                ),
        ],
        ({tabs, selectedTab}) => {
            const tab1 = tabs.find((tab) => tab.id === selectedTab.value) || tabs[0];
            if (tabs.filter((t) => t.id == selectedTab.value).length == 0) selectedTab.value = tabs[0].id;

            return next({
                tabs: tabs.map((tab) => ({
                    ...tab,
                    render: tab.control?.renderLeftPanel,
                })),
                selectedTab,
                panelOverride: tab1?.control?.panelOverride,
            });
        }
    );
