import {createElement as h} from "react";
import {cs} from "@common/react/chain-services";
import {loadRouting} from "../loaders/load-routing";
import {guestRoutes} from "./guest-routes";
import {gaPageView} from "../../common/ga/ga-pageview";
import {consumeContext, provideContext} from "@common/react/context";
import {createGuestApis} from "../../apis/guest/guest-apis";
import {ErrorBoundary} from "../../common/error-boundary/error-boundary";
import {UseState} from "@common/react/use-state";
import {createApiCollectionApis} from "../../apis/data-access/api-collection-apis";

export const loadGuestRoutes = ({intendedRoute}) =>
    cs(
        consumeContext("auth"),
        ["apiDocs", (_, next) => UseState({next})],
        [
            "routing",
            ({auth}, next) =>
                loadRouting({
                    routes: guestRoutes,
                    getDefaultRoute: () => {
                        const intended = (() => {
                            if (auth.rejectCurrentAuthRoute.value) {
                                // setTimeout(() => auth.rejectCurrentAuthRoute.onChange(false), 0);
                                return null;
                            }
                            return window.location.hash?.substring(1);
                        })();
                        return "/login" + (!intended || intended === "/dashboard" ? "" : "?intendedRoute=" + encodeURIComponent(intended));
                    },
                    next,
                }),
        ],
        ({}, next) => gaPageView({next}),
        ({}, next) => provideContext("apis", createGuestApis(), next),
        ({}, next) => provideContext("apiCollectionApis", createApiCollectionApis(), next),
        ({routing}, next) => ErrorBoundary({next, routing}),
        ({routing, apiDocs}) =>
            h(routing.route.component, {
                setIntendedRoute: intendedRoute.onChange,
                apiDocs,
            })
    );
