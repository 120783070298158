import React from "react";
import {cs} from "@common/react/chain-services";
import {VerbDashboard} from "../../../../../sdk/main/verb-dashboard";
import {sdkStaticUrl} from "../../../../../sdk/main/loaders/sdk-static-url";
import {consumeContext} from "@common/react/context";

export const IframeView = ({}) =>
    cs(consumeContext("routing"), ({routing, auth}) =>
        VerbDashboard({
            collectionId: routing.params.collectionID,
            iframeKeyName: routing.params.iframeKeyName,
            apiKey: routing.params.key,
            authParams: {},
            version: "v1.0",
            sdkStaticUrl: sdkStaticUrl(window.location.href),
            hiddenFilterIds: null,
        })
    );
