import "./default-filter-criteria.scss";

import * as React from "react";

import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {scope} from "@common/react/scope";
import {spc} from "@common/react/state-path-change";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {SelectableListChips} from "@common/ui-components/live/live-dashboard/live-filters/filter-types/text/selectable-list/chips/selectable-list-chips";
import {SearchableSelect} from "@common/ui-components/searchable-select/searchable-select";
import {cascadeCollect} from "@common/utils/cascade";
import {ffToDropdown} from "@common/form/ff-to-dropdown";
import {filterTypesToColumnTypes} from "../../../../filter-left-panel/filter-configs";
import {isCustomSelectableRanges} from "../date-picker-selectable-range/date-picker-selectable-range";
import {dateRanges} from "../date-ranges";
import {editCustomRanges} from "./custom-date-ranges/edit-custom-ranges";
import {defaultCustomRanges, DEFAULT_LAST_OR_NEXT_DAYS_VALUE} from "./default-custom-ranges";

export const DefaultFilterCriteria = ({filter, form, collection}) =>
    cs(
        consumeContext("modelForCollection"),
        [
            "fields",
            ({modelForCollection: model}, next) =>
                next(
                    cascadeCollect(
                        model,
                        "tables[*].columns[*]",
                        (column, {}, {1: table}) => filterTypesToColumnTypes[filter.value.$type].includes(column.dataType) && {table, column}
                    )
                ),
        ],

        ({fields}) => {
            const isCustomFilter = filter.value.defaultOption === "Custom";
            const isPastCustom = isCustomSelectableRanges(filter.value.pickerMaxDaysInPast);
            const isFutureCustom = isCustomSelectableRanges(filter.value.pickerMaxDaysInFuture);
            const selectionRangesIsSet = isPastCustom || isFutureCustom;

            return (
                <div className="default-filter-criteria-836">
                    <div className="label">Default Filter Criteria</div>

                    {filter.value.columns?.length > 1 && (
                        <>
                            {filter.value.multiSelectionOption !== "SingleSelect" ? (
                                <div className="select-multiple-field" ref={form.field(["selectedFields"]).domRef}>
                                    <div className="label">Selected Fields</div>

                                    {SelectableListChips({
                                        closeAllOtherDropdownWhenOpen: false,
                                        hasError: {
                                            value: form.field(["selectedFields"]).error,
                                        },
                                        className: "selectable-chips",
                                        list: filter.value.columns || [],
                                        ...(() => {
                                            const isSelected = (v) => v.default;
                                            return {
                                                isSelected,
                                                onSelect: (v) => {
                                                    const update = (val) =>
                                                        filter.change((filter) => ({
                                                            ...filter,
                                                            columns: filter.columns.map((item) =>
                                                                item.modelColumnID === v.modelColumnID
                                                                    ? {
                                                                          ...item,
                                                                          default: val,
                                                                      }
                                                                    : item
                                                            ),
                                                        }));
                                                    update(!v.default);
                                                },
                                                valueToLabel: (v) => fields.find((item) => item.column.id === v.modelColumnID)?.column?.visualNameFull,
                                            };
                                        })(),
                                    })}
                                </div>
                            ) : (
                                <div className="">
                                    {SearchableSelect({
                                        label: "Selected Fields",
                                        hasError: form.field(["selectedFields"]).error,
                                        domRef: form.field(["selectedFields"]).domRef,
                                        list: filter.value.columns || [],
                                        valueToLabel: (v) => fields.find((item) => item.column.id === v.modelColumnID)?.column?.visualNameFull,
                                        isSelected: (v) => v.default,
                                        onChange: (v) => {
                                            filter.change((filter) => ({
                                                ...filter,
                                                columns: filter.columns.map((item) => ({
                                                    ...item,
                                                    default: item.modelColumnID === v.modelColumnID,
                                                })),
                                            }));
                                        },
                                    })}
                                </div>
                            )}
                        </>
                    )}

                    {collection.$type !== "ApiCollection" &&
                        DropdownSelect({
                            label: "Date Range",
                            list: dateRanges.filter((dr) => filter.value.options?.includes(dr.name)),
                            valueToLabel: (op) => op.label,
                            ...ffToDropdown(form.field(["defaultOption"]), ["name"]),
                        })}

                    {isCustomFilter && (
                        <>
                            {DropdownSelect({
                                label: "Default Custom Range",
                                list: defaultCustomRanges.map((c) => ({
                                    ...c,
                                    disabled: selectionRangesIsSet && !["In the last", "In the next", "In the previous"].includes(c.label),
                                })),
                                valueToLabel: (item) => item.label,
                                isSelected: (item) => item.isSelected(filter.value.customRange),
                                onChange: (item) =>
                                    spc(filter, [], () => ({
                                        ...filter.value,
                                        ...item.onSelectValue({
                                            customRangeInitValue: {
                                                inPast: Math.min(
                                                    isPastCustom ? filter.value.pickerMaxDaysInPast : DEFAULT_LAST_OR_NEXT_DAYS_VALUE,
                                                    DEFAULT_LAST_OR_NEXT_DAYS_VALUE
                                                ),
                                                inFuture: Math.min(
                                                    isFutureCustom ? filter.value.pickerMaxDaysInFuture : DEFAULT_LAST_OR_NEXT_DAYS_VALUE,
                                                    DEFAULT_LAST_OR_NEXT_DAYS_VALUE
                                                ),
                                            },
                                        }),
                                    })),
                            })}

                            {cs(() => {
                                return editCustomRanges[filter.value.customRange.$type]({
                                    customRange: scope(filter, ["customRange"]),
                                    form: form.scope(["customRange"]),
                                    filter,
                                });
                            })}
                        </>
                    )}
                </div>
            );
        }
    );
