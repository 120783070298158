import React from "react";
import {cs} from "@common/react/chain-services";
import {Static2} from "@common/react/static-2";
import {Invoke} from "@common/react/invoke";
import {transformationStepTypes} from "../../../common/transformation-step-types";
import {inputStepTypes} from "../../../common/input-step-types";
import {cx} from "emotion";
import {diagramSizes, diagramUtils} from "../common/diagram-utils";
import {outputStepTypes} from "../../../common/output-step-types";
import "./transformation-diagram-node.scss";
import {InOutConnection} from "./connection/in-out-connection";
import {FlexLabel} from "../common/flex-label";
import {equalDeep} from "@common/utils/objects";
import {IgnoreUpdate} from "@common/react/ignore-update";
import {consumeContext} from "@common/react/context";

const TransformationItem = React.memo(
    ({flexLabel, nodeLabel, invalid, diagram, hoverValue, isAggregatedTransformation}) => {
        const transInfo = [...transformationStepTypes, ...outputStepTypes.filter((t) => t.$type === "ColumnOutputStep")].find((t) => t.$type === diagram.$type);
        const size = diagramSizes(diagram.$type);
        const {width, height} = typeof size === "object" ? size : {width: size, height: size};
        const fill = invalid ? "#FF7A59" : "#546b81";

        return (
            <>
                <rect width={width} height={height} rx={3} className={cx("transformation", {valid: !invalid})} />
                <foreignObject x="0" y="0" width={width} height={height} className="transformation-text">
                    <div className="icon-and-label">
                        <div className="icon">
                            {transInfo?.getIcon ? (
                                transInfo.getIcon(isAggregatedTransformation)({
                                    fill,
                                })
                            ) : transInfo?.icon ? (
                                transInfo.icon({fill})
                            ) : (
                                <img src={transInfo.iconSrc} alt="" />
                            )}
                        </div>
                        <div className="ghost-label">{nodeLabel}</div>
                        <div
                            className={cx("label-container", {
                                hidden: hoverValue?.value === diagram.id && flexLabel.isOverflowed(),
                            })}
                        >
                            {flexLabel.render({color: ""})}
                        </div>
                    </div>
                </foreignObject>
            </>
        );
    },
    (prevProps, nextProps) => {
        if (!equalDeep(prevProps.invalid, nextProps.invalid)) return false;

        if (!equalDeep(prevProps.hoverValue, nextProps.hoverValue)) return false;

        return true;
    }
);

const OutputItem = React.memo(
    ({flexLabel, nodeLabel, invalid, diagram, hoverValue, getConfiguredColor, isAggregatedTransformation}) => {
        const size = diagramSizes(diagram.$type);
        const inputInfo = [...inputStepTypes, ...outputStepTypes.filter((t) => t.$type === "ViewOutputStep")].find((t) => t.$type === diagram.$type);
        const configuredColor = getConfiguredColor?.(diagram.modelTableID);
        const fill = invalid ? "#FF7A59" : configuredColor ? "#fff" : "#546B81";

        return (
            <>
                <circle
                    r={size}
                    className={cx("circle-input", `${diagram.$type}`, {
                        valid: !invalid,
                    })}
                    {...(!invalid ? {fill: configuredColor ?? "#fff"} : {fill: "transparent"})}
                />
                <foreignObject
                    x={-size}
                    y={-size}
                    width={size * 2}
                    height={size * 2}
                    className={cx("circle-text", {
                        small: diagram.$type === "ChildDatasetStep",
                    })}
                >
                    <div className="icon-and-label">
                        <div className="icon">
                            {inputInfo?.getIcon ? (
                                inputInfo.getIcon(isAggregatedTransformation)({
                                    fill,
                                })
                            ) : inputInfo?.icon ? (
                                inputInfo.icon({fill})
                            ) : (
                                <img src={inputInfo.iconSrc} alt="" />
                            )}
                        </div>
                        <div className="ghost-label">{nodeLabel}</div>
                        <div
                            className={cx("label-container", {
                                hidden: hoverValue?.value === diagram.id && flexLabel.isOverflowed(),
                            })}
                        >
                            {flexLabel.render({
                                color: configuredColor ? "#fff" : "",
                            })}
                        </div>
                    </div>
                </foreignObject>
            </>
        );
    },
    (prevProps, nextProps) => {
        if (!equalDeep(prevProps.invalid, nextProps.invalid)) return false;

        if (!equalDeep(prevProps.hoverValue, nextProps.hoverValue)) return false;

        return true;
    }
);

const DiagramNode1 = ({diagram, draggingItem, collisionNode, hoverValue, drawingLine, nodeRef, invalid, children, interactions}) =>
    cs(
        (_, next) =>
            IgnoreUpdate({
                props: {
                    invalid,
                    hoverValue,
                    diagram,
                    collisionNode,
                    drawingLine,
                    draggingItem,
                },
                when: (pp) =>
                    invalid === pp.invalid &&
                    hoverValue.value === pp.hoverValue.value &&
                    equalDeep(pp.diagram?.position, diagram?.position) &&
                    equalDeep(pp.collisionNode, collisionNode) &&
                    equalDeep(pp.drawingLine.value, drawingLine.value) &&
                    equalDeep(pp.draggingItem, draggingItem),
                next,
            }),
        () => {
            let transform = diagram?.position ? `translate(${diagram.position.x}, ${diagram.position.y})` : null;

            return (
                <g
                    className={cx("diagram-node diagram-node-9er", {
                        grabbing: diagram.id && draggingItem.id === diagram.id,
                        selected: diagram.id && interactions.setting?.data.stepId === diagram.id,
                        "is-collision": diagram.id && collisionNode?.id === diagram.id,
                        "invalid-step": invalid,
                        hover: hoverValue.value === diagram.id,
                        "hovering-connected": hoverValue.value === diagram.id && drawingLine.value.inputStep,
                    })}
                    transform={transform}
                    ref={nodeRef.set}
                    onClick={(e) => {
                        if (diagram.id) {
                            interactions.openRightPanel({stepId: diagram.id});
                            drawingLine.onChange({
                                position: [],
                                inputStep: null,
                            });
                            // selectedConnectionPath.onChange(null);
                            //
                            // onConnectDrawingLine();

                            e.preventDefault();
                            e.stopPropagation();
                        }
                    }}
                    onMouseOver={() => {
                        if (hoverValue.value === diagram.id) return;
                        hoverValue.onChange(diagram.id);
                    }}
                    onMouseOut={() => hoverValue.onChange(null)}
                >
                    {children}
                </g>
            );
        }
    );

export const TransformationDiagramNode = ({
    diagram,
    diagrams,
    onDragging,
    onStartDragging,
    onDropped,
    interactions,
    draggingItem,
    collisionNode,
    hoverValue,
    drawingLine,
    onConnected,
    relationships,
    selectedConnectionPath,
    getConfiguredColor,
    isAggregatedMeasure,
}) =>
    cs(
        consumeContext("model"),
        ["nodeRef", (_, next) => Static2({next})],
        [
            "nodeLabel",
            ({model}, next) => {
                if (diagramUtils.isTransformationStep(diagram.$type)) {
                    return next(diagram.name);
                } else {
                    const table = model.value.tables.find((t) => t.id === diagram?.modelTableID);
                    return next(table?.visualName ?? (table?.name || diagram.name));
                }
            },
        ],
        ["flexLabel", ({nodeLabel}, next) => FlexLabel({text: nodeLabel, next})],
        [
            "renderNameReveal",
            ({nodeRef, nodeLabel}, next) =>
                next(() => {
                    const size = diagramSizes(diagram.$type);
                    const {width, height} = typeof size === "object" ? size : {width: size, height: size};
                    const textElem = nodeRef.get()?.getElementsByClassName("ghost-label")?.[0];
                    const textWidth = textElem?.getBoundingClientRect().width || width;
                    const translate = diagramUtils.isTransformationStep(diagram.$type)
                        ? {
                              x: diagram.position.x - Math.abs(textWidth - width) / 2,
                              y: diagram.position.y + 35,
                          }
                        : {
                              x: diagram.position.x - textWidth / 2,
                              y: diagram.position.y,
                          };
                    return (
                        <g className="reveal-full-name-j4w" transform={`translate(${translate.x}, ${translate.y})`}>
                            <foreignObject x="0" y="0" width={1} height={1}>
                                <div className="full-name" style={{width: textWidth}}>
                                    {nodeLabel}
                                </div>
                            </foreignObject>
                        </g>
                    );
                }),
        ],
        [
            "draggingHandler",
            (_, next) =>
                next(() => {
                    //set coordinate of svg node
                    let delta = {x: 0, y: 0};

                    return {
                        start: (node, e) => {
                            onStartDragging?.(e);
                            const rect = node.getBoundingClientRect();
                            const delta1 = diagramUtils.isTransformationStep(diagram.$type) ? 0 : diagramSizes(diagram.$type);

                            delta = {
                                x: e.sourceEvent.clientX - rect.x - delta1,
                                y: e.sourceEvent.clientY - rect.y - delta1,
                            };
                        },
                        dragging: (e) => {
                            onDragging({
                                x: e.x - delta.x,
                                y: e.y - delta.y,
                            });
                        },
                        end: (getLatestProps, e) => {
                            onDropped?.(getLatestProps);
                            delta = {x: 0, y: 0};
                        },
                    };
                }),
        ],
        ({nodeRef, draggingHandler, renderNameReveal, flexLabel, nodeLabel}) => {
            const isTransformationStep = diagramUtils.isTransformationStep(diagram.$type);
            const invalid = diagram.status?.split(", ").indexOf("Valid") === -1;

            const listener = ({getLatestProps}) => {
                const _draggingHandler = draggingHandler();
                const {diagram} = getLatestProps();
                const ref = nodeRef.get();

                const drag = diagram.id
                    ? d3
                          .drag()
                          .on("start", (e) => {
                              _draggingHandler.start(ref, e);
                          })
                          .on("drag", (e) => {
                              _draggingHandler.dragging(e);
                          })
                          .on("end", (e) => {
                              _draggingHandler.end(getLatestProps, e);
                          })
                    : () => {};
                d3.select(ref).call(drag);
            };

            const onConnectDrawingLine = () => {
                if (!drawingLine.value.inputStep) return;
                onConnected(drawingLine.value.inputStep, diagram);
                drawingLine.onChange({position: [], inputStep: null});
            };

            const ViewItem = isTransformationStep ? TransformationItem : OutputItem;

            return (
                <>
                    {Invoke({
                        props: {
                            diagram,
                            diagrams,
                            draggingItem,
                            collisionNode,
                            interactions,
                            selectedConnectionPath,
                        },
                        onMounted: listener,
                    })}

                    {DiagramNode1({
                        diagram,
                        draggingItem: draggingItem.value,
                        collisionNode,
                        hoverValue,
                        drawingLine,
                        nodeRef,
                        onConnectDrawingLine,
                        invalid,
                        interactions,
                        children: (
                            <ViewItem
                                {...{
                                    invalid,
                                    flexLabel,
                                    nodeLabel,
                                    diagram,
                                    getConfiguredColor,
                                    isAggregatedTransformation: isAggregatedMeasure,
                                }}
                            />
                        ),
                    })}

                    {hoverValue?.value === diagram.id && flexLabel.isOverflowed() && renderNameReveal()}

                    {diagram.id &&
                        InOutConnection({
                            diagram,
                            relationships,
                            interactions,
                            hoverValue,
                            selectedConnectionPath,
                            isTransformationStep,
                            drawingLine,
                            onConnected: onConnectDrawingLine,
                        })}
                </>
            );
        }
    );
