const Cookies = require("js-cookie");
const appConfig = process.env.appConfig;

const cookiePostfix = appConfig["cookie.postfix"].replace("{ticket}", appConfig["featureTicket"]);
const cookieDomain = appConfig["cookie.domain"].replace("{ticket}", appConfig["featureTicket"]);

const get = (key) => {
    if (!Cookies) return;

    if (key && key.indexOf("verb.ignore.cache") == -1) key = key.replace("verb.", "verb" + cookiePostfix?.toLowerCase() + ".");

    const str = Cookies.get(key);
    if (str) {
        try {
            return JSON.parse(str);
        } catch (e) {
            return str;
        }
    }
};

const set = (key, value, othersAttr = {}) => {
    if (!Cookies) return;

    if (key && key.indexOf("verb.ignore.cache") == -1) key = key.replace("verb.", "verb" + cookiePostfix?.toLowerCase() + ".");

    const attrs = {
        ...othersAttr,
        domain: cookieDomain,
        expires: 365,
        secure: true,
        samesite: "strict",
    };

    if (value == null) {
        Cookies.remove(key, attrs);
    } else if (typeof value === "string") {
        Cookies.set(key, value, attrs);
    } else {
        Cookies.set(key, JSON.stringify(value), attrs);
    }
};

exports.CookieStorage = {
    get,
    set,
};
