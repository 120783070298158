import FilterIcon from "assets/icons/common/FilterIcon";
import TextSnippetIcon from "assets/icons/data-sources/TextSnippetIcon";
import {cx} from "emotion";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {Checkbox} from "@common/ui-components/form/checkbox/checkbox";
import {cascadeChange} from "@common/utils/cascade";
import {isBlank} from "@common/utils/strings";
import {ConfirmDialog} from "../../../../common/confirm-dialog/confirm-dialog";
import {tooltipService3} from "../../../../common/tooltip3/tooltip-service-3";
import {syncScheduleDefault} from "../../../add/default-data-source";
import {ChooseIntervalDialog} from "../../../add/wizard/steps/tables-columns/wizard/steps/tables/choose-interval/choose-interval-dialog";
import {dataSourceTypes} from "../../../common/data-source-type";
import {FilterRowsDialog} from "../common/filter-rows-dialog/filter-rows-dialog";
import {SyncMethodDialog} from "../common/sync-method-dialog/sync-method-dialog";
import {getDetailUnstructuredTable} from "../unstructured-list/unstructured-list-helpers";
import {SyncSettingErrorItem} from "./sync-settings-error-item";
import "./sync-settings-list.scss";
import {SyncSettingsStatusDropdown} from "./sync-settings-status";
import {NoPrimaryKeyIcon} from "../../../common/icons";
import React from "react";
import {StaticTooltipService} from "../../../../common/tooltip3/static-tooltip-service";
import {AlertIcon} from "@common/ui-components/icons/global-icons";
import {decorateColumnsData} from "../headers/sync-columns-decorate-data";

export const SyncSettingItem = ({selectedTables, interactions, remoteTable, disabled, dataSource, isSyncing, hasAddingErrors, onReload}) =>
    cs(["currentDisabledStatus", (_, next) => UseState({next, initValue: disabled})], ["expanding", (_, next) => UseState({next})], ({expanding, currentDisabledStatus}) => {
        const table = remoteTable.value;
        const hasTableError = (dataSource.value.errors || []).map((x) => x.dataSourceTableID).indexOf(table.id) > -1 && !disabled;
        const disabledSyncMethod = false;

        const isSelected = selectedTables.value.findIndex((s) => s.name === table.name) > -1;

        const isStaticDatasource = [dataSourceTypes.FILE, dataSourceTypes.GOOGLE_SHEETS].includes(dataSource.value.type);
        const isUnstructured = !dataSource.value.structured;
        const hasPrimaryKey = !!table.columns.find((c) => c.primaryKey);

        return cs(
            [
                "onToggleSync",
                (_, next) =>
                    next((value, syncValue = {syncSchedule: {}, syncMethod: null}) => {
                        dataSource.change((ds) => ({
                            ...ds,
                            tables: value
                                ? [
                                      ...ds.tables,
                                      {
                                          ...table,
                                          columns: decorateColumnsData(table.columns.length === 0 ? table.disabledColumns || [] : table.columns),
                                          disabledColumns: table.columns.length === 0 ? [] : table.disabledColumns || [],
                                          paused: false,
                                          syncMethod: syncValue.syncMethod ?? table.syncMethod,
                                          syncSchedule: {
                                              ...(table?.syncSchedule || {}),
                                              ...syncValue.syncSchedule,
                                          },
                                      },
                                  ]
                                : ds.tables.filter((t) => t.name !== table.name),
                            disabledTables: value ? ds.disabledTables.filter((t) => t.name !== table.name) : [...ds.disabledTables, table],
                        }));
                    }),
            ],
            ({onToggleSync}) => (
                <div
                    className={cx("sync-setting-item-99t", {
                        "show-all-checkbox": selectedTables.value.length > 0,
                        selected: isSelected || interactions.value.selectedTable?.name === table?.name,
                        disabled: disabled,
                        "has-error": hasTableError,
                    })}
                >
                    {Checkbox({
                        state: {
                            value: isSelected,
                            onChange: (value) => selectedTables.change((old) => (value ? [...old, {...table, disabled}] : old.filter((o) => o.name !== table.name))),
                        },
                    })}

                    <div
                        className={cx("details", {
                            error: hasTableError,
                            expanding: expanding.value,
                            paused: table.paused,
                        })}
                    >
                        <div className="left" onClick={() => hasTableError && expanding.change((old) => !old)}>
                            {cs(
                                [
                                    "syncType",
                                    (_, next) => {
                                        const getSyncType = () => {
                                            // add constant status
                                            if (disabled) return "notSync";
                                            if (hasTableError) return "error";
                                            if (table.paused) return "paused";
                                            if (table.syncSchedule?.$type === "OneTimeSync") return "syncOnce";
                                            if (isSyncing) return "inSync";
                                            return "sync";
                                        };

                                        return next(getSyncType());
                                    },
                                ],

                                ({syncType}) =>
                                    SyncSettingsStatusDropdown({
                                        isSyncing,
                                        type: syncType,
                                        disabled,
                                        remoteTable,
                                        onToggleSync,
                                    })
                            )}

                            {hasTableError && (
                                <i
                                    className={cx("far fa-angle-down expand-icon", {expanding: expanding.value})}
                                    // onClick={() => expanding.change((old) => !old)}
                                />
                            )}
                        </div>

                        {cs(
                            ["chooseIntervalDialog", ({}, next) => ChooseIntervalDialog({next})],
                            ["syncMethodDialog", ({}, next) => SyncMethodDialog({next})],
                            [
                                "confirmDialog",
                                (_, next) =>
                                    ConfirmDialog({
                                        next,
                                        noHeader: true,
                                        body: `Your new Sync Method caused the Sync Interval to be changed.`,
                                        submitText: `Review Sync Interval`,
                                    }),
                            ],
                            [
                                "editSyncMethod",
                                ({syncMethodDialog, confirmDialog, chooseIntervalDialog}, next) =>
                                    next(async (e, remoteTable) => {
                                        e.preventDefault();
                                        e.stopPropagation();

                                        if (disabledSyncMethod) return;

                                        const resp = await syncMethodDialog.show({
                                            syncSchedule: remoteTable.value.syncSchedule,
                                            value: remoteTable.value.syncMethod,
                                            incrementalColumn: remoteTable.value.columns.find((c) => c.incrementalColumn)?.name || null,
                                            tables: [table],
                                        });

                                        if (resp) {
                                            const defaultSyncSchedule = syncScheduleDefault[resp.syncMethod];
                                            const newSync = {
                                                syncMethod: resp.syncMethod,

                                                syncSchedule: {
                                                    ...defaultSyncSchedule,
                                                    $type: remoteTable.value.syncSchedule.$type ?? defaultSyncSchedule.$type,
                                                },

                                                ...(resp.incrementalColumn
                                                    ? {
                                                          columns: cascadeChange(remoteTable.value.columns, "[*]", (column) => ({
                                                              ...column,
                                                              incrementalColumn: column.name === resp.incrementalColumn,
                                                          })),
                                                      }
                                                    : {}),
                                            };

                                            remoteTable.change((t) => ({...t, ...newSync}));

                                            if (remoteTable.value.syncSchedule.every) {
                                                const confirm = await confirmDialog.show({});
                                                if (confirm) {
                                                    const resp = await chooseIntervalDialog.show({
                                                        value: newSync.syncSchedule,
                                                        syncMethod: newSync.syncMethod,
                                                        title: `SYNC INTERVAL FOR ${table.name.toUpperCase()}`,
                                                    });

                                                    if (resp) {
                                                        remoteTable.change((t) => ({...t, syncSchedule: resp}));
                                                    }
                                                }
                                            }
                                        }
                                    }),
                            ],
                            ({chooseIntervalDialog, editSyncMethod}) => (
                                <div className="right">
                                    <div
                                        className="row"
                                        onClick={() => selectedTables.change((old) => (!isSelected ? [...old, {...table, disabled}] : old.filter((o) => o.name !== table.name)))}
                                    >
                                        <div className="section">
                                            <div className="label">{isUnstructured ? `Objects` : `Table`}</div>
                                            <div className={cx("value", {disabled: disabled})}>{table.name}</div>
                                        </div>

                                        <div className="section">
                                            {cs(
                                                (_, next) => {
                                                    if (isUnstructured) {
                                                        const {columns: unstructuredColumns, disabledColumns: unstructuredDisabledColumns} = getDetailUnstructuredTable(
                                                            table,
                                                            dataSource.value.tables,
                                                            dataSource.value.disabledTables || []
                                                        );

                                                        return (
                                                            <>
                                                                <div className="label">NESTED OBJECTS & PROPERTIES</div>
                                                                <div
                                                                    className={cx("action", {disabled: disabled})}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        e.stopPropagation();
                                                                        return (
                                                                            !disabled &&
                                                                            interactions.change((oldInteractions) => ({
                                                                                ...oldInteractions,
                                                                                selectedTable: table,
                                                                            }))
                                                                        );
                                                                    }}
                                                                >
                                                                    {unstructuredColumns.length}
                                                                    <span className="small"> of {unstructuredColumns.length + (unstructuredDisabledColumns || [])?.length}</span>
                                                                </div>
                                                            </>
                                                        );
                                                    }
                                                    return next();
                                                },
                                                () => (
                                                    <>
                                                        <div className="label">
                                                            Columns to Sync
                                                            {!hasPrimaryKey ? (
                                                                <>
                                                                    {" "}
                                                                    <NoPrimaryKeyIcon />
                                                                </>
                                                            ) : null}
                                                            {!hasPrimaryKey && <div className="tooltip">Table does not have a primary key to sync</div>}
                                                        </div>
                                                        <div
                                                            className={cx("action", {disabled: disabled})}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                return (
                                                                    !disabled &&
                                                                    interactions.change((oldInteractions) => ({
                                                                        ...oldInteractions,
                                                                        selectedTable: table,
                                                                    }))
                                                                );
                                                            }}
                                                        >
                                                            {table.columns.length}
                                                            <span className="small"> of {table.columns.length + (table.disabledColumns || [])?.length}</span>
                                                        </div>
                                                    </>
                                                )
                                            )}
                                        </div>

                                        {cs(() => (
                                            <>
                                                {cs(
                                                    (_, next) =>
                                                        disabled
                                                            ? currentDisabledStatus.value
                                                                ? null
                                                                : dataSource.value.id &&
                                                                  cs(
                                                                      [
                                                                          "staticTooltip",
                                                                          (_, next) =>
                                                                              StaticTooltipService({
                                                                                  direction: "right",
                                                                                  info: "If any models or collections are dependent on this table, they will be in error after saving this data source.",
                                                                                  tooltipBoxWidth: 200,
                                                                                  next,
                                                                              }),
                                                                      ],
                                                                      ({staticTooltip}) => (
                                                                          <div
                                                                              style={{
                                                                                  margin: "0 1%",
                                                                              }}
                                                                          >
                                                                              {staticTooltip.renderIcon({
                                                                                  icon: <AlertIcon fill="#ECCD39" />,
                                                                              })}
                                                                          </div>
                                                                      )
                                                                  )
                                                            : next(),
                                                    () => {
                                                        const tIncrementalColumn = table.columns.find((c) => c.incrementalColumn)?.name || null;

                                                        return (
                                                            <div className={cx("section", {error: hasAddingErrors})}>
                                                                <div className="label">Sync Method</div>

                                                                {cs(
                                                                    (_, next) => (isStaticDatasource ? "No Method" : next()),
                                                                    () => (
                                                                        <div
                                                                            className={cx("action", {disabled: disabledSyncMethod})}
                                                                            onClick={(e) => editSyncMethod(e, remoteTable)}
                                                                        >
                                                                            {table.syncMethod ? (
                                                                                <>
                                                                                    {table.syncMethod}{" "}
                                                                                    {table.syncMethod === "Incremental" && tIncrementalColumn ? `- ${tIncrementalColumn}` : null}
                                                                                </>
                                                                            ) : (
                                                                                `Not set`
                                                                            )}
                                                                        </div>
                                                                    )
                                                                )}
                                                            </div>
                                                        );
                                                    }
                                                )}

                                                {cs(
                                                    (_, next) => (disabled ? null : next()),
                                                    ({}) => (
                                                        <div className="section">
                                                            <div className="label">Sync Interval</div>
                                                            {cs(
                                                                (_, next) => (isStaticDatasource ? "No Interval" : next()),
                                                                () => (
                                                                    <div
                                                                        className={cx("action", {
                                                                            disabled:
                                                                                disabledSyncMethod ||
                                                                                table.syncMethod === "None" ||
                                                                                isBlank(table.syncMethod) ||
                                                                                table.syncSchedule?.$type === "OneTimeSync",
                                                                        })}
                                                                        onClick={async (e) => {
                                                                            e.preventDefault();
                                                                            e.stopPropagation();

                                                                            if (
                                                                                disabledSyncMethod ||
                                                                                table.syncMethod === "None" ||
                                                                                isBlank(table.syncMethod) ||
                                                                                table.syncSchedule?.$type === "OneTimeSync"
                                                                            )
                                                                                return;

                                                                            const resp = await chooseIntervalDialog.show({
                                                                                value: table.syncSchedule,
                                                                                syncMethod: table.syncMethod,
                                                                                title: `SYNC INTERVAL FOR ${table.name.toUpperCase()}`,
                                                                            });

                                                                            if (resp) {
                                                                                remoteTable.change((t) => ({...t, syncSchedule: resp}));
                                                                            }
                                                                        }}
                                                                    >
                                                                        {isBlank(table.syncMethod) ? (
                                                                            <div className="text">Not Set</div>
                                                                        ) : (
                                                                            table.syncSchedule.$type &&
                                                                            cs(
                                                                                tooltipService3({
                                                                                    direction: "above",
                                                                                    tooltipContentStyle: {
                                                                                        width: 150,
                                                                                    },
                                                                                }),
                                                                                [
                                                                                    "rSyncSchedule",
                                                                                    ({tooltip}, next) =>
                                                                                        next((syncSchedule) => {
                                                                                            return (
                                                                                                <div className="text">
                                                                                                    Every {syncSchedule.every} {syncSchedule.interval}
                                                                                                    {((!isBlank(syncSchedule.duringHours) && syncSchedule?.duringHours !== "All") ||
                                                                                                        (isBlank(syncSchedule.onDays) && syncSchedule?.onDays !== "All")) && (
                                                                                                        <span {...tooltip(() => `Custom hours or days applied to sync interval`)}>
                                                                                                            {TextSnippetIcon({
                                                                                                                className: "all-icon",
                                                                                                            })}
                                                                                                        </span>
                                                                                                    )}
                                                                                                </div>
                                                                                            );
                                                                                        }),
                                                                                ],
                                                                                ({rSyncSchedule}) => (
                                                                                    <>
                                                                                        {table.syncSchedule.$type === "SyncSchedule" && table.syncSchedule.every ? (
                                                                                            rSyncSchedule(table.syncSchedule)
                                                                                        ) : table.syncSchedule.$type === "OneTimeSync" && table.syncSchedule.every ? (
                                                                                            rSyncSchedule(table.syncSchedule)
                                                                                        ) : (
                                                                                            <div className="text">Not Set</div>
                                                                                        )}
                                                                                    </>
                                                                                )
                                                                            )
                                                                        )}
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    )
                                                )}
                                            </>
                                        ))}

                                        {cs(
                                            (_, next) => (disabled || isStaticDatasource || isUnstructured ? null : next()),
                                            ["filterRowsDialog", (_, next) => FilterRowsDialog({tableName: table.name, next})],
                                            ({filterRowsDialog}) => (
                                                <div className={cx("filter-section", {"has-filter": table.whereFilter})}>
                                                    <div className="triangle" />
                                                    <span
                                                        onClick={async (e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();

                                                            const resp = await filterRowsDialog.show({
                                                                dsID: dataSource.value.id,
                                                                dsTableID: table.id,
                                                                query: table.whereFilter,
                                                            });

                                                            remoteTable.change((t) => ({...t, whereFilter: resp}));
                                                        }}
                                                    >
                                                        {FilterIcon({className: "icon"})}
                                                    </span>
                                                </div>
                                            )
                                        )}
                                    </div>

                                    {expanding.value && hasTableError && (
                                        <div className="expandable">
                                            {SyncSettingErrorItem({
                                                editSyncMethod: (e) => editSyncMethod(e, remoteTable),
                                                tableId: table.id,
                                                editColumn: () =>
                                                    interactions.change((oldInteractions) => ({
                                                        ...oldInteractions,
                                                        selectedTable: table,
                                                    })),
                                                disabledSync: () => onToggleSync(false),
                                                error: (dataSource.value.errors || [])?.find((e) => e.dataSourceTableID === table.id),
                                                onReload,
                                            })}
                                        </div>
                                    )}
                                </div>
                            )
                        )}
                    </div>
                </div>
            )
        );
    });
