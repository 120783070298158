import {BasicPanel} from "@common/ui-components/panel/panel";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {DataTable} from "../../common/data-table/data-table";
import {LoadingIndicator} from "@common/ui-components/loading-indicator/loading-indicator";
import React from "react";
import {PopupMenu} from "../../common/popup-menu/popup-menu";
import moment from "moment";
import {ConfirmDialog} from "../../common/confirm-dialog/confirm-dialog";
import {Load2} from "@common/react/load2";

export const ReportList = ({reportType}) => {
    return cs(
        consumeContext("reportApis"),
        consumeContext("routing"),
        [
            "reports",
            ({reportApis}, next) =>
                Load2({
                    fetch: async () => {
                        if (reportType === "recurring") {
                            return await reportApis.getRecurringReports();
                        } else if (reportType === "one-time") {
                            return await reportApis.getOnetimeReports();
                        }
                    },
                    next,
                }),
        ],
        [
            "reportsCM",
            ({routing, reportApis, reports}, next) =>
                cs(
                    [
                        "confirmDialog",
                        (_, next) =>
                            ConfirmDialog({
                                next,
                                title: "Delete Report!",
                                body: "Do you want to delete this report?",
                            }),
                    ],
                    ({confirmDialog}) =>
                        PopupMenu({
                            getCommands: ({report}) => {
                                return [
                                    {
                                        label: "Edit",
                                        onClick: () => {
                                            return routing.goto("report-builder", {
                                                reportTab: "builder",
                                                reportId: report.id,
                                            });
                                        },
                                    },
                                    {
                                        label: "Delete",
                                        className: "text-danger",
                                        onClick: async () => {
                                            const res = await confirmDialog.show();
                                            if (!res) {
                                                return;
                                            }
                                            await reportApis.deleteReport(report.id);
                                            reports.onChange(reports.value.filter((r) => r.id !== report.id));
                                        },
                                    },
                                ];
                            },
                            next,
                        })
                ),
        ],
        ({reports, reportsCM}) => {
            const columns = [
                {
                    label: "Report Name",
                    format: (r) => r.name,
                },
                {
                    label: "Report Dates",
                    format: (r) =>
                        r.isRecurring ? r.dateConfig.recurringType : `${moment(r.dateConfig.startDate).format("MM/DD/YY")} - ${moment(r.dateConfig.endDate).format("MM/DD/YY")}`,
                },
                {
                    label: "Date Created",
                    format: (r) => moment(r.createdOnUtc).format("MM/DD/YY"),
                },
                {
                    label: "User",
                    format: (r) => r.createdBy,
                },
                reportType === "recurring" && {
                    label: "Status",
                    format: (r) => (r.isDisabled ? `Disabled` : `Enabled`),
                },
                {
                    format: (r) => (
                        <div className="cm">
                            {reportsCM.render({
                                params: {
                                    report: r,
                                },
                            })}
                        </div>
                    ),
                    shy: true,
                    alignRight: true,
                },
            ].filter((v) => v);

            const title = {
                "recurring": "Recurring Reports",
                "one-time": "One-time Reports",
            }[reportType];

            return (
                <BasicPanel title={title}>
                    {(a) => {
                        if (!reports?.value) {
                            return LoadingIndicator();
                        }

                        return (
                            <div className="existing-reports-f21">
                                {DataTable({
                                    maxHeight: 500,
                                    useVerbScrollbar: false,
                                    className: "report-list-fg2",
                                    list: reports.value,
                                    columns,
                                })}
                            </div>
                        );
                    }}
                </BasicPanel>
            );
        }
    );
};
