import * as React from "react";
import {NumberInputState} from "@common/form/number-input/number-input";
import {cs} from "@common/react/chain-services";
import {BasicInputLive} from "../basic-input-live/basic-input-live";

export const NumberInputLive = ({state, inputStyle, onBlur, ...props}) =>
    cs(["inputState", (_, next) => NumberInputState({next, rootState: state})], ({inputState}) =>
        BasicInputLive({
            ...props,
            state,
            style: inputStyle,
            bind: () => ({
                value: inputState.displayedValue,
                onChange: inputState.onChange,
            }),
        })
    );
