import "./text-chart.scss";

import {css, cx} from "emotion";
import * as React from "react";
import {ListItemNode, ListNode} from "@lexical/list";
import {AutoLinkNode, LinkNode} from "@lexical/link";
import {HeadingNode, QuoteNode} from "@lexical/rich-text";
import {LinkPlugin} from "@lexical/react/LexicalLinkPlugin";
import {ListPlugin} from "@lexical/react/LexicalListPlugin";
import {LexicalComposer} from "@lexical/react/LexicalComposer";
import {RichTextPlugin} from "@lexical/react/LexicalRichTextPlugin";
import {ContentEditable} from "@lexical/react/LexicalContentEditable";
import {$convertFromMarkdownString, TRANSFORMERS} from "@lexical/markdown";

import {keyed} from "../../../react/keyed";
import {cs} from "../../../react/chain-services";
import {VerbScrollbar} from "../../verb-scrollbar/verb-scrollbar";
import editorTheme from "./editor-theme";

import {cGetFontSize} from "../common/style-map/font-size";
import {StyledClass} from "@common/react/styled-class";

export const TextChart = ({tile, tileFilters, loadData, theme, size, defaultData}) =>
    cs(() => {
        return (
            <VerbScrollbar
                className={cx("text-chart-a33", tile.style.title.show && "has-title")}
                style={{
                    fontFamily: `"${theme.dataVisualization.fonts.fontFamily || theme.general.canvas.fontFamily}", sans-serif`,
                }}
            >
                {(() => cs(keyed(tile.content), () => TextPreview({tile, theme})))()}
            </VerbScrollbar>
        );
    });

const TextPreview = ({tile, theme}) => {
    const editorConfig = {
        readOnly: true,
        theme: editorTheme,
        onError: (error) => {
            console.error(error);
        },
        nodes: [HeadingNode, ListNode, ListItemNode, QuoteNode, AutoLinkNode, LinkNode],
    };

    const getFontSize = cGetFontSize(theme.dataVisualization.textTile.fontSize || theme.general.canvas.fontSize, theme);

    return cs(
        [
            "textStyle",
            (_, next) =>
                StyledClass({
                    next,
                    content: {
                        a: {
                            color: `${theme.general.buttons.hyperlinkButton.fontColorRGB}`,

                            "&:hover": {
                                color: `${theme.general.buttons.hyperlinkButton.fontColorRGB}`,
                            },
                        },
                        color: `${theme.dataVisualization.textTile.fontColorRGB || theme.general.canvas.fontColorRGB} !important`,
                        "font-family": `${theme.general.canvas.fontFamily}, sans-serif`,
                        "font-size": `${getFontSize({elemType: "text"})}px !important`,

                        ".editor-heading-h1": {
                            "font-size": `${getFontSize({elemType: "title"})}px !important`,
                        },
                    },
                }),
        ],
        ({textStyle}) => (
            <LexicalComposer initialConfig={editorConfig}>
                <div className="editor-container-a33 readonly">
                    <RichTextPlugin
                        initialEditorState={() => {
                            $convertFromMarkdownString(tile.content || "", TRANSFORMERS);
                        }}
                        contentEditable={<ContentEditable className={cx("editor-input", textStyle)} spellCheck={false} />}
                    />
                    <ListPlugin />
                    <LinkPlugin />
                    {/*<ListMaxIndentLevelPlugin*/}
                    {/*    maxDepth={4}*/}
                    {/*/>*/}
                </div>
            </LexicalComposer>
        )
    );
};
