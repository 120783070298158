import * as React from "react";

import { cs } from "@common/react/chain-services";
import { Form2 } from "@common/react/cs-form/form2";
import { Invoke } from "@common/react/invoke";
import { keyed } from "@common/react/keyed";
import { UseState } from "@common/react/use-state";
import { Watch } from "@common/react/watch";
import { getFieldType } from "@common/ui-components/charts/common/get-field-type";
import {
    getColorConfigs,
    getFieldColorConfigs, isDisableColorConfigs, listAllFields, MAX_NUM_COLOR
} from "@common/ui-components/charts/get-field-color";
import { changePath, getPath } from "@common/utils/arr-path";
import { unique } from "@common/utils/collections";
import { equalDeep } from "@common/utils/objects";

import { getTileFormConfig } from "./tiles-form-config/get-tile-form-config";

const getBarFieldProps = ($type) => {
    const maps = {
        VerticalBarChartTile: ["yAxisFields"],
        HorizontalBarChartTile: ["xAxisFields"],
        TableTile: [],
        TableKPITile: [],
        SingleKPITile: [],
        ListKPITile: [],
        LineChartTile: [],
        PieChartTile: [],
        DonutChartTile: [],
        GaugeTile: [],
        DownloadReportTile: [],
        ComboChartTile: ["yAxisBarFields"],
        BoxPlotTile: [],
    };

    return maps[$type];
};

export const forceUpdateApplyColorsBy = (newTile) => {
    if (isDisableColorConfigs(newTile) || newTile.$type == "SparkLineKPITile") {
        return newTile;
    }

    const fields = listAllFields({
        tile: newTile,
        dimensionAttr: ["xAxisField", "yAxisField"],
        measureGroupAttrs: ["yAxisBarFields", "yAxisLineFields", "xAxisFields", "yAxisFields"],
        isBarFields: getBarFieldProps(newTile.$type),
    });

    const numMeasureFields = fields.filter((f) => f.$type == "MeasureTileField" && f.isBarField).length;
    const hasDateTimeDimensionField = fields.filter((f) => f.$type == "DimensionTileField").find((f) => getFieldType(f) == "date");

    if (numMeasureFields > 1 || newTile.groupField || hasDateTimeDimensionField) {
        newTile = changePath(newTile, ["style", "barDataColorAppliedBy"], () => "Measure");
    }
    return newTile;
};

export function hasNewMeasureFields(newTile) {
    const fields = listAllFields({
        tile: newTile,
        dimensionAttr: ["xAxisField", "yAxisField"],
        measureGroupAttrs: ["yAxisBarFields", "yAxisLineFields", "xAxisFields", "yAxisFields"],
        isBarFields: ["yAxisBarFields", "xAxisFields", "yAxisFields"],
        checkUnique: false,
    });

    return fields.filter((f) => f.$type == "MeasureTileField" && !f.id);
}

export const EditTileLogic = ({updateFunc, allowInvalidUpdates = false, tile: remoteTile, next}) =>
    cs(
        [
            "localTile",
            (_, next) => {
                return UseState({
                    initValue: {
                        value: null,
                        lastUpdatedAt: null,
                    },
                    next: ({onChange, change, value}) =>
                        next({
                            value: {
                                ...value,
                                value: value.value ?? remoteTile.value,
                            },
                            flush: () => onChange({}),
                            onChange,
                            change,
                        }),
                });
            },
        ],

        [
            "form",
            ({localTile}, next) =>
                Form2({
                    data: {
                        value: localTile.value.value,
                        onChange: (v) => {
                            localTile.onChange({
                                value: v,
                                lastUpdatedAt: Date.now(),
                            });
                        },
                    },
                    ...getTileFormConfig(localTile.value.value?.$type),
                    next,
                }),
        ],

        // BUG AB#3102: Tile data color -New Themes: Bar charts: fix color order selection
        [
            "doUpdateTile",
            ({localTile}, next) =>
                next(async (newTile) => {
                    let updatedTile = await updateFunc?.(forceUpdateApplyColorsBy(newTile));

                    // do not apply the color logics below for these tile types
                    if (["ScatterPlotTile", "BubbleChartTile", "SparkLineKPITile"].includes(updatedTile?.$type)) {
                        return;
                    }

                    let newMeasureFields = hasNewMeasureFields(newTile);

                    if (
                        updatedTile &&
                        newMeasureFields.length > 0 &&
                        updatedTile.$type != "SparkLineKPITile" &&
                        (!updatedTile.groupField ||
                            (updatedTile.$type == "ComboChartTile" &&
                                updatedTile.groupField &&
                                newMeasureFields.filter((f) => !f.isBarField).length > 0))
                    ) {
                        if (updatedTile.groupField) {
                            newMeasureFields = newMeasureFields.filter((f) => !f.isBarField);
                        }

                        let updaters = [];
                        const colorConfigs = getColorConfigs(updatedTile);
                        let otherFieldConfigs = {...colorConfigs.configs};

                        for (let i = 0; i < newMeasureFields.length; i++) {
                            const {parent, fieldPath} = newMeasureFields[i];
                            const updatedField = getPath(updatedTile, [parent, ...(fieldPath ?? {})]);
                            const oldFieldConfig = {
                                ...otherFieldConfigs[updatedField.id],
                            };

                            if (oldFieldConfig.$type != "DiscreteColorApplication") {
                                continue;
                            }

                            delete otherFieldConfigs[updatedField.id];
                            const fieldColorConfig = getFieldColorConfigs({
                                field: updatedField,
                                colorConfigs,
                            });
                            let updatePath = ["style"];

                            if (fieldColorConfig.isDictionary) {
                                updatePath = updatePath.concat(
                                    fieldColorConfig.parent
                                        ? [fieldColorConfig.parent, updatedField.id]
                                        : [fieldColorConfig.prop, updatedField.id]
                                );
                            } else {
                                updatePath = updatePath.concat(fieldColorConfig.prop);
                            }

                            updaters.push({
                                updatePath,
                                oldFieldConfig,
                                fieldColorConfig,
                                field: updatedField,
                            });
                        }

                        if (updaters.length == 0) {
                            return updatedTile;
                        }

                        const usedColors = unique(
                            Object.values(otherFieldConfigs)
                                .filter((c) => c.$type == "DiscreteColorApplication")
                                .map((c) => c.index)
                        ).sort();
                        let avaiColors = Array(MAX_NUM_COLOR.DiscreteColorApplication)
                            .fill(0)
                            .map((_, i) => i)
                            .filter((i) => !usedColors.includes(i));

                        for (let i = 0; i < updaters.length; i++) {
                            const {updatePath, oldFieldConfig} = updaters[i];
                            updatedTile = changePath(updatedTile, updatePath, () => ({
                                ...oldFieldConfig,
                                index: avaiColors.shift() ?? 0,
                            }));
                        }

                        return await updateFunc?.(updatedTile);
                    }
                }),
        ],

        ({localTile, tilePath, savingQueue, form, doUpdateTile}, next) =>
            cs(["lastSave", (_, next) => UseState({next})], ({lastSave}) => (
                <>
                    {next()}

                    {Watch({
                        value: remoteTile.value,
                        onChanged: (updatedTile) => {
                            if (!equalDeep(updatedTile, localTile.value?.value)) {
                                localTile.onChange({
                                    value: updatedTile,
                                });
                            }
                        },
                    })}

                    {localTile.value &&
                        localTile.value.lastUpdatedAt &&
                        localTile.value.value &&
                        !equalDeep(remoteTile.value, localTile.value?.value) &&
                        (!lastSave.value || lastSave.value < localTile.value.lastUpdatedAt) &&
                        (allowInvalidUpdates ? true : form.valid) &&
                        cs(keyed(localTile.value.lastUpdatedAt), () =>
                            Invoke({
                                fn: async ({isMounted}) => {
                                    let newTile = localTile.value.value;

                                    await doUpdateTile(newTile);
                                    if (isMounted()) {
                                        lastSave.onChange(localTile.value.lastUpdatedAt);
                                        localTile.flush();
                                    }
                                },
                            })
                        )}
                </>
            )),
        ({localTile, form}) => {
            return next({
                form,
                tile: {
                    // To be removed (replaced by form)
                    loading: localTile.value?.value !== remoteTile.value,
                    // SP add loading resolve bug #2062 Updating before saving collection
                    value: localTile.value?.value,
                    onChange: (value) => {
                        return localTile.onChange({
                            value,
                            lastUpdatedAt: Date.now(),
                        });
                    },
                },
            });
        }
    );
