import "./panel-tabs.scss";

import React from "react";
import {cx} from "emotion";

import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {keyed} from "@common/react/keyed";

import {Tabs} from "@common/ui-components/tabs/tabs";
import {BasicPanel} from "@common/ui-components/panel/panel";

export const PanelTabs = ({className, title, tabs, defaultTabIndex = 0}) =>
    cs(["activeTab", (_, next) => UseState({initValue: tabs[defaultTabIndex].name, next})], ({activeTab}) => {
        const {render, actions} = tabs.find((t) => t.name === activeTab.value) || {};

        return (
            <BasicPanel className={cx("panel-tabs-v87", className)} title={title} rightControl={actions?.()}>
                {({height}) => (
                    <>
                        {Tabs({
                            className: "panel-tabs",
                            list: tabs,
                            activeTab,
                            type: "vertical",
                        })}
                        <div className="panel-tab-content">{cs(keyed(activeTab.value), () => render?.({height}))}</div>
                    </>
                )}
            </BasicPanel>
        );
    });
