import * as React from "react";

import {cs} from "@common/react/chain-services";
import {ObserveDomSize} from "@common/react/observe-dom-size";

import {getLayoutPadding} from "./layout-padding";

export const ChartInnerLayout = ({tile, next, noData = true, hasControls, noPadding = false}) =>
    cs(
        [
            "chartAreaSize",
            ({}, next) =>
                ObserveDomSize({
                    ignoreChange: (newSize, oldSize) => newSize.height === oldSize.height && Math.abs(newSize.width - oldSize.width) <= 20,
                    next,
                }),
        ],
        ({chartAreaSize}) => {
            let style = {
                width: "100%",
                height: "100%",
                ...(!noPadding ? getLayoutPadding({tile, noData, hasControls}) : {}),
            };

            const {paddingTop, paddingLeft, paddingBottom, paddingRight} = chartAreaSize.value ? getComputedStyle(chartAreaSize.getElement()) : {};

            const remainSize = chartAreaSize.value
                ? {
                      width: chartAreaSize.value.width - parseFloat(paddingLeft) - parseFloat(paddingRight),
                      height: chartAreaSize.value.height - parseFloat(paddingTop) - parseFloat(paddingBottom),
                  }
                : null;
            return (
                <div className="chart-or-table chart-inner-layout-2hg" style={style} ref={chartAreaSize.ref}>
                    {chartAreaSize.value != null && next(remainSize)}
                </div>
            );
        }
    );
