import {Component, createElement} from "react";
import "./error-boundary.scss";
import {consumeContext} from "@common/react/context";
import {cs} from "@common/react/chain-services";
export const ErrorBoundary = ({next, routing}) => createElement(ErrorBoundary1, {next, routing});

class ErrorBoundary1 extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            hasError: false,
        };
    }

    static getDerivedStateFromError() {
        return {hasError: true};
    }

    UNSAFE_componentWillReceiveProps(props) {
        if (props.routing.params != this.props.routing.params) {
            this.setState({hasError: false});
        }
    }

    render() {
        let {next} = this.props;
        return this.state.hasError ? <ErrorBoundaryContent /> : next();
    }
}

const ErrorBoundaryContent = () =>
    cs(consumeContext("routing"), consumeContext("auth"), consumeContext("apis"), ({auth, apis, routing}) => (
        <div className="error-container-a123">
            <center>
                <div className="logo">Verb</div>
                <div>
                    An error occured.
                    <div>
                        <a
                            onClick={() => {
                                routing.goto("dashboard");
                            }}
                        >
                            Return to the main dashboard
                        </a>
                    </div>
                    <div>
                        <a
                            onClick={() => {
                                auth.logout();
                                apis.user.signOut();
                            }}
                        >
                            Log out and try again
                        </a>
                    </div>
                </div>
            </center>
        </div>
    ));
