import {cs} from "../../react/chain-services";
import {DebounceCache} from "../../react/debounce-cache";
import {TextInput} from "../text-input/text-input";
import {TextInputAutoGrow} from "../../../web-client/src/routes/collection/tile/edit/left-panel/tabs/fields-tab/actions/add/common/text-input-autogrow";

export const DebounceTextInput = ({label, state, autoGrow, ...props}) =>
    cs(
        [
            "debounce",
            (_, next) =>
                DebounceCache({
                    state,
                    next,
                }),
        ],
        ({debounce}) =>
            autoGrow
                ? TextInputAutoGrow({
                      label,
                      state: debounce.state,
                      onBlur: debounce.flush,
                      ...props,
                  })
                : TextInput({
                      label,
                      state: debounce.state,
                      onBlur: debounce.flush,
                      ...props,
                  })
    );
