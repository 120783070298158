import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {Static2} from "@common/react/static-2";
import {ContainerTileButtonTheme} from "../../tab/general/tiles/container-tiles/container-tile-button-theme";
import ReactDOM from "react-dom";
import {cx} from "emotion";
import {SuspendUpdate} from "@common/react/suspend-update";
import {LeftPanelClosable} from "../../../../common/left-panel-closable/left-panel-closable";
import React from "react";

export const ThemeLeftPanelSections = ({overrideLeftPanelRef, sections, parentRef, form, viewTree}) =>
    cs(["selected", (_, next) => UseState({next, initValue: false})], ["panelOverride", (_, next) => UseState({next, initValue: null})], ({selected, panelOverride}) => {
        return (
            <div className="child-section-content">
                {sections.map((section, index) => (
                    <div
                        className="section-item"
                        key={index}
                        onClick={() => {
                            panelOverride.onChange(section);
                            setTimeout(() => {
                                selected.onChange(section);
                            });
                            viewTree?.change((v) => v.concat(section.id));
                        }}
                    >
                        {section.label}

                        <img src={require("../icons/arrow-right.svg")} className="arrow-right" />
                    </div>
                ))}

                {overrideLeftPanelRef.get?.() &&
                    ReactDOM.createPortal(
                        <div
                            className={cx("override", {
                                active: selected.value,
                            })}
                            style={{}}
                        >
                            {cs(
                                ({}, next) =>
                                    SuspendUpdate({
                                        active: selected.value,
                                        next,
                                    }),
                                () =>
                                    LeftPanelClosable({
                                        onClose: () => {
                                            selected.onChange(null);
                                            viewTree?.change((v) => v.splice(0, v.length - 1));
                                        },
                                        label: (panelOverride.value?.label || "").toUpperCase(),
                                        content: panelOverride.value?.render(form),
                                        overrideLeftPanelRef: parentRef,
                                    })
                            )}
                        </div>,
                        overrideLeftPanelRef.get()
                    )}
            </div>
        );
    });
