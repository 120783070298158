const hexToRGB = (hex) => {
    if (!hex) return {r: 0, g: 0, b: 0};

    // strip the leading # if it's there
    hex = hex.replace(/^\s*#|\s*$/g, "");

    // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
    if (hex.length === 3) {
        hex = hex.replace(/(.)/g, "$1$1");
    }

    return {
        r: parseInt(hex.substring(0, 2), 16),
        g: parseInt(hex.substring(2, 4), 16),
        b: parseInt(hex.substring(4, 6), 16),
    };
};
exports.hexToRGB = hexToRGB;

const rgbToHex = (r, g, b) => {
    const componentToHex = (c) => {
        const hex = c.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
    };

    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
};
exports.rgbToHex = rgbToHex;

const percentToHex = (p) => {
    const percent = Math.max(0, Math.min(100, p)); // bound percent from 0 to 100
    const intValue = Math.round((p / 100) * 255); // map percent to nearest integer (0 - 255)
    const hexValue = intValue.toString(16); // get hexadecimal representation
    return hexValue.padStart(2, "0").toUpperCase(); // format with leading 0 and upper case characters
};
exports.percentToHex = percentToHex;

const lighten = (hex, percent) => {
    const {r, g, b} = hexToRGB(hex);

    return (
        "#" +
        (0 | ((1 << 8) + r + ((256 - r) * percent) / 100)).toString(16).substring(1) +
        (0 | ((1 << 8) + g + ((256 - g) * percent) / 100)).toString(16).substring(1) +
        (0 | ((1 << 8) + b + ((256 - b) * percent) / 100)).toString(16).substring(1)
    );
};
exports.lighten = lighten;

const darken = (hex, percent) => {
    const {r, g, b} = hexToRGB(hex);

    return (
        "#" +
        (0 | ((1 << 8) + r * (1 - percent / 100))).toString(16).substring(1) +
        (0 | ((1 << 8) + g * (1 - percent / 100))).toString(16).substring(1) +
        (0 | ((1 << 8) + b * (1 - percent / 100))).toString(16).substring(1)
    );
};
exports.darken = darken;

function lightOrDark(color) {
    let r, g, b, hsp;

    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {
        color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
        r = color[1];
        g = color[2];
        b = color[3];
    } else {
        const _color = hexToRGB(color);
        r = _color.r;
        g = _color.g;
        b = _color.b;
    }

    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

    // Using the HSP value, determine whether the color is light or dark
    if (hsp > 127.5) {
        return "light";
    } else {
        return "dark";
    }
}
exports.lightOrDark = lightOrDark;

const mix = (color2, color1, ratio) => {
    const color1RGB = hexToRGB(color1);
    const color2RGB = hexToRGB(color2);

    let r = color1RGB.r * ratio + color2RGB.r * (1 - ratio);
    let g = color1RGB.g * ratio + color2RGB.g * (1 - ratio);
    let b = color1RGB.b * ratio + color2RGB.b * (1 - ratio);

    return rgbToHex(r, g, b);
};
exports.mix = mix;

const PERCENTAGE_CHANGE = 10;

const generateColorScale = (colorStops = [], isSequential = false, min = 0, max = 100) => {
    let _min = min;
    let _max = Math.max(1, max);
    let rgbColorStops = [...colorStops];
    let oriColors = [...colorStops];

    function init() {
        if (isSequential) {
            rgbColorStops = oriColors.map((c) => hexToRGB(c));
        } else {
            const maxLoop = 5;
            let numDarkenRemaining = maxLoop;
            let numLightenRemaining = maxLoop;
            rgbColorStops = [...oriColors];

            let numLoops = Math.floor((_max + 1) / oriColors.length);

            while (numLoops > 0) {
                if (numDarkenRemaining <= 0) {
                    break;
                } else {
                    rgbColorStops = rgbColorStops.concat(oriColors.map((c) => darken(c, PERCENTAGE_CHANGE * (maxLoop - numDarkenRemaining + 1))));
                    numLoops--;
                    numDarkenRemaining--;
                }
            }

            while (numLoops > 0) {
                if (numLightenRemaining <= 0) {
                    break;
                } else {
                    rgbColorStops = rgbColorStops.concat(oriColors.map((c) => lighten(c, PERCENTAGE_CHANGE * (maxLoop - numLightenRemaining + 1))));
                    numLoops--;
                    numLightenRemaining--;
                }
            }
        }
    }

    init();

    const getColor = (value) => {
        if (isSequential) {
            const numOfColorStops = rgbColorStops.length;
            if (value < _min) return rgbColorStops[0];
            if (value > _max) return rgbColorStops[numOfColorStops - 1];

            const range = _max - _min;
            let weight = (value - _min) / range;
            const colorStopIndex = Math.max(Math.ceil(weight * (numOfColorStops - 1)), 1);

            const minColor = rgbColorStops[colorStopIndex - 1];
            const maxColor = rgbColorStops[colorStopIndex];

            weight = weight * (numOfColorStops - 1) - (colorStopIndex - 1);

            const r = Math.floor(weight * maxColor.r + (1 - weight) * minColor.r);
            const g = Math.floor(weight * maxColor.g + (1 - weight) * minColor.g);
            const b = Math.floor(weight * maxColor.b + (1 - weight) * minColor.b);

            return rgbToHex(r, g, b);
        }

        return rgbColorStops[value] ?? rgbColorStops[Math.abs((value + 1) % rgbColorStops.length)];
    };

    return {
        isSequential,
        setRange: (min, max) => {
            _min = min;
            _max = Math.max(1, max);
            init();
        },
        getColor,
        generateAllColors: () => {
            let colors = [];
            for (let i = 0; i <= _max; i++) {
                colors.push(getColor(i));
            }
            return colors;
        },
    };
};

exports.generateColorScale = generateColorScale;
