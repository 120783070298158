import {conflictRect} from "../dnd-common";

const {shiftTile, getOverlapTile, autoScrollCanvas} = require("./moving-helper");
const {gridHeight, numColumnsOfGrid} = require("../grid-constants");
const {autoMove} = require("../../../../../../../common/utils/auto-move");

const {consumeContext} = require("@common/react/context");
const {chain} = require("../../../../../../../common/utils/fs");
const {getDomPos} = require("./get-dom-pos");
const {GlobalKeyDown} = require("@common/react/keys/global-key-down");
const {equalDeep} = require("../../../../../../../common/utils/objects");
const {changeVector} = require("../../../../../../../common/utils/points");
const {toVector} = require("../../../../../../../common/utils/points");
const {addVector} = require("../../../../../../../common/utils/points");
const {spc} = require("@common/react/state-path-change");
const {GlobalMouseMove} = require("@common/react/global-mouse-move");
const {getELoc} = require("@common/react/get-e-loc");
const {GlobalMouseUp} = require("@common/react/global-mouse-up");
const {fragments} = require("@common/react/fragments");
const {UseState} = require("@common/react/use-state");
const {cs} = require("@common/react/chain-services");
const autoScroll = autoMove.init();

export const Moving = ({blockSize, restrictRect, onDone, getRootPos, rejectRect, spacing, tiles, draggingState, filterDropArea, allowFilterDrops, onDropToFilters, next}) =>
    cs(consumeContext("containerBoxRef"), ["state", (_, next) => UseState({next})], ({state, containerBoxRef}) => {
        const rect = (() => {
            if (!state.value) {
                return null;
            }
            const {pos1, pos2, rootPos0, tile, pos0} = state.value;
            const rect = {
                ...tile.size,
                ...(pos2 == null
                    ? tile.position
                    : getTilePosition({
                          pos1,
                          pos2,
                          rootPos0,
                          rootPos: getRootPos(),
                          tile,
                          blockSize,
                          spacing,
                      })),
            };

            if (allowFilterDrops(tile)) {
                if (
                    isInsideFiltersArea({
                        pos: draggingState.value?.moving?.pos,
                        filterAreaRect: filterDropArea.get().getBoundingClientRect(),
                    })
                ) {
                    return {
                        error: false,
                        restricted: false,
                    };
                }
            }

            const restricted = restrictRect(rect);

            return {
                error: !equalDeep(restricted, rect),
                restricted,
            };
        })();

        return fragments(
            state.value &&
                fragments(
                    GlobalMouseMove({
                        fn: (e) => {
                            spc(state, ["pos2"], () => getELoc(e));
                            const {tile, pos0, pos1} = state.value;
                            draggingState.onChange({
                                moving: {tile, pos: getELoc(e)},
                            });

                            const container = containerBoxRef.get();
                            autoScrollCanvas({
                                pos1,
                                blockSize,
                                container,
                                e,
                                gridHeight,
                                pos0,
                                spacing,
                                tileSize: tile.size,
                                autoScroll,
                            });
                        },
                    }),
                    GlobalMouseUp({
                        fn: (e) => {
                            autoScroll.forceStop();
                            const {pos2, tile} = state.value;

                            if (allowFilterDrops(tile)) {
                                if (
                                    isInsideFiltersArea({
                                        pos: draggingState.value.moving.pos,
                                        filterAreaRect: filterDropArea.get().getBoundingClientRect(),
                                    })
                                ) {
                                    onDropToFilters(tile);
                                    draggingState.onChange(null);
                                    state.onChange(null);
                                    return;
                                }
                            }

                            draggingState.onChange(null);

                            const drop = (rejectFunc = rejectRect, cb = () => {}, disableMove) => {
                                if (
                                    rejectFunc?.({
                                        tile,
                                        rect: rect.restricted,
                                    })
                                ) {
                                    const fuzzyRect = searchSpaceToDrop({
                                        isReject: (position) =>
                                            rejectFunc?.({
                                                tile,
                                                rect: position,
                                                skipRule: true,
                                            }),
                                        position: rect.restricted,
                                        disableMove,
                                    });

                                    if (fuzzyRect) {
                                        onDone({tile, rect: fuzzyRect});
                                        cb();
                                        state.onChange(null);
                                        return;
                                    }
                                }

                                if (
                                    pos2 &&
                                    !rejectFunc?.({
                                        tile,
                                        rect: rect.restricted,
                                    }) &&
                                    (rect.restricted.x !== tile.position.x || rect.restricted.y !== tile.position.y)
                                ) {
                                    cb();
                                    onDone({tile, rect: rect.restricted});
                                }
                                state.onChange(null);
                            };

                            const overlapTile = getOverlapTile({
                                movingTile: {
                                    ...tile,
                                    position: rect.restricted,
                                },
                                tiles: tiles.filter((t) => t.key != tile.key),
                            });
                            if (overlapTile) {
                                let shiftRect = shiftTile({
                                    tiles: tiles.filter((t) => t.key != tile.key && t.key != overlapTile.key),
                                    rect: rect.restricted,
                                    tile: overlapTile,
                                });
                                if (shiftRect) {
                                    const updatedTiles = tiles.map((t) =>
                                        t.key == overlapTile.key
                                            ? {
                                                  ...t,
                                                  position: shiftRect,
                                              }
                                            : t
                                    );
                                    drop(
                                        ({tile, rect}) => {
                                            if (rejectRect({tile, rect})) return true;
                                            return conflictRect({
                                                rect,
                                                tiles: updatedTiles.filter((t) => t.key !== tile.key),
                                            });
                                        },
                                        () => {
                                            overlapTile.onChangePosition(shiftRect);
                                        },
                                        true
                                    );
                                }
                            } else {
                                drop();
                            }
                        },
                    }),
                    GlobalKeyDown({
                        keyCombo: "Escape",
                        onKeyDown: () => {
                            autoScroll.forceStop();
                            state.onChange(null);
                            draggingState.onChange(null);
                        },
                    })
                ),
            next({
                active: state.value,
                rect,

                activate: ({e, tileIndex, tile, dom}) => {
                    e.preventDefault();

                    draggingState.onChange({
                        moving: {tile, pos: getELoc(e)},
                    });

                    return state.onChange({
                        tileIndex,
                        tile,
                        rootPos0: getRootPos(),
                        pos0: getDomPos(dom),
                        pos1: getELoc(e),
                    });
                },

                forTile: (tileIndex) => {
                    if (!state.value || state.value.tileIndex !== tileIndex) {
                        return null;
                    }

                    const {pos0, pos1, pos2, tile} = state.value;

                    return {
                        rejecting: rejectRect?.({
                            tile,
                            rect: rect.restricted,
                        }),
                        position: !pos2 ? pos0 : addVector(pos0, toVector(pos1, pos2)),
                    };
                },
            })
        );
    });

export const getTilePosition = ({pos1, pos2, rootPos0, rootPos, tile, blockSize, spacing}) =>
    chain(
        addVector(toVector(pos1, pos2), toVector(rootPos, rootPos0)),

        // toVector(pos0, pos2),
        (_) => changeVector(_, (v, key) => Math.floor(key == "x" ? v / blockSize : v / (gridHeight + spacing * 2))),
        (_) => addVector(tile.position, _)
    );

export const searchSpaceToDrop = ({isReject, position, disableMove = false, noResize}) => {
    const STEP = [0, -1, 1, -2, 2, -3, 3];
    let ret = {...position};

    const tryMove = (posKey, conditional) => {
        for (let i = 0; i < STEP.length; i++) {
            if (conditional(position[posKey] + STEP[i])) {
                const pos = {
                    ...position,
                    [posKey]: position[posKey] + STEP[i],
                    width: 1,
                    height: 1,
                };

                if (!isReject(pos)) {
                    ret[posKey] = position[posKey] + STEP[i];
                    return true;
                }
            }
        }
    };

    const tryResize = (key, conditional) => {
        let updatedPos = {...ret};
        //console.log(updatedPos, conditional(updatedPos));

        while (updatedPos[key] >= 0 && (isReject(updatedPos) || conditional(updatedPos))) {
            updatedPos[key] = updatedPos[key] - 1;
        }

        if (updatedPos[key] >= 1 && ret[key] != updatedPos[key]) {
            ret[key] = updatedPos[key];
            return true;
        }
    };

    if (!disableMove) {
        const moveDone = tryMove("x", (value) => value >= 0 && value < numColumnsOfGrid);

        if (moveDone) {
            if (!isReject(ret)) {
                return ret;
            }
        }

        // tryMove("y", (value) => value >= 0 && value < gridHeight);
    }

    if (!noResize) {
        const isResizeDone = tryResize("width", ({x, width}) => x + width > numColumnsOfGrid);

        if (isResizeDone) {
            if (!isReject(ret)) {
                return ret;
            }
        }

        tryResize("height", ({y, height}) => y + height > gridHeight);
    }

    if (!isReject(ret)) {
        return ret;
    }
};

export const isInsideFiltersArea = ({pos = {x: -Infinity, y: -Infinity}, filterAreaRect}) => {
    const {x, y, width, height} = filterAreaRect;
    return pos.x >= x && pos.x <= x + width && pos.y >= y && pos.y <= y + height;
};
