import "./basic-input.scss";

import {cx} from "emotion";
import * as React from "react";
import {cs} from "../../react/chain-services";

// TODO material.io input anatomy: error msg, require
export const BasicInput = ({
    renderInput,
    hasValue,
    disabled,
    className,
    label,
    noLabel = false,
    hasError,
    domRef,
    errorMessage,
    unit,
    status,
    feedValue,
    renderToggle,
    icon,
    info,
    onFocus,
    subText,
    tooltipWidth,
    allowMoveOverTooltip,
}) =>
    cs(({}) => {
        const hasToggle = renderToggle;

        return (
            <div
                className={cx("basic-input-3fv", className, {
                    disabled,
                    "with-unit": unit,
                    hasError,
                    feeding: feedValue,
                    "has-toggle": hasToggle,
                    "no-label": noLabel,
                })}
                ref={domRef}
                onClick={() => onFocus && onFocus()}
            >
                {feedValue && <div className="feed-value">{feedValue}</div>}

                {icon && (
                    <div className="icon" onClick={icon.click}>
                        {icon.value}
                    </div>
                )}

                <div className={cx("label-input", {"with-value": hasValue})}>
                    {renderInput()}
                    {subText && <div className="sub-text">{subText}</div>}

                    {!noLabel && (
                        <div className="label">
                            {label}

                            {info && (
                                <div className="info">
                                    <i className="material-icons">info</i>
                                    <div
                                        className={cx("info-box", allowMoveOverTooltip && "allow-move-over")}
                                        style={{width: tooltipWidth}}
                                    >
                                        {info}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}

                    {status && <div className="status">{status}</div>}

                    {hasError && errorMessage && <div className="error-message">{errorMessage}</div>}
                </div>

                {hasToggle && <div className="toggle">{renderToggle?.()}</div>}

                {unit && <div className="unit">{unit}</div>}
            </div>
        );
    });
