import React from "react";

import {cs} from "@common/react/chain-services";
import {lighten} from "@common/utils/color-util";
import {UseState} from "@common/react/use-state";
import {Static2} from "@common/react/static-2";

import {CircleRadius} from "../../model-panel";
import {isTableHasRowLevelSecurity} from "../../model-utils";
import {cx} from "emotion";
import {RowSecurityIcon} from "../more-controls/icons/more-control-icons";

export const RowLevelSecurity = ({table, color, modelActionTooltip, interactions}) =>
    cs(["hovering", (_, next) => UseState({next})], ["actionRef", (_, next) => Static2({next})], ({hovering, actionRef}) => {
        const disabled = table.deleted && !isTableHasRowLevelSecurity(table);

        return (
            <g fillOpacity={disabled ? 0.5 : 1} strokeOpacity={disabled ? 0.5 : 1}>
                <path
                    ref={actionRef.set}
                    className={cx("action-btn", disabled && "disabled")}
                    onMouseEnter={(e) => {
                        hovering.onChange(true);
                        const {x, y} = actionRef.get()?.getBoundingClientRect();
                        modelActionTooltip.showTooltip({
                            pos: {
                                x,
                                y,
                                transform: `translate(-100%)`,
                            },
                            info: "Row-Level Security",
                        });
                    }}
                    onMouseLeave={() => {
                        hovering.onChange(false);
                        modelActionTooltip.hideToolTip();
                    }}
                    onClick={(e) => {
                        if (disabled) return true;

                        e.preventDefault();
                        e.stopPropagation();
                        interactions.openRowLevelSecurity({
                            tableId: table.id,
                            dataSourceID: table.dataSourceID,
                            dataSourceTableID: table.dataSourceTableID,
                        });
                    }}
                    x={table.position.x - (CircleRadius * 2 - 6)}
                    y={table.position.y - CircleRadius * 2 + 1}
                    data-name="Row-Level Security"
                    d="M-49.057-32.779A59,59,0,0,1,0-59v25A34,34,0,0,0-28.27-18.889Z"
                    transform="translate(60.011 60)"
                    fill={table.deleted ? "#E9ECEF" : hovering.value ? lighten(color, 80) : lighten(color, 70)}
                    stroke="#fff"
                    strokeWidth="2"
                />
                <RowSecurityIcon x={29.5} y={13.7} style={{pointerEvents: "none"}} />
            </g>
        );
    });
