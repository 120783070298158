import {cx} from "emotion";
import React from "react";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {GlobalMouseMove} from "@common/react/global-mouse-move";
import {spc} from "@common/react/state-path-change";
import {getELoc} from "@common/react/get-e-loc";
import {getTilePosition} from "../../../../../collection/common/dnd-grid-panel/dnd/moving";
import {autoScrollCanvas} from "../../../../../collection/common/dnd-grid-panel/dnd/moving-helper";
import {GlobalMouseUp} from "@common/react/global-mouse-up";
import {tileTypes} from "../../../../../collection/common/select-tile-type/tile-types";

export const ContextualDraggingNewTile = ({draggingState, onDrop, tile}) =>
    cs(({}) => {
        const {x, y} = draggingState.value;

        return (
            <>
                <div
                    className={cx("dragging-new-tile-a44")}
                    style={{
                        top: y,
                        left: x,
                    }}
                >
                    {tile.icon}
                </div>

                {GlobalMouseMove({
                    fn: (e) => {
                        draggingState.onChange(getELoc(e));
                    },
                })}

                {GlobalMouseUp({
                    fn: (e) => {
                        onDrop();
                    },
                })}
            </>
        );
    });
