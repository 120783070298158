exports.vennDiagramTemplate = {
    $type: "VennDiagramTile",
    style: {
        autoRound: true,
        dataLabels: {
            show: true,
        },
        legendStyle: {
            legendShown: false,
            legendPosition: "Auto",
            legendSize: "Auto",
        },
        title: {
            show: true,
        },
    },
};
