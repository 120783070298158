//field is date, dimension default
//field is measure, dimension is second
const {getFieldType} = require("@common/ui-components/charts/common/get-field-type");
const {isEmpty} = require("../../../../../../../../../../../../../../common/utils/collections");
const objectUtils = require("../../../../../../../../../../../../../../common/utils/objects");
const {isSameField} = require("@common/ui-components/charts/factory/is-same-field");
const {keepOnly} = require("../../../../../../../../../../../../../../common/utils/objects");

const getAxisSortingUpdated = ({tileFields, tile, fieldVal: {newVal, oriVal}}) => {
    const getSortField = (field) => ({
        ...keepOnly(field, ["modelID", "modelTableID", "modelColumnID"]),
        direction: "Asc",
    });

    return newVal == null
        ? (() => {
              const _sorts = tileFields.fieldsForSort.filter((s) => !isSameField(s, oriVal));
              return {
                  sort: !isEmpty(_sorts)
                      ? [
                            {
                                ...keepOnly(_sorts[0], ["modelID", "modelTableID", "modelColumnID"]),
                                direction: "Asc",
                            },
                        ]
                      : [],
              };
          })()
        : (() => {
              const primarySortField = !isEmpty(tile.value.sort) ? tile.value.sort[0] : null;
              if (primarySortField) {
                  if (getFieldType(primarySortField) === "date") {
                      return {sort: tile.value.sort};
                  } else {
                      return {sort: [...tile.value.sort, getSortField(newVal)]};
                  }
              } else {
                  return {sort: [getSortField(newVal)]};
              }
          })();
};

exports.getAxisSortingUpdated = getAxisSortingUpdated;

const getAxisSortingUpdatedByLimit = ({tile, field, dimensionFieldAttr}) => ({
    sort: [
        keepOnly(field, ["modelID", "modelTableID", "modelColumnID", "direction"]),
        ...(field.$type === "MeasureTileField" && !objectUtils.isEmpty(tile.value[dimensionFieldAttr])
            ? [
                  {
                      ...keepOnly(tile.value[dimensionFieldAttr], ["modelID", "modelTableID", "modelColumnID"]),
                      direction: field.direction,
                  },
              ]
            : []),
    ],
});

exports.getAxisSortingUpdatedByLimit = getAxisSortingUpdatedByLimit;
