/**
 * @deprecated Please use any-action-2 instead, especially for components used by SDK (because no ReactDOM available in SDK)
 */
// This service module capture any user click (or scroll) in the window and inform listeners
const {addRemove} = require("../utils/collections");

let anyActionListeners = [];

if (typeof window !== "undefined") {
    window.addEventListener(
        "click",
        (e) => {
            anyActionListeners.forEach((l) => l(e));
        },
        true
    );
}

const anyAction = addRemove(anyActionListeners);

import {FComponent} from "./f-component";
import ReactDOM from "react-dom";

export class AnyAction extends FComponent {
    constructor(props, context) {
        super(props, context);

        this.onUnmount(
            anyAction((e) => {
                if (this.props.disabled) {
                    return;
                }

                if (ReactDOM.findDOMNode(this).contains(e.composedPath()[0])) {
                    // e.stopPropagation();
                    return;
                }
                this.props.fn(e);
            })
        );
    }

    render() {
        const {next} = this.props;

        return next();
    }
}
