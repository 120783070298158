import * as React from "react";
import {cs} from "@common/react/chain-services";
import {cx} from "emotion";
import "./badge.scss";
import {tooltipService4} from "../tooltip3/tooltip-service-3";

//size: lg, md
//type: success, warning, default, info

export const Badge = ({icon, label, size = "md", type = "default", direction = "below", tooltip: tooltipText, tooltipClassName}) =>
    cs(
        [
            "tooltip",
            ({}, next) =>
                tooltipText
                    ? tooltipService4({
                          direction,
                          className: tooltipClassName,
                          next,
                      })
                    : next(),
        ],
        ({tooltip}) => (
            <div className={cx(`badge-88e badge`, `size-${size}`, `${type}-type`, !label && "no-label", !!tooltipText && "has-tooltip")} {...(tooltip?.(() => tooltipText) ?? {})}>
                {icon && <>{icon}</>}
                {label && <div className={cx("label", {"has-icon": icon})}>{label}</div>}
            </div>
        )
    );
