import React from "react";

import { cs } from "@common/react/chain-services";
import { GlobalEvent } from "@common/react/global-event";
import { spc } from "@common/react/state-path-change";
import { PlusIcon } from "@common/ui-components/icons/global-icons";
import { SearchableSelect } from "@common/ui-components/searchable-select/searchable-select";
import { VerbDialogBodyScrollbar } from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";
import { ButtonIcon } from "@common/form/buttons/button-icon/button-icon";
import { Button } from "@common/form/buttons/button/button";
import { ffToBasicInput } from "@common/form/ff-to-basic-input";
import { ffToDropdown } from "@common/form/ff-to-dropdown";
import { TextInput } from "@common/form/text-input/text-input";
import { DropdownSelect } from "@common/ui-components/dropdown-select/dropdown-select";
import { TrashIcon } from "../../../../../../common/icons/trash-icon";
import { SelectFolderService } from "../../../../collections/folder-cm/select-folder-service/select-folder-service";
import { settingKey } from "../../../settings-constants";
import { keyToArray } from "../manage-access-key-dialog";

export const AccessKeyParameters = ({form, params, modal, stepIndex, editMode, saving, folders, themes}) =>
    cs(
        [
            "selectFolderService",
            (_, next) =>
                folders
                    ? SelectFolderService({
                          apiType: modal.$type === settingKey.IFRAME ? "iframeKey" : "accessKey",
                          state: form.field("folderID").state,
                          label: "Select a folder",
                          folders,
                          next,
                      })
                    : next(null),
        ],
        ({selectFolderService}) => {
            const isIframe = modal.$type === settingKey.IFRAME;
            return (
                <>
                    <VerbDialogBodyScrollbar>
                        <div className="input-wrapper">
                            {TextInput({
                                label: "Key Name",
                                placeholder: "Acme Corp, Inc",
                                ...ffToBasicInput(form.field("name")),
                            })}
                        </div>

                        {modal?.$type != settingKey.DATA_ACCESS && (
                            <div className="dropdown-wrapper full-width">
                                {SearchableSelect({
                                    label: "Key Design Theme",
                                    list: [
                                        {
                                            id: null,
                                            name: "Use Collection Default Theme",
                                        },
                                    ].concat(themes || []),
                                    valueToInputLabel: (v) => (v.id ? v.name : "Use Collection Default Theme"),
                                    valueToLabel: (v) => <span>{v.id ? v.name : "Use Collection Default Theme"}</span>,
                                    ...ffToDropdown(form.field("themeID"), ["id"]),
                                })}
                            </div>
                        )}

                        <div className="dropdown-wrapper full-width">
                            {DropdownSelect({
                                label: "Use Active Auth Endpoint",
                                list: [
                                    {label: "Yes", value: true},
                                    {label: "No", value: false},
                                ],
                                valueToLabel: (v) => v.label,
                                ...ffToDropdown(form.field("activeAuth"), ["value"]),
                            })}
                        </div>

                        {isIframe && (
                            <div className="dropdown-wrapper full-width">
                                {DropdownSelect({
                                    label: "iFrame Domain",
                                    list: [
                                        {label: "Revive Media (data.revivemedia.us)", value: false},
                                        {label: "Generic (ad-reporting.net)", value: true},
                                    ],
                                    valueToLabel: (v) => v.label,
                                    ...ffToDropdown(form.field("genericUrl"), ["value"]),
                                })}
                            </div>
                        )}

                        {selectFolderService?.render?.()}

                        {keyToArray(params.value.userValues).length > 0 && !form.field("activeAuth").state.value && AccessKeyParameter({params})}
                    </VerbDialogBodyScrollbar>

                    {GlobalEvent({
                        fn: (e) => {
                            if (e.key == "Enter" && form.valid && !saving.value) {
                                editMode ? form.submit() : stepIndex.onChange(1);
                            }
                        },
                        eventName: "keydown",
                    })}

                    <div className="buttons">
                        <Button btnType="secondary" onClick={() => modal.resolve()}>
                            Cancel
                        </Button>
                        <Button disabled={!form.valid || saving.value} onClick={() => (editMode ? form.submit() : stepIndex.onChange(1))}>
                            {editMode ? "Save" : "Next"}
                        </Button>
                    </div>
                </>
            );
        }
    );

export const AccessKeyParameter = ({params}) => (
    <div className="parameter-wrapper">
        <div className="parameter-title">Parameters</div>

        <div className="parameter-list">
            {keyToArray(params.value.userValues).map((item, index) => (
                <div className="parameter-item" key={index}>
                    <div className="key-item">{item.key}</div>

                    <div className="key-value">
                        {item.value.$type == "UserStringValue" ? (
                            <div className="input-item">
                                {TextInput({
                                    label: "Value (optional)",
                                    state: {
                                        value: item.value.value,
                                        onChange: (val) =>
                                            params.change((p) => ({
                                                ...p,
                                                userValues: {
                                                    ...p.userValues,
                                                    [item.key]: {
                                                        ...p.userValues[item.key],
                                                        value: val,
                                                    },
                                                },
                                            })),
                                    },
                                })}
                            </div>
                        ) : (
                            <div className="values">
                                {item.value.values.map((val, index) => (
                                    <div className="input-item" key={index}>
                                        {TextInput({
                                            label: "Value (optional)",
                                            onPaste: (e) => {
                                                let clipboardData, pastedData;

                                                e.stopPropagation();
                                                e.preventDefault();

                                                clipboardData = e.clipboardData || window.clipboardData;
                                                pastedData = clipboardData.getData('Text') || '';

                                                const parseValues = pastedData.trim().split(/\,|\t|\n/).map(v => (v || '').trim()).filter(v => !!v);
                                                if(parseValues.length)
                                                    spc(params, ['userValues', item.key, 'values'], (values) => {
                                                        const newValues = (values ?? []).map((item, i) => {
                                                            if (i == index)
                                                                return {
                                                                    ...item,
                                                                    value: parseValues[0] ?? '',
                                                                };
                                                            return item;
                                                        })
                                                        if(parseValues.length === 1) return newValues;
                                                        return newValues.slice(0, index + 1).concat(parseValues.slice(1).map(v => ({$type: 'UserStringValue', value: v}))).concat(newValues.slice(index+1))
                                                    })
                                            },
                                            state: {
                                                value: val.value,
                                                onChange: (v) =>
                                                    params.change((p) => ({
                                                        ...p,
                                                        userValues: {
                                                            ...p.userValues,
                                                            [item.key]: {
                                                                ...p.userValues[item.key],
                                                                values: p.userValues[item.key].values.map((item, i) => {
                                                                    if (i == index)
                                                                        return {
                                                                            ...item,
                                                                            value: v,
                                                                        };
                                                                    return item;
                                                                }),
                                                            },
                                                        },
                                                    })),
                                            },
                                        })}

                                        <div className="btn-wrapper">
                                            {item.value.values.length > 1 && (
                                                <ButtonIcon
                                                    size="small"
                                                    className="remove-btn"
                                                    btnType="border"
                                                    icon={<TrashIcon />}
                                                    onClick={() =>
                                                        params.change((p) => ({
                                                            ...p,
                                                            userValues: {
                                                                ...p.userValues,
                                                                [item.key]: {
                                                                    ...p.userValues[item.key],
                                                                    values: p.userValues[item.key].values.filter((item, i) => i != index),
                                                                },
                                                            },
                                                        }))
                                                    }
                                                />
                                            )}

                                            {index == item.value.values.length - 1 && (
                                                <ButtonIcon
                                                    size="small"
                                                    className="add-btn"
                                                    btnType="secondary"
                                                    icon={<PlusIcon />}
                                                    onClick={() =>
                                                        params.change((p) => ({
                                                            ...p,
                                                            userValues: {
                                                                ...p.userValues,
                                                                [item.key]: {
                                                                    ...p.userValues[item.key],
                                                                    values: p.userValues[item.key].values.concat({
                                                                        $type: "UserStringValue",
                                                                        value: "",
                                                                    }),
                                                                },
                                                            },
                                                        }))
                                                    }
                                                />
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            ))}
        </div>
    </div>
);
