import {omit} from "@common/utils/objects";

const {concatArr} = require("../../../../../common/utils/objects");
const {sort, unique} = require("../../../../../common/utils/collections");
import {ModelPanelHelper} from "./model-panel-helper";

const mapTableWithRelationships = ({tables, relationships}) => {
    let ret = [];
    for (let table of tables) {
        const relatedRelationship = relationships.filter((r) => table.columns.find((c) => c.id == r.leftColumnID || c.id == r.rightColumnID));
        ret.push({
            ...table,
            relationships: relatedRelationship,
        });
    }

    return sort(ret, (t) => -t.relationships.length);
};

const getTableRelatedTo = ({table, tables}) => {
    let ret = [];

    for (let relationship of table.relationships) {
        let t = tables.find((t) => t.id != table.id && t.columns.find((c) => c.id == relationship.leftColumnID || c.id == relationship.rightColumnID));
        if (t) {
            ret = ret.concat(t);
        }
    }

    return unique(ret, (r) => r.id);
};

export const buildAutoSuggestModel = (dataSources) => {
    const _tables = concatArr(dataSources, (d) => d.tables.map((t) => ({...t, dataSourceID: d.id, dataSource: d})));
    const relationships = ModelPanelHelper.recommendRelationships({
        tables: _tables.map((t) => omit(t, ["position"])),
        relationships: [],
    });

    let tables = mapTableWithRelationships({tables: _tables, relationships});
    const atLeastTables = 10;

    if (tables.length > 0) {
        let ret = [];
        const build = (tables) => {
            if (tables.length == 0) {
                return {
                    tables: ret,
                    relationships: ModelPanelHelper.recommendRelationships({
                        tables: ret.map((t) => omit(t, ["position"])),
                        relationships: [],
                    }),
                };
            }

            const mostConnectedTables = tables.filter((t) => t.relationships.length == tables[0].relationships.length);

            for (let mostConnectedTable of mostConnectedTables) {
                ret = ret.concat(mostConnectedTables);
                ret = unique(ret.concat(getTableRelatedTo({tables, table: mostConnectedTable})), (r) => r.id);
                if (ret.length >= atLeastTables) {
                    return {
                        tables: ret,
                        relationships: ModelPanelHelper.recommendRelationships({
                            tables: ret.map((t) => omit(t, ["position"])),
                            relationships: [],
                        }),
                    };
                }
            }

            if (ret.length < atLeastTables) {
                return build(tables.filter((t) => t.relationships.length != tables[0].relationships.length));
            } else {
                return {
                    tables: ret,
                    relationships: ModelPanelHelper.recommendRelationships({
                        tables: ret.map((t) => omit(t, ["position"])),
                        relationships: [],
                    }),
                };
            }
        };

        return build(tables);
    }

    return {
        tables: [],
        relationships,
    };
};
