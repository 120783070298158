import "./in-the-year.scss";

import * as React from "react";
import {cs} from "@common/react/chain-services";
import {NumberInput} from "@common/form/number-input/number-input";
import {ffToNumberInput} from "@common/form/ff-to-number-input";

export const InTheYear = ({form}) =>
    cs(({}) => (
        <div className="edit-custom-range in-the-year-00s inline">
            <NumberInput
                key={form.field('$type').state.value}
                {...{
                    label: "Year",
                    ...ffToNumberInput(form.field(["year"])),
                }}
            />
        </div>
    ));
