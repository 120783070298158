import * as React from "react";
import {ShowHideToggle} from "../../../../../../../../../../../../../common/form/toggles/show-hide-toggle";
import {TextInput} from "../../../../../../../../../../../../../common/form/text-input/text-input";
import {scope} from "@common/react/scope";
import {ffToTextInput} from "../../../../../../../../../../../../../common/form/ff-to-text-input";
import {isTitleShown} from "../../display-size-rules";
import {HiddenFieldAlert} from "../../../common/hidden-field-alert/hidden-field-alert";
import {TextareaInput} from "../../../common/textarea-input/textarea-input";
import {getPath} from "@common/utils/arr-path";
import {ValueChipInput} from "../../../../../fields-tab/actions/add/common/value-chip-input";
import "./tile-title.scss";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {TITLE_ALIGNMENT} from "../../../common/tile-title/tile-title";
import {ffToDropdown} from "../../../../../../../../../../../../../common/form/ff-to-dropdown";

export const TileTitle = ({tile, parentTile, tileAction, form, size}) => ({
    label: "Tile Title",
    control: isTitleShown({size})
        ? ShowHideToggle({state: scope(tile, ["style", "title", "show"])})
        : HiddenFieldAlert({
              info: "Tile title can only be shown when the chart is at least 3 squares high.",
          }),
    render: () => {
        const isTileTitleShown = isTitleShown({size}) && getPath(tile.value, ["style", "title", "show"]);
        return (
            <div className="pie-chart-tile-title-f2d">
                <div className="subsection">
                    <div className="content">
                        {parentTile
                            ? ValueChipInput({
                                  tile: parentTile,
                                  tileAction,
                                  label: "Title",
                                  beforeField: "tileTitleBefore",
                                  afterField: "tileTitleAfter",
                              })
                            : TextInput({
                                  label: "Title",
                                  ...ffToTextInput(form.field(["title"])),
                              })}

                        {scope(tile, ["style", "title", "show"]).value &&
                            DropdownSelect({
                                list: [
                                    {
                                        label: "Left Aligned",
                                        value: TITLE_ALIGNMENT.LEFT,
                                    },
                                    {
                                        label: "Center Aligned",
                                        value: TITLE_ALIGNMENT.CENTER,
                                    },
                                ],
                                label: "Title Alignment",
                                valueToLabel: (v) => v?.label ?? "",
                                ...ffToDropdown(form.field("style.title.titleAlign"), ["value"]),
                            })}
                    </div>
                </div>

                {tile.value.title && (
                    <div className="subsection">
                        <div className="header">
                            Title Info Icon
                            <div className="control">
                                {isTileTitleShown
                                    ? ShowHideToggle({
                                          state: scope(tile, ["style", "showTitleInfoIcon"]),
                                      })
                                    : HiddenFieldAlert({
                                          info: "Unavailable when the Tile Title is hidden.",
                                      })}
                            </div>
                        </div>

                        {isTileTitleShown && tile.value?.style.showTitleInfoIcon && (
                            <div className="content">
                                {TextareaInput({
                                    state: scope(tile, ["style", "titleInfoIconText"]),
                                })}
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    },
});
