import "./line-display.scss";

import * as React from "react";

import {scope} from "@common/react/scope";
import {cs} from "@common/react/chain-services";
import {stateToSelect} from "@common/ui-components/form/state-to-select";
import {getPath} from "@common/utils/arr-path";
import {ThumbnailSelect} from "../../../../../../../../../common/thumbnail-select/thumbnail-select";

import {LineMarkerSelect, LineMarkerToggle} from "../../../common/line-marker-select";
import {LineWidthSelect} from "../../../common/line-width-select";
import {MultipleAxisOptions} from "../../../common/multiple-axis-options";

export const LineDisplay = ({tile}) =>
    cs(({}) => (
        <div className="line-display-section-64f">
            <div className="line-display">
                {ThumbnailSelect({
                    ...stateToSelect(scope(tile, ["style", "displayType"]), ["name"]),
                    list: lineTypes,
                })}

                {LineWidthSelect(scope(tile, ["style", "thickness"]))}

                {MultipleAxisOptions({
                    state: scope(tile, ["style", "multipleAxisOption"]),
                })}
            </div>

            <div className="subsection line-marker">
                <div className="header">
                    Markers
                    <div className="control">{LineMarkerToggle(scope(tile, ["style", "marker"]))}</div>
                </div>
                {getPath(tile.value, ["style", "marker"]) !== "None" && <div className="content">{LineMarkerSelect(scope(tile, ["style", "marker"]))}</div>}
            </div>
        </div>
    ));

export const lineTypes = [
    {
        label: "Straight",
        name: "Line",
        thumbnail: require("./icons/line-straight-icon.svg"),
    },
    {
        label: "Smooth",
        name: "Spline",
        thumbnail: require("./icons/line-smooth-icon.svg"),
    },
];
