const {changeTileFields} = require("../change-tile-fields");
const {arrMapToO} = require("../../../../../common/utils/objects");
const {tileFieldAttrs} = require("../tile-field-attrs");

/**
 * apply fields map onto a tile or a collection
 * */

const getTileWithMappedFields = (tile, fieldsMap) => {
    const getMappedField = cGetMappedField(fieldsMap);
    const fieldAttrs = tileFieldAttrs[tile.$type];

    // props having field: filters, sort, categorySort, limit, tile fields, tileActions (each contains tile and filters),
    return {
        ...tile,
        filters: tile.filters?.map((f) => ({
            ...f,
            columns: f.columns?.map((cf) => getMappedField(cf)),
        })),
        sort: tile.sort?.map((f) => getMappedField(f)),
        categorySort: tile.categorySort && getMappedField(tile.categorySort),
        limit: tile.limit && getMappedField(tile.limit),
        tileActions: tile.tileActions?.map((tileAction) => ({
            ...tileAction,
            tile: tileAction.tile && getTileWithMappedFields(tileAction.tile, fieldsMap),
            filters: tileAction.filters?.map((f) => ({
                ...f,
                columns: f.columns?.map((cf) => getMappedField(cf)),
            })),
            tileActionFields: tileAction.tileActionFields?.map((f) => getMappedField(f)),
        })),
        ...(tile.$type === "ContainerTile"
            ? {
                  tiles: tile.tiles?.map((t) => getTileWithMappedFields(t, fieldsMap)),
              }
            : arrMapToO(fieldAttrs, (attr) => changeTileFields(attr, tile, getMappedField))),
    };
};
exports.getTileWithMappedFields = getTileWithMappedFields;

const getCollectionWithMappedFields = (collection, fieldsMap) => {
    const getMappedField = cGetMappedField(fieldsMap);

    return {
        ...collection,
        // modelID: fieldsMap[0].destinationModelField.modelId,
        filters: collection.filters?.map((filter) => ({
            ...filter,
            columns: filter.columns.map((c) => getMappedField(c)),
        })),
        // DashboardCollection
        ...(collection.gridLocations && {
            gridLocations: collection.gridLocations.map((gl) => ({
                ...gl,
                tile: getTileWithMappedFields(gl.tile, fieldsMap),
            })),
        }),
        // ApiCollection, SparkCollection
        ...(collection.tile && {
            tile: getTileWithMappedFields(collection.tile, fieldsMap),
        }),
    };
};
exports.getCollectionWithMappedFields = getCollectionWithMappedFields;

const cGetMappedField = (fieldsMap) => (field) => {
    const mappedField = fieldsMap.find(
        ({oriField}) =>
            oriField.modelID === field.modelID &&
            oriField.modelTableID === field.modelTableID &&
            oriField.modelColumnID === field.modelColumnID
    )?.destinationModelField;

    return {
        ...field,
        ...(field.dataType && {dataType: mappedField?.dataType}),
        ...(field.name && {name: mappedField?.name}),
        ...(field.displayName && {displayName: mappedField?.displayName}),
        modelID: mappedField?.modelId,
        modelTableID: mappedField?.tableId,
        modelColumnID: mappedField?.id,
    };
};
