import "./api-collection-main.scss";

import React from "react";

import {Watch} from "@common/react/watch";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {consumeContext, provideContext} from "@common/react/context";

import {DashboardData} from "@common/ui-components/live/live-dashboard/data/dashboard-data";
import {LiveFilters} from "@common/ui-components/live/live-dashboard/live-filters/live-filters";
import {createLoadTileData} from "@common/ui-components/live/live-dashboard/data/load-tile-data";
import {PreviewTable} from "@common/ui-components/charts/downloadable-report/preview-table/preview-table";
import {AutoRefreshController} from "@common/ui-components/live/live-dashboard/live-grid/auto-refresh-controller";

import {initTheme} from "../../../theme/edit/common/theme-init";
import {ProvideDefaultTimezone} from "../../../collection/common/provide-default-timezone";
import {Invoke} from "@common/react/invoke";
import {recentStorage} from "../../../dashboard/env/recent-section/recent-storage";

export const ApiCollectionMain = ({saving, collection, apiKey = null}) =>
    cs(
        consumeContext("apis"),
        consumeContext("viewAsContext"),
        ["viewAs", ({viewAsContext}, next) => next(viewAsContext?.viewAs?.value ?? null)],
        ["theme", ({}, next) => next(initTheme({}))],
        ({theme}, next) => provideContext("theme", theme, next),
        (_, next) =>
            ProvideDefaultTimezone({
                next,
                timezone: null,
                defaultTimezoneToUtc: !collection.value.showTimezoneConversion ? true : collection.value.defaultTimezoneToUtc,
            }),

        ["currentCollection", (_, next) => UseState({initValue: collection.value, next})],
        [
            "collection1",
            ({currentCollection}, next) => {
                return next({
                    ...currentCollection.value,
                    filterStyle: {
                        ...currentCollection.value.filterStyle,
                        runType: "OnSubmit",
                    },
                });
            },
        ],

        consumeContext("filtersValueSet"),
        [
            "data",
            ({collection1, filtersValueSet}, next) =>
                DashboardData({
                    collection: collection1,
                    filterVals: filtersValueSet?.value,
                    next,
                }),
        ],

        ({data, collection1, apis, currentCollection, theme, viewAs}) => {
            return (
                <div className="live-dashboard-1w2 api-collection-main">
                    {Watch({
                        value: saving,
                        onChanged: (value) => {
                            if (!value) {
                                currentCollection.onChange(collection.value);
                            }
                        },
                    })}

                    <div className="filters">
                        {theme &&
                            LiveFilters({
                                collection: {
                                    value: {
                                        ...collection1,
                                        filterStyle: {
                                            showLabels: true,
                                            runType: "OnSubmit",
                                        },
                                    },
                                },
                                filterForms: data.filterForms,
                                onRunReport: () => data.unblockChanges(),
                                apiKey: viewAs?.value || apiKey,
                            })}
                    </div>

                    <div className="charts">
                        {(collection1.tile?.actualValueFields.length > 0 || collection1.tile?.aggregatedValues.length > 0) &&
                            AutoRefreshController({
                                enabledAutoRefresh: collection1?.autoDataRefresh,
                                backgroundColor: theme.general.tile.styles.tileBackgroundColorRGB,
                                loadDataOnInit: false,
                                tileID: collection1?.tile?.id,
                                tile: collection1?.tile,
                                tileFilters: data.getTileFilters?.(collection1.tile.id),
                                render: ({tile, tileFilters, refreshKey}, loadData, hasChanges) => {
                                    return PreviewTable({
                                        tile,
                                        tileFilters,
                                        saving,
                                        loadData: {
                                            key: viewAs?.value || apiKey,
                                            load: loadData
                                                ? createLoadTileData({
                                                      tileId: collection1.tile.id,
                                                      collectionId: collection1.id,
                                                      apis,
                                                      refreshKey,
                                                  })
                                                : null,
                                        },
                                    });
                                },
                            })}
                    </div>
                </div>
            );
        }
    );
