import {cs} from "@common/react/chain-services";
import * as React from "react";
import "./lp-dual-control-line.scss";

export const LpDualControlLine = ({left, right}) =>
    cs(({}) => (
        <div className="lp-dual-control-line-1s7">
            <div className="left">{left}</div>
            <div className="right">{right}</div>
        </div>
    ));
