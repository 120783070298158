import React from "react";
import {cs} from "@common/react/chain-services";
import {TextInput} from "../../../../../../../../../common/form/text-input/text-input";
import {ffToTextInput} from "../../../../../../../../../common/form/ff-to-text-input";
import {ToggleWithLabel} from "../../../../../../../../../common/form/toggles/yes-no-toggle";
import {ffToShowHideLine} from "../../../../../../../../../common/form/ff-to-show-hide-line";
import {Form2} from "@common/react/cs-form/form2";
import {dataSourceFormConfig} from "../../../../../common/data-source-form-config";
import {consumeContext} from "@common/react/context";
import {ConnectionSettingsLayout} from "../connection-settings-layout";
import {tooltipService3} from "../../../../../../common/tooltip3/tooltip-service-3";
import {Button} from "../../../../../../../../../common/form/buttons/button/button";
import {DsFolderField} from "../../../../../common/folders-dialog/ds-folder-field";
import {cx} from "emotion";

const {required} = require("@common/react/cs-form/validators/required");

export const ElasticsearchConnectionForm = ({data, adding, dsApi, formConfig, connectionStatus, next}) =>
    cs(
        consumeContext("apis"),
        [
            "form",
            ({apis}, next) =>
                Form2({
                    data,
                    fields: {
                        ...formConfig,
                        "connectionDetails.url": [required],
                        "connectionDetails.username": [required],
                        "connectionDetails.password": [required],
                        "connectionDetails.aliasesOnly": [],
                        "connectionDetails.groupingRegex": [],
                    },
                    next,
                }),
        ],
        [
            "testConnection",
            ({form}, next) =>
                next(() => {
                    const _connectionDetails = form.data.value?.connectionDetails;
                    const databaseConfigData = {
                        $type: data.value.$type,
                        ..._connectionDetails,
                    };

                    return dsApi.testConnection(
                        {
                            submitConnectionDetails: databaseConfigData,
                            connectionDetails: databaseConfigData,
                        },
                        {
                            type: data.value?.type,
                            name: form.data.value?.name,
                        }
                    );
                }),
        ],
        ({form, testConnection}) => {
            return next({
                valid: form.valid,
                beforeGoNext: testConnection,
                render: () =>
                    ConnectionSettingsLayout({
                        type: data.value.type,
                        adding,
                        connectionStatus,
                        controls: {
                            valid: form.valid,
                            onTestConnection: testConnection,
                            onDiscard: () =>
                                form.data.onChange({
                                    ...data.value,
                                    connectionDetails: {},
                                }),
                            onGoNext: dsApi?.onNextStep,
                        },
                        content: (
                            <>
                                {TextInput({
                                    label: "Display Name",
                                    ...ffToTextInput(form.field("name")),
                                })}

                                {DsFolderField({
                                    state: form.field("folderID").state,
                                })}

                                <div className="form-group-flex">
                                    {TextInput({
                                        label: "Elasticsearch Url",
                                        placeholder: "https://es.yourdomain.com/",
                                        ...ffToTextInput(form.field("connectionDetails.url")),
                                    })}
                                </div>

                                <div className="form-group-flex">
                                    {TextInput({
                                        label: "Username",
                                        ...ffToTextInput(form.field("connectionDetails.username")),
                                    })}

                                    {TextInput({
                                        label: "Password",
                                        ...ffToTextInput(form.field("connectionDetails.password")),
                                    })}

                                    {ToggleWithLabel({
                                        label: "Aliased Indexes Only",
                                        ...ffToShowHideLine(form.field("connectionDetails.aliasesOnly")),
                                    })}
                                </div>

                                <div className="form-group-flex">
                                    {TextInput({
                                        label: "Grouping Regex",
                                        placeholder: "^(?<env>\\w+)-(?<client>\\w+)-(-?\\w*)*$",
                                        ...ffToTextInput(form.field("connectionDetails.groupingRegex")),
                                    })}
                                </div>

                                {/*<div className="buttons">*/}
                                {/*    {cs(*/}
                                {/*        tooltipService3({*/}
                                {/*            direction: "above",*/}
                                {/*            tooltipContentStyle: {*/}
                                {/*                width: 150*/}
                                {/*            }*/}
                                {/*        }),*/}
                                {/*        ({tooltip}) => (*/}
                                {/*            <Button*/}
                                {/*                {...!form.valid ? tooltip(() => `All required fields must be filled before testing the connection`) : {}}*/}
                                {/*                className={cx({disabled: !form.valid    })}*/}
                                {/*                onClick={() => form.valid && testConnection()}*/}
                                {/*            >Test Connection</Button>*/}
                                {/*        )*/}
                                {/*    )}*/}
                                {/*    */}
                                {/*    <Button btnType="danger"*/}
                                {/*        onClick={() => form.data.onChange({ ...data.value, connectionDetails: {} })}*/}
                                {/*    >*/}
                                {/*        Discard Database</Button>*/}
                                {/*</div>*/}
                            </>
                        ),
                    }),

                // <div className="elasticsearch-connection-form data-source-configuration-form-5rq">
                //     <div className="col-left">
                //         <div className="header">Data Source Details</div>

                //         {TextInput({
                //             label: "Display Name",
                //             ...ffToTextInput(form.field("name"))
                //         })}

                //         <div className="header">Server Credentials</div>

                //         <div className="form-group-flex">
                //             {TextInput({
                //                 label: "Elasticsearch Url",
                //                 placeholder: "https://es.yourdomain.com/",
                //                 ...ffToTextInput(form.field("connectionDetails.url"))
                //             })}
                //         </div>

                //         <div className="form-group-flex">
                //             {TextInput({
                //                 label: "Username",
                //                 ...ffToTextInput(form.field("connectionDetails.username"))
                //             })}

                //             {TextInput({
                //                 label: "Password",
                //                 ...ffToTextInput(form.field("connectionDetails.password"))
                //             })}

                //             {ToggleWithLabel({
                //                 label: "Aliased Indexes Only",
                //                 ...ffToShowHideLine(form.field("connectionDetails.aliasesOnly"))
                //             })}
                //         </div>

                //         <div className="form-group-flex">
                //             {TextInput({
                //                 label: "Grouping Regex",
                //                 placeholder: "^(?<env>\\w+)-(?<client>\\w+)-(-?\\w*)*$",
                //                 ...ffToTextInput(form.field("connectionDetails.groupingRegex"))
                //             })}
                //         </div>
                //     </div>

                //     <div className="col-right">
                //         <div className="section information">
                //             <div className="text-header">Information</div>
                //         </div>

                //         <div className="section getting-connected">
                //             <div className="text-header active">
                //                 Getting Connected
                //             </div>

                //             <div className="content">
                //                 <div>
                //                     Provide the details and credentials for the data source you're trying to connect to.
                //                     <p>Verb will query the schema and give you the option to include/exclude data like tables, columns and rows.</p>
                //                     <p>All of the fields are required unless explicitly noted.</p>
                //                 </div>
                //             </div>
                //         </div>
                //     </div>
                // </div>
            });
        }
    );
