import React from "react";
import {cs} from "@common/react/chain-services";
import {ModelPanelHelper} from "../../../common/model-panel-helper";
import {
    SPLIT_CHAR,
    drawRelationships,
    generateAnchorsAndRelationships,
    generateAnchorsInTable,
    getPotentiallyInvalidRelationships,
} from "./relationships-common";
import {cx} from "emotion";
import {RelationshipErrors} from "./relationship-errors";
import {OuterJoinIcons} from "./outer-join-icons";
import {RELATIONSHIP_LEFT_JOIN} from "../model-constrants";
import {UseMemo} from "@common/react/use-memo";
import {consumeContext} from "@common/react/context";

export const Relationships = ({
    relationships: _relationships,
    scale,
    interactions,
    hoverInteractions,
    dataSources,
    textBoxRef,
    autoSuggest,
    modelErrorBoxRef,
    relationshipTablesState,
    ...otherProps
}) => {
    const tables = otherProps.tables
        .filter((t) => t.id)
        .map((table) => ({
            ...table,
            columns: table.columns.concat(table.disabledColumns),
        }));

    const suggestionRelationships =
        interactions.setting?.name == "suggest-relationship" || interactions.setting?.name == "all-suggested-relationships"
            ? interactions.setting?.data?.suggestedRelationships || []
            : [];

    const relationships = _relationships.concat(
        suggestionRelationships.filter((s) => {
            return !_relationships.find((r) => r.leftColumnID == s.leftColumnID && r.rightColumnID == s.rightColumnID);
        })
    );

    return cs(
        consumeContext("routing"),
        ["data", (_, next) => next(generateAnchorsAndRelationships({relationships, tables}))],
        [
            "shortestPath",
            ({data}, next) => {
                const {anchors, relationshipsTable} = data;

                const showShortestPath = interactions.setting?.showShortestPath;
                if (showShortestPath) {
                    return next(
                        ModelPanelHelper.generateShortestPath({
                            tableID: showShortestPath.tableID,
                            targetTableID: showShortestPath.targetTableID,
                            relationshipsTable,
                            anchors,
                        })
                    );
                }

                return next([]);
            },
        ],
        [
            "invalidInnerJoinRelationships",
            ({data}, next) => {
                return UseMemo({
                    fn: () => getPotentiallyInvalidRelationships({relationships: data.relationshipsTable}),
                    deps: [JSON.stringify(data.relationshipsTable)],
                    next,
                });
            },
        ],
        ({shortestPath, data, invalidInnerJoinRelationships, routing}) => {
            const {anchors, relationshipsTable} = data;

            const SELECTED_COLOR = "#546B81";

            const isHighlightAnchor = (anchor) => {
                for (let path of shortestPath) {
                    for (let relationship of path.relationships) {
                        if (relationship.anchors.find((a) => a == `${anchor.id}${SPLIT_CHAR}${anchor.table.id}`)) {
                            return true;
                        }
                    }
                }

                const isHighlighted = (relationship) =>
                    (relationship?.anchors || []).find((a) => a == `${anchor.id}${SPLIT_CHAR}${anchor.table.id}`);

                const {groupedRelationships, suggestedRelationships} = interactions.setting?.data || {};

                if (groupedRelationships) {
                    const relationship = relationshipsTable.find((r) => r.relationships.find((r) => r.id == groupedRelationships[0].id));

                    if (isHighlighted(relationship)) {
                        return true;
                    }
                }

                if (suggestedRelationships) {
                    const relationshipsFound = relationshipsTable.filter((r) =>
                        r.relationships.find((r) => suggestedRelationships.find((s) => s.id == r.id))
                    );

                    for (let relationship of relationshipsFound) {
                        if (isHighlighted(relationship)) {
                            return true;
                        }
                    }
                }

                if (hoverInteractions.value?.relationship) {
                    const relationship = hoverInteractions.value?.relationship;
                    if (isHighlighted(relationship)) {
                        return true;
                    }
                }

                return false;
            };

            const drawLine = ({relationship, index}) => {
                const isHighlight = (relationship) => {
                    if (relationship.suggestion) return "#000";

                    const {groupedRelationships, suggestedRelationships} = interactions.setting?.data || {};

                    if (groupedRelationships) {
                        if (groupedRelationships.find((r) => r.id == relationship.relationships[0].id)) {
                            return SELECTED_COLOR;
                        }
                    }

                    if (suggestedRelationships) {
                        if (relationship.relationships.find((r) => suggestedRelationships.find((s) => s.id == r.id))) {
                            return SELECTED_COLOR;
                        }
                    }

                    const activeRelationship = hoverInteractions.value?.relationship;

                    if (activeRelationship?.id == relationship.id) {
                        return SELECTED_COLOR;
                    }

                    if (shortestPath.length > 0) {
                        let isHighlight = shortestPath.find((c) => c.relationships.findIndex((r) => r.id == relationship.id) > -1);
                        if (isHighlight) {
                            return isHighlight.color;
                        }
                    }

                    return "#d4dadf";
                };

                const draw = (pos1, pos2) => {
                    const gen = d3
                        .linkHorizontal()
                        .x((d) => d.x)
                        .y((d) => d.y);

                    return gen({source: pos1, target: pos2});
                };

                const autoSuggestRelationshipFound = (relationshipTablesState || []).find((r) => r.id == relationship.id);

                const {suggestion, hidden, added} = autoSuggestRelationshipFound || {};

                const isSuggestionRelationship =
                    suggestion || (relationship.relationships.length == 1 && relationship.relationships[0].suggestion);

                const invalidInnerJoinRelationship = invalidInnerJoinRelationships.find((r) => r.id == relationship.id);

                const {relationships: errorRelationships} = JSON.parse(routing.params.invalidRelationships || "{}");

                const isInvalidRelationship =
                    errorRelationships &&
                    relationship.relationships.find((r) => errorRelationships.find((_r) => errorRelationships.includes(r.id)));

                const clickableProps = {
                    ref: (elem) =>
                        d3.select(elem).on("click", (e) => {
                            if (dataSources && !isSuggestionRelationship && relationship.id) {
                                const table = relationship.tables.find((t) =>
                                    t.columns.find((column) => column.id == relationship.relationships[0].leftColumnID)
                                );

                                interactions.editRelationship({
                                    groupedRelationships: relationship.relationships.filter((r) => !r.suggestion),
                                    table: table,
                                    target: relationship.tables.find((t) => t.id != table.id),
                                });
                                e.preventDefault();
                                e.stopPropagation();
                            }
                        }),
                    style: {cursor: isSuggestionRelationship ? "" : relationship.id ? "pointer" : "wait"},
                    onMouseEnter: () => hoverInteractions.onChange({relationship: relationship}),
                    onMouseLeave: () => hoverInteractions.onChange(null),
                    strokeWidth: Math.min(16 / scale, 16),
                    stroke: invalidInnerJoinRelationship ? "#ECCD39" : "transparent",
                    strokeOpacity: 0.2,
                    strokeLinecap: "round",
                };

                const props = {
                    d: draw(relationship.position, relationship.targetPosition),
                    fill: "transparent",
                    strokeDasharray: isSuggestionRelationship ? "3 2" : null,
                    className: cx("relationship-line", hidden && "hidden"),
                };

                const centerPos = ModelPanelHelper.getCenterPositionBetweenTwoAnchors(relationship.position, relationship.targetPosition);

                return (
                    <g key={index}>
                        <path
                            {...props}
                            name="display-line"
                            strokeWidth={2}
                            stroke={
                                isInvalidRelationship
                                    ? "#FF5C60"
                                    : autoSuggestRelationshipFound && !added
                                    ? "#000"
                                    : isHighlight(relationship)
                            }
                        />
                        <path {...props} {...clickableProps} name="clickable-area" />

                        {relationship.joinType == RELATIONSHIP_LEFT_JOIN && (
                            <OuterJoinIcons
                                centerPos={centerPos}
                                color={isInvalidRelationship ? "#FF5C60" : isHighlight(relationship)}
                                {...clickableProps}
                            />
                        )}

                        <RelationshipErrors
                            relationship={relationship}
                            modelErrorBoxRef={modelErrorBoxRef}
                            tables={tables}
                            centerPos={{
                                x: centerPos.x,
                                y: centerPos.y + (relationship.joinType == RELATIONSHIP_LEFT_JOIN ? 25 : 0),
                            }}
                        />
                    </g>
                );
            };

            return (
                <>
                    {relationshipsTable.map((relationship, index) => drawLine({relationship, index}))}

                    {anchors.map((anchor, index) => {
                        const relationshipMatch = (relationshipTablesState || []).find((r) =>
                            r.anchors.find((a) => a == `${anchor.id}${SPLIT_CHAR}${anchor.table.id}`)
                        );

                        const {relationships: errorRelationships} = JSON.parse(routing.params.invalidRelationships || "{}");

                        const isInvalidRelationship =
                            errorRelationships &&
                            anchor.relationships.find((r) => errorRelationships.find((_r) => errorRelationships.includes(r.id)));

                        const useCircle = relationshipMatch?.displayColumn && relationshipMatch?.suggestion && !relationshipMatch?.added;
                        const isHidden = relationshipMatch && !relationshipMatch?.displayColumn;

                        if (relationshipMatch && useCircle) {
                            return (
                                <circle
                                    cx={anchor.position.x}
                                    cy={anchor.position.y}
                                    key={index}
                                    stroke="black"
                                    strokeWidth={2}
                                    fill="white"
                                    r={3}
                                    style={{
                                        opacity: isHidden ? 0 : 1,
                                        transition: "opacity .3s",
                                    }}
                                />
                            );
                        }

                        return (
                            <line
                                id={`anchor_${anchor.id}_${anchor.table.id}`}
                                x1={anchor.position.x}
                                y1={anchor.position.y}
                                x2={anchor.circlePosition.x}
                                y2={anchor.circlePosition.y}
                                key={`anchor_${anchor.id}_${anchor.table.id}`}
                                strokeWidth={6}
                                stroke={
                                    isInvalidRelationship
                                        ? "#FF5C60"
                                        : isHighlightAnchor(anchor) || (relationshipMatch && !relationshipMatch.added)
                                        ? "#546B81"
                                        : "#d4dadf"
                                }
                                strokeLinecap="round"
                                style={{
                                    opacity: isHidden ? 0 : 1,
                                    transition: "opacity .3s",
                                }}
                            />
                        );
                    })}

                    <filter id="text-shadow">
                        <feDropShadow dx="0" dy="3" stdDeviation="6" floodColor="#000000" floodOpacity="0.16" />
                    </filter>
                </>
            );
        }
    );
};
