import "./render-field-control.scss";

import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {CollectionErrorBadgeWithTooltip} from "@common/ui-components/live/live-dashboard/common/collection-error-badge/collection-error-badge-with-tooltip";
import * as React from "react";
import {rFieldTypeIcon} from "../../../../../../../../../common/field-type-icon/get-field-type-icon";
import {FixedPopupMenu} from "../../../../../../../../../common/fixed-popup-menu/fixed-popup-menu";
import {MoveIconVertical} from "../../../../../../../../../common/icons/move-icon-svg";
import {TruncatingTooltip} from "../../../../../../../../../common/truncating-tooltip/truncating-tooltip";
import {FieldItem} from "../../../../../../../../edit/common/field-item/field-item";
import {dateAggregations} from "../configure-field/configuration/date/date-aggregations";

export const FieldControl = ({
    title,
    field,
    constantValue,
    getRef,
    getFieldInfo,
    provided,
    onConfigure,
    onChangeField,
    overrideDisplayName,
    onRemove,
    onEdit,
    dragging,
    onRename,
    hoveringGroup,
    hideAggregationType = false,
    hideConfiguration = false,
    hasTileError,
}) =>
    cs(
        consumeContext("collection"),
        consumeContext("sharedTile"),
        [
            "fieldCM",
            (_, next) =>
                FixedPopupMenu({
                    getCommands: () =>
                        [
                            onRename && {
                                label: "Rename",
                                onClick: onRename,
                            },
                            onEdit && {
                                label: "Edit",
                                onClick: onEdit,
                            },
                            onConfigure &&
                                !hideConfiguration && {
                                    label: "Configure",
                                    onClick: onConfigure,
                                },
                            onChangeField && {
                                label: constantValue != null ? "Change Value" : "Change Field",
                                onClick: onChangeField,
                            },
                            onRemove && {
                                className: "text-danger",
                                label: "Remove",
                                onClick: onRemove,
                            },
                        ].filter((v) => v),
                    next,
                }),
        ],
        [
            "truncatingTooltip",
            (_, next) =>
                !dragging
                    ? TruncatingTooltip({
                          delay: 500,
                          forceUpdate: true,
                          className: "field-control-tooltip",
                          next,
                      })
                    : next(),
        ],
        ({fieldCM, truncatingTooltip, collection, sharedTile}) => {
            const fieldInfo = getFieldInfo?.(field);
            const hasError = fieldInfo?.isTableDeleted || fieldInfo?.isColumnDeleted;

            const tileModelErrors = (sharedTile?.value ?? collection.value)?.tileModelErrors;
            const errorInfo = hasTileError ? hasTileError : tileModelErrors.find((e) => e.modelColumnID == fieldInfo?.modelColumnID);

            return (
                <FieldItem
                    fieldID={field?.id}
                    dragging={dragging}
                    hoveringGroup={hoveringGroup}
                    provided={provided}
                    className={{
                        draggable: true,
                        "has-data-source": !!getFieldInfo,
                        "field-item-view": true,
                        "has-error": hasError || hasTileError,
                    }}
                    getRef={getRef}
                >
                    {provided && <div className="dragging-anchor">{MoveIconVertical({})}</div>}

                    {title && (
                        <div
                            className="text"
                            {...{
                                ref: truncatingTooltip?.ref,
                                ...truncatingTooltip?.tooltip(title),
                            }}
                        >
                            <div className="title">{title}</div>
                        </div>
                    )}

                    {constantValue != null && (
                        <div className="text">
                            {rFieldTypeIcon(!isNaN(constantValue) ? "Int" : "Text")}
                            <div className="field-info">
                                <div className="title">
                                    {constantValue}
                                    <br />
                                    <span className="subtitle">Constant Value</span>
                                </div>
                            </div>
                        </div>
                    )}

                    {fieldInfo &&
                        (() => {
                            return (
                                <div className="text" key={`${fieldInfo.modelTableName}-${fieldInfo.dataSourceName}`}>
                                    {rFieldTypeIcon(fieldInfo.dataType)}
                                    <div
                                        className="field-info"
                                        {...truncatingTooltip?.tooltip(
                                            <>
                                                <b>Field:</b> {fieldInfo.visualNameShort} {!hideAggregationType && getAggType(field, fieldInfo)}
                                                <br />
                                                <b>Table Name:</b> {fieldInfo.modelTableName}
                                                <br />
                                                {fieldInfo.modelTableType == "DataSourceModelTable" ? (
                                                    <>
                                                        <b>Data Source:</b> {fieldInfo.dataSourceName}
                                                    </>
                                                ) : (
                                                    <b>Data View</b>
                                                )}
                                            </>
                                        )}
                                    >
                                        <div className="title" ref={truncatingTooltip?.ref}>
                                            {overrideDisplayName || fieldInfo.visualNameShort} {!hideAggregationType && getAggType(field, fieldInfo)}
                                            <br />
                                            {fieldInfo.modelTableName &&
                                                (fieldInfo.modelTableType == "DataSourceModelTable" ? (
                                                    <span className="subtitle">
                                                        {fieldInfo.dataSourceName} > {fieldInfo.modelTableName}
                                                    </span>
                                                ) : (
                                                    <span className="subtitle">Data View > {fieldInfo.modelTableName}</span>
                                                ))}
                                        </div>
                                    </div>
                                </div>
                            );
                        })()}

                    {(hasError || hasTileError) && <CollectionErrorBadgeWithTooltip className="alert-icon" error={errorInfo} />}

                    {fieldCM.render()}
                </FieldItem>
            );
        }
    );

export const getAggType = (field, fieldInfo) => {
    if (fieldInfo?.$type == "AggregatedMeasureModelColumn") {
        return null;
    }

    if (field.$type === "MeasureTileField" && field.aggregationFunc) {
        return `(${field.aggregationFunc})`;
    }

    if (field.dateProperties) {
        var label = dateAggregations.find((d) => d.value == field.dateProperties.aggregation)?.label;
        return label ? `(${label})` : "";
    }

    if (field.aggregationFunc) {
        return `(${field.aggregationFunc})`;
    }

    return null;
};
