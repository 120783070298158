const {lineDataLabelPositionMap, getDataLabelsStyle} = require("../../factory/data-labels");
const {verticalBarDataLabelPositionMap} = require("../../factory/data-labels");
// const {initTooltipConnectorHelper} = require("../../factory/tooltip/connector-helpers/tooltip-connector-helper");
const {getThemeBorderRadiusOptions} = require("../../bar-series-processors/get-border-radius-options");
const {DATA_BOOST_THRESHOLD, DATA_TURBO_THRESHOLD} = require("../../common/axis-chart-boost-turbo-constants");

const getPlotOptions = ({tile, theme, formatters, isCompare, onClickPoint}) => {
    const lineDataLabels = tile.style.yAxisLineDataLabels;
    const barDataLabels = tile.style.yAxisBarDataLabels;

    // const tooltipConnectorHelper = theme.dataVisualization.toolTipsAndLegends.indicatorLineShown && initTooltipConnectorHelper({tooltipStyle: theme.dataVisualization.toolTipsAndLegends});

    return {
        series: {
            cursor: onClickPoint ? "pointer" : "default",
            boostThreshold: DATA_BOOST_THRESHOLD,
            turboThreshold: DATA_TURBO_THRESHOLD,
            getExtremesFromAll: true,
            point: {
                events: {
                    // ...(tooltipConnectorHelper && {
                    //     mouseOver: function() {
                    //         setTimeout(() => tooltipConnectorHelper.render({chart: this.series.chart}), 0)
                    //     },
                    //     mouseOut: function() {
                    //         setTimeout(() => tooltipConnectorHelper.remove(), 0)
                    //     }
                    // }),
                    click: function ({x, y, point}) {
                        onClickPoint?.({x, y, point, chart: this.series.chart});
                    },
                },
            },
            dataLabels: {
                formatter: function () {
                    // customStack and indexCustomStack is provided during grouping series
                    const {customStack, indexInCustomStack} = this.series.userOptions;
                    if (this.y === 0) {
                        return;
                    }
                    return formatters.measurementFormatters[customStack][indexInCustomStack](this.y);
                },
            },
        },
        line: {
            dataLabels: {
                ...getDataLabelsStyle({
                    dataLabelsConfig: lineDataLabels,
                    theme,
                }),
                ...lineDataLabelPositionMap()[lineDataLabels.position],
            },
        },
        spline: {
            dataLabels: {
                ...getDataLabelsStyle({
                    dataLabelsConfig: lineDataLabels,
                    theme,
                }),
                ...lineDataLabelPositionMap()[lineDataLabels.position],
            },
        },
        column: {
            ...(theme.dataVisualization.axisCharts.barChartCornerRadius > 0 && getThemeBorderRadiusOptions(theme.dataVisualization.axisCharts)),
            dataLabels: {
                ...getDataLabelsStyle({dataLabelsConfig: barDataLabels, theme}),
                inside: barDataLabels.position?.startsWith("Inside"),
                ...verticalBarDataLabelPositionMap(tile.style.yAxisBarDisplayType)[barDataLabels.position],
            },
            ...(isCompare &&
                ["Stacked", "HundredPercent"].includes(tile.style.yAxisBarDisplayType) && {
                    grouping: false, // to stick primary column and compare column together
                }),
            shadow: false,
            borderWidth: 0,
            ...(() => {
                const displayType = tile.style.yAxisBarDisplayType;

                if (!displayType || displayType === "Classic") {
                    return {};
                }

                return displayType === "Stacked"
                    ? {
                          stacking: "normal",
                      }
                    : {
                          stacking: "percent",
                      };
            })(),
        },
    };
};
exports.getPlotOptions = getPlotOptions;
