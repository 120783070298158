import "./pie-chart.scss";

import * as React from "react";

import {cs} from "../../../react/chain-services";
import {consumeContext} from "../../../react/context";
import {IgnoreUpdate} from "../../../react/ignore-update";
import {keepOnly} from "../../../utils/objects";
import {RenderChart} from "../render-chart/render-chart";
import {isComparing} from "../factory/check-compare";
import {isDataValid, loadTileData} from "../common/load-tile-data";
import {MenuOptions} from "../common/menu-options/menu-options";
import {NoDataScreen} from "../common/no-data-screen/no-data-screen";
import {ActionMenu} from "../common/action-menu/action-menu";
import {ChartInnerLayout} from "../chart-layout/chart-inner-layout";
import {decorateData2} from "./decorate-data-2";
import {getValueFormatters} from "./get-value-formatters";
import {getPieChartOptions} from "./chart-options/get-pie-chart-options";
import {loadTileFields} from "../get-field-color";
import {RemoveTileControlFilter} from "../common/control-filter/remove-tile-control-filter";
import {CurrencyConversionWarning, getCurrencyConversion} from "../common/currency-conversion-warning/currency-conversion-warning";

export const PieChart = ({tile, tileFilters, theme, loadData, downloadData, size, chartRef, defaultData, overrideTile, disabledTileActions = false}) =>
    cs(
        consumeContext("tileActionControlFilter"),
        [
            "chartData",
            ({tileActionControlFilter}, next) =>
                defaultData
                    ? next(defaultData)
                    : loadTileData({
                          next,
                          tileFilters,
                          loadData,
                          size,
                          tile,
                          theme,
                          tileKey: JSON.stringify([keepOnly(tile, ["groupField", "valueField", "filters", "limit", "sort", "categorySort"])]),
                          disabled: tileActionControlFilter?.value?.tile?.id == tile.id,
                          removeJSCursor: true,
                      }),
        ],
        consumeContext("selectedTimezone"),
        [
            "rawData",
            ({chartData}, next) =>
                next({
                    ...chartData,
                    series: chartData.series.filter((s) => (tile.style?.showCompare === false ? !s.isCompare : true)),
                }),
        ],

        ({rawData}, next) => (!isDataValid(rawData) ? NoDataScreen({size, theme}) : next()),

        [
            "formatters",
            ({selectedTimezone}, next) =>
                next(
                    getValueFormatters({
                        valueField: tile.valueField,
                        groupField: tile.groupField,
                        timezone: selectedTimezone?.value,
                        tile,
                    })
                ),
        ],

        [
            "data",
            ({rawData, formatters}, next) =>
                next(
                    decorateData2({
                        series: rawData.series,
                        tile,
                        theme,
                        size,
                        formatters,
                    })
                ),
        ],

        [
            "tileFields",
            ({}, next) =>
                loadTileFields({
                    next,
                    configs: {
                        tile,
                        measureSingleAttrs: ["valueField"],
                        groupFieldAttr: "groupField",
                        checkUnique: false,
                    },
                }),
        ],

        [
            "controls",
            ({tileActionControlFilter, rawData, tileFields, data}, next) => {
                const hasMenuOptions = tile.style.showDownloadData || tile.style.showDownloadImage;
                const hasRemoveTileControlFilter = tileActionControlFilter?.value?.action?.value && tileActionControlFilter?.value?.tile.id == tile.id;
                const hasCurrencyConversionFailures = rawData.series.filter((s) => s.currencyConversionFailures).length > 0;

                // if (!hasMenuOptions && !hasRemoveTileControlFilter) {
                //     return next(null);
                // }

                return next(
                    <div className="controls">
                        {CurrencyConversionWarning({
                            theme,
                            currencyConversion: getCurrencyConversion({
                                $type: rawData.$type,
                                data: rawData,
                                tileFields,
                            }),
                        })}

                        {hasRemoveTileControlFilter &&
                            RemoveTileControlFilter({
                                tile,
                                hideBorder: !hasMenuOptions,
                            })}

                        {hasMenuOptions &&
                            MenuOptions({
                                chartRef,
                                theme,
                                tile,
                                downloadData,
                                tileFilters,
                            })}
                    </div>
                );
            },
        ],

        ({rawData, controls}, next) =>
            ChartInnerLayout({
                size,
                tile,
                theme,
                next,
                noData: !isDataValid(rawData),
                hasControls: !!controls,
            }),

        [
            "actionMenu",
            ({formatters}, next) =>
                ActionMenu({
                    tile,
                    overrideTile,
                    dimensionFormatter: formatters.dimensionFormatter,
                    disabledTileActions,
                    next,
                }),
        ],

        [
            "onClickPoint",
            ({actionMenu, tileFields}, next) => {
                if (!actionMenu.hasActions()) {
                    return next();
                }
                return next(({point, ...other}) => {
                    let fieldToValue = tileFields.reduce((obj, field) => {
                        obj[field.id] = field.$type === "CategoryTileField" ? point.name : point.y;
                        return obj;
                    }, {});

                    const tileActionData = point.series?.userOptions?.tileActionData;

                    tileActionData?.columns.forEach((c, index) => {
                        fieldToValue[c.tileFieldID] = tileActionData.data[index];
                    });

                    actionMenu.show({
                        point,
                        ...other,
                        fieldToValue,
                    });
                });
            },
        ],

        ({selectedTimezone}, next) =>
            IgnoreUpdate({
                next,
                props: {timezoneId: selectedTimezone?.value},
                when: ({timezoneId}) => timezoneId != selectedTimezone?.value,
            }),

        ({rawData, data, formatters, actionMenu, controls, onClickPoint, tileActionControlFilter}) => {
            const isCompare = isComparing(rawData, tile);

            const options = {
                ...getPieChartOptions({
                    tile,
                    theme,
                    size,
                    isCompare,
                    rawData,
                    formatters,
                    onClickPoint,
                }),
                series: data,
            };

            return (
                <div
                    className="pie-chart-43y"
                    key={getRefreshKey({
                        tile,
                        theme,
                        tileFiltersValue: tileFilters.getValue(),
                    })}
                >
                    <div className="chart">
                        <RenderChart
                            {...{
                                chartRef,
                                options,
                                size,
                                theme,
                                tile,
                                formatters,
                            }}
                        />
                    </div>
                    {controls}
                </div>
            );
        }
    );

const getRefreshKey = ({tile, theme, tileFiltersValue}) =>
    JSON.stringify([
        keepOnly(tile, ["id", "filters", "groupField", "valueField", "limit", "sort", "categorySort"]),
        keepOnly(tile.style, [
            "showCompare",
            "holeDiameter",
            "centerLabel",
            "centerLabelFontSize",
            "centerDisplay",
            // "dataTextCaseOptions",
            "dataLabels",
            "legendStyle",
            "dataColorPalette",
            "autoRound",
            "calloutLineShown",
            "maxSlices",
        ]),
        theme.dataVisualization.toolTipsAndLegends,
        tileFiltersValue,
    ]);
