import * as React from "react";
import {UseState} from "@common/react/use-state";
import {cs} from "@common/react/chain-services";
import {SelectField} from "../select-field/select-field";
import "./measure-field-groups.scss";
import {FieldTree} from "./field-tree/field-tree";
import {ConfigureField} from "../configure-field/configure-field";
import {scope} from "@common/react/scope";
import {removeField} from "./remove-field";
import {cx} from "emotion";
import {consumeContext} from "@common/react/context";
import {FieldSection} from "../field-section/field-section";
import {FieldInfoProvider} from "../../../../tile-tab/chart-types/common/field-info-provider/field-info-provider";
import {getFieldType} from "@common/ui-components/charts/common/get-field-type";
import {Button} from "../../../../../../../../../../../../common/form/buttons/button/button";
import {dateAggregates} from "../configure-field/configuration/date/date-field";
import {getTextAggregationFuncs} from "../../../../../../../../../common/aggregation-funcs/text-aggregation-funcs";
import {getNumberAggregationFuncs} from "../../../../../../../../../common/aggregation-funcs/number-aggregation-funcs";
import {flatten1} from "@common/utils/collections";
import {getBoolAggregationFuncs} from "../../../../../../../../../common/aggregation-funcs/bool-aggregation-funcs";

export const MeasureFieldGroups = ({
    label,
    fieldGroups,
    domRef,
    hasError,
    unavailable,
    showUnavailableMessage,
    onUpdateTile,
    next,
    info,
    direction,
    notAllowAndGroup,
    notAllowDnd,
}) =>
    cs(
        consumeContext("getFieldInfo"),
        consumeContext("tileFields"),
        [
            "selecting",
            (_, next) =>
                UseState({
                    // initValue: {type, handler: () => {}, oldField?},
                    next,
                }),
        ],

        ["configuring", (_, next) => UseState({next})],

        ({selecting, configuring, getFieldInfo, tileFields}) =>
            next({
                render: () =>
                    FieldSection({
                        header: FieldInfoProvider({
                            field: <div className="text">{label}</div>,
                            info,
                            direction,
                        }),
                        content: (
                            <div
                                className={cx("measure-field-groups-4ew", {
                                    hasError,
                                })}
                                ref={domRef}
                            >
                                {fieldGroups?.value?.length > 0 ? (
                                    <FieldTree
                                        {...{
                                            onUpdateTile,
                                            fieldGroups,
                                            addField: (fields) =>
                                                new Promise((resolve) => {
                                                    selecting.onChange({
                                                        type: "add",
                                                        handler: resolve,
                                                    });
                                                }),
                                            // addField: () => ({name: "" + Math.floor(Math.random()*100)}), // Dev
                                            changeField: (oldField) =>
                                                new Promise((resolve) => {
                                                    selecting.onChange({
                                                        type: "change",
                                                        handler: resolve,
                                                        oldField,
                                                    });
                                                }),
                                            configureField: ({groupIndex, fieldIndex}) =>
                                                configuring.onChange({
                                                    groupIndex,
                                                    fieldIndex,
                                                }),
                                            getFieldInfo,
                                            unavailable,
                                            showUnavailableMessage,
                                            notAllowAndGroup,
                                            notAllowDnd,
                                        }}
                                    />
                                ) : (
                                    <Button
                                        size="tiny"
                                        btnType="secondary"
                                        onClick={() =>
                                            selecting.onChange({
                                                type: "add",
                                                handler: (field) =>
                                                    fieldGroups.onChange([
                                                        {
                                                            measureFields: [field],
                                                        },
                                                    ]),
                                            })
                                        }
                                        // onClick={() => fieldTree.onChange({type: "field", field: {name: "" + Math.floor(Math.random()*100)}})} // Dev
                                    >
                                        Add {label} Field
                                    </Button>
                                )}
                            </div>
                        ),
                    }),
                override: (() => {
                    const selectedFields = flatten1(fieldGroups.value, (group) => group.measureFields);

                    const allowAggregations = (type, fieldType, table, column, currentSelected) => {
                        const selectedAgg = selectedFields
                            .filter((f) => f.modelTableID === table.id && f.modelColumnID === column.id)
                            .map((column) => {
                                return column.aggregationFunc;
                            });

                        let list = getTextAggregationFuncs({
                            notAllowNone: true,
                        });

                        if (type == "number") {
                            list = getNumberAggregationFuncs({
                                notAllowNone: true,
                            });
                        } else if (type === "bool") {
                            list = getBoolAggregationFuncs({
                                notAllowNone: true,
                            });
                        }

                        return {
                            list: list.filter((a) => selectedAgg.indexOf(a.value) == -1),
                            currentSelected: list.find((l) => l.value == currentSelected),
                        };
                    };

                    if (selecting.value) {
                        const handleAddField = (field) => {
                            selecting.value.handler({
                                ...field,
                                $type: "MeasureTileField",
                            });
                            selecting.onChange(null);
                        };

                        const handleChangeField = (field1) => {
                            selecting.value.handler({
                                ...selecting.value.oldField,
                                ...field1,
                            });
                            selecting.onChange(null);
                        };

                        return (
                            <SelectField
                                {...{
                                    label: `Select ${label} Field`,
                                    onCancel: () => selecting.onChange(null),
                                    actionType: selecting.value.type,
                                    onSelect: selecting.value.type === "add" ? handleAddField : handleChangeField,
                                    ...(selecting.value.type === "change" && {
                                        allowedFieldType: getFieldType(selecting.value.oldField),
                                    }),
                                    $type: "MeasureTileField",
                                    notAllowedFields: tileFields.allFields,
                                    allowSameFieldsInWell: true,
                                    allowAggregations: allowAggregations,
                                }}
                            />
                        );
                    } else if (configuring.value) {
                        const {groupIndex, fieldIndex} = configuring.value;
                        return (
                            <ConfigureField
                                {...{
                                    field: scope(fieldGroups, [groupIndex, "measureFields", fieldIndex]),
                                    getFieldInfo,
                                    onRemove: () =>
                                        onUpdateTile
                                            ? onUpdateTile(null, {
                                                  groupIndex,
                                                  fieldIndex,
                                              })
                                            : removeField({
                                                  groupIndex,
                                                  fieldIndex,
                                                  fieldGroups,
                                              }),
                                    onCancel: () => configuring.onChange(null),
                                    listFieldsAggregation: flatten1(Object.values(fieldGroups.value).map((v) => v.measureFields)),
                                    customListAgg: () => {
                                        const field = scope(fieldGroups, [groupIndex, "measureFields", fieldIndex]).value;
                                        const fieldInfo = getFieldInfo(field);
                                        const allow = allowAggregations(
                                            fieldInfo.type,
                                            "",
                                            {id: field.modelTableID},
                                            {id: field.modelColumnID},
                                            field.dateProperties?.aggregation || field.aggregationFunc
                                        );
                                        return [...allow.list, allow.currentSelected];
                                    },
                                }}
                            />
                        );
                    }
                })(),
            })
    );
