const {cGetFontSize} = require("../../common/style-map/font-size");

const getTooltipBoxStyle = ({theme}) => {
    const getFontSize = cGetFontSize(theme?.dataVisualization.toolTipsAndLegends.fontSize || theme.general.canvas.fontSize, theme);
    return [
        `font-size: ${getFontSize({})}px`,
        "box-shadow: 0 3px 6px rgba(0,0,0,0.16)",
        // "border: 1px solid #707070",
        `border-radius: ${theme.general.tile.styles.tileCornerRadius || 0}px`,
        // "padding: 6px 5px 8px 5px",
        // "padding: 10px 5px",
        "background: #FFFFFF",
        "width: max-content",
    ].join("; ");
};
exports.getTooltipBoxStyle = getTooltipBoxStyle;
