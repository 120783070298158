import {cs} from "@common/react/chain-services";
import {keyed} from "@common/react/keyed";
import {resetColorIndexes} from "@common/ui-components/charts/get-field-color";
import * as React from "react";
import {Expandable} from "../../../../../../common/expandable/expandable";
import "./tile-tab.scss";
// import {VisualTypes} from "./visual-types/visual-types";
import {consumeContext} from "@common/react/context";
import {UseState} from "@common/react/use-state";
import {Button} from "../../../../../../../../../common/form/buttons/button/button";
import {ConfirmDialog} from "../../../../../../common/confirm-dialog/confirm-dialog";
import {FixedPopupMenu} from "../../../../../../common/fixed-popup-menu/fixed-popup-menu";
import {cGetTileMeasureField} from "../common/get-tile-measure-field";
import {disabledControlDataTileTypes} from "../tabs";
import {ChartTypes} from "./chart-types/chart-types";
import {ScatterMarkerColorsConfig} from "./chart-types/scatter-plot/panels/marker-style/marker-colors-config";
import {ConditionalFormattingConfig} from "./chart-types/table/panels/conditional-formatting/conditional-formatting-config";
import {ColorsTileConfig} from "./colors/colors-tile-config";
import {MapColorsConfig} from "./colors/map-colors-config";
import {KpiColorsConfig} from "./kpi-colors/kpi-colors-config";
import {ReferenceLinesPanel} from "./reference-lines-panel/reference-lines-panel";
import {TableFieldConfigOverride} from "./table-field-config-override/table-field-config-override";

// TODO when changing visual types, keep all configs that still apply;
// TODO remember all configured configs for surfing visual types
export const TileTab = ({tile, isSubTile, tileFuncs, form, size, parentTile, tileAction, next, leftPanelWidth}) =>
    cs(
        consumeContext("collection"),
        consumeContext("theme"),
        [
            "tableFieldConfigOverride",
            (_, next) =>
                TableFieldConfigOverride({
                    tile,
                    next,
                }),
        ],
        [
            "colorsTileConfig",
            ({theme}, next) =>
                ColorsTileConfig({
                    tile,
                    theme,
                    next,
                }),
        ],
        [
            "kpiColorsTileConfig",
            ({theme}, next) =>
                KpiColorsConfig({
                    tile,
                    theme,
                    next,
                }),
        ],
        [
            "mapTileColorsConfig",
            ({theme}, next) =>
                MapColorsConfig({
                    tile,
                    theme,
                    next,
                }),
        ],
        [
            "scatterMarkerColorsConfig",
            ({theme}, next) =>
                ScatterMarkerColorsConfig({
                    tile,
                    theme,
                    next,
                }),
        ],
        ["conditionalFormattingConfig", (_, next) => ConditionalFormattingConfig({tile, form, leftPanelWidth, next})],
        ["referenceLinesPanel", (_, next) => ReferenceLinesPanel({tile, form, next})],
        ({
            theme,
            tableFieldConfigOverride,
            colorsTileConfig,
            collection,
            kpiColorsTileConfig,
            mapTileColorsConfig,
            scatterMarkerColorsConfig,
            referenceLinesPanel,
            conditionalFormattingConfig,
        }) =>
            next({
                render: () =>
                    cs(
                        ["isCompare", (_, next) => UseState({next})],
                        [
                            "panels",
                            (_, next) =>
                                ChartTypes({
                                    tile,
                                    form,
                                    size,
                                    theme,
                                    tableFieldConfigOverride,
                                    colorsTileConfig,
                                    kpiColorsTileConfig,
                                    mapTileColorsConfig,
                                    scatterMarkerColorsConfig,
                                    referenceLinesPanel,
                                    parentTile,
                                    tileAction,
                                    next,
                                    conditionalFormattingConfig,
                                }),
                        ],
                        [
                            "confirmDialog",
                            (_, next) =>
                                ConfirmDialog({
                                    next,
                                    submitText: "Reset",
                                    title: " Reset to Theme Defaults",
                                }),
                        ],
                        [
                            "getField",
                            ({}, next) =>
                                cs(consumeContext("getFieldInfo"), ({getFieldInfo}) => {
                                    const getTileMeasureField = cGetTileMeasureField(tile.value);
                                    return next((id) => {
                                        const mf = getTileMeasureField(id);
                                        return getFieldInfo(mf);
                                    });
                                }),
                        ],

                        ({panels, getField, confirmDialog}) => {
                            return (
                                <div className="tile-tab-9xv">
                                    {panels
                                        .filter(({label, getLabel}) =>
                                            collection?.value?.$type == "SparkCollection" ? !["Tile Title", "Chart Title"].includes(label || getLabel(getField)) : true
                                        )
                                        .map(({initExpand, label, getLabel, render, control, iconTooltip, tooltipBox, content = null}, i) =>
                                            cs(
                                                keyed(i),
                                                ({}) =>
                                                    content ?? (
                                                        <Expandable
                                                            {...{
                                                                initExpand: !!initExpand,
                                                                label: label || getLabel(getField),
                                                                render,
                                                                control,
                                                                iconTooltip,
                                                                tooltipBox,
                                                            }}
                                                        />
                                                    )
                                            )
                                        )}

                                    {isSubTile &&
                                        cs(
                                            [
                                                "popupMenu",
                                                (_, next) =>
                                                    FixedPopupMenu({
                                                        next,
                                                        getCommands: () => [
                                                            {
                                                                label: "Delete Tile",
                                                                className: "delete-action",
                                                                onClick: () => (parentTile ? tile.onChange(null) : tileFuncs?.removeTile?.(tile.value)),
                                                            },
                                                        ],
                                                    }),
                                            ],
                                            ({popupMenu}) =>
                                                !disabledControlDataTileTypes.includes(tile.value.$type) && (
                                                    <div className="actions">
                                                        <div className="more-button">
                                                            {popupMenu.render({
                                                                content: ({onMouseDown}) => (
                                                                    <Button
                                                                        iconRight={<i className="far fa-chevron-down" />}
                                                                        btnType="border"
                                                                        size="medium"
                                                                        onMouseDown={onMouseDown}
                                                                    >
                                                                        More
                                                                    </Button>
                                                                ),
                                                            })}
                                                        </div>
                                                    </div>
                                                )
                                        )}
                                </div>
                            );
                        }
                    ),
                override:
                    tableFieldConfigOverride.override ||
                    colorsTileConfig?.override ||
                    kpiColorsTileConfig.override ||
                    mapTileColorsConfig.override ||
                    scatterMarkerColorsConfig.override ||
                    referenceLinesPanel.override ||
                    conditionalFormattingConfig.override,
                customWindowWidth: conditionalFormattingConfig.override ?? 864,
            })
    );
