const Highcharts = require("highcharts/highstock");

const {sort} = require("../../../utils/collections");
const {unique} = require("../../../utils/collections");
const {arrMapToO} = require("../../../utils/objects");
const {chain} = require("../../../utils/fs");
const {prepareColor} = require("../prepare-color");
const {generateSeriesId} = require("../bar-series-processors/generate-series-id");

const getAreasplineColorOption = (color, areaLineChartFill) => {
    const avaiColorOptions = {
        Solid: {
            color,
            _baseColor: color,
            fillOpacity: 1,
        },
        Gradient: {
            fillOpacity: 0.5,
            _baseColor: color,
            lineColor: color,
            color: {
                linearGradient: {x1: 0, x2: 0, y1: 0, y2: 1},
                stops: [
                    [0, new Highcharts.Color(color).setOpacity(0.5).get("rgba")], // start
                    // [0.5, color], // middle
                    [1, new Highcharts.Color(color).setOpacity(0).get("rgba")], // end
                ],
            },
        },
        Semitransparent: {
            color,
            _baseColor: color,
            fillOpacity: 0.5,
        },
    };

    if (!areaLineChartFill) {
        return avaiColorOptions.Semitransparent;
    }

    return avaiColorOptions[areaLineChartFill];
};

const groupSeriesByStackLine = ({series, usingColorGroupField, shiftColor, tile, theme, tooltipOptions, measurementFormatters, yAxis, getYAxis, tileFields, zIndex}) => {
    return groupSeriesByStackLine1({
        tile,
        theme,
        series,
        tooltipOptions,
        measurementFormatters,
        shiftColor,
        yAxis,
        getYAxis,
        tileFields,
        usingColorGroupField,
        zIndex,
        // isXAxisDate: true,
    });
};

const groupSeriesByStackLine1 = ({tile, usingColorGroupField, theme, series, tileFields, zIndex, yAxis, getYAxis, measurementFormatters}) => {
    const isSparkLineKPITile = tile.$type == "SparkLineKPITile";
    const {dataColorPalettes, axisCharts} = theme.dataVisualization;
    const stackKeys = unique(series.map((s) => s.stack));
    const stackKeyTitleMap = arrMapToO(stackKeys, (sk) => unique(series.filter((s) => s.stack === sk).map((s) => s.measureAxisTitle)));
    const hasGroupField = tile.groupField != null;
    const {colorScale, getDisplayColor} = prepareColor({
        tile,
        tileFields,
        dataColorPalettes,
        usingColorGroupField,
    });

    const getSeries = (series) => {
        const enhanceSeries = (series1, isCompare) => {
            colorScale?.setRange(0, series1.length - 1);

            const dataLabelsStyle = tile.style.dataLabels || tile.style.yAxisLineDataLabels;
            const getLineThickness = (stack) => tile.style.thickness || tile.style.yAxisLine?.[stack]?.thickness;
            const getLineMarker = (stack) => tile.style.marker || tile.style.yAxisLine?.[stack]?.marker;
            const getDisplayType = (stack) => tile.style.lineChartDisplayType || tile.style.displayType || tile.style.yAxisLine?.[stack]?.displayType;

            const groupedSeries = series1.map((s, i) => {
                const lineThickness = getLineThickness(s.stack)?.toLowerCase() || "thin";
                const lineWidth = lineWidths[lineThickness];
                const lineMarker = !isSparkLineKPITile ? getLineMarker(s.stack) : null;
                const lineMarkerSize = lineMarker ? markerSizes[lineThickness] : null;
                const lineMarkerRadius = lineMarker ? getMarkerRadiusFromWidth(lineMarkerSize.width) : null;
                const displayType = getDisplayType(s.stack);
                const type = (() => {
                    if (!displayType || displayType === "Line") {
                        return "line";
                    }
                    if (displayType === "Spline") {
                        return "spline";
                    }
                    if (displayType.startsWith("Area")) {
                        return "area";
                    }
                    if (displayType.startsWith("SplineArea")) {
                        return "areaspline";
                    }
                })();

                const {color: displayColor} = getDisplayColor({
                    series: s,
                    index: i,
                });

                const chartColorOption = type === "areaspline" ? getAreasplineColorOption(displayColor, axisCharts.areaLineChartFill) : {color: displayColor};
                const id = generateSeriesId(s);
                const indexInCustomStack = hasGroupField ? 0 : i;

                return {
                    ...s,
                    zIndex,
                    type,
                    customStack: s.stack,
                    indexInCustomStack,
                    // link series to respective yAxis
                    ...(tile.$type === "ComboChartTile"
                        ? {yAxis: getYAxis(s)}
                        : tile.style.multipleAxisOption === "IndependentAxes"
                        ? {yAxis: stackKeys.findIndex((sk) => sk === s.stack)}
                        : yAxis && {yAxis}),
                    // ...(isXAxisDate && {data: sort(s.data, (d) => d[0])}),
                    xAxis: !isCompare ? 0 : 1,
                    marker: {
                        enabled: lineMarker ? lineMarker !== "None" : false,
                        ...(lineMarker &&
                            lineMarker !== "None" && {
                                symbol: lineMarker.replace("Hollow", "").toLowerCase(),
                            }),
                        radius: lineMarkerRadius,
                        ...(lineMarker?.startsWith("Hollow") && {
                            fillColor: theme.general.tile.styles.tileBackgroundColorRGB,
                            lineWidth: lineMarkerSize.borderWidth,
                            lineColor: null, // inherit from series
                        }),
                        states: {
                            hover: {
                                enabled: lineMarker ? lineMarker !== "None" : false,
                                radiusPlus: markerRadiusPlus,
                                lineWidthPlus: 0.5,
                            },
                        },
                    },
                    states: {
                        hover: {
                            lineWidthPlus: 0,
                            halo: {
                                size: (lineMarkerRadius + markerRadiusPlus) * 2, // hovered marker's halo radius
                            },
                        },
                    },
                    // data labels formatter in series level does not work anymore, since HC v.10.2.0
                    // ...(dataLabelsStyle.show && {
                    //     dataLabels: {
                    //         enabled: true,
                    //         formatter: function () {
                    //             return measurementFormatters[s.stack][indexInCustomStack](this.y);
                    //         }
                    //     },
                    // }),
                    label: {enabled: false},
                    // color: colors[i + (shiftColor ?? 0)],
                    // color: colors[s.legendIndex],
                    ...chartColorOption,
                    animation: false,

                    ...(!isCompare
                        ? {
                              id,
                              lineWidth: lineWidth.primary,
                              // ...tooltipOptions[s.stack](indexInCustomStack),
                          }
                        : {
                              id: `${id}_compare`,
                              linkedTo: id,
                              opacity: 0.7,
                              dashStyle: "dash",
                              lineWidth: lineWidth.compare,
                              enableMouseTracking: false,
                              // dataLabels: {
                              //     enabled: false,
                              // },
                          }),
                };
            });

            // highcharts set reversedStacks on measurement axis to true by default,
            // so have to sort series by legendIndex in reverse order if stacked
            return tile.style.displayType?.includes("AreaStacked") || tile.style.displayType?.includes("AreaHundredPercent")
                ? sort(groupedSeries, (s) => s.legendIndex * -1)
                : groupedSeries;
        };

        return [
            ...enhanceSeries(
                series.filter((s) => s.isCompare),
                true
            ),
            ...enhanceSeries(series.filter((s) => !s.isCompare)),
        ];
    };

    return arrMapToO(stackKeys, (sk) => ({
        label: stackKeyTitleMap[sk].join(" & "),
        series: chain(
            series.filter((s) => s.stack === sk),
            (_) => getSeries(_)
        ),
    }));
};
exports.groupSeriesByStackLine = groupSeriesByStackLine;

const lineWidths = {
    thin: {
        primary: 1,
        compare: 0.5,
    },
    medium: {
        primary: 2,
        compare: 1,
    },
    thick: {
        primary: 3,
        compare: 2,
    },
};
exports.lineWidths = lineWidths;

const markerSizes = {
    thin: {
        width: 3,
        borderWidth: 1,
    },
    medium: {
        width: 3.5,
        borderWidth: 1.5,
    },
    thick: {
        width: 5,
        borderWidth: 2,
    },
};
exports.markerSizes = markerSizes;

const markerRadiusPlus = 1;
exports.markerRadiusPlus = markerRadiusPlus;

const getMarkerRadiusFromWidth = (width, radiusPlus = 1) => width * 0.5 + radiusPlus;
exports.getMarkerRadiusFromWidth = getMarkerRadiusFromWidth;
