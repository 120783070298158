import React from "react";
import {cs} from "@common/react/chain-services";
import {scope} from "@common/react/scope";
import "./headers.scss";
import {TitleInfo} from "./title-info";
import {KeyValueInput} from "../../../../../../../common/form/key-value-input/key-value-input";

export const Headers = ({authEndpoint, form, disableEditing}) =>
    cs(({}) => {
        const field = form.field("headers");

        return (
            <div className="headers-65a">
                {TitleInfo({title: "Headers", infoName: "headers"})}
                {KeyValueInput({
                    disableEditing,
                    state: scope(authEndpoint, ["headers"]),
                    placeholders: {
                        key: "Authorization",
                        value: "Bearer {{token}}",
                    },
                    hasError: field.error,
                })}
            </div>
        );
    });
