import * as React from "react";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {stateToSelect} from "@common/ui-components/form/state-to-select";
import {getPath} from "@common/utils/arr-path";
import {cs} from "@common/react/chain-services";
import {DebounceNumber} from "../../../../../../../../../../../common/form/debounce-number";
import {scope} from "@common/react/scope";
import {NumberInput} from "../../../../../../../../../../../common/form/number-input/number-input";

export const GridStepTypeSelect = ({state, fieldType}) => (
    <>
        {/*{DropdownSelect({*/}
        {/*    label: "Measurement",*/}
        {/*    list: [*/}
        {/*        {*/}
        {/*            label: "By Count",*/}
        {/*            value: {*/}
        {/*                gridStepType: "Count",*/}
        {/*                gridStepNumeric: 5,*/}
        {/*            },*/}
        {/*            isSelected: (s) => s.gridStepNumeric != null && s.gridStepType === "Count",*/}
        {/*        },*/}
        {/*        {*/}
        {/*            label: "By Value",*/}
        {/*            value: {*/}
        {/*                gridStepType: "Size",*/}
        {/*                gridStepNumeric: 1,*/}
        {/*            },*/}
        {/*            isSelected: (s) => s.gridStepNumeric != null && s.gridStepType === "Size",*/}
        {/*        },*/}
        {/*        {*/}
        {/*            label: "Auto",*/}
        {/*            value: {*/}
        {/*                gridStepType: "Count",*/}
        {/*                gridStepNumeric: null,*/}
        {/*            },*/}
        {/*            isSelected: (s) => s.gridStepNumeric == null,*/}
        {/*        },*/}
        {/*    ],*/}
        {/*    valueToLabel: (v) => v.label,*/}
        {/*    isSelected: (v) => v.isSelected(state.value),*/}
        {/*    onChange: (v) => state.onChange({*/}
        {/*        ...state.value,*/}
        {/*        ...v.value,*/}
        {/*    })*/}
        {/*})}*/}

        {DropdownSelect({
            label: "Measurement",
            list: [
                {
                    label: "By Count",
                    value: "Count",
                },
                {
                    label: "By Value",
                    value: "Size",
                },
                {
                    label: "Auto",
                    value: null,
                },
            ],
            ...stateToSelect(scope(state, ["gridStepType"]), ["value"]),
            valueToLabel: (v) => v.label,
        })}

        {
            getPath(state.value, ["gridStepType"]) != null &&
                cs(
                    [
                        "state1",
                        (_, next) => {
                            const {value, onChange} = scope(state, ["gridStepNumeric"]);
                            return next({
                                value,
                                onChange: (v) => (v > 0 ? onChange(v) : onChange(null)),
                            });
                        },
                    ],
                    [
                        "debounce",
                        ({state1}, next) =>
                            DebounceNumber({
                                state: state1,
                                next,
                            }),
                    ],
                    ({debounce}) =>
                        NumberInput({
                            label: "Value",
                            state: debounce.state,
                            onBlur: debounce.flush,
                        })
                )
            // <div className={cx("dual-line", {"date-field": fieldType === "date"})}>
            //     <div className="col1">
            //         {cs(
            //             ["debounce", (_, next) => DebounceNumber({
            //                 state: scope(state, ["gridStepNumeric"]),
            //                 next,
            //             })],
            //             ({debounce}) => NumberInput({
            //                 label: "Value",
            //                 state: debounce.state,
            //                 onBlur: debounce.flush,
            //             }),
            //         )}
            //     </div>
            //     {fieldType === "date" && (
            //         <div className="col2">
            //             {DropdownSelect({
            //                 label: "Value",
            //                 list: [
            //                     ...dateAggregations,
            //                     ...timeAggregations,
            //                     {
            //                         label: "None",
            //                         value: "None",
            //                     },
            //                 ],
            //                 valueToLabel: (v) => v.label,
            //                 ...stateToSelect(scope(state, ["gridStepDate"]), ["value"]),
            //             })}
            //         </div>
            //     )}
            // </div>
        }
    </>
);
