import {formatMoney, maxBy} from "../../../utils/math-util";
import {sum, unique} from "../../../utils/collections";
import {getFieldType} from "../common/get-field-type";
import {cascadeFind} from "../../../utils/cascade";
import {isEmpty} from "../../../utils/objects";
import {getTextDimension, getTextDimensionFromHtml} from "../single-kpi/font-auto-resizing";
import {getFunnelLegendRoom} from "../funnel/legend/funnel-legend-room";
import {DATA_BOOST_THRESHOLD, SERIES_BOOST_THRESHOLD} from "../common/axis-chart-boost-turbo-constants";
import {getScatterLegendRoom} from "../scatter-plot/legend/scatter-legend-room";
import {getLegendItemName, getLegendItemValue} from "./legend/legend-view/legend-item-view";

export const scrollbarConfig = {
    barBackgroundColor: "#babac0",
    barBorderColor: "#fff",
    barBorderRadius: 6,
    barBorderWidth: 4,
    buttonArrowColor: "none",
    buttonBackgroundColor: "none",
    buttonBorderColor: "none",
    buttonBorderRadius: 0,
    buttonBorderWidth: 1,
    height: 12,
    rifleColor: "none",
    trackBackgroundColor: "none",
    trackBorderColor: "none",
    trackBorderRadius: 6,
    trackBorderWidth: 2,
};

export const getTextWidth = (fontSize, innerText) => getTextDimension(fontSize, innerText).width + 10;

export const getHtmlWidth = html => getTextDimensionFromHtml(html).width;

export const getLegendWidth = (series, fontSize, getName) => maxBy(series.map((s) => getTextWidth(fontSize, getName(s)))) + 30;

// this is used in render-chart-callback.js
// export const getChartMargin = ({legend, series, isHaveScrollBar, width, size}) => {
//     if (legend.enabled) {
//
//         const hiddenFields = getHiddenPositionFields(size);
//
//         if (hiddenFields.indexOf(upperCase1(legend.align)) > -1) return null;
//
//         if (width < 250 && window.innerWidth <= 769) {
//             return {marginBottom: 36 + (isHaveScrollBar ? 75 : 35)}
//         }
//
//         if (legend.align === "left" || legend.align === "right") {
//             const legendWidth = getLegendWidth(series);
//             if (legend.align === "left") return {marginLeft: legendWidth + 40, legendWidth}
//             return {marginRight: legendWidth + 20, legendWidth}
//         }
//
//         if (legend.align === "top") return {marginTop: 51}
//
//         return {marginBottom: 36 + (isHaveScrollBar ? 75 : 35)}
//     }
//
//     return null;
//
// }

// AB#475
export const getLegendAreaWidth = (currentWidth, size) => {
    if(!size || size == "Auto") return Math.max(103, Math.min(currentWidth, 203));
    if(size == "Small") return 103;
    if(size == "Medium") return 153;
    if(size == "Large") return 203;
};

// AB#475
export const getLegendAreaHeight = ({totalSeries, legendSize, size}) => {
    if(legendSize == "Auto") return 20;
    if(legendSize == "Small") return 20;
    if(legendSize == "Medium") return 35;
    if(legendSize == "Large") return 50;
};

const getAutoLegendAreaHeight = ({data, tile, formatters, isCompare, options, chartHeight, chartWidth, series}) => {
    let maxHeight = (chartHeight * 20) / 100;
    let numberPerCol = 0;
    let currentHeight = 0;
    let totalLegendWidth = Infinity;
    let maxNumberPerCol = 10;

    while(1) {
        if(totalLegendWidth < chartWidth || maxNumberPerCol < numberPerCol) {
            return Math.max(Math.min(currentHeight, maxHeight), 30);
        }
        numberPerCol++;
        currentHeight = numberPerCol * (options.legend.itemStyle.fontSize + 7);
        const group = groupLegendItems({
            numberPerCol,
            isCompare,
            tile,
            formatters,
            data,
            options,
            series,
        });
        totalLegendWidth = sum(group, (g) => g.groupWidth);
    }
};

export const getLegendRoom = ({legend, series, chartHeight, chartWidth, size, isCompare, formatters, options, tile}) => {
    if(tile.$type === "FunnelChartTile") {
        return getFunnelLegendRoom({tile, chartHeight, chartWidth});
    }

    if(["ScatterPlotTile", "BubbleChartTile"].includes(tile.$type)) {
        return getScatterLegendRoom({
            tile,
            series,
            chartHeight,
            chartWidth,
            options,
        });
    }

    const {legendSize, legendPosition, legendShown, legendContent} = tile.style.legendStyle || {};

    if(!legendShown) {
        return null;
    }

    let data = [];

    const isDimension =
        {
            VerticalBarChartTile: () => tile?.style?.barDataColorAppliedBy === "Dimension" && !cascadeFind(tile?.yAxisFields, "[*].measureFields", (value) => value.length > 1) && getFieldType(tile?.xAxisField) !== "date" && isEmpty(tile?.groupField),
        }[tile.$type] || (() => tile?.style?.barDataColorAppliedBy === "Dimension" && !cascadeFind(tile?.xAxisFields, "[*].measureFields", (value) => value.length > 1) && getFieldType(tile?.yAxisField) !== "date" && isEmpty(tile?.groupField));
    if(["VerticalBarChartTile", "HorizontalBarChartTile", "ComboChartTile"].includes(tile.$type) && isDimension()) {
        return null;
    }

    series.forEach((item) => {
        data = data.concat(["PieChartTile", "DonutChartTile", "GaugeTile", "VennDiagramTile"].includes(tile.$type) ? item.data : [item]);
    });

    if(legendShown) {
        if(["Left", "Right"].includes(legendPosition)) {
            let legendWidth = getLegendWidth(data, legend.itemStyle.fontSize, (s) => {
                return `${getLegendItemName({tile, formatters})(s)} ${legendContent == "LabelAndValue" ? (formatters?.valueFormatter || formatMoney)(getLegendItemValue(tile)(s)) : legendContent == "LabelAndPercent" ? `100%` : ""}`;
            });

            const width = getLegendAreaWidth(legendWidth, legendSize) + (isCompare ? 38 : 0);

            if(legendPosition === "Left") {
                return {paddingLeft: width, legendWidth: width};
            }
            return {paddingRight: width + 10, legendWidth: width};
        }

        if(legendPosition === "Top") {
            return {paddingTop: 26};
        }

        if(legendPosition === "Bottom") {
            return {paddingBottom: 26};
        }

        const uniqData = ["PieChartTile", "DonutChartTile", "GaugeTile", "VennDiagramTile"].includes(tile.$type) ? series[0].data : unique(series, (s) => s.name + s.color);

        const legendAreaHeight =
            (legendSize == "Auto" || !legendSize) && chartWidth
                ? getAutoLegendAreaHeight({
                    tile,
                    options,
                    data: uniqData,
                    formatters,
                    isCompare,
                    chartHeight,
                    chartWidth,
                    series,
                })
                : (chartHeight *
                    getLegendAreaHeight({
                        legendSize,
                        size,
                        totalSeries: data.length,
                    })) /
                100;

        if(legendPosition == "TopVertical") {
            return {
                paddingTop: legendAreaHeight,
                legendWidth: "100%",
                legendHeight: legendAreaHeight,
            };
        }

        if(legendPosition == "BottomVertical") {
            return {
                paddingBottom: legendAreaHeight,
                legendWidth: "100%",
                legendHeight: legendAreaHeight,
            };
        }
    }

    return null;
};

export function getDPath({rTopLeft, rTopRight, rBottomRight, rBottomLeft, width, height, x, y, topMargin = 0, bottomMargin = 0}) {
    const maxR = Math.min(width, height) / 2;

    if(rTopLeft > maxR) {
        rTopLeft = maxR;
    }

    if(rTopRight > maxR) {
        rTopRight = maxR;
    }

    if(rBottomRight > maxR) {
        rBottomRight = maxR;
    }

    if(rBottomLeft > maxR) {
        rBottomLeft = maxR;
    }

    return [
        "M",
        x + rTopLeft,
        y + topMargin,
        // top side
        "L",
        x + width - rTopRight,
        y + topMargin,
        // top right corner
        "C",
        x + width - rTopRight / 2,
        y,
        x + width,
        y + rTopRight / 2,
        x + width,
        y + rTopRight,
        // right side
        "L",
        x + width,
        y + height - rBottomRight,
        // bottom right corner
        "C",
        x + width,
        y + height - rBottomRight / 2,
        x + width - rBottomRight / 2,
        y + height,
        x + width - rBottomRight,
        y + height + bottomMargin,
        // bottom side
        "L",
        x + rBottomLeft,
        y + height + bottomMargin,
        // bottom left corner
        "C",
        x + rBottomLeft / 2,
        y + height,
        x,
        y + height - rBottomLeft / 2,
        x,
        y + height - rBottomLeft,
        // left side
        "L",
        x,
        y + rTopLeft,
        // top left corner
        "C",
        x,
        y + rTopLeft / 2,
        x + rTopLeft / 2,
        y,
        x + rTopLeft,
        y,
        "Z",
    ];
}

export const isChartBoosted = (options) => {
    const chartLevelBoosted = options.series?.length > SERIES_BOOST_THRESHOLD;
    const seriesLevelBoosted = options.series?.find((s) => s.data?.length > DATA_BOOST_THRESHOLD);
    return chartLevelBoosted || seriesLevelBoosted;
};

export const mapLegendItem = ({item, tile, formatters, isCompare, options}) => {
    const {$type, style} = tile;

    const fontSize = options.legend.itemStyle.fontSize;
    const subText = style.legendStyle.legendContent == "LabelAndValue" ? (formatters?.valueFormatter || formatMoney)(getLegendItemValue(tile)(item)) : style.legendStyle.legendContent == "LabelAndPercent" ? `100%` : "";
    const subTextPadding = getTextWidth(fontSize, subText) + 10 + (["PieChartTile", "DonutChartTile", "VennDiagramTile"].includes($type) && isCompare && style.legendStyle.legendContent?.startsWith("LabelAnd") ? getTextWidth(fontSize, "100%") + 30 : 0);
    const textWidth = getTextWidth(fontSize, getLegendItemName({tile, formatters})(item)) + 15 + subTextPadding;

    return {
        value: {...item},
        textWidth,
        subTextPadding,
    };
};

export const groupLegendItems = ({numberPerCol, data, tile, formatters, isCompare, options, series}) => {
    let ret = [];
    data.forEach((item) => {
        let _item = mapLegendItem({
            item,
            tile,
            formatters,
            isCompare,
            options,
            series,
        });
        if(ret.length == 0) {
            ret.push([_item]);
        } else {
            if(ret[ret.length - 1].length < numberPerCol) {
                ret[ret.length - 1].push(_item);
            } else {
                ret.push([_item]);
            }
        }
    });

    return ret.map((elem, index) => ({
        values: elem.map((e) => e.value),
        groupWidth: maxBy(elem, (item) => item.textWidth) + (ret.length - 1 == index ? 0 : 40),
        subTextPadding: maxBy(elem, (item) => item.subTextPadding),
    }));
};
