import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {stateToSelect} from "@common/ui-components/form/state-to-select";

export const OrientationSelect = (state) => {
    const {value, onChange} = state;
    return DropdownSelect({
        label: "Orientation",
        list: [
            {label: "Auto", value: "Auto"},
            {label: "Horizontal", value: "Horizontal"},
            {label: "30 degrees", value: "Degrees30"},
            {label: "45 degrees", value: "Degrees45"},
            {label: "60 degrees", value: "Degrees60"},
            {label: "Vertical", value: "Vertical"},
        ],
        valueToLabel: (v) => v.label,
        isSelected: (v) => v.value === (value || "Auto"),
        onChange: (v) => onChange(v.value),
        // ...stateToSelect(state, ["value"]),
    });
};
