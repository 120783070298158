import {UseState} from "../../../react/use-state";
import {cs} from "../../../react/chain-services";
import * as React from "react";
import {addMonth, sameDate, today} from "../../../utils/dates/date-object";
import "./calendar1.scss";
import {Colors} from "../../../ui-components/styles/colors";
import {Calendar} from "../../../ui-components/calendars/calendar/calendar";
import {cx} from "emotion";

export const Calendar1 = ({state, size, space, className, calendarProps = {}}) =>
    cs(
        [
            "month",
            (_, next) =>
                UseState({
                    initValue: state.value || today(),
                    next,
                }),
        ],
        ({month}) => (
            <div className={cx("calendar-1-5zz", className)}>
                {Calendar({
                    ...calendarProps,
                    month: month.value,
                    onSelect: state.onChange,
                    size,
                    space,
                    getHighlight: (date) => sameDate(date, state.value) && Colors.blue,
                })}

                <div className="controls">
                    <div className="left" onClick={() => month.change((m) => addMonth(m, -1))}>
                        <i className="fa fa-chevron-left" />
                    </div>
                    <div className="right" onClick={() => month.change((m) => addMonth(m, +1))}>
                        <i className="right fa fa-chevron-right" />
                    </div>
                </div>
            </div>
        )
    );
