import * as React from "react";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";

import {HorizontalBar} from "./horizontal-bar/horizontal-bar";
import {VerticalBar} from "./vertical-bar/vertical-bar";
import {LineChart} from "./line-chart/line-chart";
import {ScatterPlot} from "./scatter-plot/scatter-plot";
import {PieChart} from "./pie-chart/pie-chart";
import {DownloadReport} from "./download-report/download-report";
import {Table} from "./table/table";
import {ComboChart} from "./combo-chart/combo-chart";
import {Gauge} from "./gauge/gauge";
import {SingleKPI} from "./single-kpi/single-kpi";
import {TableKPI} from "./table-kpi/table-kpi";
import {KPIList} from "./kpi-list/kpi-list";
import {MapTile} from "./map-tile/map-tile";
import {PivotTable} from "./pivot-table/pivot-table";
import {FunnelChart} from "./funnel-chart/funnel-chart";
import {VennDiagram} from "./venn-diagram/venn-diagram";
import {SectionTile} from "./section-tile/section-tile";
import {TextTile} from "./text-tile/text-tile";
import {SparkLineKpi} from "./spark-line-kpi/spark-line-kpi";
import {BoxPlot} from "./box-plot/box-plot";
import {TextLabelTile} from "./text-label/text-label";

export const ChartTypes = ({
    tile,
    form,
    size,
    theme,
    next,
    tableFieldConfigOverride,
    colorsTileConfig,
    kpiColorsTileConfig,
    mapTileColorsConfig,
    scatterMarkerColorsConfig,
    referenceLinesPanel,
    parentTile,
    tileAction,
    conditionalFormattingConfig,
}) =>
    cs(consumeContext("isContainerTile"), ({isContainerTile}) =>
        next(
            types[tile.value.$type]?.({
                tile,
                form,
                size,
                theme,
                tableFieldConfigOverride,
                colorsTileConfig,
                kpiColorsTileConfig,
                mapTileColorsConfig,
                scatterMarkerColorsConfig,
                referenceLinesPanel,
                parentTile,
                tileAction,
                isContainerTile,
                conditionalFormattingConfig,
            }) || []
        )
    );

const types = {
    VerticalBarChartTile: VerticalBar,
    HorizontalBarChartTile: HorizontalBar,
    LineChartTile: LineChart,
    ScatterPlotTile: ScatterPlot,
    BubbleChartTile: ScatterPlot,
    PieChartTile: PieChart,
    DonutChartTile: PieChart,
    DownloadReportTile: DownloadReport,
    TableTile: Table,
    ComboChartTile: ComboChart,
    GaugeTile: Gauge,
    SingleKPITile: SingleKPI,
    TableKPITile: TableKPI,
    ListKPITile: KPIList,
    MapTile: MapTile,
    PivotTableTile: PivotTable,
    FunnelChartTile: FunnelChart,
    VennDiagramTile: VennDiagram,
    SectionTile: SectionTile,
    TextTile: TextTile,
    BoxPlotTile: BoxPlot,
    SparkLineKPITile: SparkLineKpi,
    TextLabelTile: TextLabelTile,
};
