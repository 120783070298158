import {getExtractedPointsCompare} from "./get-extracted-points-compare";
import {getLinePointsTableCompare} from "./line-points-table-compare";
import {tooltipLayout} from "./tooltip-layout";
import {getExtractedPoints} from "./get-extracted-points";
import {getBarLineNonCompareHeader} from "./bar-line-non-compare-header";
import {getLinePointsTable} from "./line-points-table";
import {getReferencePointsTable} from "./reference-points-table";

export const renderLineChartTooltip = ({points, tile, theme, rawData, formatters, isCompare}) => {
    const dataPoints = points.filter((p) => !p.series.userOptions.customType);

    const referencePointsTable = getReferencePointsTable({
        tile,
        theme,
        formatters,
        points,
        series: points[0].series.chart.series,
    });

    if (isCompare) {
        const {extractedPoints, generalInfo} = getExtractedPointsCompare({
            points: dataPoints,
            formatters,
            rawData,
            keepZeroValuePoints: true,
        });

        return tooltipLayout({
            content: `<div class="table">
                    ${getLinePointsTableCompare({
                        tile,
                        extractedPoints,
                        generalInfo,
                        rawData,
                        formatters,
                        theme,
                    })}
                </div>
                ${referencePointsTable}`,
            theme,
            isCompare,
        });
    }

    const {extractedPoints, generalInfo} = getExtractedPoints({
        points: dataPoints,
        formatters,
        keepZeroValuePoints: true,
    });
    return tooltipLayout({
        content: [
            extractedPoints &&
                `${getBarLineNonCompareHeader({
                    tile,
                    extractedPoints,
                    generalInfo,
                    formatters,
                    theme,
                })}
                <div class="table non-compare">
                    ${getLinePointsTable({
                        tile,
                        extractedPoints,
                        generalInfo,
                        rawData,
                        formatters,
                        theme,
                    })}
                </div>`,
            referencePointsTable,
        ]
            .filter((v) => v)
            .join(""),
        theme,
    });
};
