import {LpColorLine} from "../../../../../common/left-panel/lp-color-line";
import {ffToDropdown} from "../../../../../../../../common/form/ff-to-dropdown";
import {ffToColorPickerLine} from "../../../../../../../../common/form/ff-to-color-picker-line";

export const MapsTheme = ({form}) => {
    return (
        <div className="content">
            {LpColorLine({
                label: "Empty Location Color",
                ...ffToColorPickerLine(form.field(["dataVisualization", "maps", "emptyLocationColorRGB"])),
            })}

            {LpColorLine({
                label: "Border Color",
                ...ffToColorPickerLine(form.field(["dataVisualization", "maps", "borderColorRGB"])),
            })}
        </div>
    );
};
