import "./data-labels.scss";

import * as React from "react";

import {ShowHideToggle} from "../../../../../../../../../../../../../common/form/toggles/show-hide-toggle";
import {scope} from "@common/react/scope";
import {DataLabelPositionSelect} from "../../../common/data-label-position-select";
import {CheckboxLine} from "../../../../../../../../../../common/checkbox-line/checkbox-line";
import {MobileWarning} from "../../../common/mobile-warning/mobile-warning";

export const DataLabels = ({tile}) => ({
    label: (
        <div className="horizontal-data-labels-label-5e5">
            Data labels
            {MobileWarning({
                info: "Labels will not be shown on small mobile devices.",
            })}
        </div>
    ),
    control: ShowHideToggle({
        state: scope(tile, ["style", "dataLabels", "show"]),
    }),
    render: () => (
        <div className="horizontal-data-labels-3cv">
            {DataLabelPositionSelect(scope(tile, ["style", "dataLabels", "position"]))}

            {CheckboxLine({
                label: "Allow Overlapping Labels",
                state: scope(tile, ["style", "dataLabels", "allowOverlap"]),
            })}
        </div>
    ),
});
