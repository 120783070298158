import {rBarLineHeaderCompare, rBarLinePointsCompare, rTotalFooterCompare} from "./column-points-table-compare";

export const getLinePointsTableCompare = ({extractedPoints, generalInfo, tile, formatters, rawData, theme}) => {
    return `${rBarLineHeaderCompare({
        extractedPoints,
        generalInfo,
        rawData,
        formatters,
        tile,
        theme,
        useLineIndicator: true,
    })}
         ${rBarLinePointsCompare({
             extractedPoints,
             generalInfo,
             formatters,
             tile,
             theme,
             showDiffPercent: true,
         })}
         ${tile.style.displayType.indexOf("Stack") > -1 ? rTotalFooterCompare({extractedPoints, generalInfo, theme}) : ""}`;
};
