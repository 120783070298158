const {getHeightValueRatio, cGetRelativeValue} = require("../../vertical-bar/chart-options/draw-comparison-bars");
const {getComparisonBarClassName} = require("@common/ui-components/charts/vertical-bar/chart-options/draw-comparison-bars");

const initHorizontalComparisonBarsHelper = ({compareSeries, tile}) => {
    let barGroups = [];

    const render = ({chart, animationDisabled, duration} = {}) => {
        const drawEachSeries = ({series, animationDisabled, duration}) => {
            if (!series.visible) {
                return;
            }

            const respTileSeries = compareSeries.find((s) => s.name === series.name && s.stack === series.userOptions.stack && s.isCompare);

            if (!respTileSeries || respTileSeries.isEmptyData) {
                return;
            }

            const heightValueRatio = getHeightValueRatio(series);

            if (!heightValueRatio) {
                // console.error("Series with null or zeros data");
                return;
            }

            const group = chart.renderer.g(`${series.name}-comparison`).add(chart.seriesGroup);

            series.data.forEach((d, i) => {
                const rectElem = d.graphic?.element;

                if (!d.isInside || !rectElem) {
                    return;
                }

                const respDp = respTileSeries.data[i];

                if (!respDp || respDp[1] == null) {
                    return;
                }

                const mainRect = d.graphic.element.getBBox();

                const getRelativeValue = cGetRelativeValue({series});
                const compareHeight = (respDp[1] ? getRelativeValue(Math.abs(respDp[1])) : 0) * heightValueRatio;

                let leftTop, animateConfigs;
                const isNotNegative = respDp[1] >= 0;

                if (isNotNegative) {
                    leftTop = [mainRect.x - mainRect.width / 2, mainRect.y + mainRect.height - compareHeight];

                    animateConfigs = {
                        y: leftTop[1],
                        height: compareHeight,
                    };
                } else {
                    leftTop = [mainRect.x - mainRect.width / 2, mainRect.y];

                    animateConfigs = {
                        height: compareHeight,
                    };
                }

                let compareRect = chart.renderer
                    .rect(leftTop[0], isNotNegative ? leftTop[1] + compareHeight : leftTop[1], mainRect.width, 0)
                    .attr({
                        fill: series.userOptions.color ?? series.userOptions.colors[i],
                        id: `comparison-bars-${series.name}${i}`,
                        opacity: 0.25,
                        class: getComparisonBarClassName({
                            tile,
                            series,
                            dataPoint: d,
                        }),
                    })
                    .on("mouseover", () => {
                        d.onMouseOver();
                    })
                    .add(group);
                if (!animationDisabled) {
                    compareRect.animate(animateConfigs, {
                        duration: duration || 1000,
                    });
                } else {
                    compareRect.attr(animateConfigs);
                }

                const comparisonPoint = {
                    shapeArgs: {
                        x: leftTop[0],
                        y: leftTop[1],
                        width: mainRect.width,
                        height: compareHeight,
                    },
                    graphic: compareRect,
                    y: respDp[1], // used when draw round corners. ref. draw-round-corners.js
                };

                series.comparisonPoints = (series.comparisonPoints || []).concat(comparisonPoint);
            });

            const translate = {
                x: chart.chartWidth - chart.plotTop + chart.axisOffset[0] - chart.axisOffset[1] + 1 - (chart.marginRight > 10 ? chart.marginRight - 10 : 0),
                // y: chart.chartHeight - chart.marginBottom,
                y: chart.plotHeight + chart.plotTop,
            };
            group.attr({
                transform: `translate(${translate.x},${translate.y}) rotate(90) scale(-1,1) scale(1 1)`,
            });

            barGroups.push(group);
        };

        // console.log("render comparison bars");
        chart.series.forEach((s) => drawEachSeries({series: s, animationDisabled, duration}));
    };

    const remove = () => {
        // console.log("remove comparison bars")
        barGroups.forEach((g) => g.destroy());
        barGroups = [];
    };

    return {
        render,
        remove,
        rerender: (options) => {
            remove();
            render(options);
        },
    };
};
exports.initHorizontalComparisonBarsHelper = initHorizontalComparisonBarsHelper;
