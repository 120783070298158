import "./single-editor.scss";

import React from "react";

import {Invoke} from "@common/react/invoke";
import {cLsJson} from "@common/react/ls-json";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";

import {Button} from "../../../../../common/form/buttons/button/button";
import {ProgressBtn} from "../../../../../common/form/buttons/progress-button/progress-button";
import {AlertIcon} from "@common/ui-components/icons/global-icons";

import {RequestAccessDialog, RESPONSE_ACCESS_STEP, ResponseAccessDialog} from "./dialogs/dialogs";
import {Timer} from "./timer";
import {SignalRHub} from "./signal-r-hub";
import {ModalConsumer} from "../modal/modal-context";
import {waitTimeout} from "@common/utils/wait-timeout";
import {consumeContext} from "@common/react/context";
import {IsActiveUser} from "./is-active";
import {isDevCuong} from "../../../../../tools/dev/is-dev-cuong";

export const SingleEditorRegister = ({item = {type: "", name: "", parent: ""}, timeoutSeconds = 30, goBack = () => {}, otherButtons = [], getItemId}) =>
    cs(
        (_, next) => (isDevCuong() ? null : next()),
        consumeContext("toast"),
        // caching request user to ignore the case user misses click and closes the tab or refreshes tab.
        ["requestUserCache", (_, next) => next(cLsJson(`single-editor:${item.type}:${item.id}:requestUser`))],
        [
            "hub",
            (_, next) =>
                SignalRHub({
                    next,
                    url: "/api/collection/single-editor",
                    getItemId,
                }),
        ],
        [
            "isActiveUser",
            (_, next) =>
                IsActiveUser({
                    next,
                    delta: 2 * 60, // seconds
                    // delta: 10, // seconds
                }),
        ],
        [
            "state",
            ({requestUserCache, hub}, next) => {
                const {currentEditingUser, userId} = hub;
                let requestUser = requestUserCache.get() ?? null;

                if (requestUser && (requestUser.requestedAt + timeoutSeconds * 1000 < Date.now() || currentEditingUser.get().id != userId || requestUser.id == userId)) {
                    requestUser = null;
                    requestUserCache.set(null);
                }

                return UseState({
                    next,
                    initValue: {
                        isOpenBanner: !!requestUser,
                        requestUser,
                        showForceTransferred: false,
                    },
                });
            },
        ],
        ["requestDialog", ({hub, toast}, next) => RequestAccessDialog({next, item, goBack, toast, otherButtons, hub, timeoutSeconds})],
        ({requestDialog, state, toast, hub, isActiveUser, requestUserCache}, next) =>
            hub.connection && hub.currentEditingUser.value?.id
                ? Invoke({
                      next,
                      onMounted: async () => {
                          const {connection, events, currentEditingUser, needAccess} = hub ?? {};
                          if (!connection) {
                              return;
                          }

                          if (needAccess) {
                              requestDialog.show({
                                  item,
                                  currentEditingUser,
                                  timeoutSeconds, //seconds
                              });
                          }

                          events.on("accessRequest", async (requestUserId, name) => {
                              // console.log('accessRequest', requestUserId, name);
                              const requestUser = {
                                  name: name ?? "Another user",
                                  id: requestUserId,
                                  requestedAt: Date.now() - 1000,
                              };

                              requestUserCache.set(requestUser);

                              if (isActiveUser.isActive()) {
                                  state.onChange({
                                      isOpenBanner: true,
                                      requestUser,
                                  });
                              } else {
                                  await hub.respondToAccessRequest(requestUser.id, true);
                                  requestUserCache.set(null);
                                  toast.show(`Edit access was transferred to ${requestUser.name}.`);
                                  currentEditingUser.onChange(requestUser);
                                  state.onChange({
                                      showForceTransferred: true,
                                      requestUser,
                                  });
                              }
                          });

                          events.on("cancelAccessRequest", (...args) => {
                              // console.log('cancelAccessRequest', ...args);
                              requestUserCache.set(null);
                              state.onChange({
                                  isOpenBanner: false,
                                  requestUser: null,
                              });
                          });

                          events.on("accessResponse", (...args) => {
                              // console.log('accessResponse', ...args);
                              if (args[args.length - 1]) {
                                  const oldEditingUser = hub.currentEditingUser.get();
                                  const newEditorID = args[0];
                                  hub.currentEditingUser.onChange({id: newEditorID});
                                  // auto denied if returned user's ID is difference.

                                  if (newEditorID == hub.userId) {
                                      requestDialog.close();
                                      toast?.show(`${oldEditingUser.name ?? "Another user"}'s latest changes are saved and you may begin editing this ${item.type}.`);
                                  }
                              }
                          });
                      },
                  })
                : next(),
        ["registry", ({}, next) => React.createElement(ModalConsumer, {}, next)],
        ({state, toast, hub, requestUserCache}) => {
            const {currentEditingUser} = hub;
            const {isOpenBanner, requestUser, showForceTransferred} = state.value;

            if (!currentEditingUser.value || !hub.connection || !requestUser) {
                return null;
            }

            if (showForceTransferred) {
                return cs(
                    [
                        "responseDialog",
                        ({}, next) =>
                            ResponseAccessDialog({
                                next,
                                hub,
                                state,
                                item,
                                goBack,
                                toast,
                                requestUserCache,
                            }),
                    ],
                    ({responseDialog}) =>
                        Invoke({
                            fn: () => responseDialog.show({forceToStep: RESPONSE_ACCESS_STEP.INACTIVE_FORCE_TRANSFERRED}),
                        })
                );
            }

            return cs(
                [
                    "timer",
                    (_, next) =>
                        Timer({
                            next,
                            startedAt: requestUser?.requestedAt,
                            defaultTimeout: timeoutSeconds * 1000,
                        }),
                ],
                [
                    "responseDialog",
                    ({timer}, next) =>
                        ResponseAccessDialog({
                            next,
                            hub,
                            timer,
                            state,
                            item,
                            goBack,
                            toast,
                            requestUserCache,
                        }),
                ],
                [
                    "showResponseDialog",
                    ({responseDialog}, next) =>
                        next(async (forceToStep) => {
                            state.onChange({
                                ...state.value,
                                isOpenBanner: false,
                            });
                            await waitTimeout(1);
                            responseDialog.show({
                                requestUser,
                                forceToStep,
                            });
                        }),
                ],
                ({showResponseDialog, timer}, next) =>
                    Invoke({
                        next,
                        fn: () => {
                            const {currentEditingUser, events} = hub ?? {};

                            events.on("forceRelinquishAccess", (newEditingUserId) => {
                                requestUserCache.set(null);
                                showResponseDialog(RESPONSE_ACCESS_STEP.NO_RESPOND_FORCE_TRANSFERRED);
                                currentEditingUser.onChange({
                                    id: newEditingUserId,
                                });
                                timer.stop();
                            });
                        },
                    }),
                ({timer, showResponseDialog}) => {
                    const requestUserName = requestUser?.name ?? "Another user";
                    if (!isOpenBanner) {
                        return null;
                    }

                    return (
                        <div className="requesting-permission-banner-a33">
                            {timer.remaining < 10 * 1000 &&
                                Invoke({
                                    fn: () => showResponseDialog(RESPONSE_ACCESS_STEP.SECOND),
                                })}

                            <div className="icon">
                                <AlertIcon fill="#ECCD39" />
                            </div>

                            <div className="text">
                                <b>{requestUserName}</b> is requesting permission to access this {item.type}.{" "}
                                <a onClick={() => showResponseDialog(RESPONSE_ACCESS_STEP.FIRST)}>Learn More</a>
                            </div>

                            <div className="actions">
                                <Button
                                    btnType="danger"
                                    onClick={async () => {
                                        timer.stop();
                                        await hub.respondToAccessRequest(requestUser.id, false);
                                        toast.show(`${requestUserName} has been notified of your response.`);
                                        requestUserCache.set(null);
                                        state.onChange({
                                            isOpenBanner: false,
                                            requestUser: null,
                                        });
                                    }}
                                >
                                    No, I’m still editing
                                </Button>

                                <ProgressBtn
                                    btnType="primary"
                                    progress={timer.remaining / timer.defaultTimeout}
                                    onClick={async () => {
                                        timer.stop();
                                        requestUserCache.set(null);
                                        await hub.respondToAccessRequest(requestUser.id, true);
                                        toast.show(`Edit access was transferred to ${requestUserName}.`);
                                        goBack();
                                    }}
                                >
                                    Grant Access
                                </ProgressBtn>
                            </div>
                        </div>
                    );
                }
            );
        }
    );
