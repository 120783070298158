exports.percentFormats = [
    {
        label: "12.35%",
        attrSet: {
            decimalSeperator: "Dot",
            decimalPlaces: 2,
            negativeFormat: "Parentheses",
        },
    },
    {
        label: "12.4%",
        attrSet: {
            decimalSeperator: "Dot",
            decimalPlaces: 1,
            negativeFormat: "Parentheses",
        },
    },
    {
        label: "12%",
        attrSet: {
            decimalPlaces: 0,
            decimalSeperator: "Dot",
            negativeFormat: "Parentheses",
        },
    },
];
