import "./date-filter-mobile.scss";

import {cx} from "emotion";
import * as React from "react";

import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {Form2} from "@common/react/cs-form/form2";
import {UseState} from "@common/react/use-state";
import {Watch} from "@common/react/watch";

import {filterTypesToColumnTypes} from "../../../../../../../../web-client/src/routes/collection/common/filters/filter-left-panel/filter-configs";
import {cascadeCollect} from "@common/utils/cascade";
import {DropdownSelectLive} from "../../../../../filters/common/dropdown-select/dropdown-select-live";
import {SelectableListChips} from "../../text/selectable-list/chips/selectable-list-chips";

import {StyledClass} from "@common/react/styled-class";
import {AccessibleDateRangesPicker} from "@common/ui-components/live/filters/date-ranges-picker/accessible-date-ranges-picker";
import {CheckboxLine} from "../../../../../../../../web-client/src/routes/common/checkbox-line/checkbox-line";
import {mainToComparing} from "@common/logic/date-time/date-util";
import {scope} from "@common/react/scope";
import {Controller} from "./controller/controller";

export const DateFilterMobile = ({state: state1, width, size, filter, onClose, showApplyBtn, noComparing, showSelectTimezone = false}) =>
    cs(
        consumeContext("theme"),
        consumeContext("modelForCollection"),
        consumeContext("viewWidth"),
        [
            "fields",
            ({modelForCollection: model}, next) =>
                next(
                    cascadeCollect(
                        model,
                        "tables[*].columns[*]",
                        (column, {}, {1: table}) =>
                            filterTypesToColumnTypes[filter.$type].includes(column.dataType) && {
                                table,
                                column,
                            }
                    )
                ),
        ],
        [
            "state",
            ({}, next) =>
                UseState({
                    initValue: state1.value,
                    next,
                }),
        ],
        [
            "form",
            ({state, saving, apis, auth, error}, next) =>
                Form2({
                    fields: [
                        {
                            path: ["selectedFields"],
                            validators: [
                                {
                                    when: () => true,
                                    validate: (v, {data}) => (data.columns || []).length > 0,
                                },
                            ],
                        },
                    ],
                    data: state,
                    onSubmit: async () => {
                        state1.onChange(state.value);
                        onClose();
                    },
                    next,
                }),
        ],
        [
            "dateRangesPicker",
            ({state, theme}, next) => {
                const translateMainToComparing = (mainRange) => {
                    if (mainRange == null || !state.value.comparing) {
                        return null;
                    } else {
                        return mainToComparing(mainRange, filter.defaultOption);
                    }
                };
                const buttonTheme = theme?.general?.buttons;
                return AccessibleDateRangesPicker({
                    theme,

                    ranges: [
                        {
                            color: buttonTheme?.primaryButton.backgroundColorRGB,
                            name: "main",
                            range: state.value.main,
                        },
                        state.value.enableComparing &&
                            !noComparing && {
                                color: buttonTheme?.primaryButton.backgroundColorRGB,
                                name: "comparing",
                                range: state.value.comparing || state.value.main,
                                reactToOtherRangeChange: translateMainToComparing,
                            },
                    ].filter((v) => v),
                    isMobile: true,
                    next,
                });
            },
        ],
        ({dateRangesPicker, state, theme, fields, form, viewWidth}) => {
            const componentTheme = theme.general.components;

            const inputStyle = {
                border: `${componentTheme.inputBorderWidth}px solid ${componentTheme.inputBorderColorRGB}`,
                background: `${componentTheme.inputBackgroundColorRGB}`,
                color: `${componentTheme.inputTextColorRGB}`,
                borderRadius: `${componentTheme.inputCornerRadius}px`,
            };

            return cs(
                [
                    "cssClass",
                    (_, next) =>
                        StyledClass({
                            content: {
                                background: `${componentTheme.menuBackgroundColorRGB}`,
                                color: `${componentTheme.menuTextColorRGB}`,

                                "&:hover": {
                                    background: `${componentTheme.menuHoverBackgroundColorRGB}`,
                                },

                                "&.selected": {
                                    background: `${componentTheme.menuHoverBackgroundColorRGB}`,
                                },
                            },
                            next,
                        }),
                ],
                [
                    "labelStyle",
                    (_, next) =>
                        StyledClass({
                            content: {
                                color: componentTheme.inputLabelTextColorRGB,
                            },
                            next,
                        }),
                ],
                ({cssClass, labelStyle}) => (
                    <div className={"date-filter-mobile"}>
                        {filter.columns?.length > 1 && (
                            <>
                                {filter.multiSelectionOption != "SingleSelect" ? (
                                    <div className="select-multiple-field" ref={form.field(["selectedFields"]).domRef}>
                                        <div className={cx("label", labelStyle)}>
                                            {filter.multiSelectionOption === "Any"
                                                ? "Filter By (results meet any criteria)"
                                                : "Filter By (results meet all criteria)"}
                                        </div>

                                        {SelectableListChips({
                                            searchable: false,
                                            closeAllOtherDropdownWhenOpen: false,
                                            hasError: {value: form.field(["selectedFields"]).error},
                                            className: "selectable-chips",
                                            theme,
                                            list: filter.columns || [],
                                            ...(() => {
                                                const isSelected = (v) =>
                                                    state.value.columns.find((c) => c.modelColumnID == v.modelColumnID);
                                                return {
                                                    isSelected,
                                                    onSelect: (v) => {
                                                        if (isSelected(v)) {
                                                            state.change((state) => ({
                                                                ...state,
                                                                columns: state.columns.filter((c) => c.modelColumnID != v.modelColumnID),
                                                            }));
                                                        } else {
                                                            state.change((state) => ({
                                                                ...state,
                                                                columns: state.columns.concat(v),
                                                            }));
                                                        }
                                                    },
                                                    valueToLabel: (v) =>
                                                        v.displayName ||
                                                        fields.find((item) => item.column.id == v.modelColumnID)?.column?.visualNameFull,
                                                };
                                            })(),
                                        })}
                                    </div>
                                ) : (
                                    <div className="select-multiple-field">
                                        <div className={cx("label", labelStyle)}>Filter By</div>

                                        {DropdownSelectLive({
                                            closeAllOtherDropdownWhenOpen: false,
                                            hasError: form.field(["selectedFields"]).error,
                                            domRef: form.field(["selectedFields"]).domRef,
                                            list: filter.columns || [],
                                            valueToLabel: (v) =>
                                                v.displayName ||
                                                fields.find((item) => item.column.id == v.modelColumnID)?.column?.visualNameFull,
                                            isSelected: (v) => state.value.columns.find((c) => c.modelColumnID == v.modelColumnID),
                                            onChange: (v) => {
                                                state.change((state) => ({...state, columns: [v]}));
                                            },
                                            toggleStyle: inputStyle,
                                            iconColor: componentTheme.inputIconColorRGB,
                                            dropdownCss: cssClass,
                                            borderRadius: componentTheme.menuCornerRadius,
                                        })}
                                    </div>
                                )}
                            </>
                        )}

                        {Controller({
                            isMobile: true,
                            filter,
                            state,
                            dateRangesPicker,
                            theme,
                            noComparing,
                            onReset: () => {
                                state.onChange(state1.value);
                            },
                            showSelectTimezone,
                            onChange: ({name, range}) => {
                                const translateMainToComparing = (mainRange) => {
                                    if (mainRange == null || !state.value.comparing) {
                                        return null;
                                    } else {
                                        return mainToComparing(mainRange, filter.defaultOption);
                                    }
                                };
                                if (name === "main") {
                                    state.onChange({
                                        ...state.value,
                                        main: range,
                                        ...(state.value.enableComparing &&
                                            !noComparing && {
                                                comparing: translateMainToComparing(range),
                                            }),
                                    });
                                } else {
                                    state.onChange({
                                        ...state.value,
                                        comparing: range,
                                    });
                                }
                            },
                        })}
                        {Watch({
                            value: state.value,
                            onChanged: (change) => {
                                if (form.valid) {
                                    state1.onChange(change);
                                }
                            },
                        })}
                        {Watch({
                            value: state1.value,
                            onChanged: (change) => {
                                state.onChange(change);
                            },
                        })}

                        {filter.includeNullDates &&
                            CheckboxLine({
                                state: scope(state, ["includeNullDates"]),
                                label: "Include Data without Dates",
                                background: theme.general.buttons.primaryButton.backgroundColorRGB,
                                textColor: componentTheme.inputLabelTextColorRGB,
                            })}
                    </div>
                )
            );
        }
    );
