import React from "react";
import {cs} from "@common/react/chain-services";
import {TextInput} from "../../../../../../../../../common/form/text-input/text-input";
import {ffToTextInput} from "../../../../../../../../../common/form/ff-to-text-input";
import {Form2} from "@common/react/cs-form/form2";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {ffToDropdown} from "../../../../../../../../../common/form/ff-to-dropdown";
import {dataSourceFormConfig} from "../../../../../common/data-source-form-config";
import {consumeContext} from "@common/react/context";
import {ConnectionSettingsLayout} from "../connection-settings-layout";
import {tooltipService3} from "../../../../../../common/tooltip3/tooltip-service-3";
import {Button} from "../../../../../../../../../common/form/buttons/button/button";
import {DsFolderField} from "../../../../../common/folders-dialog/ds-folder-field";
import {cx} from "emotion";
const {required} = require("@common/react/cs-form/validators/required");

export const DynamoDBConnectionForm = ({data, adding, dsApi, formConfig, connectionStatus, next}) =>
    cs(
        consumeContext("apis"),
        [
            "form",
            ({apis}, next) =>
                Form2({
                    data,
                    fields: {
                        ...formConfig,
                        "connectionDetails.awsAccessKey": [required],
                        "connectionDetails.awsSecretKey": [required],
                        "connectionDetails.awsRegion": [required],
                    },
                    next,
                }),
        ],
        [
            "testConnection",
            ({form}, next) =>
                next(() => {
                    const _connectionDetails = form.data.value?.connectionDetails;
                    const databaseConfigData = {
                        $type: data.value.$type,
                        ..._connectionDetails,
                    };

                    return dsApi.testConnection(
                        {
                            submitConnectionDetails: databaseConfigData,
                            connectionDetails: databaseConfigData,
                        },
                        {
                            type: data.value?.type,
                            name: form.data.value?.name,
                        }
                    );
                }),
        ],
        ({form, testConnection}) => {
            return next({
                valid: form.valid,
                beforeGoNext: testConnection,
                render: () =>
                    ConnectionSettingsLayout({
                        type: data.value.type,
                        adding,
                        connectionStatus,
                        controls: {
                            valid: form.valid,
                            onTestConnection: testConnection,
                            onDiscard: () =>
                                form.data.onChange({
                                    ...data.value,
                                    connectionDetails: {},
                                }),
                            onGoNext: dsApi.onNextStep,
                        },
                        content: (
                            <>
                                {TextInput({
                                    label: "Display Name",
                                    ...ffToTextInput(form.field("name")),
                                })}

                                {DsFolderField({
                                    state: form.field("folderID").state,
                                })}

                                <div className="form-group-flex">
                                    {TextInput({
                                        label: "AWS Access Key",
                                        placeholder: "AWS Access Key",
                                        ...ffToTextInput(form.field("connectionDetails.awsAccessKey")),
                                    })}

                                    {TextInput({
                                        label: "AWS Secret Key",
                                        placeholder: "AWS Secret Key",
                                        ...ffToTextInput(form.field("connectionDetails.awsSecretKey")),
                                    })}
                                </div>
                                {DropdownSelect({
                                    label: "AWS Region",
                                    placeholder: "AWS Region",
                                    valueToInputLabel: (v) => v.name,
                                    valueToLabel: (v) => v.name,
                                    list: [
                                        {
                                            name: "US East (N. Virginia) [us-east-1]",
                                            value: "us-east-1",
                                        },
                                        {
                                            name: "US East (Ohio) [us-east-2]",
                                            value: "us-east-2",
                                        },
                                        {
                                            name: "US ISO East [us-iso-east-1]",
                                            value: "us-iso-east-1",
                                        },
                                        {
                                            name: "US ISO WEST [us-iso-west-1]",
                                            value: "us-iso-west-1",
                                        },
                                        {
                                            name: "US ISOB East (Ohio) [us-isob-east-1]",
                                            value: "us-isob-east-1",
                                        },
                                        {
                                            name: "US West (N. California) [us-west-1]",
                                            value: "us-west-1",
                                        },
                                        {
                                            name: "US West (Oregon) [us-west-2]",
                                            value: "us-west-2",
                                        },
                                        {
                                            name: "Africa (Cape Town) [af-south-1]",
                                            value: "af-south-1",
                                        },
                                        {
                                            name: "Asia Pacific (Hong Kong) [ap-east-1]",
                                            value: "ap-east-1",
                                        },
                                        {
                                            name: "Asia Pacific (Jakarta) [ap-southeast-3]",
                                            value: "ap-southeast-3",
                                        },
                                        {
                                            name: "Asia Pacific (Mumbai) [ap-south-1]",
                                            value: "ap-south-1",
                                        },
                                        {
                                            name: "Asia Pacific (Osaka) [ap-northeast-3]",
                                            value: "ap-northeast-3",
                                        },
                                        {
                                            name: "Asia Pacific (Seoul) [ap-northeast-2]",
                                            value: "ap-northeast-2",
                                        },
                                        {
                                            name: "Asia Pacific (Singapore) [ap-southeast-1]",
                                            value: "ap-southeast-1",
                                        },
                                        {
                                            name: "Asia Pacific (Sydney) [ap-southeast-2]",
                                            value: "ap-southeast-2",
                                        },
                                        {
                                            name: "Asia Pacific (Tokyo) [ap-northeast-1]",
                                            value: "ap-northeast-1",
                                        },
                                        {
                                            name: "AWS GovCloud (US-East) [us-gov-east-1]",
                                            value: "us-gov-east-1",
                                        },
                                        {
                                            name: "AWS GovCloud (US-West) [us-gov-west-1]",
                                            value: "us-gov-west-1",
                                        },
                                        {
                                            name: "Canada (Central) [ca-central-1]",
                                            value: "ca-central-1",
                                        },
                                        {
                                            name: "China (Beijing) [cn-north-1]",
                                            value: "cn-north-1",
                                        },
                                        {
                                            name: "China (Ningxia) [cn-northwest-1]",
                                            value: "cn-northwest-1",
                                        },
                                        {
                                            name: "Europe (Frankfurt) [eu-central-1]",
                                            value: "eu-central-1",
                                        },
                                        {
                                            name: "Europe (Ireland) [eu-west-1]",
                                            value: "eu-west-1",
                                        },
                                        {
                                            name: "Europe (London) [eu-west-2]",
                                            value: "eu-west-2",
                                        },
                                        {
                                            name: "Europe (Milan) [eu-south-1]",
                                            value: "eu-south-1",
                                        },
                                        {
                                            name: "Europe (Paris) [eu-west-3]",
                                            value: "eu-west-3",
                                        },
                                        {
                                            name: "Europe (Stockholm) [eu-north-1]",
                                            value: "eu-north-1",
                                        },
                                        {
                                            name: "Middle East (Bahrain) [me-south-1]",
                                            value: "me-south-1",
                                        },
                                        {
                                            name: "Middle East (UAE) [me-central-1]",
                                            value: "me-central-1",
                                        },
                                        {
                                            name: "South America (Sao Paulo) [sa-east-1]",
                                            value: "sa-east-1",
                                        },
                                    ],
                                    ...ffToDropdown(form.field("connectionDetails.awsRegion"), ["value"]),
                                })}

                                {/*<div className="buttons">*/}
                                {/*    {cs(*/}
                                {/*        tooltipService3({*/}
                                {/*            direction: "above",*/}
                                {/*            tooltipContentStyle: {*/}
                                {/*                width: 150*/}
                                {/*            }*/}
                                {/*        }),*/}
                                {/*        ({tooltip}) => (*/}
                                {/*            <Button*/}
                                {/*                btnType="secondary"*/}
                                {/*                size="small"*/}
                                {/*                {...!form.valid ? tooltip(() => `All required fields must be filled before testing the connection`) : {}}*/}
                                {/*                className={cx({disabled: !form.valid    })}*/}
                                {/*                onClick={() => form.valid && testConnection()}*/}
                                {/*            >Test Connection</Button>*/}
                                {/*        )*/}
                                {/*    )}*/}
                                {/*    */}
                                {/*    <Button btnType="danger"*/}
                                {/*            size="small"*/}
                                {/*        onClick={() => form.data.onChange({ ...data.value, connectionDetails: {} })}*/}
                                {/*    >*/}
                                {/*        Discard Database</Button>*/}
                                {/*</div>*/}
                            </>
                        ),
                    }),

                // <div className="dynamoDB-connection-form data-source-configuration-form-5rq">
                //     <div className="col-left">
                //         {/* <div className="header">Data Source Details</div>

                //         {TextInput({
                //             label: "Display Name",
                //             ...ffToTextInput(form.field("name"))
                //         })} */}

                //         <div className="header">Connection Settings</div>

                //         <div className="form-group-flex">
                //             {TextInput({
                //                 label: "AWS Access Key",
                //                 placeholder: "AWS Access Key",
                //                 ...ffToTextInput(form.field("connectionDetails.awsAccessKey"))
                //             })}

                //             {TextInput({
                //                 label: "AWS Secret Key",
                //                 placeholder: "AWS Secret Key",
                //                 ...ffToTextInput(form.field("connectionDetails.awsSecretKey"))
                //             })}
                //         </div>
                //         {DropdownSelect({
                //             label: "AWS Region",
                //             placeholder: "AWS Region",
                //             list: [
                //                 "US East (Virginia)",
                //                 "US East (Ohio)",
                //                 "US West (N. California)",
                //                 "US West (Oregon)",
                //                 "Africa (Cape Town)",
                //                 "Asia Pacific (Hong Kong)",
                //                 "Asia Pacific (Tokyo)",
                //                 "Asia Pacific (Seoul)",
                //                 "Asia Pacific (Osaka)",
                //                 "Asia Pacific (Mumbai)",
                //                 "Asia Pacific (Singapore)",
                //                 "Asia Pacific (Sydney)",
                //                 "Canada (Central)",
                //                 "EU Central (Frankfurt)",
                //                 "EU North (Stockholm)",
                //                 "Europe (Milan)",
                //                 "EU West (Ireland)",
                //                 "EU West (London)",
                //                 "EU West (Paris)",
                //                 "Middle East (Bahrain)",
                //                 "South America (Sao Paulo)",
                //                 "China (Beijing)",
                //                 "China (Ningxia)",
                //                 "US GovCloud East (Virginia)",
                //                 "US GovCloud West (Oregon)",
                //                 "US ISO East",
                //                 "US ISOB East (Ohio)"
                //             ],
                //             ...ffToDropdown(form.field("connectionDetails.awsRegion"))
                //         })}
                //     </div>

                //     <div className="col-right">
                //         <div className="section information">
                //             <div className="text-header">Information</div>
                //         </div>

                //         <div className="section getting-connected">
                //             <div className="text-header active">
                //                 Getting Connected
                //             </div>

                //             <div className="content">
                //                 <div>
                //                     Provide the details and credentials for the data source you're trying to connect to.
                //                     <p>Verb will query the schema and give you the option to include/exclude data like tables, columns and rows.</p>
                //                     <p>All of the fields are required unless explicitly noted.</p>
                //                 </div>
                //             </div>
                //         </div>
                //     </div>
                // </div>
            });
        }
    );
