import * as React from "react";
import {cs} from "@common/react/chain-services";
import "./publish-transformation-dialog.scss";
import {DialogService} from "../../../../common/dialog/dialog-service";
import {Load} from "@common/react/load";
import {consumeContext} from "@common/react/context";
import {UseState} from "@common/react/use-state";
import {formatDateTimeFromISOStr} from "../../../../../../../common/logic/date-time/format-date-time-full";
import {LoadingIndicator} from "@common/ui-components/loading-indicator/loading-indicator";
import {Button} from "../../../../../../../common/form/buttons/button/button";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";

export const PublishTransformationDialog = ({transformation, next: rootNext}) =>
    cs(
        consumeContext("apis"),
        [
            "modal",
            ({collection}, next) =>
                DialogService({
                    render: ({resolve}) => ({
                        title: "Publish " + transformation.name,
                        width: 560,
                        content: next({
                            resolve,
                        }),
                    }),
                    next: rootNext,
                }),
        ],
        [
            "data",
            ({apis}, next) =>
                Load({
                    fetch: () => apis.transformation.getPublishInfo(transformation.id),
                    next,
                }),
        ],
        ["publishing", (_, next) => UseState({next})],
        ({modal, data, publishing, apis}) => {
            const rError = (error) => {
                return (
                    <div className="message error">
                        Sorry, there is an issue publishing this transformation.
                        {[400, 500].includes(error.statusCode) && (
                            <>
                                <br />
                                {error.message}
                            </>
                        )}
                    </div>
                );
            };

            return (
                <div className="publish-transformation-dialog-ti5">
                    <VerbDialogBodyScrollbar>
                        {data == null ? (
                            <div className="messages">{LoadingIndicator()}</div>
                        ) : data.publishedOn ? (
                            <div className="messages">
                                <div className="message">
                                    The current version of this transformation was published on {formatDateTimeFromISOStr(data.publishedOn)}
                                    {data.publishedBy ? " by " + data.publishedBy : ""}.
                                </div>
                                <div className="message">Publishing will update this model and collections using this data immediately.</div>
                                {publishing.value?.error && rError(publishing.value.error)}
                            </div>
                        ) : (
                            <div className="messages">
                                <div className="message">This transformation has never been published before.</div>
                                {publishing.value?.error && rError(publishing.value.error)}
                            </div>
                        )}
                    </VerbDialogBodyScrollbar>

                    <div className="buttons">
                        <Button btnType="secondary" onClick={modal.resolve}>
                            {publishing.value?.done ? "Done" : "Cancel"}
                        </Button>
                        {!publishing.value?.error && (
                            <Button
                                disabled={publishing.value}
                                onClick={async () => {
                                    publishing.onChange({doing: true});
                                    try {
                                        await apis.transformation.publishTransformation(transformation.id);
                                        publishing.onChange({done: true});
                                    } catch (error) {
                                        publishing.onChange({error});
                                    }
                                    // modal.resolve
                                }}
                            >
                                {publishing.value == null ? "Publish" : publishing.value.doing ? "Publishing..." : "Published"}
                            </Button>
                        )}
                    </div>
                </div>
            );
        }
    );
