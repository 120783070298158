//import {sampleCollection} from "../../../common/logic/sample-api/sample-collection";
//import {sampleThemes} from "../../../common/logic/sample-api/sample-themes";

export const createMockApis = () => ({
    getCollection: async () => ({
        //...sampleCollection,
        //theme: sampleThemes[0],
        filterConnectedTileValidity: {
            "": {
                validTileIDs: [],
                invalidTile: [],
            },
        },
    }),

    collection: {
        getFilterData: () => ["Data 1", "Data 2", "Data 3", "Data 4"],
    },
});
