const {findMaxE} = require("../../../../utils/collections");
const {darken} = require("../../../../utils/color-util");
const {getColorConfigs, getFieldColorConfigs} = require("../../get-field-color");

const getMax = ({data, style}) => {
    const {value, previousValue, target, previousTarget} = data;

    const rawMax = (Math.abs(style.reservedPadding ?? 0) / 100 + 1) * (style.showCompare ? findMaxE([value, previousValue, target, previousTarget]) : findMaxE([value, target]));

    const max = Math.ceil(rawMax);

    return {rawMax, max};
};
exports.getMax = getMax;

const getInnerRadius = (thickness) =>
    ({
        null: 80,
        Thin: 80,
        Thick: 60,
    }[thickness] || 80);
exports.getInnerRadius = getInnerRadius;

const getRadii = (style) => {
    const leastInnerRadius = getInnerRadius(style.tickness);
    const splitPointRadius = (100 - leastInnerRadius) / 2 + leastInnerRadius;

    return {leastInnerRadius, splitPointRadius};
};
exports.getRadii = getRadii;

const getEmptyColor = ({style, theme}) => style.emptyColorRGB || theme.dataVisualization.dataColorPalettes.otherColors.emptyFillColorRGB || "#EEE";
exports.getEmptyColor = getEmptyColor;

const getColors = ({tile, theme}) => {
    const {dataColorPalettes} = theme.dataVisualization;
    const colorConfigs = getColorConfigs(tile);
    const {displayColor} = getFieldColorConfigs({
        dataColorPalettes,
        colorConfigs,
    });

    return {
        emptyColor: getEmptyColor({style: tile.style, theme}),
        dataColor: displayColor,
        compareDataColor: darken(displayColor, 20),
    };
};
exports.getColors = getColors;

const getArcSizeDegree = (arcSize) => (!arcSize ? 180 : +arcSize.slice(3));
exports.getArcSizeDegree = getArcSizeDegree;

const isCompareEnabled = ({style, data}) => style.showCompare && data.range && data.previousRange && style.comparisonGaugeShown;
exports.isCompareEnabled = isCompareEnabled;
