import React from "react";
import "./title-info.scss";
import {AuthStaticTooltip} from "./auth-static-tooltip";

export const TitleInfo = ({title, infoName}) => (
    <div className="title-info title-info-9sf">
        {title}
        {AuthStaticTooltip({name: infoName})}
    </div>
);
