import React from "react";
import {cs} from "@common/react/chain-services";
import {GlobalMouseMove} from "@common/react/global-mouse-move";
import {spc} from "@common/react/state-path-change";
import {getELoc} from "@common/react/get-e-loc";
import {GlobalMouseUp} from "@common/react/global-mouse-up";
import {CircleRadius} from "./model-panel";

export const DraggingNew = ({draggingNew, onDrop, draggingNewTablePosition}) =>
    cs(() => {
        const {pos, scale, color, table, datasource} = draggingNew.value;

        return (
            <>
                <div
                    className="dragging-new-table"
                    style={{
                        top: pos.y - CircleRadius * scale,
                        left: pos.x - CircleRadius * scale,
                    }}
                >
                    <div
                        className="circle"
                        style={{
                            width: (CircleRadius * 2 - 2) * scale,
                            height: (CircleRadius * 2 - 2) * scale,
                            borderRadius: "50%",
                            border: `2px solid ${color}`,
                        }}
                    >
                        <div
                            className="background-inside"
                            style={{
                                background: color,
                                opacity: draggingNewTablePosition ? 1 : 0.2,
                                width: "100%",
                                height: "100%",
                                borderRadius: "50%",
                            }}
                        />
                    </div>

                    {draggingNewTablePosition && (
                        <div
                            style={{
                                textAlign: "center",
                                fontSize: 15 * scale,
                                marginTop: 10,
                                color: "#707070",
                                position: "absolute",
                                top: CircleRadius * 2 * scale,
                                left: "50%",
                                transform: "translate(-50%, 0)",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {table.name}
                        </div>
                    )}
                </div>

                {GlobalMouseMove({
                    fn: (e) => {
                        spc(draggingNew, ["pos"], () => getELoc(e));
                    },
                })}
                {GlobalMouseUp({
                    fn: () => {
                        onDrop({table, datasource});
                    },
                })}
            </>
        );
    });

export const SVGFilterDropShadow = () => (
    <filter x="-50%" y="-50%" width="200%" height="200%" filterUnits="objectBoundingBox" id="drop-shadow">
        <feOffset dx="0" dy="4" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="10" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0" in="shadowBlurOuter1" type="matrix" result="shadowMatrixOuter1" />
        <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="SourceGraphic" />
        </feMerge>
    </filter>
);
