import "./account-settings-layout.scss";

import {cx} from "emotion";
import React from "react";

import {cs} from "@common/react/chain-services";
import {TabsHeader} from "../../common/tabs/tabs-header";
import {consumeContext} from "@common/react/context";

export const AccountSettingsLayout = ({activeTab, tabs, header}) =>
    cs(() => {
        const currentTab = tabs.find((t) => t.name === activeTab.value);

        return (
            <div className="account-settings-layout-2re">
                <div className="header">
                    <div className="env-name">{header}</div>

                    {sNavTabs({activeTab, tabs})}
                </div>
                <div className="content">{currentTab.content?.()}</div>
            </div>
        );
    });

export const sNavTabs = ({activeTab, tabs}) =>
    cs(consumeContext("routing"), ({routing}) =>
        TabsHeader({
            className: "account-settings-nav-tabs",
            tabs: tabs.map((tab) => ({
                ...tab,
                onClick: () => {
                    routing.goto("account-management", {setting: tab.name});
                    activeTab.onChange(tab.name);
                },
                label: (
                    <div className="tab-label">
                        {tab.secondLabel ?? tab.label}

                        {!!tab.number && <div className="number">{tab.number}</div>}
                    </div>
                ),
                active: activeTab.value === tab.name,
            })),
        })
    );

export const AccountManagementLayout = ({header, actions, content}) =>
    cs(() => (
        <div className="env-dashboard-layout-2re">
            <div className="env-dashboard-header">
                <div className="env-name">{header}</div>

                {actions && <div className="actions">{actions}</div>}
            </div>
            <div className="env-dashboard-content">{content}</div>
        </div>
    ));
