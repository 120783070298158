import React from "react";
import {concatArr} from "@common/utils/objects";
import {isAllRelationshipsRelated} from "../../auto-suggest/auto-suggest-helper";
import "./model-suggestion-tutorial-step.scss";
import {RemoveGuidanceButton} from "../steps/model-tutorial-step-1";
import {cs} from "@common/react/chain-services";
import {CreateCollectionDialog} from "../../../../../dashboard/env/collections/collection-cm/create-collection/create-collection-dialog";
import {UseState} from "@common/react/use-state";
import {cx} from "emotion";
import {keyed} from "@common/react/keyed";
import {DelayRender} from "@common/react/delay-render";
import {Watch} from "@common/react/watch";
import {buildAutoSuggestModel} from "../../../../common/build-auto-suggest-model";
import {Button} from "../../../../../../../../common/form/buttons/button/button";

export const ModelSuggestionTutorialStep = ({dataSources, model, open, folders, onGoToStep, toggleModal, dimension}) =>
    cs(
        [
            "createCollectionDialog",
            (_, next) =>
                CreateCollectionDialog({
                    folders: folders.value,
                    model: model,
                    next,
                }),
        ],
        ["stepIndex", (_, next) => UseState({next, initValue: 0})],
        ({createCollectionDialog, stepIndex}) => {
            const totalTables = concatArr(dataSources, (d) => d.tables);
            const {tables: ts, relationships: rs} = buildAutoSuggestModel(dataSources);

            const {tables, relationships} = model;
            const isUsedAllTables = tables.length > 0 && totalTables.length == tables.length;
            const isAllRelationshipRelated = tables.length > 0 && isAllRelationshipsRelated({tables: totalTables, relationships});
            const cannotAutoSuggest = ts.length > 10 && rs.length == 0;

            const lastStep = {
                title: "And now… create a collection!",
                description: "After you have created relationships between each of the tables on your canvas, you’re ready to add charts to a collection!",
                allowPrev: true,
                createCollectionBtn: true,
                height: 243,
            };

            const scenarios = [
                {
                    isShow: () => !isUsedAllTables && tables.length > 1 && relationships.length > 0,
                    click: () => onGoToStep(),
                    steps: [
                        {
                            title: "Your model is ready!",
                            checkIcon: true,
                            description: `Your model has been started with the ${tables.length} tables from your data sources that have the most data and relationships.`,
                            allowNext: true,
                            height: 267,
                            hideMinimize: true,
                        },
                        {
                            title: "Next, add additional tables",
                            image: <img src={require("../images/step-1.gif")} className="img-bg" />,
                            description: `If there are additional tables that you need for your visualizations, you can drag them onto the canvas now.`,
                            allowNext: true,
                            height: 369,
                        },
                        {
                            title: "Then, review and add relationships",
                            image: <img src={require("../images/step-2.gif")} className="img-bg" />,
                            description: (
                                <div>
                                    Review the relationships connecting the tables and add new relationships (if possible) to connect any disconnected tables.
                                    <br />
                                    <br />
                                    When columns are named similarly, Verb will provide relationship suggestions.
                                    <br />
                                    <br />
                                    Tip: You don’t have to create relationships between all of the tables, but when tables are related you can combine the data from them for more
                                    complex and powerful visualizations.
                                </div>
                            ),
                            allowNext: true,
                            allowPrev: true,
                            height: 518,
                        },
                        lastStep,
                    ],
                },
                {
                    isShow: () => isUsedAllTables && !isAllRelationshipRelated && relationships.length > 0,
                    click: () => onGoToStep(),
                    steps: [
                        {
                            title: "Your model is ready!",
                            checkIcon: true,
                            description: `Your model has been started with all of the tables from your data sources.`,
                            allowNext: true,
                            height: 254,
                            hideMinimize: true,
                        },
                        {
                            title: "Next, set up relationships between the tables on the canvas.",
                            image: <img src={require("../images/step-2.gif")} className="img-bg" />,
                            description: `Tip: You don’t have to create relationships between all of the tables, but when tables are related you can combine the data from them for more complex and powerful visualizations.`,
                            allowNext: true,
                            height: 410,
                        },
                        lastStep,
                    ],
                },
                {
                    isShow: () => isUsedAllTables && isAllRelationshipRelated && relationships.length > 0,
                    steps: [
                        {
                            title: "Your model is ready!",
                            checkIcon: true,
                            description: (
                                <div>
                                    <b>Good news!</b> Your model has been created with all of the tables from your data sources. And, we were able to establish relationships
                                    between each table for you, too.
                                    <br />
                                    <br />
                                    It’s time to add charts to a collection.
                                </div>
                            ),
                            height: 332,
                            hideMinimize: true,
                            createCollection: true,
                        },
                        {},
                    ],
                },
                {
                    isShow: () => relationships.length == 0 && tables.length == 1,
                    createCollection: true,
                    steps: [
                        {
                            title: "Your model is ready!",
                            checkIcon: true,
                            description: (
                                <div>
                                    <b>Good news!</b> Your model has been fully created. It’s time to add charts to a collection.
                                </div>
                            ),
                            height: 258,
                            hideMinimize: true,
                            createCollection: true,
                        },
                        {},
                    ],
                },
                {
                    isShow: () => relationships.length == 0 && tables.length > 1 && tables.length < 10,
                    steps: [
                        {
                            title: "Your model is ready!",
                            checkIcon: true,
                            description: "Your model has been started with all of the tables from your data sources.",
                            height: 248,
                            hideMinimize: true,
                            allowNext: true,
                        },
                        {
                            title: "Next, add additional tables",
                            image: <img src={require("../images/step-1.gif")} className="img-bg" />,
                            description: (
                                <div>
                                    If there are additional tables that you need for your visualizations, you can drag them onto the canvas.
                                    <br />
                                    <br />
                                    Tip: only include the tables that have data that you intend to visualize now. You can always add more later.
                                </div>
                            ),
                            allowNext: true,
                            height: 438,
                        },
                        {
                            title: "Next, set up relationships between each of the tables on the canvas.",
                            image: <img src={require("../images/step-2.gif")} className="img-bg" />,
                            description:
                                "Tip: You don’t have to create relationships between all of the tables, but when tables are related you can combine the data from them for more complex and powerful visualizations.",
                            allowNext: true,
                            allowPrev: true,
                            height: 413,
                        },
                        lastStep,
                    ],
                },
                {
                    isShow: () => cannotAutoSuggest,
                    steps: [
                        {
                            title: "We’re sorry! We tried, but were not able to build your model automatically",
                            description: (
                                <div>
                                    This happened because you have more than 10 tables in your data sources and there are no apparent relationships for us to suggest between any of
                                    the tables. All is not lost, though!
                                    <br />
                                    <br />
                                    <b>Click Next for the guidance to build the model yourself.</b>
                                    <br />
                                    <br />
                                    Tip: We know this isn’t what you were hoping for, but if you get stuck, you can review the{" "}
                                    <a
                                        style={{
                                            color: "#2692F5",
                                            textDecoration: "none",
                                        }}
                                        href="https://docs.revivemedia.us/data-access-apis/getting-started/data-model"
                                        target="__blank"
                                    >
                                        documentation
                                    </a>{" "}
                                    or click the chat icon at the bottom right corner to get help (often immediately) from our team.
                                </div>
                            ),
                            height: 477,
                            hideMinimize: true,
                            allowNext: true,
                        },
                        {
                            title: "First, add data to the model",
                            image: <img src={require("../images/step-1.gif")} className="img-bg" />,
                            description: "Drag each table onto the canvas that contains data you intend to show in your charts.",
                            allowNext: true,
                            height: 350,
                            allowPrev: true,
                        },
                        {
                            title: "Next, set up relationships between each of the tables on the canvas.",
                            image: <img src={require("../images/step-2.gif")} className="img-bg" />,
                            description: (
                                <div>
                                    When columns are named similarly, Verb will provide relationship suggestions.
                                    <br />
                                    <br />
                                    Tip: You don’t have to create relationships between all of the tables, but when tables are related, you can combine the data from them for more
                                    complex and powerful visualizations.
                                </div>
                            ),
                            allowNext: true,
                            allowPrev: true,
                            height: 463,
                        },
                        lastStep,
                    ],
                },
            ];

            const selectedScenario = scenarios.find((s) => s.isShow());
            const selectedStep = selectedScenario.steps[stepIndex.value];

            return (
                <div className={cx("model-tutorial-a23")} style={{top: 20}}>
                    <div
                        className={`tutorial-content ${open.value ? "expanded" : "minimize"}`}
                        style={{
                            width: open.value ? 376 : 60,
                            height: open.value ? selectedStep.height : 60,
                        }}
                    >
                        <div className={cx("expanded-title", selectedStep.checkIcon && "has-icon", selectedStep.hideMinimize && "hide-minimize")}>
                            {selectedStep.checkIcon && (
                                <div className="check-icon">
                                    <img src={require("../images/check-icon.svg")} alt="" />
                                </div>
                            )}

                            {selectedStep.title}
                        </div>

                        <div className={cx("expanded-body", "model-suggestion-tutorial-step-body-a33")}>
                            {open.value &&
                                cs(() => (
                                    <div className="step-1">
                                        {stepIndex.value == 0 && (
                                            <div
                                                className="progress-bar"
                                                style={{
                                                    background: cannotAutoSuggest ? "#F52626" : "",
                                                }}
                                            />
                                        )}

                                        {selectedStep.image}

                                        <div className="text">{selectedStep.description}</div>

                                        {stepIndex.value == selectedScenario.steps.length - 1 ? (
                                            <>
                                                <div className="step-footer has-margin">
                                                    <Button
                                                        btnType="border"
                                                        iconLeft={<i className="fas fa-chevron-left" />}
                                                        onClick={() => stepIndex.onChange(stepIndex.value - 1)}
                                                    >
                                                        Previous
                                                    </Button>

                                                    <Button onClick={() => createCollectionDialog.show()}>Create Collection</Button>
                                                </div>

                                                {RemoveGuidanceButton({})}
                                            </>
                                        ) : (
                                            <div className="step-footer">
                                                {RemoveGuidanceButton({})}

                                                {selectedStep.allowPrev && (
                                                    <Button
                                                        btnType="border"
                                                        iconLeft={<i className="fas fa-chevron-left" />}
                                                        onClick={() => stepIndex.onChange(stepIndex.value - 1)}
                                                    >
                                                        Previous
                                                    </Button>
                                                )}

                                                {selectedStep.allowNext && (
                                                    <Button iconRight={<i className="fas fa-chevron-right" />} onClick={() => stepIndex.onChange(stepIndex.value + 1)}>
                                                        Next
                                                    </Button>
                                                )}

                                                {selectedStep.createCollection && <Button onClick={() => createCollectionDialog.show()}>Create Collection</Button>}
                                            </div>
                                        )}
                                    </div>
                                ))}
                        </div>

                        {!selectedStep.hideMinimize && (
                            <div className="close-btn" onClick={() => toggleModal()}>
                                <i className="fas fa-chevron-up" />
                            </div>
                        )}
                    </div>
                </div>
            );
        }
    );
