import * as React from "react";

import {cs} from "@common/react/chain-services";
import {provideContext} from "@common/react/context";

import {Expandable} from "../../../../../../../common/expandable/expandable";

import {getTileFields} from "./common/get-tile-fields";
import {MultiFieldWell} from "./common/multi-field-well/multi-field-well";
import {MultipleFieldGroup} from "./common/multi-field-well/multiple-field-group";

export const ListKPIFields = ({tile, isSubTile, debugFunction, next}) =>
    cs(
        [
            "tileFields",
            ({}, next) =>
                next(
                    getTileFields({
                        tile: tile.value,
                        measureSingleAttrs: ["valueField"],
                    })
                ),
        ],
        ({tileFields}, next) => provideContext("tileFields", tileFields, next),

        [
            "valueField",
            (_, next) =>
                MultiFieldWell({
                    fields: {
                        value: tile.value.valueFields,
                        onChange: (value) => {
                            tile.onChange({
                                ...tile.value,
                                valueFields: value,
                                style: {
                                    ...tile.value.style,
                                    tileFields: value
                                        .map((field) => {
                                            let found = (tile.value.style.tileFields || []).find((item) => item.tileFieldID == field.id);
                                            return found ? found : null;
                                        })
                                        .filter((v) => v),
                                },
                            });
                        },
                    },
                    $type: "MeasureTileField",
                    label: "Value",
                    hideDisplayName: true,
                    hideSort: true,
                    dataKey: "valueFields",
                    allowSameFieldAndAggregation: true,
                    notAllowNoneAggregation: true,
                    next,
                }),
        ],
        [
            "multipleFieldsGroup",
            ({valueField}, next) =>
                MultipleFieldGroup({
                    group: [valueField],
                    allowDragToAnotherGroup: false,
                    tile,
                    next,
                }),
        ],
        [
            "fieldsSection",
            ({valueField, multipleFieldsGroup}, next) =>
                next({
                    render: () =>
                        Expandable({
                            label: "Fields",
                            render: () => <>{multipleFieldsGroup.render()}</>,
                        }),
                    override: valueField.override,
                }),
        ],

        ({fieldsSection}) =>
            next({
                render: () => (
                    <div className="tile-fields-3vr">
                        {fieldsSection.render()}
                        {isSubTile && debugFunction?.render?.({})}
                    </div>
                ),
                override: fieldsSection.override || debugFunction?.override(),
                overrideAll: debugFunction?.overrideAll,
            })
    );
