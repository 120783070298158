import React from "react";
import {cs} from "@common/react/chain-services";
import "./extract-configuration.scss";
import {TextManipulationResultActionSelect} from "../../common/text-manipulation-result-action-select";
import {rColumnValueSelectFromStepOutput} from "../../../../../common/column-value-select-from-step-output/column-value-select-from-step-output";
import {ffToDropdown} from "../../../../../../../../../../common/form/ff-to-dropdown";
import {keepOnly} from "@common/utils/objects";
import {NumberInput} from "../../../../../../../../../../common/form/number-input/number-input";

export const ExtractConfiguration = ({textManipulation, state, inputStep, model, form}) =>
    cs(() => {
        return (
            <div className="extract-configuration-0ew">
                <div className="config-group">
                    <div className="label">Input Column</div>

                    {(() => {
                        const dropdownProps = ffToDropdown(form.field(["textManipulation", "inputValue"]));
                        return rColumnValueSelectFromStepOutput({
                            label: "Column",
                            columns: inputStep.outputColumns,
                            model,
                            disableStaticValue: true,
                            filterColumnFn: (c) => ["Text", "IDUUID"].includes(c.dataType),
                            isValueNotAllowed: (c) => !["Text", "IDUUID"].includes(c.dataType),
                        })({
                            state: {
                                value: dropdownProps.value,
                                onChange: dropdownProps.onChange,
                            },
                            ...keepOnly(dropdownProps, ["domRef", "hasError", "errorMessage"]),
                        });
                    })()}
                </div>

                <div className="config-group">
                    <div className="label">Extract Details</div>

                    <div className="select-group">
                        <div className="label">Starting Position</div>
                        <div className="flex-group">
                            {(() => {
                                const dropdownProps = ffToDropdown(form.field(["textManipulation", "startingPosition"]));
                                return rColumnValueSelectFromStepOutput({
                                    label: "Column",
                                    columns: inputStep.outputColumns,
                                    model,
                                    disableSelectingTable: true,
                                    filterColumnFn: (c) => ["Int"].includes(c.dataType),
                                    isValueNotAllowed: (c) => !["Int"].includes(c.dataType),
                                    renderStaticInput: ({state}) =>
                                        NumberInput({
                                            state,
                                            label: "Value (starting with 1)",
                                        }),
                                })({
                                    state: {
                                        value: dropdownProps.value,
                                        onChange: dropdownProps.onChange,
                                    },
                                    ...keepOnly(dropdownProps, ["domRef", "hasError", "errorMessage"]),
                                });
                            })()}
                        </div>

                        <div className="label">Exact Length</div>
                        <div className="flex-group">
                            {(() => {
                                const dropdownProps = ffToDropdown(form.field(["textManipulation", "length"]));
                                return rColumnValueSelectFromStepOutput({
                                    label: "Length (Column/Value)",
                                    columns: inputStep.outputColumns,
                                    model,
                                    disableSelectingTable: true,
                                    filterColumnFn: (c) => ["Int"].includes(c.dataType),
                                    isValueNotAllowed: (c) => !["Int"].includes(c.dataType),
                                    renderStaticInput: ({state}) => NumberInput({state, label: "Value"}),
                                })({
                                    state: {
                                        value: dropdownProps.value,
                                        onChange: dropdownProps.onChange,
                                    },
                                    ...keepOnly(dropdownProps, ["domRef", "hasError", "errorMessage"]),
                                });
                            })()}
                        </div>
                    </div>
                </div>

                {TextManipulationResultActionSelect({
                    state: textManipulation,
                    form,
                })}
            </div>
        );
    });
