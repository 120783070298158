import {cs} from "@common/react/chain-services";
import {Static2} from "@common/react/static-2";
import {UseState} from "@common/react/use-state";
import {fragments} from "@common/react/fragments";
import {OnUnmounted} from "@common/react/on-unmounted";
import {Invoke} from "@common/react/invoke";
import {spc} from "@common/react/state-path-change";
import {LocalEvent} from "@common/react/local-event";
import {waitTimeout} from "@common/utils/wait-timeout";

export const Timer = ({next: rootNext, startedAt, startOnInit = true, resetTimerOnTimeout = false, defaultTimeout = 30 * 1000}) =>
    cs(
        ["intervalRef", (_, next) => Static2({next})],
        ["events", (_, next) => LocalEvent({next})],
        [
            "state",
            (_, next) =>
                UseState({
                    next,
                    initValue: {
                        start: startOnInit,
                        timer: defaultTimeout,
                        startedAt: startedAt ?? Date.now(),
                    },
                }),
        ],
        ({state, intervalRef, events}) => {
            const {start, timer, startedAt} = state.value;
            const stopTimer = () => {
                state.change(() => ({start: false}));
                intervalRef.get() && clearInterval(intervalRef.get());
            };

            return fragments(
                OnUnmounted({
                    action: stopTimer,
                }),
                start
                    ? Invoke({
                          fn: () => {
                              let startTime = startedAt ?? Date.now();
                              let endTime = startTime + defaultTimeout;
                              let interval = setInterval(() => {
                                  const diff = endTime - Date.now();

                                  spc(state, ["timer"], () => Math.max(diff, 0));
                                  if (diff <= 0) {
                                      clearInterval(interval);

                                      events.emit("timeout");

                                      if (resetTimerOnTimeout) {
                                          state.onChange({
                                              start: false,
                                              timer: defaultTimeout,
                                          });
                                      }
                                  }
                              }, 5);
                              intervalRef.set(interval);
                          },
                      })
                    : null,
                rootNext({
                    remaining: timer,
                    defaultTimeout,
                    isRunning: timer > 0 && start,
                    start: () => {
                        if (!start) {
                            state.onChange({
                                start: true,
                                timer: defaultTimeout,
                                startedAt: Date.now(),
                            });
                        }
                    },
                    stop: stopTimer,
                    reset: async (forceStart = false) => {
                        stopTimer();
                        await waitTimeout(1);
                        state.onChange({
                            start: forceStart,
                            timer: defaultTimeout,
                            startedAt: null,
                        });
                    },
                    ...events,
                })
            );
        }
    );
