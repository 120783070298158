import React from "react";
import {cs} from "@common/react/chain-services";
import "./or-config.scss";
import {AndConfig} from "./and-config";
import {keyed} from "@common/react/keyed";
import {getPath} from "@common/utils/arr-path";
import {cx} from "emotion";
import {spc} from "@common/react/state-path-change";
import {removeIndex} from "@common/utils/collections";

export const OrConfig = ({headerText, step, form, parentPath, path, onRemove, columns, model}) => {
    const orConfig = getPath(step.value, path);
    const isInside = path.filter((n) => n?.toString().includes("orConfigs")).length > 1;
    return (
        <div className="or-config-wsf">
            {headerText && <div className="header-text">{headerText}</div>}
            <div className={cx("inner-blocks-wrapper", {"is-inside": isInside})}>
                {(orConfig?.andConfigs || [{}])?.map((andConfig, aci) =>
                    cs(keyed(aci), ({}) =>
                        AndConfig({
                            step,
                            form,
                            parentPath,
                            path: [...path, "andConfigs!arr", aci],
                            onRemove: () => {
                                const arr = getPath(step.value, [...path, "andConfigs"]);
                                if (arr?.length > 0) {
                                    const newAndConfigs = removeIndex(aci, arr);
                                    if (newAndConfigs?.length > 0) {
                                        spc(step, [...path, "andConfigs"], () => newAndConfigs);
                                    } else {
                                        onRemove();
                                    }
                                }
                            },
                            columns,
                            model,
                        })
                    )
                )}
            </div>
        </div>
    );
};
