import "./environment-select.scss";

import React from "react";
import {cx} from "emotion";
import {cs} from "@common/react/chain-services";
import {Dropdown} from "@common/ui-components/dropdown/dropdown";
import {consumeContext} from "@common/react/context";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";
// import {Invoke} from "@common/react/invoke";

export const EnvironmentSelect = ({envId, list, onCreate}) =>
    cs(
        consumeContext("apis"),
        consumeContext("routing"),
        [
            "renderToggle",
            (_, next) =>
                next(({showExpand, showingExpand}) => {
                    const env = (list || []).find?.((l) => l.id === envId.value);
                    return (
                        <div className="toggle" onClick={() => showExpand(!showingExpand)}>
                            <div className="label">Environment</div>
                            <div className="value">
                                <div
                                    className={cx("status", {
                                        isProd: env?.isProd,
                                    })}
                                />
                                <div className="text">{env?.name || `Environment`}</div>
                            </div>

                            <div
                                className={cx("icons", {
                                    showingExpand: showingExpand,
                                })}
                            >
                                <div className="icon-up">
                                    <i className="fal fa-angle-up" />
                                </div>
                                <div className="icon-down">
                                    <i className="fal fa-angle-down" />
                                </div>
                            </div>
                        </div>
                    );
                }),
        ],
        [
            "renderExpand",
            ({routing}, next) =>
                next(({close}) => {
                    return (
                        <VerbScrollbar maxHeight={350} className="list">
                            {list.map((item, i) => (
                                <div
                                    className={cx("item", {
                                        selected: item.id === envId.value,
                                    })}
                                    key={i}
                                    onClick={() => {
                                        if (routing.routeName == "waiting-setup") {
                                            routing.goto("dashboard", {
                                                envId: item.id,
                                                // tab: "overview",
                                                tab: "collections",
                                            });
                                        } else {
                                            envId.onChange(item.id);
                                        }

                                        close();
                                    }}
                                >
                                    <div
                                        className={cx("status", {
                                            isProd: item.isProd,
                                        })}
                                    />
                                    <div className="text">{item.name}</div>
                                    {item.id === envId.value && <i className="far fa-check isSelected" />}
                                </div>
                            ))}

                            <div
                                className="addition"
                                onClick={() => {
                                    onCreate();
                                    close();
                                }}
                            >
                                <i className="fal fa-plus" />
                                Create New Environment
                            </div>
                        </VerbScrollbar>
                    );
                }),
        ],
        ({renderToggle, renderExpand}) => (
            <div className="environment-select-9io">
                {Dropdown({
                    renderToggle,
                    minExpandHeight: 300,
                    renderExpand,
                    expandDistance: 9,
                    // detectOnWheelEvent
                })}
            </div>
        )
    );
