const {upperCase1} = require("../../../../../../../utils/strings");

const initBooleanFilterState = (filter) => {
    return {
        value: filter.defaultValue !== null ? [upperCase1(filter.defaultValue.toString())] : [],
        columns: filter.columns,
        // columns: filter.columns.filter(c => c.default)
    };
};
exports.initBooleanFilterState = initBooleanFilterState;
