import React from "react";
import {cs} from "@common/react/chain-services";
import {keyed} from "@common/react/keyed";
import {Expandable} from "../../../../../common/expandable/expandable";
import {InputsMenu} from "./inputs-menu/inputs-menu";
import {TransformationsMenu} from "./transformations-menu/transformations-menu";
// import {TransformationType} from "./transformation-type/transformation-type";
import "./build-tab.scss";
import {StaticTooltipService} from "../../../../../common/tooltip3/static-tooltip-service";
import {isAggregatedMeasure, isDataView} from "../../../common/transformation-utils";
import {consumeContext} from "@common/react/context";
import {LoadingIndicator} from "@common/ui-components/loading-indicator/loading-indicator";

export const BuildTab = ({transformation, model, startDraggingNewStep, interactions, controlComp}) =>
    cs(consumeContext("routing"), ({routing}) => {
        const isAggregated = isAggregatedMeasure(transformation.value);

        const sections = [
            // AB#5771 remove the ability to change the transformation type
            // routing.params.modelTableId && {
            //     name: "Transformation Type",
            //     className: "transformation-type",
            //     render: TransformationType,
            // },
            {
                name: !isAggregated
                    ? "Inputs"
                    : cs(
                          [
                              "staticTooltip",
                              (_, next) =>
                                  StaticTooltipService({
                                      direction: "right",
                                      topOffset: 12,
                                      info: "Additional inputs are not available on aggregated measures.",
                                      next,
                                  }),
                          ],
                          ({staticTooltip}) => (
                              <span>
                                  Inputs
                                  {staticTooltip.renderIcon({
                                      className: "disabled-icon",
                                      icon: <img src={require("./disabled-icon.svg")} alt={""} />,
                                  })}
                              </span>
                          )
                      ),
                className: "inputs",
                initClose: isAggregated,
                render: InputsMenu,
            },
            {
                name: "Transformation Steps",
                className: "transformations",
                render: TransformationsMenu,
            },
        ].filter((v) => v);

        return (
            <div className="build-tab-53b">
                {!transformation.value ? (
                    LoadingIndicator({className: "loading-transformation-ad3"})
                ) : (
                    <>
                        {sections.map((section, i) =>
                            cs(keyed(i + (section.initClose ? "close" : "open")), ({}) =>
                                Expandable({
                                    className: section.className,
                                    initExpand: !section.initClose,
                                    label: section.name,
                                    render: () =>
                                        section.render({
                                            transformation,
                                            model,
                                            startDraggingNewStep,
                                            isAggregated,
                                            interactions,
                                        }),
                                })
                            )
                        )}

                        {controlComp && controlComp()}
                    </>
                )}
            </div>
        );
    });
