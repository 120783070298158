import * as React from "react";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {getPath} from "@common/utils/arr-path";
import {scope} from "@common/react/scope";
import {omit} from "@common/utils/objects";
import {cs} from "@common/react/chain-services";
import {DebounceNumber} from "../../../../../../../../../../../common/form/debounce-number";
import {NumberInput} from "../../../../../../../../../../../common/form/number-input/number-input";

export const AxisRangeSelect = (state) => (
    <>
        {DropdownSelect({
            label: "Range",
            list: [
                {label: "Auto (starting at 0)", value: "AutoFrom0"},
                {label: "Auto (based on data range)", value: "AutoFromData"},
                {label: "Custom Range", value: "Custom"},
            ],
            valueToLabel: (v) => v.label,
            isSelected: (v) => getPath(state.value, ["range"]) === v.value,
            onChange: (v) =>
                state.onChange({
                    ...(v.value !== "Custom" ? omit(state.value, ["rangeCustomMin", "rangeCustomMax"]) : state.value),
                    range: v.value,
                }),
        })}

        {getPath(state.value, ["range"]) === "Custom" && (
            <div className="dual-line">
                <div className="col1">
                    {cs(
                        [
                            "debounce",
                            (_, next) =>
                                DebounceNumber({
                                    state: scope(state, ["rangeCustomMin"]),
                                    next,
                                }),
                        ],
                        ({debounce}) =>
                            NumberInput({
                                label: "From",
                                state: debounce.state,
                                onBlur: debounce.flush,
                            })
                    )}
                </div>
                <div className="col2">
                    {cs(
                        [
                            "debounce",
                            (_, next) =>
                                DebounceNumber({
                                    state: scope(state, ["rangeCustomMax"]),
                                    next,
                                }),
                        ],
                        ({debounce}) =>
                            NumberInput({
                                label: "To",
                                state: debounce.state,
                                onBlur: debounce.flush,
                            })
                    )}
                </div>
            </div>
        )}
    </>
);
