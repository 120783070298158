import "./layout-2.scss";
import {css, cx} from "emotion";
import * as React from "react";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {SuspendUpdate} from "@common/react/suspend-update";
import {Button} from "@common/form/buttons/button/button";
import {Badge} from "../badge/badge";
import {TruncatingTooltip} from "../truncating-tooltip/truncating-tooltip";

export const SyncState = ({state}) => {
    return state?.type === "saved" ? (
        Badge({
            size: "lg",
            icon: <i className="far fa-check" />,
            type: "success",
            label: "Saved",
        })
    ) : (
        <div className={cx("save-status")} onClick={state?.onClick || undefined}>
            {state?.type === "saving" ? (
                <>
                    <i className="fa fa-spinner fa-pulse" />
                    Saving
                </>
            ) : state?.type === "unsaved" ? (
                <>
                    <img className="alert" src={require("./icons/alert-exclamation-icon.svg")} alt="" />
                    Unsaved changes
                </>
            ) : state?.type === "validating" ? (
                <>
                    <i className="fa fa-spinner fa-pulse" />
                    Validating
                </>
            ) : null}
        </div>
    );
};

export const RouteTransition = ({className, children} = {}) => {
    return cs(consumeContext("routeTransitioning"), ({routeTransitioning}) => (
        <div
            className={cx(
                className,
                routeTransitioning?.transitioning && {
                    transitioning: true,
                    [routeTransitioning.entering ? "entering" : "exiting"]: true,
                    ["phase-" + routeTransitioning.phase]: true,
                }
            )}
        >
            {children}
        </div>
    ));
};

export const LayoutContent = ({main, leftPanel, rightPanel}) =>
    cs(consumeContext("leftPanelWidth"), ({leftPanelWidth}) => (
        <div className="center">
            {leftPanel && (
                <div className="left-panel" style={{width: leftPanel.width || leftPanelWidth?.value || 320}}>
                    {leftPanel.content}
                </div>
            )}

            <div className="content">{main}</div>

            {rightPanel && (
                <div
                    className={cx("right-panel right-panel-88o", {
                        active: rightPanel.content,
                        "overlap-header": rightPanel?.overlapHeader,
                    })}
                    style={{
                        // flexBasis: rightPanel.width,
                        // marginRight: !rightPanel.content ? rightPanel.width*(-1) : 0
                        width: rightPanel.width,
                        right: !rightPanel.content ? rightPanel.width * -1 : 0,
                    }}
                >
                    {cs(
                        ({}, next) =>
                            SuspendUpdate({
                                active: !rightPanel.content,
                                delay: 500,
                                next,
                            }),
                        () => rightPanel.content
                    )}
                </div>
            )}
        </div>
    ));

export const Layout2 = ({back, labelText, leftPanel, main, syncState, historyControl, rightPanel, onPublish, className, editing = null, adding = null}) => {
    return cs(["truncatingTooltip", (_, next) => TruncatingTooltip({direction: "below", next})], ({truncatingTooltip}) => (
        <RouteTransition className={cx("layout-2-2su", className)}>
            <div className="header">
                <div className="left">
                    {editing && (
                        <div className="mode">
                            <div className="text">Edit Mode</div>
                            <div className="label" ref={truncatingTooltip.ref}>
                                <span {...truncatingTooltip?.tooltip?.(editing.label)}>{editing.label}</span>
                            </div>
                        </div>
                    )}

                    {adding && (
                        <div className="back-button">
                            <div className="arrow-btn" onClick={back.onClick}>
                                <i className="far fa-arrow-left" />
                            </div>
                            {adding.label}
                        </div>
                    )}

                    {historyControl && (
                        <>
                            <div
                                className={cx("undo-button", {
                                    disabled: !historyControl?.goBack,
                                })}
                            >
                                {!historyControl?.goBack ? <img src={require("./icons/undo-disabled-icon.svg")} alt="" /> : <img src={require("./icons/undo-icon.svg")} alt="" />}
                            </div>
                            <div
                                className={cx("redo-button", {
                                    disabled: !historyControl?.goForward,
                                })}
                            >
                                {!historyControl?.goForward ? (
                                    <img src={require("./icons/redo-disabled-icon.svg")} alt="" />
                                ) : (
                                    <img src={require("./icons/redo-icon.svg")} alt="" />
                                )}
                            </div>
                        </>
                    )}

                    {labelText && <div className="label-text">{labelText}</div>}
                </div>

                <div
                    className={cx("right", {
                        overlap: rightPanel?.overlapHeader && rightPanel.content,
                    })}
                >
                    {editing && (
                        <>
                            <SyncState state={syncState} />

                            <div className="group-buttons">
                                {onPublish && (
                                    <Button btnType="secondary" size="medium" className="btn btn-publish" onClick={onPublish}>
                                        Publish
                                    </Button>
                                )}

                                <Button btnType="primary" size="medium" className="btn" onClick={back.onClick}>
                                    Done
                                </Button>
                            </div>
                        </>
                    )}

                    {adding?.button && (
                        <div className="group-buttons adding">
                            <Button className="btn" onClick={adding.button.click}>
                                {adding.button.label}
                            </Button>
                        </div>
                    )}
                </div>
            </div>

            <LayoutContent
                {...{
                    main,
                    leftPanel,
                    rightPanel,
                }}
            />
        </RouteTransition>
    ));
};
