const {getFieldType} = require("../common/get-field-type");
const {getDateTimeFormatter} = require("../factory/formatters/get-date-time-formatter");
const {roundTo} = require("../common/formatters/numeric/round-to");
const {chain} = require("../../../utils/fs");
const {createNumericFormatter} = require("../common/formatters/numeric/numeric-formatter");
const {currencies} = require("../factory/formatters/currencies");

const getValueFormatter = ({valueField, groupField, timezone, style, tile}) => {
    const groupFieldFormatter = groupField && getFieldType(groupField) === "date" ? getDateTimeFormatter(groupField.dateProperties, timezone) : (v) => v;

    const numericProperties = {
        ...valueField.numericProperties,
        currencyOverrides: tile?.currencyOverrides,
    };

    const formatProps = {
        decimalSeperator: numericProperties?.decimalSeperator || "Dot",
        thousandsSeperator: numericProperties?.thousandsSeperator || "Comma",
        decimalPlaces: valueField.aggregationFunc.startsWith("Count") ? 0 : numericProperties?.decimalPlaces ?? 2,
        negativeFormat: numericProperties?.negativeFormat || "Parentheses",
    };

    const currencyOverrides = numericProperties?.currencyOverrides
        ? {
              currency: numericProperties?.currencyOverrides.displayIso,
              currencySymbol: currencies.find((c) => c.abbreviation === numericProperties?.currencyOverrides.displayIso)?.symbol,
          }
        : null;

    const valueFormatter = createNumericFormatter(
        {
            displayType: numericProperties?.displayType || "Number",
            ...formatProps,
            currencyProperties: {
                ...(currencyOverrides
                    ? currencyOverrides
                    : {
                          currency: numericProperties?.currencyProperties?.currency || "USD",
                          currencySymbol: numericProperties?.currencyProperties?.currencySymbol || "$",
                      }),
                currencySymbolPlacement: numericProperties?.currencyProperties?.currencySymbolPlacement || "Left",
                spaceBetweenSymbolAndNumber: numericProperties?.currencyProperties?.spaceBetweenSymbolAndNumber,
            },
        },
        {autoRound: style.autoRound}
    );

    const percentageFormatter = createNumericFormatter({
        displayType: "Percentage",
        ...formatProps,
    });

    return {
        valueFormatter: (v) =>
            v != null &&
            chain(
                v,
                (v) => (numericProperties?.displayType === "Percentage" ? v * 100 : v),
                (v) => (numericProperties?.decimalPlaces != null ? roundTo(v, numericProperties.decimalPlaces) : roundTo(v, 2)),
                (v) => valueFormatter(v)
            ),
        groupFieldFormatter,
        percentFormatter: (v) =>
            v != null &&
            chain(
                v,
                (v) => (numericProperties?.decimalPlaces != null ? roundTo(v * 100, numericProperties.decimalPlaces) : roundTo(v * 100, 2)),
                (v) => percentageFormatter(v)
            ),
    };
};
exports.getValueFormatter = getValueFormatter;
