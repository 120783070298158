import React from "react";

import {Load} from "@common/react/load";
import {Invoke} from "@common/react/invoke";
import {cs} from "@common/react/chain-services";
import {fragments} from "@common/react/fragments";
import {ChangeQueue1} from "@common/react/change-queue-1";
import {consumeContext, provideContext} from "@common/react/context";

import {waitTimeout} from "@common/utils/wait-timeout";
import {loadAutoRefreshProvider} from "@common/ui-components/live/live-dashboard/live-grid/auto-refresh-controller";
import {CollectionFilterProvider} from "@common/ui-components/live/live-dashboard/live-dashboard";

import {ApiCollectionMain} from "../../api-collection/edit/main/api-collection-main";
import {ApiCollectionTabs} from "../../api-collection/edit/tabs/api-collection-tabs";
import {EditApiCollectionLayout} from "../../api-collection/edit/layout/edit-api-collection-layout";
import {ContextualCollectionTabs} from "../../contextual-collection/edit/tabs/contextual-collection-tabs";
import {CreateThemeDialog} from "../../dashboard/env/themes/create-theme-dialog";
import {MoveCollectionDialog} from "../../dashboard/env/collections/collection-cm/move-collection/move-collection-dialog";
import {RenameCollectionDialog} from "../../dashboard/env/collections/collection-cm/rename-collection/rename-collection-dialog";
import {DeleteCollectionDialog} from "../../dashboard/env/collections/collection-cm/delete-collection/delete-collection-dialog";
import {SelectModelForCloningCollectionDialog} from "../../dashboard/env/collections/collection-cm/clone-collection/select-model-for-cloning-collection-dialog";
import {ToastWithAction} from "../../common/toast/toast-with-action";
import {SingleEditorRegister} from "../../common/single-editor/single-editor";

import {Tabs} from "./tabs/tabs";
import {loadViewAsContext} from "./layout/view-as-context";
import {EditCollectionLayout, RefreshIcon} from "./layout/edit-collection-layout";
import {createAutoSaveCollection} from "./common/auto-save-collection";
import {CollectionThemeSelect} from "./layout/theme-select/collection-theme-select";
import {SettingIcon} from "./layout/theme-select/theme-select-icons";
import {loadDataSummary} from "../../dashboard/common/load-data-summary";
import {resolveCurrentIntervalConfig, SelectInterval} from "./layout/client-refresh-interval/client-refresh-interval";
import {StyledClass} from "@common/react/styled-class";
import {Load2} from "@common/react/load2";
import {loadSharedTileFolders} from "../../dashboard/common/load-shared-tile-folders";

export const EditCollection = ({environment}) =>
    cs(
        consumeContext("apis"),
        consumeContext("auth"),
        consumeContext("routing"),
        consumeContext("collection"),
        consumeContext("toast"),
        consumeContext("dataSummary"),
        (_, next) => loadAutoRefreshProvider({next}),
        (_, next) => loadViewAsContext({next}),
        (_, next) => loadDataSummary({next}),
        ({collection}, next) => loadSharedTileFolders({next, modelID: collection.value?.modelID}),
        ({routing, collection}, next) =>
            fragments(
                collection?.value
                    ? SingleEditorRegister({
                          goBack: () => routing.goto("dashboard", {tab: "collections"}),
                          getItemId: () => routing.params.colId,
                          otherButtons: [
                              {
                                  label: "Go to Preview",
                                  function: () => {
                                      // routing.goto("dashboard", {tab: "collections"});
                                      window.open(`/#/env/${routing.params.envId}/collection/${routing.params.colId}/preview`, "_blank");
                                  },
                              },
                          ],
                          item: {
                              type: "collection",
                              parent: "Collections",
                              name: collection?.value.name,
                              id: collection?.value.id,
                          },
                      })
                    : null,
                next()
            ),
        (_, next) => CollectionFilterProvider({next}),
        [
            "savingQueue",
            ({apis, collection}, next) =>
                ChangeQueue1({
                    save: apis.collection.upsertCollection,
                    fetchLatest: () => apis.collection.getCollection(collection.value.id),
                    next,
                }),
        ],
        ["collectionToast", (_, next) => ToastWithAction({next})],
        [
            "collectionToastCss",
            ({}, next) =>
                StyledClass({
                    content: {
                        width: "24px",
                        height: "24px",
                        path: {
                            fill: "#11a1fd",
                        },
                    },
                    next,
                }),
        ],
        ({collection, collectionToast, dataSummary, savingQueue, collectionToastCss}, next) =>
            fragments(
                collection?.value && dataSummary?.dataSources
                    ? Invoke({
                          fn: async () => {
                              const {$type, autoDataRefresh} = collection.value;

                              if (["DashboardCollection", "SparkCollection"].includes($type) && !dataSummary.isLessThan1GB && autoDataRefresh == null) {
                                  const newCollection = await savingQueue.push((collection) => ({
                                      ...collection,
                                      autoDataRefresh: false,
                                  }));

                                  collection.change(() => newCollection);

                                  collectionToast.show({
                                      hasClose: true,
                                      customIcon: <RefreshIcon className={collectionToastCss} />,
                                      text: (
                                          <>
                                              <b>"Auto Data Refresh"</b> disabled to <br />
                                              optimize performance. View <br />
                                              Configuration in <SettingIcon /> <b>Settings</b>.
                                          </>
                                      ),
                                  });
                              }
                          },
                      })
                    : null,
                next()
            ),
        [
            "themes",
            ({apis}, next) =>
                Load({
                    fetch: () => apis.collection.getThemes(),
                    next,
                }),
        ],
        ({collectionToast}, next) => provideContext("collectionToast", collectionToast, next),
        [
            "renameCollectionDialog",
            ({collection}, next) =>
                RenameCollectionDialog({
                    next,
                    onDone: () => collection.reload(),
                }),
        ],
        [
            "selectModelForCloningDialog",
            ({collectionToast, routing}, next) =>
                SelectModelForCloningCollectionDialog({
                    onDone: (newCollection) => {
                        collectionToast.show({
                            text: "Collection successfully cloned",
                            action: {
                                text: "Go to New Collection",
                                onClick: () =>
                                    routing.goto("edit-collection", {
                                        colId: newCollection.id,
                                    }),
                            },
                        });
                    },
                    next,
                }),
        ],
        [
            "moveCollectionDialog",
            (_, next) =>
                MoveCollectionDialog({
                    onDone: () => {},
                    next,
                }),
        ],
        ["createThemeService", (_, next) => CreateThemeDialog({next})],
        [
            "deleteCollectionDialog",
            ({routing, toast, auth}, next) =>
                DeleteCollectionDialog({
                    onDone: async () => {
                        const envId = routing.params.envId;
                        routing.goto("dashboard", {
                            envId,
                            // tab: ["Full", "Verb"].includes(auth.user.roles[0]) ? "overview" : "collections",
                            tab: "collections",
                        });
                    },
                    next,
                }),
        ],
        [
            "collectionCommands",
            ({renameCollectionDialog, moveCollectionDialog, deleteCollectionDialog, selectModelForCloningDialog, collection, themes, savingQueue, createThemeService}, next) => {
                const autoSaveCollection = createAutoSaveCollection({
                    collection,
                    savingQueue,
                });
                return next([
                    {
                        label: "Rename",
                        onClick: () =>
                            renameCollectionDialog.show({
                                collection: collection.value,
                            }),
                        id: "rename",
                    },
                    {
                        label: "Clone",
                        onClick: () =>
                            selectModelForCloningDialog.show({
                                collection: collection.value,
                            }),
                        id: "clone",
                    },
                    {
                        label: "Move",
                        onClick: () =>
                            moveCollectionDialog.show({
                                collection: collection.value,
                            }),
                        id: "move",
                    },
                    {
                        label: "Embed Refresh Interval",
                        subLabel: resolveCurrentIntervalConfig(collection.value?.clientRefreshIntervalMins),
                        renderChildComponent: (onClose) =>
                            SelectInterval({
                                collection: autoSaveCollection,
                                onClose,
                            }),
                        id: "move",
                    },
                    {
                        label: "Collection Theme",
                        subLabel: (themes || []).find((t) => t.id == collection.value?.themeID)?.name,
                        renderChildComponent: (onClose) =>
                            CollectionThemeSelect({
                                collection: autoSaveCollection,
                                themes,
                                onClose,
                                createThemeService,
                            }),
                        id: "theme",
                    },
                    {
                        label: "Delete Collection",
                        className: "delete-action",
                        onClick: () =>
                            deleteCollectionDialog.show({
                                collections: [collection.value],
                            }),
                        id: "delete",
                    },
                ]);
            },
        ],
        [
            "collectionTypes",
            ({savingQueue, apis, collection, routing, collectionCommands}, next) =>
                next({
                    ApiCollection: {
                        tabs: ({savingQueue, next}) =>
                            ApiCollectionTabs({
                                savingQueue,
                                next,
                                collection: createAutoSaveCollection({
                                    collection,
                                    savingQueue,
                                }),
                            }),
                        layout: ({tabs}) =>
                            EditApiCollectionLayout({
                                leftPanelTabs: tabs.tabs,
                                selectedTab: tabs.selectedTab,
                                panelOverride: tabs.panelOverride,
                                main: ApiCollectionMain({
                                    collection,
                                    saving: savingQueue.executing,
                                }),
                                onBack: () => routing.goto("dashboard", {tab: "fields"}),
                                saving: savingQueue.executing,
                                collection: createAutoSaveCollection({
                                    collection,
                                    savingQueue,
                                }),
                                collectionCommands: collectionCommands.filter((c) => c.id != "theme"),
                            }),
                    },
                    DashboardCollection: {
                        tabs: ({savingQueue, next}) => Tabs({savingQueue, next}),
                        layout: ({tabs}) =>
                            EditCollectionLayout({
                                environment,
                                collection: createAutoSaveCollection({
                                    collection,
                                    savingQueue,
                                }),
                                labelText: collection.value?.name,
                                leftPanelTabs: tabs.tabs,
                                selectedTab: tabs.selectedTab,
                                leftPanelOverride: tabs.leftPanelOverride,
                                main: tabs.renderMain?.(),
                                saving: savingQueue.executing,
                                onBack: () => routing.goto("dashboard", {tab: "tiles"}),
                                collectionCommands,
                            }),
                    },
                    SparkCollection: {
                        tabs: ({savingQueue, next}) => ContextualCollectionTabs({savingQueue, next}),
                        layout: ({tabs}) =>
                            EditCollectionLayout({
                                collection: createAutoSaveCollection({
                                    collection,
                                    savingQueue,
                                }),
                                labelText: collection.value?.name,
                                leftPanelTabs: tabs.tabs,
                                selectedTab: tabs.selectedTab,
                                leftPanelOverride: tabs.leftPanelOverride,
                                main: tabs.renderMain?.(),
                                saving: savingQueue.executing,
                                savingQueue,
                                onBack: () => routing.goto("dashboard", {tab: "tiles"}),
                                collectionCommands,
                            }),
                    },
                }),
        ],

        [
            "tabs",
            ({collection, collectionTypes, savingQueue}, next) =>
                collection.value
                    ? collectionTypes[collection.value.$type].tabs({
                          savingQueue,
                          next,
                      })
                    : null,
        ],

        [
            "CollectionLayout",
            ({collection, collectionTypes, tabs}, next) => {
                return next(
                    collection.value ? (
                        <>
                            {collectionTypes[collection.value.$type].layout({
                                tabs,
                            })}
                            <div id="dragging-portal" />
                        </>
                    ) : null
                );
            },
        ],

        ({CollectionLayout}) => CollectionLayout
    );
