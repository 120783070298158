import React from "react";
import {cs} from "@common/react/chain-services";
import "./response-mapping.scss";
import {TitleInfo} from "./title-info";
import {KeyValueInput} from "../../../../../../../common/form/key-value-input/key-value-input";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";

export const ResponseMapping = ({authEndpoint, form, disableEditing}) =>
    cs(({}) => {
        const field = form.field("resultMapping");
        return (
            <div className="response-mapping-13y">
                {TitleInfo({
                    title: "Response Mapping",
                    infoName: "response-mapping",
                })}
                {KeyValueInput({
                    disableEditing,
                    state: field.state,
                    hasError: field.error,
                    domRef: field.domRef,
                    labels: {
                        key: "User Mapping",
                        value: "Property",
                    },
                    feedValues: {
                        key: "User.",
                    },
                    objectMapping: {
                        value: "property",
                        // mappingType: "mappingType"
                    },
                    switchCols: true,
                    confirmDelete: true,
                    otherFields: [
                        {
                            label: "Property Type",
                            field: (key, state) =>
                                DropdownSelect({
                                    disabled: disableEditing,
                                    list: [
                                        {
                                            label: "Single Value",
                                            value: "SingleValue",
                                        },
                                        {
                                            label: "Value Array",
                                            value: "ValueArray",
                                        },
                                    ],
                                    placeholder: "Type",
                                    valueToLabel: (v) => v.label,
                                    isSelected: (v) => v.value === state.value?.[key]?.mappingType,
                                    onChange: (v) =>
                                        state.change((s) => ({
                                            ...s,
                                            [key]: {
                                                ...(state.value?.[key] || {}),
                                                mappingType: v.value,
                                            },
                                        })),
                                }),
                        },
                    ],
                })}
            </div>
        );
    });
