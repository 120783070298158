const {adjustGaugeMargin} = require("./adjust-gauge-margin");
const {adjustMinMaxTickLabels} = require("./adjust-min-max-tick-labels");
const {writeCenterLabel} = require("./write-center-label");
const {adjustValueLabels} = require("./adjust-value-labels");
const {seriesOptions} = require("./series-options");
const {paneOptions} = require("./pane-options");
const {yAxisOptions} = require("./y-axis-options");
const {getLegendOptions} = require("../../factory/legend");
const {drawBorder} = require("./draw-border");
const {getTooltipOptions} = require("../../factory/tooltip/tooltip-options");
const {isCompareEnabled} = require("./gauge-helpers");
const {getLegendRoom} = require("../../render-chart/render-chart-helper");

const getGaugeChartOptions = ({tile, data, theme, formatters, innerSize, tileSize}) => {
    const chartEventsFn = ({chart, style, theme, data, formatters}) => {
        adjustValueLabels({chart});
        writeCenterLabel({chart, tile, style, theme, data, formatters});
        style.minMaxLabelsShown && adjustMinMaxTickLabels({chart});
        drawBorder({
            chart,
            style,
            theme,
            showMiddleBorder: isCompareEnabled({style, data}),
        });
    };

    const chart = {
        type: "solidgauge",
        backgroundColor: theme.general.tile.styles.tileBackgroundColorRGB,
        // borderColor: "red",
        // borderWidth: 1,
        // marginTop: 0,
        ...(tile.style.arcSize === "Deg180" && {
            // margin: [0, 0, 0, 0],
            // spacing: [0, 0, 0, 0],
            marginTop: 0,
            marginBottom: 0,
            spacingTop: 0,
            spacingBottom: 0,
        }),
        events: {
            load: function () {
                chartEventsFn({
                    chart: this,
                    style: tile.style,
                    theme,
                    data,
                    formatters,
                });
                tile.style.valueLabelsShown && adjustGaugeMargin({chart: this});
            },
            redraw: function () {
                chartEventsFn({
                    chart: this,
                    style: tile.style,
                    theme,
                    data,
                    formatters,
                });
            },
        },
    };

    const title = {
        text: undefined,
    };

    const {showCompare, comparisonGaugeShown, previousPeriodShown, compareValueShown, compareIndicatorShown} = tile.style;

    const tooltip = getTooltipOptions({
        tile,
        theme,
        formatters,
        rawData: data,
        isCompare: showCompare && data.range && data.previousRange && (comparisonGaugeShown || previousPeriodShown || compareValueShown || compareIndicatorShown),
    });

    const plotOptions = {
        solidgauge: {
            dataLabels: {
                enabled: false,
            },
            borderWidth: 0,
        },
    };

    const legend = getLegendOptions({style: tile.style.legendStyle, theme});

    const defaultOptions = {
        chart,
        title,
        ...yAxisOptions({tile, theme, data, formatters}),
        ...seriesOptions({tile, data, theme}),
        plotOptions,
        legend,
        tooltip,
        credits: {
            enabled: false,
        },
    };

    const legendRoom = getLegendRoom({
        legend,
        series: defaultOptions.series,
        size: tileSize,
        chartHeight: innerSize.height,
        chartWidth: innerSize.width,
        isCompare: isCompareEnabled({style: tile.style, data}),
        formatters,
        options: defaultOptions,
        tile,
    });

    return {
        ...defaultOptions,
        ...paneOptions({
            style: tile.style,
            theme,
            data,
            innerSize: !legendRoom
                ? innerSize
                : {
                      width: innerSize.width - (legendRoom.paddingRight || 0) - (legendRoom.paddingLeft || 0),
                      height: innerSize.height - (legendRoom.paddingTop || 0) - (legendRoom.paddingBottom || 0),
                  },
        }),
    };
};
exports.getGaugeChartOptions = getGaugeChartOptions;
