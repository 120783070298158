import "./force-sync-dialog.scss";

import React from "react";
import {cx} from "emotion";
import {cs} from "@common/react/chain-services";
import {DialogService} from "../../../common/dialog/dialog-service";
import {SyncMethodTypesToValue} from "../method-list-config";
import {UseState} from "@common/react/use-state";
import {unique} from "@common/utils/collections";
import {keyed} from "@common/react/keyed";
import {DataTable} from "../../../common/data-table/data-table";
import {consumeContext} from "@common/react/context";
import {Invoke} from "@common/react/invoke";
import {Button} from "../../../../../../common/form/buttons/button/button";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";
import {noun} from "@common/utils/plural";

export const ForceSyncDialog = ({next: rootNext}) =>
    cs(
        consumeContext("apis"),
        consumeContext("toast"),
        consumeContext("syncingProcess"),
        ["modalTitle", (_, next) => UseState({next, initValue: "Force Full Sync"})],
        [
            "modal",
            ({modalTitle}, next) =>
                DialogService({
                    render: ({resolve, args: {tables, dsid, isForceFull = false}}) => {
                        return {
                            title: modalTitle.value,
                            width: 540,
                            content: next({
                                resolve,
                                tables,
                                dsid,
                                isSingleTable: tables.length === 1,
                                isForceFull,
                            }),
                        };
                    },
                    next: rootNext,
                }),
        ],

        ["isLoading", (_, next) => UseState({next, initValue: false})],
        ["syncingTables", (_, next) => UseState({next, initValue: []})],
        [
            "submitForceSyncTables",
            ({modal, apis, toast}, next) =>
                next(async (tableIDs) => {
                    const {dsid, resolve, tables, isForceFull} = modal;

                    const message =
                        tableIDs.length === 1 ? tables.find((t) => t.id === tableIDs[0].dataSourceTableID)?.name : `${tableIDs.length} ${noun(tableIDs.length, "table")}`;

                    try {
                        await apis.data.forceSync(dsid, tableIDs);
                        toast?.show(`Force ${isForceFull ? `Full` : ``} Sync started on ${message}`);
                        resolve(true);
                    } catch (e) {
                        //console.log(e);
                    }
                }),
        ],

        // (({modal, isLoading, submitForceSyncTables, apis, syncingTables, modalTitle, syncingProcess}, next) => {
        //     const { syncingProcessObserver } = syncingProcess;
        //     if (isLoading.value) {
        //         return (
        //             <div className={cx("force-sync-dialog-f03")}>
        //                 {/* <div className="progress-wrapper">
        //                     <div className="progress"/>
        //                 </div> */}
        //
        //                 <VerbDialogBodyScrollbar >
        //                     Checking...
        //                 </VerbDialogBodyScrollbar>
        //                 {Invoke({
        //                     fn: async () => {
        //                         try {
        //                             // const tableIDs = await apis.data.getTableSyncing(modal.dsid);
        //                             //
        //                             // syncingTables.onChange(tableIDs);
        //                             isLoading.onChange(false);
        //
        //                             if (modal.isSingleTable && (syncingProcessObserver?.tablesSyncing || []).includes(modal.tables[0].id)) {
        //                                 modalTitle.onChange('Cannot Force Sync');
        //                             } else {
        //                                 modalTitle.onChange('Force Full Sync');
        //                             }
        //                         } catch (e) {
        //                             //console.log(e);
        //                         }
        //                     }
        //                 })}
        //             </div>
        //         )
        //     }
        //
        //     return next();
        // }),

        ({modal, syncingTables, submitForceSyncTables, syncingProcess}) => {
            const {syncingProcessObserver} = syncingProcess;
            const {tables, isSingleTable, isForceFull, resolve} = modal;

            const onForceSync = () => {
                const availableToSyncTables = tables.filter((t) => !(syncingProcessObserver.tablesSyncing || []).includes(t.id));

                submitForceSyncTables(
                    availableToSyncTables.map((t) => ({
                        dataSourceTableID: t.id,
                        full:
                            tables.filter((t) => t.syncMethod === SyncMethodTypesToValue.Full).length === tables.length ||
                            t.syncMethod === SyncMethodTypesToValue.Full ||
                            isForceFull,
                    }))
                );
            };

            return (
                <div className={cx("force-sync-dialog-f03")}>
                    <div className="dialog-body">
                        <div>Force Full Sync will cause the selected tables to be completely refreshed as if you had full sync set for the sync method.</div>
                        <br />
                        <div>If there is a lot of data in the tables being force synced, it could incur higher charges.</div>
                    </div>

                    <div className="buttons">
                        <Button btnType="secondary" onClick={() => resolve(null)}>
                            Cancel
                        </Button>
                        <Button onClick={() => onForceSync()}>Force Full Sync</Button>
                    </div>
                </div>
            );

            // if (isSingleTable) {
            //     return ForceSyncOneTable({
            //         table: tables[0],
            //         cannotForceSync: syncingTables.value.includes(tables[0].id),
            //         isForceFull,
            //         onSubmit: submitForceSyncTables,
            //         onDismiss: resolve,
            //     });
            // }

            // return ForceSyncManyTables({
            //     tables,
            //     syncingTables: syncingTables.value,
            //     onSubmit: submitForceSyncTables,
            //     onDismiss: resolve,
            // });
        }
    );

const ForceSyncOneTable = ({table, isForceFull = false, cannotForceSync = false, onSubmit, onDismiss}) =>
    cs(
        [
            "getSyncMessage",
            (_, next) =>
                next(() => {
                    const {syncMethod, syncSchedule} = table;
                    if (syncSchedule.$type == "OneTimeSync") {
                        return "Forcing a new sync will only sync this table once. If you would like to set an recurring sync interval please update that in the interval column.";
                    }

                    if (syncMethod == SyncMethodTypesToValue.Full || isForceFull) {
                        return "Forcing a full sync update all of the data in this table. Do you want to force a full sync?";
                    }

                    return `Forcing a new sync now will reset the sync interval time when this sync completes. The next scheduled sync will continue ${syncSchedule.every} ${syncSchedule.interval} after this sync completes.`;
                }),
        ],
        ({getSyncMessage}) => {
            if (cannotForceSync) {
                return (
                    <div className={cx("force-sync-dialog-f03")}>
                        <VerbDialogBodyScrollbar>
                            This table data is currently syncing. Please wait for the sync complete before forcing a new sync. You can also pause this interval so that you can
                            force the sync once the current sync complete.
                        </VerbDialogBodyScrollbar>

                        <div className="buttons">
                            <Button onClick={() => onDismiss()}>Okay</Button>
                        </div>
                    </div>
                );
            }

            const onClick = () => {
                onSubmit([
                    {
                        dataSourceTableID: table.id,
                        full: table.syncMethod == SyncMethodTypesToValue.Full || isForceFull,
                    },
                ]);
            };

            return (
                <div className={cx("force-sync-dialog-f03")}>
                    <VerbDialogBodyScrollbar>{getSyncMessage(table)}</VerbDialogBodyScrollbar>

                    <div className="buttons">
                        <Button btnType="secondary" onClick={() => onDismiss()}>
                            Cancel
                        </Button>
                        <Button onClick={onClick}>Sync Now</Button>
                    </div>
                </div>
            );
        }
    );

const ForceSyncManyTables = ({tables, syncingTables, onSubmit, onDismiss}) => {
    const isSyncingTables = tables.filter((t) => syncingTables.includes(t.id));
    const fullSyncMethodTables = tables.filter((t) => t.syncMethod == SyncMethodTypesToValue.Full);
    const availableToSyncTables = tables.filter((t) => isSyncingTables.findIndex((t2) => t2.id == t.id) < 0);
    const currentSyncMethods = unique(tables.map((t) => t.syncMethod));

    const isAllFullSync = fullSyncMethodTables.length == tables.length;
    const isSomeCannotSync = isSyncingTables.length > 0 && isSyncingTables.length < tables.length;
    const isAllCannotSync = isSyncingTables.length == tables.length;

    const message = (() => {
        if (isAllFullSync) {
            return "Forcing a full sync update all of the data in these tables. Do you want to force a full sync on all selected tables?";
        }

        if (isSomeCannotSync) {
            return "All of the selected tables are currently syncing and you may not sync. Please wait until the current sync is complete to force a new sync";
        }

        if (isAllCannotSync) {
            return "Some of the selected tables are currently syncing and you may force sync. To continue, we will deselect these tables so that you force the sync on other tables.";
        }

        return "";
    })();

    return cs(
        ["isShowMessage", (_, next) => UseState({next, initValue: message.length > 0})],
        ["isForceFullSync", (_, next) => UseState({next, initValue: undefined})],
        [
            "doSyncTables",
            ({isForceFullSync}, next) =>
                next(async () => {
                    onSubmit(
                        availableToSyncTables.map((t) => ({
                            dataSourceTableID: t.id,
                            full: isAllFullSync || t.syncMethod == SyncMethodTypesToValue.Full || isForceFullSync.value,
                        }))
                    );
                }),
        ],
        [
            "forceSyncActions",
            ({isForceFullSync}, next) =>
                next([
                    {
                        name: "Start Full Sync for All Tables",
                        description: "Forcing a full sync update all of the data in these tables. Do you want to force a full sync on all selected tables?",
                        isSelected: isForceFullSync.value === true,
                        onClick: () => isForceFullSync.onChange(true),
                    },
                    {
                        name: `Start ${currentSyncMethods.length > 1 ? "Selected" : currentSyncMethods[0]} Sync for All Tables`,
                        description: "Forcing a full sync update all of the data in these tables. Do you want to force a full sync on all selected tables?",
                        isSelected: isForceFullSync.value === false,
                        onClick: () => isForceFullSync.onChange(false),
                    },
                ]),
        ],
        ({doSyncTables, isShowMessage, forceSyncActions, isForceFullSync}) => {
            const syncBtnText = `Sync ${availableToSyncTables.length} Table${availableToSyncTables.length == 1 ? "" : "s"}`;

            const syncBtn = <Button onClick={doSyncTables}>{syncBtnText}</Button>;

            if (isAllFullSync) {
                return (
                    <div className={cx("force-sync-dialog-f03")}>
                        <VerbDialogBodyScrollbar>{message}</VerbDialogBodyScrollbar>

                        <div className="buttons">
                            <Button btnType="secondary" onClick={() => onDismiss()}>
                                Cancel
                            </Button>
                            {syncBtn}
                        </div>
                    </div>
                );
            }

            if (isAllCannotSync) {
                return (
                    <div className={cx("force-sync-dialog-f03")}>
                        <VerbDialogBodyScrollbar>{message}</VerbDialogBodyScrollbar>

                        <div className="buttons">
                            <Button onClick={() => onDismiss()}>Okay</Button>
                        </div>
                    </div>
                );
            }

            return isShowMessage.value ? (
                <div className={cx("force-sync-dialog-f03")}>
                    <VerbDialogBodyScrollbar>
                        {message}
                        <br />
                        <br />
                        {DataTable({
                            className: "unavailable-force-sync-tables",
                            list: isSyncingTables,
                            hideHeader: true,
                            columns: [
                                {
                                    label: "Table",
                                    format: (item) => <div>{item.name}</div>,
                                },
                                {
                                    label: "Status",
                                    format: () => "Force Sync Unavailable - sync in progress",
                                },
                            ],
                        })}
                    </VerbDialogBodyScrollbar>

                    <div className="buttons">
                        <Button btnType="secondary" onClick={() => onDismiss()}>
                            Cancel
                        </Button>
                        <Button onClick={() => isShowMessage.onChange(false)}>{syncBtnText}</Button>
                    </div>
                </div>
            ) : (
                <div className={cx("force-sync-dialog-f03")}>
                    <VerbDialogBodyScrollbar>
                        {forceSyncActions.map(({name, description, isSelected, onClick}, i) =>
                            cs(keyed(i), ({}) => (
                                <div
                                    className={cx("force-sync-action-type-03v", {
                                        active: isSelected,
                                    })}
                                    onClick={onClick}
                                >
                                    <div className="left-radio">
                                        <div
                                            className={cx("radio", {
                                                selected: isSelected,
                                            })}
                                        />
                                    </div>
                                    <div className="right">
                                        <div className="name">{name}</div>
                                        <div className="description">{description}</div>
                                    </div>
                                </div>
                            ))
                        )}
                    </VerbDialogBodyScrollbar>

                    <div className="buttons">
                        <Button btnType="secondary" onClick={() => onDismiss()}>
                            Cancel
                        </Button>
                        <Button disabled={isForceFullSync.value === undefined} onClick={doSyncTables}>
                            {syncBtnText}
                        </Button>
                    </div>
                </div>
            );
        }
    );
};
