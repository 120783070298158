import * as React from "react";

import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {TileActions} from "./actions/tile-actions";
import {BoxPlotFields} from "./chart-types/box-plot-fields";
import {BubbleChartFields} from "./chart-types/bubble-chart-fields";
import {ComboChartFields} from "./chart-types/combo-chart-fields";
import {DownloadReportFields} from "./chart-types/download-report-fields";
import {FunnelChartFields} from "./chart-types/funnel/funnel-chart-fields";
import {GaugeFields} from "./chart-types/gauge-fields";
import {HorizontalBarFields} from "./chart-types/horizontal-bar-fields";
import {LineChartFields} from "./chart-types/line-chart-fields";
import {ListKPIFields} from "./chart-types/list-kpi-fields";
import {MapTileFields} from "./chart-types/map-tile-fields";
import {PieChartFields} from "./chart-types/pie-chart-fields";
import {PivotTableFields} from "./chart-types/pivot-table-fields";
import {ScatterPlotFields} from "./chart-types/scatter-plot-fields/scatter-plot-fields";
import {SingleKPIFields} from "./chart-types/single-kpi-fields";
import {SparkLineKpiFields} from "./chart-types/spark-line-kpi-fields";
import {TableFields} from "./chart-types/table-fields";
import {TableKPIFields} from "./chart-types/table-kpi-fields";
import {TextLabelFields} from "./chart-types/text-label-fields";
import {VennDiagramFields} from "./chart-types/venn-diagram-fields";
import {VerticalBarFields} from "./chart-types/vertical-bar-fields";

export const FieldsTab = ({tile, isSubTile = false, remoteTile, form, parentTile, size, next}) =>
    cs(["tileActions", (_, next) => (parentTile ? next(null) : TileActions({remoteTile, size, tile, next}))], consumeContext("debugFunction"), ({tileActions, debugFunction}) => {
        if (!tile.value || !chartTypes[tile.value.$type]) {
            return next({
                render: () => <div style={{textAlign: "center", padding: "20px 0"}}>No data configuration for {tile.$type} tile.</div>,
            });
        }

        return chartTypes[tile.value.$type]({
            tileActions,
            debugFunction,
            isSubTile,
            tile,
            form,
            next,
        });
    });

const chartTypes = {
    VerticalBarChartTile: VerticalBarFields,
    HorizontalBarChartTile: HorizontalBarFields,
    PieChartTile: PieChartFields,
    DonutChartTile: PieChartFields,
    DownloadReportTile: DownloadReportFields,
    TableTile: TableFields,
    PivotTableTile: PivotTableFields,
    GaugeTile: GaugeFields,
    LineChartTile: LineChartFields,
    ScatterPlotTile: ScatterPlotFields,
    BubbleChartTile: BubbleChartFields,
    SingleKPITile: SingleKPIFields,
    TableKPITile: TableKPIFields,
    ListKPITile: ListKPIFields,
    ComboChartTile: ComboChartFields,
    MapTile: MapTileFields,
    FunnelChartTile: FunnelChartFields,
    VennDiagramTile: VennDiagramFields,
    BoxPlotTile: BoxPlotFields,
    SparkLineKPITile: SparkLineKpiFields,
    TextLabelTile: TextLabelFields,
};
