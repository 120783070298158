import React from "react";
import {cs} from "@common/react/chain-services";
import {DialogService} from "../../../../../common/dialog/dialog-service";
import {SelectTileType} from "../../../../../collection/common/select-tile-type/select-tile-type";
import {tileTypes} from "../../../../../collection/common/select-tile-type/tile-types";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";
import "./shared-tile-type-select.scss";
import {cx} from "emotion";
import {camelCaseToSpaces} from "@common/utils/strings";

const SelectSharedTileTypeDialog = ({next: rootNext}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    render: ({resolve}) => ({
                        title: "Select Shared Tile Type",
                        width: 540,
                        content: next({
                            resolve,
                        }),
                    }),
                    next: rootNext,
                }),
        ],
        ({modal}) => (
            <div className="shared-tile-type-select-dialog-99k">
                <VerbDialogBodyScrollbar>
                    {SelectTileType({
                        list: tileTypes,
                        usingClick: true,
                        value: null,
                        changeTitleWhenChangeTile: true,
                        disabledChecking: true,
                        onChange: (tile) => modal.resolve(tile),
                    })}
                </VerbDialogBodyScrollbar>
            </div>
        )
    );
export const SharedTileTypeSelect = ({state, error}) => {
    return cs(["selectSharedTileTypeDialog", (_, next) => SelectSharedTileTypeDialog({next})], ({selectSharedTileTypeDialog}) => {
        return (
            <div className={cx("shared-tile-type-select-88y", {"has-errors": !!error})}>
                <div className="label">Select Tile Type</div>
                <div
                    className="toggle"
                    onClick={async () => {
                        const resp = await selectSharedTileTypeDialog.show();
                        if (resp) state.onChange(resp);
                    }}
                >
                    {state.value ? getTileTypeName(state.value.$type) : <div className="placeholder">Select</div>}
                    <i className="fa fa-chevron-down" />
                </div>
            </div>
        );
    });
};

const getTileTypeName = ($type) => {
    const map = {
        SingleKPITile: "Single KPI Tile",
        TableKPITile: "Table KPI Tile",
        ListKPITile: "List KPI Tile",
        SparkLineKPITile: "Spark Line KPI Tile",
    };

    return map[$type] || camelCaseToSpaces($type);
};
