import React from "react";

const FolderIcon = ({className}) => (
    <svg className={className} width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14.6667 5.33333V13.3333C14.6667 13.5101 14.5965 13.6797 14.4714 13.8047C14.3464 13.9298 14.1769 14 14 14H2.00004C1.82323 14 1.65366 13.9298 1.52864 13.8047C1.40361 13.6797 1.33337 13.5101 1.33337 13.3333V4.66667H14C14.1769 4.66667 14.3464 4.7369 14.4714 4.86193C14.5965 4.98695 14.6667 5.15652 14.6667 5.33333ZM8.27604 3.33333H1.33337V2.66667C1.33337 2.48986 1.40361 2.32029 1.52864 2.19526C1.65366 2.07024 1.82323 2 2.00004 2H6.94271L8.27604 3.33333Z"
            fill="#546B81"
        />
    </svg>
);

export default FolderIcon;
