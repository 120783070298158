export const getHiddenMapLegendPositions = (size) => {
    const {width, height} = size;
    let ret = [];

    const topBottomAvail = width >= 6 && height >= 6;
    const leftRightAvail = width >= 7 && height >= 6;

    if (!topBottomAvail) {
        ret.push("Top", "Bottom");
    }

    if (!leftRightAvail) {
        ret.push("Left", "Right");
    }

    return ret;
};

export const getMapLegendPosition = (currentPos, size) => {
    const positions = ["Left", "Right", "Bottom", "Top"];
    const hiddenPos = getHiddenMapLegendPositions(size);
    if (!hiddenPos.includes(currentPos) && currentPos !== "Auto") {
        return currentPos;
    }
    return positions.filter((p) => !hiddenPos.includes(p))[0];
};
