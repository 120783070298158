function dateFilter() {
    // Date filter validations
    const checkType = (type) => (data) => data?.$type === "DateFilter" && data?.customRange?.$type === type;

    return [
        ...(() => {
            const required1 = {
                when: checkType("InRangeCustomDateFilterRange"),
                validate: (date) => date,
                getMessage: () => "Date range is required.",
            };

            return [
                {
                    path: ["customRange", "dateStart"],
                    validators: [required1],
                },
                {
                    path: ["customRange", "dateEnd"],
                    validators: [required1],
                },
            ];
        })(),

        {
            path: ["customRange", "year"],
            validators: [
                {
                    when: checkType("InTheYearCustomDateFilterRange"),
                    validate: (year) => !!year,
                },
            ],
        },
        {
            path: ["customRange", "value"],
            validators: [
                {
                    when: checkType("InTheLastOrNextCustomDateFilterRange"),
                    validate: (v) => v != null,
                },
            ],
        },
        {
            path: ["customRange", "value"],
            validators: [
                {
                    when: checkType("InThePreviousCustomDateFilterRange"),
                    validate: (v) => v != null,
                },
            ],
        },
        {
            path: ["customRange", "date"],
            validators: [
                {
                    when: checkType("BeforeOrAfterCustomDateFilterRange"),
                    validate: (v) => v != null,
                },
            ],
        },
    ];
}
exports.dateFilter = dateFilter;
