import React from "react";
import {cs} from "@common/react/chain-services";
import "./model-auto-suggest-overlay.scss";
import {buildAutoSuggestModel} from "../../../common/build-auto-suggest-model";
import {generateTablePositions, mappingRelationships} from "./auto-suggest-helper";
import {UseState} from "@common/react/use-state";
import {OnMounted} from "@common/react/on-mounted";
import {CircleRadius, headerHeight} from "../model-panel";
import {ModelPanelHelper} from "../../../common/model-panel-helper";
import {waitTimeout} from "@common/utils/wait-timeout";
import {defaultLeftPanelWidth} from "../../layout/edit-model-layout";
import {Static2} from "@common/react/static-2";
import {getModelTableFromDsTable} from "../../tabs/data/data-menu/data-source-item";
import {consumeContext} from "@common/react/context";
import {SuggestionProgressPopup} from "./suggestion-progress-popup";
import {generateAnchorsAndRelationships} from "../relationships/relationships-common";

export const ModelAutoSuggestOverlay = ({dataSources, scale, autoSuggest, showTutorial, model}) =>
    cs(
        consumeContext("apis"),
        ["state", (_, next) => UseState({next, initValue: []})],
        ["modelState", (_, next) => UseState({next})],
        ["isSuccess", (_, next) => UseState({next, initValue: false})],
        ["progress", (_, next) => UseState({next, initValue: 0})],
        ["total", (_, next) => UseState({next, initValue: 1})],
        ["autoSuggestData", (_, next) => UseState({next, initValue: buildAutoSuggestModel(dataSources)})],
        ["containerRef", (_, next) => Static2({next})],
        [
            "getTableCanvasPosition",
            (_, next) =>
                next((position) => ({
                    x: (position.x - defaultLeftPanelWidth) / scale,
                    y: position.y / scale,
                })),
        ],
        [
            "autoSaveModel",
            ({modelState, getTableCanvasPosition, apis}, next) =>
                next(async ({tables, relationships}) => {
                    const resp = await apis.model.upsertModel({
                        ...model.value,
                        tables: tables.map((t) =>
                            getModelTableFromDsTable({
                                ds: t.dataSource,
                                dsTable: {
                                    ...t,
                                    position: getTableCanvasPosition(t.position),
                                },
                            })
                        ),
                    });
                    const updatedModel = await apis.model.upsertModel({
                        ...resp,
                        relationships: mappingRelationships({
                            relationships,
                            tables: resp.tables,
                        }),
                    });
                    modelState.onChange(updatedModel);
                }),
        ],
        ({state, getTableCanvasPosition, containerRef, progress, isSuccess, modelState, total, autoSaveModel, autoSuggestData}) => {
            const {tables: ts, relationships} = autoSuggestData.value;

            return (
                <>
                    {OnMounted({
                        props: modelState.value,
                        action: async ({getLatestProps}) => {
                            if (ts.length > 10 && relationships.length == 0) {
                                autoSuggest.onChange(false);
                                showTutorial.onChange({
                                    hasSuggestionStep: true,
                                });
                            } else {
                                await waitTimeout();
                                const tables = generateTablePositions(ts, relationships, containerRef.get().getBoundingClientRect(), scale);
                                autoSaveModel({tables, relationships});

                                const {relationshipsTable} = generateAnchorsAndRelationships({
                                    relationships,
                                    tables: tables.map((table) => ({
                                        ...table,
                                        position: getTableCanvasPosition(table.position),
                                    })),
                                });

                                total.onChange(ts.length + relationshipsTable.length);

                                for (let table of tables) {
                                    const initialPosition = document
                                        .querySelector(`[data-table-id='${table.id}']`)
                                        ?.getBoundingClientRect();
                                    state.change((s) =>
                                        s.concat({
                                            ...table,
                                            initialPosition,
                                        })
                                    );

                                    await model.change(
                                        (m) => ({
                                            ...m,
                                            tables: (m?.tables || []).concat({
                                                ...table,
                                                position: getTableCanvasPosition(table.position),
                                                dataSourceTableID: table.id,
                                                hidden: true,
                                            }),
                                        }),
                                        true
                                    );

                                    await waitTimeout();
                                    state.change((s) => s.map((t) => (t.id == table.id ? {...t, initialPosition: null} : t)));
                                    await waitTimeout(1000);
                                    await model.change(
                                        (m) => ({
                                            ...m,
                                            tables: (m?.tables || []).map((mt) => (mt.id == table.id ? {...mt, hidden: false} : mt)),
                                        }),
                                        true
                                    );
                                    progress.change((p) => p + 1);
                                }

                                await model.change(
                                    (m) => ({
                                        ...m,
                                        relationships: relationships.map((r) => ({
                                            leftColumnID: r.leftColumnID,
                                            rightColumnID: r.rightColumnID,
                                            joinType: r.joinType,
                                        })),
                                        relationshipTablesState: relationshipsTable.map((r) => ({
                                            ...r,
                                            displayColumn: false,
                                            hidden: true,
                                        })),
                                    }),
                                    true
                                );
                                const update = async (updated, i) =>
                                    await model.change(
                                        (m) => ({
                                            ...m,
                                            relationshipTablesState: (m?.relationshipTablesState || []).map((r, index) =>
                                                i == index ? updated : r
                                            ),
                                        }),
                                        true
                                    );
                                await waitTimeout(100);

                                for (let i = 0; i < relationshipsTable.length; i++) {
                                    let relationship = relationshipsTable[i];
                                    progress.change((p) => p + 1);

                                    await update(
                                        {
                                            ...relationship,
                                            hidden: true,
                                            displayColumn: true,
                                            suggestion: true,
                                        },
                                        i
                                    );

                                    await waitTimeout(1000);
                                    await update(
                                        {
                                            ...relationship,
                                            hidden: false,
                                            suggestion: true,
                                            displayColumn: true,
                                        },
                                        i
                                    );

                                    await waitTimeout(1000);

                                    await update(
                                        {
                                            ...relationship,
                                            hidden: false,
                                            suggestion: false,
                                            displayColumn: true,
                                            added: false,
                                        },
                                        i
                                    );

                                    await waitTimeout(1000);

                                    await update(
                                        {
                                            ...relationship,
                                            hidden: false,
                                            suggestion: false,
                                            displayColumn: true,
                                            added: true,
                                        },
                                        i
                                    );
                                    await waitTimeout(1000);
                                }

                                await model.change(() => getLatestProps(), true);
                                autoSuggest.onChange(false);
                                showTutorial.onChange({
                                    hasSuggestionStep: true,
                                });
                            }
                        },
                    })}

                    {SuggestionProgressPopup({
                        relationships,
                        tables: ts,
                        isSuccess,
                        progress,
                        total,
                        dataSources,
                    })}

                    <div className="model-auto-suggest-overlay-a33" ref={containerRef.set}>
                        {!isSuccess.value &&
                            state.value
                                .filter((t) => !model.value.tables.find((mt) => mt.id == t.id && !mt.hidden))
                                .map((table, index) => {
                                    const color = ModelPanelHelper.getTableColor({
                                        table,
                                        dataSources,
                                    });
                                    const pos = table.initialPosition ?? table.position;

                                    return (
                                        <div
                                            className="dragging-new-table"
                                            key={index}
                                            style={{
                                                top: pos.y - CircleRadius * scale,
                                                left: pos.x - CircleRadius * scale,
                                                transition: "all .7s",
                                                position: "absolute",
                                            }}
                                        >
                                            <div
                                                className="circle"
                                                style={{
                                                    width: CircleRadius * 2 * scale,
                                                    height: CircleRadius * 2 * scale,
                                                    borderRadius: "50%",
                                                }}
                                            >
                                                <div
                                                    className="background-inside"
                                                    style={{
                                                        background: color,
                                                        opacity: 1,
                                                        width: "100%",
                                                        height: "100%",
                                                        borderRadius: "50%",
                                                    }}
                                                />
                                            </div>

                                            <div
                                                style={{
                                                    textAlign: "center",
                                                    fontSize: 15 * scale,
                                                    marginTop: 10,
                                                    color: "#707070",
                                                    position: "absolute",
                                                    top: CircleRadius * 2 * scale,
                                                    left: "50%",
                                                    transform: "translate(-50%, 0)",
                                                    whiteSpace: "nowrap",
                                                }}
                                            >
                                                {table.visualName || table.name}
                                            </div>
                                        </div>
                                    );
                                })}
                    </div>
                </>
            );
        }
    );
