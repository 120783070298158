import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {Button} from "@common/form/buttons/button/button";
import {Dialog} from "../../routes/common/dialog/dialog";
import "./access-denied-page.scss";
import {checkSingleRouteAccessRole} from "./authorization";

export const AccessDeniedPage = () =>
    cs(consumeContext("auth"), consumeContext("routing"), ({auth, routing}) => {
        const singleRouteAccessRole = checkSingleRouteAccessRole(auth.user.roles);
        const content = (
            <div className="access-denied-page-f02">
                <div className="dialog-body">
                    <div className="messages">
                        <div className="message">Your account type does not have permission access this section.</div>
                    </div>
                </div>
                <div className="buttons">
                    <Button
                        btnType="secondary"
                        onClick={() => {
                            if (singleRouteAccessRole) {
                                routing.goto(singleRouteAccessRole.routeName);
                            } else if (!routing.previousRoutes) {
                                routing.goto("dashboard", {tab: "recent"});
                            } else {
                                routing.goBack();
                            }
                        }}
                    >
                        Go Back
                    </Button>
                    <Button
                        onClick={() => singleRouteAccessRole
                            ? routing.goto(singleRouteAccessRole.routeName)
                            : routing.goto("dashboard", {tab: "recent"})
                        }
                    >Go Home</Button>
                </div>
            </div>
        );
        return Dialog({
            title: "No Access",
            active: true,
            strong: true,
            content,
        });
    });
