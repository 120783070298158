const {arrEquals} = require("../../utils/collections");
const {parseNodes} = require("../../utils/path-pattern");
const {scope} = require("../scope");

const {PerField} = require("./per-field/per-field");
const {UseState} = require("../use-state");
const {cs} = require("../chain-services");

const Form2 = ({data, fields, remoteErrors, initShowErrors, hideErrorMessage, crossFields, disabled, onSubmit, next}) =>
    cs(
        ["showErrors", (_, next) => UseState({initValue: initShowErrors, next})],
        ["submitting", (_, next) => UseState({next})],
        ["fields", (_, next) => next(normalizeFormFields(fields))],
        [
            "perField",
            ({fields}, next) =>
                PerField({
                    disableValidations: disabled,
                    data,
                    fields,
                    next,
                }),
        ],
        ["findFromRemoteErrors", (_, next) => next(cFindFromRemoteErrors(remoteErrors))],
        ({perField, showErrors, submitting, fields, findFromRemoteErrors}) => {
            let errorDom;
            const scope1 = (parentPath) => ({
                ...perField,
                data: scope(data, parentPath),
                looksValid: !submitting.value && (perField.valid || !showErrors.value),
                immediateValid: perField.valid,
                scope: (pp1) => scope1([...parentPath, ...pp1]),
                field: (path) => {
                    const remoteError = findFromRemoteErrors(path);
                    const field = perField.field([...parentPath, ...(Array.isArray(path) ? path : path.split("."))]);
                    const error = (field.error && (showErrors.value || field.error.alwaysShow) && field.error) || remoteError.error;

                    return {
                        ...field,
                        error,
                        rawError: field.error,
                        domRef: !field.error
                            ? null
                            : (dom) => {
                                  if (errorDom == null) {
                                      errorDom = dom;
                                  }
                              },
                        getErrorMessage: () => !hideErrorMessage && (error?.getMessage?.() || remoteError.message),
                    };
                },
                group: (groupName) => {
                    const fields1 = fields.filter((f) => f.inGroups?.includes(groupName));
                    return {
                        looksValid: !showErrors.value || !fields1.find((f1) => perField.field(f1.path).error),
                    };
                },
                submit: async (param) => {
                    if (!perField.valid) {
                        if (!showErrors.value) {
                            showErrors.onChange(true);
                        }
                        if (errorDom) {
                            errorDom.scrollIntoView({behavior: "smooth"});
                        }
                        return;
                    }
                    if (submitting.value) {
                        return;
                    }

                    submitting.onChange(true);
                    await onSubmit(param);
                    submitting.onChange(false);
                },
                showErrors: () => {
                    if (errorDom) {
                        errorDom.scrollIntoView({behavior: "smooth"});
                    }
                    showErrors.onChange(true);
                },
            });

            return next(scope1([]));
        }
    );
exports.Form2 = Form2;

const normalizeFormFields = (fields) => {
    if (!fields || Array.isArray(fields)) {
        return fields;
    }
    return Object.keys(fields).map((path) => {
        const flex = fields[path];
        return {
            path: path.split("."),
            ...(Array.isArray(flex)
                ? {
                      validators: flex,
                  }
                : flex),
        };
    });
};

const cFindFromRemoteErrors = (remoteErrors) => {
    const deCapitalize = (str) => str[0].toLowerCase() + str.slice(1);
    const errors = remoteErrors?.map((re) => ({
        message: re.error,
        nodes: parseNodes(re.property).map((n) => deCapitalize(n.value)),
    }));

    return (path) => {
        if (!remoteErrors) {
            return {};
        }
        const strPath = (() => {
            const arrPath = Array.isArray(path) ? path : path.split(".");
            return arrPath.map((n) => n.toString());
        })();
        for (const err of errors) {
            if (arrEquals(strPath, err.nodes)) {
                return {
                    error: true,
                    message: err.message,
                };
            }
        }
        return {};
    };
};
