import React from "react";
import {cs} from "@common/react/chain-services";
import {Load} from "@common/react/load";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import {RadioLine} from "../common/radio-line/radio-line";
import {UseState} from "@common/react/use-state";

export const TestMaps = () =>
    cs(
        [
            "geoJsonWorld",
            (_, next) =>
                Load({
                    fetch: () => fetch("https://code.highcharts.com/mapdata/custom/world.geo.json").then((res) => res.json()),
                    next,
                }),
        ],
        [
            "worldPopulationDensity",
            (_, next) =>
                Load({
                    fetch: () => fetch("https://cdn.jsdelivr.net/gh/highcharts/highcharts@v7.0.0/samples/data/world-population-density.json").then((res) => res.json()),
                    next,
                }),
        ],
        [
            "schemeIndex",
            (_, next) =>
                UseState({
                    initValue: 0,
                    next,
                }),
        ],
        ({geoJsonWorld, worldPopulationDensity, schemeIndex}) => {
            if (!geoJsonWorld || !worldPopulationDensity) {
                return "Loading...";
            }

            const mapOptions = {
                chart: {
                    map: geoJsonWorld,
                },

                title: {
                    text: "Highmaps basic demo",
                },

                subtitle: {
                    text: "World population",
                },

                mapNavigation: {
                    enabled: true,
                    buttonOptions: {
                        verticalAlign: "bottom",
                    },
                },

                ...colorSchemes[schemeIndex.value].config,

                series: [
                    {
                        mapData: geoJsonWorld,
                        data: worldPopulationDensity,
                        joinBy: ["iso-a2", "code"],
                        name: "Population density",
                        states: {
                            hover: {
                                color: "#BADA55",
                            },
                        },
                        dataLabels: {
                            // enabled: true,
                            format: "{point.name}",
                        },
                    },
                ],
            };

            return (
                <div className="test-maps-46f test-route-tab">
                    <div className="left">
                        {colorSchemes.map((scheme, i) => (
                            <div key={i} className="">
                                {RadioLine({
                                    selected: schemeIndex.value === i,
                                    label: scheme.name,
                                    onClick: () => schemeIndex.onChange(i),
                                })}
                                {schemeIndex.value === i && (
                                    <div className="">
                                        {scheme.description}
                                        <pre>{JSON.stringify(scheme.config, null, 2)}</pre>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                    <div className="right">
                        <div key={schemeIndex.value} className="chart">
                            <HighchartsReact
                                {...{
                                    highcharts: Highcharts,
                                    constructorType: "mapChart",
                                    options: mapOptions,
                                }}
                            />
                        </div>
                    </div>
                </div>
            );
        }
    );

const colorSchemes = [
    {
        name: "min and max color",
        description: (
            <div className="">
                docs:
                <br />
                <a href={"https://api.highcharts.com/highmaps/colorAxis.minColor"} target="_blank">
                    minColor
                </a>
                <br />
                <a href={"https://api.highcharts.com/highmaps/colorAxis.maxColor"} target="_blank">
                    maxColor
                </a>
            </div>
        ),
        config: {
            colorAxis: {
                min: 1,
                max: 200,
                minColor: "#0074F0",
                maxColor: "#D457D0",
            },
        },
    },
    {
        name: "array of stop colors",
        description: (
            <div className="">
                docs:
                <br />
                <a href={"https://api.highcharts.com/highmaps/colorAxis.stops"} target="_blank">
                    stops
                </a>
            </div>
        ),
        config: {
            colorAxis: {
                min: 1,
                max: 200,
                stops: [
                    [0, "#0000ff"],
                    [0.3, "#6da5ff"],
                    [0.6, "#ffff00"],
                    [1, "#ff0000"],
                ],
            },
        },
    },
    {
        name: "data classes with min and max color",
        description: (
            <div className="">
                docs:
                <br />
                <a href={"https://api.highcharts.com/highmaps/colorAxis.dataClasses"} target="_blank">
                    dataClasses
                </a>
                <br />
                <a href={"https://api.highcharts.com/highmaps/colorAxis.dataClassColor"} target="_blank">
                    dataClassColor
                </a>
            </div>
        ),
        config: {
            colorAxis: {
                dataClassColor: "category",
                dataClasses: [
                    {
                        to: 3,
                    },
                    {
                        from: 3,
                        to: 10,
                    },
                    {
                        from: 10,
                        to: 30,
                    },
                    {
                        from: 30,
                        to: 100,
                    },
                    {
                        from: 100,
                        to: 300,
                    },
                    {
                        from: 300,
                        to: 1000,
                    },
                    {
                        from: 1000,
                    },
                ],
            },
            minColor: "#0074F0",
            maxColor: "#D457D0",
        },
    },
    {
        name: "data classes with color for each class",
        description: (
            <div className="">
                docs:
                <br />
                <a href={"https://api.highcharts.com/highmaps/colorAxis.dataClasses"} target="_blank">
                    dataClasses
                </a>
                <br />
                <a href={"https://api.highcharts.com/highmaps/colorAxis.dataClassColor"} target="_blank">
                    dataClassColor
                </a>
                <br />
                <a href={"https://api.highcharts.com/highmaps/colors"} target="_blank">
                    colors
                </a>
            </div>
        ),
        config: {
            colorAxis: {
                dataClassColor: "category",
                dataClasses: [
                    {
                        to: 3,
                    },
                    {
                        from: 3,
                        to: 10,
                    },
                    {
                        from: 10,
                        to: 30,
                    },
                    {
                        from: 30,
                        to: 100,
                    },
                    {
                        from: 100,
                        to: 300,
                    },
                    {
                        from: 300,
                        to: 1000,
                    },
                    {
                        from: 1000,
                    },
                ],
            },
            colors: ["#0074F0", "#D457D0", "#FF4E87", "#FF8638", "#C2B700", "#35C06D", "#00ECFD"],
        },
    },
];
