import * as React from "react";
import {Design} from "./panels/design/design";
import {TileTitle} from "../common/tile-title/tile-title";
import {Pagination} from "./panels/pagination/pagination";
import {OtherOptions} from "../common/other-options/other-options";
import {ColumnSettings} from "./panels/column-settings/column-settings";
import {TableDisplay} from "./panels/table-display/table-display";
import {ConditionalFormatting} from "./panels/conditional-formatting/conditional-formatting";

export const Table = ({tile, size, form, tableFieldConfigOverride, parentTile, tileAction, isContainerTile, conditionalFormattingConfig}) =>
    [TileTitle, ConditionalFormatting, Design, TableDisplay, ColumnSettings, Pagination, OtherOptions].map((panel) =>
        panel({
            tile,
            size,
            form,
            tableFieldConfigOverride,
            parentTile,
            tileAction,
            isContainerTile,
            conditionalFormattingConfig,
        })
    );
