import React from "react";
import {cs} from "@common/react/chain-services";
import {ffToDropdown} from "@common/form/ff-to-dropdown";
import {rColumnValueDrillingSelectFromStepOutput} from "../../../../common/column-value-drilling-select-from-step-output/column-value-drilling-select-from-step-output";
import {spc} from "@common/react/state-path-change";
import {keepOnly} from "@common/utils/objects";
import {DropdownSelect2} from "@common/ui-components/dropdown-select/dropdown-select2";
import {cGetFieldInfo} from "../../../../../../collection/common/field-info/get-field-info";
import "./case-condition-config.scss";
import {TrashIcon} from "../../../../../../common/icons/trash-icon";
import {getPath} from "@common/utils/arr-path";
import {DebounceTextInput} from "@common/form/debounce-input/debounce-text-input";
import {ffToBasicInput} from "@common/form/ff-to-basic-input";
import {keyed} from "@common/react/keyed";
import {Button} from "@common/form/buttons/button/button";
import {removeIndex} from "@common/utils/collections";

export const CaseConditionConfig = ({step, columns, model, path, form, onRemove}) =>
    cs((_, next) => {
        const getFieldInfo = cGetFieldInfo(model);
        const ccConfig = getPath(step.value, path);
        const comparisonSelect = ({dataType, ...props}) =>
            DropdownSelect2({
                label: "Match Criteria",
                list: [
                    "Equals",
                    "Not Equals",
                    ...(["Text", "Bool"].includes(dataType)
                        ? []
                        : ["Less Than", "Less Than Or Equals", "Greater Than", "Greater Than Or Equals"]),
                    "Is Null",
                    "Is Null Or Default",
                    "Is Not Null",
                    "Is Not Null Or Default",
                    ...(["Text"].includes(dataType) ? ["Contains", "Does Not Contain"] : []),
                ].map((label) => ({
                    label,
                    value: label === "Does Not Contain" ? "NotContains" : label.replace(/ /g, ""),
                })),
                valueToLabel: (v) => v.label,
                ...props,
            });

        const pathForForm = path.map((n) => (!isNaN(n) ? n : n.replace("!arr", "")));

        const columnValueDrillingSelect = rColumnValueDrillingSelectFromStepOutput({
            columns,
            model,
            useColumn: true,
        });

        const columnValueDrillingSelectIncludingStatic = rColumnValueDrillingSelectFromStepOutput({
            columns,
            model,
            useStatic: true,
        });

        return (
            <div className="case-condition-config-88o">
                {(ccConfig?.comparisons.length > 0 ? ccConfig?.comparisons : [{}]).map((ccComparison, j) =>
                    cs(keyed(j), () => {
                        const andComparisonPath = [...path, "comparisons!arr", j];
                        const andPathForForm = andComparisonPath.map((n) => (!isNaN(n) ? n : n.replace("!arr", "")));

                        return (
                            <div className="fields">
                                {(() => {
                                    const dropdownProps = ffToDropdown(form.field([...andPathForForm, "left"]));
                                    return columnValueDrillingSelect({
                                        state: {
                                            value: dropdownProps.value,
                                            onChange: (v) => {
                                                spc(
                                                    step,
                                                    andComparisonPath,
                                                    (prev) =>
                                                        v && {
                                                            ...(prev ?? {}),
                                                            $type: "CaseColumnFilterConfig",
                                                            left: v,
                                                        }
                                                );
                                            },
                                        },
                                        ...keepOnly(dropdownProps, ["hasError", "errorMessage", "domRef"]),
                                    });
                                })()}

                                <>
                                    {cs(() => {
                                        const fieldInfo = getFieldInfo(form.field([...andPathForForm]).state.value?.left);

                                        return comparisonSelect({
                                            dataType: fieldInfo?.dataType,
                                            ...ffToDropdown(form.field([...andPathForForm, "comparison"]), ["value"]),
                                        });
                                    })}

                                    {ccComparison.$type &&
                                        (() => {
                                            const comparison = getPath(step.value, [...andComparisonPath, "comparison"]);
                                            if (!comparison || comparison.includes("Null")) {
                                                return;
                                            }
                                            const dropdownProps = ffToDropdown(form.field([...andPathForForm, "right"]));
                                            const selector = columnValueDrillingSelectIncludingStatic;
                                            return (
                                                <>
                                                    {selector({
                                                        state: {
                                                            value: dropdownProps.value,
                                                            onChange: dropdownProps.onChange,
                                                        },
                                                        ...keepOnly(dropdownProps, ["hasError", "errorMessage", "domRef"]),
                                                    })}
                                                    {dropdownProps.value?.$type === "StaticValue" &&
                                                        DebounceTextInput({
                                                            label: "Value",
                                                            ...ffToBasicInput(form.field([...andPathForForm, "right", "value"])),
                                                        })}
                                                </>
                                            );
                                        })()}

                                    {ccComparison.$type &&
                                        (j + 1 === ccConfig?.comparisons.length ? (
                                            <Button
                                                btnType="secondary"
                                                size="tiny"
                                                className="and-btn"
                                                onClick={() =>
                                                    spc(step, andComparisonPath.slice(0, andComparisonPath.length - 1), (andConfigs) => [
                                                        ...(andConfigs || []),
                                                        {},
                                                    ])
                                                }
                                            >
                                                AND
                                            </Button>
                                        ) : (
                                            <div className="and-text">AND</div>
                                        ))}

                                    {step.value.caseConditions.length > 0 && (
                                        <div
                                            className="delete-btn"
                                            onClick={() => {
                                                const arr = getPath(step.value, [...path, "comparisons"]);
                                                if (arr?.length > 0) {
                                                    const newAndConfigs = removeIndex(j, arr);
                                                    if (newAndConfigs?.length > 0) {
                                                        spc(step, [...path, "comparisons"], () => newAndConfigs);
                                                    } else {
                                                        onRemove();
                                                    }
                                                } else {
                                                    onRemove();
                                                }
                                            }}
                                        >
                                            {TrashIcon()}
                                        </div>
                                    )}
                                </>
                            </div>
                        );
                    })
                )}

                <div className="fields">
                    {/*{(() => {*/}
                    {/*    const dropdownProps = ffToDropdown(form.field([...pathForForm, "left"]));*/}
                    {/*    return columnValueDrillingSelect({*/}
                    {/*        state: {*/}
                    {/*            value: dropdownProps.value,*/}
                    {/*            onChange: (v) => {*/}
                    {/*                spc(*/}
                    {/*                    step,*/}
                    {/*                    path,*/}
                    {/*                    () =>*/}
                    {/*                        v && {*/}
                    {/*                            $type: "CaseColumnFilterConfig",*/}
                    {/*                            left: v,*/}
                    {/*                        }*/}
                    {/*                );*/}
                    {/*            },*/}
                    {/*        },*/}
                    {/*        ...keepOnly(dropdownProps, ["hasError", "errorMessage", "domRef"]),*/}
                    {/*    });*/}
                    {/*})()}*/}

                    {/*{ccConfig?.$type && (*/}
                    {/*    <>*/}
                    {/*        {cs(() => {*/}
                    {/*            const fieldInfo = getFieldInfo(form.field([...pathForForm]).state.value?.left);*/}

                    {/*            return comparisonSelect({*/}
                    {/*                dataType: fieldInfo?.dataType,*/}
                    {/*                ...ffToDropdown(form.field([...pathForForm, "comparison"]), ["value"]),*/}
                    {/*            });*/}
                    {/*        })}*/}

                    {/*        {(() => {*/}
                    {/*            const comparison = getPath(step.value, [...path, "comparison"]);*/}
                    {/*            if (!comparison || comparison.includes("Null")) {*/}
                    {/*                return;*/}
                    {/*            }*/}
                    {/*            const dropdownProps = ffToDropdown(form.field([...pathForForm, "right"]));*/}
                    {/*            const selector = columnValueDrillingSelectIncludingStatic;*/}
                    {/*            return (*/}
                    {/*                <>*/}
                    {/*                    {selector({*/}
                    {/*                        state: {*/}
                    {/*                            value: dropdownProps.value,*/}
                    {/*                            onChange: dropdownProps.onChange,*/}
                    {/*                        },*/}
                    {/*                        ...keepOnly(dropdownProps, ["hasError", "errorMessage", "domRef"]),*/}
                    {/*                    })}*/}
                    {/*                    {dropdownProps.value?.$type === "StaticValue" &&*/}
                    {/*                        DebounceTextInput({*/}
                    {/*                            label: "Value",*/}
                    {/*                            ...ffToBasicInput(form.field([...pathForForm, "right", "value"])),*/}
                    {/*                        })}*/}
                    {/*                </>*/}
                    {/*            );*/}
                    {/*        })()}*/}

                    <div className="text-separator">THEN</div>

                    {(() => {
                        const dropdownProps = ffToDropdown(form.field([...pathForForm, "value"]));
                        const selector = rColumnValueDrillingSelectFromStepOutput({
                            columns,
                            model,
                            useStatic: true,
                            label: "Show Value",
                        });
                        return (
                            <>
                                {selector({
                                    state: {
                                        value: dropdownProps.value,
                                        onChange: dropdownProps.onChange,
                                    },
                                    ...keepOnly(dropdownProps, ["hasError", "errorMessage", "domRef"]),
                                })}
                                {dropdownProps.value?.$type === "StaticValue" &&
                                    DebounceTextInput({
                                        label: "Value",
                                        ...ffToBasicInput(form.field([...pathForForm, "value", "value"])),
                                    })}
                            </>
                        );
                    })()}
                    {/*    </>*/}
                    {/*)}*/}

                    {/*{step.value.caseConditions?.length > 0 && (*/}
                    {/*    <div className="delete-btn" onClick={onRemove}>*/}
                    {/*        {TrashIcon()}*/}
                    {/*    </div>*/}
                    {/*)}*/}
                </div>
            </div>
        );
    });

const AndCaseCondition = ({}) => cs(() => <div>Hello</div>);
