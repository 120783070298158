import {cs} from "@common/react/chain-services";
import {cx} from "emotion";
import * as React from "react";
import "./thumbnail-select.scss";

export const ThumbnailSelect = ({list, isSelected, onChange}) =>
    cs(({}) => (
        <div className="thumbnail-select thumbnail-select-12a">
            {list.map((item, i) => (
                <div key={i} className={cx("item", {selected: isSelected(item)})} onClick={() => onChange(item)}>
                    {item.thumbnail ? (
                        <img className="display-icon" width={item.width || ""} draggable={false} src={item.thumbnail} alt={item.label} />
                    ) : (
                        <div className="display-icon">{item.icon}</div>
                    )}
                    <div className="display-name">{item.label}</div>
                </div>
            ))}
        </div>
    ));
