import { css, cx } from "emotion";
import moment from "moment-timezone";
import React from "react";

import { cs } from "../../../../../../react/chain-services";
import { consumeContext } from "../../../../../../react/context";

import { StyledClass } from "@common/react/styled-class";
import { getDropdownCss } from "@common/ui-components/live/filters/common/dropdown-select/dropdown.config";
import { sort } from "../../../../../../utils/collections";
import { getCurrentTzName } from "../../../../../../utils/dates/date-object";
import { DropdownWithSearch } from "../../../../../dropdown-with-search/dropdown-with-search";

export const SelectTimezoneDropdown = ({theme, toggle = DefaultToggle}) => {
    const localTimezone = getCurrentTzName();
    const componentTheme = theme.general.components;
    const downloadButtonTheme = theme.general.buttons.downloadButton;

    return cs(
        [
            "inputSearchCss",
            (_, next) =>
                StyledClass({
                    content: {
                        ".input-box": {
                            background: `${componentTheme.menuBackgroundColorRGB} !important`,
                            "border-color": `${componentTheme.menuBackgroundColorRGB} !important`,

                            ".search-icon": {
                                path: {
                                    fill: `${componentTheme.inputIconColorRGB} !important`,
                                },
                            },

                            ".clear-icon i": {
                                color: `${componentTheme.inputIconColorRGB} !important`,
                            },

                            input: {
                                "font-family": `${theme.general.canvas.fontFamily}, sans-serif !important`,
                                color: `${componentTheme.inputTextColorRGB} !important`,

                                "::-webkit-input-placeholder": {
                                    color: `${componentTheme.inputHintTextColorRGB}`,
                                },
                                ":-ms-input-placeholder": {
                                    color: `${componentTheme.inputHintTextColorRGB}`,
                                },
                                "::placeholder": {
                                    color: `${componentTheme.inputHintTextColorRGB}`,
                                },
                            },
                        },
                    },
                    next,
                }),
        ],
        [
            "buttonCss",
            (_, next) =>
                StyledClass({
                    content: {
                        background: `${downloadButtonTheme.backgroundColorRGB}`,
                        color: `${downloadButtonTheme.fontColorRGB}`,
                        border: `${downloadButtonTheme.borderWidth}px solid ${downloadButtonTheme.borderColorRGB}`,
                        "border-radius": `${downloadButtonTheme.cornerRadius}px`,
                        display: "flex",
                        "justify-content": "center",
                        width: "100%",

                        svg: {
                            path: {
                                fill: `${downloadButtonTheme.fontColorRGB}`,
                            },
                        },

                        "&:hover, &.selecting": {
                            background: `${downloadButtonTheme.hoverBackgroundColorRGB}`,
                            color: `${downloadButtonTheme.hoverFontColorRGB}`,
                            "border-color": `${downloadButtonTheme.hoverBorderColorRGB}`,

                            svg: {
                                path: {
                                    fill: `${downloadButtonTheme.hoverFontColorRGB}`,
                                },
                            },
                        },
                    },
                    next,
                }),
        ],
        [
            "checkIconCss",
            (_, next) =>
                StyledClass({
                    content: {
                        "font-size": `16px`,
                        position: "absolute",
                        right: "24px",
                        top: "17px",
                        color: "#18c96e",
                    },
                    next,
                }),
        ],
        [
            "dropdownCss",
            (_, next) =>
                StyledClass({
                    content: getDropdownCss(componentTheme),
                    next,
                }),
        ],
        [
            "noResultCss",
            (_, next) =>
                StyledClass({
                    content: {
                        background: `${componentTheme.menuBackgroundColorRGB}`,
                        color: `${componentTheme.menuTextColorRGB}`,
                    },
                    next,
                }),
        ],
        ({inputSearchCss, buttonCss, checkIconCss, dropdownCss, noResultCss}) => {
            const list = [
                {
                    value: null,
                    label: "UTC",
                },
                {
                    value: localTimezone,
                    label: `Local (${moment.tz.zone(localTimezone).abbr(Date.now())})`,
                },
                ...moment.tz.names().map((zone) => ({value: zone, label: zone})),
            ];

            return cs(consumeContext("selectedTimezone"), ({selectedTimezone}) => {
                const isSelected = (item) => item.value == selectedTimezone.value;
                const sortedList = sort(list, (l) => (isSelected(l) ? 0 : 1));
                return DropdownWithSearch({
                    className: "dropdown-timezone-9f9",
                    inputClass: inputSearchCss,
                    dropdownCss: dropdownCss,
                    noResultClass: noResultCss,
                    closeAllOtherDropdownWhenOpen: false,
                    icon: null,
                    isSelected,
                    onChange: (item) => selectedTimezone.onChange(item.value),
                    list: sortedList,
                    valueToLabel: (item) =>
                        isSelected(item) ? (
                            <>
                                <i className={cx("far fa-check", checkIconCss)} /> {item.label}
                            </>
                        ) : (
                            item.label
                        ),
                    valueToSearch: (item) => item.label,
                    renderToggle: (props) =>
                        toggle({
                            ...props,
                            selectedTimezone: selectedTimezone?.value,
                            buttonCss,
                        }),
                });
            });
        }
    );
};

const DefaultToggle = ({showExpand, showingExpand, selectedTimezone, disabled, buttonCss}) => (
    <button
        className={cx("toggle", cx("timezone-dropdown-f3b", buttonCss), {
            expanding: showingExpand,
        })}
        onClick={() => showExpand(!showingExpand)}
        disabled={disabled}
    >
        <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.5 10C0.5 15.523 4.977 20 10.5 20C10.7858 20 11.0688 19.988 11.3485 19.9645C10.8978 19.3797 10.5444 18.7161 10.3127 17.9978C8.25881 17.9498 6.29923 17.1129 4.84315 15.6569C3.34285 14.1566 2.5 12.1217 2.5 10C2.5 7.87827 3.34285 5.84344 4.84315 4.34315C6.34344 2.84285 8.37827 2 10.5 2C12.6217 2 14.6566 2.84285 16.1569 4.34315C17.613 5.79927 18.4498 7.75891 18.4978 9.81283C19.2161 10.0446 19.8797 10.398 20.4645 10.8487C20.488 10.5689 20.5 10.2859 20.5 10C20.5 4.477 16.023 0 10.5 0C4.977 0 0.5 4.477 0.5 10Z" />
            <path d="M13.9955 10H11.5V5H9.5V12H11.376C12.0584 11.1272 12.9589 10.4332 13.9955 10Z" />
            <path d="M13.0409 12.6718C12.2759 13.4658 11.8076 14.4989 11.7147 15.5975C11.6217 16.6961 11.9098 17.7932 12.5305 18.7044C13.1512 19.6157 14.0666 20.2854 15.123 20.6012C16.1793 20.9169 17.3122 20.8593 18.3311 20.4382C18.4001 19.9882 18.2489 19.5562 18.1871 19.405C18.0491 19.069 17.5943 18.4948 16.8353 17.6986C16.6325 17.4856 16.6457 17.3218 16.7183 16.8364L16.7261 16.7818C16.7753 16.4494 16.8581 16.2526 17.9771 16.075C18.5459 15.985 18.6953 16.2118 18.9023 16.5268L18.9719 16.63C19.1687 16.918 19.3145 16.984 19.5347 17.0836C19.6337 17.1286 19.7567 17.1856 19.9217 17.2786C20.3129 17.5024 20.3129 17.755 20.3129 18.3082V18.3712C20.3129 18.6058 20.2901 18.8122 20.2541 18.9916C20.7145 18.4137 21.0354 17.7374 21.1917 17.0153C21.348 16.2932 21.3355 15.5447 21.1552 14.8282C20.9749 14.1117 20.6317 13.4465 20.1523 12.8843C19.6729 12.3221 19.0702 11.8781 18.3911 11.587C18.0593 11.8108 17.6039 12.1282 17.4449 12.346C17.3639 12.457 17.2487 13.0252 16.8749 13.072C16.7777 13.084 16.6463 13.0756 16.5071 13.0666C16.1339 13.0426 15.6239 13.0096 15.4607 13.453C15.3569 13.7338 15.3389 14.497 15.6743 14.893C15.7283 14.956 15.7385 15.073 15.7019 15.2044C15.6539 15.3766 15.5573 15.4816 15.5267 15.5032C15.4691 15.4696 15.3539 15.3358 15.2753 15.2452C15.0875 15.0262 14.8523 14.7532 14.5487 14.6692C14.4383 14.6386 14.3171 14.6134 14.1989 14.5882C13.8695 14.5192 13.4969 14.4406 13.4099 14.2558C13.3463 14.1202 13.3469 13.9336 13.3469 13.7368C13.3469 13.4866 13.3469 13.204 13.2245 12.9298C13.182 12.8319 13.1194 12.7441 13.0409 12.6718ZM16.4999 22C13.1861 22 10.4999 19.3138 10.4999 16C10.4999 12.6862 13.1861 9.99999 16.4999 9.99999C19.8137 9.99999 22.4999 12.6862 22.4999 16C22.4999 19.3138 19.8137 22 16.4999 22Z" />
        </svg>
        &nbsp;
        {selectedTimezone ?? "UTC"}
    </button>
);
