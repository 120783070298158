import {cs} from "@common/react/chain-services";
import * as React from "react";
import "./change-type-dialog.scss";
import {DialogService} from "../../../../../common/dialog/dialog-service";
import {Button} from "../../../../../../../../common/form/buttons/button/button";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";

export const ChangeTypeDialog = ({next: rootNext}) =>
    cs(
        [
            "modal",
            ({}, next) =>
                DialogService({
                    registryRender: true,
                    render: ({resolve}) => ({
                        title: "Change Filter Type?",
                        width: 482,
                        content: next({
                            resolve,
                        }),
                    }),
                    next: rootNext,
                }),
        ],
        ({modal}) => (
            <div className="change-type-dialog-29s">
                <VerbDialogBodyScrollbar>
                    <div className="messages">
                        <div className="message">Changing filter type will remove the filter option settings.</div>
                    </div>
                </VerbDialogBodyScrollbar>

                <div className="buttons">
                    <Button btnType="secondary" onClick={() => modal.resolve()}>
                        Cancel
                    </Button>
                    <Button onClick={() => modal.resolve("confirm")}>Change Filter Type</Button>
                </div>
            </div>
        )
    );
