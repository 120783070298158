export const isTitleShown = ({tile, size}) => size?.width > 3 || size?.height > 2;

export const isOutsideSliceLabelsHidden = ({size, legendStyle, titleStyle, isCompare}) => {
    return (isCompare ? isOutsideSliceLabelsHiddenCompare : isOutsideSliceLabelsHiddenNormal)({
        size,
        legendStyle,
        titleStyle,
    });
};

// legend has higher precedence than slice labels
const isOutsideSliceLabelsHiddenNormal = ({size, legendStyle}) => {
    const {width, height} = size;

    if (width > 7 && height > 6) {
        return false;
    }

    if (height <= 3) {
        return true;
    }

    if (height <= 4) {
        return legendStyle.legendShown;
    }

    if (height <= 5) {
        return legendStyle.legendShown && !["Top", "Bottom"].includes(legendStyle.legendPosition);
    }

    if (height <= 6) {
        return legendStyle.legendShown && !["TopVertical", "BottomVertical"].includes(legendStyle.legendPosition);
    }

    return false;
};

const isOutsideSliceLabelsHiddenCompare = ({size, legendStyle, titleStyle}) => {
    const {width, height} = size;

    if (width >= 15 && height >= 7) {
        return false;
    }

    if (width <= 12 || height <= 4) {
        return true;
    }

    if (height <= 5) {
        return titleStyle.show || (legendStyle.legendShown && ["Top", "Bottom", "TopVertical", "BottomVertical"].includes(legendStyle.legendPosition));
    }

    if (height <= 6) {
        return titleStyle.show && legendStyle.legendShown && ["Top", "Bottom", "TopVertical", "BottomVertical"].includes(legendStyle.legendPosition);
    }

    if (width <= 15) {
        return legendStyle.legendShown && ["Left", "Right"].includes(legendStyle.legendPosition);
    }
};
