import * as React from "react";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {DialogService} from "../../../../../common/dialog/dialog-service";
import {consumeContext} from "@common/react/context";
import {Form2} from "@common/react/cs-form/form2";
import {TextInput} from "@common/form/text-input/text-input";
import {ffToTextInput} from "@common/form/ff-to-text-input";
import {Load} from "@common/react/load";
import {waitTimeout} from "@common/utils/wait-timeout";
import {Button} from "@common/form/buttons/button/button";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";
import {required} from "@common/react/cs-form/validators/required";

export const RenameSharedTileDialog = ({onDone, reloadFolderOnDone = true, loadFullData = true, next: rootNext}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    render: ({resolve, args: {tile}}) => ({
                        title: "Rename Shared Tile",
                        width: 540,
                        content: next({
                            resolve,
                            tile,
                        }),
                    }),
                    next: rootNext,
                }),
        ],
        consumeContext("apis"),
        [
            "fullData",
            ({modal, apis}, next) =>
                loadFullData
                    ? Load({
                          fetch: async () => await apis.collectionTiles.getTile(modal.tile.id),
                          next,
                      })
                    : modal.tile,
        ],
        [
            "editing",
            ({modal, fullData}, next) =>
                UseState({
                    initValue: {
                        adminTileName: modal.tile.adminTileName,
                    },
                    next,
                }),
        ],
        [
            "form",
            ({modal, editing, apis, fullData}, next) =>
                Form2({
                    data: editing,
                    fields: {
                        adminTileName: {
                            transforms: ["trim"],
                            validators: [required],
                            debounce: true,
                            asyncValidators: [
                                {
                                    validate: (name, {data}) => true,
                                    getMessage: () => `Existed`,
                                },
                            ],
                        },
                    },
                    onSubmit: async () => {
                        if (!fullData) {
                            await waitTimeout(2000);
                        }

                        if (fullData) {
                            await apis.collectionTiles.upsertTile({
                                ...fullData,
                                adminTileName: editing.value.adminTileName,
                            });

                            const newFolders = reloadFolderOnDone ? await apis.collectionTiles.getFolders() : null;
                            onDone(newFolders);
                            modal.resolve();
                        }
                    },
                    next,
                }),
        ],
        ({modal, form}) => {
            return (
                <div className="rename-collection-dialog-zd9">
                    <VerbDialogBodyScrollbar>
                        <div className="main-part">
                            {TextInput({
                                label: "Rename Admin Tile",
                                ...ffToTextInput(form.field("adminTileName")),
                            })}
                        </div>
                    </VerbDialogBodyScrollbar>

                    <div className="buttons">
                        <Button btnType="secondary" onClick={() => modal.resolve()}>
                            Cancel
                        </Button>
                        <Button disabled={!form.looksValid} onClick={form.submit}>
                            Rename
                        </Button>
                    </div>
                </div>
            );
        }
    );
