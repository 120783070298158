import {FontFamilyDropdown} from "../../../../../collection/theme/common/theme-tab/font-family-dropdown/font-family-dropdown";
import {ffToDropdown} from "../../../../../../../../common/form/ff-to-dropdown";
import {FontSizeSelect} from "../../../../../collection/tile/edit/left-panel/tabs/tile-tab/chart-types/common/font-size-select";
import {LpColorLine} from "../../../../../common/left-panel/lp-color-line";
import {ffToColorPickerLine} from "../../../../../../../../common/form/ff-to-color-picker-line";

export const VisualizationFontsTheme = ({form}) => {
    return (
        <div className="content">
            {FontFamilyDropdown({
                label: "Font Family",
                ...ffToDropdown(form.field(["dataVisualization", "fonts", "fontFamily"])),
                hasThemeDefaultOption: true,
            })}

            {LpColorLine({
                label: "Font Color",
                ...ffToColorPickerLine(form.field(["dataVisualization", "fonts", "fontColorRGB"])),
                inheritColor: form.field(["general", "canvas", "fontColorRGB"]).state.value,
            })}

            {FontSizeSelect({
                label: "Axis Font Size",
                ...ffToDropdown(form.field(["dataVisualization", "fonts", "axisFontSize"])),
            })}

            {FontSizeSelect({
                label: "Data label Font Size",
                ...ffToDropdown(form.field(["dataVisualization", "fonts", "dataLabelFontSize"])),
            })}

            {FontSizeSelect({
                label: "Grid Steps Font Size",
                ...ffToDropdown(form.field(["dataVisualization", "fonts", "gridStepsFontSize"])),
            })}
        </div>
    );
};
