import React from "react";
import {cs} from "@common/react/chain-services";
import {LeftPanelClosable} from "../../../../common/left-panel-closable/left-panel-closable";
import {scope} from "@common/react/scope";
import {DebounceCache} from "@common/react/debounce-cache";
import {TextInput} from "../../../../../../../common/form/text-input/text-input";
import {Expandable} from "../../../../common/expandable/expandable";

export const ConfigFieldOverride = ({state, onClose, label}) =>
    cs(
        ({}, next) => (
            <LeftPanelClosable
                {...{
                    onClose,
                    label: "`" + label + "` Field",
                    content: next(),
                }}
            />
        ),
        () => (
            <div className="configure-x-axis-field-9qm">
                <Expandable
                    {...{
                        label: "Display Name",
                        alwaysShow: true,
                        initExpand: true,
                        render: () => (
                            <div className="display-name-3ps">
                                {cs(
                                    [
                                        "debounce",
                                        (_, next) => {
                                            const {value, onChange} = scope(state, ["displayName"]);
                                            return DebounceCache({
                                                state: {
                                                    value: value || state.value.displayName,
                                                    onChange: (v) => (v === state.value.displayName ? onChange(null) : onChange(v)),
                                                },
                                                next,
                                            });
                                        },
                                    ],
                                    ({debounce}) =>
                                        TextInput({
                                            label: "Field Display Name",
                                            state: debounce.state,
                                            onBlur: debounce.flush,
                                        })
                                )}
                            </div>
                        ),
                    }}
                />
            </div>
        )
    );
