import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {Load2} from "@common/react/load2";
import {UseState} from "@common/react/use-state";
import {LoadingIndicator} from "@common/ui-components/loading-indicator/loading-indicator";
import {keepOnly} from "@common/utils/objects";
import {AmazonDSPConnectionForm} from "../../add/wizard/steps/connection-configuration/form/amazonDSP-connection-form";
import {BigQueryConnectionForm} from "../../add/wizard/steps/connection-configuration/form/bigQuery-connection-form";
import {DatabaseConnectionForm} from "../../add/wizard/steps/connection-configuration/form/database-connection-form";
import {DynamoDBConnectionForm} from "../../add/wizard/steps/connection-configuration/form/dynamoDB-connection-form";
import {ElasticsearchConnectionForm} from "../../add/wizard/steps/connection-configuration/form/elasticsearch-connection-form";
import {FirestoreConnectionForm} from "../../add/wizard/steps/connection-configuration/form/firestore-connection-form";
import {dataSourceTypes} from "../../common/data-source-type";
import {dataSourceFormConfig} from "../../common/data-source-form-config";
import {OutboundAPIForm} from "../../common/outbound-api/outbound-api-form";
import {outboundAPITransformDataToView} from "../../common/outbound-api/outbound-api-transform-data";
import {EditCloudConnection} from "../connection-configuration/cloud-connection/edit-cloud-connection";
import {EditFileConnection} from "../connection-configuration/file-connection/edit-file-connection";
import {DsSyncSettingsLayout} from "../sync-settings/layout/ds-sync-settings-layout";
import "./edit-connection-settings.scss";
import {loadDataFolders} from "../../add/add-data-source";
import {modifiedRawTables} from "../../add/default-data-source";
import {isNotBlank} from "@common/utils/strings";

const transformToView = (data) => ({
    ...data,
    connectionDetails: data.type === dataSourceTypes.OUTBOUNDAPI ? outboundAPITransformDataToView(data.connectionDetails) : data.connectionDetails,
});

const addingStatus = {
    error: null,
    success: false,
    loading: false,
    connectionDetails: {},
};

export const EditConnectionSettings = ({}) =>
    cs(
        consumeContext("routing"),
        consumeContext("apis"),
        consumeContext("toast"),
        (_, next) => loadDataFolders({next}),
        [
            "dataSource",
            ({apis, routing}, next) =>
                Load2({
                    fetch: () => apis.data.getDataSource(routing.params.dsId),
                    next,
                }),
        ],
        [
            "connectionSettings",
            ({dataSource, routing, apis, toast}, next) =>
                dataSource.loading
                    ? next({
                          render: () => LoadingIndicator({}),
                          controls: [],
                      })
                    : cs(
                          [
                              "editingDs",
                              (_, next) =>
                                  UseState({
                                      initValue: transformToView(dataSource.value),
                                      next,
                                  }),
                          ],
                          ["loading", (_, next) => UseState({initValue: false, next})],
                          [
                              "connectionStatus",
                              ({editingDs}, next) =>
                                  UseState({
                                      initValue: {
                                          ...addingStatus,
                                          ...keepOnly(editingDs.value, ["connectionDetails"]),
                                      },
                                      next,
                                  }),
                          ],
                          [
                              "dsApi",
                              ({connectionStatus}, next) =>
                                  cs((_) =>
                                      next({
                                          testConnection: async ({connectionDetails}, dsInfo = {}) => {
                                              try {
                                                  connectionStatus.onChange({
                                                      ...addingStatus,
                                                      loading: true,
                                                  });

                                                  const submitPayload = connectionDetails.hasOwnProperty("password")
                                                      ? isNotBlank(connectionDetails.password)
                                                          ? {
                                                                data: connectionDetails,
                                                            }
                                                          : {
                                                                dataSourceID: dsInfo.id,
                                                                data: null,
                                                            }
                                                      : {
                                                            dataSourceID: dsInfo.id,
                                                            data: connectionDetails,
                                                        };

                                                  const tables = await apis.data.getTablesColumns(submitPayload);

                                                  connectionStatus.onChange({
                                                      ...addingStatus,
                                                      success: true,
                                                      connectionDetails,
                                                  });

                                                  if (tables) {
                                                      const newDs = (ds) => ({
                                                          ...dsInfo,
                                                          connectionDetails,
                                                          tables: modifiedRawTables({
                                                              tables,
                                                              type: ds.type,
                                                          }),
                                                          disabledTables: [],
                                                      });

                                                      dataSource.change((ds) => ({
                                                          ...ds,
                                                          ...newDs(ds),
                                                      }));
                                                  }
                                              } catch (e) {
                                                  connectionStatus.onChange({
                                                      ...addingStatus,
                                                      error: e,
                                                  });
                                                  toast.show(e.message, {
                                                      isError: true,
                                                  });
                                                  // throw e;
                                              } finally {
                                                  connectionStatus.change((s) => ({
                                                      ...s,
                                                      loading: false,
                                                  }));
                                              }
                                          },
                                      })
                                  ),
                          ],
                          [
                              "renderConnectionSettingsView",
                              ({editingDs, connectionStatus, dsApi}, next) => {
                                  let connectionType =
                                      {
                                          [dataSourceTypes.FILE]: EditFileConnection,
                                          [dataSourceTypes.GOOGLE_SHEETS]: EditCloudConnection,
                                          [dataSourceTypes.DYNAMODB]: DynamoDBConnectionForm,
                                          [dataSourceTypes.AMAZONDSP]: AmazonDSPConnectionForm,
                                          [dataSourceTypes.BIGQUERY]: BigQueryConnectionForm,
                                          [dataSourceTypes.OUTBOUNDAPI]: OutboundAPIForm,
                                          [dataSourceTypes.ELASTICSEARCH]: ElasticsearchConnectionForm,
                                          [dataSourceTypes.FIRESTORE]: FirestoreConnectionForm,
                                      }[editingDs.value.type] || DatabaseConnectionForm;

                                  return connectionType({
                                      data: editingDs,
                                      connectionStatus: connectionStatus.value,
                                      dsApi,
                                      formConfig: dataSourceFormConfig({
                                          apis,
                                          dataSource,
                                      }),
                                      next,
                                  });
                              },
                          ],
                          ({renderConnectionSettingsView, editingDs, loading}) =>
                              next({
                                  controls: [
                                      {
                                          label: "Cancel",
                                          btnType: "secondary",
                                          onClick: () =>
                                              routing.goto("edit-data-source", {
                                                  envId: routing.params.envId,
                                                  dsId: routing.params.dsId,
                                                  type: routing.params.type,
                                              }),
                                      },
                                      {
                                          label: "Save & Done",
                                          btnType: "primary",
                                          disabled: !renderConnectionSettingsView.valid || loading.value,
                                          iconRight: loading.value && <i className="fad fa-spinner-third fa-spin" />,
                                          onClick: async () => {
                                              if (renderConnectionSettingsView.valid) {
                                                  try {
                                                      loading.onChange(true);
                                                      const result = await apis.data.upsertDataSource(editingDs.value);

                                                      toast.show("Connection Settings Saved");
                                                      // dataSource.reload();
                                                      routing.goto("edit-data-source", {
                                                          envId: routing.params.envId,
                                                          dsId: routing.params.dsId,
                                                      });
                                                  } catch (e) {
                                                      toast.show(e.message, {
                                                          isError: true,
                                                      });
                                                  } finally {
                                                      loading.onChange(false);
                                                  }
                                              }
                                          },
                                      },
                                  ],
                                  render: () => renderConnectionSettingsView.render(),
                              })
                      ),
        ],
        ({dataSource, connectionSettings}) =>
            DsSyncSettingsLayout({
                className: "edit-connection-settings-88o",
                editing: {
                    label: dataSource.value?.name,
                },
                rightButtons: connectionSettings.controls,
                content: connectionSettings.render({}),
            })
    );
