import React from "react";
import {cs} from "@common/react/chain-services";
import {cx} from "emotion";
import {SuspendUpdate} from "@common/react/suspend-update";
import {consumeContext} from "@common/react/context";
import {DownloadReportFields} from "../../../../collection/tile/edit/left-panel/tabs/fields-tab/chart-types/download-report-fields";
import "./api-fields-tab.scss";
import {LoadingIndicator} from "@common/ui-components/loading-indicator/loading-indicator";

export const ApiFieldsTab = ({next, savingQueue, collection}) =>
    cs(
        consumeContext("modelForCollection"),
        [
            "fields",
            ({}, next) => {
                if (!collection.value.tile) {
                    return next();
                }

                let tile = {
                    value: collection.value.tile,
                    onChange: (tile) => collection.change((c) => ({...c, tile})),
                };

                return DownloadReportFields({tile, next});
            },
        ],
        ({fields, modelForCollection: model}) => {
            return next({
                renderLeftPanel: () => {
                    if (!fields) {
                        return;
                    }

                    const override = fields.override;

                    return (
                        <div className="left-panel-t5w">
                            <div className={cx("tabs", {active: !override})}>{!model ? LoadingIndicator() : fields.render()}</div>

                            <div className={cx("override", {active: override})}>
                                {cs(
                                    ({}, next) =>
                                        SuspendUpdate({
                                            active: !override,
                                            next,
                                        }),
                                    () => override
                                )}
                            </div>
                        </div>
                    );
                },
            });
        }
    );
