import { generateDefaultTimeRange } from "@common/logic/date-time/date-util";
import { cs } from "@common/react/chain-services";
import { fragments } from "@common/react/fragments";
import { UseMemo } from "@common/react/use-memo";

import { addDate, addSecond, dateDiff, isDateExisted, secondDiff } from "../../../../../../../../utils/dates/date-object";
import { sameDateRange } from "../../../../../../../../utils/dates/date-ranges";
import { getPreviousDays } from "../common/time/get-previous-days";

export const COMPARE_MODES = {
    PREVIOUS_PERIOD: 0,
    SAME_PERIOD_YESTERDAY: 1,
    SAME_PERIOD_PREVIOUS_WEEK: 2,
    SAME_PERIOD_PREVIOUS_MONTH: 3,
    SAME_PERIOD_PREVIOUS_YEAR: 4,
    CUSTOM_PERIOD_START: 5
}

const PERIODS = {
    [COMPARE_MODES.SAME_PERIOD_YESTERDAY]: 86400,
    [COMPARE_MODES.SAME_PERIOD_PREVIOUS_WEEK]: 7 * 86400,
    [COMPARE_MODES.SAME_PERIOD_PREVIOUS_MONTH]: 28 * 86400,
    [COMPARE_MODES.SAME_PERIOD_PREVIOUS_YEAR]: 365 * 86400,
}

const addXSecondsToRange = (range, x) => ({
    from: addSecond(range.from, x),
    to: addSecond(range.to, x)
})

const lastMonthSameDate = (date) => {
    const isJan = date.month - 1 === 0;
    return {
        ...date,
        month: isJan ? 12 : date.month - 1,
        year: isJan ? date.year - 1 : date.year
    }
}

const lastYearSameDate = (date) => {
    return {
        ...date,
        year: date.year - 1
    }
}


export const compareModesConfig = [
    {
        label: "Previous Period",
        key: COMPARE_MODES.PREVIOUS_PERIOD,
        isEnabled: () => true,
        getRange: main => {
            return getPreviousDays(main)
            
        },
    },
    {
        label: "Same period yesterday",
        timeOnly: true,
        key: COMPARE_MODES.SAME_PERIOD_YESTERDAY,
        isEnabled: (range) => secondDiff(range.to, range.from) <= PERIODS[COMPARE_MODES.SAME_PERIOD_YESTERDAY],
        getRange: main => {
            const diff = secondDiff(main.to, main.from)
            const newTo = addSecond(main.to, -PERIODS[COMPARE_MODES.SAME_PERIOD_YESTERDAY]);
            return {
                to: newTo,
                from: addSecond(newTo, -diff)
            }
        }
    },
    {
        label: "Same period previous week",
        key: COMPARE_MODES.SAME_PERIOD_PREVIOUS_WEEK,
        isEnabled: range => secondDiff(range.to, range.from) <= PERIODS[COMPARE_MODES.SAME_PERIOD_PREVIOUS_WEEK],
        getRange: main => addXSecondsToRange(main, -PERIODS[COMPARE_MODES.SAME_PERIOD_PREVIOUS_WEEK])
    },
    {
        label: "Same period previous month",
        key: COMPARE_MODES.SAME_PERIOD_PREVIOUS_MONTH,
        isEnabled: range => secondDiff(range.to, range.from) <= PERIODS[COMPARE_MODES.SAME_PERIOD_PREVIOUS_MONTH],
        getRange: main => {
            
            const diff = secondDiff(main?.to, main?.from);
            let previousFrom = main?.from ? lastMonthSameDate(main.from) : null;
            let previousTo = main?.to ? lastMonthSameDate(main.to) : null;
            
            const isFromExists = isDateExisted(previousFrom);
            const isToExists = isDateExisted(previousTo)
            if(!isFromExists) {
                previousFrom = addSecond(previousTo, -diff)
            }
            else if(!isToExists) {
                previousTo = addSecond(previousFrom, diff)
            }
            
            
            return {
                from: previousFrom,
                to: previousTo
            }
        }
    },
    {
        label: "Same period previous year",
        key: COMPARE_MODES.SAME_PERIOD_PREVIOUS_YEAR,
        isEnabled: range => secondDiff(range.to, range.from) <= PERIODS[COMPARE_MODES.SAME_PERIOD_PREVIOUS_YEAR],
        getRange: main => {
            const diff = secondDiff(main?.to, main?.from);
            let previousFrom = main?.from ? lastYearSameDate(main.from) : null;
            let previousTo = main?.to ? lastYearSameDate(main.to) : null;
            const isFromExists = isDateExisted(previousFrom);
            const isToExists = isDateExisted(previousTo)
            if(!isFromExists) {
                previousFrom = addSecond(previousTo, -diff)
            }
            else if(!isToExists) {
                previousTo = addSecond(previousFrom, diff)
            }
            return {
                from: previousFrom,
                to: previousTo
            }
        }
    },
    {
        label: "Custom period start",
        key: COMPARE_MODES.CUSTOM_PERIOD_START,
        isEnabled: () => true,
        getRange: (main) => {
            const diff = secondDiff(main.to, main.from);
            
            const newTo = addSecond(main.from, -2);
            const result = {
                to: newTo,
                from: addSecond(newTo, -diff)
            }
            
            return result
        }
    },
]




const removeDuplicatedModes = modes => {
    const result = [];
    for(let i = 0;i < modes.length;i++) {
        const mode = modes[i];
        if(!result.find(m => sameDateRange(m.range, mode.range)) || mode.key === COMPARE_MODES.CUSTOM_PERIOD_START) {
            result.push(mode);
        }
    }
    return result;
}

const getModeRanges = (modes, main) =>  modes.reduce((result, config) => {
    return [...result, {key: config.key, label: config.label, range: config.getRange(main)}]
}, []) ;

const getEnabledModes = (allowTimeFilter, main) => {
    return compareModesConfig.filter(mode => {
        return mode.isEnabled(main) && (mode.timeOnly ? allowTimeFilter : true);
    })
}

export const calculatedCompareRangeModes = ({state, next: rootNext}) => {
    
    if(!state.value?.main){
        return rootNext([]);
    }
    
    return cs(
        ['mainRange', (_, next) => {
            
            if(!state.value.allowTimeFilter){
                return next(generateDefaultTimeRange(state.value.main))
            }
            return next(state.value.main)
        }],
        ['enabledModes', ({mainRange}, next) => {
            
            return UseMemo({
                next,
                fn: () => getEnabledModes(state.value.allowTimeFilter, mainRange),
                deps: [JSON.stringify(mainRange)]
            })
        }],
        ['modeRanges', ({enabledModes, mainRange}, next) => {
            
            return UseMemo({
                next,
                fn: () => removeDuplicatedModes(getModeRanges(enabledModes, mainRange)),
                deps: [JSON.stringify(mainRange)]
            });
        }],
        ({modeRanges}) => {
            
            return rootNext(modeRanges)
        },
    )
}
