import * as React from "react";
import {cs} from "@common/react/chain-services";
import {cx} from "emotion";
import {StaticTooltipService} from "../../../../../../../../../common/tooltip3/static-tooltip-service";
import "./hidden-field-alert.scss";

export const HiddenFieldAlert = ({info, tooltipBoxWidth}) =>
    cs(
        [
            "staticTooltip",
            (_, next) =>
                StaticTooltipService({
                    direction: "top",
                    tooltipBoxWidth,
                    info,
                    next,
                }),
        ],
        ({staticTooltip}) => {
            return staticTooltip.renderIcon({
                className: cx("hidden-field-alert hidden-field-alert-84f"),
                icon: <div className="hide-btn-64g">Hide</div>,
            });
        }
    );
