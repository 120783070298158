import React from "react";

import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {UseState} from "@common/react/use-state";
import {Form2} from "@common/react/cs-form/form2";
import {required} from "@common/react/cs-form/validators/required";
import {Queue1} from "@common/react/queue-1";
import {Watch} from "@common/react/watch";
import {TextInput} from "../../../../../../common/form/text-input/text-input";
import {ffToBasicInput} from "../../../../../../common/form/ff-to-basic-input";

export const OrgDetails = () =>
    cs(consumeContext("apis"), ({apis}) => (
        <div className="account-settings-general-88i">
            <div className="section">
                <div className="section-header">Organization Details</div>
                <div className="section-content">
                    {cs(
                        consumeContext("tenant"),
                        ({tenant}, next) => (tenant.tenant ? next() : <></>),
                        [
                            "params",
                            ({tenant}, next) =>
                                UseState({
                                    initValue: {name: tenant.tenant?.name},
                                    next,
                                }),
                        ],
                        [
                            "form",
                            ({params}, next) =>
                                Form2({
                                    fields: {
                                        name: [required],
                                    },
                                    data: params,
                                    onSubmit: async (params) => {
                                        return await apis.tenant.renameTenant(params);
                                    },
                                    next,
                                }),
                        ],
                        [
                            "updateQueue",
                            ({form}, next) =>
                                Queue1({
                                    fn: (params) => form.submit(params),
                                    next,
                                }),
                        ],
                        ({form, params, updateQueue}) => (
                            <>
                                {Watch({
                                    value: params.value,
                                    onChanged: (params) => updateQueue.push(params),
                                })}

                                {TextInput({
                                    label: "Organization Name",
                                    ...ffToBasicInput(form.field("name")),
                                })}
                            </>
                        )
                    )}
                </div>
            </div>
        </div>
    ));
