import {UseState} from "@common/react/use-state";
import {cs} from "@common/react/chain-services";
import * as React from "react";
import {keyed} from "@common/react/keyed";
import {Invoke} from "@common/react/invoke";
import {IgnoreUpdate} from "@common/react/ignore-update";
import {cx} from "emotion";
import "./fade-right.scss";
import {waitTimeout} from "@common/utils/wait-timeout";
import {spc} from "@common/react/state-path-change";
// import {Static2} from "@common/react/static-2";

export const FadeRight = ({key, className, next}) =>
    cs(
        // ["domRef", (_, next) => Static2({next})],
        [
            "state",
            (_, next) =>
                UseState({
                    getInitValue: () => ({
                        animating: null,
                        oldList: [key],
                    }),
                    next,
                }),
        ],
        ({state}) => (
            <div
                className={cx("fade-right-342 fade-right", className, {
                    animating: state.value.animating,
                })}
                style={{minHeight: state.value.animating?.height}}
                // ref={domRef.set}
            >
                {[
                    ...state.value.oldList
                        .filter((k) => k !== key)
                        .map((k) =>
                            cs(
                                keyed(k),
                                (_, next) => <div className={cx("item")}>{next()}</div>,
                                (_) => IgnoreUpdate({})
                            )
                        ),
                    cs(
                        keyed(key),
                        (_, next) => (
                            <div
                                className={cx("item", {
                                    active: state.value.oldList.includes(key),
                                })}
                            >
                                {next()}
                            </div>
                        ),
                        (_, next) => IgnoreUpdate({when: () => false, next}),
                        next
                    ),
                ]}

                {!state.value.oldList.includes(key) &&
                    Invoke({
                        fn: async () => {
                            // const height = domRef.get().getBoundingClientRect().height;
                            spc(state, ["animating"], () => ({}));
                            await waitTimeout(150);
                            spc(state, ["oldList"], (list) => [...list, key]);
                            await waitTimeout(150);
                            spc(state, ["animating"], () => null);
                        },
                    })}

                {state.value.oldList
                    .filter((k) => k !== key)
                    .map((oldKey) =>
                        cs(keyed(oldKey), () =>
                            Invoke({
                                fn: async ({isMounted}) => {
                                    await waitTimeout(150);
                                    if (isMounted()) {
                                        spc(state, ["oldList"], (list) => list.filter((k) => k !== oldKey));
                                    }
                                },
                            })
                        )
                    )}
            </div>
        )
    );
