import React from "react";
import {cs} from "@common/react/chain-services";
import {DialogService} from "../../../../common/dialog/dialog-service";
import "./error-connection-dialog.scss";

export const ErrorConnectionDialog = ({next: rootNext}) =>
    cs(
        [
            "modal",
            ({}, next) =>
                DialogService({
                    render: ({resolve, reject, args: {title, content}}) => ({
                        title: title,
                        content: next({reject, resolve, content}),
                    }),
                    next: rootNext,
                }),
        ],
        ({modal}) => (
            <div className="error-connection-dialog-8er">
                <div>
                    <i className="fa fa-exclamation" />
                    <div className="text">{modal.content}</div>
                </div>
            </div>
        )
    );
