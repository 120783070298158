const {createArray} = require("../../../../utils/collections");
const {sortMulti} = require("../../../../utils/collections");
const {stretch} = require("./stretch");
const {arrangeToLines} = require("./arrange-to-lines");
const {chain2} = require("../../../../utils/fs");

const rearrangeRects = (rects, {colCount}) => {
    const sortedRects = sortMulti(rects, [(r) => r.y, (r) => r.x]);

    const ordering = getOrdering(sortedRects, rects);

    return chain2(sortedRects, [(_) => arrangeToLines(_, {colCount}), (_) => stretch(_, {colCount}), (_) => revertOrdering(ordering, _)]);
};
exports.rearrangeRects = rearrangeRects;

const getOrdering = (newCol, oldCol) => {
    return newCol.map((e) => oldCol.indexOf(e));
};

const revertOrdering = (ordering, col) => {
    return createArray(col.length).map((i) => col[ordering.indexOf(i)]);
};

// console.log(rearrangeRects(
//     [
//         {x: 10, y: 0, width: 3, height: 4},
//         {x: 0, y: 0, width: 3, height: 3},
//     ],
//     {
//         colCount: 10,
//     },
// ))
