const {UseState} = require("@common/react/use-state");
const {sampleSignInRes} = require("../../../../common/logic/sample-api/sample-sign-in-res");
const {cs} = require("@common/react/chain-services");

const MockAuthentication = ({next}) =>
    cs(
        [
            "auth",
            (_, next) =>
                UseState({
                    initValue: true ? sampleSignInRes : null,
                    next,
                }),
        ],
        ({auth}) =>
            next({
                auth: auth.value,
                user: {roles: ["Verb"]},
                rejectCurrentAuthRoute: {onChange: () => {}},
                logout: () => {
                    auth.onChange(null);
                },
            })
    );
exports.MockAuthentication = MockAuthentication;
