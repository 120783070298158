import {findDuplicateds, flatten1} from "../../../../../../common/utils/collections";

export const getDuplicatesMapping = (col) => {
    const mappings = col.map((t) => t.mapsToName).filter((t) => t);
    return findDuplicateds(mappings);
};

export const validImportFlatFile = (dataSource) => {
    const duplicated1 = getDuplicatesMapping(dataSource.tables);
    const duplicated2 = flatten1(dataSource.tables.map((t) => getDuplicatesMapping(t.columns)));

    return duplicated1.length + duplicated2.length === 0;
};
