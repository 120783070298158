import {cs} from "../../react/chain-services";
import * as React from "react";
import {Toggle} from "../toggle/toggle";
import "./yes-no-toogle.scss";
// 294661
const colorsDefault = {right: "#E9ECEF"};

export const YesNoToggle = ({state, warningBeforeChange, colors = colorsDefault, ...props}) =>
    cs(
        // ["state", (_, next) => UseState({initValue: true, next})],
        ["state", (_, next) => next(state)],
        ({state}) =>
            Toggle({
                ...props,
                warningBeforeChange,
                state: state,
                smallToggle: true,
                left: {
                    label: "Yes",
                    color: "#18c96e",
                },
                right: {
                    label: "No",
                    color: colors.right, //"#E9ECEF"
                },
            })
    );

export const ToggleWithLabel = ({label, state, warningBeforeChange}) =>
    cs(() => (
        <div className="toggle-label toggle-label-5ti">
            <div className="label">{label}</div>
            {YesNoToggle({state, warningBeforeChange})}
        </div>
    ));
