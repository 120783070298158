import {ffToNumberInput} from "@common/form/ff-to-number-input";
import {NumberInput} from "@common/form/number-input/number-input";
import {scope} from "@common/react/scope";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {stateToSelect} from "@common/ui-components/form/state-to-select";
import {addDate, dateDiff, getPreviousDayInWeek, parseDate, today} from "@common/utils/dates/date-object";
import {isNullOrUndefined} from "@common/utils/objects";
import * as React from "react";
import {isCustomSelectableRanges} from "../../../date-picker-selectable-range/date-picker-selectable-range";
import {DEFAULT_LAST_OR_NEXT_DAYS_VALUE, DEFAULT_LAST_OR_NEXT_PERIOD_VALUE} from "../../default-custom-ranges";
import "./in-the-last-or-next-or-previous.scss";

const INTERVALS = [
    "Days",
    "Weeks",
    "Months",
    // "Quarters",
    "Years",
    // "Minutes",
    // "Hours",
];

export const InTheLastOrNextOrPrevious = ({customRange, filter, form}) => {
    const isNext = customRange.value.next;
    const isPastCustom = isCustomSelectableRanges(filter?.value.pickerMaxDaysInPast);
    const isFutureCustom = isCustomSelectableRanges(filter?.value.pickerMaxDaysInFuture);

    return (
        <div className="edit-custom-range in-the-last-or-next-or-previous-5e6 inline">
            <NumberInput
                key={form.field("$type").state.value}
                {...{
                    label: "Period",
                    showZero: true,
                    maxValue: (isNext ? filter?.value?.pickerMaxDaysInFuture : filter?.value?.pickerMaxDaysInPast) ?? Infinity,
                    ...ffToNumberInput(form.field(["value"])),
                }}
            />

            {DropdownSelect({
                list: INTERVALS.map((v) => ({
                    value: v,
                    disabled: (isPastCustom || isFutureCustom) && v !== "Days",
                })),
                valueToLabel: (item) => item.value.toLowerCase(),
                isSelected: (v) => v.value === customRange.value.interval,
                onChange: (v) => {
                    customRange.change((r) => ({
                        ...r,
                        interval: v.value,
                        value: v.value === "Days" ? DEFAULT_LAST_OR_NEXT_DAYS_VALUE : DEFAULT_LAST_OR_NEXT_PERIOD_VALUE,
                    }));
                },
            })}

            {customRange.value.interval === "Days" &&
                DropdownSelect({
                    label: (
                        <>
                            Negate? (<u>Not</u> In The {customRange.value.$type === "InThePreviousCustomDateFilterRange" ? "Previous" : customRange?.value?.next ? "Next" : "Last"}{" "}
                            ...)
                        </>
                    ),
                    list: [false, true],
                    valueToLabel: (item) => (item ? "Yes" : "No"),
                    ...stateToSelect(scope(customRange, ["negative"])),
                    className: "negate",
                })}
        </div>
    );
};
