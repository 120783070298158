import * as React from "react";
import {getPath} from "@common/utils/arr-path";
import {HiddenFieldAlert} from "../../common/hidden-field-alert/hidden-field-alert";
import {scope} from "@common/react/scope";
import {ShowHideToggle} from "../../../../../../../../../../../../common/form/toggles/show-hide-toggle";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {stateToSelect} from "@common/ui-components/form/state-to-select";
import {CheckboxLine} from "../../../../../../../../../common/checkbox-line/checkbox-line";
import {CheckboxLineGroup} from "../../../../../../../../../common/checkbox-line-group/checkbox-line-group";
import {stateToCheckboxLineGroup} from "../../../../../../../../../common/checkbox-line-group/state-to-checkbox-line-group";
import {stringListState} from "../../../../../../../../../common/data-logic/string-list-state";
import {spc} from "@common/react/state-path-change";

export const Legend = ({tile, size}) => ({
    label: "Legend",
    control: getPath(tile.value, ["style", "dataLabels", "show"])
        ? HiddenFieldAlert({
              info: "Legend can only be shown when section labels are turned off.",
          })
        : isFunnelLegendHiddenBySize({size})
        ? HiddenFieldAlert({
              info: "Legends cannot display on tiles smaller than 7 squares wide.",
          })
        : ShowHideToggle({
              state: {
                  value: tile.value.style.legendStyle.legendShown,
                  onChange: (v) =>
                      spc(tile, ["style"], (style) => ({
                          ...style,
                          legendStyle: {...style.legendStyle, legendShown: v},
                          dataLabels: {
                              ...style.dataLabels,
                              show: v ? false : style.dataLabels.show,
                          },
                      })),
              },
          }),
    render: () => (
        <div className="funnel-chart-section-59f legend">
            <div className="panel">
                {DropdownSelect({
                    label: "Position",
                    list: ["Right", "Left"],
                    ...stateToSelect(scope(tile, ["style", "legendStyle", "legendPosition"])),
                    valueToLabel: (v) => (v === "Right" ? "Right (Default)" : v),
                })}
            </div>
            <div className="subsection">
                <div className="header">Display Options</div>
                <div className="content">
                    {CheckboxLine({
                        label: "Label",
                        state: scope(tile, ["style", "dataLabels", "categoryShown"]),
                    })}

                    {CheckboxLineGroup({
                        list: [
                            {label: "Value", name: "Value"},
                            {label: "Percent", name: "Percent"},
                        ].filter((v) => v),
                        ...stateToCheckboxLineGroup(stringListState(scope(tile, ["style", "dataLabels", "metrics"])), ["name"]),
                    })}

                    {DropdownSelect({
                        label: "Comparison Type",
                        list: [
                            {
                                label: "Percent of Previous",
                                value: "PercentOfPrevious",
                            },
                            {
                                label: "Percent of Total",
                                value: "PercentOfTotal",
                            },
                        ],
                        ...stateToSelect(scope(tile, ["style", "dataLabels", "comparisonType"]), ["value"]),
                        valueToLabel: (v) => v.label,
                    })}
                </div>
            </div>
        </div>
    ),
});

export const isFunnelLegendHiddenBySize = ({size}) => size.width < 7;
export const isFunnelLegendHidden = ({tile, size}) => tile.style.dataLabels.show || isFunnelLegendHiddenBySize({size});
