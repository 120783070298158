import React from "react";
import {cs} from "@common/react/chain-services";
import {Static2} from "@common/react/static-2";
import {arrMapToO} from "@common/utils/objects";
import {createArray} from "@common/utils/collections";
import {keyed} from "@common/react/keyed";
import {fragments} from "@common/react/fragments";
import {UseState} from "@common/react/use-state";
import {Invoke} from "@common/react/invoke";
import {waitTimeout} from "@common/utils/wait-timeout";

export const FlexLabel = ({next, text}) =>
    cs(keyed(text), ["domRef", (_, next) => Static2({next})], ["truncatedText", (_, next) => UseState({next})], ({domRef, truncatedText}) => {
        const isOverflowed = () => {
            const dom = domRef.get();
            if (!dom) {
                return;
            }
            return dom.offsetWidth < dom.scrollWidth || dom.offsetHeight < dom.scrollHeight;
        };

        const getTruncatedText = (text) => {
            if (!isOverflowed()) {
                return;
            }
            const dom = domRef.get();
            const domRect = dom.getBoundingClientRect();
            const domStyle = (() => {
                const computedStyle = getComputedStyle(dom);
                return arrMapToO(
                    ["font-size", "height", "line-height"],
                    (v) => +computedStyle.getPropertyValue(v).replace("px", ""),
                    (v) => v
                );
            })();
            let newText;
            const firstWord = text.split(" ")[0];
            if (firstWord.length * domStyle["font-size"] * 0.6 > domRect.width) {
                const keptNumberOfLetters = Math.floor(domRect.width / (domStyle["font-size"] * 0.6));
                newText = firstWord.substr(0, keptNumberOfLetters) + "... ";
            } else {
                const numberOfLines = Math.floor(domStyle["height"] / domStyle["line-height"]);
                const keptNumberOfLetters = Math.floor((numberOfLines * domRect.width) / (domStyle["font-size"] * 0.8));
                newText = text.substr(0, keptNumberOfLetters) + "... "; // + createArray(text.slice(keptNumberOfLetters + 4).length).map((_) => ".").join("");
            }
            if (newText !== truncatedText.value) {
                truncatedText.onChange(newText);
            }
        };

        return fragments(
            next({
                render: ({color}) => (
                    <div
                        className="flex-label-35s flex-label"
                        ref={domRef.set}
                        style={{
                            display: "block",
                            width: "100%",
                            height: "100%",
                            color: color,
                        }}
                    >
                        {truncatedText.value || text}
                    </div>
                ),
                // when text is truncated it's not overflowed anymore, use truncatedText.value itself as an indicator for overflowing
                isOverflowed: () => isOverflowed() || truncatedText.value,
            }),
            Invoke({
                fn: async ({isMounted}) => {
                    await waitTimeout(150);
                    if (isMounted()) {
                        getTruncatedText(text);
                    }
                },
            })
        );
    });
