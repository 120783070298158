import "./multi-select.scss";

import {UseState} from "@common/react/use-state";
import {omit} from "@common/utils/objects";
import {cs} from "@common/react/chain-services";
import {Button} from "@common/form/buttons/button/button";
import {noun} from "@common/utils/plural";

export const MultiSelect = ({
    next: rootNext,
    itemName,
    actions,
    resolveItemKey = () => null,
    countItem = (dict) => Object.keys(dict).length,
}) =>
    cs(["selectedItems", (_, next) => UseState({initValue: {}, next})], ({selectedItems}) => {
        const _selectedItems = selectedItems.value;
        const numSelectedItems = countItem(_selectedItems, resolveItemKey);
        const clearAll = () => selectedItems.change(() => ({}));

        return rootNext({
            selectedItems: _selectedItems,
            numSelectedItems,
            isSelected: (item) => {
                const itemKey = resolveItemKey(item);
                return _selectedItems.hasOwnProperty(itemKey);
            },
            clearAll,
            bulkUpdate: ({addItems, removeItems}) => {
                selectedItems.change((s) => {
                    return addItems.reduce((s, addItem) => {
                        return {
                            ...s,
                            [resolveItemKey(addItem)]: addItem,
                        };
                    }, omit(s, removeItems.map(resolveItemKey)));
                });
            },
            toggleSelect: (item) => {
                const itemKey = resolveItemKey(item);
                selectedItems.change((s) => {
                    if (s.hasOwnProperty(itemKey)) {
                        return omit(s, [itemKey]);
                    }

                    return {
                        ...s,
                        [itemKey]: item,
                    };
                });
            },
            render: ({style} = {}) => {
                return (
                    <div className="selected-items-ctrl-f3f" style={style ?? {}}>
                        <div className="space" />

                        <div className="actions">
                            {actions
                                .filter((a) => a.isShow(_selectedItems, resolveItemKey))
                                .map((a) => {
                                    return (
                                        <Button
                                            key={a.label}
                                            onClick={async (e) => {
                                                const result = await a.onClick(_selectedItems, e);
                                                if (result) {
                                                    clearAll();
                                                }
                                            }}
                                            btnType="secondary"
                                            size="medium"
                                        >
                                            {a.label}
                                        </Button>
                                    );
                                })}
                        </div>

                        <Button
                            className="clear-items"
                            btnType="secondary"
                            size="medium"
                            iconRight={<i className="fa-regular fa-xmark" />}
                            onClick={() => clearAll()}
                        >
                            {numSelectedItems} {noun(numSelectedItems, itemName)} Selected
                        </Button>
                    </div>
                );
            },
        });
    });
