import * as React from "react";
import {ShowHideToggle} from "../../../../../../../../../../../../../common/form/toggles/show-hide-toggle";
import "./data-labels.scss";
import {scope} from "@common/react/scope";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {HiddenFieldAlert} from "../../../common/hidden-field-alert/hidden-field-alert";
// import {cascade} from "@common/utils/cascade";
// import {flatten1} from "@common/utils/collections";
// import {chain} from "@common/utils/fs";

export const DataLabels = ({tile}) => ({
    label: "Data labels",
    control:
        !tile.value.dimensionField && !tile.value.colorGroupField && !tile.value.shapeGroupField
            ? HiddenFieldAlert({
                  info: "Data labels not available when there is no Marker Aggregator or Group.",
              })
            : ShowHideToggle({
                  state: scope(tile, ["style", "dataLabels", "show"]),
              }),
    render: () => {
        const {value: value1, onChange} = scope(tile, ["style", "dataLabels", "labelFieldID"]);
        const value = value1 !== "00000000-0000-0000-0000-000000000000" ? value1 : null;

        const fieldList = scatterPlotDataLabelFieldAttrs.map((attr) => tile.value[attr] && {...tile.value[attr], attr}).filter((v) => v);

        const names = {
            dimensionField: "Marker Aggregator",
            xAxisField: "X-axis Measure",
            yAxisField: "Y-axis Measure",
            colorGroupField: "Color Group",
            shapeGroupField: "Shape Group",
        };

        return (
            <div className="scatter-plot-data-labels-53v">
                {DropdownSelect({
                    registryRender: true,
                    label: "Label Field",
                    list: fieldList,
                    valueToLabel: (v) => `${names[v.attr]} (${v.displayName})`,
                    isSelected: (v) => v.id === (value || fieldList[0]?.id),
                    onChange: (v) => onChange(v.id),
                })}
            </div>
        );
    },
});

export const scatterPlotDataLabelFieldAttrs = ["dimensionField", "colorGroupField", "shapeGroupField"];

// const getMeasureFields = (tile, measureGroupAttrs) => {
//     return chain(
//         measureGroupAttrs.map((attr) => cascade(
//             (tile[attr] || []),
//             "[*].measureFields[*]",
//             (value, path, objs) => ({
//                 ...value,
//                 attr,
//             })
//         )),
//         (_) => flatten1(_).filter(v => v),
//     )
// };
