import {consumeContext} from "@common/react/context";
import {cs} from "@common/react/chain-services";
import {Invoke} from "@common/react/invoke";

export const IntegrationSlackRedirect = () =>
    cs(consumeContext("auth"), consumeContext("routing"), ({routing, auth}) => {
        return Invoke({
            fn: () => {
                if (auth.user) {
                    routing.goto("account-management", {
                        setting: "integrations",
                    });
                }
            },
        });
    });
