import {cs} from "@common/react/chain-services";
import {DialogService} from "../../../../../common/dialog/dialog-service";
import {consumeContext} from "@common/react/context";
import {Load} from "@common/react/load";
import {UseState} from "@common/react/use-state";
import {MapFieldsForCloningSharedTileDialog} from "./map-fields-for-cloning-shared-tile-dialog";
import {flatten1} from "@common/utils/collections";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";
import {cx} from "emotion";
import {LoadingIndicator} from "@common/ui-components/loading-indicator/loading-indicator";
import {SelectModel} from "../../../collections/collection-cm/clone-collection/select-model";
import {Button} from "@common/form/buttons/button/button";
import {chain} from "@common/utils/fs";
import {purifyCollection} from "../../../collections/collection-cm/clone-collection/purify-collection";
import {removeIdsInCollection, removeIdsInTile} from "../../../../../common/remove-ids";
import * as React from "react";
import {getCloneName} from "../../../collections/collection-cm/clone-collection/select-model-for-cloning-collection-dialog";
import {Load2} from "@common/react/load2";
export const SelectModelForCloningSharedTileDialog = ({next: rootNext, folders, onDone}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    render: ({resolve, args: {tile, folder}}) => ({
                        title: "Select Model",
                        width: 347,
                        content: next({
                            resolve,
                            tile,
                            folder,
                        }),
                    }),
                    next: rootNext,
                }),
        ],
        consumeContext("apis"),
        consumeContext("routing"),
        [
            "remoteFolders",
            ({apis}, next) =>
                folders
                    ? next(folders)
                    : Load2({
                          fetch: () => apis.collectionTiles.getFolders(),
                          next,
                      }),
        ],
        [
            "remoteTile",
            ({apis, modal}, next) =>
                Load({
                    fetch: async () => apis.collectionTiles.getTile(modal.tile.id),
                    next,
                }),
        ],
        [
            "models",
            ({apis, routing}, next) =>
                Load({
                    fetch: () => apis.model.getModels({namesOnly: true}),
                    next,
                }),
        ],
        ["destinationModelId", (_, next) => UseState({next})],
        [
            "mapFieldsDialog",
            ({remoteTile, destinationModelId, modal}, next) =>
                MapFieldsForCloningSharedTileDialog({
                    tile: remoteTile,
                    destinationModelId: destinationModelId.value,
                    onDone: (newTile) => {
                        onDone(newTile);
                        modal.resolve();
                    },
                    next,
                }),
        ],
        ["cloning", (_, next) => UseState({next})],
        ["error", (_, next) => UseState({next})],
        ({modal, remoteTile, remoteFolders, models, destinationModelId, mapFieldsDialog, apis, cloning, error}) => {
            const cloneName =
                remoteFolders.value &&
                getCloneName(
                    modal.tile.adminTileName,
                    flatten1(remoteFolders.value.map((f) => f.tiles))
                        .map((c) => c.adminTileName)
                        .filter((name) => name?.length > 0)
                );
            const loading = !remoteTile || !models || !remoteFolders.value;
            return (
                <div className="select-model-for-cloning-dialog-24f">
                    <VerbDialogBodyScrollbar noPadding className={cx({loading})}>
                        {error.value && (
                            <div className="messages">
                                <div className="message error">Sorry, there is an issue cloning this collection.</div>
                            </div>
                        )}
                        {loading
                            ? LoadingIndicator({})
                            : SelectModel({
                                  list: models,
                                  isSelected: (id) => id === (destinationModelId.value || remoteTile.modelID),
                                  onSelect: (id) => destinationModelId.onChange(id),
                              })}
                    </VerbDialogBodyScrollbar>
                    <div className="buttons">
                        <Button
                            disabled={!remoteTile || !models || cloning.value}
                            onClick={async () => {
                                if (!destinationModelId.value || destinationModelId.value === remoteTile.modelID) {
                                    const cloneTile = chain(
                                        {
                                            ...remoteTile,
                                            adminTileName: cloneName,
                                        },
                                        (nTile) => removeIdsInTile(nTile, true)
                                    );
                                    try {
                                        cloning.onChange(true);
                                        const newSharedTile = await apis.collectionTiles.upsertTile(cloneTile);
                                        onDone(newSharedTile);
                                        modal.resolve();
                                    } catch (e) {
                                        error.onChange(e.message);
                                        cloning.onChange(false);
                                    }
                                } else {
                                    mapFieldsDialog.show({cloneName});
                                }
                            }}
                        >
                            Clone
                        </Button>
                    </div>
                </div>
            );
        }
    );
