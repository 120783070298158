import * as React from "react";
import "./whole-page-loading.scss";
import {ThreeDots} from "../loading-indicator/three-dots";

export const WholePageLoading = ({content} = {}) => {
    // console.log(content)
    return (
        <div className="post-guest-layout-sh2">
            {/*{content}*/}
            {ThreeDots({className: "white-dots"})}
        </div>
    );
};
