import React from "react";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {Load2} from "@common/react/load2";
import {UseState} from "@common/react/use-state";
import {loadTheme} from "../../collection/tile/edit/edit-tile";
import {dataSourceTypes} from "../common/data-source-type";
import {ErrorDataSourceDialog} from "../common/dialogs/error-data-source-dialog/error-data-source-dialog";
import {ErrorGoogleApiConnectionDialog} from "../common/dialogs/error-google-api-connection-dialog/error-google-api-connection-dialog";
import {syncingProcessProvider} from "./common/observe-syncing-service/sync-process-provider";
import "./edit-data-source.scss";
import {EditFlatFileStatus} from "./flat-file-status/edit-flat-file-status";
import {FlatFileInteraction} from "./flat-file-status/interaction/flat-file-interaction";
import {EditSyncStatus} from "./sync/edit-sync-status";
import {DsSyncInteraction} from "./sync/interaction/ds-sync-interaction";
import {DsSyncLayout} from "./sync/layout/ds-sync-layout";
import datasources from "./data-sources.json";
import {isDevSonPham} from "../../../../../tools/dev/is-dev-sonpham";
import {IntervalRequestLoad} from "@common/react/interval-request-load";
import {toastServiceDownload} from "@common/ui-components/toast/toast-service-download";

export const EditDataSource = ({environment}) => {
    return cs(
        consumeContext("routing"),
        consumeContext("apis"),
        // consumeContext("themes"),
        // ({ apis, themes }, next) => themes && loadTheme({ collection: { themeID: themes.find((t) => t.default)?.id }, apis, next }),
        ["errorDataSourceDialog", (_, next) => ErrorDataSourceDialog({next})],
        ((_, next) => toastServiceDownload({theme: {}, next})),
        ["errorGoogleApiConnectionDialog", (_, next) => ErrorGoogleApiConnectionDialog({next})],
        [
            "dataSource",
            ({apis, routing}, next) =>
                // isDevSonPham()
                //     ? UseState({initValue: datasources, next})
                //     :
                Load2({
                    _key: routing.params.dsId,
                    fetch: () => apis.data.getDataSource(routing.params.dsId),
                    next,
                }),
        ],
        ({dataSource, routing}, next) =>
            dataSource.loading
                ? DsSyncLayout({
                      onBack: () => routing.goto("dashboard", {tab: "data-sources"}),
                      dataSource,
                  })
                : next(),
        ({routing, dataSource, apis, errorGoogleApiConnectionDialog, errorDataSourceDialog, submitting}) => {
            if ([dataSourceTypes.FILE, dataSourceTypes.GOOGLE_SHEETS].includes(dataSource.value.type)) {
                return cs(["interactions", (_, next) => UseState({initValue: null, next})], ({interactions}) =>
                    DsSyncLayout({
                        dataSource,
                        blurOverlay: true,
                        onBack: () =>
                            routing.goto("dashboard", {
                                tab: "data-sources",
                            }),
                        content: EditFlatFileStatus({
                            dataSource,
                            interactions,
                        }),
                        rightPanel: {
                            content: FlatFileInteraction({
                                interactions,
                                dataSource,
                            }),
                        },
                    })
                );
            }
            return cs(
                (_, next) => syncingProcessProvider({next}),
                ["dsSettings", (_, next) => UseState({initValue: {selectedTable: null}, next})],
                [
                    "tablesStatus",
                    (_, next) =>
                        IntervalRequestLoad({
                            _key: routing.params.dsId,
                            fetch: () => apis.data.getTablesStatus(routing.params.dsId),
                            next,
                        }),
                ],
                [
                    "reload",
                    ({tablesStatus}, next) =>
                        next(() => {
                            tablesStatus.reload();
                            dataSource.reload();
                        }),
                ],
                ({dsSettings, tablesStatus, reload}) => {
                    return DsSyncLayout({
                        dataSource,
                        onBack: () => routing.goto("dashboard", {tab: "data-sources"}),
                        content: EditSyncStatus({
                            dataSource,
                            dsSettings,
                            tablesStatus,
                            onReload: reload,
                        }),
                        rightPanel: {
                            content: DsSyncInteraction({
                                dsSettings,
                                dataSource,
                                tablesStatus,
                                onReload: reload,
                            }),
                        },
                    });
                }
            );
        }
    );
};
