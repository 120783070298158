export const transformTablesColumnsToMap = (tables) => {
    const map = {};
    tables?.forEach((t) => {
        const {columns} = t;
        map[t.id] = {};
        columns.forEach((c) => {
            map[t.id][c.id] = c;
        });
    });
    return map;
};
