const parseDateTime = (str) => {
    if (!str) {
        return null;
    }

    let m = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(?:\.(\d{1,20}))?Z?$/.exec(str);

    if (m == null || m.length == 0) {
        let x = /^(\d{1,2})\/(\d{1,2})\/(\d{4}) (\d{1,2}):(\d{1,2}):(\d{1,2})$/.exec(str);
        if (x != null && x.length > 0) m = [0, x[3], x[1], x[2], 0, 0, 0, 0];
    }

    if (m == null) return null;

    return {
        date: {
            year: +m[1],
            month: +m[2],
            day: +m[3],
        },
        time: {
            hours: +m[4],
            minutes: +m[5],
            seconds: +m[6],
            milliseconds: m[7] ? +m[7] : null,
        },
    };
};
exports.parseDateTime = parseDateTime;
