export const roundToNearest = [
    "Trillion",
    "Billion",
    "Million",
    "Hundred Thousand",
    "Ten Thousand",
    "Thousand",
    "Hundred",
    "Ten",
    "Whole Number",
    "Tenth",
    "Hundredth",
    "Thousandth",
    "Ten Thousandth",
    "Hundred Thousandth",
    "Millionth",
].map((item) => ({
    label: item,
    value: item.replace(/\s/g, ""),
}));
