import React from "react";

import {scope} from "@common/react/scope";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";

import {getPath} from "@common/utils/arr-path";

import {LeftPanelClosable} from "../../../../../../../common/left-panel-closable/left-panel-closable";
import {FieldItem} from "../../../../../../edit/common/field-item/field-item";
import {renderColor} from "../colors/colors-tile-config";

export const KpiColorsConfig = ({next: rootNext, tile, theme}) =>
    cs(["selecting", (_, next) => UseState({next})], ({selecting}) =>
        rootNext({
            render: (customIndex) => {
                const colors = theme.dataVisualization.dataColorPalettes.kpiColorsRGB;
                const tileTypesConfig = {
                    TableKPITile: [
                        {
                            label: "Table Text Color",
                            path: ["style", "valueColorApplication", "index"],
                        },
                        {
                            label: "Summary Text Color",
                            path: ["style", "summaryColorApplication", "index"],
                        },
                    ],
                    SingleKPITile: [
                        {
                            label: "Value Color",
                            path: ["style", "valueColorApplication", "index"],
                        },
                        {
                            label: "Icon Color",
                            path: ["style", "titleIconColor", "index"],
                        },
                    ],
                    SparkLineKPITile: [
                        {
                            label: "Value Color",
                            path: ["style", "valueColorApplication", "index"],
                        },
                        {
                            label: "Icon Color",
                            path: ["style", "titleIconColor", "index"],
                        },
                    ],
                    ListKPITile: [
                        {
                            label: "Icon Color",
                            path: ["style", "iconColorApplication", "index"],
                        },
                        {
                            label: "Label Color",
                            path: ["style", "labelColorApplication", "index"],
                        },
                        {
                            label: "Value Color",
                            path: ["style", "valueColorApplication", "index"],
                        },
                    ],
                    TextLabelTile: [
                        {
                            label: "Value Color",
                            path: ["style", "valueColorApplication", "index"],
                        },
                    ],
                };

                return (
                    <div>
                        {tileTypesConfig[tile.value.$type]
                            .filter((_, index) => (customIndex !== undefined ? index == customIndex : true))
                            .map((field, index) => {
                                return (
                                    <FieldItem
                                        className="field-color-bbg"
                                        label={
                                            <div>
                                                <div className="field-name">{field.label}</div>
                                                <div className="selected-color">
                                                    {renderColor["DiscreteColorApplication"]({
                                                        index: getPath(tile.value, field.path) || 0,
                                                        displayColor: colors[getPath(tile.value, field.path) || 0],
                                                    })}
                                                </div>
                                            </div>
                                        }
                                        rightIcon={<i className="far fa-arrow-right" aria-hidden="true" />}
                                        key={index}
                                        onClick={() => selecting.onChange(field)}
                                    />
                                );
                            })}
                    </div>
                );
            },
            override:
                selecting.value &&
                cs(
                    ({}, next) => (
                        <LeftPanelClosable
                            {...{
                                onClose: () => selecting.onChange(false),
                                label: selecting.value.label,
                                content: next(),
                            }}
                        />
                    ),
                    ({}) => {
                        const state = scope(
                            tile,
                            selecting.value.path.filter((p) => p != "index")
                        );

                        return (
                            <div className="colors-tile-config-vrf">
                                <div className="default-padding">
                                    {theme.dataVisualization.dataColorPalettes.kpiColorsRGB.map((color, index) => (
                                        <FieldItem
                                            label={
                                                <div>
                                                    COLOR {index + 1}
                                                    <div
                                                        className="discrete-color"
                                                        style={{
                                                            background: color,
                                                        }}
                                                    />
                                                </div>
                                            }
                                            key={index}
                                            rightIcon={(state.value?.index || 0) == index && <i className="material-icons">done</i>}
                                            onClick={() => {
                                                state.onChange({
                                                    index,
                                                    $type: "KPIColorApplication",
                                                });
                                            }}
                                        />
                                    ))}
                                </div>
                            </div>
                        );
                    }
                ),
        })
    );
