import "./shortest-possible-path-dialog.scss";

import React from "react";

import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {consumeContext} from "@common/react/context";
import {GlobalEvent} from "@common/react/global-event";

import {Button} from "@common/form/buttons/button/button";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";

import {DialogService} from "../../../../../../common/dialog/dialog-service";
import {TableSelect} from "../../../../../transformation/common/table-select/table-select";

import {getTargetedTables, getValidTables} from "./shortest-possible-path-common";

export const ShortestPossiblePathDialog = ({next: rootNext}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    render: ({resolve, args: {table, relationships, tables}}) => ({
                        title: "Highlight Shortest Possible Paths",
                        width: 480,
                        content: next({
                            resolve,
                            table,
                            relationships,
                            tables,
                        }),
                    }),
                    next: rootNext,
                }),
        ],
        ({modal}) =>
            cs(
                consumeContext("interactions"),
                ["tableLeft", (_, next) => UseState({next, initValue: modal.table.id})],
                ["tableRight", (_, next) => UseState({next})],
                [
                    "tableRightList",
                    ({tableLeft, tableRight}, next) => {
                        const table = modal.tables.find((t) => t.id == tableLeft.value);
                        if (table) {
                            const list = getTargetedTables({
                                table,
                                relationships: modal.relationships,
                                tables: modal.tables,
                            });
                            const isInList = list.find((t) => t.id == tableRight.value);
                            if (tableRight.value && !isInList) tableRight.onChange(null);
                            return next(list);
                        }

                        return next([]);
                    },
                ],
                ({tableLeft, tableRight, tableRightList, interactions}) => (
                    <div className="shortest-possible-path-dialog-a23">
                        <VerbDialogBodyScrollbar>
                            <div className="text-info">
                                Select the tables to highlight the shortest relationship paths.
                            </div>

                            {GlobalEvent({
                                fn: (e) => {
                                    if (e.key == "Enter" && tableLeft.value && tableRight.value) {
                                        interactions.showShortestPath({
                                            tableID: tableLeft.value,
                                            targetTableID: tableRight.value,
                                        });
                                        modal.resolve();
                                    }
                                },
                                eventName: "keydown",
                            })}

                            <div className="tables-wrapper">
                                <div className="table-col">
                                    {TableSelect({
                                        tables: getValidTables({
                                            tables: modal.tables,
                                            relationships: modal.relationships,
                                        }),
                                        label: "Table",
                                        state: {
                                            value: tableLeft.value,
                                            onChange: (table) => {
                                                tableLeft.onChange(table.id);
                                            },
                                        },
                                    })}
                                </div>

                                <div className="table-col">
                                    {TableSelect({
                                        tables: tableRightList,
                                        label: "Table",
                                        state: {
                                            value: tableRight.value,
                                            onChange: (table) => {
                                                tableRight.onChange(table.id);
                                            },
                                        },
                                    })}
                                </div>
                            </div>
                        </VerbDialogBodyScrollbar>

                        <div className="buttons">
                            <Button btnType="secondary" onClick={() => modal.resolve()}>
                                Cancel
                            </Button>
                            <Button
                                disabled={!tableLeft.value || !tableRight.value}
                                onClick={() => {
                                    interactions.showShortestPath({
                                        tableID: tableLeft.value,
                                        targetTableID: tableRight.value,
                                    });
                                    modal.resolve();
                                }}
                            >
                                Highlight Paths
                            </Button>
                        </div>
                    </div>
                )
            )
    );
