import "./x-axis.scss";

import * as React from "react";

import {ShowHideToggle} from "../../../../../../../../../../../../../common/form/toggles/show-hide-toggle";
import {LpLine} from "../../../../../../../../../../common/left-panel/line/lp-line";
import {scope} from "@common/react/scope";
import {OrientationSelect} from "../../../common/orientation-select";
import {XAxisDateGapsSelect} from "../../../common/x-axis-date-gaps-select";
import {MaxValInView} from "../../../common/max-val-in-view/max-val-in-view";
import {AxisTitle, XAxisLabel, XGridStepLabel} from "../../../combo-chart/panels/x-axis/x-axis-rules-comp";
import {getPath} from "@common/utils/arr-path";
import {getFieldType} from "@common/ui-components/charts/common/get-field-type";
// import {cs} from "@common/react/chain-services";
// import {GridStepTypeSelect} from "../../../common/grid-step-type-select";
// import {consumeContext} from "@common/react/context";
// import {TextareaInput} from "../../../common/textarea-input/textarea-input";

export const XAxis = ({tile, size}) => ({
    label: "X-Axis",
    render: () => {
        return (
            <div className="vertical-x-axis-1vp">
                <div className="subsection">
                    <div className="content">
                        {AxisTitle({
                            state: scope(tile, ["style", "xAxis", "label"]),
                        })}
                    </div>
                </div>

                <div className="subsection">
                    {XAxisLabel({
                        tile,
                        size,
                        state: scope(tile, ["style", "xAxis", "labelShown"]),
                    })}
                </div>

                {/*{scope(tile, ["style", "xAxis", "labelShown"]).value && <>*/}
                {/*    <div className="subsection">*/}
                {/*        <div className="header">*/}
                {/*            Axis Label Info Icon*/}
                {/*            <div className="control">*/}
                {/*                {ShowHideToggle({state: scope(tile, ["style", "xAxis", "labelInfoShown"])})}*/}
                {/*            </div>*/}
                {/*        </div>*/}

                {/*        <div className="content">*/}
                {/*            {TextareaInput({})}*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</>}*/}

                {/*{!tile.value.groupField && (*/}
                {/*    <div className="subsection grid-step">*/}
                {/*        <div className="header">*/}
                {/*            Grid Step*/}
                {/*        </div>*/}
                {/*        <div className="content">*/}
                {/*            {cs(*/}
                {/*                consumeContext("getFieldInfo"),*/}
                {/*                ({getFieldInfo}) => GridStepTypeSelect({*/}
                {/*                    state: scope(tile, ["style", "xAxis"]),*/}
                {/*                    fieldType: getFieldInfo(tile.value.xAxisField).type,*/}
                {/*                })*/}
                {/*            )}*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*)}*/}

                <div className="subsection">
                    {XGridStepLabel({
                        size,
                        state: scope(tile, ["style", "xAxis", "gridStepLabelsShown"]),
                    })}

                    {getPath(tile.value, ["style", "xAxis", "gridStepLabelsShown"]) && (
                        <div className="content">{OrientationSelect(scope(tile, ["style", "xAxis", "gridStepLabelOrientation"]))}</div>
                    )}
                </div>

                <div className="subsection">
                    <div className="header">Other Options</div>
                    <div className="content">
                        {MaxValInView({state: scope(tile, ["style", "xAxis"])})}
                        {LpLine({
                            label: "Axis Line",
                            control: ShowHideToggle({
                                state: scope(tile, ["style", "xAxis", "lineShown"]),
                            }),
                        })}
                        {LpLine({
                            label: "Grid Lines",
                            control: ShowHideToggle({
                                state: scope(tile, ["style", "xAxis", "gridLinesShown"]),
                            }),
                        })}
                        {getFieldType(tile.value["xAxisField"]) === "date" && XAxisDateGapsSelect(scope(tile, ["style", "xAxis", "dateGaps"]))}
                    </div>
                </div>
            </div>
        );
    },
});
