import {cs} from "@common/react/chain-services";
import {cx} from "emotion";
import {keyed} from "@common/react/keyed";
import {scope} from "@common/react/scope";
import {isBlank, isMatchText} from "@common/utils/strings";
import {SyncSettingItem} from "./sync-settings-item";
import {dsSyncHelpers} from "../../common/helpers";
import "./sync-settings-list.scss";
import {SyncSettingsHeader} from "../headers/sync-settings-header";

export const SyncSettingsList = ({dataSource, interactions, selectedTables, searchState, oriDs, adding, errorMessage, hasErrors = false, noSearch, onReload}) =>
    cs(() => {
        const searchVal = searchState.value?.value;
        const filteredTables = searchState.value.filter ?? ((t) => isMatchText(t.name, searchVal));
        const invalidTables = dsSyncHelpers.getInvalidTables(dataSource.value?.tables);

        return (
            <div className="sync-settings-list-88t sync-settings-list">
                {SyncSettingsHeader({
                    adding,
                    searchVal,
                    selectedTables,
                    dataSource,
                    onReload,
                    interactions,
                    $type: "Table",
                })}

                {[
                    ...dataSource.value?.tables,
                    ...(dataSource.value?.disabledTables.map((t) => ({
                        ...t,
                        disabled: true,
                    })) || []),
                ].filter(filteredTables).length === 0 && noSearch}

                <div
                    className={cx("list", {
                        adding: adding,
                        both: isBlank(searchVal) && selectedTables.value.length > 0,
                        "only-actions": selectedTables.value.length > 0 && !isBlank(searchVal),
                        "only-title": isBlank(searchVal) && selectedTables.value.length === 0,
                    })}
                >
                    {hasErrors && errorMessage}

                    {cs(
                        ["sorted", (_, next) => next([...oriDs.tables, ...(oriDs.disabledTables || [])].map((t) => t.name))],
                        [
                            "sortedList",
                            ({sorted}, next) =>
                                next(
                                    [...(dataSource.value?.tables || []), ...(dataSource.value?.disabledTables || [])].sort((a, b) => {
                                        const i1 = sorted.indexOf(a.name);
                                        const i2 = sorted.indexOf(b.name);

                                        return i1 === i2 ? 0 : i1 > i2 ? 1 : -1;
                                    })
                                ),
                        ],
                        ({sortedList}) =>
                            sortedList.map((table, i) =>
                                cs(
                                    keyed(i),
                                    (_, next) => (filteredTables(table) ? next() : null),
                                    (_, next) => {
                                        const tableIdx = dataSource.value?.tables.findIndex((t) => t.name === table.name);
                                        if (tableIdx > -1) {
                                            return SyncSettingItem({
                                                adding,
                                                hasAddingErrors: hasErrors && invalidTables.indexOf(table.name) > -1,
                                                isSyncing: adding || oriDs.tables.findIndex((t) => t.name === table.name) === -1,
                                                dataSource,
                                                remoteTable: scope(dataSource, ["tables", tableIdx]),
                                                selectedTables,
                                                interactions,
                                                onReload,
                                            });
                                        } else {
                                            return next();
                                        }
                                    },
                                    () => {
                                        const disabledTableIdx = (dataSource.value?.disabledTables || []).findIndex((t) => t.name === table.name);
                                        if (disabledTableIdx > -1) {
                                            return SyncSettingItem({
                                                dataSource,
                                                disabled: true,
                                                remoteTable: scope(dataSource, ["disabledTables", disabledTableIdx]),
                                                selectedTables,
                                                interactions,
                                                onReload,
                                            });
                                        }
                                    }
                                )
                            )
                    )}
                </div>
            </div>
        );
    });
