import {Button} from "../../../../../../../../common/form/buttons/button/button";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {DropdownSelectSearch} from "@common/ui-components/dropdown-select/dropdown-select-search";
import {first, flatten1, last} from "@common/utils/collections";
import {chain} from "@common/utils/fs";
import {DialogService} from "../../../../../common/dialog/dialog-service";
import {rFieldTypeIcon} from "../../../../../common/field-type-icon/get-field-type-icon";
import {tooltipService3} from "../../../../../common/tooltip3/tooltip-service-3";
import "./sync-method-dialog.scss";

const syncMethodList = ({tables}) => {
    return [
        {
            label: "Incremental",
            value: "Incremental",
            disabled: tables.length === 1 ? tables[0].columns.findIndex((c) => c.primaryKey) === -1 : tables.filter((t) => t.syncMethod !== "Incremental").length > 0,
            // tables[0].columns.filter((c) => ["DateTime", "DateTimeOffset", "Int", "Double", "IDInt"].indexOf(c.dataType) > -1).length === 0 : true,
            // tables.find(item => item.columns.findIndex(c => c.primaryKey) === -1),
            disabledText: tables.length === 1 ? "(No Numeric/Date Columns)" : "(Not Available)",
        },
        {
            label: "Change Data Capture",
            value: "ChangeDataCapture",
            disabled: !!tables.find((item) => !item.cdcAvailable),
            disabledText: "(Not Available)",
        },
        {
            label: "Change Tracking",
            value: "ChangeTracking",
            disabled: !!tables.find((item) => !item.ctAvailable),
            disabledText: "(Not Available)",
        },
        {label: "Full", value: "Full"},
    ];
};

export const SyncMethodDialog = ({next: rootNext}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    className: "sync-method-dialog-99p",
                    render: ({resolve, args: {syncSchedule, value, incrementalColumn, tables}}) => ({
                        title: cs(tooltipService3({direction: "below"}), ({tooltip}) => (
                            <>
                                Sync Method for{" "}
                                {tables.length === 1 ? (
                                    first(tables).name
                                ) : (
                                    <span
                                        {...tooltip(() => (
                                            <>
                                                {tables
                                                    .map((t) => t.name)
                                                    .slice(0, 10)
                                                    .join(", ")}

                                                {tables.length > 10 && (
                                                    <>
                                                        <br />
                                                        <div
                                                            style={{
                                                                fontStyle: "italic",
                                                            }}
                                                        >
                                                            (only the first 10 are shown)
                                                        </div>
                                                    </>
                                                )}
                                            </>
                                        ))}
                                    >
                                        {`${tables.length} Tables`}
                                    </span>
                                )}
                            </>
                        )),
                        content: next({
                            syncSchedule,
                            value,
                            resolve,
                            incrementalColumn,
                            tables,
                        }),
                    }),
                    next: rootNext,
                }),
        ],
        [
            "state",
            ({modal}, next) =>
                UseState({
                    initValue: {
                        syncMethod: modal.value,
                        incrementalColumn: modal.incrementalColumn,
                    },
                    next,
                }),
        ],
        ({state, modal}) => {
            let methodList = syncMethodList({tables: modal.tables});

            return (
                <div className="sync-method-dialog-99e">
                    <div className="dialog-body">
                        {DropdownSelect({
                            label: "Sync Method",
                            list: modal.syncSchedule?.$type === "OneTimeSync" ? [{label: "Full", value: "Full"}] : methodList,
                            isSelected: (v) => state.value.syncMethod === v.value,
                            onChange: (v) =>
                                state.change((s) => ({
                                    ...s,
                                    syncMethod: v.value,
                                })),
                            valueToLabel: (v) => v.label,
                        })}

                        {state.value.syncMethod === "Incremental" &&
                            DropdownSelectSearch({
                                searchPlaceholder: "Find Column",
                                label: "Column",
                                valueToSearch: (v) => v.name,
                                list: chain(
                                    modal.tables.map((t) => flatten1(t.columns)),
                                    (_) => flatten1(_),
                                    (_) => _.filter((c) => ["DateTime", "DateTimeOffset", "Int", "Double", "IDInt"].indexOf(c.dataType) > -1)
                                ),
                                valueToLabel: (v) => (
                                    <>
                                        {rFieldTypeIcon(v.dataType)} {v.name}
                                    </>
                                ),
                                isSelected: (v) => v.name === state.value.incrementalColumn,
                                onChange: (v) =>
                                    state.change((s) => ({
                                        ...s,
                                        incrementalColumn: v.name,
                                    })),
                            })}
                    </div>

                    <div className="buttons">
                        <Button btnType="secondary" onClick={() => modal.resolve(null)}>
                            Cancel
                        </Button>
                        <Button
                            disabled={state.value.syncMethod === "Incremental" && !state.value.incrementalColumn}
                            onClick={() => {
                                if (state.value.syncMethod === modal.value && state.value.incrementalColumn === modal.incrementalColumn) {
                                    modal.resolve(null);
                                    return;
                                }
                                modal.resolve(state.value);
                            }}
                        >
                            Done
                        </Button>
                    </div>
                </div>
            );
        }
    );
