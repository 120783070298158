import React from "react";
import {cs} from "@common/react/chain-services";
import {DropdownSelect2} from "@common/ui-components/dropdown-select/dropdown-select2";
import {stateToSelect} from "@common/ui-components/form/state-to-select";
import {UseState} from "@common/react/use-state";
import "./rank-configuration.scss";
import {NumberInput} from "../../../../../../../../../common/form/number-input/number-input";
import {ffToDropdown} from "../../../../../../../../../common/form/ff-to-dropdown";
import {ffToNumberInput} from "../../../../../../../../../common/form/ff-to-number-input";
import {SelectColumns} from "../../../../common/select-columns/select-columns";
import {rNewColumnNameInput} from "../../common/new-column-name-input";
//TODO: grouped css columns list

// Rank, DenseRank, RowNumber, NTile, PercentRank

export const RankConfiguration = ({step, form, transformation}) =>
    cs(["state", (_, next) => UseState({initValue: {}, next})], ({state}) => {
        const inputStep = transformation.value.steps.find((s) => s.id === step.value.inputStepID);

        return (
            <div className="rank-configuration-7rt">
                <div className="config-group">
                    <div className="label">Ranking Function</div>

                    <div className="select-groups">
                        {DropdownSelect2({
                            label: "Type",
                            list: [
                                {value: "Rank", label: "Rank"},
                                {value: "DenseRank", label: "Dense Rank"},
                                {value: "RowNumber", label: "Row Number"},
                                {
                                    value: "NTile",
                                    label: "Distribute Into Groups (NTILE)",
                                },
                                {value: "PercentRank", label: "Percent Rank"},
                            ],
                            valueToLabel: (v) => v.label,
                            ...ffToDropdown(form.field(["function"]), ["value"]),
                        })}

                        {form.data.value?.function === "NTile" &&
                            NumberInput({
                                label: "Number of Groups",
                                ...ffToNumberInput(form.field(["nTileGroups"])),
                            })}
                    </div>
                </div>

                <div className="config-group">
                    <div className="label">Ranking Column Name</div>

                    {rNewColumnNameInput({
                        form,
                        newColumnPath: ["newColumn"],
                        newColumnNamePath: ["newColumn", "name"],
                    })}
                </div>

                <div className="config-group">
                    <div className="label">Select columns to Partition by</div>

                    {SelectColumns({
                        step,
                        form,
                        path: ["partitionColumns"],
                        columns: inputStep?.outputColumns,
                    })}
                </div>

                <div className="config-group">
                    <div className="label">Select columns to Order by (Optional)</div>

                    {SelectColumns({
                        step,
                        form,
                        path: ["orderColumns"],
                        columns: inputStep?.outputColumns,
                        objectForm: {
                            columnProp: "column",
                            propertySelector: ({state, ...props}) =>
                                DropdownSelect2({
                                    label: "Order",
                                    list: [
                                        {label: "Ascending", value: "Asc"},
                                        {label: "Descending", value: "Desc"},
                                    ],
                                    valueToLabel: (v) => v.label,
                                    ...stateToSelect(state, ["value"]),
                                    ...props,
                                }),
                            propertyProp: "direction",
                        },
                    })}
                </div>
            </div>
        );
    });
