const orientationMap = {
    // "Auto"
    Horizontal: 0,
    Degrees30: -30,
    Degrees45: -45,
    Degrees60: -60,
    Vertical: -90,
};

const getOrientation = (orientation) => orientationMap[orientation];
exports.getOrientation = getOrientation;
