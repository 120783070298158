import {getFieldType} from "../../../common/get-field-type";
import {getTooltipFontSizes} from "./get-tooltip-font-sizes";

/**
 * used in bar chart, line chart and combo chart without comparison
 * */
export const getBarLineNonCompareHeader = ({tile, generalInfo, formatters, theme}) => {
    const fontSizes = getTooltipFontSizes(theme);

    return `<div class="non-compare-header" style="font-size: ${fontSizes.small}px">
            <div class="title">
                ${[getBarLineMeasureLabel({generalInfo, tile}), getDimStepLabel({tile, formatters, generalInfo})].filter((v) => v).join(" - ")}
            </div>
        </div>`;
};

/**
 * measure column header - the top left text of tooltip table
 * */
export const getBarLineMeasureLabel = ({generalInfo, tile}) => {
    if (tile.groupField) {
        return generalInfo.title;
    }

    const measureLabel = (() => {
        if (["VerticalBarChartTile", "LineChartTile"].includes(tile.$type)) {
            return tile.style.yAxis[generalInfo.customStack]?.label;
        }
        if (tile.$type === "HorizontalBarChartTile") {
            return tile.style.xAxis[generalInfo.customStack]?.label;
        }
        if (tile.$type === "ComboChartTile") {
            return (tile.style.yAxisLine[generalInfo.customStack] || tile.style.yAxisBar[generalInfo.customStack])?.label;
        }
    })();

    return measureLabel || generalInfo.defaultMeasureAxisTitle;
};

/**
 * measure column header - the top right text of tooltip table
 * */
export const getDimStepLabel = ({tile, formatters, generalInfo}) => {
    const dimDataType = getFieldType(tile.xAxisField || tile.yAxisField);

    if (dimDataType !== "date") {
        return formatters.dimensionFormatter(generalInfo.dimCategory);
    }

    return typeof generalInfo.dimCategory === "number" ? formatters.dateFormatter(generalInfo.dimCategory) || "N/A" : generalInfo.dimCategory;
};
