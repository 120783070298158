const {keepOnly} = require("../../../../../../../../../../../../../../common/utils/objects");
const getGroupSortingUpdated = ({fieldVal: {newVal, oriVal}}) => ({
    categorySort:
        newVal != null
            ? {
                  ...keepOnly(newVal, ["modelID", "modelTableID", "modelColumnID"]),
                  direction: "Asc",
              }
            : newVal,
});
exports.getGroupSortingUpdated = getGroupSortingUpdated;
