import * as React from "react";
import {Expandable} from "../../../../../../../../../../../common/expandable/expandable";
import {cs} from "@common/react/chain-services";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {spc} from "@common/react/state-path-change";
import {currencies} from "@common/ui-components/charts/factory/formatters/currencies";

export const CurrencySymbol = ({field}) =>
    cs(({}) => (
        <div className="currency-symbol-35s">
            {DropdownSelect({
                label: "Symbol",
                list: currencies,
                valueToLabel: (v) => v.symbol + " " + v.name,
                isSelected: (v) => v.abbreviation === (field.value.numericProperties?.currencyProperties?.currency || "USD"),
                onChange: (v) =>
                    spc(field, ["numericProperties"], (np) => ({
                        displayType: np.displayType,
                        decimalSeperator: v.numericDecimalSeperator,
                        thousandsSeperator: v.numericThousandsSeperator,
                        currencyProperties: {
                            currency: v.abbreviation,
                            currencySymbolPlacement: v.symbolPlacement,
                            currencySymbol: v.symbol,
                            spaceBetweenSymbolAndNumber: false,
                        },
                    })),
            })}
        </div>
    ));
