import "./model-tab.scss";

import * as React from "react";

import {scope} from "@common/react/scope";
import {keyed} from "@common/react/keyed";
import {Invoke} from "@common/react/invoke";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {Form2} from "@common/react/cs-form/form2";
import {spc} from "@common/react/state-path-change";
import {required} from "@common/react/cs-form/validators/required";

import {ffToTextInput} from "@common/form/ff-to-text-input";
import {TextInput} from "@common/form/text-input/text-input";

export const ModelTab = ({model, chart, next}) =>
    cs(({}) =>
        next({
            renderMain: () => chart.render(),
            renderLeftPanel: () =>
                cs(
                    [
                        "modelName",
                        (_, next) =>
                            UseState({
                                getInitValue: () => ({
                                    name: model.value.name,
                                    lastUpdatedAt: null,
                                }),
                                next: ({value, onChange}) =>
                                    next(
                                        scope({
                                            value,
                                            onChange: (v) => {
                                                return onChange({
                                                    name: v.name,
                                                    lastUpdatedAt: Date.now(),
                                                });
                                            },
                                        })
                                    ),
                            }),
                    ],
                    [
                        "form",
                        ({modelName}, next) =>
                            Form2({
                                // initShowErrors: true,
                                data: modelName,
                                fields: {
                                    name: {
                                        transforms: ["trim"],
                                        validators: [required],
                                        debounce: true,
                                    },
                                },
                                onSubmit: () => {
                                    spc(model, ["name"], () => modelName.value.name);
                                },
                                next,
                            }),
                    ],
                    ({form, modelName}) => (
                        <>
                            <div className="model-tab-sr4">
                                <TextInput
                                    {...{
                                        label: "Name",
                                        ...ffToTextInput(form.field("name")),
                                    }}
                                />
                            </div>
                            {modelName.value.lastUpdatedAt &&
                                modelName.value.name !== model.value.name &&
                                form.valid &&
                                cs(keyed(modelName.value.lastUpdatedAt), () =>
                                    Invoke({
                                        fn: () => {
                                            form.submit();
                                        },
                                    })
                                )}
                        </>
                    )
                ),
        })
    );
