import React from "react";
import {cs} from "@common/react/chain-services";
import {FieldSection} from "../../../../../../collection/tile/edit/left-panel/tabs/fields-tab/chart-types/common/field-section/field-section";
import {FieldInfoProvider} from "../../../../../../collection/tile/edit/left-panel/tabs/tile-tab/chart-types/common/field-info-provider/field-info-provider";
import {FontFamilyDropdown} from "../../../../../../collection/theme/common/theme-tab/font-family-dropdown/font-family-dropdown";
import {ffToDropdown} from "../../../../../../../../../common/form/ff-to-dropdown";
import {FontSizeSelect} from "../../../../../../collection/tile/edit/left-panel/tabs/tile-tab/chart-types/common/font-size-select";
import {LpColorLine} from "../../../../../../common/left-panel/lp-color-line";
import {ffToColorPickerLine} from "../../../../../../../../../common/form/ff-to-color-picker-line";
import {FontOptionsSelect} from "../../../../../../collection/tile/edit/left-panel/tabs/tile-tab/chart-types/table/panels/font-options-select/font-options-select";
import {CaseOptionSelect} from "../../../../../../collection/theme/common/case-options/case-options-select";
import {NumberInput} from "../../../../../../../../../common/form/number-input/number-input";
import {LpShowHideLine} from "../../../../../../common/left-panel/lp-show-hide-line";
import {ffToShowHideLine} from "../../../../../../../../../common/form/ff-to-show-hide-line";
import {ffToNumberInput} from "../../../../../../../../../common/form/ff-to-number-input";

export const TileTitleAndTheme = ({form}) =>
    cs(() => {
        return (
            <>
                {FieldSection({
                    header: FieldInfoProvider({
                        field: <div className="text">TITLES</div>,
                        noCollapsible: true,
                    }),
                    content: (
                        <>
                            {FontFamilyDropdown({
                                label: "Font Family",
                                ...ffToDropdown(form.field(["general", "tile", "styles", "titleFontFamily"])),
                                hasThemeDefaultOption: true,
                            })}

                            {FontSizeSelect({
                                label: "Font Size group",
                                ...ffToDropdown(form.field(["general", "tile", "styles", "titleFontSize"])),
                            })}

                            {LpColorLine({
                                label: "Font Color",
                                ...ffToColorPickerLine(form.field(["general", "tile", "styles", "titleFontColorRGB"])),
                            })}

                            {FontOptionsSelect({
                                label: "FONT STYLE",
                                state: form.field(["general", "tile", "styles", "titleFontOptions"]).state,
                            })}

                            {CaseOptionSelect({
                                label: "CASE STYLE",
                                state: form.field(["general", "tile", "styles", "titleCaseOptions"]).state,
                            })}
                        </>
                    ),
                })}

                {FieldSection({
                    header: FieldInfoProvider({
                        field: <div className="text">STYLES</div>,
                        noCollapsible: true,
                    }),
                    content: (
                        <>
                            {LpColorLine({
                                label: "Tile Background Color",
                                ...ffToColorPickerLine(form.field(["general", "tile", "styles", "tileBackgroundColorRGB"])),
                            })}

                            {LpColorLine({
                                label: "Tile Border Color",
                                ...ffToColorPickerLine(form.field(["general", "tile", "styles", "tileBorderColorRGB"])),
                            })}

                            {NumberInput({
                                label: "Border Width",
                                unit: "px",
                                ...ffToNumberInput(form.field(["general", "tile", "styles", "tileBorderWidth"])),
                                showZero: true,
                            })}

                            {NumberInput({
                                label: "Corner Radius",
                                unit: "px",
                                ...ffToNumberInput(form.field(["general", "tile", "styles", "tileCornerRadius"])),
                                showZero: true,
                            })}

                            {LpShowHideLine({
                                label: "Tile Shadow",
                                ...ffToShowHideLine(form.field(["general", "tile", "styles", "tileShadow"])),
                            })}
                        </>
                    ),
                })}
            </>
        );
    });
