import * as React from "react";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {DialogService} from "../../../../../common/dialog/dialog-service";
import {TextInput} from "../../../../../../../../common/form/text-input/text-input";
import {consumeContext} from "@common/react/context";
import "./delete-folder-dialog.scss";
import {Button} from "../../../../../../../../common/form/buttons/button/button";

export const DeleteFolderDialog = ({apiType, onDone, next: rootNext}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    render: ({resolve, args: {folder}}) => ({
                        title: "Delete Folder",
                        width: 500,
                        content: next({
                            resolve,
                            folder,
                        }),
                    }),
                    next: rootNext,
                }),
        ],
        consumeContext("apis"),
        ["confirmName", (_, next) => UseState({next})],
        ["submitting", (_, next) => UseState({next})],
        ({modal, confirmName, submitting, apis}) => (
            <div className="delete-folder-dialog-3pa">
                <div className="main-part dialog-body">
                    <div className="warning">Deleting the "{modal.folder.name}" Folder cannot be undone.</div>
                    <div className="acknowledgement">The contents of this folder will not be deleted - only moved to the current level of this folder.</div>
                    {TextInput({
                        label: <span className="confirm-input-label-35c">Type the name of the folder to confirm deletion.</span>,
                        state: confirmName,
                    })}
                </div>

                <div className="buttons">
                    <Button btnType="secondary" onClick={() => modal.resolve()}>
                        Cancel
                    </Button>
                    <Button
                        disabled={!confirmName.value || confirmName.value !== modal.folder.name || submitting.value}
                        onClick={async () => {
                            submitting.onChange(true);
                            const newFolders = await apis[apiType].deleteFolder({
                                folderId: modal.folder.id,
                                confirmName: confirmName.value,
                            });
                            onDone(newFolders);
                            submitting.onChange(false);
                            modal.resolve();
                        }}
                    >
                        Confirm
                    </Button>
                </div>
            </div>
        )
    );
