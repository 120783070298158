const {darken, generateColorScale} = require("../../../utils/color-util");
const {getColorConfigs, getFieldColorConfigs} = require("../get-field-color");
const {sum} = require("@common/utils/collections");
// const {tooltipCompareFormatter} = require("./tooltip-formatter");
// const {tooltipFormatter} = require("./tooltip-formatter");

const decorateData2 = ({series, tile, theme, size, formatters}) => {
    const {dataColorPalettes} = theme.dataVisualization;
    const colorConfigs = getColorConfigs(tile);
    const {displayColor, type} = getFieldColorConfigs({
        dataColorPalettes,
        colorConfigs,
    });
    let colorScale = generateColorScale(displayColor, type === "Sequential", 0, series[0].data.length);

    const transformData = ({data, colorModifier = (v) => v}) => {
        const total = sum(data, (d) => d[1]);

        return data.slice(0, tile.style.maxSlices || 200).map((d, i) => ({
            name: d[0] ?? "null", // replace null with "null" b/c highcharts would replace it with "Slice" otherwise
            y: d[1],
            realPercentage: d[1] == null ? null : (d[1] / total) * 100,
            color: colorModifier(colorScale.getColor(i)),
        }));
    };

    const distance = tile.style.dataLabels.position?.includes("Inside")
        ? -Math.min(Math.ceil((100 - (tile.style.holeDiameter || 0)) / 2), 30)
        : 10;
    const chartSize = tile.style.dataLabels.position?.includes("Inside") ? 100 : 60;

    if (series.length === 1) {
        // no comparison
        return series.map((s) => ({
            ...s,

            data: transformData({
                data: s.data.filter((x) => x[1] != null),
            }),

            ...(tile.$type === "DonutChartTile" && {
                innerSize: `${tile.style.holeDiameter}%`,
            }),
            size: chartSize + "%",
            dataLabels: {
                enabled: tile.style.dataLabels.show,
                distance: distance + "%",
            },
            // tooltip: {
            //     pointFormatter: function() {
            //         return tooltipFormatter({formatters, theme})(this);
            //     }
            // }
        }));
    }

    const getComparisonStyles = (s) => {
        if (tile.$type === "PieChartTile") {
            return s.isCompare
                ? {
                      size: "50%",
                      shadow: {
                          color: "#FFFFFF",
                          opacity: 0.25,
                      },
                      dataLabels: {
                          enabled: false,
                      },
                  }
                : {
                      innerSize: "50%",
                      size: chartSize + "%",
                      dataLabels: {
                          enabled: tile.style.dataLabels.show,
                          distance: distance + "%",
                      },
                  };
        }

        if (tile.$type === "DonutChartTile") {
            const hd = +tile.style.holeDiameter;
            const outerHD = 100 - (100 - hd) * 0.75;
            const innerOffset = hd >= 90 ? 0.95 : hd >= 60 ? 0.9 : 0.85;
            const innerHD = ((hd * innerOffset) / outerHD) * 100;

            return s.isCompare
                ? {
                      size: `${outerHD}%`,
                      innerSize: `${innerHD}%`,
                      dataLabels: {
                          enabled: false,
                      },
                  }
                : {
                      innerSize: `${outerHD}%`,
                      dataLabels: {
                          enabled: tile.style.dataLabels.show,
                          distance: tile.style.dataLabels.position?.includes("Inside")
                              ? `-${Math.min(Math.ceil((100 - outerHD) / 2), 30)}%`
                              : "20%",
                      },
                  };
        }
    };

    return series.map((s, index) => {
        // should not remove item has null value, it will cause errors if number of items of series are different.
        let data = s.data.map((x) => [x[0], x[1] ?? 0]);

        if (index !== 0) {
            const baseSeries = series[0].data.map((x) => [x[0], x[1] ?? 0]);
            const getIndex = (item) => baseSeries.findIndex((d) => d[0] == item);
            data = data.sort((d1, d2) => getIndex(d1[0]) - getIndex(d2[0]));
        }

        return {
            ...s,
            customStack: s.stack,
            data: transformData({data, colorModifier: s.isCompare ? (c) => darken(c, 20) : (v) => v}),
            center: ["50%", "50%"],

            ...getComparisonStyles(s),

            // tooltip: {
            //     pointFormatter: function() {
            //         return tooltipCompareFormatter({formatters, theme})(this);
            //     }
            // }
        };
    });
};

exports.decorateData2 = decorateData2;
