import * as React from "react";
import {LpLine} from "./line/lp-line";
import {ColorPicker} from "../color-picker/color-picker";
import {ColorSelect} from "../color-select/color-select";

export const LpColorLine = ({label, state, hasError, domRef, info, onRemove, hideReset, inheritColor}) =>
    LpLine({
        label,
        info,
        domRef,
        hasError,
        colorPicker: true,
        control: <ColorPicker {...{...state, hasError, onRemove, hideReset, inheritColor}} />,
    });

export const LpColorSelectLine = ({label, list, state, hasError, domRef, info, onRemove}) =>
    LpLine({
        label,
        info,
        domRef,
        hasError,
        colorPicker: true,
        control: <ColorSelect {...{...state, list, hasError, onRemove}} />,
    });
