const adjustBarDataLabels = ({chart, position}) => {
    if (position !== "OutsideRight") {
        return;
    }

    const mainBarSeries = chart.series.filter((s) => !s.userOptions.isCompare && (!s.userOptions.type || s.userOptions.type === "column"));

    const adjustEachSeries = (series) =>
        series.points.forEach((p) => {
            if (!p.dataLabel || !p.graphic?.element) {
                return;
            }
            const newDataLabelTranslateX = p.graphic.element.getBBox().x + series.barW;
            p.dataLabel.element.setAttribute("transform", `translate(${newDataLabelTranslateX}, ${p.dataLabel.alignAttr.y})`);
        });

    mainBarSeries.forEach((series) => adjustEachSeries(series));
};
exports.adjustBarDataLabels = adjustBarDataLabels;
