import {Expandable} from "../../../../common/expandable/expandable";
import * as React from "react";
import {filterDefaultValues} from "../filter-default-values";
import "./filter-left-panel.scss";
import {AggregationFieldSelect, FieldSelect} from "./field-select";
import {SelectFilterType} from "./select-filter-type";
import {NumberAggregationFunctionSelect} from "../../number-aggregation-function-select/number-aggregation-function-select";
import {scope} from "@common/react/scope";
import {FieldsSelected} from "./fields-selected/fields-selected";
import {cs} from "@common/react/chain-services";
import {StaticTooltipService} from "../../../../common/tooltip3/static-tooltip-service";
import {Button} from "../../../../../../../common/form/buttons/button/button";
import {BlanksMessage} from "../filter-edit-main/options/number-filter/blanks-message/blanks-message";

export const FilterLeftPanel = ({filter, form, override, isApiCollection}) =>
    cs(() => {
        const isMultipleFieldSelect = !isApiCollection;
        // TODO: will apply verbscrollbar later

        return (
            <div
                // style={{height: "calc(100% - 60px)"}}
                className="filter-left-panel-876"
            >
                {/* TODO show confirmation dialog on changing filter type */}
                {Expandable({
                    label: "Type",
                    alwaysShow: true,
                    initExpand: true,
                    render: () => (
                        <div className="panel-12s">
                            <div className="subsection">
                                <div className="content">
                                    {SelectFilterType({
                                        filter: filter.value,
                                        onChange: (type) => filter.onChange(filterDefaultValues(type)),
                                    })}
                                </div>
                            </div>
                        </div>
                    ),
                })}

                {filter.value && (
                    <>
                        {Expandable({
                            alwaysShow: true,
                            initExpand: true,
                            label: isMultipleFieldSelect
                                ? cs(
                                      [
                                          "staticTooltip",
                                          (_, next) =>
                                              StaticTooltipService({
                                                  direction: "right",
                                                  info:
                                                      filter.value?.$type === "DateFilter"
                                                          ? "Add multiple date fields and end-users will be able to select from multiple date fields."
                                                          : "You may include multiple fields in a single filter. The filter results may be matched to ANY or ALL fields as part of the filter configuration.",
                                                  tooltipBoxWidth: 200,
                                                  topOffset: 10,
                                                  next,
                                              }),
                                      ],
                                      ({staticTooltip}) => (
                                          <div className="multi-field-label">
                                              Fields
                                              {staticTooltip.renderIcon({
                                                  icon: <i className="material-icons">info</i>,
                                              })}
                                          </div>
                                      )
                                  )
                                : "Field",
                            render: () => (
                                <div className="panel-12s">
                                    <div className="subsection">
                                        <div className="content">
                                            {isMultipleFieldSelect ? (
                                                <>
                                                    <Button
                                                        size="medium"
                                                        btnType="primary"
                                                        className="add-field-btn"
                                                        onClick={() =>
                                                            override.onChange({
                                                                type: "add",
                                                                allowedFieldType:
                                                                    {
                                                                        NumericFilter: "number",
                                                                        BooleanFilter: "bool",
                                                                        DateFilter: "date",
                                                                    }[filter.value.$type] || "text",
                                                            })
                                                        }
                                                    >
                                                        Select a Field
                                                    </Button>

                                                    {FieldsSelected({
                                                        filter,
                                                        override,
                                                    })}
                                                </>
                                            ) : (
                                                FieldSelect({
                                                    filter,
                                                    field: form.field(["columns"]),
                                                    isMultiple: isMultipleFieldSelect,
                                                    label: "Value Field",
                                                })
                                            )}

                                            {filter.value.$type === "NumericFilter" &&
                                                BlanksMessage({
                                                    value: `Any number values left blank will not be visible. To avoid missing data please transform your data to make all blank number values “0”.`,
                                                })}

                                            {filter.value.$type === "BooleanFilter" &&
                                                BlanksMessage({
                                                    value: `Blank boolean values will be counted as a “False” value.`,
                                                })}
                                        </div>
                                    </div>
                                </div>
                            ),
                        })}

                        {filter.value.$type === "NumericFilter" &&
                            Expandable({
                                alwaysShow: true,
                                initExpand: true,
                                label: "Aggregation Settings",
                                render: () => (
                                    <div className="panel-12s">
                                        <div className="subsection">
                                            <div className="content">
                                                {NumberAggregationFunctionSelect({
                                                    state: scope(filter, ["aggregationFunc"]),
                                                    label: "Field Aggregation Type",
                                                })}
                                                {filter.value.aggregationFunc !== "None" &&
                                                    AggregationFieldSelect({
                                                        filter,
                                                        field: form.field(["aggregationField"]),
                                                    })}
                                            </div>
                                        </div>
                                    </div>
                                ),
                            })}
                    </>
                )}
            </div>
        );
    });
