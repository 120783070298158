import {InTheLastOrNextOrPrevious} from "./in-the-last-or-next-or-previous/in-the-last-or-next-or-previous";
import {BeforeOrAfter} from "./before-or-after/before-or-after";
import {InRange} from "./in-range/in-range";
import {InTheYear} from "./in-the-year/in-the-year";
import {InTheCurrent} from "./in-the-current/in-the-current";

export const editCustomRanges = {
    InTheLastOrNextCustomDateFilterRange: InTheLastOrNextOrPrevious,
    InThePreviousCustomDateFilterRange: InTheLastOrNextOrPrevious,
    // todo: @sonvn update this
    InTheCurrentCustomDateFilterRange: InTheCurrent,
    BeforeOrAfterCustomDateFilterRange: BeforeOrAfter,
    InRangeCustomDateFilterRange: InRange,
    InTheYearCustomDateFilterRange: InTheYear,
};
