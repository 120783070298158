const getNumberAggregationFuncs = ({notAllowNone} = {}) => (notAllowNone ? numberAggregationFuncs.slice(1) : numberAggregationFuncs);
exports.getNumberAggregationFuncs = getNumberAggregationFuncs;

const numberAggregationFuncs = [
    {
        label: "None",
        value: "None",
    },
    {
        label: "Count",
        value: "Count",
    },
    {
        label: "Count Inc Null",
        value: "CountIncNull",
    },
    {
        label: "Count Distinct",
        value: "CountDistinct",
    },
    {
        label: "Count Distinct Inc Null",
        value: "CountDistinctIncNull",
    },
    {
        label: "Count Null",
        value: "CountNull",
    },
    {
        label: "Sum",
        value: "Sum",
    },
    {
        label: "Average",
        value: "Average",
    },
    {
        label: "Median",
        value: "Median",
    },
    {
        label: "Minimum",
        value: "Minimum",
    },
    {
        label: "Maximum",
        value: "Maximum",
    },
    {
        label: "Standard Deviation Partial",
        value: "StandardDeviationPartial",
    },
    {
        label: "Standard Deviation - Population",
        value: "StandardDeviationPopulation",
    },
    {
        label: "Variation Partial",
        value: "VariationPartial",
    },
    {
        label: "Variance - Population",
        value: "VariationPopulation",
    },
    {
        label: "First Value Asc",
        value: "FirstValueAsc",
    },
    {
        label: "First Value Desc",
        value: "FirstValueDesc",
    },
    {
        label: "Last Value Asc",
        value: "LastValueAsc",
    },
    {
        label: "Last Value Desc",
        value: "LastValueDesc",
    },
];
