const {required} = require("@common/react/cs-form/validators/required");

export const collectionFormConfig = ({apis, collection}) => ({
    fields: {
        name: {
            transforms: ["trim"],
            validators: [required],
            debounce: true,
            asyncValidators: [
                {
                    validate: (name, {data}) =>
                        apis.collection.checkCollectionName(name, {
                            collectionId: data?.id,
                        }),
                    getMessage: () => `Existed`,
                },
            ],
        },
        modelID: [required],
        themeID: ["DashboardCollection", "SparkCollection"].includes(collection?.$type || collection?.type) ? [required] : [],
    },
});
