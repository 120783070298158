import * as React from "react";

import {cs} from "@common/react/chain-services";
import {RoutingParam} from "../../../../../common/routing/routing-param";

import {TileTab} from "./tile-tab/tile-tab";
import {FieldsTab} from "./fields-tab/fields-tab";
import {FilterTab} from "./filter-tab/filter-tab";
import {consumeContext} from "@common/react/context";
import {zeroID} from "../../../../../../common/constant";

export const disabledControlDataTileTypes = ["SectionTile", "TextTile"];

export const Tabs = ({tile, remoteTile, isSubTile = false, tileFuncs, form, size, renderMain, leftPanelWidth, next}) =>
    cs(
        consumeContext("collection"),
        [
            "selectedTab",
            (_, next) =>
                RoutingParam({
                    param: "tileTab",
                    defaultValue: "tile",
                    next,
                }),
        ],
        [
            "tabs",
            ({collection}, next) =>
                cs(
                    ["tileTab", (_, next) => TileTab({tile, isSubTile, tileFuncs, form, size, leftPanelWidth, next})],
                    ["filterTab", (_, next) => FilterTab({tile, next})],
                    ["fieldsTab", (_, next) => FieldsTab({tile, isSubTile, remoteTile, form, size, next})],
                    ({tileTab, filterTab, fieldsTab}) => {
                        const tileModelErrors = (collection.value?.id !== zeroID ? collection.value : tile)?.tileModelErrors;
                        return next([
                            {
                                id: "tile",
                                label: "Display",
                                control: tileTab,
                            },
                            {
                                id: "filters",
                                label: "Filters",
                                control: filterTab,
                                disabled: [...disabledControlDataTileTypes, "TextLabelTile"].includes(tile.value?.$type),
                                errorsNumber: tileModelErrors?.filter((e) => e.fieldLocation == "Filter" && e.tileID == tile.value?.id).length ?? 0,
                            },
                            {
                                id: "fields",
                                label: "Data",
                                control: fieldsTab,
                                disabled: disabledControlDataTileTypes.includes(tile.value?.$type),
                                errorsNumber: tileModelErrors?.filter((e) => e.location == "DataTab" && e.tileID == tile.value?.id).length ?? 0,
                            },
                        ]);
                    }
                ),
        ],
        ({tabs, selectedTab}) => {
            const activeTab = tabs.find((tab) => tab.id === selectedTab.value);

            return next({
                tabs: tabs.map((tab) => ({
                    ...tab,
                    hasError: tab.control?.hasError,
                    render: tab.control ? tab.control.render : () => "ZZ",
                })),
                selectedTab,
                getOverride: () => activeTab.control?.override,
                main: () => {
                    if (activeTab.control?.overrideAll) {
                        if (!activeTab.control?.overrideAll?.overlay) {
                            return activeTab.control?.overrideAll.main({size});
                        }
                        return renderMain?.({renderOverlay: () => activeTab.control?.overrideAll?.main?.({size})});
                    }

                    return renderMain?.({});
                },
            });
        }
    );
