import {cx} from "emotion";
import {Button} from "../../../../../../../../common/form/buttons/button/button";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {Invoke} from "@common/react/invoke";
import {Static2} from "@common/react/static-2";
import {UseState} from "@common/react/use-state";
import SpinnerIcon from "../../../../../../assets/icons/data-sources/SpinnerIcon";
import {InfoDialog} from "../../../../../common/info-dialog/info-dialog";
import "./drop-zone.scss";

const FlatFileInput = ({handleChangeFile, next}) =>
    cs(
        ["inputRef", (_, next) => Static2({next})],
        ["infoDialog", (_, next) => InfoDialog({next})],
        [
            "checkFileSize",
            (_, next) =>
                next((file) => {
                    console.log(file);
                }),
        ],
        [
            "handleSubmit",
            ({checkFileSize}, next) =>
                next((e) => {
                    e.preventDefault();
                    const file = e.target.files[0];
                    if (file) {
                        handleChangeFile(file);
                    }
                }),
        ],
        ({inputRef, handleSubmit}) => (
            <>
                <input
                    type="file"
                    multiple={false}
                    onChange={handleSubmit}
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    style={{display: "none"}}
                    ref={inputRef.set}
                />

                {next({
                    onClick: () => inputRef.get() && inputRef.get().click(),
                })}
            </>
        )
    );

const limitSize = 100 * 1024 * 1024;

export const DropZone = ({data, dsApi}) =>
    cs(
        consumeContext("apis"),
        ["dropZoneRef", (_, next) => Static2({next})],

        ["infoDialog", (_, next) => InfoDialog({next})],
        [
            "checkFileSize",
            ({infoDialog}, next) =>
                next((file) => {
                    if (file.size > limitSize) {
                        infoDialog.show({
                            title: "Error",
                            content: "The selected file is too large. Only files less than 100 MB are allowed.",
                        });
                        return false;
                    }
                    return true;
                }),
        ],

        ["loading", (_, next) => UseState({next})],

        [
            "handleChangeFile",
            ({loading, checkFileSize, apis}, next) =>
                next(async (file) => {
                    if (checkFileSize(file)) {
                        try {
                            loading.onChange(true);

                            let resp = await apis.data.uploadFile(file, data.value.id);

                            await dsApi.testConnection(
                                {
                                    submitConnectionDetails: resp.details,
                                    connectionDetails: resp.details,
                                },
                                {
                                    type: data.value?.type,
                                    name: resp.details.originalFileName,
                                    id: resp.dataSourceID,
                                },
                                true
                            );
                        } catch (e) {
                            console.log(e);
                        } finally {
                            loading.onChange(false);
                        }
                    }
                }),
        ],
        ["flatFileInput", ({handleChangeFile}, next) => FlatFileInput({handleChangeFile, next})],

        ({flatFileInput, dropZoneRef, handleChangeFile, loading}) => (
            <>
                {Invoke({
                    onMounted: () => {
                        const elem = dropZoneRef.get();

                        ["dragenter", "dragover"].forEach((item) => {
                            elem.addEventListener(item, (e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                elem.classList.add("active");
                            });
                        });

                        ["dragleave", "dragend"].forEach((item) => {
                            elem.addEventListener(item, (e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                elem.classList.remove("active");
                            });
                        });

                        elem.addEventListener("drop", (e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            elem.classList.remove("active");

                            const dataTransfer = e.dataTransfer;
                            // const mimeType = dataTransfer.items[0].type;
                            if (dataTransfer.items.length == 1) {
                                const file = dataTransfer.files[0];
                                handleChangeFile(file);
                            }
                        });
                    },
                })}

                <div className="drop-zone-99e">
                    {loading.value ? (
                        <div className={cx("loading-box")}>
                            {SpinnerIcon({})}
                            <div className="text">Uploading data file…</div>
                        </div>
                    ) : (
                        <div className="drop-area-overlay" ref={dropZoneRef.set}>
                            <div className="drop-area">
                                <i className="fa-light fa-cloud-arrow-up"></i>
                                <div className="text">Drop your Excel file or browse to upload</div>
                                <Button onClick={flatFileInput.onClick}>Browse</Button>
                            </div>
                        </div>
                    )}
                </div>
            </>
        )
    );
