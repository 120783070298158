import "./view-collections.scss";
import {Button} from "@common/form/buttons/button/button";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {Load2} from "@common/react/load2";
import {LoadingIndicator} from "@common/ui-components/loading-indicator/loading-indicator";
import {RecursiveTable} from "../../../common/recursive-table/recursive-table";
import {buildFoldersTree, groupCollections} from "../../../dashboard/env/collections/folders-tree-structure";
import {cx} from "emotion";
import React from "react";
import {createDateFormatter} from "@common/ui-components/charts/common/formatters/date-formatter";
import BulkPublishService from "./bulk-publish-service";
import {flatten1} from "@common/utils/collections";
import {UseState} from "@common/react/use-state";
import {SearchInputBar} from "@common/ui-components/search-input-bar/search-input-bar";
import {noun} from "@common/utils/plural";
import {Checkbox} from "@common/ui-components/form/checkbox/checkbox";

// view collections having a shared tile
export default function ViewCollections({onClose, sharedTile}) {
    const headerHeight = 56,
        footerHeight = 72,
        controlsHeight = 38,
        controlsMarginBottom = 16,
        padding = 20;

    return cs(
        (_, next) => {
            return (
                <div
                    className="view-collections-9d2"
                    style={{
                        "--header-height": `${headerHeight}px`,
                        "--footer-height": `${footerHeight}px`,
                        "--controls-height": `${controlsHeight}px`,
                        "--controls-margin-bottom": `${controlsMarginBottom}px`,
                        "--padding": `${padding}px`,
                    }}
                >
                    <div className="header">
                        <div className="label">
                            <div className="text">Collections with {sharedTile.adminTileName}</div>
                        </div>
                        <span className="material-icons-outlined close-icon" onClick={() => onClose()}>
                            close
                        </span>
                    </div>
                    {next()}
                </div>
            );
        },
        consumeContext("apis"),
        consumeContext("routing"),
        [
            "folders",
            ({apis, routing}, next) => {
                return Load2({
                    _key: routing.params?.envId,
                    fetch: async () => {
                        const folders = await apis.collection.getFolders();
                        return folders.map((folder) => ({
                            ...folder,
                            collections: folder.collections?.filter((c) => c.tiles?.findIndex((tile) => tile.tileID === sharedTile.id) > -1),
                        }));
                    },
                    next,
                });
            },
        ],
        ({folders}, next) => {
            if (!folders.value) {
                return LoadingIndicator();
            }

            return next();
        },
        [
            "bulkPublish",
            ({folders}, next) =>
                BulkPublishService({
                    collectionIds: flatten1(folders.value.map((f) => f.collections.map((c) => c.id))),
                    next,
                }),
        ],
        ["searchKey", (_, next) => UseState({next})],
        ["selected", (_, next) => UseState({next})],
        ({folders, routing, bulkPublish, searchKey, selected}) => {
            const isCheckingPublish = ({status, progress}) => !status && !(progress > 0);
            // const isPublishing = Object.values(bulkPublish.status).findIndex((s) => s.loading) > -1;
            const haveNoCollections = folders.value.every((f) => f.collections.length === 0);

            const rTable = () => {
                const tableHeight = `calc(100vh - ${headerHeight + footerHeight + controlsHeight + controlsMarginBottom + padding * 2}px)`;

                return RecursiveTable({
                    label: "Collections",
                    maxHeight: tableHeight,
                    className: "folders-table",
                    showNoResult: true,
                    renderNoResult: () => (
                        <div className="text-message">
                            No collections found
                            {searchKey.value && (
                                <div className="clear-search" onClick={() => searchKey.onChange(null)}>
                                    Clear Search
                                </div>
                            )}
                        </div>
                    ),
                    structure: buildFoldersTree({
                        itemsProp: "collections",
                        folders: folders.value,
                        hideEmptyFolder: haveNoCollections,
                        keyword: searchKey.value,
                        groupFn: groupCollections,
                    }),
                    isGroupNode: (n) => n.collections,
                    isAlwaysExpanded: () => true,
                    getTrClass: (n) => {
                        return !n.type ? "node-group" : "";
                    },
                    columns: [
                        {
                            label: "Name",
                            indented: true,
                            className: "name",
                            headerMinWidth: 150,
                            format: (c) => {
                                // folder
                                if (!c.type) {
                                    const folderColIds = c.collections.map((c) => c.id);

                                    const isFolderSelected = selected.value && folderColIds.length > 0 && folderColIds.every((cid) => selected.value.includes(cid));

                                    const disableCheckbox =
                                        !c.collections?.length ||
                                        !c.collections.every((c) => {
                                            const {status, progress} = bulkPublish.status[c.id];
                                            return !isCheckingPublish({status, progress});
                                        });

                                    return (
                                        <div className="folder">
                                            {Checkbox({
                                                disabled: disableCheckbox,
                                                state: {
                                                    value: isFolderSelected,
                                                    onChange: () => {
                                                        if (isFolderSelected) {
                                                            const newSelectedIds = selected.value.map((cid) => (folderColIds.includes(cid) ? null : cid)).filter((v) => v);
                                                            selected.onChange(newSelectedIds);
                                                        } else {
                                                            selected.onChange([...(selected.value || []), ...folderColIds]);
                                                        }
                                                    },
                                                },
                                            })}
                                            {c.name}
                                        </div>
                                    );
                                }

                                // collection of any $type
                                const {status, progress, loading} = bulkPublish.status[c.id];
                                const checkingPublish = isCheckingPublish({status, progress});

                                return (
                                    <div className="collection">
                                        {!checkingPublish && loading ? (
                                            <i className="fad fa-spinner-third fa-spin loading-icon" />
                                        ) : (
                                            Checkbox({
                                                disabled: checkingPublish,
                                                state: {
                                                    value: selected.value?.findIndex((cid) => cid === c.id) > -1,
                                                    onChange: () =>
                                                        selected.onChange(
                                                            selected.value?.includes(c.id) ? selected.value.filter((cid) => cid !== c.id) : [...(selected.value || []), c.id]
                                                        ),
                                                },
                                            })
                                        )}
                                        {c.name}
                                    </div>
                                );
                            },
                        },
                        {
                            label: "Last modified by",
                            format: (c) => {
                                return c.versionInfo?.editedBy;
                            },
                        },
                        {
                            label: "Last modified on",
                            format: (c) => {
                                if (!c.type) {
                                    return "";
                                }

                                const editedOn = new Date(c.versionInfo?.editedOnUtc);
                                return createDateFormatter("d/M/yy @ h:mm tt").format(editedOn);
                            },
                        },
                        {
                            label: "Published on",
                            className: "published-on",
                            format: (c) => {
                                if (!c.type) {
                                    return "";
                                }

                                // if (c.name === "luan test") {
                                //     console.log(c.id, bulkPublish.status[c.id]);
                                // }

                                const {status, progress, loading} = bulkPublish.status[c.id];

                                if (isCheckingPublish({status, progress})) {
                                    return "Checking";
                                }

                                if (loading) {
                                    return `Publish ${Math.round(progress * 100)}% complete`;
                                }

                                const lastPublishedText = !status.publishedOn ? (
                                    "Never published"
                                ) : (
                                    <span>
                                        {createDateFormatter("d/M/yy @ h:mm tt").format(new Date(status.publishedOn))}
                                        <i className="far fa-check published" />
                                    </span>
                                );

                                if (status.status === "Error") {
                                    return status.total === status.errors
                                        ? "Error"
                                        : `Partial Success (${Math.round(((status.total - status.errors) / status.total) * 100)}% complete)`;
                                }

                                return lastPublishedText;
                            },
                        },
                        {
                            className: "goto-edit",
                            sticky: true,
                            format: (c) => {
                                if (!c.type) {
                                    return "";
                                }
                                return <i className="far fa-arrow-right" onClick={() => routing.goto("edit-collection", {colId: c.id})} />;
                            },
                        },
                    ],
                });
            };

            return (
                <>
                    <div className="content">
                        <div className="controls">
                            {!!selected.value?.length && (
                                <Button
                                    className="selected-items"
                                    btnType="secondary"
                                    size="medium"
                                    iconRight={<i className="fa-regular fa-xmark" />}
                                    onClick={() => selected.onChange(null)}
                                >
                                    {selected.value.length} {noun(selected.value.length, "Collection")} Selected
                                </Button>
                            )}
                            <SearchInputBar state={searchKey} />
                        </div>
                        <div className="folders-table-wrapper">{rTable()}</div>
                    </div>

                    <div className="footer">
                        <Button
                            disabled={!selected.value?.length}
                            onClick={() => {
                                bulkPublish.publish(selected.value);
                                selected.onChange(null);
                            }}
                        >
                            Publish
                        </Button>
                    </div>
                </>
            );
        }
    );
}
