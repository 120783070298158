exports.timeFormats = [
    {
        label: "5 PM",
        format: "h tt",
    },
    {
        label: "5:00 PM",
        format: "h:mm tt",
    },
    {
        label: "17",
        format: "HH",
    },
    {
        label: "17:00",
        format: "HH:mm",
    },
    {
        label: "1700",
        format: "HHmm",
    },
];
