const formToSingleFieldWell = (form, path) => {
    const formField = form.field(path);
    return {
        field: formField.state,
        hasError: formField.error,
        domRef: formField.domRef,
        errorMessage: formField.getErrorMessage?.(),
    };
};
exports.formToSingleFieldWell = formToSingleFieldWell;
