import {cs} from "../../react/chain-services";
import {DebounceNumber} from "../debounce-number";
import {NumberInput} from "../number-input/number-input";

export const DebounceNumberInput = ({state, label, ...props}) =>
    cs(
        [
            "debounce",
            (_, next) =>
                DebounceNumber({
                    state,
                    next,
                }),
        ],
        ({debounce}) =>
            NumberInput({
                label,
                state: debounce.state,
                onBlur: debounce.flush,
                ...props,
            })
    );
