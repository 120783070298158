import {cs} from "@common/react/chain-services";
import {Invoke} from "@common/react/invoke";
import {UseState} from "@common/react/use-state";
import {waitUntil} from "@common/utils/wait-until";

export const ObserveSyncingService = ({fetch, next}) =>
    cs(["state", (_, next) => UseState({initValue: null, next})], ({state}) => {
        const fetchSyncingTillDone = async () => {
            let _syncingTables;

            await waitUntil(
                async () => {
                    try {
                        _syncingTables = await fetch();
                    } catch (e) {
                        console.log(e);
                    }

                    state.onChange(_syncingTables);
                    if (_syncingTables.length === 0) return true;
                },
                {timeLimit: 10 * 60 * 1000, intervalDelay: 30 * 1000}
            );
        };

        return (
            <>
                {next({
                    tablesSyncing: state.value,
                    start: () => state.onChange(null),
                })}

                {state.value == null &&
                    Invoke({
                        fn: () => fetchSyncingTillDone(),
                    })}
            </>
        );
    });
