import {Button} from "../../../../../../common/form/buttons/button/button";
import {cs} from "@common/react/chain-services";
import {keyed} from "@common/react/keyed";
import {SyncSettingErrorItem} from "../sync-settings/list/sync-settings-error-item";
import {SyncErrors} from "../sync/errors/sync-errors";
import "./unresolved-errors.scss";
import {DsClearErrorDialog} from "../common/clear-error-dialog/ds-clear-error-dialog";
import {NavigateSyncSettings} from "../sync/common/navigate-sync-settings";
import {consumeContext} from "@common/react/context";
import InSyncIcon from "../../../../assets/icons/data-sources/InSyncIcon";

export const UnresolvedErrors = ({dataSource}) =>
    cs(
        (_, next) => (!dataSource.value ? null : next()),
        () => {
            const errors = [...(dataSource.value?.errors || [])];

            const dsErrors = errors.filter((e) => !e.dataSourceTableID);
            const tablesErrors = errors.filter((e) => e.dataSourceTableID);

            return (
                <div className="unresolved-errors-99i">
                    {errors.length === 0 ? (
                        <div className="no-errors">
                            {InSyncIcon({fill: "#1AC689"})}
                            <div className="text">No unresolved errors</div>
                        </div>
                    ) : (
                        <>
                            {dsErrors.length === 0 &&
                                tablesErrors.length > 0 &&
                                cs(
                                    consumeContext("routing"),
                                    [
                                        "goSyncSettings",
                                        ({routing}, next) =>
                                            cs(
                                                [
                                                    "navigateSyncSettings",
                                                    (_, next) =>
                                                        NavigateSyncSettings({
                                                            next,
                                                        }),
                                                ],
                                                ({navigateSyncSettings}) => next(() => navigateSyncSettings.goto())
                                            ),
                                    ],
                                    ["clearErrorDialog", (_, next) => DsClearErrorDialog({next})],
                                    ({clearErrorDialog, goSyncSettings, routing}) => (
                                        <div className="buttons">
                                            <Button onClick={goSyncSettings}>Sync Settings</Button>
                                            &nbsp;&nbsp;
                                            <Button
                                                btnType="danger"
                                                onClick={async () => {
                                                    const resp = clearErrorDialog.show({
                                                        dsID: routing.params.dsId,
                                                        goSyncSettings,
                                                        errorTablesIDsSelected: tablesErrors.map((t) => t.dataSourceTableID),
                                                    });

                                                    if (resp) {
                                                        dataSource.reload();
                                                    }
                                                }}
                                            >
                                                Clear Errors
                                            </Button>
                                        </div>
                                    )
                                )}

                            {SyncErrors({errors: dsErrors, hasActions: true})}

                            {tablesErrors.map((error, j) =>
                                cs(keyed(j), () =>
                                    SyncSettingErrorItem({
                                        tableId: error.dataSourceTableID,
                                        tableName: dataSource.value.tables.find((t) => t.id === error.dataSourceTableID)?.name,
                                        shortVersion: true,
                                        hasDataSourceErrors: dsErrors.length > 0,
                                        error,
                                    })
                                )
                            )}
                        </>
                    )}
                </div>
            );
        }
    );
