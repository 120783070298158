import "./filter-tab.scss";

import * as React from "react";

import {cs} from "@common/react/chain-services";
import {ExpandableFilters} from "./local-filters/local-filters";
import {UseState} from "@common/react/use-state";
import {EditLocalFilter} from "./edit-local-filter/edit-local-filter";
import {spc} from "@common/react/state-path-change";
import {Button} from "@common/form/buttons/button/button";
import {filterIcons} from "../../../../../edit/tabs/filters/left-panel/filters-in-collection/filters-in-collection";
import {consumeContext} from "@common/react/context";

export const FilterTab = ({tile, childTileFilters, next}) =>
    cs(
        consumeContext("collection"),
        [
            "state",
            (_, next) =>
                UseState({
                    initValue: {editing: false},
                    next,
                }),
        ],
        ({state, collection}) =>
            next({
                render: () => (
                    <div className="filter-tab-4so">
                        <div className="add-new-filter">
                            <Button className="btn" onClick={() => state.onChange({editing: true})}>
                                Add New Filter
                            </Button>
                        </div>

                        <ExpandableFilters
                            label="Local Filters"
                            list={tile.value?.filters}
                            onClickItem={(item) => state.onChange({editing: true, filter: item})}
                            renderIcon={(item) => <img src={filterIcons[item.$type]} className="field-type-icon" alt="" />}
                            hasError={(item) => {
                                for (let column of item.columns) {
                                    const found = collection?.value?.tileModelErrors?.find((c) => c.modelColumnID == column.modelColumnID);
                                    if (found) {
                                        return found;
                                    }
                                }

                                return false;
                            }}
                        />

                        {childTileFilters?.()}
                    </div>
                ),
                override:
                    state.value.editing &&
                    EditLocalFilter({
                        tile: tile.value,
                        initFilter: state.value.filter,
                        tileType: tile.value.$type,
                        onDone: (filter) => {
                            if (filter.id) {
                                spc(tile, ["filters"], (filters) => filters.map((fi) => (fi.id === filter.id ? filter : fi)));
                            } else {
                                spc(tile, ["filters"], (filters) => [...filters, filter]);
                            }
                            // state.onChange({editing: false});
                        },
                        onCancel: () => {
                            state.onChange({editing: false});
                        },
                        onDelete: () => {
                            spc(tile, ["filters"], (filters) => filters.filter((filter) => filter.id !== state.value.filter.id));
                            state.onChange({editing: false});
                        },
                    }),
            })
    );
