import React from "react";
import ReactDOM from "react-dom";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {TileTitleAndTheme} from "./title-and-style/tile-title-and-theme";
import {cx} from "emotion";
import {SuspendUpdate} from "@common/react/suspend-update";
import {LeftPanelClosable} from "../../../../../common/left-panel-closable/left-panel-closable";
import {Static2} from "@common/react/static-2";
import {ContainerTilesTheme} from "./container-tiles/container-tiles-theme";
import {ThemeLeftPanelSections} from "../../../common/theme-left-panel-sections/theme-left-panel-sections";

export const TilesThemeTheme = ({form, overrideLeftPanelRef, viewTree}) =>
    cs(
        ["ref", (_, next) => Static2({next})],
        [
            "sections",
            ({ref}, next) =>
                next([
                    {
                        label: "TILE TITLES & STYLES",
                        render: (form) => TileTitleAndTheme({form, viewTree}),
                        id: "tile-styles",
                    },
                    {
                        label: "Container Tiles",
                        render: (form) =>
                            ContainerTilesTheme({
                                form,
                                overrideLeftPanelRef: ref,
                                viewTree,
                            }),
                        id: "container",
                    },
                ]),
        ],
        ({sections, ref}) =>
            ThemeLeftPanelSections({
                sections,
                overrideLeftPanelRef,
                parentRef: ref,
                form,
                viewTree,
            })
    );
