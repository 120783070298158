import {getDataLabelsStyle} from "../../factory/data-labels";
import {scatterPlotDataLabelFieldAttrs} from "../../../../../web-client/src/routes/collection/tile/edit/left-panel/tabs/tile-tab/chart-types/scatter-plot/panels/data-labels/data-labels";

// used for both scatter plot and bubble chart
export const getScatterDataLabelsOptions = ({tile, theme, formatters}) => {
    const labelField = scatterPlotDataLabelFieldAttrs.find((attr) => tile[attr]?.id === tile.style.dataLabels.labelFieldID);
    const defaultLabelAttr = scatterPlotDataLabelFieldAttrs.find((attr) => tile[attr]);

    return {
        enabled: tile.style.dataLabels.show,
        // allowOverlap: true,

        style: getDataLabelsStyle({
            dataLabelsConfig: tile.style.dataLabels,
            theme,
        }).style,
        // autoRound: tile.style.autoRound,

        // useHTML: true,
        formatter: function () {
            // const {x, y, z} = this.point;
            const {name, colorGroupName, shapeGroupName, customType} = this.point.series.userOptions;

            if (customType === "IndicatorReferenceLine") {
                return formatters.measurementFormatters[tile.yAxisField.id](this.point.y);
            }

            const labelMap = {
                dimensionField: name,
                colorGroupField: colorGroupName,
                shapeGroupField: shapeGroupName,
            };
            return labelMap[labelField || defaultLabelAttr] || name;
        },
    };
};
