import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {flatten1} from "@common/utils/collections";
import {TabsHeader} from "../../../../../common/tabs/tabs-header";
import {SyncSettingsList} from "../sync-settings-list";
import {NoSearchesFound} from "./no-searches-found";
import {SyncSettingsColumnsSearches} from "./sync-settings-columns-searches";
import "./sync-settings-searches.scss";
import {isMatchText} from "@common/utils/strings";

export const Searches = {
    TABLE: "table",
    COLUMN: "column",
};

export const SyncSettingsSearches = ({dataSource, hasErrors, searchState, interactions, selectedTables, oriDs, clearSearch, adding}) =>
    cs(
        ["searchTab", (_, next) => UseState({initValue: Searches.TABLE, next})],
        [
            "getSearchesColumns",
            (_, next) =>
                next(() => {
                    const filteredColumns = (c) => (searchState.value.filter ? false : isMatchText(c.name, searchState.value.value));

                    return flatten1(
                        [...dataSource.value.tables, ...dataSource.value.disabledTables].map((table, i) =>
                            flatten1([
                                table.columns.filter(filteredColumns).map((c) => ({
                                    ...c,
                                    tableName: table.name,
                                })),
                                table.disabledColumns.filter(filteredColumns).map((c) => ({
                                    ...c,
                                    tableName: table.name,
                                })),
                            ])
                        )
                    );
                }),
        ],
        ({searchTab, getSearchesColumns}) => {
            const columns = getSearchesColumns();
            const searchVal = searchState.value.value;

            return (
                <div className="sync-settings-searches-88p">
                    <div className="header">
                        <div className="search">
                            <div className="text">“{searchVal}” search results</div>
                            <div className="clear" onClick={clearSearch}>
                                Clear result
                            </div>
                        </div>

                        {TabsHeader({
                            tabs: [
                                {
                                    label: "Tables",
                                    name: Searches.TABLE,
                                    numbers: () => {
                                        const filtered = searchState.value.filter ?? ((t) => isMatchText(t.name, searchVal));
                                        return (
                                            dataSource.value.tables.filter(filtered).length +
                                            dataSource.value.disabledTables
                                                .map((t) => ({
                                                    ...t,
                                                    disabled: true,
                                                }))
                                                .filter(filtered).length
                                        );
                                    },
                                },
                                {
                                    label: "Columns",
                                    name: Searches.COLUMN,
                                    numbers: () => columns.length,
                                },
                            ].map((item) => ({
                                ...item,
                                active: item.name === searchTab.value,
                                label: (
                                    <>
                                        {item.label} ({item.numbers()})
                                    </>
                                ),
                                onClick: (item) => searchTab.onChange(item.name),
                            })),
                        })}
                    </div>

                    {Searches.TABLE === searchTab.value &&
                        SyncSettingsList({
                            hasErrors,
                            searchState,
                            dataSource,
                            interactions,
                            selectedTables,
                            oriDs,
                            adding,
                            noSearch: NoSearchesFound({}),
                        })}

                    {Searches.COLUMN === searchTab.value &&
                        SyncSettingsColumnsSearches({
                            dataSource,
                            columns,
                            noSearch: NoSearchesFound({type: "columns"}),
                        })}
                </div>
            );
        }
    );
