export function delayTooltipMobile(H) {
    H.Chart.prototype.callbacks.push(function (chart) {
        chart.container.ontouchstart = function (e) {
            // console.log(chart.container.firstChild.children)

            chart.container.classList.remove("active");
            chart.tooltip?.container?.classList.remove("active");

            chart.tooltipShowTimeout = setTimeout(() => {
                chart.container.classList.add("active");
                chart.tooltip?.container?.classList.add("active");
            }, 300);
        };

        chart.container.ontouchmove = function (e) {
            chart.tooltipShowTimeout && clearTimeout(chart.tooltipShowTimeout);
            chart.tooltipConnectorDrawTimeout && clearTimeout(chart.tooltipConnectorDrawTimeout);
        };

        // chart.container.ontouchend = function (e) {
        //     // chart.tooltipTimeout && clearTimeout(chart.tooltipTimeout);
        // }
    });
}
