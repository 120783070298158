import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {DialogService} from "../../../../../common/dialog/dialog-service";
import {UseState} from "@common/react/use-state";
import {MapModelFieldsService} from "../../../../../common/map-model-fields/map-model-fields-service";
import {getFieldsModellingProp} from "../../../../../model/edit/tabs/common/get-using-collections";
import {tileFieldAttrs} from "../../../../../common/tile-field-attrs";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";
import {Button} from "@common/form/buttons/button/button";
import {chain} from "@common/utils/fs";
import {getTileWithMappedFields} from "../../../../../common/map-model-fields/get-clone-with-mapped-fields";
import {removeIdsInTile} from "../../../../../common/remove-ids";

export const MapFieldsForCloningSharedTileDialog = ({next: rootNext, destinationModelId, tile, onDone}) =>
    cs(
        consumeContext("apis"),
        [
            "modal",
            (_, next) =>
                DialogService({
                    render: ({resolve, args: {cloneName}}) => ({
                        title: "Map Fields",
                        width: 720,
                        content: next({
                            resolve,
                            cloneName,
                        }),
                    }),
                    next: rootNext,
                }),
        ],
        ["cloning", (_, next) => UseState({next})],
        ["error", (_, next) => UseState({next})],
        [
            "mapFieldsService",
            (_, next) =>
                MapModelFieldsService({
                    originFields: getTileFields(tile),
                    originModelId: tile.modelID,
                    destinationModelId,
                    next,
                }),
        ],
        ({modal, mapFieldsService, apis, error, cloning}) => {
            return (
                <div className="map-fields-for-cloning-collection-dialog-5s3">
                    <VerbDialogBodyScrollbar>
                        <div className="messages">
                            <div className="message">
                                Creating a new tile that uses a different model requires you to map the fields from this model to the new
                                model.
                            </div>
                            {error.value && (
                                <div className="message error">
                                    Sorry, there is an issue cloning this tile.
                                    <div>{error.value}</div>
                                </div>
                            )}
                        </div>
                        {mapFieldsService.renderMapTable()}
                    </VerbDialogBodyScrollbar>
                    <div className="buttons">
                        <Button
                            disabled={
                                mapFieldsService.fieldsMap == null ||
                                mapFieldsService.fieldsMap.map((fm) => fm.destinationModelField).filter((v) => v).length !==
                                    mapFieldsService.fieldsMap.length ||
                                cloning.value
                            }
                            onClick={async () => {
                                const cloneTile = chain(
                                    {
                                        ...tile,
                                        adminTileName: modal.cloneName,
                                        modelID: destinationModelId,
                                    },
                                    (sTile) =>
                                        mapFieldsService.fieldsMap?.length > 0
                                            ? getTileWithMappedFields(sTile, mapFieldsService.fieldsMap)
                                            : sTile,
                                    (sTile) => removeIdsInTile(sTile)
                                );
                                try {
                                    cloning.onChange(true);
                                    const newSharedTile = await apis.collectionTiles.upsertTile(cloneTile);
                                    onDone(newSharedTile);
                                    modal.resolve();
                                } catch (e) {
                                    error.onChange(e.message);
                                    cloning.onChange(false);
                                }
                            }}
                        >
                            Done
                        </Button>
                    </div>
                </div>
            );
        }
    );

export const getTileFields = (tile) => {
    let fields = [];

    const isIncluded = (field) => fields.find((cf) => cf.modelTableID === field.modelTableID && cf.modelColumnID === field.modelColumnID);

    const getFields = getFieldsModellingProp();

    const getTileFields = (tile) => {
        // get tile fields
        if (tile.$type === "ContainerTile") {
            tile.tiles?.forEach((t) => getTileFields(t));
        } else {
            const attrs = tileFieldAttrs[tile.$type];
            attrs?.forEach((attr) => {
                const fields = getFields(attr, tile);
                fields.forEach((field) => !isIncluded(field) && fields.push(field));
            });
        }

        // get tile filters fields
        tile.filters?.forEach((filter) => filter.columns.forEach((c) => !isIncluded(c) && fields.push(c)));

        // get tile's tileActions' fields
        tile.tileActions?.forEach((tileAction) => {
            tileAction.tile && getTileFields(tileAction.tile);
            tileAction.filters?.forEach((filter) => filter.columns.forEach((c) => !isIncluded(c) && fields.push(c)));
        });

        // tile sort and limit fields are taken from tile fields, so no need to care
    };

    getTileFields(tile);

    // fields in filters do not have dataType, so put them at bottom for now
    // collection.filters?.forEach((filter) => filter.columns.forEach((c) => !isIncluded(c) && fields.push(c)));

    return fields;
};
