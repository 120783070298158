import React from "react";

import {cs} from "@common/react/chain-services";
import {DropdownSelect2} from "@common/ui-components/dropdown-select/dropdown-select2";
import {UseState} from "@common/react/use-state";
import {spc} from "@common/react/state-path-change";
import {rNewColumnNameInput} from "../../common/new-column-name-input";

export const TextManipulationResultActionSelect = ({state, form}) =>
    cs(
        [
            "state1",
            (_, next) =>
                UseState({
                    initValue: state?.value?.overrideColumn ? "Override Input Column" : "New Column",
                    next,
                }),
        ],
        ({state1}) => (
            <>
                <div className="config-group">
                    <div className="label">Result</div>

                    {DropdownSelect2({
                        label: "Action",
                        list: ["Override Input Column", "New Column"],
                        isSelected: (v) => v === state1.value,
                        onChange: (v) => {
                            spc(state, ["overrideColumn"], () => v === "Override Input Column");
                            state1.onChange(v);
                        },
                    })}
                </div>

                {state1.value === "New Column" && (
                    <div className="config-group">
                        <div className="label">Column Name</div>

                        {rNewColumnNameInput({
                            form,
                            newColumnPath: ["textManipulation", "newColumn"],
                            newColumnNamePath: ["textManipulation", "newColumn", "name"],
                        })}
                    </div>
                )}
            </>
        )
    );
