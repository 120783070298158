import * as React from "react";
import {Display} from "./panels/display";
import {SectionLabels} from "./panels/section-labels";
import {Colors} from "../pie-chart/panels/colors/colors";
import {Legend} from "../pie-chart/panels/legend/legend";
import {OtherOptions} from "../common/other-options/other-options";
import "./venn-diagram.scss";
import {TileTitle} from "../pie-chart/panels/tile-title/tile-title";

export const VennDiagram = ({tile, form, theme, size, parentTile, colorsTileConfig, tileAction, isContainerTile}) =>
    [
        Display,
        TileTitle, // match pie chart
        SectionLabels,
        Colors,
        Legend, // match pie chart
        OtherOptions,
    ].map((panel) =>
        panel({
            tile,
            form,
            theme,
            size,
            parentTile,
            colorsTileConfig,
            tileAction,
            isContainerTile,
        })
    );
