import {cs} from "../../react/chain-services";
import {cx} from "emotion";
import {Dropdown} from "../dropdown/dropdown";
import React from "react";
import "./dropdown-select2.scss";
import {VerbScrollbar} from "../verb-scrollbar/verb-scrollbar";
import {LoadingIndicator} from "@common/ui-components/loading-indicator/loading-indicator";

// vs DropdownSelect:
// same API, can basically be used interchangeably
// differences: toggle icon and border bottom changes when opened
// TODO make this and DropdownSelect a unified component in the next component style update

export const DropdownSelect2 = ({list, valueToLabel = (v) => v, onChange, isSelected, label, disabled, className, icon = null, loading, hasError, domRef, errorMessage}) =>
    cs(
        ["chosenIndex", (_, next) => next(list?.findIndex(isSelected))],
        [
            "renderToggle",
            ({chosenIndex}, next) =>
                next(({showExpand, showingExpand}) => (
                    <div
                        className={cx("toggle", {expanding: showingExpand}, {"with-value": chosenIndex > -1}, {"has-icon": icon != null})}
                        onClick={() => showExpand(!showingExpand)}
                    >
                        {label && <div className="label">{label}</div>}

                        <span className="item-render">
                            <div className="icon">{icon}</div>
                            {chosenIndex > -1 && (
                                // valueToLabel(value, isOnToggle)
                                <div className="text">{valueToLabel(list[chosenIndex], true)}</div>
                            )}
                        </span>

                        {loading ? (
                            <div className="loading">
                                <i className="fa fa-spinner fa-pulse" />
                            </div>
                        ) : (
                            <i className="fa fa-chevron-down" />
                        )}
                    </div>
                )),
        ],
        [
            "renderExpand",
            ({chosenIndex}, next) =>
                next(({close, width}) => {
                    if (!list) {
                        return <div style={{padding: 24, width}}>{LoadingIndicator({})}</div>;
                    }
                    return (
                        <VerbScrollbar maxHeight="300px" className="list" style={{minWidth: width}}>
                            {list?.map((l, i) => (
                                <div
                                    key={i}
                                    className={cx("item", {
                                        selected: chosenIndex === i,
                                    })}
                                    onClick={() => {
                                        onChange(l);
                                        close();
                                    }}
                                >
                                    {valueToLabel(l)}
                                </div>
                            ))}
                        </VerbScrollbar>
                    );
                }),
        ],
        ({renderToggle, renderExpand}) => (
            <div
                className={cx("dropdown-select dropdown-select-2-5ws", className, {
                    hasError,
                })}
                ref={domRef}
            >
                {Dropdown({
                    renderToggle,
                    minExpandHeight: 300,
                    renderExpand,
                })}

                {hasError && errorMessage && <div className="error-message">{errorMessage}</div>}
            </div>
        )
    );
