exports.sampleModels = [
    {
        id: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
        name: "Model",
        numSources: 2,
        versionInfo: {
            editedBy: "Oleg Fridman",
            editedByID: null,
            editedOnUtc: "2020-07-15T21:42:15.568Z",
        },
    },
    {
        id: "c2643151-7ee5-4b45-bc5d-5ecf885889f4",
        name: "Google Sheets Model",
        numSources: 1,
        versionInfo: {
            editedBy: "Oleg Fridman",
            editedByID: "99ffddee-5f12-4b2d-bf35-a75bf9160715",
            editedOnUtc: "2020-08-14T20:40:45.169Z",
        },
    },
];
