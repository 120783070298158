import {cs} from "../../../../../../../common/react/chain-services";
import {DialogService} from "../../../../common/dialog/dialog-service";
import * as React from "react";
import "./fix-issues-dialog.scss";
import {Button} from "../../../../../../../common/form/buttons/button/button";
import {AlertIcon, UserIcon} from "../../../../../../../common/ui-components/icons/global-icons";
import {VerbDialogBodyScrollbar} from "../../../../../../../common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";
import {noun} from "../../../../../../../common/utils/plural";
import {getBrokenRowLevelSecurityWithTable, getErrorInCalculationOrMeasureColumn, isTableHasRowLevelSecurity} from "../model-utils";
import {createDateFormatter} from "../../../../../../../common/ui-components/charts/common/formatters/date-formatter";
import {
    getErrorViewTablesWhenColumnDeleted,
    getErrorViewTablesWhenTableDeleted,
    removeRowLevelSecurity,
    updateRowLevelSecurityColumn,
} from "./fix-issues-common";
import {ColumnSelect} from "../../tabs/left-panel-overrides/column-security/main/outer-block/inner-block/inner-value-dropdown/column-select/column-select";
import {UseState} from "../../../../../../../common/react/use-state";
import {consumeContext} from "@common/react/context";
import {DeletedColumnErrors} from "./deleted-column-errors";
import {DeletedTableErrors} from "./deleted-table-errors";

export const FixIssuesDialog = ({next: rootNext, model, dataSources, interactions}) =>
    cs(
        consumeContext("dataViewTransforms"),
        [
            "modal",
            (_, next) =>
                DialogService({
                    render: ({resolve, args: {tableID, columnID}}) => ({
                        title: "ERRORS",
                        width: 620,
                        content: next({
                            resolve,
                            tableID,
                            columnID,
                        }),
                    }),
                    next: rootNext,
                    registryRender: true,
                }),
        ],
        ({modal, dataViewTransforms}) => {
            if (!dataSources || !dataViewTransforms.value) {
                return (
                    <div className="fix-issues-dialog-a33">
                        <div className="dialog-body">Loading...</div>
                    </div>
                );
            }

            const table = model.value.tables.concat(model.value.disabledTables).find((t) => t.id == modal.tableID);

            return cs(["columnState", (_, next) => UseState({next, initValue: {}})], ["loading", (_, next) => UseState({next})], () => {
                const View = table.deleted ? DeletedTableErrors : DeletedColumnErrors;

                return (
                    <div className="fix-issues-dialog-a33">
                        <VerbDialogBodyScrollbar>
                            <View
                                model={model}
                                modal={modal}
                                dataSources={dataSources}
                                dataViewTransforms={dataViewTransforms}
                                interactions={interactions}
                            >
                                {(alertContent) => (
                                    <>
                                        <div className="removed-column-error-card">
                                            <div className="header-card">
                                                <AlertIcon className="icon" />

                                                <div className="title-card">
                                                    {alertContent.title}

                                                    <div className="column-info">{alertContent.dataSource}</div>
                                                </div>
                                            </div>

                                            <div className="description-card">
                                                Removed on <b>{alertContent.removedOn}</b>
                                            </div>

                                            <div className="deleted-by">
                                                <UserIcon /> {alertContent.deletedBy}
                                            </div>
                                        </div>

                                        <p>{alertContent.description}</p>

                                        <div className="paragraph">
                                            Possible Solutions:
                                            <ol>
                                                {alertContent.solutions.map((solution, index) => (
                                                    <li key={index}>{solution}</li>
                                                ))}
                                            </ol>
                                        </div>

                                        <div className="section">
                                            <div className="section-title">{alertContent.heading1}</div>

                                            {alertContent.errors.map((error, index) => (
                                                <div className="section-row" key={index}>
                                                    <AlertIcon className="icon" />

                                                    <span>{error.label}</span>

                                                    {error.fix && (
                                                        <Button
                                                            className="fix-btn"
                                                            btnType="border"
                                                            size="medium"
                                                            onClick={() => {
                                                                error.fix();
                                                                modal.resolve();
                                                            }}
                                                        >
                                                            Fix
                                                        </Button>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                )}
                            </View>
                        </VerbDialogBodyScrollbar>

                        <div className="buttons">
                            <Button onClick={() => modal.resolve()}>Close</Button>
                        </div>
                    </div>
                );
            });
        }
    );
