import {addDate, today, parseDate} from "../../../../../utils/dates/date-object";

export const previousXDays = (count, negative) => {
    const getMainRange = () =>
        !negative
            ? {
                  from: addDate(today(), -count),
                  to: {
                      ...addDate(today(), -1),
                      hours: 23,
                      minutes: 59,
                      seconds: 59,
                  },
              }
            : {
                  from: parseDate("1900-01-01"),
                  to: {
                      ...addDate(today(), -count - 1),
                      hours: 23,
                      minutes: 59,
                      seconds: 59,
                  },
              };
    return {
        getMainRange,
        getComparingRange: () => {
            if (negative) return null;

            const range = getMainRange();

            return {
                to: addDate(range.from, -1),
                from: {
                    ...addDate(range.from, -count),
                    hours: 23,
                    minutes: 59,
                    seconds: 59,
                },
            };
        },
        diff: count,
    };
};
