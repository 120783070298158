import {UseState} from "@common/react/use-state";
import {cs} from "@common/react/chain-services";
import {LeftPanelClosable} from "../../../../../../../../../../common/left-panel-closable/left-panel-closable";
import React from "react";
import {renderColor} from "../../../../colors/colors-tile-config";
import {FieldItem} from "../../../../../../../../../edit/common/field-item/field-item";
import {mapColorApplicationToThemeColor, MAX_NUM_COLOR} from "@common/ui-components/charts/get-field-color";
import {FieldSection} from "../../../../../fields-tab/chart-types/common/field-section/field-section";
import {FieldInfoProvider} from "../../../common/field-info-provider/field-info-provider";
import {spc} from "@common/react/state-path-change";
import {getPath} from "@common/utils/arr-path";

export const ScatterMarkerColorsConfig = ({next: rootNext, tile, theme}) =>
    cs(["selecting", (_, next) => UseState({next})], ({selecting}) => {
        const colorFieldName = tile?.value?.colorGroupField?.displayName || tile?.value?.dimensionField?.displayName || "Field";

        return rootNext({
            render: () => {
                const useSchemeColor = tile?.value?.colorGroupField != null;
                const colorApplication = useSchemeColor
                    ? tile?.value?.style.colorApplication // scheme
                    : tile?.value?.style.valueColorApplication; // discrete

                return (
                    <div className="scatter-marker-colors-9bv">
                        <div className="default-padding">
                            <FieldItem
                                className="field-color-bbg"
                                label={
                                    <div>
                                        <div className="field-name">{colorFieldName}</div>
                                        <div className="selected-color">
                                            {useSchemeColor
                                                ? renderColor["SchemeColorApplication"]({
                                                      index: colorApplication?.index ?? 0,
                                                      displayColor: mapColorApplicationToThemeColor(
                                                          theme.dataVisualization.dataColorPalettes,
                                                          colorApplication || {
                                                              index: 0,
                                                              $type: "SchemeColorApplication",
                                                              type: "Sequential",
                                                          }
                                                      ),
                                                      type: colorApplication?.type || "Sequential",
                                                  })
                                                : renderColor["DiscreteColorApplication"]({
                                                      index: colorApplication?.index ?? 0,
                                                      displayColor: mapColorApplicationToThemeColor(
                                                          theme.dataVisualization.dataColorPalettes,
                                                          colorApplication || {
                                                              index: 0,
                                                              $type: "DiscreteColorApplication",
                                                          }
                                                      ),
                                                  })}
                                        </div>
                                    </div>
                                }
                                rightIcon={<i className="far fa-arrow-right" aria-hidden="true" />}
                                onClick={() => selecting.onChange(true)}
                            />
                        </div>
                    </div>
                );
            },
            override:
                selecting.value &&
                cs(
                    ({}, next) => (
                        <LeftPanelClosable
                            {...{
                                onClose: () => selecting.onChange(false),
                                label: colorFieldName,
                                content: next(),
                            }}
                        />
                    ),
                    ({}) => MarkerColorsConfigOverride({tile, theme})
                ),
        });
    });

export const MarkerColorsConfigOverride = ({tile, theme}) => {
    const {colorSchemes} = theme.dataVisualization.dataColorPalettes;
    const schemeColor = tile?.value?.style.colorApplication;
    const discreteColor = tile?.value?.style.valueColorApplication;

    const {discreteColorsRGB} = getPath(theme, ["dataVisualization", "dataColorPalettes"]);

    const useSchemeColor = tile?.value?.colorGroupField != null;

    return (
        <div className="map-colors-config-4tg colors-tile-config-vrf">
            {!useSchemeColor && (
                <div className="default-padding">
                    {discreteColorsRGB.map((color, index) => (
                        <FieldItem
                            label={
                                <div>
                                    COLOR {index + 1}
                                    <div className="discrete-color" style={{background: color}} />
                                </div>
                            }
                            key={index}
                            rightIcon={(discreteColor?.index ?? MAX_NUM_COLOR.DiscreteColorApplication - 1) === index && <i className="material-icons">done</i>}
                            onClick={() => {
                                spc(tile, ["style", "valueColorApplication"], () => ({
                                    $type: "DiscreteColorApplication",
                                    index,
                                }));
                            }}
                        />
                    ))}
                </div>
            )}

            {useSchemeColor && (
                <>
                    {FieldSection({
                        header: FieldInfoProvider({
                            field: <div className="text">CATEGORICAL</div>,
                            noCollapsible: true,
                        }),
                        content: colorSchemes.categoricalColorSchemes.map((section, index) => (
                            <FieldItem
                                label={
                                    <div>
                                        CATEGORICAL SCHEME {index + 1}
                                        <div className="colors-list">
                                            {section.colorsRGB.map((color, index) => (
                                                <div
                                                    className="color"
                                                    key={index}
                                                    style={{
                                                        background: color,
                                                    }}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                }
                                key={index}
                                rightIcon={schemeColor.type === "Categorical" && schemeColor.index === index && <i className="material-icons">done</i>}
                                onClick={() => {
                                    spc(tile, ["style", "colorApplication"], () => ({
                                        $type: "SchemeColorApplication",
                                        index,
                                        type: "Categorical",
                                    }));
                                }}
                            />
                        )),
                    })}

                    {FieldSection({
                        header: FieldInfoProvider({
                            field: <div className="text">SEQUENTIAL</div>,
                            noCollapsible: true,
                        }),
                        content: colorSchemes.sequentialColorSchemes.map((section, index) => (
                            <FieldItem
                                label={
                                    <div>
                                        SEQUENTIAL SCHEME {index + 1}
                                        <div
                                            className="color-gradient"
                                            style={{
                                                background: `linear-gradient(to right, ${section.colorsRGB.join(",")})`,
                                            }}
                                        />
                                    </div>
                                }
                                key={index}
                                rightIcon={schemeColor.type === "Sequential" && schemeColor.index === index && <i className="material-icons">done</i>}
                                onClick={() =>
                                    spc(tile, ["style", "colorApplication"], () => ({
                                        $type: "SchemeColorApplication",
                                        index,
                                        type: "Sequential",
                                    }))
                                }
                            />
                        )),
                    })}
                </>
            )}
        </div>
    );
};
