import {Fragment, useMemo} from "react";
import {pivotTableHelper} from "../common/pivot-table-helper";
import {columnValueService} from "../common/pivot-table-column-value";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {Static2} from "@common/react/static-2";
import {headerMapping} from "../common/header-mapping";
import {getCollapsibleHeaderColumns, getMaxWidthLeftColumn} from "../common/pivot-table-common";
import {MinusIcon, PlusIcon} from "../../../icons/global-icons";
import {css, cx} from "emotion";
import {consumeContext} from "@common/react/context";
import {TruncatingTooltip} from "../../../../../web-client/src/routes/common/truncating-tooltip/truncating-tooltip";
import {VirtualizedList} from "./virtualized-list";
import {Watch} from "@common/react/watch";
import {PivotTableLine} from "../table-line/pivot-table-line";
import {getFontOptions} from "../../table/table/common/get-font-options";
import {cGetFontSize} from "../../common/style-map/font-size";
import {IgnoreUpdate} from "@common/react/ignore-update";
import {getTooltipFontSizes} from "@common/ui-components/charts/factory/tooltip/shared-tooltip-formatter/get-tooltip-font-sizes";
import {StyledClass} from "@common/react/styled-class";

export const PivotTableBody = ({data, tile, ...rest}) =>
    cs(
        consumeContext("theme"),
        consumeContext("selectedTimezone"),
        ({selectedTimezone}, next) =>
            IgnoreUpdate({
                next,
                props: {timezoneId: selectedTimezone?.value},
                when: ({timezoneId}) => timezoneId != selectedTimezone?.value,
            }),
        [
            "oriRows",
            (_, next) =>
                Static2({
                    next,
                    getInitValue: () => headerMapping.mapHeader(data.rows, true),
                }),
        ],
        [
            "collapsedRows",
            ({oriRows}, next) =>
                UseState({
                    initValue: tile.style.rowsExpanded ? [] : getCollapsibleHeaderColumns(oriRows.get()),
                    next,
                }),
        ],
        ({collapsedRows, oriRows}, next) =>
            Watch({
                value: tile.style.rowsExpanded,
                onChanged: (expanded) => {
                    collapsedRows.onChange(expanded ? [] : getCollapsibleHeaderColumns(oriRows.get()));
                },
                next,
            }),
        [
            "truncatingTooltip",
            ({theme}, next) => {
                const themeMode = theme.dataVisualization.toolTipsAndLegends.tooltipTheme.toLowerCase() || "light";

                return TruncatingTooltip({
                    direction: theme.dataVisualization.toolTipsAndLegends.position.toLowerCase(),
                    className: `${themeMode}-theme`,
                    tooltipContentStyle: {
                        fontSize: getTooltipFontSizes(theme).small,
                        fontFamily: `"${theme.general.canvas.fontFamily || "Roboto"}", sans-serif`,
                        // color: themeMode === "light" ? theme.general.canvas.fontColorRGB || "#546B81" : "#E2E2E2",
                    },
                    next,
                });
            },
        ],
        (state) => <RowRender {...{...state, ...rest, data, tile}} />
    );

const RowRender = ({
    collapsedRows,
    oriRows,
    truncatingTooltip,
    theme,
    data,
    headers,
    tooltip,
    maxFixedColumnWidth,
    scrollContainerRef,
    tile,
    isMobile,
    hideFixed,
    hideFixedRow,
    selectedTimezone,
}) => {
    const tablesStyle = theme.dataVisualization.tables;
    const collapsibleRows = useMemo(() => getCollapsibleHeaderColumns(oriRows.get()), []);
    const filteredData = useMemo(() => pivotTableHelper.filterData(data.rows, collapsedRows.value), [data.rows, collapsedRows.value]);
    const rows = useMemo(() => pivotTableHelper.buildTableLeftSideRow(filteredData), [filteredData]);

    const renderValueService = columnValueService(data.values, data.colSubtotals, data.rowSubtotals, data.totals, selectedTimezone.value);

    const nonValueMapping = {
        Dash: "-",
        NoData: "No Data",
        Blank: null,
        Zero: 0,
        Null: "Null",
    };

    const columnStyle = {
        backgroundColor: tablesStyle.cellBackgroundColorRGB || "#FFF",
        color: tablesStyle.cellFontColorRGB || "#bcbcbc",
    };
    const getFontSize = cGetFontSize(theme.general.canvas.fontSize, theme);

    const fixedColumnStyle = {
        textTransform:
            tablesStyle.headerCaseOptions === "TitleCase" ? "capitalize" : tablesStyle.headerCaseOptions === "Uppercase" ? "uppercase" : "",
        backgroundColor: tablesStyle.headerBackgroundColorRGB || "#FFF",
        fontSize: getFontSize({
            group: tablesStyle.headerFontSize || theme.general.canvas.fontSize,
            elemType: "label",
        }),
        ...getFontOptions(tablesStyle.headerFontOptions),
        color: tablesStyle.headerFontColorRGB || "#bcbcbc",
    };

    const grandTotalStyle = {
        color: tablesStyle.totalsFontColorRGB,
        background: tablesStyle.totalsBackgroundColorRGB,
        fontSize: getFontSize({
            group: tablesStyle.totalsFontSize || theme.general.canvas.fontSize,
            elemType: "label",
        }),
        ...getFontOptions(tablesStyle.totalsFontOptions),
    };

    const maxWidth = useMemo(
        () =>
            Math.min(
                maxFixedColumnWidth,
                getMaxWidthLeftColumn(
                    rows,
                    getFontSize({
                        group: tablesStyle.headerFontSize || theme.general.canvas.fontSize,
                        elemType: "label",
                    })
                )
            ),
        [maxFixedColumnWidth, rows.length]
    );
    const renderTooltipBox = (value, row, item) => {
        const tooltipTheme = theme.dataVisualization.toolTipsAndLegends;

        return (
            <div className="tooltip-box">
                <div>{row.name}</div>
                <div>{renderValueService.getValueLabel(item, headers)}</div>

                <div
                    className="bottom"
                    style={{
                        borderTop: `${tooltipTheme.indicatorLineWidth}px ${tooltipTheme.indicatorLineStyle} ${tooltipTheme.indicatorLineColorRGB}`,
                        paddingTop: 8,
                        marginTop: 8,
                        display: "flex",
                    }}
                >
                    <span style={{paddingRight: 16}}>{item.name}</span>

                    <div
                        className="value"
                        style={{
                            marginLeft: "auto",
                        }}
                    >
                        {value}
                    </div>
                </div>
            </div>
        );
    };

    return cs(
        [
            "trClass",
            ({}, next) =>
                StyledClass({
                    content: {
                        "&:last-child": {
                            ".horizontal-line": {
                                "&::after": {
                                    display: `${theme.dataVisualization.tables.borderOutsideLines ? "block" : "none"}`,
                                },
                            },
                        },
                    },
                    next,
                }),
        ],
        ({trClass}) => (
            <>
                <VirtualizedList
                    scrollContainerRef={scrollContainerRef}
                    itemHeight={tile.style.rowHeight || 32}
                    total={rows.length}
                    render={(index, height) => {
                        const row = rows[index];
                        const allowCollapsible = collapsibleRows.indexOf(row.id) > -1;
                        const collapsible = collapsedRows.value.find((c) => c == row.id);
                        const rowIndex = index;

                        return (
                            <tr key={index} style={{height}} className={cx(!tile.style.grandTotalsRowShow && trClass)}>
                                <td
                                    className={cx("left-side-column", allowCollapsible && "clickable", "fixed-column")}
                                    colSpan={1}
                                    rowSpan={1}
                                    style={{
                                        ...fixedColumnStyle,
                                        width: maxWidth,
                                    }}
                                    onClick={() => {
                                        if (allowCollapsible) {
                                            if (collapsible) {
                                                collapsedRows.onChange(collapsedRows.value.filter((c) => c != row.id));
                                            } else {
                                                collapsedRows.onChange(collapsedRows.value.concat(row.id));
                                            }
                                        }
                                    }}
                                >
                                    <PivotTableLine isFirstColumn isLatestRow={rows.length - 1 == rowIndex} />

                                    <div
                                        className={cx("data-view", allowCollapsible && "allow-collapsible")}
                                        style={{
                                            paddingLeft: 16 * row.deep,
                                            width: maxWidth,
                                        }}
                                        {...tooltip(() => row.name)}
                                    >
                                        {row.name} {allowCollapsible && <>{collapsible ? <PlusIcon /> : <MinusIcon />}</>}
                                    </div>
                                </td>

                                {headers[headers.length - 1].map((group, headerIndex) => (
                                    <Fragment key={headerIndex}>
                                        {group.items.map((item, index) =>
                                            cs(["nextElemWidth", (_, next) => Static2({next})], ({nextElemWidth}) => {
                                                const value = renderValueService.getValue(row, item);
                                                const formattedValue =
                                                    value == null
                                                        ? nonValueMapping[tile.style.missingPivotValueFormat]
                                                        : renderValueService.formatValue(tile, item.aggIndex, value);

                                                return (
                                                    <td
                                                        ref={(elem) => {
                                                            if (
                                                                item.parent == "Grand Total" &&
                                                                tile.style.grandTotalColumnPosition == "Fixed" &&
                                                                !isMobile &&
                                                                "fixed-grand-total" &&
                                                                elem?.nextSibling
                                                            ) {
                                                                let width = 0;

                                                                const getNextElemWidth = (elem) => {
                                                                    let nextElem = elem.nextSibling;
                                                                    if (!nextElem) return;

                                                                    width += parseFloat(getComputedStyle(nextElem).width.replace("px", ""));
                                                                    getNextElemWidth(nextElem);
                                                                };

                                                                getNextElemWidth(elem);

                                                                nextElemWidth.set(width);
                                                            }
                                                        }}
                                                        className={cx(
                                                            item.parent == "Grand Total" &&
                                                                tile.style.grandTotalColumnPosition == "Fixed" &&
                                                                !isMobile &&
                                                                "fixed-grand-total"
                                                        )}
                                                        key={index}
                                                        rowSpan={1}
                                                        colSpan={1}
                                                        {...tooltip(() => renderTooltipBox(formattedValue, row, item))}
                                                        style={{
                                                            ...(item.parent == "Grand Total" ? grandTotalStyle : columnStyle),
                                                            ...(nextElemWidth.get()
                                                                ? {
                                                                      right: nextElemWidth.get(),
                                                                  }
                                                                : {}),
                                                        }}
                                                    >
                                                        <PivotTableLine
                                                            isLatestColumn={
                                                                headerIndex == headers[headers.length - 1].length - 1 &&
                                                                index == group.items.length - 1
                                                            }
                                                            isFixedColumn={
                                                                item.parent == "Grand Total" &&
                                                                tile.style.grandTotalColumnPosition == "Fixed" &&
                                                                !isMobile &&
                                                                !hideFixed.value &&
                                                                index == 0
                                                            }
                                                            isLatestRow={rows.length - 1 == rowIndex}
                                                        />

                                                        <div className="value-wrapper">{formattedValue}</div>
                                                    </td>
                                                );
                                            })
                                        )}
                                    </Fragment>
                                ))}
                            </tr>
                        );
                    }}
                />

                {tile.style.grandTotalsRowShow && (
                    <tfoot>
                        {(() =>
                            cs(
                                [
                                    "cssClass",
                                    (_, next) =>
                                        StyledClass({
                                            next,
                                            content: {
                                                "horizontal-line": {
                                                    "&::after": {
                                                        display: `${theme.dataVisualization.tables.borderOutsideLines ? "block" : "none"}`,
                                                    },
                                                },
                                            },
                                        }),
                                ],
                                ({cssClass}) => (
                                    <tr
                                        style={{height: tile.style.rowHeight || 32}}
                                        className={cx(
                                            tile.style.grandTotalRowPosition == "Fixed" && !isMobile && "grand-total-row-fixed",
                                            cssClass
                                        )}
                                    >
                                        <td
                                            style={{
                                                maxWidth: maxFixedColumnWidth,
                                                ...fixedColumnStyle,
                                            }}
                                            className="grand-total left-side-column fixed-column"
                                            rowSpan={1}
                                            colSpan={1}
                                        >
                                            <PivotTableLine
                                                isFirstColumn
                                                isFixedRow={
                                                    tile.style.grandTotalColumnPosition == "Fixed" && !isMobile && !hideFixedRow.value
                                                }
                                                isLatestRow
                                            />
                                            Grand Total
                                        </td>

                                        {headers[headers.length - 1].map((group, headerIndex) => (
                                            <Fragment key={headerIndex}>
                                                {group.items.map((item, index) =>
                                                    cs(["nextElemWidth", (_, next) => Static2({next})], ({nextElemWidth}) => {
                                                        const value = renderValueService.renderGrandTotalRow(item);
                                                        const formattedValue =
                                                            value == null
                                                                ? nonValueMapping[tile.style.missingPivotValueFormat]
                                                                : renderValueService.formatValue(tile, item.aggIndex, value);

                                                        return (
                                                            <td
                                                                {...tooltip(() =>
                                                                    renderTooltipBox(
                                                                        formattedValue,
                                                                        {
                                                                            name: "Grand Total",
                                                                        },
                                                                        item
                                                                    )
                                                                )}
                                                                className={cx(
                                                                    "grand-total",
                                                                    item.parent == "Grand Total" &&
                                                                        tile.style.grandTotalColumnPosition == "Fixed" &&
                                                                        !isMobile &&
                                                                        "fixed-grand-total"
                                                                )}
                                                                key={index}
                                                                rowSpan={1}
                                                                colSpan={1}
                                                                style={{
                                                                    ...grandTotalStyle,
                                                                    ...(nextElemWidth.get()
                                                                        ? {
                                                                              right: nextElemWidth.get(),
                                                                          }
                                                                        : {}),
                                                                }}
                                                                ref={(elem) => {
                                                                    if (
                                                                        item.parent == "Grand Total" &&
                                                                        tile.style.grandTotalColumnPosition == "Fixed" &&
                                                                        !isMobile &&
                                                                        elem?.nextSibling
                                                                    ) {
                                                                        let width = 0;

                                                                        const getNextElemWidth = (elem) => {
                                                                            let nextElem = elem.nextSibling;
                                                                            if (!nextElem) return;

                                                                            width += parseFloat(
                                                                                getComputedStyle(nextElem).width.replace("px", "")
                                                                            );
                                                                            getNextElemWidth(nextElem);
                                                                        };

                                                                        getNextElemWidth(elem);

                                                                        nextElemWidth.set(width);
                                                                    }
                                                                }}
                                                            >
                                                                <PivotTableLine
                                                                    isLatestColumn={
                                                                        headerIndex == headers[headers.length - 1].length - 1 &&
                                                                        index == group.items.length - 1
                                                                    }
                                                                    isFixedColumn={
                                                                        item.parent == "Grand Total" &&
                                                                        tile.style.grandTotalColumnPosition == "Fixed" &&
                                                                        !isMobile &&
                                                                        !hideFixed.value &&
                                                                        index == 0
                                                                    }
                                                                    isFixedRow={
                                                                        tile.style.grandTotalColumnPosition == "Fixed" &&
                                                                        !isMobile &&
                                                                        !hideFixedRow.value
                                                                    }
                                                                    isLatestRow
                                                                />

                                                                {formattedValue}
                                                            </td>
                                                        );
                                                    })
                                                )}
                                            </Fragment>
                                        ))}
                                    </tr>
                                )
                            ))()}
                    </tfoot>
                )}
            </>
        )
    );
};
