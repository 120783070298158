const {debounce} = require("../../utils/debounce");

const AsyncQueue2 = ({debounceDelay}) => {
    let pendings = [];
    let requestings = [];
    let destroyed = false;
    let activated = null;

    const retrieveRequesting = (id) => {
        const index = requestings.findIndex((l) => l.id === id);

        if (index > -1) {
            const l = requestings[index];
            // l.resolve(response);
            requestings.splice(index, 1);
            return l;
        } else {
            throw 534632;
        }
    };
    return {
        request: (request) =>
            new Promise((resolve, reject) => {
                if (destroyed) {
                    // What to do?
                    return;
                }

                pendings.push({
                    request,
                    resolve,
                    reject,
                });
                activated?.debouncePush();
            }),
        activate: ({pushRequests}) => {
            if (destroyed) {
                // What to do?
                return;
            }
            const debouncePush = debounce(() => {
                const ids = pushRequests(pendings.map((p) => p.request));
                requestings.push(...pendings.map((p, i) => ({...p, id: ids[i]})));
                pendings = [];
            }, debounceDelay);
            activated = {
                // config,
                debouncePush,
            };
            if (pendings.length) {
                debouncePush();
            }
        },

        resolve: (id, data) => {
            retrieveRequesting(id).resolve(data);
        },
        reject: (id, error) => {
            retrieveRequesting(id).reject(error);
        },
        destroy: () => {
            if (destroyed) {
                throw 482313;
            }
            if (!activated) {
            } else {
                activated = null;
            }
            destroyed = true;
        },
    };
};
exports.AsyncQueue2 = AsyncQueue2;
