import React from "react";

import {scope} from "@common/react/scope";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";

import {changePath, getPath, setPath} from "@common/utils/arr-path";
import {getLayoutColumns} from "@common/ui-components/live/live-grid-panel/utils";

import {loadTheme} from "../../../collection/tile/edit/edit-tile";
import {RoutingParam} from "../../../common/routing/routing-param";
import {EditTileLogic} from "../../../collection/tile/edit/edit-tile-logic";
import {gridHeight} from "../../../collection/common/dnd-grid-panel/grid-constants";
import {TileTab} from "../../../collection/tile/edit/left-panel/tabs/tile-tab/tile-tab";
import {FieldsTab} from "../../../collection/tile/edit/left-panel/tabs/fields-tab/fields-tab";
import {createGetFieldInfo} from "../../../collection/common/field-info/create-get-field-info";

import {ContextualPanel} from "./collection/main/contextual-panel";
import {ContextualCollectionTab} from "./collection/contextual-collection-tab";

import {FilterTab} from "./filter/filter-tab";
import {fragments} from "@common/react/fragments";
import {Invoke} from "@common/react/invoke";

export const ContextualCollectionTabs = ({next, savingQueue}) =>
    cs(
        consumeContext("apis"),
        consumeContext("collection"),
        [
            "theme",
            ({apis, collection}, next) =>
                loadTheme({
                    collection: collection.value,
                    apis,
                    next,
                }),
        ],
        [
            "selectedTab",
            ({collection}, next) =>
                RoutingParam({
                    param: "collectionTab",
                    defaultValue: collection.value.tile ? "data" : "collection",
                    next,
                }),
        ],
        ({}, next) => createGetFieldInfo({next}),
        [
            "editTileLogic",
            ({collection}, next) =>
                EditTileLogic({
                    next,
                    tile: scope(collection, ["tile"]),
                    updateFunc: async (newTile) => {
                        if (newTile) {
                            const newCol = await savingQueue.push((c) => setPath(c, ["tile"], newTile));
                            collection.onChange(newCol);
                            return getPath(newCol, ["tile"]);
                        }
                    },
                }),
        ],
        [
            "autoSaveCollection",
            ({collection}, next) =>
                next({
                    value: collection.value,
                    change: async (reduce) => {
                        const newCollection = await savingQueue.push(reduce);
                        collection.change(() => newCollection);
                    },
                }),
        ],
        ["panel", ({autoSaveCollection}, next) => ContextualPanel({collection: autoSaveCollection, next})],
        [
            "size",
            ({collection}, next) => {
                const {actualNumColumns} = getLayoutColumns(collection.value.viewWidthPixels);
                return next({
                    width: actualNumColumns,
                    height: collection.value.viewHeightPixels / gridHeight,
                });
            },
        ],

        [
            "tabs",
            ({collection, editTileLogic, autoSaveCollection, panel, size}, next) =>
                cs(
                    [
                        "collectionTab",
                        ({}, next) =>
                            ContextualCollectionTab({
                                next,
                                collection: autoSaveCollection,
                                savingQueue,
                                panel,
                            }),
                    ],
                    [
                        "displayTab",
                        (_, next) => {
                            if (!collection.value.tile) {
                                return next({
                                    render: () => (
                                        <div
                                            style={{
                                                padding: "20px 0",
                                                textAlign: "center",
                                            }}
                                        >
                                            Please select a tile
                                        </div>
                                    ),
                                });
                            }

                            return TileTab({
                                ...editTileLogic,
                                tileFuncs: {},
                                size,
                                next: (props) => {
                                    return next({
                                        ...props,
                                        leftPanelOverride: props.override && {
                                            leftPanel: props.override,
                                        },
                                    });
                                },
                            });
                        },
                    ],
                    [
                        "filtersTab",
                        ({}, next) =>
                            FilterTab({
                                filters: collection.value?.filters,
                                tileType: collection.value?.tile?.$type,
                                onChangeFilters: (reduce) => {
                                    autoSaveCollection.change((c) => changePath(c, ["filters"], reduce));
                                },
                                next: (props) => {
                                    return next({
                                        ...props,
                                        leftPanelOverride: props.override && {
                                            leftPanel: props.override,
                                        },
                                    });
                                },
                            }),
                    ],
                    [
                        "dataTab",
                        ({}, next) =>
                            FieldsTab({
                                ...editTileLogic,
                                remoteTile: scope(collection, ["tile"]),
                                size,
                                next: (props) => {
                                    return next({
                                        ...props,
                                        leftPanelOverride: props.override && {
                                            leftPanel: props.override,
                                        },
                                    });
                                },
                            }),
                    ],
                    ({collectionTab, displayTab, filtersTab, dataTab}) =>
                        next([
                            {
                                id: "collection",
                                label: "Collection",
                                control: collectionTab,
                            },
                            {
                                id: "display",
                                label: "Display",
                                control: displayTab,
                            },
                            {
                                id: "filters",
                                label: "Filters",
                                control: filtersTab,
                            },
                            {
                                id: "data",
                                label: "Data",
                                control: dataTab,
                            },
                        ])
                ),
        ],
        ({selectedTab, tabs}, next) => {
            const activeTab = tabs.find((tab) => tab.id === selectedTab.value);

            if (activeTab) {
                return next();
            }

            return Invoke({
                fn: () => selectedTab.onChange(selectedTab.defaultValue),
            });
        },
        ({tabs, selectedTab, size, panel}) => {
            const activeTab = tabs.find((tab) => tab.id === selectedTab.value);

            return next({
                tabs: tabs.map((tab) => ({
                    ...tab,
                    render: tab.control?.render || tab.control?.renderLeftPanel,
                })),
                selectedTab,
                // renderMain: () => activeTab?.control?.renderMain?.() || panel.renderMain(),
                renderMain: () => {
                    if (activeTab.control?.overrideAll) {
                        if (!activeTab.control?.overrideAll?.overlay) {
                            return activeTab.control?.overrideAll.main({size});
                        }
                        return panel.renderMain()?.({
                            renderOverlay: () => activeTab.control?.overrideAll?.main?.({size}),
                        });
                    }

                    return activeTab?.control?.renderMain?.({size}) || panel.renderMain({size});
                },
                leftPanelOverride: activeTab?.control?.leftPanelOverride,
            });
        }
    );
