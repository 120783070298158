import {UseState} from "../../../../../../react/use-state";
import {cs} from "../../../../../../react/chain-services";
import * as React from "react";
import "./basic-input-live.scss";
import {cx} from "emotion";

export const BasicInputLive = ({label, placeholder, disabled = false, className, state, hasError, onBlur, bind, list, style}) =>
    cs(["focused", (_, next) => UseState({next})], ({focused}) => (
        <div
            className={cx("basic-input-live-19z", className, {
                focused: focused.value,
                hasError,
            })}
        >
            <input
                {...{
                    disabled,
                    list,
                    placeholder,
                    ...bind(state),
                    onFocus: () => focused.onChange(true),
                    onBlur: () => {
                        focused.onChange(false);
                        onBlur?.();
                    },
                    style,
                }}
            />

            {label && (
                <div
                    className={cx("label", {
                        up: focused.value || state.value != null,
                        focused: focused.value,
                    })}
                >
                    {label}
                </div>
            )}
        </div>
    ));
