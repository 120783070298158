import React from "react";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {UrlTemplate} from "../collection/tile/edit/left-panel/tabs/fields-tab/actions/add/common/url-template/url-template";
import {provideContext} from "@common/react/context";

export const TestURLEditor = () =>
    cs(
        ({}, next) => provideContext("modelForCollection", mockdata, next),
        [
            "state",
            (_, next) =>
                UseState({
                    initValue: {},
                    next,
                }),
        ],
        ({state}) => {
            return (
                <div className="test-maps-46f test-route-tab">
                    <div className="left">
                        {UrlTemplate({
                            tileAction: state,
                            tablesInTile: ["972a51d7-0129-4fb2-b312-c2c5b66c34c2", "38bd387a-6f59-42cd-bf09-b963bbc0fbb9"],
                        })}
                    </div>
                    <div className="right"></div>
                </div>
            );
        }
    );

const mockdata = {
    id: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
    name: "Model 2",
    folderID: null,
    tables: [
        {
            id: "9f5ed170-390d-4f8c-9fd5-00d697bbf6c9",
            dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
            dataSourceTableID: "2c89d8c4-8711-4743-aa57-17aa729d99ce",
            name: "Account",
            visualName: "Account",
            columns: [
                {
                    id: "478c817f-beaf-482a-a084-652cf3f3fad8",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "2c89d8c4-8711-4743-aa57-17aa729d99ce",
                    dataSourceColumnID: "77b7f1f6-045c-48e0-af43-ecd53388dd76",
                    dataType: "IDUUID",
                    name: "AccountID",
                    displayName: null,
                    visualNameShort: "Account ID",
                    visualNameFull: "Account ID (Account)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "9f5ed170-390d-4f8c-9fd5-00d697bbf6c9",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Account",
                    modelColumnID: "478c817f-beaf-482a-a084-652cf3f3fad8",
                },
                {
                    id: "c8f85f4d-80ba-4f3a-ba70-5d4bb930bae2",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "2c89d8c4-8711-4743-aa57-17aa729d99ce",
                    dataSourceColumnID: "c36f1f9a-d44e-45a0-a163-cac89f7bd8d6",
                    dataType: "Int",
                    name: "AdminAccess",
                    displayName: null,
                    visualNameShort: "Admin Access",
                    visualNameFull: "Admin Access (Account)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "9f5ed170-390d-4f8c-9fd5-00d697bbf6c9",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Account",
                    modelColumnID: "c8f85f4d-80ba-4f3a-ba70-5d4bb930bae2",
                },
                {
                    id: "fea50e62-901c-4b16-91d6-94c31138447f",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "2c89d8c4-8711-4743-aa57-17aa729d99ce",
                    dataSourceColumnID: "5ae47af5-96dd-452b-88c7-d74703745d18",
                    dataType: "DateTime",
                    name: "DateCreated",
                    displayName: null,
                    visualNameShort: "Date Created",
                    visualNameFull: "Date Created (Account)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "9f5ed170-390d-4f8c-9fd5-00d697bbf6c9",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Account",
                    modelColumnID: "fea50e62-901c-4b16-91d6-94c31138447f",
                },
                {
                    id: "c0714346-a18b-4eda-b483-6a6f56fc5efc",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "2c89d8c4-8711-4743-aa57-17aa729d99ce",
                    dataSourceColumnID: "fad7877b-d5cb-4362-8871-0e6be5115415",
                    dataType: "DateTime",
                    name: "DateOfBirth",
                    displayName: null,
                    visualNameShort: "Date Of Birth",
                    visualNameFull: "Date Of Birth (Account)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "9f5ed170-390d-4f8c-9fd5-00d697bbf6c9",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Account",
                    modelColumnID: "c0714346-a18b-4eda-b483-6a6f56fc5efc",
                },
                {
                    id: "dc4dd64f-7161-419a-a7f8-57a1ac41ccfb",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "2c89d8c4-8711-4743-aa57-17aa729d99ce",
                    dataSourceColumnID: "d96bb5c2-c93a-4e8a-ba34-a00aef08ca72",
                    dataType: "Text",
                    name: "Email",
                    displayName: null,
                    visualNameShort: "Email",
                    visualNameFull: "Email (Account)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "9f5ed170-390d-4f8c-9fd5-00d697bbf6c9",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Account",
                    modelColumnID: "dc4dd64f-7161-419a-a7f8-57a1ac41ccfb",
                },
                {
                    id: "f9207b48-a532-45d5-97b4-a12c87fa1a91",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "2c89d8c4-8711-4743-aa57-17aa729d99ce",
                    dataSourceColumnID: "0c301d6d-8839-4be5-a883-c36bb798d8df",
                    dataType: "Text",
                    name: "FirstName",
                    displayName: null,
                    visualNameShort: "First Name",
                    visualNameFull: "First Name (Account)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "9f5ed170-390d-4f8c-9fd5-00d697bbf6c9",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Account",
                    modelColumnID: "f9207b48-a532-45d5-97b4-a12c87fa1a91",
                },
                {
                    id: "55498647-613c-46eb-b593-a7a7a5f2ff52",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "2c89d8c4-8711-4743-aa57-17aa729d99ce",
                    dataSourceColumnID: "d4f2bff1-cc09-468a-8556-ce9bb7f6d1cb",
                    dataType: "Int",
                    name: "Gender",
                    displayName: null,
                    visualNameShort: "Gender",
                    visualNameFull: "Gender (Account)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "9f5ed170-390d-4f8c-9fd5-00d697bbf6c9",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Account",
                    modelColumnID: "55498647-613c-46eb-b593-a7a7a5f2ff52",
                },
                {
                    id: "f8821872-6202-464c-9604-bfd515981529",
                    calculations: [
                        {
                            transformationID: "9a2749bf-a1bc-431c-9203-b33d4efcab22",
                            name: "GenderStatus",
                            default: true,
                            viewReferenceName: "verbcalccol_9a2749bf_a1bc_431c_9203_b33d4efcab22",
                            columnReferenceName: "verbcol_calcoutput",
                            versionDetails: "LatestNotPublished",
                            publishedOn: "2020-12-29T15:56:11.982Z",
                        },
                    ],
                    dataType: "Double",
                    name: "GenderStatus",
                    displayName: null,
                    visualNameShort: "Gender Status",
                    visualNameFull: "Gender Status (Account)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "9f5ed170-390d-4f8c-9fd5-00d697bbf6c9",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Account",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "f8821872-6202-464c-9604-bfd515981529",
                },
                {
                    id: "3d6babd2-f88b-4d4f-9af9-373f4f42134f",
                    calculations: [],
                    dataType: "Double",
                    name: "GenderStatus",
                    displayName: null,
                    visualNameShort: "Gender Status",
                    visualNameFull: "Gender Status (Account)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "9f5ed170-390d-4f8c-9fd5-00d697bbf6c9",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Account",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "3d6babd2-f88b-4d4f-9af9-373f4f42134f",
                },
                {
                    id: "2ced2ecc-8a16-4296-ab1e-364acb102262",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "2c89d8c4-8711-4743-aa57-17aa729d99ce",
                    dataSourceColumnID: "6002aeea-d32b-4179-93fa-9329ff3b47e8",
                    dataType: "Int",
                    name: "GroupAge",
                    displayName: null,
                    visualNameShort: "Group Age",
                    visualNameFull: "Group Age (Account)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "9f5ed170-390d-4f8c-9fd5-00d697bbf6c9",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Account",
                    modelColumnID: "2ced2ecc-8a16-4296-ab1e-364acb102262",
                },
                {
                    id: "e714b2a1-f14d-4478-b404-c14ca4916d2a",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "2c89d8c4-8711-4743-aa57-17aa729d99ce",
                    dataSourceColumnID: "b1427e11-e682-4963-944a-8874557b40ad",
                    dataType: "Text",
                    name: "HashedPassword",
                    displayName: null,
                    visualNameShort: "Hashed Password",
                    visualNameFull: "Hashed Password (Account)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "9f5ed170-390d-4f8c-9fd5-00d697bbf6c9",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Account",
                    modelColumnID: "e714b2a1-f14d-4478-b404-c14ca4916d2a",
                },
                {
                    id: "fb378051-d41c-4758-8d9c-d4788ae38db0",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "2c89d8c4-8711-4743-aa57-17aa729d99ce",
                    dataSourceColumnID: "8ede87e8-746f-4ce5-b91a-0c008d67f338",
                    dataType: "DateTime",
                    name: "LastLoggedInUtc",
                    displayName: null,
                    visualNameShort: "Last Logged In Utc",
                    visualNameFull: "Last Logged In Utc (Account)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "9f5ed170-390d-4f8c-9fd5-00d697bbf6c9",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Account",
                    modelColumnID: "fb378051-d41c-4758-8d9c-d4788ae38db0",
                },
                {
                    id: "b5b866bd-50b5-40d5-9def-811dff69bf5c",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "2c89d8c4-8711-4743-aa57-17aa729d99ce",
                    dataSourceColumnID: "560e9ee6-e0d8-4c3f-8790-f36f741f74d2",
                    dataType: "Text",
                    name: "LastName",
                    displayName: null,
                    visualNameShort: "Last Name",
                    visualNameFull: "Last Name (Account)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "9f5ed170-390d-4f8c-9fd5-00d697bbf6c9",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Account",
                    modelColumnID: "b5b866bd-50b5-40d5-9def-811dff69bf5c",
                },
                {
                    id: "98f3da2f-783a-32c8-7f27-0d48288ee905",
                    calculations: [
                        {
                            transformationID: "c7115326-101b-41ec-bcdc-f5ca1e69ae1c",
                            name: "New column - tax",
                            default: true,
                            viewReferenceName: "verbcalccol_c7115326_101b_41ec_bcdc_f5ca1e69ae1c_v_0",
                            columnReferenceName: null,
                            versionDetails: "NotPublished",
                            publishedOn: null,
                        },
                    ],
                    dataType: "Unsupported",
                    name: "New column - tax",
                    displayName: null,
                    visualNameShort: "New Column - Tax",
                    visualNameFull: "New Column - Tax (Account)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "9f5ed170-390d-4f8c-9fd5-00d697bbf6c9",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Account",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "98f3da2f-783a-32c8-7f27-0d48288ee905",
                },
                {
                    id: "390a82b9-8536-5957-18de-d0d67d464524",
                    transformationID: "6f421d5f-6475-4844-bf8a-593d1b39a478",
                    dataType: "Unsupported",
                    columnReferenceName: null,
                    versionDetails: "NotPublished",
                    publishedOn: null,
                    name: "New Column 1096",
                    displayName: null,
                    visualNameShort: "New Column 1096",
                    visualNameFull: "New Column 1096 (Account)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "AggregatedMeasureModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "9f5ed170-390d-4f8c-9fd5-00d697bbf6c9",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Account",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "390a82b9-8536-5957-18de-d0d67d464524",
                },
                {
                    id: "c80432e1-7612-48f8-9024-7ed54aeb7f01",
                    calculations: [
                        {
                            transformationID: "ce08583a-efbb-416f-bfd5-71543d61335c",
                            name: "New Column 5612",
                            default: true,
                            viewReferenceName: "verbcalccol_ce08583a_efbb_416f_bfd5_71543d61335c",
                            columnReferenceName: null,
                            versionDetails: "NotPublished",
                            publishedOn: null,
                        },
                    ],
                    dataType: "Unsupported",
                    name: "New Column 5612",
                    displayName: null,
                    visualNameShort: "New Column 5612",
                    visualNameFull: "New Column 5612 (Account)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "9f5ed170-390d-4f8c-9fd5-00d697bbf6c9",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Account",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "c80432e1-7612-48f8-9024-7ed54aeb7f01",
                },
                {
                    id: "2f435251-6747-1e11-5bef-39e68235e4ec",
                    calculations: [
                        {
                            transformationID: "e26c03ec-fd10-4329-bfed-645d5b4b13f7",
                            name: "New Column 7006",
                            default: true,
                            viewReferenceName: "verbcalccol_e26c03ec_fd10_4329_bfed_645d5b4b13f7",
                            columnReferenceName: null,
                            versionDetails: "NotPublished",
                            publishedOn: null,
                        },
                    ],
                    dataType: "Unsupported",
                    name: "New Column 7006",
                    displayName: null,
                    visualNameShort: "New Column 7006",
                    visualNameFull: "New Column 7006 (Account)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "9f5ed170-390d-4f8c-9fd5-00d697bbf6c9",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Account",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "2f435251-6747-1e11-5bef-39e68235e4ec",
                },
                {
                    id: "85cc15a1-3457-4ecf-b179-592f31bc4dde",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "2c89d8c4-8711-4743-aa57-17aa729d99ce",
                    dataSourceColumnID: "f396986c-5e76-4093-a7d7-ec94b762a468",
                    dataType: "Bool",
                    name: "OptOut_DeadlinePassed",
                    displayName: null,
                    visualNameShort: "Opt Out Deadline Passed",
                    visualNameFull: "Opt Out Deadline Passed (Account)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "9f5ed170-390d-4f8c-9fd5-00d697bbf6c9",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Account",
                    modelColumnID: "85cc15a1-3457-4ecf-b179-592f31bc4dde",
                },
                {
                    id: "aeee4fb2-dfcd-478a-a90e-fa4931e8ab98",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "2c89d8c4-8711-4743-aa57-17aa729d99ce",
                    dataSourceColumnID: "1bc684ee-0710-4e48-9173-29e8b672ea07",
                    dataType: "Bool",
                    name: "OptOut_GroupLeaderSummary",
                    displayName: null,
                    visualNameShort: "Opt Out Group Leader Summary",
                    visualNameFull: "Opt Out Group Leader Summary (Account)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "9f5ed170-390d-4f8c-9fd5-00d697bbf6c9",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Account",
                    modelColumnID: "aeee4fb2-dfcd-478a-a90e-fa4931e8ab98",
                },
                {
                    id: "f26e2b77-cf0a-4f98-af3e-879586cb38fd",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "2c89d8c4-8711-4743-aa57-17aa729d99ce",
                    dataSourceColumnID: "ed3d3068-27cf-4127-abdb-1b2e493b9f5d",
                    dataType: "Bool",
                    name: "OptOut_InvitationAcceptance",
                    displayName: null,
                    visualNameShort: "Opt Out Invitation Acceptance",
                    visualNameFull: "Opt Out Invitation Acceptance (Account)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "9f5ed170-390d-4f8c-9fd5-00d697bbf6c9",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Account",
                    modelColumnID: "f26e2b77-cf0a-4f98-af3e-879586cb38fd",
                },
                {
                    id: "a3a3308d-7bcb-49e0-b22e-0b0daf4248d9",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "2c89d8c4-8711-4743-aa57-17aa729d99ce",
                    dataSourceColumnID: "c77c73c9-eec3-48b5-a4e2-c079b75f5e8f",
                    dataType: "Bool",
                    name: "OptOut_LowTicketWarning",
                    displayName: null,
                    visualNameShort: "Opt Out Low Ticket Warning",
                    visualNameFull: "Opt Out Low Ticket Warning (Account)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "9f5ed170-390d-4f8c-9fd5-00d697bbf6c9",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Account",
                    modelColumnID: "a3a3308d-7bcb-49e0-b22e-0b0daf4248d9",
                },
                {
                    id: "f6a49588-2a34-4aff-a6c1-85965c89c45d",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "2c89d8c4-8711-4743-aa57-17aa729d99ce",
                    dataSourceColumnID: "dc63e708-fea6-4c1e-9a03-23a43e6abe09",
                    dataType: "Bool",
                    name: "OptOut_OrderAdjustment",
                    displayName: null,
                    visualNameShort: "Opt Out Order Adjustment",
                    visualNameFull: "Opt Out Order Adjustment (Account)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "9f5ed170-390d-4f8c-9fd5-00d697bbf6c9",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Account",
                    modelColumnID: "f6a49588-2a34-4aff-a6c1-85965c89c45d",
                },
                {
                    id: "786f977f-f357-441a-b92e-7e61c64f0476",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "2c89d8c4-8711-4743-aa57-17aa729d99ce",
                    dataSourceColumnID: "ad069d73-5624-432b-a512-2891a8ad244d",
                    dataType: "Bool",
                    name: "OptOut_OrderConfirmations",
                    displayName: null,
                    visualNameShort: "Opt Out Order Confirmations",
                    visualNameFull: "Opt Out Order Confirmations (Account)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "9f5ed170-390d-4f8c-9fd5-00d697bbf6c9",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Account",
                    modelColumnID: "786f977f-f357-441a-b92e-7e61c64f0476",
                },
                {
                    id: "34257696-a4b3-4fb0-bd81-f15dc6581592",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "2c89d8c4-8711-4743-aa57-17aa729d99ce",
                    dataSourceColumnID: "6bc0ceeb-2bb0-4c6c-8368-a57efb49b1c9",
                    dataType: "Bool",
                    name: "OptOut_SalesManagerSummary",
                    displayName: null,
                    visualNameShort: "Opt Out Sales Manager Summary",
                    visualNameFull: "Opt Out Sales Manager Summary (Account)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "9f5ed170-390d-4f8c-9fd5-00d697bbf6c9",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Account",
                    modelColumnID: "34257696-a4b3-4fb0-bd81-f15dc6581592",
                },
                {
                    id: "5aa7c764-df80-4228-9ba8-ca439fda24a1",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "2c89d8c4-8711-4743-aa57-17aa729d99ce",
                    dataSourceColumnID: "f67903cc-31f4-4173-a560-1a3c262504b1",
                    dataType: "Bool",
                    name: "OptOut_TicketChecker",
                    displayName: null,
                    visualNameShort: "Opt Out Ticket Checker",
                    visualNameFull: "Opt Out Ticket Checker (Account)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "9f5ed170-390d-4f8c-9fd5-00d697bbf6c9",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Account",
                    modelColumnID: "5aa7c764-df80-4228-9ba8-ca439fda24a1",
                },
                {
                    id: "0ceec064-9b07-455a-8c63-d7dadc5fd658",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "2c89d8c4-8711-4743-aa57-17aa729d99ce",
                    dataSourceColumnID: "90e0717d-098d-49b1-b1a6-750649c82210",
                    dataType: "Text",
                    name: "PasswordResetToken",
                    displayName: null,
                    visualNameShort: "Password Reset Token",
                    visualNameFull: "Password Reset Token (Account)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "9f5ed170-390d-4f8c-9fd5-00d697bbf6c9",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Account",
                    modelColumnID: "0ceec064-9b07-455a-8c63-d7dadc5fd658",
                },
                {
                    id: "b9666296-ec61-4d1c-b43e-6f168940c138",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "2c89d8c4-8711-4743-aa57-17aa729d99ce",
                    dataSourceColumnID: "5e1a128c-ab8a-4bce-bb25-bc4deba380f3",
                    dataType: "DateTime",
                    name: "PasswordResetTokenExpirationDate",
                    displayName: null,
                    visualNameShort: "Password Reset Token Expiration Date",
                    visualNameFull: "Password Reset Token Expiration Date (Account)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "9f5ed170-390d-4f8c-9fd5-00d697bbf6c9",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Account",
                    modelColumnID: "b9666296-ec61-4d1c-b43e-6f168940c138",
                },
                {
                    id: "439c49f8-59f0-4a64-bb0f-7d34aa16616f",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "2c89d8c4-8711-4743-aa57-17aa729d99ce",
                    dataSourceColumnID: "bea0ab0f-6afd-4e45-9abd-7469d51dd32e",
                    dataType: "Text",
                    name: "Phone",
                    displayName: null,
                    visualNameShort: "Phone",
                    visualNameFull: "Phone (Account)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "9f5ed170-390d-4f8c-9fd5-00d697bbf6c9",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Account",
                    modelColumnID: "439c49f8-59f0-4a64-bb0f-7d34aa16616f",
                },
                {
                    id: "fef423bc-53a9-470e-969a-eb3182a3f1fe",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "2c89d8c4-8711-4743-aa57-17aa729d99ce",
                    dataSourceColumnID: "a167baef-2995-4156-a6f1-66a8a13be40a",
                    dataType: "Text",
                    name: "RandomCode",
                    displayName: null,
                    visualNameShort: "Random Code",
                    visualNameFull: "Random Code (Account)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "9f5ed170-390d-4f8c-9fd5-00d697bbf6c9",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Account",
                    modelColumnID: "fef423bc-53a9-470e-969a-eb3182a3f1fe",
                },
                {
                    id: "99913abb-a3be-499e-817a-0e473be7325b",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "2c89d8c4-8711-4743-aa57-17aa729d99ce",
                    dataSourceColumnID: "0d23becd-eb17-4694-afa8-778f9becea96",
                    dataType: "DateTime",
                    name: "RandomCodeExpirationDate",
                    displayName: null,
                    visualNameShort: "Random Code Expiration Date",
                    visualNameFull: "Random Code Expiration Date (Account)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "9f5ed170-390d-4f8c-9fd5-00d697bbf6c9",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Account",
                    modelColumnID: "99913abb-a3be-499e-817a-0e473be7325b",
                },
                {
                    id: "1c07547b-922e-4a36-ab84-be37e6ae7811",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "2c89d8c4-8711-4743-aa57-17aa729d99ce",
                    dataSourceColumnID: "635e9ae9-7530-4ce8-a99d-e91e17dca078",
                    dataType: "Text",
                    name: "SocialAvatar",
                    displayName: null,
                    visualNameShort: "Social Avatar",
                    visualNameFull: "Social Avatar (Account)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "9f5ed170-390d-4f8c-9fd5-00d697bbf6c9",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Account",
                    modelColumnID: "1c07547b-922e-4a36-ab84-be37e6ae7811",
                },
                {
                    id: "71cd60d7-5fac-46a0-bcd4-ff343b361031",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "2c89d8c4-8711-4743-aa57-17aa729d99ce",
                    dataSourceColumnID: "cfd058e4-e715-41d6-ae1b-315e5fbb55a7",
                    dataType: "Int",
                    name: "Status",
                    displayName: null,
                    visualNameShort: "Status",
                    visualNameFull: "Status (Account)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "9f5ed170-390d-4f8c-9fd5-00d697bbf6c9",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Account",
                    modelColumnID: "71cd60d7-5fac-46a0-bcd4-ff343b361031",
                },
                {
                    id: "21cc9fe3-a234-485d-bef7-cb73e0fed424",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "2c89d8c4-8711-4743-aa57-17aa729d99ce",
                    dataSourceColumnID: "5c09a82e-361d-49e1-8768-35eea5b9bb9e",
                    dataType: "Text",
                    name: "Username",
                    displayName: null,
                    visualNameShort: "Username",
                    visualNameFull: "Username (Account)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "9f5ed170-390d-4f8c-9fd5-00d697bbf6c9",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Account",
                    modelColumnID: "21cc9fe3-a234-485d-bef7-cb73e0fed424",
                },
            ],
            rowLevelSecurity: {
                Endpoint: null,
            },
            position: {
                x: 639,
                y: 352.5,
            },
            $type: "DataSourceModelTable",
        },
        {
            id: "c0ff2198-e12c-2204-238e-30ee2b4a71ef",
            dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
            dataSourceTableID: "b71bfc71-1f49-4965-b927-35fe1434c5c0",
            name: "ContactInfo",
            visualName: "Contact Info",
            columns: [
                {
                    id: "348ee7b6-511d-05ba-15c8-aa413c2a0bc8",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "b71bfc71-1f49-4965-b927-35fe1434c5c0",
                    dataSourceColumnID: "5fc42b9b-61c7-41b2-8314-d09a17ad8d83",
                    dataType: "IDUUID",
                    name: "ContactInfoID",
                    displayName: null,
                    visualNameShort: "Contact Info ID",
                    visualNameFull: "Contact Info ID (Contact Info)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c0ff2198-e12c-2204-238e-30ee2b4a71ef",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "ContactInfo",
                    modelColumnID: "348ee7b6-511d-05ba-15c8-aa413c2a0bc8",
                },
                {
                    id: "a02840dc-5baa-41c4-458d-fee0706147d0",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "b71bfc71-1f49-4965-b927-35fe1434c5c0",
                    dataSourceColumnID: "bf7ace3e-d5b9-4b94-803b-518c88422e66",
                    dataType: "Bool",
                    name: "Deleted",
                    displayName: null,
                    visualNameShort: "Deleted",
                    visualNameFull: "Deleted (Contact Info)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c0ff2198-e12c-2204-238e-30ee2b4a71ef",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "ContactInfo",
                    modelColumnID: "a02840dc-5baa-41c4-458d-fee0706147d0",
                },
                {
                    id: "dcd01316-99a3-8c1f-0305-69d9829fcda1",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "b71bfc71-1f49-4965-b927-35fe1434c5c0",
                    dataSourceColumnID: "4a390b19-ba9d-4398-bca5-ed0e10fb44af",
                    dataType: "Int",
                    name: "Department",
                    displayName: null,
                    visualNameShort: "Department",
                    visualNameFull: "Department (Contact Info)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c0ff2198-e12c-2204-238e-30ee2b4a71ef",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "ContactInfo",
                    modelColumnID: "dcd01316-99a3-8c1f-0305-69d9829fcda1",
                },
                {
                    id: "d35d8a25-c570-11b7-97df-0f26662a2729",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "b71bfc71-1f49-4965-b927-35fe1434c5c0",
                    dataSourceColumnID: "eefec72f-99db-41e2-a314-1d568b0b55a5",
                    dataType: "Text",
                    name: "Email",
                    displayName: null,
                    visualNameShort: "Email",
                    visualNameFull: "Email (Contact Info)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c0ff2198-e12c-2204-238e-30ee2b4a71ef",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "ContactInfo",
                    modelColumnID: "d35d8a25-c570-11b7-97df-0f26662a2729",
                },
                {
                    id: "d1c77f03-98ca-4e11-9aee-b5d004341588",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "b71bfc71-1f49-4965-b927-35fe1434c5c0",
                    dataSourceColumnID: "b1d747ac-7c19-4201-a822-c8911c6cf54b",
                    dataType: "Text",
                    name: "Name",
                    displayName: null,
                    visualNameShort: "Name",
                    visualNameFull: "Name (Contact Info)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c0ff2198-e12c-2204-238e-30ee2b4a71ef",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "ContactInfo",
                    modelColumnID: "d1c77f03-98ca-4e11-9aee-b5d004341588",
                },
                {
                    id: "6c385d3d-54bd-2905-572d-68ed5c24b369",
                    calculations: [
                        {
                            transformationID: "7f843659-c8b6-415f-aecf-2c39fc5b63e9",
                            name: "New Column 3318",
                            default: true,
                            viewReferenceName: "verbcalccol_7f843659_c8b6_415f_aecf_2c39fc5b63e9_v_0",
                            columnReferenceName: null,
                            versionDetails: "NotPublished",
                            publishedOn: null,
                        },
                    ],
                    dataType: "Unsupported",
                    name: "New Column 3318",
                    displayName: null,
                    visualNameShort: "New Column 3318",
                    visualNameFull: "New Column 3318 (Contact Info)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c0ff2198-e12c-2204-238e-30ee2b4a71ef",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "ContactInfo",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "6c385d3d-54bd-2905-572d-68ed5c24b369",
                },
                {
                    id: "21279ae3-438c-7a5a-b30a-4722bcb94602",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "b71bfc71-1f49-4965-b927-35fe1434c5c0",
                    dataSourceColumnID: "e2c36d84-e88b-40f3-9ffa-9c1900b924bc",
                    dataType: "IDUUID",
                    name: "OrganizationID",
                    displayName: null,
                    visualNameShort: "Organization ID",
                    visualNameFull: "Organization ID (Contact Info)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c0ff2198-e12c-2204-238e-30ee2b4a71ef",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "ContactInfo",
                    modelColumnID: "21279ae3-438c-7a5a-b30a-4722bcb94602",
                },
                {
                    id: "07601656-5e52-5c8d-b75a-ea292009acd4",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "b71bfc71-1f49-4965-b927-35fe1434c5c0",
                    dataSourceColumnID: "d6e78a65-6892-4bb4-a5e6-15106d6b5c2c",
                    dataType: "Text",
                    name: "Phone",
                    displayName: null,
                    visualNameShort: "Phone",
                    visualNameFull: "Phone (Contact Info)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c0ff2198-e12c-2204-238e-30ee2b4a71ef",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "ContactInfo",
                    modelColumnID: "07601656-5e52-5c8d-b75a-ea292009acd4",
                },
            ],
            rowLevelSecurity: {
                Endpoint: null,
            },
            position: {
                x: 639,
                y: 447.5,
            },
            $type: "DataSourceModelTable",
        },
        {
            id: "38bd387a-6f59-42cd-bf09-b963bbc0fbb9",
            dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
            dataSourceTableID: "1cbe278b-036d-4d39-af6c-a6b508d75b76",
            name: "Event",
            visualName: "Event",
            columns: [
                {
                    id: "d95c7629-6e68-4b96-b83b-3e2540401ebb",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "1cbe278b-036d-4d39-af6c-a6b508d75b76",
                    dataSourceColumnID: "d6affae4-fd50-4b67-8fe5-ff20a760e6be",
                    dataType: "IDUUID",
                    name: "EventID",
                    displayName: null,
                    visualNameShort: "Event ID",
                    visualNameFull: "Event ID (Event)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "38bd387a-6f59-42cd-bf09-b963bbc0fbb9",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Event",
                    modelColumnID: "d95c7629-6e68-4b96-b83b-3e2540401ebb",
                },
                {
                    id: "48b0f677-0b78-483c-b178-585f1eb69131",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "1cbe278b-036d-4d39-af6c-a6b508d75b76",
                    dataSourceColumnID: "01fb5b3f-b692-4b67-99be-b83e79022aaa",
                    dataType: "DateTime",
                    name: "DateTimeUtc",
                    displayName: null,
                    visualNameShort: "Date Time Utc",
                    visualNameFull: "Date Time Utc (Event)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "38bd387a-6f59-42cd-bf09-b963bbc0fbb9",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Event",
                    modelColumnID: "48b0f677-0b78-483c-b178-585f1eb69131",
                },
                {
                    id: "e6610fd3-1fdf-4944-bfdb-d50e35daddaa",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "1cbe278b-036d-4d39-af6c-a6b508d75b76",
                    dataSourceColumnID: "6e97ad9d-106d-4056-95b9-63f1ca386d44",
                    dataType: "Bool",
                    name: "Deleted",
                    displayName: null,
                    visualNameShort: "Deleted",
                    visualNameFull: "Deleted (Event)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "38bd387a-6f59-42cd-bf09-b963bbc0fbb9",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Event",
                    modelColumnID: "e6610fd3-1fdf-4944-bfdb-d50e35daddaa",
                },
                {
                    id: "cc06f337-635d-4e09-9c07-a543bd8196a9",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "1cbe278b-036d-4d39-af6c-a6b508d75b76",
                    dataSourceColumnID: "cad43612-e183-4895-b494-903fff77c3de",
                    dataType: "Text",
                    name: "Description",
                    displayName: null,
                    visualNameShort: "Description",
                    visualNameFull: "Description (Event)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "38bd387a-6f59-42cd-bf09-b963bbc0fbb9",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Event",
                    modelColumnID: "cc06f337-635d-4e09-9c07-a543bd8196a9",
                },
                {
                    id: "215420eb-450e-477b-98d1-9e11a7337c74",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "1cbe278b-036d-4d39-af6c-a6b508d75b76",
                    dataSourceColumnID: "4c94753d-cb25-4caf-9bb2-c47aafc826fe",
                    dataType: "Bool",
                    name: "Disabled",
                    displayName: null,
                    visualNameShort: "Disabled",
                    visualNameFull: "Disabled (Event)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "38bd387a-6f59-42cd-bf09-b963bbc0fbb9",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Event",
                    modelColumnID: "215420eb-450e-477b-98d1-9e11a7337c74",
                },
                {
                    id: "eefbe9e7-ad7e-48a9-92d5-2b2f6280bc41",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "1cbe278b-036d-4d39-af6c-a6b508d75b76",
                    dataSourceColumnID: "1cff276c-71c7-4775-bd45-bed7fbfa8596",
                    dataType: "Text",
                    name: "ExperienceID",
                    displayName: null,
                    visualNameShort: "Experience ID",
                    visualNameFull: "Experience ID (Event)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "38bd387a-6f59-42cd-bf09-b963bbc0fbb9",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Event",
                    modelColumnID: "eefbe9e7-ad7e-48a9-92d5-2b2f6280bc41",
                },
                {
                    id: "b91a4445-130d-4e37-9f1a-eddb6f30225a",
                    calculations: [],
                    dataType: "Text",
                    name: "New Column 3129",
                    displayName: null,
                    visualNameShort: "New Column 3129",
                    visualNameFull: "New Column 3129 (Event)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "38bd387a-6f59-42cd-bf09-b963bbc0fbb9",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Event",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "b91a4445-130d-4e37-9f1a-eddb6f30225a",
                },
                {
                    id: "244812d1-8d83-4c43-b35a-53456d95368f",
                    calculations: [
                        {
                            transformationID: "fe3d32b3-cbff-48cd-af06-8b0dd3d91349",
                            name: "New Column 3492",
                            default: true,
                            viewReferenceName: "verbcalccol_fe3d32b3_cbff_48cd_af06_8b0dd3d91349_v_0",
                            columnReferenceName: null,
                            versionDetails: "NotPublished",
                            publishedOn: null,
                        },
                    ],
                    dataType: "Unsupported",
                    name: "New Column 3492",
                    displayName: null,
                    visualNameShort: "New Column 3492",
                    visualNameFull: "New Column 3492 (Event)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "38bd387a-6f59-42cd-bf09-b963bbc0fbb9",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Event",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "244812d1-8d83-4c43-b35a-53456d95368f",
                },
                {
                    id: "0fbef4bd-84a8-07f1-5d75-6e52a9f93af2",
                    calculations: [
                        {
                            transformationID: "0ebb54c9-8ae4-47de-b5f2-545c4bf8079a",
                            name: "New Column 5948",
                            default: true,
                            viewReferenceName: "verbcalccol_0ebb54c9_8ae4_47de_b5f2_545c4bf8079a_v_0",
                            columnReferenceName: null,
                            versionDetails: "NotPublished",
                            publishedOn: null,
                        },
                    ],
                    dataType: "Unsupported",
                    name: "New Column 5948",
                    displayName: null,
                    visualNameShort: "New Column 5948",
                    visualNameFull: "New Column 5948 (Event)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "38bd387a-6f59-42cd-bf09-b963bbc0fbb9",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Event",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "0fbef4bd-84a8-07f1-5d75-6e52a9f93af2",
                },
                {
                    id: "fdac9aad-8555-46e1-8fce-bb71b6d97210",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "1cbe278b-036d-4d39-af6c-a6b508d75b76",
                    dataSourceColumnID: "c7ce4aa6-42c1-4570-a118-d53edfe85b30",
                    dataType: "IDUUID",
                    name: "OrganizationID",
                    displayName: null,
                    visualNameShort: "Organization ID",
                    visualNameFull: "Organization ID (Event)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "38bd387a-6f59-42cd-bf09-b963bbc0fbb9",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Event",
                    modelColumnID: "fdac9aad-8555-46e1-8fce-bb71b6d97210",
                },
                {
                    id: "835de423-a070-4460-8afc-d175e5fc371b",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "1cbe278b-036d-4d39-af6c-a6b508d75b76",
                    dataSourceColumnID: "70963e7b-f089-4fa8-a263-9184c6c55dfb",
                    dataType: "Int",
                    name: "TicketingProvider",
                    displayName: null,
                    visualNameShort: "Ticketing Provider",
                    visualNameFull: "Ticketing Provider (Event)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "38bd387a-6f59-42cd-bf09-b963bbc0fbb9",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Event",
                    modelColumnID: "835de423-a070-4460-8afc-d175e5fc371b",
                },
                {
                    id: "683cce9f-8caf-4421-9eaf-ba77ab7e4941",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "1cbe278b-036d-4d39-af6c-a6b508d75b76",
                    dataSourceColumnID: "4367a8c6-e612-477d-951a-4f045605e69c",
                    dataType: "Text",
                    name: "TicketingProviderConfigJson",
                    displayName: null,
                    visualNameShort: "Ticketing Provider Config Json",
                    visualNameFull: "Ticketing Provider Config Json (Event)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "38bd387a-6f59-42cd-bf09-b963bbc0fbb9",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Event",
                    modelColumnID: "683cce9f-8caf-4421-9eaf-ba77ab7e4941",
                },
                {
                    id: "d04ed26f-d33e-418b-9e91-aab4eb0981c5",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "1cbe278b-036d-4d39-af6c-a6b508d75b76",
                    dataSourceColumnID: "313239a1-3af6-45a4-b52d-a7e6330cf20b",
                    dataType: "Bool",
                    name: "TimeTBD",
                    displayName: null,
                    visualNameShort: "Time TBD",
                    visualNameFull: "Time TBD (Event)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "38bd387a-6f59-42cd-bf09-b963bbc0fbb9",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Event",
                    modelColumnID: "d04ed26f-d33e-418b-9e91-aab4eb0981c5",
                },
                {
                    id: "8c1ad9bf-f5c6-4f43-bc65-c78d6ddb32ed",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "1cbe278b-036d-4d39-af6c-a6b508d75b76",
                    dataSourceColumnID: "821ed952-1e04-497b-b9e0-d5041f93734d",
                    dataType: "Text",
                    name: "Title",
                    displayName: null,
                    visualNameShort: "Title",
                    visualNameFull: "Title (Event)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "38bd387a-6f59-42cd-bf09-b963bbc0fbb9",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Event",
                    modelColumnID: "8c1ad9bf-f5c6-4f43-bc65-c78d6ddb32ed",
                },
                {
                    id: "b8bbf026-74f7-402c-b21c-06e520eb18bc",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "1cbe278b-036d-4d39-af6c-a6b508d75b76",
                    dataSourceColumnID: "ffc17021-85fc-4a7b-8241-2c0e44b28343",
                    dataType: "IDUUID",
                    name: "VenueMapID",
                    displayName: null,
                    visualNameShort: "Venue Map ID",
                    visualNameFull: "Venue Map ID (Event)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "38bd387a-6f59-42cd-bf09-b963bbc0fbb9",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Event",
                    modelColumnID: "b8bbf026-74f7-402c-b21c-06e520eb18bc",
                },
            ],
            rowLevelSecurity: {},
            position: {
                x: -81,
                y: 632.5,
            },
            $type: "DataSourceModelTable",
        },
        {
            id: "e840b47f-0aae-47ac-9f1e-65bb671f0e1f",
            dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
            dataSourceTableID: "7e7d100b-a6d4-4698-bada-7261a2b1b983",
            name: "Group",
            visualName: "Group",
            columns: [
                {
                    id: "51d0c19f-932a-4013-97a2-fd52f6aa187b",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "7e7d100b-a6d4-4698-bada-7261a2b1b983",
                    dataSourceColumnID: "a9639087-6004-4f97-89b7-ef3958a89d33",
                    dataType: "IDUUID",
                    name: "GroupID",
                    displayName: null,
                    visualNameShort: "Group ID",
                    visualNameFull: "Group ID (Group)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "e840b47f-0aae-47ac-9f1e-65bb671f0e1f",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Group",
                    modelColumnID: "51d0c19f-932a-4013-97a2-fd52f6aa187b",
                },
                {
                    id: "a9730167-070d-495f-b351-b0482708bccd",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "7e7d100b-a6d4-4698-bada-7261a2b1b983",
                    dataSourceColumnID: "222763e0-a7bb-47e7-be5e-7943e355fb5c",
                    dataType: "Text",
                    name: "AccessCode",
                    displayName: null,
                    visualNameShort: "Access Code",
                    visualNameFull: "Access Code (Group)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {
                        Endpoint: {
                            Blank: {
                                id: "a553e624-e5b1-445f-a270-cec8a934842e",
                                outerBlocks: [
                                    {
                                        innerBlocks: [
                                            {
                                                leftValue: {
                                                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                                                    dataSourceTableID: "7e7d100b-a6d4-4698-bada-7261a2b1b983",
                                                    dataSourceColumnID: "222763e0-a7bb-47e7-be5e-7943e355fb5c",
                                                    $type: "ColumnBlockValue",
                                                },
                                                compareOp: "Equal",
                                                rightValue: {
                                                    valueSelector: "User.ID",
                                                    $type: "UserBlockValue",
                                                },
                                                $type: "AndBlock",
                                            },
                                            {
                                                leftValue: {
                                                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                                                    dataSourceTableID: "7e7d100b-a6d4-4698-bada-7261a2b1b983",
                                                    dataSourceColumnID: "222763e0-a7bb-47e7-be5e-7943e355fb5c",
                                                    $type: "ColumnBlockValue",
                                                },
                                                compareOp: "Equal",
                                                rightValue: {
                                                    valueSelector: "User.ID",
                                                    $type: "UserBlockValue",
                                                },
                                                $type: "AndBlock",
                                            },
                                        ],
                                    },
                                ],
                                $type: "TableSecurityConfig",
                            },
                            Hide: null,
                            Mask: null,
                            Error: null,
                        },
                    },
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "e840b47f-0aae-47ac-9f1e-65bb671f0e1f",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Group",
                    modelColumnID: "a9730167-070d-495f-b351-b0482708bccd",
                },
                {
                    id: "716043b8-0f8b-4945-bb94-04689b5b5203",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "7e7d100b-a6d4-4698-bada-7261a2b1b983",
                    dataSourceColumnID: "cd6eb9fe-4be6-4e1f-8d2e-81cc447b2afd",
                    dataType: "Bool",
                    name: "Deleted",
                    displayName: null,
                    visualNameShort: "Deleted",
                    visualNameFull: "Deleted (Group)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "e840b47f-0aae-47ac-9f1e-65bb671f0e1f",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Group",
                    modelColumnID: "716043b8-0f8b-4945-bb94-04689b5b5203",
                },
                {
                    id: "14e69aa3-0869-474a-9f96-222e20b80967",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "7e7d100b-a6d4-4698-bada-7261a2b1b983",
                    dataSourceColumnID: "2266af28-f907-47ec-b511-e5b73698c590",
                    dataType: "Text",
                    name: "Description",
                    displayName: null,
                    visualNameShort: "Description",
                    visualNameFull: "Description (Group)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "e840b47f-0aae-47ac-9f1e-65bb671f0e1f",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Group",
                    modelColumnID: "14e69aa3-0869-474a-9f96-222e20b80967",
                },
                {
                    id: "6d8e31f1-9613-4285-b056-de2308b7dff1",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "7e7d100b-a6d4-4698-bada-7261a2b1b983",
                    dataSourceColumnID: "a9dd7570-d0b4-42b3-aa18-52f933dca63e",
                    dataType: "Bool",
                    name: "GroupPageEnabled",
                    displayName: null,
                    visualNameShort: "Group Page Enabled",
                    visualNameFull: "Group Page Enabled (Group)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "e840b47f-0aae-47ac-9f1e-65bb671f0e1f",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Group",
                    modelColumnID: "6d8e31f1-9613-4285-b056-de2308b7dff1",
                },
                {
                    id: "0812732d-492c-49c0-aea4-776f2a25b603",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "7e7d100b-a6d4-4698-bada-7261a2b1b983",
                    dataSourceColumnID: "b4b07e96-c0c1-4307-aae4-f07e0d59c326",
                    dataType: "Text",
                    name: "Logo",
                    displayName: null,
                    visualNameShort: "Logo",
                    visualNameFull: "Logo (Group)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "e840b47f-0aae-47ac-9f1e-65bb671f0e1f",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Group",
                    modelColumnID: "0812732d-492c-49c0-aea4-776f2a25b603",
                },
                {
                    id: "cf306c82-0557-4e71-b68b-d13c8ef21c7f",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "7e7d100b-a6d4-4698-bada-7261a2b1b983",
                    dataSourceColumnID: "2dabd448-6b8b-47e9-b51e-48f59dbfffe1",
                    dataType: "Text",
                    name: "Name",
                    displayName: null,
                    visualNameShort: "Name",
                    visualNameFull: "Name (Group)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "e840b47f-0aae-47ac-9f1e-65bb671f0e1f",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Group",
                    modelColumnID: "cf306c82-0557-4e71-b68b-d13c8ef21c7f",
                },
                {
                    id: "5e2084d6-10f4-40d7-979f-f6e3f6f8cff5",
                    calculations: [
                        {
                            transformationID: "13b9d5ce-b055-4a4d-b7f0-1a212fdf5879",
                            name: "New Column 4543",
                            default: true,
                            viewReferenceName: "verbcalccol_13b9d5ce_b055_4a4d_b7f0_1a212fdf5879_v_0",
                            columnReferenceName: null,
                            versionDetails: "NotPublished",
                            publishedOn: null,
                        },
                    ],
                    dataType: "Unsupported",
                    name: "New Column 4543",
                    displayName: null,
                    visualNameShort: "New Column 4543",
                    visualNameFull: "New Column 4543 (Group)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "e840b47f-0aae-47ac-9f1e-65bb671f0e1f",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Group",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "5e2084d6-10f4-40d7-979f-f6e3f6f8cff5",
                },
                {
                    id: "54981ada-4bc6-4879-8da2-7bf7eec7a39c",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "7e7d100b-a6d4-4698-bada-7261a2b1b983",
                    dataSourceColumnID: "571f221c-c744-48e7-8908-05871a68da2e",
                    dataType: "Int",
                    name: "OrgImageOption",
                    displayName: null,
                    visualNameShort: "Org Image Option",
                    visualNameFull: "Org Image Option (Group)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "e840b47f-0aae-47ac-9f1e-65bb671f0e1f",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Group",
                    modelColumnID: "54981ada-4bc6-4879-8da2-7bf7eec7a39c",
                },
            ],
            rowLevelSecurity: {},
            position: {
                x: -81,
                y: 792.5,
            },
            $type: "DataSourceModelTable",
        },
        {
            id: "debc95c3-6b7a-4be8-a89b-773714b27440",
            dataSourceID: "076b802f-ef41-4d96-b538-495ca2113bc0",
            dataSourceTableID: "bc0951e0-3ee3-4044-9cc4-4bc461ef940e",
            name: "GSOrders",
            visualName: "GS Orders",
            columns: [
                {
                    id: "8b1385d1-3fdc-47db-9cfc-a1c775f2ef61",
                    dataSourceID: "076b802f-ef41-4d96-b538-495ca2113bc0",
                    dataSourceTableID: "bc0951e0-3ee3-4044-9cc4-4bc461ef940e",
                    dataSourceColumnID: "57238760-0a34-40a4-a3a9-37391dde13b7",
                    dataType: "Double",
                    name: "CustomerID",
                    displayName: null,
                    visualNameShort: "Customer ID",
                    visualNameFull: "Customer ID (GS Orders)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "debc95c3-6b7a-4be8-a89b-773714b27440",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "GSOrders",
                    modelColumnID: "8b1385d1-3fdc-47db-9cfc-a1c775f2ef61",
                },
                {
                    id: "79c7d7d1-f7ea-4ae2-801d-6c64f0de5de4",
                    dataSourceID: "076b802f-ef41-4d96-b538-495ca2113bc0",
                    dataSourceTableID: "bc0951e0-3ee3-4044-9cc4-4bc461ef940e",
                    dataSourceColumnID: "81c9eb63-9dd0-4c3a-931b-5bd9b2b8b9b9",
                    dataType: "DateTime",
                    name: "DatePlaced",
                    displayName: null,
                    visualNameShort: "Date Placed",
                    visualNameFull: "Date Placed (GS Orders)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "debc95c3-6b7a-4be8-a89b-773714b27440",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "GSOrders",
                    modelColumnID: "79c7d7d1-f7ea-4ae2-801d-6c64f0de5de4",
                },
                {
                    id: "04ca09e0-10bc-4eb5-90f4-734e043a1789",
                    dataSourceID: "076b802f-ef41-4d96-b538-495ca2113bc0",
                    dataSourceTableID: "bc0951e0-3ee3-4044-9cc4-4bc461ef940e",
                    dataSourceColumnID: "3ad3266e-f849-410d-a806-328ef098938e",
                    dataType: "Double",
                    name: "NumItems",
                    displayName: null,
                    visualNameShort: "Num Items",
                    visualNameFull: "Num Items (GS Orders)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "debc95c3-6b7a-4be8-a89b-773714b27440",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "GSOrders",
                    modelColumnID: "04ca09e0-10bc-4eb5-90f4-734e043a1789",
                },
                {
                    id: "c484ae3c-26b7-4e92-ae00-12c8dde581ff",
                    dataSourceID: "076b802f-ef41-4d96-b538-495ca2113bc0",
                    dataSourceTableID: "bc0951e0-3ee3-4044-9cc4-4bc461ef940e",
                    dataSourceColumnID: "9c595f87-aafa-4034-a27b-5f94ca76eb33",
                    dataType: "Double",
                    name: "OrderID",
                    displayName: null,
                    visualNameShort: "Order ID",
                    visualNameFull: "Order ID (GS Orders)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "debc95c3-6b7a-4be8-a89b-773714b27440",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "GSOrders",
                    modelColumnID: "c484ae3c-26b7-4e92-ae00-12c8dde581ff",
                },
                {
                    id: "9024ada1-5fd3-4d4f-9ff3-9535e5973512",
                    dataSourceID: "076b802f-ef41-4d96-b538-495ca2113bc0",
                    dataSourceTableID: "bc0951e0-3ee3-4044-9cc4-4bc461ef940e",
                    dataSourceColumnID: "6b8d0fb4-11e6-426b-9978-7e6c72b228b3",
                    dataType: "Double",
                    name: "TotalPrice",
                    displayName: null,
                    visualNameShort: "Total Price",
                    visualNameFull: "Total Price (GS Orders)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "debc95c3-6b7a-4be8-a89b-773714b27440",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "GSOrders",
                    modelColumnID: "9024ada1-5fd3-4d4f-9ff3-9535e5973512",
                },
            ],
            rowLevelSecurity: {},
            position: {
                x: 279,
                y: 432.5,
            },
            $type: "DataSourceModelTable",
        },
        {
            id: "972a51d7-0129-4fb2-b312-c2c5b66c34c2",
            dataSourceID: "3ec1cb6f-20c9-47fa-95b7-efb69cb7be6c",
            dataSourceTableID: "0b699430-6e79-4ecf-bf49-40d99bdc4d66",
            name: "League",
            visualName: "League",
            columns: [
                {
                    id: "606d4a7d-d403-4da9-8a20-627328fa5fe1",
                    dataSourceID: "3ec1cb6f-20c9-47fa-95b7-efb69cb7be6c",
                    dataSourceTableID: "0b699430-6e79-4ecf-bf49-40d99bdc4d66",
                    dataSourceColumnID: "d245dbba-ad5b-49ab-97ae-b066055054eb",
                    dataType: "Text",
                    name: "LeageAbbr",
                    displayName: null,
                    visualNameShort: "Leage Abbr",
                    visualNameFull: "Leage Abbr (League)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "972a51d7-0129-4fb2-b312-c2c5b66c34c2",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "League",
                    modelColumnID: "606d4a7d-d403-4da9-8a20-627328fa5fe1",
                },
                {
                    id: "9a7504be-f34e-42e6-a920-90c1269f27b1",
                    dataSourceID: "3ec1cb6f-20c9-47fa-95b7-efb69cb7be6c",
                    dataSourceTableID: "0b699430-6e79-4ecf-bf49-40d99bdc4d66",
                    dataSourceColumnID: "add92d5b-6a46-4dce-a0dd-aa14cade92bb",
                    dataType: "IDInt",
                    name: "League",
                    displayName: null,
                    visualNameShort: "League",
                    visualNameFull: "League (League)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "972a51d7-0129-4fb2-b312-c2c5b66c34c2",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "League",
                    modelColumnID: "9a7504be-f34e-42e6-a920-90c1269f27b1",
                },
                {
                    id: "f0967d2a-fae2-48b2-8e1a-d41bdbc57659",
                    dataSourceID: "3ec1cb6f-20c9-47fa-95b7-efb69cb7be6c",
                    dataSourceTableID: "0b699430-6e79-4ecf-bf49-40d99bdc4d66",
                    dataSourceColumnID: "287f70e8-de38-4777-a6d1-23d6e0b65854",
                    dataType: "Text",
                    name: "LeagueName",
                    displayName: null,
                    visualNameShort: "League Name",
                    visualNameFull: "League Name (League)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "972a51d7-0129-4fb2-b312-c2c5b66c34c2",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "League",
                    modelColumnID: "f0967d2a-fae2-48b2-8e1a-d41bdbc57659",
                },
            ],
            rowLevelSecurity: {},
            position: {
                x: -441,
                y: 672.5,
            },
            $type: "DataSourceModelTable",
        },
        {
            id: "e95b4ab0-58f1-a446-4a4f-f41531131d60",
            transformationID: "c1a80fc9-1921-42e3-ab46-ed818fa21f19",
            tableReferenceName: "verbview_c1a80fc9_1921_42e3_ab46_ed818fa21f19_v_201113042522589",
            versionDetails: "NotPublished",
            publishedOn: null,
            excludeFromModel: false,
            name: "luan testing view",
            visualName: "Luan Testing View",
            columns: [],
            rowLevelSecurity: {},
            position: {
                x: -591,
                y: 432.5,
            },
            $type: "ViewModelTable",
        },
        {
            id: "613094a9-c612-798c-33f0-8a8480e2e275",
            transformationID: "25424c15-d02a-4dc0-9db4-a4715221b5de",
            tableReferenceName: "verbview_25424c15_d02a_4dc0_9db4_a4715221b5de_v_201224213740824",
            versionDetails: "LatestNotPublished",
            publishedOn: "2020-12-24T21:37:43.056Z",
            excludeFromModel: false,
            name: "MLB Orgs2",
            visualName: "MLB Orgs2",
            columns: [],
            rowLevelSecurity: {},
            position: {
                x: -591,
                y: 582.5,
            },
            $type: "ViewModelTable",
        },
        {
            id: "e1b4d669-38c9-e60d-531b-1baac67c623d",
            transformationID: "83c0cff8-9614-4cbc-a8d9-5286aff26cd2",
            tableReferenceName: "verbview_83c0cff8_9614_4cbc_a8d9_5286aff26cd2",
            versionDetails: "NotPublished",
            publishedOn: null,
            excludeFromModel: false,
            name: "New View",
            visualName: "New View",
            columns: [],
            rowLevelSecurity: {},
            position: {
                x: -591,
                y: 732.5,
            },
            $type: "ViewModelTable",
        },
        {
            id: "1f302992-b36e-424b-bbb9-8f43ca490c3b",
            transformationID: "1c4eba55-feaa-4707-87f6-1b17ea27e1e3",
            tableReferenceName: "verbview_1c4eba55_feaa_4707_87f6_1b17ea27e1e3_v_201114075923110",
            versionDetails: "NotPublished",
            publishedOn: null,
            excludeFromModel: false,
            name: "New View 1053",
            visualName: "New View 1053",
            columns: [],
            rowLevelSecurity: {},
            position: {
                x: -741,
                y: 432.5,
            },
            $type: "ViewModelTable",
        },
        {
            id: "1b9a0a84-5bad-d89c-25e8-4dbbf0b00603",
            transformationID: "41205dd3-26e9-4be0-9aaf-201371a38cc7",
            tableReferenceName: "verbview_41205dd3_26e9_4be0_9aaf_201371a38cc7_v_0",
            versionDetails: "NotPublished",
            publishedOn: null,
            excludeFromModel: false,
            name: "New View 1328",
            visualName: "New View 1328",
            columns: [
                {
                    id: "558316a7-65a9-80bb-7550-b313adf0404e",
                    columnReferenceName: "groupmatics_dbo_group_accesscode",
                    name: "AccessCode",
                    displayName: null,
                    visualNameShort: "Access Code",
                    visualNameFull: "Access Code (New View 1328)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "1b9a0a84-5bad-d89c-25e8-4dbbf0b00603",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 1328",
                    modelColumnID: "558316a7-65a9-80bb-7550-b313adf0404e",
                },
                {
                    id: "5da07ccb-fe34-d02a-39de-34ed9c854f15",
                    columnReferenceName: "groupmatics_dbo_group_deleted",
                    name: "Deleted",
                    displayName: null,
                    visualNameShort: "Deleted",
                    visualNameFull: "Deleted (New View 1328)",
                    dataType: "Bool",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "1b9a0a84-5bad-d89c-25e8-4dbbf0b00603",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 1328",
                    modelColumnID: "5da07ccb-fe34-d02a-39de-34ed9c854f15",
                },
                {
                    id: "da58827e-2475-2fff-86ee-82cbead02d12",
                    columnReferenceName: "groupmatics_dbo_group_description",
                    name: "Description",
                    displayName: null,
                    visualNameShort: "Description",
                    visualNameFull: "Description (New View 1328)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "1b9a0a84-5bad-d89c-25e8-4dbbf0b00603",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 1328",
                    modelColumnID: "da58827e-2475-2fff-86ee-82cbead02d12",
                },
                {
                    id: "5eede5c1-5849-c8eb-ab35-e6ac577f58cc",
                    columnReferenceName: "groupmatics_dbo_group_groupid",
                    name: "GroupID",
                    displayName: null,
                    visualNameShort: "Group ID",
                    visualNameFull: "Group ID (New View 1328)",
                    dataType: "IDUUID",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "1b9a0a84-5bad-d89c-25e8-4dbbf0b00603",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 1328",
                    modelColumnID: "5eede5c1-5849-c8eb-ab35-e6ac577f58cc",
                },
                {
                    id: "28ff60e0-8265-2298-ff9e-f44d638ccea7",
                    columnReferenceName: "groupmatics_dbo_group_grouppageenabled",
                    name: "GroupPageEnabled",
                    displayName: null,
                    visualNameShort: "Group Page Enabled",
                    visualNameFull: "Group Page Enabled (New View 1328)",
                    dataType: "Bool",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "1b9a0a84-5bad-d89c-25e8-4dbbf0b00603",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 1328",
                    modelColumnID: "28ff60e0-8265-2298-ff9e-f44d638ccea7",
                },
                {
                    id: "5d07885b-cd81-763b-c336-69be668901ff",
                    columnReferenceName: "groupmatics_dbo_group_logo",
                    name: "Logo",
                    displayName: null,
                    visualNameShort: "Logo",
                    visualNameFull: "Logo (New View 1328)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "1b9a0a84-5bad-d89c-25e8-4dbbf0b00603",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 1328",
                    modelColumnID: "5d07885b-cd81-763b-c336-69be668901ff",
                },
                {
                    id: "8e133088-188c-31e5-e9cd-639ffbc5cc6e",
                    columnReferenceName: "groupmatics_dbo_group_name",
                    name: "Name",
                    displayName: null,
                    visualNameShort: "Name",
                    visualNameFull: "Name (New View 1328)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "1b9a0a84-5bad-d89c-25e8-4dbbf0b00603",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 1328",
                    modelColumnID: "8e133088-188c-31e5-e9cd-639ffbc5cc6e",
                },
                {
                    id: "9043c856-2dc8-0b6f-fa9b-e99cd98df194",
                    columnReferenceName: "groupmatics_dbo_group_orgimageoption",
                    name: "OrgImageOption",
                    displayName: null,
                    visualNameShort: "Org Image Option",
                    visualNameFull: "Org Image Option (New View 1328)",
                    dataType: "Int",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "1b9a0a84-5bad-d89c-25e8-4dbbf0b00603",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 1328",
                    modelColumnID: "9043c856-2dc8-0b6f-fa9b-e99cd98df194",
                },
                {
                    id: "5cde8cd4-5dc1-1ee1-ff63-35de25259b09",
                    columnReferenceName: "verbcol_viewstepcolumn_7158new_column",
                    name: "ViewStepColumn_7158New column",
                    displayName: null,
                    visualNameShort: "View Step Column 7158 New Column",
                    visualNameFull: "View Step Column 7158 New Column (New View 1328)",
                    dataType: "Double",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "1b9a0a84-5bad-d89c-25e8-4dbbf0b00603",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 1328",
                    modelColumnID: "5cde8cd4-5dc1-1ee1-ff63-35de25259b09",
                },
            ],
            rowLevelSecurity: {},
            position: {
                x: -741,
                y: 582.5,
            },
            $type: "ViewModelTable",
        },
        {
            id: "b05d342d-ce24-42d4-a126-411d02ff9e01",
            transformationID: "0a0e033d-24cc-4f8b-bc39-7d09cd5ae324",
            tableReferenceName: "verbview_0a0e033d_24cc_4f8b_bc39_7d09cd5ae324_v_0",
            versionDetails: "NotPublished",
            publishedOn: null,
            excludeFromModel: false,
            name: "New View 1574",
            visualName: "New View 1574",
            columns: [],
            rowLevelSecurity: {},
            position: {
                x: -741,
                y: 732.5,
            },
            $type: "ViewModelTable",
        },
        {
            id: "14e6d7e0-1c15-1129-0fe9-7467cc6f8a45",
            transformationID: "1bca559d-d3de-409c-9139-a35dca6f3bff",
            tableReferenceName: "verbview_1bca559d_d3de_409c_9139_a35dca6f3bff_v_201110231456814",
            versionDetails: "NotPublished",
            publishedOn: null,
            excludeFromModel: false,
            name: "New View 2",
            visualName: "New View 2",
            columns: [],
            rowLevelSecurity: {},
            position: {
                x: -891,
                y: 432.5,
            },
            $type: "ViewModelTable",
        },
        {
            id: "ff94224c-449c-6421-f11c-da856cff503d",
            transformationID: "3e1728c8-17d0-4bd8-af9b-b08c0e3c524f",
            tableReferenceName: "verbview_3e1728c8_17d0_4bd8_af9b_b08c0e3c524f_v_210930070246143",
            versionDetails: "LatestNotPublished",
            publishedOn: "2021-09-30T07:02:46.363Z",
            excludeFromModel: false,
            name: "New View 2324 (price with tax)",
            visualName: "New View 2324 Price With Tax",
            columns: [
                {
                    id: "5d84994e-2e4f-4744-50c5-801060930045",
                    columnReferenceName: "groupmatics_dbo_order_accountid",
                    name: "AccountID",
                    displayName: null,
                    visualNameShort: "Account ID",
                    visualNameFull: "Account ID (New View 2324 Price With Tax)",
                    dataType: "IDUUID",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ff94224c-449c-6421-f11c-da856cff503d",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 2324 (price with tax)",
                    modelColumnID: "5d84994e-2e4f-4744-50c5-801060930045",
                },
                {
                    id: "65f649cf-38c6-e97c-6ab9-2be50bf23579",
                    columnReferenceName: "groupmatics_dbo_order_customerip",
                    name: "CustomerIP",
                    displayName: null,
                    visualNameShort: "Customer IP",
                    visualNameFull: "Customer IP (New View 2324 Price With Tax)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ff94224c-449c-6421-f11c-da856cff503d",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 2324 (price with tax)",
                    modelColumnID: "65f649cf-38c6-e97c-6ab9-2be50bf23579",
                },
                {
                    id: "248f7483-d8f1-a2e8-5a7c-aabbfacb41f8",
                    columnReferenceName: "groupmatics_dbo_order_dateplacedutc",
                    name: "DatePlacedUtc",
                    displayName: null,
                    visualNameShort: "Date Placed Utc",
                    visualNameFull: "Date Placed Utc (New View 2324 Price With Tax)",
                    dataType: "DateTime",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ff94224c-449c-6421-f11c-da856cff503d",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 2324 (price with tax)",
                    modelColumnID: "248f7483-d8f1-a2e8-5a7c-aabbfacb41f8",
                },
                {
                    id: "ce7e1853-9b0b-6e61-1da4-d7d013b1b492",
                    columnReferenceName: "groupmatics_dbo_order_deliverymethod",
                    name: "DeliveryMethod",
                    displayName: null,
                    visualNameShort: "Delivery Method",
                    visualNameFull: "Delivery Method (New View 2324 Price With Tax)",
                    dataType: "Int",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ff94224c-449c-6421-f11c-da856cff503d",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 2324 (price with tax)",
                    modelColumnID: "ce7e1853-9b0b-6e61-1da4-d7d013b1b492",
                },
                {
                    id: "1af7ba77-0823-0ec5-0d61-df39093c0e4e",
                    columnReferenceName: "groupmatics_dbo_order_donation",
                    name: "Donation",
                    displayName: null,
                    visualNameShort: "Donation",
                    visualNameFull: "Donation (New View 2324 Price With Tax)",
                    dataType: "Double",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ff94224c-449c-6421-f11c-da856cff503d",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 2324 (price with tax)",
                    modelColumnID: "1af7ba77-0823-0ec5-0d61-df39093c0e4e",
                },
                {
                    id: "533deebc-1b8e-235d-28d0-39123446528f",
                    columnReferenceName: "groupmatics_dbo_order_emailinvitationid",
                    name: "EmailInvitationID",
                    displayName: null,
                    visualNameShort: "Email Invitation ID",
                    visualNameFull: "Email Invitation ID (New View 2324 Price With Tax)",
                    dataType: "IDUUID",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ff94224c-449c-6421-f11c-da856cff503d",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 2324 (price with tax)",
                    modelColumnID: "533deebc-1b8e-235d-28d0-39123446528f",
                },
                {
                    id: "430092ef-a62a-5d44-c543-3066d3a28f80",
                    columnReferenceName: "groupmatics_dbo_order_eventservicecustomerid",
                    name: "EventServiceCustomerID",
                    displayName: null,
                    visualNameShort: "Event Service Customer ID",
                    visualNameFull: "Event Service Customer ID (New View 2324 Price With Tax)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ff94224c-449c-6421-f11c-da856cff503d",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 2324 (price with tax)",
                    modelColumnID: "430092ef-a62a-5d44-c543-3066d3a28f80",
                },
                {
                    id: "9b7de256-bc07-edda-042d-ac2af2228406",
                    columnReferenceName: "groupmatics_dbo_order_eventservicehaspdftickets",
                    name: "EventServiceHasPdfTickets",
                    displayName: null,
                    visualNameShort: "Event Service Has Pdf Tickets",
                    visualNameFull: "Event Service Has Pdf Tickets (New View 2324 Price With Tax)",
                    dataType: "Bool",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ff94224c-449c-6421-f11c-da856cff503d",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 2324 (price with tax)",
                    modelColumnID: "9b7de256-bc07-edda-042d-ac2af2228406",
                },
                {
                    id: "68c5a5d8-6079-2ab9-08a1-4db6c974a4fd",
                    columnReferenceName: "groupmatics_dbo_order_eventservicehidegmtickets",
                    name: "EventServiceHideGMTickets",
                    displayName: null,
                    visualNameShort: "Event Service Hide GM Tickets",
                    visualNameFull: "Event Service Hide GM Tickets (New View 2324 Price With Tax)",
                    dataType: "Bool",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ff94224c-449c-6421-f11c-da856cff503d",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 2324 (price with tax)",
                    modelColumnID: "68c5a5d8-6079-2ab9-08a1-4db6c974a4fd",
                },
                {
                    id: "c700f429-ee42-1f99-01c5-ec6e2058e879",
                    columnReferenceName: "groupmatics_dbo_order_eventservicenewcustomer",
                    name: "EventServiceNewCustomer",
                    displayName: null,
                    visualNameShort: "Event Service New Customer",
                    visualNameFull: "Event Service New Customer (New View 2324 Price With Tax)",
                    dataType: "Bool",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ff94224c-449c-6421-f11c-da856cff503d",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 2324 (price with tax)",
                    modelColumnID: "c700f429-ee42-1f99-01c5-ec6e2058e879",
                },
                {
                    id: "ce83c6fe-8c4d-67b2-6b4d-d9c79e42a03a",
                    columnReferenceName: "groupmatics_dbo_order_eventserviceorderid",
                    name: "EventServiceOrderID",
                    displayName: null,
                    visualNameShort: "Event Service Order ID",
                    visualNameFull: "Event Service Order ID (New View 2324 Price With Tax)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ff94224c-449c-6421-f11c-da856cff503d",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 2324 (price with tax)",
                    modelColumnID: "ce83c6fe-8c4d-67b2-6b4d-d9c79e42a03a",
                },
                {
                    id: "14274b87-c97a-d5f1-0e8f-a7170d665755",
                    columnReferenceName: "groupmatics_dbo_order_eventservicetemporarypassword",
                    name: "EventServiceTemporaryPassword",
                    displayName: null,
                    visualNameShort: "Event Service Temporary Password",
                    visualNameFull: "Event Service Temporary Password (New View 2324 Price With Tax)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ff94224c-449c-6421-f11c-da856cff503d",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 2324 (price with tax)",
                    modelColumnID: "14274b87-c97a-d5f1-0e8f-a7170d665755",
                },
                {
                    id: "c2b47011-7e27-1822-20fc-4e06bccb2aa0",
                    columnReferenceName: "groupmatics_dbo_order_eventserviceticketprovider",
                    name: "EventServiceTicketProvider",
                    displayName: null,
                    visualNameShort: "Event Service Ticket Provider",
                    visualNameFull: "Event Service Ticket Provider (New View 2324 Price With Tax)",
                    dataType: "Int",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ff94224c-449c-6421-f11c-da856cff503d",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 2324 (price with tax)",
                    modelColumnID: "c2b47011-7e27-1822-20fc-4e06bccb2aa0",
                },
                {
                    id: "936103ec-dfd0-284c-b214-c9e7422bcfb2",
                    columnReferenceName: "groupmatics_dbo_order_expgroup",
                    name: "ExpGroup",
                    displayName: null,
                    visualNameShort: "Exp Group",
                    visualNameFull: "Exp Group (New View 2324 Price With Tax)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ff94224c-449c-6421-f11c-da856cff503d",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 2324 (price with tax)",
                    modelColumnID: "936103ec-dfd0-284c-b214-c9e7422bcfb2",
                },
                {
                    id: "eea9e387-5e40-fe96-ef31-e1e16726d497",
                    columnReferenceName: "groupmatics_dbo_order_expirationdateutc",
                    name: "ExpirationDateUtc",
                    displayName: null,
                    visualNameShort: "Expiration Date Utc",
                    visualNameFull: "Expiration Date Utc (New View 2324 Price With Tax)",
                    dataType: "DateTime",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ff94224c-449c-6421-f11c-da856cff503d",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 2324 (price with tax)",
                    modelColumnID: "eea9e387-5e40-fe96-ef31-e1e16726d497",
                },
                {
                    id: "3d34a2f4-752f-303f-e67d-875e65a443a4",
                    columnReferenceName: "groupmatics_dbo_order_feesatcheckout",
                    name: "FeesAtCheckout",
                    displayName: null,
                    visualNameShort: "Fees At Checkout",
                    visualNameFull: "Fees At Checkout (New View 2324 Price With Tax)",
                    dataType: "Double",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ff94224c-449c-6421-f11c-da856cff503d",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 2324 (price with tax)",
                    modelColumnID: "3d34a2f4-752f-303f-e67d-875e65a443a4",
                },
                {
                    id: "e3b94ee1-8695-5310-2699-243aa8f3249b",
                    columnReferenceName: "groupmatics_dbo_order_feesatpayout",
                    name: "FeesAtPayout",
                    displayName: null,
                    visualNameShort: "Fees At Payout",
                    visualNameFull: "Fees At Payout (New View 2324 Price With Tax)",
                    dataType: "Double",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ff94224c-449c-6421-f11c-da856cff503d",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 2324 (price with tax)",
                    modelColumnID: "e3b94ee1-8695-5310-2699-243aa8f3249b",
                },
                {
                    id: "c81ee009-ff21-049e-d9af-45b226698298",
                    columnReferenceName: "groupmatics_dbo_order_flattaxes",
                    name: "FlatTaxes",
                    displayName: null,
                    visualNameShort: "Flat Taxes",
                    visualNameFull: "Flat Taxes (New View 2324 Price With Tax)",
                    dataType: "Double",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ff94224c-449c-6421-f11c-da856cff503d",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 2324 (price with tax)",
                    modelColumnID: "c81ee009-ff21-049e-d9af-45b226698298",
                },
                {
                    id: "b5e590b3-e0ef-8099-5dad-395e60938bba",
                    columnReferenceName: "groupmatics_dbo_order_orderid",
                    name: "OrderID",
                    displayName: null,
                    visualNameShort: "Order ID",
                    visualNameFull: "Order ID (New View 2324 Price With Tax)",
                    dataType: "IDUUID",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ff94224c-449c-6421-f11c-da856cff503d",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 2324 (price with tax)",
                    modelColumnID: "b5e590b3-e0ef-8099-5dad-395e60938bba",
                },
                {
                    id: "89d96648-2c46-c954-4157-7683fbe0c59e",
                    columnReferenceName: "groupmatics_dbo_order_ordernumber",
                    name: "OrderNumber",
                    displayName: null,
                    visualNameShort: "Order Number",
                    visualNameFull: "Order Number (New View 2324 Price With Tax)",
                    dataType: "Int",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ff94224c-449c-6421-f11c-da856cff503d",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 2324 (price with tax)",
                    modelColumnID: "89d96648-2c46-c954-4157-7683fbe0c59e",
                },
                {
                    id: "dcb93a20-aaa8-db6a-d0e4-2d9513a0678a",
                    columnReferenceName: "groupmatics_dbo_order_outingid",
                    name: "OutingID",
                    displayName: null,
                    visualNameShort: "Outing ID",
                    visualNameFull: "Outing ID (New View 2324 Price With Tax)",
                    dataType: "IDUUID",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ff94224c-449c-6421-f11c-da856cff503d",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 2324 (price with tax)",
                    modelColumnID: "dcb93a20-aaa8-db6a-d0e4-2d9513a0678a",
                },
                {
                    id: "3c7f0f9c-1ac7-fe98-9792-11223a876864",
                    columnReferenceName: "verbcol_price_with_tax",
                    name: "Price with tax",
                    displayName: null,
                    visualNameShort: "Price With Tax",
                    visualNameFull: "Price With Tax (New View 2324 Price With Tax)",
                    dataType: "Double",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ff94224c-449c-6421-f11c-da856cff503d",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 2324 (price with tax)",
                    modelColumnID: "3c7f0f9c-1ac7-fe98-9792-11223a876864",
                },
                {
                    id: "62e1860c-6fd7-d913-f042-fbd9dd08d6e6",
                    columnReferenceName: "groupmatics_dbo_order_providerfees",
                    name: "ProviderFees",
                    displayName: null,
                    visualNameShort: "Provider Fees",
                    visualNameFull: "Provider Fees (New View 2324 Price With Tax)",
                    dataType: "Double",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ff94224c-449c-6421-f11c-da856cff503d",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 2324 (price with tax)",
                    modelColumnID: "62e1860c-6fd7-d913-f042-fbd9dd08d6e6",
                },
                {
                    id: "c132699d-310a-da53-bcf3-98889b96b92d",
                    columnReferenceName: "groupmatics_dbo_order_referrer",
                    name: "Referrer",
                    displayName: null,
                    visualNameShort: "Referrer",
                    visualNameFull: "Referrer (New View 2324 Price With Tax)",
                    dataType: "Int",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ff94224c-449c-6421-f11c-da856cff503d",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 2324 (price with tax)",
                    modelColumnID: "c132699d-310a-da53-bcf3-98889b96b92d",
                },
                {
                    id: "504a9ec8-3d18-149a-fbb5-7e3e12113e66",
                    columnReferenceName: "groupmatics_dbo_order_ticketdeliveryid",
                    name: "TicketDeliveryID",
                    displayName: null,
                    visualNameShort: "Ticket Delivery ID",
                    visualNameFull: "Ticket Delivery ID (New View 2324 Price With Tax)",
                    dataType: "IDUUID",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ff94224c-449c-6421-f11c-da856cff503d",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 2324 (price with tax)",
                    modelColumnID: "504a9ec8-3d18-149a-fbb5-7e3e12113e66",
                },
                {
                    id: "be66f43e-14d4-410d-099f-023b4407d23e",
                    columnReferenceName: "groupmatics_dbo_order_totalprice",
                    name: "TotalPrice",
                    displayName: null,
                    visualNameShort: "Total Price",
                    visualNameFull: "Total Price (New View 2324 Price With Tax)",
                    dataType: "Double",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ff94224c-449c-6421-f11c-da856cff503d",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 2324 (price with tax)",
                    modelColumnID: "be66f43e-14d4-410d-099f-023b4407d23e",
                },
                {
                    id: "c0795481-9da8-2ec0-eff1-7e6f79d85046",
                    columnReferenceName: "groupmatics_dbo_order_visitortoken",
                    name: "VisitorToken",
                    displayName: null,
                    visualNameShort: "Visitor Token",
                    visualNameFull: "Visitor Token (New View 2324 Price With Tax)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ff94224c-449c-6421-f11c-da856cff503d",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 2324 (price with tax)",
                    modelColumnID: "c0795481-9da8-2ec0-eff1-7e6f79d85046",
                },
            ],
            rowLevelSecurity: {},
            position: {
                x: -891,
                y: 582.5,
            },
            $type: "ViewModelTable",
        },
        {
            id: "445cc0a1-db5d-438e-06b9-d1879abc9cf7",
            transformationID: "906a3226-b30b-4a1b-9658-effcea297ace",
            tableReferenceName: "verbview_906a3226_b30b_4a1b_9658_effcea297ace",
            versionDetails: "LatestPublished",
            publishedOn: "2021-09-01T10:35:37.823Z",
            excludeFromModel: false,
            name: "New view 26082021",
            visualName: "New View 26082021",
            columns: [],
            rowLevelSecurity: {},
            position: {
                x: -891,
                y: 732.5,
            },
            $type: "ViewModelTable",
        },
        {
            id: "90a75b4a-d889-ef95-9326-f1496c35b41f",
            transformationID: "1c629c3e-d066-4a13-9284-372d859f899b",
            tableReferenceName: "verbview_1c629c3e_d066_4a13_9284_372d859f899b_v_0",
            versionDetails: "NotPublished",
            publishedOn: null,
            excludeFromModel: false,
            name: "New View 2987",
            visualName: "New View 2987",
            columns: [
                {
                    id: "0577668c-774c-b6aa-6938-349de934144a",
                    columnReferenceName: "groupmatics_dbo_order_accountid",
                    name: "AccountID",
                    displayName: null,
                    visualNameShort: "Account ID",
                    visualNameFull: "Account ID (New View 2987)",
                    dataType: "IDUUID",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "90a75b4a-d889-ef95-9326-f1496c35b41f",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 2987",
                    modelColumnID: "0577668c-774c-b6aa-6938-349de934144a",
                },
                {
                    id: "f0ca165c-594e-2781-ebf7-d28c2f35bcd3",
                    columnReferenceName: "groupmatics_dbo_order_customerip",
                    name: "CustomerIP",
                    displayName: null,
                    visualNameShort: "Customer IP",
                    visualNameFull: "Customer IP (New View 2987)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "90a75b4a-d889-ef95-9326-f1496c35b41f",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 2987",
                    modelColumnID: "f0ca165c-594e-2781-ebf7-d28c2f35bcd3",
                },
                {
                    id: "6d692653-7cac-a1cf-4be7-1758fbcf538b",
                    columnReferenceName: "groupmatics_dbo_order_dateplacedutc",
                    name: "DatePlacedUtc",
                    displayName: null,
                    visualNameShort: "Date Placed Utc",
                    visualNameFull: "Date Placed Utc (New View 2987)",
                    dataType: "DateTime",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "90a75b4a-d889-ef95-9326-f1496c35b41f",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 2987",
                    modelColumnID: "6d692653-7cac-a1cf-4be7-1758fbcf538b",
                },
                {
                    id: "2eb0e520-36fe-6340-08e6-75bb1789ec79",
                    columnReferenceName: "groupmatics_dbo_order_deliverymethod",
                    name: "DeliveryMethod",
                    displayName: null,
                    visualNameShort: "Delivery Method",
                    visualNameFull: "Delivery Method (New View 2987)",
                    dataType: "Int",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "90a75b4a-d889-ef95-9326-f1496c35b41f",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 2987",
                    modelColumnID: "2eb0e520-36fe-6340-08e6-75bb1789ec79",
                },
                {
                    id: "6ed436eb-8f4b-9f46-c62b-36db5deaac3b",
                    columnReferenceName: "groupmatics_dbo_order_donation",
                    name: "Donation",
                    displayName: null,
                    visualNameShort: "Donation",
                    visualNameFull: "Donation (New View 2987)",
                    dataType: "Double",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "90a75b4a-d889-ef95-9326-f1496c35b41f",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 2987",
                    modelColumnID: "6ed436eb-8f4b-9f46-c62b-36db5deaac3b",
                },
                {
                    id: "d1d8c771-e483-035e-62d7-71b7151a81e5",
                    columnReferenceName: "groupmatics_dbo_order_emailinvitationid",
                    name: "EmailInvitationID",
                    displayName: null,
                    visualNameShort: "Email Invitation ID",
                    visualNameFull: "Email Invitation ID (New View 2987)",
                    dataType: "IDUUID",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "90a75b4a-d889-ef95-9326-f1496c35b41f",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 2987",
                    modelColumnID: "d1d8c771-e483-035e-62d7-71b7151a81e5",
                },
                {
                    id: "05da4692-0fc4-f946-0b74-8218f7337148",
                    columnReferenceName: "groupmatics_dbo_order_eventservicecustomerid",
                    name: "EventServiceCustomerID",
                    displayName: null,
                    visualNameShort: "Event Service Customer ID",
                    visualNameFull: "Event Service Customer ID (New View 2987)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "90a75b4a-d889-ef95-9326-f1496c35b41f",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 2987",
                    modelColumnID: "05da4692-0fc4-f946-0b74-8218f7337148",
                },
                {
                    id: "9016e182-5dec-6b3e-21cb-47028cc67be5",
                    columnReferenceName: "groupmatics_dbo_order_eventservicehaspdftickets",
                    name: "EventServiceHasPdfTickets",
                    displayName: null,
                    visualNameShort: "Event Service Has Pdf Tickets",
                    visualNameFull: "Event Service Has Pdf Tickets (New View 2987)",
                    dataType: "Bool",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "90a75b4a-d889-ef95-9326-f1496c35b41f",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 2987",
                    modelColumnID: "9016e182-5dec-6b3e-21cb-47028cc67be5",
                },
                {
                    id: "19a4c022-e122-7601-ed46-2e5fe8a6eb81",
                    columnReferenceName: "groupmatics_dbo_order_eventservicehidegmtickets",
                    name: "EventServiceHideGMTickets",
                    displayName: null,
                    visualNameShort: "Event Service Hide GM Tickets",
                    visualNameFull: "Event Service Hide GM Tickets (New View 2987)",
                    dataType: "Bool",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "90a75b4a-d889-ef95-9326-f1496c35b41f",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 2987",
                    modelColumnID: "19a4c022-e122-7601-ed46-2e5fe8a6eb81",
                },
                {
                    id: "6fa659fe-4e75-f061-d632-ad965da3e46d",
                    columnReferenceName: "groupmatics_dbo_order_eventservicenewcustomer",
                    name: "EventServiceNewCustomer",
                    displayName: null,
                    visualNameShort: "Event Service New Customer",
                    visualNameFull: "Event Service New Customer (New View 2987)",
                    dataType: "Bool",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "90a75b4a-d889-ef95-9326-f1496c35b41f",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 2987",
                    modelColumnID: "6fa659fe-4e75-f061-d632-ad965da3e46d",
                },
                {
                    id: "f80bd42f-5fa3-fe85-fb70-a10d9672e4e3",
                    columnReferenceName: "groupmatics_dbo_order_eventserviceorderid",
                    name: "EventServiceOrderID",
                    displayName: null,
                    visualNameShort: "Event Service Order ID",
                    visualNameFull: "Event Service Order ID (New View 2987)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "90a75b4a-d889-ef95-9326-f1496c35b41f",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 2987",
                    modelColumnID: "f80bd42f-5fa3-fe85-fb70-a10d9672e4e3",
                },
                {
                    id: "d81c073f-eaef-4dc6-6454-4be6aff3b233",
                    columnReferenceName: "groupmatics_dbo_order_eventservicetemporarypassword",
                    name: "EventServiceTemporaryPassword",
                    displayName: null,
                    visualNameShort: "Event Service Temporary Password",
                    visualNameFull: "Event Service Temporary Password (New View 2987)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "90a75b4a-d889-ef95-9326-f1496c35b41f",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 2987",
                    modelColumnID: "d81c073f-eaef-4dc6-6454-4be6aff3b233",
                },
                {
                    id: "d61402c1-fab5-7844-7738-03672a1b8a17",
                    columnReferenceName: "groupmatics_dbo_order_eventserviceticketprovider",
                    name: "EventServiceTicketProvider",
                    displayName: null,
                    visualNameShort: "Event Service Ticket Provider",
                    visualNameFull: "Event Service Ticket Provider (New View 2987)",
                    dataType: "Int",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "90a75b4a-d889-ef95-9326-f1496c35b41f",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 2987",
                    modelColumnID: "d61402c1-fab5-7844-7738-03672a1b8a17",
                },
                {
                    id: "aad7964b-78f7-4ad4-ac66-883c81b5b7c7",
                    columnReferenceName: "groupmatics_dbo_order_expgroup",
                    name: "ExpGroup",
                    displayName: null,
                    visualNameShort: "Exp Group",
                    visualNameFull: "Exp Group (New View 2987)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "90a75b4a-d889-ef95-9326-f1496c35b41f",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 2987",
                    modelColumnID: "aad7964b-78f7-4ad4-ac66-883c81b5b7c7",
                },
                {
                    id: "ef917514-eb79-b0b7-e3da-cac6ed25f8af",
                    columnReferenceName: "groupmatics_dbo_order_expirationdateutc",
                    name: "ExpirationDateUtc",
                    displayName: null,
                    visualNameShort: "Expiration Date Utc",
                    visualNameFull: "Expiration Date Utc (New View 2987)",
                    dataType: "DateTime",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "90a75b4a-d889-ef95-9326-f1496c35b41f",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 2987",
                    modelColumnID: "ef917514-eb79-b0b7-e3da-cac6ed25f8af",
                },
                {
                    id: "8cc5122e-54f0-74c4-0ff5-13832980b25f",
                    columnReferenceName: "groupmatics_dbo_order_feesatcheckout",
                    name: "FeesAtCheckout",
                    displayName: null,
                    visualNameShort: "Fees At Checkout",
                    visualNameFull: "Fees At Checkout (New View 2987)",
                    dataType: "Double",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "90a75b4a-d889-ef95-9326-f1496c35b41f",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 2987",
                    modelColumnID: "8cc5122e-54f0-74c4-0ff5-13832980b25f",
                },
                {
                    id: "8c63dbf2-a438-5d45-3e3c-432a77547e3f",
                    columnReferenceName: "groupmatics_dbo_order_feesatpayout",
                    name: "FeesAtPayout",
                    displayName: null,
                    visualNameShort: "Fees At Payout",
                    visualNameFull: "Fees At Payout (New View 2987)",
                    dataType: "Double",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "90a75b4a-d889-ef95-9326-f1496c35b41f",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 2987",
                    modelColumnID: "8c63dbf2-a438-5d45-3e3c-432a77547e3f",
                },
                {
                    id: "620291f7-a20f-44a0-27cd-53fd04459e70",
                    columnReferenceName: "groupmatics_dbo_order_flattaxes",
                    name: "FlatTaxes",
                    displayName: null,
                    visualNameShort: "Flat Taxes",
                    visualNameFull: "Flat Taxes (New View 2987)",
                    dataType: "Double",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "90a75b4a-d889-ef95-9326-f1496c35b41f",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 2987",
                    modelColumnID: "620291f7-a20f-44a0-27cd-53fd04459e70",
                },
                {
                    id: "b725f8c2-baa9-27e7-43c9-f99531394da6",
                    columnReferenceName: "groupmatics_dbo_order_orderid",
                    name: "OrderID",
                    displayName: null,
                    visualNameShort: "Order ID",
                    visualNameFull: "Order ID (New View 2987)",
                    dataType: "IDUUID",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "90a75b4a-d889-ef95-9326-f1496c35b41f",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 2987",
                    modelColumnID: "b725f8c2-baa9-27e7-43c9-f99531394da6",
                },
                {
                    id: "ead7088a-30cb-be55-0703-e9aa5d64c376",
                    columnReferenceName: "groupmatics_dbo_order_ordernumber",
                    name: "OrderNumber",
                    displayName: null,
                    visualNameShort: "Order Number",
                    visualNameFull: "Order Number (New View 2987)",
                    dataType: "Int",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "90a75b4a-d889-ef95-9326-f1496c35b41f",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 2987",
                    modelColumnID: "ead7088a-30cb-be55-0703-e9aa5d64c376",
                },
                {
                    id: "dbd996ea-13e1-a3c1-38a1-e81c4d1e556a",
                    columnReferenceName: "groupmatics_dbo_order_outingid",
                    name: "OutingID",
                    displayName: null,
                    visualNameShort: "Outing ID",
                    visualNameFull: "Outing ID (New View 2987)",
                    dataType: "IDUUID",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "90a75b4a-d889-ef95-9326-f1496c35b41f",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 2987",
                    modelColumnID: "dbd996ea-13e1-a3c1-38a1-e81c4d1e556a",
                },
                {
                    id: "a5fb0973-ca00-6da1-74f3-941ae840e2e0",
                    columnReferenceName: "groupmatics_dbo_order_providerfees",
                    name: "ProviderFees",
                    displayName: null,
                    visualNameShort: "Provider Fees",
                    visualNameFull: "Provider Fees (New View 2987)",
                    dataType: "Double",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "90a75b4a-d889-ef95-9326-f1496c35b41f",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 2987",
                    modelColumnID: "a5fb0973-ca00-6da1-74f3-941ae840e2e0",
                },
                {
                    id: "6acb0e9b-4503-5381-eeb5-85a62dd0ec6f",
                    columnReferenceName: "groupmatics_dbo_order_referrer",
                    name: "Referrer",
                    displayName: null,
                    visualNameShort: "Referrer",
                    visualNameFull: "Referrer (New View 2987)",
                    dataType: "Int",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "90a75b4a-d889-ef95-9326-f1496c35b41f",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 2987",
                    modelColumnID: "6acb0e9b-4503-5381-eeb5-85a62dd0ec6f",
                },
                {
                    id: "ef75b7ef-c06c-b458-1b71-d68b040656f6",
                    columnReferenceName: "groupmatics_dbo_order_ticketdeliveryid",
                    name: "TicketDeliveryID",
                    displayName: null,
                    visualNameShort: "Ticket Delivery ID",
                    visualNameFull: "Ticket Delivery ID (New View 2987)",
                    dataType: "IDUUID",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "90a75b4a-d889-ef95-9326-f1496c35b41f",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 2987",
                    modelColumnID: "ef75b7ef-c06c-b458-1b71-d68b040656f6",
                },
                {
                    id: "66c04f11-032a-7d02-7488-8bf7307107da",
                    columnReferenceName: "groupmatics_dbo_order_totalprice",
                    name: "TotalPrice",
                    displayName: null,
                    visualNameShort: "Total Price",
                    visualNameFull: "Total Price (New View 2987)",
                    dataType: "Double",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "90a75b4a-d889-ef95-9326-f1496c35b41f",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 2987",
                    modelColumnID: "66c04f11-032a-7d02-7488-8bf7307107da",
                },
                {
                    id: "6557792f-befb-b9df-e0e3-634056126e7c",
                    columnReferenceName: "groupmatics_dbo_order_visitortoken",
                    name: "VisitorToken",
                    displayName: null,
                    visualNameShort: "Visitor Token",
                    visualNameFull: "Visitor Token (New View 2987)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "90a75b4a-d889-ef95-9326-f1496c35b41f",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 2987",
                    modelColumnID: "6557792f-befb-b9df-e0e3-634056126e7c",
                },
            ],
            rowLevelSecurity: {},
            position: {
                x: -1041,
                y: 432.5,
            },
            $type: "ViewModelTable",
        },
        {
            id: "8b2ca1f9-b732-4fa5-8fcc-5b7450111bb8",
            transformationID: "9b0040fe-905f-4706-bc38-11af886372c3",
            tableReferenceName: "verbview_9b0040fe_905f_4706_bc38_11af886372c3_v_0",
            versionDetails: "NotPublished",
            publishedOn: null,
            excludeFromModel: false,
            name: "New View 3186",
            visualName: "New View 3186",
            columns: [
                {
                    id: "713958ed-9136-1341-3dc6-a81434fa6f23",
                    columnReferenceName: "groupmatics_dbo_account_accountid",
                    name: "AccountID",
                    displayName: null,
                    visualNameShort: "Account ID",
                    visualNameFull: "Account ID (New View 3186)",
                    dataType: "IDUUID",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8b2ca1f9-b732-4fa5-8fcc-5b7450111bb8",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 3186",
                    modelColumnID: "713958ed-9136-1341-3dc6-a81434fa6f23",
                },
                {
                    id: "c76fb3ae-e21d-3f1b-6ba4-184c10a435db",
                    columnReferenceName: "groupmatics_dbo_account_adminaccess",
                    name: "AdminAccess",
                    displayName: null,
                    visualNameShort: "Admin Access",
                    visualNameFull: "Admin Access (New View 3186)",
                    dataType: "Int",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8b2ca1f9-b732-4fa5-8fcc-5b7450111bb8",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 3186",
                    modelColumnID: "c76fb3ae-e21d-3f1b-6ba4-184c10a435db",
                },
                {
                    id: "b04319f1-fe87-68c3-2fdb-62860f27ab54",
                    columnReferenceName: "groupmatics_dbo_account_datecreated",
                    name: "DateCreated",
                    displayName: null,
                    visualNameShort: "Date Created",
                    visualNameFull: "Date Created (New View 3186)",
                    dataType: "DateTime",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8b2ca1f9-b732-4fa5-8fcc-5b7450111bb8",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 3186",
                    modelColumnID: "b04319f1-fe87-68c3-2fdb-62860f27ab54",
                },
                {
                    id: "41f1ed89-de49-0fe4-52ef-2fc9b61ec5f4",
                    columnReferenceName: "groupmatics_dbo_account_dateofbirth",
                    name: "DateOfBirth",
                    displayName: null,
                    visualNameShort: "Date Of Birth",
                    visualNameFull: "Date Of Birth (New View 3186)",
                    dataType: "DateTime",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8b2ca1f9-b732-4fa5-8fcc-5b7450111bb8",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 3186",
                    modelColumnID: "41f1ed89-de49-0fe4-52ef-2fc9b61ec5f4",
                },
                {
                    id: "2f2fdfd9-b76d-d7dc-0c0b-ea2e5f2ee6a7",
                    columnReferenceName: "groupmatics_dbo_account_email",
                    name: "Email",
                    displayName: null,
                    visualNameShort: "Email",
                    visualNameFull: "Email (New View 3186)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8b2ca1f9-b732-4fa5-8fcc-5b7450111bb8",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 3186",
                    modelColumnID: "2f2fdfd9-b76d-d7dc-0c0b-ea2e5f2ee6a7",
                },
                {
                    id: "04dc577d-ad91-99f1-192e-caf5abcf392d",
                    columnReferenceName: "groupmatics_dbo_account_firstname",
                    name: "FirstName",
                    displayName: null,
                    visualNameShort: "First Name",
                    visualNameFull: "First Name (New View 3186)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8b2ca1f9-b732-4fa5-8fcc-5b7450111bb8",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 3186",
                    modelColumnID: "04dc577d-ad91-99f1-192e-caf5abcf392d",
                },
                {
                    id: "979c77a7-b5ee-0f22-bdab-1319d0d96fc1",
                    columnReferenceName: "groupmatics_dbo_account_gender",
                    name: "Gender",
                    displayName: null,
                    visualNameShort: "Gender",
                    visualNameFull: "Gender (New View 3186)",
                    dataType: "Int",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8b2ca1f9-b732-4fa5-8fcc-5b7450111bb8",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 3186",
                    modelColumnID: "979c77a7-b5ee-0f22-bdab-1319d0d96fc1",
                },
                {
                    id: "63eff847-4a89-f493-af8e-9892b7922225",
                    columnReferenceName: "groupmatics_dbo_account_groupage",
                    name: "GroupAge",
                    displayName: null,
                    visualNameShort: "Group Age",
                    visualNameFull: "Group Age (New View 3186)",
                    dataType: "Int",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8b2ca1f9-b732-4fa5-8fcc-5b7450111bb8",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 3186",
                    modelColumnID: "63eff847-4a89-f493-af8e-9892b7922225",
                },
                {
                    id: "9cdd1798-6538-b668-362d-37b365c347c5",
                    columnReferenceName: "groupmatics_dbo_account_hashedpassword",
                    name: "HashedPassword",
                    displayName: null,
                    visualNameShort: "Hashed Password",
                    visualNameFull: "Hashed Password (New View 3186)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8b2ca1f9-b732-4fa5-8fcc-5b7450111bb8",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 3186",
                    modelColumnID: "9cdd1798-6538-b668-362d-37b365c347c5",
                },
                {
                    id: "913d7745-e651-3122-bf4b-85591d160126",
                    columnReferenceName: "groupmatics_dbo_account_lastloggedinutc",
                    name: "LastLoggedInUtc",
                    displayName: null,
                    visualNameShort: "Last Logged In Utc",
                    visualNameFull: "Last Logged In Utc (New View 3186)",
                    dataType: "DateTime",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8b2ca1f9-b732-4fa5-8fcc-5b7450111bb8",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 3186",
                    modelColumnID: "913d7745-e651-3122-bf4b-85591d160126",
                },
                {
                    id: "12a9c266-0678-1f70-fc42-81ca6dea7afd",
                    columnReferenceName: "groupmatics_dbo_account_lastname",
                    name: "LastName",
                    displayName: null,
                    visualNameShort: "Last Name",
                    visualNameFull: "Last Name (New View 3186)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8b2ca1f9-b732-4fa5-8fcc-5b7450111bb8",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 3186",
                    modelColumnID: "12a9c266-0678-1f70-fc42-81ca6dea7afd",
                },
                {
                    id: "f5b1e486-adb2-9010-332f-15aa293143fa",
                    columnReferenceName: "groupmatics_dbo_account_optout_deadlinepassed",
                    name: "OptOut_DeadlinePassed",
                    displayName: null,
                    visualNameShort: "Opt Out Deadline Passed",
                    visualNameFull: "Opt Out Deadline Passed (New View 3186)",
                    dataType: "Bool",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8b2ca1f9-b732-4fa5-8fcc-5b7450111bb8",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 3186",
                    modelColumnID: "f5b1e486-adb2-9010-332f-15aa293143fa",
                },
                {
                    id: "42e9eac9-e29e-5b21-e375-f788de026bf9",
                    columnReferenceName: "groupmatics_dbo_account_optout_groupleadersummary",
                    name: "OptOut_GroupLeaderSummary",
                    displayName: null,
                    visualNameShort: "Opt Out Group Leader Summary",
                    visualNameFull: "Opt Out Group Leader Summary (New View 3186)",
                    dataType: "Bool",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8b2ca1f9-b732-4fa5-8fcc-5b7450111bb8",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 3186",
                    modelColumnID: "42e9eac9-e29e-5b21-e375-f788de026bf9",
                },
                {
                    id: "0b14b5fb-e214-0c87-bca9-581b8e7b207a",
                    columnReferenceName: "groupmatics_dbo_account_optout_invitationacceptance",
                    name: "OptOut_InvitationAcceptance",
                    displayName: null,
                    visualNameShort: "Opt Out Invitation Acceptance",
                    visualNameFull: "Opt Out Invitation Acceptance (New View 3186)",
                    dataType: "Bool",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8b2ca1f9-b732-4fa5-8fcc-5b7450111bb8",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 3186",
                    modelColumnID: "0b14b5fb-e214-0c87-bca9-581b8e7b207a",
                },
                {
                    id: "dd6e1ee6-4778-966a-6225-55a89c74719c",
                    columnReferenceName: "groupmatics_dbo_account_optout_lowticketwarning",
                    name: "OptOut_LowTicketWarning",
                    displayName: null,
                    visualNameShort: "Opt Out Low Ticket Warning",
                    visualNameFull: "Opt Out Low Ticket Warning (New View 3186)",
                    dataType: "Bool",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8b2ca1f9-b732-4fa5-8fcc-5b7450111bb8",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 3186",
                    modelColumnID: "dd6e1ee6-4778-966a-6225-55a89c74719c",
                },
                {
                    id: "00975984-be90-92c4-840a-c51b0b21c8bd",
                    columnReferenceName: "groupmatics_dbo_account_optout_orderadjustment",
                    name: "OptOut_OrderAdjustment",
                    displayName: null,
                    visualNameShort: "Opt Out Order Adjustment",
                    visualNameFull: "Opt Out Order Adjustment (New View 3186)",
                    dataType: "Bool",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8b2ca1f9-b732-4fa5-8fcc-5b7450111bb8",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 3186",
                    modelColumnID: "00975984-be90-92c4-840a-c51b0b21c8bd",
                },
                {
                    id: "76131281-b128-f24b-89cd-698b7720e1a3",
                    columnReferenceName: "groupmatics_dbo_account_optout_orderconfirmations",
                    name: "OptOut_OrderConfirmations",
                    displayName: null,
                    visualNameShort: "Opt Out Order Confirmations",
                    visualNameFull: "Opt Out Order Confirmations (New View 3186)",
                    dataType: "Bool",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8b2ca1f9-b732-4fa5-8fcc-5b7450111bb8",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 3186",
                    modelColumnID: "76131281-b128-f24b-89cd-698b7720e1a3",
                },
                {
                    id: "7fd48e9a-c607-ec47-208c-4715bb6b0567",
                    columnReferenceName: "groupmatics_dbo_account_optout_salesmanagersummary",
                    name: "OptOut_SalesManagerSummary",
                    displayName: null,
                    visualNameShort: "Opt Out Sales Manager Summary",
                    visualNameFull: "Opt Out Sales Manager Summary (New View 3186)",
                    dataType: "Bool",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8b2ca1f9-b732-4fa5-8fcc-5b7450111bb8",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 3186",
                    modelColumnID: "7fd48e9a-c607-ec47-208c-4715bb6b0567",
                },
                {
                    id: "cb579ac7-35d5-0a86-6953-392165fe475b",
                    columnReferenceName: "groupmatics_dbo_account_optout_ticketchecker",
                    name: "OptOut_TicketChecker",
                    displayName: null,
                    visualNameShort: "Opt Out Ticket Checker",
                    visualNameFull: "Opt Out Ticket Checker (New View 3186)",
                    dataType: "Bool",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8b2ca1f9-b732-4fa5-8fcc-5b7450111bb8",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 3186",
                    modelColumnID: "cb579ac7-35d5-0a86-6953-392165fe475b",
                },
                {
                    id: "e4ba00e5-690c-46d3-4c2a-396c355f716d",
                    columnReferenceName: "groupmatics_dbo_account_passwordresettoken",
                    name: "PasswordResetToken",
                    displayName: null,
                    visualNameShort: "Password Reset Token",
                    visualNameFull: "Password Reset Token (New View 3186)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8b2ca1f9-b732-4fa5-8fcc-5b7450111bb8",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 3186",
                    modelColumnID: "e4ba00e5-690c-46d3-4c2a-396c355f716d",
                },
                {
                    id: "420f8827-092b-b1a2-58c1-d5ec2c32576b",
                    columnReferenceName: "groupmatics_dbo_account_passwordresettokenexpirationdate",
                    name: "PasswordResetTokenExpirationDate",
                    displayName: null,
                    visualNameShort: "Password Reset Token Expiration Date",
                    visualNameFull: "Password Reset Token Expiration Date (New View 3186)",
                    dataType: "DateTime",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8b2ca1f9-b732-4fa5-8fcc-5b7450111bb8",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 3186",
                    modelColumnID: "420f8827-092b-b1a2-58c1-d5ec2c32576b",
                },
                {
                    id: "c35b7afa-c3e1-4cc5-2331-647bf3a11d3a",
                    columnReferenceName: "groupmatics_dbo_account_phone",
                    name: "Phone",
                    displayName: null,
                    visualNameShort: "Phone",
                    visualNameFull: "Phone (New View 3186)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8b2ca1f9-b732-4fa5-8fcc-5b7450111bb8",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 3186",
                    modelColumnID: "c35b7afa-c3e1-4cc5-2331-647bf3a11d3a",
                },
                {
                    id: "149aed4b-34c7-7e21-ecba-c8d5ca676baa",
                    columnReferenceName: "groupmatics_dbo_account_randomcode",
                    name: "RandomCode",
                    displayName: null,
                    visualNameShort: "Random Code",
                    visualNameFull: "Random Code (New View 3186)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8b2ca1f9-b732-4fa5-8fcc-5b7450111bb8",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 3186",
                    modelColumnID: "149aed4b-34c7-7e21-ecba-c8d5ca676baa",
                },
                {
                    id: "495ee67f-5410-0a0c-2ebc-da5d0a5ac097",
                    columnReferenceName: "groupmatics_dbo_account_randomcodeexpirationdate",
                    name: "RandomCodeExpirationDate",
                    displayName: null,
                    visualNameShort: "Random Code Expiration Date",
                    visualNameFull: "Random Code Expiration Date (New View 3186)",
                    dataType: "DateTime",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8b2ca1f9-b732-4fa5-8fcc-5b7450111bb8",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 3186",
                    modelColumnID: "495ee67f-5410-0a0c-2ebc-da5d0a5ac097",
                },
                {
                    id: "8dddd45e-3c91-1d1f-e326-eb019aca2767",
                    columnReferenceName: "groupmatics_dbo_account_socialavatar",
                    name: "SocialAvatar",
                    displayName: null,
                    visualNameShort: "Social Avatar",
                    visualNameFull: "Social Avatar (New View 3186)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8b2ca1f9-b732-4fa5-8fcc-5b7450111bb8",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 3186",
                    modelColumnID: "8dddd45e-3c91-1d1f-e326-eb019aca2767",
                },
                {
                    id: "177172a0-65f8-1fa4-519e-fbe2e60ad5e9",
                    columnReferenceName: "groupmatics_dbo_account_status",
                    name: "Status",
                    displayName: null,
                    visualNameShort: "Status",
                    visualNameFull: "Status (New View 3186)",
                    dataType: "Int",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8b2ca1f9-b732-4fa5-8fcc-5b7450111bb8",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 3186",
                    modelColumnID: "177172a0-65f8-1fa4-519e-fbe2e60ad5e9",
                },
                {
                    id: "525ca4ed-ed6d-67ad-a647-447a596c2c73",
                    columnReferenceName: "groupmatics_dbo_account_username",
                    name: "Username",
                    displayName: null,
                    visualNameShort: "Username",
                    visualNameFull: "Username (New View 3186)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8b2ca1f9-b732-4fa5-8fcc-5b7450111bb8",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 3186",
                    modelColumnID: "525ca4ed-ed6d-67ad-a647-447a596c2c73",
                },
            ],
            rowLevelSecurity: {},
            position: {
                x: -1041,
                y: 582.5,
            },
            $type: "ViewModelTable",
        },
        {
            id: "f1ac50b1-d794-4073-b2ab-3f4adac32a3a",
            transformationID: "6996e6f5-4ff6-4ecd-a13d-f2f17a4cd3e3",
            tableReferenceName: "verbview_6996e6f5_4ff6_4ecd_a13d_f2f17a4cd3e3",
            versionDetails: "NotPublished",
            publishedOn: null,
            excludeFromModel: false,
            name: "New View 3652",
            visualName: "New View 3652",
            columns: [],
            rowLevelSecurity: {},
            position: {
                x: -1041,
                y: 732.5,
            },
            $type: "ViewModelTable",
        },
        {
            id: "48604d0d-b429-1a9b-dc9f-0fac4226aaa7",
            transformationID: "969c221c-c093-4962-b7ac-4aed5ee8f351",
            tableReferenceName: "verbview_969c221c_c093_4962_b7ac_4aed5ee8f351_v_0",
            versionDetails: "NotPublished",
            publishedOn: null,
            excludeFromModel: false,
            name: "New View 3656",
            visualName: "New View 3656",
            columns: [],
            rowLevelSecurity: {},
            position: {
                x: -1191,
                y: 432.5,
            },
            $type: "ViewModelTable",
        },
        {
            id: "9e79d8a3-f979-4124-a7e2-3665142e3e6b",
            transformationID: "3a70df51-9f32-4f37-944c-f74535520992",
            tableReferenceName: "verbview_3a70df51_9f32_4f37_944c_f74535520992_v_201110231651820",
            versionDetails: "NotPublished",
            publishedOn: null,
            excludeFromModel: false,
            name: "New View 4",
            visualName: "New View 4",
            columns: [],
            rowLevelSecurity: {},
            position: {
                x: -1191,
                y: 582.5,
            },
            $type: "ViewModelTable",
        },
        {
            id: "9caa45f9-5edb-cd33-51c6-56fb75d7e7bc",
            transformationID: "ccbf5b00-a86d-43c2-a7fd-07b817177423",
            tableReferenceName: "verbview_ccbf5b00_a86d_43c2_a7fd_07b817177423",
            versionDetails: "NotPublished",
            publishedOn: null,
            excludeFromModel: true,
            name: "New View 4077",
            visualName: "New View 4077",
            columns: [],
            rowLevelSecurity: {},
            position: {
                x: -1191,
                y: 732.5,
            },
            $type: "ViewModelTable",
        },
        {
            id: "593fe987-93b4-dbae-09f0-297da5377d9c",
            transformationID: "4c3d82e7-4754-44de-9f36-066d2f88034d",
            tableReferenceName: "verbview_4c3d82e7_4754_44de_9f36_066d2f88034d",
            versionDetails: "NotPublished",
            publishedOn: null,
            excludeFromModel: false,
            name: "New View 4087",
            visualName: "New View 4087",
            columns: [],
            rowLevelSecurity: {},
            position: {
                x: -1341,
                y: 432.5,
            },
            $type: "ViewModelTable",
        },
        {
            id: "da997ee0-581f-c6d9-7178-f37b59929440",
            transformationID: "eaf6f0e2-2328-4f98-b581-680a56411fae",
            tableReferenceName: "verbview_eaf6f0e2_2328_4f98_b581_680a56411fae",
            versionDetails: "NotPublished",
            publishedOn: null,
            excludeFromModel: false,
            name: "New View 4166",
            visualName: "New View 4166",
            columns: [],
            rowLevelSecurity: {},
            position: {
                x: -1341,
                y: 582.5,
            },
            $type: "ViewModelTable",
        },
        {
            id: "5018a7c3-9de7-41d4-85bd-7b55150b2f22",
            transformationID: "9455e8d9-12d8-44ac-a1e4-64a23fdb36d6",
            tableReferenceName: "verbview_9455e8d9_12d8_44ac_a1e4_64a23fdb36d6_v_0",
            versionDetails: "NotPublished",
            publishedOn: null,
            excludeFromModel: false,
            name: "New View 4306",
            visualName: "New View 4306",
            columns: [
                {
                    id: "1d48fa31-00f3-43da-9a1b-907f9ad83e55",
                    columnReferenceName: "groupmatics_dbo_account_accountid",
                    name: "AccountID",
                    displayName: null,
                    visualNameShort: "Account ID",
                    visualNameFull: "Account ID (New View 4306)",
                    dataType: "IDUUID",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "5018a7c3-9de7-41d4-85bd-7b55150b2f22",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 4306",
                    modelColumnID: "1d48fa31-00f3-43da-9a1b-907f9ad83e55",
                },
                {
                    id: "cee47f42-598f-058a-47fb-44ab9193de42",
                    columnReferenceName: "groupmatics_dbo_account_accountid",
                    name: "AccountID",
                    displayName: null,
                    visualNameShort: "Account ID",
                    visualNameFull: "Account ID (New View 4306)",
                    dataType: "IDUUID",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "5018a7c3-9de7-41d4-85bd-7b55150b2f22",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 4306",
                    modelColumnID: "cee47f42-598f-058a-47fb-44ab9193de42",
                },
                {
                    id: "2a841bd5-067c-4e7c-8ab9-e0a915d11586",
                    columnReferenceName: "groupmatics_dbo_account_adminaccess",
                    name: "AdminAccess",
                    displayName: null,
                    visualNameShort: "Admin Access",
                    visualNameFull: "Admin Access (New View 4306)",
                    dataType: "Int",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "5018a7c3-9de7-41d4-85bd-7b55150b2f22",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 4306",
                    modelColumnID: "2a841bd5-067c-4e7c-8ab9-e0a915d11586",
                },
                {
                    id: "61f2e659-0e76-ff27-f632-3d32e78d63c0",
                    columnReferenceName: "groupmatics_dbo_account_adminaccess",
                    name: "AdminAccess",
                    displayName: null,
                    visualNameShort: "Admin Access",
                    visualNameFull: "Admin Access (New View 4306)",
                    dataType: "Int",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "5018a7c3-9de7-41d4-85bd-7b55150b2f22",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 4306",
                    modelColumnID: "61f2e659-0e76-ff27-f632-3d32e78d63c0",
                },
                {
                    id: "3f0a0f7d-0dfd-422e-98c0-aa6fd20d6ec1",
                    columnReferenceName: "groupmatics_dbo_account_datecreated",
                    name: "DateCreated",
                    displayName: null,
                    visualNameShort: "Date Created",
                    visualNameFull: "Date Created (New View 4306)",
                    dataType: "DateTime",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "5018a7c3-9de7-41d4-85bd-7b55150b2f22",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 4306",
                    modelColumnID: "3f0a0f7d-0dfd-422e-98c0-aa6fd20d6ec1",
                },
                {
                    id: "20293557-bf17-0f6b-96ce-e8eff38bd14b",
                    columnReferenceName: "groupmatics_dbo_account_datecreated",
                    name: "DateCreated",
                    displayName: null,
                    visualNameShort: "Date Created",
                    visualNameFull: "Date Created (New View 4306)",
                    dataType: "DateTime",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "5018a7c3-9de7-41d4-85bd-7b55150b2f22",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 4306",
                    modelColumnID: "20293557-bf17-0f6b-96ce-e8eff38bd14b",
                },
                {
                    id: "c9ae269a-ad21-4187-837f-890557c785dc",
                    columnReferenceName: "groupmatics_dbo_account_dateofbirth",
                    name: "DateOfBirth",
                    displayName: null,
                    visualNameShort: "Date Of Birth",
                    visualNameFull: "Date Of Birth (New View 4306)",
                    dataType: "DateTime",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "5018a7c3-9de7-41d4-85bd-7b55150b2f22",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 4306",
                    modelColumnID: "c9ae269a-ad21-4187-837f-890557c785dc",
                },
                {
                    id: "3462d1d7-48e5-a684-3aec-b14a5751dde5",
                    columnReferenceName: "groupmatics_dbo_account_dateofbirth",
                    name: "DateOfBirth",
                    displayName: null,
                    visualNameShort: "Date Of Birth",
                    visualNameFull: "Date Of Birth (New View 4306)",
                    dataType: "DateTime",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "5018a7c3-9de7-41d4-85bd-7b55150b2f22",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 4306",
                    modelColumnID: "3462d1d7-48e5-a684-3aec-b14a5751dde5",
                },
                {
                    id: "3d5936b7-154f-2f51-a557-b0381c1ad249",
                    columnReferenceName: "groupmatics_dbo_account_email",
                    name: "Email",
                    displayName: null,
                    visualNameShort: "Email",
                    visualNameFull: "Email (New View 4306)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "5018a7c3-9de7-41d4-85bd-7b55150b2f22",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 4306",
                    modelColumnID: "3d5936b7-154f-2f51-a557-b0381c1ad249",
                },
                {
                    id: "ac060b0d-83e7-06c9-b82b-f676a5c5da5e",
                    columnReferenceName: "groupmatics_dbo_account_firstname",
                    name: "FirstName",
                    displayName: null,
                    visualNameShort: "First Name",
                    visualNameFull: "First Name (New View 4306)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "5018a7c3-9de7-41d4-85bd-7b55150b2f22",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 4306",
                    modelColumnID: "ac060b0d-83e7-06c9-b82b-f676a5c5da5e",
                },
                {
                    id: "4547b888-1966-47f7-bdb8-59ac2c32e3ed",
                    columnReferenceName: "groupmatics_dbo_account_gender",
                    name: "Gender",
                    displayName: null,
                    visualNameShort: "Gender",
                    visualNameFull: "Gender (New View 4306)",
                    dataType: "Int",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "5018a7c3-9de7-41d4-85bd-7b55150b2f22",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 4306",
                    modelColumnID: "4547b888-1966-47f7-bdb8-59ac2c32e3ed",
                },
                {
                    id: "8f6547c9-fa7a-f35d-ef06-a7a05b10d9a1",
                    columnReferenceName: "groupmatics_dbo_account_gender",
                    name: "Gender",
                    displayName: null,
                    visualNameShort: "Gender",
                    visualNameFull: "Gender (New View 4306)",
                    dataType: "Int",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "5018a7c3-9de7-41d4-85bd-7b55150b2f22",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 4306",
                    modelColumnID: "8f6547c9-fa7a-f35d-ef06-a7a05b10d9a1",
                },
                {
                    id: "4e9db204-a97d-980a-f91e-47724c6123aa",
                    columnReferenceName: "groupmatics_dbo_account_groupage",
                    name: "GroupAge",
                    displayName: null,
                    visualNameShort: "Group Age",
                    visualNameFull: "Group Age (New View 4306)",
                    dataType: "Int",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "5018a7c3-9de7-41d4-85bd-7b55150b2f22",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 4306",
                    modelColumnID: "4e9db204-a97d-980a-f91e-47724c6123aa",
                },
                {
                    id: "32860ed2-fb1c-0fd9-754e-e3623804e58a",
                    columnReferenceName: "groupmatics_dbo_account_hashedpassword",
                    name: "HashedPassword",
                    displayName: null,
                    visualNameShort: "Hashed Password",
                    visualNameFull: "Hashed Password (New View 4306)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "5018a7c3-9de7-41d4-85bd-7b55150b2f22",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 4306",
                    modelColumnID: "32860ed2-fb1c-0fd9-754e-e3623804e58a",
                },
                {
                    id: "607f5d06-81b4-4977-a7e8-66b91b81eb82",
                    columnReferenceName: "groupmatics_dbo_account_lastloggedinutc",
                    name: "LastLoggedInUtc",
                    displayName: null,
                    visualNameShort: "Last Logged In Utc",
                    visualNameFull: "Last Logged In Utc (New View 4306)",
                    dataType: "DateTime",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "5018a7c3-9de7-41d4-85bd-7b55150b2f22",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 4306",
                    modelColumnID: "607f5d06-81b4-4977-a7e8-66b91b81eb82",
                },
                {
                    id: "1b794428-91dc-b3af-2b55-a36ec8630444",
                    columnReferenceName: "groupmatics_dbo_account_lastloggedinutc",
                    name: "LastLoggedInUtc",
                    displayName: null,
                    visualNameShort: "Last Logged In Utc",
                    visualNameFull: "Last Logged In Utc (New View 4306)",
                    dataType: "DateTime",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "5018a7c3-9de7-41d4-85bd-7b55150b2f22",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 4306",
                    modelColumnID: "1b794428-91dc-b3af-2b55-a36ec8630444",
                },
                {
                    id: "afb7abf3-4eb1-1608-4a32-25c1102129ca",
                    columnReferenceName: "groupmatics_dbo_account_lastname",
                    name: "LastName",
                    displayName: null,
                    visualNameShort: "Last Name",
                    visualNameFull: "Last Name (New View 4306)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "5018a7c3-9de7-41d4-85bd-7b55150b2f22",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 4306",
                    modelColumnID: "afb7abf3-4eb1-1608-4a32-25c1102129ca",
                },
                {
                    id: "c86ee0ec-a3f8-8fd7-96df-6f00afbafff3",
                    columnReferenceName: "groupmatics_dbo_account_optout_deadlinepassed",
                    name: "OptOut_DeadlinePassed",
                    displayName: null,
                    visualNameShort: "Opt Out Deadline Passed",
                    visualNameFull: "Opt Out Deadline Passed (New View 4306)",
                    dataType: "Bool",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "5018a7c3-9de7-41d4-85bd-7b55150b2f22",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 4306",
                    modelColumnID: "c86ee0ec-a3f8-8fd7-96df-6f00afbafff3",
                },
                {
                    id: "6f482344-737b-ecb5-07d1-894bc059c88f",
                    columnReferenceName: "groupmatics_dbo_account_optout_groupleadersummary",
                    name: "OptOut_GroupLeaderSummary",
                    displayName: null,
                    visualNameShort: "Opt Out Group Leader Summary",
                    visualNameFull: "Opt Out Group Leader Summary (New View 4306)",
                    dataType: "Bool",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "5018a7c3-9de7-41d4-85bd-7b55150b2f22",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 4306",
                    modelColumnID: "6f482344-737b-ecb5-07d1-894bc059c88f",
                },
                {
                    id: "68fb5f59-5139-3d31-9c2b-6e03dd9c8d33",
                    columnReferenceName: "groupmatics_dbo_account_optout_invitationacceptance",
                    name: "OptOut_InvitationAcceptance",
                    displayName: null,
                    visualNameShort: "Opt Out Invitation Acceptance",
                    visualNameFull: "Opt Out Invitation Acceptance (New View 4306)",
                    dataType: "Bool",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "5018a7c3-9de7-41d4-85bd-7b55150b2f22",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 4306",
                    modelColumnID: "68fb5f59-5139-3d31-9c2b-6e03dd9c8d33",
                },
                {
                    id: "e5eb6b30-8c04-110f-d489-d689b727c26e",
                    columnReferenceName: "groupmatics_dbo_account_optout_lowticketwarning",
                    name: "OptOut_LowTicketWarning",
                    displayName: null,
                    visualNameShort: "Opt Out Low Ticket Warning",
                    visualNameFull: "Opt Out Low Ticket Warning (New View 4306)",
                    dataType: "Bool",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "5018a7c3-9de7-41d4-85bd-7b55150b2f22",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 4306",
                    modelColumnID: "e5eb6b30-8c04-110f-d489-d689b727c26e",
                },
                {
                    id: "983c9f7a-d446-174d-1165-82838bfbe5d2",
                    columnReferenceName: "groupmatics_dbo_account_optout_orderadjustment",
                    name: "OptOut_OrderAdjustment",
                    displayName: null,
                    visualNameShort: "Opt Out Order Adjustment",
                    visualNameFull: "Opt Out Order Adjustment (New View 4306)",
                    dataType: "Bool",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "5018a7c3-9de7-41d4-85bd-7b55150b2f22",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 4306",
                    modelColumnID: "983c9f7a-d446-174d-1165-82838bfbe5d2",
                },
                {
                    id: "23701056-84cc-491b-9e2a-249c32f9f8f0",
                    columnReferenceName: "groupmatics_dbo_account_optout_orderadjustment",
                    name: "OptOut_OrderAdjustment",
                    displayName: null,
                    visualNameShort: "Opt Out Order Adjustment",
                    visualNameFull: "Opt Out Order Adjustment (New View 4306)",
                    dataType: "Bool",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "5018a7c3-9de7-41d4-85bd-7b55150b2f22",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 4306",
                    modelColumnID: "23701056-84cc-491b-9e2a-249c32f9f8f0",
                },
                {
                    id: "70a02a79-a000-d526-3875-c8ff1fbb21fb",
                    columnReferenceName: "groupmatics_dbo_account_optout_orderconfirmations",
                    name: "OptOut_OrderConfirmations",
                    displayName: null,
                    visualNameShort: "Opt Out Order Confirmations",
                    visualNameFull: "Opt Out Order Confirmations (New View 4306)",
                    dataType: "Bool",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "5018a7c3-9de7-41d4-85bd-7b55150b2f22",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 4306",
                    modelColumnID: "70a02a79-a000-d526-3875-c8ff1fbb21fb",
                },
                {
                    id: "1ea2a38d-3bc9-3595-59da-7dd5ef6cf195",
                    columnReferenceName: "groupmatics_dbo_account_optout_salesmanagersummary",
                    name: "OptOut_SalesManagerSummary",
                    displayName: null,
                    visualNameShort: "Opt Out Sales Manager Summary",
                    visualNameFull: "Opt Out Sales Manager Summary (New View 4306)",
                    dataType: "Bool",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "5018a7c3-9de7-41d4-85bd-7b55150b2f22",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 4306",
                    modelColumnID: "1ea2a38d-3bc9-3595-59da-7dd5ef6cf195",
                },
                {
                    id: "d619a701-d818-9393-5f65-39d46b471ae2",
                    columnReferenceName: "groupmatics_dbo_account_optout_ticketchecker",
                    name: "OptOut_TicketChecker",
                    displayName: null,
                    visualNameShort: "Opt Out Ticket Checker",
                    visualNameFull: "Opt Out Ticket Checker (New View 4306)",
                    dataType: "Bool",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "5018a7c3-9de7-41d4-85bd-7b55150b2f22",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 4306",
                    modelColumnID: "d619a701-d818-9393-5f65-39d46b471ae2",
                },
                {
                    id: "aaf56e13-f4e6-4f79-e6a0-f200b1ed2230",
                    columnReferenceName: "groupmatics_dbo_account_passwordresettoken",
                    name: "PasswordResetToken",
                    displayName: null,
                    visualNameShort: "Password Reset Token",
                    visualNameFull: "Password Reset Token (New View 4306)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "5018a7c3-9de7-41d4-85bd-7b55150b2f22",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 4306",
                    modelColumnID: "aaf56e13-f4e6-4f79-e6a0-f200b1ed2230",
                },
                {
                    id: "60d0af37-b766-5fbc-7be6-d76b667ea72d",
                    columnReferenceName: "groupmatics_dbo_account_passwordresettokenexpirationdate",
                    name: "PasswordResetTokenExpirationDate",
                    displayName: null,
                    visualNameShort: "Password Reset Token Expiration Date",
                    visualNameFull: "Password Reset Token Expiration Date (New View 4306)",
                    dataType: "DateTime",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "5018a7c3-9de7-41d4-85bd-7b55150b2f22",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 4306",
                    modelColumnID: "60d0af37-b766-5fbc-7be6-d76b667ea72d",
                },
                {
                    id: "d8c58b65-4ac7-3270-6dcb-d2ed69c7408c",
                    columnReferenceName: "groupmatics_dbo_account_phone",
                    name: "Phone",
                    displayName: null,
                    visualNameShort: "Phone",
                    visualNameFull: "Phone (New View 4306)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "5018a7c3-9de7-41d4-85bd-7b55150b2f22",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 4306",
                    modelColumnID: "d8c58b65-4ac7-3270-6dcb-d2ed69c7408c",
                },
                {
                    id: "4239c64a-8aef-4ce0-a4db-09399df06ebd",
                    columnReferenceName: "groupmatics_dbo_account_phone",
                    name: "Phone",
                    displayName: null,
                    visualNameShort: "Phone",
                    visualNameFull: "Phone (New View 4306)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "5018a7c3-9de7-41d4-85bd-7b55150b2f22",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 4306",
                    modelColumnID: "4239c64a-8aef-4ce0-a4db-09399df06ebd",
                },
                {
                    id: "74399171-8163-eed2-b1dd-b682e2795edf",
                    columnReferenceName: "groupmatics_dbo_account_randomcode",
                    name: "RandomCode",
                    displayName: null,
                    visualNameShort: "Random Code",
                    visualNameFull: "Random Code (New View 4306)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "5018a7c3-9de7-41d4-85bd-7b55150b2f22",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 4306",
                    modelColumnID: "74399171-8163-eed2-b1dd-b682e2795edf",
                },
                {
                    id: "895d76c9-c6c2-9672-5398-2138fed4b78f",
                    columnReferenceName: "groupmatics_dbo_account_randomcodeexpirationdate",
                    name: "RandomCodeExpirationDate",
                    displayName: null,
                    visualNameShort: "Random Code Expiration Date",
                    visualNameFull: "Random Code Expiration Date (New View 4306)",
                    dataType: "DateTime",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "5018a7c3-9de7-41d4-85bd-7b55150b2f22",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 4306",
                    modelColumnID: "895d76c9-c6c2-9672-5398-2138fed4b78f",
                },
                {
                    id: "28fc29e5-7760-ac0c-3d7b-34b739f04ba9",
                    columnReferenceName: "groupmatics_dbo_account_socialavatar",
                    name: "SocialAvatar",
                    displayName: null,
                    visualNameShort: "Social Avatar",
                    visualNameFull: "Social Avatar (New View 4306)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "5018a7c3-9de7-41d4-85bd-7b55150b2f22",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 4306",
                    modelColumnID: "28fc29e5-7760-ac0c-3d7b-34b739f04ba9",
                },
                {
                    id: "2991b9b0-02ab-d51e-060d-4bd513158a98",
                    columnReferenceName: "groupmatics_dbo_account_status",
                    name: "Status",
                    displayName: null,
                    visualNameShort: "Status",
                    visualNameFull: "Status (New View 4306)",
                    dataType: "Int",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "5018a7c3-9de7-41d4-85bd-7b55150b2f22",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 4306",
                    modelColumnID: "2991b9b0-02ab-d51e-060d-4bd513158a98",
                },
                {
                    id: "85101d0f-2666-4f92-82c1-e6c53874d81c",
                    columnReferenceName: "groupmatics_dbo_account_username",
                    name: "Username",
                    displayName: null,
                    visualNameShort: "Username",
                    visualNameFull: "Username (New View 4306)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "5018a7c3-9de7-41d4-85bd-7b55150b2f22",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 4306",
                    modelColumnID: "85101d0f-2666-4f92-82c1-e6c53874d81c",
                },
                {
                    id: "721fe8ba-ab87-b000-4e88-729fae4a322b",
                    columnReferenceName: "groupmatics_dbo_account_username",
                    name: "Username",
                    displayName: null,
                    visualNameShort: "Username",
                    visualNameFull: "Username (New View 4306)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "5018a7c3-9de7-41d4-85bd-7b55150b2f22",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 4306",
                    modelColumnID: "721fe8ba-ab87-b000-4e88-729fae4a322b",
                },
            ],
            rowLevelSecurity: {},
            position: {
                x: -1341,
                y: 732.5,
            },
            $type: "ViewModelTable",
        },
        {
            id: "c55b18d9-947c-124d-b441-40e00de22a88",
            transformationID: "e9c1cc16-bd1d-40c9-bd7e-331dbc4bcbd7",
            tableReferenceName: "verbview_e9c1cc16_bd1d_40c9_bd7e_331dbc4bcbd7",
            versionDetails: "NotPublished",
            publishedOn: null,
            excludeFromModel: true,
            name: "New View 4448",
            visualName: "New View 4448",
            columns: [],
            rowLevelSecurity: {},
            position: {
                x: -1491,
                y: 432.5,
            },
            $type: "ViewModelTable",
        },
        {
            id: "0c49b262-119a-06c7-bd5b-f19eaa7a3321",
            transformationID: "faf325e6-5ee0-4f33-915a-1f70c79fbd1a",
            tableReferenceName: "verbview_faf325e6_5ee0_4f33_915a_1f70c79fbd1a",
            versionDetails: "NotPublished",
            publishedOn: null,
            excludeFromModel: true,
            name: "New View 4480",
            visualName: "New View 4480",
            columns: [],
            rowLevelSecurity: {},
            position: {
                x: -1491,
                y: 582.5,
            },
            $type: "ViewModelTable",
        },
        {
            id: "86e28e59-3105-b4e6-1117-f469f93f3be1",
            transformationID: "a8c1005e-80bd-4cf3-b249-1c71ea43bb24",
            tableReferenceName: "verbview_a8c1005e_80bd_4cf3_b249_1c71ea43bb24_v_0",
            versionDetails: "NotPublished",
            publishedOn: null,
            excludeFromModel: true,
            name: "New View 4518",
            visualName: "New View 4518",
            columns: [],
            rowLevelSecurity: {},
            position: {
                x: -1491,
                y: 732.5,
            },
            $type: "ViewModelTable",
        },
        {
            id: "42fd83dd-8fd7-4281-9375-025a3adf6437",
            transformationID: "9528831b-7157-47b4-8bae-c2a305c7cb92",
            tableReferenceName: "verbview_9528831b_7157_47b4_8bae_c2a305c7cb92",
            versionDetails: "NotPublished",
            publishedOn: null,
            excludeFromModel: false,
            name: "New View 5400",
            visualName: "New View 5400",
            columns: [],
            rowLevelSecurity: {},
            position: {
                x: -1641,
                y: 432.5,
            },
            $type: "ViewModelTable",
        },
        {
            id: "1c820f97-28bc-66ea-3a57-8b3318662bdc",
            transformationID: "a879da36-71a6-415c-994e-5358de85b2f8",
            tableReferenceName: "verbview_a879da36_71a6_415c_994e_5358de85b2f8",
            versionDetails: "NotPublished",
            publishedOn: null,
            excludeFromModel: false,
            name: "New View 5443",
            visualName: "New View 5443",
            columns: [],
            rowLevelSecurity: {},
            position: {
                x: -1641,
                y: 582.5,
            },
            $type: "ViewModelTable",
        },
        {
            id: "900e0dcb-24a3-465a-bafb-2afcc7cd048e",
            transformationID: "313d17a0-bf84-4151-9a3d-d36ad2be3b6f",
            tableReferenceName: "verbview_313d17a0_bf84_4151_9a3d_d36ad2be3b6f",
            versionDetails: "NotPublished",
            publishedOn: null,
            excludeFromModel: false,
            name: "New View 5520",
            visualName: "New View 5520",
            columns: [],
            rowLevelSecurity: {},
            position: {
                x: -1641,
                y: 732.5,
            },
            $type: "ViewModelTable",
        },
        {
            id: "ae45434a-1008-4fbb-a1d1-3237d3e93436",
            transformationID: "4fb68ec7-7dc3-4174-b91f-a198cf19686d",
            tableReferenceName: "verbview_4fb68ec7_7dc3_4174_b91f_a198cf19686d",
            versionDetails: "NotPublished",
            publishedOn: null,
            excludeFromModel: false,
            name: "New View 6477",
            visualName: "New View 6477",
            columns: [],
            rowLevelSecurity: {},
            position: {
                x: -1791,
                y: 432.5,
            },
            $type: "ViewModelTable",
        },
        {
            id: "72789ed2-b7bd-25d1-ed5a-990df68b8e08",
            transformationID: "779b252b-00b2-4194-ab37-5026bae00b10",
            tableReferenceName: "verbview_779b252b_00b2_4194_ab37_5026bae00b10",
            versionDetails: "NotPublished",
            publishedOn: null,
            excludeFromModel: false,
            name: "New View 6728",
            visualName: "New View 6728",
            columns: [],
            rowLevelSecurity: {},
            position: {
                x: -1791,
                y: 582.5,
            },
            $type: "ViewModelTable",
        },
        {
            id: "5cef30fb-3fdd-429a-96e0-2bc459a04caf",
            transformationID: "0fddb910-0bc4-4d3b-b99f-a766acf4257a",
            tableReferenceName: "verbview_0fddb910_0bc4_4d3b_b99f_a766acf4257a_v_0",
            versionDetails: "NotPublished",
            publishedOn: null,
            excludeFromModel: false,
            name: "New View 7339",
            visualName: "New View 7339",
            columns: [],
            rowLevelSecurity: {},
            position: {
                x: -1791,
                y: 732.5,
            },
            $type: "ViewModelTable",
        },
        {
            id: "072ed455-e7cf-4b0b-a102-8fa6e2ca44e5",
            transformationID: "7e45f810-4a02-47aa-9d49-56982e8ba353",
            tableReferenceName: "verbview_7e45f810_4a02_47aa_9d49_56982e8ba353_v_220311071130803",
            versionDetails: "LatestPublished",
            publishedOn: "2022-03-11T07:11:30.951Z",
            excludeFromModel: false,
            name: "New View 7574",
            visualName: "New View 7574",
            columns: [
                {
                    id: "5e788e7e-3fcf-1294-105f-9821bad6b85b",
                    columnReferenceName: "groupmatics_dbo_order_accountid",
                    name: "AccountID",
                    displayName: null,
                    visualNameShort: "Account ID",
                    visualNameFull: "Account ID (New View 7574)",
                    dataType: "IDUUID",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "072ed455-e7cf-4b0b-a102-8fa6e2ca44e5",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 7574",
                    modelColumnID: "5e788e7e-3fcf-1294-105f-9821bad6b85b",
                },
                {
                    id: "420ef4b8-6507-a2b2-f8a2-c239cffc9d5c",
                    columnReferenceName: "groupmatics_dbo_order_customerip",
                    name: "CustomerIP",
                    displayName: null,
                    visualNameShort: "Customer IP",
                    visualNameFull: "Customer IP (New View 7574)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "072ed455-e7cf-4b0b-a102-8fa6e2ca44e5",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 7574",
                    modelColumnID: "420ef4b8-6507-a2b2-f8a2-c239cffc9d5c",
                },
                {
                    id: "96d03ad2-ea63-bb25-683e-b2534ca87035",
                    columnReferenceName: "groupmatics_dbo_order_dateplacedutc",
                    name: "DatePlacedUtc",
                    displayName: null,
                    visualNameShort: "Date Placed Utc",
                    visualNameFull: "Date Placed Utc (New View 7574)",
                    dataType: "DateTime",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "072ed455-e7cf-4b0b-a102-8fa6e2ca44e5",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 7574",
                    modelColumnID: "96d03ad2-ea63-bb25-683e-b2534ca87035",
                },
                {
                    id: "f9ea674c-73bb-350d-1f63-8d5aad7f550c",
                    columnReferenceName: "groupmatics_dbo_order_deliverymethod",
                    name: "DeliveryMethod",
                    displayName: null,
                    visualNameShort: "Delivery Method",
                    visualNameFull: "Delivery Method (New View 7574)",
                    dataType: "Int",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "072ed455-e7cf-4b0b-a102-8fa6e2ca44e5",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 7574",
                    modelColumnID: "f9ea674c-73bb-350d-1f63-8d5aad7f550c",
                },
                {
                    id: "d5762de0-24d2-fa8b-3fb4-a26acc3ae154",
                    columnReferenceName: "groupmatics_dbo_order_donation",
                    name: "Donation",
                    displayName: null,
                    visualNameShort: "Donation",
                    visualNameFull: "Donation (New View 7574)",
                    dataType: "Double",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "072ed455-e7cf-4b0b-a102-8fa6e2ca44e5",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 7574",
                    modelColumnID: "d5762de0-24d2-fa8b-3fb4-a26acc3ae154",
                },
                {
                    id: "7a190a75-fb2b-955f-1a59-8d790eb8b015",
                    columnReferenceName: "groupmatics_dbo_order_emailinvitationid",
                    name: "EmailInvitationID",
                    displayName: null,
                    visualNameShort: "Email Invitation ID",
                    visualNameFull: "Email Invitation ID (New View 7574)",
                    dataType: "IDUUID",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "072ed455-e7cf-4b0b-a102-8fa6e2ca44e5",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 7574",
                    modelColumnID: "7a190a75-fb2b-955f-1a59-8d790eb8b015",
                },
                {
                    id: "43a09586-88bf-47db-9bff-08e9af59ca36",
                    columnReferenceName: "groupmatics_dbo_order_eventservicecustomerid",
                    name: "EventServiceCustomerID",
                    displayName: null,
                    visualNameShort: "Event Service Customer ID",
                    visualNameFull: "Event Service Customer ID (New View 7574)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "072ed455-e7cf-4b0b-a102-8fa6e2ca44e5",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 7574",
                    modelColumnID: "43a09586-88bf-47db-9bff-08e9af59ca36",
                },
                {
                    id: "377f5ecf-33b4-d992-f02d-71f3e84e7410",
                    columnReferenceName: "groupmatics_dbo_order_eventservicehaspdftickets",
                    name: "EventServiceHasPdfTickets",
                    displayName: null,
                    visualNameShort: "Event Service Has Pdf Tickets",
                    visualNameFull: "Event Service Has Pdf Tickets (New View 7574)",
                    dataType: "Bool",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "072ed455-e7cf-4b0b-a102-8fa6e2ca44e5",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 7574",
                    modelColumnID: "377f5ecf-33b4-d992-f02d-71f3e84e7410",
                },
                {
                    id: "48e94a43-c05f-7483-030f-2c8cf32d9c31",
                    columnReferenceName: "groupmatics_dbo_order_eventservicehidegmtickets",
                    name: "EventServiceHideGMTickets",
                    displayName: null,
                    visualNameShort: "Event Service Hide GM Tickets",
                    visualNameFull: "Event Service Hide GM Tickets (New View 7574)",
                    dataType: "Bool",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "072ed455-e7cf-4b0b-a102-8fa6e2ca44e5",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 7574",
                    modelColumnID: "48e94a43-c05f-7483-030f-2c8cf32d9c31",
                },
                {
                    id: "2fbba82d-1af8-6f19-9621-33239ddd0420",
                    columnReferenceName: "groupmatics_dbo_order_eventservicenewcustomer",
                    name: "EventServiceNewCustomer",
                    displayName: null,
                    visualNameShort: "Event Service New Customer",
                    visualNameFull: "Event Service New Customer (New View 7574)",
                    dataType: "Bool",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "072ed455-e7cf-4b0b-a102-8fa6e2ca44e5",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 7574",
                    modelColumnID: "2fbba82d-1af8-6f19-9621-33239ddd0420",
                },
                {
                    id: "785b9943-82d5-599b-62ac-4055f966d0d5",
                    columnReferenceName: "groupmatics_dbo_order_eventserviceorderid",
                    name: "EventServiceOrderID",
                    displayName: null,
                    visualNameShort: "Event Service Order ID",
                    visualNameFull: "Event Service Order ID (New View 7574)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "072ed455-e7cf-4b0b-a102-8fa6e2ca44e5",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 7574",
                    modelColumnID: "785b9943-82d5-599b-62ac-4055f966d0d5",
                },
                {
                    id: "cf18e314-2540-56fb-f76b-a58778904239",
                    columnReferenceName: "groupmatics_dbo_order_eventservicetemporarypassword",
                    name: "EventServiceTemporaryPassword",
                    displayName: null,
                    visualNameShort: "Event Service Temporary Password",
                    visualNameFull: "Event Service Temporary Password (New View 7574)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "072ed455-e7cf-4b0b-a102-8fa6e2ca44e5",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 7574",
                    modelColumnID: "cf18e314-2540-56fb-f76b-a58778904239",
                },
                {
                    id: "da15fd86-1f89-f958-3704-8e8be21958e4",
                    columnReferenceName: "groupmatics_dbo_order_eventserviceticketprovider",
                    name: "EventServiceTicketProvider",
                    displayName: null,
                    visualNameShort: "Event Service Ticket Provider",
                    visualNameFull: "Event Service Ticket Provider (New View 7574)",
                    dataType: "Int",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "072ed455-e7cf-4b0b-a102-8fa6e2ca44e5",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 7574",
                    modelColumnID: "da15fd86-1f89-f958-3704-8e8be21958e4",
                },
                {
                    id: "1cc93a05-49e2-cc8f-ccae-6235b678b98b",
                    columnReferenceName: "groupmatics_dbo_order_expgroup",
                    name: "ExpGroup",
                    displayName: null,
                    visualNameShort: "Exp Group",
                    visualNameFull: "Exp Group (New View 7574)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "072ed455-e7cf-4b0b-a102-8fa6e2ca44e5",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 7574",
                    modelColumnID: "1cc93a05-49e2-cc8f-ccae-6235b678b98b",
                },
                {
                    id: "5f7ff04c-34c9-ba2b-88f0-5a76157747b5",
                    columnReferenceName: "groupmatics_dbo_order_expirationdateutc",
                    name: "ExpirationDateUtc",
                    displayName: null,
                    visualNameShort: "Expiration Date Utc",
                    visualNameFull: "Expiration Date Utc (New View 7574)",
                    dataType: "DateTime",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "072ed455-e7cf-4b0b-a102-8fa6e2ca44e5",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 7574",
                    modelColumnID: "5f7ff04c-34c9-ba2b-88f0-5a76157747b5",
                },
                {
                    id: "482c18bc-3188-0686-d0aa-13a6e299a71b",
                    columnReferenceName: "groupmatics_dbo_order_feesatcheckout",
                    name: "FeesAtCheckout",
                    displayName: null,
                    visualNameShort: "Fees At Checkout",
                    visualNameFull: "Fees At Checkout (New View 7574)",
                    dataType: "Double",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "072ed455-e7cf-4b0b-a102-8fa6e2ca44e5",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 7574",
                    modelColumnID: "482c18bc-3188-0686-d0aa-13a6e299a71b",
                },
                {
                    id: "19869b93-ff47-8d54-505c-d6308341aafb",
                    columnReferenceName: "groupmatics_dbo_order_feesatpayout",
                    name: "FeesAtPayout",
                    displayName: null,
                    visualNameShort: "Fees At Payout",
                    visualNameFull: "Fees At Payout (New View 7574)",
                    dataType: "Double",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "072ed455-e7cf-4b0b-a102-8fa6e2ca44e5",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 7574",
                    modelColumnID: "19869b93-ff47-8d54-505c-d6308341aafb",
                },
                {
                    id: "be2b1b2c-345f-fb1d-f2b7-3183fe7f7fdc",
                    columnReferenceName: "groupmatics_dbo_order_flattaxes",
                    name: "FlatTaxes",
                    displayName: null,
                    visualNameShort: "Flat Taxes",
                    visualNameFull: "Flat Taxes (New View 7574)",
                    dataType: "Double",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "072ed455-e7cf-4b0b-a102-8fa6e2ca44e5",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 7574",
                    modelColumnID: "be2b1b2c-345f-fb1d-f2b7-3183fe7f7fdc",
                },
                {
                    id: "ab7bf53a-28a5-8f6b-d048-c5cfbfaba046",
                    columnReferenceName: "groupmatics_dbo_order_orderid",
                    name: "OrderID",
                    displayName: null,
                    visualNameShort: "Order ID",
                    visualNameFull: "Order ID (New View 7574)",
                    dataType: "IDUUID",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "072ed455-e7cf-4b0b-a102-8fa6e2ca44e5",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 7574",
                    modelColumnID: "ab7bf53a-28a5-8f6b-d048-c5cfbfaba046",
                },
                {
                    id: "bb2f9df7-dcf3-2ceb-446f-cb95cceb4ea9",
                    columnReferenceName: "groupmatics_dbo_order_ordernumber",
                    name: "OrderNumber",
                    displayName: null,
                    visualNameShort: "Order Number",
                    visualNameFull: "Order Number (New View 7574)",
                    dataType: "Int",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "072ed455-e7cf-4b0b-a102-8fa6e2ca44e5",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 7574",
                    modelColumnID: "bb2f9df7-dcf3-2ceb-446f-cb95cceb4ea9",
                },
                {
                    id: "54099d92-38e8-9743-4375-67344dea06c7",
                    columnReferenceName: "groupmatics_dbo_order_outingid",
                    name: "OutingID",
                    displayName: null,
                    visualNameShort: "Outing ID",
                    visualNameFull: "Outing ID (New View 7574)",
                    dataType: "IDUUID",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "072ed455-e7cf-4b0b-a102-8fa6e2ca44e5",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 7574",
                    modelColumnID: "54099d92-38e8-9743-4375-67344dea06c7",
                },
                {
                    id: "acdc29da-0f4d-efd8-03c1-cede7d5ec2d7",
                    columnReferenceName: "groupmatics_dbo_order_providerfees",
                    name: "ProviderFees",
                    displayName: null,
                    visualNameShort: "Provider Fees",
                    visualNameFull: "Provider Fees (New View 7574)",
                    dataType: "Double",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "072ed455-e7cf-4b0b-a102-8fa6e2ca44e5",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 7574",
                    modelColumnID: "acdc29da-0f4d-efd8-03c1-cede7d5ec2d7",
                },
                {
                    id: "7eb255a2-ebc4-bea9-e2c2-3e3a596ebac2",
                    columnReferenceName: "groupmatics_dbo_order_referrer",
                    name: "Referrer",
                    displayName: null,
                    visualNameShort: "Referrer",
                    visualNameFull: "Referrer (New View 7574)",
                    dataType: "Int",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "072ed455-e7cf-4b0b-a102-8fa6e2ca44e5",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 7574",
                    modelColumnID: "7eb255a2-ebc4-bea9-e2c2-3e3a596ebac2",
                },
                {
                    id: "0081292f-ae6c-a181-d765-9cacc59e1b43",
                    columnReferenceName: "groupmatics_dbo_order_ticketdeliveryid",
                    name: "TicketDeliveryID",
                    displayName: null,
                    visualNameShort: "Ticket Delivery ID",
                    visualNameFull: "Ticket Delivery ID (New View 7574)",
                    dataType: "IDUUID",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "072ed455-e7cf-4b0b-a102-8fa6e2ca44e5",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 7574",
                    modelColumnID: "0081292f-ae6c-a181-d765-9cacc59e1b43",
                },
                {
                    id: "a2e45597-9eb6-2160-99b0-eafccb810005",
                    columnReferenceName: "groupmatics_dbo_order_totalprice",
                    name: "TotalPrice",
                    displayName: null,
                    visualNameShort: "Total Price",
                    visualNameFull: "Total Price (New View 7574)",
                    dataType: "Double",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "072ed455-e7cf-4b0b-a102-8fa6e2ca44e5",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 7574",
                    modelColumnID: "a2e45597-9eb6-2160-99b0-eafccb810005",
                },
                {
                    id: "f22f8bef-653a-5f35-cd19-cf6b0271a106",
                    columnReferenceName: "verbcol_viewstepcolumn_7590",
                    name: "ViewStepColumn_7590",
                    displayName: null,
                    visualNameShort: "View Step Column 7590",
                    visualNameFull: "View Step Column 7590 (New View 7574)",
                    dataType: "Double",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "072ed455-e7cf-4b0b-a102-8fa6e2ca44e5",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 7574",
                    modelColumnID: "f22f8bef-653a-5f35-cd19-cf6b0271a106",
                },
                {
                    id: "1053d842-0d96-0f4c-15b8-6e4fc12c2f9e",
                    columnReferenceName: "groupmatics_dbo_order_visitortoken",
                    name: "VisitorToken",
                    displayName: null,
                    visualNameShort: "Visitor Token",
                    visualNameFull: "Visitor Token (New View 7574)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "072ed455-e7cf-4b0b-a102-8fa6e2ca44e5",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 7574",
                    modelColumnID: "1053d842-0d96-0f4c-15b8-6e4fc12c2f9e",
                },
            ],
            rowLevelSecurity: {},
            position: {
                x: 639,
                y: 592.5,
            },
            $type: "ViewModelTable",
        },
        {
            id: "a541d7f4-5411-2559-de53-1718c939cf35",
            transformationID: "3071ed0a-8799-48de-bcf6-3d8e8df178ac",
            tableReferenceName: "verbview_3071ed0a_8799_48de_bcf6_3d8e8df178ac",
            versionDetails: "NotPublished",
            publishedOn: null,
            excludeFromModel: false,
            name: "New View 7765",
            visualName: "New View 7765",
            columns: [],
            rowLevelSecurity: {},
            position: {
                x: -1941,
                y: 432.5,
            },
            $type: "ViewModelTable",
        },
        {
            id: "c4a9806a-c487-79c3-d3da-1c9d54bed6ca",
            transformationID: "a9bb547a-c085-4f6b-8444-6e0fcebade17",
            tableReferenceName: "verbview_a9bb547a_c085_4f6b_8444_6e0fcebade17",
            versionDetails: "NotPublished",
            publishedOn: null,
            excludeFromModel: true,
            name: "New View 8382",
            visualName: "New View 8382",
            columns: [],
            rowLevelSecurity: {},
            position: {
                x: -1941,
                y: 582.5,
            },
            $type: "ViewModelTable",
        },
        {
            id: "6d584fdb-5dd6-4c60-b51b-515c991bcca0",
            transformationID: "6e6e91a5-3903-4c8c-9eb5-f535ff542ddf",
            tableReferenceName: "verbview_6e6e91a5_3903_4c8c_9eb5_f535ff542ddf_v_220311074534512",
            versionDetails: "LatestNotPublished",
            publishedOn: "2022-03-11T07:45:34.682Z",
            excludeFromModel: false,
            name: "New View 9139",
            visualName: "New View 9139",
            columns: [
                {
                    id: "10c25f7c-4f82-4cff-b341-c69da1d121ff",
                    columnReferenceName: "groupmatics_dbo_order_accountid",
                    name: "AccountID",
                    displayName: null,
                    visualNameShort: "Account ID",
                    visualNameFull: "Account ID (New View 9139)",
                    dataType: "IDUUID",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "6d584fdb-5dd6-4c60-b51b-515c991bcca0",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 9139",
                    modelColumnID: "10c25f7c-4f82-4cff-b341-c69da1d121ff",
                },
                {
                    id: "725eaae2-39fb-fa1c-f3fd-69c4bc058846",
                    columnReferenceName: "groupmatics_dbo_order_customerip",
                    name: "CustomerIP",
                    displayName: null,
                    visualNameShort: "Customer IP",
                    visualNameFull: "Customer IP (New View 9139)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "6d584fdb-5dd6-4c60-b51b-515c991bcca0",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 9139",
                    modelColumnID: "725eaae2-39fb-fa1c-f3fd-69c4bc058846",
                },
                {
                    id: "642a2959-92d2-7402-fb54-94a980feeaaf",
                    columnReferenceName: "groupmatics_dbo_order_dateplacedutc",
                    name: "DatePlacedUtc",
                    displayName: null,
                    visualNameShort: "Date Placed Utc",
                    visualNameFull: "Date Placed Utc (New View 9139)",
                    dataType: "DateTime",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "6d584fdb-5dd6-4c60-b51b-515c991bcca0",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 9139",
                    modelColumnID: "642a2959-92d2-7402-fb54-94a980feeaaf",
                },
                {
                    id: "da97a5d7-8177-009d-9db8-b19652448a75",
                    columnReferenceName: "groupmatics_dbo_order_deliverymethod",
                    name: "DeliveryMethod",
                    displayName: null,
                    visualNameShort: "Delivery Method",
                    visualNameFull: "Delivery Method (New View 9139)",
                    dataType: "Int",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "6d584fdb-5dd6-4c60-b51b-515c991bcca0",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 9139",
                    modelColumnID: "da97a5d7-8177-009d-9db8-b19652448a75",
                },
                {
                    id: "89c06e69-b8a1-eb9c-c14e-373fdc532619",
                    columnReferenceName: "groupmatics_dbo_order_expirationdateutc",
                    name: "ExpirationDateUtc",
                    displayName: null,
                    visualNameShort: "Expiration Date Utc",
                    visualNameFull: "Expiration Date Utc (New View 9139)",
                    dataType: "DateTime",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "6d584fdb-5dd6-4c60-b51b-515c991bcca0",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 9139",
                    modelColumnID: "89c06e69-b8a1-eb9c-c14e-373fdc532619",
                },
                {
                    id: "a98014a3-9744-8ce9-4a27-b9ad02a787bc",
                    columnReferenceName: "groupmatics_dbo_order_feesatcheckout",
                    name: "FeesAtCheckout",
                    displayName: null,
                    visualNameShort: "Fees At Checkout",
                    visualNameFull: "Fees At Checkout (New View 9139)",
                    dataType: "Double",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "6d584fdb-5dd6-4c60-b51b-515c991bcca0",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 9139",
                    modelColumnID: "a98014a3-9744-8ce9-4a27-b9ad02a787bc",
                },
                {
                    id: "a0505f0f-e22a-ec05-a8e4-4ce3f6d03e9a",
                    columnReferenceName: "groupmatics_dbo_order_feesatpayout",
                    name: "FeesAtPayout",
                    displayName: null,
                    visualNameShort: "Fees At Payout",
                    visualNameFull: "Fees At Payout (New View 9139)",
                    dataType: "Double",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "6d584fdb-5dd6-4c60-b51b-515c991bcca0",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 9139",
                    modelColumnID: "a0505f0f-e22a-ec05-a8e4-4ce3f6d03e9a",
                },
                {
                    id: "dc6b5c0a-2ac0-411d-a3ba-32e57e03d042",
                    columnReferenceName: "groupmatics_dbo_order_flattaxes",
                    name: "FlatTaxes",
                    displayName: null,
                    visualNameShort: "Flat Taxes",
                    visualNameFull: "Flat Taxes (New View 9139)",
                    dataType: "Double",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "6d584fdb-5dd6-4c60-b51b-515c991bcca0",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 9139",
                    modelColumnID: "dc6b5c0a-2ac0-411d-a3ba-32e57e03d042",
                },
                {
                    id: "41924f8f-954c-7371-d088-d721c95be326",
                    columnReferenceName: "groupmatics_dbo_order_orderid",
                    name: "OrderID",
                    displayName: null,
                    visualNameShort: "Order ID",
                    visualNameFull: "Order ID (New View 9139)",
                    dataType: "IDUUID",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "6d584fdb-5dd6-4c60-b51b-515c991bcca0",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 9139",
                    modelColumnID: "41924f8f-954c-7371-d088-d721c95be326",
                },
                {
                    id: "add7da7e-4c0e-b9e7-2c83-9208e3935e5f",
                    columnReferenceName: "groupmatics_dbo_order_ordernumber",
                    name: "OrderNumber",
                    displayName: null,
                    visualNameShort: "Order Number",
                    visualNameFull: "Order Number (New View 9139)",
                    dataType: "Int",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "6d584fdb-5dd6-4c60-b51b-515c991bcca0",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 9139",
                    modelColumnID: "add7da7e-4c0e-b9e7-2c83-9208e3935e5f",
                },
                {
                    id: "b4a1c9c0-2f98-4292-8f3b-18babe81bc45",
                    columnReferenceName: "groupmatics_dbo_order_outingid",
                    name: "OutingID",
                    displayName: null,
                    visualNameShort: "Outing ID",
                    visualNameFull: "Outing ID (New View 9139)",
                    dataType: "IDUUID",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "6d584fdb-5dd6-4c60-b51b-515c991bcca0",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 9139",
                    modelColumnID: "b4a1c9c0-2f98-4292-8f3b-18babe81bc45",
                },
            ],
            rowLevelSecurity: {},
            position: {
                x: 639,
                y: 752.5,
            },
            $type: "ViewModelTable",
        },
        {
            id: "7f291933-1738-4d5f-964b-9ef45a4608d8",
            transformationID: "2e1b39a3-362e-48fc-8e7b-cd1ecd72f4df",
            tableReferenceName: "verbview_2e1b39a3_362e_48fc_8e7b_cd1ecd72f4df_v_0",
            versionDetails: "NotPublished",
            publishedOn: null,
            excludeFromModel: false,
            name: "New View 9140",
            visualName: "New View 9140",
            columns: [],
            rowLevelSecurity: {},
            position: {
                x: -1941,
                y: 732.5,
            },
            $type: "ViewModelTable",
        },
        {
            id: "bce1a2b9-7189-0b50-7d31-4a43f7c0e8c0",
            transformationID: "4d9e0d9b-11f1-42e5-acdf-7cd329f3621c",
            tableReferenceName: "verbview_4d9e0d9b_11f1_42e5_acdf_7cd329f3621c",
            versionDetails: "NotPublished",
            publishedOn: null,
            excludeFromModel: false,
            name: "New View 9162",
            visualName: "New View 9162",
            columns: [],
            rowLevelSecurity: {},
            position: {
                x: -2091,
                y: 432.5,
            },
            $type: "ViewModelTable",
        },
        {
            id: "78d181a3-dd6c-6f70-536d-f99ab4996814",
            transformationID: "0bbfeca0-310d-4295-993a-ad8e7ad36a62",
            tableReferenceName: "verbview_0bbfeca0_310d_4295_993a_ad8e7ad36a62_v_0",
            versionDetails: "NotPublished",
            publishedOn: null,
            excludeFromModel: true,
            name: "New View 9456",
            visualName: "New View 9456",
            columns: [],
            rowLevelSecurity: {},
            position: {
                x: -2091,
                y: 582.5,
            },
            $type: "ViewModelTable",
        },
        {
            id: "8dabc60d-644f-40e7-a20b-77df1740fcce",
            transformationID: "91e8b45a-c12e-4684-b9f3-032165a65d56",
            tableReferenceName: "verbview_91e8b45a_c12e_4684_b9f3_032165a65d56_v_220413072314781",
            versionDetails: "LatestNotPublished",
            publishedOn: "2022-04-13T07:23:14.909Z",
            excludeFromModel: false,
            name: "New View 9517",
            visualName: "New View 9517",
            columns: [
                {
                    id: "ad3618ba-8a94-4468-a6cc-7c6a006854b7",
                    columnReferenceName: "groupmatics_dbo_account_accountid",
                    name: "AccountID",
                    displayName: null,
                    visualNameShort: "Account ID",
                    visualNameFull: "Account ID (New View 9517)",
                    dataType: "IDUUID",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8dabc60d-644f-40e7-a20b-77df1740fcce",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 9517",
                    modelColumnID: "ad3618ba-8a94-4468-a6cc-7c6a006854b7",
                },
                {
                    id: "344859df-5dee-2466-8fb8-9f2a2bbc3e1d",
                    columnReferenceName: "groupmatics_dbo_account_accountid",
                    name: "AccountID",
                    displayName: null,
                    visualNameShort: "Account ID",
                    visualNameFull: "Account ID (New View 9517)",
                    dataType: "IDUUID",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8dabc60d-644f-40e7-a20b-77df1740fcce",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 9517",
                    modelColumnID: "344859df-5dee-2466-8fb8-9f2a2bbc3e1d",
                },
                {
                    id: "23a1e085-9ca6-46aa-9552-d56682ef8982",
                    columnReferenceName: "groupmatics_dbo_account_adminaccess",
                    name: "AdminAccess",
                    displayName: null,
                    visualNameShort: "Admin Access",
                    visualNameFull: "Admin Access (New View 9517)",
                    dataType: "Int",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8dabc60d-644f-40e7-a20b-77df1740fcce",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 9517",
                    modelColumnID: "23a1e085-9ca6-46aa-9552-d56682ef8982",
                },
                {
                    id: "25d964eb-831a-2bb0-925a-a06e21f44389",
                    columnReferenceName: "groupmatics_dbo_account_adminaccess",
                    name: "AdminAccess",
                    displayName: null,
                    visualNameShort: "Admin Access",
                    visualNameFull: "Admin Access (New View 9517)",
                    dataType: "Int",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8dabc60d-644f-40e7-a20b-77df1740fcce",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 9517",
                    modelColumnID: "25d964eb-831a-2bb0-925a-a06e21f44389",
                },
                {
                    id: "6afabf48-a021-421f-bbe5-98003051d8ed",
                    columnReferenceName: "groupmatics_dbo_account_datecreated",
                    name: "DateCreated",
                    displayName: null,
                    visualNameShort: "Date Created",
                    visualNameFull: "Date Created (New View 9517)",
                    dataType: "DateTime",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8dabc60d-644f-40e7-a20b-77df1740fcce",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 9517",
                    modelColumnID: "6afabf48-a021-421f-bbe5-98003051d8ed",
                },
                {
                    id: "70ca8c89-0e14-a15e-c216-21fdb4716b2c",
                    columnReferenceName: "groupmatics_dbo_account_datecreated",
                    name: "DateCreated",
                    displayName: null,
                    visualNameShort: "Date Created",
                    visualNameFull: "Date Created (New View 9517)",
                    dataType: "DateTime",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8dabc60d-644f-40e7-a20b-77df1740fcce",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 9517",
                    modelColumnID: "70ca8c89-0e14-a15e-c216-21fdb4716b2c",
                },
                {
                    id: "8b5328c6-306f-4404-8aed-0b610256765d",
                    columnReferenceName: "groupmatics_dbo_account_dateofbirth",
                    name: "DateOfBirth",
                    displayName: null,
                    visualNameShort: "Date Of Birth",
                    visualNameFull: "Date Of Birth (New View 9517)",
                    dataType: "DateTime",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8dabc60d-644f-40e7-a20b-77df1740fcce",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 9517",
                    modelColumnID: "8b5328c6-306f-4404-8aed-0b610256765d",
                },
                {
                    id: "38102fa8-8ec7-aaa8-f6a3-574f02b99b90",
                    columnReferenceName: "groupmatics_dbo_account_dateofbirth",
                    name: "DateOfBirth",
                    displayName: null,
                    visualNameShort: "Date Of Birth",
                    visualNameFull: "Date Of Birth (New View 9517)",
                    dataType: "DateTime",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8dabc60d-644f-40e7-a20b-77df1740fcce",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 9517",
                    modelColumnID: "38102fa8-8ec7-aaa8-f6a3-574f02b99b90",
                },
                {
                    id: "19f281ad-f255-3f91-a4d2-266cea57cdba",
                    columnReferenceName: "groupmatics_dbo_account_email",
                    name: "Email",
                    displayName: null,
                    visualNameShort: "Email",
                    visualNameFull: "Email (New View 9517)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8dabc60d-644f-40e7-a20b-77df1740fcce",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 9517",
                    modelColumnID: "19f281ad-f255-3f91-a4d2-266cea57cdba",
                },
                {
                    id: "23af6990-c6c0-3922-631f-f19a6e889cd2",
                    columnReferenceName: "groupmatics_dbo_account_firstname",
                    name: "FirstName",
                    displayName: null,
                    visualNameShort: "First Name",
                    visualNameFull: "First Name (New View 9517)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8dabc60d-644f-40e7-a20b-77df1740fcce",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 9517",
                    modelColumnID: "23af6990-c6c0-3922-631f-f19a6e889cd2",
                },
                {
                    id: "edb5f93d-d156-4262-8bd9-a00f5c6c4c1e",
                    columnReferenceName: "groupmatics_dbo_account_gender",
                    name: "Gender",
                    displayName: null,
                    visualNameShort: "Gender",
                    visualNameFull: "Gender (New View 9517)",
                    dataType: "Int",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8dabc60d-644f-40e7-a20b-77df1740fcce",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 9517",
                    modelColumnID: "edb5f93d-d156-4262-8bd9-a00f5c6c4c1e",
                },
                {
                    id: "299c37ce-5a15-b610-8f8e-faca889cb8e1",
                    columnReferenceName: "groupmatics_dbo_account_gender",
                    name: "Gender",
                    displayName: null,
                    visualNameShort: "Gender",
                    visualNameFull: "Gender (New View 9517)",
                    dataType: "Int",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8dabc60d-644f-40e7-a20b-77df1740fcce",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 9517",
                    modelColumnID: "299c37ce-5a15-b610-8f8e-faca889cb8e1",
                },
                {
                    id: "3f67f307-d51c-996b-5b49-bf09fd67cb31",
                    columnReferenceName: "groupmatics_dbo_account_groupage",
                    name: "GroupAge",
                    displayName: null,
                    visualNameShort: "Group Age",
                    visualNameFull: "Group Age (New View 9517)",
                    dataType: "Int",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8dabc60d-644f-40e7-a20b-77df1740fcce",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 9517",
                    modelColumnID: "3f67f307-d51c-996b-5b49-bf09fd67cb31",
                },
                {
                    id: "de528c5a-469f-6121-f562-88855b6d99a6",
                    columnReferenceName: "groupmatics_dbo_account_hashedpassword",
                    name: "HashedPassword",
                    displayName: null,
                    visualNameShort: "Hashed Password",
                    visualNameFull: "Hashed Password (New View 9517)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8dabc60d-644f-40e7-a20b-77df1740fcce",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 9517",
                    modelColumnID: "de528c5a-469f-6121-f562-88855b6d99a6",
                },
                {
                    id: "b615d112-478f-455d-93cc-a1461670b7ca",
                    columnReferenceName: "groupmatics_dbo_account_lastloggedinutc",
                    name: "LastLoggedInUtc",
                    displayName: null,
                    visualNameShort: "Last Logged In Utc",
                    visualNameFull: "Last Logged In Utc (New View 9517)",
                    dataType: "DateTime",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8dabc60d-644f-40e7-a20b-77df1740fcce",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 9517",
                    modelColumnID: "b615d112-478f-455d-93cc-a1461670b7ca",
                },
                {
                    id: "46541f3f-aeb8-6640-1f18-77598db976e8",
                    columnReferenceName: "groupmatics_dbo_account_lastloggedinutc",
                    name: "LastLoggedInUtc",
                    displayName: null,
                    visualNameShort: "Last Logged In Utc",
                    visualNameFull: "Last Logged In Utc (New View 9517)",
                    dataType: "DateTime",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8dabc60d-644f-40e7-a20b-77df1740fcce",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 9517",
                    modelColumnID: "46541f3f-aeb8-6640-1f18-77598db976e8",
                },
                {
                    id: "2db71a68-235a-42af-1e0f-6c5c00632beb",
                    columnReferenceName: "groupmatics_dbo_account_lastname",
                    name: "LastName",
                    displayName: null,
                    visualNameShort: "Last Name",
                    visualNameFull: "Last Name (New View 9517)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8dabc60d-644f-40e7-a20b-77df1740fcce",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 9517",
                    modelColumnID: "2db71a68-235a-42af-1e0f-6c5c00632beb",
                },
                {
                    id: "c26aa983-1459-0260-da91-c58ed93daf16",
                    columnReferenceName: "groupmatics_dbo_account_optout_deadlinepassed",
                    name: "OptOut_DeadlinePassed",
                    displayName: null,
                    visualNameShort: "Opt Out Deadline Passed",
                    visualNameFull: "Opt Out Deadline Passed (New View 9517)",
                    dataType: "Bool",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8dabc60d-644f-40e7-a20b-77df1740fcce",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 9517",
                    modelColumnID: "c26aa983-1459-0260-da91-c58ed93daf16",
                },
                {
                    id: "e2e31cba-6a01-24c5-bca2-81b9aa434e71",
                    columnReferenceName: "groupmatics_dbo_account_optout_groupleadersummary",
                    name: "OptOut_GroupLeaderSummary",
                    displayName: null,
                    visualNameShort: "Opt Out Group Leader Summary",
                    visualNameFull: "Opt Out Group Leader Summary (New View 9517)",
                    dataType: "Bool",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8dabc60d-644f-40e7-a20b-77df1740fcce",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 9517",
                    modelColumnID: "e2e31cba-6a01-24c5-bca2-81b9aa434e71",
                },
                {
                    id: "f2f62014-8252-4e51-9054-f3f29c0a51a3",
                    columnReferenceName: "groupmatics_dbo_account_optout_invitationacceptance",
                    name: "OptOut_InvitationAcceptance",
                    displayName: null,
                    visualNameShort: "Opt Out Invitation Acceptance",
                    visualNameFull: "Opt Out Invitation Acceptance (New View 9517)",
                    dataType: "Bool",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8dabc60d-644f-40e7-a20b-77df1740fcce",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 9517",
                    modelColumnID: "f2f62014-8252-4e51-9054-f3f29c0a51a3",
                },
                {
                    id: "eac62fb9-ae9c-e1ec-d0ac-9e8830fc456e",
                    columnReferenceName: "groupmatics_dbo_account_optout_lowticketwarning",
                    name: "OptOut_LowTicketWarning",
                    displayName: null,
                    visualNameShort: "Opt Out Low Ticket Warning",
                    visualNameFull: "Opt Out Low Ticket Warning (New View 9517)",
                    dataType: "Bool",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8dabc60d-644f-40e7-a20b-77df1740fcce",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 9517",
                    modelColumnID: "eac62fb9-ae9c-e1ec-d0ac-9e8830fc456e",
                },
                {
                    id: "763b3d9c-b5fa-469a-85f6-b7c19bdf1adc",
                    columnReferenceName: "groupmatics_dbo_account_optout_orderadjustment",
                    name: "OptOut_OrderAdjustment",
                    displayName: null,
                    visualNameShort: "Opt Out Order Adjustment",
                    visualNameFull: "Opt Out Order Adjustment (New View 9517)",
                    dataType: "Bool",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8dabc60d-644f-40e7-a20b-77df1740fcce",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 9517",
                    modelColumnID: "763b3d9c-b5fa-469a-85f6-b7c19bdf1adc",
                },
                {
                    id: "8f538cca-2db3-a305-18f4-56f6506301bf",
                    columnReferenceName: "groupmatics_dbo_account_optout_orderadjustment",
                    name: "OptOut_OrderAdjustment",
                    displayName: null,
                    visualNameShort: "Opt Out Order Adjustment",
                    visualNameFull: "Opt Out Order Adjustment (New View 9517)",
                    dataType: "Bool",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8dabc60d-644f-40e7-a20b-77df1740fcce",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 9517",
                    modelColumnID: "8f538cca-2db3-a305-18f4-56f6506301bf",
                },
                {
                    id: "66831dcc-930d-377e-da90-f51ce748678d",
                    columnReferenceName: "groupmatics_dbo_account_optout_orderconfirmations",
                    name: "OptOut_OrderConfirmations",
                    displayName: null,
                    visualNameShort: "Opt Out Order Confirmations",
                    visualNameFull: "Opt Out Order Confirmations (New View 9517)",
                    dataType: "Bool",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8dabc60d-644f-40e7-a20b-77df1740fcce",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 9517",
                    modelColumnID: "66831dcc-930d-377e-da90-f51ce748678d",
                },
                {
                    id: "e31bce6a-aaad-1c01-f4bf-7ab0731bc65c",
                    columnReferenceName: "groupmatics_dbo_account_optout_salesmanagersummary",
                    name: "OptOut_SalesManagerSummary",
                    displayName: null,
                    visualNameShort: "Opt Out Sales Manager Summary",
                    visualNameFull: "Opt Out Sales Manager Summary (New View 9517)",
                    dataType: "Bool",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8dabc60d-644f-40e7-a20b-77df1740fcce",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 9517",
                    modelColumnID: "e31bce6a-aaad-1c01-f4bf-7ab0731bc65c",
                },
                {
                    id: "425349fe-a1f9-263d-2a19-1b523f72aa41",
                    columnReferenceName: "groupmatics_dbo_account_optout_ticketchecker",
                    name: "OptOut_TicketChecker",
                    displayName: null,
                    visualNameShort: "Opt Out Ticket Checker",
                    visualNameFull: "Opt Out Ticket Checker (New View 9517)",
                    dataType: "Bool",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8dabc60d-644f-40e7-a20b-77df1740fcce",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 9517",
                    modelColumnID: "425349fe-a1f9-263d-2a19-1b523f72aa41",
                },
                {
                    id: "4e20ac9f-1085-a192-748b-d4e1cc0c371f",
                    columnReferenceName: "groupmatics_dbo_account_passwordresettoken",
                    name: "PasswordResetToken",
                    displayName: null,
                    visualNameShort: "Password Reset Token",
                    visualNameFull: "Password Reset Token (New View 9517)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8dabc60d-644f-40e7-a20b-77df1740fcce",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 9517",
                    modelColumnID: "4e20ac9f-1085-a192-748b-d4e1cc0c371f",
                },
                {
                    id: "44ebd579-6507-2006-db36-9eb0a94d47ed",
                    columnReferenceName: "groupmatics_dbo_account_passwordresettokenexpirationdate",
                    name: "PasswordResetTokenExpirationDate",
                    displayName: null,
                    visualNameShort: "Password Reset Token Expiration Date",
                    visualNameFull: "Password Reset Token Expiration Date (New View 9517)",
                    dataType: "DateTime",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8dabc60d-644f-40e7-a20b-77df1740fcce",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 9517",
                    modelColumnID: "44ebd579-6507-2006-db36-9eb0a94d47ed",
                },
                {
                    id: "bdbef8bf-d81c-4aa1-8c3a-01859a34b3cb",
                    columnReferenceName: "groupmatics_dbo_account_phone",
                    name: "Phone",
                    displayName: null,
                    visualNameShort: "Phone",
                    visualNameFull: "Phone (New View 9517)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8dabc60d-644f-40e7-a20b-77df1740fcce",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 9517",
                    modelColumnID: "bdbef8bf-d81c-4aa1-8c3a-01859a34b3cb",
                },
                {
                    id: "8b32b12f-f81c-c9f3-deb3-e2ffed3c95c9",
                    columnReferenceName: "groupmatics_dbo_account_phone",
                    name: "Phone",
                    displayName: null,
                    visualNameShort: "Phone",
                    visualNameFull: "Phone (New View 9517)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8dabc60d-644f-40e7-a20b-77df1740fcce",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 9517",
                    modelColumnID: "8b32b12f-f81c-c9f3-deb3-e2ffed3c95c9",
                },
                {
                    id: "e5c63bc6-aa3b-a127-9169-2a049769c761",
                    columnReferenceName: "groupmatics_dbo_account_randomcode",
                    name: "RandomCode",
                    displayName: null,
                    visualNameShort: "Random Code",
                    visualNameFull: "Random Code (New View 9517)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8dabc60d-644f-40e7-a20b-77df1740fcce",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 9517",
                    modelColumnID: "e5c63bc6-aa3b-a127-9169-2a049769c761",
                },
                {
                    id: "4dc69346-b823-af0a-53be-9105bb2b090f",
                    columnReferenceName: "groupmatics_dbo_account_randomcodeexpirationdate",
                    name: "RandomCodeExpirationDate",
                    displayName: null,
                    visualNameShort: "Random Code Expiration Date",
                    visualNameFull: "Random Code Expiration Date (New View 9517)",
                    dataType: "DateTime",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8dabc60d-644f-40e7-a20b-77df1740fcce",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 9517",
                    modelColumnID: "4dc69346-b823-af0a-53be-9105bb2b090f",
                },
                {
                    id: "78c345cc-51f6-8942-916c-119bce69282d",
                    columnReferenceName: "groupmatics_dbo_account_socialavatar",
                    name: "SocialAvatar",
                    displayName: null,
                    visualNameShort: "Social Avatar",
                    visualNameFull: "Social Avatar (New View 9517)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8dabc60d-644f-40e7-a20b-77df1740fcce",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 9517",
                    modelColumnID: "78c345cc-51f6-8942-916c-119bce69282d",
                },
                {
                    id: "0d9c883e-5d1a-c8f7-0cfd-195bb60af0ab",
                    columnReferenceName: "groupmatics_dbo_account_status",
                    name: "Status",
                    displayName: null,
                    visualNameShort: "Status",
                    visualNameFull: "Status (New View 9517)",
                    dataType: "Int",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8dabc60d-644f-40e7-a20b-77df1740fcce",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 9517",
                    modelColumnID: "0d9c883e-5d1a-c8f7-0cfd-195bb60af0ab",
                },
                {
                    id: "586b146a-06f3-4600-884d-4894b0856bd2",
                    columnReferenceName: "groupmatics_dbo_account_username",
                    name: "Username",
                    displayName: null,
                    visualNameShort: "Username",
                    visualNameFull: "Username (New View 9517)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8dabc60d-644f-40e7-a20b-77df1740fcce",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 9517",
                    modelColumnID: "586b146a-06f3-4600-884d-4894b0856bd2",
                },
                {
                    id: "ea5e82b1-bea0-a254-7d16-703b0daa5479",
                    columnReferenceName: "groupmatics_dbo_account_username",
                    name: "Username",
                    displayName: null,
                    visualNameShort: "Username",
                    visualNameFull: "Username (New View 9517)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "8dabc60d-644f-40e7-a20b-77df1740fcce",
                    modelTableType: "ViewModelTable",
                    modelTableName: "New View 9517",
                    modelColumnID: "ea5e82b1-bea0-a254-7d16-703b0daa5479",
                },
            ],
            rowLevelSecurity: {},
            position: {
                x: 459,
                y: 312.5,
            },
            $type: "ViewModelTable",
        },
        {
            id: "34ca5a70-0ac4-4b68-a3c0-17266e7bc391",
            transformationID: "c5c978b3-4f18-4895-8752-7de1996b9ff0",
            tableReferenceName: "verbview_c5c978b3_4f18_4895_8752_7de1996b9ff0_v_210805052600898",
            versionDetails: "LatestNotPublished",
            publishedOn: "2021-08-05T05:26:01.692Z",
            excludeFromModel: false,
            name: "Oleg Tran Pub Test",
            visualName: "Oleg Tran Pub Test",
            columns: [
                {
                    id: "7ba94cda-90b8-b1c8-5662-d4694e8f514e",
                    columnReferenceName: "groupmatics_dbo_account_accountid",
                    name: "AccountID",
                    displayName: null,
                    visualNameShort: "Account ID",
                    visualNameFull: "Account ID (Oleg Tran Pub Test)",
                    dataType: "IDUUID",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "34ca5a70-0ac4-4b68-a3c0-17266e7bc391",
                    modelTableType: "ViewModelTable",
                    modelTableName: "Oleg Tran Pub Test",
                    modelColumnID: "7ba94cda-90b8-b1c8-5662-d4694e8f514e",
                },
                {
                    id: "c7572e0b-f869-1599-83a4-924cda675849",
                    columnReferenceName: "groupmatics_dbo_account_adminaccess",
                    name: "AdminAccess",
                    displayName: null,
                    visualNameShort: "Admin Access",
                    visualNameFull: "Admin Access (Oleg Tran Pub Test)",
                    dataType: "Int",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "34ca5a70-0ac4-4b68-a3c0-17266e7bc391",
                    modelTableType: "ViewModelTable",
                    modelTableName: "Oleg Tran Pub Test",
                    modelColumnID: "c7572e0b-f869-1599-83a4-924cda675849",
                },
                {
                    id: "bde8a2d3-4d41-a8f3-b43f-310fcc4de4c7",
                    columnReferenceName: "verbcol_code_key",
                    name: "Code Key",
                    displayName: null,
                    visualNameShort: "Code Key",
                    visualNameFull: "Code Key (Oleg Tran Pub Test)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "34ca5a70-0ac4-4b68-a3c0-17266e7bc391",
                    modelTableType: "ViewModelTable",
                    modelTableName: "Oleg Tran Pub Test",
                    modelColumnID: "bde8a2d3-4d41-a8f3-b43f-310fcc4de4c7",
                },
                {
                    id: "f2cebe2f-5012-f6d3-781d-8b2e492c383c",
                    columnReferenceName: "groupmatics_dbo_account_datecreated",
                    name: "DateCreated",
                    displayName: null,
                    visualNameShort: "Date Created",
                    visualNameFull: "Date Created (Oleg Tran Pub Test)",
                    dataType: "DateTime",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "34ca5a70-0ac4-4b68-a3c0-17266e7bc391",
                    modelTableType: "ViewModelTable",
                    modelTableName: "Oleg Tran Pub Test",
                    modelColumnID: "f2cebe2f-5012-f6d3-781d-8b2e492c383c",
                },
                {
                    id: "0b9dde17-bfe8-1d02-0646-5d0f2bda6c2a",
                    columnReferenceName: "groupmatics_dbo_account_dateofbirth",
                    name: "DateOfBirth",
                    displayName: null,
                    visualNameShort: "Date Of Birth",
                    visualNameFull: "Date Of Birth (Oleg Tran Pub Test)",
                    dataType: "DateTime",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "34ca5a70-0ac4-4b68-a3c0-17266e7bc391",
                    modelTableType: "ViewModelTable",
                    modelTableName: "Oleg Tran Pub Test",
                    modelColumnID: "0b9dde17-bfe8-1d02-0646-5d0f2bda6c2a",
                },
                {
                    id: "04ed6e14-ff83-5c86-6fab-67f046adce94",
                    columnReferenceName: "groupmatics_dbo_account_email",
                    name: "Email",
                    displayName: null,
                    visualNameShort: "Email",
                    visualNameFull: "Email (Oleg Tran Pub Test)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "34ca5a70-0ac4-4b68-a3c0-17266e7bc391",
                    modelTableType: "ViewModelTable",
                    modelTableName: "Oleg Tran Pub Test",
                    modelColumnID: "04ed6e14-ff83-5c86-6fab-67f046adce94",
                },
                {
                    id: "94e484a6-628f-76e4-6f3e-ce7c9bbd1ce5",
                    columnReferenceName: "groupmatics_dbo_account_firstname",
                    name: "FirstName",
                    displayName: null,
                    visualNameShort: "First Name",
                    visualNameFull: "First Name (Oleg Tran Pub Test)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "34ca5a70-0ac4-4b68-a3c0-17266e7bc391",
                    modelTableType: "ViewModelTable",
                    modelTableName: "Oleg Tran Pub Test",
                    modelColumnID: "94e484a6-628f-76e4-6f3e-ce7c9bbd1ce5",
                },
                {
                    id: "6687ee88-d6c9-58e2-10ba-2623c5c1bde3",
                    columnReferenceName: "groupmatics_dbo_account_gender",
                    name: "Gender",
                    displayName: null,
                    visualNameShort: "Gender",
                    visualNameFull: "Gender (Oleg Tran Pub Test)",
                    dataType: "Int",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "34ca5a70-0ac4-4b68-a3c0-17266e7bc391",
                    modelTableType: "ViewModelTable",
                    modelTableName: "Oleg Tran Pub Test",
                    modelColumnID: "6687ee88-d6c9-58e2-10ba-2623c5c1bde3",
                },
                {
                    id: "c3a8c154-e942-ef79-ef97-006f6a54f6f4",
                    columnReferenceName: "groupmatics_dbo_account_groupage",
                    name: "GroupAge",
                    displayName: null,
                    visualNameShort: "Group Age",
                    visualNameFull: "Group Age (Oleg Tran Pub Test)",
                    dataType: "Int",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "34ca5a70-0ac4-4b68-a3c0-17266e7bc391",
                    modelTableType: "ViewModelTable",
                    modelTableName: "Oleg Tran Pub Test",
                    modelColumnID: "c3a8c154-e942-ef79-ef97-006f6a54f6f4",
                },
                {
                    id: "4aec2dd2-0249-53f1-013e-364f8a930055",
                    columnReferenceName: "groupmatics_dbo_account_hashedpassword",
                    name: "HashedPassword",
                    displayName: null,
                    visualNameShort: "Hashed Password",
                    visualNameFull: "Hashed Password (Oleg Tran Pub Test)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "34ca5a70-0ac4-4b68-a3c0-17266e7bc391",
                    modelTableType: "ViewModelTable",
                    modelTableName: "Oleg Tran Pub Test",
                    modelColumnID: "4aec2dd2-0249-53f1-013e-364f8a930055",
                },
                {
                    id: "fb53f113-f892-afc4-1ab0-be56d08bbe05",
                    columnReferenceName: "groupmatics_dbo_account_lastloggedinutc",
                    name: "LastLoggedInUtc",
                    displayName: null,
                    visualNameShort: "Last Logged In Utc",
                    visualNameFull: "Last Logged In Utc (Oleg Tran Pub Test)",
                    dataType: "DateTime",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "34ca5a70-0ac4-4b68-a3c0-17266e7bc391",
                    modelTableType: "ViewModelTable",
                    modelTableName: "Oleg Tran Pub Test",
                    modelColumnID: "fb53f113-f892-afc4-1ab0-be56d08bbe05",
                },
                {
                    id: "0dc417b5-fb53-f44c-fcb9-e79f16d12856",
                    columnReferenceName: "groupmatics_dbo_account_lastname",
                    name: "LastName",
                    displayName: null,
                    visualNameShort: "Last Name",
                    visualNameFull: "Last Name (Oleg Tran Pub Test)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "34ca5a70-0ac4-4b68-a3c0-17266e7bc391",
                    modelTableType: "ViewModelTable",
                    modelTableName: "Oleg Tran Pub Test",
                    modelColumnID: "0dc417b5-fb53-f44c-fcb9-e79f16d12856",
                },
                {
                    id: "5bebd062-be32-270b-892f-c0c72f40285c",
                    columnReferenceName: "groupmatics_dbo_account_optout_deadlinepassed",
                    name: "OptOut_DeadlinePassed",
                    displayName: null,
                    visualNameShort: "Opt Out Deadline Passed",
                    visualNameFull: "Opt Out Deadline Passed (Oleg Tran Pub Test)",
                    dataType: "Bool",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "34ca5a70-0ac4-4b68-a3c0-17266e7bc391",
                    modelTableType: "ViewModelTable",
                    modelTableName: "Oleg Tran Pub Test",
                    modelColumnID: "5bebd062-be32-270b-892f-c0c72f40285c",
                },
                {
                    id: "ffadf253-46c8-7e2f-91be-a11d33d5a4ce",
                    columnReferenceName: "groupmatics_dbo_account_optout_groupleadersummary",
                    name: "OptOut_GroupLeaderSummary",
                    displayName: null,
                    visualNameShort: "Opt Out Group Leader Summary",
                    visualNameFull: "Opt Out Group Leader Summary (Oleg Tran Pub Test)",
                    dataType: "Bool",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "34ca5a70-0ac4-4b68-a3c0-17266e7bc391",
                    modelTableType: "ViewModelTable",
                    modelTableName: "Oleg Tran Pub Test",
                    modelColumnID: "ffadf253-46c8-7e2f-91be-a11d33d5a4ce",
                },
                {
                    id: "d65be661-d609-f2bc-feb8-e8fac36d2d76",
                    columnReferenceName: "groupmatics_dbo_account_optout_invitationacceptance",
                    name: "OptOut_InvitationAcceptance",
                    displayName: null,
                    visualNameShort: "Opt Out Invitation Acceptance",
                    visualNameFull: "Opt Out Invitation Acceptance (Oleg Tran Pub Test)",
                    dataType: "Bool",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "34ca5a70-0ac4-4b68-a3c0-17266e7bc391",
                    modelTableType: "ViewModelTable",
                    modelTableName: "Oleg Tran Pub Test",
                    modelColumnID: "d65be661-d609-f2bc-feb8-e8fac36d2d76",
                },
                {
                    id: "ebcad064-b03a-09f1-23b1-d3a2121e279a",
                    columnReferenceName: "groupmatics_dbo_account_optout_lowticketwarning",
                    name: "OptOut_LowTicketWarning",
                    displayName: null,
                    visualNameShort: "Opt Out Low Ticket Warning",
                    visualNameFull: "Opt Out Low Ticket Warning (Oleg Tran Pub Test)",
                    dataType: "Bool",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "34ca5a70-0ac4-4b68-a3c0-17266e7bc391",
                    modelTableType: "ViewModelTable",
                    modelTableName: "Oleg Tran Pub Test",
                    modelColumnID: "ebcad064-b03a-09f1-23b1-d3a2121e279a",
                },
                {
                    id: "5c864647-0270-40c1-8c4e-ccccbbca766c",
                    columnReferenceName: "groupmatics_dbo_account_optout_orderadjustment",
                    name: "OptOut_OrderAdjustment",
                    displayName: null,
                    visualNameShort: "Opt Out Order Adjustment",
                    visualNameFull: "Opt Out Order Adjustment (Oleg Tran Pub Test)",
                    dataType: "Bool",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "34ca5a70-0ac4-4b68-a3c0-17266e7bc391",
                    modelTableType: "ViewModelTable",
                    modelTableName: "Oleg Tran Pub Test",
                    modelColumnID: "5c864647-0270-40c1-8c4e-ccccbbca766c",
                },
                {
                    id: "6fd39386-e049-f4ed-44e3-1f4612441c51",
                    columnReferenceName: "groupmatics_dbo_account_optout_orderconfirmations",
                    name: "OptOut_OrderConfirmations",
                    displayName: null,
                    visualNameShort: "Opt Out Order Confirmations",
                    visualNameFull: "Opt Out Order Confirmations (Oleg Tran Pub Test)",
                    dataType: "Bool",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "34ca5a70-0ac4-4b68-a3c0-17266e7bc391",
                    modelTableType: "ViewModelTable",
                    modelTableName: "Oleg Tran Pub Test",
                    modelColumnID: "6fd39386-e049-f4ed-44e3-1f4612441c51",
                },
                {
                    id: "f43a42a7-8830-2592-9642-97b9fa2757eb",
                    columnReferenceName: "groupmatics_dbo_account_optout_salesmanagersummary",
                    name: "OptOut_SalesManagerSummary",
                    displayName: null,
                    visualNameShort: "Opt Out Sales Manager Summary",
                    visualNameFull: "Opt Out Sales Manager Summary (Oleg Tran Pub Test)",
                    dataType: "Bool",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "34ca5a70-0ac4-4b68-a3c0-17266e7bc391",
                    modelTableType: "ViewModelTable",
                    modelTableName: "Oleg Tran Pub Test",
                    modelColumnID: "f43a42a7-8830-2592-9642-97b9fa2757eb",
                },
                {
                    id: "1c353753-1572-5a31-b8e4-a8fc031c6c6b",
                    columnReferenceName: "groupmatics_dbo_account_optout_ticketchecker",
                    name: "OptOut_TicketChecker",
                    displayName: null,
                    visualNameShort: "Opt Out Ticket Checker",
                    visualNameFull: "Opt Out Ticket Checker (Oleg Tran Pub Test)",
                    dataType: "Bool",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "34ca5a70-0ac4-4b68-a3c0-17266e7bc391",
                    modelTableType: "ViewModelTable",
                    modelTableName: "Oleg Tran Pub Test",
                    modelColumnID: "1c353753-1572-5a31-b8e4-a8fc031c6c6b",
                },
                {
                    id: "17811891-8950-73e5-2ac8-67ca19134f6a",
                    columnReferenceName: "groupmatics_dbo_account_passwordresettoken",
                    name: "PasswordResetToken",
                    displayName: null,
                    visualNameShort: "Password Reset Token",
                    visualNameFull: "Password Reset Token (Oleg Tran Pub Test)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "34ca5a70-0ac4-4b68-a3c0-17266e7bc391",
                    modelTableType: "ViewModelTable",
                    modelTableName: "Oleg Tran Pub Test",
                    modelColumnID: "17811891-8950-73e5-2ac8-67ca19134f6a",
                },
                {
                    id: "5058acec-0864-0811-d7b2-8c2cf571d701",
                    columnReferenceName: "groupmatics_dbo_account_passwordresettokenexpirationdate",
                    name: "PasswordResetTokenExpirationDate",
                    displayName: null,
                    visualNameShort: "Password Reset Token Expiration Date",
                    visualNameFull: "Password Reset Token Expiration Date (Oleg Tran Pub Test)",
                    dataType: "DateTime",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "34ca5a70-0ac4-4b68-a3c0-17266e7bc391",
                    modelTableType: "ViewModelTable",
                    modelTableName: "Oleg Tran Pub Test",
                    modelColumnID: "5058acec-0864-0811-d7b2-8c2cf571d701",
                },
                {
                    id: "b7a4a93e-479c-bfd7-74c8-a9173660d8ab",
                    columnReferenceName: "groupmatics_dbo_account_phone",
                    name: "Phone",
                    displayName: null,
                    visualNameShort: "Phone",
                    visualNameFull: "Phone (Oleg Tran Pub Test)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "34ca5a70-0ac4-4b68-a3c0-17266e7bc391",
                    modelTableType: "ViewModelTable",
                    modelTableName: "Oleg Tran Pub Test",
                    modelColumnID: "b7a4a93e-479c-bfd7-74c8-a9173660d8ab",
                },
                {
                    id: "8d5126b0-d805-5bfb-7196-ea45c2658749",
                    columnReferenceName: "groupmatics_dbo_account_randomcode",
                    name: "RandomCode",
                    displayName: null,
                    visualNameShort: "Random Code",
                    visualNameFull: "Random Code (Oleg Tran Pub Test)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "34ca5a70-0ac4-4b68-a3c0-17266e7bc391",
                    modelTableType: "ViewModelTable",
                    modelTableName: "Oleg Tran Pub Test",
                    modelColumnID: "8d5126b0-d805-5bfb-7196-ea45c2658749",
                },
                {
                    id: "61781f25-66ca-1d1f-6242-f7995c64a17c",
                    columnReferenceName: "groupmatics_dbo_account_randomcodeexpirationdate",
                    name: "RandomCodeExpirationDate",
                    displayName: null,
                    visualNameShort: "Random Code Expiration Date",
                    visualNameFull: "Random Code Expiration Date (Oleg Tran Pub Test)",
                    dataType: "DateTime",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "34ca5a70-0ac4-4b68-a3c0-17266e7bc391",
                    modelTableType: "ViewModelTable",
                    modelTableName: "Oleg Tran Pub Test",
                    modelColumnID: "61781f25-66ca-1d1f-6242-f7995c64a17c",
                },
                {
                    id: "0d9bbacd-0a95-281b-71ad-c7205f4c360e",
                    columnReferenceName: "groupmatics_dbo_account_socialavatar",
                    name: "SocialAvatar",
                    displayName: null,
                    visualNameShort: "Social Avatar",
                    visualNameFull: "Social Avatar (Oleg Tran Pub Test)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "34ca5a70-0ac4-4b68-a3c0-17266e7bc391",
                    modelTableType: "ViewModelTable",
                    modelTableName: "Oleg Tran Pub Test",
                    modelColumnID: "0d9bbacd-0a95-281b-71ad-c7205f4c360e",
                },
                {
                    id: "115d40c5-fa0b-8a8c-6b33-19920e192229",
                    columnReferenceName: "groupmatics_dbo_account_status",
                    name: "Status",
                    displayName: null,
                    visualNameShort: "Status",
                    visualNameFull: "Status (Oleg Tran Pub Test)",
                    dataType: "Int",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "34ca5a70-0ac4-4b68-a3c0-17266e7bc391",
                    modelTableType: "ViewModelTable",
                    modelTableName: "Oleg Tran Pub Test",
                    modelColumnID: "115d40c5-fa0b-8a8c-6b33-19920e192229",
                },
                {
                    id: "f9b5b18a-485e-f023-5137-f34c8d24dcc3",
                    columnReferenceName: "groupmatics_dbo_account_username",
                    name: "Username",
                    displayName: null,
                    visualNameShort: "Username",
                    visualNameFull: "Username (Oleg Tran Pub Test)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "34ca5a70-0ac4-4b68-a3c0-17266e7bc391",
                    modelTableType: "ViewModelTable",
                    modelTableName: "Oleg Tran Pub Test",
                    modelColumnID: "f9b5b18a-485e-f023-5137-f34c8d24dcc3",
                },
            ],
            rowLevelSecurity: {},
            position: {
                x: -2091,
                y: 732.5,
            },
            $type: "ViewModelTable",
        },
        {
            id: "441d9abb-1695-41a4-b904-0bca100d5a9e",
            dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
            dataSourceTableID: "a284da36-c7fd-4dee-8891-982bed9c90bd",
            name: "Order",
            visualName: "Order",
            columns: [
                {
                    id: "70950103-ccd1-4252-ab48-cac39c1e3329",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "a284da36-c7fd-4dee-8891-982bed9c90bd",
                    dataSourceColumnID: "225a71fd-0969-4a25-a4cb-3fcc79951f8b",
                    dataType: "IDUUID",
                    name: "OrderID",
                    displayName: null,
                    visualNameShort: "Order ID",
                    visualNameFull: "Order ID (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    modelColumnID: "70950103-ccd1-4252-ab48-cac39c1e3329",
                },
                {
                    id: "6d20e685-5a80-40f2-a7df-742ac10180fc",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "a284da36-c7fd-4dee-8891-982bed9c90bd",
                    dataSourceColumnID: "734707e0-e483-48f3-9d4c-dc40fe4d970b",
                    dataType: "IDUUID",
                    name: "AccountID",
                    displayName: null,
                    visualNameShort: "Account ID",
                    visualNameFull: "Account ID (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    modelColumnID: "6d20e685-5a80-40f2-a7df-742ac10180fc",
                },
                {
                    id: "266bce35-7568-4489-a616-8aac7d93e04c",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "a284da36-c7fd-4dee-8891-982bed9c90bd",
                    dataSourceColumnID: "a4702b26-d916-4772-878f-dfb1874fb5ef",
                    dataType: "Text",
                    name: "CustomerIP",
                    displayName: null,
                    visualNameShort: "Customer IP",
                    visualNameFull: "Customer IP (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    modelColumnID: "266bce35-7568-4489-a616-8aac7d93e04c",
                },
                {
                    id: "5035cb8a-55ef-45e5-9772-4a2a019d89b4",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "a284da36-c7fd-4dee-8891-982bed9c90bd",
                    dataSourceColumnID: "1bd31ec6-dad2-404b-9489-49c41e499021",
                    dataType: "DateTime",
                    name: "DatePlacedUtc",
                    displayName: null,
                    visualNameShort: "Date Placed Utc",
                    visualNameFull: "Date Placed Utc (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    modelColumnID: "5035cb8a-55ef-45e5-9772-4a2a019d89b4",
                },
                {
                    id: "4a93b696-5a5a-3009-580c-1e38621ae1af",
                    calculations: [
                        {
                            transformationID: "234e5c3a-c33a-487e-b4fb-743ae394f43a",
                            name: "Date placed UTC + 1 month",
                            default: true,
                            viewReferenceName: "verbcalccol_234e5c3a_c33a_487e_b4fb_743ae394f43a_v_0",
                            columnReferenceName: null,
                            versionDetails: "NotPublished",
                            publishedOn: null,
                        },
                    ],
                    dataType: "Unsupported",
                    name: "Date placed UTC + 1 month",
                    displayName: null,
                    visualNameShort: "Date Placed UTC 1 Month",
                    visualNameFull: "Date Placed UTC 1 Month (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "4a93b696-5a5a-3009-580c-1e38621ae1af",
                },
                {
                    id: "8242ac1e-de4f-40ac-9cb9-8e5f9f0eb7a9",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "a284da36-c7fd-4dee-8891-982bed9c90bd",
                    dataSourceColumnID: "77141949-2875-4731-80a0-59b80d68fd8d",
                    dataType: "Int",
                    name: "DeliveryMethod",
                    displayName: null,
                    visualNameShort: "Delivery Method",
                    visualNameFull: "Delivery Method (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    modelColumnID: "8242ac1e-de4f-40ac-9cb9-8e5f9f0eb7a9",
                },
                {
                    id: "938a7d9a-e165-4bc0-b5ac-8cb7d1ebd6a5",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "a284da36-c7fd-4dee-8891-982bed9c90bd",
                    dataSourceColumnID: "f07fe46d-a439-44c1-8df7-98f74288b72f",
                    dataType: "Double",
                    name: "Donation",
                    displayName: null,
                    visualNameShort: "Donation",
                    visualNameFull: "Donation (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    modelColumnID: "938a7d9a-e165-4bc0-b5ac-8cb7d1ebd6a5",
                },
                {
                    id: "313a30ab-e81c-4c99-b869-1d5f9178a174",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "a284da36-c7fd-4dee-8891-982bed9c90bd",
                    dataSourceColumnID: "7d019450-f627-4fe6-9ee4-e6a8c5468a3b",
                    dataType: "IDUUID",
                    name: "EmailInvitationID",
                    displayName: null,
                    visualNameShort: "Email Invitation ID",
                    visualNameFull: "Email Invitation ID (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    modelColumnID: "313a30ab-e81c-4c99-b869-1d5f9178a174",
                },
                {
                    id: "af3c989a-df1b-457a-aee9-b5fb6b64cfd8",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "a284da36-c7fd-4dee-8891-982bed9c90bd",
                    dataSourceColumnID: "b0f49212-8a07-4ba0-b3f4-af44a8ad1079",
                    dataType: "Text",
                    name: "EventServiceCustomerID",
                    displayName: null,
                    visualNameShort: "Event Service Customer ID",
                    visualNameFull: "Event Service Customer ID (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    modelColumnID: "af3c989a-df1b-457a-aee9-b5fb6b64cfd8",
                },
                {
                    id: "633d54e2-ee89-4019-b394-03bc367cb1b7",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "a284da36-c7fd-4dee-8891-982bed9c90bd",
                    dataSourceColumnID: "e67bab4f-16c0-4119-a557-c2bc26aee590",
                    dataType: "Bool",
                    name: "EventServiceHasPdfTickets",
                    displayName: null,
                    visualNameShort: "Event Service Has Pdf Tickets",
                    visualNameFull: "Event Service Has Pdf Tickets (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    modelColumnID: "633d54e2-ee89-4019-b394-03bc367cb1b7",
                },
                {
                    id: "c58df95f-6665-43a8-8574-81e3089ab297",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "a284da36-c7fd-4dee-8891-982bed9c90bd",
                    dataSourceColumnID: "1cb3f385-3f64-48ed-b03f-1c22764f49fd",
                    dataType: "Bool",
                    name: "EventServiceHideGMTickets",
                    displayName: null,
                    visualNameShort: "Event Service Hide GM Tickets",
                    visualNameFull: "Event Service Hide GM Tickets (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    modelColumnID: "c58df95f-6665-43a8-8574-81e3089ab297",
                },
                {
                    id: "7afdd2b0-ac6d-4d14-a071-61bd3fc8eb58",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "a284da36-c7fd-4dee-8891-982bed9c90bd",
                    dataSourceColumnID: "3cdff5ed-7811-4c23-8161-99a8e8188ea7",
                    dataType: "Bool",
                    name: "EventServiceNewCustomer",
                    displayName: null,
                    visualNameShort: "Event Service New Customer",
                    visualNameFull: "Event Service New Customer (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    modelColumnID: "7afdd2b0-ac6d-4d14-a071-61bd3fc8eb58",
                },
                {
                    id: "6b347611-da74-426d-ad71-5c54cdca10e0",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "a284da36-c7fd-4dee-8891-982bed9c90bd",
                    dataSourceColumnID: "9aaee498-03c5-458b-b124-fae556191aa5",
                    dataType: "Text",
                    name: "EventServiceOrderID",
                    displayName: null,
                    visualNameShort: "Event Service Order ID",
                    visualNameFull: "Event Service Order ID (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    modelColumnID: "6b347611-da74-426d-ad71-5c54cdca10e0",
                },
                {
                    id: "2abe4d75-c979-47a9-ac28-8b1ffa7998be",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "a284da36-c7fd-4dee-8891-982bed9c90bd",
                    dataSourceColumnID: "0e63d55d-c4ec-4f24-a59c-c933e6c0ca2c",
                    dataType: "Text",
                    name: "EventServiceTemporaryPassword",
                    displayName: null,
                    visualNameShort: "Event Service Temporary Password",
                    visualNameFull: "Event Service Temporary Password (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    modelColumnID: "2abe4d75-c979-47a9-ac28-8b1ffa7998be",
                },
                {
                    id: "d56505b9-d5c3-40bf-80e7-cfa05dd4016c",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "a284da36-c7fd-4dee-8891-982bed9c90bd",
                    dataSourceColumnID: "9076c0b2-27c3-4f31-9e29-5753d4153bf0",
                    dataType: "Int",
                    name: "EventServiceTicketProvider",
                    displayName: null,
                    visualNameShort: "Event Service Ticket Provider",
                    visualNameFull: "Event Service Ticket Provider (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    modelColumnID: "d56505b9-d5c3-40bf-80e7-cfa05dd4016c",
                },
                {
                    id: "2b86bae5-728f-4a43-b816-a52d07f6e90c",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "a284da36-c7fd-4dee-8891-982bed9c90bd",
                    dataSourceColumnID: "1940093c-694d-4efe-9878-6e87fa0a94b8",
                    dataType: "Text",
                    name: "ExpGroup",
                    displayName: null,
                    visualNameShort: "Exp Group",
                    visualNameFull: "Exp Group (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    modelColumnID: "2b86bae5-728f-4a43-b816-a52d07f6e90c",
                },
                {
                    id: "221ff3ab-d727-46c5-a178-ca5856c52ef4",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "a284da36-c7fd-4dee-8891-982bed9c90bd",
                    dataSourceColumnID: "d1928e9e-0c25-4c3f-bb18-a402a42cafbf",
                    dataType: "DateTime",
                    name: "ExpirationDateUtc",
                    displayName: null,
                    visualNameShort: "Expiration Date Utc",
                    visualNameFull: "Expiration Date Utc (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    modelColumnID: "221ff3ab-d727-46c5-a178-ca5856c52ef4",
                },
                {
                    id: "48a106a4-074a-45af-b01a-1cddf52da60a",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "a284da36-c7fd-4dee-8891-982bed9c90bd",
                    dataSourceColumnID: "4636e5dd-5319-4de2-8146-aec0462f9a2f",
                    dataType: "Double",
                    name: "FeesAtCheckout",
                    displayName: null,
                    visualNameShort: "Fees At Checkout",
                    visualNameFull: "Fees At Checkout (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    modelColumnID: "48a106a4-074a-45af-b01a-1cddf52da60a",
                },
                {
                    id: "87cdc4c5-aa8f-4ef8-a0cd-1df2a0dd7940",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "a284da36-c7fd-4dee-8891-982bed9c90bd",
                    dataSourceColumnID: "4b092ab6-7e19-4df2-a062-c45617c189fa",
                    dataType: "Double",
                    name: "FeesAtPayout",
                    displayName: null,
                    visualNameShort: "Fees At Payout",
                    visualNameFull: "Fees At Payout (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    modelColumnID: "87cdc4c5-aa8f-4ef8-a0cd-1df2a0dd7940",
                },
                {
                    id: "f9737f6d-1aa9-42ee-be1b-809b8d19da45",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "a284da36-c7fd-4dee-8891-982bed9c90bd",
                    dataSourceColumnID: "27ca4763-8112-4ad5-b1b6-e283693f472e",
                    dataType: "Double",
                    name: "FlatTaxes",
                    displayName: null,
                    visualNameShort: "Flat Taxes",
                    visualNameFull: "Flat Taxes (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    modelColumnID: "f9737f6d-1aa9-42ee-be1b-809b8d19da45",
                },
                {
                    id: "d5d4d764-425d-e926-14c7-c124b79a4f92",
                    calculations: [],
                    dataType: "Unsupported",
                    name: "New Column 1487",
                    displayName: null,
                    visualNameShort: "New Column 1487",
                    visualNameFull: "New Column 1487 (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "d5d4d764-425d-e926-14c7-c124b79a4f92",
                },
                {
                    id: "d7b6cd3b-c6b5-43c5-8f63-5c0e668b7134",
                    calculations: [
                        {
                            transformationID: "0ab455ac-98e8-48dd-b2d5-77ca73b1dc07",
                            name: "New Column 2061",
                            default: true,
                            viewReferenceName: "verbcalccol_0ab455ac_98e8_48dd_b2d5_77ca73b1dc07_v_0",
                            columnReferenceName: null,
                            versionDetails: "NotPublished",
                            publishedOn: null,
                        },
                    ],
                    dataType: "Unsupported",
                    name: "New Column 2061",
                    displayName: null,
                    visualNameShort: "New Column 2061",
                    visualNameFull: "New Column 2061 (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "d7b6cd3b-c6b5-43c5-8f63-5c0e668b7134",
                },
                {
                    id: "d9259bf2-5d00-42f9-b0a9-7ec174888a05",
                    calculations: [
                        {
                            transformationID: "61339c17-52fc-4a1e-825e-0a1c2b80a35f",
                            name: "New Column 2377",
                            default: true,
                            viewReferenceName: null,
                            columnReferenceName: null,
                            versionDetails: "NotPublished",
                            publishedOn: null,
                        },
                    ],
                    dataType: "Text",
                    name: "New Column 2377",
                    displayName: null,
                    visualNameShort: "New Column 2377",
                    visualNameFull: "New Column 2377 (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "d9259bf2-5d00-42f9-b0a9-7ec174888a05",
                },
                {
                    id: "eba5b4c6-4379-4d93-8bb1-6af1f9989f02",
                    calculations: [
                        {
                            transformationID: "c1254c80-ac88-41ea-8386-50d13a2286fa",
                            name: "New Column 2567",
                            default: true,
                            viewReferenceName: "verbcalccol_c1254c80_ac88_41ea_8386_50d13a2286fa",
                            columnReferenceName: null,
                            versionDetails: "NotPublished",
                            publishedOn: null,
                        },
                    ],
                    dataType: "Unsupported",
                    name: "New Column 2567",
                    displayName: null,
                    visualNameShort: "New Column 2567",
                    visualNameFull: "New Column 2567 (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "eba5b4c6-4379-4d93-8bb1-6af1f9989f02",
                },
                {
                    id: "73b8b778-7a04-42b8-9482-9e500ac033a1",
                    calculations: [
                        {
                            transformationID: "504ff9c7-9b29-4674-8abc-0d12f0cc8dd1",
                            name: "New Column 2763",
                            default: true,
                            viewReferenceName: null,
                            columnReferenceName: null,
                            versionDetails: "NotPublished",
                            publishedOn: null,
                        },
                    ],
                    dataType: "Text",
                    name: "New Column 2763",
                    displayName: null,
                    visualNameShort: "New Column 2763",
                    visualNameFull: "New Column 2763 (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "73b8b778-7a04-42b8-9482-9e500ac033a1",
                },
                {
                    id: "8e0724e1-8afb-4227-b18b-cb321246373f",
                    calculations: [
                        {
                            transformationID: "bce82109-a2cf-41f9-9c8c-df8ed056d952",
                            name: "New Column 2946",
                            default: true,
                            viewReferenceName: null,
                            columnReferenceName: null,
                            versionDetails: "NotPublished",
                            publishedOn: null,
                        },
                    ],
                    dataType: "Text",
                    name: "New Column 2946",
                    displayName: null,
                    visualNameShort: "New Column 2946",
                    visualNameFull: "New Column 2946 (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "8e0724e1-8afb-4227-b18b-cb321246373f",
                },
                {
                    id: "cbe87f5f-2787-45eb-a298-ac1a1a85a3ca",
                    calculations: [
                        {
                            transformationID: "67d492ac-66a8-4b99-b980-7b64213f2b45",
                            name: "New Column 3157",
                            default: true,
                            viewReferenceName: null,
                            columnReferenceName: null,
                            versionDetails: "NotPublished",
                            publishedOn: null,
                        },
                    ],
                    dataType: "Text",
                    name: "New Column 3157",
                    displayName: null,
                    visualNameShort: "New Column 3157",
                    visualNameFull: "New Column 3157 (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "cbe87f5f-2787-45eb-a298-ac1a1a85a3ca",
                },
                {
                    id: "4379a481-3902-4fae-b2fb-a57120a1d2f3",
                    calculations: [],
                    dataType: "Text",
                    name: "New Column 3624",
                    displayName: null,
                    visualNameShort: "New Column 3624",
                    visualNameFull: "New Column 3624 (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "4379a481-3902-4fae-b2fb-a57120a1d2f3",
                },
                {
                    id: "43060380-babc-34b3-9a65-7de22afdfdb8",
                    calculations: [
                        {
                            transformationID: "666a1372-39d2-481c-acec-2f8844bce6b0",
                            name: "New Column 3626",
                            default: true,
                            viewReferenceName: "verbcalccol_666a1372_39d2_481c_acec_2f8844bce6b0",
                            columnReferenceName: "verbcol_calcoutput",
                            versionDetails: "NotPublished",
                            publishedOn: null,
                        },
                    ],
                    dataType: "Double",
                    name: "New Column 3626",
                    displayName: null,
                    visualNameShort: "New Column 3626",
                    visualNameFull: "New Column 3626 (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "43060380-babc-34b3-9a65-7de22afdfdb8",
                },
                {
                    id: "43c8dc57-1448-73a3-ca08-fdaa6b272d73",
                    calculations: [
                        {
                            transformationID: "4ae9bf0d-9065-4497-a46e-ac53832e045f",
                            name: "New Column 3983",
                            default: true,
                            viewReferenceName: "verbcalccol_4ae9bf0d_9065_4497_a46e_ac53832e045f",
                            columnReferenceName: null,
                            versionDetails: "NotPublished",
                            publishedOn: null,
                        },
                    ],
                    dataType: "Unsupported",
                    name: "New Column 3983",
                    displayName: null,
                    visualNameShort: "New Column 3983",
                    visualNameFull: "New Column 3983 (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "43c8dc57-1448-73a3-ca08-fdaa6b272d73",
                },
                {
                    id: "7bfc5973-89af-4a37-b7cf-63f6b0955b84",
                    calculations: [
                        {
                            transformationID: "88fe96ed-676e-4d0e-ad0b-aa4b657e66cd",
                            name: "New Column 4551",
                            default: true,
                            viewReferenceName: null,
                            columnReferenceName: null,
                            versionDetails: "NotPublished",
                            publishedOn: null,
                        },
                    ],
                    dataType: "Text",
                    name: "New Column 4551",
                    displayName: null,
                    visualNameShort: "New Column 4551",
                    visualNameFull: "New Column 4551 (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "7bfc5973-89af-4a37-b7cf-63f6b0955b84",
                },
                {
                    id: "ecfde917-98ad-47d8-ae63-06b23aa34c5e",
                    calculations: [
                        {
                            transformationID: "19a900e5-3927-4a1e-a5f0-eee2df078370",
                            name: "New Column 4601",
                            default: true,
                            viewReferenceName: null,
                            columnReferenceName: null,
                            versionDetails: "NotPublished",
                            publishedOn: null,
                        },
                    ],
                    dataType: "Text",
                    name: "New Column 4601",
                    displayName: null,
                    visualNameShort: "New Column 4601",
                    visualNameFull: "New Column 4601 (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "ecfde917-98ad-47d8-ae63-06b23aa34c5e",
                },
                {
                    id: "8e2f5756-3c42-7622-03ea-83496af095bb",
                    calculations: [
                        {
                            transformationID: "f0675a68-f449-4f70-9ca2-a36b61743fed",
                            name: "New Column 4895",
                            default: true,
                            viewReferenceName: "verbcalccol_f0675a68_f449_4f70_9ca2_a36b61743fed",
                            columnReferenceName: null,
                            versionDetails: "NotPublished",
                            publishedOn: null,
                        },
                    ],
                    dataType: "Unsupported",
                    name: "New Column 4895",
                    displayName: null,
                    visualNameShort: "New Column 4895",
                    visualNameFull: "New Column 4895 (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "8e2f5756-3c42-7622-03ea-83496af095bb",
                },
                {
                    id: "28f60996-7b76-431e-a511-a8eb2a8fe57d",
                    calculations: [
                        {
                            transformationID: "d8d22e53-a515-4899-8431-e0162d37b7e3",
                            name: "New Column 5313",
                            default: true,
                            viewReferenceName: null,
                            columnReferenceName: null,
                            versionDetails: "NotPublished",
                            publishedOn: null,
                        },
                    ],
                    dataType: "Text",
                    name: "New Column 5313",
                    displayName: null,
                    visualNameShort: "New Column 5313",
                    visualNameFull: "New Column 5313 (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "28f60996-7b76-431e-a511-a8eb2a8fe57d",
                },
                {
                    id: "72930b2e-3c50-b4f9-604d-03a063e23b97",
                    calculations: [
                        {
                            transformationID: "719f6d25-2b7e-4316-88d2-1bc10e735d2b",
                            name: "New Column 5495",
                            default: true,
                            viewReferenceName: "verbcalccol_719f6d25_2b7e_4316_88d2_1bc10e735d2b_v_0",
                            columnReferenceName: null,
                            versionDetails: "NotPublished",
                            publishedOn: null,
                        },
                    ],
                    dataType: "Unsupported",
                    name: "New Column 5495",
                    displayName: null,
                    visualNameShort: "New Column 5495",
                    visualNameFull: "New Column 5495 (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "72930b2e-3c50-b4f9-604d-03a063e23b97",
                },
                {
                    id: "8a2ba18b-26ca-4847-9e63-39a81b97d910",
                    calculations: [
                        {
                            transformationID: "bda553b2-9dc0-437e-b0ef-4b1d7489dd55",
                            name: "New Column 5866",
                            default: true,
                            viewReferenceName: null,
                            columnReferenceName: null,
                            versionDetails: "NotPublished",
                            publishedOn: null,
                        },
                    ],
                    dataType: "Text",
                    name: "New Column 5866",
                    displayName: null,
                    visualNameShort: "New Column 5866",
                    visualNameFull: "New Column 5866 (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "8a2ba18b-26ca-4847-9e63-39a81b97d910",
                },
                {
                    id: "06ffc9b8-e03b-4c22-bdae-699d071672c0",
                    calculations: [
                        {
                            transformationID: "31d095ff-b323-4eaf-944e-43bf0a9c6e34",
                            name: "New Column 6172",
                            default: true,
                            viewReferenceName: null,
                            columnReferenceName: null,
                            versionDetails: "NotPublished",
                            publishedOn: null,
                        },
                    ],
                    dataType: "Text",
                    name: "New Column 6172",
                    displayName: null,
                    visualNameShort: "New Column 6172",
                    visualNameFull: "New Column 6172 (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "06ffc9b8-e03b-4c22-bdae-699d071672c0",
                },
                {
                    id: "57cffb40-6c5d-a809-ab5a-efd27ca82716",
                    calculations: [
                        {
                            transformationID: "27d9952a-214a-4cc8-b946-5ca3d9c3a12e",
                            name: "New Column 6345",
                            default: true,
                            viewReferenceName: "verbcalccol_27d9952a_214a_4cc8_b946_5ca3d9c3a12e_v_0",
                            columnReferenceName: null,
                            versionDetails: "NotPublished",
                            publishedOn: null,
                        },
                    ],
                    dataType: "Unsupported",
                    name: "New Column 6345",
                    displayName: null,
                    visualNameShort: "New Column 6345",
                    visualNameFull: "New Column 6345 (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "57cffb40-6c5d-a809-ab5a-efd27ca82716",
                },
                {
                    id: "3cab1d7a-cfab-40f1-89ee-6b67bab65ab1",
                    calculations: [
                        {
                            transformationID: "ed29baed-1b6c-4aee-8b48-8a1c31f0e250",
                            name: "New Column 6705",
                            default: true,
                            viewReferenceName: null,
                            columnReferenceName: null,
                            versionDetails: "NotPublished",
                            publishedOn: null,
                        },
                    ],
                    dataType: "Text",
                    name: "New Column 6705",
                    displayName: null,
                    visualNameShort: "New Column 6705",
                    visualNameFull: "New Column 6705 (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "3cab1d7a-cfab-40f1-89ee-6b67bab65ab1",
                },
                {
                    id: "790b2612-4311-0ac5-d9a2-2d909468abaa",
                    calculations: [
                        {
                            transformationID: "aaf5af67-436c-4a89-811a-0d35924cc79a",
                            name: "New Column 6945",
                            default: true,
                            viewReferenceName: "verbcalccol_aaf5af67_436c_4a89_811a_0d35924cc79a",
                            columnReferenceName: null,
                            versionDetails: "NotPublished",
                            publishedOn: null,
                        },
                    ],
                    dataType: "Unsupported",
                    name: "New Column 6945",
                    displayName: null,
                    visualNameShort: "New Column 6945",
                    visualNameFull: "New Column 6945 (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "790b2612-4311-0ac5-d9a2-2d909468abaa",
                },
                {
                    id: "76f0c4c8-d054-1e76-145a-01243897ccd4",
                    calculations: [
                        {
                            transformationID: "7e4374bc-fc05-4e73-9844-442fb2f4f176",
                            name: "New Column 6996",
                            default: true,
                            viewReferenceName: "verbcalccol_7e4374bc_fc05_4e73_9844_442fb2f4f176",
                            columnReferenceName: null,
                            versionDetails: "NotPublished",
                            publishedOn: null,
                        },
                    ],
                    dataType: "Unsupported",
                    name: "New Column 6996",
                    displayName: null,
                    visualNameShort: "New Column 6996",
                    visualNameFull: "New Column 6996 (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "76f0c4c8-d054-1e76-145a-01243897ccd4",
                },
                {
                    id: "ce971a17-282a-4e23-a2e9-b887af9e2e64",
                    calculations: [
                        {
                            transformationID: "db20cdcf-1fc6-4fe2-8630-930b04d242cd",
                            name: "New Column 7835",
                            default: true,
                            viewReferenceName: null,
                            columnReferenceName: null,
                            versionDetails: "NotPublished",
                            publishedOn: null,
                        },
                    ],
                    dataType: "Text",
                    name: "New Column 7835",
                    displayName: null,
                    visualNameShort: "New Column 7835",
                    visualNameFull: "New Column 7835 (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "ce971a17-282a-4e23-a2e9-b887af9e2e64",
                },
                {
                    id: "787520f7-d02f-451f-b4a9-c5a51caf924c",
                    calculations: [
                        {
                            transformationID: "a5da84e0-febd-4fe8-b152-e3f78104e37a",
                            name: "New Column 7918",
                            default: true,
                            viewReferenceName: null,
                            columnReferenceName: null,
                            versionDetails: "NotPublished",
                            publishedOn: null,
                        },
                    ],
                    dataType: "Text",
                    name: "New Column 7918",
                    displayName: null,
                    visualNameShort: "New Column 7918",
                    visualNameFull: "New Column 7918 (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "787520f7-d02f-451f-b4a9-c5a51caf924c",
                },
                {
                    id: "6de2418a-cfa5-4959-bff8-69d14b05f602",
                    calculations: [
                        {
                            transformationID: "11f0b642-716a-4b9d-b637-731851beebae",
                            name: "New Column 8154",
                            default: true,
                            viewReferenceName: null,
                            columnReferenceName: null,
                            versionDetails: "NotPublished",
                            publishedOn: null,
                        },
                    ],
                    dataType: "Text",
                    name: "New Column 8154",
                    displayName: null,
                    visualNameShort: "New Column 8154",
                    visualNameFull: "New Column 8154 (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "6de2418a-cfa5-4959-bff8-69d14b05f602",
                },
                {
                    id: "18084373-51d1-4b59-9ce9-5e4c84529bfa",
                    calculations: [
                        {
                            transformationID: "179a39fa-fe82-4ee1-8192-0366711ece6d",
                            name: "New Column 8307",
                            default: true,
                            viewReferenceName: null,
                            columnReferenceName: null,
                            versionDetails: "NotPublished",
                            publishedOn: null,
                        },
                    ],
                    dataType: "Text",
                    name: "New Column 8307",
                    displayName: null,
                    visualNameShort: "New Column 8307",
                    visualNameFull: "New Column 8307 (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "18084373-51d1-4b59-9ce9-5e4c84529bfa",
                },
                {
                    id: "50f8af32-a619-ed04-1f0b-295286da8515",
                    calculations: [
                        {
                            transformationID: "3c89e8d7-540b-42df-a141-d08f367e1ccb",
                            name: "New Column 8333",
                            default: true,
                            viewReferenceName: "verbcalccol_3c89e8d7_540b_42df_a141_d08f367e1ccb",
                            columnReferenceName: null,
                            versionDetails: "NotPublished",
                            publishedOn: null,
                        },
                    ],
                    dataType: "Unsupported",
                    name: "New Column 8333",
                    displayName: null,
                    visualNameShort: "New Column 8333",
                    visualNameFull: "New Column 8333 (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "50f8af32-a619-ed04-1f0b-295286da8515",
                },
                {
                    id: "87193204-2182-4d32-8d1f-d450d1fd1c2c",
                    calculations: [
                        {
                            transformationID: "811533fd-f0b6-4e64-a50d-3525f107f78d",
                            name: "New Column 9336",
                            default: true,
                            viewReferenceName: null,
                            columnReferenceName: null,
                            versionDetails: "NotPublished",
                            publishedOn: null,
                        },
                    ],
                    dataType: "Text",
                    name: "New Column 9336",
                    displayName: null,
                    visualNameShort: "New Column 9336",
                    visualNameFull: "New Column 9336 (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "87193204-2182-4d32-8d1f-d450d1fd1c2c",
                },
                {
                    id: "6c4f380b-9af9-4962-9895-f3c42c3706de",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "a284da36-c7fd-4dee-8891-982bed9c90bd",
                    dataSourceColumnID: "8f3118b6-b593-4e93-b1ca-3deb84550ffe",
                    dataType: "Int",
                    name: "OrderNumber",
                    displayName: null,
                    visualNameShort: "Order Number",
                    visualNameFull: "Order Number (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    modelColumnID: "6c4f380b-9af9-4962-9895-f3c42c3706de",
                },
                {
                    id: "a32293f3-96ba-42c4-b965-17a8e68b6fa3",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "a284da36-c7fd-4dee-8891-982bed9c90bd",
                    dataSourceColumnID: "3e6c40d8-0afa-4676-8cbe-5ec975b374c5",
                    dataType: "IDUUID",
                    name: "OutingID",
                    displayName: null,
                    visualNameShort: "Outing ID",
                    visualNameFull: "Outing ID (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    modelColumnID: "a32293f3-96ba-42c4-b965-17a8e68b6fa3",
                },
                {
                    id: "a0024725-6bfb-4d8a-b4dd-7f13427fceaa",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "a284da36-c7fd-4dee-8891-982bed9c90bd",
                    dataSourceColumnID: "a8543a59-460f-45e4-aae8-af4c9be6c856",
                    dataType: "Double",
                    name: "ProviderFees",
                    displayName: null,
                    visualNameShort: "Provider Fees",
                    visualNameFull: "Provider Fees (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    modelColumnID: "a0024725-6bfb-4d8a-b4dd-7f13427fceaa",
                },
                {
                    id: "b4928f57-d922-4950-a745-6c0f8fdd2533",
                    calculations: [
                        {
                            transformationID: "3db28bd8-1153-45f5-95ff-d8c48a9c2a87",
                            name: "QA Test column",
                            default: true,
                            viewReferenceName: "verbcalccol_3db28bd8_1153_45f5_95ff_d8c48a9c2a87",
                            columnReferenceName: "verbcol_calcoutput",
                            versionDetails: "NotPublished",
                            publishedOn: null,
                        },
                    ],
                    dataType: "Int",
                    name: "QA Test column",
                    displayName: null,
                    visualNameShort: "QA Test Column",
                    visualNameFull: "QA Test Column (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "b4928f57-d922-4950-a745-6c0f8fdd2533",
                },
                {
                    id: "93a9465a-6eb9-49fd-af9f-be7c2bcad0e5",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "a284da36-c7fd-4dee-8891-982bed9c90bd",
                    dataSourceColumnID: "ce2aa9d3-d085-4541-a178-ade9f2b9134d",
                    dataType: "Int",
                    name: "Referrer",
                    displayName: null,
                    visualNameShort: "Referrer",
                    visualNameFull: "Referrer (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    modelColumnID: "93a9465a-6eb9-49fd-af9f-be7c2bcad0e5",
                },
                {
                    id: "744dee34-e6e2-5843-d026-ce8272841ff7",
                    calculations: [
                        {
                            transformationID: "9bb99bc6-d101-4ff9-a993-c4b2b9d8b7e8",
                            name: "Substraction Column",
                            default: true,
                            viewReferenceName: "verbcalccol_9bb99bc6_d101_4ff9_a993_c4b2b9d8b7e8",
                            columnReferenceName: "verbcol_calcoutput",
                            versionDetails: "LatestPublished",
                            publishedOn: "2021-06-21T08:30:25.077Z",
                        },
                    ],
                    dataType: "Double",
                    name: "Substraction Column",
                    displayName: null,
                    visualNameShort: "Substraction Column",
                    visualNameFull: "Substraction Column (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "744dee34-e6e2-5843-d026-ce8272841ff7",
                },
                {
                    id: "57d7620a-e495-4a8b-a325-87e6e326ba02",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "a284da36-c7fd-4dee-8891-982bed9c90bd",
                    dataSourceColumnID: "7f39d7cc-f1b7-4527-b300-c019b9e82268",
                    dataType: "IDUUID",
                    name: "TicketDeliveryID",
                    displayName: null,
                    visualNameShort: "Ticket Delivery ID",
                    visualNameFull: "Ticket Delivery ID (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    modelColumnID: "57d7620a-e495-4a8b-a325-87e6e326ba02",
                },
                {
                    id: "994c76be-b379-4171-ae7f-e5d4eeae1e99",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "a284da36-c7fd-4dee-8891-982bed9c90bd",
                    dataSourceColumnID: "ecfa7b64-b2d6-4395-ae40-c393fef218b3",
                    dataType: "Double",
                    name: "TotalPrice",
                    displayName: null,
                    visualNameShort: "Total Price",
                    visualNameFull: "Total Price (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    modelColumnID: "994c76be-b379-4171-ae7f-e5d4eeae1e99",
                },
                {
                    id: "e77cb160-3aac-4235-8f75-4fe4f8b1f92a",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "a284da36-c7fd-4dee-8891-982bed9c90bd",
                    dataSourceColumnID: "46a5147c-37aa-45b6-b704-40e515fd71f3",
                    dataType: "Text",
                    name: "VisitorToken",
                    displayName: null,
                    visualNameShort: "Visitor Token",
                    visualNameFull: "Visitor Token (Order)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "441d9abb-1695-41a4-b904-0bca100d5a9e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Order",
                    modelColumnID: "e77cb160-3aac-4235-8f75-4fe4f8b1f92a",
                },
            ],
            rowLevelSecurity: {
                Endpoint: null,
                Static: {
                    id: "7808ac8c-b050-40a5-af3a-a99e00c5367f",
                    outerBlocks: [
                        {
                            innerBlocks: [
                                {
                                    leftValue: {
                                        valueSelector: "User.ID2",
                                        $type: "UserBlockValue",
                                    },
                                    compareOp: "Equal",
                                    rightValue: {
                                        valueSelector: "User.ID2",
                                        $type: "UserBlockValue",
                                    },
                                    $type: "AndBlock",
                                },
                            ],
                        },
                    ],
                    $type: "TableSecurityConfig",
                },
            },
            position: {
                x: 459,
                y: 432.5,
            },
            $type: "DataSourceModelTable",
        },
        {
            id: "ec79228a-3e76-4645-bc87-b237904308ce",
            dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
            dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
            name: "Organization",
            visualName: "Organization",
            columns: [
                {
                    id: "f248245d-4751-439f-861d-36449c4fd896",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "846dd962-3809-4f4f-b150-3a9105038223",
                    dataType: "IDUUID",
                    name: "OrganizationID",
                    displayName: null,
                    visualNameShort: "Organization ID",
                    visualNameFull: "Organization ID (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "f248245d-4751-439f-861d-36449c4fd896",
                },
                {
                    id: "82e9c70b-bb66-4ded-a8ad-9ae5f32bd9f6",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "6be37102-57d6-46d4-a6c2-1f18d66e0661",
                    dataType: "Text",
                    name: "ACHTransferAccountLastFour",
                    displayName: null,
                    visualNameShort: "ACH Transfer Account Last Four",
                    visualNameFull: "ACH Transfer Account Last Four (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "82e9c70b-bb66-4ded-a8ad-9ae5f32bd9f6",
                },
                {
                    id: "3af5c31c-af4e-4422-bc6f-7e9d5f885265",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "52d3fb81-2116-4a63-9869-00efca0583b7",
                    dataType: "Int",
                    name: "ACHTransferDayOfMonth",
                    displayName: null,
                    visualNameShort: "ACH Transfer Day Of Month",
                    visualNameFull: "ACH Transfer Day Of Month (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "3af5c31c-af4e-4422-bc6f-7e9d5f885265",
                },
                {
                    id: "3b77b09a-182c-450b-a7bb-4c69652577ce",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "bc60f902-c14b-447a-8a5e-76fcfc56e7db",
                    dataType: "Int",
                    name: "ACHTransferDayOfWeek",
                    displayName: null,
                    visualNameShort: "ACH Transfer Day Of Week",
                    visualNameFull: "ACH Transfer Day Of Week (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "3b77b09a-182c-450b-a7bb-4c69652577ce",
                },
                {
                    id: "6e40e1ee-d4ab-411e-9914-a2f1ea3af406",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "18fb3e5c-2ae4-458e-ad3c-235489895dfc",
                    dataType: "Bool",
                    name: "ACHTransferEnabled",
                    displayName: null,
                    visualNameShort: "ACH Transfer Enabled",
                    visualNameFull: "ACH Transfer Enabled (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "6e40e1ee-d4ab-411e-9914-a2f1ea3af406",
                },
                {
                    id: "97319e79-26fc-4708-a291-941c3387c280",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "2f8cf5b7-d0d3-42ef-82e0-949d10c6272b",
                    dataType: "Int",
                    name: "ACHTransferFundingAccountId",
                    displayName: null,
                    visualNameShort: "ACH Transfer Funding Account Id",
                    visualNameFull: "ACH Transfer Funding Account Id (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "97319e79-26fc-4708-a291-941c3387c280",
                },
                {
                    id: "8eb9d8e6-0fe2-4be4-83e3-5e1856598517",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "2610fcad-6f3c-41ca-8e9d-f422abcb26cc",
                    dataType: "Int",
                    name: "ACHTransferLocationUserId",
                    displayName: null,
                    visualNameShort: "ACH Transfer Location User Id",
                    visualNameFull: "ACH Transfer Location User Id (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "8eb9d8e6-0fe2-4be4-83e3-5e1856598517",
                },
                {
                    id: "9ef47178-fb06-4a6f-bc2e-23f4a57d8f66",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "0e5c858d-de7f-4f83-9b35-7da33937f7f9",
                    dataType: "Int",
                    name: "ACHTransferPayoutRecurring",
                    displayName: null,
                    visualNameShort: "ACH Transfer Payout Recurring",
                    visualNameFull: "ACH Transfer Payout Recurring (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "9ef47178-fb06-4a6f-bc2e-23f4a57d8f66",
                },
                {
                    id: "a5eddc13-ccfe-44da-b3f3-3f067460a12f",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "ac15f32b-2b70-4013-85e7-6b02203a6cc3",
                    dataType: "Int",
                    name: "ACHTransferPayoutSchedule",
                    displayName: null,
                    visualNameShort: "ACH Transfer Payout Schedule",
                    visualNameFull: "ACH Transfer Payout Schedule (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "a5eddc13-ccfe-44da-b3f3-3f067460a12f",
                },
                {
                    id: "1fef4cf2-ca58-4254-bdf5-04e956724eb8",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "290cb4f6-1adf-451a-9505-24d81522bd67",
                    dataType: "Text",
                    name: "AdminNotes",
                    displayName: null,
                    visualNameShort: "Admin Notes",
                    visualNameFull: "Admin Notes (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "1fef4cf2-ca58-4254-bdf5-04e956724eb8",
                },
                {
                    id: "0fd6089c-5bcc-4ade-9c2e-053a462783e6",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "c0af410c-818f-4275-a7ea-f90d2bdadbb0",
                    dataType: "Bool",
                    name: "AllowDiscounts",
                    displayName: null,
                    visualNameShort: "Allow Discounts",
                    visualNameFull: "Allow Discounts (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "0fd6089c-5bcc-4ade-9c2e-053a462783e6",
                },
                {
                    id: "f230375a-79e0-45b5-b3a3-aca01a8427ef",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "72c09599-0148-4d8c-bb9c-5350ec3b27c6",
                    dataType: "Bool",
                    name: "AllowOpenInventory",
                    displayName: null,
                    visualNameShort: "Allow Open Inventory",
                    visualNameFull: "Allow Open Inventory (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "f230375a-79e0-45b5-b3a3-aca01a8427ef",
                },
                {
                    id: "b0c1d79f-e8e4-43b7-8a87-0eee623330e1",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "4e8a5ab9-8035-4593-aaa9-4c5ff38cca1e",
                    dataType: "Bool",
                    name: "AllowShowPurchased",
                    displayName: null,
                    visualNameShort: "Allow Show Purchased",
                    visualNameFull: "Allow Show Purchased (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "b0c1d79f-e8e4-43b7-8a87-0eee623330e1",
                },
                {
                    id: "5e3ab864-90ac-4e58-a666-1dc894c88e4d",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "dba69201-784a-4001-99a9-96b9e4ec2595",
                    dataType: "Text",
                    name: "AppWebsiteLink",
                    displayName: null,
                    visualNameShort: "App Website Link",
                    visualNameFull: "App Website Link (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "5e3ab864-90ac-4e58-a666-1dc894c88e4d",
                },
                {
                    id: "a32c3b80-98e3-43e6-a254-72aa4e38a64d",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "999e2161-0755-4c02-9da8-4964a7c89cb3",
                    dataType: "Int",
                    name: "ChargeFlatFeeToOrderIsZeroDefault",
                    displayName: null,
                    visualNameShort: "Charge Flat Fee To Order Is Zero Default",
                    visualNameFull: "Charge Flat Fee To Order Is Zero Default (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "a32c3b80-98e3-43e6-a254-72aa4e38a64d",
                },
                {
                    id: "22a52fd6-04a5-4d31-a24e-42e162313875",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "750ec632-1d30-4364-9d3c-469dcf47cfee",
                    dataType: "Int",
                    name: "ChargeFlatFeeToOrderNotZeroDefault",
                    displayName: null,
                    visualNameShort: "Charge Flat Fee To Order Not Zero Default",
                    visualNameFull: "Charge Flat Fee To Order Not Zero Default (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "22a52fd6-04a5-4d31-a24e-42e162313875",
                },
                {
                    id: "2d45bc52-43fb-4af9-b4a3-c8fa016e9307",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "61cfe047-53ed-4de1-88ee-32a316577b34",
                    dataType: "Int",
                    name: "CheckTicketsMode",
                    displayName: null,
                    visualNameShort: "Check Tickets Mode",
                    visualNameFull: "Check Tickets Mode (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "2d45bc52-43fb-4af9-b4a3-c8fa016e9307",
                },
                {
                    id: "9269dec9-9760-477c-91d2-d319d0f56062",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "4d0a5f22-2b3c-45db-8c44-ef5147df409a",
                    dataType: "Int",
                    name: "Currency",
                    displayName: null,
                    visualNameShort: "Currency",
                    visualNameFull: "Currency (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "9269dec9-9760-477c-91d2-d319d0f56062",
                },
                {
                    id: "e9201b48-9062-4c7d-b21a-a5e0202ab619",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "625f22ab-3537-488f-bafe-f5380030b4ec",
                    dataType: "Int",
                    name: "DeadlineDaysBeforeDefault",
                    displayName: null,
                    visualNameShort: "Deadline Days Before Default",
                    visualNameFull: "Deadline Days Before Default (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "e9201b48-9062-4c7d-b21a-a5e0202ab619",
                },
                {
                    id: "fe32d161-250b-4faa-8d5a-794e18b734f3",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "f7babbce-553f-4fcd-9ef8-90a8d5f1c23a",
                    dataType: "Bool",
                    name: "Deleted",
                    displayName: null,
                    visualNameShort: "Deleted",
                    visualNameFull: "Deleted (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "fe32d161-250b-4faa-8d5a-794e18b734f3",
                },
                {
                    id: "ad686ec4-c2ef-4906-bdd7-1e9bac81622a",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "e84c2b11-c8d5-404f-b0ee-70fce9ab05cb",
                    dataType: "Text",
                    name: "DeliveryDefault_DefaultEmailInstructions",
                    displayName: null,
                    visualNameShort: "Delivery Default Default Email Instructions",
                    visualNameFull: "Delivery Default Default Email Instructions (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "ad686ec4-c2ef-4906-bdd7-1e9bac81622a",
                },
                {
                    id: "746e5ddc-765b-4c83-9d6d-b95c5d43a91a",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "116f2162-53ad-48ec-86ea-ebf585d2e902",
                    dataType: "Bool",
                    name: "DeliveryDefault_DefaultEnabled",
                    displayName: null,
                    visualNameShort: "Delivery Default Default Enabled",
                    visualNameFull: "Delivery Default Default Enabled (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "746e5ddc-765b-4c83-9d6d-b95c5d43a91a",
                },
                {
                    id: "6e4d50c6-317e-4740-bf9c-91732c87085f",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "cfdf987a-b8ba-4df2-8369-dcb8d7b625c6",
                    dataType: "Text",
                    name: "DeliveryDefault_DefaultInstructions",
                    displayName: null,
                    visualNameShort: "Delivery Default Default Instructions",
                    visualNameFull: "Delivery Default Default Instructions (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "6e4d50c6-317e-4740-bf9c-91732c87085f",
                },
                {
                    id: "17cada34-015d-4aed-a7c5-c627d797ad70",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "309faee2-8be3-48fe-baeb-5939af1e0e81",
                    dataType: "Text",
                    name: "DeliveryDefault_DefaultName",
                    displayName: null,
                    visualNameShort: "Delivery Default Default Name",
                    visualNameFull: "Delivery Default Default Name (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "17cada34-015d-4aed-a7c5-c627d797ad70",
                },
                {
                    id: "620554f4-7954-41f8-929c-b1fc1190af33",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "c8d11ab9-4fb1-4265-949d-90516042a10b",
                    dataType: "Text",
                    name: "DeliveryDefault_WillCallEmailInstructions",
                    displayName: null,
                    visualNameShort: "Delivery Default Will Call Email Instructions",
                    visualNameFull: "Delivery Default Will Call Email Instructions (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "620554f4-7954-41f8-929c-b1fc1190af33",
                },
                {
                    id: "9cdf6fc8-4b88-488a-9af1-bba813deedda",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "3e37c17e-5aa5-4c28-b618-e92eddd11b11",
                    dataType: "Bool",
                    name: "DeliveryDefault_WillCallEnabled",
                    displayName: null,
                    visualNameShort: "Delivery Default Will Call Enabled",
                    visualNameFull: "Delivery Default Will Call Enabled (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "9cdf6fc8-4b88-488a-9af1-bba813deedda",
                },
                {
                    id: "cbff62ac-5d13-4d82-b670-70c438f49150",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "c39902b9-9645-44fe-a682-70fb669fb48a",
                    dataType: "Text",
                    name: "DeliveryDefault_WillCallInstructions",
                    displayName: null,
                    visualNameShort: "Delivery Default Will Call Instructions",
                    visualNameFull: "Delivery Default Will Call Instructions (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "cbff62ac-5d13-4d82-b670-70c438f49150",
                },
                {
                    id: "42916aa9-58f8-4620-b02b-72e4a5f15610",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "fc75647b-4e2b-404c-80bc-20b08368c5b5",
                    dataType: "Text",
                    name: "DeliveryDefault_WillCallName",
                    displayName: null,
                    visualNameShort: "Delivery Default Will Call Name",
                    visualNameFull: "Delivery Default Will Call Name (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "42916aa9-58f8-4620-b02b-72e4a5f15610",
                },
                {
                    id: "c279c6ff-d563-4ea7-9f23-81602366ec02",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "6288c1af-0335-4888-9c0c-c17d0b37b415",
                    dataType: "Text",
                    name: "Description",
                    displayName: null,
                    visualNameShort: "Description",
                    visualNameFull: "Description (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "c279c6ff-d563-4ea7-9f23-81602366ec02",
                },
                {
                    id: "b4c14e4f-9165-4293-9f0d-ffbb70cb43ca",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "f7d4d44f-b690-4b56-89f5-db6c37b5388e",
                    dataType: "Bool",
                    name: "EcheckTransferEnabled",
                    displayName: null,
                    visualNameShort: "Echeck Transfer Enabled",
                    visualNameFull: "Echeck Transfer Enabled (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "b4c14e4f-9165-4293-9f0d-ffbb70cb43ca",
                },
                {
                    id: "53c92bc7-c629-4c42-8af8-813ca9d3613a",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "ad836126-cf7b-4cb8-a453-23bcfdd5e042",
                    dataType: "Text",
                    name: "ExperienceCopy",
                    displayName: null,
                    visualNameShort: "Experience Copy",
                    visualNameFull: "Experience Copy (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "53c92bc7-c629-4c42-8af8-813ca9d3613a",
                },
                {
                    id: "c6c04029-60d5-49a1-a85f-694a57288aa8",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "4fbb1fb4-6a0b-441c-8b4f-9b5b6fd2344b",
                    dataType: "Int",
                    name: "ExperienceSystem",
                    displayName: null,
                    visualNameShort: "Experience System",
                    visualNameFull: "Experience System (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "c6c04029-60d5-49a1-a85f-694a57288aa8",
                },
                {
                    id: "31e2241c-e207-4d34-9406-b596d722aa1f",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "852248de-7f46-4ea4-9816-2f25e60c076d",
                    dataType: "Int",
                    name: "ExperienceType",
                    displayName: null,
                    visualNameShort: "Experience Type",
                    visualNameFull: "Experience Type (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "31e2241c-e207-4d34-9406-b596d722aa1f",
                },
                {
                    id: "e2a9e903-ab08-499f-869c-76c070a233bb",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "3360e364-8426-4366-bc6f-52acedc4238b",
                    dataType: "Text",
                    name: "ExperienceUrl",
                    displayName: null,
                    visualNameShort: "Experience Url",
                    visualNameFull: "Experience Url (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "e2a9e903-ab08-499f-869c-76c070a233bb",
                },
                {
                    id: "5ef05895-608f-4c6b-b17b-65edff49cdd5",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "88292847-4e39-4c04-baf9-b15b978d69ea",
                    dataType: "Text",
                    name: "FinePrint",
                    displayName: null,
                    visualNameShort: "Fine Print",
                    visualNameFull: "Fine Print (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "5ef05895-608f-4c6b-b17b-65edff49cdd5",
                },
                {
                    id: "bfd4648e-3de3-4a7f-873d-6af12d2a76cc",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "6f590db2-9d43-43f7-88c1-3c36bb00d0b5",
                    dataType: "Double",
                    name: "FlatTaxAmount",
                    displayName: null,
                    visualNameShort: "Flat Tax Amount",
                    visualNameFull: "Flat Tax Amount (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "bfd4648e-3de3-4a7f-873d-6af12d2a76cc",
                },
                {
                    id: "5f4f5403-7039-47bc-abc0-520d9a98d556",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "b74b57d2-f1a9-4d61-8528-2f3a92cf651c",
                    dataType: "Bool",
                    name: "FlatTaxEnabled",
                    displayName: null,
                    visualNameShort: "Flat Tax Enabled",
                    visualNameFull: "Flat Tax Enabled (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "5f4f5403-7039-47bc-abc0-520d9a98d556",
                },
                {
                    id: "c8f5fb50-beb9-47b2-b2aa-d50a9f98fbfd",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "5a62198b-5677-49a2-8701-bbfabcca8107",
                    dataType: "Bool",
                    name: "FlatTaxToGroupEnabled",
                    displayName: null,
                    visualNameShort: "Flat Tax To Group Enabled",
                    visualNameFull: "Flat Tax To Group Enabled (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "c8f5fb50-beb9-47b2-b2aa-d50a9f98fbfd",
                },
                {
                    id: "382c0262-fdc5-45f0-945d-6b62b085702d",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "6c54f974-bc3b-4922-ab76-7a0513cdee93",
                    dataType: "Text",
                    name: "GMSalesRep",
                    displayName: null,
                    visualNameShort: "GM Sales Rep",
                    visualNameFull: "GM Sales Rep (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "382c0262-fdc5-45f0-945d-6b62b085702d",
                },
                {
                    id: "af6bac0e-6353-4688-adb6-e1bd7f96fe35",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "3d19d042-62e4-4803-b957-194880b6b4b5",
                    dataType: "Text",
                    name: "HeaderImage_ImageUrl",
                    displayName: null,
                    visualNameShort: "Header Image Image Url",
                    visualNameFull: "Header Image Image Url (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "af6bac0e-6353-4688-adb6-e1bd7f96fe35",
                },
                {
                    id: "747b8090-30f1-4368-b93f-6f5410b6de67",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "98889443-9d47-44f3-a1d3-2d8e208fc2f1",
                    dataType: "Bool",
                    name: "HideLoginOnConfirmation",
                    displayName: null,
                    visualNameShort: "Hide Login On Confirmation",
                    visualNameFull: "Hide Login On Confirmation (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "747b8090-30f1-4368-b93f-6f5410b6de67",
                },
                {
                    id: "5854adf2-0f7a-46d3-9b6d-8a34df45c896",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "a6d7f455-97e6-411c-911e-70c641ae9f38",
                    dataType: "Int",
                    name: "League",
                    displayName: null,
                    visualNameShort: "League",
                    visualNameFull: "League (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "5854adf2-0f7a-46d3-9b6d-8a34df45c896",
                },
                {
                    id: "0dcf8511-5f8b-4206-ae98-7dbe99e0ddd7",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "cf075f9b-3f38-4e3b-8a8d-b2730d982333",
                    dataType: "Bool",
                    name: "LockImportedTicketPrice",
                    displayName: null,
                    visualNameShort: "Lock Imported Ticket Price",
                    visualNameFull: "Lock Imported Ticket Price (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "0dcf8511-5f8b-4206-ae98-7dbe99e0ddd7",
                },
                {
                    id: "ef3e0291-d515-4f3e-80ae-531c72050951",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "a26ab1ad-ab27-4a2c-bd43-96424a851224",
                    dataType: "Text",
                    name: "LogoImage_ImageUrl",
                    displayName: null,
                    visualNameShort: "Logo Image Image Url",
                    visualNameFull: "Logo Image Image Url (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "ef3e0291-d515-4f3e-80ae-531c72050951",
                },
                {
                    id: "2de470b7-446b-4cb6-b125-04c590a9c61e",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "01bb181b-f280-422a-89ae-97b4eb22034f",
                    dataType: "Text",
                    name: "Name",
                    displayName: null,
                    visualNameShort: "Name",
                    visualNameFull: "Name (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "2de470b7-446b-4cb6-b125-04c590a9c61e",
                },
                {
                    id: "d87fc57e-d57b-4626-8b06-fc699892e370",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "31fb159e-e9de-4b1c-a51c-53acece58903",
                    dataType: "Text",
                    name: "NavigationColor_RGBHex",
                    displayName: null,
                    visualNameShort: "Navigation Color RGB Hex",
                    visualNameFull: "Navigation Color RGB Hex (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "d87fc57e-d57b-4626-8b06-fc699892e370",
                },
                {
                    id: "a42bebc6-a8fc-c65e-9bc0-e2a07db1b500",
                    calculations: [
                        {
                            transformationID: "51e77c52-93e9-46b4-b1cc-c155fc5dcf99",
                            name: "New Column 5970",
                            default: true,
                            viewReferenceName: "verbcalccol_51e77c52_93e9_46b4_b1cc_c155fc5dcf99_v_0",
                            columnReferenceName: null,
                            versionDetails: "NotPublished",
                            publishedOn: null,
                        },
                    ],
                    dataType: "Unsupported",
                    name: "New Column 5970",
                    displayName: null,
                    visualNameShort: "New Column 5970",
                    visualNameFull: "New Column 5970 (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "a42bebc6-a8fc-c65e-9bc0-e2a07db1b500",
                },
                {
                    id: "76a0f79c-7684-4af0-8c8f-a809e7405c35",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "90e13173-3f5a-4362-a8a8-b8868c16418d",
                    dataType: "Double",
                    name: "OpenInventoryDeadlineMins",
                    displayName: null,
                    visualNameShort: "Open Inventory Deadline Mins",
                    visualNameFull: "Open Inventory Deadline Mins (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "76a0f79c-7684-4af0-8c8f-a809e7405c35",
                },
                {
                    id: "9e1f018a-8652-4734-8017-31a1ed52aa80",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "7092dfa2-cb5d-4e67-8fe7-ca12e09e3b00",
                    dataType: "Bool",
                    name: "OpenInventoryFees_ChargeFeeOnDiscounted",
                    displayName: null,
                    visualNameShort: "Open Inventory Fees Charge Fee On Discounted",
                    visualNameFull: "Open Inventory Fees Charge Fee On Discounted (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "9e1f018a-8652-4734-8017-31a1ed52aa80",
                },
                {
                    id: "a11bef84-994c-4dde-a227-f62c018ea5f0",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "2baffe16-7f73-4db4-a9eb-c35ef19e35af",
                    dataType: "Int",
                    name: "OpenInventoryPostDeadlineSalesOption",
                    displayName: null,
                    visualNameShort: "Open Inventory Post Deadline Sales Option",
                    visualNameFull: "Open Inventory Post Deadline Sales Option (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "a11bef84-994c-4dde-a227-f62c018ea5f0",
                },
                {
                    id: "322ef190-b565-44e8-b507-8c08a3c9965a",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "77f31dfa-b914-4074-9d87-42936c3f3bc8",
                    dataType: "Int",
                    name: "OpenInventorySoldOutThreshold",
                    displayName: null,
                    visualNameShort: "Open Inventory Sold Out Threshold",
                    visualNameFull: "Open Inventory Sold Out Threshold (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "322ef190-b565-44e8-b507-8c08a3c9965a",
                },
                {
                    id: "12cccf52-23c9-4706-b58b-07ce8b50f67b",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "69fa5dfa-4f6c-47e0-928a-36798065623d",
                    dataType: "IDUUID",
                    name: "PayoutDetailsID",
                    displayName: null,
                    visualNameShort: "Payout Details ID",
                    visualNameFull: "Payout Details ID (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "12cccf52-23c9-4706-b58b-07ce8b50f67b",
                },
                {
                    id: "806061d5-cfb3-4ec8-bf7a-8da389d5ceac",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "8530b0c8-5c84-4d39-92ab-3b2e752541e4",
                    dataType: "Bool",
                    name: "PreventSeatIsolation",
                    displayName: null,
                    visualNameShort: "Prevent Seat Isolation",
                    visualNameFull: "Prevent Seat Isolation (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "806061d5-cfb3-4ec8-bf7a-8da389d5ceac",
                },
                {
                    id: "8c74a441-8311-44b1-9acb-4eddca0e8093",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "f75d756b-3e5a-4da1-a136-6e8a66b22ce2",
                    dataType: "Bool",
                    name: "ReservationFees_ChargeFeeOnDiscounted",
                    displayName: null,
                    visualNameShort: "Reservation Fees Charge Fee On Discounted",
                    visualNameFull: "Reservation Fees Charge Fee On Discounted (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "8c74a441-8311-44b1-9acb-4eddca0e8093",
                },
                {
                    id: "49372690-3cd3-43ce-89f7-2dddeda9ea91",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "62530cd1-f595-4b0a-87b6-64d6a1f2d233",
                    dataType: "Bool",
                    name: "SalesTeamCanEditEventServices",
                    displayName: null,
                    visualNameShort: "Sales Team Can Edit Event Services",
                    visualNameFull: "Sales Team Can Edit Event Services (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "49372690-3cd3-43ce-89f7-2dddeda9ea91",
                },
                {
                    id: "e11c42ce-f35a-4ca7-a986-5128ee731981",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "3ce9c5d8-7a1b-4cac-b9a9-192d21b6e574",
                    dataType: "Text",
                    name: "SecondaryHeaderImage_ImageUrl",
                    displayName: null,
                    visualNameShort: "Secondary Header Image Image Url",
                    visualNameFull: "Secondary Header Image Image Url (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "e11c42ce-f35a-4ca7-a986-5128ee731981",
                },
                {
                    id: "3a2f6a37-af34-438a-aadb-c78e49b81e4c",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "d2868b21-af4a-4b12-b901-1a123f3fef67",
                    dataType: "Bool",
                    name: "SecondaryHeaderLogoEnabled",
                    displayName: null,
                    visualNameShort: "Secondary Header Logo Enabled",
                    visualNameFull: "Secondary Header Logo Enabled (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "3a2f6a37-af34-438a-aadb-c78e49b81e4c",
                },
                {
                    id: "3d77c261-6e00-4be1-a195-c71314199f68",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "c2f2b320-7ed0-4847-a683-6e71b7a593a0",
                    dataType: "Text",
                    name: "SecondaryLogoImage_ImageUrl",
                    displayName: null,
                    visualNameShort: "Secondary Logo Image Image Url",
                    visualNameFull: "Secondary Logo Image Image Url (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "3d77c261-6e00-4be1-a195-c71314199f68",
                },
                {
                    id: "69bb1664-4a2c-4b77-af91-c032b0d1f0f0",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "3fc301c0-5575-4ecf-bfa8-d132a3d690da",
                    dataType: "Int",
                    name: "SecondarySocialImageBackgroundOption",
                    displayName: null,
                    visualNameShort: "Secondary Social Image Background Option",
                    visualNameFull: "Secondary Social Image Background Option (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "69bb1664-4a2c-4b77-af91-c032b0d1f0f0",
                },
                {
                    id: "a59af29b-9d37-4ccc-95bd-db11c7cf6151",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "170f595f-fbc4-4771-a34d-89b77f11fcba",
                    dataType: "Text",
                    name: "SecondarySocialImage_ImageUrl",
                    displayName: null,
                    visualNameShort: "Secondary Social Image Image Url",
                    visualNameFull: "Secondary Social Image Image Url (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "a59af29b-9d37-4ccc-95bd-db11c7cf6151",
                },
                {
                    id: "21d45fdc-51be-41e9-88c7-ca60bc4a78f7",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "3931addf-8b9a-46e3-9800-396904201110",
                    dataType: "Int",
                    name: "SocialImageBackgroundOption",
                    displayName: null,
                    visualNameShort: "Social Image Background Option",
                    visualNameFull: "Social Image Background Option (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "21d45fdc-51be-41e9-88c7-ca60bc4a78f7",
                },
                {
                    id: "db5ec98a-0213-49f3-b144-12853e41f7a6",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "39f3160a-a266-4929-95f1-ecc6c92831c6",
                    dataType: "Text",
                    name: "SocialImage_ImageUrl",
                    displayName: null,
                    visualNameShort: "Social Image Image Url",
                    visualNameFull: "Social Image Image Url (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "db5ec98a-0213-49f3-b144-12853e41f7a6",
                },
                {
                    id: "478a33e4-7b48-497f-a7cb-681e446c414e",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "43767ae4-91be-4bb7-9506-3eaf82a7e801",
                    dataType: "Text",
                    name: "StripeApiKey",
                    displayName: null,
                    visualNameShort: "Stripe Api Key",
                    visualNameFull: "Stripe Api Key (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "478a33e4-7b48-497f-a7cb-681e446c414e",
                },
                {
                    id: "af255c17-bda1-425e-bdca-107ac7209ad9",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "d0ac437d-b473-447f-b10e-e0624261dd12",
                    dataType: "Text",
                    name: "StripePublishableKey",
                    displayName: null,
                    visualNameShort: "Stripe Publishable Key",
                    visualNameFull: "Stripe Publishable Key (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "af255c17-bda1-425e-bdca-107ac7209ad9",
                },
                {
                    id: "dbd61cb4-0704-4939-a3d5-f86e635e7060",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "fe76443e-6ae1-44d3-98b2-e555d9766230",
                    dataType: "Text",
                    name: "TicketImage_ImageUrl",
                    displayName: null,
                    visualNameShort: "Ticket Image Image Url",
                    visualNameFull: "Ticket Image Image Url (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "dbd61cb4-0704-4939-a3d5-f86e635e7060",
                },
                {
                    id: "4551f64a-c6ae-4dbc-99f5-63bc089e0a4a",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "a75aa81b-798f-4020-89ec-359fd3e0d8c4",
                    dataType: "Int",
                    name: "TicketSort",
                    displayName: null,
                    visualNameShort: "Ticket Sort",
                    visualNameFull: "Ticket Sort (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "4551f64a-c6ae-4dbc-99f5-63bc089e0a4a",
                },
                {
                    id: "35aa8179-5a37-4e6c-8bd3-d50f85689ac1",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "4b7106d2-73bb-4e67-90fe-c181c38f6fba",
                    dataType: "Text",
                    name: "TicketSponsorImage_ImageUrl",
                    displayName: null,
                    visualNameShort: "Ticket Sponsor Image Image Url",
                    visualNameFull: "Ticket Sponsor Image Image Url (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "35aa8179-5a37-4e6c-8bd3-d50f85689ac1",
                },
                {
                    id: "2abac1ce-09f0-4cd0-8d01-89c50e51095e",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "e2e36530-1668-442a-be48-35d23cb5a5d4",
                    dataType: "Text",
                    name: "TicketingPolicy",
                    displayName: null,
                    visualNameShort: "Ticketing Policy",
                    visualNameFull: "Ticketing Policy (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "2abac1ce-09f0-4cd0-8d01-89c50e51095e",
                },
                {
                    id: "fa8f6789-40be-44eb-ac5e-c97f0071e2bc",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "c54be043-b452-4724-9ea3-d688be47c3ce",
                    dataType: "Int",
                    name: "TicketingProvider",
                    displayName: null,
                    visualNameShort: "Ticketing Provider",
                    visualNameFull: "Ticketing Provider (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "fa8f6789-40be-44eb-ac5e-c97f0071e2bc",
                },
                {
                    id: "8e4e8d2b-3e67-4824-9451-460c5ccdf4c7",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "c5133eb0-d690-4d2f-94a4-62494c1344e9",
                    dataType: "Text",
                    name: "TicketingProviderConfigJson",
                    displayName: null,
                    visualNameShort: "Ticketing Provider Config Json",
                    visualNameFull: "Ticketing Provider Config Json (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "8e4e8d2b-3e67-4824-9451-460c5ccdf4c7",
                },
                {
                    id: "f74708e5-369e-4a47-8afb-cf6dcba46ed4",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "0845463c-0cb6-4964-a1fb-2b408348a0cb",
                    dataType: "Text",
                    name: "TimezoneDefault",
                    displayName: null,
                    visualNameShort: "Timezone Default",
                    visualNameFull: "Timezone Default (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "f74708e5-369e-4a47-8afb-cf6dcba46ed4",
                },
                {
                    id: "230437dc-34c5-40d5-9304-3f51ef81c83f",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "0838c973-1cb0-445f-8fbe-8649bcc3b47a",
                    dataType: "Text",
                    name: "Url",
                    displayName: null,
                    visualNameShort: "Url",
                    visualNameFull: "Url (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "230437dc-34c5-40d5-9304-3f51ef81c83f",
                },
                {
                    id: "a760bd25-bc90-4c32-ac10-a56959478382",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "9ef75fd8-0339-43e0-aa87-e4ce9a264334",
                    dataType: "Text",
                    name: "VanityUrl",
                    displayName: null,
                    visualNameShort: "Vanity Url",
                    visualNameFull: "Vanity Url (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "a760bd25-bc90-4c32-ac10-a56959478382",
                },
                {
                    id: "bb75d97e-6eda-461b-b3ca-86feda9d1c76",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "fafa8d07-6566-4d89-bb31-adc2c05254fa",
                    dataSourceColumnID: "530ea50f-e0ba-4d40-ad2a-fe0fe4c4865f",
                    dataType: "Text",
                    name: "WebsiteBadgeImage_ImageUrl",
                    displayName: null,
                    visualNameShort: "Website Badge Image Image Url",
                    visualNameFull: "Website Badge Image Image Url (Organization)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "ec79228a-3e76-4645-bc87-b237904308ce",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Organization",
                    modelColumnID: "bb75d97e-6eda-461b-b3ca-86feda9d1c76",
                },
            ],
            rowLevelSecurity: {
                Endpoint: {
                    id: "9f2fb03a-2807-471c-af39-fa6020e89d63",
                    outerBlocks: [
                        {
                            innerBlocks: [
                                {
                                    leftValue: {
                                        dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                                        dataSourceTableID: "1cbe278b-036d-4d39-af6c-a6b508d75b76",
                                        $type: "TableBlockValue",
                                    },
                                    compareOp: "Contains",
                                    rightValue: {
                                        orAndBlock: {
                                            id: "7e2c1e0c-7f05-4707-bfc3-bd71d00f6c32",
                                            outerBlocks: [
                                                {
                                                    innerBlocks: [
                                                        {
                                                            leftValue: {
                                                                dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                                                                dataSourceTableID: "1cbe278b-036d-4d39-af6c-a6b508d75b76",
                                                                dataSourceColumnID: "d6affae4-fd50-4b67-8fe5-ff20a760e6be",
                                                                $type: "ColumnBlockValue",
                                                            },
                                                            compareOp: "NotEqual",
                                                            rightValue: {
                                                                dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                                                                dataSourceTableID: "1cbe278b-036d-4d39-af6c-a6b508d75b76",
                                                                dataSourceColumnID: "6e97ad9d-106d-4056-95b9-63f1ca386d44",
                                                                $type: "ColumnBlockValue",
                                                            },
                                                            $type: "AndBlock",
                                                        },
                                                    ],
                                                },
                                            ],
                                            $type: "TableSecurityConfig",
                                        },
                                        $type: "FilterBlockValue",
                                    },
                                    $type: "AndBlock",
                                },
                            ],
                        },
                    ],
                    $type: "TableSecurityConfig",
                },
                Static: null,
            },
            position: {
                x: -261,
                y: 632.5,
            },
            $type: "DataSourceModelTable",
        },
        {
            id: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
            dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
            dataSourceTableID: "d7069190-31e9-4a80-8e64-40342e6ac012",
            name: "Outing",
            visualName: "Outing",
            columns: [
                {
                    id: "2c1ba76e-d189-4657-a241-a48df1589b40",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "d7069190-31e9-4a80-8e64-40342e6ac012",
                    dataSourceColumnID: "2bdcff68-69f0-4ee0-af90-adb9ac9ef6e3",
                    dataType: "IDUUID",
                    name: "OutingID",
                    displayName: null,
                    visualNameShort: "Outing ID",
                    visualNameFull: "Outing ID (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    modelColumnID: "2c1ba76e-d189-4657-a241-a48df1589b40",
                },
                {
                    id: "e4626054-3f8c-4c87-9d94-857915cc84ab",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "d7069190-31e9-4a80-8e64-40342e6ac012",
                    dataSourceColumnID: "1b7c844f-719e-40a7-9258-f14f9072a018",
                    dataType: "Text",
                    name: "AccessCode",
                    displayName: null,
                    visualNameShort: "Access Code",
                    visualNameFull: "Access Code (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    modelColumnID: "e4626054-3f8c-4c87-9d94-857915cc84ab",
                },
                {
                    id: "5f6a3c19-70ac-48db-a6c7-0703b13af73f",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "d7069190-31e9-4a80-8e64-40342e6ac012",
                    dataSourceColumnID: "f749bddf-1279-4bf3-805f-5d5e4acc176a",
                    dataType: "Bool",
                    name: "AllowShowPurchasedOverride",
                    displayName: null,
                    visualNameShort: "Allow Show Purchased Override",
                    visualNameFull: "Allow Show Purchased Override (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    modelColumnID: "5f6a3c19-70ac-48db-a6c7-0703b13af73f",
                },
                {
                    id: "acf4c6cf-55df-4dbd-a96b-15e77250f62c",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "d7069190-31e9-4a80-8e64-40342e6ac012",
                    dataSourceColumnID: "9d40ec31-8b53-45b5-879b-cfd1112b9cc6",
                    dataType: "Int",
                    name: "ChargeFlatFeeToOrderIsZero",
                    displayName: null,
                    visualNameShort: "Charge Flat Fee To Order Is Zero",
                    visualNameFull: "Charge Flat Fee To Order Is Zero (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    modelColumnID: "acf4c6cf-55df-4dbd-a96b-15e77250f62c",
                },
                {
                    id: "dd044ad2-f53b-4511-a752-3058ad1d3851",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "d7069190-31e9-4a80-8e64-40342e6ac012",
                    dataSourceColumnID: "2b334e56-d9ca-4e7e-a8b9-9c320e9cda50",
                    dataType: "Int",
                    name: "ChargeFlatFeeToOrderNotZero",
                    displayName: null,
                    visualNameShort: "Charge Flat Fee To Order Not Zero",
                    visualNameFull: "Charge Flat Fee To Order Not Zero (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    modelColumnID: "dd044ad2-f53b-4511-a752-3058ad1d3851",
                },
                {
                    id: "46dde1aa-e92c-412b-b36e-7a2475a6f0c3",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "d7069190-31e9-4a80-8e64-40342e6ac012",
                    dataSourceColumnID: "c49b17df-eb77-4c53-9cb7-522ef4fba004",
                    dataType: "Text",
                    name: "Contact_Email",
                    displayName: null,
                    visualNameShort: "Contact Email",
                    visualNameFull: "Contact Email (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    modelColumnID: "46dde1aa-e92c-412b-b36e-7a2475a6f0c3",
                },
                {
                    id: "77b11b16-1945-4979-aeb8-2b427c7842f8",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "d7069190-31e9-4a80-8e64-40342e6ac012",
                    dataSourceColumnID: "e210bdb7-66cb-4033-ad4a-3eb53e29e2c8",
                    dataType: "Text",
                    name: "Contact_Name",
                    displayName: null,
                    visualNameShort: "Contact Name",
                    visualNameFull: "Contact Name (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    modelColumnID: "77b11b16-1945-4979-aeb8-2b427c7842f8",
                },
                {
                    id: "08c38179-1f08-416f-bd9f-d2ca4402936d",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "d7069190-31e9-4a80-8e64-40342e6ac012",
                    dataSourceColumnID: "e53d71db-0a5b-4d47-9e96-5aeeac526c12",
                    dataType: "Text",
                    name: "Contact_Phone",
                    displayName: null,
                    visualNameShort: "Contact Phone",
                    visualNameFull: "Contact Phone (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    modelColumnID: "08c38179-1f08-416f-bd9f-d2ca4402936d",
                },
                {
                    id: "14cb42bc-34f6-45d6-b866-489397823ebc",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "d7069190-31e9-4a80-8e64-40342e6ac012",
                    dataSourceColumnID: "e6a0c7f6-9122-47ff-b0e5-abc010cbf605",
                    dataType: "IDUUID",
                    name: "CreatorID",
                    displayName: null,
                    visualNameShort: "Creator ID",
                    visualNameFull: "Creator ID (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    modelColumnID: "14cb42bc-34f6-45d6-b866-489397823ebc",
                },
                {
                    id: "b43a3ec3-fa42-4ffa-9394-83e6542d12b4",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "d7069190-31e9-4a80-8e64-40342e6ac012",
                    dataSourceColumnID: "4f6c836e-dc31-4a21-9a44-1ffdfcca7626",
                    dataType: "DateTime",
                    name: "DateCreatedUtc",
                    displayName: null,
                    visualNameShort: "Date Created Utc",
                    visualNameFull: "Date Created Utc (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    modelColumnID: "b43a3ec3-fa42-4ffa-9394-83e6542d12b4",
                },
                {
                    id: "ef4ce126-7c6b-49d7-b20b-85ffc5a1381a",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "d7069190-31e9-4a80-8e64-40342e6ac012",
                    dataSourceColumnID: "af19cbc8-db70-40b7-aa7e-b52206d62c14",
                    dataType: "DateTime",
                    name: "DeadlineUtc",
                    displayName: null,
                    visualNameShort: "Deadline Utc",
                    visualNameFull: "Deadline Utc (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    modelColumnID: "ef4ce126-7c6b-49d7-b20b-85ffc5a1381a",
                },
                {
                    id: "6ab08550-b4df-42d3-961e-b345fd67a431",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "d7069190-31e9-4a80-8e64-40342e6ac012",
                    dataSourceColumnID: "c836f3d5-44f9-42df-932e-9f5d9bd917c6",
                    dataType: "Bool",
                    name: "Deleted",
                    displayName: null,
                    visualNameShort: "Deleted",
                    visualNameFull: "Deleted (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    modelColumnID: "6ab08550-b4df-42d3-961e-b345fd67a431",
                },
                {
                    id: "e4a3089e-8c01-45d7-b797-ba8d575c8020",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "d7069190-31e9-4a80-8e64-40342e6ac012",
                    dataSourceColumnID: "3340bd66-5b0e-4b04-b0f6-914ba603f4bc",
                    dataType: "Text",
                    name: "Delivery_DefaultEmailInstructions",
                    displayName: null,
                    visualNameShort: "Delivery Default Email Instructions",
                    visualNameFull: "Delivery Default Email Instructions (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    modelColumnID: "e4a3089e-8c01-45d7-b797-ba8d575c8020",
                },
                {
                    id: "1ae4c16d-add8-4b5a-997b-1655e550c521",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "d7069190-31e9-4a80-8e64-40342e6ac012",
                    dataSourceColumnID: "6afd3be6-61f1-4be3-93a2-f44ec1a18b95",
                    dataType: "Bool",
                    name: "Delivery_DefaultEnabled",
                    displayName: null,
                    visualNameShort: "Delivery Default Enabled",
                    visualNameFull: "Delivery Default Enabled (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    modelColumnID: "1ae4c16d-add8-4b5a-997b-1655e550c521",
                },
                {
                    id: "b5d8f964-4a43-461b-8a03-28591b80a8e9",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "d7069190-31e9-4a80-8e64-40342e6ac012",
                    dataSourceColumnID: "ea91b64e-2be6-4ef0-a499-0d1a5cf4f13a",
                    dataType: "Text",
                    name: "Delivery_DefaultInstructions",
                    displayName: null,
                    visualNameShort: "Delivery Default Instructions",
                    visualNameFull: "Delivery Default Instructions (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    modelColumnID: "b5d8f964-4a43-461b-8a03-28591b80a8e9",
                },
                {
                    id: "dcfedcec-a9ee-4c62-807e-d7e8e2d26a4f",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "d7069190-31e9-4a80-8e64-40342e6ac012",
                    dataSourceColumnID: "6377e96f-57ea-4fb6-bb0c-3358a7405c43",
                    dataType: "Text",
                    name: "Delivery_DefaultName",
                    displayName: null,
                    visualNameShort: "Delivery Default Name",
                    visualNameFull: "Delivery Default Name (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    modelColumnID: "dcfedcec-a9ee-4c62-807e-d7e8e2d26a4f",
                },
                {
                    id: "9c13c0a0-fd60-4930-afae-7c89e2cbda43",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "d7069190-31e9-4a80-8e64-40342e6ac012",
                    dataSourceColumnID: "c382ef94-afc1-462e-90f0-c927bf4a0822",
                    dataType: "Text",
                    name: "Delivery_WillCallEmailInstructions",
                    displayName: null,
                    visualNameShort: "Delivery Will Call Email Instructions",
                    visualNameFull: "Delivery Will Call Email Instructions (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    modelColumnID: "9c13c0a0-fd60-4930-afae-7c89e2cbda43",
                },
                {
                    id: "5fa64b6e-0d9d-4780-93c0-3c13eeba97ec",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "d7069190-31e9-4a80-8e64-40342e6ac012",
                    dataSourceColumnID: "38f3a145-dd55-48cc-bb6e-957893f38be7",
                    dataType: "Bool",
                    name: "Delivery_WillCallEnabled",
                    displayName: null,
                    visualNameShort: "Delivery Will Call Enabled",
                    visualNameFull: "Delivery Will Call Enabled (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    modelColumnID: "5fa64b6e-0d9d-4780-93c0-3c13eeba97ec",
                },
                {
                    id: "b7886e9a-f14a-4ece-a59d-699087fcd055",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "d7069190-31e9-4a80-8e64-40342e6ac012",
                    dataSourceColumnID: "55e31244-4de5-4bbd-b39a-4cdd276ca4a9",
                    dataType: "Text",
                    name: "Delivery_WillCallInstructions",
                    displayName: null,
                    visualNameShort: "Delivery Will Call Instructions",
                    visualNameFull: "Delivery Will Call Instructions (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    modelColumnID: "b7886e9a-f14a-4ece-a59d-699087fcd055",
                },
                {
                    id: "2dce82a9-4f0d-4b7f-83cd-1ed100c28fb6",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "d7069190-31e9-4a80-8e64-40342e6ac012",
                    dataSourceColumnID: "2483f29a-0ded-44a7-86fd-a4e77be67c5b",
                    dataType: "Text",
                    name: "Delivery_WillCallName",
                    displayName: null,
                    visualNameShort: "Delivery Will Call Name",
                    visualNameFull: "Delivery Will Call Name (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    modelColumnID: "2dce82a9-4f0d-4b7f-83cd-1ed100c28fb6",
                },
                {
                    id: "d6b3ceb3-50f0-42f7-a3ba-a710e5ea695e",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "d7069190-31e9-4a80-8e64-40342e6ac012",
                    dataSourceColumnID: "321742b4-eb81-4c4c-8720-7cc1292c3526",
                    dataType: "Double",
                    name: "Deposit",
                    displayName: null,
                    visualNameShort: "Deposit",
                    visualNameFull: "Deposit (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    modelColumnID: "d6b3ceb3-50f0-42f7-a3ba-a710e5ea695e",
                },
                {
                    id: "bd829e63-4233-4354-abfc-64c8695e1d1f",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "d7069190-31e9-4a80-8e64-40342e6ac012",
                    dataSourceColumnID: "64b6cca6-e33a-44ac-94d7-ee18bdb76d37",
                    dataType: "Text",
                    name: "Description",
                    displayName: null,
                    visualNameShort: "Description",
                    visualNameFull: "Description (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    modelColumnID: "bd829e63-4233-4354-abfc-64c8695e1d1f",
                },
                {
                    id: "f08fe046-e517-4aed-91e3-5c65adff3463",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "d7069190-31e9-4a80-8e64-40342e6ac012",
                    dataSourceColumnID: "80929dcd-8b03-4218-b940-bfeec3d6868c",
                    dataType: "Bool",
                    name: "Disabled",
                    displayName: null,
                    visualNameShort: "Disabled",
                    visualNameFull: "Disabled (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    modelColumnID: "f08fe046-e517-4aed-91e3-5c65adff3463",
                },
                {
                    id: "a1b2664d-a575-420f-b86d-c2d50dfc6ac9",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "d7069190-31e9-4a80-8e64-40342e6ac012",
                    dataSourceColumnID: "4ab7b027-20f2-4a59-aab4-31c9dbb085c3",
                    dataType: "IDUUID",
                    name: "EventID",
                    displayName: null,
                    visualNameShort: "Event ID",
                    visualNameFull: "Event ID (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    modelColumnID: "a1b2664d-a575-420f-b86d-c2d50dfc6ac9",
                },
                {
                    id: "da29ab91-33ca-4596-b52e-743df8327f25",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "d7069190-31e9-4a80-8e64-40342e6ac012",
                    dataSourceColumnID: "0e36e2af-47b2-42e3-a0f5-b49fd4fbe06e",
                    dataType: "IDUUID",
                    name: "FacebookEventCreatorID",
                    displayName: null,
                    visualNameShort: "Facebook Event Creator ID",
                    visualNameFull: "Facebook Event Creator ID (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    modelColumnID: "da29ab91-33ca-4596-b52e-743df8327f25",
                },
                {
                    id: "faf34c8f-2f45-495b-b819-c86d3096a3ef",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "d7069190-31e9-4a80-8e64-40342e6ac012",
                    dataSourceColumnID: "aef790d5-84e8-49b0-b935-277079a090b4",
                    dataType: "Text",
                    name: "FacebookEventID",
                    displayName: null,
                    visualNameShort: "Facebook Event ID",
                    visualNameFull: "Facebook Event ID (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    modelColumnID: "faf34c8f-2f45-495b-b819-c86d3096a3ef",
                },
                {
                    id: "7229eee1-d889-4e20-a416-0d3c3eeff12e",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "d7069190-31e9-4a80-8e64-40342e6ac012",
                    dataSourceColumnID: "2573fe85-f618-48f2-9c4c-669ece716537",
                    dataType: "Text",
                    name: "FacebookPageID",
                    displayName: null,
                    visualNameShort: "Facebook Page ID",
                    visualNameFull: "Facebook Page ID (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    modelColumnID: "7229eee1-d889-4e20-a416-0d3c3eeff12e",
                },
                {
                    id: "21ba8bab-d779-44c4-af62-74227a2e7358",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "d7069190-31e9-4a80-8e64-40342e6ac012",
                    dataSourceColumnID: "a6014dc9-0683-4fae-a1b9-47f59bfec9f3",
                    dataType: "Text",
                    name: "FacebookPageName",
                    displayName: null,
                    visualNameShort: "Facebook Page Name",
                    visualNameFull: "Facebook Page Name (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    modelColumnID: "21ba8bab-d779-44c4-af62-74227a2e7358",
                },
                {
                    id: "5f3b6d6c-c72e-4c3b-8858-497e28dc3198",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "d7069190-31e9-4a80-8e64-40342e6ac012",
                    dataSourceColumnID: "1bc764f2-8b8b-40ac-b303-6161436dbecf",
                    dataType: "Bool",
                    name: "FeeStructure_ApplyAtPayout",
                    displayName: null,
                    visualNameShort: "Fee Structure Apply At Payout",
                    visualNameFull: "Fee Structure Apply At Payout (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    modelColumnID: "5f3b6d6c-c72e-4c3b-8858-497e28dc3198",
                },
                {
                    id: "71c4b1c1-5c24-46b7-9ff2-ab241eaae081",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "d7069190-31e9-4a80-8e64-40342e6ac012",
                    dataSourceColumnID: "6e9ecd40-6b16-4c8d-8891-660ab04888d0",
                    dataType: "Bool",
                    name: "Fundraiser",
                    displayName: null,
                    visualNameShort: "Fundraiser",
                    visualNameFull: "Fundraiser (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    modelColumnID: "71c4b1c1-5c24-46b7-9ff2-ab241eaae081",
                },
                {
                    id: "e581fe41-330a-4051-8f34-d5bec8bf6028",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "d7069190-31e9-4a80-8e64-40342e6ac012",
                    dataSourceColumnID: "f97f4a97-10d4-46f2-96ad-6fa609884824",
                    dataType: "Bool",
                    name: "GroupCanRequestPayout",
                    displayName: null,
                    visualNameShort: "Group Can Request Payout",
                    visualNameFull: "Group Can Request Payout (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    modelColumnID: "e581fe41-330a-4051-8f34-d5bec8bf6028",
                },
                {
                    id: "d418d519-5daa-41e0-8e41-87bf94d92d54",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "d7069190-31e9-4a80-8e64-40342e6ac012",
                    dataSourceColumnID: "56dbb98a-8d3d-430d-9d1e-412b07c8bbdd",
                    dataType: "IDUUID",
                    name: "GroupID",
                    displayName: null,
                    visualNameShort: "Group ID",
                    visualNameFull: "Group ID (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    modelColumnID: "d418d519-5daa-41e0-8e41-87bf94d92d54",
                },
                {
                    id: "8ca374e2-37b3-4ed0-b49a-093d7f292a81",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "d7069190-31e9-4a80-8e64-40342e6ac012",
                    dataSourceColumnID: "8fcd9643-b4c9-49d7-963f-dbfe4b85e8ee",
                    dataType: "IDUUID",
                    name: "GroupPayoutDetailsID",
                    displayName: null,
                    visualNameShort: "Group Payout Details ID",
                    visualNameFull: "Group Payout Details ID (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    modelColumnID: "8ca374e2-37b3-4ed0-b49a-093d7f292a81",
                },
                {
                    id: "76fce8bd-dfe9-468f-9492-66480b3ccd47",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "d7069190-31e9-4a80-8e64-40342e6ac012",
                    dataSourceColumnID: "ab9cd19d-0f7d-4492-9639-5beeb3ddd841",
                    dataType: "Bool",
                    name: "LowTicketWarningSent",
                    displayName: null,
                    visualNameShort: "Low Ticket Warning Sent",
                    visualNameFull: "Low Ticket Warning Sent (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    modelColumnID: "76fce8bd-dfe9-468f-9492-66480b3ccd47",
                },
                {
                    id: "1f5cbd10-5634-4f0c-a01e-326adb683f87",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "d7069190-31e9-4a80-8e64-40342e6ac012",
                    dataSourceColumnID: "94db4318-3ece-4faa-bc33-3265c332ab90",
                    dataType: "Int",
                    name: "MaxTicketsPerAccount",
                    displayName: null,
                    visualNameShort: "Max Tickets Per Account",
                    visualNameFull: "Max Tickets Per Account (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    modelColumnID: "1f5cbd10-5634-4f0c-a01e-326adb683f87",
                },
                {
                    id: "bea1cdd9-2dab-4ea2-a347-9c1ac5fdbc4b",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "d7069190-31e9-4a80-8e64-40342e6ac012",
                    dataSourceColumnID: "aeb11e7d-9365-4e12-bf2e-c16cb8ac88e3",
                    dataType: "Text",
                    name: "MeetingLocation",
                    displayName: null,
                    visualNameShort: "Meeting Location",
                    visualNameFull: "Meeting Location (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    modelColumnID: "bea1cdd9-2dab-4ea2-a347-9c1ac5fdbc4b",
                },
                {
                    id: "9ff32bd0-d114-4fec-9182-d0b3cf47783c",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "d7069190-31e9-4a80-8e64-40342e6ac012",
                    dataSourceColumnID: "3c8f0efd-0f04-47e4-adcc-badd2b06916a",
                    dataType: "Int",
                    name: "MeetingTime",
                    displayName: null,
                    visualNameShort: "Meeting Time",
                    visualNameFull: "Meeting Time (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    modelColumnID: "9ff32bd0-d114-4fec-9182-d0b3cf47783c",
                },
                {
                    id: "b25a0360-51f8-4f1a-a363-b3de0d57d998",
                    calculations: [
                        {
                            transformationID: "af57a849-4193-4d03-a61b-19af6d0f20d2",
                            name: "New Column 1140",
                            default: true,
                            viewReferenceName: "verbcalccol_af57a849_4193_4d03_a61b_19af6d0f20d2_v_201117022710549",
                            columnReferenceName: null,
                            versionDetails: "NotPublished",
                            publishedOn: null,
                        },
                    ],
                    dataType: "Unsupported",
                    name: "New Column 1140",
                    displayName: null,
                    visualNameShort: "New Column 1140",
                    visualNameFull: "New Column 1140 (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "b25a0360-51f8-4f1a-a363-b3de0d57d998",
                },
                {
                    id: "3927b946-19c0-493b-b0d6-0c4a691fef89",
                    calculations: [
                        {
                            transformationID: "d4a063af-2cc2-4006-bfbc-cda6397929ab",
                            name: "New Column 2800",
                            default: true,
                            viewReferenceName: null,
                            columnReferenceName: null,
                            versionDetails: "NotPublished",
                            publishedOn: null,
                        },
                    ],
                    dataType: "Text",
                    name: "New Column 2800",
                    displayName: null,
                    visualNameShort: "New Column 2800",
                    visualNameFull: "New Column 2800 (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "3927b946-19c0-493b-b0d6-0c4a691fef89",
                },
                {
                    id: "e231f146-1780-4a2d-b099-cfd04a678e0a",
                    calculations: [
                        {
                            transformationID: "80b04581-c276-4744-8e2c-a9e661da5eb9",
                            name: "New Column 4077",
                            default: true,
                            viewReferenceName: null,
                            columnReferenceName: null,
                            versionDetails: "NotPublished",
                            publishedOn: null,
                        },
                    ],
                    dataType: "Text",
                    name: "New Column 4077",
                    displayName: null,
                    visualNameShort: "New Column 4077",
                    visualNameFull: "New Column 4077 (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "e231f146-1780-4a2d-b099-cfd04a678e0a",
                },
                {
                    id: "fe7e81c9-9960-4dfe-ad92-c8481dcff6fe",
                    calculations: [
                        {
                            transformationID: "e6f6a6fe-2d5e-4e7e-8172-fdd8a3e53e48",
                            name: "New Column 8253",
                            default: true,
                            viewReferenceName: null,
                            columnReferenceName: null,
                            versionDetails: "NotPublished",
                            publishedOn: null,
                        },
                    ],
                    dataType: "Text",
                    name: "New Column 8253",
                    displayName: null,
                    visualNameShort: "New Column 8253",
                    visualNameFull: "New Column 8253 (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "fe7e81c9-9960-4dfe-ad92-c8481dcff6fe",
                },
                {
                    id: "61e87df0-e43a-4c63-a8d9-c97f749d66b1",
                    calculations: [
                        {
                            transformationID: "c162b4d3-6cf8-4aef-9807-ec0a05496aec",
                            name: "New Column 9135",
                            default: true,
                            viewReferenceName: null,
                            columnReferenceName: null,
                            versionDetails: "NotPublished",
                            publishedOn: null,
                        },
                    ],
                    dataType: "Text",
                    name: "New Column 9135",
                    displayName: null,
                    visualNameShort: "New Column 9135",
                    visualNameFull: "New Column 9135 (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "61e87df0-e43a-4c63-a8d9-c97f749d66b1",
                },
                {
                    id: "c7160a0f-95ce-4c4b-b688-cf31d4ad5d3f",
                    calculations: [
                        {
                            transformationID: "f49ac932-b9c7-419b-b3a8-b433f4940ab7",
                            name: "New Column 9222",
                            default: true,
                            viewReferenceName: "verbcalccol_f49ac932_b9c7_419b_b3a8_b433f4940ab7",
                            columnReferenceName: null,
                            versionDetails: "NotPublished",
                            publishedOn: null,
                        },
                    ],
                    dataType: "Unsupported",
                    name: "New Column 9222",
                    displayName: null,
                    visualNameShort: "New Column 9222",
                    visualNameFull: "New Column 9222 (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "c7160a0f-95ce-4c4b-b688-cf31d4ad5d3f",
                },
                {
                    id: "32b74128-e5b9-4a4f-bf33-d1e1f5cf340f",
                    calculations: [
                        {
                            transformationID: "2231e4d7-6220-4794-9286-20ec36d6b169",
                            name: "New Column 9464",
                            default: true,
                            viewReferenceName: null,
                            columnReferenceName: null,
                            versionDetails: "NotPublished",
                            publishedOn: null,
                        },
                    ],
                    dataType: "Text",
                    name: "New Column 9464",
                    displayName: null,
                    visualNameShort: "New Column 9464",
                    visualNameFull: "New Column 9464 (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "32b74128-e5b9-4a4f-bf33-d1e1f5cf340f",
                },
                {
                    id: "2911f8bd-fcca-45cb-8098-30865124645a",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "d7069190-31e9-4a80-8e64-40342e6ac012",
                    dataSourceColumnID: "7d526537-8db3-485f-9ae0-ce2f205abd8b",
                    dataType: "Double",
                    name: "OpenInventoryDeadlineMins",
                    displayName: null,
                    visualNameShort: "Open Inventory Deadline Mins",
                    visualNameFull: "Open Inventory Deadline Mins (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    modelColumnID: "2911f8bd-fcca-45cb-8098-30865124645a",
                },
                {
                    id: "e2aea375-7422-4ab1-bed1-f7c22cf96f12",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "d7069190-31e9-4a80-8e64-40342e6ac012",
                    dataSourceColumnID: "24add36d-f631-4397-b141-5d59fa6973e5",
                    dataType: "Bool",
                    name: "OpenInventoryFees_ChargeFeeOnDiscounted",
                    displayName: null,
                    visualNameShort: "Open Inventory Fees Charge Fee On Discounted",
                    visualNameFull: "Open Inventory Fees Charge Fee On Discounted (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    modelColumnID: "e2aea375-7422-4ab1-bed1-f7c22cf96f12",
                },
                {
                    id: "20ca984b-b91a-4643-b8c9-45fbdad2c7a0",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "d7069190-31e9-4a80-8e64-40342e6ac012",
                    dataSourceColumnID: "e12e76a4-e49c-4633-bfdc-856b99fe8efd",
                    dataType: "Int",
                    name: "OpenInventorySoldOutThreshold",
                    displayName: null,
                    visualNameShort: "Open Inventory Sold Out Threshold",
                    visualNameFull: "Open Inventory Sold Out Threshold (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    modelColumnID: "20ca984b-b91a-4643-b8c9-45fbdad2c7a0",
                },
                {
                    id: "b85cbe1c-aef4-4f67-8c6f-e4b36be79fbd",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "d7069190-31e9-4a80-8e64-40342e6ac012",
                    dataSourceColumnID: "02abce5c-bb4e-4d12-94bd-d6d8c8267e8c",
                    dataType: "Int",
                    name: "OrgImageOption",
                    displayName: null,
                    visualNameShort: "Org Image Option",
                    visualNameFull: "Org Image Option (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    modelColumnID: "b85cbe1c-aef4-4f67-8c6f-e4b36be79fbd",
                },
                {
                    id: "2e51a556-9743-4869-a0e8-8bca9e254bd9",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "d7069190-31e9-4a80-8e64-40342e6ac012",
                    dataSourceColumnID: "eb63a9ef-2b1b-401d-ab0c-3d95c335605e",
                    dataType: "Text",
                    name: "OutingImage",
                    displayName: null,
                    visualNameShort: "Outing Image",
                    visualNameFull: "Outing Image (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    modelColumnID: "2e51a556-9743-4869-a0e8-8bca9e254bd9",
                },
                {
                    id: "33a5d8d1-5e9b-42c4-868f-62483bc46595",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "d7069190-31e9-4a80-8e64-40342e6ac012",
                    dataSourceColumnID: "9eaac8a7-31e1-4d86-b5b0-627856507d07",
                    dataType: "Text",
                    name: "OutingVideo",
                    displayName: null,
                    visualNameShort: "Outing Video",
                    visualNameFull: "Outing Video (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    modelColumnID: "33a5d8d1-5e9b-42c4-868f-62483bc46595",
                },
                {
                    id: "2d26e4f9-ff6e-4c27-9715-68a977b06231",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "d7069190-31e9-4a80-8e64-40342e6ac012",
                    dataSourceColumnID: "90f1f8ac-ddf1-4c2b-88ca-72176ac06d8f",
                    dataType: "Int",
                    name: "PageAccess",
                    displayName: null,
                    visualNameShort: "Page Access",
                    visualNameFull: "Page Access (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    modelColumnID: "2d26e4f9-ff6e-4c27-9715-68a977b06231",
                },
                {
                    id: "5930896b-ecb3-4fa5-a19c-b1fb10273ac9",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "d7069190-31e9-4a80-8e64-40342e6ac012",
                    dataSourceColumnID: "105ac4e3-1a2e-4761-ba58-5c82a2cb7102",
                    dataType: "Int",
                    name: "Reason",
                    displayName: null,
                    visualNameShort: "Reason",
                    visualNameFull: "Reason (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    modelColumnID: "5930896b-ecb3-4fa5-a19c-b1fb10273ac9",
                },
                {
                    id: "2269dc40-d2dc-4d3d-8aa2-a7e97c803c93",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "d7069190-31e9-4a80-8e64-40342e6ac012",
                    dataSourceColumnID: "7444611a-f3d7-4f15-83ef-2b8ff26edba1",
                    dataType: "Bool",
                    name: "ReservationFees_ChargeFeeOnDiscounted",
                    displayName: null,
                    visualNameShort: "Reservation Fees Charge Fee On Discounted",
                    visualNameFull: "Reservation Fees Charge Fee On Discounted (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    modelColumnID: "2269dc40-d2dc-4d3d-8aa2-a7e97c803c93",
                },
                {
                    id: "dfaf79ad-fa01-4218-90cc-6c63cfbea847",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "d7069190-31e9-4a80-8e64-40342e6ac012",
                    dataSourceColumnID: "55e200b8-0bb4-42a7-9889-87d972f41386",
                    dataType: "Bool",
                    name: "ShowOnGroupPage",
                    displayName: null,
                    visualNameShort: "Show On Group Page",
                    visualNameFull: "Show On Group Page (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    modelColumnID: "dfaf79ad-fa01-4218-90cc-6c63cfbea847",
                },
                {
                    id: "87ee04da-aacc-445c-b118-f1bc467ca550",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "d7069190-31e9-4a80-8e64-40342e6ac012",
                    dataSourceColumnID: "46b315e8-09c3-406f-b0b9-c6101db7dbf3",
                    dataType: "Text",
                    name: "TicketInvitationMessage",
                    displayName: null,
                    visualNameShort: "Ticket Invitation Message",
                    visualNameFull: "Ticket Invitation Message (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    modelColumnID: "87ee04da-aacc-445c-b118-f1bc467ca550",
                },
                {
                    id: "390f6326-ff71-46f9-9183-277d51210f12",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "d7069190-31e9-4a80-8e64-40342e6ac012",
                    dataSourceColumnID: "e083e750-f671-41d1-8567-fc2f417fa220",
                    dataType: "Int",
                    name: "TicketTypes",
                    displayName: null,
                    visualNameShort: "Ticket Types",
                    visualNameFull: "Ticket Types (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    modelColumnID: "390f6326-ff71-46f9-9183-277d51210f12",
                },
                {
                    id: "8b9a30f8-dd27-4106-8080-3213c78ad354",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "d7069190-31e9-4a80-8e64-40342e6ac012",
                    dataSourceColumnID: "97a138e5-201a-4a0e-9984-57ec067c6599",
                    dataType: "Int",
                    name: "TicketingProvider",
                    displayName: null,
                    visualNameShort: "Ticketing Provider",
                    visualNameFull: "Ticketing Provider (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    modelColumnID: "8b9a30f8-dd27-4106-8080-3213c78ad354",
                },
                {
                    id: "62135d04-679a-41dc-b81e-0699bc9af56e",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "d7069190-31e9-4a80-8e64-40342e6ac012",
                    dataSourceColumnID: "565cbae3-b953-40bb-9439-4643c5ef1776",
                    dataType: "Text",
                    name: "TicketingProviderConfigJson",
                    displayName: null,
                    visualNameShort: "Ticketing Provider Config Json",
                    visualNameFull: "Ticketing Provider Config Json (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    modelColumnID: "62135d04-679a-41dc-b81e-0699bc9af56e",
                },
                {
                    id: "c3c11803-2bb7-4d60-8c92-0cb1b3f27b59",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "d7069190-31e9-4a80-8e64-40342e6ac012",
                    dataSourceColumnID: "1d0481b3-07dd-4801-a4d6-e9b715dcfac6",
                    dataType: "Text",
                    name: "Title",
                    displayName: null,
                    visualNameShort: "Title",
                    visualNameFull: "Title (Outing)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "c4dc10bb-d849-486a-8c8f-79d185034d6a",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Outing",
                    modelColumnID: "c3c11803-2bb7-4d60-8c92-0cb1b3f27b59",
                },
            ],
            rowLevelSecurity: {},
            position: {
                x: 99,
                y: 632.5,
            },
            $type: "DataSourceModelTable",
        },
        {
            id: "5d53c847-8f18-4d0c-a149-3fd6d094e11c",
            dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
            dataSourceTableID: "68bb8d12-988f-45f9-908d-b3be49c58623",
            name: "Participant",
            visualName: "Participant",
            columns: [
                {
                    id: "0e6cfe9d-fe17-4312-b93e-1ed4383913d3",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "68bb8d12-988f-45f9-908d-b3be49c58623",
                    dataSourceColumnID: "e29306fc-58bd-45ce-a4da-98fef54d62dc",
                    dataType: "IDUUID",
                    name: "ParticipantID",
                    displayName: null,
                    visualNameShort: "Participant ID",
                    visualNameFull: "Participant ID (Participant)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "5d53c847-8f18-4d0c-a149-3fd6d094e11c",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Participant",
                    modelColumnID: "0e6cfe9d-fe17-4312-b93e-1ed4383913d3",
                },
                {
                    id: "5111a677-d43b-4f82-8096-95ebc23d7312",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "68bb8d12-988f-45f9-908d-b3be49c58623",
                    dataSourceColumnID: "8acf2480-e9c8-4499-af19-9a8c6b61901e",
                    dataType: "IDUUID",
                    name: "AccountID",
                    displayName: null,
                    visualNameShort: "Account ID",
                    visualNameFull: "Account ID (Participant)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "5d53c847-8f18-4d0c-a149-3fd6d094e11c",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Participant",
                    modelColumnID: "5111a677-d43b-4f82-8096-95ebc23d7312",
                },
                {
                    id: "046f933f-458e-4ddf-8a70-bcb7120edd6c",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "68bb8d12-988f-45f9-908d-b3be49c58623",
                    dataSourceColumnID: "ecc2299a-60c0-48bc-9dda-4f9cc283c3df",
                    dataType: "Text",
                    name: "Discriminator",
                    displayName: null,
                    visualNameShort: "Discriminator",
                    visualNameFull: "Discriminator (Participant)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "5d53c847-8f18-4d0c-a149-3fd6d094e11c",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Participant",
                    modelColumnID: "046f933f-458e-4ddf-8a70-bcb7120edd6c",
                },
                {
                    id: "9162dd60-087b-4a06-9175-230dbdf19f46",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "68bb8d12-988f-45f9-908d-b3be49c58623",
                    dataSourceColumnID: "246cea6a-a61f-4ca3-86e6-42077fc298f8",
                    dataType: "Text",
                    name: "Email",
                    displayName: null,
                    visualNameShort: "Email",
                    visualNameFull: "Email (Participant)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "5d53c847-8f18-4d0c-a149-3fd6d094e11c",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Participant",
                    modelColumnID: "9162dd60-087b-4a06-9175-230dbdf19f46",
                },
                {
                    id: "d0a76697-50c8-4014-ae8e-097a8260f17c",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "68bb8d12-988f-45f9-908d-b3be49c58623",
                    dataSourceColumnID: "c7a6b731-32ea-4ffb-8589-9f144f486a5d",
                    dataType: "Text",
                    name: "Name",
                    displayName: null,
                    visualNameShort: "Name",
                    visualNameFull: "Name (Participant)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "5d53c847-8f18-4d0c-a149-3fd6d094e11c",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Participant",
                    modelColumnID: "d0a76697-50c8-4014-ae8e-097a8260f17c",
                },
                {
                    id: "5cdfaf7a-dba5-7651-db00-32072180a07f",
                    calculations: [
                        {
                            transformationID: "63a5bd85-fb50-443f-9a32-c4d021221094",
                            name: "New Column 2869",
                            default: true,
                            viewReferenceName: "verbcalccol_63a5bd85_fb50_443f_9a32_c4d021221094",
                            columnReferenceName: null,
                            versionDetails: "NotPublished",
                            publishedOn: null,
                        },
                    ],
                    dataType: "Unsupported",
                    name: "New Column 2869",
                    displayName: null,
                    visualNameShort: "New Column 2869",
                    visualNameFull: "New Column 2869 (Participant)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "5d53c847-8f18-4d0c-a149-3fd6d094e11c",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Participant",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "5cdfaf7a-dba5-7651-db00-32072180a07f",
                },
                {
                    id: "7e4639a3-660d-427f-8881-45dd1c43c48e",
                    calculations: [
                        {
                            transformationID: "0bfa718e-670b-44e7-a91e-1ba679c81aa2",
                            name: "New Column 5608",
                            default: true,
                            viewReferenceName: "verbcalccol_0bfa718e_670b_44e7_a91e_1ba679c81aa2",
                            columnReferenceName: null,
                            versionDetails: "NotPublished",
                            publishedOn: null,
                        },
                    ],
                    dataType: "Unsupported",
                    name: "New Column 5608",
                    displayName: null,
                    visualNameShort: "New Column 5608",
                    visualNameFull: "New Column 5608 (Participant)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "5d53c847-8f18-4d0c-a149-3fd6d094e11c",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Participant",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "7e4639a3-660d-427f-8881-45dd1c43c48e",
                },
                {
                    id: "7a60b607-1751-9cb1-66ff-e3b5dc70715f",
                    calculations: [
                        {
                            transformationID: "0629dd94-3103-43b5-9886-94adce74fda8",
                            name: "New Column 6711",
                            default: true,
                            viewReferenceName: "verbcalccol_0629dd94_3103_43b5_9886_94adce74fda8",
                            columnReferenceName: null,
                            versionDetails: "NotPublished",
                            publishedOn: null,
                        },
                    ],
                    dataType: "Unsupported",
                    name: "New Column 6711",
                    displayName: null,
                    visualNameShort: "New Column 6711",
                    visualNameFull: "New Column 6711 (Participant)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "5d53c847-8f18-4d0c-a149-3fd6d094e11c",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Participant",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "7a60b607-1751-9cb1-66ff-e3b5dc70715f",
                },
                {
                    id: "2de0a408-d5c5-4c91-a31d-33dc095f0d05",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "68bb8d12-988f-45f9-908d-b3be49c58623",
                    dataSourceColumnID: "d88ccbd9-2a21-4d07-95b8-a0fff24d87d0",
                    dataType: "Int",
                    name: "OpenInventoryAccess",
                    displayName: null,
                    visualNameShort: "Open Inventory Access",
                    visualNameFull: "Open Inventory Access (Participant)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "5d53c847-8f18-4d0c-a149-3fd6d094e11c",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Participant",
                    modelColumnID: "2de0a408-d5c5-4c91-a31d-33dc095f0d05",
                },
                {
                    id: "a444bd51-19a3-4f5b-96f0-66a58d308931",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "68bb8d12-988f-45f9-908d-b3be49c58623",
                    dataSourceColumnID: "99e45a47-0186-4cdc-91b6-85bb7de2d488",
                    dataType: "IDUUID",
                    name: "OwnerID",
                    displayName: null,
                    visualNameShort: "Owner ID",
                    visualNameFull: "Owner ID (Participant)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "5d53c847-8f18-4d0c-a149-3fd6d094e11c",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Participant",
                    modelColumnID: "a444bd51-19a3-4f5b-96f0-66a58d308931",
                },
                {
                    id: "c5d19563-fc61-4bdb-a4ef-9c879e8c29a8",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "68bb8d12-988f-45f9-908d-b3be49c58623",
                    dataSourceColumnID: "74345ba5-23ff-4f8a-96d5-814a2c085bec",
                    dataType: "Text",
                    name: "RepID",
                    displayName: null,
                    visualNameShort: "Rep ID",
                    visualNameFull: "Rep ID (Participant)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "5d53c847-8f18-4d0c-a149-3fd6d094e11c",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Participant",
                    modelColumnID: "c5d19563-fc61-4bdb-a4ef-9c879e8c29a8",
                },
                {
                    id: "93b91d6d-11b7-4132-9855-d21e768e41ea",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "68bb8d12-988f-45f9-908d-b3be49c58623",
                    dataSourceColumnID: "6a8b644e-e803-4bc8-b216-3c13f02b1ff7",
                    dataType: "Int",
                    name: "Status",
                    displayName: null,
                    visualNameShort: "Status",
                    visualNameFull: "Status (Participant)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "5d53c847-8f18-4d0c-a149-3fd6d094e11c",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Participant",
                    modelColumnID: "93b91d6d-11b7-4132-9855-d21e768e41ea",
                },
            ],
            rowLevelSecurity: {},
            position: {
                x: 459,
                y: 592.5,
            },
            $type: "DataSourceModelTable",
        },
        {
            id: "a053bedd-38f6-42d2-b3c7-c7c76146cce1",
            transformationID: "97d22a98-081c-4771-8fd9-81d2deee3f28",
            tableReferenceName: "verbview_97d22a98_081c_4771_8fd9_81d2deee3f28_v_210727020616348",
            versionDetails: "LatestNotPublished",
            publishedOn: "2021-07-27T02:06:16.954Z",
            excludeFromModel: false,
            name: "QA test table",
            visualName: "QA Test Table",
            columns: [
                {
                    id: "f6eda323-21de-4fc4-954e-a90c3e7da435",
                    columnReferenceName: "verbcol_new_random_column",
                    name: "New random column",
                    displayName: null,
                    visualNameShort: "New Random Column",
                    visualNameFull: "New Random Column (QA Test Table)",
                    dataType: "Double",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "a053bedd-38f6-42d2-b3c7-c7c76146cce1",
                    modelTableType: "ViewModelTable",
                    modelTableName: "QA test table",
                    modelColumnID: "f6eda323-21de-4fc4-954e-a90c3e7da435",
                },
                {
                    id: "be2a89f8-63b8-3a13-4341-0e18141e217d",
                    columnReferenceName: "verbcol_new_random_column",
                    name: "New random column",
                    displayName: null,
                    visualNameShort: "New Random Column",
                    visualNameFull: "New Random Column (QA Test Table)",
                    dataType: "Double",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "a053bedd-38f6-42d2-b3c7-c7c76146cce1",
                    modelTableType: "ViewModelTable",
                    modelTableName: "QA test table",
                    modelColumnID: "be2a89f8-63b8-3a13-4341-0e18141e217d",
                },
                {
                    id: "d38856c2-c8ff-7afe-d375-046e6bec076d",
                    columnReferenceName: "groupmatics_dbo_order_orderid",
                    name: "OrderID",
                    displayName: null,
                    visualNameShort: "Order ID",
                    visualNameFull: "Order ID (QA Test Table)",
                    dataType: "IDUUID",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "a053bedd-38f6-42d2-b3c7-c7c76146cce1",
                    modelTableType: "ViewModelTable",
                    modelTableName: "QA test table",
                    modelColumnID: "d38856c2-c8ff-7afe-d375-046e6bec076d",
                },
            ],
            rowLevelSecurity: {},
            position: {
                x: 819,
                y: 632.5,
            },
            $type: "ViewModelTable",
        },
        {
            id: "f61ce32a-8879-c7a2-0a62-6357bed42aa3",
            transformationID: "2f52100d-c85d-490c-81cb-5ef33cd5dfce",
            tableReferenceName: "verbview_2f52100d_c85d_490c_81cb_5ef33cd5dfce_v_220309084147836",
            versionDetails: "LatestNotPublished",
            publishedOn: "2022-03-09T08:41:48.064Z",
            excludeFromModel: true,
            name: "Selected Columns for League",
            visualName: "Selected Columns For League",
            columns: [
                {
                    id: "f31ab7ec-8b84-07ca-dbaf-45799cca3dda",
                    columnReferenceName: "leageabbr",
                    name: "LeageAbbr",
                    displayName: null,
                    visualNameShort: "Leage Abbr",
                    visualNameFull: "Leage Abbr (Selected Columns For League)",
                    dataType: "Text",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "f61ce32a-8879-c7a2-0a62-6357bed42aa3",
                    modelTableType: "ViewModelTable",
                    modelTableName: "Selected Columns for League",
                    modelColumnID: "f31ab7ec-8b84-07ca-dbaf-45799cca3dda",
                },
                {
                    id: "3b06a216-9420-f5ff-dc68-b409643b9b8c",
                    columnReferenceName: "league",
                    name: "League",
                    displayName: null,
                    visualNameShort: "League",
                    visualNameFull: "League (Selected Columns For League)",
                    dataType: "IDInt",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "ViewModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "f61ce32a-8879-c7a2-0a62-6357bed42aa3",
                    modelTableType: "ViewModelTable",
                    modelTableName: "Selected Columns for League",
                    modelColumnID: "3b06a216-9420-f5ff-dc68-b409643b9b8c",
                },
            ],
            rowLevelSecurity: {},
            position: {
                x: -1750,
                y: 752.5,
            },
            $type: "ViewModelTable",
        },
        {
            id: "93eb434d-e5ec-4c3c-82db-e3857c89314e",
            dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
            dataSourceTableID: "419ce21f-e367-4355-b88b-f4d5169a88d4",
            name: "Ticket",
            visualName: "Ticket",
            columns: [
                {
                    id: "6910efb0-cd66-4b45-b9ae-373bae75911a",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "419ce21f-e367-4355-b88b-f4d5169a88d4",
                    dataSourceColumnID: "cd5781dc-fdcb-4be0-802f-654c3a04a87e",
                    dataType: "IDUUID",
                    name: "TicketID",
                    displayName: null,
                    visualNameShort: "Ticket ID",
                    visualNameFull: "Ticket ID (Ticket)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "93eb434d-e5ec-4c3c-82db-e3857c89314e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Ticket",
                    modelColumnID: "6910efb0-cd66-4b45-b9ae-373bae75911a",
                },
                {
                    id: "4d2371d6-863d-4173-9ef1-d589266344e6",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "419ce21f-e367-4355-b88b-f4d5169a88d4",
                    dataSourceColumnID: "9078dcbf-865e-4fff-ab0d-b52c9770f143",
                    dataType: "Double",
                    name: "AmountToGroup",
                    displayName: null,
                    visualNameShort: "Amount To Group",
                    visualNameFull: "Amount To Group (Ticket)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "93eb434d-e5ec-4c3c-82db-e3857c89314e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Ticket",
                    modelColumnID: "4d2371d6-863d-4173-9ef1-d589266344e6",
                },
                {
                    id: "64e1079f-44a5-4b5d-bbeb-f4e2fbb6e399",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "419ce21f-e367-4355-b88b-f4d5169a88d4",
                    dataSourceColumnID: "61dcb3a0-c441-4378-8871-c93fd880d0f5",
                    dataType: "Double",
                    name: "AmountToOrganization",
                    displayName: null,
                    visualNameShort: "Amount To Organization",
                    visualNameFull: "Amount To Organization (Ticket)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "93eb434d-e5ec-4c3c-82db-e3857c89314e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Ticket",
                    modelColumnID: "64e1079f-44a5-4b5d-bbeb-f4e2fbb6e399",
                },
                {
                    id: "ce924019-4190-4e9b-8fbf-70a99212c752",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "419ce21f-e367-4355-b88b-f4d5169a88d4",
                    dataSourceColumnID: "247a1c23-2abd-4995-ae92-ef500185880d",
                    dataType: "Text",
                    name: "Area",
                    displayName: null,
                    visualNameShort: "Area",
                    visualNameFull: "Area (Ticket)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "93eb434d-e5ec-4c3c-82db-e3857c89314e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Ticket",
                    modelColumnID: "ce924019-4190-4e9b-8fbf-70a99212c752",
                },
                {
                    id: "c1c5501e-4894-4bae-bbe0-4aa09fe53ad3",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "419ce21f-e367-4355-b88b-f4d5169a88d4",
                    dataSourceColumnID: "15942789-8471-4560-8f8d-dd5e366d472f",
                    dataType: "DateTime",
                    name: "Attendee_AcceptedOnUtc",
                    displayName: null,
                    visualNameShort: "Attendee Accepted On Utc",
                    visualNameFull: "Attendee Accepted On Utc (Ticket)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "93eb434d-e5ec-4c3c-82db-e3857c89314e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Ticket",
                    modelColumnID: "c1c5501e-4894-4bae-bbe0-4aa09fe53ad3",
                },
                {
                    id: "e1c7f141-886c-41b6-99f8-b9894349a0e3",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "419ce21f-e367-4355-b88b-f4d5169a88d4",
                    dataSourceColumnID: "414ece15-e3b5-47db-85e6-d05bae907b78",
                    dataType: "Text",
                    name: "Attendee_Email",
                    displayName: null,
                    visualNameShort: "Attendee Email",
                    visualNameFull: "Attendee Email (Ticket)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "93eb434d-e5ec-4c3c-82db-e3857c89314e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Ticket",
                    modelColumnID: "e1c7f141-886c-41b6-99f8-b9894349a0e3",
                },
                {
                    id: "4f425329-191e-49bf-9917-d7a8df74b567",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "419ce21f-e367-4355-b88b-f4d5169a88d4",
                    dataSourceColumnID: "7ff15740-dd89-484e-977e-2ef3289890ef",
                    dataType: "Text",
                    name: "Attendee_FirstName",
                    displayName: null,
                    visualNameShort: "Attendee First Name",
                    visualNameFull: "Attendee First Name (Ticket)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "93eb434d-e5ec-4c3c-82db-e3857c89314e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Ticket",
                    modelColumnID: "4f425329-191e-49bf-9917-d7a8df74b567",
                },
                {
                    id: "70568fd7-0427-4b44-9cd8-1e8f4e619110",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "419ce21f-e367-4355-b88b-f4d5169a88d4",
                    dataSourceColumnID: "8c86f54d-7d1b-4e03-8645-e0193b0dfc3a",
                    dataType: "Int",
                    name: "Attendee_Gender",
                    displayName: null,
                    visualNameShort: "Attendee Gender",
                    visualNameFull: "Attendee Gender (Ticket)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "93eb434d-e5ec-4c3c-82db-e3857c89314e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Ticket",
                    modelColumnID: "70568fd7-0427-4b44-9cd8-1e8f4e619110",
                },
                {
                    id: "7d4b9b29-fcae-4302-8107-65128ca2afa2",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "419ce21f-e367-4355-b88b-f4d5169a88d4",
                    dataSourceColumnID: "e3264a87-13f6-432b-a53a-12a3bd319040",
                    dataType: "Int",
                    name: "Attendee_GroupAge",
                    displayName: null,
                    visualNameShort: "Attendee Group Age",
                    visualNameFull: "Attendee Group Age (Ticket)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "93eb434d-e5ec-4c3c-82db-e3857c89314e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Ticket",
                    modelColumnID: "7d4b9b29-fcae-4302-8107-65128ca2afa2",
                },
                {
                    id: "c53da152-dafc-49ed-8f0a-3c87150181f2",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "419ce21f-e367-4355-b88b-f4d5169a88d4",
                    dataSourceColumnID: "5d887c4d-729d-4bf4-b78f-15c21b06b402",
                    dataType: "Text",
                    name: "Attendee_LastName",
                    displayName: null,
                    visualNameShort: "Attendee Last Name",
                    visualNameFull: "Attendee Last Name (Ticket)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "93eb434d-e5ec-4c3c-82db-e3857c89314e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Ticket",
                    modelColumnID: "c53da152-dafc-49ed-8f0a-3c87150181f2",
                },
                {
                    id: "3856b6d4-410c-4990-9c2b-68c37d9e9c8c",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "419ce21f-e367-4355-b88b-f4d5169a88d4",
                    dataSourceColumnID: "ba0ab88a-f4b8-497e-b2d9-dc62a43103d9",
                    dataType: "Text",
                    name: "Barcode",
                    displayName: null,
                    visualNameShort: "Barcode",
                    visualNameFull: "Barcode (Ticket)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "93eb434d-e5ec-4c3c-82db-e3857c89314e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Ticket",
                    modelColumnID: "3856b6d4-410c-4990-9c2b-68c37d9e9c8c",
                },
                {
                    id: "25dd88d8-0bfa-4ca3-bab8-9bd8de68edf1",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "419ce21f-e367-4355-b88b-f4d5169a88d4",
                    dataSourceColumnID: "b9adc251-ce67-4f73-a7d8-cfdd25fb6768",
                    dataType: "Bool",
                    name: "BestAvailable",
                    displayName: null,
                    visualNameShort: "Best Available",
                    visualNameFull: "Best Available (Ticket)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "93eb434d-e5ec-4c3c-82db-e3857c89314e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Ticket",
                    modelColumnID: "25dd88d8-0bfa-4ca3-bab8-9bd8de68edf1",
                },
                {
                    id: "b60214b8-4e5a-4643-9128-b4fb532197b6",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "419ce21f-e367-4355-b88b-f4d5169a88d4",
                    dataSourceColumnID: "93a6f9dd-26f9-4ec0-bd1e-3280671dc1bd",
                    dataType: "Bool",
                    name: "Deleted",
                    displayName: null,
                    visualNameShort: "Deleted",
                    visualNameFull: "Deleted (Ticket)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "93eb434d-e5ec-4c3c-82db-e3857c89314e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Ticket",
                    modelColumnID: "b60214b8-4e5a-4643-9128-b4fb532197b6",
                },
                {
                    id: "765d46f0-a862-4247-bf0c-119146f6fa18",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "419ce21f-e367-4355-b88b-f4d5169a88d4",
                    dataSourceColumnID: "cd5b9284-762c-4c83-b298-514bf192e6db",
                    dataType: "IDUUID",
                    name: "DiscountID",
                    displayName: null,
                    visualNameShort: "Discount ID",
                    visualNameFull: "Discount ID (Ticket)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "93eb434d-e5ec-4c3c-82db-e3857c89314e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Ticket",
                    modelColumnID: "765d46f0-a862-4247-bf0c-119146f6fa18",
                },
                {
                    id: "91826796-6a81-4e21-8fb0-a55c7bb6728c",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "419ce21f-e367-4355-b88b-f4d5169a88d4",
                    dataSourceColumnID: "d09fb9ac-4d4b-4724-8017-bc582989a4b2",
                    dataType: "Double",
                    name: "DiscountedAmount",
                    displayName: null,
                    visualNameShort: "Discounted Amount",
                    visualNameFull: "Discounted Amount (Ticket)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "93eb434d-e5ec-4c3c-82db-e3857c89314e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Ticket",
                    modelColumnID: "91826796-6a81-4e21-8fb0-a55c7bb6728c",
                },
                {
                    id: "a28c38bc-17eb-45c2-862c-da92d3dedcdc",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "419ce21f-e367-4355-b88b-f4d5169a88d4",
                    dataSourceColumnID: "e58a342d-b0c6-4382-b003-e8ce0feede13",
                    dataType: "Text",
                    name: "Discriminator",
                    displayName: null,
                    visualNameShort: "Discriminator",
                    visualNameFull: "Discriminator (Ticket)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "93eb434d-e5ec-4c3c-82db-e3857c89314e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Ticket",
                    modelColumnID: "a28c38bc-17eb-45c2-862c-da92d3dedcdc",
                },
                {
                    id: "0f6bcd2b-fd95-491f-bbc4-2d55f470f7b0",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "419ce21f-e367-4355-b88b-f4d5169a88d4",
                    dataSourceColumnID: "4ddee0f5-fcc5-4b1f-89fb-bcaea376bada",
                    dataType: "Double",
                    name: "FeeFromOrg",
                    displayName: null,
                    visualNameShort: "Fee From Org",
                    visualNameFull: "Fee From Org (Ticket)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "93eb434d-e5ec-4c3c-82db-e3857c89314e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Ticket",
                    modelColumnID: "0f6bcd2b-fd95-491f-bbc4-2d55f470f7b0",
                },
                {
                    id: "80c802f7-961a-4c9e-8a09-e6ce3702c4f2",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "419ce21f-e367-4355-b88b-f4d5169a88d4",
                    dataSourceColumnID: "2aa5d9de-31b8-4d7e-9234-43a0dc2dc04c",
                    dataType: "Text",
                    name: "IncludedSections",
                    displayName: null,
                    visualNameShort: "Included Sections",
                    visualNameFull: "Included Sections (Ticket)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "93eb434d-e5ec-4c3c-82db-e3857c89314e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Ticket",
                    modelColumnID: "80c802f7-961a-4c9e-8a09-e6ce3702c4f2",
                },
                {
                    id: "15d56683-8450-4e9f-a481-256578d846b8",
                    calculations: [
                        {
                            transformationID: "1b58a076-1d19-4cb8-b97c-899161f7c188",
                            name: "New Column 1040",
                            default: true,
                            viewReferenceName: null,
                            columnReferenceName: null,
                            versionDetails: "NotPublished",
                            publishedOn: null,
                        },
                    ],
                    dataType: "Text",
                    name: "New Column 1040",
                    displayName: null,
                    visualNameShort: "New Column 1040",
                    visualNameFull: "New Column 1040 (Ticket)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "93eb434d-e5ec-4c3c-82db-e3857c89314e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Ticket",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "15d56683-8450-4e9f-a481-256578d846b8",
                },
                {
                    id: "92d54a20-2003-442c-9276-f163ad080b91",
                    calculations: [
                        {
                            transformationID: "7056f7e8-7979-44d4-88e1-8a2ef389dc19",
                            name: "New Column 5038",
                            default: true,
                            viewReferenceName: null,
                            columnReferenceName: null,
                            versionDetails: "NotPublished",
                            publishedOn: null,
                        },
                    ],
                    dataType: "Text",
                    name: "New Column 5038",
                    displayName: null,
                    visualNameShort: "New Column 5038",
                    visualNameFull: "New Column 5038 (Ticket)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "93eb434d-e5ec-4c3c-82db-e3857c89314e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Ticket",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "92d54a20-2003-442c-9276-f163ad080b91",
                },
                {
                    id: "0da7794c-d5b6-47c4-b0f3-362e17e12a60",
                    calculations: [
                        {
                            transformationID: "a1b7457c-0bec-407d-b898-6d3155659572",
                            name: "New Column 5358",
                            default: true,
                            viewReferenceName: null,
                            columnReferenceName: null,
                            versionDetails: "NotPublished",
                            publishedOn: null,
                        },
                    ],
                    dataType: "Text",
                    name: "New Column 5358",
                    displayName: null,
                    visualNameShort: "New Column 5358",
                    visualNameFull: "New Column 5358 (Ticket)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "93eb434d-e5ec-4c3c-82db-e3857c89314e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Ticket",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "0da7794c-d5b6-47c4-b0f3-362e17e12a60",
                },
                {
                    id: "4c12cf95-bb7a-49a7-8b75-f588c389479b",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "419ce21f-e367-4355-b88b-f4d5169a88d4",
                    dataSourceColumnID: "16c95464-0a75-4fec-80c9-b6a31d456ee4",
                    dataType: "IDUUID",
                    name: "OrderID",
                    displayName: null,
                    visualNameShort: "Order ID",
                    visualNameFull: "Order ID (Ticket)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "93eb434d-e5ec-4c3c-82db-e3857c89314e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Ticket",
                    modelColumnID: "4c12cf95-bb7a-49a7-8b75-f588c389479b",
                },
                {
                    id: "a5774e58-006c-40c4-a9da-fffe7061d4f6",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "419ce21f-e367-4355-b88b-f4d5169a88d4",
                    dataSourceColumnID: "3f6c9bf8-ad57-449e-91a0-3b5cb236f7ff",
                    dataType: "IDUUID",
                    name: "OutingID",
                    displayName: null,
                    visualNameShort: "Outing ID",
                    visualNameFull: "Outing ID (Ticket)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "93eb434d-e5ec-4c3c-82db-e3857c89314e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Ticket",
                    modelColumnID: "a5774e58-006c-40c4-a9da-fffe7061d4f6",
                },
                {
                    id: "9dc1d4c7-bf64-4f4c-ba69-e8f0195a9d27",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "419ce21f-e367-4355-b88b-f4d5169a88d4",
                    dataSourceColumnID: "99b8bec4-4b27-45a3-9d75-1ee101f2533c",
                    dataType: "Double",
                    name: "PaidFeesAtCheckout",
                    displayName: null,
                    visualNameShort: "Paid Fees At Checkout",
                    visualNameFull: "Paid Fees At Checkout (Ticket)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "93eb434d-e5ec-4c3c-82db-e3857c89314e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Ticket",
                    modelColumnID: "9dc1d4c7-bf64-4f4c-ba69-e8f0195a9d27",
                },
                {
                    id: "94ffada5-c71d-4eee-a3ee-af57383f01b7",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "419ce21f-e367-4355-b88b-f4d5169a88d4",
                    dataSourceColumnID: "2808eba0-eacd-43eb-9e24-2cfe5293f480",
                    dataType: "Double",
                    name: "PaidFeesAtPayout",
                    displayName: null,
                    visualNameShort: "Paid Fees At Payout",
                    visualNameFull: "Paid Fees At Payout (Ticket)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "93eb434d-e5ec-4c3c-82db-e3857c89314e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Ticket",
                    modelColumnID: "94ffada5-c71d-4eee-a3ee-af57383f01b7",
                },
                {
                    id: "306c6956-facc-4ea5-98ba-3234e6f64a55",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "419ce21f-e367-4355-b88b-f4d5169a88d4",
                    dataSourceColumnID: "a8eb6efe-14af-4452-9aa8-6bd127e924d7",
                    dataType: "Double",
                    name: "PaidFlatFeesAtCheckout",
                    displayName: null,
                    visualNameShort: "Paid Flat Fees At Checkout",
                    visualNameFull: "Paid Flat Fees At Checkout (Ticket)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "93eb434d-e5ec-4c3c-82db-e3857c89314e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Ticket",
                    modelColumnID: "306c6956-facc-4ea5-98ba-3234e6f64a55",
                },
                {
                    id: "50d7ccea-451e-4815-a8d1-333d4b992956",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "419ce21f-e367-4355-b88b-f4d5169a88d4",
                    dataSourceColumnID: "a1461ad0-3c0c-4510-b3dc-09cb84de2987",
                    dataType: "Double",
                    name: "PaidFlatFeesAtPayout",
                    displayName: null,
                    visualNameShort: "Paid Flat Fees At Payout",
                    visualNameFull: "Paid Flat Fees At Payout (Ticket)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "93eb434d-e5ec-4c3c-82db-e3857c89314e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Ticket",
                    modelColumnID: "50d7ccea-451e-4815-a8d1-333d4b992956",
                },
                {
                    id: "e89dfc95-b0f0-4d2d-ac90-7a0b90a6191a",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "419ce21f-e367-4355-b88b-f4d5169a88d4",
                    dataSourceColumnID: "32dd3fa6-73a4-4a1c-b36e-dc730ca7a397",
                    dataType: "Double",
                    name: "PaidFlatTaxGroup",
                    displayName: null,
                    visualNameShort: "Paid Flat Tax Group",
                    visualNameFull: "Paid Flat Tax Group (Ticket)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "93eb434d-e5ec-4c3c-82db-e3857c89314e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Ticket",
                    modelColumnID: "e89dfc95-b0f0-4d2d-ac90-7a0b90a6191a",
                },
                {
                    id: "26bf8f1b-8ac5-4cd6-b1df-2f0c4fbc8681",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "419ce21f-e367-4355-b88b-f4d5169a88d4",
                    dataSourceColumnID: "204f3862-9714-4281-b9b5-9b0b0c67caf4",
                    dataType: "Double",
                    name: "PaidFlatTaxOrganization",
                    displayName: null,
                    visualNameShort: "Paid Flat Tax Organization",
                    visualNameFull: "Paid Flat Tax Organization (Ticket)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "93eb434d-e5ec-4c3c-82db-e3857c89314e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Ticket",
                    modelColumnID: "26bf8f1b-8ac5-4cd6-b1df-2f0c4fbc8681",
                },
                {
                    id: "01568b64-b716-b41c-d9ed-f9ec2b2ea8b9",
                    calculations: [
                        {
                            transformationID: "a2b59bcd-93c9-4a19-9d0c-49a65a46334b",
                            name: "PaidMinusFees",
                            default: true,
                            viewReferenceName: "verbcalccol_a2b59bcd_93c9_4a19_9d0c_49a65a46334b",
                            columnReferenceName: "verbcol_calcoutput",
                            versionDetails: "LatestPublished",
                            publishedOn: "2021-06-23T18:39:47.603Z",
                        },
                    ],
                    dataType: "Double",
                    name: "PaidMinusFees",
                    displayName: null,
                    visualNameShort: "Paid Minus Fees",
                    visualNameFull: "Paid Minus Fees (Ticket)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "93eb434d-e5ec-4c3c-82db-e3857c89314e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Ticket",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "01568b64-b716-b41c-d9ed-f9ec2b2ea8b9",
                },
                {
                    id: "d958f02e-4b0e-4cd3-8f31-b95360853ae3",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "419ce21f-e367-4355-b88b-f4d5169a88d4",
                    dataSourceColumnID: "00e38c44-ad93-4577-89a2-228838eb49ee",
                    dataType: "Double",
                    name: "PaidOrgFlatFeesAtCheckout",
                    displayName: null,
                    visualNameShort: "Paid Org Flat Fees At Checkout",
                    visualNameFull: "Paid Org Flat Fees At Checkout (Ticket)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "93eb434d-e5ec-4c3c-82db-e3857c89314e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Ticket",
                    modelColumnID: "d958f02e-4b0e-4cd3-8f31-b95360853ae3",
                },
                {
                    id: "5e869be5-640f-4e10-ad93-ec3cc01f45b3",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "419ce21f-e367-4355-b88b-f4d5169a88d4",
                    dataSourceColumnID: "d337f572-e3fb-4a5e-92d8-1fabd609231c",
                    dataType: "Double",
                    name: "PaidToGroup",
                    displayName: null,
                    visualNameShort: "Paid To Group",
                    visualNameFull: "Paid To Group (Ticket)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "93eb434d-e5ec-4c3c-82db-e3857c89314e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Ticket",
                    modelColumnID: "5e869be5-640f-4e10-ad93-ec3cc01f45b3",
                },
                {
                    id: "da1afbd2-efe4-44e6-b225-976b268fbdf6",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "419ce21f-e367-4355-b88b-f4d5169a88d4",
                    dataSourceColumnID: "752efd59-cca2-4524-999d-d1f2bcf10233",
                    dataType: "Double",
                    name: "PaidToOrganization",
                    displayName: null,
                    visualNameShort: "Paid To Organization",
                    visualNameFull: "Paid To Organization (Ticket)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "93eb434d-e5ec-4c3c-82db-e3857c89314e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Ticket",
                    modelColumnID: "da1afbd2-efe4-44e6-b225-976b268fbdf6",
                },
                {
                    id: "32daa8ff-638f-42cc-a2fa-3b51eed5adf8",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "419ce21f-e367-4355-b88b-f4d5169a88d4",
                    dataSourceColumnID: "f8dc0ef7-363d-4fe6-b72f-6e8bf5718cdc",
                    dataType: "IDUUID",
                    name: "PrimaryTicketID",
                    displayName: null,
                    visualNameShort: "Primary Ticket ID",
                    visualNameFull: "Primary Ticket ID (Ticket)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "93eb434d-e5ec-4c3c-82db-e3857c89314e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Ticket",
                    modelColumnID: "32daa8ff-638f-42cc-a2fa-3b51eed5adf8",
                },
                {
                    id: "a249aa62-b5de-43ca-952c-79e30afb10c3",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "419ce21f-e367-4355-b88b-f4d5169a88d4",
                    dataSourceColumnID: "2adfc4b9-06fc-4103-81e6-59d735768571",
                    dataType: "Text",
                    name: "Row",
                    displayName: null,
                    visualNameShort: "Row",
                    visualNameFull: "Row (Ticket)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "93eb434d-e5ec-4c3c-82db-e3857c89314e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Ticket",
                    modelColumnID: "a249aa62-b5de-43ca-952c-79e30afb10c3",
                },
                {
                    id: "564ac415-4f1a-457b-931b-1e07ef98c085",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "419ce21f-e367-4355-b88b-f4d5169a88d4",
                    dataSourceColumnID: "108c3744-2c39-4093-8b71-ffcf0b527799",
                    dataType: "Text",
                    name: "Seat",
                    displayName: null,
                    visualNameShort: "Seat",
                    visualNameFull: "Seat (Ticket)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "93eb434d-e5ec-4c3c-82db-e3857c89314e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Ticket",
                    modelColumnID: "564ac415-4f1a-457b-931b-1e07ef98c085",
                },
                {
                    id: "b79952c5-21cc-465d-ac80-8bb73eb2648d",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "419ce21f-e367-4355-b88b-f4d5169a88d4",
                    dataSourceColumnID: "f1501586-ef95-4313-96f7-b5dcb453f404",
                    dataType: "Text",
                    name: "Section",
                    displayName: null,
                    visualNameShort: "Section",
                    visualNameFull: "Section (Ticket)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "93eb434d-e5ec-4c3c-82db-e3857c89314e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Ticket",
                    modelColumnID: "b79952c5-21cc-465d-ac80-8bb73eb2648d",
                },
                {
                    id: "3ed9e95f-0473-4b39-a577-99799d243263",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "419ce21f-e367-4355-b88b-f4d5169a88d4",
                    dataSourceColumnID: "556c4207-ac12-4878-a4f2-fa350e528921",
                    dataType: "Int",
                    name: "Status",
                    displayName: null,
                    visualNameShort: "Status",
                    visualNameFull: "Status (Ticket)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "93eb434d-e5ec-4c3c-82db-e3857c89314e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Ticket",
                    modelColumnID: "3ed9e95f-0473-4b39-a577-99799d243263",
                },
                {
                    id: "7f47116f-9ad1-4969-a611-073fb914f4a1",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "419ce21f-e367-4355-b88b-f4d5169a88d4",
                    dataSourceColumnID: "b41d0587-206f-475e-941a-5c02c643cebc",
                    dataType: "Bool",
                    name: "Taxable",
                    displayName: null,
                    visualNameShort: "Taxable",
                    visualNameFull: "Taxable (Ticket)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "93eb434d-e5ec-4c3c-82db-e3857c89314e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Ticket",
                    modelColumnID: "7f47116f-9ad1-4969-a611-073fb914f4a1",
                },
                {
                    id: "ea19bddd-0268-4ddd-a66c-f5b8f0af33fc",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "419ce21f-e367-4355-b88b-f4d5169a88d4",
                    dataSourceColumnID: "33c6067f-61ea-492f-8ebc-e347406cadc1",
                    dataType: "Bool",
                    name: "Temporary",
                    displayName: null,
                    visualNameShort: "Temporary",
                    visualNameFull: "Temporary (Ticket)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "93eb434d-e5ec-4c3c-82db-e3857c89314e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Ticket",
                    modelColumnID: "ea19bddd-0268-4ddd-a66c-f5b8f0af33fc",
                },
                {
                    id: "bca3d81c-47a9-47ab-ab8f-93d254522fad",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "419ce21f-e367-4355-b88b-f4d5169a88d4",
                    dataSourceColumnID: "881b8c6a-13ec-4fb8-a007-73d5c0d0bf67",
                    dataType: "Int",
                    name: "TicketingProvider",
                    displayName: null,
                    visualNameShort: "Ticketing Provider",
                    visualNameFull: "Ticketing Provider (Ticket)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "93eb434d-e5ec-4c3c-82db-e3857c89314e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Ticket",
                    modelColumnID: "bca3d81c-47a9-47ab-ab8f-93d254522fad",
                },
                {
                    id: "be8d8803-518e-484b-9c25-52ab939faf2a",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "419ce21f-e367-4355-b88b-f4d5169a88d4",
                    dataSourceColumnID: "2b85c303-e383-4abe-bc90-bde337515289",
                    dataType: "Text",
                    name: "TicketingProviderConfigJson",
                    displayName: null,
                    visualNameShort: "Ticketing Provider Config Json",
                    visualNameFull: "Ticketing Provider Config Json (Ticket)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "93eb434d-e5ec-4c3c-82db-e3857c89314e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Ticket",
                    modelColumnID: "be8d8803-518e-484b-9c25-52ab939faf2a",
                },
                {
                    id: "9b9e6efd-9329-4a8d-a2b5-c78b1a7c42db",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "419ce21f-e367-4355-b88b-f4d5169a88d4",
                    dataSourceColumnID: "d35b44de-05ff-44a9-8e98-526ce0ca3bee",
                    dataType: "Text",
                    name: "VenueMapSectionID",
                    displayName: null,
                    visualNameShort: "Venue Map Section ID",
                    visualNameFull: "Venue Map Section ID (Ticket)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "93eb434d-e5ec-4c3c-82db-e3857c89314e",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Ticket",
                    modelColumnID: "9b9e6efd-9329-4a8d-a2b5-c78b1a7c42db",
                },
            ],
            rowLevelSecurity: {},
            position: {
                x: 279,
                y: 592.5,
            },
            $type: "DataSourceModelTable",
        },
        {
            id: "1eb991f5-2465-495d-93db-88ffb09b4efd",
            dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
            dataSourceTableID: "0b737c91-3b8d-42ca-9676-e4350c676bcd",
            name: "Venue",
            visualName: "Venue",
            columns: [
                {
                    id: "a0d4a7e9-41e4-4dd2-bc9b-e0bdee5d7b54",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "0b737c91-3b8d-42ca-9676-e4350c676bcd",
                    dataSourceColumnID: "509a103f-adb7-4558-855e-59df0a4507c1",
                    dataType: "IDUUID",
                    name: "VenueID",
                    displayName: null,
                    visualNameShort: "Venue ID",
                    visualNameFull: "Venue ID (Venue)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "1eb991f5-2465-495d-93db-88ffb09b4efd",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Venue",
                    modelColumnID: "a0d4a7e9-41e4-4dd2-bc9b-e0bdee5d7b54",
                },
                {
                    id: "6ab512ab-dd1b-41e9-87ac-27783bb4c1b1",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "0b737c91-3b8d-42ca-9676-e4350c676bcd",
                    dataSourceColumnID: "e57313f1-f29f-41a0-a433-a604f2972af3",
                    dataType: "Text",
                    name: "Address1",
                    displayName: null,
                    visualNameShort: "Address1",
                    visualNameFull: "Address1 (Venue)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "1eb991f5-2465-495d-93db-88ffb09b4efd",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Venue",
                    modelColumnID: "6ab512ab-dd1b-41e9-87ac-27783bb4c1b1",
                },
                {
                    id: "d822777a-61b9-45eb-adaf-1483dd75065d",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "0b737c91-3b8d-42ca-9676-e4350c676bcd",
                    dataSourceColumnID: "2e86a598-d664-4e4c-aea4-0e10aa8b5b81",
                    dataType: "Text",
                    name: "Address2",
                    displayName: null,
                    visualNameShort: "Address2",
                    visualNameFull: "Address2 (Venue)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "1eb991f5-2465-495d-93db-88ffb09b4efd",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Venue",
                    modelColumnID: "d822777a-61b9-45eb-adaf-1483dd75065d",
                },
                {
                    id: "0ee21af9-e86a-4c7b-8198-f0dac2e65540",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "0b737c91-3b8d-42ca-9676-e4350c676bcd",
                    dataSourceColumnID: "0022848e-860d-4234-ace2-8ae88da34210",
                    dataType: "Text",
                    name: "City",
                    displayName: null,
                    visualNameShort: "City",
                    visualNameFull: "City (Venue)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "1eb991f5-2465-495d-93db-88ffb09b4efd",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Venue",
                    modelColumnID: "0ee21af9-e86a-4c7b-8198-f0dac2e65540",
                },
                {
                    id: "024fc6c9-e939-4a48-ac54-bdd803298549",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "0b737c91-3b8d-42ca-9676-e4350c676bcd",
                    dataSourceColumnID: "8bd63c89-ec10-4fde-944f-c76402ef744e",
                    dataType: "Int",
                    name: "Country",
                    displayName: null,
                    visualNameShort: "Country",
                    visualNameFull: "Country (Venue)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "1eb991f5-2465-495d-93db-88ffb09b4efd",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Venue",
                    modelColumnID: "024fc6c9-e939-4a48-ac54-bdd803298549",
                },
                {
                    id: "5345b572-f7f7-428f-9a63-ede799c25b79",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "0b737c91-3b8d-42ca-9676-e4350c676bcd",
                    dataSourceColumnID: "9514657e-a6e1-4f51-a012-760fb8bce844",
                    dataType: "Bool",
                    name: "Default",
                    displayName: null,
                    visualNameShort: "Default",
                    visualNameFull: "Default (Venue)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "1eb991f5-2465-495d-93db-88ffb09b4efd",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Venue",
                    modelColumnID: "5345b572-f7f7-428f-9a63-ede799c25b79",
                },
                {
                    id: "79c6b6eb-6752-4947-800d-7f1a96f7da3a",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "0b737c91-3b8d-42ca-9676-e4350c676bcd",
                    dataSourceColumnID: "e442c948-0827-45af-b76d-8f1406673b72",
                    dataType: "Bool",
                    name: "Deleted",
                    displayName: null,
                    visualNameShort: "Deleted",
                    visualNameFull: "Deleted (Venue)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "1eb991f5-2465-495d-93db-88ffb09b4efd",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Venue",
                    modelColumnID: "79c6b6eb-6752-4947-800d-7f1a96f7da3a",
                },
                {
                    id: "005f6df2-807c-486d-bb1c-465161183336",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "0b737c91-3b8d-42ca-9676-e4350c676bcd",
                    dataSourceColumnID: "e9d2ce9e-c56f-46bb-a356-203eec87dc31",
                    dataType: "Text",
                    name: "FacebookPlaceID",
                    displayName: null,
                    visualNameShort: "Facebook Place ID",
                    visualNameFull: "Facebook Place ID (Venue)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "1eb991f5-2465-495d-93db-88ffb09b4efd",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Venue",
                    modelColumnID: "005f6df2-807c-486d-bb1c-465161183336",
                },
                {
                    id: "7cb6dd96-aef4-4bf6-9bfe-618c9845bc40",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "0b737c91-3b8d-42ca-9676-e4350c676bcd",
                    dataSourceColumnID: "26065298-df05-4ba3-86f3-9ffb97d07f9f",
                    dataType: "Text",
                    name: "Name",
                    displayName: null,
                    visualNameShort: "Name",
                    visualNameFull: "Name (Venue)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "1eb991f5-2465-495d-93db-88ffb09b4efd",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Venue",
                    modelColumnID: "7cb6dd96-aef4-4bf6-9bfe-618c9845bc40",
                },
                {
                    id: "667da44c-3417-e004-f534-f70563db9a7e",
                    calculations: [
                        {
                            transformationID: "facc5110-3c4d-4c16-a0b8-3c4b01ec8154",
                            name: "New Column 4217",
                            default: true,
                            viewReferenceName: "verbcalccol_facc5110_3c4d_4c16_a0b8_3c4b01ec8154",
                            columnReferenceName: null,
                            versionDetails: "NotPublished",
                            publishedOn: null,
                        },
                    ],
                    dataType: "Unsupported",
                    name: "New Column 4217",
                    displayName: null,
                    visualNameShort: "New Column 4217",
                    visualNameFull: "New Column 4217 (Venue)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "1eb991f5-2465-495d-93db-88ffb09b4efd",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Venue",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "667da44c-3417-e004-f534-f70563db9a7e",
                },
                {
                    id: "e106735f-7d3f-41d0-9e4b-4fb41a6d0fdc",
                    calculations: [
                        {
                            transformationID: "e8b2b4fd-3090-4727-ae44-8de33369bcfe",
                            name: "New Column 5470",
                            default: true,
                            viewReferenceName: "verbcalccol_e8b2b4fd_3090_4727_ae44_8de33369bcfe",
                            columnReferenceName: null,
                            versionDetails: "NotPublished",
                            publishedOn: null,
                        },
                    ],
                    dataType: "Unsupported",
                    name: "New Column 5470",
                    displayName: null,
                    visualNameShort: "New Column 5470",
                    visualNameFull: "New Column 5470 (Venue)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "CalculatedModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "1eb991f5-2465-495d-93db-88ffb09b4efd",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Venue",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "e106735f-7d3f-41d0-9e4b-4fb41a6d0fdc",
                },
                {
                    id: "4cd870fd-0807-430b-8764-cd9c956bcd14",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "0b737c91-3b8d-42ca-9676-e4350c676bcd",
                    dataSourceColumnID: "5ab57b42-486d-43b7-9c92-6a9d35d48507",
                    dataType: "IDUUID",
                    name: "OrganizationID",
                    displayName: null,
                    visualNameShort: "Organization ID",
                    visualNameFull: "Organization ID (Venue)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "1eb991f5-2465-495d-93db-88ffb09b4efd",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Venue",
                    modelColumnID: "4cd870fd-0807-430b-8764-cd9c956bcd14",
                },
                {
                    id: "02330642-89ed-4370-9ca7-4a9577d273f6",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "0b737c91-3b8d-42ca-9676-e4350c676bcd",
                    dataSourceColumnID: "ce1734b2-7ba8-49b6-94f5-22f33dfcc8c8",
                    dataType: "Text",
                    name: "Phone",
                    displayName: null,
                    visualNameShort: "Phone",
                    visualNameFull: "Phone (Venue)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "1eb991f5-2465-495d-93db-88ffb09b4efd",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Venue",
                    modelColumnID: "02330642-89ed-4370-9ca7-4a9577d273f6",
                },
                {
                    id: "4029a917-3113-4c58-9315-cc297885b1c5",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "0b737c91-3b8d-42ca-9676-e4350c676bcd",
                    dataSourceColumnID: "a10bcda4-209c-40d0-a73f-8e0868e34fd9",
                    dataType: "Text",
                    name: "State",
                    displayName: null,
                    visualNameShort: "State",
                    visualNameFull: "State (Venue)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "1eb991f5-2465-495d-93db-88ffb09b4efd",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Venue",
                    modelColumnID: "4029a917-3113-4c58-9315-cc297885b1c5",
                },
                {
                    id: "b9735ef9-a745-4179-a213-9ca73856b20a",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "0b737c91-3b8d-42ca-9676-e4350c676bcd",
                    dataSourceColumnID: "5fc6ac67-c649-46bd-acdb-882abfb2be9a",
                    dataType: "Text",
                    name: "Timezone",
                    displayName: null,
                    visualNameShort: "Timezone",
                    visualNameFull: "Timezone (Venue)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "1eb991f5-2465-495d-93db-88ffb09b4efd",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Venue",
                    modelColumnID: "b9735ef9-a745-4179-a213-9ca73856b20a",
                },
                {
                    id: "ff3b24db-b294-4210-bf65-3aa5679cdc09",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "0b737c91-3b8d-42ca-9676-e4350c676bcd",
                    dataSourceColumnID: "0362eebb-2a93-426a-b701-dc44d4e36550",
                    dataType: "Text",
                    name: "ZipCode",
                    displayName: null,
                    visualNameShort: "Zip Code",
                    visualNameFull: "Zip Code (Venue)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "1eb991f5-2465-495d-93db-88ffb09b4efd",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "Venue",
                    modelColumnID: "ff3b24db-b294-4210-bf65-3aa5679cdc09",
                },
            ],
            rowLevelSecurity: {},
            position: {
                x: -441,
                y: 832.5,
            },
            $type: "DataSourceModelTable",
        },
        {
            id: "a7326f9a-a698-41c3-9b11-996190d7b684",
            dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
            dataSourceTableID: "490c4fed-b124-4b0b-a437-9e534054aaec",
            name: "VenueMap",
            visualName: "Venue Map",
            columns: [
                {
                    id: "e0656050-872c-41b3-9fd1-108886e138d5",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "490c4fed-b124-4b0b-a437-9e534054aaec",
                    dataSourceColumnID: "777bfb4b-8841-4f50-b4f7-aee41d2ddfd2",
                    dataType: "IDUUID",
                    name: "VenueMapID",
                    displayName: null,
                    visualNameShort: "Venue Map ID",
                    visualNameFull: "Venue Map ID (Venue Map)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "a7326f9a-a698-41c3-9b11-996190d7b684",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "VenueMap",
                    modelColumnID: "e0656050-872c-41b3-9fd1-108886e138d5",
                },
                {
                    id: "1784a74d-d9a0-4c33-b10d-227c817a5a58",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "490c4fed-b124-4b0b-a437-9e534054aaec",
                    dataSourceColumnID: "c651b50c-0c80-4d1b-a5b8-f635f08a865b",
                    dataType: "Text",
                    name: "Boundaries",
                    displayName: null,
                    visualNameShort: "Boundaries",
                    visualNameFull: "Boundaries (Venue Map)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "a7326f9a-a698-41c3-9b11-996190d7b684",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "VenueMap",
                    modelColumnID: "1784a74d-d9a0-4c33-b10d-227c817a5a58",
                },
                {
                    id: "82529fa2-40d9-4239-9ba5-533f77bd0441",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "490c4fed-b124-4b0b-a437-9e534054aaec",
                    dataSourceColumnID: "421adefc-29af-4880-a053-6c2d2d359868",
                    dataType: "Bool",
                    name: "Default",
                    displayName: null,
                    visualNameShort: "Default",
                    visualNameFull: "Default (Venue Map)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "a7326f9a-a698-41c3-9b11-996190d7b684",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "VenueMap",
                    modelColumnID: "82529fa2-40d9-4239-9ba5-533f77bd0441",
                },
                {
                    id: "ee59d1bd-84a8-4cdb-9c60-30e48754287d",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "490c4fed-b124-4b0b-a437-9e534054aaec",
                    dataSourceColumnID: "ff9f486c-e423-428a-992a-5db55c42ade6",
                    dataType: "Bool",
                    name: "Deleted",
                    displayName: null,
                    visualNameShort: "Deleted",
                    visualNameFull: "Deleted (Venue Map)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "a7326f9a-a698-41c3-9b11-996190d7b684",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "VenueMap",
                    modelColumnID: "ee59d1bd-84a8-4cdb-9c60-30e48754287d",
                },
                {
                    id: "23271220-053e-45e3-846c-1e2db69e7972",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "490c4fed-b124-4b0b-a437-9e534054aaec",
                    dataSourceColumnID: "23c05553-244a-4184-b0ef-f6a4b281ccff",
                    dataType: "Text",
                    name: "Discriminator",
                    displayName: null,
                    visualNameShort: "Discriminator",
                    visualNameFull: "Discriminator (Venue Map)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "a7326f9a-a698-41c3-9b11-996190d7b684",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "VenueMap",
                    modelColumnID: "23271220-053e-45e3-846c-1e2db69e7972",
                },
                {
                    id: "0af6faf5-5059-4bcb-920f-29a803a51ea8",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "490c4fed-b124-4b0b-a437-9e534054aaec",
                    dataSourceColumnID: "67c1c99e-9f0f-458a-9201-84de6f22a4f2",
                    dataType: "Text",
                    name: "ETag",
                    displayName: null,
                    visualNameShort: "E Tag",
                    visualNameFull: "E Tag (Venue Map)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "a7326f9a-a698-41c3-9b11-996190d7b684",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "VenueMap",
                    modelColumnID: "0af6faf5-5059-4bcb-920f-29a803a51ea8",
                },
                {
                    id: "fffbf4df-cc8b-425d-915d-85af2ccf9ed7",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "490c4fed-b124-4b0b-a437-9e534054aaec",
                    dataSourceColumnID: "f16a7267-49aa-4902-8def-268c031772db",
                    dataType: "Text",
                    name: "GMMapData",
                    displayName: null,
                    visualNameShort: "GM Map Data",
                    visualNameFull: "GM Map Data (Venue Map)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "a7326f9a-a698-41c3-9b11-996190d7b684",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "VenueMap",
                    modelColumnID: "fffbf4df-cc8b-425d-915d-85af2ccf9ed7",
                },
                {
                    id: "f97e8c66-c85c-478c-bd9f-94f5ff632198",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "490c4fed-b124-4b0b-a437-9e534054aaec",
                    dataSourceColumnID: "06f40f56-d48f-4e70-bb90-3700070b0032",
                    dataType: "Text",
                    name: "Image_ImageUrl",
                    displayName: null,
                    visualNameShort: "Image Image Url",
                    visualNameFull: "Image Image Url (Venue Map)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "a7326f9a-a698-41c3-9b11-996190d7b684",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "VenueMap",
                    modelColumnID: "f97e8c66-c85c-478c-bd9f-94f5ff632198",
                },
                {
                    id: "80ff0e54-e1e6-4636-b0e4-b2b2f458b169",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "490c4fed-b124-4b0b-a437-9e534054aaec",
                    dataSourceColumnID: "280bdee9-418d-4fc6-99dd-19550b5ac351",
                    dataType: "Int",
                    name: "MaxX",
                    displayName: null,
                    visualNameShort: "Max X",
                    visualNameFull: "Max X (Venue Map)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "a7326f9a-a698-41c3-9b11-996190d7b684",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "VenueMap",
                    modelColumnID: "80ff0e54-e1e6-4636-b0e4-b2b2f458b169",
                },
                {
                    id: "27b7035e-9cf8-42f6-94a3-dc546f650175",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "490c4fed-b124-4b0b-a437-9e534054aaec",
                    dataSourceColumnID: "efb5e072-11a9-4e4c-bf7b-186f3a43c57a",
                    dataType: "Int",
                    name: "MaxY",
                    displayName: null,
                    visualNameShort: "Max Y",
                    visualNameFull: "Max Y (Venue Map)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "a7326f9a-a698-41c3-9b11-996190d7b684",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "VenueMap",
                    modelColumnID: "27b7035e-9cf8-42f6-94a3-dc546f650175",
                },
                {
                    id: "87b6e746-db33-4206-b6b6-e80ec46dbc06",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "490c4fed-b124-4b0b-a437-9e534054aaec",
                    dataSourceColumnID: "8565803a-89f1-48c0-b601-4761915c4bf8",
                    dataType: "Text",
                    name: "Name",
                    displayName: null,
                    visualNameShort: "Name",
                    visualNameFull: "Name (Venue Map)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "a7326f9a-a698-41c3-9b11-996190d7b684",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "VenueMap",
                    modelColumnID: "87b6e746-db33-4206-b6b6-e80ec46dbc06",
                },
                {
                    id: "0f3503fe-ed2a-ca05-0da1-a12883cf0da7",
                    transformationID: "5c09199b-0521-4b4e-be13-995b0ea4d765",
                    dataType: "Double",
                    columnReferenceName: "verbcol_calcoutput",
                    versionDetails: "NotPublished",
                    publishedOn: null,
                    name: "New Measure 1255",
                    displayName: null,
                    visualNameShort: "New Measure 1255",
                    visualNameFull: "New Measure 1255 (Venue Map)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "AggregatedMeasureModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "a7326f9a-a698-41c3-9b11-996190d7b684",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "VenueMap",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    modelColumnID: "0f3503fe-ed2a-ca05-0da1-a12883cf0da7",
                },
                {
                    id: "b905c953-39f5-49d7-a70f-fa2f7227b971",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "490c4fed-b124-4b0b-a437-9e534054aaec",
                    dataSourceColumnID: "979075f7-413e-4d65-b2df-263a5f867d07",
                    dataType: "Text",
                    name: "Sections",
                    displayName: null,
                    visualNameShort: "Sections",
                    visualNameFull: "Sections (Venue Map)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "a7326f9a-a698-41c3-9b11-996190d7b684",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "VenueMap",
                    modelColumnID: "b905c953-39f5-49d7-a70f-fa2f7227b971",
                },
                {
                    id: "2d568e06-f338-4f6e-b07c-65965090eb96",
                    dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
                    dataSourceTableID: "490c4fed-b124-4b0b-a437-9e534054aaec",
                    dataSourceColumnID: "2ef2e424-d9ec-46a6-b306-27b2dfe6e099",
                    dataType: "IDUUID",
                    name: "VenueID",
                    displayName: null,
                    visualNameShort: "Venue ID",
                    visualNameFull: "Venue ID (Venue Map)",
                    dataTypeProperties: "None",
                    defaultAggregationFunc: "None",
                    displayColumnReferenceName: null,
                    columnLevelSecurity: {},
                    $type: "DataSourceModelColumn",
                    modelID: "4b486f2b-3ffc-4026-a3fc-2a5e51d48403",
                    modelTableID: "a7326f9a-a698-41c3-9b11-996190d7b684",
                    modelTableType: "DataSourceModelTable",
                    modelTableName: "VenueMap",
                    modelColumnID: "2d568e06-f338-4f6e-b07c-65965090eb96",
                },
            ],
            rowLevelSecurity: {},
            position: {
                x: -261,
                y: 792.5,
            },
            $type: "DataSourceModelTable",
        },
    ],
    relationships: [
        {
            id: "b5593af5-d6af-473b-8137-4b4606c2da5e",
            leftColumnID: "f248245d-4751-439f-861d-36449c4fd896",
            rightColumnID: "fdac9aad-8555-46e1-8fce-bb71b6d97210",
        },
        {
            id: "b2c5291e-eaa3-4db4-b8cd-2892527e3f78",
            leftColumnID: "e0656050-872c-41b3-9fd1-108886e138d5",
            rightColumnID: "b8bbf026-74f7-402c-b21c-06e520eb18bc",
        },
        {
            id: "d934cef0-e074-468c-8af7-f010c80bd900",
            leftColumnID: "478c817f-beaf-482a-a084-652cf3f3fad8",
            rightColumnID: "6d20e685-5a80-40f2-a7df-742ac10180fc",
        },
        {
            id: "5434e4d7-7be2-4f43-83f0-98ba3da6a463",
            leftColumnID: "d95c7629-6e68-4b96-b83b-3e2540401ebb",
            rightColumnID: "a1b2664d-a575-420f-b86d-c2d50dfc6ac9",
        },
        {
            id: "b6c0615e-0ac4-46cb-9147-cbcab625561e",
            leftColumnID: "478c817f-beaf-482a-a084-652cf3f3fad8",
            rightColumnID: "5111a677-d43b-4f82-8096-95ebc23d7312",
        },
        {
            id: "efbe4b7f-d304-41a4-b253-fd2eae998312",
            leftColumnID: "2c1ba76e-d189-4657-a241-a48df1589b40",
            rightColumnID: "a5774e58-006c-40c4-a9da-fffe7061d4f6",
        },
        {
            id: "20c45cee-416c-4019-b2f8-a66d7a161370",
            leftColumnID: "70950103-ccd1-4252-ab48-cac39c1e3329",
            rightColumnID: "4c12cf95-bb7a-49a7-8b75-f588c389479b",
        },
        {
            id: "07c513a5-4445-4be2-b18e-b9c109ad1fc1",
            leftColumnID: "6910efb0-cd66-4b45-b9ae-373bae75911a",
            rightColumnID: "32daa8ff-638f-42cc-a2fa-3b51eed5adf8",
        },
        {
            id: "999f6090-36f5-4c22-b390-e2d4f1588659",
            leftColumnID: "a0d4a7e9-41e4-4dd2-bc9b-e0bdee5d7b54",
            rightColumnID: "2d568e06-f338-4f6e-b07c-65965090eb96",
        },
        {
            id: "9d4dc68a-f316-4cc6-b2ee-dacf8c42b120",
            leftColumnID: "51d0c19f-932a-4013-97a2-fd52f6aa187b",
            rightColumnID: "d418d519-5daa-41e0-8e41-87bf94d92d54",
        },
        {
            id: "392a9f0b-93f6-469f-b8a3-75d3bf3a48ce",
            leftColumnID: "5854adf2-0f7a-46d3-9b6d-8a34df45c896",
            rightColumnID: "9a7504be-f34e-42e6-a920-90c1269f27b1",
        },
        {
            id: "319b65f4-477d-42c4-9049-5babf3da56b9",
            leftColumnID: "c484ae3c-26b7-4e92-ae00-12c8dde581ff",
            rightColumnID: "70950103-ccd1-4252-ab48-cac39c1e3329",
        },
        {
            id: "c2994eb7-2a18-4595-b11a-31a0df51163f",
            leftColumnID: "348ee7b6-511d-05ba-15c8-aa413c2a0bc8",
            rightColumnID: "6d20e685-5a80-40f2-a7df-742ac10180fc",
        },
        {
            id: "9a8b3be1-b2c0-4f22-a8d7-678cb960f4e9",
            leftColumnID: "10c25f7c-4f82-4cff-b341-c69da1d121ff",
            rightColumnID: "6d20e685-5a80-40f2-a7df-742ac10180fc",
        },
        {
            id: "9ab4c287-096f-4bf9-9e7b-a8e908249714",
            leftColumnID: "478c817f-beaf-482a-a084-652cf3f3fad8",
            rightColumnID: "ad3618ba-8a94-4468-a6cc-7c6a006854b7",
        },
        {
            id: "0aa176d5-1866-42dd-b7f7-672aa87bb7a5",
            leftColumnID: "5e788e7e-3fcf-1294-105f-9821bad6b85b",
            rightColumnID: "6d20e685-5a80-40f2-a7df-742ac10180fc",
        },
        {
            id: "77bd2730-6213-4b13-b656-92cd602b844d",
            leftColumnID: "d38856c2-c8ff-7afe-d375-046e6bec076d",
            rightColumnID: "ab7bf53a-28a5-8f6b-d048-c5cfbfaba046",
        },
    ],
    dataSourceIDs: ["cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf", "076b802f-ef41-4d96-b538-495ca2113bc0", "3ec1cb6f-20c9-47fa-95b7-efb69cb7be6c"],
    dataSourceErrors: [
        {
            id: "5cbf3c60-b95a-44ba-bd9b-b6a833cebba1",
            error: "ServerNotAvailable",
            message:
                "A network-related or instance-specific error occurred while establishing a connection to SQL Server. The server was not found or was not accessible. Verify that the instance name is correct and that SQL Server is configured to allow remote connections. (provider: TCP Provider, error: 40 - Could not open a connection to SQL Server)",
            dataSourceID: "cc9a84ba-0b60-4e6c-b8a9-1aabe9bdf0cf",
            identifiedUtc: "2021-03-13T02:54:31.17Z",
            $type: "DatabaseNotAvailable",
        },
    ],
    hideSuggestedRelationships: true,
};
