import "./download-button.scss";

import React from "react";
import {css, cx} from "emotion";

import {cs} from "../../../../../react/chain-services";
import {getELoc} from "../../../../../react/get-e-loc";
import {UseState} from "../../../../../react/use-state";
import {Static2} from "../../../../../react/static-2";
import {AnyAction2} from "../../../../../react/any-action-2";
import {consumeContext} from "../../../../../react/context";

import {ConfirmDialog} from "../../../../../../web-client/src/routes/common/confirm-dialog/confirm-dialog";
import {moveFiltersToDashboard} from "../../../../../../web-client/src/routes/collection/edit/common/download-report-common";
import {SelectCollectionDialog} from "../../../../../../web-client/src/routes/collection/common/dnd-grid-panel/tiles/controls/select-collection-dialog/select-collection-dialog";
import {MapFieldsForCloningTileDialog} from "../../../../../../web-client/src/routes/collection/common/dnd-grid-panel/tiles/controls/map-fields/map-fields-for-cloning-tile-dialog";

import {SelectReportToDownload} from "./select-report-to-download";
import {StyledClass} from "@common/react/styled-class";

export const DownloadButton = ({editMode, collection, draggingState, getTileFilters, isLiveDashboard, showPdfDownload}) =>
    cs(
        consumeContext("routing"),
        consumeContext("apis"),
        consumeContext("collectionToast"),
        consumeContext("theme"),
        ["openSelectReport", (_, next) => UseState({next})],
        ["openMoreControl", (_, next) => UseState({next})],
        ["moreControlBoxRef", (_, next) => Static2({next})],
        ["selectFilterBoxRef", (_, next) => Static2({next})],
        [
            "confirmRemoveDialog",
            (_, next) =>
                ConfirmDialog({
                    next,
                    title: "Delete Download Reports Button",
                    body: "Deleting the Download Reports button requires you to move the attached downloadable reports. Would you like to move these reports to the dashboard canvas or delete the tiles?",
                    cancelText: "Move to Dashboard",
                    submitText: "Delete Reports",
                }),
        ],
        ({selectFilterBoxRef, openSelectReport}, next) =>
            AnyAction2({
                disabled: !editMode || !openSelectReport.value,
                getDom: selectFilterBoxRef.get,
                fn: () => openSelectReport.onChange(false),
                next,
            }),
        ({moreControlBoxRef, openMoreControl}, next) =>
            AnyAction2({
                disabled: !editMode || !openMoreControl.value,
                getDom: moreControlBoxRef.get,
                fn: () => openMoreControl.onChange(false),
                next,
            }),
        [
            "cloneTiles",
            ({apis, routing, collectionToast}, next) =>
                next(async ({selected, sourceTiles}) => {
                    const targetCol = selected.id
                        ? await apis.collection.getCollection(selected.id)
                        : await apis.collection.upsertCollection(selected);

                    let updated = {...targetCol};
                    updated.filterDownloadTiles = updated.filterDownloadTiles.concat(
                        sourceTiles.map((s) => ({
                            ...s.tile,
                            title: `${s.tile.title} Copy`,
                        }))
                    );
                    if (targetCol.filterStyle.display == "SDK") {
                        moveFiltersToDashboard({
                            value: updated,
                            change: (reduceValue) => {
                                updated = reduceValue(updated);
                            },
                        });
                    }

                    await apis.collection.upsertCollection(updated);

                    collectionToast.show({
                        text: `Tiles successfully copied to ${targetCol.name}`,
                        action: {
                            text: "Go to Collection",
                            onClick: () =>
                                routing.goto("edit-collection", {
                                    colId: targetCol.id,
                                }),
                        },
                    });
                }),
        ],
        [
            "mapFieldsDialog",
            ({apis, routing, cloneTiles}, next) =>
                MapFieldsForCloningTileDialog({
                    onDone: async ({collection, selected}) => {
                        await cloneTiles({
                            selected,
                            sourceTiles: collection.gridLocations,
                        });
                    },
                    next,
                }),
        ],
        [
            "selectCollectionDialog",
            ({cloneTiles, mapFieldsDialog}, next) =>
                SelectCollectionDialog({
                    next,
                    collection,
                    onDone: async (selected) => {
                        if (selected.modelID == collection.value.modelID) {
                            await cloneTiles({
                                selected,
                                sourceTiles: collection.value.filterDownloadTiles,
                            });
                        } else {
                            mapFieldsDialog.show({
                                collection: {
                                    ...collection.value,
                                    gridLocations: collection.value.filterDownloadTiles.map((tile) => ({tile})),
                                },
                                modelID: selected.modelID,
                                selected,
                            });
                        }
                    },
                }),
        ],
        [
            "selectReportToDownload",
            (_, next) =>
                SelectReportToDownload({
                    next,
                    collection,
                    getTileFilters,
                    isLiveDashboard,
                    showPdfDownload,
                }),
        ],
        ({
            openSelectReport,
            selectFilterBoxRef,
            routing,
            openMoreControl,
            moreControlBoxRef,
            confirmRemoveDialog,
            selectCollectionDialog,
            selectReportToDownload,
            theme,
        }) => {
            const downloadButtonTheme = theme.general.buttons.downloadButton;

            return cs(
                [
                    "downloadButtonCss",
                    (_, next) =>
                        StyledClass({
                            content: {
                                background: `${downloadButtonTheme.backgroundColorRGB}`,
                                color: `${downloadButtonTheme.fontColorRGB}`,
                                border: `${downloadButtonTheme.borderWidth}px solid ${downloadButtonTheme.borderColorRGB}`,
                                "border-radius": `${downloadButtonTheme.cornerRadius}px`,
                                "&:hover, &.selecting": {
                                    background: `${downloadButtonTheme.hoverBackgroundColorRGB}`,
                                    color: `${downloadButtonTheme.hoverFontColorRGB}`,
                                    "border-color": `${downloadButtonTheme.hoverBorderColorRGB}`,
                                },
                            },
                            next,
                        }),
                ],
                ({downloadButtonCss}) => (
                    <div className="download-report-button-overlay-a99">
                        <button
                            className={cx(
                                "download-report-button-a99",
                                (openSelectReport.value || openMoreControl.value) && "selecting",
                                downloadButtonCss
                            )}
                            onClick={() => {
                                if (collection) {
                                    selectReportToDownload.show();
                                }
                            }}
                        >
                            <DownloadIcon />

                            {editMode && (
                                <>
                                    <span className="report-btn-overlay" />

                                    <span
                                        className="btn drag-btn"
                                        onMouseDown={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            draggingState.onChange({
                                                draggingNew: {
                                                    pos: getELoc(e),
                                                    movingFilter: true,
                                                },
                                            });
                                        }}
                                    >
                                        <DragIcon />
                                    </span>

                                    {openSelectReport.value && (
                                        <div className="select-report-to-edit" ref={selectFilterBoxRef.set}>
                                            {collection.value?.filterDownloadTiles.map((report, index) => (
                                                <div
                                                    className="report-item"
                                                    key={index}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        routing.goto("edit-tile", {
                                                            tileId: report.id,
                                                            ...(["CannotDisplay", "Invalid"].includes(report.fieldValidity) && {
                                                                tileTab: "fields",
                                                            }),
                                                        });
                                                    }}
                                                >
                                                    {report.title}
                                                </div>
                                            ))}
                                        </div>
                                    )}

                                    <span
                                        className="btn edit-btn"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            if (collection.value?.filterDownloadTiles.length == 1) {
                                                const report = collection.value?.filterDownloadTiles[0];

                                                routing.goto("edit-tile", {
                                                    tileId: report.id,
                                                    ...(["CannotDisplay", "Invalid"].includes(report.fieldValidity) && {
                                                        tileTab: "fields",
                                                    }),
                                                });
                                            } else {
                                                openSelectReport.onChange(true);
                                            }
                                        }}
                                    >
                                        <EditIcon />
                                    </span>
                                    <span
                                        className="btn more-action-btn"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            openMoreControl.onChange(true);
                                        }}
                                    >
                                        <MoreIcon />
                                    </span>

                                    {openMoreControl.value && (
                                        <div className="more-controls" ref={moreControlBoxRef.set}>
                                            <div
                                                className="control-item"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    selectCollectionDialog.show();
                                                }}
                                            >
                                                Clone to...
                                            </div>

                                            <div
                                                className="control-item delete-item"
                                                onClick={async (e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    const resp = await confirmRemoveDialog.show();
                                                    if (resp === false) {
                                                        moveFiltersToDashboard(collection);
                                                    }

                                                    if (resp === true) {
                                                        collection.change((c) => ({
                                                            ...c,
                                                            filterDownloadTiles: [],
                                                        }));
                                                    }
                                                }}
                                            >
                                                Delete
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                        </button>

                        {selectReportToDownload.render()}
                    </div>
                )
            );
        }
    );

const DownloadIcon = () => (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11 7H16L10 13L4 7H9V0H11V7ZM2 16H18V9H20V17C20 17.2652 19.8946 17.5196 19.7071 17.7071C19.5196 17.8946 19.2652 18 19 18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V9H2V16Z"
            fill="currentColor"
        />
    </svg>
);

const DragIcon = () => (
    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.75 1.5C4.75 0.675 5.425 1.01629e-07 6.25 6.55671e-08C7.075 2.95052e-08 7.75 0.675 7.75 1.5C7.75 2.325 7.075 3 6.25 3C5.425 3 4.75 2.325 4.75 1.5ZM6.25 7.5C7.075 7.5 7.75 6.825 7.75 6C7.75 5.175 7.075 4.5 6.25 4.5C5.425 4.5 4.75 5.175 4.75 6C4.75 6.825 5.425 7.5 6.25 7.5ZM6.25 12C7.075 12 7.75 11.325 7.75 10.5C7.75 9.675 7.075 9 6.25 9C5.425 9 4.75 9.675 4.75 10.5C4.75 11.325 5.425 12 6.25 12ZM1.75 9C0.925 9 0.25 9.675 0.25 10.5C0.25 11.325 0.925 12 1.75 12C2.575 12 3.25 11.325 3.25 10.5C3.25 9.675 2.575 9 1.75 9ZM1.75 7.5C2.575 7.5 3.25 6.825 3.25 6C3.25 5.175 2.575 4.5 1.75 4.5C0.925 4.5 0.25 5.175 0.25 6C0.25 6.825 0.925 7.5 1.75 7.5ZM1.75 3C2.575 3 3.25 2.325 3.25 1.5C3.25 0.675 2.575 2.26206e-07 1.75 2.62268e-07C0.925 2.9833e-07 0.25 0.675001 0.25 1.5C0.25 2.325 0.925 3 1.75 3Z"
            fill="white"
        />
    </svg>
);

const EditIcon = () => (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3.207 7.99907L8.278 2.92807L7.571 2.22107L2.5 7.29207V7.99907H3.207ZM3.6215 8.99907H1.5V6.87757L7.2175 1.16007C7.31126 1.06633 7.43842 1.01367 7.571 1.01367C7.70358 1.01367 7.83074 1.06633 7.9245 1.16007L9.339 2.57457C9.43274 2.66833 9.48539 2.79548 9.48539 2.92807C9.48539 3.06065 9.43274 3.1878 9.339 3.28157L3.6215 8.99907ZM1.5 9.99907H10.5V10.9991H1.5V9.99907Z"
            fill="white"
        />
    </svg>
);

const MoreIcon = () => (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7 2.5C7 1.95 6.55 1.5 6 1.5C5.45 1.5 5 1.95 5 2.5C5 3.05 5.45 3.5 6 3.5C6.55 3.5 7 3.05 7 2.5ZM7 9.5C7 8.95 6.55 8.5 6 8.5C5.45 8.5 5 8.95 5 9.5C5 10.05 5.45 10.5 6 10.5C6.55 10.5 7 10.05 7 9.5ZM7 6C7 5.45 6.55 5 6 5C5.45 5 5 5.45 5 6C5 6.55 5.45 7 6 7C6.55 7 7 6.55 7 6Z"
            fill="white"
        />
    </svg>
);
