const {cache2} = require("../../../../utils/cache2");
const {getTooltipPositioner} = require("./positioners/tooltip-positioners");
const {getSharedTooltipFormatter} = require("./shared-tooltip-formatter/shared-tooltip-formatters");
const {isMobile} = require("./positioners/is-mobile");
const {getTooltipScrollingInfo} = require("./positioners/tooltip-scrolling-helpers");

const getTooltipOptions = ({tile, theme, isCompare, formatters, rawData}) => {
    const tooltipFormatter = getSharedTooltipFormatter(tile.$type);
    const tooltipPositioner = getTooltipPositioner(tile.$type);
    // const tooltipPositioner = undefined;
    const cachedTooltipFormatter = cache2(tooltipFormatter, ({points}) =>
        JSON.stringify([
            ...points.map((p) => {
                const point = p.point || p;
                return point.name + (point.x?.toString() || "") + (point.y?.toString() || "");
            }),
            isCompare,
        ])
    );

    return {
        ...(isMobile() && {className: "mobile"}), // ref. render-chart.scss .highcharts-tooltip.mobile
        shape: "square",
        padding: 0,
        headerFormat: "",
        useHTML: true,
        backgroundColor: null,
        // borderColor: "#707070",
        borderWidth: 0,
        shadow: false,
        style: {
            fontFamily: `"${theme.general.canvas.fontFamily || "Roboto"}", sans-serif`,
        },
        outside: true,

        // show one tooltip with all series' points in the group
        // should be "false" for venn diagram since it is unnecessary and causes error when runs Series.prototype.searchPoint
        shared: tile.$type !== "VennDiagramTile",

        // split: false,
        hideDelay: 0,
        // stickyTracking: false,
        animation: false, // animation makes placing tooltip anchor not correct, disable it for now

        tooltipStyle: theme.dataVisualization.toolTipsAndLegends,

        positioner: function (labelWidth, labelHeight, point) {
            const {chart, container: tooltipContainer, distance = 0} = this;
            let pos = tooltipPositioner({
                chart,
                tile,
                theme,
                tooltipContent: cachedTooltipFormatter({
                    points: chart.hoverPoints,
                    tile,
                    theme,
                    rawData,
                    formatters,
                    isCompare,
                })(),
            });

            // the calculated position is related with <body>
            // but in this commit of highchart https://github.com/highcharts/highcharts/commit/e3256cd2e5f8f47e0e870b08a90fa2a11c203f53
            // it add offset of chart's container to tooltip's position
            // the code below is return new position that is related with chart's container.
            const pointer = chart.pointer;
            const {left, top} = pointer.getChartPosition();

            return {
                x: pos.x - (left - distance),
                y: pos.y - (top - distance),
            };
        },

        // positioner: undefined,

        formatter: function () {
            const points = (() => {
                if (
                    [
                        "PieChartTile",
                        "DonutChartTile",
                        "MapTile",
                        "FunnelChartTile",
                        "VennDiagramTile",
                        "ScatterPlotTile",
                        "BubbleChartTile",
                        "BoxPlotTile",
                    ].includes(tile.$type)
                ) {
                    return [this];
                }
                if (tile.$type === "GaugeTile") {
                    return [];
                }
                return this.points;
            })();

            //Cuong: Chart sometime changed points for no reason, so that why i make this
            const _points = tile.$type === "GaugeTile" ? points : points[0].series ? points : points[0].points;

            const tooltipContent = cachedTooltipFormatter({
                points: _points,
                tile,
                theme,
                rawData,
                formatters,
                isCompare,
            });

            const chart = tile.$type === "GaugeTile" ? this.series?.chart : _points[0].series?.chart;

            if (!chart) {
                return ``;
            }

            const noAnchor = ["GaugeTile", "PieChartTile", "DonutChartTile", "MapTile", "FunnelChartTile", "VennDiagramTile"].includes(
                tile.$type
            );
            const scrollingInfo = getTooltipScrollingInfo({
                tile,
                chart,
                tooltipContent: tooltipContent(),
                tooltipStyle: theme.dataVisualization.toolTipsAndLegends,
            });

            return `
                <div class="chart-tooltip-wrapper">
                    ${tooltipContent({scrollingInfo})}
                    ${!noAnchor ? '<div class="tooltip-anchor"></div>' : ""}
                </div>
            `;
        },
    };
};
exports.getTooltipOptions = getTooltipOptions;
