import * as React from "react";
import "./no-data-screen.scss";
import {consumeContext} from "../../../../react/context";
import {cx} from "emotion";
import {cs} from "../../../../react/chain-services";
import {UseState} from "../../../../react/use-state";
import {Static2} from "../../../../react/static-2";
import {ExpandPosition} from "../../../dropdown/expand-position";

export const NoDataScreen = ({size, theme}) =>
    cs(["hovering", (_, next) => UseState({next})], ["domRef", (_, next) => Static2({next})], consumeContext("sdkStaticUrl"), ({hovering, domRef, sdkStaticUrl}) => {
        return (
            <div className="no-data-screen-3sd" ref={domRef.set}>
                <div className={cx("content", {"no-text": size.height <= 2})} onMouseEnter={() => hovering.onChange(true)} onMouseLeave={() => hovering.onChange(false)}>
                    <div className="icon">
                        <img src={sdkStaticUrl(require("./no-data-icon.svg"))} alt="" />
                    </div>
                    {size.height > 2 && (
                        <div
                            className="text"
                            style={{
                                color: theme?.general.canvas.fontColorRGB,
                                fontFamily: `"${theme?.general.canvas.fontFamily || "Roboto"}", sans-serif`,
                            }}
                        >
                            No data available
                        </div>
                    )}
                </div>
                {size.height <= 2 &&
                    hovering.value &&
                    cs(
                        [
                            "expandPosition",
                            (_, next) =>
                                ExpandPosition({
                                    dom: domRef.get(),
                                    minHeight: 25,
                                    next,
                                }),
                        ],
                        ({expandPosition}) => (
                            <div
                                className="hover-text-container"
                                style={{
                                    ...(expandPosition.top
                                        ? {
                                              top: expandPosition.top,
                                          }
                                        : {
                                              bottom: expandPosition.bottom,
                                          }),
                                    ...(expandPosition.left
                                        ? {
                                              left: expandPosition.left,
                                          }
                                        : {
                                              right: expandPosition.right,
                                          }),
                                    width: domRef.get()?.getBoundingClientRect().width,
                                }}
                            >
                                <div
                                    className="text"
                                    style={{
                                        color: "white",
                                        fontFamily: `"${theme?.general.canvas.fontFamily || "Roboto"}", sans-serif`,
                                    }}
                                >
                                    No data available
                                </div>
                            </div>
                        )
                    )}
            </div>
        );
    });
