const {required} = require("@common/react/cs-form/validators/required");

exports.tableKPIFormConfig = {
    fields: {
        title: {
            transforms: ["trim"],
            validators: [required],
            debounce: true,
        },
    },
};
