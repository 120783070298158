import React from "react";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
const fonts = ["Roboto", "Montserrat", "Lato", "Open Sans"];

export const FontFamilyDropdown = ({value, onChange, hasThemeDefaultOption, ...props}) =>
    cs(consumeContext("theme"), ({theme}) => {
        const defaultFontFamilyGroup = theme.general.canvas.fontFamily;
        const list = !hasThemeDefaultOption
            ? fonts
            : [
                  null, // theme default option
                  ...fonts,
              ];

        return DropdownSelect({
            label: props.label || "Font Family",
            list: list.map((label) => ({
                label: label,
                value: label ? label.replace(/ /g, "") : null,
            })),
            valueToLabel: (v) => (v.label === null ? `Theme Default (${defaultFontFamilyGroup})` : v.label),
            isSelected: (v) => (!v.value && !value) || v.value === value,
            onChange: (v) => onChange(v.value),
        });
    });
