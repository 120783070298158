export const dataSourceTypes = {
    FILE: "File",
    GOOGLE_SHEETS: "GoogleSheets",
    MONGODB: "MongoDB",
    DYNAMODB: "DynamoDB",
    MYSQL: "MySql",
    SQLSERVER: "SqlServer",
    POSTGRESQL: "PostgreSQL",
    SUPABASE: "Supabase",
    OUTBOUNDAPI: "OutboundAPI",
    AMAZONDSP: "AmazonDSP",
    BIGQUERY: "BigQuery",
    ELASTICSEARCH: "Elasticsearch",
    REDSHIFT: "Redshift",
    SNOWFLAKE: "Snowflake",
    FIRESTORE: "Firestore",
};

export const structuredDSTypes = [dataSourceTypes.MYSQL, dataSourceTypes.SQLSERVER, dataSourceTypes.SQLSERVER, dataSourceTypes.POSTGRESQL];

export const unstructuredDSTypes = [dataSourceTypes.MONGODB, dataSourceTypes.FIRESTORE];
