import "./debug-function.scss";

import {cx} from "emotion";
import moment from "moment";
import React from "react";

import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {UseState} from "@common/react/use-state";

import {getFieldType} from "@common/ui-components/charts/common/get-field-type";
import {TableFuncs} from "@common/ui-components/charts/table/paged/search/search-columns";
import {SortIcon} from "@common/ui-components/charts/table/table/header/header";
import {SearchHeader} from "@common/ui-components/charts/table/table/header/search-header";
import {GetFiltersData} from "@common/ui-components/live/live-dashboard/data/get-filters-data";
import {RefreshSpinnerIcon} from "@common/ui-components/live/live-dashboard/live-grid/auto-refresh-controller";
import {LoadingIndicator} from "@common/ui-components/loading-indicator/loading-indicator";

import {fragments} from "@common/react/fragments";
import {spc} from "@common/react/state-path-change";
import {Watch} from "@common/react/watch";
import {changePath} from "@common/utils/arr-path";
import {omit} from "@common/utils/objects";
import {cyrb53} from "@common/utils/strings";
import {Button, ButtonWithTooltip} from "@common/form/buttons/button/button";
import {GhostButton} from "@common/form/buttons/ghost-button/ghost-button";
import TableIcon from "../../../../../../../../../../assets/icons/common/TableIcon";
import {LeftPanelClosable} from "../../../../../../../../../common/left-panel-closable/left-panel-closable";
import {joinTypes} from "../../../../../../../../../model/transformation/main/right-panel/join-data/configuration/join-types";
import {FieldItem} from "../../../../../../../../edit/common/field-item/field-item";

export const DebugFunction = ({tile, apiType = "collection", isChildTile, next}) => {
    return cs(
        consumeContext("apis"),
        consumeContext("collection"),
        consumeContext("selectedTimezone"),
        [
            "state",
            ({}, next) =>
                UseState({
                    next,
                    initValue: {
                        showUI: false,
                        debugSteps: false,
                        previewData: {},
                        activeStep: -1,
                    },
                }),
        ],
        ({apis, collection, state, selectedTimezone}) => {
            const {showUI, debugSteps, activeStep, isRefreshingData, previewData, currentHash} = state.value;

            const generateFiltersData = (tableOverrides = {}) => {
                return {
                    timezoneId: selectedTimezone?.value,
                    filterValues: GetFiltersData.getTileFilters(tile.value.id, isChildTile)?.getValue?.() ?? {},
                    // tableOverrides: GetFiltersData.getTableOverrides()
                    tableOverrides,
                };
            };

            const showDebugUI = async () => {
                spc(state, ["showUI"], () => true);

                const res = await apis[apiType].getDebugSteps({
                    collectionID: collection?.value?.id,
                    tileID: tile?.value?.id,
                    filters: generateFiltersData(),
                });

                spc(state, ["debugSteps"], () => res);
            };

            const selectStep = async ({stepIndex, isRefreshingData = false, tableOverrides}) => {
                const filters = generateFiltersData(tableOverrides);
                const hash = `${stepIndex}_${cyrb53(filters)}`;

                let newState = {
                    ...state.value,
                    activeStep: stepIndex,
                    isRefreshingData,
                    currentHash: hash,
                };

                state.onChange(newState);

                if (stepIndex >= 0 && (!previewData[hash] || isRefreshingData)) {
                    const res = await apis[apiType].getDebugStepPreview({
                        collectionID: collection?.value?.id,
                        tileID: tile?.value?.id,
                        stepIndex: stepIndex + 1,
                        filters,
                    });

                    newState = changePath(newState, ["previewData", hash], () => ({
                        lastCachedAt: new Date(),
                        data: res,
                    }));
                }

                state.onChange({
                    ...newState,
                    isRefreshingData: false,
                });
            };

            return next({
                render: () => (
                    <div className="actions">
                        <div className="debug-button">
                            <ButtonWithTooltip
                                content="The debug function allows you to view data involved and the query steps required to create this tile."
                                direction="right"
                                btnType="border"
                                size="medium"
                                onClick={() => showDebugUI()}
                                iconLeft={<BugIcon />}
                            >
                                Debug
                            </ButtonWithTooltip>
                        </div>
                    </div>
                ),
                showDebugUI,
                override: () => {
                    if (!showUI) {
                        return null;
                    }

                    return cs(
                        (_, next) =>
                            !!debugSteps
                                ? next()
                                : LoadingIndicator({
                                      className: "tile-action-loading-53s",
                                  }),
                        ({}, next) =>
                            LeftPanelClosable({
                                label: "Debug Query Steps",
                                content: next(),
                                onClose: () => {
                                    state.onChange({
                                        showUI: false,
                                        debugSteps: false,
                                        previewData: {},
                                        activeStep: -1,
                                    });
                                },
                            }),
                        () => {
                            return (
                                <div className="debug-steps-fvv">
                                    <div className={cx("step", activeStep == -1 && "selected")} onClick={() => selectStep({stepIndex: -1})}>
                                        <div className="index-number">
                                            <div>1</div>
                                        </div>
                                        <div className="step-label">
                                            Tables Involved <i className="fa-solid fa-angle-right right" aria-hidden="true" />
                                        </div>
                                    </div>

                                    {debugSteps?.steps.map((s, i) => (
                                        <div
                                            key={i}
                                            className={cx("step", activeStep == i && "selected")}
                                            onClick={() => selectStep({stepIndex: i})}
                                        >
                                            <div className="index-number">
                                                <div>{i + 2}</div>
                                            </div>
                                            <div className="step-label">
                                                {s} <i className="fa-solid fa-angle-right right" aria-hidden="true" />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            );
                        }
                    );
                },

                overrideAll: (() =>
                    showUI && {
                        main: ({size}) => {
                            if (activeStep == -1) {
                                return FirstDebugStep({
                                    tables: debugSteps?.involvedTables || [],
                                });
                            }

                            const currentStep = debugSteps?.steps[activeStep];

                            return PreviewTable({
                                data: previewData[currentHash],
                                title: currentStep,
                                isRefreshingData,
                                refetch: async (tableOverrides) =>
                                    selectStep({
                                        stepIndex: activeStep,
                                        isRefreshingData: true,
                                        tableOverrides,
                                    }),
                            });
                        },
                    })(),
            });
        }
    );
};

const FirstDebugStep = ({tables}) => {
    const getJoinType = (joinType) => joinTypes.find((j) => j.value == joinType);
    return (
        <div className="first-debug-step-v45">
            <div className="title">Tables and the joins Used to create this tile</div>
            <div className="tables-n-join">
                {tables.map((t, i) => {
                    const currentJoin = t.join == "Outer" ? getJoinType("FullOuter") : getJoinType(t.join);
                    return (
                        <React.Fragment key={i}>
                            {t.join && (
                                <FieldItem
                                    className="has-data-source join-function"
                                    leftIcon={<img src={currentJoin.iconSrc} alt={currentJoin.label} />}
                                    label={currentJoin.label}
                                />
                            )}
                            <FieldItem className="has-data-source" leftIcon={<TableIcon />} label={t.name} />
                        </React.Fragment>
                    );
                })}
            </div>
        </div>
    );
};

const PreviewTable = ({data, refetch, title, isRefreshingData}) =>
    cs(
        ["search", ({}, next) => TableFuncs({next, singleSort: true})],
        ({search}, next) => {
            return fragments(
                Watch({
                    value: JSON.stringify(search.tableSearches),
                    onChanged: async (tableSearches) => {
                        tableSearches = JSON.parse(tableSearches);
                        const currentSortColumn = tableSearches.find((c) => c.sortDirection);
                        await refetch({
                            tableSortOverride: currentSortColumn
                                ? {
                                      columnIndex: currentSortColumn.columnIndex,
                                      direction: currentSortColumn.sortDirection,
                                  }
                                : {},
                            tableSearches: tableSearches.filter((c) => c.searchText).map((c) => omit(c, ["sortDirection"])),
                        });
                    },
                }),
                next()
            );
        },
        ({search}) => {
            const {data: rows, columns} = data?.data || {};
            const isLoading = !data?.data || !rows || !columns;

            return (
                <>
                    <div className={cx("preview-data-panel-8we", title && "with-header")}>
                        {title && <div className="preview-header">{title}</div>}
                        <div className="preview-table">
                            <div className="title">
                                <div className="top-100-rows">Top 100 rows</div>

                                <div
                                    className="fetching-box"
                                    style={{
                                        marginRight: `calc(50% - 350px / 2)`,
                                    }}
                                >
                                    {isLoading || isRefreshingData ? (
                                        <>
                                            <RefreshSpinnerIcon />
                                            <span className="loading-text">Fetching Data...</span>
                                        </>
                                    ) : (
                                        <>
                                            Data retrieved at {moment(data.lastCachedAt).format("hh:mm A")}{" "}
                                            <GhostButton onClick={() => refetch()} className="ghost-btn">
                                                Refresh
                                            </GhostButton>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="table-container">
                                {(isLoading || isRefreshingData) && (
                                    <div className="loading-text">
                                        <div>IT MAY TAKE SEVERAL SECONDS TO FETCH THE DATA.</div>
                                    </div>
                                )}
                                <table>
                                    <thead>
                                        <tr>
                                            {(columns || []).map((c, i) => {
                                                const {columnIndex, sortDirection} = search.tableSearches?.find(
                                                    (s) => s.columnIndex === i
                                                ) || {
                                                    columnIndex: null,
                                                    sortDirection: null,
                                                };

                                                return (
                                                    <th className="header-a323" key={i}>
                                                        <div className="name" onClick={() => search.sortColumn(i)}>
                                                            {c.name}
                                                            <div className="sort-icon">
                                                                <div
                                                                    className={cx(
                                                                        "sort-up",
                                                                        columnIndex == i && sortDirection == "Asc" && "active"
                                                                    )}
                                                                >
                                                                    <SortIcon />
                                                                </div>

                                                                <div
                                                                    className={cx(
                                                                        "sort-down",
                                                                        columnIndex == i && sortDirection == "Desc" && "active"
                                                                    )}
                                                                >
                                                                    <SortIcon />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {c.type !== "number" && (
                                                            <div className="actions">
                                                                {SearchHeader({
                                                                    search,
                                                                    columnName: c.name,
                                                                    columnIndex: i,
                                                                })}
                                                            </div>
                                                        )}
                                                    </th>
                                                );
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!(isLoading || isRefreshingData) &&
                                            rows.map((row, i) => (
                                                <tr key={i}>
                                                    {(columns || []).map((c, j) => (
                                                        <td key={j} className={getFieldType(c)}>
                                                            {row[j]}
                                                        </td>
                                                    ))}
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </>
            );
        }
    );

export const BugIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
            <path d="M5.07 16A7.06 7.06 0 0 1 5 15v-1H3v-2h2v-1c0-.34.024-.673.07-1H3V8h2.674a7.03 7.03 0 0 1 2.84-3.072l-1.05-1.05L8.88 2.465l1.683 1.684a7.03 7.03 0 0 1 2.876 0l1.683-1.684 1.415 1.415-1.05 1.05A7.03 7.03 0 0 1 18.326 8H21v2h-2.07c.046.327.07.66.07 1v1h2v2h-2v1c0 .34-.024.673-.07 1H21v2h-2.674a7 7 0 0 1-12.652 0H3v-2h2.07zM9 10v2h6v-2H9zm0 4v2h6v-2H9z" />
        </svg>
    );
};
