import React, {createElement as h} from "react";

import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {UseState} from "@common/react/use-state";
import {AnyAction} from "@common/react/any-action";
import {cx} from "emotion";
import "./header-bar.scss";
import {CollectionsIcon, DataSourcesIcon, ModelsIcon, SettingsIcon, ShareTilesIcon, SwapIcon, ThemeIcon} from "../../dashboard/layout/top-section/icons";
import {CookieStorage} from "../../../../../common/logic/cookie-storage";
import {equalDeep} from "@common/utils/objects";
import {SelectTenantDialog} from "../../guest-routes/login/select-tenant-dialog/select-tenant-dialog";
import {getUser} from "../../guest-routes/login/login-route";
import {getUnauthorizedParamValues} from "../../../verb-web/authorization/authorization";

export const LogoHeader = ({routing, envId, environment, onGoBack}) =>
    cs(
        consumeContext("auth"),
        consumeContext("apis"),
        [
            "opened",
            (_, next) => (
                <UseState
                    {...{
                        initValue: false,
                        next,
                    }}
                />
            ),
        ],
        ({opened}, next) =>
            h(AnyAction, {
                disabled: !opened.value,
                fn: () => opened.onChange(false),
                next,
            }),
        ["selectTenantDialog", ({}, next) => SelectTenantDialog({next})],
        ({opened, auth, apis, selectTenantDialog}) => {
            const user1 = CookieStorage.get("verb.user");
            const access = CookieStorage.get("verb.auth.access");

            const unauthorizedTabs = getUnauthorizedParamValues({
                roles: auth.user.roles,
                routeName: "dashboard",
                param: "tab",
            });

            const envTabs = [
                {
                    iconSvg: DataSourcesIcon,
                    icon: "datasource",
                    title: "Data Sources",
                    background: "#20B78F1F",
                    tab: "data-sources",
                },
                {
                    iconSvg: ModelsIcon,
                    icon: "model",
                    title: "Models",
                    background: "#FCEBEB",
                    tab: "models",
                },
                {
                    iconSvg: CollectionsIcon,
                    icon: "collection",
                    title: "Collections",
                    background: "#1497DF1F",
                    tab: "collections",
                },
                {
                    iconSvg: ShareTilesIcon,
                    icon: "share-tile",
                    title: "Shared Tiles",
                    background: "#9544D71F",
                    tab: "shared-tiles",
                },
                {
                    iconSvg: ThemeIcon,
                    icon: "datasource",
                    title: "Themes",
                    background: "#ff98003d",
                    tab: "themes",
                },
                {
                    iconSvg: SettingsIcon,
                    icon: "setting",
                    title: "Settings",
                    background: "#1F4B611F",
                    tab: "settings",
                },
            ]
                .filter((t) => !unauthorizedTabs.includes(t.tab))
                .map((t) => ({...t, onClick: () => routing.goto("dashboard", {envId, tab: t.tab})}));

            const accountTabs = [
                {
                    icon: () => SettingsIcon({fill: "#919EB0"}),
                    title: "Account Settings",
                    onClick: () => routing.goto("account-management"),
                },
                // equalDeep(user1.roles, ["Verb"]) ?
                // {
                //     icon: () => SwapIcon({fill: "#919EB0"}),
                //     title: "Change Tenant",
                //     onClick: async () => {
                //         const [user, tenantId] = await Promise.all([getUser(), selectTenantDialog.show()]);
                //
                //         if (tenantId) {
                //             auth.setAuth({user, tenantId, access});
                //             CookieStorage.set("verb.admin.tenantid", tenantId.toString());
                //             window.location.href = "/";
                //         }
                //     },
                // },
                // : null,
            ].filter((v) => v);

            return (
                <div className="header-logo" onClick={() => opened.onChange(!opened.value)}>
                    <img src={require("./icons/menu-burger.svg")} className="burger-icon" alt="" />

                    <div className={cx("nav-menu", opened.value && "show")}>
                        {/*{!unauthorizedTabs.includes("overview") && (*/}
                        {/*    <div className="section">*/}
                        {/*        <div*/}
                        {/*            className="section-action"*/}
                        {/*            onClick={() =>*/}
                        {/*                routing.goto("dashboard", {*/}
                        {/*                    envId,*/}
                        {/*                    tab: "overview",*/}
                        {/*                })*/}
                        {/*            }*/}
                        {/*        >*/}
                        {/*            <i className="far fa-arrow-left" />*/}
                        {/*            Back to Overview*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*)}*/}

                        <div className="section">
                            <div className="section-title" onClick={onGoBack}>
                                <i className="far fa-arrow-left" />
                                {environment?.name}
                            </div>

                            <div className="section-body">
                                {envTabs.map((tab, index) => (
                                    <div className="section-item" key={index} onClick={() => tab.onClick()}>
                                        <div
                                            className="icon"
                                            style={{
                                                background: tab.background,
                                            }}
                                        >
                                            {tab.iconSvg({
                                                className: `icon-${tab.icon}`,
                                            })}
                                            {/* <img src={require(`./icons/${tab.icon}.svg`)} alt=""/> */}
                                        </div>

                                        {tab.title}
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="section">
                            <div className="section-title">Account Management</div>

                            <div className="section-body">
                                {accountTabs.map((tab, index) => (
                                    <div className="section-item" key={index} onClick={() => tab.onClick()}>
                                        <div className="icon">{tab.icon()}</div>

                                        {tab.title}
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="section">
                            <div
                                className="section-item"
                                onClick={() => {
                                    auth.logout();
                                    apis.user.signOut();
                                }}
                            >
                                <div className="icon">
                                    <img style={{width: 20}} src={require(`../icons/menu/logout-icon.svg`)} alt="" />
                                </div>
                                Logout
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    );
