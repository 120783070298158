const {changePath} = require("../utils/arr-path");

const spc = (state, path, change, ...other) => {
    if (!state) {
        return;
    }
    if (state.change) {
        state.change((v) => changePath(v, path, change), ...other);
    } else {
        state.onChange(changePath(state.value, path, change), ...other);
    }
};
exports.spc = spc;
