import React from "react";
import {IconDown, IconUnchanged, IconUp} from "../../../common/icons/icons";
import "./render-comparison.scss";
import {cx} from "emotion";
import {cs} from "../../../../../react/chain-services";
import {consumeContext} from "../../../../../react/context";

export const cRenderComparison = ({chart, formatters}) => {
    const compareSeries = chart.series.find((s) => s.options.isCompare);

    return (dataPoint) => {
        const respData = compareSeries.data.find((p) => p.name === dataPoint.name);
        if (!respData) {
            return (
                <div className="percent-comparison-3ta percent-comparison" style={{color: "#7e7e7e"}}>
                    N/A
                </div>
            );
        }

        const isUp = dataPoint.percentage > respData.percentage;
        const isUnchanged = () => formatters.percentFormatter(Math.abs(dataPoint.percentage - respData.percentage)) === "0%";

        return cs(consumeContext("theme"), ({theme}) => (
            <div
                className={cx("percent-comparison-3ta percent-comparison", {
                    isUp,
                })}
                style={{
                    color: `${isUnchanged() ? "#7e7e7e" : isUp ? theme?.valueFontColorGoodRGB || "#18C96E" : theme?.valueFontColorBadRGB || "#E95A5A"}`,
                }}
            >
                {isUnchanged()
                    ? IconUnchanged({className: "unchanged"})
                    : isUp > 0
                    ? IconUp({fill: theme?.valueFontColorGoodRGB || "#18C96E"})
                    : IconDown({
                          fill: theme?.valueFontColorBadRGB || "#E95A5A",
                      })}
                {formatters.percentFormatter(Math.abs(dataPoint.percentage - respData.percentage))}
            </div>
        ));
    };
};
