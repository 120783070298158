import "./chart-outer-spark-line-kpi-layout.scss";

import React from "react"
import { cx } from "emotion";

import { cs } from "@common/react/chain-services";
import { UseState } from "@common/react/use-state";
import { SuspendUpdate } from "@common/react/suspend-update";

import { ChartTitle } from "../chart-title";
import { isChartTitleHidden } from "../chart-title-display-rule";
import { renderChildTile } from "../../render-child-tile";
import { childModalTileDialog, TITLE_ALIGNMENT_TO_JUSTIFY_CONTENT } from "../chart-outer-layout";

export const ChartOuterSparkLineKpiLayout = ({ tile, size, theme, tileFilters, isLive, sdkDashboard, content, renderOverlay }) => cs(
    ["tileAction", (_, next) => UseState({ initValue: null, next })],
    ["modalTileAction", (_, next) => childModalTileDialog({ next, tileFilters, isLive, sdkDashboard })],
    ({ tileAction, modalTileAction }) => {
        const style = tile.style || {};

        const hasTitle = (
            (typeof style?.title?.show != 'undefined' ? style?.title.show : false) && !isChartTitleHidden({ tile, size })
        ) || tile?.isChild;

        const titleHeaderHeight = 0; // AB#312
        const nonTitleHeaderHeight = 0;
        const contentHeight = `calc(100% - ${hasTitle ? titleHeaderHeight : nonTitleHeaderHeight}px)`;

        const rHeader = () => (
            <div className="header"
                style={{
                    ...tile.style.textAlignmentTypeValues ? {
                        justifyContent: tile.style.textAlignmentTypeValues.toLowerCase() == "center" ? "center" : tile.style.textAlignmentTypeValues.toLowerCase() == "right" ? "flex-end" : "",
                    } : {},
                    ...tile.style.title?.titleAlign ? {
                        justifyContent: TITLE_ALIGNMENT_TO_JUSTIFY_CONTENT[tile.style.title.titleAlign],
                    } : {}
                }}
            >
                {hasTitle && ChartTitle({ tile, theme })}
            </div>
        )

        return renderOverlay ? renderOverlay() : (
            <>
                <div className={cx(
                    "chart-outer-spark-line-kpi-layout-444 chart-outer-single-kpi-layout",
                )}
                    style={{
                        opacity: tileAction.value?.tile ? 0 : 1
                    }}
                >
                    <div className="single-kpi-controls-99u"
                        id={`spark-line-kpi-controls-portal-${tile.id}`}
                        style={{
                            ...tile.style.titlePosition === "Top" ? { top: 10 } : { bottom: 10 }
                        }}
                    />

                    {cs(
                        (_, next) => tile.style.titlePosition === "Top" ? next() : null,
                        () => rHeader()
                    )}

                    <div className={cx("content")}
                        style={{ height: contentHeight }}
                    >
                        {content?.({ tileAction, modalTileAction })}
                    </div>

                    {cs(
                        (_, next) => tile.style.titlePosition === "Bottom" ? next() : null,
                        () => rHeader()
                    )}
                </div>

                <div className={cx("chart-tile-override-88i", { active: tileAction.value?.tile })}>
                    {cs(
                        ({}, next) => SuspendUpdate({ active: !tileAction.value?.tile, next }),
                        () => tileAction.value?.tile ? cs(
                            ["chartChildTile", (_, next) => renderChildTile({
                                next,
                                size,
                                tile: {
                                    ...tileAction.value?.tile,
                                    isChild: true,
                                    goBack: () => tileAction.onChange(null)
                                },
                                tileAction: tileAction.value,
                                filterVals: tileAction.value.filterVals,
                            })],
                            ({ chartChildTile }) => chartChildTile.render()
                        ) : null,
                    )}
                </div>
            </>
        )
    }
);
