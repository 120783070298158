import {mapColorApplicationToThemeColor} from "@common/ui-components/charts/get-field-color";
import {generateColorScale} from "@common/utils/color-util";

export const decorateBoxPlotData = ({rawData, tile, theme}) => {
    return {
        ...rawData,
        series: seriesDecorators({rawData, tile, theme}),
    };
};

const seriesDecorators = ({rawData, tile, theme}) => {
    const getColor = cGetBoxPlotColor({
        tile,
        theme,
        colorApplication:
            tile.style.boxPlotColorAppliedBy === "Measure"
                ? tile.style.valueColorApplication // discrete
                : tile.style.colorApplication, //scheme
        numberOfColors: 20,
    });

    return rawData.series.map((s) => {
        if (s.type === null) {
            return {
                ...s,
                data: s.data.map((d1, i) => {
                    const color = getColor(i);
                    return {
                        low: d1[1],
                        q1: d1[2],
                        median: d1[3],
                        q3: d1[4],
                        high: d1[5],
                        name: d1[0],
                        color: color,
                        fillColor: color,
                        medianColor: d1[1] === d1[3] || d1[3] === d1[5] ? color : "#fff",
                    };
                }),
                // fillColor: '#00FF00'
            };
        }
        return {
            ...s,
            data: s.data.map((s1, j) => {
                const color = getColor(s1[0]);
                return {
                    x: s1[0],
                    y: s1[1],
                    color: color,
                    fillColor: color,
                };
            }),
        };
    });
};

export const cGetBoxPlotColor = ({tile, theme, colorApplication, numberOfColors}) => {
    const displayColor = mapColorApplicationToThemeColor(theme.dataVisualization.dataColorPalettes, colorApplication);

    if (colorApplication.$type === "DiscreteColorApplication") {
        return () => displayColor;
    }

    return (index) => {
        const colorScale = generateColorScale(displayColor, colorApplication.type === "Sequential", 0, numberOfColors); // isSequential = false
        // console.log(colorScale)
        // return 123
        return colorScale.getColor(index);
    };
};
