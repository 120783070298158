import React from "react";

export const FilterIcon = ({className, stroke = "#294661"}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="13.722" height="12.5" viewBox="0 0 13.722 12.5" className={className}>
        <path
            fill="none"
            stroke={stroke}
            strokeLinecap={"round"}
            strokeLinejoin={"round"}
            strokeWidth="1.5px"
            className="a"
            d="M15.222,4.5H3l4.889,5.781v4L10.333,15.5V10.281Z"
            transform="translate(-2.25 -3.75)"
        />
    </svg>
);

export const TableIcon = ({className, fill = "#294661"}) => (
    <svg className={className} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10 7V11H16V7H10ZM8 7H2V11H8V7ZM10 16H16V13H10V16ZM8 16V13H2V16H8ZM10 2V5H16V2H10ZM8 2H2V5H8V2ZM1 0H17C17.2652 0 17.5196 0.105357 17.7071 0.292893C17.8946 0.48043 18 0.734784 18 1V17C18 17.2652 17.8946 17.5196 17.7071 17.7071C17.5196 17.8946 17.2652 18 17 18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0Z"
            fill={fill}
        />
    </svg>
);

export const ColumnIcon = ({className, fill = "#294661"}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="18" viewBox="0 0 7 13" className={className}>
        <path
            className="a"
            fill={fill}
            d="M6801,2600h-7v-13h7v13Zm-5.833-3.715v2.786h4.667v-2.786Zm0-3.715v2.786h4.667v-2.786Zm0-3.714v2.785h4.667v-2.785Z"
            transform="translate(-6794 -2587)"
        />
    </svg>
);

export const NoPrimaryKeyIcon = ({className, fill = "#294661"}) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="12" height="11.574" viewBox="0 0 12 11.574">
        <g id="No_Primary_Key" data-name="No Primary Key" transform="translate(-289.925 -289.61)">
            <path
                id="Path_849"
                data-name="Path 849"
                d="M303.3,293.718l1.232,1.23,2.053-2.054-1.232-1.232.821-.821-1.232-1.232-3.9,3.9,1.233,1.233Z"
                transform="translate(-4.66)"
                fill="#919eb0"
            />
            <path
                id="Path_850"
                data-name="Path 850"
                d="M289.925,291.676l3.567,3.567a2.909,2.909,0,1,0,2.81,2.81l3.581,3.581.991-.991-9.958-9.958Zm4.3,7.287a1.162,1.162,0,1,1,.028-1.643,1.162,1.162,0,0,1-.028,1.643Z"
                transform="translate(0 -0.451)"
                fill="#919eb0"
            />
        </g>
    </svg>
);
