import React from "react";
import "./data-menu-skeleton-loading.scss";

export const DataMenuSkeletonLoading = () => {
    return (
        <div className="data-menu-skeleton-loading">
            <div className="container large">
                <div className="loading-bar" />
            </div>

            <div className="container large">
                <div className="loading-bar" />
            </div>

            <div className="container medium">
                <div className="loading-bar" style={{maxWidth: 230}} />
            </div>

            <div className="container small">
                <div className="circle-loading-bar" />
                <div className="loading-bar" style={{maxWidth: 240}} />
            </div>

            <div className="container small">
                <div className="circle-loading-bar" />
                <div className="loading-bar" />
            </div>

            <div className="container small">
                <div className="circle-loading-bar" />
                <div className="loading-bar" style={{maxWidth: 240}} />
            </div>

            <div className="container medium">
                <div className="loading-bar" style={{maxWidth: 230}} />
            </div>

            <div className="container small">
                <div className="circle-loading-bar" />
                <div className="loading-bar" />
                <div className="action-bar" />
            </div>

            <div className="container small">
                <div className="circle-loading-bar" />
                <div className="loading-bar" />
                <div className="action-bar center" />
            </div>

            <div className="container small">
                <div className="circle-loading-bar" />
                <div className="loading-bar" />
                <div className="action-bar" />
            </div>
        </div>
    );
};
