import {cs} from "../../../../react/chain-services";
import {css, cx} from "emotion";
import {EllipsisVAlt, RemoveFilterIcon} from "../icons/icons";
import {consumeContext} from "../../../../react/context";
import "./remove-tile-control-filter.scss";
import {StyledClass} from "@common/react/styled-class";

export const RemoveTileControlFilter = ({tile, hideBorder}) =>
    cs(
        consumeContext("theme"),
        consumeContext("tileActionControlFilter"),
        [
            "iconClass",
            ({}, next) =>
                StyledClass({
                    content: {
                        svg: {
                            path: {
                                fill: "#ff5959 !important",
                                "fill-opacity": "1 !important",
                            },
                        },
                    },
                    next,
                }),
        ],
        ({tileActionControlFilter, iconClass}) =>
            tileActionControlFilter?.value?.action?.value &&
            tileActionControlFilter?.value?.tile.id == tile.id && (
                <div
                    className={cx("remove-tile-control-filter-a33", iconClass, hideBorder && "hide-border")}
                    onClick={() => {
                        tileActionControlFilter.onChange({
                            tile: tileActionControlFilter?.value?.hasPrevTileAction ? null : tileActionControlFilter?.value?.tile,
                            action: {
                                ...tileActionControlFilter?.value?.action,
                                value: null,
                            },
                        });
                    }}
                >
                    {RemoveFilterIcon()}
                </div>
            )
    );
