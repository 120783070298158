import {cs} from "@common/react/chain-services";
import * as React from "react";
import {DialogService} from "../../common/dialog/dialog-service";
import "./leave-dialog.scss";
import {keyed} from "@common/react/keyed";
import {Button} from "../../../../../common/form/buttons/button/button";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";

export const LeaveDialog = ({next: rootNext, title, messages, buttons}) =>
    cs(
        [
            "modal",
            ({}, next) =>
                DialogService({
                    render: ({resolve}) => ({
                        title,
                        content: next({
                            resolve,
                        }),
                    }),
                    next: rootNext,
                }),
        ],
        ({modal}) => (
            <div className="leave-dialog-8sa">
                <VerbDialogBodyScrollbar>
                    <div className="messages">{messages.map((m, i) => cs(keyed(i), () => <div className="message">{m}</div>))}</div>
                </VerbDialogBodyScrollbar>

                <div className="buttons">
                    <Button btnType="secondary" onClick={() => modal.resolve("discard")}>
                        Discard
                    </Button>
                    <Button onClick={() => modal.resolve("save")}>{buttons.save.label}</Button>
                </div>
            </div>
        )
    );
