import {FieldSection} from "../../../../../collection/tile/edit/left-panel/tabs/fields-tab/chart-types/common/field-section/field-section";
import {FieldInfoProvider} from "../../../../../collection/tile/edit/left-panel/tabs/tile-tab/chart-types/common/field-info-provider/field-info-provider";
import {LpColorLine} from "../../../../../common/left-panel/lp-color-line";
import {ffToColorPickerLine} from "../../../../../../../../common/form/ff-to-color-picker-line";
import {NumberInput} from "../../../../../../../../common/form/number-input/number-input";
import React from "react";
import {ffToNumberInput} from "../../../../../../../../common/form/ff-to-number-input";

export const ComponentAndInputTheme = ({form}) => {
    return (
        <>
            {FieldSection({
                header: FieldInfoProvider({
                    field: <div className="text">INPUT STYLE</div>,
                    noCollapsible: true,
                }),
                content: (
                    <>
                        {LpColorLine({
                            label: "Input Background color",
                            ...ffToColorPickerLine(form.field(["general", "components", "inputBackgroundColorRGB"])),
                        })}

                        {LpColorLine({
                            label: "Input Border Color",
                            ...ffToColorPickerLine(form.field(["general", "components", "inputBorderColorRGB"])),
                        })}

                        {NumberInput({
                            label: "Input Border Width",
                            unit: "px",
                            ...ffToNumberInput(form.field(["general", "components", "inputBorderWidth"])),
                            showZero: true,
                        })}

                        {NumberInput({
                            label: "Corner Radius",
                            unit: "px",
                            ...ffToNumberInput(form.field(["general", "components", "inputCornerRadius"])),
                            showZero: true,
                        })}

                        {LpColorLine({
                            label: "Default Text Color",
                            ...ffToColorPickerLine(form.field(["general", "components", "inputTextColorRGB"])),
                        })}

                        {LpColorLine({
                            label: "Hint Text Color",
                            ...ffToColorPickerLine(form.field(["general", "components", "inputHintTextColorRGB"])),
                        })}

                        {LpColorLine({
                            label: "Icon Color",
                            ...ffToColorPickerLine(form.field(["general", "components", "inputIconColorRGB"])),
                        })}

                        {LpColorLine({
                            label: "Label Text Color",
                            ...ffToColorPickerLine(form.field(["general", "components", "inputLabelTextColorRGB"])),
                        })}
                    </>
                ),
            })}

            {FieldSection({
                header: FieldInfoProvider({
                    field: <div className="text">MENU STYLE</div>,
                    noCollapsible: true,
                }),
                content: (
                    <>
                        {LpColorLine({
                            label: "Menu Background color",
                            ...ffToColorPickerLine(form.field(["general", "components", "menuBackgroundColorRGB"])),
                        })}

                        {NumberInput({
                            label: "Corner Radius",
                            unit: "px",
                            ...ffToNumberInput(form.field(["general", "components", "menuCornerRadius"])),
                            showZero: true,
                        })}

                        {LpColorLine({
                            label: "Text Color",
                            ...ffToColorPickerLine(form.field(["general", "components", "menuTextColorRGB"])),
                        })}

                        {LpColorLine({
                            label: "Hover / Active Background Color",
                            ...ffToColorPickerLine(form.field(["general", "components", "menuHoverBackgroundColorRGB"])),
                        })}
                    </>
                ),
            })}
        </>
    );
};
