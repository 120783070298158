import {LpColorLine} from "../../../../../../common/left-panel/lp-color-line";
import React, {Fragment} from "react";

export const CategoricalTheme = ({state}) => {
    return (
        <div className="content">
            {state.value.map((color, index) => (
                <Fragment key={index}>
                    {LpColorLine({
                        label: `Color ${index + 1}`,
                        state: {
                            value: color,
                            onChange: (v) => state.onChange(state.value.map((c, i) => (i == index ? v : c))),
                        },
                        key: index,
                    })}
                </Fragment>
            ))}
        </div>
    );
};
