export const textFilterControlTypes = [
    {
        label: "Single Select Dropdown",
        name: "SingleSelectDropdown",
    },
    {
        label: "Multi Select Dropdown",
        name: "MultiSelectDropdown",
    },
    {
        label: "Checkbox",
        name: "Checkbox",
    },
    {
        label: "Button Toggle",
        name: "ButtonToggle",
    },
    {
        label: "Button Group",
        name: "ButtonGroup",
    },
];
