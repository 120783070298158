import {cache2} from "../../../../../utils/cache2";
import {getTooltipDimensions} from "./get-tooltip-dimensions";
import {getHighchartsTooltipPadding} from "./tooltip-positioner-normal";

const cachedTooltipDimensionsGetter = cache2(getTooltipDimensions, ({htmlString}) => htmlString);

export const getAvailableHeightForTooltip = ({chart, tile, tooltipStyle}) => {
    const padding = getHighchartsTooltipPadding(tile);

    if (tile.$type === "HorizontalBarChartTile") {
        const rect = chart.scrollingContainer ? chart.scrollingContainer.getBoundingClientRect() : chart.plotBorder.element.getBoundingClientRect();
        // if (tooltipStyle.position === "Below") {
        //     return window.innerHeight - (rect.y + rect.height);
        // }
        return rect.y + chart.axisOffset[0];
    }

    const plotBackgroundRect = chart.plotBackground.element.getBoundingClientRect();
    const chartContainerRect = chart.container.getBoundingClientRect();

    if (tooltipStyle.position === "Below") {
        return window.innerHeight - (chartContainerRect.y + chartContainerRect.height + padding);
    }

    return plotBackgroundRect.y + plotBackgroundRect.height / 2;
};

export const getTooltipScrollingInfo = ({tile, chart, tooltipContent, tooltipStyle}) => {
    const hcTooltipPadding = getHighchartsTooltipPadding(tile);

    const tooltipDims = cachedTooltipDimensionsGetter({
        htmlString: tooltipContent,
        padding: hcTooltipPadding,
    });

    const availableHeight = getAvailableHeightForTooltip({
        chart,
        tile,
        tooltipStyle,
    });

    return {
        scrollEnabled: tooltipDims.height > availableHeight,
        maxHeight: availableHeight,
    };
};
