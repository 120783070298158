import {LiveDashboard} from "@common/ui-components/live/live-dashboard/live-dashboard";
import collection from "./discrete-color-collection.json";
import data from "./discrete-color-data.json";

export const DiscreteColorThemeAuto = (props) => {
    return LiveDashboard({
        collection,
        defaultData: data,
        disabledTileActions: true,
        ...props,
    });
};
