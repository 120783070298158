import * as React from "react";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {defaultCustomRanges} from "../../../../../../../collection/common/filters/filter-edit-main/options/date-filter/default-filter-criteria/default-custom-ranges";
import {spc} from "@common/react/state-path-change";
import {editCustomRanges} from "../../../../../../../collection/common/filters/filter-edit-main/options/date-filter/default-filter-criteria/custom-date-ranges/edit-custom-ranges";
import {cs} from "@common/react/chain-services";
import {scope} from "@common/react/scope";
import {ShowHideToggle} from "../../../../../../../../../../common/form/toggles/show-hide-toggle";
import {stateToSelect} from "@common/ui-components/form/state-to-select";

export const DateFilterOptions = ({filter, form, tileType}) =>
    cs(({}) => {
        return (
            <div className="local-date-filter-options-537">
                <div className="range-select">
                    <div className="header">Filter Type</div>
                    <div className="content">
                        {DropdownSelect({
                            label: "Filter Type",
                            list: defaultCustomRanges,
                            valueToLabel: (item) => item.label,
                            isSelected: (item) => item.isSelected(filter.value.customRange),
                            onChange: (item) => {
                                filter.change((f) => ({
                                    ...f,
                                    ...item.onSelectValue(),
                                }));
                            },
                        })}
                    </div>
                </div>
                <div className="range-select">
                    <div className="header">Default Filter Value</div>
                    <div className="content">
                        {editCustomRanges?.[filter.value.customRange.$type]?.({
                            customRange: scope(filter, ["customRange"]),
                            form: form.scope(["customRange"]),
                        })}
                    </div>
                </div>

                <div className="compare-select">
                    <div className="header">
                        Comparison
                        <div className="control">
                            {tileType == "ListKPITile" ? (
                                <div className="hide-btn-a22">Hide</div>
                            ) : (
                                ShowHideToggle({
                                    state: {
                                        value: filter.value.compareEnabled && filter.value.compareOnByDefault,
                                        onChange: () =>
                                            filter.onChange({
                                                ...filter.value,
                                                compareEnabled: !filter.value.compareEnabled,
                                                compareOnByDefault: !filter.value.compareEnabled,
                                                ...(!filter.value.compareEnabled && {
                                                    compareDefaultRange: "PreviousPeriod",
                                                }),
                                            }),
                                    },
                                })
                            )}
                        </div>
                    </div>
                    {tileType != "ListKPITile" && filter.value.compareEnabled && filter.value.compareOnByDefault && (
                        <div className="content">
                            {DropdownSelect({
                                label: "Comparison Date Range",
                                list: ["Previous Period", "Previous Year"].map((label) => ({
                                    label,
                                    value: label.replace(/ /g, ""),
                                })),
                                valueToLabel: (v) => v.label,
                                ...stateToSelect(scope(filter, ["compareDefaultRange"]), ["value"]),
                            })}
                        </div>
                    )}
                </div>
            </div>
        );
    });
