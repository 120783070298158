import {cs} from "../../../../../../../react/chain-services";
import {consumeContext} from "../../../../../../../react/context";
import {css} from "emotion";
import {comparisonMethods} from "../../../../../filters/text-filter/comparison-methods";
import {DropdownSelectLive} from "../../../../../filters/common/dropdown-select/dropdown-select-live";
import {ffToDropdown} from "../../../../../../../form/ff-to-dropdown";
import {TextInputLive} from "../../../common/text-input-live/text-input-live";
import {ffToBasicInput} from "../../../common/ff-to-basic-input";
import * as React from "react";
import {scope} from "../../../../../../../react/scope";
import {StyledClass} from "@common/react/styled-class";
import {getDropdownCss} from "@common/ui-components/live/filters/common/dropdown-select/dropdown.config";

export const FreeFormTextPreview = ({state}) =>
    cs(
        consumeContext("theme"),
        [
            "cssClass",
            ({theme}, next) =>
                StyledClass({
                    content: getDropdownCss(theme.general.components),
                    next,
                }),
        ],
        [
            "inputClass",
            ({theme}, next) =>
                StyledClass({
                    next,
                    content: {
                        input: {
                            "&::placeholder": {
                                color: `${theme.general.components.inputHintTextColorRGB}`,
                            },
                        },
                    },
                }),
        ],
        ({theme, cssClass, inputClass}) => {
            const componentTheme = theme.general.components;
            const inputStyle = {
                border: `${componentTheme.inputBorderWidth}px solid ${componentTheme.inputBorderColorRGB}`,
                background: `${componentTheme.inputBackgroundColorRGB}`,
                color: `${componentTheme.inputTextColorRGB}`,
                borderRadius: `${componentTheme.inputCornerRadius}px`,
            };

            const filter = {
                id: "46bfb27e-30f9-4f9f-8493-ab08e08d2c45",
                option: "FreeFormText",
                design: null,
                itemsVisible: null,
                comparisonMethods: "Equals, DoesNotEqual, StartsWith, EndsWith, Contains, DoesNotContain, IsBlank, IsNotBlank",
                defaultOperator: "Contains",
                defaultFreeFormValue: null,
                hint: null,
                selectableListSort: null,
                label: "Email (Account)",
                showLabelInfoIcon: false,
                labelInfoIconText: null,
                columns: [
                    {
                        modelID: "2863ada0-8e18-4384-a291-c19a7f417b62",
                        modelTableID: "3976bbea-7989-45fa-b28e-09fd3cd70052",
                        modelColumnID: "710505f7-a43f-4efc-85bd-742163f931c1",
                        displayName: null,
                        default: true,
                    },
                ],
                multiSelectionOption: "SingleSelect",
                required: false,
                displayArea: "Visible",
                disconnectedTiles: [],
                $type: "TextFilter",
            };

            const dropdown = (() => {
                const list = comparisonMethods.filter((cm) => (filter.comparisonMethods || "Contains")?.includes(cm.name));
                if (list.length < 2) {
                    return null;
                }
                return DropdownSelectLive({
                    hideLabelOnValue: true,
                    label: "Operator",
                    list,
                    valueToLabel: (m) => m.label,
                    ...ffToDropdown({state: scope(state, ["operator"])}, ["name"]),
                    toggleStyle: inputStyle,
                    iconColor: componentTheme.inputIconColorRGB,
                    dropdownCss: cssClass,
                    borderRadius: componentTheme.menuCornerRadius,
                    fixMaxWidth: false,
                    overrideWidth: 200,
                });
            })();

            const input = TextInputLive({
                disabled: ["IsBlank", "IsNotBlank"].includes(state.operator?.name),
                placeholder: filter.hint,
                ...ffToBasicInput({state: scope(state, ["freeFormValue"])}),
                style: inputStyle,
                className: inputClass,
            });

            return (
                <div className="free-form-text-2t7 desktop">
                    {dropdown}
                    {input}
                </div>
            );
        }
    );
