const {cascade} = require("../../../../../../../common/utils/cascade");
const {tileFieldAttrs, fieldAttrTypes, getFieldAttrType} = require("../../../../common/tile-field-attrs");

const getUsingCollections = ({collections, modelTableId, modelColumnId}) => {
    const usingCollections = [];

    const getModellingIds = getFieldsModellingProp(modelTableId ? "modelTableID" : "modelColumnID");

    const isUsing = (collection) => {
        if (collection.$type === "ApiCollection" && collection.tile) {
            const attrs = tileFieldAttrs["ApiTile"];
            for (const attr of attrs) {
                const usedIds = getModellingIds(attr, collection.tile);
                if (usedIds.includes(modelTableId || modelColumnId)) {
                    return true;
                }
            }
        }

        if (collection.$type !== "ApiCollection" && collection.gridLocations?.length > 0) {
            for (const {tile} of collection.gridLocations) {
                const attrs = tileFieldAttrs[tile.$type];
                for (const attr of attrs) {
                    const usedIds = getModellingIds(attr, tile);
                    if (usedIds.includes(modelTableId || modelColumnId)) {
                        return true;
                    }
                }
            }
        }

        return false;
    };

    for (const col of collections) {
        if (isUsing(col)) {
            usingCollections.push(col.name);
        }
    }

    return usingCollections;
};
exports.getUsingCollections = getUsingCollections;

/**
 * create a function to get all fields of a tile, or the modelProp of all fields of a tile
 * */
const getFieldsModellingProp = (modelProp) => (attr, tile) => {
    const getSingleField = (field) => {
        return field ? [modelProp ? field[modelProp] : field] : [];
    };

    const getMultiFields = (fields) => {
        return fields.map((f) => (modelProp ? f[modelProp] : f));
    };

    const getFieldGroups = (fieldGroups) => {
        return cascade(fieldGroups, "[*].measureFields[*]", (f) => (modelProp ? f[modelProp] : f));
    };

    const fieldGettersMap = {
        [fieldAttrTypes.SingleField]: getSingleField,
        [fieldAttrTypes.MultiField]: getMultiFields,
        [fieldAttrTypes.FieldGroup]: getFieldGroups,
    };

    const getter = fieldGettersMap[getFieldAttrType(attr, tile.$type)];

    return getter(tile[attr]);
};
exports.getFieldsModellingProp = getFieldsModellingProp;
