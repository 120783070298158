import {tooltipLayout} from "@common/ui-components/charts/factory/tooltip/shared-tooltip-formatter/tooltip-layout";
import {getTooltipFontSizes} from "@common/ui-components/charts/factory/tooltip/shared-tooltip-formatter/get-tooltip-font-sizes";

export const renderBoxPlotTooltip = ({points, tile, theme, rawData, formatters, isCompare}) => {
    return tooltipLayout({
        content: rTooltipContent({tile, theme, formatters, points, rawData, isCompare}),
        theme,
        isCompare,
    });
};

const rTooltipContent = ({tile, theme, formatters, points, rawData}) => {
    const {point, series} = points[0];
    const fontSizes = getTooltipFontSizes(theme);

    const header = `<div class="non-compare-header" style="font-size: ${fontSizes.small}px">
        <div class="title">
            ${tile["yAxisField"].displayName}
        </div>
    </div>`;

    return `${header}
        <div class="table non-compare">
            ${rBoxPlotRows({points, tile, formatters, theme})}
        </div>`;
};

const rBoxPlotRows = ({points, tile, formatters, theme}) => {
    const {point, series} = points[0];
    const fontSizes = getTooltipFontSizes(theme);

    const yAxisValueFormatter = formatters.tooltipValueFormatters[tile.yAxisField.id];

    return [
        {label: "Category", value: formatters.dimensionFormatter(point.category)},
        point.options.high && {label: `Max`, value: yAxisValueFormatter(point.options.high)},
        point.options.low && {label: `Min`, value: yAxisValueFormatter(point.options.low)},
        point.options.median && {label: `Median`, value: yAxisValueFormatter(point.options.median)},
        point.options.y && {label: `${series.name}`, value: point.options.y},
    ]
        .filter((v) => v)
        .map((r) => {
            return `<div class="point table-row" style="font-size: ${fontSizes.medium}px">
                <div class="name">
                    ${r.label}:
                    <strong>${r.value}</strong>
                </div>
            </div>`;
        })
        .join("");
};
