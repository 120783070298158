import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import {VerbWeb} from "./verb-web/verb-web";

// replace console.* for disable log on production
if (process.env.NODE_ENV === "production" && process.env.appConfig["api.host"].indexOf("local") === -1) {
    console.log = () => {};
    console.error = () => {};
    console.debug = () => {};
}

console.log("init Verb");
ReactDOM.render(<VerbWeb />, document.getElementById("root"));

if (module.hot) {
    module.hot.accept();
}
