import React from "react";

import {consumeContext} from "@common/react/context";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {Button} from "../../../../../../../common/form/buttons/button/button";
import {TextInput} from "../../../../../../../common/form/text-input/text-input";
import {spc} from "@common/react/state-path-change";
import {scope} from "@common/react/scope";

export const EnterPasswordStep = ({description, password: oriPassword, onNext, nextBtnText = "Next", onBack}) =>
    cs(
        consumeContext("apis"),
        consumeContext("auth"),

        [
            "state",
            (_, next) =>
                UseState({
                    next,
                    initValue: {
                        password: "",
                        errorMessage: null,
                        loading: false,
                    },
                }),
        ],
        ({state, apis}) => {
            const {password, errorMessage, loading} = state.value;

            const goNext = async () => {
                if (!password || password.length == 0 || loading) {
                    return;
                }
                try {
                    spc(state, ["loading"], () => true);
                    const resp = await apis.user.verifyPassword(password);

                    if (resp) {
                        oriPassword.onChange(password);
                        await onNext?.(password);
                        spc(state, ["loading"], () => false);
                    } else {
                        throw new Error("Password is not matched.");
                    }
                } catch (e) {
                    state.onChange({
                        ...state.value,
                        errorMessage: e.message,
                        loading: false,
                    });
                }
            };

            return (
                <>
                    <div className="content">
                        <div className="enter-current-password">
                            {description && <div className="description">{description}</div>}

                            {TextInput({
                                label: "Password",
                                state: scope(state, ["password"]),
                                type: "password",
                                hasError: !!errorMessage,
                                errorMessage,
                                autoFocus: true,
                                onKeyDown: (e) => e.key == "Enter" && goNext(),
                            })}
                        </div>
                    </div>

                    <div className="buttons">
                        {onBack && (
                            <Button btnType="secondary" disabled={loading} onClick={() => onBack?.()}>
                                Back
                            </Button>
                        )}
                        <div className="space" />
                        <Button disabled={!password || password.length == 0 || loading} onClick={goNext}>
                            {nextBtnText}
                        </Button>
                    </div>
                </>
            );
        }
    );
