import "./filter-tab.scss";

import * as React from "react";

import {EditFilter} from "./edit-filter/edit-filter";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {Button} from "../../../../../../../common/form/buttons/button/button";
import {filterIcons} from "../../../../collection/edit/tabs/filters/left-panel/filters-in-collection/filters-in-collection";
import {ExpandableFilters} from "../../../../collection/tile/edit/left-panel/tabs/filter-tab/local-filters/local-filters";

// todo: state is undefined after changing contextual tile's type though not calling setState/onChange
export const FilterTab = ({filters, onChangeFilters, tileType, next}) =>
    cs(
        [
            "state",
            (_, next) =>
                UseState({
                    initValue: {editing: false},
                    next,
                }),
        ],
        ({state}) =>
            next({
                render: () => (
                    <div className="contextual-tile-filter-tab-4so">
                        <div className="add-new-filter">
                            <Button className="btn" onClick={() => state?.onChange({editing: true})}>
                                Add New Filter
                            </Button>
                        </div>

                        <ExpandableFilters
                            list={filters}
                            renderLabel={({renderTooltip}) => <div className="contextual-filters-label-42o">Filter {renderTooltip()}</div>}
                            toolTip="Contextual tile filters can only be controlled via SDK but they will show here so that you may test the filters. You may set default filter values so that data is prefiltered however values sent via SDK will override any default settings."
                            onClickItem={(filter) => state?.onChange({editing: true, filter})}
                            renderIcon={(item) => <img src={filterIcons[item.$type]} className="field-type-icon" alt="" />}
                        />
                    </div>
                ),
                override:
                    state.value?.editing &&
                    EditFilter({
                        initFilter: state.value.filter,
                        tileType,
                        onDone: async (filter) => {
                            if (filter.id) {
                                await onChangeFilters((filters) => filters.map((fi) => (fi.id === filter.id ? filter : fi)));
                            } else {
                                await onChangeFilters((filters) => [...filters, filter]);
                            }
                        },
                        onCancel: () => {
                            state.onChange({editing: false});
                        },
                        onDelete: async () => {
                            await onChangeFilters((filters) => filters.filter((filter) => filter.id !== state.value.filter.id));
                            state.onChange({editing: false});
                        },
                    }),
            })
    );
