import {Watch} from "@common/react/watch";
import {cs} from "@common/react/chain-services";
import {fragments} from "@common/react/fragments";
import {consumeContext} from "@common/react/context";

import {arrMapToO} from "@common/utils/objects";

import {FilterForms} from "./filter-forms/filter-forms";
import {GetConnectedFilters} from "./get-connected-filters";
import {serializeFilterState} from "./serialize/serialize-filter-state";

import {Submitted} from "./submitted";
import {GetFiltersData} from "./get-filters-data";

export const DashboardData = ({isEditTile = false, collection, filterVals = {}, ref, next}) =>
    cs(
        consumeContext("selectedTimezone"),
        [
            "filterForms",
            (_, next) =>
                FilterForms({
                    filters: collection.filters,
                    filterVals,
                    next,
                }),
        ],

        ["getConnectedFilters", ({filterForms}, next) => GetConnectedFilters({collection, filterForms, next})],

        [
            "submitted",
            ({filterForms, selectedTimezone}, next) =>
                Submitted({
                    filterForms,
                    next,
                    collection,
                    selectedTimezone: selectedTimezone?.value,
                }),
        ],

        ({filterForms, submitted, getConnectedFilters}, next) => {
            // this runs in sdk only where ref may present, for setting filters value through ref
            ref &&
                ref({
                    setFilterVal: (filterId, val) => {
                        const newFrozenData = filterForms.setLiveValue(filterId, val);
                        // submitted.setData?.(newFrozenData);
                    },
                });

            return fragments(
                // expose filters data for debug function when editing a tile
                isEditTile &&
                    Watch({
                        initRun: true,
                        value: {
                            submittedData: submitted.data,
                            getConnectedFilters,
                        },
                        onChanged: ({getConnectedFilters, submittedData}) => {
                            if (getConnectedFilters) {
                                GetFiltersData.setTileFiltersFunc(
                                    cGetTileFilters({
                                        getConnectedFilters,
                                        collection,
                                        submittedData,
                                    })
                                );
                            }
                        },
                    }),
                next()
            );
        },

        ({filterForms, getConnectedFilters, submitted}) => {
            return next({
                unblockChanges: submitted.flush,
                filterForms,
                getTileFilters:
                    getConnectedFilters &&
                    cGetTileFilters({
                        getConnectedFilters,
                        collection,
                        submittedData: submitted.data,
                    }),
            });
        }
    );

export const DataboardChildTile = ({isEditTile = false, collection, filterVals = {}, next}) =>
    cs(
        consumeContext("selectedTimezone"),
        [
            "filterForms",
            (_, next) =>
                FilterForms({
                    filters: collection?.filters ?? [],
                    filterVals,
                    next,
                }),
        ],
        ["getConnectedFilters", (_, next) => next(() => (collection?.filters ?? []).map((f) => f.id))],
        [
            "submitted",
            ({filterForms, selectedTimezone}, next) =>
                Submitted({
                    filterForms,
                    next,
                    collection,
                    selectedTimezone: selectedTimezone?.value,
                }),
        ],
        ({filterForms, submitted, getConnectedFilters}, next) => {
            return fragments(
                // expose filters data for debug function when editing a tile
                isEditTile &&
                    Watch({
                        initRun: true,
                        value: {
                            submittedData: submitted.data,
                            getConnectedFilters,
                        },
                        onChanged: ({getConnectedFilters, submittedData}) => {
                            if (getConnectedFilters) {
                                GetFiltersData.setChildTileFiltersFunc(
                                    cGetTileFilters({
                                        getConnectedFilters,
                                        collection,
                                        submittedData,
                                    })
                                );
                            }
                        },
                    }),
                next()
            );
        },
        ({filterForms, getConnectedFilters, submitted}) => {
            return next({
                unblockChanges: submitted.flush,
                filterForms,
                getTileFilters:
                    getConnectedFilters &&
                    cGetTileFilters({
                        getConnectedFilters,
                        collection,
                        submittedData: submitted.data,
                    }),
            });
        }
    );

const max = (col) => {
    if (!col?.length) {
        return null;
    }
    return Math.max(...col);
};

const cGetTileFilters =
    ({getConnectedFilters, collection, submittedData}) =>
    (tileId) => {
        const connectedFilterIds = getConnectedFilters(tileId);

        const connectedFilterData = connectedFilterIds.map((filterId) => ({
            filterId,
            data: submittedData.getData(filterId),
        }));

        const invalid = !!connectedFilterData.find(({data}) => data.invalid);

        return {
            invalid,
            key: `timezone:${submittedData.selectedTimezone}|${
                !invalid && max(connectedFilterData.map((r) => r.data.updatedAt).filter((v) => v))
            }`,
            getValue: () =>
                arrMapToO(
                    connectedFilterData,
                    ({filterId, data}) => {
                        const filter = collection.filters.find((f) => f.id === filterId);
                        return serializeFilterState(data.value, {
                            filter,
                            selectedTimezone: submittedData.selectedTimezone,
                        });
                    },
                    ({filterId}) => filterId
                ),
        };
    };
