import {cs} from "../../../../../react/chain-services";
import {UseState} from "../../../../../react/use-state";
import {Watch} from "../../../../../react/watch";

// get updated chart from chartRef whenever props change.
export const RevalidateChartService = ({props, chartRef, next}) =>
    cs(
        ["state", (_, next) => UseState({next, initValue: chartRef.get().chart})],
        ({state}, next) =>
            Watch({
                next,
                value: JSON.stringify(props),
                initRun: false,
                onChanged: () => {
                    setTimeout(() => {
                        if (chartRef.get()) {
                            state.onChange(chartRef.get().chart);
                        }
                    }, 10);
                },
            }),
        ({state}) => next(state.value)
    );
