const {parseDateTime} = require("../../../../../logic/date-time/parse-date-time");
const {addDate} = require("../../../../../utils/dates/date-object");
const {upperCase1} = require("../../../../../utils/strings");

const deserializeFilterState = ({value, filter}) => {
    const byTypes = {
        DateFilter: deserializeDateFilterState,
        TextFilter: deserializeTextFilterState,
        NumericFilter: deserializeNumericFilterState,
        BooleanFilter: deserializeBooleanFilterState,
    };

    return byTypes[filter.$type]?.(value, {filter});
};
exports.deserializeFilterState = deserializeFilterState;

const deserializeDateFilterState = (value, {filter}) => {
    return {
        main: {
            from: {
                ...parseDateTime(value.range.dateStart)?.date,
                ...parseDateTime(value.range.dateStart)?.time,
            },
            to: {
                ...parseDateTime(value.range.dateEnd)?.date,
                ...parseDateTime(value.range.dateEnd)?.time,
            },
        },
        ...(value.compareRange && {
            comparing: {
                from: {
                    ...parseDateTime(value.compareRange.dateStart)?.date,
                    ...parseDateTime(value.compareRange.dateStart)?.time,
                },
                to: {
                    ...parseDateTime(value.compareRange.dateEnd)?.date,
                    ...parseDateTime(value.compareRange.dateEnd)?.time,
                },
            },
            enableComparing: true,
        }),
        columns: filter.columns.filter((c) => c.default),
        allowTimeFilter: value.allowTimeFilter,
    };
};

const deserializeNumericFilterState = (value, {filter}) => ({
    comparisonOperation: value.option,

    ...(value.option === "Range"
        ? {
              range: {
                  from: value.valueOrMin,
                  to: value.max,
              },
          }
        : {
              value: value.valueOrMin,
          }),
    columns: filter.columns.filter((c) => c.default),
});

const deserializeTextFilterState = (value, {filter}) => ({
    ...value,
    columns: filter.columns.filter((c) => c.default),
});

const deserializeBooleanFilterState = (value, {filter}) => {
    return {
        value: value.value != null ? [upperCase1(value.value.toString())] : [],
        columns: filter.columns.filter((c) => c.default),
    };
};
