import * as React from "react";
import {cs} from "@common/react/chain-services";
import "./pagination.scss";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {scope} from "@common/react/scope";
import {DebounceNumber} from "../../../../../../../../../../../../../common/form/debounce-number";
import {NumberInput} from "../../../../../../../../../../../../../common/form/number-input/number-input";
import {getPath} from "@common/utils/arr-path";
import {spc} from "@common/react/state-path-change";
import {omit} from "@common/utils/objects";

export const Pagination = ({tile}) => ({
    label: "Pagination",
    render: () => {
        return (
            <div className="table-pagination-sf2">
                {DropdownSelect({
                    label: "Pagination Type",
                    list: [
                        {
                            label: "Infinite Scroll",
                            value: {paginationType: "Infinite"},
                        },
                        {
                            label: "Paged",
                            value: {paginationType: "Paged", rowsPerPage: 5},
                        },
                    ],
                    valueToLabel: (v) => v.label,
                    isSelected: (v) => v.value.paginationType === getPath(tile.value, ["style", "paginationType"]),
                    onChange: (v) =>
                        spc(tile, ["style"], (style) => ({
                            ...omit(style, ["rowsPerPage"]),
                            ...v.value,
                        })),
                })}

                {/*{getPath(tile.value, ["style", "paginationType"]) === "Paged" && }*/}
                {cs(
                    [
                        "debounce",
                        (_, next) =>
                            DebounceNumber({
                                state: scope(tile, ["style", "rowsPerPage"]),
                                next,
                            }),
                    ],
                    ({debounce}) =>
                        NumberInput({
                            label: "Rows Per Page",
                            state: debounce.state,
                            onBlur: debounce.flush,
                        })
                )}
            </div>
        );
    },
});
