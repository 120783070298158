import {cs} from "@common/react/chain-services";
import {spc} from "@common/react/state-path-change";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";

export const ErrorHandling = ({field}) =>
    cs(() =>
        DropdownSelect({
            label: "Error Handling",
            info: "Tooltip on error handling This controls how the data is shown if there is a problem in the currency conversion. Show Default Value will show the unconverted value in the currency stored in the database. Exclude Value will hide any values that can’t be converted.",
            tooltipTopOffset: 1,
            list: [
                {
                    label: "Show Default Value",
                    value: "UseUnconverted",
                },
                {
                    label: "Exclude Value",
                    value: "ExcludeMissing",
                },
            ],
            valueToLabel: (v) => v.label,
            isSelected: (v) => v.value === field.value.currencyConversion?.isoCurrencyStrategy,
            onChange: (v) => spc(field, ["currencyConversion", "isoCurrencyStrategy"], () => v.value),
        })
    );
