import React from "react";
import { bindInput } from "../../react/bind-input";
import { cs } from "../../react/chain-services";
import { Static2 } from "../../react/static-2";
import { UseState } from "../../react/use-state";
import { BasicInput } from "../common/basic-input";
import "./text-input.scss";

export const TextInput = ({state, name, autoComplete, disabled, onBlur, type, onKeyDown, placeholder, autoFocus, feedValue, renderToggle = null, className, readOnly, icon, tabIndex, maxLength, multiline, onPaste, rows, ...props}) =>
    cs(["state1", ({}, next) => UseState({initValue: type, next})], ["inputRef", (_, next) => Static2({next})], ({state1, inputRef}) =>
        BasicInput({
            ...props,
            disabled,
            className: `${className ?? ""} text-input text-input-6dd`,
            feedValue,
            icon,
            onFocus: () => inputRef.get().focus(),
            renderInput: () =>
                !multiline ? (
                    <input
                        {...{
                            ...bindInput(state),
                            name,
                            autoComplete,
                            ref: inputRef.set,
                            disabled,
                            onBlur,
                            type: state1.value,
                            onKeyDown,
                            placeholder,
                            autoFocus,
                            readOnly,
                            tabIndex,
                            maxLength,
                            onPaste
                        }}
                    />
                ) : (
                    <textarea
                        {...{
                            ...bindInput(state),
                            ref: inputRef.set,
                            disabled,
                            onBlur,
                            type: state1.value,
                            onKeyDown,
                            placeholder,
                            autoFocus,
                            readOnly,
                            tabIndex,
                            maxLength,
                            rows,
                            onPaste
                        }}
                    ></textarea>
                ),
            hasValue: !!state.value || placeholder,
            renderToggle: () => renderToggle?.(state1),
        })
    );
