import * as React from "react";

export const rSecurityIcon = ({name, active, onClick}) => {
    const color = active ? "#294661" : "#919EB0";
    const icons = [
        {
            name: "blank",
            render: () => (
                <svg onClick={onClick} width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M4.85668 0.5H14.5C14.6768 0.5 14.8464 0.570238 14.9714 0.695262C15.0964 0.820286 15.1667 0.989856 15.1667 1.16667V11.8333C15.1667 12.0101 15.0964 12.1797 14.9714 12.3047C14.8464 12.4298 14.6768 12.5 14.5 12.5H4.85668C4.74695 12.5 4.63892 12.4729 4.54216 12.4212C4.4454 12.3694 4.36291 12.2946 4.30202 12.2033L0.746682 6.87C0.67359 6.76045 0.634583 6.6317 0.634583 6.5C0.634583 6.3683 0.67359 6.23955 0.746682 6.13L4.30202 0.796667C4.36291 0.705389 4.4454 0.630555 4.54216 0.578803C4.63892 0.527052 4.74695 0.499984 4.85668 0.5ZM5.21335 1.83333L2.10268 6.5L5.21335 11.1667H13.8333V1.83333H5.21335ZM11.1667 5.83333V7.16667H6.50002V5.83333H11.1667Z"
                        fill={color}
                    />
                </svg>
            ),
        },
        {
            name: "hide",
            render: () => (
                <svg onClick={onClick} width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M11.9214 12.3647C10.7488 13.1084 9.38851 13.5023 8.00002 13.5C4.40535 13.5 1.41469 10.9134 0.787354 7.50004C1.07404 5.94719 1.85511 4.52864 3.01402 3.45604L0.92802 1.37204L1.87135 0.428711L15.0707 13.6287L14.1274 14.5714L11.9207 12.3647H11.9214ZM3.95669 4.40004C3.05069 5.22377 2.41956 6.3059 2.14869 7.50004C2.35688 8.41102 2.77486 9.26081 3.36936 9.98177C3.96385 10.7027 4.71844 11.275 5.57305 11.6529C6.42767 12.0309 7.35872 12.2041 8.29208 12.1588C9.22544 12.1135 10.1353 11.851 10.9494 11.392L9.59735 10.04C9.02181 10.4026 8.34015 10.5588 7.66418 10.483C6.9882 10.4072 6.35805 10.104 5.87707 9.623C5.39609 9.14202 5.09284 8.51186 5.01706 7.83589C4.94128 7.15991 5.09748 6.47825 5.46002 5.90271L3.95669 4.40004ZM8.60935 9.05204L6.44802 6.89071C6.3294 7.19267 6.30148 7.52268 6.36769 7.84027C6.4339 8.15787 6.59136 8.44923 6.82077 8.67863C7.05017 8.90803 7.34153 9.0655 7.65912 9.13171C7.97672 9.19792 8.30673 9.17 8.60869 9.05138L8.60935 9.05204ZM13.8714 10.5614L12.9174 9.60804C13.363 8.97291 13.6803 8.25683 13.8514 7.50004C13.6702 6.70653 13.3295 5.95814 12.8501 5.30037C12.3707 4.64261 11.7625 4.0892 11.0626 3.67378C10.3627 3.25837 9.58555 2.98962 8.77851 2.88389C7.97147 2.77815 7.15136 2.83763 6.36802 3.05871L5.31602 2.00671C6.14735 1.68004 7.05335 1.50004 8.00002 1.50004C11.5947 1.50004 14.5854 4.08671 15.2127 7.50004C15.0084 8.61051 14.5493 9.65843 13.8714 10.5614ZM7.81535 4.50538C8.23969 4.47915 8.66476 4.54342 9.06238 4.69392C9.46 4.84442 9.82109 5.07772 10.1217 5.37834C10.4223 5.67897 10.6556 6.04006 10.8061 6.43768C10.9566 6.8353 11.0209 7.26037 10.9947 7.68471L7.81469 4.50538H7.81535Z"
                        fill={color}
                    />
                </svg>
            ),
        },
        {
            name: "mask",
            render: () => (
                <svg onClick={onClick} width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M10 4.83329H11.3333C11.5101 4.83329 11.6797 4.90353 11.8047 5.02855C11.9298 5.15358 12 5.32315 12 5.49996V13.5C12 13.6768 11.9298 13.8463 11.8047 13.9714C11.6797 14.0964 11.5101 14.1666 11.3333 14.1666H0.666667C0.489856 14.1666 0.320286 14.0964 0.195262 13.9714C0.0702379 13.8463 0 13.6768 0 13.5V5.49996C0 5.32315 0.0702379 5.15358 0.195262 5.02855C0.320286 4.90353 0.489856 4.83329 0.666667 4.83329H2V4.16663C2 3.10576 2.42143 2.08834 3.17157 1.3382C3.92172 0.588053 4.93913 0.166626 6 0.166626C7.06087 0.166626 8.07828 0.588053 8.82843 1.3382C9.57857 2.08834 10 3.10576 10 4.16663V4.83329ZM1.33333 6.16663V12.8333H10.6667V6.16663H1.33333ZM5.33333 8.83329H6.66667V10.1666H5.33333V8.83329ZM2.66667 8.83329H4V10.1666H2.66667V8.83329ZM8 8.83329H9.33333V10.1666H8V8.83329ZM8.66667 4.83329V4.16663C8.66667 3.45938 8.38572 2.7811 7.88562 2.28101C7.38552 1.78091 6.70724 1.49996 6 1.49996C5.29276 1.49996 4.61448 1.78091 4.11438 2.28101C3.61428 2.7811 3.33333 3.45938 3.33333 4.16663V4.83329H8.66667Z"
                        fill={color}
                    />
                </svg>
            ),
        },
        {
            name: "error",
            render: () => (
                <svg onClick={onClick} width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M9.12399 0.166626L12.8333 3.87796V9.12463L9.12399 12.8333H3.87732L0.166656 9.12396V3.87729L3.87732 0.166626H9.12399ZM8.57132 1.49996H4.42932L1.50066 4.42996V8.57196L4.42932 11.5013H8.57132L11.5007 8.57196V4.42929L8.57132 1.50063V1.49996ZM5.83332 8.49996H7.16666V9.83329H5.83332V8.49996ZM5.83332 3.16663H7.16666V7.16663H5.83332V3.16663Z"
                        fill={color}
                    />
                </svg>
            ),
        },
    ];

    return <div className="security-icon-98w security-icon">{icons.find((icon) => icon.name === name)?.render()}</div>;
};
