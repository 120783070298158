import React from "react";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {WholePageLoading} from "@common/ui-components/whole-page-loading/whole-page-loading";
import {Load2} from "@common/react/load2";
import {UseState} from "@common/react/use-state";
import {GuestLayout} from "../../guest-routes/common/layout/guest-layout";
import {Form2} from "@common/react/cs-form/form2";
import {required} from "@common/react/cs-form/validators/required";

export const ApiCollectionLogin = ({apiDocs}) =>
    cs(
        consumeContext("routing"),
        consumeContext("apiCollectionApis"),
        ["verifyToken", (_, next) => verifyToken({next, apiDocs})],
        ["loading", ({routing}, next) => UseState({next})],
        ["error", ({routing, verifyToken}, next) => UseState({next, initValue: verifyToken?.error?.message})],
        [
            "params",
            ({routing}, next) =>
                UseState({
                    initValue: {},
                    next,
                }),
        ],
        [
            "form",
            ({params, loading, auth, apiCollectionApis, selectTenantDialog, routing, error}, next) =>
                Form2({
                    fields: {
                        apiKey: [required],
                    },
                    data: params,
                    onSubmit: async () => {
                        loading.onChange(true);
                        error.onChange(null);
                        try {
                            const resp = await apiCollectionApis.populateDocs(params.value.apiKey);
                            apiDocs.onChange({
                                docs: resp,
                                apiKey: params.value.apiKey,
                            });
                            routing.goto("api-collection-docs");
                        } catch (err) {
                            error.onChange("Invalid API Key");
                            loading.onChange(false);
                        }
                    },
                    next,
                }),
        ],
        ({error, form, loading}) =>
            GuestLayout({
                header: "VIEW API DOCS",
                error: error.value,
                form: [
                    {
                        label: "API Key",
                        field: form.field("apiKey"),
                    },
                ],
                submitBtn: {
                    label: "Continue",
                    disabled: loading.value,
                },
                onSubmit: form.submit,
            })
    );

const verifyToken = ({next, apiDocs}) =>
    cs(
        consumeContext("routing"),
        consumeContext("apiCollectionApis"),
        [
            "apiKey",
            ({apiCollectionApis, routing}, next) =>
                routing.params.token
                    ? Load2({
                          fetch: async () => {
                              try {
                                  const apiKey = await apiCollectionApis.validateToken(routing.params.token);
                                  const apiDocs = await apiCollectionApis.populateDocs(apiKey);
                                  return {docs: apiDocs, apiKey};
                              } catch (err) {
                                  throw err;
                              }
                          },
                          next,
                          captureException: true,
                      })
                    : next({}),
        ],
        ({apiKey, routing}) => {
            if (apiKey.loading) {
                return WholePageLoading({content: "Verify token..."});
            }

            if (apiKey.value) {
                apiDocs.onChange(apiKey.value);
                routing.goto("api-collection-docs");
            }

            return next(apiKey);
        }
    );
