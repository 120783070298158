import {Watch} from "@common/react/watch";
import {Invoke} from "@common/react/invoke";
import {Static2} from "@common/react/static-2";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {fragments} from "@common/react/fragments";
import {consumeContext} from "@common/react/context";

import {arrMapToO} from "@common/utils/objects";
import {isNotEmpty} from "@common/utils/collections";

import {serializeFilterState} from "./serialize/serialize-filter-state";
import {modifyTileActionControlFilter} from "./modify-tile-action-control-filter";
import {max} from "@common/utils/math-util";

export const Submitted = ({filterForms, next, collection, selectedTimezone}) => {
    const getData = (frozenFilters) => {
        return {
            selectedTimezone,
            key: `timezone:${selectedTimezone}|${max(frozenFilters.map((r) => r.updatedAt).filter((v) => v))}`,
            getData: (filterId) => frozenFilters.find((r) => r.filterId === filterId),
        };
    };

    const getFiltersValueSet = () =>
        arrMapToO(
            filterForms.freeze(),
            (filter) =>
                serializeFilterState(filter.value, {
                    filter: (collection?.filters ?? []).find((f) => f.id === filter.filterId),
                }),
            (filter) => filter.filterId
        );

    return cs(
        consumeContext("collectionFilter"),
        consumeContext("filtersValueSet"),
        consumeContext("tileActionControlFilter"),
        [
            "store",
            (_, next) =>
                UseState({
                    getInitValue: () => getData(filterForms.freeze()),
                    next,
                }),
        ],
        ["prevFilterState", (_, next) => UseState({initValue: {}, next})],
        ["updatedFromTileAction", (_, next) => Static2({next, getInitValue: () => false})],
        ({store, collectionFilter, filtersValueSet, tileActionControlFilter, updatedFromTileAction, prevFilterState}) =>
            fragments(
                next(
                    // #4838 should see collection with no filter as applying filter after each filter change
                    // or when you change timezone it can't update the filter key.
                    collection.filters.length == 0 ||
                        (collection.filterStyle?.runType === "OnChange" && collection.filterStyle.display !== "SDK")
                        ? {
                              data: getData(filterForms.freeze()),
                          }
                        : {
                              data: store.value,
                              flush: () => {
                                  store.onChange(getData(filterForms.freeze()));
                                  collectionFilter?.onChange(filterForms.freeze());
                                  tileActionControlFilter?.onChange(null);

                                  // save filters value set on submit
                                  filtersValueSet?.onChange(getFiltersValueSet());
                              },
                              setData: (frozenData) => {
                                  collectionFilter?.onChange(frozenData);
                                  return store.onChange(getData(frozenData));
                              },
                          }
                ),
                Invoke({
                    onMounted: () => {
                        collectionFilter?.onChange(filterForms.freeze());
                    },
                }),

                Watch({
                    value: collection.filterStyle?.runType === "OnSubmit" || collection.filterStyle?.display === "SDK",
                    onChanged: (onSubmit) => {
                        if (onSubmit) {
                            store.onChange(getData(filterForms.freeze()));
                            collectionFilter?.onChange(filterForms.freeze());
                        }
                    },
                }),

                tileActionControlFilter
                    ? Watch({
                          value: tileActionControlFilter?.value?.action,
                          onChanged: (action = {}, prevActive = {}) => {
                              const {filters, value, field} = action;

                              if (prevActive?.field != field && isNotEmpty(prevFilterState.value)) {
                                  for (let id in prevFilterState.value) {
                                      const filterForm = filterForms.getForm(id);
                                      filterForm.data.onChange(prevFilterState.value[id]);
                                  }
                              }

                              let prevObj = {};

                              if (filters?.length > 0) {
                                  updatedFromTileAction.set(true);

                                  for (let filter of filters) {
                                      const filterForm = filterForms.getForm(filter.id);

                                      if (value && !prevFilterState.value[filter.id]) {
                                          prevObj[filter.id] = filterForm.data.value;
                                      }

                                      filterForm.data.onChange(
                                          !value
                                              ? prevFilterState.value[filter.id]
                                              : {
                                                    ...filterForm.data.value,
                                                    ...modifyTileActionControlFilter(filter, value, field, filterForm.data.value),
                                                }
                                      );
                                  }

                                  if (!value) {
                                      prevFilterState.onChange({});
                                  } else {
                                      prevFilterState.onChange({
                                          ...prevFilterState.value,
                                          ...prevObj,
                                      });
                                  }

                                  setTimeout(() => {
                                      updatedFromTileAction.set(false);
                                  }, 10);
                              } else {
                                  prevFilterState.onChange({});
                              }
                          },
                      })
                    : null,

                Watch({
                    value: JSON.stringify(filterForms.freeze().map((filter) => filter.updatedAt)),
                    onChanged: () => {
                        if (
                            collection.filterStyle?.display == "SDK" ||
                            (collection.filterStyle?.runType === "OnSubmit" && updatedFromTileAction.get())
                        ) {
                            store.onChange(getData(filterForms.freeze()));
                            filtersValueSet?.onChange(getFiltersValueSet());
                        }

                        if (collection.filterStyle?.runType === "OnChange" || collection.filters.length == 0) {
                            filtersValueSet?.onChange(getFiltersValueSet());

                            if (!updatedFromTileAction.get()) {
                                tileActionControlFilter?.onChange(null);
                            }
                        }
                    },
                })
            )
    );
};
