import {camelCaseToSpaces} from "@common/utils/strings";
import {getMeasureGroups as getBarLineTileMeasureGroups} from "@common/ui-components/charts/common/bar-line-chart-helpers";

export const generateLineLabel = ({$type, value, aggregation, indicatorType}) => {
    if ($type === "ConstantValueReferenceLine") {
        return "Constant" + (value != null ? ` ${value}` : "");
    }
    if ($type === "AggregateValueReferenceLine") {
        return camelCaseToSpaces(aggregation);
        // + (
        //     (value != null && aggregation === "Percentile") ? ` ${value}` : ""
        // );
    }
    return camelCaseToSpaces(indicatorType);
};

export const shouldReplaceLabel = (label, defaultLabel) => {
    if (label.startsWith("Constant") && defaultLabel.startsWith("Constant")) {
        return true;
    }

    // if (label.startsWith("Percentile") && defaultLabel.startsWith("Percentile")) {
    //     return false;
    // }

    return label === defaultLabel;
};

export const getNewReferenceLineLabel = ({referenceLine, newConfig}) => {
    const defaultLabel = generateLineLabel({
        ...referenceLine,
        ...newConfig,
    });

    const oldDefaultLabel = generateLineLabel(referenceLine);

    // if the current label is the same as default, replace it with the new default one.
    // else, which means user has edited it, keep it.
    return shouldReplaceLabel(referenceLine.label, oldDefaultLabel) ? defaultLabel : referenceLine.label;
};

export const getMeasureGroups = ({tile, forReferenceLineType}) => {
    if (["ScatterPlotTile", "BubbleChartTile"].includes(tile.$type)) {
        return [
            tile.yAxisField,
            forReferenceLineType !== "IndicatorReferenceLine" && tile.xAxisField,
        ].filter(v => v)
    }
    return getBarLineTileMeasureGroups(tile);
};
