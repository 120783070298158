import "./table-border.scss";

import React from "react";
import {css, cx} from "emotion";

import {cs} from "../../../../../react/chain-services";
import {consumeContext} from "../../../../../react/context";
import {StyledClass} from "@common/react/styled-class";

export const TableBorder = ({isFirstRow, isLastRow, isFirstColumn, isLastColumn, forceBorderTop, hideVertical, hideHorizontal}) =>
    cs(consumeContext("theme"), ({theme}) => {
        const {borderVerticalLines, borderHorizontalLines, borderOutsideLines, borderWidth, borderColorRGB} =
            theme?.dataVisualization.tables;

        return (
            <div className="table-border-controls-a32">
                {(() =>
                    cs(
                        [
                            "cssClass",
                            (_, next) =>
                                StyledClass({
                                    content: {
                                        ...(((borderHorizontalLines && !isLastRow) || (borderOutsideLines && isLastRow)) && !hideHorizontal
                                            ? {
                                                  "&::after": {
                                                      height: `${borderWidth}px`,
                                                      background: borderColorRGB,
                                                  },
                                              }
                                            : {}),
                                        ...((borderOutsideLines && isFirstRow) || forceBorderTop
                                            ? {
                                                  "&::before": {
                                                      height: `${borderWidth}px`,
                                                      background: borderColorRGB,
                                                  },
                                              }
                                            : {}),
                                    },
                                    next,
                                }),
                        ],
                        ({cssClass}) => <div className={cx("border-horizontal", cssClass)} />
                    ))()}

                {(() =>
                    cs(
                        [
                            "cssClass",
                            (_, next) =>
                                StyledClass({
                                    content: {
                                        ...((borderVerticalLines && !hideVertical && !isLastColumn) || (borderOutsideLines && isLastColumn)
                                            ? {
                                                  "&::after": {
                                                      width: `${borderWidth}px`,
                                                      background: borderColorRGB,
                                                  },
                                              }
                                            : {}),
                                        ...(borderOutsideLines && isFirstColumn
                                            ? {
                                                  "&::before": {
                                                      width: `${borderWidth}px`,
                                                      background: borderColorRGB,
                                                  },
                                              }
                                            : {}),
                                    },
                                    next,
                                }),
                        ],
                        ({cssClass}) => <div className={cx("border-vertical", cssClass)} />
                    ))()}
            </div>
        );
    });
