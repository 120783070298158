import "./toast-with-action.scss";

import React from "react";
import {cx} from "emotion";

import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {waitTimeout} from "@common/utils/wait-timeout";
import {Static2} from "@common/react/static-2";
import {CloseIcon} from "../icons/close-icon";

export const ToastWithAction = ({next}) =>
    cs(
        ["initDisplay", (_, next) => UseState({next})],
        ["display", (_, next) => UseState({next})],
        ["timeout", (_, next) => Static2({next})],
        [
            "alert",
            (_, next) =>
                UseState({
                    initValue: {
                        text: "",
                        action: {text: "", onClick: () => {}},
                        // isError
                    },
                    next,
                }),
        ],
        ({initDisplay, display, alert, timeout}) => {
            const {text, isError, action, customIcon, hasClose} = alert.value ?? {};

            const close = async () => {
                clearTimeout(timeout.get());
                display.onChange(false);
                await waitTimeout(300);
                initDisplay.onChange(false);
            };

            return next({
                render: () =>
                    initDisplay.value && (
                        <div className={cx("toast-with-action", display.value && "display", hasClose && "has-close-btn")}>
                            <div className="toast-title">{text}</div>

                            <div className={cx("icon", !customIcon && {success: !isError})}>
                                {customIcon ??
                                    (isError ? (
                                        <img src={require("@common/ui-components/charts/common/loading-wrapper/error-handler/data-error-icon.svg")} alt="" />
                                    ) : (
                                        <img src={require("./check-icon.svg")} alt="" />
                                    ))}
                            </div>

                            {action && (
                                <div className="bottom-action">
                                    <span onClick={() => action?.onClick?.()}>{action.text}</span>
                                </div>
                            )}

                            {hasClose && (
                                <div className="close-btn" onClick={() => close()}>
                                    <CloseIcon />
                                </div>
                            )}
                        </div>
                    ),
                show: async (data) => {
                    if (initDisplay.value) {
                        await close();
                    }

                    alert.onChange(data);
                    initDisplay.onChange(true);
                    await waitTimeout(10);
                    display.onChange(true);

                    let t = setTimeout(() => {
                        close();
                    }, 5000);
                    timeout.set(t);
                },
            });
        }
    );
