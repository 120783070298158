const {Component, createElement: h} = require("react");

const Rerender = ({next, getInitValue, initValue}) => h(Rerender1, {next, getInitValue, initValue});
exports.Rerender = Rerender;
exports.Rerender1 = Rerender;

class Rerender1 extends Component {
    render() {
        const {next} = this.props;

        return next({
            invoke: (cb) => {
                setTimeout(() => this.forceUpdate(cb), 0);
            },
        });
    }
}
