import {bindInput} from "@common/react/bind-input";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {UseState} from "@common/react/use-state";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";
import {flatten1} from "@common/utils/collections";
import {isMatchText} from "@common/utils/strings";
import React from "react";
import SearchIcon from "../../../../../assets/icons/common/SearchIcon";
import "./collection-preview-profile-dropdown.scss";

export const CollectionPreviewProfileDropdown = ({collection, iframeKeys, profileFolders, domRef, style, previewMode}) =>
    cs(consumeContext("routing"), ["keyword", (_, next) => UseState({next})], ({routing, keyword}) => {
        const profiles = flatten1(profileFolders.value || [], (folder) => folder.keys).filter((p) => isMatchText(p.name, keyword.value));
        const keys = (iframeKeys.value || []).filter(
            (k) => k.collections.indexOf(collection.id) > -1 && isMatchText(k.name, keyword.value)
        );

        return (
            <div
                className="collection-preview-profile-dropdown-a33"
                style={{
                    ...(domRef
                        ? window.innerHeight - domRef.get().getBoundingClientRect().top < 300
                            ? {bottom: "100%"}
                            : {top: "100%"}
                        : {}),
                    ...style,
                }}
            >
                <div className="input-search-wrapper">
                    <SearchIcon />

                    <input
                        className="input-search"
                        placeholder="Search"
                        {...{
                            ...bindInput(keyword),
                        }}
                    />
                </div>

                <VerbScrollbar maxHeight={200}>
                    <div
                        className="item"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            if (!previewMode) {
                                routing.goto("edit-collection", {
                                    colId: collection.id,
                                });
                            } else {
                                routing.goto(
                                    "preview-collection",
                                    {
                                        colId: collection.id,
                                    },
                                    true
                                );
                            }
                        }}
                    >
                        ADMIN
                    </div>

                    {profiles.length > 0 && (
                        <>
                            <div className="sub-title">PREVIEW PROFILES</div>

                            {profiles.map((profile, index) => (
                                <div
                                    className="item"
                                    key={index}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();

                                        if (!previewMode) {
                                            routing.goto("edit-collection", {
                                                colId: collection.id,
                                                previewID: profile.id,
                                            });
                                        } else {
                                            routing.goto(
                                                "preview-collection",
                                                {
                                                    colId: collection.id,
                                                    previewID: profile.id,
                                                },
                                                true
                                            );
                                        }
                                    }}
                                >
                                    {profile.name}
                                </div>
                            ))}
                        </>
                    )}

                    {keys.length > 0 && (
                        <>
                            <div className="sub-title">IFRAME KEYS</div>

                            {keys.map((key, index) => (
                                <div
                                    className="item"
                                    key={index}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();

                                        if (!previewMode) {
                                            routing.goto("edit-collection", {
                                                colId: collection.id,
                                                previewID: key.id,
                                            });
                                        } else {
                                            routing.goto(
                                                "preview-collection",
                                                {
                                                    colId: collection.id,
                                                    previewID: key.id,
                                                },
                                                true
                                            );
                                        }
                                    }}
                                >
                                    {key.name}
                                </div>
                            ))}
                        </>
                    )}
                </VerbScrollbar>
            </div>
        );
    });
