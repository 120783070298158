import "./row-level-security.scss";

import * as React from "react";

import {cs} from "@common/react/chain-services";
import {LeftPanelClosable} from "../../../../../common/left-panel-closable/left-panel-closable";
import {consumeContext} from "@common/react/context";
import {AlertCard} from "../../../../../common/alert-card/alert-card";
import {ColumnSecurityMain} from "../column-security/main/column-security-main";
import {scc} from "@common/react/state-cascade-change";
import {UseState} from "@common/react/use-state";
import {Segmented} from "@common/ui-components/segmented/segmented";
import {CopyAuthenticationDialog} from "../copy-authentication-dialog/copy-authentication-dialog";
import {envAuthSegments, getEnvAuthOther} from "../column-security/column-security";
import {keyed} from "@common/react/keyed";
import {getDSColorBasedOnIndex} from "../../common/data-source-colors";
import {UsageStatusIndicator} from "../../common/usage-status-indicator";
import {Button} from "@common/form/buttons/button/button";
import {getBrokenRowLevelSecurityWithTable} from "../../../main/model-utils";
import {AlertIcon} from "@common/ui-components/icons/global-icons";

export const RowLevelSecurity = ({dataSources, tableId, dataSourceID, dataSourceTableID, model, onCancel}) => {
    const table = model.value.tables.concat(model.value.disabledTables).find((t) => t.id === tableId && t.dataSourceID == dataSourceID);
    return cs(
        ({}, next) => {
            const index = dataSources.findIndex((t) => t.id === table?.dataSourceID);
            const color = dataSources[index]?.colorRGB || getDSColorBasedOnIndex(index);

            return (
                <LeftPanelClosable
                    {...{
                        onClose: onCancel,
                        key: table.id,
                        className: "row-level-security-left-panel-yed",
                        label: (
                            <>
                                <span>
                                    ROW-LEVEL SECURITY for <UsageStatusIndicator status="all" color={color} />{" "}
                                    <span style={{color}}>{table?.visualName?.toUpperCase()}</span> TABLE
                                </span>
                            </>
                        ),
                        content: next(),
                    }}
                />
            );
        },
        consumeContext("authEndpoint"),
        consumeContext("routing"),
        [
            "envAuthState",
            ({authEndpoint}, next) =>
                UseState({
                    // initValue: authEndpoint.value.find?.((a) => a.$type === `EndpointAuthConfig`) ? envAuthSegments[1] : envAuthSegments[0],
                    initValue: envAuthSegments[1],
                    next,
                }),
        ],
        [
            "copyModal",
            ({envAuthState}, next) =>
                CopyAuthenticationDialog({
                    envAuthType: envAuthState.value.label,
                    envAuthOtherType: getEnvAuthOther(envAuthState.value).label,
                    levelType: "row",
                    next,
                }),
        ],
        ({authEndpoint, routing, envAuthState}, next) => {
            const brokenRowLevelSecurity = getBrokenRowLevelSecurityWithTable(table, model.value.tables, model.value.disabledTables);

            return (
                <>
                    {brokenRowLevelSecurity.length > 0 && (
                        <div className="alert-wrapper">
                            {brokenRowLevelSecurity.map((error, index) => (
                                <div className="alert-box" key={index}>
                                    <div className="alert-header">
                                        <AlertIcon className="icon" />
                                        Broken Row level security - <b>&nbsp;{error.rlsAlertTitle}</b>
                                    </div>

                                    <div className="alert-description">{error.rlsAlertText}</div>
                                </div>
                            ))}
                        </div>
                    )}

                    {/*<div className="header">*/}
                    {/*    <div className="text">Authentication Method</div>*/}

                    {/*    {Segmented({*/}
                    {/*        width: 135,*/}
                    {/*        items: envAuthSegments,*/}
                    {/*        isSelected: (v) => v.value === envAuthState.value.value,*/}
                    {/*        onChange: envAuthState.onChange,*/}
                    {/*    })}*/}
                    {/*</div>*/}
                    {next()}
                </>
            );
        },
        ({authEndpoint, routing, envAuthState, copyModal}) => {
            const envAuth = envAuthState.value.value;
            const authConfig = authEndpoint.value.find((a) => a.$type === `${envAuth}AuthConfig`);

            return !authConfig?.resultMapping || Object.keys(authConfig?.resultMapping).length === 0 ? (
                <div className="no-authentication-t5u">
                    {AlertCard({
                        message:
                            "Before you can create your row-level security rules you must set up the environment authentication method.",
                    })}
                    <div className="buttons">
                        <Button btnType="secondary" size="medium" onClick={onCancel}>
                            Return to Model
                        </Button>
                        <Button btnType="primary" size="medium" onClick={() => routing.goto("dashboard", {tab: "settings"})}>
                            Set Up Authentication
                        </Button>
                    </div>
                </div>
            ) : (
                cs(keyed(envAuth), () => (
                    <>
                        <div className="row-level-security-kr3">
                            {ColumnSecurityMain({
                                introduction: {
                                    subText: () =>
                                        `Row-level security is set for each model. If you need to set security on this table in other models please set the parameters within each model.`,
                                    beginningLine: "Show this row when",
                                },
                                table,
                                tables: model.value.tables.concat(model.value.disabledTables),
                                relationships: model.value.relationships,
                                columnLevelSecurity: table.rowLevelSecurity[envAuth],
                                onUpdate: (change) => {
                                    scc(
                                        model,
                                        `${table.deleted ? "disabledTables" : "tables"}[*].rowLevelSecurity.${envAuth}`,
                                        (_, index, {1: t}) => {
                                            if (t.id == table.id) {
                                                return change;
                                            }
                                            return _;
                                        }
                                    );

                                    if (table.deleted && !change) {
                                        onCancel();
                                    }
                                },
                                userMapping: Object.keys(authConfig?.resultMapping),
                                dataSources,
                            })}
                        </div>
                        <div className="buttons">
                            <Button btnType="secondary" size="medium" onClick={() => routing.goto("dashboard", {tab: "settings"})}>
                                Go To Authentication Settings
                            </Button>

                            {/*{cs(() => {*/}
                            {/*    const envAuthOther = getEnvAuthOther(envAuth);*/}

                            {/*    return (*/}
                            {/*        <Button*/}
                            {/*            btnType="border"*/}
                            {/*            size="medium"*/}
                            {/*            onClick={() =>*/}
                            {/*                copyModal.show({*/}
                            {/*                    authConfig,*/}
                            {/*                    onCopy: () => {*/}
                            {/*                        scc(model, `tables[*].rowLevelSecurity.${envAuthOther.value}`, (_, index, {1: t}) => {*/}
                            {/*                            if (t.id == table.id) {*/}
                            {/*                                return table.rowLevelSecurity[envAuth];*/}
                            {/*                            }*/}
                            {/*                            return _;*/}
                            {/*                        });*/}
                            {/*                    },*/}
                            {/*                })*/}
                            {/*            }*/}
                            {/*        >*/}
                            {/*            Copy to {envAuthOther.label} Auth*/}
                            {/*        </Button>*/}
                            {/*    );*/}
                            {/*})}*/}
                        </div>
                    </>
                ))
            );
        }
    );
};
