// const {drawComparisonMarks} = require("./draw-comparison-marks");
// const {forceMaxValuesInView} = require("./force-max-values-in-view");
const {adjustBarDataLabels} = require("./adjust-bar-data-labels");
const {drawRoundCorners} = require("../../bar-series-processors/draw-round-corners");
const {hideOverflowTickLabel} = require("./hide-overflow-tick-label");
const {initComparisonBarsHelper} = require("./draw-comparison-bars");
const {hideOriginYaxisGridLine} = require("../../combo-chart/chart-options/hide-origin-yaxis-grid-line");
const {adjustComparisonBars} = require("./adjust-comparison-bars");
const {getTooltipOptions} = require("../../factory/tooltip/tooltip-options");
const {getPlotOptions} = require("./plot-options");
const {getLegendOptions} = require("../../factory/legend");
const {hideHorizontalScrollbar} = require("./hide-horizontal-scrollbar");
const {fixDimAxisLabel} = require("./fix-dim-axis-label");
const {fixOverlappingXAxisGridLabels} = require("./fix-overlapping-x-axis-grid-labels");
const {SERIES_BOOST_THRESHOLD} = require("../../common/axis-chart-boost-turbo-constants");
const {xLastWeekMonSun} = require("@common/ui-components/live/live-dashboard/common/logic/x-to-date");

const getVerticalBarOptions = ({tile, theme, isCompare, isDimensionRealDate, rawData, formatters, chartElementTooltip, onClickPoint}) => {
    const chartEventsFn = ({chart}) => {
        !tile.style.xAxis.lineShown && hideOriginYaxisGridLine(chart);
        isCompare && tile.style.displayType && tile.style.displayType !== "Classic" && adjustComparisonBars({chart});
        tile.style.dataLabels.show &&
            adjustBarDataLabels({
                chart,
                position: tile.style.dataLabels.position,
            });

        if (tile.style.xAxis.maxValuesInView !== "AutoMax") {
            // logic for applying scrolling on vertical bar chart is in add-scrolling-options.js
            hideHorizontalScrollbar(chart);
            tile.style.xAxis.labelShown && tile.style.xAxis.label && fixDimAxisLabel(chart);
        }

        if (
            isDimensionRealDate &&
            tile.style.xAxis.gridStepLabelsShown
            // && !tile.style.xAxis.gridStepType // auto grid step (no gridStepType on xAxis since #1003)
        ) {
            hideOverflowTickLabel(chart);
            // when maxValuesInView is AutoMax, all grid labels are shown.
            // overlapping x-axis grid labels often happen on real date dim, so put it here
            tile.style.xAxis.gridStepLabelsShown && tile.style.xAxis.maxValuesInView === "AutoMax" && fixOverlappingXAxisGridLabels(chart);
        }
    };

    const chart = {
        type: "column",
        customType: "VerticalBarChartTile",
        backgroundColor: theme.general.tile.styles.tileBackgroundColorRGB,
        events: {
            load: function () {
                const chart = this;
                chartEventsFn({chart});

                if (isCompare && (!tile.style.displayType || tile.style.displayType === "Classic")) {
                    chart.comparisonBars = initComparisonBarsHelper({
                        compareSeries: rawData.series.filter((s) => s.isCompare),
                        tile,
                    });

                    chart.comparisonBars.render({chart});
                }
            },
            redraw: function () {
                console.log("11111");
                const chart = this;
                chartEventsFn({chart});

                if (chart.comparisonBars) {
                    chart.comparisonBars.rerender({chart});
                }
            },
            render: function () {
                drawRoundCorners.apply(this);
            },
            // click: function(event) {
            //     console.log(this.hoverPoints);
            // }
        },
        zooming: {
            mouseWheel: false,
        },
    };

    const title = {
        text: undefined,
    };

    const plotOptions = getPlotOptions({
        tile,
        theme,
        isCompare,
        formatters,
        chartElementTooltip,
        onClickPoint,
    });

    const isSparkLineKPITile = tile.$type == "SparkLineKPITile";

    const tooltip = !isSparkLineKPITile && chartElementTooltip ? getTooltipOptions({tile, theme, isCompare, formatters, rawData}) : {enabled: false};
    const legend = getLegendOptions({style: tile.style.legendStyle, theme});

    return {
        boost: {
            enabled: true,
            seriesThreshold: SERIES_BOOST_THRESHOLD,
            useGPUTranslations: true,
            usePreAllocated: true,
        },
        chart,
        title,
        plotOptions,
        tooltip,
        legend,
        credits: {
            enabled: false,
        },
    };
};
exports.getVerticalBarOptions = getVerticalBarOptions;
