import {getTooltipDimensions} from "./get-tooltip-dimensions";
import {isMobile} from "./is-mobile";
import {getHighchartsTooltipPadding} from "./tooltip-positioner-normal";

export const tooltipPositionerPieDonut = ({chart, tile, theme, tooltipContent}) => {
    // sometimes the screen is scrolled and that lifts the tooltip position. use this to balance
    const topScroll = window.document.scrollingElement?.scrollTop || 0;

    const chartContainerRect = chart.container.getBoundingClientRect();

    // try inspect body > div.highcharts-tooltip-container to see this padding
    const padding = getHighchartsTooltipPadding(tile);

    const tooltipDims = getTooltipDimensions({
        htmlString: tooltipContent,
        padding,
    });

    let posX;
    let posY;

    const bottomEdgeThreshold = 30;
    const tempAbovePosY = chartContainerRect.y - tooltipDims.height - 10;
    const tempBelowPosY = chartContainerRect.y + chartContainerRect.height - padding;
    if (theme.dataVisualization.toolTipsAndLegends.position === "Below") {
        // if tooltip fit the bottom then show it at the bottom, else show on top
        // const isFitToBottom = tempBelowPosY + tooltipDims.height < window.innerHeight - bottomEdgeThreshold;
        // posY = isFitToBottom ? tempBelowPosY : tempAbovePosY;

        // instead of the above, keep staying on below since scrolling is applied. ref. tooltip-options.js
        posY = tempBelowPosY;
    } else {
        // if tooltip fits on top then show it at the top, else show at bottom
        // const isFitToTop = tooltipDims.height < chartContainerRect.y;
        // posY = isFitToTop ? Math.max(tempAbovePosY, 0) : tempBelowPosY;

        // instead of the above, keep staying on top since scrolling is applied. ref. tooltip-options.js
        posY = Math.max(tempAbovePosY, 0); // for tooltip to always stay below top edge of screen
    }

    const isOnMobile = isMobile();
    const leftRightEdgeThreshold = isOnMobile ? 0 : 20;

    const parentRect = chart.container.parentElement.getBoundingClientRect();
    const tempPosX = chartContainerRect.x + (chartContainerRect.width - tooltipDims.width) / 2;

    if (tempPosX + tooltipDims.width > window.innerWidth - leftRightEdgeThreshold) {
        // right edge
        // posX = parentRect.right - tooltipDims.width // need padding?;
        posX = window.innerWidth - tooltipDims.width;
    } else if (tempPosX < leftRightEdgeThreshold) {
        // left edge
        posX = isOnMobile ? 0 : chartContainerRect.x;
    } else {
        posX = tempPosX;
    }

    return {
        x: posX,
        y: posY + topScroll,
    };
};
