const {cache0} = require("../../common/utils/cache0");
const {isTrue} = require("./is-true");

const isDev = cache0(() => {
    try {
        if (
            window.location.href.startsWith("http://localhost:") ||
            window.location.href.startsWith("http://192.168.") ||
            window.location.href.startsWith("https://local.staging.verbdata.app") ||
            window.location.href.startsWith("https://web.local.verbdata.app/") ||
            window.location.href.indexOf(".feature.verbdata.app") >= 0
        ) {
            return isTrue(localStorage.getItem("VERB_DEV_MODE"));
        }
    } catch (e) {
        return false;
    }
});
exports.isDev = isDev;
