import {cs} from "@common/react/chain-services";
import {TilesTab} from "./tiles/tiles-tab";
import {FiltersTab} from "./filters/filters-tab";
import {RoutingParam} from "../../../common/routing/routing-param";
import {CollectionPanel} from "./tiles/main/collection-panel";
import {createAutoSaveCollection} from "../common/auto-save-collection";
import {consumeContext} from "@common/react/context";
import {SharedTilesTab} from "./shared-tiles/shared-tiles-tab";

export const Tabs = ({next, savingQueue}) =>
    cs(
        consumeContext("collection"),
        [
            "selectedTab",
            (_, next) =>
                RoutingParam({
                    param: "collectionTab",
                    defaultValue: "tiles",
                    next,
                }),
        ],
        [
            "panel",
            ({collection}, next) =>
                CollectionPanel({
                    collection: createAutoSaveCollection({collection, savingQueue}),
                    getMinHeight: () => window.innerHeight - 54, // 54 = header height,
                    next,
                }),
        ],
        [
            "tabs",
            ({panel, collection}, next) =>
                cs(
                    ["sharedTilesTab", ({}, next) => SharedTilesTab({next, savingQueue, panel})],
                    ["tilesTab", ({}, next) => TilesTab({next, savingQueue, panel})],
                    ["filtersTab", ({}, next) => FiltersTab({next, savingQueue, panel})],
                    ({sharedTilesTab, tilesTab, filtersTab}) =>
                        next([
                            {
                                id: "shared-tiles",
                                label: "Shared Tiles",
                                control: sharedTilesTab,
                            },
                            {
                                id: "tiles",
                                label: "Tiles",
                                control: tilesTab,
                            },
                            {
                                id: "filters",
                                label: "Filters",
                                control: filtersTab,
                                errorsNumber: collection.value.tileModelErrors?.filter((e) => e.location == "CollectionFilter").length ?? 0,
                            },
                        ])
                ),
        ],
        ({tabs, selectedTab, panel}) => {
            const tab1 = tabs.find((tab) => tab.id === selectedTab.value) || tabs[0];
            if (tabs.filter((t) => t.id == selectedTab.value).length == 0) selectedTab.value = tabs[0].id;

            return next({
                tabs: tabs.map((tab) => ({
                    ...tab,
                    render: tab.control?.renderLeftPanel,
                })),
                selectedTab,
                renderMain: () => panel.render(selectedTab.value),
                leftPanelOverride: tab1?.control?.leftPanelOverride,
            });
        }
    );
