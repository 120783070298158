import React from "react";
import {cs} from "@common/react/chain-services";
import {DialogService} from "../../../../common/dialog/dialog-service";
import {consumeContext} from "@common/react/context";
import {UseState} from "@common/react/use-state";
import {Form2} from "@common/react/cs-form/form2";
import {required} from "@common/react/cs-form/validators/required";
import {TextInput} from "../../../../../../../common/form/text-input/text-input";
import {ffToBasicInput} from "../../../../../../../common/form/ff-to-basic-input";
import "./create-new-env-dialog.scss";
import {ffToShowHideLine} from "../../../../../../../common/form/ff-to-show-hide-line";
import {YesNoToggle} from "../../../../../../../common/form/toggles/yes-no-toggle";
import {ConfirmDialog} from "../../../../common/confirm-dialog/confirm-dialog";
import {Button} from "../../../../../../../common/form/buttons/button/button";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";

export const CreateNewEnvDialog = ({next: rootNext}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    render: ({resolve, args: {environments}}) => ({
                        title: "Create Environment",
                        width: 480,
                        content: next({
                            resolve,
                            environments,
                        }),
                        initShow: true,
                        className: "create-env-dialog-wrapper-a22",
                    }),
                    next: rootNext,
                }),
        ],

        ({modal}) =>
            cs(
                consumeContext("apis"),
                consumeContext("tenant"),
                [
                    "params",
                    ({routing, auth}, next) =>
                        UseState({
                            next,
                            initValue: {
                                isProd: false,
                            },
                        }),
                ],
                ["saving", (_, next) => UseState({next})],
                [
                    "confirmDialog",
                    (_, next) =>
                        ConfirmDialog({
                            next,
                            title: "Change Production Environment?",
                            body: `There can only be one production environment. Updating this environment will change the ${
                                modal.environments?.find((e) => e.isProd)?.name
                            } so that it's no longer your organization's production environment.`,
                        }),
                ],
                ["error", (_, next) => UseState({next})],
                [
                    "form",
                    ({params, saving, apis, auth, error, confirmDialog, tenant}, next) =>
                        Form2({
                            fields: {
                                name: {
                                    transforms: ["trim"],
                                    validators: [required],
                                    asyncValidators: [
                                        {
                                            validate: (name, {data}) =>
                                                apis.tenant.checkEnvironmentName(name, {
                                                    envId: data?.id,
                                                }),
                                            getMessage: () => `Existed`,
                                        },
                                    ],
                                    debounce: true,
                                },
                            },
                            data: params,
                            onSubmit: async (env) => {
                                saving.onChange(true);
                                const hasProd = modal.environments?.find((e) => e.isProd);
                                if (hasProd && env.isProd) {
                                    const confirm = await confirmDialog.show();
                                    if (confirm) {
                                        const updated = await apis.tenant.upsertEnvironment({
                                            ...hasProd,
                                            isProd: false,
                                        });
                                        tenant.updateEnvironment(updated);
                                        const envCreated = await apis.tenant.upsertEnvironment(env);
                                        tenant.createNewEnvironment(envCreated);
                                        modal.resolve(envCreated.id);
                                    } else {
                                        saving.onChange(false);
                                    }
                                } else {
                                    const envCreated = await apis.tenant.upsertEnvironment(env);
                                    tenant.createNewEnvironment(envCreated);
                                    modal.resolve(envCreated.id);
                                }
                            },
                            next,
                        }),
                ],
                ({form, params, saving, error}) => {
                    return (
                        <div className="create-member-dialog-z33">
                            <VerbDialogBodyScrollbar>
                                <div className="error-message">{error.value}</div>

                                <div className="form-group">
                                    <div className="control-label">Environment Name</div>

                                    <div className="content">
                                        {TextInput({
                                            label: "Environment Name",
                                            ...ffToBasicInput(form.field("name")),
                                        })}
                                    </div>
                                </div>

                                <div className="form-group has-margin">
                                    <div className="control-label">Environment Type</div>

                                    <div className="content toggle-content">
                                        <div className="toggle">
                                            {YesNoToggle({
                                                ...ffToShowHideLine(form.field(["isProd"])),
                                            })}
                                        </div>

                                        <div className="name">Set this as your organization's production environment</div>
                                    </div>
                                </div>
                            </VerbDialogBodyScrollbar>

                            <div className="buttons">
                                <Button
                                    btnType="secondary"
                                    onClick={() => {
                                        modal.resolve();
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button disabled={saving.value} onClick={() => form.submit(params.value)}>
                                    Create
                                </Button>
                            </div>
                        </div>
                    );
                }
            )
    );
