const hideOverflowTickLabel = (chart) => {
    if (chart.xAxis[0].scrollbar) {
        return;
    }

    const leftMostTickLabel = chart.xAxis[0].labelGroup?.element?.childNodes?.[0];
    if (!leftMostTickLabel) {
        return;
    }

    const labelX = leftMostTickLabel.getBoundingClientRect().x;
    const chartX = chart.chartBackground.element.getBoundingClientRect().x;
    const padding = 3;

    if (labelX + padding <= chartX) {
        leftMostTickLabel.setAttribute("display", "none");
        chart.xAxis[0].ticks[chart.xAxis[0].tickPositions[0]]?.mark?.hide?.();
    }
};
exports.hideOverflowTickLabel = hideOverflowTickLabel;
