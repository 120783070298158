import "./field-section.scss";

import React from "react";
import {cx} from "emotion";
import {cs} from "@common/react/chain-services";

export const FieldSection = ({header, content, className}) =>
    cs(() => (
        <div className={cx("field-section-8ak", className)}>
            <div className="header">{header}</div>
            <div className="content">{content}</div>
        </div>
    ));
