const {debounce} = require("../utils/debounce");
const {Component, createElement: h} = require("react");

const UseState = ({next, getInitValue, initValue, debounce = 0}) => h(UseState1, {next, getInitValue, initValue, debounce});

exports.UseState = UseState;
exports.UseState1 = UseState;

class UseState1 extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            value: props.getInitValue ? props.getInitValue() : props.initValue,
        };

        const _change = (reduceValue, cb) => {
            return new Promise((resolve, reject) => {
                this.setState(
                    (state) => ({
                        ...state,
                        value: reduceValue(state.value),
                    }),
                    () => {
                        cb?.(this.state);

                        resolve();
                    }
                );
            });
        };

        this.change = props.debounce > 0 ? debounce(_change, props.debounce) : _change;

        const _onChange = (value1, cb) => {
            this.setState({value: value1}, () => cb?.(this.state));
        };

        this.onChange = props.debounce > 0 ? debounce(_onChange, props.debounce) : _onChange;
    }
    componentWillUnmount() {
        this.unmounted = Date.now();
    }

    setState(state, callback) {
        if (!this.unmounted || Date.now() - this.unmounted > 3000) {
            super.setState(state, callback);
            return;
        }
    }

    getState = () => this.state.value;

    render() {
        const {next, debounce} = this.props;
        const {value} = this.state;

        return (
            next({
                value,
                get: () => this.getState(),
                onChange: this.onChange,
                change: this.change,
                isDebounce: !!debounce,
            }) ?? null
        );
    }
}
