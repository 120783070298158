const {cascadeChange} = require("../../../../common/utils/cascade");
const {fieldAttrTypes, getFieldAttrType} = require("./tile-field-attrs");

const changeTileFields = (attr, tile, change) => {
    const changeSingleField = (field) => field && change(field);

    const changeMultiFields = (fields) => fields?.map((f) => change(f));

    const changeFieldGroups = (fieldGroups) => cascadeChange(fieldGroups, "[*].measureFields[*]", (f) => change(f));

    const changersMap = {
        [fieldAttrTypes.SingleField]: changeSingleField,
        [fieldAttrTypes.MultiField]: changeMultiFields,
        [fieldAttrTypes.FieldGroup]: changeFieldGroups,
    };

    const changer = changersMap[getFieldAttrType(attr, tile.$type)];

    return changer(tile[attr]);
};
exports.changeTileFields = changeTileFields;
