import collection from "./viz-fonts-collection.json";
import data from "./viz-fonts-data.json";
import {LiveDashboard} from "@common/ui-components/live/live-dashboard/live-dashboard";

export const VizFontsThemeAuto = (props) => {
    return LiveDashboard({
        collection,
        defaultData: data,
        disabledTileActions: true,
        ...props,
    });
};
