const {chain} = require("../../../utils/fs");
const {findMaxE} = require("../../../utils/collections");
const {unique} = require("../../../utils/collections");

/**
 expand data array to fit the date range.
 this is for preventing primary bars and comparison bars from not aligning,
 and for calculating correctly the max value on measure axis when setting grid step on measure axis
 **/
const syncDatetimeSeriesLength = ({series, tile, isCompare}) => {
    if (!series || series.length === 0) {
        return series;
    }

    const dataLengths = unique(series.map((s) => s.data?.length).filter((v) => v));

    // if all the series' data have the same length the return the series
    if (dataLengths.length === 1) {
        return series;
    }

    const maxDataLength = findMaxE(dataLengths);

    const filler = getGapFiller(tile);

    const fillSeriesData = (s, commonTimeStart, commonTimeEnd) => {
        const timeStart = commonTimeStart || new Date(s.range?.dateStart).getTime();
        const timeEnd = commonTimeEnd || new Date(s.range?.dateEnd).getTime();
        const difference = s.data[1][0] - s.data[0][0];
        const threshold = maxDataLength - s.data.length;
        let newData = [...s.data];

        // need threshold to stop the loop, b/c the range is not correct sometimes
        for (let i = 0; i < threshold; i++) {
            if (newData.length >= maxDataLength) {
                break;
            }
            // add one dummy date at each turn
            if (newData[0]?.[0] > timeStart) {
                newData = [
                    [
                        Math.max(newData[0][0] - difference, timeStart), // make sure the filled date should not be out of range, or Q1 2003 will be Q4 2002
                        filler,
                    ],
                    ...newData,
                ];
            } else if (newData[newData.length - 1]?.[0] < timeEnd) {
                newData = [
                    ...newData,
                    [
                        Math.min(newData[newData.length - 1][0] + difference, timeEnd), // make sure the filled date should not be out of range
                        filler,
                    ],
                ];
            }
        }

        // if data is still not enough (due to incorrect range), continue to fill
        if (newData.length < maxDataLength) {
            const placesLeft = maxDataLength - newData.length;
            for (let i = 0; i < placesLeft; i++) {
                newData = [...newData, [newData[newData.length - 1][0] + difference, filler]];
            }
        }

        return {...s, data: newData};
    };

    // used to normalize dates between series.
    // b/c the filled timestamp sometimes do not match the right timestamp---which happens when date aggregation is either month, quarter, year
    // in those cases, the differences between two consecutive timestamps are not always the same (some months have 30 days, some have 31 days, Feb has 28 or 29)
    const normalizeFilledDates = (filledSeries, isCompare, allFilledSeries) => {
        // original allSeries may not contain a series having max data length. if so use the allFilledSeries instead.
        // const longestSeries = series.find((s) => (isCompare ? s.isCompare : !s.isCompare) && s.data.length === maxDataLength);
        const longestSeries = (() => {
            const findSeries = (sArr) => sArr.find((s) => (isCompare ? s.isCompare : !s.isCompare) && s.data.length === maxDataLength);
            return findSeries(series) || findSeries(allFilledSeries);
        })();

        const normalizedDates = longestSeries.data.map((d) => d[0]);

        return {
            ...filledSeries,
            data: filledSeries.data.map((d, i) => (d[0] === normalizedDates[i] ? d : [normalizedDates[i], d[1]])),
        };
    };

    const {commonTimeStart, commonTimeEnd} = isCompare
        ? {}
        : (() => {
              const longestSeries = series.find((s) => s.data.length === maxDataLength);
              return {
                  commonTimeStart: longestSeries.data[0][0],
                  commonTimeEnd: longestSeries.data[maxDataLength - 1][0],
              };
          })();

    // return series.map((s) => (s.data.length === maxDataLength || s.data.length < 2)
    //     ? s
    //     : chain(
    //         fillSeriesData(s, commonTimeStart, commonTimeEnd),
    //         (filledSeries) => ["Months", "Quarters", "Years"].includes((tile.xAxisField || tile.yAxisField).dateProperties.aggregation)
    //             ? normalizeFilledDates(filledSeries, s.isCompare)
    //             : filledSeries,
    //     )
    // );

    return chain(
        series,
        // fill missing dates of a series data
        (series) =>
            series.map((s) => {
                if (s.data.length === maxDataLength || s.data.length < 2) {
                    return s;
                }
                return fillSeriesData(s, commonTimeStart, commonTimeEnd);
            }),
        // normalize the filled dates if needed
        (filledSeries) => {
            if (["Months", "Quarters", "Years"].includes((tile.xAxisField || tile.yAxisField).dateProperties.aggregation)) {
                return filledSeries.map((filledS) => normalizeFilledDates(filledS, filledS.isCompare, filledSeries));
            }
            return filledSeries;
        }
    );
};
exports.syncDatetimeSeriesLength = syncDatetimeSeriesLength;

const getGapFiller = (tile) => {
    const dateGaps = tile.style.xAxis.dateGaps || tile.style.yAxis.dateGaps;

    const mustUseZeroFiller = () => {
        const conditions = [
            {
                types: ["VerticalBarChartTile", "HorizontalBarChartTile"],
                displayTypes: ["Stacked", "HundredPercent"],
            },
            {
                types: ["LineChartTile"],
                displayTypes: ["SplineAreaStacked", "SplineAreaHundredPercent"],
            },
        ];

        for (let c of conditions) {
            if (c.types.includes(tile.$type) && c.displayTypes.includes(tile.style.displayType)) {
                return true;
            }
        }

        return false;
    };

    return mustUseZeroFiller() ? 0 : dateGaps === "FillWithZero" ? 0 : null;
};
