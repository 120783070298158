import {cs} from "@common/react/chain-services";
import {DialogService} from "../../../../common/dialog/dialog-service";
import "./add-existing-key-dialog.scss";
import SearchIcon from "../../../../../assets/icons/common/SearchIcon";
import {CloseIcon} from "../../../../common/icons/close-icon";
import {UseState} from "@common/react/use-state";
import {SearchInput2} from "../../../../../../../common/form/search-input/search-input-2";
import {sort} from "@common/utils/collections";
import {Button} from "../../../../../../../common/form/buttons/button/button";
import {DeleteIcon} from "../../../../common/icons/trash-icon";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";
import React from "react";
import {CheckIcon} from "../../../../collection/edit/layout/theme-select/theme-select-icons";
import {cx} from "emotion";
import {consumeContext} from "@common/react/context";
import {isMatchText} from "@common/utils/strings";

export const AddExistingKeyDialog = ({next: rootNext}) =>
    cs(
        [
            "modal",
            ({collection}, next) =>
                DialogService({
                    render: ({resolve, args: {accessKeys, preSelectCollection}}) => ({
                        title: "Add Existing key",
                        width: 560,
                        content: next({
                            resolve,
                            accessKeys,
                            preSelectCollection,
                        }),
                    }),
                    next: rootNext,
                }),
        ],
        ({modal}) =>
            cs(consumeContext("apis"), ["keyword", (_, next) => UseState({next, initValue: ""})], ["selected", (_, next) => UseState({next})], ({keyword, selected, apis}) => {
                return (
                    <div className="add-existing-key-dialog-a33">
                        <SearchInput2 state={keyword} />

                        <VerbScrollbar maxHeight="250px" className="access-list">
                            {sort(
                                modal.accessKeys.filter((key) => isMatchText(key.name, keyword.value)),
                                (l) => l.name
                            ).map((item, index) => (
                                <div
                                    className={cx("access-key", selected.value?.id == item.id && "selected")}
                                    key={index}
                                    onClick={() => {
                                        selected.onChange(item);
                                    }}
                                >
                                    {item.name}

                                    {selected.value?.id == item.id && (
                                        <div className="check-icon">
                                            <CheckIcon />
                                        </div>
                                    )}
                                </div>
                            ))}
                        </VerbScrollbar>

                        <div className="buttons">
                            <Button
                                disabled={!selected.value}
                                onClick={async () => {
                                    const updated = {
                                        ...selected.value,
                                        apiCollections: selected.value.apiCollections.concat(modal.preSelectCollection || []),
                                    };
                                    await apis.accessKey.upsertAccessApiKey(updated);
                                    modal.resolve(updated);
                                }}
                            >
                                Next
                            </Button>
                        </div>
                    </div>
                );
            })
    );
