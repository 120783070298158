const {eachMatch} = require("../regex-util");

function plain(str) {
    return {
        format: () => str,
        consume: (text) => {
            if (text.startsWith(str)) {
                return {length: str.length};
            }
            return null;
        },
    };
}

let regExp = new RegExp("^([^/]+)");
function keyed(name) {
    return {
        format: (params) => {
            let value = params[name];
            if (value == null) {
                throw `Required key ${name}`;
            }
            return value;
        },
        consume: (text) => {
            let match = regExp.exec(text);
            if (match == null) {
                return null;
            }

            return {length: match[0].length, params: {[name]: match[1]}};
        },
    };
}

const createUrlFormat = (format) => {
    let index = 0;
    let tokens = [];

    eachMatch(":(\\w+)", format, (match) => {
        if (match.index > index) {
            tokens.push(plain(format.substring(index, match.index)));
        }

        tokens.push(keyed(match[1]));

        index = match.index + match[0].length;
    });
    if (index < format.length) {
        tokens.push(plain(format.substring(index)));
    }

    return {
        format: (params) => {
            return tokens.map((t) => t.format(params)).join("");
        },
        match: (url) => {
            let params = {};
            for (const t of tokens) {
                let consume = t.consume(url);
                if (consume == null) {
                    return null;
                }
                if (consume.params) {
                    Object.assign(params, consume.params);
                }
                url = url.substring(consume.length);
            }

            if (url.length) {
                return null;
            }

            return params;
        },
    };
};
exports.createUrlFormat = createUrlFormat;
