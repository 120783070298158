import {tooltipLayout} from "./tooltip-layout";
import {rGaugeTable} from "./gauge-table";
import {rGaugeTableCompare} from "./gauge-table-compare";

export const renderGaugeChartTooltip = ({tile, theme, rawData, formatters, isCompare}) => {
    return tooltipLayout({
        content: (isCompare ? rGaugeTableCompare : rGaugeTable)({
            tile,
            rawData,
            formatters,
            theme,
        }),
        theme,
    });
};
