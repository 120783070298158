import "./control-filters-badge.scss";

import {cs} from "../../../../react/chain-services";
import {consumeContext} from "../../../../react/context";

import {CloseIcon} from "../../../../../web-client/src/routes/common/icons/close-icon";
import {InfoIconTooltip} from "../../../charts/common/info-icon-tooltip/info-icon-tooltip";
import {generateDateRangeMatchWithAggregation} from "../../../../logic/date-time/date-util";
import {formatDateLong} from "../../../formats/format-date-long";

export const ControlFiltersBadge = ({collection}) =>
    cs(consumeContext("tileActionControlFilter"), consumeContext("theme"), ({tileActionControlFilter, theme}) => {
        const {action} = tileActionControlFilter?.value || {};

        if (!action?.value || !action?.filters || action?.filters?.length == 0) {
            return null;
        }

        const componentTheme = theme.general.components;

        return (
            <div
                className="control-filters-badge-a44"
                style={{
                    marginTop: collection.filterStyle.display == "SDK" || collection.filters.filter((f) => f.displayArea == "Visible").length == 0 ? 0 : -20,
                }}
            >
                {action.filters
                    .filter((filter) => collection.filterStyle.display == "SDK" || filter.displayArea == "Hidden")
                    .map((filter) => {
                        let value = tileActionControlFilter?.value?.action?.value;

                        if (filter.$type == "DateFilter") {
                            const {from, to} = generateDateRangeMatchWithAggregation(new Date(action.value), action.field.dateProperties.aggregation);
                            value = `${formatDateLong(from)} - ${formatDateLong(to)}`;
                        }

                        return (
                            <div
                                className="select-chip"
                                key={filter.id}
                                style={{
                                    background: theme.general.buttons.buttonToggleGroup.activeBackgroundColorRGB,
                                    borderRadius: theme.general.buttons.buttonToggleGroup.cornerRadius,
                                    color: theme.general.buttons.buttonToggleGroup.activeFontColorRGB,
                                    fontFamily: `"${theme.general.canvas.fontFamily}", sans-serif`,
                                }}
                            >
                                {InfoIconTooltip({
                                    theme,
                                    width: 8,
                                    color: componentTheme.inputIconColorRGB,
                                    showInfoIcon: () => filter.showLabelInfoIcon && filter?.labelInfoIconText,
                                    infoText: filter?.labelInfoIconText,
                                })}
                                <span>{filter.label}</span>: {value}
                                <div className="close-icon">
                                    <CloseIcon
                                        onClick={() => {
                                            tileActionControlFilter.onChange({
                                                tile: tileActionControlFilter?.value?.hasPrevTileAction ? null : tileActionControlFilter?.value?.tile,
                                                action: {
                                                    ...tileActionControlFilter?.value?.action,
                                                    value: null,
                                                },
                                            });
                                        }}
                                        fill={componentTheme.inputIconColorRGB}
                                    />
                                </div>
                            </div>
                        );
                    })}
            </div>
        );
    });
