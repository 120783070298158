import {getDataLabelsStyle} from "@common/ui-components/charts/factory/data-labels";
import {
    cDataFilter,
    getReferenceSeriesStyleOptions, regressionCalculators,
    regressionTypes
} from "@common/ui-components/charts/reference-lines/indicator-series";
import {camelCaseToSpaces} from "@common/utils/strings";
import {chain} from "@common/utils/fs";
import {sort} from "@common/utils/collections";

export const generateIndicatorSeries = ({tile, theme, formatters, config, dataPoints}) => {

    // only regression is allowed on scatter/bubble chart
    if (!regressionTypes.includes(config.indicatorType)) {
        return;
    }

    const getOptions = ({config, dataLabelFormatter, name}) => {
        const seriesStyle = getReferenceSeriesStyleOptions({config, theme});
        const dataLabelStyle = getDataLabelsStyle({
            dataLabelsConfig: {
                ...tile.style.dataLabels,
                show: config.dataLabelShown,
            },
            theme
        });

        return {
            // showInLegend: true,
            marker: {
                enabled: false,
                states: {
                    hover: {
                        enabled: false,
                    }
                },
            },
            name: config.label || name,
            dataLabels: {
                ...dataLabelStyle,
                formatter: function () {
                    return dataLabelFormatter(this.y);
                }
            },

            ...(config.labelShown ? {
                label: {
                    enabled: true,
                    style: {
                        fontSize: dataLabelStyle.style.fontSize,
                        fontWeight: dataLabelStyle.style.fontWeight,
                        fontFamily: dataLabelStyle.style.fontFamily,
                        color: seriesStyle.color,
                    },
                }
            } : {
                label: {enabled: false}
            }),

            states: {
                hover: {
                    lineWidthPlus: 0,
                }
            },

            ...seriesStyle,
        }
    }

    const dataLabelFormatter = formatters.measurementFormatters[tile.yAxisField.id];
    const dataFilter = cDataFilter(config.indicatorType);

    const data = chain(
        dataPoints,
        (_) => _.map((d) => [d.x, d.y]),
        (_) => dataFilter ? _.filter(dataFilter) : _,
        (_) => sort(_, (d) => d[0]), // sort in ascending order
    );

    const regressionResult = regressionCalculators[config.indicatorType](
        data,
        config.order || 2,
    );

    return [{
        type: "spline",
        id: config.id,
        customType: config.$type,
        customStack: tile.yAxisField.id,
        // measureAxisTitle: tile.yAxisField.displayName,
        data: regressionResult.points.map((d) => ({x: d[0], y: d[1]})), // scatter/bubble chart should use {x, y} point form
        ...getOptions({
            config,
            dataLabelFormatter,
            name: `${camelCaseToSpaces(config.indicatorType)}`
        }),
    }];
};
