import {getTooltipDimensions} from "./get-tooltip-dimensions";
import {findMaxE, findMinE} from "../../../../../utils/collections";
import {isMobile} from "./is-mobile";
import {getHighchartsTooltipPadding} from "./tooltip-positioner-normal";

export const tooltipPositionerHorizontal = ({chart, tile, theme, tooltipContent}) => {
    // sometimes the screen is scrolled and that lifts the tooltip position. use this to balance
    const topScroll = window.document.scrollingElement?.scrollTop || 0;

    const chartContainerRect = chart.container.getBoundingClientRect();

    // try inspect body > div.highcharts-tooltip-container to see this padding
    const padding = getHighchartsTooltipPadding(tile);
    const tooltipDims = getTooltipDimensions({
        htmlString: tooltipContent,
        padding,
    });

    let posX;
    let posY;
    let tempPosX;

    if (chart.boostClipRect || chart.isBoosting) {
        // boost mode
        tempPosX = chartContainerRect.x + chart.axisOffset[3] + (chart.boostClipRect || chart.clipRect).element.getBoundingClientRect().width / 2 - tooltipDims.width / 2;
    } else {
        const isStacking = tile.style.displayType && tile.style.displayType !== "Classic";
        if (!isStacking) {
            const largestBar = findMaxE(chart.hoverPoints, (p) => p.shapeArgs?.height);
            const largestBarRect = largestBar?.graphic?.element.getBoundingClientRect();
            if (largestBarRect) {
                tempPosX = largestBarRect.x + largestBarRect.width - tooltipDims.width / 2;
            }
        } else if (tile.style.displayType === "HundredPercent") {
            const plotBackgroundRect = chart.plotBackground.element.getBoundingClientRect();
            // AB#6187: for 100% stacking horizontal bar chart, place the tooltip in the middle
            tempPosX = plotBackgroundRect.x + plotBackgroundRect.width / 2 - tooltipDims.width / 2;
        } else {
            const rightestBar = findMinE(chart.hoverPoints, (p) => p.shapeArgs?.x);
            if (rightestBar) {
                const rightestBarRect = rightestBar.graphic?.element.getBoundingClientRect();
                if (rightestBarRect) {
                    tempPosX = rightestBarRect.x + rightestBarRect.width - tooltipDims.width / 2;
                }
            }
        }
    }

    const parentRect = chart.container.parentElement.getBoundingClientRect();

    //--- determine posX
    const isOnMobile = isMobile();
    const leftRightEdgeThreshold = isOnMobile ? 0 : 20;

    if (tempPosX + tooltipDims.width > window.innerWidth - leftRightEdgeThreshold) {
        // right edge
        // posX = parentRect.right - tooltipDims.width; // need padding? // use this if use the right border of tile as the limit
        posX = window.innerWidth - tooltipDims.width; // use this if use the right screen edge as the limit
    } else if (tempPosX < leftRightEdgeThreshold) {
        // left edge
        posX = isOnMobile ? 0 : chartContainerRect.x;
    } else {
        posX = tempPosX;
    }

    //---- determine if tooltip is placed above or below, posY
    const bottomEdgeThreshold = 30;
    if (chart.scrollingContainer) {
        const scrollingContainerRect = chart.scrollingContainer.getBoundingClientRect();
        const tempAbovePosY = scrollingContainerRect.y - tooltipDims.height + chart.axisOffset[0] + 2;
        const offsetParentRect = chart.container.offsetParent.getBoundingClientRect();
        const tempBelowPosY = offsetParentRect.y + offsetParentRect.height - padding - 12;

        if (theme.dataVisualization.toolTipsAndLegends.position === "Below") {
            // if tooltips fit the bottom then show it at the bottom, else show on top
            const isFitToBottom = tempBelowPosY + tooltipDims.height < window.innerHeight - bottomEdgeThreshold;
            posY = isFitToBottom ? tempBelowPosY : tempAbovePosY;
            chart.customTooltipPosition = isFitToBottom ? "Below" : "Above"; // this prop is used in tooltip-connector-horizontal-helper.js

            // instead of the above, keep staying on bottom since scrolling is applied. ref. tooltip-options.js
            // posY = tempBelowPosY;
            // chart.customTooltipPosition = "Below"; // this prop is used in tooltip-connector-helper.js
        } else {
            // if tooltips fit on top then show it at the top, else show at bottom
            // const isFitToTop = tooltipDims.height < offsetParentRect.y;
            // posY = isFitToTop ? Math.max(tempAbovePosY, 0) : tempBelowPosY;
            // chart.customTooltipPosition = isFitToTop ? "Above" : "Below";

            // instead of the above, keep staying on top since scrolling is applied. ref. tooltip-options.js
            posY = Math.max(tempAbovePosY, 0); // for tooltip to always stay below top edge of screen
            chart.customTooltipPosition = "Above";
        }
    } else {
        const plotBorderRect = chart.plotBorder.element.getBoundingClientRect();
        const tempAbovePosY = plotBorderRect.y - tooltipDims.height - 10 + 2;
        const tempBelowPosY = plotBorderRect.y + plotBorderRect.height;

        if (theme.dataVisualization.toolTipsAndLegends.position === "Below") {
            const isFitToBottom = tempBelowPosY + tooltipDims.height < window.innerHeight - bottomEdgeThreshold;
            posY = isFitToBottom ? tempBelowPosY : tempAbovePosY;
            chart.customTooltipPosition = isFitToBottom ? "Below" : "Above";
        } else {
            // const isFitToTop = tooltipDims.height < plotBorderRect.y;
            // posY = isFitToTop ? Math.max(tempAbovePosY, 0) : tempBelowPosY;
            // chart.customTooltipPosition = isFitToTop ? "Above" : "Below";

            // instead of the above, keep staying on top since scrolling is applied. ref. tooltip-options.js
            posY = Math.max(tempAbovePosY, 0); // for tooltip to always stay below top edge of screen
            chart.customTooltipPosition = "Above";
        }
    }

    return {
        x: posX,
        y: posY + topScroll,
    };
};
