import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {cx} from "emotion";
import "./fixed-tooltip.scss";

export const FixedTooltip = ({className, value, content, noTooltip}) =>
    cs(["showTooltip", (_, next) => UseState({next})], ({showTooltip}) => (
        <div
            className={cx("fixed-tooltip-88e", className)}
            {...(!noTooltip
                ? {
                      onMouseOver: () => showTooltip.onChange(true),
                      onMouseOut: () => showTooltip.onChange(false),
                  }
                : {})}
        >
            {content}
            {showTooltip.value && <div className="progress-tooltip bottom">{value}</div>}
        </div>
    ));
