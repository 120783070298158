import React from "react";
import "./sdk-error-when-load.scss";

export const SDKErrorWhenLoad = ({error, staticAuth}) => (
    <div className="sdk-error-when-load-a232">
        <div className="content">
            <div className="icon">
                <i className="material-icons">info_outline</i>
            </div>

            <div className="message">
                {error.statusCode == 401
                    ? !staticAuth
                        ? "Sorry, it looks like your account does not have access to this data. Error "
                        : "Static auth is not enabled for this dashboard. Error "
                    : error.message ?? "Sorry, the system permissions are not valid. Error "}
                {error.statusCode}
            </div>
        </div>
    </div>
);
