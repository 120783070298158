exports.pieChartTemplate = {
    $type: "PieChartTile",
    style: {
        $type: "PieChartTileStyle",
        dataLabels: {
            $type: "PieChartDataLabelsTileStyle",
            show: true,
            position: "InsideRight",
            fontSize: null,
            allowOverlap: false,
            categoryShown: true,
            metricShown: true,
            metrics: "Percent,Value",
        },
        autoRound: true,
        // dataTextCaseOptions: "None",
        sliceBorderShown: true,
        sliceBorderWidth: 1,
        calloutLineShown: false,
        calloutLineWidth: 1,
        calloutLineColor: "#EAEAEA",
        legendStyle: {
            legendShown: false,
            legendPosition: "Auto",
            legendTitleShown: false,
            legendTitle: "Legend",
        },
        title: {
            show: true,
            titleLocation: "Above",
        },
    },
};
