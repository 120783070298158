import * as React from "react";
import {cs} from "@common/react/chain-services";
import {cx} from "emotion";
import "./field-info-provider.scss";
import {StaticTooltipService} from "../../../../../../../../../common/tooltip3/static-tooltip-service";

export const FieldInfoProvider = ({field, className, info, tooltipBoxWidth, topOffset, direction = "right", noCollapsible}) =>
    cs(
        [
            "staticTooltip",
            (_, next) =>
                StaticTooltipService({
                    direction,
                    info,
                    topOffset,
                    tooltipBoxWidth,
                    next,
                }),
        ],
        ({staticTooltip}) => {
            return (
                <div
                    className={cx("field-info-provider-ry3 field-info-provider", className)}
                    style={{
                        paddingRight: !info ? 0 : "",
                        paddingLeft: noCollapsible ? 16 : "",
                    }}
                >
                    {field}
                    {info &&
                        staticTooltip.renderIcon({
                            icon: <i className="material-icons">info</i>,
                            className: "tooltip-icon",
                        })}
                </div>
            );
        }
    );
