export const FAIconList = [
    "fa fa-ad",
    "fa fa-address-book",
    "fa fa-address-card",
    "fa fa-adjust",
    "fa fa-air-freshener",
    "fa fa-align-center",
    "fa fa-align-justify",
    "fa fa-align-left",
    "fa fa-align-right",
    "fa fa-allergies",
    "fa fa-ambulance",
    "fa fa-american-sign-language-interpreting",
    "fa fa-anchor",
    "fa fa-angle-double-down",
    "fa fa-angle-double-left",
    "fa fa-angle-double-right",
    "fa fa-angle-double-up",
    "fa fa-angle-down",
    "fa fa-angle-left",
    "fa fa-angle-right",
    "fa fa-angle-up",
    "fa fa-angry",
    "fa fa-ankh",
    "fa fa-apple-alt",
    "fa fa-archive",
    "fa fa-archway",
    "fa fa-arrow-alt-circle-down",
    "fa fa-arrow-alt-circle-left",
    "fa fa-arrow-alt-circle-right",
    "fa fa-arrow-alt-circle-up",
    "fa fa-arrow-circle-down",
    "fa fa-arrow-circle-left",
    "fa fa-arrow-circle-right",
    "fa fa-arrow-circle-up",
    "fa fa-arrow-down",
    "fa fa-arrow-left",
    "fa fa-arrow-right",
    "fa fa-arrow-up",
    "fa fa-arrows-alt",
    "fa fa-arrows-alt-h",
    "fa fa-arrows-alt-v",
    "fa fa-assistive-listening-systems",
    "fa fa-asterisk",
    "fa fa-at",
    "fa fa-atlas",
    "fa fa-atom",
    "fa fa-audio-description",
    "fa fa-award",
    "fa fa-baby",
    "fa fa-baby-carriage",
    "fa fa-backspace",
    "fa fa-backward",
    "fa fa-bacon",
    "fa fa-bacteria",
    "fa fa-bacterium",
    "fa fa-bahai",
    "fa fa-balance-scale",
    "fa fa-balance-scale-left",
    "fa fa-balance-scale-right",
    "fa fa-ban",
    "fa fa-band-aid",
    "fa fa-barcode",
    "fa fa-bars",
    "fa fa-baseball-ball",
    "fa fa-basketball-ball",
    "fa fa-bath",
    "fa fa-battery-empty",
    "fa fa-battery-full",
    "fa fa-battery-half",
    "fa fa-battery-quarter",
    "fa fa-battery-three-quarters",
    "fa fa-bed",
    "fa fa-beer",
    "fa fa-bell",
    "fa fa-bell-slash",
    "fa fa-bezier-curve",
    "fa fa-bible",
    "fa fa-bicycle",
    "fa fa-biking",
    "fa fa-binoculars",
    "fa fa-biohazard",
    "fa fa-birthday-cake",
    "fa fa-blender",
    "fa fa-blender-phone",
    "fa fa-blind",
    "fa fa-blog",
    "fa fa-bold",
    "fa fa-bolt",
    "fa fa-bomb",
    "fa fa-bone",
    "fa fa-bong",
    "fa fa-book",
    "fa fa-book-dead",
    "fa fa-book-medical",
    "fa fa-book-open",
    "fa fa-book-reader",
    "fa fa-bookmark",
    "fa fa-border-all",
    "fa fa-border-none",
    "fa fa-border-style",
    "fa fa-bowling-ball",
    "fa fa-box",
    "fa fa-box-open",
    "fa fa-box-tissue",
    "fa fa-boxes",
    "fa fa-braille",
    "fa fa-brain",
    "fa fa-bread-slice",
    "fa fa-briefcase",
    "fa fa-briefcase-medical",
    "fa fa-broadcast-tower",
    "fa fa-broom",
    "fa fa-brush",
    "fa fa-bug",
    "fa fa-building",
    "fa fa-bullhorn",
    "fa fa-bullseye",
    "fa fa-burn",
    "fa fa-bus",
    "fa fa-bus-alt",
    "fa fa-business-time",
    "fa fa-calculator",
    "fa fa-calendar",
    "fa fa-calendar-alt",
    "fa fa-calendar-check",
    "fa fa-calendar-day",
    "fa fa-calendar-minus",
    "fa fa-calendar-plus",
    "fa fa-calendar-times",
    "fa fa-calendar-week",
    "fa fa-camera",
    "fa fa-camera-retro",
    "fa fa-campground",
    "fa fa-candy-cane",
    "fa fa-cannabis",
    "fa fa-capsules",
    "fa fa-car",
    "fa fa-car-alt",
    "fa fa-car-battery",
    "fa fa-car-crash",
    "fa fa-car-side",
    "fa fa-caravan",
    "fa fa-caret-down",
    "fa fa-caret-left",
    "fa fa-caret-right",
    "fa fa-caret-square-down",
    "fa fa-caret-square-left",
    "fa fa-caret-square-right",
    "fa fa-caret-square-up",
    "fa fa-caret-up",
    "fa fa-carrot",
    "fa fa-cart-arrow-down",
    "fa fa-cart-plus",
    "fa fa-cash-register",
    "fa fa-cat",
    "fa fa-certificate",
    "fa fa-chair",
    "fa fa-chalkboard",
    "fa fa-chalkboard-teacher",
    "fa fa-charging-station",
    "fa fa-chart-area",
    "fa fa-chart-bar",
    "fa fa-chart-line",
    "fa fa-chart-pie",
    "fa fa-check",
    "fa fa-check-circle",
    "fa fa-check-double",
    "fa fa-check-square",
    "fa fa-cheese",
    "fa fa-chess",
    "fa fa-chess-bishop",
    "fa fa-chess-board",
    "fa fa-chess-king",
    "fa fa-chess-knight",
    "fa fa-chess-pawn",
    "fa fa-chess-queen",
    "fa fa-chess-rook",
    "fa fa-chevron-circle-down",
    "fa fa-chevron-circle-left",
    "fa fa-chevron-circle-right",
    "fa fa-chevron-circle-up",
    "fa fa-chevron-down",
    "fa fa-chevron-left",
    "fa fa-chevron-right",
    "fa fa-chevron-up",
    "fa fa-child",
    "fa fa-church",
    "fa fa-circle",
    "fa fa-circle-notch",
    "fa fa-city",
    "fa fa-clinic-medical",
    "fa fa-clipboard",
    "fa fa-clipboard-check",
    "fa fa-clipboard-list",
    "fa fa-clock",
    "fa fa-clone",
    "fa fa-closed-captioning",
    "fa fa-cloud",
    "fa fa-cloud-download-alt",
    "fa fa-cloud-meatball",
    "fa fa-cloud-moon",
    "fa fa-cloud-moon-rain",
    "fa fa-cloud-rain",
    "fa fa-cloud-showers-heavy",
    "fa fa-cloud-sun",
    "fa fa-cloud-sun-rain",
    "fa fa-cloud-upload-alt",
    "fa fa-cocktail",
    "fa fa-code",
    "fa fa-code-branch",
    "fa fa-coffee",
    "fa fa-cog",
    "fa fa-cogs",
    "fa fa-coins",
    "fa fa-columns",
    "fa fa-comment",
    "fa fa-comment-alt",
    "fa fa-comment-dollar",
    "fa fa-comment-dots",
    "fa fa-comment-medical",
    "fa fa-comment-slash",
    "fa fa-comments",
    "fa fa-comments-dollar",
    "fa fa-compact-disc",
    "fa fa-compass",
    "fa fa-compress",
    "fa fa-compress-alt",
    "fa fa-compress-arrows-alt",
    "fa fa-concierge-bell",
    "fa fa-cookie",
    "fa fa-cookie-bite",
    "fa fa-copy",
    "fa fa-copyright",
    "fa fa-couch",
    "fa fa-credit-card",
    "fa fa-crop",
    "fa fa-crop-alt",
    "fa fa-cross",
    "fa fa-crosshairs",
    "fa fa-crow",
    "fa fa-crown",
    "fa fa-crutch",
    "fa fa-cube",
    "fa fa-cubes",
    "fa fa-cut",
    "fa fa-database",
    "fa fa-deaf",
    "fa fa-democrat",
    "fa fa-desktop",
    "fa fa-dharmachakra",
    "fa fa-diagnoses",
    "fa fa-dice",
    "fa fa-dice-d20",
    "fa fa-dice-d6",
    "fa fa-dice-five",
    "fa fa-dice-four",
    "fa fa-dice-one",
    "fa fa-dice-six",
    "fa fa-dice-three",
    "fa fa-dice-two",
    "fa fa-digital-tachograph",
    "fa fa-directions",
    "fa fa-disease",
    "fa fa-divide",
    "fa fa-dizzy",
    "fa fa-dna",
    "fa fa-dog",
    "fa fa-dollar-sign",
    "fa fa-dolly",
    "fa fa-dolly-flatbed",
    "fa fa-donate",
    "fa fa-door-closed",
    "fa fa-door-open",
    "fa fa-dot-circle",
    "fa fa-dove",
    "fa fa-download",
    "fa fa-drafting-compass",
    "fa fa-dragon",
    "fa fa-draw-polygon",
    "fa fa-drum",
    "fa fa-drum-steelpan",
    "fa fa-drumstick-bite",
    "fa fa-dumbbell",
    "fa fa-dumpster",
    "fa fa-dumpster-fire",
    "fa fa-dungeon",
    "fa fa-edit",
    "fa fa-egg",
    "fa fa-eject",
    "fa fa-ellipsis-h",
    "fa fa-ellipsis-v",
    "fa fa-envelope",
    "fa fa-envelope-open",
    "fa fa-envelope-open-text",
    "fa fa-envelope-square",
    "fa fa-equals",
    "fa fa-eraser",
    "fa fa-ethernet",
    "fa fa-euro-sign",
    "fa fa-exchange-alt",
    "fa fa-exclamation",
    "fa fa-exclamation-circle",
    "fa fa-exclamation-triangle",
    "fa fa-expand",
    "fa fa-expand-alt",
    "fa fa-expand-arrows-alt",
    "fa fa-external-link-alt",
    "fa fa-external-link-square-alt",
    "fa fa-eye",
    "fa fa-eye-dropper",
    "fa fa-eye-slash",
    "fa fa-fan",
    "fa fa-fast-backward",
    "fa fa-fast-forward",
    "fa fa-faucet",
    "fa fa-fax",
    "fa fa-feather",
    "fa fa-feather-alt",
    "fa fa-female",
    "fa fa-fighter-jet",
    "fa fa-file",
    "fa fa-file-alt",
    "fa fa-file-archive",
    "fa fa-file-audio",
    "fa fa-file-code",
    "fa fa-file-contract",
    "fa fa-file-csv",
    "fa fa-file-download",
    "fa fa-file-excel",
    "fa fa-file-export",
    "fa fa-file-image",
    "fa fa-file-import",
    "fa fa-file-invoice",
    "fa fa-file-invoice-dollar",
    "fa fa-file-medical",
    "fa fa-file-medical-alt",
    "fa fa-file-pdf",
    "fa fa-file-powerpoint",
    "fa fa-file-prescription",
    "fa fa-file-signature",
    "fa fa-file-upload",
    "fa fa-file-video",
    "fa fa-file-word",
    "fa fa-fill",
    "fa fa-fill-drip",
    "fa fa-film",
    "fa fa-filter",
    "fa fa-fingerprint",
    "fa fa-fire",
    "fa fa-fire-alt",
    "fa fa-fire-extinguisher",
    "fa fa-first-aid",
    "fa fa-fish",
    "fa fa-fist-raised",
    "fa fa-flag",
    "fa fa-flag-checkered",
    "fa fa-flag-usa",
    "fa fa-flask",
    "fa fa-flushed",
    "fa fa-folder",
    "fa fa-folder-minus",
    "fa fa-folder-open",
    "fa fa-folder-plus",
    "fa fa-font",
    "fa fa-football-ball",
    "fa fa-forward",
    "fa fa-frog",
    "fa fa-frown",
    "fa fa-frown-open",
    "fa fa-funnel-dollar",
    "fa fa-futbol",
    "fa fa-gamepad",
    "fa fa-gas-pump",
    "fa fa-gavel",
    "fa fa-gem",
    "fa fa-genderless",
    "fa fa-ghost",
    "fa fa-gift",
    "fa fa-gifts",
    "fa fa-glass-cheers",
    "fa fa-glass-martini",
    "fa fa-glass-martini-alt",
    "fa fa-glass-whiskey",
    "fa fa-glasses",
    "fa fa-globe",
    "fa fa-globe-africa",
    "fa fa-globe-americas",
    "fa fa-globe-asia",
    "fa fa-globe-europe",
    "fa fa-golf-ball",
    "fa fa-gopuram",
    "fa fa-graduation-cap",
    "fa fa-greater-than",
    "fa fa-greater-than-equal",
    "fa fa-grimace",
    "fa fa-grin",
    "fa fa-grin-alt",
    "fa fa-grin-beam",
    "fa fa-grin-beam-sweat",
    "fa fa-grin-hearts",
    "fa fa-grin-squint",
    "fa fa-grin-squint-tears",
    "fa fa-grin-stars",
    "fa fa-grin-tears",
    "fa fa-grin-tongue",
    "fa fa-grin-tongue-squint",
    "fa fa-grin-tongue-wink",
    "fa fa-grin-wink",
    "fa fa-grip-horizontal",
    "fa fa-grip-lines",
    "fa fa-grip-lines-vertical",
    "fa fa-grip-vertical",
    "fa fa-guitar",
    "fa fa-h-square",
    "fa fa-hamburger",
    "fa fa-hammer",
    "fa fa-hamsa",
    "fa fa-hand-holding",
    "fa fa-hand-holding-heart",
    "fa fa-hand-holding-medical",
    "fa fa-hand-holding-usd",
    "fa fa-hand-holding-water",
    "fa fa-hand-lizard",
    "fa fa-hand-middle-finger",
    "fa fa-hand-paper",
    "fa fa-hand-peace",
    "fa fa-hand-point-down",
    "fa fa-hand-point-left",
    "fa fa-hand-point-right",
    "fa fa-hand-point-up",
    "fa fa-hand-pointer",
    "fa fa-hand-rock",
    "fa fa-hand-scissors",
    "fa fa-hand-sparkles",
    "fa fa-hand-spock",
    "fa fa-hands",
    "fa fa-hands-helping",
    "fa fa-hands-wash",
    "fa fa-handshake",
    "fa fa-handshake-alt-slash",
    "fa fa-handshake-slash",
    "fa fa-hanukiah",
    "fa fa-hard-hat",
    "fa fa-hashtag",
    "fa fa-hat-cowboy",
    "fa fa-hat-cowboy-side",
    "fa fa-hat-wizard",
    "fa fa-hdd",
    "fa fa-head-side-cough",
    "fa fa-head-side-cough-slash",
    "fa fa-head-side-mask",
    "fa fa-head-side-virus",
    "fa fa-heading",
    "fa fa-headphones",
    "fa fa-headphones-alt",
    "fa fa-headset",
    "fa fa-heart",
    "fa fa-heart-broken",
    "fa fa-heartbeat",
    "fa fa-helicopter",
    "fa fa-highlighter",
    "fa fa-hiking",
    "fa fa-hippo",
    "fa fa-history",
    "fa fa-hockey-puck",
    "fa fa-holly-berry",
    "fa fa-home",
    "fa fa-horse",
    "fa fa-horse-head",
    "fa fa-hospital",
    "fa fa-hospital-alt",
    "fa fa-hospital-symbol",
    "fa fa-hospital-user",
    "fa fa-hot-tub",
    "fa fa-hotdog",
    "fa fa-hotel",
    "fa fa-hourglass",
    "fa fa-hourglass-end",
    "fa fa-hourglass-half",
    "fa fa-hourglass-start",
    "fa fa-house-damage",
    "fa fa-house-user",
    "fa fa-hryvnia",
    "fa fa-i-cursor",
    "fa fa-ice-cream",
    "fa fa-icicles",
    "fa fa-icons",
    "fa fa-id-badge",
    "fa fa-id-card",
    "fa fa-id-card-alt",
    "fa fa-igloo",
    "fa fa-image",
    "fa fa-images",
    "fa fa-inbox",
    "fa fa-indent",
    "fa fa-industry",
    "fa fa-infinity",
    "fa fa-info",
    "fa fa-info-circle",
    "fa fa-italic",
    "fa fa-jedi",
    "fa fa-joint",
    "fa fa-journal-whills",
    "fa fa-kaaba",
    "fa fa-key",
    "fa fa-keyboard",
    "fa fa-khanda",
    "fa fa-kiss",
    "fa fa-kiss-beam",
    "fa fa-kiss-wink-heart",
    "fa fa-kiwi-bird",
    "fa fa-landmark",
    "fa fa-language",
    "fa fa-laptop",
    "fa fa-laptop-code",
    "fa fa-laptop-house",
    "fa fa-laptop-medical",
    "fa fa-laugh",
    "fa fa-laugh-beam",
    "fa fa-laugh-squint",
    "fa fa-laugh-wink",
    "fa fa-layer-group",
    "fa fa-leaf",
    "fa fa-lemon",
    "fa fa-less-than",
    "fa fa-less-than-equal",
    "fa fa-level-down-alt",
    "fa fa-level-up-alt",
    "fa fa-life-ring",
    "fa fa-lightbulb",
    "fa fa-link",
    "fa fa-lira-sign",
    "fa fa-list",
    "fa fa-list-alt",
    "fa fa-list-ol",
    "fa fa-list-ul",
    "fa fa-location-arrow",
    "fa fa-lock",
    "fa fa-lock-open",
    "fa fa-long-arrow-alt-down",
    "fa fa-long-arrow-alt-left",
    "fa fa-long-arrow-alt-right",
    "fa fa-long-arrow-alt-up",
    "fa fa-low-vision",
    "fa fa-luggage-cart",
    "fa fa-lungs",
    "fa fa-lungs-virus",
    "fa fa-magic",
    "fa fa-magnet",
    "fa fa-mail-bulk",
    "fa fa-male",
    "fa fa-map",
    "fa fa-map-marked",
    "fa fa-map-marked-alt",
    "fa fa-map-marker",
    "fa fa-map-marker-alt",
    "fa fa-map-pin",
    "fa fa-map-signs",
    "fa fa-marker",
    "fa fa-mars",
    "fa fa-mars-double",
    "fa fa-mars-stroke",
    "fa fa-mars-stroke-h",
    "fa fa-mars-stroke-v",
    "fa fa-mask",
    "fa fa-medal",
    "fa fa-medkit",
    "fa fa-meh",
    "fa fa-meh-blank",
    "fa fa-meh-rolling-eyes",
    "fa fa-memory",
    "fa fa-menorah",
    "fa fa-mercury",
    "fa fa-meteor",
    "fa fa-microchip",
    "fa fa-microphone",
    "fa fa-microphone-alt",
    "fa fa-microphone-alt-slash",
    "fa fa-microphone-slash",
    "fa fa-microscope",
    "fa fa-minus",
    "fa fa-minus-circle",
    "fa fa-minus-square",
    "fa fa-mitten",
    "fa fa-mobile",
    "fa fa-mobile-alt",
    "fa fa-money-bill",
    "fa fa-money-bill-alt",
    "fa fa-money-bill-wave",
    "fa fa-money-bill-wave-alt",
    "fa fa-money-check",
    "fa fa-money-check-alt",
    "fa fa-monument",
    "fa fa-moon",
    "fa fa-mortar-pestle",
    "fa fa-mosque",
    "fa fa-motorcycle",
    "fa fa-mountain",
    "fa fa-mouse",
    "fa fa-mouse-pointer",
    "fa fa-mug-hot",
    "fa fa-music",
    "fa fa-network-wired",
    "fa fa-neuter",
    "fa fa-newspaper",
    "fa fa-not-equal",
    "fa fa-notes-medical",
    "fa fa-object-group",
    "fa fa-object-ungroup",
    "fa fa-oil-can",
    "fa fa-om",
    "fa fa-otter",
    "fa fa-outdent",
    "fa fa-pager",
    "fa fa-paint-brush",
    "fa fa-paint-roller",
    "fa fa-palette",
    "fa fa-pallet",
    "fa fa-paper-plane",
    "fa fa-paperclip",
    "fa fa-parachute-box",
    "fa fa-paragraph",
    "fa fa-parking",
    "fa fa-passport",
    "fa fa-pastafarianism",
    "fa fa-paste",
    "fa fa-pause",
    "fa fa-pause-circle",
    "fa fa-paw",
    "fa fa-peace",
    "fa fa-pen",
    "fa fa-pen-alt",
    "fa fa-pen-fancy",
    "fa fa-pen-nib",
    "fa fa-pen-square",
    "fa fa-pencil-alt",
    "fa fa-pencil-ruler",
    "fa fa-people-arrows",
    "fa fa-people-carry",
    "fa fa-pepper-hot",
    "fa fa-percent",
    "fa fa-percentage",
    "fa fa-person-booth",
    "fa fa-phone",
    "fa fa-phone-alt",
    "fa fa-phone-slash",
    "fa fa-phone-square",
    "fa fa-phone-square-alt",
    "fa fa-phone-volume",
    "fa fa-photo-video",
    "fa fa-piggy-bank",
    "fa fa-pills",
    "fa fa-pizza-slice",
    "fa fa-place-of-worship",
    "fa fa-plane",
    "fa fa-plane-arrival",
    "fa fa-plane-departure",
    "fa fa-plane-slash",
    "fa fa-play",
    "fa fa-play-circle",
    "fa fa-plug",
    "fa fa-plus",
    "fa fa-plus-circle",
    "fa fa-plus-square",
    "fa fa-podcast",
    "fa fa-poll",
    "fa fa-poll-h",
    "fa fa-poo",
    "fa fa-poo-storm",
    "fa fa-poop",
    "fa fa-portrait",
    "fa fa-pound-sign",
    "fa fa-power-off",
    "fa fa-pray",
    "fa fa-praying-hands",
    "fa fa-prescription",
    "fa fa-prescription-bottle",
    "fa fa-prescription-bottle-alt",
    "fa fa-print",
    "fa fa-procedures",
    "fa fa-project-diagram",
    "fa fa-pump-medical",
    "fa fa-pump-soap",
    "fa fa-puzzle-piece",
    "fa fa-qrcode",
    "fa fa-question",
    "fa fa-question-circle",
    "fa fa-quidditch",
    "fa fa-quote-left",
    "fa fa-quote-right",
    "fa fa-quran",
    "fa fa-radiation",
    "fa fa-radiation-alt",
    "fa fa-rainbow",
    "fa fa-random",
    "fa fa-receipt",
    "fa fa-record-vinyl",
    "fa fa-recycle",
    "fa fa-redo",
    "fa fa-redo-alt",
    "fa fa-registered",
    "fa fa-remove-format",
    "fa fa-reply",
    "fa fa-reply-all",
    "fa fa-republican",
    "fa fa-restroom",
    "fa fa-retweet",
    "fa fa-ribbon",
    "fa fa-ring",
    "fa fa-road",
    "fa fa-robot",
    "fa fa-rocket",
    "fa fa-route",
    "fa fa-rss",
    "fa fa-rss-square",
    "fa fa-ruble-sign",
    "fa fa-ruler",
    "fa fa-ruler-combined",
    "fa fa-ruler-horizontal",
    "fa fa-ruler-vertical",
    "fa fa-running",
    "fa fa-rupee-sign",
    "fa fa-sad-cry",
    "fa fa-sad-tear",
    "fa fa-satellite",
    "fa fa-satellite-dish",
    "fa fa-save",
    "fa fa-school",
    "fa fa-screwdriver",
    "fa fa-scroll",
    "fa fa-sd-card",
    "fa fa-search",
    "fa fa-search-dollar",
    "fa fa-search-location",
    "fa fa-search-minus",
    "fa fa-search-plus",
    "fa fa-seedling",
    "fa fa-server",
    "fa fa-shapes",
    "fa fa-share",
    "fa fa-share-alt",
    "fa fa-share-alt-square",
    "fa fa-share-square",
    "fa fa-shekel-sign",
    "fa fa-shield-alt",
    "fa fa-shield-virus",
    "fa fa-ship",
    "fa fa-shipping-fast",
    "fa fa-shoe-prints",
    "fa fa-shopping-bag",
    "fa fa-shopping-basket",
    "fa fa-shopping-cart",
    "fa fa-shower",
    "fa fa-shuttle-van",
    "fa fa-sign",
    "fa fa-sign-in-alt",
    "fa fa-sign-language",
    "fa fa-sign-out-alt",
    "fa fa-signal",
    "fa fa-signature",
    "fa fa-sim-card",
    "fa fa-sink",
    "fa fa-sitemap",
    "fa fa-skating",
    "fa fa-skiing",
    "fa fa-skiing-nordic",
    "fa fa-skull",
    "fa fa-skull-crossbones",
    "fa fa-slash",
    "fa fa-sleigh",
    "fa fa-sliders-h",
    "fa fa-smile",
    "fa fa-smile-beam",
    "fa fa-smile-wink",
    "fa fa-smog",
    "fa fa-smoking",
    "fa fa-smoking-ban",
    "fa fa-sms",
    "fa fa-snowboarding",
    "fa fa-snowflake",
    "fa fa-snowman",
    "fa fa-snowplow",
    "fa fa-soap",
    "fa fa-socks",
    "fa fa-solar-panel",
    "fa fa-sort",
    "fa fa-sort-alpha-down",
    "fa fa-sort-alpha-down-alt",
    "fa fa-sort-alpha-up",
    "fa fa-sort-alpha-up-alt",
    "fa fa-sort-amount-down",
    "fa fa-sort-amount-down-alt",
    "fa fa-sort-amount-up",
    "fa fa-sort-amount-up-alt",
    "fa fa-sort-down",
    "fa fa-sort-numeric-down",
    "fa fa-sort-numeric-down-alt",
    "fa fa-sort-numeric-up",
    "fa fa-sort-numeric-up-alt",
    "fa fa-sort-up",
    "fa fa-spa",
    "fa fa-space-shuttle",
    "fa fa-spell-check",
    "fa fa-spider",
    "fa fa-spinner",
    "fa fa-splotch",
    "fa fa-spray-can",
    "fa fa-square",
    "fa fa-square-full",
    "fa fa-square-root-alt",
    "fa fa-stamp",
    "fa fa-star",
    "fa fa-star-and-crescent",
    "fa fa-star-half",
    "fa fa-star-half-alt",
    "fa fa-star-of-david",
    "fa fa-star-of-life",
    "fa fa-step-backward",
    "fa fa-step-forward",
    "fa fa-stethoscope",
    "fa fa-sticky-note",
    "fa fa-stop",
    "fa fa-stop-circle",
    "fa fa-stopwatch",
    "fa fa-stopwatch-20",
    "fa fa-store",
    "fa fa-store-alt",
    "fa fa-store-alt-slash",
    "fa fa-store-slash",
    "fa fa-stream",
    "fa fa-street-view",
    "fa fa-strikethrough",
    "fa fa-stroopwafel",
    "fa fa-subscript",
    "fa fa-subway",
    "fa fa-suitcase",
    "fa fa-suitcase-rolling",
    "fa fa-sun",
    "fa fa-superscript",
    "fa fa-surprise",
    "fa fa-swatchbook",
    "fa fa-swimmer",
    "fa fa-swimming-pool",
    "fa fa-synagogue",
    "fa fa-sync",
    "fa fa-sync-alt",
    "fa fa-syringe",
    "fa fa-table",
    "fa fa-table-tennis",
    "fa fa-tablet",
    "fa fa-tablet-alt",
    "fa fa-tablets",
    "fa fa-tachometer-alt",
    "fa fa-tag",
    "fa fa-tags",
    "fa fa-tape",
    "fa fa-tasks",
    "fa fa-taxi",
    "fa fa-teeth",
    "fa fa-teeth-open",
    "fa fa-temperature-high",
    "fa fa-temperature-low",
    "fa fa-tenge",
    "fa fa-terminal",
    "fa fa-text-height",
    "fa fa-text-width",
    "fa fa-th",
    "fa fa-th-large",
    "fa fa-th-list",
    "fa fa-theater-masks",
    "fa fa-thermometer",
    "fa fa-thermometer-empty",
    "fa fa-thermometer-full",
    "fa fa-thermometer-half",
    "fa fa-thermometer-quarter",
    "fa fa-thermometer-three-quarters",
    "fa fa-thumbs-down",
    "fa fa-thumbs-up",
    "fa fa-thumbtack",
    "fa fa-ticket-alt",
    "fa fa-times",
    "fa fa-times-circle",
    "fa fa-tint",
    "fa fa-tint-slash",
    "fa fa-tired",
    "fa fa-toggle-off",
    "fa fa-toggle-on",
    "fa fa-toilet",
    "fa fa-toilet-paper",
    "fa fa-toilet-paper-slash",
    "fa fa-toolbox",
    "fa fa-tools",
    "fa fa-tooth",
    "fa fa-torah",
    "fa fa-torii-gate",
    "fa fa-tractor",
    "fa fa-trademark",
    "fa fa-traffic-light",
    "fa fa-trailer",
    "fa fa-train",
    "fa fa-tram",
    "fa fa-transgender",
    "fa fa-transgender-alt",
    "fa fa-trash",
    "fa fa-trash-alt",
    "fa fa-trash-restore",
    "fa fa-trash-restore-alt",
    "fa fa-tree",
    "fa fa-trophy",
    "fa fa-truck",
    "fa fa-truck-loading",
    "fa fa-truck-monster",
    "fa fa-truck-moving",
    "fa fa-truck-pickup",
    "fa fa-tshirt",
    "fa fa-tty",
    "fa fa-tv",
    "fa fa-umbrella",
    "fa fa-umbrella-beach",
    "fa fa-underline",
    "fa fa-undo",
    "fa fa-undo-alt",
    "fa fa-universal-access",
    "fa fa-university",
    "fa fa-unlink",
    "fa fa-unlock",
    "fa fa-unlock-alt",
    "fa fa-upload",
    "fa fa-user",
    "fa fa-user-alt",
    "fa fa-user-alt-slash",
    "fa fa-user-astronaut",
    "fa fa-user-check",
    "fa fa-user-circle",
    "fa fa-user-clock",
    "fa fa-user-cog",
    "fa fa-user-edit",
    "fa fa-user-friends",
    "fa fa-user-graduate",
    "fa fa-user-injured",
    "fa fa-user-lock",
    "fa fa-user-md",
    "fa fa-user-minus",
    "fa fa-user-ninja",
    "fa fa-user-nurse",
    "fa fa-user-plus",
    "fa fa-user-secret",
    "fa fa-user-shield",
    "fa fa-user-slash",
    "fa fa-user-tag",
    "fa fa-user-tie",
    "fa fa-user-times",
    "fa fa-users",
    "fa fa-users-cog",
    "fa fa-users-slash",
    "fa fa-utensil-spoon",
    "fa fa-utensils",
    "fa fa-vector-square",
    "fa fa-venus",
    "fa fa-venus-double",
    "fa fa-venus-mars",
    "fa fa-vest",
    "fa fa-vest-patches",
    "fa fa-vial",
    "fa fa-vials",
    "fa fa-video",
    "fa fa-video-slash",
    "fa fa-vihara",
    "fa fa-virus",
    "fa fa-virus-slash",
    "fa fa-viruses",
    "fa fa-voicemail",
    "fa fa-volleyball-ball",
    "fa fa-volume-down",
    "fa fa-volume-mute",
    "fa fa-volume-off",
    "fa fa-volume-up",
    "fa fa-vote-yea",
    "fa fa-vr-cardboard",
    "fa fa-walking",
    "fa fa-wallet",
    "fa fa-warehouse",
    "fa fa-water",
    "fa fa-wave-square",
    "fa fa-weight",
    "fa fa-weight-hanging",
    "fa fa-wheelchair",
    "fa fa-wifi",
    "fa fa-wind",
    "fa fa-window-close",
    "fa fa-window-maximize",
    "fa fa-window-minimize",
    "fa fa-window-restore",
    "fa fa-wine-bottle",
    "fa fa-wine-glass",
    "fa fa-wine-glass-alt",
    "fa fa-won-sign",
    "fa fa-wrench",
    "fa fa-x-ray",
    "fa fa-yen-sign",
    "fa fa-yin-yang",
    "fa fa-abacus",
    "fa fa-acorn",
    "fa fa-air-conditioner",
    "fa fa-alarm-clock",
    "fa fa-alarm-exclamation",
    "fa fa-alarm-plus",
    "fa fa-alarm-snooze",
    "fa fa-album",
    "fa fa-album-collection",
    "fa fa-alicorn",
    "fa fa-alien",
    "fa fa-alien-monster",
    "fa fa-align-slash",
    "fa fa-amp-guitar",
    "fa fa-analytics",
    "fa fa-angel",
    "fa fa-apple-crate",
    "fa fa-arrow-alt-down",
    "fa fa-arrow-alt-from-bottom",
    "fa fa-arrow-alt-from-left",
    "fa fa-arrow-alt-from-right",
    "fa fa-arrow-alt-from-top",
    "fa fa-arrow-alt-left",
    "fa fa-arrow-alt-right",
    "fa fa-arrow-alt-square-down",
    "fa fa-arrow-alt-square-left",
    "fa fa-arrow-alt-square-right",
    "fa fa-arrow-alt-square-up",
    "fa fa-arrow-alt-to-bottom",
    "fa fa-arrow-alt-to-left",
    "fa fa-arrow-alt-to-right",
    "fa fa-arrow-alt-to-top",
    "fa fa-arrow-alt-up",
    "fa fa-arrow-from-bottom",
    "fa fa-arrow-from-left",
    "fa fa-arrow-from-right",
    "fa fa-arrow-from-top",
    "fa fa-arrow-square-down",
    "fa fa-arrow-square-left",
    "fa fa-arrow-square-right",
    "fa fa-arrow-square-up",
    "fa fa-arrow-to-bottom",
    "fa fa-arrow-to-left",
    "fa fa-arrow-to-right",
    "fa fa-arrow-to-top",
    "fa fa-arrows",
    "fa fa-arrows-h",
    "fa fa-arrows-v",
    "fa fa-atom-alt",
    "fa fa-axe",
    "fa fa-axe-battle",
    "fa fa-backpack",
    "fa fa-badge",
    "fa fa-badge-check",
    "fa fa-badge-dollar",
    "fa fa-badge-percent",
    "fa fa-badge-sheriff",
    "fa fa-badger-honey",
    "fa fa-bags-shopping",
    "fa fa-ball-pile",
    "fa fa-ballot",
    "fa fa-ballot-check",
    "fa fa-banjo",
    "fa fa-barcode-alt",
    "fa fa-barcode-read",
    "fa fa-barcode-scan",
    "fa fa-baseball",
    "fa fa-basketball-hoop",
    "fa fa-bat",
    "fa fa-battery-bolt",
    "fa fa-battery-slash",
    "fa fa-bed-alt",
    "fa fa-bed-bunk",
    "fa fa-bed-empty",
    "fa fa-bell-exclamation",
    "fa fa-bell-on",
    "fa fa-bell-plus",
    "fa fa-bell-school",
    "fa fa-bell-school-slash",
    "fa fa-bells",
    "fa fa-betamax",
    "fa fa-biking-mountain",
    "fa fa-blanket",
    "fa fa-blinds",
    "fa fa-blinds-open",
    "fa fa-blinds-raised",
    "fa fa-bone-break",
    "fa fa-book-alt",
    "fa fa-book-heart",
    "fa fa-book-spells",
    "fa fa-book-user",
    "fa fa-books",
    "fa fa-books-medical",
    "fa fa-boombox",
    "fa fa-boot",
    "fa fa-booth-curtain",
    "fa fa-border-bottom",
    "fa fa-border-center-h",
    "fa fa-border-center-v",
    "fa fa-border-inner",
    "fa fa-border-left",
    "fa fa-border-outer",
    "fa fa-border-right",
    "fa fa-border-style-alt",
    "fa fa-border-top",
    "fa fa-bow-arrow",
    "fa fa-bowling-pins",
    "fa fa-box-alt",
    "fa fa-box-ballot",
    "fa fa-box-check",
    "fa fa-box-fragile",
    "fa fa-box-full",
    "fa fa-box-heart",
    "fa fa-box-up",
    "fa fa-box-usd",
    "fa fa-boxes-alt",
    "fa fa-boxing-glove",
    "fa fa-brackets",
    "fa fa-brackets-curly",
    "fa fa-bread-loaf",
    "fa fa-bring-forward",
    "fa fa-bring-front",
    "fa fa-browser",
    "fa fa-bullseye-arrow",
    "fa fa-bullseye-pointer",
    "fa fa-burger-soda",
    "fa fa-burrito",
    "fa fa-bus-school",
    "fa fa-cabinet-filing",
    "fa fa-cactus",
    "fa fa-calculator-alt",
    "fa fa-calendar-edit",
    "fa fa-calendar-exclamation",
    "fa fa-calendar-star",
    "fa fa-camcorder",
    "fa fa-camera-alt",
    "fa fa-camera-home",
    "fa fa-camera-movie",
    "fa fa-camera-polaroid",
    "fa fa-campfire",
    "fa fa-candle-holder",
    "fa fa-candy-corn",
    "fa fa-car-building",
    "fa fa-car-bump",
    "fa fa-car-bus",
    "fa fa-car-garage",
    "fa fa-car-mechanic",
    "fa fa-car-tilt",
    "fa fa-car-wash",
    "fa fa-caravan-alt",
    "fa fa-caret-circle-down",
    "fa fa-caret-circle-left",
    "fa fa-caret-circle-right",
    "fa fa-caret-circle-up",
    "fa fa-cars",
    "fa fa-cassette-tape",
    "fa fa-cat-space",
    "fa fa-cauldron",
    "fa fa-cctv",
    "fa fa-chair-office",
    "fa fa-chart-line-down",
    "fa fa-chart-network",
    "fa fa-chart-pie-alt",
    "fa fa-chart-scatter",
    "fa fa-cheese-swiss",
    "fa fa-cheeseburger",
    "fa fa-chess-bishop-alt",
    "fa fa-chess-clock",
    "fa fa-chess-clock-alt",
    "fa fa-chess-king-alt",
    "fa fa-chess-knight-alt",
    "fa fa-chess-pawn-alt",
    "fa fa-chess-queen-alt",
    "fa fa-chess-rook-alt",
    "fa fa-chevron-double-down",
    "fa fa-chevron-double-left",
    "fa fa-chevron-double-right",
    "fa fa-chevron-double-up",
    "fa fa-chevron-square-down",
    "fa fa-chevron-square-left",
    "fa fa-chevron-square-right",
    "fa fa-chevron-square-up",
    "fa fa-chimney",
    "fa fa-clarinet",
    "fa fa-claw-marks",
    "fa fa-clipboard-list-check",
    "fa fa-clipboard-prescription",
    "fa fa-clipboard-user",
    "fa fa-cloud-download",
    "fa fa-cloud-drizzle",
    "fa fa-cloud-hail",
    "fa fa-cloud-hail-mixed",
    "fa fa-cloud-music",
    "fa fa-cloud-rainbow",
    "fa fa-cloud-showers",
    "fa fa-cloud-sleet",
    "fa fa-cloud-snow",
    "fa fa-cloud-upload",
    "fa fa-clouds",
    "fa fa-clouds-moon",
    "fa fa-clouds-sun",
    "fa fa-club",
    "fa fa-code-commit",
    "fa fa-code-merge",
    "fa fa-coffee-pot",
    "fa fa-coffee-togo",
    "fa fa-coffin",
    "fa fa-coffin-cross",
    "fa fa-coin",
    "fa fa-comet",
    "fa fa-comment-alt-check",
    "fa fa-comment-alt-dollar",
    "fa fa-comment-alt-dots",
    "fa fa-comment-alt-edit",
    "fa fa-comment-alt-exclamation",
    "fa fa-comment-alt-lines",
    "fa fa-comment-alt-medical",
    "fa fa-comment-alt-minus",
    "fa fa-comment-alt-music",
    "fa fa-comment-alt-plus",
    "fa fa-comment-alt-slash",
    "fa fa-comment-alt-smile",
    "fa fa-comment-alt-times",
    "fa fa-comment-check",
    "fa fa-comment-edit",
    "fa fa-comment-exclamation",
    "fa fa-comment-lines",
    "fa fa-comment-minus",
    "fa fa-comment-music",
    "fa fa-comment-plus",
    "fa fa-comment-smile",
    "fa fa-comment-times",
    "fa fa-comments-alt",
    "fa fa-comments-alt-dollar",
    "fa fa-compass-slash",
    "fa fa-compress-wide",
    "fa fa-computer-classic",
    "fa fa-computer-speaker",
    "fa fa-construction",
    "fa fa-container-storage",
    "fa fa-conveyor-belt",
    "fa fa-conveyor-belt-alt",
    "fa fa-corn",
    "fa fa-cow",
    "fa fa-cowbell",
    "fa fa-cowbell-more",
    "fa fa-credit-card-blank",
    "fa fa-credit-card-front",
    "fa fa-cricket",
    "fa fa-croissant",
    "fa fa-crutches",
    "fa fa-curling",
    "fa fa-dagger",
    "fa fa-debug",
    "fa fa-deer",
    "fa fa-deer-rudolph",
    "fa fa-desktop-alt",
    "fa fa-dewpoint",
    "fa fa-diamond",
    "fa fa-dice-d10",
    "fa fa-dice-d12",
    "fa fa-dice-d4",
    "fa fa-dice-d8",
    "fa fa-digging",
    "fa fa-diploma",
    "fa fa-disc-drive",
    "fa fa-do-not-enter",
    "fa fa-dog-leashed",
    "fa fa-dolly-empty",
    "fa fa-dolly-flatbed-alt",
    "fa fa-dolly-flatbed-empty",
    "fa fa-draw-circle",
    "fa fa-draw-square",
    "fa fa-dreidel",
    "fa fa-drone",
    "fa fa-drone-alt",
    "fa fa-drumstick",
    "fa fa-dryer",
    "fa fa-dryer-alt",
    "fa fa-duck",
    "fa fa-ear",
    "fa fa-ear-muffs",
    "fa fa-eclipse",
    "fa fa-eclipse-alt",
    "fa fa-egg-fried",
    "fa fa-elephant",
    "fa fa-ellipsis-h-alt",
    "fa fa-ellipsis-v-alt",
    "fa fa-empty-set",
    "fa fa-engine-warning",
    "fa fa-envelope-open-dollar",
    "fa fa-exchange",
    "fa fa-exclamation-square",
    "fa fa-expand-arrows",
    "fa fa-expand-wide",
    "fa fa-external-link",
    "fa fa-external-link-square",
    "fa fa-eye-evil",
    "fa fa-fan-table",
    "fa fa-farm",
    "fa fa-faucet-drip",
    "fa fa-field-hockey",
    "fa fa-file-certificate",
    "fa fa-file-chart-line",
    "fa fa-file-chart-pie",
    "fa fa-file-check",
    "fa fa-file-edit",
    "fa fa-file-exclamation",
    "fa fa-file-minus",
    "fa fa-file-music",
    "fa fa-file-plus",
    "fa fa-file-search",
    "fa fa-file-spreadsheet",
    "fa fa-file-times",
    "fa fa-file-user",
    "fa fa-files-medical",
    "fa fa-film-alt",
    "fa fa-film-canister",
    "fa fa-fire-smoke",
    "fa fa-fireplace",
    "fa fa-fish-cooked",
    "fa fa-flag-alt",
    "fa fa-flame",
    "fa fa-flashlight",
    "fa fa-flask-poison",
    "fa fa-flask-potion",
    "fa fa-flower",
    "fa fa-flower-daffodil",
    "fa fa-flower-tulip",
    "fa fa-flute",
    "fa fa-flux-capacitor",
    "fa fa-fog",
    "fa fa-folder-download",
    "fa fa-folder-times",
    "fa fa-folder-tree",
    "fa fa-folder-upload",
    "fa fa-folders",
    "fa fa-font-case",
    "fa fa-football-helmet",
    "fa fa-forklift",
    "fa fa-fragile",
    "fa fa-french-fries",
    "fa fa-frosty-head",
    "fa fa-function",
    "fa fa-galaxy",
    "fa fa-game-board",
    "fa fa-game-board-alt",
    "fa fa-game-console-handheld",
    "fa fa-gamepad-alt",
    "fa fa-garage",
    "fa fa-garage-car",
    "fa fa-garage-open",
    "fa fa-gas-pump-slash",
    "fa fa-gift-card",
    "fa fa-gingerbread-man",
    "fa fa-glass",
    "fa fa-glass-champagne",
    "fa fa-glass-citrus",
    "fa fa-glass-whiskey-rocks",
    "fa fa-glasses-alt",
    "fa fa-globe-snow",
    "fa fa-globe-stand",
    "fa fa-golf-club",
    "fa fa-gramophone",
    "fa fa-guitar-electric",
    "fa fa-guitars",
    "fa fa-h1",
    "fa fa-h2",
    "fa fa-h3",
    "fa fa-h4",
    "fa fa-hammer-war",
    "fa fa-hand-heart",
    "fa fa-hand-holding-box",
    "fa fa-hand-holding-magic",
    "fa fa-hand-holding-seedling",
    "fa fa-hand-receiving",
    "fa fa-hands-heart",
    "fa fa-hands-usd",
    "fa fa-handshake-alt",
    "fa fa-hat-chef",
    "fa fa-hat-santa",
    "fa fa-hat-winter",
    "fa fa-hat-witch",
    "fa fa-head-side",
    "fa fa-head-side-brain",
    "fa fa-head-side-headphones",
    "fa fa-head-side-medical",
    "fa fa-head-vr",
    "fa fa-heart-circle",
    "fa fa-heart-rate",
    "fa fa-heart-square",
    "fa fa-heat",
    "fa fa-helmet-battle",
    "fa fa-hexagon",
    "fa fa-hockey-mask",
    "fa fa-hockey-sticks",
    "fa fa-home-alt",
    "fa fa-home-heart",
    "fa fa-home-lg",
    "fa fa-home-lg-alt",
    "fa fa-hood-cloak",
    "fa fa-horizontal-rule",
    "fa fa-horse-saddle",
    "fa fa-hospitals",
    "fa fa-house",
    "fa fa-house-day",
    "fa fa-house-flood",
    "fa fa-house-leave",
    "fa fa-house-night",
    "fa fa-house-return",
    "fa fa-house-signal",
    "fa fa-humidity",
    "fa fa-hurricane",
    "fa fa-ice-skate",
    "fa fa-icons-alt",
    "fa fa-image-polaroid",
    "fa fa-inbox-in",
    "fa fa-inbox-out",
    "fa fa-industry-alt",
    "fa fa-info-square",
    "fa fa-inhaler",
    "fa fa-integral",
    "fa fa-intersection",
    "fa fa-inventory",
    "fa fa-island-tropical",
    "fa fa-jack-o-lantern",
    "fa fa-joystick",
    "fa fa-jug",
    "fa fa-kazoo",
    "fa fa-kerning",
    "fa fa-key-skeleton",
    "fa fa-keynote",
    "fa fa-kidneys",
    "fa fa-kite",
    "fa fa-knife-kitchen",
    "fa fa-lambda",
    "fa fa-lamp",
    "fa fa-lamp-desk",
    "fa fa-lamp-floor",
    "fa fa-landmark-alt",
    "fa fa-lasso",
    "fa fa-layer-minus",
    "fa fa-layer-plus",
    "fa fa-leaf-heart",
    "fa fa-leaf-maple",
    "fa fa-leaf-oak",
    "fa fa-level-down",
    "fa fa-level-up",
    "fa fa-light-ceiling",
    "fa fa-light-switch",
    "fa fa-light-switch-off",
    "fa fa-light-switch-on",
    "fa fa-lightbulb-dollar",
    "fa fa-lightbulb-exclamation",
    "fa fa-lightbulb-on",
    "fa fa-lightbulb-slash",
    "fa fa-lights-holiday",
    "fa fa-line-columns",
    "fa fa-line-height",
    "fa fa-lips",
    "fa fa-list-music",
    "fa fa-location",
    "fa fa-location-circle",
    "fa fa-location-slash",
    "fa fa-lock-alt",
    "fa fa-lock-open-alt",
    "fa fa-long-arrow-down",
    "fa fa-long-arrow-left",
    "fa fa-long-arrow-right",
    "fa fa-long-arrow-up",
    "fa fa-loveseat",
    "fa fa-luchador",
    "fa fa-mace",
    "fa fa-mailbox",
    "fa fa-mandolin",
    "fa fa-map-marker-alt-slash",
    "fa fa-map-marker-check",
    "fa fa-map-marker-edit",
    "fa fa-map-marker-exclamation",
    "fa fa-map-marker-minus",
    "fa fa-map-marker-plus",
    "fa fa-map-marker-question",
    "fa fa-map-marker-slash",
    "fa fa-map-marker-smile",
    "fa fa-map-marker-times",
    "fa fa-meat",
    "fa fa-megaphone",
    "fa fa-microphone-stand",
    "fa fa-microwave",
    "fa fa-mind-share",
    "fa fa-minus-hexagon",
    "fa fa-minus-octagon",
    "fa fa-mistletoe",
    "fa fa-mobile-android",
    "fa fa-mobile-android-alt",
    "fa fa-money-check-edit",
    "fa fa-money-check-edit-alt",
    "fa fa-monitor-heart-rate",
    "fa fa-monkey",
    "fa fa-moon-cloud",
    "fa fa-moon-stars",
    "fa fa-mountains",
    "fa fa-mouse-alt",
    "fa fa-mp3-player",
    "fa fa-mug",
    "fa fa-mug-marshmallows",
    "fa fa-mug-tea",
    "fa fa-music-alt",
    "fa fa-music-alt-slash",
    "fa fa-music-slash",
    "fa fa-narwhal",
    "fa fa-octagon",
    "fa fa-oil-temp",
    "fa fa-omega",
    "fa fa-ornament",
    "fa fa-outlet",
    "fa fa-oven",
    "fa fa-overline",
    "fa fa-page-break",
    "fa fa-paint-brush-alt",
    "fa fa-pallet-alt",
    "fa fa-paragraph-rtl",
    "fa fa-parking-circle",
    "fa fa-parking-circle-slash",
    "fa fa-parking-slash",
    "fa fa-paw-alt",
    "fa fa-paw-claws",
    "fa fa-pegasus",
    "fa fa-pencil",
    "fa fa-pencil-paintbrush",
    "fa fa-pennant",
    "fa fa-person-carry",
    "fa fa-person-dolly",
    "fa fa-person-dolly-empty",
    "fa fa-person-sign",
    "fa fa-phone-laptop",
    "fa fa-phone-office",
    "fa fa-phone-plus",
    "fa fa-phone-rotary",
    "fa fa-pi",
    "fa fa-piano",
    "fa fa-piano-keyboard",
    "fa fa-pie",
    "fa fa-pig",
    "fa fa-pizza",
    "fa fa-plane-alt",
    "fa fa-planet-moon",
    "fa fa-planet-ringed",
    "fa fa-plus-hexagon",
    "fa fa-plus-octagon",
    "fa fa-podium",
    "fa fa-podium-star",
    "fa fa-police-box",
    "fa fa-poll-people",
    "fa fa-popcorn",
    "fa fa-portal-enter",
    "fa fa-portal-exit",
    "fa fa-presentation",
    "fa fa-print-search",
    "fa fa-print-slash",
    "fa fa-projector",
    "fa fa-pumpkin",
    "fa fa-question-square",
    "fa fa-rabbit",
    "fa fa-rabbit-fast",
    "fa fa-racquet",
    "fa fa-radar",
    "fa fa-radio",
    "fa fa-radio-alt",
    "fa fa-raindrops",
    "fa fa-ram",
    "fa fa-ramp-loading",
    "fa fa-raygun",
    "fa fa-rectangle-landscape",
    "fa fa-rectangle-portrait",
    "fa fa-rectangle-wide",
    "fa fa-refrigerator",
    "fa fa-repeat",
    "fa fa-repeat-1",
    "fa fa-repeat-1-alt",
    "fa fa-repeat-alt",
    "fa fa-retweet-alt",
    "fa fa-rings-wedding",
    "fa fa-rocket-launch",
    "fa fa-route-highway",
    "fa fa-route-interstate",
    "fa fa-router",
    "fa fa-ruler-triangle",
    "fa fa-rv",
    "fa fa-sack",
    "fa fa-sack-dollar",
    "fa fa-salad",
    "fa fa-sandwich",
    "fa fa-sausage",
    "fa fa-sax-hot",
    "fa fa-saxophone",
    "fa fa-scalpel",
    "fa fa-scalpel-path",
    "fa fa-scanner",
    "fa fa-scanner-image",
    "fa fa-scanner-keyboard",
    "fa fa-scanner-touchscreen",
    "fa fa-scarecrow",
    "fa fa-scarf",
    "fa fa-scroll-old",
    "fa fa-scrubber",
    "fa fa-scythe",
    "fa fa-send-back",
    "fa fa-send-backward",
    "fa fa-sensor",
    "fa fa-sensor-alert",
    "fa fa-sensor-fire",
    "fa fa-sensor-on",
    "fa fa-sensor-smoke",
    "fa fa-share-all",
    "fa fa-sheep",
    "fa fa-shield",
    "fa fa-shield-check",
    "fa fa-shield-cross",
    "fa fa-shipping-timed",
    "fa fa-shish-kebab",
    "fa fa-shovel",
    "fa fa-shovel-snow",
    "fa fa-shredder",
    "fa fa-shuttlecock",
    "fa fa-sickle",
    "fa fa-sigma",
    "fa fa-sign-in",
    "fa fa-sign-out",
    "fa fa-signal-1",
    "fa fa-signal-2",
    "fa fa-signal-3",
    "fa fa-signal-4",
    "fa fa-signal-alt",
    "fa fa-signal-alt-1",
    "fa fa-signal-alt-2",
    "fa fa-signal-alt-3",
    "fa fa-signal-alt-slash",
    "fa fa-signal-slash",
    "fa fa-signal-stream",
    "fa fa-siren",
    "fa fa-siren-on",
    "fa fa-skeleton",
    "fa fa-ski-jump",
    "fa fa-ski-lift",
    "fa fa-skull-cow",
    "fa fa-sledding",
    "fa fa-sliders-h-square",
    "fa fa-sliders-v",
    "fa fa-sliders-v-square",
    "fa fa-smile-plus",
    "fa fa-smoke",
    "fa fa-snake",
    "fa fa-snooze",
    "fa fa-snow-blowing",
    "fa fa-snowflakes",
    "fa fa-snowmobile",
    "fa fa-solar-system",
    "fa fa-sort-alt",
    "fa fa-sort-circle",
    "fa fa-sort-circle-down",
    "fa fa-sort-circle-up",
    "fa fa-sort-shapes-down",
    "fa fa-sort-shapes-down-alt",
    "fa fa-sort-shapes-up",
    "fa fa-sort-shapes-up-alt",
    "fa fa-sort-size-down",
    "fa fa-sort-size-down-alt",
    "fa fa-sort-size-up",
    "fa fa-sort-size-up-alt",
    "fa fa-soup",
    "fa fa-space-station-moon",
    "fa fa-space-station-moon-alt",
    "fa fa-spade",
    "fa fa-sparkles",
    "fa fa-speaker",
    "fa fa-speakers",
    "fa fa-spider-black-widow",
    "fa fa-spider-web",
    "fa fa-spinner-third",
    "fa fa-sprinkler",
    "fa fa-square-root",
    "fa fa-squirrel",
    "fa fa-staff",
    "fa fa-star-christmas",
    "fa fa-star-exclamation",
    "fa fa-star-shooting",
    "fa fa-starfighter",
    "fa fa-starfighter-alt",
    "fa fa-stars",
    "fa fa-starship",
    "fa fa-starship-freighter",
    "fa fa-steak",
    "fa fa-steering-wheel",
    "fa fa-stocking",
    "fa fa-stomach",
    "fa fa-stretcher",
    "fa fa-sun-cloud",
    "fa fa-sun-dust",
    "fa fa-sun-haze",
    "fa fa-sunglasses",
    "fa fa-sunrise",
    "fa fa-sunset",
    "fa fa-sword",
    "fa fa-sword-laser",
    "fa fa-sword-laser-alt",
    "fa fa-swords",
    "fa fa-swords-laser",
    "fa fa-tablet-android",
    "fa fa-tablet-android-alt",
    "fa fa-tablet-rugged",
    "fa fa-tachometer",
    "fa fa-tachometer-alt-average",
    "fa fa-tachometer-alt-fast",
    "fa fa-tachometer-alt-fastest",
    "fa fa-tachometer-alt-slow",
    "fa fa-tachometer-alt-slowest",
    "fa fa-tachometer-average",
    "fa fa-tachometer-fast",
    "fa fa-tachometer-fastest",
    "fa fa-tachometer-slow",
    "fa fa-tachometer-slowest",
    "fa fa-taco",
    "fa fa-tally",
    "fa fa-tanakh",
    "fa fa-tasks-alt",
    "fa fa-telescope",
    "fa fa-temperature-down",
    "fa fa-temperature-frigid",
    "fa fa-temperature-hot",
    "fa fa-temperature-up",
    "fa fa-tennis-ball",
    "fa fa-text",
    "fa fa-text-size",
    "fa fa-theta",
    "fa fa-thunderstorm",
    "fa fa-thunderstorm-moon",
    "fa fa-thunderstorm-sun",
    "fa fa-ticket",
    "fa fa-tilde",
    "fa fa-times-hexagon",
    "fa fa-times-octagon",
    "fa fa-times-square",
    "fa fa-tire",
    "fa fa-tire-flat",
    "fa fa-tire-pressure-warning",
    "fa fa-tire-rugged",
    "fa fa-toilet-paper-alt",
    "fa fa-tombstone",
    "fa fa-tombstone-alt",
    "fa fa-toothbrush",
    "fa fa-tornado",
    "fa fa-traffic-cone",
    "fa fa-traffic-light-go",
    "fa fa-traffic-light-slow",
    "fa fa-traffic-light-stop",
    "fa fa-transporter",
    "fa fa-transporter-1",
    "fa fa-transporter-2",
    "fa fa-transporter-3",
    "fa fa-transporter-empty",
    "fa fa-trash-undo",
    "fa fa-trash-undo-alt",
    "fa fa-treasure-chest",
    "fa fa-tree-alt",
    "fa fa-tree-christmas",
    "fa fa-tree-decorated",
    "fa fa-tree-large",
    "fa fa-tree-palm",
    "fa fa-trees",
    "fa fa-triangle",
    "fa fa-triangle-music",
    "fa fa-trophy-alt",
    "fa fa-truck-container",
    "fa fa-truck-couch",
    "fa fa-truck-plow",
    "fa fa-truck-ramp",
    "fa fa-trumpet",
    "fa fa-turkey",
    "fa fa-turntable",
    "fa fa-turtle",
    "fa fa-tv-alt",
    "fa fa-tv-music",
    "fa fa-tv-retro",
    "fa fa-typewriter",
    "fa fa-ufo",
    "fa fa-ufo-beam",
    "fa fa-unicorn",
    "fa fa-union",
    "fa fa-usb-drive",
    "fa fa-usd-circle",
    "fa fa-usd-square",
    "fa fa-user-alien",
    "fa fa-user-chart",
    "fa fa-user-cowboy",
    "fa fa-user-crown",
    "fa fa-user-hard-hat",
    "fa fa-user-headset",
    "fa fa-user-md-chat",
    "fa fa-user-music",
    "fa fa-user-robot",
    "fa fa-user-unlock",
    "fa fa-user-visor",
    "fa fa-users-class",
    "fa fa-users-crown",
    "fa fa-users-medical",
    "fa fa-utensil-fork",
    "fa fa-utensil-knife",
    "fa fa-utensils-alt",
    "fa fa-vacuum",
    "fa fa-vacuum-robot",
    "fa fa-value-absolute",
    "fa fa-vhs",
    "fa fa-video-plus",
    "fa fa-violin",
    "fa fa-volcano",
    "fa fa-volume",
    "fa fa-volume-slash",
    "fa fa-vote-nay",
    "fa fa-wagon-covered",
    "fa fa-walker",
    "fa fa-walkie-talkie",
    "fa fa-wand",
    "fa fa-wand-magic",
    "fa fa-warehouse-alt",
    "fa fa-washer",
    "fa fa-watch",
    "fa fa-watch-calculator",
    "fa fa-watch-fitness",
    "fa fa-water-lower",
    "fa fa-water-rise",
    "fa fa-wave-sine",
    "fa fa-wave-triangle",
    "fa fa-waveform",
    "fa fa-waveform-path",
    "fa fa-webcam",
    "fa fa-webcam-slash",
    "fa fa-whale",
    "fa fa-wheat",
    "fa fa-whistle",
    "fa fa-wifi-1",
    "fa fa-wifi-2",
    "fa fa-wifi-slash",
    "fa fa-wind-turbine",
    "fa fa-wind-warning",
    "fa fa-window",
    "fa fa-window-alt",
    "fa fa-window-frame",
    "fa fa-window-frame-open",
    "fa fa-windsock",
    "fa fa-wreath",
    "fab fa-500px",
    "fab fa-accessible-icon",
    "fab fa-accusoft",
    "fab fa-acquisitions-incorporated",
    "fab fa-adn",
    "fab fa-adversal",
    "fab fa-affiliatetheme",
    "fab fa-airbnb",
    "fab fa-algolia",
    "fab fa-alipay",
    "fab fa-amazon",
    "fab fa-amazon-pay",
    "fab fa-amilia",
    "fab fa-android",
    "fab fa-angellist",
    "fab fa-angrycreative",
    "fab fa-angular",
    "fab fa-app-store",
    "fab fa-app-store-ios",
    "fab fa-apper",
    "fab fa-apple",
    "fab fa-apple-pay",
    "fab fa-artstation",
    "fab fa-asymmetrik",
    "fab fa-atlassian",
    "fab fa-audible",
    "fab fa-autoprefixer",
    "fab fa-avianex",
    "fab fa-aviato",
    "fab fa-aws",
    "fab fa-bandcamp",
    "fab fa-battle-net",
    "fab fa-behance",
    "fab fa-behance-square",
    "fab fa-bimobject",
    "fab fa-bitbucket",
    "fab fa-bitcoin",
    "fab fa-bity",
    "fab fa-black-tie",
    "fab fa-blackberry",
    "fab fa-blogger",
    "fab fa-blogger-b",
    "fab fa-bluetooth",
    "fab fa-bluetooth-b",
    "fab fa-bootstrap",
    "fab fa-btc",
    "fab fa-buffer",
    "fab fa-buromobelexperte",
    "fab fa-buy-n-large",
    "fab fa-buysellads",
    "fab fa-canadian-maple-leaf",
    "fab fa-cc-amazon-pay",
    "fab fa-cc-amex",
    "fab fa-cc-apple-pay",
    "fab fa-cc-diners-club",
    "fab fa-cc-discover",
    "fab fa-cc-jcb",
    "fab fa-cc-mastercard",
    "fab fa-cc-paypal",
    "fab fa-cc-stripe",
    "fab fa-cc-visa",
    "fab fa-centercode",
    "fab fa-centos",
    "fab fa-chrome",
    "fab fa-chromecast",
    "fab fa-cloudflare",
    "fab fa-cloudscale",
    "fab fa-cloudsmith",
    "fab fa-cloudversify",
    "fab fa-codepen",
    "fab fa-codiepie",
    "fab fa-confluence",
    "fab fa-connectdevelop",
    "fab fa-contao",
    "fab fa-cotton-bureau",
    "fab fa-cpanel",
    "fab fa-creative-commons",
    "fab fa-creative-commons-by",
    "fab fa-creative-commons-nc",
    "fab fa-creative-commons-nc-eu",
    "fab fa-creative-commons-nc-jp",
    "fab fa-creative-commons-nd",
    "fab fa-creative-commons-pd",
    "fab fa-creative-commons-pd-alt",
    "fab fa-creative-commons-remix",
    "fab fa-creative-commons-sa",
    "fab fa-creative-commons-sampling",
    "fab fa-creative-commons-sampling-plus",
    "fab fa-creative-commons-share",
    "fab fa-creative-commons-zero",
    "fab fa-critical-role",
    "fab fa-css3",
    "fab fa-css3-alt",
    "fab fa-cuttlefish",
    "fab fa-d-and-d",
    "fab fa-d-and-d-beyond",
    "fab fa-dailymotion",
    "fab fa-dashcube",
    "fab fa-deezer",
    "fab fa-delicious",
    "fab fa-deploydog",
    "fab fa-deskpro",
    "fab fa-dev",
    "fab fa-deviantart",
    "fab fa-dhl",
    "fab fa-diaspora",
    "fab fa-digg",
    "fab fa-digital-ocean",
    "fab fa-discord",
    "fab fa-discourse",
    "fab fa-dochub",
    "fab fa-docker",
    "fab fa-draft2digital",
    "fab fa-dribbble",
    "fab fa-dribbble-square",
    "fab fa-dropbox",
    "fab fa-drupal",
    "fab fa-dyalog",
    "fab fa-earlybirds",
    "fab fa-ebay",
    "fab fa-edge",
    "fab fa-edge-legacy",
    "fab fa-elementor",
    "fab fa-ello",
    "fab fa-ember",
    "fab fa-empire",
    "fab fa-envira",
    "fab fa-erlang",
    "fab fa-ethereum",
    "fab fa-etsy",
    "fab fa-evernote",
    "fab fa-expeditedssl",
    "fab fa-facebook",
    "fab fa-facebook-f",
    "fab fa-facebook-messenger",
    "fab fa-facebook-square",
    "fab fa-fantasy-flight-games",
    "fab fa-fedex",
    "fab fa-fedora",
    "fab fa-figma",
    "fab fa-firefox",
    "fab fa-firefox-browser",
    "fab fa-first-order",
    "fab fa-first-order-alt",
    "fab fa-firstdraft",
    "fab fa-flickr",
    "fab fa-flipboard",
    "fab fa-fly",
    "fab fa-font-awesome",
    "fab fa-font-awesome-alt",
    "fab fa-font-awesome-flag",
    "fab fa-fonticons",
    "fab fa-fonticons-fi",
    "fab fa-fort-awesome",
    "fab fa-fort-awesome-alt",
    "fab fa-forumbee",
    "fab fa-foursquare",
    "fab fa-free-code-camp",
    "fab fa-freebsd",
    "fab fa-fulcrum",
    "fab fa-galactic-republic",
    "fab fa-galactic-senate",
    "fab fa-get-pocket",
    "fab fa-gg",
    "fab fa-gg-circle",
    "fab fa-git",
    "fab fa-git-alt",
    "fab fa-git-square",
    "fab fa-github",
    "fab fa-github-alt",
    "fab fa-github-square",
    "fab fa-gitkraken",
    "fab fa-gitlab",
    "fab fa-gitter",
    "fab fa-glide",
    "fab fa-glide-g",
    "fab fa-gofore",
    "fab fa-goodreads",
    "fab fa-goodreads-g",
    "fab fa-google",
    "fab fa-google-drive",
    "fab fa-google-pay",
    "fab fa-google-play",
    "fab fa-google-plus",
    "fab fa-google-plus-g",
    "fab fa-google-plus-square",
    "fab fa-google-wallet",
    "fab fa-gratipay",
    "fab fa-grav",
    "fab fa-gripfire",
    "fab fa-grunt",
    "fab fa-guilded",
    "fab fa-gulp",
    "fab fa-hacker-news",
    "fab fa-hacker-news-square",
    "fab fa-hackerrank",
    "fab fa-hips",
    "fab fa-hire-a-helper",
    "fab fa-hive",
    "fab fa-hooli",
    "fab fa-hornbill",
    "fab fa-hotjar",
    "fab fa-houzz",
    "fab fa-html5",
    "fab fa-hubspot",
    "fab fa-ideal",
    "fab fa-imdb",
    "fab fa-innosoft",
    "fab fa-instagram",
    "fab fa-instagram-square",
    "fab fa-instalod",
    "fab fa-intercom",
    "fab fa-internet-explorer",
    "fab fa-invision",
    "fab fa-ioxhost",
    "fab fa-itch-io",
    "fab fa-itunes",
    "fab fa-itunes-note",
    "fab fa-java",
    "fab fa-jedi-order",
    "fab fa-jenkins",
    "fab fa-jira",
    "fab fa-joget",
    "fab fa-joomla",
    "fab fa-js",
    "fab fa-js-square",
    "fab fa-jsfiddle",
    "fab fa-kaggle",
    "fab fa-keybase",
    "fab fa-keycdn",
    "fab fa-kickstarter",
    "fab fa-kickstarter-k",
    "fab fa-korvue",
    "fab fa-laravel",
    "fab fa-lastfm",
    "fab fa-lastfm-square",
    "fab fa-leanpub",
    "fab fa-less",
    "fab fa-line",
    "fab fa-linkedin",
    "fab fa-linkedin-in",
    "fab fa-linode",
    "fab fa-linux",
    "fab fa-lyft",
    "fab fa-magento",
    "fab fa-mailchimp",
    "fab fa-mandalorian",
    "fab fa-markdown",
    "fab fa-mastodon",
    "fab fa-maxcdn",
    "fab fa-mdb",
    "fab fa-medapps",
    "fab fa-medium",
    "fab fa-medium-m",
    "fab fa-medrt",
    "fab fa-meetup",
    "fab fa-megaport",
    "fab fa-mendeley",
    "fab fa-microblog",
    "fab fa-microsoft",
    "fab fa-mix",
    "fab fa-mixcloud",
    "fab fa-mixer",
    "fab fa-mizuni",
    "fab fa-modx",
    "fab fa-monero",
    "fab fa-napster",
    "fab fa-neos",
    "fab fa-nimblr",
    "fab fa-node",
    "fab fa-node-js",
    "fab fa-npm",
    "fab fa-ns8",
    "fab fa-nutritionix",
    "fab fa-octopus-deploy",
    "fab fa-odnoklassniki",
    "fab fa-odnoklassniki-square",
    "fab fa-old-republic",
    "fab fa-opencart",
    "fab fa-openid",
    "fab fa-opera",
    "fab fa-optin-monster",
    "fab fa-orcid",
    "fab fa-osi",
    "fab fa-page4",
    "fab fa-pagelines",
    "fab fa-palfed",
    "fab fa-patreon",
    "fab fa-paypal",
    "fab fa-penny-arcade",
    "fab fa-perbyte",
    "fab fa-periscope",
    "fab fa-phabricator",
    "fab fa-phoenix-framework",
    "fab fa-phoenix-squadron",
    "fab fa-php",
    "fab fa-pied-piper",
    "fab fa-pied-piper-alt",
    "fab fa-pied-piper-hat",
    "fab fa-pied-piper-pp",
    "fab fa-pied-piper-square",
    "fab fa-pinterest",
    "fab fa-pinterest-p",
    "fab fa-pinterest-square",
    "fab fa-playstation",
    "fab fa-product-hunt",
    "fab fa-pushed",
    "fab fa-python",
    "fab fa-qq",
    "fab fa-quinscape",
    "fab fa-quora",
    "fab fa-r-project",
    "fab fa-raspberry-pi",
    "fab fa-ravelry",
    "fab fa-react",
    "fab fa-reacteurope",
    "fab fa-readme",
    "fab fa-rebel",
    "fab fa-red-river",
    "fab fa-reddit",
    "fab fa-reddit-alien",
    "fab fa-reddit-square",
    "fab fa-redhat",
    "fab fa-renren",
    "fab fa-replyd",
    "fab fa-researchgate",
    "fab fa-resolving",
    "fab fa-rev",
    "fab fa-rocketchat",
    "fab fa-rockrms",
    "fab fa-rust",
    "fab fa-safari",
    "fab fa-salesforce",
    "fab fa-sass",
    "fab fa-schlix",
    "fab fa-scribd",
    "fab fa-searchengin",
    "fab fa-sellcast",
    "fab fa-sellsy",
    "fab fa-servicestack",
    "fab fa-shirtsinbulk",
    "fab fa-shopify",
    "fab fa-shopware",
    "fab fa-simplybuilt",
    "fab fa-sistrix",
    "fab fa-sith",
    "fab fa-sketch",
    "fab fa-skyatlas",
    "fab fa-skype",
    "fab fa-slack",
    "fab fa-slack-hash",
    "fab fa-slideshare",
    "fab fa-snapchat",
    "fab fa-snapchat-ghost",
    "fab fa-snapchat-square",
    "fab fa-soundcloud",
    "fab fa-sourcetree",
    "fab fa-speakap",
    "fab fa-speaker-deck",
    "fab fa-spotify",
    "fab fa-squarespace",
    "fab fa-stack-exchange",
    "fab fa-stack-overflow",
    "fab fa-stackpath",
    "fab fa-staylinked",
    "fab fa-steam",
    "fab fa-steam-square",
    "fab fa-steam-symbol",
    "fab fa-sticker-mule",
    "fab fa-strava",
    "fab fa-stripe",
    "fab fa-stripe-s",
    "fab fa-studiovinari",
    "fab fa-stumbleupon",
    "fab fa-stumbleupon-circle",
    "fab fa-superpowers",
    "fab fa-supple",
    "fab fa-suse",
    "fab fa-swift",
    "fab fa-symfony",
    "fab fa-teamspeak",
    "fab fa-telegram",
    "fab fa-telegram-plane",
    "fab fa-tencent-weibo",
    "fab fa-the-red-yeti",
    "fab fa-themeco",
    "fab fa-themeisle",
    "fab fa-think-peaks",
    "fab fa-tiktok",
    "fab fa-trade-federation",
    "fab fa-trello",
    "fab fa-tripadvisor",
    "fab fa-tumblr",
    "fab fa-tumblr-square",
    "fab fa-twitch",
    "fab fa-twitter",
    "fab fa-twitter-square",
    "fab fa-typo3",
    "fab fa-uber",
    "fab fa-ubuntu",
    "fab fa-uikit",
    "fab fa-umbraco",
    "fab fa-uncharted",
    "fab fa-uniregistry",
    "fab fa-unity",
    "fab fa-unsplash",
    "fab fa-untappd",
    "fab fa-ups",
    "fab fa-usb",
    "fab fa-usps",
    "fab fa-ussunnah",
    "fab fa-vaadin",
    "fab fa-viacoin",
    "fab fa-viadeo",
    "fab fa-viadeo-square",
    "fab fa-viber",
    "fab fa-vimeo",
    "fab fa-vimeo-square",
    "fab fa-vimeo-v",
    "fab fa-vine",
    "fab fa-vk",
    "fab fa-vnv",
    "fab fa-vuejs",
    "fab fa-watchman-monitoring",
    "fab fa-waze",
    "fab fa-weebly",
    "fab fa-weibo",
    "fab fa-weixin",
    "fab fa-whatsapp",
    "fab fa-whatsapp-square",
    "fab fa-whmcs",
    "fab fa-wikipedia-w",
    "fab fa-windows",
    "fab fa-wix",
    "fab fa-wizards-of-the-coast",
    "fab fa-wodu",
    "fab fa-wolf-pack-battalion",
    "fab fa-wordpress",
    "fab fa-wordpress-simple",
    "fab fa-wpbeginner",
    "fab fa-wpexplorer",
    "fab fa-wpforms",
    "fab fa-wpressr",
    "fab fa-xbox",
    "fab fa-xing",
    "fab fa-xing-square",
    "fab fa-y-combinator",
    "fab fa-yahoo",
    "fab fa-yammer",
    "fab fa-yandex",
    "fab fa-yandex-international",
    "fab fa-yarn",
    "fab fa-yelp",
    "fab fa-yoast",
    "fab fa-youtube",
    "fab fa-youtube-square",
    "fab fa-zhihu",
];
