import "./edit-local-filter.scss";

import {cx} from "emotion";
import * as React from "react";

import {UseState} from "@common/react/use-state";
import {LeftPanelClosable} from "../../../../../../../common/left-panel-closable/left-panel-closable";
import {cs} from "@common/react/chain-services";
import {Expandable} from "../../../../../../../common/expandable/expandable";
import {SelectFilterType} from "../../../../../../common/filters/filter-left-panel/select-filter-type";
import {localFilterDefaultValues} from "../local-filter-default-values";
import {NumberAggregationFunctionSelect} from "../../../../../../common/number-aggregation-function-select/number-aggregation-function-select";
import {scope} from "@common/react/scope";
import {LocalFilterOptions} from "./options/local-filter-options";
import {Form2} from "@common/react/cs-form/form2";
import {localFilterFormConfig} from "./local-filter-form-config";
import {ConfirmLeaveDialog} from "./confirm-leave-dialog/confirm-leave-dialog";
import {TextInput} from "../../../../../../../../../../common/form/text-input/text-input";
import {ffToTextInput} from "../../../../../../../../../../common/form/ff-to-text-input";
import {equalDeep} from "@common/utils/objects";
import {AggregationFieldSelect, FieldSelect} from "../../../../../../common/filters/filter-left-panel/field-select";
import {SuspendUpdate} from "@common/react/suspend-update";
import {ConfigFieldOverride} from "../../../../../../common/filters/filter-left-panel/config-field-override";
import {Button} from "../../../../../../../../../../common/form/buttons/button/button";
import {Watch} from "@common/react/watch";

export const EditLocalFilter = ({tile, initFilter, onCancel, onDone, onDelete, tileType}) =>
    cs(
        ["initFilterState", (_, next) => UseState({initValue: initFilter, next})],
        ["confirmLeaveDialog", (_, next) => ConfirmLeaveDialog({next})],
        ["filter", (_, next) => UseState({initValue: initFilter, next})],
        ["editFieldOverride", (_, next) => UseState({next})],
        [
            "form",
            ({filter, initFilterState}, next) =>
                Form2({
                    data: filter,
                    ...localFilterFormConfig,
                    onSubmit: async (isClose) => {
                        initFilterState.onChange(filter.value);
                        await onDone(filter.value);
                        if (isClose) onCancel();
                    },
                    next,
                }),
        ],

        ({confirmLeaveDialog, filter, form, initFilterState}, next) => (
            <LeftPanelClosable
                {...{
                    onClose: async () => {
                        if (filter.value === initFilterState.value) {
                            onCancel();
                            return;
                        }
                        const answer = await confirmLeaveDialog.show();
                        if (answer === "save") {
                            form.submit(true);
                        } else if (answer === "discard") {
                            onCancel();
                        } else {
                        }
                    },
                    label: `${initFilter ? "Edit" : "Add"} Tile Filter`,
                    content: next(),
                }}
            />
        ),
        ({filter, form, initFilterState, editFieldOverride}) => (
            <div className="edit-local-filter-2cd">
                {Expandable({
                    label: "Type",
                    alwaysShow: true,
                    initExpand: true,
                    render: () => (
                        <div className="type-52w">
                            {SelectFilterType({
                                filter: filter.value,
                                onChange: (type) => filter.onChange(localFilterDefaultValues(type)),
                            })}

                            {filter.value && (
                                <>
                                    {Watch({
                                        value: filter.value.aggregationFunc,
                                        onChanged: (value) => {
                                            if (value !== "None" && ["InList", "NotInList"].includes(filter.value.defaultOption)) {
                                                filter.change((f) => ({
                                                    ...f,
                                                    defaultOption: null,
                                                }));
                                            }
                                        },
                                    })}

                                    {FieldSelect({
                                        filter,
                                        field: form.field(["columns"]),
                                        // isMultiple: filter.value.$type == "DateFilter"
                                    })}

                                    {/*{filter.value.$type == "DateFilter" && FieldsSelected({filter, editFieldOverride})}*/}

                                    {filter.value.$type === "NumericFilter" && (
                                        <>
                                            {NumberAggregationFunctionSelect({
                                                state: scope(filter, ["aggregationFunc"]),
                                                label: "Field Aggregation Type",
                                            })}
                                            {filter.value.aggregationFunc !== "None" &&
                                                AggregationFieldSelect({
                                                    filter,
                                                    field: form.field(["aggregationField"]),
                                                })}
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    ),
                })}

                {filter.value &&
                    Expandable({
                        label: "Options",
                        render: () => LocalFilterOptions({tile, filter, form, tileType}),
                    })}

                {Expandable({
                    label: "Title",
                    alwaysShow: true,
                    initExpand: true,
                    render: () => (
                        <div className="local-filter-title-78v">
                            {TextInput({
                                label: "Filter Title",
                                ...ffToTextInput(form.field(["label"])),
                            })}
                        </div>
                    ),
                })}

                <div className="control-btns">
                    <div className="button">
                        <Button
                            size="small"
                            disabled={!form.looksValid || equalDeep(initFilterState.value, filter.value)}
                            className="save-apply-btn"
                            onClick={() => form.submit(!initFilter)}
                        >
                            Save & Apply
                        </Button>
                    </div>

                    {filter.value?.id ? (
                        <div className="button">
                            <Button size="small" btnType="danger" className="delete-btn" onClick={onDelete}>
                                Delete Filter
                            </Button>
                        </div>
                    ) : (
                        <div className="button">
                            <Button size="small" btnType="border" className="cancel-btn" onClick={onCancel}>
                                Cancel
                            </Button>
                        </div>
                    )}
                </div>

                <div
                    className={cx("override", {
                        active: editFieldOverride.value,
                    })}
                >
                    {cs(
                        ({}, next) =>
                            SuspendUpdate({
                                active: !editFieldOverride.value,
                                next,
                            }),
                        () =>
                            ConfigFieldOverride({
                                label: editFieldOverride.value?.label,
                                state: {
                                    value: editFieldOverride.value
                                        ? filter.value.columns.find((c) => c.modelColumnID == editFieldOverride.value.modelColumnID)
                                        : {},
                                    onChange: (val) =>
                                        filter.change((filter) => ({
                                            ...filter,
                                            columns: filter.columns.map((col) =>
                                                col.modelColumnID == editFieldOverride.value.modelColumnID ? val : col
                                            ),
                                        })),
                                },
                                onClose: () => editFieldOverride.onChange(null),
                            })
                    )}
                </div>
            </div>
        )
    );
