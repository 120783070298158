import React from "react";
import ReactDOM from "react-dom";
import {cs} from "@common/react/chain-services";
import {CircleRadius, headerHeight} from "../model-panel";
import {ModelPanelHelper} from "../../../common/model-panel-helper";
import {GlobalMouseMove} from "@common/react/global-mouse-move";
import {spc} from "@common/react/state-path-change";
import {getELoc} from "@common/react/get-e-loc";
import {UseState} from "@common/react/use-state";
import {CreateNewRelationshipHelper} from "./create-new-relationship-helper";
import {GlobalKeyDown} from "@common/react/keys/global-key-down";
import {DEFAULT_RELATIONSHIP} from "../model-constrants";

export const CreateNewRelationshipLine = ({interactions, tables, transform, scale, hoverInteractions, dataSources, relationships, next}) =>
    cs(
        ["pos", (_, next) => UseState({next})],
        ["relationshipData", (_, next) => UseState({next})],
        ["startDragging", (_, next) => UseState({next})],
        [
            "getTargetPosition",
            (_, next) =>
                next((pos) => {
                    if (pos) {
                        return {
                            x: (pos.x - interactions?.leftPanelWidth - transform.x) / scale,
                            y: (pos.y - headerHeight - transform.y) / scale,
                            mousePosX: pos.x,
                            mousePosY: pos.y,
                        };
                    }

                    return null;
                }),
        ],
        [
            "getDegrees",
            (_, next) =>
                next((source, target) => {
                    const hypotenuse = Math.sqrt(Math.pow(source.x - target.x, 2) + Math.pow(source.y - target.y, 2));

                    if (source.y > target.y) {
                        const adjacent = target.x - source.x;
                        return (Math.asin(adjacent / hypotenuse) * 180) / Math.PI;
                    } else {
                        if (target.x < source.x) {
                            const adjacent = source.x - target.x;
                            return 180 + (Math.asin(adjacent / hypotenuse) * 180) / Math.PI;
                        }

                        const adjacent = target.y - source.y;
                        return 90 + (Math.asin(adjacent / hypotenuse) * 180) / Math.PI;
                    }
                }),
        ],
        ({getTargetPosition, pos, getDegrees, startDragging}) =>
            next({
                render: () => {
                    if (!(startDragging.value || interactions.setting?.name == "create-new-relationship")) return null;

                    const table = tables.find((t) => t.id == (interactions.setting.data?.table?.id || interactions.setting.data?.tableId));
                    const hoveringTable = interactions.setting.data?.target
                        ? interactions.setting.data?.target
                        : hoverInteractions.value?.tableId && hoverInteractions.value?.tableId != table.id
                        ? tables.find((t) => t.id == hoverInteractions.value.tableId)
                        : null;

                    const targetPos = hoveringTable
                        ? CreateNewRelationshipHelper.getPointWhenHoveringTable({
                              source: table.position,
                              target: hoveringTable.position,
                          })
                        : getTargetPosition(pos.value);
                    const degrees = targetPos ? getDegrees(table.position, targetPos) : 0;

                    const isValidRelationship =
                        interactions.setting.data?.relationship?.leftColumnID && interactions.setting.data?.relationship?.rightColumnID;

                    if (
                        ModelPanelHelper.isHasRelationship({
                            relationship: interactions.setting.data?.relationship,
                            relationships,
                        })
                    )
                        return null;

                    return (
                        <g style={{pointerEvents: "none"}}>
                            <circle
                                cx={table.position.x}
                                cy={table.position.y}
                                r={CircleRadius}
                                fill={ModelPanelHelper.getTableColor({
                                    table,
                                    dataSources,
                                })}
                                strokeWidth={15}
                                stroke={ModelPanelHelper.getTableColor({
                                    table,
                                    dataSources,
                                })}
                                strokeOpacity={0.2}
                            />

                            {hoveringTable && (
                                <circle
                                    cx={hoveringTable.position.x}
                                    cy={hoveringTable.position.y}
                                    r={5}
                                    fill="#fff"
                                    strokeWidth={2}
                                    stroke="#000"
                                />
                            )}

                            {targetPos && (
                                <g style={{pointerEvents: "none"}}>
                                    <image
                                        href={require("./arrow-icon.svg")}
                                        x={targetPos.x - 12}
                                        y={targetPos.y - 12}
                                        transform={`rotate(${degrees} ${targetPos.x} ${targetPos.y}) `}
                                    ></image>

                                    <line
                                        x1={table.position.x}
                                        y1={table.position.y}
                                        x2={targetPos.x}
                                        y2={targetPos.y}
                                        strokeWidth={Math.min(2 / scale, 2)}
                                        stroke="#000"
                                        strokeDasharray={isValidRelationship ? "" : "3 2"}
                                    />
                                </g>
                            )}

                            <circle cx={table.position.x} cy={table.position.y} r={5} fill="#fff" strokeWidth={2} stroke="#000" />

                            {interactions.setting?.name == "create-new-relationship" && !interactions.setting.data.target && (
                                <>
                                    {GlobalMouseMove({
                                        fn: (e) => {
                                            pos.onChange(getELoc(e));
                                        },
                                    })}

                                    {GlobalKeyDown({
                                        keyCombo: "Escape",
                                        onKeyDown: () => {
                                            interactions.deSelectTable();
                                        },
                                    })}
                                </>
                            )}
                        </g>
                    );
                },
                startDrag: ({e}) => {
                    startDragging.onChange(true);
                    pos.onChange(getELoc(e));
                },
                stop: (targetID, data) => {
                    const table = tables.find((t) => t.id == interactions.setting.data.tableId);
                    const targetTable = tables.find((t) => t.id == targetID);
                    if (targetTable && targetTable.id != table.id) {
                        interactions.createNewRelationship({
                            table,
                            target: targetTable,
                            relationship: DEFAULT_RELATIONSHIP,
                            ...data,
                        });
                    }

                    startDragging.onChange(false);
                },
                dragging: (e) => {
                    pos.onChange(getELoc(e));
                },
                startClick: (e) => {
                    pos.onChange(getELoc(e));
                },
            })
    );
