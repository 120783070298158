const {keyed} = require("./keyed");
const {fragments} = require("./fragments");
const {scope} = require("./scope");
const {cs} = require("./chain-services");
const {UseState} = require("./use-state");
const {Invoke} = require("./invoke");
const {Static2} = require("./static-2");

const Load2 = ({fetch, next, disabled, keepOutdatedValue, captureException, _key, isSilentLoading = false}) =>
    cs(["loaded", (_, next) => UseState({next})], ["reloadRef", (_, next) => Static2({next})], ({loaded, reloadRef}) => {
        const loading = loaded.value == null || (isSilentLoading ? false : loaded.value.key !== _key);

        return fragments(
            (loading || isSilentLoading) &&
                !disabled &&
                cs(keyed(_key), () =>
                    Invoke({
                        fn: async ({isMounted}) => {
                            try {
                                const value = await fetch();
                                if (!isMounted()) {
                                    return;
                                }
                                loaded.onChange({value, key: _key});
                            } catch (error) {
                                if (captureException) {
                                    if (!isMounted()) {
                                        return;
                                    }
                                    loaded.onChange({error, key: _key});
                                } else {
                                    throw error;
                                }
                            }
                        },
                    })
                ),

            next?.({
                loading,
                ...scope(loaded, ["value"]),
                value: loading && !keepOutdatedValue ? undefined : loaded.value?.value,
                // value: loading && !keepOutdatedValue ? undefined : loaded.value.value,
                error: loading ? undefined : loaded.value?.error,
                getLoadedState: () => loaded.get(),
                apiError: loaded.value?.error, // Temporary create this because manipulate above 'error' field will affect related existed behaviors
                reload: async () => {
                    const value = await fetch();
                    loaded.onChange({value, key: _key});
                    return value;
                },
            })
        );
    });
exports.Load2 = Load2;
