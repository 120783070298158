import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {RELATIONSHIP_INNER_JOIN, RELATIONSHIP_LEFT_JOIN} from "../../../../main/model-constrants";
import {InnerJoinIcon, LeftOuterJoinIcon, OuterJoinChildCanvas} from "../icons";
import React from "react";

export const JoinTypeDropdown = ({value, onChange}) => (
    <>
        {DropdownSelect({
            className: "select-type",
            list: [
                {label: "Outer Join", value: RELATIONSHIP_LEFT_JOIN},
                {label: "Inner Join", value: RELATIONSHIP_INNER_JOIN},
            ],
            isSelected: (v) => value == v.value,
            label: "Type",
            valueToLabel: (v) => {
                const icon = v.value == RELATIONSHIP_LEFT_JOIN ? <LeftOuterJoinIcon /> : <InnerJoinIcon />;

                return (
                    <div className="join-type-option-a33">
                        {icon} <div>{v.label}</div>
                    </div>
                );
            },
            onChange: (v) => onChange(v.value),
        })}

        {value == RELATIONSHIP_LEFT_JOIN && (
            <>
                <div className="section-description">
                    All values in <div className="circle" /> parent table and matching values in <OuterJoinChildCanvas /> child are
                    combined.
                </div>

                <a
                    className="view-docs"
                    href="https://docs.revivemedia.us/data-access-apis/getting-started/data-model#relationships"
                    target="__blank"
                >
                    Learn more about relationship paths
                </a>
            </>
        )}

        {value == RELATIONSHIP_INNER_JOIN && (
            <div className="section-description">
                All values in both tables are combined.&nbsp;
                <a
                    className="view-docs"
                    href="https://docs.revivemedia.us/data-access-apis/getting-started/data-model#relationships"
                    target="__blank"
                >
                    Learn more about relationship paths
                </a>
            </div>
        )}
    </>
);
