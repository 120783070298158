import * as React from "react";
import {cx} from "emotion";
import {cs} from "@common/react/chain-services";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {spc} from "@common/react/state-path-change";
import "../sorting.scss";
import {consumeContext} from "@common/react/context";
import {Expandable} from "../../../../../../../../../../../common/expandable/expandable";
import {getPath} from "@common/utils/arr-path";
import {FieldInfoProvider} from "../../../../../../tile-tab/chart-types/common/field-info-provider/field-info-provider";
import {sortIconsTypes} from "../sort-icons-types";
import {DnDList} from "../../../../../../../../../../../common/dnd-list/dnd-list";
import {MoveIconSvg} from "../../../../../../../../../../../common/icons/move-icon-svg";
import {TrashIcon, TrashIconBlue} from "../../../../../../../../../../../common/icons/trash-icon";
import {FieldSection} from "../../../field-section/field-section";
import {removeIndex} from "@common/utils/collections";
import {cGetFieldInfoWrtTileFields} from "../../../get-field-info-wrt-tile-fields";
import {Button} from "../../../../../../../../../../../../../../common/form/buttons/button/button";
import {tooltipService4} from "../../../../../../../../../../../common/tooltip3/tooltip-service-3";

export const TableSorting = ({fieldGroups, allFields}) =>
    cs(
        consumeContext("tileFields"),
        consumeContext("getFieldInfo"),
        [
            "tooltip",
            ({theme}, next) =>
                tooltipService4({
                    direction: "above",
                    next,
                }),
        ],
        ({tileFields, getFieldInfo, tooltip}) => {
            const getFieldInfoWrtTileFields = cGetFieldInfoWrtTileFields({
                tileFields,
                getFieldInfo,
            });

            return FieldSection({
                className: "sort-by-8yk",
                header: FieldInfoProvider({
                    field: <div className="text">Sorting</div>,
                }),
                content: (
                    <>
                        {cs(
                            [
                                "rSortByControl",
                                (_, next) =>
                                    next(({ci, onStartDragging, dragging, onRemove}) => {
                                        const sortIcons = sortIconsTypes[getFieldInfoWrtTileFields(fieldGroups.value[ci]).type];

                                        const field = {
                                            value: fieldGroups.value[ci],
                                            onChange: (value) =>
                                                fieldGroups.onChange(
                                                    fieldGroups.value.map((sort, index) => {
                                                        if (index == ci) return value;
                                                        return sort;
                                                    })
                                                ),
                                        };

                                        const aggList = allFields
                                            .filter(
                                                (f) =>
                                                    f.modelColumnID == field.value.modelColumnID && f.modelTableID == field.value.modelTableID && f.modelID == field.value.modelID
                                            )
                                            .map((f) => f.aggregationFunc)
                                            .filter((v) => v);

                                        return (
                                            <div className="sort-by has-background-border dragging">
                                                {onStartDragging && (
                                                    <div
                                                        className={cx("dragging-anchor", {
                                                            dragging,
                                                        })}
                                                        onMouseDown={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            onStartDragging(e);
                                                        }}
                                                    >
                                                        {MoveIconSvg({
                                                            fill: "#546B81",
                                                        })}
                                                    </div>
                                                )}

                                                <div className="dropdown-wrapper">
                                                    {DropdownSelect({
                                                        detectOnWheelEvent: true,
                                                        label: "Sort by",
                                                        list: tileFields.fieldsForSort
                                                            .filter(
                                                                (t) =>
                                                                    fieldGroups.value.map((f1) => f1.modelColumnID).indexOf(t.modelColumnID) === -1 ||
                                                                    t.modelColumnID === fieldGroups.value[ci].modelColumnID
                                                            )
                                                            .map((f) => ({
                                                                ...f,
                                                                label: getFieldInfo(f).visualNameFull,
                                                            })),
                                                        valueToLabel: (v) => v.label,
                                                        isSelected: (v) => v.modelColumnID === getPath(fieldGroups.value, [ci, "modelColumnID"]),
                                                        onChange: (v) =>
                                                            spc(fieldGroups, [ci], (s) => {
                                                                if (!s.direction) {
                                                                    s.direction = "Asc";
                                                                }

                                                                return {
                                                                    ...s,
                                                                    modelID: v.modelID,
                                                                    modelTableID: v.modelTableID,
                                                                    modelColumnID: v.modelColumnID,
                                                                    aggregationFunc: v.aggregationFunc,
                                                                };
                                                            }),
                                                    })}

                                                    {aggList.length > 0 &&
                                                        DropdownSelect({
                                                            detectOnWheelEvent: true,
                                                            label: "Aggregate",
                                                            className: "aggregate-field",
                                                            list: aggList,
                                                            valueToLabel: (v) => v,
                                                            isSelected: (v) => v === getPath(field.value, ["aggregationFunc"]),
                                                            onChange: (v) =>
                                                                field.onChange({
                                                                    ...field.value,
                                                                    aggregationFunc: v,
                                                                }),
                                                        })}
                                                </div>

                                                <div className="sorts">
                                                    <div
                                                        className={cx("asc", {
                                                            selected: getPath(fieldGroups.value, [ci, "direction"]) === "Asc",
                                                        })}
                                                        onClick={() => spc(fieldGroups, [ci, "direction"], (sort) => "Asc")}
                                                        {...tooltip(() => "Ascending")}
                                                    >
                                                        {sortIcons?.asc}
                                                    </div>

                                                    <div
                                                        className={cx("desc", {
                                                            selected: getPath(fieldGroups.value, [ci, "direction"]) === "Desc",
                                                        })}
                                                        onClick={() => spc(fieldGroups, [ci, "direction"], (sort) => "Desc")}
                                                        {...tooltip(() => "Descending")}
                                                    >
                                                        {sortIcons?.desc}
                                                    </div>
                                                </div>

                                                {onRemove && (
                                                    <div className="delete-btn" onClick={onRemove}>
                                                        {TrashIconBlue()}
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    }),
                            ],
                            [
                                "dnd",
                                ({rSortByControl}, next) =>
                                    DnDList({
                                        list: fieldGroups.value,
                                        onChange: (newFields) => spc(fieldGroups, [], () => newFields),
                                        renderDrag: ({index: ci}) => (
                                            <div className="sort-by-section">
                                                {rSortByControl({
                                                    ci,
                                                    dragging: true,
                                                    onStartDragging: () => {},
                                                })}
                                            </div>
                                        ),
                                        next,
                                    }),
                            ],
                            ({dnd, rSortByControl}) =>
                                dnd.list?.map(({index: ci, ref, start}) =>
                                    ci != null ? (
                                        <div className="sort-by-section" ref={ref} key={"sort-by" + ci}>
                                            {rSortByControl({
                                                ci,
                                                onStartDragging: start,
                                                onRemove: () => fieldGroups.onChange(removeIndex(ci, fieldGroups.value)),
                                            })}
                                        </div>
                                    ) : (
                                        <div className="sort-by-section-blank" />
                                    )
                                )
                        )}

                        {tileFields.fieldsForSort.length > fieldGroups.value.length && (
                            <Button size="tiny" btnType="secondary" className="add-field-btn" onClick={() => spc(fieldGroups, [], (groups) => [...groups, {}])}>
                                Add Sort Field
                            </Button>
                        )}
                    </>
                ),
            });
        }
    );
