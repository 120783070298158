import "./interaction-chart.scss";
import React from "react";
import {DateTime} from "luxon";
import {cs} from "@common/react/chain-services";
import {getRandomInt} from "@common/utils/random";
import {cx} from "emotion";
import {simpleLinearScale} from "./utils";
import {max, min} from "@common/utils/math-util";
import moment from "moment";
import {last} from "@common/utils/collections";

export const InteractionChart = ({dateRange, tablesHistories, data, prevData}) =>
    cs(
        // (_, next) => data.length === 0 ? InteractionChartNoData({}) : next(),
        () => {
            const {endTime, startTime, currentTime, syncStatuses, updates, isSameDay, lastEndedUtc} = genChartData({
                oriData: tablesHistories?.synchronizations,
                dateRange,
                data,
                prevData,
            });

            let maxDiff = Math.floor(endTime) - startTime;

            const bars = (() => {
                let bars = [];

                const maps = {
                    [SYNC_STATUSES.ERROR]: "error",
                    [SYNC_STATUSES.PAUSE]: "paused",
                    [SYNC_STATUSES.SYNC]: "sync",
                };

                if (syncStatuses.length > 0) {
                    let status = maps[syncStatuses[0].status];
                    let timestamp = syncStatuses[0].timestamp;

                    for (let i = 0; i < syncStatuses.length; i++) {
                        let syncStatus = syncStatuses[i];

                        if (status !== maps[syncStatus.status]) {
                            const diff = syncStatuses[i - 1].endedTimestamp - timestamp;

                            bars.push({
                                timestamp: timestamp,
                                type: status,
                                width: (diff / maxDiff) * 100,
                            });

                            status = maps[syncStatus.status];
                            timestamp = syncStatus.timestamp;

                            if (i === syncStatuses.length - 1) {
                                bars.push({
                                    timestamp: timestamp,
                                    type: status,
                                    width: ((syncStatus.endedTimestamp - timestamp) / maxDiff) * 100,
                                });
                            }
                        } else if (i === syncStatuses.length - 1) {
                            bars.push({
                                timestamp: timestamp,
                                type: status,
                                width: ((syncStatus.endedTimestamp - timestamp) / maxDiff) * 100,
                            });
                        }
                    }
                } else {
                    // bars = [
                    //     {
                    //         timestamp: startTime,
                    //         type: tablesHistories.paused ? maps[SYNC_STATUSES.PAUSE] : tablesHistories.errors.length > 0 ? maps[SYNC_STATUSES.ERROR] : maps[SYNC_STATUSES.SYNC],
                    //         width: ((lastEndedUtc - startTime) / maxDiff) * 100,
                    //     },
                    // ];
                }
                return bars;
            })();

            const minValue = min(updates.filter((t) => t.records > 0).map((i) => i.records));
            const maxValue = max(updates.map((i) => i.records));

            const scale = simpleLinearScale([6, 30], [minValue, maxValue]);
            const getPosition = (timestamp) => ((timestamp - startTime) / maxDiff) * 100;

            return (
                <div className="interaction-chart-99r">
                    <div className="progress">
                        {bars.map((b, index) => (
                            <div
                                key={index}
                                className={cx("bar", b.type)}
                                style={{
                                    left: `${getPosition(b.timestamp)}%`,
                                    width: `${b.width}%`,
                                }}
                            />
                        ))}
                    </div>
                    <div className="intervals">
                        {Array.from(Array(25)).map((_, i) => (
                            <div key={i} className="interval" />
                        ))}
                    </div>
                    <div className="intervals-time">
                        {[
                            {time: 12, unit: "a"},
                            ...Array.from(Array(5)).map((_, i) => ({
                                time: 2 * (i + 1),
                                unit: "a",
                            })),
                            {time: 12, unit: "p"},
                            ...Array.from(Array(5)).map((_, i) => ({
                                time: 2 * (i + 1),
                                unit: "p",
                            })),
                        ].map((item, key) => (
                            <div key={key}>
                                {item.time} {item.unit}
                            </div>
                        ))}
                    </div>
                    <div className="updates">
                        {updates.map((item) => {
                            if (item.records === 0) {
                                return (
                                    <div
                                        key={item.timestamp}
                                        className={cx("small-dot", {
                                            paused: item.status === SYNC_STATUSES.PAUSE,
                                            error: item.status === SYNC_STATUSES.ERROR,
                                        })}
                                        style={{
                                            left: `${getPosition(item.timestamp)}%`,
                                        }}
                                    />
                                );
                            }

                            return (
                                <div
                                    key={item.timestamp}
                                    className="records"
                                    style={{
                                        left: `${getPosition(item.timestamp)}%`,
                                        height: scale(item.records),
                                    }}
                                />
                            );
                        })}
                        {tablesHistories.nextSync &&
                            !tablesHistories.paused &&
                            tablesHistories.errors.length == 0 &&
                            dateRange.start.isSame(new Date(tablesHistories.nextSync), "day") && (
                                <div
                                    className="current"
                                    style={{
                                        left: `${getPosition(new Date(tablesHistories.nextSync).getTime() / 1000)}%`,
                                    }}
                                />
                            )}
                    </div>
                </div>
            );
        }
    );

export const InteractionChartNoData = ({}) =>
    cs(() => {
        return (
            <div className="interaction-chart-99r">
                <div className="progress">
                    <div className="bar" style={{width: "100%", background: "#E9ECEF"}} />
                </div>
                <div className="intervals">
                    {Array.from(Array(25)).map((_, i) => (
                        <div key={i} className="interval" />
                    ))}
                </div>
                <div className="intervals-time">
                    {[
                        {time: 12, unit: "a"},
                        ...Array.from(Array(5)).map((_, i) => ({
                            time: 2 * (i + 1),
                            unit: "a",
                        })),
                        {time: 12, unit: "p"},
                        ...Array.from(Array(5)).map((_, i) => ({
                            time: 2 * (i + 1),
                            unit: "p",
                        })),
                    ].map((item, key) => (
                        <div key={key}>
                            {item.time} {item.unit}
                        </div>
                    ))}
                </div>
            </div>
        );
    });

const SYNC_STATUSES = {
    ERROR: -1,
    PAUSE: 0,
    SYNC: 1,
};

function genChartData({dateRange, data, prevData, oriData}) {
    const startTime = dateRange.start.startOf("day");
    const endTime = dateRange.end.endOf("day");

    const utcStartTime = startTime.clone().utc().format();
    const utcEndTime = endTime.clone().utc().format();

    const isSameDay = moment().isSame(startTime, "day");
    const current = isSameDay ? moment().valueOf() / 1000 : null;
    // const lastEndedUtc = last(data)
    //     ? moment(new Date(last(data).endedUtc)).valueOf() / 1000
    //     : isSameDay
    //     ? current
    //     : endTime.valueOf() / 1000;

    const formatData = (data) =>
        (data || [])?.reduce((prevResult, d, index) => {
            const newFormattedItem = {
                startedUtc: d.startedUtc,
                endedUtc: d.endedUtc,
                timestamp: moment(new Date(d.startedUtc)).valueOf() / 1000,
                endedTimestamp: moment(new Date(d.endedUtc)).valueOf() / 1000,
                statusStr: d.status,
                status:
                    d.status === "Success" || d.status === "Sync"
                        ? SYNC_STATUSES.SYNC
                        : d.status === "Error" || d.status === "ErrorsCleared"
                        ? SYNC_STATUSES.ERROR
                        : d.status === "Paused"
                        ? SYNC_STATUSES.PAUSE
                        : null,
            };

            let addonItem = null;
            if (d.status === "ErrorsCleared") {
                const nextItem = data[index + 1];
                addonItem = [
                    {
                        startedUtc: d.endedUtc,
                        endedUtc: nextItem ? nextItem.startedUtc : isSameDay ? moment().utc().format() : utcEndTime,
                        timestamp: moment(new Date(d.endedUtc)).valueOf() / 1000,
                        endedTimestamp: nextItem
                            ? moment(new Date(nextItem.startedUtc)).valueOf() / 1000
                            : isSameDay
                            ? moment().valueOf() / 1000
                            : moment(new Date(utcEndTime)).valueOf() / 1000,
                        statusStr: "Success",
                        status: SYNC_STATUSES.SYNC,
                    },
                ];
            }
            prevResult = [...prevResult, newFormattedItem, ...(addonItem ?? [])];
            return prevResult;
        }, []);
    let rootSyncStatues = formatData(data);
    let prevSyncStatues = formatData(prevData);

    const lastSyncS = rootSyncStatues[rootSyncStatues.length - 1];

    let syncStatuses = [
        ...(rootSyncStatues[0] && prevSyncStatues.length > 0
            ? [
                  {
                      startedUtc: utcStartTime,
                      endedUtc: rootSyncStatues[0]?.startedUtc,
                      timestamp: moment(new Date(utcStartTime)).valueOf() / 1000,
                      endedTimestamp: rootSyncStatues[0]?.timestamp,
                      status: prevSyncStatues.length > 0 ? last(prevSyncStatues).status : rootSyncStatues[0]?.status,
                  },
              ]
            : []),
        ...rootSyncStatues.map((item, i) => {
            if (i != rootSyncStatues.length - 1) {
                return {
                    ...item,
                    endedTimestamp: rootSyncStatues[i + 1].timestamp,
                };
            }
            return item;
        }),
        ...(lastSyncS && lastSyncS.endedUtc !== (isSameDay ? moment().utc().format() : utcEndTime)
            ? [
                  {
                      startedUtc: lastSyncS.endedUtc,
                      endedUtc: isSameDay ? moment().utc().format() : utcEndTime,
                      timestamp: moment(new Date(lastSyncS.endedUtc)).valueOf() / 1000,
                      endedTimestamp: isSameDay ? moment().valueOf() / 1000 : moment(new Date(utcEndTime)).valueOf() / 1000,
                      status: lastSyncS.status,
                  },
              ]
            : []),
    ];

    let updates = [];

    let maxChangeRecords = 4000;

    const lastEndedUtc = last(syncStatuses) ? moment(new Date(last(syncStatuses).endedUtc)).valueOf() / 1000 : isSameDay ? current : endTime.valueOf() / 1000;

    console.log(rootSyncStatues);

    for (let i = 0; i < rootSyncStatues.length; i++) {
        let syncStatus = rootSyncStatues[i];

        if (syncStatus.status === SYNC_STATUSES.SYNC) {
            const endTime2 = Math.floor(Math.min(lastEndedUtc, current ?? lastEndedUtc));

            let numTick =
                // i === rootSyncStatues.length - 1 ? endTime2 - syncStatus.timestamp :
                (syncStatus.endedTimestamp ?? endTime2) - syncStatus.timestamp;

            const reduceNum = 1000;

            if (oriData.length > 0) {
                updates = updates.concat(
                    Array(1)
                        .fill(0)
                        .map((_, index) => {
                            let _timestamp = syncStatus.timestamp + index * 60;
                            return {
                                records: data
                                    .filter((d) => {
                                        const seconds = new Date(d.startedUtc).getTime() / 1000;
                                        return seconds <= _timestamp && seconds >= syncStatus.timestamp;
                                    })
                                    .reduce((prev, item) => (prev += item.records), 0),
                                timestamp: _timestamp,
                                status: syncStatus.status,
                            };
                        })
                );
            }
        }
    }

    return {
        startTime: startTime.valueOf() / 1000,
        endTime: endTime.valueOf() / 1000,
        currentTime: current,
        lastEndedUtc,
        isSameDay,
        syncStatuses,
        updates,
    };
}
