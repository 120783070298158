import React from "react";
import {cs} from "@common/react/chain-services";
import {TextInput} from "../../../../../../../../../common/form/text-input/text-input";
import {ffToTextInput} from "../../../../../../../../../common/form/ff-to-text-input";
import {Form2} from "@common/react/cs-form/form2";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {ffToDropdown} from "../../../../../../../../../common/form/ff-to-dropdown";
import {dataSourceFormConfig} from "../../../../../common/data-source-form-config";
import {consumeContext} from "@common/react/context";
import {ConnectionSettingsLayout} from "../connection-settings-layout";
import {tooltipService3} from "../../../../../../common/tooltip3/tooltip-service-3";
import {Button} from "../../../../../../../../../common/form/buttons/button/button";
import {DsFolderField} from "../../../../../common/folders-dialog/ds-folder-field";
import {cx} from "emotion";
const {required} = require("@common/react/cs-form/validators/required");

export const AmazonDSPConnectionForm = ({data, adding, dsApi, connectionStatus, formConfig, next}) =>
    cs(
        consumeContext("apis"),
        [
            "form",
            ({apis}, next) =>
                Form2({
                    data,
                    fields: {
                        ...formConfig,
                        "connectionDetails.refreshToken": [required],
                        "connectionDetails.clientID": [required],
                        "connectionDetails.clientSecret": [required],
                        "connectionDetails.profileIDs": [],
                    },
                    next,
                }),
        ],
        [
            "testConnection",
            ({form}, next) =>
                next(() => {
                    const _connectionDetails = form.data.value?.connectionDetails;
                    const databaseConfigData = {
                        $type: data.value.$type,
                        ..._connectionDetails,
                    };

                    return dsApi.testConnection(
                        {
                            submitConnectionDetails: databaseConfigData,
                            connectionDetails: databaseConfigData,
                        },
                        {
                            type: data.value?.type,
                            name: form.data.value?.name,
                        }
                    );
                }),
        ],
        ({form, testConnection}) => {
            return next({
                valid: form.valid,
                beforeGoNext: testConnection,
                render: () =>
                    ConnectionSettingsLayout({
                        type: data.value.type,
                        adding,
                        connectionStatus,
                        controls: {
                            valid: form.valid,
                            onTestConnection: testConnection,
                            onDiscard: () =>
                                form.data.onChange({
                                    ...data.value,
                                    connectionDetails: {},
                                }),
                            onGoNext: dsApi.onNextStep,
                        },
                        content: (
                            <>
                                {TextInput({
                                    label: "Display Name",
                                    ...ffToTextInput(form.field("name")),
                                })}

                                {DsFolderField({
                                    state: form.field("folderID").state,
                                })}

                                <div className="form-group-flex">
                                    {TextInput({
                                        label: "Refresh Token",
                                        placeholder: "Atzr|IwEBIGVD...",
                                        ...ffToTextInput(form.field("connectionDetails.refreshToken")),
                                    })}
                                </div>
                                <div className="form-group-flex">
                                    {TextInput({
                                        label: "Client ID",
                                        placeholder: "amzn1.application-oa2-client...",
                                        ...ffToTextInput(form.field("connectionDetails.clientID")),
                                    })}

                                    {TextInput({
                                        label: "Client Secret",
                                        placeholder: "123456789123456789...",
                                        ...ffToTextInput(form.field("connectionDetails.clientSecret")),
                                    })}
                                </div>
                                <div className="form-group-flex">
                                    {TextInput({
                                        label: "Profile IDs",
                                        placeholder: "1234567890,2345678901,3456789012...",
                                        ...ffToTextInput(form.field("connectionDetails.profileIDs")),
                                    })}
                                </div>

                                {/*<div className="buttons">*/}
                                {/*    {cs(*/}
                                {/*        tooltipService3({*/}
                                {/*            direction: "above",*/}
                                {/*            tooltipContentStyle: {*/}
                                {/*                width: 150*/}
                                {/*            }*/}
                                {/*        }),*/}
                                {/*        ({tooltip}) => (*/}
                                {/*            <Button*/}
                                {/*                btnType="secondary"*/}
                                {/*                size="small"*/}
                                {/*                {...!form.valid ? tooltip(() => `All required fields must be filled before testing the connection`) : {}}*/}
                                {/*                className={cx({disabled: !form.valid    })}*/}
                                {/*                onClick={() => form.valid && testConnection()}*/}
                                {/*            >Test Connection</Button>*/}
                                {/*        )*/}
                                {/*    )}*/}
                                {/*    */}
                                {/*    <Button btnType="danger"*/}
                                {/*            size="small"*/}
                                {/*        onClick={() => form.data.onChange({ ...data.value, connectionDetails: {} })}*/}
                                {/*    >*/}
                                {/*        Discard Database</Button>*/}
                                {/*</div>*/}
                            </>
                        ),
                    }),
            });
        }
    );
