import {themeCool} from "./themes-base/theme-cool";
import {themeWarm} from "./themes-base/theme-warm";
import {themeDark} from "./themes-base/theme-dark";

const mergeTheme = (dst, src) => {
    Object.keys(src).forEach((key) => {
        if (dst[key] == null) {
            dst[key] = src[key];
        } else if (typeof src[key] === "object" && src[key] !== null && typeof dst[key] === "object" && dst[key] !== null) {
            mergeTheme(dst[key], src[key]);
        }
    });
};

const baseThemes = {
    Cool: themeCool,
    Warm: themeWarm,
    Dark: themeDark,
};

export const initTheme = (theme) => {
    mergeTheme(theme || {}, baseThemes[theme?.baseTheme] || themeCool);
    return theme;
};
