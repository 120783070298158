import React from "react";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {TextInput} from "../../../../../../../../common/form/text-input/text-input";
import {DebounceCache} from "@common/react/debounce-cache";
import "./editable-label.scss";
import {Static2} from "@common/react/static-2";
import {keyed} from "@common/react/keyed";
import {Invoke} from "@common/react/invoke";
import {waitTimeout} from "@common/utils/wait-timeout";

export const EditableLabel = ({label, action}) =>
    cs(
        ["editing", (_, next) => UseState({next})],
        ["inputRef", (_, next) => Static2({next})],
        ["textLength", (_, next) => UseState({next})],
        [
            "debounce",
            (_, next) =>
                DebounceCache({
                    state: label,
                    next,
                }),
        ],
        ({editing, inputRef, textLength, debounce}) => {
            const onStartEdit = () => {
                editing.onChange(true);
                const inputElem = inputRef.get()?.getElementsByTagName("input")[0];
                inputElem.focus();
                inputElem.select();
            };

            const getTextWidth = (text) => {
                const inputElem = inputRef.get()?.getElementsByTagName("input")[0];
                const fontSize = +getComputedStyle(inputElem).getPropertyValue("font-size").replace("px", "");
                textLength.onChange(Math.max((text?.length ?? 0) * fontSize * 0.65, 100));
            };

            return (
                <>
                    <div className="editable-label-sd2 editable-label">
                        <div
                            className="input-label"
                            style={{
                                width: 180,
                                paddingRight: 25,
                            }}
                        >
                            {TextInput({
                                className: !editing.value ? "inactive" : "active",
                                state: debounce.state,
                                domRef: inputRef.set,
                                onBlur: () => {
                                    debounce.flush();
                                    editing.onChange(false);
                                },
                            })}

                            {action.render({params: {onEdit: onStartEdit}})}

                            {/*{!editing.value && textLength.value != null && (*/}
                            {/*    <div className="edit-icon">*/}
                            {/*        <img*/}
                            {/*            src={require("../../../common/icons/edit-icon.svg")}*/}
                            {/*            alt=""*/}
                            {/*            onClick={onStartEdit}*/}
                            {/*        />*/}
                            {/*    </div>*/}
                            {/*)}*/}
                        </div>
                    </div>

                    {cs(keyed(debounce.state.value), ({}) =>
                        Invoke({
                            fn: async ({isMounted}) => {
                                await waitTimeout(150);
                                if (isMounted()) {
                                    getTextWidth(debounce.state.value);
                                }
                            },
                        })
                    )}
                </>
            );
        }
    );
