import * as React from "react";

import {DialogService} from "../../../../../../common/dialog/dialog-service";
import {cs} from "@common/react/chain-services";
import {Button} from "../../../../../../../../../common/form/buttons/button/button";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";

export const ConfirmLeaveDialog = ({next: rootNext}) =>
    cs(
        [
            "modal",
            ({}, next) =>
                DialogService({
                    registryRender: true,
                    render: ({resolve}) => ({
                        title: "Save Changes?",
                        width: 482,
                        content: next({
                            resolve,
                        }),
                    }),
                    next: rootNext,
                }),
        ],
        ({modal}) => (
            <div className="confirm-leave-dialog-5w2">
                <VerbDialogBodyScrollbar>
                    <div className="messages">
                        <div className="message">You have unsaved changes to the filter you're currently configuring.</div>
                        <div className="message">Do you want to attempt to save your changes or discard them?</div>
                    </div>
                </VerbDialogBodyScrollbar>

                <div className="buttons">
                    <Button btnType="secondary" onClick={() => modal.resolve("discard")}>
                        Discard
                    </Button>
                    <Button onClick={() => modal.resolve("save")}>Save</Button>
                </div>
            </div>
        )
    );
