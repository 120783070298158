import {IconDownSvgStr, IconUnchangedSvgStr, IconUpSvgStr} from "../../../pie/chart-options/icons-svg-string";
import {lighten} from "../../../../../utils/color-util";

export const getDifference = ({value, compareValue, theme, positiveChangeGood = true}) => {
    const diff = value - (compareValue || 0);
    const state = diff === 0 ? "unchanged" : diff > 0 ? "up" : "down";

    const goodColor = theme.dataVisualization.dataColorPalettes.otherColors.conditionalGoodColorRGB || "#18C96E";
    const badColor = theme.dataVisualization.dataColorPalettes.otherColors.conditionalBadColorRGB || "#E95A5A";

    const isGood = (positiveChangeGood && diff > 0) || (!positiveChangeGood && diff < 0);
    const isBad = (positiveChangeGood && diff < 0) || (!positiveChangeGood && diff > 0);

    const stateColor = diff === 0 ? lighten("#000000", 50) : isGood ? goodColor : badColor;

    const percentDiff = diff === 0 ? 0 : !compareValue ? 100 : (Math.abs(diff) / compareValue) * 100;
    const diffIcon = {
        unchanged: IconUnchangedSvgStr(),
        up: IconUpSvgStr({fill: stateColor}),
        down: IconDownSvgStr({fill: stateColor}),
    }[state];

    return {
        diff: Math.abs(diff),
        percentDiff,
        diffIcon,
        state,
        stateColor,
    };
};
