import * as React from "react";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import "./theme-preview.scss";
import {LiveDashboard} from "@common/ui-components/live/live-dashboard/live-dashboard";
import {LoadingIndicator} from "@common/ui-components/loading-indicator/loading-indicator";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";

export const ThemePreview = ({theme}) =>
    cs(
        consumeContext("apis"),
        consumeContext("routing"),
        consumeContext("collection"),
        ({}, next) => (
            <VerbScrollbar
                className="theme-preview-2es"
                style={{
                    background: theme?.general.canvas.backgroundColorRGB ?? "white",
                }}
            >
                {next()}
            </VerbScrollbar>
        ),
        ({collection}) =>
            !theme
                ? LoadingIndicator()
                : LiveDashboard({
                      collection: collection.value,
                      theme,
                      forceAutoRefresh: true,
                  })
    );
