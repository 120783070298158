import {fragments} from "../../../../react/fragments";
import {cs} from "../../../../react/chain-services";
import {Invoke} from "../../../../react/invoke";
import {UseState} from "../../../../react/use-state";
import {keyed, keyed1} from "../../../../react/keyed";
import {chain} from "../../../../utils/fs";
import {sort} from "../../../../utils/collections";
import {spc} from "../../../../react/state-path-change";

export const MultiPageLoader = ({_key, getTotalPageCount, load, next}) =>
    cs(
        [
            "state",
            (_, next) =>
                UseState({
                    next,
                }),
        ],
        ({state}) => {
            const {requestedPages, totalPageCount, pages, error} = chain(
                state.value,
                (_) => (_?._key === _key ? _ : {}),
                (_) => {
                    const totalPageCount = !_?.pages?.length ? undefined : getTotalPageCount(_.pages[0].data);
                    return {
                        ..._,
                        totalPageCount,
                        requestedPages: totalPageCount == null ? _?.requestedPages : _.requestedPages?.filter((pageIndex) => pageIndex < totalPageCount),
                    };
                },
                (_) => ({
                    ..._,
                    requestedPages: _.requestedPages ?? [0],
                })
            );

            if (error) {
                return next({
                    error,
                });
            }

            // console.log(requestedPages)
            const loadingPages = requestedPages.filter((pi) => !pages?.find((p) => p.pageIndex === pi));

            const fullyLoaded = pages?.length && totalPageCount === pages.length;

            const loadingFull = state.value == null || state.value._key !== _key || loadingPages.length === requestedPages.length;

            return fragments(
                next({
                    loadingFull,
                    loadingPartial: !!loadingPages.length,
                    fullyLoaded,
                    loadMore: () => !fullyLoaded && spc(state, ["requestedPages"], () => [...requestedPages, Math.max(...requestedPages) + 1]),
                    pages:
                        pages &&
                        chain(
                            pages,
                            (_) => sort(_, (p) => p.pageIndex),
                            (_) => _.map((p) => p.data)
                        ),
                }),

                state.value &&
                    state.value._key !== _key &&
                    Invoke({
                        fn: () => {
                            state.onChange(null);
                        },
                    }),

                cs(
                    keyed(_key),
                    ["pageIndex", (_, next) => loadingPages.map(next)],
                    ({pageIndex}, next) => keyed1({key: pageIndex, next}),

                    ({pageIndex}) =>
                        Invoke({
                            fn: async ({isMounted}) => {
                                try {
                                    const data = await load(pageIndex);
                                    // console.log(data)
                                    if (isMounted()) {
                                        state.change((s) => ({
                                            ...(s && _key === s._key
                                                ? {
                                                      ...s,
                                                  }
                                                : {
                                                      _key,
                                                  }),
                                            pages: [
                                                ...(s?.pages?.filter((p) => p.pageIndex !== pageIndex) ?? []),
                                                {
                                                    pageIndex,
                                                    data,
                                                },
                                            ],
                                        }));
                                    }
                                } catch (error) {
                                    state.onChange({_key, error});
                                }
                            },
                        })
                )
            );
        }
    );
