import {cs} from "@common/react/chain-services";
import {Static2} from "@common/react/static-2";
import {ThemeLeftPanelSections} from "../../../common/theme-left-panel-sections/theme-left-panel-sections";
import {DiscreteColorsTheme} from "./discrete-colors/discrete-colors-theme";
import {ColorSchemes} from "./color-schemes/color-schemes";
import {KpiColorsTheme} from "./kpi-colors/kpi-colors-theme";
import {OtherColorsTheme} from "./other-colors/other-colors-theme";

export const ColorPalettesTheme = ({form, overrideLeftPanelRef, viewTree}) =>
    cs(
        ["ref", (_, next) => Static2({next})],
        [
            "sections",
            ({ref}, next) =>
                next([
                    {
                        label: "Discrete colors",
                        render: (form) => <DiscreteColorsTheme form={form} key="discrete" />,
                        id: "discrete-colors",
                    },
                    {
                        label: "Color schemes",
                        render: (form) => <ColorSchemes key="color-schemes" form={form} overrideLeftPanelRef={ref} />,
                        id: "color-schemes",
                    },
                    {
                        label: "KPI Colors",
                        render: (form) => <KpiColorsTheme key="kpi" form={form} />,
                        id: "kpi-colors",
                    },
                    {
                        label: "Other Colors",
                        render: (form) => <OtherColorsTheme key="other-colors" form={form} />,
                        id: "other-colors",
                    },
                ]),
        ],
        ({sections, ref}) =>
            ThemeLeftPanelSections({
                sections,
                overrideLeftPanelRef,
                parentRef: ref,
                form,
                viewTree,
            })
    );
