import {getTooltipFontSizes} from "./get-tooltip-font-sizes";
import {getIndicatorShapes} from "./indicator-shapes";

export const getPiePointTable = ({tile, extractedPoint, formatters, theme}) => {
    const rPoint = () => {
        const fontSizes = getTooltipFontSizes(theme);
        const indicatorShapes = getIndicatorShapes(theme);
        return `<div class="point table-row first-point" style="font-size: ${fontSizes.medium}px">
                <div class="name">
                    <span class="indicator" style="color: ${extractedPoint.color}">${indicatorShapes["bar"]}</span>
                    ${formatters.groupFieldFormatter(extractedPoint.dimCategory)}
                </div>
                <div class="main-value">
                    ${formatters.valueFormatter(extractedPoint.value || 0)}
                    <span class="percent" style="font-size: ${fontSizes.small}px">(${formatters.percentFormatter(extractedPoint.percentage || 0)})</span>                   
                </div>
            </div>`;
    };

    return `${rPoint()}`;
};
