import * as React from "react";
import {ShowHideToggle} from "../../../../../../../../../../../../../common/form/toggles/show-hide-toggle";
import "./y-axis.scss";
import {LpLine} from "../../../../../../../../../../common/left-panel/line/lp-line";
import {scope} from "@common/react/scope";
import {OrientationSelect} from "../../../common/orientation-select";
import {MaxValInView} from "../../../common/max-val-in-view/max-val-in-view";
import {AxisTitle} from "../../../combo-chart/panels/x-axis/x-axis-rules-comp";
import {YAxisLabel, YGridStepLabel} from "./y-axis-rules-comp";
import {getPath} from "@common/utils/arr-path";
import {XAxisDateGapsSelect} from "../../../common/x-axis-date-gaps-select";
import {getFieldType} from "@common/ui-components/charts/common/get-field-type";

export const YAxis = ({tile, size}) => ({
    label: "Y-Axis",
    render: () => {
        return (
            <div className="horizontal-y-axis-2qp">
                <div className="subsection">
                    <div className="content">
                        {AxisTitle({
                            tile,
                            size,
                            state: scope(tile, ["style", "yAxis", "label"]),
                        })}
                    </div>
                </div>

                <div className="subsection">
                    {YAxisLabel({
                        tile,
                        size,
                        state: scope(tile, ["style", "yAxis", "labelShown"]),
                    })}
                </div>

                {/*{scope(tile, ["style", "yAxis", "labelShown"]).value && <>*/}
                {/*    <div className="subsection">*/}
                {/*        <div className="header">*/}
                {/*            Axis Label Info Icon*/}
                {/*            <div className="control">*/}
                {/*                {ShowHideToggle({state: scope(tile, ["style", "yAxis", "showInfoIcon"])})}*/}
                {/*            </div>*/}
                {/*        </div>*/}

                {/*        {tile.value.style?.yAxis.showInfoIcon && (*/}
                {/*            <div className="content">*/}
                {/*                {TextareaInput({state: scope(tile, ["style", "yAxis", "infoIconText"])})}*/}
                {/*            </div>*/}
                {/*        )}*/}
                {/*    </div>*/}
                {/*</>}*/}

                {/*{!tile.value.groupField && (*/}
                {/*    <div className="subsection grid-step">*/}
                {/*        <div className="header">*/}
                {/*            Grid Step*/}
                {/*        </div>*/}
                {/*        <div className="content">*/}
                {/*            {cs(*/}
                {/*                consumeContext("getFieldInfo"),*/}
                {/*                ({getFieldInfo}) => GridStepTypeSelect({*/}
                {/*                    state: scope(tile, ["style", "yAxis"]),*/}
                {/*                    fieldType: getFieldInfo(tile.value.yAxisField).type,*/}
                {/*                })*/}
                {/*            )}*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*)}*/}

                <div className="subsection">
                    {YGridStepLabel({
                        tile,
                        size,
                        state: scope(tile, ["style", "yAxis", "gridStepLabelsShown"]),
                    })}

                    {getPath(tile.value, ["style", "yAxis", "gridStepLabelsShown"]) && (
                        <div className="content">{OrientationSelect(scope(tile, ["style", "yAxis", "gridStepLabelOrientation"]))}</div>
                    )}
                </div>

                <div className="subsection">
                    <div className="header">Other Options</div>
                    <div className="content">
                        {MaxValInView({state: scope(tile, ["style", "yAxis"])})}
                        {LpLine({
                            label: "Axis Line",
                            control: ShowHideToggle({
                                state: scope(tile, ["style", "yAxis", "lineShown"]),
                            }),
                        })}
                        {LpLine({
                            label: "Grid Lines",
                            control: ShowHideToggle({
                                state: scope(tile, ["style", "yAxis", "gridLinesShown"]),
                            }),
                        })}

                        {getFieldType(tile.value["yAxisField"]) === "date" && XAxisDateGapsSelect(scope(tile, ["style", "yAxis", "dateGaps"]))}
                    </div>
                </div>
            </div>
        );
    },
});
