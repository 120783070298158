export const decorateColumnsData = (columns) => {
    const dataTypeProperties = {
        DateTime: "None",
        Text: "None",
        Int: "None",
    };

    return columns.map((c) => ({
        ...c,
        dataTypeProperties: c.dataTypeProperties ?? (dataTypeProperties?.[c.dataType] || "None"),
        defaultAggregationFunc: c.defaultAggregationFunc ?? "None",
    }));
};
