import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";

export const RoutingParam = ({param, defaultValue, next}) =>
    cs(consumeContext("routing"), ({routing}) => {
        return next({
            defaultValue,
            value: routing.params?.[param] ?? defaultValue,
            onChange: (v) => {
                routing.updateParams({[param]: v});
            },
        });
    });
