import * as React from "react";
import {DataLabels} from "./panels/data-labels/data-labels";
import {Legend} from "./panels/legend/legend";
import {TileTitle} from "../common/tile-title/tile-title";
import {OtherOptions} from "../common/other-options/other-options";
import {MarkerStyle} from "./panels/marker-style/marker-style";
import {XAxis} from "./panels/x-axis/x-axis";
import {YAxis} from "./panels/y-axis/y-axis";
import {ReferenceLines} from "../common/reference-lines/reference-lines";

export const ScatterPlot = ({tile, size, form, scatterMarkerColorsConfig, referenceLinesPanel, parentTile, tileAction, isContainerTile}) => [
    TileTitle,
    (props) => ReferenceLines({...props, referenceLinesPanel}),
    XAxis,
    YAxis,
    DataLabels,
    MarkerStyle,
    Legend,
    OtherOptions,
].map((panel) => panel({tile, size, form, scatterMarkerColorsConfig, parentTile, tileAction, isContainerTile}));
