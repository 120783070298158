import {cs} from "@common/react/chain-services";

import {provideContext} from "@common/react/context";
import {createLiveApis} from "./live-apis";
import {createMockApis} from "./mock-apis";

const sdkUseMockApis = require("./sdk-use-mock-apis");

export const loadApis = ({next, ...props}) =>
    cs(
        [
            "apis",
            (_, next) =>
                process.env.NODE_ENV === "development" && sdkUseMockApis ? next(createMockApis(props)) : createLiveApis(props, next),
        ],
        ({apis}, next) => provideContext("apis", apis, next),
        next
    );
