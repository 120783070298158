import * as React from "react";
import {cs} from "@common/react/chain-services";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {spc} from "@common/react/state-path-change";
import {scope} from "@common/react/scope";
import {editCustomRanges} from "../../../../../../../../common/filters/filter-edit-main/options/date-filter/default-filter-criteria/custom-date-ranges/edit-custom-ranges";
import {
    defaultCustomRanges,
    localDefaultCustomRanges,
} from "../../../../../../../../common/filters/filter-edit-main/options/date-filter/default-filter-criteria/default-custom-ranges";
import "./date-filter-options.scss";
import {ShowHideToggle} from "@common/form/toggles/show-hide-toggle";
import {stateToSelect} from "@common/ui-components/form/state-to-select";
import {UseState} from "@common/react/use-state";
import {Static2} from "@common/react/static-2";

export const DateFilterOptions = ({tile, filter, form, tileType}) =>
    cs(({}) => {
        return (
            <div className="local-date-filter-options-537">
                <div className="range-select">
                    <div className="header">Date range</div>
                    <div className="content">
                        {DropdownSelect({
                            label: "Date Range Type",
                            list: [...defaultCustomRanges, ...localDefaultCustomRanges],
                            valueToLabel: (item) => item.label,
                            isSelected: (item) => item.isSelected(filter.value.customRange, filter.value.defaultOption),
                            onChange: (item) => {
                                filter.change((f) => ({
                                    ...f,
                                    ...item.onSelectValue(),
                                }));
                            },
                        })}

                        {filter.value.customRange &&
                            editCustomRanges?.[filter.value.customRange.$type]?.({
                                customRange: scope(filter, ["customRange"]),
                                form: form.scope(["customRange"]),
                            })}
                    </div>
                </div>

                <div className="compare-select">
                    <div className="header">
                        Comparison
                        <div className="control">
                            {tileType == "ListKPITile" || tileType == "PivotTableTile" || !tile.style?.showCompare
                                ? cs(["pos", (_, next) => UseState({next})], ["hideBtnRef", (_, next) => Static2({next})], ({hideBtnRef, pos}) => (
                                    <>
                                        <div
                                            className="hide-btn-a22"
                                            ref={hideBtnRef.set}
                                            onMouseEnter={() => {
                                                const {top, right} = hideBtnRef.get().getBoundingClientRect();
                                                pos.onChange({
                                                    top: top - 110,
                                                    left: right - 110,
                                                });
                                            }}
                                            onMouseLeave={() => {
                                                pos.onChange(null);
                                            }}
                                        >
                                            Hide
                                        </div>

                                        {!tile.style.showCompare && pos.value && (
                                            <div
                                                className="tooltip-box-a44 bottom"
                                                style={{
                                                    ...pos.value,
                                                    position: "fixed",
                                                    zIndex: 10,
                                                }}
                                            >
                                                A Comparison Date Range cannot be shown on this tile because the display settings is off. To enable the Comparison, go to the
                                                Other Options section of the Display tab.
                                            </div>
                                        )}
                                    </>
                                ))
                                : // <div className="hide-btn-a22">
                                //     Hide
                                // </div>
                                ShowHideToggle({
                                    state: {
                                        value: filter.value.compareEnabled && filter.value.compareOnByDefault,
                                        onChange: () =>
                                            filter.onChange({
                                                ...filter.value,
                                                compareEnabled: !filter.value.compareEnabled,
                                                compareOnByDefault: !filter.value.compareEnabled,
                                                ...(!filter.value.compareEnabled && {
                                                    compareDefaultRange: "PreviousPeriod",
                                                }),
                                            }),
                                    },
                                })}
                        </div>
                    </div>
                    {tileType != "ListKPITile" && tileType != "PivotTableTile" && tile.style?.showCompare && (
                        <div className="content">
                            {DropdownSelect({
                                label: "Comparison Date Range",
                                list: ["Previous Period", "Previous Year"].map((label) => ({
                                    label,
                                    value: label.replace(/ /g, ""),
                                })),
                                valueToLabel: (v) => v.label,
                                ...stateToSelect(scope(filter, ["compareDefaultRange"]), ["value"]),
                            })}
                        </div>
                    )}
                </div>
            </div>
        );
    });
