function numericFilter() {
    return [
        {
            path: ["defaultValueOrMin"],
            validators: [
                {
                    when: (data) => data?.$type === "NumericFilter" && data?.defaultOption != null,
                    validate: (v, {data}) => (data.defaultMax ? v != null : true),
                    getMessage: () => "Default value is required.",
                },
            ],
        },

        {
            path: ["defaultMax"],
            validators: [
                {
                    when: (data) => data?.$type === "NumericFilter" && data?.defaultOption != null && data?.defaultOption === "Range",
                    validate: (v, {data}) => (data.defaultValueOrMin ? v != null : true) && (data.defaultValueOrMin == null || v >= data.defaultValueOrMin),
                    getMessage: () => "Default value is required.",
                },
            ],
        },
        {
            path: ["list"],
            validators: [],
        },
        // {
        //     path: ["defaultOption"],
        //     validators: [{
        //         when: (data) => data?.$type === "NumericFilter" && data?.required,
        //         validate: (v) => v != null,
        //     }],
        // },
    ];
}
exports.numericFilter = numericFilter;
