exports.tableTemplate = {
    $type: "TableTile",
    style: {
        $type: "TableTileStyle",
        tableBorderWidth: 2,
        tableBorderVerticalLines: false,
        tableBorderHorizontalLines: true,
        tableBorderColorRGB: "#F2F3F5",
        tableCellColorRGB: "#f8f8f8",
        tableAlternateType: "AlternateRows",
        tableAltCellColorRGB: "#FFFFFF",
        rowHeight: null,
        rowFontSize: "Medium",
        allowWordWrap: true,
        columnWidths: "Auto",
        headerShown: true,
        headerFontSize: "Medium",
        headerFontOptions: "Bold",
        headerFontColorRGB: "#bcbcbc",
        headerCellColorRGB: "#FFFFFF",
        totalsShown: true,
        totalPositiveChangeGood: true,
        totalFontSize: "Medium",
        totalFontOptions: "Bold",
        totalFontColorRGB: "#02516C",
        totalCellColorRGB: "#FFFFFF",
        paginationType: "Paged",
        rowsPerPage: 25,
        title: {
            show: true,
            titleLocation: "Above",
        },
    },
};
