const {getFieldType} = require("../common/get-field-type");

const isDimRealDateTime = ({tile, dimensionAttr, series, noLimit = false}) => {
    if (noLimit) return getFieldType(tile[dimensionAttr]) === "date";

    return getFieldType(tile[dimensionAttr]) === "date" && series && series[0] && series[0].data && series[0].data.length && typeof series[0].data[0][0] === "number";
    //var tileDimAttr = tile[dimensionAttr];

    //if (typeof tileDimAttr === "undefined") {
    //    consol.log("undefined");
    //}

    //var s0 = series[0];

    //if (typeof s0 === "undefined") {
    //    consol.log("undefined");
    //}

    //var d = s0.data;

    //if (typeof d === "undefined") {
    //    consol.log("undefined");
    //}

    //var d0 = d[0];

    //if (typeof d0 === "undefined") {
    //    consol.log("undefined");
    //}

    //var d00 = d0[0];

    //if (typeof d00 === "undefined") {
    //    consol.log("undefined");
    //}

    //return getFieldType(tileDimAttr) === "date" && typeof d00 === "number";
};
exports.isDimRealDateTime = isDimRealDateTime;
