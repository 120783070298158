import * as React from "react";
import {Static2} from "@common/react/static-2";
import {AnyAction2} from "@common/react/any-action-2";
import {cs} from "@common/react/chain-services";

export const ChooseFilterToMoveBox = ({collection, tile}) =>
    cs(
        ["selectFilterBoxRef", (_, next) => Static2({next})],
        ({selectFilterBoxRef}, next) =>
            AnyAction2({
                getDom: selectFilterBoxRef.get,
                fn: () => {
                    if (selectFilterBoxRef.get()) {
                        tile.onDelete(true);
                    }
                },
                next,
            }),
        ({selectFilterBoxRef}) => (
            <div
                className="select-filter-to-move-box"
                ref={(elem) => {
                    setTimeout(() => {
                        selectFilterBoxRef.set(elem);
                    }, 800);
                }}
            >
                <div className="filter-text-title">Select Report To Move</div>

                {collection.value.filterDownloadTiles.map((report, index) => (
                    <div className="report-item" key={index} onClick={() => tile.onMoveFilterToDashboard(report)}>
                        {report.title}
                    </div>
                ))}
            </div>
        )
    );
