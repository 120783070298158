import {cloneElement, createRef, isValidElement} from "react";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import "./toast-service-download.scss";
import {provideContext} from "@common/react/context";
import {css, cx} from "emotion";
import {renderCompInRegistry} from "@common/ui-components/registry/common-registry";
import SpinnerIcon from "../../../web-client/src/assets/icons/data-sources/SpinnerIcon";

export const toastServiceDownload = ({theme, next}) =>
    cs(["downloadToast", (_, next) => downloadToast({theme, next})], ({downloadToast}) =>
        provideContext("downloadToast", downloadToast, next)
    );

const downloadToast = ({theme, next}) =>
    cs(["messages", (_, next) => UseState({initValue: [], next})], ({messages}) => {
        return (
            <>
                {renderCompInRegistry({
                    comp: (
                        <TransitionGroup className="toast-download-service-88o">
                            {messages.value.map(({id, text, nodeRef, icon}) => (
                                <CSSTransition key={id} nodeRef={nodeRef} timeout={500} classNames="item">
                                    <div
                                        ref={nodeRef}
                                        className={cx("toast-item")}
                                        style={{
                                            "background-color": `${theme?.general?.tile.styles.tileBackgroundColorRGB}`,
                                            border: `${theme?.general?.tile.styles.tileBorderWidth}px solid ${theme?.general?.tile.styles.tileBorderColorRGB}`,
                                            "border-radius": `${theme?.general?.tile.styles.tileCornerRadius}px`,
                                            color: `${theme?.general?.tile.styles.titleFontColorRGB}`,
                                        }}
                                    >
                                        {isValidElement(icon) &&
                                            cloneElement(icon, {
                                                className: cx(icon.props.className),
                                            })}
                                        <div
                                            className={cx("text")}
                                            style={{
                                                "fontFamily": `${theme?.general?.tile.styles.titleFontFamily || "Roboto"}`,
                                            }}
                                        >
                                            {text}
                                        </div>
                                    </div>
                                </CSSTransition>
                            ))}
                        </TransitionGroup>
                    ),
                })}

                {next({
                    add: (item) =>
                        messages.change((old) => [
                            ...old,
                            {
                                ...item,
                                text: "Preparing file...",
                                icon: <SpinnerIcon />,
                                nodeRef: createRef(null),
                            },
                        ]),
                    update: (item) =>
                        messages.change((old) =>
                            old.map((o) =>
                                o.id === item.id
                                    ? {
                                          ...o,
                                          text: "Download will begin shortly...",
                                          icon: <i className="fa fa-check" />,
                                      }
                                    : o
                            )
                        ),
                    remove: (item) => messages.change((old) => old.filter((o) => o.id !== item.id)),
                })}
            </>
        );
    });
