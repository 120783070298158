const {Load2} = require("@common/react/load2");
const {cs} = require("@common/react/chain-services");
const {consumeContext, provideContext} = require("@common/react/context");

const {sum} = require("../../../../../common/utils/collections");

const loadDataSummary = ({next: rootNext}) =>
    cs(
        consumeContext("apis"),
        [
            "dataSummary",
            ({apis}, next) =>
                Load2({
                    fetch: () => apis.data.getSummary(),
                    next,
                }),
        ],
        ({dataSummary}) => {
            let totalSizeMB = sum(dataSummary.value, (item) => item.sizeMB);
            return provideContext(
                "dataSummary",
                {
                    dataSources: dataSummary.value,
                    isLessThan1GB: totalSizeMB < 1024,
                    reload: dataSummary.reload,
                    totalSizeMB,
                },
                rootNext
            );
        }
    );

exports.loadDataSummary = loadDataSummary;
