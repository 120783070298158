import React from "react";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {TransformationMain} from "../main/transformation-main";
import {ChangeQueue1} from "@common/react/change-queue-1";
import {Queue1} from "@common/react/queue-1";
import {Load} from "@common/react/load";
import {fragments} from "@common/react/fragments";
import {SingleEditorRegister} from "../../../common/single-editor/single-editor";

export const EditTransformation = ({environment, transformation}) =>
    cs(
        consumeContext("routing"),
        consumeContext("apis"),
        ({toastService, routing}, next) =>
            fragments(
                transformation?.value
                    ? SingleEditorRegister({
                          toastService,
                          goBack: () =>
                              routing.goto("edit-model", {
                                  modelId: routing.params.modelId,
                              }),
                          getItemId: () => routing.params.transformationId,
                          item: {
                              type: "transformation",
                              parent: "Model",
                              name: transformation?.value?.name,
                              id: transformation?.value?.id,
                          },
                      })
                    : null,
                next()
            ),
        [
            "dataSourcesSummary",
            ({apis, routing}, next) =>
                Load({
                    fetch: () => routing.params.transformationId !== "undefined" && apis.data.getDataSources({namesOnly: true}),
                    next,
                }),
        ],
        [
            "updatePositionQueue",
            ({apis, routing}, next) =>
                Queue1({
                    fn: ({updated}) => (!!environment?.readOnly ? () => null : apis.transformation.upsertTransformationPosition(routing.params.transformationId, updated)),
                    next,
                }),
        ],
        [
            "savingQueue",
            ({apis}, next) =>
                ChangeQueue1({
                    save: apis.transformation.upsertTransformation,
                    fetchLatest: () => apis.transformation.getTransformation(transformation.value.id),
                    next,
                }),
        ],
        ({savingQueue, updatePositionQueue, dataSourcesSummary}) => {
            return TransformationMain({
                environment,
                transformation: createAutoSaveTransformation({
                    transformation,
                    savingQueue,
                    readOnly: !!environment?.readOnly,
                }),
                dataSourcesSummary,
                saving: savingQueue.executing,
                updatePositionQueue,
            });
        }
    );

const createAutoSaveTransformation = ({transformation, savingQueue, readOnly}) => ({
    value: transformation?.value,
    change: async (reduce, notUpdatingImmediately, afterUpdating) => {
        transformation.change(reduce);
        if (!notUpdatingImmediately && !readOnly) {
            const newTransformation = await savingQueue.push(reduce);
            transformation.change(() => newTransformation);
            afterUpdating?.(newTransformation);
        }
    },
});
