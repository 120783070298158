const {addDate} = require("../../../../../utils/dates/date-object");
const {formatDateTime} = require("../../../../../logic/date-time/format-date-time");

const serializeFilterState = (state, {filter}) => {
    const byTypes = {
        DateFilter: serializeDateFilterState,
        TextFilter: serializeTextFilterState,
        NumericFilter: serializeNumericFilterState,
        BooleanFilter: serializeBooleanFilterState,
    };

    return byTypes[filter.$type]?.(state, {filter});
};
exports.serializeFilterState = serializeFilterState;

const serializeDateFilterState = (state, {filter}) => {
    return {
        $type: "DateFilterValue",
        option: state.main ? "Custom" : "AllTime",
        includeNullDates: !!state.includeNullDates,
        range: {
            $type: "InRangeCustomDateFilterRange",
            dateStart: state.main ? formatDateTime({date: state.main.from, time: state.main.from}) : null,
            dateEnd: state.main ? formatDateTime({date: state.main.to, time: state.main.to}) : null, // date-end
        },
        allowTimeFilter: state.allowTimeFilter,
        columns: state.columns,
        compareRange:
            state.enableComparing && state.main
                ? // AB#1043: compare is not available on multiple fields
                  state.comparing && (state.columns.length < 2 || filter.multiSelectionOption == "Any")
                    ? {
                          $type: "InRangeCustomDateFilterRange",
                          dateStart: formatDateTime({
                              date: state.comparing.from,
                              time: state.comparing.from,
                          }),
                          dateEnd: formatDateTime({
                              date: state.comparing.to,
                              time: state.comparing.to,
                          }), // date-end
                      }
                    : null
                : null,
    };
};

const serializeNumericFilterState = (state) => ({
    $type: "NumericFilterValue",
    option: state.comparisonOperation,
    ...(state.comparisonOperation === "Range"
        ? {
              valueOrMin: state.range?.from,
              max: state.range?.to,
          }
        : {
              valueOrMin: state.value,
          }),
    columns: state.columns,
});

const serializeTextFilterState = (state, {filter}) => ({
    $type: "TextFilterValue",
    option: filter.option,
    ...state,
});

const serializeBooleanFilterState = (state) => {
    return {
        $type: "BooleanFilterValue",
        value: !state.value || state.value.length == 0 || state.value.length == 2 ? null : state.value[0] == "True",
        columns: state.columns,
    };
};
