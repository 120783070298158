import React from "react";
import {cs} from "@common/react/chain-services";
import {rFieldTypeIcon} from "../../../../common/field-type-icon/get-field-type-icon";
import "./column-value-select-from-step-output.scss";
import {consumeContext} from "@common/react/context";
import {DropdownSelectSearch} from "@common/ui-components/dropdown-select/dropdown-select-search";

/**
 * to choose a column-value from outputColumns of a transformation step (no relationships)
 * to choose column-value through relationships from outputColumns, use column-value-drilling-select-from-step-output.jsx
 */

export const rColumnValueSelectFromStepOutput =
    ({columns, label, valueToLabel, filterColumnFn, isUnavailable, disableStaticValue, renderStaticInput}) =>
    ({state, ...props}) =>
        cs(consumeContext("getModelColumn"), ({getModelColumn}) => {
            const modelColumns = !columns ? [] : columns.filter((col) => col.$type === "ModelStepColumn").map((col) => getModelColumn(col));

            const isSelected = (column) => {
                const checkFns = {
                    ViewStepColumn: (v) => v.name === state.value?.name,
                    ModelStepColumn: (v) => v.modelColumnID === state.value?.modelColumnID,
                    GroupAggColumn: (v) => v.name === state.value?.name,
                    StaticValue: () => false,
                };
                return checkFns[column.$type](column);
            };

            const defaultValueToLabel = (c) => {
                const column = ["ViewStepColumn", "GroupAggColumn"].includes(c.$type) ? c : modelColumns.find((c1) => c1.id === c.modelColumnID);
                return (
                    <div className="column-label">
                        {rFieldTypeIcon(column?.dataType)}
                        <div className="text">
                            {column?.name}
                            {modelColumns.find((c1) => c1.id !== c.modelColumnID && c1.name === column.name) && ` [${column.tableName}]`}
                        </div>
                    </div>
                );
            };

            return (
                <>
                    <div className="column-value-select-from-step-output column-value-select-from-step-output-46s">
                        {DropdownSelectSearch({
                            ...props,
                            label,
                            searchPlaceholder: "Find column",
                            list: filterColumnFn ? columns.filter(filterColumnFn) : columns,
                            valueToLabel: (c) => {
                                if (valueToLabel) {
                                    return valueToLabel({
                                        column: c,
                                        getColumnInfo: getModelColumn,
                                        modelColumns,
                                    });
                                } else {
                                    return defaultValueToLabel(c);
                                }
                            },
                            isSelected,
                            valueToSearch: (c) => (c.$type === "ViewStepColumn" ? c.name : modelColumns.find((mc) => mc.id === c.modelColumnID).name),
                            onChange: (c) => {
                                if (c.$type === "ViewStepColumn") {
                                    state.onChange({
                                        $type: "ViewColumnValue",
                                        name: c.name,
                                    });
                                }

                                if (c.$type === "ModelStepColumn") {
                                    state.onChange({
                                        $type: "ModelColumnValue",
                                        modelID: c.modelID,
                                        modelTableID: c.modelTableID,
                                        modelColumnID: c.modelColumnID,
                                    });
                                }
                            },
                            isUnavailable,
                            ...(!disableStaticValue && {
                                outOfListChoice: {
                                    isSelected: () => state.value?.$type === "StaticValue",
                                    onChange: () => state.onChange({$type: "StaticValue"}),
                                    label: "Use Static Value",
                                    valueLabel: "Static Value",
                                },
                            }),
                        })}
                    </div>

                    {state.value?.$type === "StaticValue" &&
                        !disableStaticValue &&
                        renderStaticInput?.({
                            state: {
                                value: state.value.value?.toString(),
                                onChange: (v) => state.onChange({...state.value, value: v?.toString()}),
                            },
                        })}
                </>
            );
        });
