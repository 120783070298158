import {TileTitle} from "../common/tile-title/tile-title";
import {ValueFormat} from "../single-kpi/panels/value-format/value-format";
import {KpiColors} from "../table-kpi/panels/kpi-colors/kpi-colors";
import {SectionOptions} from "../common/section-options/section-options";

export const SectionTile = ({tile, size, form, parentTile, tileAction, kpiColorsTileConfig, isContainerTile}) =>
    [TileTitle, SectionOptions].map((panel) =>
        panel({
            tile,
            size,
            form,
            parentTile,
            tileAction,
            kpiColorsTileConfig,
            isContainerTile,
        })
    );
