const {addUpSameCategories} = require("./add-up-same-categories");
const {syncDatetimeSeriesLength} = require("./sync-datetime-series-length");
const {fillEmptyCategory} = require("./fill-empty-category");
const {getFieldType} = require("../common/get-field-type");

const seriesProcessors = [
    // this is for fixing the datetime series with unequal data length, which cause the main and comparison bars not aligned
    {
        condition: ({tile, isDimensionRealDate, isCompare}) => {
            // if tile is of type "LineChartTile" and there's comparison and x-axis is real datetime then...
            if (tile.$type === "LineChartTile") {
                return isCompare && isDimensionRealDate;
            }

            // if bar display is stacked or chart is in compare mode, and dimension is real datetime, then...
            return (isCompare || (tile.style.yAxisBarDisplayType || tile.style.displayType) === "Stacked") && isDimensionRealDate;
        },
        // ... then make sure that all datetime series have the same data length
        processor: syncDatetimeSeriesLength,
    },
    // this is for fixing the category series with data having null or empty category
    {
        // if dimension field is text (category) then...
        condition: ({tile, rawData}) => {
            return getFieldType(tile.xAxisField || tile.yAxisField) === "text" && (!rawData.series[0]?.data?.[0]?.[0] || typeof rawData.series[0].data[0][0] === "string");
        },
        // ...then make sure data category contains no empty string or null
        processor: fillEmptyCategory,
    },
    // this is for fixing the datetime-string series with data having repeated datetime string
    // for example, data with all categories of "Q2 2021"
    {
        // if dimension field is datetime-string (category) then...
        condition: ({tile, rawData}) => {
            return getFieldType(tile.xAxisField || tile.yAxisField) === "date" && typeof rawData.series[0]?.data?.[0]?.[0] === "string";
        },
        // ...then add up the values from same category
        processor: addUpSameCategories,
    },
];

const rawDataProcessor = ({tile, rawData, isDimensionRealDate, isCompare}) => {
    const seriesProcessor = seriesProcessors.find((sp) => sp.condition({tile, rawData, isDimensionRealDate, isCompare}));

    if (!seriesProcessor) {
        return rawData;
    }

    return {
        ...rawData,
        series: seriesProcessor.processor({
            series: rawData.series,
            tile,
            isCompare,
        }),
    };
};
exports.rawDataProcessor = rawDataProcessor;
