const chartElementTooltipContainer = (() => {
    const className = "chart-element-tooltip-container";
    const container1 = document.body.getElementsByClassName(className)?.[0];
    if (container1) {
        return container1;
    }
    const container = document.createElement("div");
    container.className = className;
    document.body.appendChild(container);
    return container;
})();

const renderTooltip = (pos, content) => {
    const tooltip = document.createElement("div");
    tooltip.style.position = "fixed";
    tooltip.style.top = `${pos.y}px`;
    tooltip.style.left = `${pos.x}px`;
    tooltip.style.padding = `10px`;
    tooltip.style.border = `1px solid red`;
    tooltip.style.borderRadius = `4px`;
    tooltip.style.backgroundColor = `white`;
    tooltip.innerHTML = content;
    return tooltip;
};

const chartElementTooltipService = () => {
    let currentTooltip;

    const show = (event, content, fixedPos) => {
        // console.log(event, content)
        const {x, y, width, height} = event.target.getBoundingClientRect();
        const backgroundRect = event.fromElement.getBoundingClientRect();
        currentTooltip = renderTooltip(
            fixedPos
                ? {
                      x: backgroundRect.x + backgroundRect.width * 0.5,
                      y: backgroundRect.y + backgroundRect.height * 0.5,
                  }
                : {x: x + width, y},
            content
        );
        chartElementTooltipContainer.appendChild(currentTooltip);
    };

    const close = () => {
        if (currentTooltip) {
            chartElementTooltipContainer.removeChild(currentTooltip);
            currentTooltip = null;
        }
    };

    return {
        show,
        close,
    };
};
exports.chartElementTooltipService = chartElementTooltipService;
