import * as React from "react";
import {cs} from "../../../../../../../../../../../common/react/chain-services";
import {provideContext} from "../../../../../../../../../../../common/react/context";
import {scope} from "../../../../../../../../../../../common/react/scope";
import {isSameField} from "../../../../../../../../../../../common/ui-components/charts/factory/is-same-field";
import {Expandable} from "../../../../../../../../common/expandable/expandable";
import {getTileActionsUpdated} from "../../actions/get-tile-actions-updated";
import {getTileFields} from "../common/get-tile-fields";
import {Limit} from "../common/limit-sorting/limit/limit";
import {defaultAggregationFunctions, getColumnType} from "../common/select-field/select-field";
import {SingleFieldWell} from "../common/single-field-well/single-field-well";
import {updateSingleFieldInTile} from "../common/update-tile";
import "../tile-fields.scss";
import {AddRemoveDimensionDialog} from "./add-remove-dimension-dialog";
import {updateScatterPlotDimension} from "./scatter-dimension-helpers";

export const ScatterPlotFields = ({tile, isSubTile, form, next, tileActions, debugFunction}) => {
    return cs(
        [
            "tileFields",
            ({}, next) =>
                next(
                    getTileFields({
                        tile: tile.value,
                        dimensionAttr: "dimensionField",
                        measureSingleAttrs: ["xAxisField", "yAxisField"],
                        groupFieldAttrs: ["colorGroupField", "shapeGroupField"],
                    })
                ),
        ],
        ({tileFields}, next) => provideContext("tileFields", tileFields, next),

        [
            "fieldsSection",
            ({tileFields}, next) => {
                const measureFieldColumnFilter = (column) => {
                    if (tile.value.dimensionField != null) {
                        return true;
                    }
                    const type = getColumnType(column);
                    return type === "number";
                };

                const cOnUpdateMeasureField = (measureAttr) => (newField, oriField) => {
                    tile.onChange({
                        ...tile.value,
                        // normally, when changing field, only fields of same data types are allowed.
                        // but for scatter plot, if dimensionField is present, changing between measure fields of different data types is allowed.
                        // so have to set the appropriate aggregationFunc of new measure field here.
                        [measureAttr]: !newField
                            ? null
                            : {
                                  ...newField,
                                  aggregationFunc: tile.value.dimensionField == null ? "None" : defaultAggregationFunctions[getColumnType(newField)],
                              },
                        // update limit if it is the measure field
                        limit: isSameField(tile.value.limit, oriField) ? null : tile.value.limit,
                    });
                };

                // if there is no dimension, the measure fields are not aggregated, hence hide aggregation select and the aggregation type on field card.
                const hideAggregationSelectOnMeasure = tile.value.dimensionField == null;

                return cs(
                    ["dimensionDialog", (_, next) => AddRemoveDimensionDialog({next})],
                    [
                        "xAxisField",
                        (_, next) => {
                            const field = scope(tile, ["xAxisField"]);
                            return SingleFieldWell({
                                field,
                                $type: "MeasureTileField",
                                label: "X-Axis",
                                filterColumn: measureFieldColumnFilter,
                                allowAllFieldTypesWhenChangingField: true,
                                hideAggregationSelect: hideAggregationSelectOnMeasure,
                                hideAggregationType: hideAggregationSelectOnMeasure,
                                notAllowNoneAggregation: true,
                                onUpdateTile: cOnUpdateMeasureField("xAxisField"),
                                next,
                            });
                        },
                    ],
                    [
                        "yAxisField",
                        (_, next) => {
                            const field = scope(tile, ["yAxisField"]);
                            return SingleFieldWell({
                                field,
                                $type: "MeasureTileField",
                                label: "Y-Axis",
                                filterColumn: measureFieldColumnFilter,
                                allowAllFieldTypesWhenChangingField: true,
                                hideAggregationSelect: hideAggregationSelectOnMeasure,
                                hideAggregationType: hideAggregationSelectOnMeasure,
                                notAllowNoneAggregation: true,
                                onUpdateTile: cOnUpdateMeasureField("yAxisField"),
                                next,
                            });
                        },
                    ],
                    [
                        "dimensionField",
                        ({dimensionDialog}, next) =>
                            SingleFieldWell({
                                field: scope(tile, ["dimensionField"]),
                                $type: "CategoryTileField",
                                label: (
                                    <span>
                                        Marker Aggregator <i style={{textTransform: "none"}}>(optional)</i>
                                    </span>
                                ),
                                fieldLabel: `Marker Aggregator`,
                                // filterColumn: (column) => column.$type !== 'AggregatedMeasureModelColumn',
                                info: "Marker Aggregator is used to aggregate the measure fields. If you do not want to aggregate measure values then do not use the dimension field.",
                                onUpdateTile: (newVal, oriVal) =>
                                    updateScatterPlotDimension({
                                        newDimension: newVal,
                                        oldDimension: oriVal,
                                        tile,
                                        dimensionDialog,
                                    }),
                                next,
                            }),
                    ],
                    [
                        "colorGroupField",
                        (_, next) =>
                            SingleFieldWell({
                                field: scope(tile, ["colorGroupField"]),
                                $type: "CategoryTileField",
                                label: (
                                    <span>
                                        Color Group <i style={{textTransform: "none"}}>(optional)</i>
                                    </span>
                                ),
                                fieldLabel: "Color Group",
                                info: "Use the Color Group option if you would like to color the markers by a separate field. This field is optional.",
                                onUpdateTile: (newVal, oriVal) =>
                                    updateSingleFieldInTile({
                                        fieldAttr: "colorGroupField",
                                        fieldVal: {newVal, oriVal},
                                        tileFields,
                                        tile,
                                        // updateTileActions: getTileActionsUpdated
                                    }),
                                next,
                            }),
                    ],
                    [
                        "shapeGroupField",
                        (_, next) =>
                            SingleFieldWell({
                                field: scope(tile, ["shapeGroupField"]),
                                $type: "CategoryTileField",
                                label: (
                                    <span>
                                        Shape Group <i style={{textTransform: "none"}}>(optional)</i>
                                    </span>
                                ),
                                fieldLabel: "Shape Group",
                                info: "Shape groups will automatically apply a different shape for each group. This field is optional.",
                                onUpdateTile: (newVal, oriVal) =>
                                    updateSingleFieldInTile({
                                        fieldAttr: "shapeGroupField",
                                        fieldVal: {newVal, oriVal},
                                        tileFields,
                                        tile,
                                        updateTileActions: getTileActionsUpdated,
                                    }),
                                next,
                            }),
                    ],
                    ({xAxisField, yAxisField, dimensionField, colorGroupField, shapeGroupField}) =>
                        next({
                            render: () =>
                                Expandable({
                                    label: "Fields",
                                    render: () => (
                                        <>
                                            {xAxisField.render()}
                                            {yAxisField.render()}
                                            {dimensionField.render()}
                                            {colorGroupField.render()}
                                            {shapeGroupField.render()}
                                        </>
                                    ),
                                }),
                            override: xAxisField.override || yAxisField.override || dimensionField.override || colorGroupField.override || shapeGroupField.override,
                        })
                );
            },
        ],

        [
            "limit",
            ({tileFields}, next) => {
                const formField = form.field(["limit"]);

                const isMeasureFieldSelected = [tile.value.xAxisField, tile.value.yAxisField].find((f) => f && formField.state.value && isSameField(f, formField.state.value));

                return next({
                    render: () =>
                        Expandable({
                            initExpand: false,
                            label: "Limit",
                            render: () =>
                                Limit({
                                    field: formField.state,
                                    allFields: tileFields.allFieldsWithoutUniq,
                                    fieldsForLimit: ["xAxisField", "yAxisField", "dimensionField"].map((attr) => tile.value[attr]).filter((v) => v),
                                    isHiddenRankOption: () => !isMeasureFieldSelected,
                                    showOther: false,
                                }),
                        }),
                });
            },
        ],

        ({fieldsSection, limit}) =>
            next({
                render: () => (
                    <div className="tile-fields-3vr">
                        {fieldsSection.render()}
                        {limit.render()}
                        {tileActions?.render?.()}
                        {isSubTile && debugFunction?.render?.({})}
                    </div>
                ),
                override: fieldsSection.override || debugFunction?.override() || tileActions?.override({}),
                overrideAll: debugFunction?.overrideAll || tileActions?.overrideAll,
            })
    );
};
