import React from "react";
import {cs} from "@common/react/chain-services";
import "./map-values-configuration.scss";
import {rColumnSelectFromStepOutput} from "../../../../common/column-select-from-step-output/column-select-from-step-output";
import {DropdownSelect2} from "@common/ui-components/dropdown-select/dropdown-select2";
import {ValueReplacements} from "./value-replacements/value-replacements";
import {keepOnly, omit} from "@common/utils/objects";
import {DebounceTextInput} from "@common/form/debounce-input/debounce-text-input";
import {ffToDropdown} from "@common/form/ff-to-dropdown";
import {ffToTextInput} from "@common/form/ff-to-text-input";
import {rNewColumnNameInput} from "../../common/new-column-name-input";

export const MapValuesConfiguration = ({transformation, step, form, model}) =>
    cs(({}) => {
        const inputStep = transformation.value.steps.find((s) => s.id === step.value.inputStepID);
        const columnSelect = rColumnSelectFromStepOutput({
            label: "Column",
            columns: inputStep?.outputColumns,
        });

        return (
            <div className="map-values-configuration-4ht">
                <div className="config-group">
                    <div className="label">Column to map</div>
                    {(() => {
                        const dropdownProps = ffToDropdown(form.field(["columnToMap"]));
                        return columnSelect({
                            state: {
                                value: dropdownProps.value,
                                onChange: (c) =>
                                    step.onChange({
                                        ...omit(step.value, ["valueLookup"]),
                                        columnToMap: c,
                                    }),
                            },
                            ...keepOnly(dropdownProps, ["domRef", "hasError", "errorMessage"]),
                        });
                    })()}
                </div>

                {step.value.columnToMap && (
                    <>
                        <div className="config-group dual">
                            <div className="label">Value not found action</div>
                            <div className="dual-line">
                                {DropdownSelect2({
                                    label: "Action",
                                    list: [
                                        {
                                            label: "Keep Original Value",
                                            value: "KeepOriginal",
                                        },
                                        {
                                            label: "Write NULL",
                                            value: "WriteNull",
                                        },
                                        {
                                            label: "Write Blank",
                                            value: "WriteBlank",
                                        },
                                        {
                                            label: "Write Custom Value",
                                            value: "WriteCustomValue",
                                        },
                                        {
                                            label: "Other Column Value",
                                            value: "OtherColumnValue",
                                        },
                                    ],
                                    valueToLabel: (v) => v.label,
                                    ...ffToDropdown(form.field("notFoundAction"), ["value"]),
                                })}

                                {(() => {
                                    if (step.value.notFoundAction === "WriteCustomValue") {
                                        return DebounceTextInput({
                                            label: "Custom Value",
                                            ...ffToTextInput(form.field(["notFoundCustomValue"])),
                                        });
                                    } else if (step.value.notFoundAction === "OtherColumnValue") {
                                        const dropdownProps = ffToDropdown(form.field(["notFoundOtherColumn"]));
                                        return columnSelect({
                                            state: {
                                                value: dropdownProps.value,
                                                onChange: (c) =>
                                                    step.onChange({
                                                        ...omit(step.value, ["valueLookup"]),
                                                        notFoundOtherColumn: c,
                                                    }),
                                            },
                                            ...keepOnly(dropdownProps, ["domRef", "hasError", "errorMessage"]),
                                        });
                                    }
                                })()}
                            </div>
                        </div>

                        {ValueReplacements({step, form, model})}

                        <div className="config-group result">
                            <div className="label">Result</div>
                            {(() => {
                                const dropdownProps = ffToDropdown(form.field(["newColumn"]));
                                return DropdownSelect2({
                                    label: "Action",
                                    list: [
                                        {
                                            label: "Override Input Column",
                                            isSelected: () => step.value.overrideColumn,
                                        },
                                        {
                                            label: "New Column",
                                            isSelected: () => !step.value.overrideColumn && step.value.newColumn,
                                        },
                                    ],
                                    valueToLabel: (v) => v.label,
                                    isSelected: (v) => v.isSelected(),
                                    onChange: (v) =>
                                        v.label.includes("Override")
                                            ? step.onChange({
                                                  ...step.value,
                                                  overrideColumn: true,
                                                  newColumn: null,
                                              })
                                            : step.onChange({
                                                  ...step.value,
                                                  overrideColumn: false,
                                                  newColumn: {
                                                      $type: "ViewStepColumn",
                                                  },
                                              }),
                                    ...keepOnly(dropdownProps, ["domRef", "hasError", "errorMessage"]),
                                });
                            })()}
                        </div>
                        {step.value.newColumn && (
                            <div className="config-group">
                                <div className="label">New column name</div>
                                {rNewColumnNameInput({
                                    form,
                                    newColumnPath: ["newColumn"],
                                    newColumnNamePath: ["newColumn", "name"],
                                })}
                            </div>
                        )}
                    </>
                )}
            </div>
        );
    });
