import * as React from "react";
import {cs} from "@common/react/chain-services";
import {DialogService} from "../../../../../../../../common/dialog/dialog-service";
import "./remove-block-dialog.scss";
import {Button} from "../../../../../../../../../../../common/form/buttons/button/button";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";

export const RemoveBlockDialog = ({model, onDone, next: rootNext}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    render: ({resolve}) => ({
                        title: "Delete Clause?",
                        width: 480,
                        content: next({
                            resolve,
                        }),
                        className: "remove-block-wrapper-o23",
                    }),
                    next: rootNext,
                }),
        ],
        ({modal}) => (
            <div className="remove-block-dialog-a32">
                <VerbDialogBodyScrollbar>
                    <div className="messages">
                        <div className="message">Are you sure you want to delete this clause? It may effect users' access to data.</div>
                    </div>
                </VerbDialogBodyScrollbar>

                <div className="buttons">
                    <Button btnType="secondary" onClick={() => modal.resolve()}>
                        Cancel
                    </Button>
                    <Button onClick={() => modal.resolve("confirm")}>Delete Clause</Button>
                </div>
            </div>
        )
    );
