import "./loading-skeleton.scss";

import React from 'react';

import {cs} from "@common/react/chain-services";
import {ObserveDomSize} from "@common/react/observe-dom-size";

import {
    AreaSkeleton,
    ComboSkeleton,
    DonutSkeleton,
    DownloadSkeleton,
    GaugeSkeleton,
    HorizontalSkeleton,
    KPIListSkeleton,
    LineSkeleton,
    MapSkeleton,
    SingleKPISkeleton,
    TableSkeleton,
    VerticalSkeleton,
    FunnelSkeleton,
    VennDiagramSkeleton,
    ScatterPlotSkeleton,
    BubbleChartSkeleton,
    SparkLineKPISkeleton,
    BoxPlotSkeleton,
    TextLabelSkeleton,
} from "./skeletons";

export const forDarkBGColors = {
    stopColor1: "#F8FCFF20",
    stopColor2: "#EFF3F610",
    stopColor3: "#D1D8DE10",
    stopColor4: "#F2F4F520",
    stopColor5: "#F4F5F710",
};

export const forLightBGColors = {
    stopColor1: "#1C1C1C15",
    stopColor2: "#26262605",
    stopColor3: "#38383805",
    stopColor4: "#24242415",
    stopColor5: "#1F1F1F05",
};

const loadingSkeletonMapper = {
    VerticalBarChartTile: VerticalSkeleton,
    HorizontalBarChartTile: HorizontalSkeleton,
    LineChartTile: (props) => {
        const isAreaLine = ["Area", "SplineArea"].includes(props.displayType);

        if (isAreaLine) return AreaSkeleton(props);

        return LineSkeleton(props);
    },
    ScatterPlotTile: ScatterPlotSkeleton,
    BubbleChartTile: BubbleChartSkeleton,
    PieChartTile: DonutSkeleton,
    DonutChartTile: DonutSkeleton,
    DownloadReportTile: DownloadSkeleton,
    TableTile: TableSkeleton,
    PivotTableTile: TableSkeleton,
    ComboChartTile: ComboSkeleton,
    GaugeTile: GaugeSkeleton,
    SingleKPITile: SingleKPISkeleton,
    SparkLineKPITile: SparkLineKPISkeleton,
    TableKPITile: (props) => {
        return (
            <div className="table-kpi-skeleton-f34" style={{flex: 1}}>
                <SingleKPISkeleton {...props} style={{width: "55%", marginBottom: 25}} />
                <KPIListSkeleton {...props} style={{width: "100%"}} />
            </div>
        );
    },
    ListKPITile: KPIListSkeleton,
    MapTile: MapSkeleton,
    FunnelChartTile: FunnelSkeleton,
    VennDiagramTile: VennDiagramSkeleton,
    BoxPlotTile: BoxPlotSkeleton,
    TextLabelTile: TextLabelSkeleton,
};

export const LoadingSkeleton = ({tile, animated = true, theme}) =>
    cs(["skeletonSize", (_, next) => ObserveDomSize({next})], ({skeletonSize}) => {
        const type = tile.$type || tile.type;
        const displayType = tile.style.displayType;

        return theme?.general.tile.styles.tileBackgroundColorRGB && type ? (
            <div className="loading-skeleton-y3g loading-skeleton">
                <div className="svg-container" aria-busy="true" ref={skeletonSize.ref}>
                    {loadingSkeletonMapper[type] &&
                        loadingSkeletonMapper[type]({
                            ...theme.skeletonColor,
                            tile,
                            displayType,
                            animated,
                            strokeColor: theme?.general.tile.styles.tileBackgroundColorRGB,
                            height: (skeletonSize.value && skeletonSize.value?.height - 20) || undefined,
                            width: (skeletonSize.value && skeletonSize.value?.width - 20) || undefined,
                        })}
                </div>
            </div>
        ) : null;
    });
