import {equalDeep} from "../../utils/objects";
import {cs} from "../chain-services";
import {consumeContext} from "../context";
import {fragments} from "../fragments";
import {Invoke} from "../invoke";
import {keyed} from "../keyed";
import {Static2} from "../static-2";
import {UseState} from "../use-state";
import {CachedApiProvider} from "./cached-api-provider";

const extractCacheData = (loaded) => {
    const {value} = loaded?.value ?? {};

    return value
        ? {
              data: value.data,
              lastCachedAt: value.createdAt,
          }
        : null;
};

export const CachedLoad = ({fetch, next: rootNext, disabled, keepOutdatedValue, captureException, key, getCacheKey, onCached}) => {
    const _key = JSON.stringify(key);

    return cs(
        consumeContext("apiCache"),
        [
            "cache",
            ({apiCache}, next) => {
                return Static2({
                    getInitValue: () => {
                        return apiCache.createFetcher({
                            fetch,
                            key,
                            getCacheKey,
                            onCached,
                        });
                    },
                    next,
                });
            },
        ],
        ["loaded", (_, next) => UseState({next})],

        ({loaded, cache, apiCache}) => {
            const loading = !loaded.value || !equalDeep(loaded.value.key, _key) || loaded.value?.refetching;
            return fragments(
                !disabled &&
                    cs(
                        keyed(_key),
                        (_, next) =>
                            Invoke({
                                fn: () => {
                                    if (loaded.value) {
                                        cache.set(
                                            apiCache.createFetcher({
                                                fetch,
                                                key,
                                                getCacheKey,
                                                onCached,
                                            })
                                        );

                                        if (!keepOutdatedValue) {
                                            loaded.onChange({});
                                        }
                                    }
                                },
                                next,
                            }),
                        () =>
                            Invoke({
                                fn: async ({isMounted}) => {
                                    const fetcher = cache.get();
                                    try {
                                        const newValue = await fetcher.fetch();

                                        if (!isMounted()) {
                                            return;
                                        }

                                        loaded.onChange({
                                            value: newValue,
                                            key: _key,
                                        });
                                    } catch (error) {
                                        if (captureException) {
                                            if (!isMounted()) {
                                                return;
                                            }
                                            loaded.onChange({
                                                error,
                                                key: _key,
                                            });
                                        } else {
                                            throw error;
                                        }
                                    }
                                },
                            })
                    ),
                !disabled &&
                    loaded.value?.refetching &&
                    cs(() =>
                        Invoke({
                            fn: async ({isMounted}) => {
                                const fetcher = cache.get();
                                try {
                                    const newValue = await fetcher.refresh();

                                    if (!isMounted()) {
                                        return;
                                    }
                                    loaded.onChange({
                                        key: _key,
                                        value: newValue,
                                    });
                                } catch (error) {
                                    if (captureException) {
                                        if (!isMounted()) {
                                            return;
                                        }
                                        loaded.onChange({
                                            key: _key,
                                            error,
                                        });
                                    } else {
                                        throw error;
                                    }
                                }
                            },
                        })
                    ),
                rootNext?.({
                    loading,
                    value: loading && !keepOutdatedValue ? undefined : extractCacheData(loaded),
                    error: loading ? undefined : loaded.value?.error,
                    refetch: () =>
                        loaded.onChange({
                            ...loaded.value,
                            refetching: true,
                        }),
                })
            );
        }
    );
};

export const initApiCache =
    (
        {} //For further config if needed
    ) =>
    (_, next) =>
        CachedApiProvider({next});
