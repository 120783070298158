const {cIndexedGetter} = require("../../../../../../common/utils/indexing");

const flattenModelCols = (model) => {
    if (!model) return [];

    const allTables = model.tables.concat(model.disabledTables || []);

    let cols = [];
    for (let i = 0; i < allTables.length; i++) {
        let tab = allTables[i];

        const loop = (columns) => {
            if (!columns?.length) {
                return;
            }
            for (let j = 0; j < columns.length; j++) {
                let col = columns[j];
                // somehow there can be undefined column here
                if (!col) {
                    return;
                }
                col.modelID = model.id;
                col.modelTableID = tab.id;
                col.modelTableType = tab.$type;
                col.modelTableName = tab.name;
                col.dataSourceID = tab.dataSourceID;
                col.modelColumnID = col.id;
                col.isTableDeleted = tab.deleted;
                cols.push(col);
            }
        };

        loop(tab.columns);
        loop(tab.disabledColumns);
    }
    return cols;
};
exports.flattenModelCols = flattenModelCols;

const cGetField = (model) => cIndexedGetter(flattenModelCols(model), ["modelID", "modelTableID", "modelColumnID"]);
exports.cGetField = cGetField;
