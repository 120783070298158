import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {css, cx} from "emotion";
import {FilterSubmitButtons} from "@common/ui-components/charts/table/table/header/filter-types/filter-submit-buttons";
import {DropdownSelectLive} from "@common/ui-components/live/filters/common/dropdown-select/dropdown-select-live";
import {StyledClass} from "@common/react/styled-class";

export const HeaderFilterByBoolean = ({search, theme, close, columnIndex, defaultSelected}) => {
    const componentTheme = theme.general.components;

    return cs(
        [
            "selected",
            (_, next) =>
                UseState({
                    next,
                    initValue: defaultSelected?.search == null ? "True" : defaultSelected?.search,
                }),
        ],
        [
            "cssClass",
            (_, next) =>
                StyledClass({
                    next,
                    content: {
                        background: `${componentTheme.menuBackgroundColorRGB}`,
                        color: `${componentTheme.menuTextColorRGB}`,

                        "&:hover": {
                            background: `${componentTheme.menuHoverBackgroundColorRGB}`,
                        },

                        "&.selected": {
                            background: `${componentTheme.menuHoverBackgroundColorRGB}`,
                        },
                    },
                }),
        ],
        ({selected, cssClass}) => {
            const inputStyle = {
                border: `${componentTheme.inputBorderWidth}px solid ${componentTheme.inputBorderColorRGB}`,
                background: `${componentTheme.inputBackgroundColorRGB}`,
                color: `${componentTheme.inputTextColorRGB}`,
                borderRadius: `${componentTheme.inputCornerRadius}px`,
            };

            return (
                <>
                    <div className="dropdown-wrapper">
                        <div
                            className="dropdown-label"
                            style={{
                                color: theme.general.components.inputLabelTextColorRGB,
                            }}
                        >
                            Filter By
                        </div>

                        {DropdownSelectLive({
                            className: cx("dropdown-select"),
                            prioritizeSelect: false,
                            list: ["True", "False"],
                            valueToLabel: (v) => v,
                            isSelected: (v) => selected.value === v,
                            onChange: (v) => selected.onChange(v),
                            toggleStyle: inputStyle,
                            iconColor: componentTheme.inputIconColorRGB,
                            dropdownCss: cssClass,
                            borderRadius: componentTheme.menuCornerRadius,
                        })}
                    </div>

                    <FilterSubmitButtons
                        disabled={selected.value == null}
                        onReset={() => {
                            close?.();
                            search.searchColumn({
                                cIndex: columnIndex,
                                search: null,
                            });
                        }}
                        onSubmit={() => {
                            close?.();

                            search.searchColumn({
                                cIndex: columnIndex,
                                search: selected.value,
                                $type: "BooleanTableSearch",
                            });
                        }}
                    />
                </>
            );
        }
    );
};
