import { ButtonIcon } from "@common/form/buttons/button-icon/button-icon";
import { Button } from "@common/form/buttons/button/button";
import { scope } from "@common/react/scope";
import { stateArraySupport } from "@common/utils/state-array-support";
import { TrashIcon } from "../../../../../../../../../../../../common/icons/trash-icon";
import { ConditionDropdown, getConditionalDropdownOptions } from "./condition-dropdown/condition-dropdown";
import { StaticValueInput } from "./static-value-input/static-value-input";
import { TableSelectField } from "./table-select-field/table-select-field";

export const SingleColorFormatRules = ({singleColorFormatRulesState, tile}) => {
    return (
        <>
            {singleColorFormatRulesState.value.map((andBlock, aI) => {
                const andBlockState = stateArraySupport(singleColorFormatRulesState, [aI]);

                return (
                    <>
                        <div className="cfr-and-block" key={aI}>
                            {andBlock.fieldConditions.map((orBlock, oI) => {
                                const orBlockState = stateArraySupport(scope(andBlockState, ["fieldConditions"]), [oI], true);
                                const tableColumns = tile.value.actualValueFields.concat(tile.value.aggregatedValues);
                                const tableFieldColumn = tableColumns.find((c) => c.modelColumnID == orBlockState.value.field?.modelColumnID);
                                const isAggregatedField = tile.value.aggregatedValues.find((c) => c.modelColumnID == orBlockState.value.field?.modelColumnID);

                                return (
                                    <>
                                        <div className="cfr-or-block" key={oI}>
                                            <TableSelectField
                                                enable
                                                label="Table Field"
                                                columns={tableColumns}
                                                value={orBlockState.value.field}
                                                onChange={(value) => {
                                                    const newColumn = tableColumns.find((c) => c.modelColumnID == value?.modelColumnID);
                                                    const isAggregatedField = tile.value.aggregatedValues.find((c) => c.modelColumnID == value?.modelColumnID);
                                                    let conditionalType = orBlockState.value.conditionType;
                                                    if (orBlockState.value.field?.dataType != newColumn.dataType) {
                                                        const conditionalOption = getConditionalDropdownOptions(isAggregatedField).find((option) => option.logic(newColumn));

                                                        if (!conditionalOption.conditions.find((c) => c == conditionalType)) {
                                                            conditionalType = conditionalOption.conditions[0];
                                                        }
                                                    }

                                                    orBlockState.onChange({
                                                        field: value,
                                                        conditionType: conditionalType,
                                                        comparisonStaticValue: null,
                                                        comparisonField: null,
                                                    });
                                                }}
                                                className="or-block-column"
                                            />

                                            <ConditionDropdown
                                                tableField={tableFieldColumn}
                                                isAggregatedField={isAggregatedField}
                                                className="or-block-column"
                                                state={{
                                                    value: orBlockState.value.conditionType,
                                                    onChange: (v) =>
                                                        orBlockState.onChange({
                                                            conditionType: v,
                                                        }),
                                                }}
                                            />

                                            <TableSelectField
                                                enable={tableFieldColumn}
                                                label="Value"
                                                className="or-block-column"
                                                columns={tableColumns.filter((c) => c.modelColumnID != orBlockState.value.field?.modelColumnID)}
                                                value={orBlockState.value.comparisonField}
                                                isBooleanTableField={tableFieldColumn?.dataType == "Bool"}
                                                onChange={(value) => {
                                                    if (!value?.modelColumnID) {
                                                        orBlockState.onChange({
                                                            comparisonStaticValue: tableFieldColumn?.dataType == "Bool" ? value : "",
                                                            comparisonField: null,
                                                        });
                                                    } else {
                                                        orBlockState.onChange({
                                                            comparisonStaticValue: null,
                                                            comparisonField: value,
                                                        });
                                                    }
                                                }}
                                                useStatic
                                                staticValue={orBlockState.value.comparisonStaticValue}
                                            />

                                            {orBlockState.value.comparisonStaticValue != null && tableFieldColumn?.dataType != "Bool" ? (
                                                <StaticValueInput
                                                    className="or-block-column"
                                                    dataType={tableFieldColumn?.dataType}
                                                    value={orBlockState.value.comparisonStaticValue}
                                                    onChange={(value) => {
                                                        orBlockState.onChange({
                                                            comparisonStaticValue: ["Double", "Int"].indexOf(tableFieldColumn?.dataType) > -1 ? value?.toString?.() : value,
                                                        })
                                                    }
                                                        
                                                    }
                                                />
                                            ) : (
                                                <div className="or-block-column" />
                                            )}

                                            <div className="cfs-action-buttons">
                                                {oI == andBlock.fieldConditions.length - 1 ? (
                                                    <Button
                                                        btnType="secondary"
                                                        size="small"
                                                        onClick={() => {
                                                            andBlockState.onChange({
                                                                fieldConditions: andBlockState.value.fieldConditions.concat([{}]),
                                                            });
                                                        }}
                                                    >
                                                        OR
                                                    </Button>
                                                ) : (
                                                    <b className="or-text">OR</b>
                                                )}

                                                {!(singleColorFormatRulesState.value.length == 1 && andBlock.fieldConditions.length == 1) && (
                                                    <ButtonIcon
                                                        className="delete-button"
                                                        btnType="ghost"
                                                        size="small"
                                                        onClick={() => {
                                                            const updated = andBlockState.value.fieldConditions.filter((_, index) => index != oI);
                                                            if (updated.length == 0) {
                                                                singleColorFormatRulesState.onChange(singleColorFormatRulesState.value.filter((_, index) => index != aI));
                                                            } else {
                                                                andBlockState.onChange({
                                                                    fieldConditions: andBlockState.value.fieldConditions.filter((_, index) => index != oI),
                                                                });
                                                            }
                                                        }}
                                                        icon={<TrashIcon />}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </>
                                );
                            })}
                        </div>

                        {singleColorFormatRulesState.value.length - 1 > aI && (
                            <div className="and-text">
                                <b>AND</b>
                            </div>
                        )}
                    </>
                );
            })}

            {singleColorFormatRulesState.value.length > 0 && (
                <Button
                    onClick={() => {
                        singleColorFormatRulesState.onChange(singleColorFormatRulesState.value.concat([{fieldConditions: [{}]}]));
                    }}
                    className="and-btn"
                    size="small"
                    btnType="border"
                >
                    AND
                </Button>
            )}
        </>
    );
};
