import {getPiePointTableCompare} from "./pie-point-table-compare";
import {tooltipLayout} from "./tooltip-layout";
import {getPiePointTable} from "./pie-point-table";

export const renderPieDonutChartTooltip = ({points, tile, theme, rawData, formatters, isCompare}) => {
    if (isCompare) {
        const extractedPoint = getExtractedPointPieCompare({points});
        return (
            extractedPoint &&
            tooltipLayout({
                content: `<div class="table">
                    ${getPiePointTableCompare({
                        tile,
                        extractedPoint,
                        rawData,
                        formatters,
                        theme,
                    })}
                </div>`,
                theme,
                isCompare,
            })
        );
    }

    const extractedPoint = getExtractedPointPie({points});

    return (
        extractedPoint &&
        tooltipLayout({
            content: `<div class="non-compare-header">
                <div class="title">${extractedPoint.title}</div>
            </div>
            <div class="table">
                ${getPiePointTable({tile, extractedPoint, formatters, theme})}
            </div>`,
            theme,
        })
    );
};

const getExtractedPointPie = ({points}) => {
    const point = points[0];
    const series = point.series;

    if (!series) {
        return;
    }

    return {
        name: series.name,
        color: point.color,
        // dimCategory: point.key,
        dimCategory: point.point?.options.name,
        dimValue: point.x,
        value: point.y,
        percentage: point.point?.realPercentage,
        title: series.userOptions.measureAxisTitle,
    };
};

const getExtractedPointPieCompare = ({points}) => {
    const point = points[0];
    const series = point.series;

    if (!series) {
        return;
    }

    if (series.userOptions.isCompare && point) {
        const respectivePoint = (series?.chart?.series || [])
            .find?.((s) => !s.userOptions.isCompare)
            ?.data.find?.((s) => s.name === point.point?.options?.name);

        return {
            name: series.name,
            color: respectivePoint?.color,
            dimCategory: respectivePoint?.name,
            dimValue: respectivePoint?.x,
            compareDimValue: point.x,
            value: respectivePoint?.y,
            compareValue: point.y,
            percentage: respectivePoint?.realPercentage,
            comparePercentage: point.point?.realPercentage,
            title: series.userOptions.measureAxisTitle,
        };
    }

    const respectivePoint = series.chart.series.find((s) => s.userOptions.isCompare).data.find((s) => s.name === point.point?.options.name);

    return {
        name: series.name,
        color: point.color,
        // dimCategory: point.key,
        dimCategory: point.point?.options.name,
        dimValue: point.x,
        compareDimValue: respectivePoint?.x,
        value: point.y,
        compareValue: respectivePoint?.y,
        percentage: point.point?.realPercentage,
        comparePercentage: respectivePoint?.realPercentage,
        title: series.userOptions.measureAxisTitle,
    };
};
