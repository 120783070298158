exports.sampleAuthEndpoint = {
    id: "aaa",
    method: "Get",
    endpoint: "https://web.staging.verbdata.app/authMock/595745e5-d238-4ff4-905e-6fb73de9d98d.json",
    headers: {
        Authorization: "Bearer {btoken}",
    },
    bodyContentType: "None",
    bodyRaw: null,
    bodyFormData: {
        username: "{uname}",
    },
    resultMapping: {
        // "User.ID":"userID"
        "User.ID": {
            property: "UserID",
            mappingType: "SingleValue",
        },
    },
    parameters: ["btoken", "uname"],
};
