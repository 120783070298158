import "./spark-display.scss";

import * as React from "react";

import {scope} from "@common/react/scope";
import {cs} from "@common/react/chain-services";
import {stateToSelect} from "@common/ui-components/form/state-to-select";
import {getPath} from "@common/utils/arr-path";

import {ThumbnailSelect} from "../../../../../../../../../common/thumbnail-select/thumbnail-select";
import {LineWidthSelect} from "../../../common/line-width-select";
import {lineTypes} from "../../../line-chart/panels/display/line-display";

export const SparkDisplay = ({tile, colorsTileConfig, form}) => ({
    initExpand: true,
    label: "Spark Display",
    render: () => {
        const type = getPath(tile.value, ["style", "sparkLineChartType"]);
        return sections[type]({tile, colorsTileConfig, form})
    },
});


export const sections = {
    "Area": ({tile, colorsTileConfig}) => cs(
        ({}) => {
            const {value, onChange} = scope(tile, ["style", "lineChartDisplayType"]);
            const onChangeLineDisplay = (lineDisplay) => onChange(`${lineDisplay}`);

            return (
                <div className="area-display-section-df8">
                    <div className="subsection">
                        <div className="content">
                            {ThumbnailSelect({
                                list: [
                                    {
                                        label: "Straight",
                                        value: "Area",
                                        thumbnail: require("../../../line-chart/panels/display/icons/line-straight-icon.svg"),
                                    },
                                    {
                                        label: "Smooth",
                                        value: "SplineArea",
                                        thumbnail: require("../../../line-chart/panels/display/icons/line-smooth-icon.svg"),
                                    },
                                ],
                                isSelected: (type) => type.value === value,
                                onChange: (type) => onChangeLineDisplay(type.value),
                            })}

                            {LineWidthSelect(scope(tile, ["style", "thickness"]))}

                            {colorsTileConfig?.render()}
                        </div>
                    </div>
                </div>
            );
        }
    ),
    "Line": ({tile, colorsTileConfig}) => cs(
        ({}) => {
            return (
                <div className="line-display-section-64f">
                    <div className="line-display">
                        {ThumbnailSelect({
                            ...stateToSelect(scope(tile, ["style", "lineChartDisplayType"]), ["name"]),
                            list: lineTypes,
                        })}

                        {LineWidthSelect(scope(tile, ["style", "thickness"]))}

                        {colorsTileConfig?.render()}
                    </div>
                </div>
            );
        }
    ),
    "Bar": ({colorsTileConfig}) => (
        <div className="vertical-colors-6kl">
            {colorsTileConfig?.render()}
        </div>
    ),
};
