import "./change-password-dialog.scss";

import * as React from "react";

import {cs} from "@common/react/chain-services";
import {DialogService} from "../../../common/dialog/dialog-service";
import {UseState} from "@common/react/use-state";
import {Form2} from "@common/react/cs-form/form2";
import {required} from "@common/react/cs-form/validators/required";
import {equalsPath} from "@common/react/cs-form/validators/equals-path";
import {keyed} from "@common/react/keyed";
import {TextInput} from "../../../../../../common/form/text-input/text-input";
import {ffToBasicInput} from "../../../../../../common/form/ff-to-basic-input";
import {consumeContext} from "@common/react/context";
import {password} from "@common/react/cs-form/validators/password";
import {Button} from "../../../../../../common/form/buttons/button/button";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";

export const ChangePasswordDialog = ({email, next: rootNext}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    registryRender: true,
                    render: ({resolve}) => ({
                        title: "Update Password",
                        width: 480,
                        content: next({
                            resolve,
                        }),
                        initShow: true,
                        className: "change-password-dialog-wrapper-z33",
                    }),
                    next: rootNext,
                }),
        ],
        ({modal}) =>
            cs(
                consumeContext("apis"),
                ["params", (_, next) => UseState({next})],
                ["saving", (_, next) => UseState({next})],
                ["error", (_, next) => UseState({next})],
                [
                    "form",
                    ({params, saving, apis, error}, next) =>
                        Form2({
                            fields: {
                                currentPassword: [required, password],
                                newPassword: [required, password],
                                newPasswordConfirm: [required, equalsPath("newPassword")],
                            },
                            data: params,
                            onSubmit: async () => {
                                try {
                                    saving.onChange(true);
                                    error.onChange(null);
                                    await apis.user.changePassword({
                                        email,
                                        ...params.value,
                                    });
                                    params.onChange(null);
                                    modal.resolve();
                                } catch (err) {
                                    error.onChange(err.message);
                                    saving.onChange(false);
                                }
                            },
                            next,
                        }),
                ],
                ({form, saving, error}) => (
                    <div className="change-password-dialog-z33">
                        <VerbDialogBodyScrollbar>
                            <div className="error-message">{error.value}</div>

                            <div className="messages">
                                {[
                                    {
                                        label: "Current Password",
                                        value: "currentPassword",
                                        info: "The password must be at least 8 characters and contain at least one upper case letter, lower case letter, number and special character.",
                                    },
                                    {
                                        label: "New Password",
                                        value: "newPassword",
                                        info: "The password must be at least 8 characters and contain at least one upper case letter, lower case letter, number and special character.",
                                    },
                                    {
                                        label: "Confirm New Password",
                                        value: "newPasswordConfirm",
                                    },
                                ].map((field, index) =>
                                    cs(keyed(index), () =>
                                        TextInput({
                                            label: field.label,
                                            ...ffToBasicInput(form.field(field.value)),
                                            type: "password",
                                            info: field.info,
                                        })
                                    )
                                )}
                            </div>
                        </VerbDialogBodyScrollbar>

                        <div className="buttons">
                            <Button
                                btnType="secondary"
                                onClick={() => {
                                    modal.resolve();
                                }}
                            >
                                Cancel
                            </Button>
                            <Button disabled={saving.value} onClick={() => form.submit()}>
                                Update
                            </Button>
                        </div>
                    </div>
                )
            )
    );
