import {cs} from "@common/react/chain-services";
import {FieldSection} from "../../../field-section/field-section";
import {FieldInfoProvider} from "../../../../../../tile-tab/chart-types/common/field-info-provider/field-info-provider";
import * as React from "react";
import {UseState} from "@common/react/use-state";
import ArrowChervonDown, {ArrowChervonRight} from "../../../../../../../../../../../../assets/icons/common/ArrowChervonDown";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {sortIconsTypes} from "../sort-icons-types";
import {scope} from "@common/react/scope";
import "./pivot-table-sorting.scss";
import {consumeContext} from "@common/react/context";
import {getAggType} from "../../../render-field-control/field-control";
import {ArrowChevDown, ArrowChevRight} from "@common/ui-components/icons/global-icons";
import {cx} from "emotion";
import {unique} from "@common/utils/collections";
import {getPath} from "@common/utils/arr-path";

export const PivotTableSorting = ({tile}) =>
    cs(() => {
        return (
            <>
                {[
                    {value: "rowFields", label: "Rows"},
                    {value: "columnFields", label: "Columns"},
                ]
                    .filter((f) => tile.value[f.value]?.length > 0)
                    .map((field, index) => {
                        const state = scope(tile, [field.value]);

                        return FieldSection({
                            header: FieldInfoProvider({
                                field: <div className="text">{field.label}</div>,
                            }),
                            key: index,
                            content: unique(
                                state.value.filter((v) => v.id),
                                (f) => `${f.modelColumnID}-${f.modelTableID}-${f.dataType == "DateTime" ? "DateTime" : Math.random()}`
                            ).map((field) =>
                                SortingFieldSelection({
                                    value: field,
                                    onChange: (v) =>
                                        state.onChange(
                                            state.value.map((f) => {
                                                if (f.dataType == "DateTime") {
                                                    if (f.modelColumnID == field.modelColumnID && f.modelTableID == field.modelTableID)
                                                        return {
                                                            ...f,
                                                            sortDirection: v.sortDirection,
                                                        };
                                                }

                                                if (f.id == field.id) return v;
                                                return f;
                                            })
                                        ),
                                    valueFields: tile.value.valueFields,
                                })
                            ),
                        });
                    })}
            </>
        );
    });

const SortingFieldSelection = ({value, onChange, valueFields}) =>
    cs(
        consumeContext("getFieldInfo"),
        consumeContext("collection"),
        [
            "open",
            (_, next) =>
                UseState({
                    next,
                    initValue: true,
                }),
        ],
        ({open, getFieldInfo, collection}) => {
            const list = value.dataType == "DateTime" ? [null] : [null, ...valueFields.map((_, index) => index)];
            const selectedField = value.aggregateSortIndex === null ? value : valueFields[value.aggregateSortIndex] || value;
            const sortIcon = sortIconsTypes[getFieldInfo(selectedField).type];

            return (
                <div className="sorting-field-selection-a33">
                    <div className="field-label" onClick={() => open.onChange(!open.value)}>
                        {!open.value ? <ArrowChevRight /> : <ArrowChevDown />}

                        {getFieldInfo(value).visualNameShort}
                    </div>

                    {open.value && sortIcon && (
                        <div className="field-content">
                            {DropdownSelect({
                                className: "dropdown",
                                label: "Sort By",
                                list,
                                isSelected: (v) => value.aggregateSortIndex === v,
                                onChange: (v) => onChange({...value, aggregateSortIndex: v}),
                                valueToLabel: (v) => {
                                    const renderFieldValue = (field) => {
                                        const fieldInfo = getFieldInfo(field);
                                        return (
                                            <span>
                                                {fieldInfo.visualNameShort} {getAggType(field)}
                                            </span>
                                        );
                                    };

                                    if (v === null) return renderFieldValue(value);
                                    return renderFieldValue(valueFields[v]);
                                },
                            })}

                            <div className="sort-icons">
                                <div
                                    className={cx("sort-item", value?.sortDirection == "Asc" && "selected")}
                                    onClick={() => {
                                        onChange({
                                            ...value,
                                            sortDirection: "Asc",
                                        });
                                    }}
                                >
                                    {sortIcon?.asc}
                                </div>
                                <div
                                    className={cx("sort-item", value?.sortDirection == "Desc" && "selected")}
                                    onClick={() => {
                                        onChange({
                                            ...value,
                                            sortDirection: "Desc",
                                        });
                                    }}
                                >
                                    {sortIcon?.desc}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            );
        }
    );
