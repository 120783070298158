import {cs} from "@common/react/chain-services";
import {CheckboxLineGroupFlex} from "../../../../../../../common/checkbox-line-group/checkbox-line-group-flex";
import {scope} from "@common/react/scope";
import * as React from "react";
import {tooltipService3} from "../../../../../../../common/tooltip3/tooltip-service-3";
import {CheckboxLine} from "../../../../../../../common/checkbox-line/checkbox-line";

export const TimeFilterConfig = ({state}) =>
    cs(tooltipService3({direction: "below"}), ({tooltip}) => (
        <div className="comparison handling-missing-dates-5qq">
            <div className="header">TIME OF DAY FILTER</div>

            <div className="content">
                {CheckboxLine({
                    state,
                    label: (
                        <>
                            Allow for time of day filters
                            <i {...tooltip(() => `Users may specify the time within the start and end date.`)} className="material-icons">
                                info
                            </i>
                        </>
                    ),
                })}
            </div>
        </div>
    ));
