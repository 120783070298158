import React, {createElement as h} from "react";
import {cs} from "@common/react/chain-services";
import "./preview-list-select.scss";
import {ArrowChervonDown} from "@common/ui-components/charts/common/icons/icons";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";
import {cx} from "emotion";
import {TextInput} from "../../../../../../common/form/text-input/text-input";
import {scope} from "@common/react/scope";
import {UseState} from "@common/react/use-state";
import {RadioLineLarge} from "../../radio-line/radio-line-large";
import {AnyAction} from "@common/react/any-action";
import {consumeContext} from "@common/react/context";
import {isMatchText} from "@common/utils/strings";

export const PreviewListSelect = ({list, state, themeID}) =>
    cs(
        consumeContext("routing"),
        ["open", (_, next) => UseState({next, initValue: false})],
        ["keyword", (_, next) => UseState({next, initValue: ""})],
        ({open}, next) =>
            h(AnyAction, {
                disabled: !open?.value,
                fn: () => open.onChange(false),
                next,
            }),
        ({open, keyword, routing}) => {
            let usingTheme = list.filter((item) => item.themeID == themeID && isMatchText(item.name, keyword.value));
            let allCollections = list.filter((item) => item.themeID != themeID && isMatchText(item.name, keyword.value));

            return (
                <div className="preview-list-select-a44" onClick={() => open.onChange(true)}>
                    <div className="select-value">
                        {state.value ? list.find((item) => item.id == state.value)?.name : "Auto"} <span className="material-icons-outlined arrow-down">keyboard_arrow_down</span>
                    </div>

                    {open?.value && (
                        <div className={cx("preview-list", open?.value && "show")}>
                            <div className="search-input">
                                <i className="far fa-search" />
                                {TextInput({
                                    placeholder: "Search for collection...",
                                    state: scope(keyword),
                                })}
                            </div>

                            <VerbScrollbar maxHeight="250px" className="body-wrapper">
                                {RadioLineLarge({
                                    selected: !state.value,
                                    onClick: () => {
                                        state.onChange(null);
                                        routing.goto("edit-theme", {
                                            themeId: themeID,
                                        });
                                        setTimeout(() => {
                                            open.onChange(false);
                                        }, 0);
                                    },
                                    className: "radio-line",
                                    size: "large",
                                    label: "Auto",
                                })}

                                {!!usingTheme.length && (
                                    <>
                                        <div className="section-title">USING THIS THEME</div>
                                        {usingTheme
                                            .sort((a, b) => (a.name > b.name ? 1 : -1))
                                            .map((collection) =>
                                                RadioLineLarge({
                                                    selected: collection.id == state.value,
                                                    onClick: () => {
                                                        state.onChange(collection.id);
                                                        routing.goto("edit-theme", {
                                                            themeId: themeID,
                                                            collectionID: collection.id,
                                                        });
                                                        setTimeout(() => {
                                                            open.onChange(false);
                                                        }, 0);
                                                    },
                                                    className: "radio-line",
                                                    size: "large",
                                                    label: collection.name,
                                                    key: collection.id,
                                                })
                                            )}
                                    </>
                                )}

                                {!!allCollections.length && <div className="section-title">ALL COLLECTIONS</div>}

                                {allCollections
                                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                                    .map((collection) =>
                                        RadioLineLarge({
                                            selected: collection.id == state.value,
                                            onClick: () => {
                                                state.onChange(collection.id);
                                                routing.goto("edit-theme", {
                                                    themeId: themeID,
                                                    collectionID: collection.id,
                                                });
                                                setTimeout(() => {
                                                    open.onChange(false);
                                                }, 0);
                                            },
                                            className: "radio-line",
                                            size: "large",
                                            label: collection.name,
                                            key: collection.id,
                                        })
                                    )}
                            </VerbScrollbar>
                        </div>
                    )}
                </div>
            );
        }
    );
