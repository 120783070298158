import * as React from "react";
import {cx} from "emotion";
import {TableColumnLine} from "../../../common/table-column-line/table-column-line";
import {dataViewColor, getDSColorBasedOnIndex} from "../../../common/data-source-colors";
import {chain} from "@common/utils/fs";
import {reverse} from "@common/utils/collections";
import "./render-relationship-line.scss";

export const rRelationshipLine =
    ({getModelColumn, originTableId, dataSources}) =>
    ({relationship, control}) => {
        const leftCol = getModelColumn(relationship.leftColumnID);
        const rightCol = getModelColumn(relationship.rightColumnID);
        const getColor = (c) => (c.dsIndex != null ? dataSources[c.dsIndex]?.colorRGB || getDSColorBasedOnIndex(c.dsIndex) : dataViewColor);

        const cols = chain(
            [leftCol, rightCol].map((c) => (
                <div className={cx("column-line", c.table.id === originTableId ? "left" : "right")} key={c.column.id}>
                    {TableColumnLine({
                        color: getColor(c),
                        table: c.table,
                        column: c.column,
                    })}
                </div>
            )),
            (_) => (leftCol.table.id === originTableId ? _ : reverse(_))
        );

        return (
            <div className="relationship-line-gh7 relationship-line">
                {cols}
                <div className="action">{control}</div>
            </div>
        );
    };
