import React from "react";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {Load2} from "@common/react/load2";
import {getRandomInt} from "@common/utils/random";
import {keyed1} from "@common/react/keyed";
import {changePath} from "@common/utils/arr-path";
import {chain} from "@common/utils/fs";
import {UseState} from "@common/react/use-state";
import {Invoke} from "@common/react/invoke";
import {waitUntil} from "@common/utils/wait-until";
import {EditTransformation} from "./edit-transformation";

export const EditDataView = ({environment}) =>
    cs(
        consumeContext("routing"),
        consumeContext("apis"),
        consumeContext("model"),
        ({routing}, next) =>
            keyed1({
                key: routing.params.transformationId,
                next,
            }),
        ["loading", (_, next) => UseState({next, initValue: false})],
        ({loading, model, apis, routing: {params, goto}}, next) => {
            if (!loading.value && model.value && params.transformationId === "undefined" && params.isNew) {
                return Invoke({
                    fn: () => {
                        loading.onChange(true);
                        const newName = `New View ${getRandomInt(1000, 9999)}`;
                        const initTransformation = {
                            $type: "ViewTransformation",
                            modelID: model.value.id,
                            name: newName,
                            steps: [
                                {
                                    $type: "ModelTableDatasetStep",
                                    position: {x: 400, y: 100},
                                    name: "Table",
                                    modelID: model.value.id,
                                },
                                {
                                    $type: "ViewOutputStep",
                                    name: newName,
                                    position: {x: 400, y: 580},
                                },
                            ],
                        };
                        (async () => {
                            const newTransformation = await apis.transformation.upsertTransformation(initTransformation);
                            if (newTransformation.id) {
                                let newModel = await model.reload();
                                newModel = changePath(newModel, ["tables"], (t) =>
                                    chain(t, (_) =>
                                        _?.map((t) =>
                                            t.transformationID === newTransformation.id
                                                ? {
                                                      ...t,
                                                      excludeFromModel: true,
                                                  }
                                                : t
                                        )
                                    )
                                );

                                await Promise.all([model.change(() => newModel), apis.model.upsertModel(newModel)]);

                                goto("edit-data-view", {
                                    tab: "build",
                                    transformationId: newTransformation.id,
                                    isNew: true,
                                });
                            }
                        })();
                    },
                });
            }

            return next();
        },
        [
            "transformation",
            ({apis, routing, model}, next) => {
                if (!model.value || routing.params.transformationId == "undefined") {
                    return next();
                }

                const getTransformation = async () => {
                    const fetch = () => apis.transformation.getTransformation(routing.params.transformationId);
                    let res;

                    const success = await waitUntil(
                        async () => {
                            try {
                                res = await fetch();
                                return true;
                            } catch (e) {
                                return false;
                            }
                        },
                        {timeLimit: 2 * 60 * 1000, intervalDelay: 5000}
                    );

                    if (success) {
                        return res;
                    }
                };

                return Load2({
                    fetch: () => getTransformation(),
                    next,
                });
            },
        ],
        ({transformation}) => EditTransformation({environment, transformation})
    );
