const adjustHorizontalBarDataLabels = ({chart, position}) => {
    if (position !== "OutsideAbove") {
        return;
    }

    const mainBarSeries = chart.series.filter((s) => !s.userOptions.isCompare);

    const adjustEachSeries = (series) =>
        series.points.forEach((p) => {
            if (!p.dataLabel || !p.graphic.element) {
                return;
            }
            const {1: translateX, 2: translateY} = p.dataLabel.element.getAttribute("transform").match(/translate\((.*),(.*)\)/);
            const newDataLabelTranslateY = translateY - p.graphic.element.getBBox().width * 0.5 - (p.dataLabel.element.getBBox().height * 2) / 3;
            p.dataLabel.element.setAttribute("transform", `translate(${translateX}, ${newDataLabelTranslateY})`);
        });

    mainBarSeries.forEach((series) => adjustEachSeries(series));
};
exports.adjustHorizontalBarDataLabels = adjustHorizontalBarDataLabels;
