import * as React from "react";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {stateToSelect} from "@common/ui-components/form/state-to-select";

export const LineWidthSelect = (state) =>
    DropdownSelect({
        label: "Line Width",
        list: ["Thin", "Medium", "Thick"].map((value) => ({
            value,
            label: value === "Medium" ? "Standard" : value,
        })),
        valueToLabel: (v) => v.label,
        ...stateToSelect(state, ["value"]),
    });
