import React from "react";
import * as signalR from "@microsoft/signalr";

import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {Static2} from "@common/react/static-2";
import {UseState} from "@common/react/use-state";
import {fragments} from "@common/react/fragments";
import {OnUnmounted} from "@common/react/on-unmounted";
import {Invoke} from "@common/react/invoke";
import {urlPath} from "../../../../../tools/fetcher/url-path";
import {LocalEvent} from "@common/react/local-event";

export const SignalRHub = ({url, getItemId, next: rootNext}) =>
    cs(
        ({}, next) => {
            return !!getItemId ? next() : null;
        },
        consumeContext("auth"),
        consumeContext("tenant"),
        consumeContext("routing"),
        ["hub", (_, next) => Static2({next})],
        ["events", (_, next) => LocalEvent({next})], // can't register new events after starting signalR, need a middle-ware to manage events
        ["currentEditingUser", (_, next) => UseState({next})],
        ({hub, events, tenant, auth, routing, currentEditingUser}) => {
            const userId = auth.user.id;
            const tenantId = tenant.tenant.id;
            const envId = routing.params.envId;
            const itemId = getItemId({routing});

            return fragments(
                OnUnmounted({
                    action: async () => {
                        const connection = hub.get();
                        if (connection) {
                            const oldUser = currentEditingUser.get();
                            if (oldUser?.id == userId) {
                                const resp = await connection.invoke("release", userId, tenantId, envId, itemId);
                            }
                            await connection.stop();
                        }
                        events.removeAllListeners();
                    },
                }),
                tenantId
                    ? Invoke({
                          onMounted: async () => {
                              let connection = new signalR.HubConnectionBuilder()
                                  .withAutomaticReconnect()
                                  .withUrl(urlPath(url), {
                                      accessTokenFactory: () => auth.access,
                                      headers: {
                                          "x-tenant-id": tenantId,
                                          "x-env-id": envId,
                                      },
                                  })
                                  .build();

                              hub.set(connection);
                              connection.on("accessResponse", (...args) => {
                                  events.emit("accessResponse", ...args);
                              });

                              connection.on("accessRequest", (...args) => {
                                  events.emit("accessRequest", ...args);
                              });

                              connection.on("forceRelinquishAccess", (...args) => {
                                  events.emit("forceRelinquishAccess", ...args);
                              });

                              connection.on("cancelAccessRequest", (...args) => {
                                  events.emit("cancelAccessRequest", ...args);
                              });

                              connection.onclose((e) => {
                                  // console.log("single-editor close");
                                  // console.log(e);
                              });

                              try {
                                  await connection.start();
                                  events.emit("started");
                                  const user = await connection.invoke("checkIn", userId, tenantId, envId, itemId);
                                  currentEditingUser.onChange(user);
                                  events.emit("checkedIn", user, user.id != userId);
                              } catch (e) {
                                  // console.log(e);
                              }
                          },
                      })
                    : null,
                currentEditingUser.value
                    ? rootNext({
                          connection: hub.get(),
                          needAccess: currentEditingUser.value ? currentEditingUser.value.id != userId : false,
                          userId,
                          events,
                          itemId,
                          tenantId,
                          envId,
                          currentEditingUser,
                          start: async () => {
                              const connection = hub.get();
                              if (!connection) {
                                  return;
                              }
                              await connection.start();
                              events.emit("started");
                          },
                          requestAccess: async () => {
                              const connection = hub.get();
                              if (!connection) {
                                  return;
                              }
                              await connection.invoke("requestAccess", userId, tenantId, envId, itemId);
                          },
                          respondToAccessRequest: async (requestingUserID, relinquishAccess) => {
                              const connection = hub.get();
                              if (!connection) {
                                  return;
                              }
                              const resp = await connection.invoke(
                                  "respondToAccessRequest",
                                  userId,
                                  tenantId,
                                  envId,
                                  itemId,
                                  relinquishAccess,
                                  requestingUserID
                              );
                          },
                          checkIn: async () => {
                              const connection = hub.get();
                              if (!connection) {
                                  return;
                              }
                              const user = await connection.invoke("checkIn", userId, tenantId, envId, itemId);
                              currentEditingUser.onChange(user);
                              events.emit("checkedIn", user, user.id != userId);
                          },
                          demandAccess: async () => {
                              const connection = hub.get();
                              if (!connection) {
                                  return;
                              }
                              await connection.invoke("demandAccess", userId, tenantId, envId, itemId);
                          },
                          cancelRequestAccess: async () => {
                              const connection = hub.get();
                              if (!connection) {
                                  return;
                              }
                              await connection.invoke("cancelRequestAccess", userId, tenantId, envId, itemId);
                          },
                      })
                    : null
            );
        }
    );
