import * as React from "react";
import {cs} from "@common/react/chain-services";
import {ShowHideToggle} from "../../../../../../../../../../../../../common/form/toggles/show-hide-toggle";
import "./legend.scss";
import {scope} from "@common/react/scope";
import {LegendPositionSelect} from "../../../common/legend-position-select";
import {Static2} from "@common/react/static-2";
import {UseState} from "@common/react/use-state";
import {getHiddenPositionFields} from "./legend-display-rules";
import {FontSizeSelect} from "../../../common/font-size-select";
import {LegendSizeSelect} from "../../../common/legend-size-select";
import {cascadeFind} from "@common/utils/cascade";
import {getFieldType} from "@common/ui-components/charts/common/get-field-type";
import {isEmpty} from "@common/utils/objects";

export const Legend = ({tile, size}) => {
    const rControl = () => {
        const hiddenPositionFieldsLength = getHiddenPositionFields(size).length;
        const isBarDataColorAppliedByDimension =
            tile?.value.style?.barDataColorAppliedBy === "Dimension" &&
            !cascadeFind(tile?.value.xAxisFields, "[*].measureFields", (value) => value.length > 1) &&
            getFieldType(tile?.value["yAxisField"]) !== "date" &&
            isEmpty(tile?.value?.groupField);

        if (hiddenPositionFieldsLength >= 6 || isBarDataColorAppliedByDimension) {
            const message = isBarDataColorAppliedByDimension
                ? `Legend is not available when data colors are applied by the dimension`
                : `Legend can only be shown when the chart is at least 12 squares wide and 2 squares high or 6 squares wide and 4 squares high.`;

            return cs(["pos", (_, next) => UseState({next})], ["hideBtnRef", (_, next) => Static2({next})], ({hideBtnRef, pos}) => (
                <>
                    <div
                        className="hide-btn-a22"
                        ref={hideBtnRef.set}
                        onMouseEnter={() => {
                            const {top, right} = hideBtnRef.get().getBoundingClientRect();
                            pos.onChange({
                                top: top - 95,
                                left: right - 110,
                            });
                        }}
                        onMouseLeave={() => {
                            pos.onChange(null);
                        }}
                    >
                        Hide
                    </div>

                    {pos.value && (
                        <div
                            className="tooltip-box-a44 bottom"
                            style={{
                                ...pos.value,
                                position: "fixed",
                                zIndex: 10,
                            }}
                        >
                            {message}
                        </div>
                    )}
                </>
            ));
        }

        return ShowHideToggle({
            state: scope(tile, ["style", "legendStyle", "legendShown"]),
        });
    };

    return {
        label: "Legend",
        iconTooltip: (
            <div className="icon-tooltip-a22">
                <i className="fa fa-mobile-alt" style={{color: "#FF7A59"}} />
                <div className="tooltip-box-a44 bottom">
                    Left & Right Vertical legends cannot display on small mobile devices. If your chart is at least 4 squares high, the legend will be shown as horizontal.
                </div>
            </div>
        ),
        control: rControl(),
        render: () =>
            cs(() => (
                <div className="combo-chart-legend-aw4">
                    <div className="position-select has-tooltip">
                        {LegendPositionSelect(scope(tile, ["style", "legendStyle", "legendPosition"]), size)}
                        {LegendSizeSelect(scope(tile, ["style", "legendStyle", "legendSize"]), tile.value.style.legendStyle)}
                        {/* {FontSizeSelect(scope(tile, ["style", "legendStyle", "legendFontSize"]))} */}
                    </div>
                </div>
            )),
    };
};
