import "./authentication.scss";

import React from "react";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {EvnAuthentication} from "./env-authentication";
import {consumeContext} from "@common/react/context";
import {Button} from "@common/form/buttons/button/button";
import {BasicPanel} from "@common/ui-components/panel/panel";
// import {Tabs} from "@common/ui-components/tabs/tabs";
// import {keyed} from "@common/react/keyed";
// import {StaticAuthentication} from "./static-authentication/static-authentication";

export const Authentication = ({}) =>
    cs(
        consumeContext("authEndpoint"),
        ({authEndpoint}, next) => {
            if (!authEndpoint.value) {
                return;
            }
            return next();
        },
        ["activeTab", (_, next) => UseState({initValue: "active", next})],
        [
            "tabs",
            ({authEndpoint}, next) => {
                return cs(
                    // [
                    //     "staticAuth",
                    //     (_, next) =>
                    //         next(
                    //             StaticAuthentication({
                    //                 staticEndpoint: authEndpoint.value.find((e) => e.$type === "StaticAuthConfig"),
                    //             })
                    //         ),
                    // ],
                    [
                        "activeAuth",
                        (_, next) =>
                            next(
                                EvnAuthentication({
                                    evnAuthEndpoint: authEndpoint.value.find((e) => e.$type === "EndpointAuthConfig"),
                                })
                            ),
                    ],
                    ({staticAuth, activeAuth}) =>
                        next([
                            {
                                name: "active",
                                label: "Active Auth",
                                render: () => activeAuth,
                            },
                            // {
                            //     name: "static",
                            //     label: "Static Auth",
                            //     render: () => staticAuth,
                            // },
                        ])
                );
            },
        ],
        ({activeTab, tabs}) => {
            const {render} = tabs.find((t) => t.name === activeTab.value) || {};

            return (
                <BasicPanel className="authentication-8as" title="Authentication" style={{height: "100%"}}>
                    {() => (
                        <>
                            {/*{Tabs({*/}
                            {/*    className: "authentication-tabs",*/}
                            {/*    list: tabs,*/}
                            {/*    activeTab,*/}
                            {/*    type: "vertical",*/}
                            {/*})}*/}
                            {/*<div className="authentication-content">{cs(keyed(activeTab.value), () => render())}</div>*/}
                            {render()}
                        </>
                    )}
                </BasicPanel>
            );
        }
    );
