import {createFetcher} from "../../../../tools/fetcher/fetcher";

export const loginSso = async (data) => {
    const fetcher = createFetcher({
        headers: {
            "x-tenant-id": "00000000-0000-0000-0000-000000000000",
        },
    });
    const res = await fetcher.postRaw("/api/user/sso", data);
    if (res.status !== 401 && res.status !== 403) {
        return await res.json();
    } else {
        return null;
    }
};
