import "./js-parameters.scss";

import React from "react";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

import {Button} from "../../../../../../../../../../../../../common/form/buttons/button/button";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {UseState} from "@common/react/use-state";
import {DragIcon, LockIcon, PlusIcon} from "@common/ui-components/icons/global-icons";
import {TrashIconBold} from "../../../../../../../../../../common/icons/trash-icon";
import {getActionFields} from "../../action-fields/action-field-utils";
import {DropdownFieldMapping} from "./dropdown-field-mapping";
import {flatten1} from "@common/utils/collections";
import {defaultAggregationFunctions, defaultDateProperties, getColumnType, getDefaultNumericProperties} from "../../../../chart-types/common/select-field/select-field";

export const getTileFieldFromColumn = (column, fieldType) => {
    const fieldProps = {
        modelID: column.modelID,
        modelTableID: column.modelTableID,
        modelColumnID: column.modelColumnID,
        dataType: column.dataType,
        displayName: column.visualNameShort,
    };

    const columnType = getColumnType(column);

    const otherProps = {
        MeasureTileField: {
            aggregationFunc: defaultAggregationFunctions[columnType],
            numericProperties: getDefaultNumericProperties(columnType),
        },
        DimensionTileField: {
            ...(columnType === "date" && {
                dateProperties: defaultDateProperties,
            }),
        },
        DimensionlessMeasureTileField: {
            aggregationFunc: defaultAggregationFunctions[columnType],
            // sort: fieldProps,
            // limit: fieldProps,
            numericProperties: getDefaultNumericProperties(columnType),
            ...(columnType === "date" && {
                dateProperties: defaultDateProperties,
            }),
        },
        CategoryTileField: {
            ...(columnType === "date" && {
                dateProperties: defaultDateProperties,
            }),
        },
    };

    return {
        ...fieldProps,
        ...otherProps[fieldType],
    };
};

export const JsParameters = ({tile, fieldType, tileAction, tablesInTile}) => {
    const {triggerParameters, tileActionFields, enabledTileFieldIDs: fixedParams} = tileAction.value;

    return cs(
        consumeContext("modelForCollection"),
        consumeContext("getFieldInfo"),
        consumeContext("tileFields"),
        ({modelForCollection}, next) => {
            if (!modelForCollection?.tables) {
                return null;
            }

            return next();
        },
        [
            "localState",
            ({modelForCollection, tileFields}, next) => {
                let list = flatten1(
                    modelForCollection.tables.filter((t) => tablesInTile.includes(t.id)),
                    (t) => t.columns
                );

                return UseState({
                    next,
                    initValue: (triggerParameters || []).map((columnID, i) => ({
                        value: list.find((f) => f.modelColumnID == columnID),
                        id: Date.now() + i,
                    })),
                });
            },
        ],
        ({localState, modelForCollection, getFieldInfo, tileFields}) => {
            const actionFields = getActionFields(tile, getFieldInfo);
            const fixedParam = actionFields.find((a) => fixedParams.includes(a.id));

            const updateState = (updates) => {
                localState.onChange(updates);

                tileAction.onChange({
                    ...tileAction.value,
                    tileActionFields: updates.map((c) => getTileFieldFromColumn(c.value, fieldType)),
                    triggerParameters: updates.map((c) => c.value?.modelColumnID),
                });
            };

            const isAllowedColumn = (c) => {
                if ((triggerParameters ?? []).includes(c.modelColumnID)) {
                    return false;
                }
                let field = tileFields?.allFields?.find((f) => f.modelColumnID == c.id);
                if (field) {
                    return !(fixedParams ?? []).includes(field.id);
                }

                return true;
            };

            let list = modelForCollection.tables
                .filter((t) => tablesInTile.includes(t.id))
                .map((t) => ({
                    ...t,
                    columns: (t.columns ?? []).filter(isAllowedColumn),
                }));

            const reorder = (list, startIndex, endIndex) => {
                const result = Array.from(list);
                const [removed] = result.splice(startIndex, 1);
                result.splice(endIndex, 0, removed);

                return result;
            };

            const onDragEnd = (result) => {
                if (!result.destination) {
                    return;
                }

                const items = reorder(localState.value, result.source.index, result.destination.index);

                updateState(items);
            };

            return (
                <div className="js-parameters-99u">
                    <div className="parameters">
                        <div className="lines" />

                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable">
                                {(provided) => (
                                    <div {...provided.droppableProps} ref={provided.innerRef}>
                                        <div className="parameter">
                                            <div className="label">Parameter 1</div>
                                            <div className="fixed-value">
                                                {fixedParam?.name}
                                                {LockIcon({})}
                                            </div>
                                        </div>

                                        {localState.value.map((parameter, index) => {
                                            return (
                                                <Draggable key={parameter.id.toString()} draggableId={`draggable-${index}`} index={index}>
                                                    {(provided) => {
                                                        return (
                                                            <div className="parameter" ref={provided.innerRef} {...provided.draggableProps}>
                                                                <span {...provided.dragHandleProps} className="drag-icon">
                                                                    {DragIcon({})}
                                                                </span>

                                                                <div className="value">
                                                                    {DropdownFieldMapping({
                                                                        list,
                                                                        label: `Parameter ${index + 2}`,
                                                                        valueToLabel: (v) => (v ? v.name : null),
                                                                        state: {
                                                                            value: parameter.value,
                                                                            onChange: (v) => {
                                                                                const items = localState.value.map((c, i) =>
                                                                                    i == index
                                                                                        ? {
                                                                                              ...c,
                                                                                              value: v,
                                                                                          }
                                                                                        : c
                                                                                );

                                                                                updateState(items);
                                                                            },
                                                                        },
                                                                    })}

                                                                    <span
                                                                        onClick={() => {
                                                                            const items = localState.value.filter((c, i) => i !== index);
                                                                            updateState(items);
                                                                        }}
                                                                    >
                                                                        {TrashIconBold()}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        );
                                                    }}
                                                </Draggable>
                                            );
                                        })}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>

                        <Button
                            className="button-item parameter"
                            btnType="border"
                            iconLeft={<PlusIcon />}
                            size="small"
                            onClick={() => {
                                const updated = [...localState.value, {value: "", id: Date.now()}];
                                localState.onChange(updated);
                            }}
                        >
                            Add Parameter
                        </Button>
                    </div>
                </div>
            );
        }
    );
};
