import "./model-table-controls.scss";

import React from "react";
import {cx} from "emotion";

import {cs} from "@common/react/chain-services";
import {spc} from "@common/react/state-path-change";
import {UseState} from "@common/react/use-state";
import {Watch} from "@common/react/watch";
import {omit, pick} from "@common/utils/objects";
import {waitTimeout} from "@common/utils/wait-timeout";

import {ModelPanelHelper} from "../../../common/model-panel-helper";
import {CircleRadius} from "../model-panel";
import {CreateNewRelationship} from "./actions/create-new-relationship";
import {RowLevelSecurity} from "./actions/row-level-security";
import {MoreAction} from "./actions/more-action";
import {EditDataView} from "./actions/edit-data-view";
import {PreviewDataAction} from "./actions/preview-data-action";
import {scope} from "@common/react/scope";
import {lightOrDark} from "@common/utils/color-util";
import {RowSecurityIcon, CreateRelationshipIcon, PreviewDataIcon} from "./more-controls/icons/more-control-icons";
import {consumeContext} from "@common/react/context";
import {DEFAULT_RELATIONSHIP} from "../model-constrants";
export const ModelTableControls = ({table, dataSources, model, scale, tableMoreControls, ...otherProps}) =>
    cs(
        consumeContext("routing"),
        [
            "state",
            (_, next) =>
                UseState({
                    next,
                    initValue: {
                        tableState: {position: {}},
                        display: false,
                        initDisplay: false,
                    },
                }),
        ],

        ({state}, next) =>
            Watch({
                value: table,
                onChanged: async (current, prev) => {
                    const close = async () => {
                        spc(state, ["display"], () => false);
                        await waitTimeout(200);
                        spc(state, ["initDisplay"], () => false);
                    };

                    const open = async () => {
                        state.onChange({
                            tableState: current,
                            initDisplay: true,
                        });
                        await waitTimeout(10);
                        state.change((s) => ({...s, display: true}));
                    };

                    if (current && current.id) {
                        if (!prev) {
                            await open();
                        } else {
                            if (current.id == prev?.id) {
                                spc(state, ["tableState"], () => current);
                            } else {
                                await close();
                                await open();
                            }
                        }
                    } else {
                        await close();
                    }
                },
                next,
            }),
        ({state, routing}) => {
            const {initDisplay, display, tableState} = state.value;
            const {hoverInteractions, createRelationshipLine, interactions} = otherProps;

            let transform = `translate(0, 0) scale(1)`;

            const cX = (tableState?.position?.x ?? 0) - CircleRadius;
            const cY = (tableState?.position?.y ?? 0) - CircleRadius;

            const showAlternativeMenu = scale < 0.9;

            if (tableState && scale < 0.8) {
                const newScale = (1 / scale) * 0.8;

                transform = `translate(${(1 - newScale) * (cX + CircleRadius)}, ${
                    (1 - newScale) * (cY + CircleRadius)
                }) scale(${newScale})`;
            }

            return (
                <g className={cx("model-table-control", display && "display")} transform={transform}>
                    {initDisplay &&
                        table &&
                        (() => {
                            const color = ModelPanelHelper.getTableColor({
                                table: tableState,
                                dataSources,
                            });

                            const isLightColor = lightOrDark(color) == "light";

                            const suggestedRelationships = ModelPanelHelper.recommendRelationships({
                                tables: model.value.tables.map((t) => omit(t, ["position"])),
                                relationships: model.value.relationships,
                            });

                            const toggleMoreActionMenu = (e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                if (table.deleted) return;

                                if (tableMoreControls.open) {
                                    tableMoreControls.forceClose();
                                } else {
                                    const disablePreview =
                                        (table?.$type === "ViewModelTable" && table?.publishedOn === null) || table.deleted;

                                    tableMoreControls.showControl({
                                        targetElem: e.target,
                                        data: {
                                            table,
                                            suggestedRelationships,
                                            relationships: otherProps.relationships,
                                            dataSources,
                                            ...pick(otherProps, ["relationships", "interactions", "removeTableService"]),
                                        },
                                        customActions: showAlternativeMenu
                                            ? [
                                                  {
                                                      label: "Create New Relationship",
                                                      icon: CreateRelationshipIcon({}),
                                                      onClick: () => {
                                                          const suggestColumns = ModelPanelHelper.getSuggestColumns({
                                                              table,
                                                              suggestedRelationships: suggestedRelationships || [],
                                                              relationships: model.value.relationships,
                                                          });

                                                          interactions.createNewRelationship({
                                                              table,
                                                              relationship: DEFAULT_RELATIONSHIP,
                                                              suggestedRelationships: suggestColumns,
                                                              tables: model.value.tables,
                                                              allowBack: true,
                                                          });

                                                          createRelationshipLine.startClick(e);
                                                      },
                                                  },
                                                  {
                                                      label: "Edit Data View",
                                                      icon: RowSecurityIcon({}),
                                                      onClick: () => {
                                                          routing.goto("edit-data-view", {
                                                              tab: "build",
                                                              transformationId: table.transformationID,
                                                          });
                                                      },
                                                      hidden: () => table.$type == "DataSourceModelTable",
                                                  },
                                                  {
                                                      label: "Row-Level Security",
                                                      icon: RowSecurityIcon({}),
                                                      onClick: () =>
                                                          interactions.openRowLevelSecurity({
                                                              tableId: table.id,
                                                              dataSourceID: table.dataSourceID,
                                                              dataSourceTableID: table.dataSourceTableID,
                                                          }),
                                                      hidden: () => table.$type == "ViewModelTable",
                                                  },
                                                  {
                                                      label: disablePreview ? "Preview not available" : "Preview Data",
                                                      icon: PreviewDataIcon({}),
                                                      onClick: () => {
                                                          if (!disablePreview) {
                                                              interactions.previewData({table, tableId: table.id});
                                                          }
                                                      },
                                                  },
                                              ]
                                            : [],
                                    });
                                }
                            };

                            return (
                                <>
                                    <svg
                                        style={{pointerEvents: display ? "" : "none"}}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="120.023"
                                        height="83.885"
                                        viewBox="0 0 120.023 83.885"
                                        x={cX - CircleRadius}
                                        y={cY - CircleRadius}
                                    >
                                        {scale >= 0.9 ? (
                                            <>
                                                {CreateNewRelationship({
                                                    table: tableState,
                                                    dataSources,
                                                    color,
                                                    model,
                                                    scale,
                                                    suggestedRelationships,
                                                    ...otherProps,
                                                    display: scope(state, ["display"]),
                                                })}

                                                {table?.$type === "ViewModelTable" && !table.deleted
                                                    ? EditDataView({
                                                          table: tableState,
                                                          dataSources,
                                                          color,
                                                          model,
                                                          scale,
                                                          ...otherProps,
                                                      })
                                                    : RowLevelSecurity({
                                                          table: tableState,
                                                          color,
                                                          scale,
                                                          ...otherProps,
                                                      })}

                                                {PreviewDataAction({
                                                    table: tableState,
                                                    color,
                                                    scale,
                                                    ...otherProps,
                                                })}

                                                {MoreAction({
                                                    table: tableState,
                                                    color,
                                                    toggleMoreActionMenu,
                                                    ...pick(otherProps, ["modelActionTooltip"]),
                                                })}
                                            </>
                                        ) : (
                                            <g className="custom-ellipsis" onClick={toggleMoreActionMenu}>
                                                <circle cx={60} cy={60} r={20} fill="transparent" />
                                                <svg
                                                    style={{pointerEvents: "none"}}
                                                    x={58}
                                                    y={50}
                                                    width="4.64"
                                                    height="16"
                                                    viewBox="0 0 3.484 12"
                                                >
                                                    <g transform="translate(-703.098 -174.146)">
                                                        <path
                                                            d="M3.429,4.821A1.742,1.742,0,1,1,1.688,6.563,1.741,1.741,0,0,1,3.429,4.821ZM1.688,2.3A1.742,1.742,0,1,0,3.429.563,1.741,1.741,0,0,0,1.688,2.3Zm0,8.516A1.742,1.742,0,1,0,3.429,9.079,1.741,1.741,0,0,0,1.688,10.821Z"
                                                            transform="translate(701.411 173.583)"
                                                            fill={isLightColor ? "#546b81" : "#ffffff"}
                                                        />
                                                    </g>
                                                </svg>
                                            </g>
                                        )}
                                    </svg>
                                </>
                            );
                        })()}
                </g>
            );
        }
    );
