import React from "react";
import {Button} from "../../../../../../../../common/form/buttons/button/button";
import {TextInput} from "../../../../../../../../common/form/text-input/text-input";
import {cs} from "@common/react/chain-services";
import {Invoke} from "@common/react/invoke";
import {Static2} from "@common/react/static-2";
import {UseState} from "@common/react/use-state";
import {isNotBlank} from "@common/utils/strings";
import {DialogService} from "../../../../../common/dialog/dialog-service";
import CodeMirror from "codemirror";
import "codemirror/lib/codemirror.css";
import "codemirror/addon/hint/show-hint";
import "codemirror/addon/hint/show-hint.css";
import "codemirror/addon/hint/sql-hint";

import "./filter-rows-dialog.scss";
import {OnUnmounted} from "@common/react/on-unmounted";
import {consumeContext} from "@common/react/context";
import {FilterRowsInfoBox} from "./filter-rows-info-box";

let editor;

export const FilterRowsDialog = ({tableName, next: rootNext}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    className: "blur",
                    disabledClickOverlay: true,
                    render: ({resolve, reject, args: {query, dsID, dsTableID}}) => ({
                        width: 550,
                        title: "Filter Rows",
                        content: next({
                            resolve,
                            reject,
                            query,
                            dsID,
                            dsTableID,
                        }),
                    }),
                    next: rootNext,
                }),
        ],
        consumeContext("apis"),
        ["loading", (_, next) => UseState({initValue: false, next})],
        ["queryResult", (_, next) => UseState({next})],
        ["state", ({modal}, next) => UseState({initValue: modal.query, next})],
        ["editorRef", (_, next) => Static2({next})],
        ({state, modal, editorRef, apis, loading, queryResult}) => {
            return (
                <div className="filter-rows-dialog-88o">
                    {Invoke({
                        onMounted: () => {
                            editor = CodeMirror.fromTextArea(editorRef.get(), {
                                firstLineNumber: 2,
                                lineNumbers: true,
                                mode: "text/x-sql",
                                styleActiveLine: true,
                            });

                            CodeMirror.commands.autocomplete = (cm) => {
                                CodeMirror.showHint(cm, CodeMirror.hint.sql);
                            };

                            editor.on("change", () => {
                                state.onChange(editor.getValue());
                            });
                        },
                    })}

                    <div className="dialog-body">
                        <div className="text">Data that matches the query below will not be ingested.</div>

                        {FilterRowsInfoBox({
                            loading: loading.value,
                            queryResult: queryResult.value,
                        })}

                        <div className="codemirror-box">
                            <div className="first-line">
                                <div className="line-number">1</div>
                                <div className="text">
                                    <span className="cm-keyword">SELECT</span> * <span className="cm-keyword">FROM</span> {tableName.toUpperCase()}{" "}
                                    <span className="cm-keyword">WHERE</span>
                                </div>
                            </div>

                            <textarea ref={editorRef.set} rows="4" cols="50" defaultValue={state.value ?? ""} />
                        </div>
                    </div>

                    <div className="buttons">
                        {state.value && (
                            <div className="left">
                                <Button
                                    onClick={() => {
                                        // editor.getDoc().setValue(null);
                                        state.onChange(false);
                                        modal.resolve(null);
                                    }}
                                    btnType="danger"
                                >
                                    Delete Filter
                                </Button>
                            </div>
                        )}

                        <div className="right">
                            <Button btnType="secondary" onClick={() => modal.reject()}>
                                Cancel
                            </Button>
                            <Button
                                iconLeft={<i className="fas fa-play" />}
                                btnType="secondary"
                                onClick={async () => {
                                    loading.onChange(true);

                                    const resp = await apis.data.rowFilter({
                                        dataSourceID: modal.dsID,
                                        dataSourceTableID: modal.dsTableID,
                                        query: state.value,
                                    });

                                    loading.onChange(false);
                                    queryResult.onChange(resp);
                                }}
                            >
                                Test
                            </Button>
                            <Button onClick={() => modal.resolve(state.value)}>Done</Button>
                        </div>
                    </div>
                </div>
            );
        }
    );
