import {getErrorViewTablesWhenColumnDeleted} from "./fix-issues-common";
import {getErrorInCalculationOrMeasureColumn} from "../model-utils";
import * as React from "react";
import {createDateFormatter} from "@common/ui-components/charts/common/formatters/date-formatter";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {getErrorsInColumnDeleted} from "../../layout/error-badge/error-badge-helper";

export const DeletedColumnErrors = ({model, modal, dataSources, interactions, children}) =>
    cs(consumeContext("routing"), ({routing}) => {
        const {relationships} = model.value;
        const allTables = model.value.tables.concat(model.value.disabledTables);
        const table = allTables.find((t) => t.id == modal.tableID);
        const column = table.disabledColumns.find((c) => c.id == modal.columnID);
        const dataSource = dataSources.find((d) => d.id == table.dataSourceID);

        const deletedColumnErrors = getErrorsInColumnDeleted({
            column,
            allTables,
            table,
            relationships,
        })
            .map((error) => {
                if (error.errorType == "relationship") {
                    return {
                        ...error,
                        fix: () =>
                            model.change((m) => ({
                                ...m,
                                relationships: m.relationships.filter((r) => r.id != error.relationship.id),
                            })),
                    };
                }

                if (error.errorType == "rowLevelSecurity") {
                    return {
                        ...error,
                        fix: () =>
                            interactions.openRowLevelSecurity({
                                dataSources,
                                tableId: error.errorInTable.errorInTable.id,
                                dataSourceID: error.errorInTable.errorInTable.dataSourceID,
                                model,
                            }),
                    };
                }

                if (error.errorType == "transformation") {
                    return {
                        ...error,
                        fix: () => routing.goto("edit-data-view", {tab: "build", transformationId: error.transformationID}),
                    };
                }

                if (error.errorType == "transformedColumn") {
                    return {
                        ...error,
                        fix: () =>
                            routing.goto("edit-transformed-column", {
                                tab: "build",
                                modelTableId: table.id,
                                transformationId: error.errorColumn.transformationID || error.errorColumn.calculations[0].transformationID,
                            }),
                    };
                }

                return null;
            })
            .filter((v) => v);

        return children({
            title: (
                <span>
                    Column removed from data source: <b>{column?.name}</b>
                </span>
            ),
            dataSource: `${dataSource.name} > ${table.visualName}`,
            removedOn: createDateFormatter("MMM d, yyyy @ h:mm tt").format(new Date(column?.deletedInfo?.deletedDateUtc)),
            deletedBy: column?.deletedInfo?.deletedBy,
            description: `There are ${deletedColumnErrors.length} ${
                deletedColumnErrors.length > 1 ? "dependencies" : "dependency"
            } on this column causing errors in this model that could be causing issues in collections. `,
            solutions: ["Turn the column back on in the data source.", "Update each of the following broken dependencies individually."],
            heading1: "Missing Column Errors",
            errors: deletedColumnErrors,
        });
    });
