import React from "react";
import {cs} from "@common/react/chain-services";
import "./aggregated-model-table-dataset-configuration.scss";
import {ButtonDropdown} from "../../../../../../data-source/common/button-dropdown/button-dropdown";
import {cx} from "emotion";
import {ColumnMultiSelectSearch} from "../../../../common/column-multi-select-search/column-multi-select-search";
import {spc} from "@common/react/state-path-change";
import {getFieldType} from "@common/ui-components/charts/common/get-field-type";
import {ffToDropdown} from "../../../../../../../../../common/form/ff-to-dropdown";
import {rColumnControl} from "../../common/column-control/column-control";
import {removeIndex} from "@common/utils/collections";
import {rFieldTypeIcon} from "../../../../../../common/field-type-icon/get-field-type-icon";
import {AggregationSelect} from "../../../../common/aggregation-select/aggregation-select";
import {keepOnly} from "@common/utils/objects";

export const AggregatedModelTableDatasetConfiguration = ({transformation, step, form, model}) => {
    const modelTable = model.tables.find((t) => t.id === step.value.modelTableID);

    const rAggregationSelect = (ci, dataType) => {
        const dropdownProps = ffToDropdown(form.field(["columns", ci, "aggregationFunc"]));

        return AggregationSelect({
            state: {
                value: dropdownProps.value,
                onChange: dropdownProps.onChange,
            },
            hideNone: true,
            dataType,
            ...keepOnly(dropdownProps, ["domRef", "hasError", "errorMessage"]),
        });
    };

    return (
        <div className="aggregated-model-table-dataset-configuration-h53">
            <div className="config-group">
                <div className="label">Column Aggregations</div>
                <div className="select-columns">
                    <div className="search-select-columns">
                        {(() => {
                            const {hasError, errorMessage} = ffToDropdown(form.field(["columns"]));
                            const isSelected = (c) => step.value?.columns?.map((c1) => c1.column.modelColumnID).includes(c.id);
                            return ColumnMultiSelectSearch({
                                columns: modelTable.columns,
                                hasError,
                                errorMessage,
                                isSelected,
                                onSelect: (c) => {
                                    if (isSelected(c)) {
                                        spc(step, ["columns"], (columns) => columns.filter((c1) => c1.column.modelColumnID !== c.id));
                                    } else {
                                        spc(step, ["columns"], (columns) => [
                                            ...(columns || []),
                                            {
                                                name: c.name,
                                                dataType: c.dataType,
                                                column: {
                                                    modelID: model.id,
                                                    modelTableID: modelTable.id,
                                                    modelColumnID: c.id,
                                                },
                                                aggregationFunc: getFieldType(c) === "number" ? "Sum" : "Count",
                                            },
                                        ]);
                                    }
                                },
                            });
                        })()}
                    </div>
                    <div className="columns-actions">
                        {ButtonDropdown({
                            label: "Columns",
                            list: ["Add All", "Remove All"],
                            onChange: (t) => {
                                switch (t) {
                                    case "Add All": {
                                        spc(step, ["columns"], () =>
                                            modelTable.columns.map((c) => ({
                                                name: c.name,
                                                column: {
                                                    modelID: model.id,
                                                    modelTableID: modelTable.id,
                                                    modelColumnID: c.id,
                                                },
                                                aggregationFunc: getFieldType(c) === "number" ? "Sum" : "Count",
                                            }))
                                        );
                                        break;
                                    }
                                    case "Remove All": {
                                        spc(step, ["columns"], () => null);
                                        break;
                                    }
                                }
                            },
                        })}
                    </div>
                </div>
                <div className="columns">
                    {step.value?.columns?.map((c, ci) => {
                        const modelColumn = modelTable.columns
                            .concat(modelTable.disabledColumns)
                            .find((mc) => mc.id === c.column.modelColumnID);
                        const {hasError, errorMessage} = ffToDropdown(form.field(["columns", ci, "column"]));
                        return (
                            <div className="column" key={ci}>
                                {rColumnControl({
                                    columnSelector: (
                                        <div
                                            className={cx("column-name", {
                                                hasError,
                                            })}
                                        >
                                            <div className="title">Column</div>
                                            <div className="column-label">
                                                {rFieldTypeIcon(modelColumn.dataType)}
                                                <div className="text">{c.name}</div>
                                            </div>
                                            {errorMessage && <div className="error-message">{errorMessage}</div>}
                                        </div>
                                    ),
                                    propertySelector: rAggregationSelect(ci, modelColumn.dataType),
                                    onRemove: () =>
                                        step.change((s1) => ({
                                            ...s1,
                                            columns: removeIndex(ci, s1.columns),
                                            errors: s1.errors.filter((e) => e.property !== `Columns[${ci}].Column`),
                                        })),
                                })}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};
