import {cs} from "@common/react/chain-services";
import {bindInput} from "@common/react/bind-input";
import {Static2} from "@common/react/static-2";
import {UseState} from "@common/react/use-state";
import "./text-input-autogrow.scss";

export const TextInputAutoGrow = ({state, ...props}) =>
    cs(["elemRef", (_, next) => Static2({next})], ({elemRef}) => (
        <div
            className="text-input-autogrow-99o"
            ref={elemRef.set}
            style={{
                display: "inline-grid",
                alignItems: "center",
                justifyItems: "start",
                padding: 0,
            }}
        >
            <input {...props} {...{...bindInput(state)}} type="text" onChange={(e) => state.onChange(e.target.value)} />

            <span
                style={{
                    gridArea: "1 / 1 / 2 / 2",
                    visibility: "hidden",
                }}
            >
                {state.value}
            </span>
        </div>
    ));
