import * as React from "react";

import {cs} from "@common/react/chain-services";
import {Expandable} from "../../../../../common/expandable/expandable";
import {CollectionSettings} from "../../../../../collection/edit/tabs/tiles/left-panel/collection-settings/collection-settings";
import {Design} from "../../../../../collection/edit/tabs/tiles/left-panel/design/design";
import {TileSize} from "./tile-size";
import {ConfirmDialog} from "../../../../../common/confirm-dialog/confirm-dialog";
import {Button} from "../../../../../../../../common/form/buttons/button/button";
import {AddingTileDashboard} from "../../../../../collection/edit/tabs/tiles/adding-tile/adding-tile-dashboard";
import {FieldSection} from "../../../../../collection/tile/edit/left-panel/tabs/fields-tab/chart-types/common/field-section/field-section";
import {FieldInfoProvider} from "../../../../../collection/tile/edit/left-panel/tabs/tile-tab/chart-types/common/field-info-provider/field-info-provider";
import {FontFamilyDropdown} from "../../../../../collection/theme/common/theme-tab/font-family-dropdown/font-family-dropdown";
import {ffToDropdown} from "../../../../../../../../common/form/ff-to-dropdown";
import {FontSizeSelect} from "../../../../../collection/tile/edit/left-panel/tabs/tile-tab/chart-types/common/font-size-select";
import {LpColorLine} from "../../../../../common/left-panel/lp-color-line";
import {ffToColorPickerLine} from "../../../../../../../../common/form/ff-to-color-picker-line";
import "./tile-size.scss";

export const ContextualCollectionMenu = ({collection, panel}) =>
    cs(() => (
        <div className="collection-menu-1ws">
            {FieldSection({
                header: FieldInfoProvider({
                    field: <div className="text">{"Expected Tile Size".toUpperCase()}</div>,
                    noCollapsible: true,
                }),
                content: <TileSize collection={collection} />,
            })}

            {FieldSection({
                header: FieldInfoProvider({
                    field: <div className="text">{"Tile Type".toUpperCase()}</div>,
                    noCollapsible: true,
                }),
                content: <AddingTileDashboard panel={panel} collection={collection} />,
            })}
        </div>
    ));
