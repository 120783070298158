import {outputStepTypes} from "../../../common/output-step-types";

export const diagramSizes = ($type) =>
    ({
        ModelTableDatasetStep: 60,
        ChildDatasetStep: 40,
        ViewOutputStep: 60,
        ColumnOutputStep: {width: 116, height: 80},
    }[$type] || 66);

export const diagramUtils = {
    calculateConnectedPath: ({relationships, relationship, diagrams}) => {
        const source = diagrams.find((d) => d.id === relationship.source);
        const target = diagrams.find((d) => d.id === relationship.target);

        const linksToTarget = relationships.filter((l) => l.target === target.id);
        const linksToSource = relationships.filter((l) => l.source === source.id);

        const sourceSize = diagramSizes(source.$type);
        const targetSize = diagramSizes(target.$type);

        const isTransformationStep = diagramUtils.isTransformationStep;

        const calculatedLink = {
            source: {
                x: source.position.x + (isTransformationStep(source.$type) ? (sourceSize?.width ?? sourceSize) / 2 : 0),
                y: source.position.y + (sourceSize?.width ?? sourceSize),
            },
            target: {
                x: target.position.x + (isTransformationStep(target.$type) ? Math.ceil((targetSize?.width ?? targetSize) / 2) : 0),
                y: target.position.y + (isTransformationStep(target.$type) ? 0 : -(targetSize?.width ?? targetSize)),
            },
        };

        // + (isTransformationStep(target.$type) ?
        //     (linksToTarget.findIndex(l => l.target === target.id && l.source === source.id) + 1)
        //     * Math.ceil((targetSize?.width ?? targetSize) / (linksToTarget.length + 1)) : 0),

        const path = (item) => {
            const tempY = item.target.y > item.source.y ? (item.target.y - item.source.y) / 2 + item.source.y : item.source.y + 10;
            if (item.target.y > item.source.y) {
                return `
                   M ${item.source.x} ${item.source.y}
                   L ${item.source.x} ${tempY}
                   A 16 16 90 0 0 ${item.source.x} ${tempY}
                   L ${item.target.x} ${tempY}
                   A 16 16 90 0 1 ${item.target.x} ${tempY}
                   L ${item.target.x} ${item.target.y}
                `;
            } else {
                const tempX = (item.target.x - item.source.x) / 2 + item.source.x;

                return `
                   M ${item.source.x} ${item.source.y}
                   L ${item.source.x} ${tempY}
                   A 16 16 90 0 0 ${item.source.x} ${tempY}
                   L ${tempX} ${tempY}
                   A 16 16 90 0 1 ${tempX} ${tempY}
                   L ${tempX} ${item.target.y - 10}
                   A 16 16 90 0 1 ${tempX} ${item.target.y - 10}
                   L ${item.target.x} ${item.target.y - 10}
                   A 16 16 90 0 1 ${item.target.x} ${item.target.y - 10}
                   L ${item.target.x} ${item.target.y}
                `;
            }
        };

        return {
            path: path(calculatedLink),
            link: calculatedLink,
        };
    },
    isTransformationStep: ($type) => !["ModelTableDatasetStep", "AggregatedModelTableDatasetStep", "ChildDatasetStep", "ViewOutputStep"].includes($type),
    isCollide2Nodes: (draggingItem, diagram) => {
        if (["ModelTableDatasetStep"].indexOf(draggingItem.$type) > -1) return false;

        if (draggingItem.$type === "ChildDatasetStep" && diagramUtils.isTransformationStep(diagram.$type)) return false;

        if ([...outputStepTypes.map((t) => t.$type)].indexOf(diagram.$type) > -1) return false;

        if (draggingItem.id && diagram.hasOwnProperty("inputStep1ID") && diagram.inputStep1ID === draggingItem.id && diagram.inputStep2ID === draggingItem.id) return false;

        if (draggingItem.id && diagram.hasOwnProperty("inputStepID") && draggingItem.id === diagram.inputStepID) return false;

        const distance = diagramUtils.distanceBetween2Nodes(draggingItem, diagram);
        return diagramUtils.getDistanceDiagonalLine(diagram) + diagramUtils.getDistanceDiagonalLine(draggingItem) / 2 > distance;
    },
    distanceBetween2Nodes: (item1, item2) => {
        const getDelta = (n) => {
            const size = diagramSizes(n.$type);
            const ret = {
                x: (typeof size === "object" ? size.width : size) / 2,
                y: (typeof size === "object" ? size.height : size) / 2,
            };
            return diagramUtils.isTransformationStep(n.$type) ? ret : {x: 0, y: 0};
        };

        const delta1 = getDelta(item1);
        const delta2 = getDelta(item2);

        return Math.sqrt(Math.pow(item1.position.x + delta1.x - (item2.position.x + delta2.x), 2) + Math.pow(item1.position.y + delta1.y - (item2.position.y + delta2.y), 2));
    },
    getDistanceDiagonalLine: (item) => {
        const size = diagramSizes(item.$type);
        if (!diagramUtils.isTransformationStep(item.$type)) return size;

        const delta = {
            x: (typeof size === "object" ? size.width : size) / 2,
            y: (typeof size === "object" ? size.height : size) / 2,
        };

        return Math.sqrt(Math.pow(item.position.x - (item.position.x + delta.x), 2) + Math.pow(item.position.y - (item.position.y + delta.y), 2));
    },
    getRelationshipsTransformation: (diagrams) => {
        let r = [];
        diagrams.forEach((d) => {
            if (d.hasOwnProperty("inputStep1ID")) {
                r = [...r, {source: d.inputStep1ID ?? null, target: d.id}, {source: d.inputStep2ID ?? null, target: d.id}];
            } else {
                r = [...r, {source: d.inputStepID ?? null, target: d.id}];
            }
        });
        return r;
    },
    getRelationshipsTree: (relationships, source = null) => {
        const relationshipTree = (relationships, source = null, path = []) => {
            let r = [];

            relationships
                .filter((d) => d.source === source)
                .forEach((d) => {
                    let path1 = [...path, ...(d.source ? [d.source] : [])];
                    let cd = {
                        ...d,
                        path: path1,
                        children: relationshipTree(relationships, d.target, path1),
                    };
                    return r.push(cd);
                });
            return r;
        };

        return relationshipTree(relationships, (source = null), []).filter((r) => r.children.length !== 0);
    },
    isValidConnected: (inputStep, targetStep) => {
        if (inputStep.id !== targetStep.id) {
            if (targetStep.$type === "ModelTableDatasetStep") return false;
            if (targetStep.$type === "ChildDatasetStep" && inputStep.$type === "ModelTableDatasetStep") return true;

            if (inputStep.hasOwnProperty("inputStep1ID")) {
                return inputStep.inputStep1ID !== targetStep.id && inputStep.inputStep2ID !== targetStep.id;
            } else {
                return targetStep.id !== inputStep.inputStepID;
            }
        }
        return false;
    },
    generatePositionWithoutOverlapping: (steps, newItem) => {
        const {distanceBetween2Nodes, getDistanceDiagonalLine} = diagramUtils;

        const getPosition = (item, depth = 1) => {
            if (depth <= 10)
                for (let step of steps) {
                    if (getDistanceDiagonalLine(step) + getDistanceDiagonalLine(item) / 2 > distanceBetween2Nodes(step, item)) {
                        return getPosition(
                            {
                                ...item,
                                position: {
                                    x: step.position.x + diagramSizes(step.$type) + 10,
                                    y: step.position.y,
                                },
                            },
                            depth + 1
                        );
                    }
                }
            return item.position;
        };

        return getPosition(newItem);
    },
};
