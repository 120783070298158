import {getTooltipFontSizes} from "./get-tooltip-font-sizes";
import {getDimStepLabel} from "./bar-line-non-compare-header";
import {getIndicatorShapes} from "./indicator-shapes";

// create table for reference lines
// used for all bar and line chart tooltip
export const getReferencePointsTable = ({tile, theme, formatters, points, series}) => {
    const indicatorPoints = points?.filter((p) => p.series.userOptions.customType === "IndicatorReferenceLine");
    // const constantOrAggregateValueSeries = series?.filter((s) => ["ConstantValueReferenceLine", "AggregateValueReferenceLine"].includes(s.userOptions.customType));

    if (
        // !constantOrAggregateValueSeries?.length &&
        !indicatorPoints?.length
    ) {
        return "";
    }

    const fontSizes = getTooltipFontSizes(theme);

    const generalInfo = {
        title: points[0].series.userOptions.measureAxisTitle,
        dimValue: points[0].x,
        dimCategory: points[0].key || points[0].name,
        customStack: points[0].series.userOptions.customStack,
        defaultMeasureAxisTitle: points[0].series.userOptions.measureAxisTitle,
    };

    const header = `<div class="non-compare-header" style="font-size: ${fontSizes.small}px; margin-top: 10px">
            <div class="title">
                Indicator - ${getDimStepLabel({
                    tile,
                    formatters,
                    generalInfo,
                })}
            </div>
        </div>`;

    const rTable = () => {
        const indicatorShapes = getIndicatorShapes(theme);

        // const constantOrAggregateValueRows = constantOrAggregateValueSeries?.length > 0 && constantOrAggregateValueSeries.map((s) => {
        //     const valueFormatter = formatters.tooltipValueFormatters[s.userOptions.customStack][0];
        //     return (
        //         `<div class="point table-row" style="font-size: ${fontSizes.medium}px">
        //             <div class="name">
        //                 ${s.userOptions.name}
        //             </div>
        //             <div class="main-value">${valueFormatter(s.userOptions.plotLineOptions.value)}</div>
        //         </div>`
        //     );
        // });

        const indicatorRows =
            indicatorPoints?.length > 0 &&
            indicatorPoints.map((p) => {
                const valueFormatter = formatters.tooltipValueFormatters[p.series.userOptions.customStack][p.series.userOptions.indexInCustomStack];
                return `<div class="point table-row" style="font-size: ${fontSizes.medium}px">
                    <div class="name">
                        <span class="indicator" style="color: ${p.series.userOptions.color}">
                            ${p.series.userOptions.dashStyle === "dash" ? indicatorShapes["dashed-line"] : indicatorShapes["line"]}
                        </span>
                        ${p.series.userOptions.name}
                    </div>
                    <div class="main-value">${valueFormatter(p.y || 0)}</div>
                </div>`;
            });

        return `<div class="table non-compare">
                ${[
                    // ...(constantOrAggregateValueRows || []),
                    ...(indicatorRows || []),
                ].join("")}
            </div>`;
    };

    return `${header}${rTable()}`;
};
