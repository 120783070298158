const {hideOverflowTickLabel} = require("../../vertical-bar/chart-options/hide-overflow-tick-label");
const {hideOriginYaxisGridLine} = require("../../combo-chart/chart-options/hide-origin-yaxis-grid-line");
const {getPlotOptions} = require("./plot-options");
const {getTooltipOptions} = require("../../factory/tooltip/tooltip-options");
const {getLegendOptions} = require("../../factory/legend");
const {hideHorizontalScrollbar} = require("../../vertical-bar/chart-options/hide-horizontal-scrollbar");
const {fixDimAxisLabel} = require("../../vertical-bar/chart-options/fix-dim-axis-label");
const {fixOverlappingXAxisGridLabels} = require("../../vertical-bar/chart-options/fix-overlapping-x-axis-grid-labels");
const {SERIES_BOOST_THRESHOLD} = require("../../common/axis-chart-boost-turbo-constants");

const getLineChartOptions = ({tile, isDimensionRealDate, theme, isCompare, formatters, rawData, onClickPoint}) => {
    const isSparkLineKPITile = tile.$type == "SparkLineKPITile";

    const chartEventsFn = ({chart}) => {
        !tile.style.xAxis.lineShown && hideOriginYaxisGridLine(chart);

        if (!tile.style.xAxis.maxValuesInView.startsWith("Auto")) {
            // logic for applying scrolling on line chart is in add-scrolling-options.js
            hideHorizontalScrollbar(chart);
            tile.style.xAxis.labelShown && tile.style.xAxis.label && fixDimAxisLabel(chart);
        }

        if (
            isDimensionRealDate &&
            tile.style.xAxis.gridStepLabelsShown
            // && !tile.style.xAxis.gridStepType // auto grid step (no gridStepType on xAxis since #1003)
        ) {
            hideOverflowTickLabel(chart);
            // when maxValuesInView is of type Auto, all grid labels are shown.
            // overlapping x-axis grid labels often happen on real date dim, so put it here
            tile.style.xAxis.gridStepLabelsShown && tile.style.xAxis.maxValuesInView.startsWith("Auto") && fixOverlappingXAxisGridLabels(chart);
        }
    };

    const type = (() => {
        const displayType = tile.style.lineChartDisplayType || tile.style.displayType;
        if (!displayType || displayType === "Line") {
            return "line";
        }
        if (displayType === "Spline") {
            return "spline";
        }
        if (displayType.startsWith("Area")) {
            return "area";
        }
        if (displayType.startsWith("SplineArea")) {
            return "areaspline";
        }
    })();

    const chart = {
        type,
        customType: "LineChartTile",
        backgroundColor: theme.general.tile.styles.tileBackgroundColorRGB,
        events: {
            load: function () {
                const chart = this;
                chartEventsFn({chart});
            },
            redraw: function () {
                const chart = this;
                chartEventsFn({chart});
            },
        },
        zooming: {
            mouseWheel: false,
        },
    };

    const title = {
        text: undefined,
    };

    const plotOptions = getPlotOptions({tile, theme, formatters, onClickPoint});

    const tooltip = !isSparkLineKPITile ? getTooltipOptions({
        tile,
        theme,
        isCompare,
        formatters,
        rawData,
    }) : {enabled: false};

    const legend = getLegendOptions({style: tile.style.legendStyle, theme});

    return {
        boost: {
            enabled: true,
            seriesThreshold: SERIES_BOOST_THRESHOLD,
            useGPUTranslations: true,
            usePreAllocated: true,
        },
        chart,
        title,
        plotOptions,
        tooltip,
        legend,
        credits: {
            enabled: false,
        },
    };
};
exports.getLineChartOptions = getLineChartOptions;
