import {cs} from "@common/react/chain-services";
import {scope} from "@common/react/scope";
import * as React from "react";
import "./comparison-methods.scss";
import {CheckboxLineGroupFlex} from "../../../../../../../common/checkbox-line-group/checkbox-line-group-flex";
import {comparisonMethods} from "@common/ui-components/live/filters/text-filter/comparison-methods";
import {stateToCheckboxLineGroup} from "../../../../../../../common/checkbox-line-group/state-to-checkbox-line-group";
import {stringListState} from "../../../../../../../common/data-logic/string-list-state";
import {StaticTooltipService} from "../../../../../../../common/tooltip3/static-tooltip-service";
import {chain} from "@common/utils/fs";
import {enforceDefault} from "../../common/enforce-default";

export const ComparisonMethods = ({filter}) =>
    cs(
        [
            "staticTooltip",
            (_, next) =>
                StaticTooltipService({
                    direction: "right",
                    info: "These comparison options will display to the user. If only 1 option is selected the user will not be shown the comparison method.",
                    tooltipBoxWidth: 200,
                    topOffset: -7,
                    next,
                }),
        ],
        ({staticTooltip}) => (
            <div className="comparison-methods comparison-methods-98g">
                <div className="header">
                    Comparison Methods
                    {staticTooltip.renderIcon({
                        icon: <i className="material-icons">info</i>,
                        className: "info-tooltip",
                    })}
                </div>

                <div className="content">
                    {CheckboxLineGroupFlex({
                        list: comparisonMethods,
                        numberOfColumns: 2,
                        ...chain(
                            enforceDefault(filter, {
                                attr: "comparisonMethods",
                                defaultAttr: "defaultOperator",
                                attrDefaultValue: "Contains",
                            }),
                            (_) => stateToCheckboxLineGroup(stringListState(_), ["name"])
                        ),
                    })}
                </div>
            </div>
        )
    );
