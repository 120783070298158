export const errorsMessages = {
    dataSource: {
        ConnectionTimeout: {
            label: "Data Source Error: Connection timeout occurred after 30 seconds",
            solutions: [
                "Make sure your IPs are Whitelisted",
                "Check the connection settings to verify the port number and/or the settings are correct",
            ],
        },
        AccessDenied: {
            label: "Data Source Error: Server Access Denied",
            solutions: [
                "Make sure your IPs are Whitelisted",
                "Check the connection settings to verify the password and permission are correct",
            ],
        },
        ServerNotAvailable: {
            label: "Data Source Error: Server not available",
            solutions: [
                "Ensure your server is online",
                "Check Connection Settings to Ensure everything is correct",
                "Attempt connection now to see if the problem exists",
            ],
        },
        UnknownError: {
            label: "Data Source Error",
            solutions: ["View Full Error for database response", "Contact Support for assistance"],
        },
    },
    table: {
        UnknownError: {
            label: "Unknown Table Issue",
            solutions: [
                "Check the properties on the table in your data source and update the settings in Verb to match. Then clear the error to enable syncing.",
                "Disable sync for this table",
            ],
            actions: ["disable", "clear"],
        },
        NotAvailable: {
            label: "Table Not Available",
            solutions: [
                "Check the data source to confirm that the Verb user has access to this table.",
                "Ensure this table still exists in the data source. If it does not, disable sync for this table.",
                "Check the data source to see if this table was renamed. If it was, the table will display as a new table in Verb. Disable sync for this table and enable sync on the new table.",
                "Disable sync for this table.",
            ],
            actions: ["disable", "clear"],
        },
        NetworkIssue: {
            label: "Table Error: Network Issue",
            solutions: [
                "Verify the security protocol configurations of the data source",
                "Confirm the Verb user has access to this table",
                "Ensure the Connections Settings are correct and they didn’t cause a timeout\n" + "Then clear the error to enable syncing.",
            ],
            actions: ["clear", "connection", "disable"],
        },
        CTNotAvailable: {
            label: "Sync Method: Change Tracking Not Available",
            solutions: [
                "Update the sync method to one compatible with your data source.",
                "Update your data source to support Change Tracking, then clear the error to enable syncing.",
            ],
            actions: ["syncMethod", "clear", "disable"],
        },
        CDCNotAvailable: {
            label: "Sync Method: Change Data Capture Not Available",
            solutions: [
                "Update the sync method to one compatible with your data source.",
                "Update your data source to support Change Data Capture, then clear the error to enable syncing.",
            ],
            actions: ["syncMethod", "clear", "disable"],
        },
        IncrementalColumnNotAvailable: {
            label: "Sync Method: Incremental Column Not Available",
            solutions: [
                "Confirm the incremental column used on this table was not edited in your database then clear the error to enable syncing.",
                "Update sync settings to change this table’s sync method.",
            ],
            actions: ["syncMethod", "clear", "disable"],
        },
        SyncDisabled: {
            label: "Sync Method: Not Set",
            solutions: ["Update the sync method for this table.", "Disable the sync for this table."],
            actions: ["syncMethod", "clear", "disable"],
        },
        SubscriptionOverage: {
            label: "Subscription Overage",
            desc: "The managed data limited was exceeded while syncing this table. Update your plan to continue ingestion, then clear the error to enable syncing.",
            // "solutions": [
            //     "Confirm your database settings are correct to sync with Verb and then clear the error to enable syncing",
            //     "Update sync settings to change this table’s sync method"
            // ],
            actions: ["updatePlan", "clear", "disable"],
        },
    },
    column: {
        NotAvailable: {
            label: "Column not found: {Column Name}",
            solutions: [
                "Check the table in your database to fix the problem with the column at the source and then clear the error to enable syncing",
                "Edit Columns to turn sync off on the missing column",
            ],
        },
        DataTypeChanged: {
            label: "Column Data Type Changed: {Column Name}",
            solutions: [
                "Edit columns to turn off sync on the affected column",
                "To update the column data type turn off sync on affected column and save settings. Then turn on the column to re-sync with the new data type.",
            ],
        },
    },
};

export const shortErrorsMessages = ({syncSetting, clearErrors: clearErrorsFn}) => {
    const clearErrors = clearErrorsFn();
    return {
        table: {
            UnknownError: {
                label: "Unknown Table Issue",
                message: (
                    <>
                        Something is preventing Verb from syncing this table. Confirm that everything is okay with it in the data source.
                        Then go to {syncSetting} for possible solutions. Or {clearErrors} to resume syncing and see if the issue is already
                        resolved.
                    </>
                ),
            },
            NotAvailable: {
                label: "Table Not Available",
                message: (
                    <>
                        This table could not be found in the data source. Check the data source to confirm that Verb has appropriate
                        permissions. Then {clearErrors} to resume syncing.
                        <br />
                        <br />
                        If the table has been renamed or removed from the data source, go to {syncSetting} for all possible solutions.
                    </>
                ),
            },
            NetworkIssue: {
                label: "Network Issue",
                message: (
                    <>
                        There was a network issue when attempting to sync to this table. To resolve the issue:
                        <p>1) Verify the security protocol configurations of the data source</p>
                        <p>2) Confirm the Verb user has access to this table</p>
                        <p>3) Ensure the Connections Settings are correct and they didn’t cause a timeout</p>
                        Then, {clearErrors} to resume syncing and see if the issue is resolved.
                    </>
                ),
            },
            CTNotAvailable: {
                label: "Sync Method: Change Tracking Not Available",
                message: (
                    <>
                        Verb was not able to sync using the Change Tracking sync method. Go to {syncSetting} to update the sync method to
                        one compatible with your data source. Or {clearErrors} to resume syncing and see if the issue is already resolved.
                    </>
                ),
            },
            CDCNotAvailable: {
                label: "Sync Method: Change Data Capture Not Available",
                message: (
                    <>
                        Verb was not able to sync using the Change Data Capture sync method. Go to {syncSetting} to update the sync method
                        to one compatible with your data source. Or {clearErrors} to resume syncing and see if the issue is already
                        resolved.
                    </>
                ),
            },
            IncrementalColumnNotAvailable: {
                label: "Sync Method: Incremental Column Not Available",
                message: (
                    <>
                        Verb was not able to sync using the Incremental Column sync method. Go to {syncSetting} to change sync methods or to
                        update the incremental sync method to use a different column. Or {clearErrors} to resume syncing and see if the
                        issue is already resolved.
                    </>
                ),
            },
            SyncDisabled: {
                label: "Sync Method: Not Set",
                message: (
                    <>
                        The sync method was not configured for this table. Go to {syncSetting} to set it. Or {clearErrors} to resume syncing
                        and see if the issue is already resolved."
                    </>
                ),
            },
            SubscriptionOverage: {
                label: "Subscription Overage",
                message: (
                    <>
                        The managed data limit was exceeded while syncing this table. Update your plan to continue ingestion. Or{" "}
                        {clearErrors} to resume syncing and see if the issue is already resolved."
                    </>
                ),
            },
        },
    };
};
