import * as React from "react";
import {cs} from "../../../react/chain-services";
import {consumeContext} from "../../../react/context";
import {DownloadReportButton} from "./download-button/download-report-button";
import {PreviewTable} from "./preview-table/preview-table";

export const DownloadableReport = ({tile, tileFilters, theme, loadData, downloadData, size}) =>
    cs(consumeContext("routing"), ({routing}) =>
        routing?.params?.tileId && ["filters", "fields"].includes(routing?.params?.tileTab)
            ? PreviewTable({
                  tile,
                  isDownloadReportTile: true,
                  tileFilters,
                  loadData,
              })
            : DownloadReportButton({tile, theme, tileFilters, downloadData})
    );
