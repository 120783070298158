import {cs} from "../../../../../../../react/chain-services";
import {fragments} from "../../../../../../../react/fragments";
import * as React from "react";
import "./numeric-filter-input.scss";
import {DropdownSelectLive} from "../../../../../filters/common/dropdown-select/dropdown-select-live";
import {NumberInputLive} from "../../../common/number-input-live/number-input-live";
import {ChipInput} from "../../../../../../../form/chip-input/chip-input";
import {ffToBasicInput} from "../../../common/ff-to-basic-input";
import {ffToDropdown} from "../../../../../../../form/ff-to-dropdown";
import {consumeContext} from "../../../../../../../react/context";
import {css} from "emotion";
import {comparisonMethods} from "../../../../../filters/text-filter/comparison-methods";
import {ControlFilterInput} from "../../../common/control-filter-input/control-filter-input";
import {StyledClass} from "@common/react/styled-class";
import {getDropdownCss} from "@common/ui-components/live/filters/common/dropdown-select/dropdown.config";

export const NumericFilterInput = ({filter, form, next}) =>
    cs(
        consumeContext("theme"),
        consumeContext("tileActionControlFilter"),
        [
            "cssClass",
            ({theme}, next) => {
                const componentTheme = theme.general.components;
                return StyledClass({
                    next,
                    content: getDropdownCss(componentTheme),
                });
            },
        ],
        ({theme, tileActionControlFilter, cssClass}) => {
            let list = filter.options.split(/\s*,\s*/);
            const hasActiveTileActionControl =
                tileActionControlFilter?.value?.action?.value &&
                (tileActionControlFilter?.value?.action?.filters || []).find((f) => f.id == filter.id) &&
                !list.find((item) => item == "EqualTo");
            const method = form.data.value?.comparisonOperation;
            const {from, to} = form.data.value?.range ?? {};
            const action = tileActionControlFilter?.value?.action?.value;
            const inputValue = form.data.value?.value;

            const preview = hasActiveTileActionControl
                ? action && <span>Equals to {action}</span>
                : ["IsNull", "IsNotNull"].includes(method)
                ? valueLabelMap[method]
                : method === "Range"
                ? from && to && `${from} - ${to}`
                : ["InList", "NotInList"].includes(method)
                ? form.data.value?.list ?? ""
                : inputValue && `${valueLabelMap[method]} ${inputValue}`;

            return next({
                mobileFilter: {
                    label: filter?.label,
                    shortPreview: () => preview,
                    preview: () => preview,
                },
                control: () => {
                    const componentTheme = theme.general.components;

                    const inputStyle = {
                        border: `${componentTheme.inputBorderWidth}px solid ${componentTheme.inputBorderColorRGB}`,
                        background: `${componentTheme.inputBackgroundColorRGB}`,
                        color: `${componentTheme.inputTextColorRGB}`,
                        borderRadius: `${componentTheme.inputCornerRadius}px`,
                    };

                    const line1 = (() => {
                        if (list.length < 2 || hasActiveTileActionControl) {
                            return null;
                        }

                        return DropdownSelectLive({
                            hideLabelOnValue: true,
                            label: "Comparison Operation",
                            list,
                            valueToLabel,
                            toggleStyle: inputStyle,
                            iconColor: componentTheme.inputIconColorRGB,
                            dropdownCss: cssClass,
                            borderRadius: componentTheme.menuCornerRadius,
                            ...ffToDropdown(form.field("comparisonOperation")),
                        });
                    })();

                    const line2 = hasActiveTileActionControl ? (
                        ControlFilterInput({label: "Equal To"})
                    ) : ["IsNull", "IsNotNull"].includes(form.data.value?.comparisonOperation) ? null : form.data.value
                          ?.comparisonOperation === "Range" ? (
                        fragments(
                            NumberInputLive({
                                placeholder: "From",
                                ...ffToBasicInput(form.field("range.from")),
                                style: inputStyle,
                            }),
                            NumberInputLive({
                                placeholder: "To",
                                ...ffToBasicInput(form.field("range.to")),
                                style: inputStyle,
                            })
                        )
                    ) : ["InList", "NotInList"].includes(form.data.value?.comparisonOperation) ? (
                        <div style={{display: "inline-block"}}>
                            {ChipInput({
                                state: form.field("list").state,
                                numericOnly: true,
                            })}
                        </div>
                    ) : (
                        NumberInputLive({
                            placeholder: filter.hint || "Value",
                            ...ffToBasicInput(form.field("value")),
                            style: inputStyle,
                        })
                    );

                    return [
                        <div className="numeric-filter-input-2w9 desktop">
                            {line1}
                            {line2}
                        </div>,
                    ];
                },
            });
        }
    );

const methods = [
    {
        label: "Range",
        name: "Range",
    },
    {
        label: "Equal To",
        name: "EqualTo",
    },
    {
        label: "Not Equal to",
        name: "NotEqualTo",
    },
    {
        label: "Greater than or equal to",
        name: "GreaterThanOrEqualTo",
    },
    {
        label: "Greater than",
        name: "GreaterThan",
    },
    {
        label: "Less than or equal to",
        name: "LessThanOrEqualTo",
    },
    {
        label: "Less than",
        name: "LessThan",
    },
    {
        label: "Is Null",
        name: "IsNull",
    },
    {
        label: "Is Not Null",
        name: "IsNotNull",
    },
    {
        label: "In List",
        name: "InList",
    },
    {
        label: "Not In List",
        name: "NotInList",
    },
];

const valueToLabel = (value) => {
    return methods.find((r) => r.name === value)?.label;
};
const valueLabelMap = methods.reduce((r, c) => ({...r, [c.name]: [c.label]}), {});
