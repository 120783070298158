import {addScrollingOptions} from "./scrolling/add-scrolling-options";
import {changePath} from "../../../utils/arr-path";
import {chain} from "../../../utils/fs";

// add options that are only available when chart size is available
export const addOptionsNeedingChartSize = ({tile, options, noScroll, chartWidth, chartHeight}) => {
    const addedOptions = [
        {
            condition: !noScroll && ["VerticalBarChartTile", "HorizontalBarChartTile", "ComboChartTile", "LineChartTile", "BoxPlotTile"].includes(tile.$type),
            addOption: addScrollingOptions,
        },
        {
            condition: ["VerticalBarChartTile", "HorizontalBarChartTile", "ComboChartTile", "LineChartTile"].includes(tile.$type),
            addOption: addDimensionAxisGridLabelsWidthLimit,
        },
    ];

    return chain(
        options,
        ...addedOptions.map((ao) => {
            if (ao.condition) {
                return (_) => ao.addOption({tile, chartWidth, chartHeight, options: _});
            }
            return (_) => _;
        })
    );
};

// for the width of dimension grid labels of a horizontal bar chart to not exceed half of chart width
// for the height of dimension grid labels of a non-horizontal bar chart to not exceed half of chart height
const addDimensionAxisGridLabelsWidthLimit = ({tile, options, chartWidth, chartHeight}) => {
    const notApply = tile.$type !== "HorizontalBarChartTile" && (!tile.style.xAxis.gridStepLabelOrientation || ["Auto", "Horizontal"].includes(tile.style.xAxis.gridStepLabelOrientation));

    if (notApply) {
        return options;
    }

    const getNewXAxisOption = (xAxisOption) => {
        const maxPercent = 0.45;
        const distanceBetweenLabelAndAxis = 10;
        const maxWidth = tile.$type === "HorizontalBarChartTile" ? chartWidth * maxPercent : chartHeight * maxPercent;
        return changePath(xAxisOption, ["labels", "style", "width"], () => maxWidth - distanceBetweenLabelAndAxis);
        // return changePath(xAxisOption, ["labels", "formatter"], (fn) => {
        //     return function() {
        //         console.log(this)
        //         const label = fn.apply(this);
        //         return label;
        //     }
        // });
    };

    const newXAxis = options.xAxis[0] ? [getNewXAxisOption(options.xAxis[0]), options.xAxis[1]] : getNewXAxisOption(options.xAxis);

    return {
        ...options,
        xAxis: newXAxis,
    };
};
