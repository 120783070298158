import {rBarLinePoints} from "./column-points-table";

export const getLinePointsTableForCombo = ({tile, theme, extractedPoints, rawData, formatters}) => {
    return rBarLinePoints({
        extractedPoints,
        useLineIndicator: true,
        tile,
        theme,
        formatters,
    });
};
