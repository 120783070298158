import * as React from "react";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {FieldItem} from "../../../../../../edit/common/field-item/field-item";
import {getPath} from "@common/utils/arr-path";
import {spc} from "@common/react/state-path-change";
import {MAX_NUM_COLOR} from "@common/ui-components/charts/get-field-color";

export const ReferenceLineColorConfig = ({referenceLine}) =>
    cs(consumeContext("theme"), ({theme}) => {
        const {discreteColorsRGB} = getPath(theme, ["dataVisualization", "dataColorPalettes"]);
        return (
            <div className="reference-line-color-config-24f colors-tile-config-vrf">
                <div className="default-padding">
                    {discreteColorsRGB.map((color, index) => (
                        <FieldItem
                            label={
                                <div>
                                    COLOR {index + 1}
                                    <div className="discrete-color" style={{background: color}} />
                                </div>
                            }
                            key={index}
                            rightIcon={
                                (referenceLine.value.colorApplication?.index ?? MAX_NUM_COLOR.DiscreteColorApplication - 1) === index && <i className="material-icons">done</i>
                            }
                            onClick={() => {
                                spc(referenceLine, ["colorApplication"], () => ({
                                    $type: "DiscreteColorApplication",
                                    index,
                                }));
                            }}
                        />
                    ))}
                </div>
            </div>
        );
    });
