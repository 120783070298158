import {Fragment} from "react";
import {css, cx} from "emotion";
import {MinusIcon, PlusIcon} from "../../../icons/global-icons";
import {consumeContext} from "../../../../react/context";
import {cs} from "../../../../react/chain-services";
import {PivotTableLine} from "../table-line/pivot-table-line";
import {UseState} from "../../../../react/use-state";
import {Watch} from "../../../../react/watch";
import {OnMounted} from "../../../../react/on-mounted";
import {cGetFontSize} from "../../common/style-map/font-size";
import {getFontOptions} from "../../table/table/common/get-font-options";
import {Static2} from "../../../../react/static-2";
import {
    CurrencyConversionWarning,
    getPivotTableTileColumnCurrencyConversion,
} from "../../common/currency-conversion-warning/currency-conversion-warning";
import {StyledClass} from "@common/react/styled-class";

export const PivotTableHeader = ({
    data,
    tileFields,
    headers,
    collapsibleColumns,
    collapsedColumns,
    maxFixedColumnWidth,
    tile,
    isMobile,
    hideFixed,
}) =>
    cs(
        consumeContext("theme"),
        [
            "trClass",
            ({theme}, next) =>
                StyledClass({
                    content: {
                        th: {
                            "&:last-child": {
                                ".vertical-line": {
                                    "&::after": {
                                        display: `${theme.dataVisualization.tables.borderOutsideLines ? "block" : "none"}`,
                                    },
                                },
                            },
                        },
                    },
                    next,
                }),
        ],
        ({theme, trClass}) => {
            const getFontSize = cGetFontSize(theme.general.canvas.fontSize, theme);
            const tablesStyle = theme.dataVisualization.tables;

            const tableStyle = {
                textTransform:
                    tablesStyle.headerCaseOptions === "TitleCase"
                        ? "capitalize"
                        : tablesStyle.headerCaseOptions === "Uppercase"
                        ? "uppercase"
                        : "",
                backgroundColor: tablesStyle.headerBackgroundColorRGB || "#FFF",
                fontSize: getFontSize({
                    group: tablesStyle.headerFontSize || theme.general.canvas.fontSize,
                    elemType: "label",
                }),
                ...getFontOptions(tablesStyle.headerFontOptions),
                color: tablesStyle.headerFontColorRGB || "#bcbcbc",
            };

            return (
                <thead>
                    {headers.map((headerRowGroup, rowIndex) => {
                        // 6133: Pivot Table - Single Value Hide Header Row
                        if (headerRowGroup[0]?.groupID === "agg" && (data.aggregates || []).length === 1) return null;

                        return (
                            <tr className={cx(trClass)} style={{height: tile.style.rowHeight || 32}} key={rowIndex}>
                                {rowIndex == 0 && (
                                    <th
                                        colSpan={1}
                                        className="fixed-column"
                                        rowSpan={headers.length}
                                        style={{
                                            maxWidth: maxFixedColumnWidth,
                                            ...tableStyle,
                                        }}
                                    >
                                        <PivotTableLine isFirstColumn isFirstRow />
                                    </th>
                                )}

                                {headerRowGroup.map((group, headerIndex) => {
                                    return (
                                        <Fragment key={headerIndex}>
                                            {group.items.map((column, index) =>
                                                cs(["nextElemWidth", (_, next) => Static2({next})], ({nextElemWidth}) => {
                                                    const allowCollapsible = collapsibleColumns.indexOf(column.id) > -1;
                                                    const collapsible = collapsedColumns.value.find((c) => c == column.id);
                                                    const isGrandTotal = column.id == "Grand Total" || column.parent == "Grand Total";

                                                    return (
                                                        <th
                                                            ref={(elem) => {
                                                                if (
                                                                    column.parent == "Grand Total" &&
                                                                    tile.style.grandTotalColumnPosition == "Fixed" &&
                                                                    !isMobile &&
                                                                    elem?.nextSibling
                                                                ) {
                                                                    let width = 0;

                                                                    const getNextElemWidth = (elem) => {
                                                                        let nextElem = elem.nextSibling;
                                                                        if (!nextElem) return;
                                                                        width += parseFloat(
                                                                            getComputedStyle(nextElem).width.replace("px", "")
                                                                        );
                                                                        getNextElemWidth(nextElem);
                                                                    };

                                                                    getNextElemWidth(elem);

                                                                    nextElemWidth.set(width);
                                                                }
                                                            }}
                                                            className={cx(
                                                                isGrandTotal ? "grand-total" : "",
                                                                allowCollapsible && "clickable",
                                                                isGrandTotal &&
                                                                    tile.style.grandTotalColumnPosition == "Fixed" &&
                                                                    !isMobile &&
                                                                    "fixed-grand-total"
                                                            )}
                                                            colSpan={column.colSpan}
                                                            rowSpan={column.rowSpan}
                                                            key={index}
                                                            onClick={() => {
                                                                if (allowCollapsible) {
                                                                    if (collapsible) {
                                                                        collapsedColumns.onChange(
                                                                            collapsedColumns.value.filter((c) => c != column.id)
                                                                        );
                                                                    } else {
                                                                        collapsedColumns.onChange(collapsedColumns.value.concat(column.id));
                                                                    }
                                                                }
                                                            }}
                                                            style={{
                                                                ...tableStyle,
                                                                ...(nextElemWidth.get()
                                                                    ? {
                                                                          right: nextElemWidth.get(),
                                                                      }
                                                                    : {}),
                                                            }}
                                                        >
                                                            <PivotTableLine
                                                                isFixedColumn={
                                                                    isGrandTotal &&
                                                                    tile.style.grandTotalColumnPosition == "Fixed" &&
                                                                    !isMobile &&
                                                                    !hideFixed.value &&
                                                                    index == 0
                                                                }
                                                                isFirstRow={rowIndex == 0}
                                                                isLatestColumn={
                                                                    headerIndex == headerRowGroup.length - 1 &&
                                                                    index == group.items.length - 1 &&
                                                                    !isGrandTotal &&
                                                                    !tile.style.grandTotalsColumnShown
                                                                }
                                                            />

                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    "align-items": "center",
                                                                }}
                                                            >
                                                                {column.name}{" "}
                                                                {allowCollapsible && <>{collapsible ? <PlusIcon /> : <MinusIcon />}</>}
                                                                {group.parentID &&
                                                                    CurrencyConversionWarning({
                                                                        theme,
                                                                        currencyConversion: getPivotTableTileColumnCurrencyConversion({
                                                                            data,
                                                                            tileFields,
                                                                            column: {
                                                                                ...column,
                                                                                parentID: group.parentID,
                                                                            },
                                                                        }),
                                                                    })}
                                                            </div>
                                                        </th>
                                                    );
                                                })
                                            )}
                                        </Fragment>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </thead>
            );
        }
    );
