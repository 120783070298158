// const moment = require("moment-timezone");
const {paddingLeft} = require("../../utils/strings");

const formatDateTime = ({date, time} = {}) => {
    if (date == null) {
        return null;
    }
    return (
        date.year +
        "-" +
        paddingLeft(date.month) +
        "-" +
        paddingLeft(date.day) +
        "T" +
        paddingLeft(time?.hours ?? 0) +
        ":" +
        paddingLeft(time?.minutes ?? 0) +
        ":" +
        paddingLeft(time?.seconds ?? 0) +
        "Z" +
        ""
    );
};
exports.formatDateTime = formatDateTime;

const parseDateToObject = (date) => {
    return {
        day: date.getDate(),
        month: date.getMonth() + 1,
        year: date.getFullYear(),
    };
};

exports.parseDateToObject = parseDateToObject;

const formatSecondstoMMss = (secs) => {
    const minutes = Math.floor(secs / 60);
    const seconds = Math.floor(secs) % 60;

    return `${minutes}:${paddingLeft(seconds)}`;
};

exports.formatSecondstoMMss = formatSecondstoMMss;

// const formatDateWithTz = ({date, time, timezone}={}) => {
//     if (date==null) {
//         return null;
//     }
//     if (!timezone) {
//         return formatDateTime({date, time});
//     }
//
//     const strDT = (
//         date.year + "-" +
//         paddingLeft(date.month) + "-" +
//         paddingLeft(date.day) + ""
//     )
//
//     return moment.tz(strDT, 'YYYY-MM-DD', timezone).toISOString();
// };
// exports.formatDateWithTz = formatDateWithTz;
