import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {TabsHeader} from "../../../common/tabs/tabs-header";
import {ClearedErrors} from "./cleared-errors";
import "./ds-errors-summary.scss";
import {UnresolvedErrors} from "./unresolved-errors";

const ERRORS = {
    UNRESOLVED: "Unresolved",
    CLEARED: "Cleared",
};

export const DsErrorsSummary = ({dataSource}) =>
    cs(["tab", (_, next) => UseState({initValue: ERRORS.UNRESOLVED, next})], ({tab}) => (
        <div className="ds-errors-99p">
            {TabsHeader({
                tabs: [
                    {
                        label: `${ERRORS.UNRESOLVED} Errors`,
                        name: ERRORS.UNRESOLVED,
                    },
                    {
                        label: `${ERRORS.CLEARED} Errors`,
                        name: ERRORS.CLEARED,
                    },
                ].map((item) => ({
                    ...item,
                    active: item.name === tab.value,
                    label: <>{item.label}</>,
                    // label: <>{item.label} ({item.numbers()})</>,
                    onClick: (item) => tab.onChange(item.name),
                })),
            })}

            <div className="list">
                {tab.value === ERRORS.UNRESOLVED && UnresolvedErrors({dataSource})}
                {tab.value === ERRORS.CLEARED && ClearedErrors({dataSource})}
            </div>
        </div>
    ));
