import {UseState} from "@common/react/use-state";
import {cs} from "@common/react/chain-services";
import * as React from "react";
import {DialogService} from "../../../../common/dialog/dialog-service";
import {consumeContext} from "@common/react/context";
import {Load} from "@common/react/load";
import {formatDateTimeFromISOStr} from "../../../../../../../common/logic/date-time/format-date-time-full";
import "./publish-api-dialog.scss";
import {TextInput} from "../../../../../../../common/form/text-input/text-input";
import {Form2} from "@common/react/cs-form/form2";
import {required} from "@common/react/cs-form/validators/required";
import {isVersionNumber} from "@common/react/cs-form/validators/is-version-number";
import {ffToBasicInput} from "../../../../../../../common/form/ff-to-basic-input";
import {isNumber} from "@common/react/cs-form/validators/is-number";
import {cx} from "emotion";
import {removeIdsInCollection} from "../../../../common/remove-ids";
import {Button} from "../../../../../../../common/form/buttons/button/button";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";

export const PublishApiDialog = ({next: rootNext}) =>
    cs(
        consumeContext("apis"),
        consumeContext("collection"),
        consumeContext("routing"),
        [
            "modal",
            ({collection}, next) =>
                DialogService({
                    // initShow: isDevQuan(),
                    render: ({resolve}) => ({
                        title: "Publish " + collection.value?.name,
                        width: 560,
                        content: next({
                            resolve,
                        }),
                    }),
                    next: rootNext,
                }),
        ],
        [
            "data",
            ({apis, collection}, next) =>
                Load({
                    fetch: () =>
                        apis.collection.getPublishInfo({
                            collectionId: collection.value.id,
                        }),
                    next,
                }),
        ],
        ["publishing", (_, next) => UseState({next})],
        ["state", (_, next) => UseState({next})],
        ["option", (_, next) => UseState({next, initValue: "over_write"})],
        [
            "form",
            ({state, saving, apis, auth, collection, publishing, modal, data, option, routing}, next) =>
                Form2({
                    fields: {
                        ...(option.value == "over_write"
                            ? {}
                            : {
                                  version: {
                                      validators: [required, isNumber, isVersionNumber],
                                      asyncValidators: [
                                          {
                                              validate: (name, {data}) =>
                                                  apis.collection.checkCollectionName(collection.value.name, {version: data.version}),
                                              getMessage: () => `Existed`,
                                          },
                                      ],
                                  },
                              }),
                    },
                    data: state,
                    onSubmit: async () => {
                        publishing.onChange({doing: true});

                        if (data.publishedOn) {
                            if (option.value == "over_write") {
                                modal.resolve({version: collection.value.version});
                            } else {
                                let newCollection = await apis.collection.upsertCollection({
                                    ...removeIdsInCollection(collection.value),
                                    version: state.value.version,
                                });
                                routing.goto("edit-collection", {
                                    colId: newCollection.id,
                                    collectionTab: "fields",
                                });
                            }
                        } else {
                            await apis.collection.upsertCollection({
                                ...collection.value,
                                version: state.value?.version || collection.value.version,
                            });
                            modal.resolve({version: state.value?.version || collection.value.version});
                        }
                    },
                    next,
                }),
        ],
        ({modal, data, publishing, option, collection, form}) => {


            return (
                <div className="publish-collection-dialog-8sx">
                    <VerbDialogBodyScrollbar>
                        {data == null ? (
                            <div className="messages">
                                <div className="message">Loading...</div>
                            </div>
                        ) : (data.publishedOn || collection.value.version) ? (
                            <div className="messages">
                                Publishing will update this collection for users as soon as the data is ready.
                                <div className="publish-options">
                                    <div className="publish-option" onClick={() => option.onChange("over_write")}>
                                        <div className={cx("radio", option.value == "over_write" && "checked")} />

                                        <div className="option-name">
                                            <b>Overwrite Current Version (Version {collection.value.version})</b>
                                        </div>

                                        <div className="option-description">
                                            {data.publishedOn ? (
                                              <>Last published on {formatDateTimeFromISOStr(data.publishedOn)}{" "}
                                                  {data.publishedBy ? " by " + data.publishedBy : ""}</>
                                            ) : <>Never published</>}
                                        </div>
                                    </div>

                                    <div className="publish-option" onClick={() => option.onChange("new_version")}>
                                        <div className={cx("radio", option.value == "new_version" && "checked")} />

                                        <div className="option-name">
                                            <b>Create New Version</b>
                                        </div>

                                        <div className="option-description">
                                            Previous version will remain available until deleted by your organization.
                                        </div>
                                    </div>

                                    <div className="text-input-wrapper">
                                        {option.value == "new_version" &&
                                            TextInput({
                                                label: "New Version Number",
                                                info: "Version names are numeric only. They may have 1 whole part and up to 5 fractional parts, i.e. X.XXXXX.",
                                                ...ffToBasicInput(form.field("version")),
                                            })}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="messages">
                                <div className="message">
                                    This API Collection has never been published. Please add a version number in order to complete the
                                    publish process.
                                    <br />
                                    <br />
                                    The data will be available to users as soon as the publish process is complete.
                                    <br />
                                    <br />
                                    {TextInput({
                                        label: "Version Number",
                                        info: "Version names are numeric only. They may have 1 whole part and up to 5 fractional parts, i.e. X.XXXXX.",
                                        ...ffToBasicInput(form.field("version")),
                                    })}
                                </div>
                            </div>
                        )}
                    </VerbDialogBodyScrollbar>

                    <div className="buttons">
                        <Button btnType="secondary" onClick={modal.resolve}>
                            {publishing.value?.done ? "Done" : "Cancel"}
                        </Button>
                        <Button disabled={publishing.value} onClick={form.submit}>
                            {option.value == "new_version" ? "Create New Version" : "Publish"}
                        </Button>
                    </div>
                </div>
            );
        }
    );
