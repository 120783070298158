import {cs} from "@common/react/chain-services";
import {DsListTypes} from "./list/ds-list-types";
import "./select-data-source.scss";

export const SelectDataSource = ({next, data, dsApi}) =>
    cs(() =>
        next({
            render: () => (
                <div className="select-data-source-88r">
                    <div className="left-side">
                        <div className="header">1. Select data source type</div>

                        {DsListTypes({data, dsApi})}
                    </div>

                    <div className="right-side">
                        <div className="introduction">
                            <div className="label">How Verb connections work</div>
                            <div className="description">
                                <p>
                                    Verb denormalizes data across data types and sources and optimizes data warehouses to save you valuable
                                    time and to help create better data experiences. When dashboards are rendered and reports run, queries
                                    hit your individualized Verb warehouse instead of your environment or individual sources.
                                </p>
                                <p>
                                    Setting up your sync process will give you the opportunity to select the data that you want to ingest
                                    and how you want to ingest it. The initial ingestion will begin upon completion however you will be able
                                    to start modeling your data and create reports immediately.{" "}
                                </p>
                                <p>Data security is our priority. Data is always encrypted - in flight and at rest using AES-256-GCM.</p>
                            </div>
                        </div>
                    </div>
                </div>
            ),
        })
    );
