import {createFetcher} from "../../../../tools/fetcher/fetcher";
import {cQueryString} from "../../apis/auth/env-apis";

const notionAppHost = process.env.appConfig["notion-app"]["api.host"];
// const notionAppHost = `http://localhost:3002`;
// const notionAppHost = `https://notion-app-dev.data.revivemedia.us`;
// const notionAppHost = `https://notion-app.data.revivemedia.us`;

export const createReportApis = () => {
    const fetcher = createFetcher({
        urlModifier: (url) => `${notionAppHost}${url}`,
    });

    return {
        getReportSections: () => fetcher.get("/api/report-sections"),
        getReportById: (id) => fetcher.get(`/api/report/${id}`),
        // generateReport: (report) => fetcher.post("/api/report", report),
        saveReport: (report) => fetcher.put("/api/report", report),
        getReports: () => fetcher.get("/api/reports"),
        getRecurringReports: () => fetcher.get(`/api/reports${cQueryString({recurring: true})}`),
        getOnetimeReports: () => fetcher.get(`/api/reports?recurring=false`),

        disableReport: (id) => fetcher.put(`/api/report/${id}/disable`),
        enableReport: (id) => fetcher.put(`/api/report/${id}/enable`),
        deleteReport: (id) => fetcher.put(`/api/report/${id}/delete`),

        // testRecurring: () => fetcher.put("/api/test-recurring"),
        // stopTestRecurring: () => fetcher.put("/api/stop-test-recurring"),
        // listRecurringJobs: () => fetcher.get("/api/recurring-jobs"),
    };
};
