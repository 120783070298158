import * as React from "react";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {stateToSelect} from "@common/ui-components/form/state-to-select";

const listValues = [
    {
        value: "AlternateAxis",
        label: "Alternate Axis Options",
    },
    {
        value: "IndependentAxes",
        label: "Independent Axes",
    },
];

export const MultipleAxisOptions = ({state, label = "Multiple Axis Options"}) =>
    DropdownSelect({
        registryRender: true,
        list: listValues,
        valueToLabel: (v) => v.label,
        ...stateToSelect(state, ["value"]),
        directionTooltip: "top",
        info: (
            <span>
                <b>Alternate Axis Option:</b> Separates each axis into an option that the user can turn on/off with a menu.
                <br />
                <br />
                <b>Independent Axes:</b> Will show all axes at once.
            </span>
        ),
        label,
    });
