const {formatDateTime} = require("../../../../../../../../../../common/logic/date-time/format-date-time");
const {today} = require("../../../../../../../../../../common/utils/dates/date-object");

const DEFAULT_LAST_OR_NEXT_DAYS_VALUE = 90;

const DEFAULT_LAST_OR_NEXT_PERIOD_VALUE = 1;

exports.DEFAULT_LAST_OR_NEXT_DAYS_VALUE = DEFAULT_LAST_OR_NEXT_DAYS_VALUE;

exports.DEFAULT_LAST_OR_NEXT_PERIOD_VALUE = DEFAULT_LAST_OR_NEXT_PERIOD_VALUE;

const defaultCustomRanges = [
    {
        label: "In the last",
        isSelected: (customRange) => customRange?.$type === "InTheLastOrNextCustomDateFilterRange" && !customRange?.next,
        onSelectValue: ({customRangeInitValue} = {}) => ({
            customRange: {
                $type: "InTheLastOrNextCustomDateFilterRange",
                value: customRangeInitValue?.inPast ?? DEFAULT_LAST_OR_NEXT_DAYS_VALUE,
                interval: "Days",
                negative: false,
            },
            defaultOption: "Custom",
        }),
    },
    {
        label: "In the previous",
        isSelected: (customRange) => customRange?.$type === "InThePreviousCustomDateFilterRange",
        onSelectValue: ({customRangeInitValue} = {}) => ({
            customRange: {
                $type: "InThePreviousCustomDateFilterRange",
                value: customRangeInitValue?.inPast ?? DEFAULT_LAST_OR_NEXT_DAYS_VALUE,
                interval: "Days",
                negative: false,
            },
            defaultOption: "Custom",
        }),
    },
    {
        label: "In the next",
        isSelected: (customRange) => customRange?.$type === "InTheLastOrNextCustomDateFilterRange" && customRange?.next,
        onSelectValue: ({customRangeInitValue} = {}) => ({
            customRange: {
                $type: "InTheLastOrNextCustomDateFilterRange",
                next: true,
                value: customRangeInitValue?.inFuture ?? DEFAULT_LAST_OR_NEXT_DAYS_VALUE,
                interval: "Days",
                negative: false,
            },
            defaultOption: "Custom",
        }),
    },

    // todo: @sonvn update this
    {
        label: "In the current",
        isSelected: (customRange) => customRange?.$type === "InTheCurrentCustomDateFilterRange",
        onSelectValue: () => ({
            customRange: {
                $type: "InTheCurrentCustomDateFilterRange",
                next: true,
                value: 1,
                interval: "Days",
                negative: false,
            },
            defaultOption: "Custom",
        }),
    },
    {
        label: "Before date",
        isSelected: (customRange) => customRange?.$type === "BeforeOrAfterCustomDateFilterRange" && !customRange?.after,
        onSelectValue: () => ({
            customRange: {
                $type: "BeforeOrAfterCustomDateFilterRange",
                date: formatDateTime({date: today()}),
            },
            defaultOption: "Custom",
        }),
    },
    {
        label: "After date",
        isSelected: (customRange) => customRange?.$type === "BeforeOrAfterCustomDateFilterRange" && customRange?.after,
        onSelectValue: () => ({
            customRange: {
                $type: "BeforeOrAfterCustomDateFilterRange",
                date: formatDateTime({date: today()}),
                after: true,
            },
            defaultOption: "Custom",
        }),
    },
    {
        label: "In date range",
        isSelected: (customRange) => customRange?.$type === "InRangeCustomDateFilterRange",
        onSelectValue: () => ({
            customRange: {
                $type: "InRangeCustomDateFilterRange",
                dateStart: formatDateTime({date: today()}),
                dateEnd: formatDateTime({date: today()}),
            },
            defaultOption: "Custom",
        }),
    },
    {
        label: "In the year",
        isSelected: (customRange) => customRange?.$type === "InTheYearCustomDateFilterRange",
        onSelectValue: () => ({
            customRange: {
                $type: "InTheYearCustomDateFilterRange",
                year: new Date().getFullYear(),
            },
            defaultOption: "Custom",
        }),
    },
];
exports.defaultCustomRanges = defaultCustomRanges;

const localDefaultCustomRanges = [
    {
        label: "Is Null",
        isSelected: (_, defaultOption) => defaultOption === "IsNull",
        onSelectValue: () => ({
            customRange: null,
            defaultOption: "IsNull",
        }),
    },
    {
        label: "Is Not Null",
        isSelected: (_, defaultOption) => defaultOption === "IsNotNull",
        onSelectValue: () => ({
            customRange: null,
            defaultOption: "IsNotNull",
        }),
    },
];

exports.localDefaultCustomRanges = localDefaultCustomRanges;
