const {adjustHorizontalBarDataLabels} = require("./adjust-horizontal-bar-data-labels");
const {drawRoundCorners} = require("../../bar-series-processors/draw-round-corners");
const {initHorizontalComparisonBarsHelper} = require("./draw-horizontal-comparison-bars");
const {adjustComparisonBars} = require("../../vertical-bar/chart-options/adjust-comparison-bars");
const {hideOriginYaxisGridLine} = require("../../combo-chart/chart-options/hide-origin-yaxis-grid-line");
const {getTooltipOptions} = require("../../factory/tooltip/tooltip-options");
const {getPlotOptions} = require("./plot-options");
const {getLegendOptions} = require("../../factory/legend");
const {fixHorizontalDimAxisLabel} = require("./fix-horizontal-dim-axis-label");
const {drawIndicatorSeriesLabel} = require("../../reference-lines/draw-indicator-series-label");
const {SERIES_BOOST_THRESHOLD} = require("../../common/axis-chart-boost-turbo-constants");

const getHorizontalBarOptions = ({tile, theme, isCompare, rawData, formatters, onClickPoint}) => {
    const chartEventsFn = ({chart}) => {
        !tile.style.yAxis.lineShown && hideOriginYaxisGridLine(chart);
        isCompare && tile.style.displayType && tile.style.displayType !== "Classic" && adjustComparisonBars({chart, isHorizontal: true});
        tile.style.dataLabels.show &&
            adjustHorizontalBarDataLabels({
                chart,
                position: tile.style.dataLabels.position,
            });

        if (tile.style.yAxis.maxValuesInView !== "AutoMax") {
            // logic for applying scrolling on horizontal bar chart is in add-scrolling-options.js
            tile.style.yAxis.labelShown && tile.style.yAxis.label && fixHorizontalDimAxisLabel(chart);
        }

        drawIndicatorSeriesLabel({chart});
    };

    const chart = {
        type: "bar",
        customType: "HorizontalBarChartTile",
        // open space for scrollbar
        ...(tile.style.yAxis.maxValuesInViewFixed && {
            marginRight: 30,
        }),
        backgroundColor: theme.general.tile.styles.tileBackgroundColorRGB,
        events: {
            load: function () {
                const chart = this;
                chartEventsFn({chart});

                if (isCompare && (!tile.style.displayType || tile.style.displayType === "Classic")) {
                    chart.comparisonBars = initHorizontalComparisonBarsHelper({
                        compareSeries: rawData.series.filter((s) => s.isCompare),
                        tile,
                    });

                    chart.comparisonBars.render({chart});
                }
            },
            render: function () {
                drawRoundCorners.apply(this);
            },
            redraw: function () {
                const chart = this;
                chartEventsFn({chart});

                if (chart.comparisonBars) {
                    chart.comparisonBars.rerender({chart});
                }
            },
        },
        zooming: {
            mouseWheel: false,
        },
    };

    const title = {
        text: undefined,
    };

    const plotOptions = getPlotOptions({
        tile,
        theme,
        formatters,
        isCompare,
        onClickPoint,
    });

    const tooltip = getTooltipOptions({
        tile,
        theme,
        isCompare,
        formatters,
        rawData,
    });

    const legend = getLegendOptions({style: tile.style.legendStyle, theme});

    return {
        boost: {
            enabled: true,
            seriesThreshold: SERIES_BOOST_THRESHOLD,
            useGPUTranslations: true,
            usePreAllocated: true,
        },
        chart,
        title,
        plotOptions,
        tooltip,
        legend,
        credits: {
            enabled: false,
        },
    };
};
exports.getHorizontalBarOptions = getHorizontalBarOptions;
