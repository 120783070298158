import * as React from "react";
import {cs} from "@common/react/chain-services";
import "./design.scss";
import {NumberInput} from "../../../../../../../../../../../../../common/form/number-input/number-input";
import {DebounceNumber} from "../../../../../../../../../../../../../common/form/debounce-number";
import {scope} from "@common/react/scope";
import {LpColorLine} from "../../../../../../../../../../common/left-panel/lp-color-line";
import {LpDualControlLine} from "../../../../../../../../../../common/left-panel/dual-control-line/lp-dual-control-line";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {ColorPicker} from "../../../../../../../../../../common/color-picker/color-picker";
import {getPath} from "@common/utils/arr-path";
import {spc} from "@common/react/state-path-change";
import {LpShowHideLine} from "../../../../../../../../../../common/left-panel/lp-show-hide-line";
import {Columns} from "../columns/columns";
import {Rows} from "../rows/rows";

export const Design = ({tile, form}) => ({
    label: "Design",
    render: () => (
        <div className="table-design-sfv">
            <div className="subsection">
                <div className="header">Columns</div>
                {Columns({tile})}
            </div>

            <div className="subsection">
                <div className="header">Rows</div>
                {Rows({tile})}
            </div>
        </div>
    ),
});
