import { Button } from "@common/form/buttons/button/button";
import { ffToTextInput } from "@common/form/ff-to-text-input";
import { TextInput } from "@common/form/text-input/text-input";
import { cs } from "@common/react/chain-services";
import { consumeContext } from "@common/react/context";
import { Form2 } from "@common/react/cs-form/form2";
import { SubcribeKeyEvents } from "@common/react/keys/global-key-down";
import { Load } from "@common/react/load";
import { UseState } from "@common/react/use-state";
import { VerbDialogBodyScrollbar } from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";
import { waitTimeout } from "@common/utils/wait-timeout";
import * as React from "react";
import { DialogService } from "../../../../../common/dialog/dialog-service";
import { isParentApiCollection } from "../../collection-dashboard-helpers";
import { collectionFormConfig } from "../create-collection/collection-form-config";
import "./rename-collection-dialog.scss";

export const RenameCollectionDialog = ({onDone, next: rootNext}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    render: ({resolve, args: {collection}}) => ({
                        title: "Rename Collection",
                        width: 540,
                        content: next({
                            resolve,
                            collection,
                        }),
                    }),
                    next: rootNext,
                }),
        ],
        consumeContext("apis"),
        [
            "fullCol",
            ({modal, apis}, next) =>
                Load({
                    fetch: async () => {
                        if (isParentApiCollection(modal.collection)) {
                            return await Promise.all(modal.collection.childs.map((c) => apis.collection.getCollection(c.id)));
                        }
                        return await apis.collection.getCollection(modal.collection.id);
                    },
                    next,
                }),
        ],
        [
            "editing",
            ({modal}, next) =>
                UseState({
                    initValue: {
                        name: modal.collection.name,
                    },
                    next,
                }),
        ],
        [
            "form",
            ({modal, editing, apis, fullCol}, next) =>
                Form2({
                    data: editing,
                    fields: {
                        name: collectionFormConfig({
                            apis,
                            collection: modal.collection,
                        }).fields.name,
                    },
                    onSubmit: async () => {
                        if (!fullCol) {
                            await waitTimeout(2000);
                        }

                        if (fullCol) {
                            if (isParentApiCollection(modal.collection)) {
                                await Promise.all(
                                    fullCol.map((c) =>
                                        apis.collection.upsertCollection({
                                            ...c,
                                            name: editing.value.name,
                                        })
                                    )
                                );
                            } else {
                                await apis.collection.upsertCollection({
                                    ...fullCol,
                                    name: editing.value.name,
                                });
                            }

                            const newFolders = await apis.collection.getFolders();
                            onDone(newFolders);
                            modal.resolve();
                        }
                    },
                    next,
                }),
        ],
        ({modal, form}) => {
            return (
                <div className="rename-collection-dialog-zd9">
                    <VerbDialogBodyScrollbar>
                        <div className="main-part">
                            {TextInput({
                                label: "Rename Collection",
                                ...ffToTextInput(form.field("name")),
                            })}
                        </div>
                    </VerbDialogBodyScrollbar>

                    {SubcribeKeyEvents({
                        events: [
                            {
                                keyCombo: "Enter",
                                onKeyDown: () => form.looksValid && form.submit(),
                            },
                        ]
                    })}
                    <div className="buttons">
                        <Button btnType="secondary" onClick={() => modal.resolve()}>
                            Cancel
                        </Button>
                        <Button disabled={!form.looksValid} onClick={form.submit}>
                            Rename
                        </Button>
                    </div>
                </div>
            );
        }
    );
