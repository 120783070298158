import {cs} from "@common/react/chain-services";
import {StaticTooltipService} from "../../../../common/tooltip3/static-tooltip-service";
import {AlertIcon} from "@common/ui-components/icons/global-icons";

export const SyncColumnAlertIcon = ({column, oriColumn, table, oriTable}) => {
    if (!column || !oriColumn) return null;

    const disabledColumn = (column, table) => table.disabledColumns.findIndex((c) => c?.name === column?.name) > -1;

    const logics = [
        {
            condition: () => column.deleted_in_remote_column,
            tooltipText: (
                <div>
                    This column no longer exists in the data source.
                    <br />
                    <br />
                    If any models or collections are dependent on this column, they will be in error after saving this data source.
                </div>
            ),
            iconType: "error",
        },
        {
            condition: () => {
                return disabledColumn(column, table) && !disabledColumn(oriColumn, oriTable);
            },
            tooltipText: "If any models or collections are dependent on this column, they will be in error after saving this data source.",
            iconType: "alert",
        },
        {
            condition: () => {
                return column.dataTypeProperties != oriColumn.dataTypeProperties && !disabledColumn(column, table);
            },
            tooltipText: (
                <div>
                    This column’s data type has changed. To fix, sync should be set to “No” and the data source should be saved. Then sync can be set to “Yes” again.
                    <br />
                    <br />
                    If any models or collections are dependent on this column’s data type, they will be in error after this fix.
                </div>
            ),
            iconType: "error",
        },
    ];

    const condition = logics.find((l) => l.condition());

    if (condition) {
        return cs(
            [
                "staticTooltip",
                (_, next) =>
                    StaticTooltipService({
                        direction: "top",
                        info: condition.tooltipText,
                        tooltipBoxWidth: 185,
                        next,
                    }),
            ],
            ({staticTooltip}) => {
                return staticTooltip.renderIcon({
                    icon: condition.iconType == "alert" ? <AlertIcon fill="#ECCD39" /> : <AlertIcon />,
                    className: "sync-alert",
                });
            }
        );
    }

    return null;
};
