import React from "react";
import {cs} from "@common/react/chain-services";
import {DialogService} from "../../../../common/dialog/dialog-service";
import "./manage-member-dialog.scss";
import {TextInput} from "../../../../../../../common/form/text-input/text-input";
import {cx} from "emotion";
import {Form2} from "@common/react/cs-form/form2";
import {required} from "@common/react/cs-form/validators/required";
import {consumeContext} from "@common/react/context";
import {UseState} from "@common/react/use-state";
import {email} from "@common/react/cs-form/validators/email";
import {ffToBasicInput} from "../../../../../../../common/form/ff-to-basic-input";
import {Button} from "../../../../../../../common/form/buttons/button/button";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";
import {GlobalEvent} from "@common/react/global-event";

export const userRoles = [
    {
        name: "Full Admin",
        description: "Access all features and manage organization settings",
        value: "Full",
    },
    {
        name: "Collection Editor",
        description: "Edit collections & themes",
        value: "CollectionEditor",
    },
    //{name: "Data Source Admin", description: "Manage data sources and collections", value: "DataSource"},
];

export const ManageMemberDialog = ({next: rootNext}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    render: ({resolve, args: {user}}) => ({
                        title: user.id ? "Edit Member's Access" : "Invite Organization Member",
                        width: 480,
                        content: next({
                            resolve,
                            user,
                        }),
                        initShow: true,
                        className: "manage-member-dialog-wrapper-a22",
                    }),
                    next: rootNext,
                }),
        ],

        ({modal}) =>
            cs(
                consumeContext("apis"),
                [
                    "params",
                    ({routing, auth}, next) =>
                        UseState({
                            initValue: modal.user,
                            next,
                        }),
                ],
                ["saving", (_, next) => UseState({next})],
                ["error", (_, next) => UseState({next})],
                [
                    "form",
                    ({params, saving, apis, auth, error}, next) =>
                        Form2({
                            fields: {
                                email: modal.user.id ? [] : [required, email],
                            },
                            data: params,
                            onSubmit: async (user) => {
                                saving.onChange(true);
                                error.onChange(false);

                                try {
                                    if (user.id) {
                                        await apis.user.updateRole({
                                            email: user.email,
                                            roles: user.roles,
                                        });
                                    } else {
                                        await apis.tenant.inviteUser(user);
                                    }

                                    const users = await apis.tenant.getUsers();
                                    modal.resolve(users);
                                } catch (err) {
                                    saving.onChange(false);
                                    error.onChange(err.message);
                                }
                            },
                            next,
                        }),
                ],
                ({form, params, saving, error}) => {
                    return (
                        <div className="manage-member-dialog-z33">
                            <VerbDialogBodyScrollbar>
                                <div className="error-message">{error.value}</div>

                                {!modal.user.id &&
                                    TextInput({
                                        label: "Teammate's Email Address",
                                        ...ffToBasicInput(form.field("email")),
                                    })}

                                <div className="roles-wrapper">
                                    <div className="role-title">
                                        {modal.user.id
                                            ? `${modal.user.$type == "PendingUserInvite" ? modal.user.email : `${modal.user.firstName} ${modal.user.lastName}`} account access`
                                            : "Member access"}
                                    </div>

                                    <div className="roles">
                                        {userRoles.map((role, index) => (
                                            <div
                                                className="role"
                                                key={index}
                                                onClick={() =>
                                                    params.onChange({
                                                        ...params.value,
                                                        roles: [role.value],
                                                    })
                                                }
                                            >
                                                <div className={cx("radio", params.value.roles[0] == role.value && "checked")} />

                                                <div className="role-name">{role.name}</div>

                                                <div className="role-description">{role.description}</div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </VerbDialogBodyScrollbar>

                            <div className="buttons">
                                {GlobalEvent({
                                    fn: (e) => {
                                        if (e.key == "Enter" && !saving.value) {
                                            form.submit(params.value);
                                        }
                                    },
                                    eventName: "keydown",
                                })}

                                <Button
                                    btnType="secondary"
                                    onClick={() => {
                                        modal.resolve();
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button disabled={saving.value} onClick={() => form.submit(params.value)}>
                                    {modal.user.id ? "Update Access" : "Invite"}
                                </Button>
                            </div>
                        </div>
                    );
                }
            )
    );
