var content = require("!!../../../../../../../../tools/web-builder/node_modules/css-loader/dist/cjs.js??ref--5-oneOf-3-1!../../../../../../../../tools/web-builder/node_modules/resolve-url-loader/index.js??ref--5-oneOf-3-2!../../../../../../../../tools/web-builder/node_modules/sass-loader/dist/cjs.js??ref--5-oneOf-3-3!./preview-data.scss");

if (typeof content === 'string') {
  content = [[module.id, content, '']];
}

var options = {"attributes":{"verb-css":"true"}}

options.insert = function insertIntoTarget(element, options) {
                        window.verbLoadingCSS = window.verbLoadingCSS || 0;
                        window.verbStyleSheet = window.verbStyleSheet || []; // cache all verb's styles

                        window.verbLoadingCSS++;

                        setTimeout(() => {
                            const containerDom = document.querySelector("div[x-verb-dashboard]");

                            window.verbLoadingCSS--;
                            window.verbStyleSheet.push(element);

                            if (containerDom && containerDom.shadowRoot) {
                                setTimeout(() => {
                                    if (element.textContent.includes("high-charts-tooltip-config-ab3ss-s82723")) {
                                        const clone = element.cloneNode(true);
                                        document.head.append(clone);
                                    }

                                    // containerDom.shadowRoot.append(element);
                                }, 100);
                            } else {
                                document.head.append(element);
                            }
                        }, 10);
                    };
options.singleton = false;

var update = require("!../../../../../../../../tools/web-builder/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js")(content, options);

if (content.locals) {
  module.exports = content.locals;
}
