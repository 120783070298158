const defaultDateValue = () => ({
    $type: "DateFilter",
    options: "Last7Days, YearToDate, Custom",
    defaultOption: "Custom",
    compareEnabled: true,
    compareOnByDefault: true,
    customRange: {
        $type: "InTheLastOrNextCustomDateFilterRange",
        value: 90,
        interval: "Days",
        negative: false,
    },
    columns: [],
    multiSelectionOption: "SingleSelect",
});
const defaultTextValue = () => ({
    $type: "TextFilter",
    option: "FreeFormText", // SelectableList
    comparisonMethods: "Contains",
    // design: "Dropdown" "ButtonBar"
    defaultOperator: "Contains", // Equals, DoesNotEqual, StartsWith, EndsWith, Contains, DoesNotContain, IsBlank, IsNotBlank
    columns: [],
    selectableListSort: null,
    multiSelectionOption: "Any",
    // defaultValues
});
const defaultNumberValue = () => ({
    $type: "NumericFilter",
    design: "Input",
    options: "Range",
    defaultOption: "Range",
    aggregationFunc: "None",
    columns: [],
    multiSelectionOption: "Any",
});

const defaultBooleanValue = () => ({
    $type: "BooleanFilter",
    design: "Dropdown",
    defaultValue: null,
    columns: [],
    multiSelectionOption: "Any",
});

const filterDefaultValues = (type) =>
    ({
        DateFilter: defaultDateValue,
        TextFilter: defaultTextValue,
        NumericFilter: defaultNumberValue,
        BooleanFilter: defaultBooleanValue,
    }[type]());
exports.filterDefaultValues = filterDefaultValues;
