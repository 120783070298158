export const themeWarm = {
    general: {
        canvas: {
            fontFamily: "Lato",
            fontSize: "ExtraSmall",
            fontColorRGB: "#1F0D03",
            backgroundColorRGB: "#FFF8F0",
            tileSpacing: "Narrow",
        },
        tile: {
            styles: {
                titleFontSize: "ExtraSmall",
                titleFontColorRGB: "#8A634B",
                titleFontOptions: "Bold",
                titleCaseOptions: "Uppercase",
                tileBackgroundColorRGB: "#FFFFFF",
                tileBorderColorRGB: "#DEE2E7",
                tileCornerRadius: 8,
                tileBorderWidth: 0,
                tileShadow: false,
            },
            containerTiles: {
                buttonStyle: {
                    backgroundColorRGB: "#FFFFFF",
                    borderColorRGB: "#FFFFFF",
                    fontColorRGB: "#8A634B",
                    hoverBackgroundColorRGB: "#E9E7E6",
                    hoverBorderColorRGB: "#E9E7E6",
                    hoverFontColorRGB: "#ED5844",
                    activeBackgroundColorRGB: "#ED5844",
                    activeBorderColorRGB: "#ED5844",
                    activeFontColorRGB: "#FFFFFF",
                    borderWidth: 0,
                    cornerRadius: 8,
                },
                tabStyle: {
                    backgroundColorRGB: "#FFFFFF",
                    borderColorRGB: "#FFFFFF",
                    fontColorRGB: "#8A634B",
                    bottomBorderHeight: 2,
                    hoverBackgroundColorRGB: "#E9E7E6",
                    hoverBorderColorRGB: "#E9E7E6",
                    hoverFontColorRGB: "#E9E7E6",
                    activeBackgroundColorRGB: "#FFFFFF",
                    activeBorderColorRGB: "#ED5844",
                    activeFontColorRGB: "#ED5844",
                },
                dropdownStyle: {
                    backgroundColorRGB: "#FFFFFF",
                    borderColorRGB: "#D6D0CA",
                    fontColorRGB: "#8A634B",
                    borderWidth: 1,
                    cornerRadius: 32,
                },
            },
        },
        buttons: {
            primaryButton: {
                backgroundColorRGB: "#ED5844",
                fontColorRGB: "#FFFFFF",
                borderColorRGB: "#ED5844",
                hoverBackgroundColorRGB: "#FF5F49",
                hoverFontColorRGB: "#FFFFFF",
                hoverBorderColorRGB: "#FF5F49",
                cornerRadius: 4,
                borderWidth: 0,
            },
            secondaryButton: {
                backgroundColorRGB: "#FFF8F0",
                fontColorRGB: "#8A634B",
                borderColorRGB: "#D6D0CA",
                hoverBackgroundColorRGB: "#F5EEE6",
                hoverFontColorRGB: "#8A634B",
                hoverBorderColorRGB: "#8A634B",
                cornerRadius: 4,
                borderWidth: 1,
            },
            downloadButton: {
                backgroundColorRGB: "#FFF8F0",
                fontColorRGB: "#8A634B",
                borderColorRGB: "#D6D0CA",
                hoverBackgroundColorRGB: "#F5EEE6",
                hoverFontColorRGB: "#8A634B",
                hoverBorderColorRGB: "#8A634B",
                cornerRadius: 4,
                borderWidth: 1,
            },
            buttonToggleGroup: {
                backgroundColorRGB: "#FFFFFF",
                fontColorRGB: "#919EB9",
                borderColorRGB: "#DEE2E7",
                activeBackgroundColorRGB: "#E7F6FF",
                activeFontColorRGB: "#0276F2",
                activeBorderColorRGB: "#0276F2",
                cornerRadius: 4,
                borderWidth: 1,
            },
            hyperlinkButton: {
                fontColorRGB: "#ED5844",
                hoverFontColorRGB: "#FF5F49",
            },
        },
        components: {
            inputBackgroundColorRGB: "#FFFFFF",
            inputBorderColorRGB: "#D6D0CA",
            inputBorderWidth: 1,
            inputCornerRadius: 4,
            inputTextColorRGB: "#8A634B",
            inputHintTextColorRGB: "#8F8681",
            inputIconColorRGB: "#8A634B",
            inputLabelTextColorRGB: "#1F0D03",
            menuBackgroundColorRGB: "#FFFFFF",
            menuCornerRadius: 4,
            menuTextColorRGB: "#8A634B",
            menuHoverBackgroundColorRGB: "#E9E7E6",
        },
    },
    dataVisualization: {
        dataColorPalettes: {
            discreteColorsRGB: ["#ED5844", "#A87C61", "#9DB6BD", "#476A6F", "#519E8A", "#1F487E", "#247BA0", "#6D2E46"],
            colorSchemes: {
                categoricalColorSchemes: [
                    {
                        colorsRGB: ["#FAC748", "#EED2D2", "#F88DAD", "#6A994E", "#BC4749", "#E9724C"],
                    },
                    {
                        colorsRGB: ["#E9724C", "#BC4749", "#6A994E", "#F88DAD", "#EED2D2", "#FAC748"],
                    },
                    {
                        colorsRGB: ["#654F6F", "#ACBFA4", "#0B6E4F", "#C58882", "#DF928E", "#F3DFA2"],
                    },
                ],
                sequentialColorSchemes: [
                    {
                        colorsRGB: ["#ed5844", "#e9e5c6"],
                    },
                    {
                        colorsRGB: ["#8b644b", "#e4d5a2"],
                    },
                    {
                        colorsRGB: ["#6b9a4f", "#fbdc8d"],
                    },
                ],
            },
            kpiColorsRGB: ["#1F0D03", "#8A634B", "#ED5844", "#7D1128", "#00BD9D"],
            otherColors: {
                emptyFillColorRGB: "#DEE2E7",
                conditionalGoodColorRGB: "#00BD9D",
                conditionalBadColorRGB: "#7D1128",
            },
        },
        fonts: {
            fontFamily: null,
            fontColorRGB: null,
            axisFontSize: null,
            dataLabelFontSize: null,
            gridStepsFontSize: null,
        },
        toolTipsAndLegends: {
            tooltipTheme: "Dark",
            position: "Above",
            fontSize: null,
            indicatorLineShown: true,
            indicatorLineStyle: "Solid",
            indicatorLineColorRGB: "#ED5844",
            indicatorLineWidth: 1.0,
            legendFontSize: null,
            legendSymbol: "Square",
        },
        axisCharts: {
            axisLineColorRGB: "#D6D0CA",
            gridStepLineColorRGB: "#D6D0CA",
            gridStepLineStyle: "Dashed",
            dimensionAxisTickMarks: false,
            barChartCornerRadius: 12,
            barChartCornerRadiusLocation: "Top",
            areaLineChartFill: "Gradient",
        },
        gauges: {
            showBorder: false,
            borderWidth: 2,
            borderColorRGB: "#FFFFFF",
            calloutLineWidth: null,
            calloutLineColorRGB: null,
        },
        pieAndDonutCharts: {
            showBorder: false,
            borderWidth: 2,
            borderColorRGB: "#FFFFFF",
            calloutLineWidth: null,
            calloutLineColorRGB: null,
        },
        tables: {
            headerBackgroundColorRGB: "#F5EEE6",
            headerFontColorRGB: "#8A634B",
            headerFontSize: null,
            headerFontOptions: "Bold",
            headerCaseOptions: "Uppercase",
            cellFontColorRGB: "#294661",
            cellBackgroundColorRGB: "#FFFFFF",
            cellAlternateType: "AlternateRows",
            cellAltCellColorRGB: "#FFFBF7",
            totalsBackgroundColorRGB: "#FFFFFF",
            totalsFontColorRGB: "#8A634B",
            totalsFontSize: null,
            totalsFontOptions: "Bold",
            borderVerticalLines: true,
            borderHorizontalLines: false,
            borderOutsideLines: false,
            borderWidth: 1,
            borderColorRGB: "#D6D0CA",
            tables: {
                headerBackgroundColorRGB: "#F4F5F7",
                headerFontColorRGB: "#919EB0",
                headerFontSize: null,
                headerFontOptions: "Bold",
                headerCaseOptions: "Uppercase",
                cellFontColorRGB: "#294661",
                cellBackgroundColorRGB: "#FFFFFF",
                cellAlternateType: "AlternateRows",
                cellAltCellColorRGB: "#F9FBFC",
                totalsBackgroundColorRGB: "#FFFFFF",
                totalsFontColorRGB: "#294661",
                totalsFontOptions: "Bold",
                totalsFontSize: null,
                borderVerticalLines: true,
                borderHorizontalLines: true,
                borderOutsideLines: true,
                borderWidth: 1,
                borderColorRGB: "#E9EEF3",
                colorScaleSchemes: [
                    {
                        colorsRGB: ["#FF5959", "#FDED5B", "#1AC689"],
                    },
                    {
                        colorsRGB: ["#1AC689", "#FDED5B", "#FF5959"],
                    },
                    {
                        colorsRGB: ["#1AC689", "#FF5959"],
                    },
                    {
                        colorsRGB: ["#FFFFFF", "#1AC689"],
                    },
                    {
                        colorsRGB: ["#FF5959", "#FFFFFF"],
                    },
                ],
                singleColorsRGB: ["#1AC689", "#FF5959", "#FDED5B", "#0074F0", "#FF8638"],
                fontColorsRGB: ["#294661", "#000000", "#ffffff", "#1AC689", "#FF5959"],
            },
        },
        maps: {
            emptyLocationColorRGB: "#FFFFFF",
            borderColorRGB: "#E9ECEF",
        },
        sectionTile: {
            backgroundColorRGB: null,
            fontColorRGB: null,
            fontStyle: "Bold",
            caseStyle: "Uppercase",
            fontSize: null,
        },
        textTile: {
            fontColorRGB: null,
            fontSize: null,
        },
    },
};
