import {cs} from "@common/react/chain-services";
import {consumeContext, provideContext} from "@common/react/context";
import {Invoke} from "@common/react/invoke";
import {keyed} from "@common/react/keyed";
import {OnMounted} from "@common/react/on-mounted";
import {OnUnmounted} from "@common/react/on-unmounted";
import {UseState} from "@common/react/use-state";
import React from "react";
import {Button} from "../../../../../../../common/form/buttons/button/button";
import {formatDateTimeFromISOStr} from "../../../../../../../common/logic/date-time/format-date-time-full";
import {CircularProgressBar} from "../../../../common/radial-progress/circular-progress-bar";
import {ObserveProgressService} from "./observe-progress-service";
import "./publish-button.scss";

export const publishProcessProvider = ({next: rootNext, walkThrough}) =>
    cs(
        consumeContext("apis"),
        consumeContext("collection"),
        consumeContext("collectionToast"),
        ["remotePublishStatus", (_, next) => UseState({next})],
        ["confirming", (_, next) => UseState({next})],

        [
            "publishProcessObserver",
            ({apis, collectionToast: toast, collection, confirming, showTooltip, remotePublishStatus}, next) => {
                return ObserveProgressService({
                    fetch: () =>
                        apis.collection.getPublishInfo({
                            collectionId: collection.value.id,
                        }),
                    calculateProgress: (info) => info.complete / info.total,
                    checkDone: (info) => info?.status === "Success",
                    onDone: (info, {processType}) => {
                        if (processType === "new-process") {
                            confirming.onChange(true);
                            setTimeout(() => confirming.onChange(false), 500);
                            remotePublishStatus.onChange(info);
                            toast.show({
                                text: `${collection.value?.name} is published!`,
                            });
                            walkThrough?.goNext(true);
                        }

                        if (processType === "remote-process") {
                            remotePublishStatus.onChange(info);
                        }
                    },
                    checkError: (info) => info?.status === "Error",
                    onError: (info, {noToast}) => {
                        !noToast &&
                            toast.show({
                                text: `Error publishing ${collection.value?.name}`,
                                isError: true,
                            });
                        remotePublishStatus.onChange(info);
                        showTooltip?.onChange(false);
                    },
                    onApiError: () => {},
                    next,
                });
            },
        ],
        ({publishProcessObserver, remotePublishStatus, confirming}, next) =>
            provideContext(
                "publishProcess",
                {
                    publishProcessObserver,
                    remotePublishStatus,
                    confirming,
                },
                next
            ),
        ({publishProcessObserver, remotePublishStatus, confirming}) =>
            rootNext({
                publishProcessObserver,
                remotePublishStatus,
                confirming,
            })
    );

export const CollectionPublishBtn = ({walkThrough, btnType = "primary", noTooltip, beforeRun}) =>
    cs(
        consumeContext("collection"),
        consumeContext("publishProcess"),

        ["newPublish", (_, next) => UseState({next})],
        ["showTooltip", (_, next) => UseState({next})],

        ({collection, showTooltip, newPublish, publishProcess}) => {
            const {publishProcessObserver, remotePublishStatus, confirming} = publishProcess;

            const publishStatus = remotePublishStatus.value;

            const iconAndTooltip = (() => {
                if (!publishStatus) {
                    return {
                        // icon: <i className="fad fa-spinner-third fa-spin"/>,
                        icon: null,
                        tooltip: "Checking publish status",
                    };
                }

                if (publishProcessObserver.loading) {
                    return {
                        icon: CircularProgressBar({
                            progressColor: btnType == "primary" ? "#FFFFFF" : "#919eb0",
                            holeColor: btnType == "primary" ? "#11A1FD" : "#FFFFFF",
                            progress: publishProcessObserver.progress,
                            size: 16,
                        }),
                        tooltip: `Publish ${Math.round(publishProcessObserver.progress * 100)}% complete`,
                    };
                }

                const lastPublishedText = !publishStatus.publishedOn ? (
                    "Never published"
                ) : (
                    <>
                        Last published on <strong>{formatDateTimeFromISOStr(publishStatus.publishedOn, "MMM Do YYYY [at] h:mm A")}</strong>{" "}
                        {publishStatus.publishedBy ? `by ${publishStatus.publishedBy}` : ""}
                    </>
                );

                if (publishStatus.status === "Error") {
                    return {
                        icon: <i className="far fa-exclamation-circle" style={{fontSize: 16}} />,
                        tooltip: (
                            <>
                                {lastPublishedText}
                                <div style={{marginTop: 6}}>
                                    Status:{" "}
                                    {publishStatus.total == publishStatus.errors
                                        ? "Error"
                                        : `Partial Success (${Math.round(((publishStatus.total - publishStatus.errors) / publishStatus.total) * 100)}% complete)`}
                                </div>
                            </>
                        ),
                    };
                }

                return {
                    icon: confirming.value && <i className="far fa-check" style={{fontSize: 16}} />,
                    tooltip: lastPublishedText,
                };
            })();

            return (
                <>
                    <div
                        {...{
                            className: "publish-button-5hg publish-button",
                            onMouseOver: () => !noTooltip && showTooltip.onChange(true),
                            onMouseOut: () => !noTooltip && showTooltip.onChange(false),
                        }}
                    >
                        <Button
                            className="collection-publish-btn"
                            size="medium"
                            btnType={btnType}
                            disabled={!publishStatus || publishProcessObserver.loading || confirming.value}
                            iconLeft={iconAndTooltip.icon}
                            onClick={async () => {
                                if (beforeRun) {
                                    const resp = await beforeRun();
                                    if (resp) {
                                        walkThrough?.showOff();
                                        newPublish.onChange(true);
                                    }
                                } else {
                                    walkThrough?.showOff();
                                    newPublish.onChange(true);
                                }
                            }}
                        >
                            Publish
                            {(publishProcessObserver.loading || confirming.value) && publishStatus ? `ing` : ``}
                        </Button>

                        {showTooltip.value && <div className="progress-tooltip bottom">{iconAndTooltip.tooltip}</div>}
                    </div>

                    {/* check publish status or if a remote publish process is running */}
                    {publishStatus == null &&
                        Invoke({
                            fn: () =>
                                publishProcessObserver.start({
                                    args: {
                                        processType: "remote-process",
                                        noToast: true,
                                    },
                                }),
                        })}

                    {/* mount new publish process when new publish is triggered */}
                    {newPublish.value &&
                        NewPublishProcess({
                            collectionId: collection.value.id,
                            publishProcessObserver,
                            unmount: () => newPublish.onChange(false),
                        })}
                </>
            );
        }
    );

export const NewPublishProcess = ({collectionId, publishProcessObserver, unmount}) =>
    cs(consumeContext("apis"), ["putStatus", (_, next) => UseState({next})], ({apis, putStatus}) => {
        return (
            <>
                {/* when this is mounted, initiate new publish process */}
                {OnMounted({
                    action: async () => {
                        // console.log("process mounted. initiating...")
                        const putResult = await apis.collection.publishCollection({
                            collectionId,
                        });
                        putStatus.onChange(putResult);
                    },
                })}

                {/* invoke process observer. show circular progress bar first, start GET only after the PUT is done */}
                {cs(keyed(JSON.stringify(putStatus.value)), ({}) =>
                    Invoke({
                        fn: () => {
                            // putStatus.value && console.log("done initiating. now getting...");
                            publishProcessObserver.start({
                                args: {processType: "new-process"},
                                delayFetching: !putStatus.value,
                            });
                        },
                    })
                )}

                {/* unmount the process when done */}
                {putStatus.value &&
                    !publishProcessObserver.loading &&
                    Invoke({
                        fn: () => unmount(),
                    })}

                {/*{OnUnmounted({*/}
                {/*    action: () => console.log("done publishing. process unmounted.")*/}
                {/*})}*/}
            </>
        );
    });
