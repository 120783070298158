import {parseDateUTC} from "@common/utils/dates/date-object";
import {formatDateTime} from "@common/logic/date-time/format-date-time";
import {flatten1} from "@common/utils/collections";
import {chain} from "@common/utils/fs";
import {isObject} from "@common/utils/object";
import {
    actionFieldsType as _actionFieldsType,
    getErrorMessageFieldControlFilter,
} from "../../../../../web-client/src/routes/collection/tile/edit/left-panel/tabs/fields-tab/actions/add/action-fields/action-field-utils";
import {timeAggregations} from "../../../../../web-client/src/routes/collection/tile/edit/left-panel/tabs/fields-tab/chart-types/common/configure-field/configuration/date/time-aggregations";
import {keepOnly} from "@common/utils/objects";

export const actionFieldsType = _actionFieldsType;

export const getActionFieldsFromTile = (tile) =>
    (actionFieldsType[tile.$type] || []).reduce((listField, field) => (tile?.[field] ? listField.concat(tile[field]) : listField), []);

// export const allFieldsType = {
//     "VerticalBarChartTile": ["xAxisField", "groupField", "yAxisFields"],
//     "HorizontalBarChartTile": ["yAxisField", "groupField", "xAxisFields"],
//     "LineChartTile": ["xAxisField", "groupField", "yAxisFields"],
//     "ComboChartTile": ["xAxisField", "groupField", "yAxisBarFields", "yAxisLineFields"],
//     "PieChartTile": ["valueField", "groupField"],
//     "DonutChartTile": ["valueField", "groupField"],
//     "TableTile": ["actualValueFields"],
//     "SingleKPITile": ["valueField"],
//     "TableKPITile": ["groupField", "valueFields"],
//     "GaugeTile": ["valueField", "targetValueField"],
//     "FunnelChartTile": ["valueField", "groupField"],
// }

export const isDisableControlFilterAction = (tile) => {
    return ["PivotTableTile", "SingleKPITile", "GaugeTile", "MapTile"].includes(tile.$type);
};

export const createFilterVals = ({filter, ...other}) => {
    const byTypes = {
        DateFilter: genDateFilterVal,
        TextFilter: genTextFilterVal,
        NumericFilter: genNumericFilterVal,
        BooleanFilter: genBoolFilterVal,
    };

    return byTypes[filter.$type]?.({...other, filter});
};

export const getEndTime = (field, currDate) => {
    try {
        const currentDate = new Date(currDate);
        const maps = {
            Weeks: () => {
                currentDate.setUTCDate(currentDate.getUTCDate() + 6);
                return currentDate;
            },
            Months: () => {
                currentDate.setUTCMonth(currentDate.getUTCMonth() + 1);
                currentDate.setUTCDate(currentDate.getUTCDate() - 1);
                return currentDate;
            },
            Quarters: () => {
                currentDate.setUTCMonth(currentDate.getUTCMonth() + 3);
                currentDate.setUTCDate(currentDate.getUTCDate() - 1);
                return currentDate;
            },
            Years: () => {
                currentDate.setUTCFullYear(currentDate.getUTCFullYear() + 1);
                currentDate.setUTCDate(currentDate.getUTCDate() - 1);
                return currentDate;
            },
        };
        return maps[field.dateProperties.aggregation]();
    } catch (e) {
        return currDate;
    }
};

export const genDateFilterVal = ({value, filter, field}) => {
    let dateStart = value;
    let dateEnd = getEndTime(field, value);

    dateStart = parseDateUTC(dateStart);
    dateEnd = parseDateUTC(dateEnd);

    return {
        $type: "DateFilterValue",
        option: filter.options,
        range: {
            $type: "InRangeCustomDateFilterRange",
            dateStart: formatDateTime({date: dateStart, time: dateStart}),
            dateEnd: formatDateTime({date: dateEnd, time: dateEnd}), // date-end
        },
        columns: filter.columns,
        compareRange: null,
    };
};

export const genNumericFilterVal = ({value, filter}) => ({
    $type: "NumericFilterValue",
    option: filter.defaultOption,
    valueOrMin: value,
    columns: filter.columns,
});

export const genBoolFilterVal = ({value, filter}) => ({
    $type: "BooleanFilterValue",
    value: value === "True",
    columns: filter.columns,
});

export const genTextFilterVal = ({value, filter}) => ({
    $type: "TextFilterValue",
    option: filter.option,
    columns: filter.columns,
    freeFormValue: value,
    operator: "Equals",
});

export const mapFieldToValue = ({actionType, tile, field, data, series, row, noFilter = false, dimensionFormatter, fieldToValue}) => {
    const ret = (() => {
        if (noFilter) {
            return {
                value: row?.value ?? data?.[0] ?? series,
                displayText: row?.value ?? data?.[0] ?? series,
            };
        }

        if (tile.$type === "MapTile") {
            return {
                value: fieldToValue[field.id],
                displayText: fieldToValue[field.id],
            };
        }

        if (tile.$type === "TableTile" && (actionType === "OpenNewTileAction" || actionType == "ControlCollectionFiltersAction")) {
            return {
                value: fieldToValue[field.id].value,
                displayText: row.rawData.find((r) => r.tileFieldID === field.id)?.formattedValue,
            };
        }

        const maps = {
            DimensionTileField: () => ({
                value: data[0],
                displayText: dimensionFormatter?.(data[0]) ?? data[0],
            }),
            CategoryTileField: () =>
                ["DonutChartTile", "PieChartTile", "MapTile", "VennDiagramTile"].includes(tile?.$type)
                    ? {
                          value: data[0],
                          displayText: data[0],
                      }
                    : {
                          value: row?.name ?? series,
                          displayText: row?.name ?? series,
                      },
        };

        try {
            return maps[field.$type]();
        } catch (e) {
            return {
                value: series,
                displayText: series,
            };
        }
    })();

    return {
        ...ret,
        ...keepOnly(field, ["modelColumnID", "modelID", "modelTableID"]),
    };
};

export const getAllAvailableActions = ({tileActions, fieldSections, fieldName, onlyDimensionField}) => {
    let filteredFields = fieldName ? fieldSections.filter((f) => f.displayName == fieldName) : fieldSections;
    if (onlyDimensionField) {
        filteredFields = filteredFields.filter((f) => f.$type == "DimensionTileField");
    }

    return chain(
        filteredFields,
        (_) => _.map((f) => tileActions.filter((a) => a.enabledTileFieldIDs.includes(f.id)).map((a) => ({...a, field: f}))),
        (_) => flatten1(_)
    );
};

export const getFieldsHaveActions = ({tileActions, fieldSections}) => {
    return chain(fieldSections, (_) => _.filter((f) => tileActions.filter((a) => a.enabledTileFieldIDs.includes(f.id)).length > 0));
};

export const isValidTileAction = (action, {tile, collectionFilters}) => {
    if (!action) {
        return false;
    }

    const actionFields = getActionFieldsFromTile(tile?.value || tile);

    if (action.enabledTileFieldIDs.length > 0) {
        if (action.$type == "OpenNewTileAction") {
            return !!action.tile;
        }

        if (action.$type == "ControlCollectionFiltersAction") {
            if (isDisableControlFilterAction(tile?.value || tile)) {
                return false;
            }

            return isValidTileActionControlFilter({
                actionFields,
                action,
                collectionFilters,
            });
        }

        //todo check navigate and trigger

        return true;
    }

    return false;
};

export const isValidTileActionControlFilter = ({actionFields, action, collectionFilters}) => {
    for (let field of actionFields.filter((field) => action.enabledTileFieldIDs.includes(field.id))) {
        let errors = getErrorMessageFieldControlFilter(action, collectionFilters, field);
        if (errors) {
            console.log(errors);
            return false;
        }
    }

    return true;
};

export const hasNoAction = (tileActions, {tile, collectionFilters}) => {
    return (
        !tileActions ||
        tileActions.length == 0 ||
        tileActions.filter((action) =>
            isValidTileAction(action, {
                tile,
                collectionFilters,
            })
        ).length <= 0
    );
};

export const navigationActionTypes = [
    {
        label: "New Browser Tab",
        value: "NewTab",
        action: (url) => window.open(url, "_blank"),
    },
    {
        label: "Same Browser Tab",
        value: "Current",
        action: (url) => (window.location = url),
    },
    {
        label: "New Window",
        value: "NewWindow",
        action: (url) => window.open(url, "_blank", "location=yes"),
    },
];

export const parseURLTemplate = ({ops, tileActionFields = [], fieldToValue, authParams = {}}) => {
    let ret = "";
    ops.forEach((item) => {
        if (isObject(item.insert)) {
            const {type, modelColumnID, modelID, modelTableID, value} = item.insert.mention;

            const currentField = tileActionFields.find(
                (f) => f.modelColumnID == modelColumnID && f.modelTableID == modelTableID && f.modelID == modelID
            );

            if (type == "column") {
                ret += fieldToValue?.[currentField.id] ?? "";
            } else if (type == "authParameters") {
                ret += authParams?.[value.replace("User.", "")] ?? "";
            }
        } else {
            ret += item.insert;
        }
    });

    ret = ret.replaceAll(" ", "");

    if (/^(:\/\/)/.test(ret)) {
        return `http${ret}`;
    }
    if (!/^(f|ht)tps?:\/\//i.test(ret)) {
        return `http://${ret}`;
    }

    return ret;
};

export const mapFieldToColumn = (columns, fields) => {
    return columns.map((c) => ({
        ...c,
        tileFieldID: fields?.find((f) => f.modelColumnID == c.id)?.id ?? null,
    }));
};

export const getTileActionField = (action, filters, field, filterValues) => {
    if (timeAggregations.find((t) => t.value == field?.dateProperties?.aggregation)) {
        return;
    }

    const foundFilters = filters.filter((f) =>
        f.columns.find((column) => {
            return column.modelColumnID == field.modelColumnID && column.modelTableID == field.modelTableID;
        })
    );

    if (foundFilters.length > 0) {
        return {
            value: filterValues.find(
                (f) => f.modelID == field.modelID && f.modelColumnID == field.modelColumnID && f.modelTableID == field.modelTableID
            )?.value,
            field: field,
            filters: foundFilters,
        };
    }

    return null;
};
