import {Button} from "@common/form/buttons/button/button";
import {ffToTextInput} from "@common/form/ff-to-text-input";
import {TextInput} from "@common/form/text-input/text-input";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {Form2} from "@common/react/cs-form/form2";
import {required} from "@common/react/cs-form/validators/required";
import {Load2} from "@common/react/load2";
import {scope} from "@common/react/scope";
import {UseState} from "@common/react/use-state";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";
import {omit} from "@common/utils/objects";
import * as React from "react";
import {SelectFolderService} from "../../dashboard/env/collections/folder-cm/select-folder-service/select-folder-service";
import {DialogService} from "../dialog/dialog-service";

export const ConvertToSharedTileModal = ({next: rootNext, createNew = true}) =>
    cs(
        consumeContext("apis"),
        consumeContext("sharedTileFolders"),
        [
            "modal",
            (_, next) =>
                DialogService({
                    render: ({resolve, args}) => {
                        return {
                            title: "Convert to Shared TIle",
                            width: 400,
                            content: next({
                                resolve,
                                ...args,
                            }),
                        };
                    },
                    next: rootNext,
                }),
        ],
        ({modal, apis, sharedTileFolders}) =>
            cs(
                ["saving", (_, next) => UseState({next})],
                [
                    "sharedTile",
                    (_, next) =>
                        UseState({
                            initValue: {
                                adminTileName: "",
                                folderID: null,
                            },
                            next,
                        }),
                ],
                [
                    "form",
                    ({sharedTile, saving}, next) =>
                        Form2({
                            data: sharedTile,
                            fields: {
                                adminTileName: [required],
                            },
                            onSubmit: async () => {
                                saving.onChange(true);
                                const resp = await apis.collectionTiles.upsertTile({
                                    ...modal.tile,
                                    ...sharedTile.value,
                                    isShared: true,
                                    defaultRowSpan: modal.defaultRowSpan,
                                    defaultColSpan: modal.defaultColSpan,
                                });

                                await sharedTileFolders.reload();
                                saving.onChange(false);
                                modal.resolve(resp);
                            },
                            next,
                        }),
                ],
                [
                    "selectFolderService",
                    ({sharedTile}, next) =>
                        SelectFolderService({
                            apiType: "",
                            state: scope(sharedTile, ["folderID"]),
                            label: "Select a folder",
                            folders: sharedTileFolders.value,
                            next,
                        }),
                ],
                ({form, saving, selectFolderService}) => {
                    return (
                        <div className="create-shared-tile-dialog-99o">
                            <VerbDialogBodyScrollbar>
                                <div className="shared-tile-form">
                                    {TextInput({
                                        label: "Tile Admin Name",
                                        autoFocus: true,
                                        ...ffToTextInput(form.field(["adminTileName"])),
                                    })}

                                    {selectFolderService.render()}
                                </div>
                            </VerbDialogBodyScrollbar>

                            <div className="buttons">
                                <Button btnType="secondary" onClick={() => modal.resolve()}>
                                    Cancel
                                </Button>

                                <Button onClick={() => form.submit()} disabled={saving.value}>
                                    Convert
                                </Button>
                            </div>
                        </div>
                    );
                }
            )
    );
