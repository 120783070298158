const {isSameField} = require("@common/ui-components/charts/factory/is-same-field");
const getSortUpdatedWithMultipleAgg = ({tile, oriVal, allFields}) => ({
    sort: tile.value.sort
        .map((sort) => {
            if (isSameField(sort, oriVal)) {
                const currentAggs = allFields.filter((f) => f.id != oriVal.id);
                if (currentAggs.length == 0) return null;
                if (sort.aggregationFunc == oriVal.aggregationFunc) {
                    return {
                        ...sort,
                        aggregationFunc: currentAggs[0].aggregationFunc,
                    };
                }
                return sort;
            } else {
                return sort;
            }
        })
        .filter((s) => s),
});
exports.getSortUpdatedWithMultipleAgg = getSortUpdatedWithMultipleAgg;
