const {createApisMock} = require("../../apis/auth/apis-mock");
const {provideContext} = require("@common/react/context");
const {AuthApis} = require("../../apis/auth/auth-apis");
const {consumeContext} = require("@common/react/context");
const {cs} = require("@common/react/chain-services");
const clientUseMockApis = require("./client-use-mock-apis");

const loadApis = ({next, onUnauthenticated}) =>
    cs(
        consumeContext("auth"),
        [
            "apis",
            ({auth}, next) =>
                process.env.NODE_ENV === "development" && clientUseMockApis
                    ? next(createApisMock({authToken: auth.auth.authToken}))
                    : AuthApis({
                          refreshAuthToken: auth.refreshAuthToken,
                          tenantId: auth.tenantId,
                          onUnauthenticated,
                          next,
                      }),
        ],
        ({apis}) => provideContext("apis", apis, next)
    );
exports.loadApis = loadApis;
