import {createFetcher} from "../../../tools/fetcher/fetcher";
import {cs} from "@common/react/chain-services";
import {AutoRecreateStatic} from "@common/api/data-query/auto-recreate-static";
import {DataQueryFetcher2} from "@common/api/data-query/data-query-fetcher-2";
import {getChannels} from "@common/api/data-query/get-channels";
import {urlPath} from "../../../tools/fetcher/url-path";
import {CookieStorage} from "@common/logic/cookie-storage";

export const createLiveApis = ({currencyOverrides, tenantId, envId, version, apiKey, staticAuth, authParams, themeID}, next) => {
    const fetcher = createFetcher({
        headers: {
            "x-version": version,
            "x-api-key": apiKey,
            "x-referrer-url": window.location.href,
            "x-sdk-framework": window.VerbSDKFramework,
        },
    });

    const newApiKeyType = /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/gi.test(
        apiKey
    );

    staticAuth = !!staticAuth;

    return cs(
        [
            "dataQueryFetcher",
            ({}, next) =>
                AutoRecreateStatic({
                    create: () =>
                        DataQueryFetcher2({
                            configBuilder: (builder) =>
                                builder.withUrl(urlPath("/api/render/data-query"), {
                                    headers: {
                                        "x-tenant-id": tenantId,
                                        "x-env-id": envId,
                                        "x-api-key": apiKey,
                                    },
                                }),
                            channels: getChannels({
                                tenantId,
                                envId,
                                apiKey,
                                type: "render",
                            }),
                        }),
                    destroy: (v) => v.destroy(),
                    next,
                }),
        ],

        ({dataQueryFetcher}) =>
            next({
                getCollection: (collectionId) =>
                    fetcher.post(
                        !newApiKeyType
                            ? `/api/render/${tenantId}/env/${envId}/collection/${collectionId}`
                            : `/api/render/collection/${collectionId}`,
                        {
                            staticAuth,
                            authParams,
                            themeID,
                        }
                    ),

                collection: {
                    // getFilterData: ({ collectionId, filterId, columns, filterValues }) =>
                    //     fetcher.post(
                    //         !newApiKeyType
                    //             ? `/api/render/${tenantId}/env/${envId}/collection/${collectionId}/data/filter/${filterId}/text`
                    //             : `/api/render/collection/${collectionId}/data/filter/${filterId}/text`,
                    //         {
                    //             staticAuth,
                    //             authParams,
                    //             filterColumns: columns,
                    //             filterValues
                    //         })
                    // ,
                    getFilterData: async ({collectionId, filterId, columns, filterValues}) => {
                        const {$values} = await dataQueryFetcher.channelInvoke("textFilterValues", {
                            collectionId,
                            filterId,
                            filterColumns: columns,
                            filterValues,
                            staticAuth,
                            authParams,
                            ignoreServerSideCache: CookieStorage.get("verb.ignore.cache.server")?.toLowerCase() == "true",
                            ignoreWarehouseCache: CookieStorage.get("verb.ignore.cache.warehouse")?.toLowerCase() == "true",
                            // apiKey,
                        });

                        return $values;
                    },

                    // getNumericFilterData: ({ collectionId, filterId, columns, filterValues }) =>
                    //     fetcher.post(
                    //         !newApiKeyType
                    //             ? `/api/render/${tenantId}/env/${envId}/collection/${collectionId}/data/filter/${filterId}/range`
                    //             : `/api/render/collection/${collectionId}/data/filter/${filterId}/range`,
                    //         {
                    //             staticAuth,
                    //             authParams,
                    //             filterColumns: columns,
                    //             filterValues
                    //         })
                    // ,

                    getNumericFilterData: ({collectionId, filterId, columns, filterValues}) =>
                        dataQueryFetcher.channelInvoke("numericFilterRange", {
                            collectionId,
                            filterId,
                            filterColumns: columns,
                            filterValues,
                            staticAuth,
                            authParams,
                            ignoreServerSideCache: CookieStorage.get("verb.ignore.cache.server")?.toLowerCase() == "true",
                            ignoreWarehouseCache: CookieStorage.get("verb.ignore.cache.warehouse")?.toLowerCase() == "true",
                            // apiKey,
                        }),

                    // getTileData: async ({collectionId, tileId, filterValues, page= 0, tableOverrides, timeZoneId = null}) => {
                    //     const map = await fetcher.post(
                    //         !newApiKeyType
                    //             ? `/api/render/${tenantId}/env/${envId}/collection/${collectionId}/data`
                    //             : `/api/render/collection/${collectionId}/data`,
                    //         {
                    //             tiles: [{
                    //                 tileID: tileId,
                    //                 page,
                    //             }],
                    //             filterValues,
                    //             timeZoneId,
                    //             staticAuth,
                    //             authParams,
                    //             tableOverrides
                    //         });
                    //     return map[tileId];
                    // },

                    getTileData: ({collectionId, tileId, isShared, filterValues, page = 0, tableOverrides, timeZoneId = null}) =>
                        dataQueryFetcher.channelInvoke("tileData", {
                            type: "collection",
                            collectionId,
                            tileId,
                            isShared,
                            filterValues,
                            tableOverrides,
                            page,
                            timeZoneId,
                            currencyOverrides,
                            staticAuth,
                            authParams,
                            ignoreServerSideCache: CookieStorage.get("verb.ignore.cache.server")?.toLowerCase() == "true",
                            ignoreWarehouseCache: CookieStorage.get("verb.ignore.cache.warehouse")?.toLowerCase() == "true",
                            // apiKey,
                        }),

                    downloadTileData: ({colId, tileId, filterValues, format, timeZoneId = null}) =>
                        fetcher.post(
                            !newApiKeyType
                                ? `/api/render/${tenantId}/env/${envId}/collection/${colId}/data/${tileId}/download` +
                                      cQueryString({format, timeZoneId})
                                : `/api/render/collection/${colId}/data/${tileId}/download` + cQueryString({format, timeZoneId}),
                            {
                                filterValues,
                                staticAuth,
                                authParams,
                            }
                        ),
                },
            })
    );
};

const cQueryString = (queryObj) => {
    const query = Object.entries(queryObj)
        .map(([key, value]) => value && `${key}=${encodeURIComponent(value)}`) // string value only
        .filter((v) => v)
        .join("&");
    return query ? "?" + query : "";
};
