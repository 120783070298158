const {TimedAction} = require("./timed-action");
const {Static} = require("./static");

const {cs} = require("./chain-services");
const {createElement: h} = require("react");

const TimeoutAction = ({delay = 500, action}) =>
    cs(
        [
            "s",
            (_, next) =>
                h(Static, {
                    getInitValue: () => Date.now(),
                    next,
                }),
        ],
        ({s}) =>
            h(TimedAction, {
                time: s + delay,
                action,
            })
    );
exports.TimeoutAction = TimeoutAction;
