import React from "react";
import {cs} from "@common/react/chain-services";
import {cx} from "emotion";
import {isMatchText, isNotBlank, upperCase1} from "@common/utils/strings";
import {FAIconList} from "./fa-icon-list";
import "./icon-picker.scss";
import {Static2} from "@common/react/static-2";
import {Dropdown} from "@common/ui-components/dropdown/dropdown";
import {SearchInput} from "@common/form/search-input/search-input";
import {UseState} from "@common/react/use-state";
import {tooltipService3} from "../../../../../../../../../common/tooltip3/tooltip-service-3";
import {FixedSizeGrid} from "react-window";
import {chunkArrayInGroups} from "@common/utils/collections";

const generateLabelFromIcon = (value) => {
    let arr = value.split(" ");
    arr = arr[1].split("-");
    arr = arr.filter((a) => a != "fa");
    return arr.map((a) => upperCase1(a)).join(" ");
};

export const IconPicker = ({label, onChange, value, placeholder}) =>
    cs(
        [
            "renderToggle",
            ({chosenIndex, staticTooltip}, next) =>
                next(({showExpand, showingExpand}) => (
                    <div className={cx("toggle", {expanding: showingExpand})} onClick={() => showExpand(!showingExpand)}>
                        {value ? (
                            <span className="item-render">
                                <span className="icon">
                                    <i className={cx("fa", value)} />
                                </span>
                                <div className="text">{generateLabelFromIcon(value)}</div>
                            </span>
                        ) : (
                            <span className="placeholder-text">{placeholder}</span>
                        )}
                        <i className="fa fa-chevron-down" />
                    </div>
                )),
        ],
        [
            "renderExpand",
            (_, next) =>
                next(({close, width}) =>
                    cs(
                        ["keyword", (_, next) => UseState({next, initValue: ""})],
                        tooltipService3({
                            direction: "below",
                            alternateStyle: true,
                        }),
                        ({keyword, tooltip}) => {
                            return (
                                <div className="list-icons" style={{width: width}}>
                                    <div className="search-header">
                                        {SearchInput({
                                            label: "Search",
                                            state: keyword,
                                            delay: 0,
                                        })}
                                    </div>

                                    {FixedList({keyword, width, close, onChange, tooltip, value})}
                                </div>
                            );
                        }
                    )
                ),
        ],
        ["dropdownRef", (_, next) => Static2({next})],
        ({renderToggle, renderExpand, dropdownRef}) => (
            <div className={cx("dropdown-select dropdown-select-5gy icon-picker-a33", {"has-value": placeholder || value})}>
                {label && <div className="label">{label}</div>}
                {Dropdown({
                    dropdownRef,
                    renderToggle,
                    minExpandHeight: 300,
                    renderExpand,
                    detectOnWheelEvent: true,
                })}
            </div>
        )
    );

const FixedList = ({keyword, width, close, onChange, tooltip, value}) => {
    let searched =
        isNotBlank(keyword.value) && keyword.value.length >= 3
            ? FAIconList.filter((l) => isMatchText(generateLabelFromIcon(l), keyword.value))
            : FAIconList;
    const chunkSearched = chunkArrayInGroups(searched, 10);

    return (
        <FixedSizeGrid
            className="list-wrapper"
            itemData={{list: chunkSearched}}
            columnCount={10}
            columnWidth={24}
            rowHeight={24}
            width={250}
            height={150}
            rowCount={chunkSearched.length}
        >
            {({data: {list}, columnIndex, rowIndex, style}) => {
                const item = list[rowIndex][columnIndex];

                return (
                    <div
                        style={style}
                        className={cx("item", {
                            selected: value === item,
                        })}
                        {...tooltip(() => generateLabelFromIcon(item))}
                        onClick={() => {
                            onChange(item);
                            close();
                        }}
                    >
                        <i className={item} />
                    </div>
                );
            }}
        </FixedSizeGrid>
    );
};
