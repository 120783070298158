const isComparing = (rawData, tile) => {
    if (tile.style?.showCompare === false) return false;
    return rawData.series.findIndex((s) => s.isCompare) > -1;
};
exports.isComparing = isComparing;

// const isComparing = ({collectionFiltersValue, collectionFilters, localFilters}) => {
//
//     const colDateFilterColumnIds = collectionFiltersValue && Object.keys(collectionFiltersValue).map(
//         (fid) => collectionFilters?.find((cf) => cf.$type === "DateFilterValue" && cf.id === fid)?.modelColumnID
//     ).filter(v=>v);
//     const localDateFilterColumnIds = localFilters?.map((lcf) => lcf.$type === "DateFilterValue" && lcf.modelColumnID).filter(v=>v);
//
//     if (isSubset(colDateFilterColumnIds, localDateFilterColumnIds)) {
//         return false;
//     }
//
//     for (const k in collectionFiltersValue) {
//         const filter = collectionFiltersValue[k];
//         if (filter.$type === "DateFilterValue" && filter.compareRange?.$type) {
//             return true;
//         }
//     }
//
//     return false;
// };
//
// const isSubset = (col1, col2) => {// col1 is subset of col2
//     if (col1?.length > col2?.length) {
//         return false;
//     }
//
//     for (const x of col1) {
//         if (col2.indexOf(x) === -1) {
//             return false;
//         }
//     }
//
//     return true;
// };
