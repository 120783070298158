import {getPreviousDays, getPreviousDays1} from "@common/ui-components/live/live-dashboard/live-filters/filter-types/date/expand/common/time/get-previous-days";
import {getMode} from "../../ui-components/live/live-dashboard/live-filters/filter-types/date/expand/get-mode";
import {addDate} from "../../utils/dates/js-date";
import {parseDateToObject, parseDateUTCToObject} from "./format-date-time";

export const getQuarter = (date) => Math.floor(date.getUTCMonth() / 3 + 1);

export const generateDateRangeMatchWithAggregation = (date, aggregation) => {
    const ranges = {
        Years: (date) => {
            const year = date.getUTCFullYear();
            return {
                from: new Date(year, 0, 1),
                to: addDate(new Date(year, 11, 31), 1),
            };
        },
        Quarters: (date) => {
            const year = date.getUTCFullYear();
            const quarter = getQuarter(addDate(date, 1));
            return {
                from: new Date(year, quarter * 3 - 3, 1),
                to: addDate(new Date(year, quarter * 3, 0), 1),
            };
        },
        Months: (date) => {
            const year = date.getUTCFullYear();
            const month = addDate(date, 1).getUTCMonth();
            return {
                from: new Date(year, month, 1),
                to: addDate(new Date(year, month + 1, 0), 1),
            };
        },
        Weeks: (date) => {
            const first = date.getUTCDate() - date.getUTCDay();
            const last = first + 6;

            return {
                from: new Date(date.setDate(first)),
                to: addDate(new Date(date.setDate(last)), 1),
            };
        },
        Days: (date) => {
            let day = date.getUTCDate();
            let month = date.getUTCMonth();
            let year = date.getUTCFullYear();

            return {
                from: new Date(year, month, day),
                to: addDate(new Date(year, month, day), 1),
            };
        },
    };

    const {from, to} = ranges[aggregation](date);

    return {
        from: parseDateToObject(from),
        to: parseDateToObject(to),
    };
};

export const mainToComparing = (mainRange, defaultOption = "Custom") => {
    const mode = getMode(mainRange, defaultOption);

    if (mode) {
        return mode.getComparingRange();
    } else {
        return mode?.getComparingRange?.() ?? getPreviousDays1(mainRange);
    }
};

export const plusDate = (date, number) => {
    const d = new Date(date);
    d.setHours(0, 0, 0, 0);
    d.setDate(d.getDate() + number);
    return d;
};

export const generateDefaultTimeRange = (value) => {
    if (!value) return value;

    const {from, to} = value;

    return {
        from: {
            ...from,
            hours: 0,
            minutes: 0,
            seconds: 0,
        },
        to: {
            ...to,
            hours: 23,
            minutes: 59,
            seconds: 59,
        },
    };
};
