import * as React from "react";
import {cs} from "@common/react/chain-services";
import "./in-range.scss";
import {addDate} from "@common/utils/dates/date-object";
import {parseDateTime} from "../../../../../../../../../../../../common/logic/date-time/parse-date-time";
import {formatDateTime} from "../../../../../../../../../../../../common/logic/date-time/format-date-time";
import {DateRangePicker} from "./date-range-picker/date-range-picker";

export const InRange = ({form, filter}) =>
    cs(({}) => (
        <div className="in-range-11a edit-custom-range full-line">
            {DateRangePicker({
                ...formToDateRangePicker(form, {
                    from: "dateStart",
                    to: "dateEnd",
                }),
                filter
            })}
        </div>
    ));

const formToDateRangePicker = (form, {from, to}) => {
    const fieldFrom = form.field(from);
    const fieldTo = form.field(to);
    return {
        state: {
            value: {
                from: parseDateTime(fieldFrom.state.value)?.date,
                to: addDate(parseDateTime(fieldTo.state.value)?.date, 1), // date-end
            },
            onChange: (r) =>
                form.data.change((data) => ({
                    ...data,
                    [from]: formatDateTime({date: r?.from}),
                    [to]: formatDateTime({date: addDate(r?.to, -1)}), // date-end
                })),
        },
        domRef: (dom) => {
            fieldFrom.domRef?.(dom);
            fieldTo.domRef?.(dom);
        },
        hasError: fieldFrom.error || fieldTo.error,
    };
};
