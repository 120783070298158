import {numColumnsOfGrid} from "../grid-constants";
import {max} from "../../../../../../../common/utils/math-util";
import {getELoc} from "@common/react/get-e-loc";

import {conflictRect} from "../dnd-common";

export const getOverlapTile = ({movingTile, tiles}) => {
    for (let tile of tiles) {
        if (isOverlapTile({source: movingTile, target: tile}) || isOverlapTile({source: tile, target: movingTile})) {
            return tile;
        }
    }

    return null;
};

const tryMove = ({moveFunc, max, tiles}) => {
    let newPos = moveFunc(0);
    for (let i = 1; i <= max; i++) {
        newPos = moveFunc(i);
        if (conflictRect({rect: newPos, tiles}) || newPos.x + newPos.width > numColumnsOfGrid || newPos.x < 0 || newPos.y < 0) {
            return {
                rect: moveFunc(i - 1),
                space: i - 1,
            };
        }
    }

    return {
        rect: newPos,
        space: max,
    };
};

export const shiftTile = ({rect, tile, tiles}) => {
    const {x, y} = tile.position;
    let width = rect.width + rect.x - x;
    let height = rect.height + rect.y - y;

    let shiftLeft = tryMove({
        moveFunc: (index) => ({x: x - index, y, ...tile.size}),
        max: width,
        tiles,
    });
    let shiftRight = tryMove({
        moveFunc: (index) => ({x: x + index, y, ...tile.size}),
        max: width,
        tiles,
    });
    let shiftTop = tryMove({
        moveFunc: (index) => ({x, y: y - index, ...tile.size}),
        max: height,
        tiles,
    });
    let shiftBottom = tryMove({
        moveFunc: (index) => ({x, y: y + index, ...tile.size}),
        max: height,
        tiles,
    });

    let maxRectLeftRight = max([shiftLeft, shiftRight], (s) => s.space);
    if (maxRectLeftRight.space == width) {
        return maxRectLeftRight.rect;
    }

    let maxRect = max([shiftLeft, shiftRight, shiftTop, shiftBottom], (s) => s.space);
    if (maxRect.space > 0) {
        return maxRect.rect;
    }

    return null;
};

const isOverlapTile = ({source, target}) =>
    isInRange({
        from: target.position.x,
        value: source.position.x + source.size.width - 1,
        to: target.position.x + target.size.width - 1,
    }) &&
    isInRange({
        from: target.position.y,
        value: source.position.y + source.size.height - 1,
        to: target.position.y + target.size.height - 1,
    }) &&
    isInRange({
        from: target.position.x,
        value: source.position.x,
        to: target.position.x + target.size.width - 1,
    }) &&
    isInRange({
        from: target.position.y,
        value: source.position.y,
        to: target.position.y + target.size.height - 1,
    });

const isInRange = ({from, to, value}) => from <= value && value <= to;

export const autoScrollCanvas = ({container, pos1, pos0, tileSize, blockSize, gridHeight, spacing, e, autoScroll}) => {
    const {bottom, top, left, right} = container.getBoundingClientRect();
    const delta = {x: pos1.x - pos0.x, y: pos1.y - pos0.y};

    const {scrollTop, scrollLeft, scrollHeight, scrollWidth, offsetHeight, offsetWidth} = container.getDom();

    const movePosition = ({x, y}) => {
        let ret = {};
        const _x = x - delta.x;
        const _y = y - delta.y;

        if (_x > right - tileSize.width * blockSize) {
            ret.right = true;
        }

        if (_x < left + 20) {
            ret.left = true;
        }

        if (_y < top + 20) {
            ret.top = true;
        }

        if (_y > bottom - 120) {
            ret.bottom = true;
        }

        return ret;
    };

    const isStopAutoScroll = ({x, y}) => {
        const movePos = movePosition({x, y});
        return (
            !movePos ||
            (movePos.bottom && scrollTop === scrollHeight - offsetHeight) ||
            (movePos.top && scrollTop <= 0) ||
            (movePos.right && scrollLeft === scrollWidth - offsetWidth) ||
            (movePos.left && scrollLeft <= 0)
        );
    };

    autoScroll.move(getELoc(e), isStopAutoScroll, () => {
        const {x, y} = getELoc(e);

        if (movePosition({x, y}).left) {
            container.autoScrollingLeft(-3);
        }

        if (movePosition({x, y}).right) {
            container.autoScrollingLeft(3);
        }

        if (movePosition({x, y}).top) {
            container.autoScrollingTop(-3);
        }

        if (movePosition({x, y}).bottom) {
            container.autoScrollingTop(3);
        }
    });
};
