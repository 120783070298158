import React from "react";
import {cs} from "../../../../react/chain-services";
import {css, cx} from "emotion";
import {getScrollbarStyleLegend} from "../../render-chart/chart-scrolling-style";
import "./funnel-legend-display.scss";
import {reverse} from "../../../../utils/collections";
import {getTextDimension} from "../../single-kpi/font-auto-resizing";
import {RevalidateChartService} from "../../render-chart/legend/display/revalidate-chart-service";
import {StyledClass} from "@common/react/styled-class";

export const FunnelLegendDisplay = (props) => {
    const {chartRef, legendRoom, tile, options, theme, isCompare, formatters} = props;

    if (!tile.style.legendStyle.legendShown) {
        return null;
    }

    return cs(
        [
            "chart",
            (_, next) =>
                RevalidateChartService({
                    props: {tile, theme},
                    chartRef,
                    next,
                }),
        ],
        [
            "scrollBarClass",
            ({}, next) =>
                StyledClass({
                    content: getScrollbarStyleLegend(theme),
                    next,
                }),
        ],
        ({chart, scrollBarClass}) => {
            const showCategory = tile.style.dataLabels.categoryShown;
            const showValue = tile.style.dataLabels.metrics?.includes("Value");
            const showPercent = tile.style.dataLabels.metrics?.includes("Percent");

            const stages = tile.style.displayType === "Pyramid" ? reverse(chart.series[0].data) : chart.series[0].data;
            const labelHeight = getTextDimension(options.legend.itemStyle.fontSize, "a").height;

            return (
                <div
                    className={cx(
                        "legend-wrapper verb-tile-legend", // ref. chart-legend-style.scss for legend-wrapper style
                        "funnel-legend-display-6rd",
                        {
                            "percent-of-previous": tile.style.dataLabels.comparisonType === "PercentOfPrevious",
                        },
                        scrollBarClass,
                        `vertical-scroll verb-tile-legend-${legendRoom.paddingLeft ? "left" : "right"}`
                    )}
                    style={{
                        left: legendRoom.paddingLeft ? 0 : "",
                        right: legendRoom.paddingRight ? 0 : "",
                        top: legendRoom.paddingBottom ? "" : 0,
                        bottom: legendRoom.paddingBottom ? "0" : "",
                        width: legendRoom.paddingRight || legendRoom.paddingLeft,
                    }}
                >
                    <div className="legend-container">
                        {stages.map((stage, i) => {
                            return (
                                <div
                                    className="legend-item"
                                    key={i}
                                    style={{
                                        cursor: "default",
                                        color: `${theme.general.canvas.fontColorRGB}`,
                                        fontSize: `${options.legend.itemStyle.fontSize}px`,
                                    }}
                                    // onClick={() => stage.setVisible(!stage.visible)}
                                >
                                    <div className="icon-label">
                                        <div
                                            className="icon"
                                            style={{
                                                background: stage.color,
                                                borderRadius:
                                                    theme.dataVisualization.toolTipsAndLegends.legendSymbol === "Circle" ? "50%" : "",
                                                ...(theme.dataVisualization.toolTipsAndLegends.legendFontSize === "ExtraLarge" && {
                                                    top: 4,
                                                }),
                                            }}
                                        />
                                        {showCategory ? (
                                            <div
                                                className="label"
                                                style={{
                                                    ...((stage.name == null || stage.name.replace?.(/ /g, "") === "") && {
                                                        height: labelHeight,
                                                    }),
                                                }}
                                            >
                                                {stage.name}
                                            </div>
                                        ) : (
                                            <div className="label" style={{height: labelHeight}} />
                                        )}
                                    </div>

                                    {tile.style.dataLabels.comparisonType === "PercentOfTotal" ? (
                                        <div className="values">
                                            <div className="values-group">
                                                {showValue && (
                                                    <>
                                                        {formatters.valueFormatter(stage.value)}
                                                        &nbsp;
                                                    </>
                                                )}
                                                {showPercent &&
                                                    (showValue
                                                        ? `(${formatters.percentFormatter(stage.percentOfTotal)})`
                                                        : formatters.percentFormatter(stage.percentOfTotal))}
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="values">
                                            <div className="values-group">
                                                {showValue && (
                                                    <span
                                                        style={{
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        {formatters.valueFormatter(stage.value)}
                                                    </span>
                                                )}

                                                {showPercent && (
                                                    <div
                                                        className="percent-of-previous"
                                                        style={{
                                                            fontSize: `${options.legend.itemStyle.fontSize - 1}px`,
                                                        }}
                                                    >
                                                        {i === 0
                                                            ? "-"
                                                            : `${formatters.percentFormatter(stage.percentOfPrevious)} of ${
                                                                  stages[i - 1].name
                                                              }`}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        }
    );
};
