import React from "react";
import {cs} from "@common/react/chain-services";
import {Expandable} from "../../../../common/expandable/expandable";
import {TextInput} from "../../../../../../../common/form/text-input/text-input";
import {consumeContext} from "@common/react/context";
import {createAutoSaveCollection} from "../../../../collection/edit/common/auto-save-collection";
import {Form2} from "@common/react/cs-form/form2";
import {required} from "@common/react/cs-form/validators/required";
import {UseState} from "@common/react/use-state";
import {ffToBasicInput} from "../../../../../../../common/form/ff-to-basic-input";
import {Invoke} from "@common/react/invoke";
import {keyed} from "@common/react/keyed";

export const ApiCollectionTab = ({next, savingQueue}) =>
    cs(consumeContext("collection"), ({collection}) => {
        return next({
            renderLeftPanel: () => (
                <Expandable
                    {...{
                        label: "Collection Settings",
                        render: () => (
                            <div className="collection-settings-35g">
                                <div className="line">
                                    {CollectionName({
                                        collection: createAutoSaveCollection({
                                            collection,
                                            savingQueue,
                                        }),
                                    })}
                                </div>
                            </div>
                        ),
                    }}
                />
            ),
        });
    });

export const CollectionName = ({collection}) =>
    cs(
        consumeContext("apis"),
        [
            "localName",
            (_, next) =>
                UseState({
                    initValue: {
                        value: null,
                        lastUpdatedAt: null,
                    },
                    next: ({onChange, value}) =>
                        next({
                            value: {
                                ...value,
                                value: value.value ? value.value : collection.value,
                            },
                            flush: () => onChange({}),
                            onChange: ({value, lastUpdatedAt}) => {
                                onChange({
                                    value,
                                    lastUpdatedAt,
                                });
                            },
                        }),
                }),
        ],
        [
            "form",
            ({localName, tenant, saving, apis, auth}, next) =>
                Form2({
                    fields: {
                        name: {
                            validators: [required],
                            asyncValidators: [
                                {
                                    validate: (name) => (collection.value.name == name ? Promise.resolve(true) : apis.collection.checkCollectionName(name)),
                                    getMessage: () => `Existed`,
                                },
                            ],
                        },
                    },
                    data: {
                        value: localName.value.value,
                        onChange: (v) => {
                            localName.onChange({
                                value: v,
                                lastUpdatedAt: Date.now(),
                            });
                        },
                    },
                    next,
                }),
        ],
        ({localName, tilePath, form}, next) =>
            cs(["lastSave", (_, next) => UseState({next})], ({lastSave}) => (
                <>
                    {next()}
                    {localName.value.lastUpdatedAt &&
                        (!lastSave.value || lastSave.value < localName.value.lastUpdatedAt) &&
                        form.valid &&
                        cs(keyed(localName.value.lastUpdatedAt), () =>
                            Invoke({
                                fn: async ({isMounted}) => {
                                    collection.change(() => localName.value.value);
                                    if (isMounted()) {
                                        localName.flush();
                                        lastSave.onChange(localName.value.lastUpdatedAt);
                                    }
                                },
                            })
                        )}
                </>
            )),
        ({form}) =>
            TextInput({
                label: "Name",
                ...ffToBasicInput(form.field("name")),
            })
    );
