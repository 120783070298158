import * as React from "react";
import { DropdownSelect } from "../../../../../../../../../../../../../common/ui-components/dropdown-select/dropdown-select";
import { ffToDropdown } from "../../../../../../../../../../../../../common/form/ff-to-dropdown";
import "./whiskers.scss";
import {LpLine} from "../../../../../../../../../../common/left-panel/line/lp-line";
import {scope} from "../../../../../../../../../../../../../common/react/scope";
import { ShowHideToggle } from "../../../../../../../../../../../../../common/form/toggles/show-hide-toggle";

export const Whiskers = ({ tile, form }) => ({
    label: "Whiskers Options",
    render: () => (
        <div className="whiskers-99o">
            {/*{DropdownSelect({*/}
            {/*    list: ["Interquartile range", "Min/Max"],*/}
            {/*    label: "Whisker Length",*/}
            {/*    valueToLabel: (v) => v,*/}
            {/*    ...ffToDropdown(form.field("style.whiskers"))*/}
            {/*})}*/}

            {LpLine({
                label: "Outliers",
                control: ShowHideToggle({state: scope(tile, ["includeOutliers"])}),
            })}

            {LpLine({
                label: "End Cap Line",
                control: ShowHideToggle({state: scope(tile, ["style", "isEndCapLineEnabled"])}),
            })}
        </div>
    )
})
