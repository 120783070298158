export const booleanFilterControlTypes = [
    {
        label: "Dropdown",
        name: "Dropdown",
    },
    {
        label: "Button Toggles",
        name: "ButtonToggle",
    },
    {
        label: "Radio Button",
        name: "RadioButton",
    },
];
