import {cs} from "@common/react/chain-services";
import {createArray} from "@common/utils/collections";
import {DataTable} from "../../../common/data-table/data-table";
import {SkeletonBox} from "../../../common/skeleton/skeleton-box";

const list = createArray(5).map(() => ({id: Date.now()}));

export const FlatFileSkeletons = ({}) =>
    cs(() =>
        DataTable({
            list,
            className: "edit-flat-file-table-99p sync-status-table-88e",
            columns: [
                {
                    format: () => SkeletonBox({style: {width: 52, height: 16}}),
                },
                {
                    label: "File",
                    format: () => SkeletonBox({style: {width: 52, height: 16}}),
                },
                {
                    label: "Data",
                    className: "table-row-99e",
                    format: () => SkeletonBox({style: {width: `80%`, height: 30}}),
                },
                {
                    label: "Records",
                    format: () => SkeletonBox({style: {width: 85, height: 16}}),
                },
                {
                    format: () => SkeletonBox({style: {width: 52, height: 16}}),
                },
            ],
        })
    );
