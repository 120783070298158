const {chain} = require("../../../../utils/fs");
const {last} = require("../../../../utils/collections");

const arrangeToLines = (rects, {colCount}) => {
    let ret = [];
    for (const rect of rects) {
        ret.push(
            (() => {
                if (ret.length === 0) {
                    return {...rect, x: 0, y: 0};
                } else {
                    const blankRect = getBlankRect(ret, {colCount});

                    if (rect.width <= blankRect.width) {
                        return {
                            x: blankRect.x,
                            y: blankRect.y,
                            width: rect.width,
                            height: rect.height,
                        };
                    } else {
                        return {
                            x: 0,
                            y: blankRect.y + blankRect.height,
                            width: Math.min(rect.width, colCount),
                            height: rect.height,
                        };
                    }
                }
            })()
        );
    }
    return ret;
};
exports.arrangeToLines = arrangeToLines;

const getBlankRect = (rects, {colCount}) => {
    const toY = Math.max(...rects.map((r) => r.y + r.height));

    return chain(last(rects), (_) => {
        const x = _.x + _.width;
        const y = _.y;
        return {
            x,
            y,
            width: colCount - x,
            height: toY - y,
        };
    });
};
