import * as React from "react";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {spc} from "@common/react/state-path-change";
import {consumeContext} from "@common/react/context";

import {chain} from "@common/utils/fs";
import {Button} from "@common/form/buttons/button/button";

import {getDSColorBasedOnIndex} from "../../common/data-source-colors";
import {Expandable} from "../../../../../common/expandable/expandable";
import {UsageStatusIndicator} from "../../common/usage-status-indicator";

import {TableColumns} from "./table-columns";
import {TableLabelSection} from "./table-label-section";
import {MoreActionPopup} from "./more-action/more-action-popup";
import {EllipsisText} from "@common/ui-components/text/text";
import {IgnoreUpdateModelPositions} from "./common";
import {equalDeep} from "@common/utils/objects";

export const getModelTableFromDsTable = ({ds, dsTable}) => ({
    $type: "DataSourceModelTable",
    name: dsTable.name,
    dataSourceID: ds.id,
    dataSourceTableID: dsTable.id,
    position: dsTable.position,
    columns: dsTable.columns.map((c) => ({
        $type: "DataSourceModelColumn",
        dataSourceID: ds.id,
        dataSourceTableID: dsTable.id,
        dataSourceColumnID: c.id,
        name: c.name,
        dataType: c.dataType,
    })),
});

const getTogglePosition = (toggleElem) => {
    const parentElem = toggleElem.closest(".ds-tables");
    const {height, top: toggleTopOffset} = toggleElem.getBoundingClientRect();
    const parentElemTopOffset = parentElem.getBoundingClientRect().top;
    return toggleTopOffset - parentElemTopOffset + height;
};

export const DataSourceItem = React.memo(
    ({
        dataSource,
        dataSources,
        interactionsData,
        model,
        chart,
        searchValue,
        removeTableService,
        shortestPossiblePathDialog,
        toggleSelectedTable = () => null,
    }) => {
        // console.log("re-render", dataSource.name);

        return cs(
            consumeContext("dataSourceModelContext"),
            ({dataSourceModelContext}, next) => {
                return IgnoreUpdateModelPositions({
                    dataSourceModelContext,
                    extraProps: {interactionsData},
                    next,
                });
            },
            ["openPopupOption", (_, next) => UseState({next})],
            ({openPopupOption, dataSourceModelContext}) => {
                const {getDataSourceUsage, getDataSourceTableUsage, getRelationshipUsage, getEntities, entities} = dataSourceModelContext;

                const dsStatus = getDataSourceUsage(dataSource);
                const color = dataSource.colorRGB || getDSColorBasedOnIndex(dataSource.dsIndex);
                const dsTables = dataSource.tables.map((tableID) => entities.tables[tableID]);

                const _matchedDataSourceColumns = getEntities("columns", {
                    isSearchResults: true,
                    filterFn: (t) => t.dataSourceID == dataSource.id,
                });

                const onlyShowMatchedItems = !!searchValue && _matchedDataSourceColumns.length == 1;

                const searchedTables = getEntities("tables", {
                    isSearchResults: onlyShowMatchedItems,
                    filterFn: (t) => t.dataSourceID == dataSource.id,
                });

                const relatedModelTables = getEntities("modelTables", {
                    isSearchResults: onlyShowMatchedItems,
                    filterFn: (t) => t.dataSourceID === dataSource.id,
                });

                const {dataSourceTableID, dataSourceID} = interactionsData?.data ?? {};
                const {table, top, currentToggle} = openPopupOption.value ?? {};

                return Expandable({
                    initExpand: dataSources.length === 1,
                    passiveExpand: !!searchValue && (dataSource.ranges?.length > 0 || _matchedDataSourceColumns.length > 0),
                    style: {
                        zIndex: openPopupOption.value ? 12 : "",
                    },
                    updateState:
                        !interactionsData || !dataSourceTableID || !dataSourceID
                            ? null
                            : {
                                  value: dsTables.find((table) => dataSource.id == dataSourceID && table.id == dataSourceTableID),
                                  // timestamp: interactions.setting.timestamp,
                              },
                    label: (
                        <div className="data-source-label">
                            <UsageStatusIndicator status={dsStatus} color={color} />
                            <EllipsisText
                                text={dataSource.visualName ?? dataSource.name}
                                highlights={dataSource.ranges}
                                highlightColor={color}
                            />
                        </div>
                    ),
                    level: 1,
                    noTopBorder: true,
                    control: dsStatus !== "all" && (
                        <Button
                            size="tiny"
                            btnType="border"
                            className="action-btn"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                const modelDsTableIds = relatedModelTables.map((t) => t.dataSourceTableID);

                                const newTables = chain(
                                    dsTables,
                                    (tables) => (!modelDsTableIds?.length ? tables : tables.filter((t) => !modelDsTableIds.includes(t.id))),
                                    (tables) =>
                                        tables.map((dsTable) =>
                                            getModelTableFromDsTable({
                                                ds: dataSource,
                                                dsTable: {
                                                    ...dsTable,
                                                    columns: dsTable.columns.map((columnID) => entities.columns[columnID]),
                                                },
                                            })
                                        )
                                );

                                spc(model, ["tables"], (tables) => [...(tables || []), ...newTables]);
                            }}
                        >
                            Add All
                        </Button>
                    ),
                    // render: () => null,
                    render: () => {
                        return (
                            <div className="ds-tables">
                                {table &&
                                    MoreActionPopup({
                                        openPopupOption,
                                        dataSource,
                                        model,
                                        table,
                                        top,
                                        removeTableService,
                                        shortestPossiblePathDialog,
                                        currentToggle,
                                    })}
                                {searchedTables.map((table) => {
                                    const status = getDataSourceTableUsage({
                                        dsTableId: table.id,
                                        dsID: dataSource.id,
                                    });

                                    const modelTable = relatedModelTables.filter((t) => t.dataSourceTableID === table.id)?.[0];

                                    const searchedColumns = getEntities("columns", {
                                        isSearchResults: onlyShowMatchedItems,
                                        filterFn: (c) =>
                                            (c.dataSourceTableID === table.id &&
                                                c.dataSourceID == dataSource.id &&
                                                c.$type != "DataSourceModelColumn") ||
                                            c.parent == modelTable?._id,
                                    });

                                    const matchedColumns = searchedColumns.filter((c) => c.ranges?.length > 0);
                                    const isOpenPopupMenu = openPopupOption.value?.table?.id == table.id;

                                    const resolvedTable = {
                                        ...table,
                                        columns: table.columns.map((columnID) => entities.columns[columnID]),
                                    };

                                    const resolvedModelTable = modelTable
                                        ? {
                                              ...modelTable,
                                              columns: modelTable.columns.map((columnID) => entities.columns[columnID]),
                                          }
                                        : null;

                                    const control =
                                        status !== "none" ? (
                                            <button
                                                className="action-btn more-option"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    if (openPopupOption.value) {
                                                        openPopupOption.onChange(null);
                                                    } else {
                                                        const currentToggle = e.target.closest("button");
                                                        openPopupOption.onChange({
                                                            table: resolvedTable,
                                                            top: getTogglePosition(currentToggle),
                                                            currentToggle,
                                                        });
                                                    }
                                                }}
                                            >
                                                <img src={require("../../common/icons/more-option.svg")} alt="" />
                                            </button>
                                        ) : (
                                            <Button
                                                btnType="border"
                                                size="tiny"
                                                className="action-btn"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();

                                                    const newTable = getModelTableFromDsTable({
                                                        ds: dataSource,
                                                        dsTable: resolvedTable,
                                                    });

                                                    spc(model, ["tables"], (tables) => [...(tables || []), newTable]);
                                                }}
                                            >
                                                Add
                                            </Button>
                                        );

                                    const label = TableLabelSection({
                                        status,
                                        modelTable: resolvedModelTable,
                                        table: resolvedTable,
                                        ds: dataSource,
                                        chart,
                                        color,
                                    });

                                    const customClick =
                                        resolvedModelTable && !searchValue
                                            ? (isExpanded) => {
                                                  toggleSelectedTable(isExpanded, resolvedModelTable);
                                              }
                                            : null;

                                    const props = {
                                        style: {
                                            zIndex: isOpenPopupMenu ? 12 : "",
                                        },
                                        dataID: `${table.dataSourceTableID ?? table.id}_${table.dataSourceID}`,
                                        initExpand: false,
                                        customClick,
                                        className: {
                                            "active-popup": isOpenPopupMenu,
                                        },
                                        updateState:
                                            table.dataSourceID == dataSourceID && table.id == dataSourceTableID
                                                ? {
                                                      value: true,
                                                      // timestamp: interactions.setting?.timestamp,
                                                  }
                                                : null,
                                        label,
                                        control,
                                        level: 2,
                                        noTopBorder: true,
                                        passiveExpand:
                                            !!searchValue &&
                                            ((table.ranges?.length > 0 && matchedColumns?.length > 1) || onlyShowMatchedItems),
                                        render: () =>
                                            TableColumns({
                                                model,
                                                table: resolvedTable,
                                                modelTable: resolvedModelTable,
                                                searchedColumns,
                                                color,
                                                getRelationshipUsage,
                                            }),
                                    };

                                    return <Expandable key={table.id} {...props} />;
                                })}
                            </div>
                        );
                    },
                });
            }
        );
    },
    (prevProps, nextProps) => {
        if (!equalDeep(prevProps.interactionsData, nextProps.interactionsData)) {
            return false;
        }

        if (prevProps.searchValue != nextProps.searchValue) {
            return false;
        }

        return true;
    }
);
