import React from "react";
import {cs} from "@common/react/chain-services";
import "./inputs-menu.scss";
import {inputStepTypes} from "../../../../common/input-step-types";
import {keyed} from "@common/react/keyed";
import {DragWatcher} from "../../../../../common/drag-watcher";
import {getELoc} from "@common/react/get-e-loc";
import {cx} from "emotion";
import {isAggregatedMeasure} from "../../../../common/transformation-utils";

export const InputsMenu = ({transformation, startDraggingNewStep}) =>
    cs(({}) => (
        <div
            className={cx("inputs-menu-46j", {
                disabled: isAggregatedMeasure(transformation.value),
            })}
        >
            {inputStepTypes
                .filter((s) => s.$type !== "AggregatedModelTableDatasetStep")
                .map((step, i) =>
                    cs(
                        keyed(i),
                        [
                            "dragWatcher",
                            (_, next) =>
                                DragWatcher({
                                    onDragged: (e) => {
                                        startDraggingNewStep({
                                            ...step,
                                            position: getELoc(e),
                                        });
                                    },
                                    next,
                                }),
                        ],
                        ({dragWatcher}) => (
                            <div className="input-step" onMouseDown={dragWatcher.onMouseDown}>
                                <img src={step.iconSrc} alt="" draggable={false} />
                                <div className="label">{step.name}</div>
                            </div>
                        )
                    )
                )}
        </div>
    ));
