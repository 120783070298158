import {omit} from "@common/utils/objects";

const {last} = require("../../../../../../common/utils/collections");
import {isBlank, isMatchText} from "@common/utils/strings";

export const groupItemsDefault = (cols, searchKeyword, filterFn) => {
    let ret = [];
    for (let col of cols) {
        let fn = (col, ret) => {
            ret.push(col);
        };

        if (isBlank(searchKeyword)) {
            fn(col, ret);
        } else {
            if (isMatchText(col.name ?? "", searchKeyword) || filterFn?.(col, searchKeyword)) {
                fn(col, ret);
            }
        }

        // if(isBlank(searchKeyword) || isMatchText(col.name, searchKeyword)) {
        //     fn(col, ret);
        // }
    }
    return ret;
};

export let resolveItemKey = (item) => {
    try {
        const baseKey = `${item.type}_${item.id}`;
        if (item.type === "ApiCollection") {
            return baseKey + `_isChild:${!!item.isChild}`;
        }
        return baseKey;
    } catch (e) {
        throw e;
    }
};

export const groupCollections = (collections, searchKeyword, filterFn) => {
    let ret = [];
    for (let col of collections) {
        let fn = (col, ret) => {
            if (col.type === "ApiCollection") {
                let found = ret.find((r) => r.name == col.name);
                if (found) {
                    found.numChildren++;
                    const _parent = omit(found, ["childs"]);

                    found.childs = found.childs
                        .map((c) => ({...c, parent: _parent}))
                        .concat({
                            ...col,
                            type: "ApiCollection",
                            isChild: true,
                            parent: _parent,
                        });
                } else {
                    const parent = {
                        ...col,
                        childs: [],
                        numChildren: 0,
                    };

                    parent.numChildren++;
                    parent.childs.push({...col, parent: omit(parent, ["childs"]), type: "ApiCollection", isChild: true});
                    ret.push(parent);
                }
            } else {
                // AB#6372 hide Contextual Tile
                if (col.type != "SparkCollection") {
                    ret.push(col);
                }
            }
        };

        // if(isBlank(searchKeyword) || isMatchText(col.name, searchKeyword)) {
        //     fn(col, ret);
        // }

        if (isBlank(searchKeyword)) {
            fn(col, ret);
        } else {
            if (isMatchText(col.name, searchKeyword) || filterFn?.(col, searchKeyword)) {
                fn(col, ret);
            }
        }
    }
    return ret;
};

export const getFoldersTreeStructure = ({folders, searchKeyword}) => {
    return {
        getList: (path) => {
            const parent = last(path);

            return parent == null
                ? [...folders.filter((fo) => fo.id && fo.parentFolderID == null), ...groupCollections(folders.find((fo) => fo.id == null).collections, searchKeyword)]
                : [
                      ...folders.filter((fo) => fo.id && fo.parentFolderID === parent.id),
                      ...groupCollections(
                          parent.collections.map((col) => ({
                              ...col,
                              folderID: parent.id,
                          })),
                          searchKeyword
                      ),
                  ];
        },
        isGroupNode: (n) => n.collections,
        isAlwaysExpanded: (n) => n.type === "api" || !isBlank(searchKeyword),
    };
};

export const getFoldersTreeStructureWithoutType = ({folders, searchKeyword, type}) => {
    const groupItems = (cols, searchKeyword) => {
        let ret = [];
        for (let col of cols) {
            let fn = (col, ret) => {
                ret.push(col);
            };

            if (isBlank(searchKeyword)) {
                fn(col, ret);
            } else {
                if (isMatchText(col.name, searchKeyword)) {
                    fn(col, ret);
                }
            }
        }
        return ret;
    };

    return {
        getList: (path) => {
            const parent = last(path);

            return parent == null
                ? [...folders.filter((fo) => fo.id && fo.parentFolderID == null), ...groupItems(folders.find((fo) => fo.id == null)[type], searchKeyword)]
                : [
                      ...folders.filter((fo) => fo.id && fo.parentFolderID === parent.id),
                      ...groupItems(
                          parent[type].map((col) => ({
                              ...col,
                              folderID: parent.id,
                          })),
                          searchKeyword
                      ),
                  ];
        },
        isGroupNode: (n) => n[type],
        isAlwaysExpanded: () => !isBlank(searchKeyword),
    };
};

function getChildrenFolders({
    folderId, folders, itemsProp, groupFn, keyword = "", hideEmptyFolder = false, filterFn,
}) {
    return folders
        .filter((f) => f.parentFolderID === folderId)
        .map((f) => ({
            ...f,
            itemsProp,
            [itemsProp]: groupFn(f[itemsProp], keyword, filterFn),
            children: getChildrenFolders({
                folderId: f.id,
                folders: folders.filter((f) => f.id),
                itemsProp,
                groupFn,
                keyword,
                hideEmptyFolder,
                filterFn,
            }).filter((f) => (!isBlank(keyword) || hideEmptyFolder ? f[itemsProp].length > 0 || f.children.length > 0 : true)),
        }));
}

export function buildFoldersTree({
    folders = [],
    keyword = "", itemsProp = "collections", groupFn = groupItemsDefault, hideEmptyFolder = false, filterFn
}) {
    return folders
        .filter((f) => !f.id)
        .map((f) => {
            return ({
                ...f,
                itemsProp,
                [itemsProp]: groupFn(f[itemsProp], keyword, filterFn),
                children: getChildrenFolders({
                    folderId: f.id,
                    folders: folders.filter((f) => f.id),
                    itemsProp,
                    groupFn,
                    keyword,
                    hideEmptyFolder,
                    filterFn,
                }).filter((f) => (!isBlank(keyword) || hideEmptyFolder ? f[itemsProp].length > 0 || f.children.length > 0 : true)),
            });
        });
}
