import "./calendar-2.scss";

import {css, cx} from "emotion";
import * as React from "react";

import {StyledClass} from "@common/react/styled-class";
import {getTooltipFontSizes} from "@common/ui-components/charts/factory/tooltip/shared-tooltip-formatter/get-tooltip-font-sizes";
import {hexToRgb} from "@common/ui-components/styles/colors";
import {tooltipService4} from "../../../../web-client/src/routes/common/tooltip3/tooltip-service-3";
import {cs} from "@common/react/chain-services";
import {findLastIndex, flatten1} from "@common/utils/collections";
import {compares, getMonthWeeks, sameDate, today} from "@common/utils/dates/date-object";
import {fullDaysInWeek} from "@common/utils/dates/day-in-weeks";
import {inRange} from "@common/utils/ranges";

export const Calendar2 = ({month, originalRanges, theme, onHover, ranges, size = 16, space = 4, getHighlight, onSelect, selectControl}) =>
    cs(
        [
            "tooltip",
            (_, next) => {
                const themeMode = theme ? theme.dataVisualization.toolTipsAndLegends.tooltipTheme.toLowerCase() : "light";
                return tooltipService4({
                    direction: theme?.tooltipHover?.position?.toLowerCase() || "below",
                    className: `${themeMode}-theme`,
                    tooltipContentStyle: {
                        fontSize: theme ? getTooltipFontSizes(theme).small : "12px",
                        fontFamily: theme?.general?.canvas?.fontFamily,
                        color: themeMode === "light" ? theme?.general?.canvas?.fontColorRGB ?? "#546B81" : "#E2E2E2",
                    },
                    next,
                });
            },
        ],
        [
            "dayClass",
            ({theme}, next) => {
                const borderRadius = size / 3;
                const componentTheme = theme?.general?.components;
                const buttonTheme = theme?.general?.buttons;
                const rgb = hexToRgb(buttonTheme?.primaryButton?.backgroundColorRGB ?? "#0276F2");
                return StyledClass({
                    next,
                    content: {
                        width: `${size}px!important`,
                        height: `${size}px`,
                        "line-height": `${size}px`,
                        "font-size": `${size * 0.4}px`,
                        color: `${componentTheme?.menuTextColorRGB ?? "black"} !important`,
                        "&.dayHighlight": {
                            color: `${buttonTheme?.primaryButton?.fontColorRGB ?? "white"} !important`,
                            "background-color": `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.5) !important`,
                            "&.from": {
                                "border-top-left-radius": `${borderRadius}px`,
                                "border-bottom-left-radius": `${borderRadius}px`,
                            },
                            "&.to": {
                                "border-top-right-radius": `${borderRadius}px`,
                                "border-bottom-right-radius": `${borderRadius}px`,
                            },
                        },
                        "&.head": {
                            color: `${buttonTheme?.primaryButton?.fontColorRGB ?? "white"} !important`,
                            "background-color": `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}) !important`,
                        },

                        "&.today > span": {
                            position: `absolute`,
                            width: `${size * 0.8}px`,
                            height: `${size * 0.8}px`,
                            top: `50%`,
                            left: `50%`,
                            transform: `translate(-50%, -50%)`,
                            border: `1px solid ${componentTheme?.menuTextColorRGB ?? "black"}`,
                            "border-radius": `50%`,
                        },
                        "&.today.dayHighlight > span": {
                            "border-color": `${buttonTheme?.primaryButton?.fontColorRGB ?? "white"}`,
                        },
                        "&.today.head > span": {
                            "border-color": `${buttonTheme?.primaryButton?.fontColorRGB ?? "white"}`,
                        },
                    },
                });
            },
        ],
        [
            "lineClass",
            (_, next) => {
                return StyledClass({
                    next,
                    content: {
                        height: `${size}px`,
                    },
                });
            },
        ],
        ({tooltip, dayClass, lineClass}) => {
            const borderRadius = size / 3;
            const position = (i) => i * (size + space);

            const monthWeeks = getMonthWeeks(month);
            const now = today();
            const originRange = originalRanges[0]?.range;
            return (
                <div
                    className={cx(
                        "calendar-2 calendar-2-69z",
                        css`
                            width: ${size * 7 + space * 6}px;
                        `
                    )}
                >
                    <div className={cx("day-header", lineClass)}>
                        {fullDaysInWeek.map((label, i) => (
                            <div className={cx("day", dayClass)} key={i} style={{left: position(i)}}>
                                {label}
                            </div>
                        ))}
                    </div>
                    <div className="lines" onMouseLeave={() => onHover?.(null)}>
                        {monthWeeks.map((line, i) => (
                            <div className={cx("line", lineClass)} key={i}>
                                {(() => {
                                    const days = line.map((day, i) => ({
                                        day,
                                        pos: i,
                                    }));
                                    return (
                                        <>
                                            {ranges && RangesHighlight({borderRadius, ranges, days, position, size})}

                                            {days.map(({day, pos}) => {
                                                const dayHighlight = getHighlight
                                                    ? getHighlight(day)
                                                    : ranges && getDayHighlightByRanges(day, {ranges});
                                                const selectable = selectControl?.selectable?.(day) ?? {value: true};
                                                const isCurrentMonth = month.month === day.month && month.year === day.year;
                                                const isToday = sameDate(now, day);

                                                const isHead = sameDate(day, originRange.from) || sameDate(day, originRange.to);
                                                const bind = tooltip?.(() => selectable.message ?? "");
                                                return (
                                                    <div
                                                        className={cx("day", dayClass, {
                                                            dayHighlight: !!dayHighlight,
                                                            other: !isCurrentMonth,
                                                            unselectabled: !selectable.value,
                                                            head: isHead,
                                                            from: !dayHighlight?.fromSameTo && dayHighlight?.name === "from",
                                                            to: !dayHighlight?.fromSameTo && dayHighlight?.name === "to",
                                                            today: isToday,
                                                        })}
                                                        key={pos}
                                                        style={{
                                                            left: position(pos),
                                                        }}
                                                        onMouseDown={(e) => {
                                                            e.preventDefault();
                                                            selectable.value && onSelect(day);
                                                        }}
                                                        onMouseEnter={(e) => {
                                                            if (selectable.value) {
                                                                onHover?.(day);
                                                            } else {
                                                                bind?.onMouseEnter?.(e);
                                                            }
                                                        }}
                                                        onMouseLeave={(e) => !selectable.value && bind?.onMouseLeave?.(e)}
                                                    >
                                                        <span />
                                                        {day.day}
                                                    </div>
                                                );
                                            })}
                                        </>
                                    );
                                })()}
                            </div>
                        ))}
                    </div>
                </div>
            );
        }
    );

const RangesHighlight = ({ranges, days, position, size, borderRadius}) =>
    ranges.map((range, rangeIndex) => {
        if (!range.range?.from || !range.range?.to) {
            return null;
        }
        const start = days.findIndex(({day}) => inRange(day, range.range, compares));
        if (start === -1) {
            return null;
        }

        const end = findLastIndex(days, ({day}) => inRange(day, range.range, compares));
        const left = position(days[start].pos);

        const highlightCss = css`
            height: ${size}px !important;
            background: ${range.color} !important;

            left: ${left}px !important;
            width: ${position(days[end].pos) + size - left}px !important;

            &.from {
                border-top-left-radius: ${borderRadius}px;
                border-bottom-left-radius: ${borderRadius}px;
            }

            &.to {
                border-top-right-radius: ${borderRadius}px;
                border-bottom-right-radius: ${borderRadius}px;
            }
        `;
        const fromSameTo = sameDate(range.range.from, range.range.to);
        return (
            <div
                key={rangeIndex}
                className={cx("range-highlight", highlightCss, {
                    from: !fromSameTo && sameDate(range.range.from, days[start]?.day),
                    to: !fromSameTo && sameDate(range.range.to, days[end]?.day),
                })}
            />
        );
    });

const getDayHighlightByRanges = (date, {ranges}) => {
    return flatten1(
        ranges.map((r) => [
            {date: r.range.from, color: r.color, name: "from", fromSameTo: sameDate(r.range.to, r.range.from)},
            {date: r.range.to, color: r.color, name: "to", fromSameTo: sameDate(r.range.to, r.range.from)},
        ])
    ).find((r) => sameDate(r.date, date));
};
