import React from "react";
import {cs} from "@common/react/chain-services";
import {FieldSection} from "../../../../../collection/tile/edit/left-panel/tabs/fields-tab/chart-types/common/field-section/field-section";
import {FieldInfoProvider} from "../../../../../collection/tile/edit/left-panel/tabs/tile-tab/chart-types/common/field-info-provider/field-info-provider";
import {FontFamilyDropdown} from "../../../../../collection/theme/common/theme-tab/font-family-dropdown/font-family-dropdown";
import {ffToDropdown} from "../../../../../../../../common/form/ff-to-dropdown";
import {FontSizeSelect} from "../../../../../collection/tile/edit/left-panel/tabs/tile-tab/chart-types/common/font-size-select";
import {LpColorLine} from "../../../../../common/left-panel/lp-color-line";
import {ffToColorPickerLine} from "../../../../../../../../common/form/ff-to-color-picker-line";
import {FontOptionsSelect} from "../../../../../collection/tile/edit/left-panel/tabs/tile-tab/chart-types/table/panels/font-options-select/font-options-select";
import {CaseOptionSelect} from "../../../../../collection/theme/common/case-options/case-options-select";
import {NumberInput} from "../../../../../../../../common/form/number-input/number-input";
import {LpShowHideLine} from "../../../../../common/left-panel/lp-show-hide-line";
import {ffToShowHideLine} from "../../../../../../../../common/form/ff-to-show-hide-line";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";

export const CanvasTheme = ({form}) =>
    cs(() => {
        return (
            <>
                {FieldSection({
                    header: FieldInfoProvider({
                        field: <div className="text">FONTS</div>,
                        noCollapsible: true,
                    }),
                    content: (
                        <>
                            {FontFamilyDropdown({
                                label: "Collection Font",
                                ...ffToDropdown(form.field(["general", "canvas", "fontFamily"])),
                            })}

                            {FontSizeSelect({
                                label: "Font Size group",
                                ...ffToDropdown(form.field(["general", "canvas", "fontSize"])),
                                noThemeDefaultOption: true,
                            })}

                            {LpColorLine({
                                label: "Font Color",
                                ...ffToColorPickerLine(form.field(["general", "canvas", "fontColorRGB"])),
                            })}
                        </>
                    ),
                })}

                {FieldSection({
                    header: FieldInfoProvider({
                        field: <div className="text">CANVAS STYLE</div>,
                        noCollapsible: true,
                    }),
                    content: (
                        <>
                            {LpColorLine({
                                label: "Canvas Background",
                                ...ffToColorPickerLine(form.field(["general", "canvas", "backgroundColorRGB"])),
                            })}

                            {DropdownSelect({
                                label: "Tile Spacing",
                                ...ffToDropdown(form.field(["general", "canvas", "tileSpacing"])),
                                list: ["Narrow", "Standard", "Wide"],
                            })}
                        </>
                    ),
                })}
            </>
        );
    });
