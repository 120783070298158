import "./live-grid.scss";

import * as React from "react";
import {cx} from "emotion";

import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {consumeContext} from "@common/react/context";

import {LiveGridPanel} from "../../live-grid-panel/live-grid-panel";
import {ChartDraw} from "../../../charts/chart-draw";
import {createLoadTileData} from "../data/load-tile-data";
import {createDownloadTileData} from "../data/download-tile-data";
import {createLoadTileData2} from "../data/load-tile-data-2";
import {LoadingIndicator} from "../../../loading-indicator/loading-indicator";
import {ContainerTile} from "../../../container-tile/container-tile";
import {lightOrDark} from "@common/utils/color-util";
import {forDarkBGColors, forLightBGColors} from "../../../charts/common/loading-wrapper/loading-skeletion";
import {chain} from "@common/utils/fs";
import {sortMulti} from "@common/utils/collections";
import {AutoRefreshController} from "./auto-refresh-controller";
import {NO_DATA_TILES} from "../../../../../web-client/src/routes/collection/common/select-tile-type/tile-types";

export const LiveGrid = ({
    getTileFilters,
    collection,
    sdkDashboard,
    isEditTile = false,
    onEdit = null,
    onSelectTile = null,
    disabledTileActions = false,
    renderOverlay,
    apiKey = null,
    defaultData,
    forceAutoRefresh = false,
    loadedTileCounter,
}) =>
    cs(
        consumeContext("apis"),
        consumeContext("theme"),
        consumeContext("currencyOverrides"),
        ({theme}, next) => (!theme ? LoadingIndicator() : next()),
        [
            "skeletonColor",
            (_, next) =>
                UseState({
                    next,
                    initValue: {
                        forDarkBGColors,
                        forLightBGColors,
                    },
                }),
        ],
        ({apis, theme: _theme, skeletonColor, currencyOverrides}) => {
            const {forDarkBGColors, forLightBGColors} = skeletonColor.value;
            let theme = {
                ..._theme,
                skeletonColor: lightOrDark(_theme.general.canvas.backgroundColorRGB) == "light" ? forLightBGColors : forDarkBGColors,
            };

            return (
                <div className={cx("live-grid-6qr live-grid", !sdkDashboard && "verb-dashboard")}>
                    {LiveGridPanel({
                        collection,
                        spacing: {Narrow: 3, Standard: 6, Wide: 12}[theme?.general.canvas.tileSpacing],
                        tiles: chain(
                            collection?.gridLocations,
                            (_) => sortMulti(_, [(gridLog) => gridLog.rowStart, (gridLog) => gridLog.colStart]),
                            (_) =>
                                _.map((gridLoc) => {
                                    const size = {
                                        width: gridLoc.colSpan,
                                        height: gridLoc.rowSpan,
                                    };

                                    const isOnChangeFilterType = collection?.filterStyle?.runType === "OnChange";
                                    const enabledAutoRefresh = sdkDashboard || forceAutoRefresh || collection?.autoDataRefresh;

                                    const renderChart = (tile) =>
                                        AutoRefreshController({
                                            enabledAutoRefresh,
                                            backgroundColor: theme.general.tile.styles.tileBackgroundColorRGB,
                                            loadDataOnInit: false,
                                            tileID: tile.id,
                                            tile,
                                            tileFilters: isOnChangeFilterType ? getTileFilters?.(tile.id) : {},
                                            render: ({tile, tileFilters, refreshKey}, loadData, hasChanges) => {
                                                const loadDataFunc = ["TableTile", "MapTile"].includes(tile.$type)
                                                    ? createLoadTileData2
                                                    : createLoadTileData;
                                                return (
                                                    <ChartDraw
                                                        key={refreshKey}
                                                        {...{
                                                            isEditTile,
                                                            renderOverlay,
                                                            hasChanges,
                                                            tileFilters:
                                                                !enabledAutoRefresh && isOnChangeFilterType
                                                                    ? tileFilters
                                                                    : getTileFilters?.(tile.id),
                                                            loadData: {
                                                                key: apiKey,
                                                                load: loadData
                                                                    ? loadDataFunc({
                                                                          tileId: tile.id,
                                                                          collectionId: collection.id,
                                                                          // collectionId: collection.isIndependentContext
                                                                          //     ? null
                                                                          //     : collection.id,
                                                                          isShared: collection.isIndependentContext,
                                                                          apis,
                                                                          loadedTileCounter,
                                                                          refreshKey,
                                                                      })
                                                                    : null,
                                                            },
                                                            downloadData: createDownloadTileData({
                                                                tileId: tile.id,
                                                                collectionId: collection.id,
                                                                // collectionId: collection.isIndependentContext
                                                                //     ? null
                                                                //     : collection.id,
                                                                isShared: collection.isIndependentContext,
                                                                apis,
                                                                apiKey,
                                                            }),
                                                            tile: {
                                                                ...tile,
                                                                ...(currencyOverrides
                                                                    ? {
                                                                          currencyOverrides,
                                                                      }
                                                                    : {}),
                                                            },
                                                            size,
                                                            theme,
                                                            onEdit,
                                                            isLive: true,
                                                            sdkDashboard,
                                                            disabledTileActions,
                                                            defaultData,
                                                        }}
                                                    />
                                                );
                                            },
                                        });

                                    return {
                                        key: gridLoc.tile.id,
                                        position: {
                                            x: gridLoc.colStart - 1,
                                            y: gridLoc.rowStart - 1,
                                        },
                                        size,
                                        $type: gridLoc.tile.$type,
                                        name: gridLoc.tile.title,
                                        render: () => {
                                            return (
                                                <div
                                                    className="chart-2ei"
                                                    style={{
                                                        backgroundColor:
                                                            gridLoc.tile?.style?.backgroundStyle == "NoBackground"
                                                                ? "transparent"
                                                                : gridLoc.tile.$type == "SectionTile"
                                                                ? theme?.dataVisualization.sectionTile.backgroundColorRGB ||
                                                                  theme?.general.tile.styles.tileBackgroundColorRGB
                                                                : theme?.general.tile.styles.tileBackgroundColorRGB,
                                                        borderColor:
                                                            gridLoc.tile?.style?.backgroundStyle == "NoBackground"
                                                                ? "transparent"
                                                                : theme?.general.tile.styles.tileBorderColorRGB,
                                                        borderRadius: theme?.general.tile.styles.tileCornerRadius,
                                                        borderWidth: theme?.general.tile.styles.tileBorderWidth,
                                                        boxShadow:
                                                            theme?.general.tile.styles.tileShadow &&
                                                            gridLoc.tile?.style?.backgroundStyle != "NoBackground"
                                                                ? "0 2px 8px 6px rgba(7, 6, 6, 0.05)"
                                                                : "",
                                                    }}
                                                >
                                                    {gridLoc.tile.$type == "ContainerTile"
                                                        ? ContainerTile({
                                                              isLiveGrid: true,
                                                              containerTile: gridLoc.tile,
                                                              theme,
                                                              onSelectTile,
                                                              renderChart,
                                                              isDonePreLoad:
                                                                  loadedTileCounter.value.loadingTiles >=
                                                                  collection?.gridLocations.filter(
                                                                      (gL) => ![...NO_DATA_TILES, "ContainerTile"].includes(gL.tile.$type)
                                                                  ).length,
                                                          })
                                                        : renderChart(gridLoc.tile)}
                                                </div>
                                            );
                                        },
                                    };
                                })
                        ),
                    })}
                </div>
            );
        }
    );
