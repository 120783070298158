const {defaultLeftPanelWidth} = require("../../layout/edit-model-layout");
const {unique} = require("../../../../../../../common/utils/collections");
const {autoOrganizeModel} = require("../../../common/auto-organize-model");

const getContainerWidth = (length) => {
    const line = 4 * Math.ceil(length / 4);
    const totalLines = (line - 2) / 2;
    return Math.floor(totalLines / 2) + 1;
};
exports.getContainerWidth = getContainerWidth;

const generateTablePositions = (tables, relationships, container, scale) => {
    const updatedTables = autoOrganizeModel({tables, relationships, container});

    return updatedTables
        .filter((t) => t.position)
        .map((t) => ({
            ...t,
            position: {
                x: defaultLeftPanelWidth + t.position.x * scale,
                y: t.position.y * scale - 50,
            },
        }));
};

exports.generateTablePositions = generateTablePositions;

const buildArr = (totalLine) => {
    if (totalLine == 1) return [0];
    let ret = [];
    for (let i = -Math.floor(totalLine / 2); i <= Math.floor(totalLine / 2); i++) {
        ret.push(i);
    }
    return ret;
};

const buildTree = ({tables, centerPoint, tableWidth}) => {
    const line = 4 * Math.ceil(tables.length / 4);

    const totalLines = (line - 2) / 2;

    let ret = [];
    ret.push({
        y: centerPoint.y - tableWidth * (Math.floor(totalLines / 2) + 1),
        x: centerPoint.x,
    });

    buildArr(totalLines).forEach((i) => {
        ret.push({
            y: centerPoint.y + tableWidth * i,
            x: centerPoint.x - (Math.floor(totalLines / 2) + 1 - Math.abs(i)) * tableWidth,
        });

        ret.push({
            y: centerPoint.y + tableWidth * i,
            x: centerPoint.x + (Math.floor(totalLines / 2) + 1 - Math.abs(i)) * tableWidth,
        });
    });

    ret.push({
        y: centerPoint.y + tableWidth * (Math.floor(totalLines / 2) + 1),
        x: centerPoint.x,
    });

    return {
        points: ret,
        totalLines,
    };
};

const mappingRelationships = ({relationships, tables}) => {
    let ret = [];

    const getColumnID = (columnID) => {
        for (let table of tables) {
            for (let column of table.columns) {
                if (columnID == column.dataSourceColumnID) {
                    return column.id;
                }
            }
        }
    };

    for (let relationship of relationships) {
        ret.push({
            leftColumnID: getColumnID(relationship.leftColumnID),
            rightColumnID: getColumnID(relationship.rightColumnID),
        });
    }

    return ret;
};

exports.mappingRelationships = mappingRelationships;

const isAllRelationshipsRelated = ({tables, relationships}) => {
    for (let table of tables) {
        if (!table.columns.find((c) => relationships.find((r) => r.leftColumnID == c.id || r.rightColumnID == c.id))) {
            return false;
        }
    }

    return true;
};

exports.isAllRelationshipsRelated = isAllRelationshipsRelated;
