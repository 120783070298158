import {cs} from "@common/react/chain-services";
import {ConfirmDialog} from "../../common/confirm-dialog/confirm-dialog";

export const DeleteTileModal = ({next: rootNext, isRemove = false, onConfirm}) =>
    cs(
        [
            "confirmDeleteTileModal",
            (_, next) =>
                ConfirmDialog({
                    next,
                    title: `${isRemove ? "Remove" : "Delete"} Tile?`,
                    body: `Are you sure that you want to ${isRemove ? "remove" : "delete"} this tile?`,
                }),
        ],
        [
            "removeTile",
            ({confirmDeleteTileModal}) =>
                rootNext(async (...args) => {
                    const res = await confirmDeleteTileModal.show();

                    if (res) {
                        onConfirm?.(...args);
                    }

                    return null;
                }),
        ]
    );
