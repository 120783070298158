function textFilter() {
    return [
        {
            path: ["defaultFreeFormValue"],
            validators: [
                {
                    when: (data) => data?.$type === "TextFilter" && data?.required && data?.option === "FreeFormText",
                    validate: (v) => !!v,
                    getMessage: () => "Default value is required.",
                },
            ],
        },
        {
            path: ["itemsVisible"],
            validators: [
                {
                    when: (data) => data?.$type === "TextFilter" && data?.option === "SelectableList" && ["ButtonToggle", "Checkbox", "ButtonGroup"].includes(data?.design),
                    validate: (v) => v >= 2 && v <= 10,
                    getMessage: (v) => (v > 10 ? "Visibility limit cannot be more than 10." : "Visibility limit cannot be less than 2."),
                },
            ],
        },
        {
            path: ["selectableListSort"],
            validators: [
                {
                    when: (data) => data?.$type === "TextFilter" && data?.option === "SelectableList" && data?.selectableListSort !== null,
                    validate: (v) => {
                        if (!v?.hasOwnProperty("modelColumnID")) return false;
                        return [v.modelColumnID, v.modelID, v.modelTableID].indexOf("00000000-0000-0000-0000-000000000000") === -1;
                    },
                    getMessage: (v) => "Value Field is required",
                },
            ],
        },
        {
            path: ["selectableListSort", "aggregationFunc"],
            validators: [
                {
                    when: (data) => data?.$type === "TextFilter" && data?.option === "SelectableList" && data?.selectableListSort !== null,
                    validate: (v) => !!v,
                    getMessage: (v) => "Field Aggregation Type is required",
                },
            ],
        },
    ];
}
exports.textFilter = textFilter;
