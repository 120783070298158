const booleanFormatter = (value) => {
    if (value === true) {
        return "True";
    }
    if (value === false) {
        return "False";
    }
    return value;
};
exports.booleanFormatter = booleanFormatter;
