import * as React from "react";
import {cs} from "@common/react/chain-services";
import {FreeFormText} from "./free-form-text";
import "./text-filter-options.scss";
import {Invoke} from "@common/react/invoke";
import {spc} from "@common/react/state-path-change";

export const TextFilterOptions = ({filter, form}) =>
    cs(({}) => (
        <div className="text-filter-options text-filter-options-9s3">
            {filter.value?.option !== "FreeFormText" &&
                Invoke({
                    fn: () => spc(filter, ["option"], () => "FreeFormText"),
                })}

            {filter.value?.option === "FreeFormText" && FreeFormText({filter, form})}
        </div>
    ));
