const _isWindowFramed = () => {
    /*jshint eqeqeq:false */
    // Cannot compare WindowProxy objects with ===/!==
    const isNotChildWindow = !window.opener;
    const hasWindowAncestors = !!((window.top && window !== window.top) || (window.parent && window !== window.parent));
    return isNotChildWindow && hasWindowAncestors;
};

const _isFrameElement = (el) => {
    try {
        return (
            typeof el === "object" &&
            el !== null &&
            el.nodeType === 1 &&
            /^I?FRAME$/.test(el.nodeName || "") &&
            !!el.hasAttribute &&
            !!el.getAttribute &&
            !!el.setAttribute
        );
    } catch (err) {
        return false;
    }
};

const _getFrame = () => {
    try {
        const frameEl = window.frameElement;
        if (!_isFrameElement(frameEl)) {
            return null;
        }
        return frameEl;
    } catch (err) {
        return null;
    }
};

const isSandboxed = (frameEl) => {
    try {
        return !!frameEl.hasAttribute("sandbox");
    } catch (sandboxErr) {
        return null;
    }
};

const checkIframeSandbox = (values) => {
    if (!_isWindowFramed()) {
        return true;
    }

    const frameEl = _getFrame();
    if (!frameEl) {
        return false;
    }

    const sandboxed = isSandboxed(frameEl);
    if (sandboxed === false) {
        return true;
    } else if (sandboxed == null) {
        return false;
    }

    const sandboxAllowances = frameEl.getAttribute("sandbox") || "";

    for (let i = 0; i < values.length; i++) {
        const value = values[i];
        if (sandboxAllowances.indexOf(value) == -1) {
            return false;
        }
    }

    return true;
    // return sandboxAllowances.replace(/^\s+|\s+$/g, "").toLowerCase().split(/\s+/).includes("allow-downloads");
};

const canDownloadDirectly = () => checkIframeSandbox(["allow-downloads"]);
exports.canDownloadDirectly = canDownloadDirectly;

const canPrint = () => checkIframeSandbox(["allow-modals"]);
exports.canPrint = canPrint;
