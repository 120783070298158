exports.aggregationFuncs = [
    {
        label: "None",
        value: "None",
    },
    {
        label: "Count",
        value: "Count",
    },
    {
        label: "Count (including Nulls)",
        value: "CountIncNull",
    },
    {
        label: "Count Distinct",
        value: "CountDistinct",
    },
    {
        label: "Count Distinct (including Nulls)",
        value: "CountDistinctIncNull",
    },
    {
        label: "Count Null",
        value: "CountNull",
    },
    {
        label: "Count If True",
        value: "CountIfTrue",
        dataTypes: ["Bool"],
    },
    {
        label: "Count If False",
        value: "CountIfFalse",
        dataTypes: ["Bool"],
    },
    {
        label: "Sum",
        value: "Sum",
        dataTypes: ["Int", "Double"],
    },
    {
        label: "Average",
        value: "Average",
        dataTypes: ["Int", "Double"],
    },
    {
        label: "Median",
        value: "Median",
        dataTypes: ["Int", "Double"],
    },
    // {
    //     label: "Mode",
    //     value: "Mode",
    // },
    {
        label: "Minimum",
        value: "Minimum",
        dataTypes: ["Int", "Double", "Bool", "DateTime", "DateTimeOffset", "IDInt", "Text"],
    },
    {
        label: "Maximum",
        value: "Maximum",
        dataTypes: ["Int", "Double", "Bool", "DateTime", "DateTimeOffset", "IDInt", "Text"],
    },
    {
        label: "Standard Deviation (Partial Sampling)",
        value: "StandardDeviationPartial",
        dataTypes: ["Int", "Double"],
    },
    {
        label: "Standard Deviation (Entire Population)",
        value: "StandardDeviationPopulation",
        dataTypes: ["Int", "Double"],
    },
    {
        label: "Variance (Partial Sampling)",
        value: "VariationPartial",
        dataTypes: ["Int", "Double"],
    },
    {
        label: "Variance (Entire Population)",
        value: "VariationPopulation",
        dataTypes: ["Int", "Double"],
    },
    {
        label: "First Value (Ascending)",
        value: "FirstValueAsc",
    },
    {
        label: "First Value (Descending)",
        value: "FirstValueDesc",
    },
    {
        label: "Last Value (Ascending)",
        value: "LastValueAsc",
    },
    {
        label: "Last Value (Descending)",
        value: "LastValueDesc",
    },
];

exports.aggregationFuncsCal = [
    {
        label: "Sum",
        value: "Sum",
    },
    {
        label: "Average",
        value: "Average",
    },
    {
        label: "Median",
        value: "Median",
    },
    {
        label: "Standard Deviation (Partial Sampling)",
        value: "StandardDeviationPartial",
    },
    {
        label: "Standard Deviation (Entire Population)",
        value: "StandardDeviationPopulation",
    },
    {
        label: "Variance (Partial Sampling)",
        value: "VariationPartial",
    },
    {
        label: "Variance (Entire Population)",
        value: "VariationPopulation",
    },
];

exports.aggregationFuncsOther = [
    {
        label: "None",
        value: "None",
    },
    {
        label: "Count",
        value: "Count",
    },
    {
        label: "Count (including Nulls)",
        value: "CountIncNull",
    },
    {
        label: "Count Distinct",
        value: "CountDistinct",
    },
    {
        label: "Count Distinct (including Nulls)",
        value: "CountDistinctIncNull",
    },
    {
        label: "Count Null",
        value: "CountNull",
    },
    {
        label: "First Value (Ascending)",
        value: "FirstValueAsc",
    },
    {
        label: "First Value (Descending)",
        value: "FirstValueDesc",
    },
    {
        label: "Last Value (Ascending)",
        value: "LastValueAsc",
    },
    {
        label: "Last Value (Descending)",
        value: "LastValueDesc",
    },
];
