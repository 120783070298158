const {getRadii, getEmptyColor, getArcSizeDegree, isCompareEnabled} = require("./gauge-helpers");
const {cGetFontSize} = require("../../common/style-map/font-size");

const paneOptions = ({style, data, theme, innerSize}) => {
    const arcSize = getArcSizeDegree(style.arcSize);
    const {leastInnerRadius, splitPointRadius} = getRadii(style);

    const getFontSize = cGetFontSize(theme.general.canvas.fontSize, theme);
    let fontSize = getFontSize({
        group: theme.dataVisualization.fonts.dataLabelFontSize,
    });

    const commonAttrs = {
        size: "100%",
        // only gauge with arcSize = 180 has padding issue.
        ...(style.arcSize === "Deg180"
            ? (() => {
                  const defaultRatioHeight = 1;
                  const minMaxLabelHeight = fontSize + 4;
                  const calculatedAvailableRatioHeight = defaultRatioHeight - (style.minMaxLabelsShown ? minMaxLabelHeight / innerSize.height : 0.05);
                  const radius = Math.min((innerSize.width - 20) / 2, innerSize.height) - (style.minMaxLabelsShown ? minMaxLabelHeight : 0);
                  const bottomPosition = calculatedAvailableRatioHeight - (calculatedAvailableRatioHeight - radius / innerSize.height) / 2;

                  return {
                      size: radius * 2 * calculatedAvailableRatioHeight,
                      center: ["50%", `${bottomPosition * 100}%`],
                  };
              })()
            : {}),
        // size: getSize(style.arcSize),
        // center: getCenter(style.arcSize),
        // center: ["50%", "50%"],
        startAngle: -1 * (arcSize / 2),
        endAngle: arcSize / 2,
    };

    const backgroundColor = getEmptyColor({style, theme});
    const compareEnabled = isCompareEnabled({style, data});

    const borderOption = theme.dataVisualization.gauges.showBorder
        ? {
              borderColor: theme.dataVisualization.gauges.borderColorRGB,
              borderWidth: theme.dataVisualization.gauges.borderWidth,
          }
        : {
              borderWidth: 0,
          };

    return {
        pane: [
            {
                ...commonAttrs,
                background: {
                    backgroundColor,
                    // borderWidth: 0,
                    ...borderOption,
                    outerRadius: "100%",
                    innerRadius: `${compareEnabled ? splitPointRadius : leastInnerRadius}%`,
                    shape: "arc",
                },
            },
            // use pane to carry plot lines of compare gauge
            compareEnabled && {
                ...commonAttrs,
                background: {
                    backgroundColor,
                    borderWidth: 0,
                    outerRadius: `${splitPointRadius}%`,
                    innerRadius: `${leastInnerRadius}%`,
                    shape: "arc",
                },
            },
        ].filter((v) => v),
    };
};
exports.paneOptions = paneOptions;

// const getCenter = (arcSize) => ({
//     "Deg180": ["50%", "90%"],
//     "Deg270": ["50%", "80%"],
//     "Deg300": ["50%", "70%"],
//     "Deg330": ["50%", "60%"],
//     "Deg360": ["50%", "50%"],
// }[arcSize]);
//
// const getSize = (arcSize) => ({
//     "Deg180": "100%",
//     "Deg270": "160%",
//     "Deg300": "140%",
//     "Deg330": "120%",
//     "Deg360": "100%",
// }[arcSize]);
