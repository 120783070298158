const ChartSize = {
    Small: 0,
    Standard: 1,
    Large: 2,
};

const MapHeight = {
    [ChartSize.Small]: 60,
    [ChartSize.Standard]: 60,
    [ChartSize.Large]: 72,
};

const MapSparkLineSize = {
    [ChartSize.Small]: 0.4,
    [ChartSize.Standard]: 0.5,
    [ChartSize.Large]: 0.6,
};

export const getCalculatedStyles = ({sparkLineOrientation, sparkLineSize}) => {
    if (["LeftRight", "RightLeft"].includes(sparkLineOrientation)) {
        let height = MapHeight[sparkLineSize];
        return {
            spaceStyle: {
                width: 20,
                height,
            },
            flexDirection: sparkLineOrientation == "LeftRight" ? "row" : "row-reverse",
            kpiStyle: {
                height: "100%",
                width: `calc((100% - 20px) * ${1 - MapSparkLineSize[sparkLineSize]})`,
            },
            sparkLineStyle: {
                height,
                width: `calc((100% - 20px) * ${MapSparkLineSize[sparkLineSize]})`,
            },
        };
    }

    return {
        spaceStyle: {},
        flexDirection: sparkLineOrientation == "TopBottom" ? "column" : "column-reverse",
        kpiStyle: {
            width: "100%",
            height: `calc(100% * ${1 - MapSparkLineSize[sparkLineSize]})`,
        },
        sparkLineStyle: {
            width: "100%",
            height: `calc(100% * ${MapSparkLineSize[sparkLineSize]})`,
        },
    };
};
