const {NewColumnIcon, NewViewIcon, DataViewIcon, NewAggregatedMeasureIcon} = require("./icons/icons");

const outputStepTypes = [
    {
        $type: "ColumnOutputStep",
        name: "New Column",
        iconSrc: require("./icons/new-column-icon.svg"),
        getIconSrc: (isAggregated) => (isAggregated ? require("./icons/aggregated-measure-output-icon.svg") : require("./icons/new-column-icon.svg")),
        icon: NewColumnIcon,
        getIcon: (isAggregated) => (isAggregated ? NewAggregatedMeasureIcon : NewColumnIcon),
    },
    {
        $type: "ViewOutputStep",
        name: "New View",
        iconSrc: require("./icons/new-view-icon.svg"),
        // icon: NewViewIcon
        icon: DataViewIcon,
    },
];
exports.outputStepTypes = outputStepTypes;
