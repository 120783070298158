import "./edit-tile.scss";

import * as React from "react";

import {cs} from "@common/react/chain-services";
import {ChangeQueue1} from "@common/react/change-queue-1";
import {consumeContext, provideContext} from "@common/react/context";
import {fragments} from "@common/react/fragments";
import {Load2} from "@common/react/load2";
import {scope} from "@common/react/scope";

import {changePath, getPath, setPath} from "@common/utils/arr-path";
import {cascadeChange} from "@common/utils/cascade";
import {unique} from "@common/utils/collections";
import {chain} from "@common/utils/fs";
import {equalDeep, keepOnly} from "@common/utils/objects";

import {LiveDashboard} from "@common/ui-components/live/live-dashboard/live-dashboard";
import {loadAutoRefreshProvider} from "@common/ui-components/live/live-dashboard/live-grid/auto-refresh-controller";
import {LoadingIndicator} from "@common/ui-components/loading-indicator/loading-indicator";

import {SingleEditorRegister} from "../../../common/single-editor/single-editor";

import {createGetFieldInfo} from "../../common/field-info/create-get-field-info";

import {Invoke} from "@common/react/invoke";
import {spc} from "@common/react/state-path-change";
import {UseState} from "@common/react/use-state";
import {ConvertToSharedTileModal} from "../../../common/convert-to-shared-tile-modal/convert-to-shared-tile-modal";
import {ToastWithAction} from "../../../common/toast/toast-service";
import {loadSharedTileFolders} from "../../../dashboard/common/load-shared-tile-folders";
import {DeleteTileModal} from "../../common/delete-tile-modal";
import {EditTileLayout} from "../../common/edit-tile-layout/edit-tile-layout";
import {loadViewAsContext} from "../../edit/layout/view-as-context";
import ViewCollections from "../../shared-tile/view-collections/view-collections";
import {EditTileLogic} from "./edit-tile-logic";
import {LeftPanel} from "./left-panel/left-panel";
import {DebugFunction} from "./left-panel/tabs/fields-tab/chart-types/common/debug-function/debug-function";
import {Tabs} from "./left-panel/tabs/tabs";

export const EditTile = ({}) =>
    cs(
        consumeContext("apis"),
        consumeContext("routing"),
        consumeContext("collection"),
        ({}, next) => loadSharedTileFolders({next}),
        [
            "convertToSharedTileDialog",
            (_, next) =>
                ConvertToSharedTileModal({
                    next,
                }),
        ],
        [
            "convertToShared",
            ({convertToSharedTileDialog, collection}, next) => {
                return next(async (gridLoc) => {
                    const sharedTile = await convertToSharedTileDialog.show({
                        tile: {
                            ...gridLoc.tile,
                            modelID: collection.value?.modelID,
                        },
                        defaultRowSpan: gridLoc.rowSpan,
                        defaultColSpan: gridLoc.colSpan,
                    });

                    if (sharedTile) {
                        spc(collection, ["gridLocations", (gl) => gl.tile.id === gridLoc.tile.id], (gl) => ({
                            ...gl,
                            tile: sharedTile,
                        }));
                    }
                });
            },
        ],
        (_, next) => provideContext("editingMode", true, next),
        (_, next) => loadViewAsContext({next}),
        ({collection}, next) =>
            loadAutoRefreshProvider({
                next,
                collection: collection.value,
                isSDKDashboard: false,
            }),
        ({routing, collection}, next) =>
            fragments(
                collection?.value
                    ? SingleEditorRegister({
                          goBack: () => routing.goto("dashboard", {tab: "collections"}),
                          getItemId: () => routing.params.colId,
                          otherButtons: [
                              {
                                  label: "Go to Preview",
                                  function: () => {
                                      window.open(`/#/env/${routing.params.envId}/collection/${routing.params.colId}/preview`, "_blank");
                                  },
                              },
                          ],
                          item: {
                              type: "collection",
                              parent: "Collections",
                              name: collection?.value.name,
                              id: collection?.value.id,
                          },
                      })
                    : null,
                next()
            ),
        consumeContext("viewAsContext"),
        ["leftPanelWidth", (_, next) => UseState({next, initValue: null})],
        ({leftPanelWidth}, next) => provideContext("leftPanelWidth", leftPanelWidth, next),
        ["viewAs", ({viewAsContext}, next) => next(viewAsContext?.viewAs?.value ?? null)],
        [
            "savingQueue",
            ({apis, collection}, next) =>
                ChangeQueue1({
                    fetchLatest: () => apis.collection.getCollection(collection.value.id),
                    save: apis.collection.upsertCollection,
                    next,
                }),
        ],
        ["remoteCollection", ({collection}, next) => next(collection)],

        [
            "tilePath",
            ({routing, collection}, next) => {
                if (collection.value) {
                    const foundTile = collection.value.filterDownloadTiles.find((tile) => tile?.id === routing.params.tileId);
                    if (foundTile) {
                        return next(["filterDownloadTiles", (tile) => tile.id === routing.params.tileId]);
                    }
                }

                return next(["gridLocations", (gl) => gl.tile.id === routing.params.tileId, "tile"]);
            },
        ],

        ["remoteTile", ({remoteCollection, tilePath}, next) => next(scope(remoteCollection, tilePath))],

        ({remoteCollection, remoteTile, routing}, next) => {
            if (remoteCollection.loading) {
                return null;
            }

            if (!remoteCollection.value || remoteCollection.apiError) {
                return Invoke({
                    onMounted: () => {
                        routing.goto("dashboard");
                    },
                });
            }

            if (!remoteTile.value || remoteTile.apiError) {
                return Invoke({
                    onMounted: () => {
                        routing.goto("edit-collection");
                    },
                });
            }

            return next();
        },

        [
            "theme",
            ({apis, remoteCollection, viewAs}, next) =>
                loadTheme({
                    collection: remoteCollection.value,
                    viewAs,
                    apis,
                    next,
                }),
        ],

        ({}, next) => createGetFieldInfo({next}),

        [
            "editTileLogic",
            ({remoteCollection, savingQueue, tilePath, remoteTile}, next) =>
                EditTileLogic({
                    next,
                    tile: remoteTile,
                    allowInvalidUpdates: true,
                    updateFunc: async (newTile) => {
                        if (newTile) {
                            const newCol = await savingQueue.push((c) => setPath(c, tilePath, newTile));
                            remoteCollection.onChange(newCol);
                            return getPath(newCol, tilePath);
                        }
                    },
                }),
        ],
        [
            "removeTile",
            ({remoteCollection, editTileLogic, savingQueue, routing, collection}, next) => {
                let isSharedTile = editTileLogic.tile?.value?.isShared;
                return DeleteTileModal({
                    next,
                    isRemove: isSharedTile,
                    onConfirm: async (tile) => {
                        if (!tile) {
                            return;
                        }
                        const pathUpdate = ["gridLocations"];
                        const gridLocations = getPath(remoteCollection.value, pathUpdate);
                        const newCol = await savingQueue.push((c) =>
                            setPath(
                                c,
                                pathUpdate,
                                gridLocations.filter((t) => t.tile.id != tile.id)
                            )
                        );
                        if (newCol) {
                            collection.reload?.();
                            routing.goto("edit-collection");
                        }
                    },
                });
            },
        ],
        [
            "fakeCollection",
            ({remoteCollection, localTile, editTileLogic}, next) =>
                next(
                    changePath(remoteCollection.value, ["gridLocations"], (gls) =>
                        chain(
                            gls,
                            (_) => {
                                let tileList = _?.filter((gl) => gl.tile.id === editTileLogic.tile.value?.id);
                                if (tileList.length == 0) {
                                    tileList = [
                                        {
                                            colSpan: 6,
                                            rowSpan: 2,
                                            tile: remoteCollection.value.filterDownloadTiles.find((v) => v.id == editTileLogic.tile.value?.id),
                                        },
                                    ];
                                }

                                return tileList.map((item) => {
                                    const isDownloadReportTile = item.tile.$type == "DownloadReportTile";

                                    if (!isDownloadReportTile) {
                                        return item;
                                    }

                                    return {
                                        ...item,
                                        colSpan: 24,
                                        // rowSpan: 12,
                                        rowSpan: null,
                                    };
                                });
                            },
                            (_) =>
                                cascadeChange(_, "[*]", (gl) => ({
                                    ...gl,
                                    colStart: 1,
                                    rowStart: 1,
                                }))
                        )
                    )
                ),
        ],
        ["debugFunction", ({editTileLogic}, next) => DebugFunction({tile: editTileLogic?.tile, isChildTile: false, next})],
        ({debugFunction}, next) => provideContext("debugFunction", debugFunction, next),
        ({
            routing,
            theme,
            remoteTile,
            debugFunction,
            removeTile,
            savingQueue,
            fakeCollection: collection,
            remoteCollection,
            editTileLogic,
            viewAs,
            leftPanelWidth,
            convertToShared,
        }) => {
            return cs(
                ["interactions", (_, next) => UseState({initValue: null, next})],
                [
                    "tabs",
                    (_, next) =>
                        !editTileLogic.tile.value
                            ? next()
                            : Tabs({
                                  ...editTileLogic,
                                  remoteTile,
                                  leftPanelWidth,
                                  isSaving: savingQueue.executing,
                                  size: {
                                      width: collection?.gridLocations[0]?.colSpan,
                                      height: collection?.gridLocations[0]?.rowSpan,
                                  },
                                  tileFuncs: {
                                      removeTile,
                                  },
                                  renderMain: ({renderOverlay}) =>
                                      !theme ? (
                                          <div
                                              className=""
                                              style={{
                                                  position: "relative",
                                                  top: 50,
                                              }}
                                          >
                                              {LoadingIndicator()}
                                          </div>
                                      ) : (
                                          LiveDashboard({
                                              theme,
                                              isEditTile: true,
                                              collection,
                                              renderOverlay,
                                              apiKey: viewAs?.value,
                                              disabledTileActions: true,
                                              noBackgroundColor: false,
                                              disabledFilters: unique([
                                                  ...remoteTile?.value?.filters.map((f) => keepOnly(f, ["$type", "columns"])),
                                                  ...collection?.filters
                                                      ?.filter((f) => f.disconnectedTiles.indexOf(remoteTile?.value?.id) > -1)
                                                      .map((f) => keepOnly(f, ["$type", "columns"])),
                                              ]),
                                          })
                                      ),
                                  next,
                              }),
                ],
                ({tabs, interactions}) => {
                    let isSharedTile = editTileLogic.tile?.value?.isShared;

                    const isNonDataTile = ["SectionTile", "TextLabelTile", "TextTile"].includes(editTileLogic.tile?.value?.$type);

                    return EditTileLayout({
                        interactions,
                        isSharedTile,
                        editing: {
                            label: remoteTile.value.title,
                            title: collection.name,
                            commands: [
                                {
                                    label: "Convert to Shared",
                                    onClick: () => {
                                        convertToShared(collection.gridLocations?.find((t) => t.tile.id === remoteTile.value.id));
                                    },
                                    hide: isSharedTile,
                                },
                                {
                                    label: "Go to Shared Tile View",
                                    onClick: () => {
                                        routing.goto("edit-shared-tile", {sharedTileId: remoteTile.value?.id});
                                    },
                                    hide: !isSharedTile,
                                },
                                {
                                    label: "DeBug",
                                    onClick: () => {
                                        routing.updateParams({tileTab: "fields"});
                                        debugFunction.showDebugUI();
                                    },
                                    hide: isNonDataTile,
                                },
                                {
                                    label: isSharedTile ? "Remove" : "Delete",
                                    className: "delete-action",
                                    onClick: () => removeTile?.(editTileLogic?.tile?.value),
                                    hide: false,
                                },
                            ].filter((item) => !item.hide),
                        },
                        back: {
                            onClick: () => routing.goto("edit-collection"),
                            label: "Done editing tile",
                        },
                        leftPanel: {
                            content: !editTileLogic.tile.value ? LoadingIndicator() : LeftPanel(tabs, leftPanelWidth),
                            width: leftPanelWidth.value,
                        },
                        main: tabs.main(),
                        rightPanel: isSharedTile && {
                            content: interactions.value?.name === "view-collections" && (
                                <ViewCollections onClose={() => interactions.onChange(null)} sharedTile={editTileLogic.tile.value} />
                            ),
                        },
                        syncState: {
                            type: savingQueue.executing ? "saving" : equalDeep(editTileLogic?.tile?.value, remoteTile.value) ? "saved" : "unsaved",
                            onClick: editTileLogic?.form?.showErrors,
                        },
                        collection: {
                            value: remoteCollection.value,
                            onChange: async (newCollection) => {
                                remoteCollection.onChange(newCollection);
                                const newCol = await savingQueue.push(() => newCollection);
                                remoteCollection.onChange(newCol);
                            },
                        },
                    });
                }
            );
        }
    );

export const loadTheme = ({collection, viewAs, apis, next}) =>
    cs(
        [
            "remoteTheme",
            ({}, next) => {
                const themeID = viewAs?.themeID ?? collection?.themeID;
                return Load2({
                    _key: JSON.stringify({id: themeID, apiKey: viewAs?.value}),
                    fetch: () => (!themeID ? null : apis.collection.getTheme(themeID)),
                    next,
                });
            },
        ],
        ["theme", ({remoteTheme}, next) => next(remoteTheme.value)],
        ({theme}, next) => provideContext("theme", theme, next),
        ({theme}) => next(theme)
    );
