import "./tile-fields.scss";

import * as React from "react";

import {cs} from "@common/react/chain-services";
import {provideContext} from "@common/react/context";
import {scope} from "@common/react/scope";
import {Expandable} from "../../../../../../../common/expandable/expandable";
import {getTileActionsUpdated} from "../actions/get-tile-actions-updated";
import {getTileFields} from "./common/get-tile-fields";
import {hasDateGroupsConfiguration} from "./common/has-date-groups-configuration";
import {getLimitUpdated} from "./common/limit-sorting/limit/get-limit-updated";
import {Limit} from "./common/limit-sorting/limit/limit";
import {DimensionlessSorting} from "./common/limit-sorting/sorting/dimensionless/dimensionless-sorting";
import {getDimensionlessSortingUpdated, getDimensionlessSortingUpdatedByLimit} from "./common/limit-sorting/sorting/dimensionless/get-dimensionless-sorting-updated";
import {getColumnType} from "./common/select-field/select-field";
import {SingleFieldWell} from "./common/single-field-well/single-field-well";
import {updateLimitSortingInTile, updateSingleFieldInTile} from "./common/update-tile";

export const PieChartFields = ({tile, isSubTile, tileActions, debugFunction, form, next}) =>
    cs(
        [
            "tileFields",
            ({}, next) =>
                next(
                    getTileFields({
                        tile: tile.value,
                        measureSingleAttrs: ["valueField"],
                        groupFieldAttr: "groupField",
                    })
                ),
        ],
        ({tileFields}, next) => provideContext("tileFields", tileFields, next),

        [
            "valueField",
            ({tileFields}, next) =>
                SingleFieldWell({
                    field: scope(tile, ["valueField"]),
                    $type: "DimensionlessMeasureTileField",
                    onUpdateTile: (newVal, oriVal) =>
                        updateSingleFieldInTile({
                            fieldAttr: "valueField",
                            fieldVal: {newVal, oriVal},
                            tileFields,
                            tile,
                            updateSorting: getDimensionlessSortingUpdated,
                            updateLimit: getLimitUpdated,
                        }),
                    label: "Value",
                    notAllowNoneAggregation: true,
                    next,
                }),
        ],
        [
            "groupField",
            ({tileFields}, next) =>
                SingleFieldWell({
                    field: scope(tile, ["groupField"]),
                    hasDateGroupsConfiguration: hasDateGroupsConfiguration(tile.value.$type),
                    onUpdateTile: (newVal, oriVal) =>
                        updateSingleFieldInTile({
                            fieldAttr: "groupField",
                            fieldVal: {newVal, oriVal},
                            tileFields,
                            tile,
                            updateSorting: getDimensionlessSortingUpdated,
                            updateLimit: getLimitUpdated,
                            updateTileActions: getTileActionsUpdated,
                        }),
                    $type: "CategoryTileField",
                    label: "Group",
                    filterColumn: (column) => column.$type !== "AggregatedMeasureModelColumn",
                    next,
                }),
        ],
        [
            "fieldsSection",
            ({valueField, groupField}, next) =>
                next({
                    render: () =>
                        Expandable({
                            label: "Fields",
                            render: () => (
                                <>
                                    {valueField.render()}
                                    {groupField.render()}
                                </>
                            ),
                        }),
                    override: valueField.override || groupField.override,
                }),
        ],

        [
            "limitSorting",
            ({tileFields}, next) =>
                cs(
                    [
                        "limit",
                        (_, next) => {
                            const formField = form.field(["limit"]);
                            return next(
                                Limit({
                                    field: formField.state,
                                    allFields: tileFields.allFieldsWithoutUniq,
                                    onChange: (newLimit, field) => {
                                        const limit = {
                                            ...newLimit,
                                            direction: field.$type === "DimensionlessMeasureTileField" ? "Asc" : newLimit.direction,
                                        };

                                        updateLimitSortingInTile({
                                            tile,
                                            limit,
                                            updateSorting: () =>
                                                getDimensionlessSortingUpdatedByLimit({
                                                    tile,
                                                    field: {
                                                        ...limit,
                                                        $type: field.$type,
                                                    },
                                                    groupFieldAttr: "groupField",
                                                }),
                                        });
                                    },
                                    isHiddenRankOption: () => false,
                                })
                            );
                        },
                    ],
                    [
                        "sorting",
                        (_, next) =>
                            next(
                                DimensionlessSorting({
                                    fieldGroups: form.field(["sort"]).state,
                                    title: "Sorting on Slices",
                                    allFields: tileFields.allFieldsWithoutUniq,
                                })
                            ),
                    ],
                    ({limit, sorting}) =>
                        next({
                            render: () =>
                                Expandable({
                                    initExpand: false,
                                    label: "Limit & Sorting",
                                    render: () => (
                                        <>
                                            {limit}
                                            {sorting}
                                        </>
                                    ),
                                }),
                        })
                ),
        ],

        ({fieldsSection, limitSorting}) =>
            next({
                render: () => {
                    return (
                        <div className="tile-fields-3vr">
                            {fieldsSection.render()}
                            {limitSorting.render({})}
                            {tileActions?.render?.({})}
                            {isSubTile && debugFunction?.render?.({})}
                        </div>
                    );
                },
                override:
                    fieldsSection.override ||
                    debugFunction?.override() ||
                    tileActions?.override({
                        fieldType: "DimensionlessMeasureTileField",
                    }),
                overrideAll: debugFunction?.overrideAll || tileActions?.overrideAll,
            })
    );
