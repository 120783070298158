const {spc} = require("../../../../react/state-path-change");

const createLoadTileData = ({tileId, collectionId, isShared, apis, loadedTileCounter, refreshKey}) => {
    return async ({filters, page, extraCacheKey, apiKey, timeZoneId}) => {
        spc(loadedTileCounter, ["loadingTiles"], (v) => v + 1);

        let res = await apis.collection.getTileData({
            tileId,
            collectionId,
            isShared,
            filterValues: filters,
            extraCacheKey,
            refreshKey,
            page,
            apiKey,
            timeZoneId,
        });

        spc(loadedTileCounter, ["loadedTiles"], (v) => v + 1);

        return res;
    };
};
exports.createLoadTileData = createLoadTileData;
