import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {DropdownSelectLive} from "@common/ui-components/live/filters/common/dropdown-select/dropdown-select-live";
import {css, cx} from "emotion";
import {FilterSubmitButtons} from "@common/ui-components/charts/table/table/header/filter-types/filter-submit-buttons";
import {NumberInputLive} from "@common/ui-components/live/live-dashboard/live-filters/common/number-input-live/number-input-live";
import {scope} from "@common/react/scope";
import {StyledClass} from "@common/react/styled-class";

const searchTypes = [
    {
        label: "Equal To",
        value: "EqualTo",
    },
    {
        label: "Not Equal To",
        value: "NotEqualTo",
    },
    {
        label: "Greater Than",
        value: "GreaterThan",
    },
    {
        label: "Greater Than or Equal To",
        value: "GreaterThanOrEqualTo",
    },
    {
        label: "Less Than",
        value: "LessThan",
    },
    {
        label: "Less Than or Equal To",
        value: "LessThanOrEqualTo",
    },
    {
        label: "Is Empty",
        value: "IsNull",
    },
];

export const HeaderFilterByNumber = ({search, theme, close, columnIndex, defaultSelected}) =>
    cs(
        [
            "state",
            (_, next) =>
                UseState({
                    next,
                    initValue: {search: defaultSelected?.search || "", searchType: defaultSelected?.searchType ?? searchTypes[0].value},
                }),
        ],
        [
            "cssClass",
            (_, next) => {
                const componentTheme = theme.general.components;
                return StyledClass({
                    next,
                    content: {
                        background: `${componentTheme.menuBackgroundColorRGB}`,
                        color: `${componentTheme.menuTextColorRGB}`,

                        "&:hover": {
                            background: `${componentTheme.menuHoverBackgroundColorRGB}`,
                        },

                        "&.selected": {
                            background: `${componentTheme.menuHoverBackgroundColorRGB}`,
                        },
                    },
                });
            },
        ],
        ({state, cssClass}) => {
            const componentTheme = theme.general.components;

            const inputStyle = {
                border: `${componentTheme.inputBorderWidth}px solid ${componentTheme.inputBorderColorRGB}`,
                background: `${componentTheme.inputBackgroundColorRGB}`,
                color: `${componentTheme.inputTextColorRGB}`,
                borderRadius: `${componentTheme.inputCornerRadius}px`,
            };

            return (
                <>
                    <div className="dropdown-wrapper">
                        <div
                            className="dropdown-label"
                            style={{
                                color: theme.general.components.inputLabelTextColorRGB,
                            }}
                        >
                            Filter By
                        </div>

                        {DropdownSelectLive({
                            className: cx("dropdown-select"),
                            list: searchTypes.map((c) => c.value),
                            valueToLabel: (v) => searchTypes.find((r) => r.value == v)?.label,
                            isSelected: (v) => state.value.searchType == v,
                            onChange: (v) => {
                                state.change((state) => ({
                                    ...state,
                                    searchType: v,
                                }));
                            },
                            toggleStyle: inputStyle,
                            iconColor: componentTheme.inputIconColorRGB,
                            dropdownCss: cssClass,
                            borderRadius: componentTheme.menuCornerRadius,
                        })}

                        {(() =>
                            cs(
                                [
                                    "cssClass",
                                    (_, next) =>
                                        StyledClass({
                                            content: {
                                                width: "100% !important",
                                            },
                                            next,
                                        }),
                                ],
                                ({cssClass}) =>
                                    NumberInputLive({
                                        state: scope(state, ["search"]),
                                        placeholder: "Value",
                                        disabled: state.value.searchType == "IsNull",
                                        className: cx("dropdown-input", cssClass),
                                        inputStyle,
                                    })
                            ))()}
                    </div>

                    <FilterSubmitButtons
                        disabled={(state.value.search || "").length == 0 && state.value.searchType !== "IsNull"}
                        onReset={() => {
                            close?.();
                            search.searchColumn({
                                cIndex: columnIndex,
                                search: null,
                            });
                        }}
                        onSubmit={() => {
                            close?.();
                            search.searchColumn({
                                cIndex: columnIndex,
                                search: state.value.searchType == "IsNull" ? null : state.value.search,
                                searchType: state.value.searchType,
                                $type: "NumericTableSearch",
                            });
                        }}
                    />
                </>
            );
        }
    );
