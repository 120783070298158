import {cs} from "@common/react/chain-services";
import {Load2} from "@common/react/load2";
import {consumeContext} from "@common/react/context";
import {DataTable} from "../../../common/data-table/data-table";
import moment from "moment";

export const ClearedErrors = () =>
    cs(
        consumeContext("apis"),
        consumeContext("routing"),
        [
            "errorHistories",
            ({routing, apis}, next) =>
                Load2({
                    fetch: () => apis.data.getErrorHistories(routing.params.dsId),
                    next,
                }),
        ],
        ({errorHistories}) => {
            return (
                <div>
                    {DataTable({
                        list: errorHistories.value || [],
                        columns: [
                            {
                                label: "Error Date",
                                format: (row) => (
                                    <div>
                                        <div>{moment(new Date(row.identifiedUtc)).format("MMM DD,YYYY")}</div>
                                        <div>{moment(new Date(row.identifiedUtc)).format("hh:mm A")}</div>
                                    </div>
                                ),
                                sortValue: (row) => row.identifiedUtc,
                            },
                            {
                                label: "Source",
                                format: (row) => <strong>{row.dataSourceType}</strong>,
                                sortValue: (row) => row.dataSourceType,
                            },
                            {
                                label: "Error Message",
                                format: (row) => row.message,
                                // sortValue: (row) => row.records
                            },
                        ],
                    })}
                </div>
            );
        }
    );
