import React from "react";
import {cs} from "@common/react/chain-services";
import {ApiFiltersMenu} from "./left-panel/api-filters-menu";
import {consumeContext} from "@common/react/context";
import {AddingFilter} from "../../../../collection/edit/tabs/filters/adding-filter/adding-filter";
import {createAutoSaveCollection} from "../../../../collection/edit/common/auto-save-collection";

export const ApiFiltersTab = ({next, savingQueue}) =>
    cs(
        consumeContext("collection"),
        consumeContext("routing"),
        [
            "addingFilter",
            ({collection}, next) =>
                AddingFilter({
                    next,
                    collection,
                }),
        ],
        ({addingFilter, collection, routing}) => {
            return next({
                renderLeftPanel: () =>
                    ApiFiltersMenu({
                        onAddFilter: () => routing.goto("add-filter"),
                        collection: createAutoSaveCollection({
                            collection,
                            savingQueue,
                        }),
                    }),
                panelOverride: addingFilter,
            });
        }
    );
