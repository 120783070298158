import React from "react";
import {cs} from "@common/react/chain-services";
import {rFieldTypeIcon} from "../../../../common/field-type-icon/get-field-type-icon";
import "./column-select-from-step-output.scss";
import {consumeContext} from "@common/react/context";
import {DropdownSelectSearch} from "@common/ui-components/dropdown-select/dropdown-select-search";

/**
 * to choose a column from outputColumns of a transformation step
 * to choose column through relationships from outputColumns, use column-value-drilling-select-from-step-output.jsx
 */

export const rColumnSelectFromStepOutput =
    ({columns, label, valueToLabel, isUnavailable}) =>
    ({state, ...props}) =>
        cs(consumeContext("getModelColumn"), ({getModelColumn}) => {
            const modelColumns = !columns ? [] : columns.filter((col) => col.$type === "ModelStepColumn").map((col) => getModelColumn(col));

            const isSelected = (column) =>
                ({
                    ViewStepColumn: (v) => v.name === state.value?.name,
                    ModelStepColumn: (v) => v.modelColumnID === state.value?.modelColumnID,
                    GroupAggColumn: (v) => v.name === state.value?.name,
                }[column.$type](column));

            const defaultValueToLabel = (c) => {
                const column = ["ViewStepColumn", "GroupAggColumn"].includes(c.$type) ? c : modelColumns.find((c1) => c1.id === c.modelColumnID);
                return (
                    <div className="column-label">
                        {rFieldTypeIcon(column?.dataType)}
                        <div className="text">
                            {column?.name}
                            {modelColumns.find((c1) => c1.id !== c.modelColumnID && c1.name === column.name) && ` [${column.tableName}]`}
                        </div>
                    </div>
                );
            };

            var colName = (c) => (c.$type === "ViewStepColumn" ? c.name : modelColumns.find((mc) => mc.id === c.modelColumnID).name);

            return (
                <div className="column-select-from-step-output column-select-from-step-output-56g">
                    {DropdownSelectSearch({
                        ...props,
                        label,
                        searchPlaceholder: "Find column",
                        list: columns?.filter((v) => v).sort((a, b) => (colName(a) < colName(b) ? -1 : 1)),
                        valueToLabel: (c) => {
                            if (valueToLabel) {
                                return valueToLabel({
                                    column: c,
                                    getColumnInfo: getModelColumn,
                                    modelColumns,
                                });
                            } else {
                                return defaultValueToLabel(c);
                            }
                        },
                        isSelected,
                        valueToSearch: colName,
                        onChange: (c) => state.onChange(c),
                        isUnavailable,
                    })}
                </div>
            );
        });
