import React from "react";

const ResumeSyncIcon = ({className, fill = "#919EB0"}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="19.869" height="24" viewBox="0 0 19.869 24" fill="currentColor">
        <path
            id="ResumeSync_Icon"
            data-name="ResumeSync Icon"
            d="M-3670.137-1569h0a9.884,9.884,0,0,1-7.034-2.916,9.866,9.866,0,0,1-2.9-7.027,9.869,9.869,0,0,1,2.9-7.028,9.865,9.865,0,0,1,7.025-2.9l0-4.13,6.15,6.15-6.15,6.149v-5.622l-.166,0a7.267,7.267,0,0,0-5.1,2.11,7.4,7.4,0,0,0-2.175,5.271,7.4,7.4,0,0,0,2.175,5.271,7.4,7.4,0,0,0,5.271,2.174,7.4,7.4,0,0,0,5.271-2.174,7.445,7.445,0,0,0,2.176-5.764h2.483a9.86,9.86,0,0,1-.619,3.994,9.87,9.87,0,0,1-2.281,3.526A9.869,9.869,0,0,1-3670.137-1569Z"
            transform="translate(3680.07 1593)"
        />
    </svg>
);

export default ResumeSyncIcon;
