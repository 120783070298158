const {booleanFormatter} = require("../../../factory/formatters/boolean-formatter");
const {roundTo} = require("../../../common/formatters/numeric/round-to");
const {chain} = require("../../../../../utils/fs");
const {getNumericFormatter} = require("../../../factory/formatters/get-numeric-formatter");
const {getFieldType} = require("../../../common/get-field-type");
const {getDateTimeFormatter} = require("../../../factory/formatters/get-date-time-formatter");
const {css} = require("emotion");

const getColumns = ({tile, timezone}) => {
    const fields = [...(tile.actualValueFields || []), ...(tile.aggregatedValues || [])];

    return fields.map((f) => {
        const type = getFieldType(f);
        const dataType = type !== "number" && f.aggregationFunc?.toLowerCase().startsWith("count") ? "number" : type;
        const formatter = getFieldFormatter(f, timezone, tile);

        return {
            field_id: f.id,
            type: dataType,
            formatter: (v) => (typeof v != "object" && v != null ? formatter(v) : " "),
            width: `${tile.style?.columnCustomWidths?.[f.id]}%`,
            columnSettings: tile.style?.columnSettings?.[f.id],
        };
    });
};
exports.getColumns = getColumns;

const getFieldFormatter = (field, timezone, tile) => {
    const type = getFieldType(field);
    const dataType = type !== "number" && field.aggregationFunc?.toLowerCase().startsWith("count") ? "number" : type;

    return (() => {
        if (dataType === "date") {
            return getDateTimeFormatter(field.dateProperties, timezone);
        }

        if (dataType === "number") {
            const numericProperties = field.numericProperties || {};
            const numericFormatter = getNumericFormatter({
                numericProperties: {
                    ...numericProperties,
                    currencyOverrides: tile?.currencyOverrides,
                },
                useDefaultCountFormat: field.aggregationFunc?.startsWith("Count"),
            });

            return (v) =>
                v != null &&
                chain(
                    v,
                    (v) => (numericProperties?.displayType === "Percentage" ? v * 100 : v),
                    (v) => (numericProperties?.decimalPlaces != null ? roundTo(v, numericProperties.decimalPlaces) : roundTo(v, 2)),
                    (v) => numericFormatter(v)
                );
        }

        if (dataType === "text") {
            return (v) => v;
        }

        if (dataType === "bool") {
            return booleanFormatter;
        }
    })();
};

exports.getFieldFormatter = getFieldFormatter;
