const {FComponent} = require("./f-component");
const {createElement: h} = require("react");

const Interval = ({fn, delay = 500, next}) => h(Interval1, {fn, delay, next});
exports.Interval = Interval;

class Interval1 extends FComponent {
    constructor(props, context) {
        super(props, context);

        this.onMount(() => {
            const interval = setInterval(() => this.props.fn(), props.delay);
            this.onUnmount(() => clearInterval(interval));
        });

        if (props.startImmediately) {
            this.props.fn();
        }
    }

    render() {
        const {next} = this.props;

        return (next && next()) || null;
    }
}
