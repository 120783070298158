import "./date-field.scss";

import * as React from "react";

import {scope} from "@common/react/scope";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {spc} from "@common/react/state-path-change";

import {stateToSelect} from "@common/ui-components/form/state-to-select";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {createDateFormatter} from "@common/ui-components/charts/common/formatters/date-formatter";

import {getPath} from "@common/utils/arr-path";
import {TextInput} from "../../../../../../../../../../../../../../common/form/text-input/text-input";
import {getTextAggregationFuncs} from "../../../../../../../../../../../common/aggregation-funcs/text-aggregation-funcs";
import {FieldInfoProvider} from "../../../../../../tile-tab/chart-types/common/field-info-provider/field-info-provider";

import {FieldSection} from "../../../field-section/field-section";
import {dateFormats} from "../../field-format/date-time/date-formats";
import {SelectNumberFormat} from "../number/select-number-format";
import {NumberFieldPreview} from "../number/number-field-preview";
import {dateAggregations} from "./date-aggregations";
import {timeAggregations} from "./time-aggregations";

export const dateAggregates = [
    {
        label: "Auto",
        value: "Auto",
    },
    ...dateAggregations,
    ...timeAggregations,
    {
        label: "None",
        value: "None",
    },
];

export const dateAggregatesWithoutNone = [
    {
        label: "Auto",
        value: "Auto",
    },
    ...dateAggregations,
    ...timeAggregations,
];

export const DateField = ({field, onAddFormat, hideAggregationSelect, notAllowNoneAggregation, customListAgg, allowAggregations}) =>
    cs(() => {
        if (field.value.$type === "MeasureTileField") {
            return (
                <>
                    {field.value.aggregationFunc?.startsWith("Count") && NumberFieldPreview({field})}

                    {FieldSection({
                        header: FieldInfoProvider({
                            field: <div className="text">FORMATTING</div>,
                        }),
                        content: (
                            <div className="date-field-2va">
                                {DropdownSelect({
                                    label: "Aggregate",
                                    list:
                                        customListAgg?.() ??
                                        getTextAggregationFuncs({
                                            notAllowNone: true,
                                        }),
                                    valueToLabel: (v) => v.label,
                                    ...stateToSelect(scope(field, ["aggregationFunc"]), ["value"]),
                                })}
                                {field.value.aggregationFunc?.startsWith("Count") && SelectNumberFormat({field, onAddFormat})}
                            </div>
                        ),
                    })}
                </>
            );
        } else {
            const aggregation = getPath(field.value, ["dateProperties", "aggregation"]);
            const belongsToDate = (value) => dateAggregations.map((v) => v.value).includes(value || "Days");
            const formatList = dateFormats;
            const defaultFormat = belongsToDate(aggregation) ? "M/d/yy" : "h:mm tt";
            const notAllowNone = notAllowNoneAggregation || field.value.$type == "DimensionlessMeasureTileField";

            return (
                <>
                    {FieldSection({
                        header: FieldInfoProvider({
                            field: <div className="text">PREVIEW</div>,
                        }),
                        content: (
                            <div className="preview-field-a33">
                                <div className="preview-text">{createDateFormatter(field.value.dateProperties?.format || defaultFormat).format(new Date(2025, 8, 30, 17, 45))}</div>

                                <div className="sample-data">
                                    <div className="sample-label">SAMPLE DATA:</div>

                                    <div className="sample-value">{createDateFormatter("dd/MM/yyyy HH:mm:ss").format(new Date(2025, 8, 30, 17, 45))}</div>
                                </div>
                            </div>
                        ),
                    })}

                    {(!hideAggregationSelect || ["DateTime", "DateTimeOffset"].includes(field.value.dataType)) &&
                        FieldSection({
                            header: FieldInfoProvider({
                                field: <div className="text">FORMATTING</div>,
                            }),
                            content: (
                                <div className="date-field-2va">
                                    {(allowAggregations || ["DateTime", "DateTimeOffset"].includes(field.value.dataType)) &&
                                        DropdownSelect({
                                            label: "Aggregate",
                                            list: customListAgg?.() ?? (notAllowNone ? dateAggregatesWithoutNone : dateAggregates),
                                            valueToLabel: (v) => v.label,
                                            isSelected: (v) => v.value === (aggregation || "Days"),
                                            onChange: (v) =>
                                                spc(field, ["dateProperties"], () => ({
                                                    aggregation: v.value,
                                                    format: v.value === "None" ? "M/d/yy|h:mm tt" : v.format,
                                                })),
                                        })}
                                    {(() => {
                                        return cs(
                                            [
                                                "showCustomFormat",
                                                (_, next) =>
                                                    UseState({
                                                        next,
                                                        initValue: !formatList.find((f) => f.format == field.value.dateProperties?.format),
                                                    }),
                                            ],
                                            ({showCustomFormat}) => (
                                                <>
                                                    {field.value?.dateProperties?.aggregation != "Auto" &&
                                                        DropdownSelect({
                                                            label: "Format",
                                                            list: [
                                                                ...formatList,
                                                                {
                                                                    label: "Custom Format",
                                                                    customFormat: true,
                                                                },
                                                            ],
                                                            valueToLabel: (v) => v.label,
                                                            isSelected: (v) => {
                                                                if (showCustomFormat.value) {
                                                                    return v.customFormat;
                                                                }

                                                                return v.format === (field.value.dateProperties?.format || defaultFormat);
                                                            },
                                                            onChange: (v) => {
                                                                if (v.customFormat) {
                                                                    showCustomFormat.onChange(true);
                                                                } else {
                                                                    showCustomFormat.onChange(false);
                                                                    spc(field, ["dateProperties", "format"], () => v.format);
                                                                }
                                                            },
                                                        })}

                                                    {field.value?.dateProperties?.aggregation != "Auto" &&
                                                        showCustomFormat.value &&
                                                        TextInput({
                                                            label: "Custom Format",
                                                            info: (
                                                                <div className="more-tooltip-option-ab33">
                                                                    <div>M: Month</div>
                                                                    <div>d: Short Date</div>
                                                                    <div>yyyy: year</div>
                                                                </div>
                                                            ),
                                                            tooltipWidth: 168,
                                                            allowMoveOverTooltip: true,
                                                            state: scope(field, ["dateProperties", "format"]),
                                                        })}
                                                </>
                                            )
                                        );
                                    })()}
                                </div>
                            ),
                        })}
                </>
            );
        }
    });
