import {ShowHideToggle} from "../../../../../../../../../../../../common/form/toggles/show-hide-toggle";
import {scope} from "@common/react/scope";
import {getPath} from "@common/utils/arr-path";
import {isChartTitleHidden} from "@common/ui-components/charts/chart-layout/chart-title-display-rule";
import {ValueChipInput} from "../../../../fields-tab/actions/add/common/value-chip-input";
import {TextInput} from "../../../../../../../../../../../../common/form/text-input/text-input";
import {ffToTextInput} from "../../../../../../../../../../../../common/form/ff-to-text-input";
import {HiddenFieldAlert} from "../hidden-field-alert/hidden-field-alert";
import {TextareaInput} from "../textarea-input/textarea-input";
import * as React from "react";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {ffToDropdown} from "../../../../../../../../../../../../common/form/ff-to-dropdown";
import {WysiwygEditor} from "../../../../../../../../../common/wysiwyg-editor/wysiwyg-editor";

export const TextTileContent = ({form}) => ({
    label: "Content",
    render: () => {
        return (
            <div className="table-columns-35g">
                <div
                    className="panel"
                    style={{
                        paddingLeft: 17,
                    }}
                >
                    {WysiwygEditor({
                        state: form.field(["content"]).state,
                    })}
                </div>
            </div>
        );
    },
});
