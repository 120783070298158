import React from "react";
import {cs} from "../../react/chain-services";
import {UseState} from "../../react/use-state";
import {Dropdown} from "../dropdown/dropdown";
import {cx} from "emotion";
import "./dropdown-select-search.scss";
import {SearchInput} from "../../form/search-input/search-input";
import {VerbScrollbar} from "../verb-scrollbar/verb-scrollbar";
import {isMatchText} from "../../utils/strings";
import {DropdownVirtualizedList} from "@common/ui-components/dropdown-select/dropdown-virtualized-list";
import {IgnoreUpdate} from "@common/react/ignore-update";

export const DropdownSelectSearch = ({label, searchPlaceholder, list, valueToLabel = (v) => v, valueToSearch = (v) => v, onChange, isSelected, isUnavailable = (v) => false, outOfListChoice, hasError, domRef, errorMessage, tabIndex}) =>
    cs(["search", (_, next) => UseState({next})], ({search}) => {
        const chosenIndex = list?.findIndex(isSelected);

        const renderToggle = ({showExpand, showingExpand}) => (
            <div
                className={cx(
                    "toggle",
                    {expanding: showingExpand},
                    {
                        "with-value": chosenIndex > -1 || outOfListChoice?.isSelected?.(),
                    }
                )}
                onClick={() => showExpand(!showingExpand)}
            >
                {label && <div className="label">{label}</div>}

                <span className="item-render">
                    {chosenIndex > -1 && valueToLabel(list[chosenIndex])}
                    {outOfListChoice?.isSelected?.() && outOfListChoice.valueLabel}
                </span>

                <i className="fa fa-chevron-down" />
            </div>
        );

        const renderExpand = ({close, width}) => {
            const filteredList = list.filter((l) => isMatchText(valueToSearch(l), search.value));
            return (
                <>
                    <div className="search">
                        <SearchInput
                            {...{
                                state: search,
                                // label: "Search",
                                placeholder: searchPlaceholder ?? "",
                                autoFocus: true,
                            }}
                        />
                    </div>

                    <VerbScrollbar className="list" maxHeight={300} style={{minWidth: width}}>
                        {list?.map((l, i) => {
                            if (isMatchText(valueToSearch(l), search.value)) {
                                return (
                                    <div
                                        key={i}
                                        className={cx("item", {
                                            selected: chosenIndex === i,
                                            unavailable: isUnavailable(l),
                                        })}
                                        onClick={() => {
                                            onChange(l);
                                            close();
                                        }}
                                    >
                                        {valueToLabel(l)}
                                    </div>
                                );
                            }

                            return null;
                        })}
                    </VerbScrollbar>
                    {outOfListChoice && (
                        <div
                            className="out-of-list-choice"
                            onClick={() => {
                                outOfListChoice.onChange();
                                close();
                            }}
                        >
                            {outOfListChoice.label}
                        </div>
                    )}
                </>
            );
        };

        return (
            <div
                className={cx("dropdown-select-search dropdown-select-search-rd3", {
                    hasError,
                })}
                ref={domRef}
                {...{tabIndex}}
            >
                {Dropdown({
                    renderToggle,
                    minExpandHeight: 300,
                    renderExpand,
                })}

                {hasError && errorMessage && <div className="error-message">{errorMessage}</div>}
            </div>
        );
    });
