import "./url-template.scss";

import React from "react";
import {cx} from "emotion";
import Quill from "quill";
import "quill-mention";

import {cs} from "@common/react/chain-services";
import {scope} from "@common/react/scope";
import {Static2} from "@common/react/static-2";
import {SearchInput} from "../../../../../../../../../../../../../common/form/search-input/search-input";
import {UseState} from "@common/react/use-state";
import {consumeContext} from "@common/react/context";
import {flatten1} from "@common/utils/collections";
import {keyed} from "@common/react/keyed";
import {isEmpty, keepOnly, pick} from "@common/utils/objects";
import {chain} from "@common/utils/fs";
import {rFieldTypeIcon} from "../../../../../../../../../../common/field-type-icon/get-field-type-icon";
import {getFieldType} from "@common/ui-components/charts/common/get-field-type";
import {Dropdown} from "@common/ui-components/dropdown/dropdown";
import {renderCompInRegistry} from "@common/ui-components/registry/common-registry";
import {Invoke} from "@common/react/invoke";
import {GlobalKeyDown} from "@common/react/keys/global-key-down";
import {Watch} from "@common/react/watch";
import {getTileFieldFromColumn} from "../js-parameters/js-parameters";
import {debounce} from "@common/utils/debounce";
import {isMatchText} from "@common/utils/strings";

const rAddingUrlTemplateGuide = ({adding, style}) => (
    <div className="adding-url-template-blank-42e">
        <div className={cx("guide", {"select-field": adding})} style={style}>
            <div className="line">
                <div className="point" />
            </div>
            <div className="content">Select available field parameters</div>
        </div>
    </div>
);

const isAllowedColumn = (c) => {
    // if (!tileFields?.allFields?.find(f => f.modelColumnID == c.id)) {
    //     return false;
    // }
    const columnType = getFieldType(c);
    return columnType !== "date";
};

export const UrlContentEditable = ({tileAction, columns, fieldType, quillRef, onShowPopup}) =>
    cs(({}) => {
        const urlTemplate = scope(tileAction, ["urlTemplate"]);
        return (
            <>
                {Invoke({
                    props: {tileAction},
                    onMounted: ({getLatestProps}) => {
                        let quillEditable = new Quill("#url-template", {
                            placeholder: "https://youtube.com",
                            modules: {
                                mention: {
                                    allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
                                    mentionDenotationChars: ["{"],
                                    positioningStrategy: "fixed",
                                    source: function (searchTerm, renderList, mentionChar) {},
                                },
                            },
                        });

                        quillRef.set(quillEditable);

                        const keyboard = quillEditable.getModule("keyboard");
                        keyboard.addBinding({key: 219, shiftKey: true}, (range) => {
                            onShowPopup();
                            return false; // return false will prevent other listeners from receiving the event
                        });
                        // TODO: add hover to chip
                        // window.addEventListener('mention-hovered', (event) => {console.log('hovered: ', event)}, false);

                        urlTemplate.value && quillEditable.setContents(JSON.parse(urlTemplate.value));

                        const listener = debounce((contents) => {
                            const {tileAction: latestTileAction} = getLatestProps();

                            latestTileAction.change((old) => ({
                                ...old,
                                urlTemplate: JSON.stringify(contents),
                                tileActionFields: (contents.ops || [])
                                    .filter((o) => o?.insert?.mention?.type === "column")
                                    .map((o) => {
                                        const {modelColumnID} = o?.insert?.mention ?? {};
                                        const column = columns.find((c2) => c2.modelColumnID === modelColumnID);
                                        if (column) {
                                            return getTileFieldFromColumn(column, fieldType);
                                        }
                                        return null;
                                    })
                                    .filter((v) => v),
                            }));
                        }, 1000);

                        quillEditable.on("text-change", (delta, oldDelta, source) => {
                            if (source === "user") {
                                const contents = quillEditable.getContents();
                                // const {ops = []} = contents;
                                listener(contents);
                            }
                        });
                    },
                })}

                <div id="url-template" />
            </>
        );
    });

export const UrlTemplate = ({tileAction, fieldType, tablesInTile, tileFields}) =>
    cs(
        consumeContext("modelForCollection"),
        ["quillRef", (_, next) => Static2({next})],
        ["urlTemplate", (_, next) => UseState({next})],
        [
            "insertItem",
            ({quillRef}, next) =>
                next((item) => {
                    let quillEditable = quillRef.get();
                    if (quillEditable) {
                        let data = {
                            denotationChar: "{",
                            id: item.id,
                            value: item.value,
                            type: item.type ?? "column",
                        };

                        if (data.type == "column") {
                            data = {
                                ...data,
                                ...pick(item.column, ["modelColumnID", "modelID", "modelTableID"]),
                            };
                        }

                        quillEditable.getModule("mention").insertItem(data, true);
                    }
                }),
        ],

        ["curlyBracketRef", (_, next) => Static2({next})],
        ["showTooltip", (_, next) => UseState({initValue: false, next})],
        [
            "fieldDropdown",
            ({quillRef, insertItem, curlyBracketRef, showTooltip, modelForCollection}, next) =>
                Dropdown({
                    next,
                    registryRender: true,
                    // useTogglePosition: true,
                    customToggleRef: curlyBracketRef,
                    renderExpand: ({close}) => {
                        return (
                            <>
                                {GlobalKeyDown({
                                    keyCombo: "Escape",
                                    onKeyDown: () => {
                                        let quillEditable = quillRef.get();
                                        quillEditable?.focus();
                                        close();
                                    },
                                })}

                                {cs(
                                    consumeContext("authEndpoint"),
                                    ["search", (_, next) => UseState({next})],
                                    ["selected", (_, next) => UseState({initValue: null, next})],
                                    ["focusIdx", (_, next) => UseState({initValue: null, next})],
                                    ({search, authEndpoint, selected, focusIdx}) => {
                                        const authProperties = flatten1(
                                            authEndpoint.value.map((ap) =>
                                                Object.keys(ap.resultMapping)
                                                    .filter((key) => ap.resultMapping[key].mappingType === "SingleValue")
                                                    .map((key) => ({
                                                        name: key,
                                                        value: ap.resultMapping[key],
                                                        type: "authParameters",
                                                    }))
                                            )
                                        );

                                        const tables = (modelForCollection?.tables || []).filter((t) => tablesInTile.includes(t.id));

                                        const renderList = () => (
                                            <>
                                                <div className="label">Auth Properties</div>

                                                <div className="list">
                                                    {authProperties.map((ap) => (
                                                        <div
                                                            className="item"
                                                            onClick={() =>
                                                                insertItem({
                                                                    id: ap.name,
                                                                    value: ap.name,
                                                                    type: ap.type,
                                                                })
                                                            }
                                                        >
                                                            {ap.name}
                                                        </div>
                                                    ))}
                                                </div>

                                                <div className="separator" />

                                                {cs(
                                                    (_, next) =>
                                                        selected.value?.id ? (
                                                            <>
                                                                <div className="back-to-list" onClick={() => selected.onChange(null)}>
                                                                    <i className="far fa-angle-left" />
                                                                    <div className="name">{selected.value.name}</div>
                                                                </div>

                                                                <div className="list">
                                                                    {chain(
                                                                        tables,
                                                                        (_) => _.find((t) => t.id === selected.value?.id),
                                                                        (_) => _.columns.filter(isAllowedColumn)
                                                                    ).map((column, i) =>
                                                                        cs(keyed(i), () => (
                                                                            <div
                                                                                className="item"
                                                                                onClick={() => {
                                                                                    insertItem({
                                                                                        id: column.id,
                                                                                        column,
                                                                                        value: `${selected.value.name}.${column.name}`,
                                                                                        type: "column",
                                                                                    });
                                                                                }}
                                                                            >
                                                                                {rFieldTypeIcon(column.dataType)}
                                                                                <div className="name">{column.name}</div>
                                                                            </div>
                                                                        ))
                                                                    )}
                                                                </div>
                                                            </>
                                                        ) : (
                                                            next()
                                                        ),
                                                    () => (
                                                        <>
                                                            <div className="label">All Fields</div>

                                                            <div className="list">
                                                                {tables
                                                                    .filter((t) => t.columns.filter(isAllowedColumn).length > 0)
                                                                    .map((table, i) =>
                                                                        cs(keyed(i), () => (
                                                                            <div className="item" onClick={() => selected.onChange(keepOnly(table, ["name", "id"]))}>
                                                                                <img src={require("../../../../../../../../../../common/icons/data-source-table-icon.svg")} />
                                                                                <div className="name">{table.name}</div>
                                                                                <i className="far fa-angle-right" />
                                                                            </div>
                                                                        ))
                                                                    )}
                                                            </div>
                                                        </>
                                                    )
                                                )}
                                            </>
                                        );

                                        const getSearchesList = () =>
                                            chain(
                                                tables,
                                                (_) =>
                                                    _.map((t) =>
                                                        t.columns
                                                            .filter((c) => isMatchText(c.name, search.value) && isAllowedColumn(c))
                                                            .map((c) => ({
                                                                ...c,
                                                                tableName: t.name,
                                                                type: "column",
                                                            }))
                                                    ),
                                                (_) => [...authProperties.filter((a) => isMatchText(a.name, search.value)), ...flatten1(_)]
                                            );

                                        const renderSearches = () => (
                                            <>
                                                <div className="list">
                                                    {getSearchesList().map((item, i) => {
                                                        return (
                                                            <div
                                                                className={cx("item", {
                                                                    focused: focusIdx.value === i,
                                                                })}
                                                                key={i}
                                                                onClick={() => {
                                                                    insertItem({
                                                                        id: item.id,
                                                                        column: item,
                                                                        value: `${item.tableName}.${item.name}`,
                                                                        type: item.type,
                                                                    });
                                                                }}
                                                            >
                                                                {item.dataType && rFieldTypeIcon(item.dataType)}
                                                                <div className="name">{item.name}</div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </>
                                        );

                                        return (
                                            <div className="url-template-popup-99o">
                                                {SearchInput({
                                                    autoFocus: true,
                                                    state: search,
                                                    placeholder: "Search for fields, authentication profiles...",
                                                    delay: 300,
                                                    onKeyDown: (e) => {
                                                        if (search.value) {
                                                            if (e.key === "ArrowDown") {
                                                                e.preventDefault();
                                                                const list = getSearchesList();
                                                                focusIdx.change((v) => (v === null ? 0 : (v + 1) % list.length));
                                                            } else if (e.key === "ArrowUp") {
                                                                e.preventDefault();
                                                                const list = getSearchesList();
                                                                focusIdx.change((v) => (v === null ? 0 : (v - 1 + list.length) % list.length));
                                                            }
                                                        }
                                                    },
                                                })}

                                                {search.value ? renderSearches() : renderList()}
                                            </div>
                                        );
                                    }
                                )}
                            </>
                        );
                    },
                }),
        ],
        ({curlyBracketRef, quillRef, showTooltip, modelForCollection, fieldDropdown}) => {
            let quillEditable = quillRef.get();
            const tables = (modelForCollection?.tables || []).filter((t) => tablesInTile.includes(t.id));
            let columns = chain(tables, (_) => flatten1(_, (t) => t.columns.filter(isAllowedColumn)));

            return (
                <div className="url-template-88p">
                    {UrlContentEditable({
                        tileAction,
                        columns,
                        fieldType,
                        quillRef,
                        onShowPopup: () => fieldDropdown.showExpand(),
                    })}

                    <div
                        className="curly-bracket"
                        ref={curlyBracketRef.set}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            fieldDropdown.showingExpand ? fieldDropdown.closeDropdown() : fieldDropdown.showExpand();
                        }}
                    >{`{ }`}</div>

                    {Watch({
                        value: quillEditable && quillEditable.hasFocus() && isEmpty(tileAction.value.urlTemplate),
                        onChanged: (value) => (value ? fieldDropdown.showExpand() : fieldDropdown.closeDropdown()),
                    })}

                    {showTooltip.value &&
                        cs(() => {
                            const rect = curlyBracketRef.get().getBoundingClientRect();
                            const style = {
                                top: rect.top - 8,
                                left: rect.left + 12,
                            };
                            return renderCompInRegistry({
                                comp: rAddingUrlTemplateGuide({style}),
                            });
                        })}
                </div>
            );
        }
    );
