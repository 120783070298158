export const DaterangeIcon = ({fill = "#919EB0", style = {}}) => (
    <svg width="28" height="27" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
        <path
            d="M20.6667 3.00065H26C26.3536 3.00065 26.6928 3.14113 26.9428 3.39118C27.1929 3.64122 27.3334 3.98036 27.3334 4.33398V25.6673C27.3334 26.0209 27.1929 26.3601 26.9428 26.6101C26.6928 26.8602 26.3536 27.0006 26 27.0006H2.00002C1.6464 27.0006 1.30726 26.8602 1.05721 26.6101C0.807163 26.3601 0.666687 26.0209 0.666687 25.6673V4.33398C0.666687 3.98036 0.807163 3.64122 1.05721 3.39118C1.30726 3.14113 1.6464 3.00065 2.00002 3.00065H7.33335V0.333984H10V3.00065H18V0.333984H20.6667V3.00065ZM24.6667 13.6673H3.33335V24.334H24.6667V13.6673ZM18 5.66732H10V8.33398H7.33335V5.66732H3.33335V11.0007H24.6667V5.66732H20.6667V8.33398H18V5.66732ZM6.00002 16.334H8.66669V19.0007H6.00002V16.334ZM12.6667 16.334H15.3334V19.0007H12.6667V16.334ZM19.3334 16.334H22V19.0007H19.3334V16.334Z"
            fill={fill}
        />
    </svg>
);

export const TextUuidsIcon = ({fill = "#919EB0", style = {}}) => (
    <svg width="20" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
        <path d="M11.3334 3.00065V23.0007H8.66669V3.00065H0.666687V0.333984H19.3334V3.00065H11.3334Z" fill={fill} />
    </svg>
);

export const NumberIcon = ({fill = "#919EB0", style = {}}) => (
    <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
        <path
            d="M5.37865 14.6667L5.93865 9.33333H0.333313V6.66667H6.21998L6.91998 0H9.60131L8.90131 6.66667H14.22L14.92 0H17.6013L16.9013 6.66667H21.6666V9.33333H16.6213L16.0613 14.6667H21.6666V17.3333H15.78L15.08 24H12.3986L13.0986 17.3333H7.77998L7.07998 24H4.39865L5.09865 17.3333H0.333313V14.6667H5.37865ZM8.05998 14.6667H13.38L13.94 9.33333H8.61998L8.05998 14.6667Z"
            fill={fill}
        />
    </svg>
);

export const BooleanIcon = ({fill = "#919EB0", style = {}}) => (
    <svg width="30" height="20" viewBox="0 0 30 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
        <path
            d="M9.66671 3.33268C7.8986 3.33268 6.2029 4.03506 4.95266 5.2853C3.70242 6.53555 3.00004 8.23124 3.00004 9.99935C3.00004 11.7675 3.70242 13.4632 4.95266 14.7134C6.2029 15.9636 7.8986 16.666 9.66671 16.666H20.3334C22.1015 16.666 23.7972 15.9636 25.0474 14.7134C26.2977 13.4632 27 11.7675 27 9.99935C27 8.23124 26.2977 6.53555 25.0474 5.2853C23.7972 4.03506 22.1015 3.33268 20.3334 3.33268H9.66671ZM9.66671 0.666016H20.3334C22.8087 0.666016 25.1827 1.64935 26.933 3.39969C28.6834 5.15003 29.6667 7.524 29.6667 9.99935C29.6667 12.4747 28.6834 14.8487 26.933 16.599C25.1827 18.3494 22.8087 19.3327 20.3334 19.3327H9.66671C7.19135 19.3327 4.81738 18.3494 3.06704 16.599C1.3167 14.8487 0.333374 12.4747 0.333374 9.99935C0.333374 7.524 1.3167 5.15003 3.06704 3.39969C4.81738 1.64935 7.19135 0.666016 9.66671 0.666016ZM9.66671 13.9993C8.60584 13.9993 7.58843 13.5779 6.83828 12.8278C6.08813 12.0776 5.66671 11.0602 5.66671 9.99935C5.66671 8.93848 6.08813 7.92107 6.83828 7.17092C7.58843 6.42078 8.60584 5.99935 9.66671 5.99935C10.7276 5.99935 11.745 6.42078 12.4951 7.17092C13.2453 7.92107 13.6667 8.93848 13.6667 9.99935C13.6667 11.0602 13.2453 12.0776 12.4951 12.8278C11.745 13.5779 10.7276 13.9993 9.66671 13.9993Z"
            fill={fill}
        />
    </svg>
);
