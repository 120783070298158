import "./filters-menu.scss";

import * as React from "react";

import {cs} from "@common/react/chain-services";
import {Expandable} from "../../../../../common/expandable/expandable";
import {FiltersInCollection} from "./filters-in-collection/filters-in-collection";

import {FiltersDisplay} from "./display/filters-display";
import {scope} from "@common/react/scope";
import {spc} from "@common/react/state-path-change";
import {LpShowHideLine} from "../../../../../common/left-panel/lp-show-hide-line";

import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {consumeContext} from "@common/react/context";
import {getCurrentTzName} from "@common/utils/dates/date-object";

export const FiltersMenu = ({collection, onAddFilter}) =>
    cs(consumeContext("selectedTimezone"), ({selectedTimezone}) => {
        const {defaultTimezoneToUtc, showPdfDownload, pdfDownloadIncludeFilter} = collection.value;
        return (
            <div className="filters-menu-4xm">
                {/*<div className="add-new-filter">*/}
                {/*    <div className="btn" onClick={onAddFilter}>*/}
                {/*        Add New Filter*/}
                {/*    </div>*/}
                {/*</div>*/}

                <Expandable
                    {...{
                        label: "Filters in collection",
                        render: () => FiltersInCollection({collection, onAddFilter}),
                    }}
                />

                {Expandable({
                    label: "Display",
                    render: () => FiltersDisplay({collection}),
                })}

                {Expandable({
                    label: "Time Zone Display",
                    render: () => (
                        <div className="filters-display-8wq">
                            <div className="embedded-filters">
                                {LpShowHideLine({
                                    label: "Time Zone Conversion",
                                    state: scope(collection, ["showTimezoneConversion"]),
                                })}
                                {collection.value.showTimezoneConversion &&
                                    DropdownSelect({
                                        className: "default-time-zone",
                                        label: "Default Time Zone",
                                        list: [
                                            {
                                                timezoneValue: getCurrentTzName(),
                                                value: false,
                                                label: "Local Browser Time Zone",
                                            },
                                            {
                                                timezoneValue: null,
                                                value: true,
                                                label: "UTC",
                                            },
                                        ],
                                        valueToLabel: (v) => v.label,
                                        isSelected: (v) => defaultTimezoneToUtc == v.value,
                                        onChange: (v) => {
                                            collection.change((c) => ({
                                                ...c,
                                                defaultTimezoneToUtc: v.value,
                                            }));
                                            selectedTimezone.onChange(v.timezoneValue);
                                        },
                                    })}
                            </div>
                        </div>
                    ),
                })}

                {Expandable({
                    label: "Download Options",
                    render: () => (
                        <div className="filters-display-8wq">
                            <div className="embedded-filters">
                                {LpShowHideLine({
                                    label: "Download Full Dashboard PDF",
                                    state: scope(collection, ["showPdfDownload"]),
                                })}

                                {showPdfDownload && (
                                    <>
                                        <br />
                                        {DropdownSelect({
                                            className: "with-filters",
                                            label: "Filter View",
                                            list: [
                                                {
                                                    value: false,
                                                    label: "Do Not Include Filters",
                                                },
                                                {
                                                    value: true,
                                                    label: "Include Filters",
                                                },
                                            ],
                                            valueToLabel: (v) => v.label,
                                            isSelected: (v) => (!pdfDownloadIncludeFilter ? false == v.value : pdfDownloadIncludeFilter == v.value),
                                            onChange: (v) =>
                                                collection.change((c) => ({
                                                    ...c,
                                                    pdfDownloadIncludeFilter: v.value,
                                                })),
                                        })}
                                    </>
                                )}
                            </div>
                        </div>
                    ),
                })}

                {/*<Expandable {...{*/}
                {/*    label: "Labels",*/}
                {/*    render: () => FiltersLabels({collection})*/}
                {/*}}/>*/}

                {/*<Expandable {...{*/}
                {/*    label: "Function",*/}
                {/*    render: () => FiltersFunction({collection})*/}
                {/*}}/>*/}
            </div>
        );
    });
