import "./collection-panel.scss";

import * as React from "react";

import {Load2} from "@common/react/load2";
import {Static2} from "@common/react/static-2";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {OnMounted} from "@common/react/on-mounted";
import {spc} from "@common/react/state-path-change";
import {OnUnmounted} from "@common/react/on-unmounted";
import {consumeContext, provideContext} from "@common/react/context";

import {ChartDraw} from "@common/ui-components/charts/chart-draw";
import {ContainerTile} from "@common/ui-components/container-tile/container-tile";
import {LoadingIndicator} from "@common/ui-components/loading-indicator/loading-indicator";
import {DashboardData} from "@common/ui-components/live/live-dashboard/data/dashboard-data";
import {LiveFilters} from "@common/ui-components/live/live-dashboard/live-filters/live-filters";
import {createLoadTileData} from "@common/ui-components/live/live-dashboard/data/load-tile-data";
import {createDownloadTileData} from "@common/ui-components/live/live-dashboard/data/download-tile-data";
import {createLoadTileData2} from "@common/ui-components/live/live-dashboard/data/load-tile-data-2";
import {AutoRefreshController} from "@common/ui-components/live/live-dashboard/live-grid/auto-refresh-controller";

import {chain} from "@common/utils/fs";
import {sortMulti} from "@common/utils/collections";
import {isDevQuan} from "../../../../../../../../tools/dev/is-dev-quan";

import {FlapFilters} from "../../../../filter/flap-filters/flap-filters-dialog";
import {DnDGridPanel, DummyReportTileID} from "../../../../common/dnd-grid-panel/dnd-grid-panel";
import {CONTAINER_TILE_MIN_HEIGHT, CONTAINER_TILE_MIN_WIDTH} from "../../../../container-tile/container-tile-constants";
import {ProvideDefaultTimezone} from "../../../../common/provide-default-timezone";
import {toastServiceDownload} from "@common/ui-components/toast/toast-service-download";
import {Invoke} from "@common/react/invoke";
import {recentStorage} from "../../../../../dashboard/env/recent-section/recent-storage";
import {omit} from "@common/utils/objects";
import {ConvertToSharedTileModal} from "../../../../../common/convert-to-shared-tile-modal/convert-to-shared-tile-modal";

export const CollectionPanel = ({collection, getMinHeight, next}) =>
    cs(
        consumeContext("collectionToast"),
        consumeContext("viewAsContext"),
        ["draggingState", (_, next) => UseState({next})],
        ["viewAs", ({viewAsContext}, next) => next(viewAsContext?.viewAs?.value ?? null)],
        (_, next) =>
            ProvideDefaultTimezone({
                next,
                timezone: null,
                defaultTimezoneToUtc: !collection.value.showTimezoneConversion ? true : collection.value.defaultTimezoneToUtc,
            }),

        ({draggingState, collectionToast, viewAs}) =>
            next({
                startDraggingNewTile: ({tile, pos}) => draggingState.onChange({draggingNew: {tile, pos}}),
                render: () =>
                    cs(
                        consumeContext("routing"),
                        consumeContext("apis"),
                        consumeContext("setContainerBackground"),
                        [
                            "remoteTheme",
                            ({apis}, next) => {
                                const themeID = viewAs?.themeID ?? collection.value?.themeID;
                                return Load2({
                                    _key: JSON.stringify({
                                        id: themeID,
                                        apiKey: viewAs?.value,
                                    }),
                                    fetch: () => (!themeID ? null : apis.collection.getTheme(themeID)),
                                    next,
                                });
                            },
                        ],
                        ["theme", ({remoteTheme}, next) => next(remoteTheme.value)],

                        ({theme}, next) =>
                            !collection.value || !theme
                                ? LoadingIndicator({
                                      className: "loading-collection-46s",
                                  })
                                : next(),
                        ({theme}, next) => provideContext("theme", theme, next),
                        ["tileActionControlFilter", (_, next) => UseState({next})],
                        ({tileActionControlFilter}, next) => provideContext("tileActionControlFilter", tileActionControlFilter, next),
                        ({theme}, next) => toastServiceDownload({theme, next}),

                        consumeContext("filtersValueSet"),
                        [
                            "dashboardData",
                            ({filtersValueSet}, next) =>
                                DashboardData({
                                    collection: collection.value,
                                    filterVals: filtersValueSet?.value,
                                    next,
                                }),
                        ],

                        ["filterDropArea", (_, next) => Static2({next})],
                        [
                            "loadedTileCounter",
                            (_, next) =>
                                UseState({
                                    next,
                                    initValue: {
                                        loadingTiles: 0,
                                        loadedTiles: 0,
                                    },
                                }),
                        ],
                        [
                            "convertToSharedTileDialog",
                            (_, next) =>
                                ConvertToSharedTileModal({
                                    next,
                                }),
                        ],
                        ({routing, theme, apis, setContainerBackground, dashboardData, filterDropArea, loadedTileCounter, convertToSharedTileDialog}) => {
                            const background = theme?.general.canvas.backgroundColorRGB;
                            const dragState = draggingState?.value?.draggingNew ?? draggingState?.value?.moving;
                            const showFilterDrop = dragState?.tile?.$type == "DownloadReportTile" && collection.value.filterStyle.display != "SDK";

                            return (
                                <div
                                    className="collection-panel-3gz"
                                    style={
                                        {
                                            // background: collection.value?.gridLocations?.length > 0 ? theme?.backgroundColorRGB : "transparent",
                                            // background: theme?.backgroundColorRGB,
                                            // height: hasScrollHeight() ? "max-content" : "100%",
                                        }
                                    }
                                >
                                    {FlapFilters({
                                        collection,
                                        filterForms: dashboardData.filterForms,
                                        onRunReport: () => {
                                            dashboardData?.unblockChanges?.();
                                        },
                                    })}

                                    <div className="filters verb-filters" style={{background}}>
                                        {LiveFilters({
                                            draggingState,
                                            showFilterDrop,
                                            filterDropArea,
                                            collection,
                                            editMode: true,
                                            filterForms: dashboardData.filterForms,
                                            onRunReport: () => {
                                                dashboardData?.unblockChanges?.();
                                            },
                                            apiKey: viewAs?.value,
                                            getTileFilters: dashboardData.getTileFilters,
                                        })}
                                    </div>

                                    {OnMounted({
                                        action: () => setContainerBackground(theme?.general.canvas.backgroundColorRGB),
                                    })}

                                    {OnUnmounted({
                                        action: () => setContainerBackground(null),
                                    })}

                                    {DnDGridPanel({
                                        collection,
                                        theme,
                                        getMinHeight,
                                        filterDropArea,
                                        draggingState,
                                        showFilterDrop,
                                        spacing: {
                                            Narrow: 3,
                                            Standard: 6,
                                            Wide: 12,
                                        }[theme?.general.canvas.tileSpacing],
                                        tiles: chain(
                                            collection.value?.gridLocations,
                                            (_) => sortMulti(_, [(gridLog) => gridLog.rowStart, (gridLog) => gridLog.colStart]),
                                            (_) =>
                                                _.map((gridLoc) => {
                                                    const size = {
                                                        width: gridLoc.colSpan,
                                                        height: gridLoc.rowSpan,
                                                    };
                                                    const onEdit = () => {
                                                        if (gridLoc.tile.$type !== "ContainerTile") {
                                                            routing.goto("edit-tile", {
                                                                tileId: gridLoc.tile.id,
                                                                ...(["CannotDisplay", "Invalid"].includes(gridLoc.tile.fieldValidity) && {
                                                                    tileTab: "fields",
                                                                }),
                                                            });
                                                        } else {
                                                            routing.goto("edit-container-tile", {
                                                                cTileId: gridLoc.tile.id,
                                                                tileTab: "fields",
                                                            });
                                                        }
                                                    };

                                                    const isOnChangeFilterType = collection.value?.filterStyle?.runType === "OnChange";
                                                    const showRefreshBtnOnLargeTile = size.width > 3 || size.height > 2;

                                                    const enabledAutoRefresh = collection.value?.autoDataRefresh;

                                                    const renderChart = (tile) =>
                                                        AutoRefreshController({
                                                            enabledAutoRefresh,
                                                            loadDataOnInit: false,
                                                            backgroundColor: theme.general.tile.styles.tileBackgroundColorRGB,
                                                            tile,
                                                            tileID: tile.id,
                                                            tileFilters: isOnChangeFilterType ? dashboardData.getTileFilters?.(tile.id) : {},
                                                            render: ({tile, tileFilters, refreshKey}, loadData, hasChanges) => {
                                                                const loadDataFunc = ["TableTile", "MapTile"].includes(tile.$type) ? createLoadTileData2 : createLoadTileData;

                                                                return (
                                                                    <ChartDraw
                                                                        key={refreshKey}
                                                                        {...{
                                                                            tileFilters:
                                                                                !enabledAutoRefresh && isOnChangeFilterType ? tileFilters : dashboardData.getTileFilters?.(tile.id),
                                                                            hasChanges,
                                                                            loadData: {
                                                                                key: viewAs?.value,
                                                                                load: loadData
                                                                                    ? loadDataFunc({
                                                                                          tileId: tile.id,
                                                                                          collectionId: collection.value?.id,
                                                                                          apis,
                                                                                          loadedTileCounter,
                                                                                          refreshKey,
                                                                                      })
                                                                                    : null,
                                                                            },
                                                                            downloadData: createDownloadTileData({
                                                                                tileId: tile.id,
                                                                                collectionId: collection.value?.id,
                                                                                apis,
                                                                            }),
                                                                            tile,
                                                                            size,
                                                                            onEdit,
                                                                            theme,
                                                                            disabledTileActions: true,
                                                                        }}
                                                                    />
                                                                );
                                                            },
                                                        });

                                                    return {
                                                        key: gridLoc.tile.id,
                                                        position: {
                                                            x: gridLoc.colStart - 1,
                                                            y: gridLoc.rowStart - 1,
                                                        },
                                                        size,
                                                        showHoverRefresh: !showRefreshBtnOnLargeTile,
                                                        theme,
                                                        $type: gridLoc.tile.$type,
                                                        style: gridLoc.tile.style,
                                                        title: gridLoc.tile.title,
                                                        isShared: gridLoc.tile.isShared,
                                                        adminTileName: gridLoc.tile.adminTileName,
                                                        onChangeSize: async (size) => {
                                                            const {$type, id} = gridLoc.tile;

                                                            spc(collection, ["gridLocations", (gl) => gl.tile.id === id], (gl) => ({
                                                                ...gl,
                                                                colSpan: size.width,
                                                                rowSpan: size.height,
                                                                colStart: size.x + 1,
                                                                rowStart: size.y + 1,
                                                            }));
                                                        },
                                                        onChangePosition: async (position) => {
                                                            const {$type, id} = gridLoc.tile;

                                                            // if (
                                                            //     $type == "ContainerTile" &&
                                                            //     (position?.width < CONTAINER_TILE_MIN_WIDTH || position?.height < CONTAINER_TILE_MIN_HEIGHT)
                                                            // ) {
                                                            //     return;
                                                            // }

                                                            spc(collection, ["gridLocations", (gl) => gl.tile.id === id], (gl) => ({
                                                                ...gl,
                                                                colStart: position.x + 1,
                                                                rowStart: position.y + 1,
                                                                ...(position.width
                                                                    ? {
                                                                          colSpan: position.width,
                                                                          rowSpan: position.height,
                                                                      }
                                                                    : {}),
                                                            }));
                                                        },
                                                        onDropToFilters: () => {
                                                            let tile = collection.value.gridLocations.find((gl) => gl.tile.id == gridLoc.tile.id)?.tile;

                                                            if (tile) {
                                                                const {show, fontSize} = tile.style.title;

                                                                if (show && !fontSize) {
                                                                    tile.style.title.fontSize = "Medium";
                                                                }

                                                                collection.change((c) => ({
                                                                    ...c,
                                                                    gridLocations: c.gridLocations.filter((gl) => gl.tile.id !== tile.id),
                                                                    filterDownloadTiles: (c.filterDownloadTiles || []).concat(tile),
                                                                }));
                                                            }
                                                        },
                                                        onMoveFilterToDashboard: (reportTile) => {
                                                            const dummyFilterTile = collection.value.gridLocations.find((gl) => gl.tile.id == DummyReportTileID);
                                                            const updatedGrid = collection.value.gridLocations
                                                                .concat({
                                                                    ...dummyFilterTile,
                                                                    tile: reportTile,
                                                                })
                                                                .filter((g) => g.tile.id != DummyReportTileID);

                                                            collection.change((c) => ({
                                                                ...c,
                                                                filterDownloadTiles: c.filterDownloadTiles.filter((t) => t.id != reportTile.id),
                                                                gridLocations: updatedGrid,
                                                            }));
                                                        },
                                                        onEdit,
                                                        onDelete: async (saveLocal) => {
                                                            if (saveLocal) {
                                                                collection.changeWithoutSync((c) => ({
                                                                    ...c,
                                                                    gridLocations: c.gridLocations.filter((gl) => gl.tile.id !== gridLoc.tile.id),
                                                                }));
                                                            } else {
                                                                try {
                                                                    await collection.change(
                                                                        (c) => ({
                                                                            ...c,
                                                                            gridLocations: c.gridLocations.filter((gl) => gl.tile.id !== gridLoc.tile.id),
                                                                        }),
                                                                        false
                                                                    );
                                                                } catch (e) {
                                                                    collectionToast.show({
                                                                        text: (
                                                                            <span>
                                                                                Error: cannot delete <b>{gridLoc.tile.title}</b>
                                                                            </span>
                                                                        ),
                                                                        isError: true,
                                                                    });
                                                                }
                                                            }
                                                        },
                                                        onReplaceSharedTile: async () => {
                                                            const {$type, id} = gridLoc.tile;

                                                            spc(collection, ["gridLocations", (gl) => gl.tile.id === id], (gl) => ({
                                                                ...gl,
                                                                tile: omit(gl.tile, ["id", "isShared", "defaultColSpan", "defaultRowSpan", "adminTileName"]),
                                                            }));
                                                        },
                                                        convertToSharedTile: async () => {
                                                            const sharedTile = await convertToSharedTileDialog.show({
                                                                tile: {
                                                                    ...gridLoc.tile,
                                                                    modelID: collection.value?.modelID,
                                                                },
                                                                defaultRowSpan: gridLoc.rowSpan,
                                                                defaultColSpan: gridLoc.colSpan,
                                                            });

                                                            if (sharedTile) {
                                                                spc(collection, ["gridLocations", (gl) => gl.tile.id === gridLoc.tile.id], (gl) => ({
                                                                    ...gl,
                                                                    tile: sharedTile,
                                                                }));

                                                                collectionToast.show({
                                                                    text: `Shared tile successfully created`,
                                                                    action: {
                                                                        text: "Edit Shared Tile",
                                                                        onClick: () => {
                                                                            alert("go to shared tile");
                                                                        },
                                                                    },
                                                                });
                                                            }
                                                        },
                                                        render: ({}) => (
                                                            <div
                                                                className="chart-2ei"
                                                                style={{
                                                                    backgroundColor:
                                                                        gridLoc.tile?.style?.backgroundStyle == "NoBackground"
                                                                            ? "transparent"
                                                                            : gridLoc.tile.$type == "SectionTile"
                                                                            ? theme?.dataVisualization.sectionTile.backgroundColorRGB ||
                                                                              theme?.general.tile.styles.tileBackgroundColorRGB
                                                                            : theme?.general.tile.styles.tileBackgroundColorRGB,
                                                                    borderColor:
                                                                        gridLoc.tile?.style?.backgroundStyle == "NoBackground"
                                                                            ? "transparent"
                                                                            : theme?.general.tile.styles.tileBorderColorRGB,
                                                                    borderRadius: theme?.general.tile.styles.tileCornerRadius,
                                                                    borderWidth: theme?.general.tile.styles.tileBorderWidth,
                                                                    boxShadow:
                                                                        theme?.general.tile.styles.tileShadow && gridLoc.tile?.style?.backgroundStyle != "NoBackground"
                                                                            ? "0 2px 8px 6px rgba(7, 6, 6, 0.05)"
                                                                            : "",
                                                                }}
                                                            >
                                                                {(() => {
                                                                    if (excluded(gridLoc.tile.id)) {
                                                                        return "EXCLUDED";
                                                                    }

                                                                    if (gridLoc.tile.$type == "ContainerTile") {
                                                                        return ContainerTile({
                                                                            containerTile: gridLoc.tile,
                                                                            theme,
                                                                            renderChart,
                                                                            onEdit,
                                                                            isLiveGrid: false,
                                                                            isDonePreLoad:
                                                                                loadedTileCounter.value.loadingTiles >=
                                                                                collection.value?.gridLocations.filter((gL) => gL.tile.$type != "DownloadReportTile").length,
                                                                        });
                                                                    }

                                                                    return renderChart(gridLoc.tile);
                                                                })()}
                                                            </div>
                                                        ),
                                                    };
                                                })
                                        ),
                                    })}
                                </div>
                            );
                        }
                    ),
            })
    );

const excluded = !isDevQuan() ? () => false : (id) => excludedTileIds.includes(id);

const excludedTileIds = [
    // "7338e498-d3b5-4a0a-80f9-40b92652087e",
    // "b2965420-313f-402f-87c6-b6ad630dbda0",
    // "201f1584-0415-453a-8ea3-57e44fad9462",
    // "cc57a8e0-9b5d-4640-9e02-ea5dcd47683b",
    //
    // // "855f3810-ece1-479e-8737-df09c880782c",
    //
    // "c261590f-d2c7-4ccf-8f78-5d847ef3654d",
    // "14323781-a76e-4b18-b24f-ab36b40a7494",
    // "3e49a5db-d6e6-440c-ad8a-4b683aee4d7c"
];
