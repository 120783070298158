import {css, cx} from "emotion";
import {getScrollbarStyleLegend} from "@common/ui-components/charts/render-chart/chart-scrolling-style";
import {LegendControl} from "@common/ui-components/charts/render-chart/legend/control/legend-control";
import {getScatterGroupsForLegend} from "@common/ui-components/charts/scatter-plot/legend/scatter-legend-room";
import {ColorGroupItem, ReferenceLineItem, ShapeGroupItem} from "@common/ui-components/charts/scatter-plot/legend/scatter-legend-item";
import React from "react";
import {cs} from "@common/react/chain-services";
import {StyledClass} from "@common/react/styled-class";

export const ScatterLegendNormalDisplay = ({
    tile,
    theme,
    size,
    formatters,
    chart,
    hidden,
    options,
    legendRoom,
    legendContainerRef,
    scrollLeft,
}) => {
    const hasColorGroup = tile.colorGroupField != null;
    const hasShapeGroup = tile.shapeGroupField != null;
    const hasReferenceLines = !!tile.referenceLines?.length;

    // legend only available when having one group or both groups or reference lines
    if (!hasColorGroup && !hasShapeGroup && !hasReferenceLines) {
        return;
    }

    const rSeparator = () => (
        <div
            className="separator"
            style={{height: ["Top", "Bottom", "TopVertical", "BottomVertical"].includes(tile.style.legendStyle.legendPosition) ? 26 : 40}}
        />
    );

    return cs(
        [
            "scrollbarLegendClass",
            (_, next) =>
                StyledClass({
                    content: getScrollbarStyleLegend(theme),
                    next,
                }),
        ],
        ({scrollbarLegendClass}) => (
            <div
                className={cx(
                    "legend-wrapper verb-tile-legend scatter-plot-legend-9c2",
                    scrollbarLegendClass,
                    `${
                        legendRoom.paddingTop || legendRoom.paddingBottom
                            ? `horizontal-scroll verb-tile-legend-${legendRoom.paddingTop ? "top" : "bottom"}`
                            : `vertical-scroll verb-tile-legend-${legendRoom.paddingLeft ? "left" : "right"}`
                    }`
                )}
                style={{
                    width: legendRoom.paddingTop || legendRoom.paddingBottom ? "100%" : `${legendRoom.legendWidth}px`,
                    left: legendRoom.paddingLeft ? 0 : "",
                    right: legendRoom.paddingRight ? 0 : "",
                    height: legendRoom.paddingTop || legendRoom.paddingBottom ? "" : `${chart.plotHeight}px`,
                    top: legendRoom.paddingBottom ? "" : 0,
                    bottom: legendRoom.paddingBottom ? "0" : "",
                }}
            >
                <div
                    className="legend-container"
                    ref={(elem) => {
                        legendContainerRef.set(elem);
                        setTimeout(() => {
                            if (scrollLeft.value === null) {
                                scrollLeft.onChange(0);
                            }
                        });
                    }}
                    style={{
                        justifyContent: legendContainerRef.get()?.scrollWidth - legendContainerRef.get()?.clientWidth > 0 ? "" : "center",
                    }}
                    onScroll={() => {
                        scrollLeft.onChange(legendContainerRef.get().scrollLeft);
                    }}
                >
                    {/* color group legend */}
                    {hasColorGroup &&
                        getScatterGroupsForLegend({
                            series: chart.series,
                            groupNameAttr: "colorGroupName",
                            areSeriesFromChart: true,
                        }).map(({name, color}) => {
                            return ColorGroupItem({
                                name,
                                color,
                                fontSize: options.legend.itemStyle.fontSize,
                                chart,
                                hidden,
                                theme,
                            });
                        })}

                    {hasColorGroup && hasShapeGroup && rSeparator()}

                    {/* shape group legend */}
                    {hasShapeGroup &&
                        getScatterGroupsForLegend({
                            series: chart.series,
                            groupNameAttr: "shapeGroupName",
                            areSeriesFromChart: true,
                        }).map(({name, shape}) => {
                            return ShapeGroupItem({
                                name,
                                shape,
                                fontSize: options.legend.itemStyle.fontSize,
                                chart,
                                hidden,
                                theme,
                            });
                        })}

                    {(hasColorGroup || hasShapeGroup) && hasReferenceLines && rSeparator()}

                    {/* reference line legend */}
                    {hasReferenceLines &&
                        chart.series
                            .filter((s) =>
                                ["ConstantValueReferenceLine", "AggregateValueReferenceLine", "IndicatorReferenceLine"].includes(
                                    s.userOptions.customType
                                )
                            )
                            .map((s) =>
                                ReferenceLineItem({
                                    series: s,
                                    chart,
                                    tile,
                                    theme,
                                    hidden,
                                    fontSize: options.legend.itemStyle.fontSize,
                                })
                            )}

                    {scrollLeft.value !== null &&
                        LegendControl({
                            legendContainerRef,
                            scrollLeft,
                        })}
                </div>
            </div>
        )
    );
};
