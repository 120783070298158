import {cs} from "../../../react/chain-services";
import {css, cx} from "emotion";
import {UseState} from "../../../react/use-state";
import * as React from "react";

import {DialogService} from "../../../../web-client/src/routes/common/dialog/dialog-service";
import {copyStringToClipboard} from "../../../utils/browser/copy-string-to-clipboard";

export const IframeDownloadModal = ({next: rootNext}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    registryRender: true,
                    render: ({resolve, args: {href, title = "Iframe Download", message = "* Iframe not supported download directly"}}) => ({
                        title,
                        content: next({resolve, href, message}),
                    }),
                    next: rootNext,
                }),
        ],
        ({modal}) => (
            <div
                style={{
                    padding: 16,
                    textAlign: "center",
                }}
            >
                {/* using target _new is trick b/c target _blank in a tag won't work in iframe*/}
                <a href={`javascript:window.open('${modal.href}', '_blank')`} target="_new">
                    Click here to download
                </a>

                {cs(["copied", (_, next) => UseState({next})], ({copied}) => (
                    <div
                        style={{
                            padding: "8px 0",
                            cursor: "pointer",
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            copied.onChange(true);
                            copyStringToClipboard(modal.href);
                        }}
                    >
                        Or copy url to new browser
                        <div style={{wordBreak: "break-all"}}>
                            {modal.href.length > 200 ? `${modal.href.substring(0, 200)}...` : modal.href}
                        </div>
                        <div
                            style={{
                                color: copied.value ? "#11a1fd" : "",
                            }}
                        >
                            {copied.value ? `Copied` : `Copy`}
                        </div>
                    </div>
                ))}

                <div
                    style={{
                        "margin-top": "10px",
                        "font-style": "italic",
                        "font-size": "10px",
                    }}
                >
                    {modal.message}
                </div>
            </div>
        )
    );
