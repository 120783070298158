import {cs} from "@common/react/chain-services";
import * as React from "react";
import "./mp-line.scss";

export const MpLine = ({left, right}) =>
    cs(({}) => (
        <div className="mp-line-8s3 mp-line">
            <div className="left">{left}</div>
            <div className="right">{right}</div>
        </div>
    ));
