import * as React from "react";
import {cs} from "@common/react/chain-services";
import {cx} from "emotion";
import {StaticTooltipService} from "../../../../../../../../../common/tooltip3/static-tooltip-service";
import "./mobile-warning.scss";

export const MobileWarning = ({info, tooltipBoxWidth}) =>
    cs(
        [
            "staticTooltip",
            (_, next) =>
                StaticTooltipService({
                    direction: "top",
                    tooltipBoxWidth,
                    leftOffset: 2,
                    info,
                    next,
                }),
        ],
        ({staticTooltip}) => {
            return staticTooltip.renderIcon({
                className: cx("mobile-warning mobile-warning-53s"),
                icon: <i className="fa fa-mobile-alt" style={{color: "#FF7A59"}} />,
            });
        }
    );
