import "./action-menu.scss";

import React from "react";

import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {Dropdown} from "../../../dropdown/dropdown";
import {keyed} from "@common/react/keyed";
import {fragments} from "@common/react/fragments";
import {Static2} from "@common/react/static-2";
import {Invoke} from "@common/react/invoke";
import {Watch} from "@common/react/watch";
import {equalDeep, pick} from "@common/utils/objects";
import {buildString} from "@common/utils/strings";
import {
    createFilterVals,
    getAllAvailableActions,
    mapFieldToValue,
    getFieldsHaveActions,
    hasNoAction,
    navigationActionTypes,
    parseURLTemplate,
    mapFieldToColumn,
    getTileActionField,
    getActionFieldsFromTile,
    isValidTileAction,
    isDisableControlFilterAction,
} from "./action-menu-utils";
import {VerbEvent} from "../../../live/live-dashboard/common/js-trigger";
import {consumeContext} from "@common/react/context";
import {flattenModelCols} from "../../../../../web-client/src/routes/collection/common/field-info/get-field";
import {getErrorMessageFieldControlFilter} from "../../../../../web-client/src/routes/collection/tile/edit/left-panel/tabs/fields-tab/actions/add/action-fields/action-field-utils";

export const ActionMenu = ({tile, overrideTile, dimensionFormatter, next: rootNext, disabledTileActions = false}) =>
    cs(
        consumeContext("collection"),
        consumeContext("modelForCollection"),
        consumeContext("tileFields"),
        consumeContext("tileActionControlFilter"),
        consumeContext("authParams"),
        ["currentPoint", ({}, next) => UseState({next, initValue: null})],
        ["fieldSections", ({}, next) => next(getActionFieldsFromTile(tile))],
        ["cachedTileActions", (_, next) => Static2({next})],
        [
            "executeAction",
            ({currentPoint, modelForCollection, collection, tileFields, authParams, tileActionControlFilter}, next) =>
                next(async ({action, field, noFilter, filters, filterValues, fieldToValue}) => {
                    if (disabledTileActions) {
                        return;
                    }

                    const {
                        $type,
                        tileTitleBefore,
                        tileTitleAfter,
                        tile: childTile,
                        tileActionFields,
                        navigationType,
                        urlTemplate,
                        triggerParameters,
                        enabledTileFieldIDs,
                    } = action;
                    const submitFilterValues = filters.reduce((prev, item) => {
                        prev[item.id] = createFilterVals({
                            value: filterValues.find(
                                (fs) =>
                                    item.columns[0].modelColumnID === fs.modelColumnID &&
                                    item.columns[0].modelID === fs.modelID &&
                                    item.columns[0].modelTableID === fs.modelTableID
                            )?.value,
                            filter: item,
                            field,
                        });

                        return prev;
                    }, {});

                    if ($type === "OpenNewTileAction") {
                        childTile &&
                            overrideTile?.({
                                tileAction: {
                                    ...action,
                                    tile: {
                                        ...childTile,
                                        title: buildString([
                                            tileTitleBefore,
                                            filterValues.map((v) => v.displayText).join(" / "),
                                            tileTitleAfter,
                                        ]),
                                    },
                                    currentFilters: !noFilter ? filters : [],
                                    filterVals: !noFilter ? submitFilterValues : {},
                                },
                            });
                    } else if (fieldToValue && $type == "NavigateTileAction") {
                        const goToType = navigationActionTypes.find((n) => n.value == navigationType);
                        let rawURL = null;

                        try {
                            let {ops} = JSON.parse(urlTemplate);
                            rawURL = parseURLTemplate({
                                ops,
                                tileActionFields,
                                fieldToValue,
                                authParams,
                            });
                        } catch (e) {}
                        goToType.action(rawURL);
                        // console.log(rawURL);
                    } else if (fieldToValue && $type == "TriggerTileAction") {
                        let list = mapFieldToColumn(flattenModelCols(modelForCollection), tileFields);
                        let returnedParams = enabledTileFieldIDs.map((tileFieldID) => {
                            const currentField = list.find((c) => c.tileFieldID == tileFieldID);
                            return {
                                tileFieldID,
                                name: `${currentField?.modelTableName}.${currentField?.name}`,
                                value: fieldToValue[tileFieldID],
                            };
                        });

                        returnedParams = returnedParams.concat(
                            triggerParameters.map((modelColumnID) => {
                                const column = list.find((c) => c.modelColumnID == modelColumnID);
                                const currentField = column
                                    ? tileActionFields.find(
                                          (f) =>
                                              f.modelColumnID == column.modelColumnID &&
                                              f.modelTableID == column.modelTableID &&
                                              f.modelID == column.modelID
                                      )
                                    : null;

                                if (!currentField) {
                                    return null;
                                }

                                return {
                                    tileFieldID: currentField.id,
                                    name: `${column?.modelTableName}.${column?.name}`,
                                    value: fieldToValue[currentField.id],
                                };
                            })
                        );

                        await VerbEvent.emit("jsTrigger", {
                            tile: pick(tile, ["name", "id", "$type"]),
                            collection: pick(collection.value, ["name", "id", "$type"]),
                            params: returnedParams,
                        });
                    } else if (filterValues && action.$type == "ControlCollectionFiltersAction") {
                        if (isDisableControlFilterAction(tile)) {
                            return;
                        }

                        const actionField = getTileActionField(action, collection.value.filters, field, filterValues);

                        if (actionField) {
                            tileActionControlFilter.change((current) => ({
                                tile,
                                action: actionField,
                                hasPrevTileAction: current?.tile?.id != tile.id && current?.tile,
                            }));
                        }
                    }
                }),
        ],
        [
            "renderActions",
            ({currentPoint, executeAction, collection, fieldSections}, next) =>
                next(({actions, field, close}) => {
                    const {noFilter = false} = currentPoint.value;

                    return actions
                        .filter((a) =>
                            isValidTileAction(a, {
                                tile,
                                collectionFilters: collection.value.filters,
                            })
                        )
                        .map((action, i) =>
                            cs(keyed(i), () => {
                                const {filters, menuTitleBefore, menuTitleAfter} = action;

                                let currentFilters = !noFilter
                                    ? (filters || []).filter(
                                          (f) =>
                                              f.columns[0].modelColumnID === field.modelColumnID &&
                                              f.columns[0].modelID === field.modelID &&
                                              f.columns[0].modelTableID === field.modelTableID
                                      )
                                    : [];
                                if (
                                    tile.$type === "TableTile" &&
                                    action.filterLogic === "Additive" &&
                                    action.$type === "OpenNewTileAction"
                                ) {
                                    currentFilters = action.filters.filter((f) => {
                                        let found = fieldSections.find(
                                            (fs) =>
                                                f.columns[0].modelColumnID === fs.modelColumnID &&
                                                f.columns[0].modelID === fs.modelID &&
                                                f.columns[0].modelTableID === fs.modelTableID
                                        );

                                        return found ? action.enabledTileFieldIDs.includes(found.id) : false;
                                    });
                                }

                                const currentFilterValues =
                                    currentFilters.length > 0
                                        ? currentFilters.map((f) => {
                                              let field1 = fieldSections.find(
                                                  (fs) =>
                                                      f.columns[0].modelColumnID === fs.modelColumnID &&
                                                      f.columns[0].modelID === fs.modelID &&
                                                      f.columns[0].modelTableID === fs.modelTableID
                                              );

                                              return mapFieldToValue({
                                                  actionType: action.$type,
                                                  tile,
                                                  noFilter,
                                                  field: field1,
                                                  ...currentPoint.value,
                                                  dimensionFormatter,
                                              });
                                          })
                                        : [
                                              mapFieldToValue({
                                                  actionType: action.$type,
                                                  tile,
                                                  noFilter,
                                                  field,
                                                  ...currentPoint.value,
                                                  dimensionFormatter,
                                              }),
                                          ];

                                return (
                                    <div
                                        className="item"
                                        key={i}
                                        onClick={(e) => {
                                            close?.(e);
                                            executeAction({
                                                action,
                                                field,
                                                filters: currentFilters,
                                                filterValues: currentFilterValues,
                                                noFilter,
                                                fieldToValue: currentPoint.value.fieldToValue,
                                            });
                                        }}
                                    >
                                        {menuTitleBefore}&nbsp;
                                        <b>{currentFilterValues.map((v) => v.displayText).join(" / ")}</b>&nbsp;
                                        {action.$type != "ControlCollectionFiltersAction" ? menuTitleAfter : ""}
                                    </div>
                                );
                            })
                        );
                }),
        ],
        [
            "menu",
            ({fieldSections, currentPoint, renderActions, collection}, next) =>
                Dropdown({
                    next,
                    registryRender: true,
                    detectOnWheelEvent: true,
                    className: "action-menu-vf4",
                    expandClassNames: "action-menu-expand-v4d",
                    renderExpand: ({close}) => {
                        if (!currentPoint.value || !tile.tileActions || tile.tileActions.length == 0) {
                            return null;
                        }

                        const {fieldName, onlyDimensionField, additionalActions} = currentPoint.value;
                        let filteredFields = fieldName ? fieldSections.filter((f) => f.displayName == fieldName) : fieldSections;

                        if (onlyDimensionField) {
                            filteredFields = filteredFields.filter((f) => f.$type == "DimensionTileField");
                        }

                        return (
                            <div className="list">
                                {additionalActions &&
                                    fragments(
                                        <div className="section-item">{additionalActions.sectionName}</div>,
                                        additionalActions.actions.map((a, i) => (
                                            <div
                                                className="item"
                                                key={i}
                                                onClick={(e) => {
                                                    close?.(e);
                                                    a.function();
                                                }}
                                            >
                                                {a.label}
                                            </div>
                                        ))
                                    )}
                                {filteredFields.map((field) =>
                                    cs(keyed(field.id), () => {
                                        const actions = tile.tileActions.filter((action) => {
                                            if (action.enabledTileFieldIDs.includes(field.id)) {
                                                if (action.$type != "ControlCollectionFiltersAction") {
                                                    return isValidTileAction(action, {
                                                        tile,
                                                        collectionFilters: collection.value.filters,
                                                    });
                                                }

                                                return !getErrorMessageFieldControlFilter(action, collection.value.filters, field);
                                            }

                                            return false;
                                        });
                                        if (actions.length == 0) {
                                            return null;
                                        }

                                        return fragments(
                                            <div className="section-item">{field.displayName}</div>,
                                            renderActions({
                                                actions,
                                                field,
                                                close,
                                            })
                                        );
                                    })
                                )}
                            </div>
                        );
                    },
                }),
        ],
        [
            "showMenu",
            ({currentPoint, menu, cachedTileActions, fieldSections, executeAction, collection}, next) =>
                next(
                    ({
                        x,
                        y,
                        noFilter = false,
                        point,
                        chart,
                        row,
                        fieldName,
                        onlyDimensionField = false,
                        fieldToValue,
                        additionalActions,
                    }) => {
                        if (disabledTileActions) {
                            return;
                        }

                        const tileActions = cachedTileActions.get();
                        if (
                            hasNoAction(tileActions, {
                                tile,
                                collectionFilters: collection.value.filters,
                            })
                        ) {
                            return;
                        }

                        chart?.tooltip?.hide();

                        const pointData = {
                            data: point ? [point?.options?.name || point?.name || point?.category || point.x, point.y] : null,
                            index: point ? point.index : null,
                            series: point ? point.series?.name : null,
                            row,
                            fieldName,
                            noFilter,
                            onlyDimensionField,
                            fieldToValue,
                            additionalActions,
                        };

                        const actions = getAllAvailableActions({
                            tileActions,
                            fieldSections,
                            fieldName,
                            onlyDimensionField,
                        });

                        if (!additionalActions && actions.length == 1) {
                            const currentAction = actions[0];

                            const {filters, field} = currentAction;

                            const currentField = !noFilter ? field : null;
                            let currentFilters = !noFilter
                                ? (filters || []).filter(
                                      (f) =>
                                          f.columns[0].modelColumnID === field.modelColumnID &&
                                          f.columns[0].modelID === field.modelID &&
                                          f.columns[0].modelTableID === field.modelTableID
                                  )
                                : [];

                            if (
                                tile.$type === "TableTile" &&
                                currentAction.filterLogic === "Additive" &&
                                currentAction.$type === "OpenNewTileAction"
                            ) {
                                currentFilters = currentAction.filters.filter((f) => {
                                    let found = fieldSections.find(
                                        (fs) =>
                                            f.columns[0].modelColumnID === fs.modelColumnID &&
                                            f.columns[0].modelID === fs.modelID &&
                                            f.columns[0].modelTableID === fs.modelTableID
                                    );

                                    return found ? currentAction.enabledTileFieldIDs.includes(found.id) : false;
                                });
                            }

                            const currentFilterValues =
                                currentFilters.length > 0
                                    ? currentFilters.map((f) => {
                                          let field1 = fieldSections.find(
                                              (fs) =>
                                                  f.columns[0].modelColumnID === fs.modelColumnID &&
                                                  f.columns[0].modelID === fs.modelID &&
                                                  f.columns[0].modelTableID === fs.modelTableID
                                          );

                                          return mapFieldToValue({
                                              actionType: currentAction.$type,
                                              tile,
                                              noFilter,
                                              field: field1,
                                              data: pointData.data,
                                              series: pointData.series,
                                              row: pointData.row,
                                              dimensionFormatter,
                                              fieldToValue,
                                          });
                                      })
                                    : [
                                          mapFieldToValue({
                                              actionType: currentAction.$type,
                                              tile,
                                              noFilter,
                                              field,
                                              data: pointData.data,
                                              series: pointData.series,
                                              row: pointData.row,
                                              dimensionFormatter,
                                              fieldToValue,
                                          }),
                                      ];

                            executeAction({
                                action: currentAction,
                                field: currentField,
                                filters: currentFilters,
                                filterValues: currentFilterValues,
                                fieldToValue,
                                noFilter,
                            });
                        } else {
                            currentPoint.onChange(pointData);
                            menu.showExpand(true, {top: y, left: x});
                        }
                    }
                ),
        ],
        ({cachedTileActions, showMenu}, next) =>
            fragments(
                Invoke({
                    fn: () => cachedTileActions.set(tile.tileActions),
                }),
                Watch({
                    value: tile.tileActions,
                    onChanged: (newTileActions, oldTileActions) => {
                        if (!equalDeep(newTileActions, oldTileActions)) {
                            cachedTileActions.set(newTileActions);
                        }
                    },
                }),
                next()
            ),
        ({menu, currentPoint, showMenu, fieldSections, collection}) => {
            return rootNext({
                currentPoint,
                hasActions: () =>
                    !disabledTileActions &&
                    !hasNoAction(tile.tileActions, {
                        tile,
                        collectionFilters: collection.value?.filters,
                    }),
                // hasActions: () => !hasNoAction(tile.tileActions, {tile, collectionFilters: collection.value?.filters}),
                isFieldHasActions: ({id}) =>
                    getFieldsHaveActions({
                        fieldSections,
                        tileActions: tile.tileActions,
                    }).findIndex((_field) => _field.id === id) >= 0,
                show: showMenu,
                render: menu.render,
            });
        }
    );
