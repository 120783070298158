exports.downloadReportTemplate = {
    $type: "DownloadReportTile",
    style: {
        $type: "DownloadReportTileStyle",
        downloadFormats: "XLSX",
        buttonText: "Download Report",
        // buttonColorRGB, buttonHoverColorRGB, buttonFontColorRGB from theme
        buttonFontSize: "Small",
        title: {
            show: false,
            titleLocation: "Above",
        },
    },
};
