import SearchIcon from "../../../web-client/src/assets/icons/common/SearchIcon";
import {CloseIcon} from "../../../web-client/src/routes/common/icons/close-icon";
import "./search-input-2.scss";
import {cx} from "emotion";

export const SearchInput2 = ({state, className}) => (
    <div className={cx("search-input-2-wrapper", className)}>
        <div className="search-icon">
            <SearchIcon />
        </div>

        <input className="search-input" placeholder="Search" value={state.value} onChange={(e) => state.onChange(e.target.value)} />

        {state.value.length > 0 && (
            <div
                className="close-icon"
                onClick={() => {
                    state.onChange("");
                }}
            >
                <CloseIcon fill="#919EB0" />
            </div>
        )}
    </div>
);
