import { OnUnmounted } from "@common/react/on-unmounted";
import { StyledClass } from "@common/react/styled-class";
import { cx } from "emotion";
import * as React from "react";
import { cs } from "../../../react/chain-services";
import { SubcribeKeyEvents } from "../../../react/keys/global-key-down";
import { spc } from "../../../react/state-path-change";
import { addDate, addMonth } from "../../../utils/dates/date-object";
import { fullMonths } from "../../../utils/dates/full-months";
import { DropdownSelect } from "../../dropdown-select/dropdown-select";
import { Calendar2 } from "../calendar-2/calendar-2";
import { allowedYears } from "./allowed-years";
import "./single-calendar.scss";

export const SingleCalendar = ({
    state,
    selectControl,
    space,
    renderActions,
    theme,
    size: relativeSize,
    originalRanges,
    hover,
    month,
    isMobile,
    ranges,
    onSelect,
    years = allowedYears,
    months = fullMonths,
}) => {
    const componentTheme = theme?.general?.components;
    const buttonTheme = theme?.general?.buttons;
    const paddingTotal = relativeSize / 8;
    const size = (relativeSize - paddingTotal - space * 6) / 7;
    return cs(
        [
            "monthStyle",
            (_, next) => {
                return StyledClass({
                    content: {
                        "& > .dropdown-select": {
                            "font-size": `${size * 0.4}px`,
                            "&.toggle": {
                                "&.text": {
                                    color: `${componentTheme?.inputTextColorRGB ?? "black"}`,
                                    "font-size": `${size * 0.4}px !important`,
                                },
                                "&:hover": {
                                    "background-color": `${componentTheme?.menuHoverBackgroundColorRGB ?? "#62a7f0"} `,
                                },
                                "&.expanding": {
                                    "background-color": `${componentTheme?.menuHoverBackgroundColorRGB ?? "#62a7f0"} `,
                                },
                            },
                        },
                    },
                    next,
                });
            },
        ],
        [
            "carretStyle",
            (_, next) => {
                return StyledClass({
                    content: {
                        color: `${buttonTheme?.hyperlinkButton?.fontColorRGB || "#0276F2"} `,
                        width: `${size * 0.8}px`,
                        height: `${size * 0.8}px`,
                        "&:hover": {
                            "background-color": `${componentTheme?.menuHoverBackgroundColorRGB ?? "#62a7f0"}`,
                        },
                        i: {
                            "font-size": `${size * 0.4}px`,
                        },
                    },
                    next,
                });
            },
        ],
        ({monthStyle, carretStyle}) => {
            const onHoverAlign = (margin) => {
                const {selecting} = state.value;

                const current =
                    hover.value ??
                    (() => {
                        const rangeCurrent = ranges.find((r) => r.name == selecting?.[0])?.range?.[selecting?.[1]];
                        const delta = selecting?.[1] === "from" ? 0 : -1;
                        return rangeCurrent ? addDate(rangeCurrent, delta) : null;
                    })() ??
                    month.value;
                const newHover = addDate(current, margin);

                if (selectControl?.selectable?.(newHover)?.value ?? true) {
                    hover.onChange(newHover);
                }
                if (newHover.month !== month.value.month || newHover.year !== month.value.year) {
                    spc(month, ["month"], () => newHover.month);
                    spc(month, ["year"], () => newHover.year);
                }
            };

            return (
                <>
                    {OnUnmounted({
                        action: () => {
                            hover.onChange(null);
                        },
                    })}
                    <div
                        className={cx("single-calendar single-calendar-1ww")}
                        style={{
                            padding: `${paddingTotal / 2}px`,
                        }}
                    >
                        <div
                            style={{
                                "margin-bottom": `${size * 0.4}px`,
                            }}
                            className={cx("controls")}
                        >
                            <div
                                className={cx("left", carretStyle, {hidden: !selectControl.navigation.canLeft(month.value)})}
                                onClick={() => month.change((m) => addMonth(m, -1))}
                            >
                                <i className="fa fa-chevron-left" />
                            </div>
                            <div className={cx("selector", monthStyle)}>
                                {DropdownSelect({
                                    list: months,
                                    closeAllOtherDropdownWhenOpen: false,
                                    minWidth: true,
                                    valueToLabel: (item) => item,
                                    isSelected: (item, i) => month.value.month - 1 === i,
                                    onChange: (item, i) => spc(month, ["month"], () => i + 1),
                                })}
                                {DropdownSelect({
                                    list: years,
                                    closeAllOtherDropdownWhenOpen: false,
                                    minWidth: true,
                                    valueToLabel: (item) => item,
                                    isSelected: (item) => month.value.year === item,
                                    onChange: (item) => spc(month, ["year"], () => item),
                                })}
                            </div>
                            <div
                                className={cx("right", carretStyle, {hidden: !selectControl.navigation.canRight(addMonth(month.value, 1))})}
                                onClick={() => month.change((m) => addMonth(m, +1))}
                            >
                                <i className="right fa fa-chevron-right" />
                            </div>
                        </div>
                        {!isMobile &&
                            SubcribeKeyEvents({
                                events: [
                                    {
                                        keyCombo: "ArrowUp",
                                        onKeyDown: () => onHoverAlign(-1),
                                    },
                                    {
                                        keyCombo: "ArrowDown",
                                        onKeyDown: () => onHoverAlign(1),
                                    },

                                    {
                                        keyCombo: "Enter",
                                        onKeyDown: () => {
                                            hover.value && onSelect(hover.value);
                                        },
                                    },
                                ],
                            })}
                        {Calendar2({
                            month: month.value,
                            onHover: hover.onChange,
                            onSelect,
                            size,
                            ranges,
                            theme,
                            originalRanges,
                            monthStyle,
                            space,
                            selectControl,
                        })}
                        <div className="calendar-actions">{renderActions?.()}</div>
                    </div>
                </>
            );
        }
    );
};
