import React from "react";
import {cs} from "@common/react/chain-services";
import "./outer-block.scss";
import {InnerBlock} from "./inner-block/inner-block";
import {cx} from "emotion";

export const OuterBlock = ({outerBlock, userMapping, onChange, outerBlocksLength, headerText, filterInnerBlock, ...otherProps}) =>
    cs(() => {
        return (
            <div className="outer-block-a23">
                <div className="or-text">{headerText}</div>

                <div
                    className={cx("inner-blocks-wrapper", {
                        "filter-inner-block": filterInnerBlock,
                    })}
                >
                    {outerBlock.innerBlocks.map((innerBlock, index) => (
                        <InnerBlock
                            filterInnerBlock={filterInnerBlock}
                            hideRemove={outerBlocksLength == 1 && outerBlock.innerBlocks.length == 1 && (filterInnerBlock ? true : !outerBlock.innerBlocks[0].leftValue)}
                            isLastChild={index == outerBlock.innerBlocks.length - 1}
                            hasBorder={index < outerBlock.innerBlocks.length - 1}
                            key={innerBlock.timestamp}
                            innerBlock={innerBlock}
                            userMapping={userMapping}
                            onChange={(updated) =>
                                onChange({
                                    ...outerBlock,
                                    innerBlocks: outerBlock.innerBlocks.map((b) => (b.timestamp == innerBlock.timestamp ? updated : b)),
                                })
                            }
                            onAdd={() => {
                                onChange({
                                    ...outerBlock,
                                    innerBlocks: outerBlock.innerBlocks.concat({
                                        leftValue: filterInnerBlock ? {$type: "ColumnBlockValue"} : null,
                                        compareOp: "Equal",
                                        timestamp: new Date().getTime(),
                                    }),
                                });
                            }}
                            onRemove={() => {
                                onChange({
                                    ...outerBlock,
                                    innerBlocks: outerBlock.innerBlocks.filter((block) => block.timestamp != innerBlock.timestamp),
                                });
                            }}
                            {...otherProps}
                        />
                    ))}
                </div>
            </div>
        );
    });
