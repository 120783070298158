const {createArray} = require("../../utils/collections");
const {arrMapToO} = require("../../utils/objects");

exports.sampleTenant = {
    id: "595745e5-d238-4ff4-905e-6fb73de9d98d",
    name: "Main Test Tenant",
    subdomain: "sd-4938824209",
};

exports.sampleAuthTestResponse = {
    success: true,
    responseCode: "Continue",
    responseCodeNum: 0,
    userValues: {
        "User.ID": {
            value: "000-000",
            $type: "UserStringValue",
        },
        "User.Roles": {
            values: [
                {
                    value: "Verb",
                    $type: "UserStringValue",
                },
                {
                    value: "Full",
                    $type: "UserStringValue",
                },
            ],
            // "values":null,
            $type: "UserArrayValue",
        },
        "User.TenantID": {
            // "value":"aaa-aaa",
            value: null,
            $type: "UserStringValue",
        },
    },
    rawResponse: JSON.stringify(
        arrMapToO(
            createArray(5),
            (v) => ({
                key1: `value${v}`,
                key2: `value${v}`,
            }),
            (v) => `key${v}`
        ),
        null,
        2
    ),
    responseContentType: "None",
};
