import React from "react";
import {cs} from "../../../../react/chain-services";
import "../render-chart.scss";
import {LegendNormalDisplay} from "./display/legend-normal-display";
import {LegendTopBottomVerticalDisplay} from "./display/legend-top-bottom-vertical-display";
import {FunnelLegendDisplay} from "../../funnel/legend/funnel-legend-display";
import {ScatterLegendDisplay} from "../../scatter-plot/legend/scatter-legend-display";

export const LegendDisplay = (props) =>
    cs(() => {
        if (props.tile.$type === "FunnelChartTile") {
            return FunnelLegendDisplay(props);
        }

        if (["ScatterPlotTile", "BubbleChartTile"].includes(props.tile.$type)) {
            return ScatterLegendDisplay(props);
        }

        if (["TopVertical", "BottomVertical"].includes(props.tile.style.legendStyle.legendPosition)) {
            return LegendTopBottomVerticalDisplay(props);
        }

        return LegendNormalDisplay(props);
    });
