const {getRadii, getArcSizeDegree} = require("./gauge-helpers");

const drawBorder = ({chart, style, theme, showMiddleBorder}) => {
    const middleBorderName = "middle-border";
    if (chart[middleBorderName]?.element) {
        chart[middleBorderName].destroy?.();
    }

    const outerBorderName = "outer-border";
    if (chart[outerBorderName]?.element) {
        chart[outerBorderName].destroy?.();
    }

    const yAxis = chart.yAxis[0];
    const center = {
        x: yAxis.center[0] + chart.plotLeft,
        y: yAxis.center[1] + chart.plotTop,
    };
    const {startAngle, endAngle} = arcSizeToRadianEnds(style.arcSize);

    // draw middle border when having compare
    if (theme.dataVisualization.gauges.showBorder && showMiddleBorder) {
        const {leastInnerRadius, splitPointRadius} = getRadii(style);
        const middleRadius = (yAxis.center[2] * splitPointRadius) / 100 / 2;

        chart[middleBorderName] = chart.renderer
            .arc(center.x, center.y, middleRadius, middleRadius, startAngle, endAngle)
            .attr({
                fill: "none",
                stroke: theme.dataVisualization.gauges.borderColorRGB,
                "stroke-width": theme.dataVisualization.gauges.borderWidth,
                zIndex: 3,
            })
            .add()
            .show();
    }

    // draw outer border

    if (theme.dataVisualization.gauges.showBorder) {
        const outerRadius = yAxis.center[2] / 2;

        chart[outerBorderName] = chart.renderer
            .arc(center.x, center.y, outerRadius, outerRadius, startAngle, endAngle)
            .attr({
                fill: "none",
                stroke: theme.dataVisualization.gauges.borderColorRGB,
                "stroke-width": theme.dataVisualization.gauges.borderWidth,
                zIndex: 3,
            })
            .add()
            .show();

        // this pane instance is old, so can't copy its path for new border
        // const paneElem = chart.pane[0].group.element.children[0];
        // const d = paneElem.getAttribute("d");
        // console.log(111, paneElem, d);

        // chart[outerBorderName] = chart.renderer.path(
        //     d.split(" ")
        // ).attr({
        //     fill: "none",
        //     stroke: theme.dataVisualization.gauges.borderColorRGB,
        //     "stroke-width": theme.dataVisualization.gauges.borderWidth,
        //     zIndex: 3,
        // }).add().show();
    }
};
exports.drawBorder = drawBorder;

const degreeToRadian = (degree) => (degree * Math.PI) / 180;

const arcSizeToRadianEnds = (arcSize) => {
    const arcSizeDegree = getArcSizeDegree(arcSize);
    const rotationAngle = -Math.PI / 2;
    const startAngleDegree = -1 * (arcSizeDegree / 2),
        endAngleDegree = arcSizeDegree / 2;
    return {
        startAngle: degreeToRadian(startAngleDegree) + rotationAngle,
        endAngle: degreeToRadian(endAngleDegree) + rotationAngle,
    };
};
