export const getTooltipDimensions = ({htmlString, padding}) => {
    let ghost = document.createElement("div");

    ghost.className = "ghost-tooltip-container";
    ghost.style.width = "max-content";

    // highcharts-tooltip-container leaves spaces around so need this padding
    // the padding depends on chart tile, which decides this padding before pass it into this
    // need the bottom of real tooltip to be at the right place, so set padding bottom to 0
    ghost.style.padding = `${padding}px ${padding}px 0 ${padding}px`;

    // ghost.style.position = "fixed";
    // ghost.style.right = "50px";
    // ghost.style.top = "200px";

    document.body.appendChild(ghost);
    ghost.innerHTML = htmlString;

    let rect = ghost.getBoundingClientRect();
    document.body.removeChild(ghost);

    return rect;
};
