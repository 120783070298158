export const generateArrayBetweenNumbers = (from, to) => {
    let list = [];
    for (let i = from; i <= to; i++) {
        list.push(i);
    }

    return list;
};

export const chunk = (arr, length) => {
    return Array(Math.ceil(arr.length / length))
        .fill(0)
        .map((x, i) => arr.slice(i * length, i * length + length));
};
