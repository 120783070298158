import {DropdownSelect} from "../../../../../../../../../../../../../../../../common/ui-components/dropdown-select/dropdown-select";
import "./condition-dropdown.scss";
import {cx} from "emotion";

export const CONDITIONAL = {
    Equal: "Equal To",
    NotEqual: "Not Equal To",
    GreaterThan: "Greater Than",
    GreaterThanOrEqual: "Greater Than Or Equal To",
    LessThan: "Less Than",
    LessThanOrEqual: "Less Than Or Equal To",
    Contains: "Contains",
    DoesNotContain: "Does Not Contain",
    Empty: "Empty",
    NotEmpty: "Not Empty",
    StartsWith: "Start With",
    Regex: "Regex",
};

export const getConditionalDropdownOptions = (aggregatedField) => [
    {
        logic: (tableField = {}) => ["Int", "Double", "DateTime", "DateTimeOffset"].indexOf(tableField.dataType) > -1,
        conditions: ["Equal", "NotEqual", "GreaterThan", "GreaterThanOrEqual", "LessThan", "LessThanOrEqual"],
    },
    {
        logic: (tableField = {}) => ["Text", "IDInt", "IDUUID", "Location"].indexOf(tableField.dataType) > -1 && aggregatedField,
        conditions: ["Equal", "NotEqual", "Empty", "NotEmpty", "Contains", "DoesNotContain", "StartsWith", "Regex"],
    },
    {
        logic: (tableField = {}) => ["Text", "IDInt", "IDUUID", "Location"].indexOf(tableField.dataType) > -1 && !aggregatedField,
        conditions: ["Equal", "NotEqual", "GreaterThan", "GreaterThanOrEqual", "LessThan", "LessThanOrEqual"],
    },
    {
        logic: (tableField = {}) => tableField.dataType == "Bool",
        conditions: ["Equal"],
    },
];

export const ConditionDropdown = ({tableField, state, className, isAggregatedField}) =>
    DropdownSelect({
        label: "Condition",
        list: getConditionalDropdownOptions(isAggregatedField).find((c) => c.logic(tableField))?.conditions || [],
        isSelected: (v) => state.value == v,
        valueToLabel: (v) => CONDITIONAL[v],
        className: className,
        expandClassName: "condition-dropdown-a33",
        onChange: state.onChange,
        expandWidth: 160,
    });
