const {required} = require("@common/react/cs-form/validators/required");

export const dataSourceFormConfig = ({apis, dataSource}) => ({
    folderID: [],
    name: {
        transforms: ["trim"],
        validators: [required],
        debounce: true,
        asyncValidators: [
            {
                validate: (name) => {
                    if (dataSource?.value.name === name) return Promise.resolve(true);
                    return apis.data.checkDataSourceName(name);
                },
                getMessage: () => `Existed`,
            },
        ],
    },
});
