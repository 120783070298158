import {LiveDashboard} from "@common/ui-components/live/live-dashboard/live-dashboard";
import collection from "./container-tabs-collection.json";
import data from "./container-tabs-data.json";

export const ContainerTabsThemeAuto = (props) => {
    return LiveDashboard({
        collection,
        defaultData: data,
        disabledTileActions: true,
        ...props,
    });
};
