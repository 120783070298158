import {cs} from "@common/react/chain-services";
import "./interaction-combo-chart.scss";
import {SkeletonBox} from "../../../../../common/skeleton/skeleton-box";
import {createArray} from "@common/utils/collections";
import {interactionComboChartRawData, interactionComboChartTheme, interactionComboChartTile} from "./interaction-combo-chart-config";
import {ComboChart} from "@common/ui-components/charts/combo-chart/combo-chart";
import {Static2} from "@common/react/static-2";
import {cChartOuterLayout} from "@common/ui-components/charts/chart-layout/chart-outer-layout";
import {consumeContext, provideContext} from "@common/react/context";
import momentTz from "moment-timezone";
import {keyed} from "@common/react/keyed";

export const InteractionComboChartNoData = () =>
    cs(() => (
        <div className="interaction-combo-chart-no-data-99o">
            <div className="chart">
                {createArray(30).map(() =>
                    SkeletonBox({
                        style: {
                            width: 12,
                            height: Math.floor(Math.random() * (116 - 40) + 40),
                        },
                    })
                )}
            </div>
        </div>
    ));

export const InteractionComboChart = ({comboChartData, _key}) =>
    cs(
        consumeContext("theme"),
        [
            "formatted",
            (_, next) =>
                next({
                    syncDuration: comboChartData.map((item) => [item.date, item.syncDuration]),
                    attempts: comboChartData.map((item) => [item.date, item.attempts]),
                    errors: comboChartData.map((item) => [item.date, item.errors]),
                    dataSynced: comboChartData.map((item) => [item.date, item.sizeMB]),
                }),
        ],
        ["chartRef", (_, next) => Static2({next})],
        ({chartRef, theme: _theme, formatted}) => {
            const size = {width: 10, height: 10};
            const theme = {
                ..._theme,
                ...interactionComboChartTheme,
            };

            const chartOuterLayout = cChartOuterLayout({
                tile: interactionComboChartTile,
                size,
                theme,
                style: {padding: 0},
            });

            return (
                <div
                    className="chart-content-container-99i"
                    style={{
                        position: "relative",
                        overflow: "hidden",
                        height: 140,
                        width: "100%",
                        // border: "1px solid #E9ECEF",
                        // backgroundColor: theme?.tileBackgroundColorRGB,
                        // borderColor: theme?.tileBorderColorRGB ?? "#E9ECEF",
                        // borderRadius: theme?.tileCornerRadius ?? 3,
                        // borderWidth: theme?.tileBorderWidth ?? 1,
                        // boxShadow: theme?.tileShadow ? "0 2px 8px 6px rgba(7, 6, 6, 0.05)" : "",
                    }}
                >
                    {chartOuterLayout({
                        content: (props) =>
                            cs(
                                keyed(_key),
                                (_, next) => provideContext("selectedTimezone", {value: momentTz.tz.guess()}, next),
                                () => (
                                    <ComboChart
                                        noScroll={true}
                                        chartRef={chartRef}
                                        tile={interactionComboChartTile}
                                        theme={theme}
                                        size={{width: 279, height: 140}}
                                        defaultData={interactionComboChartRawData(formatted)}
                                        tileFilters={{
                                            invalid: false,
                                            key: new Date().getTime(),
                                            getValue: () => ({}),
                                        }}
                                    />
                                )
                            ),
                    })}
                </div>
            );
        }
    );
