exports.numberFormats = [
    {
        label: "1,203.90",
        attrSet: {
            decimalSeperator: "Dot",
            thousandsSeperator: "Comma",
            decimalPlaces: 2,
            negativeFormat: "Parentheses",
            shiftDecimalPlaceLeft: 0,
        },
    },
    {
        label: "1203.90",
        attrSet: {
            decimalSeperator: "Dot",
            thousandsSeperator: "None",
            decimalPlaces: 2,
            negativeFormat: "Parentheses",
            shiftDecimalPlaceLeft: 0,
        },
    },
    {
        label: "1,204",
        attrSet: {
            decimalSeperator: "Dot",
            thousandsSeperator: "Comma",
            decimalPlaces: 0,
            negativeFormat: "Parentheses",
            shiftDecimalPlaceLeft: 0,
        },
    },
    {
        label: "1204",
        attrSet: {
            decimalSeperator: "Dot",
            thousandsSeperator: "None",
            decimalPlaces: 0,
            negativeFormat: "Parentheses",
            shiftDecimalPlaceLeft: 0,
        },
    },
];
