const {toRange} = require("../../../../utils/ranges");
const {conflictStrict} = require("../../../../utils/ranges");

const showDataLabels = ({points: allPoints, allowOverlap, getRect}) => {
    if (allPoints.length === 0) {
        return;
    }

    if (allowOverlap) {
        for (const point of allPoints) {
            point.dataLabel.div.style.visibility = "inherit";
            point.dataLabel.div.style.opacity = 1;
        }
        return;
    }

    const points = allPoints.filter((point) => point.dataLabel.visibility !== "hidden");

    const belongsToGroup = (point, group) => {
        const rect1 = getRect({point});
        for (const p of group) {
            // console.log("point", point.dataLabel.div.children[0])
            // console.log("p in group", p.dataLabel.div.children[0])
            // console.log("group", group.map((p) => p.dataLabel.div.children[0]))
            // console.log("point in group", group.indexOf(point) > -1)
            if (areConflicting({rect1, rect2: getRect({point: p})})) {
                // console.log("conflict")
                return true;
            }
            // console.log("not conflict")
        }
        return false;
    };

    let pointGroupCounter = 0;
    let pointGroups = {
        0: [points[0]],
    };

    // console.log("get point groups")
    for (let i = 1; i < points.length; i++) {
        if (belongsToGroup(points[i], pointGroups[pointGroupCounter])) {
            pointGroups[pointGroupCounter] = [...(pointGroups[pointGroupCounter] || []), points[i]];
        } else {
            pointGroups[pointGroupCounter + 1] = [...(pointGroups[pointGroupCounter + 1] || []), points[i]];
            pointGroupCounter++;
        }
    }

    // for (const groupIndex in pointGroups) {
    //     const maxPoint = findMaxE(pointGroups[groupIndex], (p) => p.y);
    //     for (const point of pointGroups[groupIndex]) {
    //         if (point !== maxPoint) {
    //             point.dataLabel.div.style.display = "none";
    //         }
    //     }
    //     maxPoint.dataLabel.div.style.visibility = "inherit";
    //     maxPoint.dataLabel.div.style.opacity = 1;
    // }

    const getShownFromPointGroup = (pg, previousPg) => {
        // console.log("get shown from point group")

        let shownGroup = [];

        for (let i = pg.length - 1; i >= 0; i--) {
            if ((shownGroup.length === 0 || !belongsToGroup(pg[i], shownGroup)) && (!previousPg || !belongsToGroup(pg[i], previousPg))) {
                shownGroup = [...shownGroup, pg[i]];
            }
        }

        return shownGroup;
    };

    for (const groupIndex in pointGroups) {
        // console.log("group", pointGroups[groupIndex].map((p) => p.dataLabel.div.children[0]))
        const shownPoints = getShownFromPointGroup(pointGroups[groupIndex], groupIndex > 0 && pointGroups[groupIndex - 1]);
        // console.log("shown points", shownPoints.map((p) => p.dataLabel.div.children[0]))
        for (const point of pointGroups[groupIndex]) {
            if (shownPoints.indexOf(point) > -1) {
                point.dataLabel.div.style.display = "block";
                point.dataLabel.div.style.visibility = "inherit";
                point.dataLabel.div.style.opacity = 1;
                if (point.dataLabel.div.style.left.startsWith("-")) {
                    point.dataLabel.div.style.left = 0;
                }
                // point.dataLabel.text.element.style.border = "1px solid black";
            } else {
                // console.log("not shown")
                point.dataLabel.div.style.display = "none";
                point.dataLabel.div.style.visibility = "hidden";
            }
        }
    }
};
exports.showDataLabels = showDataLabels;

const getDataLabelRect = ({point, useXSetting}) => {
    const {plotLeft, plotTop} = point.series.chart;
    return {
        x: (useXSetting ? point.dataLabel.xSetting : point.dataLabel.x) + plotLeft, // sometimes xSetting is more correct than x, need to find out why
        y: point.dataLabel.y + plotTop,
        width: point.dataLabel.width,
        height: point.dataLabel.height,
    };
};
exports.getDataLabelRect = getDataLabelRect;

const getDataLabelAbsRect = ({point}) => {
    const elem = point.dataLabel.div.children[0];
    return elem.getBoundingClientRect();
};
exports.getDataLabelAbsRect = getDataLabelAbsRect;

const areConflicting = ({rect1, rect2}) => {
    return conflictStrict(toRange(rect2.x, rect2.width), toRange(rect1.x, rect1.width)) && conflictStrict(toRange(rect2.y, rect2.height), toRange(rect1.y, rect1.height));
};
