import {Expandable} from "../../../../common/expandable/expandable";
import {LpColorLine} from "../../../../common/left-panel/lp-color-line";
import {ffToColorPickerLine} from "../../../../../../../common/form/ff-to-color-picker-line";
import {NumberInput} from "../../../../../../../common/form/number-input/number-input";
import {ffToNumberInput} from "../../../../../../../common/form/ff-to-number-input";
import * as React from "react";

export const ContainerTiles = ({form, sectionInitClosed}) => (
    <Expandable
        {...{
            initExpand: !sectionInitClosed,
            label: "Container Tiles",
            render: () => (
                <>
                    <div className="panel-33">
                        <div className="header">Button Style</div>

                        <div className="content">
                            {LpColorLine({
                                label: "Background Color",
                                ...ffToColorPickerLine(form.field(["container", "button", "backgroundColorRGB"])),
                            })}

                            {LpColorLine({
                                label: "Border Color",
                                ...ffToColorPickerLine(form.field(["container", "button", "borderColorRGB"])),
                            })}

                            {LpColorLine({
                                label: "Font Color",
                                ...ffToColorPickerLine(form.field(["container", "button", "fontColorRGB"])),
                            })}

                            {LpColorLine({
                                label: "Hover Background Color",
                                ...ffToColorPickerLine(form.field(["container", "button", "hoverBackgroundColorRGB"])),
                            })}

                            {LpColorLine({
                                label: "Hover Border Color",
                                ...ffToColorPickerLine(form.field(["container", "button", "hoverBorderColorRGB"])),
                            })}

                            {LpColorLine({
                                label: "Hover Font Color",
                                ...ffToColorPickerLine(form.field(["container", "button", "hoverFontColorRGB"])),
                            })}

                            {LpColorLine({
                                label: "Active Background Color",
                                ...ffToColorPickerLine(form.field(["container", "button", "activeBackgroundColorRGB"])),
                            })}

                            {LpColorLine({
                                label: "Active Border Color",
                                ...ffToColorPickerLine(form.field(["container", "button", "activeBorderColorRGB"])),
                            })}

                            {LpColorLine({
                                label: "Active Font Color",
                                ...ffToColorPickerLine(form.field(["container", "button", "activeFontColorRGB"])),
                            })}

                            {NumberInput({
                                label: "Border Width",
                                ...ffToNumberInput(form.field(["container", "button", "borderWidth"])),
                                unit: "pixels",
                                showZero: true,
                            })}

                            {NumberInput({
                                label: "Corner Radius",
                                ...ffToNumberInput(form.field(["container", "button", "cornerRadius"])),
                                unit: "pixels",
                                showZero: true,
                            })}
                        </div>
                    </div>
                    <div className="panel-33">
                        <div className="header">Tab Style</div>

                        <div className="content">
                            {LpColorLine({
                                label: "Background Color",
                                ...ffToColorPickerLine(form.field(["container", "tab", "backgroundColorRGB"])),
                            })}

                            {LpColorLine({
                                label: "Border Color",
                                ...ffToColorPickerLine(form.field(["container", "tab", "borderColorRGB"])),
                            })}

                            {LpColorLine({
                                label: "Font Color",
                                ...ffToColorPickerLine(form.field(["container", "tab", "fontColorRGB"])),
                            })}

                            {NumberInput({
                                label: "Bottom Border Height",
                                ...ffToNumberInput(form.field(["container", "tab", "bottomBorderHeight"])),
                                unit: "pixels",
                                showZero: true,
                            })}

                            {LpColorLine({
                                label: "Hover Background Color",
                                ...ffToColorPickerLine(form.field(["container", "tab", "hoverBackgroundColorRGB"])),
                            })}

                            {LpColorLine({
                                label: "Hover Border Color",
                                ...ffToColorPickerLine(form.field(["container", "tab", "hoverBorderColorRGB"])),
                            })}

                            {LpColorLine({
                                label: "Hover Font Color",
                                ...ffToColorPickerLine(form.field(["container", "tab", "hoverFontColorRGB"])),
                            })}

                            {LpColorLine({
                                label: "Active Background Color",
                                ...ffToColorPickerLine(form.field(["container", "tab", "activeBackgroundColorRGB"])),
                            })}

                            {LpColorLine({
                                label: "Active Border Color",
                                ...ffToColorPickerLine(form.field(["container", "tab", "activeBorderColorRGB"])),
                            })}

                            {LpColorLine({
                                label: "Active Font Color",
                                ...ffToColorPickerLine(form.field(["container", "tab", "activeFontColorRGB"])),
                            })}
                        </div>
                    </div>

                    <div className="panel-33">
                        <div className="header">Dropdown Style</div>

                        <div className="content">
                            {LpColorLine({
                                label: "Background Color",
                                ...ffToColorPickerLine(form.field(["container", "dropDown", "backgroundColorRGB"])),
                            })}

                            {LpColorLine({
                                label: "Border Color",
                                ...ffToColorPickerLine(form.field(["container", "dropDown", "borderColorRGB"])),
                            })}

                            {LpColorLine({
                                label: "Font Color",
                                ...ffToColorPickerLine(form.field(["container", "dropDown", "fontColorRGB"])),
                            })}

                            {NumberInput({
                                label: "Border Width",
                                ...ffToNumberInput(form.field(["container", "dropDown", "borderWidth"])),
                                unit: "pixels",
                                showZero: true,
                            })}

                            {NumberInput({
                                label: "Corner Radius",
                                ...ffToNumberInput(form.field(["container", "dropDown", "cornerRadius"])),
                                unit: "pixels",
                                showZero: true,
                            })}
                        </div>
                    </div>

                    <div className="panel-33">
                        <div className="header">Dropdown Open</div>

                        <div className="content">
                            {LpColorLine({
                                label: "Active Background Color",
                                ...ffToColorPickerLine(form.field(["container", "dropDown", "activeBackgroundColorRGB"])),
                            })}

                            {LpColorLine({
                                label: "Active Border Color",
                                ...ffToColorPickerLine(form.field(["container", "dropDown", "activeBorderColorRGB"])),
                            })}

                            {LpColorLine({
                                label: "Active Font Color",
                                ...ffToColorPickerLine(form.field(["container", "dropDown", "activeFontColorRGB"])),
                            })}

                            {LpColorLine({
                                label: "Item Hover Background Color",
                                ...ffToColorPickerLine(form.field(["container", "dropDown", "hoverBackgroundColorRGB"])),
                            })}

                            {NumberInput({
                                label: "Border Width",
                                ...ffToNumberInput(form.field(["container", "dropDown", "borderWidth"])),
                                unit: "pixels",
                                showZero: true,
                            })}

                            {NumberInput({
                                label: "Corner Radius",
                                ...ffToNumberInput(form.field(["container", "dropDown", "cornerRadius"])),
                                unit: "pixels",
                                showZero: true,
                            })}
                        </div>
                    </div>
                </>
            ),
        }}
    />
);
