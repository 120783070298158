import React from "react";
import {cs} from "@common/react/chain-services";
import {TextManipulationResultActionSelect} from "../../common/text-manipulation-result-action-select";
import {rColumnValueSelectFromStepOutput} from "../../../../../common/column-value-select-from-step-output/column-value-select-from-step-output";
import {ffToDropdown} from "../../../../../../../../../../common/form/ff-to-dropdown";
import {keepOnly} from "@common/utils/objects";
import {NumberInput} from "../../../../../../../../../../common/form/number-input/number-input";

export const LeftConfiguration = ({textManipulation, inputStep, state, model, form}) =>
    cs(() => {
        return (
            <>
                <div className="config-group">
                    <div className="label">Input Column</div>

                    {(() => {
                        const dropdownProps = ffToDropdown(form.field(["textManipulation", "inputValue"]));
                        return rColumnValueSelectFromStepOutput({
                            label: "Column",
                            columns: inputStep.outputColumns,
                            model,
                            disableStaticValue: true,
                            filterColumnFn: (c) => ["Text", "IDUUID"].includes(c.dataType),
                            isValueNotAllowed: (c) => !["Text", "IDUUID"].includes(c.dataType),
                        })({
                            state: {
                                value: dropdownProps.value,
                                onChange: dropdownProps.onChange,
                            },
                            ...keepOnly(dropdownProps, ["domRef", "hasError", "errorMessage"]),
                        });
                    })()}
                </div>

                <div className="config-group">
                    <div className="label">Length</div>

                    <div className="flex-group">
                        {(() => {
                            const dropdownProps = ffToDropdown(form.field(["textManipulation", "length"]));
                            return rColumnValueSelectFromStepOutput({
                                columns: inputStep.outputColumns,
                                model,
                                filterColumnFn: (c) => ["Double", "Int"].includes(c.dataType),
                                isValueNotAllowed: (c) => !["Double", "Int"].includes(c.dataType),
                                renderStaticInput: ({state}) => NumberInput({state, label: "Value"}),
                            })({
                                state: {
                                    value: dropdownProps.value,
                                    onChange: dropdownProps.onChange,
                                },
                                ...keepOnly(dropdownProps, ["domRef", "hasError", "errorMessage"]),
                            });
                        })()}
                    </div>
                </div>

                {TextManipulationResultActionSelect({
                    state: textManipulation,
                    form,
                })}
            </>
        );
    });
