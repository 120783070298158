export const InnerJoinIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18.67" height="12.001" viewBox="0 0 18.67 12.001">
        <path
            data-name="Inner JOin"
            d="M-11518.665-128.014A5.966,5.966,0,0,1-11522-127a6.008,6.008,0,0,1-6-6,6.009,6.009,0,0,1,6-6,5.964,5.964,0,0,1,3.335,1.015,5.96,5.96,0,0,1,3.333-1.015,6.008,6.008,0,0,1,6,6,6.006,6.006,0,0,1-6,6A5.962,5.962,0,0,1-11518.665-128.014Zm3.333-.32a4.67,4.67,0,0,0,4.667-4.667,4.672,4.672,0,0,0-4.667-4.667,4.64,4.64,0,0,0-2.269.59,5.979,5.979,0,0,1,1.551,3.3c0,.006,0,.012,0,.018s0,.034.007.052,0,.022,0,.033,0,.033,0,.049l0,.034c0,.016,0,.031,0,.047s0,.032,0,.047l0,.036.006.081c0,.01,0,.021,0,.031l0,.055c0,.011,0,.022,0,.034s0,.037,0,.055,0,.021,0,.031,0,.039,0,.058v.029c0,.029,0,.057,0,.086s0,.057,0,.086v.03c0,.019,0,.037,0,.056s0,.023,0,.035,0,.033,0,.05,0,.027,0,.04l0,.041c0,.015,0,.031,0,.046a.032.032,0,0,0,0,.01,5.975,5.975,0,0,1-1.585,3.685A4.635,4.635,0,0,0-11515.332-128.334Zm-11.335-4.667a4.672,4.672,0,0,0,4.667,4.667,4.638,4.638,0,0,0,2.27-.59,5.979,5.979,0,0,1-1.6-4.077,5.976,5.976,0,0,1,1.6-4.076,4.642,4.642,0,0,0-2.271-.59A4.674,4.674,0,0,0-11526.667-133Z"
            transform="translate(11528.001 139.001)"
            fill="#546b81"
        />
    </svg>
);

export const LeftOuterJoinIcon = ({position, fill = "#546b81"}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18.667"
        height="12"
        viewBox="0 0 18.667 12"
        {...(position
            ? {
                  x: position?.x - 9,
                  y: position?.y - 6,
              }
            : {})}
    >
        <path
            data-name="Subtraction 19"
            d="M12.666,12a5.965,5.965,0,0,1-3.331-1.012,6,6,0,1,1,0-9.976A6,6,0,1,1,12.666,12ZM9.335,1.012a5.995,5.995,0,0,0,0,9.976,6.053,6.053,0,0,0,1.065-.91,4.664,4.664,0,0,1,0-8.157,6.064,6.064,0,0,0-1.065-.91Zm3.331.322a4.672,4.672,0,0,0-2.266.588,6,6,0,0,1,0,8.157,4.666,4.666,0,1,0,2.266-8.745Z"
            fill={fill}
        />
    </svg>
);

export const OuterJoinChildCanvas = ({className}) => (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} width="12.173" height="15.716" viewBox="0 0 12.173 15.716">
        <g id="Outer_Join_Child" data-name="Outer Join Child" transform="translate(1 1.631)">
            <line
                id="Line_318"
                data-name="Line 318"
                x2="8.673"
                transform="translate(2.5 5.734)"
                fill="none"
                stroke="#546b81"
                strokeWidth="1"
            />
            <line
                id="Line_1"
                data-name="Line 1"
                y1="6.75"
                x2="7.173"
                transform="translate(2.5 -1.266)"
                fill="none"
                stroke="#546b81"
                strokeWidth="1"
            />
            <line
                id="Line_2"
                data-name="Line 2"
                x2="7.173"
                y2="7.25"
                transform="translate(2.5 6.484)"
                fill="none"
                stroke="#546b81"
                strokeWidth="1"
            />
            <g id="Ellipse_1" data-name="Ellipse 1" transform="translate(-1 2.484)" fill="#fff" stroke="#546b81" strokeWidth="1">
                <circle cx="3.5" cy="3.5" r="3.5" stroke="none" />
                <circle cx="3.5" cy="3.5" r="3" fill="none" />
            </g>
        </g>
    </svg>
);

export const SwapRelationshipIcon = ({className, onClick}) => (
    <svg className={className} onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
        <path
            data-name="Swap Relationship"
            d="M8.49,13.5l-3.99,4,3.99,4v-3H15.5v-2H8.49Zm14.01-2-3.99-4v3H11.5v2h7.01v3Z"
            transform="translate(-4.5 -7.5)"
            fill="#11a1fd"
        />
    </svg>
);
