import React, {createElement as h} from "react";
import {cs} from "@common/react/chain-services";
import {consumeContext, provideContext} from "@common/react/context";
import {TransformationInteractions} from "./transformation-interactions";
import {TransformationDiagramPanel} from "./transformation-diagram-panel/transformation-diagram-panel";
import {cRightPanel} from "./right-panel/right-panel";
import {Layout2} from "../../../common/layout2/layout-2";
import {LeftPanel} from "./left-panel/left-panel";
import {spc} from "@common/react/state-path-change";
import {LeaveEditTransformationDialog} from "./leave-edit-transformation-dialog/leave-edit-transformation-dialog";
import {PublishTransformationDialog} from "./publish-transformation-dialog/publish-transformation-dialog";
import {getDSColorBasedOnIndex} from "../../edit/tabs/common/data-source-colors";
import {LoadingIndicator} from "@common/ui-components/loading-indicator/loading-indicator";
import "./transformation-main.scss";
import {cascadeFind} from "@common/utils/cascade";
import {ChangeQueue1} from "@common/react/change-queue-1";
import {createAutoSaveModel} from "../../common/auto-save-model";
import {isAggregatedMeasure, isDataView} from "../common/transformation-utils";
import {fragments} from "@common/react/fragments";
import {transformTablesColumnsToMap} from "./utils";
import {Static2} from "@common/react/static-2";
import {Watch} from "@common/react/watch";
import {ToastWithAction2} from "../../../common/toast/ToastWithAction2";
import {omit} from "@common/utils/objects";

const TransformModelTablesDataToMap = ({model, next: rootNext}) => {
    return cs(
        [
            "tablesData",
            (_, next) =>
                Static2({
                    getInitValue: () => {},
                    next,
                }),
        ],
        ({tablesData}, next) =>
            model?.value
                ? Watch({
                      onChanged: () => tablesData.set(transformTablesColumnsToMap(model.value.tables)),
                      initRun: true,
                      value: model.value.id,
                      next,
                  })
                : next(),
        ({tablesData}) => rootNext(tablesData.get())
    );
};

export const TransformationMain = ({transformation, saving, updatePositionQueue, dataSourcesSummary, environment}) =>
    cs(
        consumeContext("routing"),
        consumeContext("apis"),
        consumeContext("model"),
        ["tablesColumnsMap", ({model}, next) => TransformModelTablesDataToMap({model, next})],
        [
            "savingQueue",
            ({apis, model, routing}, next) =>
                ChangeQueue1({
                    save: apis.model.upsertModel,
                    fetchLatest: () => apis.model.getModel(routing.params.modelId),
                    next,
                }),
        ],
        ({model}, next) => cGetModelColumn({model, next}),
        [
            "interactions",
            (_, next) =>
                !transformation.value
                    ? next({})
                    : h(TransformationInteractions, {
                          transformation,
                          next,
                          updatePositionQueue,
                      }),
        ],
        [
            "actionToast",
            ({interactions}, next) =>
                ToastWithAction2({
                    next,
                    onShow: (config, fn, data) => {
                        const setting = interactions.getStaticSetting();
                        if (setting?.data?.stepId !== data?.stepId) {
                            fn(config);
                        }
                    },
                }),
        ],
        ({actionToast}, next) => provideContext("actionToast", actionToast, next),
        ({actionToast}, next) => fragments(actionToast.render(), next()),
        ["leaveEditTransformationDialog", (_, next) => LeaveEditTransformationDialog({next})],
        [
            "publishTransformation",
            (_, next) =>
                PublishTransformationDialog({
                    transformation: transformation.value,
                    next,
                }),
        ],
        [
            "transformationDiagramPanel",
            ({interactions, model}, next) => {
                if (!transformation.value || !model.value || !dataSourcesSummary) {
                    return next({
                        render: () =>
                            LoadingIndicator({
                                className: "loading-transformation-ad3",
                            }),
                    });
                }

                return TransformationDiagramPanel({
                    environment,
                    transformation: {
                        ...transformation,
                        value: {
                            ...transformation.value,
                            steps: transformation.value?.steps?.map((s) => omit(s, ["outputColumns"])),
                        },
                    },
                    interactions,
                    getConfiguredColor: (tableId) => {
                        const dataSourceIDs = (dataSourcesSummary || []).map((ds) => ds.id);
                        const index = dataSourceIDs.indexOf(model.value.tables.find((t) => t.id == tableId)?.dataSourceID);
                        return dataSourcesSummary[index]?.colorRGB || getDSColorBasedOnIndex(index);
                    },
                    isAggregatedMeasure: isAggregatedMeasure(transformation.value),
                    dataSourcesSummary,
                    next,
                });
            },
        ],
        ({
            routing,
            interactions,
            transformationDiagramPanel,
            rightPanel,
            leaveEditTransformationDialog,
            publishTransformation,
            model,
            savingQueue,
            tablesColumnsMap,
        }) => {
            return Layout2({
                back: {
                    onClick: async () => {
                        const outputStep = transformation.value?.steps.find((s) =>
                            ["ViewOutputStep", "ColumnOutputStep"].includes(s.$type)
                        );
                        const routingParams = {
                            modelId: routing.params.modelId,
                            ...(routing.params.isNew
                                ? {
                                      transformationID: routing.params.transformationId,
                                  }
                                : {}),
                        };

                        if (!outputStep.inputStepID) {
                            const answer = await leaveEditTransformationDialog.show();
                            if (answer === "save-as-draft") {
                                routing.goto("edit-model", routingParams);
                                model.reload();
                            } else {
                                // stay in editing transformation
                            }
                        } else {
                            routing.goto("edit-model", routingParams);
                            model.reload();
                        }
                    },
                    label: `Done editing ${
                        isDataView(transformation.value) ? "view" : isAggregatedMeasure(transformation.value) ? "measure" : "column"
                    }`,
                },
                editing: {
                    label: transformation.value?.name,
                },
                leftPanel: {
                    content: LeftPanel({
                        transformation,
                        interactions,
                        model: createAutoSaveModel({
                            model,
                            savingQueue,
                            readOnly: !!environment?.readOnly,
                        }),
                        dataSources: dataSourcesSummary,
                        startDraggingNewStep: (newStep) => transformationDiagramPanel.startDraggingNewStep?.(newStep),
                    }),
                    width: 240,
                },
                main: transformationDiagramPanel.render(),
                rightPanel: {
                    content: cs(
                        [
                            "rightPanel",
                            (_, next) =>
                                cRightPanel({
                                    transformation: {
                                        ...transformation,
                                    },
                                    tablesColumnsMap,
                                    model: model.value,
                                    interactions,
                                    onDeleteStep: () => {
                                        interactions.onCancel();
                                        spc(transformation, ["steps"], (steps) =>
                                            steps
                                                .filter((s) => s.id !== interactions.setting.data.stepId)
                                                .map((s) => {
                                                    if (s.inputStepID) {
                                                        return {
                                                            ...s,
                                                            inputStepID:
                                                                s.inputStepID === interactions.setting.data.stepId ? null : s.inputStepID,
                                                        };
                                                    }

                                                    if (s.inputStep1ID || s.inputStep2ID) {
                                                        return {
                                                            ...s,
                                                            inputStep1ID:
                                                                s.inputStep1ID === interactions.setting.data.stepId ? null : s.inputStep1ID,
                                                            inputStep2ID:
                                                                s.inputStep2ID === interactions.setting.data.stepId ? null : s.inputStep2ID,
                                                        };
                                                    }
                                                    return s;
                                                })
                                        );
                                    },
                                    next,
                                }),
                        ],
                        ({rightPanel}) => rightPanel
                    ),
                    width: interactions.setting?.showPreview ? window.innerWidth - 240 : 480,
                    overlapHeader: true,
                },
                syncState: {
                    type: saving || updatePositionQueue.executing ? "saving" : "saved",
                },
                onPublish: () => publishTransformation.show(),
            });
        }
    );

const cGetModelColumn = ({model, next}) =>
    cs(
        [
            "getModelColumn",
            (_, next) => {
                const getModelColumn = (col) => {
                    if (!col) {
                        return;
                    }

                    return cascadeFind(model.value, "tables[*].columns[*]", (column, {}, {1: table}) => {
                        if (
                            (col.$type === "ModelStepColumn" &&
                                column.id === col.modelColumnID &&
                                table.id === col.modelTableID &&
                                model.value.id === col.modelID) ||
                            (col.$type === "ViewStepColumn" && column.name === col.name)
                        ) {
                            return {
                                ...column,
                                tableName: table.name,
                            };
                        }
                    });
                };
                return next(getModelColumn);
            },
        ],
        ({getModelColumn}) => provideContext("getModelColumn", getModelColumn, next)
    );
