import {getFieldFormatter} from "../../table/table/common/get-columns";

export const headerDelim = "~!@#";
export const keyDelim = "$%^*";

export const columnValueService = (values, colSubTotals, rowsSubTotals, totals, timezone) => {
    return {
        getValue: (row, column) => {
            try {
                if (row.latestChild && column.parent.flattenID && !column.parent.totalColumn) {
                    if (values[`${row.flattenID}${keyDelim}${column.parent.flattenID}`]) {
                        return values[`${row.flattenID}${keyDelim}${column.parent.flattenID}`][column.aggIndex].value;
                    }

                    if (colSubTotals[`${column.parent.flattenID}${keyDelim}${row.flattenID}`]) {
                        return colSubTotals[`${column.parent.flattenID}${keyDelim}${row.flattenID}`][column.aggIndex].value;
                    }

                    if (rowsSubTotals[`${row.flattenID}${keyDelim}${column.parent.flattenID}`]) {
                        return rowsSubTotals[`${row.flattenID}${keyDelim}${column.parent.flattenID}`][column.aggIndex].value;
                    }
                }

                if (column.parent.flattenID) {
                    if (!column.parent.totalColumn) {
                        return colSubTotals[`${column.parent.flattenID}${keyDelim}${row.flattenID}`][column.aggIndex].value;
                    }

                    if (rowsSubTotals[`${row.flattenID}${keyDelim}${column.parent.flattenID}`]) {
                        return rowsSubTotals[`${row.flattenID}${keyDelim}${column.parent.flattenID}`][column.aggIndex].value;
                    } else {
                        return null;
                    }
                }

                if (rowsSubTotals[`${row.flattenID}${keyDelim}`]) {
                    return rowsSubTotals[`${row.flattenID}${keyDelim}`][column.aggIndex].value;
                }

                return colSubTotals[`${row.flattenID}${keyDelim}`][column.aggIndex].value;
            } catch (err) {
                // console.log(err);
                return null;
            }
        },
        renderGrandTotalRow: (column) => {
            if (colSubTotals[`${column.parent.flattenID}${keyDelim}`]) {
                return colSubTotals[`${column.parent.flattenID}${keyDelim}`][column.aggIndex].value;
            }

            return totals[column.aggIndex].value;
        },
        getValueLabel: (column, headers) => {
            const ids = column.parent.flattenID?.split(headerDelim);
            let name = [];

            if (column.parent == "Grand Total") return "Grand Total";

            for (let i = 0; i < ids.length; i++) {
                let id = ids[i];
                let header = headers[i];

                for (let group of header) {
                    for (let item of group.items) {
                        if (item.groupIndex == parseInt(id)) {
                            name.push(item.name);
                        }
                    }
                }
            }

            return name.join(" - ");
        },
        formatValue: (tile, aggIndex, value) => {
            if (value === null) return null;
            const field = tile.valueFields[aggIndex];
            const formatter = getFieldFormatter(field, timezone, tile);
            return formatter(value);
        },
    };
};
