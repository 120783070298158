import "./live-filters-desktop.scss";

import {cx} from "emotion";
import React from "react";

import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {OnMounted} from "@common/react/on-mounted";
import {Static2} from "@common/react/static-2";
import {UseState} from "@common/react/use-state";

import {StyledClass} from "@common/react/styled-class";
import {SelectTimezoneDropdown} from "./select-timezone";
import {fragments} from "@common/react/fragments";
import {OnUnmounted} from "@common/react/on-unmounted";
import {RAFLoop} from "@common/utils/loop";

export const LiveFiltersDesktop = ({
    filters,
    showTimezoneDropdown = false,
    renderFilter,
    noLabel,
    runReportButton,
    downLoadButton,
    theme,
    valueCount,
    wrapLine,
    customWindowWidth,
    filterDialog,
}) =>
    cs(
        ["localFilters", (_, next) => UseState({next, initValue: null})],
        ["displayFilters", (_, next) => UseState({next, initValue: filters.map((f) => f.id)})],
        ["preRenderRef", (_, next) => Static2({next})],
        ["containerRef", (_, next) => Static2({next})],
        ["rightBtnRef", (_, next) => Static2({next})],
        ["removeFuncRef", (_, next) => Static2({next})],
        [
            "getDisplayFilters",
            ({localFilters, containerRef, rightBtnRef}, next) =>
                next(() => {
                    if (!localFilters.value) return [];

                    const {width: containerWidth} = customWindowWidth ? {width: customWindowWidth} : containerRef.get().getBoundingClientRect();
                    const {width: buttonContainerWidth} = rightBtnRef.get().getBoundingClientRect();
                    const maxWidth = containerWidth - buttonContainerWidth;
                    let totalWidth = 0;
                    let ret = [];
                    for (let filter of localFilters.value) {
                        if (totalWidth + filter.width > maxWidth) {
                            return ret;
                        } else {
                            if (filters.find((f) => f.id == filter.id && f.displayArea == "Visible")) {
                                totalWidth += filter.width;
                                ret.push(filter.id);
                            }
                        }
                    }

                    return ret;
                }),
        ],
        ({containerRef, removeFuncRef, rightBtnRef, preRenderRef, localFilters, displayFilters, getDisplayFilters}, next) =>
            fragments(
                next(),
                OnMounted({
                    action: () => {
                        let updatedFilters = [];
                        const filterElements = preRenderRef.get().getElementsByClassName("filter");
                        for (let i = 0; i < filterElements.length; i++) {
                            let element = filterElements[i];
                            updatedFilters.push({
                                id: filters[i].id,
                                width: element.getBoundingClientRect().width + 20,
                            });
                        }

                        localFilters.onChange(updatedFilters);

                        const removeInterval = RAFLoop.addTarget({
                            update: () => {
                                if (!preRenderRef.get() || !containerRef.get()) {
                                    return;
                                }

                                const _currentDisplayFilters = getDisplayFilters();
                                if (JSON.stringify(_currentDisplayFilters) != JSON.stringify(displayFilters.get())) {
                                    displayFilters.onChange(_currentDisplayFilters);
                                }
                            },
                        });

                        removeFuncRef.set(removeInterval);
                    },
                }),
                OnUnmounted({
                    action: () => {
                        const func = removeFuncRef.get();
                        func?.();
                    },
                })
            ),

        ({localFilters, preRenderRef, containerRef, displayFilters, rightBtnRef, getDisplayFilters}) => {
            const visibleFilters = filters.filter((filter) => {
                if (wrapLine) {
                    return filter.displayArea == "Visible";
                }

                return getDisplayFilters().indexOf(filter.id) > -1;
            });
            const nonHiddenFilters = filters.filter((f) => f.displayArea !== "Hidden");
            // const behindMoreFilters = filters.filter((f) => f.displayArea === "BehindMore");

            return (
                <div
                    className={cx("live-filters-layout-0a0 desktop live-filter-desktop-a33", {noLabel})}
                    style={{
                        fontFamily: `"${theme.general.canvas.fontFamily || "Roboto"}", sans-serif`,
                        ...(!localFilters.value ? {opacity: 0, pointerEvents: "none"} : {}),
                    }}
                    ref={containerRef.set}
                >
                    <div className="left">
                        {!localFilters.value ? (
                            <div className="filters filter-single-line-pre-render-a33" ref={preRenderRef.set}>
                                {filters.map(renderFilter)}
                            </div>
                        ) : (
                            cs(() => {
                                if (visibleFilters.length == 0 && nonHiddenFilters.length > 0) {
                                    return (
                                        <MoreFilterButton className={cx("filters-button", valueCount > 0 && "has-value-count")} onClick={() => filterDialog.show()}>
                                            Filters{" "}
                                            {valueCount > 0 && (
                                                <span className="value-wrapper">
                                                    {" "}
                                                    <span className="splash" /> {valueCount}
                                                </span>
                                            )}
                                        </MoreFilterButton>
                                    );
                                }

                                return <div className="filters">{visibleFilters.map(renderFilter)}</div>;
                            })
                        )}
                    </div>

                    <div className="right" ref={rightBtnRef.set}>
                        {visibleFilters.length > 0 && visibleFilters.length != nonHiddenFilters.length && (
                            <MoreFilterButton
                                className={cx("more-btn")}
                                onClick={() =>
                                    filterDialog.show({
                                        visibleFilters,
                                    })
                                }
                            >
                                More
                            </MoreFilterButton>
                        )}

                        {showTimezoneDropdown && SelectTimezoneDropdown({theme})}

                        {runReportButton && visibleFilters.length > 0 && <div className="run-report-btn">{runReportButton({})}</div>}

                        {downLoadButton}
                    </div>
                </div>
            );
        }
    );

export const MoreFilterButton = ({onClick, children, className}) =>
    cs(
        consumeContext("theme"),
        [
            "buttonCss",
            ({theme}, next) => {
                const secondaryButtonTheme = theme.general.buttons.secondaryButton;

                return StyledClass({
                    content: {
                        background: `${secondaryButtonTheme.backgroundColorRGB}`,
                        color: `${secondaryButtonTheme.fontColorRGB}`,
                        border: `${secondaryButtonTheme.borderWidth}px solid ${secondaryButtonTheme.borderColorRGB}`,
                        "border-radius": `${secondaryButtonTheme.cornerRadius}px`,

                        "&:hover": {
                            background: `${secondaryButtonTheme.hoverBackgroundColorRGB}`,
                            color: `${secondaryButtonTheme.hoverFontColorRGB}`,
                            "border-color": `${secondaryButtonTheme.hoverBorderColorRGB}`,
                        },
                    },
                    next,
                });
            },
        ],
        ({buttonCss}) => {
            return (
                <button className={cx("more-btn", className, buttonCss)} onClick={onClick}>
                    {children}
                </button>
            );
        }
    );
