export const stateArraySupport = (state, indexes, isObjectUpdate) => {
    const getValue = (currentDeep, value) => {
        if (indexes[currentDeep + 1] !== undefined) {
            return getValue(currentDeep + 1, value[indexes[currentDeep]]);
        }

        return value[indexes[currentDeep]];
    };

    const getUpdatedValue = (currentDeep, array, updated) => {
        return array.map((item, index) => {
            if (index == indexes[currentDeep]) {
                if (indexes[currentDeep + 1] !== undefined) {
                    return getUpdatedValue(currentDeep + 1, item, updated);
                } else {
                    if (isObjectUpdate) {
                        return {
                            ...item,
                            ...updated,
                        };
                    }

                    return updated;
                }
            }

            return item;
        });
    };

    return {
        value: getValue(0, state.value),
        onChange: (updated) => state.onChange(getUpdatedValue(0, state.value, updated)),
    };
};
