const {waitTimeout} = require("../../../../common/utils/wait-timeout");
const {sampleTenant, sampleAuthTestResponse} = require("../../../../common/logic/sample-api/sample-tenant");
const {sampleEnvironments} = require("../../../../common/logic/sample-api/sample-environments");

const createBasicMockApis = ({authToken}) => {
    return {
        user: {
            signOut: async () => {},
        },
        tenant: {
            getTenant: () => sampleTenant,
            upsertTenant: async (tenant) => {
                await waitTimeout(100);
                return tenant;
            },
            getAuthParameters: () => [`btoken${Math.random() * 10}`, `uname${Math.random() * 10}`],
            authTest: async (authTestReq) => {
                await waitTimeout(500);
                return sampleAuthTestResponse;
            },
            getEnvironments: () => sampleEnvironments,
            upsertEnvironment: async (env) => {
                await waitTimeout(500);
                // console.log("upsert env", env);
                return env;
            },
            checkEnvironmentName: async () => {
                await waitTimeout(500);
                // return Math.round(Math.random()) === 1;
                return true;
            },
        },
    };
};
exports.createBasicMockApis = createBasicMockApis;
