import React from "react";
import {cs} from "@common/react/chain-services";
import "./column-security-main.scss";
import {UseState} from "@common/react/use-state";
import {OuterBlock} from "./outer-block/outer-block";
import {Watch} from "@common/react/watch";
import {waitTimeout} from "@common/utils/wait-timeout";
import {spc} from "@common/react/state-path-change";
import {equalDeep, omit} from "@common/utils/objects";
import {Button} from "../../../../../../../../../common/form/buttons/button/button";

export const getTimestamp = () => new Date().getTime() + Math.random();

const defaultBlocks = {
    outerBlocks: [
        {
            timestamp: getTimestamp(),
            innerBlocks: [
                {
                    leftValue: null,
                    timestamp: getTimestamp(),
                    compareOp: "Equal",
                },
            ],
        },
    ],
};

export const ColumnSecurityMain = ({columnLevelSecurity, activeTab, column, introduction, onUpdate, ...otherProps}) =>
    cs(
        [
            "modifyValue",
            (_, next) =>
                next((value) => {
                    if (!value) return defaultBlocks;
                    const insertTimestamp = (block) => ({
                        timestamp: getTimestamp(),
                        innerBlocks: block.innerBlocks.map((innerBlock) => ({
                            ...innerBlock,
                            timestamp: getTimestamp(),
                            rightValue: innerBlock.rightValue
                                ? {
                                      ...innerBlock.rightValue,
                                      orAndBlock: innerBlock.rightValue.orAndBlock
                                          ? {
                                                ...innerBlock.rightValue.orAndBlock,
                                                outerBlocks: innerBlock.rightValue.orAndBlock.outerBlocks.map((block) => insertTimestamp(block)),
                                            }
                                          : null,
                                  }
                                : null,
                        })),
                    });

                    return {
                        ...value,
                        outerBlocks: value.outerBlocks.map((block) => insertTimestamp(block)),
                    };
                }),
        ],
        [
            "formatValue",
            (_, next) =>
                next((value) => {
                    const removeTimestamp = (block) => ({
                        innerBlocks: block.innerBlocks.map((innerBlock) => ({
                            ...omit(innerBlock, ["timestamp"]),
                            rightValue: innerBlock.rightValue
                                ? {
                                      ...innerBlock.rightValue,
                                      orAndBlock: innerBlock.rightValue.orAndBlock
                                          ? {
                                                ...innerBlock.rightValue.orAndBlock,
                                                outerBlocks: innerBlock.rightValue.orAndBlock.outerBlocks.map((block) => removeTimestamp(block)),
                                            }
                                          : null,
                                  }
                                : null,
                        })),
                    });

                    return {
                        ...value,
                        outerBlocks: value.outerBlocks.map((block) => removeTimestamp(block)),
                    };
                }),
        ],
        [
            "isValid",
            (_, next) =>
                next((value) => {
                    let flag = true;

                    const loop = (outerBlocks) => {
                        for (let block of outerBlocks) {
                            for (let innerBlock of block.innerBlocks) {
                                if (Object.keys(innerBlock.leftValue || {}).length <= 1 || Object.keys(innerBlock.rightValue || {}).length <= 1) {
                                    flag = false;
                                }
                                if (innerBlock.rightValue?.orAndBlock) {
                                    loop(innerBlock.rightValue?.orAndBlock.outerBlocks);
                                }
                            }
                        }
                    };
                    loop(value.outerBlocks);

                    return flag;
                }),
        ],
        ["state", ({modifyValue}, next) => UseState({next, initValue: modifyValue(columnLevelSecurity)})],
        ({state, modifyValue, formatValue, isValid}) => {
            const {outerBlocks} = state.value;

            return (
                <div className="cs-blank-value-o19">
                    {Watch({
                        value: state.value,
                        onChanged: async (newVal, oldVal) => {
                            if (!equalDeep(newVal, oldVal)) {
                                if (newVal.outerBlocks?.length == 1 && newVal.outerBlocks[0].innerBlocks.length == 1 && !newVal.outerBlocks[0].innerBlocks[0].leftValue) {
                                    onUpdate(null);
                                } else {
                                    if (isValid(newVal)) {
                                        onUpdate(formatValue(newVal));
                                    }
                                }
                            }
                        },
                    })}

                    {Watch({
                        value: activeTab,
                        onChanged: async (newVal, oldVal) => {
                            if (!equalDeep(newVal, oldVal)) {
                                state.onChange(modifyValue(columnLevelSecurity));
                            }
                        },
                    })}

                    <div className="text-info">{introduction.subText(column)}</div>

                    {outerBlocks.map((outerBlock, index) => (
                        <OuterBlock
                            headerText={index == 0 ? introduction.beginningLine : "OR when..."}
                            outerBlocksLength={outerBlocks.length}
                            outerBlock={outerBlock}
                            key={outerBlock.timestamp}
                            onChange={(updated) => {
                                if (updated.innerBlocks.length == 0) {
                                    const outerBlocksUpdated = outerBlocks.filter((o) => o.timestamp != outerBlock.timestamp);
                                    if (outerBlocksUpdated.length == 0) {
                                        spc(state, ["outerBlocks"], () => [
                                            {
                                                timestamp: getTimestamp(),
                                                innerBlocks: [
                                                    {
                                                        leftValue: null,
                                                        timestamp: getTimestamp(),
                                                        compareOp: "Equal",
                                                    },
                                                ],
                                            },
                                        ]);
                                    } else {
                                        spc(state, ["outerBlocks"], () => outerBlocksUpdated);
                                    }
                                } else {
                                    spc(state, ["outerBlocks"], () => outerBlocks.map((o, i) => (o.timestamp == outerBlock.timestamp ? updated : o)));
                                }
                            }}
                            activeTab={activeTab}
                            {...otherProps}
                        />
                    ))}

                    <Button
                        btnType="border"
                        size="tiny"
                        onClick={() => {
                            spc(state, ["outerBlocks"], () =>
                                outerBlocks.concat({
                                    timestamp: getTimestamp(),
                                    innerBlocks: [
                                        {
                                            leftValue: null,
                                            timestamp: getTimestamp(),
                                            compareOp: "Equal",
                                        },
                                    ],
                                })
                            );
                        }}
                    >
                        OR
                    </Button>
                </div>
            );
        }
    );
