import React from "react";
import {TableSelect} from "../../../../../../common/table-select/table-select";
import {spc} from "@common/react/state-path-change";
import {ColumnSelectFromModelColumns} from "../../../../../../common/column-select-from-model-columns/column-select-from-model-columns";
import {DataTable} from "../../../../../../../../common/data-table/data-table";
import "./search-in-table.scss";
import {ffToDropdown} from "../../../../../../../../../../../common/form/ff-to-dropdown";
import {keepOnly} from "@common/utils/objects";

export const SearchInTable = ({step, form, model}) => {
    const valueLookup = step.value.valueLookup;
    return (
        valueLookup && (
            <div className="config-group search-in-table-53x">
                <div className="label">Table to search values</div>

                {TableSelect({
                    tables: model.tables,
                    label: "Table",
                    state: {
                        value: step.value.valueLookup?.modelTableID,
                        onChange: (table) =>
                            spc(step, ["valueLookup"], (valueLookup) => ({
                                $type: valueLookup.$type,
                                modelID: model.id,
                                modelTableID: table.id,
                            })),
                    },
                    ...keepOnly(ffToDropdown(form.field(["valueLookup", "modelID"])), ["domRef", "hasError", "errorMessage"]),
                })}

                {(() => {
                    const columnSelect = ({state, ...props}) =>
                        ColumnSelectFromModelColumns({
                            state,
                            columns: model.tables.find((t) => t.id === valueLookup.modelTableID)?.columns,
                            label: "Select column",
                            ...props,
                        });

                    return DataTable({
                        className: "value-replace-table",
                        list: [valueLookup],
                        columns: [
                            {
                                label: "Value column to match",
                                format: () => {
                                    const dropdownProps = ffToDropdown(form.field(["valueLookup", "lookupModelColumnID"]), ["id"]);
                                    return columnSelect({
                                        state: {
                                            value: dropdownProps.value,
                                            onChange: dropdownProps.onChange,
                                        },
                                        ...keepOnly(dropdownProps, ["domRef", "hasError", "errorMessage"]),
                                    });
                                },
                            },
                            {
                                label: "Replace with text from",
                                format: () => {
                                    const dropdownProps = ffToDropdown(form.field(["valueLookup", "valueModelColumnID"]), ["id"]);
                                    return columnSelect({
                                        state: {
                                            value: dropdownProps.value,
                                            onChange: dropdownProps.onChange,
                                        },
                                        ...keepOnly(dropdownProps, ["domRef", "hasError", "errorMessage"]),
                                    });
                                },
                            },
                        ],
                    });
                })()}
            </div>
        )
    );
};
