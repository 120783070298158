import {cs} from "@common/react/chain-services";
import * as React from "react";
import "./options.scss";
import {NumberFilterOptions} from "./number-filter/number-filter-options";
import {DateFilterOptions} from "./date-filter/date-filter-options";
import {TextFilterOptions} from "./text-filter/text-filter-options";
import {BooleanFilterOptions} from "./boolean-filter/boolean-filter-options";

export const Options = ({filter, form}) => cs(({}) => <div className="options-2ws">{optionsTypes[filter.value.$type]({filter, form})}</div>);

const optionsTypes = {
    DateFilter: DateFilterOptions,
    TextFilter: TextFilterOptions,
    NumericFilter: NumberFilterOptions,
    BooleanFilter: BooleanFilterOptions,
};
