import * as React from "react";
import {cs} from "@common/react/chain-services";
import "./column-settings.scss";
import {LeftPanelClosable} from "../../../../../common/left-panel-closable/left-panel-closable";
import {SearchInput} from "../../../../../../../../common/form/search-input/search-input";
import {UseState} from "@common/react/use-state";
import {DataTable} from "../../../../../common/data-table/data-table";
import {rSecurityIcon} from "../../common/icons/render-security-icon";
import {cx} from "emotion";
import {rFieldTypeIcon2} from "../../../../../common/field-type-icon/get-field-type-icon-2";
import {keyed} from "@common/react/keyed";
import {cGetSearchedTables} from "../../common/get-searched-tables";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {scc} from "@common/react/state-cascade-change";
import {getNumberAggregationFuncs} from "../../../../../common/aggregation-funcs/number-aggregation-funcs";
import {getTextAggregationFuncs} from "../../../../../common/aggregation-funcs/text-aggregation-funcs";
import {envAuthSegments} from "../column-security/column-security";
import {isNotPublishedCalculatedColumn} from "../../common/column-utils";
import {UsageStatusIndicator} from "../../common/usage-status-indicator";
import {dataSourceColors, getDSColorBasedOnIndex} from "../../common/data-source-colors";

export const ColumnSettings = ({tableId, model, dataSources, openColumnSecurity, onCancel}) => {
    const table = {
        value: model.value.tables.find((t) => t.id === tableId),
        change: (change) =>
            scc(model, "tables[*]", (t) => {
                if (t.id === tableId) {
                    return change(t);
                }
                return t;
            }),
    };

    return cs(
        ({}, next) => {
            const index = dataSources.findIndex((t) => t.id === table?.value.dataSourceID);
            const color = dataSources[index]?.colorRGB || getDSColorBasedOnIndex(index);

            return (
                <LeftPanelClosable
                    {...{
                        onClose: onCancel,
                        className: "column-settings-left-panel-h68",
                        label: (
                            <>
                                COLUMN SETTINGS for <UsageStatusIndicator status="all" color={color} />{" "}
                                <span style={{color}}>{table.value.visualName?.toUpperCase()} </span> TABLE
                            </>
                        ),
                        // enableTruncatingTooltip: true,
                        content: next(),
                    }}
                />
            );
        },
        ["search", (_, next) => UseState({next})],
        ({search}) => {
            const getSearchedTable = (table) => cGetSearchedTables(search.value)([table])[0];
            // const mixedTableDsColumns = getSearchedTable(table).columns.map((c) => {
            //     return {
            //         ...c,
            //         ...(dataSourceTable && {
            //             dsColumn: dataSourceTable.columns.find((dsc) => dsc.id === c.dataSourceColumnID),
            //         }),
            //     }
            // });

            return (
                <div className="column-settings-eg3">
                    <div className="search">
                        <SearchInput
                            {...{
                                state: search,
                                label: "Search",
                                placeholder: "Find Fields",
                            }}
                        />
                    </div>
                    {DataTable({
                        className: "column-settings-table",
                        list: getSearchedTable(table.value).columns.filter((c) => !isNotPublishedCalculatedColumn(c)),
                        columns: [
                            {
                                label: "Column",
                                format: (c) => {
                                    const isCalculated = c.$type === "CalculatedModelColumn";
                                    const isAggregated = c.$type === "AggregatedMeasureModelColumn";
                                    return (
                                        <div
                                            className={cx("column-name-cell", {
                                                calculated: isCalculated,
                                                aggregated: isAggregated,
                                            })}
                                        >
                                            {isCalculated && (
                                                <img
                                                    className="calculated-plus-icon"
                                                    src={require("../../common/icons/calculated-plus-icon.svg")}
                                                    alt=""
                                                />
                                            )}
                                            {isAggregated && (
                                                <img
                                                    className="aggregated-measure-icon"
                                                    src={require("../../common/icons/aggregated-measure-icon.svg")}
                                                    alt=""
                                                />
                                            )}
                                            {c.name}
                                        </div>
                                    );
                                },
                            },
                            {
                                label: "Type",
                                format: (c) => {
                                    return (
                                        <div
                                            className={cx("type-cell", {
                                                inactive: true,
                                            })}
                                        >
                                            {rFieldTypeIcon2({
                                                dataType: c.dataType,
                                                fillColor: "rgba(41,70,97,0.5)",
                                            })}
                                            {c.dataType}
                                            {c.dataTypeProperties && c.dataTypeProperties !== "None" ? `(${c.dataTypeProperties})` : ""}
                                        </div>
                                    );
                                },
                            },
                            {
                                label: "Aggregation",
                                format: (c) => {
                                    if (c.$type === "AggregatedMeasureModelColumn") return;
                                    return c.$type === "CalculatedModelColumn" ? (
                                        <div className="aggregation-cell calculated">
                                            {DropdownSelect({
                                                list: ["Double", "Int"].includes(c.dataType)
                                                    ? getNumberAggregationFuncs()
                                                    : getTextAggregationFuncs(),
                                                valueToLabel: (v) => v.label,
                                                isSelected: (v) => c.defaultAggregationFunc === v.value,
                                                onChange: (v) =>
                                                    scc(table, "columns[*]", (column) => {
                                                        if (column.id === c.id) {
                                                            return {
                                                                ...column,
                                                                defaultAggregationFunc: v.value,
                                                            };
                                                        }
                                                        return column;
                                                    }),
                                            })}
                                        </div>
                                    ) : (
                                        <div
                                            className={cx("aggregation-cell non-calculated", {
                                                inactive: true,
                                            })}
                                        >
                                            {c.defaultAggregationFunc}
                                        </div>
                                    );
                                },
                            },
                            {
                                label: "Display Field",
                                format: (c) => {
                                    if (c.$type === "AggregatedMeasureModelColumn") return;
                                    return c.$type === "CalculatedModelColumn" ? (
                                        <div className="display-field-cell calculated">
                                            {DropdownSelect({
                                                list: table.value.columns.map((c) => c.name),
                                                isSelected: (v) => (c.displayColumnReferenceName || c.name) === v,
                                                onChange: (v) =>
                                                    scc(table, "columns[*]", (column) => {
                                                        if (column.id === c.id) {
                                                            return {
                                                                ...column,
                                                                displayColumnReferenceName: v === c.name ? null : v,
                                                            };
                                                        }
                                                        return column;
                                                    }),
                                            })}
                                        </div>
                                    ) : (
                                        <div
                                            className={cx("display-field-cell non-calculated", {
                                                inactive: true,
                                            })}
                                        >
                                            {/*{c.dsColumn && (*/}
                                            {/*    c.dsColumn.displayFieldID*/}
                                            {/*        ? dataSourceTable.columns.find((c) => c.id === c.dsColumn.displayFieldID)?.name*/}
                                            {/*        : c.dsColumn.name*/}
                                            {/*)}*/}
                                            {c.displayColumnReferenceName || c.name}
                                        </div>
                                    );
                                },
                            },
                            {
                                label: "Column-Level Security",
                                format: (c) => {
                                    const keys = envAuthSegments.map((s) => s.value);
                                    const isActiveColumn = (name) => {
                                        for (let key of keys) {
                                            if (c.columnLevelSecurity[key] && !!c.columnLevelSecurity[key][name]) return true;
                                        }
                                    };

                                    return (
                                        <div className={cx("column-security-cell")}>
                                            {["Blank", "Hide", "Mask", "Error"].map((name) =>
                                                cs(keyed(name), ({}) =>
                                                    rSecurityIcon({
                                                        name: name.toLowerCase(),
                                                        active: isActiveColumn(name),
                                                        onClick: () =>
                                                            openColumnSecurity({
                                                                columnId: c.id,
                                                                tab: name,
                                                            }),
                                                    })
                                                )
                                            )}
                                        </div>
                                    );
                                },
                            },
                        ],
                    })}
                </div>
            );
        }
    );
};
