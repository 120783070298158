import {createFetcher} from "../../../../tools/fetcher/fetcher";

export const createGuestApis = () => {
    const fetcher = createFetcher({});
    return {
        login: (params) => fetcher.post("/api/user/sign-in", params),
        mfaSignIn: (code, rememberMe) => fetcher.post("/api/user/mfa-sign-in", {code, rememberMe}),
        sendRecoveryEmail: (email) => fetcher.post("/api/user/mfa-recover", {email}),
        sendPasswordResetEmail: ({email}) => fetcher.put("/api/user/reset/init", {email}),
        completePasswordReset: ({email, resetToken, newPassword, newPasswordConfirm}) =>
            fetcher.post("/api/user/reset/complete", {
                email,
                resetToken,
                newPassword,
                newPasswordConfirm,
            }),
        getTenantViaInviteID: (inviteID) => {
            //console.log(inviteID);
            return fetcher.get(`/api/user/tenant/user/invite/${inviteID}`);
        },
        register: (params) => fetcher.post("/api/user/register", params),
    };
};
