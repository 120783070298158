const {Static2} = require("./static-2");
const {UseState} = require("./use-state");
const {cs} = require("./chain-services");

/**
 * Queue of only 1 element in wait list, next added element will overwrite the current one in wait list
 */
const Queue1 = ({next, fn}) =>
    cs(["waiting", (_, next) => Static2({next})], ["executing", (_, next) => UseState({next})], ({executing, waiting}) =>
        next({
            executing: executing.value,
            push: (value) => {
                if (!executing.value) {
                    (async () => {
                        executing.onChange(true);
                        waiting.set(value);
                        for (;;) {
                            const v = waiting.get();
                            if (v == null) {
                                break;
                            }
                            waiting.set(null);

                            await fn(v);
                        }
                        executing.onChange(false);
                    })();
                } else {
                    waiting.set(value);
                }
            },
        })
    );
exports.Queue1 = Queue1;
