const {roundTo} = require("../common/formatters/numeric/round-to");
const {chain} = require("../../../utils/fs");
const {getNumericFormatter} = require("../factory/formatters/get-numeric-formatter");
const {getDateTimeFormatter} = require("../factory/formatters/get-date-time-formatter");

const getValueFormatters = ({valueField, targetValueField, targetValueConstant, timezone, tile}) => {
    const numericProperties = valueField.numericProperties;
    const isAggCount = valueField.aggregationFunc.startsWith("Count");

    const valueFormatter = getNumericFormatter({
        numericProperties: {
            ...numericProperties,
            currencyOverrides: tile?.currencyOverrides,
        },
        useDefaultCountFormat: isAggCount,
    });
    const valueRoundFormatter = getNumericFormatter({
        numericProperties: {
            ...numericProperties,
            currencyOverrides: tile.currencyOverrides,
        },
        autoRound: true,
        useDefaultCountFormat: isAggCount,
    });

    const targetValueNumericProperties = {
        ...((targetValueField || targetValueConstant)?.numericProperties || {}),
        currencyOverrides: tile?.currencyOverrides,
    };
    const targetValueFormatter = getNumericFormatter({
        numericProperties: targetValueNumericProperties,
    });
    const targetValueRoundFormatter = getNumericFormatter({
        numericProperties: targetValueNumericProperties,
        autoRound: true,
    });

    const dateTimeFormatter = getDateTimeFormatter(null, timezone);

    return {
        valueFormatter: cValueFormatter(numericProperties, valueFormatter),
        valueRoundFormatter: cValueFormatter(numericProperties, valueRoundFormatter),
        targetValueFormatter: cValueFormatter(targetValueNumericProperties, targetValueFormatter),
        targetValueRoundFormatter: cValueFormatter(targetValueNumericProperties, targetValueRoundFormatter),
        percentFormatter: (v) => roundTo(v, 1) + "%",
        dateFormatter: (v) => v != null && dateTimeFormatter(v),
    };
};
exports.getValueFormatters = getValueFormatters;

const cValueFormatter = (numericProperties, formatter) => {
    return (v) =>
        v != null &&
        chain(
            v,
            (v) => (numericProperties?.displayType === "Percentage" ? v * 100 : v),
            (v) => (numericProperties?.decimalPlaces != null ? roundTo(v, numericProperties.decimalPlaces) : roundTo(v, 2)),
            (v) => formatter(v)
        );
};
