import React from "react";
import {cs} from "@common/react/chain-services";
import {ffToTextInput} from "../../../../../../../common/form/ff-to-text-input";
import {TextInput} from "../../../../../../../common/form/text-input/text-input";
import {DataTable} from "../../../../common/data-table/data-table";
import {Form2} from "@common/react/cs-form/form2";

const {required} = require("@common/react/cs-form/validators/required");
import "./edit-file-connection.scss";
import {RemoveSourceDialog} from "../../../remove-source-dialog/remove-source-dialog";
import {consumeContext} from "@common/react/context";
import {FilesUploadDialog} from "../../../add/wizard/steps/connection-configuration/list/dialog/files-upload/files-upload-dialog";
import {modifiedRawTables} from "../../../add/default-data-source";
import {ConnectingDataSourceDialog} from "../../../add/dialogs/connecting-data-source-dialog/connecting-data-source-dialog";
import {ErrorConnectionDialog} from "../../../add/dialogs/error-connection-dialog/error-connection-dialog";
import {Load2} from "@common/react/load2";
import {formatDateTimeFromISOStr} from "../../../../../../../common/logic/date-time/format-date-time-full";
import {Button} from "../../../../../../../common/form/buttons/button/button";

export const EditFileConnection = ({next, data}) =>
    cs(
        consumeContext("routing"),
        consumeContext("apis"),
        ["connectingDataSourceDialog", ({}, next) => ConnectingDataSourceDialog({next})],
        ["errorConnectionDialog", ({}, next) => ErrorConnectionDialog({next})],
        [
            "versionHistory",
            ({apis, routing}, next) =>
                Load2({
                    fetch: () => apis.data.getDataSourceHistory(routing.params.dsId),
                    next,
                }),
        ],
        [
            "filesUploadDialog",
            ({apis, connectingDataSourceDialog, versionHistory}, next) =>
                FilesUploadDialog({
                    next,
                    services: {
                        getTablesColumns: async (modifiedData) => {
                            try {
                                const tables = await connectingDataSourceDialog.show({
                                    type: modifiedData.dataSource.type,
                                    onConnect: async () =>
                                        await apis.data.getTablesColumns({
                                            dataSourceID: data.value.id,
                                            data: modifiedData.submit,
                                        }),
                                });
                                if (tables) {
                                    await versionHistory.reload();
                                    data.change((oldData) => ({
                                        ...oldData,
                                        ...modifiedData.dataSource,
                                        id: data.value.id,
                                        tables: modifiedRawTables({
                                            tables,
                                            type: modifiedData.dataSource.type,
                                        }),
                                    }));
                                }
                            } catch (e) {
                                // errorConnectionDialog.show()
                            }
                        },
                    },
                }),
        ],
        [
            "removeSourceDialog",
            ({routing}, next) =>
                RemoveSourceDialog({
                    onDone: () => routing.goto("dashboard", {tab: "data-sources"}),
                    next,
                }),
        ],
        [
            "form",
            (_, next) =>
                Form2({
                    data,
                    fields: {
                        name: [required],
                    },
                    next,
                }),
        ],
        ({form, versionHistory, filesUploadDialog}) => {
            return next({
                render: () => (
                    <div className="edit-file-connection-9er">
                        <div className="col-left">
                            <div className="header">File Upload Details</div>

                            {TextInput({
                                label: "Display Name",
                                ...ffToTextInput(form.field("name")),
                            })}

                            <div className="text-header">File Version History</div>
                            {versionHistory.value?.length > 0 &&
                                DataTable({
                                    list: versionHistory.value,
                                    columns: [
                                        {
                                            label: "",
                                            format: () => <span className="material-icons">insert_drive_file</span>,
                                        },
                                        {
                                            label: "File Name",
                                            format: (item) => item.originalFileName,
                                        },
                                        {
                                            label: "Upload date",
                                            format: (item) => formatDateTimeFromISOStr(item.timestamp, "MMM Do YYYY [@] h:mm A"),
                                        },
                                        {
                                            label: "Uploaded by",
                                            format: (item) => item.uploadedBy,
                                        },
                                    ],
                                })}

                            <div className="buttons">
                                {/*<button className="btn-remove" onClick={() => removeSourceDialog.show({dataSource: pick(data.value, ["name", "id"])})}>Remove Source</button>*/}
                                <Button
                                    btnType="secondary"
                                    size="medium"
                                    className="btn-upload"
                                    onClick={() =>
                                        filesUploadDialog.show({
                                            type: data.value.type,
                                            id: data.value.id,
                                        })
                                    }
                                >
                                    Upload New Version
                                </Button>
                            </div>
                        </div>

                        {/*
            <div className="col-right">
                <div className="section information">
                    <div className="text-header">Information</div>
                </div>

                <div className="section getting-connected">
                    <div className="text-header active">
                        Static Files
                    </div>

                    <div className="content">
                        <div>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur viverra varius augue a cursus. Vivamus sed sapien diam.
                        </div>
                    </div>
                </div>

                <div className="section getting-connected">
                    <div className="text-header active">Making Changes</div>

                    <div className="content">
                        <div>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur viverra varius augue a cursus. Vivamus sed sapien diam.
                        </div>
                    </div>
                </div>
            </div>
            */}
                    </div>
                ),
            });
        }
    );
