import * as React from "react";

import {cs} from "../../../../react/chain-services";
import {scope} from "../../../../react/scope";
import {spc} from "../../../../react/state-path-change";
import {UseState} from "../../../../react/use-state";
import {Watch} from "../../../../react/watch";
import {chain} from "../../../../utils/fs";

import {setPath} from "../../../../utils/arr-path";
import {addDate, addMonth, compares, dateDiff, today} from "../../../../utils/dates/date-object";
import {waitTimeout} from "../../../../utils/wait-timeout";
import {formatDateLong2} from "../../../formats/format-date-long";
import {parseDateLong2} from "../../../formats/parse-date-long";

import {SelectControl} from "@common/ui-components/calendars/select-control";
import {MAX_YEAR, MIN_YEAR} from "@common/ui-components/calendars/single-calendar/allowed-years";
import {omit} from "@common/utils/objects";
import {flatten1} from "../../../../utils/collections";
import {SingleCalendar} from "../../../calendars/single-calendar/single-calendar";
import {applyAutoRange, applyRangesMod} from "./apply-ranges-mod";

const YEAR_LIMIT = {max: MAX_YEAR, min: MIN_YEAR};

const transformPickableRange = (pickableRange) => {
    return {
        from: pickableRange?.allowedStartDate,
        to: pickableRange?.allowedEndDate,
        message: pickableRange?.message,
    };
};

export const AccessibleDateRangesPicker = ({next, ranges, isMobile, theme, onReset}) =>
    cs(
        [
            "state",
            (_, next) =>
                UseState({
                    getInitValue: () => ({
                        month: ranges[0].range?.from ?? addMonth(today()),
                    }),
                    next,
                }),
        ],
        ["hover", (_, next) => UseState({next})],

        [
            "ranges1",
            ({hover, state}, next) =>
                next(
                    hover.value == null || !state.value.selecting
                        ? ranges
                        : applyRangesMod({
                              focus: hover.value,
                              selecting: state.value.selecting,
                              ranges,
                          })
                ),
        ],
        ({state, hover, ranges1}) => {
            const getRangeInputs = ({name, onChange, selectControl, next: inputsNext}) => {
                const r = ranges.find((r) => r.name === name) ?? ranges[0];

                const focus = (attr) => {
                    const target = r.range?.[attr];
                    if (target) {
                        const date = target;
                        spc(state, ["month"], () => date);
                        hover.onChange(date);
                    }
                };

                let doms = {};
                const rangeSelected = name ? state.value.selecting?.[0] === name : true;
                const selectedAttr = state.value.selecting?.[1];
                const input = ({attr, delta = 0, next}) => {
                    const getInputValue = () => (r?.range?.[attr] ? formatDateLong2(addDate(r.range[attr], delta)) : formatDateLong2(today()));
                    return cs(
                        [
                            "inputState",
                            (_, next) =>
                                UseState({
                                    next,
                                    initValue: null,
                                }),
                        ],
                        [
                            "proxy",
                            ({inputState}, next) => {
                                return next({
                                    state: {
                                        value: inputState.value ?? getInputValue(),
                                        onChange: (str) => {
                                            inputState.onChange(str);

                                            const value = str ? chain(str, (v) => parseDateLong2(v)) : null;
                                            const date = value ? addDate(value, -delta) : null;
                                            if (date) {
                                                spc(state, ["month"], () => value);
                                                hover.onChange(value);

                                                onChange({
                                                    name,
                                                    range: r.range,
                                                    attr,
                                                    date,
                                                });
                                            }
                                        },
                                    },
                                    flush: () => inputState.onChange(null),
                                });
                            },
                        ],
                        ({proxy}) => {
                            const openCalendar = () => {
                                if ((name && state.value.selecting?.[0] !== name) || selectedAttr !== attr) {
                                    spc(state, ["selecting"], () => [name, attr]);
                                } else {
                                    focus(selectedAttr);
                                }
                            };
                            const checkValid = () => {
                                if (attr === "to" && name === "comparing") return true;
                                const target = r.range?.[attr];
                                return target ? selectControl.selectable(target).value : true;
                            };

                            return next({
                                state: proxy.state,
                                // onFocus: openCalendar,
                                onClick: openCalendar,
                                onBlur: () => {
                                    proxy.flush();
                                },
                                outline: rangeSelected && selectedAttr === attr && r?.color,
                                domRef: (r) => (doms[attr] = r),
                                getRef: () => doms[attr],
                                resetInput: () => proxy.flush(),
                                isSelected: rangeSelected && attr === selectedAttr,
                                valid: checkValid(),
                            });
                        }
                    );
                };

                return (
                    <>
                        {cs(["from", (_, next) => input({attr: "from", next})], ["to", (_, next) => input({attr: "to", delta: 0, next})], ({from, to}) => {
                            return inputsNext({
                                from,
                                to,
                            });
                        })}

                        {Watch({
                            value: JSON.stringify(state.value.selecting),
                            onChanged: async () => {
                                if (state.value.selecting && rangeSelected && selectedAttr) {
                                    await waitTimeout(0);
                                    focus(selectedAttr);
                                }
                            },
                        })}
                    </>
                );
            };
            return next({
                initAutoRangeCalendar: ({next: calendarNext, ...config}) => {
                    const {pickableRange, name, diff} = config ?? {};

                    const selectabledRange = transformPickableRange(pickableRange);
                    const rangeInputs = ({next, onChange, selectControl}) => {
                        return getRangeInputs({
                            name,
                            selectControl,
                            onChange: ({name, attr, date}) => {
                                const main = ranges[0]?.range;
                                console.log("main");
                                onChange({
                                    name,
                                    range: main &&
                                        attr === "from" && {
                                            from: omit(date, ["hours", "minutes", "seconds"]),
                                            to: omit(addDate(date, dateDiff(main.to, main.from)), ["hours", "minutes", "seconds"]),
                                        },
                                });
                            },
                            next,
                        });
                    };
                    return cs(["selectControl", (_, next) => SelectControl({next, selectabledRange, yearLimit: YEAR_LIMIT})], ({selectControl}) => {
                        return calendarNext({
                            getRangeInputs: ({next, onChange}) => rangeInputs({onChange, selectControl, next}),
                            render: ({width, space, onChange, renderActions}) => {
                                return SingleCalendar({
                                    state,
                                    hover,
                                    size: width,
                                    space,
                                    theme,
                                    renderActions,
                                    month: scope(state, ["month"]),
                                    ranges: [
                                        applyAutoRange({
                                            range: ranges.find((r) => r.name === name),
                                            focus: hover.value,
                                            diff,
                                        }),
                                    ].filter((r) => r.range),
                                    originalRanges: ranges.filter((r) => r.range && r.name === name),
                                    isMobile,
                                    selectControl,
                                    onSelect: (day) => {
                                        const main = ranges[0].range;
                                        if (main) {
                                            const to =
                                                dateDiff(main.to, main.from) > dateDiff(main.from, day) ? addDate(main.from, -1) : addDate(day, dateDiff(main.to, main.from));

                                            onChange({
                                                name,
                                                range: {
                                                    from: day,
                                                    to,
                                                },
                                            });
                                        }
                                    },
                                });
                            },
                        });
                    });
                },
                initSingleCalendar: ({next: calendarNext, ...config}) => {
                    const {pickableRange, name} = config ?? {};
                    const selectabledRange = transformPickableRange(pickableRange);

                    const checkDayValid = (day) => {
                        const {selecting} = state.value;
                        const curRange = ranges.find((r) => r.name === name) ?? ranges[0];

                        if (selecting?.[1] == "from") {
                            return {
                                disabled: curRange && curRange.range?.to ? compares.gt(day, curRange.range.to) : false,
                                message: "The Start Date must come before the End Date",
                            };
                        }
                        if (selecting?.[1] == "to") {
                            return {
                                disabled: curRange && curRange.range?.from ? compares.lt(day, curRange.range.from) : false,
                                message: "The End Date must come after the Start Date",
                            };
                        }
                        return {
                            disabled: false,
                        };
                    };
                    const rangeInputs = ({next, onChange, selectControl}) => {
                        return getRangeInputs({
                            name,
                            selectControl,
                            onChange: ({name, range, attr, date}) => {
                                const newRange = setPath(range, [attr], date);
                                onChange({
                                    name,
                                    range: {
                                        from: omit(newRange?.from, ["hours", "minutes", "seconds"]),
                                        to: omit(newRange?.to, ["hours", "minutes", "seconds"]),
                                    },
                                });
                            },
                            next,
                        });
                    };
                    return cs(["selectControl", (_, next) => SelectControl({next, selectabledRange, checkDayValid, yearLimit: YEAR_LIMIT})], ({selectControl}) => {
                        return calendarNext({
                            getRangeInputs: ({next, onChange}) => rangeInputs({next, onChange, selectControl}),
                            render: ({onChange, width, space, renderActions}) => {
                                return SingleCalendar({
                                    state,
                                    hover,
                                    space,
                                    theme,
                                    size: width,
                                    month: scope(state, ["month"]),
                                    isMobile,
                                    ranges: ranges1.filter((r) => r.range && r.name === name),
                                    originalRanges: ranges.filter((r) => r.range && r.name === name),
                                    selectControl,
                                    renderActions,
                                    onSelect: async (date) => {
                                        const selecting = state.value.selecting ?? [ranges[0].name, "from"];
                                        const inputs = getInputs(ranges).filter((i) => i[0] == selecting[0]);
                                        const inputIndex = inputs.findIndex((i) => i[0] == selecting[0] && i[1] == selecting[1]);
                                        const newRange = setPath(ranges.find((r) => r.name === selecting[0]).range, [selecting[1]], date);

                                        onChange({
                                            name: selecting[0],
                                            range: newRange,
                                            attr: selecting[1],
                                        });
                                        if (selecting[1]) {
                                            await waitTimeout(0);
                                            spc(state, ["selecting"], () => inputs[(inputIndex + 1) % inputs.length]);
                                        }
                                    },
                                });
                            },
                        });
                    });
                },
            });
        }
    );

const getInputs = (ranges) =>
    chain(
        ranges,
        (_) =>
            _.map((item) => [
                [item.name, "from"],
                [item.name, "to"],
            ]),
        (_) => flatten1(_)
    );
