import {cs} from "@common/react/chain-services";
import {keyed} from "@common/react/keyed";
import {consumeContext} from "@common/react/context";
import {UseState} from "@common/react/use-state";
import {Load2} from "@common/react/load2";
import {SearchInput} from "../../../../../../../common/form/search-input/search-input";
import {ButtonDropdown} from "../../../../data-source/common/button-dropdown/button-dropdown";
import {NewFolderIcon, PlusIcon} from "@common/ui-components/icons/global-icons";
import {LoadingIndicator} from "@common/ui-components/loading-indicator/loading-indicator";
import {RecursiveTable} from "../../../../common/recursive-table/recursive-table";
import {buildFoldersTree} from "../../collections/folders-tree-structure";
import {isBlank} from "@common/utils/strings";
import {css, cx} from "emotion";
import {createDateFormatter} from "@common/ui-components/charts/common/formatters/date-formatter";
import {BasicPanel} from "@common/ui-components/panel/panel";
import React from "react";
import SearchIcon from "assets/icons/common/SearchIcon";
import FolderIcon from "assets/icons/common/FolderIcon";
import FolderOpenIcon from "assets/icons/common/FolderOpenIcon";
import {CreateFolderDialog} from "../../collections/folder-cm/create-folder/create-folder-dialog";
import {FolderCM} from "../../collections/folder-cm/folder-cm";
import {PopupMenu} from "../../../../common/popup-menu/popup-menu";
import {MoveToDialog} from "../../common/move-to-dialog/move-to-dialog";
import {ManagePreviewProfileDialog} from "./manage-profile/manage-preview-profile-dialog";
import {ConfirmDialog} from "../../../../common/confirm-dialog/confirm-dialog";
import {DeleteKeyDialog} from "../commons/delete-key-dialog";
import {MultiSelect} from "../../../common/multi-select";
import {noun} from "@common/utils/plural";

export const PreviewProfiles = () =>
    cs(
        keyed("preview-profiles"),
        consumeContext("authEndpoint"),
        consumeContext("apis"),
        consumeContext("routing"),
        ["searchText", (_, next) => UseState({initValue: "", next})],
        [
            "folders",
            ({apis, routing}, next) =>
                Load2({
                    _key: routing.params?.envId,
                    fetch: () => apis.previewProfile.getFolders(),
                    next,
                }),
        ],
        [
            "createFolderDialog",
            ({folders}, next) =>
                CreateFolderDialog({
                    apiType: "previewProfile",
                    folders: folders.value,
                    onCreate: (newFolder) => folders.onChange([...(folders.value || []), newFolder]),
                    next,
                }),
        ],
        ["endpointAuthConfig", ({authEndpoint}, next) => next(authEndpoint.value.find((t) => t.$type === "EndpointAuthConfig") || {})],
        [
            "defaultUserValues",
            ({endpointAuthConfig}, next) => {
                let ret = {};
                if (endpointAuthConfig) {
                    for (let key in endpointAuthConfig.resultMapping) {
                        const mappingType = endpointAuthConfig.resultMapping[key].mappingType;
                        ret[key] = {
                            $type: mappingType == "SingleValue" ? "UserStringValue" : "UserArrayValue",
                            ...(mappingType == "SingleValue"
                                ? {value: ""}
                                : {
                                      values: [
                                          {
                                              $type: "UserStringValue",
                                              value: "",
                                          },
                                      ],
                                  }),
                        };
                    }
                    return next(ret);
                }
                return next(null);
            },
        ],
        ["managePreviewProfileDialog", (_, next) => ManagePreviewProfileDialog({next})],
        ["onUpdateFolders", ({folders}, next) => next((newFolders) => folders.onChange(newFolders))],
        [
            "moveProfileDialog",
            ({folders, onUpdateFolders}, next) =>
                MoveToDialog({
                    apiType: "previewProfile",
                    folders: folders.value,
                    onDone: onUpdateFolders,
                    next,
                }),
        ],
        ["confirmDialog", ({}, next) => ConfirmDialog({next, title: "Delete Profile?"})],
        ["folderCM", ({folders}, next) => FolderCM({apiType: "previewProfile", folders, next})],
        [
            "updateProfile",
            ({managePreviewProfileDialog, folders}, next) =>
                next(async (key) => {
                    const resp = await managePreviewProfileDialog.show({
                        folders: folders.value,
                        profile: key,
                    });

                    if (resp) {
                        folders.change((oldFolders) =>
                            oldFolders.map((f) =>
                                f.id === resp.folderID
                                    ? {
                                          ...f,
                                          keys: f.keys.map((key) => {
                                              if (key.id == resp.id) return resp;
                                              return key;
                                          }),
                                      }
                                    : f
                            )
                        );
                    }
                }),
        ],
        [
            "deleteProfileDialog",
            ({onUpdateFolders}, next) =>
                DeleteKeyDialog({
                    entityName: "previewProfile",
                    entityDisplayName: "Preview Profile",
                    title: "Delete Preview Profile",
                    onDone: onUpdateFolders,
                    reloadFolderOnDone: true,
                    next,
                }),
        ],
        [
            "multiSelectCtrl",
            ({moveProfileDialog, deleteProfileDialog}, next) =>
                MultiSelect({
                    next,
                    itemName: "Key",
                    resolveItemKey: (item) => `${item.type}_${item.id}`,
                    actions: [
                        {
                            label: "Move To",
                            isShow: () => true,
                            onClick: async (selectedItems) => {
                                const updateItems = Object.values(selectedItems);
                                return await moveProfileDialog.show({
                                    isUpdateMany: true,
                                    label: `${updateItems.length} ${noun(updateItems.length, "Data Access API Key")}`,
                                    params: updateItems,
                                });
                            },
                        },
                        {
                            label: "Delete",
                            isShow: () => true,
                            onClick: async (selectedItems) => {
                                const updateItems = Object.values(selectedItems);
                                return await deleteProfileDialog.show({
                                    items: updateItems,
                                });
                            },
                        },
                    ],
                }),
        ],
        [
            "akCM",
            ({managePreviewProfileDialog, moveProfileDialog, deleteProfileDialog, folders, confirmDialog, apis, updateProfile}, next) =>
                PopupMenu({
                    itemHeight: 48,
                    getCommands: ({profile}) => {
                        return [
                            {
                                label: "Move to",
                                onClick: () => moveProfileDialog.show({params: profile}),
                            },
                            {
                                label: "Profile Parameters",
                                onClick: () => updateProfile(profile),
                            },
                            {
                                label: "Delete",
                                className: "delete-action",
                                onClick: () => deleteProfileDialog.show({items: [profile]}),
                            },
                        ];
                    },
                    minWidth: 150,
                    next,
                }),
        ],
        ({
            folders,
            searchText,
            createFolderDialog,
            folderCM,
            akCM,
            managePreviewProfileDialog,
            defaultUserValues,
            updateProfile,
            multiSelectCtrl,
        }) => {
            const hasSelectedItems = multiSelectCtrl.numSelectedItems > 0;
            const isFolder = (item) => item?.hasOwnProperty("children") || item?.hasOwnProperty("keys");
            return (
                <BasicPanel
                    className="data-access-api-key-a23"
                    title={
                        <div className="key-title">
                            <div>Preview Profiles</div>
                        </div>
                    }
                    rightControl={
                        <>
                            {SearchInput({
                                placeholder: "Search Profile",
                                state: searchText,
                                icon: SearchIcon({}),
                            })}

                            {ButtonDropdown({
                                className: css`
                                    margin-left: 24px;
                                `,
                                label: "New",
                                list: [
                                    {
                                        value: 0,
                                        label: (
                                            <>
                                                <PlusIcon fill="#919EB0" /> Create New Profile
                                            </>
                                        ),
                                    },
                                    {
                                        value: 1,
                                        label: (
                                            <>
                                                <NewFolderIcon fill="#919EB0" /> Create New Folder
                                            </>
                                        ),
                                    },
                                ],
                                valueToLabel: (item) => item.label,
                                onChange: (item) => {
                                    const maps = {
                                        0: async () => {
                                            const resp = await managePreviewProfileDialog.show({
                                                folders: folders.value,
                                                profile: {
                                                    name: "",
                                                    userValues: defaultUserValues,
                                                    folderID: null,
                                                },
                                            });

                                            if (resp) {
                                                folders.change((oldFolders) =>
                                                    oldFolders.map((f) =>
                                                        f.id === resp.folderID
                                                            ? {
                                                                  ...f,
                                                                  keys: [...f.keys, resp],
                                                              }
                                                            : f
                                                    )
                                                );
                                            }
                                        },
                                        1: createFolderDialog.show,
                                    };

                                    return maps[item.value]();
                                },
                            })}

                            {hasSelectedItems && multiSelectCtrl.render({style: {marginBottom: 0, marginLeft: 16}})}
                        </>
                    }
                >
                    {({height}) => (
                        <div className="data-access-api-keys-wrapper">
                            {!folders.value
                                ? LoadingIndicator()
                                : RecursiveTable({
                                      showNoResult: searchText.value?.length > 0,
                                      onClearSearch: () => searchText.onChange(""),
                                      maxHeight: height,
                                      structure: buildFoldersTree({
                                          itemsProp: "keys",
                                          folders: folders.value,
                                          keyword: searchText.value,
                                      }),
                                      isGroupNode: (n) => n?.keys,
                                      isAlwaysExpanded: () => !isBlank(searchText.value),
                                      getTrClass: (n) => multiSelectCtrl.isSelected(n) && "is-selected-tr",
                                      getChildTrClass: (n) => (isFolder(n) && multiSelectCtrl.isSelected(n) ? "is-selected-tr" : ""),
                                      onClickRow: (item, event) => {
                                          if (isFolder(item)) {
                                              return;
                                          }
                                          event.preventDefault();
                                          event.stopPropagation();
                                          multiSelectCtrl.toggleSelect(item);
                                      },
                                      columns: [
                                          {
                                              label: "Name",
                                              indented: true,
                                              format: (key, {expanding}) => {
                                                  if (key.keys) {
                                                      return (
                                                          <div
                                                              className={cx("icon-name-34n", "folder", {
                                                                  expanding,
                                                              })}
                                                          >
                                                              {expanding ? FolderOpenIcon({}) : FolderIcon({})}
                                                              {key.name}
                                                          </div>
                                                      );
                                                  }

                                                  return (
                                                      <div className="link" onClick={() => updateProfile(key)}>
                                                          {key.name}
                                                      </div>
                                                  );
                                              },
                                          },
                                          {
                                              label: "Date generated",
                                              format: (key) => {
                                                  if (key.keys) return null;
                                                  return (
                                                      <div className="api-key-item">
                                                          {createDateFormatter("MMM d, yyyy @ h:mm tt").format(new Date(key.created))}
                                                      </div>
                                                  );
                                              },
                                          },
                                          {
                                              format: (key) => (
                                                  <div className="cm">
                                                      {key.keys
                                                          ? folderCM.render({
                                                                params: key,
                                                            })
                                                          : akCM.render({
                                                                params: {
                                                                    profile: key,
                                                                },
                                                                img: <i className="far fa-ellipsis-h" />,
                                                            })}
                                                  </div>
                                              ),
                                              className: "models-cm-35v",
                                              shy: true,
                                              alignRight: true,
                                          },
                                      ],
                                  })}
                        </div>
                    )}
                </BasicPanel>
            );
        }
    );
