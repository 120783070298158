import React from "react";
import {cs} from "../../react/chain-services";
import "./key-value-input.scss";
import {TextInput} from "../text-input/text-input";
import {UseState} from "../../react/use-state";
import {scope} from "../../react/scope";
import {arrMapToO, omit} from "../../utils/objects";
import {replaceFind_f, reverse} from "../../utils/collections";
import {chain} from "../../utils/fs";
import {keyed} from "../../react/keyed";
import {cx} from "emotion";
import {TrashIconBold} from "../../../web-client/src/routes/common/icons/trash-icon";
import {ButtonIcon} from "../buttons/button-icon/button-icon";
import {ConfirmDialog} from "../../../web-client/src/routes/common/confirm-dialog/confirm-dialog";

export const KeyValueInput = ({
                                  state,
                                  labels,
                                  placeholders,
                                  feedValues,
                                  switchCols,
                                  hasError,
                                  domRef,
                                  otherFields = [],
                                  objectMapping,
                                  confirmDelete,
                                  disableEditing
                              }) =>
    cs(
        [
            "confirmRemoveDialog",
            (_, next) =>
                ConfirmDialog({
                    next,
                    submitText: "Delete Property",
                    title: "DELETE RESPONSE MAPPING PROPERTY",
                }),
        ],
        [
            "adding",
            (_, next) =>
                UseState({
                    initValue: {
                        isAdding: !state?.value || Object.keys(state.value)?.length === 0
                    },
                    next,
                }),
        ],
        ({adding, confirmRemoveDialog}) => {
            const existing = state.value && Object.keys(state.value)?.length > 0;
            const getCols = (cols) => chain(cols, (_) => (switchCols ? reverse(_) : _));

            const getOffsetKey = (key) => (feedValues?.key ? key?.replace(feedValues.key, "") : key);
            const getOffsetValue = (value) => (feedValues?.value ? value?.replace(feedValues.value, "") : value);
            const isObject = (value) => typeof value == "object";

            return (
                <div
                    className={cx("key-value-input key-value-input-9dg", {
                        hasError,
                    })}
                    ref={domRef}
                >
                    <table>
                        <thead>
                            <tr className="headers">
                                {getCols([<th key="key-header">{labels?.key || "Key"}</th>, <th key="value-header">{labels?.value || "Value"}</th>])}
                                {otherFields?.map((f, i) => (
                                    <th key={i} className={f.className}>
                                        {f.label}
                                        {f.tooltip ?? null}
                                    </th>
                                ))}
                                <th className="actions-col" />
                            </tr>
                        </thead>
                        <tbody>
                            {state.value &&
                                Object.entries(state.value).map(([key, value], i) => {
                                    const keyColumn = (
                                        <td key="key-column">
                                            {LocalStateTextInput({
                                                key: `${key}${i}`,
                                                disabled: disableEditing,
                                                state: {
                                                    value: getOffsetKey(key),
                                                    onChange: (v) => {
                                                        const oldKey = Object.entries(state.value).find((e, j) => e[1] === value && j === i)[0];
                                                        const newKey = (feedValues?.key || "") + (v ?? "");
                                                        const newObj = replaceKey(state.value, oldKey, newKey);
                                                        state.onChange(removeEmpty(newObj));
                                                    },
                                                },
                                                ...(!getOffsetKey(key) && {
                                                    autoFocus: true,
                                                }),
                                                feedValue: feedValues?.key,
                                            })}
                                        </td>
                                    );
                                    const valueColumn = (
                                        <td className="value-col" key="value-column">
                                            {LocalStateTextInput({
                                                key: `${JSON.stringify(value)}${i}`,
                                                disabled: disableEditing,
                                                state: {
                                                    value: isObject(value) ? value[objectMapping?.value] : getOffsetValue(value),
                                                    onChange: (v) => {
                                                        const newVal = (feedValues?.value || "") + (v ?? "");

                                                        const newObj = {
                                                            ...state.value,
                                                            [key]:
                                                                isObject(value) || objectMapping
                                                                    ? {
                                                                          ...state.value[key],
                                                                          [objectMapping?.value]: newVal,
                                                                      }
                                                                    : newVal,
                                                        };
                                                        state.onChange(removeEmpty(newObj));
                                                    },
                                                },
                                                ...(!getOffsetValue(value) && {
                                                    autoFocus: true,
                                                }),
                                                feedValue: feedValues?.value,
                                            })}
                                        </td>
                                    );
                                    return (
                                        <tr key={i}>
                                            {getCols([keyColumn, valueColumn])}
                                            {otherFields.map((f, i) => (
                                                <td key={i} className={f.className}>
                                                    {f.field(key, state)}
                                                </td>
                                            ))}
                                            <td className="actions-col">
                                                <ButtonIcon
                                                    disabled={disableEditing}
                                                    icon={TrashIconBold()}
                                                    className="delete-btn"
                                                    size="small"
                                                    btnType="border"
                                                    onClick={async () => {
                                                        if (confirmDelete) {
                                                            const resp = await confirmRemoveDialog.show({
                                                                description: (
                                                                    <span>
                                                                        If row level security on any model is dependent on the{" "}
                                                                        <b>{isObject(value) ? value[objectMapping?.value] : getOffsetValue(value)}</b> property, the model(s) will
                                                                        be in error after saving Authentication Settings.
                                                                    </span>
                                                                ),
                                                            });

                                                            if (resp) {
                                                                state.onChange(removeEmpty(omit(state.value, [key])));
                                                            }
                                                        } else {
                                                            state.onChange(removeEmpty(omit(state.value, [key])));
                                                        }
                                                    }}
                                                />
                                                {Object.entries(state.value).length > 0 && Object.entries(state.value).length === i + 1 && (
                                                    <ButtonIcon
                                                        disabled={disableEditing}
                                                        btnType="secondary"
                                                        size="small"
                                                        icon={<span className="material-icons">add</span>}
                                                        className="add-btn"
                                                        onClick={() => {
                                                            return adding.onChange({
                                                                ...adding.value,
                                                                isAdding: true,
                                                            });
                                                        }}
                                                    />
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })}
                            {(adding.value?.isAdding || !existing) &&
                                (() => {
                                    const {value: kValue, onChange: kOnChange} = scope(adding, ["pair", "key"]);
                                    const {value: vValue, onChange: vOnChange} = scope(adding, ["pair", "value"]);
                                    const key = (feedValues?.key || "") + (kValue || "");
                                    const val = vValue ? (feedValues?.value || "") + vValue : "";

                                    const onBlur = () => {
                                        state.onChange({
                                            ...state.value,
                                            // ...(kValue && kValue.trim() !== "" && {
                                            //     [key]: val
                                            // }),
                                            [key]: objectMapping
                                                ? {
                                                      ...state.value?.[key],
                                                      [objectMapping.value]: val,
                                                  }
                                                : val,
                                        });
                                        adding.onChange({
                                            isAdding: false,
                                        });
                                    };
                                    const keyColumn = (
                                        <td key="key-column">
                                            {TextInput({
                                                disabled: disableEditing,
                                                state: {
                                                    value: getOffsetKey(kValue),
                                                    onChange: kOnChange,
                                                },
                                                onBlur,
                                                ...(existing &&
                                                    !switchCols && {
                                                        autoFocus: true,
                                                    }),
                                                ...(!existing &&
                                                    placeholders && {
                                                        placeholder: placeholders.key,
                                                    }),
                                                feedValue: feedValues?.key,
                                            })}
                                        </td>
                                    );
                                    const valueColumn = (
                                        <td className="value-col" key="value-column">
                                            {TextInput({
                                                disabled: disableEditing,
                                                state: {
                                                    value: getOffsetValue(vValue),
                                                    onChange: vOnChange,
                                                },
                                                onBlur,
                                                ...(existing &&
                                                    switchCols && {
                                                        autoFocus: true,
                                                    }),
                                                ...(!existing &&
                                                    placeholders && {
                                                        placeholder: placeholders.value,
                                                    }),
                                                feedValue: feedValues?.value,
                                            })}
                                        </td>
                                    );
                                    return (
                                        <tr>
                                            {getCols([keyColumn, valueColumn])}
                                            {otherFields.map((f, i) => cs(keyed(i), () => <td className={f.className}>{f.field(key, state)}</td>))}
                                            <td className="actions-col">
                                                {existing && (
                                                    <ButtonIcon
                                                        icon={TrashIconBold()}
                                                        className="delete-btn"
                                                        size="small"
                                                        btnType="border"
                                                        onClick={() => {
                                                            adding.onChange({
                                                                isAdding: false,
                                                            });
                                                        }}
                                                    />
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })()}

                            <tr>
                                <td />
                                {/*<td className="add-btn-row">*/}
                                {/*    <button*/}
                                {/*        className="add-btn"*/}
                                {/*        disabled={adding.value.isAdding || !existing}*/}
                                {/*        onClick={() => {*/}
                                {/*            // const key = (feedValues?.key ?? (labels?.key || "Key"));*/}
                                {/*            // state.onChange({*/}
                                {/*            //     ...state.value,*/}
                                {/*            //     [key]: objectMapping ? Object.values(objectMapping).reduce((s, item) => {*/}
                                {/*            //         s[item] = "";*/}
                                {/*            //         return s;*/}
                                {/*            //     }, {}) : ""*/}
                                {/*            // })*/}
                                {/*            adding.onChange({...adding.value, isAdding: true})*/}
                                {/*        }}*/}
                                {/*    >Add</button>*/}
                                {/*</td>*/}
                                <td />
                                {otherFields?.map((_, i) => (
                                    <td key={i} />
                                ))}
                                <td />
                            </tr>
                        </tbody>
                    </table>

                    {hasError && hasError.getMessage && <div className="error-msg">{hasError.getMessage()}</div>}
                </div>
            );
        }
    );

const LocalStateTextInput = ({state, key, disabled, ...props}) =>
    cs(
        keyed(key),
        [
            "localState",
            (_, next) =>
                UseState({
                    initValue: state.value,
                    next,
                }),
        ],
        ({localState}) =>
            TextInput({
                disabled,
                state: {
                    value: localState.value,
                    onChange: localState.onChange,
                },
                onBlur: () => {
                    const v = localState.value;
                    state.onChange(v);
                    // localState.onChange(null);
                },
                ...props,
            })
    );

const replaceKey = (obj, oldKey, newKey) => {
    const newEntries = replaceFind_f(
        Object.entries(obj),
        (e) => [newKey, e[1]],
        (e) => e[0] === oldKey
    );
    return arrMapToO(
        newEntries,
        (_) => _[1],
        (_) => _[0]
    );
};

const removeEmpty = (obj) => {
    const kept = Object.entries(obj).filter(([key, value]) => (key && key.trim() !== "") || (value && value.trim() !== ""));
    return kept.length === 0
        ? null
        : arrMapToO(
              kept,
              (_) => _[1],
              (_) => _[0]
          );
};
