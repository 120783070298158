import "./action-fields.scss";

import React from "react";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {keyed} from "@common/react/keyed";
import {rFieldTypeIcon} from "../../../../../../../../../common/field-type-icon/get-field-type-icon";
import {Checkbox} from "../../../../../../../../../../../../common/ui-components/form/checkbox/checkbox";
import {spc} from "@common/react/state-path-change";
import {RadioLine} from "../../../../../../../../../common/radio-line/radio-line";

import {LabelInfo} from "../edit-tile-action";

import {getActionFields, getErrorMessageFieldControlFilter} from "./action-field-utils";
import {tooltipService3} from "../../../../../../../../../common/tooltip3/tooltip-service-3";

// TODO: always require 1 checkbox
export const ActionFields = ({tile, tileAction, isOnlySelected, info, label = "Action Fields"}) =>
    cs(consumeContext("getFieldInfo"), consumeContext("collection"), tooltipService3(), ({getFieldInfo, collection, tooltip}) => {
        const fields = getActionFields(tile, getFieldInfo);

        const getErrorMessage = (field) => getErrorMessageFieldControlFilter(tileAction.value, collection.value.filters, field);

        return (
            <div className="action-fields-89o">
                {LabelInfo({
                    label: label,
                    info: isOnlySelected
                        ? "One field can be selected per navigate to tile action however, you may create multiple navigate to tile actions on the same tile."
                        : info,
                })}

                <div className="content">
                    {tile.value.$type == "MapTile" ? (
                        <div className="action-field">
                            {RadioLine({
                                selected: true,
                                onClick: () => {},
                            })}

                            <div className="field">
                                {rFieldTypeIcon("Location")}
                                Location Fields
                            </div>
                        </div>
                    ) : (
                        fields.map((field, i) =>
                            cs(keyed(i), () => (
                                <div className="action-field">
                                    {isOnlySelected
                                        ? RadioLine({
                                              selected: tileAction.value?.enabledTileFieldIDs?.indexOf(field.id) !== -1,
                                              onClick: () => spc(tileAction, ["enabledTileFieldIDs"], () => [field.id]),
                                          })
                                        : Checkbox({
                                              state: {
                                                  value: tileAction.value?.enabledTileFieldIDs?.indexOf(field.id) !== -1,
                                                  onChange: (checked) =>
                                                      spc(tileAction, ["enabledTileFieldIDs"], (old) => (checked ? [...old, field.id] : old.filter((o) => o !== field.id))),
                                              },
                                          })}

                                    <div
                                        className="field"
                                        style={{
                                            paddingRight: getErrorMessage(field) ? 40 : "",
                                        }}
                                    >
                                        {rFieldTypeIcon(field.dataType)}
                                        {field?.visualNameFull}
                                        {getErrorMessage(field) && (
                                            <span
                                                {...{
                                                    ...tooltip(getErrorMessage(field)),
                                                }}
                                                className="material-icons-outlined"
                                            >
                                                warning
                                            </span>
                                        )}
                                    </div>
                                </div>
                            ))
                        )
                    )}
                </div>
            </div>
        );
    });
