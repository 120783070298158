import {Load2} from "@common/react/load2";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {consumeContext, provideContext} from "@common/react/context";

import {flatten1} from "@common/utils/collections";

let cachedView = null;
const cachedRoutes = ["edit-container-tile", "edit-tile", "edit-collection"];

export const loadViewAsContext = ({next: rootNext}) =>
    cs(
        consumeContext("apis"),
        consumeContext("routing"),
        consumeContext("filtersValueSet"),
        [
            "iframeKeys",
            ({apis, routing}, next) =>
                Load2({
                    _key: routing.params?.envId,
                    fetch: () => apis.iframeKey.getIframeKeys(),
                    next,
                }),
        ],
        [
            "folders",
            ({apis, routing}, next) =>
                Load2({
                    _key: routing.params?.envId,
                    fetch: () => apis.previewProfile.getFolders(),
                    next,
                }),
        ],
        ({iframeKeys, folders}, next) => (iframeKeys.loading || folders.loading ? rootNext(null) : next()),
        [
            "viewAs",
            ({routing, iframeKeys, folders}, next) => {
                const {previousRoutes} = routing;

                if (!cachedView || !cachedRoutes.includes(previousRoutes?.[0]?.routeName)) {
                    cachedView = {label: "Admin", value: null};

                    const key = (iframeKeys.value || []).find((key) => key.id == routing.params.previewID);
                    if (key) {
                        cachedView.label = key.name;
                        cachedView.value = key.apiKey;
                        cachedView.themeID = key.themeID;
                    }

                    const profiles = flatten1(folders.value, (folder) => folder.keys);
                    const profile = profiles.find((p) => p.id == routing.params.previewID);
                    if (profile) {
                        cachedView.label = profile.name;
                        cachedView.value = profile.apiKey;
                    }
                }

                return UseState({
                    next,
                    initValue: cachedView,
                });
            },
        ],
        [
            "collectionViewAs",
            ({viewAs, collection}, next) =>
                viewAs?.value?.value === null
                    ? next(null)
                    : cs(({liveApis}) =>
                          next({
                              collection,
                              liveApis,
                          })
                      ),
        ],
        ({iframeKeys, folders, viewAs, collectionViewAs, filtersValueSet}) => {
            return provideContext(
                "viewAsContext",
                {
                    iframeKeys,
                    folders,
                    viewAs: {
                        value: viewAs?.value,
                        onChange: (newValue) => {
                            // reset filtersValue when changing preview profile
                            filtersValueSet?.onChange({});

                            viewAs.onChange(newValue);
                            cachedView = newValue;
                        },
                    },
                    collectionViewAs,
                },
                rootNext
            );
        }
    );
