import {cs} from "@common/react/chain-services";
import ColumnIcon from "assets/icons/data-sources/ColumnIcon";
import TableIcon from "assets/icons/common/TableIcon";

export const NoSearchesFound = ({type = "tables"}) =>
    cs(() => (
        <div className="no-searches-found-99o">
            {type === "tables" || type === "objects" ? TableIcon({}) : ColumnIcon({})}
            <div className="text-lg">No {type} match your search</div>
            <div>Try being less specific or using different keywords.</div>
        </div>
    ));
