import * as React from "react";
import {cs} from "@common/react/chain-services";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {operations} from "../operations";
import {NumberInput} from "../../../../../../../../../../common/form/number-input/number-input";
import {ffToNumberInput} from "../../../../../../../../../../common/form/ff-to-number-input";
import {ffToDropdown} from "../../../../../../../../../../common/form/ff-to-dropdown";
import {stringToList} from "../../../../../../../common/data-logic/string-to-list";
import {ChipInput} from "../../../../../../../../../../common/form/chip-input/chip-input";
import "./default-filter-criteria.scss";
import {scope} from "@common/react/scope";
import {chain} from "@common/utils/fs";

export const DefaultFilterCriteria = ({filter, form}) =>
    cs(({}) => (
        <div className="default-filter-criteria-5ds">
            <div className="label">
                Default Filter Criteria <span>(Optional)</span>
            </div>

            <div className="left">
                {DropdownSelect({
                    label: "Comparison Operation",
                    list: chain(
                        operations,
                        (list) => list.filter((op) => stringToList(filter.value.options)?.includes(op.name)),
                        (list) => list.filter((v) => v),
                        (list) =>
                            list.filter((l) => {
                                if (l.aggregationFuncs) return l?.aggregationFuncs.includes(filter.value.aggregationFunc);
                                return true;
                            })
                    ),
                    valueToLabel: (op) => op.label,
                    ...ffToDropdown(form.field(["defaultOption"]), ["name"]),
                })}
            </div>

            {["EqualTo", "NotEqualTo", "GreaterThanOrEqualTo", "LessThanOrEqualTo", "GreaterThan", "LessThan"].includes(filter.value.defaultOption) && (
                <div className="right">
                    {NumberInput({
                        label: "Value",
                        showZero: true,
                        ...ffToNumberInput(form.field(["defaultValueOrMin"])),
                    })}
                </div>
            )}

            {filter.value.defaultOption === "Range" && (
                <div className="right dual">
                    <div className="left">
                        {NumberInput({
                            label: "From",
                            showZero: true,
                            ...ffToNumberInput(form.field(["defaultValueOrMin"])),
                        })}
                    </div>
                    <div className="right">
                        {NumberInput({
                            label: "To",
                            showZero: true,
                            ...ffToNumberInput(form.field(["defaultMax"])),
                        })}
                    </div>
                </div>
            )}

            {["InList", "NotInList"].includes(filter.value.defaultOption) && (
                <div className="right">
                    {ChipInput({
                        state: scope(filter, ["defaultList"]),
                        numericOnly: true,
                    })}
                </div>
            )}
        </div>
    ));
