const {minArrayLength} = require("@common/react/cs-form/validators/min-length-array");
const {minLength} = require("@common/react/cs-form/validators/min-length");
const {textFilter} = require("./text-filter-config");
const {numericFilter} = require("./numeric-filter-config");
const {dateFilter} = require("./date-filter-config");
const {required} = require("@common/react/cs-form/validators/required");

const filterFormConfig = {
    fields: [
        {
            path: ["columns"],
            validators: [minArrayLength(1)],
        },
        {
            path: ["selectedFields"],
            validators: [
                {
                    when: () => true,
                    validate: (v, {data}) => (data.columns || []).find((c) => c.default),
                },
            ],
        },
        {
            path: ["option"],
            validators: [
                {
                    when: (data) => data?.$type === "TextFilter",
                    validate: (option) => option?.length,
                },
            ],
        },
        {
            path: ["options"],
            validators: [
                {
                    when: (data) => data?.$type !== "TextFilter" && data?.$type !== "BooleanFilter",
                    validate: (options) => options?.length,
                },
            ],
        },

        {
            path: ["label"],
            debounce: true,
        },

        {
            path: ["defaultOption"],
            validators: [
                {
                    when: (data) => data?.$type !== "TextFilter" && data?.$type !== "NumericFilter" && data?.$type !== "BooleanFilter",
                    validate: (option) => !!option,
                },
            ],
        },

        ...dateFilter(),
        ...numericFilter(),
        ...textFilter(),
    ],
};
exports.filterFormConfig = filterFormConfig;
