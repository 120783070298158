import * as React from "react";
import {ThreeDots} from "./three-dots";
import "./loading-indicator.scss";
import {cx} from "emotion";

export const LoadingIndicator = ({className} = {}) => {
    return <div className={cx("loading-indicator-rh2 loading-indicator", className)}>{ThreeDots()}</div>;
};

export const LoadingIndicatorWhite = ({className} = {}) => {
    return <div className={cx("loading-indicator-rh2 loading-indicator", className)}>{ThreeDots({className: "white-dots"})}</div>;
};
