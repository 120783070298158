import React from "react";

const InSyncIcon = ({className, fill = "#919EB0"}) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
        <path
            id="InSync_Icon"
            data-name="InSync Icon"
            d="M10.455,13.1l-1.68,1.68,5.4,5.4,12-12L24.5,6.5l-10.32,10.26Zm14.52,2.28a9.559,9.559,0,1,1-6.96-9.24l1.86-1.86a11.162,11.162,0,0,0-4.5-.9,12,12,0,1,0,12,12Z"
            transform="translate(-3.375 -3.375)"
        />
    </svg>
);

export default InSyncIcon;
