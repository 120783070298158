import {scope} from "@common/react/scope";
import "./conditional-format-rules.scss";
import {SingleColorFormatRules} from "./single-color-fortmat-rules/single-color-format-rules";
import {getConditionalDropdownOptions} from "./single-color-fortmat-rules/condition-dropdown/condition-dropdown";
import {TableSelectField} from "./single-color-fortmat-rules/table-select-field/table-select-field";

export const ConditionalFormatRules = ({state, tile}) => {
    const singleColorFormatRulesState = scope(state, ["conditions"]);
    const colorType = state.value.colorType;
    const tableColumns = tile.value.actualValueFields.filter((c) => ["Int", "Double"].indexOf(c.dataType) > -1).concat(tile.value.aggregatedValues);

    if (colorType == "SingleColor") {
        return <SingleColorFormatRules tile={tile} singleColorFormatRulesState={singleColorFormatRulesState} />;
    }

    return (
        <div className="format-based-on">
            <TableSelectField label="Format Based On" columns={tableColumns} {...scope(state, ["formatBasedOn"])} />
        </div>
    );
};
