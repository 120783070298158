import {getDateTimeFormatter} from "../factory/formatters/get-date-time-formatter";
import {booleanFormatter} from "../factory/formatters/boolean-formatter";
import {getFieldType} from "../common/get-field-type";
import {getNumericFormatter} from "../factory/formatters/get-numeric-formatter";
import {chain} from "../../../utils/fs";
import {roundTo} from "../common/formatters/numeric/round-to";

export const getBoxPlotFormatters = ({tile, rawData, timezone}) => {
    const dimFieldType = getFieldType(tile.xAxisField);
    const isDimensionRealDate = dimFieldType === "date";
    const dimensionFormatter = isDimensionRealDate
        ? getDateTimeFormatter(tile.xAxisField.dateProperties, timezone)
        : dimFieldType === "bool"
        ? booleanFormatter
        : (v) => v;
    let measurementFormatters = {};
    let tooltipValueFormatters = {};

    [tile.yAxisField]
        .filter((v) => v)
        .forEach((f, i) => {
            const numericProperties = f?.numericProperties;
            const isAggCount = f?.aggregationFunc?.startsWith("Count");

            const numericFormatter = getNumericFormatter({numericProperties, autoRound: true, useDefaultCountFormat: isAggCount});
            const tooltipValueFormatter = getNumericFormatter({numericProperties, useDefaultCountFormat: isAggCount});

            measurementFormatters[f.id] = (v) =>
                v != null &&
                chain(
                    v,
                    (v) => (numericProperties?.displayType === "Percentage" ? v * 100 : v),
                    (v) => (numericProperties?.decimalPlaces != null ? roundTo(v, numericProperties.decimalPlaces) : roundTo(v, 2)),
                    (v) => numericFormatter(v)
                );

            tooltipValueFormatters[f.id] = (v) =>
                v != null &&
                chain(
                    v,
                    (v) => (numericProperties?.displayType === "Percentage" ? v * 100 : v),
                    (v) => (numericProperties?.decimalPlaces != null ? roundTo(v, numericProperties.decimalPlaces) : roundTo(v, 2)),
                    (v) => tooltipValueFormatter(v)
                );
        });

    const dateTimeFormatter = getDateTimeFormatter({format: "MMM d, yyyy"}, timezone);

    return {
        dimensionFormatter,
        measurementFormatters,
        tooltipValueFormatters,
        dateFormatter: (v) => v != null && dateTimeFormatter(v),
        percentFormatter: (v) => roundTo(v, 1) + "%",
    };
};
