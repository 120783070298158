import {Expandable} from "../../../../../../../../../../common/expandable/expandable";
import {cs} from "@common/react/chain-services";
import {StaticTooltipService} from "../../../../../../../../../../common/tooltip3/static-tooltip-service";
import React from "react";
import {FieldSection} from "../../field-section/field-section";
import {FieldInfoProvider} from "../../../../../tile-tab/chart-types/common/field-info-provider/field-info-provider";
import {scope} from "@common/react/scope";
import {DebounceTextInput} from "@common/form/debounce-input/debounce-text-input";
import {ShowHideToggle} from "@common/form/toggles/show-hide-toggle";
import "./date-groups-configuration.scss";

export const DateGroupsConfiguration = ({field}) =>
    cs(() => {
        return Expandable({
            className: "date-groups-configuration-88i",
            label: "Date Groups Configuration",
            // alwaysShow: true,
            initExpand: field.value.isDateGroupCategory,
            iconTooltip: cs(
                [
                    "staticTooltip",
                    (_, next) =>
                        StaticTooltipService({
                            direction: "right",
                            info: (
                                <>
                                    Date fields used as categories are grouped by values in the past and the future calculated when the collection is viewed.
                                    <br />
                                    <br />
                                    Customize the label that shows when the groups are displayed in the tile.
                                </>
                            ),
                            tooltipBoxWidth: 250,
                            topOffset: -8,
                            next,
                        }),
                ],
                ({staticTooltip}) => (
                    <div className="expandable-tooltip-info-icon">
                        {staticTooltip.renderIcon({
                            icon: <i className="material-icons">info</i>,
                            className: "info-tooltip",
                        })}
                    </div>
                )
            ),
            render: () => (
                <>
                    {FieldSection({
                        header: FieldInfoProvider({
                            field: <div className="text">Values in the past</div>,
                        }),
                        content: (
                            <>
                                {DebounceTextInput({
                                    label: "Past Group Label",
                                    state: scope(field, ["pastDateGroupLabel"]),
                                })}
                            </>
                        ),
                    })}

                    {FieldSection({
                        header: FieldInfoProvider({
                            field: <div className="text">Values In The Future</div>,
                        }),
                        content: (
                            <>
                                {DebounceTextInput({
                                    label: "Future Group Label",
                                    state: scope(field, ["futureDateGroupLabel"]),
                                })}
                            </>
                        ),
                    })}

                    {FieldSection({
                        header: FieldInfoProvider({
                            field: <div className="text">Values without Dates (Null)</div>,
                        }),
                        content: (
                            <>
                                {DebounceTextInput({
                                    label: "Null Group Label",
                                    state: scope(field, ["nullDateGroupLabel"]),
                                })}
                            </>
                        ),
                    })}
                </>
            ),
        });
    });
