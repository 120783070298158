import * as React from "react";
import {cs} from "../../react/chain-services";
import "./toggle.scss";
import {cx} from "emotion";
import {tooltipService3} from "../../../web-client/src/routes/common/tooltip3/tooltip-service-3";

export const Toggle = ({
    state,
    smallToggle,
    left,
    undefined: undefined1,
    disabled,
    right,
    warningBeforeChange,
    tooltipInfo = null,
    isReversed,
}) =>
    cs(
        tooltipService3({
            direction: "above",
            tooltipContentStyle: {width: 150},
        }),
        ({tooltip}) => {
            const un1 = undefined1 && state.value == null;
            return (
                <div
                    {...(tooltipInfo ? tooltip(() => tooltipInfo) : {})}
                    className={cx("toggle toggle-4gl", un1 ? "undefined" : (isReversed ? !state.value : state.value) ? "left" : "right", {
                        smallToggle,
                        disabled,
                    })}
                    style={{
                        background: un1 ? undefined1.color : (isReversed ? !state.value : state.value) ? left.color : right.color,
                    }}
                    onClick={async (e) => {
                        if (disabled) {
                            return;
                        }
                        e.stopPropagation();
                        if (warningBeforeChange) {
                            const resp = await warningBeforeChange(!state.value);
                            if (resp) state.onChange(!state.value);
                        } else {
                            state.onChange(!state.value);
                        }
                    }}
                >
                    <div className="left">{left.label}</div>
                    <div className="right">{right.label}</div>
                    <div className={cx("rect")} />
                </div>
            );
        }
    );

export const DisabledToggle = ({}) =>
    cs(() => (
        <div className={cx("toggle toggle-4gl center")} style={{background: "#538C8B"}}>
            <div className={cx("rect")} />
        </div>
    ));
