import * as React from "react";
import {cs} from "@common/react/chain-services";
import {spc} from "@common/react/state-path-change";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {ShowHideToggle} from "../../../../../../../../../../common/form/toggles/show-hide-toggle";
import {DebounceNumberInput} from "../../../../../../../../../../common/form/debounce-input/debounce-number-input";
import {DebounceTextInput} from "../../../../../../../../../../common/form/debounce-input/debounce-text-input";
import {Button} from "../../../../../../../../../../common/form/buttons/button/button";
import {scope} from "@common/react/scope";
import {LineWidthSelect} from "../chart-types/common/line-width-select";
import "./edit-reference-line.scss";
import {LpLine} from "../../../../../../../common/left-panel/line/lp-line";
import {
    regressionTypes,
    movingAverageTypes,
} from "../../../../../../../../../../common/ui-components/charts/reference-lines/indicator-series";
import {aggregateValueTypes} from "../../../../../../../../../../common/ui-components/charts/reference-lines/constant-or-aggregate-value-series";
import {isStacked, isDimDate} from "../../../../../../../../../../common/ui-components/charts/common/bar-line-chart-helpers";
import {camelCaseToSpaces} from "../../../../../../../../../../common/utils/strings";
import {generateLineLabel, getMeasureGroups, getNewReferenceLineLabel} from "./reference-line-helpers";
import {renderColor} from "../colors/colors-tile-config";
import {getPath} from "@common/utils/arr-path";
import {FieldItem} from "../../../../../../edit/common/field-item/field-item";
import {consumeContext} from "@common/react/context";
import {MAX_NUM_COLOR} from "@common/ui-components/charts/get-field-color";

export const EditReferenceLine = ({tile, referenceLine, onDelete, onConfigColor}) =>
    cs(consumeContext("theme"), ({theme}) => {
        const stacked = isStacked(tile.value);
        const isScatterBubble = ["ScatterPlotTile", "BubbleChartTile"].includes(tile.value.$type);

        return (
            <div className="edit-reference-line-539">
                <div className="subsection">
                    <div className="content">
                        {DropdownSelect({
                            label: "Reference Line Type",
                            list: [
                                {
                                    label: "Constant Value",
                                    value: "ConstantValueReferenceLine",
                                    allowed: true,
                                },
                                {
                                    label: "Aggregate Value",
                                    value: "AggregateValueReferenceLine",
                                    allowed: !stacked,
                                },
                                {
                                    label: "Indicator/Trend",
                                    value: "IndicatorReferenceLine",
                                    allowed: isScatterBubble || (!stacked && isDimDate(tile.value)),
                                },
                            ].filter((l) => l.allowed),
                            valueToLabel: (v) => v.label,
                            isSelected: (v) => v.value === referenceLine.value?.$type,
                            onChange: (v) => {
                                const defaultSet = {
                                    ConstantValueReferenceLine: {value: null},
                                    AggregateValueReferenceLine: {
                                        aggregation: "Average",
                                        value: null,
                                    },
                                    IndicatorReferenceLine: {
                                        indicatorType: "LinearRegression",
                                    },
                                };

                                const newLabel = getNewReferenceLineLabel({
                                    referenceLine: referenceLine.value,
                                    newConfig: {
                                        $type: v.value,
                                        ...defaultSet[v.value],
                                    },
                                });

                                referenceLine.onChange({
                                    ...referenceLine.value,
                                    $type: v.value,
                                    ...defaultSet[v.value],
                                    label: newLabel,
                                });
                            },
                        })}

                        {referenceLine.value?.$type &&
                            {
                                ConstantValueReferenceLine: ConstantValue,
                                AggregateValueReferenceLine: AggregateValue,
                                IndicatorReferenceLine: ({referenceLine}) =>
                                    Indicator({referenceLine, hideMovingAverageTypes: isScatterBubble}),
                            }[referenceLine.value.$type]({tile, referenceLine})}

                        {AxisGroupSelect({tile, referenceLine})}
                    </div>
                </div>

                <div className="subsection">
                    <div className="header">Line Label</div>
                    <div className="content">
                        {DebounceTextInput({
                            label: "Line Label",
                            state: scope(referenceLine, ["label"]),
                        })}
                        {LpLine({
                            label: "Line Label",
                            control: ShowHideToggle({
                                state: scope(referenceLine, ["labelShown"]),
                            }),
                        })}
                        {LpLine({
                            label: "Data Label",
                            control: ShowHideToggle({
                                state: scope(referenceLine, ["dataLabelShown"]),
                            }),
                        })}
                    </div>
                </div>
                <div className="subsection">
                    <div className="header">Line Style</div>
                    <div className="content">
                        {DropdownSelect({
                            label: "Line Style",
                            list: ["Solid", "Dash"],
                            isSelected: (v) => v === (referenceLine.value?.lineStyle || "Solid"),
                            onChange: (v) => spc(referenceLine, ["lineStyle"], () => v),
                        })}
                        {LineWidthSelect(scope(referenceLine, ["lineWidth"]))}

                        {(() => {
                            const {discreteColorsRGB} = getPath(theme, ["dataVisualization", "dataColorPalettes"]);
                            const colorIndex = referenceLine.value.colorApplication?.index ?? MAX_NUM_COLOR.DiscreteColorApplication - 1;
                            return (
                                <FieldItem
                                    className="field-color-bbg"
                                    label={
                                        <div>
                                            <div className="field-name">{referenceLine.value.label}</div>
                                            <div className="selected-color">
                                                {renderColor["DiscreteColorApplication"]({
                                                    index: colorIndex,
                                                    displayColor: discreteColorsRGB[colorIndex],
                                                })}
                                            </div>
                                        </div>
                                    }
                                    rightIcon={<i className="far fa-arrow-right" aria-hidden="true" />}
                                    onClick={() =>
                                        onConfigColor({
                                            id: referenceLine.value.id,
                                            type: "color",
                                        })
                                    }
                                />
                            );
                        })()}
                    </div>
                </div>

                <div className="control-btns">
                    <Button size="small" btnType="danger" className="delete-btn" onClick={onDelete}>
                        Delete Line
                    </Button>
                </div>
            </div>
        );
    });

const ConstantValue = ({referenceLine}) => {
    return DebounceNumberInput({
        label: "Value",
        state: {
            value: referenceLine.value.value,
            onChange: (v) => {
                referenceLine.onChange({
                    ...referenceLine.value,
                    value: v,
                    label: generateLineLabel({
                        $type: "ConstantValueReferenceLine",
                        value: v,
                    }),
                });
            },
        },
    });
};

const AggregateValue = ({referenceLine}) => (
    <>
        {DropdownSelect({
            label: "Aggregation Type",
            list: aggregateValueTypes,
            isSelected: (v) => v === referenceLine.value?.aggregation,
            onChange: (v) => {
                const newLabel = getNewReferenceLineLabel({
                    referenceLine: referenceLine.value,
                    newConfig: {
                        aggregation: v,
                    },
                });

                referenceLine.onChange({
                    ...referenceLine.value,
                    aggregation: v,
                    label: newLabel,
                });
            },
        })}
        {referenceLine.value?.aggregation === "Percentile" &&
            DebounceNumberInput({
                label: "Percentile",
                state: scope(referenceLine, ["value"]),
            })}
    </>
);

const Indicator = ({referenceLine, hideMovingAverageTypes}) => (
    <>
        {DropdownSelect({
            label: "Indicator/Trend Type",
            list: [...regressionTypes, ...(hideMovingAverageTypes ? [] : movingAverageTypes)].map((value) => ({
                label: camelCaseToSpaces(value),
                value,
            })),
            valueToLabel: (v) => v.label,
            isSelected: (v) => v.value === referenceLine.value?.indicatorType,
            onChange: (v) => {
                const newLabel = getNewReferenceLineLabel({
                    referenceLine: referenceLine.value,
                    newConfig: {
                        indicatorType: v.value,
                    },
                });

                referenceLine.onChange({
                    ...referenceLine.value,
                    indicatorType: v.value,
                    label: newLabel,
                    ...(v.value.startsWith("Polynomial") && {
                        order: referenceLine.value.order || 2,
                    }),
                    ...((v.value.includes("MovingAverage") || v.value === "AccelerationBand") && {
                        period: referenceLine.value.period || 2,
                    }),
                    ...(v.value === "AccelerationBand" && {
                        factor: referenceLine.value.factor || 0.01,
                    }),
                });
            },
        })}
        {referenceLine.value?.indicatorType === "PolynomialRegression" && (
            <>
                {DropdownSelect({
                    label: "Order",
                    list: [
                        {
                            label: "Quadratic Expression",
                            value: 2,
                        },
                        {
                            label: "Cubic Expression",
                            value: 3,
                        },
                        {
                            label: "Quartic Expression",
                            value: 4,
                        },
                    ],
                    valueToLabel: (v) => v.label,
                    isSelected: (v) => v.value === referenceLine.value?.order,
                    onChange: (v) => spc(referenceLine, ["order"], () => v.value),
                })}
            </>
        )}
        {referenceLine.value?.indicatorType?.includes("MovingAverage") &&
            DebounceNumberInput({
                label: "Period",
                state: scope(referenceLine, ["period"]),
            })}
        {referenceLine.value?.indicatorType === "AccelerationBand" && (
            <>
                {DebounceNumberInput({
                    label: "Factor",
                    state: scope(referenceLine, ["factor"]),
                })}
                {DebounceNumberInput({
                    label: "Period",
                    state: scope(referenceLine, ["period"]),
                })}
            </>
        )}
    </>
);

const AxisGroupSelect = ({tile, referenceLine}) => {
    const measureGroups = getMeasureGroups({tile: tile.value, forReferenceLineType: referenceLine.value.$type});

    const axisGroups = measureGroups.map((mfg) => ({
        value: mfg.id,
        label: mfg.measureFields?.map((f) => f.displayName).join(" & ") || mfg.displayName,
    }));

    return DropdownSelect({
        label: "Axis Group",
        list: axisGroups,
        valueToLabel: (v) => v.label,
        isSelected: (v) => v.value === referenceLine.value?.measureGroupID,
        onChange: (v) => spc(referenceLine, ["measureGroupID"], () => v.value),
    });
};
