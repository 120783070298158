const {autoRoundNumber} = require("./auto-round-number");
const {paddingRight} = require("../../../../../utils/strings");
const {createString} = require("../../../../../utils/strings");
const {chain} = require("../../../../../utils/fs");

const createMainNumberFormatter = ({displayType, decimalSeperator, thousandsSeperator, decimalPlaces, negativeFormat}, {autoRound}) => {
    return (number1) => {
        const {number, tail} = autoRound ? autoRoundNumber(number1) : {number: number1};
        const {negative, main, decimal} = breakNumber(number);

        return chain(
            [
                thousandsSeperator === "None" ? main : addThousandsSeperator(main, {Dot: ".", Comma: ",", Space: " "}[thousandsSeperator]),
                displayType.startsWith("Currency")
                    ? autoRound // AB#1352
                        ? tail
                            ? decimal
                            : decimal && decimalPlaces && paddingRight(decimal, decimalPlaces) // AB#630
                        : decimalPlaces && paddingRight(decimal, decimalPlaces)
                    : autoRound
                    ? decimal
                    : decimalPlaces && paddingRight(decimal, decimalPlaces),
            ]
                .filter((v) => v)
                .join({Dot: ".", Comma: ","}[decimalSeperator]),
            (_) => _ + (tail || ""),
            (_) => (!negative ? _ : negativeFormat === "MinusSign" ? "-" + _ : "(" + _ + ")")
        );
    };
};
exports.createMainNumberFormatter = createMainNumberFormatter;

const breakNumber = (number) => {
    const str = number.toString();
    const m = /(-)?([\d.]+)e\+([\d-]+)$/.exec(str);

    if (m) {
        // 1.234e+4 format
        const dotPos = chain(m[2].indexOf("."), (_) => (_ === -1 ? m[2].length : _));
        return {
            negative: !!m[1],
            ...(({dotPos, digits}) => {
                if (dotPos > digits.length) {
                    return {
                        main: digits + createString("0", dotPos - digits.length),
                        decimal: null,
                    };
                } else if (dotPos < 0) {
                    return {
                        main: null,
                        decimal: createString("0", -dotPos) + digits,
                    };
                } else {
                    return {
                        main: digits.substring(0, dotPos),
                        decimal: digits.substring(dotPos),
                    };
                }
            })({
                dotPos: dotPos + +m[3],
                digits: m[2].replace(".", ""),
            }),
        };
    } else {
        const m1 = /(-)?(\d*)(\.\d+)?/.exec(str);
        return {
            negative: !!m1[1],
            main: m1[2],
            decimal: m1[3]?.substring(1),
        };
    }
};

const addThousandsSeperator = (str, separator) => {
    let ret = "";
    for (let i = 0; i < str.length; i++) {
        if (i > 0 && (str.length - i) % 3 === 0) {
            ret += separator;
        }
        ret += str[i];
    }
    return ret;
};
