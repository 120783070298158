import {LpShowHideLine} from "../../../../../common/left-panel/lp-show-hide-line";
import {ffToShowHideLine} from "../../../../../../../../common/form/ff-to-show-hide-line";
import {NumberInput} from "../../../../../../../../common/form/number-input/number-input";
import {ffToNumberInput} from "../../../../../../../../common/form/ff-to-number-input";
import {LpColorLine} from "../../../../../common/left-panel/lp-color-line";
import {ffToColorPickerLine} from "../../../../../../../../common/form/ff-to-color-picker-line";
import {FieldSection} from "../../../../../collection/tile/edit/left-panel/tabs/fields-tab/chart-types/common/field-section/field-section";
import {FieldInfoProvider} from "../../../../../collection/tile/edit/left-panel/tabs/tile-tab/chart-types/common/field-info-provider/field-info-provider";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {ffToDropdown} from "../../../../../../../../common/form/ff-to-dropdown";
import {FontOptionsSelect} from "../../../../../collection/tile/edit/left-panel/tabs/tile-tab/chart-types/table/panels/font-options-select/font-options-select";
import {CaseOptionSelect} from "../../../../../collection/theme/common/case-options/case-options-select";
import {FontSizeSelect} from "../../../../../collection/tile/edit/left-panel/tabs/tile-tab/chart-types/common/font-size-select";

export const SectionTitleText = ({form, key}) => {
    return (
        <>
            {FieldSection({
                header: FieldInfoProvider({
                    field: <div className="text">SECTION TITLE TILE</div>,
                    noCollapsible: true,
                }),
                content: (
                    <>
                        {LpColorLine({
                            label: "Background Color",
                            inheritColor: form.field(["general", "tile", "styles", "tileBackgroundColorRGB"]).state.value,
                            ...ffToColorPickerLine(form.field(["dataVisualization", "sectionTile", "backgroundColorRGB"])),
                        })}

                        {LpColorLine({
                            label: "Font Color",
                            inheritColor: form.field(["general", "tile", "styles", "titleFontColorRGB"]).state.value,
                            ...ffToColorPickerLine(form.field(["dataVisualization", "sectionTile", "fontColorRGB"])),
                        })}

                        {FontSizeSelect({
                            label: "Font Size",
                            ...ffToDropdown(form.field(["dataVisualization", "sectionTile", "fontSize"])),
                        })}

                        {FontOptionsSelect({
                            label: "FONT STYLE",
                            state: form.field(["dataVisualization", "sectionTile", "fontStyle"]).state,
                        })}

                        {CaseOptionSelect({
                            label: "CASE STYLE",
                            state: form.field(["dataVisualization", "sectionTile", "caseStyle"]).state,
                        })}
                    </>
                ),
            })}

            {FieldSection({
                header: FieldInfoProvider({
                    field: <div className="text">TEXT TILE</div>,
                    noCollapsible: true,
                }),
                content: (
                    <>
                        {LpColorLine({
                            label: "Font Color",
                            inheritColor: form.field(["general", "tile", "styles", "titleFontColorRGB"]).state.value,
                            ...ffToColorPickerLine(form.field(["dataVisualization", "textTile", "fontColorRGB"])),
                        })}

                        {FontSizeSelect({
                            label: "Font Size",
                            ...ffToDropdown(form.field(["dataVisualization", "textTile", "fontSize"])),
                        })}
                    </>
                ),
            })}
        </>
    );
};
