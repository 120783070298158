import "./select-collection-dialog.scss";

import React from "react";

import {Load} from "@common/react/load";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {Form2} from "@common/react/cs-form/form2";
import {DialogService} from "../../../../../../common/dialog/dialog-service";
import {consumeContext} from "@common/react/context";

import {Button} from "../../../../../../../../../common/form/buttons/button/button";

import {collectionFormConfig} from "../../../../../../dashboard/env/collections/collection-cm/create-collection/collection-form-config";

import {CollectionList} from "./collection-list";
import {CreateNewCollection} from "./create-new-collection";

export const SelectCollectionDialog = ({next: rootNext, collection, onDone}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    render: ({resolve}) => ({
                        title: "CLONE TO...",
                        width: 347,
                        content: next({
                            resolve,
                        }),
                        className: "select-collection-dialog-wrapper-a33",
                        customCloseIcon: <img src={require("./icons/close-x-icon.svg")} />,
                    }),
                    next: rootNext,
                }),
        ],
        ({modal}) =>
            cs(
                consumeContext("apis"),
                [
                    "collections",
                    ({apis, routing}, next) =>
                        Load({
                            fetch: () => apis.collection.getCollectionsOverview(),
                            next,
                        }),
                ],
                [
                    "models",
                    ({apis}, next) =>
                        Load({
                            fetch: () => apis.model.getModels(),
                            next,
                        }),
                ],
                ["keyword", (_, next) => UseState({next, initValue: ""})],
                ["selected", (_, next) => UseState({next})],
                ["cloning", (_, next) => UseState({next})],
                [
                    "clone",
                    ({selected, cloning}, next) =>
                        next(async () => {
                            try {
                                cloning.onChange(true);
                                await onDone(selected.value);
                                cloning.onChange(false);
                                modal.resolve();
                            } catch(e) {
                                cloning.onChange(false);
                            }


                        }),
                ],
                [
                    "form",
                    ({selected, apis, routing, modal, clone}, next) =>
                        Form2({
                            data: selected,
                            ...collectionFormConfig({
                                apis,
                                collection: selected.value,
                            }),
                            onSubmit: async () => {
                                clone();
                            },
                            next,
                        }),
                ],
                ({collections, models, selected, clone, cloning, form}) => {
                    return (
                        <div className="select-collection-dialog-a32">
                            <div className="dialog-body">
                                {selected.value == null || selected.value.id
                                    ? CollectionList({
                                        selected,
                                        collections,
                                        collection,
                                    })
                                    : CreateNewCollection({
                                        sourceCollection: collection,
                                        selected,
                                        models,
                                        form,
                                    })}
                            </div>

                            <div className="dialog-footer">
                                <Button disabled={!selected.value?.name || !selected.value?.modelID || cloning.value} onClick={selected.value?.id ? clone : form.submit}>
                                    Clone
                                </Button>
                            </div>
                        </div>
                    );
                }
            )
    );
