import {cs} from "@common/react/chain-services";
import * as React from "react";
import {consumeContext} from "@common/react/context";

export const Link = ({to, ...props}) =>
    cs(consumeContext("routing"), ({routing}) => (
        <a
            {...{
                ...props,
                href: "#" + to,
                onClick: (e) => {
                    e.preventDefault();
                    routing.pushHistory(to);
                },
            }}
        />
    ));
