const {UseState} = require("./use-state");
const {cs} = require("./chain-services");

const StateProxy = ({state, condition, same = (v1, v2) => v1 === v2, next}) =>
    cs(["buffer", (_, next) => UseState({next})], ({buffer}) =>
        next({
            state: {
                value: (buffer.value ?? state).value,
                onChange: (v) => {
                    if (!same(state.value, v) && condition && !condition(v)) {
                        buffer.onChange({value: v});
                    } else {
                        state.onChange(v);
                        if (buffer.value != null) {
                            buffer.onChange(null);
                        }
                    }
                },
            },
            flush: () => buffer.onChange(null),
        })
    );
exports.StateProxy = StateProxy;
