const {findMaxValue} = require("../../../../utils/collections");
const {sum} = require("../../../../utils/collections");
const {createArray} = require("../../../../utils/collections");
const {chain} = require("../../../../utils/fs");
const {flatten1} = require("../../../../utils/collections");

const getGridStepOption = ({series, barDisplay, gridStepType, gridStepNumeric}) => {
    if (!gridStepType || !gridStepNumeric) {
        return {
            tickPositions: null,
            tickInterval: null,
            tickPositioner: null,
        };
    }

    if (gridStepType === "Count") {
        // return getCountTicks({series, gridStepNumeric});
        // return getTickInterval({series, gridStepNumeric, barDisplay});
        return {
            tickAmount: gridStepNumeric,
        };
    }

    return {
        tickInterval: gridStepNumeric,
    };
};
exports.getGridStepOption = getGridStepOption;

// const getCountTicks = ({series, gridStepNumeric}) => {
//     const getValues = (series) => series.data?.map((d) => d[1]) || [];
//
//     const maxData = chain(
//         last(sort(flatten1(series.map((s) => getValues(s))))),
//         (_) => Math.ceil(_),
//     );
//
//     const roundMax = Math.ceil(maxData / 100) * 100;
//
//     const gapNo = gridStepNumeric > 1 ? (gridStepNumeric - 1) : 1;
//     const interval = Math.round(roundMax / gapNo);
//
//     return {
//         max: roundMax,
//         tickPositions: createArray(gapNo + 1).map((_, i) => i*interval),
//     }
// };

// const getTickInterval = ({series, gridStepNumeric, barDisplay}) => {
//
//     const maxData = (() => {
//         if (!["Stacked", "HundredPercent"].includes(barDisplay)) {
//             const getValues = (series) => series.data?.map((d) => d[1]) || [];
//             return chain(
//                 flatten1(series.map((s) => getValues(s))),
//                 (_) => findMaxValue(_),
//                 (_) => Math.ceil(_),
//             );
//         }
//
//         if (barDisplay === "Stacked") {
//             const getMaxValuesSum = (series) => {
//                 if (!series?.length) {
//                     return 0;
//                 }
//                 const maxDataLength = findMaxValue(series.map((s) => s.data.length));
//                 return chain(
//                     createArray(maxDataLength).map((_, index) => sum(series.map((s) => s.data[index]?.[1] || 0))),
//                     (_) => findMaxValue(_),
//                     (_) => Math.ceil(_),
//                 );
//             };
//
//             return Math.max(
//                 getMaxValuesSum(series.filter((s) => !s.isCompare)),
//                 getMaxValuesSum(series.filter((s) => s.isCompare)),
//             );
//         }
//
//         if (barDisplay === "HundredPercent") {
//             return 100;
//         }
//     })();
//
//     const roundMax = Math.ceil(maxData / 100) * 100;
//     const paddedMax = barDisplay === "HundredPercent" ? 100 : roundMax * 1.1; // add 10% for padding top
//
//     const gapNo = gridStepNumeric > 1 ? (gridStepNumeric - 1) : 1;
//     const interval = Math.ceil(paddedMax / gapNo); // should be the ceiling. b/c if the #gapNo of the intervals do not add up to paddedMax, one more gap will be added
//
//     return {
//         max: paddedMax,
//         tickInterval: interval,
//     };
// };
