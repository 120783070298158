import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {SuspendUpdate} from "@common/react/suspend-update";
// import {LoadingIndicator} from "@common/ui-components/loading-indicator/loading-indicator";
import {isBlank} from "@common/utils/strings";
import {HeaderBar} from "../../../../common/header-bar/header-bar";
import {MoveToDialog} from "../../../../dashboard/env/common/move-to-dialog/move-to-dialog";
import {RenameDataSourceDialog} from "../../../common/rename-datasource-dialog/rename-datasource-dialog";
import {RemoveSourceDialog} from "../../../remove-source-dialog/remove-source-dialog";
import {cx} from "emotion";
import "./ds-sync-layout.scss";
import {dataSourceTypes} from "../../../common/data-source-type";
import {NavigateSyncSettings} from "../common/navigate-sync-settings";
import {waitTimeout} from "@common/utils/wait-timeout";

export const DsSyncLayout = ({dataSource, onBack, content, rightPanel, blurOverlay}) =>
    cs(
        consumeContext("tenant"),
        consumeContext("routing"),
        consumeContext("toast"),
        [
            "renameDataSourceDialog",
            ({collection}, next) =>
                RenameDataSourceDialog({
                    next,
                    onDone: () => dataSource.reload(),
                }),
        ],
        [
            "deleteDataSourceDialog",
            ({routing, toast}, next) =>
                RemoveSourceDialog({
                    onDone: async (dataSourceID, dataSourceName) => {
                        const envId = routing.params.envId;
                        routing.goto("dashboard", {envId, tab: "data-sources"});
                        await waitTimeout(500);
                        toast.show(dataSourceName + " deleted");
                    },
                    next,
                }),
        ],
        [
            "dataSourceCommands",
            ({renameDataSourceDialog, deleteDataSourceDialog, routing}, next) =>
                cs(
                    [
                        "moveDsDialog",
                        (_, next) =>
                            MoveToDialog({
                                label: "DataSource",
                                apiType: "data",
                                next,
                            }),
                    ],
                    [
                        "goSyncSettings",
                        (_, next) =>
                            cs(["navigateSyncSettings", (_, next) => NavigateSyncSettings({next})], ({navigateSyncSettings}) =>
                                next(() => navigateSyncSettings.goto())
                            ),
                    ],
                    ({moveDsDialog, goSyncSettings}) => {
                        if (dataSource.loading) return next([]);

                        return next([
                            ...([dataSourceTypes.FILE, dataSourceTypes.GOOGLE_SHEETS].includes(dataSource.value.type)
                                ? [
                                      {
                                          label: "Import New Version",
                                          id: "import-version",
                                          onClick: () =>
                                              routing.goto("edit-import-new-flat-file", {
                                                  envId: routing.params.envId,
                                                  dsId: routing.params.dsId,
                                              }),
                                      },
                                  ]
                                : [
                                      {
                                          label: "Connection Settings",
                                          onClick: () =>
                                              routing.goto("edit-connection-settings", {
                                                  envId: routing.params.envId,
                                                  dsId: routing.params.dsId,
                                              }),
                                          id: "connection-settings",
                                      },
                                      {
                                          label: "Sync Settings",
                                          onClick: goSyncSettings,
                                          id: "sync-settings",
                                          disabled: (dataSource.value?.errors || [])?.filter((x) => !x.dataSourceTableID).length > 0,
                                          disabledTooltip: `Cannot enter Sync Settings because there is an error connecting to the database.`,
                                      },
                                  ]),
                            {
                                label: "Rename",
                                onClick: () =>
                                    renameDataSourceDialog.show({
                                        dataSource: dataSource.value,
                                    }),
                                id: "rename",
                            },
                            {
                                label: "Delete Data Source",
                                className: "delete-action",
                                onClick: () =>
                                    deleteDataSourceDialog.show({
                                        dataSources: [dataSource.value],
                                    }),
                                id: "delete",
                            },
                        ]);
                    }
                ),
        ],
        ({dataSourceCommands, routing}) => {
            return (
                <div className="ds-sync-layout-86o">
                    {HeaderBar({
                        ID: dataSource.value?.id,
                        type: "datasource",
                        name: !isBlank(dataSource?.value?.name) ? dataSource?.value?.name : !routing.params.dsId ? "New Data Source" : "",
                        commands: dataSourceCommands,
                    })}

                    <div className="content">{content}</div>

                    {rightPanel && (
                        <>
                            <div
                                className={cx("right-panel right-panel-88o", {
                                    active: rightPanel?.content !== null,
                                })}
                            >
                                {cs(
                                    ({}, next) =>
                                        SuspendUpdate({
                                            active: !rightPanel?.content,
                                            delay: 500,
                                            next,
                                        }),
                                    () => rightPanel?.content
                                )}
                            </div>

                            {rightPanel?.content && blurOverlay && <div className="overlay" />}
                        </>
                    )}
                </div>
            );
        }
    );
