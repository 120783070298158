exports.sampleThemes = [
    {
        id: "0fa1a80c-dc70-4dcb-880b-f2dbdeb0933d",
        name: "Default Theme",
        backgroundColorRGB: "#ffffff",
        tileSpacing: "Narrow",
        font: "Roboto",
        fontColorRGB: "#808080",
        tileBorderWidth: 1,
        tileCornerRadius: 3,
        tileBackgroundColorRGB: "#FFFFFF",
        tileBorderColorRGB: "#E9ECEF",
        emptyFillColorRGB: "#E9ECEF",

        buttonColorRGB: "#002CA6",
        buttonHoverColorRGB: "#0034C4",
        buttonFontColorRGB: "#FFFFFF",

        tableBorderWidth: 1, // [0, 5]
        tableBorderColorRGB: "#EAEAEA",
        tableCellColorRGB: "#EAEAEA",

        tableAlternateType: "AlternateRows", // None, AlternateRows, AlternateCols
        tableAltCellColorRGB: null,

        dataColorPalette: [
            "#2364C2", // "pink",
            "#7EBD36",
            "#F7931E",
            "#FB3640",
            "#605F5E",
            "#01295F",
            "#0091CF",
            "#AAB613",
            "#FC8023",
            "#C25935",
            "#424141",
            "#624645",
            "#00AF8F",
            "#D3AC00",
            "#FF3D04",
            "#8E6243",
        ],
    },
    {
        id: "6e5a7658-21a1-419e-973e-9626a81af92e",
        name: "Default Theme",
        dataColorPalette: ["#2364C2", "#7EBD36", "#F7931E", "#FB3640", "#605F5E", "#01295F", "#0091CF", "#AAB613", "#FC8023", "#C25935", "#424141", "#624645", "#00AF8F", "#D3AC00", "#FF3D04", "#8E6243"],
    },
];
