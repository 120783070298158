import {required} from "@common/react/cs-form/validators/required";
import {min} from "@common/react/cs-form/validators/min";
import {max} from "@common/react/cs-form/validators/max";

export const themeFormConfig = ({apis}) => ({
    fields: {
        name: {
            transforms: ["trim"],
            validators: [required],
            asyncValidators: [
                {
                    validate: (name, {data}) =>
                        apis.collection.checkThemeName(name, {
                            themeId: data?.id,
                        }),
                    getMessage: () => `Existed`,
                },
            ],
        },
        backgroundColorRGB: [required],
        tileSpacing: [required],
        font: [required],
        fontColorRGB: [required],
        buttonFontColorRGB: [required],
        buttonColorRGB: [required],
        buttonHoverColorRGB: [required],
        tileBackgroundColorRGB: [required],
        tileBorderColorRGB: [required],
        emptyFillColorRGB: [required],
        tableBorderColorRGB: [required],
        tableCellColorRGB: [required],
        tileBorderWidth: [required, max(5), min(0)],
        barCornerRadius: [required, min(0)],
        barCornerRadiusLocation: [required],
        areaLineChartFill: [required],

        "container.button.borderWidth": [max(5), min(0)],
        "container.button.cornerRadius": [max(30), min(0)],
        "container.tab.bottomBorderHeight": [min(0)],
        "container.dropDown.borderWidth": [max(5), min(0)],
        "container.dropDown.cornerRadius": [max(30), min(0)],
        "container.dropDown.shadowSize": [max(5), min(0)],
        "container.dropDown.shadowTransparency": [max(100), min(0)],
    },
});
