import React from "react";

const DataSourceErrorIcon = ({className, fill = "#919EB0"}) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24.035" height="24" viewBox="0 0 24.035 24">
        <path
            id="SourceError_Icon"
            data-name="SourceError Icon"
            d="M-2388.878-2957.76a7.756,7.756,0,0,1,7.752-7.76,7.765,7.765,0,0,1,7.768,7.76,7.765,7.765,0,0,1-7.768,7.761A7.756,7.756,0,0,1-2388.878-2957.76Zm1.552,0a6.207,6.207,0,0,0,6.208,6.208,6.207,6.207,0,0,0,6.208-6.208,6.206,6.206,0,0,0-6.208-6.207A6.206,6.206,0,0,0-2387.327-2957.76Zm5.433,3.88v-1.552h1.552v1.552Zm-8.575-1.6a16.859,16.859,0,0,1-4.163-.739c-2.283-.762-2.762-1.8-2.762-2.541v-11.911c0-.737.481-1.776,2.772-2.54a18.6,18.6,0,0,1,5.659-.788,18.607,18.607,0,0,1,5.66.788c2.291.764,2.772,1.8,2.772,2.54v3.35c-.2-.013-.4-.019-.606-.019-.314,0-.632.015-.943.046v-1.354a7.726,7.726,0,0,1-1.223.516,18.61,18.61,0,0,1-5.66.788,18.6,18.6,0,0,1-5.659-.788,7.742,7.742,0,0,1-1.224-.517v3.934c0,.164.417.641,1.7,1.071a16.811,16.811,0,0,0,4.933.706,9.57,9.57,0,0,0-.8,1.527,17.351,17.351,0,0,1-4.622-.764,7.874,7.874,0,0,1-1.214-.512v3.927c0,.164.417.642,1.7,1.072a14.853,14.853,0,0,0,3.429.639,9.619,9.619,0,0,0,.244,1.569h0Zm-3.663-16.262c-1.3.431-1.714.908-1.714,1.071s.418.639,1.714,1.07a17.032,17.032,0,0,0,5.17.708,17.031,17.031,0,0,0,5.169-.708c1.3-.431,1.714-.907,1.714-1.07s-.418-.64-1.714-1.071a17.033,17.033,0,0,0-5.169-.707A17.034,17.034,0,0,0-2394.132-2971.743Zm12.238,14.759v-4.656h1.552v4.656Z"
            transform="translate(2397.394 2974)"
        />
    </svg>
);

export default DataSourceErrorIcon;
