import * as React from "react";
import {cs} from "@common/react/chain-services";
import {Button} from "../../../../../../../../../../../common/form/buttons/button/button";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";
import {DialogService} from "../../../../../../../../common/dialog/dialog-service";
import "./add-remove-dimension-dialog.scss";
import {consumeContext} from "@common/react/context";
import {FieldControl} from "../common/render-field-control/field-control";

export const AddRemoveDimensionDialog = ({next: rootNext}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    render: ({resolve, args: {tile, type, nonNumericMeasureAttrs}}) => ({
                        title: elements[type].title,
                        width: 540,
                        content: next({
                            resolve,
                            type,
                            tile,
                            nonNumericMeasureAttrs,
                        }),
                    }),
                    next: rootNext,
                }),
        ],
        consumeContext("getFieldInfo"),
        ({modal, getFieldInfo}) => {
            const {btnLabel, Comp} = elements[modal.type];
            return (
                <div className="add-remove-dimension-dialog-72f">
                    <VerbDialogBodyScrollbar>
                        <Comp tile={modal.tile} getFieldInfo={getFieldInfo} nonNumericMeasureAttrs={modal.nonNumericMeasureAttrs} />
                    </VerbDialogBodyScrollbar>
                    <div className="buttons">
                        <Button btnType="secondary" onClick={() => modal.resolve(false)}>
                            Cancel
                        </Button>
                        <Button onClick={() => modal.resolve(true)}>{btnLabel}</Button>
                    </div>
                </div>
            );
        }
    );

const RemoveDimension = ({tile, getFieldInfo, nonNumericMeasureAttrs}) => {
    return (
        <div className="remove-dimension-0c2">
            <div className="messages">
                <div className="message">
                    Removing the Dimension field will automatically remove any measure fields that are not numeric data types because there is no aggregation without the dimension.
                </div>
                <div className="message">These fields will be removed. Are you sure you want to make this change?</div>
            </div>
            <div className="fields">
                {nonNumericMeasureAttrs.map((attr) => {
                    return (
                        <div key={attr} className="field">
                            <div className="name">{attr === "xAxisField" ? "X-Axis" : "Y-Axis"}</div>
                            <div className="card">
                                {FieldControl({
                                    field: tile[attr],
                                    getFieldInfo,
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

const AddDimension = ({tile}) => {
    return (
        <div className="messages">
            <div className="message">
                Adding a Dimension field will update the values in the measure fields to aggregate based on the new dimension field instead of showing as actual values.
            </div>
            <div className="message">Are you sure you want to make this change?</div>
        </div>
    );
};

const elements = {
    remove: {
        title: "Remove dimension and measures",
        btnLabel: "Remove Dimension & Measure",
        Comp: RemoveDimension,
    },
    add: {
        title: "Add aggregation to measures",
        btnLabel: "Add Dimension",
        Comp: AddDimension,
    },
};
