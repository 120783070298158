import {TileTitle} from "../common/tile-title/tile-title";
import {SectionOptions} from "../common/section-options/section-options";
import {TextTileContent} from "../common/text-tile-content/text-tile-content";

export const TextTile = ({tile, size, form, parentTile, tileAction, kpiColorsTileConfig, isContainerTile}) =>
    [TileTitle, TextTileContent].map((panel) =>
        panel({
            tile,
            size,
            form,
            parentTile,
            tileAction,
            kpiColorsTileConfig,
            isContainerTile,
        })
    );
