const {cGetFontSize} = require("../common/style-map/font-size");

const getLegendOptions = ({style: style1, theme}) => {
    const style = style1 || {};
    const getFontSize = cGetFontSize(theme.general.canvas.fontSize, theme);

    return {
        enabled: !!style.legendShown,

        title: {
            text: style.legendTitleShown ? style.legendTitle : null,
            style: {
                color: theme.dataVisualization.fonts.fontColorRGB || theme.general.canvas.fontColorRGB,
                fontWeight: "normal",
                fontFamily: `"${theme.dataVisualization.fonts.fontFamily || theme.general.canvas.fontFamily}", sans-serif`,
            },
        },

        itemStyle: {
            fontSize: getFontSize({
                group: theme.dataVisualization.toolTipsAndLegends.legendFontSize,
            }),
        },

        symbolHeight: 18,
        symbolWidth: 18,
        symbolRadius: 3,
        useHTML: true,

        ...(style.legendPosition === "Bottom" && {
            layout: "horizontal",
            align: "bottom",
            verticalAlign: "bottom",
            x: 10,
        }),

        ...(style.legendPosition === "Top" && {
            layout: "horizontal",
            align: "top",
            verticalAlign: "top",
            x: 10,
        }),

        ...(style.legendPosition === "Right" && {
            layout: "vertical",
            align: "right",
            verticalAlign: "top",
            itemMarginBottom: 10,
        }),

        ...(style.legendPosition === "Left" && {
            layout: "vertical",
            align: "left",
            verticalAlign: "top",
            itemMarginBottom: 10,
        }),

        labelFormatter: function () {
            const style = `
                position: relative; top: -3px; 
                font-weight: normal; 
                font-family: ${theme.dataVisualization.fonts.fontFamily || theme.general.canvas.fontFamily}, sans-serif; 
                color: ${theme.general.canvas.fontColorRGB || "black"}
            `;
            return `<span style="${style}">${this.name}</span>`;
        },
    };
};
exports.getLegendOptions = getLegendOptions;
