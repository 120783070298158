import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {stateToSelect} from "@common/ui-components/form/state-to-select";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";

export const FontSizeSelect = ({value, onChange, themeFontSizeGroup, noThemeDefaultOption, ...props}) =>
    cs(consumeContext("theme"), ({theme}) => {
        const defaultFontSizeGroup = theme.general.canvas.fontSize || themeFontSizeGroup;
        const defaultList = ["Extra Small", "Small", "Medium", "Large", "Extra Large"];
        const list = noThemeDefaultOption
            ? defaultList
            : [
                  null, // theme default option
                  ...defaultList,
              ];

        return DropdownSelect({
            label: props.label || "Font Size",
            list: list.map((label) => ({
                label: label === "Medium" ? "Standard" : label,
                value: label ? label.replace(/ /g, "") : null,
            })),
            valueToLabel: (v) =>
                v.label === null
                    ? `Theme Default (${!defaultFontSizeGroup || defaultFontSizeGroup === "Medium" ? "Standard" : defaultFontSizeGroup.replace(/([A-Z])/g, " $1").trim()})`
                    : v.label,
            isSelected: (v) => (!v.value && !value) || v.value === value,
            onChange: (v) => onChange(v.value),
        });
    });
