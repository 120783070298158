import {headerMapping} from "./header-mapping";
import {aggregatesMapping} from "./aggregates-mapping";
import {findChildRelated, getChildGroup} from "./pivot-table-common";
import {sort} from "../../../../utils/collections";
import {headerDelim} from "@common/ui-components/charts/pivot-table/common/pivot-table-column-value";

const addGrandTotalColumn = (headers) => {
    headers[0].push({
        items: [
            {
                name: "Grand Total",
                deep: 0,
                id: "Grand Total",
            },
        ],
    });
};

const generateRowSpanNumber = (headers) => {
    let maxDeep = headers.length;

    for (let i = 0; i < headers.length; i++) {
        let header = headers[i];
        for (let group of header) {
            for (let item of group.items) {
                const haveChild = getChildGroup(headers[i + 1], item.id);
                if (haveChild) item.rowSpan = 1;
                else {
                    item.rowSpan = maxDeep - item.deep;
                }
            }
        }
    }
};

const generateColSpanNumber = (headers) => {
    for (let i = headers.length - 1; i > -1; i--) {
        let header = headers[i];
        if (i == headers.length - 1) {
            for (let group of header) {
                for (let item of group.items) {
                    item.colSpan = 1;
                }
            }
        } else {
            for (let group of header) {
                for (let item of group.items) {
                    item.colSpan = findChildRelated(headers, i + 1, item.id);
                }
            }
        }
    }
};

export const pivotTableHelper = {
    filterData: (data, cIDS) => {
        let ret = [];
        const remappingColumnHeaders = (col) => {
            let currentIDs = [];
            for (let i = 0; i < col.headers.length; i++) {
                let header = col.headers[i];
                currentIDs.push(header.groupIndex);
                let id = currentIDs.join(headerDelim);
                if (cIDS.indexOf(id) > -1) {
                    return col.headers.slice(0, i + 1);
                }
            }

            return col.headers;
        };

        for (let col of data) {
            ret.push({
                ...col,
                headers: remappingColumnHeaders(col),
            });
        }

        return ret;
    },
    buildTableHeader: (cols, aggregates, showGrandTotal) => {
        let headers = headerMapping.mapHeader(cols, false);
        if (showGrandTotal) {
            addGrandTotalColumn(headers);
        }

        generateRowSpanNumber(headers);
        headers = headers.concat([aggregatesMapping.attachAggregates(headers, aggregates, showGrandTotal)]);
        generateColSpanNumber(headers);
        return headers;
    },
    buildTableLeftSideRow: (rows) => {
        let ret = [];
        let headers = headerMapping.mapHeader(rows, true);

        const groupChild = (index, ret, header, parentID) => {
            let matchGroups;

            if (parentID) {
                matchGroups = header.filter((g) => g.parentID == parentID);
            } else {
                matchGroups = header;
            }

            for (let group of matchGroups) {
                let sortedItems = sort(group.items, (item) => item.groupIndex);
                for (let item of sortedItems) {
                    ret.push({
                        ...item,
                        deep: index,
                        latestChild: !headers[index + 1],
                    });

                    if (headers[index + 1]) {
                        groupChild(index + 1, ret, headers[index + 1], item.id);
                    }
                }
            }
        };

        groupChild(0, ret, headers[0]);

        return ret;
    },
};
