import {cs} from "@common/react/chain-services";
import {DragDropContext} from "react-beautiful-dnd";
import {UseState} from "@common/react/use-state";
import {defaultAggregationFunctions, defaultDateProperties, getColumnType, getDefaultNumericProperties} from "../select-field/select-field";
import * as React from "react";
import {
    getPivotTableSortingAfterMoveValueToAnotherSection,
    getPivotTableSortingAfterReorderValueUpdated,
} from "../limit-sorting/sorting/pivot-table-sorting/get-pivotable-sorting-updated";
import {consumeContext} from "@common/react/context";

export const MultipleFieldGroup = ({group, allowDragToAnotherGroup, next, tile}) =>
    cs(
        consumeContext("getFieldInfo"),
        [
            "placeholder",
            (_, next) =>
                UseState({
                    next,
                }),
        ],
        [
            "hoveringGroup",
            (_, next) =>
                UseState({
                    next,
                }),
        ],
        [
            "dragging",
            (_, next) =>
                UseState({
                    next,
                }),
        ],
        ({placeholder, hoveringGroup, dragging, getFieldInfo}) =>
            next({
                render: () => {
                    const queryAttr = "data-rbd-drag-handle-draggable-id";

                    const getDraggedDom = (draggableId) => {
                        const domQuery = `[${queryAttr}='${draggableId}']`;
                        return document.querySelector(domQuery);
                    };

                    const handleDragStart = (event) => {
                        const draggedDOM = getDraggedDom(event.draggableId);

                        if (!draggedDOM) {
                            return;
                        }

                        const {clientHeight, clientWidth} = draggedDOM;
                        const sourceIndex = event.source.index;
                        let clientY =
                            parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
                            [...draggedDOM.parentNode.children].slice(0, sourceIndex).reduce((total, curr) => {
                                const style = curr.currentStyle || window.getComputedStyle(curr);
                                const marginBottom = parseFloat(style.marginBottom);
                                return total + curr.clientHeight + marginBottom;
                            }, 0);

                        placeholder.onChange({
                            clientHeight,
                            clientWidth,
                            clientY,
                            clientX: parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingLeft),
                        });
                    };

                    const handleDragUpdate = (event) => {
                        if (!event.destination) {
                            return;
                        }

                        const draggedDOM = getDraggedDom(event.draggableId);

                        if (!draggedDOM) {
                            return;
                        }

                        const {clientHeight, clientWidth} = draggedDOM;
                        const destinationIndex = event.destination.index;
                        const sourceIndex = event.source.index;

                        const childrenArray = [...draggedDOM.parentNode.children];
                        const movedItem = childrenArray[sourceIndex];
                        childrenArray.splice(sourceIndex, 1);

                        const updatedArray = [...childrenArray.slice(0, destinationIndex), movedItem, ...childrenArray.slice(destinationIndex + 1)];

                        let clientY =
                            parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
                            updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
                                const style = curr.currentStyle || window.getComputedStyle(curr);
                                const marginBottom = parseFloat(style.marginBottom);
                                return total + curr.clientHeight + marginBottom;
                            }, 0);

                        placeholder.onChange({
                            clientHeight,
                            clientWidth,
                            clientY,
                            clientX: parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingLeft),
                        });
                    };

                    const onDragEnd = (result) => {
                        placeholder.onChange(null);
                        dragging.onChange(false);
                        const {destination, source, draggableId} = result;

                        if (!destination) {
                            hoveringGroup.onChange(null);
                            return;
                        }

                        hoveringGroup.onChange(draggableId);

                        if (destination.droppableId === source.droppableId && destination.index === source.index) {
                            return;
                        }

                        const start = tile.value[source.droppableId];
                        const finish = tile.value[destination.droppableId];

                        if (start === finish) {
                            const newFields = Array.from(start);

                            let newField = start.find((s) => s.id == draggableId);

                            newFields.splice(source.index, 1);
                            newFields.splice(destination.index, 0, newField);

                            const updated = {
                                ...tile.value,
                                [source.droppableId]: newFields,
                            };

                            tile.onChange({
                                ...updated,
                                ...(destination.droppableId == "valueFields" && tile.value.$type == "PivotTableTile"
                                    ? getPivotTableSortingAfterReorderValueUpdated(tile.value, updated)
                                    : {}),
                            });

                            return;
                        }

                        // Moving from one list to another
                        const startFields = Array.from(start);
                        startFields.splice(source.index, 1);

                        const newStart = [...startFields];

                        const finishedFields = Array.from(finish);

                        let newField = start.find((s) => s.id == draggableId);

                        if (destination.droppableId == "valueFields") {
                            newField.aggregateSortIndex = null;
                        }

                        const column = getFieldInfo(newField);
                        const columnType = getColumnType(column);

                        const defaultAgg = {
                            PivotCategoryTileField: {
                                ...(columnType === "date" && {
                                    dateProperties: defaultDateProperties,
                                }),
                            },
                            MeasureTileField: {
                                aggregationFunc: defaultAggregationFunctions[columnType],
                                numericProperties: getDefaultNumericProperties(columnType),
                            },
                        };

                        newField = {
                            ...newField,
                            ...defaultAgg[destination.droppableId == "valueFields" ? "MeasureTileField" : "PivotCategoryTileField"],
                        };

                        finishedFields.splice(destination.index, 0, newField);
                        const newFinish = [...finishedFields];
                        let updated = {
                            ...tile.value,
                            [source.droppableId]: newStart,
                            [destination.droppableId]: newFinish,
                        };

                        tile.onChange({
                            ...updated,
                            ...(source.droppableId == "valueFields" && tile.value.$type == "PivotTableTile"
                                ? getPivotTableSortingAfterMoveValueToAnotherSection(updated, source.index)
                                : {}),
                            ...(destination.droppableId == "valueFields" && tile.value.$type == "PivotTableTile"
                                ? getPivotTableSortingAfterReorderValueUpdated(tile.value, updated)
                                : {}),
                        });
                    };

                    return (
                        <DragDropContext
                            onBeforeDragStart={() => {
                                dragging.onChange(true);
                            }}
                            onDragStart={handleDragStart}
                            onDragUpdate={handleDragUpdate}
                            onDragEnd={onDragEnd}
                        >
                            {group.map((groupField) => groupField.render(placeholder, allowDragToAnotherGroup, hoveringGroup, dragging.value))}
                        </DragDropContext>
                    );
                },
            })
    );
