import "./filter-preview.scss";

import {cx} from "emotion";
import * as React from "react";

import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {filterTypes} from "@common/ui-components/live/live-dashboard/live-filters/filter-types/filter-types";
import {initFilterState} from "@common/ui-components/live/live-dashboard/data/filter-forms/init-filter-state/init-filter-state";
import {InfoIconTooltip} from "@common/ui-components/charts/common/info-icon-tooltip/info-icon-tooltip";
import {Load} from "@common/react/load";
import {LoadingIndicator} from "@common/ui-components/loading-indicator/loading-indicator";
import {consumeContext, provideContext} from "@common/react/context";
import {keyed} from "@common/react/keyed";
import {keepOnly} from "@common/utils/objects";
import {Form2} from "@common/react/cs-form/form2";
import {createArray} from "@common/utils/collections";
import {initTheme} from "../../../../../theme/edit/common/theme-init";

export const FilterPreview = ({filter, collection, form}) =>
    cs(
        (_, next) => (
            <div className="filter-preview-yh2">
                <div className="label">Preview</div>
                {next()}
            </div>
        ),

        consumeContext("apis"),
        [
            "theme",
            ({apis}, next) => {
                const themeId = collection?.themeID;
                if (collection?.$type === "ApiCollection" || !themeId) {
                    return next(initTheme({}));
                }
                return Load({
                    _key: themeId,
                    fetch: () => themeId && apis.collection.getTheme(themeId),
                    next,
                });
            },
        ],

        ({theme}, next) => (!collection || !filter || !theme ? LoadingIndicator() : <div className="presentation">{next()}</div>),
        ({theme}, next) => provideContext("theme", theme, next),

        (_, next) => {
            return cs(keyed(JSON.stringify([keepOnly(filter, filterRefreshKeys[filter.$type]), {isValid: form.validData}])), ({}) =>
                form.validData ? (
                    next()
                ) : (
                    <div className="invalid-warning">
                        <img src={require("../../icons/invalid-warning-icon.svg")} alt={""} />
                        <div className="messages">
                            <div className="line-1">Can't render preview</div>
                            <div className="line-2">
                                {form.errorField.error.getMessage?.(form.errorField.state.value) || "Invalid value."}
                            </div>
                        </div>
                    </div>
                )
            );
        },

        [
            "mockForm",
            (_, next) =>
                cs(
                    [
                        "state",
                        (_, next) =>
                            UseState({
                                initValue: initFilterState(filter),
                                next,
                            }),
                    ],
                    [
                        "form",
                        ({state}, next) =>
                            Form2({
                                data: state,
                                next,
                            }),
                    ],
                    ({form}) => next(form)
                ),
        ],
        [
            "comp",
            ({mockForm}, next) =>
                filterTypes[filter.$type]({
                    filter,
                    form: mockForm,
                    providedFilterData: getFilterPreviewData(filter),
                    next,
                }),
        ],
        ({theme, comp}) => {
            return (
                <div className={cx("filter", {isHiddenFilter: filter.hidden})}>
                    <div className="label">
                        {filter.label}

                        {InfoIconTooltip({
                            theme,
                            width: 8,
                            color: "#707070",
                            showInfoIcon: () => filter.showLabelInfoIcon && filter?.labelInfoIconText,
                            infoText: filter?.labelInfoIconText,
                        })}
                    </div>

                    {filter.hidden && (
                        <div className="hidden-filter">
                            Hidden Filter
                            <div className="tooltip">This filter will not show when embedded.</div>
                        </div>
                    )}

                    <div className="control">
                        {comp.control({size: "desktop"}).map((line, i) => (
                            <div className="line" key={i}>
                                {line}
                            </div>
                        ))}
                    </div>
                </div>
            );
        }
    );

export const filterRefreshKeys = {
    DateFilter: ["compareEnabled", "compareOnByDefault", "columns", "multiSelectionOption", "defaultOption", "customRange"],
    TextFilter: ["option", "design", "itemsVisible", "defaultOperator", "defaultFreeFormValue", "columns", "multiSelectionOption"],
    NumericFilter: ["design", "defaultOption", "defaultValueOrMin", "defaultMax", "defaultList", "columns", "multiSelectionOption"],
    BooleanFilter: ["design", "defaultValue", "columns", "multiSelectionOption"],
};

export const getFilterPreviewData = (filter) => {
    const cDataArray = (n) => createArray(n).map((_, i) => `Item #${i + 1}`);

    if (filter.$type === "TextFilter") {
        return cDataArray(filter.option !== "SelectableList" ? 5 : filter.itemsVisible || 5);
    }

    if (filter.$type === "NumericFilter" && filter.design === "Slider") {
        return {min: 1, max: 1000};
    }
};
