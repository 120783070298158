export const getAutoMaxValuesInView = ({tile, options, availableSpace}) => {
    const barDisplayType = tile.style.displayType || tile.style.yAxisBarDisplayType;
    return (["Stacked", "HundredPercent"].includes(barDisplayType) ? getAutoMaxValInViewStacked : getAutoMaxValInViewNonStacked)({
        tile,
        options,
        availableSpace,
    });
};

const getAutoMaxValInViewNonStacked = ({tile, options, availableSpace}) => {
    const autoDimensions = {
        Auto: {
            minBarWidth: 7,
            minSpaceBetweenEachBar: 2,
            minSpaceBetweenEachBarGroup: 16,
        },
        AutoDense: {
            minBarWidth: 3,
            minSpaceBetweenEachBar: 1,
            minSpaceBetweenEachBarGroup: 7,
        },
        AutoSpacious: {
            minBarWidth: 15,
            minSpaceBetweenEachBar: 6,
            minSpaceBetweenEachBarGroup: 36,
        },
    }[tile.style.xAxis.maxValuesInView || tile.style.yAxis.maxValuesInView];

    // if a tile is combo chart, series has type to distinguish column series from line series; else series has no type attribute
    const numberOfBarsPerGroup = options.series.filter((s) => !s.isCompare && (!s.type || s.type === "column")).length;

    const getSpaceBetweenEachBarGroup = ({minBarWidth, minSpaceBetweenEachBarGroup}) => (numberOfBarsPerGroup <= 3 ? minSpaceBetweenEachBarGroup : minBarWidth * numberOfBarsPerGroup);

    const getBarGroupWidth = ({minBarWidth, minSpaceBetweenEachBar, minSpaceBetweenEachBarGroup}) => {
        return (
            (minBarWidth + minSpaceBetweenEachBar) * numberOfBarsPerGroup +
            getSpaceBetweenEachBarGroup({
                minBarWidth,
                minSpaceBetweenEachBarGroup,
            })
        );
    };

    return Math.max(Math.floor(availableSpace / getBarGroupWidth(autoDimensions)), 1);
};

const getAutoMaxValInViewStacked = ({tile, options, availableSpace}) => {
    const autoDimensions = {
        Auto: {
            minBarWidth: 7,
            minSpaceBetweenEachBarGroup: 7,
        },
        AutoDense: {
            minBarWidth: 3,
            minSpaceBetweenEachBarGroup: 7,
        },
        AutoSpacious: {
            minBarWidth: 15,
            minSpaceBetweenEachBarGroup: 15,
        },
    }[tile.style.xAxis.maxValuesInView || tile.style.yAxis.maxValuesInView];

    return Math.max(Math.floor(availableSpace / (autoDimensions.minBarWidth + autoDimensions.minSpaceBetweenEachBarGroup)), 1);
};
