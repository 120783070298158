import "./adding-tile.scss";

import * as React from "react";

import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {TextInput} from "@common/form/text-input/text-input";
import {scope} from "@common/react/scope";
import {consumeContext} from "@common/react/context";
import {changePath} from "@common/utils/arr-path";
import {Form2} from "@common/react/cs-form/form2";
import {required} from "@common/react/cs-form/validators/required";
import {ffToTextInput} from "@common/form/ff-to-text-input";
import {last} from "@common/utils/collections";
import {Button} from "@common/form/buttons/button/button";

import {SelectTileType} from "../../../../common/select-tile-type/select-tile-type";
import {LeftPanelClosable} from "../../../../../common/left-panel-closable/left-panel-closable";
import {verticalBarTemplate} from "../../../../common/tile-templates/vertical-bar/vertical-bar-template";
import {tileTypes} from "../../../../common/select-tile-type/tile-types";

export const defaultValue = {
    size: {
        width: 5,
        height: 5,
    },
    tile: verticalBarTemplate,
};
export const tileFormConfig = {
    fields: {
        "tile.title": [required],
    },
};

export const AddingTile = ({collection, updateFunc, next, isCallFromEditContainer = false, containerTileID, panel}) =>
    cs(
        ["state", (_, next) => UseState({next})],
        consumeContext("apis"),
        consumeContext("routing"),
        [
            "form",
            ({state, apis, routing}, next) =>
                Form2({
                    ...tileFormConfig,
                    data: scope(state, ["adding"]),
                    onSubmit: async () => {
                        const newCollection = (() => {
                            let {size, tile} = state.value.adding;

                            if (isCallFromEditContainer) {
                                return changePath(collection.value, ["gridLocations"], (gls) =>
                                    gls.map((gl) => {
                                        if (gl.tile.id != containerTileID) {
                                            return gl;
                                        }

                                        tile.style.title.show = false;

                                        return {
                                            ...gl,
                                            tile: {
                                                ...gl.tile,
                                                tiles: (gl.tile.tiles || []).concat(tile),
                                            },
                                        };
                                    })
                                );
                            }

                            return changePath(collection.value, ["gridLocations"], (gls) => [
                                ...gls,
                                {
                                    colStart: 1,
                                    rowStart: gls.length ? Math.max(...gls.map((gl) => gl.rowStart + gl.rowSpan)) : 1,
                                    colSpan: size.width,
                                    rowSpan: size.height,
                                    tile,
                                },
                            ]);
                        })();

                        const savedCollection = await updateFunc?.(newCollection);

                        if (!isCallFromEditContainer) {
                            const {id, $type} = last(savedCollection.gridLocations).tile;

                            if ($type != "ContainerTile") {
                                routing.goto("edit-tile", {
                                    tileId: id,
                                    tileTab: "fields",
                                });
                            } else {
                                routing.goto("edit-container-tile", {
                                    cTileId: id,
                                });
                            }
                        } else {
                            state.onChange(null);
                        }
                    },
                    next,
                }),
        ],
        ({form, state}) =>
            next({
                show: () => {
                    state.onChange({adding: defaultValue});
                },
                ...(state.value &&
                    (() => {
                        const adding = scope(state, ["adding"]);
                        return {
                            leftPanel: (
                                <LeftPanelClosable
                                    {...{
                                        label: isCallFromEditContainer ? "Tile options" : "ADD NEW TILE",
                                        onClose: () => {
                                            state.onChange(null);
                                        },
                                        content: (
                                            <TileOptions
                                                {...{
                                                    tile: scope(adding, ["tile"]),
                                                    form,
                                                    isCallFromEditContainer,
                                                    panel,
                                                }}
                                            />
                                        ),
                                    }}
                                />
                            ),
                        };
                    })()),
            })
    );

const TileOptions = ({tile, form, isCallFromEditContainer, panel}) =>
    cs(({}) => (
        <div className="adding-tile-left-panel-content-2s9 container-adding-tile">
            {!isCallFromEditContainer && <div className="description">Drag a tile onto the canvas to begin.</div>}

            <div className="select-type">
                {SelectTileType({
                    ...tile,
                    form,
                    panel,
                    usingClick: isCallFromEditContainer,
                    list: isCallFromEditContainer ? tileTypes.filter((t) => t.name != "Container Tile") : tileTypes,
                })}
            </div>
            {isCallFromEditContainer && (
                <>
                    <div className="tile-title">
                        {TextInput({
                            label: "Tile Title",
                            ...ffToTextInput(form.field(["tile", "title"])),
                        })}
                    </div>
                    <Button disabled={!form.looksValid} className="add-tile-btn" onClick={form.submit}>
                        Add tile
                    </Button>
                </>
            )}
        </div>
    ));
