import {cs} from "@common/react/chain-services";
import {tooltipRegistry3} from "../../routes/common/tooltip3/tooltip-registry-3";
import {modalRegistry} from "../../routes/common/modal/modal-registry";
import {dropdownRegistry} from "@common/ui-components/dropdown/dropdown-registry";
import {commonRegistry} from "@common/ui-components/registry/common-registry";
import {toastService} from "../../routes/common/toast2/toast-service";
import {loadApis} from "../loaders/load-apis";
import {loadRouting} from "../loaders/load-routing";
import {CollectionPreviewIframe} from "../../routes/collection/preview/iframe/collection-preview-iframe";
import {loadEnvApis} from "../../routes/common/loaders/load-env-apis";
import {loadTenant} from "./load-tenant";
import * as React from "react";
import {provideContext} from "@common/react/context";
import {sdkStaticUrl} from "../../../../sdk/main/loaders/sdk-static-url";

export const loadPreviewCollectionRoute = () =>
    cs(
        [
            "defaultEnvironment",
            (_, next) => {
                const matches = /envId=(.*)&colId/g.exec(window.location.hash);
                return next(matches[1] ?? null);
            },
        ],
        ({}, next) => tooltipRegistry3({next}),
        ({}, next) => modalRegistry({next}),
        ({}, next) => dropdownRegistry({next}),
        ({}, next) => commonRegistry({next}),
        ({}, next) => toastService({next}),
        (_, next) => provideContext("sdkStaticUrl", sdkStaticUrl(window.location.href), next),
        ({auth}, next) =>
            loadApis({
                next,
                onUnauthenticated: () => {
                    auth.invalidate();
                },
            }),
        [
            "routing",
            ({auth, defaultEnvironment}, next) =>
                defaultEnvironment
                    ? loadRouting({
                          routes: [
                              {
                                  name: "iframe-preview-collection",
                                  path: "/iframe/preview",
                                  query: ["key", "colId", "apiKey"],
                                  route: {
                                      component: CollectionPreviewIframe,
                                  },
                              },
                          ],
                          defaultParams: {
                              envId: defaultEnvironment,
                          },
                          next,
                      })
                    : null,
        ],

        ({routing}, next) => loadEnvApis({envId: routing.params.envId, next}),
        ({routing}) => React.createElement(routing.route.component)
    );
