import * as React from "react";
import {cs} from "../../../react/chain-services";
import {cx} from "emotion";
import "./render-summary.scss";
import {IconDown, IconUnchanged, IconUp} from "../common/icons/icons";

export const rSummary = ({style, theme, row, size, formatter: {valueFormatter, percentFormatter}, next, hideComparison, onClickPoint}) => {
    const showCompare = !hideComparison && style.summaryCompareValueShown;
    const hasActions = !!onClickPoint;
    const value = valueFormatter(row?.value);
    const colors = theme.dataVisualization.dataColorPalettes.kpiColorsRGB;
    const color = colors[style.summaryColorApplication?.index || 0];

    return next(
        style.summaryShown && (
            <div className={cx("kpi-summary-46h verb-table-kpi-summary")} key={"summary"}>
                <div
                    className={cx("summary-wrapper", size?.width <= 6 && "small-summary")}
                    style={{
                        justifyContent: style.actualValuePosition,
                    }}
                >
                    {/*<div*/}
                    {/*    className="title"*/}
                    {/*    style={{color}}*/}
                    {/*>*/}
                    {/*    {style.summaryTitleShown && (row.name || style.summaryTitle)}*/}
                    {/*</div>*/}

                    <div
                        className="value-change"
                        style={{
                            ...(style.actualValuePosition === "Right"
                                ? {
                                      flexDirection: "row-reverse",
                                  }
                                : {}),
                        }}
                    >
                        <div
                            className={cx("value verb-table-kpi-summary-value", {
                                "has-actions": hasActions,
                            })}
                            style={{color}}
                            onClick={(e) =>
                                onClickPoint?.({
                                    x: e.clientX,
                                    y: e.clientY,
                                    noFilter: true,
                                    row: {
                                        ...row,
                                        value,
                                    },
                                    fieldName: row.name,
                                })
                            }
                        >
                            {value}
                        </div>

                        {showCompare && (
                            <div
                                className="change-wrap"
                                style={{
                                    paddingLeft: 12,
                                    paddingRight: style.actualValuePosition === "Right" ? 12 : 0,
                                }}
                            >
                                {row &&
                                    (() => {
                                        if (row.change == null) {
                                            return <div className="change no-padding">{style.summaryCompareValueShown ? "N/A" : ""}</div>;
                                        }

                                        const isGood = (style.summaryPositiveChangeGood && row.change > 0) || (!style.summaryPositiveChangeGood && row.change < 0);
                                        const isUp = row.change > 0;

                                        const isUnchanged = () => {
                                            return (
                                                style.compareValueShown &&
                                                (style.compareValueMethod === "Percent"
                                                    ? percentFormatter(Math.abs(row.change)) === "0%"
                                                    : valueFormatter(Math.abs(row.difference)) === 0)
                                            );
                                        };

                                        const goodColor = theme.dataVisualization.dataColorPalettes.otherColors.conditionalGoodColorRGB || "#18C96E";
                                        const badColor = theme.dataVisualization.dataColorPalettes.otherColors.conditionalBadColorRGB || "#E95A5A";

                                        return (
                                            <div
                                                className={cx("change verb-table-kpi-summary-change")}
                                                style={{
                                                    color: `${isUnchanged() ? "#7e7e7e" : isGood ? goodColor : badColor}`,
                                                }}
                                            >
                                                {style.summaryPreviousPeriodShown && (
                                                    <div
                                                        className="prev verb-table-kpi-summary-prev"
                                                        style={{
                                                            color,
                                                            marginRight: 10,
                                                        }}
                                                    >
                                                        {valueFormatter(row.previousValue) || 0}
                                                        {/* Prev {valueFormatter(row.previousValue)} */}
                                                    </div>
                                                )}

                                                {style.summaryIndicatorShown &&
                                                    style.summaryCompareValueMethod &&
                                                    (isUnchanged()
                                                        ? IconUnchanged({})
                                                        : isUp
                                                        ? IconUp({
                                                              fill: isGood ? goodColor : badColor,
                                                          })
                                                        : IconDown({
                                                              fill: isGood ? goodColor : badColor,
                                                          }))}

                                                {style.summaryCompareValueMethod ? (
                                                    style.summaryCompareValueMethod === "Percent" ? (
                                                        percentFormatter(Math.abs(row.change))
                                                    ) : (
                                                        valueFormatter(Math.abs(row.difference))
                                                    )
                                                ) : (
                                                    <div>&nbsp;</div>
                                                )}
                                            </div>
                                        );
                                    })()}

                                {/*{style.summaryPreviousPeriodShown && (*/}
                                {/*    <div className="prev verb-table-kpi-summary-prev" style={{color}}>*/}
                                {/*        {cs(() => {*/}
                                {/*            const formattedValue = valueFormatter(row.previousValue);*/}
                                {/*            return formattedValue ? `Prev ${formattedValue}` : null*/}
                                {/*        })}*/}
                                {/*        /!* Prev {valueFormatter(row.previousValue)} *!/*/}
                                {/*    </div>*/}
                                {/*)}*/}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    );
};

export const ArrowUpSolid = ({fill}) => (
    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-up" className="arrow-icon" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
        <path
            fill={fill}
            d="M34.9 289.5l-22.2-22.2c-9.4-9.4-9.4-24.6 0-33.9L207 39c9.4-9.4 24.6-9.4 33.9 0l194.3 194.3c9.4 9.4 9.4 24.6 0 33.9L413 289.4c-9.5 9.5-25 9.3-34.3-.4L264 168.6V456c0 13.3-10.7 24-24 24h-32c-13.3 0-24-10.7-24-24V168.6L69.2 289.1c-9.3 9.8-24.8 10-34.3.4z"
        />
    </svg>
);

export const ArrowDownSolid = ({fill}) => (
    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-down" className="arrow-icon" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
        <path
            fill={fill}
            d="M413.1 222.5l22.2 22.2c9.4 9.4 9.4 24.6 0 33.9L241 473c-9.4 9.4-24.6 9.4-33.9 0L12.7 278.6c-9.4-9.4-9.4-24.6 0-33.9l22.2-22.2c9.5-9.5 25-9.3 34.3.4L184 343.4V56c0-13.3 10.7-24 24-24h32c13.3 0 24 10.7 24 24v287.4l114.8-120.5c9.3-9.8 24.8-10 34.3-.4z"
        />
    </svg>
);
