import * as React from "react";
import {cs} from "../../react/chain-services";
import {bindInput} from "../../react/bind-input";
import {UseState} from "../../react/use-state";
import {DebounceCache} from "../../react/debounce-cache";
import "./search-input.scss";
import {cx} from "emotion";
import {OnUnmounted} from "../../react/on-unmounted";
import SearchIcon from "assets/icons/common/SearchIcon";

// variant: primary, secondary
// primary: search icon and clear icon align right
// secondary: search icon align left and clear icon align right

export const SearchInput = ({state, label, placeholder, autoFocus, delay, icon, variant = "primary", className, flushOnBlur = true, ...props}) =>
    cs(["focused", (_, next) => UseState({next})], ({focused}) =>
        cs(
            [
                "debounce",
                (_, next) =>
                    DebounceCache({
                        state,
                        next,
                        delay,
                    }),
            ],
            ({debounce}, next) =>
                OnUnmounted({
                    action: () => debounce.flush(),
                    next,
                }),
            ({debounce}) => (
                <div className={cx("search-input-38d search-input", variant, className)}>
                    {label && <div className="label">{label}</div>}
                    <div className={cx("input-box", {focused: focused.value})}>
                        <input
                            {...{
                                ...bindInput(debounce.state),
                                placeholder,
                                onFocus: () => focused.onChange(true),
                                onBlur: () => {
                                    focused.onChange(false);
                                    if (flushOnBlur) {
                                        debounce.flush();
                                    }
                                },
                                autoFocus,
                                ...props,
                            }}
                        />

                        {debounce.active || debounce.state.value ? (
                            <span className="clear-icon" onMouseDown={(e) => e.preventDefault()} onClick={() => state.onChange(null)}>
                                <i className="fal fa-times" />
                            </span>
                        ) : (
                            // <img
                            //     src={require("../common/close-black-icon.svg")} alt=""
                            //     onMouseDown={(e) => e.preventDefault()}
                            //     onClick={() => state.onChange(null)}
                            // />
                            // icon ?? <i className="fa fa-search"/>
                            SearchIcon({className: "search-icon switch"})
                        )}

                        {SearchIcon({className: "search-icon new"})}
                    </div>
                </div>
            )
        )
    );
