import {cs} from "../../../../../../react/chain-services";
import {consumeContext} from "../../../../../../react/context";
import * as React from "react";
import {ErrorDialog} from "../../../../../../../web-client/src/verb-web/auth/tile-data-api-error-handler/error-dialog/error-dialog";
import {ConfirmDialog} from "../../../../../../../web-client/src/routes/common/confirm-dialog/confirm-dialog";
import "./filter-data-error-handler.scss";

export const FilterDataErrorHandler = ({error}) =>
    cs(
        consumeContext("routing"),
        ["errorDialog", (_, next) => ErrorDialog({next})],
        [
            "confirmDialog",
            (_, next) =>
                ConfirmDialog({
                    next,
                    submitText: "Go To Model",
                    cancelText: "Learn More",
                    title: "Conflicting Relationship Paths",
                }),
        ],
        ({confirmDialog, errorDialog, routing}) => {
            return (
                <div className="filter-data-error-handler-76d">
                    <i
                        className="material-icons warning-icon"
                        onClick={() => {
                            if (error.statusCode === 472) {
                                const [table1, table2] = error.data.Tables[0].map((t) => t);

                                confirmDialog.show({
                                    description: (
                                        <div style={{fontSize: "14px"}}>
                                            There are {error.data.Relationships.length} shortest possible paths between the{" "}
                                            <b>
                                                <u>{table1.name}</u>
                                            </b>{" "}
                                            and{" "}
                                            <b>
                                                <u>{table2.name}</u>
                                            </b>
                                            .
                                            <br />
                                            <br />
                                            To support this tile there can only be 1 shortest path. Please update the model by removing a
                                            relationship or creating a new data view with the required data.
                                        </div>
                                    ),
                                    confirmClick: () => {
                                        routing.goto("edit-model", {
                                            modelId: collection.value.modelID,
                                            tableID: table1.id,
                                            targetTableID: table2.id,
                                        });
                                    },
                                });
                            } else {
                                errorDialog.show({
                                    title: "Unrelated Tables",
                                    content: error.message,
                                });
                            }
                        }}
                    >
                        warning
                    </i>
                </div>
            );
        }
    );
