import React from "react";
import {cs} from "@common/react/chain-services";
import {DialogService} from "../../../../../common/dialog/dialog-service";
import "./copy-authentication-dialog.scss";
import {consumeContext} from "@common/react/context";
import {Button} from "../../../../../../../../common/form/buttons/button/button";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";

export const CopyAuthenticationDialog = ({envAuthType, envAuthOtherType, levelType, next: rootNext}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    render: ({resolve, args: {authConfig, onCopy}}) => ({
                        title: `COPY TO ${envAuthOtherType.toUpperCase()} AUTH`,
                        width: 540,
                        content: next({resolve, authConfig, onCopy}),
                    }),
                    next: rootNext,
                }),
        ],
        consumeContext("routing"),
        ({modal, routing}) => {
            const {resolve, authConfig, onCopy} = modal;
            return (
                <div className="copy-authentication-dialog-9ew">
                    {!authConfig?.resultMapping || Object.keys(authConfig?.resultMapping).length === 0 ? (
                        <>
                            <VerbDialogBodyScrollbar>
                                Sorry, the {envAuthType} Auth method is not configured.
                                <br />
                                <br />
                                To set up {levelType} level security rules the auth method must be configured.
                            </VerbDialogBodyScrollbar>

                            <div className="buttons">
                                <Button btnType="secondary" onClick={resolve}>
                                    Go Back
                                </Button>
                                <Button
                                    onClick={() =>
                                        routing.goto("dashboard", {
                                            tab: "settings",
                                        })
                                    }
                                >
                                    Go To Authentication Settings
                                </Button>
                            </div>
                        </>
                    ) : (
                        <>
                            <VerbDialogBodyScrollbar>
                                Not all properties can be copied to the {envAuthOtherType} Auth {levelType} level security rules because the properties do not match. We will alert
                                you where new selection need to be made.
                                <br />
                                <div className="copy-text">Do you want to copy these {levelType} level rules?</div>
                            </VerbDialogBodyScrollbar>

                            <div className="buttons">
                                <Button btnType="secondary" onClick={resolve}>
                                    Cancel
                                </Button>
                                <Button
                                    onClick={() => {
                                        onCopy();
                                        resolve();
                                    }}
                                >
                                    Copy
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            );
        }
    );
