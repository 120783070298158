import {cs} from "@common/react/chain-services";
import "./invalid-relationships-banner.scss";
import {consumeContext} from "@common/react/context";
import {AlertIcon, CheckIcon} from "@common/ui-components/icons/global-icons";
import * as React from "react";
import {omit} from "@common/utils/objects";
import {generateAnchorsAndRelationships, getPotentiallyInvalidRelationships} from "../relationships/relationships-common";
import {UseMemo} from "@common/react/use-memo";
import {cx} from "emotion";

export const InvalidRelationshipsBanner = ({relationships, tables}) =>
    cs(
        consumeContext("routing"),
        ["data", (_, next) => next(generateAnchorsAndRelationships({relationships, tables}))],
        [
            "invalidInnerJoinRelationships",
            ({data}, next) => {
                return UseMemo({
                    fn: () => getPotentiallyInvalidRelationships({relationships: data.relationshipsTable}),
                    deps: [JSON.stringify(data.relationshipsTable)],
                    next,
                });
            },
        ],
        ({routing, invalidInnerJoinRelationships}) => {
            const {tile, relationships} = JSON.parse(routing.params.invalidRelationships);

            const foundInvalidRelationship = invalidInnerJoinRelationships.find((r) =>
                r.relationships.find((r) => relationships.includes(r.id))
            );

            const isFixed = !foundInvalidRelationship;

            return (
                <div className={cx("invalid-relationships-banner-a44", isFixed && "fixed")}>
                    <div className="alert-title">
                        {isFixed ? <CheckIcon color="#1AC689" /> : <AlertIcon />}

                        <div className="box-title">
                            Error on tile: <span>{tile.title}</span>
                        </div>
                    </div>

                    <div className="alert-description">
                        The relationship path required for this tile includes outer joins between inner joins making it invalid. Please
                        review and update the model relationships.
                    </div>

                    <div className="alert-action">
                        <div
                            className="action-btn"
                            onClick={() =>
                                window.open("https://docs.revivemedia.us/data-access-apis/getting-started/data-model#relationships", "_blank")
                            }
                        >
                            Learn more
                        </div>

                        <div
                            className="action-btn"
                            onClick={() => {
                                routing.goto("edit-model", omit(routing.params, "invalidRelationships"));
                            }}
                        >
                            Close view
                        </div>
                    </div>
                </div>
            );
        }
    );
