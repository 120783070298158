import React from "react";
import {cs} from "@common/react/chain-services";
import {formatDateTimeFromISOStr} from "../../../../../../../../common/logic/date-time/format-date-time-full";
import "./publish-collection.scss";
import {Button} from "../../../../../../../../common/form/buttons/button/button";
import {CollectionPublishBtn} from "../../publish-button/publish-button";

export const PublishCollection = ({data, publishing, onPublish, hidePublishProcess}) =>
    cs(({}) => (
        <div className="publish-collection-8sx">
            {publishing ? (
                <div className="flex">
                    <div className="messages">
                        <div className="title">Publishing...</div>
                    </div>
                    {!hidePublishProcess && <CollectionPublishBtn btnType="border" />}
                </div>
            ) : data.publishedOn ? (
                <div className="flex">
                    <div className="messages">
                        <div className="text">
                            Last published on <strong>{formatDateTimeFromISOStr(data.publishedOn)}</strong>
                        </div>
                        <div>
                            {data.publishedBy ? (
                                <span>
                                    <i className="fal fa-user" /> {data.publishedBy}
                                </span>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                    {!hidePublishProcess && <CollectionPublishBtn btnType="border" />}
                </div>
            ) : (
                <div className="messages">
                    <div className="title">This collection has never been published before</div>
                    <div className="text">In order to share and embed this collection you must first publish the collection.</div>
                    {!hidePublishProcess && CollectionPublishBtn({noTooltip: true})}
                </div>
            )}
        </div>
    ));
