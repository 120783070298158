import "./marker-style.scss";
import {LpLine} from "../../../../../../../../../../common/left-panel/line/lp-line";
import {scope} from "@common/react/scope";
import {YesNoToggle} from "../../../../../../../../../../../../../common/form/toggles/yes-no-toggle";

// TODO luan #4361 color
export const MarkerStyle = ({tile, scatterMarkerColorsConfig}) => ({
    label: "Marker Style",
    render: () => {
        return (
            <div className="marker-style-op3">
                <div className="subsection">
                    <div className="header">Marker Color</div>
                    <div className="content">{scatterMarkerColorsConfig.render()}</div>
                </div>
                <div className="subsection">
                    <div className="header">Marker Style</div>
                    <div className="content">
                        {LpLine({
                            label: "Transparency",
                            control: YesNoToggle({
                                state: scope(tile, ["style", "transparent"]),
                            }),
                        })}
                    </div>
                </div>
            </div>
        );
    },
});
