const {UseState} = require("./use-state");
const {cs} = require("./chain-services");
const {fragments} = require("./fragments");
const {Invoke} = require("./invoke");
const {Load2} = require("@common/react/load2");

const IntervalRequestLoad = ({delay = 30 * 1000, _key, ...props}) =>
    cs(
        ["counter", (_, next) => UseState({initValue: 0, next})],
        ({counter}, next) =>
            fragments(
                Invoke({
                    fn: () => {
                        setInterval(() => counter.change((prev) => prev + 1), delay);
                    },
                }),
                next()
            ),
        ({counter}) =>
            Load2({
                _key: JSON.stringify({
                    _key,
                    counter,
                }),
                isSilentLoading: true,
                ...props,
            })
    );

exports.IntervalRequestLoad = IntervalRequestLoad;
