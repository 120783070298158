import {Columns} from "../table/panels/columns/columns";
import {Rows} from "../table/panels/rows/rows";
import * as React from "react";
import {XAxisDateGapsSelect} from "../common/x-axis-date-gaps-select";
import {scope} from "@common/react/scope";
import {EndUserDataExport} from "../common/other-options/other-options";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {stateToSelect} from "@common/ui-components/form/state-to-select";
import {spc} from "@common/react/state-path-change";

export const PivotTableFormatting = ({tile, form}) => ({
    label: "Formatting",
    render: () => (
        <div className="table-columns-35g">
            <div className="panel">
                {/*{XAxisDateGapsSelect(scope(tile, ["style", "dateGaps"]))}*/}

                {[...tile.value.rowFields, ...tile.value.columnFields].find((f) => f.dataType == "DateTime") &&
                    DropdownSelect({
                        label: "Date Gaps",
                        list: [
                            {value: "None", label: "Show Gaps"},
                            {
                                value: "FillMissingDates",
                                label: "Fill With Zero",
                            },
                        ],
                        ...stateToSelect(scope(tile, ["style", "dateGaps"]), ["value"]),
                        valueToLabel: (v) => v.label,
                    })}

                {DropdownSelect({
                    label: "Missing Value Format",
                    list: [
                        {value: "Dash", label: `"-"`},
                        {value: "NoData", label: `"No Data"`},
                        {value: "Blank", label: "Blank"},
                        {value: "Zero", label: `"0"`},
                        {value: "Null", label: `"Null"`},
                    ],
                    isSelected: (v) => v.value == tile.value.style.missingPivotValueFormat,
                    onChange: (v) =>
                        spc(tile, ["style"], (style) => ({
                            ...style,
                            missingPivotValueFormat: v.value,
                        })),
                    valueToLabel: (v) => `Show ${v.label}`,
                })}
            </div>
        </div>
    ),
});
