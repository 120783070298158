import * as React from "react";
import {SelectFieldComp} from "./select-field";
import {cs} from "@common/react/chain-services";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {UseState} from "@common/react/use-state";
import "./select-field-or-constant-value.scss";
import {cx} from "emotion";

export const SelectFieldOrConstantValueComp = (props) =>
    cs(
        [
            "isConstant",
            (_, next) =>
                UseState({
                    getInitValue: () => {
                        if (props.constantField.value != null) {
                            return true;
                        }
                        if (props.field.value != null) {
                            return false;
                        }
                        return undefined;
                    },
                    next,
                }),
        ],
        ({isConstant}) => {
            const {onChooseValueType, constantFieldSelector} = props;
            return (
                <div className="select-field-or-constant-value-9f2">
                    <div
                        className={cx("type-select-and-constant-value", {
                            constant: isConstant.value === true,
                        })}
                    >
                        {DropdownSelect({
                            label: "Value Type",
                            list: [true, false],
                            valueToLabel: (v) => (v ? "Constant Value" : "Data Field"),
                            onChange: (v) => {
                                isConstant.onChange(v);
                                onChooseValueType?.(v);
                            },
                            isSelected: (v) => v === isConstant.value,
                            placeholder: "Please choose value type.",
                        })}
                        {isConstant.value === true && constantFieldSelector?.()}
                    </div>
                    {isConstant.value === false && SelectFieldComp(props)}
                </div>
            );
        }
    );
