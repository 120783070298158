import React from "react";
import {cx} from "emotion";
import "./map-breadcrumbs.scss";

export const MapBreadcrumbs = ({steps, onChange}) => {
    return (
        <div className="map-breadcrumbs-g65 map-breadcrumbs">
            {steps?.map((step, i) => {
                const active = i === steps.length - 1;
                return (
                    <div className={cx("step", {active})} key={i} onClick={() => !active && onChange(i)}>
                        {i > 0 && <span className="delimiter">/</span>}
                        {step.isGlobal ? "Global" : step.name}
                    </div>
                );
            })}
        </div>
    );
};
