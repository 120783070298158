import "./error-handler.scss";

import {cx} from "emotion";
import * as React from "react";

import {consumeContext} from "@common/react/context";
import {cs} from "@common/react/chain-services";
import {ErrorMessage} from "../../../error-message/error-message";
import {Button} from "@common/form/buttons/button/button";
import {tooltipService4} from "../../../../../../web-client/src/routes/common/tooltip3/tooltip-service-3";
import {Static2} from "@common/react/static-2";
import {fragments} from "@common/react/fragments";
import {UseState} from "@common/react/use-state";
import {UseEffect} from "@common/react/use-effect";

const MessageLine = ({message, size, next: rootNext}) => {
    return cs(
        ["state", (_, next) => UseState({next, initValue: 1})],
        ["messageRef", (_, next) => Static2({next})],
        ({state, messageRef}, next) =>
            UseEffect({
                fn: () => {
                    const heigth = messageRef.get()?.offsetHeight;

                    state.onChange(heigth ? heigth / 14 : 1);
                },
                deps: [JSON.stringify(size)],
                next,
            }),
        ({state, messageRef}) =>
            fragments(
                <div className="message-line-ref" ref={messageRef.set}>
                    {message}
                </div>,
                rootNext(state.value)
            )
    );
};

export const ErrorHandler = ({error, size, tile}) =>
    cs(
        consumeContext("tileDataApiErrorHandler"),
        consumeContext("sdkStaticUrl"),
        consumeContext("theme"),
        ({tileDataApiErrorHandler: errorHandler, sdkStaticUrl}) => {
            const statusCode = error.statusCode || error.StatusCode;

            const configuration = {
                icon: <img src={sdkStaticUrl(require("./data-error-icon2.svg"))} alt={""} />,
                message: [471, 472, 476].includes(statusCode)
                    ? "Sorry, there is an issue with the data model"
                    : statusCode == 473
                    ? error.message
                    : "Sorry, there was an issue loading this data",
                button: errorHandler?.condition(error, tile) && {
                    label: "Learn More",
                    onClick: () => errorHandler?.condition(error, tile) && errorHandler.onClick(error, tile),
                },
            };
            return (
                <div className="error-handler-7dv error-handler">
                    <div className="content">
                        {cs(["messageLine", ({}, next) => MessageLine({size, message: configuration.message, next})], ({messageLine}) =>
                            ErrorMessage({
                                ...responsive(
                                    {
                                        ...configuration,
                                        getIcon: ({iconOnly}) =>
                                            iconOnly
                                                ? TooltipIcon({
                                                      icon: configuration.icon,
                                                      renderTooltip: ({onClose}) => ErrorTileTooltip({...configuration, onClose}),
                                                  })
                                                : configuration.icon,
                                    },
                                    {size, messageLine}
                                ),
                            })
                        )}
                    </div>
                </div>
            );
        }
    );

const ErrorTileTooltip = ({button, message, onClose}) => {
    return cs(consumeContext("theme"), ({theme}) => {
        const themeMode = theme.dataVisualization.toolTipsAndLegends.tooltipTheme.toLowerCase() || "light";

        return (
            <div className="error-tile-tooltip">
                <div className="message">{message}</div>
                {button && (
                    <div className="btn-wrapper">
                        <Button
                            className={cx(themeMode === "light" && "light")}
                            onClick={() => {
                                onClose();
                                button.onClick();
                            }}
                        >
                            {button.label}
                        </Button>
                    </div>
                )}
            </div>
        );
    });
};

const TooltipIcon = ({icon, renderTooltip}) => {
    return cs(
        consumeContext("theme"),
        [
            "tooltip",
            ({theme}, next) => {
                const themeMode = theme.dataVisualization.toolTipsAndLegends.tooltipTheme.toLowerCase() || "light";
                return tooltipService4({
                    direction: "above",
                    className: cx(themeMode === "light" && "err-handle-light"),
                    tooltipContentStyle: {
                        width: 285,
                        padding: 16,
                    },
                    allowTooltipInteraction: true,
                    next,
                });
            },
        ],
        ({tooltip}) => {
            return <span {...tooltip(({close}) => renderTooltip({onClose: close}))}>{icon}</span>;
        }
    );
};

const responsive = ({getIcon, message, button}, {size: {height}, messageLine}) => {
    return messageLine < 3
        ? height >=
          1 + // icon height
              messageLine + // message heigth
              (button ? 1 : 0) // btn height
            ? {
                  icon: getIcon({iconOnly: false}),
                  message,
                  button,
              }
            : {
                  icon: getIcon({iconOnly: true}),
              }
        : {
              icon: getIcon({iconOnly: true}),
          };
};

// const errorContents = {
//     471: {
//         title: "Unrelated Tables",
//         content: (<>
//             <div>
//                 There is no relationship path connecting the data selected.
//             </div>
//             <div>
//                 Please select new data or update the model to create a relationship path.
//             </div>
//         </>)
//     },
//     472: {
//         title: "Conflicting Relationship Paths",
//         content: (<>
//             <div>
//                 There are conflicting relationship paths used for this tile.
//             </div>
//             <div>
//                 Please select new data or update the model to solve the conflict.
//             </div>
//         </>)
//     },
// };
