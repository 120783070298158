import {right} from "../../../../../../../../../../../../../storybook/stories/svg-icons/similar-svg-icons-clean";
import {CONDITIONAL} from "./format-rules/single-color-fortmat-rules/condition-dropdown/condition-dropdown";
import {createDateFormatter} from "@common/ui-components/charts/common/formatters/date-formatter";
import {isBlank} from "@common/utils/strings";
import {isEmpty, isNullOrUndefined} from "@common/utils/objects";

export const isValidConditionalRule = (rule) => {
    if (rule.colorType == "ColorScale") {
        return rule.formatBasedOn;
    } else {
        if (rule.conditions.length == 0) {
            return false;
        }

        for (let condition of rule.conditions) {
            for (let fieldCondition of condition.fieldConditions) {
                if (isEmpty(fieldCondition.field)) {
                    return false;
                }

                if (isNullOrUndefined(fieldCondition.comparisonStaticValue) && isNullOrUndefined(fieldCondition.comparisonField)) {
                    return false;
                }
            }
        }
    }

    return true;
};

export const generateRuleTitle = (rule, allColumns) => {
    if (rule.colorType == "ColorScale") {
        const column = allColumns.find((c) => c.modelColumnID == rule.formatBasedOn.modelColumnID);
        return column.displayName;
    }

    try {
        const firstCondition = rule.conditions[0].fieldConditions[0];
        const column = allColumns.find((c) => c.modelColumnID == firstCondition.field.modelColumnID);
        const value = firstCondition.comparisonField
            ? allColumns.find((c) => c.modelColumnID == firstCondition.comparisonField.modelColumnID)?.displayName
            : ["DateTime", "DateTimeOffset"].indexOf(column.dataType) > -1
            ? createDateFormatter("MMM d, yyyy").format(new Date(firstCondition.comparisonStaticValue))
            : firstCondition.comparisonStaticValue;

        const result = `${column.displayName} ${CONDITIONAL[firstCondition.conditionType]} ${value}`;

        if (rule.conditions.length > 1) {
            return `${result} +${rule.conditions.length - 1}`;
        }

        return result;
    } catch (err) {
        console.log(err);
        return "ERROR";
    }
};
