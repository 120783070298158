import * as React from "react";
import {cs} from "../../react/chain-services";
import {consumeContext} from "../../react/context";

import {createLoadTileData2} from "../live/live-dashboard/data/load-tile-data-2";
import {createLoadTileData} from "../live/live-dashboard/data/load-tile-data";
import {DataboardChildTile} from "../live/live-dashboard/data/dashboard-data";
import {createDownloadTileData} from "../live/live-dashboard/data/download-tile-data";
import {FlapFilters} from "../../../web-client/src/routes/collection/filter/flap-filters/flap-filters-dialog";
import {ChartDraw} from "./chart-draw";

//TODO: check filters array in tile actions
export const renderChildTile = ({tile, size = {width: 8, height: 8}, tileAction, filterVals, isEditTile, sdkDashboard = false, next}) => {
    const loadDataFunc = ["TableTile", "MapTile"].includes(tile.$type) ? createLoadTileData2 : createLoadTileData;
    return cs(
        consumeContext("collection"),
        consumeContext("apis"),
        consumeContext("theme"),
        [
            "data",
            ({collection}, next) =>
                DataboardChildTile({
                    isEditTile,
                    collection: collection
                        ? {
                              ...collection?.value,
                              filters: (collection?.value?.filters ?? []).concat(tileAction?.currentFilters ?? []),
                          }
                        : null,
                    filterVals,
                    next,
                }),
        ],
        // keyed(tile.id),
        ({data, collection, theme, apis}) =>
            next({
                filter: () =>
                    !sdkDashboard &&
                    FlapFilters({
                        collection,
                        forceShowFlap: true,
                        filterForms: data.filterForms,
                        onRunReport: () => {
                            data.unblockChanges();
                        },
                    }),
                render: () =>
                    ChartDraw({
                        tileFilters: data.getTileFilters?.(tile.id),
                        loadData: {
                            key: null,
                            load: loadDataFunc({
                                tileId: tile.id,
                                collectionId: collection?.value?.id,
                                apis,
                            }),
                        },
                        downloadData: createDownloadTileData({
                            tileId: tile.id,
                            collectionId: collection?.value?.id,
                            apis,
                        }),
                        tile,
                        size,
                        theme,
                    }),
            })
    );
};
