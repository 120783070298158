import "./data-labels.scss";

import * as React from "react";

import {scope} from "@common/react/scope";
import {LpLine} from "../../../../../../../../../../common/left-panel/line/lp-line";
import {ShowHideToggle} from "../../../../../../../../../../../../../common/form/toggles/show-hide-toggle";

export const DataLabels = ({tile}) => ({
    label: "Data labels",
    control: ShowHideToggle({
        state: scope(tile, ["style", "dataLabels", "show"]),
    }),
    render: () => (
        <div className="gauge-data-labels-yiu">
            {LpLine({
                label: "Min/Max Labels",
                control: ShowHideToggle({
                    state: scope(tile, ["style", "minMaxLabelsShown"]),
                }),
            })}
            {LpLine({
                label: "Value Labels",
                control: ShowHideToggle({
                    state: scope(tile, ["style", "valueLabelsShown"]),
                }),
            })}
        </div>
    ),
});
