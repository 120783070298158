import {cs} from "@common/react/chain-services";
import {cx} from "emotion";
import "./radio-line-large.scss";

export const RadioLineLarge = ({selected, onClick, label, className, disabled}) => {
    return (
        <div className={cx("radio-line-large-a33", className, {selected})} onClick={(e) => !disabled && onClick(e)}>
            <div className={cx("radio", {selected}, {disabled})} />

            {label && <div className="label">{label}</div>}
        </div>
    );
};
