import {Fragment, createElement as h} from "react";
import * as ReactDOM from "react-dom";
import {TooltipProvider} from "./tooltip-context-3";
import {Static2} from "@common/react/static-2";
import {cs} from "@common/react/chain-services";

export const tooltipRegistry3 = ({next, shadowRoot}) =>
    cs(["domRef", ({}, next) => Static2({next})], ({domRef}) =>
        h(
            Fragment,
            {},
            h(
                TooltipProvider,
                {
                    value: {
                        render: (element) => ReactDOM.createPortal(element, shadowRoot ?? domRef.get()),
                    },
                },
                next()
            ),

            h("div", {className: "tooltip-registry-3", ref: domRef.set})
        )
    );
