import React from "react";

import {cs} from "@common/react/chain-services";
import {ChangePasswordDialog} from "../change-password/change-password-dialog";
import {consumeContext} from "@common/react/context";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";
import {Button} from "@common/form/buttons/button/button";
import {TextInput} from "@common/form/text-input/text-input";
import {SetupMFAModal} from "../setup-mfa-modal/setup-mfa-modal";
import {Badge} from "../../../common/badge/badge";
import {DisableMfaModal} from "../setup-mfa-modal/disable-mfa-modal";
import {UpdateAuthenticatorAppModal} from "../setup-mfa-modal/update-authenticator-app-modal";
import {ConfirmDialog} from "../../../common/confirm-dialog/confirm-dialog";
import {ChangeEmailDialog} from "../change-email/change-email-dialog";

export const PersonalSecurity = () =>
    cs(
        consumeContext("auth"),
        consumeContext("apis"),
        ["mfaModal", (_, next) => SetupMFAModal({next})],
        ["disableMFAModal", (_, next) => DisableMfaModal({next})],
        [
            "confirmDialog",
            (_, next) =>
                ConfirmDialog({
                    next,
                    submitText: "Logout",
                    hideCancel: true,
                    title: "Session Reset",
                }),
        ],

        ["changePasswordDialog", ({auth}, next) => ChangePasswordDialog({next, email: auth.user.email})],
        ["changeEmailDialog", ({auth}, next) => ChangeEmailDialog({next, email: auth.user.email})],
        ({apis, auth, mfaModal, disableMFAModal, confirmDialog, changePasswordDialog, changeEmailDialog}) => {
            const {isMfaEnabled} = auth?.user;
            // const isMfaEnabled = true;

            const doGlobalSignOut = async () => {
                const result = await confirmDialog.show({
                    description: "This will log your account out of all active sessions including this session.",
                });

                if (result) {
                    auth.logout();
                    await apis.user.globalSignOut();
                }
            };

            return (
                <VerbScrollbar className="account-settings-general-88i">
                    <div className="section">
                        <div className="section-header">Account Credentials</div>
                        <div className="section-content">
                            <div className="flex-group">
                                {TextInput({
                                    label: "Email",
                                    readOnly: true,
                                    state: {value: auth.user.email},
                                    icon: {
                                        value: <i className="far fa-pen" />,
                                        click: () => changeEmailDialog.show(),
                                    },
                                })}

                                {TextInput({
                                    label: "Password",
                                    readOnly: true,
                                    state: {value: "Password"},
                                    type: "password",
                                    icon: {
                                        value: <i className="far fa-pen" />,
                                        click: () => changePasswordDialog.show(),
                                    },
                                })}
                            </div>
                        </div>
                    </div>

                    <div className="section has-actions">
                        <div className="section-header">Multi-Factor Authentication</div>
                        <div className="section-subheader">Enhance your account security</div>
                        <div className="actions">
                            {isMfaEnabled ? (
                                <>
                                    {Badge({
                                        size: "lg",
                                        icon: <i className="far fa-check" />,
                                        type: "success",
                                        label: "Enabled",
                                    })}
                                    <Button btnType="border" onClick={() => disableMFAModal.show({})}>
                                        Deactivate
                                    </Button>
                                </>
                            ) : (
                                <Button btnType="primary" onClick={() => mfaModal.show({})}>
                                    Configure
                                </Button>
                            )}
                        </div>
                        <div className="section-content">
                            {isMfaEnabled &&
                                cs(["updateAppModal", (_, next) => UpdateAuthenticatorAppModal({next})], ({updateAppModal}) => (
                                    <>
                                        <div className="sub-section">
                                            <div className="section-header">Authenticator App</div>
                                            <div className="actions">
                                                <Button btnType="primary" onClick={() => updateAppModal.show({})}>
                                                    Update
                                                </Button>
                                            </div>
                                        </div>
                                    </>
                                ))}
                        </div>
                    </div>

                    <div className="section has-actions">
                        <div className="section-header">Session Reset</div>
                        <div className="section-subheader">Log out of all sessions</div>
                        <div className="actions">
                            <Button btnType="primary" onClick={doGlobalSignOut}>
                                Logout
                            </Button>
                        </div>
                        <div className="section-content" />
                    </div>
                </VerbScrollbar>
            );
        }
    );
