import * as React from "react";
import {ThumbnailSelect} from "../../../../../../../../../common/thumbnail-select/thumbnail-select";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import "./display.scss";
import {omit} from "@common/utils/objects";
import {getPath} from "@common/utils/arr-path";
import {CheckboxLine} from "../../../../../../../../../../common/checkbox-line/checkbox-line";
import {scope} from "@common/react/scope";
import {stateToSelect} from "@common/ui-components/form/state-to-select";
import {DebounceNumberInput} from "@common/form/debounce-input/debounce-number-input";
import {ffToNumberInput} from "@common/form/ff-to-number-input";

export const Display = ({tile, form}) => ({
    initExpand: true,
    label: "Display",
    render: () => {
        return (
            <div className="pie-chart-displays-sf3">
                {ThumbnailSelect({
                    list: types,
                    isSelected: (v) => getPath(tile.value, ["$type"]) === v.name,
                    onChange: (v) => tile.onChange(v.change(tile.value)),
                })}

                {/*{DropdownSelect({*/}
                {/*    label: "Data Display",*/}
                {/*    list: [*/}
                {/*        "As entered (unchanged)",*/}
                {/*        "Lowercase", "Sentence Case", "Title Case", "Uppercase"*/}
                {/*    ].map((label) => ({*/}
                {/*        label,*/}
                {/*        value: label.includes("unchanged") ? "None" : label.replace(/ /g, "")*/}
                {/*    })),*/}
                {/*    valueToLabel: (v) => v.label,*/}
                {/*    ...stateToSelect(scope(tile, ["style", "dataTextCaseOptions"]), ["value"]),*/}
                {/*})}*/}

                {CheckboxLine({
                    label: "Auto-round values",
                    state: scope(tile, ["style", "autoRound"]),
                })}

                {DebounceNumberInput({
                    label: "Max Number of Slices",
                    ...ffToNumberInput(form.field(["style", "maxSlices"])),
                })}
            </div>
        );
    },
});

const types = [
    {
        label: "Classic",
        name: "PieChartTile",
        thumbnail: require("./icons/pie-icon.svg"),
        change: (tile) => ({
            ...tile,
            $type: "PieChartTile",
            style: {
                ...omit(tile.style, ["holeDiameter", "centerDisplay", "centerLabel", "centerLabelFontSize"]),
                $type: "PieChartTileStyle",
            },
        }),
    },
    {
        label: "Donut",
        name: "DonutChartTile",
        thumbnail: require("./icons/donut-icon.svg"),
        change: (tile) => ({
            ...tile,
            $type: "DonutChartTile",
            style: {
                ...tile.style,
                $type: "DonutTileStyle",
                holeDiameter: 80,
            },
        }),
    },
];
