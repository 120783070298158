const getChannels = ({tenantId, envId, apiKey, type}) =>
    [
        {
            name: "tileData",
            method: "getTileData",
            responseChannel: "TileDataResult",
            flexAttr: "tileId",
        },
        {
            name: "textFilterValues",
            method: "getTextFilterValues",
            responseChannel: "TextFilterResult",
            flexAttr: "filterId",
        },
        {
            name: "numericFilterRange",
            method: "getNumericFilterRange",
            responseChannel: "NumericFilterResult",
            flexAttr: "filterId",
        },
    ].map(({flexAttr, ...channel}) => ({
        ...channel,
        formatRequestMessage: (requests) => (type === "collection" ? [JSON.stringify(requests), tenantId, envId, apiKey] : [JSON.stringify(requests), apiKey]),
        getResponseMessage: ([collectionId, tileIdOrFilterId, dataJson, success]) => {
            return {
                success: success == null || success,
                message: JSON.parse(dataJson),
            };
        },
        matchResponsePayload: (respPayload, request) => {
            const [collectionId, tileIdOrFilterId] = respPayload;
            return request.collectionId === collectionId && request[flexAttr] === tileIdOrFilterId && true;
        },
    }));
exports.getChannels = getChannels;
