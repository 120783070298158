import "./delete-collection-dialog.scss";

import * as React from "react";

import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {consumeContext} from "@common/react/context";
import {TextInput} from "@common/form/text-input/text-input";
import {Button} from "@common/form/buttons/button/button";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";

import {DialogService} from "../../../../../common/dialog/dialog-service";

// support delete one or many collections
// params: can be one collection or many collections
export const DeleteCollectionDialog = ({onDone, next: rootNext, reloadFolderOnDone = false}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    render: ({resolve, args: {collections}}) => ({
                        title: "Delete Collection",
                        width: 500,
                        content: next({
                            resolve,
                            collections,
                        }),
                    }),
                    next: rootNext,
                }),
        ],
        consumeContext("apis"),
        consumeContext("toast"),
        ["confirmText", (_, next) => UseState({next})],
        ["submitting", (_, next) => UseState({next})],
        ({modal, confirmText, submitting, apis, toast}) => {
            const {collections} = modal;

            const numberOfCollections = collections.length;
            const message =
                numberOfCollections == 1
                    ? `Deleting the "${collections[0].name}" collection cannot be undone.`
                    : `Are you sure you want to delete ${numberOfCollections} collections?`;
            return (
                <form
                    onSubmit={async (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (!confirmText.value || confirmText.value.toLowerCase() !== "delete" || submitting.value) {
                            return;
                        }

                        submitting.onChange(true);
                        await Promise.all(
                            collections.map((c) =>
                                apis.collection.deleteCollection({
                                    colId: c.id,
                                    colName: c.name,
                                })
                            )
                        );
                        const newFolders = reloadFolderOnDone ? await apis.collection.getFolders() : null;
                        onDone?.(newFolders, numberOfCollections == 1 ? collections[0].name : `${numberOfCollections} collections`);

                        toast.show(`${numberOfCollections == 1 ? collections[0].name : `${numberOfCollections} collections`} deleted`);

                        submitting.onChange(false);
                        modal.resolve(true);
                    }}
                >
                    <div className="delete-collection-dialog-57a">
                        <VerbDialogBodyScrollbar>
                            <div className="main-part">
                                <div className="warning">{message}</div>

                                {TextInput({
                                    label: <span className="confirm-input-label-5sj">Please type DELETE to confirm</span>,
                                    state: confirmText,
                                })}
                            </div>
                        </VerbDialogBodyScrollbar>

                        <div className="buttons">
                            <Button type="button" btnType="secondary" onClick={() => modal.resolve()}>
                                Cancel
                            </Button>
                            <Button type="submit" btnType="primary-danger" disabled={!confirmText.value || confirmText.value.toLowerCase() !== "delete" || submitting.value}>
                                Delete
                            </Button>
                        </div>
                    </div>
                </form>
            );
        }
    );
