import * as React from "react";
import {cs} from "@common/react/chain-services";
import {ShowHideToggle} from "../../../../../../../../../../../../../common/form/toggles/show-hide-toggle";
import "./value-format.scss";
import {TextInput} from "../../../../../../../../../../../../../common/form/text-input/text-input";
import {scope} from "@common/react/scope";
import {DebounceCache} from "@common/react/debounce-cache";
import {CheckboxLine} from "../../../../../../../../../../common/checkbox-line/checkbox-line";
import {ComparisonMethodSelect} from "../../../common/comparison-method-select";
import {getPath} from "@common/utils/arr-path";
import {ComparisonDateRange} from "../../../common/other-options/other-options";
import {PositiveChangeValueSelect} from "../../../common/positive-change-value-select";

export const ValueFormat = ({tile}) => ({
    label: "Value Format",
    render: () => (
        <div className="gauge-value-format-rs3">
            <div className="subsection">
                <div className="header">
                    Value Label
                    <div className="control">
                        {ShowHideToggle({
                            state: scope(tile, ["style", "valueLabelShown"]),
                        })}
                    </div>
                </div>
                {getPath(tile.value, ["style", "valueLabelShown"]) && (
                    <div className="content">
                        {cs(
                            [
                                "debounce",
                                (_, next) =>
                                    DebounceCache({
                                        state: scope(tile, ["style", "valueLabel"]),
                                        next,
                                    }),
                            ],
                            ({debounce}) =>
                                TextInput({
                                    label: "Value Label",
                                    state: debounce.state,
                                    onBlur: debounce.flush,
                                })
                        )}
                    </div>
                )}
            </div>

            <div className="subsection">
                <div className="header">
                    Actual Value
                    <div className="control">
                        {ShowHideToggle({
                            state: scope(tile, ["style", "actualValueShown"]),
                        })}
                    </div>
                </div>
                {getPath(tile.value, ["style", "actualValueShown"]) && (
                    <div className="content">
                        {CheckboxLine({
                            label: "Auto Round",
                            state: scope(tile, ["style", "autoRound"]),
                        })}
                    </div>
                )}
            </div>

            <div className="subsection">{ComparisonDateRange({tile})}</div>

            {getPath(tile.value, ["style", "showCompare"]) && (
                <>
                    <div className="subsection">
                        <div className="header">
                            Comparison Gauge
                            <div className="control">
                                {ShowHideToggle({
                                    state: scope(tile, ["style", "comparisonGaugeShown"]),
                                })}
                            </div>
                        </div>
                    </div>

                    <div className="subsection">
                        <div className="header">
                            Previous Period
                            <div className="control">
                                {ShowHideToggle({
                                    state: scope(tile, ["style", "previousPeriodShown"]),
                                })}
                            </div>
                        </div>
                    </div>

                    <div className="subsection">
                        <div className="header">
                            Comparison Value
                            <div className="control">
                                {ShowHideToggle({
                                    state: scope(tile, ["style", "compareValueShown"]),
                                })}
                            </div>
                        </div>
                        {getPath(tile.value, ["style", "compareValueShown"]) && (
                            <div className="content">{ComparisonMethodSelect(scope(tile, ["style", "compareValueMethod"]))}</div>
                        )}
                    </div>

                    <div className="subsection">
                        <div className="header">
                            Comparison Indicator
                            <div className="control">
                                {ShowHideToggle({
                                    state: scope(tile, ["style", "compareIndicatorShown"]),
                                })}
                            </div>
                        </div>
                        {getPath(tile.value, ["style", "compareIndicatorShown"]) && (
                            <div className="content">{PositiveChangeValueSelect(scope(tile, ["style", "positiveChangeGood"]))}</div>
                        )}
                    </div>
                </>
            )}
        </div>
    ),
});
