export const getBoxPlotAutoMaxValuesWidthInView = ({tile, options, availableSpace}) => {
    // let valueInView = tile.style.xAxis.maxValuesInView || tile.style.yAxis.maxValuesInView || "Auto";
    // Auto is fixed b.c we're not display this config
    const autoDimensions = {
        Auto: {
            minBoxWidth: 30,
            minSpaceBetweenEachBox: 6,
            minSpaceBetweenEachBoxGroup: 16,
        },
        AutoDense: {
            minBoxWidth: 20,
            minSpaceBetweenEachBox: 10,
            minSpaceBetweenEachBoxGroup: 7,
        },
        AutoSpacious: {
            minBoxWidth: 40,
            minSpaceBetweenEachBox: 14,
            minSpaceBetweenEachBoxGroup: 36,
        },
    }["Auto"];

    const numberOfBoxes = options.series.filter((s) => !s.isCompare && !s.type)[0].data.length;

    const getBoxesWidth = ({minBoxWidth, minSpaceBetweenEachBox}) => {
        return (minBoxWidth + minSpaceBetweenEachBox) * numberOfBoxes;
    };

    const width = getBoxesWidth(autoDimensions);
    return width >= availableSpace ? width : availableSpace;
};
