import {Expandable} from "../../../../common/expandable/expandable";
import {ColorPalette} from "../../../../common/left-panel/color-palette/color-palette";
import {LpColorLine} from "../../../../common/left-panel/lp-color-line";
import {ffToColorPickerLine} from "../../../../../../../common/form/ff-to-color-picker-line";
import * as React from "react";

export const DataColors = ({form, sectionInitClosed}) => (
    <Expandable
        {...{
            initExpand: !sectionInitClosed,
            label: "Data Colors",
            render: () => (
                <div className="panel-33">
                    <div className="panel-22">
                        {ColorPalette({
                            state: form.field(["dataColorPalette"]).state,
                            addMore: true,
                        })}

                        {LpColorLine({
                            label: "Empty Fill Color",
                            ...ffToColorPickerLine(form.field(["emptyFillColorRGB"])),
                        })}
                    </div>

                    <div className="header">
                        Conditional Colors
                        <div className="info">
                            <i className="material-icons">info</i>
                            <div className="info-box">
                                When a comparison value, indicator arrow or conditional option is selected these colors will be applied. You will have the option to select whether
                                positive values are good or bad to control which color is applied.{" "}
                            </div>
                        </div>
                    </div>

                    <div className="content">
                        {LpColorLine({
                            label: "Good Color",
                            ...ffToColorPickerLine(form.field(["valueFontColorGoodRGB"])),
                        })}

                        {LpColorLine({
                            label: "Bad Color",
                            ...ffToColorPickerLine(form.field(["valueFontColorBadRGB"])),
                        })}
                    </div>
                </div>
            ),
        }}
    />
);
