import * as React from "react";
import {LpLine} from "./line/lp-line";
import {ColorPicker} from "../color-picker/color-picker";
import {ShowHideToggle} from "../../../../../common/form/toggles/show-hide-toggle";

export const LpShowHideLine = ({label, state, hasError, domRef, info}) =>
    LpLine({
        info,
        label,
        domRef,
        hasError,
        control: <ShowHideToggle state={state} />,
    });
