const {getInnerRadius} = require("./gauge-helpers");
const {getDifference} = require("../../factory/tooltip/shared-tooltip-formatter/get-difference");
const {cs} = require("../../../../react/chain-services");
const {getCurrencyConversion} = require("../../common/currency-conversion-warning/currency-conversion-warning");

const writeCenterLabel = ({chart, tile, style, theme, data, formatters}) => {
    const name = "centerLabel";

    chart[name]?.destroy();

    const yAxis = chart.yAxis[0];
    const center = {
        x: yAxis.center[0] + chart.plotLeft,
        y: yAxis.center[1] + chart.plotTop,
    };
    const innerRadius = style.autoRound ? 0.6 : getInnerRadius(style.tickness) * 0.01;
    const centerWidth = yAxis.center[2] * innerRadius - 30;
    const fontSize = Math.round(yAxis.center[2] * 0.05); // old 0.065
    const lineHeight = Math.round(fontSize * 1.4);

    const css = {
        fontSize: `${fontSize}px`,
        color: theme.dataVisualization.fonts.fontColorRGB || theme.general.canvas.fontColorRGB,
        fontFamily: `"${theme.dataVisualization.fonts.fontFamily || theme.general.canvas.fontFamily}", sans-serif`,
    };

    const valueLine = (() => {
        if (!style.actualValueShown) {
            return;
        }
        const formattedValue = style.autoRound ? formatters.valueRoundFormatter(data.value) : formatters.valueFormatter(data.value);

        const failures = cs(
            [
                "currencyConversion",
                (_, next) =>
                    next(
                        getCurrencyConversion({
                            $type: data.$type,
                            data,
                            tileFields: [tile.valueField],
                        })
                    ),
            ],
            ({currencyConversion}, next) => (currencyConversion?.hasFailures ? next() : ""),
            () => `<span
                class="material-icons-round currency-failures"
                style="color: #FF5959; font-size: 12px"
            >priority_high</span>`
        );

        return `<div style="width: ${centerWidth}px; text-align: center; height: ${lineHeight}px; line-height: ${lineHeight}px;">
                ${formattedValue}
                
                ${failures}
            </div>`;
    })();

    const previousValue = (() => {
        if (!style.showCompare || !style.previousPeriodShown || data.previousRange == null) {
            return;
        }
        const previousValueDisplay = data.previousValue == null ? "N/A" : formatters.valueRoundFormatter(data.previousValue);
        return !(style.compareValueShown || style.compareIndicatorShown) ? `<div style="text-align: center; color: #989898">${previousValueDisplay}</div>` : `<span style="padding-right: 2px; color: #989898">${previousValueDisplay}</span>`;
    })();

    const difference = (() => {
        if (!style.showCompare || !(style.compareValueShown || style.compareIndicatorShown)) {
            return;
        }

        const {diff, percentDiff, diffIcon, stateColor} = getDifference({
            value: data.value,
            compareValue: data.previousValue,
            positiveChangeGood: style.positiveChangeGood,
            theme,
        });

        const formattedDiff = style.compareValueMethod === "Percent" ? formatters.percentFormatter(percentDiff) : formatters.valueRoundFormatter(diff);

        // base for diff svg transform scale ratio: center width = 160, icon width = 18
        const diff1 = `
            <span class="difference" style="color: ${stateColor}; padding-left: ${style.compareIndicatorShown ? (20 * centerWidth) / 160 + 3 : 0}px">
                ${
                    style.compareIndicatorShown
                        ? `<div class="diff-svg" style="transform: translateY(-50%) scale(${centerWidth / 160})">
                        ${diffIcon}
                    </div>`
                        : ""
                }
                ${style.compareValueShown ? formattedDiff : ""}
            </span>
        `;

        return !style.previousPeriodShown || data.previousRange == null ? `<div style="text-align: center">${diff1}</div>` : `<span style="padding-left: 2px;">${diff1}</span>`;
    })();

    const previousValueAndDiffLine = (() => {
        if (!previousValue && !difference) {
            return;
        }

        const pd = previousValue && difference ? `<div style="text-align: center">${previousValue}${difference}</div>` : [previousValue, difference].filter((v) => v).join(" ");

        return `<div style="
                width: ${centerWidth}px; height: ${lineHeight}px; line-height: ${lineHeight}px; 
                font-size: ${fontSize * 0.9}px;"
            >
                ${pd}
            </div>`;
    })();

    const valueLabel = (() => {
        if (!(style.valueLabelShown && style.valueLabel)) {
            return;
        }

        return `<div style="
                font-weight: 500; 
                width: ${centerWidth}px; height: ${lineHeight}px; line-height: ${lineHeight}px; 
                margin: auto; 
                text-align: center; 
                white-space: normal; 
                overflow-wrap: break-word;"
            >
                ${style.valueLabel}
                
            </div>`;
    })();

    const content = `<div class="gauge-center-label-5t3" style="width: ${centerWidth}px;">` + [valueLine, valueLabel, previousValueAndDiffLine].filter((v) => v).join(" ") + `</div>`;
    chart[name] = chart.renderer.text(content, center.x, center.y, true).css(css).hide().add();

    const bbox = chart[name].getBBox();
    chart[name]
        .attr({
            x: center.x - bbox.width / 2,
            y: center.y - (style.arcSize === "Deg180" ? bbox.height / 2 : 0),
        })
        .show();
};
exports.writeCenterLabel = writeCenterLabel;
