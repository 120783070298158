import {cx} from "emotion";
import React from "react";
import "./tabs-header.scss";

export const TabsHeader = ({className, tabs}) => (
    <div className={cx("tabs-header-r2j tabs-header", className)}>
        {tabs.map(
            (tab, i) =>
                tab && (
                    <div
                        {...{
                            className: cx("tab", {active: tab.active}),
                            key: i || tab?.name,
                            onClick: () => tab.onClick(tab),
                            to: tab.to,
                            draggable: false,
                        }}
                    >
                        {tab?.icon} {tab.label}
                    </div>
                )
        )}
    </div>
);
