import * as React from "react";

import {cs} from "@common/react/chain-services";
import {Static2} from "@common/react/static-2";
import {UseState} from "@common/react/use-state";
import {ShowHideToggle} from "../../../../../../../../../../../../../common/form/toggles/show-hide-toggle";

export const isShowYAxisLabel = ({size, tile}) => {
    let count = 0;
    let style = tile.style;

    const doCount = (key) => {
        if (style[key]) {
            if (style[key].$type) count++;
            else {
                count += Object.keys(style[key]).length;
            }
        }
    };

    doCount("yAxis");
    doCount("yAxisBar");
    doCount("yAxisLine");
    const {height, width} = size;

    if (count <= 1) {
        return width >= 4 && height >= 3;
    }
    return width >= 7 && height >= 3;
};

export const isShowYGridStepLabel = ({size}) => {
    const {height, width} = size;
    return width >= 4 && height >= 2;
};

const isHaveMultipleYAxis = (tile) => {
    return (tile.yAxisBarFields?.length || 0) + (tile.yAxisLineFields?.length || 0) > 1;
};

export const YAxisLabel = ({size, tile, state}) =>
    cs(["legendRef", (_, next) => Static2({next})], ["pos", (_, next) => UseState({next})], ({legendRef, pos}) => (
        <div className="header">
            Axis Label
            {isHaveMultipleYAxis(tile.value) && (
                <div
                    className="icon-tooltip-a22"
                    style={{
                        display: "inline",
                    }}
                >
                    <i className="fa fa-mobile-alt" style={{color: "#FF7A59"}} />
                    <div
                        className="tooltip-box-a44 bottom"
                        style={{
                            top: "-87px",
                        }}
                    >
                        When there are multiple Y-Axes set to show, all Y-Axis labels will be hidden when the chart is viewed on a small mobile device.
                    </div>
                </div>
            )}
            <div className="control">
                {isShowYAxisLabel({size, tile: tile.value}) ? (
                    ShowHideToggle({state})
                ) : (
                    <div
                        className="hide-btn-a22"
                        ref={legendRef.set}
                        onMouseEnter={() => {
                            const {top, right} = legendRef.get().getBoundingClientRect();
                            pos.onChange({
                                top: top - 160,
                                left: right - 110,
                            });
                        }}
                        onMouseLeave={() => {
                            pos.onChange(null);
                        }}
                    >
                        Hide
                    </div>
                )}
            </div>
            {pos.value && (
                <div
                    className="tooltip-box-a33 bottom"
                    style={{
                        ...pos.value,
                    }}
                >
                    <b>When there is a single Y-Axis:</b> the chart must be at least 4 squares wide and 3 squares high to show the label.
                    <br />
                    <br />
                    <b>When there are multiple Y-Axes:</b> the chart must be at least 7 squares wide and 3 squares high to show the labels.
                </div>
            )}
        </div>
    ));

export const YGridStepLabel = ({tile, size, state}) =>
    cs(["legendRef", (_, next) => Static2({next})], ["pos", (_, next) => UseState({next})], ({legendRef, pos}) => (
        <div className="header">
            Grid Step Labels
            <div className="control">
                {isShowYGridStepLabel({size}) ? (
                    ShowHideToggle({state})
                ) : (
                    <div
                        className="hide-btn-a22"
                        ref={legendRef.set}
                        onMouseEnter={() => {
                            const {top, right} = legendRef.get().getBoundingClientRect();
                            pos.onChange({
                                top: top - 90,
                                left: right - 110,
                            });
                        }}
                        onMouseLeave={() => {
                            pos.onChange(null);
                        }}
                    >
                        Hide
                    </div>
                )}
            </div>
            {pos.value && (
                <div
                    className="tooltip-box-a33 bottom"
                    style={{
                        ...pos.value,
                    }}
                >
                    Y-Axis Step Labels can only be shown when the chart is at least 4 squares wide and 2 squares high.
                </div>
            )}
        </div>
    ));
