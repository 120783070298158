const {cs} = require("@common/react/chain-services");
const {randomId} = require("@common/utils/random");
const {Static} = require("@common/react/static");
const {Static2} = require("@common/react/static-2");
const {createContext, createElement} = require("react");
const {fragments} = require("@common/react/fragments");
const {Invoke} = require("@common/react/invoke");
const {Watch} = require("@common/react/watch");
const {OnUnmounted} = require("@common/react/on-unmounted");
const {objectToCss} = require("@common/utils/object");

const StyledClass = ({content, next}) =>
    cs(
        ["className", (_, next) => Static({getInitValue: () => "styled-class-" + randomId(), next})],
        ["styleRef", (_, next) => Static2({next})],
        ["parentDom", (_, next) => createElement(Context.Consumer, {children: next})],
        ({parentDom, className, styleRef}) => {
            const getStyleContent = () => objectToCss(content, `.${className}`);

            return fragments(
                Invoke({
                    action: () => {
                        const styleElement = document.createElement("style");
                        styleElement.setAttribute("data-styled-class", "true");
                        styleElement.innerHTML = getStyleContent();
                        styleRef.set(styleElement);
                        (parentDom ?? document.head).appendChild(styleElement);
                    },
                }),
                Watch({
                    value: content,
                    onChanged: () => {
                        styleRef.get().innerHTML = getStyleContent();
                    },
                }),
                OnUnmounted({
                    action: () => {
                        styleRef.get().remove();
                    },
                }),
                next(className)
            );
        }
    );
exports.StyledClass = StyledClass;

const StyledClassParentDom = ({dom, next}) => createElement(Context.Provider, {value: dom}, next());
exports.StyledClassParentDom = StyledClassParentDom;

const Context = createContext(null);
