import React from "react";
import {cx} from "emotion";
import {cs} from "../../../../react/chain-services";
import {keyed} from "../../../../react/keyed";
import "./color-axis.scss";
import {isColorAxisVertical} from "../map-helper";
import {colorAxisDimensions} from "./get-map-legend-room";
import {RevalidateChartService} from "../../render-chart/legend/display/revalidate-chart-service";

export const ColorAxis = ({tile, theme, options, formatters, chartRef, legendRoom}) =>
    cs(
        [
            "chart",
            (_, next) =>
                RevalidateChartService({
                    props: {tile, theme, mapName: options.series[0].mapGeoJson},
                    chartRef,
                    next,
                }),
        ],
        ({chart}) => {
            if (!chart || !legendRoom) {
                return null;
            }

            const isVertical = isColorAxisVertical(tile);
            return (
                <div
                    className={cx("map-color-axis-53d verb-tile-legend")}
                    style={{
                        width: legendRoom.paddingTop || legendRoom.paddingBottom ? "100%" : `${legendRoom.legendWidth}px`,
                        left: legendRoom.paddingLeft ? 0 : "",
                        right: legendRoom.paddingRight ? 0 : "",
                        height: legendRoom.paddingTop || legendRoom.paddingBottom ? `${legendRoom.legendHeight}px` : `${chart.plotHeight}px`,
                        top: legendRoom.paddingBottom ? "" : 0,
                        bottom: legendRoom.paddingBottom ? "0" : "",
                    }}
                >
                    <div className="legend-container">
                        {chart.colorAxis?.map((cla, i) =>
                            cs(keyed(i), ({}) =>
                                rColorAxis({
                                    colorAxis: cla,
                                    isVertical,
                                    options,
                                    formatters,
                                    legendRoom,
                                })
                            )
                        )}
                    </div>
                </div>
            );
        }
    );

const rColorAxis = ({colorAxis, isVertical, options, formatters, legendRoom}) => {
    // dataMax
    // dataMin
    // min
    // max
    // len
    // series [].data
    // stops [] [0, "#fff", c]
    // tickInterval
    // tickPositions
    const {len, stops, tickPositions} = colorAxis;
    const formatter = formatters.measurementFormatters[options.series[0].customStack];

    const renderTicks = () => {
        const tickLabelStyle = {
            fontSize: options.legend.itemStyle.fontSize,
            fontFamily: options.legend.itemStyle.fontFamily,
            position: "absolute",
        };

        // if only on value on the color axis, fake the 0.
        if (tickPositions.length === 1) {
            return (
                <>
                    <div
                        className="tick-label"
                        style={{
                            ...tickLabelStyle,
                            ...(isVertical
                                ? {
                                      top: 0,
                                      right: colorAxisDimensions.thickness + 5,
                                      transform: "translateY(-50%)",
                                  }
                                : {
                                      top: colorAxisDimensions.thickness + 5,
                                      left: `100%`,
                                      transform: "translateX(-50%)",
                                  }),
                        }}
                    >
                        {formatter(tickPositions[0])}
                    </div>
                    <div
                        className="tick-label"
                        style={{
                            ...tickLabelStyle,
                            ...(isVertical
                                ? {
                                      top: `100%`,
                                      right: colorAxisDimensions.thickness + 5,
                                      transform: "translateY(-50%)",
                                  }
                                : {
                                      top: colorAxisDimensions.thickness + 5,
                                      left: 0,
                                      transform: "translateX(-50%)",
                                  }),
                        }}
                    >
                        {formatter(0)}
                    </div>
                </>
            );
        }

        return tickPositions.map((t, i) => (
            <div
                key={i}
                className="tick-label"
                style={{
                    fontSize: options.legend.itemStyle.fontSize,
                    fontFamily: options.legend.itemStyle.fontFamily,

                    position: "absolute",
                    ...(isVertical
                        ? {
                              top: `${(100 / (tickPositions.length - 1)) * (tickPositions.length - 1 - i)}%`,
                              right: colorAxisDimensions.thickness + 5,
                              transform: "translateY(-50%)",
                          }
                        : {
                              top: colorAxisDimensions.thickness + 5,
                              left: `${(100 / (tickPositions.length - 1)) * i}%`,
                              transform: "translateX(-50%)",
                          }),
                }}
            >
                {formatter(t)}
            </div>
        ));
    };

    return (
        <div
            className="map-color-axis"
            style={{
                position: "relative",
                ...(isVertical
                    ? {
                          paddingLeft: legendRoom.maxLabelWidth + colorAxisDimensions.labelDistance,
                      }
                    : {
                          paddingBottom: legendRoom.labelHeight + colorAxisDimensions.labelDistance,
                      }),
            }}
        >
            <div
                className="axis"
                style={{
                    ...(isVertical
                        ? {
                              width: colorAxisDimensions.thickness,
                              height: len,
                              // background: `linear-gradient(to bottom,  ${stops[0][1]}, ${stops[1][1]})`,
                              background: `linear-gradient(to bottom,  ${stops[1][1]}, ${stops[0][1]})`, // to flip the scale's colors
                          }
                        : {
                              width: len,
                              height: colorAxisDimensions.thickness,
                              background: `linear-gradient(to right,  ${stops[0][1]}, ${stops[1][1]})`,
                          }),
                }}
            />

            <div
                className={cx("anchor", {
                    right: isVertical,
                    above: !isVertical,
                })}
            />

            {renderTicks()}
        </div>
    );
};
