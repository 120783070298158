const {sameDateRange} = require("../../../../../../../utils/dates/date-ranges");
const {sort} = require("../../../../../../../utils/collections");
const {chain} = require("../../../../../../../utils/fs");
import {dateDiff, sameDate, tomorrow} from "../../../../../../../utils/dates/date-object";
import {defaultDateRangeModes} from "../../../../common/logic/default-date-range-modes";
import {lastXDays} from "../../../../common/logic/last-x-days";

export const getMode = (mainRange, preferredModeName) => {
    const mode = chain(preferredModeName ? sort(defaultDateRangeModes, (mode) => (mode.name === preferredModeName ? 0 : 1)) : defaultDateRangeModes, (_) =>
        _.find((mode) => {
            return sameDateRange(mode.getMainRange?.(), mainRange);
        })
    );
    if (mode) {
        return mode;
    }
    if (mainRange && sameDate(tomorrow(), mainRange.to)) {
        const count = dateDiff(mainRange.to, mainRange.from);
        return {
            label: `Last ${count} Days`,
            name: `Last${count}Days`,
            ...lastXDays(count),
        };
    }
    return null;
};
