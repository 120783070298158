import * as React from "react";
import {cs} from "@common/react/chain-services";
import "./radio-line.scss";
import {cx} from "emotion";

// scale: lg | md
export const RadioLine = ({selected, onClick, label, content, className, disabled, scale = "lg"}) =>
    cs(({}) => (
        <div className={cx(`radio-line radio-line-6fk ${scale}`, className, {selected}, {disabled}, {hasContent: content})} onClick={(e) => !disabled && onClick(e)}>
            <div className={cx(`radio ${scale}`, {selected}, {disabled})} />

            {label && <div className="label">{label}</div>}
            {content && <div className="content">{content}</div>}
        </div>
    ));
