import React from "react";
import {cs} from "@common/react/chain-services";
import {OutputConnection} from "./output-connection";
import {InputConnection} from "./input-connection";
import {diagramSizes} from "../../common/diagram-utils";
import {isBlank} from "@common/utils/strings";
import {inputStepTypes} from "../../../../common/input-step-types";
import {outputStepTypes} from "../../../../common/output-step-types";
import "./in-out-connection.scss";

const colors = {
    selected: "#002CA6",
    hovered: "#546B81",
};

export const InOutConnection = ({relationships, interactions, selectedConnectionPath, hoverValue, diagram, isTransformationStep, drawingLine, onConnected}) => {
    //TODO: refactor
    const size = diagramSizes(diagram.$type);
    const {width, height} = typeof size === "object" ? size : {width: size, height: size};

    const sourcesSelected = relationships.filter((r) => r.target && r.target === interactions.setting?.data.stepId).map((t) => t.source);
    const isSelected = sourcesSelected.indexOf(diagram.id) > -1 || interactions.setting?.data.stepId === diagram.id;
    const isSelectedPath = () => {
        if (selectedConnectionPath.value) {
            const {target, source} = selectedConnectionPath.value;
            if (target === diagram.id) {
                if (diagram.hasOwnProperty("inputStepID")) {
                    return diagram.inputStepID === source;
                } else {
                    return diagram.inputStep1ID === source || diagram.inputStep2ID === source;
                }
            }
        }
        return false;
    };
    const noInputStep =
        !inputStepTypes.map((t) => t.$type).includes(diagram.$type) &&
        (diagram.inputStepID ? isBlank(diagram.inputStepID) : isBlank(diagram.inputStep1ID) && isBlank(diagram.inputStep2ID));
    const noOutput = noInputStep || outputStepTypes.map((t) => t.$type).includes(diagram.$type);
    const noConnectedItem = !relationships.map((t) => t.source).includes(diagram.id);

    const getInputPointCss = () => {
        if (isSelectedPath()) {
            return {
                stroke: "#002CA6",
                fill: "#fff",
                strokeWidth: 2,
            };
        }

        if (isSelected && !noInputStep) {
            return {
                fill: colors["selected"],
            };
        }

        if (
            relationships
                .filter((r) => r.source && r.source === hoverValue.value)
                .map((r) => r.target)
                .indexOf(diagram.id) > -1
        ) {
            return {
                fill: "#fff",
                stroke: colors["hovered"],
                strokeWidth: 3,
            };
        }

        if (noInputStep) {
            return {
                fill: "#fff",
                stroke: "#8F9EAC",
            };
        }

        return {
            fill: "#8F9EAC",
        };
    };

    const getOutputPointCss = () => {
        if (selectedConnectionPath.value?.source == diagram.id || sourcesSelected.indexOf(diagram.id) > -1) {
            return {
                stroke: "#002CA6",
                fill: "#fff",
                strokeWidth: 2,
            };
        }

        if (hoverValue.value === diagram.id || noConnectedItem) {
            return {
                fill: "#fff",
                stroke: colors["hovered"],
                strokeWidth: 3,
            };
        }

        if (noInputStep) {
            return {
                fill: "#fff",
                stroke: "#8F9EAC",
            };
        }

        return {
            fill: "#8F9EAC",
        };
    };

    return (
        <>
            {!noOutput &&
                OutputConnection({
                    diagram,
                    drawingLine,
                    isTransformationStep,
                    width,
                    height,
                    transform: {
                        x: diagram.position?.x,
                        y: diagram.position?.y,
                    },
                    onSelect: ({x, y}) => {
                        drawingLine.change((dl) => ({
                            ...dl,
                            position: {
                                start: {x, y},
                                end: {x, y},
                            },
                            inputStep: diagram,
                        }));
                    },
                    css: getOutputPointCss(),
                    size: hoverValue.value === diagram.id || noOutput || noConnectedItem ? 4 : 3,
                })}

            {!inputStepTypes.map((t) => t.$type).includes(diagram.$type) &&
                InputConnection({
                    css: getInputPointCss(),
                    isTransformationStep,
                    width,
                    height,
                    transform: {
                        x: diagram.position.x,
                        y: diagram.position.y,
                    },
                    size:
                        relationships
                            .filter((r) => r.source && r.source === hoverValue.value)
                            .map((r) => r.target)
                            .indexOf(diagram.id) > -1 || noOutput
                            ? 5
                            : 4,
                    onConnected: () => {
                        if (!drawingLine.value.inputStep) return;
                        onConnected(drawingLine.value.inputStep, diagram);
                        drawingLine.onChange({
                            position: [],
                            inputStep: null,
                        });
                    },
                })}
        </>
    );
};
