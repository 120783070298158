import React from "react";
import {cx} from "emotion";

import "./tabs.scss";

const defaultTabTypes = ["horizontal", "vertical"];

export const Tabs = ({list, activeTab, className, type = "horizontal"}) => {
    return (
        <div className={cx("tab-y7g", defaultTabTypes.includes(type) && type, className)}>
            {list.map((tab) => (
                <div
                    className={cx("tab-item", {
                        active: tab.name === activeTab.value,
                    })}
                    key={tab.name}
                    onClick={() => activeTab.onChange(tab.name)}
                >
                    {tab.label}
                </div>
            ))}
        </div>
    );
};
