import "./text-manipulation-configuration.scss";

import React from "react";

import {cs} from "@common/react/chain-services";
import {DropdownSelect2} from "@common/ui-components/dropdown-select/dropdown-select2";
import {scope} from "@common/react/scope";
import {UseState} from "@common/react/use-state";
import {CombineTextConfiguration} from "./combine-text/combine-text-configuration";
import {SplitConfiguration} from "./split/split-configuration";
import {ExtractConfiguration} from "./extract/extract-configuration";
import {LeftConfiguration} from "./left/left-configuration";
import {PadConfiguration} from "./pad/pad-configuration";
import {ChangeCaseConfiguration} from "./change-case/change-case-configuration";
import {ExtractDomainConfiguration} from "./extract-domain/extract-domain-configuration";
import {LengthConfiguration} from "./length/length-configuration";
import {TrimConfiguration} from "./trim/trim-configuration";
import {JsonValueConfiguration} from "./json-value/json-value-configuration";
import {ListAggConfiguration} from "./list-agg/list-agg-configuration";
import {ffToDropdown} from "../../../../../../../../../common/form/ff-to-dropdown";
import {keepOnly} from "@common/utils/objects";

export const TextManipulationConfiguration = ({transformation, step, form, model, setting, togglePreview}) =>
    cs(["state", (_, next) => UseState({initValue: {}, next})], ({state}) => {
        const operationConfig = {
            ChangeCase: ChangeCaseConfiguration,
            Combine: CombineTextConfiguration,
            Extract: ExtractConfiguration,
            ExtractDomainFromEmail: ExtractDomainConfiguration,
            Left: LeftConfiguration,
            Length: LengthConfiguration,
            Pad: PadConfiguration,
            Right: LeftConfiguration,
            Split: SplitConfiguration,
            Trim: TrimConfiguration,
            JsonValue: JsonValueConfiguration,
            ListAgg: ListAggConfiguration,
        };

        const inputStep = transformation.value.steps.find((s) => s.id === step.value.inputStepID);
        const $type = step?.value?.textManipulation?.$type;

        return (
            <div className="text-manipulation-configuration-9ew">
                <div className="config-group">
                    <div className="label">Operation</div>

                    {(() => {
                        const dropdownProps = ffToDropdown(form.field(["textManipulation"]));
                        return DropdownSelect2({
                            label: "Type",
                            list: [
                                {value: "ChangeCase", text: "Change Case"},
                                {value: "Combine", text: "Combine Text"},
                                {value: "Extract", text: "Extract (Substring)"},
                                {
                                    value: "ExtractDomainFromEmail",
                                    text: "Extract Domain From Email",
                                },
                                {value: "Left", text: "Left"},
                                {value: "Length", text: "Length"},
                                {value: "Pad", text: "Pad"},
                                {value: "Right", text: "Right"},
                                {value: "Split", text: "Split"},
                                {value: "Trim", text: "Trim"},
                                {
                                    value: "JsonValue",
                                    text: "Extract Value From JSON",
                                },
                                {
                                    value: "ListAgg",
                                    text: "List Aggregate (GROUP_CONCAT)",
                                },
                            ],
                            valueToLabel: (v) => v.text,
                            isSelected: (v) => v.value === dropdownProps.value?.$type,
                            onChange: (v) =>
                                dropdownProps.onChange({
                                    $type: v.value,
                                    overrideColumn: true,
                                }),
                            ...keepOnly(dropdownProps, ["hasError", "errorMessage", "domRef"]),
                        });
                    })()}
                </div>

                {$type &&
                    operationConfig[$type]({
                        textManipulation: scope(step, ["textManipulation"]),
                        step,
                        model,
                        form,
                        state,
                        inputStep,
                    })}
            </div>
        );
    });
