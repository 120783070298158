import React from "react";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {consumeContext} from "@common/react/context";
import {TextInput} from "../../../../../../common/form/text-input/text-input";
import {ffToTextInput} from "../../../../../../common/form/ff-to-text-input";
import {Form2} from "@common/react/cs-form/form2";
import "./outbound-api-form.scss";
import {OutboundApiInputs2} from "./outbound-api-inputs";
import {OutboundAPISteps} from "./steps/outbound-api-steps";
import {outboundApiValidation} from "./outbound-api-validation";
import {outboundAPITransformData} from "./outbound-api-transform-data";
import {ConnectionSettingsLayout} from "../../add/wizard/steps/connection-configuration/connection-settings-layout";
import {DsFolderField} from "../folders-dialog/ds-folder-field";

export const OutboundAPIForm = ({adding, dsApi, connectionStatus, formConfig, data, next}) =>
    cs(
        consumeContext("apis"),
        [
            "form",
            ({apis}, next) =>
                Form2({
                    data: {
                        value: {
                            ...data.value,
                            connectionDetails: {
                                ...(data.value?.connectionDetails ?? {}),
                                inputs: data.value?.connectionDetails?.inputs ?? [],
                                requests: data.value?.connectionDetails?.requests ?? [
                                    {
                                        httpMethod: "Get",
                                        headers: [],
                                        resultMapping: [{ID: new Date().getTime()}],
                                    },
                                ],
                            },
                        },
                        onChange: data.onChange,
                    },
                    fields: {
                        ...formConfig,
                        "connectionDetails.inputs": [],
                        "connectionDetails.requests": [],
                    },
                    next,
                }),
        ],

        ["submittingError", (_, next) => UseState({initValue: false, next})],
        ["isValid", ({form}, next) => next(() => outboundApiValidation.valid(form.data.value))],
        [
            "testConnection",
            ({form, submittingError, isValid}, next) =>
                next(() => {
                    if (!isValid()) {
                        submittingError.onChange(true);
                        return;
                    }

                    const _connectionDetails = form.data.value?.connectionDetails;
                    const databaseConfigData = outboundAPITransformData(data.value.$type, _connectionDetails);

                    return dsApi.testConnection(
                        {
                            submitConnectionDetails: databaseConfigData,
                            connectionDetails: _connectionDetails,
                        },
                        {
                            type: data.value?.type,
                            name: form.data.value?.name,
                        }
                    );
                }),
        ],
        ({form, submittingError, testConnection, isValid}) => {
            const requestsField = form.field("connectionDetails.requests");

            return next({
                valid: form.valid && outboundApiValidation.valid(form.data.value),
                beforeGoNext: testConnection,
                render: () =>
                    ConnectionSettingsLayout({
                        type: data.value.type,
                        adding,
                        connectionStatus,
                        className: "outbound-api-form-8io",
                        controls: {
                            valid: isValid(),
                            onTestConnection: testConnection,
                            onDiscard: () =>
                                form.data.onChange({
                                    ...data.value,
                                    connectionDetails: {
                                        requests: [
                                            {
                                                httpMethod: "Get",
                                                resultMapping: [{ID: new Date().getTime()}],
                                            },
                                        ],
                                    },
                                }),
                            onGoNext: dsApi.onNextStep,
                        },
                        content: (
                            <>
                                {TextInput({
                                    label: "Display Name",
                                    ...ffToTextInput(form.field("name")),
                                })}

                                {DsFolderField({
                                    state: form.field("folderID").state,
                                })}

                                <div className="header">Input</div>

                                {OutboundApiInputs2({
                                    field: form.field("connectionDetails.inputs").state,
                                })}

                                <div className="header">Steps</div>

                                {OutboundAPISteps({
                                    state: requestsField.state,
                                    hasError: submittingError.value,
                                })}

                                <div className="header">Primary Key Field(s)</div>

                                {TextInput({
                                    label: "Primary Key Field(s) - optional",
                                    placeholder: "id,date,other",
                                    ...ffToTextInput(form.field("connectionDetails.idColumns")),
                                })}
                            </>
                        ),
                    }),
            });
        }
    );
