import React from "react";
import {cs} from "@common/react/chain-services";
import {StaticTooltipService} from "../../../../../../../../../../common/tooltip3/static-tooltip-service";
import "./column-settings.scss";

export const ColumnSettings = ({tile, tableFieldConfigOverride}) => ({
    label: "Column Settings",
    iconTooltip: cs(
        [
            "staticTooltip",
            (_, next) =>
                StaticTooltipService({
                    direction: "top",
                    info: "Add tooltips to column names and set conditional formatting rules.",
                    tooltipBoxWidth: 200,
                    next,
                }),
        ],
        ({staticTooltip}) => (
            <div className="column-settings-info-35d">
                {staticTooltip.renderIcon({
                    icon: <i className="material-icons">info</i>,
                    className: "info-tooltip",
                })}
            </div>
        )
    ),
    render: () => (
        <div className="column-settings-a23">
            <div className="subsection">
                <div className="content">{tableFieldConfigOverride.render()}</div>
            </div>
        </div>
    ),
});
