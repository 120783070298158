import {getDataLabelsStyle} from "../../factory/data-labels";

export const getSectionLabelsOptions = ({tile, theme, formatters}) => {
    return {
        enabled: tile.style.dataLabels.show,
        style: getDataLabelsStyle({
            dataLabelsConfig: tile.style.dataLabels,
            theme,
        }).style,
        formatter: function () {
            return this.point.custom.displayName; // ref. decorate-venn-data.js
        },
    };
};
