// const {initTooltipConnectorHorizontalHelper} = require("../tooltip/connector-helpers/tooltip-connector-horizontal-helper");
// const {initTooltipConnectorHelper} = require("../tooltip/connector-helpers/tooltip-connector-helper");
const {dimAxisLabelSeriesTooltip} = require("./dim-axis-label-series-tooltip");
const {getCategories} = require("./get-categories");
const {isDimRealDateTime} = require("../is-dim-real-date-time");
const {getDatetimeExtremes} = require("./get-datetime-extremes");
const {getDatetimeTickInterval} = require("./get-datetime-tick-interval");
// const {getDimAxisLabelAverageLength} = require("./get-dim-axis-label-average-length");
// const {cSyncLinearDimAxis} = require("./sync-dim-axis");
// const {cSyncCategorizedDimAxis} = require("./sync-dim-axis");
// const {cSyncDateDimAxis} = require("./sync-dim-axis");
// const {scrollbarConfig} = require("../../render-chart/render-chart-helper");
// const {getGridStepOption} = require("./grid-step");
const {getFieldType} = require("../../common/get-field-type");
const {getOrientation} = require("../../common/style-map/orientation");
const {cGetFontSize} = require("../../common/style-map/font-size");
const {getLinearTickInterval} = require("./get-linear-tick-interval");

const mapGridStyleToHC = {
    Solid: "Solid",
    Dotted: "Dot",
    Dashed: "Dash",
};

const getDimensionAxisOptions = ({
    tile,
    rawSeries, // series from rawData before being grouped, the order of categories is preserved
    series, // grouped series used for displaying in chart, with correct stacking order and legend order
    theme,
    isCompare,
    dimensionAttr,
    styleDimensionAttr,
    dimensionFormatter,
    // chartElementTooltip,
}) => {
    const dimensionFieldType = getFieldType(tile[dimensionAttr]);
    const {axisCharts} = theme.dataVisualization;

    const dimSortDesc = ["date", "number"].includes(dimensionFieldType) && tile.sort[0]?.modelColumnID === tile[dimensionAttr].modelColumnID && tile.sort[0]?.direction === "Desc";

    const isRealDateTime = isDimRealDateTime({tile, dimensionAttr, series});

    const isRealLinear = dimensionFieldType === "number" && typeof series[0].data[0][0] === "number";

    const style = tile.style[styleDimensionAttr];
    const generalStyle = theme.general;

    const maxValuesInView = style.maxValuesInViewFixed;

    const getFontSize = cGetFontSize(generalStyle.canvas.fontSize, theme);

    const tickNGridOptions = {
        tickLength: axisCharts.dimensionAxisTickMarks ? 10 : 0,
        tickWidth: !!style.gridStepLabelsShown && axisCharts.dimensionAxisTickMarks ? 1 : 0,
        tickColor: axisCharts.axisLineColorRGB,
        lineColor: axisCharts.axisLineColorRGB,
        gridLineColor: axisCharts.gridStepLineColorRGB,
        gridLineDashStyle: mapGridStyleToHC[axisCharts.gridStepLineStyle],
    };

    // const tooltipConnectorHelper = dataVisualizationStyle.toolTipsAndLegends?.indicatorLineShown && (
    //     tile.$type === "HorizontalBarChartTile" ?
    //         initTooltipConnectorHorizontalHelper({tooltipStyle: dataVisualizationStyle.toolTipsAndLegends}) :
    //         initTooltipConnectorHelper({tooltipStyle: dataVisualizationStyle.toolTipsAndLegends})
    // );

    const allItems = series.find((s) => !s.isCompare)?.data?.map((dp) => dp[0]);

    let dimAxisCommon = {
        title: {
            text: style.labelShown ? style.label : undefined,
            style: {
                fontSize: getFontSize({
                    group: theme.dataVisualization.fonts.axisFontSize,
                    elemType: "label",
                }),
                color: theme.dataVisualization.fonts.fontColorRGB || generalStyle.canvas.fontColorRGB,
                fontFamily: `"${theme.dataVisualization.fonts.fontFamily || theme.general.canvas.fontFamily}", sans-serif`,
            },
        },
        showLastLabel: true,
        showFirstLabel: true,

        labels: {
            enabled: !!style.gridStepLabelsShown,
            style: {
                fontSize: getFontSize({
                    group: theme.dataVisualization.fonts.gridStepsFontSize,
                }),
                color: theme.dataVisualization.fonts.fontColorRGB || generalStyle.canvas.fontColorRGB,
                fontFamily: `"${theme.dataVisualization.fonts.fontFamily || theme.general.canvas.fontFamily}", sans-serif`,
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
            },
            ...(() => {
                const orientation = style.gridStepLabelOrientation;

                if (!orientation) {
                    return {};
                }

                return orientation === "Auto"
                    ? {
                          autoRotation: [-45, -50, -55, -60, -65, -70, -75, -80, -85, -90],
                      }
                    : {
                          rotation: getOrientation(orientation),
                      };
            })(),
            formatter: function () {
                // when xAxis is of type array (to do multiple xAxis in compare mode), and with some chart types, for a reason tick pos is index not timestamp
                // need this to map index to correct timestamp
                if (["LineChartTile", "VerticalBarChartTile", "HorizontalBarChartTile", "ComboChartTile"].includes(tile.$type) && isRealDateTime && isCompare) {
                    return dimensionFormatter(allItems[this.value] || this.value);
                }
                return dimensionFormatter(this.value);
            },
            events: {
                mouseover: function (e) {
                    // chartElementTooltip.show(e, this.value, true)

                    // show tooltip and connector line when hovering dim axis labels
                    dimAxisLabelSeriesTooltip.show(e, this);

                    // this is handle as an HC extension now
                    // if (tooltipConnectorHelper) {
                    //     setTimeout(() => tooltipConnectorHelper.render({chart: this.chart}), 0)
                    // }
                },
                mouseout: function (e) {
                    // chartElementTooltip.close()

                    // remove tooltip and connector line when not hovering dim axis labels
                    dimAxisLabelSeriesTooltip.close(this);

                    // this is handle as an HC extension now
                    // if (tooltipConnectorHelper) {
                    //     setTimeout(() => tooltipConnectorHelper.remove(), 0)
                    // }
                },
            },
        },

        // no gridStepType on dimension axis since #1003, default to Auto
        // ...getGridStepOption({
        //     isRealDateTime,
        //     dimensionFieldType,
        //     gridStepType: style.gridStepType,
        //     gridStepNumeric: style.gridStepNumeric,
        //     gridStepDate: style.gridStepDate,
        //     series: series.filter((s) => !s.isCompare),
        // }),

        className: "verb-tile-axis verb-tile-axis-bottom verb-tile-axis-dimension",
        // the vertical line aligned with tooltip
        crosshair: {
            width: 0, // do not show this crosshair, just to get the point coordinate

            // this makes the crosshair moves along mouse cursor
            // snap: false,

            // width: 1,
            // color: "black",
            // zIndex: 3,
            // className: "highcharts-tooltip-crosshair",

            // this is the label under each crosshair
            // label: {
            //     enabled: true,
            //     align: "right"
            // }
        },
        ...tickNGridOptions,
    };
    if (style?.logarithmic) dimAxisCommon.type = "logarithmic"; // Set conditionally, otherwise Highcharts renders text labels as numbers (index)

    const dimAxisBaseCategory = {
        type: "category",

        ...(dimensionFieldType === "date" && dimSortDesc // if dim not sorted by date then follow the sort of data
            ? tile.$type === "HorizontalBarChartTile"
                ? {
                      reversed: false,
                  }
                : {
                      //Comment to fix bug 978: Sort Date as Text Desc Issue
                      // reversed: true,
                  }
            : {reversed: null}),

        ...getCategories({rawSeries, series}),
    };

    const dimAxisBaseLinear = {
        type: "linear",

        // ...(isRealLinear && dimSortDesc && {
        //     reversed: tile.$type === "HorizontalBarChartTile" ? false : true,
        //     minPadding: 0.01,
        //     maxPadding: 0.01,
        // }),

        ...getLinearTickInterval({
            dimensionDataType: tile[dimensionAttr].dataType,
            series,
        }),
    };

    const dimAxisBaseDateTime = {
        type: "datetime",
        startOfWeek: 0,
        // TODO auto x-axis datetime options
        // tickInterval: 24 * 3600 * 1000,
        // minTickInterval: 24 * 3600 * 1000,
        // maxTickInterval: 24 * 3600 * 1000,
        // units: [['day', [1]]],
        // tickPositioner: function(min, max){
        //     let interval = this.options.tickInterval,
        //         ticks = [],
        //         count = 0;
        //
        //     while(min < max) {
        //         ticks.push(min);
        //         min += interval;
        //         count ++;
        //     }
        //
        //     ticks.info = {
        //         unitName: 'day',
        //         count: 1,
        //         higherRanks: {},
        //         totalRange: interval * count
        //     }
        //
        //
        //     return ticks;
        // },
        // startOnTick: true,
        // endOnTick: true,

        ...(isRealDateTime && dimSortDesc
            ? tile.$type === "HorizontalBarChartTile"
                ? {
                      reversed: false,
                  }
                : {
                      reversed: true,
                  }
            : {reversed: null}),

        ...(isRealDateTime && {
            // for HC not to add extra dates on 2 ends of dim axis when scrollablePlotArea is used on a bar and combo chart. AB#4633
            ...(tile.$type !== "LineChartTile" && {
                minPadding: 0,
                maxPadding: 0,
            }),

            // tickPixelInterval: Math.ceil(getDimAxisLabelAverageLength({
            //     series,
            //     dimensionFormatter,
            //     fontSize: getFontSize({group: theme.dataVisualization.fonts.gridStepsFontSize})
            // })),

            // to keep ticks aligned with date aggregation
            // (do not use now, this may not work as expected.
            // when xAxis is defined in array form, if tickInterval is set, tick labels are broken, only first one is shown. need investigate more)
            // tickInterval: getDatetimeTickInterval({series}),
        }),
    };

    // const cSyncAxes = isRealDateTime
    //     ? cSyncDateDimAxis
    //     : dimensionFieldType === "number" ? cSyncLinearDimAxis : cSyncCategorizedDimAxis;
    // const {primaryAxis, compareAxis, syncAxesFn, needScrollBar} = maxValuesInView ? cSyncAxes({
    //     series: series.filter((s) => !s.isCompare),
    //     compareSeries: series.filter((s) => s.isCompare),
    //     maxValuesInView,
    // }) : {};

    // for scrollablePlotArea, the min and max date prevent hc from adding more dates
    const {primaryAxis, compareAxis} = maxValuesInView && isRealDateTime ? getDatetimeExtremes({series, tile}) : {};

    const dimAxisPrimary = {
        // id: "main-dim",
        ...(isRealDateTime && dimAxisBaseDateTime),
        ...(isRealLinear && dimAxisBaseLinear), // watch out the case of number type with limit, does linear must be sort like datetime
        ...(!isRealDateTime && !isRealLinear && dimAxisBaseCategory),

        // ...(!maxValuesInView && {
        //     min: null,
        //     max: null,
        // }),

        // if using scrollPlotArea, no need to set min max
        // ...(primaryAxis && needScrollBar && {
        //     min: primaryAxis.min,
        //     max: primaryAxis.max,
        // }),

        ...(primaryAxis && {
            min: primaryAxis.min,
            max: primaryAxis.max,
        }),

        ...dimAxisCommon,

        lineWidth: style.lineShown ? 1 : 0,
        states: {
            hover: {
                lineWidthPlus: 0,
            },
        },
        gridLineWidth: style.gridLinesShown ? 1 : 0,

        // ...(!maxValuesInView && {
        //     scrollbar: {
        //         enabled: false,
        //     }
        // }),
        // ...(needScrollBar && {
        //     scrollbar: {
        //         enabled: true,
        //         ...scrollbarConfig
        //     },
        // }),

        // ...(!maxValuesInView && {
        //     events: {}
        // }),
        // ...(isCompare && maxValuesInView && {
        //     events: {
        //         // if using scrollPlotArea, no need to call afterSetExtremes
        //         afterSetExtremes: function(e) {
        //             // const {chart} = this;
        //             // const newExtremes = chart.xAxis[0].getExtremes();
        //             // const compareXAxis = syncAxesFn(newExtremes);
        //             // chart.xAxis[1].setExtremes(compareXAxis.newCompareMin, compareXAxis.newCompareMax);
        //
        //             // this event is fired right after the chart is loaded, so need to check e.trigger to see if it is fired deliberately or not
        //             // if (e.trigger != null && chart.comparisonBars) {
        //             //     chart.comparisonBars.remove();
        //             //     chart.comparisonBars.render({chart, animationDisabled: true});
        //             // }
        //         }
        //     }
        // }),
    };

    const dimAxisCompare = {
        // linkedTo: "main-dim",
        ...(isRealDateTime && dimAxisBaseDateTime),
        ...(!isRealDateTime && dimensionFieldType !== "number" && dimAxisBaseCategory),

        // ...(!maxValuesInView && {
        //     min: null,
        //     max: null,
        // }),

        // if using scrollPlotArea, no need to set min max
        // ...(compareAxis && needScrollBar && {
        //     // min: compareAxis.min,
        //     max: compareAxis.max,
        // }),

        ...(compareAxis && {
            min: compareAxis.min,
            max: compareAxis.max,
        }),

        visible: false,
    };

    return isCompare ? [dimAxisPrimary, dimAxisCompare] : dimAxisPrimary;
};
exports.getDimensionAxisOptions = getDimensionAxisOptions;
exports.mapGridStyleToHC = mapGridStyleToHC;
