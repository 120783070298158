import * as React from "react";
import {cs} from "@common/react/chain-services";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {Expandable} from "../../../../../../../../../../../common/expandable/expandable";
import "./date-time-format.scss";
import {timeFormats} from "./time-formats";
import {dateFormats} from "./date-formats";
import {dateAggregations} from "../../configuration/date/date-aggregations";
import {getPath} from "@common/utils/arr-path";
import {stateToSelect} from "@common/ui-components/form/state-to-select";
import {scope} from "@common/react/scope";
import {createDateFormatter} from "@common/ui-components/charts/common/formatters/date-formatter";

export const DateTimeFormat = ({field}) =>
    cs(({}) => {
        const aggregation = getPath(field.value, ["dateProperties", "aggregation"]);
        if (!aggregation) {
            return;
        }
        return aggregation === "None" ? datetimeFormat(field) : dateOrTimeFormat(field);
    });

const dateOrTimeFormat = (field) => {
    const aggregation = getPath(field.value, ["dateProperties", "aggregation"]);
    const isDate = dateAggregations.map((v) => v.value).includes(aggregation);
    const formatsObj = isDate
        ? {
              list: dateFormats,
              label: "Date Format",
          }
        : {
              list: timeFormats,
              label: "Time Format",
          };
    const format = getPath(field.value, ["dateProperties", "format"]);
    const defaultFormat = isDate ? "M/d/yy" : "h:mm tt";
    const formatter = (v) => createDateFormatter(format || defaultFormat).format(new Date(v));

    return (
        <div className="date-or-time-format-3kl">
            <div className="preview">
                <div className="header">Preview</div>
                <div className="content">
                    <div className="format-preview">{formatter(new Date())}</div>
                </div>
            </div>
            <Expandable
                {...{
                    label: formatsObj.label.split(" ")[0],
                    render: () => (
                        <div className="select-format">
                            {DropdownSelect({
                                label: formatsObj.label,
                                list: formatsObj.list,
                                valueToLabel: (v) => v.label,
                                ...stateToSelect(scope(field, ["dateProperties", "format"]), ["format"]),
                            })}
                        </div>
                    ),
                }}
            />
        </div>
    );
};

const datetimeFormat = (field) => {
    const {value, onChange} = scope(field, ["dateProperties", "format"]);
    const formats = value?.split("|") || [];
    const dateFormat = formats[0] || "M/d/yy";
    const timeFormat = formats[1] || "h:mm tt";
    const formatter = (v) => {
        const date = new Date(v);
        return createDateFormatter(`${dateFormat} ${timeFormat}`).format(date);
    };

    const onChangeDateFormat = (df) => onChange(`${df}|${timeFormat}`);
    const onChangeTimeFormat = (tf) => onChange(`${dateFormat}|${tf}`);

    return (
        <div className="date-time-format-8ai">
            <div className="preview">
                <div className="header">Preview</div>
                <div className="content">
                    {/*<div className="date">*/}
                    {/*    {dateFormat && dateFormats.find((df) => df.format === dateFormat).label}*/}
                    {/*</div>*/}
                    {/*<div className="time">*/}
                    {/*    5 PM*/}
                    {/*</div>*/}
                    <div className="format-preview">
                        {/*{formatter("2020-09-20T00:00:00Z")}*/}
                        {formatter(new Date())}
                    </div>
                </div>
            </div>
            <Expandable
                {...{
                    label: "Date",
                    render: () => (
                        <div className="select-format">
                            {DropdownSelect({
                                label: "Date Format",
                                list: dateFormats,
                                valueToLabel: (v) => v.label,
                                isSelected: (v) => v.format === dateFormat,
                                onChange: (v) => onChangeDateFormat(v.format),
                            })}
                        </div>
                    ),
                }}
            />
            <Expandable
                {...{
                    label: "Time",
                    render: () => (
                        <div className="select-format">
                            {DropdownSelect({
                                label: "Time Format",
                                list: timeFormats,
                                valueToLabel: (v) => v.label,
                                isSelected: (v) => v.format === timeFormat,
                                onChange: (v) => onChangeTimeFormat(v.format),
                            })}
                        </div>
                    ),
                }}
            />
        </div>
    );
};
