import {cx} from "emotion";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {scope} from "@common/react/scope";
import {Static2} from "@common/react/static-2";
import {UseState} from "@common/react/use-state";
import {inside} from "@common/utils/rectangles";
import {buildString} from "@common/utils/strings";
import {renderChildTile} from "../../../../../../../common/ui-components/charts/render-child-tile";

import {DraggingNewChildTile} from "./child-modal-tile";

export const ChildSameTile = ({next}) =>
    cs(["draggingState", (_, next) => UseState({next})], ({draggingState}) =>
        next({
            overlay: true,
            startDraggingNewTile: ({tile, pos}) => draggingState.onChange({draggingNew: {tile, pos}}),
            render: ({tile, tileActionId, remoteTile}) => {
                const ti = tile.value.tileActions.findIndex((ta) => ta?.id === tileActionId);
                const childTile = scope(tile, ["tileActions", ti, "tile"]);

                const renderChart = () => {
                    return cs(
                        [
                            "childTileComp",
                            (_, next) => {
                                return childTile?.value?.id
                                    ? renderChildTile({
                                          tile: {
                                              ...remoteTile.value.tileActions[ti].tile,
                                              isEditTile: true,
                                              title: buildString([
                                                  tile.value.tileActions[ti].tileTitleBefore ?? "",
                                                  "{{Value}}",
                                                  tile.value.tileActions[ti].tileTitleAfter ?? "",
                                              ]),
                                          },
                                          next,
                                      })
                                    : next(null);
                            },
                        ],
                        ({childTileComp}) => {
                            return childTileComp.render();
                        }
                    );
                };

                return cs(["staticRef", (_, next) => Static2({next})], ({staticRef}) => {
                    const insideRect = () => {
                        let elem = staticRef.get();
                        if (!elem) return false;
                        return (
                            draggingState.value?.draggingNew &&
                            inside(elem.getBoundingClientRect?.(), draggingState.value?.draggingNew?.pos)
                        );
                    };

                    return (
                        <div className="child-same-tile-99i">
                            {childTile?.value?.id ? (
                                renderChart()
                            ) : (
                                <div
                                    className={cx("tile", {
                                        hovering: insideRect(),
                                    })}
                                    ref={staticRef.set}
                                >
                                    Drag a tile type here to start
                                </div>
                            )}

                            {draggingState.value?.draggingNew &&
                                DraggingNewChildTile({
                                    draggingNew: scope(draggingState, ["draggingNew"]),
                                    onDrop: () => {
                                        const {draggingNew} = draggingState.value;

                                        if (insideRect()) {
                                            childTile.onChange({
                                                ...draggingNew.tile,
                                            });
                                        }
                                        draggingState.onChange(null);
                                    },
                                })}
                        </div>
                    );
                });
            },
        })
    );
