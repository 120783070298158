import "./radio-button.scss";

import * as React from "react";
import {cx} from "emotion";
import {cs} from "@common/react/chain-services";
import {StyledClass} from "@common/react/styled-class";

export const RadioButton = ({color, selected, disabled}) =>
    cs(
        [
            "cssClass",
            ({}, next) =>
                StyledClass({
                    content: {
                        "&.selected": {
                            "border-color": color || "#294661",
                            "&:after": {
                                background: color || "#294661",
                            },
                        },
                    },
                    next,
                }),
        ],
        ({cssClass}) => <div className={cx("radio-button radio-button-35a", {selected}, {disabled}, cssClass)} />
    );
