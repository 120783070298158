import "./sequential-theme.scss";
import React from "react";
import {LpColorLine} from "../../../../../../common/left-panel/lp-color-line";
import {Button} from "@common/form/buttons/button/button";
import {PlusIcon} from "@common/ui-components/icons/global-icons";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {UseState} from "@common/react/use-state";
import {cs} from "@common/react/chain-services";
import {Watch} from "@common/react/watch";

export const SequentialTheme = ({state}) =>
    cs(
        [
            "localState",
            (_, next) =>
                UseState({
                    next,
                    initValue: state.value.map((color) => ({
                        value: color,
                        id: Date.now() + Math.random(),
                    })),
                }),
        ],
        ({localState}) => {
            const reorder = (list, startIndex, endIndex) => {
                const result = Array.from(list);
                const [removed] = result.splice(startIndex, 1);
                result.splice(endIndex, 0, removed);

                return result;
            };

            const onDragEnd = (result) => {
                if (!result.destination) {
                    return;
                }

                const items = reorder(localState.value, result.source.index, result.destination.index);

                localState.onChange(items);
                state.onChange(items.map((v) => v.value));
            };

            return (
                <div className="sequential-theme">
                    <div className="preview-box">
                        Preview:
                        <div
                            className="preview-div"
                            style={{
                                background: `linear-gradient(to right, ${state.value.join(",")})`,
                            }}
                        />
                    </div>

                    {Watch({
                        value: state.value,
                        onChanged: (colors) => {
                            let ret = [];
                            for (let i = 0; i < colors.length; i++) {
                                let color = colors[i];
                                ret.push({
                                    id: localState.value[i].id,
                                    value: color,
                                });
                            }

                            localState.onChange(ret);
                        },
                    })}

                    <div className="colors-box">
                        <div className="color-list">
                            <div className="lines" />

                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable">
                                    {(provided) => (
                                        <div {...provided.droppableProps} ref={provided.innerRef}>
                                            {localState.value.map((color, index) => (
                                                <Draggable key={color.id.toString()} draggableId={color.id.toString()} index={index}>
                                                    {(provided) => (
                                                        <div className="color-item" ref={provided.innerRef} {...provided.draggableProps}>
                                                            <img src={require("./drag-icon.svg")} className="drag-icon" {...provided.dragHandleProps} />

                                                            {LpColorLine({
                                                                label: `Color ${index + 1}`,
                                                                state: {
                                                                    value: color.value,
                                                                    onChange: (v) => {
                                                                        const updated = localState.value.map((c, i) =>
                                                                            i == index
                                                                                ? {
                                                                                      ...c,
                                                                                      value: v,
                                                                                  }
                                                                                : c
                                                                        );
                                                                        localState.onChange(updated);
                                                                        state.onChange(updated.map((c) => c.value));
                                                                    },
                                                                },
                                                                key: index,
                                                                onRemove:
                                                                    localState.value.length > 2
                                                                        ? () => {
                                                                              const updated = localState.value.filter((c, i) => i != index);
                                                                              localState.onChange(updated);
                                                                              state.onChange(updated.map((c) => c.value));
                                                                          }
                                                                        : null,
                                                            })}
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>

                            <Button
                                className="button-item color-item"
                                btnType="border"
                                iconLeft={<PlusIcon />}
                                size="small"
                                onClick={() => {
                                    const updated = localState.value.concat({
                                        value: "#FFFFFF",
                                        id: Date.now(),
                                    });
                                    localState.onChange(updated);
                                    state.onChange(updated.map((c) => c.value));
                                }}
                            >
                                Add Color
                            </Button>
                        </div>
                    </div>
                </div>
            );
        }
    );
