import {Button} from "@common/form/buttons/button/button";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {cSsJson} from "@common/react/ss-json";
import {UseState} from "@common/react/use-state";
import {createDateFormatter} from "@common/ui-components/charts/common/formatters/date-formatter";
import {CollectionDashboardIcon, CollectionSparkIcon} from "@common/ui-components/icons/global-icons";
import {SearchInputBar} from "@common/ui-components/search-input-bar/search-input-bar";
import {last} from "@common/utils/collections";
import {noun} from "@common/utils/plural";
import {isBlank} from "@common/utils/strings";
import {cx} from "emotion";
import React from "react";
import {tileTypes} from "../../../collection/common/select-tile-type/tile-types";
import ViewCollections from "../../../collection/shared-tile/view-collections/view-collections";
import {MultiSelect} from "../../common/multi-select";
import {isParentApiCollection} from "../collections/collection-dashboard-helpers";
import {loadCollectionsDashboardActions} from "../collections/collections-dashboard";
import {loadDataAccessApiKeyActions} from "../settings/data-access-key/data-access-api-key";
import {loadIframeKeyActions} from "../settings/iframe-key/iframe-key";
import {loadSharedTileDashboardActions} from "../shared-tiles/shared-tiles-dashboard";
import {DeleteRecentDialog} from "./delete-dialog/delete-recent-dialog";
import {recentStorage} from "./recent-storage";
import {RecentTable} from "./recent-table";

export const RecentSection = ({next, updateEvents, environment, folders, apiCollections, dashboardCollectionsOverview, iframeKeyFolders, accessApiKeyFolders}) =>
    cs(
        consumeContext("routing"),
        ["keyword", (_, next) => UseState({initValue: cSsJson("SEARCH_VALUE").get()?.recent || "", next})],
        ["dataAccessApiKeyActions", (_, next) => loadDataAccessApiKeyActions({apiCollections, accessApiKeyFolders, next})],
        ["iframeKeyActions", (_, next) => loadIframeKeyActions({iframeKeyFolders, dashboardCollectionsOverview, next})],
        ["collectionsDashboardActions", (_, next) => loadCollectionsDashboardActions({next, updateEvents, environment, folders})],
        ["sharedTileDashboardActions", (_, next) => loadSharedTileDashboardActions({next})],
        ["deleteRecentDialog", (_, next) => DeleteRecentDialog({next, onDone: () => {}})],
        [
            "multiSelectCtrl",
            (
                {
                    collectionsDashboardActions: {moveCollectionDialog, deleteCollectionDialog},
                    dataAccessApiKeyActions: {moveAccessKeyDialog, deleteAccessKeyDialog},
                    iframeKeyActions: {moveIframeKeyDialog, deleteIframeKeyDialog},
                    sharedTileDashboardActions: {moveSharedTileDialog, deleteSharedTileDialog},
                    deleteRecentDialog,
                },
                next
            ) =>
                MultiSelect({
                    next,
                    itemName: "Item",
                    countItem: (selectedItems) => {
                        let count = 0;
                        let ignoreKey = new Set();
                        let visitedAPICollections = [];

                        for (let key in selectedItems) {
                            const item = selectedItems[key];

                            if (ignoreKey.has(key)) {
                                continue;
                            }

                            if (item.type != "ApiCollection") {
                                count++;
                                continue;
                            }

                            if (visitedAPICollections.includes(item.name)) {
                                continue;
                            }

                            count++;
                            visitedAPICollections.push(item.name);
                        }

                        return count;
                    },
                    resolveItemKey: (item) => `${item.type}-${item.item.id}`,
                    actions: [
                        {
                            label: "Move To",
                            isShow: (selectedItems, resolveItemKey) => {
                                let keyGroup = null;
                                for (let key in selectedItems) {
                                    const item = selectedItems[key];
                                    if (keyGroup == null) {
                                        keyGroup = item.type;
                                    } else if (keyGroup != item.type) {
                                        return false;
                                    }
                                }
                                return true;
                            },
                            onClick: async (selectedItems) => {
                                const updateItems = Object.values(selectedItems);
                                const item = updateItems[0];
                                const fn = {
                                    "iframe-key": moveIframeKeyDialog,
                                    "data-access-api-key": moveAccessKeyDialog,
                                    collection: moveCollectionDialog,
                                    "shared-tile": moveSharedTileDialog,
                                }[item.type];

                                return await fn?.show({
                                    isUpdateMany: true,
                                    label: `${updateItems.length} ${noun(updateItems.length, item.type.replaceAll("-", " "))}`,
                                    params: updateItems.map((t) => t.item),
                                });
                            },
                        },
                        {
                            label: "Delete",
                            isShow: () => true,
                            onClick: async (selectedItems) => {
                                const updateItems = Object.values(selectedItems);
                                const getItems = (type) => updateItems.filter((u) => u.type == type).map((u) => u.item);

                                const collections = getItems("collection");
                                const dataAccessApiKeys = getItems("data-access-api-key");
                                const iframeKeys = getItems("iframe-key");
                                const sharedTiles = getItems("shared-tile");

                                await deleteRecentDialog.show({
                                    collections,
                                    dataAccessApiKeys,
                                    iframeKeys,
                                    sharedTiles,
                                });

                                recentStorage.remove(updateItems[0]?.envId, (r) => updateItems.findIndex((u) => u.item.id == r.item.id) == -1);

                                return true;
                            },
                        },
                    ],
                }),
        ],
        ({keyword, dataAccessApiKeyActions, iframeKeyActions, collectionsDashboardActions, sharedTileDashboardActions, routing, multiSelectCtrl}) => {
            const getColumnsDataAccessApiKey = () =>
                cs(() => {
                    const {manageKeyDialog, viewApiKeyDialog, akCM, viewKeyParameters} = dataAccessApiKeyActions;
                    return [
                        {
                            label: "Name",
                            indented: true,
                            format: (key) => {
                                return (
                                    <div className="icon-name-34n dashboard" onClick={() => viewKeyParameters(key, 0)}>
                                        <img className="icon" src={require("./icons/data-access-api-icon.svg")} />
                                        {key.name}
                                    </div>
                                );
                            },
                        },
                        {
                            // label: "Key",
                            format: (key) => (
                                <div
                                    className="api-key-item"
                                    onClick={() =>
                                        viewApiKeyDialog.show({
                                            accessKey: key,
                                        })
                                    }
                                >
                                    <span className="item">
                                        <b>.........................</b>
                                        <div className="tooltip">Reveal key</div>
                                    </span>
                                </div>
                            ),
                        },
                        {
                            // label: "Collections",
                            format: (key) =>
                                key.keys ? null : (
                                    <div className="icon-name-34n dashboard" onClick={() => viewKeyParameters(key, 1)}>
                                        {key.apiCollections?.length} Collections
                                    </div>
                                ),
                        },
                        {
                            format: (c, path) => {
                                return (
                                    <div className={cx("icon-name-34n", "folder")}>
                                        <img
                                            src={c.folderID == null ? require("../collections/icons/folder-line-icon.svg") : require("../collections/icons/folder-icon.svg")}
                                            alt={""}
                                        />
                                        {c.folderID == null ? "Data Access API Keys" : last(path)?.name}
                                    </div>
                                );
                            },
                        },
                        {
                            format: (key) => {
                                return (
                                    <div className="cm">
                                        {akCM.render({
                                            params: {
                                                ak: key,
                                            },
                                            img: <i className="far fa-ellipsis-h" />,
                                        })}
                                    </div>
                                );
                            },
                            className: "models-cm-35v",
                            shy: true,
                            alignRight: true,
                        },
                    ];
                });

            const getColumnsIframeKey = () =>
                cs(() => {
                    const {manageKeyDialog, viewKeyParameters, showUrlsDialog, akCM, moveIframeKeyDialog, deleteIframeKeyDialog} = iframeKeyActions;
                    return [
                        {
                            label: "Name",
                            indented: true,
                            format: (key) => {
                                return (
                                    <div className="icon-name-34n dashboard" onClick={() => viewKeyParameters(key, 0)}>
                                        <img className="icon" src={require("./icons/iframe-key-icon.svg")} />
                                        {key.name}
                                    </div>
                                );
                            },
                        },
                        {
                            label: "URLs",
                            className: "btn-urls",
                            format: (key) =>
                                key.keys ? null : (
                                    <div className="api-key-item">
                                        <Button
                                            btnType="border"
                                            size="small"
                                            onClick={() =>
                                                showUrlsDialog.show({
                                                    accessKey: key,
                                                    collections: dashboardCollectionsOverview.value,
                                                })
                                            }
                                        >
                                            Show URLs
                                        </Button>
                                        {/*<span*/}
                                        {/*    className="item show-urls"*/}
                                        {/*    onClick={}*/}
                                        {/*>*/}
                                        {/*    Show URLs*/}
                                        {/*</span>*/}
                                    </div>
                                ),
                        },
                        {
                            label: "Collections",
                            format: (key) =>
                                key.keys ? null : (
                                    <div className="icon-name-34n dashboard" onClick={() => viewKeyParameters(key, 1)}>
                                        {key.collections?.length} Collections
                                    </div>
                                ),
                        },
                        {
                            format: (c, path) => {
                                return (
                                    <div className={cx("icon-name-34n", "folder")}>
                                        <img
                                            src={c.folderID == null ? require("../collections/icons/folder-line-icon.svg") : require("../collections/icons/folder-icon.svg")}
                                            alt={""}
                                        />
                                        {c.folderID == null ? "iFrame Keys" : last(path)?.name}
                                    </div>
                                );
                            },
                        },
                        {
                            format: (key) => {
                                return (
                                    <div className="cm">
                                        {akCM.render({
                                            params: {
                                                ak: key,
                                            },
                                            img: <i className="far fa-ellipsis-h" />,
                                        })}
                                    </div>
                                );
                            },
                            className: "models-cm-35v",
                            shy: true,
                            alignRight: true,
                        },
                    ];
                });

            const getColumnsCollection = () =>
                cs(() => {
                    const {collectionCM} = collectionsDashboardActions;
                    return [
                        {
                            label: "Name",
                            indented: true,
                            format: (c) => {
                                if (c.type === "DashboardCollection") {
                                    return (
                                        <div className={cx("icon-name-34n", "dashboard")} onClick={() => routing.goto("edit-collection", {colId: c.id})}>
                                            {CollectionDashboardIcon({className: "icon"})}
                                            {/* <img className="icon" src={require("./icons/report-icon.svg")} alt=""/> */}
                                            {c.name}
                                        </div>
                                    );
                                } else if (c.type == "ApiCollection") {
                                    if (isParentApiCollection(c)) {
                                        return (
                                            <div className={cx("icon-name-34n", "dashboard")}>
                                                <img className="icon" src={require("../collections/icons/api-icon.svg")} alt="" />
                                                {c.name}
                                            </div>
                                        );
                                    }

                                    return (
                                        <div
                                            className={cx("icon-name-34n", "dashboard")}
                                            onClick={() =>
                                                routing.goto("edit-collection", {
                                                    colId: c.id,
                                                    collectionTab: "fields",
                                                })
                                            }
                                        >
                                            {<img className="icon" src={require("../collections/icons/api-icon.svg")} alt="" />}
                                            {c.name} |&nbsp;
                                            <span
                                                style={
                                                    {
                                                        // paddingLeft: c.isChild ? "30px" : ""
                                                    }
                                                }
                                            >
                                                Version {c.version}
                                            </span>
                                        </div>
                                    );
                                } else if (c.type == "SparkCollection") {
                                    return (
                                        <div className={cx("icon-name-34n", "dashboard")} onClick={() => routing.goto("edit-collection", {colId: c.id})}>
                                            {CollectionSparkIcon({className: "icon"})}
                                            {c.name}
                                        </div>
                                    );
                                }
                            },
                        },
                        {
                            label: "",
                            format: () => null,
                        },
                        {
                            label: "Last modified on",
                            format: (c, path) => {
                                if (!c.type) return "";
                                if (isParentApiCollection(c)) {
                                    return "";
                                }

                                const remoteFolder = folders.value?.find((f) => f.id === (last(path)?.id ?? null));
                                const remoteCol = (remoteFolder?.collections || [])?.find((rc) => rc.id === c.id);

                                const editedOn = new Date((remoteCol ?? c).versionInfo?.editedOnUtc);

                                return <div className="last-modified-on-23c">{createDateFormatter("MMM d, yyyy @ h:mm tt").format(editedOn)}</div>;
                            },
                        },
                        {
                            format: (c, path) => {
                                return (
                                    <div className={cx("icon-name-34n", "folder")}>
                                        <img
                                            src={
                                                path.length === 0 || last(path)?.id === null
                                                    ? require("../collections/icons/folder-line-icon.svg")
                                                    : require("../collections/icons/folder-icon.svg")
                                            }
                                            alt={""}
                                        />
                                        {path.length === 0 || last(path)?.id === null ? "Collections" : last(path)?.name}
                                    </div>
                                );
                            },
                        },
                        {
                            format: (c) => {
                                return <div className="cm">{collectionCM.render({params: {collection: c}})}</div>;
                            },
                            className: "collection-commands-31m",
                            shy: true,
                        },
                    ];
                });

            const getColumnsSharedTile = () =>
                cs(() => {
                    const {sharedTileCm} = sharedTileDashboardActions;
                    return [
                        {
                            label: "Name",
                            indented: true,
                            format: (t, {expanding}) => {
                                return (
                                    <div
                                        className="shared-tile-name-88u"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            routing.goto("edit-shared-tile", {sharedTileId: t.id});
                                        }}
                                    >
                                        {tileTypes.find((tt) => tt.types.includes(t.type))?.icon}
                                        <span>{t.adminTileName}</span>
                                    </div>
                                );
                            },
                        },
                        {
                            label: "Last Modified By",
                            format: (t) => null,
                        },
                        {
                            label: "Last modified on",
                            format: (t) => {
                                if (!t.versionInfo) return "";

                                const editedOn = new Date(t.versionInfo?.editedOnUtc);

                                return <div className="last-modified-on-23c">{createDateFormatter("MMM d, yyyy @ h:mm tt").format(editedOn)}</div>;
                            },
                        },
                        {
                            format: (c, path) => {
                                return (
                                    <div className={cx("icon-name-34n", "folder")}>
                                        <img
                                            src={c.folderID == null ? require("../collections/icons/folder-line-icon.svg") : require("../collections/icons/folder-icon.svg")}
                                            alt={""}
                                        />
                                        {c.folderID == null ? "Shared Tiles" : last(path)?.name}
                                    </div>
                                );
                            },
                        },
                        {
                            format: (t) => {
                                if (!t.versionInfo) return "";
                                return sharedTileCm.render({params: t});
                            },
                            className: "data-source-cm-12s",
                            shy: true,
                            alignRight: true,
                        },
                    ];
                });

            const filteredList = (list) => (isBlank(keyword.value) ? list : list.filter((t) => t.item.name.toLowerCase().includes(keyword.value.toLowerCase())));

            return next({
                actions: cs(() => <>{SearchInputBar({state: keyword, type: "recent"})}</>),
                content: (
                    <div>
                        {multiSelectCtrl.numSelectedItems > 0 && multiSelectCtrl.render()}
                        {RecentTable({
                            headers: [{label: "Name"}, {label: ""}, {label: ""}, {label: ""}, {label: ""}],
                            getTrClass: (n) => {
                                return multiSelectCtrl.isSelected(n) ? " is-selected-tr" : "";
                            },
                            onClickRow: (item) => {
                                multiSelectCtrl.toggleSelect(item);
                            },
                            columns: (type) => {
                                if (type == "collection") return getColumnsCollection();
                                if (type == "iframe-key") return getColumnsIframeKey();
                                if (type == "shared-tile") return getColumnsSharedTile();
                                return getColumnsDataAccessApiKey();
                            },
                            list: filteredList(recentStorage.get(routing.params?.envId)),
                        })}
                    </div>
                ),
                ...(sharedTileDashboardActions?.interactions
                    ? (() => {
                          const interactions = sharedTileDashboardActions?.interactions;
                          return {
                              rightPanel: {
                                  onClose: () => interactions.onChange(null),
                                  content: interactions.value?.name === "view-collections" && (
                                      <ViewCollections onClose={() => interactions.onChange(null)} sharedTile={interactions.value.tile} />
                                  ),
                              },
                          };
                      })()
                    : {}),
            });
        }
    );
