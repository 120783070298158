const {decorateFunction} = require("./decorate-function");

const debounce = (fn, delay) => {
    let timeout = null;
    const cancel = () => {
        if (timeout) {
            clearTimeout(timeout);
        }
    };
    return decorateFunction(
        (...params) => {
            cancel();

            timeout = setTimeout(() => {
                timeout = null;
                fn(...params);
            }, delay || 0);
        },
        {
            cancel,
        }
    );
};
exports.debounce = debounce;

const nullDebounce = (fn, delay) => {
    return decorateFunction(
        (...params) => {
            fn(...params);
        },
        {
            cancel: () => {},
        }
    );
};
exports.nullDebounce = nullDebounce;
