const {createArray} = require("../collections");
const {paddingLeft} = require("../strings");
const JsDate = require("./js-date");
const {keepOnly} = require("../objects");
const chain = require("../fs").chain;
const {DateTime} = require("luxon");
const moment = require("moment");

const getWeekDays = (date) => {
    const start = toDate(getWeekStart(date)).getTime();

    return createArray(7).map((i) => {
        const wd = new Date(start);
        wd.setDate(wd.getDate() + i);
        return Object.assign({}, parseDate(wd), {dow: i});
    });
};
exports.getWeekDays = getWeekDays;

const getYearStart = (date) => {
    return {...date, day: 1, month: 1};
};
exports.getYearStart = getYearStart;

const getMonthStart = (date) => {
    return {...date, day: 1};
};
exports.getMonthStart = getMonthStart;

const getStartOfDay = (dateObject) => {
    const d = toDate(dateObject);
    d.setHours(0, 0, 0, 0);
    return parseDate(d);
};
exports.getStartOfDay = getStartOfDay;

// start from sunday
const getWeekStart = (date) => {
    const d = toDate(date);

    const start = new Date(d.getTime());
    start.setDate(start.getDate() - d.getDay());
    return parseDate(start);
};
exports.getWeekStart = getWeekStart;

const getMonthEnd = (date) => ({
    ...date,
    day: getMonthLength(date),
});
exports.getMonthEnd = getMonthEnd;

const getMonthLength = (date) => {
    return chain(
        toDate({...date, day: 1}),
        (d) => JsDate.addMonth(d, 1),
        (d) => JsDate.addDate(d, -1),
        (d) => d.getDate()
    );
};
exports.getMonthLength = getMonthLength;

const getMonthWeeks = (date) => {
    const monthStart = getMonthStart(date);

    let weeks = [];
    let index = getWeekStart(monthStart);
    for (;;) {
        const weekDays = getWeekDays(index);
        if (weekDays.find((wd) => wd.month === date.month)) {
            weeks.push(weekDays);
            index = addDate(index, 7);
        } else {
            break;
        }
    }
    return weeks;
};
exports.getMonthWeeks = getMonthWeeks;

const getMonthDays = (date) => {
    return chain(getMonthStart(date), (start) => createArray(getMonthLength(date)).map((i) => addDate(start, i)));
};

exports.getMonthDays = getMonthDays;

const toDate = (date) => (typeof date === "undefined" ? new Date() : new Date(date.year, date.month - 1, date.day || 1, 0));
exports.toDate = toDate;

const toDateSecond = (date) => (typeof date === "undefined" ? new Date() : new Date(date.year, date.month - 1, date.day || 1, date.hours, date.minutes, date.seconds));
exports.toDateSecond = toDateSecond;

const parseDate = (date, tz) => {
    if (date == null) {
        return null;
    }
    if (tz && typeof date === "number") {
        return DateTime.fromMillis(date, {zone: tz}).toObject();
    }

    date = typeof date === "number" || typeof date === "string" ? new Date(date) : date;

    return {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate(),
        hours: date.getHours(),
        minutes: date.getMinutes(),
        seconds: date.getSeconds(),
    };
};
exports.parseDate = parseDate;

const parseDateUTC = (date) => {
    if (date == null) {
        return null;
    }

    let formattedDate = typeof date === "number" || typeof date === "string" ? new Date(date) : date;

    return typeof date === "string"
        ? {
              year: formattedDate.getFullYear(),
              month: formattedDate.getMonth() + 1,
              day: formattedDate.getDate(),
              hours: formattedDate.getHours(),
              minutes: formattedDate.getMinutes(),
              seconds: formattedDate.getSeconds(),
          }
        : {
              year: formattedDate.getUTCFullYear(),
              month: formattedDate.getUTCMonth() + 1,
              day: formattedDate.getUTCDate(),
              hours: formattedDate.getUTCHours(),
              minutes: formattedDate.getUTCMinutes(),
              seconds: formattedDate.getUTCSeconds(),
          };
};
exports.parseDateUTC = parseDateUTC;

const sameDate = (d1, d2) => {
    if (d1 == null && d2 == null) {
        return true;
    } else if (d1 == null || d2 == null) {
        return false;
    }

    return d1.day === d2.day && d1.month === d2.month && d1.year === d2.year;
};
exports.sameDate = sameDate;

const sameExactDate = (d1, d2) => {
    if (d1 == null && d2 == null) {
        return true;
    } else if (d1 == null || d2 == null) {
        return false;
    }

    return toDateSecond(d1) - toDateSecond(d2) === 0;
};
exports.sameExactDate = sameExactDate;

const inDate = (millis, date, tz) => {
    const d = millis - toJsDate(date, tz).getTime();

    return d >= 0 && d < 24 * 60 * 60 * 1000;
};

exports.inDate = inDate;

const importances = ["year", "month", "day"];
const lt = (d1, d2) => {
    if (!d1 || !d2) return false;

    for (const a of importances) {
        if (d1[a] !== d2[a]) {
            return d1[a] < d2[a];
        }
    }
    return false; // Don't accept equals
};
exports.lt = lt;
const le = (d1, d2) => {
    for (const a of importances) {
        if (d1[a] !== d2[a]) {
            return d1[a] < d2[a];
        }
    }
    return true; // Accept equals
};
exports.le = le;
const gt = (d1, d2) => {
    for (const a of importances) {
        if (d1[a] !== d2[a]) {
            return d1[a] > d2[a];
        }
    }
    return false; // Don't accept equals
};
exports.gt = gt;

const ge = (d1, d2) => {
    for (const a of importances) {
        if (d1[a] !== d2[a]) {
            return d1[a] > d2[a];
        }
    }
    return true; // Accept equals
};
exports.ge = ge;

exports.compares = {ge, gt, le, lt};

const serializeDate = (date) => `${date.year}-${paddingLeft(date.month)}-${paddingLeft(date.day)}`;
exports.serializeDate = serializeDate;

const serializeMonth = (date) => `${date.year}-${paddingLeft(date.month)}`;
exports.serializeMonth = serializeMonth;

const deserialize = (date) => parseDate(new Date(date));
exports.deserialize = deserialize;

const addSecond = (date, delta) => {
    return date && parseDate(JsDate.addSeconds(toDateSecond(date), delta));
};
exports.addSecond = addSecond;

const addDate = (date, delta) => date && parseDate(JsDate.addDate(toDate(date), delta));
exports.addDate = addDate;

const addMonth = (date, delta) => date && parseDate(JsDate.addMonth(toDate(date), delta));
exports.addMonth = addMonth;

const addYear = (date, delta) => date && parseDate(JsDate.addYear(toDate(date), delta));
exports.addYear = addYear;

const getYearRange = (year) => {
    const yearStart = {year, month: 1, day: 1};
    const yearEnd = {year: year, month: 12, day: 31};
    return {from: yearStart, to: yearEnd};
};

exports.getYearRange = getYearRange;

const getMonthRange = ({year, month}) => {
    const monthStart = {year, month, day: 1};
    return {from: monthStart, to: addMonth(monthStart, 1)};
};
exports.getMonthRange = getMonthRange;

const getWeekRange = (date) => {
    const weekStart = getWeekStart(date);
    return {from: weekStart, to: addDate(weekStart, 7)};
};
exports.getWeekRange = getWeekRange;

const getDow = (date) => {
    if (date.dow) {
        return date.dow;
    }
    return toDate(date).getDay();
};
exports.getDow = getDow;

const secondDiff = (d1, d2) => {
    return Math.abs((toDateSecond(d1).getTime() - toDateSecond(d2).getTime()) / 1000);
};
exports.secondDiff = secondDiff;

function dateDiff(d1, d2) {
    return Math.round((toDate(d1).getTime() - toDate(d2).getTime()) / (24 * 60 * 60 * 1000));
}
exports.dateDiff = dateDiff;

const today = (tz) => {
    return keepOnly(DateTime.fromObject({zone: tz}).toObject(), ["year", "month", "day"]);
};
exports.today = today;

const todayWithTime = (tz) => {
    return DateTime.fromObject({zone: tz}).toObject();
};
exports.todayWithTime = todayWithTime;

const currentYear = (tz) => {
    return keepOnly(today(tz), ["year"])?.year;
};

exports.currentYear = currentYear;

const tomorrow = (tz) => addDate(today(tz), 1);
exports.tomorrow = tomorrow;

const yesterday = (tz) => addDate(today(tz), -1);
exports.yesterday = yesterday;

const singleDateRange = (date) => ({from: date, to: addDate(date, 1)});
exports.singleDateRange = singleDateRange;

const toJsDate = (d, tz) => {
    if (!tz) {
        return new Date(d.year, d.month - 1, d.day || 1, 0, 0, 0, 0);
    }
    return DateTime.fromObject({...keepOnly(d, ["year", "month", "day"]), zone: tz}).toJSDate();
};
exports.toJsDate = toJsDate;
exports.toJsDate1 = toJsDate;

const minDate = (d1, d2) => {
    const diff = dateDiff(d1, d2);
    if (diff >= 0) {
        return d2;
    }
    return d1;
};
exports.minDate = minDate;

const getDatesInRange = ({from, to}) => {
    let ret = [];
    for (; !sameDate(from, to); from = addDate(from, 1)) {
        ret.push(from);
    }
    return ret;
};
exports.getDatesInRange = getDatesInRange;

const truncateMonth = (date) => {
    return {
        ...date,
        day: 1,
    };
};
exports.truncateMonth = truncateMonth;

const truncateYear = (date) => {
    return {
        ...date,
        month: 1,
        day: 1,
    };
};
exports.truncateYear = truncateYear;

exports.daysInMonth = function daysInMonth({month, year}) {
    return new Date(year, month, 0).getDate();
};
function isLeapYear(year) {
    return year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0);
}
exports.isLeapYear = isLeapYear;

exports.daysInYear = function daysInYear(year) {
    return isLeapYear(year) ? 366 : 365;
};
const getCurrentTzName = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone || moment.tz.guess();
};

exports.getCurrentTzName = getCurrentTzName;

const isDateExisted = (date) => {
    return date && moment(`${date.day}/${date.month}/${date.year}`, "D/M/YYYY", true).isValid();
};

exports.isDateExisted = isDateExisted;

// is d1 before d2?
const isBefore = (d1, d2) => {
    return moment(toJsDate(d1)).isBefore(toJsDate(d2));
};
exports.isBefore = isBefore;

const getPreviousDayInWeek = (delta) => {
    let target = delta;
    let date = new Date();
    date.setDate(date.getDate() - (date.getDay() == target ? 7 : (date.getDay() + (7 - target)) % 7));
    return new Date(date);
};

exports.getPreviousDayInWeek = getPreviousDayInWeek;

const getDayEnd = (date) => {
    return {...date, hours: 23, minutes: 59, seconds: 59};
};

exports.getDayEnd = getDayEnd;

const getDayStart = (date) => {
    return {...date, hours: 0, minutes: 0, seconds: 0};
};

exports.getDayStart = getDayStart;
