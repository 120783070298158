import {cs} from "@common/react/chain-services";
import * as React from "react";
import "./date-filter-options.scss";
import {DateRanges} from "./date-ranges/date-ranges";
import {Comparison} from "./comparison/comparison";
import {DefaultFilterCriteria} from "./default-filter-criteria/default-filter-criteria";
import {consumeContext} from "@common/react/context";
import {TextInput} from "../../../../../../../../../common/form/text-input/text-input";
import {ffToTextInput} from "../../../../../../../../../common/form/ff-to-text-input";
import {scope} from "@common/react/scope";
import {TextareaInput} from "../../../../../tile/edit/left-panel/tabs/tile-tab/chart-types/common/textarea-input/textarea-input";
import {getPath} from "@common/utils/arr-path";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {ffToDropdown} from "../../../../../../../../../common/form/ff-to-dropdown";
import {MpLine} from "../../common/mp-line/mp-line";
import {ShowHideToggle} from "../../../../../../../../../common/form/toggles/show-hide-toggle";
import {OnOffToggle} from "../../../../../../../../../common/form/toggles/on-off-toggle";
import {HandlingMissingDates} from "./handling-missing-dates/handling-missing-dates";
import {DatePickerSelectableRange} from "./date-picker-selectable-range/date-picker-selectable-range";
import {TimeFilterConfig} from "./time-filter/time-filter-config";

export const DateFilterOptions = ({filter, form}) =>
    cs(consumeContext("collection"), ({collection}) => {
        return (
            <div className="date-filter-options-9c9">
                <div className="option-group label">
                    <div className="label">Filter Label</div>
                    {TextInput({
                        label: "Label",
                        ...ffToTextInput(form.field(["label"])),
                    })}

                    {filter.value.label && (
                        <>
                            {MpLine({
                                left: ShowHideToggle({
                                    state: scope(filter, ["showLabelInfoIcon"]),
                                }),
                                right: "Filter Label Info Icon",
                            })}
                            {getPath(filter.value, ["showLabelInfoIcon"]) &&
                                TextareaInput({
                                    state: scope(filter, ["labelInfoIconText"]),
                                })}
                        </>
                    )}
                </div>

                <div className="option-group">
                    <div className="label">Filter Settings</div>
                    {filter.value.columns?.length > 1 &&
                        DropdownSelect({
                            label: "Multi-select options",
                            list: ["SingleSelect", "Any", "All"],
                            info: "Select how the date fields will interact when selected by the end user.",
                            tooltipTopOffset: -5,
                            valueToLabel: (op) =>
                                [
                                    {
                                        label: "Single field selection only",
                                        value: "SingleSelect",
                                    },
                                    {
                                        label: "Show results meeting ANY criteria",
                                        value: "Any",
                                    },
                                    {
                                        label: "Show results meeting ALL criteria",
                                        value: "All",
                                    },
                                ].find((item) => item.value === op).label,
                            ...ffToDropdown(form.field(["multiSelectionOption"])),
                        })}

                    {collection.value.$type !== "ApiCollection" && (
                        <>
                            {DatePickerSelectableRange({filter})}
                            {DateRanges({filter, form})}
                            {Comparison({filter, form})}
                            {TimeFilterConfig({state: scope(filter, ["allowTimeOfDay"])})}
                            {HandlingMissingDates({
                                state: scope(filter, ["includeNullDates"]),
                            })}
                        </>
                    )}

                    {/*{MpLine({*/}
                    {/*    left: OnOffToggle({state: scope(filter, ["required"])}),*/}
                    {/*    right: "Require value to run this dashboard report",*/}
                    {/*})}*/}
                </div>

                {DefaultFilterCriteria({
                    filter,
                    form,
                    collection: collection.value,
                })}
            </div>
        );
    });
