import * as React from "react";
import {scope} from "@common/react/scope";
import {CheckboxLine} from "../../../../../../../../../../common/checkbox-line/checkbox-line";
import {ShowHideToggle} from "../../../../../../../../../../../../../common/form/toggles/show-hide-toggle";
import {ComparisonMethodSelect} from "../../../common/comparison-method-select";
import {PositiveChangeValueSelect} from "../../../common/positive-change-value-select";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {ffToDropdown} from "../../../../../../../../../../../../../common/form/ff-to-dropdown";
import {LpLine} from "../../../../../../../../../../common/left-panel/line/lp-line";
import {cs} from "@common/react/chain-services";
import {StaticTooltipService} from "../../../../../../../../../../common/tooltip3/static-tooltip-service";
// import {ComparisonDateRange} from "./comparison-date-range/comparison-date-range";

export const Comparison = ({tile, form}) => ({
    label: "Comparison Date Range",
    iconTooltip: cs(
        [
            "staticTooltip",
            (_, next) =>
                StaticTooltipService({
                    direction: "top",
                    info: "When a date filter is applied with a comparison range, the comparison data can be hidden.",
                    tooltipBoxWidth: 200,
                    // topOffset: -8,
                    next,
                }),
        ],
        ({staticTooltip}) => (
            <div className="expandable-tooltip-info-icon">
                {staticTooltip.renderIcon({
                    icon: <i className="material-icons">info</i>,
                    className: "info-tooltip",
                })}
            </div>
        )
    ),
    control: ShowHideToggle({state: scope(tile, ["style", "showCompare"])}),
    render: () => (
        <div className="single-kpi-value-format-yio">
            <div className="subsection">
                <div className="content">
                    {DropdownSelect({
                        label: "Position",
                        list: ["Vertical", "Horizontal"],
                        valueToLabel: (v) => v,
                        ...ffToDropdown(form.field(["style", "compareValuePosition"])),
                    })}
                </div>
            </div>

            {scope(tile, ["style", "showCompare"]).value && (
                <>
                    <div className="subsection">
                        <div className="header">
                            Previous Period
                            <div className="control">
                                {ShowHideToggle({
                                    state: scope(tile, ["style", "previousPeriodShown"]),
                                })}
                            </div>
                        </div>
                    </div>

                    {(() => {
                        const compareValueMethod = scope(tile, ["style", "compareValueMethod"]);

                        return (
                            <div className="subsection">
                                <div className="header">
                                    Comparison Value
                                    <div className="control">
                                        {ShowHideToggle({
                                            state: {
                                                value: !!compareValueMethod.value,
                                                onChange: (val) => compareValueMethod.onChange(val ? "Percent" : null),
                                            },
                                        })}
                                    </div>
                                </div>
                                {compareValueMethod.value && (
                                    <div className="content">
                                        {ComparisonMethodSelect(scope(tile, ["style", "compareValueMethod"]))}

                                        {LpLine({
                                            label: "Comparison Indicator",
                                            control: ShowHideToggle({
                                                state: scope(tile, ["style", "indicatorShown"]),
                                            }),
                                        })}

                                        {tile.value.style.indicatorShown && PositiveChangeValueSelect(scope(tile, ["style", "positiveChangeGood"]))}
                                    </div>
                                )}
                            </div>
                        );
                    })()}

                    {/*<div className="subsection">*/}
                    {/*    <div className="header">*/}
                    {/*        Comparison Indicator*/}
                    {/*        <div className="control">*/}
                    {/*            {ShowHideToggle({state: scope(tile, ["style", "indicatorShown"])})}*/}
                    {/*        </div>*/}
                    {/*    </div>*/}

                    {/*    /!* {tile.value.style["indicatorShown"] && ( *!/*/}
                    {/*    <div className="content">*/}
                    {/*        {PositiveChangeValueSelect(scope(tile, ["style", "positiveChangeGood"]))}*/}
                    {/*    </div>*/}
                    {/*    /!* )} *!/*/}
                    {/*</div>*/}
                </>
            )}

            {/*{ComparisonDateRange({*/}
            {/*    tile*/}
            {/*})}*/}
        </div>
    ),
});
