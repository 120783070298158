import {cs} from "@common/react/chain-services";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {getPath} from "@common/utils/arr-path";
import {upperCase1} from "@common/utils/strings";
import {FieldInfoProvider} from "./field-info-provider/field-info-provider";

export const getHiddenLegendDisplay = (style) => {
    if (["Left", "Right"].indexOf(style.legendPosition) > -1) {
        return style.legendSize == "Large" || style.legendSize == "Auto" ? [] : ["LabelAndValue", "LabelAndPercent"];
    }

    return [];
};

export const getLegendContentValue = (currentValue, style) => {
    const hiddenFields = getHiddenLegendDisplay(style);
    return hiddenFields.indexOf(currentValue) > -1 ? "Label" : currentValue || "Label";
};

export const LegendDisplaySelect = (state, style) =>
    cs(["hiddenFields", (_, next) => next(getHiddenLegendDisplay(style))], ({hiddenFields}) =>
        FieldInfoProvider({
            className: "margin-bottom",
            info: ["Left", "Right"].indexOf(style.legendPosition) > -1 ? "Legend size must be Wide or Auto to display either Label and Value or Label and Percentage" : null,
            tooltipBoxWidth: 170,
            topOffset: 35,
            field: DropdownSelect({
                label: "Display",
                list: [
                    {
                        label: "Label",
                        disabled: false,
                    },
                    {
                        label: "LabelAndValue",
                        disabled: hiddenFields.indexOf("LabelAndValue") > -1,
                    },
                    {
                        label: "LabelAndPercent",
                        disabled: hiddenFields.indexOf("LabelAndPercent") > -1,
                    },
                ],
                isSelected: (v) => getPath(v.label) === getLegendContentValue(state.value, style),
                onChange: (v) => state.onChange(getPath(v.label)),
                valueToLabel: (v) =>
                    `${upperCase1(
                        v.label
                            .replace(/([A-Z])/g, " $1")
                            .trim()
                            .toLowerCase()
                    )} ${v.disabled ? "(unavailable)" : ""}`,
            }),
        })
    );
