import {isTitleShown} from "../../../../web-client/src/routes/collection/tile/edit/left-panel/tabs/tile-tab/chart-types/pie-chart/display-size-rules";
import {isScatterLegendHidden} from "../../../../web-client/src/routes/collection/tile/edit/left-panel/tabs/tile-tab/chart-types/scatter-plot/panels/legend/legend";
import {getLegendPosition} from "../../../../web-client/src/routes/collection/tile/edit/left-panel/tabs/tile-tab/chart-types/combo-chart/panels/legend/legend-display-rules";

export const scatterPlotRestyling = ({tile, size}) => {
    const newTitleStyle = {
        ...tile.style.title,
        show: isTitleShown({size}) ? tile.style.title.show : false,
    };

    const newLegendStyle = {
        ...tile.style.legendStyle,
        legendPosition: getLegendPosition(tile.style.legendStyle.legendPosition, size, tile.$type),
        legendShown: isScatterLegendHidden({size}) ? false : tile.style.legendStyle.legendShown,
    };

    return {
        ...tile,
        style: {
            ...tile.style,
            title: newTitleStyle,
            legendStyle: newLegendStyle,
        },
    };
};
