import React from "react";
import {MapStepsDropdown} from "./map-steps-dropdown";
import "./map-tracker.scss";
import {MapBreadcrumbs} from "./map-breadcrumbs";

export const MapTracker = ({drillingTracker}) => {
    return (
        <div className="map-tracker-52c">
            {/*{MapStepsDropdown({*/}
            {/*    steps: drillingTracker.value.steps,*/}
            {/*    onChange: (stepIndex) => drillingTracker.onChange({*/}
            {/*        ...drillingTracker.value,*/}
            {/*        steps: drillingTracker.value.steps.slice(0, stepIndex + 1)*/}
            {/*    }),*/}
            {/*})}*/}

            {MapBreadcrumbs({
                steps: drillingTracker.value.steps || drillingTracker.value.autoSteps,
                onChange: (stepIndex) =>
                    drillingTracker.onChange({
                        ...drillingTracker.value,
                        steps: drillingTracker.value.steps.slice(0, stepIndex + 1),
                    }),
            })}
        </div>
    );
};
