const cache2 = (fn, getKey = (...args) => JSON.stringify(args)) => {
    let cache = {};

    return (...args) => {
        const key = getKey(...args);
        if (!cache.hasOwnProperty(key)) {
            cache[key] = fn(...args);
        }
        return cache[key];
    };
};
exports.cache2 = cache2;
