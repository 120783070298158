import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {keyed} from "@common/react/keyed";
import {UseState} from "@common/react/use-state";
import {last} from "@common/utils/collections";
import {chain} from "@common/utils/fs";
import {DialogService} from "../../../common/dialog/dialog-service";
import FolderIcon from "assets/icons/common/FolderIcon";
import "./ds-folders-dialog.scss";
import {FixedPopupMenu} from "../../../common/fixed-popup-menu/fixed-popup-menu";
import {Button} from "@common/form/buttons/button/button";
import {cx} from "emotion";
import {getFoldersPath} from "../../edit/common/helpers";
import {keepOnly} from "@common/utils/objects";
import {TextInput} from "@common/form/text-input/text-input";
import {isBlank} from "@common/utils/strings";

export const DsFoldersDialog = ({next: rootNext, remoteFolders}) =>
    cs(
        [
            "modal",
            ({}, next) =>
                DialogService({
                    render: ({resolve, reject, args: {folderID}}) => ({
                        title: "Set Location",
                        content: next({reject, resolve, folderID}),
                    }),
                    next: rootNext,
                }),
        ],
        consumeContext("apis"),
        [
            "stack",
            ({modal}, next) =>
                UseState({
                    getInitValue: () => {
                        const paths = getFoldersPath(modal.folderID, remoteFolders.value);
                        return paths.slice(0, paths.length - (paths.length > 1 ? 1 : 0));
                    },
                    next,
                }),
        ],
        ["selected", ({modal}, next) => UseState({initValue: modal.folderID, next})],
        ({modal, stack, selected, apis}) => {
            const currentStack = last(stack.value);

            if (!remoteFolders.value) return null;

            return (
                <div className="ds-folders-dialog-99y">
                    <div className="dialog-body">
                        {cs(
                            [
                                "popup",
                                (_, next) =>
                                    FixedPopupMenu({
                                        offsetTop: 25,
                                        icon: <i className="far fa-ellipsis-h" />,
                                        getCommands: () =>
                                            cs(["list", (_, next) => next(stack.value.slice(0, stack.value.length - 2))], ({list}) =>
                                                list.map((s, i) => ({
                                                    label: s.name,
                                                    onClick: () => {
                                                        selected.onChange(s.id);
                                                        stack.change((prev) => prev.splice(0, i + 1));
                                                    },
                                                }))
                                            ),
                                        next,
                                    }),
                            ],
                            ({popup}) => (
                                <div className="breadcum">
                                    {stack.value.length >= 3 && (
                                        <>
                                            {popup.render({})}
                                            <i className="far fa-angle-right" />
                                        </>
                                    )}

                                    {stack.value.slice(-2).map((s, i) => (
                                        <div
                                            className={cx("item", {
                                                active: currentStack.id === s.id,
                                            })}
                                            onClick={() => {
                                                selected.onChange(s.id);
                                                return (
                                                    currentStack.id !== s.id && stack.change((prev) => prev.splice(0, prev.length - 1 + i))
                                                );
                                            }}
                                        >
                                            {s.name}
                                            {stack.value.slice(-2).length > i + 1 && <i className="far fa-angle-right" />}
                                        </div>
                                    ))}
                                </div>
                            )
                        )}

                        <div className="list">
                            {chain(remoteFolders.value, (_) => _.filter((f) => f.parentFolderID === currentStack.id && f.id !== null)).map(
                                (folder, i) =>
                                    cs(keyed(i), () => {
                                        const hasChildren = (folder) =>
                                            remoteFolders.value.filter((f) => f.parentFolderID === folder.id).length > 0;
                                        return (
                                            <div
                                                className={cx("item", {
                                                    active: folder.id === selected.value,
                                                })}
                                                onClick={() => {
                                                    if (hasChildren(folder)) {
                                                        stack.change((s) => [...s, keepOnly(folder, ["id", "name", "parentFolderID"])]);
                                                    }

                                                    selected.onChange(folder.id);
                                                }}
                                            >
                                                {FolderIcon({})}
                                                <div className="text">{folder.name}</div>
                                                {hasChildren(folder) && <i className="far fa-angle-right" />}
                                            </div>
                                        );
                                    })
                            )}
                        </div>

                        {cs(["showing", (_, next) => UseState({next})], ({showing}) => (
                            <>
                                {!showing.value ? (
                                    <div className="new">
                                        <Button btnType="border" size="small" onClick={() => showing.onChange(true)}>
                                            New Folder
                                        </Button>
                                    </div>
                                ) : (
                                    cs(
                                        [
                                            "state",
                                            (_, next) =>
                                                UseState({
                                                    initValue: "",
                                                    next,
                                                }),
                                        ],
                                        ({state}) => (
                                            <div className="new-folder-input">
                                                {TextInput({
                                                    state,
                                                    placeholder: "New Folder",
                                                })}
                                                <Button btnType="secondary" size="small" onClick={() => showing.onChange(false)}>
                                                    <i className="fa-solid fa-xmark"></i>
                                                </Button>
                                                &nbsp;
                                                <Button
                                                    size="small"
                                                    onClick={async () => {
                                                        if (isBlank(state.value)) return;
                                                        await apis.data.upsertFolder({
                                                            name: state.value,
                                                            parentFolderID: selected.value,
                                                        });
                                                        showing.onChange(false);
                                                        remoteFolders.reload();
                                                    }}
                                                >
                                                    <i className="fa-solid fa-check"></i>
                                                </Button>
                                            </div>
                                        )
                                    )
                                )}
                            </>
                        ))}
                    </div>

                    <div className="buttons">
                        <Button btnType="secondary" onClick={() => modal.resolve(null)}>
                            Cancel
                        </Button>
                        <Button onClick={() => modal.resolve(selected.value)}>Set Location</Button>
                    </div>
                </div>
            );
        }
    );
