import {breakPoints, numColumnsOfGrid} from "../../../../web-client/src/routes/collection/common/dnd-grid-panel/grid-constants";

export const getLayoutColumns = (layoutWidth, customWindowWidth) => {
    let numColumns = breakPoints[1];

    const ww = customWindowWidth || window.innerWidth;

    if (ww >= 768) {
        numColumns = breakPoints[0];
    }

    if (ww >= 1024) {
        numColumns = null;
    }

    const actualNumColumns = numColumns ?? numColumnsOfGrid;

    return {
        numColumns,
        actualNumColumns,
        blockWidth: Math.max(1, layoutWidth / actualNumColumns),
    };
};

export const getLayoutChartsHeight = (dashboardElem) => {
    const defaultReturn = {
        availableChartsHeight: 0,
    };
    if (!dashboardElem) {
        return defaultReturn;
    }

    const chartsElem = dashboardElem.getElementsByClassName("charts")[0];

    if (!chartsElem) {
        return defaultReturn;
    }
    const {top: dashboardTop, height} = dashboardElem.getBoundingClientRect();
    const {top: chartTop} = chartsElem.getBoundingClientRect();

    return {
        availableChartsHeight: height - Math.abs(dashboardTop - chartTop),
    };
};
