import {initDateFilterState} from "./date/date-filter-state";
import {initNumericFilterState} from "./numeric/numeric-filter-state";
import {initBooleanFilterState} from "./boolean/boolean-filter-state";

export const initFilterState = (filter) => {
    const byTypes = {
        DateFilter: initDateFilterState,
        TextFilter: initTextFilterState,
        NumericFilter: initNumericFilterState,
        BooleanFilter: initBooleanFilterState,
    };

    return byTypes[filter.$type](filter);
};

export const initTextFilterState = (filter) => {
    // TextFilterValue
    return filter.option === "FreeFormText"
        ? {
              freeFormValue: filter.defaultFreeFormValue,
              operator: filter.defaultOperator,
              columns: filter.columns,
              // columns: filter.columns.filter(c => c.default)
          }
        : {
              // SelectableList
              selectableListValues: [],
              columns: filter.columns,
              // columns: filter.columns.filter(c => c.default)
          };
};
