import {cx} from "emotion";
import moment from "moment";
import React from "react";
import {GhostButton} from "../../../../../../../../common/form/buttons/ghost-button/ghost-button";
import {CachedLoad} from "@common/react/cached-api/cached-load";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {Load2} from "@common/react/load2";
import {getFieldType} from "@common/ui-components/charts/common/get-field-type";
import {TableFuncs} from "@common/ui-components/charts/table/paged/search/search-columns";

import {SortIcon} from "@common/ui-components/charts/table/table/header/header";
import {SearchHeader} from "@common/ui-components/charts/table/table/header/search-header";
import {LeftPanelClosable} from "../../../../../common/left-panel-closable/left-panel-closable";
import {dataViewColor, getDSColorBasedOnIndex} from "../../common/data-source-colors";
import {UsageStatusIndicator} from "../../common/usage-status-indicator";
import "./preview-data.scss";
import {RefreshSpinnerIcon} from "@common/ui-components/live/live-dashboard/live-grid/auto-refresh-controller";

const PREVIEW_TABLE_API_KEY = "PREVIEW_TABLE";

export const PreviewData = ({tableId, dataSources, onCancel, model, toggleViewFullWidth, isViewFullWidth, onPreviewData}) => {
    const table = model.value.tables.find((t) => t.id === tableId);

    return cs(
        consumeContext("actionToast"),
        ({}, next) => {
            const index = dataSources.findIndex((t) => t.id === table?.dataSourceID);

            const color = table?.$type === "ViewModelTable" ? dataViewColor : dataSources[index]?.colorRGB || getDSColorBasedOnIndex(index);
            return (
                <LeftPanelClosable
                    {...{
                        onClose: onCancel,
                        className: "preview-data-left-panel-9ol",
                        label: (
                            <>
                                DATA PREVIEW for <UsageStatusIndicator status="all" color={color} />{" "}
                                <span style={{color}}>{table?.visualName?.toUpperCase()}</span>{" "}
                                {table?.$type === "ViewModelTable" ? `DATA VIEW` : `TABLE`}
                            </>
                        ),
                        content: next(),
                    }}
                />
            );
        },
        consumeContext("apis"),
        ["search", ({}, next) => TableFuncs({next})],
        [
            "transformation",
            ({apis}, next) =>
                table?.transformationID
                    ? Load2({
                          _key: table.id,
                          fetch: () => apis.transformation.getTransformation(table.transformationID),
                          captureException: true,
                          next,
                      })
                    : next({loading: false}),
        ],

        [
            "preview",
            ({apis, transformation, search, actionToast}, next) => {
                if (transformation.loading) {
                    return null;
                }
                if (!table) {
                    return null;
                }
                const key = {
                    apiKey: PREVIEW_TABLE_API_KEY,
                    apiParams: {
                        modelId: model.value.id,
                        tableId: table.id,
                        search: search.tableSearches,
                    },
                };

                return CachedLoad({
                    keepOutdatedValue: true,
                    getCacheKey: (k) => JSON.stringify(k),
                    key,
                    fetch: () => apis.model.previewTable(model.value.id, table.id, search.tableSearches),
                    captureException: true,
                    onCached: () => {
                        actionToast.show({
                            text: ` Preview data for ${table.name} ready.`,
                            action: {
                                text: "View preview data",
                                onClick: () =>
                                    onPreviewData({
                                        dataSources,
                                        tableId: table?.id,
                                        model,
                                        table: table,
                                    }),
                            },
                            extraData: {
                                tableId: table.id,
                            },
                        });
                    },
                    next,
                });
            },
        ],
        ({preview, search}) => {
            const {data, lastCachedAt, message} = preview.value || {};
            const columns = preview?.value ? data?.columns : table?.columns;
            const previewTable = () => {
                return (
                    <div className="preview-table">
                        <div className="title">
                            <div className="top-100-rows">Top 100 rows</div>

                            <div className="fetching-box">
                                {preview?.loading ? (
                                    <>
                                        <RefreshSpinnerIcon />
                                        <span className="loading-text">Fetching Data...</span>
                                    </>
                                ) : (
                                    <>
                                        Data retrieved at {moment(lastCachedAt).format("hh:mm A")}{" "}
                                        <GhostButton onClick={() => preview.refetch()} className="ghost-btn">
                                            Refresh
                                        </GhostButton>
                                    </>
                                )}
                            </div>

                            <GhostButton
                                iconLeft={
                                    !isViewFullWidth ? (
                                        <i className="fal fa-arrows-h" />
                                    ) : (
                                        <span
                                            className="fa-rotate-by"
                                            style={{
                                                "--fa-rotate-angle": "45deg",
                                                display: "inline-block",
                                            }}
                                        >
                                            <i className="fal fa-compress-alt" />
                                        </span>
                                    )
                                }
                                className="screen-control"
                                onClick={toggleViewFullWidth}
                            >
                                {isViewFullWidth ? "Collapse Table" : "Expand Table"}
                            </GhostButton>
                        </div>
                        <div className="table-container">
                            {preview?.loading && (
                                <div className="loading-text">
                                    <div>
                                        IT MAY TAKE SEVERAL SECONDS TO FETCH THE DATA. <br />
                                        YOU CAN CLOSE THE PREVIEW AND CHECK BACK SHORTLY.
                                    </div>
                                </div>
                            )}
                            <table>
                                <thead>
                                    <tr>
                                        {(columns || []).map((c, i) => {
                                            const {columnIndex, sortDirection} = search.tableSearches?.find((s) => s.columnIndex === i) || {
                                                columnIndex: null,
                                                sortDirection: null,
                                            };

                                            return (
                                                <th className="header-a323" key={i}>
                                                    <div>
                                                        <div className="name" onClick={() => search.sortColumn(i)}>
                                                            {c.name}
                                                            <div className="sort-icon">
                                                                <div
                                                                    className={cx(
                                                                        "sort-up",
                                                                        columnIndex == i && sortDirection == "Asc" && "active"
                                                                    )}
                                                                >
                                                                    <SortIcon />
                                                                </div>

                                                                <div
                                                                    className={cx(
                                                                        "sort-down",
                                                                        columnIndex == i && sortDirection == "Desc" && "active"
                                                                    )}
                                                                >
                                                                    <SortIcon />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {c.type !== "number" && (
                                                            <div className="actions">
                                                                {SearchHeader({
                                                                    search,
                                                                    columnName: c.name,
                                                                    columnIndex: i,
                                                                })}
                                                            </div>
                                                        )}
                                                    </div>
                                                </th>
                                            );
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {!preview.loading &&
                                        data?.data.map((row, i) => (
                                            <tr key={i}>
                                                {(columns || []).map((c, j) => (
                                                    <td key={j} className={getFieldType(c)}>
                                                        {row[j]}
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                );
            };

            const errorMessage = (message) => (
                <div className="error-messages">
                    <div className="warning">
                        <span className="material-icons">warning</span>
                    </div>
                    <div>{message}</div>
                </div>
            );

            return <div className="preview-data-panel-8we">{message ? errorMessage(message) : previewTable()}</div>;
        }
    );
};
