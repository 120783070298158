import * as React from "react";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {SearchInput} from "../../../../../../../../common/form/search-input/search-input";
import {cx} from "emotion";
import {consumeContext} from "@common/react/context";
import "./select-model.scss";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";
import {isMatchText} from "@common/utils/strings";

export const SelectModel = ({isSelected, onSelect, list}) =>
    cs(consumeContext("apis"), ["search", (_, next) => UseState({next})], ({search}) => {
        return (
            <div className="select-model-2f6">
                {SearchInput({
                    state: search,
                    placeholder: "Search for a model...",
                    delay: 0,
                })}
                <VerbScrollbar style={{height: "calc(100% - 48px)"}} className="model-list">
                    {list
                        .filter((l) => isMatchText(l.name, search.value))
                        .map((model) => (
                            <div
                                key={model.id}
                                className={cx("model-item", {
                                    selected: isSelected(model.id),
                                })}
                                onClick={() => onSelect(model.id)}
                            >
                                <img src={require("./circle-model-icon.svg")} className="model-icon" alt={""} />

                                {model.name}

                                {isSelected(model.id) && <img src={require("../../../../../common/icons/blue-check-icon.svg")} className="check-icon" alt="" />}
                            </div>
                        ))}
                </VerbScrollbar>
            </div>
        );
    });
