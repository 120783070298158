import {lighten} from "@common/utils/color-util";
import {OuterJoinChildCanvas, SwapRelationshipIcon} from "../icons";
import React from "react";
import {ModelPanelHelper} from "../../../../../common/model-panel-helper";
import {RELATIONSHIP_INNER_JOIN} from "../../../../main/model-constrants";
import "./cr-table-section.scss";
import {cs} from "@common/react/chain-services";
import {tooltipService4} from "../../../../../../common/tooltip3/tooltip-service-3";
import {getTooltipFontSizes} from "@common/ui-components/charts/factory/tooltip/shared-tooltip-formatter/get-tooltip-font-sizes";
import {StaticTooltipService} from "../../../../../../common/tooltip3/static-tooltip-service";

export const CrTableSection = ({table, target, onSwap, relationship, dataSources}) => {
    const sourceColor = ModelPanelHelper.getTableColor({table, dataSources});
    const targetColor = target ? ModelPanelHelper.getTableColor({table: target, dataSources}) : null;

    return (
        <div className="table-name-section">
            <div
                className="table-col left-side"
                style={{
                    background: relationship?.leftColumnID ? lighten(sourceColor, 70) : lighten(sourceColor, 95),
                    borderColor: relationship?.leftColumnID ? sourceColor : lighten(sourceColor, 60),
                }}
            >
                <img
                    src={require(`../../suggest-relationship/${table.$type == "ViewModelTable" ? "data-view-icon.svg" : "table-icon.svg"}`)}
                    className="table-icon"
                />

                <b className="table-name">{table.visualName}</b>

                <div className="left-join-icon" />
            </div>

            {target && (
                <>
                    {relationship.joinType == RELATIONSHIP_INNER_JOIN ? (
                        <div className="relationship-line" />
                    ) : (
                        cs(
                            [
                                "staticTooltip",
                                (_, next) =>
                                    StaticTooltipService({
                                        direction: "top",
                                        info: "Swap parent - child relationship",
                                        tooltipBoxWidth: 205,
                                        next,
                                    }),
                            ],
                            ({staticTooltip}) =>
                                staticTooltip.renderIcon({
                                    icon: <SwapRelationshipIcon onClick={() => onSwap()} />,
                                    className: "swap-icon",
                                })
                        )
                    )}

                    <div
                        className="table-col right-side"
                        style={{
                            background: relationship?.rightColumnID ? lighten(targetColor, 70) : lighten(targetColor, 95),
                            borderColor: relationship?.rightColumnID ? targetColor : lighten(targetColor, 60),
                        }}
                    >
                        <img
                            src={require(`../../suggest-relationship/${
                                target.$type == "ViewModelTable" ? "data-view-icon.svg" : "table-icon.svg"
                            }`)}
                            className="table-icon"
                        />

                        <b className="table-name">{target.visualName}</b>

                        {relationship.joinType == RELATIONSHIP_INNER_JOIN ? (
                            <div className="circle" />
                        ) : (
                            <OuterJoinChildCanvas className="outer-join-icon" />
                        )}
                    </div>
                </>
            )}
        </div>
    );
};
