import {min} from "moment";
import {YesNoToggle} from "../../../../../../../common/form/toggles/yes-no-toggle";
import {cs} from "@common/react/chain-services";
import {scope} from "@common/react/scope";
import {UseState} from "@common/react/use-state";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {sort} from "@common/utils/collections";
import {chain} from "@common/utils/fs";
import {noun} from "@common/utils/plural";
import {TableIcon} from "../../../common/icons";
import {getDuplicatesMapping} from "../flat-file-mapping-helpers";

export const FlatFileTablesMapping = ({editingDs}) =>
    cs(() => {
        const tables = [...editingDs.value.tables, ...(editingDs.value?.disabledTables || [])];
        const duplicatesMapping = getDuplicatesMapping(tables);

        const sortedTables = chain(
            tables,
            (_) => [...sort(_, (c) => c.name)]
            // (_) => _.filter((c) => c.name.toLowerCase().indexOf((keyword.value || "").toLowerCase()) > -1)
        );

        return (
            <>
                <div className="label">Import Configuration</div>

                <div className="list">
                    <div className="labels">
                        <div>Table</div>
                        <div>Import</div>
                    </div>

                    {sortedTables.map((table) => {
                        const isDisabledTable = (editingDs.value?.disabledTables || []).findIndex((c) => c.name === table.name) > -1;
                        const isMappingError = duplicatesMapping.includes(table.mapsToName);

                        return (
                            <div className="table-item">
                                <div className="info">
                                    {isMappingError ? (
                                        <span
                                            className="material-icons-outlined"
                                            style={{
                                                color: "#FF5959",
                                                fontSize: 20,
                                            }}
                                        >
                                            sync_problem
                                        </span>
                                    ) : (
                                        TableIcon({})
                                    )}

                                    <div className="center">
                                        <div className="text-wrapper">
                                            <div className="name">{table.name}</div>

                                            <div className="overview">
                                                ({table.columns.length} {noun(table.columns.length, "column")}; {Number(table.records ?? table.rows)} rows)
                                            </div>
                                        </div>

                                        {!isDisabledTable &&
                                            cs(
                                                ["selected", (_, next) => next(scope(editingDs, ["tables", editingDs.value.tables.findIndex((t) => t.name === table.name)]))],
                                                ({selected}, next) => {
                                                    if (table.id) {
                                                        const list = [
                                                            ...tables
                                                                .filter((t) => !t.id)
                                                                .map((t) => ({
                                                                    name: t.name,
                                                                    schemaReferenceName: t.schemaReferenceName,
                                                                    label: `${t.name} (${t.columns.length} ${noun(t.columns.length, "column")}; ${Number(
                                                                        t.records ?? t.rows
                                                                    )} rows)`,
                                                                    columns: t.columns.map((c) => ({
                                                                        ...c,
                                                                        mapsToName: c?.suggestedName || null,
                                                                        mapsToSchema: c?.suggestedReferenceName || null,
                                                                    })),
                                                                    disabledColumns: [],
                                                                })),
                                                            {
                                                                name: null,
                                                                schemaReferenceName: null,
                                                                label: `${table.name} (${table.columns.length} ${noun(table.columns.length, "column")}; ${Number(
                                                                    table.records ?? table.rows
                                                                )} rows) (Old)`,
                                                                columns: table.columns.map((c) => ({
                                                                    ...c,
                                                                    mapsToName: null,
                                                                    mapsToSchema: null,
                                                                })),
                                                                disabledColumns: [],
                                                            },
                                                        ];

                                                        return DropdownSelect({
                                                            hasError: isMappingError,
                                                            valueToLabelList: (v) => v.label,
                                                            label: "Maps To",
                                                            list,
                                                            valueToLabel: (v) => v.label,
                                                            isSelected: (v) => v.name === selected.value.mapsToName,
                                                            onChange: (v) =>
                                                                selected.change((old) => ({
                                                                    ...old,
                                                                    mapsToName: v.name,
                                                                    mapsToSchema: v.schemaReferenceName,
                                                                })),
                                                        });
                                                    } else {
                                                        return next();
                                                    }
                                                },
                                                ({selected}) => {
                                                    const list = [
                                                        ...tables
                                                            .filter((t) => t.id)
                                                            .map((t) => ({
                                                                name: t.name,
                                                                schemaReferenceName: t.schemaReferenceName,
                                                                label: `${t.name} (${t.columns.length} ${noun(t.columns.length, "column")}; ${Number(t.records ?? t.rows)} rows)`,
                                                                columns: t.columns.map((c) => ({
                                                                    ...c,
                                                                    mapsToName: c?.suggestedName || null,
                                                                    mapsToSchema: c?.suggestedReferenceName || null,
                                                                })),
                                                                disabledColumns: [],
                                                            })),
                                                        {
                                                            name: null,
                                                            schemaReferenceName: null,
                                                            label: "New",
                                                            columns: table.columns.map((c) => ({
                                                                ...c,
                                                                mapsToName: null,
                                                                mapsToSchema: null,
                                                            })),
                                                            disabledColumns: [],
                                                        },
                                                    ];

                                                    return DropdownSelect({
                                                        hasError: isMappingError,
                                                        valueToLabelList: (v) => (v.name ? v.label : <span className="new">{v.label}</span>),
                                                        label: "Maps To",
                                                        list,
                                                        valueToLabel: (v) => (v.name ? v.label : <div className="new">{v.label}</div>),
                                                        isSelected: (v) => v.name === selected.value.mapsToName,
                                                        onChange: (v) =>
                                                            selected.change((old) => ({
                                                                ...old,
                                                                mapsToName: v.name,
                                                                mapsToSchema: v.schemaReferenceName,
                                                                columns: v.columns,
                                                                disabledColumns: v.disabledColumns,
                                                            })),
                                                    });
                                                }
                                            )}
                                    </div>
                                </div>

                                {YesNoToggle({
                                    state: {
                                        value: !isDisabledTable,
                                        onChange: (value) => {
                                            editingDs.change((ds) => ({
                                                ...ds,
                                                tables: value ? [...ds.tables, table] : ds.tables.filter((t) => t.name !== table.name),
                                                disabledTables: value ? ds.disabledTables.filter((t) => t.name !== table.name) : [...ds.disabledTables, table],
                                            }));
                                        },
                                    },
                                })}
                            </div>
                        );
                    })}
                </div>
            </>
        );
    });
