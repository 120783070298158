import * as React from "react";
import {cs} from "@common/react/chain-services";
import {NumberField} from "./number/number-field";
import {DateField} from "./date/date-field";
import {Expandable} from "../../../../../../../../../../common/expandable/expandable";
import {TextField} from "./text/text-field";
import {getPath} from "@common/utils/arr-path";

export const Configuration = ({field, getFieldInfo, constantField, notAllowNoneAggregation, hideAggregationSelect, onAddFormat, customListAgg, allowAggregations}) =>
    cs(({}) => {
        if (constantField) {
            // only show the numeric configuration for a constant value if it is numeric (like a gauge's target constant value).
            if (constantField.value?.value != null && !isNaN(constantField.value.value)) {
                return (
                    <Expandable
                        {...{
                            label: "Configuration",
                            alwaysShow: true,
                            initExpand: true,
                            render: () =>
                                NumberField({
                                    field: constantField,
                                    getFieldInfo,
                                    onAddFormat,
                                    notAllowNoneAggregation,
                                    customListAgg,
                                }),
                        }}
                    />
                );
            }
            return;
        }

        const fieldType = getFieldInfo(field.value).type;

        // with this condition being satisfied, the text field configuration has nothing to show. hence hide it.
        // a part of this logic can be found in text-field.jsx
        if (fieldType === "text" && !field.value.$type.includes("MeasureTileField") && !getPath(field.value, ["aggregationFunc"])?.toLowerCase().startsWith("count")) {
            return;
        }

        return (
            <Expandable
                {...{
                    label: "Configuration",
                    alwaysShow: true,
                    initExpand: true,
                    render: () =>
                        types[fieldType]({
                            type: fieldType,
                            field,
                            getFieldInfo,
                            onAddFormat,
                            notAllowNoneAggregation,
                            hideAggregationSelect,
                            customListAgg,
                            allowAggregations,
                        }),
                }}
            />
        );
    });

const types = {
    number: NumberField,
    date: DateField,
    text: TextField,
    bool: TextField,
};
