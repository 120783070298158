import moment from "moment";
import {formatDateTime} from "../../../../../../../common/logic/date-time/format-date-time";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {Load2} from "@common/react/load2";
import {InteractionTable} from "../common/tables/interaction-table";
import {InteractionComboChart, InteractionComboChartNoData} from "./chart/interaction-combo-chart";
import {SyncStatusIcon} from "../common/sync-status-icon/sync-status-icon";
import {formatNumber} from "@common/utils/format";
import {CacheSync} from "./ds-sync-cache";
import {createArray} from "@common/utils/collections";
import {SkeletonBox} from "../../../../common/skeleton/skeleton-box";

export const SyncSummaryInteraction = ({dateRange, tableId, next}) =>
    cs(
        consumeContext("apis"),
        consumeContext("routing"),
        [
            "tablesSummary",
            ({apis, routing}, next) => {
                const format = (d) => ({
                    year: d.get("year"),
                    month: d.get("month") + 1,
                    day: d.get("date"),
                });

                const params = {
                    start: formatDateTime({date: format(dateRange.start)}),
                    end: formatDateTime({date: format(dateRange.end)}),
                };

                return CacheSync({
                    _key: JSON.stringify(params),
                    fetch: () => apis.data.getTableHistoriesSummary(routing.params.dsId, tableId, params),
                    next,
                });
            },
        ],
        ({tablesSummary}) =>
            next({
                loading: !tablesSummary,
                renderLoading: () => {
                    const skeleton = () => SkeletonBox({style: {width: 52, height: 16}});
                    return (
                        <>
                            {InteractionComboChartNoData({})}

                            {InteractionTable({
                                list: createArray(3),
                                columns: [
                                    {
                                        label: "Date",
                                        format: (row) => (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {SkeletonBox({
                                                    style: {
                                                        width: 6,
                                                        height: 6,
                                                        borderRadius: `50%`,
                                                    },
                                                })}
                                                &nbsp;&nbsp;
                                                {skeleton()}
                                            </div>
                                        ),
                                    },
                                    {label: "Sync Duration", format: skeleton},
                                    {label: "Attempts", format: skeleton},
                                    {label: "Errors", format: skeleton},
                                    {label: "Data", format: skeleton},
                                    {label: "Records", format: skeleton},
                                ],
                            })}
                        </>
                    );
                },
                renderChart: () =>
                    cs(
                        [
                            "comboChartData",
                            (_, next) => {
                                const ret = dateRange.list.map((item) => {
                                    const syncItem = tablesSummary.synchronizations.find((s) => moment(s.date).isSame(item, "day"));
                                    if (syncItem) {
                                        return {
                                            ...syncItem,
                                            date1: syncItem.date,
                                            date: item.valueOf(),
                                        };
                                    }
                                    return {
                                        date: item.valueOf(),
                                        errors: 0,
                                        records: 0,
                                        sizeMB: 0,
                                        syncDuration: 0,
                                        attempts: 0,
                                    };
                                });
                                return next(ret);
                            },
                        ],
                        ({comboChartData}) =>
                            InteractionComboChart({
                                comboChartData,
                                _key: JSON.stringify(dateRange),
                            })
                    ),
                renderTable: ({height}) =>
                    InteractionTable({
                        className: "sync-summary-interaction-88p",
                        style: {height},
                        list: tablesSummary?.synchronizations || [],
                        isFullRow: (row) => true,
                        subColumns: [
                            {style: {maxWidth: 15}, format: () => null},
                            {className: "error", format: (row) => row.error},
                        ],
                        columns: [
                            {
                                alignCenter: true,
                                style: {
                                    display: "flex",
                                    alignItems: "center",
                                    height: 41,
                                },
                                format: (row) => {
                                    return row.synchronizations.map((item) => {
                                        const type = item === "Error" ? "error-table" : item === "Success" ? "sync" : item.toLowerCase();
                                        return <>{SyncStatusIcon({type})}</>;
                                    });
                                },
                            },
                            {
                                label: "Date",
                                format: (row) => moment(new Date(row.date)).format("MMMM D, YYYY"),
                            },
                            {
                                label: "Sync Duration",
                                format: (row) => `${formatNumber(row.syncDuration / (60 * 1000))} min`,
                                className: "sync-duration",
                                alignRight: true,
                            },
                            {
                                label: "Attempts",
                                format: (row) => row.attempts,
                                className: "attempts",
                                alignRight: true,
                            },
                            {
                                label: "Errors",
                                format: (row) => (
                                    <span
                                        style={{
                                            color: row?.errors > 0 ? "#FF5959" : "unset",
                                        }}
                                    >
                                        {row?.errors}
                                    </span>
                                ),
                                alignRight: true,
                            },
                            {
                                label: "Data",
                                format: (row) => `${formatNumber(row?.sizeMB)} MB`,
                                className: "data",
                                alignRight: true,
                            },
                            {
                                label: "Records",
                                format: (row) => formatNumber(row?.records),
                                alignRight: true,
                            },
                        ],
                        footerColumns: cs(
                            // ["getValue", (_, next) => next((prop) => tablesHistories.value?.synchronizations.reduce((prev, item) => prev += item[prop], 0))],
                            (_) => [
                                {format: () => null},
                                {format: () => `TOTALS IN PERIOD`},
                                {
                                    format: () =>
                                        `${Number((tablesSummary?.periodSyncDuration || 0) / (60 * 1000)).toLocaleString({
                                            maximumFractionDigits: 2,
                                            minimumFractionDigits: 2,
                                        })} MIN`,
                                    alignRight: true,
                                },
                                {
                                    format: () => tablesSummary?.periodAttempts,
                                    alignRight: true,
                                },
                                {
                                    format: () => tablesSummary?.periodErrors,
                                    alignRight: true,
                                },
                                {
                                    format: () => `${formatNumber(tablesSummary?.periodSizeMB)} MB`,
                                    alignRight: true,
                                },
                                {
                                    format: () => formatNumber(tablesSummary?.periodRecords),
                                    alignRight: true,
                                },
                            ]
                        ),
                    }),
            })
    );
