import React from "react";
import {cs} from "@common/react/chain-services";
import {Static2} from "@common/react/static-2";
import {DiscreteColorsTheme} from "../color-palettes/discrete-colors/discrete-colors-theme";
import {ColorSchemes} from "../color-palettes/color-schemes/color-schemes";
import {KpiColorsTheme} from "../color-palettes/kpi-colors/kpi-colors-theme";
import {OtherColorsTheme} from "../color-palettes/other-colors/other-colors-theme";
import {ThemeLeftPanelSections} from "../../../common/theme-left-panel-sections/theme-left-panel-sections";
import {TableFormatTheme} from "./format/table-format-theme";
import {TableConditionalFormatTheme} from "./conditional-format/table-conditional-format-theme";

export const TablesTheme = ({form, overrideLeftPanelRef, viewTree}) =>
    cs(
        ["ref", (_, next) => Static2({next})],
        [
            "sections",
            ({ref}, next) =>
                next([
                    {
                        label: "Table Format",
                        render: (form) => <TableFormatTheme form={form} />,
                        id: "tables",
                    },
                    {
                        label: "Conditional Format",
                        render: (form) => <TableConditionalFormatTheme overrideLeftPanelRef={ref} form={form} />,
                        id: "table-conditional-format",
                    },
                ]),
        ],
        ({sections, ref}) =>
            ThemeLeftPanelSections({
                sections,
                overrideLeftPanelRef,
                parentRef: ref,
                form,
                viewTree,
            })
    );
