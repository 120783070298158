import { cs } from "@common/react/chain-services";
import { consumeContext } from "@common/react/context";
import { Form2 } from "@common/react/cs-form/form2";
import { required } from "@common/react/cs-form/validators/required";
import { SubcribeKeyEvents } from "@common/react/keys/global-key-down";
import { UseState } from "@common/react/use-state";
import { VerbDialogBodyScrollbar } from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";
import * as React from "react";
import { Button } from "../../../../../../common/form/buttons/button/button";
import { ffToTextInput } from "../../../../../../common/form/ff-to-text-input";
import { TextInput } from "../../../../../../common/form/text-input/text-input";
import { DialogService } from "../../../common/dialog/dialog-service";

export const RenameThemeDialog = ({next: rootNext}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    render: ({resolve, args: {theme}}) => ({
                        title: "Rename Theme",
                        width: 540,
                        content: next({
                            resolve,
                            theme,
                        }),
                    }),
                    next: rootNext,
                }),
        ],
        consumeContext("apis"),
        [
            "editing",
            ({modal}, next) =>
                UseState({
                    initValue: {
                        name: modal.theme.name,
                    },
                    next,
                }),
        ],
        [
            "form",
            ({modal, editing, apis, fullCol}, next) =>
                Form2({
                    data: editing,
                    fields: {
                        name: {
                            transforms: ["trim"],
                            validators: [required],
                            debounce: true,
                            asyncValidators: [
                                {
                                    validate: (name) =>
                                        apis.collection.checkThemeName(name, {
                                            themeId: modal.theme.id,
                                        }),
                                    getMessage: () => `Existed`,
                                },
                            ],
                        },
                    },
                    onSubmit: async () => {
                        modal.resolve(editing.value.name);
                    },
                    next,
                }),
        ],
        ({modal, form}) => (
            <div className="rename-collection-dialog-zd9">
                <VerbDialogBodyScrollbar>
                    <div className="main-part">
                        {TextInput({
                            label: "Rename Theme",
                            ...ffToTextInput(form.field("name")),
                        })}
                    </div>
                </VerbDialogBodyScrollbar>
                {SubcribeKeyEvents({
                    events: [
                        {
                            keyCombo: "Enter",
                            onKeyDown: () => form.looksValid && form.submit(),
                        },
                    ]
                })}
                <div className="buttons">
                    <Button btnType="secondary" onClick={() => modal.resolve()}>
                        Cancel
                    </Button>
                    <Button disabled={!form.looksValid} onClick={form.submit}>
                        Rename
                    </Button>
                </div>
            </div>
        )
    );
