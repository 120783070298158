import {chain} from "@common/utils/fs";
import {UseState} from "@common/react/use-state";
import {cs} from "@common/react/chain-services";
import {getELoc} from "@common/react/get-e-loc";
import * as React from "react";
import {GlobalMouseUp} from "@common/react/global-mouse-up";
import {inside} from "@common/utils/rectangles";
import {GlobalMouseMove} from "@common/react/global-mouse-move";
import {spc} from "@common/react/state-path-change";
import {insertAt, removeIndex} from "@common/utils/collections";
import {IgnoreUpdate} from "@common/react/ignore-update";
import {addVector, toVector} from "@common/utils/points";
import {GlobalKeyDown} from "@common/react/keys/global-key-down";

export const DnDList = ({list, renderDrag, onChange, next}) =>
    cs(["state", (_, next) => UseState({next})], ({state}) => (
        <>
            {!state.value
                ? (() => {
                      let doms = [];
                      return cs(
                          ({}, next) => IgnoreUpdate({unless: () => true, next}),
                          () =>
                              next({
                                  dragging: false,
                                  list: list?.map((value, index) => ({
                                      value,
                                      index,
                                      ref: (dom) => {
                                          if (dom) {
                                              doms[index] = dom;
                                          }
                                      },
                                      start: (e) => {
                                          e.preventDefault();
                                          state.onChange({
                                              rects: doms.map((dom) => dom.getBoundingClientRect()),
                                              index,
                                              pos0: getELoc(e),
                                              pos1: getELoc(e),
                                          });
                                      },
                                  })),
                              })
                      );
                  })()
                : (() => {
                      const {rects, pos1, index: i0} = state.value;
                      const i1 = rects.findIndex((rect) => inside(rect, pos1));

                      return cs(
                          ({}, next) =>
                              IgnoreUpdate({
                                  props: {i1},
                                  when: (pp) => pp?.i1 === i1,
                                  next,
                              }),
                          () =>
                              next({
                                  dragging: true,
                                  list: chain(
                                      list.map((value, index) => ({
                                          value,
                                          index,
                                      })),
                                      (_) => removeIndex(i0, _),
                                      (_) => insertAt(_, {}, i1 === -1 ? i0 : i1)
                                  ),
                              })
                      );
                  })()}

            {state.value && (
                <>
                    {GlobalMouseUp({
                        fn: () => {
                            state.onChange(null);

                            const {rects, pos1, index: i0} = state.value;
                            const i1 = rects.findIndex((rect) => inside(rect, pos1));

                            if (i1 > -1 && i1 !== i0) {
                                onChange(
                                    chain(
                                        list,
                                        (_) => removeIndex(i0, _),
                                        (_) => insertAt(_, list[i0], i1)
                                    )
                                );
                            }
                        },
                    })}
                    {GlobalMouseMove({
                        fn: (e) => {
                            spc(state, ["pos1"], () => getELoc(e));
                        },
                    })}
                    {GlobalKeyDown({
                        keyCombo: "Escape",
                        onKeyDown: () => {
                            state.onChange(null);
                        },
                    })}

                    <div
                        style={{
                            position: "fixed",
                            ...chain(state.value.rects[state.value.index], (_) => ({
                                ...chain(addVector(_, toVector(state.value.pos0, state.value.pos1)), (_) => ({
                                    top: _.y,
                                    left: _.x,
                                })),
                                width: _.width,
                                height: _.height,
                            })),
                        }}
                    >
                        {cs(
                            ({}, next) => IgnoreUpdate({next}),
                            () =>
                                renderDrag({
                                    index: state.value.index,
                                    value: list[state.value.index],
                                })
                        )}
                    </div>
                </>
            )}
        </>
    ));
