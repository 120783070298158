const {writeCenterLabel} = require("./write-center-label");
const {writeComparisonCenterLabel} = require("./write-comparison-center-label");
const {adjustOutsideDataLabels} = require("./adjust-outside-data-labels");
const {adjustInsideDataLabels} = require("./adjust-inside-data-labels");
const {adjustFullyInsideDataLabels} = require("./adjust-fully-inside-data-labels");
// const {getTooltipOptions} = require("./tooltip");
const {getTooltipOptions} = require("../../factory/tooltip/tooltip-options");
const {getPlotOptions} = require("./plot-options");
const {getLegendOptions} = require("../../factory/legend");

const getPieChartOptions = ({tile, theme, size, isCompare, formatters, rawData, onClickPoint}) => {
    const chartEventsFn = (chart, calculateDataLabels = true) => {
        if (tile.$type === "DonutChartTile") {
            (isCompare ? writeComparisonCenterLabel : writeCenterLabel)({
                chart,
                theme,
                style: tile.style,
                formatters,
            });
        }

        if (calculateDataLabels && tile.style.dataLabels.show) {
            // for now: when connector lines is turned on, do not adjust data labels to slice center. let highcharts decide the labels
            if (tile.style.dataLabels.position === "OutsideAbove" && !tile.style.calloutLineShown) {
                adjustOutsideDataLabels({
                    chart,
                    allowOverlap: tile.style.dataLabels.allowOverlap,
                });
            } else if (tile.style.dataLabels.position === "InsideMiddle") {
                adjustFullyInsideDataLabels({chart, theme});
            } else if (tile.style.dataLabels.position === "InsideRight" && !tile.style.dataLabels.allowOverlap) {
                adjustInsideDataLabels({
                    chart,
                    allowOverlap: tile.style.dataLabels.allowOverlap,
                });
            }
        }
    };

    const haloSize = 7;
    // const expandFullSize = !tile.style.legendStyle?.legendShown && (!tile.style.dataLabels?.show || tile.style.dataLabels.position !== "OutsideAbove");
    const expandFullSize = !tile.style.dataLabels?.show || tile.style.dataLabels.position !== "OutsideAbove";

    const chart = {
        type: "pie",
        backgroundColor: theme.general.tile.styles.tileBackgroundColorRGB,
        events: {
            load: function () {
                chartEventsFn(this);
            },
            redraw: function () {
                chartEventsFn(this, false);
            },
        },
        zooming: {
            mouseWheel: false,
        },
        ...(expandFullSize && {
            margin: [haloSize, haloSize, haloSize, haloSize],
            spacingTop: 0,
            spacingBottom: 0,
            spacingLeft: 0,
            spacingRight: 0,
        }),
    };

    const title = {
        text: undefined,
    };

    const plotOptions = getPlotOptions({
        tile,
        theme,
        size,
        haloSize,
        expandFullSize,
        isCompare,
        formatters,
        onClickPoint,
    });

    const legend = getLegendOptions({style: tile.style.legendStyle, theme});

    const tooltip = getTooltipOptions({
        tile,
        theme,
        formatters,
        rawData,
        isCompare,
    });

    return {
        chart,
        title,
        plotOptions,
        legend,
        tooltip,
        credits: {
            enabled: false,
        },
    };
};
exports.getPieChartOptions = getPieChartOptions;
