import * as React from "react";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import "./toast-service.scss";
import {provideContext} from "@common/react/context";
import {cx} from "emotion";

/**
 * global toast. this can be used when having to show toast message after routing
 * */
export const toastService = ({next}) => cs(["toast", (_, next) => toastService1({next})], ({toast}) => provideContext("toast", toast, next));

const toastService1 = ({next}) =>
    cs(
        [
            "messages",
            (_, next) =>
                UseState({
                    initValue: [],
                    next,
                }),
        ],
        ({messages}) => (
            <>
                {next({
                    show: (content, options = {}) => {
                        const id = new Date().getTime();
                        messages.onChange([...messages.value, {id, content, ...options}]);
                        setTimeout(() => {
                            messages.change((c) => c.filter((c) => c.id !== id));
                        }, 5000);
                    },
                })}

                <div
                    className={cx("toast-service-rg3", {
                        show: messages.value.length > 0,
                    })}
                >
                    {messages.value.map((msg) => (
                        <div className="toast-item" key={msg.id} onClick={() => messages.change((c) => c.filter((c) => c.id !== msg.id))}>
                            {msg.isError ? (
                                <span className="material-icons-outlined errors">error_outline</span>
                            ) : (
                                <div className="icon">
                                    <span className="material-icons">check</span>
                                </div>
                            )}

                            {msg.content}
                        </div>
                    ))}
                </div>
            </>
        )
    );
