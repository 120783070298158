import * as React from "react";
import {cx} from "emotion";
import {cs} from "../../../react/chain-services";
import {InfoIconTooltip} from "../common/info-icon-tooltip/info-icon-tooltip";
import {TruncatingTooltip} from "../../../../web-client/src/routes/common/truncating-tooltip/truncating-tooltip";
import {getTooltipFontSizes} from "../factory/tooltip/shared-tooltip-formatter/get-tooltip-font-sizes";
import {getFontOptions} from "../table/table/common/get-font-options";
import {getFontFamilyOptions} from "../table/table/common/get-font-family-options";
import {cGetFontSize} from "../common/style-map/font-size";
import "./chart-title.scss";
import {lightOrDark} from "@common/ui-components/styles/colors";

export const ChartTitle = ({tile, theme, titleHeaderHeight, hasSwapAxis, hasOptions}) => {
    const style = tile.style || {};
    const getFontSize = cGetFontSize(theme.fontSizeGroup, theme);
    const hasInfoIcon = style.showTitleInfoIcon && style.titleInfoIconText;
    const hasTitleIcon = style.showTitleIcon; //SingleKPI

    const getSectionTileColor = () => {
        if (tile.style.backgroundStyle == "NoBackground") {
            if (lightOrDark(theme.general.canvas.backgroundColorRGB) == "dark") {
                return "#FFF";
            }
        }

        return theme.dataVisualization.sectionTile.fontColorRGB || theme.general.tile.styles.titleFontColorRGB;
    };

    const titleStyle = {
        color: tile.$type == "SectionTile" ? getSectionTileColor() : theme.general.tile.styles.titleFontColorRGB,
        ...getFontOptions(
            tile.$type == "SectionTile"
                ? theme.dataVisualization.sectionTile.fontStyle || theme.general.tile.styles.titleFontOptions
                : theme.general.tile.styles.titleFontOptions
        ),
        fontSize: `${getFontSize({
            group:
                (tile.$type === "SectionTile" ? theme.dataVisualization.sectionTile.fontSize : theme.general.tile.styles.titleFontSize) ||
                theme.general.canvas.fontSize,
            elemType: tile.$type == "SectionTile" ? "section" : "title",
        })}px`,
        ...(tile.$type == "SectionTile"
            ? {
                  fontFamily: `${theme.general.tile.styles.titleFontFamily || theme.general.canvas.fontFamily}, sans-serif`,
              }
            : getFontFamilyOptions({
                  fontFamily: theme.general.tile.styles.titleFontFamily || theme.general.canvas.fontFamily,
                  fontOption: theme.general.tile.styles.titleFontOptions,
              })),
        ...(tile.isChild
            ? {}
            : {
                  textTransform:
                      ((tile.$type == "SectionTile" ? theme.dataVisualization.sectionTile.caseStyle : null) ||
                          theme.general.tile.styles.titleCaseOptions) === "TitleCase"
                          ? "capitalize"
                          : ((tile.$type == "SectionTile" ? theme.dataVisualization.sectionTile.caseStyle : null) ||
                                theme.general.tile.styles.titleCaseOptions) === "Uppercase"
                          ? "uppercase"
                          : "",
              }),
    };

    const themeMode = theme.dataVisualization.toolTipsAndLegends.tooltipTheme.toLowerCase() || "light";

    return cs(
        [
            "truncatingTooltip",
            (_, next) =>
                TruncatingTooltip({
                    forceUpdate: true,
                    delay: 0,
                    alternateStyle: false,
                    className: `${themeMode}-theme`,
                    tooltipContentStyle: {
                        fontSize: getTooltipFontSizes(theme).small,
                        fontFamily: theme.general.canvas.fontFamily,
                        color: themeMode === "light" ? theme.general.canvas.fontColorRGB || "#546B81" : "#E2E2E2",
                    },
                    next,
                }),
        ],
        ({truncatingTooltip}) => (
            <>
                <div
                    className={cx("tile-title-6g3 tile-title verb-tile-title", {
                        "has-info-icon": hasInfoIcon,
                        "has-title-icon": hasTitleIcon,
                    })}
                    ref={truncatingTooltip.ref}
                    style={{
                        height: titleHeaderHeight,
                        lineHeight: `${titleHeaderHeight}px`,
                    }}
                >
                    {tile?.isChild && (
                        <span className="back-arrow" onClick={() => tile.goBack()}>
                            <i className="far fa-arrow-left" />
                            &nbsp;
                        </span>
                    )}

                    <span
                        {...{
                            className: "title-text",
                            style: {
                                ...titleStyle,
                                // to prevent italic text from being cut off
                                ...(titleStyle.fontStyle === "italic" && {
                                    paddingRight: 3,
                                }),
                            },
                            ...truncatingTooltip.tooltip(tile.title),
                        }}
                    >
                        {tile.title}
                    </span>
                </div>

                {hasInfoIcon &&
                    cs(() => {
                        const tooltipSize = getFontSize(
                            tile.$type === "SectionTile"
                                ? {
                                      group: theme.dataVisualization.sectionTile.fontSize,
                                      elemType: "section_title_icon",
                                  }
                                : {
                                      group: theme.general.tile.styles.titleFontSize,
                                      elemType: "title_icon",
                                  }
                        );
                        return (
                            <div
                                className={cx("info-icon", {
                                    isTruncated: truncatingTooltip.isActive,
                                })}
                                style={{
                                    background: tile.$type !== "SectionTile" ? theme.general.tile.styles.tileBackgroundColorRGB : "",
                                    lineHeight: "normal",
                                    width: tooltipSize,
                                    height: tooltipSize,
                                }}
                            >
                                {InfoIconTooltip({
                                    theme,
                                    width: tooltipSize,
                                    color: theme?.fontColorRGB,
                                    showInfoIcon: () => style.showTitleInfoIcon && style.titleInfoIconText,
                                    infoText: style.titleInfoIconText,
                                })}
                            </div>
                        );
                    })}
                {hasTitleIcon && <div className={cx("icons-placeholder title-icon")} />}
                {(hasOptions || hasSwapAxis?.value) && (
                    <div
                        className={cx("icons-placeholder", {
                            fullWidth: hasOptions && hasSwapAxis?.value,
                        })}
                    />
                )}
            </>
        )
    );
};
