import * as React from "react";
import {ShowHideToggle} from "../../../../../../../../../../../../../common/form/toggles/show-hide-toggle";
import "./legend.scss";
import {scope} from "@common/react/scope";
import {LegendPositionSelect} from "../../../common/legend-position-select";
import {FontSizeSelect} from "../../../common/font-size-select";
import {LegendDisplaySelect} from "../../../common/legend-display-select";
import {LegendSizeSelect} from "../../../common/legend-size-select";
import {getHiddenPositionFields} from "../../../combo-chart/panels/legend/legend-display-rules";
import {MobileWarning} from "../../../common/mobile-warning/mobile-warning";
import {HiddenFieldAlert} from "../../../common/hidden-field-alert/hidden-field-alert";

export const Legend = ({tile, size}) => ({
    label: (
        <div className="pie-legend-label-87r">
            Legend
            {MobileWarning({
                info: "Left & Right Vertical legends cannot display on small mobile devices. If your chart is at least 4 squares high, the legend will be shown as horizontal.",
                tooltipBoxWidth: 160,
            })}
        </div>
    ),
    control:
        getHiddenPositionFields(size).length < 6
            ? ShowHideToggle({
                  state: scope(tile, ["style", "legendStyle", "legendShown"]),
              })
            : HiddenFieldAlert({
                  info: "Legend can only be shown when the chart is at least 12 squares wide and 2 squares high or 6 squares wide and 4 squares high.",
              }),
    render: () => (
        <div className="pie-chart-legend-42f">
            <div className="position-select">
                {LegendPositionSelect(scope(tile, ["style", "legendStyle", "legendPosition"]), size)}
                {LegendSizeSelect(scope(tile, ["style", "legendStyle", "legendSize"]), tile.value.style.legendStyle)}
                {LegendDisplaySelect(scope(tile, ["style", "legendStyle", "legendContent"]), tile.value.style.legendStyle)}
                {/* {FontSizeSelect(scope(tile, ["style", "legendStyle", "legendFontSize"]))} */}
            </div>
        </div>
    ),
});
