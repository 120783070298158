const dimAxisLabelSeriesTooltip = {
    show: (e, context) => {
        const {pos, value, chart} = context;
        // manually prepare hoverPoints for chart tooltip
        const hoverPoints = chart.series.map((series) => !series.userOptions.isCompare && series.points.find((p) => [pos, value].includes(p.category))).filter((v) => v);
        if (hoverPoints?.length > 0) {
            chart.hoverPoint = hoverPoints[0];
            chart.hoverPoints = hoverPoints;
            // show tooltip
            chart.tooltip.refresh(chart.hoverPoints);
            // draw crosshair for tooltip connector
            chart.xAxis[0].drawCrosshair(e, chart.hoverPoint);
        }
    },
    close: (context) => {
        context.chart.tooltip.hide();
        context.chart.tooltip.manuallyHide?.();
    },
};
exports.dimAxisLabelSeriesTooltip = dimAxisLabelSeriesTooltip;
