import {avg, sort, sum, unique} from "../../../../../common/utils/collections";
import {generateAnchorPosition, getNextPos, getRandomPosition, isCircleIntersecting} from "./model-panel-helper";
import {minBy} from "../../../../../common/utils/math-util";

const isAvailableRelationship = ({source, target, tables}) => {
    let currentTable = tables.find((t) => t.columns.find((c) => c.id == source));
    let targetTable = tables.find((t) => t.columns.find((c) => c.id == target));
    return targetTable && currentTable.id != targetTable.id;
};

const tryGenerateNewPosIfIntersecting = ({pos, getNextPos, generatedPositionsTableIds, circlePos, index}) => {
    for (let position of generatedPositionsTableIds) {
        if (isCircleIntersecting(pos, position)) {
            const updatedPos = getNextPos({currentPos: pos, circlePos, index});

            return tryGenerateNewPosIfIntersecting({
                pos: updatedPos,
                getNextPos,
                generatedPositionsTableIds,
                circlePos,
                index: index + 1,
            });
        }
    }

    return pos;
};

const generateTablePos = (index, circlePos, side, generatedPositionsTableIds) => {
    if (side == "right") {
        let pos = {x: circlePos.x + 180, y: circlePos.y + index};
        return tryGenerateNewPosIfIntersecting({
            pos,
            getNextPos: ({currentPos, circlePos, index}) => ({
                x: circlePos.x + 180,
                y: currentPos.y + index,
            }),
            generatedPositionsTableIds,
            circlePos,
            index,
        });
    }

    if (side == "left") {
        let pos = {x: circlePos.x - 180, y: circlePos.y + index};

        return tryGenerateNewPosIfIntersecting({
            pos,
            getNextPos: ({currentPos, circlePos, index}) => ({
                x: circlePos.x - 180,
                y: currentPos.y + index,
            }),
            generatedPositionsTableIds,
            circlePos,
            index,
        });
    }

    if (side == "top") {
        let pos = {x: circlePos.x + index, y: circlePos.y - 180};
        return tryGenerateNewPosIfIntersecting({
            pos,
            getNextPos: ({currentPos, circlePos, index}) => ({
                x: currentPos.x + index,
                y: circlePos.y - 180,
            }),
            generatedPositionsTableIds,
            circlePos,
            index,
        });
    }

    if (side == "bottom") {
        let pos = {x: circlePos.x + index, y: circlePos.y + 180};
        return tryGenerateNewPosIfIntersecting({
            pos,
            getNextPos: ({currentPos, circlePos, index}) => ({
                x: currentPos.x + index,
                y: circlePos.y + 180,
            }),
            generatedPositionsTableIds,
            circlePos,
            index,
        });
    }
};

export const getSide = (side) => {
    const sideObj = {
        top: "bottom",
        left: "right",
        right: "left",
        bottom: "top",
    };

    return sideObj[side];
};

const groupTablesToColumns = ({columns, fixedSide, generatedPositionsTableIds}) => {
    let ret = [
        {side: "left", tables: []},
        {side: "right", tables: []},
        {side: "bottom", tables: []},
        {side: "top", tables: []},
    ];

    if (fixedSide) {
        for (let column of columns) {
            let tableFound = generatedPositionsTableIds.find((t) => column.relatedTables.map((t) => t.id).indexOf(t.id) > -1);
            let side = tableFound ? getSide(tableFound.side || fixedSide) : fixedSide;
            ret = ret.map((item) =>
                item.side == side
                    ? {
                          ...item,
                          tables: item.tables.concat(column.relatedTables),
                      }
                    : item
            );
        }

        return ret;
    }

    for (let i = 0; i < columns.length; i++) {
        let column = columns[i];
        ret[i % 4].tables = ret[i % 4].tables.concat(column.relatedTables);
    }

    return ret;
};

const generateRelatedTables = ({table, generatedPositionsTableIds, latestCenterPoint, sortedTables, fixedSide}) => {
    const groupedColumns = groupTablesToColumns({
        columns: table.relationshipColumns,
        fixedSide,
        generatedPositionsTableIds,
    });

    let generatedTables = [];

    for (let side of groupedColumns) {
        let posIndex = 0 - side.tables.length / 2 + 1;

        for (let i = 0; i < side.tables.length; i++) {
            let table = side.tables[i];
            let posFound = generatedPositionsTableIds.find((t) => t.id == table.id);

            if (!posFound) {
                let tableInfo = {
                    id: table.id,
                    name: table.name,
                    ...generateTablePos(posIndex * Math.max(Math.min(150, side.tables.length * 20), 80), latestCenterPoint, side.side, generatedPositionsTableIds),
                    side: side.side,
                };

                generatedPositionsTableIds.push(tableInfo);

                generatedTables.push(tableInfo);
            }

            posIndex++;
        }
    }

    if (generatedTables.length > 0) {
        for (let t of generatedTables) {
            let table = sortedTables.find((s) => s.id == t.id);
            generateRelatedTables({
                table,
                generatedPositionsTableIds,
                latestCenterPoint: {x: t.x, y: t.y},
                sortedTables,
                fixedSide: t.side,
            });
        }
    }
};

export const autoOrganizeModel = ({tables, relationships, container}) => {
    const liteTables = tables.map((table) => {
        let relationshipColumns = table.columns
            .filter((column) => {
                let r1 = relationships.filter((r) => r.leftColumnID == column.id);
                for (let r of r1) {
                    if (
                        isAvailableRelationship({
                            source: column.id,
                            target: r.rightColumnID,
                            tables,
                        })
                    )
                        return true;
                }

                let r2 = relationships.filter((r) => r.rightColumnID == column.id);
                for (let r of r2) {
                    if (
                        isAvailableRelationship({
                            source: column.id,
                            target: r.leftColumnID,
                            tables,
                        })
                    )
                        return true;
                }

                return false;
            })
            .map((column) => {
                const relatedRelationships = relationships.filter((r) => r.leftColumnID == column.id || r.rightColumnID == column.id);
                return {
                    columnID: column.id,
                    columnName: column.name,
                    relatedTables: tables
                        .filter((t) => t.columns.find((c) => relatedRelationships.find((r) => r.leftColumnID == c.id || r.rightColumnID == c.id)) && t.id != table.id)
                        .map((t) => ({id: t.id, name: t.visualName})),
                };
            });

        return {
            id: table.id,
            name: table.visualName,
            relationshipColumns: relationshipColumns,
        };
    });

    const sortedTables = sort(liteTables, (table) => -sum(table.relationshipColumns, (column) => column.relatedTables.length));

    let generatedPositionsTableIds = [];
    let latestCenterPoint = {x: container.width / 2, y: container.height / 2};

    let tablesWithRelationships = sortedTables.filter((t) => t.relationshipColumns.length > 0);
    let tablesWithoutRelationships = sortedTables.filter((t) => t.relationshipColumns.length == 0);

    for (let table of tablesWithRelationships) {
        if (!generatedPositionsTableIds.find((t) => t.id == table.id)) {
            generatedPositionsTableIds.push({
                id: table.id,
                name: table.name,
                ...latestCenterPoint,
            });

            generateRelatedTables({
                table,
                generatedPositionsTableIds,
                latestCenterPoint,
                sortedTables,
            });

            latestCenterPoint = getRandomPosition({
                currentPositions: generatedPositionsTableIds,
                height: sortedTables.length * 20,
                scale: 1,
                transform: {x: 120, y: 120},
            });
        }
    }

    let withoutRelationshipsTablePoints = {
        x: generatedPositionsTableIds.length == 0 ? 150 : minBy(generatedPositionsTableIds, (table) => table.x) - 150,
        y: container.height / 2,
    };
    let numberOfTableEachColumn = Math.max(Math.floor(tablesWithoutRelationships.length / 3), 1);

    for (let i = 0; i < numberOfTableEachColumn; i++) {
        let tables = tablesWithoutRelationships.slice(i * 3, i * 3 + 3);

        for (let j = 0; j < tables.length; j++) {
            let table = tables[j];
            generatedPositionsTableIds.push({
                id: table.id,
                name: table.name,
                x: withoutRelationshipsTablePoints.x - i * 150,
                y: withoutRelationshipsTablePoints.y + j * 150,
            });
        }
    }

    return tables.map((table) => ({
        ...table,
        position: generatedPositionsTableIds.find((t) => t.id == table.id) || table.position,
    }));
};
