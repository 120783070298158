const {cs} = require("../../react/chain-services");
const {Static2} = require("../../react/static-2");
const {fragments} = require("../../react/fragments");
const {keyed} = require("../../react/keyed");
const {OnUnmounted} = require("../../react/on-unmounted");
const {Watch} = require("@common/react/watch");

const AutoRecreateStatic = ({create, _key, destroy, next}) =>
    cs(
        [
            "ref",
            ({}, next) =>
                Static2({
                    getInitValue: create,
                    next,
                }),
        ],
        ({ref}) => {
            const value = ref.get() ?? ref.recreate();
            return fragments(
                next(value),
                cs(keyed(_key), () =>
                    OnUnmounted({
                        action: () => {
                            destroy(value);
                        },
                    })
                ),
                Watch({
                    value: _key,
                    onChanged: () => {
                        ref.set(create());
                    },
                })
            );
        }
    );
exports.AutoRecreateStatic = AutoRecreateStatic;
