import React from "react";

const EncryptedIcon = ({className, fill = "#919EB0"}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path
            id="Encrypted_Icon"
            data-name="Encrypted Icon"
            d="M-1980-2650a12,12,0,0,1,12-12,12,12,0,0,1,12,12,12,12,0,0,1-12,12A12,12,0,0,1-1980-2650Zm1.579,0A10.434,10.434,0,0,0-1968-2639.578a10.433,10.433,0,0,0,10.42-10.421,10.433,10.433,0,0,0-10.42-10.422A10.433,10.433,0,0,0-1978.421-2650Zm5.368,5.684a.635.635,0,0,1-.447-.186.632.632,0,0,1-.184-.447v-7.578a.631.631,0,0,1,.184-.447.633.633,0,0,1,.447-.185h1.263v-.631a3.792,3.792,0,0,1,1.11-2.681,3.794,3.794,0,0,1,2.681-1.11,3.792,3.792,0,0,1,2.679,1.11,3.792,3.792,0,0,1,1.11,2.681v.631h1.263a.633.633,0,0,1,.447.185.631.631,0,0,1,.185.447v7.578a.631.631,0,0,1-.185.447.634.634,0,0,1-.447.186Zm.632-1.263h8.841v-6.316h-8.841Zm2.634-10a2.526,2.526,0,0,0-.74,1.787v.631h5.053v-.631a2.525,2.525,0,0,0-.74-1.787,2.526,2.526,0,0,0-1.786-.74A2.526,2.526,0,0,0-1969.786-2655.576Zm3.681,7.471v-1.264h1.263v1.264Zm-2.526,0v-1.264h1.263v1.264Zm-2.526,0v-1.264h1.263v1.264Z"
            transform="translate(1980 2662)"
        />
    </svg>
);

export default EncryptedIcon;
