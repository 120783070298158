import {cs} from "@common/react/chain-services";
import {StyledClassParentDom} from "@common/react/styled-class";
import {UseState} from "@common/react/use-state";
import {ObserveWindowWidth} from "@common/ui-components/live/live-dashboard/live-filters/layout/observe-window-width";
import React from "react";
import {isDev} from "../../../tools/dev/is-dev";
import {loadGApi} from "../common/gapi/gapi-root";
import {MobileBlockView} from "../routes/common/mobile/mobile-block-view";
import {loadAuthRoutes} from "./auth/load-auth-routes";
import {loadPreviewCollectionRoute} from "./auth/load-preview-collection-route";
import {loadGuestRoutes} from "./guest/load-guest-routes";
import {loadAuth} from "./loaders/load-auth";
import {loadFullStory} from "./loaders/load-fullstory";
import {loadIntercom} from "./loaders/load-intercom";
import "./verb-web.scss";
import {loginSso} from "../apis/user-apis/login-sso";
import {loadAuthSso} from "./loaders/load-auth-sso";

export const VerbWeb = () =>
    cs(
        // ({}) => TestSignalR({}),
        //using iframe/preview to preview collection

        ({}, next) =>
            StyledClassParentDom({
                next,
            }),
        ["isPreviewIframe", (_, next) => next(window.location.hash.includes("iframe/preview"))],
        [
            "windowSize",
            (_, next) =>
                ObserveWindowWidth({
                    getSize: (vw) => (vw < 768 ? "mobile" : "desktop"),
                    next,
                }),
        ],
        ({windowSize, isPreviewIframe}, next) => {
            if (windowSize === "mobile" && !isPreviewIframe) return MobileBlockView({});
            return next();
        },
        (_, next) => loadAuthSso({next}),
        ["auth", ({isPreviewIframe}, next) => loadAuth({isPreviewIframe, next})],

        ({auth, isPreviewIframe}, next) => {
            return isPreviewIframe ? (auth.user && auth.access ? loadPreviewCollectionRoute() : null) : next();
        },

        // [""fullstory", ({auth}, next) => (!isDev() ? loadFullStory({next, auth}) : next())],
        // ({}, next) => loadGa({app: "verb", next}),

        ({}, next) => (!isDev() ? loadGApi({next}) : next()),
        // ({auth, fullstory}, next) => !isDev() ? loadIntercom({next, auth, fullstory}) : next(),
        // ({auth, fullstory}, next) => loadIntercom({next, auth, fullstory}),

        ["intendedRoute", (_, next) => UseState({next})],
        ({auth, intendedRoute}) => (auth.user && auth.access ? loadAuthRoutes({intendedRoute}) : loadGuestRoutes({intendedRoute}))
    );
