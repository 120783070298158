import * as React from "react";
import {cx} from "emotion";
import {filterIcons} from "../filters-in-collection";
import {FixedPopupMenu} from "../../../../../../../common/fixed-popup-menu/fixed-popup-menu";
import {ExpandableText} from "../../../../../../../common/expandable/expandable";
import {consumeContext} from "@common/react/context";
import {spc} from "@common/react/state-path-change";
import {ConfirmDialog} from "../../../../../../../common/confirm-dialog/confirm-dialog";
import {FieldItem} from "../../../../../common/field-item/field-item";
import {cs} from "@common/react/chain-services";
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import {AlertIcon} from "@common/ui-components/icons/global-icons";
import {UseState} from "@common/react/use-state";
import {CollectionErrorBadgeWithTooltip} from "@common/ui-components/live/live-dashboard/common/collection-error-badge/collection-error-badge-with-tooltip";

const getFilterLabels = (collection) => ({
    Visible: `${collection.filterStyle.wrapLines ? `Always ` : ``} Visible`,
    BehindMore: "Behind More button",
    Hidden: "Hidden Filters",
});

export const DndFilters = ({filtersGroup, collection}) =>
    cs(
        consumeContext("routing"),
        [
            "renderFilter",
            ({routing}, next) =>
                next(({value, dragging, provided}) =>
                    cs(
                        [
                            "confirmDeleteDialog",
                            (_, next) =>
                                ConfirmDialog({
                                    next,
                                    title: "Delete Filter?",
                                    body: `Are you sure that you want to delete this filter?`,
                                }),
                        ],
                        [
                            "popupMenu",
                            ({confirmDeleteDialog}, next) =>
                                FixedPopupMenu({
                                    next,
                                    getCommands: ({filter}) => [
                                        {
                                            label: "Edit",
                                            onClick: () => routing.goto("edit-filter", {filterId: filter.id}),
                                        },
                                        {
                                            label: "Delete Filter",
                                            className: "delete-action",
                                            onClick: () => {
                                                confirmDeleteDialog.show({
                                                    confirmClick: () =>
                                                        spc(collection, ["filters"], (filters) =>
                                                            filters.filter((f) => f.id !== filter.id)
                                                        ),
                                                });
                                            },
                                        },
                                    ],
                                }),
                        ],
                        ({popupMenu}) => {
                            const hasError = collection.value.tileModelErrors?.find((e) => e.filterID == value.id);

                            return (
                                <FieldItem
                                    onClick={() => routing.goto("edit-filter", {filterId: value.id})}
                                    provided={provided}
                                    dragging={dragging}
                                    className={hasError && "has-error"}
                                >
                                    <div className="text">
                                        <img src={filterIcons[value.$type]} />
                                        <div className="title">{value.label}</div>
                                    </div>

                                    {hasError && (
                                        <CollectionErrorBadgeWithTooltip error={hasError} title="Broken Filter" className="alert-icon" />
                                    )}

                                    {popupMenu.render({params: {filter: value}})}
                                </FieldItem>
                            );
                        }
                    )
                ),
        ],
        ({renderFilter}, next) => {
            if (collection.value.filterStyle.display === "SDK") {
                return (
                    <div className="filters-collection-types">
                        <div className="filter-type">
                            {collection.value.filters.map((filter) => renderFilter({key: filter.id, value: filter}))}
                        </div>
                    </div>
                );
            }
            return next();
        },
        ["dragging", (_, next) => UseState({next, initValue: false})],
        ({renderFilter, dragging}) => {
            return (
                <div className="filters-collection-types">
                    <DragDropContext
                        onBeforeDragStart={() => {
                            dragging.onChange(true);
                        }}
                        onDragEnd={(result) => {
                            dragging.onChange(false);
                            const {destination, source, draggableId} = result;

                            if (!destination) {
                                return;
                            }

                            if (destination.droppableId === source.droppableId && destination.index === source.index) {
                                return;
                            }

                            const start = filtersGroup.value[source.droppableId];
                            const finish = filtersGroup.value[destination.droppableId];

                            if (source.droppableId === destination.droppableId) {
                                const newFields = Array.from(start);

                                let newField = start.find((s) => s.id == draggableId);

                                newFields.splice(source.index, 1);
                                newFields.splice(destination.index, 0, newField);

                                const updated = {
                                    ...filtersGroup.value,
                                    [source.droppableId]: newFields,
                                };

                                filtersGroup.onChange(updated);
                                return;
                            }

                            const startFields = Array.from(start);
                            startFields.splice(source.index, 1);

                            const newStart = [...startFields];

                            const finishedFields = Array.from(finish);

                            let newField = start.find((s) => s.id == draggableId);
                            finishedFields.splice(destination.index, 0, newField);
                            const newFinish = [...finishedFields];
                            let updated = {
                                ...filtersGroup.value,
                                [source.droppableId]: newStart,
                                [destination.droppableId]: newFinish,
                            };

                            filtersGroup.onChange(updated);
                        }}
                    >
                        {Object.keys(filtersGroup.value).map((key, index) => (
                            <Droppable droppableId={key} type="allow-droppable-each-other" key={index}>
                                {(provided, snapshot) => {
                                    const item = filtersGroup.value[key];

                                    return (
                                        <div className="filter-type" key={index} {...provided.droppableProps} ref={provided.innerRef}>
                                            <ExpandableText
                                                initExpand
                                                text={<div className="label">{getFilterLabels(collection.value)[key]}</div>}
                                                render={() => (
                                                    <div className="list">
                                                        {item.length > 0 ? (
                                                            <>
                                                                {item.map((filter, index) => {
                                                                    return (
                                                                        <Draggable key={filter.id} draggableId={filter.id} index={index}>
                                                                            {(provided) =>
                                                                                renderFilter({
                                                                                    value: filter,
                                                                                    provided,
                                                                                    dragging: dragging.value,
                                                                                    key: filter.id,
                                                                                })
                                                                            }
                                                                        </Draggable>
                                                                    );
                                                                })}
                                                            </>
                                                        ) : !snapshot.isDraggingOver ? (
                                                            <div className={cx("placeholder")}>Drag a filter here.</div>
                                                        ) : null}

                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            />
                                        </div>
                                    );
                                }}
                            </Droppable>
                        ))}
                    </DragDropContext>
                </div>
            );
        }
    );
