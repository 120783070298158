import * as React from "react";
import {cs} from "@common/react/chain-services";
import "./number-field.scss";
import {NumberAggregationFunctionSelect} from "../../../../../../../../../../common/number-aggregation-function-select/number-aggregation-function-select";
import {scope} from "@common/react/scope";
import {SelectNumberFormat} from "./select-number-format";
import {FieldSection} from "../../../field-section/field-section";
import {FieldInfoProvider} from "../../../../../../tile-tab/chart-types/common/field-info-provider/field-info-provider";
import {createNumericFormatter} from "@common/ui-components/charts/common/formatters/numeric/numeric-formatter";
import {chain} from "@common/utils/fs";
import {roundTo} from "@common/ui-components/charts/common/formatters/numeric/round-to";
import {NumberFieldPreview} from "./number-field-preview";

export const NumberField = ({field, getFieldInfo, onAddFormat, notAllowNoneAggregation, hideAggregationSelect, customListAgg}) =>
    cs(({}) => {
        // const numericProperties = field.value.numericProperties;

        // const numericFormatter = createNumericFormatter({
        //     displayType: numericProperties?.displayType || "Number",
        //
        //     decimalSeperator: numericProperties?.decimalSeperator || "Dot",
        //     thousandsSeperator: numericProperties?.thousandsSeperator || "Comma",
        //     decimalPlaces: numericProperties?.decimalPlaces ?? ((field.value.aggregationFunc ?? "").startsWith("Count") ? 0 : 2),
        //
        //     negativeFormat: numericProperties?.negativeFormat || "Parentheses",
        //
        //     currencyProperties: {
        //         currency: numericProperties?.currencyProperties?.currency || "USD",
        //         currencySymbolPlacement: numericProperties?.currencyProperties?.currencySymbolPlacement || "Left",
        //         currencySymbol: numericProperties?.currencyProperties?.currencySymbol || "$",
        //         spaceBetweenSymbolAndNumber: numericProperties?.currencyProperties?.spaceBetweenSymbolAndNumber,
        //     },
        // });

        // const formatter = (v) => chain(
        //     v,
        //     (v) => numericProperties?.displayType === "Percentage" ? v * 100 : v,
        //     (_) => numericProperties?.decimalPlaces != null ? roundTo(_, numericProperties.decimalPlaces) : roundTo(_, 2),
        //     (_) => numericFormatter(_),
        // );

        const notAllowNone = notAllowNoneAggregation || ["MeasureTileField", "DimensionlessMeasureTileField"].includes(field.value.$type);
        return (
            <>
                {NumberFieldPreview({field})}

                {FieldSection({
                    header: FieldInfoProvider({
                        field: <div className="text">FORMATTING</div>,
                    }),
                    content: (
                        <div className="number-field-24v">
                            {!hideAggregationSelect &&
                                field.value.$type &&
                                field.value.$type.includes("MeasureTileField") &&
                                getFieldInfo(field.value).$type !== "AggregatedMeasureModelColumn" &&
                                NumberAggregationFunctionSelect({
                                    state: scope(field, ["aggregationFunc"]),
                                    notAllowNoneAggregation: notAllowNone,
                                    customListAgg,
                                })}

                            {SelectNumberFormat({field, onAddFormat})}
                        </div>
                    ),
                })}
            </>
        );
    });
