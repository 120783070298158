const {required} = require("@common/react/cs-form/validators/required");

exports.comboChartFormConfig = {
    fields: {
        xAxisField: {
            inGroups: ["fields"],
            validators: [
                // {
                //     validate: (v) => v!=null,
                // },
            ],
        },
        yAxisBarFields: {
            inGroups: ["fields"],
            validators: [
                // {
                //     validate: (v) => !!v?.length,
                // },
            ],
        },
        yAxisLineFields: {
            inGroups: ["fields"],
            validators: [
                // {
                //     validate: (v) => !!v?.length,
                // },
            ],
        },
        // "groupField": {
        //     inGroups: ["fields"],
        //     validators: [
        //         {
        //             when: (tile) => tile.yAxisFields && Math.max(...tile.yAxisFields.map((yaf) => yaf.measureFields.length)) > 1,
        //             validate: (groupField) => groupField == null,
        //             getMessage: () => "Group field must be empty when more than 1 y-axis fields are selected",
        //         },
        //     ],
        // },
        title: {
            transforms: ["trim"],
            validators: [required],
            debounce: true,
        },
    },
};
