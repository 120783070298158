import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {Static2} from "@common/react/static-2";
import {CanvasTheme} from "../general/canvas/canvas-theme";
import {TilesThemeTheme} from "../general/tiles/tiles-theme-theme";
import {ButtonsTheme} from "../general/buttons/buttons-theme";
import {ComponentAndInputTheme} from "../general/component-and-input/component-and-input-theme";
import {LeftPanelClosable} from "../../../../common/left-panel-closable/left-panel-closable";
import {ColorPalettesTheme} from "./color-palettes/color-palettes.theme";
import {TablesTheme} from "./tables/tables-theme";
import {MapsTheme} from "./maps/maps-theme";
import {VisualizationFontsTheme} from "./fonts/visualization-fonts-theme";
import {TooltipsAndLegendsTheme} from "./tooltips-and-legends/tooltips-and-legends-theme";
import {AxisChartsTheme} from "./axis-charts/axis-charts-theme";
import {PieAndGaugesTheme} from "./pie-and-gauges/pie-and-gauges-theme";
import {SectionTitleText} from "./section-title-text/section-title-text";

export const DataVizTab = ({form, next, viewTree}) =>
    cs(
        ["selected", (_, next) => UseState({next, initValue: false})],
        ["panelOverride", (_, next) => UseState({next, initValue: null})],
        ["overrideLeftPanelRef", (_, next) => Static2({next})],
        [
            "sections",
            ({overrideLeftPanelRef}, next) =>
                next([
                    {
                        label: "Data Color Palettes",
                        render: (form) =>
                            ColorPalettesTheme({
                                form,
                                overrideLeftPanelRef,
                                viewTree,
                            }),
                        id: "data-color-palettes",
                    },
                    {
                        label: "Visualization Fonts",
                        render: (form) => VisualizationFontsTheme({form}),
                        id: "visualization-fonts",
                    },
                    {
                        label: "Tooltips & Legends",
                        render: (form) => TooltipsAndLegendsTheme({form}),
                        id: "tooltips",
                    },
                    {
                        label: "Gauges",
                        render: (form) => PieAndGaugesTheme({form, key: "gauges"}),
                        id: "gauges",
                    },
                    {
                        label: "Pie/Donut",
                        render: (form) => PieAndGaugesTheme({form, key: "pieAndDonutCharts"}),
                        id: "pieAndDonutCharts",
                    },
                    {
                        label: "Axis Charts",
                        render: (form) => AxisChartsTheme({form}),
                        id: "axis-charts",
                    },
                    {
                        label: "Tables",
                        render: (form) => TablesTheme({form, overrideLeftPanelRef, viewTree}),
                        id: "tables",
                    },
                    {
                        label: "Maps",
                        render: (form) => MapsTheme({form}),
                        id: "maps",
                    },
                    {
                        label: "Section Title / Text",
                        render: (form) => SectionTitleText({form}),
                        id: "section-text-tile",
                    },
                ]),
        ],
        ({sections, selected, panelOverride, overrideLeftPanelRef}) =>
            next({
                renderLeftPanel: () =>
                    sections.map((section, index) => (
                        <div
                            className="section-item"
                            key={index}
                            onClick={() => {
                                panelOverride.onChange(section);
                                setTimeout(() => {
                                    selected.onChange(section);
                                });
                                viewTree.change((v) => v.concat(section.id));
                            }}
                        >
                            {section.label}

                            <img src={require("../../common/icons/arrow-right.svg")} className="arrow-right" />
                        </div>
                    )),
                panelOverride: {
                    active: selected.value,
                    component: LeftPanelClosable({
                        onClose: () => {
                            selected.onChange(null);
                            viewTree.change((v) => v.splice(0, v.length - 1));
                        },
                        label: (panelOverride.value?.headerTitle || panelOverride.value?.label || "").toUpperCase(),
                        content: panelOverride.value?.render(form),
                        overrideLeftPanelRef,
                    }),
                },
            })
    );
