export const getFontFamilyOptions = ({fontFamily, fontOption}) => {
    const family = fontFamily.replace(/\s/g, '');

    const fontWeight = (() => {
        const weightSets = !fontOption.includes("Bold") ? unboldFontWeights : boldFontWeights;
        return fontWeights[family][weightSets[family]];
    })();

    return {
        fontFamily: `${family}, sans-serif`,
        fontWeight,
    };
};

// AB#3026
const unboldFontWeights = {
    Roboto: "medium",
    Montserrat: "medium",
    Lato: "bold",
    OpenSans: "semiBold",
};

const boldFontWeights = {
    Roboto: "bold",
    Montserrat: "bold",
    Lato: "black",
    OpenSans: "bold",
};

// src. https://fonts.google.com/
const fontWeights = {
    Roboto: {
        thin: 100,
        light: 300,
        regular: 400,
        medium: 500,
        bold: 700,
        black: 900,
    },
    Montserrat: {
        thin: 100,
        extraLight: 200,
        light: 300,
        regular: 400,
        medium: 500,
        semiBold: 600,
        bold: 700,
        extraBold: 800,
        black: 900,
    },
    Lato: {
        thin: 100,
        light: 300,
        regular: 400,
        bold: 700,
        black: 900,
    },
    OpenSans: {
        light: 300,
        regular: 400,
        medium: 500,
        semiBold: 600,
        bold: 700,
        extraBold: 800,
    },
};
