import * as React from "react";
import {cs} from "@common/react/chain-services";
import {ShowHideToggle} from "../../../../../../../../../../../../../common/form/toggles/show-hide-toggle";
import "./y-axis.scss";
import {LpLine} from "../../../../../../../../../../common/left-panel/line/lp-line";
import {scope} from "@common/react/scope";
import {OrientationSelect} from "../../../common/orientation-select";
import {AxisRangeSelect} from "../../../common/axis-range-select";
import {GridStepTypeSelect} from "../../../common/grid-step-type-select";
import {AxisLocationSelect} from "../../../common/axis-location-select";
import {AxisTitle} from "../../../combo-chart/panels/x-axis/x-axis-rules-comp";
import {YAxisLabel, YGridStepLabel} from "../../../horizontal-bar/panels/y-axis/y-axis-rules-comp";
import {getPath} from "@common/utils/arr-path";
import {AxisScaleSelect} from "../../../common/axis-scale-select";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {stateToSelect} from "@common/ui-components/form/state-to-select";

export const YAxis = ({tile, size}) => ({
    label: "Y-Axis",
    render: () => (
        <div className="scatter-plot-y-axis-3sf">
            <div className="subsection">
                {YAxisLabel({
                    tile,
                    size,
                    state: scope(tile, ["style", "yAxis", "labelShown"]),
                })}

                {getPath(tile.value, ["style", "yAxis", "labelShown"]) && (
                    <div className="content">
                        {AxisTitle({
                            tile,
                            size,
                            state: scope(tile, ["style", "yAxis", "label"]),
                        })}
                    </div>
                )}
            </div>
            <div className="subsection">
                <div className="header">Range</div>
                <div className="content">{AxisRangeSelect(scope(tile, ["style", "yAxis"]))}</div>
            </div>

            <div className="subsection">
                <div className="header">Grid Step</div>
                <div className="content">
                    {GridStepTypeSelect({
                        state: scope(tile, ["style", "yAxis"]),
                        fieldType: "number",
                    })}
                </div>
            </div>

            <div className="subsection">
                {YGridStepLabel({
                    tile,
                    size,
                    state: scope(tile, ["style", "yAxis", "gridStepLabelsShown"]),
                })}

                {getPath(tile.value, ["style", "yAxis", "gridStepLabelsShown"]) && (
                    <div className="content">{OrientationSelect(scope(tile, ["style", "yAxis", "gridStepLabelOrientation"]))}</div>
                )}
            </div>

            <div className="subsection">
                <div className="header">Other Options</div>
                <div className="content">
                    {AxisLocationSelect(scope(tile, ["style", "yAxis", "location"]))}

                    {LpLine({
                        label: "Axis Line",
                        control: ShowHideToggle({
                            state: scope(tile, ["style", "yAxis", "lineShown"]),
                        }),
                    })}

                    {LpLine({
                        label: "Grid Lines",
                        control: ShowHideToggle({
                            state: scope(tile, ["style", "yAxis", "gridLinesShown"]),
                        }),
                    })}

                    {AxisScaleSelect(scope(tile, ["style", "yAxis", "logarithmic"]))}

                    {(() => {
                        const state = scope(tile, ["style", "yAxis", "axisDirection"]);
                        return DropdownSelect({
                            registryRender: true,
                            label: "Axis Direction",
                            list: [
                                {label: "Min to Max", value: "MinToMax"},
                                {label: "Max to Min", value: "MaxToMin"},
                            ],
                            ...stateToSelect(state, ["value"]),
                            valueToLabel: (v) => v.label,
                        });
                    })()}
                </div>
            </div>
        </div>
    ),
});
