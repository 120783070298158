import "./edit-collection-layout.scss";

import React from "react";
import {cx} from "emotion";

import {keyed} from "@common/react/keyed";
import {Invoke} from "@common/react/invoke";
import {Static2} from "@common/react/static-2";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {SuspendUpdate} from "@common/react/suspend-update";
import {consumeContext, provideContext} from "@common/react/context";

import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";
import {LoadingIndicator} from "@common/ui-components/loading-indicator/loading-indicator";

import {Badge} from "../../../common/badge/badge";
import {HeaderBar} from "../../../common/header-bar/header-bar";
import {ButtonIconWithTooltip} from "@common/form/buttons/button-icon/button-icon";
import {LeftPanelTabs} from "../../../common/left-panel-tabs/left-panel-tabs";
import {WalkThroughSteps} from "../../../common/walk-through-steps/walk-through-steps";

import {EmbedDialog} from "./embed-dialog/embed-dialog";
import {CollectionPublishBtn, publishProcessProvider} from "./publish-button/publish-button";
import {SelectViewProfile} from "../../preview/select-view-profile/select-view-profile";
import {AutoRefreshBadge, AutoRefreshSettingBtns} from "./auto-refresh-settings";
import {CollectionErrors} from "../../../dashboard/env/collections/collection-errors/collection-errors";

export const EditCollectionLayout = ({collection, environment, saving, leftPanelTabs, leftPanelOverride, selectedTab, main, collectionCommands}) =>
    cs(
        consumeContext("tenant"),
        consumeContext("routing"),
        consumeContext("collectionToast"),
        consumeContext("routeTransitioning"),
        consumeContext("viewAsContext"),
        consumeContext("model"),

        [
            "walkThrough",
            (_, next) =>
                WalkThroughSteps({
                    steps: [
                        {
                            selector: ".share-actions .publish-button .btn",
                            content: "To push your updates to embedded dashboards you’ll just “Publish” button",
                            noAction: true,
                        },
                        {
                            selector: ".share-actions .share-button .btn",
                            content: "Now that your dashboard is published click the “Embed” button to get your embed code.",
                            noAction: true,
                        },
                    ],
                    next,
                }),
        ],
        ({walkThrough}, next) => publishProcessProvider({walkThrough: !!environment?.readOnly ? walkThrough : null, next}),

        ["containerBoxRef", (_, next) => Static2({next})],
        ["tutorialBoxRef", (_, next) => Static2({next})],
        ({tutorialBoxRef}, next) => provideContext("tutorialBoxRef", tutorialBoxRef, next),
        ["background", (_, next) => UseState({next})],
        ({background}, next) => provideContext("setContainerBackground", (value) => background.onChange(value), next),
        ({containerBoxRef}, next) => provideContext("containerBoxRef", containerBoxRef, next),
        [
            "embedDialog",
            ({collectionToast}, next) =>
                EmbedDialog({
                    collection,
                    next,
                    onClose: () => {
                        collectionToast.show({
                            text: "Instructions Sent",
                        });
                    },
                }),
        ],
        ({
            // publishDialog,
            embedDialog,
            routeTransitioning,
            routing,
            containerBoxRef,
            background,
            collectionToast,
            walkThrough,
            tutorialBoxRef,
            viewAsContext,
        }) => {
            const {folders, iframeKeys, viewAs} = viewAsContext || {};

            const {autoDataRefresh} = collection?.value ?? {};
            const isEnabledAutoRefresh = autoDataRefresh == null ? true : autoDataRefresh;

            return (
                <div className={cx("edit-collection-layout-4rw", {transitioning: routeTransitioning?.transitioning}, routeTransitioning?.transitioning)}>
                    {HeaderBar({
                        ID: collection?.value.id,
                        type: "collection",
                        name: collection?.value.name,
                        commands: collectionCommands,
                        minWidthCommand: 268,
                        previousRoutes: ["dashboard", "edit-data-source", "edit-model", "edit-shared-tile"],
                        shareActions: () => (
                            <>
                                {(iframeKeys?.value || folders?.value) &&
                                    cs(() => {
                                        const keys = iframeKeys.value?.filter((k) => k.collections.indexOf(collection?.value?.id) > -1);
                                        if (keys?.length === 0 && folders.value?.length == 0) return null;
                                        return SelectViewProfile({keys, viewAs, folders});
                                    })}

                                <div className="space" />

                                {AutoRefreshBadge({isEnabledAutoRefresh})}

                                <div className="collection-statuses">
                                    {collection?.value?.tileModelErrors && (
                                        <CollectionErrors size="large" errors={collection?.value?.tileModelErrors} collectionID={collection?.value?.id} />
                                    )}

                                    {saving ? (
                                        <div className={cx("save-status")}>
                                            <i className="fa fa-spinner fa-pulse" />
                                            Saving
                                        </div>
                                    ) : (
                                        Badge({
                                            size: "lg",
                                            icon: <i className="far fa-check" />,
                                            type: "success",
                                            label: "Saved",
                                        })
                                    )}
                                </div>

                                <div className="group-buttons">
                                    {AutoRefreshSettingBtns({collectionToast, isEnabledAutoRefresh, collection})}

                                    <ButtonIconWithTooltip
                                        tooltipClassName="tooltip-white-f3z small"
                                        content="Embed"
                                        icon={<CodeIcon />}
                                        className="btn"
                                        size="medium"
                                        btnType="ghost-no-border"
                                        onClick={() => {
                                            if (!!environment?.readOnly) walkThrough.showOff();
                                            embedDialog.show();
                                        }}
                                    />

                                    <ButtonIconWithTooltip
                                        tooltipClassName="tooltip-white-f3z small"
                                        content="Preview"
                                        icon={<PreviewIcon />}
                                        className="btn"
                                        size="medium"
                                        btnType="ghost-no-border"
                                        onClick={() => {
                                            window.open(`/#/env/${routing.params.envId}/collection/${routing.params.colId}/preview`, "_blank");
                                        }}
                                    />
                                </div>

                                <div className="group-buttons">{CollectionPublishBtn({walkThrough: !!environment?.readOnly ? walkThrough : null})}</div>

                                {!!environment?.readOnly && walkThrough.render()}
                            </>
                        ),
                    })}

                    {!collection.value ? (
                        LoadingIndicator()
                    ) : (
                        <>
                            {Invoke({
                                fn: async () => {
                                    // if (routing.params.action == "publish") {
                                    //     publishDialog.show();
                                    // }

                                    if (routing.params.action == "embed") {
                                        embedDialog.show();
                                    }
                                },
                            })}

                            <div className="center">
                                <div ref={tutorialBoxRef.set} />

                                <div className="left-panel">
                                    <div
                                        className={cx("main", {
                                            active: !leftPanelOverride || (leftPanelOverride?.length > 0 && leftPanelOverride?.filter((mode) => mode.leftPanel).length == 0),
                                        })}
                                    >
                                        <LeftPanelTabs
                                            {...{
                                                tabs: leftPanelTabs,
                                                selected: selectedTab,
                                            }}
                                        />
                                    </div>

                                    {(() => {
                                        const renderLeftPanel = (mode) =>
                                            cs(
                                                ({}, next) => <div className={cx("override", {active: mode.leftPanel})}>{next()}</div>,
                                                ({}, next) => SuspendUpdate({active: !mode.leftPanel, next}),
                                                () => mode.leftPanel
                                            );

                                        if (Array.isArray(leftPanelOverride) && leftPanelOverride?.length > 0) {
                                            return leftPanelOverride.map((panel, i) => cs(keyed(i), () => renderLeftPanel(panel)));
                                        }

                                        if (leftPanelOverride) {
                                            return renderLeftPanel(leftPanelOverride);
                                        }

                                        return null;
                                    })()}
                                </div>

                                <div className="content">
                                    <VerbScrollbar
                                        className="absolute-box"
                                        style={{
                                            background: background.value,
                                            height: "100%",
                                        }}
                                        ref={containerBoxRef.set}
                                    >
                                        {main}
                                    </VerbScrollbar>
                                </div>

                                {collectionToast.render()}
                            </div>
                        </>
                    )}
                </div>
            );
        }
    );

export const PreviewIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="18" viewBox="0 0 21 18" fill="none">
        <path
            d="M10.32 0C15.4633 0 19.7424 3.70104 20.64 8.5849C19.7434 13.4687 15.4633 17.1698 10.32 17.1698C5.17669 17.1698 0.897599 13.4687 0 8.5849C0.896645 3.70104 5.17669 0 10.32 0ZM10.32 15.262C12.2654 15.2616 14.153 14.6008 15.674 13.3878C17.1949 12.1748 18.259 10.4815 18.6922 8.5849C18.2574 6.68983 17.1926 4.99832 15.6718 3.78692C14.1511 2.57551 12.2643 1.91589 10.32 1.91589C8.37571 1.91589 6.48893 2.57551 4.96815 3.78692C3.44737 4.99832 2.38256 6.68983 1.94782 8.5849C2.38097 10.4815 3.44509 12.1748 4.96602 13.3878C6.48695 14.6008 8.3746 15.2616 10.32 15.262ZM10.32 12.8773C9.18157 12.8773 8.08977 12.4251 7.28478 11.6201C6.47979 10.8151 6.02755 9.72332 6.02755 8.5849C6.02755 7.44647 6.47979 6.35467 7.28478 5.54968C8.08977 4.74469 9.18157 4.29245 10.32 4.29245C11.4584 4.29245 12.5502 4.74469 13.3552 5.54968C14.1602 6.35467 14.6124 7.44647 14.6124 8.5849C14.6124 9.72332 14.1602 10.8151 13.3552 11.6201C12.5502 12.4251 11.4584 12.8773 10.32 12.8773ZM10.32 10.9696C10.9525 10.9696 11.559 10.7183 12.0062 10.2711C12.4534 9.82391 12.7047 9.21736 12.7047 8.5849C12.7047 7.95244 12.4534 7.34588 12.0062 6.89866C11.559 6.45145 10.9525 6.2002 10.32 6.2002C9.68754 6.2002 9.08098 6.45145 8.63377 6.89866C8.18655 7.34588 7.93531 7.95244 7.93531 8.5849C7.93531 9.21736 8.18655 9.82391 8.63377 10.2711C9.08098 10.7183 9.68754 10.9696 10.32 10.9696Z"
            fill="#919EB0"
        />
    </svg>
);

export const RefreshIcon = ({className, style}) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none" style={style}>
        <path
            d="M2.94355 2.06806C4.48593 0.731569 6.45914 -0.00283888 8.5 8.24726e-06C13.1946 8.24726e-06 17 3.80546 17 8.50001C17 10.3156 16.4305 11.9986 15.4615 13.379L12.75 8.50001H15.3C15.3001 7.16689 14.9084 5.86314 14.1735 4.75087C13.4386 3.63861 12.3929 2.76689 11.1666 2.24411C9.94026 1.72133 8.58731 1.57055 7.27596 1.81052C5.96462 2.05049 4.75274 2.67062 3.791 3.59381L2.94355 2.06806ZM14.0565 14.932C12.5141 16.2684 10.5409 17.0029 8.5 17C3.80545 17 0 13.1946 0 8.50001C0 6.68441 0.5695 5.00141 1.5385 3.62101L4.25 8.50001H1.7C1.69989 9.83313 2.09164 11.1369 2.82654 12.2491C3.56143 13.3614 4.60706 14.2331 5.8334 14.7559C7.05974 15.2787 8.41269 15.4295 9.72404 15.1895C11.0354 14.9495 12.2473 14.3294 13.209 13.4062L14.0565 14.932Z"
            fill="#919EB0"
        />
    </svg>
);

export const CodeIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="14" viewBox="0 0 21 14" fill="none">
        <path
            d="M20.33 6.53425L13.7958 13.0685L12.4891 11.7618L17.7167 6.53425L12.4891 1.30666L13.7958 0L20.33 6.53425ZM2.61333 6.53425L7.84091 11.7618L6.53425 13.0685L0 6.53425L6.53425 0L7.84091 1.30666L2.61333 6.53425Z"
            fill="#919EB0"
        />
    </svg>
);

export const RefreshOffIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <path
            d="M13.7919 2.07523C12.4136 1.08387 10.7225 0.500008 8.89506 0.500008C6.8794 0.497196 4.93054 1.22254 3.40721 2.54253L4.2442 4.04944C5.19406 3.13765 6.39098 2.52518 7.68614 2.28817C8.98129 2.05116 10.3175 2.20008 11.5287 2.71641C11.8985 2.87404 12.2516 3.06378 12.5845 3.28261L13.7919 2.07523Z"
            fill="#919EB0"
            fillOpacity="0.6"
        />
        <path
            d="M3.28272 12.5844C2.56265 11.4888 2.1789 10.2063 2.17901 8.89507H4.69753L2.01951 4.0763C1.06247 5.43966 0.5 7.10188 0.5 8.89507C0.5 10.7225 1.08386 12.4136 2.07523 13.7919L3.28272 12.5844Z"
            fill="#919EB0"
            fillOpacity="0.6"
        />
        <path
            d="M4.813 16.2327C6.02134 16.9063 7.41336 17.2901 8.89506 17.2901C10.9107 17.2929 12.8596 16.5676 14.3829 15.2476L13.5459 13.7407C12.5961 14.6525 11.3991 15.265 10.104 15.502C8.80883 15.739 7.47258 15.5901 6.26138 15.0737C6.19408 15.045 6.12734 15.0153 6.06116 14.9845L4.813 16.2327Z"
            fill="#919EB0"
            fillOpacity="0.6"
        />
        <path
            d="M14.9843 6.06137C15.3956 6.94536 15.6112 7.91213 15.6111 8.89507H13.0926L15.7706 13.7138C16.7277 12.3505 17.2901 10.6883 17.2901 8.89507C17.2901 7.41337 16.9063 6.02135 16.2327 4.813L14.9843 6.06137Z"
            fill="#919EB0"
            fillOpacity="0.6"
        />
        <path d="M0.956335 16.325L2.13137 17.5L17.5001 2.13134L16.325 0.956278L0.956335 16.325Z" fill="#919EB0" fillOpacity="0.6" />
    </svg>
);
