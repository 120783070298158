const {omit} = require("../../../../common/utils/objects");
const {last} = require("../../../../common/utils/collections");
const {sampleTransformation} = require("../../../../common/logic/sample-api/sample-transformation");
const {sampleSBDataSourcesSummary} = require("../../../../common/logic/sample-api/sample-data-sources-summary");
const {sampleSBModel} = require("../../../../common/logic/sample-api/sample-model");
const {sampleDataSources} = require("../../../../common/logic/sample-api/sample-data-sources");
const {sampleAuthEndpoint} = require("../../../../common/logic/sample-api/sample-auth-endpoint");
const {sampleModels} = require("../../../../common/logic/sample-api/sample-models");
const {sampleTileData} = require("../../../../common/logic/sample-api/sample-tile-data/sample-tile-data");
const {chain} = require("../../../../common/utils/fs");
const {cascadeChange} = require("../../../../common/utils/cascade");
const {createArray} = require("../../../../common/utils/collections");
const {sampleModel} = require("../../../../common/logic/sample-api/sample-model");
const {waitTimeout} = require("../../../../common/utils/wait-timeout");
const {sampleThemes} = require("../../../../common/logic/sample-api/sample-themes");
const {sampleCollection} = require("../../../../common/logic/sample-api/sample-collection");
const {sampleFolders} = require("../../../../common/logic/sample-api/sample-folders");

const createEnvMockApis = ({authToken, envId}) => {
    return {
        collection: {
            getFolders: () => sampleFolders,
            upsertFolder: async (folder) => {
                await waitTimeout(100);
                return {...folder, id: "new-folder-id"};
            },
            checkFolderName: async (name, {folderId, parentFolderId} = {}) => {
                await waitTimeout(100);
                return true;
            },
            getCollection: async () => {
                // await waitTimeout(1000);

                return sampleCollection;
            },
            upsertCollection: async (col) => {
                await waitTimeout(100);

                return chain(
                    col,
                    (_) => cascadeChange(_, "gridLocations[*].tile.id", (id) => id ?? "" + Math.random()),
                    (_) => cascadeChange(_, "filters[*].id", (id) => id ?? "" + Math.random())
                );
            },
            getThemes: () => sampleThemes,
            getTheme: () => sampleThemes[0],
            checkThemeName: async (name, {themeId} = {}) => {
                await waitTimeout(1000);
                return name.startsWith("Default Theme");
            },
            upsertTheme: async () => {
                await waitTimeout(1000);
            },
            getTileData: async ({tileId, filterValues, page}) => {
                // console.log("Loading page", page);
                // console.log("filterValues:", filterValues);
                await waitTimeout(150);
                // return sampleTileData[tileId];
                return sampleTileData;
                // throw {
                //     statusCode: 471,
                //     message: "aaa",
                // }
            },
            getValidTiles: ({}) => ({
                validTileIDs: [sampleCollection.gridLocations[0].tile.id],
                invalidTile: [],
            }),
            getFilterData: () => ["Data 1", "Data 2", "Data 3", "Data 4", "ewefwe", "uuuu"],
            shareCollection: () => null,
            getPublishInfo: async () => {
                await waitTimeout(1000);
                return {
                    // publishedOn: new Date().toISOString(),
                };
            },
        },
        model: {
            getModel: () => {
                // return sampleModel;
                return sampleSBModel;
            },
            getModels: () => sampleModels,
            getSuggestedRelationships: async () => {
                await waitTimeout(1000);
                return [
                    {
                        leftColumnID: sampleModel.relationships[0].leftColumnID,
                        rightColumnID: sampleModel.relationships[1].rightColumnID,
                    },
                    {
                        leftColumnID: sampleModel.relationships[2].leftColumnID,
                        rightColumnID: sampleModel.relationships[3].rightColumnID,
                    },
                ];
            },
            upsertModel: async (model) => {
                await waitTimeout(300);
                return model;
            },
            upsertTablePosition: async (modelId, data) => {
                await waitTimeout(300);
                return data;
            },
        },
        transformation: {
            getTransformation: (transformationId) => {
                if (transformationId.includes("new")) {
                    return {
                        ...sampleTransformation,
                        $type: transformationId === "new-view-id" ? "ViewTransformation" : "ColumnTransformation",
                        steps: [sampleTransformation.steps[0], omit(last(sampleTransformation.steps), ["inputStepID"])],
                    };
                }
                return sampleTransformation;
            },
            upsertTransformation: async (transformation) => {
                await waitTimeout(500);
                if (transformation.id) {
                    return transformation;
                }
                return {
                    ...transformation,
                    id: transformation.$type === "ViewTransformation" ? "new-view-id" : "new-column-id",
                };
            },
            checkTransformationName: async (name, {transformationId} = {}) => {
                await waitTimeout(1000);
                return true;
            },
            deleteTransformation: ({transformationId, confirmName}) => {
                console.log(transformationId, confirmName);
            },
            getPublishInfo: async () => {
                await waitTimeout(1000);
                return {
                    publishedOn: "2020-10-16T05:36:54.009Z",
                    publishedBy: "AAA",
                };
            },
            publishTransformation: async (transformationId) => {
                await waitTimeout(1000);
            },
            getStepPreview: async () => {
                await waitTimeout(1000);
                const colNo = 20,
                    rowNo = 20;
                return {
                    columns: createArray(colNo + 1).map((_, i) => ({
                        name: `column ${i}`,
                        dataType: "Text",
                    })),
                    data: createArray(rowNo).map((_, i) => createArray(colNo).map((_, j) => `row ${i} column ${j}`)),
                };
                // throw {
                //     "statusCode": 481,
                //     "message": "An error processing transformation step `Group Data` (Group Data Step) occured. | Object reference not set to an instance of an object.",
                //     "source": "GET /api/model/transformation/8668fb1e-03c7-4ee2-81ec-f6168368c9b6",
                //     "data": {
                //         "StepID": "000-123",
                //         "StepName": "Group Data",
                //         "StepType": "Group Data Step"
                //     }
                // }
            },
        },
        data: {
            getDataSources: async () => {
                await waitTimeout(100);
                // return sampleDataSourcesSummary;
                return sampleSBDataSourcesSummary;
            },
            getDataSource: async (dataSourceId) => {
                await waitTimeout(100);
                return sampleDataSources[dataSourceId];
            },
        },
        authEndpoint: {
            getAuthEndpoint: () => sampleAuthEndpoint,
            upsertAuthEndpoint: async (authEndpoint) => {
                await waitTimeout(500);
                return authEndpoint;
            },
        },
    };
};
exports.createEnvMockApis = createEnvMockApis;
