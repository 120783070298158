import {cx} from "emotion";
import {MoveIconSvg} from "../../../../../../../../../../../common/icons/move-icon-svg";
import {TrashIconBlue} from "../../../../../../../../../../../common/icons/trash-icon";
import * as React from "react";
import "./draggable-sorting-item.scss"

// the draggable sorting item which can be found in sorting section of funnel chart or table tile
export const DraggableSortingItem = ({children, onStartDragging, dragging, onRemove}) => {
    return (
        <div className={cx("draggable-sorting-item-g03", {dragging})}>
            <div
                className="dragging-anchor"
                onMouseDown={(e) => {
                    onStartDragging?.(e)
                }}
            >
                {MoveIconSvg({fill: "#546B81"})}
            </div>

            {children}

            {onRemove && (
                <div
                    className="delete-btn"
                    onClick={onRemove}
                >
                    {TrashIconBlue()}
                </div>
            )}
        </div>
    )
}

export const SortingItemBlank = () => {
    return (
        <div className="draggable-sorting-item-blank-tg2"/>
    )
}
