import React from "react";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";

export const AuthParamsObject = ({authParams}) =>
    cs(["open", (_, next) => UseState({next, initValue: true})], ({open}) =>
        authParams.map((a) => (
            <>
                <div className="filter">
                    <h6 className="api-list-title" onClick={() => open.onChange(!open.value)}>
                        <i className="material-icons">{open.value ? "keyboard_arrow_down" : "keyboard_arrow_up"}</i> "authParams" (object)
                    </h6>

                    {open.value && (
                        <div className="filter-item">
                            <h4>Key</h4>
                            <ul className="api-list-group">
                                <li className="api-list-item">
                                    <h3 className="api-list-item-label">
                                        <span className="api-list-item-label-name">"{a}"</span> <span className="api-list-item-validation">string</span>
                                    </h3>
                                    <div className="api-list-item-description-and-children">
                                        <p className="api-list-item-description">
                                            <span>Constant key of this auth parameter.</span>
                                        </p>
                                    </div>
                                </li>
                            </ul>
                            <h4>Value</h4>
                            <ul className="api-list-group">
                                <li className="api-list-item">
                                    <h3 className="api-list-item-label">
                                        <span className="api-list-item-label-name">value</span> <span className="api-list-item-validation">string</span>
                                    </h3>
                                    <div className="api-list-item-description-and-children">
                                        <p className="api-list-item-description">
                                            <span>Value of this auth parameter.</span>
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
            </>
        ))
    );
