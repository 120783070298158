import * as React from "react";
import {cs} from "@common/react/chain-services";
import "./column-security.scss";
import {LeftPanelClosable} from "../../../../../common/left-panel-closable/left-panel-closable";
import {ArrowLeftIcon} from "../../../../../../assets/icons/ArrowLeftIcon";
import {consumeContext} from "@common/react/context";
import {AlertCard} from "../../../../../common/alert-card/alert-card";
import {UseState} from "@common/react/use-state";
import {rSecurityIcon} from "../../common/icons/render-security-icon";
import {TabsHeader} from "../../../../../common/tabs/tabs-header";
import {ColumnSecurityMain} from "./main/column-security-main";
import {scc} from "@common/react/state-cascade-change";
import {Segmented} from "@common/ui-components/segmented/segmented";
import {CopyAuthenticationDialog} from "../copy-authentication-dialog/copy-authentication-dialog";
import {keyed} from "@common/react/keyed";
import {UsageStatusIndicator} from "../../common/usage-status-indicator";
import {dataSourceColors, getDSColorBasedOnIndex} from "../../common/data-source-colors";
import {Button} from "../../../../../../../../common/form/buttons/button/button";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";

export const envAuthLabels = {
    Static: "Static",
    Endpoint: "Active",
};

export const envAuthOtherLabel = (current) => envAuthLabels[Object.keys(envAuthLabels).filter((k) => k !== current)[0]];

export const envAuthSegments = [
    {label: "Static", value: "Static"},
    {label: "Active", value: "Endpoint"},
];

export const getEnvAuthOther = (current) => envAuthSegments.filter((e) => e.value != current)[0];

export const ColumnSecurity = ({columnId, tableId, model, onCancel, tab, dataSources}) => {
    const table = model.value.tables.find((t) => t.id === tableId);
    const column = table.columns.find((c) => c.id === columnId);

    return cs(
        ({}, next) => {
            const index = dataSources.findIndex((t) => t.id === table?.dataSourceID);
            const color = dataSources[index]?.colorRGB || getDSColorBasedOnIndex(index);

            return (
                <LeftPanelClosable
                    {...{
                        onClose: onCancel,
                        label: (
                            <div className="column-security-label-97f">
                                <div className="back-arrow" onClick={() => onCancel({toName: "column-settings"})}>
                                    {ArrowLeftIcon({fillColor: "#294661"})}
                                </div>
                                COLUMN-LEVEL SECURITY for <UsageStatusIndicator status="all" color={color} />{" "}
                                <span style={{color}}>{table?.visualName?.toUpperCase()}</span> TABLE
                            </div>
                        ),
                        content: next(),
                    }}
                />
            );
        },
        consumeContext("authEndpoint"),
        consumeContext("routing"),
        [
            "envAuthState",
            ({authEndpoint}, next) =>
                UseState({
                    // initValue: authEndpoint.value?.find?.((a) => a.$type === `EndpointAuthConfig`)
                    //     ? envAuthSegments[1]
                    //     : envAuthSegments[0],
                    initValue: envAuthSegments[1],
                    next,
                }),
        ],
        [
            "copyModal",
            ({envAuthState}, next) =>
                CopyAuthenticationDialog({
                    envAuthType: envAuthState.value.label,
                    envAuthOtherType: getEnvAuthOther(envAuthState.value).label,
                    levelType: "column",
                    next,
                }),
        ],
        [
            "renderButtons",
            ({routing, copyModal}, next) =>
                next(({authConfig, onCopy, envAuthOther}) => {
                    return (
                        <div className="buttons">
                            <Button btnType="secondary" onClick={() => routing.goto("dashboard", {tab: "settings"})}>
                                Go To Authentication Settings
                            </Button>

                            <Button
                                btnType="border"
                                onClick={() =>
                                    copyModal.show({
                                        authConfig,
                                        onCopy,
                                    })
                                }
                            >
                                Copy to {envAuthOther.label} Auth
                            </Button>
                        </div>
                    );
                }),
        ],
        ({authEndpoint, routing, envAuthState, copyModal, renderButtons}) => {
            const envAuth = envAuthState.value.value;
            const envAuthOther = getEnvAuthOther(envAuth);
            const authConfig = (authEndpoint.value || []).find((a) => a.$type === `${envAuth}AuthConfig`);

            return (
                <div className="column-security-k66">
                    <div className="header">
                        <div className="text">Authentication Method</div>

                        {/*{Segmented({*/}
                        {/*    width: 135,*/}
                        {/*    items: envAuthSegments,*/}
                        {/*    isSelected: (v) => v.value === envAuth,*/}
                        {/*    onChange: envAuthState.onChange,*/}
                        {/*})}*/}
                    </div>

                    {!authConfig?.resultMapping || Object.keys(authConfig?.resultMapping).length === 0 ? (
                        <>
                            <div className="no-authentication-46g">
                                {AlertCard({
                                    message:
                                        "Before you can create your column-level security rules you must set up the environment authentication method.",
                                })}
                            </div>
                            {renderButtons({
                                authConfig,
                                envAuthOther,
                                onCopy: () => {},
                            })}
                        </>
                    ) : (
                        cs(
                            ["selected", (_, next) => UseState({initValue: tab, next})],
                            [
                                "tabs",
                                ({selected}, next) =>
                                    next(
                                        [
                                            {
                                                key: "Blank",
                                                tabLabel: "Blank Value",
                                            },
                                            {
                                                key: "Hide",
                                                tabLabel: "Hide Column",
                                            },
                                            {
                                                key: "Mask",
                                                tabLabel: "Mask value",
                                            },
                                            {
                                                key: "Error",
                                                tabLabel: "Report Error",
                                            },
                                        ].map((tab) => ({
                                            ...tab,
                                            rLabel: ({active}) => (
                                                <div className="tab-label">
                                                    {rSecurityIcon({
                                                        name: tab.key.toLowerCase(),
                                                        active,
                                                    })}
                                                    {tab.tabLabel}
                                                </div>
                                            ),
                                        }))
                                    ),
                            ],
                            ({tabs, selected}) => {
                                const {tables, relationships} = model.value;
                                const introductions = [
                                    {
                                        key: "Blank",
                                        subText: (
                                            column
                                        ) => `A blank value will show as an empty value for the ${column.visualNameFull} column. Users will see
                        that the column exists but will not have access to the data.`,
                                        beginningLine: "Show a blank value in this column when…",
                                    },
                                    {
                                        key: "Hide",
                                        subText: (column) =>
                                            `The ${column.visualNameFull} column will not be accessible or displayed. User will not know that this column exists.`,
                                        beginningLine: "Hide this column when…",
                                    },
                                    {
                                        key: "Mask",
                                        subText: (column) =>
                                            `A masked value will show for the ${column.visualNameFull} column. User will see a set of asterisk instead of the original value.`,
                                        beginningLine: "Mask the value in this column when…",
                                    },
                                    {
                                        key: "Error",
                                        subText: (column) =>
                                            `An error will be displayed/sent to the user if they view a collection that access the ${column.visualNameFull} column.`,
                                        beginningLine: "Show an error when…",
                                    },
                                ];

                                return (
                                    <>
                                        {TabsHeader({
                                            className: "tabs-header",
                                            tabs: tabs.map((tab) => ({
                                                ...tab,
                                                onClick: () => selected.onChange(tab.key),
                                                active: selected.value === tab.key,
                                                label: tab.rLabel({
                                                    active: selected.value === tab.key,
                                                }),
                                            })),
                                        })}
                                        <VerbScrollbar className="main">
                                            {cs(keyed(envAuth), () =>
                                                ColumnSecurityMain({
                                                    introduction: introductions.find((i) => i.key == selected.value),
                                                    table,
                                                    column,
                                                    tables,
                                                    relationships,
                                                    activeTab: selected.value,
                                                    columnLevelSecurity: column.columnLevelSecurity?.[`${envAuth}`]?.[selected.value],
                                                    onUpdate: (change) => {
                                                        scc(
                                                            model,
                                                            `tables[*].columns[*].columnLevelSecurity.${envAuth}`,
                                                            (_, index, {1: t, 3: c}) => {
                                                                if (t.id == table.id && c.id === column.id) {
                                                                    return {
                                                                        ..._,
                                                                        [selected.value]: change,
                                                                    };
                                                                }
                                                                return _;
                                                            }
                                                        );
                                                    },
                                                    userMapping: Object.keys(authConfig?.resultMapping),
                                                    dataSources,
                                                })
                                            )}
                                        </VerbScrollbar>

                                        {renderButtons({
                                            authConfig,
                                            envAuthOther,
                                            onCopy: () => {
                                                scc(
                                                    model,
                                                    `tables[*].columns[*].columnLevelSecurity.${envAuthOther.value}`,
                                                    (_, index, {1: t, 3: c}) => {
                                                        if (t.id == table.id && c.id === column.id) {
                                                            return {
                                                                ..._,
                                                                [selected.value]:
                                                                    column.columnLevelSecurity?.[`${envAuth}`]?.[selected.value],
                                                            };
                                                        }
                                                        return _;
                                                    }
                                                );
                                            },
                                        })}
                                    </>
                                );
                            }
                        )
                    )}
                </div>
            );
        }
    );
};
