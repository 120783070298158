import {dashArrays, getTrianglePath} from "./tooltip-connector-helpers";

export const drawTooltipConnector = ({chart, tooltipStyle}) => {
    drawConnectorCircle({chart, tooltipStyle});
    drawConnectorLine({chart, tooltipStyle});
    placeTooltipAnchor({chart, tooltipStyle});
};

export const destroyTooltipConnector = ({chart}) => {
    if (chart.connectorCircle?.element) {
        chart.connectorCircle?.destroy();
    }
    if (chart.connectorLine?.element) {
        chart.connectorLine?.destroy();
    }
    if (chart.connectorFakeAnchor?.element) {
        chart.connectorFakeAnchor?.destroy();
    }
};

const drawConnectorCircle = ({chart, tooltipStyle}) => {
    const crosshair = chart.xAxis[0].cross;

    if (!crosshair) {
        return;
    }

    const {1: x, 2: y} = crosshair.pathArray[1];

    if (!chart.connectorCircle?.element) {
        chart.connectorCircle = chart.renderer
            .circle(x, y, 3)
            .attr({
                fill: tooltipStyle.indicatorLineColorRGB || "#000000",
                stroke: tooltipStyle.indicatorLineColorRGB || "#000000",
                "stroke-width": 1,
                "pointer-events": "none",
                zIndex: 3,
            })
            .add()
            .show();
    } else {
        chart.connectorCircle.attr({x, y});
    }
};

const drawConnectorLine = ({chart, tooltipStyle}) => {
    const crosshair = chart.xAxis[0].cross;

    if (!crosshair) {
        return;
    }

    const {1: x, 2: y} = crosshair.pathArray[1];
    const topEnd = {
        x,
        y: y - chart.containerBox.height,
    };

    const pathArr = ["M", x, y, "L", topEnd.x, topEnd.y];

    if (!chart.connectorLine?.element) {
        chart.connectorLine = chart.renderer
            .path(pathArr)
            .attr({
                stroke: chart.customTooltipPosition !== "Above" ? "none" : tooltipStyle.indicatorLineColorRGB || "#000000",
                "stroke-width": tooltipStyle.indicatorLineWidth || 0.5,
                ...(["Dashed", "Dotted"].includes(tooltipStyle.indicatorLineStyle) && {
                    "stroke-dasharray": dashArrays[tooltipStyle.indicatorLineStyle],
                }),
                "pointer-events": "none",
                zIndex: 3,
            })
            .add()
            .show();
    } else {
        chart.connectorLine.attr({
            d: pathArr.join(" "),
        });
    }
};

const placeTooltipAnchor = ({chart, tooltipStyle}) => {
    const crosshair = chart.xAxis[0].cross;

    if (!crosshair || !chart.tooltip.container) {
        return;
    }

    const {1: x, 2: y} = crosshair.pathArray[1];
    const topEnd = {
        x,
        y: y - chart.containerBox.height,
    };

    if (!chart.connectorFakeAnchor?.element) {
        chart.connectorFakeAnchor = chart.renderer
            .path(getTrianglePath(topEnd))
            .attr({
                fill: "none",
                stroke: "none",
                "stroke-width": 1,
                zIndex: 3,
            })
            .add()
            .hide();
    } else {
        chart.connectorFakeAnchor.attr({
            d: getTrianglePath(topEnd),
        });
    }

    const tooltipAnchor = chart.tooltip.container.getElementsByClassName("tooltip-anchor")[0];
    const tooltipBox = chart.tooltip.container.getElementsByClassName("chart-tooltip-wrapper")[0];

    if (!tooltipBox) {
        return;
    }

    const tooltipBoxRect = tooltipBox.getBoundingClientRect();
    const anchorRect = chart.connectorFakeAnchor.element.getBoundingClientRect();

    if (chart.customTooltipPosition === "Above") {
        tooltipAnchor.style.top = "100%";
        tooltipAnchor.style["border-bottom-color"] = "transparent";
        tooltipAnchor.style["border-top-color"] = tooltipStyle.tooltipTheme === "Dark" ? "#000000" : "#FFFFFF";
    } else {
        tooltipAnchor.style.top = "-12px";
        tooltipAnchor.style["border-top-color"] = "transparent";
        tooltipAnchor.style["border-bottom-color"] = tooltipStyle.tooltipTheme === "Dark" ? "#000000" : "#FFFFFF";
    }

    tooltipAnchor.style.left = `${Math.floor(anchorRect.x - tooltipBoxRect.x)}px`;
    tooltipAnchor.style.opacity = "1";
};
