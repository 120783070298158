exports.mapTileTemplate = {
    $type: "MapTile",
    style: {
        $type: "MapTileStyle",
        autoRound: true,
        defaultLocation: "Auto",

        title: {
            show: true,
            titleLocation: "Above",
        },

        legendStyle: {
            legendShown: true,
            legendPosition: "Auto",
        },

        showCompare: false,
        showDownloadData: false,
        showDownloadImage: false,
    },
};
