import React from "react";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import "./select-collections.scss";
import {SearchInput} from "../../../../../../../../../common/form/search-input/search-input";
import {sort, unique} from "@common/utils/collections";
import {CheckboxLine} from "../../../../../../common/checkbox-line/checkbox-line";
import {settingKey} from "../../../settings-constants";
import {Button} from "../../../../../../../../../common/form/buttons/button/button";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";
import {keyed} from "@common/react/keyed";
import {SearchInput2} from "../../../../../../../../../common/form/search-input/search-input-2";
import {isMatchText} from "@common/utils/strings";
import {LoadingIndicator} from "@common/ui-components/loading-indicator/loading-indicator";

export const SelectCollections = ({form, collections, stepIndex, saving, editMode, modal, preSelectCollection}) =>
    cs(["collectionsProps", (_, next) => next(modal?.$type === settingKey.IFRAME ? "collections" : "apiCollections")], ({collectionsProps}) =>
        cs(
            ["keyword", (_, next) => UseState({next, initValue: ""})],
            [
                "list",
                (_, next) => {
                    const state = form.data;
                    const selectedCollections = collections.value.filter((c) => state.value[collectionsProps].indexOf(c.id) > -1);
                    return UseState({
                        next,
                        initValue: sort(selectedCollections, (c) => c.name).concat(
                            sort(
                                collections.value.filter((c) => state.value[collectionsProps].indexOf(c.id) == -1),
                                (c) => c.name
                            )
                        ),
                    });
                },
            ],
            ({keyword, list}) => {
                const state = form.data;
                const checkboxStatus = collections.length === state.value[collectionsProps].length ? true : state.value[collectionsProps].length === 0 ? false : "partial";
                const _list = list.value.filter((l) => isMatchText(l.name, keyword.value));

                return (
                    <>
                        <div className="select-collections-a34">
                            <SearchInput2 state={keyword} />

                            <div className="collections-group dialog-body">
                                {CheckboxLine({
                                    label: <b>Published Collections</b>,
                                    state: {
                                        value: checkboxStatus,
                                        onChange: () => {
                                            if (checkboxStatus === true)
                                                state.change((c) => ({
                                                    ...c,
                                                    [collectionsProps]: preSelectCollection ? [preSelectCollection] : [],
                                                }));
                                            else {
                                                state.change((c) => ({
                                                    ...c,
                                                    [collectionsProps]: collections.map((c) => c.id),
                                                }));
                                            }
                                        },
                                    },
                                })}

                                {collections.loading ? (
                                    <LoadingIndicator />
                                ) : (
                                    <VerbScrollbar maxHeight="250px" className="collections">
                                        {_list.map((item, index) =>
                                            cs(keyed(item.id), () =>
                                                CheckboxLine({
                                                    key: index,
                                                    readOnly: (preSelectCollection ? [preSelectCollection] : []).indexOf(item.id) > -1,
                                                    state: {
                                                        value: state.value[collectionsProps].indexOf(item.id) > -1,
                                                        onChange: (val) => {
                                                            if (val) {
                                                                state.change((c) => ({
                                                                    ...c,
                                                                    [collectionsProps]: c[collectionsProps].concat(item.id),
                                                                }));
                                                            } else {
                                                                state.change((c) => ({
                                                                    ...c,
                                                                    [collectionsProps]: c[collectionsProps].filter((v) => v !== item.id),
                                                                }));
                                                            }
                                                        },
                                                    },
                                                    label: item.name,
                                                })
                                            )
                                        )}
                                    </VerbScrollbar>
                                )}
                            </div>
                        </div>

                        {!editMode ? (
                            <div className="buttons">
                                <Button
                                    btnType="border"
                                    style={{
                                        float: "left",
                                    }}
                                    onClick={() => stepIndex.onChange(0)}
                                >
                                    Back
                                </Button>

                                <Button btnType="secondary" disabled={saving.value || state.value[collectionsProps].length === 0} onClick={() => form.submit()}>
                                    Save
                                </Button>
                                <Button disabled={saving.value || state.value[collectionsProps].length === 0} onClick={() => form.submit(true)}>
                                    Save & Send
                                </Button>
                            </div>
                        ) : (
                            <div className="buttons">
                                <Button btnType="secondary" onClick={() => modal.resolve()}>
                                    Cancel
                                </Button>
                                <Button disabled={saving.value || state.value[collectionsProps].length === 0} onClick={() => form.submit()}>
                                    Save
                                </Button>
                            </div>
                        )}
                    </>
                );
            }
        )
    );
