import "./show-urls-dialogs.scss";

import React from "react";

import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {consumeContext} from "@common/react/context";

import {Button} from "@common/form/buttons/button/button";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";

import {DialogService} from "../../../../../common/dialog/dialog-service";
import {CopySettingKey} from "../../commons/copy-setting-key/copy-setting-key";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {LoadingIndicator} from "@common/ui-components/loading-indicator/loading-indicator";

export const ShowUrlsDialog = ({next: rootNext}) =>
    cs(
        consumeContext("routing"),
        consumeContext("authEndpoint"),
        [
            "modal",
            (_, next) =>
                DialogService({
                    render: ({resolve, args: {accessKey, collections}}) => ({
                        title: `${accessKey.name} URLs`,
                        width: 480,
                        content: next({
                            resolve,
                            accessKey,
                            collections,
                        }),
                        initShow: true,
                        // className: "manage-access-key-dialog-wrapper-a23"
                    }),
                    next: rootNext,
                }),
        ],
        ["genericUrl", ({modal}, next) => UseState({next, initValue: !!modal.accessKey?.genericUrl})],
        ({modal, authEndpoint, genericUrl}) => {
            const endpointAuth = authEndpoint.value.find((i) => i.$type === "EndpointAuthConfig");
            const {activeAuth, apiKey, collections, name} = modal.accessKey;
            const genIframeURL = (collectionID) => {
                let host = `${window.location.protocol}//${genericUrl.value ? "ad-reporting.net" : window.location.host}`;
                let baseURL = `${host}/iframe/view/index.html?collectionID=${collectionID}&key=${apiKey}&iframeKeyName=${encodeURIComponent(name)}`;

                if (activeAuth) {
                    for (let i = 0; i < endpointAuth.parameters.length; i++) {
                        const param = endpointAuth.parameters[i];
                        baseURL += `&${param}={REPLACE_WITH_VALUE}`;
                    }
                }

                return baseURL;
            };

            return (
                <div className="show-urls-dialog-0wq">
                    <VerbDialogBodyScrollbar>
                        <div className="description">This iFrame key has access to the following collections. To embed the collection copy and paste the URL where needed</div>
                        <div className="section">
                            <div className="dropdown-wrapper full-width">
                                {DropdownSelect({
                                    label: "iFrame Domain",
                                    list: [
                                        {label: "Revive Media (data.revivemedia.us)", value: false},
                                        {label: "Generic (ad-reporting.net)", value: true},
                                    ],
                                    valueToLabel: (v) => v.label,
                                    isSelected: (v) => v.value === genericUrl.value,
                                    onChange: (v) => genericUrl.onChange(v.value),
                                    value: genericUrl.value,
                                })}
                            </div>
                            <br />
                        </div>
                        <div className="section">
                            <div className="label">Collections</div>

                            <div className="list">
                                {modal.collections.loading ? (
                                    <LoadingIndicator />
                                ) : (
                                    modal.collections.value
                                        .filter((c) => collections.includes(c.id))
                                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                                        .map((c1, index) => (
                                            <div className="item" key={index}>
                                                <div className="icon">
                                                    <img src={require(`../../../collections/icons/report-icon.svg`)} alt="" />
                                                </div>
                                                <div className="text">{c1.name}</div>
                                                <div className="space" />
                                                {CopySettingKey({
                                                    value: genIframeURL(c1.id),
                                                    text: "URL",
                                                })}
                                            </div>
                                        ))
                                )}
                            </div>
                        </div>

                        <div className="section">
                            <div className="label">iFrame Key</div>
                            <div className="content">
                                <div className="copy-key-wrapper">
                                    <div className="value">{apiKey}</div>

                                    {CopySettingKey({
                                        value: apiKey,
                                        text: "Key",
                                    })}
                                </div>
                            </div>
                        </div>
                    </VerbDialogBodyScrollbar>

                    <div className="buttons">
                        <Button onClick={() => modal.resolve()}>Done</Button>
                    </div>
                </div>
            );
        }
    );
