import {LpColorLine} from "../../../../../common/left-panel/lp-color-line";
import {ffToColorPickerLine} from "../../../../../../../../common/form/ff-to-color-picker-line";
import {NumberInput} from "../../../../../../../../common/form/number-input/number-input";
import React from "react";
import {ffToNumberInput} from "../../../../../../../../common/form/ff-to-number-input";

export const ButtonTheme = ({form, itemKey}) => {
    return (
        <div className="content">
            {LpColorLine({
                label: "Default Background Color",
                ...ffToColorPickerLine(form.field(["general", "buttons", itemKey, "backgroundColorRGB"])),
            })}

            {LpColorLine({
                label: "Default Border Color",
                ...ffToColorPickerLine(form.field(["general", "buttons", itemKey, "borderColorRGB"])),
            })}

            {LpColorLine({
                label: "Default Text Color",
                ...ffToColorPickerLine(form.field(["general", "buttons", itemKey, "fontColorRGB"])),
            })}

            {LpColorLine({
                label: "Hover Background Color",
                ...ffToColorPickerLine(form.field(["general", "buttons", itemKey, "hoverBackgroundColorRGB"])),
            })}

            {LpColorLine({
                label: "Hover Text Color",
                ...ffToColorPickerLine(form.field(["general", "buttons", itemKey, "hoverFontColorRGB"])),
            })}

            {LpColorLine({
                label: "Hover Border Color",
                ...ffToColorPickerLine(form.field(["general", "buttons", itemKey, "hoverBorderColorRGB"])),
            })}

            {NumberInput({
                label: "Border Width",
                unit: "px",
                ...ffToNumberInput(form.field(["general", "buttons", itemKey, "borderWidth"])),
                showZero: true,
            })}

            {NumberInput({
                label: "Corner Radius",
                unit: "px",
                ...ffToNumberInput(form.field(["general", "buttons", itemKey, "cornerRadius"])),
                showZero: true,
            })}
        </div>
    );
};
