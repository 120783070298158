import {cs} from "@common/react/chain-services";
import {renderColor} from "./colors-tile-config";
import {FieldItem} from "../../../../../../edit/common/field-item/field-item";
import React from "react";
import {UseState} from "@common/react/use-state";
import {mapColorApplicationToThemeColor} from "@common/ui-components/charts/get-field-color";
import {LeftPanelClosable} from "../../../../../../../common/left-panel-closable/left-panel-closable";
import {MapLocationColorsConfig} from "./map-location-colors-config";

export const MapColorsConfig = ({next: rootNext, tile, theme}) =>
    cs(["selecting", (_, next) => UseState({next})], ({selecting}) =>
        rootNext({
            render: () => {
                return (
                    <div className="map-colors-8je">
                        <div className="default-padding">
                            <FieldItem
                                className="field-color-bbg"
                                label={
                                    <div>
                                        <div className="field-name">Locations</div>
                                        <div className="selected-color">
                                            {renderColor["SchemeColorApplication"]({
                                                index: tile.value.style.locationColorApplication?.index ?? 0,
                                                displayColor: mapColorApplicationToThemeColor(
                                                    theme.dataVisualization.dataColorPalettes,
                                                    tile.value.style.locationColorApplication || {
                                                        index: 0,
                                                        $type: "SchemeColorApplication",
                                                        type: "Sequential",
                                                    }
                                                ),
                                                type: tile.value.style.locationColorApplication?.type || "Sequential",
                                            })}
                                        </div>
                                    </div>
                                }
                                rightIcon={<i className="far fa-arrow-right" aria-hidden="true" />}
                                onClick={() => selecting.onChange("location")}
                            />
                        </div>
                    </div>
                );
            },
            override:
                selecting.value &&
                cs(
                    ({}, next) => (
                        <LeftPanelClosable
                            {...{
                                onClose: () => selecting.onChange(false),
                                label: "Locations Color",
                                content: next(),
                            }}
                        />
                    ),
                    ({}) => MapLocationColorsConfig({tile, theme})
                ),
        })
    );
