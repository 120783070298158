import React from "react";
import {cs} from "@common/react/chain-services";
import {DialogService} from "../../../../common/dialog/dialog-service";
import {Invoke} from "@common/react/invoke";
import "./connecting-data-source-dialog.scss";
import {dataSourceTypes} from "../../../common/data-source-type";

export const ConnectingDataSourceDialog = ({next: rootNext}) =>
    cs(
        [
            "modal",
            ({}, next) =>
                DialogService({
                    strong: true,
                    render: ({resolve, reject, args: {onConnect, type}}) => ({
                        title: [dataSourceTypes.FILE, dataSourceTypes.GOOGLE_SHEETS].indexOf(type) > -1 ? "Importing Your Data" : "Connecting to your data source",
                        content: next({
                            reject,
                            resolve,
                            onConnect,
                            type,
                        }),
                    }),
                    next: rootNext,
                }),
        ],
        ({modal}) => {
            const importedType = [dataSourceTypes.FILE, dataSourceTypes.GOOGLE_SHEETS].indexOf(modal.type) > -1;

            return (
                <div className="connecting-data-source-dialog-8fg">
                    <div className="progress-wrapper">
                        <div className="progress" />
                    </div>

                    <div className="text">
                        <div>Verb is {importedType ? `importing your data` : `connecting to your data source to gather schema information`}. Next you will be able to:</div>
                        <br />
                        {[
                            ...(importedType
                                ? ["Select table to include", "Limit and manage columns"]
                                : ["Select tables and sync methods", "Limit and manage columns", "Add filters to limit data synced per table"]),
                            "Add data to a model",
                        ].map((item, index) => (
                            <div key={index}>- {item}</div>
                        ))}
                    </div>

                    {Invoke({
                        fn: async () => {
                            try {
                                const resp = await modal.onConnect();
                                modal.resolve(resp);
                            } catch (e) {
                                await modal.reject(e);
                            }
                        },
                    })}
                </div>
            );
        }
    );
