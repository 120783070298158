import {cx} from "emotion";
import moment from "moment";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {Checkbox} from "@common/ui-components/form/checkbox/checkbox";
import {formatNumber} from "@common/utils/format";
import {DataTable} from "../../../../common/data-table/data-table";
import {PopupMenu} from "../../../../common/popup-menu/popup-menu";
import {SyncMethodTypesToValue} from "../../../common/method-list-config";
import {SyncStatusIcon} from "../common/sync-status-icon/sync-status-icon";
import "./sync-status-table.scss";
import {dataSourceTypes} from "../../../common/data-source-type";

export const SyncStatusTable = ({
    data,
    dsSettings,
    selectedTables,
    tablesSyncing,
    tablesErrorIDs,
    forceSyncDialog,
    dsId,
    onReload,
    dataSource,
    style,
}) =>
    cs(
        consumeContext("syncingProcess"),
        consumeContext("apis"),
        consumeContext("toast"),
        [
            "getStatus",
            (_, next) =>
                next((row) => {
                    if (tablesErrorIDs.indexOf(row.id) > -1) return "error";
                    if (tablesSyncing.indexOf(row.id) > -1) return "syncing";
                    if (row.paused) return "paused";
                    if (row.syncSchedule.$type === "OneTimeSync") return "syncOnce";
                    if (row.lastSync) return "sync";
                    return "will-sync";
                }),
        ],
        [
            "dataSourceCM",
            ({getStatus, syncingProcess, apis, toast}, next) => {
                const {syncingProcessObserver} = syncingProcess;

                return PopupMenu({
                    getCommands: ({item}) => {
                        const syncStatus = getStatus(item);

                        return cs(
                            [
                                "onTogglePause",
                                ({}, next) =>
                                    next(async (paused) => {
                                        try {
                                            const apisData = paused ? apis.data.pauseTables : apis.data.resumeTables;
                                            await apisData(dataSource.value.id, [item.id]);

                                            toast.show(`${paused ? `Pause` : `Unpause`} ${item.name} table`);
                                            onReload();
                                        } catch (e) {
                                            toast.show(e.message, {
                                                isError: true,
                                            });
                                        }
                                    }),
                            ],
                            ({onTogglePause}) => [
                                {
                                    label: "Sync Detail",
                                    onClick: () =>
                                        dsSettings.onChange({
                                            selectedTable: item,
                                            syncStatus,
                                        }),
                                },
                                ...(!item.paused && ["sync", "will-sync"].includes(syncStatus)
                                    ? [
                                          {
                                              label: "Pause",
                                              onClick: () => onTogglePause(true),
                                          },
                                      ]
                                    : []),
                                ...(item.paused
                                    ? [
                                          {
                                              label: "Unpause",
                                              onClick: () => onTogglePause(false),
                                          },
                                      ]
                                    : []),
                                ...(dataSource.value.type !== dataSourceTypes.AMAZONDSP
                                    ? [
                                          {
                                              label: "Sync Now",
                                              disabled: ["error", "syncing", "paused"].includes(syncStatus),
                                              onClick: async () => {
                                                  await apis.data.forceSync(dsId, [
                                                      {
                                                          dataSourceTableID: item.id,
                                                          full: item.syncMethod === SyncMethodTypesToValue.Full,
                                                      },
                                                  ]);
                                                  syncingProcessObserver.start();

                                                  toast?.show(`Sync started on ${item.name} table`);
                                              },
                                          },
                                          {
                                              label: "Force Full Sync",
                                              disabled: ["error", "syncing", "paused"].includes(syncStatus),
                                              className: "noBorder",
                                              onClick: () =>
                                                  forceSyncDialog.show({
                                                      tables: [item],
                                                      dsid: dsId,
                                                      isForceFull: true,
                                                  }),
                                          },
                                      ]
                                    : []),
                            ]
                        );
                    },
                    next,
                });
            },
        ],
        ({dataSourceCM, getStatus}) => {
            return (
                <div className="sync-status-wrapper-99o">
                    {DataTable({
                        applyStyleOnParent: true,
                        style,
                        maxHeight: `100%`,
                        list: data,
                        initSorting: {asc: true, columnIndex: 2},
                        className: "sync-status-table-88e",
                        onActionEntireRow: (item) => {
                            return dsSettings.onChange({
                                selectedTable: item,
                                syncStatus: getStatus(item),
                            });
                        },
                        rowClassName: (item) => (selectedTables.value.findIndex((s) => s.id === item.id) > -1 ? "selected" : ""),
                        columns: [
                            {
                                labelF: () => {
                                    let checked = () =>
                                        selectedTables.value.length === data.length
                                            ? true
                                            : selectedTables.value.length > 0
                                            ? "partial"
                                            : false;

                                    return Checkbox({
                                        state: {
                                            value: checked(),
                                            onChange: () => {
                                                if (checked() === "partial" || checked() === false) {
                                                    selectedTables.onChange(data);
                                                } else {
                                                    selectedTables.onChange([]);
                                                }
                                            },
                                        },
                                    });
                                },
                                format: (row) =>
                                    Checkbox({
                                        state: {
                                            value: selectedTables.value.findIndex((s) => s.id === row.id) > -1,
                                            onChange: (value) =>
                                                selectedTables.change((old) =>
                                                    value ? [...old, row] : old.filter((o) => o.id !== row.id)
                                                ),
                                        },
                                    }),

                                shyF: (row) => selectedTables.value.length === 0,
                            },
                            {
                                label: "Next Sync",
                                format: (row) =>
                                    cs(
                                        [
                                            "syncStatus",
                                            (_, next) => {
                                                return next(getStatus(row));
                                            },
                                        ],
                                        ({syncStatus}) => {
                                            const rStatus = (status) => {
                                                const date = moment(new Date(row.nextSync));

                                                return (
                                                    {
                                                        syncing: (
                                                            <>
                                                                <div className="text">Syncing...</div>
                                                            </>
                                                        ),
                                                        error: (
                                                            <>
                                                                <div className="text">Disabled</div>
                                                                <div>{moment(new Date(row.lastSync)).fromNow()}</div>
                                                            </>
                                                        ),
                                                        paused: (
                                                            <>
                                                                <div className="text">Paused</div>
                                                            </>
                                                        ),
                                                        syncOnce: (
                                                            <>
                                                                <div className="text">Sync Once</div>
                                                            </>
                                                        ),
                                                    }[status] || (
                                                        <>
                                                            <div className="text">
                                                                {moment().isSame(date) ? `Today` : date.format("MMM Do")}
                                                            </div>
                                                            <div className="text">{date.format("hh:mm A")}</div>
                                                        </>
                                                    )
                                                );
                                            };

                                            return (
                                                <div className={cx(`next-sync ${syncStatus}`)}>
                                                    <div className="icon-wrapper">
                                                        {SyncStatusIcon({
                                                            type: syncStatus,
                                                        })}
                                                    </div>
                                                    <div className="sync-info">{rStatus(syncStatus)}</div>
                                                </div>
                                            );
                                        }
                                    ),
                                sortValue: (row) => row.nextSync,
                            },
                            {
                                label: !dataSource.value.structured ? "Object" : "Table",
                                className: "table-row-99e",
                                format: (row) => (
                                    <div className="table-info">
                                        <div className="name">{row.name}</div>
                                        <div className="sync-schedule">
                                            Sync Method: {row.syncMethod} -{" "}
                                            {row.syncSchedule.$type === "OneTimeSync" ? (
                                                <>Sync Once</>
                                            ) : (
                                                <>
                                                    Every {row.syncSchedule.every} {row.syncSchedule.interval}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                ),
                                sortValue: (row) => row.name,
                            },
                            {
                                label: "Latest Sync",
                                format: (row) => {
                                    if (row.lastSync === 0) return null;

                                    return row.lastSync === null ? (
                                        `Never Synced`
                                    ) : (
                                        <>
                                            <div>{moment(new Date(row.lastSync)).format("MMM DD, YYYY")}</div>
                                            <div>{moment(new Date(row.lastSync)).format("h:mm A")}</div>
                                        </>
                                    );
                                },
                                sortValue: (row) => row.lastSync,
                            },
                            {
                                label: "Latest Sync Data",
                                format: (row) => (row.sizeMB !== null ? `${formatNumber(row.sizeMB)} MB` : null),
                                sortValue: (row) => row.sizeMB,
                            },
                            {
                                label: "Latest Sync Records",
                                format: (row) => (row.records !== null ? formatNumber(row.records) : null),
                                sortValue: (row) => row.records,
                            },
                            {
                                label: "",
                                className: "sync-cm-12s",
                                format: (row) => (
                                    <div className="cm">
                                        {dataSourceCM.render({
                                            params: {item: row},
                                        })}
                                    </div>
                                ),
                                shy: true,
                                alignRight: true,
                            },
                        ],
                    })}
                </div>
            );
        }
    );
