import {rBarLineHeaderCompare, rBarLinePointsCompare} from "./column-points-table-compare";

export const getLinePointsTableForComboCompare = ({extractedPoints, generalInfo, tile, formatters, rawData, theme, hideMainCompareTitle}) => {
    return `${rBarLineHeaderCompare({
        extractedPoints,
        generalInfo,
        rawData,
        formatters,
        tile,
        theme,
        hideMainCompareTitle,
    })}
         ${rBarLinePointsCompare({
             extractedPoints,
             generalInfo,
             formatters,
             showDiffPercent: true,
             useLineIndicator: true,
             tile,
             theme,
         })}`;
};
