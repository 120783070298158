import React, {Component} from "react";
import {GlobalMouseMove} from "../../react/global-mouse-move";
import {GlobalMouseUp} from "../../react/global-mouse-up";
import {cx} from "emotion";

export class HorizontalScrollbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dragging: false,
        };
    }

    render() {
        const {dragging} = this.state;
        const {scrollWidth, containerWidth, scrollLeft, scrollbarContainer, horizontalThumb} = this.props;

        return (
            <>
                {dragging &&
                    GlobalMouseMove({
                        fn: (e) => {
                            const nextScrollTop = scrollWidth * ((e.clientX - this.grabClientX) / containerWidth);
                            scrollbarContainer.scrollLeft = this.lastScrollLeft + nextScrollTop;
                        },
                    })}

                {dragging &&
                    GlobalMouseUp({
                        fn: (e) => {
                            this.setState({dragging: false});
                        },
                    })}

                {scrollWidth && Math.abs(containerWidth - scrollWidth) > 3 ? (
                    <div className={cx("scrollbar-container-track", "horizontal", dragging && "dragging")}>
                        <div
                            className={cx("scrollbar-thumb", horizontalThumb && "visible")}
                            onMouseDown={(e) => {
                                this.grabClientX = e.clientX;
                                this.lastScrollLeft = scrollbarContainer.scrollLeft;
                                this.setState({dragging: true});
                            }}
                            style={{
                                width: `${(containerWidth / scrollWidth) * 100}%`,
                                left: `${(scrollLeft / scrollWidth) * 100}%`,
                            }}
                        />
                    </div>
                ) : null}
            </>
        );
    }
}
