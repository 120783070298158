import * as React from "react";
import {Display} from "./panels/display/display";
import {DataLabels} from "./panels/data-labels/data-labels";
import {Colors} from "./panels/colors/colors";
import {Legend} from "./panels/legend/legend";
import {TileTitle} from "./panels/tile-title/tile-title";
import {DonutHole} from "./panels/donut-hole/donut-hole";
import {OtherOptions} from "../common/other-options/other-options";

export const PieChart = ({tile, form, theme, size, parentTile, colorsTileConfig, tileAction, isContainerTile}) =>
    [Display, TileTitle, tile.value.$type === "DonutChartTile" && DonutHole, DataLabels, Colors, Legend, OtherOptions]
        .filter((v) => v)
        .map((panel) =>
            panel({
                tile,
                form,
                theme,
                size,
                parentTile,
                colorsTileConfig,
                tileAction,
                isContainerTile,
            })
        );
