import {arrEquals, flatten1, last} from "../../../../../../../common/utils/collections";

export const getDetailUnstructuredTable = (cTable, tables = [], disabledTables) => {
    let columns = [];
    let disabledColumns = [];

    const disabledTablesNames = disabledTables.map((t) => t.name);
    const list = [...tables, ...disabledTables];

    for (let k = 0; k < list.length; k++) {
        const t1 = list[k];
        if (t1.tableReferenceName.indexOf(cTable.tableReferenceName) > -1) {
            if (disabledTablesNames.includes(t1.name)) {
                disabledColumns = [...disabledColumns, t1.columns, t1.disabledColumns];
            } else {
                columns = [...columns, t1.columns];
                disabledColumns = [...disabledColumns, t1.disabledColumns];
            }
        }
    }

    return {
        columns: flatten1(columns),
        disabledColumns: flatten1(disabledColumns),
    };
};

export const buildUnstructuredTreeTable = (cTable, tables, disabledTables) => {
    const disabledTablesNames = disabledTables.map((t) => t.name);

    const _tables = [...tables, ...disabledTables]
        .filter((t) => t.name.indexOf(cTable.name) > -1 && t.isChildTable)
        .map((t) => ({
            ...t,
            disabled: disabledTablesNames.includes(t.name),
            stack: t.name.split(">").map((v) => v.trim()),
        }));

    const getChildrenTables = (stack) => {
        const children = _tables.filter((t) => {
            return stack.length + 1 === t.stack.length && arrEquals(t.stack.slice(0, stack.length), stack);
        });
        if (children.length === 0) return [];

        return children.map((c) => ({
            ...c,
            // disabled: disabledTablesNames.includes(c.name),
            originalName: last(c.stack),
            children: getChildrenTables(c.stack),
        }));
    };

    return {
        ...cTable,
        children: getChildrenTables([cTable.name]),
    };
};

export const getRealNameChildTable = (table) => last(table.name.split(">").map((v) => v.trim()));
