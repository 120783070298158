import {cs} from "../../../../react/chain-services";
import * as React from "react";
import {cx} from "emotion";
import "./loading-wrapper.scss";
import {ErrorHandler} from "./error-handler/error-handler";
import {LoadingSkeleton} from "./loading-skeletion";

export const LoadingWrapper = ({loading, animated, tile, apiError, error, size, chartOuterLayout, next: rootNext, theme}) =>
    cs(({}, next) => {
        const withLayout = (content) => (chartOuterLayout ? chartOuterLayout({content}) : content);

        // return (
        //     <div className={cx("loading-wrapper-23e", {loading})}>
        //         {withLayout(
        //             <div className="loading-container">
        //                 <LoadingSkeleton tile={tile} theme={theme}/>
        //             </div>
        //         )}
        //     </div>
        // )

        return apiError || error ? (
            <div className={cx("loading-wrapper-23e", {loading})}>
                {withLayout(
                    apiError ? (
                        ErrorHandler({error: apiError, size, tile})
                    ) : (
                        <div className="loading-container">
                            <LoadingSkeleton animated={animated} tile={tile} theme={theme} />
                        </div>
                    )
                )}
            </div>
        ) : (
            next()
        );
    }, rootNext);
