import {UseState} from "@common/react/use-state";
import {cs} from "@common/react/chain-services";
import * as React from "react";
import {consumeContext} from "@common/react/context";
import {Form2} from "@common/react/cs-form/form2";
import {required} from "@common/react/cs-form/validators/required";
import {GuestLayout} from "../common/layout/guest-layout";
import {minLength} from "@common/react/cs-form/validators/min-length";
import {email} from "@common/react/cs-form/validators/email";

export const ResetPasswordRoute = ({}) =>
    cs(
        consumeContext("apis"),
        consumeContext("routing"),
        ["state", (_, next) => UseState({next})],
        [
            "params",
            ({routing}, next) =>
                UseState({
                    getInitValue: () => ({
                        email: routing.params.email,
                        resetToken: routing.params.resetToken,
                        newPassword: "",
                        newPasswordConfirm: "",
                    }),
                    next,
                }),
        ],

        [
            "form",
            ({params, state, routing, apis}, next) =>
                Form2({
                    fields: {
                        email: [required, email],
                        resetToken: [required, minLength(4)],
                        newPassword: [required, minLength(5)],
                        newPasswordConfirm: [
                            required,
                            {
                                validate: (confirm, {data}) => !data.newPassword || confirm === data.newPassword,
                            },
                        ],
                    },
                    data: params,
                    onSubmit: async () => {
                        state.onChange({loading: true});

                        try {
                            await apis.completePasswordReset(params.value);

                            routing.goto("login", {
                                message: "resetPasswordSuccess",
                            });
                            // TODO Change Auth
                        } catch (error) {
                            state.onChange({
                                loading: false,
                                error: error.message,
                            });
                        }
                    },
                    next,
                }),
        ],

        ({state, form, routing}) =>
            GuestLayout({
                header: "Reset Password",
                error: state.value?.error,
                message: "Please check your email for a reset token.",
                form: [
                    {
                        label: "Email Address",
                        field: form.field("email"),
                        disabled: true,
                    },
                    {
                        label: "Reset Token",
                        field: form.field("resetToken"),
                    },
                    {
                        label: "New Password",
                        field: form.field("newPassword"),
                        type: "password",
                    },
                    {
                        label: "Confirm New Password",
                        field: form.field("newPasswordConfirm"),
                        type: "password",
                    },
                ],
                onSubmit: form.submit,
                link: {
                    label: "Back to login",
                    to: routing.toPath("login"),
                },
                submitBtn: {
                    label: "Reset password",
                    disabled: state.value?.loading,
                },
            })
    );
