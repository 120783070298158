import {isShowGridStepLabel, isShowXAxisLabel} from "../../../../web-client/src/routes/collection/tile/edit/left-panel/tabs/tile-tab/chart-types/combo-chart/panels/x-axis/x-axis-rules-comp";
import {isShowYAxisLabel, isShowYGridStepLabel} from "../../../../web-client/src/routes/collection/tile/edit/left-panel/tabs/tile-tab/chart-types/horizontal-bar/panels/y-axis/y-axis-rules-comp";
import {getLegendPosition} from "../../../../web-client/src/routes/collection/tile/edit/left-panel/tabs/tile-tab/chart-types/combo-chart/panels/legend/legend-display-rules";
import {getLegendContentValue} from "../../../../web-client/src/routes/collection/tile/edit/left-panel/tabs/tile-tab/chart-types/common/legend-display-select";
import {isFunnelLegendHiddenBySize} from "../../../../web-client/src/routes/collection/tile/edit/left-panel/tabs/tile-tab/chart-types/funnel-chart/panels/legend";
import {isSectionLabelHidden} from "../../../../web-client/src/routes/collection/tile/edit/left-panel/tabs/tile-tab/chart-types/venn-diagram/display-rules";
import {isScatterLegendHidden} from "../../../../web-client/src/routes/collection/tile/edit/left-panel/tabs/tile-tab/chart-types/scatter-plot/panels/legend/legend";
import {arrMapToO} from "../../../utils/objects";

export const isDisabledChartSetting = ({tile, size}) => {
    if (["ScatterPlotTile", "BubbleChartTile"].includes(tile?.$type)) {
        return isScatterLegendHidden({size});
    }

    if (tile?.style?.xAxis) {
        return !isShowXAxisLabel({tile, size}) || !isShowGridStepLabel({size}) || !isShowYAxisLabel({size, tile}) || !isShowYGridStepLabel({size, tile});
    }

    if (tile?.$type === "FunnelChartTile") {
        return isFunnelLegendHiddenBySize({size});
    }

    if (tile?.$type === "VennDiagramTile") {
        return isSectionLabelHidden({size});
    }

    return false;
};

// this is for not showing data labels on mobile AB#500
const updateDataLabels = ({tile}) => {
    if (["VerticalBarChartTile", "HorizontalBarChartTile", "LineChartTile"].includes(tile.$type)) {
        return {
            dataLabels: {
                ...tile.style.dataLabels,
                show: window.innerWidth <= 769 ? false : tile.style.dataLabels.show,
            },
        };
    }

    if (tile.$type === "ComboChartTile") {
        return arrMapToO(["yAxisLineDataLabels", "yAxisBarDataLabels"], (prop) => ({
            ...tile.style[prop],
            show: window.innerWidth <= 769 ? false : tile.style[prop].show,
        }));
    }

    return {};
};

export const chartRestyling = ({tile, size}) => {
    const updateAxis = ({value, canShowLabel, canShowGridStepLabel}) => {
        if (value.$type) {
            return {
                ...value,
                labelShown: canShowLabel ? value.labelShown : false,
                gridStepLabelsShown: canShowGridStepLabel ? value.gridStepLabelsShown : false,
            };
        } else {
            const labelShow = window.innerWidth <= 769 ? false : canShowLabel;

            let ret = {};
            for (let key in value) {
                ret[key] = {
                    ...value[key],
                    labelShown: labelShow ? value[key].labelShown : false,
                    gridStepLabelsShown: canShowGridStepLabel ? value[key].gridStepLabelsShown : false,
                };
            }

            return ret;
        }
    };

    let legendStyle = tile.style.legendStyle;

    if (tile.style.legendStyle) {
        const legendPosition = getLegendPosition(tile.style.legendStyle.legendPosition, size, tile.$type);
        legendStyle = {
            ...tile.style.legendStyle,
            legendPosition,
            legendShown: !legendPosition ? false : tile.style.legendStyle.legendShown,
            legendContent: getLegendContentValue(tile.style.legendStyle.legendContent, tile.style.legendStyle),
        };
    }

    if (tile.style.xAxis) {
        const canShowXAxisLabel = isShowXAxisLabel({tile, size});
        const canShowXGridStepLabel = isShowGridStepLabel({size});
        const canShowYAxisLabel = isShowYAxisLabel({size, tile});
        const canShowYGridStepLabel = isShowYGridStepLabel({size, tile});

        return {
            ...tile,
            style: {
                ...tile.style,
                xAxis: updateAxis({
                    value: tile.style.xAxis,
                    canShowLabel: canShowXAxisLabel,
                    canShowGridStepLabel: canShowXGridStepLabel,
                }),
                ...(tile.style.yAxis
                    ? {
                          yAxis: updateAxis({
                              value: tile.style.yAxis,
                              canShowLabel: canShowYAxisLabel,
                              canShowGridStepLabel: canShowYGridStepLabel,
                          }),
                      }
                    : {}),
                ...(tile.style.yAxisBar
                    ? {
                          yAxisBar: updateAxis({
                              value: tile.style.yAxisBar,
                              canShowLabel: canShowYAxisLabel,
                              canShowGridStepLabel: canShowYGridStepLabel,
                          }),
                      }
                    : {}),
                ...(tile.style.yAxisLine
                    ? {
                          yAxisLine: updateAxis({
                              value: tile.style.yAxisLine,
                              canShowLabel: canShowYAxisLabel,
                              canShowGridStepLabel: canShowYGridStepLabel,
                          }),
                      }
                    : {}),
                legendStyle,
                ...updateDataLabels({tile}),
            },
        };
    }

    return {
        ...tile,
        style: {
            ...tile.style,
            legendStyle,
        },
    };
};
