import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {SimpleSearch} from "@common/utils/simple-search";
import {createSchema} from "@common/utils/normalize";
import {keepOnly, omit} from "@common/utils/objects";
import {provideContext} from "@common/react/context";
import {flatten1, unique} from "@common/utils/collections";
import {fragments} from "@common/react/fragments";
import {Watch} from "@common/react/watch";
import {Static2} from "@common/react/static-2";
import {cyrb53} from "@common/utils/strings";
import {Invoke} from "@common/react/invoke";

const columnSchema = createSchema(
    "columns",
    {},
    {
        idAttribute: (value, parent) => {
            return `dataSourceID:${parent?.dataSourceID ?? value.dataSourceID ?? ""}_dataSourceTableID:${
                parent?.dataSourceTableID ?? value.dataSourceTableID ?? ""
            }_columnID:${value.id}`;
        },
        processStrategy: (value, parent, key) => {
            const _value = {
                ...value,
                _metadata: {
                    schema: key,
                    parentSchema: parent?._metadata?.schema,
                    parent: parent?._id ?? parent?.id,
                },
            };

            if (!value.$type) {
                return {
                    ..._value,
                    $type: "OriginalColumn",
                    parent: parent?._id ?? parent?.id,
                    dataSourceTableID: parent?.dataSourceTableID,
                    dataSourceID: parent?.dataSourceID,
                };
            }

            return {
                ..._value,
                parent: parent?._id ?? parent?.id,
            };
        },
    }
);

const tableSchema = createSchema(
    "tables",
    {
        columns: [columnSchema],
    },
    {
        idAttribute: (value, parent) => {
            return `dataSourceID:${parent?.dataSourceID ?? parent?.id ?? value.dataSourceID ?? ""}_tableID:${value.id}`;
        },
        processStrategy: (value, parent, key) => {
            const _value = {
                ...omit(value, ["disabledColumns"]),
                _metadata: {
                    schema: key,
                    parentSchema: parent?._metadata?.schema,
                    parent: parent?._id ?? parent?.id,
                },
            };

            if (!value.$type) {
                return {
                    ..._value,
                    $type: "OriginalTable",
                    dataSourceID: parent?.id,
                    dataSourceTableID: value.id,
                    parent: parent?._id ?? parent?.id,
                };
            }

            return {
                ..._value,
                parent: parent?._id ?? parent?.id,
            };
        },
    }
);

const modelTable = createSchema(
    "modelTables",
    {
        columns: [columnSchema],
    },
    {
        idAttribute: (value, parent) => {
            return `dataSourceID:${parent?.dataSourceID ?? value.dataSourceID ?? ""}_tableID:${value.id}`;
        },
        processStrategy: (value, parent, key) => {
            if (value.$type == "ViewModelTable") {
                return {
                    ...value,
                    _metadata: {
                        schema: key,
                    },
                };
            }

            return {
                ...value,
                _metadata: {
                    schema: key,
                    parentSchema: "dataSources",
                    parent: value.dataSourceID,
                },
                parent: value.dataSourceID,
            };
        },
    }
);

const dataSourceSchema = createSchema(
    "dataSources",
    {
        tables: [tableSchema],
    },
    {
        processStrategy: (value, parent) => {
            return {
                ...omit(value, ["disabledTables", "connectionDetails"]),
                _metadata: {
                    schema: "dataSources",
                    parent: parent?.id,
                },
                parent: parent?.id,
            };
        },
    }
);

export const DataSourceModelContext = ({dataSources, model, next: rootNext}) => {
    return cs(
        [
            "dbRef",
            (_, next) =>
                Static2({
                    next,
                    getInitValue: () =>
                        new SimpleSearch({
                            schema: {dataSources: [dataSourceSchema], modelTables: [modelTable]},
                            data: {
                                dataSources: dataSources?.map((dS, index) => ({...dS, dsIndex: index})),
                                modelTables: model?.tables ?? [],
                            },
                            searchProperty: (item) => item.visualName ?? item.name,
                        }),
                }),
        ],

        ["forceUpdate", ({db}, next) => UseState({next, initValue: 0})],

        ({dbRef, forceUpdate}) => {
            const db = dbRef.get();

            const getDataSourceTableUsage = ({dsTableId, dsID}) => {
                const _modelTables = db.getEntities("modelTables", {
                    isSearchResults: false,
                    filterFn: (modelTable) => modelTable.dataSourceTableID == dsTableId && modelTable.dataSourceID == dsID,
                });

                if (_modelTables.length == 0) {
                    return "none";
                }

                return "all";
            };

            const relationshipColumns = model.relationships
                ? unique(flatten1(model.relationships.map((r) => [r.leftColumnID, r.rightColumnID])))
                : [];

            const reset = (data) => {
                let entities = db.reset(data);
                setTimeout(() => forceUpdate.change((c) => c + 1));
                return entities;
            };

            const search = (data) => {
                let results = db.search(data);
                setTimeout(() => forceUpdate.change((c) => c + 1));
                return results;
            };

            return fragments(
                Invoke({
                    onMounted: () => {
                        window.db = db;
                    },
                }),
                Watch({
                    value: cyrb53(
                        JSON.stringify({
                            dataSources: dataSources?.length,
                            modelTables: (model?.tables ?? []).map((t) => omit(t, ["position"])),
                        })
                    ),
                    onChanged: () => {
                        reset({dataSources: dataSources?.map((dS, index) => ({...dS, dsIndex: index})), modelTables: model?.tables ?? []});
                    },
                }),
                provideContext(
                    "dataSourceModelContext",
                    {
                        entities: db.entities,
                        relationships: model.relationships,
                        search,
                        getSearchText: () => db._searchText,
                        reset,
                        getEntities: (...args) => db.getEntities(...args),
                        getModelColumn: (colID) => {
                            const column = db.entities.columns[colID];
                            const modelTable = db.entities.modelTables[column.parent];
                            const ds = db.entities.modelTables[modelTable.dataSourceID];
                            return {
                                column,
                                table: keepOnly(modelTable ?? {}, ["$type", "id", "name", "visualName"]),
                                ...(modelTable?.$type === "ViewModelTable"
                                    ? {
                                          dvIndex: dataSources?.length,
                                      }
                                    : {
                                          dsIndex: ds?.dsIndex ?? dataSources?.findIndex((ds) => ds.id === modelTable.dataSourceID),
                                      }),
                            };
                        },
                        getDataSourceUsage: (ds) => {
                            const _modelTables = db.getEntities("modelTables", {
                                isSearchResults: false,
                                filterFn: (modelTable) => modelTable.dataSourceID == ds.id,
                            });

                            if (_modelTables.length == 0) {
                                return "none";
                            }

                            const dsTables = db.getEntities("tables", {
                                isSearchResults: false,
                                filterFn: (dsTable) => dsTable.dataSourceID == ds.id,
                            });

                            const usedDSTables = dsTables.filter((t) => _modelTables.map((mT) => mT.dataSourceTableID).includes(t.id));

                            if (ds.tables.length == usedDSTables.length) {
                                return "all";
                            }

                            return "partial";
                        },
                        getDataSourceTableUsage,
                        getRelationshipUsage: ({dsID, dsTableId, dsColumnId, columnId}) => {
                            if (dsTableId && dsID && getDataSourceTableUsage({dsTableId, dsID}) === "none") {
                                return false;
                            }

                            if (columnId) {
                                return relationshipColumns.includes(columnId);
                            }

                            if (!columnId && dsColumnId) {
                                const _columns = db.getEntities("columns", {
                                    isSearchResults: false,
                                    filterFn: (column) =>
                                        column.dataSourceColumnID == dsColumnId &&
                                        column.dataSourceID == dsID &&
                                        column.dataSourceTableID == dsTableId,
                                });

                                const ids = _columns.map((c) => c.id);

                                return relationshipColumns.find((cid) => ids.includes(cid));
                            }

                            return false;
                        },
                    },
                    rootNext
                )
            );
        }
    );
};
