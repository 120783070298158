import * as React from "react";

import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {UseState} from "@common/react/use-state";
import {TextInput} from "@common/form/text-input/text-input";
import {Button} from "@common/form/buttons/button/button";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";
import {DialogService} from "../../../../common/dialog/dialog-service";

export const DeleteKeyDialog = ({onDone, entityDisplayName, entityName, title, next: rootNext, reloadFolderOnDone = false}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    render: ({resolve, args: {items}}) => ({
                        title,
                        width: 500,
                        content: next({
                            resolve,
                            items,
                        }),
                    }),
                    next: rootNext,
                }),
        ],
        consumeContext("apis"),
        ["confirmName", (_, next) => UseState({next})],
        ["submitting", (_, next) => UseState({next})],
        ({modal, confirmName, submitting, apis}) => {
            const {items} = modal;
            const hasManyItems = items.length > 1;
            const isDisabled = (hasManyItems ? !confirmName.value || confirmName.value !== "delete" : false) || submitting.value;
            const message = !hasManyItems
                ? `Are you sure you want to delete ${items[0].name} ${entityDisplayName}? This action cannot be undone.`
                : `Are you sure you want to delete ${items.length} ${entityDisplayName}s?`;

            return (
                <form
                    onSubmit={async (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (isDisabled) {
                            return;
                        }

                        submitting.onChange(true);

                        await Promise.all(items.map((item) => apis[entityName].removeKey(item.id)));

                        const newFolders = reloadFolderOnDone ? await apis[entityName].getFolders() : null;
                        onDone(newFolders);
                        submitting.onChange(false);
                        modal.resolve(true);
                    }}
                >
                    <div className="delete-collection-dialog-57a">
                        <VerbDialogBodyScrollbar>
                            <div className="main-part">
                                <div className="warning">{message}</div>

                                {hasManyItems &&
                                    TextInput({
                                        label: (
                                            <span className="confirm-input-label-5sj">
                                                {hasManyItems
                                                    ? "Please type DELETE to confirm"
                                                    : "Type the name of the model to confirm deletion."}
                                            </span>
                                        ),
                                        state: confirmName,
                                    })}
                            </div>
                        </VerbDialogBodyScrollbar>

                        <div className="buttons">
                            <Button type="button" btnType="secondary" onClick={() => modal.resolve()}>
                                Cancel
                            </Button>
                            <Button disabled={isDisabled} type="submit" btnType="primary-danger">
                                Delete
                            </Button>
                        </div>
                    </div>
                </form>
            );
        }
    );
