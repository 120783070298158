const {equalDeep} = require("../utils/objects");
const {Interval} = require("./interval");
const {fragments} = require("./fragments");
const {Static2} = require("./static-2");
const {cs} = require("./chain-services");

const ObserveDomSize0 = ({onChange, next, startImmediately = false}) =>
    cs(["domRef", (_, next) => Static2({next})], ["size", (_, next) => Static2({next})], ({domRef, size}) =>
        fragments(
            size.get() &&
                Interval({
                    delay: 500,
                    fn: () => {
                        const dom = domRef.get();
                        if (!dom) {
                            return;
                        }

                        const r = dom.getBoundingClientRect();
                        const size1 = {
                            width: r.width,
                            height: r.height,
                        };
                        const s1 = size.get();

                        if (!equalDeep(s1, size1)) {
                            size.set(size1);
                            onChange?.(size1, dom);
                        }
                    },
                }),

            next({
                ref: (dom) => {
                    domRef.set(dom);

                    if (!size.get() && dom) {
                        const r = dom.getBoundingClientRect();
                        const size1 = {
                            width: r.width,
                            height: r.height,
                        };
                        size.set(size1);
                        if (startImmediately) {
                            onChange?.(size1, dom);
                        }
                    }
                },
                element: domRef.get(),
                // value: size.get(),
            })
        )
    );
exports.ObserveDomSize0 = ObserveDomSize0;
