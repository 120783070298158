import {isFunnelLegendHidden} from "../../../../web-client/src/routes/collection/tile/edit/left-panel/tabs/tile-tab/chart-types/funnel-chart/panels/legend";
import {isFunnelSectionLabelsHidden} from "../../../../web-client/src/routes/collection/tile/edit/left-panel/tabs/tile-tab/chart-types/funnel-chart/panels/section-labels";
import {cascadeChange} from "../../../utils/cascade";

export const funnelChartRestyling = ({tile, size}) => {
    return cascadeChange(tile, "style", (style) => ({
        ...style,
        legendStyle: {
            ...style.legendStyle,
            legendShown: style.legendStyle.legendShown && !isFunnelLegendHidden({tile, size}),
        },
        dataLabels: {
            ...style.dataLabels,
            show: style.dataLabels?.show && !isFunnelSectionLabelsHidden({tile, size}),
        },
    }));
};
