import "./edit-model-layout.scss";

import {cx} from "emotion";
import React from "react";

import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {SuspendUpdate} from "@common/react/suspend-update";
import {LoadingIndicator} from "@common/ui-components/loading-indicator/loading-indicator";
import {waitTimeout} from "@common/utils/wait-timeout";
import {Badge} from "../../../common/badge/badge";
import {HeaderBar} from "../../../common/header-bar/header-bar";
import {DeleteModelDialog} from "../../../dashboard/env/models/delete-model-dialog";
import {RenameModelDialog} from "../../common/rename-model-dialog/rename-model-dialog";
import {MoveToDialog} from "../../../dashboard/env/common/move-to-dialog/move-to-dialog";
import {ModelErrorsBadgeHeader} from "./error-badge/model-errors-badge-header";

export const defaultLeftPanelWidth = 320;
export const EditModelLayout = ({model, onBack, labelText, saving, leftPanelTabs, leftPanelOverride, selectedTab, main, leftPanelAction, leftPanelWidth, dataSources}) =>
    cs(
        consumeContext("tenant"),
        consumeContext("routing"),
        consumeContext("routeTransitioning"),
        consumeContext("toast"),
        ["renameModelDialog", ({collection}, next) => RenameModelDialog({next, onDone: () => model.reload()})],
        [
            "deleteModelDialog",
            ({routing, toastService, toast}, next) =>
                DeleteModelDialog({
                    onDone: async () => {
                        const envId = routing.params.envId;
                        routing.goto("dashboard", {envId, tab: "models"});
                    },
                    next,
                }),
        ],
        [
            "modelCommands",
            ({renameModelDialog, deleteModelDialog}, next) =>
                cs(
                    [
                        "moveModelDialog",
                        (_, next) =>
                            MoveToDialog({
                                apiType: "model",
                                next,
                            }),
                    ],
                    ({moveModelDialog}) =>
                        next([
                            {
                                label: "Rename",
                                onClick: () => renameModelDialog.show({model: model.value}),
                                id: "rename",
                            },
                            {
                                label: "Move",
                                onClick: () => moveModelDialog.show({params: model.value}),
                                id: "move",
                            },
                            {
                                label: "Delete Model",
                                className: "delete-action",
                                onClick: () => deleteModelDialog.show({models: [model.value]}),
                                id: "delete",
                            },
                        ])
                ),
        ],
        ({routeTransitioning, modelCommands}) => (
            <div className={cx("edit-model-layout-dg7", {transitioning: routeTransitioning?.transitioning}, routeTransitioning?.transitioning)}>
                {HeaderBar({
                    ID: model?.value?.id,
                    type: "model",
                    name: labelText,
                    commands: modelCommands,
                    previousRoutes: ["dashboard", "edit-collection", "edit-data-source"],
                    shareActions: () => (
                        <div className="model-status-bar">
                            {model?.value && dataSources && ModelErrorsBadgeHeader({model: model.value, dataSources})}

                            {saving ? (
                                <div className={cx("save-status")}>
                                    <i className="fa fa-spinner fa-pulse" />
                                    Saving
                                </div>
                            ) : (
                                Badge({
                                    size: "lg",
                                    icon: <i className="far fa-check" />,
                                    type: "success",
                                    label: "Saved",
                                })
                            )}
                        </div>
                    ),
                })}

                {!model?.value ? (
                    LoadingIndicator({className: "loading-model"})
                ) : (
                    <div className="center">
                        <div className="left-panel" style={{flex: `0 0 ${leftPanelWidth || defaultLeftPanelWidth}px`}}>
                            <div className={cx("main", {active: !leftPanelOverride})}>
                                {cs(
                                    ({}, next) => SuspendUpdate({active: leftPanelOverride, next}),
                                    () => leftPanelAction
                                )}
                            </div>

                            <div className={cx("override", {active: leftPanelOverride})}>
                                {cs(
                                    ({}, next) => SuspendUpdate({active: !leftPanelOverride, next}),
                                    () => leftPanelOverride
                                )}
                            </div>
                        </div>

                        <div className="content">
                            <div className="absolute-box">{main}</div>
                        </div>
                    </div>
                )}
            </div>
        )
    );
