import React from "react";
import {cs} from "@common/react/chain-services";
import {TrashIcon} from "../../../../../../common/icons/trash-icon";
import {MoveIconSvg} from "../../../../../../common/icons/move-icon-svg";
import "./column-control.scss";
import {cx} from "emotion";

export const rColumnControl = ({columnSelector, propertySelector, propertyInput, propertySelector2, onRemove, onStartDragging, dragging}) => {
    return (
        <div className="column-control-fhj column-control">
            {onStartDragging && (
                <div className={cx("dragging-anchor", {dragging})} onMouseDown={onStartDragging}>
                    {MoveIconSvg({fill: "#546B81"})}
                </div>
            )}

            <div className="line-1">
                <div className={cx("column-selector")}>{columnSelector}</div>

                <div className="property-selector">{propertySelector ? propertySelector : propertyInput ? propertyInput : <></>}</div>
            </div>

            {propertySelector && propertyInput && (
                <div className="line-2">
                    <div className="property-input">{propertyInput}</div>
                </div>
            )}

            {propertySelector2 && (
                <div className="line-2">
                    <div className="property-selector-2">{propertySelector2}</div>
                </div>
            )}

            {onRemove && (
                <div className="delete-btn" onClick={onRemove}>
                    {TrashIcon()}
                </div>
            )}
        </div>
    );
};
