import "./dropdown-multi-select-search-live.scss";

import React from "react";
import {cx} from "emotion";

import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {Checkbox} from "../../../../form/checkbox/checkbox";
import {Dropdown} from "../../../../dropdown/dropdown";
import {SearchInput} from "@common/form/search-input/search-input";
import {expandDistance} from "../control-styles-config";
import {sort} from "@common/utils/collections";
import {consumeContext} from "@common/react/context";
import {keyed} from "@common/react/keyed";
import {isMatchText} from "@common/utils/strings";
import {LoadingIndicator} from "../../../../loading-indicator/loading-indicator";
import {GhostButton} from "@common/form/buttons/ghost-button/ghost-button";
import {Button} from "@common/form/buttons/button/button";
import {FixedSizeList} from "react-window";
import {OnUnmounted} from "@common/react/on-unmounted";
import {
    getHeightOfDropdown,
    getInputSearchCss,
    ITEM_SIZE_HEIGHT,
    MAX_WIDTH,
} from "@common/ui-components/live/filters/common/dropdown-select/dropdown.config";
import {StyledClass} from "@common/react/styled-class";

export const DropdownMultiSelectSearchLive = ({
    list,
    isSelected,
    onSelect,
    isSelectedAll,
    onSelectAll,
    resetBtnClassName = "",
    applyBtnClassName = "",
    onApply,
    onClose,
    label,
    toggleLabel,
    valueToLabel = (v) => v,
    valueToSearch = (v) => v,
    checkboxBackground,
    className,
    hasError,
    errorMessage,
    minExpandHeight,
    toggleStyle,
    iconColor,
    dropdownCss,
    borderRadius,
    previewMode,
    closeAllOtherDropdownWhenOpen,
    onPassiveClose,
    loading = false,
    fixMaxWidth = true,
}) =>
    cs(
        consumeContext("theme"),
        [
            "searching",
            (_, next) =>
                UseState({
                    // initValue: {front, behind},
                    next,
                }),
        ],
        ({searching, theme}) => {
            const renderToggle = ({showExpand, showingExpand}) =>
                cs(() => (
                    <div
                        className={cx("toggle", {expanding: showingExpand})}
                        style={toggleStyle}
                        onClick={() => {
                            showExpand(!showingExpand);
                        }}
                    >
                        {toggleLabel}
                        <i
                            className="fa fa-chevron-down"
                            style={{
                                color: iconColor,
                            }}
                        />
                    </div>
                ));

            const renderExpand = ({boxShadow, width, close}) =>
                cs(
                    keyed(list?.length ?? Date.now()),
                    [
                        "localList",
                        (_, next) =>
                            UseState({
                                initValue: sort(list, (l) => (isSelected(l) ? 0 : 1)),
                                next,
                            }),
                    ],
                    ({localList}) => {
                        const searched = localList.value?.filter((l) => isMatchText(valueToSearch(l), searching.value?.behind));
                        const componentTheme = theme.general.components;

                        return cs(
                            [
                                "inputSearchCss",
                                (_, next) =>
                                    StyledClass({
                                        content: getInputSearchCss(componentTheme, theme),
                                        next,
                                    }),
                            ],
                            ({inputSearchCss}) => (
                                <div
                                    className="expand-group"
                                    style={{
                                        maxWidth: fixMaxWidth ? "100%" : "auto",
                                        minWidth: width,
                                        background: componentTheme.menuBackgroundColorRGB,
                                        ...(boxShadow ? {boxShadow: "0 3px 6px #00000029"} : {}),
                                    }}
                                >
                                    {onClose &&
                                        OnUnmounted({
                                            action: () => {
                                                onClose?.();
                                            },
                                        })}
                                    {cs(
                                        (_, next) => (!list || loading ? <div style={{padding: 24}}>{LoadingIndicator({})}</div> : next()),
                                        () => (
                                            <>
                                                {SearchInput({
                                                    state: {
                                                        value: searching.value?.front,
                                                        onChange: (v) =>
                                                            searching.onChange({
                                                                front: v,
                                                                behind: v,
                                                            }),
                                                    },
                                                    placeholder: "Search...",
                                                    delay: 300,
                                                    autoFocus: true,
                                                    className: inputSearchCss,
                                                })}

                                                <div className="list">
                                                    {!searched ? (
                                                        <div
                                                            className="no-results"
                                                            style={{
                                                                background: componentTheme.menuBackgroundColorRGB,
                                                                color: componentTheme.menuTextColorRGB,
                                                            }}
                                                        >
                                                            {LoadingIndicator({})}
                                                        </div>
                                                    ) : !searched?.length ? (
                                                        <div
                                                            className="no-results"
                                                            style={{
                                                                background: componentTheme.menuBackgroundColorRGB,
                                                                color: componentTheme.menuTextColorRGB,
                                                            }}
                                                        >
                                                            No results found.
                                                        </div>
                                                    ) : (
                                                        <>
                                                            {(() => {
                                                                let modifiedList = [...searched];

                                                                if (isSelectedAll && !modifiedList.includes("All")) {
                                                                    modifiedList = ["All", ...modifiedList];
                                                                }

                                                                return (
                                                                    <FixedSizeList
                                                                        width={width > MAX_WIDTH ? width : MAX_WIDTH}
                                                                        height={getHeightOfDropdown(modifiedList)}
                                                                        itemData={{
                                                                            list: modifiedList,
                                                                            isSelected,
                                                                            valueToLabel,
                                                                            dropdownCss,
                                                                            onSelect,
                                                                            isSelectedAll: () => isSelectedAll(searched),
                                                                            onSelectAll: () => onSelectAll(searched),
                                                                            checkboxBackground,
                                                                        }}
                                                                        itemCount={modifiedList.length}
                                                                        itemSize={ITEM_SIZE_HEIGHT}
                                                                    >
                                                                        {Row}
                                                                    </FixedSizeList>
                                                                );
                                                            })()}
                                                        </>
                                                    )}
                                                </div>

                                                {onSelectAll && (
                                                    <div className="actions">
                                                        <GhostButton
                                                            className={resetBtnClassName}
                                                            size="small"
                                                            onClick={() => onSelectAll([])}
                                                        >
                                                            Reset
                                                        </GhostButton>
                                                        {onApply && (
                                                            <Button
                                                                className={applyBtnClassName}
                                                                size="small"
                                                                onClick={() => {
                                                                    onApply();
                                                                    close();
                                                                }}
                                                            >
                                                                Apply
                                                            </Button>
                                                        )}
                                                    </div>
                                                )}
                                            </>
                                        )
                                    )}
                                </div>
                            )
                        );
                    }
                );

            return (
                <div className={cx("dropdown-multi-select-search-live dropdown-multi-select-search-live-g43", className, {hasError})}>
                    {!previewMode &&
                        Dropdown({
                            renderToggle,
                            renderExpand,
                            minExpandHeight,
                            expandDistance,
                            onPassiveClose: () => {
                                onPassiveClose?.();
                                searching.onChange(null);
                            },
                            borderRadius,
                            detectOnWheelEvent: true,
                            closeAllOtherDropdownWhenOpen,
                        })}

                    {previewMode && renderExpand({width: 210, boxShadow: true})}

                    {hasError && errorMessage && <div className="error-message">{errorMessage}</div>}
                </div>
            );
        }
    );

const Row = ({data, index, style}) => {
    const {list, isSelected, dropdownCss, valueToLabel, onSelect, checkboxBackground, isSelectedAll, onSelectAll} = data;
    let value = list[index];

    if (value === "All") {
        return (
            <div key={index} className={cx("item", dropdownCss)} onClick={() => onSelectAll()}>
                {Checkbox({
                    readOnly: true,
                    state: {
                        value: isSelectedAll(),
                        onChange: () => {},
                    },
                    background: checkboxBackground,
                })}
                Select All In View
            </div>
        );
    }

    return (
        <div
            key={index}
            className={cx(
                "item",
                {
                    selected: isSelected(value),
                },
                dropdownCss
            )}
            onClick={() => onSelect(value)}
            style={style}
        >
            {Checkbox({
                readOnly: true,
                state: {
                    value: isSelected(value),
                    onChange: () => {},
                },
                background: checkboxBackground,
            })}
            <div className="text">{valueToLabel(value)}</div>
        </div>
    );
};
