// const {getPieStack} = require("../get-pie-stack");
const {getDataLabelsOptions} = require("./data-labels");

const getPlotOptions = ({tile, theme, haloSize, expandFullSize, isCompare, formatters, onClickPoint}) => {
    let dataVizTheme = theme.dataVisualization.pieAndDonutCharts;

    return {
        pie: {
            dataLabels: getDataLabelsOptions({
                tile,
                theme,
                formatters,
                isCompare,
            }),
            showInLegend: !!tile.style.legendStyle.legendShown,

            // size: getPieStack({size, legendStyle: tile.style.legendStyle}) === "horizontal"
            //     ? (isCompare ? (tile.style.dataLabels.show ? "70%" : "75%") : "85%")
            //     : (isCompare ? (tile.style.dataLabels.show ? "60%" : "65%") : "100%"),

            ...(expandFullSize && {
                slicedOffset: 0,
                size: "100%",
            }),

            // ...(tile.$type === "DonutChartTile" ? {
            //     innerSize: `${tile.style.holeDiameter}%`,
            // } : {
            //     innerSize: 0,
            // }),

            // TODO: clarify theme.dataVisualization.pieAndDonutCharts  theme.general.tile.styles collection.gridLocation.tile.style
            borderWidth: dataVizTheme.showBorder ? dataVizTheme.borderWidth || 0 : 0,
            borderColor: dataVizTheme.borderColorRGB,
        },
        series: {
            states: {
                inactive: {
                    opacity: 1,
                },
                hover: {
                    halo: {
                        size: haloSize,
                    },
                },
            },
            cursor: onClickPoint ? "pointer" : "default",
            point: {
                events: {
                    click: function ({x, y, point}) {
                        onClickPoint?.({x, y, point, chart: this.series.chart});
                    },
                },
            },
        },
    };
};
exports.getPlotOptions = getPlotOptions;
