import * as React from "react";
import {ShowHideToggle} from "@common/form/toggles/show-hide-toggle";
import {LpLine} from "../../../../../../../../../../common/left-panel/line/lp-line";
import {scope} from "@common/react/scope";
import {OrientationSelect} from "../../../common/orientation-select";
import {AxisTitle, XAxisLabel, XGridStepLabel} from "../../../combo-chart/panels/x-axis/x-axis-rules-comp";
import {getPath} from "@common/utils/arr-path";

export const XAxis = ({tile, size}) => ({
    label: "X-Axis",
    render: () => (
        <div className="scatter-plot-x-axis-98f">
            <div className="subsection">
                <div className="content">

                    {AxisTitle({
                        tile,
                        size,
                        state: scope(tile, ["style", "xAxis", "label"])
                    })}
                </div>
            </div>

            <div className="subsection">
                {XAxisLabel({
                    tile,
                    size,
                    state: scope(tile, ["style", "xAxis", "labelShown"])
                })}
            </div>

            <div className="subsection">
                { XGridStepLabel({
                    size,
                    state: scope(tile, ["style", "xAxis", "gridStepLabelsShown"])
                })}

                {getPath(tile.value, ["style", "xAxis", "gridStepLabelsShown"]) && (
                    <div className="content">
                        {OrientationSelect(scope(tile, ["style", "xAxis", "gridStepLabelOrientation"]))}
                    </div>
                )}
            </div>

            <div className="subsection">
                <div className="header">
                    Other Options
                </div>
                <div className="content">
                    {LpLine({
                        label: "Axis Line",
                        control: ShowHideToggle({state: scope(tile, ["style", "xAxis", "lineShown"])}),
                    })}
                </div>
            </div>
        </div>
    )
})
