import "./sync-settings-unstructured-interaction.scss";

import {cx} from "emotion";
import {Button} from "@common/form/buttons/button/button";
import {OnOffToggle} from "@common/form/toggles/on-off-toggle";
import {DisabledToggle} from "@common/form/toggle/toggle";
import {cs} from "@common/react/chain-services";
import {scope} from "@common/react/scope";
import {UseState} from "@common/react/use-state";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {stateToSelect} from "@common/ui-components/form/state-to-select";
import {SearchInputBar} from "@common/ui-components/search-input-bar/search-input-bar";
import {arrEquals, sort} from "@common/utils/collections";
import {chain} from "@common/utils/fs";
import {isBlank, isMatchText} from "@common/utils/strings";
import {rFieldTypeIcon} from "../../../../common/field-type-icon/get-field-type-icon";
import {InteractionTable} from "../../sync/common/tables/interaction-table";
import {SyncSettingStatus} from "../list/sync-settings-status";
import {buildUnstructuredTreeTable, getRealNameChildTable} from "../unstructured-list/unstructured-list-helpers";
import {UnstructuredSettingsTree} from "./unstructured-settings-tree";
import {SyncColumnAlertIcon} from "./sync-column-alert-icon";

export const SyncSettingsUnstructuredInteraction = ({table: remoteTable, interactions, dataSource}) =>
    cs(
        ["localDataSource", (_, next) => UseState({initValue: dataSource.value, next})],
        ["keyword", (_, next) => UseState({initValue: "", next})],

        ["nestedTable", ({localDataSource}, next) => next(buildUnstructuredTreeTable(remoteTable.value, localDataSource.value.tables, localDataSource.value.disabledTables || []))],
        ["selected", ({nestedTable}, next) => UseState({initValue: nestedTable, next})],

        ({keyword, selected, nestedTable, localDataSource}) => {
            const hasError = (dataSource.value.errors || []).map((x) => x.dataSourceTableID).indexOf(remoteTable.value.id) > -1;

            return (
                <div className="sync-settings-unstructured-interaction-88o">
                    <div className="header">
                        <div className="left-side">
                            {cs(
                                [
                                    "syncType",
                                    (_, next) => {
                                        const getSyncType = () => {
                                            // add constant status
                                            if (hasError) return "error";
                                            if (remoteTable.value.paused) return "paused";
                                            return "sync";
                                        };

                                        return next(getSyncType());
                                    },
                                ],
                                ({syncType}) => SyncSettingStatus({type: syncType, onlyIcon: true})
                            )}
                            <div className="label">{remoteTable.value.name}</div>
                        </div>

                        <span className="material-icons-outlined close-icon" onClick={() => interactions.onChange({selectedTable: null})}>
                            close
                        </span>
                    </div>

                    {cs(
                        [
                            "localTable",
                            (_, next) => {
                                const tIndex = localDataSource.value.tables.findIndex((t) => t.name === selected.value.name);
                                return next(scope(localDataSource, ["tables", tIndex]));
                            },
                        ],
                        [
                            "getTotalProperties",
                            ({localTable}, next) =>
                                next(() => {
                                    return dataSource.value.tables
                                        .filter((t) => t.tableReferenceName.indexOf(localTable.value.tableReferenceName) > -1)
                                        .reduce((prev, item) => (prev += item.columns.length + (item.disabledColumns || [])?.length), 0);
                                }),
                        ],
                        ({localTable, getTotalProperties}) => {
                            return (
                                <div className="content">
                                    <div className="header">
                                        <div className="info">
                                            <i
                                                className={cx("fas fa-arrow-up", {"child-table": selected.value.isChildTable})}
                                                onClick={() => {
                                                    if (!selected.value.isChildTable) return;

                                                    const _stack = selected.value.stack;
                                                    const parentStack = _stack.slice(0, _stack.length - 1);

                                                    const getParent = (children) => {
                                                        if (arrEquals([nestedTable.name], parentStack)) return nestedTable;

                                                        const findItem = children.find((c) => arrEquals(c.stack, parentStack.slice(0, c.stack.length)));
                                                        if (findItem.stack.length === parentStack.length) return findItem;

                                                        return getParent(findItem.children);
                                                    };

                                                    selected.onChange(getParent(nestedTable.children));
                                                }}
                                            />

                                            <div className="label">
                                                <div className="text">{selected.value.originalName || localTable.value.name}</div>
                                                <div className="sub-label">
                                                    <div>
                                                        Nested Objects: {selected.value.children.filter((s) => s.children.length > 0).length} ({selected.value.children.length}{" "}
                                                        total)
                                                    </div>
                                                    <div className="separator" />
                                                    <div>
                                                        Properties: {localTable.value.columns.length} of{" "}
                                                        {localTable.value.columns.length + (localTable.value.disabledColumns || [])?.length} ({getTotalProperties()} total)
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {SearchInputBar({state: keyword})}
                                    </div>

                                    <div className="main">
                                        <div className="left">
                                            <div
                                                onClick={() => selected.onChange(nestedTable)}
                                                className={cx("parent-table", {selected: selected.value.name === remoteTable.value.name})}
                                            >
                                                {remoteTable.value.name}
                                            </div>
                                            {UnstructuredSettingsTree({
                                                list: nestedTable ? nestedTable.children : [],
                                                selected,
                                            })}
                                        </div>
                                        <div className="separator" />
                                        <div className="right">
                                            {cs(() => {
                                                const disabledColumn = (row) => localTable.value.disabledColumns.findIndex((c) => c.name === row.name) > -1;
                                                const columns = [...localTable.value.columns, ...localTable.value.disabledColumns];
                                                const originalTable = (dataSource.value.tables || []).find((t) => t.name === localTable.value.name);

                                                const isHasErrorRow = (column) => {
                                                    if (!column || !originalTable) return false;
                                                    const oriColumn = [...originalTable.columns, ...originalTable.disabledColumns].find((c) => c.name == column.name);
                                                    return column?.dataTypeProperties != oriColumn?.dataTypeProperties && !disabledColumn(column);
                                                };

                                                const sortedColumns = chain(
                                                    [...columns, ...selected.value.children],
                                                    (_) => [
                                                        ..._.filter((c) => c.primaryKey),
                                                        ...sort(
                                                            _.filter((c) => !c.primaryKey),
                                                            (c) => c.name
                                                        ),
                                                    ],
                                                    (_) => _.filter((c) => isMatchText(c.name, keyword.value))
                                                );

                                                return InteractionTable({
                                                    list: sortedColumns,
                                                    rowClassName: (row) =>
                                                        row.isChildTable ? "child-table" : disabledColumn(row) ? "disabled" : isHasErrorRow(row) ? "error" : "",
                                                    columns: [
                                                        {
                                                            label: "Object/Property",
                                                            classNameFn: (row) => (row.isChildTable ? "child-table-name" : ""),
                                                            className: "column-name",
                                                            format: (row) => {
                                                                if (row.isChildTable) {
                                                                    return (
                                                                        <div className="text" onClick={() => selected.onChange(row)}>
                                                                            {getRealNameChildTable(row)}
                                                                        </div>
                                                                    );
                                                                }

                                                                return (
                                                                    <>
                                                                        {row.name}
                                                                        {row.primaryKey && <span className="primary">(Primary Key)</span>}
                                                                    </>
                                                                );
                                                            },
                                                            // sortValue: ( row ) => row.name
                                                        },
                                                        {
                                                            label: "Type",
                                                            className: "interaction-row-types",
                                                            format: (row) => {
                                                                if (row.isChildTable) return null;

                                                                const isDisabledColumn = disabledColumn(row);

                                                                const config =
                                                                    {
                                                                        Text: {
                                                                            list: ["NonPII", "PII", "None"],
                                                                            valueToLabel: (v) => (
                                                                                <>
                                                                                    {rFieldTypeIcon(`Text`)} Text ({v === "None" ? "Unqualified" : v === "NonPII" ? "Non-PII" : v})
                                                                                </>
                                                                            ),
                                                                        },
                                                                        DateTime: {
                                                                            list: ["LocalTime", "UTC", "None"],
                                                                            valueToLabel: (v) => (
                                                                                <>
                                                                                    {rFieldTypeIcon(`DateTime`)} DateTime (
                                                                                    {v === "None" ? "Unqualified" : v === "LocalTime" ? "Local Time" : v})
                                                                                </>
                                                                            ),
                                                                        },
                                                                        DateTimeOffset: {
                                                                            list: ["LocalTime", "UTC", "None"],
                                                                            valueToLabel: (v) => (
                                                                                <>
                                                                                    {rFieldTypeIcon(`DateTimeOffset`)} DateTimeOffset (
                                                                                    {v === "None" ? "Unqualified" : v === "LocalTime" ? "Local Time" : v})
                                                                                </>
                                                                            ),
                                                                        },
                                                                    }[row.dataType] || undefined;

                                                                if (config && !isDisabledColumn) {
                                                                    let columnIndex = localTable.value.columns.findIndex((c) => c.name === row.name);
                                                                    let disabledColumnIndex = localTable.value.disabledColumns?.findIndex((c) => c.name === row.name);

                                                                    return DropdownSelect({
                                                                        ...config,
                                                                        disabled: isDisabledColumn,
                                                                        label: null,
                                                                        errorMessage: `This field is required`,
                                                                        hasError: isBlank(row.dataTypeProperties),
                                                                        ...stateToSelect(
                                                                            scope(localTable, [
                                                                                isDisabledColumn ? "disabledColumns" : "columns",
                                                                                isDisabledColumn ? disabledColumnIndex : columnIndex,
                                                                                "dataTypeProperties",
                                                                            ])
                                                                        ),
                                                                    });
                                                                } else {
                                                                    return (
                                                                        <span className={cx("value", {"id-uuid": row.dataType === "IDUUID"})}>
                                                                            {config ? (
                                                                                config.valueToLabel(row.dataTypeProperties)
                                                                            ) : (
                                                                                <>
                                                                                    {rFieldTypeIcon(row.dataType)} {row.dataType}
                                                                                </>
                                                                            )}
                                                                        </span>
                                                                    );
                                                                }
                                                            },
                                                        },
                                                        {
                                                            labelF: () => (
                                                                <>
                                                                    Sync &nbsp;&nbsp;
                                                                    <Button
                                                                        btnType="border"
                                                                        size="tiny"
                                                                        onClick={() =>
                                                                            localTable.change((oldTable) => ({
                                                                                ...oldTable,
                                                                                columns: [...oldTable.columns, ...oldTable.disabledColumns],
                                                                                disabledColumns: [],
                                                                            }))
                                                                        }
                                                                    >
                                                                        ALL
                                                                    </Button>{" "}
                                                                    &nbsp;&nbsp;
                                                                    <Button
                                                                        btnType="border"
                                                                        size="tiny"
                                                                        onClick={() =>
                                                                            localTable.change((oldTable) => ({
                                                                                ...oldTable,
                                                                                disabledColumns: [...oldTable.columns, ...oldTable.disabledColumns],
                                                                                columns: [],
                                                                            }))
                                                                        }
                                                                    >
                                                                        NONE
                                                                    </Button>
                                                                </>
                                                            ),
                                                            headClassName: "column-sync-toggle-head",
                                                            format: (row) => {
                                                                if (row.isChildTable) {
                                                                    if (row.children.length > 0) return DisabledToggle({});

                                                                    const tIndex = localDataSource.value.tables.findIndex((t) => t.name === row.name);
                                                                    const _table = [...localDataSource.value.tables, ...(localDataSource.value.disabledTables || [])].find(
                                                                        (t) => t.name === row.name
                                                                    );

                                                                    return OnOffToggle({
                                                                        colors: {right: "#294661"},
                                                                        state: {
                                                                            value: tIndex > -1,
                                                                            onChange: () =>
                                                                                localDataSource.change((old) => ({
                                                                                    ...old,
                                                                                    tables: tIndex > -1 ? old.tables.filter((t) => t.name !== row.name) : [...old.tables, _table],
                                                                                    disabledTables:
                                                                                        tIndex > -1
                                                                                            ? [...(old.disabledTables || []), _table]
                                                                                            : (old.disabledTables || []).filter((t) => t.name !== row.name),
                                                                                })),
                                                                        },
                                                                    });
                                                                }

                                                                return (
                                                                    <div className="toggle-sync">
                                                                        {OnOffToggle({
                                                                            colors: {right: "#294661"},
                                                                            tooltipInfo: row.primaryKey ? `Primary Key column must be set to sync.` : null,
                                                                            disabled: row.primaryKey,
                                                                            state: {
                                                                                value: !disabledColumn(row),
                                                                                onChange: (value) =>
                                                                                    localTable.change((oldTable) => ({
                                                                                        ...oldTable,
                                                                                        columns: value
                                                                                            ? [...oldTable.columns, row]
                                                                                            : oldTable.columns.filter((c) => c.name !== row.name),
                                                                                        disabledColumns: !value
                                                                                            ? [...oldTable.disabledColumns, row]
                                                                                            : oldTable.disabledColumns.filter((c) => c.name !== row.name),
                                                                                    })),
                                                                            },
                                                                        })}

                                                                        {dataSource.value.id &&
                                                                            originalTable &&
                                                                            SyncColumnAlertIcon({
                                                                                column: row,
                                                                                oriColumn: [...originalTable.columns, ...originalTable.disabledColumns].find(
                                                                                    (c) => c.name == row.name
                                                                                ),
                                                                                table: localTable.value,
                                                                                oriTable: originalTable,
                                                                            })}
                                                                    </div>
                                                                );
                                                            },
                                                        },
                                                    ],
                                                });
                                            })}
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                    )}

                    <div className="footer">
                        <Button
                            onClick={() => {
                                // remoteTable.onChange(table.value);
                                dataSource.onChange(localDataSource.value);
                                interactions.onChange({selectedTable: null});
                            }}
                        >
                            Done
                        </Button>
                    </div>
                </div>
            );
        }
    );
