import React from "react";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {UseState} from "@common/react/use-state";
import {AccountCanceledDialog, AccountSuspendedDialog} from "./account-suspended-dialog";
import {Watch} from "@common/react/watch";
import {Invoke} from "@common/react/invoke";

export const AccountSuspendedService = ({next, subscriptionBrief}) =>
    cs(
        consumeContext("routing"),
        ["showDialog", (_, next) => UseState({initValue: false, next})],
        ["accountSuspendedDialog", (_, next) => AccountSuspendedDialog({next})],
        ["accountCanceledDialog", (_, next) => AccountCanceledDialog({next})],
        ({routing, accountSuspendedDialog, accountCanceledDialog, showDialog}, next) => {
            const handleShowDialog = async ({routing, showDialog}) => {
                if (!showDialog.value && routing.params.setting != "organization-details" && routing.params.setting != "billing") {
                    const _dialog = {
                        Suspended: accountSuspendedDialog,
                        Canceled: accountCanceledDialog,
                    }[subscriptionBrief.value.status];

                    if (!_dialog) return;

                    showDialog.onChange(true);
                    await _dialog?.show({
                        subscriptionBrief: subscriptionBrief.value,
                    });
                    showDialog.onChange(false);
                }
            };

            return (
                <>
                    {subscriptionBrief.value && subscriptionBrief.value.status != "Active" && (
                        <>
                            {Invoke({
                                fn: async () => handleShowDialog({routing, showDialog}),
                            })}

                            {Watch({
                                value: {routing, showDialog},
                                onChanged: handleShowDialog,
                            })}
                        </>
                    )}
                    {next()}
                </>
            );
        },
        () => next()
    );
