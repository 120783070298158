import React from "react";
import {cs} from "../../../../react/chain-services";
import {cx} from "emotion";
import "./map-zoom-controls.scss";

export const MapZoomControls = ({chartRef, legendRoom}) => {
    const chart = chartRef.get().chart;
    return (
        <div
            className="map-zoom-controls-j72 map-zoom-controls"
            style={{
                right: legendRoom?.paddingRight || 0,
                bottom: legendRoom?.paddingBottom || 5,
            }}
        >
            <div className="zoom-btn zoom-in" onClick={() => chart.mapZoom(0.5)}>
                <span className="material-icons">add</span>
            </div>
            <div className="zoom-btn zoom-out" onClick={() => chart.mapZoom(2)}>
                <span className="material-icons">remove</span>
            </div>
        </div>
    );
};
