import "./standard.scss";

import * as React from "react";
import {css, cx} from "emotion";

import {Watch} from "@common/react/watch";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {ObserveDomSize} from "@common/react/observe-dom-size";

import {IconDown, IconUnchanged, IconUp} from "../common/icons/icons";
import {CURRENCY_CONVERSION} from "../common/currency-conversion-warning/currency-conversion-warning";

import {FontAutoResizing, initFontSizeList} from "./font-auto-resizing";

export const StandardSingleKPI = ({
    titleStyle,
    title,
    hideComparison,
    theme,
    row,
    style,
    currencyConversion,
    formatter: {valueFormatter, percentFormatter},
    actionMenu,
    onClickPoint,
}) =>
    cs(
        ["contentSize", (_, next) => ObserveDomSize({next})],
        ["containerSize", (_, next) => ObserveDomSize({next})],
        [
            "main",
            ({contentSize, containerSize}, next) => {
                const colors = theme.dataVisualization.dataColorPalettes.kpiColorsRGB;
                const color = colors[style.valueColorApplication?.index || 0];

                if (row) {
                    const hasActions = !!onClickPoint;
                    const showCompare = !hideComparison && style.showCompare && row.change != null;

                    const isGood =
                        (style.positiveChangeGood && (row.change || row.value) > 0) ||
                        (!style.positiveChangeGood && (row.change || row.value) < 0);
                    const isUp = (row.change || row.value) > 0;

                    const isUnchanged = () => {
                        return (
                            style.compareValueShown &&
                            (style.compareValueMethod === "Percent"
                                ? percentFormatter(Math.abs(row.change)) === "0%"
                                : valueFormatter(Math.abs(row.difference)) === 0)
                        );
                    };

                    const value = valueFormatter(row.value);
                    const maxFontSize = 40;
                    const minFontSize = 13;

                    return next(
                        <div className="main">
                            <div className="value-change">
                                <div className="value" ref={(elem) => contentSize.ref(elem)}>
                                    {cs(
                                        [
                                            "fontSizeGroupDimension",
                                            (_, next) =>
                                                UseState({
                                                    next,
                                                    initValue: initFontSizeList(maxFontSize, minFontSize, value),
                                                }),
                                        ],
                                        ({fontSizeGroupDimension}, next) =>
                                            Watch({
                                                next,
                                                value: value,
                                                onChanged: (v) => {
                                                    fontSizeGroupDimension.onChange(initFontSizeList(maxFontSize, minFontSize, v));
                                                },
                                            }),
                                        ({fontSizeGroupDimension}) => {
                                            const containerWidth = contentSize.value?.width;
                                            const valueSize = fontSizeGroupDimension.value?.find(
                                                (v) => v.width <= contentSize.value?.width - 20
                                            );

                                            const relativeCompValue =
                                                style.compareValueMethod === "Percent"
                                                    ? percentFormatter(Math.abs(row.change || row.value / 100))
                                                    : valueFormatter(Math.abs(row.difference));
                                            const absoluteCompValue = valueFormatter(row.previousValue || 0);
                                            const comparisonStr = `${absoluteCompValue}${relativeCompValue}`;
                                            const estimatedComparisonStrWidth = estimateStringWidth(comparisonStr);

                                            const valueWrapperStyle =
                                                style.compareValuePosition === "Vertical"
                                                    ? {}
                                                    : containerWidth - valueSize?.width >= estimatedComparisonStrWidth
                                                    ? {
                                                          flexDirection: "row",
                                                          alignItems: "center",
                                                          justifyContent: style.valuePosition,
                                                          ...(style.valuePosition === "Right"
                                                              ? {
                                                                    flexDirection: "row-reverse",
                                                                }
                                                              : {}),
                                                      }
                                                    : {};

                                            const trendColor = isGood
                                                ? theme.dataVisualization.dataColorPalettes.otherColors.conditionalGoodColorRGB || "#18C96E"
                                                : theme.dataVisualization.dataColorPalettes.otherColors.conditionalBadColorRGB || "#E95A5A";
                                            return (
                                                <div className="value-wrapper" style={valueWrapperStyle}>
                                                    <div
                                                        className={cx("value-number verb-kpi-value", {
                                                            "has-arrow":
                                                                showCompare &&
                                                                !style.previousPeriodShown &&
                                                                !style.compareValueMethod &&
                                                                style.indicatorShown,
                                                            "has-actions": hasActions,
                                                        })}
                                                        style={{
                                                            color,
                                                            textAlign: style.valuePosition,
                                                            paddingLeft: style.valuePosition === "Left" ? 20 : 0,
                                                            paddingRight: style.valuePosition === "Right" ? 20 : 0,
                                                        }}
                                                        onClick={(e) =>
                                                            onClickPoint?.({
                                                                x: e.clientX,
                                                                y: e.clientY,
                                                                noFilter: true,
                                                                row: {
                                                                    ...row,
                                                                    value,
                                                                },
                                                                fieldName: row.name,
                                                            })
                                                        }
                                                    >
                                                        {FontAutoResizing({
                                                            fontSizeGroupDimension,
                                                            noTitle: !titleStyle.show,
                                                            width: contentSize.value?.width,
                                                            height: contentSize.value?.height,
                                                            text: value,
                                                            maxFontSize,
                                                            minFontSize,
                                                            attached: ({fontSize}) =>
                                                                cs(
                                                                    (_, next) => (currencyConversion?.hasFailures ? next() : null),
                                                                    [
                                                                        "condition",
                                                                        (_, next) => {
                                                                            if (
                                                                                currencyConversion.isoCurrencyStrategy ===
                                                                                CURRENCY_CONVERSION.Unconverted
                                                                            )
                                                                                return next(row.currencyConversionFailures);
                                                                            if (
                                                                                currencyConversion.isoCurrencyStrategy ===
                                                                                CURRENCY_CONVERSION.Exclude
                                                                            )
                                                                                return next(row.value === 0);
                                                                            return next(null);
                                                                        },
                                                                    ],
                                                                    ({condition}) => {
                                                                        return condition ? (
                                                                            <span
                                                                                className={cx("material-icons-round currency-failures")}
                                                                                style={{
                                                                                    color: "#ff5959",
                                                                                    "font-size": `${fontSize - fontSize / 2}px`,
                                                                                }}
                                                                            >
                                                                                priority_high
                                                                            </span>
                                                                        ) : null;
                                                                    }
                                                                ),
                                                        })}

                                                        {/*{showCompare && !style.previousPeriodShown && !style.compareValueMethod && style.indicatorShown && (*/}
                                                        {/*    <div className="arrow">*/}
                                                        {/*        {isUnchanged() ? IconUnchanged({}) : isUp ? <IconUp*/}
                                                        {/*                fill={isGood ? theme.dataVisualization.dataColorPalettes.otherColors.conditionalGoodColorRGB || "#18C96E" : theme.dataVisualization.dataColorPalettes.otherColors.conditionalBadColorRGB || "#E95A5A"}/> :*/}
                                                        {/*            <IconDown*/}
                                                        {/*                fill={isGood ? theme.dataVisualization.dataColorPalettes.otherColors.conditionalGoodColorRGB || "#18C96E" : theme.dataVisualization.dataColorPalettes.otherColors.conditionalBadColorRGB || "#E95A5A"}/>}*/}
                                                        {/*    </div>*/}
                                                        {/*)}*/}
                                                    </div>

                                                    {showCompare &&
                                                        (style.compareValuePosition === "Vertical"
                                                            ? containerSize.value?.height > 60
                                                            : containerWidth - valueSize?.width >= estimatedComparisonStrWidth) && (
                                                            <div
                                                                className="change-wrapper"
                                                                style={{
                                                                    justifyContent: style.valuePosition,
                                                                    paddingLeft:
                                                                        style.valuePosition === "Left" || style.valuePosition === "Center"
                                                                            ? 20
                                                                            : 0,
                                                                    paddingRight: style.valuePosition === "Right" ? 20 : 0,
                                                                }}
                                                            >
                                                                {style.previousPeriodShown && (
                                                                    <div
                                                                        className="prev verb-kpi-compare-prev"
                                                                        style={{
                                                                            color: color,
                                                                        }}
                                                                    >
                                                                        {absoluteCompValue}
                                                                    </div>
                                                                )}

                                                                <div
                                                                    className="change verb-kpi-compare-change"
                                                                    style={{
                                                                        marginLeft: style.previousPeriodShown ? 12 : 0,
                                                                        color: `${isUnchanged() ? color : trendColor}`,
                                                                    }}
                                                                >
                                                                    {style.indicatorShown &&
                                                                        (style.previousPeriodShown || style.compareValueMethod) && (
                                                                            <>
                                                                                {isUnchanged() ? (
                                                                                    IconUnchanged({
                                                                                        className: "unchanged",
                                                                                    })
                                                                                ) : isUp ? (
                                                                                    <IconUp fill={trendColor} />
                                                                                ) : (
                                                                                    <IconDown fill={trendColor} />
                                                                                )}
                                                                            </>
                                                                        )}

                                                                    {style.compareValueMethod ? relativeCompValue : null}
                                                                </div>
                                                            </div>
                                                        )}
                                                </div>
                                            );
                                        }
                                    )}
                                </div>
                            </div>
                        </div>
                    );
                }

                return null;
            },
        ],
        ({main, containerSize}) => {
            return (
                <div
                    className="condensed-single-kpi-1sc"
                    style={{
                        fontFamily: `"${theme.dataVisualization.fonts.fontFamily || theme.general.canvas.fontFamily}", sans-serif`,
                    }}
                >
                    <div ref={(elem) => containerSize.ref(elem)} className="main-area">
                        {main}
                    </div>
                </div>
            );
        }
    );

// working with fontSize == 11px
function estimateStringWidth(str) {
    return str.length * 6.8 + 50;
}
