const {listAllFields} = require("@common/ui-components/charts/get-field-color");
const {getFieldType} = require("@common/ui-components/charts/common/get-field-type");

const getTileFields = ({tile, dimensionAttr, measureGroupAttrs, measureSingleAttrs, measureMultiAttr, groupFieldAttr, groupFieldAttrs, getFieldInfo}) => {
    if (!tile) {
        return;
    }

    const isDimDateTime = dimensionAttr && (getFieldInfo?.(tile[dimensionAttr])?.type === "date" || getFieldType(tile[dimensionAttr]) === "date");

    const allTileFields = listAllFields({
        tile,
        dimensionAttr,
        measureGroupAttrs,
        measureSingleAttrs,
        measureMultiAttr,
        groupFieldAttr,
        groupFieldAttrs,
    });

    const allFieldsWithoutUniq = listAllFields({
        tile,
        dimensionAttr,
        measureGroupAttrs,
        measureSingleAttrs,
        measureMultiAttr,
        groupFieldAttr,
        groupFieldAttrs,
        checkUnique: false,
    });

    // tile without dimension go to this case, too
    if (!isDimDateTime) {
        return {
            allFields: allTileFields,
            fieldsForSort: allTileFields,
            fieldsForLimit: allTileFields,
            allFieldsWithoutUniq,
        };
    }

    const dimNoLimit = dimHasNoLimit(tile[dimensionAttr]);

    return {
        allFields: allTileFields,
        // if datetime dim has no limit, remove other fields apart from datetime in sort dropdown
        fieldsForSort: dimNoLimit ? [tile[dimensionAttr]] : allTileFields,
        // dimNoLimit prop only exists if dim is of datetime type
        dimNoLimit,
        fieldsForLimit: allTileFields,
        allFieldsWithoutUniq,
    };
};
exports.getTileFields = getTileFields;

const dimHasNoLimit = (dimField) => {
    return !dimField.limit || dimField.limit.limit == null || dimField.limit.limit === 0;
};
