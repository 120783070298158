import {FieldStyle} from "./field-style";

export const FieldsStyle = (tileFields, fields) =>
    fields
        ? fields.map((k, index) =>
              FieldStyle({
                  field: k,
                  index,
                  style: {
                      value: tileFields.value?.find((s) => s.tileFieldID == k.id) || {},
                      onChange: (value) => {
                          let found = tileFields.value?.find((s) => s.tileFieldID == k.id);
                          if (found) {
                              tileFields.onChange(
                                  tileFields.value.map((item) => {
                                      if (item.tileFieldID == k.id) return value;
                                      return item;
                                  })
                              );
                          } else {
                              tileFields.onChange(
                                  tileFields.value.concat({
                                      ...value,
                                      tileFieldID: k.id,
                                  })
                              );
                          }
                      },
                  },
              })
          )
        : [];
