const createAutoSaveModel = ({model, savingQueue, errorDialog, readOnly}) => ({
    value: model.value,
    change: async (reduce, saveToLocal) => {
        await model.change(reduce);
        if (!saveToLocal && !readOnly) {
            try {
                const newModel = await savingQueue.push(reduce);
                model.change(() => newModel);
            } catch (e) {
                errorDialog?.show({title: "Error", content: e.message});
                model.change(() => model.value);
            }
        }
    },
    reload: model.reload,
});
exports.createAutoSaveModel = createAutoSaveModel;
