import {cs} from "@common/react/chain-services";
import {Dropdown} from "@common/ui-components/dropdown/dropdown";
import {cx} from "emotion";
import {rFieldTypeIcon} from "../../../../../../../../../../../common/field-type-icon/get-field-type-icon";
import React from "react";
import {SearchInput} from "@common/form/search-input/search-input";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";
import {isMatchText} from "@common/utils/strings";
import "./color-schema-dropdown.scss";
import {consumeContext} from "@common/react/context";

export const ColorSchemaDropdown = ({label, renderColor, value, onChange, list, className, singleColor, hideDefaultOption, onSelectDefaultOption}) =>
    cs(
        consumeContext("theme"),
        ({}, next) => <div className={cx("column-select-o13 column-select color-schema-dropdown-a33", className)}>{next()}</div>,
        ({theme}) =>
            Dropdown({
                minExpandHeight: 350,
                minWidth: 160,
                expandClassNames: "expanded-dropdown",
                renderToggle: ({showExpand, showingExpand}) => (
                    <div
                        className={cx("toggle", {
                            expanding: showingExpand,
                            "with-value": true,
                        })}
                    >
                        <div className={cx("display-value", singleColor && "single-color")} onClick={() => showExpand(true)}>
                            <div className="color-item">{value != null ? renderColor(value) : "Default"}</div>
                        </div>

                        {label && <div className="label">{label}</div>}
                        <i className="fa fa-chevron-down" />
                    </div>
                ),
                renderExpand: ({close}) => (
                    <div className={cx("list")} style={{width: "100%"}}>
                        <VerbScrollbar maxHeight="250px" className="list-wrapper">
                            {!hideDefaultOption && (
                                <div className="item">
                                    <div
                                        className={cx("item-wrapper", {
                                            selected: value == null,
                                        })}
                                        onClick={() => {
                                            onSelectDefaultOption();
                                            close();
                                        }}
                                    >
                                        <div className="single-color-wrapper">
                                            <div className="color-box" style={{background: theme?.dataVisualization.tables?.cellBackgroundColorRGB}} />
                                            Default
                                        </div>
                                    </div>
                                </div>
                            )}

                            {list.map((item, index) => {
                                return (
                                    <div key={index} className={cx("item")}>
                                        <div
                                            className={cx("item-wrapper", {
                                                selected: value == index,
                                            })}
                                            onClick={() => {
                                                onChange(index);
                                                close();
                                            }}
                                        >
                                            {!singleColor && <div className="schema-label">Schema {index + 1}</div>}

                                            {renderColor(index)}
                                        </div>
                                    </div>
                                );
                            })}
                        </VerbScrollbar>
                    </div>
                ),
            })
    );
