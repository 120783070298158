const {createEnvMockApis} = require("./env-mock-apis");
const {createBasicMockApis} = require("./basic-mock-apis");

const createApisMock = ({authToken}) => {
    const basicApis = createBasicMockApis({authToken});
    return {
        ...basicApis,

        withEnv: ({envId, next}) => next(createEnvMockApis({authToken, envId})),
    };
};
exports.createApisMock = createApisMock;
