import {chain} from "../../../utils/fs";
import {sort, unique} from "../../../utils/collections";
import {mapColorApplicationToThemeColor} from "../get-field-color";
import {generateColorScale} from "../../../utils/color-util";

export const decorateScatterData = ({rawData, tile, theme}) => {
    const seriesDecorator = seriesDecorators.find((d) => d.condition(tile)).decorator;
    return {
        ...rawData,
        series: seriesDecorator({rawData, tile, theme}),
    };
};

const seriesDecorators = [
    {
        condition: (tile) => !tile.colorGroupField,
        decorator: ({rawData, tile, theme}) => {
            const getCommonProps = cGetCommonScatterSeriesProps({tile});

            const getColor = cGetScatterColor({
                tile,
                theme,
                colorApplication: tile.style.valueColorApplication, // discrete
            });

            const color = getColor();

            const shapeGroupNames = getScatterGroupNames(rawData, "shapeGroupName");

            return rawData.series.map((s) => {
                return {
                    ...s,
                    data: s.data.map((dp) => ({x: dp[0], y: dp[1]})),
                    color,
                    marker: {
                        color,
                        symbol: getScatterMarker(shapeGroupNames.indexOf(s.shapeGroupName)),
                    },
                    custom: {
                        shapeGroup: s.shapeGroupName,
                    },
                    ...getCommonProps(s),
                };
            });
        },
    },
    {
        condition: (tile) => tile.colorGroupField,
        decorator: ({rawData, tile, theme}) => {
            const colorGroupNames = getScatterGroupNames(rawData, "colorGroupName");

            const getColor = cGetScatterColor({
                tile,
                theme,
                colorApplication: tile.style.colorApplication, // scheme
                numberOfColors: colorGroupNames.length || 1,
            });

            const shapeGroupNames = getScatterGroupNames(rawData, "shapeGroupName");

            const getCommonProps = cGetCommonScatterSeriesProps({tile});

            return rawData.series.map((s) => {
                const color = getColor(colorGroupNames.indexOf(s.colorGroupName));
                return {
                    ...s,
                    data: s.data.map((dp) => ({x: dp[0], y: dp[1]})),
                    color,
                    marker: {
                        color,
                        symbol: getScatterMarker(shapeGroupNames.indexOf(s.shapeGroupName)),
                    },
                    custom: {
                        colorGroup: s.colorGroupName,
                        shapeGroup: s.shapeGroupName,
                    },
                    ...getCommonProps(s),
                };
            });
        },
    },
];

export const cGetCommonScatterSeriesProps = ({tile}) => {
    return (s) => ({
        ...(s.isCompare ? {opacity: tile.style.transparent ? 0.25 : 0.5} : {opacity: tile.style.transparent ? 0.5 : 1}),
    });
};

export const cGetScatterColor = ({tile, theme, colorApplication, numberOfColors}) => {
    const displayColor = mapColorApplicationToThemeColor(theme.dataVisualization.dataColorPalettes, colorApplication);

    if (colorApplication.$type === "DiscreteColorApplication") {
        return () => displayColor;
    }

    return (index) => {
        const colorScale = generateColorScale(displayColor, colorApplication.type === "Sequential", 0, numberOfColors); // isSequential = false
        return colorScale.getColor(index);
    };
};

const getScatterMarker = (index) => {
    const markers = ["circle", "square", "triangle", "triangle-down", "diamond"];

    if (index < 0) {
        return markers[0];
    }

    const mIndex = index < markers.length ? index : index % markers.length;
    return markers[mIndex];
};

export const getScatterGroupNames = (rawData, groupNameAttr) =>
    chain(
        rawData.series.map((s) => s[groupNameAttr]),
        (_) => _.filter((v) => v),
        (_) => unique(_),
        (_) => sort(_) // sort in alphabetical order
    );
