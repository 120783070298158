import React from "react";

const HideIcon = ({className, fill = "#919EB0"}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24.48" height="24" viewBox="0 0 24.48 24">
        <path
            id="Hide_Icon"
            data-name="Hide Icon"
            d="M108.075,61.648a12.45,12.45,0,0,1-18.894-8.255A12.425,12.425,0,0,1,92.96,46.53l-3.54-3.537,1.6-1.6,22.4,22.4-1.6,1.6-3.745-3.745ZM94.559,48.132a10.142,10.142,0,0,0-3.068,5.261A10.188,10.188,0,0,0,106.426,60L104.132,57.7a5.091,5.091,0,0,1-7.021-7.021Zm7.9,7.894-3.668-3.668a2.828,2.828,0,0,0,3.667,3.667Zm8.93,2.561-1.619-1.618a10.106,10.106,0,0,0,1.585-3.577,10.188,10.188,0,0,0-12.7-7.537l-1.785-1.785a12.456,12.456,0,0,1,16.795,9.322A12.377,12.377,0,0,1,111.384,58.588ZM101.107,48.311a5.092,5.092,0,0,1,5.4,5.4l-5.4-5.4Z"
            transform="translate(-89.181 -41.393)"
        />
    </svg>
);

export default HideIcon;
