import * as React from "react";
import {cx} from "emotion";
import {cs} from "@common/react/chain-services";
import {SuspendUpdate} from "@common/react/suspend-update";
import {keyed} from "@common/react/keyed";
import {UseState} from "@common/react/use-state";
import {Watch} from "@common/react/watch";

export const LeftPanels = ({editSubTilePanel, overrideLeftPanels}) => {
    return (
        <>
            {cs(
                ["show", (_, next) => UseState({next, initValue: true})],
                ({show}, next) => {
                    return (
                        <>
                            {Watch({
                                value: editSubTilePanel.editTileLogic.tile.value?.id,
                                onChanged: (newTileId, oldTileId) => {
                                    if (newTileId != oldTileId) {
                                        show.onChange(false);
                                        setTimeout(() => {
                                            show.onChange(true);
                                        }, 300);
                                    }
                                },
                            })}
                            {next()}
                        </>
                    );
                },
                ({show}) => {
                    return <div className={cx("main", {active: show.value})}>{show.value && editSubTilePanel.leftPanel}</div>;
                }
            )}

            {(() => {
                const renderLeftPanel = (panel) =>
                    cs(
                        ({}, next) => (
                            <div
                                className={cx("override", {
                                    active: panel.leftPanel,
                                })}
                            >
                                {next()}
                            </div>
                        ),
                        ({}, next) => SuspendUpdate({active: !panel.leftPanel, next}),
                        () => panel.leftPanel
                    );

                if (overrideLeftPanels?.length > 0) {
                    return overrideLeftPanels.map((panel, i) => cs(keyed(i), () => renderLeftPanel(panel)));
                }

                return null;
            })()}
        </>
    );
};
