import React from "react";
import {ShowHideToggle} from "../../../../../../../../../../../../../common/form/toggles/show-hide-toggle";
import {scope} from "@common/react/scope";
import {TextInput} from "../../../../../../../../../../../../../common/form/text-input/text-input";
import {TextareaInput} from "../../../common/textarea-input/textarea-input";
import "./field-style.scss";
import {CheckboxLine} from "../../../../../../../../../../common/checkbox-line/checkbox-line";
import {getPath} from "@common/utils/arr-path";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {cs} from "@common/react/chain-services";
import {IconPicker} from "../../../common/icon-picker/icon-picker";
import {FieldInfoProvider} from "../../../common/field-info-provider/field-info-provider";
import {upperCase1} from "@common/utils/strings";
import {getLegendContentValue} from "../../../common/legend-display-select";
import {PositiveChangeValueSelect} from "../../../common/positive-change-value-select";

export const FieldStyle =
    ({field, index, style}) =>
    ({tile, form}) => ({
        label: `Value ${index + 1} - ${field.displayName}`,
        render: () =>
            cs(() => {
                const valueIconUrl = {
                    value: JSON.parse(style.value.valueIconUrl || "{}"),
                    onChange: (value) => {
                        style.onChange({
                            ...style.value,
                            valueIconUrl: JSON.stringify(value),
                        });
                    },
                };

                return (
                    <div className="table-kpi-summary-number-pi3">
                        <div className="subsection">
                            <div className="content">
                                {TextInput({
                                    label: "Label",
                                    state: scope(style, ["displayText"]),
                                })}

                                {CheckboxLine({
                                    label: "Auto-Round",
                                    state: scope(style, ["autoRound"]),
                                })}
                            </div>
                        </div>

                        <div className="subsection">
                            <div className="header">
                                Value Icon
                                <div className="control">
                                    {ShowHideToggle({
                                        state: scope(style, ["showValueIcon"]),
                                    })}
                                </div>
                            </div>
                            {style.value.showValueIcon && (
                                <div className="content">
                                    {DropdownSelect({
                                        label: "Icon Options",
                                        list: [
                                            {
                                                label: "Use FontAwesome",
                                                isSelected: () => valueIconUrl.value.icon !== undefined,
                                                onChange: () =>
                                                    valueIconUrl.onChange({
                                                        icon: "",
                                                    }),
                                            },
                                            {
                                                label: "Customize via URL",
                                                isSelected: () => valueIconUrl.value.url !== undefined,
                                                onChange: () =>
                                                    valueIconUrl.onChange({
                                                        url: "",
                                                    }),
                                            },
                                        ],
                                        isSelected: (v) => v.isSelected(),
                                        onChange: (v) => v.onChange(),
                                        valueToLabel: (v) => v.label,
                                    })}

                                    {valueIconUrl.value.icon !== undefined &&
                                        IconPicker({
                                            label: "Select icon",
                                            value: valueIconUrl.value.icon,
                                            onChange: (icon) => valueIconUrl.onChange({icon}),
                                        })}

                                    {valueIconUrl.value.url !== undefined &&
                                        FieldInfoProvider({
                                            className: "margin-bottom",
                                            info: "Custom Icons must be hosted on a secure site (https) and can only be in the format of; JPEG, PNG, GIF, SVG, WEBP files.",
                                            tooltipBoxWidth: 170,
                                            topOffset: 35,
                                            field: TextInput({
                                                label: "Icon URL",
                                                state: scope(valueIconUrl, ["url"]),
                                            }),
                                        })}
                                </div>
                            )}
                        </div>

                        <div className="subsection">
                            <div className="header">
                                Information Icon
                                <div className="control">
                                    {ShowHideToggle({
                                        state: scope(style, ["showInfoIcon"]),
                                    })}
                                </div>
                            </div>
                            {style.value.showInfoIcon && (
                                <div className="content">
                                    {TextareaInput({
                                        state: scope(style, ["infoIconText"]),
                                    })}
                                </div>
                            )}
                        </div>

                        <div className="subsection">
                            <div className="header">
                                Conditional Value Format
                                <div className="info">
                                    <i className="material-icons">info</i>
                                    <div className="info-box">
                                        Turning the Conditional Value Format on will apply green or red color to the value font depending on the sign ov the value.
                                    </div>
                                </div>
                                <div className="control">
                                    {ShowHideToggle({
                                        state: scope(style, ["conditionalValueFontColor"]),
                                    })}
                                </div>
                            </div>
                            {style.value.conditionalValueFontColor && <div className="content">{PositiveChangeValueSelect(scope(style, ["positiveGood"]))}</div>}
                        </div>
                    </div>
                );
            }),
    });
