const {tableKPIFormConfig} = require("./table-kpi-form-config");
const {singleKPIFormConfig} = require("./single-kpi-form-config");
const {gaugeFormConfig} = require("./gauge-form-config");
const {comboChartFormConfig} = require("./combo-chart-form-config");
const {tableFormConfig} = require("./table-form-config");
const {downloadReportFormConfig} = require("./download-report-form-config");
const {donutChartFormConfig} = require("./donut-chart-form-config");
const {pieChartFormConfig} = require("./pie-chart-form-config");
const {scatterPlotFormConfig} = require("./scatter-plot-form-config");
const {lineChartFormConfig} = require("./line-chart-form-config");
const {horizontalBarFormConfig} = require("./horizontal-bar-form-config");
const {verticalBarFormConfig} = require("./vertical-bar-form-config");
const {boxPlotFormConfig} = require("./box-plot-form-config");

const getTileFormConfig = (type) => types[type] || {};
exports.getTileFormConfig = getTileFormConfig;

const types = {
    VerticalBarChartTile: verticalBarFormConfig,
    HorizontalBarChartTile: horizontalBarFormConfig,
    LineChartTile: lineChartFormConfig,
    ScatterPlotTile: scatterPlotFormConfig,
    PieChartTile: pieChartFormConfig,
    DonutChartTile: donutChartFormConfig,
    DownloadReportTile: downloadReportFormConfig,
    TableTile: tableFormConfig,
    ComboChartTile: comboChartFormConfig,
    GaugeTile: gaugeFormConfig,
    SingleKPITile: singleKPIFormConfig,
    TableKPITile: tableKPIFormConfig,
    BoxPlotTile: boxPlotFormConfig,
};
