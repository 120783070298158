import {cs} from "@common/react/chain-services";
import {PopupMenu} from "../../../../common/popup-menu/popup-menu";
import {consumeContext} from "@common/react/context";
import {RenameSharedTileDialog} from "./rename/rename-shared-tile-dialog";
import {SelectModelForCloningSharedTileDialog} from "./clone/select-model-for-cloning-shared-tile-dialog";

export const SharedTilesCm = ({folders, moveSharedTileDialog, deleteSharedTileDialog, interactions, next}) =>
    cs(
        consumeContext("routing"),

        [
            "renameSharedTileDialog",
            ({}, next) =>
                RenameSharedTileDialog({
                    onDone: folders.onChange,
                    next,
                }),
        ],
        [
            "selectModelForCloningDialog",
            ({routing}, next) =>
                SelectModelForCloningSharedTileDialog({
                    folders,
                    onDone: (newCollection) => {
                        // collectionToast.show({
                        //     text: "Collection successfully cloned",
                        //     action: {
                        //         text: "Go to New Collection",
                        //         onClick: () =>
                        //             routing.goto("edit-collection", {
                        //                 colId: newCollection.id,
                        //             }),
                        //     },
                        // });
                        folders.reload();
                    },
                    next,
                }),
        ],
        ({routing, renameSharedTileDialog, selectModelForCloningDialog}) =>
            PopupMenu({
                next,
                getCommands: (tile) => [
                    {
                        label: "Edit",
                        onClick: () => routing.goto("edit-shared-tile", {sharedTileId: tile.id}),
                    },
                    {
                        label: "View Collections",
                        onClick: () => interactions.onChange({name: "view-collections", tile}),
                    },
                    {
                        label: "Rename",
                        onClick: () => renameSharedTileDialog.show({tile}),
                    },
                    {
                        label: "Clone",
                        onClick: () =>
                            selectModelForCloningDialog.show({
                                tile,
                                folder: folders.value.find((f) => f.tiles.findIndex((c) => c.id === tile.id) > -1),
                            }),
                    },
                    {
                        label: "Move to",
                        onClick: () => moveSharedTileDialog.show({params: tile}),
                    },
                    {
                        label: "Delete",
                        className: "text-danger",
                        onClick: () => deleteSharedTileDialog.show({tiles: [tile]}),
                    },
                ],
            })
    );
