import React from "react";
import {cs} from "../../react/chain-services";
import {UseState} from "../../react/use-state";
import "./chip-input.scss";
import {isNotBlank} from "../../utils/strings";
import {chain} from "../../utils/fs";
import {unique} from "../../utils/collections";

export const ChipInput = ({state, numericOnly = true}) =>
    cs(["localState", (_, next) => UseState({initValue: "", next})], ({localState}) => (
        <div className="chip-input-99o">
            <div className="values">
                {cs(["list", (_, next) => next(isNotBlank(state.value) ? state.value.split(",") : [])], ({list}) =>
                    list.map((v) => (
                        <div className="value" onClick={() => state.onChange(list.filter((l) => l !== v).join(","))}>
                            {v}
                            <i className="fal fa-times" />
                        </div>
                    ))
                )}
            </div>

            <input
                placeholder="Enter your value"
                value={localState.value}
                onChange={(e) => localState.onChange(e.target.value)}
                onKeyDown={(e) => {
                    const onEnter = () => {
                        if (e.key === "Enter" && isNotBlank(localState.value)) {
                            state.change((v) => {
                                const list = v ? v.split(/,\s?/) : [];

                                const ret = chain(
                                    list,
                                    (old) => unique([...old, localState.value]),
                                    (old) => old.filter((v) => v)
                                );

                                return ret.join(",");
                            });
                            localState.onChange("");
                        }
                    };

                    if (numericOnly) {
                        const key = e.key;

                        if (
                            /^\d*\.?\d*$/.test(key) ||
                            key === "Shift" ||
                            key === "Backspace" ||
                            key === "Alt" ||
                            key === "Tab" ||
                            key === "Control" ||
                            key === "Enter" ||
                            key === "ArrowUp" ||
                            key === "ArrowLeft" ||
                            key === "ArrowRight" ||
                            key === "ArrowDown" ||
                            ((e.keyCode === 65 || e.keyCode === 86 || e.keyCode === 67) && (e.ctrlKey === true || e.metaKey === true))
                        ) {
                            //input is VALID
                            onEnter();
                        } else {
                            if (e.preventDefault) e.preventDefault();
                        }
                    } else {
                        onEnter();
                    }
                }}
            />
        </div>
    ));
