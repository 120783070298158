import {getExtractedPointsCompare} from "./get-extracted-points-compare";
import {getColumnPointsTableCompare} from "./column-points-table-compare";
import {getColumnPointsTable} from "./column-points-table";
import {getExtractedPoints} from "./get-extracted-points";
import {tooltipLayout} from "./tooltip-layout";
import {getBarLineNonCompareHeader} from "./bar-line-non-compare-header";
import {getReferencePointsTable} from "./reference-points-table";

export const renderBarChartTooltip = ({points, tile, theme, rawData, formatters, isCompare}) => {
    const dataPoints = points.filter((p) => !p.series.userOptions.customType);

    const referencePointsTable = getReferencePointsTable({
        tile,
        theme,
        formatters,
        points,
        series: points[0].series.chart.series,
    });

    if (isCompare) {
        const {extractedPoints, generalInfo} = getExtractedPointsCompare({
            points: dataPoints,
            formatters,
            rawData,
            keepZeroValuePoints: dataPoints[0].series.userOptions.isCumulative,
        });
        return tooltipLayout({
            content: `<div class="table">
                    ${getColumnPointsTableCompare({
                        tile,
                        extractedPoints,
                        generalInfo,
                        rawData,
                        formatters,
                        theme,
                    })}
                </div>
                ${referencePointsTable}`,
            theme,
            isCompare,
        });
    }

    const {extractedPoints, generalInfo} = getExtractedPoints({
        points: dataPoints,
        formatters,
        keepZeroValuePoints: dataPoints[0].series.userOptions.isCumulative,
    });
    return tooltipLayout({
        content: `${getBarLineNonCompareHeader({
            tile,
            extractedPoints,
            generalInfo,
            formatters,
            theme,
        })}
            <div class="table non-compare">
                ${getColumnPointsTable({
                    tile,
                    extractedPoints,
                    generalInfo,
                    rawData,
                    formatters,
                    theme,
                })}
            </div>
            ${referencePointsTable}`,
        theme,
    });
};
