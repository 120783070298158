import * as React from "react";
import {cs} from "@common/react/chain-services";
// import "./region-select.scss";
// import {Dropdown} from "@common/ui-components/dropdown/dropdown";
// import {UseState} from "@common/react/use-state";
// import continents from "@common/ui-components/charts/map-tile/highmaps-list/custom";
// import countries from "@common/ui-components/charts/map-tile/highmaps-list/countries";
// import {cx} from "emotion";
// import {SearchInput} from "../../../../../../../../../../../../../common/form/search-input/search-input";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {camelCaseToSpaces} from "@common/utils/strings";

export const RegionSelect = ({value, onChange}) =>
    cs(({}) => {
        return (
            <div className="region-select-6ws region-select">
                {DropdownSelect({
                    label: "Region",
                    //"Global", "UsCanada", "SouthAmerica", "Oceania", "NorthAmerica", "Europe", "Asia", "Africa"
                    list: [
                        {
                            name: "Global",
                            value: "Global",
                        },
                        {
                            name: "United States & Canada",
                            value: "UsCanada",
                        },
                        {
                            name: "North America",
                            value: "NorthAmerica",
                        },
                        {
                            name: "South America",
                            value: "SouthAmerica",
                        },
                        {
                            name: "Oceania",
                            value: "Oceania",
                        },
                        {
                            name: "Europe",
                            value: "Europe",
                        },
                        {
                            name: "Asia",
                            value: "Asia",
                        },
                        {
                            name: "Africa",
                            value: "Africa",
                        },
                    ],
                    isSelected: (v) => v.value === value,
                    onChange: (v) => onChange(v.value),
                    valueToLabel: (v) => v.name,
                })}
            </div>
        );
    });

// export const RegionSelect = ({value, onChange, }) => cs(
//     ({}) => {
//         const getValueName = (value) => (
//             continents.maps.find((m) => m.path === value) || countries.maps.find((m) => m.path === value)
//         ).name;
//
//         return (
//             <div className="region-select-6ws region-select">
//                 {Dropdown({
//                     className: "region-select-2tw region-select",
//                     minExpandHeight: 300,
//                     renderToggle: ({showExpand, showingExpand}) => (
//                         <div
//                             className={cx("toggle", {expanding: showingExpand}, {"with-value": value})}
//                             onClick={() => showExpand(!showingExpand)}
//                         >
//                             <div className="label">
//                                 Region
//                             </div>
//                             {value && getValueName(value)}
//                             <i className="fa fa-chevron-down"/>
//                         </div>
//                     ),
//                     renderExpand: ({close, width}) => cs(
//                         ["search", (_, next) => UseState({next})],
//                         ["address", (_, next) => UseState({
//                             getInitValue: () => {
//                                 if (value) {
//                                     return continents.maps.find((m) => m.path === value) ? "Continents" : "Countries";
//                                 }
//                                 return null;
//                             },
//                             next,
//                         })],
//                         ({search, address}) => {
//                             const rMapGroups = () => (
//                                 <div className="map-groups">
//                                     {["Continents", "Countries"].map((g, i) => (
//                                         <div
//                                             key={i}
//                                             className="group"
//                                             onClick={() => address.onChange(g)}
//                                         >
//                                             {g}
//                                             <i className="fa fa-chevron-right"/>
//                                         </div>
//                                     ))}
//                                 </div>
//                             );
//
//                             const rMapList = (maps) => (
//                                 <div className="list">
//                                     {maps.map((c, i) => (
//                                         <div
//                                             key={i}
//                                             className={cx("map", {credited: c.isCredited, selected: c.path === value})}
//                                             onClick={() => {
//                                                 onChange(c.path);
//                                                 close();
//                                             }}
//                                         >
//                                             {c.name}
//                                         </div>
//                                     ))}
//                                 </div>
//                             );
//
//                             const rMaps = () => {
//                                 return (
//                                     <div className="maps">
//                                         <div
//                                             className="header"
//                                             onClick={() => address.onChange(null)}
//                                         >
//                                             <i className="fa fa-chevron-left"/>
//                                             {address.value}
//                                         </div>
//                                         {address.value === "Continents" ? rMapList(continents.maps) : rMapList(countries.maps)}
//                                     </div>
//                                 )
//                             };
//
//                             const rSearchedMaps = () => {
//                                 const rGroup = (name, maps) => {
//                                     if (maps.length === 0) {
//                                         return;
//                                     }
//                                     return (
//                                         <div className="group">
//                                             <div className="header">
//                                                 {name}
//                                             </div>
//                                             {rMapList(maps)}
//                                         </div>
//                                     )
//                                 };
//                                 const getSearchedMaps = (maps) => maps.filter((m) => m.name.toLowerCase().includes(search.value.toLowerCase()));
//                                 return (
//                                     <div className="searched-maps">
//                                         {rGroup("Continents", getSearchedMaps(continents.maps))}
//                                         {rGroup("Countries", getSearchedMaps(countries.maps))}
//                                     </div>
//                                 )
//                             };
//
//                             return (
//                                 <div className="region-menu" style={{width: width}}>
//                                     <div className="search">
//                                         {SearchInput({
//                                             state: search,
//                                             placeholder: "Search for region...",
//                                             delay: 0,
//                                         })}
//                                     </div>
//                                     <div className="menu">
//                                         {search.value ? rSearchedMaps() : (
//                                             address.value ? rMaps() : rMapGroups()
//                                         )}
//                                     </div>
//                                 </div>
//                             )
//                         }
//                     )
//                 })}
//             </div>
//         )
//     }
// );
