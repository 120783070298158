import React from "react";
import {cs} from "@common/react/chain-services";
import {DropdownSelect2} from "@common/ui-components/dropdown-select/dropdown-select2";
import "./pivot-data-configuration.scss";
import {keyed} from "@common/react/keyed";
import {SelectColumns} from "../../../../common/select-columns/select-columns";
import {rColumnSelectFromStepOutput} from "../../../../common/column-select-from-step-output/column-select-from-step-output";
import {removeIndex} from "@common/utils/collections";
import {ffToDropdown} from "../../../../../../../../../common/form/ff-to-dropdown";
import {keepOnly, omit} from "@common/utils/objects";
import {ffToTextInput} from "../../../../../../../../../common/form/ff-to-text-input";
import {DebounceTextInput} from "../../../../../../../../../common/form/debounce-input/debounce-text-input";
import {spc} from "@common/react/state-path-change";
import {aggregationFuncs, aggregationFuncsCal, aggregationFuncsOther} from "../../../../../../common/aggregation-funcs/aggregation-funcs";
import {consumeContext} from "@common/react/context";
import {rNewColumnNameInput} from "../../common/new-column-name-input";
import {GhostButton} from "../../../../../../../../../common/form/buttons/ghost-button/ghost-button";
import {PlusIcon} from "@common/ui-components/icons/global-icons";

export const PivotDataConfiguration = ({step, form, transformation}) =>
    cs(consumeContext("getModelColumn"), ({getModelColumn}) => {
        const inputStep = transformation.value.steps.find((s) => s.id === step.value.inputStepID);
        const columnSelect = rColumnSelectFromStepOutput({
            label: "Column",
            columns: inputStep?.outputColumns || [],
        });

        return (
            <div className="pivot-data-configuration-8ew">
                <div className="config-group">
                    <div className="label">Columns to show as rows</div>

                    {SelectColumns({
                        step,
                        form,
                        path: ["groupByColumns"],
                        columns: inputStep?.outputColumns,
                    })}
                </div>

                <div className="config-group">
                    <div className="label">Column Values to show AS Columns</div>

                    <div className="columns-values">
                        <div className="config-group">
                            <div className="label">Column to search</div>

                            {(() => {
                                const dropdownProps = ffToDropdown(form.field(["columnToSearch"]));
                                return columnSelect({
                                    state: {
                                        value: dropdownProps.value,
                                        onChange: (c) =>
                                            step.onChange({
                                                ...omit(step.value, ["valueLookup"]),
                                                columnToSearch: c,
                                            }),
                                    },
                                    ...keepOnly(dropdownProps, ["domRef", "hasError", "errorMessage"]),
                                });
                            })()}
                        </div>

                        <div className="config-group">
                            <div className="label">New Columns</div>

                            {PivotNewColumns({step, form})}
                        </div>

                        <div className="config-group">
                            <div className="label">Value Column</div>

                            {(() => {
                                const dropdownProps = ffToDropdown(form.field(["valueColumn"]));
                                return columnSelect({
                                    state: {
                                        value: dropdownProps.value,
                                        onChange: (c) =>
                                            step.onChange({
                                                ...omit(step.value, ["valueLookup"]),
                                                valueColumn: c,
                                            }),
                                    },
                                    ...keepOnly(dropdownProps, ["domRef", "hasError", "errorMessage"]),
                                });
                            })()}
                        </div>

                        <div className="config-group">
                            <div className="label">Value Aggregation</div>

                            {(() => {
                                const valueColumn = getModelColumn(form.field(["valueColumn"]).state.value);
                                return DropdownSelect2({
                                    label: "Value Aggregation",
                                    list: [...aggregationFuncsOther, ...(["Int", "Double"].includes(valueColumn?.dataType) ? aggregationFuncsCal : [])],
                                    valueToLabel: (v) => v.label,
                                    ...ffToDropdown(form.field(["valueAgg"]), ["value"]),
                                });
                            })()}
                        </div>
                    </div>
                </div>
            </div>
        );
    });

const PivotNewColumns = ({step, form}) =>
    cs(() => (
        <div className="new-columns">
            <div className="headers">
                <div>Match Value</div>
                <div>Column Name</div>
            </div>

            <div className="list">
                {(step.value.newColumns || []).map((item, index) =>
                    cs(keyed(index), () => (
                        <div className="item">
                            {DebounceTextInput({
                                label: "Name",
                                ...ffToTextInput(form.field(["newColumns", index, "valueMatch"])),
                            })}
                            <div className="equal-sign">=</div>

                            {rNewColumnNameInput({
                                form,
                                newColumnPath: ["newColumns", index, "newColumn"],
                                newColumnNamePath: ["newColumns", index, "newColumn", "name"],
                            })}

                            <div className="trash-icon" onClick={() => spc(step, ["newColumns"], (_newColumns) => removeIndex(index, _newColumns))}>
                                <img src={require(`../../../../../../common/icons/trash.svg`)} />
                            </div>
                        </div>
                    ))
                )}

                <GhostButton
                    iconLeft={<PlusIcon />}
                    className="btn-add"
                    onClick={() => spc(step, ["newColumns"], (_newColumns) => [...(_newColumns ?? []), {$type: "PivotColumn"}])}
                >
                    ADD
                </GhostButton>
            </div>
        </div>
    ));
