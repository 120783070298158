import React from "react";
import {cs} from "@common/react/chain-services";
import {CircleRadius} from "../../model-panel";
import {lighten} from "@common/utils/color-util";
import {UseState} from "@common/react/use-state";
import {Static2} from "@common/react/static-2";
import {consumeContext} from "@common/react/context";

export const EditDataView = ({table, color, modelActionTooltip, scale, interactions, model, dataSources}) =>
    cs(
        ["hovering", (_, next) => UseState({next})],
        ["actionRef", (_, next) => Static2({next})],
        consumeContext("routing"),
        ({hovering, actionRef, routing}) => {
            return (
                <g>
                    <path
                        ref={actionRef.set}
                        className="action-btn"
                        onMouseEnter={(e) => {
                            hovering.onChange(true);
                            const {x, y} = actionRef.get()?.getBoundingClientRect();
                            modelActionTooltip.showTooltip({
                                pos: {
                                    x,
                                    y,
                                    transform: `translate(-100%)`,
                                },
                                info: "Edit Data View",
                            });
                        }}
                        onMouseLeave={() => {
                            hovering.onChange(false);
                            modelActionTooltip.hideToolTip();
                        }}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            routing.goto("edit-data-view", {
                                tab: "build",
                                transformationId: table.transformationID,
                            });
                        }}
                        x={table.position.x - (CircleRadius * 2 - 6)}
                        y={table.position.y - CircleRadius * 2 + 1}
                        data-name="Row-Level Security"
                        d="M-49.057-32.779A59,59,0,0,1,0-59v25A34,34,0,0,0-28.27-18.889Z"
                        transform="translate(60.011 60)"
                        fill={hovering.value ? lighten(color, 80) : lighten(color, 70)}
                        stroke="#fff"
                        strokeWidth="2"
                    />

                    <svg
                        style={{pointerEvents: "none"}}
                        xmlns="http://www.w3.org/2000/svg"
                        width="13.198"
                        height="13.181"
                        viewBox="0 0 13.198 13.181"
                        x={29.5}
                        y={13.7}
                    >
                        <g transform="translate(-257.522 -120.369)">
                            <path
                                className="a"
                                fill="#546b81"
                                d="M267.664,133.55h-8.35a1.794,1.794,0,0,1-1.792-1.793v-8.349a1.794,1.794,0,0,1,1.792-1.793h4.174a.6.6,0,1,1,0,1.2h-4.174a.593.593,0,0,0-.592.593v8.349a.594.594,0,0,0,.592.594h8.35a.6.6,0,0,0,.593-.594v-4.174a.6.6,0,1,1,1.2,0v4.174A1.794,1.794,0,0,1,267.664,133.55Z"
                            />
                            <path
                                className="a"
                                fill="#546b81"
                                d="M261.7,129.972a.6.6,0,0,1-.582-.746l.6-2.386a.6.6,0,0,1,.157-.278l5.662-5.665h0a1.91,1.91,0,0,1,2.637,0,1.87,1.87,0,0,1,0,2.637l-5.667,5.666a.59.59,0,0,1-.279.158l-2.385.6A.6.6,0,0,1,261.7,129.972Zm1.142-2.679-.316,1.254,1.253-.313,5.549-5.548a.665.665,0,0,0-.941-.94Z"
                            />
                        </g>
                    </svg>
                </g>
            );
        }
    );
