import React from "react";
import "./left-panel-tabs.scss";
import {cs} from "@common/react/chain-services";
import {cx} from "emotion";
import {keyed} from "@common/react/keyed";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";
import {AlertIcon} from "@common/ui-components/icons/global-icons";

export const LeftPanelTabs = ({tabs, selected, hideHeaderIfOneTab = false, viewTree, children}) =>
    cs(({}) => (
        <div className={`left-panel-tabs-4dd left-panel-tabs ${hideHeaderIfOneTab && "hide-header"}`}>
            {!hideHeaderIfOneTab && (
                <div className="header">
                    {tabs.map((tab, i) => (
                        <div
                            style={{
                                width: `${100 / tabs.length}%`,
                            }}
                            className={cx("tab", {selected: selected.value === tab.id, disabled: tab.disabled})}
                            key={i}
                            onClick={() => !tab.disabled && selected.value !== tab.id && selected.onChange?.(tab.id)}
                        >
                            <div className="label">{tab.label}</div>

                            {tab.errorsNumber > 0 && (
                                <div className="error-badge-wrapper">
                                    <AlertIcon />
                                </div>
                            )}

                            {tab.hasError && (
                                <div className="error-icon">
                                    <i className="fa fa-exclamation" />
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            )}

            <div className="content">
                {cs(keyed(selected.value), ({}) => tabs.find((tab) => tab.id === selected.value)?.render())}

                {children}
            </div>
        </div>
    ));
