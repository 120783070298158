import {cs} from "@common/react/chain-services";
import {Invoke} from "@common/react/invoke";
import {UseState} from "@common/react/use-state";
import {parseUrlQuery} from "../../../../../common/utils/parse-url-query";
import {arrMapToO, keepOnly} from "../../../../../common/utils/objects";
import {chain} from "../../../../../common/utils/fs";
import {buildUrlQuery} from "../../../../../common/utils/build-url-query";
import {Router} from "./router";
import {keyed} from "@common/react/keyed";
import {cSsJson} from "@common/react/ss-json";

const prevRoutesStorage = cSsJson("PREV_ROUTES");

export const Routing = ({routes, defaultParams, getDefaultRoute, next}) =>
    cs(
        ["router", ({}, next) => Router({routes, next})],
        ["block", (_, next) => UseState({initValue: null, next})],

        ({router}, next) =>
            router.current != null
                ? next()
                : (() => {
                      const defaultRoute = getDefaultRoute(prevRoutesStorage.get());
                      return cs(keyed(defaultRoute), () =>
                          Invoke({
                              fn: () => {
                                  setTimeout(() => router.pushHistory(defaultRoute), 0);
                              },
                          })
                      );
                  })(),

        ({
            router,
            router: {
                routes,
                current: {props, route, routeConfig},
                goBack,
            },
            block,
        }) => {
            const queryParams = parseUrlQuery(props.location.search);

            const params = {
                ...defaultParams,
                ...props.match.params,
                ...queryParams,
            };

            const toPath = (name, params2) => {
                const nextRoute = routes.find((r) => r.name === name);

                const params1 = {
                    ...defaultParams,
                    ...props.match.params,
                    ...(route.query &&
                        keepOnly(
                            queryParams,
                            route.query.filter(({carryOn}) => carryOn).map(({paramName}) => paramName)
                        )),
                    ...params2,
                };

                const query =
                    nextRoute.query &&
                    chain(
                        nextRoute.query.filter(({paramName}) => params1[paramName] != null),
                        (_) =>
                            arrMapToO(
                                _,
                                ({paramName}) => params1[paramName],
                                ({paramName}) => paramName
                            ),
                        (_) => buildUrlQuery(_)
                    );
                return nextRoute.path.replace(/:(\w+)/g, (s) => encodeURIComponent(params1[s.substring(1)])) + (query ?? "");
            };

            const previousRoutes = prevRoutesStorage.get();
            return next({
                params,
                previousRoutes,
                route: route.route,
                routeConfig,
                routeName: route.name,
                goBack,
                goto: async (name, params1, openNewTab) => {
                    if (block.value) {
                        await block.value?.();
                        block.onChange(null);
                    }
                    prevRoutesStorage.set([{params, routeName: route.name}, ...(previousRoutes || [])]);

                    if (openNewTab) {
                        window.open(`/#${toPath(name, params1)}`);
                        return;
                    }

                    return router.pushHistory(toPath(name, params1));
                },
                pushParams: (params) => router.pushHistory(toPath(route.name, params)),
                updateParams: (params1) => router.pushHistory(toPath(route.name, {...params, ...params1})),
                pushHistory: router.pushHistory,
                toPath,

                //TODO: add blockers for checking unsaved changes (simple version) #653: Filter Alert
                block: (block1) => {
                    block.onChange(block1);
                },
            });
        }
    );
