import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {TabsHeader} from "../../../../common/tabs/tabs-header";
import {FlatFileColumnsImport} from "./flat-file-columns-import";
import {FlatFileTablesImport} from "./flat-file-tables-import";
import {FlatFileNameLocation} from "./flat-file-name-location";
import {consumeContext} from "@common/react/context";
import {formatFoldersPath, getFoldersPath} from "../../common/helpers";
import {BannerClosable, SuccessInfo} from "../../import-new-flat-file/banner-closable/banner-closable";
import {ObserveDomSize} from "@common/react/observe-dom-size";

import "./flat-file-import-configuration.scss";

export const FlatFileImportConfiguration = ({dataSource}) =>
    cs(
        consumeContext("dataSourceFolders"),
        ["active", (_, next) => UseState({initValue: "Name", next})],
        ["bannerDomSize", (_, next) => ObserveDomSize({next})],
        ({active, dataSourceFolders, bannerDomSize}) => {
            return (
                <div className="flat-file-import-configuration-88y">
                    <div className="col-left">
                        <div ref={bannerDomSize.ref}>
                            {BannerClosable({
                                content: SuccessInfo({}),
                            })}
                        </div>

                        <div className="col-left-content">
                            {TabsHeader({
                                className: "flat-file-tabs",
                                tabs: [
                                    {
                                        id: "Tables",
                                        label: "Tables",
                                        getNumbers: () => dataSource.value.tables.length + (dataSource.value.disabledTables || []).length,
                                    },
                                    {
                                        id: "Columns",
                                        label: "Columns",
                                        getNumbers: () => {
                                            const c1 = dataSource.value.tables.reduce((prev, item) => (prev += item.columns.length), 0);
                                            // const c2 = (dataSource.value.disabledTables || []).reduce((prev, item) => prev += item.columns.length, 0)
                                            return c1;
                                        },
                                    },
                                    {
                                        id: "Name",
                                        label: "Name & Location",
                                    },
                                ].map((item) => ({
                                    id: item.id,
                                    label: (
                                        <>
                                            {item.label} {item.getNumbers && `(${item.getNumbers?.()})`}
                                        </>
                                    ),
                                    onClick: () => active.onChange(item.id),
                                    active: active.value === item.id,
                                })),
                            })}

                            {active.value === "Tables" && FlatFileTablesImport({dataSource})}
                            {active.value === "Columns" && FlatFileColumnsImport({dataSource})}
                            {active.value === "Name" && FlatFileNameLocation({dataSource})}
                        </div>
                    </div>

                    <div className="col-right">
                        <div className="summary-box">
                            <div className="label">
                                <i className="fa-light fa-cloud-arrow-up"></i>
                                {"  "}
                                Import Summary
                            </div>

                            <div className="content">
                                <div className="section">
                                    <div className="label">Tables</div>
                                    <div className="value">
                                        <div className="text">
                                            {dataSource.value.tables.length} to import, {(dataSource.value?.disabledTables || []).length} to exclude
                                        </div>
                                    </div>
                                </div>
                                <div className="section">
                                    <div className="label">Columns</div>
                                    <div className="value">
                                        <div className="text">
                                            {dataSource.value.tables.reduce((prev, item) => (prev += item.columns.length), 0)} to import,{" "}
                                            {dataSource.value.tables.reduce((prev, item) => (prev += (item.disabledColumns || []).length), 0)} to exclude
                                        </div>
                                    </div>
                                </div>
                                <div className="section">
                                    <div className="label">Name & Location</div>
                                    <div className="value">
                                        <div className="text">{dataSource.value.name}</div>
                                        {dataSourceFolders.value && (
                                            <div className="location">{formatFoldersPath(getFoldersPath(dataSource.value?.folderID, dataSourceFolders.value))}</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    );
