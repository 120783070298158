import {renderBarChartTooltip} from "./bar-chart-tooltip";
import {renderComboChartTooltip} from "./combo-chart-tooltip";
import {renderLineChartTooltip} from "./line-chart-tooltip";
import {renderPieDonutChartTooltip} from "./pie-donut-chart-tooltip";
import {renderGaugeChartTooltip} from "./gauge-chart-tooltip";
import {renderMapTileTooltip} from "./map-tile-tooltip";
import {renderFunnelChartTooltip} from "./funnel-chart-tooltip";
import {renderScatterPlotTooltip} from "./scatter-plot-tooltip";
import {renderVennDiagramTooltip} from "./venn-diagram-tooltip";
import {
    renderBoxPlotTooltip
} from "@common/ui-components/charts/factory/tooltip/shared-tooltip-formatter/box-plot-tooltip";

const sharedTooltipFormatters = [
    {
        types: ["VerticalBarChartTile", "HorizontalBarChartTile"],
        formatter: renderBarChartTooltip,
    },
    {
        types: ["ComboChartTile"],
        formatter: renderComboChartTooltip,
    },
    {
        types: ["LineChartTile"],
        formatter: renderLineChartTooltip,
    },
    {
        types: ["PieChartTile", "DonutChartTile"],
        formatter: renderPieDonutChartTooltip,
    },
    {
        types: ["GaugeTile"],
        formatter: renderGaugeChartTooltip,
    },
    {
        types: ["MapTile"],
        formatter: renderMapTileTooltip,
    },
    {
        types: ["FunnelChartTile"],
        formatter: renderFunnelChartTooltip,
    },
    {
        types: ["ScatterPlotTile", "BubbleChartTile"],
        formatter: renderScatterPlotTooltip,
    },
    {
        types: ["VennDiagramTile"],
        formatter: renderVennDiagramTooltip,
    },
    {
        types: ["BoxPlotTile"],
        formatter: renderBoxPlotTooltip
    }
];

export const getSharedTooltipFormatter = (type) => {
    return sharedTooltipFormatters.find((tf) => tf.types.includes(type)).formatter;
};
