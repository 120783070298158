import "./model-column-data-sources-select.scss";

import {cx} from "emotion";
import * as React from "react";

import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";

import {SearchInput} from "../../../../../common/form/search-input/search-input";

import {Dropdown} from "@common/ui-components/dropdown/dropdown";
import {getFieldType} from "@common/ui-components/charts/common/get-field-type";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";

import {TruncatingTooltip} from "../truncating-tooltip/truncating-tooltip";

import {ModelColumnCard} from "./model-column-card";
import {isMatchText} from "@common/utils/strings";

/**
 * model field dropdown select with relating data sources information
 * */
export const cModelColumnDataSourcesSelect =
    ({model, dataSources, getDataSourceInfo, getValueModelColumn}) =>
    ({value, onChange, allowedType}) =>
        cs(({}) => {
            const structuredModel = getDataSourceStructureOfModel(model, dataSources);

            const isAllowedColumn = (() => {
                // const allowedFieldType = (
                //     value == null ? null : value.dataType
                //         ? getFieldType(value)
                //         : getFieldType(getValueModelColumn(value)) // sometimes the value doesn't have dataType (like column from filter)
                // ) || allowedType;

                const allowedFieldType = allowedType;

                return (column) => {
                    const columnType = getFieldType(column);
                    return !allowedFieldType || allowedFieldType === columnType;
                };
            })();

            return Dropdown({
                className: "model-column-data-sources-select-3s5 model-column-data-sources-select",
                minExpandHeight: 360,
                renderToggle: ({showExpand, showingExpand}) => (
                    <div className={cx("toggle", {expanding: showingExpand})} onClick={() => showExpand(!showingExpand)}>
                        {!value ? (
                            <div className="empty-model-column-card" />
                        ) : (
                            ModelColumnCard({
                                modelColumn: value,
                                dsInfo: getDataSourceInfo(value),
                                nameTooltipEnabled: true,
                                dataSourceTooltipEnabled: true,
                            })
                        )}
                        <i className="fa fa-chevron-down" />
                    </div>
                ),
                renderExpand: ({close, width, top, bottom}) =>
                    cs(
                        ["search", (_, next) => UseState({next})],
                        [
                            "address",
                            (_, next) =>
                                UseState({
                                    initValue: !value
                                        ? null
                                        : value.$type === "ViewModelColumn"
                                        ? {
                                              type: "ViewModelTable",
                                              viewTableId: value.tableId,
                                          }
                                        : {
                                              dsId: value.dataSourceID,
                                              dsTableId: value.dataSourceTableID,
                                          },
                                    next,
                                }),
                        ],
                        ({search, address}) => {
                            // console.log("structure model", structuredModel)
                            // console.log("value", value)
                            // console.log("address", address.value)

                            const maxHeight = window.innerHeight - (top || bottom) - 120;

                            const rSearchedColumns = () => {
                                const rSm = (sm) => {
                                    return (
                                        <div key={sm.dsId || sm.type} className="table-group">
                                            {sm.tables.map((table) => {
                                                const searchedColumns = table.columns.filter((c) => isMatchText(c.name, search.value));
                                                if (!searchedColumns?.length > 0) {
                                                    return;
                                                }
                                                return (
                                                    <div key={table.id} className="table">
                                                        <div className="header">{table.$type === "ViewModelTable" ? "View Table" : `${table.dsName} > ${table.dsTableName}`}</div>
                                                        <div className="column-list">
                                                            {searchedColumns.map((column) => (
                                                                <div
                                                                    key={column.id}
                                                                    className={cx("item", {
                                                                        selected: column.id === value?.id,
                                                                    })}
                                                                    onClick={() => {
                                                                        onChange({
                                                                            ...column,
                                                                            tableId: table.id,
                                                                            modelId: model.id,
                                                                            // to navigate calculated and aggregated measure column in this dropdown
                                                                            ...(["CalculatedModelColumn", "AggregatedMeasureModelColumn"].includes(column.$type) && {
                                                                                dataSourceID: table.dataSourceID,
                                                                                dataSourceTableID: table.dataSourceTableID,
                                                                            }),
                                                                        });
                                                                        close();
                                                                    }}
                                                                >
                                                                    {ModelColumnCard({
                                                                        modelColumn: column,
                                                                        dsInfo: {
                                                                            dsName: table.dsName,
                                                                            dsTableName: table.dsTableName,
                                                                        },
                                                                        nameTooltipEnabled: true,
                                                                    })}

                                                                    {column.id === value?.id && <img src={require("../icons/check-icon2.svg")} className="check-icon" alt="" />}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    );
                                };

                                return (
                                    <VerbScrollbar className="searched-columns" style={{maxHeight: maxHeight + 56}}>
                                        {structuredModel.map((sm) => rSm(sm))}
                                    </VerbScrollbar>
                                );
                            };

                            const rDataSourceLevel = () => {
                                return (
                                    <VerbScrollbar className="data-source-level" style={{maxHeight}}>
                                        {structuredModel.map((sm, i) => (
                                            <div
                                                key={i}
                                                className="item"
                                                onClick={() =>
                                                    address.onChange(
                                                        sm.type === "ViewModelTable"
                                                            ? {
                                                                  type: "ViewModelTable",
                                                              }
                                                            : {dsId: sm.dsId}
                                                    )
                                                }
                                            >
                                                <img
                                                    className={sm.type === "ViewModelTable" ? "view-table-indicator" : "database-indicator"}
                                                    src={sm.type === "ViewModelTable" ? require("../icons/data-view-icon.svg") : require("../icons/datasource-icon-light.svg")}
                                                    alt=""
                                                />
                                                {sm.dsName || "View Tables"}
                                                <img className="chevron" src={require("../icons/chevron-right-icon.svg")} alt={""} />
                                            </div>
                                        ))}
                                    </VerbScrollbar>
                                );
                            };

                            const rTableLevel = () => {
                                const dsOrViewModel = structuredModel.find(
                                    (sm) => sm.dsId === address.value.dsId || (sm.type && address.value.type && sm.type === address.value.type)
                                );
                                // console.log("table level", dsOrViewModel)
                                return (
                                    <div className="table-level">
                                        <div className="header" onClick={() => address.onChange(null)}>
                                            <img src={require("../icons/chevron-left-big-icon.svg")} alt={""} />
                                            <div className="name">{dsOrViewModel.type === "ViewModelTable" ? "View Tables" : dsOrViewModel.dsName}</div>
                                        </div>
                                        <VerbScrollbar className="list" style={{maxHeight}}>
                                            {dsOrViewModel.tables
                                                .filter((table) => !(table.$type === "ViewModelTable" && table.versionDetails === "NotPublished"))
                                                .map((table) => (
                                                    <div
                                                        key={table.id}
                                                        className="item"
                                                        onClick={() =>
                                                            address.onChange(
                                                                dsOrViewModel.type === "ViewModelTable"
                                                                    ? {
                                                                          type: "ViewModelTable",
                                                                          viewTableId: table.id,
                                                                      }
                                                                    : {
                                                                          dsId: dsOrViewModel.dsId,
                                                                          dsTableId: table.dataSourceTableID,
                                                                      }
                                                            )
                                                        }
                                                    >
                                                        <img
                                                            className={table.$type === "ViewModelTable" ? "view-table-indicator" : "table-indicator"}
                                                            src={
                                                                table.$type === "ViewModelTable"
                                                                    ? require("../icons/data-view-icon.svg")
                                                                    : require("../icons/data-source-table-icon.svg")
                                                            }
                                                            alt={""}
                                                        />
                                                        {table.$type === "ViewModelTable" ? table.name : table.dsTableName}
                                                        <img className={"chevron"} src={require("../icons/chevron-right-icon.svg")} alt={""} />
                                                    </div>
                                                ))}
                                        </VerbScrollbar>
                                    </div>
                                );
                            };

                            const rColumnLevel = () => {
                                const dsOrViewModelTable = structuredModel
                                    .find((sm) => sm.dsId === address.value.dsId || (sm.type && address.value.type && sm.type === address.value.type))
                                    .tables.find(
                                        (table) =>
                                            (table.$type === "ViewModelTable" && table.id === address.value.viewTableId && isPublishedView(table)) ||
                                            (table.$type === "DataSourceModelTable" && table.dataSourceTableID === address.value.dsTableId)
                                    );
                                // console.log("column level", dsOrViewModelTable)

                                return (
                                    <div className="column-level">
                                        {cs(["truncatingTooltip", (_, next) => TruncatingTooltip({next})], ({truncatingTooltip}) => (
                                            <div
                                                className="header"
                                                onClick={() =>
                                                    address.onChange(
                                                        dsOrViewModelTable.$type === "ViewModelTable"
                                                            ? {
                                                                  type: "ViewModelTable",
                                                              }
                                                            : {
                                                                  dsId: dsOrViewModelTable.dataSourceID,
                                                              }
                                                    )
                                                }
                                            >
                                                <img src={require("../icons/chevron-left-big-icon.svg")} alt={""} />
                                                <div
                                                    {...{
                                                        className: "name",
                                                        ...truncatingTooltip.tooltip(
                                                            dsOrViewModelTable.$type === "ViewModelTable" ? (
                                                                <div className="">
                                                                    View Tables <br />
                                                                    {dsOrViewModelTable.name}
                                                                </div>
                                                            ) : (
                                                                <div className="">
                                                                    {dsOrViewModelTable.dsName}
                                                                    <br />
                                                                    {dsOrViewModelTable.dsTableName}
                                                                </div>
                                                            )
                                                        ),
                                                        ref: truncatingTooltip.ref,
                                                    }}
                                                >
                                                    {dsOrViewModelTable.$type === "ViewModelTable"
                                                        ? `View Tables  >  ${dsOrViewModelTable.name}`
                                                        : `${dsOrViewModelTable.dsName}  >  ${dsOrViewModelTable.dsTableName}`}
                                                </div>
                                            </div>
                                        ))}
                                        <VerbScrollbar className="list" style={{maxHeight}}>
                                            {dsOrViewModelTable.columns
                                                .filter((column) => isAllowedColumn(column) && isPublishedTransformedColumn(column))
                                                .map((column) => (
                                                    <div
                                                        key={column.id}
                                                        className={cx("item", {
                                                            selected: column.id === value?.id,
                                                        })}
                                                        onClick={() => {
                                                            onChange({
                                                                ...column,
                                                                tableId: dsOrViewModelTable.id,
                                                                tableName: dsOrViewModelTable.name,
                                                                modelId: model.id,
                                                                // to navigate calculated and aggregated measure column in this dropdown
                                                                ...(["CalculatedModelColumn", "AggregatedMeasureModelColumn"].includes(column.$type) && {
                                                                    dataSourceID: dsOrViewModelTable.dsId,
                                                                    dataSourceTableID: dsOrViewModelTable.dsTableId,
                                                                }),
                                                            });
                                                            close();
                                                        }}
                                                    >
                                                        {ModelColumnCard({
                                                            modelColumn:
                                                                column.$type === "ViewModelColumn"
                                                                    ? {
                                                                          ...column,
                                                                          tableName: dsOrViewModelTable.name,
                                                                      }
                                                                    : column,
                                                            dsInfo: {
                                                                dsName: dsOrViewModelTable.dsName,
                                                                dsTableName: dsOrViewModelTable.dsTableName,
                                                            },
                                                            nameTooltipEnabled: true,
                                                        })}

                                                        {column.id === value?.id && <img src={require("../icons/check-icon2.svg")} className="check-icon" alt="" />}
                                                    </div>
                                                ))}
                                        </VerbScrollbar>
                                    </div>
                                );
                            };

                            return (
                                <div className="ds-model-menu" style={{width: 360}}>
                                    {SearchInput({
                                        state: search,
                                        placeholder: "Search for fields...",
                                        delay: 300,
                                    })}

                                    <div className="menu">
                                        {(() => {
                                            if (search.value) {
                                                return rSearchedColumns();
                                            }

                                            if (!address.value) {
                                                return rDataSourceLevel();
                                            }

                                            if ((address.value.dsId && !address.value.dsTableId) || (address.value.type && !address.value.viewTableId)) {
                                                return rTableLevel();
                                            }

                                            if ((address.value.dsId && address.value.dsTableId) || (address.value.type && address.value.viewTableId)) {
                                                return rColumnLevel();
                                            }
                                        })()}
                                    </div>
                                </div>
                            );
                        }
                    ),
            });
        });

const getDataSourceStructureOfModel = (model, dataSources) => {
    const dsModel = model.dataSourceIDs.map((dsId) => {
        const ds = dataSources.find((ds) => ds.id === dsId);
        return {
            dsId,
            dsName: ds.name,
            tables: model.tables
                .filter((table) => table.dataSourceID === dsId)
                .map((table) => ({
                    ...table,
                    // for navigating purpose in this dropdown
                    dsId,
                    dsTableId: table.dataSourceTableID,
                    dsName: ds.name,
                    dsTableName: ds.tables.find((t) => t.id === table.dataSourceTableID)?.name,
                })),
        };
    });

    const viewModelTables = model.tables.filter((table) => table.$type === "ViewModelTable");

    return [...dsModel, {type: "ViewModelTable", tables: viewModelTables}];
};

export const isPublishedView = (modelTable) => {
    if (modelTable.$type !== "ViewModelTable") {
        return true;
    }
    return modelTable.versionDetails !== "NotPublished";
};

export const isPublishedTransformedColumn = (modelColumn) => {
    if (!["CalculatedModelColumn", "AggregatedMeasureModelColumn"].includes(modelColumn.$type)) {
        return true;
    }
    return (
        !(modelColumn.$type === "CalculatedModelColumn" && modelColumn.calculations?.filter((cal) => cal.versionDetails !== "NotPublished").length === 0) &&
        !(modelColumn.$type === "AggregatedMeasureModelColumn" && modelColumn.versionDetails === "NotPublished")
    );
};
