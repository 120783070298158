import {createFetcher} from "../../../../../tools/fetcher/fetcher";

export const createReportApis = () => {
    const fetcher = createFetcher({
        // urlModifier: (url) => `http://localhost:3002${url}`,
        urlModifier: (url) => `https://notion.abexpress.xyz${url}`,
    });

    return {
        getReportSections: () => fetcher.get("/api/report-sections"),
        // generateReport: (report) => fetcher.post("/api/report", report),
        saveReport: (report) => fetcher.put("/api/report", report),
        getReports: () => fetcher.get("/api/reports"),
    };
};
