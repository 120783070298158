import "./collections-dashboard.scss";

import {cx} from "emotion";
import React from "react";

import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {Load2} from "@common/react/load2";
import {cSsJson} from "@common/react/ss-json";
import {UseState} from "@common/react/use-state";
import {CollectionDashboardIcon, CollectionSparkIcon, DocsIcon, NewFolderIcon, PlusIcon} from "@common/ui-components/icons/global-icons";
import {LoadingIndicator} from "@common/ui-components/loading-indicator/loading-indicator";
import {SearchInputBar} from "@common/ui-components/search-input-bar/search-input-bar";
import {sum} from "@common/utils/collections";
import {omit} from "@common/utils/objects";
import {noun} from "@common/utils/plural";
import {isBlank} from "@common/utils/strings";
import {ObserveProgressService} from "../../../../routes/collection/edit/layout/publish-button/observe-progress-service";
import {RecursiveTable} from "../../../common/recursive-table/recursive-table";
import {ToastWithAction} from "../../../common/toast/toast-with-action";
import {ButtonDropdown} from "../../../data-source/common/button-dropdown/button-dropdown";
import {MultiSelect} from "../../common/multi-select";
import {MoveToDialog} from "../common/move-to-dialog/move-to-dialog";
import {recentStorage} from "../recent-section/recent-storage";
import {CollectionCM} from "./collection-cm/collection-cm";

import {CreateCollectionDialog} from "./collection-cm/create-collection/create-collection-dialog";
import {DeleteCollectionDialog} from "./collection-cm/delete-collection/delete-collection-dialog";
import {isParentApiCollection} from "./collection-dashboard-helpers";
import {CollectionErrors} from "./collection-errors/collection-errors";
import {CreateCollectionService} from "./create-collection-service";
import {CreateFolderDialog} from "./folder-cm/create-folder/create-folder-dialog";
import {FolderCM} from "./folder-cm/folder-cm";
import {buildFoldersTree, groupCollections, resolveItemKey} from "./folders-tree-structure";
import {CollectionPreviewProfileDropdown} from "./preview-profile-dropdown/collection-preview-profile-dropdown";
import {CollectionTilesDropdown} from "./tile-dropdown/collection-tiles-dropdown";

import {Invoke} from "@common/react/invoke";
import {createDateFormatter} from "@common/ui-components/charts/common/formatters/date-formatter";

export const loadCollectionsDashboardActions = ({next, environment, folders, updateEvents}) =>
    cs(
        consumeContext("apis"),
        consumeContext("routing"),
        consumeContext("toast"),
        [
            "onUpdateFolders",
            (_, next) =>
                next((newFolders) => {
                    const res = folders.onChange(newFolders);
                    updateEvents.emit("reload");
                    return res;
                }),
        ],
        [
            "iframeKeys",
            ({apis, routing}, next) =>
                Load2({
                    _key: routing.params?.envId,
                    fetch: () => apis.iframeKey.getIframeKeys(),
                    next,
                }),
        ],
        [
            "profileFolders",
            ({apis, routing}, next) =>
                Load2({
                    _key: routing.params?.envId,
                    fetch: () => apis.previewProfile.getFolders(),
                    next,
                }),
        ],
        ["collectionToast", (_, next) => ToastWithAction({next})],
        [
            "moveCollectionDialog",
            ({onUpdateFolders}, next) =>
                MoveToDialog({
                    apiType: "collection",
                    folders: folders.value,
                    onDone: onUpdateFolders,
                    next,
                }),
        ],
        [
            "deleteCollectionDialog",
            ({onUpdateFolders, toast}, next) =>
                DeleteCollectionDialog({
                    onDone: (newFolders, collectionName) => {
                        onUpdateFolders(newFolders);
                    },
                    reloadFolderOnDone: true,
                    next,
                }),
        ],
        [
            "collectionCM",
            ({collectionToast, profileFolders, moveCollectionDialog, deleteCollectionDialog, onUpdateFolders, iframeKeys}, next) =>
                CollectionCM({
                    folders,
                    onUpdateFolders,
                    moveCollectionDialog,
                    deleteCollectionDialog,
                    next,
                    updateEvents,
                    collectionToast,
                    environment,
                    profileFolders,
                    iframeKeys,
                }),
        ],
        ({iframeKeys, profileFolders, multiSelectCtrl, collectionCM, collectionToast, moveCollectionDialog, deleteCollectionDialog}) =>
            next({iframeKeys, profileFolders, multiSelectCtrl, collectionCM, collectionToast, moveCollectionDialog, deleteCollectionDialog})
    );
export const CollectionsDashboard = ({next, updateEvents, environment, folders}) =>
    cs(
        consumeContext("apis"),
        consumeContext("routing"),
        ["folderCM", (_, next) => FolderCM({apiType: "collection", folders, next, updateEvents})],
        ["keyword", (_, next) => UseState({initValue: cSsJson("SEARCH_VALUE").get()?.collection || "", next})],
        ["collectionsDashboardActions", (_, next) => loadCollectionsDashboardActions({next, updateEvents, environment, folders})],
        [
            "multiSelectCtrl",
            ({collectionsDashboardActions: {moveCollectionDialog, deleteCollectionDialog}}, next) =>
                MultiSelect({
                    next,
                    itemName: "Collection",
                    countItem: (selectedItems) => {
                        let count = 0;
                        let ignoreKey = new Set();
                        let visitedAPICollections = [];

                        for (let key in selectedItems) {
                            const item = selectedItems[key];

                            if (ignoreKey.has(key)) {
                                continue;
                            }

                            if (item.type != "ApiCollection") {
                                count++;
                                continue;
                            }

                            if (visitedAPICollections.includes(item.name)) {
                                continue;
                            }

                            count++;
                            visitedAPICollections.push(item.name);
                            // const {isChild, parent} = item;
                            //
                            // if (isChild) {
                            //     const parentKey = resolveItemKey(parent);
                            //     ignoreKey.add(parentKey);
                            //
                            //     parent.childs.forEach((child) => ignoreKey.add(resolveItemKey(child)));
                            // } else {
                            //     const parentKey = resolveItemKey(item);
                            //     ignoreKey.add(parentKey);
                            //     item.childs.forEach((child) => ignoreKey.add(resolveItemKey(child)));
                            // }
                        }

                        return count;
                    },
                    resolveItemKey,
                    actions: [
                        {
                            label: "Move To",
                            isShow: (selectedItems, resolveItemKey) => {
                                // find API Collection with version and it's parent is not selected.
                                for (let key in selectedItems) {
                                    const item = selectedItems[key];

                                    if (item.type != "ApiCollection") {
                                        continue;
                                    }

                                    const {isChild, parent} = item;

                                    const isSelectedParent = parent ? selectedItems.hasOwnProperty(resolveItemKey(parent)) : false;
                                    if (isChild && !isSelectedParent) {
                                        return false;
                                    }
                                }

                                return true;
                            },
                            onClick: async (selectedItems) => {
                                const updateItems = Object.values(selectedItems).filter((item) => item.type != "ApiCollection" || item.isChild);
                                return await moveCollectionDialog.show({
                                    isUpdateMany: true,
                                    label: `${updateItems.length} ${noun(updateItems.length, "Collection")}`,
                                    params: updateItems,
                                });
                            },
                        },
                        {
                            label: "Delete",
                            isShow: () => true,
                            onClick: async (selectedItems) => {
                                const updateItems = Object.values(selectedItems).filter((item) => item.type != "ApiCollection" || item.isChild);
                                return await deleteCollectionDialog.show({
                                    collections: updateItems,
                                });
                            },
                        },
                    ],
                }),
        ],
        ({apis, keyword, folderCM, multiSelectCtrl, collectionsDashboardActions: {iframeKeys, profileFolders, collectionCM, collectionToast}}) => {
            const hasSelectedItems = multiSelectCtrl.numSelectedItems > 0;
            const tableHeight = `calc(100vh - ${78 + 54 + 40 + (hasSelectedItems ? 52 : 0)}px)`;
            return next({
                number: folders.value && sum(folders.value, (fo) => fo.collections?.length ?? 0),
                actions: cs(
                    [
                        "createCollectionDialog",
                        (_, next) =>
                            CreateCollectionDialog({
                                environment,
                                folders: folders.value,
                                next,
                            }),
                    ],
                    [
                        "createCollectionService",
                        (_, next) =>
                            CreateCollectionService({
                                currentLength: sum(folders.value, (fo) => fo.collections?.length ?? 0),
                                next,
                            }),
                    ],
                    [
                        "createFolderDialog",
                        (_, next) =>
                            CreateFolderDialog({
                                apiType: "collection",
                                folders: folders.value,
                                onCreate: (newFolder) => folders.onChange([...(folders.value || []), newFolder]),
                                next,
                            }),
                    ],
                    ({createCollectionDialog, createFolderDialog, createCollectionService}) => (
                        <div className="collections-dashboard-actions-98y">
                            {SearchInputBar({state: keyword, type: "collection"})}

                            {ButtonDropdown({
                                label: "New",
                                list: [
                                    {
                                        value: 0,
                                        label: (
                                            <>
                                                <PlusIcon fill="#919EB0" /> Add New Collection
                                            </>
                                        ),
                                        show: !environment?.readOnly,
                                    },
                                    {
                                        value: 1,
                                        label: (
                                            <>
                                                <NewFolderIcon fill="#919EB0" /> Create New Folder
                                            </>
                                        ),
                                    },
                                ].filter((i) => i.show ?? true),
                                valueToLabel: (item) => item.label,
                                onChange: (item) => {
                                    const maps = {
                                        0: async () =>
                                            createCollectionService.createNew({
                                                onDone: () => {
                                                    createCollectionDialog.show();
                                                },
                                            }),
                                        1: createFolderDialog.show,
                                    };

                                    return maps[item.value]();
                                },
                            })}
                        </div>
                    )
                ),
                content: cs(consumeContext("routing"), ({routing}) => {
                    return (
                        <div className="collections-dashboard-content-98y">
                            {hasSelectedItems && multiSelectCtrl.render()}
                            {!folders.value
                                ? LoadingIndicator()
                                : RecursiveTable({
                                      label: "Collections",
                                      showNoResult: keyword.value?.length > 0,
                                      onClearSearch: () => keyword.onChange(""),
                                      maxHeight: tableHeight,
                                      style: {height: tableHeight, background: "white"},
                                      structure: buildFoldersTree({
                                          itemsProp: "collections",
                                          folders: folders.value,
                                          keyword: keyword.value,
                                          groupFn: groupCollections,
                                          filterFn: (col, keyword) => col.id.indexOf(keyword) > -1,
                                      }),
                                      isGroupNode: (n) => n.collections,
                                      isAlwaysExpanded: (n) => n.type === "api" || !isBlank(keyword.value),
                                      getTrClass: (n) => {
                                          let classNames = !n.type ? "node-group" : n?.childs?.length > 1 ? "multi-api-collection" : "";

                                          if (n.type && multiSelectCtrl.isSelected(n)) {
                                              classNames += " is-selected-tr";
                                          }

                                          return classNames;
                                      },
                                      getChildTrClass: (n) => {
                                          return n.type && multiSelectCtrl.isSelected(n) ? " is-selected-tr" : "";
                                      },
                                      onClickRow: (item, event, path) => {
                                          if (!item.type) {
                                              return;
                                          }

                                          event.preventDefault();
                                          event.stopPropagation();

                                          if (item.type != "ApiCollection") {
                                              multiSelectCtrl.toggleSelect(item);
                                          } else {
                                              const isParent = !item.isChild;
                                              const isAdded = multiSelectCtrl.isSelected(item);
                                              let params = {
                                                  addItems: [],
                                                  removeItems: [],
                                              };

                                              if (isParent) {
                                                  if (!isAdded) {
                                                      params.addItems = [item].concat(item.childs);
                                                  } else {
                                                      params.removeItems = [item].concat(item.childs);
                                                  }

                                                  return multiSelectCtrl.bulkUpdate(params);
                                              }
                                              let parent = item.parent;
                                              const parentKey = resolveItemKey(parent);

                                              const {selectedItems} = multiSelectCtrl;

                                              const numAddedChildren = Object.values(selectedItems).filter((i) => i.isChild && resolveItemKey(i.parent) == parentKey).length;
                                              const isAddedParent = selectedItems.hasOwnProperty(parentKey);

                                              if (!isAdded) {
                                                  params.addItems = [item].concat(numAddedChildren + 1 == parent.numChildren ? [parent] : []);
                                              } else {
                                                  params.removeItems = [item].concat(isAddedParent ? [parent] : []);
                                              }

                                              return multiSelectCtrl.bulkUpdate(params);
                                          }
                                      },
                                      columns: [
                                          {
                                              label: "Name",
                                              indented: true,
                                              haveDropdown: (c) => {
                                                  if (c.type && ((c.type === "DashboardCollection" && c.tiles) || !isParentApiCollection(c))) {
                                                      return {
                                                          render: (domRef) =>
                                                              CollectionPreviewProfileDropdown({
                                                                  domRef,
                                                                  iframeKeys,
                                                                  profileFolders,
                                                                  collection: c,
                                                              }),
                                                      };
                                                  }

                                                  return null;
                                              },
                                              format: (c, {expanding, path}) => {
                                                  const addRecent = () => {
                                                      recentStorage.add({
                                                          envId: routing.params?.envId,
                                                          type: "collection",
                                                          item: omit(c, ["childs", "parent"]),
                                                          path,
                                                      });
                                                  };
                                                  if (!c.type) {
                                                      return (
                                                          <div className={cx("icon-name-34n", "folder", {expanding})}>
                                                              <img src={expanding ? require("./icons/folder-open-icon.svg") : require("./icons/folder-icon.svg")} alt={""} />
                                                              {c.name}
                                                              {/*{c.error && <img className="error" src={require("./icons/exclamation-icon.svg")} alt=""/>}*/}
                                                          </div>
                                                      );
                                                  } else if (c.type === "DashboardCollection") {
                                                      return (
                                                          <div className={cx("icon-name-34n", "dashboard", {expanding})}>
                                                              {CollectionDashboardIcon({className: "icon"})}
                                                              {/* <img className="icon" src={require("./icons/report-icon.svg")} alt=""/> */}
                                                              <div
                                                                  className="name"
                                                                  onClick={(e) => {
                                                                      e.preventDefault();
                                                                      e.stopPropagation();
                                                                      addRecent();
                                                                      routing.goto("edit-collection", {colId: c.id});
                                                                  }}
                                                              >
                                                                  {c.name}
                                                              </div>
                                                          </div>
                                                      );
                                                  } else if (c.type == "ApiCollection") {
                                                      if (isParentApiCollection(c)) {
                                                          return (
                                                              <div className={cx("icon-name-34n", "dashboard", {expanding})}>
                                                                  <img className="icon" src={require("./icons/api-icon.svg")} alt="" />
                                                                  {c.name}
                                                              </div>
                                                          );
                                                      }

                                                      return (
                                                          <div className={cx("icon-name-34n", "dashboard", {expanding})}>
                                                              {!c.isChild && <img className="icon" src={require("./icons/api-icon.svg")} alt="" />}
                                                              <div
                                                                  className="name"
                                                                  onClick={(e) => {
                                                                      e.preventDefault();
                                                                      e.stopPropagation();
                                                                      addRecent();
                                                                      routing.goto("edit-collection", {
                                                                          colId: c.id,
                                                                          collectionTab: "fields",
                                                                      });
                                                                  }}
                                                              >
                                                                  {!c.isChild && c.name} {!c.isChild ? "|" : ""}&nbsp;
                                                                  <span style={{paddingLeft: c.isChild ? "30px" : ""}}>Version {c.version}</span>
                                                              </div>
                                                          </div>
                                                      );
                                                  } else if (c.type == "SparkCollection") {
                                                      return (
                                                          <div className={cx("icon-name-34n", "dashboard", {expanding})}>
                                                              {CollectionSparkIcon({className: "icon"})}
                                                              {c.name}
                                                              <div
                                                                  className="name"
                                                                  onClick={(e) => {
                                                                      e.preventDefault();
                                                                      e.stopPropagation();
                                                                      routing.goto("edit-collection", {colId: c.id});
                                                                  }}
                                                              >
                                                                  {c.name}
                                                              </div>
                                                          </div>
                                                      );
                                                  }
                                              },
                                          },
                                          {
                                              label: "",
                                              haveDropdown: (c) => {
                                                  if (c.type && c.tiles && c.type == "DashboardCollection") {
                                                      return {
                                                          render: (domRef) =>
                                                              CollectionTilesDropdown({
                                                                  collection: c,
                                                                  domRef,
                                                              }),
                                                          fullColumn: true,
                                                      };
                                                  }

                                                  return null;
                                              },
                                              format: (c) => {
                                                  if (c.type && c.tiles && c.type == "DashboardCollection") {
                                                      return (
                                                          <div style={{minWidth: 220}}>
                                                              {c.tiles.length} {noun(c.tiles.length, "Tile")}
                                                          </div>
                                                      );
                                                  }

                                                  return null;
                                              },
                                          },
                                          {
                                              label: "Last modified by",
                                              format: (c) => {
                                                  if (isParentApiCollection(c)) {
                                                      return "";
                                                  }

                                                  return c.versionInfo?.editedBy;
                                              },
                                          },
                                          {
                                              label: "Last modified on",
                                              format: (c) => {
                                                  if (!c.type) return "";

                                                  if (isParentApiCollection(c)) {
                                                      return "";
                                                  }

                                                  const editedOn = new Date(c.versionInfo?.editedOnUtc);

                                                  return <div className="last-modified-on-23c">{createDateFormatter("MMM d, yyyy @ h:mm tt").format(editedOn)}</div>;
                                              },
                                          },
                                          {
                                              label: "Published on",
                                              format: (c) => {
                                                  if (!c.type) return "";

                                                  if (isParentApiCollection(c)) {
                                                      return "";
                                                  }

                                                  const publishedOn = new Date(c.publishedOnUtc || new Date().toUTCString());

                                                  return (
                                                      <div className={cx("publishing-date-23x", {pending: c.publishedOnUtc === null})}>
                                                          <img
                                                              src={
                                                                  c.publishedOnUtc
                                                                      ? require("../../../common/icons/published-icon.svg")
                                                                      : require("../../../common/icons/not-published-icon.svg")
                                                              }
                                                              alt=""
                                                          />
                                                          {c.publishedOnUtc ? createDateFormatter("MMM d, yyyy @ h:mm tt").format(publishedOn) : "Not Published"}
                                                      </div>
                                                  );
                                              },
                                          },
                                          {
                                              format: (c) => {
                                                  if (c.tileModelErrors?.length > 0) {
                                                      return <CollectionErrors collectionID={c.id} errors={c.tileModelErrors} />;
                                                  }
                                              },
                                              className: "collection-tile-errors",
                                              shy: true,
                                          },
                                          {
                                              format: (c) => {
                                                  return cs(
                                                      ["confirming", (_, next) => UseState({next})],
                                                      ["remotePublishStatus", (_, next) => UseState({next})],
                                                      [
                                                          "publishProcessObserver",
                                                          ({confirming, remotePublishStatus}, next) => {
                                                              return ObserveProgressService({
                                                                  debug: c.id === "410add45-4202-4c5e-8ce9-bb8a1ea898b0",
                                                                  fetch: () =>
                                                                      apis.collection.getPublishInfo({
                                                                          collectionId: c?.id,
                                                                      }),
                                                                  calculateProgress: (info) => info.complete / info.total,
                                                                  checkDone: (info) => info?.status === "Success",
                                                                  onDone: (info, {processType}) => {
                                                                      if (processType === "new-process") {
                                                                          confirming.onChange(true);
                                                                          setTimeout(() => confirming.onChange(false), 500);
                                                                          remotePublishStatus.onChange(info);
                                                                          collectionToast.show({
                                                                              text: `${c?.name} is published!`,
                                                                          });
                                                                      }

                                                                      if (processType === "remote-process") {
                                                                          remotePublishStatus.onChange(info);
                                                                      }
                                                                  },
                                                                  checkError: (info) => info?.status === "Error",
                                                                  onError: (info, {noToast}) => {
                                                                      !noToast &&
                                                                          collectionToast.show({
                                                                              text: `Error publishing ${c?.name}`,
                                                                              isError: true,
                                                                          });
                                                                      remotePublishStatus.onChange(info);
                                                                  },
                                                                  onApiError: () => {},
                                                                  next,
                                                              });
                                                          },
                                                      ],

                                                      ({publishProcessObserver, remotePublishStatus, confirming}) => {
                                                          return (
                                                              <div className="cm">
                                                                  {}
                                                                  {!c.type
                                                                      ? folderCM.render({params: c})
                                                                      : collectionCM.render({
                                                                            onMenuOpen: () => {
                                                                                if (!remotePublishStatus.value && !publishProcessObserver.loading) {
                                                                                    publishProcessObserver.start({
                                                                                        args: {
                                                                                            processType: "remote-process",
                                                                                            noToast: true,
                                                                                        },
                                                                                    });
                                                                                }
                                                                            },
                                                                            params: {
                                                                                collection: c,
                                                                                publishCollection: async () => {
                                                                                    const latestConfirming = confirming.get();
                                                                                    const latestRemotePublishStatus = remotePublishStatus.get();
                                                                                    const latestPublishProcessObserver = publishProcessObserver.getLatest();

                                                                                    if (!latestRemotePublishStatus) {
                                                                                        collectionToast.show({
                                                                                            text: "Checking Collection Publish Status in Progress",
                                                                                            isError: true,
                                                                                        });
                                                                                    } else if (latestPublishProcessObserver.loading || latestConfirming) {
                                                                                        collectionToast.show({
                                                                                            text: "Collection Publish Already in Progress",
                                                                                            isError: true,
                                                                                        });
                                                                                    } else {
                                                                                        const result = await apis.collection.publishCollection({
                                                                                            collectionId: c.id,
                                                                                        });
                                                                                        latestPublishProcessObserver.start({
                                                                                            args: {processType: "new-process"},
                                                                                            delayFetching: !result,
                                                                                        });
                                                                                        collectionToast.show({
                                                                                            text: "Collection Publish Started",
                                                                                        });
                                                                                    }
                                                                                },
                                                                            },
                                                                        })}
                                                              </div>
                                                          );
                                                      }
                                                  );
                                              },
                                              className: "collection-commands-31m",
                                              shy: true,
                                          },
                                      ],
                                  })}
                            {collectionToast.render()}
                        </div>
                    );
                }),
            });
        }
    );
