import {arrMapToO} from "../../../../../../../../common/utils/objects";

export const vanilla = {
    sample: ({collection, filterVals}) => {
        const filters = collection.filterStyle.display == "SDK" ? [filterVals[0]] : filterVals.filter((f) => f.displayArea === "Hidden");
        const content = `
    window.verbAsyncUpdate = function() {
      const dashboardCollection = Verb.getDashboardCollection("verb-${collection.id}");
      ${filters.map(
          (filter, index) => `
      // Set ${filter.$type} named "${filter.label}"
      dashboardCollection.setFilterVal("${filter.id}", ${JSON.stringify(filter.value, null, 8)});
      `
      )}
    }
    `;
        return `<script>
  ${content}
</script>
\n\n
<div
  id="verb-${collection.id}"
  x-verb-dashboard="true"
  x-collection-id="${collection.id}"
></div>`;
    },
    dashboard: ({collection, filterVals}) =>
        `<div\n` + `  x-verb-dashboard="true"\n` + `  x-collection-id="${collection?.id}"\n` + `></div>`,
    filters: ({collection, filterVals}) =>
        collection.filterStyle.display == "Detached"
            ? `<div 
  x-verb-filters="true"
  x-collection-id="${collection?.id}" 
></div>
`
            : null,
    script: ({apiKey, staticAuth, authEndpoint}) =>
        `<script>\n` +
        `window.verbAsyncInit = function() {\n` +
        `  Verb.init({\n` +
        `    apiKey           : "${apiKey}",\n` +
        `    version          : "v1.0",\n` +
        (staticAuth
            ? `      staticAuth       : true \n`
            : `      authParams       : \n` +
              `      ${JSON.stringify(
                  arrMapToO(authEndpoint?.parameters || [], () => "REPLACE_WITH_VALUE"),
                  null,
                  2
              ).replace(/\n/g, "\n      ")}, \n`) +
        `    });\n` +
        `  };\n` +
        `</script>\n` +
        `<script \n` +
        `  async defer\n` +
        `  src="${window.location.origin}/sdk/verb-sdk.js"\n` +
        `></script>\n` +
        ``,
};

export const react = {
    sample: ({collection, filterVals}) => {
        const filters = collection.filterStyle.display == "SDK" ? [filterVals[0]] : filterVals.filter((f) => f.displayArea === "Hidden");
        const contentRef = `
    ${filters.map(
        (filter, index) => `    // Set ${filter.$type} named "${filter.label}"
    dashboardRef.setFilterVal("${filter.id}", ${JSON.stringify(filter.value, null, 6)});
    `
    )}
    `;
        const content = `    const dashboardCollection = window.Verb.getDashboardByCollectionId("${collection.id}");
    ${filters.map(
        (filter, index) => `
    // Set ${filter.$type} named "${filter.label}"
    dashboardCollection.setFilterVal("${filter.id}", ${JSON.stringify(filter.value, null, 6)});
    `
    )}
    `;
        return `import React from "react";
import ReactDOM from "react-dom"
\n\n
function VerbApp(){
  let dashboardRef;
  \n\n
  const setFilterValueThroughRef = () => {
    ${contentRef}
  }
  \n\n
  return (
    <div>
      \n\n
      <button onClick={() => {
        ${content}      
      }}
      >    
        Set filter value      
      </button>
      \n\n  
      <button onClick={setFilterValueThroughRef}>
        Set Filter Value Through Ref
      </button>
      \n\n
      {
        window.VerbDashboard({
          collectionId: "${collection.id}",
          React: React,
          ReactDOM: ReactDOM,
          ref: (dashboard) => dashboardRef = dashboard,
        })
      }
    </div>
  )
}`;
    },
    dashboard: ({collection}) =>
        `import React from "react";
import ReactDOM from "react-dom"
\n\n
const VerbApp = () => (
  <div>
    {
      window.VerbDashboard({
        collectionId: "${collection?.id}",
        React: React,
        ReactDOM: ReactDOM
      })
    }
  </div>
)`,
    filters: ({collection, filterVals}) =>
        collection.filterStyle.display == "Detached"
            ? `import React from "react";
import ReactDOM from "react-dom"
\n\n
const VerbFilters = () => (
  <div>
    {
      window.VerbFilters({
        collectionId: "${collection.id}",
        React: React,
        ReactDOM: ReactDOM
      }))
    }
  </div>
)
`
            : null,
    script: ({apiKey, staticAuth, authEndpoint}) =>
        `<script>\n` +
        `  window.VerbParams = {\n` +
        `    apiKey           : "${apiKey}",\n` +
        `    version          : "v1.0",\n` +
        (staticAuth
            ? `    staticAuth       : true \n`
            : `    authParams       : \n` +
              `      ${JSON.stringify(
                  arrMapToO(authEndpoint?.parameters || [], () => "REPLACE_WITH_VALUE"),
                  null,
                  2
              ).replace(/\n/g, "\n      ")}, \n`) +
        `  };\n` +
        `</script>\n` +
        `<script \n` +
        `  async defer\n` +
        `  src="${window.location.origin}/sdk/verb-sdk-react.js"\n` +
        `></script>\n` +
        ``,
};

export const angular = {
    sample: ({collection, filterVals}) => {
        const filters = collection.filterStyle.display == "SDK" ? [filterVals[0]] : filterVals.filter((f) => f.displayArea === "Hidden");
        const contentRef = `
    ${filters.map(
        (filter, index) => `
    // Set ${filter.$type} named "${filter.label}"
    this.dashboard.dashboardRef.setFilterVal("${filter.id}", ${JSON.stringify(filter.value, null, 6)});
    `
    )}
    `;
        const content = `
    const dashboardCollection = window.Verb.getDashboardByCollectionId("${collection.id}");
    ${filters.map(
        (filter, index) => `
    // Set ${filter.$type} named "${filter.label}"
    dashboardCollection.setFilterVal("${filter.id}", ${JSON.stringify(filter.value, null, 6)});
    `
    )}
    `;

        return `import { Component, ElementRef, ViewChild } from '@angular/core';
@Component({
  selector: 'verb-app',
  template: \`<div>
    <button
      (click)="setFilterVal()"
    >
      Set filter value
    </button>
    \n\n
    <button
      (click)="setFilterValThroughRef()"
    >
      Set filter value through ref
    </button>
    \n\n
    <verb-dashboard
      #dashboard
      [params]='{
        collectionId: "${collection.id}"
      }'
    ></verb-dashboard>
  </div>\`
})
\n\n
export class VerbApp {
  @ViewChild('dashboard') dashboard: ElementRef;
  \n\n
  setFilterValThroughRef() {
    ${contentRef}
  }
  \n\n
  setFilterVal() {
    ${content}
  }
}`;
    },
    dashboard: ({includeFilter, collection, hiddenFilterIds}) =>
        `<verb-dashboard
  [params]='{
    collectionId: "${collection?.id}",
  }'
></verb-dashboard>`,
    filters: ({collection, filterVals}) =>
        collection.filterStyle.display == "Detached"
            ? `
<verb-filters
[params]='{
  collectionId: "${collection.id}"
}'
></verb-filters>
`
            : `import { Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'verb-app',
  template: \`<div>
    <button
      (click)="setFilterVal()"
    >
      Set filter value
    </button>
    \n\n
    <button
      (click)="setFilterValThroughRef()"
    >
      Set filter value through ref
    </button>
    \n\n
    <verb-dashboard
      #dashboard
      [params]='{
        collectionId: "${collection.id}"
      }'
    ></verb-dashboard>
  </div>\`
})

export class VerbApp {
  @ViewChild('dashboard') dashboard: ElementRef;
  \n\n
  setFilterValThroughRef() {
      // Set numeric filter named "{filter name here}"
      this.dashboard.dashboardRef.setFilterVal(
          "${filterVals[0].id}",
          ${JSON.stringify(filterVals[0].value, null, 6)}
      );
  }
  \n\n
  setFilterVal() {
      window.Verb.getDashboardByCollectionId(
          "${collection.id}"
      ).setFilterVal(
          "${filterVals[0].id}",
          ${JSON.stringify(filterVals[0].value, null, 6)}
      );
  }
}`,
    script: ({apiKey, staticAuth, authEndpoint}) =>
        `<script>\n` +
        `  window.VerbParams = {\n` +
        `    Verb.init({\n` +
        `    apiKey           : "${apiKey}",\n` +
        `    version          : "v1.0",\n` +
        (staticAuth
            ? `    staticAuth       : true \n`
            : `    authParams       : \n` +
              `      ${JSON.stringify(
                  arrMapToO(authEndpoint?.parameters || [], () => "REPLACE_WITH_VALUE"),
                  null,
                  2
              ).replace(/\n/g, "\n      ")}, \n`) +
        `  };\n` +
        `</script>\n` +
        `<script \n` +
        `  async defer\n` +
        `  src="${window.location.origin}/sdk/verb-sdk-angular.js"\n` +
        `></script>\n` +
        ``,
    components: {
        dashboard: ({filterVals}) =>
            `import {Component, Input, ViewChild} from '@angular/core';
      \n\n
@Component({
  selector: 'verb-dashboard',
  template: \`<div #div>Loading Verb Dashboard...</div>\`,
})
\n\n
export class VerbDashboard {
  @ViewChild("div") el = null;
  @Input() params! : any;

  dashboardRef: any;

  ngAfterViewInit() {
    window["VerbDashboard"]({
      dom: this.el?.["nativeElement"],
      ...this.params,
      ref: (ref: any) => this.dashboardRef = ref,
    });
  }

  ngOnDestroy() {
    this.dashboardRef?.destroy();
  }
}`,
        filter: ({filterVals}) =>
            `import {Component, Input, ViewChild, ElementRef} from '@angular/core';
\n\n
@Component({
  selector: "verb-filters",
  template: \`<div #filter x-verb-filters="true" [attr.x-collection-id]="null"></div>\`,
})
\n\n

export class VerbFilters {
  @ViewChild("filter") el! :ElementRef ;
  @Input() params! : any;
  \n\n
  ngAfterViewInit() {
    const dom = this.el?.["nativeElement"];
    const collectionId = this.params.collectionId;

    dom?.setAttribute('x-collection-id', collectionId);

    window.Verb.getDashboardByCollectionId(collectionId)?.addFilterDom?.(dom);
  }
  \n\n
  ngOnDestroy() {
    const dom = this.el?.["nativeElement"];
    const collectionId = this.params.collectionId;

    window.Verb.getDashboardByCollectionId(collectionId)?.removeFilterDom?.(dom);
  }
}`,
    },
};

export const vue = {
    sample: ({collection, filterVals}) => {
        const filters = collection.filterStyle.display == "SDK" ? [filterVals[0]] : filterVals.filter((f) => f.displayArea === "Hidden");
        const contentRef = `
    ${filters.map(
        (filter, index) => `
    // Set ${filter.$type} named "${filter.label}"
    this.$refs.dashboard.dashboardRef.setFilterVal("${filter.id}", ${JSON.stringify(filter.value, null, 4)});
    `
    )}
    `;
        const content = `
    const dashboardCollection = window.Verb.getDashboardByCollectionId("${collection.id}");
    ${filters.map(
        (filter, index) => `
    // Set ${filter.$type} named "${filter.label}"
    dashboardCollection.setFilterVal("${filter.id}", ${JSON.stringify(filter.value, null, 4)});
    `
    )}
    `;

        return `<script>
new Vue({
  el: '#root',
  data: {
    setFilterVal: () => {
      ${content}
    },

    methods: {
      setFilterValThroughRef() {
        ${contentRef}
      }
    }
  },
  template: \`<div>
    <button v-on:click="setFilterVal">Set Filter Value</button>
    <button v-on:click="setFilterValThroughRef">Set Filter Value through ef</button>
    <verb-dashboard
      ref="dashboard"
      v-bind:params='{
        collectionId: "${collection.id}",
      }'
    />
  </div>\`
});
</script>`;
    },
    dashboard: ({collection}) =>
        `<script>
  new Vue({
    el: '#root',
    template: \`
      <verb-dashboard
        v-bind:params='{
          collectionId: "${collection.id}",
        }'
      />
    \`
  });
</script>`,
    filters: ({collection, filterVals}) =>
        collection.filterStyle.display == "Detached"
            ? `<script>
  new Vue({
    el: '#root',
    template: \`
      <verb-filters
        v-bind:params='{
          collectionId: "${collection.id}",
        }'
      />
    \`
  });
</script>`
            : null,
    script: ({apiKey, staticAuth, authEndpoint}) =>
        `<script>\n` +
        `  window.VerbParams = {\n` +
        `    apiKey           : "${apiKey}",\n` +
        `    version          : "v1.0",\n` +
        (staticAuth
            ? `    staticAuth       : true \n`
            : `    authParams       : \n` +
              `      ${JSON.stringify(
                  arrMapToO(authEndpoint?.parameters || [], () => "REPLACE_WITH_VALUE"),
                  null,
                  2
              ).replace(/\n/g, "\n      ")}, \n`) +
        `  };\n` +
        `</script>\n` +
        `<script \n` +
        `  async defer\n` +
        `  src="${window.location.origin}/sdk/verb-sdk-react.js"\n` +
        `></script>\n` +
        ``,
    components: {
        dashboard: ({}) =>
            `<script>
  Vue.component('verb-dashboard', {
    props: ['params'],
    template: '<div>Loading Verb Dashboard...</div>',
    mounted() {
      window["VerbDashboard"]({
        dom: this.$el,
        ...this.params,
        ref: (ref) => this.dashboardRef = ref,
      });
    },
    beforeDestroy() {
      this.dashboardRef?.destroy();
    },
  });
</script>`,
        filter: ({}) =>
            `<script>
  Vue.component('verb-filters', {
    props: ['params'],
    template: '<div x-verb-filters="true"></div>',
    mounted() {
      const dom = this.$el;
      const {collectionId} = this.params;

      dom.setAttribute('x-collection-id', collectionId);
            
      window.Verb.getDashboardByCollectionId(collectionId)?.addFilterDom?.(dom);
    },
        
    beforeDestroy() {

      const dom = this.$el;
      const {collectionId} = this.params;

      window.Verb.getDashboardByCollectionId(collectionId)?.removeFilterDom?.(dom);
    },
  });
</script>`,
    },
};

export const templateEmbedCodes = {
    vanilla,
    react,
    angular,
    vue,
};
