import {cs} from "../../../../../../react/chain-services";
import {consumeContext} from "../../../../../../react/context";
import "./control-filter-input.scss";
import {cx} from "emotion";
import {CloseIcon} from "../../../../../../../web-client/src/routes/common/icons/close-icon";

export const ControlFilterInput = ({label}) =>
    cs(consumeContext("theme"), consumeContext("tileActionControlFilter"), ({tileActionControlFilter, theme}) => {
        const componentTheme = theme.general.components;

        const inputStyle = {
            border: `${componentTheme.inputBorderWidth}px solid ${componentTheme.inputBorderColorRGB}`,
            background: `${componentTheme.inputBackgroundColorRGB}`,
            color: `${componentTheme.inputTextColorRGB}`,
            borderRadius: `${componentTheme.inputCornerRadius}px`,
        };

        return (
            <div className={cx("control-filter-input-a33")} style={inputStyle}>
                <div
                    className="select-chip"
                    style={{
                        background: theme.general.buttons.buttonToggleGroup.activeBackgroundColorRGB,
                        borderRadius: theme.general.buttons.buttonToggleGroup.cornerRadius,
                        color: theme.general.buttons.buttonToggleGroup.activeFontColorRGB,
                        fontFamily: `"${theme.general.canvas.fontFamily}", sans-serif`,
                    }}
                >
                    <span>{label}</span>: {tileActionControlFilter.value?.action?.value}{" "}
                    <CloseIcon
                        onClick={() => {
                            tileActionControlFilter.onChange({
                                tile: tileActionControlFilter.value?.hasPrevTileAction ? null : tileActionControlFilter.value.tile,
                                action: {
                                    ...tileActionControlFilter.value.action,
                                    value: null,
                                },
                            });
                        }}
                        fill={componentTheme.inputIconColorRGB}
                    />
                </div>
            </div>
        );
    });
