const {cGetFontSize} = require("../common/style-map/font-size");

const getDataLabelsOptions = ({tile, theme, formatters, chartElementTooltip}) => {
    return {
        ...getDataLabelsStyle({dataLabelsConfig: tile.style.dataLabels, theme}),

        inside: tile.style.dataLabels.position?.startsWith("Inside"),

        ...(tile.$type === "HorizontalBarChartTile" && horizontalBarDataLabelPositionMap(tile.style.displayType)[tile.style.dataLabels.position]),

        ...(tile.$type === "LineChartTile" &&
            {
                // align: "right",
            }),

        ...(tile.$type === "VerticalBarChartTile" && verticalBarDataLabelPositionMap(tile.style.displayType)[tile.style.dataLabels.position]),

        // events: {
        //     mouseover: function(e) {
        //         chartElementTooltip.show(e, this.dataLabel.textStr)
        //     },
        //     mouseout: function(e) {
        //         chartElementTooltip.close()
        //     },
        // },

        formatter: function () {
            // customStack and indexCustomStack is provided during grouping series
            const {customStack, indexInCustomStack} = this.series.userOptions;
            if (this.y === 0) {
                return;
            }
            return formatters.measurementFormatters[customStack][indexInCustomStack](this.y);
        },
    };
};
exports.getDataLabelsOptions = getDataLabelsOptions;

const getDataLabelsStyle = ({dataLabelsConfig, theme}) => {
    const getFontSize = cGetFontSize(theme.general.canvas.fontSize, theme);
    return {
        enabled: dataLabelsConfig.show,
        // crop: false,
        // overflow: "none",
        ...(dataLabelsConfig.allowOverlap
            ? {
                  allowOverlap: true,
              }
            : {
                  allowOverlap: false,
              }),

        style: {
            fontSize: getFontSize({
                group: theme.dataVisualization.fonts.dataLabelFontSize,
            }),
            color: theme.dataVisualization.fonts.fontColorRGB || theme.general.canvas.fontColorRGB,
            fontWeight: "normal",
            fontFamily: `"${theme.dataVisualization.fonts.fontFamily || theme.general.canvas.fontFamily}", sans-serif`,
            textShadow: false,
            textOutline: theme.general.tile.styles.tileBackgroundColorRGB || false,
        },
    };
};
exports.getDataLabelsStyle = getDataLabelsStyle;

const verticalBarDataLabelPositionMap = (barDisplayType) => {
    const isStacked = ["Stacked", "HundredPercent"].includes(barDisplayType);
    return {
        OutsideAbove: {
            align: "center",
        },
        OutsideRight: {
            // align: isStacked ? "right" : "left",

            // just place data labels as InsideMiddle then adjust to move right later
            inside: true,
            verticalAlign: "middle",
        },
        InsideMiddle: {
            verticalAlign: "middle",
        },
        InsideBottom: {
            verticalAlign: "bottom",
        },
        InsideTop: {
            verticalAlign: "top",
            // y: -10,
        },
        InsideRight: {
            align: isStacked ? "right" : "left",
        },
    };
};
exports.verticalBarDataLabelPositionMap = verticalBarDataLabelPositionMap;

const horizontalBarDataLabelPositionMap = (barDisplayType) => {
    const isStacked = ["Stacked", "HundredPercent"].includes(barDisplayType);
    return {
        OutsideAbove: {
            // verticalAlign: isStacked ? "top" : "bottom",

            // just place data labels as InsideMiddle then adjust to move above later
            inside: true,
            verticalAlign: "middle",
        },
        OutsideRight: {
            align: "left",
        },
        InsideMiddle: {
            verticalAlign: "middle",
        },
        InsideBottom: {
            verticalAlign: isStacked ? "bottom" : "top",
        },
        InsideTop: {
            verticalAlign: isStacked ? "top" : "bottom",
            // y: -10,
        },
        InsideRight: {
            align: "right",
        },
    };
};

const lineDataLabelPositionMap = () => {
    return {
        OutsideAbove: {},
        OutsideRight: {
            align: "left",
        },
        InsideMiddle: {},
        InsideBottom: {
            verticalAlign: "top",
        },
        InsideTop: {},
        InsideRight: {
            align: "left",
        },
    };
};
exports.lineDataLabelPositionMap = lineDataLabelPositionMap;
