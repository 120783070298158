import {cs} from "@common/react/chain-services";
import {Load2} from "@common/react/load2";
import {IgnoreUpdate} from "@common/react/ignore-update";
import {LoadingWrapper} from "./loading-wrapper/loading-wrapper";
import {consumeContext} from "@common/react/context";
import {fragments} from "@common/react/fragments";
import {Invoke} from "@common/react/invoke";
import {Static2} from "@common/react/static-2";
import {OnUnmounted} from "@common/react/on-unmounted";
import {UseState} from "@common/react/use-state";

export const loadTileData = ({
    next,
    tile,
    theme,
    chartOuterLayout,
    size,
    tileFilters,
    loadData,
    tileKey,
    page,
    tableOverrides,
    keepOutdatedValue = false,
    disabled,
    removeJSCursor,
}) =>
    cs(
        consumeContext("selectedTimezone"),
        consumeContext("autoRefreshProvider"),
        ["clearEvent", (_, next) => Static2({next})],
        ["increment", (_, next) => UseState({next, initValue: 0})],
        [
            "tileData",
            ({selectedTimezone, increment}, next) => {
                if (!loadData.load) {
                    return next({
                        value: null,
                        loading: true,
                        getLoadedState: () => null,
                    });
                }

                return Load2({
                    //TODO: ask Luan about keepOutdatedValue
                    keepOutdatedValue,
                    disabled: tileFilters.invalid || disabled,
                    captureException: true,
                    isSilentLoading: loadData.load && increment.value > 0,
                    _key: JSON.stringify({
                        key: loadData.key,
                        tileFilters: {
                            key: tileFilters.key,
                        },
                        tileKey,
                        page,
                        tableOverrides,
                        increment: increment.value,
                    }),
                    fetch: async () =>
                        loadData.load({
                            apiKey: loadData.key,
                            filters: tileFilters.getValue(),
                            extraCacheKey: `${tileKey}${loadData.key ? Date.now() : ``}${increment.value}`,
                            page,
                            tableOverrides,
                            timeZoneId: selectedTimezone?.value,
                        }),
                    next,
                });
            },
        ],
        ({autoRefreshProvider, tileData, clearEvent, increment}, next) => {
            if (!autoRefreshProvider.events) {
                return next();
            }

            return fragments(
                Invoke({
                    onMounted: () => {
                        clearEvent.set(
                            autoRefreshProvider.events?.on("silentReload", () => {
                                if (tileData.getLoadedState()?.value) {
                                    increment.change((c) => c + 1);
                                }
                            })
                        );
                    },
                }),
                OnUnmounted({
                    action: () => {
                        const clearFn = clearEvent.get();
                        clearFn?.();
                    },
                }),
                next()
            );
        },
        ({tileData}, next) =>
            LoadingWrapper({
                loading: tileData.loading,
                animated: !!loadData.load,
                tile,
                theme,
                tileBackgroundColorRGB: theme.general.tile.styles.tileBackgroundColorRGB,
                size,
                chartOuterLayout,
                next,
                ...(tileData.error
                    ? {
                          apiError: tileData.error,
                      }
                    : {
                          error: (!tileData.value || tileData.loading) && !disabled ? "Loading data..." : null,
                      }),
            }),
        ({tileData}, next) =>
            IgnoreUpdate({
                when: () => tileData.loading,
                next,
            }),
        ({tileData}) => {
            // if (!tileData.loading) {
            //     console.log("finish fetching data at ", Date())
            // }

            if (removeJSCursor && tileData.value) {
                return next(JSON.parse(JSON.stringify(tileData.value)));
            }

            return next(tileData.value);
        }
    );

const checkIsValidTileFuncs = {
    ChartTileData: (data) => data.series?.length > 0 && data.series.some(series => series.data.map((dp) => dp[1] != null).filter((v) => v).length > 0),
    // "TableTileData": () => true,
    // "KPITileData": () => true,
    // "GaugeTileData": () => true,
    ScatterPlotTileData: (data) => data.series?.length > 0,
    BubbleChartTileData: (data) => data.series?.length > 0,
};

export const isDataValid = (data, tile) => {
    if (tile?.$type === "VennDiagramTile") {
        return data.series?.length > 0 && data.series[0].data?.[0].length > 0;
    }
    return checkIsValidTileFuncs[data.$type]?.(data) ?? true;
};
