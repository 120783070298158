import "./live-filters.scss";

import { css, cx } from "emotion";
import * as React from "react";

import { cs } from "@common/react/chain-services";
import { consumeContext, provideContext } from "@common/react/context";

import { getCollectionFilters, getFiltersConfig } from "@common/ui-components/live/live-dashboard/live-filters/get-collection-filters";
import { equalDeep, keepOnly } from "@common/utils/objects";

import { isInsideFiltersArea } from "../../../../../web-client/src/routes/collection/common/dnd-grid-panel/dnd/moving";

import { StyledClass } from "@common/react/styled-class";
import { DownloadButton } from "./download-button/download-button";
import { LiveFiltersLayout } from "./layout/live-filters-layout";

export const LiveFilters = ({
    collection,
    onRunReport,
    filterForms,
    hiddenFilterIds,
    sdkDashboard,
    isLiveDashboard,
    showFilterDrop,
    filterDropArea,
    draggingState,
    editMode,
    getTileFilters,
    disabledFilters = [],
    apiKey = null,
}) =>
    cs(
        consumeContext("theme"),
        (_, next) => provideContext("editMode", editMode, next),
        (_, next) => (collection.value.filterStyle?.display === "SDK" ? <></> : next()),
        [
            "filtersConfig",
            (_, next) =>
                getFiltersConfig({
                    apiKey,
                    collection,
                    hiddenFilterIds,
                    sdkDashboard,
                    isLiveDashboard,
                    next,
                }),
        ],
        ["filters", ({filtersConfig}, next) => getCollectionFilters({filterForms, filtersConfig, next})],
        ({theme, filters, filtersConfig}) => {
            let {
                filters: collectionFilters,
                filterStyle,
                hideAllFilters,
                filterDownloadTiles,
                customWindowWidth,
                showTimezoneConversion,
                showPdfDownload,
            } = collection?.value ?? {};

            const dragState = draggingState?.value?.draggingNew ?? draggingState?.value?.moving;
            const isActive =
                dragState?.pos &&
                showFilterDrop &&
                isInsideFiltersArea({
                    pos: dragState.pos,
                    filterAreaRect: filterDropArea.get().getBoundingClientRect(),
                });

            const reportButton = ({fullWidth, onClick}) => {
                const isDisabled = disabledFilters
                    ? collectionFilters.filter(
                          (f) =>
                              disabledFilters.findIndex(
                                  (df) =>
                                      df.$type === f.$type &&
                                      equalDeep(
                                          keepOnly(df.columns[0], ["modelColumnID", "modelID", "modelTableID"]),
                                          keepOnly(f.columns[0], ["modelColumnID", "modelID", "modelTableID"])
                                      )
                              ) === -1
                      ).length === 0
                    : false;

                return (
                    <RunReportButton
                        theme={theme}
                        fullWidth={fullWidth}
                        onRunReport={() => {
                            onRunReport();
                        }}
                        onClick={onClick}
                        disabled={filterForms.invalid || isDisabled}
                    >
                        {filterStyle?.runButtonText || "Run Report"}
                    </RunReportButton>
                );
            };
            return (
                <div
                    {...{
                        className: cx("filters-area-wrapper-a33"),
                        ...(filterDropArea && {
                            ref: filterDropArea.set,
                        }),
                        style: {
                            minHeight: showFilterDrop ? "40px" : "",
                            marginBottom: "20px",
                        },
                    }}
                >
                    {showFilterDrop && <div className={cx("filters-drop-area", isActive && "active")}>Add to Filter Area</div>}

                    <div
                        className="filters-layout-wrapper-a32"
                        style={{
                            opacity: showFilterDrop ? 0 : 1,
                        }}
                    >
                        {LiveFiltersLayout({
                            noLabel: !filterStyle?.showLabels,
                            showTimezoneConversion,
                            noFilter: hideAllFilters,
                            wrapLine: filterStyle?.wrapLines,
                            customWindowWidth,
                            filters,
                            filtersConfig,
                            theme,
                            sdkDashboard,
                            filterForms,
                            disabledFilters,
                            onRunReport: filterStyle?.runType === "OnSubmit" ? onRunReport : null,
                            reportButton,
                            runReportButton: filterStyle?.runType === "OnSubmit" && reportButton,
                            downLoadButton:
                                (filterDownloadTiles?.length > 0 || showPdfDownload) &&
                                DownloadButton({
                                    collection,
                                    editMode,
                                    draggingState,
                                    getTileFilters,
                                    isLiveDashboard,
                                    showPdfDownload,
                                }),
                        })}
                    </div>
                </div>
            );
        }
    );

export const RunReportButton = ({fullWidth, onRunReport, onClick, disabled, theme, children}) => {
    const primaryButtonTheme = theme.general.buttons.primaryButton;

    return cs(
        [
            "cssClass",
            (_, next) =>
                StyledClass({
                    content: {
                        fontFamily: `"${theme.general.canvas.fontFamily || "Roboto"}", sans-serif`,
                        background: `${primaryButtonTheme.backgroundColorRGB}`,
                        color: `${primaryButtonTheme.fontColorRGB}`,
                        border: `${primaryButtonTheme.borderWidth}px solid ${primaryButtonTheme.borderColorRGB}`,
                        "border-radius": `${primaryButtonTheme.cornerRadius}px`,

                        "&:hover": {
                            background: `${primaryButtonTheme.hoverBackgroundColorRGB}`,
                            color: `${primaryButtonTheme.hoverFontColorRGB}`,
                            "border-color": `${primaryButtonTheme.hoverBorderColorRGB}`,
                        },
                    },
                    next,
                }),
        ],
        ({cssClass}) => (
            <button
                className={cx("" + "run-report-btn-3qo", {fullWidth}, "verb-filter-run-button", cssClass)}
                onClick={() => {
                    onRunReport();
                    onClick?.();
                }}
                disabled={disabled}
            >
                {children}
            </button>
        )
    );
};
