import * as React from "react";
import {cs} from "@common/react/chain-services";
import "./area-display.scss";
import {scope} from "@common/react/scope";
import {ThumbnailSelect} from "../../../../../../../../../common/thumbnail-select/thumbnail-select";
import {LineMarkerSelect, LineMarkerToggle} from "../../../common/line-marker-select";
import {getPath} from "@common/utils/arr-path";
import {LineWidthSelect} from "../../../common/line-width-select";
import {MultipleAxisOptions} from "../../../common/multiple-axis-options";

export const AreaDisplay = ({tile}) =>
    cs(({}) => {
        const {value, onChange} = scope(tile, ["style", "displayType"]);
        const lineDisplayValue = value.startsWith("Spline") ? "Spline" : "";
        const chartDisplayValue = value.match(/Area(.*)$/)[0];
        const onChangeLineDisplay = (lineDisplay) => onChange(`${lineDisplay}${chartDisplayValue}`);
        const onChangeChartDisplay = (chartDisplay) => onChange(`${lineDisplayValue}${chartDisplay}`);

        return (
            <div className="area-display-section-df8">
                <div className="subsection">
                    <div className="header">Chart Display</div>
                    <div className="content">
                        {ThumbnailSelect({
                            list: areaTypes,
                            isSelected: (type) => type.value === chartDisplayValue,
                            onChange: (type) => onChangeChartDisplay(type.value),
                        })}
                    </div>
                </div>
                <div className="subsection">
                    <div className="header">Line Display</div>
                    <div className="content">
                        {ThumbnailSelect({
                            list: lineTypes,
                            isSelected: (type) => type.value === lineDisplayValue,
                            onChange: (type) => onChangeLineDisplay(type.value),
                        })}

                        {LineWidthSelect(scope(tile, ["style", "thickness"]))}
                    </div>
                </div>

                <div className="subsection">
                    <div className="header">MULTIPLE AXIS DISPLAY</div>
                    <div className="content">
                        {MultipleAxisOptions({
                            state: scope(tile, ["style", "multipleAxisOption"]),
                        })}
                    </div>
                </div>

                <div className="subsection">
                    <div className="header">
                        Markers
                        <div className="control">{LineMarkerToggle(scope(tile, ["style", "marker"]))}</div>
                    </div>
                    {getPath(tile.value, ["style", "marker"]) !== "None" && <div className="content">{LineMarkerSelect(scope(tile, ["style", "marker"]))}</div>}
                </div>
            </div>
        );
    });

// Area, SplineArea,
// AreaStacked, SplineAreaStacked,
// AreaHundredPercent, SplineAreaHundredPercent

const lineTypes = [
    {
        label: "Straight",
        value: "",
        thumbnail: require("./icons/line-straight-icon.svg"),
    },
    {
        label: "Smooth",
        value: "Spline",
        thumbnail: require("./icons/line-smooth-icon.svg"),
    },
];

const areaTypes = [
    {
        label: "Classic",
        value: "Area",
        thumbnail: require("./icons/area-classic-icon.svg"),
    },
    {
        label: "Stacked",
        value: "AreaStacked",
        thumbnail: require("./icons/area-stacked-icon.svg"),
    },
    {
        label: "100%",
        value: "AreaHundredPercent",
        thumbnail: require("./icons/area-hundred-percent-icon.svg"),
    },
];
