import "./bottom-section.scss";

import React from "react";
import {cx} from "emotion";

import {keyed} from "@common/react/keyed";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";

const {consumeContext} = require("@common/react/context");

import {equalDeep} from "@common/utils/objects";
import {CookieStorage} from "../../../../../../common/logic/cookie-storage";

import {getUser} from "../../../guest-routes/login/login-route";
import {SelectTenantDialog} from "../../../guest-routes/login/select-tenant-dialog/select-tenant-dialog";

import {SettingsIcon, SwapIcon} from "../top-section/icons";
import {isAuthorized} from "../../../../verb-web/authorization/authorization";

export const BottomSection = () =>
    cs(
        consumeContext("auth"),
        consumeContext("routing"),
        consumeContext("apis"),
        ["selectTenantDialog", ({}, next) => SelectTenantDialog({next})],
        [
            "list",
            ({routing, auth, apis, selectTenantDialog}, next) => {
                const user1 = CookieStorage.get("verb.user");
                const access = CookieStorage.get("verb.auth.access");

                return next(
                    [
                        isAuthorized({roles: auth.user.roles, routeName: "account-management"}) && {
                            label: "Account Settings",
                            icon: () => SettingsIcon({fill: "#919EB0"}),
                            onClick: () =>
                                routing.goto("account-management", {
                                    setting: "account-details",
                                }),
                            id: "personal",
                        },
                        // equalDeep(user1.roles, ["Verb"]) ?
                        // {
                        //     label: "Change Tenant",
                        //     icon: () => SwapIcon({fill: "#919EB0"}),
                        //     onClick: async () => {
                        //         const [user, tenantId] = await Promise.all([getUser(), selectTenantDialog.show()]);
                        //
                        //         if (tenantId) {
                        //             auth.setAuth({
                        //                 user,
                        //                 tenantId,
                        //                 access,
                        //             });
                        //             CookieStorage.set("verb.admin.tenantid", tenantId.toString());
                        //             window.location.href = "/";
                        //         }
                        //     },
                        //     id: "change-tenant",
                        // },
                        // : null,
                        // {
                        //     label: "Organization Details",
                        //     icon: () => <img src={require("../../../common/icons/menu/org-icon.svg")} alt=""/>,
                        //     onClick: () => routing.goto("account-management", {setting: "organization-details", tab: "your-organization"}),
                        //     id: "organization-details",
                        //     isHidden: () => ["UserManager", "Full", "Verb"].indexOf(auth.user.roles[0]) == -1
                        // },
                        // {
                        //     label: "System API Keys",
                        //     icon: () => <img src={require("../../../common/icons/menu/system-icon.svg")} alt=""/>,
                        //     id: "system-api-keys",
                        //     onClick: () => routing.goto("account-management", {setting: "system-api-keys", tab: "sdk-key"}),
                        //     isHidden: () => ["UserManager", "Full", "Verb"].indexOf(auth.user.roles[0]) == -1
                        // },
                        {
                            label: "Logout",
                            icon: () => <img src={require("../../../common/icons/menu/logout-icon.svg")} alt="" />,
                            onClick: async () => {
                                auth.logout();
                                await apis.user.signOut();
                            },
                            id: "logout",
                        },
                    ].filter((v) => v)
                );
            },
        ],
        ["showing", (_, next) => UseState({initValue: false, next})],
        ({auth, list, showing}) => {
            const user1 = CookieStorage.get("verb.user");
            const itemHeight = 53;

            return (
                <div
                    className={cx("bottom-section bottom-section-3s8", {
                        showing: showing.value,
                        // "verb-admin": equalDeep(user1.roles, ["Verb"]),
                    })}
                    style={{bottom: showing.value ? 0 : list.length * itemHeight * -1}}
                >
                    <div className="user-info" onClick={() => showing.onChange(!showing.value)}>
                        <img src={require("./user-icon.svg")} alt="" />
                        <div className="name">{auth.user.firstName}</div>

                        <div className="icon">
                            {!showing.value ? <i className="far fa-ellipsis-v" /> : <i className="far fa-chevron-down" />}
                        </div>
                    </div>

                    <div className={cx("menu-list")}>
                        {list.map((l, i) =>
                            cs(keyed(i), () => (
                                <div className="menu-item" style={{height: itemHeight}} onClick={() => l.onClick()}>
                                    {l.icon()} <span className="text">{l.label}</span>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            );
        }
    );
