import "./scatter-plot.scss";
import {cs} from "../../../react/chain-services";
import {RenderChart} from "../render-chart/render-chart";
import * as React from "react";
import {getScatterPlotOptions} from "./options/scatter-plot-options";
import {consumeContext} from "../../../react/context";
import {keepOnly} from "../../../utils/objects";
import {isDataValid, loadTileData} from "../common/load-tile-data";
import {getScatterMeasurementAxesOptions} from "./options/scatter-measurement-axes";
import {decorateScatterData} from "./decorate-scatter-data";
import {isComparing} from "../factory/check-compare";
import {getScatterFormatters} from "./formatters";
import {MenuOptions} from "../common/menu-options/menu-options";
import {ChartInnerLayout} from "../chart-layout/chart-inner-layout";
import {NoDataScreen} from "@common/ui-components/charts/common/no-data-screen/no-data-screen";
import {
    generateReferenceSeries
} from "./reference-lines/generate-reference-series";
import {ActionMenu} from "@common/ui-components/charts/common/action-menu/action-menu";
import {RemoveTileControlFilter} from "@common/ui-components/charts/common/control-filter/remove-tile-control-filter";
import {cOnClickScatterPoint} from "@common/ui-components/charts/scatter-plot/on-click-scatter-point";
import {
    CurrencyConversionWarning,
    getCurrencyConversion
} from "@common/ui-components/charts/common/currency-conversion-warning/currency-conversion-warning";
import {loadTileFields} from "@common/ui-components/charts/get-field-color";
import {IgnoreUpdate} from "@common/react/ignore-update";

export const ScatterPlot = (
    {
        tile,
        chartRef, size, theme,
        tileFilters,
        loadData, downloadData, defaultData,
        overrideTile, disabledTileActions = false
    }
) => cs(
    consumeContext("tileActionControlFilter"),
    consumeContext('selectedTimezone'),
    ["chartData", ({tileActionControlFilter}, next) => defaultData ? next(defaultData) : loadTileData({
        next, tileFilters, loadData, size, tile, theme,
        tileKey: JSON.stringify([
            keepOnly(tile, ["limit", "sort", "categorySort", "filters", "dimensionField", "xAxisField", "yAxisField", "colorGroupField", "shapeGroupField"]),
        ]),
        disabled: tileActionControlFilter.value?.tile?.id === tile.id,
        removeJSCursor: true
    })],

    ({ chartData }, next) => !isDataValid(chartData) ? NoDataScreen({ size, theme }) : next(),

    ["rawData", ({chartData}, next) => next({...chartData, series: chartData.series.filter(s => tile.style?.showCompare === false ? !s.isCompare : true)})],

    ["formatters", ({rawData, selectedTimezone}, next) => next(getScatterFormatters({
        tile,
        timezone: selectedTimezone?.value,
        rawData,
    }))],

    ["tileFields", ({}, next) => loadTileFields({
        next,
        configs: {
            tile,
            dimensionAttr: "dimensionField",
            measureSingleAttrs: ["xAxisField", "yAxisField"],
            groupFieldAttrs: ["colorGroupField", "shapeGroupField"],
            checkUnique: false,
        }
    })],

    ["actionMenu", ({formatters}, next) => ActionMenu({
        tile,
        overrideTile,
        dimensionFormatter: formatters.dimensionFormatter,
        disabledTileActions,
        next,
    })],

    ["controls", ({rawData, chartData, tileFields}, next) => {
        const hasMenuOptions = tile.style.showDownloadData || tile.style.showDownloadImage;
        return next(
            <div className="controls">
                {CurrencyConversionWarning({
                    currencyConversion: getCurrencyConversion({ $type: chartData.$type, data: chartData, tileFields }),
                    theme
                })}

                {RemoveTileControlFilter({tile, hideBorder: !hasMenuOptions})}

                {hasMenuOptions && MenuOptions({
                    chartRef,
                    theme,
                    tile,
                    downloadData,
                    tileFilters
                })}
            </div>
        )
    }],

    ({ chartData, controls }, next) => ChartInnerLayout({
        size, tile, theme, next, noData: !isDataValid(chartData),
        hasControls: !!controls
    }),

    ({ selectedTimezone }, next) => IgnoreUpdate({
        next,
        props: { timezoneId: selectedTimezone?.value },
        when: ({ timezoneId }) => timezoneId != selectedTimezone?.value,
    }),

    ({formatters, chartData, rawData, actionMenu, controls}) => {

        const decoratedData = decorateScatterData({rawData, tile, theme});
        const isCompare = isComparing(rawData, tile);
        const referenceSeries = generateReferenceSeries({tile, theme, formatters, series: decoratedData.series});
        const measurementAxesOptions = getScatterMeasurementAxesOptions({
            tile, theme, rawData, formatters,
            referenceSeries
        });

        const options = {
            ...getScatterPlotOptions({
                tile, theme, rawData, formatters, isCompare,
                onClickPoint: cOnClickScatterPoint({actionMenu, tile}),
            }),
            xAxis: measurementAxesOptions.xAxis,
            yAxis: measurementAxesOptions.yAxis,
            series: [
                ...decoratedData.series,
                ...(referenceSeries || []),
            ],
        }

        return (
            <div
                className="scatter-plot-24k"
                key={getRefreshKey({
                    tile, theme,
                    // tileFiltersValue: tileFilters.getValue(),
                })}
            >
                <div className="chart">
                    <RenderChart
                        {...{
                            chartRef,
                            tile,
                            options, size, theme,
                            isCompare,
                            formatters,
                        }}
                    />
                </div>

                {controls}
            </div>
        )
    }
);

const getRefreshKey = ({tile, theme, tileFiltersValue}) => JSON.stringify([
    keepOnly(tile, ["id", "style", "referenceLines"]),
    // theme.dataVisualization.toolTipsAndLegends,
    // tileFiltersValue
]);
