import {scope} from "@common/react/scope";

import {TileTitle} from "../common/tile-title/tile-title";
import {FieldsStyle} from "./panels/field-style/fields-style";
import {FontDisplayOptions} from "./panels/font-display-options/font-display-options";

export const KPIList = ({tile, size, theme, form, parentTile, tileAction, kpiColorsTileConfig, isContainerTile}) =>
    [TileTitle, ...FieldsStyle(scope(tile, ["style", "tileFields"]), tile.value.valueFields), FontDisplayOptions].map((panel) =>
        panel({
            tile,
            size,
            form,
            parentTile,
            tileAction,
            kpiColorsTileConfig,
            isContainerTile,
        })
    );
