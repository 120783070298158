import {cs} from "@common/react/chain-services";
import "./flat-file-summary.scss";
import {Button} from "../../../../../../../common/form/buttons/button/button";
import {IconDown, IconUnchanged, IconUp} from "@common/ui-components/charts/common/icons/icons";
import {consumeContext} from "@common/react/context";

export const FlatFileSummary = ({histories}) =>
    cs(consumeContext("routing"), ({routing}) => {
        return (
            <div className="flat-file-summary-99p">
                {histories[1] && (
                    <div className="card import-summary">
                        <div className="label">Last Import Summary</div>

                        <div className="content">
                            <div className="section">
                                <div className="type">Tables</div>
                                <div className="main">
                                    {histories[1].tables} new, {histories[1].tables} mapped
                                </div>
                                {/*<div className="main">1 new, 7 mapped</div>*/}
                                <div className="desc">(1 new excluded; 1 old deleted)</div>
                            </div>
                            {/*<div className="section">*/}
                            {/*    <div className="type">Columns</div>*/}
                            {/*    <div className="main">1 new, 7 mapped</div>*/}
                            {/*    <div className="desc">(1 new excluded; 1 old deleted)</div>*/}
                            {/*</div>*/}

                            <Button
                                className="import-button"
                                onClick={() =>
                                    routing.goto("edit-import-new-flat-file", {
                                        envId: routing.params.envId,
                                        dsId: routing.params.dsId,
                                    })
                                }
                            >
                                Import New Version
                            </Button>
                        </div>
                    </div>
                )}

                {cs(
                    [
                        "getChanges",
                        (_, next) =>
                            next((prev, current) => {
                                if (prev === current)
                                    return (
                                        <>
                                            {IconUnchanged({})}
                                            <span className="unchanged">0%</span>
                                        </>
                                    );

                                if (prev > current) {
                                    return (
                                        <>
                                            {IconDown({})}
                                            <span className="down">{Number(((prev - current) / prev) * 100).toLocaleString()}%</span>
                                        </>
                                    );
                                } else {
                                    return (
                                        <>
                                            {IconUp({})}
                                            <span className="up">{Number(((current - prev) / current) * 100).toLocaleString()}%</span>
                                        </>
                                    );
                                }
                            }),
                    ],
                    ({getChanges}) => {
                        const current = histories[0] || {sizeMB: 0, records: 0};
                        const prev = histories[1] || {sizeMB: 0, records: 0};

                        return (
                            <>
                                <div className="card stats">
                                    <div className="label">Data Last Imported</div>
                                    <div className="content">
                                        <div className="value">{Number(current.sizeMB).toLocaleString()} MB</div>
                                        <div className="past">
                                            <div className="value">{Number(prev.sizeMB).toLocaleString()} MB</div>
                                            <div className="changes"> {getChanges(prev.sizeMB, current.sizeMB)}</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card stats">
                                    <div className="label">Records Last Imported</div>
                                    <div className="content">
                                        <div className="value">{Number(current.records).toLocaleString()}</div>
                                        <div className="past">
                                            <div className="value">{Number(prev.records).toLocaleString()}</div>
                                            <div className="changes"> {getChanges(prev.records, current.records)}</div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        );
                    }
                )}
            </div>
        );
    });
