import * as React from "react";
import "./file-format.scss";
import {CheckboxLineGroup} from "../../../../../../../../../../common/checkbox-line-group/checkbox-line-group";
import {ffToCheckboxGroup} from "../../../../../../../../../../../../../common/form/ff-to-checkbox-group";

export const FileFormat = ({tile, form}) => ({
    label: "File Format",
    render: () => (
        <div className="download-report-file-format-24g">
            {CheckboxLineGroup({
                list: [
                    "XLSX",
                    "CSV",
                    "JSON",
                    // "XML", "XLS",
                ].map((l) => ({label: l, value: l})),
                ...ffToCheckboxGroup(form.field(["style", "downloadFormats"]), ["value"]),
            })}
        </div>
    ),
});
