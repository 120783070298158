import React from "react";
import {cs} from "@common/react/chain-services";
import {LeftPanelClosable} from "../../../../common/left-panel-closable/left-panel-closable";
import {consumeContext} from "@common/react/context";
import {SelectFieldBase} from "../../select-field-base/select-field-base";

export const SelectFilterField = ({filter, onClose, allowedFieldType}) =>
    cs(
        ({}, next) => (
            <LeftPanelClosable
                {...{
                    onClose,
                    label: "Select Field",
                    content: next(),
                }}
            />
        ),
        consumeContext("modelForCollection"),
        ({modelForCollection}) => {
            return SelectFieldBase({
                model: modelForCollection,
                allowedFieldType,
                notAllowedFields: filter.value?.columns,
                onSelect: ({column, table}) => {
                    filter.change((filter) => {
                        const newColumns = [
                            ...(filter.columns || []),
                            {
                                modelID: modelForCollection.id,
                                modelTableID: table.id,
                                modelColumnID: column.id,
                                displayName: column.visualNameShort,
                                ...(!filter.columns?.length && {
                                    default: true,
                                }),
                            },
                        ];

                        return {
                            ...filter,
                            ...(!filter.columns?.length && {
                                label: column.visualNameShort,
                            }),
                            columns: newColumns,
                            multiSelectionOption:
                                newColumns.length == 1
                                    ? "SingleSelect"
                                    : !filter.multiSelectionOption || filter.multiSelectionOption == "SingleSelect"
                                    ? "Any"
                                    : filter.multiSelectionOption ?? "Any",
                        };
                    });
                    onClose();
                },
            });
        }
    );
