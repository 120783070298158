import {cs} from "@common/react/chain-services";
import {scope} from "@common/react/scope";
import {FieldSection} from "../common/field-section/field-section";
import {FieldInfoProvider} from "../../../tile-tab/chart-types/common/field-info-provider/field-info-provider";
import * as React from "react";
import {Button} from "@common/form/buttons/button/button";
import {DnDList} from "../../../../../../../../common/dnd-list/dnd-list";
import {
    DraggableSortingItem,
    SortingItemBlank
} from "../common/limit-sorting/sorting/draggable-sorting-item/draggable-sorting-item";
import {DebounceTextInput} from "@common/form/debounce-input/debounce-text-input";
import "./funnel-sorting.scss";
import {removeIndex} from "@common/utils/collections";
import {spc} from "@common/react/state-path-change";

export const FunnelSorting = ({tile}) => cs(
    ({}) => {
        return FieldSection({
            header: FieldInfoProvider({
                field: <div className="text">Sorting</div>,
                info: "By default funnels are sorted largest to smallest. It’s possible to custom sort funnels. To do this, create a stage for each group available. Verb will match the data to the stage title provided in the sorting list. If the text does not match or a group is not included in the sort list it will be included at the beginning of the funnel chart."
            }),
            content: (
                <div className="funnel-sorting-9c2">
                    <SortingStages tile={tile}/>
                    <Button
                        size="tiny"
                        btnType="secondary"
                        className="add-field-btn"
                        onClick={() => spc(tile, ["customSortValues"], (values) => ([...(values || []), null]))}
                    >
                        Add Funnel Stage
                    </Button>
                </div>
            )
        })
    }
)

const SortingStages = ({tile}) => cs(
    ["dnd", (_, next) => DnDList({
        list: tile.value.customSortValues,
        onChange: (newList) => spc(tile, ["customSortValues"], () => newList),
        renderDrag: ({index}) => <Stage {...{
            index,
            onStartDragging: () => {},
            dragging: true,
            tile,
        }}/>,
        next,
    })],
    ({dnd}) => {
        return dnd.list?.map(({index, ref, start}) => (
            index != null ? (
                <div className="stage" ref={ref} key={index}>
                    <Stage {...{
                        index,
                        onStartDragging: start,
                        onRemove: () => spc(tile, ["customSortValues"], (values) => removeIndex(index, values)),
                        tile,
                    }}/>
                </div>
            ) : (
                <SortingItemBlank/>
            )
        ))
    }
)

const Stage = ({index, onStartDragging, dragging, onRemove, tile}) => {
    const state = scope(tile, ["customSortValues", index]);
    return (
        <DraggableSortingItem {...{
            onStartDragging, dragging, onRemove
        }}>
            <DebounceTextInput {...{
                label: `${index < 10 ? ordinals[index] : getOrdinalSuffix(index + 1)} Stage`,
                state,
                // autoFocus: state.value == null,
            }}/>
        </DraggableSortingItem>
    )
}

const ordinals = ["First", "Second", "Third", "Fourth", "Fifth", "Sixth", "Seventh", "Eighth", "Ninth", "Tenth"]

const getOrdinalSuffix = (i) => {
    let j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + "st";
    }
    if (j === 2 && k !== 12) {
        return i + "nd";
    }
    if (j === 3 && k !== 13) {
        return i + "rd";
    }
    return i + "th";
}
