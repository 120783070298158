import React from "react";

import {cs} from "@common/react/chain-services";
import {SVGFilterDropShadow} from "./model-dragging-new";
import {Relationships} from "./relationships/relationships";
import {scope} from "@common/react/scope";
import {ModelTable} from "./table/model-table";
import {ModelTableControls} from "./controls/model-table-controls";
import {CreateNewRelationshipLine} from "./create-new-relationship/create-new-relationship-line";
import {equalDeep} from "@common/utils/objects";
import {Static2} from "@common/react/static-2";
import {consumeContext, provideContext} from "@common/react/context";
import {getTableErrors} from "../layout/error-badge/error-badge-helper";
import {RemoveTableDialog} from "../tabs/left-panel-overrides/table-panel/remove-table/remove-table-dialog";

export const ModelTables = ({
    model,
    tables,
    invalidTables,
    warningTables,
    dataSources,
    autoSuggest,
    interactions,
    transformState,
    state,
    svgRef,
    textBoxRef,
    modelRef,
    zoomRef,
    modelActionTooltip,
    tableMoreControls,
    tooltip,
}) => {
    let container = modelRef.get()?.getBoundingClientRect();
    const zoom = zoomRef.get();
    const {transform, scale} = transformState;
    const {relationships, disabledTables} = model.value;
    const {grabID, lastTableGrabPosition, movedToCenter} = state.value;

    return cs(
        consumeContext("dataViewTransforms"),
        ["modelErrorBoxRef", (_, next) => Static2({next})],
        ["modelErrorBoxSelectedRef", (_, next) => Static2({next})],
        ({modelErrorBoxSelectedRef}, next) => provideContext("modelErrorBoxSelectedRef", modelErrorBoxSelectedRef, next),
        [
            "removeTableService",
            (_, next) =>
                RemoveTableDialog({
                    model,
                    onDone: () => {},
                    next,
                }),
        ],
        [
            "createRelationshipLine",
            ({}, next) =>
                CreateNewRelationshipLine({
                    next,
                    interactions,
                    relationships: model.value.relationships,
                    tables: model.value.tables,
                    dataSources,
                    hoverInteractions: scope(state, ["hoverInteractions"]),
                    ...transformState,
                }),
        ],
        ({
            removeRelationshipService,
            createRelationshipLine,
            modelErrorBoxRef,
            modelErrorBoxSelectedRef,
            dataViewTransforms,
            removeTableService,
        }) => {
            return (
                <svg
                    ref={svgRef.set}
                    width="100%"
                    height="100%"
                    onClick={() => {
                        interactions.deSelectTable();
                    }}
                >
                    <g transform={`translate(${transform.x}, ${transform.y}) scale(${scale})`}>
                        {SVGFilterDropShadow({})}

                        {movedToCenter && (
                            <>
                                {Relationships({
                                    hoverInteractions: scope(state, ["hoverInteractions"]),
                                    textBoxRef,
                                    autoSuggest,
                                    removeRelationshipService,
                                    relationships: relationships,
                                    tables,
                                    scale,
                                    interactions,
                                    dataSources,
                                    modelErrorBoxRef,
                                    relationshipTablesState: model.value.relationshipTablesState,
                                })}

                                {tables
                                    .filter((t) => !t.hidden)
                                    .map((table) => (
                                        <ModelTable
                                            key={`${table.id}-${table.dataSourceTableID}`}
                                            {...{
                                                hoverInteractions: scope(state, ["hoverInteractions"]),
                                                relationships,
                                                dataSources,
                                                container,
                                                table,
                                                state,
                                                invalidTables,
                                                scale,
                                                transform,
                                                lastTableGrabPosition,
                                                interactions,
                                                autoSuggest,
                                                modelErrorBoxRef,
                                                errors: getTableErrors({
                                                    table,
                                                    disabledTables,
                                                    dataViewTransforms: dataViewTransforms.value,
                                                    tables,
                                                    relationships,
                                                }),
                                                isWarning: table.id && warningTables.includes(table.id),
                                                isGrab: table.id && table.id == grabID,
                                                onGrab: () =>
                                                    state.onChange({
                                                        ...state.value,
                                                        grabID: table.id,
                                                        lastTableGrabPosition: table.position,
                                                    }),
                                                onMove: (position) =>
                                                    model.change(
                                                        (m) => ({
                                                            ...m,
                                                            tables: m.tables.map((t) => (t.id == table.id ? {...t, position} : t)),
                                                            disabledTables: m.disabledTables.map((t) =>
                                                                t.id == table.id ? {...t, position} : t
                                                            ),
                                                        }),
                                                        true
                                                    ),
                                                onMovePan: ({transform, tablePos}) => {
                                                    model.change(
                                                        (m) => ({
                                                            ...m,
                                                            tables: m.tables.map((t) =>
                                                                t.id == table.id
                                                                    ? {
                                                                          ...t,
                                                                          position: tablePos,
                                                                      }
                                                                    : t
                                                            ),
                                                        }),
                                                        () => {}
                                                    );

                                                    d3.select(svgRef.get()).call(
                                                        zoom.transform,
                                                        d3.zoomIdentity.translate(transform.x, transform.y).scale(scale)
                                                    );
                                                },
                                                onDrop: (position) => {
                                                    state.onChange({
                                                        ...state.value,
                                                        grabID: null,
                                                    });

                                                    if (!equalDeep(position, lastTableGrabPosition)) {
                                                        interactions.upsertTablePosition(model.value.id, {
                                                            [table.id]: position,
                                                        });
                                                    }

                                                    model.change(
                                                        (m) => ({
                                                            ...m,
                                                            tables: m.tables.map((t) =>
                                                                t.id == table.id
                                                                    ? {
                                                                          ...t,
                                                                          position,
                                                                      }
                                                                    : t
                                                            ),
                                                        }),
                                                        true // only update localState
                                                    );
                                                },
                                                tooltip,
                                            }}
                                        />
                                    ))}

                                {createRelationshipLine.render()}
                            </>
                        )}

                        <g ref={textBoxRef.set} />

                        {dataSources &&
                            ModelTableControls({
                                table: tables.find((t) => t.id == interactions.setting?.data?.tableId),
                                dataSources,
                                tableMoreControls,
                                relationships,
                                modelActionTooltip,
                                transform,
                                scale,
                                interactions,
                                model,
                                createRelationshipLine,
                                hoverInteractions: scope(state, ["hoverInteractions"]),
                                removeTableService,
                            })}

                        <g ref={modelErrorBoxRef.set} />
                        <g ref={modelErrorBoxSelectedRef.set} />
                    </g>
                </svg>
            );
        }
    );
};
