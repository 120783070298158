import "./header-bar.scss";

import React from "react";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {LogoHeader} from "./logo-header";
import {DataTypeSelect} from "./data-type-select";
import {FixedPopupMenu} from "../fixed-popup-menu/fixed-popup-menu";
import {tGetEnvironment} from "../../../verb-web/auth/load-auth-routes";
import {PreviewListSelect} from "./preview/preview-list-select";

export const HeaderBar = ({ID, type, name, shareActions, previousRoutes = [], commands, noDataType, previewList, selectedState, themeID, minWidthCommand}) =>
    cs(
        consumeContext("tenant"),
        consumeContext("routing"),
        ["environment", (_, next) => tGetEnvironment({next})],
        [
            "popupMenu",
            ({routing, auth, apis}, next) =>
                FixedPopupMenu({
                    next,
                    position: {top: 10},
                    getCommands: () => commands,
                    minWidth: minWidthCommand,
                }),
        ],
        ({routing, environment, popupMenu}) => {
            const envId = routing.params.envId;
            const onGoBack = () => {
                const prevRoute = (routing.previousRoutes || []).filter((r) => previousRoutes.find((r2) => r2.split("|")[0] == r.routeName))[0] ?? {
                    routeName: "dashboard",
                    params: {
                        envId,
                        // Mapping routing params tab with type of header ()
                        tab:
                            {
                                datasource: "data-sources",
                                collection: "collections",
                                model: "models",
                                sharedTile: "shared-tiles",
                            }[type] || "overview",
                    },
                };
                routing.goto(prevRoute.routeName, prevRoute.params);
            };

            return (
                <div className="header-bar-a44">
                    {LogoHeader({
                        routing,
                        envId,
                        environment,
                        onGoBack,
                    })}

                    <div className="env-collections">
                        <div className="nav-back" onClick={onGoBack}>
                            <i className="far fa-arrow-left" />
                        </div>

                        <div className="env-collection-select">
                            <div className="env-name">{environment?.name || "Environment"}</div>

                            {name &&
                                DataTypeSelect({
                                    ID,
                                    type,
                                    name,
                                    noDataType,
                                })}
                        </div>

                        {!environment?.readOnly && popupMenu.render({})}

                        {/*<img*/}
                        {/*    className='back-btn'*/}
                        {/*    src={require("./icons/back-btn.svg")} alt=""/>*/}
                    </div>

                    {previewList && (
                        <div className="preview-actions">
                            <div className="preview-control">
                                <div className="label">Preview</div>

                                {PreviewListSelect({
                                    state: selectedState,
                                    list: previewList,
                                    themeID,
                                })}
                            </div>
                        </div>
                    )}

                    {shareActions && <div className="share-actions">{shareActions()}</div>}
                </div>
            );
        }
    );
