import {FieldSection} from "../../../../../collection/tile/edit/left-panel/tabs/fields-tab/chart-types/common/field-section/field-section";
import {FieldInfoProvider} from "../../../../../collection/tile/edit/left-panel/tabs/tile-tab/chart-types/common/field-info-provider/field-info-provider";
import {LpColorLine} from "../../../../../common/left-panel/lp-color-line";
import {ffToDropdown} from "../../../../../../../../common/form/ff-to-dropdown";
import {FontSizeSelect} from "../../../../../collection/tile/edit/left-panel/tabs/tile-tab/chart-types/common/font-size-select";
import {FontOptionsSelect} from "../../../../../collection/tile/edit/left-panel/tabs/tile-tab/chart-types/table/panels/font-options-select/font-options-select";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {NumberInput} from "../../../../../../../../common/form/number-input/number-input";
import {ffToColorPickerLine} from "../../../../../../../../common/form/ff-to-color-picker-line";
import {ffToNumberInput} from "../../../../../../../../common/form/ff-to-number-input";

export const AxisChartsTheme = ({form}) => {
    return (
        <>
            {FieldSection({
                header: FieldInfoProvider({
                    field: <div className="text">AXIS/GRID STYLE</div>,
                    noCollapsible: true,
                }),
                content: (
                    <>
                        {LpColorLine({
                            label: "Axis Line Color",
                            ...ffToColorPickerLine(form.field(["dataVisualization", "axisCharts", "axisLineColorRGB"])),
                        })}

                        {LpColorLine({
                            label: "Grid Step Line Color",
                            ...ffToColorPickerLine(form.field(["dataVisualization", "axisCharts", "gridStepLineColorRGB"])),
                        })}

                        {DropdownSelect({
                            label: "Grid Step Line Style",
                            list: ["Solid", "Dashed", "Dotted"],
                            ...ffToDropdown(form.field(["dataVisualization", "axisCharts", "gridStepLineStyle"])),
                        })}

                        {DropdownSelect({
                            label: "Dimension Axis Line Style",
                            list: [
                                {label: "Tick Marks", value: true},
                                {label: "No Tick Marks", value: false},
                            ],
                            valueToLabel: (v) => v.label,
                            ...ffToDropdown(form.field(["dataVisualization", "axisCharts", "dimensionAxisTickMarks"]), ["value"]),
                        })}
                    </>
                ),
            })}

            {FieldSection({
                header: FieldInfoProvider({
                    field: <div className="text">BAR CHARTS</div>,
                    noCollapsible: true,
                }),
                content: (
                    <>
                        {NumberInput({
                            label: "Corner Radius",
                            unit: "px",
                            ...ffToNumberInput(form.field(["dataVisualization", "axisCharts", "barChartCornerRadius"])),
                            showZero: true,
                        })}

                        {DropdownSelect({
                            label: "Corner Radius Placement",
                            list: [
                                {
                                    value: "Top",
                                    label: "Top Corners",
                                },
                                {
                                    value: "All",
                                    label: "All Corners",
                                },
                            ],
                            valueToLabel: (v) => v.label,
                            ...ffToDropdown(form.field(["dataVisualization", "axisCharts", "barChartCornerRadiusLocation"]), ["value"]),
                        })}
                    </>
                ),
            })}

            {FieldSection({
                header: FieldInfoProvider({
                    field: <div className="text">AREA LINE</div>,
                    noCollapsible: true,
                }),
                content: (
                    <>
                        {DropdownSelect({
                            label: "Area Fill",
                            list: ["Semitransparent", "Gradient", "Solid"],
                            ...ffToDropdown(form.field(["dataVisualization", "axisCharts", "areaLineChartFill"])),
                        })}
                    </>
                ),
            })}
        </>
    );
};
