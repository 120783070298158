import {FieldSection} from "../../../../../collection/tile/edit/left-panel/tabs/fields-tab/chart-types/common/field-section/field-section";
import {FieldInfoProvider} from "../../../../../collection/tile/edit/left-panel/tabs/tile-tab/chart-types/common/field-info-provider/field-info-provider";
import {LpColorLine} from "../../../../../common/left-panel/lp-color-line";
import {ffToDropdown} from "../../../../../../../../common/form/ff-to-dropdown";
import {FontSizeSelect} from "../../../../../collection/tile/edit/left-panel/tabs/tile-tab/chart-types/common/font-size-select";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {LpShowHideLine} from "../../../../../common/left-panel/lp-show-hide-line";
import {ffToShowHideLine} from "../../../../../../../../common/form/ff-to-show-hide-line";
import {NumberInput} from "../../../../../../../../common/form/number-input/number-input";
import {ffToColorPickerLine} from "../../../../../../../../common/form/ff-to-color-picker-line";
import {ffToNumberInput} from "../../../../../../../../common/form/ff-to-number-input";

export const TooltipsAndLegendsTheme = ({form}) => {
    return (
        <>
            {FieldSection({
                header: FieldInfoProvider({
                    field: <div className="text">TOOLTIPS</div>,
                    noCollapsible: true,
                }),
                content: (
                    <>
                        {DropdownSelect({
                            label: "Tooltip theme",
                            list: ["Light", "Dark"],
                            ...ffToDropdown(form.field(["dataVisualization", "toolTipsAndLegends", "tooltipTheme"])),
                        })}

                        {DropdownSelect({
                            label: "Tooltip position",
                            list: ["Above", "Below"].map((value) => ({
                                value,
                                label: value + " charts",
                            })),
                            valueToLabel: (v) => v.label,
                            ...ffToDropdown(form.field(["dataVisualization", "toolTipsAndLegends", "position"]), ["value"]),
                        })}

                        {FontSizeSelect({
                            label: "Hover Tooltip Font Size",
                            ...ffToDropdown(form.field(["dataVisualization", "toolTipsAndLegends", "fontSize"])),
                        })}

                        {LpShowHideLine({
                            label: "HOVER INDICATOR LINE",
                            ...ffToShowHideLine(form.field(["dataVisualization", "toolTipsAndLegends", "indicatorLineShown"])),
                        })}

                        {DropdownSelect({
                            label: "Line Style",
                            list: ["Solid", "Dashed", "Dotted"],
                            ...ffToDropdown(form.field(["dataVisualization", "toolTipsAndLegends", "indicatorLineStyle"])),
                        })}

                        {LpColorLine({
                            label: "Line Color",
                            ...ffToColorPickerLine(form.field(["dataVisualization", "toolTipsAndLegends", "indicatorLineColorRGB"])),
                        })}

                        {NumberInput({
                            label: "Line Width",
                            unit: "px",
                            ...ffToNumberInput(form.field(["dataVisualization", "toolTipsAndLegends", "indicatorLineWidth"])),
                            showZero: true,
                        })}
                    </>
                ),
            })}

            {FieldSection({
                header: FieldInfoProvider({
                    field: <div className="text">LEGENDS</div>,
                    noCollapsible: true,
                }),
                content: (
                    <>
                        {FontSizeSelect({
                            label: "Legend Font Size",
                            ...ffToDropdown(form.field(["dataVisualization", "toolTipsAndLegends", "legendFontSize"])),
                            themeFontSizeGroup: form.field(["general", "canvas", "fontFamily"]).state.value,
                        })}

                        {DropdownSelect({
                            label: "Symbol Shapes",
                            list: ["Square", "Circle"],
                            ...ffToDropdown(form.field(["dataVisualization", "toolTipsAndLegends", "legendSymbol"])),
                        })}
                    </>
                ),
            })}
        </>
    );
};
