import "./pagination.scss";

import * as React from "react";
import {css, cx} from "emotion";

import {keyed} from "../../../../../react/keyed";
import {Invoke} from "../../../../../react/invoke";
import {cs} from "../../../../../react/chain-services";
import {UseState} from "../../../../../react/use-state";
import {spc} from "../../../../../react/state-path-change";

import {pagination} from "./pagination-func";
import {StyledClass} from "@common/react/styled-class";

export const Pagination = ({disabled, next, theme, containerSize}) =>
    cs(
        [
            "page",
            (_, next) =>
                UseState({
                    initValue: {
                        current: 0,
                        total: 0,
                    },
                    next,
                }),
        ],
        ({page}) =>
            next({
                renderControl: ({numberOfPage}) =>
                    cs(({}) => {
                        const pages = pagination(page.value.current + 1, numberOfPage, 2);
                        const doubleArrowClass = (index) => (index <= pages.length - 1 - index ? "left" : "right");

                        // theme.buttonColorRGB
                        const primaryButton = theme.general.buttons.primaryButton;
                        const secondaryButton = theme.general.buttons.secondaryButton;

                        const getCssClass = (style) => ({
                            background: `${style.backgroundColorRGB}`,
                            color: `${style.fontColorRGB}`,
                            border: `${style.borderWidth}px solid ${style.borderColorRGB}`,
                            "border-radius": `${style.cornerRadius}px`,
                            "font-family": `${theme.general.canvas.fontFamily}, sans-serif`,

                            "&:hover": {
                                background: `${style.hoverBackgroundColorRGB}`,
                                color: `${style.hoverFontColorRGB}`,
                                "border-color": `${style.hoverBorderColorRGB}`,
                            },
                        });

                        return (
                            !disabled && (
                                <div className="pagination pagination-35g">
                                    {cs(keyed(numberOfPage), () =>
                                        Invoke({
                                            fn: () => {
                                                if(numberOfPage != page.value.total) {
                                                    page.onChange({
                                                        current: 0,
                                                        total: numberOfPage,
                                                    });
                                                }
                                            },
                                        })
                                    )}

                                    {containerSize?.value?.width >= 380 && (
                                        <div className="pages">
                                            <div
                                                className={cx("prev", page.value.current == 0 && "disabled")}
                                                onClick={() =>
                                                    pages.length > 1 && spc(page, ["current"], () => Math.max(0, page.value.current - 1))
                                                }
                                            >
                                                <LeftArrow fill={secondaryButton.fontColorRGB} />
                                            </div>

                                            {pages.map((item, index) => {
                                                const active = item == page.value.current + 1;
                                                const style = active ? primaryButton : secondaryButton;

                                                return cs(
                                                    keyed(index),
                                                    [
                                                        "cssClass",
                                                        (_, next) =>
                                                            StyledClass({
                                                                content: getCssClass(style),
                                                                next,
                                                            }),
                                                    ],
                                                    ({cssClass}) => (
                                                        <div
                                                            className={cx(
                                                                "page",
                                                                item == "..." && "dots",
                                                                pages.findIndex((p) => p == item) != index && "next-double-arrow",
                                                                item == page.value.current + 1 && "active",
                                                                cssClass
                                                            )}
                                                            key={index}
                                                            onClick={() =>
                                                                pages.length > 1 &&
                                                                spc(page, ["current"], () =>
                                                                    item == "..."
                                                                        ? doubleArrowClass(index) == "left"
                                                                            ? Math.max(pages[index + 1] - 2, 0)
                                                                            : Math.min(pages[index - 1] + 3, numberOfPage - 1)
                                                                        : item - 1
                                                                )
                                                            }
                                                        >
                                                            <span className="text">{item}</span>
                                                            {item == "..." && (
                                                                <div className={cx("double-arrow", doubleArrowClass(index))}>
                                                                    <DoubleArrow fill={primaryButton.backgroundColorRGB} />
                                                                </div>
                                                            )}
                                                        </div>
                                                    )
                                                );
                                            })}

                                            <div
                                                className={cx(
                                                    "next",
                                                    (page.value.current == numberOfPage - 1 || pages.length <= 1) && "disabled"
                                                )}
                                                onClick={() =>
                                                    pages.length > 1 &&
                                                    spc(page, ["current"], () => Math.min(numberOfPage - 1, page.value.current + 1))
                                                }
                                            >
                                                <LeftArrow fill={secondaryButton.fontColorRGB} />
                                            </div>
                                        </div>
                                    )}

                                    <div
                                        className="jump-to"
                                        style={{
                                            color: secondaryButton.fontColorRGB,
                                        }}
                                    >
                                        Jump to:
                                        {(() =>
                                            cs(
                                                [
                                                    "cssClass",
                                                    (_, next) =>
                                                        StyledClass({
                                                            content: getCssClass(secondaryButton),
                                                            next,
                                                        }),
                                                ],
                                                ({cssClass}) => (
                                                    <div className={cx("jump-to-box", cssClass)}>
                                                        <b>{page.value.current + 1}</b>
                                                        <i><ArrowDropdown fill={secondaryButton.fontColorRGB} /></i>

                                                    </div>
                                                )
                                            ))()}
                                        <select
                                            disabled={pages.length <= 1}
                                            value={page.value.current}
                                            onChange={(e) => {
                                                const newVal = parseInt(e.target.value);
                                                if(Number.isInteger(newVal)) {
                                                    spc(page, ["current"], () => newVal);
                                                }
                                            }}
                                        >
                                            {[...Array(numberOfPage).keys()].map((item) => (
                                                <option value={item} key={item}>
                                                    {item + 1}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            )
                        );
                    }),
                page: !disabled && page.value.current,
            })
    );

const DoubleArrow = ({fill = "#02516c"}) => (
    <svg
        viewBox="64 64 896 896"
        focusable="false"
        className=""
        data-icon="double-right"
        width="1em"
        height="1em"
        fill={fill}
        aria-hidden="true"
    >
        <path d="M533.2 492.3L277.9 166.1c-3-3.9-7.7-6.1-12.6-6.1H188c-6.7 0-10.4 7.7-6.3 12.9L447.1 512 181.7 851.1A7.98 7.98 0 00188 864h77.3c4.9 0 9.6-2.3 12.6-6.1l255.3-326.1c9.1-11.7 9.1-27.9 0-39.5zm304 0L581.9 166.1c-3-3.9-7.7-6.1-12.6-6.1H492c-6.7 0-10.4 7.7-6.3 12.9L751.1 512 485.7 851.1A7.98 7.98 0 00492 864h77.3c4.9 0 9.6-2.3 12.6-6.1l255.3-326.1c9.1-11.7 9.1-27.9 0-39.5z" />
    </svg>
);

const LeftArrow = ({fill = "#02516c"}) => (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.02398 9.16689H17.1673V10.8336H7.02398L11.494 15.3036L10.3157 16.4819L3.83398 10.0002L10.3157 3.51855L11.494 4.69689L7.02398 9.16689Z"
            fill={fill}
        />
    </svg>
);

const ArrowDropdown = ({fill = "currentColor"}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="6" viewBox="0 0 11 6">
        <g fill="none" fillRule="evenodd">
            <g fill={fill} fillRule="nonzero">
                <path
                    d="M2.937-1.768c-.232-.21-.607-.21-.838 0-.231.211-.231.552 0 .763L6.496 3 2.1 7.005c-.231.21-.231.552 0 .763.116.105.267.158.419.158.151 0 .303-.053.419-.158L7.752 3.38c.231-.21.231-.552 0-.762L2.937-1.768z"
                    transform="translate(-1568 -1326) translate(1169 796) translate(0 476) translate(87 41) translate(211.8) translate(100.482 13) rotate(90 4.926 3)"
                />
            </g>
        </g>
    </svg>
);
