const {getDomPos} = require("./get-dom-pos");
const {Static2} = require("@common/react/static-2");
const {Moving} = require("./moving");
const {Resizing} = require("./resizing");
const {cs} = require("@common/react/chain-services");

const DnD = ({
    blockSize,
    restrictResize,
    restrictMove,
    onMoved,
    rejectRect,
    spacing,
    onResized,
    tiles,
    draggingState,
    filterDropArea,
    next,
    allowFilterDrops,
    onDropToFilters,
}) =>
    cs(
        ["rootDomRef", (_, next) => Static2({next})],
        [
            "moving",
            ({rootDomRef}, next) =>
                Moving({
                    tiles,
                    draggingState,
                    filterDropArea,
                    allowFilterDrops,
                    blockSize,
                    restrictRect: restrictMove,
                    getRootPos: () => getDomPos(rootDomRef.get()),
                    rejectRect,
                    onDone: onMoved,
                    next,
                    spacing,
                    onDropToFilters,
                }),
        ],
        [
            "resizing",
            ({rootDomRef}, next) =>
                Resizing({
                    blockSize,
                    restrictRect: restrictResize,
                    getRootPos: () => getDomPos(rootDomRef.get()),
                    rejectRect,
                    onDone: onResized,
                    next,
                    spacing,
                    filterDropArea,
                }),
        ],
        ({moving, resizing, rootDomRef}) => {
            const {error: rectRestricted, restricted: restrictedRect} = resizing.rect || moving.rect || {};

            return next({
                activeRect: restrictedRect,
                rectRestricted,
                rootDomRef: rootDomRef.set,
                getRootPos: () => getDomPos(rootDomRef.get()),
                forTile: ({tileIndex, tile}) => {
                    let dom;
                    return {
                        ref: (r) => (dom = r),
                        resizing: resizing.forTile(tileIndex),
                        moving: moving.forTile(tileIndex),
                        startResizing: (e, position) =>
                            resizing.activate({
                                e,
                                tileIndex,
                                tile,
                                dom,
                                position,
                            }),
                        startMoving: (e) => moving.activate({e, tileIndex, tile, dom}),
                    };
                },
            });
        }
    );
exports.DnD = DnD;
