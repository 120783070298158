import {Expandable} from "../../../../common/expandable/expandable";
import {LpColorLine} from "../../../../common/left-panel/lp-color-line";
import {ffToColorPickerLine} from "../../../../../../../common/form/ff-to-color-picker-line";
import {NumberInput} from "../../../../../../../common/form/number-input/number-input";
import {ffToNumberInput} from "../../../../../../../common/form/ff-to-number-input";
import {betweenNumbers} from "@common/utils/math-util";
import {LpShowHideLine} from "../../../../common/left-panel/lp-show-hide-line";
import {ffToShowHideLine} from "../../../../../../../common/form/ff-to-show-hide-line";
import * as React from "react";

export const TileStyles = ({form, sectionInitClosed}) => (
    <Expandable
        {...{
            initExpand: !sectionInitClosed,
            label: "Tile Styles",
            render: () => (
                <div className="panel-22">
                    {LpColorLine({
                        label: "Tile Background Color",
                        ...ffToColorPickerLine(form.field(["tileBackgroundColorRGB"])),
                    })}

                    <div className="dual-line">
                        <div className="col1">
                            {NumberInput({
                                label: "Tile Border Width",
                                ...ffToNumberInput(form.field(["tileBorderWidth"]), !betweenNumbers(0, 5, form.field(["tileBorderWidth"]).value || 0) ? "Between 0 and 5" : null),
                                unit: "pixels",
                            })}
                        </div>
                        <div className="col2">
                            {NumberInput({
                                label: "Tile Corner Radius",
                                ...ffToNumberInput(form.field(["tileCornerRadius"])),
                                unit: "pixels",
                            })}
                        </div>
                    </div>

                    {LpColorLine({
                        label: "Border Color",
                        ...ffToColorPickerLine(form.field(["tileBorderColorRGB"])),
                    })}

                    {LpShowHideLine({
                        label: "Tile Shadow",
                        ...ffToShowHideLine(form.field(["tileShadow"])),
                    })}
                </div>
            ),
        }}
    />
);
