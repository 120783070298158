import React from "react";
import {cs} from "@common/react/chain-services";
import {keyed} from "@common/react/keyed";
import {scope} from "@common/react/scope";
import {TextInput} from "../../../../../../common/form/text-input/text-input";
import {TrashIconBold} from "../../../common/icons/trash-icon";
import {removeIndex} from "@common/utils/collections";
import {Checkbox} from "@common/ui-components/form/checkbox/checkbox";
import {UseState} from "@common/react/use-state";

export const OutboundKeyValueInputs = ({labels, otherFields, state, placeholders}) =>
    cs(
        [
            "adding",
            (_, next) =>
                UseState({
                    initValue: {
                        isAdding: !state?.value || state.value?.length === 0,
                    },
                    next,
                }),
        ],
        ({adding}) => {
            const existing = state?.value && state.value?.length > 0;

            return (
                <div className="key-value-input key-value-input-9dg">
                    <table>
                        <thead>
                            <tr className="headers">
                                <th key="key-header">{labels?.key || "Key"}</th>
                                <th key="value-header">{labels?.value || "Value"}</th>
                                {(otherFields || [])?.map((f, i) => {
                                    return (
                                        <th key={i} className={f.className}>
                                            {f.label}
                                            {f.tooltip ?? null}
                                        </th>
                                    );
                                })}
                                <th className="actions-col" />
                            </tr>
                        </thead>
                        <tbody>
                            {state?.value &&
                                (state.value || [])?.map((item, i) => (
                                    <tr key={i}>
                                        <td key="key-column">
                                            {TextInput({
                                                placeholder: placeholders?.key || "Key",
                                                state: scope(state, [i, "key"]),
                                                autoFocus: true,
                                            })}
                                        </td>
                                        <td key="value-column" className="value-col">
                                            {TextInput({
                                                placeholder: placeholders?.value || "Value",
                                                state: scope(state, [i, "value"]),
                                            })}
                                        </td>
                                        {(otherFields || [])?.map((f, ci) => (
                                            <td key={ci} className={f.className}>
                                                {f.field({
                                                    state: scope(state, [i, f.key]),
                                                })}
                                            </td>
                                        ))}
                                        <td className="actions-col">
                                            <div className="delete-btn" onClick={() => state.change((oldState) => removeIndex(i, oldState))}>
                                                {TrashIconBold()}
                                            </div>

                                            {state.value?.length > 0 && state.value?.length === i + 1 && (
                                                <div className="add-btn" onClick={() => state.change((oldState) => [...oldState, {}])}>
                                                    <span className="material-icons">add</span>
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            {!existing &&
                                (() => {
                                    return (
                                        <tr>
                                            <td key="key-column">
                                                {LocalStateTextInput({
                                                    placeholder: placeholders?.key || "Key",
                                                    state: {
                                                        value: state.value?.[0]?.key,
                                                        onChange: (v) =>
                                                            state.change((s) => [
                                                                {
                                                                    ...(s?.[0] ?? {}),
                                                                    key: v,
                                                                },
                                                            ]),
                                                    },
                                                })}
                                            </td>
                                            <td key="value-column" className="value-col">
                                                {LocalStateTextInput({
                                                    placeholder: placeholders?.value || "Value",
                                                    state: {
                                                        value: state.value?.[0]?.value,
                                                        onChange: (v) =>
                                                            state.change((s) => [
                                                                {
                                                                    ...(s?.[0] ?? {}),
                                                                    value: v,
                                                                },
                                                            ]),
                                                    },
                                                })}
                                            </td>
                                            {(otherFields || [])?.map((f, ci) => (
                                                <td key={ci} className={f.className}>
                                                    {f.field({
                                                        state: {
                                                            value: state.value?.[0]?.[f.key],
                                                            onChange: (v) =>
                                                                state.change((s) => [
                                                                    {
                                                                        ...(s?.[0] ?? {}),
                                                                        [f.key]: v,
                                                                    },
                                                                ]),
                                                        },
                                                    })}
                                                </td>
                                            ))}
                                            <td className="actions-col">
                                                {existing && (
                                                    <div className="delete-btn" onClick={() => state.change((oldState) => removeIndex(0, oldState))}>
                                                        {TrashIconBold()}
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })()}
                            <tr>
                                <td />
                                <td />
                                {(otherFields || [])?.map((_, i) => (
                                    <td key={i} />
                                ))}
                                <td />
                            </tr>
                        </tbody>
                    </table>
                </div>
            );
        }
    );

const LocalStateTextInput = ({state, key, ...props}) =>
    cs(
        keyed(key),
        [
            "localState",
            (_, next) =>
                UseState({
                    initValue: state.value,
                    next,
                }),
        ],
        ({localState}) =>
            TextInput({
                state: {
                    value: localState.value,
                    onChange: localState.onChange,
                },
                onBlur: () => {
                    const v = localState.value;
                    state.onChange(v);
                },
                ...props,
            })
    );

export const OutboundApiInputs2 = ({field}) => {
    return OutboundKeyValueInputs({
        state: field,
        labels: {
            key: "Name",
            value: "Value",
        },
        placeholders: {
            key: "Name",
            value: "Value",
        },
        objectMapping: {
            value: "value",
        },
        otherFields: [
            {
                label: "Iterate",
                key: "iterate",
                className: "iterate",
                field: ({state}) => Checkbox({state}),
            },
            {
                label: "Secret",
                className: "secret",
                key: "secret",
                field: ({state}) => Checkbox({state}),
            },
        ],
    });
};
