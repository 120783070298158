import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {getNumberAggregationFuncs} from "../../../common/aggregation-funcs/number-aggregation-funcs";
import {stateToSelect} from "@common/ui-components/form/state-to-select";

export const NumberAggregationFunctionSelect = ({state, label, notAllowNoneAggregation, customListAgg, ...props}) =>
    DropdownSelect({
        label: label || "Aggregate",
        list: customListAgg?.() ?? getNumberAggregationFuncs({notAllowNone: notAllowNoneAggregation}),
        valueToLabel: (v) => v.label,
        ...stateToSelect(state, ["value"]),
        ...props,
    });
