import {cs} from "@common/react/chain-services";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import * as React from "react";
import "./default-filter-criteria.scss";
import {comparisonMethods} from "@common/ui-components/live/filters/text-filter/comparison-methods";
import {ffToDropdown} from "../../../../../../../../../../common/form/ff-to-dropdown";
import {TextInput} from "../../../../../../../../../../common/form/text-input/text-input";
import {ffToTextInput} from "../../../../../../../../../../common/form/ff-to-text-input";

export const DefaultFilterCriteria = ({filter, form}) =>
    cs(({}) => {
        const currentType = filter.value?.defaultOperator && comparisonMethods.find((m) => m.name === filter.value?.defaultOperator);

        return (
            <div className="default-filter-criteria default-filter-criteria-3ss">
                <div className="label">
                    Default Filter Criteria <span>(Optional)</span>
                </div>

                <div className="left">
                    {DropdownSelect({
                        label: "Comparison Method",
                        list: comparisonMethods.filter((cm) => (filter.value?.comparisonMethods || "Contains")?.includes(cm.name)),
                        valueToLabel: (m) => m.label,
                        ...ffToDropdown(form.field("defaultOperator"), ["name"]),
                    })}
                </div>

                {currentType?.hasDefaultValue && (
                    <div className="right">
                        {TextInput({
                            label: "Default Value",
                            ...ffToTextInput(form.field("defaultFreeFormValue")),
                        })}
                    </div>
                )}
            </div>
        );
    });
