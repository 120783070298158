const {createNumericFormatter} = require("../../common/formatters/numeric/numeric-formatter");
const {currencies} = require("./currencies");

const getNumericFormatter = ({numericProperties, autoRound, useDefaultCountFormat}) => {
    const currencyOverrides = numericProperties?.currencyOverrides
        ? {
              currency: numericProperties?.currencyOverrides.displayIso,
              currencySymbol: currencies.find((c) => c.abbreviation === numericProperties?.currencyOverrides.displayIso)?.symbol,
          }
        : null;

    return createNumericFormatter(
        {
            displayType: numericProperties?.displayType || "Number",

            decimalSeperator: numericProperties?.decimalSeperator || "Dot",
            thousandsSeperator: numericProperties?.thousandsSeperator || "Comma",
            decimalPlaces: numericProperties?.decimalPlaces ?? (useDefaultCountFormat ? 0 : 2),

            negativeFormat: numericProperties?.negativeFormat || "Parentheses",

            currencyProperties: {
                ...(currencyOverrides
                    ? currencyOverrides
                    : {
                          currency: numericProperties?.currencyProperties?.currency || "USD",
                          currencySymbol: numericProperties?.currencyProperties?.currencySymbol || "$",
                      }),
                currencySymbolPlacement: numericProperties?.currencyProperties?.currencySymbolPlacement || "Left",
                spaceBetweenSymbolAndNumber: numericProperties?.currencyProperties?.spaceBetweenSymbolAndNumber,
            },
        },
        {autoRound}
    );
};
exports.getNumericFormatter = getNumericFormatter;
