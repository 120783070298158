import React from "react";
import {GhostButton} from "../../../../../../../../../common/form/buttons/ghost-button/ghost-button";
import {spc} from "@common/react/state-path-change";
import {DropdownSelect2} from "@common/ui-components/dropdown-select/dropdown-select2";
import {stateToSelect} from "@common/ui-components/form/state-to-select";
import {PlusIcon} from "@common/ui-components/icons/global-icons";
import {SelectColumns} from "../../../../common/select-columns/select-columns";
import "./change-column-type-configuration.scss";

export const ChangeColumnTypeConfiguration = ({transformation, step, form, model}) => {
    const prevStep = transformation.value.steps.find((s) => s.id === step.value.inputStepID);

    return (
        <div className="change-column-type-configurations-tg4">
            <div className="config-group">
                <div className="label">Select Columns to change</div>

                {SelectColumns({
                    step,
                    form,
                    path: ["changeColumns"],
                    columns: prevStep.outputColumns,
                    objectForm: {
                        columnProp: "column",
                        propertySelector: ({state, ...props}) =>
                            DropdownSelect2({
                                label: "Data Type",
                                list: [
                                    {label: "Text", value: "Text"},
                                    {
                                        label: "Integer (Whole Number)",
                                        value: "Int",
                                    },
                                    {label: "Decimal", value: "Double"},
                                    {label: "Date & Time", value: "DateTime"},
                                    {label: "Boolean", value: "Bool"},
                                ],
                                valueToLabel: (v) => v.label,
                                ...stateToSelect(state, ["value"]),
                                ...props,
                            }),
                        propertyProp: "dataType",
                    },
                })}

                <GhostButton
                    iconLeft={<PlusIcon />}
                    className="btn-add-all"
                    onClick={() =>
                        spc(step, ["changeColumns"], (old) => {
                            const _changeColumns = old.map((o) => o.column);
                            const otherColumns = prevStep.outputColumns.filter((column) => _changeColumns.findIndex((cc) => cc?.modelColumnID === column?.modelColumnID) === -1);
                            return [
                                ...old,
                                ...otherColumns.map((oC) => ({
                                    column: oC,
                                    dataType: "Text",
                                    renameTo: null,
                                })),
                            ];
                        })
                    }
                >
                    Add All Columns
                </GhostButton>

                <div className="info">
                    <ul>
                        <li>
                            <b>Date & Time</b> must be in one of the following formats:
                            <ul>
                                <li>
                                    <code>dd MMM yyyy HH:mm:ss.ff</code>
                                    <ul>
                                        <li>17 Dec 2022 20:42:32.23342</li>
                                    </ul>
                                </li>
                                <li>
                                    <code>MM/dd/yyyy HH:mm:ss.ff</code>
                                    <ul>
                                        <li>12/17/2022 20:42:32.23342</li>
                                    </ul>
                                </li>
                                <li>
                                    <code>yyyy.MM.dd HH:mm:ss.ff</code>
                                    <ul>
                                        <li>2022-12-17 20:42:32.23342</li>
                                        <li>2022-12-17T20:42:32.23342</li>
                                    </ul>
                                </li>
                                <li>
                                    <i>The date separator can be ".", "-" or "/"</i>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <b>Integer</b> or <b>Decimal</b> must be in one of the following formats:
                            <ul>
                                <li>
                                    <code>#.#</code>
                                    <ul>
                                        <li>
                                            1656 <i>or</i> 1656.5689 <i>or</i> 1,656.5689
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <code>-#.#</code>
                                    <ul>
                                        <li>
                                            -1656 <i>or</i> -1656.5689 <i>or</i> -1,656.5689
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <code>(#.#)</code>
                                    <ul>
                                        <li>
                                            (1656) <i>or</i> (1656.5689) <i>or</i> (1,656.5689)
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <i>The decimal separator must be a period (.)</i>
                                </li>
                                <li>
                                    <i>The thousands separator must be a comma (,)</i>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <b>Boolean</b> must be one of the following values (case insensitive):
                            <ul>
                                <li>True / Yes / 1</li>
                                <li>False / No / 0</li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};
