const fixedOffset = 5;

export function applyFixedOverride(H) {
    function applyFixed() {
        let fixedRenderer, scrollableWidth, scrollableHeight;
        const firstTime = !this.fixedDiv,
            chartOptions = this.options.chart,
            scrollableOptions = chartOptions.scrollablePlotArea;

        // First render
        if (firstTime) {
            this.fixedDiv = document.createElement("div");
            H.extend(this.fixedDiv, {className: "highcharts-fixed"});
            H.css(this.fixedDiv, {
                position: "absolute",
                overflow: "hidden",
                pointerEvents: "none",
                zIndex: ((chartOptions.style && chartOptions.style.zIndex) || 0) + 2,
                top: 0,
            });

            if (this.scrollingContainer) {
                this.scrollingContainer.parentNode.insertBefore(this.fixedDiv, this.scrollingContainer);
            }
            this.renderTo.style.overflow = "visible";

            this.fixedRenderer = fixedRenderer = new H.Renderer(this.fixedDiv, this.chartWidth + 2, this.chartHeight, this.options.chart.style);
            // Mask
            this.scrollableMask = fixedRenderer
                .path()
                .attr({
                    fill: this.options.chart.backgroundColor || "#fff",
                    "fill-opacity": scrollableOptions.opacity || 0.85,
                    zIndex: -1,
                })
                .addClass("highcharts-scrollable-mask")
                .add();

            H.addEvent(this, "afterShowResetZoom", this.moveFixedElements);
            H.addEvent(this, "afterDrilldown", this.moveFixedElements);
            H.addEvent(this, "afterLayOutTitles", this.moveFixedElements);
        } else {
            // Set the size of the fixed renderer to the visible width
            this.fixedRenderer.setSize(this.chartWidth, this.chartHeight);
        }

        this.moveFixedElements();

        // Increase the size of the scrollable renderer and background
        scrollableWidth = this.chartWidth + 1 + (this.scrollablePixelsX || 0);
        scrollableHeight = this.chartHeight + (this.scrollablePixelsY || 0);
        H.stop(this.container);
        this.container.style.width = scrollableWidth + "px";
        this.container.style.height = scrollableHeight + "px";
        this.renderer.boxWrapper.attr({
            width: scrollableWidth,
            height: scrollableHeight,
            viewBox: [0, 0, scrollableWidth, scrollableHeight].join(" "),
        });
        this.chartBackground.attr({
            width: scrollableWidth,
            height: scrollableHeight,
        });

        this.scrollingContainer.style.height = this.chartHeight + "px";

        // Set scroll position
        if (firstTime) {
            if (scrollableOptions.scrollPositionX) {
                this.scrollingContainer.scrollLeft = this.scrollablePixelsX * scrollableOptions.scrollPositionX;
            }
            if (scrollableOptions.scrollPositionY) {
                this.scrollingContainer.scrollTop = this.scrollablePixelsY * scrollableOptions.scrollPositionY;
            }
        }

        // Mask behind the left and right side
        const axisOffset = this.axisOffset,
            maskTop = this.plotTop - axisOffset[0] - 1,
            maskLeft = this.plotLeft - axisOffset[3] - 1,
            maskBottom = this.plotTop + this.plotHeight + axisOffset[2] + 1,
            maskRight = this.plotLeft + this.plotWidth + axisOffset[1] + 1, // - fixedOffset,
            maskPlotRight = this.plotLeft + this.plotWidth - (this.scrollablePixelsX || 0),
            maskPlotBottom = this.plotTop + this.plotHeight - (this.scrollablePixelsY || 0);
        let d;

        if (this.scrollablePixelsX) {
            d = [
                // Left side
                ["M", 0, maskTop],
                ["L", this.plotLeft - 1, maskTop],
                ["L", this.plotLeft - 1, maskBottom],
                ["L", 0, maskBottom],
                ["Z"],

                // Right side
                ["M", maskPlotRight, maskTop],
                ["L", this.chartWidth + 2, maskTop],
                ["L", this.chartWidth + 2, maskBottom],
                ["L", maskPlotRight, maskBottom],
                ["Z"],
            ];
        } else if (this.scrollablePixelsY) {
            d = [
                // Top side
                ["M", maskLeft, 0],
                ["L", maskLeft, this.plotTop - 1],
                ["L", maskRight, this.plotTop - 1],
                ["L", maskRight, 0],
                ["Z"],

                // Bottom side
                ["M", maskLeft, maskPlotBottom],
                ["L", maskLeft, this.chartHeight],
                ["L", maskRight, this.chartHeight],
                ["L", maskRight, maskPlotBottom],
                ["Z"],
            ];
        } else {
            d = [["M", 0, 0]];
        }

        if (this.redrawTrigger !== "adjustHeight") {
            this.scrollableMask.attr({d});
        }
    }

    H.Chart.prototype.applyFixed = applyFixed;
}
