module.exports = {
    view: [
        "Verb.Web/web-client/src/routes/common/icons/data-view-icon.svg",
        "Verb.Web/web-client/src/routes/model/transformation/common/icons/new-view-icon.svg",
        "Verb.Web/web-client/src/routes/model/edit/tabs/left-panel-overrides/suggest-relationship/data-view-icon.svg",
        "Verb.Web/web-client/src/routes/model/transformation/common/icons/icons.jsx/NewViewIcon",
        "Verb.Web/web-client/src/routes/model/transformation/common/icons/icons.jsx/DataViewIcon",
    ],
    info: ["Verb.Web/web-client/src/routes/common/icons/info-icon-full.svg", "Verb.Web/web-client/src/routes/common/icons/info-icon.svg", "Verb.Web/web-client/src/routes/common/icons/info-light.svg", "Verb.Web/common/ui-components/charts/common/icons/icons.jsx/InfoIconFull"],
    limit: [
        "Verb.Web/web-client/src/routes/collection/tile/edit/left-panel/tabs/fields-tab/chart-types/common/configure-field/limit-old/lower-limit-icon.svg",
        "Verb.Web/web-client/src/routes/collection/tile/edit/left-panel/tabs/fields-tab/chart-types/common/configure-field/limit-old/upper-limit-icon.svg",
        "Verb.Web/web-client/src/routes/collection/tile/edit/left-panel/tabs/fields-tab/chart-types/common/limit-sorting/limit/lower-limit-icon.svg",
        "Verb.Web/web-client/src/routes/collection/tile/edit/left-panel/tabs/fields-tab/chart-types/common/limit-sorting/limit/upper-limit-icon.svg",
    ],
    create: ["Verb.Web/web-client/src/routes/model/edit/tabs/data/data-menu/more-action/icons/create-relationship.svg", "Verb.Web/web-client/src/routes/model/edit/main/controls/more-controls/icons/more-control-icons.jsx/CreateRelationshipIcon"],
    // "highlight", "shortest"
    paths: ["Verb.Web/web-client/src/routes/model/edit/main/controls/more-controls/icons/more-control-icons.jsx/HighlightShortestPathsIcon"],
    suggest: ["Verb.Web/web-client/src/routes/model/edit/tabs/left-panel-overrides/create-relationship/suggest-icon.svg", "Verb.Web/web-client/src/routes/model/edit/main/controls/more-controls/icons/more-control-icons.jsx/SuggestRelationshipIcon"],
    // "delete", "remove" not including "duplicates"
    trash: [
        "Verb.Web/web-client/src/routes/common/icons/trash.svg",
        "Verb.Web/web-client/src/routes/common/icons/trash-icon.jsx/TrashIcon",
        "Verb.Web/web-client/src/routes/common/icons/trash-icon.jsx/TrashIconBold",
        "Verb.Web/web-client/src/routes/common/icons/trash-icon.jsx/TrashIconBlue",
        "Verb.Web/web-client/src/routes/collection/common/dnd-grid-panel/tiles/icons/icon-menu-delete.svg",
        "Verb.Web/web-client/src/routes/model/edit/main/controls/more-controls/icons/more-control-icons.jsx/DeleteIcon",
        "Verb.Web/web-client/src/routes/model/edit/main/controls/more-controls/icons/more-control-icons.jsx/RemoveIcon",
        "Verb.Web/web-client/src/routes/model/edit/tabs/data/data-menu/more-action/icons/remove-model.svg",
    ],
    // "remove" with "duplicates"
    remove: ["Verb.Web/web-client/src/routes/model/transformation/common/icons/remove-duplicates-icon.svg", "Verb.Web/web-client/src/routes/model/transformation/common/icons/icons.jsx/RemoveDuplicatesIcon"],
    close: [
        "Verb.Web/common/form/common/close-black-icon.svg",
        "Verb.Web/web-client/src/routes/common/icons/close-icon.svg",
        "Verb.Web/web-client/src/routes/common/icons/close-icon2.svg",
        "Verb.Web/web-client/src/routes/common/toast/close-24px.svg",
        "Verb.Web/web-client/src/routes/common/icons/close-icon.jsx/CloseIcon",
        "Verb.Web/web-client/src/routes/collection/common/dnd-grid-panel/tiles/controls/select-collection-dialog/icons/close-x-icon.svg",
    ],
    // not include "kpi", "chart"
    table: [
        "Verb.Web/web-client/src/routes/common/icons/data-source-table-icon.svg",
        "Verb.Web/web-client/src/routes/common/icons/table-icon.svg",
        "Verb.Web/web-client/src/routes/data-source/common/icons.jsx/TableIcon",
        "Verb.Web/web-client/src/routes/model/transformation/common/icons/model-table-icon.svg",
        "Verb.Web/web-client/src/routes/model/edit/tabs/left-panel-overrides/suggest-relationship/table-icon.svg",
        "Verb.Web/web-client/src/routes/model/transformation/common/icons/icons.jsx/ModelTableIcon",
    ],
    asc: [
        "Verb.Web/web-client/src/routes/collection/tile/edit/left-panel/tabs/fields-tab/chart-types/common/configure-field/sort-by-old/icons/sort-date-asc-icon.svg",
        "Verb.Web/web-client/src/routes/collection/tile/edit/left-panel/tabs/fields-tab/chart-types/common/configure-field/sort-by-old/icons/sort-number-asc-icon.svg",
        "Verb.Web/web-client/src/routes/collection/tile/edit/left-panel/tabs/fields-tab/chart-types/common/configure-field/sort-by-old/icons/sort-text-asc-icon.svg",
        "Verb.Web/web-client/src/routes/collection/tile/edit/left-panel/tabs/fields-tab/chart-types/common/limit-sorting/sorting/icons/sort-boolean-asc-icon.svg",
        "Verb.Web/web-client/src/routes/collection/tile/edit/left-panel/tabs/fields-tab/chart-types/common/limit-sorting/sorting/icons/sort-date-asc-icon.svg",
        "Verb.Web/web-client/src/routes/collection/tile/edit/left-panel/tabs/fields-tab/chart-types/common/limit-sorting/sorting/icons/sort-number-asc-icon.svg",
        "Verb.Web/web-client/src/routes/collection/tile/edit/left-panel/tabs/fields-tab/chart-types/common/limit-sorting/sorting/icons/sort-text-asc-icon.svg",
    ],
    desc: [
        "Verb.Web/web-client/src/routes/collection/tile/edit/left-panel/tabs/fields-tab/chart-types/common/configure-field/sort-by-old/icons/sort-date-desc-icon.svg",
        "Verb.Web/web-client/src/routes/collection/tile/edit/left-panel/tabs/fields-tab/chart-types/common/configure-field/sort-by-old/icons/sort-number-desc-icon.svg",
        "Verb.Web/web-client/src/routes/collection/tile/edit/left-panel/tabs/fields-tab/chart-types/common/configure-field/sort-by-old/icons/sort-text-desc-icon.svg",
        "Verb.Web/web-client/src/routes/collection/tile/edit/left-panel/tabs/fields-tab/chart-types/common/limit-sorting/sorting/icons/sort-boolean-desc-icon.svg",
        "Verb.Web/web-client/src/routes/collection/tile/edit/left-panel/tabs/fields-tab/chart-types/common/limit-sorting/sorting/icons/sort-date-desc-icon.svg",
        "Verb.Web/web-client/src/routes/collection/tile/edit/left-panel/tabs/fields-tab/chart-types/common/limit-sorting/sorting/icons/sort-number-desc-icon.svg",
        "Verb.Web/web-client/src/routes/collection/tile/edit/left-panel/tabs/fields-tab/chart-types/common/limit-sorting/sorting/icons/sort-text-desc-icon.svg",
    ],
    // not include "asc", "desc"
    text: [
        "Verb.Web/web-client/src/routes/common/field-type-icon/field-type-icons/text-uuids-icon.svg",
        "Verb.Web/web-client/src/routes/collection/common/filters/icons/text-icon.svg",
        "Verb.Web/web-client/src/routes/collection/common/filters/icons/text-uuids-icon.svg",
        "Verb.Web/web-client/src/routes/model/transformation/common/icons/text-manipulation-icon.svg",
        "Verb.Web/web-client/src/routes/model/transformation/common/icons/icons.jsx/TextManipulationIcon",
    ],
    // not include "asc", "desc"
    boolean: [
        "Verb.Web/web-client/src/routes/common/field-type-icon/field-type-icons/boolean-icon.svg",
        "Verb.Web/web-client/src/routes/collection/common/filters/icons/boolean-icon.svg",
        "Verb.Web/web-client/src/routes/collection/tile/edit/left-panel/tabs/fields-tab/chart-types/common/limit-sorting/sorting/icons/sort-boolean-asc-icon.svg",
        "Verb.Web/web-client/src/routes/collection/tile/edit/left-panel/tabs/fields-tab/chart-types/common/limit-sorting/sorting/icons/sort-boolean-desc-icon.svg",
    ],
    // not include "asc", "desc"
    number: ["Verb.Web/web-client/src/routes/common/field-type-icon/field-type-icons/number-icon.svg", "Verb.Web/web-client/src/routes/collection/common/filters/icons/number-icon.svg"],
    // not include "asc", "desc"
    date: [
        "Verb.Web/web-client/src/routes/common/field-type-icon/field-type-icons/date-icon.svg",
        "Verb.Web/web-client/src/routes/common/field-type-icon/field-type-icons/date-time-offset-icon.svg",
        "Verb.Web/web-client/src/routes/collection/common/filters/icons/date-range-icon.svg",
        "Verb.Web/web-client/src/routes/collection/common/filters/icons/material-date-range-icon.svg",
        "Verb.Web/web-client/src/routes/model/transformation/common/icons/date-operation-icon.svg",
        "Verb.Web/web-client/src/routes/contextual-collection/edit/tabs/filter/local-filters/icons/material-date-range-icon.svg",
        "Verb.Web/web-client/src/routes/model/transformation/common/icons/icons.jsx/DateOperationIcon",
        "Verb.Web/web-client/src/routes/collection/tile/edit/left-panel/tabs/filter-tab/local-filters/icons/material-date-range-icon.svg",
    ],
    check: [
        "Verb.Web/common/ui-components/form/checkbox/check-box.svg",
        "Verb.Web/web-client/src/routes/common/icons/blue-check-icon.svg",
        "Verb.Web/web-client/src/routes/common/icons/check-icon-black.svg",
        "Verb.Web/web-client/src/routes/common/icons/check-icon-green.svg",
        "Verb.Web/web-client/src/routes/common/icons/check-icon.svg",
        "Verb.Web/web-client/src/routes/common/icons/green-check-icon.svg",
        "Verb.Web/web-client/src/routes/common/toast/check-icon.svg",
        "Verb.Web/web-client/src/routes/model/edit/main/tutorial/images/check-icon.svg",
        "Verb.Web/web-client/src/routes/model/edit/tabs/left-panel-overrides/suggest-relationship/check-icon.svg",
        "Verb.Web/web-client/src/routes/collection/common/dnd-grid-panel/tiles/controls/select-collection-dialog/icons/check-icon.svg",
    ],
    // not include "remove"
    model: [
        "Verb.Web/web-client/src/routes/common/header-bar/icons/model.svg",
        "Verb.Web/web-client/src/routes/dashboard/env/models/model-icon.svg",
        "Verb.Web/web-client/src/routes/dashboard/env/overview/icons/model.svg",
        "Verb.Web/web-client/src/routes/model/transformation/common/icons/model-table-icon.svg",
        "Verb.Web/web-client/src/routes/dashboard/env/collections/collection-cm/clone-collection/circle-model-icon.svg",
        "Verb.Web/web-client/src/routes/model/transformation/common/icons/icons.jsx/ModelTableIcon",
    ],
    filter: [
        "Verb.Web/common/ui-components/charts/common/icons/local-filter-icon.svg",
        "Verb.Web/common/ui-components/live/live-dashboard/live-filters/layout/hidden-filter-icon.svg",
        "Verb.Web/web-client/src/routes/data-source/common/icons.jsx/FilterIcon",
        "Verb.Web/web-client/src/routes/model/transformation/common/icons/filter-data-icon.svg",
        "Verb.Web/web-client/src/routes/model/transformation/common/icons/icons.jsx/FilterDataIcon",
    ],
    arrow: [
        "Verb.Web/common/ui-components/charts/common/icons/icon-arrow-circle-down.svg",
        "Verb.Web/common/ui-components/charts/common/icons/icon-arrow-circle-up.svg",
        "Verb.Web/common/ui-components/charts/table-kpi/icons/arrow-down-solid.svg",
        "Verb.Web/common/ui-components/charts/table-kpi/icons/arrow-up-solid.svg",
        "Verb.Web/web-client/src/routes/common/map-model-fields/mapping-arrow.svg",
        "Verb.Web/web-client/src/routes/common/header-bar/icons/arrow-down.svg",
        "Verb.Web/web-client/src/routes/getting-started/gs-step/icons/arrow-icon.svg",
        "Verb.Web/common/ui-components/charts/common/icons/icons.jsx/ArrowChervonDown",
        "Verb.Web/common/ui-components/charts/common/icons/icons.jsx/ArrowChervonUp",
        "Verb.Web/web-client/src/routes/collection/preview/collection-preview-icons.jsx/ArrowDownIcon",
        "Verb.Web/web-client/src/routes/dashboard/env/overview/icons/next-arrow.svg",
        "Verb.Web/web-client/src/routes/model/edit/main/create-new-relationship/arrow-icon.svg",
        "Verb.Web/web-client/src/routes/collection/common/dnd-grid-panel/tiles/controls/select-collection-dialog/icons/back-arrow.svg",
    ],
    // not include "bottom", "join"
    right: ["Verb.Web/web-client/src/routes/common/icons/chevron-right-icon.svg", "Verb.Web/web-client/src/routes/common/icons/chevron-thin-right.svg"],
    refresh: ["Verb.Web/web-client/src/routes/common/icons/refresh-icon.svg", "Verb.Web/web-client/src/routes/collection/preview/collection-preview-icons.jsx/RefreshIcon", "Verb.Web/web-client/src/routes/model/common/zoom-controls/icons/refresh.svg"],
    edit: [
        "Verb.Web/web-client/src/routes/account-management/personal/edit-24px.svg",
        "Verb.Web/web-client/src/routes/model/transformation/common/icons/edit-icon.svg",
        "Verb.Web/web-client/src/routes/collection/common/dnd-grid-panel/tiles/icons/icon-menu-edit.svg",
        "Verb.Web/web-client/src/routes/model/edit/tabs/common/column-line/column-line-actions/edit-column-icon.svg",
        "Verb.Web/web-client/src/routes/model/edit/tabs/data/data-menu/more-action/icons/edit-icon.svg",
        "Verb.Web/web-client/src/routes/model/edit/main/controls/more-controls/icons/more-control-icons.jsx/EditIcon",
    ],
    exclamation: [
        "Verb.Web/web-client/src/routes/common/icons/exclamation-icon.svg",
        "Verb.Web/web-client/src/routes/common/layout2/icons/alert-exclamation-icon.svg",
        "Verb.Web/web-client/src/routes/data-source/add/wizard/steps/tables-columns/wizard/steps/tables/choose-interval/exclamation-solid.svg",
    ],
    more: [
        "Verb.Web/web-client/src/routes/common/icons/more-horz-icon.svg",
        "Verb.Web/web-client/src/routes/collection/common/dnd-grid-panel/tiles/icons/icon-menu-more.svg",
        "Verb.Web/web-client/src/routes/model/edit/tabs/common/column-line/more-option.svg",
        "Verb.Web/web-client/src/routes/model/edit/tabs/data/data-menu/more-option.svg",
    ],
    ellipsis: ["Verb.Web/web-client/src/routes/common/popup-menu/ellipsis-v-icon.svg", "Verb.Web/common/ui-components/charts/common/icons/icons.jsx/EllipsisVAlt", "Verb.Web/web-client/src/routes/collection/common/dnd-grid-panel/tiles/icons/ellipsis-v-icon-svg.jsx/EllipsisIconSvg"],
    alert: ["Verb.Web/web-client/src/routes/data-source/edit/alert-icon.svg", "Verb.Web/web-client/src/routes/common/layout2/icons/alert-exclamation-icon.svg", "Verb.Web/web-client/src/routes/model/transformation/main/right-panel/append-rows/alert-octagon.svg"],
    // not include "manage"
    org: ["Verb.Web/web-client/src/routes/common/header-bar/icons/org.svg", "Verb.Web/web-client/src/routes/common/icons/menu/org-icon.svg"],
    redo: ["Verb.Web/web-client/src/routes/common/layout2/icons/redo-disabled-icon.svg", "Verb.Web/web-client/src/routes/common/layout2/icons/redo-icon.svg"],
    undo: ["Verb.Web/web-client/src/routes/common/layout2/icons/undo-disabled-icon.svg", "Verb.Web/web-client/src/routes/common/layout2/icons/undo-icon.svg"],
    security: [
        "Verb.Web/web-client/src/routes/model/edit/main/table/row-level-security-icon.svg",
        "Verb.Web/web-client/src/routes/model/edit/tabs/common/icons/blank-security-icon.svg",
        "Verb.Web/web-client/src/routes/model/edit/tabs/common/icons/error-security-icon.svg",
        "Verb.Web/web-client/src/routes/model/edit/tabs/common/icons/hide-security-icon.svg",
        "Verb.Web/web-client/src/routes/model/edit/tabs/common/icons/mask-security-icon.svg",
        "Verb.Web/web-client/src/routes/model/edit/main/controls/more-controls/icons/column-security-icon.svg",
        "Verb.Web/web-client/src/routes/model/edit/tabs/common/column-line/column-line-actions/row-level-security-icon.svg",
        "Verb.Web/web-client/src/routes/model/edit/tabs/common/icons/render-security-icon.jsx/rSecurityIcon",
        "Verb.Web/web-client/src/routes/model/edit/tabs/data/data-menu/more-action/icons/column-security.svg",
        "Verb.Web/web-client/src/routes/model/edit/tabs/data/data-menu/more-action/icons/row-security.svg",
        "Verb.Web/web-client/src/routes/model/edit/main/controls/more-controls/icons/more-control-icons.jsx/ColumnSecurityIcon",
        "Verb.Web/web-client/src/routes/model/edit/main/controls/more-controls/icons/more-control-icons.jsx/RowSecurity",
    ],
    // not include "plus"
    calculated: [
        "Verb.Web/web-client/src/routes/model/edit/main/table/calculated-plus-icon.svg",
        "Verb.Web/web-client/src/routes/model/edit/tabs/common/icons/calculated-plus-icon.svg",
        "Verb.Web/web-client/src/routes/model/edit/main/controls/more-controls/icons/add-calculated-column-icon.svg",
        "Verb.Web/web-client/src/routes/model/edit/tabs/data/data-menu/more-action/icons/add-calculated-column.svg",
        "Verb.Web/web-client/src/routes/model/edit/main/controls/more-controls/icons/more-control-icons.jsx/AddCalculatedColumnIcon",
    ],
    // "add-icon.svg"
    plus: [
        "Verb.Web/common/ui-components/icons/global-icons.jsx/PlusIcon",
        "Verb.Web/web-client/src/routes/model/edit/main/table/calculated-plus-icon.svg",
        "Verb.Web/web-client/src/routes/model/edit/tabs/common/icons/calculated-plus-icon.svg",
        "Verb.Web/web-client/src/routes/model/edit/tabs/left-panel-overrides/suggest-relationship/plus-icon.svg",
        "Verb.Web/web-client/src/routes/collection/common/dnd-grid-panel/tiles/controls/select-collection-dialog/icons/add-icon.svg",
    ],
    warning: ["Verb.Web/web-client/src/routes/collection/common/filters/icons/invalid-warning-icon.svg", "Verb.Web/web-client/src/routes/model/transformation/common/icons/warning-step-icon.svg", "Verb.Web/web-client/src/routes/collection/common/filters/filter-edit-main/connected-tiles/warning.svg"],
    tour: ["Verb.Web/common/ui-components/icons/global-icons.jsx/GuildTourIcon", "Verb.Web/web-client/src/routes/dashboard/env/overview/icons/guide-tour.svg"],
    play: ["Verb.Web/common/ui-components/icons/global-icons.jsx/PlayIcon", "Verb.Web/web-client/src/routes/dashboard/env/settings/authentication/play-icon.svg", "Verb.Web/web-client/src/routes/dashboard/env/settings/authentication/auth-test-dialog/white-play-icon.svg"],
    burger: ["Verb.Web/web-client/src/routes/common/header-bar/icons/burger-icon.svg", "Verb.Web/web-client/src/routes/common/header-bar/icons/menu-burger.svg"],
    error: ["Verb.Web/common/ui-components/charts/common/loading-wrapper/error-handler/data-error-icon.svg", "Verb.Web/web-client/src/routes/model/edit/tabs/common/icons/error-security-icon.svg"],
    logout: ["Verb.Web/web-client/src/routes/common/header-bar/icons/logout.svg", "Verb.Web/web-client/src/routes/common/icons/menu/logout-icon.svg"],
    search: ["Verb.Web/web-client/src/routes/collection/preview/collection-preview-icons.jsx/SearchIcon", "Verb.Web/web-client/src/routes/collection/common/dnd-grid-panel/tiles/controls/select-collection-dialog/icons/search.svg"],
    // "spark"
    contextual: [
        "Verb.Web/web-client/src/routes/dashboard/env/collections/icons/contextual-icon.svg",
        "Verb.Web/web-client/src/routes/getting-started/build-data/create-dashboards/icons/contextual-tile-icon.svg",
        "Verb.Web/web-client/src/routes/dashboard/env/collections/icons/spark-icon.svg",
        "Verb.Web/web-client/src/routes/dashboard/env/collections/collection-cm/create-collection/choose-type/spark-color-icon.svg",
    ],
    // not including "build"
    // not including "download", "downloadable"
    report: ["Verb.Web/web-client/src/routes/common/header-bar/icons/report-icon.svg", "Verb.Web/web-client/src/routes/dashboard/env/collections/icons/report-icon.svg", "Verb.Web/web-client/src/routes/dashboard/env/collections/collection-cm/create-collection/choose-type/report-color-icon.svg"],
};
