import * as React from "react";
import {css, cx} from "emotion";
import {LpLine} from "../../../../../../../../../../common/left-panel/line/lp-line";
import "./font-options-select.scss";
import {stateToCheckboxLineGroup} from "../../../../../../../../../../common/checkbox-line-group/state-to-checkbox-line-group";
import {stringListState} from "../../../../../../../../../../common/data-logic/string-list-state";
import {Button} from "../../../../../../../../../../../../../common/form/buttons/button/button";
import {ButtonIcon} from "../../../../../../../../../../../../../common/form/buttons/button-icon/button-icon";
import {BoldIcon, ItalicIcon, UnderlineIcon} from "./icons/font-style-icons";
import {stringListStateWithNoneValue} from "../../../../../../../../../../common/data-logic/string-list-state-with-none-value";
import {cs} from "@common/react/chain-services";
import {StyledClass} from "@common/react/styled-class";

export const FontOptionsSelect = ({label, state}) =>
    LpLine({
        label,
        control: FontOptions(stateToCheckboxLineGroup(stringListStateWithNoneValue(state), ["name"])),
    });

const FontOptions = ({isSelected, onChange}) =>
    cs(
        [
            "buttonClass",
            (_, next) =>
                StyledClass({
                    content: {
                        "&:hover": {
                            background: "#e8f6ff !important",
                            "border-color": "transparent !important",
                            color: " #919eb0 !important",
                        },
                    },
                    next,
                }),
        ],
        ({buttonClass}) => (
            <div className="font-options-24c">
                {fontOptions.map((fo, i) => (
                    <ButtonIcon
                        className={cx("font-option", !isSelected(fo) ? buttonClass : "")}
                        style={{
                            padding: "0 8px",
                        }}
                        btnType={isSelected(fo) ? "secondary" : "ghost"}
                        key={i}
                        onClick={() => onChange(fo)}
                        icon={fo.iconPath}
                    />
                ))}
            </div>
        )
    );

const fontOptions = [
    {
        name: "Bold",
        iconPath: <BoldIcon />,
    },
    {
        name: "Italics",
        iconPath: <ItalicIcon />,
    },
    {
        name: "Underline",
        iconPath: <UnderlineIcon />,
    },
];
