const {isMatchText} = require("@common/utils/strings");
const {SimpleSearchFactory} = require("@common/utils/simple-search");

const cGetSearchedTables = (searchVal) => (tables) => {
    if (!searchVal) {
        return tables;
    }

    return tables.reduce((resultTables, table) => {
        const result = isMatchText(table.visualName ?? table.name, searchVal, true);
        const tableCols = table.columns || [];
        const filteredColumns = cGetSearchedColumns(searchVal)(tableCols);

        if (!result && filteredColumns.length == 0) {
            return resultTables;
        }

        return resultTables.concat({
            ...table,
            highlights: result?.ranges,
            columns: filteredColumns.length > 0 ? filteredColumns : tableCols,
            numMatchedColumns: filteredColumns.length,
        });
    }, []);
};
exports.cGetSearchedTables = cGetSearchedTables;

const cGetSearchedColumns = (searchVal) => (columns) => {
    if (!searchVal) {
        return columns;
    }
    return columns?.reduce((resColumns, column) => {
        const result = isMatchText(column.visualName ?? column.name, searchVal, true);

        if (!result) {
            return resColumns;
        }

        return resColumns.concat({
            ...column,
            highlights: result?.ranges,
        });
    }, []);
};

exports.cGetSearchedColumns = cGetSearchedColumns;

const cGetSearchedTableWithID = (tableID) => (tables) => tableID ? tables.find((table) => table.id == tableID) : null;
exports.cGetSearchedTableWithID = cGetSearchedTableWithID;
