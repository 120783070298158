import "./chart-table.scss";

import * as React from "react";

import {keyed} from "@common/react/keyed";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {IgnoreUpdate} from "@common/react/ignore-update";
import {ObserveDomSize} from "@common/react/observe-dom-size";

import {chain} from "@common/utils/fs";

import {DataRows} from "./data-rows/data-rows";
import {TotalRow} from "./total-row/total-row";
import {Header} from "./header/header";
import {getColumns} from "./common/get-columns";

export const ChartTable = ({data, tileFields, tile, theme, loadingIndicator, domRef, totalRef, sort, search, actionMenu}) =>
    cs(
        consumeContext("selectedTimezone"),
        ({selectedTimezone}, next) =>
            IgnoreUpdate({
                next,
                props: {timezoneId: selectedTimezone?.value},
                when: ({timezoneId}) => timezoneId != selectedTimezone?.value,
            }),
        ["headerSize", (_, next) => ObserveDomSize({next})],
        ["columns", ({selectedTimezone}, next) => next(getColumns({tile, timezone: selectedTimezone.value}))],
        ({headerSize, columns}) => {
            let totalsShown =
                tile.style.totalsShown && Object.values(tile.style.columnSettings).filter((c) => !!c.showSummaryAggregation).length > 0;
            return (
                <div className="chart-table-1z2" ref={domRef}>
                    <table
                        style={{
                            // ...border,
                            borderCollapse: "collapse",
                            ...((tile.style.columnWidths === "EqualDist" || tile.style.columnWidths === "Custom") && {
                                tableLayout: "fixed",
                            }),
                            // ...(!tile.style.rowHeight && {height: "100%"}),
                        }}
                    >
                        {tile.style?.columnWidths === "Custom" && (
                            <colgroup>
                                {columns.map((column) => (
                                    <col span="1" style={{width: column.width}} />
                                ))}
                            </colgroup>
                        )}

                        {tile.style.headerShown &&
                            Header({
                                data,
                                tile,
                                theme,
                                columns,
                                tileFields,
                                domRef: headerSize.ref,
                                sort,
                                search,
                            })}

                        <tbody>
                            {data.rows.length == 0 && (
                                <tr className="loading-row">
                                    <td colSpan={columns.length}>No data</td>
                                </tr>
                            )}

                            {chain(
                                [
                                    [
                                        ...DataRows({
                                            data,
                                            tile,
                                            theme,
                                            columns,
                                            search,
                                            actionMenu,
                                            totalsShown,
                                        }),
                                        loadingIndicator &&
                                            cs(keyed("loading-row"), () => (
                                                <tr className="loading-row">
                                                    <td colSpan={columns.length}>{loadingIndicator.loading && "Loading..."}</td>
                                                </tr>
                                            )),
                                    ].filter((v) => v),
                                ],
                                (_) => _.map((e, i) => e && cs(keyed(i), () => e))
                            )}
                        </tbody>
                        {totalsShown && (
                            <tfoot ref={totalRef.set}>
                                {TotalRow({
                                    data,
                                    tile,
                                    theme,
                                    columns,
                                    headerSize: headerSize.value,
                                })}
                            </tfoot>
                        )}
                    </table>
                </div>
            );
        }
    );
