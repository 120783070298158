const getTextAggregationFuncs = ({notAllowNone} = {}) => (notAllowNone ? textAggregationFuncs.slice(1) : textAggregationFuncs);
exports.getTextAggregationFuncs = getTextAggregationFuncs;

const textAggregationFuncs = [
    {
        label: "None",
        value: "None",
    },
    {
        label: "Count",
        value: "Count",
    },
    {
        label: "Count Inc Null",
        value: "CountIncNull",
    },
    {
        label: "Count Distinct",
        value: "CountDistinct",
    },
    {
        label: "Count Distinct Inc Null",
        value: "CountDistinctIncNull",
    },
    {
        label: "Count Null",
        value: "CountNull",
    },
    {
        label: "First Value Asc",
        value: "FirstValueAsc",
    },
    {
        label: "First Value Desc",
        value: "FirstValueDesc",
    },
    {
        label: "Last Value Asc",
        value: "LastValueAsc",
    },
    {
        label: "Last Value Desc",
        value: "LastValueDesc",
    },
];
