import "./side-panel.scss";

import React from "react";
import {createPortal} from "react-dom";
import {cx} from "emotion";

import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {Static2} from "@common/react/static-2";
import {Invoke} from "@common/react/invoke";
import {OnUnmounted} from "@common/react/on-unmounted";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";

let CloseIcon = require("../../../../../common/form/common/close-black-icon.svg");

export const SidePanel = ({renderContent, renderFooter, onClose = () => {}, panelWidth = 600, renderToggle, title, className = "", next: rootNext}) =>
    cs(
        ["domRef", ({}, next) => Static2({next})],
        ["isOpen", (_, next) => UseState({next, initValue: false})],
        ({domRef, isOpen}, next) => (
            <>
                {Invoke({
                    fn: () => {
                        const elem = document.createElement("div");
                        document.body.append(elem);
                        domRef.set(elem);
                        isOpen.onChange(false);
                    },
                })}
                {OnUnmounted({
                    action: () => {
                        document.body.removeChild(domRef.get());
                    },
                })}
                {next()}
            </>
        ),
        ({isOpen, domRef}) => {
            const closePanel = async () => {
                await onClose?.();
                isOpen.onChange(false);
            };

            const openPanel = () => isOpen.onChange(true);

            const props = {
                isOpen: isOpen.value,
                close: closePanel,
                open: openPanel,
            };

            return rootNext({
                ...props,
                render: () =>
                    domRef.get() &&
                    createPortal(
                        <div className={className}>
                            <div
                                className={cx("side-panel-f35", isOpen.value && "open")}
                                style={{
                                    width: Math.min(panelWidth, window.innerWidth),
                                }}
                            >
                                {title && (
                                    <div className="side-panel-header">
                                        {title}

                                        <div className="btn-close" onClick={closePanel}>
                                            <img src={CloseIcon} alt="" />
                                        </div>
                                    </div>
                                )}
                                {renderToggle && renderToggle(props)}
                                <VerbScrollbar style={{height: "calc(100% - 165px)"}} className="side-panel-body">
                                    {renderContent &&
                                        renderContent({
                                            isOpen: isOpen.value,
                                            close: closePanel,
                                        })}
                                </VerbScrollbar>

                                {renderFooter && <div className="panel-footer">{renderFooter(props)}</div>}
                            </div>

                            {isOpen.value && <div className="side-panel-backdrop-vbv" onClick={closePanel} />}
                        </div>,
                        domRef.get()
                    ),
            });
        }
    );
