import {FieldSection} from "../../../../../../collection/tile/edit/left-panel/tabs/fields-tab/chart-types/common/field-section/field-section";
import {FieldInfoProvider} from "../../../../../../collection/tile/edit/left-panel/tabs/tile-tab/chart-types/common/field-info-provider/field-info-provider";
import React from "react";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import ReactDOM from "react-dom";
import {cx} from "emotion";
import {SuspendUpdate} from "@common/react/suspend-update";
import {LeftPanelClosable} from "../../../../../../common/left-panel-closable/left-panel-closable";
import {CategoricalTheme} from "./categorical-theme";
import {SequentialTheme} from "./sequential-theme";

export const ColorSchemes = ({form, overrideLeftPanelRef}) =>
    cs(
        ["selected", (_, next) => UseState({next, initValue: false})],
        ["panelOverride", (_, next) => UseState({next, initValue: null})],
        ({selected, panelOverride}) => {
            const categoricalState = form.field([
                "dataVisualization",
                "dataColorPalettes",
                "colorSchemes",
                "categoricalColorSchemes",
            ]).state;
            const sequentialState = form.field(["dataVisualization", "dataColorPalettes", "colorSchemes", "sequentialColorSchemes"]).state;

            const render = () => {
                if (panelOverride.value?.type == "categorical") {
                    return (
                        <CategoricalTheme
                            state={{
                                value: categoricalState.value[panelOverride.value.index].colorsRGB,
                                onChange: (value) =>
                                    categoricalState.onChange(
                                        categoricalState.value.map((_, index) => {
                                            if (index == panelOverride.value.index) return {colorsRGB: value};
                                            return _;
                                        })
                                    ),
                            }}
                            key={panelOverride.value.index}
                        />
                    );
                }

                return (
                    <SequentialTheme
                        state={{
                            value: sequentialState.value[panelOverride.value.index].colorsRGB,
                            onChange: (value) =>
                                sequentialState.onChange(
                                    sequentialState.value.map((_, index) => {
                                        if (index == panelOverride.value.index) return {colorsRGB: value};
                                        return _;
                                    })
                                ),
                        }}
                        key={panelOverride.value.index}
                    />
                );
            };

            return (
                <>
                    {FieldSection({
                        header: FieldInfoProvider({
                            field: <div className="text">CATEGORICAL</div>,
                            noCollapsible: true,
                        }),
                        content: (
                            <>
                                {categoricalState.value.map((section, index) => (
                                    <div
                                        className="section-item"
                                        key={index}
                                        onClick={() => {
                                            panelOverride.onChange({
                                                type: "categorical",
                                                index,
                                                label: `CATEGORICAL SCHEME ${index + 1}`,
                                            });

                                            // change to index of color applications
                                            form.data.change((f) => ({
                                                ...f,
                                                selectedColorPalettes: {
                                                    type: "Categorical",
                                                    index,
                                                },
                                            }));

                                            setTimeout(() => {
                                                selected.onChange(true);
                                            });
                                        }}
                                    >
                                        <div>
                                            CATEGORICAL SCHEME {index + 1}
                                            <div className="colors-list">
                                                {section.colorsRGB.map((color, index) => (
                                                    <div
                                                        className="color"
                                                        key={index}
                                                        style={{
                                                            background: color,
                                                        }}
                                                    />
                                                ))}
                                            </div>
                                        </div>

                                        <img src={require("../../../../common/icons/arrow-right.svg")} className="arrow-right" />
                                    </div>
                                ))}
                            </>
                        ),
                    })}

                    {FieldSection({
                        header: FieldInfoProvider({
                            field: <div className="text">SEQUENTIAL</div>,
                            noCollapsible: true,
                        }),
                        content: (
                            <>
                                {sequentialState.value.map((section, index) => (
                                    <div
                                        className="section-item"
                                        key={index}
                                        onClick={() => {
                                            panelOverride.onChange({
                                                type: "sequential",
                                                index,
                                                label: `SEQUENTIAL SCHEME ${index + 1}`,
                                            });

                                            // change to index of color applications
                                            form.data.change((f) => ({
                                                ...f,
                                                selectedColorPalettes: {
                                                    type: "Sequential",
                                                    index,
                                                },
                                            }));

                                            setTimeout(() => {
                                                selected.onChange(true);
                                            });
                                        }}
                                    >
                                        <div>
                                            SEQUENTIAL SCHEME {index + 1}
                                            <div
                                                className="color-gradient"
                                                key={index}
                                                style={{
                                                    background: `linear-gradient(to right, ${section.colorsRGB.join(",")})`,
                                                }}
                                            />
                                        </div>

                                        <img src={require("../../../../common/icons/arrow-right.svg")} className="arrow-right" />
                                    </div>
                                ))}
                            </>
                        ),
                    })}

                    {overrideLeftPanelRef.get?.() &&
                        ReactDOM.createPortal(
                            <div
                                className={cx("override", {
                                    active: selected.value,
                                })}
                                style={{}}
                            >
                                {cs(
                                    ({}, next) =>
                                        SuspendUpdate({
                                            active: selected.value,
                                            next,
                                        }),
                                    () =>
                                        LeftPanelClosable({
                                            onClose: () => selected.onChange(null),
                                            label: (panelOverride.value?.label || "").toUpperCase(),
                                            content: panelOverride.value && render(),
                                        })
                                )}
                            </div>,
                            overrideLeftPanelRef.get()
                        )}
                </>
            );
        }
    );
