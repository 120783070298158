import * as React from "react";

export const removeRowLevelSecurity = (outerBlocks, outerBlockIndex, innerBlockIndex) => {
    let ret = [...outerBlocks];
    ret[outerBlockIndex].innerBlocks = ret[outerBlockIndex].innerBlocks.filter((_, i) => i != innerBlockIndex);
    return ret;
};

export const updateRowLevelSecurityColumn = ({outerBlocks, outerBlockIndex, innerBlockIndex, newColumn, valueKey}) => {
    let ret = [...outerBlocks];
    let innerBlock = ret[outerBlockIndex].innerBlocks[innerBlockIndex];

    innerBlock[valueKey] = {
        ...newColumn,
        $type: newColumn.modelTableID ? "ViewColumnBlockValue" : "ColumnBlockValue",
    };

    return ret;
};

export const getErrorViewTablesWhenColumnDeleted = ({columnID, viewTables}) => {
    return viewTables.filter((table) => {
        return table.requiredModelColumns.find((c) => c.modelColumnID == columnID);
    });
};

export const getErrorViewTablesWhenTableDeleted = ({tableID, dataViewTransforms, viewTables}) => {
    let ret = [];

    for (let transformation of dataViewTransforms) {
        const transformationTableSource = transformation.steps.find((s) => s.$type == "ModelTableDatasetStep");
        if (transformationTableSource) {
            if (transformationTableSource.modelTableID == tableID) {
                const found = viewTables.find((t) => t.transformationID == transformation.id);
                if (found) {
                    ret.push(found);
                }
            }
        }
    }

    return ret;
};
