import "./free-form-text.scss";

import { css } from "emotion";
import * as React from "react";

import { StyledClass } from "@common/react/styled-class";
import { getDropdownCss } from "@common/ui-components/live/filters/common/dropdown-select/dropdown.config";
import { ffToDropdown } from "../../../../../../../form/ff-to-dropdown";
import { cs } from "../../../../../../../react/chain-services";
import { consumeContext } from "../../../../../../../react/context";
import { DropdownSelectLive } from "../../../../../filters/common/dropdown-select/dropdown-select-live";
import { comparisonMethods, textFilterValueLabelMap } from "../../../../../filters/text-filter/comparison-methods";
import { ControlFilterInput } from "../../../common/control-filter-input/control-filter-input";
import { ffToBasicInput } from "../../../common/ff-to-basic-input";
import { TextInputLive } from "../../../common/text-input-live/text-input-live";

export const FreeFormText = ({form, filter, next}) => {
    return cs(
        consumeContext("theme"),
        consumeContext("tileActionControlFilter"),
        [
            "cssClass",
            ({theme}, next) =>
                StyledClass({
                    content: getDropdownCss(theme.general.components),
                    next,
                }),
        ],
        [
            "inputClass",
            ({theme}, next) =>
                StyledClass({
                    next,
                    content: {
                        input: {
                            "&::placeholder": {
                                color: `${theme.general.components.inputHintTextColorRGB}`,
                            },
                        },
                    },
                }),
        ],
        ({theme, tileActionControlFilter, cssClass, inputClass}) => {
            const method = form.data?.value?.operator;
            const value = form.data?.value?.freeFormValue ?? "";
            let list = comparisonMethods.filter((cm) => (filter.comparisonMethods || "Contains")?.includes(cm.name));

            const hasActiveTileActionControl =
                tileActionControlFilter?.value?.action?.value &&
                (tileActionControlFilter?.value?.action?.filters || []).find((f) => f.id == filter.id) &&
                !list.find((item) => item.label == "Equals");

            const preview =
                list.length < 2 || hasActiveTileActionControl ? (
                    value && <span>{`Equals ${value}`}</span>
                ) : ["IsBlank", "IsNotBlank"].includes(method) ? (
                    <span>{textFilterValueLabelMap[method]}</span>
                ) : (
                    value && <span>{`${textFilterValueLabelMap[method]} ${value}`}</span>
                );
            return next({
                mobileFilter: {
                    label: filter?.label,
                    shortPreview: () => preview,
                    preview: () => preview,
                },
                control: ({size}) => {
                    const componentTheme = theme.general.components;

                    const inputStyle = {
                        border: `${componentTheme.inputBorderWidth}px solid ${componentTheme.inputBorderColorRGB}`,
                        background: `${componentTheme.inputBackgroundColorRGB}`,
                        color: `${componentTheme.inputTextColorRGB}`,
                        borderRadius: `${componentTheme.inputCornerRadius}px`,
                    };

                    const dropdown = (() => {
                        if (list.length < 2 || hasActiveTileActionControl) {
                            return null;
                        }
                        return DropdownSelectLive({
                            hideLabelOnValue: true,
                            label: "Operator",
                            list,
                            valueToLabel: (m) => m.label,
                            ...ffToDropdown(form.field("operator"), ["name"]),
                            onChange: (v) => {
                                form.data.change((c) => ({
                                    ...c,
                                    operator: v.name,
                                    freeFormValue: ["IsBlank", "IsNotBlank"].includes(v.name) ? null : c.freeFormValue,
                                }));
                            },
                            toggleStyle: inputStyle,
                            iconColor: componentTheme.inputIconColorRGB,
                            dropdownCss: cssClass,
                            borderRadius: componentTheme.menuCornerRadius,
                            fixMaxWidth: false,
                            overrideWidth: 200,
                        });
                    })();

                    const input = hasActiveTileActionControl
                        ? ControlFilterInput({label: "Equals"})
                        : TextInputLive({
                              disabled: ["IsBlank", "IsNotBlank"].includes(form.data?.value?.operator),
                              placeholder: filter.hint,
                              ...ffToBasicInput(form.field("freeFormValue")),
                              style: inputStyle,
                              className: inputClass,
                          });

                    return [
                        <div className="free-form-text-2t7 desktop">
                            {dropdown}
                            {input}
                        </div>,
                    ];
                },
            });
        }
    );
};
