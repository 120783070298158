import {cs} from "@common/react/chain-services";
import {cx} from "emotion";
import * as React from "react";
import "./filters-select.scss";

export const FiltersSelect = ({list, isSelected, onChange}) =>
    cs(({}) => (
        <div className="filters-select filters-select-12a">
            {list.map((item, i) => (
                <div key={i} className={cx("item", {selected: isSelected(item)})} onClick={() => onChange(item)}>
                    <div className="display-icon">
                        {item.icon({
                            fill: isSelected(item) ? "#11A1FD" : "#919EB0",
                        })}
                    </div>
                    <div className="display-name">{item.label}</div>
                </div>
            ))}
        </div>
    ));
