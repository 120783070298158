import * as React from "react";
import {cs} from "@common/react/chain-services";
import {Expandable} from "../../../../../../../../../../common/expandable/expandable";
import {cx} from "emotion";
import {NumberInput} from "../../../../../../../../../../../../../common/form/number-input/number-input";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {CheckboxLine} from "../../../../../../../../../../common/checkbox-line/checkbox-line";
import "./limit.scss";
import {getPath} from "@common/utils/arr-path";
import {consumeContext} from "@common/react/context";
import {scope} from "@common/react/scope";
import {spc} from "@common/react/state-path-change";
import {FieldInfoProvider} from "../../../../../tile-tab/chart-types/common/field-info-provider/field-info-provider";
import {FieldSection} from "../../field-section/field-section";
import {keepOnly} from "@common/utils/objects";
import {getFieldType} from "@common/ui-components/charts/common/get-field-type";
import {tooltipService4} from "../../../../../../../../../../common/tooltip3/tooltip-service-3";
import {sortIconsTypes} from "../sorting/sort-icons-types";
import {MissingFieldAlertCard} from "../missing-field-alert-card/missing-field-alert-card";
import {CollectionErrorBadgeWithTooltip} from "@common/ui-components/live/live-dashboard/common/collection-error-badge/collection-error-badge-with-tooltip";

export const Limit = ({dimensionAttr, showOther = true, field, isHiddenRankOption, onChange, allFields = [], fieldsForLimit}) =>
    cs(
        consumeContext("collection"),
        consumeContext("tileFields"),
        consumeContext("getFieldInfo"),
        [
            "tooltip",
            ({theme}, next) =>
                tooltipService4({
                    direction: "above",
                    next,
                }),
        ],
        ({tileFields, getFieldInfo, tooltip, collection}) => {
            const fieldInfo = getFieldInfo?.(field.value);

            const aggList = allFields
                .filter(
                    (f) =>
                        f.modelColumnID == field.value?.modelColumnID &&
                        f.modelTableID == field.value?.modelTableID &&
                        f.modelID == field.value?.modelID
                )
                .map((f) => f.aggregationFunc)
                .filter((v) => v);

            return FieldSection({
                className: "limit-1ak",
                header: FieldInfoProvider({
                    info: <span>When limits are applied, they are applied before the sort.</span>,
                    field: <div className="text">Limit</div>,
                }),
                content: (
                    <>
                        {DropdownSelect({
                            detectOnWheelEvent: true,
                            list: [
                                ...(fieldsForLimit || tileFields.fieldsForLimit).map((f) => ({
                                    ...f,
                                    label: getFieldInfo?.(f).visualNameFull,
                                    value: getFieldInfo?.(f).visualNameFull,
                                })),
                                {
                                    value: null,
                                    label: "Not limited (all data shown)",
                                },
                            ],
                            className: "dropdown",
                            label: "Limit by",
                            valueToLabel: (v) => v.label,
                            isSelected: (v) =>
                                v.value === null ? field.value === v.value : v.modelColumnID === getPath(field.value, ["modelColumnID"]),
                            onChange: (v) => {
                                const limitConfigure = {
                                    text: {top: "Asc", bottom: "Desc"},
                                }[getFieldType(v)] || {top: "Desc", bottom: "Asc"};

                                const _limit =
                                    v.value === null
                                        ? null
                                        : {
                                              direction: limitConfigure.top,
                                              limit: 5,
                                              modelID: v.modelID,
                                              modelTableID: v.modelTableID,
                                              modelColumnID: v.modelColumnID,
                                              rankBy: "Position",
                                              aggregationFunc: v.aggregationFunc,
                                          };

                                return onChange && _limit !== null ? onChange(_limit, v) : field.onChange(_limit);
                            },
                        })}

                        {field.value != null &&
                            (() => {
                                const fieldInfo = getFieldInfo?.(keepOnly(field.value, ["modelID", "modelTableID", "modelColumnID"]));

                                const isNumeric = ["Int", "Double", "IDInt"].includes(fieldInfo.dataType);
                                const isDate = ["DateTime", "DateTimeOffset"].includes(fieldInfo.dataType);

                                const limitConfigure = {
                                    text: {top: "Asc", bottom: "Desc"},
                                }[fieldInfo.type] || {
                                    top: "Desc",
                                    bottom: "Asc",
                                };

                                return (
                                    <>
                                        <div className="first-line">
                                            <div className="upper-lower-limit">
                                                <div
                                                    className={cx("upper", {
                                                        selected: getPath(field.value, ["direction"]) === limitConfigure.top,
                                                    })}
                                                    onClick={() =>
                                                        spc(field, ["direction"], (sort) =>
                                                            !getPath(field.value, ["limit"]) && sort === limitConfigure.top
                                                                ? "None"
                                                                : limitConfigure.top
                                                        )
                                                    }
                                                    {...tooltip(() => (isNumeric ? "Smallest" : isDate ? "Earliest" : "First") + " Values")}
                                                >
                                                    {isNumeric
                                                        ? sortIconsTypes.number.asc
                                                        : isDate
                                                        ? sortIconsTypes.date.asc
                                                        : sortIconsTypes.text.asc}
                                                </div>
                                                <div
                                                    className={cx("lower", {
                                                        selected: getPath(field.value, ["direction"]) === limitConfigure.bottom,
                                                    })}
                                                    onClick={() =>
                                                        spc(field, ["direction"], (sort) =>
                                                            !getPath(field.value, ["limit"]) && sort === limitConfigure.bottom
                                                                ? "None"
                                                                : limitConfigure.bottom
                                                        )
                                                    }
                                                    {...tooltip(() => (isNumeric ? "Largest" : isDate ? "Latest" : "Last") + " Values")}
                                                >
                                                    {isNumeric
                                                        ? sortIconsTypes.number.desc
                                                        : isDate
                                                        ? sortIconsTypes.date.desc
                                                        : sortIconsTypes.text.desc}
                                                </div>
                                            </div>
                                            <div className="limit-number">
                                                {NumberInput({
                                                    label: "Limit Number",
                                                    state: {
                                                        value: getPath(field.value, ["limit"]),
                                                        onChange: (v) => {
                                                            field.change((limit) => ({
                                                                ...(limit || {}),
                                                                limit: v,
                                                                direction:
                                                                    v > 0 && !["Asc", "Desc"].includes(limit?.direction)
                                                                        ? limitConfigure.top
                                                                        : limit?.direction,
                                                                ...(v > 0 &&
                                                                    !limit?.modelColumnID && {
                                                                        modelColumnID: field.value.modelColumnID,
                                                                        modelTableID: field.value.modelTableID,
                                                                        modelID: field.value.modelID,
                                                                    }),
                                                            }));
                                                        },
                                                    },
                                                })}
                                            </div>
                                        </div>

                                        {aggList.length > 0 &&
                                            FieldInfoProvider({
                                                className: "margin-bottom",
                                                field: DropdownSelect({
                                                    detectOnWheelEvent: true,
                                                    label: "Aggregate",
                                                    list: aggList,
                                                    valueToLabel: (v) => v,
                                                    isSelected: (v) => v === getPath(field.value, ["aggregationFunc"]),
                                                    onChange: (v) =>
                                                        field.change((s) => ({
                                                            ...s,
                                                            aggregationFunc: v,
                                                        })),
                                                }),
                                            })}

                                        {!isHiddenRankOption() &&
                                            FieldInfoProvider({
                                                className: "margin-bottom",
                                                info: (
                                                    <span>
                                                        <b>Rank by Position:</b> Each limit position is a unique value. If there are tied
                                                        values they are assigned unique ranks and the number of groups shown will match the
                                                        limit amount selected. Note: This is not available when the limiting column is a
                                                        date.
                                                        <br />
                                                        <br />
                                                        <b>Rank by Value:</b> Limit positions may contain multiple values if there are
                                                        groups with tied values. There may be more groups displayed than the limit amount
                                                        selected due to tied values. Also known as dense rank.
                                                    </span>
                                                ),
                                                field: DropdownSelect({
                                                    detectOnWheelEvent: true,
                                                    label: "Rank By",
                                                    list: ["Position", "Value"],
                                                    valueToLabel: (v) => v,
                                                    isSelected: (v) => v === getPath(field.value, ["rankBy"]),
                                                    onChange: (v) =>
                                                        field.change((s) => ({
                                                            ...s,
                                                            rankBy: v,
                                                        })),
                                                }),
                                            })}

                                        {showOther &&
                                            FieldInfoProvider({
                                                className: "show-other-info-provider",
                                                info: <span>"Show Other" is only available when there is no Group field selected.</span>,
                                                field: CheckboxLine({
                                                    label: "Show Other",
                                                    state: scope(field, ["showOther"]),
                                                }),
                                            })}
                                    </>
                                );
                            })()}
                    </>
                ),
            });
        }
    );
