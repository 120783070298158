export const tooltipLayout =
    ({content, theme, isCompare}) =>
    ({scrollingInfo} = {}) => {
        return `<div 
            class="chart-tooltip-box ${isCompare ? "compare" : ""} ${theme.dataVisualization.toolTipsAndLegends.tooltipTheme === "Dark" ? "dark-mode" : ""} ${theme.dataVisualization.toolTipsAndLegends.position === "Below" ? "below" : ""}" 
            style="
                border-radius: ${theme.general.tile.styles.tileCornerRadius || 0}px;
                ${scrollingInfo?.scrollEnabled ? `max-height: ${scrollingInfo.maxHeight}px; overflow-y: scroll;` : ""}
            "
        >
            ${content}
        </div>`;
    };
