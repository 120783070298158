import {rBarLinePoints, rTotalFooter} from "./column-points-table";

export const getLinePointsTable = ({tile, theme, extractedPoints, generalInfo, rawData, formatters}) => {
    let ret = rBarLinePoints({
        extractedPoints,
        generalInfo,
        tile,
        theme,
        formatters,
    });

    if (tile.style.displayType.indexOf("Stack") >= 0) {
        ret += rTotalFooter({extractedPoints, generalInfo, theme});
    }

    return ret;
};
