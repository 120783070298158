import "./pivot-table-chart.scss";

import * as React from "react";
import {css, cx} from "emotion";

import {Watch} from "../../../react/watch";
import {Static2} from "../../../react/static-2";
import {cs} from "../../../react/chain-services";
import {UseState} from "../../../react/use-state";
import {OnMounted} from "../../../react/on-mounted";
import {consumeContext} from "../../../react/context";

import {PivotTableHeader} from "./header/pivot-table-header";
import {PivotTableBody} from "./body/pivot-table-body";
import {pivotTableHelper} from "./common/pivot-table-helper";
import {getCollapsibleHeaderColumns} from "./common/pivot-table-common";
import {headerMapping} from "./common/header-mapping";
import {tooltipService4} from "../../../../web-client/src/routes/common/tooltip3/tooltip-service-3";
import {getTooltipFontSizes} from "../factory/tooltip/shared-tooltip-formatter/get-tooltip-font-sizes";
import {loadTileData} from "../common/load-tile-data";
import data from "../../../../web-client/src/routes/test-route/pivot-table-data-sample.json";
import {VerbScrollbar} from "../../verb-scrollbar/verb-scrollbar";
import {MenuOptions} from "../common/menu-options/menu-options";
import {MaximizeIcon} from "../../../../web-client/src/routes/collection/tile/edit/child-tile/icons";
import {MaximizeChartDialog} from "./dialog/maximize-chart-dialog";
import {NoDataScreen} from "../common/no-data-screen/no-data-screen";
import {CurrencyConversionWarning, getCurrencyConversion} from "../common/currency-conversion-warning/currency-conversion-warning";
import {loadTileFields} from "../get-field-color";

export const PivotTableChart = ({tile, size, loadData, downloadData, tileFilters, theme, defaultData, modalMode}) =>
    cs(
        consumeContext("viewWidth"),
        [
            "data",
            ({pagination, sort, search}, next) => {
                if (defaultData) return next(defaultData);

                return loadTileData({
                    keepOutdatedValue: true,
                    next,
                    tileFilters,
                    loadData,
                    size,
                    page: 0,
                    tile,
                    theme,
                    tileKey: getPivotTableTileKey({tile}),
                });
            },
        ],
        ({data}, next) => {
            if (data.rows.length == 0) {
                return NoDataScreen({
                    size,
                    theme,
                });
            }

            return next();
        },
        ["scrollContainerRef", (_, next) => Static2({next})],
        consumeContext("tileWidthPx"),
        [
            "oriHeaders",
            ({data}, next) =>
                Static2({
                    next,
                    getInitValue: () => headerMapping.mapHeader(data.cols),
                }),
        ],
        [
            "collapsedColumns",
            ({oriHeaders}, next) =>
                UseState({
                    next,
                    initValue: tile.style.columnsExpanded ? [] : getCollapsibleHeaderColumns(oriHeaders.get()),
                }),
        ],
        ({collapsedColumns, oriHeaders}, next) =>
            Watch({
                value: tile.style.columnsExpanded,
                onChanged: (expanded) => {
                    collapsedColumns.onChange(expanded ? [] : getCollapsibleHeaderColumns(oriHeaders.get()));
                },
                next,
            }),
        [
            "tileFields",
            ({}, next) =>
                loadTileFields({
                    next,
                    configs: {
                        tile,
                        measureMultiAttr: ["valueFields"],
                    },
                }),
        ],
        [
            "actionMenu",
            ({data, tileFields}, next) => {
                return next(
                    <>
                        {/*TODO: pivot table*/}
                        {/*{CurrencyConversionWarning({*/}
                        {/*    theme,*/}
                        {/*    currencyConversion: getCurrencyConversion({ $type: data.$type, data, tileFields})*/}
                        {/*})}*/}

                        {MenuOptions({
                            theme,
                            tile,
                            downloadData,
                            tileFilters,
                        })}
                    </>
                );
            },
        ],
        ["maximizeDialog", (_, next) => (!modalMode ? MaximizeChartDialog({next}) : next())],
        [
            "tooltip",
            (_, next) => {
                const themeMode = theme.dataVisualization.toolTipsAndLegends.tooltipTheme.toLowerCase() || "light";

                return tooltipService4({
                    direction: theme.dataVisualization.toolTipsAndLegends.position.toLowerCase(),
                    className: `${themeMode}-theme`,
                    tooltipContentStyle: {
                        fontSize: getTooltipFontSizes(theme).small,
                        fontFamily: `"${theme.general.canvas.fontFamily || "Roboto"}", sans-serif`,
                        // color: themeMode === "light" ? theme.general.canvas.fontColorRGB || "#546B81" : "#E2E2E2",
                    },
                    next,
                });
            },
        ],
        ["hideFixed", (_, next) => UseState({next, initValue: false})],
        ["hideFixedRow", (_, next) => UseState({next, initValue: false})],
        ({hideFixed, scrollContainerRef, hideFixedRow}, next) =>
            OnMounted({
                next,
                action: () => {
                    const updateView = () => {
                        const elem = scrollContainerRef.get()?.getDom?.();
                        if (elem) {
                            hideFixed.onChange(
                                Math.floor((elem?.offsetWidth || 0) + (elem?.scrollLeft || 0)) == Math.floor(elem?.scrollWidth)
                            );
                            hideFixedRow.onChange(
                                Math.floor((elem?.offsetHeight || 0) + (elem?.scrollTop || 0)) == Math.floor(elem?.scrollHeight)
                            );
                        }
                    };

                    setTimeout(() => {
                        updateView();

                        if (scrollContainerRef.get()) {
                            scrollContainerRef.get().onScrollListener(() => {
                                updateView();
                            });

                            scrollContainerRef.get().onDomSizeChange(() => {
                                updateView();
                            });
                        }
                    }, 10);
                },
            }),
        ({
            oriHeaders,
            collapsedColumns,
            tooltip,
            data,
            tileWidthPx,
            scrollContainerRef,
            hideFixed,
            hideFixedRow,
            actionMenu,
            maximizeDialog,
            viewWidth,
            tileFields,
        }) => {
            const collapsibleColumns = getCollapsibleHeaderColumns(oriHeaders.get());
            const columns = pivotTableHelper.filterData(data.cols, collapsedColumns.value);
            const headers = pivotTableHelper.buildTableHeader(columns, data.aggregates, tile.style.grandTotalsColumnShown);
            const maxFixedColumnWidth = tileWidthPx / 2;
            const isMobile = (viewWidth?.value || window.innerWidth) <= 768;

            const hasControl = !modalMode && (!!actionMenu || tile.style.maximizeTile);

            return (
                <div className={cx("pivot-table-chart-wrapper-a33", hasControl && !tile.style.title.show && "hide-title")}>
                    {!modalMode && (
                        <div className="controls">
                            {actionMenu}

                            {tile.style.maximizeTile && (
                                <MaximizeIcon
                                    className={cx("maximize-icon")}
                                    fill={theme.general.components.menuTextColorRGB}
                                    onClick={() => {
                                        maximizeDialog.show({
                                            tile,
                                            size,
                                            loadData,
                                            downloadData,
                                            tileFilters,
                                            theme,
                                            defaultData: data,
                                        });
                                    }}
                                />
                            )}
                        </div>
                    )}

                    <VerbScrollbar ref={scrollContainerRef.set} className="pivot-table-chart-a29">
                        <table className="pivot-table">
                            {PivotTableHeader({
                                tileFields,
                                data,
                                headers,
                                collapsibleColumns,
                                collapsedColumns,
                                maxFixedColumnWidth,
                                tile,
                                isMobile,
                                scrollContainerRef,
                                hideFixed,
                            })}
                            {PivotTableBody({
                                data,
                                headers,
                                tooltip,
                                maxFixedColumnWidth,
                                scrollContainerRef,
                                tile,
                                isMobile,
                                hideFixed,
                                hideFixedRow,
                            })}
                        </table>
                    </VerbScrollbar>
                </div>
            );
        }
    );

const getPivotTableTileKey = ({tile}) =>
    JSON.stringify([tile.filters, tile.rowFields, tile.columnFields, tile.valueFields, tile.style.dateGaps]);
