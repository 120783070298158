import {isHundredPercent} from "@common/ui-components/charts/common/bar-line-chart-helpers";

export const ReferenceLines = ({tile, form, referenceLinesPanel}) => {
    if (isHundredPercent(tile.value)) {
        return;
    }
    return {
        label: "Reference Lines",
        render: referenceLinesPanel.render,
    };
};
