import React from "react";
import {cs} from "@common/react/chain-services";
import "./filter-data-configuration.scss";
import {spc} from "@common/react/state-path-change";
import {OrConfig} from "./or-config";
import {keyed} from "@common/react/keyed";
import {removeIndex} from "@common/utils/collections";
import {Button} from "../../../../../../../../../common/form/buttons/button/button";

export const FilterDataConfiguration = ({transformation, step, form, model}) => {
    const inputStep = transformation.value.steps.find((s) => s.id === step.value.inputStepID);

    return (
        <div className="filter-data-configurations-57d">
            {(step.value.orConfigs?.length > 0 ? step.value.orConfigs : [{}])?.map((orConfig, oci) =>
                cs(keyed(oci), ({}) =>
                    OrConfig({
                        headerText: oci === 0 ? "SHOW ONLY ROWS WHERE" : "OR when...",
                        step,
                        form,
                        path: ["orConfigs!arr", oci],
                        onRemove: () => spc(step, ["orConfigs"], (orConfigs) => removeIndex(oci, orConfigs)),
                        columns: inputStep?.outputColumns,
                        model,
                    })
                )
            )}
            {step.value.orConfigs?.length > 0 && (
                <Button size="tiny" btnType="border" onClick={() => spc(step, ["orConfigs"], (orConfigs) => [...(orConfigs || []), {$type: "OrFilterConfig", andConfigs: [{}]}])}>
                    OR
                </Button>
            )}
        </div>
    );
};
