import { getFieldType } from "@common/ui-components/charts/common/get-field-type";
import { isNil } from "@common/utils/common";
import { chain } from "@common/utils/fs";

// AB#5510 AB#5527
// add classname to all bar data points and line series, except the comparison bar data points of classic bar/combo chart,
// because those are drawn manually in draw-comparison-bars.js and draw-horizontal-comparison-bars.js and the classnames will be added there.
export const addClassnameToDataPoint = ({series, tile}) => {
    if (!applyForChartTypes.includes(tile.$type)) {
        return series;
    }

    const isReferenceLineSeries = (s) => {
        return ["ConstantValueReferenceLine", "AggregateValueReferenceLine", "IndicatorReferenceLine"].includes(s.customType);
    };

    const handleBarSeries = (s) => {
        if (isReferenceLineSeries(s)) {
            return s;
        }
        const className = getClassFromName(s.name);
        return {
            ...s,
            data: s.data.map((dp) => {
                if (!isNil(dp[0])) {
                    return {x: dp[0], y: dp[1], className};
                }
                return {...dp, className};
            }),
        };
    };

    const handleLineSeries = (s) => {
        if (isReferenceLineSeries(s)) {
            return s;
        }
        const className = getClassFromName(s.name);
        return {
            ...s,
            className,
        };
    };

    const handleBarSeriesWithoutGroup = (s) => {
        if (isReferenceLineSeries(s)) {
            return s;
        }
        return {
            ...s,
            data: s.data.map((dp) => {
                if (!isNil(dp[0])) {
                    return {
                        x: dp[0],
                        y: dp[1],
                        className: getClassFromName(dp[0]),
                    };
                }
                return {...dp, className: getClassFromName(dp.name || dp.x)};
            }),
        };
    };

    if (["VerticalBarChartTile", "HorizontalBarChartTile"].includes(tile.$type)) {
        if (tile.groupField) {
            return series.map((s) => handleBarSeries(s));
        }
        if (getFieldType(tile.xAxisField || tile.yAxisField) !== "date") {
            return series.map((s) => handleBarSeriesWithoutGroup(s));
        }
        return series;
    }

    if (tile.$type === "LineChartTile") {
        if (tile.groupField || getFieldType(tile.xAxisField) !== "date") {
            return series.map((s) => handleLineSeries(s));
        }
        return series;
    }

    if (tile.$type === "ComboChartTile") {
        const barStacks = tile.yAxisBarFields?.map((mfg) => mfg.id);
        // const lineStacks = tile.yAxisLineFields?.map((mfg) => mfg.id);

        if (tile.groupField) {
            return series.map((s) => {
                if (barStacks?.includes(s.customStack)) {
                    return handleBarSeries(s);
                }
                return handleLineSeries(s);
            });
        }
        if (getFieldType(tile.xAxisField) !== "date") {
            return series.map((s) => {
                if (barStacks?.includes(s.customStack)) {
                    return handleBarSeriesWithoutGroup(s);
                }
                return handleLineSeries(s);
            });
        }
        return series;
    }

    // pie/donut always have group
    if (["PieChartTile", "DonutChartTile"].includes(tile.$type)) {
        return series.map((s) => {
            return {
                ...s,
                data: s.data.map((dp) => ({
                    ...dp,
                    className: getClassFromName(dp.name),
                })),
            };
        });
    }
};

export const getClassFromName = (name) => {
    return `verb-group-${cleanStringForClass(name)}`;
};

const cleanStringForClass = (str) => {
    if (!str || !isNaN(str)) {
        return str;
    }
    return chain(
        str,
        // lower case everything
        (str) => str.toLowerCase(),
        // remove all non alpha-numeric characters
        (str) => str.replace(/[^a-z0-9]/g, " "),
        // replace spaces with dash
        (str) => str.replace(/\s+/g, "-")
    );
};

const applyForChartTypes = ["VerticalBarChartTile", "HorizontalBarChartTile", "LineChartTile", "ComboChartTile", "PieChartTile", "DonutChartTile"];
