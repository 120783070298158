import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {stateToSelect} from "@common/ui-components/form/state-to-select";
import {scope} from "@common/react/scope";
import {PrefixSuffixInput} from "../../../../../../../../../common/prefix-suffix-input/prefix-suffix-input";
import "./content.scss";

export const Content = ({tile, size, form, kpiColorsTileConfig}) => ({
    label: "Content",
    render: () => {
        return (
            <div className="text-label-section-fv9 text-label-content">
                <div className="subsection">
                    <div className="content">
                        {PrefixSuffixInput({
                            label: "Support Text",
                            prefixState: scope(tile, ["style", "supportTextBefore"]),
                            suffixState: scope(tile, ["style", "supportTextAfter"]),
                            rTooltipContent: () => (
                                <div className="support-text-tooltip-content-d2t">
                                    Add static text around the text label.
                                    <br />
                                    Select the text label field on the Data tab.
                                </div>
                            ),
                        })}
                        {DropdownSelect({
                            label: "Position",
                            list: ["Left", "Center", "Right"],
                            ...stateToSelect(scope(tile, ["style", "valuePosition"])),
                        })}
                        {kpiColorsTileConfig?.render(0)}
                    </div>
                </div>
            </div>
        );
    },
});
