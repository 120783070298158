import * as React from "react";
import {cx} from "emotion";

export const UsageStatusIndicator = React.memo(({status, color}) => {
    const hexToRGB = (hex) => {
        const m = hex.match(/^#?([\da-f]{2})([\da-f]{2})([\da-f]{2})$/i);
        return `${parseInt(m[1], 16)},${parseInt(m[2], 16)},${parseInt(m[3], 16)}`;
    };

    const icon = (() => {
        if (status === "all") {
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8">
                    <circle id="Ellipse_50" data-name="Ellipse 50" cx="4" cy="4" r="4" fill={color} />
                </svg>
            );
        }

        if (status === "partial") {
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11">
                    <g id="Partial_Icon" data-name="Partial Icon" transform="matrix(0.819, 0.574, -0.574, 0.819, -13.511, -33.427)">
                        <path
                            id="Intersection_15"
                            data-name="Intersection 15"
                            d="M7406,6830v-8a4,4,0,0,1,0,8Z"
                            transform="translate(-7368 -6805)"
                            fill={color}
                        />
                        <g
                            id="Ellipse_49"
                            data-name="Ellipse 49"
                            transform="translate(34 17)"
                            fill={`rgba(${hexToRGB(color)},0.2)`}
                            stroke={color}
                            strokeWidth="1"
                        >
                            <circle cx="4" cy="4" r="4" stroke="none" />
                            <circle cx="4" cy="4" r="3.5" fill="none" />
                        </g>
                    </g>
                </svg>
            );
        }

        if (status === "none") {
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8">
                    <g id="Ellipse_50" data-name="Ellipse 50" fill={`rgba(${hexToRGB(color)},0.2)`} stroke={color} strokeWidth="1">
                        <circle cx="4" cy="4" r="4" stroke="none" />
                        <circle cx="4" cy="4" r="3.5" fill="none" />
                    </g>
                </svg>
            );
        }
    })();

    return (
        <div
            className={cx("status-indicator-er5 status-indicator", {
                partial: status === "partial",
            })}
            style={{display: "inline-block", width: 8}}
        >
            {icon}
        </div>
    );
});
