import {chain} from "../../../utils/fs";
import {roundTo} from "../common/formatters/numeric/round-to";
import {getNumericFormatter} from "../factory/formatters/get-numeric-formatter";
import {createDateFormatter} from "../common/formatters/date-formatter";

export const getMapTileFormatters = ({tile, mapName}) => {
    let measurementFormatters = {};

    tile.valueFields.forEach((mfg) => {
        const mf = mfg.measureFields[0];
        const numericFormatter = getNumericFormatter({
            numericProperties: {
                ...mf.numericProperties,
                currencyOverrides: tile?.currencyOverrides,
            },
            autoRound: true,
            useDefaultCountFormat: mf.aggregationFunc.startsWith("Count"),
        });

        measurementFormatters[mfg.id] = (v) =>
            v != null &&
            chain(
                v,
                (v) => (mf.numericProperties?.displayType === "Percentage" ? v * 100 : v),
                (v) => (mf.numericProperties?.decimalPlaces != null ? roundTo(v, mf.numericProperties.decimalPlaces) : roundTo(v, 2)),
                (v) => numericFormatter(v)
            );
    });

    // const dimensionFormatter = (() => {
    //     const {0: level, 1: country, 2: province, 3: county} = mapName.split("-");
    //     const levelDimensionFieldMap = {
    //         "countries": "countryField",
    //         "province": "provinceField",
    //         "county": "countyField",
    //         "postalcode": "postalCodeField",
    //     };
    //     const currentDimension = levelDimensionFieldMap[level];
    //     if (!currentDimension || !tile[currentDimension]) {
    //         return (v) => v;
    //     }
    //     return (v) => v;
    // })();

    return {
        measurementFormatters,
        // dimensionFormatter,
        percentFormatter: (v) => roundTo(v, 1) + "%",
        percentRoundFormatter: (v) => Math.round(v) + "%",
        dateFormatter: (v) => createDateFormatter("MMM d, yyyy").format(new Date(v)),
    };
};
