import * as React from "react";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {DebounceNumber} from "../../../../../../../../../../../../../common/form/debounce-number";
import {NumberInput} from "../../../../../../../../../../../../../common/form/number-input/number-input";
import "./row-height-select.scss";
import {cx} from "emotion";

export const RowHeightSelect = (state, className) =>
    cs(
        [
            "type",
            (_, next) =>
                UseState({
                    initValue: !state.value ? "Auto" : "Fixed",
                    next,
                }),
        ],
        ({type}) => (
            <div className={cx("row-height-select-24c", className)}>
                <div className="col1">
                    {DropdownSelect({
                        label: "Row Height",
                        list: ["Auto", "Fixed"],
                        isSelected: (v) => v === type.value,
                        onChange: (v) => {
                            type.onChange(v);
                            v === "Auto" ? state.onChange(null) : state.onChange(32);
                        },
                    })}
                </div>
                <div className="col2">
                    {cs(
                        [
                            "debounce",
                            (_, next) =>
                                DebounceNumber({
                                    state,
                                    next,
                                }),
                        ],
                        ({debounce}) =>
                            NumberInput({
                                label: "Pixels",
                                state: debounce.state,
                                onBlur: debounce.flush,
                                disabled: type.value === "Auto",
                                minValue: state.value == null ? -Infinity : 32,
                            })
                    )}
                </div>
            </div>
        )
    );
