import * as React from "react";
import {cs} from "@common/react/chain-services";
import "./columns.scss";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {scope} from "@common/react/scope";
import {consumeContext} from "@common/react/context";
import {DebounceNumber} from "../../../../../../../../../../../../../common/form/debounce-number";
import {NumberInput} from "../../../../../../../../../../../../../common/form/number-input/number-input";
import {keyed} from "@common/react/keyed";
import {getPath} from "@common/utils/arr-path";
import {spc} from "@common/react/state-path-change";
import {ShowHideToggle} from "../../../../../../../../../../../../../common/form/toggles/show-hide-toggle";
import {TextareaInput} from "../../../common/textarea-input/textarea-input";
import {IndividualColumns} from "./individual-columns";
import {LpShowHideLine} from "../../../../../../../../../../common/left-panel/lp-show-hide-line";
import {stateToSelect} from "@common/ui-components/form/state-to-select";
import {StaticTooltipService} from "../../../../../../../../../../common/tooltip3/static-tooltip-service";

export const Columns = ({tile, form}) =>
    cs(
        consumeContext("getFieldInfo"),
        [
            "staticTooltip",
            (_, next) =>
                StaticTooltipService({
                    direction: "top",
                    info: "Grand Total options will not be fixed on small screen sizes to prevent readability issues.",
                    tooltipBoxWidth: 200,
                    topOffset: 20,
                    leftOffset: -5,
                    next,
                }),
        ],
        ({getFieldInfo, staticTooltip}) => {
            return (
                <div className="table-columns-35g">
                    {tile.value.$type != "PivotTableTile" && (
                        <>
                            <div className="panel">
                                {DropdownSelect({
                                    list: [
                                        {label: "Auto", name: "Auto"},
                                        {
                                            label: "Equal Distribution",
                                            name: "EqualDist",
                                        },
                                        {
                                            label: "Custom Proportions",
                                            name: "Custom",
                                        },
                                    ],
                                    label: "Column Widths",
                                    valueToLabel: (v) => v.label,
                                    // ...stateToSelect(scope(tile, ["style", "columnWidths"]), ["name"]),
                                    isSelected: (v) => v.name === getPath(tile.value, ["style", "columnWidths"]),
                                    onChange: (v) =>
                                        spc(tile, ["style"], (style) => ({
                                            ...style,
                                            columnWidths: v.name,
                                            columnCustomWidths: null,
                                        })),
                                })}
                            </div>
                            {getPath(tile.value, ["style", "columnWidths"]) === "Custom" &&
                                IndividualColumns({
                                    tile,
                                    getFieldInfo,
                                    state: scope(tile, ["style", "columnCustomWidths"]),
                                })}
                        </>
                    )}

                    {tile.value.$type == "PivotTableTile" && (
                        <div className="panel">
                            {DropdownSelect({
                                list: [
                                    {label: "Columns Expanded", value: true},
                                    {label: "Columns Collapsed", value: false},
                                ],
                                label: "Default View",
                                valueToLabel: (v) => v.label,
                                isSelected: (v) => v.value == tile.value.style.columnsExpanded,
                                onChange: (v) =>
                                    spc(tile, ["style"], (style) => ({
                                        ...style,
                                        columnsExpanded: v.value,
                                    })),
                            })}

                            {LpShowHideLine({
                                state: scope(tile, ["style", "grandTotalsColumnShown"]),
                                label: (
                                    <span>
                                        Grand Totals{" "}
                                        {tile.value.style.grandTotalsColumnShown &&
                                            staticTooltip.renderIcon({
                                                icon: (
                                                    <i
                                                        className="fa fa-mobile-alt"
                                                        style={{
                                                            color: "#FF7A59",
                                                        }}
                                                    />
                                                ),
                                                className: "info-tooltip",
                                            })}
                                    </span>
                                ),
                            })}

                            {tile.value.style.grandTotalsColumnShown && (
                                <>
                                    {DropdownSelect({
                                        list: ["Inline", "Fixed"],
                                        label: "Grand Totals Position",
                                        valueToLabel: (v) => v,
                                        ...stateToSelect(scope(tile, ["style", "grandTotalColumnPosition"])),
                                    })}
                                </>
                            )}
                        </div>
                    )}
                </div>
            );
        }
    );

const rColumnsInfoIcon = ({tile}) => {
    const columnInfoIcons = scope(tile, ["style", "columnInfoIcons"]);

    return cs(() =>
        [...tile.value.actualValueFields, ...tile.value.aggregatedValues].map((item, i) =>
            cs(keyed(i), () => {
                const index = columnInfoIcons.value.findIndex((o) => o.tileFieldID === item.id);

                return (
                    <div className="subsection">
                        <div className="header">
                            {item.displayName} Info Icon
                            <div className="control">
                                {ShowHideToggle({
                                    state: {
                                        value: index > -1,
                                        onChange: (val) => {
                                            return val
                                                ? columnInfoIcons.change((oldInfo) => [
                                                      ...oldInfo,
                                                      {
                                                          tileFieldID: item.id,
                                                          text: "",
                                                      },
                                                  ])
                                                : columnInfoIcons.change((oldInfo) => oldInfo.filter((o) => o.tileFieldID !== item.id));
                                        },
                                    },
                                })}
                            </div>
                        </div>

                        {index > -1 && (
                            <div className="content">
                                {TextareaInput({
                                    state: scope(columnInfoIcons, [index, "text"]),
                                })}
                            </div>
                        )}
                    </div>
                );
            })
        )
    );
};
