import "./edit-theme.scss";

import {cx} from "emotion";
import React from "react";

import {Load} from "@common/react/load";
import {Load2} from "@common/react/load2";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {ChangeQueue1} from "@common/react/change-queue-1";
import {SuspendUpdate} from "@common/react/suspend-update";
import {consumeContext, provideContext} from "@common/react/context";

import {flatten1} from "@common/utils/collections";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";
import {LiveDashboard} from "@common/ui-components/live/live-dashboard/live-dashboard";
import {LoadingIndicator} from "@common/ui-components/loading-indicator/loading-indicator";
import {Badge} from "../../common/badge/badge";
import {HeaderBar} from "../../common/header-bar/header-bar";
import {ToastWithAction} from "../../common/toast/toast-with-action";
import {LeftPanelTabs} from "../../common/left-panel-tabs/left-panel-tabs";
import {DeleteThemeDialog} from "../../collection/theme/edit/delete-theme-dialog/delete-theme-dialog";
import {getCloneName} from "../../dashboard/env/collections/collection-cm/clone-collection/select-model-for-cloning-collection-dialog";
import {EditThemeTab} from "./tab/edit-theme-tab";
import {EditThemeLogic} from "./edit-theme-logic";
import {initTheme} from "./common/theme-init";
import {RenameThemeDialog} from "./rename-theme/rename-theme-dialog";
import {PublishThemeButton} from "./publish-theme/publish-theme";
import {loadModelForCollection} from "../../../verb-web/auth/load-auth-routes";
import {DesignThemeDashboardAuto} from "./auto-preview-data/dashboard/design-theme-dashboard-auto";
import {ContainerThemeAuto} from "./auto-preview-data/container/container-theme-auto";
import {ContainerTabsThemeAuto} from "./auto-preview-data/container/tabs/container-tabs-theme-auto";
import {ContainerButtonsThemeAuto} from "./auto-preview-data/container/buttons/container-buttons-theme-auto";
import {ContainerDropdownThemeAuto} from "./auto-preview-data/container/dropdown/container-dropdown-theme-auto";
import {DiscreteColorThemeAuto} from "./auto-preview-data/data-color-palettes/discrete-color/discrete-color-theme-auto";
import {ColorSchemesThemeAuto} from "./auto-preview-data/data-color-palettes/color-schemes/color-schemes-theme-auto";
import {KpiColorsThemeAuto} from "./auto-preview-data/data-color-palettes/kpi-colors/kpi-colors-theme-auto";
import {OtherColorsThemeAuto} from "./auto-preview-data/data-color-palettes/orther-colors/other-colors-theme-auto";
import {VizFontsThemeAuto} from "./auto-preview-data/viz-fonts/viz-fonts-theme-auto";
import {TooltipLegendsThemeAuto} from "./auto-preview-data/tooltip-legends/tooltip-legends-theme-auto";
import {GaugeChartThemeAuto} from "./auto-preview-data/gauge-chart/gauge-chart-theme-auto";
import {PieChartThemeAuto} from "./auto-preview-data/pie/pie-chart-theme-auto";
import {AxisChartThemeAuto} from "./auto-preview-data/axis-chart/axis-chart-theme-auto";
import {TableThemeAuto} from "./auto-preview-data/table/table-theme-auto";
import {DesignThemeButtonsAuto} from "./auto-preview-data/buttons/design-theme-buttons-auto";
import {DesignThemeComponentsAuto} from "./auto-preview-data/components/design-theme-components-auto";
import {MapChartThemeAuto} from "./auto-preview-data/map/map-theme-auto";
import {SectionAndTextTileThemeAuto} from "./auto-preview-data/section-and-text-tile/section-and-text-tile-theme-auto";
import {keyed1} from "@common/react/keyed";
import {IgnoreUpdate} from "@common/react/ignore-update";
import {equalDeep} from "@common/utils/objects";
import {TableConditionalFormatThemeAuto} from "./auto-preview-data/table-conditional-format/table-conditional-format-theme-auto";

export const EditTheme = () =>
    cs(
        consumeContext("apis"),
        consumeContext("routing"),
        [
            "theme",
            ({apis, routing}, next) =>
                Load({
                    fetch: () => apis.collection.getTheme(routing.params.themeId, false),
                    next,
                    _key: routing.params.themeId,
                }),
        ],
        [
            "folders",
            ({apis, routing}, next) =>
                Load2({
                    _key: routing.params?.envId,
                    fetch: () => apis.theme.getFolders(),
                    next,
                }),
        ],
        [
            "collections",
            ({apis}, next) =>
                Load({
                    fetch: async () => {
                        const cols = await apis.collection.getCollectionsSummary();
                        return cols.filter((c) => c.type != "ApiCollection");
                    },
                    next,
                }),
        ],
        ({theme, collections}, next) => (!theme || !collections ? LoadingIndicator({className: "loading-theme-a343"}) : next()),
        [
            "selectedCollectionID",
            ({collections, routing}, next) =>
                UseState({
                    next,
                    initValue: routing.params.collectionID || null,
                }),
        ],
        [
            "collection",
            ({apis, routing, selectedCollectionID}, next) =>
                selectedCollectionID.value
                    ? Load({
                          fetch: () => apis.collection.getCollection(selectedCollectionID.value),
                          next,
                          _key: selectedCollectionID.value,
                      })
                    : next(null),
        ],
        ({collection}, next) => provideContext("collection", {value: collection}, next),
        ({}, next) => loadModelForCollection({next}),
        [
            "savingQueue",
            ({apis, routing}, next) =>
                ChangeQueue1({
                    fetchLatest: () => apis.collection.getTheme(routing.params.themeId),
                    save: apis.collection.upsertTheme,
                    next,
                }),
        ],
        [
            "form",
            ({theme, savingQueue}, next) =>
                EditThemeLogic({
                    next,
                    theme,
                    updateFunc: async (updatedTheme) => {
                        if (updatedTheme) {
                            savingQueue.push(() => updatedTheme);
                        }
                    },
                }),
        ],
        ({form}, next) => provideContext("theme", form.data.value, next),
        [
            "deleteThemeDialog",
            ({folders, routing}, next) =>
                DeleteThemeDialog({
                    themes: flatten1((folders.value || []).map((f) => f.themes)),
                    onDone: async () => {
                        routing.goto("dashboard", {tab: "themes"});
                    },
                    next,
                }),
        ],
        ["renameThemeDialog", (_, next) => RenameThemeDialog({next})],
        ["viewTree", ({routing}, next) => UseState({next, initValue: ["general"]})],
        ["editThemeTab", ({form, viewTree}, next) => EditThemeTab({next, form, viewTree})],
        ["collectionToast", (_, next) => ToastWithAction({next})],
        [
            "themeCM",
            ({renameThemeDialog, form, theme, deleteThemeDialog, folders, apis, routing, collectionToast}, next) =>
                next([
                    {
                        label: "Rename",
                        onClick: async () => {
                            const name = await renameThemeDialog.show({
                                theme: form.data.value,
                            });
                            if (name) {
                                form.data.onChange({...form.data.value, name});
                            }
                        },
                        id: "rename",
                    },
                    {
                        label: "Clone",
                        onClick: async () => {
                            const name = getCloneName(
                                form.data.value.name,
                                flatten1(folders.value.map((f) => f.themes)).map((c) => c.name)
                            );

                            const theme = await apis.collection.upsertTheme({
                                ...form.data.value,
                                id: null,
                                default: false,
                                name,
                            });

                            await folders.reload();

                            collectionToast.show({
                                text: "Theme successfully cloned",
                                action: {
                                    text: "Go to New Theme",
                                    onClick: () =>
                                        routing.goto("edit-theme", {
                                            themeId: theme.id,
                                        }),
                                },
                            });
                        },
                        id: "clone",
                    },
                    {
                        label: "Set as Default",
                        onClick: async () =>
                            form.data.onChange({
                                ...form.data.value,
                                default: true,
                            }),
                        id: "set_default",
                        disabled: form.data.value.default,
                    },
                    {
                        label: "Reset to Defaults",
                        onClick: () => {
                            const defaultTheme = initTheme({
                                baseTheme: form.data.value.baseTheme,
                            });
                            form.data.onChange({
                                ...form.data.value,
                                general: defaultTheme.general,
                                dataVisualization: defaultTheme.dataVisualization,
                            });
                        },
                        id: "reset",
                    },
                    {
                        label: "Delete Theme",
                        className: "delete-action",
                        onClick: async () =>
                            deleteThemeDialog.show({
                                themes: [theme],
                            }),
                        id: "delete",
                    },
                ]),
        ],
        ({theme, themeCM, editThemeTab, savingQueue, collection, collections, selectedCollectionID, form, collectionToast, viewTree, routing}) => {
            const autoPreviewComponents = {
                general: DesignThemeDashboardAuto,
                canvas: DesignThemeDashboardAuto,
                tiles: DesignThemeDashboardAuto,
                container: ContainerThemeAuto,
                "tile-styles": DesignThemeDashboardAuto,
                button: ContainerButtonsThemeAuto,
                tab: ContainerTabsThemeAuto,
                dropdown: ContainerDropdownThemeAuto,
                buttons: DesignThemeButtonsAuto,
                components: DesignThemeComponentsAuto,
                "data-viz": DesignThemeDashboardAuto,
                "data-color-palettes": DesignThemeDashboardAuto,
                "discrete-colors": DiscreteColorThemeAuto,
                "color-schemes": ColorSchemesThemeAuto,
                "kpi-colors": KpiColorsThemeAuto,
                "other-colors": OtherColorsThemeAuto,
                "visualization-fonts": VizFontsThemeAuto,
                tooltips: TooltipLegendsThemeAuto,
                gauges: GaugeChartThemeAuto,
                pieAndDonutCharts: PieChartThemeAuto,
                "axis-charts": AxisChartThemeAuto,
                tables: TableThemeAuto,
                "table-conditional-format": TableConditionalFormatThemeAuto,
                maps: MapChartThemeAuto,
                "section-text-tile": SectionAndTextTileThemeAuto,
            };

            const excludedKeyRefresh = ["general", "canvas", "tiles", "tile-styles", "data-viz", "data-color-palettes"];

            return (
                theme && (
                    <div className="edit-theme-route-a33">
                        {HeaderBar({
                            name: form.data.value.name,
                            commands: themeCM,
                            noDataType: true,
                            previewList: collections,
                            selectedState: selectedCollectionID,
                            themeID: theme.id,
                            previousRoutes: ["edit-collection", "dashboard"],
                            shareActions: () => (
                                <>
                                    <div className="space" />

                                    {savingQueue.executing ? (
                                        <div className={cx("save-status")}>
                                            <i className="fa fa-spinner fa-pulse" />
                                            Saving
                                        </div>
                                    ) : (
                                        Badge({
                                            size: "lg",
                                            icon: <i className="far fa-check" />,
                                            type: "success",
                                            label: "Saved",
                                        })
                                    )}

                                    <div className="group-buttons">
                                        {PublishThemeButton({
                                            themeID: theme.id,
                                            name: theme.name,
                                            collectionToast,
                                        })}
                                    </div>
                                </>
                            ),
                        })}

                        <div className="page">
                            <div className="left-panel">
                                <div
                                    className={cx("main", {
                                        active: editThemeTab.panelOverride?.active,
                                    })}
                                >
                                    <LeftPanelTabs
                                        tabs={editThemeTab.tabs}
                                        selected={{
                                            value: editThemeTab.selectedTab.value,
                                            onChange: (id) => {
                                                editThemeTab.selectedTab.onChange(id);
                                                viewTree.onChange([id]);
                                            },
                                        }}
                                    />
                                </div>

                                <div
                                    className={cx("override", {
                                        active: editThemeTab.panelOverride?.active,
                                    })}
                                >
                                    {cs(
                                        ({}, next) =>
                                            SuspendUpdate({
                                                active: editThemeTab.panelOverride?.active,
                                                next,
                                            }),
                                        () => editThemeTab.panelOverride?.component
                                    )}
                                </div>
                            </div>

                            <VerbScrollbar
                                style={{
                                    height: "100%",
                                    background: form.data.value.general.canvas.backgroundColorRGB,
                                }}
                                className="content"
                            >
                                {routing.params.collectionID
                                    ? LiveDashboard({
                                          collection,
                                          theme: form.data.value,
                                          forceAutoRefresh: true,
                                      })
                                    : cs(
                                          ["themeKey", (_, next) => next(viewTree.value[viewTree.value.length - 1])],

                                          ({themeKey}, next) =>
                                              IgnoreUpdate({
                                                  props: {themeKey, formValue: form.data.value},
                                                  next,
                                                  when: (pp) =>
                                                      equalDeep(pp.formValue, form.data.value) && excludedKeyRefresh.includes(themeKey) && excludedKeyRefresh.includes(pp.themeKey),
                                              }),
                                          ({themeKey}, next) => {
                                              return themeKey
                                                  ? keyed1({
                                                        key: themeKey,
                                                        next,
                                                    })
                                                  : null;
                                          },
                                          ({themeKey}) =>
                                              autoPreviewComponents[themeKey]?.({
                                                  theme: form.data.value,
                                                  forceAutoRefresh: true,
                                              }) ?? null
                                      )}
                            </VerbScrollbar>
                        </div>

                        {collectionToast.render()}
                    </div>
                )
            );
        }
    );
