import * as React from "react";

import {cs} from "@common/react/chain-services";
import {consumeContext, provideContext} from "@common/react/context";
import {Load} from "@common/react/load";
import {Load2} from "@common/react/load2";
import {UseState} from "@common/react/use-state";
import {dropdownRegistry} from "@common/ui-components/dropdown/dropdown-registry";
import {commonRegistry} from "@common/ui-components/registry/common-registry";
import {WholePageLoading} from "@common/ui-components/whole-page-loading/whole-page-loading";

import {loadRouting} from "../loaders/load-routing";
import {authRoutes} from "./auth-routes";
import {cacheTileDataApi} from "./cache-tile-data-api";
import {loadAuthEndpoint} from "./load-auth-endpoint";
import {loadTenant} from "./load-tenant";
import {loadTileDataApiErrorHandler} from "./tile-data-api-error-handler/load-tile-data-api-error-handler";

import {loadApis} from "../loaders/load-apis";

import {CookieStorage} from "@common/logic/cookie-storage";
import {initApiCache} from "@common/react/cached-api/cached-load";
import {fragments} from "@common/react/fragments";
import {Invoke} from "@common/react/invoke";
import {Watch} from "@common/react/watch";
import {createApiCollectionApis} from "../../apis/data-access/api-collection-apis";
import {ErrorBoundary} from "../../common/error-boundary/error-boundary";
import {gaPageView} from "../../common/ga/ga-pageview";
import {AccountSuspendedService} from "../../routes/common/account-suspended/account-suspended-service";
import {BannerService} from "../../routes/common/banner/banner-service";
import {loadEnvApis} from "../../routes/common/loaders/load-env-apis";
import {modalRegistry} from "../../routes/common/modal/modal-registry";
import {popupRegistry} from "../../routes/common/popup-menu/popup-registry";
import {toastService} from "../../routes/common/toast2/toast-service";
import {tooltipRegistry3} from "../../routes/common/tooltip3/tooltip-registry-3";
import {loadDataSummary} from "../../routes/dashboard/common/load-data-summary";
import {NewUserDialog} from "../../routes/dashboard/env/overview/new-user-dialog/new-user-dialog";
import {checkSingleRouteAccessRole, ProtectedRoute} from "../authorization/authorization";

export const loadAuthRoutes = ({intendedRoute}) =>
    cs(
        ({}, next) => tooltipRegistry3({next}),
        ({}, next) => modalRegistry({next}),
        ({}, next) => dropdownRegistry({next}),
        ({}, next) => commonRegistry({next}),
        ({}, next) => popupRegistry({next}),
        ({}, next) => toastService({next}),
        consumeContext("intercom"),
        consumeContext("auth"),
        initApiCache({}),
        ({auth}, next) =>
            loadApis({
                next,
                onUnauthenticated: () => {
                    auth.invalidate();
                },
            }),

        ["apiDocs", (_, next) => UseState({next})],
        ({}, next) => provideContext("apiCollectionApis", createApiCollectionApis(), next),
        ({}, next) => provideContext("sdkStaticUrl", (url) => url, next),
        ({}, next) => provideContext("apiDocs", null, next),
        ["tenant", ({}, next) => loadTenant({next})],
        consumeContext("apis"),
        ["subscriptionBrief", (_, next) => loadSubscriptionBrief({next})],

        ({subscriptionBrief, auth, apis}, next) =>
            cs(
                [
                    "logout",
                    ({apis}, next) =>
                        next(() => {
                            auth.logout();
                            apis?.user?.signOut();
                        }),
                ],
                ({logout}) => {
                    if (subscriptionBrief.error) {
                        logout();
                        return;
                    }
                    return subscriptionBrief.loading ? WholePageLoading({content: "Loading getting started and subscription brief"}) : next();
                }
            ),
        [
            "defaultEnvironment",
            ({apis, tenant}, next) =>
                cs(["state", ({}, next) => UseState({next})], ({state}) => {
                    if (state.value && tenant) {
                        return next(state.value);
                    }

                    if (!tenant || !tenant.environments) {
                        return WholePageLoading({content: "Loading getting started and subscription brief"});
                    }

                    return fragments(
                        Invoke({
                            fn: async () => {
                                let defaultEnv = tenant.environments[0];

                                if (tenant.environments.length > 2) {
                                } else {
                                    const dataSources = await apis.tenant.getDataSources({namesOnly: true}, defaultEnv.id);

                                    if (dataSources.length == 0) {
                                        const sampleEnv = (tenant.environments || []).find?.((l) => l.name === "Sample Environment");
                                        if (sampleEnv) {
                                            defaultEnv = sampleEnv;
                                        }
                                    }
                                }

                                state.onChange(defaultEnv.id);
                            },
                        }),
                        WholePageLoading({content: "Loading getting started and subscription brief"})
                    );
                }),
        ],
        [
            "routing",
            ({tenant, auth, defaultEnvironment}, next) =>
                defaultEnvironment
                    ? loadRouting({
                          routes: authRoutes,
                          defaultParams: {envId: defaultEnvironment, tab: "recent"},
                          getDefaultRoute: (previousRoutes) => {
                              if (intendedRoute.value) {
                                  // intendedRoute.onChange(null);
                                  return intendedRoute.value;
                              }

                              if (!previousRoutes && !auth.user.isMfaEnabled && !CookieStorage.get("verb:first-time-login")) {
                                  return "/setup-mfa";
                              }

                              const singleRouteAccessRole = checkSingleRouteAccessRole(auth.user.roles);
                              if (singleRouteAccessRole) {
                                  return singleRouteAccessRole.routePath;
                              }

                              return "/dashboard";
                          },
                          next,
                      })
                    : null,
        ],
        (_, next) => {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                login_status: true,
            });
            return next();
        },
        ["newUserGsDialog", (_, next) => NewUserDialog({next})],
        ({newUserGsDialog}, next) =>
            Invoke({
                next,
                fn: async () => {
                    if (CookieStorage.get("verb:first-time-login")) {
                        await newUserGsDialog.show({});
                        CookieStorage.set("verb:first-time-login", null);
                    }
                },
            }),
        ({}, next) => gaPageView({next}),
        ({routing}, next) => ErrorBoundary({next, routing}),

        ({tenant, subscriptionBrief, intercom}, next) => {
            if (tenant.tenant && intercom) {
                intercom.boot({tenant: tenant.tenant, subscription: subscriptionBrief.value});
            }
            return next();
        },

        ({subscriptionBrief}, next) => AccountSuspendedService({next, subscriptionBrief}),
        ({subscriptionBrief}, next) => provideContext("subscriptionBrief", subscriptionBrief, next),

        ({routing}, next) => loadEnvApis({envId: routing.params.envId, next}),

        ({}, next) => cacheTileDataApi({next}),

        ({}, next) => loadDataSummary({next}),

        ({}, next) => loadCollection({next}),

        // ({}, next) => loadThemes({next}),
        // to store filters value set across collection pages
        ({}, next) => cFiltersValueSet({next}),

        ({}, next) => loadModelForCollection({next}),

        ({}, next) => loadModel({next}),

        ({}, next) => loadTileDataApiErrorHandler({next}),

        ({}, next) => loadAuthEndpoint({next}),
        ({}, next) => BannerService({next}),

        ["environment", ({routing, tenant: {environments}}, next) => next(environments?.find?.((e) => e.id === routing.params.envId))],

        // fix route's name is not the latest value.
        consumeContext("routing", "routing2"),
        ({routing2, apiDocs, environment}) => {
            return (
                <ProtectedRoute>
                    {React.createElement(routing2.route.component, {
                        apiDocs,
                        environment,
                    })}
                </ProtectedRoute>
            );
        }
    );

const loadThemes = ({next}) =>
    cs(
        consumeContext("apis"),
        consumeContext("routing"),
        [
            "themes",
            ({apis}, next) =>
                Load({
                    fetch: () => apis.collection.getThemes(),
                    next,
                }),
        ],
        ({themes}) => provideContext("themes", themes, next)
    );

export const loadCollection = ({next}) =>
    cs(
        consumeContext("apis"),
        consumeContext("routing"),
        [
            "collection",
            ({apis, routing}, next) =>
                Load2({
                    _key: routing.params.colId ?? null,
                    fetch: async () => {
                        if (!routing.params.colId) {
                            return null;
                        }
                        // console.log("Loading collection");
                        return apis.collection.getCollection(routing.params.colId);
                    },
                    captureException: true,
                    next,
                }),
        ],
        ({collection, routing}, next) => {
            if (collection.apiError) {
                routing.goto("dashboard");
                return null;
            }
            return next();
        },
        ({collection}) => provideContext("collection", collection, next)
    );

const cFiltersValueSet = ({next: rootNext}) =>
    cs(
        consumeContext("routing"),
        ["filtersValueSet", (_, next) => UseState({next})],
        ({filtersValueSet}, next) => provideContext("filtersValueSet", filtersValueSet, next),
        ({routing, filtersValueSet}) => (
            <>
                {rootNext()}

                {/* clear filters value set when navigating to pages other than collection pages */}
                {Watch({
                    value: routing.params.colId,
                    onChanged: () => filtersValueSet.onChange(null),
                })}
            </>
        )
    );

export const loadModelForCollection = ({next}) =>
    cs(
        consumeContext("apis"),
        consumeContext("collection"),
        [
            "modelForCollection",
            ({apis, collection}, next) =>
                Load({
                    _key: collection.value?.modelID,
                    fetch: async () => {
                        if (collection.value?.modelID) {
                            const model = await apis.model.getModel(collection.value.modelID);
                            return {
                                ...model,
                                tables: model.tables.map((table) => ({
                                    ...table,
                                    columns: table.columns.concat(table.disabledColumns),
                                })),
                            };
                        }

                        return null;
                    },
                    next,
                }),
        ],
        ({modelForCollection}) => provideContext("modelForCollection", modelForCollection, next)
    );

const loadModel = ({next}) =>
    cs(
        consumeContext("apis"),
        consumeContext("routing"),
        [
            "model",
            ({apis, routing}, next) =>
                Load2({
                    _key: routing.params.modelId ?? null,
                    fetch: async () => {
                        if (!routing.params.modelId) {
                            return null;
                        }
                        return apis.model.getModel(routing.params.modelId);
                    },
                    next,
                }),
        ],
        ({model}) => provideContext("model", model, next)
    );

const loadSubscriptionBrief = ({next}) =>
    cs(
        consumeContext("apis"),
        // [
        //     "subscriptionBrief",
        //     ({apis}, next) =>
        //         Load2({
        //             fetch: () => apis.billing.getSubscriptionBrief(),
        //             captureException: true,
        //             next,
        //         }),
        // ],
        ({subscriptionBrief}) =>
            next({
                ...subscriptionBrief,
                loading: false,
                isBoughtFeature: (feature) => {
                    // if (!subscriptionBrief.value) {
                    //     return false;
                    // }

                    return true;

                    // const {types} = subscriptionBrief.value.allowances.find((item) => item.$type == "CollectionTypes");
                    //
                    // return types.split(",").includes(feature);
                },
            })
    );
export const tGetEnvironment = ({next}) =>
    cs(consumeContext("routing"), consumeContext("tenant"), ({routing, tenant: {environments}}) => next(environments?.find?.((e) => e.id === routing.params.envId)));
