const colorAxisAnchor = ({isVertical}) => {
    let anchor;

    return {
        show: ({point}) => {
            const {max, min, dataMin, dataMax} = point.series.colorAxis; // dataMin and dataMax are taken from data, while min and max are padded.
            const realMin = dataMin === dataMax ? 0 : min; // if there is only 1 value, take 0 as min.
            anchor = point.series.chart.container.parentElement.nextElementSibling?.getElementsByClassName("anchor")[0];

            if (!anchor) {
                return;
            }

            const percentPos = ((point.value - realMin) / (max - realMin)) * 100;
            if (isVertical) {
                // anchor.style.top = `${percentPos}%`;
                anchor.style.top = `${100 - percentPos}%`; // to flip the scale's anchor
            } else {
                anchor.style.left = `${percentPos}%`;
            }
            anchor.style.opacity = "1";
        },
        hide: () => {
            if (anchor) {
                anchor.style.opacity = "0";
            }
        },
    };
};
exports.colorAxisAnchor = colorAxisAnchor;
