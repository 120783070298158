import {
    getBrokenRelationshipWithTables,
    getBrokenRowLevelSecurityWithTable,
    getErrorInCalculationOrMeasureColumn,
    getErrorsInDataView,
} from "../../main/model-utils";
import {getErrorViewTablesWhenColumnDeleted, getErrorViewTablesWhenTableDeleted} from "../../main/fix-issues-dialog/fix-issues-common";
import * as React from "react";

export const groupErrors = ({model, dataViewTransforms, dataSources}) => {
    const {relationships, disabledTables, tables} = model;

    const allTables = model.tables.concat(model.disabledTables);

    let errors = [];

    for (const table of disabledTables) {
        const foundErrors = getErrorsInTableDeleted({
            table,
            relationships,
            allTables,
            dataViewTransforms,
        });

        if (foundErrors.length > 0) {
            const ds = (dataSources || []).find((d) => d.id == table.dataSourceID);

            errors.push({
                table,
                reason: `table not found in ${ds?.name}`,
                numberOfErrors: foundErrors.length,
            });
        }
    }

    for (const table of tables) {
        for (let column of table.disabledColumns || []) {
            const foundErrors = getErrorsInColumnDeleted({
                table,
                relationships,
                allTables,
                column,
            });

            if (foundErrors.length > 0) {
                errors.push({
                    table,
                    column,
                    reason: `column not found in ${table.visualName}`,
                    numberOfErrors: foundErrors.length,
                });
            }
        }
    }

    return errors;
};

const findBrokenRowLevelSecurityTables = ({tableID, allTables}) => {
    let brokenErrors = [];
    const tables = allTables.filter((t) => !t.deleted);
    const disabledTables = allTables.filter((t) => t.deleted);

    for (let table of tables) {
        const errors = getBrokenRowLevelSecurityWithTable(table, allTables, disabledTables);
        const isMatchWithThisTable = errors.filter((e) => e.tableID == tableID);
        brokenErrors = brokenErrors.concat(isMatchWithThisTable);
    }

    return brokenErrors;
};

export const getErrorsInColumnDeleted = ({table, relationships, allTables, column}) => {
    let errors = [];

    const brokenRowLevelSecurity = findBrokenRowLevelSecurityTables({tableID: table.id, allTables});

    const usingRelationships = relationships.filter((r) => r.leftColumnID == column.id || r.rightColumnID == column.id);

    for (let relationship of usingRelationships) {
        const targetTable = allTables.find(
            (t) =>
                t.columns.concat(t.disabledColumns).find((c) => c.id == relationship.leftColumnID || c.id == relationship.rightColumnID) &&
                t.id != table.id
        );
        if (targetTable) {
            errors.push({
                label: (
                    <span>
                        {targetTable.visualName} - Relationship - <b className="error-text">{table.visualName}</b>
                    </span>
                ),
                errorType: "relationship",
                relationship,
            });
        }
    }

    const rlsErrorsInColumn = brokenRowLevelSecurity.filter((r) => r.columnID == column.id);

    for (let e of rlsErrorsInColumn) {
        errors.push({
            label: (
                <span>
                    {e.errorInTable.visualName} - <b className="error-text">Row Level Security</b>
                </span>
            ),
            errorType: "rowLevelSecurity",
            errorInTable: e,
        });
    }

    const viewTablesError = getErrorViewTablesWhenColumnDeleted({
        viewTables: allTables.filter((v) => v.$type == "ViewModelTable"),
        columnID: column.id,
    });

    for (const viewTable of viewTablesError) {
        errors.push({
            label: (
                <span>
                    {viewTable.visualName} - Transformation -{" "}
                    <b className="error-text">
                        {table?.visualName}.{column?.name}
                    </b>
                </span>
            ),
            errorType: "transformation",
            transformationID: viewTable.transformationID,
        });
    }

    const calculatorColumnErrors = getErrorInCalculationOrMeasureColumn({table, columnID: column.id});

    for (const calColumn of calculatorColumnErrors) {
        errors.push({
            label: calColumn.label,
            errorType: "transformedColumn",
            errorColumn: calColumn.errorColumn,
        });
    }

    return errors;
};

export const getErrorsInTableDeleted = ({table, relationships, allTables, dataViewTransforms}) => {
    if (!table.deleted) return [];

    let errors = [];
    const tables = allTables.filter((t) => !t.deleted);
    const brokenRowLevelSecurity = findBrokenRowLevelSecurityTables({tableID: table.id, allTables});

    for (let relationship of relationships) {
        const match = table.columns.find((c) => c.id == relationship.leftColumnID || c.id == relationship.rightColumnID);
        const targetTable = tables.find((t) =>
            t.columns.concat(t.disabledColumns).find((c) => c.id == relationship.leftColumnID || c.id == relationship.rightColumnID)
        );

        if (match && targetTable) {
            errors.push({
                label: (
                    <span>
                        {targetTable.visualName} - Relationship - <b className="error-text">{table.visualName}</b>
                    </span>
                ),
                relationship,
                errorType: "relationship",
                dependencyError: (
                    <span>
                        Relationship from this table to <b>{targetTable.visualName}</b> will be removed
                    </span>
                ),
                level: "warning",
            });
        }
    }

    const rlsErrorsInTable = brokenRowLevelSecurity.filter((r) => !r.columnID);

    for (let e of rlsErrorsInTable) {
        errors.push({
            label: (
                <span>
                    {e.errorInTable.visualName} - <b className="error-text">Row Level Security</b>
                </span>
            ),
            errorType: "rowLevelSecurity",
            errorInTable: e,
            dependencyError: (
                <span>
                    Row level security on <b>{e.errorInTable.visualName}</b> will be in error
                </span>
            ),
            level: "error",
        });
    }

    const calculatorColumnErrors = getErrorInCalculationOrMeasureColumn({table});

    for (const calColumn of calculatorColumnErrors) {
        errors.push({
            label: calColumn.dependencyError,
            errorType: "transformedColumn",
            level: "warning",
            dependencyError: calColumn.dependencyError,
        });
    }

    const viewTablesError = getErrorViewTablesWhenTableDeleted({
        viewTables: tables.filter((v) => v.$type == "ViewModelTable"),
        tableID: table.id,
        dataViewTransforms,
    });

    for (const e of viewTablesError) {
        errors.push({
            label: (
                <span>
                    {e.visualName} - <b className="error-text">Transformation</b>
                </span>
            ),
            errorType: "transformation",
            transformationID: e.transformationID,
            dependencyError: (
                <span>
                    Transformation on <b>{e.visualName}</b> - Select Columns will be in error
                </span>
            ),
            level: "error",
        });
    }

    return errors;
};

export const getTableErrors = ({table, relationships, disabledTables, tables, dataViewTransforms}) => {
    let errors = [];
    if (table.deleted) {
        return getErrorInCalculationOrMeasureColumn({table});
    }

    // const brokenRelationshipTables = getBrokenRelationshipWithTables(table, relationships, disabledTables);
    //
    // for (let table of brokenRelationshipTables) {
    //     errors.push({
    //         label: `Broken relationship to ${table.visualName}`,
    //         tableID: table.id,
    //     });
    // }

    const brokenRowLevelSecurityErrors = getBrokenRowLevelSecurityWithTable(table, tables, disabledTables);

    errors = errors.concat(brokenRowLevelSecurityErrors);
    errors = errors.concat(getErrorInCalculationOrMeasureColumn({table}));

    if (table.$type == "ViewModelTable") {
        errors = errors.concat(getErrorsInDataView({table, disabledTables: disabledTables, dataViewTransforms, tables: tables}));
    }

    return errors;
};
