export const extendFunnelSectionPill = (H) => {
    H.wrap(H.seriesTypes.pyramid.prototype, "drawDataLabels", function (proceed) {
        proceed.apply(this, Array.prototype.slice.call(arguments, 1));

        const {chart, points, center} = this;

        const showSectionPills = chart.userOptions.plotOptions.series.dataLabels.comparisonType === "PercentOfPrevious" && chart.userOptions.plotOptions.series.dataLabels.metrics?.includes("Percent");

        // if data labels is enabled and no section pills, just make better section labels
        if (chart.userOptions.plotOptions.series.dataLabels.enabled && !showSectionPills) {
            // resize funnel and reposition labels if needed
            makeBetterSectionLabelsPosition({chart, points, center});
            return;
        }

        // if data labels is not enabled or no section pills, stop
        if (!chart.userOptions.plotOptions.series.dataLabels.enabled || !showSectionPills) {
            return;
        }

        // if pills have been drawn, just update position
        if (chart.sectionPillsGroup) {
            updateSectionPillsPosition({chart, points, center});

            // resize funnel and reposition labels if needed
            makeBetterSectionLabelsPosition({chart, points, center});

            return;
        }

        // draw pills
        drawSectionPills({chart, points, center});

        // resize funnel and reposition labels if needed
        makeBetterSectionLabelsPosition({chart, points, center});
    });
};

const drawSectionPills = ({chart, points, center}) => {
    const formatters = chart.userOptions.formatters;
    const percentFmt = chart.userOptions.plotOptions.series.dataLabels.autoRound ? formatters.percentRoundFormatter : formatters.percentFormatter;
    chart.sectionPillsGroup = chart.renderer.g("section-pills-group").attr({zIndex: 3}).add();

    for (let i = points.length - 1; i > 0; i--) {
        const point = points[i];

        // draw but not show yet
        point.sectionPill = chart.renderer
            .label(percentFmt(point.percentOfPrevious), center[0] + chart.plotLeft, point.dlBox.y)
            .css({
                ...chart.userOptions.plotOptions.series.dataLabels.style,
            })
            .attr({
                fill: chart.userOptions.plotOptions.pyramid.borderColor,
                r: 10,
            })
            .add(chart.sectionPillsGroup);

        // after drawing, update position based on pill width to align center
        point.sectionPill
            .attr({
                transform: `translate(${point.sectionPill.translateX - point.sectionPill.width / 2}, ${point.sectionPill.translateY})`,
            })
            .show();
    }
};

const updateSectionPillsPosition = ({chart, points, center}) => {
    for (let i = points.length - 1; i > 0; i--) {
        const point = points[i];
        point.sectionPill.attr({
            transform: `translate(${center[0] + chart.plotLeft - point.sectionPill.width / 2}, ${point.dlBox.y})`,
        });
    }
};

// if labels cut pills or overlap other sections, make the funnel narrower and move labels to the right
const makeBetterSectionLabelsPosition = ({chart, points, center}) => {
    if (!isCutting({chart, points})) {
        // make sure the labels are text center aligned again if being moved outside before
        makeSectionLabelsTextAlignCenter({points});
        return;
    }

    const narrowScale = getNarrowScale({chart, points});
    makeFunnelNarrower({chart, narrowScale});
    updateSectionPillsPositionForNarrowerFunnel({
        chart,
        points,
        center,
        narrowScale,
    });
    updateSectionLabelsPositionForNarrowerFunnel({
        chart,
        points,
        center,
        narrowScale,
    });
};

// check if labels cut pills or overlap other sections
const isCutting = ({chart, points}) => {
    if (chart.sectionPillsGroup) {
        const doesPillCutLabel = chart.userOptions.plotOptions.pyramid.reversed ? (pillRect, labelRect) => pillRect.y < labelRect.y + labelRect.height : (pillRect, labelRect) => pillRect.y + pillRect.height > labelRect.y;

        for (const point of points) {
            if (!point.sectionPill) {
                continue;
            }
            const pillRect = point.sectionPill.element.getBoundingClientRect();
            const labelRect = point.dataLabel.div.children[0].getBoundingClientRect(); // should get rect of the child span of div
            if (doesPillCutLabel(pillRect, labelRect)) {
                return true;
            }
        }
        return false;
    }

    const doesSectionCutLabel = chart.userOptions.plotOptions.pyramid.reversed ? (sectionRect, labelRect) => sectionRect.y + sectionRect.height < labelRect.y + labelRect.height : (sectionRect, labelRect) => sectionRect.y > labelRect.y;

    for (const point of points) {
        const sectionRect = point.graphic.element.getBoundingClientRect();
        const labelRect = point.dataLabel.div.children[0].getBoundingClientRect();
        if (doesSectionCutLabel(sectionRect, labelRect)) {
            return true;
        }
    }

    return false;
};

const getNarrowScale = ({chart, points}) => {
    const widest = points[0];
    const labelRect = widest.dataLabel.div.children[0].getBoundingClientRect();
    return 1 - (labelRect.width + 10) / chart.chartWidth;
};

const makeFunnelNarrower = ({chart, narrowScale}) => {
    const currentTransform = chart.seriesGroup.element.children[0].getAttribute("transform");
    const newTransform = currentTransform.replace("scale(1 1)", `scale(${narrowScale} 1)`);
    chart.seriesGroup.element.children[0].setAttribute("transform", newTransform);
    chart.seriesGroup.element.children[1].setAttribute("transform", newTransform);
};

const updateSectionPillsPositionForNarrowerFunnel = ({chart, points, center, narrowScale}) => {
    if (!chart.sectionPillsGroup) {
        return;
    }

    const newCenterX = center[0] * narrowScale; // old centerX * scale = new centerX of the narrow funnel
    for (let i = points.length - 1; i > 0; i--) {
        const point = points[i];
        point.sectionPill.attr({
            transform: `translate(${newCenterX + chart.plotLeft - point.sectionPill.width / 2}, ${point.dlBox.y})`,
        });
    }
};

const updateSectionLabelsPositionForNarrowerFunnel = ({chart, points, center, narrowScale}) => {
    for (const point of points) {
        const bbox = point.graphic.getBBox();
        const rect = point.graphic.element.getBoundingClientRect();
        // const newLeft = (bbox.x + bbox.width) * narrowScale; // the old section right * scale = the new right, which is the left position of new label
        const newLeft = bbox.x + rect.width;
        point.dataLabel.div.style.left = `${newLeft}px`;
        point.dataLabel.div.children[0].children[0].style["text-align"] = "left";

        // correctly position the first section label
        if (chart.userOptions.plotOptions.pyramid.reversed) {
            // displayType pyramid
            if (point.colorIndex === points.length - 1) {
                const newTop = bbox.y;
                point.dataLabel.div.style.top = `${newTop}px`;
                point.dataLabel.div.children[0].style.top = `0px`;
            }
        } else {
            if (point.colorIndex === 0) {
                const newTop = bbox.y;
                point.dataLabel.div.style.top = `${newTop}px`;
                point.dataLabel.div.children[0].style.top = `0px`;
            }
        }
    }

    // hide overlapping labels
    for (let i = points.length - 1; i > 0; i = i - 2) {
        const point = points[i];
        const nextPoint = points[i - 1];
        const rect = point.dataLabel.div.children[0].getBoundingClientRect();
        const nextRect = nextPoint.dataLabel.div.children[0].getBoundingClientRect();

        const isCut = chart.userOptions.plotOptions.pyramid.reversed ? (rect, nextRect) => rect.y + rect.height > nextRect.y : (rect, nextRect) => rect.y < nextRect.y + nextRect.height;

        if (isCut(rect, nextRect)) {
            nextPoint.dataLabel.div.style.visibility = `hidden`;
        }
    }
};

const makeSectionLabelsTextAlignCenter = ({points}) => {
    for (const point of points) {
        point.dataLabel.div.children[0].children[0].style["text-align"] = "center";
    }
};
