import * as React from "react";
import * as ReactDOM from "react-dom";
import {UseState} from "@common/react/use-state";
import {Static2} from "@common/react/static-2";
import {cs} from "@common/react/chain-services";
import {OnMounted} from "@common/react/on-mounted";
import {RAFLoop} from "@common/utils/loop";

export const VerbShadowRoot = ({render, containerDOM}) =>
    cs(
        ["init", (_, next) => UseState({next})],
        ["shadowRoot", (_, next) => Static2({next})],
        ["dom", (_, next) => Static2({next})],
        ({dom, shadowRoot, init}, next) =>
            OnMounted({
                next,
                action: () => {
                    setTimeout(() => {
                        const container = containerDOM ?? dom.get();
                        shadowRoot.set(container.attachShadow({mode: "open"}));
                        init.onChange(true);

                        const removeInterval = RAFLoop.addTarget({
                            update: () => {
                                if (window.verbLoadingCSS == 0) {
                                    for (let element of window.verbStyleSheet) {
                                        const clone = element.cloneNode(true);
                                        container.shadowRoot.append(clone);
                                    }
                                    removeInterval();
                                }
                            },
                        });

                        // if (window.verbStyleSheet?.length > 0) {
                        //     for (let element of window.verbStyleSheet) {
                        //         const clone = element.cloneNode(true);
                        //         container.shadowRoot.append(clone);
                        //     }
                        // } else {
                        // setTimeout(() => {
                        //     window.verbStyleSheet = [];
                        //
                        //     const elements = container.shadowRoot.querySelectorAll(`[verb-css="true"]`);
                        //     for (let element of elements) {
                        //         const clone = element.cloneNode(true);
                        //         window.verbStyleSheet.push(clone);
                        //     }
                        // }, 1000);
                        // }
                    }, 0);
                },
            }),
        ({dom, init, shadowRoot}) => {
            if (!containerDOM) {
                return (
                    <div x-verb-dashboard="true" ref={dom.set}>
                        {init.value && ReactDOM.createPortal(render(shadowRoot.get()), shadowRoot.get())}
                    </div>
                );
            }

            if (!init.value) return null;

            return ReactDOM.createPortal(render(shadowRoot.get()), shadowRoot.get());
        }
    );
