const {reverse} = require("../../../../../utils/collections");
const {createMainNumberFormatter} = require("./main-number-formatter");
const {chain2, chain} = require("../../../../../utils/fs");
const {currencies} = require("../../../factory/formatters/currencies");

const createNumericFormatter = (
    {
        displayType, // Number, Percentage, Ratio, Currency, CurrencyRatio
        decimalSeperator, // Dot, Comma
        thousandsSeperator, // None, Comma, Dot, Space
        decimalPlaces, // int
        negativeFormat, // MinusSign, Parenthesis
        currencyProperties,
    },
    {autoRound} = {}
) => {
    const chain1 = [
        createMainNumberFormatter(
            {
                displayType,
                decimalSeperator,
                thousandsSeperator,
                decimalPlaces,
                negativeFormat,
            },
            {autoRound}
        ),
        displayType === "Percentage" ? (_) => _ + "%" : displayType === "Currency" ? createCurrencyFormatter(currencyProperties) : displayType === "Ratio" && ((_) => _ + " : 1"),
    ].filter((v) => v);

    return (number) => {
        // let conversionNumber = number;
        // //TODO: calculate rate * number
        // if(sdkConversion && displayType === "Currency") {
        //     conversionNumber = sdkConversion.exchange_rates[sdkConversion.displayCode] * number
        // }

        return chain2(number, chain1);
    };
};
exports.createNumericFormatter = createNumericFormatter;

const createCurrencyFormatter = ({
    currency, // USD
    currencySymbolPlacement, // Left, Right
    currencySymbol, // $
    spaceBetweenSymbolAndNumber, // true/false
}) => {
    if (currencySymbol) {
        let symbol = currencySymbol;

        if (window.VerbCurrencySymbolConfigOverride) {
            if (currencies.find((c) => c.symbol == symbol)) {
                symbol = window.VerbCurrencySymbolConfigOverride;
            }
        }

        return (numStr) => {
            return chain(
                [symbol, numStr],
                (_) => (currencySymbolPlacement === "Left" ? _ : reverse(_)),
                (_) => _.join(spaceBetweenSymbolAndNumber ? " " : "")
            );
        };
    } else {
        return (numStr) => {
            return numStr + " " + currency;
        };
    }
};
