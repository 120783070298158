import "./filter-edit-main.scss";
import * as React from "react";

import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {FilterOptionsTab} from "./options-tab/filter-options-tab";
import {TabsHeader} from "../../../../common/tabs/tabs-header";
import {FilterConnectedTilesTab} from "./connected-tiles-tab/filter-connected-tiles-tab";
import {FilterPreview} from "./filter-preview/filter-preview";
import {UseState} from "@common/react/use-state";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";

export const FilterEditMain = ({filter, form}) =>
    cs(
        [
            "activeTab",
            (_, next) =>
                UseState({
                    initValue: "options",
                    next,
                }),
        ],
        consumeContext("collection"),
        [
            "tabs",
            ({collection}, next) => {
                const {$type, gridLocations} = collection.value;
                return next(
                    [
                        {
                            name: "options",
                            label: "Options",
                            render: () =>
                                FilterOptionsTab({
                                    filter,
                                    form,
                                    showCustomizations: $type !== "ApiCollection",
                                }),
                        },
                        $type !== "ApiCollection" &&
                            gridLocations?.length > 0 && {
                                name: "connected-tiles",
                                label: "Connected Tiles",
                                render: () => FilterConnectedTilesTab({filter, form}),
                            },
                    ].filter((v) => v)
                );
            },
        ],
        ({tabs, activeTab, collection}) => {
            return (
                <div className="filter-edit-main-34f">
                    <div className="preview">
                        <FilterPreview
                            {...{
                                filter: filter.value,
                                collection: collection.value,
                                form,
                            }}
                        />
                    </div>
                    <div className="setting-area">
                        <div className="header">{rNavTabs({activeTab, tabs})}</div>
                        <VerbScrollbar style={{height: "calc(100%)"}} className="content">
                            {tabs.find((t) => t.name === activeTab.value).render()}
                        </VerbScrollbar>
                    </div>
                </div>
            );
        }
    );

const rNavTabs = ({activeTab, tabs}) =>
    TabsHeader({
        className: "filter-nav-tabs",
        tabs: tabs.map((tab) => ({
            ...tab,
            onClick: () => activeTab.onChange(tab.name),
            label: <div className="filter-nav-tab-label">{tab.label}</div>,
            active: activeTab.value === tab.name,
        })),
    });
