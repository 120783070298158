import "./legend.scss";

import * as React from "react";

import {ShowHideToggle} from "../../../../../../../../../../../../../common/form/toggles/show-hide-toggle";
import {scope} from "@common/react/scope";
import {LegendPositionSelect} from "../../../common/legend-position-select";
import {LegendSizeSelect} from "../../../common/legend-size-select";

export const Legend = ({tile}) => ({
    label: "Legend",
    control: ShowHideToggle({
        state: scope(tile, ["style", "legendStyle", "legendShown"]),
    }),
    render: () => (
        <div className="gauge-legend-st4">
            <div className="position-select">
                {LegendPositionSelect(scope(tile, ["style", "legendStyle", "legendPosition"]))}
                {LegendSizeSelect(scope(tile, ["style", "legendStyle", "legendSize"]), tile.value.style.legendStyle)}
            </div>
        </div>
    ),
});
