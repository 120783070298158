const {Load2} = require("./load2");
const {cs} = require("./chain-services");

const Load = ({fetch, next, _key, captureException}) =>
    cs(
        [
            "load2",
            ({}, next) =>
                Load2({
                    fetch,
                    next,
                    _key,
                    captureException,
                }),
        ],
        ({load2}) => next && next(!captureException ? load2.value : load2.value || load2.error)
    );
exports.Load = Load;
exports.Load1 = Load;
