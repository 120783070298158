import "./edit-tile-action.scss";

import React from "react";

import {scope} from "@common/react/scope";
import {Invoke} from "@common/react/invoke";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";

import {stateToSelect} from "@common/ui-components/form/state-to-select";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {isDisableControlFilterAction, navigationActionTypes} from "@common/ui-components/charts/common/action-menu/action-menu-utils";
import {Button} from "@common/form/buttons/button/button";
import {ConfirmDialog} from "../../../../../../../../common/confirm-dialog/confirm-dialog";
import {LeftPanelClosable} from "../../../../../../../../common/left-panel-closable/left-panel-closable";
import {DebounceTextInput} from "@common/form/debounce-input/debounce-text-input";
import {arrEquals, isEmpty} from "@common/utils/collections";
import {isEmpty as isObjectEmpty} from "@common/utils/objects";
import {StaticTooltipService} from "../../../../../../../../common/tooltip3/static-tooltip-service";

import {ValueChipInput} from "./common/value-chip-input";
import {ActionFields} from "./action-fields/action-fields";
import {UrlTemplate} from "./common/url-template/url-template";
import {JsParameters} from "./common/js-parameters/js-parameters";
import {fieldsInTile, getTablesInTile} from "./common/get-tables-in-tile";
import {getActionFields} from "./action-fields/action-field-utils";

export const EditTileAction = ({tile, tileAction, fieldType, onCancel, onRemove, onManageChildTile}) =>
    cs(
        (_, next) =>
            cs(
                [
                    "confirmDialog",
                    (_, next) =>
                        ConfirmDialog({
                            title: "Action is Incomplete",
                            body: (
                                <>
                                    This tile action is not complete and cannot be used on your collection. You may save the action and
                                    return to the parent tile or cancel to complete the action.
                                </>
                            ),
                            submitText: "Save Incomplete Action",
                            next,
                        }),
                ],
                ({confirmDialog}) =>
                    LeftPanelClosable({
                        onClose: async () => {
                            const {enabledTileFieldIDs, tile, $type} = tileAction?.value ?? {};
                            if (isEmpty(enabledTileFieldIDs) || ($type == "OpenNewTileAction" && isObjectEmpty(tile))) {
                                const status = await confirmDialog.show();
                                if (!status) return;
                            }
                            onCancel();
                        },
                        label: tileAction?.value?.id ? tileAction?.value?.name || `[Action]` : "Create An Action",
                        content: next(),
                    })
            ),
        [
            "tablesInTile",
            (_, next) => {
                return next(
                    getTablesInTile({
                        tile: tile.value,
                        ...fieldsInTile[tile.value.$type],
                    })
                );
            },
        ],
        ({tablesInTile}) => {
            let list = [
                {label: "Open New Tile", value: "OpenNewTileAction"},
                {label: "Navigate to URL", value: "NavigateTileAction"},
                {label: "Trigger Javascript", value: "TriggerTileAction"},
            ];
            if (!isDisableControlFilterAction(tile.value)) {
                list = list.concat({
                    label: "Control Collection Filters",
                    value: "ControlCollectionFiltersAction",
                    menuTitleBefore: "Filter on",
                });
            }

            return (
                <div className="add-action-8io">
                    <div className="sub-section">Configuration</div>

                    {Invoke({
                        onMounted: async () => {
                            if (tile.value.$type === "MapTile") {
                                const fields = getActionFields(tile);
                                const preSelectFieldIDs = getPreSelectFieldIds(tileAction.value?.$type, fields, tile);

                                if (!arrEquals(preSelectFieldIDs, tileAction.value?.enabledTileFieldIDs)) {
                                    tileAction.change((old) => ({
                                        ...old,
                                        enabledTileFieldIDs: preSelectFieldIDs,
                                    }));
                                }
                            }
                        },
                    })}

                    {DropdownSelect({
                        list: list,
                        label: "Action Type",
                        valueToLabel: (v) => v.label,
                        isSelected: (v) => v.value === tileAction.value?.$type,
                        onChange: (v) => {
                            const fields = getActionFields(tile);

                            return tileAction.change((old) => ({
                                ...old,
                                enabledTileFieldIDs: getPreSelectFieldIds(v.value, fields, tile),
                                $type: v.value,
                                name: v.label,
                                menuTitleBefore: v.menuTitleBefore || "View",
                                menuTitleAfter: v.menuTitleAfter || "Details",
                            }));
                        },
                    })}

                    {tile.value.$type === "TableTile" &&
                        tileAction.value?.$type === "OpenNewTileAction" &&
                        DropdownSelect({
                            list: ["Exclusive", "Additive"],
                            label: "Filter Logic",
                            info: (
                                <>
                                    <strong>Additive</strong>: When the end-user selects any active field on the row, all configured filter
                                    values will be applied to the child tile.
                                    <br />
                                    <strong>Exclusive</strong>: When the end-user selects any active field, only that value is applied to
                                    the child tile.
                                </>
                            ),
                            isSelected: (v) => v === tileAction.value?.filterLogic,
                            onChange: (v) => tileAction.change((old) => ({...old, filterLogic: v})),
                        })}

                    {cs(
                        [
                            "tileActionView",
                            (_, next) =>
                                next(
                                    {
                                        OpenNewTileAction: OpenNewTile,
                                        NavigateTileAction: GoToURL,
                                        TriggerTileAction: TriggerJS,
                                        ControlCollectionFiltersAction: ControlFilter,
                                    }[tileAction.value.$type]
                                ),
                        ],
                        ({tileActionView}) =>
                            tileActionView({
                                tile,
                                tileAction,
                                fieldType,
                                onManageChildTile,
                                tablesInTile,
                            })
                    )}

                    <div className="sub-section">Labels</div>

                    {DebounceTextInput({
                        label: "Action Name",
                        state: scope(tileAction, ["name"]),
                    })}

                    {ValueChipInput({
                        tile,
                        tileAction,
                        label: "Menu Title",
                        beforeField: "menuTitleBefore",
                        afterField: "menuTitleAfter",
                        hideAfterField: tileAction.value.$type == "ControlCollectionFiltersAction",
                    })}

                    {cs(
                        [
                            "confirmDialog",
                            (_, next) =>
                                ConfirmDialog({
                                    title: "Delete Action",
                                    body: <>Are you sure that you want to delete this tile action?</>,
                                    next,
                                }),
                        ],
                        ({confirmDialog}) => (
                            <div className="delete">
                                <Button
                                    btnType="danger"
                                    onClick={async () => {
                                        const status = await confirmDialog.show();
                                        if (status) onRemove?.();
                                    }}
                                >
                                    Delete Action
                                </Button>
                            </div>
                        )
                    )}
                </div>
            );
        }
    );

const OpenNewTile = ({tile, tileAction, onManageChildTile}) =>
    cs(() => (
        <>
            {DropdownSelect({
                list: [
                    {label: "Open as Modal", value: "Modal"},
                    {label: "Open in Same Tile", value: "SameTile"},
                ],
                label: "Open Type",
                valueToLabel: (v) => v.label,
                ...stateToSelect(scope(tileAction, ["openType"]), ["value"]),
            })}

            {ActionFields({tile, tileAction})}

            <div className="manage-tile">
                <Button btnType="secondary" onClick={onManageChildTile}>
                    Manage Tile
                </Button>
            </div>
        </>
    ));

const GoToURL = ({tile, tileAction, fieldType, tablesInTile}) =>
    cs(consumeContext("tileFields"), ({tileFields}) => {
        return (
            <>
                {DropdownSelect({
                    list: navigationActionTypes,
                    valueToLabel: (v) => v.label,
                    label: "Open URL As",
                    ...stateToSelect(scope(tileAction, ["navigationType"]), ["value"]),
                })}

                {ActionFields({tile, tileAction, isOnlySelected: true})}

                <div className="section url">
                    {LabelInfo({
                        label: "URL Template",
                        info: "The URL Template will utilize the values based on the action field selected. The fields available to format the template must come from tables used in the parent tile or the viewing user's profile. If you need other data we suggest adding a calculated column or creating a new view to include the necessary data.",
                    })}

                    {UrlTemplate({
                        tileAction,
                        fieldType,
                        tablesInTile,
                        tileFields,
                    })}
                </div>
            </>
        );
    });

const TriggerJS = ({tile, fieldType, tileAction, tablesInTile}) =>
    cs(() => (
        <>
            {ActionFields({tile, tileAction, isOnlySelected: true})}

            <div className="section url">
                {LabelInfo({
                    label: "JS Parameters",
                    info: "The JS Parameters will utilize the values based on the action field selected. The fields available must come from tables used in the parent tile. If you need other data we suggest adding a calculated column or creating a new view to include the necessary data.",
                })}

                {JsParameters({
                    tile,
                    tileAction,
                    fieldType,
                    tablesInTile,
                })}
            </div>
        </>
    ));

const ControlFilter = ({tile, tileAction}) =>
    cs(() => (
        <>
            {ActionFields({
                tile,
                tileAction,
                label: "Select Fields",
                info: "The fields listed are dimensions or categories on this tile. The field must correlate to a collection filter to be active. If filters or fields are changed this list will update and enable new filter options by default.",
            })}
        </>
    ));

export const LabelInfo = ({info, label}) =>
    cs(
        [
            "staticTooltip",
            (_, next) =>
                info
                    ? StaticTooltipService({
                          direction: "top",
                          info: info,
                          tooltipBoxWidth: 200,
                          topOffset: 20,
                          leftOffset: -5,
                          next,
                      })
                    : next(null),
        ],
        ({staticTooltip}) => (
            <div className="label-info-99p">
                {label}
                {staticTooltip?.renderIcon({
                    icon: <i className="material-icons">info</i>,
                    className: "info-tooltip",
                })}
            </div>
        )
    );

export const getPreSelectFieldIds = (tileActionType, fields, tile) => {
    if (tile.value.$type == "MapTile" || tileActionType == "ControlCollectionFiltersAction") {
        let ret = [];
        for (let field of fields) {
            ret.push(field.id);
        }

        return ret;
    }

    return fields.length > 0 ? [fields[0].id] : [];
};
