import {cs} from "@common/react/chain-services";
import {consumeContext, provideContext} from "@common/react/context";
import {createReportApis} from "./report-apis";
import {RoutingParam} from "../common/routing/routing-param";
import {DashboardLayout} from "../dashboard/layout/dashboard-layout";
import {ReportBuilderMain} from "./report-builder-main";
import {Load} from "@common/react/load";
export const ReportBuilderRoute = ({}) =>
    cs(
        consumeContext("routing"),
        ["envId", (_, next) => RoutingParam({param: "envId", next})],
        ({routing, envId}, next) =>
            DashboardLayout({
                tab: {
                    value: null,
                    onChange: (tab) => routing.goto("dashboard", {envId: envId.value, tab}),
                },
                envId: {
                    value: envId.value,
                    onChange: (envId) => routing.goto("dashboard", {envId}),
                },
                next,
            }),
        ["reportApis", (_, next) => next(createReportApis())],
        ({reportApis}, next) => provideContext("reportApis", reportApis, next),
        [
            "reportSections",
            ({reportApis}, next) =>
                Load({
                    fetch: async () => {
                        return await reportApis.getReportSections();
                    },
                    next,
                }),
        ],
        ({routing, reportSections}) => ReportBuilderMain({reportSections, defaultTab: routing.params.setting})
    );
