import {cx} from "emotion";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import "./unstructured-settings-tree.scss";
import {arrEquals, sort} from "@common/utils/collections";
import {Watch} from "@common/react/watch";

export const UnstructuredSettingsTree = ({list, level = 0, selected}) =>
    cs(() =>
        sort(list, (t) => t.originalName).map((item) => {
            let selectedStack = selected.value.stack;

            return ExpandableSelect({
                disabled: item.disabled,
                level,
                forceExpand: selectedStack ? arrEquals(item.stack, selectedStack.slice(0, selectedStack.length - 1)) : false,
                isSelected: () => selected.value.name === item.name,
                onSelect: () => selected.onChange(item),
                initExpand: false,
                label: item.stack[level + 1],
                ...(item.children.length > 0
                    ? {
                          render: () =>
                              UnstructuredSettingsTree({
                                  list: item.children,
                                  level: level + 1,
                                  selected,
                              }),
                      }
                    : {}),
            });
        })
    );

const ExpandableSelect = ({label, initExpand = true, render, level, dataID, className, isSelected, onSelect, forceExpand, disabled}) =>
    cs(["expanding", (_, next) => UseState({initValue: initExpand, next})], ({expanding}) => {
        return (
            <>
                {Watch({
                    value: forceExpand && !expanding.value,
                    onChanged: (value) => value && expanding.onChange(value),
                })}

                <div
                    className={cx(
                        "expandable expandable-select-2si",
                        {
                            selected: isSelected(),
                            expanding: expanding.value && render,
                        },
                        className
                    )}
                    data-id={dataID}
                >
                    <div
                        className={cx("toggle", {disabled})}
                        style={{
                            paddingLeft: level * 8 + 14,
                        }}
                        onClick={() => {
                            if (!disabled) {
                                onSelect?.();
                                expanding.onChange(!expanding.value);
                            }
                        }}
                    >
                        {render ? <i className={cx("fas fa-angle-right", !expanding.value ? "right" : "down")} /> : <i style={{width: 7}} />}

                        <div className="label">{label}</div>
                    </div>
                    {render && expanding.value && <div className="content">{render?.()}</div>}
                </div>
            </>
        );
    });
