import {UseMemo} from "@common/react/use-memo";
import {Watch} from "@common/react/watch";
import {chain} from "@common/utils/fs";

const {cs} = require("../../react/chain-services");
const {fragments} = require("../../react/fragments");
const {keyed} = require("../../react/keyed");
const {OnUnmounted} = require("../../react/on-unmounted");
const {Static2} = require("../../react/static-2");
const {le, ge} = require("../../utils/dates/date-object");

const createNavigateControl = ({selectabledRange}) => {
    const {from, to} = selectabledRange ?? {};
    const canLeft = (m) => {
        if (!from || m.year > from.year) {
            return true;
        }
        if (m.year === from.year) {
            return m.month >= from.month;
        }
        return false;
    };
    const canRight = (m) => {
        if (!to || m.year < to.year) {
            return true;
        }
        if (m.year === to.year) {
            return m.month <= to.month;
        }
        return false;
    };
    return {
        canLeft,
        canRight,
    };
};

const createSelectableRangeControl = ({selectabledRange}) => {
    const {from, to, message} = selectabledRange ?? {};

    const isSelectable = (day) => {
        if (!from && !to) {
            return true;
        }

        return from ? (to ? le(day, to) && ge(day, from) : ge(day, from)) : le(day, to);
    };

    const navigation = createNavigateControl({selectabledRange});

    return {
        isSelectable,
        navigation,
        message,
    };
};

const SelectControl = ({next: rootNext, selectabledRange, checkDayValid, yearLimit}) => {
    return cs(
        [
            "state",
            (_, next) => {
                return UseMemo({
                    fn: () => createSelectableRangeControl({selectabledRange}),
                    deps: [JSON.stringify(selectabledRange)],
                    next,
                });
            },
        ],
        ({state}) => {
            const {isSelectable, navigation, message} = state;
            return fragments(
                rootNext({
                    selectable: (day) => {
                        if (day.year > yearLimit.max || day.year < yearLimit.min) {
                            return {
                                value: false,
                            };
                        }
                        const {disabled, message: validMessage} = checkDayValid?.(day) ?? {disabled: false};
                        const selectable = isSelectable(day);
                        return {
                            value: !disabled && selectable,
                            message: disabled ? validMessage : !selectable ? message : null,
                        };
                    },
                    navigation,
                })
            );
        }
    );
};

export {SelectControl};
