import React from "react";

import {Invoke} from "@common/react/invoke";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {spc} from "@common/react/state-path-change";

import {Button} from "../../../../../../common/form/buttons/button/button";
import {ProgressBtn} from "../../../../../../common/form/buttons/progress-button/progress-button";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";

import {DialogService} from "../../dialog/dialog-service";
import {Timer} from "../timer";
import {waitTimeout} from "@common/utils/wait-timeout";
import {OnUnmounted} from "@common/react/on-unmounted";

const REQUEST_ACCESS_STEP = {
    INTRO: 0,
    REQUESTING: 1,
    DENIED: 99,
};
export const RequestAccessDialog = ({next: rootNext, item, goBack, otherButtons, hub, toast, timeoutSeconds = 30}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    strong: true,
                    next: rootNext,
                    render: ({resolve, args = {}}) => ({
                        width: 488,
                        noHeader: true,
                        initShow: true,
                        content: next({resolve, ...args}),
                        className: "single-editor-dialog-wrapper-d2v",
                    }),
                }),
        ],
        ["disabled", (_, next) => UseState({next})],
        ({modal, disabled}) =>
            cs(
                [
                    "timer",
                    (_, next) =>
                        Timer({
                            next,
                            defaultTimeout: timeoutSeconds * 1000,
                            startOnInit: false,
                        }),
                ],
                [
                    "state",
                    (_, next) =>
                        UseState({
                            next,
                            initValue: {
                                step: REQUEST_ACCESS_STEP.INTRO,
                            },
                        }),
                ],
                ({timer, state}, next) =>
                    Invoke({
                        next,
                        fn: () => {
                            timer.on("timeout", async () => {
                                await hub.demandAccess();
                                const oldEditingUser = hub.currentEditingUser.get();

                                toast?.show(`${oldEditingUser.name ?? "Another user"}'s latest changes are saved and you may begin editing this ${item.type}.`);

                                hub.currentEditingUser.onChange({
                                    id: hub.userId,
                                    name: null,
                                });
                                modal.resolve(true);
                            });

                            hub.events.on("accessResponse", (...args) => {
                                const newEditorID = args[0];
                                timer.stop();
                                // auto denied if returned user's ID is difference.
                                if (!args[args.length - 1] || newEditorID != hub.userId) {
                                    spc(state, ["step"], () => REQUEST_ACCESS_STEP.DENIED);
                                }
                            });
                        },
                    }),
                [
                    "controlButtons",
                    ({state, timer}, next) =>
                        next(({resolve}, disabled) => {
                            const {step} = state.value;

                            const onBack = () => {
                                resolve(false);
                                goBack();
                            };

                            const maps = {
                                [REQUEST_ACCESS_STEP.INTRO]: () => (
                                    <div className="buttons">
                                        {(otherButtons ?? []).map((btn) => (
                                            <Button key={btn.label} btnType="secondary" onClick={btn.function}>
                                                {btn.label}
                                            </Button>
                                        ))}

                                        <div className="space" />

                                        <Button btnType="border" onClick={onBack}>
                                            {otherButtons?.length > 0 ? "Go Back" : `Return to ${item.parent}`}
                                        </Button>

                                        <Button
                                            disabled={disabled.value}
                                            onClick={async () => {
                                                spc(state, ["step"], () => REQUEST_ACCESS_STEP.REQUESTING);
                                                timer.start();
                                                await hub?.requestAccess();
                                            }}
                                        >
                                            Request Access
                                        </Button>
                                    </div>
                                ),
                                [REQUEST_ACCESS_STEP.REQUESTING]: () => (
                                    <div className="buttons">
                                        <Button
                                            btnType="danger"
                                            onClick={async () => {
                                                timer.reset();
                                                spc(state, ["step"], () => REQUEST_ACCESS_STEP.INTRO);
                                                await hub.cancelRequestAccess();
                                            }}
                                        >
                                            Cancel Request
                                        </Button>
                                    </div>
                                ),
                                [REQUEST_ACCESS_STEP.DENIED]: () => (
                                    <div className="buttons">
                                        {(otherButtons ?? []).map((btn) => (
                                            <Button key={btn.label} btnType="secondary" onClick={btn.function}>
                                                {btn.label}
                                            </Button>
                                        ))}

                                        <div className="space" />

                                        <Button disabled={disabled.value} onClick={onBack}>
                                            Return to {item.parent}
                                        </Button>
                                    </div>
                                ),
                            };
                            return maps[step]?.() ?? null;
                        }),
                ],
                [
                    "renderBody",
                    ({state, timer}, next) =>
                        next(({currentEditingUser}) => {
                            const {step} = state.value;
                            const maps = {
                                [REQUEST_ACCESS_STEP.INTRO]: () => (
                                    <div className="messages" style={{height: 201}}>
                                        <span className="material-icons-outlined danger">warning</span>
                                        <div className="message-line">
                                            <b>{currentEditingUser.value?.name ?? "Another user"}</b> is currently editing this {item.type}
                                        </div>
                                        <div className="message-line small">
                                            To avoid conflicts in the configuration of a {item.type}, <b>only one user may edit the {item.type} at a time</b>.
                                        </div>
                                        <div className="message-line small">
                                            By requesting access, {currentEditingUser.value?.name ?? "another user"} will have {timeoutSeconds} seconds to grant or reject the
                                            transfer of control.
                                        </div>
                                    </div>
                                ),
                                [REQUEST_ACCESS_STEP.REQUESTING]: () => (
                                    <div className="messages" style={{height: 201}}>
                                        <span className="material-icons warning">timer</span>
                                        <div className="message-line">
                                            <b>Access requested</b>
                                        </div>
                                        <div className="message-line small">
                                            Waiting for {currentEditingUser.value?.name ?? "another user"}
                                            's response
                                        </div>
                                        <ProgressBar progress={(timer.remaining / timer.defaultTimeout) * 100} />
                                        <div className="time-remaining-text">{Math.floor(timer.remaining / 1000)} seconds left</div>
                                    </div>
                                ),
                                [REQUEST_ACCESS_STEP.DENIED]: () => (
                                    <div className="messages" style={{height: 201}}>
                                        <span className="material-icons danger">report_gmailerrorred</span>
                                        <div className="message-line">
                                            <b style={{color: "#FF5959"}}>Access denied</b>
                                        </div>
                                        <div className="message-line small">
                                            {currentEditingUser.value?.name ?? "Another user"} denied your request. If you need more details please reach out to them individually.
                                        </div>
                                    </div>
                                ),
                            };

                            return maps[step]();
                        }),
                ],
                ({controlButtons, renderBody}) => (
                    <div className="single-editor-dialog-vc0">
                        <VerbDialogBodyScrollbar>{renderBody(modal)}</VerbDialogBodyScrollbar>

                        {controlButtons(modal, disabled)}
                    </div>
                )
            )
    );

export const RESPONSE_ACCESS_STEP = {
    FIRST: 0,
    SECOND: 1,
    INACTIVE_FORCE_TRANSFERRED: 2,
    NO_RESPOND_FORCE_TRANSFERRED: 3,
};

export const ResponseAccessDialog = ({next: rootNext, item, goBack, requestUserCache, hub, timer, toast}) =>
    cs(
        [
            "state",
            (_, next) =>
                UseState({
                    next,
                    initValue: {
                        step: RESPONSE_ACCESS_STEP.SECOND,
                    },
                }),
        ],
        [
            "modal",
            ({state}, next) =>
                DialogService({
                    strong: true,
                    next: (props) =>
                        rootNext({
                            ...props,
                            show: async ({forceToStep, ...other}) => {
                                if (Object.values(RESPONSE_ACCESS_STEP).includes(forceToStep)) {
                                    state.onChange({step: forceToStep});
                                    await waitTimeout(1);
                                }
                                props.show(other);
                            },
                        }),
                    registryRender: true,
                    render: ({resolve, args = {}}) => ({
                        width: 488,
                        noHeader: true,
                        initShow: true,
                        content: next({resolve, ...args}),
                        className: "single-editor-dialog-wrapper-d2v",
                    }),
                }),
        ],
        ["disabled", (_, next) => UseState({next})],
        ({modal, state, disabled}) =>
            cs(
                [
                    "controlButtons",
                    ({}, next) =>
                        next(({resolve, requestUser}, disabled) => {
                            let {step} = state.value;
                            const requestUserName = requestUser?.name ?? "Another user";

                            const decline = async () => {
                                await hub.respondToAccessRequest(requestUser.id, false);
                                toast.show(`${requestUserName} has been notified of your response.`);
                                modal.resolve(false);
                                timer.stop();
                                state.onChange({
                                    isOpenBanner: false,
                                    requestUser: null,
                                });

                                requestUserCache.set(null);
                            };

                            const accept = async () => {
                                requestUserCache.set(null);
                                await hub.respondToAccessRequest(requestUser.id, true);
                                hub.currentEditingUser.onChange(requestUser);
                                toast.show(`Edit access was transferred to ${requestUserName}.`);
                                modal.resolve(true);
                                goBack();
                            };

                            const closeAndBack = async () => {
                                resolve(true);
                                goBack();
                            };

                            const maps = {
                                [RESPONSE_ACCESS_STEP.FIRST]: () => (
                                    <div className="buttons">
                                        <Button btnType="danger" onClick={decline}>
                                            No, I’m still editing
                                        </Button>

                                        <ProgressBtn btnType="primary" progress={timer.remaining / timer.defaultTimeout} onClick={accept}>
                                            Grant Access
                                        </ProgressBtn>
                                    </div>
                                ),
                                [RESPONSE_ACCESS_STEP.SECOND]: () => (
                                    <div className="buttons">
                                        <Button btnType="danger" onClick={decline}>
                                            No, I’m still editing
                                        </Button>

                                        <ProgressBtn btnType="primary" progress={timer.remaining / timer.defaultTimeout} onClick={accept}>
                                            Grant Access
                                        </ProgressBtn>
                                    </div>
                                ),
                                [RESPONSE_ACCESS_STEP.INACTIVE_FORCE_TRANSFERRED]: () => (
                                    <div className="buttons">
                                        <Button disabled={disabled.value} onClick={closeAndBack}>
                                            Close
                                        </Button>
                                    </div>
                                ),
                                [RESPONSE_ACCESS_STEP.NO_RESPOND_FORCE_TRANSFERRED]: () => (
                                    <div className="buttons">
                                        <Button disabled={disabled.value} onClick={closeAndBack}>
                                            Close
                                        </Button>
                                    </div>
                                ),
                            };
                            return maps[step]?.() ?? null;
                        }),
                ],
                [
                    "renderBody",
                    ({}, next) =>
                        next(({requestUser}) => {
                            let {step} = state.value;
                            const requestUserName = requestUser?.name ?? "Another user";

                            const maps = {
                                [RESPONSE_ACCESS_STEP.FIRST]: () => (
                                    <div className="messages">
                                        <span className="material-icons warning">info</span>
                                        <div className="message-line">
                                            <b>{requestUserName}</b> is requesting access to this {item.type}
                                        </div>
                                        <div className="message-line small">
                                            To avoid conflicts in the configuration of a {item.type}, <b>only one user may edit the {item.type} at a time</b>.
                                        </div>
                                        <div className="message-line small">
                                            If you are finished with your edits you can grant access to <b>{requestUserName}</b> which will save your current version and return you
                                            to the {item.type}.
                                        </div>
                                        <div className="message-line small">If you are still making edits you can deny the request and we'll let them know.</div>
                                    </div>
                                ),
                                [RESPONSE_ACCESS_STEP.SECOND]: () => (
                                    <div className="messages">
                                        <span className="material-icons warning">timer</span>
                                        <div className="message-line">
                                            <b>Grant access</b>
                                        </div>
                                        <div className="message-line small">
                                            Verb will transfer edit rights to {requestUserName} if you don't respond to their request. Would you like to close your session and give
                                            access to them?
                                        </div>
                                        <ProgressBar progress={(timer.remaining / timer.defaultTimeout) * 100} />
                                        <div className="time-remaining-text">{Math.floor(timer.remaining / 1000)} seconds left</div>
                                    </div>
                                ),
                                [RESPONSE_ACCESS_STEP.NO_RESPOND_FORCE_TRANSFERRED]: () => (
                                    <div className="messages" style={{height: 136}}>
                                        <span className="material-icons success">done</span>
                                        <div className="message-line">
                                            <b>Edit access transferred</b>
                                        </div>
                                        <div className="message-line small">
                                            {requestUserName} requested permission to {item.name} but you did not respond within the given time. Your latest changes were saved
                                            before transferring access to them.
                                        </div>
                                    </div>
                                ),
                                [RESPONSE_ACCESS_STEP.INACTIVE_FORCE_TRANSFERRED]: () => (
                                    <div className="messages" style={{height: 136}}>
                                        <span className="material-icons success">done</span>
                                        <div className="message-line">
                                            <b>Edit access transferred</b>
                                        </div>
                                        <div className="message-line small">
                                            Your session was inactive and {requestUserName} requested access to edit {item.name}. Your latest changes were saved before transferring
                                            access to them.
                                        </div>
                                    </div>
                                ),
                            };

                            return maps[step]?.() ?? null;
                        }),
                ],
                ({controlButtons, renderBody}) => (
                    <div className="single-editor-dialog-vc0">
                        {OnUnmounted({
                            action: () => {
                                state.onChange(RESPONSE_ACCESS_STEP.SECOND);
                            },
                        })}
                        <VerbDialogBodyScrollbar>{renderBody(modal)}</VerbDialogBodyScrollbar>

                        {controlButtons(modal, disabled)}
                    </div>
                )
            )
    );

const ProgressBar = ({progress = 0, height = 12, bgColor = "#EFF3F6", trackBarColor = "#11A1FD", borderRadius = "24px"}) => (
    <div
        className="progress-bar has-track"
        style={{
            background: bgColor,
            borderRadius,
            height,
            marginTop: 8,
        }}
    >
        <div
            style={{
                background: trackBarColor,
                borderRadius,
                width: `${progress}%`,
                height,
            }}
        />
    </div>
);
