import "./model-tutorial.scss";

import React from "react";
import {cx} from "emotion";

import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {ModelTutorialStep1} from "./steps/model-tutorial-step-1";
import {ModelTutorialStep2} from "./steps/model-tutorial-step-2";
import {ModelTutorialStep3} from "./steps/model-tutorial-step-3";
import {Load2} from "@common/react/load2";
import {consumeContext, provideContext} from "@common/react/context";
import {DelayRender} from "@common/react/delay-render";
import {keyed} from "@common/react/keyed";
import {ModelSuggestionTutorialStep} from "./auto-suggest-steps/model-suggestion-tutorial-step";

export const ModelTutorial = ({model, lowPosition, showTutorial, dataSources}) =>
    cs(
        consumeContext("routing"),
        consumeContext("apis"),
        consumeContext("auth"),
        ({}, next) => provideContext("showTutorial", showTutorial, next),
        [
            "open",
            ({auth}, next) => {
                const initValue = showTutorial.value?.hasSuggestionStep ?? auth.user.preferences.modelGuidance == "ShowExpanded";
                return UseState({next, initValue});
            },
        ],
        ["step", (_, next) => UseState({next, initValue: 0})],
        [
            "folders",
            ({apis, routing}, next) =>
                Load2({
                    _key: routing.params?.envId,
                    fetch: () => apis.collection.getFolders(),
                    next,
                }),
        ],
        [
            "toggleModal",
            ({open, apis, auth}, next) =>
                next(() => {
                    if (!open.value) {
                        open.onChange(true);
                        apis.user.updateUserPreferences({
                            modelGuidance: "ShowExpanded",
                        });
                        auth.setAuth({
                            user: {
                                ...auth.user,
                                preferences: {
                                    ...auth.user.preferences,
                                    modelGuidance: "ShowExpanded",
                                },
                            },
                        });
                    } else {
                        open.onChange(false);
                        apis.user.updateUserPreferences({
                            modelGuidance: "ShowMinimized",
                        });
                        auth.setAuth({
                            user: {
                                ...auth.user,
                                preferences: {
                                    ...auth.user.preferences,
                                    modelGuidance: "ShowMinimized",
                                },
                            },
                        });
                    }
                }),
        ],
        ({open, step, folders, toggleModal}) => {
            const isAutoSuggestMode = showTutorial.value?.hasSuggestionStep;

            const steps = [
                {
                    title: "First, add data to the model",
                    component: <ModelTutorialStep1 />,
                    width: 376,
                    height: 345,
                },
                {
                    title: "Next, set up relationships between each of the tables on the canvas.",
                    component: <ModelTutorialStep2 />,
                    width: 396,
                    height: 456,
                },
                {
                    title: "And now… create a collection!",
                    component: <ModelTutorialStep3 />,
                    width: 376,
                    height: 243,
                },
            ];

            const selectedStep = steps[step.value];

            if (isAutoSuggestMode) {
                return ModelSuggestionTutorialStep({
                    model,
                    dataSources,
                    folders,
                    open,
                    toggleModal,
                });
            }

            return (
                <div className="model-tutorial-a23" style={{top: lowPosition ? 70 : 20}}>
                    <div
                        className={`tutorial-content ${open.value ? "expanded" : "minimize"}`}
                        style={{
                            width: open.value ? selectedStep.width : 60,
                            height: open.value ? selectedStep.height : 60,
                        }}
                    >
                        <div className={cx("expanded-title")}>{selectedStep.title}</div>

                        <div className="expanded-body">
                            {open.value &&
                                cs(
                                    keyed(step.value),
                                    ({}, next) => DelayRender({delay: 200, next}),
                                    () =>
                                        React.cloneElement(selectedStep.component, {
                                            onNext: () => step.onChange(step.value + 1),
                                            onBack: () => step.onChange(step.value - 1),
                                            onClose: () => open.onChange(false),
                                            folders,
                                            model,
                                            dataSources,
                                            showTutorial,
                                            onGoToStep: (stepIndex) => step.onChange(stepIndex),
                                        })
                                )}
                        </div>

                        <div className="close-btn" onClick={() => toggleModal()}>
                            <i className="fas fa-chevron-up" />
                        </div>
                    </div>
                </div>
            );
        }
    );
