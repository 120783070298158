export const DATA_BOOST_THRESHOLD = 2500;

// https://api.highcharts.com/highcharts/plotOptions.series.turboThreshold
// turbo mode: if data length is more than 1000 (default threshold),
// then turbo mode will kick in;
// and only render chart if data is only one dimensional arrays of numbers, or two dimensional arrays of numeric x and y values.
// default is 1000. set to 0 disable.
// disable to allow rendering chart with long data and data is not of the forms mentioned above.
export const DATA_TURBO_THRESHOLD = 0;

export const SERIES_BOOST_THRESHOLD = 1000;
