const {getSeriesBorderRadiusOptions} = require("./get-border-radius-options");
const {sort} = require("../../../utils/collections");
const {flatten1} = require("../../../utils/collections");
const {unique} = require("../../../utils/collections");
const {arrMapToO} = require("../../../utils/objects");
const {chain} = require("../../../utils/fs");
const {prepareColor} = require("../prepare-color");
const {generateSeriesId} = require("./generate-series-id");

const groupNonCompareBarSeries = ({series, type, tile, theme, getYAxis, tileFields, tooltipOptions, measurementFormatters, isBarDataColorAppliedByDimension, isDimensionDate}) => {
    const isStacked = ["Stacked", "HundredPercent"].includes(tile.style.displayType || tile.style.yAxisBarDisplayType);
    const stackKeys = unique(series.map((s) => s.stack));
    const stackKeyTitleMap = arrMapToO(stackKeys, (sk) => unique(series.filter((s) => s.stack === sk).map((s) => s.measureAxisTitle)));

    return arrMapToO(stackKeys, (sk) => ({
        label: stackKeyTitleMap[sk].join(" & "),
        series: chain(
            series.filter((s) => s.stack === sk),
            (_series) => {
                const getSeriesFn = isStacked ? getSeriesStacked : getSeriesNonStacked;
                return getSeriesFn({
                    series: _series,
                    getYAxis,
                    tileFields,
                    yAxis: stackKeys.findIndex((s) => s === sk),
                    tile,
                    type,
                    tooltipOptions,
                    measurementFormatters,
                    theme,
                    hasGroupField: tile.groupField != null,
                    isBarDataColorAppliedByDimension,
                    isDimensionDate,
                });
            },
            // highcharts set reversedStacks on measurement axis to true by default,
            // so have to sort series by legendIndex in reverse order if stacked
            (_series) => sort(_series, (s) => s.legendIndex * (isStacked ? -1 : 1))
        ),
    }));
};
exports.groupNonCompareBarSeries = groupNonCompareBarSeries;

const getSeriesStacked = ({series, ...props}) => {
    const {tile, type, yAxis, getYAxis, tileFields, hasGroupField, measurementFormatters, theme, isBarDataColorAppliedByDimension, isDimensionDate} = props;
    const {dataColorPalettes} = theme.dataVisualization;
    const dataLabels = tile.style.dataLabels || tile.style.yAxisBarDataLabels;
    const {colorScale, getDisplayColor} = prepareColor({
        tile,
        tileFields,
        dataColorPalettes,
        isBarDataColorAppliedByDimension,
    });

    colorScale?.setRange(0, series.length - 1);

    const isStackedColumn = ["Stacked", "HundredPercent"].includes(tile.style.displayType || tile.style.yAxisBarDisplayType);

    return series.map((s, i) => {
        if (isBarDataColorAppliedByDimension) {
            colorScale?.setRange(0, s.data.length - 1);
        }
        const colorOptions = getDisplayColor({series: s, index: i});

        const indexInCustomStack = hasGroupField ? 0 : i;

        return {
            id: generateSeriesId(s),
            ...s,
            ...(tile.$type === "ComboChartTile"
                ? {
                      yAxis: getYAxis(s),
                  }
                : tile.style.multipleAxisOption === "IndependentAxes" && {
                      yAxis,
                  }),
            ...(type && {type}),
            customStack: s.stack,
            indexInCustomStack,
            ...(isStackedColumn && getSeriesBorderRadiusOptions(i, series.length, theme)),
            // ...tooltipOptions[s.stack](indexInCustomStack),
            ...colorOptions,
            pointPadding: 0.15,
            // data labels formatter in series level does not work anymore, since HC v.10.2.0
            // ...(dataLabels?.show && {
            //     dataLabels: {
            //         formatter: function () {
            //             if (this.y === 0) {
            //                 return;
            //             }
            //             return measurementFormatters[s.stack][indexInCustomStack](this.y);
            //         }
            //     },
            // }),
            states: {
                inactive: {
                    opacity: 1,
                },
            },
            maxPointWidth: 70,
        };
    });
};

const getSeriesNonStacked = ({series, ...props}) => {
    const {tile, theme, type, yAxis, getYAxis, tileFields, hasGroupField, measurementFormatters, isBarDataColorAppliedByDimension} = props;
    const {dataColorPalettes} = theme.dataVisualization;
    const dataLabels = tile.style.dataLabels || tile.style.yAxisBarDataLabels;
    const groupNames = unique(series.map((s) => s.name));
    const {colorScale, getDisplayColor} = prepareColor({
        tile,
        tileFields,
        dataColorPalettes,
        isBarDataColorAppliedByDimension,
    });

    colorScale?.setRange(0, groupNames.length - 1);

    const getSeries = (gn, i) =>
        series
            .filter((s) => s.name === gn)
            .map((s, j) => {
                if (isBarDataColorAppliedByDimension) {
                    colorScale?.setRange(0, s.data.length - 1);
                }
                const colorOptions = getDisplayColor({series: s, index: i});

                const indexInCustomStack = hasGroupField ? 0 : j;

                return {
                    id: generateSeriesId(s),
                    ...s,
                    ...(tile.$type === "ComboChartTile"
                        ? {
                              yAxis: getYAxis(s),
                          }
                        : tile.style.multipleAxisOption === "IndependentAxes" && {yAxis}),
                    ...(type && {type}),
                    customStack: s.stack,
                    indexInCustomStack,
                    xAxis: !s.isCompare ? 0 : 1,
                    // ...tooltipOptions[s.stack](indexInCustomStack),
                    ...colorOptions,
                    // pointPadding: 0.2,
                    // ...(dataLabels?.show && {
                    //     dataLabels: {
                    //         formatter: function () {
                    //             if (this.y === 0) {
                    //                 return;
                    //             }
                    //             return measurementFormatters[s.stack][indexInCustomStack](this.y);
                    //         }
                    //     },
                    // }),
                    states: {
                        inactive: {
                            opacity: 1,
                        },
                    },
                    maxPointWidth: 70,
                };
            });

    return flatten1(groupNames.map(getSeries));
};
