import {cs} from "@common/react/chain-services";
import {Dropdown} from "@common/ui-components/dropdown/dropdown";
import {isNotBlank} from "@common/utils/strings";
import {SearchInputBar} from "@common/ui-components/search-input-bar/search-input-bar";
import {css, cx} from "emotion";
import {UseState} from "@common/react/use-state";
import {HeaderFilterByText} from "@common/ui-components/charts/table/table/header/filter-types/header-filter-by-text";
import {HeaderFilterByNumber} from "@common/ui-components/charts/table/table/header/filter-types/header-filter-by-number";
import {HeaderFilterByDate} from "@common/ui-components/charts/table/table/header/filter-types/header-filter-by-date";
import {HeaderFilterByBoolean} from "@common/ui-components/charts/table/table/header/filter-types/header-filter-by-boolean";

export const FilterHeader = ({theme, search, columnIndex, tileColumn, columnType}) =>
    cs(
        ["hovering", (_, next) => UseState({next})],
        ["dateFilterSelectedDateType", (_, next) => UseState({next, initValue: null})],
        ({hovering, dateFilterSelectedDateType}) => {
            const buttonsStyle = theme.general.buttons;
            const tablesStyle = theme.dataVisualization.tables;
            const componentTheme = theme.general.components;

            return Dropdown({
                registryRender: true,
                detectOnWheelEvent: true,
                useTogglePosition: true,
                forcedExpandLeft: true,
                expandDistance: -15,
                className: "search-header",
                renderToggle: ({showExpand, showingExpand}) => {
                    return (
                        <div
                            className="search-header"
                            onClick={() => {
                                showExpand?.();
                            }}
                            onMouseEnter={() => hovering.onChange(true)}
                            onMouseLeave={() => hovering.onChange(false)}
                        >
                            {FilterIcon({
                                fill:
                                    showingExpand ||
                                    hovering.value ||
                                    search.tableSearches.find((c) => c.columnIndex == columnIndex)?.search
                                        ? buttonsStyle.primaryButton.backgroundColorRGB
                                        : tablesStyle?.headerFontColorRGB || "#bcbcbc",
                            })}
                        </div>
                    );
                },
                renderExpand: ({close}) => {
                    const headerFilters = {
                        text: HeaderFilterByText,
                        date: HeaderFilterByDate,
                        number: HeaderFilterByNumber,
                        bool: HeaderFilterByBoolean,
                    };

                    const View = headerFilters[columnType];

                    return (
                        <div
                            className="search-input-container-ff5"
                            style={{
                                borderRadius: theme.general.components.menuCornerRadius,
                                background: theme.general.components.menuBackgroundColorRGB,
                                zIndex: 10,
                                width: 280,
                            }}
                        >
                            {View({
                                tileColumn,
                                search,
                                componentTheme,
                                defaultSelected: (search.tableSearches || []).find((c) => c.columnIndex == columnIndex),
                                theme,
                                close,
                                columnIndex,
                                dateFilterSelectedDateType,
                            })}
                        </div>
                    );
                },
            });
        }
    );

const FilterIcon = ({fill}) => (
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.33329 9.33333V13.3333L6.66663 14.6667V9.33333L2.66663 3.33333V2H13.3333V3.33333L9.33329 9.33333ZM4.26929 3.33333L7.99996 8.92933L11.7306 3.33333H4.26929Z"
            fill={fill}
        />
    </svg>
);
