
import React from "react";

const FilterMobileIcon = ({className, fill = "#294661", size = {width: 20, height: 16}}) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width={size.width} height={size.height} viewBox="0 0 20 16">
        <g id="Filter_Icon" data-name="Filter Icon" transform="translate(-312 -22.999)">
            <path fill={fill} id="Union_4" data-name="Union 4" d="M36.645-829.5H28a1,1,0,0,1-1-1,1,1,0,0,1,1-1h8.645A3.5,3.5,0,0,1,40-834a3.5,3.5,0,0,1,3.355,2.5H46a1,1,0,0,1,1,1,1,1,0,0,1-1,1H43.355A3.5,3.5,0,0,1,40-827,3.5,3.5,0,0,1,36.645-829.5Zm1.855-1A1.5,1.5,0,0,0,40-829a1.5,1.5,0,0,0,1.5-1.5A1.5,1.5,0,0,0,40-832,1.5,1.5,0,0,0,38.5-830.5Zm-7.855-8H28a1,1,0,0,1-1-1,1,1,0,0,1,1-1h2.645A3.5,3.5,0,0,1,34-843a3.5,3.5,0,0,1,3.355,2.5H46a1,1,0,0,1,1,1,1,1,0,0,1-1,1H37.355A3.5,3.5,0,0,1,34-836,3.5,3.5,0,0,1,30.645-838.5Zm1.855-1A1.5,1.5,0,0,0,34-838a1.5,1.5,0,0,0,1.5-1.5A1.5,1.5,0,0,0,34-841,1.5,1.5,0,0,0,32.5-839.5Z" transform="translate(285 866)" />
        </g>
    </svg>
);

export default FilterMobileIcon;