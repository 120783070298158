import React from "react";
import {DialogService} from "../../../../../../common/dialog/dialog-service";
import {cs} from "@common/react/chain-services";
import {Button} from "../../../../../../../../../common/form/buttons/button/button";
import {VerbScrollbar} from "@common/ui-components/verb-scrollbar/verb-scrollbar";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";

export const ConfirmChangeFilterDisplayDialog = ({next: rootNext, onMove, onDelete}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    render: ({resolve}) => ({
                        title: "Move Download Reports from Filter Area",
                        width: 480,
                        content: next({
                            resolve,
                        }),
                        initShow: true,
                        className: "confirm-dialog-wrapper-z33",
                    }),
                    next: rootNext,
                }),
        ],
        ({modal}) =>
            cs(() => (
                <div className="confirm-dialog-z33">
                    <VerbDialogBodyScrollbar>
                        <div className="message">
                            Changing the Collection Filter Display to Control via SDK requires you to move the downloadable reports. Would you like to move these reports to the
                            dashboard canvas or delete the tiles?
                        </div>
                    </VerbDialogBodyScrollbar>

                    <div className="buttons">
                        <Button
                            btnType="secondary"
                            onClick={() => {
                                modal.resolve();
                                onDelete();
                            }}
                        >
                            Delete Reports
                        </Button>
                        <Button
                            onClick={async () => {
                                modal.resolve();
                                onMove();
                            }}
                        >
                            Move to Dashboard
                        </Button>
                    </div>
                </div>
            ))
    );
