exports.dateFormats = [
    {
        label: "Sep 30 (No Year)",
        format: "MMM d",
        inConfig: true,
    },
    {
        label: "30 Sep (No Year)",
        format: "d MMM",
        inConfig: true,
    },
    {
        label: "9/30 (No Year)",
        format: "M/d",
        inConfig: true,
    },
    {
        label: "30/9 (No Year)",
        format: "d/M",
        inConfig: true,
    },
    {
        label: "Sep 30, 2025",
        format: "MMM d, yyyy",
        inConfig: true,
    },
    {
        label: "September 30, 2025",
        format: "MMMM d, yyyy",
        inConfig: true,
    },
    {
        label: "9/30/25",
        format: "M/d/yy",
        inConfig: true,
    },
    {
        label: "9.30.25",
        format: "M.d.yy",
    },
    {
        label: "09/30/2025",
        format: "MM/dd/yyyy",
    },
    {
        label: "09.30.2025",
        format: "MM.dd.yyyy",
    },
    {
        label: "30 Sep, 2025",
        format: "d MMM, yyyy",
    },
    {
        label: "30/9/25",
        format: "d/M/yy",
    },
    {
        label: "30.9.25",
        format: "d.M.yy",
    },
    {
        label: "30/09/2025",
        format: "dd/MM/yyyy",
    },
    {
        label: "30.09.2025",
        format: "dd.MM.yyyy",
    },
    {
        label: "Q3 2025",
        format: "Q yyyy",
        inConfig: true,
    },
    {
        label: "Q3 25",
        format: "Q yy",
    },
    {
        label: "Saturday",
        format: "dddd",
        inConfig: true,
    },
    {
        label: "Week 40",
        format: "Week W",
    },
    {
        label: "September 2025",
        format: "MMM yyyy",
        inConfig: true,
    },
    {
        label: "Sep 25 (No Date)",
        format: "MMM yy",
    },
    {
        label: "2025 (Year)",
        format: "yyyy",
    },
    {
        label: "5:45 pm",
        format: "h:mm tt",
    },
    {
        label: "5 pm",
        format: "h tt",
    },
];
