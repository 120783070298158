import React from "react";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {Load2} from "@common/react/load2";
import {getRandomInt} from "@common/utils/random";
import {EditTransformation} from "./edit-transformation";
import {keyed1} from "@common/react/keyed";

export const EditTransformedColumn = ({environment}) =>
    cs(
        consumeContext("routing"),
        consumeContext("apis"),
        consumeContext("model"),
        ({routing}, next) =>
            keyed1({
                key: routing.params.transformationId,
                next,
            }),
        [
            "transformation",
            ({apis, routing, model}, next) => {
                if (!model.value) {
                    return next();
                }

                if (routing.params.transformationId === "undefined" && routing.params.isNew) {
                    const modelTableId = routing.params.modelTableId;
                    const newName = routing.params.type === "calculated-column" ? `New Column ${getRandomInt(1000, 9999)}` : `New Measure ${getRandomInt(1000, 9999)}`;
                    const initTransformation = {
                        $type: "ColumnTransformation",
                        modelID: model.value.id,
                        modelTableID: modelTableId,
                        name: newName,
                        steps: [
                            {
                                $type: routing.params.type === "calculated-column" ? "ModelTableDatasetStep" : "AggregatedModelTableDatasetStep",
                                position: {x: 400, y: 100},
                                name: "Table",
                                modelID: model.value.id,
                                modelTableID: modelTableId,
                            },
                            {
                                $type: "ColumnOutputStep",
                                name: newName,
                                position: {x: 400, y: 580},
                            },
                        ],
                    };
                    (async () => {
                        const newTransformation = await apis.transformation.upsertTransformation(initTransformation);
                        if (newTransformation.id) {
                            model.reload();
                            routing.goto("edit-transformed-column", {
                                tab: "build",
                                modelTableId,
                                transformationId: newTransformation.id,
                                isNew: true,
                            });
                        }
                    })();
                    return next();
                }

                return Load2({
                    fetch: () => apis.transformation.getTransformation(routing.params.transformationId),
                    next,
                });
            },
        ],
        ({transformation}) => EditTransformation({environment, transformation})
    );
