import {CircleRadius, headerHeight} from "../edit/main/model-panel";
import {minBy, quadraticEquations, randomColor} from "@common/utils/math-util";
import {sort, unique} from "@common/utils/collections";
import {dataViewColor, getDSColorBasedOnIndex} from "../edit/tabs/common/data-source-colors";
import {cyrb53, replaceCharacters} from "@common/utils/strings";
import {cache2} from "@common/utils/cache2";
import {RELATIONSHIP_INNER_JOIN} from "../edit/main/model-constrants";

export const isCircleIntersecting = (pos1, pos2) => {
    const distance = Math.sqrt(Math.pow(pos1.x - pos2.x, 2) + Math.pow(pos1.y - pos2.y, 2));
    return CircleRadius * 3 - distance >= 0;
};

export const getNextPos = (pos1, R, circleX, circleY, targetPosName) => {
    const a = 1;
    const b = -2 * (targetPosName == "x" ? circleX : circleY);
    const c =
        Math.pow(pos1, 2) -
        2 * (targetPosName == "x" ? circleY : circleX) * pos1 +
        (Math.pow(circleX, 2) + Math.pow(circleY, 2) - Math.pow(R, 2));
    return quadraticEquations(a, b, c);
};

export const generateAnchorPosition = (index, circlePos, side, circleRadius = CircleRadius) => {
    if (side == "right") {
        let y = circlePos.y + index;
        const [x1, x2] = getNextPos(y, circleRadius, circlePos.x, circlePos.y, "x");
        let x = Math.max(x1, x2) + 3;
        return {x, y};
    }

    if (side == "left") {
        let y = circlePos.y + index;
        const [x1, x2] = getNextPos(y, circleRadius, circlePos.x, circlePos.y, "x");
        let x = Math.min(x1, x2) - 3;
        return {x, y};
    }

    if (side == "top") {
        let x = circlePos.x + index;
        const [x1, x2] = getNextPos(x, circleRadius, circlePos.x, circlePos.y, "y");
        let y = Math.min(x1, x2) - 3;
        return {x, y};
    }

    if (side == "bottom") {
        let x = circlePos.x + index;
        const [x1, x2] = getNextPos(x, circleRadius, circlePos.x, circlePos.y, "y");
        let y = Math.max(x1, x2) + 3;
        return {x, y};
    }
};

const sortAnchorFunc = (anchor, side) => {
    if (side == "top" || side == "bottom") return anchor.targetTable.position.x;
    return anchor.targetTable.position.y;
};

const getAnchorPositions = ({anchors, side, table}) => {
    const circlePos = table.position;
    let ret = [];
    let startPosIndex = 0 - Math.floor(anchors.length / 2);

    for (let anchor of sort(anchors, (anchor) => sortAnchorFunc(anchor, side))) {
        if (anchor.targetTable) {
            ret.push({
                id: anchor.anchorID,
                position: generateAnchorPosition(startPosIndex * 12, circlePos, side),
                circlePosition: circlePos,
                side,
                anchorIndex: startPosIndex + Math.floor(anchors.length / 2),
                targetPosition: anchor.targetTable.position,
                relationships: anchor.relationships,
                targetTable: anchor.targetTable,
                table,
                relatedTables: anchor.relatedTables,
            });
        }

        startPosIndex++;
    }
    return ret;
};

export const getRandomPosition = ({height, scale, transform, currentPositions, iteration}) => {
    if (!iteration) iteration = 1;

    const randomPosMouseX = Math.floor(Math.random() * 350) + 240;
    const randomPosMouseY = Math.floor(Math.random() * (height + 60)) + 60;

    const posX = (randomPosMouseX - 240 - transform.x) / scale;
    const posY = (randomPosMouseY - headerHeight - transform.y) / scale;

    for (let pos of currentPositions) {
        if (iteration < 30 && isCircleIntersecting({x: posX, y: posY}, pos)) {
            return getRandomPosition({height, scale, transform, currentPositions, iteration: iteration + 1});
        }
    }
    return {
        x: posX,
        y: posY,
    };
};

const isEqualsRelationship = (r1, r2) =>
    (r1.leftColumnID == r2.leftColumnID || r1.leftColumnID == r2.rightColumnID) &&
    (r1.rightColumnID == r2.rightColumnID || r1.rightColumnID == r2.leftColumnID);

const cleanDataName = (name) => {
    if (!name || name.length == 0) {
        return "";
    }

    const charObj = {
        "/": "",
        "[": "",
        "]": "",
        '"': "",
        "'": "",
        $: "",
        ".": "",
        "-": "",
        " ": "",
        "`": "",
        "%": "",
        "*": "",
        _: "",
    };

    let newName = replaceCharacters(name, charObj).toLowerCase();
    if (!newName) return name;
    if (!/[a-zA-Z]/.test(newName[0])) newName = "c_" + newName;
    return newName;
};

export const ModelPanelHelper = {
    generatePosition: ({width, height}, tables, {transform, scale}) => {
        let ret = [...tables];
        let needUpsert = false;
        let currentPositions = tables.filter((t) => t.position).map((t) => t.position);

        for (let table of tables) {
            if (!table.position) {
                const pos = getRandomPosition({currentPositions, height, scale, transform});
                table.position = pos;
                currentPositions.push(pos);
                needUpsert = true;
            }
        }

        return {
            updatedTables: ret,
            needUpsert,
        };
    },
    isCircleIntersecting,
    getSide: (from, target) =>
        Math.abs(target.x - from.x) > Math.abs(target.y - from.y)
            ? target.x > from.x
                ? "right"
                : "left"
            : target.y > from.y
            ? "bottom"
            : "top",
    buildAnchorPosition: (table) => {
        let ret = [];

        for (let side of ["top", "left", "right", "bottom"]) {
            let anchors = table.anchors.filter((a) => a.side == side);
            if (anchors.length > 0) {
                ret = ret.concat(getAnchorPositions({anchors, side, table: table.tableInfo}));
            }
        }

        return ret;
    },
    isSelectedRelationship: ({table, interactions, relationships, hoverInteractions}) => {
        const isSelected = (relationshipId) => {
            if (relationshipId) {
                let relationship = relationships.find((r) => r.id == relationshipId);
                return table.columns.find((c) => c.id == relationship?.leftColumnID || c.id == relationship?.rightColumnID);
            }
            return false;
        };

        return isSelected(interactions?.setting?.data?.relationshipId) || isSelected(hoverInteractions.value?.relationshipId);
    },
    isTableSelected: ({table, interactions}) => {
        const isSelected = (tableId) => tableId == table.id;
        return isSelected(interactions?.setting?.data?.tableId);
    },
    isTableHovering: ({table, hoverInteractions}) => {
        const isSelected = (tableId) => tableId == table.id;
        return isSelected(hoverInteractions.value?.tableId);
    },
    isHighLightTable: ({table, interactions, hoverInteractions, relationships, autoSuggest}) => {
        if (autoSuggest.value) {
            return table.columns.find((c) =>
                relationships.find(
                    (relationship) =>
                        (relationship.leftColumnID == c.id || relationship.rightColumnID == c.id) && relationship.displayColumn
                )
            );
        }

        const isSelected = ({tableId, dataSourceTableID, dataSourceID}) => {
            return tableId == table.id && table.dataSourceTableID == dataSourceTableID && table.dataSourceID == dataSourceID;
        };

        if (
            interactions?.setting?.name == "create-new-relationship" &&
            (interactions?.setting?.data?.table.id == table.id || interactions?.setting?.data?.target?.id == table.id)
        ) {
            return true;
        }

        const suggestRelationships =
            interactions?.setting?.name == "suggest-relationship" || interactions?.setting?.name == "all-suggested-relationships"
                ? interactions?.setting?.data?.suggestedRelationships
                : null;
        if (
            suggestRelationships &&
            table.columns.find((c) => suggestRelationships.find((r) => r.leftColumnID == c.id || r.rightColumnID == c.id))
        ) {
            return true;
        }

        return isSelected(hoverInteractions.value || {}) && !isSelected(interactions?.setting?.data || {});
    },
    isHighlightRelationship: ({tables, relationship, interactions, hoverInteractions, shortestPath}) => {
        if (shortestPath.length > 0) {
            let isHighlight = shortestPath.find((c) => c.relationships.findIndex((r) => r.id == relationship.id) > -1);
            if (isHighlight) {
                return {color: isHighlight.color};
            }
        }

        const isSelected = ({relationshipId, tableId, columnId}) => {
            if (relationship.id && relationshipId == relationship.id) return true;

            if (columnId) {
                return columnId == relationship.leftColumnID || columnId == relationship.rightColumnID;
            }

            let table = tables.find((t) => t.id == tableId);
            if (table) {
                if (columnId) {
                    return columnId == relationship.leftColumnID || interactions.setting.data.columnId == relationship.rightColumnID;
                }

                return table.columns.find((c) => c.id == relationship.leftColumnID || c.id == relationship.rightColumnID);
            }

            return false;
        };

        return isSelected(interactions?.setting?.data || {}) || isSelected(hoverInteractions.value || {});
    },
    isHighlightAnchor: ({anchor, relationships, tables, interactions, hoverInteractions}) => {
        const isSelected = ({relationshipId, columnId, tableId}) => {
            if (relationshipId) {
                let relationshipFound = relationships.find((r) => r.id && r.id === relationshipId);

                if (relationshipFound) {
                    return anchor.id == relationshipFound.rightColumnID || anchor.id == relationshipFound.leftColumnID;
                }
            }

            if (columnId) {
                if (anchor.id == columnId) return true;
                else {
                    let relationshipsFiltered = relationships.filter((r) => r.leftColumnID == columnId || r.rightColumnID == columnId);
                    return relationshipsFiltered.find((r) => r.leftColumnID == anchor.id || r.rightColumnID == anchor.id);
                }
            }

            if (tableId) {
                let table = tables.find((t) => t.id == tableId);
                if (!table) return false;

                let found = table.columns.find((c) => c.id == anchor.id);
                if (found) return true;

                let targetColumnIds = relationships
                    .filter((r) => r.rightColumnID == anchor.id || r.leftColumnID == anchor.id)
                    .map((r) => (r.leftColumnID == anchor.id ? r.rightColumnID : r.leftColumnID));

                return table.columns.find((c) => targetColumnIds.indexOf(c.id) > -1);
            }
        };

        return isSelected(interactions?.setting?.data || {}) || isSelected(hoverInteractions.value || {});
    },
    isDisplayColumnName: ({relationship, interactions, anchorIDs = []}) => {
        const isSelected = ({relationshipId, columnId}) => {
            if (relationshipId) {
                return relationship.id == relationshipId;
            }

            if (columnId) {
                return anchorIDs.indexOf(columnId) > -1;
            }
        };

        return isSelected(interactions?.setting?.data || {});
    },
    generateShortestPath: ({relationshipsTable, tableID, targetTableID}) => {
        const visitedTableIds = [tableID];

        const getTablesRelatedTo = (tableID) => {
            let targetTables = [];

            for (let relationship of relationshipsTable) {
                const currentTableIndex = relationship.tables.findIndex((t) => t.id == tableID);
                if (currentTableIndex > -1) {
                    const targetTable = relationship.tables[1 - currentTableIndex];
                    if (!visitedTableIds.find((id) => id == targetTable.id)) {
                        targetTables.push({
                            table: targetTable,
                            relationship,
                        });

                        if (targetTable.id != targetTableID) {
                            visitedTableIds.push(targetTable.id);
                        }
                    }
                }
            }

            let ret = [];

            let foundTargetedTables = targetTables.filter((t) => t.table.id == targetTableID);
            if (foundTargetedTables.length > 0) {
                return foundTargetedTables;
            } else {
                for (let item of targetTables) {
                    ret.push({
                        table: item.table,
                        relationship: item.relationship,
                        childs: getTablesRelatedTo(item.table.id),
                    });
                }
            }

            return ret;
        };

        const colors = ["#FF0000", "#20BB00", "#00CCFF", "#DDFF00", "#FF7700", "#A200E7"];

        const tree = getTablesRelatedTo(tableID);

        let ret = [];

        for (let node of tree) {
            const seekRelationShip = (node, cb) => {
                if (node.childs?.length > 0) {
                    for (let child of node.childs) {
                        seekRelationShip(child, (item) => cb([node.relationship].concat(item)));
                    }
                } else {
                    if (node.table.id == targetTableID) {
                        cb([node.relationship]);
                    }
                }
            };

            seekRelationShip(node, (items) => {
                ret.push(items);
            });
        }

        const minPathLength = minBy(ret, (r) => r.length);
        let highlightRelationships = [];

        for (let i = 0; i < ret.filter((r) => r.length == minPathLength).length; i++) {
            const color = colors[i] || randomColor(colors);
            highlightRelationships.push({
                color,
                relationships: ret[i],
            });
        }

        return highlightRelationships;
    },
    getTableColor: ({table, dataSources = []}) => {
        if (table.$type === "ViewModelTable") {
            return dataViewColor;
        }
        if (table.dataSourceID) {
            const dsIndex = dataSources.findIndex((d) => d.id === table.dataSourceID);
            return dataSources[dsIndex]?.colorRGB || getDSColorBasedOnIndex(dsIndex);
        }
        return "#d4dadf";
    },
    isDraggingInvalid: ({grabPos, tables, grabID}) => {
        let ret = [];
        for (let table of tables) {
            if (table.id != grabID) {
                if (isCircleIntersecting(table.position, grabPos)) {
                    ret.push(table.id);
                }
            }
        }

        return ret;
    },
    getSuggestColumns: ({table, suggestedRelationships, relationships}) => {
        if (!table) return [];

        let ret = [];
        for (let relationship of suggestedRelationships) {
            let column = table.columns.find((c) => c.id == relationship.leftColumnID || c.id == relationship.rightColumnID);
            let existedRelationship = relationships.find(
                (r) =>
                    [relationship.leftColumnID, relationship.rightColumnID].includes(r.leftColumnID) &&
                    [relationship.leftColumnID, relationship.rightColumnID].includes(r.rightColumnID)
            );

            if (column && !existedRelationship) {
                ret.push({...relationship, suggestion: true});
            }
        }

        return ret;
    },
    getCenterPositionBetweenTwoAnchors: (pos1, pos2) => {
        let avg = {x: Math.abs(pos1.x - pos2.x) / 2, y: Math.abs(pos1.y - pos2.y) / 2};
        return {
            x: Math.min(pos1.x, pos2.x) + avg.x,
            y: Math.min(pos1.y, pos2.y) + avg.y,
        };
    },
    recommendRelationships: cache2(
        ({tables: _tables, relationships}) => {
            const tables = _tables.filter((t) => !t.excludeFromModel);

            const primaryKeyVariations = ({tableName, columns}) => {
                try {
                    const possiblePKs = unique(["id", tableName + "id", tableName.replace(/s\s*$/, "")], (t) => t);
                    const pk = columns.filter((c) => possiblePKs.includes(cleanDataName(c.name)))[0];
                    if (!pk) return null;
                    return [pk.id, ...possiblePKs.filter((i) => i != "id")];
                } catch (e) {
                    throw e;
                }
            };

            const tablesAndCols = tables.reduce((_tables, table) => {
                if (table.id) {
                    let tName = cleanDataName(table.name);
                    let pks = primaryKeyVariations({tableName: tName, columns: table.columns});

                    _tables = _tables.concat({
                        tName,
                        pks,
                        tableID: table.id,
                        fks: table.columns.map((c) => ({
                            id: c.id,
                            name: cleanDataName(c.name),
                        })),
                    });
                }

                return _tables;
            }, []);

            let ret = [];

            for (let t1 of tablesAndCols) {
                for (let t2 of tablesAndCols) {
                    if (t1.tableID != t2.tableID && t1.pks) {
                        for (let fk of t2.fks) {
                            if (t1.pks.indexOf(fk.name) > -1) {
                                let relationship = {
                                    leftColumnID: t1.pks[0],
                                    rightColumnID: fk.id,
                                    id: new Date().getTime() + Math.random(),
                                    suggestion: true,
                                    joinType: RELATIONSHIP_INNER_JOIN,
                                };

                                let found = relationships.find((r) => isEqualsRelationship(r, relationship));
                                if (!found) ret.push(relationship);
                            }
                        }
                    }
                }
            }

            return ret;
        },
        (...args) => JSON.stringify(args)
    ),
    isEqualsRelationship,
    isHasRelationship: ({relationship, relationships}) =>
        !relationship?.leftColumnID || !relationship?.rightColumnID
            ? false
            : relationships.find((r) => isEqualsRelationship(r, relationship)),
    getRelationshipsBetweenTables: ({table, target, relationships}) => {
        let ret = [];
        for (let relationship of relationships) {
            const foundLeft = table.columns.find((c) => c.id == relationship.leftColumnID);
            if (foundLeft) {
                if (target.columns.find((c) => c.id == relationship.rightColumnID)) {
                    ret.push(relationship);
                    continue;
                }
            }

            const foundRight = table.columns.find((c) => c.id == relationship.rightColumnID);
            if (foundRight) {
                if (target.columns.find((c) => c.id == relationship.leftColumnID)) {
                    ret.push(relationship);
                }
            }
        }

        return ret;
    },
};
