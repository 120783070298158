import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {dataSourceTypes} from "../../../common/data-source-type";
import {dataSourceFormConfig} from "../../../common/data-source-form-config";
import {OutboundAPIForm} from "../../../common/outbound-api/outbound-api-form";
import {SelectDataSource} from "../../select-data-source/select-data-source";
import {AmazonDSPConnectionForm} from "../../wizard/steps/connection-configuration/form/amazonDSP-connection-form";
import {BigQueryConnectionForm} from "../../wizard/steps/connection-configuration/form/bigQuery-connection-form";
import {DatabaseConnectionForm} from "../../wizard/steps/connection-configuration/form/database-connection-form";
import {DynamoDBConnectionForm} from "../../wizard/steps/connection-configuration/form/dynamoDB-connection-form";
import {ElasticsearchConnectionForm} from "../../wizard/steps/connection-configuration/form/elasticsearch-connection-form";
import {FirestoreConnectionForm} from "../../wizard/steps/connection-configuration/form/firestore-connection-form";
import {UploadFlatFile} from "./flat-file-step/upload-flat-file";

export const DsAddingConnectionSettings = ({next: rootNext, dataSource, dsApi, connectionStatus}) =>
    cs(
        consumeContext("apis"),
        [
            "addingSettings",
            (_, next) => {
                const $type = dataSource.value?.type;

                return next(
                    $type && ![dataSourceTypes.GOOGLE_SHEETS].includes($type)
                        ? {
                              [dataSourceTypes.FILE]: UploadFlatFile,
                              [dataSourceTypes.DYNAMODB]: DynamoDBConnectionForm,
                              [dataSourceTypes.ELASTICSEARCH]: ElasticsearchConnectionForm,
                              [dataSourceTypes.OUTBOUNDAPI]: OutboundAPIForm,
                              [dataSourceTypes.AMAZONDSP]: AmazonDSPConnectionForm,
                              [dataSourceTypes.BIGQUERY]: BigQueryConnectionForm,
                              [dataSourceTypes.FIRESTORE]: FirestoreConnectionForm,
                          }[$type] || DatabaseConnectionForm
                        : SelectDataSource
                );
            },
        ],
        ({addingSettings, apis}) =>
            addingSettings({
                data: dataSource,
                formConfig: dataSourceFormConfig({apis, dataSource}),
                next: rootNext,
                adding: true,
                dsApi,
                connectionStatus,
            })
    );
