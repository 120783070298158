import React from "react";
import {cs} from "@common/react/chain-services";
import {keyed} from "@common/react/keyed";
import {CaseConditionConfig} from "./case-condition-config";
import {spc} from "@common/react/state-path-change";
import {removeIndex} from "@common/utils/collections";
import "./case-when-configuration.scss";
import {Button} from "@common/form/buttons/button/button";
import {DropdownSelect2} from "@common/ui-components/dropdown-select/dropdown-select2";
import {ffToDropdown} from "@common/form/ff-to-dropdown";
import {DebounceTextInput} from "@common/form/debounce-input/debounce-text-input";
import {ffToTextInput} from "@common/form/ff-to-text-input";
import {keepOnly, omit} from "@common/utils/objects";
import {rColumnSelectFromStepOutput} from "../../../../common/column-select-from-step-output/column-select-from-step-output";
import {rNewColumnNameInput} from "../../common/new-column-name-input";
export const CaseWhenConfiguration = ({transformation, step, form, model}) =>
    cs((_, next) => {
        const inputStep = transformation.value.steps.find((s) => s.id === step.value.inputStepID);
        const columnSelect = rColumnSelectFromStepOutput({
            label: "Column",
            columns: inputStep?.outputColumns,
        });

        return (
            <div className="case-when-configuration-88u">
                {(step.value.caseConditions?.length > 0 ? step.value.caseConditions : [{}]).map((cc, index) =>
                    cs(keyed(index), () => (
                        <>
                            <div className="header-text">WHEN</div>
                            <div className="inner-block">
                                {CaseConditionConfig({
                                    step,
                                    form,
                                    path: ["caseConditions!arr", index],
                                    onRemove: () => spc(step, ["caseConditions"], (caseConditions) => removeIndex(index, caseConditions)),
                                    columns: inputStep?.outputColumns,
                                    model,
                                })}
                            </div>
                        </>
                    ))
                )}

                {step.value.caseConditions?.length > 0 && (
                    <Button
                        size="tiny"
                        btnType="border"
                        onClick={() =>
                            spc(step, ["caseConditions"], (caseConditions) => [
                                ...(caseConditions || []),
                                {$type: "CaseColumnFilterConfig", comparisons: [{}]},
                            ])
                        }
                    >
                        ANOTHER WHEN
                    </Button>
                )}

                <div className="config-group dual">
                    <div className="label">Value not found action (ELSE)</div>
                    <div className="dual-line">
                        {DropdownSelect2({
                            label: "Action",
                            list: [
                                {
                                    label: "Write NULL",
                                    value: "WriteNull",
                                },
                                {
                                    label: "Write Default",
                                    value: "WriteDefault",
                                },
                                {
                                    label: "Write Custom Value",
                                    value: "WriteCustomValue",
                                },
                                {
                                    label: "Other Column Value",
                                    value: "OtherColumnValue",
                                },
                            ],
                            valueToLabel: (v) => v.label,
                            ...ffToDropdown(form.field("notFoundAction"), ["value"]),
                        })}

                        {(() => {
                            if (step.value.notFoundAction === "WriteCustomValue") {
                                return DebounceTextInput({
                                    label: "Custom Value",
                                    ...ffToTextInput(form.field(["notFoundCustomValue"])),
                                });
                            } else if (step.value.notFoundAction === "OtherColumnValue") {
                                const dropdownProps = ffToDropdown(form.field(["notFoundOtherColumn"]));
                                return columnSelect({
                                    state: {
                                        value: dropdownProps.value,
                                        onChange: (c) =>
                                            step.onChange({
                                                ...omit(step.value, ["valueLookup"]),
                                                notFoundOtherColumn: c,
                                            }),
                                    },
                                    ...keepOnly(dropdownProps, ["domRef", "hasError", "errorMessage"]),
                                });
                            }
                        })()}
                    </div>
                </div>

                <div className="config-group">
                    <div className="label">Column Name</div>
                    {rNewColumnNameInput({
                        form,
                        newColumnPath: ["newColumn"],
                        newColumnNamePath: ["newColumn", "name"],
                    })}
                </div>
            </div>
        );
    });
