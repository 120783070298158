const {UseState} = require("./use-state");
const {cs} = require("./chain-services");
const {fragments} = require("./fragments");
const {Watch} = require("./watch");

const Transformer = ({state, useRawValue, transform, applyCondition, same = (v1, v2) => v1 === v2, next}) =>
    cs(
        ["buffer", (_, next) => UseState({next})],
        [
            "localState",
            (_, next) =>
                UseState({
                    next,
                    initValue: {
                        rawValue: state.value,
                        buffer: null,
                    },
                }),
        ],
        ({localState}) => {
            const {buffer, rawValue} = localState.value;
            return fragments(
                useRawValue
                    ? Watch({
                          value: state.value,
                          onChanged: (changed) => {
                              localState.onChange({
                                  ...localState.value,
                                  rawValue: changed,
                              });
                          },
                      })
                    : null,
                next({
                    rawValue,
                    state: {
                        value: buffer ?? state.value,
                        onChange: (v) => {
                            const realValue = transform(v);

                            let newLocalState = {
                                rawValue: v,
                            };

                            if (!same(realValue, v)) {
                                newLocalState.buffer = v;
                                // buffer.onChange({value: v});
                            } else {
                                if (buffer != null) {
                                    // buffer.onChange(null);
                                    newLocalState.buffer = null;
                                }
                            }

                            localState.onChange(newLocalState);

                            if (!applyCondition || applyCondition(realValue, v)) {
                                state.onChange(realValue);
                            }
                        },
                    },
                    flush: () =>
                        localState.onChange({
                            ...localState.value,
                            buffer: null,
                        }),
                })
            );
        }
    );
exports.Transformer = Transformer;
