import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {stateToSelect} from "@common/ui-components/form/state-to-select";

export const PositiveChangeValueSelect = (state) =>
    DropdownSelect({
        label: "Positive Change Value (> 0)",
        list: [
            {label: "Good", value: true},
            {label: "Bad", value: false},
        ],
        valueToLabel: (v) => v.label,
        ...stateToSelect(state, ["value"]),
    });
