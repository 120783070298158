import {dataSourceTypes} from "../../../../../common/data-source-type";
import {ToggleWithLabel} from "../../../../../../../../../common/form/toggles/yes-no-toggle";
import {TextInput} from "../../../../../../../../../common/form/text-input/text-input";
import {ffToShowHideLine} from "../../../../../../../../../common/form/ff-to-show-hide-line";
import {ffToTextInput} from "../../../../../../../../../common/form/ff-to-text-input";
import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {ffToDropdown} from "../../../../../../../../../common/form/ff-to-dropdown";

// Type: SqlServerConnectionDetails
// Host: swipe-staging-preprod1.cxudloyrk1wj.us-east-2.rds.amazonaws.com
// Database: SwipeBy
// Username: dataverb
// Password: O6cfs7xKS!bZtCLJ
// Port: 1433

export const databaseConnectionConfig = (type) =>
    ({
        [dataSourceTypes.MONGODB]: {
            port: 27017,
            config1: [
                {
                    field: "connectionDetails.srvConnection",
                    input: ToggleWithLabel,
                    label: "Use SRV",
                    binding: ffToShowHideLine,
                },
            ],
            config2: [
                {
                    field: "connectionDetails.replicaSet",
                    input: TextInput,
                    label: "Replica Set (optional)",
                    binding: ffToTextInput,
                },
                {
                    field: "connectionDetails.ssl",
                    input: ToggleWithLabel,
                    label: "Use SSL",
                    binding: ffToShowHideLine,
                },
            ],
            config3: [
                {
                    field: "connectionDetails.authSource",
                    input: TextInput,
                    label: "Auth Source (optional)",
                    binding: ffToTextInput,
                },
                {
                    field: "connectionDetails.readPreference",
                    input: DropdownSelect,
                    placeholder: "Read Preference",
                    binding: ffToDropdown,
                    list: ["Primary", "Secondary"],
                },
            ],
            modifyConnectionDetails: (connectionDetails) => {
                const hosts1 = Object.entries(connectionDetails?.hosts || {});
                const defaultHost = hosts1[0] ? hosts1[0][0] : undefined;
                const defaultPort = hosts1[0] ? hosts1[0][1] : undefined;

                const _host = connectionDetails.host ?? defaultHost ?? "";
                const _port = connectionDetails?.srvConnection ? 0 : connectionDetails.port ?? defaultPort;

                return {
                    ...connectionDetails,
                    host: _host,
                    port: _port,
                    hosts: {[_host]: _port},
                };
            },
        },
        [dataSourceTypes.SQLSERVER]: {
            port: 1433,
            config1: [
                {
                    field: "connectionDetails.namedInstance",
                    input: TextInput,
                    label: "Instance (Optional)",
                    binding: ffToTextInput,
                },
            ],
        },
        [dataSourceTypes.SNOWFLAKE]: {
            port: 443,
        },
        [dataSourceTypes.MYSQL]: {
            port: 3306,
        },
        [dataSourceTypes.POSTGRESQL]: {
            port: 5432,
            config1: [
                {
                    field: "connectionDetails.schema",
                    input: TextInput,
                    label: "Schema (Optional)",
                    binding: ffToTextInput,
                },
            ],
        },
        [dataSourceTypes.SUPABASE]: {
            port: 5432,
            config1: [
                {
                    field: "connectionDetails.schema",
                    input: TextInput,
                    label: "Schema (Optional)",
                    binding: ffToTextInput,
                },
            ],
        },
        [dataSourceTypes.REDSHIFT]: {
            port: 5432,
            config1: [
                {
                    field: "connectionDetails.schema",
                    input: TextInput,
                    label: "Schema (Optional)",
                    binding: ffToTextInput,
                },
            ],
        },
    }[type] || {});
