import "./render-chart.scss";

import * as React from "react";
import {css, cx} from "emotion";
import * as events from "events";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

import {cs} from "@common/react/chain-services";
import {ObserveDomSize0} from "@common/react/observe-dom-size-0";
import {getLegendRoom} from "./render-chart-helper";
import {arrMapToO, equalDeep, omit} from "@common/utils/objects";
import {LegendDisplay} from "./legend/legend-display";
import {UseState} from "@common/react/use-state";
import {allowHtmlElements} from "./hc-extensions/allow-html-elements";
import {applyFixedOverride} from "./hc-extensions/apply-fixed-override";
import {delayTooltipMobile} from "./hc-extensions/delay-tooltip-mobile";
import {getScrollbarStyleHC} from "./chart-scrolling-style";
import {isMobile} from "../factory/tooltip/positioners/is-mobile";
import {setPath} from "@common/utils/arr-path";
import {splineIndicatorLines} from "../reference-lines/spline-indicator-lines";
import {adjustIndicatorSeriesLabel} from "../reference-lines/adjust-indicator-series-label";
import {extendTooltipConnector} from "../factory/tooltip/extend-tooltip-connector/extend-tooltip-connector";
import {extendTooltipHiding} from "../factory/tooltip/extend-tooltip-hiding";
import {ControlFilterWrapper} from "../common/control-filter/control-filter-wrapper";
import {extendFunnelSectionPill} from "../funnel/chart-options/extend-funnel-section-pill";
import {addOptionsNeedingChartSize} from "./add-options-needing-chart-size";
import {addClassnameToDataPoint} from "@common/ui-components/charts/render-chart/add-classname-to-data-point";
import {StyledClass} from "@common/react/styled-class";
import {extendProportionalFunnel} from "@common/ui-components/charts/funnel/chart-options/extend-proportional-funnel";
// import {OnUnmounted} from "../../../react/on-unmounted";
// import {OnMounted} from "../../../react/on-mounted";

allowHtmlElements(Highcharts);
applyFixedOverride(Highcharts);
splineIndicatorLines(Highcharts);
adjustIndicatorSeriesLabel(Highcharts);
isMobile() && delayTooltipMobile(Highcharts);
extendTooltipHiding(Highcharts);
extendTooltipConnector(Highcharts, isMobile());
extendFunnelSectionPill(Highcharts);
extendProportionalFunnel(Highcharts);

export const RenderChart = React.memo(
    ({tile, options, size, theme, isCompare, formatters, chartRef, noScroll}) =>
        cs(
            // ["chartRef", (_, next) => Static2({next})],
            ["chartRect", (_, next) => UseState({next})],
            [
                "sizeRef",
                ({chartRect}, next) =>
                    ObserveDomSize0({
                        startImmediately: true,
                        onChange: (containerSize, element) => {
                            const chart = chartRef.get()?.chart;
                            chartRect.onChange(element.getBoundingClientRect());
                            if (chart && typeof chart?.reflow == "function" && chartRect.value) {
                                chart?.reflow?.();
                            }
                        },
                        next,
                    }),
            ],
            ["chartLoaded", ({}, next) => UseState({next})],
            [
                "scrollbarHCClass",
                ({}, next) => {
                    return StyledClass({
                        content: getScrollbarStyleHC(theme),
                        next,
                    });
                },
            ],
            // ({}, next) => IgnoreUpdate({next}),
            ({sizeRef, chartLoaded, chartRect, scrollbarHCClass}) => {
                const legendRoom = getLegendRoom({
                    legend: options.legend,
                    series: options.series,
                    size,
                    chartHeight: chartRect.value?.height,
                    chartWidth: chartRect.value?.width,
                    isCompare,
                    formatters,
                    options,
                    tile,
                });

                return (
                    <div
                        className={cx("render-chart-2fv", scrollbarHCClass, isMobile() && "mobile")}
                        style={{
                            width: "100%",
                            height: "100%",
                            ...(legendRoom && {
                                position: "relative",
                                ...arrMapToO(["paddingTop", "paddingBottom", "paddingLeft", "paddingRight"], (k) => legendRoom[k] || 0),
                            }),
                        }}
                        ref={sizeRef.ref}
                    >
                        {chartRect.value && (
                            <ControlFilterWrapper
                                tile={tile}
                                series={options.series}
                                dimensionAxisType={options.xAxis?.[0] ? options.xAxis[0].type : options.xAxis?.type}
                            >
                                {(series) => {
                                    const _series = addClassnameToDataPoint({
                                        series,
                                        tile,
                                    });
                                    return (
                                        <MemoChartRenderer
                                            {...{
                                                chartRef,
                                                chartLoaded,
                                                options: {
                                                    ...addOptionsNeedingChartSize({
                                                        tile,
                                                        options,
                                                        chartWidth:
                                                            chartRect.value.width -
                                                            (legendRoom?.paddingRight || 0) -
                                                            (legendRoom?.paddingLeft || 0),
                                                        chartHeight:
                                                            chartRect.value.height -
                                                            (legendRoom?.paddingTop || 0) -
                                                            (legendRoom?.paddingBottom || 0),
                                                        noScroll,
                                                    }),
                                                    series: _series,
                                                    legend: {
                                                        enabled: false,
                                                    },
                                                    exporting: {
                                                        enabled: false,
                                                    },
                                                },
                                                containerProps: {
                                                    style: {
                                                        width: "100%",
                                                        height: "100%",
                                                    },
                                                },
                                            }}
                                        />
                                    );
                                }}
                            </ControlFilterWrapper>
                        )}

                        {legendRoom &&
                            chartLoaded.value &&
                            LegendDisplay({
                                size,
                                chartRef,
                                legendRoom,
                                tile,
                                options,
                                theme,
                                chartRect,
                                isCompare,
                                formatters,
                            })}

                        {/*{Invoke({*/}
                        {/*    fn: ({isMounted}) => {*/}
                        {/*        console.log("start rendering at ", Date())*/}
                        {/*        console.log("highcharts rendering...")*/}
                        {/*    },*/}
                        {/*    onMounted: () => {*/}
                        {/*        console.log("finish rendering at ", Date())*/}
                        {/*    }*/}
                        {/*})}*/}
                    </div>
                );
            }
        ),
    (prevProps, nextProps) => {
        const prevOption = setPath(prevProps.options, ["plotOptions", "series", "*", "events", "click"]);
        const nextOption = setPath(nextProps.options, ["plotOptions", "series", "*", "events", "click"]);

        if (!equalDeep(prevOption, nextOption, {ignoreFunction: true})) {
            return false;
        }
        if (!equalDeep(prevProps.tile.style, nextProps.tile.style)) {
            return false;
        }
        return equalDeep(prevProps.theme, nextProps.theme);
    }
);

const MemoChartRenderer = React.memo(
    ({options, chartRef, containerProps, chartLoaded}) => {
        return (
            <HighchartsReact
                {...{
                    highcharts: Highcharts,
                    options: options,
                    containerProps: containerProps,
                    ref: chartRef.set,
                    // immutable: true,
                    callback: () => {
                        setTimeout(() => {
                            chartLoaded.onChange(true);
                        });
                    },
                }}
            />
        );
    },
    (prevProps, nextProps) => {
        const prevOption = removeUnusedProps(prevProps.options);
        const nextOption = removeUnusedProps(nextProps.options);

        if (!equalDeep(prevOption, nextOption, {ignoreFunction: true})) {
            return false;
        }

        if (!equalDeep(prevProps.containerProps, nextProps.containerProps)) {
            return false;
        }

        return true;
    }
);

function removeUnusedProps(prop) {
    let ret = prop;
    ret = {
        ...ret,
        series: ret.series.map((s) => omit(s, ["point", "events", "customEvents"])),
    };
    return ret;
}
