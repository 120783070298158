import "./map-fields-for-cloning-collection-dialog.scss";

import * as React from "react";

import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {consumeContext} from "@common/react/context";

import {DialogService} from "../../../../../common/dialog/dialog-service";
import {MapModelFieldsService} from "../../../../../common/map-model-fields/map-model-fields-service";
import {tileFieldAttrs} from "../../../../../common/tile-field-attrs";
import {getFieldsModellingProp} from "../../../../../model/edit/tabs/common/get-using-collections";
import {getCollectionWithMappedFields} from "../../../../../common/map-model-fields/get-clone-with-mapped-fields";
import {chain} from "@common/utils/fs";
import {removeIdsInCollection} from "../../../../../common/remove-ids";
import {purifyCollection} from "./purify-collection";
import {Button} from "../../../../../../../../common/form/buttons/button/button";
import {VerbDialogBodyScrollbar} from "@common/ui-components/verb-scrollbar/verb-dialog-body-scrollbar";

export const MapFieldsForCloningCollectionDialog = ({collection, destinationModelId, onDone, next: rootNext}) =>
    cs(
        consumeContext("apis"),
        [
            "modal",
            (_, next) =>
                DialogService({
                    render: ({resolve, args: {cloneName}}) => ({
                        title: "Map Fields",
                        width: 720,
                        content: next({
                            resolve,
                            cloneName,
                        }),
                    }),
                    next: rootNext,
                }),
        ],
        ["cloning", (_, next) => UseState({next})],
        ["error", (_, next) => UseState({next})],
        [
            "mapFieldsService",
            (_, next) =>
                MapModelFieldsService({
                    originFields: getCollectionFields(collection),
                    originModelId: collection.modelID,
                    destinationModelId,
                    next,
                }),
        ],
        ({modal, mapFieldsService, apis, error, cloning}) => {
            // console.log(mapFieldsService.fieldsMap)
            return (
                <div className="map-fields-for-cloning-collection-dialog-5s3">
                    <VerbDialogBodyScrollbar>
                        <div className="messages">
                            <div className="message">
                                Creating a new collection that uses a different model requires you to map the fields from this model to the
                                new model.
                            </div>
                            {error.value && (
                                <div className="message error">
                                    Sorry, there is an issue cloning this collection.
                                    <div>{error.value}</div>
                                </div>
                            )}
                        </div>
                        {mapFieldsService.renderMapTable()}
                    </VerbDialogBodyScrollbar>
                    <div className="buttons">
                        <Button
                            disabled={
                                mapFieldsService.fieldsMap == null ||
                                mapFieldsService.fieldsMap.map((fm) => fm.destinationModelField).filter((v) => v).length !== mapFieldsService.fieldsMap.length ||
                                cloning.value
                            }
                            onClick={async () => {
                                const cloneCollection = chain(
                                    {
                                        ...purifyCollection(collection),
                                        name: modal.cloneName,
                                        modelID: destinationModelId,
                                    },
                                    (col) =>
                                        mapFieldsService.fieldsMap?.length > 0
                                            ? getCollectionWithMappedFields(col, mapFieldsService.fieldsMap)
                                            : col,
                                    (col) => removeIdsInCollection(col)
                                );
                                try {
                                    cloning.onChange(true);
                                    const newCollection = await apis.collection.upsertCollection(cloneCollection);
                                    onDone(newCollection);
                                    modal.resolve();
                                } catch (e) {
                                    error.onChange(e.message);
                                    cloning.onChange(false);
                                }
                            }}
                        >
                            Done
                        </Button>
                    </div>
                </div>
            );
        }
    );

export const getCollectionFields = (collection) => {
    if(collection.gridLocations?.length === 0) {
        return;
    }

    let collectionFields = [];

    const isIncluded = (field) => collectionFields.find((cf) => cf.modelTableID === field.modelTableID && cf.modelColumnID === field.modelColumnID);

    const getFields = getFieldsModellingProp();

    const getTileFields = (tile) => {
        // get tile fields
        if(tile.$type === "ContainerTile") {
            tile.tiles?.forEach((t) => getTileFields(t));
        } else {
            const attrs = tileFieldAttrs[tile.$type];
            attrs?.forEach((attr) => {
                const fields = getFields(attr, tile);
                fields.forEach((field) => !isIncluded(field) && collectionFields.push(field));
            });
        }

        // get tile filters fields
        tile.filters?.forEach((filter) => filter.columns.forEach((c) => !isIncluded(c) && collectionFields.push(c)));

        // get tile's tileActions' fields
        tile.tileActions?.forEach((tileAction) => {
            tileAction.tile && getTileFields(tileAction.tile);
            tileAction.filters?.forEach((filter) => filter.columns.forEach((c) => !isIncluded(c) && collectionFields.push(c)));
        });

        // tile sort and limit fields are taken from tile fields, so no need to care
    };

    if(["ApiCollection", "SparkCollection"].includes(collection.$type)) {
        getTileFields(collection.tile);
    } else {
        collection.gridLocations.forEach(({tile}) => getTileFields(tile));
    }

    // fields in filters do not have dataType, so put them at bottom for now
    collection.filters?.forEach((filter) => filter.columns.forEach((c) => !isIncluded(c) && collectionFields.push(c)));

    return collectionFields;
};
