import * as React from "react";

import {Load} from "@common/react/load";
import {cs} from "@common/react/chain-services";
import {consumeContext} from "@common/react/context";
import {Button} from "@common/form/buttons/button/button";

import {joinJsx} from "@common/react/join-jsx";
import {DialogService} from "../../../../common/dialog/dialog-service";
import {keyToArray} from "../../../../dashboard/env/settings/data-access-key/manage-key/manage-access-key-dialog";

export const DeleteDataViewDialog = ({model, onDone, next: rootNext, getModelColumnProviderName = "getModelColumn"}) =>
    cs(
        [
            "modal",
            (_, next) =>
                DialogService({
                    render: ({resolve, args: {table}}) => ({
                        title: "Delete Data View?",
                        width: 480,
                        content: next({
                            resolve,
                            table,
                        }),
                    }),
                    next: rootNext,
                }),
        ],
        consumeContext("apis"),
        [
            "impactedCollections",
            ({apis, modal: {table}}, next) =>
                Load({
                    fetch: async () => {
                        if(!table || !table.id) return [];
                        const tables = model.value.tables.filter((t) => t.id == table.id);
                        const resp = await apis.model.getImpactedCollection({
                            modelID: model.value.id,
                            tables,
                        });
                        return keyToArray(resp).map((item) => item.value.name);
                    },
                    next,
                }),
        ],
        consumeContext(getModelColumnProviderName),
        [
            "doDelete",
            ({}, next) =>
                next(async (props) => {
                    const {
                        modal: {table, resolve},
                    } = props;
                    const getModelColumn = props[getModelColumnProviderName];

                    if (table.$type != "ViewModelTable") {
                        return;
                    }
                    let newTables;

                    newTables = model.value.tables.filter((t) => t.id !== table.id);

                    const newRelationships = model.value.relationships
                        .map((r) => {
                            const tableIds = [r.leftColumnID, r.rightColumnID]
                                .map((id) => getModelColumn(id)?.table.id || null)
                                .filter((id) => !!id);
                            if (!tableIds.includes(table.id)) {
                                return r;
                            }
                        })
                        .filter((v) => v);

                    model.change(() => ({
                        ...model.value,
                        tables: newTables,
                        relationships: newRelationships,
                    }));

                    resolve();
                    onDone?.();
                }),
        ],

        (props) => {
            const {
                modal: {table, resolve},
                impactedCollections,
                doDelete,
            } = props;
            const tableName = table?.visualName.toLowerCase();
            return (
                <div className="remove-table-dialog-kl8">
                    {!impactedCollections && !impactedCollections?.length ? (
                        <div className="checking dialog-body">Checking...</div>
                    ) : (
                        <>
                            <div className="main-part dialog-body">
                                {!impactedCollections?.length ? (
                                    `The ${tableName} data view is not used in any collections but removing this data view will limit collection data in the future.`
                                ) : (
                                    <>
                                        The {tableName} data view is used in the following collections;
                                        <br />
                                        <br />
                                        {joinJsx(
                                            impactedCollections.map((n) => "- " + n),
                                            <br />
                                        )}
                                        <br />
                                        <br />
                                        Deleting this data view will not be reflected in the embedded collection until the next publish.
                                    </>
                                )}
                                <br />
                                <br />
                                Are you sure that you want to delete the {tableName} data view?
                            </div>
                            <div className="buttons">
                                <Button btnType="secondary" onClick={() => resolve()}>
                                    Cancel
                                </Button>
                                <Button onClick={() => doDelete(props)}>Delete Data View</Button>
                            </div>
                        </>
                    )}
                </div>
            );
        }
    );
