import {DropdownSelect} from "@common/ui-components/dropdown-select/dropdown-select";
import {stateToSelect} from "@common/ui-components/form/state-to-select";

export const XAxisDateGapsSelect = (state) =>
    DropdownSelect({
        registryRender: true,
        label: "Date Gaps",
        list: ["Fill With Zero", "Show Gaps"].map((label) => ({
            label,
            value: label.replace(/ /g, ""),
        })),
        ...stateToSelect(state, ["value"]),
        valueToLabel: (v) => v.label,
    });
