const getBoolAggregationFuncs = ({notAllowNone} = {}) => (notAllowNone ? boolAggregationFuncs.slice(1) : boolAggregationFuncs);
exports.getBoolAggregationFuncs = getBoolAggregationFuncs;

const boolAggregationFuncs = [
    {
        label: "None",
        value: "None",
    },
    {
        label: "Count",
        value: "Count",
    },
    {
        label: "Count Inc Null",
        value: "CountIncNull",
    },
    {
        label: "Count Distinct",
        value: "CountDistinct",
    },
    {
        label: "Count Distinct Inc Null",
        value: "CountDistinctIncNull",
    },
    {
        label: "Count Null",
        value: "CountNull",
    },
    {
        label: "Count If True",
        value: "CountIfTrue",
    },
    {
        label: "Count If False",
        value: "CountIfFalse",
    },
    {
        label: "First Value Asc",
        value: "FirstValueAsc",
    },
    {
        label: "First Value Desc",
        value: "FirstValueDesc",
    },
    {
        label: "Last Value Asc",
        value: "LastValueAsc",
    },
    {
        label: "Last Value Desc",
        value: "LastValueDesc",
    },
];
