import "./live-dashboard.scss";

import {cx} from "emotion";
import * as React from "react";
import moment from "moment-timezone";

import {Load2} from "@common/react/load2";
import {Watch} from "@common/react/watch";
import {Static2} from "@common/react/static-2";
import {cs} from "@common/react/chain-services";
import {UseState} from "@common/react/use-state";
import {fragments} from "@common/react/fragments";
import {OnMounted} from "@common/react/on-mounted";
import {OnUnmounted} from "@common/react/on-unmounted";
import {CaptureException} from "@common/react/capture-exception";
import {consumeContext, provideContext} from "@common/react/context";

import {LiveGrid} from "./live-grid/live-grid";
import {LiveFilters} from "./live-filters/live-filters";
import {DashboardData} from "./data/dashboard-data";

import {initTheme} from "../../../../web-client/src/routes/theme/edit/common/theme-init";
import {tooltipService3} from "../../../../web-client/src/routes/common/tooltip3/tooltip-service-3";
import {ConfirmDialog} from "../../../../web-client/src/routes/common/confirm-dialog/confirm-dialog";
import {gridHeight} from "../../../../web-client/src/routes/collection/common/dnd-grid-panel/grid-constants";

import {chain} from "@common/utils/fs";
import {mergeDeep} from "@common/utils/object";

import {LoadingIndicator} from "../../loading-indicator/loading-indicator";
import {getLayoutColumns} from "../live-grid-panel/utils";
import {VerbEvent} from "./common/js-trigger";
import {ControlFiltersBadge} from "./control-filters-badge/control-filters-badge";
import {FlapFilters} from "../../../../web-client/src/routes/collection/filter/flap-filters/flap-filters-dialog";
import {downloadPDFFunc} from "./live-filters/download-button/download-pdf";
import {loadAutoRefreshProvider} from "./live-grid/auto-refresh-controller";
import {ProvideDefaultTimezone} from "../../../../web-client/src/routes/collection/common/provide-default-timezone";
import {toastServiceDownload} from "@common/ui-components/toast/toast-service-download";
import {Invoke} from "@common/react/invoke";

export const isValidTimezone = (timezone) => moment.tz.names().includes(timezone);

export const CollectionFilterProvider = ({next}) =>
    cs(
        ["collectionFilter", (_, next) => UseState({next, initValue: null})],
        ({collectionFilter}, next) => provideContext("collectionFilter", collectionFilter, next),
        ({collectionFilter}) => next(collectionFilter.value)
    );

const LiveDashboardMode = {
    SDK: 0,
    Preview: 1,
    Edit: 2,
};

export const LiveDashboard = ({
    collection: rootCollection,
    theme: defaultTheme,
    ref, // this is used in sdk only, for setting filters value through ref
    noBackgroundColor,
    loadFont,
    sdkDashboard,
    isEditTile = false,
    onEdit,
    onSelectTile,
    noFilters = false,
    disabledFilters,
    renderFilter,
    hiddenFilterIds,
    filterVals, // this is used in sdk only
    disabledTileActions = false,
    renderOverlay,
    apiKey = null,
    defaultData,
    timezone = null,
    forceAutoRefresh = false,
    refreshing,
    printPDF = false,
}) =>
    cs(
        consumeContext("shadowRoot"),
        consumeContext("autoRefreshProvider"),
        ({autoRefreshProvider}, next) =>
            autoRefreshProvider
                ? next()
                : loadAutoRefreshProvider({
                      next,
                      collection: rootCollection,
                      isSDKDashboard: sdkDashboard,
                  }),
        ["collection", (_, next) => next(fixSparkCollection(rootCollection))],
        (_, next) => CollectionFilterProvider({next}),
        ["eventRef", (_, next) => Static2({next})],
        ["dashboardRef", (_, next) => Static2({next})],
        ({dashboardRef}, next) => provideContext("dashboardRef", dashboardRef, next),
        ["tileActionControlFilter", (_, next) => UseState({next})],
        ["loadedTileCounter", (_, next) => UseState({next, initValue: {loadingTiles: 0, loadedTiles: 0}})],

        ({loadedTileCounter, shadowRoot}, next) =>
            fragments(
                printPDF &&
                    Watch({
                        value: loadedTileCounter.value,
                        onChanged: async ({loadingTiles, loadedTiles}) => {
                            if (loadedTiles && loadingTiles && loadedTiles == loadingTiles) {
                                await downloadPDFFunc(rootCollection.name, rootCollection.pdfDownloadIncludeFilter, undefined, shadowRoot);
                                window.close();
                            }
                        },
                    }),
                next()
            ),
        ({tileActionControlFilter}, next) =>
            Watch({
                value: refreshing,
                onChanged: () => tileActionControlFilter.onChange(null),
                next,
            }),
        ({tileActionControlFilter}, next) => provideContext("tileActionControlFilter", tileActionControlFilter, next),
        [
            "viewJSParams",
            (_, next) =>
                !sdkDashboard
                    ? ConfirmDialog({
                          next,
                          hideCancel: true,
                          submitText: "Close",
                          title: "JS Params",
                      })
                    : next(),
        ],
        ({}, next) => captureException({next}),
        ({collection}, next) => (collection == null ? <div style={{padding: 25}}>{LoadingIndicator({})}</div> : next()),
        ({collection}, next) =>
            cs(
                [
                    "remoteTheme",
                    ({}, next) =>
                        defaultTheme
                            ? next({value: initTheme(defaultTheme)})
                            : cs(consumeContext("apis"), ({apis}) =>
                                  Load2({
                                      _key: JSON.stringify({
                                          id: collection?.themeID,
                                          apiKey,
                                      }),
                                      fetch: () => (!collection?.themeID ? null : apis.collection.getTheme(collection.themeID)),
                                      next,
                                  })
                              ),
                ],
                ({remoteTheme}, next) => (remoteTheme.loading ? null : next()),
                ({remoteTheme}, next) => provideContext("theme", {...remoteTheme.value, sdkDashboard}, next),
                next
            ),
        ({eventRef, viewJSParams}, next) =>
            fragments(
                !sdkDashboard &&
                    OnMounted({
                        action: () => {
                            eventRef.set(
                                VerbEvent.on("jsTrigger", (...params) => {
                                    viewJSParams.show({
                                        description: (
                                            <div className="pre-code">
                                                <pre>
                                                    {JSON.stringify(params, null, 2)
                                                        .split("\n")
                                                        .map((line, i) => (
                                                            <span key={i}>{line}</span>
                                                        ))}
                                                </pre>
                                            </div>
                                        ),
                                    });
                                })
                            );
                        },
                    }),
                !sdkDashboard &&
                    OnUnmounted({
                        action: () => {
                            const removeListener = eventRef.get();
                            removeListener?.();
                        },
                    }),
                next()
            ),
        (_, next) =>
            ProvideDefaultTimezone({
                next,
                timezone,
                defaultTimezoneToUtc: !rootCollection.showTimezoneConversion ? true : rootCollection.defaultTimezoneToUtc,
            }),
        tooltipService3({direction: "below"}),
        consumeContext("theme"),
        consumeContext("filtersValueSet"),
        [
            "data",
            ({collection, filtersValueSet}, next) =>
                DashboardData({
                    isEditTile,
                    collection,
                    filterVals: filtersValueSet?.value ?? filterVals,
                    next,
                    ref,
                }),
        ],

        ({theme}, next) => {
            if (!theme || !loadFont) return next();

            return Invoke({
                next,
                fn: () => loadFont(theme.general.canvas.fontFamily),
            });
        },
        ({theme}, next) => toastServiceDownload({theme, next}),
        ({data, dashboardRef, theme, collection, loadedTileCounter}) => {
            const background = !noBackgroundColor ? theme.general.canvas.backgroundColorRGB : "transparent";

            const rDashboardFilter = () => (
                <div
                    {...{
                        className: cx("filters verb-filters"),
                        style: {
                            background,
                            padding: !sdkDashboard ? "0 20px" : "",
                        },
                    }}
                >
                    {LiveFilters({
                        apiKey,
                        sdkDashboard,
                        isLiveDashboard: true,
                        hiddenFilterIds,
                        collection: {value: collection},
                        filterForms: data.filterForms,
                        onRunReport: () => {
                            data?.unblockChanges?.();
                        },
                        getTileFilters: data.getTileFilters,
                        disabledFilters,
                        editMode: isEditTile,
                    })}
                </div>
            );

            return (
                <div
                    className={cx(
                        "live-dashboard-1w2",
                        collection.$type == "SparkCollection" && "spark-collection",
                        !sdkDashboard && "not-sdk-dashboard",
                        `verb-collection verb-collection-${collection.id.replace(/-/g, "")} verb-collection-${collection.name
                            .toLowerCase()
                            .replace(/[^\d\w]/g, "")}`
                    )}
                    style={{
                        background,
                        fontFamily: `"${theme.general.canvas.fontFamily}", sans-serif`,
                    }}
                    ref={dashboardRef.set}
                >
                    <>
                        {!sdkDashboard &&
                            FlapFilters({
                                collection: {value: collection},
                                filterForms: data.filterForms,
                                onRunReport: () => {
                                    data.unblockChanges();
                                },
                            })}

                        {chain(renderFilter ?? (({content}) => content), (renderFilter) => renderFilter({content: rDashboardFilter()}))}

                        {ControlFiltersBadge({collection})}

                        {dashboardRef.get() && (
                            <div className="charts" style={{background}}>
                                {LiveGrid({
                                    apiKey,
                                    getTileFilters: data.getTileFilters,
                                    renderOverlay,
                                    collection,
                                    sdkDashboard,
                                    forceAutoRefresh,
                                    onEdit,
                                    onSelectTile,
                                    disabledTileActions,
                                    defaultData,
                                    loadedTileCounter,
                                    isEditTile,
                                })}
                            </div>
                        )}
                    </>
                </div>
            );
        }
    );

const captureException = ({next}) =>
    CaptureException({
        renderException: (error) => (
            <div className="">
                <div className="">
                    An error has occurred while rendering Verb's Dashboard. We are so sorry your workflow is interrupted.
                </div>

                <div className="">Error detail:</div>
                <div className="">{error.toString()}</div>
            </div>
        ),
        onException: ({error, info}) => console.error({error, info}),
        next,
    });

const fixSparkCollection = (rootCollection) => {
    if (rootCollection?.$type === "SparkCollection") {
        // Needs refactoring
        const {actualNumColumns} = getLayoutColumns(rootCollection.viewWidthPixels, rootCollection.customWindowWidth);

        const newTile = mergeDeep(
            {
                style: {
                    title: {
                        show: false,
                    },
                    noPadding: true,
                },
            },
            rootCollection.tile
        );

        return {
            ...rootCollection,
            gridLocations: [
                {
                    tile: newTile,
                    colSpan: actualNumColumns,
                    colStart: 1,
                    rowSpan: rootCollection.viewHeightPixels / gridHeight,
                    rowStart: 1,
                },
            ],
        };
    } else {
        return rootCollection;
    }
};
